/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AuthTokenResponse = (function() {

    /**
     * Properties of an AuthTokenResponse.
     * @exports IAuthTokenResponse
     * @interface IAuthTokenResponse
     * @property {string|null} [accessToken] AuthTokenResponse accessToken
     * @property {string|null} [refreshToken] AuthTokenResponse refreshToken
     * @property {string|null} [scope] AuthTokenResponse scope
     * @property {string|null} [tokenType] AuthTokenResponse tokenType
     * @property {number|null} [expiresIn] AuthTokenResponse expiresIn
     * @property {string|null} [streemUserId] AuthTokenResponse streemUserId
     * @property {string|null} [streemCompanyId] AuthTokenResponse streemCompanyId
     */

    /**
     * Constructs a new AuthTokenResponse.
     * @exports AuthTokenResponse
     * @classdesc Represents an AuthTokenResponse.
     * @implements IAuthTokenResponse
     * @constructor
     * @param {IAuthTokenResponse=} [p] Properties to set
     */
    function AuthTokenResponse(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * AuthTokenResponse accessToken.
     * @member {string} accessToken
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.accessToken = "";

    /**
     * AuthTokenResponse refreshToken.
     * @member {string} refreshToken
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.refreshToken = "";

    /**
     * AuthTokenResponse scope.
     * @member {string} scope
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.scope = "";

    /**
     * AuthTokenResponse tokenType.
     * @member {string} tokenType
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.tokenType = "";

    /**
     * AuthTokenResponse expiresIn.
     * @member {number} expiresIn
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.expiresIn = 0;

    /**
     * AuthTokenResponse streemUserId.
     * @member {string} streemUserId
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.streemUserId = "";

    /**
     * AuthTokenResponse streemCompanyId.
     * @member {string} streemCompanyId
     * @memberof AuthTokenResponse
     * @instance
     */
    AuthTokenResponse.prototype.streemCompanyId = "";

    /**
     * Creates a new AuthTokenResponse instance using the specified properties.
     * @function create
     * @memberof AuthTokenResponse
     * @static
     * @param {IAuthTokenResponse=} [properties] Properties to set
     * @returns {AuthTokenResponse} AuthTokenResponse instance
     */
    AuthTokenResponse.create = function create(properties) {
        return new AuthTokenResponse(properties);
    };

    /**
     * Encodes the specified AuthTokenResponse message. Does not implicitly {@link AuthTokenResponse.verify|verify} messages.
     * @function encode
     * @memberof AuthTokenResponse
     * @static
     * @param {IAuthTokenResponse} m AuthTokenResponse message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AuthTokenResponse.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.accessToken != null && Object.hasOwnProperty.call(m, "accessToken"))
            w.uint32(10).string(m.accessToken);
        if (m.refreshToken != null && Object.hasOwnProperty.call(m, "refreshToken"))
            w.uint32(18).string(m.refreshToken);
        if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
            w.uint32(26).string(m.scope);
        if (m.tokenType != null && Object.hasOwnProperty.call(m, "tokenType"))
            w.uint32(34).string(m.tokenType);
        if (m.expiresIn != null && Object.hasOwnProperty.call(m, "expiresIn"))
            w.uint32(40).uint32(m.expiresIn);
        if (m.streemUserId != null && Object.hasOwnProperty.call(m, "streemUserId"))
            w.uint32(82).string(m.streemUserId);
        if (m.streemCompanyId != null && Object.hasOwnProperty.call(m, "streemCompanyId"))
            w.uint32(90).string(m.streemCompanyId);
        return w;
    };

    /**
     * Decodes an AuthTokenResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AuthTokenResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {AuthTokenResponse} AuthTokenResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AuthTokenResponse.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.AuthTokenResponse();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.accessToken = r.string();
                    break;
                }
            case 2: {
                    m.refreshToken = r.string();
                    break;
                }
            case 3: {
                    m.scope = r.string();
                    break;
                }
            case 4: {
                    m.tokenType = r.string();
                    break;
                }
            case 5: {
                    m.expiresIn = r.uint32();
                    break;
                }
            case 10: {
                    m.streemUserId = r.string();
                    break;
                }
            case 11: {
                    m.streemCompanyId = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an AuthTokenResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AuthTokenResponse
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {AuthTokenResponse} AuthTokenResponse
     */
    AuthTokenResponse.fromObject = function fromObject(d) {
        if (d instanceof $root.AuthTokenResponse)
            return d;
        var m = new $root.AuthTokenResponse();
        if (d.accessToken != null) {
            m.accessToken = String(d.accessToken);
        }
        if (d.refreshToken != null) {
            m.refreshToken = String(d.refreshToken);
        }
        if (d.scope != null) {
            m.scope = String(d.scope);
        }
        if (d.tokenType != null) {
            m.tokenType = String(d.tokenType);
        }
        if (d.expiresIn != null) {
            m.expiresIn = d.expiresIn >>> 0;
        }
        if (d.streemUserId != null) {
            m.streemUserId = String(d.streemUserId);
        }
        if (d.streemCompanyId != null) {
            m.streemCompanyId = String(d.streemCompanyId);
        }
        return m;
    };

    /**
     * Creates a plain object from an AuthTokenResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AuthTokenResponse
     * @static
     * @param {AuthTokenResponse} m AuthTokenResponse
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AuthTokenResponse.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.accessToken = "";
            d.refreshToken = "";
            d.scope = "";
            d.tokenType = "";
            d.expiresIn = 0;
            d.streemUserId = "";
            d.streemCompanyId = "";
        }
        if (m.accessToken != null && m.hasOwnProperty("accessToken")) {
            d.accessToken = m.accessToken;
        }
        if (m.refreshToken != null && m.hasOwnProperty("refreshToken")) {
            d.refreshToken = m.refreshToken;
        }
        if (m.scope != null && m.hasOwnProperty("scope")) {
            d.scope = m.scope;
        }
        if (m.tokenType != null && m.hasOwnProperty("tokenType")) {
            d.tokenType = m.tokenType;
        }
        if (m.expiresIn != null && m.hasOwnProperty("expiresIn")) {
            d.expiresIn = m.expiresIn;
        }
        if (m.streemUserId != null && m.hasOwnProperty("streemUserId")) {
            d.streemUserId = m.streemUserId;
        }
        if (m.streemCompanyId != null && m.hasOwnProperty("streemCompanyId")) {
            d.streemCompanyId = m.streemCompanyId;
        }
        return d;
    };

    /**
     * Converts this AuthTokenResponse to JSON.
     * @function toJSON
     * @memberof AuthTokenResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AuthTokenResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AuthTokenResponse
     * @function getTypeUrl
     * @memberof AuthTokenResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AuthTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AuthTokenResponse";
    };

    return AuthTokenResponse;
})();

$root.StreemCallPayload = (function() {

    /**
     * Properties of a StreemCallPayload.
     * @exports IStreemCallPayload
     * @interface IStreemCallPayload
     * @property {string|null} [fromName] StreemCallPayload fromName
     * @property {streem.api.IUser|null} [fromUser] StreemCallPayload fromUser
     * @property {streem.api.IUser|null} [toUser] StreemCallPayload toUser
     * @property {streem.api.IRoom|null} [room] StreemCallPayload room
     * @property {streem.api.IRoomInvitation|null} [invitation] StreemCallPayload invitation
     */

    /**
     * Constructs a new StreemCallPayload.
     * @exports StreemCallPayload
     * @classdesc Represents a StreemCallPayload.
     * @implements IStreemCallPayload
     * @constructor
     * @param {IStreemCallPayload=} [p] Properties to set
     */
    function StreemCallPayload(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * StreemCallPayload fromName.
     * @member {string} fromName
     * @memberof StreemCallPayload
     * @instance
     */
    StreemCallPayload.prototype.fromName = "";

    /**
     * StreemCallPayload fromUser.
     * @member {streem.api.IUser|null|undefined} fromUser
     * @memberof StreemCallPayload
     * @instance
     */
    StreemCallPayload.prototype.fromUser = null;

    /**
     * StreemCallPayload toUser.
     * @member {streem.api.IUser|null|undefined} toUser
     * @memberof StreemCallPayload
     * @instance
     */
    StreemCallPayload.prototype.toUser = null;

    /**
     * StreemCallPayload room.
     * @member {streem.api.IRoom|null|undefined} room
     * @memberof StreemCallPayload
     * @instance
     */
    StreemCallPayload.prototype.room = null;

    /**
     * StreemCallPayload invitation.
     * @member {streem.api.IRoomInvitation|null|undefined} invitation
     * @memberof StreemCallPayload
     * @instance
     */
    StreemCallPayload.prototype.invitation = null;

    /**
     * Creates a new StreemCallPayload instance using the specified properties.
     * @function create
     * @memberof StreemCallPayload
     * @static
     * @param {IStreemCallPayload=} [properties] Properties to set
     * @returns {StreemCallPayload} StreemCallPayload instance
     */
    StreemCallPayload.create = function create(properties) {
        return new StreemCallPayload(properties);
    };

    /**
     * Encodes the specified StreemCallPayload message. Does not implicitly {@link StreemCallPayload.verify|verify} messages.
     * @function encode
     * @memberof StreemCallPayload
     * @static
     * @param {IStreemCallPayload} m StreemCallPayload message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StreemCallPayload.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.fromName != null && Object.hasOwnProperty.call(m, "fromName"))
            w.uint32(10).string(m.fromName);
        if (m.fromUser != null && Object.hasOwnProperty.call(m, "fromUser"))
            $root.streem.api.User.encode(m.fromUser, w.uint32(18).fork()).ldelim();
        if (m.toUser != null && Object.hasOwnProperty.call(m, "toUser"))
            $root.streem.api.User.encode(m.toUser, w.uint32(26).fork()).ldelim();
        if (m.room != null && Object.hasOwnProperty.call(m, "room"))
            $root.streem.api.Room.encode(m.room, w.uint32(34).fork()).ldelim();
        if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
            $root.streem.api.RoomInvitation.encode(m.invitation, w.uint32(42).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a StreemCallPayload message from the specified reader or buffer.
     * @function decode
     * @memberof StreemCallPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {StreemCallPayload} StreemCallPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StreemCallPayload.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.StreemCallPayload();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.fromName = r.string();
                    break;
                }
            case 2: {
                    m.fromUser = $root.streem.api.User.decode(r, r.uint32());
                    break;
                }
            case 3: {
                    m.toUser = $root.streem.api.User.decode(r, r.uint32());
                    break;
                }
            case 4: {
                    m.room = $root.streem.api.Room.decode(r, r.uint32());
                    break;
                }
            case 5: {
                    m.invitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a StreemCallPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StreemCallPayload
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {StreemCallPayload} StreemCallPayload
     */
    StreemCallPayload.fromObject = function fromObject(d) {
        if (d instanceof $root.StreemCallPayload)
            return d;
        var m = new $root.StreemCallPayload();
        if (d.fromName != null) {
            m.fromName = String(d.fromName);
        }
        if (d.fromUser != null) {
            if (typeof d.fromUser !== "object")
                throw TypeError(".StreemCallPayload.fromUser: object expected");
            m.fromUser = $root.streem.api.User.fromObject(d.fromUser);
        }
        if (d.toUser != null) {
            if (typeof d.toUser !== "object")
                throw TypeError(".StreemCallPayload.toUser: object expected");
            m.toUser = $root.streem.api.User.fromObject(d.toUser);
        }
        if (d.room != null) {
            if (typeof d.room !== "object")
                throw TypeError(".StreemCallPayload.room: object expected");
            m.room = $root.streem.api.Room.fromObject(d.room);
        }
        if (d.invitation != null) {
            if (typeof d.invitation !== "object")
                throw TypeError(".StreemCallPayload.invitation: object expected");
            m.invitation = $root.streem.api.RoomInvitation.fromObject(d.invitation);
        }
        return m;
    };

    /**
     * Creates a plain object from a StreemCallPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StreemCallPayload
     * @static
     * @param {StreemCallPayload} m StreemCallPayload
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StreemCallPayload.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.fromName = "";
            d.fromUser = null;
            d.toUser = null;
            d.room = null;
            d.invitation = null;
        }
        if (m.fromName != null && m.hasOwnProperty("fromName")) {
            d.fromName = m.fromName;
        }
        if (m.fromUser != null && m.hasOwnProperty("fromUser")) {
            d.fromUser = $root.streem.api.User.toObject(m.fromUser, o);
        }
        if (m.toUser != null && m.hasOwnProperty("toUser")) {
            d.toUser = $root.streem.api.User.toObject(m.toUser, o);
        }
        if (m.room != null && m.hasOwnProperty("room")) {
            d.room = $root.streem.api.Room.toObject(m.room, o);
        }
        if (m.invitation != null && m.hasOwnProperty("invitation")) {
            d.invitation = $root.streem.api.RoomInvitation.toObject(m.invitation, o);
        }
        return d;
    };

    /**
     * Converts this StreemCallPayload to JSON.
     * @function toJSON
     * @memberof StreemCallPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StreemCallPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StreemCallPayload
     * @function getTypeUrl
     * @memberof StreemCallPayload
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StreemCallPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreemCallPayload";
    };

    return StreemCallPayload;
})();

$root.streem = (function() {

    /**
     * Namespace streem.
     * @exports streem
     * @namespace
     */
    var streem = {};

    streem.room = (function() {

        /**
         * Namespace room.
         * @memberof streem
         * @namespace
         */
        var room = {};

        room.DataTrackMessage = (function() {

            /**
             * Properties of a DataTrackMessage.
             * @memberof streem.room
             * @interface IDataTrackMessage
             * @property {streem.room.ICameraFrame|null} [cameraFrame] DataTrackMessage cameraFrame
             * @property {streem.room.ILaserUpdate|null} [laserUpdate] DataTrackMessage laserUpdate
             * @property {streem.room.IArTrackables|null} [arTrackables] DataTrackMessage arTrackables
             * @property {streem.room.IArAnchors|null} [arAnchors] DataTrackMessage arAnchors
             * @property {streem.room.IDrawUpdate|null} [drawUpdate] DataTrackMessage drawUpdate
             * @property {streem.room.IArLightEstimate|null} [arLightEstimate] DataTrackMessage arLightEstimate
             * @property {streem.room.IDataTrackPacket|null} [dataTrackPacket] DataTrackMessage dataTrackPacket
             * @property {streem.room.IDataTrackQuality|null} [dataTrackQuality] DataTrackMessage dataTrackQuality
             */

            /**
             * Constructs a new DataTrackMessage.
             * @memberof streem.room
             * @classdesc Represents a DataTrackMessage.
             * @implements IDataTrackMessage
             * @constructor
             * @param {streem.room.IDataTrackMessage=} [p] Properties to set
             */
            function DataTrackMessage(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DataTrackMessage cameraFrame.
             * @member {streem.room.ICameraFrame|null|undefined} cameraFrame
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.cameraFrame = null;

            /**
             * DataTrackMessage laserUpdate.
             * @member {streem.room.ILaserUpdate|null|undefined} laserUpdate
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.laserUpdate = null;

            /**
             * DataTrackMessage arTrackables.
             * @member {streem.room.IArTrackables|null|undefined} arTrackables
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.arTrackables = null;

            /**
             * DataTrackMessage arAnchors.
             * @member {streem.room.IArAnchors|null|undefined} arAnchors
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.arAnchors = null;

            /**
             * DataTrackMessage drawUpdate.
             * @member {streem.room.IDrawUpdate|null|undefined} drawUpdate
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.drawUpdate = null;

            /**
             * DataTrackMessage arLightEstimate.
             * @member {streem.room.IArLightEstimate|null|undefined} arLightEstimate
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.arLightEstimate = null;

            /**
             * DataTrackMessage dataTrackPacket.
             * @member {streem.room.IDataTrackPacket|null|undefined} dataTrackPacket
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.dataTrackPacket = null;

            /**
             * DataTrackMessage dataTrackQuality.
             * @member {streem.room.IDataTrackQuality|null|undefined} dataTrackQuality
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            DataTrackMessage.prototype.dataTrackQuality = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * DataTrackMessage type.
             * @member {"cameraFrame"|"laserUpdate"|"arTrackables"|"arAnchors"|"drawUpdate"|"arLightEstimate"|"dataTrackPacket"|"dataTrackQuality"|undefined} type
             * @memberof streem.room.DataTrackMessage
             * @instance
             */
            Object.defineProperty(DataTrackMessage.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["cameraFrame", "laserUpdate", "arTrackables", "arAnchors", "drawUpdate", "arLightEstimate", "dataTrackPacket", "dataTrackQuality"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new DataTrackMessage instance using the specified properties.
             * @function create
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {streem.room.IDataTrackMessage=} [properties] Properties to set
             * @returns {streem.room.DataTrackMessage} DataTrackMessage instance
             */
            DataTrackMessage.create = function create(properties) {
                return new DataTrackMessage(properties);
            };

            /**
             * Encodes the specified DataTrackMessage message. Does not implicitly {@link streem.room.DataTrackMessage.verify|verify} messages.
             * @function encode
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {streem.room.IDataTrackMessage} m DataTrackMessage message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataTrackMessage.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.cameraFrame != null && Object.hasOwnProperty.call(m, "cameraFrame"))
                    $root.streem.room.CameraFrame.encode(m.cameraFrame, w.uint32(810).fork()).ldelim();
                if (m.laserUpdate != null && Object.hasOwnProperty.call(m, "laserUpdate"))
                    $root.streem.room.LaserUpdate.encode(m.laserUpdate, w.uint32(818).fork()).ldelim();
                if (m.arTrackables != null && Object.hasOwnProperty.call(m, "arTrackables"))
                    $root.streem.room.ArTrackables.encode(m.arTrackables, w.uint32(826).fork()).ldelim();
                if (m.arAnchors != null && Object.hasOwnProperty.call(m, "arAnchors"))
                    $root.streem.room.ArAnchors.encode(m.arAnchors, w.uint32(834).fork()).ldelim();
                if (m.drawUpdate != null && Object.hasOwnProperty.call(m, "drawUpdate"))
                    $root.streem.room.DrawUpdate.encode(m.drawUpdate, w.uint32(842).fork()).ldelim();
                if (m.arLightEstimate != null && Object.hasOwnProperty.call(m, "arLightEstimate"))
                    $root.streem.room.ArLightEstimate.encode(m.arLightEstimate, w.uint32(850).fork()).ldelim();
                if (m.dataTrackPacket != null && Object.hasOwnProperty.call(m, "dataTrackPacket"))
                    $root.streem.room.DataTrackPacket.encode(m.dataTrackPacket, w.uint32(858).fork()).ldelim();
                if (m.dataTrackQuality != null && Object.hasOwnProperty.call(m, "dataTrackQuality"))
                    $root.streem.room.DataTrackQuality.encode(m.dataTrackQuality, w.uint32(866).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a DataTrackMessage message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.DataTrackMessage} DataTrackMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataTrackMessage.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.DataTrackMessage();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 101: {
                            m.cameraFrame = $root.streem.room.CameraFrame.decode(r, r.uint32());
                            break;
                        }
                    case 102: {
                            m.laserUpdate = $root.streem.room.LaserUpdate.decode(r, r.uint32());
                            break;
                        }
                    case 103: {
                            m.arTrackables = $root.streem.room.ArTrackables.decode(r, r.uint32());
                            break;
                        }
                    case 104: {
                            m.arAnchors = $root.streem.room.ArAnchors.decode(r, r.uint32());
                            break;
                        }
                    case 105: {
                            m.drawUpdate = $root.streem.room.DrawUpdate.decode(r, r.uint32());
                            break;
                        }
                    case 106: {
                            m.arLightEstimate = $root.streem.room.ArLightEstimate.decode(r, r.uint32());
                            break;
                        }
                    case 107: {
                            m.dataTrackPacket = $root.streem.room.DataTrackPacket.decode(r, r.uint32());
                            break;
                        }
                    case 108: {
                            m.dataTrackQuality = $root.streem.room.DataTrackQuality.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DataTrackMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.DataTrackMessage} DataTrackMessage
             */
            DataTrackMessage.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.DataTrackMessage)
                    return d;
                var m = new $root.streem.room.DataTrackMessage();
                if (d.cameraFrame != null) {
                    if (typeof d.cameraFrame !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.cameraFrame: object expected");
                    m.cameraFrame = $root.streem.room.CameraFrame.fromObject(d.cameraFrame);
                }
                if (d.laserUpdate != null) {
                    if (typeof d.laserUpdate !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.laserUpdate: object expected");
                    m.laserUpdate = $root.streem.room.LaserUpdate.fromObject(d.laserUpdate);
                }
                if (d.arTrackables != null) {
                    if (typeof d.arTrackables !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.arTrackables: object expected");
                    m.arTrackables = $root.streem.room.ArTrackables.fromObject(d.arTrackables);
                }
                if (d.arAnchors != null) {
                    if (typeof d.arAnchors !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.arAnchors: object expected");
                    m.arAnchors = $root.streem.room.ArAnchors.fromObject(d.arAnchors);
                }
                if (d.drawUpdate != null) {
                    if (typeof d.drawUpdate !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.drawUpdate: object expected");
                    m.drawUpdate = $root.streem.room.DrawUpdate.fromObject(d.drawUpdate);
                }
                if (d.arLightEstimate != null) {
                    if (typeof d.arLightEstimate !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.arLightEstimate: object expected");
                    m.arLightEstimate = $root.streem.room.ArLightEstimate.fromObject(d.arLightEstimate);
                }
                if (d.dataTrackPacket != null) {
                    if (typeof d.dataTrackPacket !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.dataTrackPacket: object expected");
                    m.dataTrackPacket = $root.streem.room.DataTrackPacket.fromObject(d.dataTrackPacket);
                }
                if (d.dataTrackQuality != null) {
                    if (typeof d.dataTrackQuality !== "object")
                        throw TypeError(".streem.room.DataTrackMessage.dataTrackQuality: object expected");
                    m.dataTrackQuality = $root.streem.room.DataTrackQuality.fromObject(d.dataTrackQuality);
                }
                return m;
            };

            /**
             * Creates a plain object from a DataTrackMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {streem.room.DataTrackMessage} m DataTrackMessage
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataTrackMessage.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.cameraFrame != null && m.hasOwnProperty("cameraFrame")) {
                    d.cameraFrame = $root.streem.room.CameraFrame.toObject(m.cameraFrame, o);
                    if (o.oneofs)
                        d.type = "cameraFrame";
                }
                if (m.laserUpdate != null && m.hasOwnProperty("laserUpdate")) {
                    d.laserUpdate = $root.streem.room.LaserUpdate.toObject(m.laserUpdate, o);
                    if (o.oneofs)
                        d.type = "laserUpdate";
                }
                if (m.arTrackables != null && m.hasOwnProperty("arTrackables")) {
                    d.arTrackables = $root.streem.room.ArTrackables.toObject(m.arTrackables, o);
                    if (o.oneofs)
                        d.type = "arTrackables";
                }
                if (m.arAnchors != null && m.hasOwnProperty("arAnchors")) {
                    d.arAnchors = $root.streem.room.ArAnchors.toObject(m.arAnchors, o);
                    if (o.oneofs)
                        d.type = "arAnchors";
                }
                if (m.drawUpdate != null && m.hasOwnProperty("drawUpdate")) {
                    d.drawUpdate = $root.streem.room.DrawUpdate.toObject(m.drawUpdate, o);
                    if (o.oneofs)
                        d.type = "drawUpdate";
                }
                if (m.arLightEstimate != null && m.hasOwnProperty("arLightEstimate")) {
                    d.arLightEstimate = $root.streem.room.ArLightEstimate.toObject(m.arLightEstimate, o);
                    if (o.oneofs)
                        d.type = "arLightEstimate";
                }
                if (m.dataTrackPacket != null && m.hasOwnProperty("dataTrackPacket")) {
                    d.dataTrackPacket = $root.streem.room.DataTrackPacket.toObject(m.dataTrackPacket, o);
                    if (o.oneofs)
                        d.type = "dataTrackPacket";
                }
                if (m.dataTrackQuality != null && m.hasOwnProperty("dataTrackQuality")) {
                    d.dataTrackQuality = $root.streem.room.DataTrackQuality.toObject(m.dataTrackQuality, o);
                    if (o.oneofs)
                        d.type = "dataTrackQuality";
                }
                return d;
            };

            /**
             * Converts this DataTrackMessage to JSON.
             * @function toJSON
             * @memberof streem.room.DataTrackMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataTrackMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataTrackMessage
             * @function getTypeUrl
             * @memberof streem.room.DataTrackMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataTrackMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.DataTrackMessage";
            };

            return DataTrackMessage;
        })();

        room.LaserUpdate = (function() {

            /**
             * Properties of a LaserUpdate.
             * @memberof streem.room
             * @interface ILaserUpdate
             * @property {number|null} [xPercent] LaserUpdate xPercent
             * @property {number|null} [yPercent] LaserUpdate yPercent
             * @property {streem.room.ToolEventType|null} [eventType] LaserUpdate eventType
             */

            /**
             * Constructs a new LaserUpdate.
             * @memberof streem.room
             * @classdesc Represents a LaserUpdate.
             * @implements ILaserUpdate
             * @constructor
             * @param {streem.room.ILaserUpdate=} [p] Properties to set
             */
            function LaserUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LaserUpdate xPercent.
             * @member {number} xPercent
             * @memberof streem.room.LaserUpdate
             * @instance
             */
            LaserUpdate.prototype.xPercent = 0;

            /**
             * LaserUpdate yPercent.
             * @member {number} yPercent
             * @memberof streem.room.LaserUpdate
             * @instance
             */
            LaserUpdate.prototype.yPercent = 0;

            /**
             * LaserUpdate eventType.
             * @member {streem.room.ToolEventType} eventType
             * @memberof streem.room.LaserUpdate
             * @instance
             */
            LaserUpdate.prototype.eventType = 0;

            /**
             * Creates a new LaserUpdate instance using the specified properties.
             * @function create
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {streem.room.ILaserUpdate=} [properties] Properties to set
             * @returns {streem.room.LaserUpdate} LaserUpdate instance
             */
            LaserUpdate.create = function create(properties) {
                return new LaserUpdate(properties);
            };

            /**
             * Encodes the specified LaserUpdate message. Does not implicitly {@link streem.room.LaserUpdate.verify|verify} messages.
             * @function encode
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {streem.room.ILaserUpdate} m LaserUpdate message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LaserUpdate.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.xPercent != null && Object.hasOwnProperty.call(m, "xPercent"))
                    w.uint32(13).float(m.xPercent);
                if (m.yPercent != null && Object.hasOwnProperty.call(m, "yPercent"))
                    w.uint32(21).float(m.yPercent);
                if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                    w.uint32(24).int32(m.eventType);
                return w;
            };

            /**
             * Decodes a LaserUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.LaserUpdate} LaserUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LaserUpdate.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.LaserUpdate();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.xPercent = r.float();
                            break;
                        }
                    case 2: {
                            m.yPercent = r.float();
                            break;
                        }
                    case 3: {
                            m.eventType = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LaserUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.LaserUpdate} LaserUpdate
             */
            LaserUpdate.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.LaserUpdate)
                    return d;
                var m = new $root.streem.room.LaserUpdate();
                if (d.xPercent != null) {
                    m.xPercent = Number(d.xPercent);
                }
                if (d.yPercent != null) {
                    m.yPercent = Number(d.yPercent);
                }
                switch (d.eventType) {
                default:
                    if (typeof d.eventType === "number") {
                        m.eventType = d.eventType;
                        break;
                    }
                    break;
                case "TOOL_EVENT_TYPE_INVALID":
                case 0:
                    m.eventType = 0;
                    break;
                case "TOOL_EVENT_TYPE_MOVE":
                case 1:
                    m.eventType = 1;
                    break;
                case "TOOL_EVENT_TYPE_HIDE":
                case 2:
                    m.eventType = 2;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a LaserUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {streem.room.LaserUpdate} m LaserUpdate
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LaserUpdate.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.xPercent = 0;
                    d.yPercent = 0;
                    d.eventType = o.enums === String ? "TOOL_EVENT_TYPE_INVALID" : 0;
                }
                if (m.xPercent != null && m.hasOwnProperty("xPercent")) {
                    d.xPercent = o.json && !isFinite(m.xPercent) ? String(m.xPercent) : m.xPercent;
                }
                if (m.yPercent != null && m.hasOwnProperty("yPercent")) {
                    d.yPercent = o.json && !isFinite(m.yPercent) ? String(m.yPercent) : m.yPercent;
                }
                if (m.eventType != null && m.hasOwnProperty("eventType")) {
                    d.eventType = o.enums === String ? $root.streem.room.ToolEventType[m.eventType] === undefined ? m.eventType : $root.streem.room.ToolEventType[m.eventType] : m.eventType;
                }
                return d;
            };

            /**
             * Converts this LaserUpdate to JSON.
             * @function toJSON
             * @memberof streem.room.LaserUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LaserUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LaserUpdate
             * @function getTypeUrl
             * @memberof streem.room.LaserUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LaserUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.LaserUpdate";
            };

            return LaserUpdate;
        })();

        room.DrawUpdate = (function() {

            /**
             * Properties of a DrawUpdate.
             * @memberof streem.room
             * @interface IDrawUpdate
             * @property {number|null} [xPercent] DrawUpdate xPercent
             * @property {number|null} [yPercent] DrawUpdate yPercent
             * @property {streem.room.ToolEventType|null} [eventType] DrawUpdate eventType
             */

            /**
             * Constructs a new DrawUpdate.
             * @memberof streem.room
             * @classdesc Represents a DrawUpdate.
             * @implements IDrawUpdate
             * @constructor
             * @param {streem.room.IDrawUpdate=} [p] Properties to set
             */
            function DrawUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DrawUpdate xPercent.
             * @member {number} xPercent
             * @memberof streem.room.DrawUpdate
             * @instance
             */
            DrawUpdate.prototype.xPercent = 0;

            /**
             * DrawUpdate yPercent.
             * @member {number} yPercent
             * @memberof streem.room.DrawUpdate
             * @instance
             */
            DrawUpdate.prototype.yPercent = 0;

            /**
             * DrawUpdate eventType.
             * @member {streem.room.ToolEventType} eventType
             * @memberof streem.room.DrawUpdate
             * @instance
             */
            DrawUpdate.prototype.eventType = 0;

            /**
             * Creates a new DrawUpdate instance using the specified properties.
             * @function create
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {streem.room.IDrawUpdate=} [properties] Properties to set
             * @returns {streem.room.DrawUpdate} DrawUpdate instance
             */
            DrawUpdate.create = function create(properties) {
                return new DrawUpdate(properties);
            };

            /**
             * Encodes the specified DrawUpdate message. Does not implicitly {@link streem.room.DrawUpdate.verify|verify} messages.
             * @function encode
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {streem.room.IDrawUpdate} m DrawUpdate message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DrawUpdate.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.xPercent != null && Object.hasOwnProperty.call(m, "xPercent"))
                    w.uint32(13).float(m.xPercent);
                if (m.yPercent != null && Object.hasOwnProperty.call(m, "yPercent"))
                    w.uint32(21).float(m.yPercent);
                if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                    w.uint32(24).int32(m.eventType);
                return w;
            };

            /**
             * Decodes a DrawUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.DrawUpdate} DrawUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DrawUpdate.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.DrawUpdate();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.xPercent = r.float();
                            break;
                        }
                    case 2: {
                            m.yPercent = r.float();
                            break;
                        }
                    case 3: {
                            m.eventType = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DrawUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.DrawUpdate} DrawUpdate
             */
            DrawUpdate.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.DrawUpdate)
                    return d;
                var m = new $root.streem.room.DrawUpdate();
                if (d.xPercent != null) {
                    m.xPercent = Number(d.xPercent);
                }
                if (d.yPercent != null) {
                    m.yPercent = Number(d.yPercent);
                }
                switch (d.eventType) {
                default:
                    if (typeof d.eventType === "number") {
                        m.eventType = d.eventType;
                        break;
                    }
                    break;
                case "TOOL_EVENT_TYPE_INVALID":
                case 0:
                    m.eventType = 0;
                    break;
                case "TOOL_EVENT_TYPE_MOVE":
                case 1:
                    m.eventType = 1;
                    break;
                case "TOOL_EVENT_TYPE_HIDE":
                case 2:
                    m.eventType = 2;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a DrawUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {streem.room.DrawUpdate} m DrawUpdate
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DrawUpdate.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.xPercent = 0;
                    d.yPercent = 0;
                    d.eventType = o.enums === String ? "TOOL_EVENT_TYPE_INVALID" : 0;
                }
                if (m.xPercent != null && m.hasOwnProperty("xPercent")) {
                    d.xPercent = o.json && !isFinite(m.xPercent) ? String(m.xPercent) : m.xPercent;
                }
                if (m.yPercent != null && m.hasOwnProperty("yPercent")) {
                    d.yPercent = o.json && !isFinite(m.yPercent) ? String(m.yPercent) : m.yPercent;
                }
                if (m.eventType != null && m.hasOwnProperty("eventType")) {
                    d.eventType = o.enums === String ? $root.streem.room.ToolEventType[m.eventType] === undefined ? m.eventType : $root.streem.room.ToolEventType[m.eventType] : m.eventType;
                }
                return d;
            };

            /**
             * Converts this DrawUpdate to JSON.
             * @function toJSON
             * @memberof streem.room.DrawUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DrawUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DrawUpdate
             * @function getTypeUrl
             * @memberof streem.room.DrawUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DrawUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.DrawUpdate";
            };

            return DrawUpdate;
        })();

        /**
         * ToolEventType enum.
         * @name streem.room.ToolEventType
         * @enum {number}
         * @property {number} TOOL_EVENT_TYPE_INVALID=0 TOOL_EVENT_TYPE_INVALID value
         * @property {number} TOOL_EVENT_TYPE_MOVE=1 TOOL_EVENT_TYPE_MOVE value
         * @property {number} TOOL_EVENT_TYPE_HIDE=2 TOOL_EVENT_TYPE_HIDE value
         */
        room.ToolEventType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TOOL_EVENT_TYPE_INVALID"] = 0;
            values[valuesById[1] = "TOOL_EVENT_TYPE_MOVE"] = 1;
            values[valuesById[2] = "TOOL_EVENT_TYPE_HIDE"] = 2;
            return values;
        })();

        room.CameraFrame = (function() {

            /**
             * Properties of a CameraFrame.
             * @memberof streem.room
             * @interface ICameraFrame
             * @property {number|null} [frameNumber] CameraFrame frameNumber
             * @property {Array.<number>|null} [pose] CameraFrame pose
             * @property {Array.<number>|null} [intrinsics] CameraFrame intrinsics
             * @property {ICameraIntrinsics|null} [cameraIntrinsics] CameraFrame cameraIntrinsics
             * @property {streem.api.ISize|null} [cameraDimensions] CameraFrame cameraDimensions
             * @property {streem.room.CameraFrame.FrameSide|null} [imageUpSide] CameraFrame imageUpSide
             * @property {streem.api.IPose|null} [cameraPose] CameraFrame cameraPose
             */

            /**
             * Constructs a new CameraFrame.
             * @memberof streem.room
             * @classdesc Represents a CameraFrame.
             * @implements ICameraFrame
             * @constructor
             * @param {streem.room.ICameraFrame=} [p] Properties to set
             */
            function CameraFrame(p) {
                this.pose = [];
                this.intrinsics = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CameraFrame frameNumber.
             * @member {number} frameNumber
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.frameNumber = 0;

            /**
             * CameraFrame pose.
             * @member {Array.<number>} pose
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.pose = $util.emptyArray;

            /**
             * CameraFrame intrinsics.
             * @member {Array.<number>} intrinsics
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.intrinsics = $util.emptyArray;

            /**
             * CameraFrame cameraIntrinsics.
             * @member {ICameraIntrinsics|null|undefined} cameraIntrinsics
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.cameraIntrinsics = null;

            /**
             * CameraFrame cameraDimensions.
             * @member {streem.api.ISize|null|undefined} cameraDimensions
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.cameraDimensions = null;

            /**
             * CameraFrame imageUpSide.
             * @member {streem.room.CameraFrame.FrameSide} imageUpSide
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.imageUpSide = 0;

            /**
             * CameraFrame cameraPose.
             * @member {streem.api.IPose|null|undefined} cameraPose
             * @memberof streem.room.CameraFrame
             * @instance
             */
            CameraFrame.prototype.cameraPose = null;

            /**
             * Creates a new CameraFrame instance using the specified properties.
             * @function create
             * @memberof streem.room.CameraFrame
             * @static
             * @param {streem.room.ICameraFrame=} [properties] Properties to set
             * @returns {streem.room.CameraFrame} CameraFrame instance
             */
            CameraFrame.create = function create(properties) {
                return new CameraFrame(properties);
            };

            /**
             * Encodes the specified CameraFrame message. Does not implicitly {@link streem.room.CameraFrame.verify|verify} messages.
             * @function encode
             * @memberof streem.room.CameraFrame
             * @static
             * @param {streem.room.ICameraFrame} m CameraFrame message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CameraFrame.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.frameNumber != null && Object.hasOwnProperty.call(m, "frameNumber"))
                    w.uint32(8).uint32(m.frameNumber);
                if (m.pose != null && m.pose.length) {
                    w.uint32(18).fork();
                    for (var i = 0; i < m.pose.length; ++i)
                        w.float(m.pose[i]);
                    w.ldelim();
                }
                if (m.intrinsics != null && m.intrinsics.length) {
                    w.uint32(26).fork();
                    for (var i = 0; i < m.intrinsics.length; ++i)
                        w.float(m.intrinsics[i]);
                    w.ldelim();
                }
                if (m.cameraIntrinsics != null && Object.hasOwnProperty.call(m, "cameraIntrinsics"))
                    $root.CameraIntrinsics.encode(m.cameraIntrinsics, w.uint32(34).fork()).ldelim();
                if (m.cameraDimensions != null && Object.hasOwnProperty.call(m, "cameraDimensions"))
                    $root.streem.api.Size.encode(m.cameraDimensions, w.uint32(42).fork()).ldelim();
                if (m.imageUpSide != null && Object.hasOwnProperty.call(m, "imageUpSide"))
                    w.uint32(48).int32(m.imageUpSide);
                if (m.cameraPose != null && Object.hasOwnProperty.call(m, "cameraPose"))
                    $root.streem.api.Pose.encode(m.cameraPose, w.uint32(58).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CameraFrame message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.CameraFrame
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.CameraFrame} CameraFrame
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CameraFrame.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.CameraFrame();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.frameNumber = r.uint32();
                            break;
                        }
                    case 2: {
                            if (!(m.pose && m.pose.length))
                                m.pose = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.pose.push(r.float());
                            } else
                                m.pose.push(r.float());
                            break;
                        }
                    case 3: {
                            if (!(m.intrinsics && m.intrinsics.length))
                                m.intrinsics = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.intrinsics.push(r.float());
                            } else
                                m.intrinsics.push(r.float());
                            break;
                        }
                    case 4: {
                            m.cameraIntrinsics = $root.CameraIntrinsics.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.cameraDimensions = $root.streem.api.Size.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.imageUpSide = r.int32();
                            break;
                        }
                    case 7: {
                            m.cameraPose = $root.streem.api.Pose.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CameraFrame message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.CameraFrame
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.CameraFrame} CameraFrame
             */
            CameraFrame.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.CameraFrame)
                    return d;
                var m = new $root.streem.room.CameraFrame();
                if (d.frameNumber != null) {
                    m.frameNumber = d.frameNumber >>> 0;
                }
                if (d.pose) {
                    if (!Array.isArray(d.pose))
                        throw TypeError(".streem.room.CameraFrame.pose: array expected");
                    m.pose = [];
                    for (var i = 0; i < d.pose.length; ++i) {
                        m.pose[i] = Number(d.pose[i]);
                    }
                }
                if (d.intrinsics) {
                    if (!Array.isArray(d.intrinsics))
                        throw TypeError(".streem.room.CameraFrame.intrinsics: array expected");
                    m.intrinsics = [];
                    for (var i = 0; i < d.intrinsics.length; ++i) {
                        m.intrinsics[i] = Number(d.intrinsics[i]);
                    }
                }
                if (d.cameraIntrinsics != null) {
                    if (typeof d.cameraIntrinsics !== "object")
                        throw TypeError(".streem.room.CameraFrame.cameraIntrinsics: object expected");
                    m.cameraIntrinsics = $root.CameraIntrinsics.fromObject(d.cameraIntrinsics);
                }
                if (d.cameraDimensions != null) {
                    if (typeof d.cameraDimensions !== "object")
                        throw TypeError(".streem.room.CameraFrame.cameraDimensions: object expected");
                    m.cameraDimensions = $root.streem.api.Size.fromObject(d.cameraDimensions);
                }
                switch (d.imageUpSide) {
                default:
                    if (typeof d.imageUpSide === "number") {
                        m.imageUpSide = d.imageUpSide;
                        break;
                    }
                    break;
                case "UNSPECIFIED":
                case 0:
                    m.imageUpSide = 0;
                    break;
                case "TOP":
                case 1:
                    m.imageUpSide = 1;
                    break;
                case "LEFT":
                case 2:
                    m.imageUpSide = 2;
                    break;
                case "BOTTOM":
                case 3:
                    m.imageUpSide = 3;
                    break;
                case "RIGHT":
                case 4:
                    m.imageUpSide = 4;
                    break;
                }
                if (d.cameraPose != null) {
                    if (typeof d.cameraPose !== "object")
                        throw TypeError(".streem.room.CameraFrame.cameraPose: object expected");
                    m.cameraPose = $root.streem.api.Pose.fromObject(d.cameraPose);
                }
                return m;
            };

            /**
             * Creates a plain object from a CameraFrame message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.CameraFrame
             * @static
             * @param {streem.room.CameraFrame} m CameraFrame
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CameraFrame.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.pose = [];
                    d.intrinsics = [];
                }
                if (o.defaults) {
                    d.frameNumber = 0;
                    d.cameraIntrinsics = null;
                    d.cameraDimensions = null;
                    d.imageUpSide = o.enums === String ? "UNSPECIFIED" : 0;
                    d.cameraPose = null;
                }
                if (m.frameNumber != null && m.hasOwnProperty("frameNumber")) {
                    d.frameNumber = m.frameNumber;
                }
                if (m.pose && m.pose.length) {
                    d.pose = [];
                    for (var j = 0; j < m.pose.length; ++j) {
                        d.pose[j] = o.json && !isFinite(m.pose[j]) ? String(m.pose[j]) : m.pose[j];
                    }
                }
                if (m.intrinsics && m.intrinsics.length) {
                    d.intrinsics = [];
                    for (var j = 0; j < m.intrinsics.length; ++j) {
                        d.intrinsics[j] = o.json && !isFinite(m.intrinsics[j]) ? String(m.intrinsics[j]) : m.intrinsics[j];
                    }
                }
                if (m.cameraIntrinsics != null && m.hasOwnProperty("cameraIntrinsics")) {
                    d.cameraIntrinsics = $root.CameraIntrinsics.toObject(m.cameraIntrinsics, o);
                }
                if (m.cameraDimensions != null && m.hasOwnProperty("cameraDimensions")) {
                    d.cameraDimensions = $root.streem.api.Size.toObject(m.cameraDimensions, o);
                }
                if (m.imageUpSide != null && m.hasOwnProperty("imageUpSide")) {
                    d.imageUpSide = o.enums === String ? $root.streem.room.CameraFrame.FrameSide[m.imageUpSide] === undefined ? m.imageUpSide : $root.streem.room.CameraFrame.FrameSide[m.imageUpSide] : m.imageUpSide;
                }
                if (m.cameraPose != null && m.hasOwnProperty("cameraPose")) {
                    d.cameraPose = $root.streem.api.Pose.toObject(m.cameraPose, o);
                }
                return d;
            };

            /**
             * Converts this CameraFrame to JSON.
             * @function toJSON
             * @memberof streem.room.CameraFrame
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CameraFrame.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CameraFrame
             * @function getTypeUrl
             * @memberof streem.room.CameraFrame
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CameraFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.CameraFrame";
            };

            /**
             * FrameSide enum.
             * @name streem.room.CameraFrame.FrameSide
             * @enum {number}
             * @property {number} UNSPECIFIED=0 UNSPECIFIED value
             * @property {number} TOP=1 TOP value
             * @property {number} LEFT=2 LEFT value
             * @property {number} BOTTOM=3 BOTTOM value
             * @property {number} RIGHT=4 RIGHT value
             */
            CameraFrame.FrameSide = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNSPECIFIED"] = 0;
                values[valuesById[1] = "TOP"] = 1;
                values[valuesById[2] = "LEFT"] = 2;
                values[valuesById[3] = "BOTTOM"] = 3;
                values[valuesById[4] = "RIGHT"] = 4;
                return values;
            })();

            return CameraFrame;
        })();

        room.ArTrackables = (function() {

            /**
             * Properties of an ArTrackables.
             * @memberof streem.room
             * @interface IArTrackables
             * @property {Array.<streem.room.ar.ITrackable>|null} [trackables] ArTrackables trackables
             */

            /**
             * Constructs a new ArTrackables.
             * @memberof streem.room
             * @classdesc Represents an ArTrackables.
             * @implements IArTrackables
             * @constructor
             * @param {streem.room.IArTrackables=} [p] Properties to set
             */
            function ArTrackables(p) {
                this.trackables = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArTrackables trackables.
             * @member {Array.<streem.room.ar.ITrackable>} trackables
             * @memberof streem.room.ArTrackables
             * @instance
             */
            ArTrackables.prototype.trackables = $util.emptyArray;

            /**
             * Creates a new ArTrackables instance using the specified properties.
             * @function create
             * @memberof streem.room.ArTrackables
             * @static
             * @param {streem.room.IArTrackables=} [properties] Properties to set
             * @returns {streem.room.ArTrackables} ArTrackables instance
             */
            ArTrackables.create = function create(properties) {
                return new ArTrackables(properties);
            };

            /**
             * Encodes the specified ArTrackables message. Does not implicitly {@link streem.room.ArTrackables.verify|verify} messages.
             * @function encode
             * @memberof streem.room.ArTrackables
             * @static
             * @param {streem.room.IArTrackables} m ArTrackables message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArTrackables.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.trackables != null && m.trackables.length) {
                    for (var i = 0; i < m.trackables.length; ++i)
                        $root.streem.room.ar.Trackable.encode(m.trackables[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an ArTrackables message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.ArTrackables
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.ArTrackables} ArTrackables
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArTrackables.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ArTrackables();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.trackables && m.trackables.length))
                                m.trackables = [];
                            m.trackables.push($root.streem.room.ar.Trackable.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArTrackables message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.ArTrackables
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.ArTrackables} ArTrackables
             */
            ArTrackables.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.ArTrackables)
                    return d;
                var m = new $root.streem.room.ArTrackables();
                if (d.trackables) {
                    if (!Array.isArray(d.trackables))
                        throw TypeError(".streem.room.ArTrackables.trackables: array expected");
                    m.trackables = [];
                    for (var i = 0; i < d.trackables.length; ++i) {
                        if (typeof d.trackables[i] !== "object")
                            throw TypeError(".streem.room.ArTrackables.trackables: object expected");
                        m.trackables[i] = $root.streem.room.ar.Trackable.fromObject(d.trackables[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an ArTrackables message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.ArTrackables
             * @static
             * @param {streem.room.ArTrackables} m ArTrackables
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArTrackables.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.trackables = [];
                }
                if (m.trackables && m.trackables.length) {
                    d.trackables = [];
                    for (var j = 0; j < m.trackables.length; ++j) {
                        d.trackables[j] = $root.streem.room.ar.Trackable.toObject(m.trackables[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ArTrackables to JSON.
             * @function toJSON
             * @memberof streem.room.ArTrackables
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArTrackables.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArTrackables
             * @function getTypeUrl
             * @memberof streem.room.ArTrackables
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArTrackables.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.ArTrackables";
            };

            return ArTrackables;
        })();

        room.ArAnchors = (function() {

            /**
             * Properties of an ArAnchors.
             * @memberof streem.room
             * @interface IArAnchors
             * @property {Array.<streem.room.ar.IAnchor>|null} [anchors] ArAnchors anchors
             */

            /**
             * Constructs a new ArAnchors.
             * @memberof streem.room
             * @classdesc Represents an ArAnchors.
             * @implements IArAnchors
             * @constructor
             * @param {streem.room.IArAnchors=} [p] Properties to set
             */
            function ArAnchors(p) {
                this.anchors = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArAnchors anchors.
             * @member {Array.<streem.room.ar.IAnchor>} anchors
             * @memberof streem.room.ArAnchors
             * @instance
             */
            ArAnchors.prototype.anchors = $util.emptyArray;

            /**
             * Creates a new ArAnchors instance using the specified properties.
             * @function create
             * @memberof streem.room.ArAnchors
             * @static
             * @param {streem.room.IArAnchors=} [properties] Properties to set
             * @returns {streem.room.ArAnchors} ArAnchors instance
             */
            ArAnchors.create = function create(properties) {
                return new ArAnchors(properties);
            };

            /**
             * Encodes the specified ArAnchors message. Does not implicitly {@link streem.room.ArAnchors.verify|verify} messages.
             * @function encode
             * @memberof streem.room.ArAnchors
             * @static
             * @param {streem.room.IArAnchors} m ArAnchors message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArAnchors.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.anchors != null && m.anchors.length) {
                    for (var i = 0; i < m.anchors.length; ++i)
                        $root.streem.room.ar.Anchor.encode(m.anchors[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an ArAnchors message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.ArAnchors
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.ArAnchors} ArAnchors
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArAnchors.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ArAnchors();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            if (!(m.anchors && m.anchors.length))
                                m.anchors = [];
                            m.anchors.push($root.streem.room.ar.Anchor.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArAnchors message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.ArAnchors
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.ArAnchors} ArAnchors
             */
            ArAnchors.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.ArAnchors)
                    return d;
                var m = new $root.streem.room.ArAnchors();
                if (d.anchors) {
                    if (!Array.isArray(d.anchors))
                        throw TypeError(".streem.room.ArAnchors.anchors: array expected");
                    m.anchors = [];
                    for (var i = 0; i < d.anchors.length; ++i) {
                        if (typeof d.anchors[i] !== "object")
                            throw TypeError(".streem.room.ArAnchors.anchors: object expected");
                        m.anchors[i] = $root.streem.room.ar.Anchor.fromObject(d.anchors[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an ArAnchors message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.ArAnchors
             * @static
             * @param {streem.room.ArAnchors} m ArAnchors
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArAnchors.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.anchors = [];
                }
                if (m.anchors && m.anchors.length) {
                    d.anchors = [];
                    for (var j = 0; j < m.anchors.length; ++j) {
                        d.anchors[j] = $root.streem.room.ar.Anchor.toObject(m.anchors[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ArAnchors to JSON.
             * @function toJSON
             * @memberof streem.room.ArAnchors
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArAnchors.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArAnchors
             * @function getTypeUrl
             * @memberof streem.room.ArAnchors
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArAnchors.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.ArAnchors";
            };

            return ArAnchors;
        })();

        room.ArLightEstimate = (function() {

            /**
             * Properties of an ArLightEstimate.
             * @memberof streem.room
             * @interface IArLightEstimate
             * @property {streem.room.ArLightEstimate.IAmbient|null} [ambient] ArLightEstimate ambient
             */

            /**
             * Constructs a new ArLightEstimate.
             * @memberof streem.room
             * @classdesc Represents an ArLightEstimate.
             * @implements IArLightEstimate
             * @constructor
             * @param {streem.room.IArLightEstimate=} [p] Properties to set
             */
            function ArLightEstimate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArLightEstimate ambient.
             * @member {streem.room.ArLightEstimate.IAmbient|null|undefined} ambient
             * @memberof streem.room.ArLightEstimate
             * @instance
             */
            ArLightEstimate.prototype.ambient = null;

            /**
             * Creates a new ArLightEstimate instance using the specified properties.
             * @function create
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {streem.room.IArLightEstimate=} [properties] Properties to set
             * @returns {streem.room.ArLightEstimate} ArLightEstimate instance
             */
            ArLightEstimate.create = function create(properties) {
                return new ArLightEstimate(properties);
            };

            /**
             * Encodes the specified ArLightEstimate message. Does not implicitly {@link streem.room.ArLightEstimate.verify|verify} messages.
             * @function encode
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {streem.room.IArLightEstimate} m ArLightEstimate message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArLightEstimate.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ambient != null && Object.hasOwnProperty.call(m, "ambient"))
                    $root.streem.room.ArLightEstimate.Ambient.encode(m.ambient, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an ArLightEstimate message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.ArLightEstimate} ArLightEstimate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArLightEstimate.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ArLightEstimate();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ambient = $root.streem.room.ArLightEstimate.Ambient.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArLightEstimate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.ArLightEstimate} ArLightEstimate
             */
            ArLightEstimate.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.ArLightEstimate)
                    return d;
                var m = new $root.streem.room.ArLightEstimate();
                if (d.ambient != null) {
                    if (typeof d.ambient !== "object")
                        throw TypeError(".streem.room.ArLightEstimate.ambient: object expected");
                    m.ambient = $root.streem.room.ArLightEstimate.Ambient.fromObject(d.ambient);
                }
                return m;
            };

            /**
             * Creates a plain object from an ArLightEstimate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {streem.room.ArLightEstimate} m ArLightEstimate
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArLightEstimate.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ambient = null;
                }
                if (m.ambient != null && m.hasOwnProperty("ambient")) {
                    d.ambient = $root.streem.room.ArLightEstimate.Ambient.toObject(m.ambient, o);
                }
                return d;
            };

            /**
             * Converts this ArLightEstimate to JSON.
             * @function toJSON
             * @memberof streem.room.ArLightEstimate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArLightEstimate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArLightEstimate
             * @function getTypeUrl
             * @memberof streem.room.ArLightEstimate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArLightEstimate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.ArLightEstimate";
            };

            ArLightEstimate.Ambient = (function() {

                /**
                 * Properties of an Ambient.
                 * @memberof streem.room.ArLightEstimate
                 * @interface IAmbient
                 * @property {number|null} [intensityLumens] Ambient intensityLumens
                 * @property {number|null} [colorTemperatureKelvins] Ambient colorTemperatureKelvins
                 */

                /**
                 * Constructs a new Ambient.
                 * @memberof streem.room.ArLightEstimate
                 * @classdesc Represents an Ambient.
                 * @implements IAmbient
                 * @constructor
                 * @param {streem.room.ArLightEstimate.IAmbient=} [p] Properties to set
                 */
                function Ambient(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Ambient intensityLumens.
                 * @member {number} intensityLumens
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @instance
                 */
                Ambient.prototype.intensityLumens = 0;

                /**
                 * Ambient colorTemperatureKelvins.
                 * @member {number} colorTemperatureKelvins
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @instance
                 */
                Ambient.prototype.colorTemperatureKelvins = 0;

                /**
                 * Creates a new Ambient instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {streem.room.ArLightEstimate.IAmbient=} [properties] Properties to set
                 * @returns {streem.room.ArLightEstimate.Ambient} Ambient instance
                 */
                Ambient.create = function create(properties) {
                    return new Ambient(properties);
                };

                /**
                 * Encodes the specified Ambient message. Does not implicitly {@link streem.room.ArLightEstimate.Ambient.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {streem.room.ArLightEstimate.IAmbient} m Ambient message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Ambient.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.intensityLumens != null && Object.hasOwnProperty.call(m, "intensityLumens"))
                        w.uint32(13).float(m.intensityLumens);
                    if (m.colorTemperatureKelvins != null && Object.hasOwnProperty.call(m, "colorTemperatureKelvins"))
                        w.uint32(21).float(m.colorTemperatureKelvins);
                    return w;
                };

                /**
                 * Decodes an Ambient message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ArLightEstimate.Ambient} Ambient
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Ambient.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ArLightEstimate.Ambient();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.intensityLumens = r.float();
                                break;
                            }
                        case 2: {
                                m.colorTemperatureKelvins = r.float();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Ambient message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ArLightEstimate.Ambient} Ambient
                 */
                Ambient.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ArLightEstimate.Ambient)
                        return d;
                    var m = new $root.streem.room.ArLightEstimate.Ambient();
                    if (d.intensityLumens != null) {
                        m.intensityLumens = Number(d.intensityLumens);
                    }
                    if (d.colorTemperatureKelvins != null) {
                        m.colorTemperatureKelvins = Number(d.colorTemperatureKelvins);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Ambient message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {streem.room.ArLightEstimate.Ambient} m Ambient
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Ambient.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.intensityLumens = 0;
                        d.colorTemperatureKelvins = 0;
                    }
                    if (m.intensityLumens != null && m.hasOwnProperty("intensityLumens")) {
                        d.intensityLumens = o.json && !isFinite(m.intensityLumens) ? String(m.intensityLumens) : m.intensityLumens;
                    }
                    if (m.colorTemperatureKelvins != null && m.hasOwnProperty("colorTemperatureKelvins")) {
                        d.colorTemperatureKelvins = o.json && !isFinite(m.colorTemperatureKelvins) ? String(m.colorTemperatureKelvins) : m.colorTemperatureKelvins;
                    }
                    return d;
                };

                /**
                 * Converts this Ambient to JSON.
                 * @function toJSON
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Ambient.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Ambient
                 * @function getTypeUrl
                 * @memberof streem.room.ArLightEstimate.Ambient
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Ambient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ArLightEstimate.Ambient";
                };

                return Ambient;
            })();

            return ArLightEstimate;
        })();

        room.DataTrackPacket = (function() {

            /**
             * Properties of a DataTrackPacket.
             * @memberof streem.room
             * @interface IDataTrackPacket
             * @property {streem.room.DataTrackPacket.Type|null} [type] DataTrackPacket type
             * @property {streem.room.DataTrackPacket.Compression|null} [compression] DataTrackPacket compression
             * @property {number|null} [messageId] DataTrackPacket messageId
             * @property {number|null} [numberOfPackets] DataTrackPacket numberOfPackets
             * @property {number|null} [index] DataTrackPacket index
             * @property {Uint8Array|null} [data] DataTrackPacket data
             */

            /**
             * Constructs a new DataTrackPacket.
             * @memberof streem.room
             * @classdesc Represents a DataTrackPacket.
             * @implements IDataTrackPacket
             * @constructor
             * @param {streem.room.IDataTrackPacket=} [p] Properties to set
             */
            function DataTrackPacket(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DataTrackPacket type.
             * @member {streem.room.DataTrackPacket.Type} type
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.type = 0;

            /**
             * DataTrackPacket compression.
             * @member {streem.room.DataTrackPacket.Compression} compression
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.compression = 0;

            /**
             * DataTrackPacket messageId.
             * @member {number} messageId
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.messageId = 0;

            /**
             * DataTrackPacket numberOfPackets.
             * @member {number} numberOfPackets
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.numberOfPackets = 0;

            /**
             * DataTrackPacket index.
             * @member {number} index
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.index = 0;

            /**
             * DataTrackPacket data.
             * @member {Uint8Array} data
             * @memberof streem.room.DataTrackPacket
             * @instance
             */
            DataTrackPacket.prototype.data = $util.newBuffer([]);

            /**
             * Creates a new DataTrackPacket instance using the specified properties.
             * @function create
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {streem.room.IDataTrackPacket=} [properties] Properties to set
             * @returns {streem.room.DataTrackPacket} DataTrackPacket instance
             */
            DataTrackPacket.create = function create(properties) {
                return new DataTrackPacket(properties);
            };

            /**
             * Encodes the specified DataTrackPacket message. Does not implicitly {@link streem.room.DataTrackPacket.verify|verify} messages.
             * @function encode
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {streem.room.IDataTrackPacket} m DataTrackPacket message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataTrackPacket.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(8).int32(m.type);
                if (m.compression != null && Object.hasOwnProperty.call(m, "compression"))
                    w.uint32(16).int32(m.compression);
                if (m.messageId != null && Object.hasOwnProperty.call(m, "messageId"))
                    w.uint32(24).int32(m.messageId);
                if (m.numberOfPackets != null && Object.hasOwnProperty.call(m, "numberOfPackets"))
                    w.uint32(32).int32(m.numberOfPackets);
                if (m.index != null && Object.hasOwnProperty.call(m, "index"))
                    w.uint32(40).int32(m.index);
                if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                    w.uint32(50).bytes(m.data);
                return w;
            };

            /**
             * Decodes a DataTrackPacket message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.DataTrackPacket} DataTrackPacket
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataTrackPacket.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.DataTrackPacket();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.type = r.int32();
                            break;
                        }
                    case 2: {
                            m.compression = r.int32();
                            break;
                        }
                    case 3: {
                            m.messageId = r.int32();
                            break;
                        }
                    case 4: {
                            m.numberOfPackets = r.int32();
                            break;
                        }
                    case 5: {
                            m.index = r.int32();
                            break;
                        }
                    case 6: {
                            m.data = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DataTrackPacket message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.DataTrackPacket} DataTrackPacket
             */
            DataTrackPacket.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.DataTrackPacket)
                    return d;
                var m = new $root.streem.room.DataTrackPacket();
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "TYPE_UNKNOWN":
                case 0:
                    m.type = 0;
                    break;
                case "TYPE_CAMERA_FRAME":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_LASER_UPDATE":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_AR_ANCHORS":
                case 3:
                    m.type = 3;
                    break;
                case "TYPE_DRAW_UPDATE":
                case 4:
                    m.type = 4;
                    break;
                case "TYPE_AR_LIGHT_ESTIMATE":
                case 5:
                    m.type = 5;
                    break;
                case "TYPE_AR_TRACKABLE_MARKER2D":
                case 6:
                    m.type = 6;
                    break;
                case "TYPE_AR_TRACKABLE_MARKER3D":
                case 7:
                    m.type = 7;
                    break;
                case "TYPE_AR_TRACKABLE_PLANE":
                case 8:
                    m.type = 8;
                    break;
                case "TYPE_AR_TRACKABLE_FEATUREPOINT":
                case 9:
                    m.type = 9;
                    break;
                case "TYPE_AR_TRACKABLE_MESH":
                case 10:
                    m.type = 10;
                    break;
                case "TYPE_DATA_TRACK_QUALITY":
                case 11:
                    m.type = 11;
                    break;
                }
                switch (d.compression) {
                default:
                    if (typeof d.compression === "number") {
                        m.compression = d.compression;
                        break;
                    }
                    break;
                case "COMPRESSION_NONE":
                case 0:
                    m.compression = 0;
                    break;
                case "COMPRESSION_GZIP":
                case 1:
                    m.compression = 1;
                    break;
                }
                if (d.messageId != null) {
                    m.messageId = d.messageId | 0;
                }
                if (d.numberOfPackets != null) {
                    m.numberOfPackets = d.numberOfPackets | 0;
                }
                if (d.index != null) {
                    m.index = d.index | 0;
                }
                if (d.data != null) {
                    if (typeof d.data === "string")
                        $util.base64.decode(d.data, m.data = $util.newBuffer($util.base64.length(d.data)), 0);
                    else if (d.data.length >= 0)
                        m.data = d.data;
                }
                return m;
            };

            /**
             * Creates a plain object from a DataTrackPacket message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {streem.room.DataTrackPacket} m DataTrackPacket
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataTrackPacket.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "TYPE_UNKNOWN" : 0;
                    d.compression = o.enums === String ? "COMPRESSION_NONE" : 0;
                    d.messageId = 0;
                    d.numberOfPackets = 0;
                    d.index = 0;
                    if (o.bytes === String)
                        d.data = "";
                    else {
                        d.data = [];
                        if (o.bytes !== Array)
                            d.data = $util.newBuffer(d.data);
                    }
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.room.DataTrackPacket.Type[m.type] === undefined ? m.type : $root.streem.room.DataTrackPacket.Type[m.type] : m.type;
                }
                if (m.compression != null && m.hasOwnProperty("compression")) {
                    d.compression = o.enums === String ? $root.streem.room.DataTrackPacket.Compression[m.compression] === undefined ? m.compression : $root.streem.room.DataTrackPacket.Compression[m.compression] : m.compression;
                }
                if (m.messageId != null && m.hasOwnProperty("messageId")) {
                    d.messageId = m.messageId;
                }
                if (m.numberOfPackets != null && m.hasOwnProperty("numberOfPackets")) {
                    d.numberOfPackets = m.numberOfPackets;
                }
                if (m.index != null && m.hasOwnProperty("index")) {
                    d.index = m.index;
                }
                if (m.data != null && m.hasOwnProperty("data")) {
                    d.data = o.bytes === String ? $util.base64.encode(m.data, 0, m.data.length) : o.bytes === Array ? Array.prototype.slice.call(m.data) : m.data;
                }
                return d;
            };

            /**
             * Converts this DataTrackPacket to JSON.
             * @function toJSON
             * @memberof streem.room.DataTrackPacket
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataTrackPacket.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataTrackPacket
             * @function getTypeUrl
             * @memberof streem.room.DataTrackPacket
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataTrackPacket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.DataTrackPacket";
            };

            /**
             * Type enum.
             * @name streem.room.DataTrackPacket.Type
             * @enum {number}
             * @property {number} TYPE_UNKNOWN=0 TYPE_UNKNOWN value
             * @property {number} TYPE_CAMERA_FRAME=1 TYPE_CAMERA_FRAME value
             * @property {number} TYPE_LASER_UPDATE=2 TYPE_LASER_UPDATE value
             * @property {number} TYPE_AR_ANCHORS=3 TYPE_AR_ANCHORS value
             * @property {number} TYPE_DRAW_UPDATE=4 TYPE_DRAW_UPDATE value
             * @property {number} TYPE_AR_LIGHT_ESTIMATE=5 TYPE_AR_LIGHT_ESTIMATE value
             * @property {number} TYPE_AR_TRACKABLE_MARKER2D=6 TYPE_AR_TRACKABLE_MARKER2D value
             * @property {number} TYPE_AR_TRACKABLE_MARKER3D=7 TYPE_AR_TRACKABLE_MARKER3D value
             * @property {number} TYPE_AR_TRACKABLE_PLANE=8 TYPE_AR_TRACKABLE_PLANE value
             * @property {number} TYPE_AR_TRACKABLE_FEATUREPOINT=9 TYPE_AR_TRACKABLE_FEATUREPOINT value
             * @property {number} TYPE_AR_TRACKABLE_MESH=10 TYPE_AR_TRACKABLE_MESH value
             * @property {number} TYPE_DATA_TRACK_QUALITY=11 TYPE_DATA_TRACK_QUALITY value
             */
            DataTrackPacket.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "TYPE_CAMERA_FRAME"] = 1;
                values[valuesById[2] = "TYPE_LASER_UPDATE"] = 2;
                values[valuesById[3] = "TYPE_AR_ANCHORS"] = 3;
                values[valuesById[4] = "TYPE_DRAW_UPDATE"] = 4;
                values[valuesById[5] = "TYPE_AR_LIGHT_ESTIMATE"] = 5;
                values[valuesById[6] = "TYPE_AR_TRACKABLE_MARKER2D"] = 6;
                values[valuesById[7] = "TYPE_AR_TRACKABLE_MARKER3D"] = 7;
                values[valuesById[8] = "TYPE_AR_TRACKABLE_PLANE"] = 8;
                values[valuesById[9] = "TYPE_AR_TRACKABLE_FEATUREPOINT"] = 9;
                values[valuesById[10] = "TYPE_AR_TRACKABLE_MESH"] = 10;
                values[valuesById[11] = "TYPE_DATA_TRACK_QUALITY"] = 11;
                return values;
            })();

            /**
             * Compression enum.
             * @name streem.room.DataTrackPacket.Compression
             * @enum {number}
             * @property {number} COMPRESSION_NONE=0 COMPRESSION_NONE value
             * @property {number} COMPRESSION_GZIP=1 COMPRESSION_GZIP value
             */
            DataTrackPacket.Compression = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "COMPRESSION_NONE"] = 0;
                values[valuesById[1] = "COMPRESSION_GZIP"] = 1;
                return values;
            })();

            return DataTrackPacket;
        })();

        room.DataTrackQuality = (function() {

            /**
             * Properties of a DataTrackQuality.
             * @memberof streem.room
             * @interface IDataTrackQuality
             * @property {streem.room.DataTrackQuality.QualityLevel|null} [qualityLevel] DataTrackQuality qualityLevel
             */

            /**
             * Constructs a new DataTrackQuality.
             * @memberof streem.room
             * @classdesc Represents a DataTrackQuality.
             * @implements IDataTrackQuality
             * @constructor
             * @param {streem.room.IDataTrackQuality=} [p] Properties to set
             */
            function DataTrackQuality(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DataTrackQuality qualityLevel.
             * @member {streem.room.DataTrackQuality.QualityLevel} qualityLevel
             * @memberof streem.room.DataTrackQuality
             * @instance
             */
            DataTrackQuality.prototype.qualityLevel = 0;

            /**
             * Creates a new DataTrackQuality instance using the specified properties.
             * @function create
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {streem.room.IDataTrackQuality=} [properties] Properties to set
             * @returns {streem.room.DataTrackQuality} DataTrackQuality instance
             */
            DataTrackQuality.create = function create(properties) {
                return new DataTrackQuality(properties);
            };

            /**
             * Encodes the specified DataTrackQuality message. Does not implicitly {@link streem.room.DataTrackQuality.verify|verify} messages.
             * @function encode
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {streem.room.IDataTrackQuality} m DataTrackQuality message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataTrackQuality.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.qualityLevel != null && Object.hasOwnProperty.call(m, "qualityLevel"))
                    w.uint32(8).int32(m.qualityLevel);
                return w;
            };

            /**
             * Decodes a DataTrackQuality message from the specified reader or buffer.
             * @function decode
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.room.DataTrackQuality} DataTrackQuality
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataTrackQuality.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.DataTrackQuality();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.qualityLevel = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DataTrackQuality message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.room.DataTrackQuality} DataTrackQuality
             */
            DataTrackQuality.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.room.DataTrackQuality)
                    return d;
                var m = new $root.streem.room.DataTrackQuality();
                switch (d.qualityLevel) {
                default:
                    if (typeof d.qualityLevel === "number") {
                        m.qualityLevel = d.qualityLevel;
                        break;
                    }
                    break;
                case "QUALITY_LEVEL_UNKNOWN":
                case 0:
                    m.qualityLevel = 0;
                    break;
                case "QUALITY_LEVEL_VERY_BAD":
                case 1:
                    m.qualityLevel = 1;
                    break;
                case "QUALITY_LEVEL_BAD":
                case 2:
                    m.qualityLevel = 2;
                    break;
                case "QUALITY_LEVEL_GOOD":
                case 3:
                    m.qualityLevel = 3;
                    break;
                case "QUALITY_LEVEL_VERY_GOOD":
                case 4:
                    m.qualityLevel = 4;
                    break;
                case "QUALITY_LEVEL_EXCELLENT":
                case 5:
                    m.qualityLevel = 5;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a DataTrackQuality message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {streem.room.DataTrackQuality} m DataTrackQuality
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataTrackQuality.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.qualityLevel = o.enums === String ? "QUALITY_LEVEL_UNKNOWN" : 0;
                }
                if (m.qualityLevel != null && m.hasOwnProperty("qualityLevel")) {
                    d.qualityLevel = o.enums === String ? $root.streem.room.DataTrackQuality.QualityLevel[m.qualityLevel] === undefined ? m.qualityLevel : $root.streem.room.DataTrackQuality.QualityLevel[m.qualityLevel] : m.qualityLevel;
                }
                return d;
            };

            /**
             * Converts this DataTrackQuality to JSON.
             * @function toJSON
             * @memberof streem.room.DataTrackQuality
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataTrackQuality.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataTrackQuality
             * @function getTypeUrl
             * @memberof streem.room.DataTrackQuality
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataTrackQuality.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.room.DataTrackQuality";
            };

            /**
             * QualityLevel enum.
             * @name streem.room.DataTrackQuality.QualityLevel
             * @enum {number}
             * @property {number} QUALITY_LEVEL_UNKNOWN=0 QUALITY_LEVEL_UNKNOWN value
             * @property {number} QUALITY_LEVEL_VERY_BAD=1 QUALITY_LEVEL_VERY_BAD value
             * @property {number} QUALITY_LEVEL_BAD=2 QUALITY_LEVEL_BAD value
             * @property {number} QUALITY_LEVEL_GOOD=3 QUALITY_LEVEL_GOOD value
             * @property {number} QUALITY_LEVEL_VERY_GOOD=4 QUALITY_LEVEL_VERY_GOOD value
             * @property {number} QUALITY_LEVEL_EXCELLENT=5 QUALITY_LEVEL_EXCELLENT value
             */
            DataTrackQuality.QualityLevel = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "QUALITY_LEVEL_UNKNOWN"] = 0;
                values[valuesById[1] = "QUALITY_LEVEL_VERY_BAD"] = 1;
                values[valuesById[2] = "QUALITY_LEVEL_BAD"] = 2;
                values[valuesById[3] = "QUALITY_LEVEL_GOOD"] = 3;
                values[valuesById[4] = "QUALITY_LEVEL_VERY_GOOD"] = 4;
                values[valuesById[5] = "QUALITY_LEVEL_EXCELLENT"] = 5;
                return values;
            })();

            return DataTrackQuality;
        })();

        room.ar = (function() {

            /**
             * Namespace ar.
             * @memberof streem.room
             * @namespace
             */
            var ar = {};

            ar.MeshArtifact = (function() {

                /**
                 * Properties of a MeshArtifact.
                 * @memberof streem.room.ar
                 * @interface IMeshArtifact
                 * @property {Array.<streem.room.ar.ITrackable>|null} [trackables] MeshArtifact trackables
                 * @property {Array.<streem.room.ar.MeshArtifact.IEnhancement>|null} [meshEnhancements] MeshArtifact meshEnhancements
                 */

                /**
                 * Constructs a new MeshArtifact.
                 * @memberof streem.room.ar
                 * @classdesc Represents a MeshArtifact.
                 * @implements IMeshArtifact
                 * @constructor
                 * @param {streem.room.ar.IMeshArtifact=} [p] Properties to set
                 */
                function MeshArtifact(p) {
                    this.trackables = [];
                    this.meshEnhancements = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * MeshArtifact trackables.
                 * @member {Array.<streem.room.ar.ITrackable>} trackables
                 * @memberof streem.room.ar.MeshArtifact
                 * @instance
                 */
                MeshArtifact.prototype.trackables = $util.emptyArray;

                /**
                 * MeshArtifact meshEnhancements.
                 * @member {Array.<streem.room.ar.MeshArtifact.IEnhancement>} meshEnhancements
                 * @memberof streem.room.ar.MeshArtifact
                 * @instance
                 */
                MeshArtifact.prototype.meshEnhancements = $util.emptyArray;

                /**
                 * Creates a new MeshArtifact instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {streem.room.ar.IMeshArtifact=} [properties] Properties to set
                 * @returns {streem.room.ar.MeshArtifact} MeshArtifact instance
                 */
                MeshArtifact.create = function create(properties) {
                    return new MeshArtifact(properties);
                };

                /**
                 * Encodes the specified MeshArtifact message. Does not implicitly {@link streem.room.ar.MeshArtifact.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {streem.room.ar.IMeshArtifact} m MeshArtifact message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MeshArtifact.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.trackables != null && m.trackables.length) {
                        for (var i = 0; i < m.trackables.length; ++i)
                            $root.streem.room.ar.Trackable.encode(m.trackables[i], w.uint32(10).fork()).ldelim();
                    }
                    if (m.meshEnhancements != null && m.meshEnhancements.length) {
                        for (var i = 0; i < m.meshEnhancements.length; ++i)
                            $root.streem.room.ar.MeshArtifact.Enhancement.encode(m.meshEnhancements[i], w.uint32(18).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a MeshArtifact message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.MeshArtifact} MeshArtifact
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MeshArtifact.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.MeshArtifact();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.trackables && m.trackables.length))
                                    m.trackables = [];
                                m.trackables.push($root.streem.room.ar.Trackable.decode(r, r.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(m.meshEnhancements && m.meshEnhancements.length))
                                    m.meshEnhancements = [];
                                m.meshEnhancements.push($root.streem.room.ar.MeshArtifact.Enhancement.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a MeshArtifact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.MeshArtifact} MeshArtifact
                 */
                MeshArtifact.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.MeshArtifact)
                        return d;
                    var m = new $root.streem.room.ar.MeshArtifact();
                    if (d.trackables) {
                        if (!Array.isArray(d.trackables))
                            throw TypeError(".streem.room.ar.MeshArtifact.trackables: array expected");
                        m.trackables = [];
                        for (var i = 0; i < d.trackables.length; ++i) {
                            if (typeof d.trackables[i] !== "object")
                                throw TypeError(".streem.room.ar.MeshArtifact.trackables: object expected");
                            m.trackables[i] = $root.streem.room.ar.Trackable.fromObject(d.trackables[i]);
                        }
                    }
                    if (d.meshEnhancements) {
                        if (!Array.isArray(d.meshEnhancements))
                            throw TypeError(".streem.room.ar.MeshArtifact.meshEnhancements: array expected");
                        m.meshEnhancements = [];
                        for (var i = 0; i < d.meshEnhancements.length; ++i) {
                            if (typeof d.meshEnhancements[i] !== "object")
                                throw TypeError(".streem.room.ar.MeshArtifact.meshEnhancements: object expected");
                            m.meshEnhancements[i] = $root.streem.room.ar.MeshArtifact.Enhancement.fromObject(d.meshEnhancements[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a MeshArtifact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {streem.room.ar.MeshArtifact} m MeshArtifact
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MeshArtifact.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.trackables = [];
                        d.meshEnhancements = [];
                    }
                    if (m.trackables && m.trackables.length) {
                        d.trackables = [];
                        for (var j = 0; j < m.trackables.length; ++j) {
                            d.trackables[j] = $root.streem.room.ar.Trackable.toObject(m.trackables[j], o);
                        }
                    }
                    if (m.meshEnhancements && m.meshEnhancements.length) {
                        d.meshEnhancements = [];
                        for (var j = 0; j < m.meshEnhancements.length; ++j) {
                            d.meshEnhancements[j] = $root.streem.room.ar.MeshArtifact.Enhancement.toObject(m.meshEnhancements[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this MeshArtifact to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.MeshArtifact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MeshArtifact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MeshArtifact
                 * @function getTypeUrl
                 * @memberof streem.room.ar.MeshArtifact
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MeshArtifact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.MeshArtifact";
                };

                MeshArtifact.Enhancement = (function() {

                    /**
                     * Properties of an Enhancement.
                     * @memberof streem.room.ar.MeshArtifact
                     * @interface IEnhancement
                     * @property {Uint8Array|null} [textureJpegData] Enhancement textureJpegData
                     * @property {ICameraIntrinsics|null} [cameraIntrinsics] Enhancement cameraIntrinsics
                     * @property {streem.api.ISize|null} [cameraDimensions] Enhancement cameraDimensions
                     * @property {streem.api.IPose|null} [cameraPose] Enhancement cameraPose
                     */

                    /**
                     * Constructs a new Enhancement.
                     * @memberof streem.room.ar.MeshArtifact
                     * @classdesc Represents an Enhancement.
                     * @implements IEnhancement
                     * @constructor
                     * @param {streem.room.ar.MeshArtifact.IEnhancement=} [p] Properties to set
                     */
                    function Enhancement(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Enhancement textureJpegData.
                     * @member {Uint8Array} textureJpegData
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @instance
                     */
                    Enhancement.prototype.textureJpegData = $util.newBuffer([]);

                    /**
                     * Enhancement cameraIntrinsics.
                     * @member {ICameraIntrinsics|null|undefined} cameraIntrinsics
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @instance
                     */
                    Enhancement.prototype.cameraIntrinsics = null;

                    /**
                     * Enhancement cameraDimensions.
                     * @member {streem.api.ISize|null|undefined} cameraDimensions
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @instance
                     */
                    Enhancement.prototype.cameraDimensions = null;

                    /**
                     * Enhancement cameraPose.
                     * @member {streem.api.IPose|null|undefined} cameraPose
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @instance
                     */
                    Enhancement.prototype.cameraPose = null;

                    /**
                     * Creates a new Enhancement instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {streem.room.ar.MeshArtifact.IEnhancement=} [properties] Properties to set
                     * @returns {streem.room.ar.MeshArtifact.Enhancement} Enhancement instance
                     */
                    Enhancement.create = function create(properties) {
                        return new Enhancement(properties);
                    };

                    /**
                     * Encodes the specified Enhancement message. Does not implicitly {@link streem.room.ar.MeshArtifact.Enhancement.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {streem.room.ar.MeshArtifact.IEnhancement} m Enhancement message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Enhancement.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.textureJpegData != null && Object.hasOwnProperty.call(m, "textureJpegData"))
                            w.uint32(10).bytes(m.textureJpegData);
                        if (m.cameraIntrinsics != null && Object.hasOwnProperty.call(m, "cameraIntrinsics"))
                            $root.CameraIntrinsics.encode(m.cameraIntrinsics, w.uint32(18).fork()).ldelim();
                        if (m.cameraDimensions != null && Object.hasOwnProperty.call(m, "cameraDimensions"))
                            $root.streem.api.Size.encode(m.cameraDimensions, w.uint32(26).fork()).ldelim();
                        if (m.cameraPose != null && Object.hasOwnProperty.call(m, "cameraPose"))
                            $root.streem.api.Pose.encode(m.cameraPose, w.uint32(34).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes an Enhancement message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.MeshArtifact.Enhancement} Enhancement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Enhancement.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.MeshArtifact.Enhancement();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.textureJpegData = r.bytes();
                                    break;
                                }
                            case 2: {
                                    m.cameraIntrinsics = $root.CameraIntrinsics.decode(r, r.uint32());
                                    break;
                                }
                            case 3: {
                                    m.cameraDimensions = $root.streem.api.Size.decode(r, r.uint32());
                                    break;
                                }
                            case 4: {
                                    m.cameraPose = $root.streem.api.Pose.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an Enhancement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.MeshArtifact.Enhancement} Enhancement
                     */
                    Enhancement.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.MeshArtifact.Enhancement)
                            return d;
                        var m = new $root.streem.room.ar.MeshArtifact.Enhancement();
                        if (d.textureJpegData != null) {
                            if (typeof d.textureJpegData === "string")
                                $util.base64.decode(d.textureJpegData, m.textureJpegData = $util.newBuffer($util.base64.length(d.textureJpegData)), 0);
                            else if (d.textureJpegData.length >= 0)
                                m.textureJpegData = d.textureJpegData;
                        }
                        if (d.cameraIntrinsics != null) {
                            if (typeof d.cameraIntrinsics !== "object")
                                throw TypeError(".streem.room.ar.MeshArtifact.Enhancement.cameraIntrinsics: object expected");
                            m.cameraIntrinsics = $root.CameraIntrinsics.fromObject(d.cameraIntrinsics);
                        }
                        if (d.cameraDimensions != null) {
                            if (typeof d.cameraDimensions !== "object")
                                throw TypeError(".streem.room.ar.MeshArtifact.Enhancement.cameraDimensions: object expected");
                            m.cameraDimensions = $root.streem.api.Size.fromObject(d.cameraDimensions);
                        }
                        if (d.cameraPose != null) {
                            if (typeof d.cameraPose !== "object")
                                throw TypeError(".streem.room.ar.MeshArtifact.Enhancement.cameraPose: object expected");
                            m.cameraPose = $root.streem.api.Pose.fromObject(d.cameraPose);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an Enhancement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {streem.room.ar.MeshArtifact.Enhancement} m Enhancement
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Enhancement.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            if (o.bytes === String)
                                d.textureJpegData = "";
                            else {
                                d.textureJpegData = [];
                                if (o.bytes !== Array)
                                    d.textureJpegData = $util.newBuffer(d.textureJpegData);
                            }
                            d.cameraIntrinsics = null;
                            d.cameraDimensions = null;
                            d.cameraPose = null;
                        }
                        if (m.textureJpegData != null && m.hasOwnProperty("textureJpegData")) {
                            d.textureJpegData = o.bytes === String ? $util.base64.encode(m.textureJpegData, 0, m.textureJpegData.length) : o.bytes === Array ? Array.prototype.slice.call(m.textureJpegData) : m.textureJpegData;
                        }
                        if (m.cameraIntrinsics != null && m.hasOwnProperty("cameraIntrinsics")) {
                            d.cameraIntrinsics = $root.CameraIntrinsics.toObject(m.cameraIntrinsics, o);
                        }
                        if (m.cameraDimensions != null && m.hasOwnProperty("cameraDimensions")) {
                            d.cameraDimensions = $root.streem.api.Size.toObject(m.cameraDimensions, o);
                        }
                        if (m.cameraPose != null && m.hasOwnProperty("cameraPose")) {
                            d.cameraPose = $root.streem.api.Pose.toObject(m.cameraPose, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Enhancement to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Enhancement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Enhancement
                     * @function getTypeUrl
                     * @memberof streem.room.ar.MeshArtifact.Enhancement
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Enhancement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.MeshArtifact.Enhancement";
                    };

                    return Enhancement;
                })();

                return MeshArtifact;
            })();

            ar.DepthMapData = (function() {

                /**
                 * Properties of a DepthMapData.
                 * @memberof streem.room.ar
                 * @interface IDepthMapData
                 * @property {IVectorBuffer|null} [depthMap] DepthMapData depthMap
                 * @property {IVectorBuffer|null} [confidenceMap] DepthMapData confidenceMap
                 * @property {streem.room.ar.DepthMapData.Encoding|null} [encoding] DepthMapData encoding
                 */

                /**
                 * Constructs a new DepthMapData.
                 * @memberof streem.room.ar
                 * @classdesc Represents a DepthMapData.
                 * @implements IDepthMapData
                 * @constructor
                 * @param {streem.room.ar.IDepthMapData=} [p] Properties to set
                 */
                function DepthMapData(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * DepthMapData depthMap.
                 * @member {IVectorBuffer|null|undefined} depthMap
                 * @memberof streem.room.ar.DepthMapData
                 * @instance
                 */
                DepthMapData.prototype.depthMap = null;

                /**
                 * DepthMapData confidenceMap.
                 * @member {IVectorBuffer|null|undefined} confidenceMap
                 * @memberof streem.room.ar.DepthMapData
                 * @instance
                 */
                DepthMapData.prototype.confidenceMap = null;

                /**
                 * DepthMapData encoding.
                 * @member {streem.room.ar.DepthMapData.Encoding} encoding
                 * @memberof streem.room.ar.DepthMapData
                 * @instance
                 */
                DepthMapData.prototype.encoding = 0;

                /**
                 * Creates a new DepthMapData instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {streem.room.ar.IDepthMapData=} [properties] Properties to set
                 * @returns {streem.room.ar.DepthMapData} DepthMapData instance
                 */
                DepthMapData.create = function create(properties) {
                    return new DepthMapData(properties);
                };

                /**
                 * Encodes the specified DepthMapData message. Does not implicitly {@link streem.room.ar.DepthMapData.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {streem.room.ar.IDepthMapData} m DepthMapData message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DepthMapData.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.depthMap != null && Object.hasOwnProperty.call(m, "depthMap"))
                        $root.VectorBuffer.encode(m.depthMap, w.uint32(10).fork()).ldelim();
                    if (m.confidenceMap != null && Object.hasOwnProperty.call(m, "confidenceMap"))
                        $root.VectorBuffer.encode(m.confidenceMap, w.uint32(18).fork()).ldelim();
                    if (m.encoding != null && Object.hasOwnProperty.call(m, "encoding"))
                        w.uint32(24).int32(m.encoding);
                    return w;
                };

                /**
                 * Decodes a DepthMapData message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.DepthMapData} DepthMapData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DepthMapData.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.DepthMapData();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.depthMap = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.confidenceMap = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.encoding = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DepthMapData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.DepthMapData} DepthMapData
                 */
                DepthMapData.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.DepthMapData)
                        return d;
                    var m = new $root.streem.room.ar.DepthMapData();
                    if (d.depthMap != null) {
                        if (typeof d.depthMap !== "object")
                            throw TypeError(".streem.room.ar.DepthMapData.depthMap: object expected");
                        m.depthMap = $root.VectorBuffer.fromObject(d.depthMap);
                    }
                    if (d.confidenceMap != null) {
                        if (typeof d.confidenceMap !== "object")
                            throw TypeError(".streem.room.ar.DepthMapData.confidenceMap: object expected");
                        m.confidenceMap = $root.VectorBuffer.fromObject(d.confidenceMap);
                    }
                    switch (d.encoding) {
                    default:
                        if (typeof d.encoding === "number") {
                            m.encoding = d.encoding;
                            break;
                        }
                        break;
                    case "ENCODING_INVALID":
                    case 0:
                        m.encoding = 0;
                        break;
                    case "ENCODING_IOS":
                    case 1:
                        m.encoding = 1;
                        break;
                    case "ENCODING_ANDROID":
                    case 2:
                        m.encoding = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a DepthMapData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {streem.room.ar.DepthMapData} m DepthMapData
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DepthMapData.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.depthMap = null;
                        d.confidenceMap = null;
                        d.encoding = o.enums === String ? "ENCODING_INVALID" : 0;
                    }
                    if (m.depthMap != null && m.hasOwnProperty("depthMap")) {
                        d.depthMap = $root.VectorBuffer.toObject(m.depthMap, o);
                    }
                    if (m.confidenceMap != null && m.hasOwnProperty("confidenceMap")) {
                        d.confidenceMap = $root.VectorBuffer.toObject(m.confidenceMap, o);
                    }
                    if (m.encoding != null && m.hasOwnProperty("encoding")) {
                        d.encoding = o.enums === String ? $root.streem.room.ar.DepthMapData.Encoding[m.encoding] === undefined ? m.encoding : $root.streem.room.ar.DepthMapData.Encoding[m.encoding] : m.encoding;
                    }
                    return d;
                };

                /**
                 * Converts this DepthMapData to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.DepthMapData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DepthMapData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DepthMapData
                 * @function getTypeUrl
                 * @memberof streem.room.ar.DepthMapData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DepthMapData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.DepthMapData";
                };

                /**
                 * Encoding enum.
                 * @name streem.room.ar.DepthMapData.Encoding
                 * @enum {number}
                 * @property {number} ENCODING_INVALID=0 ENCODING_INVALID value
                 * @property {number} ENCODING_IOS=1 ENCODING_IOS value
                 * @property {number} ENCODING_ANDROID=2 ENCODING_ANDROID value
                 */
                DepthMapData.Encoding = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ENCODING_INVALID"] = 0;
                    values[valuesById[1] = "ENCODING_IOS"] = 1;
                    values[valuesById[2] = "ENCODING_ANDROID"] = 2;
                    return values;
                })();

                return DepthMapData;
            })();

            ar.LayoutEstimationData = (function() {

                /**
                 * Properties of a LayoutEstimationData.
                 * @memberof streem.room.ar
                 * @interface ILayoutEstimationData
                 * @property {IVectorBuffer|null} [layoutEdgeVertices] LayoutEstimationData layoutEdgeVertices
                 * @property {streem.room.ar.IMesh|null} [triangulatedFloor] LayoutEstimationData triangulatedFloor
                 * @property {number|null} [areaSquareMeters] LayoutEstimationData areaSquareMeters
                 */

                /**
                 * Constructs a new LayoutEstimationData.
                 * @memberof streem.room.ar
                 * @classdesc Represents a LayoutEstimationData.
                 * @implements ILayoutEstimationData
                 * @constructor
                 * @param {streem.room.ar.ILayoutEstimationData=} [p] Properties to set
                 */
                function LayoutEstimationData(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LayoutEstimationData layoutEdgeVertices.
                 * @member {IVectorBuffer|null|undefined} layoutEdgeVertices
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @instance
                 */
                LayoutEstimationData.prototype.layoutEdgeVertices = null;

                /**
                 * LayoutEstimationData triangulatedFloor.
                 * @member {streem.room.ar.IMesh|null|undefined} triangulatedFloor
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @instance
                 */
                LayoutEstimationData.prototype.triangulatedFloor = null;

                /**
                 * LayoutEstimationData areaSquareMeters.
                 * @member {number} areaSquareMeters
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @instance
                 */
                LayoutEstimationData.prototype.areaSquareMeters = 0;

                /**
                 * Creates a new LayoutEstimationData instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {streem.room.ar.ILayoutEstimationData=} [properties] Properties to set
                 * @returns {streem.room.ar.LayoutEstimationData} LayoutEstimationData instance
                 */
                LayoutEstimationData.create = function create(properties) {
                    return new LayoutEstimationData(properties);
                };

                /**
                 * Encodes the specified LayoutEstimationData message. Does not implicitly {@link streem.room.ar.LayoutEstimationData.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {streem.room.ar.ILayoutEstimationData} m LayoutEstimationData message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LayoutEstimationData.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.layoutEdgeVertices != null && Object.hasOwnProperty.call(m, "layoutEdgeVertices"))
                        $root.VectorBuffer.encode(m.layoutEdgeVertices, w.uint32(10).fork()).ldelim();
                    if (m.triangulatedFloor != null && Object.hasOwnProperty.call(m, "triangulatedFloor"))
                        $root.streem.room.ar.Mesh.encode(m.triangulatedFloor, w.uint32(18).fork()).ldelim();
                    if (m.areaSquareMeters != null && Object.hasOwnProperty.call(m, "areaSquareMeters"))
                        w.uint32(29).float(m.areaSquareMeters);
                    return w;
                };

                /**
                 * Decodes a LayoutEstimationData message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.LayoutEstimationData} LayoutEstimationData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LayoutEstimationData.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.LayoutEstimationData();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.layoutEdgeVertices = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.triangulatedFloor = $root.streem.room.ar.Mesh.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.areaSquareMeters = r.float();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LayoutEstimationData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.LayoutEstimationData} LayoutEstimationData
                 */
                LayoutEstimationData.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.LayoutEstimationData)
                        return d;
                    var m = new $root.streem.room.ar.LayoutEstimationData();
                    if (d.layoutEdgeVertices != null) {
                        if (typeof d.layoutEdgeVertices !== "object")
                            throw TypeError(".streem.room.ar.LayoutEstimationData.layoutEdgeVertices: object expected");
                        m.layoutEdgeVertices = $root.VectorBuffer.fromObject(d.layoutEdgeVertices);
                    }
                    if (d.triangulatedFloor != null) {
                        if (typeof d.triangulatedFloor !== "object")
                            throw TypeError(".streem.room.ar.LayoutEstimationData.triangulatedFloor: object expected");
                        m.triangulatedFloor = $root.streem.room.ar.Mesh.fromObject(d.triangulatedFloor);
                    }
                    if (d.areaSquareMeters != null) {
                        m.areaSquareMeters = Number(d.areaSquareMeters);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LayoutEstimationData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {streem.room.ar.LayoutEstimationData} m LayoutEstimationData
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LayoutEstimationData.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.layoutEdgeVertices = null;
                        d.triangulatedFloor = null;
                        d.areaSquareMeters = 0;
                    }
                    if (m.layoutEdgeVertices != null && m.hasOwnProperty("layoutEdgeVertices")) {
                        d.layoutEdgeVertices = $root.VectorBuffer.toObject(m.layoutEdgeVertices, o);
                    }
                    if (m.triangulatedFloor != null && m.hasOwnProperty("triangulatedFloor")) {
                        d.triangulatedFloor = $root.streem.room.ar.Mesh.toObject(m.triangulatedFloor, o);
                    }
                    if (m.areaSquareMeters != null && m.hasOwnProperty("areaSquareMeters")) {
                        d.areaSquareMeters = o.json && !isFinite(m.areaSquareMeters) ? String(m.areaSquareMeters) : m.areaSquareMeters;
                    }
                    return d;
                };

                /**
                 * Converts this LayoutEstimationData to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LayoutEstimationData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LayoutEstimationData
                 * @function getTypeUrl
                 * @memberof streem.room.ar.LayoutEstimationData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LayoutEstimationData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.LayoutEstimationData";
                };

                return LayoutEstimationData;
            })();

            /**
             * TrackableType enum.
             * @name streem.room.ar.TrackableType
             * @enum {number}
             * @property {number} TRACKABLE_TYPE_INVALID=0 TRACKABLE_TYPE_INVALID value
             * @property {number} TRACKABLE_TYPE_MARKER_2D=1 TRACKABLE_TYPE_MARKER_2D value
             * @property {number} TRACKABLE_TYPE_MARKER_3D=2 TRACKABLE_TYPE_MARKER_3D value
             * @property {number} TRACKABLE_TYPE_PLANE=3 TRACKABLE_TYPE_PLANE value
             * @property {number} TRACKABLE_TYPE_FEATURE_POINT=4 TRACKABLE_TYPE_FEATURE_POINT value
             * @property {number} TRACKABLE_TYPE_MESH=5 TRACKABLE_TYPE_MESH value
             * @property {number} TRACKABLE_TYPE_STATIC=6 TRACKABLE_TYPE_STATIC value
             */
            ar.TrackableType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TRACKABLE_TYPE_INVALID"] = 0;
                values[valuesById[1] = "TRACKABLE_TYPE_MARKER_2D"] = 1;
                values[valuesById[2] = "TRACKABLE_TYPE_MARKER_3D"] = 2;
                values[valuesById[3] = "TRACKABLE_TYPE_PLANE"] = 3;
                values[valuesById[4] = "TRACKABLE_TYPE_FEATURE_POINT"] = 4;
                values[valuesById[5] = "TRACKABLE_TYPE_MESH"] = 5;
                values[valuesById[6] = "TRACKABLE_TYPE_STATIC"] = 6;
                return values;
            })();

            ar.TrackableDefinition = (function() {

                /**
                 * Properties of a TrackableDefinition.
                 * @memberof streem.room.ar
                 * @interface ITrackableDefinition
                 * @property {streem.room.ar.TrackableDefinition.IMarker2d|null} [marker_2d] TrackableDefinition marker_2d
                 * @property {streem.room.ar.TrackableDefinition.IMarker3d|null} [marker_3d] TrackableDefinition marker_3d
                 * @property {streem.room.ar.TrackableDefinition.IStatic|null} ["static"] TrackableDefinition static
                 * @property {streem.room.ar.TrackableDefinition.IPlane|null} [plane] TrackableDefinition plane
                 * @property {streem.room.ar.TrackableDefinition.IFeaturePoint|null} [featurePoint] TrackableDefinition featurePoint
                 * @property {streem.room.ar.TrackableDefinition.IMesh|null} [mesh] TrackableDefinition mesh
                 */

                /**
                 * Constructs a new TrackableDefinition.
                 * @memberof streem.room.ar
                 * @classdesc Represents a TrackableDefinition.
                 * @implements ITrackableDefinition
                 * @constructor
                 * @param {streem.room.ar.ITrackableDefinition=} [p] Properties to set
                 */
                function TrackableDefinition(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * TrackableDefinition marker_2d.
                 * @member {streem.room.ar.TrackableDefinition.IMarker2d|null|undefined} marker_2d
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype.marker_2d = null;

                /**
                 * TrackableDefinition marker_3d.
                 * @member {streem.room.ar.TrackableDefinition.IMarker3d|null|undefined} marker_3d
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype.marker_3d = null;

                /**
                 * TrackableDefinition static.
                 * @member {streem.room.ar.TrackableDefinition.IStatic|null|undefined} static
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype["static"] = null;

                /**
                 * TrackableDefinition plane.
                 * @member {streem.room.ar.TrackableDefinition.IPlane|null|undefined} plane
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype.plane = null;

                /**
                 * TrackableDefinition featurePoint.
                 * @member {streem.room.ar.TrackableDefinition.IFeaturePoint|null|undefined} featurePoint
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype.featurePoint = null;

                /**
                 * TrackableDefinition mesh.
                 * @member {streem.room.ar.TrackableDefinition.IMesh|null|undefined} mesh
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                TrackableDefinition.prototype.mesh = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * TrackableDefinition type.
                 * @member {"marker_2d"|"marker_3d"|"static"|"plane"|"featurePoint"|"mesh"|undefined} type
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 */
                Object.defineProperty(TrackableDefinition.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["marker_2d", "marker_3d", "static", "plane", "featurePoint", "mesh"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new TrackableDefinition instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {streem.room.ar.ITrackableDefinition=} [properties] Properties to set
                 * @returns {streem.room.ar.TrackableDefinition} TrackableDefinition instance
                 */
                TrackableDefinition.create = function create(properties) {
                    return new TrackableDefinition(properties);
                };

                /**
                 * Encodes the specified TrackableDefinition message. Does not implicitly {@link streem.room.ar.TrackableDefinition.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {streem.room.ar.ITrackableDefinition} m TrackableDefinition message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TrackableDefinition.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.marker_2d != null && Object.hasOwnProperty.call(m, "marker_2d"))
                        $root.streem.room.ar.TrackableDefinition.Marker2d.encode(m.marker_2d, w.uint32(810).fork()).ldelim();
                    if (m.marker_3d != null && Object.hasOwnProperty.call(m, "marker_3d"))
                        $root.streem.room.ar.TrackableDefinition.Marker3d.encode(m.marker_3d, w.uint32(818).fork()).ldelim();
                    if (m["static"] != null && Object.hasOwnProperty.call(m, "static"))
                        $root.streem.room.ar.TrackableDefinition.Static.encode(m["static"], w.uint32(826).fork()).ldelim();
                    if (m.plane != null && Object.hasOwnProperty.call(m, "plane"))
                        $root.streem.room.ar.TrackableDefinition.Plane.encode(m.plane, w.uint32(834).fork()).ldelim();
                    if (m.featurePoint != null && Object.hasOwnProperty.call(m, "featurePoint"))
                        $root.streem.room.ar.TrackableDefinition.FeaturePoint.encode(m.featurePoint, w.uint32(842).fork()).ldelim();
                    if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                        $root.streem.room.ar.TrackableDefinition.Mesh.encode(m.mesh, w.uint32(850).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a TrackableDefinition message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.TrackableDefinition} TrackableDefinition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TrackableDefinition.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 101: {
                                m.marker_2d = $root.streem.room.ar.TrackableDefinition.Marker2d.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.marker_3d = $root.streem.room.ar.TrackableDefinition.Marker3d.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m["static"] = $root.streem.room.ar.TrackableDefinition.Static.decode(r, r.uint32());
                                break;
                            }
                        case 104: {
                                m.plane = $root.streem.room.ar.TrackableDefinition.Plane.decode(r, r.uint32());
                                break;
                            }
                        case 105: {
                                m.featurePoint = $root.streem.room.ar.TrackableDefinition.FeaturePoint.decode(r, r.uint32());
                                break;
                            }
                        case 106: {
                                m.mesh = $root.streem.room.ar.TrackableDefinition.Mesh.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a TrackableDefinition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.TrackableDefinition} TrackableDefinition
                 */
                TrackableDefinition.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.TrackableDefinition)
                        return d;
                    var m = new $root.streem.room.ar.TrackableDefinition();
                    if (d.marker_2d != null) {
                        if (typeof d.marker_2d !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.marker_2d: object expected");
                        m.marker_2d = $root.streem.room.ar.TrackableDefinition.Marker2d.fromObject(d.marker_2d);
                    }
                    if (d.marker_3d != null) {
                        if (typeof d.marker_3d !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.marker_3d: object expected");
                        m.marker_3d = $root.streem.room.ar.TrackableDefinition.Marker3d.fromObject(d.marker_3d);
                    }
                    if (d["static"] != null) {
                        if (typeof d["static"] !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.static: object expected");
                        m["static"] = $root.streem.room.ar.TrackableDefinition.Static.fromObject(d["static"]);
                    }
                    if (d.plane != null) {
                        if (typeof d.plane !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.plane: object expected");
                        m.plane = $root.streem.room.ar.TrackableDefinition.Plane.fromObject(d.plane);
                    }
                    if (d.featurePoint != null) {
                        if (typeof d.featurePoint !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.featurePoint: object expected");
                        m.featurePoint = $root.streem.room.ar.TrackableDefinition.FeaturePoint.fromObject(d.featurePoint);
                    }
                    if (d.mesh != null) {
                        if (typeof d.mesh !== "object")
                            throw TypeError(".streem.room.ar.TrackableDefinition.mesh: object expected");
                        m.mesh = $root.streem.room.ar.TrackableDefinition.Mesh.fromObject(d.mesh);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a TrackableDefinition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {streem.room.ar.TrackableDefinition} m TrackableDefinition
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TrackableDefinition.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (m.marker_2d != null && m.hasOwnProperty("marker_2d")) {
                        d.marker_2d = $root.streem.room.ar.TrackableDefinition.Marker2d.toObject(m.marker_2d, o);
                        if (o.oneofs)
                            d.type = "marker_2d";
                    }
                    if (m.marker_3d != null && m.hasOwnProperty("marker_3d")) {
                        d.marker_3d = $root.streem.room.ar.TrackableDefinition.Marker3d.toObject(m.marker_3d, o);
                        if (o.oneofs)
                            d.type = "marker_3d";
                    }
                    if (m["static"] != null && m.hasOwnProperty("static")) {
                        d["static"] = $root.streem.room.ar.TrackableDefinition.Static.toObject(m["static"], o);
                        if (o.oneofs)
                            d.type = "static";
                    }
                    if (m.plane != null && m.hasOwnProperty("plane")) {
                        d.plane = $root.streem.room.ar.TrackableDefinition.Plane.toObject(m.plane, o);
                        if (o.oneofs)
                            d.type = "plane";
                    }
                    if (m.featurePoint != null && m.hasOwnProperty("featurePoint")) {
                        d.featurePoint = $root.streem.room.ar.TrackableDefinition.FeaturePoint.toObject(m.featurePoint, o);
                        if (o.oneofs)
                            d.type = "featurePoint";
                    }
                    if (m.mesh != null && m.hasOwnProperty("mesh")) {
                        d.mesh = $root.streem.room.ar.TrackableDefinition.Mesh.toObject(m.mesh, o);
                        if (o.oneofs)
                            d.type = "mesh";
                    }
                    return d;
                };

                /**
                 * Converts this TrackableDefinition to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.TrackableDefinition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TrackableDefinition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TrackableDefinition
                 * @function getTypeUrl
                 * @memberof streem.room.ar.TrackableDefinition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TrackableDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.TrackableDefinition";
                };

                TrackableDefinition.Marker2d = (function() {

                    /**
                     * Properties of a Marker2d.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IMarker2d
                     * @property {string|null} [file] Marker2d file
                     * @property {streem.api.ISize|null} [expectedSizeMeters] Marker2d expectedSizeMeters
                     */

                    /**
                     * Constructs a new Marker2d.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a Marker2d.
                     * @implements IMarker2d
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IMarker2d=} [p] Properties to set
                     */
                    function Marker2d(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Marker2d file.
                     * @member {string} file
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @instance
                     */
                    Marker2d.prototype.file = "";

                    /**
                     * Marker2d expectedSizeMeters.
                     * @member {streem.api.ISize|null|undefined} expectedSizeMeters
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @instance
                     */
                    Marker2d.prototype.expectedSizeMeters = null;

                    /**
                     * Creates a new Marker2d instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMarker2d=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.Marker2d} Marker2d instance
                     */
                    Marker2d.create = function create(properties) {
                        return new Marker2d(properties);
                    };

                    /**
                     * Encodes the specified Marker2d message. Does not implicitly {@link streem.room.ar.TrackableDefinition.Marker2d.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMarker2d} m Marker2d message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Marker2d.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                            w.uint32(10).string(m.file);
                        if (m.expectedSizeMeters != null && Object.hasOwnProperty.call(m, "expectedSizeMeters"))
                            $root.streem.api.Size.encode(m.expectedSizeMeters, w.uint32(18).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Marker2d message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.Marker2d} Marker2d
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Marker2d.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.Marker2d();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.file = r.string();
                                    break;
                                }
                            case 2: {
                                    m.expectedSizeMeters = $root.streem.api.Size.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Marker2d message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.Marker2d} Marker2d
                     */
                    Marker2d.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.Marker2d)
                            return d;
                        var m = new $root.streem.room.ar.TrackableDefinition.Marker2d();
                        if (d.file != null) {
                            m.file = String(d.file);
                        }
                        if (d.expectedSizeMeters != null) {
                            if (typeof d.expectedSizeMeters !== "object")
                                throw TypeError(".streem.room.ar.TrackableDefinition.Marker2d.expectedSizeMeters: object expected");
                            m.expectedSizeMeters = $root.streem.api.Size.fromObject(d.expectedSizeMeters);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Marker2d message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.Marker2d} m Marker2d
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Marker2d.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.file = "";
                            d.expectedSizeMeters = null;
                        }
                        if (m.file != null && m.hasOwnProperty("file")) {
                            d.file = m.file;
                        }
                        if (m.expectedSizeMeters != null && m.hasOwnProperty("expectedSizeMeters")) {
                            d.expectedSizeMeters = $root.streem.api.Size.toObject(m.expectedSizeMeters, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Marker2d to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Marker2d.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Marker2d
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.Marker2d
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Marker2d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.Marker2d";
                    };

                    return Marker2d;
                })();

                TrackableDefinition.Marker3d = (function() {

                    /**
                     * Properties of a Marker3d.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IMarker3d
                     * @property {string|null} [file] Marker3d file
                     */

                    /**
                     * Constructs a new Marker3d.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a Marker3d.
                     * @implements IMarker3d
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IMarker3d=} [p] Properties to set
                     */
                    function Marker3d(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Marker3d file.
                     * @member {string} file
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @instance
                     */
                    Marker3d.prototype.file = "";

                    /**
                     * Creates a new Marker3d instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMarker3d=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.Marker3d} Marker3d instance
                     */
                    Marker3d.create = function create(properties) {
                        return new Marker3d(properties);
                    };

                    /**
                     * Encodes the specified Marker3d message. Does not implicitly {@link streem.room.ar.TrackableDefinition.Marker3d.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMarker3d} m Marker3d message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Marker3d.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                            w.uint32(10).string(m.file);
                        return w;
                    };

                    /**
                     * Decodes a Marker3d message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.Marker3d} Marker3d
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Marker3d.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.Marker3d();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.file = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Marker3d message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.Marker3d} Marker3d
                     */
                    Marker3d.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.Marker3d)
                            return d;
                        var m = new $root.streem.room.ar.TrackableDefinition.Marker3d();
                        if (d.file != null) {
                            m.file = String(d.file);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Marker3d message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.Marker3d} m Marker3d
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Marker3d.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.file = "";
                        }
                        if (m.file != null && m.hasOwnProperty("file")) {
                            d.file = m.file;
                        }
                        return d;
                    };

                    /**
                     * Converts this Marker3d to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Marker3d.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Marker3d
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.Marker3d
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Marker3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.Marker3d";
                    };

                    return Marker3d;
                })();

                TrackableDefinition.Static = (function() {

                    /**
                     * Properties of a Static.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IStatic
                     * @property {streem.api.ITransform|null} [transform] Static transform
                     */

                    /**
                     * Constructs a new Static.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a Static.
                     * @implements IStatic
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IStatic=} [p] Properties to set
                     */
                    function Static(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Static transform.
                     * @member {streem.api.ITransform|null|undefined} transform
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @instance
                     */
                    Static.prototype.transform = null;

                    /**
                     * Creates a new Static instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IStatic=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.Static} Static instance
                     */
                    Static.create = function create(properties) {
                        return new Static(properties);
                    };

                    /**
                     * Encodes the specified Static message. Does not implicitly {@link streem.room.ar.TrackableDefinition.Static.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IStatic} m Static message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Static.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                            $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Static message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.Static} Static
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Static.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.Static();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Static message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.Static} Static
                     */
                    Static.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.Static)
                            return d;
                        var m = new $root.streem.room.ar.TrackableDefinition.Static();
                        if (d.transform != null) {
                            if (typeof d.transform !== "object")
                                throw TypeError(".streem.room.ar.TrackableDefinition.Static.transform: object expected");
                            m.transform = $root.streem.api.Transform.fromObject(d.transform);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Static message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.Static} m Static
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Static.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.transform = null;
                        }
                        if (m.transform != null && m.hasOwnProperty("transform")) {
                            d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Static to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Static.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Static
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.Static
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Static.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.Static";
                    };

                    return Static;
                })();

                TrackableDefinition.Plane = (function() {

                    /**
                     * Properties of a Plane.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IPlane
                     * @property {boolean|null} [horizontal] Plane horizontal
                     * @property {boolean|null} [vertical] Plane vertical
                     */

                    /**
                     * Constructs a new Plane.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a Plane.
                     * @implements IPlane
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IPlane=} [p] Properties to set
                     */
                    function Plane(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Plane horizontal.
                     * @member {boolean} horizontal
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @instance
                     */
                    Plane.prototype.horizontal = false;

                    /**
                     * Plane vertical.
                     * @member {boolean} vertical
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @instance
                     */
                    Plane.prototype.vertical = false;

                    /**
                     * Creates a new Plane instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IPlane=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.Plane} Plane instance
                     */
                    Plane.create = function create(properties) {
                        return new Plane(properties);
                    };

                    /**
                     * Encodes the specified Plane message. Does not implicitly {@link streem.room.ar.TrackableDefinition.Plane.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IPlane} m Plane message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Plane.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.horizontal != null && Object.hasOwnProperty.call(m, "horizontal"))
                            w.uint32(8).bool(m.horizontal);
                        if (m.vertical != null && Object.hasOwnProperty.call(m, "vertical"))
                            w.uint32(16).bool(m.vertical);
                        return w;
                    };

                    /**
                     * Decodes a Plane message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.Plane} Plane
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Plane.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.Plane();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.horizontal = r.bool();
                                    break;
                                }
                            case 2: {
                                    m.vertical = r.bool();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Plane message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.Plane} Plane
                     */
                    Plane.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.Plane)
                            return d;
                        var m = new $root.streem.room.ar.TrackableDefinition.Plane();
                        if (d.horizontal != null) {
                            m.horizontal = Boolean(d.horizontal);
                        }
                        if (d.vertical != null) {
                            m.vertical = Boolean(d.vertical);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Plane message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.Plane} m Plane
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Plane.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.horizontal = false;
                            d.vertical = false;
                        }
                        if (m.horizontal != null && m.hasOwnProperty("horizontal")) {
                            d.horizontal = m.horizontal;
                        }
                        if (m.vertical != null && m.hasOwnProperty("vertical")) {
                            d.vertical = m.vertical;
                        }
                        return d;
                    };

                    /**
                     * Converts this Plane to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Plane.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Plane
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.Plane
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Plane.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.Plane";
                    };

                    return Plane;
                })();

                TrackableDefinition.FeaturePoint = (function() {

                    /**
                     * Properties of a FeaturePoint.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IFeaturePoint
                     */

                    /**
                     * Constructs a new FeaturePoint.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a FeaturePoint.
                     * @implements IFeaturePoint
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IFeaturePoint=} [p] Properties to set
                     */
                    function FeaturePoint(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new FeaturePoint instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IFeaturePoint=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.FeaturePoint} FeaturePoint instance
                     */
                    FeaturePoint.create = function create(properties) {
                        return new FeaturePoint(properties);
                    };

                    /**
                     * Encodes the specified FeaturePoint message. Does not implicitly {@link streem.room.ar.TrackableDefinition.FeaturePoint.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IFeaturePoint} m FeaturePoint message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FeaturePoint.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a FeaturePoint message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.FeaturePoint} FeaturePoint
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FeaturePoint.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.FeaturePoint();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a FeaturePoint message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.FeaturePoint} FeaturePoint
                     */
                    FeaturePoint.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.FeaturePoint)
                            return d;
                        return new $root.streem.room.ar.TrackableDefinition.FeaturePoint();
                    };

                    /**
                     * Creates a plain object from a FeaturePoint message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.FeaturePoint} m FeaturePoint
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FeaturePoint.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this FeaturePoint to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FeaturePoint.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for FeaturePoint
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.FeaturePoint
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    FeaturePoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.FeaturePoint";
                    };

                    return FeaturePoint;
                })();

                TrackableDefinition.Mesh = (function() {

                    /**
                     * Properties of a Mesh.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @interface IMesh
                     */

                    /**
                     * Constructs a new Mesh.
                     * @memberof streem.room.ar.TrackableDefinition
                     * @classdesc Represents a Mesh.
                     * @implements IMesh
                     * @constructor
                     * @param {streem.room.ar.TrackableDefinition.IMesh=} [p] Properties to set
                     */
                    function Mesh(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Mesh instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMesh=} [properties] Properties to set
                     * @returns {streem.room.ar.TrackableDefinition.Mesh} Mesh instance
                     */
                    Mesh.create = function create(properties) {
                        return new Mesh(properties);
                    };

                    /**
                     * Encodes the specified Mesh message. Does not implicitly {@link streem.room.ar.TrackableDefinition.Mesh.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.IMesh} m Mesh message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mesh.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Mesh message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.TrackableDefinition.Mesh} Mesh
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mesh.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.TrackableDefinition.Mesh();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Mesh message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.TrackableDefinition.Mesh} Mesh
                     */
                    Mesh.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.TrackableDefinition.Mesh)
                            return d;
                        return new $root.streem.room.ar.TrackableDefinition.Mesh();
                    };

                    /**
                     * Creates a plain object from a Mesh message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {streem.room.ar.TrackableDefinition.Mesh} m Mesh
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Mesh.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Mesh to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Mesh.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Mesh
                     * @function getTypeUrl
                     * @memberof streem.room.ar.TrackableDefinition.Mesh
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Mesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.TrackableDefinition.Mesh";
                    };

                    return Mesh;
                })();

                return TrackableDefinition;
            })();

            ar.Trackable = (function() {

                /**
                 * Properties of a Trackable.
                 * @memberof streem.room.ar
                 * @interface ITrackable
                 * @property {Uint8Array|null} [id] Trackable id
                 * @property {streem.api.IPose|null} [pose] Trackable pose
                 * @property {streem.room.ar.Trackable.IMarker2d|null} [marker_2d] Trackable marker_2d
                 * @property {streem.room.ar.Trackable.IMarker3d|null} [marker_3d] Trackable marker_3d
                 * @property {streem.room.ar.Trackable.IPlane|null} [plane] Trackable plane
                 * @property {streem.room.ar.Trackable.IFeaturePoint|null} [featurePoint] Trackable featurePoint
                 * @property {streem.room.ar.IMesh|null} [mesh] Trackable mesh
                 */

                /**
                 * Constructs a new Trackable.
                 * @memberof streem.room.ar
                 * @classdesc Represents a Trackable.
                 * @implements ITrackable
                 * @constructor
                 * @param {streem.room.ar.ITrackable=} [p] Properties to set
                 */
                function Trackable(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Trackable id.
                 * @member {Uint8Array} id
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.id = $util.newBuffer([]);

                /**
                 * Trackable pose.
                 * @member {streem.api.IPose|null|undefined} pose
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.pose = null;

                /**
                 * Trackable marker_2d.
                 * @member {streem.room.ar.Trackable.IMarker2d|null|undefined} marker_2d
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.marker_2d = null;

                /**
                 * Trackable marker_3d.
                 * @member {streem.room.ar.Trackable.IMarker3d|null|undefined} marker_3d
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.marker_3d = null;

                /**
                 * Trackable plane.
                 * @member {streem.room.ar.Trackable.IPlane|null|undefined} plane
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.plane = null;

                /**
                 * Trackable featurePoint.
                 * @member {streem.room.ar.Trackable.IFeaturePoint|null|undefined} featurePoint
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.featurePoint = null;

                /**
                 * Trackable mesh.
                 * @member {streem.room.ar.IMesh|null|undefined} mesh
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Trackable.prototype.mesh = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Trackable type.
                 * @member {"marker_2d"|"marker_3d"|"plane"|"featurePoint"|"mesh"|undefined} type
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 */
                Object.defineProperty(Trackable.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["marker_2d", "marker_3d", "plane", "featurePoint", "mesh"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Trackable instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {streem.room.ar.ITrackable=} [properties] Properties to set
                 * @returns {streem.room.ar.Trackable} Trackable instance
                 */
                Trackable.create = function create(properties) {
                    return new Trackable(properties);
                };

                /**
                 * Encodes the specified Trackable message. Does not implicitly {@link streem.room.ar.Trackable.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {streem.room.ar.ITrackable} m Trackable message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Trackable.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).bytes(m.id);
                    if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                        $root.streem.api.Pose.encode(m.pose, w.uint32(18).fork()).ldelim();
                    if (m.marker_2d != null && Object.hasOwnProperty.call(m, "marker_2d"))
                        $root.streem.room.ar.Trackable.Marker2d.encode(m.marker_2d, w.uint32(810).fork()).ldelim();
                    if (m.marker_3d != null && Object.hasOwnProperty.call(m, "marker_3d"))
                        $root.streem.room.ar.Trackable.Marker3d.encode(m.marker_3d, w.uint32(818).fork()).ldelim();
                    if (m.plane != null && Object.hasOwnProperty.call(m, "plane"))
                        $root.streem.room.ar.Trackable.Plane.encode(m.plane, w.uint32(826).fork()).ldelim();
                    if (m.featurePoint != null && Object.hasOwnProperty.call(m, "featurePoint"))
                        $root.streem.room.ar.Trackable.FeaturePoint.encode(m.featurePoint, w.uint32(834).fork()).ldelim();
                    if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                        $root.streem.room.ar.Mesh.encode(m.mesh, w.uint32(842).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Trackable message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.Trackable} Trackable
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Trackable.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Trackable();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.bytes();
                                break;
                            }
                        case 2: {
                                m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.marker_2d = $root.streem.room.ar.Trackable.Marker2d.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.marker_3d = $root.streem.room.ar.Trackable.Marker3d.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m.plane = $root.streem.room.ar.Trackable.Plane.decode(r, r.uint32());
                                break;
                            }
                        case 104: {
                                m.featurePoint = $root.streem.room.ar.Trackable.FeaturePoint.decode(r, r.uint32());
                                break;
                            }
                        case 105: {
                                m.mesh = $root.streem.room.ar.Mesh.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Trackable message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.Trackable} Trackable
                 */
                Trackable.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.Trackable)
                        return d;
                    var m = new $root.streem.room.ar.Trackable();
                    if (d.id != null) {
                        if (typeof d.id === "string")
                            $util.base64.decode(d.id, m.id = $util.newBuffer($util.base64.length(d.id)), 0);
                        else if (d.id.length >= 0)
                            m.id = d.id;
                    }
                    if (d.pose != null) {
                        if (typeof d.pose !== "object")
                            throw TypeError(".streem.room.ar.Trackable.pose: object expected");
                        m.pose = $root.streem.api.Pose.fromObject(d.pose);
                    }
                    if (d.marker_2d != null) {
                        if (typeof d.marker_2d !== "object")
                            throw TypeError(".streem.room.ar.Trackable.marker_2d: object expected");
                        m.marker_2d = $root.streem.room.ar.Trackable.Marker2d.fromObject(d.marker_2d);
                    }
                    if (d.marker_3d != null) {
                        if (typeof d.marker_3d !== "object")
                            throw TypeError(".streem.room.ar.Trackable.marker_3d: object expected");
                        m.marker_3d = $root.streem.room.ar.Trackable.Marker3d.fromObject(d.marker_3d);
                    }
                    if (d.plane != null) {
                        if (typeof d.plane !== "object")
                            throw TypeError(".streem.room.ar.Trackable.plane: object expected");
                        m.plane = $root.streem.room.ar.Trackable.Plane.fromObject(d.plane);
                    }
                    if (d.featurePoint != null) {
                        if (typeof d.featurePoint !== "object")
                            throw TypeError(".streem.room.ar.Trackable.featurePoint: object expected");
                        m.featurePoint = $root.streem.room.ar.Trackable.FeaturePoint.fromObject(d.featurePoint);
                    }
                    if (d.mesh != null) {
                        if (typeof d.mesh !== "object")
                            throw TypeError(".streem.room.ar.Trackable.mesh: object expected");
                        m.mesh = $root.streem.room.ar.Mesh.fromObject(d.mesh);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Trackable message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {streem.room.ar.Trackable} m Trackable
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Trackable.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        if (o.bytes === String)
                            d.id = "";
                        else {
                            d.id = [];
                            if (o.bytes !== Array)
                                d.id = $util.newBuffer(d.id);
                        }
                        d.pose = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = o.bytes === String ? $util.base64.encode(m.id, 0, m.id.length) : o.bytes === Array ? Array.prototype.slice.call(m.id) : m.id;
                    }
                    if (m.pose != null && m.hasOwnProperty("pose")) {
                        d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                    }
                    if (m.marker_2d != null && m.hasOwnProperty("marker_2d")) {
                        d.marker_2d = $root.streem.room.ar.Trackable.Marker2d.toObject(m.marker_2d, o);
                        if (o.oneofs)
                            d.type = "marker_2d";
                    }
                    if (m.marker_3d != null && m.hasOwnProperty("marker_3d")) {
                        d.marker_3d = $root.streem.room.ar.Trackable.Marker3d.toObject(m.marker_3d, o);
                        if (o.oneofs)
                            d.type = "marker_3d";
                    }
                    if (m.plane != null && m.hasOwnProperty("plane")) {
                        d.plane = $root.streem.room.ar.Trackable.Plane.toObject(m.plane, o);
                        if (o.oneofs)
                            d.type = "plane";
                    }
                    if (m.featurePoint != null && m.hasOwnProperty("featurePoint")) {
                        d.featurePoint = $root.streem.room.ar.Trackable.FeaturePoint.toObject(m.featurePoint, o);
                        if (o.oneofs)
                            d.type = "featurePoint";
                    }
                    if (m.mesh != null && m.hasOwnProperty("mesh")) {
                        d.mesh = $root.streem.room.ar.Mesh.toObject(m.mesh, o);
                        if (o.oneofs)
                            d.type = "mesh";
                    }
                    return d;
                };

                /**
                 * Converts this Trackable to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.Trackable
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Trackable.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Trackable
                 * @function getTypeUrl
                 * @memberof streem.room.ar.Trackable
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Trackable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.Trackable";
                };

                Trackable.Marker2d = (function() {

                    /**
                     * Properties of a Marker2d.
                     * @memberof streem.room.ar.Trackable
                     * @interface IMarker2d
                     * @property {streem.api.ISize|null} [estimatedSizeMeters] Marker2d estimatedSizeMeters
                     */

                    /**
                     * Constructs a new Marker2d.
                     * @memberof streem.room.ar.Trackable
                     * @classdesc Represents a Marker2d.
                     * @implements IMarker2d
                     * @constructor
                     * @param {streem.room.ar.Trackable.IMarker2d=} [p] Properties to set
                     */
                    function Marker2d(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Marker2d estimatedSizeMeters.
                     * @member {streem.api.ISize|null|undefined} estimatedSizeMeters
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @instance
                     */
                    Marker2d.prototype.estimatedSizeMeters = null;

                    /**
                     * Creates a new Marker2d instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {streem.room.ar.Trackable.IMarker2d=} [properties] Properties to set
                     * @returns {streem.room.ar.Trackable.Marker2d} Marker2d instance
                     */
                    Marker2d.create = function create(properties) {
                        return new Marker2d(properties);
                    };

                    /**
                     * Encodes the specified Marker2d message. Does not implicitly {@link streem.room.ar.Trackable.Marker2d.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {streem.room.ar.Trackable.IMarker2d} m Marker2d message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Marker2d.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.estimatedSizeMeters != null && Object.hasOwnProperty.call(m, "estimatedSizeMeters"))
                            $root.streem.api.Size.encode(m.estimatedSizeMeters, w.uint32(26).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Marker2d message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.Trackable.Marker2d} Marker2d
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Marker2d.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Trackable.Marker2d();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 3: {
                                    m.estimatedSizeMeters = $root.streem.api.Size.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Marker2d message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.Trackable.Marker2d} Marker2d
                     */
                    Marker2d.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.Trackable.Marker2d)
                            return d;
                        var m = new $root.streem.room.ar.Trackable.Marker2d();
                        if (d.estimatedSizeMeters != null) {
                            if (typeof d.estimatedSizeMeters !== "object")
                                throw TypeError(".streem.room.ar.Trackable.Marker2d.estimatedSizeMeters: object expected");
                            m.estimatedSizeMeters = $root.streem.api.Size.fromObject(d.estimatedSizeMeters);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Marker2d message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {streem.room.ar.Trackable.Marker2d} m Marker2d
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Marker2d.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.estimatedSizeMeters = null;
                        }
                        if (m.estimatedSizeMeters != null && m.hasOwnProperty("estimatedSizeMeters")) {
                            d.estimatedSizeMeters = $root.streem.api.Size.toObject(m.estimatedSizeMeters, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Marker2d to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Marker2d.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Marker2d
                     * @function getTypeUrl
                     * @memberof streem.room.ar.Trackable.Marker2d
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Marker2d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.Trackable.Marker2d";
                    };

                    return Marker2d;
                })();

                Trackable.Marker3d = (function() {

                    /**
                     * Properties of a Marker3d.
                     * @memberof streem.room.ar.Trackable
                     * @interface IMarker3d
                     */

                    /**
                     * Constructs a new Marker3d.
                     * @memberof streem.room.ar.Trackable
                     * @classdesc Represents a Marker3d.
                     * @implements IMarker3d
                     * @constructor
                     * @param {streem.room.ar.Trackable.IMarker3d=} [p] Properties to set
                     */
                    function Marker3d(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Marker3d instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {streem.room.ar.Trackable.IMarker3d=} [properties] Properties to set
                     * @returns {streem.room.ar.Trackable.Marker3d} Marker3d instance
                     */
                    Marker3d.create = function create(properties) {
                        return new Marker3d(properties);
                    };

                    /**
                     * Encodes the specified Marker3d message. Does not implicitly {@link streem.room.ar.Trackable.Marker3d.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {streem.room.ar.Trackable.IMarker3d} m Marker3d message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Marker3d.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Marker3d message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.Trackable.Marker3d} Marker3d
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Marker3d.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Trackable.Marker3d();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Marker3d message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.Trackable.Marker3d} Marker3d
                     */
                    Marker3d.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.Trackable.Marker3d)
                            return d;
                        return new $root.streem.room.ar.Trackable.Marker3d();
                    };

                    /**
                     * Creates a plain object from a Marker3d message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {streem.room.ar.Trackable.Marker3d} m Marker3d
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Marker3d.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Marker3d to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Marker3d.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Marker3d
                     * @function getTypeUrl
                     * @memberof streem.room.ar.Trackable.Marker3d
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Marker3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.Trackable.Marker3d";
                    };

                    return Marker3d;
                })();

                Trackable.Plane = (function() {

                    /**
                     * Properties of a Plane.
                     * @memberof streem.room.ar.Trackable
                     * @interface IPlane
                     * @property {streem.room.ar.Trackable.Plane.Alignment|null} [alignment] Plane alignment
                     * @property {streem.room.ar.Trackable.Plane.Classification|null} [classification] Plane classification
                     * @property {number|null} [extentX] Plane extentX
                     * @property {number|null} [extentZ] Plane extentZ
                     * @property {Array.<streem.api.IPoint>|null} [boundaryPolygon] Plane boundaryPolygon
                     */

                    /**
                     * Constructs a new Plane.
                     * @memberof streem.room.ar.Trackable
                     * @classdesc Represents a Plane.
                     * @implements IPlane
                     * @constructor
                     * @param {streem.room.ar.Trackable.IPlane=} [p] Properties to set
                     */
                    function Plane(p) {
                        this.boundaryPolygon = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Plane alignment.
                     * @member {streem.room.ar.Trackable.Plane.Alignment} alignment
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     */
                    Plane.prototype.alignment = 0;

                    /**
                     * Plane classification.
                     * @member {streem.room.ar.Trackable.Plane.Classification} classification
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     */
                    Plane.prototype.classification = 0;

                    /**
                     * Plane extentX.
                     * @member {number} extentX
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     */
                    Plane.prototype.extentX = 0;

                    /**
                     * Plane extentZ.
                     * @member {number} extentZ
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     */
                    Plane.prototype.extentZ = 0;

                    /**
                     * Plane boundaryPolygon.
                     * @member {Array.<streem.api.IPoint>} boundaryPolygon
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     */
                    Plane.prototype.boundaryPolygon = $util.emptyArray;

                    /**
                     * Creates a new Plane instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {streem.room.ar.Trackable.IPlane=} [properties] Properties to set
                     * @returns {streem.room.ar.Trackable.Plane} Plane instance
                     */
                    Plane.create = function create(properties) {
                        return new Plane(properties);
                    };

                    /**
                     * Encodes the specified Plane message. Does not implicitly {@link streem.room.ar.Trackable.Plane.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {streem.room.ar.Trackable.IPlane} m Plane message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Plane.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.alignment != null && Object.hasOwnProperty.call(m, "alignment"))
                            w.uint32(8).int32(m.alignment);
                        if (m.classification != null && Object.hasOwnProperty.call(m, "classification"))
                            w.uint32(16).int32(m.classification);
                        if (m.extentX != null && Object.hasOwnProperty.call(m, "extentX"))
                            w.uint32(29).float(m.extentX);
                        if (m.extentZ != null && Object.hasOwnProperty.call(m, "extentZ"))
                            w.uint32(37).float(m.extentZ);
                        if (m.boundaryPolygon != null && m.boundaryPolygon.length) {
                            for (var i = 0; i < m.boundaryPolygon.length; ++i)
                                $root.streem.api.Point.encode(m.boundaryPolygon[i], w.uint32(42).fork()).ldelim();
                        }
                        return w;
                    };

                    /**
                     * Decodes a Plane message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.Trackable.Plane} Plane
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Plane.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Trackable.Plane();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.alignment = r.int32();
                                    break;
                                }
                            case 2: {
                                    m.classification = r.int32();
                                    break;
                                }
                            case 3: {
                                    m.extentX = r.float();
                                    break;
                                }
                            case 4: {
                                    m.extentZ = r.float();
                                    break;
                                }
                            case 5: {
                                    if (!(m.boundaryPolygon && m.boundaryPolygon.length))
                                        m.boundaryPolygon = [];
                                    m.boundaryPolygon.push($root.streem.api.Point.decode(r, r.uint32()));
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Plane message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.Trackable.Plane} Plane
                     */
                    Plane.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.Trackable.Plane)
                            return d;
                        var m = new $root.streem.room.ar.Trackable.Plane();
                        switch (d.alignment) {
                        default:
                            if (typeof d.alignment === "number") {
                                m.alignment = d.alignment;
                                break;
                            }
                            break;
                        case "ALIGNMENT_UNKNOWN":
                        case 0:
                            m.alignment = 0;
                            break;
                        case "ALIGNMENT_HORIZONTAL":
                        case 1:
                            m.alignment = 1;
                            break;
                        case "ALIGNMENT_VERTICAL":
                        case 2:
                            m.alignment = 2;
                            break;
                        }
                        switch (d.classification) {
                        default:
                            if (typeof d.classification === "number") {
                                m.classification = d.classification;
                                break;
                            }
                            break;
                        case "CLASSIFICATION_UNKNOWN":
                        case 0:
                            m.classification = 0;
                            break;
                        case "CLASSIFICATION_WALL":
                        case 1:
                            m.classification = 1;
                            break;
                        case "CLASSIFICATION_FLOOR":
                        case 2:
                            m.classification = 2;
                            break;
                        case "CLASSIFICATION_CEILING":
                        case 3:
                            m.classification = 3;
                            break;
                        case "CLASSIFICATION_TABLE":
                        case 4:
                            m.classification = 4;
                            break;
                        case "CLASSIFICATION_SEAT":
                        case 5:
                            m.classification = 5;
                            break;
                        case "CLASSIFICATION_DOOR":
                        case 6:
                            m.classification = 6;
                            break;
                        case "CLASSIFICATION_WINDOW":
                        case 7:
                            m.classification = 7;
                            break;
                        case "CLASSIFICATION_DOWNWARD_FACING":
                        case 8:
                            m.classification = 8;
                            break;
                        case "CLASSIFICATION_UPWARD_FACING":
                        case 9:
                            m.classification = 9;
                            break;
                        }
                        if (d.extentX != null) {
                            m.extentX = Number(d.extentX);
                        }
                        if (d.extentZ != null) {
                            m.extentZ = Number(d.extentZ);
                        }
                        if (d.boundaryPolygon) {
                            if (!Array.isArray(d.boundaryPolygon))
                                throw TypeError(".streem.room.ar.Trackable.Plane.boundaryPolygon: array expected");
                            m.boundaryPolygon = [];
                            for (var i = 0; i < d.boundaryPolygon.length; ++i) {
                                if (typeof d.boundaryPolygon[i] !== "object")
                                    throw TypeError(".streem.room.ar.Trackable.Plane.boundaryPolygon: object expected");
                                m.boundaryPolygon[i] = $root.streem.api.Point.fromObject(d.boundaryPolygon[i]);
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Plane message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {streem.room.ar.Trackable.Plane} m Plane
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Plane.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.boundaryPolygon = [];
                        }
                        if (o.defaults) {
                            d.alignment = o.enums === String ? "ALIGNMENT_UNKNOWN" : 0;
                            d.classification = o.enums === String ? "CLASSIFICATION_UNKNOWN" : 0;
                            d.extentX = 0;
                            d.extentZ = 0;
                        }
                        if (m.alignment != null && m.hasOwnProperty("alignment")) {
                            d.alignment = o.enums === String ? $root.streem.room.ar.Trackable.Plane.Alignment[m.alignment] === undefined ? m.alignment : $root.streem.room.ar.Trackable.Plane.Alignment[m.alignment] : m.alignment;
                        }
                        if (m.classification != null && m.hasOwnProperty("classification")) {
                            d.classification = o.enums === String ? $root.streem.room.ar.Trackable.Plane.Classification[m.classification] === undefined ? m.classification : $root.streem.room.ar.Trackable.Plane.Classification[m.classification] : m.classification;
                        }
                        if (m.extentX != null && m.hasOwnProperty("extentX")) {
                            d.extentX = o.json && !isFinite(m.extentX) ? String(m.extentX) : m.extentX;
                        }
                        if (m.extentZ != null && m.hasOwnProperty("extentZ")) {
                            d.extentZ = o.json && !isFinite(m.extentZ) ? String(m.extentZ) : m.extentZ;
                        }
                        if (m.boundaryPolygon && m.boundaryPolygon.length) {
                            d.boundaryPolygon = [];
                            for (var j = 0; j < m.boundaryPolygon.length; ++j) {
                                d.boundaryPolygon[j] = $root.streem.api.Point.toObject(m.boundaryPolygon[j], o);
                            }
                        }
                        return d;
                    };

                    /**
                     * Converts this Plane to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.Trackable.Plane
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Plane.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Plane
                     * @function getTypeUrl
                     * @memberof streem.room.ar.Trackable.Plane
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Plane.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.Trackable.Plane";
                    };

                    /**
                     * Alignment enum.
                     * @name streem.room.ar.Trackable.Plane.Alignment
                     * @enum {number}
                     * @property {number} ALIGNMENT_UNKNOWN=0 ALIGNMENT_UNKNOWN value
                     * @property {number} ALIGNMENT_HORIZONTAL=1 ALIGNMENT_HORIZONTAL value
                     * @property {number} ALIGNMENT_VERTICAL=2 ALIGNMENT_VERTICAL value
                     */
                    Plane.Alignment = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "ALIGNMENT_UNKNOWN"] = 0;
                        values[valuesById[1] = "ALIGNMENT_HORIZONTAL"] = 1;
                        values[valuesById[2] = "ALIGNMENT_VERTICAL"] = 2;
                        return values;
                    })();

                    /**
                     * Classification enum.
                     * @name streem.room.ar.Trackable.Plane.Classification
                     * @enum {number}
                     * @property {number} CLASSIFICATION_UNKNOWN=0 CLASSIFICATION_UNKNOWN value
                     * @property {number} CLASSIFICATION_WALL=1 CLASSIFICATION_WALL value
                     * @property {number} CLASSIFICATION_FLOOR=2 CLASSIFICATION_FLOOR value
                     * @property {number} CLASSIFICATION_CEILING=3 CLASSIFICATION_CEILING value
                     * @property {number} CLASSIFICATION_TABLE=4 CLASSIFICATION_TABLE value
                     * @property {number} CLASSIFICATION_SEAT=5 CLASSIFICATION_SEAT value
                     * @property {number} CLASSIFICATION_DOOR=6 CLASSIFICATION_DOOR value
                     * @property {number} CLASSIFICATION_WINDOW=7 CLASSIFICATION_WINDOW value
                     * @property {number} CLASSIFICATION_DOWNWARD_FACING=8 CLASSIFICATION_DOWNWARD_FACING value
                     * @property {number} CLASSIFICATION_UPWARD_FACING=9 CLASSIFICATION_UPWARD_FACING value
                     */
                    Plane.Classification = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "CLASSIFICATION_UNKNOWN"] = 0;
                        values[valuesById[1] = "CLASSIFICATION_WALL"] = 1;
                        values[valuesById[2] = "CLASSIFICATION_FLOOR"] = 2;
                        values[valuesById[3] = "CLASSIFICATION_CEILING"] = 3;
                        values[valuesById[4] = "CLASSIFICATION_TABLE"] = 4;
                        values[valuesById[5] = "CLASSIFICATION_SEAT"] = 5;
                        values[valuesById[6] = "CLASSIFICATION_DOOR"] = 6;
                        values[valuesById[7] = "CLASSIFICATION_WINDOW"] = 7;
                        values[valuesById[8] = "CLASSIFICATION_DOWNWARD_FACING"] = 8;
                        values[valuesById[9] = "CLASSIFICATION_UPWARD_FACING"] = 9;
                        return values;
                    })();

                    return Plane;
                })();

                Trackable.FeaturePoint = (function() {

                    /**
                     * Properties of a FeaturePoint.
                     * @memberof streem.room.ar.Trackable
                     * @interface IFeaturePoint
                     * @property {number|null} [confidence] FeaturePoint confidence
                     */

                    /**
                     * Constructs a new FeaturePoint.
                     * @memberof streem.room.ar.Trackable
                     * @classdesc Represents a FeaturePoint.
                     * @implements IFeaturePoint
                     * @constructor
                     * @param {streem.room.ar.Trackable.IFeaturePoint=} [p] Properties to set
                     */
                    function FeaturePoint(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * FeaturePoint confidence.
                     * @member {number} confidence
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @instance
                     */
                    FeaturePoint.prototype.confidence = 0;

                    /**
                     * Creates a new FeaturePoint instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {streem.room.ar.Trackable.IFeaturePoint=} [properties] Properties to set
                     * @returns {streem.room.ar.Trackable.FeaturePoint} FeaturePoint instance
                     */
                    FeaturePoint.create = function create(properties) {
                        return new FeaturePoint(properties);
                    };

                    /**
                     * Encodes the specified FeaturePoint message. Does not implicitly {@link streem.room.ar.Trackable.FeaturePoint.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {streem.room.ar.Trackable.IFeaturePoint} m FeaturePoint message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FeaturePoint.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.confidence != null && Object.hasOwnProperty.call(m, "confidence"))
                            w.uint32(13).float(m.confidence);
                        return w;
                    };

                    /**
                     * Decodes a FeaturePoint message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.Trackable.FeaturePoint} FeaturePoint
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FeaturePoint.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Trackable.FeaturePoint();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.confidence = r.float();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a FeaturePoint message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.Trackable.FeaturePoint} FeaturePoint
                     */
                    FeaturePoint.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.Trackable.FeaturePoint)
                            return d;
                        var m = new $root.streem.room.ar.Trackable.FeaturePoint();
                        if (d.confidence != null) {
                            m.confidence = Number(d.confidence);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a FeaturePoint message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {streem.room.ar.Trackable.FeaturePoint} m FeaturePoint
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FeaturePoint.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.confidence = 0;
                        }
                        if (m.confidence != null && m.hasOwnProperty("confidence")) {
                            d.confidence = o.json && !isFinite(m.confidence) ? String(m.confidence) : m.confidence;
                        }
                        return d;
                    };

                    /**
                     * Converts this FeaturePoint to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FeaturePoint.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for FeaturePoint
                     * @function getTypeUrl
                     * @memberof streem.room.ar.Trackable.FeaturePoint
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    FeaturePoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.Trackable.FeaturePoint";
                    };

                    return FeaturePoint;
                })();

                return Trackable;
            })();

            /**
             * TrackableScanningStatus enum.
             * @name streem.room.ar.TrackableScanningStatus
             * @enum {number}
             * @property {number} TRACKABLE_SCANNING_STATUS_INVALID=0 TRACKABLE_SCANNING_STATUS_INVALID value
             * @property {number} TRACKABLE_SCANNING_STATUS_ERROR=1 TRACKABLE_SCANNING_STATUS_ERROR value
             * @property {number} TRACKABLE_SCANNING_STATUS_SCANNING=2 TRACKABLE_SCANNING_STATUS_SCANNING value
             */
            ar.TrackableScanningStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TRACKABLE_SCANNING_STATUS_INVALID"] = 0;
                values[valuesById[1] = "TRACKABLE_SCANNING_STATUS_ERROR"] = 1;
                values[valuesById[2] = "TRACKABLE_SCANNING_STATUS_SCANNING"] = 2;
                return values;
            })();

            /**
             * TrackableStatus enum.
             * @name streem.room.ar.TrackableStatus
             * @enum {number}
             * @property {number} TRACKABLE_STATUS_INVALID=0 TRACKABLE_STATUS_INVALID value
             * @property {number} TRACKABLE_STATUS_NORMAL=1 TRACKABLE_STATUS_NORMAL value
             * @property {number} TRACKABLE_STATUS_PAUSED=2 TRACKABLE_STATUS_PAUSED value
             * @property {number} TRACKABLE_STATUS_STOPPED=3 TRACKABLE_STATUS_STOPPED value
             */
            ar.TrackableStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TRACKABLE_STATUS_INVALID"] = 0;
                values[valuesById[1] = "TRACKABLE_STATUS_NORMAL"] = 1;
                values[valuesById[2] = "TRACKABLE_STATUS_PAUSED"] = 2;
                values[valuesById[3] = "TRACKABLE_STATUS_STOPPED"] = 3;
                return values;
            })();

            ar.Anchor = (function() {

                /**
                 * Properties of an Anchor.
                 * @memberof streem.room.ar
                 * @interface IAnchor
                 * @property {string|null} [id] Anchor id
                 * @property {streem.api.IPose|null} [pose] Anchor pose
                 */

                /**
                 * Constructs a new Anchor.
                 * @memberof streem.room.ar
                 * @classdesc Represents an Anchor.
                 * @implements IAnchor
                 * @constructor
                 * @param {streem.room.ar.IAnchor=} [p] Properties to set
                 */
                function Anchor(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Anchor id.
                 * @member {string} id
                 * @memberof streem.room.ar.Anchor
                 * @instance
                 */
                Anchor.prototype.id = "";

                /**
                 * Anchor pose.
                 * @member {streem.api.IPose|null|undefined} pose
                 * @memberof streem.room.ar.Anchor
                 * @instance
                 */
                Anchor.prototype.pose = null;

                /**
                 * Creates a new Anchor instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {streem.room.ar.IAnchor=} [properties] Properties to set
                 * @returns {streem.room.ar.Anchor} Anchor instance
                 */
                Anchor.create = function create(properties) {
                    return new Anchor(properties);
                };

                /**
                 * Encodes the specified Anchor message. Does not implicitly {@link streem.room.ar.Anchor.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {streem.room.ar.IAnchor} m Anchor message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Anchor.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                        $root.streem.api.Pose.encode(m.pose, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an Anchor message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.Anchor} Anchor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Anchor.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Anchor();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Anchor message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.Anchor} Anchor
                 */
                Anchor.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.Anchor)
                        return d;
                    var m = new $root.streem.room.ar.Anchor();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.pose != null) {
                        if (typeof d.pose !== "object")
                            throw TypeError(".streem.room.ar.Anchor.pose: object expected");
                        m.pose = $root.streem.api.Pose.fromObject(d.pose);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Anchor message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {streem.room.ar.Anchor} m Anchor
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Anchor.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.id = "";
                        d.pose = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.pose != null && m.hasOwnProperty("pose")) {
                        d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                    }
                    return d;
                };

                /**
                 * Converts this Anchor to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.Anchor
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Anchor.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Anchor
                 * @function getTypeUrl
                 * @memberof streem.room.ar.Anchor
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Anchor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.Anchor";
                };

                return Anchor;
            })();

            /**
             * AnchorTrackingStatus enum.
             * @name streem.room.ar.AnchorTrackingStatus
             * @enum {number}
             * @property {number} ANCHOR_TRACKING_STATUS_INVALID=0 ANCHOR_TRACKING_STATUS_INVALID value
             * @property {number} ANCHOR_TRACKING_STATUS_ERROR=1 ANCHOR_TRACKING_STATUS_ERROR value
             * @property {number} ANCHOR_TRACKING_STATUS_NORMAL=2 ANCHOR_TRACKING_STATUS_NORMAL value
             * @property {number} ANCHOR_TRACKING_STATUS_PAUSED=3 ANCHOR_TRACKING_STATUS_PAUSED value
             * @property {number} ANCHOR_TRACKING_STATUS_STOPPED=4 ANCHOR_TRACKING_STATUS_STOPPED value
             */
            ar.AnchorTrackingStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ANCHOR_TRACKING_STATUS_INVALID"] = 0;
                values[valuesById[1] = "ANCHOR_TRACKING_STATUS_ERROR"] = 1;
                values[valuesById[2] = "ANCHOR_TRACKING_STATUS_NORMAL"] = 2;
                values[valuesById[3] = "ANCHOR_TRACKING_STATUS_PAUSED"] = 3;
                values[valuesById[4] = "ANCHOR_TRACKING_STATUS_STOPPED"] = 4;
                return values;
            })();

            /**
             * CameraTrackingStatus enum.
             * @name streem.room.ar.CameraTrackingStatus
             * @enum {number}
             * @property {number} CAMERA_TRACKING_STATUS_STOPPED=0 CAMERA_TRACKING_STATUS_STOPPED value
             * @property {number} CAMERA_TRACKING_STATUS_NORMAL=1 CAMERA_TRACKING_STATUS_NORMAL value
             * @property {number} CAMERA_TRACKING_STATUS_PAUSED=2 CAMERA_TRACKING_STATUS_PAUSED value
             */
            ar.CameraTrackingStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CAMERA_TRACKING_STATUS_STOPPED"] = 0;
                values[valuesById[1] = "CAMERA_TRACKING_STATUS_NORMAL"] = 1;
                values[valuesById[2] = "CAMERA_TRACKING_STATUS_PAUSED"] = 2;
                return values;
            })();

            /**
             * CameraTrackingPausedReason enum.
             * @name streem.room.ar.CameraTrackingPausedReason
             * @enum {number}
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_UNKNOWN=0 CAMERA_TRACKING_PAUSED_REASON_UNKNOWN value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_INITIALIZING=1 CAMERA_TRACKING_PAUSED_REASON_INITIALIZING value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_RELOCALIZING=2 CAMERA_TRACKING_PAUSED_REASON_RELOCALIZING value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_EXCESSIVE_MOTION=3 CAMERA_TRACKING_PAUSED_REASON_EXCESSIVE_MOTION value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_FEATURES=4 CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_FEATURES value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_LIGHT=5 CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_LIGHT value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_CAMERA_UNAVAILABLE=6 CAMERA_TRACKING_PAUSED_REASON_CAMERA_UNAVAILABLE value
             * @property {number} CAMERA_TRACKING_PAUSED_REASON_BAD_STATE=7 CAMERA_TRACKING_PAUSED_REASON_BAD_STATE value
             */
            ar.CameraTrackingPausedReason = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CAMERA_TRACKING_PAUSED_REASON_UNKNOWN"] = 0;
                values[valuesById[1] = "CAMERA_TRACKING_PAUSED_REASON_INITIALIZING"] = 1;
                values[valuesById[2] = "CAMERA_TRACKING_PAUSED_REASON_RELOCALIZING"] = 2;
                values[valuesById[3] = "CAMERA_TRACKING_PAUSED_REASON_EXCESSIVE_MOTION"] = 3;
                values[valuesById[4] = "CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_FEATURES"] = 4;
                values[valuesById[5] = "CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_LIGHT"] = 5;
                values[valuesById[6] = "CAMERA_TRACKING_PAUSED_REASON_CAMERA_UNAVAILABLE"] = 6;
                values[valuesById[7] = "CAMERA_TRACKING_PAUSED_REASON_BAD_STATE"] = 7;
                return values;
            })();

            /**
             * WorldMappingStatus enum.
             * @name streem.room.ar.WorldMappingStatus
             * @enum {number}
             * @property {number} WORLD_MAPPING_STATUS_UNKNOWN=0 WORLD_MAPPING_STATUS_UNKNOWN value
             * @property {number} WORLD_MAPPING_STATUS_INITIALIZING=1 WORLD_MAPPING_STATUS_INITIALIZING value
             * @property {number} WORLD_MAPPING_STATUS_LIMITED=2 WORLD_MAPPING_STATUS_LIMITED value
             * @property {number} WORLD_MAPPING_STATUS_EXTENDING=3 WORLD_MAPPING_STATUS_EXTENDING value
             * @property {number} WORLD_MAPPING_STATUS_MAPPED=4 WORLD_MAPPING_STATUS_MAPPED value
             */
            ar.WorldMappingStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WORLD_MAPPING_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "WORLD_MAPPING_STATUS_INITIALIZING"] = 1;
                values[valuesById[2] = "WORLD_MAPPING_STATUS_LIMITED"] = 2;
                values[valuesById[3] = "WORLD_MAPPING_STATUS_EXTENDING"] = 3;
                values[valuesById[4] = "WORLD_MAPPING_STATUS_MAPPED"] = 4;
                return values;
            })();

            ar.Vignette = (function() {

                /**
                 * Properties of a Vignette.
                 * @memberof streem.room.ar
                 * @interface IVignette
                 * @property {string|null} [version] Vignette version
                 * @property {string|null} [id] Vignette id
                 * @property {Array.<streem.room.ar.IVignetteSlot>|null} [slots] Vignette slots
                 * @property {Array.<ICatalogItem>|null} [catalog] Vignette catalog
                 * @property {streem.room.ar.IVignetteScene|null} [scene] Vignette scene
                 * @property {Array.<IRoomString>|null} [localizableStrings] Vignette localizableStrings
                 */

                /**
                 * Constructs a new Vignette.
                 * @memberof streem.room.ar
                 * @classdesc Represents a Vignette.
                 * @implements IVignette
                 * @constructor
                 * @param {streem.room.ar.IVignette=} [p] Properties to set
                 */
                function Vignette(p) {
                    this.slots = [];
                    this.catalog = [];
                    this.localizableStrings = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Vignette version.
                 * @member {string} version
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.version = "";

                /**
                 * Vignette id.
                 * @member {string} id
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.id = "";

                /**
                 * Vignette slots.
                 * @member {Array.<streem.room.ar.IVignetteSlot>} slots
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.slots = $util.emptyArray;

                /**
                 * Vignette catalog.
                 * @member {Array.<ICatalogItem>} catalog
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.catalog = $util.emptyArray;

                /**
                 * Vignette scene.
                 * @member {streem.room.ar.IVignetteScene|null|undefined} scene
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.scene = null;

                /**
                 * Vignette localizableStrings.
                 * @member {Array.<IRoomString>} localizableStrings
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 */
                Vignette.prototype.localizableStrings = $util.emptyArray;

                /**
                 * Creates a new Vignette instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {streem.room.ar.IVignette=} [properties] Properties to set
                 * @returns {streem.room.ar.Vignette} Vignette instance
                 */
                Vignette.create = function create(properties) {
                    return new Vignette(properties);
                };

                /**
                 * Encodes the specified Vignette message. Does not implicitly {@link streem.room.ar.Vignette.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {streem.room.ar.IVignette} m Vignette message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Vignette.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                        w.uint32(10).string(m.version);
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(18).string(m.id);
                    if (m.slots != null && m.slots.length) {
                        for (var i = 0; i < m.slots.length; ++i)
                            $root.streem.room.ar.VignetteSlot.encode(m.slots[i], w.uint32(26).fork()).ldelim();
                    }
                    if (m.catalog != null && m.catalog.length) {
                        for (var i = 0; i < m.catalog.length; ++i)
                            $root.CatalogItem.encode(m.catalog[i], w.uint32(34).fork()).ldelim();
                    }
                    if (m.scene != null && Object.hasOwnProperty.call(m, "scene"))
                        $root.streem.room.ar.VignetteScene.encode(m.scene, w.uint32(42).fork()).ldelim();
                    if (m.localizableStrings != null && m.localizableStrings.length) {
                        for (var i = 0; i < m.localizableStrings.length; ++i)
                            $root.RoomString.encode(m.localizableStrings[i], w.uint32(58).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a Vignette message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.Vignette} Vignette
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Vignette.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Vignette();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.version = r.string();
                                break;
                            }
                        case 2: {
                                m.id = r.string();
                                break;
                            }
                        case 3: {
                                if (!(m.slots && m.slots.length))
                                    m.slots = [];
                                m.slots.push($root.streem.room.ar.VignetteSlot.decode(r, r.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(m.catalog && m.catalog.length))
                                    m.catalog = [];
                                m.catalog.push($root.CatalogItem.decode(r, r.uint32()));
                                break;
                            }
                        case 5: {
                                m.scene = $root.streem.room.ar.VignetteScene.decode(r, r.uint32());
                                break;
                            }
                        case 7: {
                                if (!(m.localizableStrings && m.localizableStrings.length))
                                    m.localizableStrings = [];
                                m.localizableStrings.push($root.RoomString.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Vignette message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.Vignette} Vignette
                 */
                Vignette.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.Vignette)
                        return d;
                    var m = new $root.streem.room.ar.Vignette();
                    if (d.version != null) {
                        m.version = String(d.version);
                    }
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.slots) {
                        if (!Array.isArray(d.slots))
                            throw TypeError(".streem.room.ar.Vignette.slots: array expected");
                        m.slots = [];
                        for (var i = 0; i < d.slots.length; ++i) {
                            if (typeof d.slots[i] !== "object")
                                throw TypeError(".streem.room.ar.Vignette.slots: object expected");
                            m.slots[i] = $root.streem.room.ar.VignetteSlot.fromObject(d.slots[i]);
                        }
                    }
                    if (d.catalog) {
                        if (!Array.isArray(d.catalog))
                            throw TypeError(".streem.room.ar.Vignette.catalog: array expected");
                        m.catalog = [];
                        for (var i = 0; i < d.catalog.length; ++i) {
                            if (typeof d.catalog[i] !== "object")
                                throw TypeError(".streem.room.ar.Vignette.catalog: object expected");
                            m.catalog[i] = $root.CatalogItem.fromObject(d.catalog[i]);
                        }
                    }
                    if (d.scene != null) {
                        if (typeof d.scene !== "object")
                            throw TypeError(".streem.room.ar.Vignette.scene: object expected");
                        m.scene = $root.streem.room.ar.VignetteScene.fromObject(d.scene);
                    }
                    if (d.localizableStrings) {
                        if (!Array.isArray(d.localizableStrings))
                            throw TypeError(".streem.room.ar.Vignette.localizableStrings: array expected");
                        m.localizableStrings = [];
                        for (var i = 0; i < d.localizableStrings.length; ++i) {
                            if (typeof d.localizableStrings[i] !== "object")
                                throw TypeError(".streem.room.ar.Vignette.localizableStrings: object expected");
                            m.localizableStrings[i] = $root.RoomString.fromObject(d.localizableStrings[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Vignette message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {streem.room.ar.Vignette} m Vignette
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Vignette.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.slots = [];
                        d.catalog = [];
                        d.localizableStrings = [];
                    }
                    if (o.defaults) {
                        d.version = "";
                        d.id = "";
                        d.scene = null;
                    }
                    if (m.version != null && m.hasOwnProperty("version")) {
                        d.version = m.version;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.slots && m.slots.length) {
                        d.slots = [];
                        for (var j = 0; j < m.slots.length; ++j) {
                            d.slots[j] = $root.streem.room.ar.VignetteSlot.toObject(m.slots[j], o);
                        }
                    }
                    if (m.catalog && m.catalog.length) {
                        d.catalog = [];
                        for (var j = 0; j < m.catalog.length; ++j) {
                            d.catalog[j] = $root.CatalogItem.toObject(m.catalog[j], o);
                        }
                    }
                    if (m.scene != null && m.hasOwnProperty("scene")) {
                        d.scene = $root.streem.room.ar.VignetteScene.toObject(m.scene, o);
                    }
                    if (m.localizableStrings && m.localizableStrings.length) {
                        d.localizableStrings = [];
                        for (var j = 0; j < m.localizableStrings.length; ++j) {
                            d.localizableStrings[j] = $root.RoomString.toObject(m.localizableStrings[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Vignette to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.Vignette
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Vignette.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Vignette
                 * @function getTypeUrl
                 * @memberof streem.room.ar.Vignette
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Vignette.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.Vignette";
                };

                return Vignette;
            })();

            ar.VignetteScene = (function() {

                /**
                 * Properties of a VignetteScene.
                 * @memberof streem.room.ar
                 * @interface IVignetteScene
                 * @property {string|null} [id] VignetteScene id
                 * @property {Model3d.IItem|null} [model] VignetteScene model
                 * @property {string|null} [initialPortalHotspotId] VignetteScene initialPortalHotspotId
                 * @property {Array.<streem.room.ar.IVignetteHotspot>|null} [hotspots] VignetteScene hotspots
                 * @property {Array.<ILight>|null} [lighting] VignetteScene lighting
                 */

                /**
                 * Constructs a new VignetteScene.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteScene.
                 * @implements IVignetteScene
                 * @constructor
                 * @param {streem.room.ar.IVignetteScene=} [p] Properties to set
                 */
                function VignetteScene(p) {
                    this.hotspots = [];
                    this.lighting = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteScene id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 */
                VignetteScene.prototype.id = "";

                /**
                 * VignetteScene model.
                 * @member {Model3d.IItem|null|undefined} model
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 */
                VignetteScene.prototype.model = null;

                /**
                 * VignetteScene initialPortalHotspotId.
                 * @member {string} initialPortalHotspotId
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 */
                VignetteScene.prototype.initialPortalHotspotId = "";

                /**
                 * VignetteScene hotspots.
                 * @member {Array.<streem.room.ar.IVignetteHotspot>} hotspots
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 */
                VignetteScene.prototype.hotspots = $util.emptyArray;

                /**
                 * VignetteScene lighting.
                 * @member {Array.<ILight>} lighting
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 */
                VignetteScene.prototype.lighting = $util.emptyArray;

                /**
                 * Creates a new VignetteScene instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {streem.room.ar.IVignetteScene=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteScene} VignetteScene instance
                 */
                VignetteScene.create = function create(properties) {
                    return new VignetteScene(properties);
                };

                /**
                 * Encodes the specified VignetteScene message. Does not implicitly {@link streem.room.ar.VignetteScene.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {streem.room.ar.IVignetteScene} m VignetteScene message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteScene.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                        $root.Model3d.Item.encode(m.model, w.uint32(18).fork()).ldelim();
                    if (m.initialPortalHotspotId != null && Object.hasOwnProperty.call(m, "initialPortalHotspotId"))
                        w.uint32(26).string(m.initialPortalHotspotId);
                    if (m.hotspots != null && m.hotspots.length) {
                        for (var i = 0; i < m.hotspots.length; ++i)
                            $root.streem.room.ar.VignetteHotspot.encode(m.hotspots[i], w.uint32(34).fork()).ldelim();
                    }
                    if (m.lighting != null && m.lighting.length) {
                        for (var i = 0; i < m.lighting.length; ++i)
                            $root.Light.encode(m.lighting[i], w.uint32(42).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a VignetteScene message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteScene} VignetteScene
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteScene.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteScene();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.model = $root.Model3d.Item.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.initialPortalHotspotId = r.string();
                                break;
                            }
                        case 4: {
                                if (!(m.hotspots && m.hotspots.length))
                                    m.hotspots = [];
                                m.hotspots.push($root.streem.room.ar.VignetteHotspot.decode(r, r.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(m.lighting && m.lighting.length))
                                    m.lighting = [];
                                m.lighting.push($root.Light.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteScene message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteScene} VignetteScene
                 */
                VignetteScene.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteScene)
                        return d;
                    var m = new $root.streem.room.ar.VignetteScene();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.model != null) {
                        if (typeof d.model !== "object")
                            throw TypeError(".streem.room.ar.VignetteScene.model: object expected");
                        m.model = $root.Model3d.Item.fromObject(d.model);
                    }
                    if (d.initialPortalHotspotId != null) {
                        m.initialPortalHotspotId = String(d.initialPortalHotspotId);
                    }
                    if (d.hotspots) {
                        if (!Array.isArray(d.hotspots))
                            throw TypeError(".streem.room.ar.VignetteScene.hotspots: array expected");
                        m.hotspots = [];
                        for (var i = 0; i < d.hotspots.length; ++i) {
                            if (typeof d.hotspots[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteScene.hotspots: object expected");
                            m.hotspots[i] = $root.streem.room.ar.VignetteHotspot.fromObject(d.hotspots[i]);
                        }
                    }
                    if (d.lighting) {
                        if (!Array.isArray(d.lighting))
                            throw TypeError(".streem.room.ar.VignetteScene.lighting: array expected");
                        m.lighting = [];
                        for (var i = 0; i < d.lighting.length; ++i) {
                            if (typeof d.lighting[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteScene.lighting: object expected");
                            m.lighting[i] = $root.Light.fromObject(d.lighting[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteScene message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {streem.room.ar.VignetteScene} m VignetteScene
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteScene.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.hotspots = [];
                        d.lighting = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.model = null;
                        d.initialPortalHotspotId = "";
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.model != null && m.hasOwnProperty("model")) {
                        d.model = $root.Model3d.Item.toObject(m.model, o);
                    }
                    if (m.initialPortalHotspotId != null && m.hasOwnProperty("initialPortalHotspotId")) {
                        d.initialPortalHotspotId = m.initialPortalHotspotId;
                    }
                    if (m.hotspots && m.hotspots.length) {
                        d.hotspots = [];
                        for (var j = 0; j < m.hotspots.length; ++j) {
                            d.hotspots[j] = $root.streem.room.ar.VignetteHotspot.toObject(m.hotspots[j], o);
                        }
                    }
                    if (m.lighting && m.lighting.length) {
                        d.lighting = [];
                        for (var j = 0; j < m.lighting.length; ++j) {
                            d.lighting[j] = $root.Light.toObject(m.lighting[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this VignetteScene to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteScene
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteScene.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteScene
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteScene
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteScene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteScene";
                };

                return VignetteScene;
            })();

            ar.VignetteSlot = (function() {

                /**
                 * Properties of a VignetteSlot.
                 * @memberof streem.room.ar
                 * @interface IVignetteSlot
                 * @property {string|null} [id] VignetteSlot id
                 * @property {IStaticAnchor|null} [anchor] VignetteSlot anchor
                 * @property {string|null} [selectedElementId] VignetteSlot selectedElementId
                 * @property {string|null} [displayNameId] VignetteSlot displayNameId
                 * @property {Array.<streem.room.ar.IVignetteElement>|null} [elements] VignetteSlot elements
                 * @property {number|null} [proximityThreshold] VignetteSlot proximityThreshold
                 */

                /**
                 * Constructs a new VignetteSlot.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteSlot.
                 * @implements IVignetteSlot
                 * @constructor
                 * @param {streem.room.ar.IVignetteSlot=} [p] Properties to set
                 */
                function VignetteSlot(p) {
                    this.elements = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteSlot id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.id = "";

                /**
                 * VignetteSlot anchor.
                 * @member {IStaticAnchor|null|undefined} anchor
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.anchor = null;

                /**
                 * VignetteSlot selectedElementId.
                 * @member {string} selectedElementId
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.selectedElementId = "";

                /**
                 * VignetteSlot displayNameId.
                 * @member {string} displayNameId
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.displayNameId = "";

                /**
                 * VignetteSlot elements.
                 * @member {Array.<streem.room.ar.IVignetteElement>} elements
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.elements = $util.emptyArray;

                /**
                 * VignetteSlot proximityThreshold.
                 * @member {number} proximityThreshold
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 */
                VignetteSlot.prototype.proximityThreshold = 0;

                /**
                 * Creates a new VignetteSlot instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {streem.room.ar.IVignetteSlot=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteSlot} VignetteSlot instance
                 */
                VignetteSlot.create = function create(properties) {
                    return new VignetteSlot(properties);
                };

                /**
                 * Encodes the specified VignetteSlot message. Does not implicitly {@link streem.room.ar.VignetteSlot.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {streem.room.ar.IVignetteSlot} m VignetteSlot message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteSlot.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.anchor != null && Object.hasOwnProperty.call(m, "anchor"))
                        $root.StaticAnchor.encode(m.anchor, w.uint32(18).fork()).ldelim();
                    if (m.selectedElementId != null && Object.hasOwnProperty.call(m, "selectedElementId"))
                        w.uint32(26).string(m.selectedElementId);
                    if (m.displayNameId != null && Object.hasOwnProperty.call(m, "displayNameId"))
                        w.uint32(34).string(m.displayNameId);
                    if (m.elements != null && m.elements.length) {
                        for (var i = 0; i < m.elements.length; ++i)
                            $root.streem.room.ar.VignetteElement.encode(m.elements[i], w.uint32(42).fork()).ldelim();
                    }
                    if (m.proximityThreshold != null && Object.hasOwnProperty.call(m, "proximityThreshold"))
                        w.uint32(53).float(m.proximityThreshold);
                    return w;
                };

                /**
                 * Decodes a VignetteSlot message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteSlot} VignetteSlot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteSlot.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteSlot();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.anchor = $root.StaticAnchor.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.selectedElementId = r.string();
                                break;
                            }
                        case 4: {
                                m.displayNameId = r.string();
                                break;
                            }
                        case 5: {
                                if (!(m.elements && m.elements.length))
                                    m.elements = [];
                                m.elements.push($root.streem.room.ar.VignetteElement.decode(r, r.uint32()));
                                break;
                            }
                        case 6: {
                                m.proximityThreshold = r.float();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteSlot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteSlot} VignetteSlot
                 */
                VignetteSlot.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteSlot)
                        return d;
                    var m = new $root.streem.room.ar.VignetteSlot();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.anchor != null) {
                        if (typeof d.anchor !== "object")
                            throw TypeError(".streem.room.ar.VignetteSlot.anchor: object expected");
                        m.anchor = $root.StaticAnchor.fromObject(d.anchor);
                    }
                    if (d.selectedElementId != null) {
                        m.selectedElementId = String(d.selectedElementId);
                    }
                    if (d.displayNameId != null) {
                        m.displayNameId = String(d.displayNameId);
                    }
                    if (d.elements) {
                        if (!Array.isArray(d.elements))
                            throw TypeError(".streem.room.ar.VignetteSlot.elements: array expected");
                        m.elements = [];
                        for (var i = 0; i < d.elements.length; ++i) {
                            if (typeof d.elements[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteSlot.elements: object expected");
                            m.elements[i] = $root.streem.room.ar.VignetteElement.fromObject(d.elements[i]);
                        }
                    }
                    if (d.proximityThreshold != null) {
                        m.proximityThreshold = Number(d.proximityThreshold);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteSlot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {streem.room.ar.VignetteSlot} m VignetteSlot
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteSlot.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.elements = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.anchor = null;
                        d.selectedElementId = "";
                        d.displayNameId = "";
                        d.proximityThreshold = 0;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.anchor != null && m.hasOwnProperty("anchor")) {
                        d.anchor = $root.StaticAnchor.toObject(m.anchor, o);
                    }
                    if (m.selectedElementId != null && m.hasOwnProperty("selectedElementId")) {
                        d.selectedElementId = m.selectedElementId;
                    }
                    if (m.displayNameId != null && m.hasOwnProperty("displayNameId")) {
                        d.displayNameId = m.displayNameId;
                    }
                    if (m.elements && m.elements.length) {
                        d.elements = [];
                        for (var j = 0; j < m.elements.length; ++j) {
                            d.elements[j] = $root.streem.room.ar.VignetteElement.toObject(m.elements[j], o);
                        }
                    }
                    if (m.proximityThreshold != null && m.hasOwnProperty("proximityThreshold")) {
                        d.proximityThreshold = o.json && !isFinite(m.proximityThreshold) ? String(m.proximityThreshold) : m.proximityThreshold;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteSlot to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteSlot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteSlot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteSlot
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteSlot
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteSlot";
                };

                return VignetteSlot;
            })();

            ar.VignetteElement = (function() {

                /**
                 * Properties of a VignetteElement.
                 * @memberof streem.room.ar
                 * @interface IVignetteElement
                 * @property {string|null} [id] VignetteElement id
                 * @property {string|null} [selectedStateId] VignetteElement selectedStateId
                 * @property {string|null} [thumbnailUrl] VignetteElement thumbnailUrl
                 * @property {Array.<streem.room.ar.IVignetteElementState>|null} [states] VignetteElement states
                 * @property {string|null} [selectedFinishId] VignetteElement selectedFinishId
                 */

                /**
                 * Constructs a new VignetteElement.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteElement.
                 * @implements IVignetteElement
                 * @constructor
                 * @param {streem.room.ar.IVignetteElement=} [p] Properties to set
                 */
                function VignetteElement(p) {
                    this.states = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteElement id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 */
                VignetteElement.prototype.id = "";

                /**
                 * VignetteElement selectedStateId.
                 * @member {string} selectedStateId
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 */
                VignetteElement.prototype.selectedStateId = "";

                /**
                 * VignetteElement thumbnailUrl.
                 * @member {string} thumbnailUrl
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 */
                VignetteElement.prototype.thumbnailUrl = "";

                /**
                 * VignetteElement states.
                 * @member {Array.<streem.room.ar.IVignetteElementState>} states
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 */
                VignetteElement.prototype.states = $util.emptyArray;

                /**
                 * VignetteElement selectedFinishId.
                 * @member {string} selectedFinishId
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 */
                VignetteElement.prototype.selectedFinishId = "";

                /**
                 * Creates a new VignetteElement instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {streem.room.ar.IVignetteElement=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteElement} VignetteElement instance
                 */
                VignetteElement.create = function create(properties) {
                    return new VignetteElement(properties);
                };

                /**
                 * Encodes the specified VignetteElement message. Does not implicitly {@link streem.room.ar.VignetteElement.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {streem.room.ar.IVignetteElement} m VignetteElement message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteElement.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.selectedStateId != null && Object.hasOwnProperty.call(m, "selectedStateId"))
                        w.uint32(18).string(m.selectedStateId);
                    if (m.thumbnailUrl != null && Object.hasOwnProperty.call(m, "thumbnailUrl"))
                        w.uint32(26).string(m.thumbnailUrl);
                    if (m.states != null && m.states.length) {
                        for (var i = 0; i < m.states.length; ++i)
                            $root.streem.room.ar.VignetteElementState.encode(m.states[i], w.uint32(34).fork()).ldelim();
                    }
                    if (m.selectedFinishId != null && Object.hasOwnProperty.call(m, "selectedFinishId"))
                        w.uint32(42).string(m.selectedFinishId);
                    return w;
                };

                /**
                 * Decodes a VignetteElement message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteElement} VignetteElement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteElement.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteElement();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.selectedStateId = r.string();
                                break;
                            }
                        case 3: {
                                m.thumbnailUrl = r.string();
                                break;
                            }
                        case 4: {
                                if (!(m.states && m.states.length))
                                    m.states = [];
                                m.states.push($root.streem.room.ar.VignetteElementState.decode(r, r.uint32()));
                                break;
                            }
                        case 5: {
                                m.selectedFinishId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteElement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteElement} VignetteElement
                 */
                VignetteElement.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteElement)
                        return d;
                    var m = new $root.streem.room.ar.VignetteElement();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.selectedStateId != null) {
                        m.selectedStateId = String(d.selectedStateId);
                    }
                    if (d.thumbnailUrl != null) {
                        m.thumbnailUrl = String(d.thumbnailUrl);
                    }
                    if (d.states) {
                        if (!Array.isArray(d.states))
                            throw TypeError(".streem.room.ar.VignetteElement.states: array expected");
                        m.states = [];
                        for (var i = 0; i < d.states.length; ++i) {
                            if (typeof d.states[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteElement.states: object expected");
                            m.states[i] = $root.streem.room.ar.VignetteElementState.fromObject(d.states[i]);
                        }
                    }
                    if (d.selectedFinishId != null) {
                        m.selectedFinishId = String(d.selectedFinishId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteElement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {streem.room.ar.VignetteElement} m VignetteElement
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteElement.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.states = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.selectedStateId = "";
                        d.thumbnailUrl = "";
                        d.selectedFinishId = "";
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.selectedStateId != null && m.hasOwnProperty("selectedStateId")) {
                        d.selectedStateId = m.selectedStateId;
                    }
                    if (m.thumbnailUrl != null && m.hasOwnProperty("thumbnailUrl")) {
                        d.thumbnailUrl = m.thumbnailUrl;
                    }
                    if (m.states && m.states.length) {
                        d.states = [];
                        for (var j = 0; j < m.states.length; ++j) {
                            d.states[j] = $root.streem.room.ar.VignetteElementState.toObject(m.states[j], o);
                        }
                    }
                    if (m.selectedFinishId != null && m.hasOwnProperty("selectedFinishId")) {
                        d.selectedFinishId = m.selectedFinishId;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteElement to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteElement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteElement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteElement
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteElement
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteElement";
                };

                return VignetteElement;
            })();

            ar.VignetteElementState = (function() {

                /**
                 * Properties of a VignetteElementState.
                 * @memberof streem.room.ar
                 * @interface IVignetteElementState
                 * @property {string|null} [id] VignetteElementState id
                 * @property {IPresentation|null} [presentation] VignetteElementState presentation
                 * @property {Array.<streem.room.ar.IVignetteHotspotGroup>|null} [positionGroups] VignetteElementState positionGroups
                 * @property {Array.<streem.room.ar.IVignetteElementFinish>|null} [finishes] VignetteElementState finishes
                 */

                /**
                 * Constructs a new VignetteElementState.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteElementState.
                 * @implements IVignetteElementState
                 * @constructor
                 * @param {streem.room.ar.IVignetteElementState=} [p] Properties to set
                 */
                function VignetteElementState(p) {
                    this.positionGroups = [];
                    this.finishes = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteElementState id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteElementState
                 * @instance
                 */
                VignetteElementState.prototype.id = "";

                /**
                 * VignetteElementState presentation.
                 * @member {IPresentation|null|undefined} presentation
                 * @memberof streem.room.ar.VignetteElementState
                 * @instance
                 */
                VignetteElementState.prototype.presentation = null;

                /**
                 * VignetteElementState positionGroups.
                 * @member {Array.<streem.room.ar.IVignetteHotspotGroup>} positionGroups
                 * @memberof streem.room.ar.VignetteElementState
                 * @instance
                 */
                VignetteElementState.prototype.positionGroups = $util.emptyArray;

                /**
                 * VignetteElementState finishes.
                 * @member {Array.<streem.room.ar.IVignetteElementFinish>} finishes
                 * @memberof streem.room.ar.VignetteElementState
                 * @instance
                 */
                VignetteElementState.prototype.finishes = $util.emptyArray;

                /**
                 * Creates a new VignetteElementState instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {streem.room.ar.IVignetteElementState=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteElementState} VignetteElementState instance
                 */
                VignetteElementState.create = function create(properties) {
                    return new VignetteElementState(properties);
                };

                /**
                 * Encodes the specified VignetteElementState message. Does not implicitly {@link streem.room.ar.VignetteElementState.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {streem.room.ar.IVignetteElementState} m VignetteElementState message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteElementState.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.presentation != null && Object.hasOwnProperty.call(m, "presentation"))
                        $root.Presentation.encode(m.presentation, w.uint32(26).fork()).ldelim();
                    if (m.positionGroups != null && m.positionGroups.length) {
                        for (var i = 0; i < m.positionGroups.length; ++i)
                            $root.streem.room.ar.VignetteHotspotGroup.encode(m.positionGroups[i], w.uint32(34).fork()).ldelim();
                    }
                    if (m.finishes != null && m.finishes.length) {
                        for (var i = 0; i < m.finishes.length; ++i)
                            $root.streem.room.ar.VignetteElementFinish.encode(m.finishes[i], w.uint32(42).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a VignetteElementState message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteElementState} VignetteElementState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteElementState.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteElementState();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 3: {
                                m.presentation = $root.Presentation.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                if (!(m.positionGroups && m.positionGroups.length))
                                    m.positionGroups = [];
                                m.positionGroups.push($root.streem.room.ar.VignetteHotspotGroup.decode(r, r.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(m.finishes && m.finishes.length))
                                    m.finishes = [];
                                m.finishes.push($root.streem.room.ar.VignetteElementFinish.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteElementState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteElementState} VignetteElementState
                 */
                VignetteElementState.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteElementState)
                        return d;
                    var m = new $root.streem.room.ar.VignetteElementState();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.presentation != null) {
                        if (typeof d.presentation !== "object")
                            throw TypeError(".streem.room.ar.VignetteElementState.presentation: object expected");
                        m.presentation = $root.Presentation.fromObject(d.presentation);
                    }
                    if (d.positionGroups) {
                        if (!Array.isArray(d.positionGroups))
                            throw TypeError(".streem.room.ar.VignetteElementState.positionGroups: array expected");
                        m.positionGroups = [];
                        for (var i = 0; i < d.positionGroups.length; ++i) {
                            if (typeof d.positionGroups[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteElementState.positionGroups: object expected");
                            m.positionGroups[i] = $root.streem.room.ar.VignetteHotspotGroup.fromObject(d.positionGroups[i]);
                        }
                    }
                    if (d.finishes) {
                        if (!Array.isArray(d.finishes))
                            throw TypeError(".streem.room.ar.VignetteElementState.finishes: array expected");
                        m.finishes = [];
                        for (var i = 0; i < d.finishes.length; ++i) {
                            if (typeof d.finishes[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteElementState.finishes: object expected");
                            m.finishes[i] = $root.streem.room.ar.VignetteElementFinish.fromObject(d.finishes[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteElementState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {streem.room.ar.VignetteElementState} m VignetteElementState
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteElementState.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.positionGroups = [];
                        d.finishes = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.presentation = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.presentation != null && m.hasOwnProperty("presentation")) {
                        d.presentation = $root.Presentation.toObject(m.presentation, o);
                    }
                    if (m.positionGroups && m.positionGroups.length) {
                        d.positionGroups = [];
                        for (var j = 0; j < m.positionGroups.length; ++j) {
                            d.positionGroups[j] = $root.streem.room.ar.VignetteHotspotGroup.toObject(m.positionGroups[j], o);
                        }
                    }
                    if (m.finishes && m.finishes.length) {
                        d.finishes = [];
                        for (var j = 0; j < m.finishes.length; ++j) {
                            d.finishes[j] = $root.streem.room.ar.VignetteElementFinish.toObject(m.finishes[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this VignetteElementState to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteElementState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteElementState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteElementState
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteElementState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteElementState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteElementState";
                };

                return VignetteElementState;
            })();

            ar.VignetteElementFinish = (function() {

                /**
                 * Properties of a VignetteElementFinish.
                 * @memberof streem.room.ar
                 * @interface IVignetteElementFinish
                 * @property {string|null} [finishId] VignetteElementFinish finishId
                 * @property {IElement|null} [element] VignetteElementFinish element
                 */

                /**
                 * Constructs a new VignetteElementFinish.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteElementFinish.
                 * @implements IVignetteElementFinish
                 * @constructor
                 * @param {streem.room.ar.IVignetteElementFinish=} [p] Properties to set
                 */
                function VignetteElementFinish(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteElementFinish finishId.
                 * @member {string} finishId
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @instance
                 */
                VignetteElementFinish.prototype.finishId = "";

                /**
                 * VignetteElementFinish element.
                 * @member {IElement|null|undefined} element
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @instance
                 */
                VignetteElementFinish.prototype.element = null;

                /**
                 * Creates a new VignetteElementFinish instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {streem.room.ar.IVignetteElementFinish=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteElementFinish} VignetteElementFinish instance
                 */
                VignetteElementFinish.create = function create(properties) {
                    return new VignetteElementFinish(properties);
                };

                /**
                 * Encodes the specified VignetteElementFinish message. Does not implicitly {@link streem.room.ar.VignetteElementFinish.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {streem.room.ar.IVignetteElementFinish} m VignetteElementFinish message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteElementFinish.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.finishId != null && Object.hasOwnProperty.call(m, "finishId"))
                        w.uint32(10).string(m.finishId);
                    if (m.element != null && Object.hasOwnProperty.call(m, "element"))
                        $root.Element.encode(m.element, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a VignetteElementFinish message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteElementFinish} VignetteElementFinish
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteElementFinish.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteElementFinish();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.finishId = r.string();
                                break;
                            }
                        case 2: {
                                m.element = $root.Element.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteElementFinish message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteElementFinish} VignetteElementFinish
                 */
                VignetteElementFinish.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteElementFinish)
                        return d;
                    var m = new $root.streem.room.ar.VignetteElementFinish();
                    if (d.finishId != null) {
                        m.finishId = String(d.finishId);
                    }
                    if (d.element != null) {
                        if (typeof d.element !== "object")
                            throw TypeError(".streem.room.ar.VignetteElementFinish.element: object expected");
                        m.element = $root.Element.fromObject(d.element);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteElementFinish message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {streem.room.ar.VignetteElementFinish} m VignetteElementFinish
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteElementFinish.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.finishId = "";
                        d.element = null;
                    }
                    if (m.finishId != null && m.hasOwnProperty("finishId")) {
                        d.finishId = m.finishId;
                    }
                    if (m.element != null && m.hasOwnProperty("element")) {
                        d.element = $root.Element.toObject(m.element, o);
                    }
                    return d;
                };

                /**
                 * Converts this VignetteElementFinish to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteElementFinish.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteElementFinish
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteElementFinish
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteElementFinish.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteElementFinish";
                };

                return VignetteElementFinish;
            })();

            ar.VignetteHotspotGroup = (function() {

                /**
                 * Properties of a VignetteHotspotGroup.
                 * @memberof streem.room.ar
                 * @interface IVignetteHotspotGroup
                 * @property {string|null} [id] VignetteHotspotGroup id
                 * @property {streem.api.IPoint|null} [focalPoint] VignetteHotspotGroup focalPoint
                 * @property {Array.<streem.room.ar.IVignetteHotspot>|null} [hotspots] VignetteHotspotGroup hotspots
                 */

                /**
                 * Constructs a new VignetteHotspotGroup.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteHotspotGroup.
                 * @implements IVignetteHotspotGroup
                 * @constructor
                 * @param {streem.room.ar.IVignetteHotspotGroup=} [p] Properties to set
                 */
                function VignetteHotspotGroup(p) {
                    this.hotspots = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteHotspotGroup id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @instance
                 */
                VignetteHotspotGroup.prototype.id = "";

                /**
                 * VignetteHotspotGroup focalPoint.
                 * @member {streem.api.IPoint|null|undefined} focalPoint
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @instance
                 */
                VignetteHotspotGroup.prototype.focalPoint = null;

                /**
                 * VignetteHotspotGroup hotspots.
                 * @member {Array.<streem.room.ar.IVignetteHotspot>} hotspots
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @instance
                 */
                VignetteHotspotGroup.prototype.hotspots = $util.emptyArray;

                /**
                 * Creates a new VignetteHotspotGroup instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {streem.room.ar.IVignetteHotspotGroup=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteHotspotGroup} VignetteHotspotGroup instance
                 */
                VignetteHotspotGroup.create = function create(properties) {
                    return new VignetteHotspotGroup(properties);
                };

                /**
                 * Encodes the specified VignetteHotspotGroup message. Does not implicitly {@link streem.room.ar.VignetteHotspotGroup.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {streem.room.ar.IVignetteHotspotGroup} m VignetteHotspotGroup message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteHotspotGroup.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.focalPoint != null && Object.hasOwnProperty.call(m, "focalPoint"))
                        $root.streem.api.Point.encode(m.focalPoint, w.uint32(18).fork()).ldelim();
                    if (m.hotspots != null && m.hotspots.length) {
                        for (var i = 0; i < m.hotspots.length; ++i)
                            $root.streem.room.ar.VignetteHotspot.encode(m.hotspots[i], w.uint32(26).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a VignetteHotspotGroup message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteHotspotGroup} VignetteHotspotGroup
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteHotspotGroup.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspotGroup();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.focalPoint = $root.streem.api.Point.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                if (!(m.hotspots && m.hotspots.length))
                                    m.hotspots = [];
                                m.hotspots.push($root.streem.room.ar.VignetteHotspot.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteHotspotGroup message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteHotspotGroup} VignetteHotspotGroup
                 */
                VignetteHotspotGroup.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteHotspotGroup)
                        return d;
                    var m = new $root.streem.room.ar.VignetteHotspotGroup();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.focalPoint != null) {
                        if (typeof d.focalPoint !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspotGroup.focalPoint: object expected");
                        m.focalPoint = $root.streem.api.Point.fromObject(d.focalPoint);
                    }
                    if (d.hotspots) {
                        if (!Array.isArray(d.hotspots))
                            throw TypeError(".streem.room.ar.VignetteHotspotGroup.hotspots: array expected");
                        m.hotspots = [];
                        for (var i = 0; i < d.hotspots.length; ++i) {
                            if (typeof d.hotspots[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteHotspotGroup.hotspots: object expected");
                            m.hotspots[i] = $root.streem.room.ar.VignetteHotspot.fromObject(d.hotspots[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteHotspotGroup message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {streem.room.ar.VignetteHotspotGroup} m VignetteHotspotGroup
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteHotspotGroup.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.hotspots = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.focalPoint = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.focalPoint != null && m.hasOwnProperty("focalPoint")) {
                        d.focalPoint = $root.streem.api.Point.toObject(m.focalPoint, o);
                    }
                    if (m.hotspots && m.hotspots.length) {
                        d.hotspots = [];
                        for (var j = 0; j < m.hotspots.length; ++j) {
                            d.hotspots[j] = $root.streem.room.ar.VignetteHotspot.toObject(m.hotspots[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this VignetteHotspotGroup to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteHotspotGroup.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteHotspotGroup
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteHotspotGroup
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteHotspotGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteHotspotGroup";
                };

                return VignetteHotspotGroup;
            })();

            ar.VignetteHotspot = (function() {

                /**
                 * Properties of a VignetteHotspot.
                 * @memberof streem.room.ar
                 * @interface IVignetteHotspot
                 * @property {string|null} [id] VignetteHotspot id
                 * @property {streem.api.IPose|null} [pose] VignetteHotspot pose
                 * @property {streem.room.ar.VignetteHotspot.IIcon|null} [icon] VignetteHotspot icon
                 * @property {streem.room.ar.IVignettePortal|null} [portal] VignetteHotspot portal
                 * @property {streem.room.ar.VignetteHotspot.IModal|null} [modal] VignetteHotspot modal
                 * @property {streem.room.ar.VignetteHotspot.IStateChange|null} [stateChange] VignetteHotspot stateChange
                 */

                /**
                 * Constructs a new VignetteHotspot.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteHotspot.
                 * @implements IVignetteHotspot
                 * @constructor
                 * @param {streem.room.ar.IVignetteHotspot=} [p] Properties to set
                 */
                function VignetteHotspot(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteHotspot id.
                 * @member {string} id
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.id = "";

                /**
                 * VignetteHotspot pose.
                 * @member {streem.api.IPose|null|undefined} pose
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.pose = null;

                /**
                 * VignetteHotspot icon.
                 * @member {streem.room.ar.VignetteHotspot.IIcon|null|undefined} icon
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.icon = null;

                /**
                 * VignetteHotspot portal.
                 * @member {streem.room.ar.IVignettePortal|null|undefined} portal
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.portal = null;

                /**
                 * VignetteHotspot modal.
                 * @member {streem.room.ar.VignetteHotspot.IModal|null|undefined} modal
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.modal = null;

                /**
                 * VignetteHotspot stateChange.
                 * @member {streem.room.ar.VignetteHotspot.IStateChange|null|undefined} stateChange
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                VignetteHotspot.prototype.stateChange = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * VignetteHotspot action.
                 * @member {"portal"|"modal"|"stateChange"|undefined} action
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 */
                Object.defineProperty(VignetteHotspot.prototype, "action", {
                    get: $util.oneOfGetter($oneOfFields = ["portal", "modal", "stateChange"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new VignetteHotspot instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {streem.room.ar.IVignetteHotspot=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteHotspot} VignetteHotspot instance
                 */
                VignetteHotspot.create = function create(properties) {
                    return new VignetteHotspot(properties);
                };

                /**
                 * Encodes the specified VignetteHotspot message. Does not implicitly {@link streem.room.ar.VignetteHotspot.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {streem.room.ar.IVignetteHotspot} m VignetteHotspot message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteHotspot.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                        w.uint32(10).string(m.id);
                    if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                        $root.streem.api.Pose.encode(m.pose, w.uint32(18).fork()).ldelim();
                    if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                        $root.streem.room.ar.VignetteHotspot.Icon.encode(m.icon, w.uint32(26).fork()).ldelim();
                    if (m.portal != null && Object.hasOwnProperty.call(m, "portal"))
                        $root.streem.room.ar.VignettePortal.encode(m.portal, w.uint32(802).fork()).ldelim();
                    if (m.modal != null && Object.hasOwnProperty.call(m, "modal"))
                        $root.streem.room.ar.VignetteHotspot.Modal.encode(m.modal, w.uint32(810).fork()).ldelim();
                    if (m.stateChange != null && Object.hasOwnProperty.call(m, "stateChange"))
                        $root.streem.room.ar.VignetteHotspot.StateChange.encode(m.stateChange, w.uint32(818).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a VignetteHotspot message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteHotspot} VignetteHotspot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteHotspot.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.id = r.string();
                                break;
                            }
                        case 2: {
                                m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.icon = $root.streem.room.ar.VignetteHotspot.Icon.decode(r, r.uint32());
                                break;
                            }
                        case 100: {
                                m.portal = $root.streem.room.ar.VignettePortal.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.modal = $root.streem.room.ar.VignetteHotspot.Modal.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.stateChange = $root.streem.room.ar.VignetteHotspot.StateChange.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteHotspot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteHotspot} VignetteHotspot
                 */
                VignetteHotspot.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteHotspot)
                        return d;
                    var m = new $root.streem.room.ar.VignetteHotspot();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.pose != null) {
                        if (typeof d.pose !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspot.pose: object expected");
                        m.pose = $root.streem.api.Pose.fromObject(d.pose);
                    }
                    if (d.icon != null) {
                        if (typeof d.icon !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspot.icon: object expected");
                        m.icon = $root.streem.room.ar.VignetteHotspot.Icon.fromObject(d.icon);
                    }
                    if (d.portal != null) {
                        if (typeof d.portal !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspot.portal: object expected");
                        m.portal = $root.streem.room.ar.VignettePortal.fromObject(d.portal);
                    }
                    if (d.modal != null) {
                        if (typeof d.modal !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspot.modal: object expected");
                        m.modal = $root.streem.room.ar.VignetteHotspot.Modal.fromObject(d.modal);
                    }
                    if (d.stateChange != null) {
                        if (typeof d.stateChange !== "object")
                            throw TypeError(".streem.room.ar.VignetteHotspot.stateChange: object expected");
                        m.stateChange = $root.streem.room.ar.VignetteHotspot.StateChange.fromObject(d.stateChange);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteHotspot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {streem.room.ar.VignetteHotspot} m VignetteHotspot
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteHotspot.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.id = "";
                        d.pose = null;
                        d.icon = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.pose != null && m.hasOwnProperty("pose")) {
                        d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                    }
                    if (m.icon != null && m.hasOwnProperty("icon")) {
                        d.icon = $root.streem.room.ar.VignetteHotspot.Icon.toObject(m.icon, o);
                    }
                    if (m.portal != null && m.hasOwnProperty("portal")) {
                        d.portal = $root.streem.room.ar.VignettePortal.toObject(m.portal, o);
                        if (o.oneofs)
                            d.action = "portal";
                    }
                    if (m.modal != null && m.hasOwnProperty("modal")) {
                        d.modal = $root.streem.room.ar.VignetteHotspot.Modal.toObject(m.modal, o);
                        if (o.oneofs)
                            d.action = "modal";
                    }
                    if (m.stateChange != null && m.hasOwnProperty("stateChange")) {
                        d.stateChange = $root.streem.room.ar.VignetteHotspot.StateChange.toObject(m.stateChange, o);
                        if (o.oneofs)
                            d.action = "stateChange";
                    }
                    return d;
                };

                /**
                 * Converts this VignetteHotspot to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteHotspot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteHotspot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteHotspot
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteHotspot
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteHotspot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteHotspot";
                };

                VignetteHotspot.Icon = (function() {

                    /**
                     * Properties of an Icon.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @interface IIcon
                     * @property {string|null} [unselectedName] Icon unselectedName
                     * @property {string|null} [selectedName] Icon selectedName
                     */

                    /**
                     * Constructs a new Icon.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @classdesc Represents an Icon.
                     * @implements IIcon
                     * @constructor
                     * @param {streem.room.ar.VignetteHotspot.IIcon=} [p] Properties to set
                     */
                    function Icon(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Icon unselectedName.
                     * @member {string} unselectedName
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @instance
                     */
                    Icon.prototype.unselectedName = "";

                    /**
                     * Icon selectedName.
                     * @member {string} selectedName
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @instance
                     */
                    Icon.prototype.selectedName = "";

                    /**
                     * Creates a new Icon instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IIcon=} [properties] Properties to set
                     * @returns {streem.room.ar.VignetteHotspot.Icon} Icon instance
                     */
                    Icon.create = function create(properties) {
                        return new Icon(properties);
                    };

                    /**
                     * Encodes the specified Icon message. Does not implicitly {@link streem.room.ar.VignetteHotspot.Icon.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IIcon} m Icon message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Icon.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.unselectedName != null && Object.hasOwnProperty.call(m, "unselectedName"))
                            w.uint32(10).string(m.unselectedName);
                        if (m.selectedName != null && Object.hasOwnProperty.call(m, "selectedName"))
                            w.uint32(18).string(m.selectedName);
                        return w;
                    };

                    /**
                     * Decodes an Icon message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.VignetteHotspot.Icon} Icon
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Icon.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.Icon();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.unselectedName = r.string();
                                    break;
                                }
                            case 2: {
                                    m.selectedName = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an Icon message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.VignetteHotspot.Icon} Icon
                     */
                    Icon.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.VignetteHotspot.Icon)
                            return d;
                        var m = new $root.streem.room.ar.VignetteHotspot.Icon();
                        if (d.unselectedName != null) {
                            m.unselectedName = String(d.unselectedName);
                        }
                        if (d.selectedName != null) {
                            m.selectedName = String(d.selectedName);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an Icon message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.Icon} m Icon
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Icon.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.unselectedName = "";
                            d.selectedName = "";
                        }
                        if (m.unselectedName != null && m.hasOwnProperty("unselectedName")) {
                            d.unselectedName = m.unselectedName;
                        }
                        if (m.selectedName != null && m.hasOwnProperty("selectedName")) {
                            d.selectedName = m.selectedName;
                        }
                        return d;
                    };

                    /**
                     * Converts this Icon to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Icon.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Icon
                     * @function getTypeUrl
                     * @memberof streem.room.ar.VignetteHotspot.Icon
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Icon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.Icon";
                    };

                    return Icon;
                })();

                VignetteHotspot.Modal = (function() {

                    /**
                     * Properties of a Modal.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @interface IModal
                     * @property {string|null} [titleStringId] Modal titleStringId
                     * @property {string|null} [bodyStringId] Modal bodyStringId
                     * @property {streem.room.ar.VignetteHotspot.Modal.IImage|null} [image] Modal image
                     * @property {streem.room.ar.VignetteHotspot.Modal.IVideo|null} [video] Modal video
                     * @property {streem.room.ar.VignetteHotspot.Modal.IAlternateModal|null} [modal] Modal modal
                     */

                    /**
                     * Constructs a new Modal.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @classdesc Represents a Modal.
                     * @implements IModal
                     * @constructor
                     * @param {streem.room.ar.VignetteHotspot.IModal=} [p] Properties to set
                     */
                    function Modal(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Modal titleStringId.
                     * @member {string} titleStringId
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Modal.prototype.titleStringId = "";

                    /**
                     * Modal bodyStringId.
                     * @member {string} bodyStringId
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Modal.prototype.bodyStringId = "";

                    /**
                     * Modal image.
                     * @member {streem.room.ar.VignetteHotspot.Modal.IImage|null|undefined} image
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Modal.prototype.image = null;

                    /**
                     * Modal video.
                     * @member {streem.room.ar.VignetteHotspot.Modal.IVideo|null|undefined} video
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Modal.prototype.video = null;

                    /**
                     * Modal modal.
                     * @member {streem.room.ar.VignetteHotspot.Modal.IAlternateModal|null|undefined} modal
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Modal.prototype.modal = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * Modal action.
                     * @member {"image"|"video"|"modal"|undefined} action
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     */
                    Object.defineProperty(Modal.prototype, "action", {
                        get: $util.oneOfGetter($oneOfFields = ["image", "video", "modal"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new Modal instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IModal=} [properties] Properties to set
                     * @returns {streem.room.ar.VignetteHotspot.Modal} Modal instance
                     */
                    Modal.create = function create(properties) {
                        return new Modal(properties);
                    };

                    /**
                     * Encodes the specified Modal message. Does not implicitly {@link streem.room.ar.VignetteHotspot.Modal.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IModal} m Modal message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Modal.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.titleStringId != null && Object.hasOwnProperty.call(m, "titleStringId"))
                            w.uint32(18).string(m.titleStringId);
                        if (m.bodyStringId != null && Object.hasOwnProperty.call(m, "bodyStringId"))
                            w.uint32(26).string(m.bodyStringId);
                        if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                            $root.streem.room.ar.VignetteHotspot.Modal.Image.encode(m.image, w.uint32(802).fork()).ldelim();
                        if (m.video != null && Object.hasOwnProperty.call(m, "video"))
                            $root.streem.room.ar.VignetteHotspot.Modal.Video.encode(m.video, w.uint32(810).fork()).ldelim();
                        if (m.modal != null && Object.hasOwnProperty.call(m, "modal"))
                            $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal.encode(m.modal, w.uint32(818).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Modal message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.VignetteHotspot.Modal} Modal
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Modal.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.Modal();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 2: {
                                    m.titleStringId = r.string();
                                    break;
                                }
                            case 3: {
                                    m.bodyStringId = r.string();
                                    break;
                                }
                            case 100: {
                                    m.image = $root.streem.room.ar.VignetteHotspot.Modal.Image.decode(r, r.uint32());
                                    break;
                                }
                            case 101: {
                                    m.video = $root.streem.room.ar.VignetteHotspot.Modal.Video.decode(r, r.uint32());
                                    break;
                                }
                            case 102: {
                                    m.modal = $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Modal message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.VignetteHotspot.Modal} Modal
                     */
                    Modal.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.VignetteHotspot.Modal)
                            return d;
                        var m = new $root.streem.room.ar.VignetteHotspot.Modal();
                        if (d.titleStringId != null) {
                            m.titleStringId = String(d.titleStringId);
                        }
                        if (d.bodyStringId != null) {
                            m.bodyStringId = String(d.bodyStringId);
                        }
                        if (d.image != null) {
                            if (typeof d.image !== "object")
                                throw TypeError(".streem.room.ar.VignetteHotspot.Modal.image: object expected");
                            m.image = $root.streem.room.ar.VignetteHotspot.Modal.Image.fromObject(d.image);
                        }
                        if (d.video != null) {
                            if (typeof d.video !== "object")
                                throw TypeError(".streem.room.ar.VignetteHotspot.Modal.video: object expected");
                            m.video = $root.streem.room.ar.VignetteHotspot.Modal.Video.fromObject(d.video);
                        }
                        if (d.modal != null) {
                            if (typeof d.modal !== "object")
                                throw TypeError(".streem.room.ar.VignetteHotspot.Modal.modal: object expected");
                            m.modal = $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal.fromObject(d.modal);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Modal message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.Modal} m Modal
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Modal.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.titleStringId = "";
                            d.bodyStringId = "";
                        }
                        if (m.titleStringId != null && m.hasOwnProperty("titleStringId")) {
                            d.titleStringId = m.titleStringId;
                        }
                        if (m.bodyStringId != null && m.hasOwnProperty("bodyStringId")) {
                            d.bodyStringId = m.bodyStringId;
                        }
                        if (m.image != null && m.hasOwnProperty("image")) {
                            d.image = $root.streem.room.ar.VignetteHotspot.Modal.Image.toObject(m.image, o);
                            if (o.oneofs)
                                d.action = "image";
                        }
                        if (m.video != null && m.hasOwnProperty("video")) {
                            d.video = $root.streem.room.ar.VignetteHotspot.Modal.Video.toObject(m.video, o);
                            if (o.oneofs)
                                d.action = "video";
                        }
                        if (m.modal != null && m.hasOwnProperty("modal")) {
                            d.modal = $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal.toObject(m.modal, o);
                            if (o.oneofs)
                                d.action = "modal";
                        }
                        return d;
                    };

                    /**
                     * Converts this Modal to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Modal.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Modal
                     * @function getTypeUrl
                     * @memberof streem.room.ar.VignetteHotspot.Modal
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Modal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.Modal";
                    };

                    Modal.Image = (function() {

                        /**
                         * Properties of an Image.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @interface IImage
                         * @property {string|null} [titleStringId] Image titleStringId
                         * @property {string|null} [url] Image url
                         */

                        /**
                         * Constructs a new Image.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @classdesc Represents an Image.
                         * @implements IImage
                         * @constructor
                         * @param {streem.room.ar.VignetteHotspot.Modal.IImage=} [p] Properties to set
                         */
                        function Image(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }

                        /**
                         * Image titleStringId.
                         * @member {string} titleStringId
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @instance
                         */
                        Image.prototype.titleStringId = "";

                        /**
                         * Image url.
                         * @member {string} url
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @instance
                         */
                        Image.prototype.url = "";

                        /**
                         * Creates a new Image instance using the specified properties.
                         * @function create
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IImage=} [properties] Properties to set
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Image} Image instance
                         */
                        Image.create = function create(properties) {
                            return new Image(properties);
                        };

                        /**
                         * Encodes the specified Image message. Does not implicitly {@link streem.room.ar.VignetteHotspot.Modal.Image.verify|verify} messages.
                         * @function encode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IImage} m Image message or plain object to encode
                         * @param {$protobuf.Writer} [w] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Image.encode = function encode(m, w) {
                            if (!w)
                                w = $Writer.create();
                            if (m.titleStringId != null && Object.hasOwnProperty.call(m, "titleStringId"))
                                w.uint32(10).string(m.titleStringId);
                            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                                w.uint32(18).string(m.url);
                            return w;
                        };

                        /**
                         * Decodes an Image message from the specified reader or buffer.
                         * @function decode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                         * @param {number} [l] Message length if known beforehand
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Image} Image
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Image.decode = function decode(r, l) {
                            if (!(r instanceof $Reader))
                                r = $Reader.create(r);
                            var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.Modal.Image();
                            while (r.pos < c) {
                                var t = r.uint32();
                                switch (t >>> 3) {
                                case 1: {
                                        m.titleStringId = r.string();
                                        break;
                                    }
                                case 2: {
                                        m.url = r.string();
                                        break;
                                    }
                                default:
                                    r.skipType(t & 7);
                                    break;
                                }
                            }
                            return m;
                        };

                        /**
                         * Creates an Image message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {Object.<string,*>} d Plain object
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Image} Image
                         */
                        Image.fromObject = function fromObject(d) {
                            if (d instanceof $root.streem.room.ar.VignetteHotspot.Modal.Image)
                                return d;
                            var m = new $root.streem.room.ar.VignetteHotspot.Modal.Image();
                            if (d.titleStringId != null) {
                                m.titleStringId = String(d.titleStringId);
                            }
                            if (d.url != null) {
                                m.url = String(d.url);
                            }
                            return m;
                        };

                        /**
                         * Creates a plain object from an Image message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.Image} m Image
                         * @param {$protobuf.IConversionOptions} [o] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Image.toObject = function toObject(m, o) {
                            if (!o)
                                o = {};
                            var d = {};
                            if (o.defaults) {
                                d.titleStringId = "";
                                d.url = "";
                            }
                            if (m.titleStringId != null && m.hasOwnProperty("titleStringId")) {
                                d.titleStringId = m.titleStringId;
                            }
                            if (m.url != null && m.hasOwnProperty("url")) {
                                d.url = m.url;
                            }
                            return d;
                        };

                        /**
                         * Converts this Image to JSON.
                         * @function toJSON
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Image.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for Image
                         * @function getTypeUrl
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Image
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Image.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.Modal.Image";
                        };

                        return Image;
                    })();

                    Modal.Video = (function() {

                        /**
                         * Properties of a Video.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @interface IVideo
                         * @property {string|null} [titleStringId] Video titleStringId
                         * @property {string|null} [url] Video url
                         */

                        /**
                         * Constructs a new Video.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @classdesc Represents a Video.
                         * @implements IVideo
                         * @constructor
                         * @param {streem.room.ar.VignetteHotspot.Modal.IVideo=} [p] Properties to set
                         */
                        function Video(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }

                        /**
                         * Video titleStringId.
                         * @member {string} titleStringId
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @instance
                         */
                        Video.prototype.titleStringId = "";

                        /**
                         * Video url.
                         * @member {string} url
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @instance
                         */
                        Video.prototype.url = "";

                        /**
                         * Creates a new Video instance using the specified properties.
                         * @function create
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IVideo=} [properties] Properties to set
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Video} Video instance
                         */
                        Video.create = function create(properties) {
                            return new Video(properties);
                        };

                        /**
                         * Encodes the specified Video message. Does not implicitly {@link streem.room.ar.VignetteHotspot.Modal.Video.verify|verify} messages.
                         * @function encode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IVideo} m Video message or plain object to encode
                         * @param {$protobuf.Writer} [w] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Video.encode = function encode(m, w) {
                            if (!w)
                                w = $Writer.create();
                            if (m.titleStringId != null && Object.hasOwnProperty.call(m, "titleStringId"))
                                w.uint32(10).string(m.titleStringId);
                            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                                w.uint32(18).string(m.url);
                            return w;
                        };

                        /**
                         * Decodes a Video message from the specified reader or buffer.
                         * @function decode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                         * @param {number} [l] Message length if known beforehand
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Video} Video
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Video.decode = function decode(r, l) {
                            if (!(r instanceof $Reader))
                                r = $Reader.create(r);
                            var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.Modal.Video();
                            while (r.pos < c) {
                                var t = r.uint32();
                                switch (t >>> 3) {
                                case 1: {
                                        m.titleStringId = r.string();
                                        break;
                                    }
                                case 2: {
                                        m.url = r.string();
                                        break;
                                    }
                                default:
                                    r.skipType(t & 7);
                                    break;
                                }
                            }
                            return m;
                        };

                        /**
                         * Creates a Video message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {Object.<string,*>} d Plain object
                         * @returns {streem.room.ar.VignetteHotspot.Modal.Video} Video
                         */
                        Video.fromObject = function fromObject(d) {
                            if (d instanceof $root.streem.room.ar.VignetteHotspot.Modal.Video)
                                return d;
                            var m = new $root.streem.room.ar.VignetteHotspot.Modal.Video();
                            if (d.titleStringId != null) {
                                m.titleStringId = String(d.titleStringId);
                            }
                            if (d.url != null) {
                                m.url = String(d.url);
                            }
                            return m;
                        };

                        /**
                         * Creates a plain object from a Video message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.Video} m Video
                         * @param {$protobuf.IConversionOptions} [o] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Video.toObject = function toObject(m, o) {
                            if (!o)
                                o = {};
                            var d = {};
                            if (o.defaults) {
                                d.titleStringId = "";
                                d.url = "";
                            }
                            if (m.titleStringId != null && m.hasOwnProperty("titleStringId")) {
                                d.titleStringId = m.titleStringId;
                            }
                            if (m.url != null && m.hasOwnProperty("url")) {
                                d.url = m.url;
                            }
                            return d;
                        };

                        /**
                         * Converts this Video to JSON.
                         * @function toJSON
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Video.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for Video
                         * @function getTypeUrl
                         * @memberof streem.room.ar.VignetteHotspot.Modal.Video
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Video.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.Modal.Video";
                        };

                        return Video;
                    })();

                    Modal.AlternateModal = (function() {

                        /**
                         * Properties of an AlternateModal.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @interface IAlternateModal
                         * @property {string|null} [titleStringId] AlternateModal titleStringId
                         * @property {string|null} [modalId] AlternateModal modalId
                         */

                        /**
                         * Constructs a new AlternateModal.
                         * @memberof streem.room.ar.VignetteHotspot.Modal
                         * @classdesc Represents an AlternateModal.
                         * @implements IAlternateModal
                         * @constructor
                         * @param {streem.room.ar.VignetteHotspot.Modal.IAlternateModal=} [p] Properties to set
                         */
                        function AlternateModal(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }

                        /**
                         * AlternateModal titleStringId.
                         * @member {string} titleStringId
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @instance
                         */
                        AlternateModal.prototype.titleStringId = "";

                        /**
                         * AlternateModal modalId.
                         * @member {string} modalId
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @instance
                         */
                        AlternateModal.prototype.modalId = "";

                        /**
                         * Creates a new AlternateModal instance using the specified properties.
                         * @function create
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IAlternateModal=} [properties] Properties to set
                         * @returns {streem.room.ar.VignetteHotspot.Modal.AlternateModal} AlternateModal instance
                         */
                        AlternateModal.create = function create(properties) {
                            return new AlternateModal(properties);
                        };

                        /**
                         * Encodes the specified AlternateModal message. Does not implicitly {@link streem.room.ar.VignetteHotspot.Modal.AlternateModal.verify|verify} messages.
                         * @function encode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.IAlternateModal} m AlternateModal message or plain object to encode
                         * @param {$protobuf.Writer} [w] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AlternateModal.encode = function encode(m, w) {
                            if (!w)
                                w = $Writer.create();
                            if (m.titleStringId != null && Object.hasOwnProperty.call(m, "titleStringId"))
                                w.uint32(10).string(m.titleStringId);
                            if (m.modalId != null && Object.hasOwnProperty.call(m, "modalId"))
                                w.uint32(18).string(m.modalId);
                            return w;
                        };

                        /**
                         * Decodes an AlternateModal message from the specified reader or buffer.
                         * @function decode
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                         * @param {number} [l] Message length if known beforehand
                         * @returns {streem.room.ar.VignetteHotspot.Modal.AlternateModal} AlternateModal
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AlternateModal.decode = function decode(r, l) {
                            if (!(r instanceof $Reader))
                                r = $Reader.create(r);
                            var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal();
                            while (r.pos < c) {
                                var t = r.uint32();
                                switch (t >>> 3) {
                                case 1: {
                                        m.titleStringId = r.string();
                                        break;
                                    }
                                case 2: {
                                        m.modalId = r.string();
                                        break;
                                    }
                                default:
                                    r.skipType(t & 7);
                                    break;
                                }
                            }
                            return m;
                        };

                        /**
                         * Creates an AlternateModal message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {Object.<string,*>} d Plain object
                         * @returns {streem.room.ar.VignetteHotspot.Modal.AlternateModal} AlternateModal
                         */
                        AlternateModal.fromObject = function fromObject(d) {
                            if (d instanceof $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal)
                                return d;
                            var m = new $root.streem.room.ar.VignetteHotspot.Modal.AlternateModal();
                            if (d.titleStringId != null) {
                                m.titleStringId = String(d.titleStringId);
                            }
                            if (d.modalId != null) {
                                m.modalId = String(d.modalId);
                            }
                            return m;
                        };

                        /**
                         * Creates a plain object from an AlternateModal message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {streem.room.ar.VignetteHotspot.Modal.AlternateModal} m AlternateModal
                         * @param {$protobuf.IConversionOptions} [o] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AlternateModal.toObject = function toObject(m, o) {
                            if (!o)
                                o = {};
                            var d = {};
                            if (o.defaults) {
                                d.titleStringId = "";
                                d.modalId = "";
                            }
                            if (m.titleStringId != null && m.hasOwnProperty("titleStringId")) {
                                d.titleStringId = m.titleStringId;
                            }
                            if (m.modalId != null && m.hasOwnProperty("modalId")) {
                                d.modalId = m.modalId;
                            }
                            return d;
                        };

                        /**
                         * Converts this AlternateModal to JSON.
                         * @function toJSON
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AlternateModal.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for AlternateModal
                         * @function getTypeUrl
                         * @memberof streem.room.ar.VignetteHotspot.Modal.AlternateModal
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        AlternateModal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.Modal.AlternateModal";
                        };

                        return AlternateModal;
                    })();

                    return Modal;
                })();

                VignetteHotspot.StateChange = (function() {

                    /**
                     * Properties of a StateChange.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @interface IStateChange
                     * @property {string|null} [elementId] StateChange elementId
                     * @property {string|null} [newStateId] StateChange newStateId
                     */

                    /**
                     * Constructs a new StateChange.
                     * @memberof streem.room.ar.VignetteHotspot
                     * @classdesc Represents a StateChange.
                     * @implements IStateChange
                     * @constructor
                     * @param {streem.room.ar.VignetteHotspot.IStateChange=} [p] Properties to set
                     */
                    function StateChange(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * StateChange elementId.
                     * @member {string} elementId
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @instance
                     */
                    StateChange.prototype.elementId = "";

                    /**
                     * StateChange newStateId.
                     * @member {string} newStateId
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @instance
                     */
                    StateChange.prototype.newStateId = "";

                    /**
                     * Creates a new StateChange instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IStateChange=} [properties] Properties to set
                     * @returns {streem.room.ar.VignetteHotspot.StateChange} StateChange instance
                     */
                    StateChange.create = function create(properties) {
                        return new StateChange(properties);
                    };

                    /**
                     * Encodes the specified StateChange message. Does not implicitly {@link streem.room.ar.VignetteHotspot.StateChange.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.IStateChange} m StateChange message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StateChange.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(18).string(m.elementId);
                        if (m.newStateId != null && Object.hasOwnProperty.call(m, "newStateId"))
                            w.uint32(26).string(m.newStateId);
                        return w;
                    };

                    /**
                     * Decodes a StateChange message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.VignetteHotspot.StateChange} StateChange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StateChange.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteHotspot.StateChange();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 2: {
                                    m.elementId = r.string();
                                    break;
                                }
                            case 3: {
                                    m.newStateId = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a StateChange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.VignetteHotspot.StateChange} StateChange
                     */
                    StateChange.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.VignetteHotspot.StateChange)
                            return d;
                        var m = new $root.streem.room.ar.VignetteHotspot.StateChange();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        if (d.newStateId != null) {
                            m.newStateId = String(d.newStateId);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a StateChange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {streem.room.ar.VignetteHotspot.StateChange} m StateChange
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StateChange.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                            d.newStateId = "";
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        if (m.newStateId != null && m.hasOwnProperty("newStateId")) {
                            d.newStateId = m.newStateId;
                        }
                        return d;
                    };

                    /**
                     * Converts this StateChange to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StateChange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for StateChange
                     * @function getTypeUrl
                     * @memberof streem.room.ar.VignetteHotspot.StateChange
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    StateChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.VignetteHotspot.StateChange";
                    };

                    return StateChange;
                })();

                return VignetteHotspot;
            })();

            ar.VignettePortal = (function() {

                /**
                 * Properties of a VignettePortal.
                 * @memberof streem.room.ar
                 * @interface IVignettePortal
                 * @property {streem.api.IPose|null} [initialCameraPose] VignettePortal initialCameraPose
                 * @property {streem.room.ar.VignettePortal.ICameraRotationLimits|null} [rotationLimits] VignettePortal rotationLimits
                 * @property {number|null} [fieldOfViewDegrees] VignettePortal fieldOfViewDegrees
                 * @property {streem.room.ar.VignettePortal.PortalType|null} [type] VignettePortal type
                 */

                /**
                 * Constructs a new VignettePortal.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignettePortal.
                 * @implements IVignettePortal
                 * @constructor
                 * @param {streem.room.ar.IVignettePortal=} [p] Properties to set
                 */
                function VignettePortal(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignettePortal initialCameraPose.
                 * @member {streem.api.IPose|null|undefined} initialCameraPose
                 * @memberof streem.room.ar.VignettePortal
                 * @instance
                 */
                VignettePortal.prototype.initialCameraPose = null;

                /**
                 * VignettePortal rotationLimits.
                 * @member {streem.room.ar.VignettePortal.ICameraRotationLimits|null|undefined} rotationLimits
                 * @memberof streem.room.ar.VignettePortal
                 * @instance
                 */
                VignettePortal.prototype.rotationLimits = null;

                /**
                 * VignettePortal fieldOfViewDegrees.
                 * @member {number} fieldOfViewDegrees
                 * @memberof streem.room.ar.VignettePortal
                 * @instance
                 */
                VignettePortal.prototype.fieldOfViewDegrees = 0;

                /**
                 * VignettePortal type.
                 * @member {streem.room.ar.VignettePortal.PortalType} type
                 * @memberof streem.room.ar.VignettePortal
                 * @instance
                 */
                VignettePortal.prototype.type = 0;

                /**
                 * Creates a new VignettePortal instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {streem.room.ar.IVignettePortal=} [properties] Properties to set
                 * @returns {streem.room.ar.VignettePortal} VignettePortal instance
                 */
                VignettePortal.create = function create(properties) {
                    return new VignettePortal(properties);
                };

                /**
                 * Encodes the specified VignettePortal message. Does not implicitly {@link streem.room.ar.VignettePortal.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {streem.room.ar.IVignettePortal} m VignettePortal message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignettePortal.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.initialCameraPose != null && Object.hasOwnProperty.call(m, "initialCameraPose"))
                        $root.streem.api.Pose.encode(m.initialCameraPose, w.uint32(18).fork()).ldelim();
                    if (m.rotationLimits != null && Object.hasOwnProperty.call(m, "rotationLimits"))
                        $root.streem.room.ar.VignettePortal.CameraRotationLimits.encode(m.rotationLimits, w.uint32(26).fork()).ldelim();
                    if (m.fieldOfViewDegrees != null && Object.hasOwnProperty.call(m, "fieldOfViewDegrees"))
                        w.uint32(37).float(m.fieldOfViewDegrees);
                    if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                        w.uint32(40).int32(m.type);
                    return w;
                };

                /**
                 * Decodes a VignettePortal message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignettePortal} VignettePortal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignettePortal.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignettePortal();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 2: {
                                m.initialCameraPose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.rotationLimits = $root.streem.room.ar.VignettePortal.CameraRotationLimits.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.fieldOfViewDegrees = r.float();
                                break;
                            }
                        case 5: {
                                m.type = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignettePortal message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignettePortal} VignettePortal
                 */
                VignettePortal.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignettePortal)
                        return d;
                    var m = new $root.streem.room.ar.VignettePortal();
                    if (d.initialCameraPose != null) {
                        if (typeof d.initialCameraPose !== "object")
                            throw TypeError(".streem.room.ar.VignettePortal.initialCameraPose: object expected");
                        m.initialCameraPose = $root.streem.api.Pose.fromObject(d.initialCameraPose);
                    }
                    if (d.rotationLimits != null) {
                        if (typeof d.rotationLimits !== "object")
                            throw TypeError(".streem.room.ar.VignettePortal.rotationLimits: object expected");
                        m.rotationLimits = $root.streem.room.ar.VignettePortal.CameraRotationLimits.fromObject(d.rotationLimits);
                    }
                    if (d.fieldOfViewDegrees != null) {
                        m.fieldOfViewDegrees = Number(d.fieldOfViewDegrees);
                    }
                    switch (d.type) {
                    default:
                        if (typeof d.type === "number") {
                            m.type = d.type;
                            break;
                        }
                        break;
                    case "PORTAL_TYPE_INVALID":
                    case 0:
                        m.type = 0;
                        break;
                    case "PORTAL_TYPE_INITIAL":
                    case 1:
                        m.type = 1;
                        break;
                    case "PORTAL_TYPE_SLOT":
                    case 2:
                        m.type = 2;
                        break;
                    case "PORTAL_TYPE_SCENE":
                    case 3:
                        m.type = 3;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignettePortal message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {streem.room.ar.VignettePortal} m VignettePortal
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignettePortal.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.initialCameraPose = null;
                        d.rotationLimits = null;
                        d.fieldOfViewDegrees = 0;
                        d.type = o.enums === String ? "PORTAL_TYPE_INVALID" : 0;
                    }
                    if (m.initialCameraPose != null && m.hasOwnProperty("initialCameraPose")) {
                        d.initialCameraPose = $root.streem.api.Pose.toObject(m.initialCameraPose, o);
                    }
                    if (m.rotationLimits != null && m.hasOwnProperty("rotationLimits")) {
                        d.rotationLimits = $root.streem.room.ar.VignettePortal.CameraRotationLimits.toObject(m.rotationLimits, o);
                    }
                    if (m.fieldOfViewDegrees != null && m.hasOwnProperty("fieldOfViewDegrees")) {
                        d.fieldOfViewDegrees = o.json && !isFinite(m.fieldOfViewDegrees) ? String(m.fieldOfViewDegrees) : m.fieldOfViewDegrees;
                    }
                    if (m.type != null && m.hasOwnProperty("type")) {
                        d.type = o.enums === String ? $root.streem.room.ar.VignettePortal.PortalType[m.type] === undefined ? m.type : $root.streem.room.ar.VignettePortal.PortalType[m.type] : m.type;
                    }
                    return d;
                };

                /**
                 * Converts this VignettePortal to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignettePortal
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignettePortal.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignettePortal
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignettePortal
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignettePortal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignettePortal";
                };

                VignettePortal.CameraRotationLimits = (function() {

                    /**
                     * Properties of a CameraRotationLimits.
                     * @memberof streem.room.ar.VignettePortal
                     * @interface ICameraRotationLimits
                     * @property {number|null} [leftRotationDegrees] CameraRotationLimits leftRotationDegrees
                     * @property {number|null} [rightRotationDegrees] CameraRotationLimits rightRotationDegrees
                     * @property {number|null} [downRotationDegrees] CameraRotationLimits downRotationDegrees
                     * @property {number|null} [upRotationDegrees] CameraRotationLimits upRotationDegrees
                     */

                    /**
                     * Constructs a new CameraRotationLimits.
                     * @memberof streem.room.ar.VignettePortal
                     * @classdesc Represents a CameraRotationLimits.
                     * @implements ICameraRotationLimits
                     * @constructor
                     * @param {streem.room.ar.VignettePortal.ICameraRotationLimits=} [p] Properties to set
                     */
                    function CameraRotationLimits(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * CameraRotationLimits leftRotationDegrees.
                     * @member {number} leftRotationDegrees
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @instance
                     */
                    CameraRotationLimits.prototype.leftRotationDegrees = 0;

                    /**
                     * CameraRotationLimits rightRotationDegrees.
                     * @member {number} rightRotationDegrees
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @instance
                     */
                    CameraRotationLimits.prototype.rightRotationDegrees = 0;

                    /**
                     * CameraRotationLimits downRotationDegrees.
                     * @member {number} downRotationDegrees
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @instance
                     */
                    CameraRotationLimits.prototype.downRotationDegrees = 0;

                    /**
                     * CameraRotationLimits upRotationDegrees.
                     * @member {number} upRotationDegrees
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @instance
                     */
                    CameraRotationLimits.prototype.upRotationDegrees = 0;

                    /**
                     * Creates a new CameraRotationLimits instance using the specified properties.
                     * @function create
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {streem.room.ar.VignettePortal.ICameraRotationLimits=} [properties] Properties to set
                     * @returns {streem.room.ar.VignettePortal.CameraRotationLimits} CameraRotationLimits instance
                     */
                    CameraRotationLimits.create = function create(properties) {
                        return new CameraRotationLimits(properties);
                    };

                    /**
                     * Encodes the specified CameraRotationLimits message. Does not implicitly {@link streem.room.ar.VignettePortal.CameraRotationLimits.verify|verify} messages.
                     * @function encode
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {streem.room.ar.VignettePortal.ICameraRotationLimits} m CameraRotationLimits message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraRotationLimits.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.leftRotationDegrees != null && Object.hasOwnProperty.call(m, "leftRotationDegrees"))
                            w.uint32(13).float(m.leftRotationDegrees);
                        if (m.rightRotationDegrees != null && Object.hasOwnProperty.call(m, "rightRotationDegrees"))
                            w.uint32(21).float(m.rightRotationDegrees);
                        if (m.downRotationDegrees != null && Object.hasOwnProperty.call(m, "downRotationDegrees"))
                            w.uint32(29).float(m.downRotationDegrees);
                        if (m.upRotationDegrees != null && Object.hasOwnProperty.call(m, "upRotationDegrees"))
                            w.uint32(37).float(m.upRotationDegrees);
                        return w;
                    };

                    /**
                     * Decodes a CameraRotationLimits message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.room.ar.VignettePortal.CameraRotationLimits} CameraRotationLimits
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraRotationLimits.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignettePortal.CameraRotationLimits();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.leftRotationDegrees = r.float();
                                    break;
                                }
                            case 2: {
                                    m.rightRotationDegrees = r.float();
                                    break;
                                }
                            case 3: {
                                    m.downRotationDegrees = r.float();
                                    break;
                                }
                            case 4: {
                                    m.upRotationDegrees = r.float();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a CameraRotationLimits message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.room.ar.VignettePortal.CameraRotationLimits} CameraRotationLimits
                     */
                    CameraRotationLimits.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.room.ar.VignettePortal.CameraRotationLimits)
                            return d;
                        var m = new $root.streem.room.ar.VignettePortal.CameraRotationLimits();
                        if (d.leftRotationDegrees != null) {
                            m.leftRotationDegrees = Number(d.leftRotationDegrees);
                        }
                        if (d.rightRotationDegrees != null) {
                            m.rightRotationDegrees = Number(d.rightRotationDegrees);
                        }
                        if (d.downRotationDegrees != null) {
                            m.downRotationDegrees = Number(d.downRotationDegrees);
                        }
                        if (d.upRotationDegrees != null) {
                            m.upRotationDegrees = Number(d.upRotationDegrees);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a CameraRotationLimits message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {streem.room.ar.VignettePortal.CameraRotationLimits} m CameraRotationLimits
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraRotationLimits.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.leftRotationDegrees = 0;
                            d.rightRotationDegrees = 0;
                            d.downRotationDegrees = 0;
                            d.upRotationDegrees = 0;
                        }
                        if (m.leftRotationDegrees != null && m.hasOwnProperty("leftRotationDegrees")) {
                            d.leftRotationDegrees = o.json && !isFinite(m.leftRotationDegrees) ? String(m.leftRotationDegrees) : m.leftRotationDegrees;
                        }
                        if (m.rightRotationDegrees != null && m.hasOwnProperty("rightRotationDegrees")) {
                            d.rightRotationDegrees = o.json && !isFinite(m.rightRotationDegrees) ? String(m.rightRotationDegrees) : m.rightRotationDegrees;
                        }
                        if (m.downRotationDegrees != null && m.hasOwnProperty("downRotationDegrees")) {
                            d.downRotationDegrees = o.json && !isFinite(m.downRotationDegrees) ? String(m.downRotationDegrees) : m.downRotationDegrees;
                        }
                        if (m.upRotationDegrees != null && m.hasOwnProperty("upRotationDegrees")) {
                            d.upRotationDegrees = o.json && !isFinite(m.upRotationDegrees) ? String(m.upRotationDegrees) : m.upRotationDegrees;
                        }
                        return d;
                    };

                    /**
                     * Converts this CameraRotationLimits to JSON.
                     * @function toJSON
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraRotationLimits.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CameraRotationLimits
                     * @function getTypeUrl
                     * @memberof streem.room.ar.VignettePortal.CameraRotationLimits
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CameraRotationLimits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.room.ar.VignettePortal.CameraRotationLimits";
                    };

                    return CameraRotationLimits;
                })();

                /**
                 * PortalType enum.
                 * @name streem.room.ar.VignettePortal.PortalType
                 * @enum {number}
                 * @property {number} PORTAL_TYPE_INVALID=0 PORTAL_TYPE_INVALID value
                 * @property {number} PORTAL_TYPE_INITIAL=1 PORTAL_TYPE_INITIAL value
                 * @property {number} PORTAL_TYPE_SLOT=2 PORTAL_TYPE_SLOT value
                 * @property {number} PORTAL_TYPE_SCENE=3 PORTAL_TYPE_SCENE value
                 */
                VignettePortal.PortalType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PORTAL_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "PORTAL_TYPE_INITIAL"] = 1;
                    values[valuesById[2] = "PORTAL_TYPE_SLOT"] = 2;
                    values[valuesById[3] = "PORTAL_TYPE_SCENE"] = 3;
                    return values;
                })();

                return VignettePortal;
            })();

            ar.VignetteState = (function() {

                /**
                 * Properties of a VignetteState.
                 * @memberof streem.room.ar
                 * @interface IVignetteState
                 * @property {streem.room.ar.IVignette|null} [vignette] VignetteState vignette
                 * @property {Array.<streem.room.ar.IVignetteBackStackItem>|null} [backStack] VignetteState backStack
                 * @property {streem.api.IPose|null} [currentCameraPose] VignetteState currentCameraPose
                 */

                /**
                 * Constructs a new VignetteState.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteState.
                 * @implements IVignetteState
                 * @constructor
                 * @param {streem.room.ar.IVignetteState=} [p] Properties to set
                 */
                function VignetteState(p) {
                    this.backStack = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteState vignette.
                 * @member {streem.room.ar.IVignette|null|undefined} vignette
                 * @memberof streem.room.ar.VignetteState
                 * @instance
                 */
                VignetteState.prototype.vignette = null;

                /**
                 * VignetteState backStack.
                 * @member {Array.<streem.room.ar.IVignetteBackStackItem>} backStack
                 * @memberof streem.room.ar.VignetteState
                 * @instance
                 */
                VignetteState.prototype.backStack = $util.emptyArray;

                /**
                 * VignetteState currentCameraPose.
                 * @member {streem.api.IPose|null|undefined} currentCameraPose
                 * @memberof streem.room.ar.VignetteState
                 * @instance
                 */
                VignetteState.prototype.currentCameraPose = null;

                /**
                 * Creates a new VignetteState instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {streem.room.ar.IVignetteState=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteState} VignetteState instance
                 */
                VignetteState.create = function create(properties) {
                    return new VignetteState(properties);
                };

                /**
                 * Encodes the specified VignetteState message. Does not implicitly {@link streem.room.ar.VignetteState.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {streem.room.ar.IVignetteState} m VignetteState message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteState.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.vignette != null && Object.hasOwnProperty.call(m, "vignette"))
                        $root.streem.room.ar.Vignette.encode(m.vignette, w.uint32(82).fork()).ldelim();
                    if (m.backStack != null && m.backStack.length) {
                        for (var i = 0; i < m.backStack.length; ++i)
                            $root.streem.room.ar.VignetteBackStackItem.encode(m.backStack[i], w.uint32(90).fork()).ldelim();
                    }
                    if (m.currentCameraPose != null && Object.hasOwnProperty.call(m, "currentCameraPose"))
                        $root.streem.api.Pose.encode(m.currentCameraPose, w.uint32(162).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a VignetteState message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteState} VignetteState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteState.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteState();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 10: {
                                m.vignette = $root.streem.room.ar.Vignette.decode(r, r.uint32());
                                break;
                            }
                        case 11: {
                                if (!(m.backStack && m.backStack.length))
                                    m.backStack = [];
                                m.backStack.push($root.streem.room.ar.VignetteBackStackItem.decode(r, r.uint32()));
                                break;
                            }
                        case 20: {
                                m.currentCameraPose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteState} VignetteState
                 */
                VignetteState.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteState)
                        return d;
                    var m = new $root.streem.room.ar.VignetteState();
                    if (d.vignette != null) {
                        if (typeof d.vignette !== "object")
                            throw TypeError(".streem.room.ar.VignetteState.vignette: object expected");
                        m.vignette = $root.streem.room.ar.Vignette.fromObject(d.vignette);
                    }
                    if (d.backStack) {
                        if (!Array.isArray(d.backStack))
                            throw TypeError(".streem.room.ar.VignetteState.backStack: array expected");
                        m.backStack = [];
                        for (var i = 0; i < d.backStack.length; ++i) {
                            if (typeof d.backStack[i] !== "object")
                                throw TypeError(".streem.room.ar.VignetteState.backStack: object expected");
                            m.backStack[i] = $root.streem.room.ar.VignetteBackStackItem.fromObject(d.backStack[i]);
                        }
                    }
                    if (d.currentCameraPose != null) {
                        if (typeof d.currentCameraPose !== "object")
                            throw TypeError(".streem.room.ar.VignetteState.currentCameraPose: object expected");
                        m.currentCameraPose = $root.streem.api.Pose.fromObject(d.currentCameraPose);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {streem.room.ar.VignetteState} m VignetteState
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteState.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.backStack = [];
                    }
                    if (o.defaults) {
                        d.vignette = null;
                        d.currentCameraPose = null;
                    }
                    if (m.vignette != null && m.hasOwnProperty("vignette")) {
                        d.vignette = $root.streem.room.ar.Vignette.toObject(m.vignette, o);
                    }
                    if (m.backStack && m.backStack.length) {
                        d.backStack = [];
                        for (var j = 0; j < m.backStack.length; ++j) {
                            d.backStack[j] = $root.streem.room.ar.VignetteBackStackItem.toObject(m.backStack[j], o);
                        }
                    }
                    if (m.currentCameraPose != null && m.hasOwnProperty("currentCameraPose")) {
                        d.currentCameraPose = $root.streem.api.Pose.toObject(m.currentCameraPose, o);
                    }
                    return d;
                };

                /**
                 * Converts this VignetteState to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteState
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteState";
                };

                return VignetteState;
            })();

            ar.VignetteBackStackItem = (function() {

                /**
                 * Properties of a VignetteBackStackItem.
                 * @memberof streem.room.ar
                 * @interface IVignetteBackStackItem
                 * @property {string|null} [slotId] VignetteBackStackItem slotId
                 * @property {streem.room.ar.IVignettePortal|null} [portal] VignetteBackStackItem portal
                 * @property {string|null} [hotspotId] VignetteBackStackItem hotspotId
                 */

                /**
                 * Constructs a new VignetteBackStackItem.
                 * @memberof streem.room.ar
                 * @classdesc Represents a VignetteBackStackItem.
                 * @implements IVignetteBackStackItem
                 * @constructor
                 * @param {streem.room.ar.IVignetteBackStackItem=} [p] Properties to set
                 */
                function VignetteBackStackItem(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteBackStackItem slotId.
                 * @member {string} slotId
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @instance
                 */
                VignetteBackStackItem.prototype.slotId = "";

                /**
                 * VignetteBackStackItem portal.
                 * @member {streem.room.ar.IVignettePortal|null|undefined} portal
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @instance
                 */
                VignetteBackStackItem.prototype.portal = null;

                /**
                 * VignetteBackStackItem hotspotId.
                 * @member {string} hotspotId
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @instance
                 */
                VignetteBackStackItem.prototype.hotspotId = "";

                /**
                 * Creates a new VignetteBackStackItem instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {streem.room.ar.IVignetteBackStackItem=} [properties] Properties to set
                 * @returns {streem.room.ar.VignetteBackStackItem} VignetteBackStackItem instance
                 */
                VignetteBackStackItem.create = function create(properties) {
                    return new VignetteBackStackItem(properties);
                };

                /**
                 * Encodes the specified VignetteBackStackItem message. Does not implicitly {@link streem.room.ar.VignetteBackStackItem.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {streem.room.ar.IVignetteBackStackItem} m VignetteBackStackItem message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteBackStackItem.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.slotId != null && Object.hasOwnProperty.call(m, "slotId"))
                        w.uint32(10).string(m.slotId);
                    if (m.portal != null && Object.hasOwnProperty.call(m, "portal"))
                        $root.streem.room.ar.VignettePortal.encode(m.portal, w.uint32(18).fork()).ldelim();
                    if (m.hotspotId != null && Object.hasOwnProperty.call(m, "hotspotId"))
                        w.uint32(26).string(m.hotspotId);
                    return w;
                };

                /**
                 * Decodes a VignetteBackStackItem message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.VignetteBackStackItem} VignetteBackStackItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteBackStackItem.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.VignetteBackStackItem();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.slotId = r.string();
                                break;
                            }
                        case 2: {
                                m.portal = $root.streem.room.ar.VignettePortal.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.hotspotId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteBackStackItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.VignetteBackStackItem} VignetteBackStackItem
                 */
                VignetteBackStackItem.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.VignetteBackStackItem)
                        return d;
                    var m = new $root.streem.room.ar.VignetteBackStackItem();
                    if (d.slotId != null) {
                        m.slotId = String(d.slotId);
                    }
                    if (d.portal != null) {
                        if (typeof d.portal !== "object")
                            throw TypeError(".streem.room.ar.VignetteBackStackItem.portal: object expected");
                        m.portal = $root.streem.room.ar.VignettePortal.fromObject(d.portal);
                    }
                    if (d.hotspotId != null) {
                        m.hotspotId = String(d.hotspotId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteBackStackItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {streem.room.ar.VignetteBackStackItem} m VignetteBackStackItem
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteBackStackItem.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.slotId = "";
                        d.portal = null;
                        d.hotspotId = "";
                    }
                    if (m.slotId != null && m.hasOwnProperty("slotId")) {
                        d.slotId = m.slotId;
                    }
                    if (m.portal != null && m.hasOwnProperty("portal")) {
                        d.portal = $root.streem.room.ar.VignettePortal.toObject(m.portal, o);
                    }
                    if (m.hotspotId != null && m.hasOwnProperty("hotspotId")) {
                        d.hotspotId = m.hotspotId;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteBackStackItem to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteBackStackItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteBackStackItem
                 * @function getTypeUrl
                 * @memberof streem.room.ar.VignetteBackStackItem
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteBackStackItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.VignetteBackStackItem";
                };

                return VignetteBackStackItem;
            })();

            ar.Mesh = (function() {

                /**
                 * Properties of a Mesh.
                 * @memberof streem.room.ar
                 * @interface IMesh
                 * @property {IVectorBuffer|null} [vertices] Mesh vertices
                 * @property {IVectorBuffer|null} [faces] Mesh faces
                 * @property {IVectorBuffer|null} [normals] Mesh normals
                 * @property {IVectorBuffer|null} [faceClassifications] Mesh faceClassifications
                 * @property {streem.room.ar.Mesh.Platform|null} [platform] Mesh platform
                 */

                /**
                 * Constructs a new Mesh.
                 * @memberof streem.room.ar
                 * @classdesc Represents a Mesh.
                 * @implements IMesh
                 * @constructor
                 * @param {streem.room.ar.IMesh=} [p] Properties to set
                 */
                function Mesh(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Mesh vertices.
                 * @member {IVectorBuffer|null|undefined} vertices
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 */
                Mesh.prototype.vertices = null;

                /**
                 * Mesh faces.
                 * @member {IVectorBuffer|null|undefined} faces
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 */
                Mesh.prototype.faces = null;

                /**
                 * Mesh normals.
                 * @member {IVectorBuffer|null|undefined} normals
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 */
                Mesh.prototype.normals = null;

                /**
                 * Mesh faceClassifications.
                 * @member {IVectorBuffer|null|undefined} faceClassifications
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 */
                Mesh.prototype.faceClassifications = null;

                /**
                 * Mesh platform.
                 * @member {streem.room.ar.Mesh.Platform} platform
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 */
                Mesh.prototype.platform = 0;

                /**
                 * Creates a new Mesh instance using the specified properties.
                 * @function create
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {streem.room.ar.IMesh=} [properties] Properties to set
                 * @returns {streem.room.ar.Mesh} Mesh instance
                 */
                Mesh.create = function create(properties) {
                    return new Mesh(properties);
                };

                /**
                 * Encodes the specified Mesh message. Does not implicitly {@link streem.room.ar.Mesh.verify|verify} messages.
                 * @function encode
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {streem.room.ar.IMesh} m Mesh message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Mesh.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.vertices != null && Object.hasOwnProperty.call(m, "vertices"))
                        $root.VectorBuffer.encode(m.vertices, w.uint32(10).fork()).ldelim();
                    if (m.faces != null && Object.hasOwnProperty.call(m, "faces"))
                        $root.VectorBuffer.encode(m.faces, w.uint32(18).fork()).ldelim();
                    if (m.normals != null && Object.hasOwnProperty.call(m, "normals"))
                        $root.VectorBuffer.encode(m.normals, w.uint32(26).fork()).ldelim();
                    if (m.faceClassifications != null && Object.hasOwnProperty.call(m, "faceClassifications"))
                        $root.VectorBuffer.encode(m.faceClassifications, w.uint32(34).fork()).ldelim();
                    if (m.platform != null && Object.hasOwnProperty.call(m, "platform"))
                        w.uint32(40).int32(m.platform);
                    return w;
                };

                /**
                 * Decodes a Mesh message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.room.ar.Mesh} Mesh
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Mesh.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.room.ar.Mesh();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.vertices = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.faces = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.normals = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.faceClassifications = $root.VectorBuffer.decode(r, r.uint32());
                                break;
                            }
                        case 5: {
                                m.platform = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Mesh message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.room.ar.Mesh} Mesh
                 */
                Mesh.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.room.ar.Mesh)
                        return d;
                    var m = new $root.streem.room.ar.Mesh();
                    if (d.vertices != null) {
                        if (typeof d.vertices !== "object")
                            throw TypeError(".streem.room.ar.Mesh.vertices: object expected");
                        m.vertices = $root.VectorBuffer.fromObject(d.vertices);
                    }
                    if (d.faces != null) {
                        if (typeof d.faces !== "object")
                            throw TypeError(".streem.room.ar.Mesh.faces: object expected");
                        m.faces = $root.VectorBuffer.fromObject(d.faces);
                    }
                    if (d.normals != null) {
                        if (typeof d.normals !== "object")
                            throw TypeError(".streem.room.ar.Mesh.normals: object expected");
                        m.normals = $root.VectorBuffer.fromObject(d.normals);
                    }
                    if (d.faceClassifications != null) {
                        if (typeof d.faceClassifications !== "object")
                            throw TypeError(".streem.room.ar.Mesh.faceClassifications: object expected");
                        m.faceClassifications = $root.VectorBuffer.fromObject(d.faceClassifications);
                    }
                    switch (d.platform) {
                    default:
                        if (typeof d.platform === "number") {
                            m.platform = d.platform;
                            break;
                        }
                        break;
                    case "PLATFORM_INVALID":
                    case 0:
                        m.platform = 0;
                        break;
                    case "PLATFORM_IOS":
                    case 1:
                        m.platform = 1;
                        break;
                    case "PLATFORM_ANDROID":
                    case 2:
                        m.platform = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Mesh message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {streem.room.ar.Mesh} m Mesh
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Mesh.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.vertices = null;
                        d.faces = null;
                        d.normals = null;
                        d.faceClassifications = null;
                        d.platform = o.enums === String ? "PLATFORM_INVALID" : 0;
                    }
                    if (m.vertices != null && m.hasOwnProperty("vertices")) {
                        d.vertices = $root.VectorBuffer.toObject(m.vertices, o);
                    }
                    if (m.faces != null && m.hasOwnProperty("faces")) {
                        d.faces = $root.VectorBuffer.toObject(m.faces, o);
                    }
                    if (m.normals != null && m.hasOwnProperty("normals")) {
                        d.normals = $root.VectorBuffer.toObject(m.normals, o);
                    }
                    if (m.faceClassifications != null && m.hasOwnProperty("faceClassifications")) {
                        d.faceClassifications = $root.VectorBuffer.toObject(m.faceClassifications, o);
                    }
                    if (m.platform != null && m.hasOwnProperty("platform")) {
                        d.platform = o.enums === String ? $root.streem.room.ar.Mesh.Platform[m.platform] === undefined ? m.platform : $root.streem.room.ar.Mesh.Platform[m.platform] : m.platform;
                    }
                    return d;
                };

                /**
                 * Converts this Mesh to JSON.
                 * @function toJSON
                 * @memberof streem.room.ar.Mesh
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Mesh.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Mesh
                 * @function getTypeUrl
                 * @memberof streem.room.ar.Mesh
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Mesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.room.ar.Mesh";
                };

                /**
                 * Platform enum.
                 * @name streem.room.ar.Mesh.Platform
                 * @enum {number}
                 * @property {number} PLATFORM_INVALID=0 PLATFORM_INVALID value
                 * @property {number} PLATFORM_IOS=1 PLATFORM_IOS value
                 * @property {number} PLATFORM_ANDROID=2 PLATFORM_ANDROID value
                 */
                Mesh.Platform = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PLATFORM_INVALID"] = 0;
                    values[valuesById[1] = "PLATFORM_IOS"] = 1;
                    values[valuesById[2] = "PLATFORM_ANDROID"] = 2;
                    return values;
                })();

                return Mesh;
            })();

            return ar;
        })();

        return room;
    })();

    streem.user = (function() {

        /**
         * Namespace user.
         * @memberof streem
         * @namespace
         */
        var user = {};

        user.UserWallItem = (function() {

            /**
             * Properties of a UserWallItem.
             * @memberof streem.user
             * @interface IUserWallItem
             * @property {string|null} [id] UserWallItem id
             * @property {string|null} [ownerDeviceId] UserWallItem ownerDeviceId
             * @property {google.protobuf.ITimestamp|null} [clientCreatedAt] UserWallItem clientCreatedAt
             * @property {streem.user.UserWallItem.ILastIdentifiedDevice|null} [lastIdentifiedDevice] UserWallItem lastIdentifiedDevice
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] UserWallItem roomInvitation
             * @property {streem.api.ISmsDeliveryStatus|null} [smsDeliveryStatus] UserWallItem smsDeliveryStatus
             * @property {streem.api.IUserAvailability|null} [userAvailability] UserWallItem userAvailability
             * @property {streem.api.IGroupReservation|null} [lastGroupReservation] UserWallItem lastGroupReservation
             * @property {streem.user.UserWallItem.IHeartbeat|null} [heartbeat] UserWallItem heartbeat
             */

            /**
             * Constructs a new UserWallItem.
             * @memberof streem.user
             * @classdesc Represents a UserWallItem.
             * @implements IUserWallItem
             * @constructor
             * @param {streem.user.IUserWallItem=} [p] Properties to set
             */
            function UserWallItem(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserWallItem id.
             * @member {string} id
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.id = "";

            /**
             * UserWallItem ownerDeviceId.
             * @member {string} ownerDeviceId
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.ownerDeviceId = "";

            /**
             * UserWallItem clientCreatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} clientCreatedAt
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.clientCreatedAt = null;

            /**
             * UserWallItem lastIdentifiedDevice.
             * @member {streem.user.UserWallItem.ILastIdentifiedDevice|null|undefined} lastIdentifiedDevice
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.lastIdentifiedDevice = null;

            /**
             * UserWallItem roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.roomInvitation = null;

            /**
             * UserWallItem smsDeliveryStatus.
             * @member {streem.api.ISmsDeliveryStatus|null|undefined} smsDeliveryStatus
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.smsDeliveryStatus = null;

            /**
             * UserWallItem userAvailability.
             * @member {streem.api.IUserAvailability|null|undefined} userAvailability
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.userAvailability = null;

            /**
             * UserWallItem lastGroupReservation.
             * @member {streem.api.IGroupReservation|null|undefined} lastGroupReservation
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.lastGroupReservation = null;

            /**
             * UserWallItem heartbeat.
             * @member {streem.user.UserWallItem.IHeartbeat|null|undefined} heartbeat
             * @memberof streem.user.UserWallItem
             * @instance
             */
            UserWallItem.prototype.heartbeat = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UserWallItem type.
             * @member {"lastIdentifiedDevice"|"roomInvitation"|"smsDeliveryStatus"|"userAvailability"|"lastGroupReservation"|"heartbeat"|undefined} type
             * @memberof streem.user.UserWallItem
             * @instance
             */
            Object.defineProperty(UserWallItem.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["lastIdentifiedDevice", "roomInvitation", "smsDeliveryStatus", "userAvailability", "lastGroupReservation", "heartbeat"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UserWallItem instance using the specified properties.
             * @function create
             * @memberof streem.user.UserWallItem
             * @static
             * @param {streem.user.IUserWallItem=} [properties] Properties to set
             * @returns {streem.user.UserWallItem} UserWallItem instance
             */
            UserWallItem.create = function create(properties) {
                return new UserWallItem(properties);
            };

            /**
             * Encodes the specified UserWallItem message. Does not implicitly {@link streem.user.UserWallItem.verify|verify} messages.
             * @function encode
             * @memberof streem.user.UserWallItem
             * @static
             * @param {streem.user.IUserWallItem} m UserWallItem message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWallItem.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                    w.uint32(10).string(m.id);
                if (m.ownerDeviceId != null && Object.hasOwnProperty.call(m, "ownerDeviceId"))
                    w.uint32(18).string(m.ownerDeviceId);
                if (m.clientCreatedAt != null && Object.hasOwnProperty.call(m, "clientCreatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.clientCreatedAt, w.uint32(26).fork()).ldelim();
                if (m.lastIdentifiedDevice != null && Object.hasOwnProperty.call(m, "lastIdentifiedDevice"))
                    $root.streem.user.UserWallItem.LastIdentifiedDevice.encode(m.lastIdentifiedDevice, w.uint32(802).fork()).ldelim();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(810).fork()).ldelim();
                if (m.smsDeliveryStatus != null && Object.hasOwnProperty.call(m, "smsDeliveryStatus"))
                    $root.streem.api.SmsDeliveryStatus.encode(m.smsDeliveryStatus, w.uint32(818).fork()).ldelim();
                if (m.userAvailability != null && Object.hasOwnProperty.call(m, "userAvailability"))
                    $root.streem.api.UserAvailability.encode(m.userAvailability, w.uint32(826).fork()).ldelim();
                if (m.lastGroupReservation != null && Object.hasOwnProperty.call(m, "lastGroupReservation"))
                    $root.streem.api.GroupReservation.encode(m.lastGroupReservation, w.uint32(834).fork()).ldelim();
                if (m.heartbeat != null && Object.hasOwnProperty.call(m, "heartbeat"))
                    $root.streem.user.UserWallItem.Heartbeat.encode(m.heartbeat, w.uint32(842).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a UserWallItem message from the specified reader or buffer.
             * @function decode
             * @memberof streem.user.UserWallItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.user.UserWallItem} UserWallItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWallItem.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.user.UserWallItem();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.id = r.string();
                            break;
                        }
                    case 2: {
                            m.ownerDeviceId = r.string();
                            break;
                        }
                    case 3: {
                            m.clientCreatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 100: {
                            m.lastIdentifiedDevice = $root.streem.user.UserWallItem.LastIdentifiedDevice.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    case 102: {
                            m.smsDeliveryStatus = $root.streem.api.SmsDeliveryStatus.decode(r, r.uint32());
                            break;
                        }
                    case 103: {
                            m.userAvailability = $root.streem.api.UserAvailability.decode(r, r.uint32());
                            break;
                        }
                    case 104: {
                            m.lastGroupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                            break;
                        }
                    case 105: {
                            m.heartbeat = $root.streem.user.UserWallItem.Heartbeat.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UserWallItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.user.UserWallItem
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.user.UserWallItem} UserWallItem
             */
            UserWallItem.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.user.UserWallItem)
                    return d;
                var m = new $root.streem.user.UserWallItem();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.ownerDeviceId != null) {
                    m.ownerDeviceId = String(d.ownerDeviceId);
                }
                if (d.clientCreatedAt != null) {
                    if (typeof d.clientCreatedAt !== "object")
                        throw TypeError(".streem.user.UserWallItem.clientCreatedAt: object expected");
                    m.clientCreatedAt = $root.google.protobuf.Timestamp.fromObject(d.clientCreatedAt);
                }
                if (d.lastIdentifiedDevice != null) {
                    if (typeof d.lastIdentifiedDevice !== "object")
                        throw TypeError(".streem.user.UserWallItem.lastIdentifiedDevice: object expected");
                    m.lastIdentifiedDevice = $root.streem.user.UserWallItem.LastIdentifiedDevice.fromObject(d.lastIdentifiedDevice);
                }
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.user.UserWallItem.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                if (d.smsDeliveryStatus != null) {
                    if (typeof d.smsDeliveryStatus !== "object")
                        throw TypeError(".streem.user.UserWallItem.smsDeliveryStatus: object expected");
                    m.smsDeliveryStatus = $root.streem.api.SmsDeliveryStatus.fromObject(d.smsDeliveryStatus);
                }
                if (d.userAvailability != null) {
                    if (typeof d.userAvailability !== "object")
                        throw TypeError(".streem.user.UserWallItem.userAvailability: object expected");
                    m.userAvailability = $root.streem.api.UserAvailability.fromObject(d.userAvailability);
                }
                if (d.lastGroupReservation != null) {
                    if (typeof d.lastGroupReservation !== "object")
                        throw TypeError(".streem.user.UserWallItem.lastGroupReservation: object expected");
                    m.lastGroupReservation = $root.streem.api.GroupReservation.fromObject(d.lastGroupReservation);
                }
                if (d.heartbeat != null) {
                    if (typeof d.heartbeat !== "object")
                        throw TypeError(".streem.user.UserWallItem.heartbeat: object expected");
                    m.heartbeat = $root.streem.user.UserWallItem.Heartbeat.fromObject(d.heartbeat);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserWallItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.user.UserWallItem
             * @static
             * @param {streem.user.UserWallItem} m UserWallItem
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserWallItem.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.ownerDeviceId = "";
                    d.clientCreatedAt = null;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.ownerDeviceId != null && m.hasOwnProperty("ownerDeviceId")) {
                    d.ownerDeviceId = m.ownerDeviceId;
                }
                if (m.clientCreatedAt != null && m.hasOwnProperty("clientCreatedAt")) {
                    d.clientCreatedAt = $root.google.protobuf.Timestamp.toObject(m.clientCreatedAt, o);
                }
                if (m.lastIdentifiedDevice != null && m.hasOwnProperty("lastIdentifiedDevice")) {
                    d.lastIdentifiedDevice = $root.streem.user.UserWallItem.LastIdentifiedDevice.toObject(m.lastIdentifiedDevice, o);
                    if (o.oneofs)
                        d.type = "lastIdentifiedDevice";
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                    if (o.oneofs)
                        d.type = "roomInvitation";
                }
                if (m.smsDeliveryStatus != null && m.hasOwnProperty("smsDeliveryStatus")) {
                    d.smsDeliveryStatus = $root.streem.api.SmsDeliveryStatus.toObject(m.smsDeliveryStatus, o);
                    if (o.oneofs)
                        d.type = "smsDeliveryStatus";
                }
                if (m.userAvailability != null && m.hasOwnProperty("userAvailability")) {
                    d.userAvailability = $root.streem.api.UserAvailability.toObject(m.userAvailability, o);
                    if (o.oneofs)
                        d.type = "userAvailability";
                }
                if (m.lastGroupReservation != null && m.hasOwnProperty("lastGroupReservation")) {
                    d.lastGroupReservation = $root.streem.api.GroupReservation.toObject(m.lastGroupReservation, o);
                    if (o.oneofs)
                        d.type = "lastGroupReservation";
                }
                if (m.heartbeat != null && m.hasOwnProperty("heartbeat")) {
                    d.heartbeat = $root.streem.user.UserWallItem.Heartbeat.toObject(m.heartbeat, o);
                    if (o.oneofs)
                        d.type = "heartbeat";
                }
                return d;
            };

            /**
             * Converts this UserWallItem to JSON.
             * @function toJSON
             * @memberof streem.user.UserWallItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserWallItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserWallItem
             * @function getTypeUrl
             * @memberof streem.user.UserWallItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserWallItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.user.UserWallItem";
            };

            UserWallItem.LastIdentifiedDevice = (function() {

                /**
                 * Properties of a LastIdentifiedDevice.
                 * @memberof streem.user.UserWallItem
                 * @interface ILastIdentifiedDevice
                 * @property {string|null} [deviceSid] LastIdentifiedDevice deviceSid
                 */

                /**
                 * Constructs a new LastIdentifiedDevice.
                 * @memberof streem.user.UserWallItem
                 * @classdesc Represents a LastIdentifiedDevice.
                 * @implements ILastIdentifiedDevice
                 * @constructor
                 * @param {streem.user.UserWallItem.ILastIdentifiedDevice=} [p] Properties to set
                 */
                function LastIdentifiedDevice(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LastIdentifiedDevice deviceSid.
                 * @member {string} deviceSid
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @instance
                 */
                LastIdentifiedDevice.prototype.deviceSid = "";

                /**
                 * Creates a new LastIdentifiedDevice instance using the specified properties.
                 * @function create
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {streem.user.UserWallItem.ILastIdentifiedDevice=} [properties] Properties to set
                 * @returns {streem.user.UserWallItem.LastIdentifiedDevice} LastIdentifiedDevice instance
                 */
                LastIdentifiedDevice.create = function create(properties) {
                    return new LastIdentifiedDevice(properties);
                };

                /**
                 * Encodes the specified LastIdentifiedDevice message. Does not implicitly {@link streem.user.UserWallItem.LastIdentifiedDevice.verify|verify} messages.
                 * @function encode
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {streem.user.UserWallItem.ILastIdentifiedDevice} m LastIdentifiedDevice message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LastIdentifiedDevice.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                        w.uint32(10).string(m.deviceSid);
                    return w;
                };

                /**
                 * Decodes a LastIdentifiedDevice message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.user.UserWallItem.LastIdentifiedDevice} LastIdentifiedDevice
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LastIdentifiedDevice.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.user.UserWallItem.LastIdentifiedDevice();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.deviceSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LastIdentifiedDevice message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.user.UserWallItem.LastIdentifiedDevice} LastIdentifiedDevice
                 */
                LastIdentifiedDevice.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.user.UserWallItem.LastIdentifiedDevice)
                        return d;
                    var m = new $root.streem.user.UserWallItem.LastIdentifiedDevice();
                    if (d.deviceSid != null) {
                        m.deviceSid = String(d.deviceSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LastIdentifiedDevice message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {streem.user.UserWallItem.LastIdentifiedDevice} m LastIdentifiedDevice
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LastIdentifiedDevice.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.deviceSid = "";
                    }
                    if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                        d.deviceSid = m.deviceSid;
                    }
                    return d;
                };

                /**
                 * Converts this LastIdentifiedDevice to JSON.
                 * @function toJSON
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LastIdentifiedDevice.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LastIdentifiedDevice
                 * @function getTypeUrl
                 * @memberof streem.user.UserWallItem.LastIdentifiedDevice
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LastIdentifiedDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.user.UserWallItem.LastIdentifiedDevice";
                };

                return LastIdentifiedDevice;
            })();

            UserWallItem.Heartbeat = (function() {

                /**
                 * Properties of a Heartbeat.
                 * @memberof streem.user.UserWallItem
                 * @interface IHeartbeat
                 * @property {number|null} [count] Heartbeat count
                 * @property {google.protobuf.ITimestamp|null} [timestamp] Heartbeat timestamp
                 */

                /**
                 * Constructs a new Heartbeat.
                 * @memberof streem.user.UserWallItem
                 * @classdesc Represents a Heartbeat.
                 * @implements IHeartbeat
                 * @constructor
                 * @param {streem.user.UserWallItem.IHeartbeat=} [p] Properties to set
                 */
                function Heartbeat(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Heartbeat count.
                 * @member {number} count
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @instance
                 */
                Heartbeat.prototype.count = 0;

                /**
                 * Heartbeat timestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} timestamp
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @instance
                 */
                Heartbeat.prototype.timestamp = null;

                /**
                 * Creates a new Heartbeat instance using the specified properties.
                 * @function create
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {streem.user.UserWallItem.IHeartbeat=} [properties] Properties to set
                 * @returns {streem.user.UserWallItem.Heartbeat} Heartbeat instance
                 */
                Heartbeat.create = function create(properties) {
                    return new Heartbeat(properties);
                };

                /**
                 * Encodes the specified Heartbeat message. Does not implicitly {@link streem.user.UserWallItem.Heartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {streem.user.UserWallItem.IHeartbeat} m Heartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Heartbeat.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                        w.uint32(8).uint32(m.count);
                    if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                        $root.google.protobuf.Timestamp.encode(m.timestamp, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Heartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.user.UserWallItem.Heartbeat} Heartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Heartbeat.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.user.UserWallItem.Heartbeat();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.count = r.uint32();
                                break;
                            }
                        case 2: {
                                m.timestamp = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.user.UserWallItem.Heartbeat} Heartbeat
                 */
                Heartbeat.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.user.UserWallItem.Heartbeat)
                        return d;
                    var m = new $root.streem.user.UserWallItem.Heartbeat();
                    if (d.count != null) {
                        m.count = d.count >>> 0;
                    }
                    if (d.timestamp != null) {
                        if (typeof d.timestamp !== "object")
                            throw TypeError(".streem.user.UserWallItem.Heartbeat.timestamp: object expected");
                        m.timestamp = $root.google.protobuf.Timestamp.fromObject(d.timestamp);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {streem.user.UserWallItem.Heartbeat} m Heartbeat
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Heartbeat.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.count = 0;
                        d.timestamp = null;
                    }
                    if (m.count != null && m.hasOwnProperty("count")) {
                        d.count = m.count;
                    }
                    if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                        d.timestamp = $root.google.protobuf.Timestamp.toObject(m.timestamp, o);
                    }
                    return d;
                };

                /**
                 * Converts this Heartbeat to JSON.
                 * @function toJSON
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Heartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Heartbeat
                 * @function getTypeUrl
                 * @memberof streem.user.UserWallItem.Heartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Heartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.user.UserWallItem.Heartbeat";
                };

                return Heartbeat;
            })();

            return UserWallItem;
        })();

        return user;
    })();

    streem.api = (function() {

        /**
         * Namespace api.
         * @memberof streem
         * @namespace
         */
        var api = {};

        api.Setting = (function() {

            /**
             * Properties of a Setting.
             * @memberof streem.api
             * @interface ISetting
             * @property {string|null} [name] Setting name
             * @property {string|null} [value] Setting value
             * @property {streem.api.SettingDefinition.Type|null} [type] Setting type
             */

            /**
             * Constructs a new Setting.
             * @memberof streem.api
             * @classdesc Represents a Setting.
             * @implements ISetting
             * @constructor
             * @param {streem.api.ISetting=} [p] Properties to set
             */
            function Setting(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Setting name.
             * @member {string} name
             * @memberof streem.api.Setting
             * @instance
             */
            Setting.prototype.name = "";

            /**
             * Setting value.
             * @member {string} value
             * @memberof streem.api.Setting
             * @instance
             */
            Setting.prototype.value = "";

            /**
             * Setting type.
             * @member {streem.api.SettingDefinition.Type} type
             * @memberof streem.api.Setting
             * @instance
             */
            Setting.prototype.type = 0;

            /**
             * Creates a new Setting instance using the specified properties.
             * @function create
             * @memberof streem.api.Setting
             * @static
             * @param {streem.api.ISetting=} [properties] Properties to set
             * @returns {streem.api.Setting} Setting instance
             */
            Setting.create = function create(properties) {
                return new Setting(properties);
            };

            /**
             * Encodes the specified Setting message. Does not implicitly {@link streem.api.Setting.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Setting
             * @static
             * @param {streem.api.ISetting} m Setting message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Setting.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(26).string(m.value);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(32).int32(m.type);
                return w;
            };

            /**
             * Decodes a Setting message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Setting
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Setting} Setting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Setting.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Setting();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.value = r.string();
                            break;
                        }
                    case 4: {
                            m.type = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Setting message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Setting
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Setting} Setting
             */
            Setting.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Setting)
                    return d;
                var m = new $root.streem.api.Setting();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "TYPE_STRING":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_BOOLEAN":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_INTEGER":
                case 3:
                    m.type = 3;
                    break;
                case "TYPE_DOUBLE":
                case 4:
                    m.type = 4;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a Setting message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Setting
             * @static
             * @param {streem.api.Setting} m Setting
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Setting.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.value = "";
                    d.type = o.enums === String ? "TYPE_INVALID" : 0;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.SettingDefinition.Type[m.type] === undefined ? m.type : $root.streem.api.SettingDefinition.Type[m.type] : m.type;
                }
                return d;
            };

            /**
             * Converts this Setting to JSON.
             * @function toJSON
             * @memberof streem.api.Setting
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Setting.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Setting
             * @function getTypeUrl
             * @memberof streem.api.Setting
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Setting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Setting";
            };

            return Setting;
        })();

        api.SettingDefinition = (function() {

            /**
             * Properties of a SettingDefinition.
             * @memberof streem.api
             * @interface ISettingDefinition
             * @property {string|null} [name] SettingDefinition name
             * @property {streem.api.SettingDefinition.Type|null} [type] SettingDefinition type
             * @property {string|null} ["default"] SettingDefinition default
             * @property {boolean|null} [publicRead] SettingDefinition publicRead
             * @property {boolean|null} [companyWrite] SettingDefinition companyWrite
             * @property {streem.api.SettingDefinition.Scope|null} [scope] SettingDefinition scope
             * @property {google.protobuf.ITimestamp|null} [createdAt] SettingDefinition createdAt
             * @property {google.protobuf.ITimestamp|null} [modifiedAt] SettingDefinition modifiedAt
             */

            /**
             * Constructs a new SettingDefinition.
             * @memberof streem.api
             * @classdesc Represents a SettingDefinition.
             * @implements ISettingDefinition
             * @constructor
             * @param {streem.api.ISettingDefinition=} [p] Properties to set
             */
            function SettingDefinition(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SettingDefinition name.
             * @member {string} name
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.name = "";

            /**
             * SettingDefinition type.
             * @member {streem.api.SettingDefinition.Type} type
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.type = 0;

            /**
             * SettingDefinition default.
             * @member {string} default
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype["default"] = "";

            /**
             * SettingDefinition publicRead.
             * @member {boolean} publicRead
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.publicRead = false;

            /**
             * SettingDefinition companyWrite.
             * @member {boolean} companyWrite
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.companyWrite = false;

            /**
             * SettingDefinition scope.
             * @member {streem.api.SettingDefinition.Scope} scope
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.scope = 0;

            /**
             * SettingDefinition createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.createdAt = null;

            /**
             * SettingDefinition modifiedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} modifiedAt
             * @memberof streem.api.SettingDefinition
             * @instance
             */
            SettingDefinition.prototype.modifiedAt = null;

            /**
             * Creates a new SettingDefinition instance using the specified properties.
             * @function create
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {streem.api.ISettingDefinition=} [properties] Properties to set
             * @returns {streem.api.SettingDefinition} SettingDefinition instance
             */
            SettingDefinition.create = function create(properties) {
                return new SettingDefinition(properties);
            };

            /**
             * Encodes the specified SettingDefinition message. Does not implicitly {@link streem.api.SettingDefinition.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {streem.api.ISettingDefinition} m SettingDefinition message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SettingDefinition.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(16).int32(m.type);
                if (m["default"] != null && Object.hasOwnProperty.call(m, "default"))
                    w.uint32(26).string(m["default"]);
                if (m.publicRead != null && Object.hasOwnProperty.call(m, "publicRead"))
                    w.uint32(32).bool(m.publicRead);
                if (m.companyWrite != null && Object.hasOwnProperty.call(m, "companyWrite"))
                    w.uint32(40).bool(m.companyWrite);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(48).int32(m.scope);
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(58).fork()).ldelim();
                if (m.modifiedAt != null && Object.hasOwnProperty.call(m, "modifiedAt"))
                    $root.google.protobuf.Timestamp.encode(m.modifiedAt, w.uint32(66).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SettingDefinition message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SettingDefinition} SettingDefinition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SettingDefinition.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SettingDefinition();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.type = r.int32();
                            break;
                        }
                    case 3: {
                            m["default"] = r.string();
                            break;
                        }
                    case 4: {
                            m.publicRead = r.bool();
                            break;
                        }
                    case 5: {
                            m.companyWrite = r.bool();
                            break;
                        }
                    case 6: {
                            m.scope = r.int32();
                            break;
                        }
                    case 7: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            m.modifiedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SettingDefinition message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SettingDefinition} SettingDefinition
             */
            SettingDefinition.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SettingDefinition)
                    return d;
                var m = new $root.streem.api.SettingDefinition();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "TYPE_STRING":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_BOOLEAN":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_INTEGER":
                case 3:
                    m.type = 3;
                    break;
                case "TYPE_DOUBLE":
                case 4:
                    m.type = 4;
                    break;
                }
                if (d["default"] != null) {
                    m["default"] = String(d["default"]);
                }
                if (d.publicRead != null) {
                    m.publicRead = Boolean(d.publicRead);
                }
                if (d.companyWrite != null) {
                    m.companyWrite = Boolean(d.companyWrite);
                }
                switch (d.scope) {
                default:
                    if (typeof d.scope === "number") {
                        m.scope = d.scope;
                        break;
                    }
                    break;
                case "SCOPE_INVALID":
                case 0:
                    m.scope = 0;
                    break;
                case "SCOPE_PLATFORM":
                case 1:
                    m.scope = 1;
                    break;
                case "SCOPE_LICENSE":
                case 2:
                    m.scope = 2;
                    break;
                case "SCOPE_INTERNAL":
                case 3:
                    m.scope = 3;
                    break;
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.SettingDefinition.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                if (d.modifiedAt != null) {
                    if (typeof d.modifiedAt !== "object")
                        throw TypeError(".streem.api.SettingDefinition.modifiedAt: object expected");
                    m.modifiedAt = $root.google.protobuf.Timestamp.fromObject(d.modifiedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a SettingDefinition message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {streem.api.SettingDefinition} m SettingDefinition
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SettingDefinition.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.type = o.enums === String ? "TYPE_INVALID" : 0;
                    d["default"] = "";
                    d.publicRead = false;
                    d.companyWrite = false;
                    d.scope = o.enums === String ? "SCOPE_INVALID" : 0;
                    d.createdAt = null;
                    d.modifiedAt = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.SettingDefinition.Type[m.type] === undefined ? m.type : $root.streem.api.SettingDefinition.Type[m.type] : m.type;
                }
                if (m["default"] != null && m.hasOwnProperty("default")) {
                    d["default"] = m["default"];
                }
                if (m.publicRead != null && m.hasOwnProperty("publicRead")) {
                    d.publicRead = m.publicRead;
                }
                if (m.companyWrite != null && m.hasOwnProperty("companyWrite")) {
                    d.companyWrite = m.companyWrite;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = o.enums === String ? $root.streem.api.SettingDefinition.Scope[m.scope] === undefined ? m.scope : $root.streem.api.SettingDefinition.Scope[m.scope] : m.scope;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.modifiedAt != null && m.hasOwnProperty("modifiedAt")) {
                    d.modifiedAt = $root.google.protobuf.Timestamp.toObject(m.modifiedAt, o);
                }
                return d;
            };

            /**
             * Converts this SettingDefinition to JSON.
             * @function toJSON
             * @memberof streem.api.SettingDefinition
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SettingDefinition.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SettingDefinition
             * @function getTypeUrl
             * @memberof streem.api.SettingDefinition
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SettingDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SettingDefinition";
            };

            /**
             * Type enum.
             * @name streem.api.SettingDefinition.Type
             * @enum {number}
             * @property {number} TYPE_INVALID=0 TYPE_INVALID value
             * @property {number} TYPE_STRING=1 TYPE_STRING value
             * @property {number} TYPE_BOOLEAN=2 TYPE_BOOLEAN value
             * @property {number} TYPE_INTEGER=3 TYPE_INTEGER value
             * @property {number} TYPE_DOUBLE=4 TYPE_DOUBLE value
             */
            SettingDefinition.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_INVALID"] = 0;
                values[valuesById[1] = "TYPE_STRING"] = 1;
                values[valuesById[2] = "TYPE_BOOLEAN"] = 2;
                values[valuesById[3] = "TYPE_INTEGER"] = 3;
                values[valuesById[4] = "TYPE_DOUBLE"] = 4;
                return values;
            })();

            /**
             * Scope enum.
             * @name streem.api.SettingDefinition.Scope
             * @enum {number}
             * @property {number} SCOPE_INVALID=0 SCOPE_INVALID value
             * @property {number} SCOPE_PLATFORM=1 SCOPE_PLATFORM value
             * @property {number} SCOPE_LICENSE=2 SCOPE_LICENSE value
             * @property {number} SCOPE_INTERNAL=3 SCOPE_INTERNAL value
             */
            SettingDefinition.Scope = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SCOPE_INVALID"] = 0;
                values[valuesById[1] = "SCOPE_PLATFORM"] = 1;
                values[valuesById[2] = "SCOPE_LICENSE"] = 2;
                values[valuesById[3] = "SCOPE_INTERNAL"] = 3;
                return values;
            })();

            return SettingDefinition;
        })();

        api.SettingEntry = (function() {

            /**
             * Properties of a SettingEntry.
             * @memberof streem.api
             * @interface ISettingEntry
             * @property {string|null} [settingName] SettingEntry settingName
             * @property {string|null} [entityId] SettingEntry entityId
             * @property {streem.api.EntityType|null} [entityType] SettingEntry entityType
             * @property {string|null} [settingValue] SettingEntry settingValue
             * @property {google.protobuf.ITimestamp|null} [createdAt] SettingEntry createdAt
             * @property {google.protobuf.ITimestamp|null} [modifiedAt] SettingEntry modifiedAt
             */

            /**
             * Constructs a new SettingEntry.
             * @memberof streem.api
             * @classdesc Represents a SettingEntry.
             * @implements ISettingEntry
             * @constructor
             * @param {streem.api.ISettingEntry=} [p] Properties to set
             */
            function SettingEntry(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SettingEntry settingName.
             * @member {string} settingName
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.settingName = "";

            /**
             * SettingEntry entityId.
             * @member {string} entityId
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.entityId = "";

            /**
             * SettingEntry entityType.
             * @member {streem.api.EntityType} entityType
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.entityType = 0;

            /**
             * SettingEntry settingValue.
             * @member {string} settingValue
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.settingValue = "";

            /**
             * SettingEntry createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.createdAt = null;

            /**
             * SettingEntry modifiedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} modifiedAt
             * @memberof streem.api.SettingEntry
             * @instance
             */
            SettingEntry.prototype.modifiedAt = null;

            /**
             * Creates a new SettingEntry instance using the specified properties.
             * @function create
             * @memberof streem.api.SettingEntry
             * @static
             * @param {streem.api.ISettingEntry=} [properties] Properties to set
             * @returns {streem.api.SettingEntry} SettingEntry instance
             */
            SettingEntry.create = function create(properties) {
                return new SettingEntry(properties);
            };

            /**
             * Encodes the specified SettingEntry message. Does not implicitly {@link streem.api.SettingEntry.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SettingEntry
             * @static
             * @param {streem.api.ISettingEntry} m SettingEntry message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SettingEntry.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settingName != null && Object.hasOwnProperty.call(m, "settingName"))
                    w.uint32(10).string(m.settingName);
                if (m.entityId != null && Object.hasOwnProperty.call(m, "entityId"))
                    w.uint32(18).string(m.entityId);
                if (m.entityType != null && Object.hasOwnProperty.call(m, "entityType"))
                    w.uint32(24).int32(m.entityType);
                if (m.settingValue != null && Object.hasOwnProperty.call(m, "settingValue"))
                    w.uint32(34).string(m.settingValue);
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(42).fork()).ldelim();
                if (m.modifiedAt != null && Object.hasOwnProperty.call(m, "modifiedAt"))
                    $root.google.protobuf.Timestamp.encode(m.modifiedAt, w.uint32(50).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SettingEntry message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SettingEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SettingEntry} SettingEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SettingEntry.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SettingEntry();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.settingName = r.string();
                            break;
                        }
                    case 2: {
                            m.entityId = r.string();
                            break;
                        }
                    case 3: {
                            m.entityType = r.int32();
                            break;
                        }
                    case 4: {
                            m.settingValue = r.string();
                            break;
                        }
                    case 5: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.modifiedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SettingEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SettingEntry
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SettingEntry} SettingEntry
             */
            SettingEntry.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SettingEntry)
                    return d;
                var m = new $root.streem.api.SettingEntry();
                if (d.settingName != null) {
                    m.settingName = String(d.settingName);
                }
                if (d.entityId != null) {
                    m.entityId = String(d.entityId);
                }
                switch (d.entityType) {
                default:
                    if (typeof d.entityType === "number") {
                        m.entityType = d.entityType;
                        break;
                    }
                    break;
                case "ENTITY_TYPE_INVALID":
                case 0:
                    m.entityType = 0;
                    break;
                case "ENTITY_TYPE_LICENSE":
                case 1:
                    m.entityType = 1;
                    break;
                case "ENTITY_TYPE_COMPANY":
                case 2:
                    m.entityType = 2;
                    break;
                }
                if (d.settingValue != null) {
                    m.settingValue = String(d.settingValue);
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.SettingEntry.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                if (d.modifiedAt != null) {
                    if (typeof d.modifiedAt !== "object")
                        throw TypeError(".streem.api.SettingEntry.modifiedAt: object expected");
                    m.modifiedAt = $root.google.protobuf.Timestamp.fromObject(d.modifiedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a SettingEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SettingEntry
             * @static
             * @param {streem.api.SettingEntry} m SettingEntry
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SettingEntry.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.settingName = "";
                    d.entityId = "";
                    d.entityType = o.enums === String ? "ENTITY_TYPE_INVALID" : 0;
                    d.settingValue = "";
                    d.createdAt = null;
                    d.modifiedAt = null;
                }
                if (m.settingName != null && m.hasOwnProperty("settingName")) {
                    d.settingName = m.settingName;
                }
                if (m.entityId != null && m.hasOwnProperty("entityId")) {
                    d.entityId = m.entityId;
                }
                if (m.entityType != null && m.hasOwnProperty("entityType")) {
                    d.entityType = o.enums === String ? $root.streem.api.EntityType[m.entityType] === undefined ? m.entityType : $root.streem.api.EntityType[m.entityType] : m.entityType;
                }
                if (m.settingValue != null && m.hasOwnProperty("settingValue")) {
                    d.settingValue = m.settingValue;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.modifiedAt != null && m.hasOwnProperty("modifiedAt")) {
                    d.modifiedAt = $root.google.protobuf.Timestamp.toObject(m.modifiedAt, o);
                }
                return d;
            };

            /**
             * Converts this SettingEntry to JSON.
             * @function toJSON
             * @memberof streem.api.SettingEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SettingEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SettingEntry
             * @function getTypeUrl
             * @memberof streem.api.SettingEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SettingEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SettingEntry";
            };

            return SettingEntry;
        })();

        /**
         * EntityType enum.
         * @name streem.api.EntityType
         * @enum {number}
         * @property {number} ENTITY_TYPE_INVALID=0 ENTITY_TYPE_INVALID value
         * @property {number} ENTITY_TYPE_LICENSE=1 ENTITY_TYPE_LICENSE value
         * @property {number} ENTITY_TYPE_COMPANY=2 ENTITY_TYPE_COMPANY value
         */
        api.EntityType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ENTITY_TYPE_INVALID"] = 0;
            values[valuesById[1] = "ENTITY_TYPE_LICENSE"] = 1;
            values[valuesById[2] = "ENTITY_TYPE_COMPANY"] = 2;
            return values;
        })();

        api.LookupPiiPayload = (function() {

            /**
             * Properties of a LookupPiiPayload.
             * @memberof streem.api
             * @interface ILookupPiiPayload
             * @property {Array.<streem.api.ILookupPiiCompanyResult>|null} [lookupPiiCompanyResults] LookupPiiPayload lookupPiiCompanyResults
             * @property {string|null} [customIdentifier] LookupPiiPayload customIdentifier
             */

            /**
             * Constructs a new LookupPiiPayload.
             * @memberof streem.api
             * @classdesc Represents a LookupPiiPayload.
             * @implements ILookupPiiPayload
             * @constructor
             * @param {streem.api.ILookupPiiPayload=} [p] Properties to set
             */
            function LookupPiiPayload(p) {
                this.lookupPiiCompanyResults = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LookupPiiPayload lookupPiiCompanyResults.
             * @member {Array.<streem.api.ILookupPiiCompanyResult>} lookupPiiCompanyResults
             * @memberof streem.api.LookupPiiPayload
             * @instance
             */
            LookupPiiPayload.prototype.lookupPiiCompanyResults = $util.emptyArray;

            /**
             * LookupPiiPayload customIdentifier.
             * @member {string} customIdentifier
             * @memberof streem.api.LookupPiiPayload
             * @instance
             */
            LookupPiiPayload.prototype.customIdentifier = "";

            /**
             * Creates a new LookupPiiPayload instance using the specified properties.
             * @function create
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {streem.api.ILookupPiiPayload=} [properties] Properties to set
             * @returns {streem.api.LookupPiiPayload} LookupPiiPayload instance
             */
            LookupPiiPayload.create = function create(properties) {
                return new LookupPiiPayload(properties);
            };

            /**
             * Encodes the specified LookupPiiPayload message. Does not implicitly {@link streem.api.LookupPiiPayload.verify|verify} messages.
             * @function encode
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {streem.api.ILookupPiiPayload} m LookupPiiPayload message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LookupPiiPayload.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.lookupPiiCompanyResults != null && m.lookupPiiCompanyResults.length) {
                    for (var i = 0; i < m.lookupPiiCompanyResults.length; ++i)
                        $root.streem.api.LookupPiiCompanyResult.encode(m.lookupPiiCompanyResults[i], w.uint32(10).fork()).ldelim();
                }
                if (m.customIdentifier != null && Object.hasOwnProperty.call(m, "customIdentifier"))
                    w.uint32(18).string(m.customIdentifier);
                return w;
            };

            /**
             * Decodes a LookupPiiPayload message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.LookupPiiPayload} LookupPiiPayload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LookupPiiPayload.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.LookupPiiPayload();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.lookupPiiCompanyResults && m.lookupPiiCompanyResults.length))
                                m.lookupPiiCompanyResults = [];
                            m.lookupPiiCompanyResults.push($root.streem.api.LookupPiiCompanyResult.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.customIdentifier = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LookupPiiPayload message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.LookupPiiPayload} LookupPiiPayload
             */
            LookupPiiPayload.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.LookupPiiPayload)
                    return d;
                var m = new $root.streem.api.LookupPiiPayload();
                if (d.lookupPiiCompanyResults) {
                    if (!Array.isArray(d.lookupPiiCompanyResults))
                        throw TypeError(".streem.api.LookupPiiPayload.lookupPiiCompanyResults: array expected");
                    m.lookupPiiCompanyResults = [];
                    for (var i = 0; i < d.lookupPiiCompanyResults.length; ++i) {
                        if (typeof d.lookupPiiCompanyResults[i] !== "object")
                            throw TypeError(".streem.api.LookupPiiPayload.lookupPiiCompanyResults: object expected");
                        m.lookupPiiCompanyResults[i] = $root.streem.api.LookupPiiCompanyResult.fromObject(d.lookupPiiCompanyResults[i]);
                    }
                }
                if (d.customIdentifier != null) {
                    m.customIdentifier = String(d.customIdentifier);
                }
                return m;
            };

            /**
             * Creates a plain object from a LookupPiiPayload message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {streem.api.LookupPiiPayload} m LookupPiiPayload
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LookupPiiPayload.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.lookupPiiCompanyResults = [];
                }
                if (o.defaults) {
                    d.customIdentifier = "";
                }
                if (m.lookupPiiCompanyResults && m.lookupPiiCompanyResults.length) {
                    d.lookupPiiCompanyResults = [];
                    for (var j = 0; j < m.lookupPiiCompanyResults.length; ++j) {
                        d.lookupPiiCompanyResults[j] = $root.streem.api.LookupPiiCompanyResult.toObject(m.lookupPiiCompanyResults[j], o);
                    }
                }
                if (m.customIdentifier != null && m.hasOwnProperty("customIdentifier")) {
                    d.customIdentifier = m.customIdentifier;
                }
                return d;
            };

            /**
             * Converts this LookupPiiPayload to JSON.
             * @function toJSON
             * @memberof streem.api.LookupPiiPayload
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LookupPiiPayload.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LookupPiiPayload
             * @function getTypeUrl
             * @memberof streem.api.LookupPiiPayload
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LookupPiiPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.LookupPiiPayload";
            };

            return LookupPiiPayload;
        })();

        api.LookupPiiCompanyResult = (function() {

            /**
             * Properties of a LookupPiiCompanyResult.
             * @memberof streem.api
             * @interface ILookupPiiCompanyResult
             * @property {Array.<streem.api.ILookupPiiResult>|null} [piiResults] LookupPiiCompanyResult piiResults
             * @property {string|null} [companySid] LookupPiiCompanyResult companySid
             */

            /**
             * Constructs a new LookupPiiCompanyResult.
             * @memberof streem.api
             * @classdesc Represents a LookupPiiCompanyResult.
             * @implements ILookupPiiCompanyResult
             * @constructor
             * @param {streem.api.ILookupPiiCompanyResult=} [p] Properties to set
             */
            function LookupPiiCompanyResult(p) {
                this.piiResults = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LookupPiiCompanyResult piiResults.
             * @member {Array.<streem.api.ILookupPiiResult>} piiResults
             * @memberof streem.api.LookupPiiCompanyResult
             * @instance
             */
            LookupPiiCompanyResult.prototype.piiResults = $util.emptyArray;

            /**
             * LookupPiiCompanyResult companySid.
             * @member {string} companySid
             * @memberof streem.api.LookupPiiCompanyResult
             * @instance
             */
            LookupPiiCompanyResult.prototype.companySid = "";

            /**
             * Creates a new LookupPiiCompanyResult instance using the specified properties.
             * @function create
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {streem.api.ILookupPiiCompanyResult=} [properties] Properties to set
             * @returns {streem.api.LookupPiiCompanyResult} LookupPiiCompanyResult instance
             */
            LookupPiiCompanyResult.create = function create(properties) {
                return new LookupPiiCompanyResult(properties);
            };

            /**
             * Encodes the specified LookupPiiCompanyResult message. Does not implicitly {@link streem.api.LookupPiiCompanyResult.verify|verify} messages.
             * @function encode
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {streem.api.ILookupPiiCompanyResult} m LookupPiiCompanyResult message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LookupPiiCompanyResult.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.piiResults != null && m.piiResults.length) {
                    for (var i = 0; i < m.piiResults.length; ++i)
                        $root.streem.api.LookupPiiResult.encode(m.piiResults[i], w.uint32(10).fork()).ldelim();
                }
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                return w;
            };

            /**
             * Decodes a LookupPiiCompanyResult message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.LookupPiiCompanyResult} LookupPiiCompanyResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LookupPiiCompanyResult.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.LookupPiiCompanyResult();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.piiResults && m.piiResults.length))
                                m.piiResults = [];
                            m.piiResults.push($root.streem.api.LookupPiiResult.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LookupPiiCompanyResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.LookupPiiCompanyResult} LookupPiiCompanyResult
             */
            LookupPiiCompanyResult.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.LookupPiiCompanyResult)
                    return d;
                var m = new $root.streem.api.LookupPiiCompanyResult();
                if (d.piiResults) {
                    if (!Array.isArray(d.piiResults))
                        throw TypeError(".streem.api.LookupPiiCompanyResult.piiResults: array expected");
                    m.piiResults = [];
                    for (var i = 0; i < d.piiResults.length; ++i) {
                        if (typeof d.piiResults[i] !== "object")
                            throw TypeError(".streem.api.LookupPiiCompanyResult.piiResults: object expected");
                        m.piiResults[i] = $root.streem.api.LookupPiiResult.fromObject(d.piiResults[i]);
                    }
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a LookupPiiCompanyResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {streem.api.LookupPiiCompanyResult} m LookupPiiCompanyResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LookupPiiCompanyResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.piiResults = [];
                }
                if (o.defaults) {
                    d.companySid = "";
                }
                if (m.piiResults && m.piiResults.length) {
                    d.piiResults = [];
                    for (var j = 0; j < m.piiResults.length; ++j) {
                        d.piiResults[j] = $root.streem.api.LookupPiiResult.toObject(m.piiResults[j], o);
                    }
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                return d;
            };

            /**
             * Converts this LookupPiiCompanyResult to JSON.
             * @function toJSON
             * @memberof streem.api.LookupPiiCompanyResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LookupPiiCompanyResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LookupPiiCompanyResult
             * @function getTypeUrl
             * @memberof streem.api.LookupPiiCompanyResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LookupPiiCompanyResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.LookupPiiCompanyResult";
            };

            return LookupPiiCompanyResult;
        })();

        api.LookupPiiResult = (function() {

            /**
             * Properties of a LookupPiiResult.
             * @memberof streem.api
             * @interface ILookupPiiResult
             * @property {streem.api.ICallLogEntry|null} [callLogEntry] LookupPiiResult callLogEntry
             * @property {Array.<streem.api.IArtifact>|null} [artifacts] LookupPiiResult artifacts
             */

            /**
             * Constructs a new LookupPiiResult.
             * @memberof streem.api
             * @classdesc Represents a LookupPiiResult.
             * @implements ILookupPiiResult
             * @constructor
             * @param {streem.api.ILookupPiiResult=} [p] Properties to set
             */
            function LookupPiiResult(p) {
                this.artifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LookupPiiResult callLogEntry.
             * @member {streem.api.ICallLogEntry|null|undefined} callLogEntry
             * @memberof streem.api.LookupPiiResult
             * @instance
             */
            LookupPiiResult.prototype.callLogEntry = null;

            /**
             * LookupPiiResult artifacts.
             * @member {Array.<streem.api.IArtifact>} artifacts
             * @memberof streem.api.LookupPiiResult
             * @instance
             */
            LookupPiiResult.prototype.artifacts = $util.emptyArray;

            /**
             * Creates a new LookupPiiResult instance using the specified properties.
             * @function create
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {streem.api.ILookupPiiResult=} [properties] Properties to set
             * @returns {streem.api.LookupPiiResult} LookupPiiResult instance
             */
            LookupPiiResult.create = function create(properties) {
                return new LookupPiiResult(properties);
            };

            /**
             * Encodes the specified LookupPiiResult message. Does not implicitly {@link streem.api.LookupPiiResult.verify|verify} messages.
             * @function encode
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {streem.api.ILookupPiiResult} m LookupPiiResult message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LookupPiiResult.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntry != null && Object.hasOwnProperty.call(m, "callLogEntry"))
                    $root.streem.api.CallLogEntry.encode(m.callLogEntry, w.uint32(10).fork()).ldelim();
                if (m.artifacts != null && m.artifacts.length) {
                    for (var i = 0; i < m.artifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.artifacts[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a LookupPiiResult message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.LookupPiiResult} LookupPiiResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LookupPiiResult.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.LookupPiiResult();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callLogEntry = $root.streem.api.CallLogEntry.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            if (!(m.artifacts && m.artifacts.length))
                                m.artifacts = [];
                            m.artifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LookupPiiResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.LookupPiiResult} LookupPiiResult
             */
            LookupPiiResult.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.LookupPiiResult)
                    return d;
                var m = new $root.streem.api.LookupPiiResult();
                if (d.callLogEntry != null) {
                    if (typeof d.callLogEntry !== "object")
                        throw TypeError(".streem.api.LookupPiiResult.callLogEntry: object expected");
                    m.callLogEntry = $root.streem.api.CallLogEntry.fromObject(d.callLogEntry);
                }
                if (d.artifacts) {
                    if (!Array.isArray(d.artifacts))
                        throw TypeError(".streem.api.LookupPiiResult.artifacts: array expected");
                    m.artifacts = [];
                    for (var i = 0; i < d.artifacts.length; ++i) {
                        if (typeof d.artifacts[i] !== "object")
                            throw TypeError(".streem.api.LookupPiiResult.artifacts: object expected");
                        m.artifacts[i] = $root.streem.api.Artifact.fromObject(d.artifacts[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a LookupPiiResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {streem.api.LookupPiiResult} m LookupPiiResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LookupPiiResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.artifacts = [];
                }
                if (o.defaults) {
                    d.callLogEntry = null;
                }
                if (m.callLogEntry != null && m.hasOwnProperty("callLogEntry")) {
                    d.callLogEntry = $root.streem.api.CallLogEntry.toObject(m.callLogEntry, o);
                }
                if (m.artifacts && m.artifacts.length) {
                    d.artifacts = [];
                    for (var j = 0; j < m.artifacts.length; ++j) {
                        d.artifacts[j] = $root.streem.api.Artifact.toObject(m.artifacts[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this LookupPiiResult to JSON.
             * @function toJSON
             * @memberof streem.api.LookupPiiResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LookupPiiResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LookupPiiResult
             * @function getTypeUrl
             * @memberof streem.api.LookupPiiResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LookupPiiResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.LookupPiiResult";
            };

            return LookupPiiResult;
        })();

        api.OAuthUrlProperties = (function() {

            /**
             * Properties of a OAuthUrlProperties.
             * @memberof streem.api
             * @interface IOAuthUrlProperties
             * @property {string|null} [grantType] OAuthUrlProperties grantType
             * @property {string|null} [clientId] OAuthUrlProperties clientId
             * @property {string|null} [clientSecret] OAuthUrlProperties clientSecret
             */

            /**
             * Constructs a new OAuthUrlProperties.
             * @memberof streem.api
             * @classdesc Represents a OAuthUrlProperties.
             * @implements IOAuthUrlProperties
             * @constructor
             * @param {streem.api.IOAuthUrlProperties=} [p] Properties to set
             */
            function OAuthUrlProperties(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OAuthUrlProperties grantType.
             * @member {string} grantType
             * @memberof streem.api.OAuthUrlProperties
             * @instance
             */
            OAuthUrlProperties.prototype.grantType = "";

            /**
             * OAuthUrlProperties clientId.
             * @member {string} clientId
             * @memberof streem.api.OAuthUrlProperties
             * @instance
             */
            OAuthUrlProperties.prototype.clientId = "";

            /**
             * OAuthUrlProperties clientSecret.
             * @member {string} clientSecret
             * @memberof streem.api.OAuthUrlProperties
             * @instance
             */
            OAuthUrlProperties.prototype.clientSecret = "";

            /**
             * Creates a new OAuthUrlProperties instance using the specified properties.
             * @function create
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {streem.api.IOAuthUrlProperties=} [properties] Properties to set
             * @returns {streem.api.OAuthUrlProperties} OAuthUrlProperties instance
             */
            OAuthUrlProperties.create = function create(properties) {
                return new OAuthUrlProperties(properties);
            };

            /**
             * Encodes the specified OAuthUrlProperties message. Does not implicitly {@link streem.api.OAuthUrlProperties.verify|verify} messages.
             * @function encode
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {streem.api.IOAuthUrlProperties} m OAuthUrlProperties message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OAuthUrlProperties.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.grantType != null && Object.hasOwnProperty.call(m, "grantType"))
                    w.uint32(10).string(m.grantType);
                if (m.clientId != null && Object.hasOwnProperty.call(m, "clientId"))
                    w.uint32(18).string(m.clientId);
                if (m.clientSecret != null && Object.hasOwnProperty.call(m, "clientSecret"))
                    w.uint32(26).string(m.clientSecret);
                return w;
            };

            /**
             * Decodes a OAuthUrlProperties message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.OAuthUrlProperties} OAuthUrlProperties
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OAuthUrlProperties.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.OAuthUrlProperties();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.grantType = r.string();
                            break;
                        }
                    case 2: {
                            m.clientId = r.string();
                            break;
                        }
                    case 3: {
                            m.clientSecret = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a OAuthUrlProperties message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.OAuthUrlProperties} OAuthUrlProperties
             */
            OAuthUrlProperties.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.OAuthUrlProperties)
                    return d;
                var m = new $root.streem.api.OAuthUrlProperties();
                if (d.grantType != null) {
                    m.grantType = String(d.grantType);
                }
                if (d.clientId != null) {
                    m.clientId = String(d.clientId);
                }
                if (d.clientSecret != null) {
                    m.clientSecret = String(d.clientSecret);
                }
                return m;
            };

            /**
             * Creates a plain object from a OAuthUrlProperties message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {streem.api.OAuthUrlProperties} m OAuthUrlProperties
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OAuthUrlProperties.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.grantType = "";
                    d.clientId = "";
                    d.clientSecret = "";
                }
                if (m.grantType != null && m.hasOwnProperty("grantType")) {
                    d.grantType = m.grantType;
                }
                if (m.clientId != null && m.hasOwnProperty("clientId")) {
                    d.clientId = m.clientId;
                }
                if (m.clientSecret != null && m.hasOwnProperty("clientSecret")) {
                    d.clientSecret = m.clientSecret;
                }
                return d;
            };

            /**
             * Converts this OAuthUrlProperties to JSON.
             * @function toJSON
             * @memberof streem.api.OAuthUrlProperties
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OAuthUrlProperties.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OAuthUrlProperties
             * @function getTypeUrl
             * @memberof streem.api.OAuthUrlProperties
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OAuthUrlProperties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.OAuthUrlProperties";
            };

            return OAuthUrlProperties;
        })();

        api.Downloads = (function() {

            /**
             * Constructs a new Downloads service.
             * @memberof streem.api
             * @classdesc Represents a Downloads
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Downloads(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Downloads.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Downloads;

            /**
             * Creates new Downloads service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Downloads
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Downloads} RPC service. Useful where requests and/or responses are streamed.
             */
            Downloads.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Downloads#createSignedDownloadUrl}.
             * @memberof streem.api.Downloads
             * @typedef CreateSignedDownloadUrlCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateSignedDownloadUrlResponse} [response] CreateSignedDownloadUrlResponse
             */

            /**
             * Calls CreateSignedDownloadUrl.
             * @function createSignedDownloadUrl
             * @memberof streem.api.Downloads
             * @instance
             * @param {streem.api.ICreateSignedDownloadUrlRequest} request CreateSignedDownloadUrlRequest message or plain object
             * @param {streem.api.Downloads.CreateSignedDownloadUrlCallback} callback Node-style callback called with the error, if any, and CreateSignedDownloadUrlResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Downloads.prototype.createSignedDownloadUrl = function createSignedDownloadUrl(request, callback) {
                return this.rpcCall(createSignedDownloadUrl, $root.streem.api.CreateSignedDownloadUrlRequest, $root.streem.api.CreateSignedDownloadUrlResponse, request, callback);
            }, "name", { value: "CreateSignedDownloadUrl" });

            /**
             * Calls CreateSignedDownloadUrl.
             * @function createSignedDownloadUrl
             * @memberof streem.api.Downloads
             * @instance
             * @param {streem.api.ICreateSignedDownloadUrlRequest} request CreateSignedDownloadUrlRequest message or plain object
             * @returns {Promise<streem.api.CreateSignedDownloadUrlResponse>} Promise
             * @variation 2
             */

            return Downloads;
        })();

        api.CreateSignedDownloadUrlRequest = (function() {

            /**
             * Properties of a CreateSignedDownloadUrlRequest.
             * @memberof streem.api
             * @interface ICreateSignedDownloadUrlRequest
             * @property {string|null} [storageUrl] CreateSignedDownloadUrlRequest storageUrl
             * @property {streem.api.ArtifactFile.Scope|null} [scope] CreateSignedDownloadUrlRequest scope
             * @property {string|null} [scopeSid] CreateSignedDownloadUrlRequest scopeSid
             * @property {boolean|null} [attachment] CreateSignedDownloadUrlRequest attachment
             * @property {string|null} [filename] CreateSignedDownloadUrlRequest filename
             */

            /**
             * Constructs a new CreateSignedDownloadUrlRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateSignedDownloadUrlRequest.
             * @implements ICreateSignedDownloadUrlRequest
             * @constructor
             * @param {streem.api.ICreateSignedDownloadUrlRequest=} [p] Properties to set
             */
            function CreateSignedDownloadUrlRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSignedDownloadUrlRequest storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             */
            CreateSignedDownloadUrlRequest.prototype.storageUrl = "";

            /**
             * CreateSignedDownloadUrlRequest scope.
             * @member {streem.api.ArtifactFile.Scope} scope
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             */
            CreateSignedDownloadUrlRequest.prototype.scope = 0;

            /**
             * CreateSignedDownloadUrlRequest scopeSid.
             * @member {string} scopeSid
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             */
            CreateSignedDownloadUrlRequest.prototype.scopeSid = "";

            /**
             * CreateSignedDownloadUrlRequest attachment.
             * @member {boolean} attachment
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             */
            CreateSignedDownloadUrlRequest.prototype.attachment = false;

            /**
             * CreateSignedDownloadUrlRequest filename.
             * @member {string} filename
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             */
            CreateSignedDownloadUrlRequest.prototype.filename = "";

            /**
             * Creates a new CreateSignedDownloadUrlRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {streem.api.ICreateSignedDownloadUrlRequest=} [properties] Properties to set
             * @returns {streem.api.CreateSignedDownloadUrlRequest} CreateSignedDownloadUrlRequest instance
             */
            CreateSignedDownloadUrlRequest.create = function create(properties) {
                return new CreateSignedDownloadUrlRequest(properties);
            };

            /**
             * Encodes the specified CreateSignedDownloadUrlRequest message. Does not implicitly {@link streem.api.CreateSignedDownloadUrlRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {streem.api.ICreateSignedDownloadUrlRequest} m CreateSignedDownloadUrlRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSignedDownloadUrlRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(10).string(m.storageUrl);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(16).int32(m.scope);
                if (m.scopeSid != null && Object.hasOwnProperty.call(m, "scopeSid"))
                    w.uint32(26).string(m.scopeSid);
                if (m.attachment != null && Object.hasOwnProperty.call(m, "attachment"))
                    w.uint32(32).bool(m.attachment);
                if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                    w.uint32(42).string(m.filename);
                return w;
            };

            /**
             * Decodes a CreateSignedDownloadUrlRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSignedDownloadUrlRequest} CreateSignedDownloadUrlRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSignedDownloadUrlRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSignedDownloadUrlRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.scope = r.int32();
                            break;
                        }
                    case 3: {
                            m.scopeSid = r.string();
                            break;
                        }
                    case 4: {
                            m.attachment = r.bool();
                            break;
                        }
                    case 5: {
                            m.filename = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSignedDownloadUrlRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSignedDownloadUrlRequest} CreateSignedDownloadUrlRequest
             */
            CreateSignedDownloadUrlRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSignedDownloadUrlRequest)
                    return d;
                var m = new $root.streem.api.CreateSignedDownloadUrlRequest();
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                switch (d.scope) {
                default:
                    if (typeof d.scope === "number") {
                        m.scope = d.scope;
                        break;
                    }
                    break;
                case "SCOPE_INVALID":
                case 0:
                    m.scope = 0;
                    break;
                case "SCOPE_ROOM":
                case 1:
                    m.scope = 1;
                    break;
                case "SCOPE_COMPANY":
                case 2:
                    m.scope = 2;
                    break;
                }
                if (d.scopeSid != null) {
                    m.scopeSid = String(d.scopeSid);
                }
                if (d.attachment != null) {
                    m.attachment = Boolean(d.attachment);
                }
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSignedDownloadUrlRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {streem.api.CreateSignedDownloadUrlRequest} m CreateSignedDownloadUrlRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSignedDownloadUrlRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storageUrl = "";
                    d.scope = o.enums === String ? "SCOPE_INVALID" : 0;
                    d.scopeSid = "";
                    d.attachment = false;
                    d.filename = "";
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = o.enums === String ? $root.streem.api.ArtifactFile.Scope[m.scope] === undefined ? m.scope : $root.streem.api.ArtifactFile.Scope[m.scope] : m.scope;
                }
                if (m.scopeSid != null && m.hasOwnProperty("scopeSid")) {
                    d.scopeSid = m.scopeSid;
                }
                if (m.attachment != null && m.hasOwnProperty("attachment")) {
                    d.attachment = m.attachment;
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                return d;
            };

            /**
             * Converts this CreateSignedDownloadUrlRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSignedDownloadUrlRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSignedDownloadUrlRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateSignedDownloadUrlRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSignedDownloadUrlRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSignedDownloadUrlRequest";
            };

            return CreateSignedDownloadUrlRequest;
        })();

        api.CreateSignedDownloadUrlResponse = (function() {

            /**
             * Properties of a CreateSignedDownloadUrlResponse.
             * @memberof streem.api
             * @interface ICreateSignedDownloadUrlResponse
             * @property {string|null} [downloadUrl] CreateSignedDownloadUrlResponse downloadUrl
             */

            /**
             * Constructs a new CreateSignedDownloadUrlResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateSignedDownloadUrlResponse.
             * @implements ICreateSignedDownloadUrlResponse
             * @constructor
             * @param {streem.api.ICreateSignedDownloadUrlResponse=} [p] Properties to set
             */
            function CreateSignedDownloadUrlResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSignedDownloadUrlResponse downloadUrl.
             * @member {string} downloadUrl
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @instance
             */
            CreateSignedDownloadUrlResponse.prototype.downloadUrl = "";

            /**
             * Creates a new CreateSignedDownloadUrlResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {streem.api.ICreateSignedDownloadUrlResponse=} [properties] Properties to set
             * @returns {streem.api.CreateSignedDownloadUrlResponse} CreateSignedDownloadUrlResponse instance
             */
            CreateSignedDownloadUrlResponse.create = function create(properties) {
                return new CreateSignedDownloadUrlResponse(properties);
            };

            /**
             * Encodes the specified CreateSignedDownloadUrlResponse message. Does not implicitly {@link streem.api.CreateSignedDownloadUrlResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {streem.api.ICreateSignedDownloadUrlResponse} m CreateSignedDownloadUrlResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSignedDownloadUrlResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                    w.uint32(10).string(m.downloadUrl);
                return w;
            };

            /**
             * Decodes a CreateSignedDownloadUrlResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSignedDownloadUrlResponse} CreateSignedDownloadUrlResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSignedDownloadUrlResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSignedDownloadUrlResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.downloadUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSignedDownloadUrlResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSignedDownloadUrlResponse} CreateSignedDownloadUrlResponse
             */
            CreateSignedDownloadUrlResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSignedDownloadUrlResponse)
                    return d;
                var m = new $root.streem.api.CreateSignedDownloadUrlResponse();
                if (d.downloadUrl != null) {
                    m.downloadUrl = String(d.downloadUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSignedDownloadUrlResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {streem.api.CreateSignedDownloadUrlResponse} m CreateSignedDownloadUrlResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSignedDownloadUrlResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.downloadUrl = "";
                }
                if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                    d.downloadUrl = m.downloadUrl;
                }
                return d;
            };

            /**
             * Converts this CreateSignedDownloadUrlResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSignedDownloadUrlResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSignedDownloadUrlResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateSignedDownloadUrlResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSignedDownloadUrlResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSignedDownloadUrlResponse";
            };

            return CreateSignedDownloadUrlResponse;
        })();

        api.Devices = (function() {

            /**
             * Constructs a new Devices service.
             * @memberof streem.api
             * @classdesc Represents a Devices
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Devices(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Devices.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Devices;

            /**
             * Creates new Devices service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Devices
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Devices} RPC service. Useful where requests and/or responses are streamed.
             */
            Devices.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Devices#updateDevice}.
             * @memberof streem.api.Devices
             * @typedef UpdateDeviceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateDeviceResponse} [response] UpdateDeviceResponse
             */

            /**
             * Calls UpdateDevice.
             * @function updateDevice
             * @memberof streem.api.Devices
             * @instance
             * @param {streem.api.IUpdateDeviceRequest} request UpdateDeviceRequest message or plain object
             * @param {streem.api.Devices.UpdateDeviceCallback} callback Node-style callback called with the error, if any, and UpdateDeviceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Devices.prototype.updateDevice = function updateDevice(request, callback) {
                return this.rpcCall(updateDevice, $root.streem.api.UpdateDeviceRequest, $root.streem.api.UpdateDeviceResponse, request, callback);
            }, "name", { value: "UpdateDevice" });

            /**
             * Calls UpdateDevice.
             * @function updateDevice
             * @memberof streem.api.Devices
             * @instance
             * @param {streem.api.IUpdateDeviceRequest} request UpdateDeviceRequest message or plain object
             * @returns {Promise<streem.api.UpdateDeviceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Devices#deleteDevice}.
             * @memberof streem.api.Devices
             * @typedef DeleteDeviceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteDevice.
             * @function deleteDevice
             * @memberof streem.api.Devices
             * @instance
             * @param {streem.api.IDeleteDeviceRequest} request DeleteDeviceRequest message or plain object
             * @param {streem.api.Devices.DeleteDeviceCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Devices.prototype.deleteDevice = function deleteDevice(request, callback) {
                return this.rpcCall(deleteDevice, $root.streem.api.DeleteDeviceRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteDevice" });

            /**
             * Calls DeleteDevice.
             * @function deleteDevice
             * @memberof streem.api.Devices
             * @instance
             * @param {streem.api.IDeleteDeviceRequest} request DeleteDeviceRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Devices;
        })();

        api.UpdateDeviceRequest = (function() {

            /**
             * Properties of an UpdateDeviceRequest.
             * @memberof streem.api
             * @interface IUpdateDeviceRequest
             * @property {streem.api.IDevice|null} [device] UpdateDeviceRequest device
             */

            /**
             * Constructs a new UpdateDeviceRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateDeviceRequest.
             * @implements IUpdateDeviceRequest
             * @constructor
             * @param {streem.api.IUpdateDeviceRequest=} [p] Properties to set
             */
            function UpdateDeviceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateDeviceRequest device.
             * @member {streem.api.IDevice|null|undefined} device
             * @memberof streem.api.UpdateDeviceRequest
             * @instance
             */
            UpdateDeviceRequest.prototype.device = null;

            /**
             * Creates a new UpdateDeviceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {streem.api.IUpdateDeviceRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateDeviceRequest} UpdateDeviceRequest instance
             */
            UpdateDeviceRequest.create = function create(properties) {
                return new UpdateDeviceRequest(properties);
            };

            /**
             * Encodes the specified UpdateDeviceRequest message. Does not implicitly {@link streem.api.UpdateDeviceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {streem.api.IUpdateDeviceRequest} m UpdateDeviceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDeviceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                    $root.streem.api.Device.encode(m.device, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateDeviceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateDeviceRequest} UpdateDeviceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDeviceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateDeviceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.device = $root.streem.api.Device.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateDeviceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateDeviceRequest} UpdateDeviceRequest
             */
            UpdateDeviceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateDeviceRequest)
                    return d;
                var m = new $root.streem.api.UpdateDeviceRequest();
                if (d.device != null) {
                    if (typeof d.device !== "object")
                        throw TypeError(".streem.api.UpdateDeviceRequest.device: object expected");
                    m.device = $root.streem.api.Device.fromObject(d.device);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateDeviceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {streem.api.UpdateDeviceRequest} m UpdateDeviceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDeviceRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.device = null;
                }
                if (m.device != null && m.hasOwnProperty("device")) {
                    d.device = $root.streem.api.Device.toObject(m.device, o);
                }
                return d;
            };

            /**
             * Converts this UpdateDeviceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateDeviceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDeviceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateDeviceRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateDeviceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateDeviceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateDeviceRequest";
            };

            return UpdateDeviceRequest;
        })();

        api.UpdateDeviceResponse = (function() {

            /**
             * Properties of an UpdateDeviceResponse.
             * @memberof streem.api
             * @interface IUpdateDeviceResponse
             * @property {streem.api.IDevice|null} [device] UpdateDeviceResponse device
             */

            /**
             * Constructs a new UpdateDeviceResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateDeviceResponse.
             * @implements IUpdateDeviceResponse
             * @constructor
             * @param {streem.api.IUpdateDeviceResponse=} [p] Properties to set
             */
            function UpdateDeviceResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateDeviceResponse device.
             * @member {streem.api.IDevice|null|undefined} device
             * @memberof streem.api.UpdateDeviceResponse
             * @instance
             */
            UpdateDeviceResponse.prototype.device = null;

            /**
             * Creates a new UpdateDeviceResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {streem.api.IUpdateDeviceResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateDeviceResponse} UpdateDeviceResponse instance
             */
            UpdateDeviceResponse.create = function create(properties) {
                return new UpdateDeviceResponse(properties);
            };

            /**
             * Encodes the specified UpdateDeviceResponse message. Does not implicitly {@link streem.api.UpdateDeviceResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {streem.api.IUpdateDeviceResponse} m UpdateDeviceResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDeviceResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                    $root.streem.api.Device.encode(m.device, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateDeviceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateDeviceResponse} UpdateDeviceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDeviceResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateDeviceResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.device = $root.streem.api.Device.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateDeviceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateDeviceResponse} UpdateDeviceResponse
             */
            UpdateDeviceResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateDeviceResponse)
                    return d;
                var m = new $root.streem.api.UpdateDeviceResponse();
                if (d.device != null) {
                    if (typeof d.device !== "object")
                        throw TypeError(".streem.api.UpdateDeviceResponse.device: object expected");
                    m.device = $root.streem.api.Device.fromObject(d.device);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateDeviceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {streem.api.UpdateDeviceResponse} m UpdateDeviceResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDeviceResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.device = null;
                }
                if (m.device != null && m.hasOwnProperty("device")) {
                    d.device = $root.streem.api.Device.toObject(m.device, o);
                }
                return d;
            };

            /**
             * Converts this UpdateDeviceResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateDeviceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDeviceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateDeviceResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateDeviceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateDeviceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateDeviceResponse";
            };

            return UpdateDeviceResponse;
        })();

        api.DeleteDeviceRequest = (function() {

            /**
             * Properties of a DeleteDeviceRequest.
             * @memberof streem.api
             * @interface IDeleteDeviceRequest
             * @property {string|null} [deviceSid] DeleteDeviceRequest deviceSid
             */

            /**
             * Constructs a new DeleteDeviceRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteDeviceRequest.
             * @implements IDeleteDeviceRequest
             * @constructor
             * @param {streem.api.IDeleteDeviceRequest=} [p] Properties to set
             */
            function DeleteDeviceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteDeviceRequest deviceSid.
             * @member {string} deviceSid
             * @memberof streem.api.DeleteDeviceRequest
             * @instance
             */
            DeleteDeviceRequest.prototype.deviceSid = "";

            /**
             * Creates a new DeleteDeviceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {streem.api.IDeleteDeviceRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteDeviceRequest} DeleteDeviceRequest instance
             */
            DeleteDeviceRequest.create = function create(properties) {
                return new DeleteDeviceRequest(properties);
            };

            /**
             * Encodes the specified DeleteDeviceRequest message. Does not implicitly {@link streem.api.DeleteDeviceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {streem.api.IDeleteDeviceRequest} m DeleteDeviceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteDeviceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                    w.uint32(10).string(m.deviceSid);
                return w;
            };

            /**
             * Decodes a DeleteDeviceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteDeviceRequest} DeleteDeviceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteDeviceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteDeviceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.deviceSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteDeviceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteDeviceRequest} DeleteDeviceRequest
             */
            DeleteDeviceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteDeviceRequest)
                    return d;
                var m = new $root.streem.api.DeleteDeviceRequest();
                if (d.deviceSid != null) {
                    m.deviceSid = String(d.deviceSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteDeviceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {streem.api.DeleteDeviceRequest} m DeleteDeviceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteDeviceRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.deviceSid = "";
                }
                if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                    d.deviceSid = m.deviceSid;
                }
                return d;
            };

            /**
             * Converts this DeleteDeviceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteDeviceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteDeviceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteDeviceRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteDeviceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteDeviceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteDeviceRequest";
            };

            return DeleteDeviceRequest;
        })();

        /**
         * UploadType enum.
         * @name streem.api.UploadType
         * @enum {number}
         * @property {number} UPLOAD_TYPE_UNKNOWN=0 UPLOAD_TYPE_UNKNOWN value
         * @property {number} UPLOAD_TYPE_STREEMSHOT=1 UPLOAD_TYPE_STREEMSHOT value
         * @property {number} UPLOAD_TYPE_RECORDING=2 UPLOAD_TYPE_RECORDING value
         * @property {number} UPLOAD_TYPE_RECORDING_TRACK=3 UPLOAD_TYPE_RECORDING_TRACK value
         * @property {number} UPLOAD_TYPE_GPS_POSITION=4 UPLOAD_TYPE_GPS_POSITION value
         * @property {number} UPLOAD_TYPE_MESH=5 UPLOAD_TYPE_MESH value
         * @property {number} UPLOAD_TYPE_SCENESHOT=6 UPLOAD_TYPE_SCENESHOT value
         * @property {number} UPLOAD_TYPE_TRANSCRIPTION=7 UPLOAD_TYPE_TRANSCRIPTION value
         * @property {number} UPLOAD_TYPE_LAYOUT_ESTIMATION=8 UPLOAD_TYPE_LAYOUT_ESTIMATION value
         * @property {number} UPLOAD_TYPE_TEXTURED_MESH=9 UPLOAD_TYPE_TEXTURED_MESH value
         * @property {number} UPLOAD_TYPE_ROOM_NOTE=10 UPLOAD_TYPE_ROOM_NOTE value
         * @property {number} UPLOAD_TYPE_STREEMSHOT_NOTE=11 UPLOAD_TYPE_STREEMSHOT_NOTE value
         */
        api.UploadType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UPLOAD_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "UPLOAD_TYPE_STREEMSHOT"] = 1;
            values[valuesById[2] = "UPLOAD_TYPE_RECORDING"] = 2;
            values[valuesById[3] = "UPLOAD_TYPE_RECORDING_TRACK"] = 3;
            values[valuesById[4] = "UPLOAD_TYPE_GPS_POSITION"] = 4;
            values[valuesById[5] = "UPLOAD_TYPE_MESH"] = 5;
            values[valuesById[6] = "UPLOAD_TYPE_SCENESHOT"] = 6;
            values[valuesById[7] = "UPLOAD_TYPE_TRANSCRIPTION"] = 7;
            values[valuesById[8] = "UPLOAD_TYPE_LAYOUT_ESTIMATION"] = 8;
            values[valuesById[9] = "UPLOAD_TYPE_TEXTURED_MESH"] = 9;
            values[valuesById[10] = "UPLOAD_TYPE_ROOM_NOTE"] = 10;
            values[valuesById[11] = "UPLOAD_TYPE_STREEMSHOT_NOTE"] = 11;
            return values;
        })();

        /**
         * AppPlatform enum.
         * @name streem.api.AppPlatform
         * @enum {number}
         * @property {number} APP_PLATFORM_INVALID=0 APP_PLATFORM_INVALID value
         * @property {number} APP_PLATFORM_IOS=1 APP_PLATFORM_IOS value
         * @property {number} APP_PLATFORM_ANDROID=2 APP_PLATFORM_ANDROID value
         * @property {number} APP_PLATFORM_WEB=3 APP_PLATFORM_WEB value
         */
        api.AppPlatform = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "APP_PLATFORM_INVALID"] = 0;
            values[valuesById[1] = "APP_PLATFORM_IOS"] = 1;
            values[valuesById[2] = "APP_PLATFORM_ANDROID"] = 2;
            values[valuesById[3] = "APP_PLATFORM_WEB"] = 3;
            return values;
        })();

        api.App = (function() {

            /**
             * Properties of an App.
             * @memberof streem.api
             * @interface IApp
             * @property {string|null} [sid] App sid
             * @property {string|null} [companySid] App companySid
             * @property {streem.api.AppPlatform|null} [platform] App platform
             * @property {string|null} [platformAppId] App platformAppId
             * @property {boolean|null} [allowMultiCompanyLogin] App allowMultiCompanyLogin
             */

            /**
             * Constructs a new App.
             * @memberof streem.api
             * @classdesc Represents an App.
             * @implements IApp
             * @constructor
             * @param {streem.api.IApp=} [p] Properties to set
             */
            function App(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * App sid.
             * @member {string} sid
             * @memberof streem.api.App
             * @instance
             */
            App.prototype.sid = "";

            /**
             * App companySid.
             * @member {string} companySid
             * @memberof streem.api.App
             * @instance
             */
            App.prototype.companySid = "";

            /**
             * App platform.
             * @member {streem.api.AppPlatform} platform
             * @memberof streem.api.App
             * @instance
             */
            App.prototype.platform = 0;

            /**
             * App platformAppId.
             * @member {string} platformAppId
             * @memberof streem.api.App
             * @instance
             */
            App.prototype.platformAppId = "";

            /**
             * App allowMultiCompanyLogin.
             * @member {boolean} allowMultiCompanyLogin
             * @memberof streem.api.App
             * @instance
             */
            App.prototype.allowMultiCompanyLogin = false;

            /**
             * Creates a new App instance using the specified properties.
             * @function create
             * @memberof streem.api.App
             * @static
             * @param {streem.api.IApp=} [properties] Properties to set
             * @returns {streem.api.App} App instance
             */
            App.create = function create(properties) {
                return new App(properties);
            };

            /**
             * Encodes the specified App message. Does not implicitly {@link streem.api.App.verify|verify} messages.
             * @function encode
             * @memberof streem.api.App
             * @static
             * @param {streem.api.IApp} m App message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            App.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.platform != null && Object.hasOwnProperty.call(m, "platform"))
                    w.uint32(24).int32(m.platform);
                if (m.platformAppId != null && Object.hasOwnProperty.call(m, "platformAppId"))
                    w.uint32(34).string(m.platformAppId);
                if (m.allowMultiCompanyLogin != null && Object.hasOwnProperty.call(m, "allowMultiCompanyLogin"))
                    w.uint32(40).bool(m.allowMultiCompanyLogin);
                return w;
            };

            /**
             * Decodes an App message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.App
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.App} App
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            App.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.App();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 3: {
                            m.platform = r.int32();
                            break;
                        }
                    case 4: {
                            m.platformAppId = r.string();
                            break;
                        }
                    case 5: {
                            m.allowMultiCompanyLogin = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an App message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.App
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.App} App
             */
            App.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.App)
                    return d;
                var m = new $root.streem.api.App();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                switch (d.platform) {
                default:
                    if (typeof d.platform === "number") {
                        m.platform = d.platform;
                        break;
                    }
                    break;
                case "APP_PLATFORM_INVALID":
                case 0:
                    m.platform = 0;
                    break;
                case "APP_PLATFORM_IOS":
                case 1:
                    m.platform = 1;
                    break;
                case "APP_PLATFORM_ANDROID":
                case 2:
                    m.platform = 2;
                    break;
                case "APP_PLATFORM_WEB":
                case 3:
                    m.platform = 3;
                    break;
                }
                if (d.platformAppId != null) {
                    m.platformAppId = String(d.platformAppId);
                }
                if (d.allowMultiCompanyLogin != null) {
                    m.allowMultiCompanyLogin = Boolean(d.allowMultiCompanyLogin);
                }
                return m;
            };

            /**
             * Creates a plain object from an App message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.App
             * @static
             * @param {streem.api.App} m App
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            App.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.companySid = "";
                    d.platform = o.enums === String ? "APP_PLATFORM_INVALID" : 0;
                    d.platformAppId = "";
                    d.allowMultiCompanyLogin = false;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.platform != null && m.hasOwnProperty("platform")) {
                    d.platform = o.enums === String ? $root.streem.api.AppPlatform[m.platform] === undefined ? m.platform : $root.streem.api.AppPlatform[m.platform] : m.platform;
                }
                if (m.platformAppId != null && m.hasOwnProperty("platformAppId")) {
                    d.platformAppId = m.platformAppId;
                }
                if (m.allowMultiCompanyLogin != null && m.hasOwnProperty("allowMultiCompanyLogin")) {
                    d.allowMultiCompanyLogin = m.allowMultiCompanyLogin;
                }
                return d;
            };

            /**
             * Converts this App to JSON.
             * @function toJSON
             * @memberof streem.api.App
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            App.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for App
             * @function getTypeUrl
             * @memberof streem.api.App
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            App.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.App";
            };

            return App;
        })();

        api.AuthIdentity = (function() {

            /**
             * Properties of an AuthIdentity.
             * @memberof streem.api
             * @interface IAuthIdentity
             * @property {streem.api.AuthIdentity.IUser|null} [user] AuthIdentity user
             * @property {streem.api.AuthIdentity.ICompanyApiKey|null} [companyApiKey] AuthIdentity companyApiKey
             * @property {streem.api.AuthIdentity.IRoomShareToken|null} [roomShareToken] AuthIdentity roomShareToken
             */

            /**
             * Constructs a new AuthIdentity.
             * @memberof streem.api
             * @classdesc Represents an AuthIdentity.
             * @implements IAuthIdentity
             * @constructor
             * @param {streem.api.IAuthIdentity=} [p] Properties to set
             */
            function AuthIdentity(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AuthIdentity user.
             * @member {streem.api.AuthIdentity.IUser|null|undefined} user
             * @memberof streem.api.AuthIdentity
             * @instance
             */
            AuthIdentity.prototype.user = null;

            /**
             * AuthIdentity companyApiKey.
             * @member {streem.api.AuthIdentity.ICompanyApiKey|null|undefined} companyApiKey
             * @memberof streem.api.AuthIdentity
             * @instance
             */
            AuthIdentity.prototype.companyApiKey = null;

            /**
             * AuthIdentity roomShareToken.
             * @member {streem.api.AuthIdentity.IRoomShareToken|null|undefined} roomShareToken
             * @memberof streem.api.AuthIdentity
             * @instance
             */
            AuthIdentity.prototype.roomShareToken = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * AuthIdentity identity.
             * @member {"user"|"companyApiKey"|"roomShareToken"|undefined} identity
             * @memberof streem.api.AuthIdentity
             * @instance
             */
            Object.defineProperty(AuthIdentity.prototype, "identity", {
                get: $util.oneOfGetter($oneOfFields = ["user", "companyApiKey", "roomShareToken"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AuthIdentity instance using the specified properties.
             * @function create
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {streem.api.IAuthIdentity=} [properties] Properties to set
             * @returns {streem.api.AuthIdentity} AuthIdentity instance
             */
            AuthIdentity.create = function create(properties) {
                return new AuthIdentity(properties);
            };

            /**
             * Encodes the specified AuthIdentity message. Does not implicitly {@link streem.api.AuthIdentity.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {streem.api.IAuthIdentity} m AuthIdentity message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthIdentity.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.AuthIdentity.User.encode(m.user, w.uint32(10).fork()).ldelim();
                if (m.companyApiKey != null && Object.hasOwnProperty.call(m, "companyApiKey"))
                    $root.streem.api.AuthIdentity.CompanyApiKey.encode(m.companyApiKey, w.uint32(18).fork()).ldelim();
                if (m.roomShareToken != null && Object.hasOwnProperty.call(m, "roomShareToken"))
                    $root.streem.api.AuthIdentity.RoomShareToken.encode(m.roomShareToken, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AuthIdentity message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AuthIdentity} AuthIdentity
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthIdentity.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AuthIdentity();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.AuthIdentity.User.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.companyApiKey = $root.streem.api.AuthIdentity.CompanyApiKey.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.roomShareToken = $root.streem.api.AuthIdentity.RoomShareToken.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AuthIdentity message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AuthIdentity} AuthIdentity
             */
            AuthIdentity.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AuthIdentity)
                    return d;
                var m = new $root.streem.api.AuthIdentity();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.AuthIdentity.user: object expected");
                    m.user = $root.streem.api.AuthIdentity.User.fromObject(d.user);
                }
                if (d.companyApiKey != null) {
                    if (typeof d.companyApiKey !== "object")
                        throw TypeError(".streem.api.AuthIdentity.companyApiKey: object expected");
                    m.companyApiKey = $root.streem.api.AuthIdentity.CompanyApiKey.fromObject(d.companyApiKey);
                }
                if (d.roomShareToken != null) {
                    if (typeof d.roomShareToken !== "object")
                        throw TypeError(".streem.api.AuthIdentity.roomShareToken: object expected");
                    m.roomShareToken = $root.streem.api.AuthIdentity.RoomShareToken.fromObject(d.roomShareToken);
                }
                return m;
            };

            /**
             * Creates a plain object from an AuthIdentity message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {streem.api.AuthIdentity} m AuthIdentity
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthIdentity.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.AuthIdentity.User.toObject(m.user, o);
                    if (o.oneofs)
                        d.identity = "user";
                }
                if (m.companyApiKey != null && m.hasOwnProperty("companyApiKey")) {
                    d.companyApiKey = $root.streem.api.AuthIdentity.CompanyApiKey.toObject(m.companyApiKey, o);
                    if (o.oneofs)
                        d.identity = "companyApiKey";
                }
                if (m.roomShareToken != null && m.hasOwnProperty("roomShareToken")) {
                    d.roomShareToken = $root.streem.api.AuthIdentity.RoomShareToken.toObject(m.roomShareToken, o);
                    if (o.oneofs)
                        d.identity = "roomShareToken";
                }
                return d;
            };

            /**
             * Converts this AuthIdentity to JSON.
             * @function toJSON
             * @memberof streem.api.AuthIdentity
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthIdentity.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AuthIdentity
             * @function getTypeUrl
             * @memberof streem.api.AuthIdentity
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthIdentity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AuthIdentity";
            };

            AuthIdentity.User = (function() {

                /**
                 * Properties of a User.
                 * @memberof streem.api.AuthIdentity
                 * @interface IUser
                 * @property {streem.api.IUser|null} [user] User user
                 * @property {streem.api.IInvitation|null} [invitation] User invitation
                 * @property {streem.api.IGroupReservation|null} [groupReservation] User groupReservation
                 */

                /**
                 * Constructs a new User.
                 * @memberof streem.api.AuthIdentity
                 * @classdesc Represents a User.
                 * @implements IUser
                 * @constructor
                 * @param {streem.api.AuthIdentity.IUser=} [p] Properties to set
                 */
                function User(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * User user.
                 * @member {streem.api.IUser|null|undefined} user
                 * @memberof streem.api.AuthIdentity.User
                 * @instance
                 */
                User.prototype.user = null;

                /**
                 * User invitation.
                 * @member {streem.api.IInvitation|null|undefined} invitation
                 * @memberof streem.api.AuthIdentity.User
                 * @instance
                 */
                User.prototype.invitation = null;

                /**
                 * User groupReservation.
                 * @member {streem.api.IGroupReservation|null|undefined} groupReservation
                 * @memberof streem.api.AuthIdentity.User
                 * @instance
                 */
                User.prototype.groupReservation = null;

                /**
                 * Creates a new User instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {streem.api.AuthIdentity.IUser=} [properties] Properties to set
                 * @returns {streem.api.AuthIdentity.User} User instance
                 */
                User.create = function create(properties) {
                    return new User(properties);
                };

                /**
                 * Encodes the specified User message. Does not implicitly {@link streem.api.AuthIdentity.User.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {streem.api.AuthIdentity.IUser} m User message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                User.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                        $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                    if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
                        $root.streem.api.Invitation.encode(m.invitation, w.uint32(18).fork()).ldelim();
                    if (m.groupReservation != null && Object.hasOwnProperty.call(m, "groupReservation"))
                        $root.streem.api.GroupReservation.encode(m.groupReservation, w.uint32(26).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a User message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AuthIdentity.User} User
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                User.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AuthIdentity.User();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.user = $root.streem.api.User.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.invitation = $root.streem.api.Invitation.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.groupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a User message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AuthIdentity.User} User
                 */
                User.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AuthIdentity.User)
                        return d;
                    var m = new $root.streem.api.AuthIdentity.User();
                    if (d.user != null) {
                        if (typeof d.user !== "object")
                            throw TypeError(".streem.api.AuthIdentity.User.user: object expected");
                        m.user = $root.streem.api.User.fromObject(d.user);
                    }
                    if (d.invitation != null) {
                        if (typeof d.invitation !== "object")
                            throw TypeError(".streem.api.AuthIdentity.User.invitation: object expected");
                        m.invitation = $root.streem.api.Invitation.fromObject(d.invitation);
                    }
                    if (d.groupReservation != null) {
                        if (typeof d.groupReservation !== "object")
                            throw TypeError(".streem.api.AuthIdentity.User.groupReservation: object expected");
                        m.groupReservation = $root.streem.api.GroupReservation.fromObject(d.groupReservation);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a User message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {streem.api.AuthIdentity.User} m User
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                User.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.user = null;
                        d.invitation = null;
                        d.groupReservation = null;
                    }
                    if (m.user != null && m.hasOwnProperty("user")) {
                        d.user = $root.streem.api.User.toObject(m.user, o);
                    }
                    if (m.invitation != null && m.hasOwnProperty("invitation")) {
                        d.invitation = $root.streem.api.Invitation.toObject(m.invitation, o);
                    }
                    if (m.groupReservation != null && m.hasOwnProperty("groupReservation")) {
                        d.groupReservation = $root.streem.api.GroupReservation.toObject(m.groupReservation, o);
                    }
                    return d;
                };

                /**
                 * Converts this User to JSON.
                 * @function toJSON
                 * @memberof streem.api.AuthIdentity.User
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                User.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for User
                 * @function getTypeUrl
                 * @memberof streem.api.AuthIdentity.User
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AuthIdentity.User";
                };

                return User;
            })();

            AuthIdentity.CompanyApiKey = (function() {

                /**
                 * Properties of a CompanyApiKey.
                 * @memberof streem.api.AuthIdentity
                 * @interface ICompanyApiKey
                 * @property {streem.api.ICompanyApiKey|null} [companyApiKey] CompanyApiKey companyApiKey
                 */

                /**
                 * Constructs a new CompanyApiKey.
                 * @memberof streem.api.AuthIdentity
                 * @classdesc Represents a CompanyApiKey.
                 * @implements ICompanyApiKey
                 * @constructor
                 * @param {streem.api.AuthIdentity.ICompanyApiKey=} [p] Properties to set
                 */
                function CompanyApiKey(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CompanyApiKey companyApiKey.
                 * @member {streem.api.ICompanyApiKey|null|undefined} companyApiKey
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @instance
                 */
                CompanyApiKey.prototype.companyApiKey = null;

                /**
                 * Creates a new CompanyApiKey instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {streem.api.AuthIdentity.ICompanyApiKey=} [properties] Properties to set
                 * @returns {streem.api.AuthIdentity.CompanyApiKey} CompanyApiKey instance
                 */
                CompanyApiKey.create = function create(properties) {
                    return new CompanyApiKey(properties);
                };

                /**
                 * Encodes the specified CompanyApiKey message. Does not implicitly {@link streem.api.AuthIdentity.CompanyApiKey.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {streem.api.AuthIdentity.ICompanyApiKey} m CompanyApiKey message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompanyApiKey.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyApiKey != null && Object.hasOwnProperty.call(m, "companyApiKey"))
                        $root.streem.api.CompanyApiKey.encode(m.companyApiKey, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a CompanyApiKey message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AuthIdentity.CompanyApiKey} CompanyApiKey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompanyApiKey.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AuthIdentity.CompanyApiKey();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.companyApiKey = $root.streem.api.CompanyApiKey.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CompanyApiKey message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AuthIdentity.CompanyApiKey} CompanyApiKey
                 */
                CompanyApiKey.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AuthIdentity.CompanyApiKey)
                        return d;
                    var m = new $root.streem.api.AuthIdentity.CompanyApiKey();
                    if (d.companyApiKey != null) {
                        if (typeof d.companyApiKey !== "object")
                            throw TypeError(".streem.api.AuthIdentity.CompanyApiKey.companyApiKey: object expected");
                        m.companyApiKey = $root.streem.api.CompanyApiKey.fromObject(d.companyApiKey);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CompanyApiKey message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {streem.api.AuthIdentity.CompanyApiKey} m CompanyApiKey
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyApiKey.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyApiKey = null;
                    }
                    if (m.companyApiKey != null && m.hasOwnProperty("companyApiKey")) {
                        d.companyApiKey = $root.streem.api.CompanyApiKey.toObject(m.companyApiKey, o);
                    }
                    return d;
                };

                /**
                 * Converts this CompanyApiKey to JSON.
                 * @function toJSON
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyApiKey.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CompanyApiKey
                 * @function getTypeUrl
                 * @memberof streem.api.AuthIdentity.CompanyApiKey
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompanyApiKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AuthIdentity.CompanyApiKey";
                };

                return CompanyApiKey;
            })();

            AuthIdentity.RoomShareToken = (function() {

                /**
                 * Properties of a RoomShareToken.
                 * @memberof streem.api.AuthIdentity
                 * @interface IRoomShareToken
                 * @property {streem.api.IRoomShareToken|null} [roomShareToken] RoomShareToken roomShareToken
                 */

                /**
                 * Constructs a new RoomShareToken.
                 * @memberof streem.api.AuthIdentity
                 * @classdesc Represents a RoomShareToken.
                 * @implements IRoomShareToken
                 * @constructor
                 * @param {streem.api.AuthIdentity.IRoomShareToken=} [p] Properties to set
                 */
                function RoomShareToken(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomShareToken roomShareToken.
                 * @member {streem.api.IRoomShareToken|null|undefined} roomShareToken
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @instance
                 */
                RoomShareToken.prototype.roomShareToken = null;

                /**
                 * Creates a new RoomShareToken instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {streem.api.AuthIdentity.IRoomShareToken=} [properties] Properties to set
                 * @returns {streem.api.AuthIdentity.RoomShareToken} RoomShareToken instance
                 */
                RoomShareToken.create = function create(properties) {
                    return new RoomShareToken(properties);
                };

                /**
                 * Encodes the specified RoomShareToken message. Does not implicitly {@link streem.api.AuthIdentity.RoomShareToken.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {streem.api.AuthIdentity.IRoomShareToken} m RoomShareToken message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomShareToken.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomShareToken != null && Object.hasOwnProperty.call(m, "roomShareToken"))
                        $root.streem.api.RoomShareToken.encode(m.roomShareToken, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomShareToken message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AuthIdentity.RoomShareToken} RoomShareToken
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomShareToken.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AuthIdentity.RoomShareToken();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomShareToken = $root.streem.api.RoomShareToken.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomShareToken message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AuthIdentity.RoomShareToken} RoomShareToken
                 */
                RoomShareToken.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AuthIdentity.RoomShareToken)
                        return d;
                    var m = new $root.streem.api.AuthIdentity.RoomShareToken();
                    if (d.roomShareToken != null) {
                        if (typeof d.roomShareToken !== "object")
                            throw TypeError(".streem.api.AuthIdentity.RoomShareToken.roomShareToken: object expected");
                        m.roomShareToken = $root.streem.api.RoomShareToken.fromObject(d.roomShareToken);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomShareToken message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {streem.api.AuthIdentity.RoomShareToken} m RoomShareToken
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomShareToken.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomShareToken = null;
                    }
                    if (m.roomShareToken != null && m.hasOwnProperty("roomShareToken")) {
                        d.roomShareToken = $root.streem.api.RoomShareToken.toObject(m.roomShareToken, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomShareToken to JSON.
                 * @function toJSON
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomShareToken.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomShareToken
                 * @function getTypeUrl
                 * @memberof streem.api.AuthIdentity.RoomShareToken
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomShareToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AuthIdentity.RoomShareToken";
                };

                return RoomShareToken;
            })();

            return AuthIdentity;
        })();

        api.Device = (function() {

            /**
             * Properties of a Device.
             * @memberof streem.api
             * @interface IDevice
             * @property {string|null} [sid] Device sid
             * @property {string|null} [appSid] Device appSid
             * @property {number|null} [apiLevel] Device apiLevel
             * @property {string|null} [applicationName] Device applicationName
             * @property {string|null} [appVersion] Device appVersion
             * @property {string|null} [brand] Device brand
             * @property {string|null} [buildNumber] Device buildNumber
             * @property {string|null} [bundleId] Device bundleId
             * @property {string|null} [deviceCountry] Device deviceCountry
             * @property {string|null} [deviceLocale] Device deviceLocale
             * @property {string|null} [deviceName] Device deviceName
             * @property {string|null} [manufacturer] Device manufacturer
             * @property {Long|null} [maxMemory] Device maxMemory
             * @property {string|null} [model] Device model
             * @property {string|null} [modelCode] Device modelCode
             * @property {string|null} [platform] Device platform
             * @property {string|null} [systemName] Device systemName
             * @property {string|null} [systemVersion] Device systemVersion
             * @property {string|null} [timezone] Device timezone
             * @property {Long|null} [totalMemory] Device totalMemory
             * @property {string|null} [userAgent] Device userAgent
             * @property {string|null} [vendorId] Device vendorId
             * @property {Array.<streem.api.VideoCodec>|null} [supportedVideoCodecs] Device supportedVideoCodecs
             * @property {Array.<streem.api.VideoPlatform>|null} [supportedVideoPlatforms] Device supportedVideoPlatforms
             * @property {Array.<streem.api.WallPlatform>|null} [supportedWallPlatforms] Device supportedWallPlatforms
             */

            /**
             * Constructs a new Device.
             * @memberof streem.api
             * @classdesc Represents a Device.
             * @implements IDevice
             * @constructor
             * @param {streem.api.IDevice=} [p] Properties to set
             */
            function Device(p) {
                this.supportedVideoCodecs = [];
                this.supportedVideoPlatforms = [];
                this.supportedWallPlatforms = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Device sid.
             * @member {string} sid
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.sid = "";

            /**
             * Device appSid.
             * @member {string} appSid
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.appSid = "";

            /**
             * Device apiLevel.
             * @member {number} apiLevel
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.apiLevel = 0;

            /**
             * Device applicationName.
             * @member {string} applicationName
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.applicationName = "";

            /**
             * Device appVersion.
             * @member {string} appVersion
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.appVersion = "";

            /**
             * Device brand.
             * @member {string} brand
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.brand = "";

            /**
             * Device buildNumber.
             * @member {string} buildNumber
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.buildNumber = "";

            /**
             * Device bundleId.
             * @member {string} bundleId
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.bundleId = "";

            /**
             * Device deviceCountry.
             * @member {string} deviceCountry
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.deviceCountry = "";

            /**
             * Device deviceLocale.
             * @member {string} deviceLocale
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.deviceLocale = "";

            /**
             * Device deviceName.
             * @member {string} deviceName
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.deviceName = "";

            /**
             * Device manufacturer.
             * @member {string} manufacturer
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.manufacturer = "";

            /**
             * Device maxMemory.
             * @member {Long} maxMemory
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.maxMemory = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Device model.
             * @member {string} model
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.model = "";

            /**
             * Device modelCode.
             * @member {string} modelCode
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.modelCode = "";

            /**
             * Device platform.
             * @member {string} platform
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.platform = "";

            /**
             * Device systemName.
             * @member {string} systemName
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.systemName = "";

            /**
             * Device systemVersion.
             * @member {string} systemVersion
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.systemVersion = "";

            /**
             * Device timezone.
             * @member {string} timezone
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.timezone = "";

            /**
             * Device totalMemory.
             * @member {Long} totalMemory
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.totalMemory = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Device userAgent.
             * @member {string} userAgent
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.userAgent = "";

            /**
             * Device vendorId.
             * @member {string} vendorId
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.vendorId = "";

            /**
             * Device supportedVideoCodecs.
             * @member {Array.<streem.api.VideoCodec>} supportedVideoCodecs
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.supportedVideoCodecs = $util.emptyArray;

            /**
             * Device supportedVideoPlatforms.
             * @member {Array.<streem.api.VideoPlatform>} supportedVideoPlatforms
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.supportedVideoPlatforms = $util.emptyArray;

            /**
             * Device supportedWallPlatforms.
             * @member {Array.<streem.api.WallPlatform>} supportedWallPlatforms
             * @memberof streem.api.Device
             * @instance
             */
            Device.prototype.supportedWallPlatforms = $util.emptyArray;

            /**
             * Creates a new Device instance using the specified properties.
             * @function create
             * @memberof streem.api.Device
             * @static
             * @param {streem.api.IDevice=} [properties] Properties to set
             * @returns {streem.api.Device} Device instance
             */
            Device.create = function create(properties) {
                return new Device(properties);
            };

            /**
             * Encodes the specified Device message. Does not implicitly {@link streem.api.Device.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Device
             * @static
             * @param {streem.api.IDevice} m Device message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Device.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                    w.uint32(18).string(m.appSid);
                if (m.apiLevel != null && Object.hasOwnProperty.call(m, "apiLevel"))
                    w.uint32(24).int32(m.apiLevel);
                if (m.applicationName != null && Object.hasOwnProperty.call(m, "applicationName"))
                    w.uint32(42).string(m.applicationName);
                if (m.appVersion != null && Object.hasOwnProperty.call(m, "appVersion"))
                    w.uint32(58).string(m.appVersion);
                if (m.brand != null && Object.hasOwnProperty.call(m, "brand"))
                    w.uint32(74).string(m.brand);
                if (m.buildNumber != null && Object.hasOwnProperty.call(m, "buildNumber"))
                    w.uint32(82).string(m.buildNumber);
                if (m.bundleId != null && Object.hasOwnProperty.call(m, "bundleId"))
                    w.uint32(90).string(m.bundleId);
                if (m.deviceCountry != null && Object.hasOwnProperty.call(m, "deviceCountry"))
                    w.uint32(106).string(m.deviceCountry);
                if (m.deviceLocale != null && Object.hasOwnProperty.call(m, "deviceLocale"))
                    w.uint32(114).string(m.deviceLocale);
                if (m.deviceName != null && Object.hasOwnProperty.call(m, "deviceName"))
                    w.uint32(122).string(m.deviceName);
                if (m.manufacturer != null && Object.hasOwnProperty.call(m, "manufacturer"))
                    w.uint32(210).string(m.manufacturer);
                if (m.maxMemory != null && Object.hasOwnProperty.call(m, "maxMemory"))
                    w.uint32(216).int64(m.maxMemory);
                if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                    w.uint32(226).string(m.model);
                if (m.modelCode != null && Object.hasOwnProperty.call(m, "modelCode"))
                    w.uint32(234).string(m.modelCode);
                if (m.platform != null && Object.hasOwnProperty.call(m, "platform"))
                    w.uint32(250).string(m.platform);
                if (m.systemName != null && Object.hasOwnProperty.call(m, "systemName"))
                    w.uint32(266).string(m.systemName);
                if (m.systemVersion != null && Object.hasOwnProperty.call(m, "systemVersion"))
                    w.uint32(274).string(m.systemVersion);
                if (m.timezone != null && Object.hasOwnProperty.call(m, "timezone"))
                    w.uint32(282).string(m.timezone);
                if (m.totalMemory != null && Object.hasOwnProperty.call(m, "totalMemory"))
                    w.uint32(296).int64(m.totalMemory);
                if (m.userAgent != null && Object.hasOwnProperty.call(m, "userAgent"))
                    w.uint32(306).string(m.userAgent);
                if (m.vendorId != null && Object.hasOwnProperty.call(m, "vendorId"))
                    w.uint32(314).string(m.vendorId);
                if (m.supportedVideoCodecs != null && m.supportedVideoCodecs.length) {
                    w.uint32(322).fork();
                    for (var i = 0; i < m.supportedVideoCodecs.length; ++i)
                        w.int32(m.supportedVideoCodecs[i]);
                    w.ldelim();
                }
                if (m.supportedVideoPlatforms != null && m.supportedVideoPlatforms.length) {
                    w.uint32(330).fork();
                    for (var i = 0; i < m.supportedVideoPlatforms.length; ++i)
                        w.int32(m.supportedVideoPlatforms[i]);
                    w.ldelim();
                }
                if (m.supportedWallPlatforms != null && m.supportedWallPlatforms.length) {
                    w.uint32(338).fork();
                    for (var i = 0; i < m.supportedWallPlatforms.length; ++i)
                        w.int32(m.supportedWallPlatforms[i]);
                    w.ldelim();
                }
                return w;
            };

            /**
             * Decodes a Device message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Device
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Device} Device
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Device.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Device();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.appSid = r.string();
                            break;
                        }
                    case 3: {
                            m.apiLevel = r.int32();
                            break;
                        }
                    case 5: {
                            m.applicationName = r.string();
                            break;
                        }
                    case 7: {
                            m.appVersion = r.string();
                            break;
                        }
                    case 9: {
                            m.brand = r.string();
                            break;
                        }
                    case 10: {
                            m.buildNumber = r.string();
                            break;
                        }
                    case 11: {
                            m.bundleId = r.string();
                            break;
                        }
                    case 13: {
                            m.deviceCountry = r.string();
                            break;
                        }
                    case 14: {
                            m.deviceLocale = r.string();
                            break;
                        }
                    case 15: {
                            m.deviceName = r.string();
                            break;
                        }
                    case 26: {
                            m.manufacturer = r.string();
                            break;
                        }
                    case 27: {
                            m.maxMemory = r.int64();
                            break;
                        }
                    case 28: {
                            m.model = r.string();
                            break;
                        }
                    case 29: {
                            m.modelCode = r.string();
                            break;
                        }
                    case 31: {
                            m.platform = r.string();
                            break;
                        }
                    case 33: {
                            m.systemName = r.string();
                            break;
                        }
                    case 34: {
                            m.systemVersion = r.string();
                            break;
                        }
                    case 35: {
                            m.timezone = r.string();
                            break;
                        }
                    case 37: {
                            m.totalMemory = r.int64();
                            break;
                        }
                    case 38: {
                            m.userAgent = r.string();
                            break;
                        }
                    case 39: {
                            m.vendorId = r.string();
                            break;
                        }
                    case 40: {
                            if (!(m.supportedVideoCodecs && m.supportedVideoCodecs.length))
                                m.supportedVideoCodecs = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.supportedVideoCodecs.push(r.int32());
                            } else
                                m.supportedVideoCodecs.push(r.int32());
                            break;
                        }
                    case 41: {
                            if (!(m.supportedVideoPlatforms && m.supportedVideoPlatforms.length))
                                m.supportedVideoPlatforms = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.supportedVideoPlatforms.push(r.int32());
                            } else
                                m.supportedVideoPlatforms.push(r.int32());
                            break;
                        }
                    case 42: {
                            if (!(m.supportedWallPlatforms && m.supportedWallPlatforms.length))
                                m.supportedWallPlatforms = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.supportedWallPlatforms.push(r.int32());
                            } else
                                m.supportedWallPlatforms.push(r.int32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Device message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Device
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Device} Device
             */
            Device.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Device)
                    return d;
                var m = new $root.streem.api.Device();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.appSid != null) {
                    m.appSid = String(d.appSid);
                }
                if (d.apiLevel != null) {
                    m.apiLevel = d.apiLevel | 0;
                }
                if (d.applicationName != null) {
                    m.applicationName = String(d.applicationName);
                }
                if (d.appVersion != null) {
                    m.appVersion = String(d.appVersion);
                }
                if (d.brand != null) {
                    m.brand = String(d.brand);
                }
                if (d.buildNumber != null) {
                    m.buildNumber = String(d.buildNumber);
                }
                if (d.bundleId != null) {
                    m.bundleId = String(d.bundleId);
                }
                if (d.deviceCountry != null) {
                    m.deviceCountry = String(d.deviceCountry);
                }
                if (d.deviceLocale != null) {
                    m.deviceLocale = String(d.deviceLocale);
                }
                if (d.deviceName != null) {
                    m.deviceName = String(d.deviceName);
                }
                if (d.manufacturer != null) {
                    m.manufacturer = String(d.manufacturer);
                }
                if (d.maxMemory != null) {
                    if ($util.Long)
                        (m.maxMemory = $util.Long.fromValue(d.maxMemory)).unsigned = false;
                    else if (typeof d.maxMemory === "string")
                        m.maxMemory = parseInt(d.maxMemory, 10);
                    else if (typeof d.maxMemory === "number")
                        m.maxMemory = d.maxMemory;
                    else if (typeof d.maxMemory === "object")
                        m.maxMemory = new $util.LongBits(d.maxMemory.low >>> 0, d.maxMemory.high >>> 0).toNumber();
                }
                if (d.model != null) {
                    m.model = String(d.model);
                }
                if (d.modelCode != null) {
                    m.modelCode = String(d.modelCode);
                }
                if (d.platform != null) {
                    m.platform = String(d.platform);
                }
                if (d.systemName != null) {
                    m.systemName = String(d.systemName);
                }
                if (d.systemVersion != null) {
                    m.systemVersion = String(d.systemVersion);
                }
                if (d.timezone != null) {
                    m.timezone = String(d.timezone);
                }
                if (d.totalMemory != null) {
                    if ($util.Long)
                        (m.totalMemory = $util.Long.fromValue(d.totalMemory)).unsigned = false;
                    else if (typeof d.totalMemory === "string")
                        m.totalMemory = parseInt(d.totalMemory, 10);
                    else if (typeof d.totalMemory === "number")
                        m.totalMemory = d.totalMemory;
                    else if (typeof d.totalMemory === "object")
                        m.totalMemory = new $util.LongBits(d.totalMemory.low >>> 0, d.totalMemory.high >>> 0).toNumber();
                }
                if (d.userAgent != null) {
                    m.userAgent = String(d.userAgent);
                }
                if (d.vendorId != null) {
                    m.vendorId = String(d.vendorId);
                }
                if (d.supportedVideoCodecs) {
                    if (!Array.isArray(d.supportedVideoCodecs))
                        throw TypeError(".streem.api.Device.supportedVideoCodecs: array expected");
                    m.supportedVideoCodecs = [];
                    for (var i = 0; i < d.supportedVideoCodecs.length; ++i) {
                        switch (d.supportedVideoCodecs[i]) {
                        default:
                            if (typeof d.supportedVideoCodecs[i] === "number") {
                                m.supportedVideoCodecs[i] = d.supportedVideoCodecs[i];
                                break;
                            }
                        case "VIDEO_CODEC_INVALID":
                        case 0:
                            m.supportedVideoCodecs[i] = 0;
                            break;
                        case "VIDEO_CODEC_H264":
                        case 1:
                            m.supportedVideoCodecs[i] = 1;
                            break;
                        case "VIDEO_CODEC_VP8":
                        case 2:
                            m.supportedVideoCodecs[i] = 2;
                            break;
                        case "VIDEO_CODEC_VP9":
                        case 3:
                            m.supportedVideoCodecs[i] = 3;
                            break;
                        }
                    }
                }
                if (d.supportedVideoPlatforms) {
                    if (!Array.isArray(d.supportedVideoPlatforms))
                        throw TypeError(".streem.api.Device.supportedVideoPlatforms: array expected");
                    m.supportedVideoPlatforms = [];
                    for (var i = 0; i < d.supportedVideoPlatforms.length; ++i) {
                        switch (d.supportedVideoPlatforms[i]) {
                        default:
                            if (typeof d.supportedVideoPlatforms[i] === "number") {
                                m.supportedVideoPlatforms[i] = d.supportedVideoPlatforms[i];
                                break;
                            }
                        case "VIDEO_PLATFORM_INVALID":
                        case 0:
                            m.supportedVideoPlatforms[i] = 0;
                            break;
                        case "VIDEO_PLATFORM_TWILIO":
                        case 1:
                            m.supportedVideoPlatforms[i] = 1;
                            break;
                        case "VIDEO_PLATFORM_ARAAS":
                        case 2:
                            m.supportedVideoPlatforms[i] = 2;
                            break;
                        case "VIDEO_PLATFORM_CHIME":
                        case 3:
                            m.supportedVideoPlatforms[i] = 3;
                            break;
                        }
                    }
                }
                if (d.supportedWallPlatforms) {
                    if (!Array.isArray(d.supportedWallPlatforms))
                        throw TypeError(".streem.api.Device.supportedWallPlatforms: array expected");
                    m.supportedWallPlatforms = [];
                    for (var i = 0; i < d.supportedWallPlatforms.length; ++i) {
                        switch (d.supportedWallPlatforms[i]) {
                        default:
                            if (typeof d.supportedWallPlatforms[i] === "number") {
                                m.supportedWallPlatforms[i] = d.supportedWallPlatforms[i];
                                break;
                            }
                        case "WALL_PLATFORM_INVALID":
                        case 0:
                            m.supportedWallPlatforms[i] = 0;
                            break;
                        case "WALL_PLATFORM_TWILIO":
                        case 1:
                            m.supportedWallPlatforms[i] = 1;
                            break;
                        case "WALL_PLATFORM_STREEM":
                        case 2:
                            m.supportedWallPlatforms[i] = 2;
                            break;
                        }
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Device message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Device
             * @static
             * @param {streem.api.Device} m Device
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Device.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.supportedVideoCodecs = [];
                    d.supportedVideoPlatforms = [];
                    d.supportedWallPlatforms = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.appSid = "";
                    d.apiLevel = 0;
                    d.applicationName = "";
                    d.appVersion = "";
                    d.brand = "";
                    d.buildNumber = "";
                    d.bundleId = "";
                    d.deviceCountry = "";
                    d.deviceLocale = "";
                    d.deviceName = "";
                    d.manufacturer = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.maxMemory = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.maxMemory = o.longs === String ? "0" : 0;
                    d.model = "";
                    d.modelCode = "";
                    d.platform = "";
                    d.systemName = "";
                    d.systemVersion = "";
                    d.timezone = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalMemory = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalMemory = o.longs === String ? "0" : 0;
                    d.userAgent = "";
                    d.vendorId = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.appSid != null && m.hasOwnProperty("appSid")) {
                    d.appSid = m.appSid;
                }
                if (m.apiLevel != null && m.hasOwnProperty("apiLevel")) {
                    d.apiLevel = m.apiLevel;
                }
                if (m.applicationName != null && m.hasOwnProperty("applicationName")) {
                    d.applicationName = m.applicationName;
                }
                if (m.appVersion != null && m.hasOwnProperty("appVersion")) {
                    d.appVersion = m.appVersion;
                }
                if (m.brand != null && m.hasOwnProperty("brand")) {
                    d.brand = m.brand;
                }
                if (m.buildNumber != null && m.hasOwnProperty("buildNumber")) {
                    d.buildNumber = m.buildNumber;
                }
                if (m.bundleId != null && m.hasOwnProperty("bundleId")) {
                    d.bundleId = m.bundleId;
                }
                if (m.deviceCountry != null && m.hasOwnProperty("deviceCountry")) {
                    d.deviceCountry = m.deviceCountry;
                }
                if (m.deviceLocale != null && m.hasOwnProperty("deviceLocale")) {
                    d.deviceLocale = m.deviceLocale;
                }
                if (m.deviceName != null && m.hasOwnProperty("deviceName")) {
                    d.deviceName = m.deviceName;
                }
                if (m.manufacturer != null && m.hasOwnProperty("manufacturer")) {
                    d.manufacturer = m.manufacturer;
                }
                if (m.maxMemory != null && m.hasOwnProperty("maxMemory")) {
                    if (typeof m.maxMemory === "number")
                        d.maxMemory = o.longs === String ? String(m.maxMemory) : m.maxMemory;
                    else
                        d.maxMemory = o.longs === String ? $util.Long.prototype.toString.call(m.maxMemory) : o.longs === Number ? new $util.LongBits(m.maxMemory.low >>> 0, m.maxMemory.high >>> 0).toNumber() : m.maxMemory;
                }
                if (m.model != null && m.hasOwnProperty("model")) {
                    d.model = m.model;
                }
                if (m.modelCode != null && m.hasOwnProperty("modelCode")) {
                    d.modelCode = m.modelCode;
                }
                if (m.platform != null && m.hasOwnProperty("platform")) {
                    d.platform = m.platform;
                }
                if (m.systemName != null && m.hasOwnProperty("systemName")) {
                    d.systemName = m.systemName;
                }
                if (m.systemVersion != null && m.hasOwnProperty("systemVersion")) {
                    d.systemVersion = m.systemVersion;
                }
                if (m.timezone != null && m.hasOwnProperty("timezone")) {
                    d.timezone = m.timezone;
                }
                if (m.totalMemory != null && m.hasOwnProperty("totalMemory")) {
                    if (typeof m.totalMemory === "number")
                        d.totalMemory = o.longs === String ? String(m.totalMemory) : m.totalMemory;
                    else
                        d.totalMemory = o.longs === String ? $util.Long.prototype.toString.call(m.totalMemory) : o.longs === Number ? new $util.LongBits(m.totalMemory.low >>> 0, m.totalMemory.high >>> 0).toNumber() : m.totalMemory;
                }
                if (m.userAgent != null && m.hasOwnProperty("userAgent")) {
                    d.userAgent = m.userAgent;
                }
                if (m.vendorId != null && m.hasOwnProperty("vendorId")) {
                    d.vendorId = m.vendorId;
                }
                if (m.supportedVideoCodecs && m.supportedVideoCodecs.length) {
                    d.supportedVideoCodecs = [];
                    for (var j = 0; j < m.supportedVideoCodecs.length; ++j) {
                        d.supportedVideoCodecs[j] = o.enums === String ? $root.streem.api.VideoCodec[m.supportedVideoCodecs[j]] === undefined ? m.supportedVideoCodecs[j] : $root.streem.api.VideoCodec[m.supportedVideoCodecs[j]] : m.supportedVideoCodecs[j];
                    }
                }
                if (m.supportedVideoPlatforms && m.supportedVideoPlatforms.length) {
                    d.supportedVideoPlatforms = [];
                    for (var j = 0; j < m.supportedVideoPlatforms.length; ++j) {
                        d.supportedVideoPlatforms[j] = o.enums === String ? $root.streem.api.VideoPlatform[m.supportedVideoPlatforms[j]] === undefined ? m.supportedVideoPlatforms[j] : $root.streem.api.VideoPlatform[m.supportedVideoPlatforms[j]] : m.supportedVideoPlatforms[j];
                    }
                }
                if (m.supportedWallPlatforms && m.supportedWallPlatforms.length) {
                    d.supportedWallPlatforms = [];
                    for (var j = 0; j < m.supportedWallPlatforms.length; ++j) {
                        d.supportedWallPlatforms[j] = o.enums === String ? $root.streem.api.WallPlatform[m.supportedWallPlatforms[j]] === undefined ? m.supportedWallPlatforms[j] : $root.streem.api.WallPlatform[m.supportedWallPlatforms[j]] : m.supportedWallPlatforms[j];
                    }
                }
                return d;
            };

            /**
             * Converts this Device to JSON.
             * @function toJSON
             * @memberof streem.api.Device
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Device.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Device
             * @function getTypeUrl
             * @memberof streem.api.Device
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Device.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Device";
            };

            return Device;
        })();

        api.Company = (function() {

            /**
             * Properties of a Company.
             * @memberof streem.api
             * @interface ICompany
             * @property {string|null} [sid] Company sid
             * @property {string|null} [code] Company code
             * @property {string|null} [name] Company name
             * @property {boolean|null} [active] Company active
             * @property {string|null} [parentCompanySid] Company parentCompanySid
             * @property {number|null} [subCompanyLimit] Company subCompanyLimit
             * @property {google.protobuf.ITimestamp|null} [createdAt] Company createdAt
             * @property {google.protobuf.ITimestamp|null} [activeStatusModifiedAt] Company activeStatusModifiedAt
             * @property {Array.<streem.api.ICallSource>|null} [callSources] Company callSources
             */

            /**
             * Constructs a new Company.
             * @memberof streem.api
             * @classdesc Represents a Company.
             * @implements ICompany
             * @constructor
             * @param {streem.api.ICompany=} [p] Properties to set
             */
            function Company(p) {
                this.callSources = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Company sid.
             * @member {string} sid
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.sid = "";

            /**
             * Company code.
             * @member {string} code
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.code = "";

            /**
             * Company name.
             * @member {string} name
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.name = "";

            /**
             * Company active.
             * @member {boolean} active
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.active = false;

            /**
             * Company parentCompanySid.
             * @member {string} parentCompanySid
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.parentCompanySid = "";

            /**
             * Company subCompanyLimit.
             * @member {number} subCompanyLimit
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.subCompanyLimit = 0;

            /**
             * Company createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.createdAt = null;

            /**
             * Company activeStatusModifiedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} activeStatusModifiedAt
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.activeStatusModifiedAt = null;

            /**
             * Company callSources.
             * @member {Array.<streem.api.ICallSource>} callSources
             * @memberof streem.api.Company
             * @instance
             */
            Company.prototype.callSources = $util.emptyArray;

            /**
             * Creates a new Company instance using the specified properties.
             * @function create
             * @memberof streem.api.Company
             * @static
             * @param {streem.api.ICompany=} [properties] Properties to set
             * @returns {streem.api.Company} Company instance
             */
            Company.create = function create(properties) {
                return new Company(properties);
            };

            /**
             * Encodes the specified Company message. Does not implicitly {@link streem.api.Company.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Company
             * @static
             * @param {streem.api.ICompany} m Company message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Company.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(18).string(m.code);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(32).bool(m.active);
                if (m.parentCompanySid != null && Object.hasOwnProperty.call(m, "parentCompanySid"))
                    w.uint32(42).string(m.parentCompanySid);
                if (m.subCompanyLimit != null && Object.hasOwnProperty.call(m, "subCompanyLimit"))
                    w.uint32(48).int32(m.subCompanyLimit);
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(58).fork()).ldelim();
                if (m.activeStatusModifiedAt != null && Object.hasOwnProperty.call(m, "activeStatusModifiedAt"))
                    $root.google.protobuf.Timestamp.encode(m.activeStatusModifiedAt, w.uint32(66).fork()).ldelim();
                if (m.callSources != null && m.callSources.length) {
                    for (var i = 0; i < m.callSources.length; ++i)
                        $root.streem.api.CallSource.encode(m.callSources[i], w.uint32(74).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a Company message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Company
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Company} Company
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Company.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Company();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.code = r.string();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    case 4: {
                            m.active = r.bool();
                            break;
                        }
                    case 5: {
                            m.parentCompanySid = r.string();
                            break;
                        }
                    case 6: {
                            m.subCompanyLimit = r.int32();
                            break;
                        }
                    case 7: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            m.activeStatusModifiedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            if (!(m.callSources && m.callSources.length))
                                m.callSources = [];
                            m.callSources.push($root.streem.api.CallSource.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Company message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Company
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Company} Company
             */
            Company.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Company)
                    return d;
                var m = new $root.streem.api.Company();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.active != null) {
                    m.active = Boolean(d.active);
                }
                if (d.parentCompanySid != null) {
                    m.parentCompanySid = String(d.parentCompanySid);
                }
                if (d.subCompanyLimit != null) {
                    m.subCompanyLimit = d.subCompanyLimit | 0;
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.Company.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                if (d.activeStatusModifiedAt != null) {
                    if (typeof d.activeStatusModifiedAt !== "object")
                        throw TypeError(".streem.api.Company.activeStatusModifiedAt: object expected");
                    m.activeStatusModifiedAt = $root.google.protobuf.Timestamp.fromObject(d.activeStatusModifiedAt);
                }
                if (d.callSources) {
                    if (!Array.isArray(d.callSources))
                        throw TypeError(".streem.api.Company.callSources: array expected");
                    m.callSources = [];
                    for (var i = 0; i < d.callSources.length; ++i) {
                        if (typeof d.callSources[i] !== "object")
                            throw TypeError(".streem.api.Company.callSources: object expected");
                        m.callSources[i] = $root.streem.api.CallSource.fromObject(d.callSources[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Company message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Company
             * @static
             * @param {streem.api.Company} m Company
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Company.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.callSources = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.code = "";
                    d.name = "";
                    d.active = false;
                    d.parentCompanySid = "";
                    d.subCompanyLimit = 0;
                    d.createdAt = null;
                    d.activeStatusModifiedAt = null;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = m.active;
                }
                if (m.parentCompanySid != null && m.hasOwnProperty("parentCompanySid")) {
                    d.parentCompanySid = m.parentCompanySid;
                }
                if (m.subCompanyLimit != null && m.hasOwnProperty("subCompanyLimit")) {
                    d.subCompanyLimit = m.subCompanyLimit;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.activeStatusModifiedAt != null && m.hasOwnProperty("activeStatusModifiedAt")) {
                    d.activeStatusModifiedAt = $root.google.protobuf.Timestamp.toObject(m.activeStatusModifiedAt, o);
                }
                if (m.callSources && m.callSources.length) {
                    d.callSources = [];
                    for (var j = 0; j < m.callSources.length; ++j) {
                        d.callSources[j] = $root.streem.api.CallSource.toObject(m.callSources[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this Company to JSON.
             * @function toJSON
             * @memberof streem.api.Company
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Company
             * @function getTypeUrl
             * @memberof streem.api.Company
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Company.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Company";
            };

            /**
             * IdentityProvider enum.
             * @name streem.api.Company.IdentityProvider
             * @enum {number}
             * @property {number} IDENTITY_PROVIDER_INVALID=0 IDENTITY_PROVIDER_INVALID value
             * @property {number} IDENTITY_PROVIDER_COGNITO=1 IDENTITY_PROVIDER_COGNITO value
             * @property {number} IDENTITY_PROVIDER_FUSION_AUTH=2 IDENTITY_PROVIDER_FUSION_AUTH value
             */
            Company.IdentityProvider = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "IDENTITY_PROVIDER_INVALID"] = 0;
                values[valuesById[1] = "IDENTITY_PROVIDER_COGNITO"] = 1;
                values[valuesById[2] = "IDENTITY_PROVIDER_FUSION_AUTH"] = 2;
                return values;
            })();

            return Company;
        })();

        api.PublicLoginConfiguration = (function() {

            /**
             * Properties of a PublicLoginConfiguration.
             * @memberof streem.api
             * @interface IPublicLoginConfiguration
             * @property {streem.api.PublicLoginConfiguration.IOpenId|null} [openId] PublicLoginConfiguration openId
             * @property {string|null} [logoutEndpoint] PublicLoginConfiguration logoutEndpoint
             */

            /**
             * Constructs a new PublicLoginConfiguration.
             * @memberof streem.api
             * @classdesc Represents a PublicLoginConfiguration.
             * @implements IPublicLoginConfiguration
             * @constructor
             * @param {streem.api.IPublicLoginConfiguration=} [p] Properties to set
             */
            function PublicLoginConfiguration(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PublicLoginConfiguration openId.
             * @member {streem.api.PublicLoginConfiguration.IOpenId|null|undefined} openId
             * @memberof streem.api.PublicLoginConfiguration
             * @instance
             */
            PublicLoginConfiguration.prototype.openId = null;

            /**
             * PublicLoginConfiguration logoutEndpoint.
             * @member {string} logoutEndpoint
             * @memberof streem.api.PublicLoginConfiguration
             * @instance
             */
            PublicLoginConfiguration.prototype.logoutEndpoint = "";

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * PublicLoginConfiguration provider.
             * @member {"openId"|undefined} provider
             * @memberof streem.api.PublicLoginConfiguration
             * @instance
             */
            Object.defineProperty(PublicLoginConfiguration.prototype, "provider", {
                get: $util.oneOfGetter($oneOfFields = ["openId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new PublicLoginConfiguration instance using the specified properties.
             * @function create
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {streem.api.IPublicLoginConfiguration=} [properties] Properties to set
             * @returns {streem.api.PublicLoginConfiguration} PublicLoginConfiguration instance
             */
            PublicLoginConfiguration.create = function create(properties) {
                return new PublicLoginConfiguration(properties);
            };

            /**
             * Encodes the specified PublicLoginConfiguration message. Does not implicitly {@link streem.api.PublicLoginConfiguration.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {streem.api.IPublicLoginConfiguration} m PublicLoginConfiguration message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublicLoginConfiguration.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.openId != null && Object.hasOwnProperty.call(m, "openId"))
                    $root.streem.api.PublicLoginConfiguration.OpenId.encode(m.openId, w.uint32(10).fork()).ldelim();
                if (m.logoutEndpoint != null && Object.hasOwnProperty.call(m, "logoutEndpoint"))
                    w.uint32(82).string(m.logoutEndpoint);
                return w;
            };

            /**
             * Decodes a PublicLoginConfiguration message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PublicLoginConfiguration} PublicLoginConfiguration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublicLoginConfiguration.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PublicLoginConfiguration();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.openId = $root.streem.api.PublicLoginConfiguration.OpenId.decode(r, r.uint32());
                            break;
                        }
                    case 10: {
                            m.logoutEndpoint = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PublicLoginConfiguration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PublicLoginConfiguration} PublicLoginConfiguration
             */
            PublicLoginConfiguration.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PublicLoginConfiguration)
                    return d;
                var m = new $root.streem.api.PublicLoginConfiguration();
                if (d.openId != null) {
                    if (typeof d.openId !== "object")
                        throw TypeError(".streem.api.PublicLoginConfiguration.openId: object expected");
                    m.openId = $root.streem.api.PublicLoginConfiguration.OpenId.fromObject(d.openId);
                }
                if (d.logoutEndpoint != null) {
                    m.logoutEndpoint = String(d.logoutEndpoint);
                }
                return m;
            };

            /**
             * Creates a plain object from a PublicLoginConfiguration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {streem.api.PublicLoginConfiguration} m PublicLoginConfiguration
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PublicLoginConfiguration.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.logoutEndpoint = "";
                }
                if (m.openId != null && m.hasOwnProperty("openId")) {
                    d.openId = $root.streem.api.PublicLoginConfiguration.OpenId.toObject(m.openId, o);
                    if (o.oneofs)
                        d.provider = "openId";
                }
                if (m.logoutEndpoint != null && m.hasOwnProperty("logoutEndpoint")) {
                    d.logoutEndpoint = m.logoutEndpoint;
                }
                return d;
            };

            /**
             * Converts this PublicLoginConfiguration to JSON.
             * @function toJSON
             * @memberof streem.api.PublicLoginConfiguration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PublicLoginConfiguration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PublicLoginConfiguration
             * @function getTypeUrl
             * @memberof streem.api.PublicLoginConfiguration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PublicLoginConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PublicLoginConfiguration";
            };

            PublicLoginConfiguration.OpenId = (function() {

                /**
                 * Properties of an OpenId.
                 * @memberof streem.api.PublicLoginConfiguration
                 * @interface IOpenId
                 * @property {string|null} [tokenEndpoint] OpenId tokenEndpoint
                 * @property {string|null} [clientId] OpenId clientId
                 * @property {string|null} [authorizationEndpoint] OpenId authorizationEndpoint
                 * @property {string|null} [idpHint] OpenId idpHint
                 */

                /**
                 * Constructs a new OpenId.
                 * @memberof streem.api.PublicLoginConfiguration
                 * @classdesc Represents an OpenId.
                 * @implements IOpenId
                 * @constructor
                 * @param {streem.api.PublicLoginConfiguration.IOpenId=} [p] Properties to set
                 */
                function OpenId(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * OpenId tokenEndpoint.
                 * @member {string} tokenEndpoint
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @instance
                 */
                OpenId.prototype.tokenEndpoint = "";

                /**
                 * OpenId clientId.
                 * @member {string} clientId
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @instance
                 */
                OpenId.prototype.clientId = "";

                /**
                 * OpenId authorizationEndpoint.
                 * @member {string} authorizationEndpoint
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @instance
                 */
                OpenId.prototype.authorizationEndpoint = "";

                /**
                 * OpenId idpHint.
                 * @member {string} idpHint
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @instance
                 */
                OpenId.prototype.idpHint = "";

                /**
                 * Creates a new OpenId instance using the specified properties.
                 * @function create
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {streem.api.PublicLoginConfiguration.IOpenId=} [properties] Properties to set
                 * @returns {streem.api.PublicLoginConfiguration.OpenId} OpenId instance
                 */
                OpenId.create = function create(properties) {
                    return new OpenId(properties);
                };

                /**
                 * Encodes the specified OpenId message. Does not implicitly {@link streem.api.PublicLoginConfiguration.OpenId.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {streem.api.PublicLoginConfiguration.IOpenId} m OpenId message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OpenId.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.tokenEndpoint != null && Object.hasOwnProperty.call(m, "tokenEndpoint"))
                        w.uint32(10).string(m.tokenEndpoint);
                    if (m.clientId != null && Object.hasOwnProperty.call(m, "clientId"))
                        w.uint32(18).string(m.clientId);
                    if (m.authorizationEndpoint != null && Object.hasOwnProperty.call(m, "authorizationEndpoint"))
                        w.uint32(26).string(m.authorizationEndpoint);
                    if (m.idpHint != null && Object.hasOwnProperty.call(m, "idpHint"))
                        w.uint32(34).string(m.idpHint);
                    return w;
                };

                /**
                 * Decodes an OpenId message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.PublicLoginConfiguration.OpenId} OpenId
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OpenId.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PublicLoginConfiguration.OpenId();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.tokenEndpoint = r.string();
                                break;
                            }
                        case 2: {
                                m.clientId = r.string();
                                break;
                            }
                        case 3: {
                                m.authorizationEndpoint = r.string();
                                break;
                            }
                        case 4: {
                                m.idpHint = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an OpenId message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.PublicLoginConfiguration.OpenId} OpenId
                 */
                OpenId.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.PublicLoginConfiguration.OpenId)
                        return d;
                    var m = new $root.streem.api.PublicLoginConfiguration.OpenId();
                    if (d.tokenEndpoint != null) {
                        m.tokenEndpoint = String(d.tokenEndpoint);
                    }
                    if (d.clientId != null) {
                        m.clientId = String(d.clientId);
                    }
                    if (d.authorizationEndpoint != null) {
                        m.authorizationEndpoint = String(d.authorizationEndpoint);
                    }
                    if (d.idpHint != null) {
                        m.idpHint = String(d.idpHint);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an OpenId message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {streem.api.PublicLoginConfiguration.OpenId} m OpenId
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenId.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.tokenEndpoint = "";
                        d.clientId = "";
                        d.authorizationEndpoint = "";
                        d.idpHint = "";
                    }
                    if (m.tokenEndpoint != null && m.hasOwnProperty("tokenEndpoint")) {
                        d.tokenEndpoint = m.tokenEndpoint;
                    }
                    if (m.clientId != null && m.hasOwnProperty("clientId")) {
                        d.clientId = m.clientId;
                    }
                    if (m.authorizationEndpoint != null && m.hasOwnProperty("authorizationEndpoint")) {
                        d.authorizationEndpoint = m.authorizationEndpoint;
                    }
                    if (m.idpHint != null && m.hasOwnProperty("idpHint")) {
                        d.idpHint = m.idpHint;
                    }
                    return d;
                };

                /**
                 * Converts this OpenId to JSON.
                 * @function toJSON
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenId.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for OpenId
                 * @function getTypeUrl
                 * @memberof streem.api.PublicLoginConfiguration.OpenId
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OpenId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.PublicLoginConfiguration.OpenId";
                };

                return OpenId;
            })();

            return PublicLoginConfiguration;
        })();

        api.ExternalUser = (function() {

            /**
             * Properties of an ExternalUser.
             * @memberof streem.api
             * @interface IExternalUser
             * @property {string|null} [userSid] ExternalUser userSid
             * @property {string|null} [externalUserId] ExternalUser externalUserId
             */

            /**
             * Constructs a new ExternalUser.
             * @memberof streem.api
             * @classdesc Represents an ExternalUser.
             * @implements IExternalUser
             * @constructor
             * @param {streem.api.IExternalUser=} [p] Properties to set
             */
            function ExternalUser(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ExternalUser userSid.
             * @member {string} userSid
             * @memberof streem.api.ExternalUser
             * @instance
             */
            ExternalUser.prototype.userSid = "";

            /**
             * ExternalUser externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.ExternalUser
             * @instance
             */
            ExternalUser.prototype.externalUserId = "";

            /**
             * Creates a new ExternalUser instance using the specified properties.
             * @function create
             * @memberof streem.api.ExternalUser
             * @static
             * @param {streem.api.IExternalUser=} [properties] Properties to set
             * @returns {streem.api.ExternalUser} ExternalUser instance
             */
            ExternalUser.create = function create(properties) {
                return new ExternalUser(properties);
            };

            /**
             * Encodes the specified ExternalUser message. Does not implicitly {@link streem.api.ExternalUser.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ExternalUser
             * @static
             * @param {streem.api.IExternalUser} m ExternalUser message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalUser.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(18).string(m.externalUserId);
                return w;
            };

            /**
             * Decodes an ExternalUser message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ExternalUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ExternalUser} ExternalUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalUser.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ExternalUser();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.externalUserId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ExternalUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ExternalUser
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ExternalUser} ExternalUser
             */
            ExternalUser.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ExternalUser)
                    return d;
                var m = new $root.streem.api.ExternalUser();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                return m;
            };

            /**
             * Creates a plain object from an ExternalUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ExternalUser
             * @static
             * @param {streem.api.ExternalUser} m ExternalUser
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalUser.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.externalUserId = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                return d;
            };

            /**
             * Converts this ExternalUser to JSON.
             * @function toJSON
             * @memberof streem.api.ExternalUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ExternalUser
             * @function getTypeUrl
             * @memberof streem.api.ExternalUser
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ExternalUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ExternalUser";
            };

            return ExternalUser;
        })();

        /**
         * CompanyUploadType enum.
         * @name streem.api.CompanyUploadType
         * @enum {number}
         * @property {number} COMPANY_UPLOAD_TYPE_INVALID=0 COMPANY_UPLOAD_TYPE_INVALID value
         * @property {number} COMPANY_UPLOAD_TYPE_LOGO=1 COMPANY_UPLOAD_TYPE_LOGO value
         * @property {number} COMPANY_UPLOAD_TYPE_WATERMARK=2 COMPANY_UPLOAD_TYPE_WATERMARK value
         * @property {number} COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG=3 COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG value
         * @property {number} COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO=4 COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO value
         */
        api.CompanyUploadType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COMPANY_UPLOAD_TYPE_INVALID"] = 0;
            values[valuesById[1] = "COMPANY_UPLOAD_TYPE_LOGO"] = 1;
            values[valuesById[2] = "COMPANY_UPLOAD_TYPE_WATERMARK"] = 2;
            values[valuesById[3] = "COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG"] = 3;
            values[valuesById[4] = "COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO"] = 4;
            return values;
        })();

        api.CompanyRoomOutcomeConfig = (function() {

            /**
             * Properties of a CompanyRoomOutcomeConfig.
             * @memberof streem.api
             * @interface ICompanyRoomOutcomeConfig
             * @property {streem.api.CompanyRoomOutcomeConfig.ISection|null} [diagnosis] CompanyRoomOutcomeConfig diagnosis
             * @property {streem.api.CompanyRoomOutcomeConfig.ISection|null} [disposition] CompanyRoomOutcomeConfig disposition
             * @property {Object.<string,streem.api.CompanyRoomOutcomeConfig.ISection>|null} [customFields] CompanyRoomOutcomeConfig customFields
             * @property {google.protobuf.ITimestamp|null} [createdAt] CompanyRoomOutcomeConfig createdAt
             */

            /**
             * Constructs a new CompanyRoomOutcomeConfig.
             * @memberof streem.api
             * @classdesc Represents a CompanyRoomOutcomeConfig.
             * @implements ICompanyRoomOutcomeConfig
             * @constructor
             * @param {streem.api.ICompanyRoomOutcomeConfig=} [p] Properties to set
             */
            function CompanyRoomOutcomeConfig(p) {
                this.customFields = {};
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CompanyRoomOutcomeConfig diagnosis.
             * @member {streem.api.CompanyRoomOutcomeConfig.ISection|null|undefined} diagnosis
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @instance
             */
            CompanyRoomOutcomeConfig.prototype.diagnosis = null;

            /**
             * CompanyRoomOutcomeConfig disposition.
             * @member {streem.api.CompanyRoomOutcomeConfig.ISection|null|undefined} disposition
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @instance
             */
            CompanyRoomOutcomeConfig.prototype.disposition = null;

            /**
             * CompanyRoomOutcomeConfig customFields.
             * @member {Object.<string,streem.api.CompanyRoomOutcomeConfig.ISection>} customFields
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @instance
             */
            CompanyRoomOutcomeConfig.prototype.customFields = $util.emptyObject;

            /**
             * CompanyRoomOutcomeConfig createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @instance
             */
            CompanyRoomOutcomeConfig.prototype.createdAt = null;

            /**
             * Creates a new CompanyRoomOutcomeConfig instance using the specified properties.
             * @function create
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {streem.api.ICompanyRoomOutcomeConfig=} [properties] Properties to set
             * @returns {streem.api.CompanyRoomOutcomeConfig} CompanyRoomOutcomeConfig instance
             */
            CompanyRoomOutcomeConfig.create = function create(properties) {
                return new CompanyRoomOutcomeConfig(properties);
            };

            /**
             * Encodes the specified CompanyRoomOutcomeConfig message. Does not implicitly {@link streem.api.CompanyRoomOutcomeConfig.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {streem.api.ICompanyRoomOutcomeConfig} m CompanyRoomOutcomeConfig message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyRoomOutcomeConfig.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.diagnosis != null && Object.hasOwnProperty.call(m, "diagnosis"))
                    $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.diagnosis, w.uint32(10).fork()).ldelim();
                if (m.disposition != null && Object.hasOwnProperty.call(m, "disposition"))
                    $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.disposition, w.uint32(18).fork()).ldelim();
                if (m.customFields != null && Object.hasOwnProperty.call(m, "customFields")) {
                    for (var ks = Object.keys(m.customFields), i = 0; i < ks.length; ++i) {
                        w.uint32(26).fork().uint32(10).string(ks[i]);
                        $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.customFields[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                    }
                }
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CompanyRoomOutcomeConfig message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CompanyRoomOutcomeConfig} CompanyRoomOutcomeConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyRoomOutcomeConfig.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyRoomOutcomeConfig(), k, value;
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            if (m.customFields === $util.emptyObject)
                                m.customFields = {};
                            var c2 = r.uint32() + r.pos;
                            k = "";
                            value = null;
                            while (r.pos < c2) {
                                var tag2 = r.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    k = r.string();
                                    break;
                                case 2:
                                    value = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                                    break;
                                default:
                                    r.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            m.customFields[k] = value;
                            break;
                        }
                    case 4: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CompanyRoomOutcomeConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CompanyRoomOutcomeConfig} CompanyRoomOutcomeConfig
             */
            CompanyRoomOutcomeConfig.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CompanyRoomOutcomeConfig)
                    return d;
                var m = new $root.streem.api.CompanyRoomOutcomeConfig();
                if (d.diagnosis != null) {
                    if (typeof d.diagnosis !== "object")
                        throw TypeError(".streem.api.CompanyRoomOutcomeConfig.diagnosis: object expected");
                    m.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.diagnosis);
                }
                if (d.disposition != null) {
                    if (typeof d.disposition !== "object")
                        throw TypeError(".streem.api.CompanyRoomOutcomeConfig.disposition: object expected");
                    m.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.disposition);
                }
                if (d.customFields) {
                    if (typeof d.customFields !== "object")
                        throw TypeError(".streem.api.CompanyRoomOutcomeConfig.customFields: object expected");
                    m.customFields = {};
                    for (var ks = Object.keys(d.customFields), i = 0; i < ks.length; ++i) {
                        if (typeof d.customFields[ks[i]] !== "object")
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.customFields: object expected");
                        m.customFields[ks[i]] = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.customFields[ks[i]]);
                    }
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.CompanyRoomOutcomeConfig.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a CompanyRoomOutcomeConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {streem.api.CompanyRoomOutcomeConfig} m CompanyRoomOutcomeConfig
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyRoomOutcomeConfig.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.objects || o.defaults) {
                    d.customFields = {};
                }
                if (o.defaults) {
                    d.diagnosis = null;
                    d.disposition = null;
                    d.createdAt = null;
                }
                if (m.diagnosis != null && m.hasOwnProperty("diagnosis")) {
                    d.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.diagnosis, o);
                }
                if (m.disposition != null && m.hasOwnProperty("disposition")) {
                    d.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.disposition, o);
                }
                var ks2;
                if (m.customFields && (ks2 = Object.keys(m.customFields)).length) {
                    d.customFields = {};
                    for (var j = 0; j < ks2.length; ++j) {
                        d.customFields[ks2[j]] = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.customFields[ks2[j]], o);
                    }
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                return d;
            };

            /**
             * Converts this CompanyRoomOutcomeConfig to JSON.
             * @function toJSON
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyRoomOutcomeConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CompanyRoomOutcomeConfig
             * @function getTypeUrl
             * @memberof streem.api.CompanyRoomOutcomeConfig
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CompanyRoomOutcomeConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CompanyRoomOutcomeConfig";
            };

            CompanyRoomOutcomeConfig.Section = (function() {

                /**
                 * Properties of a Section.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @interface ISection
                 * @property {Array.<streem.api.CompanyRoomOutcomeConfig.IOption>|null} [options] Section options
                 * @property {Array.<string>|null} [callSourceCodes] Section callSourceCodes
                 */

                /**
                 * Constructs a new Section.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @classdesc Represents a Section.
                 * @implements ISection
                 * @constructor
                 * @param {streem.api.CompanyRoomOutcomeConfig.ISection=} [p] Properties to set
                 */
                function Section(p) {
                    this.options = [];
                    this.callSourceCodes = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Section options.
                 * @member {Array.<streem.api.CompanyRoomOutcomeConfig.IOption>} options
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @instance
                 */
                Section.prototype.options = $util.emptyArray;

                /**
                 * Section callSourceCodes.
                 * @member {Array.<string>} callSourceCodes
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @instance
                 */
                Section.prototype.callSourceCodes = $util.emptyArray;

                /**
                 * Creates a new Section instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.ISection=} [properties] Properties to set
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Section} Section instance
                 */
                Section.create = function create(properties) {
                    return new Section(properties);
                };

                /**
                 * Encodes the specified Section message. Does not implicitly {@link streem.api.CompanyRoomOutcomeConfig.Section.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.ISection} m Section message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Section.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.options != null && m.options.length) {
                        for (var i = 0; i < m.options.length; ++i)
                            $root.streem.api.CompanyRoomOutcomeConfig.Option.encode(m.options[i], w.uint32(10).fork()).ldelim();
                    }
                    if (m.callSourceCodes != null && m.callSourceCodes.length) {
                        for (var i = 0; i < m.callSourceCodes.length; ++i)
                            w.uint32(18).string(m.callSourceCodes[i]);
                    }
                    return w;
                };

                /**
                 * Decodes a Section message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Section} Section
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Section.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyRoomOutcomeConfig.Section();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.options && m.options.length))
                                    m.options = [];
                                m.options.push($root.streem.api.CompanyRoomOutcomeConfig.Option.decode(r, r.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(m.callSourceCodes && m.callSourceCodes.length))
                                    m.callSourceCodes = [];
                                m.callSourceCodes.push(r.string());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Section message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Section} Section
                 */
                Section.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CompanyRoomOutcomeConfig.Section)
                        return d;
                    var m = new $root.streem.api.CompanyRoomOutcomeConfig.Section();
                    if (d.options) {
                        if (!Array.isArray(d.options))
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Section.options: array expected");
                        m.options = [];
                        for (var i = 0; i < d.options.length; ++i) {
                            if (typeof d.options[i] !== "object")
                                throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Section.options: object expected");
                            m.options[i] = $root.streem.api.CompanyRoomOutcomeConfig.Option.fromObject(d.options[i]);
                        }
                    }
                    if (d.callSourceCodes) {
                        if (!Array.isArray(d.callSourceCodes))
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Section.callSourceCodes: array expected");
                        m.callSourceCodes = [];
                        for (var i = 0; i < d.callSourceCodes.length; ++i) {
                            m.callSourceCodes[i] = String(d.callSourceCodes[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Section message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.Section} m Section
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Section.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.options = [];
                        d.callSourceCodes = [];
                    }
                    if (m.options && m.options.length) {
                        d.options = [];
                        for (var j = 0; j < m.options.length; ++j) {
                            d.options[j] = $root.streem.api.CompanyRoomOutcomeConfig.Option.toObject(m.options[j], o);
                        }
                    }
                    if (m.callSourceCodes && m.callSourceCodes.length) {
                        d.callSourceCodes = [];
                        for (var j = 0; j < m.callSourceCodes.length; ++j) {
                            d.callSourceCodes[j] = m.callSourceCodes[j];
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Section to JSON.
                 * @function toJSON
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Section.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Section
                 * @function getTypeUrl
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Section
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Section.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CompanyRoomOutcomeConfig.Section";
                };

                return Section;
            })();

            CompanyRoomOutcomeConfig.Option = (function() {

                /**
                 * Properties of an Option.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @interface IOption
                 * @property {streem.api.CompanyRoomOutcomeConfig.IEntry|null} [entry] Option entry
                 * @property {Object.<string,string>|null} [properties] Option properties
                 */

                /**
                 * Constructs a new Option.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @classdesc Represents an Option.
                 * @implements IOption
                 * @constructor
                 * @param {streem.api.CompanyRoomOutcomeConfig.IOption=} [p] Properties to set
                 */
                function Option(p) {
                    this.properties = {};
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Option entry.
                 * @member {streem.api.CompanyRoomOutcomeConfig.IEntry|null|undefined} entry
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @instance
                 */
                Option.prototype.entry = null;

                /**
                 * Option properties.
                 * @member {Object.<string,string>} properties
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @instance
                 */
                Option.prototype.properties = $util.emptyObject;

                /**
                 * Creates a new Option instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.IOption=} [properties] Properties to set
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Option} Option instance
                 */
                Option.create = function create(properties) {
                    return new Option(properties);
                };

                /**
                 * Encodes the specified Option message. Does not implicitly {@link streem.api.CompanyRoomOutcomeConfig.Option.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.IOption} m Option message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Option.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.entry != null && Object.hasOwnProperty.call(m, "entry"))
                        $root.streem.api.CompanyRoomOutcomeConfig.Entry.encode(m.entry, w.uint32(10).fork()).ldelim();
                    if (m.properties != null && Object.hasOwnProperty.call(m, "properties")) {
                        for (var ks = Object.keys(m.properties), i = 0; i < ks.length; ++i) {
                            w.uint32(18).fork().uint32(10).string(ks[i]).uint32(18).string(m.properties[ks[i]]).ldelim();
                        }
                    }
                    return w;
                };

                /**
                 * Decodes an Option message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Option} Option
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Option.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyRoomOutcomeConfig.Option(), k, value;
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.entry = $root.streem.api.CompanyRoomOutcomeConfig.Entry.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                if (m.properties === $util.emptyObject)
                                    m.properties = {};
                                var c2 = r.uint32() + r.pos;
                                k = "";
                                value = "";
                                while (r.pos < c2) {
                                    var tag2 = r.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        k = r.string();
                                        break;
                                    case 2:
                                        value = r.string();
                                        break;
                                    default:
                                        r.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                m.properties[k] = value;
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Option message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Option} Option
                 */
                Option.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CompanyRoomOutcomeConfig.Option)
                        return d;
                    var m = new $root.streem.api.CompanyRoomOutcomeConfig.Option();
                    if (d.entry != null) {
                        if (typeof d.entry !== "object")
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Option.entry: object expected");
                        m.entry = $root.streem.api.CompanyRoomOutcomeConfig.Entry.fromObject(d.entry);
                    }
                    if (d.properties) {
                        if (typeof d.properties !== "object")
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Option.properties: object expected");
                        m.properties = {};
                        for (var ks = Object.keys(d.properties), i = 0; i < ks.length; ++i) {
                            m.properties[ks[i]] = String(d.properties[ks[i]]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Option message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.Option} m Option
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Option.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.objects || o.defaults) {
                        d.properties = {};
                    }
                    if (o.defaults) {
                        d.entry = null;
                    }
                    if (m.entry != null && m.hasOwnProperty("entry")) {
                        d.entry = $root.streem.api.CompanyRoomOutcomeConfig.Entry.toObject(m.entry, o);
                    }
                    var ks2;
                    if (m.properties && (ks2 = Object.keys(m.properties)).length) {
                        d.properties = {};
                        for (var j = 0; j < ks2.length; ++j) {
                            d.properties[ks2[j]] = m.properties[ks2[j]];
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Option to JSON.
                 * @function toJSON
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Option.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Option
                 * @function getTypeUrl
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Option
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CompanyRoomOutcomeConfig.Option";
                };

                return Option;
            })();

            CompanyRoomOutcomeConfig.Entry = (function() {

                /**
                 * Properties of an Entry.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @interface IEntry
                 * @property {string|null} [code] Entry code
                 * @property {string|null} [label] Entry label
                 * @property {Array.<string>|null} [callSourceCodes] Entry callSourceCodes
                 */

                /**
                 * Constructs a new Entry.
                 * @memberof streem.api.CompanyRoomOutcomeConfig
                 * @classdesc Represents an Entry.
                 * @implements IEntry
                 * @constructor
                 * @param {streem.api.CompanyRoomOutcomeConfig.IEntry=} [p] Properties to set
                 */
                function Entry(p) {
                    this.callSourceCodes = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Entry code.
                 * @member {string} code
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @instance
                 */
                Entry.prototype.code = "";

                /**
                 * Entry label.
                 * @member {string} label
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @instance
                 */
                Entry.prototype.label = "";

                /**
                 * Entry callSourceCodes.
                 * @member {Array.<string>} callSourceCodes
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @instance
                 */
                Entry.prototype.callSourceCodes = $util.emptyArray;

                /**
                 * Creates a new Entry instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.IEntry=} [properties] Properties to set
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Entry} Entry instance
                 */
                Entry.create = function create(properties) {
                    return new Entry(properties);
                };

                /**
                 * Encodes the specified Entry message. Does not implicitly {@link streem.api.CompanyRoomOutcomeConfig.Entry.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.IEntry} m Entry message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Entry.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                        w.uint32(10).string(m.code);
                    if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                        w.uint32(18).string(m.label);
                    if (m.callSourceCodes != null && m.callSourceCodes.length) {
                        for (var i = 0; i < m.callSourceCodes.length; ++i)
                            w.uint32(26).string(m.callSourceCodes[i]);
                    }
                    return w;
                };

                /**
                 * Decodes an Entry message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Entry} Entry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Entry.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyRoomOutcomeConfig.Entry();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.code = r.string();
                                break;
                            }
                        case 2: {
                                m.label = r.string();
                                break;
                            }
                        case 3: {
                                if (!(m.callSourceCodes && m.callSourceCodes.length))
                                    m.callSourceCodes = [];
                                m.callSourceCodes.push(r.string());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Entry message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CompanyRoomOutcomeConfig.Entry} Entry
                 */
                Entry.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CompanyRoomOutcomeConfig.Entry)
                        return d;
                    var m = new $root.streem.api.CompanyRoomOutcomeConfig.Entry();
                    if (d.code != null) {
                        m.code = String(d.code);
                    }
                    if (d.label != null) {
                        m.label = String(d.label);
                    }
                    if (d.callSourceCodes) {
                        if (!Array.isArray(d.callSourceCodes))
                            throw TypeError(".streem.api.CompanyRoomOutcomeConfig.Entry.callSourceCodes: array expected");
                        m.callSourceCodes = [];
                        for (var i = 0; i < d.callSourceCodes.length; ++i) {
                            m.callSourceCodes[i] = String(d.callSourceCodes[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Entry message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {streem.api.CompanyRoomOutcomeConfig.Entry} m Entry
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Entry.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.callSourceCodes = [];
                    }
                    if (o.defaults) {
                        d.code = "";
                        d.label = "";
                    }
                    if (m.code != null && m.hasOwnProperty("code")) {
                        d.code = m.code;
                    }
                    if (m.label != null && m.hasOwnProperty("label")) {
                        d.label = m.label;
                    }
                    if (m.callSourceCodes && m.callSourceCodes.length) {
                        d.callSourceCodes = [];
                        for (var j = 0; j < m.callSourceCodes.length; ++j) {
                            d.callSourceCodes[j] = m.callSourceCodes[j];
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Entry to JSON.
                 * @function toJSON
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Entry.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Entry
                 * @function getTypeUrl
                 * @memberof streem.api.CompanyRoomOutcomeConfig.Entry
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Entry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CompanyRoomOutcomeConfig.Entry";
                };

                return Entry;
            })();

            return CompanyRoomOutcomeConfig;
        })();

        api.CompanyRoomOutcomeConfigDetail = (function() {

            /**
             * Properties of a CompanyRoomOutcomeConfigDetail.
             * @memberof streem.api
             * @interface ICompanyRoomOutcomeConfigDetail
             * @property {string|null} [companyId] CompanyRoomOutcomeConfigDetail companyId
             * @property {boolean|null} [visibility] CompanyRoomOutcomeConfigDetail visibility
             * @property {number|null} [version] CompanyRoomOutcomeConfigDetail version
             * @property {string|null} [fileName] CompanyRoomOutcomeConfigDetail fileName
             * @property {string|null} [storageUrl] CompanyRoomOutcomeConfigDetail storageUrl
             * @property {string|null} [amazonVersionId] CompanyRoomOutcomeConfigDetail amazonVersionId
             */

            /**
             * Constructs a new CompanyRoomOutcomeConfigDetail.
             * @memberof streem.api
             * @classdesc Represents a CompanyRoomOutcomeConfigDetail.
             * @implements ICompanyRoomOutcomeConfigDetail
             * @constructor
             * @param {streem.api.ICompanyRoomOutcomeConfigDetail=} [p] Properties to set
             */
            function CompanyRoomOutcomeConfigDetail(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CompanyRoomOutcomeConfigDetail companyId.
             * @member {string} companyId
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.companyId = "";

            /**
             * CompanyRoomOutcomeConfigDetail visibility.
             * @member {boolean} visibility
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.visibility = false;

            /**
             * CompanyRoomOutcomeConfigDetail version.
             * @member {number} version
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.version = 0;

            /**
             * CompanyRoomOutcomeConfigDetail fileName.
             * @member {string} fileName
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.fileName = "";

            /**
             * CompanyRoomOutcomeConfigDetail storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.storageUrl = "";

            /**
             * CompanyRoomOutcomeConfigDetail amazonVersionId.
             * @member {string} amazonVersionId
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             */
            CompanyRoomOutcomeConfigDetail.prototype.amazonVersionId = "";

            /**
             * Creates a new CompanyRoomOutcomeConfigDetail instance using the specified properties.
             * @function create
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {streem.api.ICompanyRoomOutcomeConfigDetail=} [properties] Properties to set
             * @returns {streem.api.CompanyRoomOutcomeConfigDetail} CompanyRoomOutcomeConfigDetail instance
             */
            CompanyRoomOutcomeConfigDetail.create = function create(properties) {
                return new CompanyRoomOutcomeConfigDetail(properties);
            };

            /**
             * Encodes the specified CompanyRoomOutcomeConfigDetail message. Does not implicitly {@link streem.api.CompanyRoomOutcomeConfigDetail.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {streem.api.ICompanyRoomOutcomeConfigDetail} m CompanyRoomOutcomeConfigDetail message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyRoomOutcomeConfigDetail.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
                    w.uint32(10).string(m.companyId);
                if (m.visibility != null && Object.hasOwnProperty.call(m, "visibility"))
                    w.uint32(16).bool(m.visibility);
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(24).int32(m.version);
                if (m.fileName != null && Object.hasOwnProperty.call(m, "fileName"))
                    w.uint32(34).string(m.fileName);
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(42).string(m.storageUrl);
                if (m.amazonVersionId != null && Object.hasOwnProperty.call(m, "amazonVersionId"))
                    w.uint32(50).string(m.amazonVersionId);
                return w;
            };

            /**
             * Decodes a CompanyRoomOutcomeConfigDetail message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CompanyRoomOutcomeConfigDetail} CompanyRoomOutcomeConfigDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyRoomOutcomeConfigDetail.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyRoomOutcomeConfigDetail();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyId = r.string();
                            break;
                        }
                    case 2: {
                            m.visibility = r.bool();
                            break;
                        }
                    case 3: {
                            m.version = r.int32();
                            break;
                        }
                    case 4: {
                            m.fileName = r.string();
                            break;
                        }
                    case 5: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 6: {
                            m.amazonVersionId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CompanyRoomOutcomeConfigDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CompanyRoomOutcomeConfigDetail} CompanyRoomOutcomeConfigDetail
             */
            CompanyRoomOutcomeConfigDetail.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CompanyRoomOutcomeConfigDetail)
                    return d;
                var m = new $root.streem.api.CompanyRoomOutcomeConfigDetail();
                if (d.companyId != null) {
                    m.companyId = String(d.companyId);
                }
                if (d.visibility != null) {
                    m.visibility = Boolean(d.visibility);
                }
                if (d.version != null) {
                    m.version = d.version | 0;
                }
                if (d.fileName != null) {
                    m.fileName = String(d.fileName);
                }
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                if (d.amazonVersionId != null) {
                    m.amazonVersionId = String(d.amazonVersionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a CompanyRoomOutcomeConfigDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {streem.api.CompanyRoomOutcomeConfigDetail} m CompanyRoomOutcomeConfigDetail
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyRoomOutcomeConfigDetail.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyId = "";
                    d.visibility = false;
                    d.version = 0;
                    d.fileName = "";
                    d.storageUrl = "";
                    d.amazonVersionId = "";
                }
                if (m.companyId != null && m.hasOwnProperty("companyId")) {
                    d.companyId = m.companyId;
                }
                if (m.visibility != null && m.hasOwnProperty("visibility")) {
                    d.visibility = m.visibility;
                }
                if (m.version != null && m.hasOwnProperty("version")) {
                    d.version = m.version;
                }
                if (m.fileName != null && m.hasOwnProperty("fileName")) {
                    d.fileName = m.fileName;
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.amazonVersionId != null && m.hasOwnProperty("amazonVersionId")) {
                    d.amazonVersionId = m.amazonVersionId;
                }
                return d;
            };

            /**
             * Converts this CompanyRoomOutcomeConfigDetail to JSON.
             * @function toJSON
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyRoomOutcomeConfigDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CompanyRoomOutcomeConfigDetail
             * @function getTypeUrl
             * @memberof streem.api.CompanyRoomOutcomeConfigDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CompanyRoomOutcomeConfigDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CompanyRoomOutcomeConfigDetail";
            };

            return CompanyRoomOutcomeConfigDetail;
        })();

        api.CallSource = (function() {

            /**
             * Properties of a CallSource.
             * @memberof streem.api
             * @interface ICallSource
             * @property {string|null} [sid] CallSource sid
             * @property {string|null} [companyId] CallSource companyId
             * @property {string|null} [code] CallSource code
             * @property {string|null} [name] CallSource name
             * @property {boolean|null} [isDefault] CallSource isDefault
             * @property {string|null} [logoUrl] CallSource logoUrl
             * @property {number|null} [maxDiagnoses] CallSource maxDiagnoses
             * @property {streem.api.ICustomTheme|null} [customTheme] CallSource customTheme
             */

            /**
             * Constructs a new CallSource.
             * @memberof streem.api
             * @classdesc Represents a CallSource.
             * @implements ICallSource
             * @constructor
             * @param {streem.api.ICallSource=} [p] Properties to set
             */
            function CallSource(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CallSource sid.
             * @member {string} sid
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.sid = "";

            /**
             * CallSource companyId.
             * @member {string} companyId
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.companyId = "";

            /**
             * CallSource code.
             * @member {string} code
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.code = "";

            /**
             * CallSource name.
             * @member {string} name
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.name = "";

            /**
             * CallSource isDefault.
             * @member {boolean} isDefault
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.isDefault = false;

            /**
             * CallSource logoUrl.
             * @member {string|null|undefined} logoUrl
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.logoUrl = null;

            /**
             * CallSource maxDiagnoses.
             * @member {number|null|undefined} maxDiagnoses
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.maxDiagnoses = null;

            /**
             * CallSource customTheme.
             * @member {streem.api.ICustomTheme|null|undefined} customTheme
             * @memberof streem.api.CallSource
             * @instance
             */
            CallSource.prototype.customTheme = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CallSource _logoUrl.
             * @member {"logoUrl"|undefined} _logoUrl
             * @memberof streem.api.CallSource
             * @instance
             */
            Object.defineProperty(CallSource.prototype, "_logoUrl", {
                get: $util.oneOfGetter($oneOfFields = ["logoUrl"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CallSource _maxDiagnoses.
             * @member {"maxDiagnoses"|undefined} _maxDiagnoses
             * @memberof streem.api.CallSource
             * @instance
             */
            Object.defineProperty(CallSource.prototype, "_maxDiagnoses", {
                get: $util.oneOfGetter($oneOfFields = ["maxDiagnoses"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CallSource _customTheme.
             * @member {"customTheme"|undefined} _customTheme
             * @memberof streem.api.CallSource
             * @instance
             */
            Object.defineProperty(CallSource.prototype, "_customTheme", {
                get: $util.oneOfGetter($oneOfFields = ["customTheme"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CallSource instance using the specified properties.
             * @function create
             * @memberof streem.api.CallSource
             * @static
             * @param {streem.api.ICallSource=} [properties] Properties to set
             * @returns {streem.api.CallSource} CallSource instance
             */
            CallSource.create = function create(properties) {
                return new CallSource(properties);
            };

            /**
             * Encodes the specified CallSource message. Does not implicitly {@link streem.api.CallSource.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CallSource
             * @static
             * @param {streem.api.ICallSource} m CallSource message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallSource.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
                    w.uint32(18).string(m.companyId);
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(26).string(m.code);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(34).string(m.name);
                if (m.isDefault != null && Object.hasOwnProperty.call(m, "isDefault"))
                    w.uint32(40).bool(m.isDefault);
                if (m.logoUrl != null && Object.hasOwnProperty.call(m, "logoUrl"))
                    w.uint32(50).string(m.logoUrl);
                if (m.maxDiagnoses != null && Object.hasOwnProperty.call(m, "maxDiagnoses"))
                    w.uint32(56).int32(m.maxDiagnoses);
                if (m.customTheme != null && Object.hasOwnProperty.call(m, "customTheme"))
                    $root.streem.api.CustomTheme.encode(m.customTheme, w.uint32(66).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CallSource message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CallSource
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CallSource} CallSource
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallSource.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallSource();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.companyId = r.string();
                            break;
                        }
                    case 3: {
                            m.code = r.string();
                            break;
                        }
                    case 4: {
                            m.name = r.string();
                            break;
                        }
                    case 5: {
                            m.isDefault = r.bool();
                            break;
                        }
                    case 6: {
                            m.logoUrl = r.string();
                            break;
                        }
                    case 7: {
                            m.maxDiagnoses = r.int32();
                            break;
                        }
                    case 8: {
                            m.customTheme = $root.streem.api.CustomTheme.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CallSource message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CallSource
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CallSource} CallSource
             */
            CallSource.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CallSource)
                    return d;
                var m = new $root.streem.api.CallSource();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.companyId != null) {
                    m.companyId = String(d.companyId);
                }
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.isDefault != null) {
                    m.isDefault = Boolean(d.isDefault);
                }
                if (d.logoUrl != null) {
                    m.logoUrl = String(d.logoUrl);
                }
                if (d.maxDiagnoses != null) {
                    m.maxDiagnoses = d.maxDiagnoses | 0;
                }
                if (d.customTheme != null) {
                    if (typeof d.customTheme !== "object")
                        throw TypeError(".streem.api.CallSource.customTheme: object expected");
                    m.customTheme = $root.streem.api.CustomTheme.fromObject(d.customTheme);
                }
                return m;
            };

            /**
             * Creates a plain object from a CallSource message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CallSource
             * @static
             * @param {streem.api.CallSource} m CallSource
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallSource.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.companyId = "";
                    d.code = "";
                    d.name = "";
                    d.isDefault = false;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.companyId != null && m.hasOwnProperty("companyId")) {
                    d.companyId = m.companyId;
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.isDefault != null && m.hasOwnProperty("isDefault")) {
                    d.isDefault = m.isDefault;
                }
                if (m.logoUrl != null && m.hasOwnProperty("logoUrl")) {
                    d.logoUrl = m.logoUrl;
                    if (o.oneofs)
                        d._logoUrl = "logoUrl";
                }
                if (m.maxDiagnoses != null && m.hasOwnProperty("maxDiagnoses")) {
                    d.maxDiagnoses = m.maxDiagnoses;
                    if (o.oneofs)
                        d._maxDiagnoses = "maxDiagnoses";
                }
                if (m.customTheme != null && m.hasOwnProperty("customTheme")) {
                    d.customTheme = $root.streem.api.CustomTheme.toObject(m.customTheme, o);
                    if (o.oneofs)
                        d._customTheme = "customTheme";
                }
                return d;
            };

            /**
             * Converts this CallSource to JSON.
             * @function toJSON
             * @memberof streem.api.CallSource
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallSource.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CallSource
             * @function getTypeUrl
             * @memberof streem.api.CallSource
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CallSource";
            };

            return CallSource;
        })();

        api.CustomTheme = (function() {

            /**
             * Properties of a CustomTheme.
             * @memberof streem.api
             * @interface ICustomTheme
             * @property {string|null} [primaryColor] CustomTheme primaryColor
             */

            /**
             * Constructs a new CustomTheme.
             * @memberof streem.api
             * @classdesc Represents a CustomTheme.
             * @implements ICustomTheme
             * @constructor
             * @param {streem.api.ICustomTheme=} [p] Properties to set
             */
            function CustomTheme(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CustomTheme primaryColor.
             * @member {string} primaryColor
             * @memberof streem.api.CustomTheme
             * @instance
             */
            CustomTheme.prototype.primaryColor = "";

            /**
             * Creates a new CustomTheme instance using the specified properties.
             * @function create
             * @memberof streem.api.CustomTheme
             * @static
             * @param {streem.api.ICustomTheme=} [properties] Properties to set
             * @returns {streem.api.CustomTheme} CustomTheme instance
             */
            CustomTheme.create = function create(properties) {
                return new CustomTheme(properties);
            };

            /**
             * Encodes the specified CustomTheme message. Does not implicitly {@link streem.api.CustomTheme.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CustomTheme
             * @static
             * @param {streem.api.ICustomTheme} m CustomTheme message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomTheme.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.primaryColor != null && Object.hasOwnProperty.call(m, "primaryColor"))
                    w.uint32(10).string(m.primaryColor);
                return w;
            };

            /**
             * Decodes a CustomTheme message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CustomTheme
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CustomTheme} CustomTheme
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomTheme.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CustomTheme();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.primaryColor = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CustomTheme message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CustomTheme
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CustomTheme} CustomTheme
             */
            CustomTheme.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CustomTheme)
                    return d;
                var m = new $root.streem.api.CustomTheme();
                if (d.primaryColor != null) {
                    m.primaryColor = String(d.primaryColor);
                }
                return m;
            };

            /**
             * Creates a plain object from a CustomTheme message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CustomTheme
             * @static
             * @param {streem.api.CustomTheme} m CustomTheme
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomTheme.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.primaryColor = "";
                }
                if (m.primaryColor != null && m.hasOwnProperty("primaryColor")) {
                    d.primaryColor = m.primaryColor;
                }
                return d;
            };

            /**
             * Converts this CustomTheme to JSON.
             * @function toJSON
             * @memberof streem.api.CustomTheme
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomTheme.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CustomTheme
             * @function getTypeUrl
             * @memberof streem.api.CustomTheme
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CustomTheme.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CustomTheme";
            };

            return CustomTheme;
        })();

        api.Tags = (function() {

            /**
             * Constructs a new Tags service.
             * @memberof streem.api
             * @classdesc Represents a Tags
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Tags(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Tags.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Tags;

            /**
             * Creates new Tags service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Tags
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Tags} RPC service. Useful where requests and/or responses are streamed.
             */
            Tags.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Tags#listTagDefinitions}.
             * @memberof streem.api.Tags
             * @typedef ListTagDefinitionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListTagDefinitionsResponse} [response] ListTagDefinitionsResponse
             */

            /**
             * Calls ListTagDefinitions.
             * @function listTagDefinitions
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.IListTagDefinitionsRequest} request ListTagDefinitionsRequest message or plain object
             * @param {streem.api.Tags.ListTagDefinitionsCallback} callback Node-style callback called with the error, if any, and ListTagDefinitionsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tags.prototype.listTagDefinitions = function listTagDefinitions(request, callback) {
                return this.rpcCall(listTagDefinitions, $root.streem.api.ListTagDefinitionsRequest, $root.streem.api.ListTagDefinitionsResponse, request, callback);
            }, "name", { value: "ListTagDefinitions" });

            /**
             * Calls ListTagDefinitions.
             * @function listTagDefinitions
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.IListTagDefinitionsRequest} request ListTagDefinitionsRequest message or plain object
             * @returns {Promise<streem.api.ListTagDefinitionsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Tags#getTagDefinition}.
             * @memberof streem.api.Tags
             * @typedef GetTagDefinitionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetTagDefinitionResponse} [response] GetTagDefinitionResponse
             */

            /**
             * Calls GetTagDefinition.
             * @function getTagDefinition
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.IGetTagDefinitionRequest} request GetTagDefinitionRequest message or plain object
             * @param {streem.api.Tags.GetTagDefinitionCallback} callback Node-style callback called with the error, if any, and GetTagDefinitionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tags.prototype.getTagDefinition = function getTagDefinition(request, callback) {
                return this.rpcCall(getTagDefinition, $root.streem.api.GetTagDefinitionRequest, $root.streem.api.GetTagDefinitionResponse, request, callback);
            }, "name", { value: "GetTagDefinition" });

            /**
             * Calls GetTagDefinition.
             * @function getTagDefinition
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.IGetTagDefinitionRequest} request GetTagDefinitionRequest message or plain object
             * @returns {Promise<streem.api.GetTagDefinitionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Tags#saveTagDefinition}.
             * @memberof streem.api.Tags
             * @typedef SaveTagDefinitionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveTagDefinitionResponse} [response] SaveTagDefinitionResponse
             */

            /**
             * Calls SaveTagDefinition.
             * @function saveTagDefinition
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.ISaveTagDefinitionRequest} request SaveTagDefinitionRequest message or plain object
             * @param {streem.api.Tags.SaveTagDefinitionCallback} callback Node-style callback called with the error, if any, and SaveTagDefinitionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tags.prototype.saveTagDefinition = function saveTagDefinition(request, callback) {
                return this.rpcCall(saveTagDefinition, $root.streem.api.SaveTagDefinitionRequest, $root.streem.api.SaveTagDefinitionResponse, request, callback);
            }, "name", { value: "SaveTagDefinition" });

            /**
             * Calls SaveTagDefinition.
             * @function saveTagDefinition
             * @memberof streem.api.Tags
             * @instance
             * @param {streem.api.ISaveTagDefinitionRequest} request SaveTagDefinitionRequest message or plain object
             * @returns {Promise<streem.api.SaveTagDefinitionResponse>} Promise
             * @variation 2
             */

            return Tags;
        })();

        api.ListTagDefinitionsRequest = (function() {

            /**
             * Properties of a ListTagDefinitionsRequest.
             * @memberof streem.api
             * @interface IListTagDefinitionsRequest
             * @property {number|null} [pageSize] ListTagDefinitionsRequest pageSize
             * @property {string|null} [pageToken] ListTagDefinitionsRequest pageToken
             */

            /**
             * Constructs a new ListTagDefinitionsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListTagDefinitionsRequest.
             * @implements IListTagDefinitionsRequest
             * @constructor
             * @param {streem.api.IListTagDefinitionsRequest=} [p] Properties to set
             */
            function ListTagDefinitionsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListTagDefinitionsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListTagDefinitionsRequest
             * @instance
             */
            ListTagDefinitionsRequest.prototype.pageSize = 0;

            /**
             * ListTagDefinitionsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListTagDefinitionsRequest
             * @instance
             */
            ListTagDefinitionsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListTagDefinitionsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {streem.api.IListTagDefinitionsRequest=} [properties] Properties to set
             * @returns {streem.api.ListTagDefinitionsRequest} ListTagDefinitionsRequest instance
             */
            ListTagDefinitionsRequest.create = function create(properties) {
                return new ListTagDefinitionsRequest(properties);
            };

            /**
             * Encodes the specified ListTagDefinitionsRequest message. Does not implicitly {@link streem.api.ListTagDefinitionsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {streem.api.IListTagDefinitionsRequest} m ListTagDefinitionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagDefinitionsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(8).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(18).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListTagDefinitionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListTagDefinitionsRequest} ListTagDefinitionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagDefinitionsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListTagDefinitionsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 2: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListTagDefinitionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListTagDefinitionsRequest} ListTagDefinitionsRequest
             */
            ListTagDefinitionsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListTagDefinitionsRequest)
                    return d;
                var m = new $root.streem.api.ListTagDefinitionsRequest();
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListTagDefinitionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {streem.api.ListTagDefinitionsRequest} m ListTagDefinitionsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListTagDefinitionsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListTagDefinitionsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListTagDefinitionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListTagDefinitionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListTagDefinitionsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListTagDefinitionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListTagDefinitionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListTagDefinitionsRequest";
            };

            return ListTagDefinitionsRequest;
        })();

        api.ListTagDefinitionsResponse = (function() {

            /**
             * Properties of a ListTagDefinitionsResponse.
             * @memberof streem.api
             * @interface IListTagDefinitionsResponse
             * @property {Array.<streem.api.ITagDefinition>|null} [tags] ListTagDefinitionsResponse tags
             * @property {string|null} [nextPageToken] ListTagDefinitionsResponse nextPageToken
             * @property {number|null} [totalSize] ListTagDefinitionsResponse totalSize
             */

            /**
             * Constructs a new ListTagDefinitionsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListTagDefinitionsResponse.
             * @implements IListTagDefinitionsResponse
             * @constructor
             * @param {streem.api.IListTagDefinitionsResponse=} [p] Properties to set
             */
            function ListTagDefinitionsResponse(p) {
                this.tags = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListTagDefinitionsResponse tags.
             * @member {Array.<streem.api.ITagDefinition>} tags
             * @memberof streem.api.ListTagDefinitionsResponse
             * @instance
             */
            ListTagDefinitionsResponse.prototype.tags = $util.emptyArray;

            /**
             * ListTagDefinitionsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListTagDefinitionsResponse
             * @instance
             */
            ListTagDefinitionsResponse.prototype.nextPageToken = "";

            /**
             * ListTagDefinitionsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListTagDefinitionsResponse
             * @instance
             */
            ListTagDefinitionsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListTagDefinitionsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {streem.api.IListTagDefinitionsResponse=} [properties] Properties to set
             * @returns {streem.api.ListTagDefinitionsResponse} ListTagDefinitionsResponse instance
             */
            ListTagDefinitionsResponse.create = function create(properties) {
                return new ListTagDefinitionsResponse(properties);
            };

            /**
             * Encodes the specified ListTagDefinitionsResponse message. Does not implicitly {@link streem.api.ListTagDefinitionsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {streem.api.IListTagDefinitionsResponse} m ListTagDefinitionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagDefinitionsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tags != null && m.tags.length) {
                    for (var i = 0; i < m.tags.length; ++i)
                        $root.streem.api.TagDefinition.encode(m.tags[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListTagDefinitionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListTagDefinitionsResponse} ListTagDefinitionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagDefinitionsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListTagDefinitionsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.tags && m.tags.length))
                                m.tags = [];
                            m.tags.push($root.streem.api.TagDefinition.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListTagDefinitionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListTagDefinitionsResponse} ListTagDefinitionsResponse
             */
            ListTagDefinitionsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListTagDefinitionsResponse)
                    return d;
                var m = new $root.streem.api.ListTagDefinitionsResponse();
                if (d.tags) {
                    if (!Array.isArray(d.tags))
                        throw TypeError(".streem.api.ListTagDefinitionsResponse.tags: array expected");
                    m.tags = [];
                    for (var i = 0; i < d.tags.length; ++i) {
                        if (typeof d.tags[i] !== "object")
                            throw TypeError(".streem.api.ListTagDefinitionsResponse.tags: object expected");
                        m.tags[i] = $root.streem.api.TagDefinition.fromObject(d.tags[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListTagDefinitionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {streem.api.ListTagDefinitionsResponse} m ListTagDefinitionsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListTagDefinitionsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.tags = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.tags && m.tags.length) {
                    d.tags = [];
                    for (var j = 0; j < m.tags.length; ++j) {
                        d.tags[j] = $root.streem.api.TagDefinition.toObject(m.tags[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListTagDefinitionsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListTagDefinitionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListTagDefinitionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListTagDefinitionsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListTagDefinitionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListTagDefinitionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListTagDefinitionsResponse";
            };

            return ListTagDefinitionsResponse;
        })();

        api.GetTagDefinitionRequest = (function() {

            /**
             * Properties of a GetTagDefinitionRequest.
             * @memberof streem.api
             * @interface IGetTagDefinitionRequest
             * @property {string|null} [tagName] GetTagDefinitionRequest tagName
             */

            /**
             * Constructs a new GetTagDefinitionRequest.
             * @memberof streem.api
             * @classdesc Represents a GetTagDefinitionRequest.
             * @implements IGetTagDefinitionRequest
             * @constructor
             * @param {streem.api.IGetTagDefinitionRequest=} [p] Properties to set
             */
            function GetTagDefinitionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetTagDefinitionRequest tagName.
             * @member {string} tagName
             * @memberof streem.api.GetTagDefinitionRequest
             * @instance
             */
            GetTagDefinitionRequest.prototype.tagName = "";

            /**
             * Creates a new GetTagDefinitionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {streem.api.IGetTagDefinitionRequest=} [properties] Properties to set
             * @returns {streem.api.GetTagDefinitionRequest} GetTagDefinitionRequest instance
             */
            GetTagDefinitionRequest.create = function create(properties) {
                return new GetTagDefinitionRequest(properties);
            };

            /**
             * Encodes the specified GetTagDefinitionRequest message. Does not implicitly {@link streem.api.GetTagDefinitionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {streem.api.IGetTagDefinitionRequest} m GetTagDefinitionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTagDefinitionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tagName != null && Object.hasOwnProperty.call(m, "tagName"))
                    w.uint32(10).string(m.tagName);
                return w;
            };

            /**
             * Decodes a GetTagDefinitionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTagDefinitionRequest} GetTagDefinitionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTagDefinitionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTagDefinitionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tagName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTagDefinitionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTagDefinitionRequest} GetTagDefinitionRequest
             */
            GetTagDefinitionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTagDefinitionRequest)
                    return d;
                var m = new $root.streem.api.GetTagDefinitionRequest();
                if (d.tagName != null) {
                    m.tagName = String(d.tagName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetTagDefinitionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {streem.api.GetTagDefinitionRequest} m GetTagDefinitionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTagDefinitionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tagName = "";
                }
                if (m.tagName != null && m.hasOwnProperty("tagName")) {
                    d.tagName = m.tagName;
                }
                return d;
            };

            /**
             * Converts this GetTagDefinitionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetTagDefinitionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTagDefinitionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTagDefinitionRequest
             * @function getTypeUrl
             * @memberof streem.api.GetTagDefinitionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTagDefinitionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTagDefinitionRequest";
            };

            return GetTagDefinitionRequest;
        })();

        api.GetTagDefinitionResponse = (function() {

            /**
             * Properties of a GetTagDefinitionResponse.
             * @memberof streem.api
             * @interface IGetTagDefinitionResponse
             * @property {streem.api.ITagDefinition|null} [tag] GetTagDefinitionResponse tag
             */

            /**
             * Constructs a new GetTagDefinitionResponse.
             * @memberof streem.api
             * @classdesc Represents a GetTagDefinitionResponse.
             * @implements IGetTagDefinitionResponse
             * @constructor
             * @param {streem.api.IGetTagDefinitionResponse=} [p] Properties to set
             */
            function GetTagDefinitionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetTagDefinitionResponse tag.
             * @member {streem.api.ITagDefinition|null|undefined} tag
             * @memberof streem.api.GetTagDefinitionResponse
             * @instance
             */
            GetTagDefinitionResponse.prototype.tag = null;

            /**
             * Creates a new GetTagDefinitionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {streem.api.IGetTagDefinitionResponse=} [properties] Properties to set
             * @returns {streem.api.GetTagDefinitionResponse} GetTagDefinitionResponse instance
             */
            GetTagDefinitionResponse.create = function create(properties) {
                return new GetTagDefinitionResponse(properties);
            };

            /**
             * Encodes the specified GetTagDefinitionResponse message. Does not implicitly {@link streem.api.GetTagDefinitionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {streem.api.IGetTagDefinitionResponse} m GetTagDefinitionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTagDefinitionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tag != null && Object.hasOwnProperty.call(m, "tag"))
                    $root.streem.api.TagDefinition.encode(m.tag, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetTagDefinitionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTagDefinitionResponse} GetTagDefinitionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTagDefinitionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTagDefinitionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tag = $root.streem.api.TagDefinition.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTagDefinitionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTagDefinitionResponse} GetTagDefinitionResponse
             */
            GetTagDefinitionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTagDefinitionResponse)
                    return d;
                var m = new $root.streem.api.GetTagDefinitionResponse();
                if (d.tag != null) {
                    if (typeof d.tag !== "object")
                        throw TypeError(".streem.api.GetTagDefinitionResponse.tag: object expected");
                    m.tag = $root.streem.api.TagDefinition.fromObject(d.tag);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetTagDefinitionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {streem.api.GetTagDefinitionResponse} m GetTagDefinitionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTagDefinitionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tag = null;
                }
                if (m.tag != null && m.hasOwnProperty("tag")) {
                    d.tag = $root.streem.api.TagDefinition.toObject(m.tag, o);
                }
                return d;
            };

            /**
             * Converts this GetTagDefinitionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetTagDefinitionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTagDefinitionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTagDefinitionResponse
             * @function getTypeUrl
             * @memberof streem.api.GetTagDefinitionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTagDefinitionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTagDefinitionResponse";
            };

            return GetTagDefinitionResponse;
        })();

        api.SaveTagDefinitionRequest = (function() {

            /**
             * Properties of a SaveTagDefinitionRequest.
             * @memberof streem.api
             * @interface ISaveTagDefinitionRequest
             * @property {string|null} [tagName] SaveTagDefinitionRequest tagName
             */

            /**
             * Constructs a new SaveTagDefinitionRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveTagDefinitionRequest.
             * @implements ISaveTagDefinitionRequest
             * @constructor
             * @param {streem.api.ISaveTagDefinitionRequest=} [p] Properties to set
             */
            function SaveTagDefinitionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveTagDefinitionRequest tagName.
             * @member {string} tagName
             * @memberof streem.api.SaveTagDefinitionRequest
             * @instance
             */
            SaveTagDefinitionRequest.prototype.tagName = "";

            /**
             * Creates a new SaveTagDefinitionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {streem.api.ISaveTagDefinitionRequest=} [properties] Properties to set
             * @returns {streem.api.SaveTagDefinitionRequest} SaveTagDefinitionRequest instance
             */
            SaveTagDefinitionRequest.create = function create(properties) {
                return new SaveTagDefinitionRequest(properties);
            };

            /**
             * Encodes the specified SaveTagDefinitionRequest message. Does not implicitly {@link streem.api.SaveTagDefinitionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {streem.api.ISaveTagDefinitionRequest} m SaveTagDefinitionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveTagDefinitionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tagName != null && Object.hasOwnProperty.call(m, "tagName"))
                    w.uint32(10).string(m.tagName);
                return w;
            };

            /**
             * Decodes a SaveTagDefinitionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveTagDefinitionRequest} SaveTagDefinitionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveTagDefinitionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveTagDefinitionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tagName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveTagDefinitionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveTagDefinitionRequest} SaveTagDefinitionRequest
             */
            SaveTagDefinitionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveTagDefinitionRequest)
                    return d;
                var m = new $root.streem.api.SaveTagDefinitionRequest();
                if (d.tagName != null) {
                    m.tagName = String(d.tagName);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveTagDefinitionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {streem.api.SaveTagDefinitionRequest} m SaveTagDefinitionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveTagDefinitionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tagName = "";
                }
                if (m.tagName != null && m.hasOwnProperty("tagName")) {
                    d.tagName = m.tagName;
                }
                return d;
            };

            /**
             * Converts this SaveTagDefinitionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveTagDefinitionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveTagDefinitionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveTagDefinitionRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveTagDefinitionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveTagDefinitionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveTagDefinitionRequest";
            };

            return SaveTagDefinitionRequest;
        })();

        api.SaveTagDefinitionResponse = (function() {

            /**
             * Properties of a SaveTagDefinitionResponse.
             * @memberof streem.api
             * @interface ISaveTagDefinitionResponse
             * @property {streem.api.ITagDefinition|null} [tag] SaveTagDefinitionResponse tag
             */

            /**
             * Constructs a new SaveTagDefinitionResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveTagDefinitionResponse.
             * @implements ISaveTagDefinitionResponse
             * @constructor
             * @param {streem.api.ISaveTagDefinitionResponse=} [p] Properties to set
             */
            function SaveTagDefinitionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveTagDefinitionResponse tag.
             * @member {streem.api.ITagDefinition|null|undefined} tag
             * @memberof streem.api.SaveTagDefinitionResponse
             * @instance
             */
            SaveTagDefinitionResponse.prototype.tag = null;

            /**
             * Creates a new SaveTagDefinitionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {streem.api.ISaveTagDefinitionResponse=} [properties] Properties to set
             * @returns {streem.api.SaveTagDefinitionResponse} SaveTagDefinitionResponse instance
             */
            SaveTagDefinitionResponse.create = function create(properties) {
                return new SaveTagDefinitionResponse(properties);
            };

            /**
             * Encodes the specified SaveTagDefinitionResponse message. Does not implicitly {@link streem.api.SaveTagDefinitionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {streem.api.ISaveTagDefinitionResponse} m SaveTagDefinitionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveTagDefinitionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tag != null && Object.hasOwnProperty.call(m, "tag"))
                    $root.streem.api.TagDefinition.encode(m.tag, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveTagDefinitionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveTagDefinitionResponse} SaveTagDefinitionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveTagDefinitionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveTagDefinitionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tag = $root.streem.api.TagDefinition.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveTagDefinitionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveTagDefinitionResponse} SaveTagDefinitionResponse
             */
            SaveTagDefinitionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveTagDefinitionResponse)
                    return d;
                var m = new $root.streem.api.SaveTagDefinitionResponse();
                if (d.tag != null) {
                    if (typeof d.tag !== "object")
                        throw TypeError(".streem.api.SaveTagDefinitionResponse.tag: object expected");
                    m.tag = $root.streem.api.TagDefinition.fromObject(d.tag);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveTagDefinitionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {streem.api.SaveTagDefinitionResponse} m SaveTagDefinitionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveTagDefinitionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tag = null;
                }
                if (m.tag != null && m.hasOwnProperty("tag")) {
                    d.tag = $root.streem.api.TagDefinition.toObject(m.tag, o);
                }
                return d;
            };

            /**
             * Converts this SaveTagDefinitionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveTagDefinitionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveTagDefinitionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveTagDefinitionResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveTagDefinitionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveTagDefinitionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveTagDefinitionResponse";
            };

            return SaveTagDefinitionResponse;
        })();

        api.wall = (function() {

            /**
             * Namespace wall.
             * @memberof streem.api
             * @namespace
             */
            var wall = {};

            wall.Wall = (function() {

                /**
                 * Properties of a Wall.
                 * @memberof streem.api.wall
                 * @interface IWall
                 * @property {string|null} [wallId] Wall wallId
                 * @property {streem.api.wall.WallType|null} [wallType] Wall wallType
                 * @property {string|null} [webhookUrl] Wall webhookUrl
                 * @property {number|null} [webhookTimeoutMs] Wall webhookTimeoutMs
                 */

                /**
                 * Constructs a new Wall.
                 * @memberof streem.api.wall
                 * @classdesc Represents a Wall.
                 * @implements IWall
                 * @constructor
                 * @param {streem.api.wall.IWall=} [p] Properties to set
                 */
                function Wall(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Wall wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.Wall
                 * @instance
                 */
                Wall.prototype.wallId = "";

                /**
                 * Wall wallType.
                 * @member {streem.api.wall.WallType} wallType
                 * @memberof streem.api.wall.Wall
                 * @instance
                 */
                Wall.prototype.wallType = 0;

                /**
                 * Wall webhookUrl.
                 * @member {string} webhookUrl
                 * @memberof streem.api.wall.Wall
                 * @instance
                 */
                Wall.prototype.webhookUrl = "";

                /**
                 * Wall webhookTimeoutMs.
                 * @member {number} webhookTimeoutMs
                 * @memberof streem.api.wall.Wall
                 * @instance
                 */
                Wall.prototype.webhookTimeoutMs = 0;

                /**
                 * Creates a new Wall instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {streem.api.wall.IWall=} [properties] Properties to set
                 * @returns {streem.api.wall.Wall} Wall instance
                 */
                Wall.create = function create(properties) {
                    return new Wall(properties);
                };

                /**
                 * Encodes the specified Wall message. Does not implicitly {@link streem.api.wall.Wall.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {streem.api.wall.IWall} m Wall message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Wall.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.wallType != null && Object.hasOwnProperty.call(m, "wallType"))
                        w.uint32(16).int32(m.wallType);
                    if (m.webhookUrl != null && Object.hasOwnProperty.call(m, "webhookUrl"))
                        w.uint32(26).string(m.webhookUrl);
                    if (m.webhookTimeoutMs != null && Object.hasOwnProperty.call(m, "webhookTimeoutMs"))
                        w.uint32(32).int32(m.webhookTimeoutMs);
                    return w;
                };

                /**
                 * Decodes a Wall message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.Wall} Wall
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Wall.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.Wall();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.wallType = r.int32();
                                break;
                            }
                        case 3: {
                                m.webhookUrl = r.string();
                                break;
                            }
                        case 4: {
                                m.webhookTimeoutMs = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Wall message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.Wall} Wall
                 */
                Wall.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.Wall)
                        return d;
                    var m = new $root.streem.api.wall.Wall();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    switch (d.wallType) {
                    default:
                        if (typeof d.wallType === "number") {
                            m.wallType = d.wallType;
                            break;
                        }
                        break;
                    case "WALL_TYPE_INVALID":
                    case 0:
                        m.wallType = 0;
                        break;
                    case "WALL_TYPE_ROOM":
                    case 1:
                        m.wallType = 1;
                        break;
                    case "WALL_TYPE_USER":
                    case 2:
                        m.wallType = 2;
                        break;
                    }
                    if (d.webhookUrl != null) {
                        m.webhookUrl = String(d.webhookUrl);
                    }
                    if (d.webhookTimeoutMs != null) {
                        m.webhookTimeoutMs = d.webhookTimeoutMs | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Wall message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {streem.api.wall.Wall} m Wall
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Wall.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.wallType = o.enums === String ? "WALL_TYPE_INVALID" : 0;
                        d.webhookUrl = "";
                        d.webhookTimeoutMs = 0;
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.wallType != null && m.hasOwnProperty("wallType")) {
                        d.wallType = o.enums === String ? $root.streem.api.wall.WallType[m.wallType] === undefined ? m.wallType : $root.streem.api.wall.WallType[m.wallType] : m.wallType;
                    }
                    if (m.webhookUrl != null && m.hasOwnProperty("webhookUrl")) {
                        d.webhookUrl = m.webhookUrl;
                    }
                    if (m.webhookTimeoutMs != null && m.hasOwnProperty("webhookTimeoutMs")) {
                        d.webhookTimeoutMs = m.webhookTimeoutMs;
                    }
                    return d;
                };

                /**
                 * Converts this Wall to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.Wall
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Wall.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Wall
                 * @function getTypeUrl
                 * @memberof streem.api.wall.Wall
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Wall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.Wall";
                };

                return Wall;
            })();

            wall.WallItemEntry = (function() {

                /**
                 * Properties of a WallItemEntry.
                 * @memberof streem.api.wall
                 * @interface IWallItemEntry
                 * @property {string|null} [entryId] WallItemEntry entryId
                 * @property {string|null} [wallId] WallItemEntry wallId
                 * @property {streem.api.wall.WallType|null} [wallType] WallItemEntry wallType
                 * @property {number|null} [version] WallItemEntry version
                 * @property {Uint8Array|null} [wallItemEncoded] WallItemEntry wallItemEncoded
                 * @property {google.protobuf.ITimestamp|null} [expiresAt] WallItemEntry expiresAt
                 */

                /**
                 * Constructs a new WallItemEntry.
                 * @memberof streem.api.wall
                 * @classdesc Represents a WallItemEntry.
                 * @implements IWallItemEntry
                 * @constructor
                 * @param {streem.api.wall.IWallItemEntry=} [p] Properties to set
                 */
                function WallItemEntry(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * WallItemEntry entryId.
                 * @member {string} entryId
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.entryId = "";

                /**
                 * WallItemEntry wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.wallId = "";

                /**
                 * WallItemEntry wallType.
                 * @member {streem.api.wall.WallType} wallType
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.wallType = 0;

                /**
                 * WallItemEntry version.
                 * @member {number} version
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.version = 0;

                /**
                 * WallItemEntry wallItemEncoded.
                 * @member {Uint8Array} wallItemEncoded
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.wallItemEncoded = $util.newBuffer([]);

                /**
                 * WallItemEntry expiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 */
                WallItemEntry.prototype.expiresAt = null;

                /**
                 * Creates a new WallItemEntry instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {streem.api.wall.IWallItemEntry=} [properties] Properties to set
                 * @returns {streem.api.wall.WallItemEntry} WallItemEntry instance
                 */
                WallItemEntry.create = function create(properties) {
                    return new WallItemEntry(properties);
                };

                /**
                 * Encodes the specified WallItemEntry message. Does not implicitly {@link streem.api.wall.WallItemEntry.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {streem.api.wall.IWallItemEntry} m WallItemEntry message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WallItemEntry.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.entryId != null && Object.hasOwnProperty.call(m, "entryId"))
                        w.uint32(10).string(m.entryId);
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(18).string(m.wallId);
                    if (m.wallType != null && Object.hasOwnProperty.call(m, "wallType"))
                        w.uint32(24).int32(m.wallType);
                    if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                        w.uint32(32).int32(m.version);
                    if (m.wallItemEncoded != null && Object.hasOwnProperty.call(m, "wallItemEncoded"))
                        w.uint32(42).bytes(m.wallItemEncoded);
                    if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                        $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(50).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a WallItemEntry message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.WallItemEntry} WallItemEntry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WallItemEntry.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.WallItemEntry();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.entryId = r.string();
                                break;
                            }
                        case 2: {
                                m.wallId = r.string();
                                break;
                            }
                        case 3: {
                                m.wallType = r.int32();
                                break;
                            }
                        case 4: {
                                m.version = r.int32();
                                break;
                            }
                        case 5: {
                                m.wallItemEncoded = r.bytes();
                                break;
                            }
                        case 6: {
                                m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a WallItemEntry message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.WallItemEntry} WallItemEntry
                 */
                WallItemEntry.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.WallItemEntry)
                        return d;
                    var m = new $root.streem.api.wall.WallItemEntry();
                    if (d.entryId != null) {
                        m.entryId = String(d.entryId);
                    }
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    switch (d.wallType) {
                    default:
                        if (typeof d.wallType === "number") {
                            m.wallType = d.wallType;
                            break;
                        }
                        break;
                    case "WALL_TYPE_INVALID":
                    case 0:
                        m.wallType = 0;
                        break;
                    case "WALL_TYPE_ROOM":
                    case 1:
                        m.wallType = 1;
                        break;
                    case "WALL_TYPE_USER":
                    case 2:
                        m.wallType = 2;
                        break;
                    }
                    if (d.version != null) {
                        m.version = d.version | 0;
                    }
                    if (d.wallItemEncoded != null) {
                        if (typeof d.wallItemEncoded === "string")
                            $util.base64.decode(d.wallItemEncoded, m.wallItemEncoded = $util.newBuffer($util.base64.length(d.wallItemEncoded)), 0);
                        else if (d.wallItemEncoded.length >= 0)
                            m.wallItemEncoded = d.wallItemEncoded;
                    }
                    if (d.expiresAt != null) {
                        if (typeof d.expiresAt !== "object")
                            throw TypeError(".streem.api.wall.WallItemEntry.expiresAt: object expected");
                        m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a WallItemEntry message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {streem.api.wall.WallItemEntry} m WallItemEntry
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WallItemEntry.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.entryId = "";
                        d.wallId = "";
                        d.wallType = o.enums === String ? "WALL_TYPE_INVALID" : 0;
                        d.version = 0;
                        if (o.bytes === String)
                            d.wallItemEncoded = "";
                        else {
                            d.wallItemEncoded = [];
                            if (o.bytes !== Array)
                                d.wallItemEncoded = $util.newBuffer(d.wallItemEncoded);
                        }
                        d.expiresAt = null;
                    }
                    if (m.entryId != null && m.hasOwnProperty("entryId")) {
                        d.entryId = m.entryId;
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.wallType != null && m.hasOwnProperty("wallType")) {
                        d.wallType = o.enums === String ? $root.streem.api.wall.WallType[m.wallType] === undefined ? m.wallType : $root.streem.api.wall.WallType[m.wallType] : m.wallType;
                    }
                    if (m.version != null && m.hasOwnProperty("version")) {
                        d.version = m.version;
                    }
                    if (m.wallItemEncoded != null && m.hasOwnProperty("wallItemEncoded")) {
                        d.wallItemEncoded = o.bytes === String ? $util.base64.encode(m.wallItemEncoded, 0, m.wallItemEncoded.length) : o.bytes === Array ? Array.prototype.slice.call(m.wallItemEncoded) : m.wallItemEncoded;
                    }
                    if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                        d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this WallItemEntry to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.WallItemEntry
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WallItemEntry.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WallItemEntry
                 * @function getTypeUrl
                 * @memberof streem.api.wall.WallItemEntry
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WallItemEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.WallItemEntry";
                };

                return WallItemEntry;
            })();

            /**
             * WallType enum.
             * @name streem.api.wall.WallType
             * @enum {number}
             * @property {number} WALL_TYPE_INVALID=0 WALL_TYPE_INVALID value
             * @property {number} WALL_TYPE_ROOM=1 WALL_TYPE_ROOM value
             * @property {number} WALL_TYPE_USER=2 WALL_TYPE_USER value
             */
            wall.WallType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WALL_TYPE_INVALID"] = 0;
                values[valuesById[1] = "WALL_TYPE_ROOM"] = 1;
                values[valuesById[2] = "WALL_TYPE_USER"] = 2;
                return values;
            })();

            wall.WallItemEntryEvent = (function() {

                /**
                 * Properties of a WallItemEntryEvent.
                 * @memberof streem.api.wall
                 * @interface IWallItemEntryEvent
                 * @property {streem.api.wall.IWallItemEntry|null} [wallItemEntry] WallItemEntryEvent wallItemEntry
                 * @property {streem.api.wall.WallItemEntryEvent.EventType|null} [eventType] WallItemEntryEvent eventType
                 * @property {string|null} [connectionId] WallItemEntryEvent connectionId
                 */

                /**
                 * Constructs a new WallItemEntryEvent.
                 * @memberof streem.api.wall
                 * @classdesc Represents a WallItemEntryEvent.
                 * @implements IWallItemEntryEvent
                 * @constructor
                 * @param {streem.api.wall.IWallItemEntryEvent=} [p] Properties to set
                 */
                function WallItemEntryEvent(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * WallItemEntryEvent wallItemEntry.
                 * @member {streem.api.wall.IWallItemEntry|null|undefined} wallItemEntry
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @instance
                 */
                WallItemEntryEvent.prototype.wallItemEntry = null;

                /**
                 * WallItemEntryEvent eventType.
                 * @member {streem.api.wall.WallItemEntryEvent.EventType} eventType
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @instance
                 */
                WallItemEntryEvent.prototype.eventType = 0;

                /**
                 * WallItemEntryEvent connectionId.
                 * @member {string} connectionId
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @instance
                 */
                WallItemEntryEvent.prototype.connectionId = "";

                /**
                 * Creates a new WallItemEntryEvent instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {streem.api.wall.IWallItemEntryEvent=} [properties] Properties to set
                 * @returns {streem.api.wall.WallItemEntryEvent} WallItemEntryEvent instance
                 */
                WallItemEntryEvent.create = function create(properties) {
                    return new WallItemEntryEvent(properties);
                };

                /**
                 * Encodes the specified WallItemEntryEvent message. Does not implicitly {@link streem.api.wall.WallItemEntryEvent.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {streem.api.wall.IWallItemEntryEvent} m WallItemEntryEvent message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WallItemEntryEvent.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallItemEntry != null && Object.hasOwnProperty.call(m, "wallItemEntry"))
                        $root.streem.api.wall.WallItemEntry.encode(m.wallItemEntry, w.uint32(10).fork()).ldelim();
                    if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                        w.uint32(16).int32(m.eventType);
                    if (m.connectionId != null && Object.hasOwnProperty.call(m, "connectionId"))
                        w.uint32(26).string(m.connectionId);
                    return w;
                };

                /**
                 * Decodes a WallItemEntryEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.WallItemEntryEvent} WallItemEntryEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WallItemEntryEvent.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.WallItemEntryEvent();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallItemEntry = $root.streem.api.wall.WallItemEntry.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.eventType = r.int32();
                                break;
                            }
                        case 3: {
                                m.connectionId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a WallItemEntryEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.WallItemEntryEvent} WallItemEntryEvent
                 */
                WallItemEntryEvent.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.WallItemEntryEvent)
                        return d;
                    var m = new $root.streem.api.wall.WallItemEntryEvent();
                    if (d.wallItemEntry != null) {
                        if (typeof d.wallItemEntry !== "object")
                            throw TypeError(".streem.api.wall.WallItemEntryEvent.wallItemEntry: object expected");
                        m.wallItemEntry = $root.streem.api.wall.WallItemEntry.fromObject(d.wallItemEntry);
                    }
                    switch (d.eventType) {
                    default:
                        if (typeof d.eventType === "number") {
                            m.eventType = d.eventType;
                            break;
                        }
                        break;
                    case "EVENT_TYPE_INVALID":
                    case 0:
                        m.eventType = 0;
                        break;
                    case "EVENT_TYPE_ADDED":
                    case 1:
                        m.eventType = 1;
                        break;
                    case "EVENT_TYPE_UPDATED":
                    case 2:
                        m.eventType = 2;
                        break;
                    case "EVENT_TYPE_DELETED":
                    case 3:
                        m.eventType = 3;
                        break;
                    }
                    if (d.connectionId != null) {
                        m.connectionId = String(d.connectionId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a WallItemEntryEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {streem.api.wall.WallItemEntryEvent} m WallItemEntryEvent
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WallItemEntryEvent.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallItemEntry = null;
                        d.eventType = o.enums === String ? "EVENT_TYPE_INVALID" : 0;
                        d.connectionId = "";
                    }
                    if (m.wallItemEntry != null && m.hasOwnProperty("wallItemEntry")) {
                        d.wallItemEntry = $root.streem.api.wall.WallItemEntry.toObject(m.wallItemEntry, o);
                    }
                    if (m.eventType != null && m.hasOwnProperty("eventType")) {
                        d.eventType = o.enums === String ? $root.streem.api.wall.WallItemEntryEvent.EventType[m.eventType] === undefined ? m.eventType : $root.streem.api.wall.WallItemEntryEvent.EventType[m.eventType] : m.eventType;
                    }
                    if (m.connectionId != null && m.hasOwnProperty("connectionId")) {
                        d.connectionId = m.connectionId;
                    }
                    return d;
                };

                /**
                 * Converts this WallItemEntryEvent to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WallItemEntryEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WallItemEntryEvent
                 * @function getTypeUrl
                 * @memberof streem.api.wall.WallItemEntryEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WallItemEntryEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.WallItemEntryEvent";
                };

                /**
                 * EventType enum.
                 * @name streem.api.wall.WallItemEntryEvent.EventType
                 * @enum {number}
                 * @property {number} EVENT_TYPE_INVALID=0 EVENT_TYPE_INVALID value
                 * @property {number} EVENT_TYPE_ADDED=1 EVENT_TYPE_ADDED value
                 * @property {number} EVENT_TYPE_UPDATED=2 EVENT_TYPE_UPDATED value
                 * @property {number} EVENT_TYPE_DELETED=3 EVENT_TYPE_DELETED value
                 */
                WallItemEntryEvent.EventType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "EVENT_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "EVENT_TYPE_ADDED"] = 1;
                    values[valuesById[2] = "EVENT_TYPE_UPDATED"] = 2;
                    values[valuesById[3] = "EVENT_TYPE_DELETED"] = 3;
                    return values;
                })();

                return WallItemEntryEvent;
            })();

            wall.Walls = (function() {

                /**
                 * Constructs a new Walls service.
                 * @memberof streem.api.wall
                 * @classdesc Represents a Walls
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Walls(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Walls.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Walls;

                /**
                 * Creates new Walls service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.wall.Walls
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {Walls} RPC service. Useful where requests and/or responses are streamed.
                 */
                Walls.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.wall.Walls#createWall}.
                 * @memberof streem.api.wall.Walls
                 * @typedef CreateWallCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.CreateWallResponse} [response] CreateWallResponse
                 */

                /**
                 * Calls CreateWall.
                 * @function createWall
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.ICreateWallRequest} request CreateWallRequest message or plain object
                 * @param {streem.api.wall.Walls.CreateWallCallback} callback Node-style callback called with the error, if any, and CreateWallResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.createWall = function createWall(request, callback) {
                    return this.rpcCall(createWall, $root.streem.api.wall.CreateWallRequest, $root.streem.api.wall.CreateWallResponse, request, callback);
                }, "name", { value: "CreateWall" });

                /**
                 * Calls CreateWall.
                 * @function createWall
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.ICreateWallRequest} request CreateWallRequest message or plain object
                 * @returns {Promise<streem.api.wall.CreateWallResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.wall.Walls#getWall}.
                 * @memberof streem.api.wall.Walls
                 * @typedef GetWallCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.GetWallResponse} [response] GetWallResponse
                 */

                /**
                 * Calls GetWall.
                 * @function getWall
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IGetWallRequest} request GetWallRequest message or plain object
                 * @param {streem.api.wall.Walls.GetWallCallback} callback Node-style callback called with the error, if any, and GetWallResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.getWall = function getWall(request, callback) {
                    return this.rpcCall(getWall, $root.streem.api.wall.GetWallRequest, $root.streem.api.wall.GetWallResponse, request, callback);
                }, "name", { value: "GetWall" });

                /**
                 * Calls GetWall.
                 * @function getWall
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IGetWallRequest} request GetWallRequest message or plain object
                 * @returns {Promise<streem.api.wall.GetWallResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.wall.Walls#listWallItemEntries}.
                 * @memberof streem.api.wall.Walls
                 * @typedef ListWallItemEntriesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.ListWallItemEntriesResponse} [response] ListWallItemEntriesResponse
                 */

                /**
                 * Calls ListWallItemEntries.
                 * @function listWallItemEntries
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IListWallItemEntriesRequest} request ListWallItemEntriesRequest message or plain object
                 * @param {streem.api.wall.Walls.ListWallItemEntriesCallback} callback Node-style callback called with the error, if any, and ListWallItemEntriesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.listWallItemEntries = function listWallItemEntries(request, callback) {
                    return this.rpcCall(listWallItemEntries, $root.streem.api.wall.ListWallItemEntriesRequest, $root.streem.api.wall.ListWallItemEntriesResponse, request, callback);
                }, "name", { value: "ListWallItemEntries" });

                /**
                 * Calls ListWallItemEntries.
                 * @function listWallItemEntries
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IListWallItemEntriesRequest} request ListWallItemEntriesRequest message or plain object
                 * @returns {Promise<streem.api.wall.ListWallItemEntriesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.wall.Walls#getWallItemEntry}.
                 * @memberof streem.api.wall.Walls
                 * @typedef GetWallItemEntryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.GetWallItemEntryResponse} [response] GetWallItemEntryResponse
                 */

                /**
                 * Calls GetWallItemEntry.
                 * @function getWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IGetWallItemEntryRequest} request GetWallItemEntryRequest message or plain object
                 * @param {streem.api.wall.Walls.GetWallItemEntryCallback} callback Node-style callback called with the error, if any, and GetWallItemEntryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.getWallItemEntry = function getWallItemEntry(request, callback) {
                    return this.rpcCall(getWallItemEntry, $root.streem.api.wall.GetWallItemEntryRequest, $root.streem.api.wall.GetWallItemEntryResponse, request, callback);
                }, "name", { value: "GetWallItemEntry" });

                /**
                 * Calls GetWallItemEntry.
                 * @function getWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IGetWallItemEntryRequest} request GetWallItemEntryRequest message or plain object
                 * @returns {Promise<streem.api.wall.GetWallItemEntryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.wall.Walls#saveWallItemEntry}.
                 * @memberof streem.api.wall.Walls
                 * @typedef SaveWallItemEntryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.SaveWallItemEntryResponse} [response] SaveWallItemEntryResponse
                 */

                /**
                 * Calls SaveWallItemEntry.
                 * @function saveWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.ISaveWallItemEntryRequest} request SaveWallItemEntryRequest message or plain object
                 * @param {streem.api.wall.Walls.SaveWallItemEntryCallback} callback Node-style callback called with the error, if any, and SaveWallItemEntryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.saveWallItemEntry = function saveWallItemEntry(request, callback) {
                    return this.rpcCall(saveWallItemEntry, $root.streem.api.wall.SaveWallItemEntryRequest, $root.streem.api.wall.SaveWallItemEntryResponse, request, callback);
                }, "name", { value: "SaveWallItemEntry" });

                /**
                 * Calls SaveWallItemEntry.
                 * @function saveWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.ISaveWallItemEntryRequest} request SaveWallItemEntryRequest message or plain object
                 * @returns {Promise<streem.api.wall.SaveWallItemEntryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.wall.Walls#deleteWallItemEntry}.
                 * @memberof streem.api.wall.Walls
                 * @typedef DeleteWallItemEntryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.wall.DeleteWallItemEntryResponse} [response] DeleteWallItemEntryResponse
                 */

                /**
                 * Calls DeleteWallItemEntry.
                 * @function deleteWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IDeleteWallItemEntryRequest} request DeleteWallItemEntryRequest message or plain object
                 * @param {streem.api.wall.Walls.DeleteWallItemEntryCallback} callback Node-style callback called with the error, if any, and DeleteWallItemEntryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Walls.prototype.deleteWallItemEntry = function deleteWallItemEntry(request, callback) {
                    return this.rpcCall(deleteWallItemEntry, $root.streem.api.wall.DeleteWallItemEntryRequest, $root.streem.api.wall.DeleteWallItemEntryResponse, request, callback);
                }, "name", { value: "DeleteWallItemEntry" });

                /**
                 * Calls DeleteWallItemEntry.
                 * @function deleteWallItemEntry
                 * @memberof streem.api.wall.Walls
                 * @instance
                 * @param {streem.api.wall.IDeleteWallItemEntryRequest} request DeleteWallItemEntryRequest message or plain object
                 * @returns {Promise<streem.api.wall.DeleteWallItemEntryResponse>} Promise
                 * @variation 2
                 */

                return Walls;
            })();

            wall.CreateWallRequest = (function() {

                /**
                 * Properties of a CreateWallRequest.
                 * @memberof streem.api.wall
                 * @interface ICreateWallRequest
                 * @property {string|null} [wallId] CreateWallRequest wallId
                 * @property {streem.api.wall.WallType|null} [wallType] CreateWallRequest wallType
                 * @property {string|null} [webhookUrl] CreateWallRequest webhookUrl
                 */

                /**
                 * Constructs a new CreateWallRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a CreateWallRequest.
                 * @implements ICreateWallRequest
                 * @constructor
                 * @param {streem.api.wall.ICreateWallRequest=} [p] Properties to set
                 */
                function CreateWallRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CreateWallRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.CreateWallRequest
                 * @instance
                 */
                CreateWallRequest.prototype.wallId = "";

                /**
                 * CreateWallRequest wallType.
                 * @member {streem.api.wall.WallType} wallType
                 * @memberof streem.api.wall.CreateWallRequest
                 * @instance
                 */
                CreateWallRequest.prototype.wallType = 0;

                /**
                 * CreateWallRequest webhookUrl.
                 * @member {string} webhookUrl
                 * @memberof streem.api.wall.CreateWallRequest
                 * @instance
                 */
                CreateWallRequest.prototype.webhookUrl = "";

                /**
                 * Creates a new CreateWallRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {streem.api.wall.ICreateWallRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.CreateWallRequest} CreateWallRequest instance
                 */
                CreateWallRequest.create = function create(properties) {
                    return new CreateWallRequest(properties);
                };

                /**
                 * Encodes the specified CreateWallRequest message. Does not implicitly {@link streem.api.wall.CreateWallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {streem.api.wall.ICreateWallRequest} m CreateWallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWallRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.wallType != null && Object.hasOwnProperty.call(m, "wallType"))
                        w.uint32(16).int32(m.wallType);
                    if (m.webhookUrl != null && Object.hasOwnProperty.call(m, "webhookUrl"))
                        w.uint32(26).string(m.webhookUrl);
                    return w;
                };

                /**
                 * Decodes a CreateWallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.CreateWallRequest} CreateWallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWallRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.CreateWallRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.wallType = r.int32();
                                break;
                            }
                        case 3: {
                                m.webhookUrl = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CreateWallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.CreateWallRequest} CreateWallRequest
                 */
                CreateWallRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.CreateWallRequest)
                        return d;
                    var m = new $root.streem.api.wall.CreateWallRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    switch (d.wallType) {
                    default:
                        if (typeof d.wallType === "number") {
                            m.wallType = d.wallType;
                            break;
                        }
                        break;
                    case "WALL_TYPE_INVALID":
                    case 0:
                        m.wallType = 0;
                        break;
                    case "WALL_TYPE_ROOM":
                    case 1:
                        m.wallType = 1;
                        break;
                    case "WALL_TYPE_USER":
                    case 2:
                        m.wallType = 2;
                        break;
                    }
                    if (d.webhookUrl != null) {
                        m.webhookUrl = String(d.webhookUrl);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CreateWallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {streem.api.wall.CreateWallRequest} m CreateWallRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWallRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.wallType = o.enums === String ? "WALL_TYPE_INVALID" : 0;
                        d.webhookUrl = "";
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.wallType != null && m.hasOwnProperty("wallType")) {
                        d.wallType = o.enums === String ? $root.streem.api.wall.WallType[m.wallType] === undefined ? m.wallType : $root.streem.api.wall.WallType[m.wallType] : m.wallType;
                    }
                    if (m.webhookUrl != null && m.hasOwnProperty("webhookUrl")) {
                        d.webhookUrl = m.webhookUrl;
                    }
                    return d;
                };

                /**
                 * Converts this CreateWallRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.CreateWallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateWallRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.CreateWallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateWallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.CreateWallRequest";
                };

                return CreateWallRequest;
            })();

            wall.CreateWallResponse = (function() {

                /**
                 * Properties of a CreateWallResponse.
                 * @memberof streem.api.wall
                 * @interface ICreateWallResponse
                 * @property {streem.api.wall.IWall|null} [wall] CreateWallResponse wall
                 */

                /**
                 * Constructs a new CreateWallResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a CreateWallResponse.
                 * @implements ICreateWallResponse
                 * @constructor
                 * @param {streem.api.wall.ICreateWallResponse=} [p] Properties to set
                 */
                function CreateWallResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CreateWallResponse wall.
                 * @member {streem.api.wall.IWall|null|undefined} wall
                 * @memberof streem.api.wall.CreateWallResponse
                 * @instance
                 */
                CreateWallResponse.prototype.wall = null;

                /**
                 * Creates a new CreateWallResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {streem.api.wall.ICreateWallResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.CreateWallResponse} CreateWallResponse instance
                 */
                CreateWallResponse.create = function create(properties) {
                    return new CreateWallResponse(properties);
                };

                /**
                 * Encodes the specified CreateWallResponse message. Does not implicitly {@link streem.api.wall.CreateWallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {streem.api.wall.ICreateWallResponse} m CreateWallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWallResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wall != null && Object.hasOwnProperty.call(m, "wall"))
                        $root.streem.api.wall.Wall.encode(m.wall, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a CreateWallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.CreateWallResponse} CreateWallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWallResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.CreateWallResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wall = $root.streem.api.wall.Wall.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CreateWallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.CreateWallResponse} CreateWallResponse
                 */
                CreateWallResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.CreateWallResponse)
                        return d;
                    var m = new $root.streem.api.wall.CreateWallResponse();
                    if (d.wall != null) {
                        if (typeof d.wall !== "object")
                            throw TypeError(".streem.api.wall.CreateWallResponse.wall: object expected");
                        m.wall = $root.streem.api.wall.Wall.fromObject(d.wall);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CreateWallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {streem.api.wall.CreateWallResponse} m CreateWallResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWallResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wall = null;
                    }
                    if (m.wall != null && m.hasOwnProperty("wall")) {
                        d.wall = $root.streem.api.wall.Wall.toObject(m.wall, o);
                    }
                    return d;
                };

                /**
                 * Converts this CreateWallResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.CreateWallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateWallResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.CreateWallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateWallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.CreateWallResponse";
                };

                return CreateWallResponse;
            })();

            wall.GetWallRequest = (function() {

                /**
                 * Properties of a GetWallRequest.
                 * @memberof streem.api.wall
                 * @interface IGetWallRequest
                 * @property {string|null} [wallId] GetWallRequest wallId
                 */

                /**
                 * Constructs a new GetWallRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a GetWallRequest.
                 * @implements IGetWallRequest
                 * @constructor
                 * @param {streem.api.wall.IGetWallRequest=} [p] Properties to set
                 */
                function GetWallRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetWallRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.GetWallRequest
                 * @instance
                 */
                GetWallRequest.prototype.wallId = "";

                /**
                 * Creates a new GetWallRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {streem.api.wall.IGetWallRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.GetWallRequest} GetWallRequest instance
                 */
                GetWallRequest.create = function create(properties) {
                    return new GetWallRequest(properties);
                };

                /**
                 * Encodes the specified GetWallRequest message. Does not implicitly {@link streem.api.wall.GetWallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {streem.api.wall.IGetWallRequest} m GetWallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWallRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    return w;
                };

                /**
                 * Decodes a GetWallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.GetWallRequest} GetWallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWallRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.GetWallRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetWallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.GetWallRequest} GetWallRequest
                 */
                GetWallRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.GetWallRequest)
                        return d;
                    var m = new $root.streem.api.wall.GetWallRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetWallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {streem.api.wall.GetWallRequest} m GetWallRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWallRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    return d;
                };

                /**
                 * Converts this GetWallRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.GetWallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetWallRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.GetWallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.GetWallRequest";
                };

                return GetWallRequest;
            })();

            wall.GetWallResponse = (function() {

                /**
                 * Properties of a GetWallResponse.
                 * @memberof streem.api.wall
                 * @interface IGetWallResponse
                 * @property {streem.api.wall.IWall|null} [wall] GetWallResponse wall
                 */

                /**
                 * Constructs a new GetWallResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a GetWallResponse.
                 * @implements IGetWallResponse
                 * @constructor
                 * @param {streem.api.wall.IGetWallResponse=} [p] Properties to set
                 */
                function GetWallResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetWallResponse wall.
                 * @member {streem.api.wall.IWall|null|undefined} wall
                 * @memberof streem.api.wall.GetWallResponse
                 * @instance
                 */
                GetWallResponse.prototype.wall = null;

                /**
                 * Creates a new GetWallResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {streem.api.wall.IGetWallResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.GetWallResponse} GetWallResponse instance
                 */
                GetWallResponse.create = function create(properties) {
                    return new GetWallResponse(properties);
                };

                /**
                 * Encodes the specified GetWallResponse message. Does not implicitly {@link streem.api.wall.GetWallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {streem.api.wall.IGetWallResponse} m GetWallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWallResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wall != null && Object.hasOwnProperty.call(m, "wall"))
                        $root.streem.api.wall.Wall.encode(m.wall, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetWallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.GetWallResponse} GetWallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWallResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.GetWallResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wall = $root.streem.api.wall.Wall.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetWallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.GetWallResponse} GetWallResponse
                 */
                GetWallResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.GetWallResponse)
                        return d;
                    var m = new $root.streem.api.wall.GetWallResponse();
                    if (d.wall != null) {
                        if (typeof d.wall !== "object")
                            throw TypeError(".streem.api.wall.GetWallResponse.wall: object expected");
                        m.wall = $root.streem.api.wall.Wall.fromObject(d.wall);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetWallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {streem.api.wall.GetWallResponse} m GetWallResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWallResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wall = null;
                    }
                    if (m.wall != null && m.hasOwnProperty("wall")) {
                        d.wall = $root.streem.api.wall.Wall.toObject(m.wall, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetWallResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.GetWallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetWallResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.GetWallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.GetWallResponse";
                };

                return GetWallResponse;
            })();

            wall.ListWallItemEntriesRequest = (function() {

                /**
                 * Properties of a ListWallItemEntriesRequest.
                 * @memberof streem.api.wall
                 * @interface IListWallItemEntriesRequest
                 * @property {string|null} [wallId] ListWallItemEntriesRequest wallId
                 * @property {string|null} [pageToken] ListWallItemEntriesRequest pageToken
                 * @property {number|null} [pageSize] ListWallItemEntriesRequest pageSize
                 */

                /**
                 * Constructs a new ListWallItemEntriesRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a ListWallItemEntriesRequest.
                 * @implements IListWallItemEntriesRequest
                 * @constructor
                 * @param {streem.api.wall.IListWallItemEntriesRequest=} [p] Properties to set
                 */
                function ListWallItemEntriesRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListWallItemEntriesRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @instance
                 */
                ListWallItemEntriesRequest.prototype.wallId = "";

                /**
                 * ListWallItemEntriesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @instance
                 */
                ListWallItemEntriesRequest.prototype.pageToken = "";

                /**
                 * ListWallItemEntriesRequest pageSize.
                 * @member {number} pageSize
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @instance
                 */
                ListWallItemEntriesRequest.prototype.pageSize = 0;

                /**
                 * Creates a new ListWallItemEntriesRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {streem.api.wall.IListWallItemEntriesRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.ListWallItemEntriesRequest} ListWallItemEntriesRequest instance
                 */
                ListWallItemEntriesRequest.create = function create(properties) {
                    return new ListWallItemEntriesRequest(properties);
                };

                /**
                 * Encodes the specified ListWallItemEntriesRequest message. Does not implicitly {@link streem.api.wall.ListWallItemEntriesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {streem.api.wall.IListWallItemEntriesRequest} m ListWallItemEntriesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListWallItemEntriesRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                        w.uint32(18).string(m.pageToken);
                    if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                        w.uint32(24).int32(m.pageSize);
                    return w;
                };

                /**
                 * Decodes a ListWallItemEntriesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.ListWallItemEntriesRequest} ListWallItemEntriesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListWallItemEntriesRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.ListWallItemEntriesRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.pageToken = r.string();
                                break;
                            }
                        case 3: {
                                m.pageSize = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListWallItemEntriesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.ListWallItemEntriesRequest} ListWallItemEntriesRequest
                 */
                ListWallItemEntriesRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.ListWallItemEntriesRequest)
                        return d;
                    var m = new $root.streem.api.wall.ListWallItemEntriesRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    if (d.pageToken != null) {
                        m.pageToken = String(d.pageToken);
                    }
                    if (d.pageSize != null) {
                        m.pageSize = d.pageSize | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListWallItemEntriesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {streem.api.wall.ListWallItemEntriesRequest} m ListWallItemEntriesRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWallItemEntriesRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.pageToken = "";
                        d.pageSize = 0;
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                        d.pageToken = m.pageToken;
                    }
                    if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                        d.pageSize = m.pageSize;
                    }
                    return d;
                };

                /**
                 * Converts this ListWallItemEntriesRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWallItemEntriesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListWallItemEntriesRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.ListWallItemEntriesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListWallItemEntriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.ListWallItemEntriesRequest";
                };

                return ListWallItemEntriesRequest;
            })();

            wall.ListWallItemEntriesResponse = (function() {

                /**
                 * Properties of a ListWallItemEntriesResponse.
                 * @memberof streem.api.wall
                 * @interface IListWallItemEntriesResponse
                 * @property {Array.<streem.api.wall.IWallItemEntry>|null} [wallItemEntries] ListWallItemEntriesResponse wallItemEntries
                 * @property {string|null} [nextPageToken] ListWallItemEntriesResponse nextPageToken
                 * @property {number|null} [totalSize] ListWallItemEntriesResponse totalSize
                 */

                /**
                 * Constructs a new ListWallItemEntriesResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a ListWallItemEntriesResponse.
                 * @implements IListWallItemEntriesResponse
                 * @constructor
                 * @param {streem.api.wall.IListWallItemEntriesResponse=} [p] Properties to set
                 */
                function ListWallItemEntriesResponse(p) {
                    this.wallItemEntries = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListWallItemEntriesResponse wallItemEntries.
                 * @member {Array.<streem.api.wall.IWallItemEntry>} wallItemEntries
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @instance
                 */
                ListWallItemEntriesResponse.prototype.wallItemEntries = $util.emptyArray;

                /**
                 * ListWallItemEntriesResponse nextPageToken.
                 * @member {string} nextPageToken
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @instance
                 */
                ListWallItemEntriesResponse.prototype.nextPageToken = "";

                /**
                 * ListWallItemEntriesResponse totalSize.
                 * @member {number} totalSize
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @instance
                 */
                ListWallItemEntriesResponse.prototype.totalSize = 0;

                /**
                 * Creates a new ListWallItemEntriesResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {streem.api.wall.IListWallItemEntriesResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.ListWallItemEntriesResponse} ListWallItemEntriesResponse instance
                 */
                ListWallItemEntriesResponse.create = function create(properties) {
                    return new ListWallItemEntriesResponse(properties);
                };

                /**
                 * Encodes the specified ListWallItemEntriesResponse message. Does not implicitly {@link streem.api.wall.ListWallItemEntriesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {streem.api.wall.IListWallItemEntriesResponse} m ListWallItemEntriesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListWallItemEntriesResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallItemEntries != null && m.wallItemEntries.length) {
                        for (var i = 0; i < m.wallItemEntries.length; ++i)
                            $root.streem.api.wall.WallItemEntry.encode(m.wallItemEntries[i], w.uint32(10).fork()).ldelim();
                    }
                    if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                        w.uint32(18).string(m.nextPageToken);
                    if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                        w.uint32(24).int32(m.totalSize);
                    return w;
                };

                /**
                 * Decodes a ListWallItemEntriesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.ListWallItemEntriesResponse} ListWallItemEntriesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListWallItemEntriesResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.ListWallItemEntriesResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.wallItemEntries && m.wallItemEntries.length))
                                    m.wallItemEntries = [];
                                m.wallItemEntries.push($root.streem.api.wall.WallItemEntry.decode(r, r.uint32()));
                                break;
                            }
                        case 2: {
                                m.nextPageToken = r.string();
                                break;
                            }
                        case 3: {
                                m.totalSize = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListWallItemEntriesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.ListWallItemEntriesResponse} ListWallItemEntriesResponse
                 */
                ListWallItemEntriesResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.ListWallItemEntriesResponse)
                        return d;
                    var m = new $root.streem.api.wall.ListWallItemEntriesResponse();
                    if (d.wallItemEntries) {
                        if (!Array.isArray(d.wallItemEntries))
                            throw TypeError(".streem.api.wall.ListWallItemEntriesResponse.wallItemEntries: array expected");
                        m.wallItemEntries = [];
                        for (var i = 0; i < d.wallItemEntries.length; ++i) {
                            if (typeof d.wallItemEntries[i] !== "object")
                                throw TypeError(".streem.api.wall.ListWallItemEntriesResponse.wallItemEntries: object expected");
                            m.wallItemEntries[i] = $root.streem.api.wall.WallItemEntry.fromObject(d.wallItemEntries[i]);
                        }
                    }
                    if (d.nextPageToken != null) {
                        m.nextPageToken = String(d.nextPageToken);
                    }
                    if (d.totalSize != null) {
                        m.totalSize = d.totalSize | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListWallItemEntriesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {streem.api.wall.ListWallItemEntriesResponse} m ListWallItemEntriesResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWallItemEntriesResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.wallItemEntries = [];
                    }
                    if (o.defaults) {
                        d.nextPageToken = "";
                        d.totalSize = 0;
                    }
                    if (m.wallItemEntries && m.wallItemEntries.length) {
                        d.wallItemEntries = [];
                        for (var j = 0; j < m.wallItemEntries.length; ++j) {
                            d.wallItemEntries[j] = $root.streem.api.wall.WallItemEntry.toObject(m.wallItemEntries[j], o);
                        }
                    }
                    if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                        d.nextPageToken = m.nextPageToken;
                    }
                    if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                        d.totalSize = m.totalSize;
                    }
                    return d;
                };

                /**
                 * Converts this ListWallItemEntriesResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWallItemEntriesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListWallItemEntriesResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.ListWallItemEntriesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListWallItemEntriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.ListWallItemEntriesResponse";
                };

                return ListWallItemEntriesResponse;
            })();

            wall.GetWallItemEntryRequest = (function() {

                /**
                 * Properties of a GetWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @interface IGetWallItemEntryRequest
                 * @property {string|null} [wallId] GetWallItemEntryRequest wallId
                 * @property {string|null} [entryId] GetWallItemEntryRequest entryId
                 */

                /**
                 * Constructs a new GetWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a GetWallItemEntryRequest.
                 * @implements IGetWallItemEntryRequest
                 * @constructor
                 * @param {streem.api.wall.IGetWallItemEntryRequest=} [p] Properties to set
                 */
                function GetWallItemEntryRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetWallItemEntryRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @instance
                 */
                GetWallItemEntryRequest.prototype.wallId = "";

                /**
                 * GetWallItemEntryRequest entryId.
                 * @member {string} entryId
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @instance
                 */
                GetWallItemEntryRequest.prototype.entryId = "";

                /**
                 * Creates a new GetWallItemEntryRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.IGetWallItemEntryRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.GetWallItemEntryRequest} GetWallItemEntryRequest instance
                 */
                GetWallItemEntryRequest.create = function create(properties) {
                    return new GetWallItemEntryRequest(properties);
                };

                /**
                 * Encodes the specified GetWallItemEntryRequest message. Does not implicitly {@link streem.api.wall.GetWallItemEntryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.IGetWallItemEntryRequest} m GetWallItemEntryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWallItemEntryRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.entryId != null && Object.hasOwnProperty.call(m, "entryId"))
                        w.uint32(18).string(m.entryId);
                    return w;
                };

                /**
                 * Decodes a GetWallItemEntryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.GetWallItemEntryRequest} GetWallItemEntryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWallItemEntryRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.GetWallItemEntryRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.entryId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetWallItemEntryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.GetWallItemEntryRequest} GetWallItemEntryRequest
                 */
                GetWallItemEntryRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.GetWallItemEntryRequest)
                        return d;
                    var m = new $root.streem.api.wall.GetWallItemEntryRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    if (d.entryId != null) {
                        m.entryId = String(d.entryId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetWallItemEntryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.GetWallItemEntryRequest} m GetWallItemEntryRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWallItemEntryRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.entryId = "";
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.entryId != null && m.hasOwnProperty("entryId")) {
                        d.entryId = m.entryId;
                    }
                    return d;
                };

                /**
                 * Converts this GetWallItemEntryRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWallItemEntryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetWallItemEntryRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.GetWallItemEntryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWallItemEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.GetWallItemEntryRequest";
                };

                return GetWallItemEntryRequest;
            })();

            wall.GetWallItemEntryResponse = (function() {

                /**
                 * Properties of a GetWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @interface IGetWallItemEntryResponse
                 * @property {streem.api.wall.IWallItemEntry|null} [wallItemEntry] GetWallItemEntryResponse wallItemEntry
                 */

                /**
                 * Constructs a new GetWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a GetWallItemEntryResponse.
                 * @implements IGetWallItemEntryResponse
                 * @constructor
                 * @param {streem.api.wall.IGetWallItemEntryResponse=} [p] Properties to set
                 */
                function GetWallItemEntryResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetWallItemEntryResponse wallItemEntry.
                 * @member {streem.api.wall.IWallItemEntry|null|undefined} wallItemEntry
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @instance
                 */
                GetWallItemEntryResponse.prototype.wallItemEntry = null;

                /**
                 * Creates a new GetWallItemEntryResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.IGetWallItemEntryResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.GetWallItemEntryResponse} GetWallItemEntryResponse instance
                 */
                GetWallItemEntryResponse.create = function create(properties) {
                    return new GetWallItemEntryResponse(properties);
                };

                /**
                 * Encodes the specified GetWallItemEntryResponse message. Does not implicitly {@link streem.api.wall.GetWallItemEntryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.IGetWallItemEntryResponse} m GetWallItemEntryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWallItemEntryResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallItemEntry != null && Object.hasOwnProperty.call(m, "wallItemEntry"))
                        $root.streem.api.wall.WallItemEntry.encode(m.wallItemEntry, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetWallItemEntryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.GetWallItemEntryResponse} GetWallItemEntryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWallItemEntryResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.GetWallItemEntryResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallItemEntry = $root.streem.api.wall.WallItemEntry.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetWallItemEntryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.GetWallItemEntryResponse} GetWallItemEntryResponse
                 */
                GetWallItemEntryResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.GetWallItemEntryResponse)
                        return d;
                    var m = new $root.streem.api.wall.GetWallItemEntryResponse();
                    if (d.wallItemEntry != null) {
                        if (typeof d.wallItemEntry !== "object")
                            throw TypeError(".streem.api.wall.GetWallItemEntryResponse.wallItemEntry: object expected");
                        m.wallItemEntry = $root.streem.api.wall.WallItemEntry.fromObject(d.wallItemEntry);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetWallItemEntryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.GetWallItemEntryResponse} m GetWallItemEntryResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWallItemEntryResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallItemEntry = null;
                    }
                    if (m.wallItemEntry != null && m.hasOwnProperty("wallItemEntry")) {
                        d.wallItemEntry = $root.streem.api.wall.WallItemEntry.toObject(m.wallItemEntry, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetWallItemEntryResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWallItemEntryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetWallItemEntryResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.GetWallItemEntryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWallItemEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.GetWallItemEntryResponse";
                };

                return GetWallItemEntryResponse;
            })();

            wall.SaveWallItemEntryRequest = (function() {

                /**
                 * Properties of a SaveWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @interface ISaveWallItemEntryRequest
                 * @property {string|null} [wallId] SaveWallItemEntryRequest wallId
                 * @property {string|null} [entryId] SaveWallItemEntryRequest entryId
                 * @property {string|null} [connectionId] SaveWallItemEntryRequest connectionId
                 * @property {Uint8Array|null} [wallItemEncoded] SaveWallItemEntryRequest wallItemEncoded
                 * @property {google.protobuf.ITimestamp|null} [expiresAt] SaveWallItemEntryRequest expiresAt
                 */

                /**
                 * Constructs a new SaveWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a SaveWallItemEntryRequest.
                 * @implements ISaveWallItemEntryRequest
                 * @constructor
                 * @param {streem.api.wall.ISaveWallItemEntryRequest=} [p] Properties to set
                 */
                function SaveWallItemEntryRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SaveWallItemEntryRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 */
                SaveWallItemEntryRequest.prototype.wallId = "";

                /**
                 * SaveWallItemEntryRequest entryId.
                 * @member {string} entryId
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 */
                SaveWallItemEntryRequest.prototype.entryId = "";

                /**
                 * SaveWallItemEntryRequest connectionId.
                 * @member {string} connectionId
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 */
                SaveWallItemEntryRequest.prototype.connectionId = "";

                /**
                 * SaveWallItemEntryRequest wallItemEncoded.
                 * @member {Uint8Array} wallItemEncoded
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 */
                SaveWallItemEntryRequest.prototype.wallItemEncoded = $util.newBuffer([]);

                /**
                 * SaveWallItemEntryRequest expiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 */
                SaveWallItemEntryRequest.prototype.expiresAt = null;

                /**
                 * Creates a new SaveWallItemEntryRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.ISaveWallItemEntryRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.SaveWallItemEntryRequest} SaveWallItemEntryRequest instance
                 */
                SaveWallItemEntryRequest.create = function create(properties) {
                    return new SaveWallItemEntryRequest(properties);
                };

                /**
                 * Encodes the specified SaveWallItemEntryRequest message. Does not implicitly {@link streem.api.wall.SaveWallItemEntryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.ISaveWallItemEntryRequest} m SaveWallItemEntryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveWallItemEntryRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.entryId != null && Object.hasOwnProperty.call(m, "entryId"))
                        w.uint32(18).string(m.entryId);
                    if (m.connectionId != null && Object.hasOwnProperty.call(m, "connectionId"))
                        w.uint32(26).string(m.connectionId);
                    if (m.wallItemEncoded != null && Object.hasOwnProperty.call(m, "wallItemEncoded"))
                        w.uint32(34).bytes(m.wallItemEncoded);
                    if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                        $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(42).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a SaveWallItemEntryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.SaveWallItemEntryRequest} SaveWallItemEntryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveWallItemEntryRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.SaveWallItemEntryRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.entryId = r.string();
                                break;
                            }
                        case 3: {
                                m.connectionId = r.string();
                                break;
                            }
                        case 4: {
                                m.wallItemEncoded = r.bytes();
                                break;
                            }
                        case 5: {
                                m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SaveWallItemEntryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.SaveWallItemEntryRequest} SaveWallItemEntryRequest
                 */
                SaveWallItemEntryRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.SaveWallItemEntryRequest)
                        return d;
                    var m = new $root.streem.api.wall.SaveWallItemEntryRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    if (d.entryId != null) {
                        m.entryId = String(d.entryId);
                    }
                    if (d.connectionId != null) {
                        m.connectionId = String(d.connectionId);
                    }
                    if (d.wallItemEncoded != null) {
                        if (typeof d.wallItemEncoded === "string")
                            $util.base64.decode(d.wallItemEncoded, m.wallItemEncoded = $util.newBuffer($util.base64.length(d.wallItemEncoded)), 0);
                        else if (d.wallItemEncoded.length >= 0)
                            m.wallItemEncoded = d.wallItemEncoded;
                    }
                    if (d.expiresAt != null) {
                        if (typeof d.expiresAt !== "object")
                            throw TypeError(".streem.api.wall.SaveWallItemEntryRequest.expiresAt: object expected");
                        m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SaveWallItemEntryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.SaveWallItemEntryRequest} m SaveWallItemEntryRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveWallItemEntryRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.entryId = "";
                        d.connectionId = "";
                        if (o.bytes === String)
                            d.wallItemEncoded = "";
                        else {
                            d.wallItemEncoded = [];
                            if (o.bytes !== Array)
                                d.wallItemEncoded = $util.newBuffer(d.wallItemEncoded);
                        }
                        d.expiresAt = null;
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.entryId != null && m.hasOwnProperty("entryId")) {
                        d.entryId = m.entryId;
                    }
                    if (m.connectionId != null && m.hasOwnProperty("connectionId")) {
                        d.connectionId = m.connectionId;
                    }
                    if (m.wallItemEncoded != null && m.hasOwnProperty("wallItemEncoded")) {
                        d.wallItemEncoded = o.bytes === String ? $util.base64.encode(m.wallItemEncoded, 0, m.wallItemEncoded.length) : o.bytes === Array ? Array.prototype.slice.call(m.wallItemEncoded) : m.wallItemEncoded;
                    }
                    if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                        d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this SaveWallItemEntryRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveWallItemEntryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SaveWallItemEntryRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.SaveWallItemEntryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveWallItemEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.SaveWallItemEntryRequest";
                };

                return SaveWallItemEntryRequest;
            })();

            wall.SaveWallItemEntryResponse = (function() {

                /**
                 * Properties of a SaveWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @interface ISaveWallItemEntryResponse
                 * @property {streem.api.wall.IWallItemEntry|null} [wallItemEntry] SaveWallItemEntryResponse wallItemEntry
                 */

                /**
                 * Constructs a new SaveWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a SaveWallItemEntryResponse.
                 * @implements ISaveWallItemEntryResponse
                 * @constructor
                 * @param {streem.api.wall.ISaveWallItemEntryResponse=} [p] Properties to set
                 */
                function SaveWallItemEntryResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SaveWallItemEntryResponse wallItemEntry.
                 * @member {streem.api.wall.IWallItemEntry|null|undefined} wallItemEntry
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @instance
                 */
                SaveWallItemEntryResponse.prototype.wallItemEntry = null;

                /**
                 * Creates a new SaveWallItemEntryResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.ISaveWallItemEntryResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.SaveWallItemEntryResponse} SaveWallItemEntryResponse instance
                 */
                SaveWallItemEntryResponse.create = function create(properties) {
                    return new SaveWallItemEntryResponse(properties);
                };

                /**
                 * Encodes the specified SaveWallItemEntryResponse message. Does not implicitly {@link streem.api.wall.SaveWallItemEntryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.ISaveWallItemEntryResponse} m SaveWallItemEntryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveWallItemEntryResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallItemEntry != null && Object.hasOwnProperty.call(m, "wallItemEntry"))
                        $root.streem.api.wall.WallItemEntry.encode(m.wallItemEntry, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a SaveWallItemEntryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.SaveWallItemEntryResponse} SaveWallItemEntryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveWallItemEntryResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.SaveWallItemEntryResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallItemEntry = $root.streem.api.wall.WallItemEntry.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SaveWallItemEntryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.SaveWallItemEntryResponse} SaveWallItemEntryResponse
                 */
                SaveWallItemEntryResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.SaveWallItemEntryResponse)
                        return d;
                    var m = new $root.streem.api.wall.SaveWallItemEntryResponse();
                    if (d.wallItemEntry != null) {
                        if (typeof d.wallItemEntry !== "object")
                            throw TypeError(".streem.api.wall.SaveWallItemEntryResponse.wallItemEntry: object expected");
                        m.wallItemEntry = $root.streem.api.wall.WallItemEntry.fromObject(d.wallItemEntry);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SaveWallItemEntryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.SaveWallItemEntryResponse} m SaveWallItemEntryResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveWallItemEntryResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallItemEntry = null;
                    }
                    if (m.wallItemEntry != null && m.hasOwnProperty("wallItemEntry")) {
                        d.wallItemEntry = $root.streem.api.wall.WallItemEntry.toObject(m.wallItemEntry, o);
                    }
                    return d;
                };

                /**
                 * Converts this SaveWallItemEntryResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveWallItemEntryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SaveWallItemEntryResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.SaveWallItemEntryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveWallItemEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.SaveWallItemEntryResponse";
                };

                return SaveWallItemEntryResponse;
            })();

            wall.DeleteWallItemEntryRequest = (function() {

                /**
                 * Properties of a DeleteWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @interface IDeleteWallItemEntryRequest
                 * @property {string|null} [wallId] DeleteWallItemEntryRequest wallId
                 * @property {string|null} [entryId] DeleteWallItemEntryRequest entryId
                 * @property {string|null} [connectionId] DeleteWallItemEntryRequest connectionId
                 */

                /**
                 * Constructs a new DeleteWallItemEntryRequest.
                 * @memberof streem.api.wall
                 * @classdesc Represents a DeleteWallItemEntryRequest.
                 * @implements IDeleteWallItemEntryRequest
                 * @constructor
                 * @param {streem.api.wall.IDeleteWallItemEntryRequest=} [p] Properties to set
                 */
                function DeleteWallItemEntryRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * DeleteWallItemEntryRequest wallId.
                 * @member {string} wallId
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @instance
                 */
                DeleteWallItemEntryRequest.prototype.wallId = "";

                /**
                 * DeleteWallItemEntryRequest entryId.
                 * @member {string} entryId
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @instance
                 */
                DeleteWallItemEntryRequest.prototype.entryId = "";

                /**
                 * DeleteWallItemEntryRequest connectionId.
                 * @member {string} connectionId
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @instance
                 */
                DeleteWallItemEntryRequest.prototype.connectionId = "";

                /**
                 * Creates a new DeleteWallItemEntryRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.IDeleteWallItemEntryRequest=} [properties] Properties to set
                 * @returns {streem.api.wall.DeleteWallItemEntryRequest} DeleteWallItemEntryRequest instance
                 */
                DeleteWallItemEntryRequest.create = function create(properties) {
                    return new DeleteWallItemEntryRequest(properties);
                };

                /**
                 * Encodes the specified DeleteWallItemEntryRequest message. Does not implicitly {@link streem.api.wall.DeleteWallItemEntryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.IDeleteWallItemEntryRequest} m DeleteWallItemEntryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWallItemEntryRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.wallId != null && Object.hasOwnProperty.call(m, "wallId"))
                        w.uint32(10).string(m.wallId);
                    if (m.entryId != null && Object.hasOwnProperty.call(m, "entryId"))
                        w.uint32(18).string(m.entryId);
                    if (m.connectionId != null && Object.hasOwnProperty.call(m, "connectionId"))
                        w.uint32(26).string(m.connectionId);
                    return w;
                };

                /**
                 * Decodes a DeleteWallItemEntryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.DeleteWallItemEntryRequest} DeleteWallItemEntryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWallItemEntryRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.DeleteWallItemEntryRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.wallId = r.string();
                                break;
                            }
                        case 2: {
                                m.entryId = r.string();
                                break;
                            }
                        case 3: {
                                m.connectionId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteWallItemEntryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.DeleteWallItemEntryRequest} DeleteWallItemEntryRequest
                 */
                DeleteWallItemEntryRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.DeleteWallItemEntryRequest)
                        return d;
                    var m = new $root.streem.api.wall.DeleteWallItemEntryRequest();
                    if (d.wallId != null) {
                        m.wallId = String(d.wallId);
                    }
                    if (d.entryId != null) {
                        m.entryId = String(d.entryId);
                    }
                    if (d.connectionId != null) {
                        m.connectionId = String(d.connectionId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a DeleteWallItemEntryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {streem.api.wall.DeleteWallItemEntryRequest} m DeleteWallItemEntryRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWallItemEntryRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.wallId = "";
                        d.entryId = "";
                        d.connectionId = "";
                    }
                    if (m.wallId != null && m.hasOwnProperty("wallId")) {
                        d.wallId = m.wallId;
                    }
                    if (m.entryId != null && m.hasOwnProperty("entryId")) {
                        d.entryId = m.entryId;
                    }
                    if (m.connectionId != null && m.hasOwnProperty("connectionId")) {
                        d.connectionId = m.connectionId;
                    }
                    return d;
                };

                /**
                 * Converts this DeleteWallItemEntryRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWallItemEntryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteWallItemEntryRequest
                 * @function getTypeUrl
                 * @memberof streem.api.wall.DeleteWallItemEntryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteWallItemEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.DeleteWallItemEntryRequest";
                };

                return DeleteWallItemEntryRequest;
            })();

            wall.DeleteWallItemEntryResponse = (function() {

                /**
                 * Properties of a DeleteWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @interface IDeleteWallItemEntryResponse
                 */

                /**
                 * Constructs a new DeleteWallItemEntryResponse.
                 * @memberof streem.api.wall
                 * @classdesc Represents a DeleteWallItemEntryResponse.
                 * @implements IDeleteWallItemEntryResponse
                 * @constructor
                 * @param {streem.api.wall.IDeleteWallItemEntryResponse=} [p] Properties to set
                 */
                function DeleteWallItemEntryResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new DeleteWallItemEntryResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.IDeleteWallItemEntryResponse=} [properties] Properties to set
                 * @returns {streem.api.wall.DeleteWallItemEntryResponse} DeleteWallItemEntryResponse instance
                 */
                DeleteWallItemEntryResponse.create = function create(properties) {
                    return new DeleteWallItemEntryResponse(properties);
                };

                /**
                 * Encodes the specified DeleteWallItemEntryResponse message. Does not implicitly {@link streem.api.wall.DeleteWallItemEntryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.IDeleteWallItemEntryResponse} m DeleteWallItemEntryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWallItemEntryResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a DeleteWallItemEntryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.wall.DeleteWallItemEntryResponse} DeleteWallItemEntryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWallItemEntryResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.wall.DeleteWallItemEntryResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteWallItemEntryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.wall.DeleteWallItemEntryResponse} DeleteWallItemEntryResponse
                 */
                DeleteWallItemEntryResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.wall.DeleteWallItemEntryResponse)
                        return d;
                    return new $root.streem.api.wall.DeleteWallItemEntryResponse();
                };

                /**
                 * Creates a plain object from a DeleteWallItemEntryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {streem.api.wall.DeleteWallItemEntryResponse} m DeleteWallItemEntryResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWallItemEntryResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DeleteWallItemEntryResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWallItemEntryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteWallItemEntryResponse
                 * @function getTypeUrl
                 * @memberof streem.api.wall.DeleteWallItemEntryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteWallItemEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.wall.DeleteWallItemEntryResponse";
                };

                return DeleteWallItemEntryResponse;
            })();

            return wall;
        })();

        api.RoomInvitations = (function() {

            /**
             * Constructs a new RoomInvitations service.
             * @memberof streem.api
             * @classdesc Represents a RoomInvitations
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function RoomInvitations(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (RoomInvitations.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RoomInvitations;

            /**
             * Creates new RoomInvitations service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.RoomInvitations
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {RoomInvitations} RPC service. Useful where requests and/or responses are streamed.
             */
            RoomInvitations.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.RoomInvitations#createRoomInvitation}.
             * @memberof streem.api.RoomInvitations
             * @typedef CreateRoomInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomInvitationResponse} [response] CreateRoomInvitationResponse
             */

            /**
             * Calls CreateRoomInvitation.
             * @function createRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.ICreateRoomInvitationRequest} request CreateRoomInvitationRequest message or plain object
             * @param {streem.api.RoomInvitations.CreateRoomInvitationCallback} callback Node-style callback called with the error, if any, and CreateRoomInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomInvitations.prototype.createRoomInvitation = function createRoomInvitation(request, callback) {
                return this.rpcCall(createRoomInvitation, $root.streem.api.CreateRoomInvitationRequest, $root.streem.api.CreateRoomInvitationResponse, request, callback);
            }, "name", { value: "CreateRoomInvitation" });

            /**
             * Calls CreateRoomInvitation.
             * @function createRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.ICreateRoomInvitationRequest} request CreateRoomInvitationRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomInvitations#getRoomInvitation}.
             * @memberof streem.api.RoomInvitations
             * @typedef GetRoomInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomInvitationResponse} [response] GetRoomInvitationResponse
             */

            /**
             * Calls GetRoomInvitation.
             * @function getRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IGetRoomInvitationRequest} request GetRoomInvitationRequest message or plain object
             * @param {streem.api.RoomInvitations.GetRoomInvitationCallback} callback Node-style callback called with the error, if any, and GetRoomInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomInvitations.prototype.getRoomInvitation = function getRoomInvitation(request, callback) {
                return this.rpcCall(getRoomInvitation, $root.streem.api.GetRoomInvitationRequest, $root.streem.api.GetRoomInvitationResponse, request, callback);
            }, "name", { value: "GetRoomInvitation" });

            /**
             * Calls GetRoomInvitation.
             * @function getRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IGetRoomInvitationRequest} request GetRoomInvitationRequest message or plain object
             * @returns {Promise<streem.api.GetRoomInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomInvitations#acceptRoomInvitation}.
             * @memberof streem.api.RoomInvitations
             * @typedef AcceptRoomInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.AcceptRoomInvitationResponse} [response] AcceptRoomInvitationResponse
             */

            /**
             * Calls AcceptRoomInvitation.
             * @function acceptRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IAcceptRoomInvitationRequest} request AcceptRoomInvitationRequest message or plain object
             * @param {streem.api.RoomInvitations.AcceptRoomInvitationCallback} callback Node-style callback called with the error, if any, and AcceptRoomInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomInvitations.prototype.acceptRoomInvitation = function acceptRoomInvitation(request, callback) {
                return this.rpcCall(acceptRoomInvitation, $root.streem.api.AcceptRoomInvitationRequest, $root.streem.api.AcceptRoomInvitationResponse, request, callback);
            }, "name", { value: "AcceptRoomInvitation" });

            /**
             * Calls AcceptRoomInvitation.
             * @function acceptRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IAcceptRoomInvitationRequest} request AcceptRoomInvitationRequest message or plain object
             * @returns {Promise<streem.api.AcceptRoomInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomInvitations#rejectRoomInvitation}.
             * @memberof streem.api.RoomInvitations
             * @typedef RejectRoomInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.RejectRoomInvitationResponse} [response] RejectRoomInvitationResponse
             */

            /**
             * Calls RejectRoomInvitation.
             * @function rejectRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IRejectRoomInvitationRequest} request RejectRoomInvitationRequest message or plain object
             * @param {streem.api.RoomInvitations.RejectRoomInvitationCallback} callback Node-style callback called with the error, if any, and RejectRoomInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomInvitations.prototype.rejectRoomInvitation = function rejectRoomInvitation(request, callback) {
                return this.rpcCall(rejectRoomInvitation, $root.streem.api.RejectRoomInvitationRequest, $root.streem.api.RejectRoomInvitationResponse, request, callback);
            }, "name", { value: "RejectRoomInvitation" });

            /**
             * Calls RejectRoomInvitation.
             * @function rejectRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.IRejectRoomInvitationRequest} request RejectRoomInvitationRequest message or plain object
             * @returns {Promise<streem.api.RejectRoomInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomInvitations#cancelRoomInvitation}.
             * @memberof streem.api.RoomInvitations
             * @typedef CancelRoomInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CancelRoomInvitationResponse} [response] CancelRoomInvitationResponse
             */

            /**
             * Calls CancelRoomInvitation.
             * @function cancelRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.ICancelRoomInvitationRequest} request CancelRoomInvitationRequest message or plain object
             * @param {streem.api.RoomInvitations.CancelRoomInvitationCallback} callback Node-style callback called with the error, if any, and CancelRoomInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomInvitations.prototype.cancelRoomInvitation = function cancelRoomInvitation(request, callback) {
                return this.rpcCall(cancelRoomInvitation, $root.streem.api.CancelRoomInvitationRequest, $root.streem.api.CancelRoomInvitationResponse, request, callback);
            }, "name", { value: "CancelRoomInvitation" });

            /**
             * Calls CancelRoomInvitation.
             * @function cancelRoomInvitation
             * @memberof streem.api.RoomInvitations
             * @instance
             * @param {streem.api.ICancelRoomInvitationRequest} request CancelRoomInvitationRequest message or plain object
             * @returns {Promise<streem.api.CancelRoomInvitationResponse>} Promise
             * @variation 2
             */

            return RoomInvitations;
        })();

        api.CreateRoomInvitationRequest = (function() {

            /**
             * Properties of a CreateRoomInvitationRequest.
             * @memberof streem.api
             * @interface ICreateRoomInvitationRequest
             * @property {string|null} [roomSid] CreateRoomInvitationRequest roomSid
             * @property {string|null} [fromUserSid] CreateRoomInvitationRequest fromUserSid
             * @property {string|null} [fromDeviceSid] CreateRoomInvitationRequest fromDeviceSid
             * @property {string|null} [toUserSid] CreateRoomInvitationRequest toUserSid
             */

            /**
             * Constructs a new CreateRoomInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomInvitationRequest.
             * @implements ICreateRoomInvitationRequest
             * @constructor
             * @param {streem.api.ICreateRoomInvitationRequest=} [p] Properties to set
             */
            function CreateRoomInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomInvitationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomInvitationRequest
             * @instance
             */
            CreateRoomInvitationRequest.prototype.roomSid = "";

            /**
             * CreateRoomInvitationRequest fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.CreateRoomInvitationRequest
             * @instance
             */
            CreateRoomInvitationRequest.prototype.fromUserSid = "";

            /**
             * CreateRoomInvitationRequest fromDeviceSid.
             * @member {string} fromDeviceSid
             * @memberof streem.api.CreateRoomInvitationRequest
             * @instance
             */
            CreateRoomInvitationRequest.prototype.fromDeviceSid = "";

            /**
             * CreateRoomInvitationRequest toUserSid.
             * @member {string} toUserSid
             * @memberof streem.api.CreateRoomInvitationRequest
             * @instance
             */
            CreateRoomInvitationRequest.prototype.toUserSid = "";

            /**
             * Creates a new CreateRoomInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {streem.api.ICreateRoomInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomInvitationRequest} CreateRoomInvitationRequest instance
             */
            CreateRoomInvitationRequest.create = function create(properties) {
                return new CreateRoomInvitationRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomInvitationRequest message. Does not implicitly {@link streem.api.CreateRoomInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {streem.api.ICreateRoomInvitationRequest} m CreateRoomInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(18).string(m.fromUserSid);
                if (m.fromDeviceSid != null && Object.hasOwnProperty.call(m, "fromDeviceSid"))
                    w.uint32(26).string(m.fromDeviceSid);
                if (m.toUserSid != null && Object.hasOwnProperty.call(m, "toUserSid"))
                    w.uint32(34).string(m.toUserSid);
                return w;
            };

            /**
             * Decodes a CreateRoomInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomInvitationRequest} CreateRoomInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 3: {
                            m.fromDeviceSid = r.string();
                            break;
                        }
                    case 4: {
                            m.toUserSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomInvitationRequest} CreateRoomInvitationRequest
             */
            CreateRoomInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomInvitationRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomInvitationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.fromDeviceSid != null) {
                    m.fromDeviceSid = String(d.fromDeviceSid);
                }
                if (d.toUserSid != null) {
                    m.toUserSid = String(d.toUserSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {streem.api.CreateRoomInvitationRequest} m CreateRoomInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.fromUserSid = "";
                    d.fromDeviceSid = "";
                    d.toUserSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.fromDeviceSid != null && m.hasOwnProperty("fromDeviceSid")) {
                    d.fromDeviceSid = m.fromDeviceSid;
                }
                if (m.toUserSid != null && m.hasOwnProperty("toUserSid")) {
                    d.toUserSid = m.toUserSid;
                }
                return d;
            };

            /**
             * Converts this CreateRoomInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomInvitationRequest";
            };

            return CreateRoomInvitationRequest;
        })();

        api.CreateRoomInvitationResponse = (function() {

            /**
             * Properties of a CreateRoomInvitationResponse.
             * @memberof streem.api
             * @interface ICreateRoomInvitationResponse
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] CreateRoomInvitationResponse roomInvitation
             */

            /**
             * Constructs a new CreateRoomInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomInvitationResponse.
             * @implements ICreateRoomInvitationResponse
             * @constructor
             * @param {streem.api.ICreateRoomInvitationResponse=} [p] Properties to set
             */
            function CreateRoomInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomInvitationResponse roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.api.CreateRoomInvitationResponse
             * @instance
             */
            CreateRoomInvitationResponse.prototype.roomInvitation = null;

            /**
             * Creates a new CreateRoomInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {streem.api.ICreateRoomInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomInvitationResponse} CreateRoomInvitationResponse instance
             */
            CreateRoomInvitationResponse.create = function create(properties) {
                return new CreateRoomInvitationResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomInvitationResponse message. Does not implicitly {@link streem.api.CreateRoomInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {streem.api.ICreateRoomInvitationResponse} m CreateRoomInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomInvitationResponse} CreateRoomInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomInvitationResponse} CreateRoomInvitationResponse
             */
            CreateRoomInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomInvitationResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomInvitationResponse();
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.api.CreateRoomInvitationResponse.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {streem.api.CreateRoomInvitationResponse} m CreateRoomInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomInvitation = null;
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                }
                return d;
            };

            /**
             * Converts this CreateRoomInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomInvitationResponse";
            };

            return CreateRoomInvitationResponse;
        })();

        api.GetRoomInvitationRequest = (function() {

            /**
             * Properties of a GetRoomInvitationRequest.
             * @memberof streem.api
             * @interface IGetRoomInvitationRequest
             * @property {string|null} [roomSid] GetRoomInvitationRequest roomSid
             * @property {string|null} [invitationSid] GetRoomInvitationRequest invitationSid
             */

            /**
             * Constructs a new GetRoomInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomInvitationRequest.
             * @implements IGetRoomInvitationRequest
             * @constructor
             * @param {streem.api.IGetRoomInvitationRequest=} [p] Properties to set
             */
            function GetRoomInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomInvitationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomInvitationRequest
             * @instance
             */
            GetRoomInvitationRequest.prototype.roomSid = "";

            /**
             * GetRoomInvitationRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.GetRoomInvitationRequest
             * @instance
             */
            GetRoomInvitationRequest.prototype.invitationSid = "";

            /**
             * Creates a new GetRoomInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {streem.api.IGetRoomInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomInvitationRequest} GetRoomInvitationRequest instance
             */
            GetRoomInvitationRequest.create = function create(properties) {
                return new GetRoomInvitationRequest(properties);
            };

            /**
             * Encodes the specified GetRoomInvitationRequest message. Does not implicitly {@link streem.api.GetRoomInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {streem.api.IGetRoomInvitationRequest} m GetRoomInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(18).string(m.invitationSid);
                return w;
            };

            /**
             * Decodes a GetRoomInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomInvitationRequest} GetRoomInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.invitationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomInvitationRequest} GetRoomInvitationRequest
             */
            GetRoomInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomInvitationRequest)
                    return d;
                var m = new $root.streem.api.GetRoomInvitationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {streem.api.GetRoomInvitationRequest} m GetRoomInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.invitationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomInvitationRequest";
            };

            return GetRoomInvitationRequest;
        })();

        api.GetRoomInvitationResponse = (function() {

            /**
             * Properties of a GetRoomInvitationResponse.
             * @memberof streem.api
             * @interface IGetRoomInvitationResponse
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] GetRoomInvitationResponse roomInvitation
             */

            /**
             * Constructs a new GetRoomInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomInvitationResponse.
             * @implements IGetRoomInvitationResponse
             * @constructor
             * @param {streem.api.IGetRoomInvitationResponse=} [p] Properties to set
             */
            function GetRoomInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomInvitationResponse roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.api.GetRoomInvitationResponse
             * @instance
             */
            GetRoomInvitationResponse.prototype.roomInvitation = null;

            /**
             * Creates a new GetRoomInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {streem.api.IGetRoomInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomInvitationResponse} GetRoomInvitationResponse instance
             */
            GetRoomInvitationResponse.create = function create(properties) {
                return new GetRoomInvitationResponse(properties);
            };

            /**
             * Encodes the specified GetRoomInvitationResponse message. Does not implicitly {@link streem.api.GetRoomInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {streem.api.IGetRoomInvitationResponse} m GetRoomInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRoomInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomInvitationResponse} GetRoomInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomInvitationResponse} GetRoomInvitationResponse
             */
            GetRoomInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomInvitationResponse)
                    return d;
                var m = new $root.streem.api.GetRoomInvitationResponse();
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.api.GetRoomInvitationResponse.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {streem.api.GetRoomInvitationResponse} m GetRoomInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomInvitation = null;
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                }
                return d;
            };

            /**
             * Converts this GetRoomInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomInvitationResponse";
            };

            return GetRoomInvitationResponse;
        })();

        api.AcceptRoomInvitationRequest = (function() {

            /**
             * Properties of an AcceptRoomInvitationRequest.
             * @memberof streem.api
             * @interface IAcceptRoomInvitationRequest
             * @property {string|null} [roomSid] AcceptRoomInvitationRequest roomSid
             * @property {string|null} [invitationSid] AcceptRoomInvitationRequest invitationSid
             * @property {string|null} [acceptedByUserSid] AcceptRoomInvitationRequest acceptedByUserSid
             */

            /**
             * Constructs a new AcceptRoomInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents an AcceptRoomInvitationRequest.
             * @implements IAcceptRoomInvitationRequest
             * @constructor
             * @param {streem.api.IAcceptRoomInvitationRequest=} [p] Properties to set
             */
            function AcceptRoomInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcceptRoomInvitationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @instance
             */
            AcceptRoomInvitationRequest.prototype.roomSid = "";

            /**
             * AcceptRoomInvitationRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @instance
             */
            AcceptRoomInvitationRequest.prototype.invitationSid = "";

            /**
             * AcceptRoomInvitationRequest acceptedByUserSid.
             * @member {string} acceptedByUserSid
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @instance
             */
            AcceptRoomInvitationRequest.prototype.acceptedByUserSid = "";

            /**
             * Creates a new AcceptRoomInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {streem.api.IAcceptRoomInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.AcceptRoomInvitationRequest} AcceptRoomInvitationRequest instance
             */
            AcceptRoomInvitationRequest.create = function create(properties) {
                return new AcceptRoomInvitationRequest(properties);
            };

            /**
             * Encodes the specified AcceptRoomInvitationRequest message. Does not implicitly {@link streem.api.AcceptRoomInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {streem.api.IAcceptRoomInvitationRequest} m AcceptRoomInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcceptRoomInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(18).string(m.invitationSid);
                if (m.acceptedByUserSid != null && Object.hasOwnProperty.call(m, "acceptedByUserSid"))
                    w.uint32(26).string(m.acceptedByUserSid);
                return w;
            };

            /**
             * Decodes an AcceptRoomInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcceptRoomInvitationRequest} AcceptRoomInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcceptRoomInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcceptRoomInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.invitationSid = r.string();
                            break;
                        }
                    case 3: {
                            m.acceptedByUserSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcceptRoomInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcceptRoomInvitationRequest} AcceptRoomInvitationRequest
             */
            AcceptRoomInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcceptRoomInvitationRequest)
                    return d;
                var m = new $root.streem.api.AcceptRoomInvitationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                if (d.acceptedByUserSid != null) {
                    m.acceptedByUserSid = String(d.acceptedByUserSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcceptRoomInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {streem.api.AcceptRoomInvitationRequest} m AcceptRoomInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcceptRoomInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.invitationSid = "";
                    d.acceptedByUserSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                if (m.acceptedByUserSid != null && m.hasOwnProperty("acceptedByUserSid")) {
                    d.acceptedByUserSid = m.acceptedByUserSid;
                }
                return d;
            };

            /**
             * Converts this AcceptRoomInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcceptRoomInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcceptRoomInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.AcceptRoomInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcceptRoomInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcceptRoomInvitationRequest";
            };

            return AcceptRoomInvitationRequest;
        })();

        api.AcceptRoomInvitationResponse = (function() {

            /**
             * Properties of an AcceptRoomInvitationResponse.
             * @memberof streem.api
             * @interface IAcceptRoomInvitationResponse
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] AcceptRoomInvitationResponse roomInvitation
             */

            /**
             * Constructs a new AcceptRoomInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents an AcceptRoomInvitationResponse.
             * @implements IAcceptRoomInvitationResponse
             * @constructor
             * @param {streem.api.IAcceptRoomInvitationResponse=} [p] Properties to set
             */
            function AcceptRoomInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcceptRoomInvitationResponse roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @instance
             */
            AcceptRoomInvitationResponse.prototype.roomInvitation = null;

            /**
             * Creates a new AcceptRoomInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {streem.api.IAcceptRoomInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.AcceptRoomInvitationResponse} AcceptRoomInvitationResponse instance
             */
            AcceptRoomInvitationResponse.create = function create(properties) {
                return new AcceptRoomInvitationResponse(properties);
            };

            /**
             * Encodes the specified AcceptRoomInvitationResponse message. Does not implicitly {@link streem.api.AcceptRoomInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {streem.api.IAcceptRoomInvitationResponse} m AcceptRoomInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcceptRoomInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AcceptRoomInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcceptRoomInvitationResponse} AcceptRoomInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcceptRoomInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcceptRoomInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcceptRoomInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcceptRoomInvitationResponse} AcceptRoomInvitationResponse
             */
            AcceptRoomInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcceptRoomInvitationResponse)
                    return d;
                var m = new $root.streem.api.AcceptRoomInvitationResponse();
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.api.AcceptRoomInvitationResponse.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcceptRoomInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {streem.api.AcceptRoomInvitationResponse} m AcceptRoomInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcceptRoomInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomInvitation = null;
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                }
                return d;
            };

            /**
             * Converts this AcceptRoomInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcceptRoomInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcceptRoomInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.AcceptRoomInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcceptRoomInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcceptRoomInvitationResponse";
            };

            return AcceptRoomInvitationResponse;
        })();

        api.RejectRoomInvitationRequest = (function() {

            /**
             * Properties of a RejectRoomInvitationRequest.
             * @memberof streem.api
             * @interface IRejectRoomInvitationRequest
             * @property {string|null} [roomSid] RejectRoomInvitationRequest roomSid
             * @property {string|null} [invitationSid] RejectRoomInvitationRequest invitationSid
             * @property {string|null} [rejectedByUserSid] RejectRoomInvitationRequest rejectedByUserSid
             */

            /**
             * Constructs a new RejectRoomInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a RejectRoomInvitationRequest.
             * @implements IRejectRoomInvitationRequest
             * @constructor
             * @param {streem.api.IRejectRoomInvitationRequest=} [p] Properties to set
             */
            function RejectRoomInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RejectRoomInvitationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RejectRoomInvitationRequest
             * @instance
             */
            RejectRoomInvitationRequest.prototype.roomSid = "";

            /**
             * RejectRoomInvitationRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.RejectRoomInvitationRequest
             * @instance
             */
            RejectRoomInvitationRequest.prototype.invitationSid = "";

            /**
             * RejectRoomInvitationRequest rejectedByUserSid.
             * @member {string} rejectedByUserSid
             * @memberof streem.api.RejectRoomInvitationRequest
             * @instance
             */
            RejectRoomInvitationRequest.prototype.rejectedByUserSid = "";

            /**
             * Creates a new RejectRoomInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {streem.api.IRejectRoomInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.RejectRoomInvitationRequest} RejectRoomInvitationRequest instance
             */
            RejectRoomInvitationRequest.create = function create(properties) {
                return new RejectRoomInvitationRequest(properties);
            };

            /**
             * Encodes the specified RejectRoomInvitationRequest message. Does not implicitly {@link streem.api.RejectRoomInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {streem.api.IRejectRoomInvitationRequest} m RejectRoomInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RejectRoomInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(18).string(m.invitationSid);
                if (m.rejectedByUserSid != null && Object.hasOwnProperty.call(m, "rejectedByUserSid"))
                    w.uint32(26).string(m.rejectedByUserSid);
                return w;
            };

            /**
             * Decodes a RejectRoomInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RejectRoomInvitationRequest} RejectRoomInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RejectRoomInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RejectRoomInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.invitationSid = r.string();
                            break;
                        }
                    case 3: {
                            m.rejectedByUserSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RejectRoomInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RejectRoomInvitationRequest} RejectRoomInvitationRequest
             */
            RejectRoomInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RejectRoomInvitationRequest)
                    return d;
                var m = new $root.streem.api.RejectRoomInvitationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                if (d.rejectedByUserSid != null) {
                    m.rejectedByUserSid = String(d.rejectedByUserSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a RejectRoomInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {streem.api.RejectRoomInvitationRequest} m RejectRoomInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RejectRoomInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.invitationSid = "";
                    d.rejectedByUserSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                if (m.rejectedByUserSid != null && m.hasOwnProperty("rejectedByUserSid")) {
                    d.rejectedByUserSid = m.rejectedByUserSid;
                }
                return d;
            };

            /**
             * Converts this RejectRoomInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RejectRoomInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RejectRoomInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RejectRoomInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.RejectRoomInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RejectRoomInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RejectRoomInvitationRequest";
            };

            return RejectRoomInvitationRequest;
        })();

        api.RejectRoomInvitationResponse = (function() {

            /**
             * Properties of a RejectRoomInvitationResponse.
             * @memberof streem.api
             * @interface IRejectRoomInvitationResponse
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] RejectRoomInvitationResponse roomInvitation
             */

            /**
             * Constructs a new RejectRoomInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a RejectRoomInvitationResponse.
             * @implements IRejectRoomInvitationResponse
             * @constructor
             * @param {streem.api.IRejectRoomInvitationResponse=} [p] Properties to set
             */
            function RejectRoomInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RejectRoomInvitationResponse roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.api.RejectRoomInvitationResponse
             * @instance
             */
            RejectRoomInvitationResponse.prototype.roomInvitation = null;

            /**
             * Creates a new RejectRoomInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {streem.api.IRejectRoomInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.RejectRoomInvitationResponse} RejectRoomInvitationResponse instance
             */
            RejectRoomInvitationResponse.create = function create(properties) {
                return new RejectRoomInvitationResponse(properties);
            };

            /**
             * Encodes the specified RejectRoomInvitationResponse message. Does not implicitly {@link streem.api.RejectRoomInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {streem.api.IRejectRoomInvitationResponse} m RejectRoomInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RejectRoomInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a RejectRoomInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RejectRoomInvitationResponse} RejectRoomInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RejectRoomInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RejectRoomInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RejectRoomInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RejectRoomInvitationResponse} RejectRoomInvitationResponse
             */
            RejectRoomInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RejectRoomInvitationResponse)
                    return d;
                var m = new $root.streem.api.RejectRoomInvitationResponse();
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.api.RejectRoomInvitationResponse.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a RejectRoomInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {streem.api.RejectRoomInvitationResponse} m RejectRoomInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RejectRoomInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomInvitation = null;
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                }
                return d;
            };

            /**
             * Converts this RejectRoomInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.RejectRoomInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RejectRoomInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RejectRoomInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.RejectRoomInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RejectRoomInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RejectRoomInvitationResponse";
            };

            return RejectRoomInvitationResponse;
        })();

        api.CancelRoomInvitationRequest = (function() {

            /**
             * Properties of a CancelRoomInvitationRequest.
             * @memberof streem.api
             * @interface ICancelRoomInvitationRequest
             * @property {string|null} [roomSid] CancelRoomInvitationRequest roomSid
             * @property {string|null} [invitationSid] CancelRoomInvitationRequest invitationSid
             * @property {string|null} [canceledByUserSid] CancelRoomInvitationRequest canceledByUserSid
             */

            /**
             * Constructs a new CancelRoomInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a CancelRoomInvitationRequest.
             * @implements ICancelRoomInvitationRequest
             * @constructor
             * @param {streem.api.ICancelRoomInvitationRequest=} [p] Properties to set
             */
            function CancelRoomInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CancelRoomInvitationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CancelRoomInvitationRequest
             * @instance
             */
            CancelRoomInvitationRequest.prototype.roomSid = "";

            /**
             * CancelRoomInvitationRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.CancelRoomInvitationRequest
             * @instance
             */
            CancelRoomInvitationRequest.prototype.invitationSid = "";

            /**
             * CancelRoomInvitationRequest canceledByUserSid.
             * @member {string} canceledByUserSid
             * @memberof streem.api.CancelRoomInvitationRequest
             * @instance
             */
            CancelRoomInvitationRequest.prototype.canceledByUserSid = "";

            /**
             * Creates a new CancelRoomInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {streem.api.ICancelRoomInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.CancelRoomInvitationRequest} CancelRoomInvitationRequest instance
             */
            CancelRoomInvitationRequest.create = function create(properties) {
                return new CancelRoomInvitationRequest(properties);
            };

            /**
             * Encodes the specified CancelRoomInvitationRequest message. Does not implicitly {@link streem.api.CancelRoomInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {streem.api.ICancelRoomInvitationRequest} m CancelRoomInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelRoomInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(18).string(m.invitationSid);
                if (m.canceledByUserSid != null && Object.hasOwnProperty.call(m, "canceledByUserSid"))
                    w.uint32(26).string(m.canceledByUserSid);
                return w;
            };

            /**
             * Decodes a CancelRoomInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CancelRoomInvitationRequest} CancelRoomInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelRoomInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CancelRoomInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.invitationSid = r.string();
                            break;
                        }
                    case 3: {
                            m.canceledByUserSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CancelRoomInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CancelRoomInvitationRequest} CancelRoomInvitationRequest
             */
            CancelRoomInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CancelRoomInvitationRequest)
                    return d;
                var m = new $root.streem.api.CancelRoomInvitationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                if (d.canceledByUserSid != null) {
                    m.canceledByUserSid = String(d.canceledByUserSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CancelRoomInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {streem.api.CancelRoomInvitationRequest} m CancelRoomInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelRoomInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.invitationSid = "";
                    d.canceledByUserSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                if (m.canceledByUserSid != null && m.hasOwnProperty("canceledByUserSid")) {
                    d.canceledByUserSid = m.canceledByUserSid;
                }
                return d;
            };

            /**
             * Converts this CancelRoomInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CancelRoomInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelRoomInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelRoomInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.CancelRoomInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelRoomInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CancelRoomInvitationRequest";
            };

            return CancelRoomInvitationRequest;
        })();

        api.CancelRoomInvitationResponse = (function() {

            /**
             * Properties of a CancelRoomInvitationResponse.
             * @memberof streem.api
             * @interface ICancelRoomInvitationResponse
             * @property {streem.api.IRoomInvitation|null} [roomInvitation] CancelRoomInvitationResponse roomInvitation
             */

            /**
             * Constructs a new CancelRoomInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a CancelRoomInvitationResponse.
             * @implements ICancelRoomInvitationResponse
             * @constructor
             * @param {streem.api.ICancelRoomInvitationResponse=} [p] Properties to set
             */
            function CancelRoomInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CancelRoomInvitationResponse roomInvitation.
             * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
             * @memberof streem.api.CancelRoomInvitationResponse
             * @instance
             */
            CancelRoomInvitationResponse.prototype.roomInvitation = null;

            /**
             * Creates a new CancelRoomInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {streem.api.ICancelRoomInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.CancelRoomInvitationResponse} CancelRoomInvitationResponse instance
             */
            CancelRoomInvitationResponse.create = function create(properties) {
                return new CancelRoomInvitationResponse(properties);
            };

            /**
             * Encodes the specified CancelRoomInvitationResponse message. Does not implicitly {@link streem.api.CancelRoomInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {streem.api.ICancelRoomInvitationResponse} m CancelRoomInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelRoomInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
                    $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CancelRoomInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CancelRoomInvitationResponse} CancelRoomInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelRoomInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CancelRoomInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CancelRoomInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CancelRoomInvitationResponse} CancelRoomInvitationResponse
             */
            CancelRoomInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CancelRoomInvitationResponse)
                    return d;
                var m = new $root.streem.api.CancelRoomInvitationResponse();
                if (d.roomInvitation != null) {
                    if (typeof d.roomInvitation !== "object")
                        throw TypeError(".streem.api.CancelRoomInvitationResponse.roomInvitation: object expected");
                    m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a CancelRoomInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {streem.api.CancelRoomInvitationResponse} m CancelRoomInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelRoomInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomInvitation = null;
                }
                if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
                    d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
                }
                return d;
            };

            /**
             * Converts this CancelRoomInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CancelRoomInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelRoomInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelRoomInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.CancelRoomInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelRoomInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CancelRoomInvitationResponse";
            };

            return CancelRoomInvitationResponse;
        })();

        api.ArtifactFile = (function() {

            /**
             * Properties of an ArtifactFile.
             * @memberof streem.api
             * @interface IArtifactFile
             * @property {string|null} [storageUrl] ArtifactFile storageUrl
             * @property {streem.api.ArtifactFile.Scope|null} [scope] ArtifactFile scope
             * @property {string|null} [scopeSid] ArtifactFile scopeSid
             * @property {string|null} [artifactSid] ArtifactFile artifactSid
             */

            /**
             * Constructs a new ArtifactFile.
             * @memberof streem.api
             * @classdesc Represents an ArtifactFile.
             * @implements IArtifactFile
             * @constructor
             * @param {streem.api.IArtifactFile=} [p] Properties to set
             */
            function ArtifactFile(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArtifactFile storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.ArtifactFile
             * @instance
             */
            ArtifactFile.prototype.storageUrl = "";

            /**
             * ArtifactFile scope.
             * @member {streem.api.ArtifactFile.Scope} scope
             * @memberof streem.api.ArtifactFile
             * @instance
             */
            ArtifactFile.prototype.scope = 0;

            /**
             * ArtifactFile scopeSid.
             * @member {string} scopeSid
             * @memberof streem.api.ArtifactFile
             * @instance
             */
            ArtifactFile.prototype.scopeSid = "";

            /**
             * ArtifactFile artifactSid.
             * @member {string} artifactSid
             * @memberof streem.api.ArtifactFile
             * @instance
             */
            ArtifactFile.prototype.artifactSid = "";

            /**
             * Creates a new ArtifactFile instance using the specified properties.
             * @function create
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {streem.api.IArtifactFile=} [properties] Properties to set
             * @returns {streem.api.ArtifactFile} ArtifactFile instance
             */
            ArtifactFile.create = function create(properties) {
                return new ArtifactFile(properties);
            };

            /**
             * Encodes the specified ArtifactFile message. Does not implicitly {@link streem.api.ArtifactFile.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {streem.api.IArtifactFile} m ArtifactFile message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArtifactFile.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(10).string(m.storageUrl);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(16).int32(m.scope);
                if (m.scopeSid != null && Object.hasOwnProperty.call(m, "scopeSid"))
                    w.uint32(26).string(m.scopeSid);
                if (m.artifactSid != null && Object.hasOwnProperty.call(m, "artifactSid"))
                    w.uint32(34).string(m.artifactSid);
                return w;
            };

            /**
             * Decodes an ArtifactFile message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArtifactFile} ArtifactFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArtifactFile.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArtifactFile();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.scope = r.int32();
                            break;
                        }
                    case 3: {
                            m.scopeSid = r.string();
                            break;
                        }
                    case 4: {
                            m.artifactSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArtifactFile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArtifactFile} ArtifactFile
             */
            ArtifactFile.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArtifactFile)
                    return d;
                var m = new $root.streem.api.ArtifactFile();
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                switch (d.scope) {
                default:
                    if (typeof d.scope === "number") {
                        m.scope = d.scope;
                        break;
                    }
                    break;
                case "SCOPE_INVALID":
                case 0:
                    m.scope = 0;
                    break;
                case "SCOPE_ROOM":
                case 1:
                    m.scope = 1;
                    break;
                case "SCOPE_COMPANY":
                case 2:
                    m.scope = 2;
                    break;
                }
                if (d.scopeSid != null) {
                    m.scopeSid = String(d.scopeSid);
                }
                if (d.artifactSid != null) {
                    m.artifactSid = String(d.artifactSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an ArtifactFile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {streem.api.ArtifactFile} m ArtifactFile
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArtifactFile.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storageUrl = "";
                    d.scope = o.enums === String ? "SCOPE_INVALID" : 0;
                    d.scopeSid = "";
                    d.artifactSid = "";
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = o.enums === String ? $root.streem.api.ArtifactFile.Scope[m.scope] === undefined ? m.scope : $root.streem.api.ArtifactFile.Scope[m.scope] : m.scope;
                }
                if (m.scopeSid != null && m.hasOwnProperty("scopeSid")) {
                    d.scopeSid = m.scopeSid;
                }
                if (m.artifactSid != null && m.hasOwnProperty("artifactSid")) {
                    d.artifactSid = m.artifactSid;
                }
                return d;
            };

            /**
             * Converts this ArtifactFile to JSON.
             * @function toJSON
             * @memberof streem.api.ArtifactFile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArtifactFile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArtifactFile
             * @function getTypeUrl
             * @memberof streem.api.ArtifactFile
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArtifactFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArtifactFile";
            };

            /**
             * Scope enum.
             * @name streem.api.ArtifactFile.Scope
             * @enum {number}
             * @property {number} SCOPE_INVALID=0 SCOPE_INVALID value
             * @property {number} SCOPE_ROOM=1 SCOPE_ROOM value
             * @property {number} SCOPE_COMPANY=2 SCOPE_COMPANY value
             */
            ArtifactFile.Scope = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SCOPE_INVALID"] = 0;
                values[valuesById[1] = "SCOPE_ROOM"] = 1;
                values[valuesById[2] = "SCOPE_COMPANY"] = 2;
                return values;
            })();

            return ArtifactFile;
        })();

        api.Terms = (function() {

            /**
             * Constructs a new Terms service.
             * @memberof streem.api
             * @classdesc Represents a Terms
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Terms(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Terms.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Terms;

            /**
             * Creates new Terms service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Terms
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Terms} RPC service. Useful where requests and/or responses are streamed.
             */
            Terms.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Terms#getTermsUpdated}.
             * @memberof streem.api.Terms
             * @typedef GetTermsUpdatedCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetTermsUpdatedResponse} [response] GetTermsUpdatedResponse
             */

            /**
             * Calls GetTermsUpdated.
             * @function getTermsUpdated
             * @memberof streem.api.Terms
             * @instance
             * @param {streem.api.IGetTermsUpdatedRequest} request GetTermsUpdatedRequest message or plain object
             * @param {streem.api.Terms.GetTermsUpdatedCallback} callback Node-style callback called with the error, if any, and GetTermsUpdatedResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Terms.prototype.getTermsUpdated = function getTermsUpdated(request, callback) {
                return this.rpcCall(getTermsUpdated, $root.streem.api.GetTermsUpdatedRequest, $root.streem.api.GetTermsUpdatedResponse, request, callback);
            }, "name", { value: "GetTermsUpdated" });

            /**
             * Calls GetTermsUpdated.
             * @function getTermsUpdated
             * @memberof streem.api.Terms
             * @instance
             * @param {streem.api.IGetTermsUpdatedRequest} request GetTermsUpdatedRequest message or plain object
             * @returns {Promise<streem.api.GetTermsUpdatedResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Terms#saveTermsUpdated}.
             * @memberof streem.api.Terms
             * @typedef SaveTermsUpdatedCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveTermsUpdatedResponse} [response] SaveTermsUpdatedResponse
             */

            /**
             * Calls SaveTermsUpdated.
             * @function saveTermsUpdated
             * @memberof streem.api.Terms
             * @instance
             * @param {streem.api.ISaveTermsUpdatedRequest} request SaveTermsUpdatedRequest message or plain object
             * @param {streem.api.Terms.SaveTermsUpdatedCallback} callback Node-style callback called with the error, if any, and SaveTermsUpdatedResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Terms.prototype.saveTermsUpdated = function saveTermsUpdated(request, callback) {
                return this.rpcCall(saveTermsUpdated, $root.streem.api.SaveTermsUpdatedRequest, $root.streem.api.SaveTermsUpdatedResponse, request, callback);
            }, "name", { value: "SaveTermsUpdated" });

            /**
             * Calls SaveTermsUpdated.
             * @function saveTermsUpdated
             * @memberof streem.api.Terms
             * @instance
             * @param {streem.api.ISaveTermsUpdatedRequest} request SaveTermsUpdatedRequest message or plain object
             * @returns {Promise<streem.api.SaveTermsUpdatedResponse>} Promise
             * @variation 2
             */

            return Terms;
        })();

        api.GetTermsUpdatedRequest = (function() {

            /**
             * Properties of a GetTermsUpdatedRequest.
             * @memberof streem.api
             * @interface IGetTermsUpdatedRequest
             */

            /**
             * Constructs a new GetTermsUpdatedRequest.
             * @memberof streem.api
             * @classdesc Represents a GetTermsUpdatedRequest.
             * @implements IGetTermsUpdatedRequest
             * @constructor
             * @param {streem.api.IGetTermsUpdatedRequest=} [p] Properties to set
             */
            function GetTermsUpdatedRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetTermsUpdatedRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {streem.api.IGetTermsUpdatedRequest=} [properties] Properties to set
             * @returns {streem.api.GetTermsUpdatedRequest} GetTermsUpdatedRequest instance
             */
            GetTermsUpdatedRequest.create = function create(properties) {
                return new GetTermsUpdatedRequest(properties);
            };

            /**
             * Encodes the specified GetTermsUpdatedRequest message. Does not implicitly {@link streem.api.GetTermsUpdatedRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {streem.api.IGetTermsUpdatedRequest} m GetTermsUpdatedRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTermsUpdatedRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetTermsUpdatedRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTermsUpdatedRequest} GetTermsUpdatedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTermsUpdatedRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTermsUpdatedRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTermsUpdatedRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTermsUpdatedRequest} GetTermsUpdatedRequest
             */
            GetTermsUpdatedRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTermsUpdatedRequest)
                    return d;
                return new $root.streem.api.GetTermsUpdatedRequest();
            };

            /**
             * Creates a plain object from a GetTermsUpdatedRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {streem.api.GetTermsUpdatedRequest} m GetTermsUpdatedRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTermsUpdatedRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetTermsUpdatedRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetTermsUpdatedRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTermsUpdatedRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTermsUpdatedRequest
             * @function getTypeUrl
             * @memberof streem.api.GetTermsUpdatedRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTermsUpdatedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTermsUpdatedRequest";
            };

            return GetTermsUpdatedRequest;
        })();

        api.GetTermsUpdatedResponse = (function() {

            /**
             * Properties of a GetTermsUpdatedResponse.
             * @memberof streem.api
             * @interface IGetTermsUpdatedResponse
             * @property {google.protobuf.ITimestamp|null} [updatedAt] GetTermsUpdatedResponse updatedAt
             */

            /**
             * Constructs a new GetTermsUpdatedResponse.
             * @memberof streem.api
             * @classdesc Represents a GetTermsUpdatedResponse.
             * @implements IGetTermsUpdatedResponse
             * @constructor
             * @param {streem.api.IGetTermsUpdatedResponse=} [p] Properties to set
             */
            function GetTermsUpdatedResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetTermsUpdatedResponse updatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
             * @memberof streem.api.GetTermsUpdatedResponse
             * @instance
             */
            GetTermsUpdatedResponse.prototype.updatedAt = null;

            /**
             * Creates a new GetTermsUpdatedResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {streem.api.IGetTermsUpdatedResponse=} [properties] Properties to set
             * @returns {streem.api.GetTermsUpdatedResponse} GetTermsUpdatedResponse instance
             */
            GetTermsUpdatedResponse.create = function create(properties) {
                return new GetTermsUpdatedResponse(properties);
            };

            /**
             * Encodes the specified GetTermsUpdatedResponse message. Does not implicitly {@link streem.api.GetTermsUpdatedResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {streem.api.IGetTermsUpdatedResponse} m GetTermsUpdatedResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTermsUpdatedResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.updatedAt != null && Object.hasOwnProperty.call(m, "updatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.updatedAt, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetTermsUpdatedResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTermsUpdatedResponse} GetTermsUpdatedResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTermsUpdatedResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTermsUpdatedResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.updatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTermsUpdatedResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTermsUpdatedResponse} GetTermsUpdatedResponse
             */
            GetTermsUpdatedResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTermsUpdatedResponse)
                    return d;
                var m = new $root.streem.api.GetTermsUpdatedResponse();
                if (d.updatedAt != null) {
                    if (typeof d.updatedAt !== "object")
                        throw TypeError(".streem.api.GetTermsUpdatedResponse.updatedAt: object expected");
                    m.updatedAt = $root.google.protobuf.Timestamp.fromObject(d.updatedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetTermsUpdatedResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {streem.api.GetTermsUpdatedResponse} m GetTermsUpdatedResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTermsUpdatedResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.updatedAt = null;
                }
                if (m.updatedAt != null && m.hasOwnProperty("updatedAt")) {
                    d.updatedAt = $root.google.protobuf.Timestamp.toObject(m.updatedAt, o);
                }
                return d;
            };

            /**
             * Converts this GetTermsUpdatedResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetTermsUpdatedResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTermsUpdatedResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTermsUpdatedResponse
             * @function getTypeUrl
             * @memberof streem.api.GetTermsUpdatedResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTermsUpdatedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTermsUpdatedResponse";
            };

            return GetTermsUpdatedResponse;
        })();

        api.SaveTermsUpdatedRequest = (function() {

            /**
             * Properties of a SaveTermsUpdatedRequest.
             * @memberof streem.api
             * @interface ISaveTermsUpdatedRequest
             */

            /**
             * Constructs a new SaveTermsUpdatedRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveTermsUpdatedRequest.
             * @implements ISaveTermsUpdatedRequest
             * @constructor
             * @param {streem.api.ISaveTermsUpdatedRequest=} [p] Properties to set
             */
            function SaveTermsUpdatedRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new SaveTermsUpdatedRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {streem.api.ISaveTermsUpdatedRequest=} [properties] Properties to set
             * @returns {streem.api.SaveTermsUpdatedRequest} SaveTermsUpdatedRequest instance
             */
            SaveTermsUpdatedRequest.create = function create(properties) {
                return new SaveTermsUpdatedRequest(properties);
            };

            /**
             * Encodes the specified SaveTermsUpdatedRequest message. Does not implicitly {@link streem.api.SaveTermsUpdatedRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {streem.api.ISaveTermsUpdatedRequest} m SaveTermsUpdatedRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveTermsUpdatedRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a SaveTermsUpdatedRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveTermsUpdatedRequest} SaveTermsUpdatedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveTermsUpdatedRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveTermsUpdatedRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveTermsUpdatedRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveTermsUpdatedRequest} SaveTermsUpdatedRequest
             */
            SaveTermsUpdatedRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveTermsUpdatedRequest)
                    return d;
                return new $root.streem.api.SaveTermsUpdatedRequest();
            };

            /**
             * Creates a plain object from a SaveTermsUpdatedRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {streem.api.SaveTermsUpdatedRequest} m SaveTermsUpdatedRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveTermsUpdatedRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SaveTermsUpdatedRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveTermsUpdatedRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveTermsUpdatedRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveTermsUpdatedRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveTermsUpdatedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveTermsUpdatedRequest";
            };

            return SaveTermsUpdatedRequest;
        })();

        api.SaveTermsUpdatedResponse = (function() {

            /**
             * Properties of a SaveTermsUpdatedResponse.
             * @memberof streem.api
             * @interface ISaveTermsUpdatedResponse
             * @property {google.protobuf.ITimestamp|null} [updatedAt] SaveTermsUpdatedResponse updatedAt
             */

            /**
             * Constructs a new SaveTermsUpdatedResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveTermsUpdatedResponse.
             * @implements ISaveTermsUpdatedResponse
             * @constructor
             * @param {streem.api.ISaveTermsUpdatedResponse=} [p] Properties to set
             */
            function SaveTermsUpdatedResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveTermsUpdatedResponse updatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @instance
             */
            SaveTermsUpdatedResponse.prototype.updatedAt = null;

            /**
             * Creates a new SaveTermsUpdatedResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {streem.api.ISaveTermsUpdatedResponse=} [properties] Properties to set
             * @returns {streem.api.SaveTermsUpdatedResponse} SaveTermsUpdatedResponse instance
             */
            SaveTermsUpdatedResponse.create = function create(properties) {
                return new SaveTermsUpdatedResponse(properties);
            };

            /**
             * Encodes the specified SaveTermsUpdatedResponse message. Does not implicitly {@link streem.api.SaveTermsUpdatedResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {streem.api.ISaveTermsUpdatedResponse} m SaveTermsUpdatedResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveTermsUpdatedResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.updatedAt != null && Object.hasOwnProperty.call(m, "updatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.updatedAt, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveTermsUpdatedResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveTermsUpdatedResponse} SaveTermsUpdatedResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveTermsUpdatedResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveTermsUpdatedResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.updatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveTermsUpdatedResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveTermsUpdatedResponse} SaveTermsUpdatedResponse
             */
            SaveTermsUpdatedResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveTermsUpdatedResponse)
                    return d;
                var m = new $root.streem.api.SaveTermsUpdatedResponse();
                if (d.updatedAt != null) {
                    if (typeof d.updatedAt !== "object")
                        throw TypeError(".streem.api.SaveTermsUpdatedResponse.updatedAt: object expected");
                    m.updatedAt = $root.google.protobuf.Timestamp.fromObject(d.updatedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveTermsUpdatedResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {streem.api.SaveTermsUpdatedResponse} m SaveTermsUpdatedResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveTermsUpdatedResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.updatedAt = null;
                }
                if (m.updatedAt != null && m.hasOwnProperty("updatedAt")) {
                    d.updatedAt = $root.google.protobuf.Timestamp.toObject(m.updatedAt, o);
                }
                return d;
            };

            /**
             * Converts this SaveTermsUpdatedResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveTermsUpdatedResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveTermsUpdatedResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveTermsUpdatedResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveTermsUpdatedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveTermsUpdatedResponse";
            };

            return SaveTermsUpdatedResponse;
        })();

        api.CallDetail = (function() {

            /**
             * Properties of a CallDetail.
             * @memberof streem.api
             * @interface ICallDetail
             * @property {string|null} [sid] CallDetail sid
             * @property {string|null} [callSourceSid] CallDetail callSourceSid
             * @property {Array.<streem.api.CallDetail.IItem>|null} [items] CallDetail items
             * @property {streem.api.CallDetail.ICustomer|null} [customer] CallDetail customer
             * @property {string|null} [callSourceCode] CallDetail callSourceCode
             */

            /**
             * Constructs a new CallDetail.
             * @memberof streem.api
             * @classdesc Represents a CallDetail.
             * @implements ICallDetail
             * @constructor
             * @param {streem.api.ICallDetail=} [p] Properties to set
             */
            function CallDetail(p) {
                this.items = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CallDetail sid.
             * @member {string} sid
             * @memberof streem.api.CallDetail
             * @instance
             */
            CallDetail.prototype.sid = "";

            /**
             * CallDetail callSourceSid.
             * @member {string} callSourceSid
             * @memberof streem.api.CallDetail
             * @instance
             */
            CallDetail.prototype.callSourceSid = "";

            /**
             * CallDetail items.
             * @member {Array.<streem.api.CallDetail.IItem>} items
             * @memberof streem.api.CallDetail
             * @instance
             */
            CallDetail.prototype.items = $util.emptyArray;

            /**
             * CallDetail customer.
             * @member {streem.api.CallDetail.ICustomer|null|undefined} customer
             * @memberof streem.api.CallDetail
             * @instance
             */
            CallDetail.prototype.customer = null;

            /**
             * CallDetail callSourceCode.
             * @member {string} callSourceCode
             * @memberof streem.api.CallDetail
             * @instance
             */
            CallDetail.prototype.callSourceCode = "";

            /**
             * Creates a new CallDetail instance using the specified properties.
             * @function create
             * @memberof streem.api.CallDetail
             * @static
             * @param {streem.api.ICallDetail=} [properties] Properties to set
             * @returns {streem.api.CallDetail} CallDetail instance
             */
            CallDetail.create = function create(properties) {
                return new CallDetail(properties);
            };

            /**
             * Encodes the specified CallDetail message. Does not implicitly {@link streem.api.CallDetail.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CallDetail
             * @static
             * @param {streem.api.ICallDetail} m CallDetail message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallDetail.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.callSourceSid != null && Object.hasOwnProperty.call(m, "callSourceSid"))
                    w.uint32(18).string(m.callSourceSid);
                if (m.items != null && m.items.length) {
                    for (var i = 0; i < m.items.length; ++i)
                        $root.streem.api.CallDetail.Item.encode(m.items[i], w.uint32(34).fork()).ldelim();
                }
                if (m.customer != null && Object.hasOwnProperty.call(m, "customer"))
                    $root.streem.api.CallDetail.Customer.encode(m.customer, w.uint32(42).fork()).ldelim();
                if (m.callSourceCode != null && Object.hasOwnProperty.call(m, "callSourceCode"))
                    w.uint32(50).string(m.callSourceCode);
                return w;
            };

            /**
             * Decodes a CallDetail message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CallDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CallDetail} CallDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallDetail.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallDetail();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.callSourceSid = r.string();
                            break;
                        }
                    case 4: {
                            if (!(m.items && m.items.length))
                                m.items = [];
                            m.items.push($root.streem.api.CallDetail.Item.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            m.customer = $root.streem.api.CallDetail.Customer.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.callSourceCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CallDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CallDetail
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CallDetail} CallDetail
             */
            CallDetail.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CallDetail)
                    return d;
                var m = new $root.streem.api.CallDetail();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.callSourceSid != null) {
                    m.callSourceSid = String(d.callSourceSid);
                }
                if (d.items) {
                    if (!Array.isArray(d.items))
                        throw TypeError(".streem.api.CallDetail.items: array expected");
                    m.items = [];
                    for (var i = 0; i < d.items.length; ++i) {
                        if (typeof d.items[i] !== "object")
                            throw TypeError(".streem.api.CallDetail.items: object expected");
                        m.items[i] = $root.streem.api.CallDetail.Item.fromObject(d.items[i]);
                    }
                }
                if (d.customer != null) {
                    if (typeof d.customer !== "object")
                        throw TypeError(".streem.api.CallDetail.customer: object expected");
                    m.customer = $root.streem.api.CallDetail.Customer.fromObject(d.customer);
                }
                if (d.callSourceCode != null) {
                    m.callSourceCode = String(d.callSourceCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a CallDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CallDetail
             * @static
             * @param {streem.api.CallDetail} m CallDetail
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallDetail.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.items = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.callSourceSid = "";
                    d.customer = null;
                    d.callSourceCode = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.callSourceSid != null && m.hasOwnProperty("callSourceSid")) {
                    d.callSourceSid = m.callSourceSid;
                }
                if (m.items && m.items.length) {
                    d.items = [];
                    for (var j = 0; j < m.items.length; ++j) {
                        d.items[j] = $root.streem.api.CallDetail.Item.toObject(m.items[j], o);
                    }
                }
                if (m.customer != null && m.hasOwnProperty("customer")) {
                    d.customer = $root.streem.api.CallDetail.Customer.toObject(m.customer, o);
                }
                if (m.callSourceCode != null && m.hasOwnProperty("callSourceCode")) {
                    d.callSourceCode = m.callSourceCode;
                }
                return d;
            };

            /**
             * Converts this CallDetail to JSON.
             * @function toJSON
             * @memberof streem.api.CallDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CallDetail
             * @function getTypeUrl
             * @memberof streem.api.CallDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CallDetail";
            };

            CallDetail.Item = (function() {

                /**
                 * Properties of an Item.
                 * @memberof streem.api.CallDetail
                 * @interface IItem
                 * @property {string|null} [sid] Item sid
                 * @property {string|null} [description] Item description
                 * @property {string|null} [manufacturer] Item manufacturer
                 * @property {string|null} [brand] Item brand
                 * @property {string|null} [modelNumber] Item modelNumber
                 * @property {Array.<string>|null} [symptoms] Item symptoms
                 * @property {string|null} [tradeDescription] Item tradeDescription
                 */

                /**
                 * Constructs a new Item.
                 * @memberof streem.api.CallDetail
                 * @classdesc Represents an Item.
                 * @implements IItem
                 * @constructor
                 * @param {streem.api.CallDetail.IItem=} [p] Properties to set
                 */
                function Item(p) {
                    this.symptoms = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Item sid.
                 * @member {string} sid
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.sid = "";

                /**
                 * Item description.
                 * @member {string|null|undefined} description
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.description = null;

                /**
                 * Item manufacturer.
                 * @member {string|null|undefined} manufacturer
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.manufacturer = null;

                /**
                 * Item brand.
                 * @member {string|null|undefined} brand
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.brand = null;

                /**
                 * Item modelNumber.
                 * @member {string|null|undefined} modelNumber
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.modelNumber = null;

                /**
                 * Item symptoms.
                 * @member {Array.<string>} symptoms
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.symptoms = $util.emptyArray;

                /**
                 * Item tradeDescription.
                 * @member {string|null|undefined} tradeDescription
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Item.prototype.tradeDescription = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Item _description.
                 * @member {"description"|undefined} _description
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Object.defineProperty(Item.prototype, "_description", {
                    get: $util.oneOfGetter($oneOfFields = ["description"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Item _manufacturer.
                 * @member {"manufacturer"|undefined} _manufacturer
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Object.defineProperty(Item.prototype, "_manufacturer", {
                    get: $util.oneOfGetter($oneOfFields = ["manufacturer"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Item _brand.
                 * @member {"brand"|undefined} _brand
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Object.defineProperty(Item.prototype, "_brand", {
                    get: $util.oneOfGetter($oneOfFields = ["brand"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Item _modelNumber.
                 * @member {"modelNumber"|undefined} _modelNumber
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Object.defineProperty(Item.prototype, "_modelNumber", {
                    get: $util.oneOfGetter($oneOfFields = ["modelNumber"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Item _tradeDescription.
                 * @member {"tradeDescription"|undefined} _tradeDescription
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 */
                Object.defineProperty(Item.prototype, "_tradeDescription", {
                    get: $util.oneOfGetter($oneOfFields = ["tradeDescription"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Item instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {streem.api.CallDetail.IItem=} [properties] Properties to set
                 * @returns {streem.api.CallDetail.Item} Item instance
                 */
                Item.create = function create(properties) {
                    return new Item(properties);
                };

                /**
                 * Encodes the specified Item message. Does not implicitly {@link streem.api.CallDetail.Item.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {streem.api.CallDetail.IItem} m Item message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Item.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                        w.uint32(10).string(m.sid);
                    if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                        w.uint32(18).string(m.description);
                    if (m.manufacturer != null && Object.hasOwnProperty.call(m, "manufacturer"))
                        w.uint32(26).string(m.manufacturer);
                    if (m.brand != null && Object.hasOwnProperty.call(m, "brand"))
                        w.uint32(34).string(m.brand);
                    if (m.modelNumber != null && Object.hasOwnProperty.call(m, "modelNumber"))
                        w.uint32(42).string(m.modelNumber);
                    if (m.symptoms != null && m.symptoms.length) {
                        for (var i = 0; i < m.symptoms.length; ++i)
                            w.uint32(50).string(m.symptoms[i]);
                    }
                    if (m.tradeDescription != null && Object.hasOwnProperty.call(m, "tradeDescription"))
                        w.uint32(58).string(m.tradeDescription);
                    return w;
                };

                /**
                 * Decodes an Item message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CallDetail.Item} Item
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Item.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallDetail.Item();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.sid = r.string();
                                break;
                            }
                        case 2: {
                                m.description = r.string();
                                break;
                            }
                        case 3: {
                                m.manufacturer = r.string();
                                break;
                            }
                        case 4: {
                                m.brand = r.string();
                                break;
                            }
                        case 5: {
                                m.modelNumber = r.string();
                                break;
                            }
                        case 6: {
                                if (!(m.symptoms && m.symptoms.length))
                                    m.symptoms = [];
                                m.symptoms.push(r.string());
                                break;
                            }
                        case 7: {
                                m.tradeDescription = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Item message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CallDetail.Item} Item
                 */
                Item.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CallDetail.Item)
                        return d;
                    var m = new $root.streem.api.CallDetail.Item();
                    if (d.sid != null) {
                        m.sid = String(d.sid);
                    }
                    if (d.description != null) {
                        m.description = String(d.description);
                    }
                    if (d.manufacturer != null) {
                        m.manufacturer = String(d.manufacturer);
                    }
                    if (d.brand != null) {
                        m.brand = String(d.brand);
                    }
                    if (d.modelNumber != null) {
                        m.modelNumber = String(d.modelNumber);
                    }
                    if (d.symptoms) {
                        if (!Array.isArray(d.symptoms))
                            throw TypeError(".streem.api.CallDetail.Item.symptoms: array expected");
                        m.symptoms = [];
                        for (var i = 0; i < d.symptoms.length; ++i) {
                            m.symptoms[i] = String(d.symptoms[i]);
                        }
                    }
                    if (d.tradeDescription != null) {
                        m.tradeDescription = String(d.tradeDescription);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Item message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {streem.api.CallDetail.Item} m Item
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Item.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.symptoms = [];
                    }
                    if (o.defaults) {
                        d.sid = "";
                    }
                    if (m.sid != null && m.hasOwnProperty("sid")) {
                        d.sid = m.sid;
                    }
                    if (m.description != null && m.hasOwnProperty("description")) {
                        d.description = m.description;
                        if (o.oneofs)
                            d._description = "description";
                    }
                    if (m.manufacturer != null && m.hasOwnProperty("manufacturer")) {
                        d.manufacturer = m.manufacturer;
                        if (o.oneofs)
                            d._manufacturer = "manufacturer";
                    }
                    if (m.brand != null && m.hasOwnProperty("brand")) {
                        d.brand = m.brand;
                        if (o.oneofs)
                            d._brand = "brand";
                    }
                    if (m.modelNumber != null && m.hasOwnProperty("modelNumber")) {
                        d.modelNumber = m.modelNumber;
                        if (o.oneofs)
                            d._modelNumber = "modelNumber";
                    }
                    if (m.symptoms && m.symptoms.length) {
                        d.symptoms = [];
                        for (var j = 0; j < m.symptoms.length; ++j) {
                            d.symptoms[j] = m.symptoms[j];
                        }
                    }
                    if (m.tradeDescription != null && m.hasOwnProperty("tradeDescription")) {
                        d.tradeDescription = m.tradeDescription;
                        if (o.oneofs)
                            d._tradeDescription = "tradeDescription";
                    }
                    return d;
                };

                /**
                 * Converts this Item to JSON.
                 * @function toJSON
                 * @memberof streem.api.CallDetail.Item
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Item.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Item
                 * @function getTypeUrl
                 * @memberof streem.api.CallDetail.Item
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CallDetail.Item";
                };

                return Item;
            })();

            CallDetail.Customer = (function() {

                /**
                 * Properties of a Customer.
                 * @memberof streem.api.CallDetail
                 * @interface ICustomer
                 * @property {string|null} [name] Customer name
                 * @property {string|null} [planType] Customer planType
                 * @property {string|null} [contractNumber] Customer contractNumber
                 * @property {number|null} [callsAvailable] Customer callsAvailable
                 * @property {string|null} [address] Customer address
                 */

                /**
                 * Constructs a new Customer.
                 * @memberof streem.api.CallDetail
                 * @classdesc Represents a Customer.
                 * @implements ICustomer
                 * @constructor
                 * @param {streem.api.CallDetail.ICustomer=} [p] Properties to set
                 */
                function Customer(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Customer name.
                 * @member {string|null|undefined} name
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Customer.prototype.name = null;

                /**
                 * Customer planType.
                 * @member {string|null|undefined} planType
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Customer.prototype.planType = null;

                /**
                 * Customer contractNumber.
                 * @member {string|null|undefined} contractNumber
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Customer.prototype.contractNumber = null;

                /**
                 * Customer callsAvailable.
                 * @member {number|null|undefined} callsAvailable
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Customer.prototype.callsAvailable = null;

                /**
                 * Customer address.
                 * @member {string|null|undefined} address
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Customer.prototype.address = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Customer _name.
                 * @member {"name"|undefined} _name
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Object.defineProperty(Customer.prototype, "_name", {
                    get: $util.oneOfGetter($oneOfFields = ["name"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Customer _planType.
                 * @member {"planType"|undefined} _planType
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Object.defineProperty(Customer.prototype, "_planType", {
                    get: $util.oneOfGetter($oneOfFields = ["planType"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Customer _contractNumber.
                 * @member {"contractNumber"|undefined} _contractNumber
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Object.defineProperty(Customer.prototype, "_contractNumber", {
                    get: $util.oneOfGetter($oneOfFields = ["contractNumber"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Customer _callsAvailable.
                 * @member {"callsAvailable"|undefined} _callsAvailable
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Object.defineProperty(Customer.prototype, "_callsAvailable", {
                    get: $util.oneOfGetter($oneOfFields = ["callsAvailable"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Customer _address.
                 * @member {"address"|undefined} _address
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 */
                Object.defineProperty(Customer.prototype, "_address", {
                    get: $util.oneOfGetter($oneOfFields = ["address"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Customer instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {streem.api.CallDetail.ICustomer=} [properties] Properties to set
                 * @returns {streem.api.CallDetail.Customer} Customer instance
                 */
                Customer.create = function create(properties) {
                    return new Customer(properties);
                };

                /**
                 * Encodes the specified Customer message. Does not implicitly {@link streem.api.CallDetail.Customer.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {streem.api.CallDetail.ICustomer} m Customer message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Customer.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(10).string(m.name);
                    if (m.planType != null && Object.hasOwnProperty.call(m, "planType"))
                        w.uint32(18).string(m.planType);
                    if (m.contractNumber != null && Object.hasOwnProperty.call(m, "contractNumber"))
                        w.uint32(26).string(m.contractNumber);
                    if (m.callsAvailable != null && Object.hasOwnProperty.call(m, "callsAvailable"))
                        w.uint32(32).int32(m.callsAvailable);
                    if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                        w.uint32(42).string(m.address);
                    return w;
                };

                /**
                 * Decodes a Customer message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CallDetail.Customer} Customer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Customer.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallDetail.Customer();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.name = r.string();
                                break;
                            }
                        case 2: {
                                m.planType = r.string();
                                break;
                            }
                        case 3: {
                                m.contractNumber = r.string();
                                break;
                            }
                        case 4: {
                                m.callsAvailable = r.int32();
                                break;
                            }
                        case 5: {
                                m.address = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Customer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CallDetail.Customer} Customer
                 */
                Customer.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CallDetail.Customer)
                        return d;
                    var m = new $root.streem.api.CallDetail.Customer();
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    if (d.planType != null) {
                        m.planType = String(d.planType);
                    }
                    if (d.contractNumber != null) {
                        m.contractNumber = String(d.contractNumber);
                    }
                    if (d.callsAvailable != null) {
                        m.callsAvailable = d.callsAvailable | 0;
                    }
                    if (d.address != null) {
                        m.address = String(d.address);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Customer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {streem.api.CallDetail.Customer} m Customer
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Customer.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                        if (o.oneofs)
                            d._name = "name";
                    }
                    if (m.planType != null && m.hasOwnProperty("planType")) {
                        d.planType = m.planType;
                        if (o.oneofs)
                            d._planType = "planType";
                    }
                    if (m.contractNumber != null && m.hasOwnProperty("contractNumber")) {
                        d.contractNumber = m.contractNumber;
                        if (o.oneofs)
                            d._contractNumber = "contractNumber";
                    }
                    if (m.callsAvailable != null && m.hasOwnProperty("callsAvailable")) {
                        d.callsAvailable = m.callsAvailable;
                        if (o.oneofs)
                            d._callsAvailable = "callsAvailable";
                    }
                    if (m.address != null && m.hasOwnProperty("address")) {
                        d.address = m.address;
                        if (o.oneofs)
                            d._address = "address";
                    }
                    return d;
                };

                /**
                 * Converts this Customer to JSON.
                 * @function toJSON
                 * @memberof streem.api.CallDetail.Customer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Customer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Customer
                 * @function getTypeUrl
                 * @memberof streem.api.CallDetail.Customer
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Customer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CallDetail.Customer";
                };

                return Customer;
            })();

            return CallDetail;
        })();

        api.AggregatedRoomMetrics = (function() {

            /**
             * Properties of an AggregatedRoomMetrics.
             * @memberof streem.api
             * @interface IAggregatedRoomMetrics
             * @property {streem.api.AggregatedRoomMetrics.IAggregatedBy|null} [aggregatedBy] AggregatedRoomMetrics aggregatedBy
             * @property {streem.api.AggregatedRoomMetrics.IMetrics|null} [metrics] AggregatedRoomMetrics metrics
             * @property {google.type.IInterval|null} [timeRange] AggregatedRoomMetrics timeRange
             */

            /**
             * Constructs a new AggregatedRoomMetrics.
             * @memberof streem.api
             * @classdesc Represents an AggregatedRoomMetrics.
             * @implements IAggregatedRoomMetrics
             * @constructor
             * @param {streem.api.IAggregatedRoomMetrics=} [p] Properties to set
             */
            function AggregatedRoomMetrics(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AggregatedRoomMetrics aggregatedBy.
             * @member {streem.api.AggregatedRoomMetrics.IAggregatedBy|null|undefined} aggregatedBy
             * @memberof streem.api.AggregatedRoomMetrics
             * @instance
             */
            AggregatedRoomMetrics.prototype.aggregatedBy = null;

            /**
             * AggregatedRoomMetrics metrics.
             * @member {streem.api.AggregatedRoomMetrics.IMetrics|null|undefined} metrics
             * @memberof streem.api.AggregatedRoomMetrics
             * @instance
             */
            AggregatedRoomMetrics.prototype.metrics = null;

            /**
             * AggregatedRoomMetrics timeRange.
             * @member {google.type.IInterval|null|undefined} timeRange
             * @memberof streem.api.AggregatedRoomMetrics
             * @instance
             */
            AggregatedRoomMetrics.prototype.timeRange = null;

            /**
             * Creates a new AggregatedRoomMetrics instance using the specified properties.
             * @function create
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {streem.api.IAggregatedRoomMetrics=} [properties] Properties to set
             * @returns {streem.api.AggregatedRoomMetrics} AggregatedRoomMetrics instance
             */
            AggregatedRoomMetrics.create = function create(properties) {
                return new AggregatedRoomMetrics(properties);
            };

            /**
             * Encodes the specified AggregatedRoomMetrics message. Does not implicitly {@link streem.api.AggregatedRoomMetrics.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {streem.api.IAggregatedRoomMetrics} m AggregatedRoomMetrics message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AggregatedRoomMetrics.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.aggregatedBy != null && Object.hasOwnProperty.call(m, "aggregatedBy"))
                    $root.streem.api.AggregatedRoomMetrics.AggregatedBy.encode(m.aggregatedBy, w.uint32(10).fork()).ldelim();
                if (m.metrics != null && Object.hasOwnProperty.call(m, "metrics"))
                    $root.streem.api.AggregatedRoomMetrics.Metrics.encode(m.metrics, w.uint32(18).fork()).ldelim();
                if (m.timeRange != null && Object.hasOwnProperty.call(m, "timeRange"))
                    $root.google.type.Interval.encode(m.timeRange, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AggregatedRoomMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AggregatedRoomMetrics} AggregatedRoomMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AggregatedRoomMetrics.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregatedRoomMetrics();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.aggregatedBy = $root.streem.api.AggregatedRoomMetrics.AggregatedBy.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.metrics = $root.streem.api.AggregatedRoomMetrics.Metrics.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.timeRange = $root.google.type.Interval.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AggregatedRoomMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AggregatedRoomMetrics} AggregatedRoomMetrics
             */
            AggregatedRoomMetrics.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AggregatedRoomMetrics)
                    return d;
                var m = new $root.streem.api.AggregatedRoomMetrics();
                if (d.aggregatedBy != null) {
                    if (typeof d.aggregatedBy !== "object")
                        throw TypeError(".streem.api.AggregatedRoomMetrics.aggregatedBy: object expected");
                    m.aggregatedBy = $root.streem.api.AggregatedRoomMetrics.AggregatedBy.fromObject(d.aggregatedBy);
                }
                if (d.metrics != null) {
                    if (typeof d.metrics !== "object")
                        throw TypeError(".streem.api.AggregatedRoomMetrics.metrics: object expected");
                    m.metrics = $root.streem.api.AggregatedRoomMetrics.Metrics.fromObject(d.metrics);
                }
                if (d.timeRange != null) {
                    if (typeof d.timeRange !== "object")
                        throw TypeError(".streem.api.AggregatedRoomMetrics.timeRange: object expected");
                    m.timeRange = $root.google.type.Interval.fromObject(d.timeRange);
                }
                return m;
            };

            /**
             * Creates a plain object from an AggregatedRoomMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {streem.api.AggregatedRoomMetrics} m AggregatedRoomMetrics
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AggregatedRoomMetrics.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.aggregatedBy = null;
                    d.metrics = null;
                    d.timeRange = null;
                }
                if (m.aggregatedBy != null && m.hasOwnProperty("aggregatedBy")) {
                    d.aggregatedBy = $root.streem.api.AggregatedRoomMetrics.AggregatedBy.toObject(m.aggregatedBy, o);
                }
                if (m.metrics != null && m.hasOwnProperty("metrics")) {
                    d.metrics = $root.streem.api.AggregatedRoomMetrics.Metrics.toObject(m.metrics, o);
                }
                if (m.timeRange != null && m.hasOwnProperty("timeRange")) {
                    d.timeRange = $root.google.type.Interval.toObject(m.timeRange, o);
                }
                return d;
            };

            /**
             * Converts this AggregatedRoomMetrics to JSON.
             * @function toJSON
             * @memberof streem.api.AggregatedRoomMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AggregatedRoomMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AggregatedRoomMetrics
             * @function getTypeUrl
             * @memberof streem.api.AggregatedRoomMetrics
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AggregatedRoomMetrics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AggregatedRoomMetrics";
            };

            AggregatedRoomMetrics.AggregatedBy = (function() {

                /**
                 * Properties of an AggregatedBy.
                 * @memberof streem.api.AggregatedRoomMetrics
                 * @interface IAggregatedBy
                 * @property {string|null} [userSid] AggregatedBy userSid
                 * @property {string|null} [companySid] AggregatedBy companySid
                 * @property {string|null} [groupSid] AggregatedBy groupSid
                 * @property {string|null} [groupName] AggregatedBy groupName
                 */

                /**
                 * Constructs a new AggregatedBy.
                 * @memberof streem.api.AggregatedRoomMetrics
                 * @classdesc Represents an AggregatedBy.
                 * @implements IAggregatedBy
                 * @constructor
                 * @param {streem.api.AggregatedRoomMetrics.IAggregatedBy=} [p] Properties to set
                 */
                function AggregatedBy(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * AggregatedBy userSid.
                 * @member {string|null|undefined} userSid
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 */
                AggregatedBy.prototype.userSid = null;

                /**
                 * AggregatedBy companySid.
                 * @member {string|null|undefined} companySid
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 */
                AggregatedBy.prototype.companySid = null;

                /**
                 * AggregatedBy groupSid.
                 * @member {string|null|undefined} groupSid
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 */
                AggregatedBy.prototype.groupSid = null;

                /**
                 * AggregatedBy groupName.
                 * @member {string|null|undefined} groupName
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 */
                AggregatedBy.prototype.groupName = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * AggregatedBy type.
                 * @member {"userSid"|"companySid"|"groupSid"|"groupName"|undefined} type
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 */
                Object.defineProperty(AggregatedBy.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["userSid", "companySid", "groupSid", "groupName"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new AggregatedBy instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.IAggregatedBy=} [properties] Properties to set
                 * @returns {streem.api.AggregatedRoomMetrics.AggregatedBy} AggregatedBy instance
                 */
                AggregatedBy.create = function create(properties) {
                    return new AggregatedBy(properties);
                };

                /**
                 * Encodes the specified AggregatedBy message. Does not implicitly {@link streem.api.AggregatedRoomMetrics.AggregatedBy.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.IAggregatedBy} m AggregatedBy message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AggregatedBy.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                        w.uint32(10).string(m.userSid);
                    if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                        w.uint32(18).string(m.companySid);
                    if (m.groupSid != null && Object.hasOwnProperty.call(m, "groupSid"))
                        w.uint32(26).string(m.groupSid);
                    if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                        w.uint32(34).string(m.groupName);
                    return w;
                };

                /**
                 * Decodes an AggregatedBy message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AggregatedRoomMetrics.AggregatedBy} AggregatedBy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AggregatedBy.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregatedRoomMetrics.AggregatedBy();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.userSid = r.string();
                                break;
                            }
                        case 2: {
                                m.companySid = r.string();
                                break;
                            }
                        case 3: {
                                m.groupSid = r.string();
                                break;
                            }
                        case 4: {
                                m.groupName = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an AggregatedBy message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AggregatedRoomMetrics.AggregatedBy} AggregatedBy
                 */
                AggregatedBy.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AggregatedRoomMetrics.AggregatedBy)
                        return d;
                    var m = new $root.streem.api.AggregatedRoomMetrics.AggregatedBy();
                    if (d.userSid != null) {
                        m.userSid = String(d.userSid);
                    }
                    if (d.companySid != null) {
                        m.companySid = String(d.companySid);
                    }
                    if (d.groupSid != null) {
                        m.groupSid = String(d.groupSid);
                    }
                    if (d.groupName != null) {
                        m.groupName = String(d.groupName);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an AggregatedBy message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.AggregatedBy} m AggregatedBy
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AggregatedBy.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (m.userSid != null && m.hasOwnProperty("userSid")) {
                        d.userSid = m.userSid;
                        if (o.oneofs)
                            d.type = "userSid";
                    }
                    if (m.companySid != null && m.hasOwnProperty("companySid")) {
                        d.companySid = m.companySid;
                        if (o.oneofs)
                            d.type = "companySid";
                    }
                    if (m.groupSid != null && m.hasOwnProperty("groupSid")) {
                        d.groupSid = m.groupSid;
                        if (o.oneofs)
                            d.type = "groupSid";
                    }
                    if (m.groupName != null && m.hasOwnProperty("groupName")) {
                        d.groupName = m.groupName;
                        if (o.oneofs)
                            d.type = "groupName";
                    }
                    return d;
                };

                /**
                 * Converts this AggregatedBy to JSON.
                 * @function toJSON
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AggregatedBy.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AggregatedBy
                 * @function getTypeUrl
                 * @memberof streem.api.AggregatedRoomMetrics.AggregatedBy
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AggregatedBy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AggregatedRoomMetrics.AggregatedBy";
                };

                return AggregatedBy;
            })();

            /**
             * AggregateBy enum.
             * @name streem.api.AggregatedRoomMetrics.AggregateBy
             * @enum {number}
             * @property {number} AGGREGATE_BY_USER_SID=0 AGGREGATE_BY_USER_SID value
             * @property {number} AGGREGATE_BY_GROUP_SID=1 AGGREGATE_BY_GROUP_SID value
             * @property {number} AGGREGATE_BY_COMPANY_SID=2 AGGREGATE_BY_COMPANY_SID value
             * @property {number} AGGREGATE_BY_GROUP_NAME=3 AGGREGATE_BY_GROUP_NAME value
             */
            AggregatedRoomMetrics.AggregateBy = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AGGREGATE_BY_USER_SID"] = 0;
                values[valuesById[1] = "AGGREGATE_BY_GROUP_SID"] = 1;
                values[valuesById[2] = "AGGREGATE_BY_COMPANY_SID"] = 2;
                values[valuesById[3] = "AGGREGATE_BY_GROUP_NAME"] = 3;
                return values;
            })();

            AggregatedRoomMetrics.Metrics = (function() {

                /**
                 * Properties of a Metrics.
                 * @memberof streem.api.AggregatedRoomMetrics
                 * @interface IMetrics
                 * @property {number|null} [totalCallCount] Metrics totalCallCount
                 * @property {number|null} [missedCallCount] Metrics missedCallCount
                 * @property {google.protobuf.IDuration|null} [avgCallDuration] Metrics avgCallDuration
                 * @property {google.protobuf.IDuration|null} [activeDuration] Metrics activeDuration
                 * @property {number|null} [avgCallCountPerHour] Metrics avgCallCountPerHour
                 * @property {number|null} [unavailabilityPercentage] Metrics unavailabilityPercentage
                 */

                /**
                 * Constructs a new Metrics.
                 * @memberof streem.api.AggregatedRoomMetrics
                 * @classdesc Represents a Metrics.
                 * @implements IMetrics
                 * @constructor
                 * @param {streem.api.AggregatedRoomMetrics.IMetrics=} [p] Properties to set
                 */
                function Metrics(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Metrics totalCallCount.
                 * @member {number} totalCallCount
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.totalCallCount = 0;

                /**
                 * Metrics missedCallCount.
                 * @member {number} missedCallCount
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.missedCallCount = 0;

                /**
                 * Metrics avgCallDuration.
                 * @member {google.protobuf.IDuration|null|undefined} avgCallDuration
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.avgCallDuration = null;

                /**
                 * Metrics activeDuration.
                 * @member {google.protobuf.IDuration|null|undefined} activeDuration
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.activeDuration = null;

                /**
                 * Metrics avgCallCountPerHour.
                 * @member {number} avgCallCountPerHour
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.avgCallCountPerHour = 0;

                /**
                 * Metrics unavailabilityPercentage.
                 * @member {number} unavailabilityPercentage
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 */
                Metrics.prototype.unavailabilityPercentage = 0;

                /**
                 * Creates a new Metrics instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.IMetrics=} [properties] Properties to set
                 * @returns {streem.api.AggregatedRoomMetrics.Metrics} Metrics instance
                 */
                Metrics.create = function create(properties) {
                    return new Metrics(properties);
                };

                /**
                 * Encodes the specified Metrics message. Does not implicitly {@link streem.api.AggregatedRoomMetrics.Metrics.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.IMetrics} m Metrics message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Metrics.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.totalCallCount != null && Object.hasOwnProperty.call(m, "totalCallCount"))
                        w.uint32(8).int32(m.totalCallCount);
                    if (m.missedCallCount != null && Object.hasOwnProperty.call(m, "missedCallCount"))
                        w.uint32(16).int32(m.missedCallCount);
                    if (m.avgCallDuration != null && Object.hasOwnProperty.call(m, "avgCallDuration"))
                        $root.google.protobuf.Duration.encode(m.avgCallDuration, w.uint32(26).fork()).ldelim();
                    if (m.activeDuration != null && Object.hasOwnProperty.call(m, "activeDuration"))
                        $root.google.protobuf.Duration.encode(m.activeDuration, w.uint32(34).fork()).ldelim();
                    if (m.avgCallCountPerHour != null && Object.hasOwnProperty.call(m, "avgCallCountPerHour"))
                        w.uint32(40).int32(m.avgCallCountPerHour);
                    if (m.unavailabilityPercentage != null && Object.hasOwnProperty.call(m, "unavailabilityPercentage"))
                        w.uint32(48).int32(m.unavailabilityPercentage);
                    return w;
                };

                /**
                 * Decodes a Metrics message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AggregatedRoomMetrics.Metrics} Metrics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Metrics.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregatedRoomMetrics.Metrics();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.totalCallCount = r.int32();
                                break;
                            }
                        case 2: {
                                m.missedCallCount = r.int32();
                                break;
                            }
                        case 3: {
                                m.avgCallDuration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.activeDuration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                break;
                            }
                        case 5: {
                                m.avgCallCountPerHour = r.int32();
                                break;
                            }
                        case 6: {
                                m.unavailabilityPercentage = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Metrics message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AggregatedRoomMetrics.Metrics} Metrics
                 */
                Metrics.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AggregatedRoomMetrics.Metrics)
                        return d;
                    var m = new $root.streem.api.AggregatedRoomMetrics.Metrics();
                    if (d.totalCallCount != null) {
                        m.totalCallCount = d.totalCallCount | 0;
                    }
                    if (d.missedCallCount != null) {
                        m.missedCallCount = d.missedCallCount | 0;
                    }
                    if (d.avgCallDuration != null) {
                        if (typeof d.avgCallDuration !== "object")
                            throw TypeError(".streem.api.AggregatedRoomMetrics.Metrics.avgCallDuration: object expected");
                        m.avgCallDuration = $root.google.protobuf.Duration.fromObject(d.avgCallDuration);
                    }
                    if (d.activeDuration != null) {
                        if (typeof d.activeDuration !== "object")
                            throw TypeError(".streem.api.AggregatedRoomMetrics.Metrics.activeDuration: object expected");
                        m.activeDuration = $root.google.protobuf.Duration.fromObject(d.activeDuration);
                    }
                    if (d.avgCallCountPerHour != null) {
                        m.avgCallCountPerHour = d.avgCallCountPerHour | 0;
                    }
                    if (d.unavailabilityPercentage != null) {
                        m.unavailabilityPercentage = d.unavailabilityPercentage | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Metrics message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {streem.api.AggregatedRoomMetrics.Metrics} m Metrics
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Metrics.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.totalCallCount = 0;
                        d.missedCallCount = 0;
                        d.avgCallDuration = null;
                        d.activeDuration = null;
                        d.avgCallCountPerHour = 0;
                        d.unavailabilityPercentage = 0;
                    }
                    if (m.totalCallCount != null && m.hasOwnProperty("totalCallCount")) {
                        d.totalCallCount = m.totalCallCount;
                    }
                    if (m.missedCallCount != null && m.hasOwnProperty("missedCallCount")) {
                        d.missedCallCount = m.missedCallCount;
                    }
                    if (m.avgCallDuration != null && m.hasOwnProperty("avgCallDuration")) {
                        d.avgCallDuration = $root.google.protobuf.Duration.toObject(m.avgCallDuration, o);
                    }
                    if (m.activeDuration != null && m.hasOwnProperty("activeDuration")) {
                        d.activeDuration = $root.google.protobuf.Duration.toObject(m.activeDuration, o);
                    }
                    if (m.avgCallCountPerHour != null && m.hasOwnProperty("avgCallCountPerHour")) {
                        d.avgCallCountPerHour = m.avgCallCountPerHour;
                    }
                    if (m.unavailabilityPercentage != null && m.hasOwnProperty("unavailabilityPercentage")) {
                        d.unavailabilityPercentage = m.unavailabilityPercentage;
                    }
                    return d;
                };

                /**
                 * Converts this Metrics to JSON.
                 * @function toJSON
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Metrics.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Metrics
                 * @function getTypeUrl
                 * @memberof streem.api.AggregatedRoomMetrics.Metrics
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Metrics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AggregatedRoomMetrics.Metrics";
                };

                return Metrics;
            })();

            return AggregatedRoomMetrics;
        })();

        api.Invitations = (function() {

            /**
             * Constructs a new Invitations service.
             * @memberof streem.api
             * @classdesc Represents an Invitations
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Invitations(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Invitations.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Invitations;

            /**
             * Creates new Invitations service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Invitations
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Invitations} RPC service. Useful where requests and/or responses are streamed.
             */
            Invitations.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Invitations#getInvitation}.
             * @memberof streem.api.Invitations
             * @typedef GetInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetInvitationResponse} [response] GetInvitationResponse
             */

            /**
             * Calls GetInvitation.
             * @function getInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetInvitationRequest} request GetInvitationRequest message or plain object
             * @param {streem.api.Invitations.GetInvitationCallback} callback Node-style callback called with the error, if any, and GetInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Invitations.prototype.getInvitation = function getInvitation(request, callback) {
                return this.rpcCall(getInvitation, $root.streem.api.GetInvitationRequest, $root.streem.api.GetInvitationResponse, request, callback);
            }, "name", { value: "GetInvitation" });

            /**
             * Calls GetInvitation.
             * @function getInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetInvitationRequest} request GetInvitationRequest message or plain object
             * @returns {Promise<streem.api.GetInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Invitations#getInvitationSmsStatuses}.
             * @memberof streem.api.Invitations
             * @typedef GetInvitationSmsStatusesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetInvitationSmsStatusesResponse} [response] GetInvitationSmsStatusesResponse
             */

            /**
             * Calls GetInvitationSmsStatuses.
             * @function getInvitationSmsStatuses
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetInvitationSmsStatusesRequest} request GetInvitationSmsStatusesRequest message or plain object
             * @param {streem.api.Invitations.GetInvitationSmsStatusesCallback} callback Node-style callback called with the error, if any, and GetInvitationSmsStatusesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Invitations.prototype.getInvitationSmsStatuses = function getInvitationSmsStatuses(request, callback) {
                return this.rpcCall(getInvitationSmsStatuses, $root.streem.api.GetInvitationSmsStatusesRequest, $root.streem.api.GetInvitationSmsStatusesResponse, request, callback);
            }, "name", { value: "GetInvitationSmsStatuses" });

            /**
             * Calls GetInvitationSmsStatuses.
             * @function getInvitationSmsStatuses
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetInvitationSmsStatusesRequest} request GetInvitationSmsStatusesRequest message or plain object
             * @returns {Promise<streem.api.GetInvitationSmsStatusesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Invitations#getCurrentInvitation}.
             * @memberof streem.api.Invitations
             * @typedef GetCurrentInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCurrentInvitationResponse} [response] GetCurrentInvitationResponse
             */

            /**
             * Calls GetCurrentInvitation.
             * @function getCurrentInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetCurrentInvitationRequest} request GetCurrentInvitationRequest message or plain object
             * @param {streem.api.Invitations.GetCurrentInvitationCallback} callback Node-style callback called with the error, if any, and GetCurrentInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Invitations.prototype.getCurrentInvitation = function getCurrentInvitation(request, callback) {
                return this.rpcCall(getCurrentInvitation, $root.streem.api.GetCurrentInvitationRequest, $root.streem.api.GetCurrentInvitationResponse, request, callback);
            }, "name", { value: "GetCurrentInvitation" });

            /**
             * Calls GetCurrentInvitation.
             * @function getCurrentInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.IGetCurrentInvitationRequest} request GetCurrentInvitationRequest message or plain object
             * @returns {Promise<streem.api.GetCurrentInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Invitations#sendInvitation}.
             * @memberof streem.api.Invitations
             * @typedef SendInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SendInvitationResponse} [response] SendInvitationResponse
             */

            /**
             * Calls SendInvitation.
             * @function sendInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.ISendInvitationRequest} request SendInvitationRequest message or plain object
             * @param {streem.api.Invitations.SendInvitationCallback} callback Node-style callback called with the error, if any, and SendInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Invitations.prototype.sendInvitation = function sendInvitation(request, callback) {
                return this.rpcCall(sendInvitation, $root.streem.api.SendInvitationRequest, $root.streem.api.SendInvitationResponse, request, callback);
            }, "name", { value: "SendInvitation" });

            /**
             * Calls SendInvitation.
             * @function sendInvitation
             * @memberof streem.api.Invitations
             * @instance
             * @param {streem.api.ISendInvitationRequest} request SendInvitationRequest message or plain object
             * @returns {Promise<streem.api.SendInvitationResponse>} Promise
             * @variation 2
             */

            return Invitations;
        })();

        api.GetInvitationRequest = (function() {

            /**
             * Properties of a GetInvitationRequest.
             * @memberof streem.api
             * @interface IGetInvitationRequest
             * @property {string|null} [invitationSid] GetInvitationRequest invitationSid
             */

            /**
             * Constructs a new GetInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetInvitationRequest.
             * @implements IGetInvitationRequest
             * @constructor
             * @param {streem.api.IGetInvitationRequest=} [p] Properties to set
             */
            function GetInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetInvitationRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.GetInvitationRequest
             * @instance
             */
            GetInvitationRequest.prototype.invitationSid = "";

            /**
             * Creates a new GetInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {streem.api.IGetInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.GetInvitationRequest} GetInvitationRequest instance
             */
            GetInvitationRequest.create = function create(properties) {
                return new GetInvitationRequest(properties);
            };

            /**
             * Encodes the specified GetInvitationRequest message. Does not implicitly {@link streem.api.GetInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {streem.api.IGetInvitationRequest} m GetInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(10).string(m.invitationSid);
                return w;
            };

            /**
             * Decodes a GetInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetInvitationRequest} GetInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetInvitationRequest} GetInvitationRequest
             */
            GetInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetInvitationRequest)
                    return d;
                var m = new $root.streem.api.GetInvitationRequest();
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {streem.api.GetInvitationRequest} m GetInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitationSid = "";
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                return d;
            };

            /**
             * Converts this GetInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetInvitationRequest";
            };

            return GetInvitationRequest;
        })();

        api.GetInvitationResponse = (function() {

            /**
             * Properties of a GetInvitationResponse.
             * @memberof streem.api
             * @interface IGetInvitationResponse
             * @property {streem.api.IInvitation|null} [invitation] GetInvitationResponse invitation
             */

            /**
             * Constructs a new GetInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetInvitationResponse.
             * @implements IGetInvitationResponse
             * @constructor
             * @param {streem.api.IGetInvitationResponse=} [p] Properties to set
             */
            function GetInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetInvitationResponse invitation.
             * @member {streem.api.IInvitation|null|undefined} invitation
             * @memberof streem.api.GetInvitationResponse
             * @instance
             */
            GetInvitationResponse.prototype.invitation = null;

            /**
             * Creates a new GetInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {streem.api.IGetInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.GetInvitationResponse} GetInvitationResponse instance
             */
            GetInvitationResponse.create = function create(properties) {
                return new GetInvitationResponse(properties);
            };

            /**
             * Encodes the specified GetInvitationResponse message. Does not implicitly {@link streem.api.GetInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {streem.api.IGetInvitationResponse} m GetInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
                    $root.streem.api.Invitation.encode(m.invitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetInvitationResponse} GetInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitation = $root.streem.api.Invitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetInvitationResponse} GetInvitationResponse
             */
            GetInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetInvitationResponse)
                    return d;
                var m = new $root.streem.api.GetInvitationResponse();
                if (d.invitation != null) {
                    if (typeof d.invitation !== "object")
                        throw TypeError(".streem.api.GetInvitationResponse.invitation: object expected");
                    m.invitation = $root.streem.api.Invitation.fromObject(d.invitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {streem.api.GetInvitationResponse} m GetInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitation = null;
                }
                if (m.invitation != null && m.hasOwnProperty("invitation")) {
                    d.invitation = $root.streem.api.Invitation.toObject(m.invitation, o);
                }
                return d;
            };

            /**
             * Converts this GetInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetInvitationResponse";
            };

            return GetInvitationResponse;
        })();

        api.GetInvitationSmsStatusesRequest = (function() {

            /**
             * Properties of a GetInvitationSmsStatusesRequest.
             * @memberof streem.api
             * @interface IGetInvitationSmsStatusesRequest
             * @property {string|null} [invitationSid] GetInvitationSmsStatusesRequest invitationSid
             */

            /**
             * Constructs a new GetInvitationSmsStatusesRequest.
             * @memberof streem.api
             * @classdesc Represents a GetInvitationSmsStatusesRequest.
             * @implements IGetInvitationSmsStatusesRequest
             * @constructor
             * @param {streem.api.IGetInvitationSmsStatusesRequest=} [p] Properties to set
             */
            function GetInvitationSmsStatusesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetInvitationSmsStatusesRequest invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @instance
             */
            GetInvitationSmsStatusesRequest.prototype.invitationSid = "";

            /**
             * Creates a new GetInvitationSmsStatusesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {streem.api.IGetInvitationSmsStatusesRequest=} [properties] Properties to set
             * @returns {streem.api.GetInvitationSmsStatusesRequest} GetInvitationSmsStatusesRequest instance
             */
            GetInvitationSmsStatusesRequest.create = function create(properties) {
                return new GetInvitationSmsStatusesRequest(properties);
            };

            /**
             * Encodes the specified GetInvitationSmsStatusesRequest message. Does not implicitly {@link streem.api.GetInvitationSmsStatusesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {streem.api.IGetInvitationSmsStatusesRequest} m GetInvitationSmsStatusesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetInvitationSmsStatusesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(10).string(m.invitationSid);
                return w;
            };

            /**
             * Decodes a GetInvitationSmsStatusesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetInvitationSmsStatusesRequest} GetInvitationSmsStatusesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetInvitationSmsStatusesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetInvitationSmsStatusesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetInvitationSmsStatusesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetInvitationSmsStatusesRequest} GetInvitationSmsStatusesRequest
             */
            GetInvitationSmsStatusesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetInvitationSmsStatusesRequest)
                    return d;
                var m = new $root.streem.api.GetInvitationSmsStatusesRequest();
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetInvitationSmsStatusesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {streem.api.GetInvitationSmsStatusesRequest} m GetInvitationSmsStatusesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetInvitationSmsStatusesRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitationSid = "";
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                return d;
            };

            /**
             * Converts this GetInvitationSmsStatusesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetInvitationSmsStatusesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetInvitationSmsStatusesRequest
             * @function getTypeUrl
             * @memberof streem.api.GetInvitationSmsStatusesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetInvitationSmsStatusesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetInvitationSmsStatusesRequest";
            };

            return GetInvitationSmsStatusesRequest;
        })();

        api.GetInvitationSmsStatusesResponse = (function() {

            /**
             * Properties of a GetInvitationSmsStatusesResponse.
             * @memberof streem.api
             * @interface IGetInvitationSmsStatusesResponse
             * @property {Array.<streem.api.ISmsDeliveryStatus>|null} [smsDeliveryStatuses] GetInvitationSmsStatusesResponse smsDeliveryStatuses
             */

            /**
             * Constructs a new GetInvitationSmsStatusesResponse.
             * @memberof streem.api
             * @classdesc Represents a GetInvitationSmsStatusesResponse.
             * @implements IGetInvitationSmsStatusesResponse
             * @constructor
             * @param {streem.api.IGetInvitationSmsStatusesResponse=} [p] Properties to set
             */
            function GetInvitationSmsStatusesResponse(p) {
                this.smsDeliveryStatuses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetInvitationSmsStatusesResponse smsDeliveryStatuses.
             * @member {Array.<streem.api.ISmsDeliveryStatus>} smsDeliveryStatuses
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @instance
             */
            GetInvitationSmsStatusesResponse.prototype.smsDeliveryStatuses = $util.emptyArray;

            /**
             * Creates a new GetInvitationSmsStatusesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {streem.api.IGetInvitationSmsStatusesResponse=} [properties] Properties to set
             * @returns {streem.api.GetInvitationSmsStatusesResponse} GetInvitationSmsStatusesResponse instance
             */
            GetInvitationSmsStatusesResponse.create = function create(properties) {
                return new GetInvitationSmsStatusesResponse(properties);
            };

            /**
             * Encodes the specified GetInvitationSmsStatusesResponse message. Does not implicitly {@link streem.api.GetInvitationSmsStatusesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {streem.api.IGetInvitationSmsStatusesResponse} m GetInvitationSmsStatusesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetInvitationSmsStatusesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.smsDeliveryStatuses != null && m.smsDeliveryStatuses.length) {
                    for (var i = 0; i < m.smsDeliveryStatuses.length; ++i)
                        $root.streem.api.SmsDeliveryStatus.encode(m.smsDeliveryStatuses[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetInvitationSmsStatusesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetInvitationSmsStatusesResponse} GetInvitationSmsStatusesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetInvitationSmsStatusesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetInvitationSmsStatusesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.smsDeliveryStatuses && m.smsDeliveryStatuses.length))
                                m.smsDeliveryStatuses = [];
                            m.smsDeliveryStatuses.push($root.streem.api.SmsDeliveryStatus.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetInvitationSmsStatusesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetInvitationSmsStatusesResponse} GetInvitationSmsStatusesResponse
             */
            GetInvitationSmsStatusesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetInvitationSmsStatusesResponse)
                    return d;
                var m = new $root.streem.api.GetInvitationSmsStatusesResponse();
                if (d.smsDeliveryStatuses) {
                    if (!Array.isArray(d.smsDeliveryStatuses))
                        throw TypeError(".streem.api.GetInvitationSmsStatusesResponse.smsDeliveryStatuses: array expected");
                    m.smsDeliveryStatuses = [];
                    for (var i = 0; i < d.smsDeliveryStatuses.length; ++i) {
                        if (typeof d.smsDeliveryStatuses[i] !== "object")
                            throw TypeError(".streem.api.GetInvitationSmsStatusesResponse.smsDeliveryStatuses: object expected");
                        m.smsDeliveryStatuses[i] = $root.streem.api.SmsDeliveryStatus.fromObject(d.smsDeliveryStatuses[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetInvitationSmsStatusesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {streem.api.GetInvitationSmsStatusesResponse} m GetInvitationSmsStatusesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetInvitationSmsStatusesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.smsDeliveryStatuses = [];
                }
                if (m.smsDeliveryStatuses && m.smsDeliveryStatuses.length) {
                    d.smsDeliveryStatuses = [];
                    for (var j = 0; j < m.smsDeliveryStatuses.length; ++j) {
                        d.smsDeliveryStatuses[j] = $root.streem.api.SmsDeliveryStatus.toObject(m.smsDeliveryStatuses[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetInvitationSmsStatusesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetInvitationSmsStatusesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetInvitationSmsStatusesResponse
             * @function getTypeUrl
             * @memberof streem.api.GetInvitationSmsStatusesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetInvitationSmsStatusesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetInvitationSmsStatusesResponse";
            };

            return GetInvitationSmsStatusesResponse;
        })();

        api.GetCurrentInvitationRequest = (function() {

            /**
             * Properties of a GetCurrentInvitationRequest.
             * @memberof streem.api
             * @interface IGetCurrentInvitationRequest
             */

            /**
             * Constructs a new GetCurrentInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentInvitationRequest.
             * @implements IGetCurrentInvitationRequest
             * @constructor
             * @param {streem.api.IGetCurrentInvitationRequest=} [p] Properties to set
             */
            function GetCurrentInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCurrentInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {streem.api.IGetCurrentInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.GetCurrentInvitationRequest} GetCurrentInvitationRequest instance
             */
            GetCurrentInvitationRequest.create = function create(properties) {
                return new GetCurrentInvitationRequest(properties);
            };

            /**
             * Encodes the specified GetCurrentInvitationRequest message. Does not implicitly {@link streem.api.GetCurrentInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {streem.api.IGetCurrentInvitationRequest} m GetCurrentInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCurrentInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentInvitationRequest} GetCurrentInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentInvitationRequest} GetCurrentInvitationRequest
             */
            GetCurrentInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentInvitationRequest)
                    return d;
                return new $root.streem.api.GetCurrentInvitationRequest();
            };

            /**
             * Creates a plain object from a GetCurrentInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {streem.api.GetCurrentInvitationRequest} m GetCurrentInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentInvitationRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentInvitationRequest";
            };

            return GetCurrentInvitationRequest;
        })();

        api.GetCurrentInvitationResponse = (function() {

            /**
             * Properties of a GetCurrentInvitationResponse.
             * @memberof streem.api
             * @interface IGetCurrentInvitationResponse
             * @property {streem.api.IInvitation|null} [invitation] GetCurrentInvitationResponse invitation
             */

            /**
             * Constructs a new GetCurrentInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentInvitationResponse.
             * @implements IGetCurrentInvitationResponse
             * @constructor
             * @param {streem.api.IGetCurrentInvitationResponse=} [p] Properties to set
             */
            function GetCurrentInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCurrentInvitationResponse invitation.
             * @member {streem.api.IInvitation|null|undefined} invitation
             * @memberof streem.api.GetCurrentInvitationResponse
             * @instance
             */
            GetCurrentInvitationResponse.prototype.invitation = null;

            /**
             * Creates a new GetCurrentInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {streem.api.IGetCurrentInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.GetCurrentInvitationResponse} GetCurrentInvitationResponse instance
             */
            GetCurrentInvitationResponse.create = function create(properties) {
                return new GetCurrentInvitationResponse(properties);
            };

            /**
             * Encodes the specified GetCurrentInvitationResponse message. Does not implicitly {@link streem.api.GetCurrentInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {streem.api.IGetCurrentInvitationResponse} m GetCurrentInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
                    $root.streem.api.Invitation.encode(m.invitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCurrentInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentInvitationResponse} GetCurrentInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitation = $root.streem.api.Invitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentInvitationResponse} GetCurrentInvitationResponse
             */
            GetCurrentInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentInvitationResponse)
                    return d;
                var m = new $root.streem.api.GetCurrentInvitationResponse();
                if (d.invitation != null) {
                    if (typeof d.invitation !== "object")
                        throw TypeError(".streem.api.GetCurrentInvitationResponse.invitation: object expected");
                    m.invitation = $root.streem.api.Invitation.fromObject(d.invitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCurrentInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {streem.api.GetCurrentInvitationResponse} m GetCurrentInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitation = null;
                }
                if (m.invitation != null && m.hasOwnProperty("invitation")) {
                    d.invitation = $root.streem.api.Invitation.toObject(m.invitation, o);
                }
                return d;
            };

            /**
             * Converts this GetCurrentInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentInvitationResponse";
            };

            return GetCurrentInvitationResponse;
        })();

        api.SendInvitationRequest = (function() {

            /**
             * Properties of a SendInvitationRequest.
             * @memberof streem.api
             * @interface ISendInvitationRequest
             * @property {string|null} [fromUserSid] SendInvitationRequest fromUserSid
             * @property {string|null} [toUserName] SendInvitationRequest toUserName
             * @property {string|null} [phone] SendInvitationRequest phone
             * @property {string|null} [referenceId] SendInvitationRequest referenceId
             * @property {string|null} [companyCode] SendInvitationRequest companyCode
             * @property {streem.api.DeliveryType|null} [deliveryType] SendInvitationRequest deliveryType
             * @property {string|null} [integrationId] SendInvitationRequest integrationId
             */

            /**
             * Constructs a new SendInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a SendInvitationRequest.
             * @implements ISendInvitationRequest
             * @constructor
             * @param {streem.api.ISendInvitationRequest=} [p] Properties to set
             */
            function SendInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SendInvitationRequest fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.fromUserSid = "";

            /**
             * SendInvitationRequest toUserName.
             * @member {string} toUserName
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.toUserName = "";

            /**
             * SendInvitationRequest phone.
             * @member {string} phone
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.phone = "";

            /**
             * SendInvitationRequest referenceId.
             * @member {string} referenceId
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.referenceId = "";

            /**
             * SendInvitationRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.companyCode = "";

            /**
             * SendInvitationRequest deliveryType.
             * @member {streem.api.DeliveryType} deliveryType
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.deliveryType = 0;

            /**
             * SendInvitationRequest integrationId.
             * @member {string} integrationId
             * @memberof streem.api.SendInvitationRequest
             * @instance
             */
            SendInvitationRequest.prototype.integrationId = "";

            /**
             * Creates a new SendInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {streem.api.ISendInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.SendInvitationRequest} SendInvitationRequest instance
             */
            SendInvitationRequest.create = function create(properties) {
                return new SendInvitationRequest(properties);
            };

            /**
             * Encodes the specified SendInvitationRequest message. Does not implicitly {@link streem.api.SendInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {streem.api.ISendInvitationRequest} m SendInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(10).string(m.fromUserSid);
                if (m.toUserName != null && Object.hasOwnProperty.call(m, "toUserName"))
                    w.uint32(18).string(m.toUserName);
                if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                    w.uint32(26).string(m.phone);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(34).string(m.referenceId);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(42).string(m.companyCode);
                if (m.deliveryType != null && Object.hasOwnProperty.call(m, "deliveryType"))
                    w.uint32(48).int32(m.deliveryType);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(58).string(m.integrationId);
                return w;
            };

            /**
             * Decodes a SendInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SendInvitationRequest} SendInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SendInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 2: {
                            m.toUserName = r.string();
                            break;
                        }
                    case 3: {
                            m.phone = r.string();
                            break;
                        }
                    case 4: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 5: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 6: {
                            m.deliveryType = r.int32();
                            break;
                        }
                    case 7: {
                            m.integrationId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SendInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SendInvitationRequest} SendInvitationRequest
             */
            SendInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SendInvitationRequest)
                    return d;
                var m = new $root.streem.api.SendInvitationRequest();
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.toUserName != null) {
                    m.toUserName = String(d.toUserName);
                }
                if (d.phone != null) {
                    m.phone = String(d.phone);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                switch (d.deliveryType) {
                default:
                    if (typeof d.deliveryType === "number") {
                        m.deliveryType = d.deliveryType;
                        break;
                    }
                    break;
                case "DELIVERY_TYPE_INVALID":
                case 0:
                    m.deliveryType = 0;
                    break;
                case "DELIVERY_TYPE_SMS":
                case 1:
                    m.deliveryType = 1;
                    break;
                case "DELIVERY_TYPE_EMAIL":
                case 2:
                    m.deliveryType = 2;
                    break;
                case "DELIVERY_TYPE_LINK":
                case 3:
                    m.deliveryType = 3;
                    break;
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                return m;
            };

            /**
             * Creates a plain object from a SendInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {streem.api.SendInvitationRequest} m SendInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.fromUserSid = "";
                    d.toUserName = "";
                    d.phone = "";
                    d.referenceId = "";
                    d.companyCode = "";
                    d.deliveryType = o.enums === String ? "DELIVERY_TYPE_INVALID" : 0;
                    d.integrationId = "";
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.toUserName != null && m.hasOwnProperty("toUserName")) {
                    d.toUserName = m.toUserName;
                }
                if (m.phone != null && m.hasOwnProperty("phone")) {
                    d.phone = m.phone;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.deliveryType != null && m.hasOwnProperty("deliveryType")) {
                    d.deliveryType = o.enums === String ? $root.streem.api.DeliveryType[m.deliveryType] === undefined ? m.deliveryType : $root.streem.api.DeliveryType[m.deliveryType] : m.deliveryType;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                return d;
            };

            /**
             * Converts this SendInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SendInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.SendInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SendInvitationRequest";
            };

            return SendInvitationRequest;
        })();

        api.SendInvitationResponse = (function() {

            /**
             * Properties of a SendInvitationResponse.
             * @memberof streem.api
             * @interface ISendInvitationResponse
             * @property {streem.api.IInvitation|null} [invitation] SendInvitationResponse invitation
             * @property {boolean|null} [messageDeliveredSuccessfully] SendInvitationResponse messageDeliveredSuccessfully
             * @property {string|null} [link] SendInvitationResponse link
             * @property {boolean|null} [landline] SendInvitationResponse landline
             */

            /**
             * Constructs a new SendInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a SendInvitationResponse.
             * @implements ISendInvitationResponse
             * @constructor
             * @param {streem.api.ISendInvitationResponse=} [p] Properties to set
             */
            function SendInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SendInvitationResponse invitation.
             * @member {streem.api.IInvitation|null|undefined} invitation
             * @memberof streem.api.SendInvitationResponse
             * @instance
             */
            SendInvitationResponse.prototype.invitation = null;

            /**
             * SendInvitationResponse messageDeliveredSuccessfully.
             * @member {boolean} messageDeliveredSuccessfully
             * @memberof streem.api.SendInvitationResponse
             * @instance
             */
            SendInvitationResponse.prototype.messageDeliveredSuccessfully = false;

            /**
             * SendInvitationResponse link.
             * @member {string} link
             * @memberof streem.api.SendInvitationResponse
             * @instance
             */
            SendInvitationResponse.prototype.link = "";

            /**
             * SendInvitationResponse landline.
             * @member {boolean} landline
             * @memberof streem.api.SendInvitationResponse
             * @instance
             */
            SendInvitationResponse.prototype.landline = false;

            /**
             * Creates a new SendInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {streem.api.ISendInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.SendInvitationResponse} SendInvitationResponse instance
             */
            SendInvitationResponse.create = function create(properties) {
                return new SendInvitationResponse(properties);
            };

            /**
             * Encodes the specified SendInvitationResponse message. Does not implicitly {@link streem.api.SendInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {streem.api.ISendInvitationResponse} m SendInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
                    $root.streem.api.Invitation.encode(m.invitation, w.uint32(10).fork()).ldelim();
                if (m.messageDeliveredSuccessfully != null && Object.hasOwnProperty.call(m, "messageDeliveredSuccessfully"))
                    w.uint32(16).bool(m.messageDeliveredSuccessfully);
                if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                    w.uint32(26).string(m.link);
                if (m.landline != null && Object.hasOwnProperty.call(m, "landline"))
                    w.uint32(32).bool(m.landline);
                return w;
            };

            /**
             * Decodes a SendInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SendInvitationResponse} SendInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SendInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitation = $root.streem.api.Invitation.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.messageDeliveredSuccessfully = r.bool();
                            break;
                        }
                    case 3: {
                            m.link = r.string();
                            break;
                        }
                    case 4: {
                            m.landline = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SendInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SendInvitationResponse} SendInvitationResponse
             */
            SendInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SendInvitationResponse)
                    return d;
                var m = new $root.streem.api.SendInvitationResponse();
                if (d.invitation != null) {
                    if (typeof d.invitation !== "object")
                        throw TypeError(".streem.api.SendInvitationResponse.invitation: object expected");
                    m.invitation = $root.streem.api.Invitation.fromObject(d.invitation);
                }
                if (d.messageDeliveredSuccessfully != null) {
                    m.messageDeliveredSuccessfully = Boolean(d.messageDeliveredSuccessfully);
                }
                if (d.link != null) {
                    m.link = String(d.link);
                }
                if (d.landline != null) {
                    m.landline = Boolean(d.landline);
                }
                return m;
            };

            /**
             * Creates a plain object from a SendInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {streem.api.SendInvitationResponse} m SendInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitation = null;
                    d.messageDeliveredSuccessfully = false;
                    d.link = "";
                    d.landline = false;
                }
                if (m.invitation != null && m.hasOwnProperty("invitation")) {
                    d.invitation = $root.streem.api.Invitation.toObject(m.invitation, o);
                }
                if (m.messageDeliveredSuccessfully != null && m.hasOwnProperty("messageDeliveredSuccessfully")) {
                    d.messageDeliveredSuccessfully = m.messageDeliveredSuccessfully;
                }
                if (m.link != null && m.hasOwnProperty("link")) {
                    d.link = m.link;
                }
                if (m.landline != null && m.hasOwnProperty("landline")) {
                    d.landline = m.landline;
                }
                return d;
            };

            /**
             * Converts this SendInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SendInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.SendInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SendInvitationResponse";
            };

            return SendInvitationResponse;
        })();

        api.AuthToken = (function() {

            /**
             * Constructs a new AuthToken service.
             * @memberof streem.api
             * @classdesc Represents an AuthToken
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function AuthToken(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (AuthToken.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AuthToken;

            /**
             * Creates new AuthToken service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.AuthToken
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {AuthToken} RPC service. Useful where requests and/or responses are streamed.
             */
            AuthToken.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.AuthToken#grantToken}.
             * @memberof streem.api.AuthToken
             * @typedef GrantTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GrantTokenResponse} [response] GrantTokenResponse
             */

            /**
             * Calls GrantToken.
             * @function grantToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGrantTokenRequest} request GrantTokenRequest message or plain object
             * @param {streem.api.AuthToken.GrantTokenCallback} callback Node-style callback called with the error, if any, and GrantTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthToken.prototype.grantToken = function grantToken(request, callback) {
                return this.rpcCall(grantToken, $root.streem.api.GrantTokenRequest, $root.streem.api.GrantTokenResponse, request, callback);
            }, "name", { value: "GrantToken" });

            /**
             * Calls GrantToken.
             * @function grantToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGrantTokenRequest} request GrantTokenRequest message or plain object
             * @returns {Promise<streem.api.GrantTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthToken#grantTokenFromInvitation}.
             * @memberof streem.api.AuthToken
             * @typedef GrantTokenFromInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GrantTokenFromInvitationResponse} [response] GrantTokenFromInvitationResponse
             */

            /**
             * Calls GrantTokenFromInvitation.
             * @function grantTokenFromInvitation
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGrantTokenFromInvitationRequest} request GrantTokenFromInvitationRequest message or plain object
             * @param {streem.api.AuthToken.GrantTokenFromInvitationCallback} callback Node-style callback called with the error, if any, and GrantTokenFromInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthToken.prototype.grantTokenFromInvitation = function grantTokenFromInvitation(request, callback) {
                return this.rpcCall(grantTokenFromInvitation, $root.streem.api.GrantTokenFromInvitationRequest, $root.streem.api.GrantTokenFromInvitationResponse, request, callback);
            }, "name", { value: "GrantTokenFromInvitation" });

            /**
             * Calls GrantTokenFromInvitation.
             * @function grantTokenFromInvitation
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGrantTokenFromInvitationRequest} request GrantTokenFromInvitationRequest message or plain object
             * @returns {Promise<streem.api.GrantTokenFromInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthToken#createStreemSdkToken}.
             * @memberof streem.api.AuthToken
             * @typedef CreateStreemSdkTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateStreemSdkTokenResponse} [response] CreateStreemSdkTokenResponse
             */

            /**
             * Calls CreateStreemSdkToken.
             * @function createStreemSdkToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.ICreateStreemSdkTokenRequest} request CreateStreemSdkTokenRequest message or plain object
             * @param {streem.api.AuthToken.CreateStreemSdkTokenCallback} callback Node-style callback called with the error, if any, and CreateStreemSdkTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthToken.prototype.createStreemSdkToken = function createStreemSdkToken(request, callback) {
                return this.rpcCall(createStreemSdkToken, $root.streem.api.CreateStreemSdkTokenRequest, $root.streem.api.CreateStreemSdkTokenResponse, request, callback);
            }, "name", { value: "CreateStreemSdkToken" });

            /**
             * Calls CreateStreemSdkToken.
             * @function createStreemSdkToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.ICreateStreemSdkTokenRequest} request CreateStreemSdkTokenRequest message or plain object
             * @returns {Promise<streem.api.CreateStreemSdkTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthToken#createSupportToken}.
             * @memberof streem.api.AuthToken
             * @typedef CreateSupportTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateSupportTokenResponse} [response] CreateSupportTokenResponse
             */

            /**
             * Calls CreateSupportToken.
             * @function createSupportToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.ICreateSupportTokenRequest} request CreateSupportTokenRequest message or plain object
             * @param {streem.api.AuthToken.CreateSupportTokenCallback} callback Node-style callback called with the error, if any, and CreateSupportTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthToken.prototype.createSupportToken = function createSupportToken(request, callback) {
                return this.rpcCall(createSupportToken, $root.streem.api.CreateSupportTokenRequest, $root.streem.api.CreateSupportTokenResponse, request, callback);
            }, "name", { value: "CreateSupportToken" });

            /**
             * Calls CreateSupportToken.
             * @function createSupportToken
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.ICreateSupportTokenRequest} request CreateSupportTokenRequest message or plain object
             * @returns {Promise<streem.api.CreateSupportTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthToken#getCurrentAuthIdentity}.
             * @memberof streem.api.AuthToken
             * @typedef GetCurrentAuthIdentityCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCurrentAuthIdentityResponse} [response] GetCurrentAuthIdentityResponse
             */

            /**
             * Calls GetCurrentAuthIdentity.
             * @function getCurrentAuthIdentity
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGetCurrentAuthIdentityRequest} request GetCurrentAuthIdentityRequest message or plain object
             * @param {streem.api.AuthToken.GetCurrentAuthIdentityCallback} callback Node-style callback called with the error, if any, and GetCurrentAuthIdentityResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthToken.prototype.getCurrentAuthIdentity = function getCurrentAuthIdentity(request, callback) {
                return this.rpcCall(getCurrentAuthIdentity, $root.streem.api.GetCurrentAuthIdentityRequest, $root.streem.api.GetCurrentAuthIdentityResponse, request, callback);
            }, "name", { value: "GetCurrentAuthIdentity" });

            /**
             * Calls GetCurrentAuthIdentity.
             * @function getCurrentAuthIdentity
             * @memberof streem.api.AuthToken
             * @instance
             * @param {streem.api.IGetCurrentAuthIdentityRequest} request GetCurrentAuthIdentityRequest message or plain object
             * @returns {Promise<streem.api.GetCurrentAuthIdentityResponse>} Promise
             * @variation 2
             */

            return AuthToken;
        })();

        api.GrantTokenRequest = (function() {

            /**
             * Properties of a GrantTokenRequest.
             * @memberof streem.api
             * @interface IGrantTokenRequest
             * @property {string|null} [clientId] GrantTokenRequest clientId
             * @property {string|null} [grantType] GrantTokenRequest grantType
             * @property {string|null} [scope] GrantTokenRequest scope
             * @property {string|null} [streemCompanyCode] GrantTokenRequest streemCompanyCode
             * @property {string|null} [audience] GrantTokenRequest audience
             * @property {string|null} [assertion] GrantTokenRequest assertion
             * @property {string|null} [refreshToken] GrantTokenRequest refreshToken
             * @property {string|null} [username] GrantTokenRequest username
             * @property {string|null} [password] GrantTokenRequest password
             */

            /**
             * Constructs a new GrantTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a GrantTokenRequest.
             * @implements IGrantTokenRequest
             * @constructor
             * @param {streem.api.IGrantTokenRequest=} [p] Properties to set
             */
            function GrantTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GrantTokenRequest clientId.
             * @member {string} clientId
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.clientId = "";

            /**
             * GrantTokenRequest grantType.
             * @member {string} grantType
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.grantType = "";

            /**
             * GrantTokenRequest scope.
             * @member {string} scope
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.scope = "";

            /**
             * GrantTokenRequest streemCompanyCode.
             * @member {string} streemCompanyCode
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.streemCompanyCode = "";

            /**
             * GrantTokenRequest audience.
             * @member {string} audience
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.audience = "";

            /**
             * GrantTokenRequest assertion.
             * @member {string} assertion
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.assertion = "";

            /**
             * GrantTokenRequest refreshToken.
             * @member {string} refreshToken
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.refreshToken = "";

            /**
             * GrantTokenRequest username.
             * @member {string} username
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.username = "";

            /**
             * GrantTokenRequest password.
             * @member {string} password
             * @memberof streem.api.GrantTokenRequest
             * @instance
             */
            GrantTokenRequest.prototype.password = "";

            /**
             * Creates a new GrantTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {streem.api.IGrantTokenRequest=} [properties] Properties to set
             * @returns {streem.api.GrantTokenRequest} GrantTokenRequest instance
             */
            GrantTokenRequest.create = function create(properties) {
                return new GrantTokenRequest(properties);
            };

            /**
             * Encodes the specified GrantTokenRequest message. Does not implicitly {@link streem.api.GrantTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {streem.api.IGrantTokenRequest} m GrantTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrantTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.clientId != null && Object.hasOwnProperty.call(m, "clientId"))
                    w.uint32(10).string(m.clientId);
                if (m.grantType != null && Object.hasOwnProperty.call(m, "grantType"))
                    w.uint32(18).string(m.grantType);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(26).string(m.scope);
                if (m.streemCompanyCode != null && Object.hasOwnProperty.call(m, "streemCompanyCode"))
                    w.uint32(402).string(m.streemCompanyCode);
                if (m.audience != null && Object.hasOwnProperty.call(m, "audience"))
                    w.uint32(410).string(m.audience);
                if (m.assertion != null && Object.hasOwnProperty.call(m, "assertion"))
                    w.uint32(802).string(m.assertion);
                if (m.refreshToken != null && Object.hasOwnProperty.call(m, "refreshToken"))
                    w.uint32(1602).string(m.refreshToken);
                if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                    w.uint32(2402).string(m.username);
                if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                    w.uint32(2410).string(m.password);
                return w;
            };

            /**
             * Decodes a GrantTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GrantTokenRequest} GrantTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrantTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GrantTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.clientId = r.string();
                            break;
                        }
                    case 2: {
                            m.grantType = r.string();
                            break;
                        }
                    case 3: {
                            m.scope = r.string();
                            break;
                        }
                    case 50: {
                            m.streemCompanyCode = r.string();
                            break;
                        }
                    case 51: {
                            m.audience = r.string();
                            break;
                        }
                    case 100: {
                            m.assertion = r.string();
                            break;
                        }
                    case 200: {
                            m.refreshToken = r.string();
                            break;
                        }
                    case 300: {
                            m.username = r.string();
                            break;
                        }
                    case 301: {
                            m.password = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GrantTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GrantTokenRequest} GrantTokenRequest
             */
            GrantTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GrantTokenRequest)
                    return d;
                var m = new $root.streem.api.GrantTokenRequest();
                if (d.clientId != null) {
                    m.clientId = String(d.clientId);
                }
                if (d.grantType != null) {
                    m.grantType = String(d.grantType);
                }
                if (d.scope != null) {
                    m.scope = String(d.scope);
                }
                if (d.streemCompanyCode != null) {
                    m.streemCompanyCode = String(d.streemCompanyCode);
                }
                if (d.audience != null) {
                    m.audience = String(d.audience);
                }
                if (d.assertion != null) {
                    m.assertion = String(d.assertion);
                }
                if (d.refreshToken != null) {
                    m.refreshToken = String(d.refreshToken);
                }
                if (d.username != null) {
                    m.username = String(d.username);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                return m;
            };

            /**
             * Creates a plain object from a GrantTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {streem.api.GrantTokenRequest} m GrantTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GrantTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.clientId = "";
                    d.grantType = "";
                    d.scope = "";
                    d.streemCompanyCode = "";
                    d.audience = "";
                    d.assertion = "";
                    d.refreshToken = "";
                    d.username = "";
                    d.password = "";
                }
                if (m.clientId != null && m.hasOwnProperty("clientId")) {
                    d.clientId = m.clientId;
                }
                if (m.grantType != null && m.hasOwnProperty("grantType")) {
                    d.grantType = m.grantType;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = m.scope;
                }
                if (m.streemCompanyCode != null && m.hasOwnProperty("streemCompanyCode")) {
                    d.streemCompanyCode = m.streemCompanyCode;
                }
                if (m.audience != null && m.hasOwnProperty("audience")) {
                    d.audience = m.audience;
                }
                if (m.assertion != null && m.hasOwnProperty("assertion")) {
                    d.assertion = m.assertion;
                }
                if (m.refreshToken != null && m.hasOwnProperty("refreshToken")) {
                    d.refreshToken = m.refreshToken;
                }
                if (m.username != null && m.hasOwnProperty("username")) {
                    d.username = m.username;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                return d;
            };

            /**
             * Converts this GrantTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GrantTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GrantTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GrantTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.GrantTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GrantTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GrantTokenRequest";
            };

            return GrantTokenRequest;
        })();

        api.GrantTokenResponse = (function() {

            /**
             * Properties of a GrantTokenResponse.
             * @memberof streem.api
             * @interface IGrantTokenResponse
             * @property {string|null} [accessToken] GrantTokenResponse accessToken
             * @property {string|null} [tokenType] GrantTokenResponse tokenType
             * @property {number|null} [expiresIn] GrantTokenResponse expiresIn
             * @property {string|null} [scope] GrantTokenResponse scope
             * @property {string|null} [refreshToken] GrantTokenResponse refreshToken
             * @property {string|null} [streemCompanyId] GrantTokenResponse streemCompanyId
             * @property {string|null} [streemUserId] GrantTokenResponse streemUserId
             */

            /**
             * Constructs a new GrantTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a GrantTokenResponse.
             * @implements IGrantTokenResponse
             * @constructor
             * @param {streem.api.IGrantTokenResponse=} [p] Properties to set
             */
            function GrantTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GrantTokenResponse accessToken.
             * @member {string} accessToken
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.accessToken = "";

            /**
             * GrantTokenResponse tokenType.
             * @member {string} tokenType
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.tokenType = "";

            /**
             * GrantTokenResponse expiresIn.
             * @member {number} expiresIn
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.expiresIn = 0;

            /**
             * GrantTokenResponse scope.
             * @member {string} scope
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.scope = "";

            /**
             * GrantTokenResponse refreshToken.
             * @member {string} refreshToken
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.refreshToken = "";

            /**
             * GrantTokenResponse streemCompanyId.
             * @member {string} streemCompanyId
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.streemCompanyId = "";

            /**
             * GrantTokenResponse streemUserId.
             * @member {string} streemUserId
             * @memberof streem.api.GrantTokenResponse
             * @instance
             */
            GrantTokenResponse.prototype.streemUserId = "";

            /**
             * Creates a new GrantTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {streem.api.IGrantTokenResponse=} [properties] Properties to set
             * @returns {streem.api.GrantTokenResponse} GrantTokenResponse instance
             */
            GrantTokenResponse.create = function create(properties) {
                return new GrantTokenResponse(properties);
            };

            /**
             * Encodes the specified GrantTokenResponse message. Does not implicitly {@link streem.api.GrantTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {streem.api.IGrantTokenResponse} m GrantTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrantTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.accessToken != null && Object.hasOwnProperty.call(m, "accessToken"))
                    w.uint32(10).string(m.accessToken);
                if (m.tokenType != null && Object.hasOwnProperty.call(m, "tokenType"))
                    w.uint32(18).string(m.tokenType);
                if (m.expiresIn != null && Object.hasOwnProperty.call(m, "expiresIn"))
                    w.uint32(24).uint32(m.expiresIn);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(34).string(m.scope);
                if (m.refreshToken != null && Object.hasOwnProperty.call(m, "refreshToken"))
                    w.uint32(42).string(m.refreshToken);
                if (m.streemCompanyId != null && Object.hasOwnProperty.call(m, "streemCompanyId"))
                    w.uint32(50).string(m.streemCompanyId);
                if (m.streemUserId != null && Object.hasOwnProperty.call(m, "streemUserId"))
                    w.uint32(58).string(m.streemUserId);
                return w;
            };

            /**
             * Decodes a GrantTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GrantTokenResponse} GrantTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrantTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GrantTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.accessToken = r.string();
                            break;
                        }
                    case 2: {
                            m.tokenType = r.string();
                            break;
                        }
                    case 3: {
                            m.expiresIn = r.uint32();
                            break;
                        }
                    case 4: {
                            m.scope = r.string();
                            break;
                        }
                    case 5: {
                            m.refreshToken = r.string();
                            break;
                        }
                    case 6: {
                            m.streemCompanyId = r.string();
                            break;
                        }
                    case 7: {
                            m.streemUserId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GrantTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GrantTokenResponse} GrantTokenResponse
             */
            GrantTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GrantTokenResponse)
                    return d;
                var m = new $root.streem.api.GrantTokenResponse();
                if (d.accessToken != null) {
                    m.accessToken = String(d.accessToken);
                }
                if (d.tokenType != null) {
                    m.tokenType = String(d.tokenType);
                }
                if (d.expiresIn != null) {
                    m.expiresIn = d.expiresIn >>> 0;
                }
                if (d.scope != null) {
                    m.scope = String(d.scope);
                }
                if (d.refreshToken != null) {
                    m.refreshToken = String(d.refreshToken);
                }
                if (d.streemCompanyId != null) {
                    m.streemCompanyId = String(d.streemCompanyId);
                }
                if (d.streemUserId != null) {
                    m.streemUserId = String(d.streemUserId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GrantTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {streem.api.GrantTokenResponse} m GrantTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GrantTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.accessToken = "";
                    d.tokenType = "";
                    d.expiresIn = 0;
                    d.scope = "";
                    d.refreshToken = "";
                    d.streemCompanyId = "";
                    d.streemUserId = "";
                }
                if (m.accessToken != null && m.hasOwnProperty("accessToken")) {
                    d.accessToken = m.accessToken;
                }
                if (m.tokenType != null && m.hasOwnProperty("tokenType")) {
                    d.tokenType = m.tokenType;
                }
                if (m.expiresIn != null && m.hasOwnProperty("expiresIn")) {
                    d.expiresIn = m.expiresIn;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = m.scope;
                }
                if (m.refreshToken != null && m.hasOwnProperty("refreshToken")) {
                    d.refreshToken = m.refreshToken;
                }
                if (m.streemCompanyId != null && m.hasOwnProperty("streemCompanyId")) {
                    d.streemCompanyId = m.streemCompanyId;
                }
                if (m.streemUserId != null && m.hasOwnProperty("streemUserId")) {
                    d.streemUserId = m.streemUserId;
                }
                return d;
            };

            /**
             * Converts this GrantTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GrantTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GrantTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GrantTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.GrantTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GrantTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GrantTokenResponse";
            };

            return GrantTokenResponse;
        })();

        api.GrantTokenFromInvitationRequest = (function() {

            /**
             * Properties of a GrantTokenFromInvitationRequest.
             * @memberof streem.api
             * @interface IGrantTokenFromInvitationRequest
             * @property {string|null} [invitationCode] GrantTokenFromInvitationRequest invitationCode
             * @property {string|null} [appSid] GrantTokenFromInvitationRequest appSid
             * @property {string|null} [resource] GrantTokenFromInvitationRequest resource
             */

            /**
             * Constructs a new GrantTokenFromInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a GrantTokenFromInvitationRequest.
             * @implements IGrantTokenFromInvitationRequest
             * @constructor
             * @param {streem.api.IGrantTokenFromInvitationRequest=} [p] Properties to set
             */
            function GrantTokenFromInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GrantTokenFromInvitationRequest invitationCode.
             * @member {string} invitationCode
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @instance
             */
            GrantTokenFromInvitationRequest.prototype.invitationCode = "";

            /**
             * GrantTokenFromInvitationRequest appSid.
             * @member {string} appSid
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @instance
             */
            GrantTokenFromInvitationRequest.prototype.appSid = "";

            /**
             * GrantTokenFromInvitationRequest resource.
             * @member {string} resource
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @instance
             */
            GrantTokenFromInvitationRequest.prototype.resource = "";

            /**
             * Creates a new GrantTokenFromInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {streem.api.IGrantTokenFromInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.GrantTokenFromInvitationRequest} GrantTokenFromInvitationRequest instance
             */
            GrantTokenFromInvitationRequest.create = function create(properties) {
                return new GrantTokenFromInvitationRequest(properties);
            };

            /**
             * Encodes the specified GrantTokenFromInvitationRequest message. Does not implicitly {@link streem.api.GrantTokenFromInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {streem.api.IGrantTokenFromInvitationRequest} m GrantTokenFromInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrantTokenFromInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitationCode != null && Object.hasOwnProperty.call(m, "invitationCode"))
                    w.uint32(10).string(m.invitationCode);
                if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                    w.uint32(18).string(m.appSid);
                if (m.resource != null && Object.hasOwnProperty.call(m, "resource"))
                    w.uint32(34).string(m.resource);
                return w;
            };

            /**
             * Decodes a GrantTokenFromInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GrantTokenFromInvitationRequest} GrantTokenFromInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrantTokenFromInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GrantTokenFromInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitationCode = r.string();
                            break;
                        }
                    case 2: {
                            m.appSid = r.string();
                            break;
                        }
                    case 4: {
                            m.resource = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GrantTokenFromInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GrantTokenFromInvitationRequest} GrantTokenFromInvitationRequest
             */
            GrantTokenFromInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GrantTokenFromInvitationRequest)
                    return d;
                var m = new $root.streem.api.GrantTokenFromInvitationRequest();
                if (d.invitationCode != null) {
                    m.invitationCode = String(d.invitationCode);
                }
                if (d.appSid != null) {
                    m.appSid = String(d.appSid);
                }
                if (d.resource != null) {
                    m.resource = String(d.resource);
                }
                return m;
            };

            /**
             * Creates a plain object from a GrantTokenFromInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {streem.api.GrantTokenFromInvitationRequest} m GrantTokenFromInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GrantTokenFromInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitationCode = "";
                    d.appSid = "";
                    d.resource = "";
                }
                if (m.invitationCode != null && m.hasOwnProperty("invitationCode")) {
                    d.invitationCode = m.invitationCode;
                }
                if (m.appSid != null && m.hasOwnProperty("appSid")) {
                    d.appSid = m.appSid;
                }
                if (m.resource != null && m.hasOwnProperty("resource")) {
                    d.resource = m.resource;
                }
                return d;
            };

            /**
             * Converts this GrantTokenFromInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GrantTokenFromInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GrantTokenFromInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.GrantTokenFromInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GrantTokenFromInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GrantTokenFromInvitationRequest";
            };

            return GrantTokenFromInvitationRequest;
        })();

        api.GrantTokenFromInvitationResponse = (function() {

            /**
             * Properties of a GrantTokenFromInvitationResponse.
             * @memberof streem.api
             * @interface IGrantTokenFromInvitationResponse
             * @property {string|null} [accessToken] GrantTokenFromInvitationResponse accessToken
             * @property {string|null} [tokenType] GrantTokenFromInvitationResponse tokenType
             * @property {number|null} [expiresIn] GrantTokenFromInvitationResponse expiresIn
             * @property {string|null} [scope] GrantTokenFromInvitationResponse scope
             * @property {string|null} [refreshToken] GrantTokenFromInvitationResponse refreshToken
             * @property {string|null} [streemCompanyId] GrantTokenFromInvitationResponse streemCompanyId
             * @property {string|null} [streemUserId] GrantTokenFromInvitationResponse streemUserId
             */

            /**
             * Constructs a new GrantTokenFromInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a GrantTokenFromInvitationResponse.
             * @implements IGrantTokenFromInvitationResponse
             * @constructor
             * @param {streem.api.IGrantTokenFromInvitationResponse=} [p] Properties to set
             */
            function GrantTokenFromInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GrantTokenFromInvitationResponse accessToken.
             * @member {string} accessToken
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.accessToken = "";

            /**
             * GrantTokenFromInvitationResponse tokenType.
             * @member {string} tokenType
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.tokenType = "";

            /**
             * GrantTokenFromInvitationResponse expiresIn.
             * @member {number} expiresIn
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.expiresIn = 0;

            /**
             * GrantTokenFromInvitationResponse scope.
             * @member {string} scope
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.scope = "";

            /**
             * GrantTokenFromInvitationResponse refreshToken.
             * @member {string} refreshToken
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.refreshToken = "";

            /**
             * GrantTokenFromInvitationResponse streemCompanyId.
             * @member {string} streemCompanyId
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.streemCompanyId = "";

            /**
             * GrantTokenFromInvitationResponse streemUserId.
             * @member {string} streemUserId
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             */
            GrantTokenFromInvitationResponse.prototype.streemUserId = "";

            /**
             * Creates a new GrantTokenFromInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {streem.api.IGrantTokenFromInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.GrantTokenFromInvitationResponse} GrantTokenFromInvitationResponse instance
             */
            GrantTokenFromInvitationResponse.create = function create(properties) {
                return new GrantTokenFromInvitationResponse(properties);
            };

            /**
             * Encodes the specified GrantTokenFromInvitationResponse message. Does not implicitly {@link streem.api.GrantTokenFromInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {streem.api.IGrantTokenFromInvitationResponse} m GrantTokenFromInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrantTokenFromInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.accessToken != null && Object.hasOwnProperty.call(m, "accessToken"))
                    w.uint32(10).string(m.accessToken);
                if (m.tokenType != null && Object.hasOwnProperty.call(m, "tokenType"))
                    w.uint32(18).string(m.tokenType);
                if (m.expiresIn != null && Object.hasOwnProperty.call(m, "expiresIn"))
                    w.uint32(24).uint32(m.expiresIn);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(34).string(m.scope);
                if (m.refreshToken != null && Object.hasOwnProperty.call(m, "refreshToken"))
                    w.uint32(42).string(m.refreshToken);
                if (m.streemCompanyId != null && Object.hasOwnProperty.call(m, "streemCompanyId"))
                    w.uint32(50).string(m.streemCompanyId);
                if (m.streemUserId != null && Object.hasOwnProperty.call(m, "streemUserId"))
                    w.uint32(58).string(m.streemUserId);
                return w;
            };

            /**
             * Decodes a GrantTokenFromInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GrantTokenFromInvitationResponse} GrantTokenFromInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrantTokenFromInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GrantTokenFromInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.accessToken = r.string();
                            break;
                        }
                    case 2: {
                            m.tokenType = r.string();
                            break;
                        }
                    case 3: {
                            m.expiresIn = r.uint32();
                            break;
                        }
                    case 4: {
                            m.scope = r.string();
                            break;
                        }
                    case 5: {
                            m.refreshToken = r.string();
                            break;
                        }
                    case 6: {
                            m.streemCompanyId = r.string();
                            break;
                        }
                    case 7: {
                            m.streemUserId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GrantTokenFromInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GrantTokenFromInvitationResponse} GrantTokenFromInvitationResponse
             */
            GrantTokenFromInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GrantTokenFromInvitationResponse)
                    return d;
                var m = new $root.streem.api.GrantTokenFromInvitationResponse();
                if (d.accessToken != null) {
                    m.accessToken = String(d.accessToken);
                }
                if (d.tokenType != null) {
                    m.tokenType = String(d.tokenType);
                }
                if (d.expiresIn != null) {
                    m.expiresIn = d.expiresIn >>> 0;
                }
                if (d.scope != null) {
                    m.scope = String(d.scope);
                }
                if (d.refreshToken != null) {
                    m.refreshToken = String(d.refreshToken);
                }
                if (d.streemCompanyId != null) {
                    m.streemCompanyId = String(d.streemCompanyId);
                }
                if (d.streemUserId != null) {
                    m.streemUserId = String(d.streemUserId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GrantTokenFromInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {streem.api.GrantTokenFromInvitationResponse} m GrantTokenFromInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GrantTokenFromInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.accessToken = "";
                    d.tokenType = "";
                    d.expiresIn = 0;
                    d.scope = "";
                    d.refreshToken = "";
                    d.streemCompanyId = "";
                    d.streemUserId = "";
                }
                if (m.accessToken != null && m.hasOwnProperty("accessToken")) {
                    d.accessToken = m.accessToken;
                }
                if (m.tokenType != null && m.hasOwnProperty("tokenType")) {
                    d.tokenType = m.tokenType;
                }
                if (m.expiresIn != null && m.hasOwnProperty("expiresIn")) {
                    d.expiresIn = m.expiresIn;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = m.scope;
                }
                if (m.refreshToken != null && m.hasOwnProperty("refreshToken")) {
                    d.refreshToken = m.refreshToken;
                }
                if (m.streemCompanyId != null && m.hasOwnProperty("streemCompanyId")) {
                    d.streemCompanyId = m.streemCompanyId;
                }
                if (m.streemUserId != null && m.hasOwnProperty("streemUserId")) {
                    d.streemUserId = m.streemUserId;
                }
                return d;
            };

            /**
             * Converts this GrantTokenFromInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GrantTokenFromInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GrantTokenFromInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.GrantTokenFromInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GrantTokenFromInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GrantTokenFromInvitationResponse";
            };

            return GrantTokenFromInvitationResponse;
        })();

        api.CreateStreemSdkTokenRequest = (function() {

            /**
             * Properties of a CreateStreemSdkTokenRequest.
             * @memberof streem.api
             * @interface ICreateStreemSdkTokenRequest
             */

            /**
             * Constructs a new CreateStreemSdkTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemSdkTokenRequest.
             * @implements ICreateStreemSdkTokenRequest
             * @constructor
             * @param {streem.api.ICreateStreemSdkTokenRequest=} [p] Properties to set
             */
            function CreateStreemSdkTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new CreateStreemSdkTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {streem.api.ICreateStreemSdkTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateStreemSdkTokenRequest} CreateStreemSdkTokenRequest instance
             */
            CreateStreemSdkTokenRequest.create = function create(properties) {
                return new CreateStreemSdkTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateStreemSdkTokenRequest message. Does not implicitly {@link streem.api.CreateStreemSdkTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {streem.api.ICreateStreemSdkTokenRequest} m CreateStreemSdkTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemSdkTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a CreateStreemSdkTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemSdkTokenRequest} CreateStreemSdkTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemSdkTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemSdkTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemSdkTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemSdkTokenRequest} CreateStreemSdkTokenRequest
             */
            CreateStreemSdkTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemSdkTokenRequest)
                    return d;
                return new $root.streem.api.CreateStreemSdkTokenRequest();
            };

            /**
             * Creates a plain object from a CreateStreemSdkTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {streem.api.CreateStreemSdkTokenRequest} m CreateStreemSdkTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemSdkTokenRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateStreemSdkTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemSdkTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemSdkTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemSdkTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemSdkTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemSdkTokenRequest";
            };

            return CreateStreemSdkTokenRequest;
        })();

        api.CreateStreemSdkTokenResponse = (function() {

            /**
             * Properties of a CreateStreemSdkTokenResponse.
             * @memberof streem.api
             * @interface ICreateStreemSdkTokenResponse
             * @property {string|null} [sdkToken] CreateStreemSdkTokenResponse sdkToken
             */

            /**
             * Constructs a new CreateStreemSdkTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemSdkTokenResponse.
             * @implements ICreateStreemSdkTokenResponse
             * @constructor
             * @param {streem.api.ICreateStreemSdkTokenResponse=} [p] Properties to set
             */
            function CreateStreemSdkTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateStreemSdkTokenResponse sdkToken.
             * @member {string} sdkToken
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @instance
             */
            CreateStreemSdkTokenResponse.prototype.sdkToken = "";

            /**
             * Creates a new CreateStreemSdkTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {streem.api.ICreateStreemSdkTokenResponse=} [properties] Properties to set
             * @returns {streem.api.CreateStreemSdkTokenResponse} CreateStreemSdkTokenResponse instance
             */
            CreateStreemSdkTokenResponse.create = function create(properties) {
                return new CreateStreemSdkTokenResponse(properties);
            };

            /**
             * Encodes the specified CreateStreemSdkTokenResponse message. Does not implicitly {@link streem.api.CreateStreemSdkTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {streem.api.ICreateStreemSdkTokenResponse} m CreateStreemSdkTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemSdkTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sdkToken != null && Object.hasOwnProperty.call(m, "sdkToken"))
                    w.uint32(10).string(m.sdkToken);
                return w;
            };

            /**
             * Decodes a CreateStreemSdkTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemSdkTokenResponse} CreateStreemSdkTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemSdkTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemSdkTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sdkToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemSdkTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemSdkTokenResponse} CreateStreemSdkTokenResponse
             */
            CreateStreemSdkTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemSdkTokenResponse)
                    return d;
                var m = new $root.streem.api.CreateStreemSdkTokenResponse();
                if (d.sdkToken != null) {
                    m.sdkToken = String(d.sdkToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateStreemSdkTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {streem.api.CreateStreemSdkTokenResponse} m CreateStreemSdkTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemSdkTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sdkToken = "";
                }
                if (m.sdkToken != null && m.hasOwnProperty("sdkToken")) {
                    d.sdkToken = m.sdkToken;
                }
                return d;
            };

            /**
             * Converts this CreateStreemSdkTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemSdkTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemSdkTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemSdkTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemSdkTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemSdkTokenResponse";
            };

            return CreateStreemSdkTokenResponse;
        })();

        api.CreateSupportTokenRequest = (function() {

            /**
             * Properties of a CreateSupportTokenRequest.
             * @memberof streem.api
             * @interface ICreateSupportTokenRequest
             * @property {string|null} [companyCode] CreateSupportTokenRequest companyCode
             * @property {string|null} [roomSid] CreateSupportTokenRequest roomSid
             */

            /**
             * Constructs a new CreateSupportTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateSupportTokenRequest.
             * @implements ICreateSupportTokenRequest
             * @constructor
             * @param {streem.api.ICreateSupportTokenRequest=} [p] Properties to set
             */
            function CreateSupportTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSupportTokenRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.CreateSupportTokenRequest
             * @instance
             */
            CreateSupportTokenRequest.prototype.companyCode = "";

            /**
             * CreateSupportTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateSupportTokenRequest
             * @instance
             */
            CreateSupportTokenRequest.prototype.roomSid = "";

            /**
             * Creates a new CreateSupportTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {streem.api.ICreateSupportTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateSupportTokenRequest} CreateSupportTokenRequest instance
             */
            CreateSupportTokenRequest.create = function create(properties) {
                return new CreateSupportTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateSupportTokenRequest message. Does not implicitly {@link streem.api.CreateSupportTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {streem.api.ICreateSupportTokenRequest} m CreateSupportTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSupportTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(10).string(m.companyCode);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a CreateSupportTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSupportTokenRequest} CreateSupportTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSupportTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSupportTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSupportTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSupportTokenRequest} CreateSupportTokenRequest
             */
            CreateSupportTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSupportTokenRequest)
                    return d;
                var m = new $root.streem.api.CreateSupportTokenRequest();
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSupportTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {streem.api.CreateSupportTokenRequest} m CreateSupportTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSupportTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCode = "";
                    d.roomSid = "";
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this CreateSupportTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSupportTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSupportTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSupportTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateSupportTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSupportTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSupportTokenRequest";
            };

            return CreateSupportTokenRequest;
        })();

        api.CreateSupportTokenResponse = (function() {

            /**
             * Properties of a CreateSupportTokenResponse.
             * @memberof streem.api
             * @interface ICreateSupportTokenResponse
             * @property {string|null} [sdkToken] CreateSupportTokenResponse sdkToken
             * @property {string|null} [roomUrl] CreateSupportTokenResponse roomUrl
             */

            /**
             * Constructs a new CreateSupportTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateSupportTokenResponse.
             * @implements ICreateSupportTokenResponse
             * @constructor
             * @param {streem.api.ICreateSupportTokenResponse=} [p] Properties to set
             */
            function CreateSupportTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSupportTokenResponse sdkToken.
             * @member {string} sdkToken
             * @memberof streem.api.CreateSupportTokenResponse
             * @instance
             */
            CreateSupportTokenResponse.prototype.sdkToken = "";

            /**
             * CreateSupportTokenResponse roomUrl.
             * @member {string} roomUrl
             * @memberof streem.api.CreateSupportTokenResponse
             * @instance
             */
            CreateSupportTokenResponse.prototype.roomUrl = "";

            /**
             * Creates a new CreateSupportTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {streem.api.ICreateSupportTokenResponse=} [properties] Properties to set
             * @returns {streem.api.CreateSupportTokenResponse} CreateSupportTokenResponse instance
             */
            CreateSupportTokenResponse.create = function create(properties) {
                return new CreateSupportTokenResponse(properties);
            };

            /**
             * Encodes the specified CreateSupportTokenResponse message. Does not implicitly {@link streem.api.CreateSupportTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {streem.api.ICreateSupportTokenResponse} m CreateSupportTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSupportTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sdkToken != null && Object.hasOwnProperty.call(m, "sdkToken"))
                    w.uint32(10).string(m.sdkToken);
                if (m.roomUrl != null && Object.hasOwnProperty.call(m, "roomUrl"))
                    w.uint32(18).string(m.roomUrl);
                return w;
            };

            /**
             * Decodes a CreateSupportTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSupportTokenResponse} CreateSupportTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSupportTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSupportTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sdkToken = r.string();
                            break;
                        }
                    case 2: {
                            m.roomUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSupportTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSupportTokenResponse} CreateSupportTokenResponse
             */
            CreateSupportTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSupportTokenResponse)
                    return d;
                var m = new $root.streem.api.CreateSupportTokenResponse();
                if (d.sdkToken != null) {
                    m.sdkToken = String(d.sdkToken);
                }
                if (d.roomUrl != null) {
                    m.roomUrl = String(d.roomUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSupportTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {streem.api.CreateSupportTokenResponse} m CreateSupportTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSupportTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sdkToken = "";
                    d.roomUrl = "";
                }
                if (m.sdkToken != null && m.hasOwnProperty("sdkToken")) {
                    d.sdkToken = m.sdkToken;
                }
                if (m.roomUrl != null && m.hasOwnProperty("roomUrl")) {
                    d.roomUrl = m.roomUrl;
                }
                return d;
            };

            /**
             * Converts this CreateSupportTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSupportTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSupportTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSupportTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateSupportTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSupportTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSupportTokenResponse";
            };

            return CreateSupportTokenResponse;
        })();

        api.GetCurrentAuthIdentityRequest = (function() {

            /**
             * Properties of a GetCurrentAuthIdentityRequest.
             * @memberof streem.api
             * @interface IGetCurrentAuthIdentityRequest
             */

            /**
             * Constructs a new GetCurrentAuthIdentityRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentAuthIdentityRequest.
             * @implements IGetCurrentAuthIdentityRequest
             * @constructor
             * @param {streem.api.IGetCurrentAuthIdentityRequest=} [p] Properties to set
             */
            function GetCurrentAuthIdentityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCurrentAuthIdentityRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {streem.api.IGetCurrentAuthIdentityRequest=} [properties] Properties to set
             * @returns {streem.api.GetCurrentAuthIdentityRequest} GetCurrentAuthIdentityRequest instance
             */
            GetCurrentAuthIdentityRequest.create = function create(properties) {
                return new GetCurrentAuthIdentityRequest(properties);
            };

            /**
             * Encodes the specified GetCurrentAuthIdentityRequest message. Does not implicitly {@link streem.api.GetCurrentAuthIdentityRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {streem.api.IGetCurrentAuthIdentityRequest} m GetCurrentAuthIdentityRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentAuthIdentityRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCurrentAuthIdentityRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentAuthIdentityRequest} GetCurrentAuthIdentityRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentAuthIdentityRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentAuthIdentityRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentAuthIdentityRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentAuthIdentityRequest} GetCurrentAuthIdentityRequest
             */
            GetCurrentAuthIdentityRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentAuthIdentityRequest)
                    return d;
                return new $root.streem.api.GetCurrentAuthIdentityRequest();
            };

            /**
             * Creates a plain object from a GetCurrentAuthIdentityRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {streem.api.GetCurrentAuthIdentityRequest} m GetCurrentAuthIdentityRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentAuthIdentityRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentAuthIdentityRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentAuthIdentityRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentAuthIdentityRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentAuthIdentityRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentAuthIdentityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentAuthIdentityRequest";
            };

            return GetCurrentAuthIdentityRequest;
        })();

        api.GetCurrentAuthIdentityResponse = (function() {

            /**
             * Properties of a GetCurrentAuthIdentityResponse.
             * @memberof streem.api
             * @interface IGetCurrentAuthIdentityResponse
             * @property {streem.api.IAuthIdentity|null} [authIdentity] GetCurrentAuthIdentityResponse authIdentity
             */

            /**
             * Constructs a new GetCurrentAuthIdentityResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentAuthIdentityResponse.
             * @implements IGetCurrentAuthIdentityResponse
             * @constructor
             * @param {streem.api.IGetCurrentAuthIdentityResponse=} [p] Properties to set
             */
            function GetCurrentAuthIdentityResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCurrentAuthIdentityResponse authIdentity.
             * @member {streem.api.IAuthIdentity|null|undefined} authIdentity
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @instance
             */
            GetCurrentAuthIdentityResponse.prototype.authIdentity = null;

            /**
             * Creates a new GetCurrentAuthIdentityResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {streem.api.IGetCurrentAuthIdentityResponse=} [properties] Properties to set
             * @returns {streem.api.GetCurrentAuthIdentityResponse} GetCurrentAuthIdentityResponse instance
             */
            GetCurrentAuthIdentityResponse.create = function create(properties) {
                return new GetCurrentAuthIdentityResponse(properties);
            };

            /**
             * Encodes the specified GetCurrentAuthIdentityResponse message. Does not implicitly {@link streem.api.GetCurrentAuthIdentityResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {streem.api.IGetCurrentAuthIdentityResponse} m GetCurrentAuthIdentityResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentAuthIdentityResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.authIdentity != null && Object.hasOwnProperty.call(m, "authIdentity"))
                    $root.streem.api.AuthIdentity.encode(m.authIdentity, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCurrentAuthIdentityResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentAuthIdentityResponse} GetCurrentAuthIdentityResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentAuthIdentityResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentAuthIdentityResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.authIdentity = $root.streem.api.AuthIdentity.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentAuthIdentityResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentAuthIdentityResponse} GetCurrentAuthIdentityResponse
             */
            GetCurrentAuthIdentityResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentAuthIdentityResponse)
                    return d;
                var m = new $root.streem.api.GetCurrentAuthIdentityResponse();
                if (d.authIdentity != null) {
                    if (typeof d.authIdentity !== "object")
                        throw TypeError(".streem.api.GetCurrentAuthIdentityResponse.authIdentity: object expected");
                    m.authIdentity = $root.streem.api.AuthIdentity.fromObject(d.authIdentity);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCurrentAuthIdentityResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {streem.api.GetCurrentAuthIdentityResponse} m GetCurrentAuthIdentityResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentAuthIdentityResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.authIdentity = null;
                }
                if (m.authIdentity != null && m.hasOwnProperty("authIdentity")) {
                    d.authIdentity = $root.streem.api.AuthIdentity.toObject(m.authIdentity, o);
                }
                return d;
            };

            /**
             * Converts this GetCurrentAuthIdentityResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentAuthIdentityResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentAuthIdentityResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentAuthIdentityResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentAuthIdentityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentAuthIdentityResponse";
            };

            return GetCurrentAuthIdentityResponse;
        })();

        api.IncomingWebhook = (function() {

            /**
             * Constructs a new IncomingWebhook service.
             * @memberof streem.api
             * @classdesc Represents an IncomingWebhook
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function IncomingWebhook(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (IncomingWebhook.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = IncomingWebhook;

            /**
             * Creates new IncomingWebhook service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.IncomingWebhook
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {IncomingWebhook} RPC service. Useful where requests and/or responses are streamed.
             */
            IncomingWebhook.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.IncomingWebhook#texturedMeshEventIngress}.
             * @memberof streem.api.IncomingWebhook
             * @typedef TexturedMeshEventIngressCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.TexturedMeshEventIngressResponse} [response] TexturedMeshEventIngressResponse
             */

            /**
             * Calls TexturedMeshEventIngress.
             * @function texturedMeshEventIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.ITexturedMeshEventIngressRequest} request TexturedMeshEventIngressRequest message or plain object
             * @param {streem.api.IncomingWebhook.TexturedMeshEventIngressCallback} callback Node-style callback called with the error, if any, and TexturedMeshEventIngressResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(IncomingWebhook.prototype.texturedMeshEventIngress = function texturedMeshEventIngress(request, callback) {
                return this.rpcCall(texturedMeshEventIngress, $root.streem.api.TexturedMeshEventIngressRequest, $root.streem.api.TexturedMeshEventIngressResponse, request, callback);
            }, "name", { value: "TexturedMeshEventIngress" });

            /**
             * Calls TexturedMeshEventIngress.
             * @function texturedMeshEventIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.ITexturedMeshEventIngressRequest} request TexturedMeshEventIngressRequest message or plain object
             * @returns {Promise<streem.api.TexturedMeshEventIngressResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.IncomingWebhook#audioTranscriptionEventIngress}.
             * @memberof streem.api.IncomingWebhook
             * @typedef AudioTranscriptionEventIngressCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.AudioTranscriptionEventIngressResponse} [response] AudioTranscriptionEventIngressResponse
             */

            /**
             * Calls AudioTranscriptionEventIngress.
             * @function audioTranscriptionEventIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.IAudioTranscriptionEventIngressRequest} request AudioTranscriptionEventIngressRequest message or plain object
             * @param {streem.api.IncomingWebhook.AudioTranscriptionEventIngressCallback} callback Node-style callback called with the error, if any, and AudioTranscriptionEventIngressResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(IncomingWebhook.prototype.audioTranscriptionEventIngress = function audioTranscriptionEventIngress(request, callback) {
                return this.rpcCall(audioTranscriptionEventIngress, $root.streem.api.AudioTranscriptionEventIngressRequest, $root.streem.api.AudioTranscriptionEventIngressResponse, request, callback);
            }, "name", { value: "AudioTranscriptionEventIngress" });

            /**
             * Calls AudioTranscriptionEventIngress.
             * @function audioTranscriptionEventIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.IAudioTranscriptionEventIngressRequest} request AudioTranscriptionEventIngressRequest message or plain object
             * @returns {Promise<streem.api.AudioTranscriptionEventIngressResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.IncomingWebhook#wallIngress}.
             * @memberof streem.api.IncomingWebhook
             * @typedef WallIngressCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.WallIngressResponse} [response] WallIngressResponse
             */

            /**
             * Calls WallIngress.
             * @function wallIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.IWallIngressRequest} request WallIngressRequest message or plain object
             * @param {streem.api.IncomingWebhook.WallIngressCallback} callback Node-style callback called with the error, if any, and WallIngressResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(IncomingWebhook.prototype.wallIngress = function wallIngress(request, callback) {
                return this.rpcCall(wallIngress, $root.streem.api.WallIngressRequest, $root.streem.api.WallIngressResponse, request, callback);
            }, "name", { value: "WallIngress" });

            /**
             * Calls WallIngress.
             * @function wallIngress
             * @memberof streem.api.IncomingWebhook
             * @instance
             * @param {streem.api.IWallIngressRequest} request WallIngressRequest message or plain object
             * @returns {Promise<streem.api.WallIngressResponse>} Promise
             * @variation 2
             */

            return IncomingWebhook;
        })();

        api.TexturedMeshEventIngressRequest = (function() {

            /**
             * Properties of a TexturedMeshEventIngressRequest.
             * @memberof streem.api
             * @interface ITexturedMeshEventIngressRequest
             * @property {streem.api.Artifact.Status|null} [status] TexturedMeshEventIngressRequest status
             * @property {string|null} [roomSid] TexturedMeshEventIngressRequest roomSid
             * @property {string|null} [texturedMeshSid] TexturedMeshEventIngressRequest texturedMeshSid
             */

            /**
             * Constructs a new TexturedMeshEventIngressRequest.
             * @memberof streem.api
             * @classdesc Represents a TexturedMeshEventIngressRequest.
             * @implements ITexturedMeshEventIngressRequest
             * @constructor
             * @param {streem.api.ITexturedMeshEventIngressRequest=} [p] Properties to set
             */
            function TexturedMeshEventIngressRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TexturedMeshEventIngressRequest status.
             * @member {streem.api.Artifact.Status} status
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @instance
             */
            TexturedMeshEventIngressRequest.prototype.status = 0;

            /**
             * TexturedMeshEventIngressRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @instance
             */
            TexturedMeshEventIngressRequest.prototype.roomSid = "";

            /**
             * TexturedMeshEventIngressRequest texturedMeshSid.
             * @member {string} texturedMeshSid
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @instance
             */
            TexturedMeshEventIngressRequest.prototype.texturedMeshSid = "";

            /**
             * Creates a new TexturedMeshEventIngressRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {streem.api.ITexturedMeshEventIngressRequest=} [properties] Properties to set
             * @returns {streem.api.TexturedMeshEventIngressRequest} TexturedMeshEventIngressRequest instance
             */
            TexturedMeshEventIngressRequest.create = function create(properties) {
                return new TexturedMeshEventIngressRequest(properties);
            };

            /**
             * Encodes the specified TexturedMeshEventIngressRequest message. Does not implicitly {@link streem.api.TexturedMeshEventIngressRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {streem.api.ITexturedMeshEventIngressRequest} m TexturedMeshEventIngressRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TexturedMeshEventIngressRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(8).int32(m.status);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.texturedMeshSid != null && Object.hasOwnProperty.call(m, "texturedMeshSid"))
                    w.uint32(26).string(m.texturedMeshSid);
                return w;
            };

            /**
             * Decodes a TexturedMeshEventIngressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TexturedMeshEventIngressRequest} TexturedMeshEventIngressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TexturedMeshEventIngressRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TexturedMeshEventIngressRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.status = r.int32();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 3: {
                            m.texturedMeshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TexturedMeshEventIngressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TexturedMeshEventIngressRequest} TexturedMeshEventIngressRequest
             */
            TexturedMeshEventIngressRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TexturedMeshEventIngressRequest)
                    return d;
                var m = new $root.streem.api.TexturedMeshEventIngressRequest();
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_UNKNOWN":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_REJECTED":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_ERROR":
                case 2:
                    m.status = 2;
                    break;
                case "STATUS_STARTED":
                case 3:
                    m.status = 3;
                    break;
                case "STATUS_CAPTURED":
                case 4:
                    m.status = 4;
                    break;
                case "STATUS_FULFILLED":
                case 5:
                    m.status = 5;
                    break;
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.texturedMeshSid != null) {
                    m.texturedMeshSid = String(d.texturedMeshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a TexturedMeshEventIngressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {streem.api.TexturedMeshEventIngressRequest} m TexturedMeshEventIngressRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TexturedMeshEventIngressRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                    d.roomSid = "";
                    d.texturedMeshSid = "";
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.texturedMeshSid != null && m.hasOwnProperty("texturedMeshSid")) {
                    d.texturedMeshSid = m.texturedMeshSid;
                }
                return d;
            };

            /**
             * Converts this TexturedMeshEventIngressRequest to JSON.
             * @function toJSON
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TexturedMeshEventIngressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TexturedMeshEventIngressRequest
             * @function getTypeUrl
             * @memberof streem.api.TexturedMeshEventIngressRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TexturedMeshEventIngressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TexturedMeshEventIngressRequest";
            };

            return TexturedMeshEventIngressRequest;
        })();

        api.TexturedMeshEventIngressResponse = (function() {

            /**
             * Properties of a TexturedMeshEventIngressResponse.
             * @memberof streem.api
             * @interface ITexturedMeshEventIngressResponse
             */

            /**
             * Constructs a new TexturedMeshEventIngressResponse.
             * @memberof streem.api
             * @classdesc Represents a TexturedMeshEventIngressResponse.
             * @implements ITexturedMeshEventIngressResponse
             * @constructor
             * @param {streem.api.ITexturedMeshEventIngressResponse=} [p] Properties to set
             */
            function TexturedMeshEventIngressResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TexturedMeshEventIngressResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {streem.api.ITexturedMeshEventIngressResponse=} [properties] Properties to set
             * @returns {streem.api.TexturedMeshEventIngressResponse} TexturedMeshEventIngressResponse instance
             */
            TexturedMeshEventIngressResponse.create = function create(properties) {
                return new TexturedMeshEventIngressResponse(properties);
            };

            /**
             * Encodes the specified TexturedMeshEventIngressResponse message. Does not implicitly {@link streem.api.TexturedMeshEventIngressResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {streem.api.ITexturedMeshEventIngressResponse} m TexturedMeshEventIngressResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TexturedMeshEventIngressResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a TexturedMeshEventIngressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TexturedMeshEventIngressResponse} TexturedMeshEventIngressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TexturedMeshEventIngressResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TexturedMeshEventIngressResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TexturedMeshEventIngressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TexturedMeshEventIngressResponse} TexturedMeshEventIngressResponse
             */
            TexturedMeshEventIngressResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TexturedMeshEventIngressResponse)
                    return d;
                return new $root.streem.api.TexturedMeshEventIngressResponse();
            };

            /**
             * Creates a plain object from a TexturedMeshEventIngressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {streem.api.TexturedMeshEventIngressResponse} m TexturedMeshEventIngressResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TexturedMeshEventIngressResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TexturedMeshEventIngressResponse to JSON.
             * @function toJSON
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TexturedMeshEventIngressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TexturedMeshEventIngressResponse
             * @function getTypeUrl
             * @memberof streem.api.TexturedMeshEventIngressResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TexturedMeshEventIngressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TexturedMeshEventIngressResponse";
            };

            return TexturedMeshEventIngressResponse;
        })();

        api.AudioTranscriptionEventIngressRequest = (function() {

            /**
             * Properties of an AudioTranscriptionEventIngressRequest.
             * @memberof streem.api
             * @interface IAudioTranscriptionEventIngressRequest
             * @property {streem.api.Artifact.Status|null} [status] AudioTranscriptionEventIngressRequest status
             * @property {string|null} [roomSid] AudioTranscriptionEventIngressRequest roomSid
             * @property {string|null} [transcriptionSid] AudioTranscriptionEventIngressRequest transcriptionSid
             * @property {string|null} [transcriptionJobSid] AudioTranscriptionEventIngressRequest transcriptionJobSid
             */

            /**
             * Constructs a new AudioTranscriptionEventIngressRequest.
             * @memberof streem.api
             * @classdesc Represents an AudioTranscriptionEventIngressRequest.
             * @implements IAudioTranscriptionEventIngressRequest
             * @constructor
             * @param {streem.api.IAudioTranscriptionEventIngressRequest=} [p] Properties to set
             */
            function AudioTranscriptionEventIngressRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AudioTranscriptionEventIngressRequest status.
             * @member {streem.api.Artifact.Status} status
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @instance
             */
            AudioTranscriptionEventIngressRequest.prototype.status = 0;

            /**
             * AudioTranscriptionEventIngressRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @instance
             */
            AudioTranscriptionEventIngressRequest.prototype.roomSid = "";

            /**
             * AudioTranscriptionEventIngressRequest transcriptionSid.
             * @member {string} transcriptionSid
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @instance
             */
            AudioTranscriptionEventIngressRequest.prototype.transcriptionSid = "";

            /**
             * AudioTranscriptionEventIngressRequest transcriptionJobSid.
             * @member {string} transcriptionJobSid
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @instance
             */
            AudioTranscriptionEventIngressRequest.prototype.transcriptionJobSid = "";

            /**
             * Creates a new AudioTranscriptionEventIngressRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {streem.api.IAudioTranscriptionEventIngressRequest=} [properties] Properties to set
             * @returns {streem.api.AudioTranscriptionEventIngressRequest} AudioTranscriptionEventIngressRequest instance
             */
            AudioTranscriptionEventIngressRequest.create = function create(properties) {
                return new AudioTranscriptionEventIngressRequest(properties);
            };

            /**
             * Encodes the specified AudioTranscriptionEventIngressRequest message. Does not implicitly {@link streem.api.AudioTranscriptionEventIngressRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {streem.api.IAudioTranscriptionEventIngressRequest} m AudioTranscriptionEventIngressRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioTranscriptionEventIngressRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(8).int32(m.status);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.transcriptionSid != null && Object.hasOwnProperty.call(m, "transcriptionSid"))
                    w.uint32(26).string(m.transcriptionSid);
                if (m.transcriptionJobSid != null && Object.hasOwnProperty.call(m, "transcriptionJobSid"))
                    w.uint32(34).string(m.transcriptionJobSid);
                return w;
            };

            /**
             * Decodes an AudioTranscriptionEventIngressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AudioTranscriptionEventIngressRequest} AudioTranscriptionEventIngressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioTranscriptionEventIngressRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AudioTranscriptionEventIngressRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.status = r.int32();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 3: {
                            m.transcriptionSid = r.string();
                            break;
                        }
                    case 4: {
                            m.transcriptionJobSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AudioTranscriptionEventIngressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AudioTranscriptionEventIngressRequest} AudioTranscriptionEventIngressRequest
             */
            AudioTranscriptionEventIngressRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AudioTranscriptionEventIngressRequest)
                    return d;
                var m = new $root.streem.api.AudioTranscriptionEventIngressRequest();
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_UNKNOWN":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_REJECTED":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_ERROR":
                case 2:
                    m.status = 2;
                    break;
                case "STATUS_STARTED":
                case 3:
                    m.status = 3;
                    break;
                case "STATUS_CAPTURED":
                case 4:
                    m.status = 4;
                    break;
                case "STATUS_FULFILLED":
                case 5:
                    m.status = 5;
                    break;
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.transcriptionSid != null) {
                    m.transcriptionSid = String(d.transcriptionSid);
                }
                if (d.transcriptionJobSid != null) {
                    m.transcriptionJobSid = String(d.transcriptionJobSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an AudioTranscriptionEventIngressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {streem.api.AudioTranscriptionEventIngressRequest} m AudioTranscriptionEventIngressRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioTranscriptionEventIngressRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                    d.roomSid = "";
                    d.transcriptionSid = "";
                    d.transcriptionJobSid = "";
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.transcriptionSid != null && m.hasOwnProperty("transcriptionSid")) {
                    d.transcriptionSid = m.transcriptionSid;
                }
                if (m.transcriptionJobSid != null && m.hasOwnProperty("transcriptionJobSid")) {
                    d.transcriptionJobSid = m.transcriptionJobSid;
                }
                return d;
            };

            /**
             * Converts this AudioTranscriptionEventIngressRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioTranscriptionEventIngressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AudioTranscriptionEventIngressRequest
             * @function getTypeUrl
             * @memberof streem.api.AudioTranscriptionEventIngressRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioTranscriptionEventIngressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AudioTranscriptionEventIngressRequest";
            };

            return AudioTranscriptionEventIngressRequest;
        })();

        api.AudioTranscriptionEventIngressResponse = (function() {

            /**
             * Properties of an AudioTranscriptionEventIngressResponse.
             * @memberof streem.api
             * @interface IAudioTranscriptionEventIngressResponse
             */

            /**
             * Constructs a new AudioTranscriptionEventIngressResponse.
             * @memberof streem.api
             * @classdesc Represents an AudioTranscriptionEventIngressResponse.
             * @implements IAudioTranscriptionEventIngressResponse
             * @constructor
             * @param {streem.api.IAudioTranscriptionEventIngressResponse=} [p] Properties to set
             */
            function AudioTranscriptionEventIngressResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new AudioTranscriptionEventIngressResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {streem.api.IAudioTranscriptionEventIngressResponse=} [properties] Properties to set
             * @returns {streem.api.AudioTranscriptionEventIngressResponse} AudioTranscriptionEventIngressResponse instance
             */
            AudioTranscriptionEventIngressResponse.create = function create(properties) {
                return new AudioTranscriptionEventIngressResponse(properties);
            };

            /**
             * Encodes the specified AudioTranscriptionEventIngressResponse message. Does not implicitly {@link streem.api.AudioTranscriptionEventIngressResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {streem.api.IAudioTranscriptionEventIngressResponse} m AudioTranscriptionEventIngressResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioTranscriptionEventIngressResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes an AudioTranscriptionEventIngressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AudioTranscriptionEventIngressResponse} AudioTranscriptionEventIngressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioTranscriptionEventIngressResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AudioTranscriptionEventIngressResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AudioTranscriptionEventIngressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AudioTranscriptionEventIngressResponse} AudioTranscriptionEventIngressResponse
             */
            AudioTranscriptionEventIngressResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AudioTranscriptionEventIngressResponse)
                    return d;
                return new $root.streem.api.AudioTranscriptionEventIngressResponse();
            };

            /**
             * Creates a plain object from an AudioTranscriptionEventIngressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {streem.api.AudioTranscriptionEventIngressResponse} m AudioTranscriptionEventIngressResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioTranscriptionEventIngressResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AudioTranscriptionEventIngressResponse to JSON.
             * @function toJSON
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioTranscriptionEventIngressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AudioTranscriptionEventIngressResponse
             * @function getTypeUrl
             * @memberof streem.api.AudioTranscriptionEventIngressResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioTranscriptionEventIngressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AudioTranscriptionEventIngressResponse";
            };

            return AudioTranscriptionEventIngressResponse;
        })();

        api.WallIngressRequest = (function() {

            /**
             * Properties of a WallIngressRequest.
             * @memberof streem.api
             * @interface IWallIngressRequest
             * @property {streem.api.wall.IWallItemEntryEvent|null} [event] WallIngressRequest event
             */

            /**
             * Constructs a new WallIngressRequest.
             * @memberof streem.api
             * @classdesc Represents a WallIngressRequest.
             * @implements IWallIngressRequest
             * @constructor
             * @param {streem.api.IWallIngressRequest=} [p] Properties to set
             */
            function WallIngressRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * WallIngressRequest event.
             * @member {streem.api.wall.IWallItemEntryEvent|null|undefined} event
             * @memberof streem.api.WallIngressRequest
             * @instance
             */
            WallIngressRequest.prototype.event = null;

            /**
             * Creates a new WallIngressRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {streem.api.IWallIngressRequest=} [properties] Properties to set
             * @returns {streem.api.WallIngressRequest} WallIngressRequest instance
             */
            WallIngressRequest.create = function create(properties) {
                return new WallIngressRequest(properties);
            };

            /**
             * Encodes the specified WallIngressRequest message. Does not implicitly {@link streem.api.WallIngressRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {streem.api.IWallIngressRequest} m WallIngressRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WallIngressRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.event != null && Object.hasOwnProperty.call(m, "event"))
                    $root.streem.api.wall.WallItemEntryEvent.encode(m.event, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a WallIngressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WallIngressRequest} WallIngressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WallIngressRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WallIngressRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.event = $root.streem.api.wall.WallItemEntryEvent.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WallIngressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WallIngressRequest} WallIngressRequest
             */
            WallIngressRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WallIngressRequest)
                    return d;
                var m = new $root.streem.api.WallIngressRequest();
                if (d.event != null) {
                    if (typeof d.event !== "object")
                        throw TypeError(".streem.api.WallIngressRequest.event: object expected");
                    m.event = $root.streem.api.wall.WallItemEntryEvent.fromObject(d.event);
                }
                return m;
            };

            /**
             * Creates a plain object from a WallIngressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {streem.api.WallIngressRequest} m WallIngressRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WallIngressRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.event = null;
                }
                if (m.event != null && m.hasOwnProperty("event")) {
                    d.event = $root.streem.api.wall.WallItemEntryEvent.toObject(m.event, o);
                }
                return d;
            };

            /**
             * Converts this WallIngressRequest to JSON.
             * @function toJSON
             * @memberof streem.api.WallIngressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WallIngressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WallIngressRequest
             * @function getTypeUrl
             * @memberof streem.api.WallIngressRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WallIngressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WallIngressRequest";
            };

            return WallIngressRequest;
        })();

        api.WallIngressResponse = (function() {

            /**
             * Properties of a WallIngressResponse.
             * @memberof streem.api
             * @interface IWallIngressResponse
             */

            /**
             * Constructs a new WallIngressResponse.
             * @memberof streem.api
             * @classdesc Represents a WallIngressResponse.
             * @implements IWallIngressResponse
             * @constructor
             * @param {streem.api.IWallIngressResponse=} [p] Properties to set
             */
            function WallIngressResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new WallIngressResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {streem.api.IWallIngressResponse=} [properties] Properties to set
             * @returns {streem.api.WallIngressResponse} WallIngressResponse instance
             */
            WallIngressResponse.create = function create(properties) {
                return new WallIngressResponse(properties);
            };

            /**
             * Encodes the specified WallIngressResponse message. Does not implicitly {@link streem.api.WallIngressResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {streem.api.IWallIngressResponse} m WallIngressResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WallIngressResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a WallIngressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WallIngressResponse} WallIngressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WallIngressResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WallIngressResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WallIngressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WallIngressResponse} WallIngressResponse
             */
            WallIngressResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WallIngressResponse)
                    return d;
                return new $root.streem.api.WallIngressResponse();
            };

            /**
             * Creates a plain object from a WallIngressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {streem.api.WallIngressResponse} m WallIngressResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WallIngressResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this WallIngressResponse to JSON.
             * @function toJSON
             * @memberof streem.api.WallIngressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WallIngressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WallIngressResponse
             * @function getTypeUrl
             * @memberof streem.api.WallIngressResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WallIngressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WallIngressResponse";
            };

            return WallIngressResponse;
        })();

        api.Countries = (function() {

            /**
             * Constructs a new Countries service.
             * @memberof streem.api
             * @classdesc Represents a Countries
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Countries(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Countries.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Countries;

            /**
             * Creates new Countries service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Countries
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Countries} RPC service. Useful where requests and/or responses are streamed.
             */
            Countries.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Countries#getCountries}.
             * @memberof streem.api.Countries
             * @typedef GetCountriesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCountriesResponse} [response] GetCountriesResponse
             */

            /**
             * Calls GetCountries.
             * @function getCountries
             * @memberof streem.api.Countries
             * @instance
             * @param {streem.api.IGetCountriesRequest} request GetCountriesRequest message or plain object
             * @param {streem.api.Countries.GetCountriesCallback} callback Node-style callback called with the error, if any, and GetCountriesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Countries.prototype.getCountries = function getCountries(request, callback) {
                return this.rpcCall(getCountries, $root.streem.api.GetCountriesRequest, $root.streem.api.GetCountriesResponse, request, callback);
            }, "name", { value: "GetCountries" });

            /**
             * Calls GetCountries.
             * @function getCountries
             * @memberof streem.api.Countries
             * @instance
             * @param {streem.api.IGetCountriesRequest} request GetCountriesRequest message or plain object
             * @returns {Promise<streem.api.GetCountriesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Countries#createCountry}.
             * @memberof streem.api.Countries
             * @typedef CreateCountryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCountryResponse} [response] CreateCountryResponse
             */

            /**
             * Calls CreateCountry.
             * @function createCountry
             * @memberof streem.api.Countries
             * @instance
             * @param {streem.api.ICreateCountryRequest} request CreateCountryRequest message or plain object
             * @param {streem.api.Countries.CreateCountryCallback} callback Node-style callback called with the error, if any, and CreateCountryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Countries.prototype.createCountry = function createCountry(request, callback) {
                return this.rpcCall(createCountry, $root.streem.api.CreateCountryRequest, $root.streem.api.CreateCountryResponse, request, callback);
            }, "name", { value: "CreateCountry" });

            /**
             * Calls CreateCountry.
             * @function createCountry
             * @memberof streem.api.Countries
             * @instance
             * @param {streem.api.ICreateCountryRequest} request CreateCountryRequest message or plain object
             * @returns {Promise<streem.api.CreateCountryResponse>} Promise
             * @variation 2
             */

            return Countries;
        })();

        api.CreateCountryRequest = (function() {

            /**
             * Properties of a CreateCountryRequest.
             * @memberof streem.api
             * @interface ICreateCountryRequest
             * @property {string|null} [callingCode] CreateCountryRequest callingCode
             * @property {string|null} [countryName] CreateCountryRequest countryName
             */

            /**
             * Constructs a new CreateCountryRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCountryRequest.
             * @implements ICreateCountryRequest
             * @constructor
             * @param {streem.api.ICreateCountryRequest=} [p] Properties to set
             */
            function CreateCountryRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCountryRequest callingCode.
             * @member {string} callingCode
             * @memberof streem.api.CreateCountryRequest
             * @instance
             */
            CreateCountryRequest.prototype.callingCode = "";

            /**
             * CreateCountryRequest countryName.
             * @member {string} countryName
             * @memberof streem.api.CreateCountryRequest
             * @instance
             */
            CreateCountryRequest.prototype.countryName = "";

            /**
             * Creates a new CreateCountryRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {streem.api.ICreateCountryRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCountryRequest} CreateCountryRequest instance
             */
            CreateCountryRequest.create = function create(properties) {
                return new CreateCountryRequest(properties);
            };

            /**
             * Encodes the specified CreateCountryRequest message. Does not implicitly {@link streem.api.CreateCountryRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {streem.api.ICreateCountryRequest} m CreateCountryRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCountryRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callingCode != null && Object.hasOwnProperty.call(m, "callingCode"))
                    w.uint32(10).string(m.callingCode);
                if (m.countryName != null && Object.hasOwnProperty.call(m, "countryName"))
                    w.uint32(26).string(m.countryName);
                return w;
            };

            /**
             * Decodes a CreateCountryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCountryRequest} CreateCountryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCountryRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCountryRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callingCode = r.string();
                            break;
                        }
                    case 3: {
                            m.countryName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCountryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCountryRequest} CreateCountryRequest
             */
            CreateCountryRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCountryRequest)
                    return d;
                var m = new $root.streem.api.CreateCountryRequest();
                if (d.callingCode != null) {
                    m.callingCode = String(d.callingCode);
                }
                if (d.countryName != null) {
                    m.countryName = String(d.countryName);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCountryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {streem.api.CreateCountryRequest} m CreateCountryRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCountryRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callingCode = "";
                    d.countryName = "";
                }
                if (m.callingCode != null && m.hasOwnProperty("callingCode")) {
                    d.callingCode = m.callingCode;
                }
                if (m.countryName != null && m.hasOwnProperty("countryName")) {
                    d.countryName = m.countryName;
                }
                return d;
            };

            /**
             * Converts this CreateCountryRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCountryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCountryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCountryRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCountryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCountryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCountryRequest";
            };

            return CreateCountryRequest;
        })();

        api.CreateCountryResponse = (function() {

            /**
             * Properties of a CreateCountryResponse.
             * @memberof streem.api
             * @interface ICreateCountryResponse
             * @property {streem.api.ICountry|null} [country] CreateCountryResponse country
             */

            /**
             * Constructs a new CreateCountryResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCountryResponse.
             * @implements ICreateCountryResponse
             * @constructor
             * @param {streem.api.ICreateCountryResponse=} [p] Properties to set
             */
            function CreateCountryResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCountryResponse country.
             * @member {streem.api.ICountry|null|undefined} country
             * @memberof streem.api.CreateCountryResponse
             * @instance
             */
            CreateCountryResponse.prototype.country = null;

            /**
             * Creates a new CreateCountryResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {streem.api.ICreateCountryResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCountryResponse} CreateCountryResponse instance
             */
            CreateCountryResponse.create = function create(properties) {
                return new CreateCountryResponse(properties);
            };

            /**
             * Encodes the specified CreateCountryResponse message. Does not implicitly {@link streem.api.CreateCountryResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {streem.api.ICreateCountryResponse} m CreateCountryResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCountryResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                    $root.streem.api.Country.encode(m.country, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCountryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCountryResponse} CreateCountryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCountryResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCountryResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.country = $root.streem.api.Country.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCountryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCountryResponse} CreateCountryResponse
             */
            CreateCountryResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCountryResponse)
                    return d;
                var m = new $root.streem.api.CreateCountryResponse();
                if (d.country != null) {
                    if (typeof d.country !== "object")
                        throw TypeError(".streem.api.CreateCountryResponse.country: object expected");
                    m.country = $root.streem.api.Country.fromObject(d.country);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCountryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {streem.api.CreateCountryResponse} m CreateCountryResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCountryResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.country = null;
                }
                if (m.country != null && m.hasOwnProperty("country")) {
                    d.country = $root.streem.api.Country.toObject(m.country, o);
                }
                return d;
            };

            /**
             * Converts this CreateCountryResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCountryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCountryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCountryResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCountryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCountryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCountryResponse";
            };

            return CreateCountryResponse;
        })();

        api.GetCountriesRequest = (function() {

            /**
             * Properties of a GetCountriesRequest.
             * @memberof streem.api
             * @interface IGetCountriesRequest
             */

            /**
             * Constructs a new GetCountriesRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCountriesRequest.
             * @implements IGetCountriesRequest
             * @constructor
             * @param {streem.api.IGetCountriesRequest=} [p] Properties to set
             */
            function GetCountriesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCountriesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {streem.api.IGetCountriesRequest=} [properties] Properties to set
             * @returns {streem.api.GetCountriesRequest} GetCountriesRequest instance
             */
            GetCountriesRequest.create = function create(properties) {
                return new GetCountriesRequest(properties);
            };

            /**
             * Encodes the specified GetCountriesRequest message. Does not implicitly {@link streem.api.GetCountriesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {streem.api.IGetCountriesRequest} m GetCountriesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCountriesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCountriesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCountriesRequest} GetCountriesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCountriesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCountriesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCountriesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCountriesRequest} GetCountriesRequest
             */
            GetCountriesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCountriesRequest)
                    return d;
                return new $root.streem.api.GetCountriesRequest();
            };

            /**
             * Creates a plain object from a GetCountriesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {streem.api.GetCountriesRequest} m GetCountriesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCountriesRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCountriesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCountriesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCountriesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCountriesRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCountriesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCountriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCountriesRequest";
            };

            return GetCountriesRequest;
        })();

        api.GetCountriesResponse = (function() {

            /**
             * Properties of a GetCountriesResponse.
             * @memberof streem.api
             * @interface IGetCountriesResponse
             * @property {Array.<streem.api.ICountry>|null} [countries] GetCountriesResponse countries
             */

            /**
             * Constructs a new GetCountriesResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCountriesResponse.
             * @implements IGetCountriesResponse
             * @constructor
             * @param {streem.api.IGetCountriesResponse=} [p] Properties to set
             */
            function GetCountriesResponse(p) {
                this.countries = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCountriesResponse countries.
             * @member {Array.<streem.api.ICountry>} countries
             * @memberof streem.api.GetCountriesResponse
             * @instance
             */
            GetCountriesResponse.prototype.countries = $util.emptyArray;

            /**
             * Creates a new GetCountriesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {streem.api.IGetCountriesResponse=} [properties] Properties to set
             * @returns {streem.api.GetCountriesResponse} GetCountriesResponse instance
             */
            GetCountriesResponse.create = function create(properties) {
                return new GetCountriesResponse(properties);
            };

            /**
             * Encodes the specified GetCountriesResponse message. Does not implicitly {@link streem.api.GetCountriesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {streem.api.IGetCountriesResponse} m GetCountriesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCountriesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.countries != null && m.countries.length) {
                    for (var i = 0; i < m.countries.length; ++i)
                        $root.streem.api.Country.encode(m.countries[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetCountriesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCountriesResponse} GetCountriesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCountriesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCountriesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.countries && m.countries.length))
                                m.countries = [];
                            m.countries.push($root.streem.api.Country.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCountriesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCountriesResponse} GetCountriesResponse
             */
            GetCountriesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCountriesResponse)
                    return d;
                var m = new $root.streem.api.GetCountriesResponse();
                if (d.countries) {
                    if (!Array.isArray(d.countries))
                        throw TypeError(".streem.api.GetCountriesResponse.countries: array expected");
                    m.countries = [];
                    for (var i = 0; i < d.countries.length; ++i) {
                        if (typeof d.countries[i] !== "object")
                            throw TypeError(".streem.api.GetCountriesResponse.countries: object expected");
                        m.countries[i] = $root.streem.api.Country.fromObject(d.countries[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCountriesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {streem.api.GetCountriesResponse} m GetCountriesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCountriesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.countries = [];
                }
                if (m.countries && m.countries.length) {
                    d.countries = [];
                    for (var j = 0; j < m.countries.length; ++j) {
                        d.countries[j] = $root.streem.api.Country.toObject(m.countries[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetCountriesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCountriesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCountriesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCountriesResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCountriesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCountriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCountriesResponse";
            };

            return GetCountriesResponse;
        })();

        api.Webhooks = (function() {

            /**
             * Constructs a new Webhooks service.
             * @memberof streem.api
             * @classdesc Represents a Webhooks
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Webhooks(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Webhooks.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Webhooks;

            /**
             * Creates new Webhooks service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Webhooks
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Webhooks} RPC service. Useful where requests and/or responses are streamed.
             */
            Webhooks.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Webhooks#listWebhooks}.
             * @memberof streem.api.Webhooks
             * @typedef ListWebhooksCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListWebhooksResponse} [response] ListWebhooksResponse
             */

            /**
             * Calls ListWebhooks.
             * @function listWebhooks
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IListWebhooksRequest} request ListWebhooksRequest message or plain object
             * @param {streem.api.Webhooks.ListWebhooksCallback} callback Node-style callback called with the error, if any, and ListWebhooksResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.listWebhooks = function listWebhooks(request, callback) {
                return this.rpcCall(listWebhooks, $root.streem.api.ListWebhooksRequest, $root.streem.api.ListWebhooksResponse, request, callback);
            }, "name", { value: "ListWebhooks" });

            /**
             * Calls ListWebhooks.
             * @function listWebhooks
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IListWebhooksRequest} request ListWebhooksRequest message or plain object
             * @returns {Promise<streem.api.ListWebhooksResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#createWebhook}.
             * @memberof streem.api.Webhooks
             * @typedef CreateWebhookCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateWebhookResponse} [response] CreateWebhookResponse
             */

            /**
             * Calls CreateWebhook.
             * @function createWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ICreateWebhookRequest} request CreateWebhookRequest message or plain object
             * @param {streem.api.Webhooks.CreateWebhookCallback} callback Node-style callback called with the error, if any, and CreateWebhookResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.createWebhook = function createWebhook(request, callback) {
                return this.rpcCall(createWebhook, $root.streem.api.CreateWebhookRequest, $root.streem.api.CreateWebhookResponse, request, callback);
            }, "name", { value: "CreateWebhook" });

            /**
             * Calls CreateWebhook.
             * @function createWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ICreateWebhookRequest} request CreateWebhookRequest message or plain object
             * @returns {Promise<streem.api.CreateWebhookResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#getWebhook}.
             * @memberof streem.api.Webhooks
             * @typedef GetWebhookCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetWebhookResponse} [response] GetWebhookResponse
             */

            /**
             * Calls GetWebhook.
             * @function getWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IGetWebhookRequest} request GetWebhookRequest message or plain object
             * @param {streem.api.Webhooks.GetWebhookCallback} callback Node-style callback called with the error, if any, and GetWebhookResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.getWebhook = function getWebhook(request, callback) {
                return this.rpcCall(getWebhook, $root.streem.api.GetWebhookRequest, $root.streem.api.GetWebhookResponse, request, callback);
            }, "name", { value: "GetWebhook" });

            /**
             * Calls GetWebhook.
             * @function getWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IGetWebhookRequest} request GetWebhookRequest message or plain object
             * @returns {Promise<streem.api.GetWebhookResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#updateWebhook}.
             * @memberof streem.api.Webhooks
             * @typedef UpdateWebhookCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateWebhookResponse} [response] UpdateWebhookResponse
             */

            /**
             * Calls UpdateWebhook.
             * @function updateWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IUpdateWebhookRequest} request UpdateWebhookRequest message or plain object
             * @param {streem.api.Webhooks.UpdateWebhookCallback} callback Node-style callback called with the error, if any, and UpdateWebhookResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.updateWebhook = function updateWebhook(request, callback) {
                return this.rpcCall(updateWebhook, $root.streem.api.UpdateWebhookRequest, $root.streem.api.UpdateWebhookResponse, request, callback);
            }, "name", { value: "UpdateWebhook" });

            /**
             * Calls UpdateWebhook.
             * @function updateWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IUpdateWebhookRequest} request UpdateWebhookRequest message or plain object
             * @returns {Promise<streem.api.UpdateWebhookResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#deleteWebhook}.
             * @memberof streem.api.Webhooks
             * @typedef DeleteWebhookCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteWebhookResponse} [response] DeleteWebhookResponse
             */

            /**
             * Calls DeleteWebhook.
             * @function deleteWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IDeleteWebhookRequest} request DeleteWebhookRequest message or plain object
             * @param {streem.api.Webhooks.DeleteWebhookCallback} callback Node-style callback called with the error, if any, and DeleteWebhookResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.deleteWebhook = function deleteWebhook(request, callback) {
                return this.rpcCall(deleteWebhook, $root.streem.api.DeleteWebhookRequest, $root.streem.api.DeleteWebhookResponse, request, callback);
            }, "name", { value: "DeleteWebhook" });

            /**
             * Calls DeleteWebhook.
             * @function deleteWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IDeleteWebhookRequest} request DeleteWebhookRequest message or plain object
             * @returns {Promise<streem.api.DeleteWebhookResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#listSigningKeys}.
             * @memberof streem.api.Webhooks
             * @typedef ListSigningKeysCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListSigningKeysResponse} [response] ListSigningKeysResponse
             */

            /**
             * Calls ListSigningKeys.
             * @function listSigningKeys
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IListSigningKeysRequest} request ListSigningKeysRequest message or plain object
             * @param {streem.api.Webhooks.ListSigningKeysCallback} callback Node-style callback called with the error, if any, and ListSigningKeysResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.listSigningKeys = function listSigningKeys(request, callback) {
                return this.rpcCall(listSigningKeys, $root.streem.api.ListSigningKeysRequest, $root.streem.api.ListSigningKeysResponse, request, callback);
            }, "name", { value: "ListSigningKeys" });

            /**
             * Calls ListSigningKeys.
             * @function listSigningKeys
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IListSigningKeysRequest} request ListSigningKeysRequest message or plain object
             * @returns {Promise<streem.api.ListSigningKeysResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#createSigningKey}.
             * @memberof streem.api.Webhooks
             * @typedef CreateSigningKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateSigningKeyResponse} [response] CreateSigningKeyResponse
             */

            /**
             * Calls CreateSigningKey.
             * @function createSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ICreateSigningKeyRequest} request CreateSigningKeyRequest message or plain object
             * @param {streem.api.Webhooks.CreateSigningKeyCallback} callback Node-style callback called with the error, if any, and CreateSigningKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.createSigningKey = function createSigningKey(request, callback) {
                return this.rpcCall(createSigningKey, $root.streem.api.CreateSigningKeyRequest, $root.streem.api.CreateSigningKeyResponse, request, callback);
            }, "name", { value: "CreateSigningKey" });

            /**
             * Calls CreateSigningKey.
             * @function createSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ICreateSigningKeyRequest} request CreateSigningKeyRequest message or plain object
             * @returns {Promise<streem.api.CreateSigningKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#getSigningKey}.
             * @memberof streem.api.Webhooks
             * @typedef GetSigningKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetSigningKeyResponse} [response] GetSigningKeyResponse
             */

            /**
             * Calls GetSigningKey.
             * @function getSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IGetSigningKeyRequest} request GetSigningKeyRequest message or plain object
             * @param {streem.api.Webhooks.GetSigningKeyCallback} callback Node-style callback called with the error, if any, and GetSigningKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.getSigningKey = function getSigningKey(request, callback) {
                return this.rpcCall(getSigningKey, $root.streem.api.GetSigningKeyRequest, $root.streem.api.GetSigningKeyResponse, request, callback);
            }, "name", { value: "GetSigningKey" });

            /**
             * Calls GetSigningKey.
             * @function getSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IGetSigningKeyRequest} request GetSigningKeyRequest message or plain object
             * @returns {Promise<streem.api.GetSigningKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#updateSigningKey}.
             * @memberof streem.api.Webhooks
             * @typedef UpdateSigningKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateSigningKeyResponse} [response] UpdateSigningKeyResponse
             */

            /**
             * Calls UpdateSigningKey.
             * @function updateSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IUpdateSigningKeyRequest} request UpdateSigningKeyRequest message or plain object
             * @param {streem.api.Webhooks.UpdateSigningKeyCallback} callback Node-style callback called with the error, if any, and UpdateSigningKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.updateSigningKey = function updateSigningKey(request, callback) {
                return this.rpcCall(updateSigningKey, $root.streem.api.UpdateSigningKeyRequest, $root.streem.api.UpdateSigningKeyResponse, request, callback);
            }, "name", { value: "UpdateSigningKey" });

            /**
             * Calls UpdateSigningKey.
             * @function updateSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IUpdateSigningKeyRequest} request UpdateSigningKeyRequest message or plain object
             * @returns {Promise<streem.api.UpdateSigningKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#deleteSigningKey}.
             * @memberof streem.api.Webhooks
             * @typedef DeleteSigningKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteSigningKeyResponse} [response] DeleteSigningKeyResponse
             */

            /**
             * Calls DeleteSigningKey.
             * @function deleteSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IDeleteSigningKeyRequest} request DeleteSigningKeyRequest message or plain object
             * @param {streem.api.Webhooks.DeleteSigningKeyCallback} callback Node-style callback called with the error, if any, and DeleteSigningKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.deleteSigningKey = function deleteSigningKey(request, callback) {
                return this.rpcCall(deleteSigningKey, $root.streem.api.DeleteSigningKeyRequest, $root.streem.api.DeleteSigningKeyResponse, request, callback);
            }, "name", { value: "DeleteSigningKey" });

            /**
             * Calls DeleteSigningKey.
             * @function deleteSigningKey
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.IDeleteSigningKeyRequest} request DeleteSigningKeyRequest message or plain object
             * @returns {Promise<streem.api.DeleteSigningKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Webhooks#testWebhook}.
             * @memberof streem.api.Webhooks
             * @typedef TestWebhookCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.TestWebhookResponse} [response] TestWebhookResponse
             */

            /**
             * Calls TestWebhook.
             * @function testWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ITestWebhookRequest} request TestWebhookRequest message or plain object
             * @param {streem.api.Webhooks.TestWebhookCallback} callback Node-style callback called with the error, if any, and TestWebhookResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Webhooks.prototype.testWebhook = function testWebhook(request, callback) {
                return this.rpcCall(testWebhook, $root.streem.api.TestWebhookRequest, $root.streem.api.TestWebhookResponse, request, callback);
            }, "name", { value: "TestWebhook" });

            /**
             * Calls TestWebhook.
             * @function testWebhook
             * @memberof streem.api.Webhooks
             * @instance
             * @param {streem.api.ITestWebhookRequest} request TestWebhookRequest message or plain object
             * @returns {Promise<streem.api.TestWebhookResponse>} Promise
             * @variation 2
             */

            return Webhooks;
        })();

        api.ListWebhooksRequest = (function() {

            /**
             * Properties of a ListWebhooksRequest.
             * @memberof streem.api
             * @interface IListWebhooksRequest
             * @property {number|null} [pageSize] ListWebhooksRequest pageSize
             * @property {string|null} [pageToken] ListWebhooksRequest pageToken
             * @property {string|null} [companySid] ListWebhooksRequest companySid
             * @property {string|null} [sort] ListWebhooksRequest sort
             */

            /**
             * Constructs a new ListWebhooksRequest.
             * @memberof streem.api
             * @classdesc Represents a ListWebhooksRequest.
             * @implements IListWebhooksRequest
             * @constructor
             * @param {streem.api.IListWebhooksRequest=} [p] Properties to set
             */
            function ListWebhooksRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListWebhooksRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListWebhooksRequest
             * @instance
             */
            ListWebhooksRequest.prototype.pageSize = 0;

            /**
             * ListWebhooksRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListWebhooksRequest
             * @instance
             */
            ListWebhooksRequest.prototype.pageToken = "";

            /**
             * ListWebhooksRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.ListWebhooksRequest
             * @instance
             */
            ListWebhooksRequest.prototype.companySid = "";

            /**
             * ListWebhooksRequest sort.
             * @member {string} sort
             * @memberof streem.api.ListWebhooksRequest
             * @instance
             */
            ListWebhooksRequest.prototype.sort = "";

            /**
             * Creates a new ListWebhooksRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {streem.api.IListWebhooksRequest=} [properties] Properties to set
             * @returns {streem.api.ListWebhooksRequest} ListWebhooksRequest instance
             */
            ListWebhooksRequest.create = function create(properties) {
                return new ListWebhooksRequest(properties);
            };

            /**
             * Encodes the specified ListWebhooksRequest message. Does not implicitly {@link streem.api.ListWebhooksRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {streem.api.IListWebhooksRequest} m ListWebhooksRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListWebhooksRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(8).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(18).string(m.pageToken);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(26).string(m.companySid);
                if (m.sort != null && Object.hasOwnProperty.call(m, "sort"))
                    w.uint32(34).string(m.sort);
                return w;
            };

            /**
             * Decodes a ListWebhooksRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListWebhooksRequest} ListWebhooksRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListWebhooksRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListWebhooksRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 2: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.companySid = r.string();
                            break;
                        }
                    case 4: {
                            m.sort = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListWebhooksRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListWebhooksRequest} ListWebhooksRequest
             */
            ListWebhooksRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListWebhooksRequest)
                    return d;
                var m = new $root.streem.api.ListWebhooksRequest();
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.sort != null) {
                    m.sort = String(d.sort);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListWebhooksRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {streem.api.ListWebhooksRequest} m ListWebhooksRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListWebhooksRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.companySid = "";
                    d.sort = "";
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.sort != null && m.hasOwnProperty("sort")) {
                    d.sort = m.sort;
                }
                return d;
            };

            /**
             * Converts this ListWebhooksRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListWebhooksRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListWebhooksRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListWebhooksRequest
             * @function getTypeUrl
             * @memberof streem.api.ListWebhooksRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListWebhooksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListWebhooksRequest";
            };

            return ListWebhooksRequest;
        })();

        api.ListWebhooksResponse = (function() {

            /**
             * Properties of a ListWebhooksResponse.
             * @memberof streem.api
             * @interface IListWebhooksResponse
             * @property {Array.<streem.api.IWebhook>|null} [webhooks] ListWebhooksResponse webhooks
             * @property {string|null} [nextPageToken] ListWebhooksResponse nextPageToken
             * @property {number|null} [totalSize] ListWebhooksResponse totalSize
             */

            /**
             * Constructs a new ListWebhooksResponse.
             * @memberof streem.api
             * @classdesc Represents a ListWebhooksResponse.
             * @implements IListWebhooksResponse
             * @constructor
             * @param {streem.api.IListWebhooksResponse=} [p] Properties to set
             */
            function ListWebhooksResponse(p) {
                this.webhooks = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListWebhooksResponse webhooks.
             * @member {Array.<streem.api.IWebhook>} webhooks
             * @memberof streem.api.ListWebhooksResponse
             * @instance
             */
            ListWebhooksResponse.prototype.webhooks = $util.emptyArray;

            /**
             * ListWebhooksResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListWebhooksResponse
             * @instance
             */
            ListWebhooksResponse.prototype.nextPageToken = "";

            /**
             * ListWebhooksResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListWebhooksResponse
             * @instance
             */
            ListWebhooksResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListWebhooksResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {streem.api.IListWebhooksResponse=} [properties] Properties to set
             * @returns {streem.api.ListWebhooksResponse} ListWebhooksResponse instance
             */
            ListWebhooksResponse.create = function create(properties) {
                return new ListWebhooksResponse(properties);
            };

            /**
             * Encodes the specified ListWebhooksResponse message. Does not implicitly {@link streem.api.ListWebhooksResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {streem.api.IListWebhooksResponse} m ListWebhooksResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListWebhooksResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhooks != null && m.webhooks.length) {
                    for (var i = 0; i < m.webhooks.length; ++i)
                        $root.streem.api.Webhook.encode(m.webhooks[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListWebhooksResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListWebhooksResponse} ListWebhooksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListWebhooksResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListWebhooksResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.webhooks && m.webhooks.length))
                                m.webhooks = [];
                            m.webhooks.push($root.streem.api.Webhook.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListWebhooksResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListWebhooksResponse} ListWebhooksResponse
             */
            ListWebhooksResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListWebhooksResponse)
                    return d;
                var m = new $root.streem.api.ListWebhooksResponse();
                if (d.webhooks) {
                    if (!Array.isArray(d.webhooks))
                        throw TypeError(".streem.api.ListWebhooksResponse.webhooks: array expected");
                    m.webhooks = [];
                    for (var i = 0; i < d.webhooks.length; ++i) {
                        if (typeof d.webhooks[i] !== "object")
                            throw TypeError(".streem.api.ListWebhooksResponse.webhooks: object expected");
                        m.webhooks[i] = $root.streem.api.Webhook.fromObject(d.webhooks[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListWebhooksResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {streem.api.ListWebhooksResponse} m ListWebhooksResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListWebhooksResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.webhooks = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.webhooks && m.webhooks.length) {
                    d.webhooks = [];
                    for (var j = 0; j < m.webhooks.length; ++j) {
                        d.webhooks[j] = $root.streem.api.Webhook.toObject(m.webhooks[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListWebhooksResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListWebhooksResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListWebhooksResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListWebhooksResponse
             * @function getTypeUrl
             * @memberof streem.api.ListWebhooksResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListWebhooksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListWebhooksResponse";
            };

            return ListWebhooksResponse;
        })();

        api.CreateWebhookRequest = (function() {

            /**
             * Properties of a CreateWebhookRequest.
             * @memberof streem.api
             * @interface ICreateWebhookRequest
             * @property {string|null} [companySid] CreateWebhookRequest companySid
             * @property {string|null} [label] CreateWebhookRequest label
             * @property {string|null} [url] CreateWebhookRequest url
             * @property {string|null} [method] CreateWebhookRequest method
             * @property {number|null} [timeoutMs] CreateWebhookRequest timeoutMs
             * @property {number|null} [maxAttempts] CreateWebhookRequest maxAttempts
             * @property {Array.<streem.api.Webhook.IHeader>|null} [headers] CreateWebhookRequest headers
             */

            /**
             * Constructs a new CreateWebhookRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateWebhookRequest.
             * @implements ICreateWebhookRequest
             * @constructor
             * @param {streem.api.ICreateWebhookRequest=} [p] Properties to set
             */
            function CreateWebhookRequest(p) {
                this.headers = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateWebhookRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.companySid = "";

            /**
             * CreateWebhookRequest label.
             * @member {string} label
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.label = "";

            /**
             * CreateWebhookRequest url.
             * @member {string} url
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.url = "";

            /**
             * CreateWebhookRequest method.
             * @member {string} method
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.method = "";

            /**
             * CreateWebhookRequest timeoutMs.
             * @member {number} timeoutMs
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.timeoutMs = 0;

            /**
             * CreateWebhookRequest maxAttempts.
             * @member {number} maxAttempts
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.maxAttempts = 0;

            /**
             * CreateWebhookRequest headers.
             * @member {Array.<streem.api.Webhook.IHeader>} headers
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             */
            CreateWebhookRequest.prototype.headers = $util.emptyArray;

            /**
             * Creates a new CreateWebhookRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {streem.api.ICreateWebhookRequest=} [properties] Properties to set
             * @returns {streem.api.CreateWebhookRequest} CreateWebhookRequest instance
             */
            CreateWebhookRequest.create = function create(properties) {
                return new CreateWebhookRequest(properties);
            };

            /**
             * Encodes the specified CreateWebhookRequest message. Does not implicitly {@link streem.api.CreateWebhookRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {streem.api.ICreateWebhookRequest} m CreateWebhookRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateWebhookRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(18).string(m.label);
                if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                    w.uint32(26).string(m.url);
                if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                    w.uint32(34).string(m.method);
                if (m.timeoutMs != null && Object.hasOwnProperty.call(m, "timeoutMs"))
                    w.uint32(40).int32(m.timeoutMs);
                if (m.maxAttempts != null && Object.hasOwnProperty.call(m, "maxAttempts"))
                    w.uint32(48).int32(m.maxAttempts);
                if (m.headers != null && m.headers.length) {
                    for (var i = 0; i < m.headers.length; ++i)
                        $root.streem.api.Webhook.Header.encode(m.headers[i], w.uint32(58).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a CreateWebhookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateWebhookRequest} CreateWebhookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateWebhookRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateWebhookRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    case 2: {
                            m.label = r.string();
                            break;
                        }
                    case 3: {
                            m.url = r.string();
                            break;
                        }
                    case 4: {
                            m.method = r.string();
                            break;
                        }
                    case 5: {
                            m.timeoutMs = r.int32();
                            break;
                        }
                    case 6: {
                            m.maxAttempts = r.int32();
                            break;
                        }
                    case 7: {
                            if (!(m.headers && m.headers.length))
                                m.headers = [];
                            m.headers.push($root.streem.api.Webhook.Header.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateWebhookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateWebhookRequest} CreateWebhookRequest
             */
            CreateWebhookRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateWebhookRequest)
                    return d;
                var m = new $root.streem.api.CreateWebhookRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                if (d.url != null) {
                    m.url = String(d.url);
                }
                if (d.method != null) {
                    m.method = String(d.method);
                }
                if (d.timeoutMs != null) {
                    m.timeoutMs = d.timeoutMs | 0;
                }
                if (d.maxAttempts != null) {
                    m.maxAttempts = d.maxAttempts | 0;
                }
                if (d.headers) {
                    if (!Array.isArray(d.headers))
                        throw TypeError(".streem.api.CreateWebhookRequest.headers: array expected");
                    m.headers = [];
                    for (var i = 0; i < d.headers.length; ++i) {
                        if (typeof d.headers[i] !== "object")
                            throw TypeError(".streem.api.CreateWebhookRequest.headers: object expected");
                        m.headers[i] = $root.streem.api.Webhook.Header.fromObject(d.headers[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateWebhookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {streem.api.CreateWebhookRequest} m CreateWebhookRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateWebhookRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.headers = [];
                }
                if (o.defaults) {
                    d.companySid = "";
                    d.label = "";
                    d.url = "";
                    d.method = "";
                    d.timeoutMs = 0;
                    d.maxAttempts = 0;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = m.url;
                }
                if (m.method != null && m.hasOwnProperty("method")) {
                    d.method = m.method;
                }
                if (m.timeoutMs != null && m.hasOwnProperty("timeoutMs")) {
                    d.timeoutMs = m.timeoutMs;
                }
                if (m.maxAttempts != null && m.hasOwnProperty("maxAttempts")) {
                    d.maxAttempts = m.maxAttempts;
                }
                if (m.headers && m.headers.length) {
                    d.headers = [];
                    for (var j = 0; j < m.headers.length; ++j) {
                        d.headers[j] = $root.streem.api.Webhook.Header.toObject(m.headers[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this CreateWebhookRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateWebhookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateWebhookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateWebhookRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateWebhookRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateWebhookRequest";
            };

            return CreateWebhookRequest;
        })();

        api.CreateWebhookResponse = (function() {

            /**
             * Properties of a CreateWebhookResponse.
             * @memberof streem.api
             * @interface ICreateWebhookResponse
             * @property {streem.api.IWebhook|null} [webhook] CreateWebhookResponse webhook
             * @property {streem.api.IWebhookSigningKey|null} [signingKey] CreateWebhookResponse signingKey
             */

            /**
             * Constructs a new CreateWebhookResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateWebhookResponse.
             * @implements ICreateWebhookResponse
             * @constructor
             * @param {streem.api.ICreateWebhookResponse=} [p] Properties to set
             */
            function CreateWebhookResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateWebhookResponse webhook.
             * @member {streem.api.IWebhook|null|undefined} webhook
             * @memberof streem.api.CreateWebhookResponse
             * @instance
             */
            CreateWebhookResponse.prototype.webhook = null;

            /**
             * CreateWebhookResponse signingKey.
             * @member {streem.api.IWebhookSigningKey|null|undefined} signingKey
             * @memberof streem.api.CreateWebhookResponse
             * @instance
             */
            CreateWebhookResponse.prototype.signingKey = null;

            /**
             * Creates a new CreateWebhookResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {streem.api.ICreateWebhookResponse=} [properties] Properties to set
             * @returns {streem.api.CreateWebhookResponse} CreateWebhookResponse instance
             */
            CreateWebhookResponse.create = function create(properties) {
                return new CreateWebhookResponse(properties);
            };

            /**
             * Encodes the specified CreateWebhookResponse message. Does not implicitly {@link streem.api.CreateWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {streem.api.ICreateWebhookResponse} m CreateWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateWebhookResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhook != null && Object.hasOwnProperty.call(m, "webhook"))
                    $root.streem.api.Webhook.encode(m.webhook, w.uint32(10).fork()).ldelim();
                if (m.signingKey != null && Object.hasOwnProperty.call(m, "signingKey"))
                    $root.streem.api.WebhookSigningKey.encode(m.signingKey, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateWebhookResponse} CreateWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateWebhookResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateWebhookResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhook = $root.streem.api.Webhook.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.signingKey = $root.streem.api.WebhookSigningKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateWebhookResponse} CreateWebhookResponse
             */
            CreateWebhookResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateWebhookResponse)
                    return d;
                var m = new $root.streem.api.CreateWebhookResponse();
                if (d.webhook != null) {
                    if (typeof d.webhook !== "object")
                        throw TypeError(".streem.api.CreateWebhookResponse.webhook: object expected");
                    m.webhook = $root.streem.api.Webhook.fromObject(d.webhook);
                }
                if (d.signingKey != null) {
                    if (typeof d.signingKey !== "object")
                        throw TypeError(".streem.api.CreateWebhookResponse.signingKey: object expected");
                    m.signingKey = $root.streem.api.WebhookSigningKey.fromObject(d.signingKey);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {streem.api.CreateWebhookResponse} m CreateWebhookResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateWebhookResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhook = null;
                    d.signingKey = null;
                }
                if (m.webhook != null && m.hasOwnProperty("webhook")) {
                    d.webhook = $root.streem.api.Webhook.toObject(m.webhook, o);
                }
                if (m.signingKey != null && m.hasOwnProperty("signingKey")) {
                    d.signingKey = $root.streem.api.WebhookSigningKey.toObject(m.signingKey, o);
                }
                return d;
            };

            /**
             * Converts this CreateWebhookResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateWebhookResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateWebhookResponse";
            };

            return CreateWebhookResponse;
        })();

        api.GetWebhookRequest = (function() {

            /**
             * Properties of a GetWebhookRequest.
             * @memberof streem.api
             * @interface IGetWebhookRequest
             * @property {string|null} [webhookSid] GetWebhookRequest webhookSid
             */

            /**
             * Constructs a new GetWebhookRequest.
             * @memberof streem.api
             * @classdesc Represents a GetWebhookRequest.
             * @implements IGetWebhookRequest
             * @constructor
             * @param {streem.api.IGetWebhookRequest=} [p] Properties to set
             */
            function GetWebhookRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetWebhookRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.GetWebhookRequest
             * @instance
             */
            GetWebhookRequest.prototype.webhookSid = "";

            /**
             * Creates a new GetWebhookRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {streem.api.IGetWebhookRequest=} [properties] Properties to set
             * @returns {streem.api.GetWebhookRequest} GetWebhookRequest instance
             */
            GetWebhookRequest.create = function create(properties) {
                return new GetWebhookRequest(properties);
            };

            /**
             * Encodes the specified GetWebhookRequest message. Does not implicitly {@link streem.api.GetWebhookRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {streem.api.IGetWebhookRequest} m GetWebhookRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWebhookRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                return w;
            };

            /**
             * Decodes a GetWebhookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetWebhookRequest} GetWebhookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWebhookRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetWebhookRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetWebhookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetWebhookRequest} GetWebhookRequest
             */
            GetWebhookRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetWebhookRequest)
                    return d;
                var m = new $root.streem.api.GetWebhookRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetWebhookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {streem.api.GetWebhookRequest} m GetWebhookRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWebhookRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                return d;
            };

            /**
             * Converts this GetWebhookRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetWebhookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWebhookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetWebhookRequest
             * @function getTypeUrl
             * @memberof streem.api.GetWebhookRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetWebhookRequest";
            };

            return GetWebhookRequest;
        })();

        api.GetWebhookResponse = (function() {

            /**
             * Properties of a GetWebhookResponse.
             * @memberof streem.api
             * @interface IGetWebhookResponse
             * @property {streem.api.IWebhook|null} [webhook] GetWebhookResponse webhook
             */

            /**
             * Constructs a new GetWebhookResponse.
             * @memberof streem.api
             * @classdesc Represents a GetWebhookResponse.
             * @implements IGetWebhookResponse
             * @constructor
             * @param {streem.api.IGetWebhookResponse=} [p] Properties to set
             */
            function GetWebhookResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetWebhookResponse webhook.
             * @member {streem.api.IWebhook|null|undefined} webhook
             * @memberof streem.api.GetWebhookResponse
             * @instance
             */
            GetWebhookResponse.prototype.webhook = null;

            /**
             * Creates a new GetWebhookResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {streem.api.IGetWebhookResponse=} [properties] Properties to set
             * @returns {streem.api.GetWebhookResponse} GetWebhookResponse instance
             */
            GetWebhookResponse.create = function create(properties) {
                return new GetWebhookResponse(properties);
            };

            /**
             * Encodes the specified GetWebhookResponse message. Does not implicitly {@link streem.api.GetWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {streem.api.IGetWebhookResponse} m GetWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWebhookResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhook != null && Object.hasOwnProperty.call(m, "webhook"))
                    $root.streem.api.Webhook.encode(m.webhook, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetWebhookResponse} GetWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWebhookResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetWebhookResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhook = $root.streem.api.Webhook.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetWebhookResponse} GetWebhookResponse
             */
            GetWebhookResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetWebhookResponse)
                    return d;
                var m = new $root.streem.api.GetWebhookResponse();
                if (d.webhook != null) {
                    if (typeof d.webhook !== "object")
                        throw TypeError(".streem.api.GetWebhookResponse.webhook: object expected");
                    m.webhook = $root.streem.api.Webhook.fromObject(d.webhook);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {streem.api.GetWebhookResponse} m GetWebhookResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWebhookResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhook = null;
                }
                if (m.webhook != null && m.hasOwnProperty("webhook")) {
                    d.webhook = $root.streem.api.Webhook.toObject(m.webhook, o);
                }
                return d;
            };

            /**
             * Converts this GetWebhookResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetWebhookResponse
             * @function getTypeUrl
             * @memberof streem.api.GetWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetWebhookResponse";
            };

            return GetWebhookResponse;
        })();

        api.UpdateWebhookRequest = (function() {

            /**
             * Properties of an UpdateWebhookRequest.
             * @memberof streem.api
             * @interface IUpdateWebhookRequest
             * @property {string|null} [webhookSid] UpdateWebhookRequest webhookSid
             * @property {google.protobuf.IStringValue|null} [label] UpdateWebhookRequest label
             * @property {google.protobuf.IStringValue|null} [url] UpdateWebhookRequest url
             * @property {google.protobuf.IStringValue|null} [method] UpdateWebhookRequest method
             * @property {google.protobuf.IInt32Value|null} [timeoutMs] UpdateWebhookRequest timeoutMs
             * @property {google.protobuf.IInt32Value|null} [maxAttempts] UpdateWebhookRequest maxAttempts
             * @property {Array.<streem.api.Webhook.IHeader>|null} [headers] UpdateWebhookRequest headers
             * @property {boolean|null} [clearHeaders] UpdateWebhookRequest clearHeaders
             */

            /**
             * Constructs a new UpdateWebhookRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateWebhookRequest.
             * @implements IUpdateWebhookRequest
             * @constructor
             * @param {streem.api.IUpdateWebhookRequest=} [p] Properties to set
             */
            function UpdateWebhookRequest(p) {
                this.headers = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateWebhookRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.webhookSid = "";

            /**
             * UpdateWebhookRequest label.
             * @member {google.protobuf.IStringValue|null|undefined} label
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.label = null;

            /**
             * UpdateWebhookRequest url.
             * @member {google.protobuf.IStringValue|null|undefined} url
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.url = null;

            /**
             * UpdateWebhookRequest method.
             * @member {google.protobuf.IStringValue|null|undefined} method
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.method = null;

            /**
             * UpdateWebhookRequest timeoutMs.
             * @member {google.protobuf.IInt32Value|null|undefined} timeoutMs
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.timeoutMs = null;

            /**
             * UpdateWebhookRequest maxAttempts.
             * @member {google.protobuf.IInt32Value|null|undefined} maxAttempts
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.maxAttempts = null;

            /**
             * UpdateWebhookRequest headers.
             * @member {Array.<streem.api.Webhook.IHeader>} headers
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.headers = $util.emptyArray;

            /**
             * UpdateWebhookRequest clearHeaders.
             * @member {boolean} clearHeaders
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             */
            UpdateWebhookRequest.prototype.clearHeaders = false;

            /**
             * Creates a new UpdateWebhookRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {streem.api.IUpdateWebhookRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateWebhookRequest} UpdateWebhookRequest instance
             */
            UpdateWebhookRequest.create = function create(properties) {
                return new UpdateWebhookRequest(properties);
            };

            /**
             * Encodes the specified UpdateWebhookRequest message. Does not implicitly {@link streem.api.UpdateWebhookRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {streem.api.IUpdateWebhookRequest} m UpdateWebhookRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateWebhookRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    $root.google.protobuf.StringValue.encode(m.label, w.uint32(18).fork()).ldelim();
                if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                    $root.google.protobuf.StringValue.encode(m.url, w.uint32(26).fork()).ldelim();
                if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                    $root.google.protobuf.StringValue.encode(m.method, w.uint32(34).fork()).ldelim();
                if (m.timeoutMs != null && Object.hasOwnProperty.call(m, "timeoutMs"))
                    $root.google.protobuf.Int32Value.encode(m.timeoutMs, w.uint32(42).fork()).ldelim();
                if (m.maxAttempts != null && Object.hasOwnProperty.call(m, "maxAttempts"))
                    $root.google.protobuf.Int32Value.encode(m.maxAttempts, w.uint32(50).fork()).ldelim();
                if (m.headers != null && m.headers.length) {
                    for (var i = 0; i < m.headers.length; ++i)
                        $root.streem.api.Webhook.Header.encode(m.headers[i], w.uint32(58).fork()).ldelim();
                }
                if (m.clearHeaders != null && Object.hasOwnProperty.call(m, "clearHeaders"))
                    w.uint32(64).bool(m.clearHeaders);
                return w;
            };

            /**
             * Decodes an UpdateWebhookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateWebhookRequest} UpdateWebhookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateWebhookRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateWebhookRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 2: {
                            m.label = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.url = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.method = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.timeoutMs = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.maxAttempts = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            if (!(m.headers && m.headers.length))
                                m.headers = [];
                            m.headers.push($root.streem.api.Webhook.Header.decode(r, r.uint32()));
                            break;
                        }
                    case 8: {
                            m.clearHeaders = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateWebhookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateWebhookRequest} UpdateWebhookRequest
             */
            UpdateWebhookRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateWebhookRequest)
                    return d;
                var m = new $root.streem.api.UpdateWebhookRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.label != null) {
                    if (typeof d.label !== "object")
                        throw TypeError(".streem.api.UpdateWebhookRequest.label: object expected");
                    m.label = $root.google.protobuf.StringValue.fromObject(d.label);
                }
                if (d.url != null) {
                    if (typeof d.url !== "object")
                        throw TypeError(".streem.api.UpdateWebhookRequest.url: object expected");
                    m.url = $root.google.protobuf.StringValue.fromObject(d.url);
                }
                if (d.method != null) {
                    if (typeof d.method !== "object")
                        throw TypeError(".streem.api.UpdateWebhookRequest.method: object expected");
                    m.method = $root.google.protobuf.StringValue.fromObject(d.method);
                }
                if (d.timeoutMs != null) {
                    if (typeof d.timeoutMs !== "object")
                        throw TypeError(".streem.api.UpdateWebhookRequest.timeoutMs: object expected");
                    m.timeoutMs = $root.google.protobuf.Int32Value.fromObject(d.timeoutMs);
                }
                if (d.maxAttempts != null) {
                    if (typeof d.maxAttempts !== "object")
                        throw TypeError(".streem.api.UpdateWebhookRequest.maxAttempts: object expected");
                    m.maxAttempts = $root.google.protobuf.Int32Value.fromObject(d.maxAttempts);
                }
                if (d.headers) {
                    if (!Array.isArray(d.headers))
                        throw TypeError(".streem.api.UpdateWebhookRequest.headers: array expected");
                    m.headers = [];
                    for (var i = 0; i < d.headers.length; ++i) {
                        if (typeof d.headers[i] !== "object")
                            throw TypeError(".streem.api.UpdateWebhookRequest.headers: object expected");
                        m.headers[i] = $root.streem.api.Webhook.Header.fromObject(d.headers[i]);
                    }
                }
                if (d.clearHeaders != null) {
                    m.clearHeaders = Boolean(d.clearHeaders);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateWebhookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {streem.api.UpdateWebhookRequest} m UpdateWebhookRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateWebhookRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.headers = [];
                }
                if (o.defaults) {
                    d.webhookSid = "";
                    d.label = null;
                    d.url = null;
                    d.method = null;
                    d.timeoutMs = null;
                    d.maxAttempts = null;
                    d.clearHeaders = false;
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = $root.google.protobuf.StringValue.toObject(m.label, o);
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = $root.google.protobuf.StringValue.toObject(m.url, o);
                }
                if (m.method != null && m.hasOwnProperty("method")) {
                    d.method = $root.google.protobuf.StringValue.toObject(m.method, o);
                }
                if (m.timeoutMs != null && m.hasOwnProperty("timeoutMs")) {
                    d.timeoutMs = $root.google.protobuf.Int32Value.toObject(m.timeoutMs, o);
                }
                if (m.maxAttempts != null && m.hasOwnProperty("maxAttempts")) {
                    d.maxAttempts = $root.google.protobuf.Int32Value.toObject(m.maxAttempts, o);
                }
                if (m.headers && m.headers.length) {
                    d.headers = [];
                    for (var j = 0; j < m.headers.length; ++j) {
                        d.headers[j] = $root.streem.api.Webhook.Header.toObject(m.headers[j], o);
                    }
                }
                if (m.clearHeaders != null && m.hasOwnProperty("clearHeaders")) {
                    d.clearHeaders = m.clearHeaders;
                }
                return d;
            };

            /**
             * Converts this UpdateWebhookRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateWebhookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateWebhookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateWebhookRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateWebhookRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateWebhookRequest";
            };

            return UpdateWebhookRequest;
        })();

        api.UpdateWebhookResponse = (function() {

            /**
             * Properties of an UpdateWebhookResponse.
             * @memberof streem.api
             * @interface IUpdateWebhookResponse
             * @property {streem.api.IWebhook|null} [webhook] UpdateWebhookResponse webhook
             */

            /**
             * Constructs a new UpdateWebhookResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateWebhookResponse.
             * @implements IUpdateWebhookResponse
             * @constructor
             * @param {streem.api.IUpdateWebhookResponse=} [p] Properties to set
             */
            function UpdateWebhookResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateWebhookResponse webhook.
             * @member {streem.api.IWebhook|null|undefined} webhook
             * @memberof streem.api.UpdateWebhookResponse
             * @instance
             */
            UpdateWebhookResponse.prototype.webhook = null;

            /**
             * Creates a new UpdateWebhookResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {streem.api.IUpdateWebhookResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateWebhookResponse} UpdateWebhookResponse instance
             */
            UpdateWebhookResponse.create = function create(properties) {
                return new UpdateWebhookResponse(properties);
            };

            /**
             * Encodes the specified UpdateWebhookResponse message. Does not implicitly {@link streem.api.UpdateWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {streem.api.IUpdateWebhookResponse} m UpdateWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateWebhookResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhook != null && Object.hasOwnProperty.call(m, "webhook"))
                    $root.streem.api.Webhook.encode(m.webhook, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateWebhookResponse} UpdateWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateWebhookResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateWebhookResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhook = $root.streem.api.Webhook.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateWebhookResponse} UpdateWebhookResponse
             */
            UpdateWebhookResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateWebhookResponse)
                    return d;
                var m = new $root.streem.api.UpdateWebhookResponse();
                if (d.webhook != null) {
                    if (typeof d.webhook !== "object")
                        throw TypeError(".streem.api.UpdateWebhookResponse.webhook: object expected");
                    m.webhook = $root.streem.api.Webhook.fromObject(d.webhook);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {streem.api.UpdateWebhookResponse} m UpdateWebhookResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateWebhookResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhook = null;
                }
                if (m.webhook != null && m.hasOwnProperty("webhook")) {
                    d.webhook = $root.streem.api.Webhook.toObject(m.webhook, o);
                }
                return d;
            };

            /**
             * Converts this UpdateWebhookResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateWebhookResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateWebhookResponse";
            };

            return UpdateWebhookResponse;
        })();

        api.DeleteWebhookRequest = (function() {

            /**
             * Properties of a DeleteWebhookRequest.
             * @memberof streem.api
             * @interface IDeleteWebhookRequest
             * @property {string|null} [webhookSid] DeleteWebhookRequest webhookSid
             */

            /**
             * Constructs a new DeleteWebhookRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteWebhookRequest.
             * @implements IDeleteWebhookRequest
             * @constructor
             * @param {streem.api.IDeleteWebhookRequest=} [p] Properties to set
             */
            function DeleteWebhookRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteWebhookRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.DeleteWebhookRequest
             * @instance
             */
            DeleteWebhookRequest.prototype.webhookSid = "";

            /**
             * Creates a new DeleteWebhookRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {streem.api.IDeleteWebhookRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteWebhookRequest} DeleteWebhookRequest instance
             */
            DeleteWebhookRequest.create = function create(properties) {
                return new DeleteWebhookRequest(properties);
            };

            /**
             * Encodes the specified DeleteWebhookRequest message. Does not implicitly {@link streem.api.DeleteWebhookRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {streem.api.IDeleteWebhookRequest} m DeleteWebhookRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteWebhookRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                return w;
            };

            /**
             * Decodes a DeleteWebhookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteWebhookRequest} DeleteWebhookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteWebhookRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteWebhookRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteWebhookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteWebhookRequest} DeleteWebhookRequest
             */
            DeleteWebhookRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteWebhookRequest)
                    return d;
                var m = new $root.streem.api.DeleteWebhookRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteWebhookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {streem.api.DeleteWebhookRequest} m DeleteWebhookRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteWebhookRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                return d;
            };

            /**
             * Converts this DeleteWebhookRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteWebhookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteWebhookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteWebhookRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteWebhookRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteWebhookRequest";
            };

            return DeleteWebhookRequest;
        })();

        api.DeleteWebhookResponse = (function() {

            /**
             * Properties of a DeleteWebhookResponse.
             * @memberof streem.api
             * @interface IDeleteWebhookResponse
             */

            /**
             * Constructs a new DeleteWebhookResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteWebhookResponse.
             * @implements IDeleteWebhookResponse
             * @constructor
             * @param {streem.api.IDeleteWebhookResponse=} [p] Properties to set
             */
            function DeleteWebhookResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteWebhookResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {streem.api.IDeleteWebhookResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteWebhookResponse} DeleteWebhookResponse instance
             */
            DeleteWebhookResponse.create = function create(properties) {
                return new DeleteWebhookResponse(properties);
            };

            /**
             * Encodes the specified DeleteWebhookResponse message. Does not implicitly {@link streem.api.DeleteWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {streem.api.IDeleteWebhookResponse} m DeleteWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteWebhookResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteWebhookResponse} DeleteWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteWebhookResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteWebhookResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteWebhookResponse} DeleteWebhookResponse
             */
            DeleteWebhookResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteWebhookResponse)
                    return d;
                return new $root.streem.api.DeleteWebhookResponse();
            };

            /**
             * Creates a plain object from a DeleteWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {streem.api.DeleteWebhookResponse} m DeleteWebhookResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteWebhookResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteWebhookResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteWebhookResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteWebhookResponse";
            };

            return DeleteWebhookResponse;
        })();

        api.ListSigningKeysRequest = (function() {

            /**
             * Properties of a ListSigningKeysRequest.
             * @memberof streem.api
             * @interface IListSigningKeysRequest
             * @property {number|null} [webhookSid] ListSigningKeysRequest webhookSid
             * @property {number|null} [pageSize] ListSigningKeysRequest pageSize
             * @property {string|null} [pageToken] ListSigningKeysRequest pageToken
             * @property {string|null} [sort] ListSigningKeysRequest sort
             * @property {google.protobuf.IBoolValue|null} [expired] ListSigningKeysRequest expired
             */

            /**
             * Constructs a new ListSigningKeysRequest.
             * @memberof streem.api
             * @classdesc Represents a ListSigningKeysRequest.
             * @implements IListSigningKeysRequest
             * @constructor
             * @param {streem.api.IListSigningKeysRequest=} [p] Properties to set
             */
            function ListSigningKeysRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSigningKeysRequest webhookSid.
             * @member {number} webhookSid
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             */
            ListSigningKeysRequest.prototype.webhookSid = 0;

            /**
             * ListSigningKeysRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             */
            ListSigningKeysRequest.prototype.pageSize = 0;

            /**
             * ListSigningKeysRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             */
            ListSigningKeysRequest.prototype.pageToken = "";

            /**
             * ListSigningKeysRequest sort.
             * @member {string} sort
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             */
            ListSigningKeysRequest.prototype.sort = "";

            /**
             * ListSigningKeysRequest expired.
             * @member {google.protobuf.IBoolValue|null|undefined} expired
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             */
            ListSigningKeysRequest.prototype.expired = null;

            /**
             * Creates a new ListSigningKeysRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {streem.api.IListSigningKeysRequest=} [properties] Properties to set
             * @returns {streem.api.ListSigningKeysRequest} ListSigningKeysRequest instance
             */
            ListSigningKeysRequest.create = function create(properties) {
                return new ListSigningKeysRequest(properties);
            };

            /**
             * Encodes the specified ListSigningKeysRequest message. Does not implicitly {@link streem.api.ListSigningKeysRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {streem.api.IListSigningKeysRequest} m ListSigningKeysRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSigningKeysRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(8).int32(m.webhookSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                if (m.sort != null && Object.hasOwnProperty.call(m, "sort"))
                    w.uint32(34).string(m.sort);
                if (m.expired != null && Object.hasOwnProperty.call(m, "expired"))
                    $root.google.protobuf.BoolValue.encode(m.expired, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ListSigningKeysRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSigningKeysRequest} ListSigningKeysRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSigningKeysRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSigningKeysRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.int32();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 4: {
                            m.sort = r.string();
                            break;
                        }
                    case 5: {
                            m.expired = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSigningKeysRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSigningKeysRequest} ListSigningKeysRequest
             */
            ListSigningKeysRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSigningKeysRequest)
                    return d;
                var m = new $root.streem.api.ListSigningKeysRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = d.webhookSid | 0;
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.sort != null) {
                    m.sort = String(d.sort);
                }
                if (d.expired != null) {
                    if (typeof d.expired !== "object")
                        throw TypeError(".streem.api.ListSigningKeysRequest.expired: object expected");
                    m.expired = $root.google.protobuf.BoolValue.fromObject(d.expired);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSigningKeysRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {streem.api.ListSigningKeysRequest} m ListSigningKeysRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSigningKeysRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = 0;
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.sort = "";
                    d.expired = null;
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.sort != null && m.hasOwnProperty("sort")) {
                    d.sort = m.sort;
                }
                if (m.expired != null && m.hasOwnProperty("expired")) {
                    d.expired = $root.google.protobuf.BoolValue.toObject(m.expired, o);
                }
                return d;
            };

            /**
             * Converts this ListSigningKeysRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListSigningKeysRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSigningKeysRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSigningKeysRequest
             * @function getTypeUrl
             * @memberof streem.api.ListSigningKeysRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSigningKeysRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSigningKeysRequest";
            };

            return ListSigningKeysRequest;
        })();

        api.ListSigningKeysResponse = (function() {

            /**
             * Properties of a ListSigningKeysResponse.
             * @memberof streem.api
             * @interface IListSigningKeysResponse
             * @property {Array.<streem.api.IWebhookSigningKey>|null} [signingKeys] ListSigningKeysResponse signingKeys
             * @property {string|null} [nextPageToken] ListSigningKeysResponse nextPageToken
             * @property {number|null} [totalSize] ListSigningKeysResponse totalSize
             */

            /**
             * Constructs a new ListSigningKeysResponse.
             * @memberof streem.api
             * @classdesc Represents a ListSigningKeysResponse.
             * @implements IListSigningKeysResponse
             * @constructor
             * @param {streem.api.IListSigningKeysResponse=} [p] Properties to set
             */
            function ListSigningKeysResponse(p) {
                this.signingKeys = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSigningKeysResponse signingKeys.
             * @member {Array.<streem.api.IWebhookSigningKey>} signingKeys
             * @memberof streem.api.ListSigningKeysResponse
             * @instance
             */
            ListSigningKeysResponse.prototype.signingKeys = $util.emptyArray;

            /**
             * ListSigningKeysResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListSigningKeysResponse
             * @instance
             */
            ListSigningKeysResponse.prototype.nextPageToken = "";

            /**
             * ListSigningKeysResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListSigningKeysResponse
             * @instance
             */
            ListSigningKeysResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListSigningKeysResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {streem.api.IListSigningKeysResponse=} [properties] Properties to set
             * @returns {streem.api.ListSigningKeysResponse} ListSigningKeysResponse instance
             */
            ListSigningKeysResponse.create = function create(properties) {
                return new ListSigningKeysResponse(properties);
            };

            /**
             * Encodes the specified ListSigningKeysResponse message. Does not implicitly {@link streem.api.ListSigningKeysResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {streem.api.IListSigningKeysResponse} m ListSigningKeysResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSigningKeysResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.signingKeys != null && m.signingKeys.length) {
                    for (var i = 0; i < m.signingKeys.length; ++i)
                        $root.streem.api.WebhookSigningKey.encode(m.signingKeys[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListSigningKeysResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSigningKeysResponse} ListSigningKeysResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSigningKeysResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSigningKeysResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.signingKeys && m.signingKeys.length))
                                m.signingKeys = [];
                            m.signingKeys.push($root.streem.api.WebhookSigningKey.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSigningKeysResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSigningKeysResponse} ListSigningKeysResponse
             */
            ListSigningKeysResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSigningKeysResponse)
                    return d;
                var m = new $root.streem.api.ListSigningKeysResponse();
                if (d.signingKeys) {
                    if (!Array.isArray(d.signingKeys))
                        throw TypeError(".streem.api.ListSigningKeysResponse.signingKeys: array expected");
                    m.signingKeys = [];
                    for (var i = 0; i < d.signingKeys.length; ++i) {
                        if (typeof d.signingKeys[i] !== "object")
                            throw TypeError(".streem.api.ListSigningKeysResponse.signingKeys: object expected");
                        m.signingKeys[i] = $root.streem.api.WebhookSigningKey.fromObject(d.signingKeys[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSigningKeysResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {streem.api.ListSigningKeysResponse} m ListSigningKeysResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSigningKeysResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.signingKeys = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.signingKeys && m.signingKeys.length) {
                    d.signingKeys = [];
                    for (var j = 0; j < m.signingKeys.length; ++j) {
                        d.signingKeys[j] = $root.streem.api.WebhookSigningKey.toObject(m.signingKeys[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListSigningKeysResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListSigningKeysResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSigningKeysResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSigningKeysResponse
             * @function getTypeUrl
             * @memberof streem.api.ListSigningKeysResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSigningKeysResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSigningKeysResponse";
            };

            return ListSigningKeysResponse;
        })();

        api.CreateSigningKeyRequest = (function() {

            /**
             * Properties of a CreateSigningKeyRequest.
             * @memberof streem.api
             * @interface ICreateSigningKeyRequest
             * @property {string|null} [webhookSid] CreateSigningKeyRequest webhookSid
             * @property {string|null} [sharedSecret] CreateSigningKeyRequest sharedSecret
             * @property {google.protobuf.ITimestamp|null} [expiresAt] CreateSigningKeyRequest expiresAt
             * @property {string|null} [label] CreateSigningKeyRequest label
             */

            /**
             * Constructs a new CreateSigningKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateSigningKeyRequest.
             * @implements ICreateSigningKeyRequest
             * @constructor
             * @param {streem.api.ICreateSigningKeyRequest=} [p] Properties to set
             */
            function CreateSigningKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSigningKeyRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.CreateSigningKeyRequest
             * @instance
             */
            CreateSigningKeyRequest.prototype.webhookSid = "";

            /**
             * CreateSigningKeyRequest sharedSecret.
             * @member {string} sharedSecret
             * @memberof streem.api.CreateSigningKeyRequest
             * @instance
             */
            CreateSigningKeyRequest.prototype.sharedSecret = "";

            /**
             * CreateSigningKeyRequest expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.CreateSigningKeyRequest
             * @instance
             */
            CreateSigningKeyRequest.prototype.expiresAt = null;

            /**
             * CreateSigningKeyRequest label.
             * @member {string} label
             * @memberof streem.api.CreateSigningKeyRequest
             * @instance
             */
            CreateSigningKeyRequest.prototype.label = "";

            /**
             * Creates a new CreateSigningKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {streem.api.ICreateSigningKeyRequest=} [properties] Properties to set
             * @returns {streem.api.CreateSigningKeyRequest} CreateSigningKeyRequest instance
             */
            CreateSigningKeyRequest.create = function create(properties) {
                return new CreateSigningKeyRequest(properties);
            };

            /**
             * Encodes the specified CreateSigningKeyRequest message. Does not implicitly {@link streem.api.CreateSigningKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {streem.api.ICreateSigningKeyRequest} m CreateSigningKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSigningKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                if (m.sharedSecret != null && Object.hasOwnProperty.call(m, "sharedSecret"))
                    w.uint32(18).string(m.sharedSecret);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(26).fork()).ldelim();
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(34).string(m.label);
                return w;
            };

            /**
             * Decodes a CreateSigningKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSigningKeyRequest} CreateSigningKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSigningKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSigningKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 2: {
                            m.sharedSecret = r.string();
                            break;
                        }
                    case 3: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.label = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSigningKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSigningKeyRequest} CreateSigningKeyRequest
             */
            CreateSigningKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSigningKeyRequest)
                    return d;
                var m = new $root.streem.api.CreateSigningKeyRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.sharedSecret != null) {
                    m.sharedSecret = String(d.sharedSecret);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.CreateSigningKeyRequest.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSigningKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {streem.api.CreateSigningKeyRequest} m CreateSigningKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSigningKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                    d.sharedSecret = "";
                    d.expiresAt = null;
                    d.label = "";
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.sharedSecret != null && m.hasOwnProperty("sharedSecret")) {
                    d.sharedSecret = m.sharedSecret;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                return d;
            };

            /**
             * Converts this CreateSigningKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSigningKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSigningKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSigningKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateSigningKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSigningKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSigningKeyRequest";
            };

            return CreateSigningKeyRequest;
        })();

        api.CreateSigningKeyResponse = (function() {

            /**
             * Properties of a CreateSigningKeyResponse.
             * @memberof streem.api
             * @interface ICreateSigningKeyResponse
             * @property {streem.api.IWebhookSigningKey|null} [signingKey] CreateSigningKeyResponse signingKey
             */

            /**
             * Constructs a new CreateSigningKeyResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateSigningKeyResponse.
             * @implements ICreateSigningKeyResponse
             * @constructor
             * @param {streem.api.ICreateSigningKeyResponse=} [p] Properties to set
             */
            function CreateSigningKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSigningKeyResponse signingKey.
             * @member {streem.api.IWebhookSigningKey|null|undefined} signingKey
             * @memberof streem.api.CreateSigningKeyResponse
             * @instance
             */
            CreateSigningKeyResponse.prototype.signingKey = null;

            /**
             * Creates a new CreateSigningKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {streem.api.ICreateSigningKeyResponse=} [properties] Properties to set
             * @returns {streem.api.CreateSigningKeyResponse} CreateSigningKeyResponse instance
             */
            CreateSigningKeyResponse.create = function create(properties) {
                return new CreateSigningKeyResponse(properties);
            };

            /**
             * Encodes the specified CreateSigningKeyResponse message. Does not implicitly {@link streem.api.CreateSigningKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {streem.api.ICreateSigningKeyResponse} m CreateSigningKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSigningKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.signingKey != null && Object.hasOwnProperty.call(m, "signingKey"))
                    $root.streem.api.WebhookSigningKey.encode(m.signingKey, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateSigningKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSigningKeyResponse} CreateSigningKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSigningKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSigningKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.signingKey = $root.streem.api.WebhookSigningKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSigningKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSigningKeyResponse} CreateSigningKeyResponse
             */
            CreateSigningKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSigningKeyResponse)
                    return d;
                var m = new $root.streem.api.CreateSigningKeyResponse();
                if (d.signingKey != null) {
                    if (typeof d.signingKey !== "object")
                        throw TypeError(".streem.api.CreateSigningKeyResponse.signingKey: object expected");
                    m.signingKey = $root.streem.api.WebhookSigningKey.fromObject(d.signingKey);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSigningKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {streem.api.CreateSigningKeyResponse} m CreateSigningKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSigningKeyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.signingKey = null;
                }
                if (m.signingKey != null && m.hasOwnProperty("signingKey")) {
                    d.signingKey = $root.streem.api.WebhookSigningKey.toObject(m.signingKey, o);
                }
                return d;
            };

            /**
             * Converts this CreateSigningKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSigningKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSigningKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSigningKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateSigningKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSigningKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSigningKeyResponse";
            };

            return CreateSigningKeyResponse;
        })();

        api.GetSigningKeyRequest = (function() {

            /**
             * Properties of a GetSigningKeyRequest.
             * @memberof streem.api
             * @interface IGetSigningKeyRequest
             * @property {string|null} [webhookSid] GetSigningKeyRequest webhookSid
             * @property {string|null} [signingKeySid] GetSigningKeyRequest signingKeySid
             */

            /**
             * Constructs a new GetSigningKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a GetSigningKeyRequest.
             * @implements IGetSigningKeyRequest
             * @constructor
             * @param {streem.api.IGetSigningKeyRequest=} [p] Properties to set
             */
            function GetSigningKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSigningKeyRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.GetSigningKeyRequest
             * @instance
             */
            GetSigningKeyRequest.prototype.webhookSid = "";

            /**
             * GetSigningKeyRequest signingKeySid.
             * @member {string} signingKeySid
             * @memberof streem.api.GetSigningKeyRequest
             * @instance
             */
            GetSigningKeyRequest.prototype.signingKeySid = "";

            /**
             * Creates a new GetSigningKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {streem.api.IGetSigningKeyRequest=} [properties] Properties to set
             * @returns {streem.api.GetSigningKeyRequest} GetSigningKeyRequest instance
             */
            GetSigningKeyRequest.create = function create(properties) {
                return new GetSigningKeyRequest(properties);
            };

            /**
             * Encodes the specified GetSigningKeyRequest message. Does not implicitly {@link streem.api.GetSigningKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {streem.api.IGetSigningKeyRequest} m GetSigningKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSigningKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                if (m.signingKeySid != null && Object.hasOwnProperty.call(m, "signingKeySid"))
                    w.uint32(18).string(m.signingKeySid);
                return w;
            };

            /**
             * Decodes a GetSigningKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSigningKeyRequest} GetSigningKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSigningKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSigningKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 2: {
                            m.signingKeySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSigningKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSigningKeyRequest} GetSigningKeyRequest
             */
            GetSigningKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSigningKeyRequest)
                    return d;
                var m = new $root.streem.api.GetSigningKeyRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.signingKeySid != null) {
                    m.signingKeySid = String(d.signingKeySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSigningKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {streem.api.GetSigningKeyRequest} m GetSigningKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSigningKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                    d.signingKeySid = "";
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.signingKeySid != null && m.hasOwnProperty("signingKeySid")) {
                    d.signingKeySid = m.signingKeySid;
                }
                return d;
            };

            /**
             * Converts this GetSigningKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetSigningKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSigningKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSigningKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.GetSigningKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSigningKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSigningKeyRequest";
            };

            return GetSigningKeyRequest;
        })();

        api.GetSigningKeyResponse = (function() {

            /**
             * Properties of a GetSigningKeyResponse.
             * @memberof streem.api
             * @interface IGetSigningKeyResponse
             * @property {streem.api.IWebhookSigningKey|null} [signingKey] GetSigningKeyResponse signingKey
             */

            /**
             * Constructs a new GetSigningKeyResponse.
             * @memberof streem.api
             * @classdesc Represents a GetSigningKeyResponse.
             * @implements IGetSigningKeyResponse
             * @constructor
             * @param {streem.api.IGetSigningKeyResponse=} [p] Properties to set
             */
            function GetSigningKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSigningKeyResponse signingKey.
             * @member {streem.api.IWebhookSigningKey|null|undefined} signingKey
             * @memberof streem.api.GetSigningKeyResponse
             * @instance
             */
            GetSigningKeyResponse.prototype.signingKey = null;

            /**
             * Creates a new GetSigningKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {streem.api.IGetSigningKeyResponse=} [properties] Properties to set
             * @returns {streem.api.GetSigningKeyResponse} GetSigningKeyResponse instance
             */
            GetSigningKeyResponse.create = function create(properties) {
                return new GetSigningKeyResponse(properties);
            };

            /**
             * Encodes the specified GetSigningKeyResponse message. Does not implicitly {@link streem.api.GetSigningKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {streem.api.IGetSigningKeyResponse} m GetSigningKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSigningKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.signingKey != null && Object.hasOwnProperty.call(m, "signingKey"))
                    $root.streem.api.WebhookSigningKey.encode(m.signingKey, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetSigningKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSigningKeyResponse} GetSigningKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSigningKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSigningKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.signingKey = $root.streem.api.WebhookSigningKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSigningKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSigningKeyResponse} GetSigningKeyResponse
             */
            GetSigningKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSigningKeyResponse)
                    return d;
                var m = new $root.streem.api.GetSigningKeyResponse();
                if (d.signingKey != null) {
                    if (typeof d.signingKey !== "object")
                        throw TypeError(".streem.api.GetSigningKeyResponse.signingKey: object expected");
                    m.signingKey = $root.streem.api.WebhookSigningKey.fromObject(d.signingKey);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSigningKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {streem.api.GetSigningKeyResponse} m GetSigningKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSigningKeyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.signingKey = null;
                }
                if (m.signingKey != null && m.hasOwnProperty("signingKey")) {
                    d.signingKey = $root.streem.api.WebhookSigningKey.toObject(m.signingKey, o);
                }
                return d;
            };

            /**
             * Converts this GetSigningKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetSigningKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSigningKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSigningKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.GetSigningKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSigningKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSigningKeyResponse";
            };

            return GetSigningKeyResponse;
        })();

        api.UpdateSigningKeyRequest = (function() {

            /**
             * Properties of an UpdateSigningKeyRequest.
             * @memberof streem.api
             * @interface IUpdateSigningKeyRequest
             * @property {string|null} [webhookSid] UpdateSigningKeyRequest webhookSid
             * @property {string|null} [signingKeySid] UpdateSigningKeyRequest signingKeySid
             * @property {google.protobuf.ITimestamp|null} [expiresAt] UpdateSigningKeyRequest expiresAt
             * @property {boolean|null} [clearExpiresAt] UpdateSigningKeyRequest clearExpiresAt
             * @property {google.protobuf.IStringValue|null} [label] UpdateSigningKeyRequest label
             */

            /**
             * Constructs a new UpdateSigningKeyRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateSigningKeyRequest.
             * @implements IUpdateSigningKeyRequest
             * @constructor
             * @param {streem.api.IUpdateSigningKeyRequest=} [p] Properties to set
             */
            function UpdateSigningKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateSigningKeyRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             */
            UpdateSigningKeyRequest.prototype.webhookSid = "";

            /**
             * UpdateSigningKeyRequest signingKeySid.
             * @member {string} signingKeySid
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             */
            UpdateSigningKeyRequest.prototype.signingKeySid = "";

            /**
             * UpdateSigningKeyRequest expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             */
            UpdateSigningKeyRequest.prototype.expiresAt = null;

            /**
             * UpdateSigningKeyRequest clearExpiresAt.
             * @member {boolean} clearExpiresAt
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             */
            UpdateSigningKeyRequest.prototype.clearExpiresAt = false;

            /**
             * UpdateSigningKeyRequest label.
             * @member {google.protobuf.IStringValue|null|undefined} label
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             */
            UpdateSigningKeyRequest.prototype.label = null;

            /**
             * Creates a new UpdateSigningKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {streem.api.IUpdateSigningKeyRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateSigningKeyRequest} UpdateSigningKeyRequest instance
             */
            UpdateSigningKeyRequest.create = function create(properties) {
                return new UpdateSigningKeyRequest(properties);
            };

            /**
             * Encodes the specified UpdateSigningKeyRequest message. Does not implicitly {@link streem.api.UpdateSigningKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {streem.api.IUpdateSigningKeyRequest} m UpdateSigningKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSigningKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                if (m.signingKeySid != null && Object.hasOwnProperty.call(m, "signingKeySid"))
                    w.uint32(18).string(m.signingKeySid);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(26).fork()).ldelim();
                if (m.clearExpiresAt != null && Object.hasOwnProperty.call(m, "clearExpiresAt"))
                    w.uint32(32).bool(m.clearExpiresAt);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    $root.google.protobuf.StringValue.encode(m.label, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateSigningKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateSigningKeyRequest} UpdateSigningKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSigningKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateSigningKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 2: {
                            m.signingKeySid = r.string();
                            break;
                        }
                    case 3: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.clearExpiresAt = r.bool();
                            break;
                        }
                    case 5: {
                            m.label = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateSigningKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateSigningKeyRequest} UpdateSigningKeyRequest
             */
            UpdateSigningKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateSigningKeyRequest)
                    return d;
                var m = new $root.streem.api.UpdateSigningKeyRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.signingKeySid != null) {
                    m.signingKeySid = String(d.signingKeySid);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.UpdateSigningKeyRequest.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.clearExpiresAt != null) {
                    m.clearExpiresAt = Boolean(d.clearExpiresAt);
                }
                if (d.label != null) {
                    if (typeof d.label !== "object")
                        throw TypeError(".streem.api.UpdateSigningKeyRequest.label: object expected");
                    m.label = $root.google.protobuf.StringValue.fromObject(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateSigningKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {streem.api.UpdateSigningKeyRequest} m UpdateSigningKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSigningKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                    d.signingKeySid = "";
                    d.expiresAt = null;
                    d.clearExpiresAt = false;
                    d.label = null;
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.signingKeySid != null && m.hasOwnProperty("signingKeySid")) {
                    d.signingKeySid = m.signingKeySid;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.clearExpiresAt != null && m.hasOwnProperty("clearExpiresAt")) {
                    d.clearExpiresAt = m.clearExpiresAt;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = $root.google.protobuf.StringValue.toObject(m.label, o);
                }
                return d;
            };

            /**
             * Converts this UpdateSigningKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateSigningKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSigningKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateSigningKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateSigningKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSigningKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateSigningKeyRequest";
            };

            return UpdateSigningKeyRequest;
        })();

        api.UpdateSigningKeyResponse = (function() {

            /**
             * Properties of an UpdateSigningKeyResponse.
             * @memberof streem.api
             * @interface IUpdateSigningKeyResponse
             * @property {streem.api.IWebhookSigningKey|null} [signingKey] UpdateSigningKeyResponse signingKey
             */

            /**
             * Constructs a new UpdateSigningKeyResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateSigningKeyResponse.
             * @implements IUpdateSigningKeyResponse
             * @constructor
             * @param {streem.api.IUpdateSigningKeyResponse=} [p] Properties to set
             */
            function UpdateSigningKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateSigningKeyResponse signingKey.
             * @member {streem.api.IWebhookSigningKey|null|undefined} signingKey
             * @memberof streem.api.UpdateSigningKeyResponse
             * @instance
             */
            UpdateSigningKeyResponse.prototype.signingKey = null;

            /**
             * Creates a new UpdateSigningKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {streem.api.IUpdateSigningKeyResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateSigningKeyResponse} UpdateSigningKeyResponse instance
             */
            UpdateSigningKeyResponse.create = function create(properties) {
                return new UpdateSigningKeyResponse(properties);
            };

            /**
             * Encodes the specified UpdateSigningKeyResponse message. Does not implicitly {@link streem.api.UpdateSigningKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {streem.api.IUpdateSigningKeyResponse} m UpdateSigningKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSigningKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.signingKey != null && Object.hasOwnProperty.call(m, "signingKey"))
                    $root.streem.api.WebhookSigningKey.encode(m.signingKey, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateSigningKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateSigningKeyResponse} UpdateSigningKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSigningKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateSigningKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.signingKey = $root.streem.api.WebhookSigningKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateSigningKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateSigningKeyResponse} UpdateSigningKeyResponse
             */
            UpdateSigningKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateSigningKeyResponse)
                    return d;
                var m = new $root.streem.api.UpdateSigningKeyResponse();
                if (d.signingKey != null) {
                    if (typeof d.signingKey !== "object")
                        throw TypeError(".streem.api.UpdateSigningKeyResponse.signingKey: object expected");
                    m.signingKey = $root.streem.api.WebhookSigningKey.fromObject(d.signingKey);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateSigningKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {streem.api.UpdateSigningKeyResponse} m UpdateSigningKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSigningKeyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.signingKey = null;
                }
                if (m.signingKey != null && m.hasOwnProperty("signingKey")) {
                    d.signingKey = $root.streem.api.WebhookSigningKey.toObject(m.signingKey, o);
                }
                return d;
            };

            /**
             * Converts this UpdateSigningKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateSigningKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSigningKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateSigningKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateSigningKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSigningKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateSigningKeyResponse";
            };

            return UpdateSigningKeyResponse;
        })();

        api.DeleteSigningKeyRequest = (function() {

            /**
             * Properties of a DeleteSigningKeyRequest.
             * @memberof streem.api
             * @interface IDeleteSigningKeyRequest
             * @property {string|null} [webhookSid] DeleteSigningKeyRequest webhookSid
             * @property {string|null} [signingKeySid] DeleteSigningKeyRequest signingKeySid
             */

            /**
             * Constructs a new DeleteSigningKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteSigningKeyRequest.
             * @implements IDeleteSigningKeyRequest
             * @constructor
             * @param {streem.api.IDeleteSigningKeyRequest=} [p] Properties to set
             */
            function DeleteSigningKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteSigningKeyRequest webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.DeleteSigningKeyRequest
             * @instance
             */
            DeleteSigningKeyRequest.prototype.webhookSid = "";

            /**
             * DeleteSigningKeyRequest signingKeySid.
             * @member {string} signingKeySid
             * @memberof streem.api.DeleteSigningKeyRequest
             * @instance
             */
            DeleteSigningKeyRequest.prototype.signingKeySid = "";

            /**
             * Creates a new DeleteSigningKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {streem.api.IDeleteSigningKeyRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteSigningKeyRequest} DeleteSigningKeyRequest instance
             */
            DeleteSigningKeyRequest.create = function create(properties) {
                return new DeleteSigningKeyRequest(properties);
            };

            /**
             * Encodes the specified DeleteSigningKeyRequest message. Does not implicitly {@link streem.api.DeleteSigningKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {streem.api.IDeleteSigningKeyRequest} m DeleteSigningKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSigningKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(10).string(m.webhookSid);
                if (m.signingKeySid != null && Object.hasOwnProperty.call(m, "signingKeySid"))
                    w.uint32(18).string(m.signingKeySid);
                return w;
            };

            /**
             * Decodes a DeleteSigningKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSigningKeyRequest} DeleteSigningKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSigningKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSigningKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 2: {
                            m.signingKeySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSigningKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSigningKeyRequest} DeleteSigningKeyRequest
             */
            DeleteSigningKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSigningKeyRequest)
                    return d;
                var m = new $root.streem.api.DeleteSigningKeyRequest();
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.signingKeySid != null) {
                    m.signingKeySid = String(d.signingKeySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteSigningKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {streem.api.DeleteSigningKeyRequest} m DeleteSigningKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSigningKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webhookSid = "";
                    d.signingKeySid = "";
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.signingKeySid != null && m.hasOwnProperty("signingKeySid")) {
                    d.signingKeySid = m.signingKeySid;
                }
                return d;
            };

            /**
             * Converts this DeleteSigningKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSigningKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSigningKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSigningKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteSigningKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSigningKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSigningKeyRequest";
            };

            return DeleteSigningKeyRequest;
        })();

        api.DeleteSigningKeyResponse = (function() {

            /**
             * Properties of a DeleteSigningKeyResponse.
             * @memberof streem.api
             * @interface IDeleteSigningKeyResponse
             */

            /**
             * Constructs a new DeleteSigningKeyResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteSigningKeyResponse.
             * @implements IDeleteSigningKeyResponse
             * @constructor
             * @param {streem.api.IDeleteSigningKeyResponse=} [p] Properties to set
             */
            function DeleteSigningKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteSigningKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {streem.api.IDeleteSigningKeyResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteSigningKeyResponse} DeleteSigningKeyResponse instance
             */
            DeleteSigningKeyResponse.create = function create(properties) {
                return new DeleteSigningKeyResponse(properties);
            };

            /**
             * Encodes the specified DeleteSigningKeyResponse message. Does not implicitly {@link streem.api.DeleteSigningKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {streem.api.IDeleteSigningKeyResponse} m DeleteSigningKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSigningKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteSigningKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSigningKeyResponse} DeleteSigningKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSigningKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSigningKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSigningKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSigningKeyResponse} DeleteSigningKeyResponse
             */
            DeleteSigningKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSigningKeyResponse)
                    return d;
                return new $root.streem.api.DeleteSigningKeyResponse();
            };

            /**
             * Creates a plain object from a DeleteSigningKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {streem.api.DeleteSigningKeyResponse} m DeleteSigningKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSigningKeyResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteSigningKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSigningKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSigningKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSigningKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteSigningKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSigningKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSigningKeyResponse";
            };

            return DeleteSigningKeyResponse;
        })();

        api.TestWebhookRequest = (function() {

            /**
             * Properties of a TestWebhookRequest.
             * @memberof streem.api
             * @interface ITestWebhookRequest
             * @property {streem.api.IWebhookEventPayload|null} [event] TestWebhookRequest event
             */

            /**
             * Constructs a new TestWebhookRequest.
             * @memberof streem.api
             * @classdesc Represents a TestWebhookRequest.
             * @implements ITestWebhookRequest
             * @constructor
             * @param {streem.api.ITestWebhookRequest=} [p] Properties to set
             */
            function TestWebhookRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TestWebhookRequest event.
             * @member {streem.api.IWebhookEventPayload|null|undefined} event
             * @memberof streem.api.TestWebhookRequest
             * @instance
             */
            TestWebhookRequest.prototype.event = null;

            /**
             * Creates a new TestWebhookRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {streem.api.ITestWebhookRequest=} [properties] Properties to set
             * @returns {streem.api.TestWebhookRequest} TestWebhookRequest instance
             */
            TestWebhookRequest.create = function create(properties) {
                return new TestWebhookRequest(properties);
            };

            /**
             * Encodes the specified TestWebhookRequest message. Does not implicitly {@link streem.api.TestWebhookRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {streem.api.ITestWebhookRequest} m TestWebhookRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestWebhookRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.event != null && Object.hasOwnProperty.call(m, "event"))
                    $root.streem.api.WebhookEventPayload.encode(m.event, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TestWebhookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TestWebhookRequest} TestWebhookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestWebhookRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TestWebhookRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.event = $root.streem.api.WebhookEventPayload.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TestWebhookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TestWebhookRequest} TestWebhookRequest
             */
            TestWebhookRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TestWebhookRequest)
                    return d;
                var m = new $root.streem.api.TestWebhookRequest();
                if (d.event != null) {
                    if (typeof d.event !== "object")
                        throw TypeError(".streem.api.TestWebhookRequest.event: object expected");
                    m.event = $root.streem.api.WebhookEventPayload.fromObject(d.event);
                }
                return m;
            };

            /**
             * Creates a plain object from a TestWebhookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {streem.api.TestWebhookRequest} m TestWebhookRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TestWebhookRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.event = null;
                }
                if (m.event != null && m.hasOwnProperty("event")) {
                    d.event = $root.streem.api.WebhookEventPayload.toObject(m.event, o);
                }
                return d;
            };

            /**
             * Converts this TestWebhookRequest to JSON.
             * @function toJSON
             * @memberof streem.api.TestWebhookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TestWebhookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TestWebhookRequest
             * @function getTypeUrl
             * @memberof streem.api.TestWebhookRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TestWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TestWebhookRequest";
            };

            return TestWebhookRequest;
        })();

        api.TestWebhookResponse = (function() {

            /**
             * Properties of a TestWebhookResponse.
             * @memberof streem.api
             * @interface ITestWebhookResponse
             * @property {string|null} [message] TestWebhookResponse message
             */

            /**
             * Constructs a new TestWebhookResponse.
             * @memberof streem.api
             * @classdesc Represents a TestWebhookResponse.
             * @implements ITestWebhookResponse
             * @constructor
             * @param {streem.api.ITestWebhookResponse=} [p] Properties to set
             */
            function TestWebhookResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TestWebhookResponse message.
             * @member {string} message
             * @memberof streem.api.TestWebhookResponse
             * @instance
             */
            TestWebhookResponse.prototype.message = "";

            /**
             * Creates a new TestWebhookResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {streem.api.ITestWebhookResponse=} [properties] Properties to set
             * @returns {streem.api.TestWebhookResponse} TestWebhookResponse instance
             */
            TestWebhookResponse.create = function create(properties) {
                return new TestWebhookResponse(properties);
            };

            /**
             * Encodes the specified TestWebhookResponse message. Does not implicitly {@link streem.api.TestWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {streem.api.ITestWebhookResponse} m TestWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestWebhookResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                    w.uint32(10).string(m.message);
                return w;
            };

            /**
             * Decodes a TestWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TestWebhookResponse} TestWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestWebhookResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TestWebhookResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.message = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TestWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TestWebhookResponse} TestWebhookResponse
             */
            TestWebhookResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TestWebhookResponse)
                    return d;
                var m = new $root.streem.api.TestWebhookResponse();
                if (d.message != null) {
                    m.message = String(d.message);
                }
                return m;
            };

            /**
             * Creates a plain object from a TestWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {streem.api.TestWebhookResponse} m TestWebhookResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TestWebhookResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.message = "";
                }
                if (m.message != null && m.hasOwnProperty("message")) {
                    d.message = m.message;
                }
                return d;
            };

            /**
             * Converts this TestWebhookResponse to JSON.
             * @function toJSON
             * @memberof streem.api.TestWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TestWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TestWebhookResponse
             * @function getTypeUrl
             * @memberof streem.api.TestWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TestWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TestWebhookResponse";
            };

            return TestWebhookResponse;
        })();

        api.Session = (function() {

            /**
             * Properties of a Session.
             * @memberof streem.api
             * @interface ISession
             * @property {string|null} [sessionSid] Session sessionSid
             */

            /**
             * Constructs a new Session.
             * @memberof streem.api
             * @classdesc Represents a Session.
             * @implements ISession
             * @constructor
             * @param {streem.api.ISession=} [p] Properties to set
             */
            function Session(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Session sessionSid.
             * @member {string} sessionSid
             * @memberof streem.api.Session
             * @instance
             */
            Session.prototype.sessionSid = "";

            /**
             * Creates a new Session instance using the specified properties.
             * @function create
             * @memberof streem.api.Session
             * @static
             * @param {streem.api.ISession=} [properties] Properties to set
             * @returns {streem.api.Session} Session instance
             */
            Session.create = function create(properties) {
                return new Session(properties);
            };

            /**
             * Encodes the specified Session message. Does not implicitly {@link streem.api.Session.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Session
             * @static
             * @param {streem.api.ISession} m Session message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sessionSid != null && Object.hasOwnProperty.call(m, "sessionSid"))
                    w.uint32(10).string(m.sessionSid);
                return w;
            };

            /**
             * Decodes a Session message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Session();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sessionSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Session message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Session
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Session} Session
             */
            Session.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Session)
                    return d;
                var m = new $root.streem.api.Session();
                if (d.sessionSid != null) {
                    m.sessionSid = String(d.sessionSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a Session message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Session
             * @static
             * @param {streem.api.Session} m Session
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Session.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sessionSid = "";
                }
                if (m.sessionSid != null && m.hasOwnProperty("sessionSid")) {
                    d.sessionSid = m.sessionSid;
                }
                return d;
            };

            /**
             * Converts this Session to JSON.
             * @function toJSON
             * @memberof streem.api.Session
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Session.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Session
             * @function getTypeUrl
             * @memberof streem.api.Session
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Session";
            };

            return Session;
        })();

        api.AnalyticEvent = (function() {

            /**
             * Properties of an AnalyticEvent.
             * @memberof streem.api
             * @interface IAnalyticEvent
             * @property {google.protobuf.ITimestamp|null} [sentAt] AnalyticEvent sentAt
             * @property {google.protobuf.ITimestamp|null} [receivedAt] AnalyticEvent receivedAt
             * @property {streem.api.AppPlatform|null} [appPlatform] AnalyticEvent appPlatform
             * @property {string|null} [sessionSid] AnalyticEvent sessionSid
             * @property {string|null} [userSid] AnalyticEvent userSid
             * @property {string|null} [deviceSid] AnalyticEvent deviceSid
             * @property {string|null} [appSid] AnalyticEvent appSid
             * @property {streem.api.AnalyticEvent.Region|null} [region] AnalyticEvent region
             * @property {Array.<string>|null} [workflowSids] AnalyticEvent workflowSids
             * @property {string|null} [applicationName] AnalyticEvent applicationName
             * @property {streem.api.AnalyticEvent.IScreenViewed|null} [screenViewed] AnalyticEvent screenViewed
             * @property {streem.api.AnalyticEvent.IElementClicked|null} [elementClicked] AnalyticEvent elementClicked
             * @property {streem.api.AnalyticEvent.IAppBackgrounded|null} [appBackgrounded] AnalyticEvent appBackgrounded
             * @property {streem.api.AnalyticEvent.IAppForegrounded|null} [appForegrounded] AnalyticEvent appForegrounded
             * @property {streem.api.AnalyticEvent.IPermissionRequested|null} [permissionRequested] AnalyticEvent permissionRequested
             * @property {streem.api.AnalyticEvent.IModalViewed|null} [modalViewed] AnalyticEvent modalViewed
             * @property {streem.api.AnalyticEvent.ITooltipViewed|null} [tooltipViewed] AnalyticEvent tooltipViewed
             * @property {streem.api.AnalyticEvent.ILoginAttempted|null} [loginAttempted] AnalyticEvent loginAttempted
             * @property {streem.api.AnalyticEvent.IIdentifyAttempted|null} [identifyAttempted] AnalyticEvent identifyAttempted
             * @property {streem.api.AnalyticEvent.ILoggedOut|null} [loggedOut] AnalyticEvent loggedOut
             * @property {streem.api.AnalyticEvent.IInviteCreated|null} [inviteCreated] AnalyticEvent inviteCreated
             * @property {streem.api.AnalyticEvent.IInviteClicked|null} [inviteClicked] AnalyticEvent inviteClicked
             * @property {streem.api.AnalyticEvent.IConnectionStatusChanged|null} [connectionStatusChanged] AnalyticEvent connectionStatusChanged
             * @property {streem.api.AnalyticEvent.IResolutionChanged|null} [resolutionChanged] AnalyticEvent resolutionChanged
             * @property {streem.api.AnalyticEvent.ILocalVideoStatsMeasured|null} [localVideoStatsMeasured] AnalyticEvent localVideoStatsMeasured
             * @property {streem.api.AnalyticEvent.ILocalAudioStatsMeasured|null} [localAudioStatsMeasured] AnalyticEvent localAudioStatsMeasured
             * @property {streem.api.AnalyticEvent.IRemoteVideoStatsMeasured|null} [remoteVideoStatsMeasured] AnalyticEvent remoteVideoStatsMeasured
             * @property {streem.api.AnalyticEvent.IRemoteAudioStatsMeasured|null} [remoteAudioStatsMeasured] AnalyticEvent remoteAudioStatsMeasured
             * @property {streem.api.AnalyticEvent.ICameraSubjectChangeRequested|null} [cameraSubjectChangeRequested] AnalyticEvent cameraSubjectChangeRequested
             * @property {streem.api.AnalyticEvent.ICameraSubjectChangeRejected|null} [cameraSubjectChangeRejected] AnalyticEvent cameraSubjectChangeRejected
             * @property {streem.api.AnalyticEvent.ICameraSubjectSet|null} [cameraSubjectSet] AnalyticEvent cameraSubjectSet
             * @property {streem.api.AnalyticEvent.ICameraStatusSet|null} [cameraStatusSet] AnalyticEvent cameraStatusSet
             * @property {streem.api.AnalyticEvent.IMicrophoneStatusSet|null} [microphoneStatusSet] AnalyticEvent microphoneStatusSet
             * @property {streem.api.AnalyticEvent.IToolPlaced|null} [toolPlaced] AnalyticEvent toolPlaced
             * @property {streem.api.AnalyticEvent.IToolMoved|null} [toolMoved] AnalyticEvent toolMoved
             * @property {streem.api.AnalyticEvent.IToolRemoved|null} [toolRemoved] AnalyticEvent toolRemoved
             * @property {streem.api.AnalyticEvent.IArtifactRequested|null} [artifactRequested] AnalyticEvent artifactRequested
             * @property {streem.api.AnalyticEvent.IArtifactRequestRejected|null} [artifactRequestRejected] AnalyticEvent artifactRequestRejected
             * @property {streem.api.AnalyticEvent.IArtifactCaptured|null} [artifactCaptured] AnalyticEvent artifactCaptured
             * @property {streem.api.AnalyticEvent.IArtifactFulfilled|null} [artifactFulfilled] AnalyticEvent artifactFulfilled
             * @property {streem.api.AnalyticEvent.IArtifactUpdated|null} [artifactUpdated] AnalyticEvent artifactUpdated
             * @property {streem.api.AnalyticEvent.IArtifactDeleted|null} [artifactDeleted] AnalyticEvent artifactDeleted
             * @property {streem.api.AnalyticEvent.IArtifactViewed|null} [artifactViewed] AnalyticEvent artifactViewed
             * @property {streem.api.AnalyticEvent.IGuidedExperiencePhaseUpdated|null} [guidedExperiencePhaseUpdated] AnalyticEvent guidedExperiencePhaseUpdated
             * @property {streem.api.AnalyticEvent.IFavoriteUpdated|null} [favoriteUpdated] AnalyticEvent favoriteUpdated
             * @property {streem.api.AnalyticEvent.INoteSaved|null} [noteSaved] AnalyticEvent noteSaved
             * @property {streem.api.AnalyticEvent.INoteDeleted|null} [noteDeleted] AnalyticEvent noteDeleted
             * @property {streem.api.AnalyticEvent.IWorkflowStarted|null} [workflowStarted] AnalyticEvent workflowStarted
             * @property {streem.api.AnalyticEvent.IWorkflowAssociated|null} [workflowAssociated] AnalyticEvent workflowAssociated
             * @property {streem.api.AnalyticEvent.ICameraSubjectChangeAborted|null} [cameraSubjectChangeAborted] AnalyticEvent cameraSubjectChangeAborted
             * @property {streem.api.AnalyticEvent.IVignetteElementSelected|null} [vignetteElementSelected] AnalyticEvent vignetteElementSelected
             * @property {streem.api.AnalyticEvent.IVignetteDrawerExpanded|null} [vignetteDrawerExpanded] AnalyticEvent vignetteDrawerExpanded
             * @property {streem.api.AnalyticEvent.IVignetteFinishChanged|null} [vignetteFinishChanged] AnalyticEvent vignetteFinishChanged
             * @property {streem.api.AnalyticEvent.IVignettePortalVisited|null} [vignettePortalVisited] AnalyticEvent vignettePortalVisited
             * @property {streem.api.AnalyticEvent.IVignetteHotspotClicked|null} [vignetteHotspotClicked] AnalyticEvent vignetteHotspotClicked
             */

            /**
             * Constructs a new AnalyticEvent.
             * @memberof streem.api
             * @classdesc Represents an AnalyticEvent.
             * @implements IAnalyticEvent
             * @constructor
             * @param {streem.api.IAnalyticEvent=} [p] Properties to set
             */
            function AnalyticEvent(p) {
                this.workflowSids = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AnalyticEvent sentAt.
             * @member {google.protobuf.ITimestamp|null|undefined} sentAt
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.sentAt = null;

            /**
             * AnalyticEvent receivedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} receivedAt
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.receivedAt = null;

            /**
             * AnalyticEvent appPlatform.
             * @member {streem.api.AppPlatform} appPlatform
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.appPlatform = 0;

            /**
             * AnalyticEvent sessionSid.
             * @member {string} sessionSid
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.sessionSid = "";

            /**
             * AnalyticEvent userSid.
             * @member {string} userSid
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.userSid = "";

            /**
             * AnalyticEvent deviceSid.
             * @member {string} deviceSid
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.deviceSid = "";

            /**
             * AnalyticEvent appSid.
             * @member {string} appSid
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.appSid = "";

            /**
             * AnalyticEvent region.
             * @member {streem.api.AnalyticEvent.Region} region
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.region = 0;

            /**
             * AnalyticEvent workflowSids.
             * @member {Array.<string>} workflowSids
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.workflowSids = $util.emptyArray;

            /**
             * AnalyticEvent applicationName.
             * @member {string} applicationName
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.applicationName = "";

            /**
             * AnalyticEvent screenViewed.
             * @member {streem.api.AnalyticEvent.IScreenViewed|null|undefined} screenViewed
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.screenViewed = null;

            /**
             * AnalyticEvent elementClicked.
             * @member {streem.api.AnalyticEvent.IElementClicked|null|undefined} elementClicked
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.elementClicked = null;

            /**
             * AnalyticEvent appBackgrounded.
             * @member {streem.api.AnalyticEvent.IAppBackgrounded|null|undefined} appBackgrounded
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.appBackgrounded = null;

            /**
             * AnalyticEvent appForegrounded.
             * @member {streem.api.AnalyticEvent.IAppForegrounded|null|undefined} appForegrounded
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.appForegrounded = null;

            /**
             * AnalyticEvent permissionRequested.
             * @member {streem.api.AnalyticEvent.IPermissionRequested|null|undefined} permissionRequested
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.permissionRequested = null;

            /**
             * AnalyticEvent modalViewed.
             * @member {streem.api.AnalyticEvent.IModalViewed|null|undefined} modalViewed
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.modalViewed = null;

            /**
             * AnalyticEvent tooltipViewed.
             * @member {streem.api.AnalyticEvent.ITooltipViewed|null|undefined} tooltipViewed
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.tooltipViewed = null;

            /**
             * AnalyticEvent loginAttempted.
             * @member {streem.api.AnalyticEvent.ILoginAttempted|null|undefined} loginAttempted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.loginAttempted = null;

            /**
             * AnalyticEvent identifyAttempted.
             * @member {streem.api.AnalyticEvent.IIdentifyAttempted|null|undefined} identifyAttempted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.identifyAttempted = null;

            /**
             * AnalyticEvent loggedOut.
             * @member {streem.api.AnalyticEvent.ILoggedOut|null|undefined} loggedOut
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.loggedOut = null;

            /**
             * AnalyticEvent inviteCreated.
             * @member {streem.api.AnalyticEvent.IInviteCreated|null|undefined} inviteCreated
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.inviteCreated = null;

            /**
             * AnalyticEvent inviteClicked.
             * @member {streem.api.AnalyticEvent.IInviteClicked|null|undefined} inviteClicked
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.inviteClicked = null;

            /**
             * AnalyticEvent connectionStatusChanged.
             * @member {streem.api.AnalyticEvent.IConnectionStatusChanged|null|undefined} connectionStatusChanged
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.connectionStatusChanged = null;

            /**
             * AnalyticEvent resolutionChanged.
             * @member {streem.api.AnalyticEvent.IResolutionChanged|null|undefined} resolutionChanged
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.resolutionChanged = null;

            /**
             * AnalyticEvent localVideoStatsMeasured.
             * @member {streem.api.AnalyticEvent.ILocalVideoStatsMeasured|null|undefined} localVideoStatsMeasured
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.localVideoStatsMeasured = null;

            /**
             * AnalyticEvent localAudioStatsMeasured.
             * @member {streem.api.AnalyticEvent.ILocalAudioStatsMeasured|null|undefined} localAudioStatsMeasured
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.localAudioStatsMeasured = null;

            /**
             * AnalyticEvent remoteVideoStatsMeasured.
             * @member {streem.api.AnalyticEvent.IRemoteVideoStatsMeasured|null|undefined} remoteVideoStatsMeasured
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.remoteVideoStatsMeasured = null;

            /**
             * AnalyticEvent remoteAudioStatsMeasured.
             * @member {streem.api.AnalyticEvent.IRemoteAudioStatsMeasured|null|undefined} remoteAudioStatsMeasured
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.remoteAudioStatsMeasured = null;

            /**
             * AnalyticEvent cameraSubjectChangeRequested.
             * @member {streem.api.AnalyticEvent.ICameraSubjectChangeRequested|null|undefined} cameraSubjectChangeRequested
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.cameraSubjectChangeRequested = null;

            /**
             * AnalyticEvent cameraSubjectChangeRejected.
             * @member {streem.api.AnalyticEvent.ICameraSubjectChangeRejected|null|undefined} cameraSubjectChangeRejected
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.cameraSubjectChangeRejected = null;

            /**
             * AnalyticEvent cameraSubjectSet.
             * @member {streem.api.AnalyticEvent.ICameraSubjectSet|null|undefined} cameraSubjectSet
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.cameraSubjectSet = null;

            /**
             * AnalyticEvent cameraStatusSet.
             * @member {streem.api.AnalyticEvent.ICameraStatusSet|null|undefined} cameraStatusSet
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.cameraStatusSet = null;

            /**
             * AnalyticEvent microphoneStatusSet.
             * @member {streem.api.AnalyticEvent.IMicrophoneStatusSet|null|undefined} microphoneStatusSet
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.microphoneStatusSet = null;

            /**
             * AnalyticEvent toolPlaced.
             * @member {streem.api.AnalyticEvent.IToolPlaced|null|undefined} toolPlaced
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.toolPlaced = null;

            /**
             * AnalyticEvent toolMoved.
             * @member {streem.api.AnalyticEvent.IToolMoved|null|undefined} toolMoved
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.toolMoved = null;

            /**
             * AnalyticEvent toolRemoved.
             * @member {streem.api.AnalyticEvent.IToolRemoved|null|undefined} toolRemoved
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.toolRemoved = null;

            /**
             * AnalyticEvent artifactRequested.
             * @member {streem.api.AnalyticEvent.IArtifactRequested|null|undefined} artifactRequested
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactRequested = null;

            /**
             * AnalyticEvent artifactRequestRejected.
             * @member {streem.api.AnalyticEvent.IArtifactRequestRejected|null|undefined} artifactRequestRejected
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactRequestRejected = null;

            /**
             * AnalyticEvent artifactCaptured.
             * @member {streem.api.AnalyticEvent.IArtifactCaptured|null|undefined} artifactCaptured
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactCaptured = null;

            /**
             * AnalyticEvent artifactFulfilled.
             * @member {streem.api.AnalyticEvent.IArtifactFulfilled|null|undefined} artifactFulfilled
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactFulfilled = null;

            /**
             * AnalyticEvent artifactUpdated.
             * @member {streem.api.AnalyticEvent.IArtifactUpdated|null|undefined} artifactUpdated
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactUpdated = null;

            /**
             * AnalyticEvent artifactDeleted.
             * @member {streem.api.AnalyticEvent.IArtifactDeleted|null|undefined} artifactDeleted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactDeleted = null;

            /**
             * AnalyticEvent artifactViewed.
             * @member {streem.api.AnalyticEvent.IArtifactViewed|null|undefined} artifactViewed
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.artifactViewed = null;

            /**
             * AnalyticEvent guidedExperiencePhaseUpdated.
             * @member {streem.api.AnalyticEvent.IGuidedExperiencePhaseUpdated|null|undefined} guidedExperiencePhaseUpdated
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.guidedExperiencePhaseUpdated = null;

            /**
             * AnalyticEvent favoriteUpdated.
             * @member {streem.api.AnalyticEvent.IFavoriteUpdated|null|undefined} favoriteUpdated
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.favoriteUpdated = null;

            /**
             * AnalyticEvent noteSaved.
             * @member {streem.api.AnalyticEvent.INoteSaved|null|undefined} noteSaved
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.noteSaved = null;

            /**
             * AnalyticEvent noteDeleted.
             * @member {streem.api.AnalyticEvent.INoteDeleted|null|undefined} noteDeleted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.noteDeleted = null;

            /**
             * AnalyticEvent workflowStarted.
             * @member {streem.api.AnalyticEvent.IWorkflowStarted|null|undefined} workflowStarted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.workflowStarted = null;

            /**
             * AnalyticEvent workflowAssociated.
             * @member {streem.api.AnalyticEvent.IWorkflowAssociated|null|undefined} workflowAssociated
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.workflowAssociated = null;

            /**
             * AnalyticEvent cameraSubjectChangeAborted.
             * @member {streem.api.AnalyticEvent.ICameraSubjectChangeAborted|null|undefined} cameraSubjectChangeAborted
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.cameraSubjectChangeAborted = null;

            /**
             * AnalyticEvent vignetteElementSelected.
             * @member {streem.api.AnalyticEvent.IVignetteElementSelected|null|undefined} vignetteElementSelected
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.vignetteElementSelected = null;

            /**
             * AnalyticEvent vignetteDrawerExpanded.
             * @member {streem.api.AnalyticEvent.IVignetteDrawerExpanded|null|undefined} vignetteDrawerExpanded
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.vignetteDrawerExpanded = null;

            /**
             * AnalyticEvent vignetteFinishChanged.
             * @member {streem.api.AnalyticEvent.IVignetteFinishChanged|null|undefined} vignetteFinishChanged
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.vignetteFinishChanged = null;

            /**
             * AnalyticEvent vignettePortalVisited.
             * @member {streem.api.AnalyticEvent.IVignettePortalVisited|null|undefined} vignettePortalVisited
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.vignettePortalVisited = null;

            /**
             * AnalyticEvent vignetteHotspotClicked.
             * @member {streem.api.AnalyticEvent.IVignetteHotspotClicked|null|undefined} vignetteHotspotClicked
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            AnalyticEvent.prototype.vignetteHotspotClicked = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * AnalyticEvent type.
             * @member {"screenViewed"|"elementClicked"|"appBackgrounded"|"appForegrounded"|"permissionRequested"|"modalViewed"|"tooltipViewed"|"loginAttempted"|"identifyAttempted"|"loggedOut"|"inviteCreated"|"inviteClicked"|"connectionStatusChanged"|"resolutionChanged"|"localVideoStatsMeasured"|"localAudioStatsMeasured"|"remoteVideoStatsMeasured"|"remoteAudioStatsMeasured"|"cameraSubjectChangeRequested"|"cameraSubjectChangeRejected"|"cameraSubjectSet"|"cameraStatusSet"|"microphoneStatusSet"|"toolPlaced"|"toolMoved"|"toolRemoved"|"artifactRequested"|"artifactRequestRejected"|"artifactCaptured"|"artifactFulfilled"|"artifactUpdated"|"artifactDeleted"|"artifactViewed"|"guidedExperiencePhaseUpdated"|"favoriteUpdated"|"noteSaved"|"noteDeleted"|"workflowStarted"|"workflowAssociated"|"cameraSubjectChangeAborted"|"vignetteElementSelected"|"vignetteDrawerExpanded"|"vignetteFinishChanged"|"vignettePortalVisited"|"vignetteHotspotClicked"|undefined} type
             * @memberof streem.api.AnalyticEvent
             * @instance
             */
            Object.defineProperty(AnalyticEvent.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["screenViewed", "elementClicked", "appBackgrounded", "appForegrounded", "permissionRequested", "modalViewed", "tooltipViewed", "loginAttempted", "identifyAttempted", "loggedOut", "inviteCreated", "inviteClicked", "connectionStatusChanged", "resolutionChanged", "localVideoStatsMeasured", "localAudioStatsMeasured", "remoteVideoStatsMeasured", "remoteAudioStatsMeasured", "cameraSubjectChangeRequested", "cameraSubjectChangeRejected", "cameraSubjectSet", "cameraStatusSet", "microphoneStatusSet", "toolPlaced", "toolMoved", "toolRemoved", "artifactRequested", "artifactRequestRejected", "artifactCaptured", "artifactFulfilled", "artifactUpdated", "artifactDeleted", "artifactViewed", "guidedExperiencePhaseUpdated", "favoriteUpdated", "noteSaved", "noteDeleted", "workflowStarted", "workflowAssociated", "cameraSubjectChangeAborted", "vignetteElementSelected", "vignetteDrawerExpanded", "vignetteFinishChanged", "vignettePortalVisited", "vignetteHotspotClicked"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AnalyticEvent instance using the specified properties.
             * @function create
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {streem.api.IAnalyticEvent=} [properties] Properties to set
             * @returns {streem.api.AnalyticEvent} AnalyticEvent instance
             */
            AnalyticEvent.create = function create(properties) {
                return new AnalyticEvent(properties);
            };

            /**
             * Encodes the specified AnalyticEvent message. Does not implicitly {@link streem.api.AnalyticEvent.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {streem.api.IAnalyticEvent} m AnalyticEvent message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnalyticEvent.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sentAt != null && Object.hasOwnProperty.call(m, "sentAt"))
                    $root.google.protobuf.Timestamp.encode(m.sentAt, w.uint32(10).fork()).ldelim();
                if (m.receivedAt != null && Object.hasOwnProperty.call(m, "receivedAt"))
                    $root.google.protobuf.Timestamp.encode(m.receivedAt, w.uint32(18).fork()).ldelim();
                if (m.appPlatform != null && Object.hasOwnProperty.call(m, "appPlatform"))
                    w.uint32(24).int32(m.appPlatform);
                if (m.sessionSid != null && Object.hasOwnProperty.call(m, "sessionSid"))
                    w.uint32(34).string(m.sessionSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(42).string(m.userSid);
                if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                    w.uint32(50).string(m.deviceSid);
                if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                    w.uint32(58).string(m.appSid);
                if (m.region != null && Object.hasOwnProperty.call(m, "region"))
                    w.uint32(64).int32(m.region);
                if (m.workflowSids != null && m.workflowSids.length) {
                    for (var i = 0; i < m.workflowSids.length; ++i)
                        w.uint32(74).string(m.workflowSids[i]);
                }
                if (m.applicationName != null && Object.hasOwnProperty.call(m, "applicationName"))
                    w.uint32(82).string(m.applicationName);
                if (m.screenViewed != null && Object.hasOwnProperty.call(m, "screenViewed"))
                    $root.streem.api.AnalyticEvent.ScreenViewed.encode(m.screenViewed, w.uint32(802).fork()).ldelim();
                if (m.elementClicked != null && Object.hasOwnProperty.call(m, "elementClicked"))
                    $root.streem.api.AnalyticEvent.ElementClicked.encode(m.elementClicked, w.uint32(810).fork()).ldelim();
                if (m.appBackgrounded != null && Object.hasOwnProperty.call(m, "appBackgrounded"))
                    $root.streem.api.AnalyticEvent.AppBackgrounded.encode(m.appBackgrounded, w.uint32(818).fork()).ldelim();
                if (m.appForegrounded != null && Object.hasOwnProperty.call(m, "appForegrounded"))
                    $root.streem.api.AnalyticEvent.AppForegrounded.encode(m.appForegrounded, w.uint32(826).fork()).ldelim();
                if (m.permissionRequested != null && Object.hasOwnProperty.call(m, "permissionRequested"))
                    $root.streem.api.AnalyticEvent.PermissionRequested.encode(m.permissionRequested, w.uint32(834).fork()).ldelim();
                if (m.modalViewed != null && Object.hasOwnProperty.call(m, "modalViewed"))
                    $root.streem.api.AnalyticEvent.ModalViewed.encode(m.modalViewed, w.uint32(842).fork()).ldelim();
                if (m.tooltipViewed != null && Object.hasOwnProperty.call(m, "tooltipViewed"))
                    $root.streem.api.AnalyticEvent.TooltipViewed.encode(m.tooltipViewed, w.uint32(850).fork()).ldelim();
                if (m.loginAttempted != null && Object.hasOwnProperty.call(m, "loginAttempted"))
                    $root.streem.api.AnalyticEvent.LoginAttempted.encode(m.loginAttempted, w.uint32(1602).fork()).ldelim();
                if (m.identifyAttempted != null && Object.hasOwnProperty.call(m, "identifyAttempted"))
                    $root.streem.api.AnalyticEvent.IdentifyAttempted.encode(m.identifyAttempted, w.uint32(1610).fork()).ldelim();
                if (m.loggedOut != null && Object.hasOwnProperty.call(m, "loggedOut"))
                    $root.streem.api.AnalyticEvent.LoggedOut.encode(m.loggedOut, w.uint32(1618).fork()).ldelim();
                if (m.inviteCreated != null && Object.hasOwnProperty.call(m, "inviteCreated"))
                    $root.streem.api.AnalyticEvent.InviteCreated.encode(m.inviteCreated, w.uint32(2402).fork()).ldelim();
                if (m.inviteClicked != null && Object.hasOwnProperty.call(m, "inviteClicked"))
                    $root.streem.api.AnalyticEvent.InviteClicked.encode(m.inviteClicked, w.uint32(2410).fork()).ldelim();
                if (m.connectionStatusChanged != null && Object.hasOwnProperty.call(m, "connectionStatusChanged"))
                    $root.streem.api.AnalyticEvent.ConnectionStatusChanged.encode(m.connectionStatusChanged, w.uint32(3202).fork()).ldelim();
                if (m.resolutionChanged != null && Object.hasOwnProperty.call(m, "resolutionChanged"))
                    $root.streem.api.AnalyticEvent.ResolutionChanged.encode(m.resolutionChanged, w.uint32(3210).fork()).ldelim();
                if (m.localVideoStatsMeasured != null && Object.hasOwnProperty.call(m, "localVideoStatsMeasured"))
                    $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured.encode(m.localVideoStatsMeasured, w.uint32(3218).fork()).ldelim();
                if (m.localAudioStatsMeasured != null && Object.hasOwnProperty.call(m, "localAudioStatsMeasured"))
                    $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured.encode(m.localAudioStatsMeasured, w.uint32(3226).fork()).ldelim();
                if (m.remoteVideoStatsMeasured != null && Object.hasOwnProperty.call(m, "remoteVideoStatsMeasured"))
                    $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured.encode(m.remoteVideoStatsMeasured, w.uint32(3234).fork()).ldelim();
                if (m.remoteAudioStatsMeasured != null && Object.hasOwnProperty.call(m, "remoteAudioStatsMeasured"))
                    $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured.encode(m.remoteAudioStatsMeasured, w.uint32(3242).fork()).ldelim();
                if (m.cameraSubjectChangeRequested != null && Object.hasOwnProperty.call(m, "cameraSubjectChangeRequested"))
                    $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested.encode(m.cameraSubjectChangeRequested, w.uint32(3250).fork()).ldelim();
                if (m.cameraSubjectChangeRejected != null && Object.hasOwnProperty.call(m, "cameraSubjectChangeRejected"))
                    $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected.encode(m.cameraSubjectChangeRejected, w.uint32(3258).fork()).ldelim();
                if (m.cameraSubjectSet != null && Object.hasOwnProperty.call(m, "cameraSubjectSet"))
                    $root.streem.api.AnalyticEvent.CameraSubjectSet.encode(m.cameraSubjectSet, w.uint32(3266).fork()).ldelim();
                if (m.cameraStatusSet != null && Object.hasOwnProperty.call(m, "cameraStatusSet"))
                    $root.streem.api.AnalyticEvent.CameraStatusSet.encode(m.cameraStatusSet, w.uint32(3274).fork()).ldelim();
                if (m.microphoneStatusSet != null && Object.hasOwnProperty.call(m, "microphoneStatusSet"))
                    $root.streem.api.AnalyticEvent.MicrophoneStatusSet.encode(m.microphoneStatusSet, w.uint32(3282).fork()).ldelim();
                if (m.toolPlaced != null && Object.hasOwnProperty.call(m, "toolPlaced"))
                    $root.streem.api.AnalyticEvent.ToolPlaced.encode(m.toolPlaced, w.uint32(4002).fork()).ldelim();
                if (m.toolMoved != null && Object.hasOwnProperty.call(m, "toolMoved"))
                    $root.streem.api.AnalyticEvent.ToolMoved.encode(m.toolMoved, w.uint32(4010).fork()).ldelim();
                if (m.toolRemoved != null && Object.hasOwnProperty.call(m, "toolRemoved"))
                    $root.streem.api.AnalyticEvent.ToolRemoved.encode(m.toolRemoved, w.uint32(4018).fork()).ldelim();
                if (m.artifactRequested != null && Object.hasOwnProperty.call(m, "artifactRequested"))
                    $root.streem.api.AnalyticEvent.ArtifactRequested.encode(m.artifactRequested, w.uint32(4802).fork()).ldelim();
                if (m.artifactRequestRejected != null && Object.hasOwnProperty.call(m, "artifactRequestRejected"))
                    $root.streem.api.AnalyticEvent.ArtifactRequestRejected.encode(m.artifactRequestRejected, w.uint32(4810).fork()).ldelim();
                if (m.artifactCaptured != null && Object.hasOwnProperty.call(m, "artifactCaptured"))
                    $root.streem.api.AnalyticEvent.ArtifactCaptured.encode(m.artifactCaptured, w.uint32(4818).fork()).ldelim();
                if (m.artifactFulfilled != null && Object.hasOwnProperty.call(m, "artifactFulfilled"))
                    $root.streem.api.AnalyticEvent.ArtifactFulfilled.encode(m.artifactFulfilled, w.uint32(4826).fork()).ldelim();
                if (m.artifactUpdated != null && Object.hasOwnProperty.call(m, "artifactUpdated"))
                    $root.streem.api.AnalyticEvent.ArtifactUpdated.encode(m.artifactUpdated, w.uint32(4834).fork()).ldelim();
                if (m.artifactDeleted != null && Object.hasOwnProperty.call(m, "artifactDeleted"))
                    $root.streem.api.AnalyticEvent.ArtifactDeleted.encode(m.artifactDeleted, w.uint32(4842).fork()).ldelim();
                if (m.artifactViewed != null && Object.hasOwnProperty.call(m, "artifactViewed"))
                    $root.streem.api.AnalyticEvent.ArtifactViewed.encode(m.artifactViewed, w.uint32(4850).fork()).ldelim();
                if (m.guidedExperiencePhaseUpdated != null && Object.hasOwnProperty.call(m, "guidedExperiencePhaseUpdated"))
                    $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.encode(m.guidedExperiencePhaseUpdated, w.uint32(4858).fork()).ldelim();
                if (m.favoriteUpdated != null && Object.hasOwnProperty.call(m, "favoriteUpdated"))
                    $root.streem.api.AnalyticEvent.FavoriteUpdated.encode(m.favoriteUpdated, w.uint32(4866).fork()).ldelim();
                if (m.noteSaved != null && Object.hasOwnProperty.call(m, "noteSaved"))
                    $root.streem.api.AnalyticEvent.NoteSaved.encode(m.noteSaved, w.uint32(5602).fork()).ldelim();
                if (m.noteDeleted != null && Object.hasOwnProperty.call(m, "noteDeleted"))
                    $root.streem.api.AnalyticEvent.NoteDeleted.encode(m.noteDeleted, w.uint32(5610).fork()).ldelim();
                if (m.workflowStarted != null && Object.hasOwnProperty.call(m, "workflowStarted"))
                    $root.streem.api.AnalyticEvent.WorkflowStarted.encode(m.workflowStarted, w.uint32(6402).fork()).ldelim();
                if (m.workflowAssociated != null && Object.hasOwnProperty.call(m, "workflowAssociated"))
                    $root.streem.api.AnalyticEvent.WorkflowAssociated.encode(m.workflowAssociated, w.uint32(6410).fork()).ldelim();
                if (m.cameraSubjectChangeAborted != null && Object.hasOwnProperty.call(m, "cameraSubjectChangeAborted"))
                    $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted.encode(m.cameraSubjectChangeAborted, w.uint32(6418).fork()).ldelim();
                if (m.vignetteElementSelected != null && Object.hasOwnProperty.call(m, "vignetteElementSelected"))
                    $root.streem.api.AnalyticEvent.VignetteElementSelected.encode(m.vignetteElementSelected, w.uint32(7202).fork()).ldelim();
                if (m.vignetteDrawerExpanded != null && Object.hasOwnProperty.call(m, "vignetteDrawerExpanded"))
                    $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.encode(m.vignetteDrawerExpanded, w.uint32(7210).fork()).ldelim();
                if (m.vignetteFinishChanged != null && Object.hasOwnProperty.call(m, "vignetteFinishChanged"))
                    $root.streem.api.AnalyticEvent.VignetteFinishChanged.encode(m.vignetteFinishChanged, w.uint32(7218).fork()).ldelim();
                if (m.vignettePortalVisited != null && Object.hasOwnProperty.call(m, "vignettePortalVisited"))
                    $root.streem.api.AnalyticEvent.VignettePortalVisited.encode(m.vignettePortalVisited, w.uint32(7226).fork()).ldelim();
                if (m.vignetteHotspotClicked != null && Object.hasOwnProperty.call(m, "vignetteHotspotClicked"))
                    $root.streem.api.AnalyticEvent.VignetteHotspotClicked.encode(m.vignetteHotspotClicked, w.uint32(7234).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AnalyticEvent message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AnalyticEvent} AnalyticEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnalyticEvent.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sentAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.receivedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.appPlatform = r.int32();
                            break;
                        }
                    case 4: {
                            m.sessionSid = r.string();
                            break;
                        }
                    case 5: {
                            m.userSid = r.string();
                            break;
                        }
                    case 6: {
                            m.deviceSid = r.string();
                            break;
                        }
                    case 7: {
                            m.appSid = r.string();
                            break;
                        }
                    case 8: {
                            m.region = r.int32();
                            break;
                        }
                    case 9: {
                            if (!(m.workflowSids && m.workflowSids.length))
                                m.workflowSids = [];
                            m.workflowSids.push(r.string());
                            break;
                        }
                    case 10: {
                            m.applicationName = r.string();
                            break;
                        }
                    case 100: {
                            m.screenViewed = $root.streem.api.AnalyticEvent.ScreenViewed.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.elementClicked = $root.streem.api.AnalyticEvent.ElementClicked.decode(r, r.uint32());
                            break;
                        }
                    case 102: {
                            m.appBackgrounded = $root.streem.api.AnalyticEvent.AppBackgrounded.decode(r, r.uint32());
                            break;
                        }
                    case 103: {
                            m.appForegrounded = $root.streem.api.AnalyticEvent.AppForegrounded.decode(r, r.uint32());
                            break;
                        }
                    case 104: {
                            m.permissionRequested = $root.streem.api.AnalyticEvent.PermissionRequested.decode(r, r.uint32());
                            break;
                        }
                    case 105: {
                            m.modalViewed = $root.streem.api.AnalyticEvent.ModalViewed.decode(r, r.uint32());
                            break;
                        }
                    case 106: {
                            m.tooltipViewed = $root.streem.api.AnalyticEvent.TooltipViewed.decode(r, r.uint32());
                            break;
                        }
                    case 200: {
                            m.loginAttempted = $root.streem.api.AnalyticEvent.LoginAttempted.decode(r, r.uint32());
                            break;
                        }
                    case 201: {
                            m.identifyAttempted = $root.streem.api.AnalyticEvent.IdentifyAttempted.decode(r, r.uint32());
                            break;
                        }
                    case 202: {
                            m.loggedOut = $root.streem.api.AnalyticEvent.LoggedOut.decode(r, r.uint32());
                            break;
                        }
                    case 300: {
                            m.inviteCreated = $root.streem.api.AnalyticEvent.InviteCreated.decode(r, r.uint32());
                            break;
                        }
                    case 301: {
                            m.inviteClicked = $root.streem.api.AnalyticEvent.InviteClicked.decode(r, r.uint32());
                            break;
                        }
                    case 400: {
                            m.connectionStatusChanged = $root.streem.api.AnalyticEvent.ConnectionStatusChanged.decode(r, r.uint32());
                            break;
                        }
                    case 401: {
                            m.resolutionChanged = $root.streem.api.AnalyticEvent.ResolutionChanged.decode(r, r.uint32());
                            break;
                        }
                    case 402: {
                            m.localVideoStatsMeasured = $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured.decode(r, r.uint32());
                            break;
                        }
                    case 403: {
                            m.localAudioStatsMeasured = $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured.decode(r, r.uint32());
                            break;
                        }
                    case 404: {
                            m.remoteVideoStatsMeasured = $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured.decode(r, r.uint32());
                            break;
                        }
                    case 405: {
                            m.remoteAudioStatsMeasured = $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured.decode(r, r.uint32());
                            break;
                        }
                    case 406: {
                            m.cameraSubjectChangeRequested = $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested.decode(r, r.uint32());
                            break;
                        }
                    case 407: {
                            m.cameraSubjectChangeRejected = $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected.decode(r, r.uint32());
                            break;
                        }
                    case 408: {
                            m.cameraSubjectSet = $root.streem.api.AnalyticEvent.CameraSubjectSet.decode(r, r.uint32());
                            break;
                        }
                    case 409: {
                            m.cameraStatusSet = $root.streem.api.AnalyticEvent.CameraStatusSet.decode(r, r.uint32());
                            break;
                        }
                    case 410: {
                            m.microphoneStatusSet = $root.streem.api.AnalyticEvent.MicrophoneStatusSet.decode(r, r.uint32());
                            break;
                        }
                    case 500: {
                            m.toolPlaced = $root.streem.api.AnalyticEvent.ToolPlaced.decode(r, r.uint32());
                            break;
                        }
                    case 501: {
                            m.toolMoved = $root.streem.api.AnalyticEvent.ToolMoved.decode(r, r.uint32());
                            break;
                        }
                    case 502: {
                            m.toolRemoved = $root.streem.api.AnalyticEvent.ToolRemoved.decode(r, r.uint32());
                            break;
                        }
                    case 600: {
                            m.artifactRequested = $root.streem.api.AnalyticEvent.ArtifactRequested.decode(r, r.uint32());
                            break;
                        }
                    case 601: {
                            m.artifactRequestRejected = $root.streem.api.AnalyticEvent.ArtifactRequestRejected.decode(r, r.uint32());
                            break;
                        }
                    case 602: {
                            m.artifactCaptured = $root.streem.api.AnalyticEvent.ArtifactCaptured.decode(r, r.uint32());
                            break;
                        }
                    case 603: {
                            m.artifactFulfilled = $root.streem.api.AnalyticEvent.ArtifactFulfilled.decode(r, r.uint32());
                            break;
                        }
                    case 604: {
                            m.artifactUpdated = $root.streem.api.AnalyticEvent.ArtifactUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 605: {
                            m.artifactDeleted = $root.streem.api.AnalyticEvent.ArtifactDeleted.decode(r, r.uint32());
                            break;
                        }
                    case 606: {
                            m.artifactViewed = $root.streem.api.AnalyticEvent.ArtifactViewed.decode(r, r.uint32());
                            break;
                        }
                    case 607: {
                            m.guidedExperiencePhaseUpdated = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 608: {
                            m.favoriteUpdated = $root.streem.api.AnalyticEvent.FavoriteUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 700: {
                            m.noteSaved = $root.streem.api.AnalyticEvent.NoteSaved.decode(r, r.uint32());
                            break;
                        }
                    case 701: {
                            m.noteDeleted = $root.streem.api.AnalyticEvent.NoteDeleted.decode(r, r.uint32());
                            break;
                        }
                    case 800: {
                            m.workflowStarted = $root.streem.api.AnalyticEvent.WorkflowStarted.decode(r, r.uint32());
                            break;
                        }
                    case 801: {
                            m.workflowAssociated = $root.streem.api.AnalyticEvent.WorkflowAssociated.decode(r, r.uint32());
                            break;
                        }
                    case 802: {
                            m.cameraSubjectChangeAborted = $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted.decode(r, r.uint32());
                            break;
                        }
                    case 900: {
                            m.vignetteElementSelected = $root.streem.api.AnalyticEvent.VignetteElementSelected.decode(r, r.uint32());
                            break;
                        }
                    case 901: {
                            m.vignetteDrawerExpanded = $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.decode(r, r.uint32());
                            break;
                        }
                    case 902: {
                            m.vignetteFinishChanged = $root.streem.api.AnalyticEvent.VignetteFinishChanged.decode(r, r.uint32());
                            break;
                        }
                    case 903: {
                            m.vignettePortalVisited = $root.streem.api.AnalyticEvent.VignettePortalVisited.decode(r, r.uint32());
                            break;
                        }
                    case 904: {
                            m.vignetteHotspotClicked = $root.streem.api.AnalyticEvent.VignetteHotspotClicked.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AnalyticEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AnalyticEvent} AnalyticEvent
             */
            AnalyticEvent.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AnalyticEvent)
                    return d;
                var m = new $root.streem.api.AnalyticEvent();
                if (d.sentAt != null) {
                    if (typeof d.sentAt !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.sentAt: object expected");
                    m.sentAt = $root.google.protobuf.Timestamp.fromObject(d.sentAt);
                }
                if (d.receivedAt != null) {
                    if (typeof d.receivedAt !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.receivedAt: object expected");
                    m.receivedAt = $root.google.protobuf.Timestamp.fromObject(d.receivedAt);
                }
                switch (d.appPlatform) {
                default:
                    if (typeof d.appPlatform === "number") {
                        m.appPlatform = d.appPlatform;
                        break;
                    }
                    break;
                case "APP_PLATFORM_INVALID":
                case 0:
                    m.appPlatform = 0;
                    break;
                case "APP_PLATFORM_IOS":
                case 1:
                    m.appPlatform = 1;
                    break;
                case "APP_PLATFORM_ANDROID":
                case 2:
                    m.appPlatform = 2;
                    break;
                case "APP_PLATFORM_WEB":
                case 3:
                    m.appPlatform = 3;
                    break;
                }
                if (d.sessionSid != null) {
                    m.sessionSid = String(d.sessionSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.deviceSid != null) {
                    m.deviceSid = String(d.deviceSid);
                }
                if (d.appSid != null) {
                    m.appSid = String(d.appSid);
                }
                switch (d.region) {
                default:
                    if (typeof d.region === "number") {
                        m.region = d.region;
                        break;
                    }
                    break;
                case "REGION_INVALID":
                case 0:
                    m.region = 0;
                    break;
                case "REGION_GLOBAL":
                case 1:
                    m.region = 1;
                    break;
                case "REGION_US":
                case 2:
                    m.region = 2;
                    break;
                case "REGION_CA":
                case 3:
                    m.region = 3;
                    break;
                }
                if (d.workflowSids) {
                    if (!Array.isArray(d.workflowSids))
                        throw TypeError(".streem.api.AnalyticEvent.workflowSids: array expected");
                    m.workflowSids = [];
                    for (var i = 0; i < d.workflowSids.length; ++i) {
                        m.workflowSids[i] = String(d.workflowSids[i]);
                    }
                }
                if (d.applicationName != null) {
                    m.applicationName = String(d.applicationName);
                }
                if (d.screenViewed != null) {
                    if (typeof d.screenViewed !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.screenViewed: object expected");
                    m.screenViewed = $root.streem.api.AnalyticEvent.ScreenViewed.fromObject(d.screenViewed);
                }
                if (d.elementClicked != null) {
                    if (typeof d.elementClicked !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.elementClicked: object expected");
                    m.elementClicked = $root.streem.api.AnalyticEvent.ElementClicked.fromObject(d.elementClicked);
                }
                if (d.appBackgrounded != null) {
                    if (typeof d.appBackgrounded !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.appBackgrounded: object expected");
                    m.appBackgrounded = $root.streem.api.AnalyticEvent.AppBackgrounded.fromObject(d.appBackgrounded);
                }
                if (d.appForegrounded != null) {
                    if (typeof d.appForegrounded !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.appForegrounded: object expected");
                    m.appForegrounded = $root.streem.api.AnalyticEvent.AppForegrounded.fromObject(d.appForegrounded);
                }
                if (d.permissionRequested != null) {
                    if (typeof d.permissionRequested !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.permissionRequested: object expected");
                    m.permissionRequested = $root.streem.api.AnalyticEvent.PermissionRequested.fromObject(d.permissionRequested);
                }
                if (d.modalViewed != null) {
                    if (typeof d.modalViewed !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.modalViewed: object expected");
                    m.modalViewed = $root.streem.api.AnalyticEvent.ModalViewed.fromObject(d.modalViewed);
                }
                if (d.tooltipViewed != null) {
                    if (typeof d.tooltipViewed !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.tooltipViewed: object expected");
                    m.tooltipViewed = $root.streem.api.AnalyticEvent.TooltipViewed.fromObject(d.tooltipViewed);
                }
                if (d.loginAttempted != null) {
                    if (typeof d.loginAttempted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.loginAttempted: object expected");
                    m.loginAttempted = $root.streem.api.AnalyticEvent.LoginAttempted.fromObject(d.loginAttempted);
                }
                if (d.identifyAttempted != null) {
                    if (typeof d.identifyAttempted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.identifyAttempted: object expected");
                    m.identifyAttempted = $root.streem.api.AnalyticEvent.IdentifyAttempted.fromObject(d.identifyAttempted);
                }
                if (d.loggedOut != null) {
                    if (typeof d.loggedOut !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.loggedOut: object expected");
                    m.loggedOut = $root.streem.api.AnalyticEvent.LoggedOut.fromObject(d.loggedOut);
                }
                if (d.inviteCreated != null) {
                    if (typeof d.inviteCreated !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.inviteCreated: object expected");
                    m.inviteCreated = $root.streem.api.AnalyticEvent.InviteCreated.fromObject(d.inviteCreated);
                }
                if (d.inviteClicked != null) {
                    if (typeof d.inviteClicked !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.inviteClicked: object expected");
                    m.inviteClicked = $root.streem.api.AnalyticEvent.InviteClicked.fromObject(d.inviteClicked);
                }
                if (d.connectionStatusChanged != null) {
                    if (typeof d.connectionStatusChanged !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.connectionStatusChanged: object expected");
                    m.connectionStatusChanged = $root.streem.api.AnalyticEvent.ConnectionStatusChanged.fromObject(d.connectionStatusChanged);
                }
                if (d.resolutionChanged != null) {
                    if (typeof d.resolutionChanged !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.resolutionChanged: object expected");
                    m.resolutionChanged = $root.streem.api.AnalyticEvent.ResolutionChanged.fromObject(d.resolutionChanged);
                }
                if (d.localVideoStatsMeasured != null) {
                    if (typeof d.localVideoStatsMeasured !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.localVideoStatsMeasured: object expected");
                    m.localVideoStatsMeasured = $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured.fromObject(d.localVideoStatsMeasured);
                }
                if (d.localAudioStatsMeasured != null) {
                    if (typeof d.localAudioStatsMeasured !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.localAudioStatsMeasured: object expected");
                    m.localAudioStatsMeasured = $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured.fromObject(d.localAudioStatsMeasured);
                }
                if (d.remoteVideoStatsMeasured != null) {
                    if (typeof d.remoteVideoStatsMeasured !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.remoteVideoStatsMeasured: object expected");
                    m.remoteVideoStatsMeasured = $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured.fromObject(d.remoteVideoStatsMeasured);
                }
                if (d.remoteAudioStatsMeasured != null) {
                    if (typeof d.remoteAudioStatsMeasured !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.remoteAudioStatsMeasured: object expected");
                    m.remoteAudioStatsMeasured = $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured.fromObject(d.remoteAudioStatsMeasured);
                }
                if (d.cameraSubjectChangeRequested != null) {
                    if (typeof d.cameraSubjectChangeRequested !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.cameraSubjectChangeRequested: object expected");
                    m.cameraSubjectChangeRequested = $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested.fromObject(d.cameraSubjectChangeRequested);
                }
                if (d.cameraSubjectChangeRejected != null) {
                    if (typeof d.cameraSubjectChangeRejected !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.cameraSubjectChangeRejected: object expected");
                    m.cameraSubjectChangeRejected = $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected.fromObject(d.cameraSubjectChangeRejected);
                }
                if (d.cameraSubjectSet != null) {
                    if (typeof d.cameraSubjectSet !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.cameraSubjectSet: object expected");
                    m.cameraSubjectSet = $root.streem.api.AnalyticEvent.CameraSubjectSet.fromObject(d.cameraSubjectSet);
                }
                if (d.cameraStatusSet != null) {
                    if (typeof d.cameraStatusSet !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.cameraStatusSet: object expected");
                    m.cameraStatusSet = $root.streem.api.AnalyticEvent.CameraStatusSet.fromObject(d.cameraStatusSet);
                }
                if (d.microphoneStatusSet != null) {
                    if (typeof d.microphoneStatusSet !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.microphoneStatusSet: object expected");
                    m.microphoneStatusSet = $root.streem.api.AnalyticEvent.MicrophoneStatusSet.fromObject(d.microphoneStatusSet);
                }
                if (d.toolPlaced != null) {
                    if (typeof d.toolPlaced !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.toolPlaced: object expected");
                    m.toolPlaced = $root.streem.api.AnalyticEvent.ToolPlaced.fromObject(d.toolPlaced);
                }
                if (d.toolMoved != null) {
                    if (typeof d.toolMoved !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.toolMoved: object expected");
                    m.toolMoved = $root.streem.api.AnalyticEvent.ToolMoved.fromObject(d.toolMoved);
                }
                if (d.toolRemoved != null) {
                    if (typeof d.toolRemoved !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.toolRemoved: object expected");
                    m.toolRemoved = $root.streem.api.AnalyticEvent.ToolRemoved.fromObject(d.toolRemoved);
                }
                if (d.artifactRequested != null) {
                    if (typeof d.artifactRequested !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactRequested: object expected");
                    m.artifactRequested = $root.streem.api.AnalyticEvent.ArtifactRequested.fromObject(d.artifactRequested);
                }
                if (d.artifactRequestRejected != null) {
                    if (typeof d.artifactRequestRejected !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactRequestRejected: object expected");
                    m.artifactRequestRejected = $root.streem.api.AnalyticEvent.ArtifactRequestRejected.fromObject(d.artifactRequestRejected);
                }
                if (d.artifactCaptured != null) {
                    if (typeof d.artifactCaptured !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactCaptured: object expected");
                    m.artifactCaptured = $root.streem.api.AnalyticEvent.ArtifactCaptured.fromObject(d.artifactCaptured);
                }
                if (d.artifactFulfilled != null) {
                    if (typeof d.artifactFulfilled !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactFulfilled: object expected");
                    m.artifactFulfilled = $root.streem.api.AnalyticEvent.ArtifactFulfilled.fromObject(d.artifactFulfilled);
                }
                if (d.artifactUpdated != null) {
                    if (typeof d.artifactUpdated !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactUpdated: object expected");
                    m.artifactUpdated = $root.streem.api.AnalyticEvent.ArtifactUpdated.fromObject(d.artifactUpdated);
                }
                if (d.artifactDeleted != null) {
                    if (typeof d.artifactDeleted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactDeleted: object expected");
                    m.artifactDeleted = $root.streem.api.AnalyticEvent.ArtifactDeleted.fromObject(d.artifactDeleted);
                }
                if (d.artifactViewed != null) {
                    if (typeof d.artifactViewed !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.artifactViewed: object expected");
                    m.artifactViewed = $root.streem.api.AnalyticEvent.ArtifactViewed.fromObject(d.artifactViewed);
                }
                if (d.guidedExperiencePhaseUpdated != null) {
                    if (typeof d.guidedExperiencePhaseUpdated !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.guidedExperiencePhaseUpdated: object expected");
                    m.guidedExperiencePhaseUpdated = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.fromObject(d.guidedExperiencePhaseUpdated);
                }
                if (d.favoriteUpdated != null) {
                    if (typeof d.favoriteUpdated !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.favoriteUpdated: object expected");
                    m.favoriteUpdated = $root.streem.api.AnalyticEvent.FavoriteUpdated.fromObject(d.favoriteUpdated);
                }
                if (d.noteSaved != null) {
                    if (typeof d.noteSaved !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.noteSaved: object expected");
                    m.noteSaved = $root.streem.api.AnalyticEvent.NoteSaved.fromObject(d.noteSaved);
                }
                if (d.noteDeleted != null) {
                    if (typeof d.noteDeleted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.noteDeleted: object expected");
                    m.noteDeleted = $root.streem.api.AnalyticEvent.NoteDeleted.fromObject(d.noteDeleted);
                }
                if (d.workflowStarted != null) {
                    if (typeof d.workflowStarted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.workflowStarted: object expected");
                    m.workflowStarted = $root.streem.api.AnalyticEvent.WorkflowStarted.fromObject(d.workflowStarted);
                }
                if (d.workflowAssociated != null) {
                    if (typeof d.workflowAssociated !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.workflowAssociated: object expected");
                    m.workflowAssociated = $root.streem.api.AnalyticEvent.WorkflowAssociated.fromObject(d.workflowAssociated);
                }
                if (d.cameraSubjectChangeAborted != null) {
                    if (typeof d.cameraSubjectChangeAborted !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.cameraSubjectChangeAborted: object expected");
                    m.cameraSubjectChangeAborted = $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted.fromObject(d.cameraSubjectChangeAborted);
                }
                if (d.vignetteElementSelected != null) {
                    if (typeof d.vignetteElementSelected !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.vignetteElementSelected: object expected");
                    m.vignetteElementSelected = $root.streem.api.AnalyticEvent.VignetteElementSelected.fromObject(d.vignetteElementSelected);
                }
                if (d.vignetteDrawerExpanded != null) {
                    if (typeof d.vignetteDrawerExpanded !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.vignetteDrawerExpanded: object expected");
                    m.vignetteDrawerExpanded = $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.fromObject(d.vignetteDrawerExpanded);
                }
                if (d.vignetteFinishChanged != null) {
                    if (typeof d.vignetteFinishChanged !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.vignetteFinishChanged: object expected");
                    m.vignetteFinishChanged = $root.streem.api.AnalyticEvent.VignetteFinishChanged.fromObject(d.vignetteFinishChanged);
                }
                if (d.vignettePortalVisited != null) {
                    if (typeof d.vignettePortalVisited !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.vignettePortalVisited: object expected");
                    m.vignettePortalVisited = $root.streem.api.AnalyticEvent.VignettePortalVisited.fromObject(d.vignettePortalVisited);
                }
                if (d.vignetteHotspotClicked != null) {
                    if (typeof d.vignetteHotspotClicked !== "object")
                        throw TypeError(".streem.api.AnalyticEvent.vignetteHotspotClicked: object expected");
                    m.vignetteHotspotClicked = $root.streem.api.AnalyticEvent.VignetteHotspotClicked.fromObject(d.vignetteHotspotClicked);
                }
                return m;
            };

            /**
             * Creates a plain object from an AnalyticEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {streem.api.AnalyticEvent} m AnalyticEvent
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AnalyticEvent.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.workflowSids = [];
                }
                if (o.defaults) {
                    d.sentAt = null;
                    d.receivedAt = null;
                    d.appPlatform = o.enums === String ? "APP_PLATFORM_INVALID" : 0;
                    d.sessionSid = "";
                    d.userSid = "";
                    d.deviceSid = "";
                    d.appSid = "";
                    d.region = o.enums === String ? "REGION_INVALID" : 0;
                    d.applicationName = "";
                }
                if (m.sentAt != null && m.hasOwnProperty("sentAt")) {
                    d.sentAt = $root.google.protobuf.Timestamp.toObject(m.sentAt, o);
                }
                if (m.receivedAt != null && m.hasOwnProperty("receivedAt")) {
                    d.receivedAt = $root.google.protobuf.Timestamp.toObject(m.receivedAt, o);
                }
                if (m.appPlatform != null && m.hasOwnProperty("appPlatform")) {
                    d.appPlatform = o.enums === String ? $root.streem.api.AppPlatform[m.appPlatform] === undefined ? m.appPlatform : $root.streem.api.AppPlatform[m.appPlatform] : m.appPlatform;
                }
                if (m.sessionSid != null && m.hasOwnProperty("sessionSid")) {
                    d.sessionSid = m.sessionSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                    d.deviceSid = m.deviceSid;
                }
                if (m.appSid != null && m.hasOwnProperty("appSid")) {
                    d.appSid = m.appSid;
                }
                if (m.region != null && m.hasOwnProperty("region")) {
                    d.region = o.enums === String ? $root.streem.api.AnalyticEvent.Region[m.region] === undefined ? m.region : $root.streem.api.AnalyticEvent.Region[m.region] : m.region;
                }
                if (m.workflowSids && m.workflowSids.length) {
                    d.workflowSids = [];
                    for (var j = 0; j < m.workflowSids.length; ++j) {
                        d.workflowSids[j] = m.workflowSids[j];
                    }
                }
                if (m.applicationName != null && m.hasOwnProperty("applicationName")) {
                    d.applicationName = m.applicationName;
                }
                if (m.screenViewed != null && m.hasOwnProperty("screenViewed")) {
                    d.screenViewed = $root.streem.api.AnalyticEvent.ScreenViewed.toObject(m.screenViewed, o);
                    if (o.oneofs)
                        d.type = "screenViewed";
                }
                if (m.elementClicked != null && m.hasOwnProperty("elementClicked")) {
                    d.elementClicked = $root.streem.api.AnalyticEvent.ElementClicked.toObject(m.elementClicked, o);
                    if (o.oneofs)
                        d.type = "elementClicked";
                }
                if (m.appBackgrounded != null && m.hasOwnProperty("appBackgrounded")) {
                    d.appBackgrounded = $root.streem.api.AnalyticEvent.AppBackgrounded.toObject(m.appBackgrounded, o);
                    if (o.oneofs)
                        d.type = "appBackgrounded";
                }
                if (m.appForegrounded != null && m.hasOwnProperty("appForegrounded")) {
                    d.appForegrounded = $root.streem.api.AnalyticEvent.AppForegrounded.toObject(m.appForegrounded, o);
                    if (o.oneofs)
                        d.type = "appForegrounded";
                }
                if (m.permissionRequested != null && m.hasOwnProperty("permissionRequested")) {
                    d.permissionRequested = $root.streem.api.AnalyticEvent.PermissionRequested.toObject(m.permissionRequested, o);
                    if (o.oneofs)
                        d.type = "permissionRequested";
                }
                if (m.modalViewed != null && m.hasOwnProperty("modalViewed")) {
                    d.modalViewed = $root.streem.api.AnalyticEvent.ModalViewed.toObject(m.modalViewed, o);
                    if (o.oneofs)
                        d.type = "modalViewed";
                }
                if (m.tooltipViewed != null && m.hasOwnProperty("tooltipViewed")) {
                    d.tooltipViewed = $root.streem.api.AnalyticEvent.TooltipViewed.toObject(m.tooltipViewed, o);
                    if (o.oneofs)
                        d.type = "tooltipViewed";
                }
                if (m.loginAttempted != null && m.hasOwnProperty("loginAttempted")) {
                    d.loginAttempted = $root.streem.api.AnalyticEvent.LoginAttempted.toObject(m.loginAttempted, o);
                    if (o.oneofs)
                        d.type = "loginAttempted";
                }
                if (m.identifyAttempted != null && m.hasOwnProperty("identifyAttempted")) {
                    d.identifyAttempted = $root.streem.api.AnalyticEvent.IdentifyAttempted.toObject(m.identifyAttempted, o);
                    if (o.oneofs)
                        d.type = "identifyAttempted";
                }
                if (m.loggedOut != null && m.hasOwnProperty("loggedOut")) {
                    d.loggedOut = $root.streem.api.AnalyticEvent.LoggedOut.toObject(m.loggedOut, o);
                    if (o.oneofs)
                        d.type = "loggedOut";
                }
                if (m.inviteCreated != null && m.hasOwnProperty("inviteCreated")) {
                    d.inviteCreated = $root.streem.api.AnalyticEvent.InviteCreated.toObject(m.inviteCreated, o);
                    if (o.oneofs)
                        d.type = "inviteCreated";
                }
                if (m.inviteClicked != null && m.hasOwnProperty("inviteClicked")) {
                    d.inviteClicked = $root.streem.api.AnalyticEvent.InviteClicked.toObject(m.inviteClicked, o);
                    if (o.oneofs)
                        d.type = "inviteClicked";
                }
                if (m.connectionStatusChanged != null && m.hasOwnProperty("connectionStatusChanged")) {
                    d.connectionStatusChanged = $root.streem.api.AnalyticEvent.ConnectionStatusChanged.toObject(m.connectionStatusChanged, o);
                    if (o.oneofs)
                        d.type = "connectionStatusChanged";
                }
                if (m.resolutionChanged != null && m.hasOwnProperty("resolutionChanged")) {
                    d.resolutionChanged = $root.streem.api.AnalyticEvent.ResolutionChanged.toObject(m.resolutionChanged, o);
                    if (o.oneofs)
                        d.type = "resolutionChanged";
                }
                if (m.localVideoStatsMeasured != null && m.hasOwnProperty("localVideoStatsMeasured")) {
                    d.localVideoStatsMeasured = $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured.toObject(m.localVideoStatsMeasured, o);
                    if (o.oneofs)
                        d.type = "localVideoStatsMeasured";
                }
                if (m.localAudioStatsMeasured != null && m.hasOwnProperty("localAudioStatsMeasured")) {
                    d.localAudioStatsMeasured = $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured.toObject(m.localAudioStatsMeasured, o);
                    if (o.oneofs)
                        d.type = "localAudioStatsMeasured";
                }
                if (m.remoteVideoStatsMeasured != null && m.hasOwnProperty("remoteVideoStatsMeasured")) {
                    d.remoteVideoStatsMeasured = $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured.toObject(m.remoteVideoStatsMeasured, o);
                    if (o.oneofs)
                        d.type = "remoteVideoStatsMeasured";
                }
                if (m.remoteAudioStatsMeasured != null && m.hasOwnProperty("remoteAudioStatsMeasured")) {
                    d.remoteAudioStatsMeasured = $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured.toObject(m.remoteAudioStatsMeasured, o);
                    if (o.oneofs)
                        d.type = "remoteAudioStatsMeasured";
                }
                if (m.cameraSubjectChangeRequested != null && m.hasOwnProperty("cameraSubjectChangeRequested")) {
                    d.cameraSubjectChangeRequested = $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested.toObject(m.cameraSubjectChangeRequested, o);
                    if (o.oneofs)
                        d.type = "cameraSubjectChangeRequested";
                }
                if (m.cameraSubjectChangeRejected != null && m.hasOwnProperty("cameraSubjectChangeRejected")) {
                    d.cameraSubjectChangeRejected = $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected.toObject(m.cameraSubjectChangeRejected, o);
                    if (o.oneofs)
                        d.type = "cameraSubjectChangeRejected";
                }
                if (m.cameraSubjectSet != null && m.hasOwnProperty("cameraSubjectSet")) {
                    d.cameraSubjectSet = $root.streem.api.AnalyticEvent.CameraSubjectSet.toObject(m.cameraSubjectSet, o);
                    if (o.oneofs)
                        d.type = "cameraSubjectSet";
                }
                if (m.cameraStatusSet != null && m.hasOwnProperty("cameraStatusSet")) {
                    d.cameraStatusSet = $root.streem.api.AnalyticEvent.CameraStatusSet.toObject(m.cameraStatusSet, o);
                    if (o.oneofs)
                        d.type = "cameraStatusSet";
                }
                if (m.microphoneStatusSet != null && m.hasOwnProperty("microphoneStatusSet")) {
                    d.microphoneStatusSet = $root.streem.api.AnalyticEvent.MicrophoneStatusSet.toObject(m.microphoneStatusSet, o);
                    if (o.oneofs)
                        d.type = "microphoneStatusSet";
                }
                if (m.toolPlaced != null && m.hasOwnProperty("toolPlaced")) {
                    d.toolPlaced = $root.streem.api.AnalyticEvent.ToolPlaced.toObject(m.toolPlaced, o);
                    if (o.oneofs)
                        d.type = "toolPlaced";
                }
                if (m.toolMoved != null && m.hasOwnProperty("toolMoved")) {
                    d.toolMoved = $root.streem.api.AnalyticEvent.ToolMoved.toObject(m.toolMoved, o);
                    if (o.oneofs)
                        d.type = "toolMoved";
                }
                if (m.toolRemoved != null && m.hasOwnProperty("toolRemoved")) {
                    d.toolRemoved = $root.streem.api.AnalyticEvent.ToolRemoved.toObject(m.toolRemoved, o);
                    if (o.oneofs)
                        d.type = "toolRemoved";
                }
                if (m.artifactRequested != null && m.hasOwnProperty("artifactRequested")) {
                    d.artifactRequested = $root.streem.api.AnalyticEvent.ArtifactRequested.toObject(m.artifactRequested, o);
                    if (o.oneofs)
                        d.type = "artifactRequested";
                }
                if (m.artifactRequestRejected != null && m.hasOwnProperty("artifactRequestRejected")) {
                    d.artifactRequestRejected = $root.streem.api.AnalyticEvent.ArtifactRequestRejected.toObject(m.artifactRequestRejected, o);
                    if (o.oneofs)
                        d.type = "artifactRequestRejected";
                }
                if (m.artifactCaptured != null && m.hasOwnProperty("artifactCaptured")) {
                    d.artifactCaptured = $root.streem.api.AnalyticEvent.ArtifactCaptured.toObject(m.artifactCaptured, o);
                    if (o.oneofs)
                        d.type = "artifactCaptured";
                }
                if (m.artifactFulfilled != null && m.hasOwnProperty("artifactFulfilled")) {
                    d.artifactFulfilled = $root.streem.api.AnalyticEvent.ArtifactFulfilled.toObject(m.artifactFulfilled, o);
                    if (o.oneofs)
                        d.type = "artifactFulfilled";
                }
                if (m.artifactUpdated != null && m.hasOwnProperty("artifactUpdated")) {
                    d.artifactUpdated = $root.streem.api.AnalyticEvent.ArtifactUpdated.toObject(m.artifactUpdated, o);
                    if (o.oneofs)
                        d.type = "artifactUpdated";
                }
                if (m.artifactDeleted != null && m.hasOwnProperty("artifactDeleted")) {
                    d.artifactDeleted = $root.streem.api.AnalyticEvent.ArtifactDeleted.toObject(m.artifactDeleted, o);
                    if (o.oneofs)
                        d.type = "artifactDeleted";
                }
                if (m.artifactViewed != null && m.hasOwnProperty("artifactViewed")) {
                    d.artifactViewed = $root.streem.api.AnalyticEvent.ArtifactViewed.toObject(m.artifactViewed, o);
                    if (o.oneofs)
                        d.type = "artifactViewed";
                }
                if (m.guidedExperiencePhaseUpdated != null && m.hasOwnProperty("guidedExperiencePhaseUpdated")) {
                    d.guidedExperiencePhaseUpdated = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.toObject(m.guidedExperiencePhaseUpdated, o);
                    if (o.oneofs)
                        d.type = "guidedExperiencePhaseUpdated";
                }
                if (m.favoriteUpdated != null && m.hasOwnProperty("favoriteUpdated")) {
                    d.favoriteUpdated = $root.streem.api.AnalyticEvent.FavoriteUpdated.toObject(m.favoriteUpdated, o);
                    if (o.oneofs)
                        d.type = "favoriteUpdated";
                }
                if (m.noteSaved != null && m.hasOwnProperty("noteSaved")) {
                    d.noteSaved = $root.streem.api.AnalyticEvent.NoteSaved.toObject(m.noteSaved, o);
                    if (o.oneofs)
                        d.type = "noteSaved";
                }
                if (m.noteDeleted != null && m.hasOwnProperty("noteDeleted")) {
                    d.noteDeleted = $root.streem.api.AnalyticEvent.NoteDeleted.toObject(m.noteDeleted, o);
                    if (o.oneofs)
                        d.type = "noteDeleted";
                }
                if (m.workflowStarted != null && m.hasOwnProperty("workflowStarted")) {
                    d.workflowStarted = $root.streem.api.AnalyticEvent.WorkflowStarted.toObject(m.workflowStarted, o);
                    if (o.oneofs)
                        d.type = "workflowStarted";
                }
                if (m.workflowAssociated != null && m.hasOwnProperty("workflowAssociated")) {
                    d.workflowAssociated = $root.streem.api.AnalyticEvent.WorkflowAssociated.toObject(m.workflowAssociated, o);
                    if (o.oneofs)
                        d.type = "workflowAssociated";
                }
                if (m.cameraSubjectChangeAborted != null && m.hasOwnProperty("cameraSubjectChangeAborted")) {
                    d.cameraSubjectChangeAborted = $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted.toObject(m.cameraSubjectChangeAborted, o);
                    if (o.oneofs)
                        d.type = "cameraSubjectChangeAborted";
                }
                if (m.vignetteElementSelected != null && m.hasOwnProperty("vignetteElementSelected")) {
                    d.vignetteElementSelected = $root.streem.api.AnalyticEvent.VignetteElementSelected.toObject(m.vignetteElementSelected, o);
                    if (o.oneofs)
                        d.type = "vignetteElementSelected";
                }
                if (m.vignetteDrawerExpanded != null && m.hasOwnProperty("vignetteDrawerExpanded")) {
                    d.vignetteDrawerExpanded = $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.toObject(m.vignetteDrawerExpanded, o);
                    if (o.oneofs)
                        d.type = "vignetteDrawerExpanded";
                }
                if (m.vignetteFinishChanged != null && m.hasOwnProperty("vignetteFinishChanged")) {
                    d.vignetteFinishChanged = $root.streem.api.AnalyticEvent.VignetteFinishChanged.toObject(m.vignetteFinishChanged, o);
                    if (o.oneofs)
                        d.type = "vignetteFinishChanged";
                }
                if (m.vignettePortalVisited != null && m.hasOwnProperty("vignettePortalVisited")) {
                    d.vignettePortalVisited = $root.streem.api.AnalyticEvent.VignettePortalVisited.toObject(m.vignettePortalVisited, o);
                    if (o.oneofs)
                        d.type = "vignettePortalVisited";
                }
                if (m.vignetteHotspotClicked != null && m.hasOwnProperty("vignetteHotspotClicked")) {
                    d.vignetteHotspotClicked = $root.streem.api.AnalyticEvent.VignetteHotspotClicked.toObject(m.vignetteHotspotClicked, o);
                    if (o.oneofs)
                        d.type = "vignetteHotspotClicked";
                }
                return d;
            };

            /**
             * Converts this AnalyticEvent to JSON.
             * @function toJSON
             * @memberof streem.api.AnalyticEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AnalyticEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AnalyticEvent
             * @function getTypeUrl
             * @memberof streem.api.AnalyticEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AnalyticEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AnalyticEvent";
            };

            /**
             * Region enum.
             * @name streem.api.AnalyticEvent.Region
             * @enum {number}
             * @property {number} REGION_INVALID=0 REGION_INVALID value
             * @property {number} REGION_GLOBAL=1 REGION_GLOBAL value
             * @property {number} REGION_US=2 REGION_US value
             * @property {number} REGION_CA=3 REGION_CA value
             */
            AnalyticEvent.Region = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "REGION_INVALID"] = 0;
                values[valuesById[1] = "REGION_GLOBAL"] = 1;
                values[valuesById[2] = "REGION_US"] = 2;
                values[valuesById[3] = "REGION_CA"] = 3;
                return values;
            })();

            AnalyticEvent.FavoriteUpdated = (function() {

                /**
                 * Properties of a FavoriteUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IFavoriteUpdated
                 * @property {boolean|null} [status] FavoriteUpdated status
                 */

                /**
                 * Constructs a new FavoriteUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a FavoriteUpdated.
                 * @implements IFavoriteUpdated
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IFavoriteUpdated=} [p] Properties to set
                 */
                function FavoriteUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * FavoriteUpdated status.
                 * @member {boolean} status
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @instance
                 */
                FavoriteUpdated.prototype.status = false;

                /**
                 * Creates a new FavoriteUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IFavoriteUpdated=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.FavoriteUpdated} FavoriteUpdated instance
                 */
                FavoriteUpdated.create = function create(properties) {
                    return new FavoriteUpdated(properties);
                };

                /**
                 * Encodes the specified FavoriteUpdated message. Does not implicitly {@link streem.api.AnalyticEvent.FavoriteUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IFavoriteUpdated} m FavoriteUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FavoriteUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(8).bool(m.status);
                    return w;
                };

                /**
                 * Decodes a FavoriteUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.FavoriteUpdated} FavoriteUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FavoriteUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.FavoriteUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.status = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a FavoriteUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.FavoriteUpdated} FavoriteUpdated
                 */
                FavoriteUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.FavoriteUpdated)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.FavoriteUpdated();
                    if (d.status != null) {
                        m.status = Boolean(d.status);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a FavoriteUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.FavoriteUpdated} m FavoriteUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FavoriteUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.status = false;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = m.status;
                    }
                    return d;
                };

                /**
                 * Converts this FavoriteUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FavoriteUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for FavoriteUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.FavoriteUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FavoriteUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.FavoriteUpdated";
                };

                return FavoriteUpdated;
            })();

            AnalyticEvent.WorkflowStarted = (function() {

                /**
                 * Properties of a WorkflowStarted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IWorkflowStarted
                 * @property {string|null} [workflowSid] WorkflowStarted workflowSid
                 * @property {string|null} [type] WorkflowStarted type
                 */

                /**
                 * Constructs a new WorkflowStarted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a WorkflowStarted.
                 * @implements IWorkflowStarted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IWorkflowStarted=} [p] Properties to set
                 */
                function WorkflowStarted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * WorkflowStarted workflowSid.
                 * @member {string} workflowSid
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @instance
                 */
                WorkflowStarted.prototype.workflowSid = "";

                /**
                 * WorkflowStarted type.
                 * @member {string} type
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @instance
                 */
                WorkflowStarted.prototype.type = "";

                /**
                 * Creates a new WorkflowStarted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {streem.api.AnalyticEvent.IWorkflowStarted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.WorkflowStarted} WorkflowStarted instance
                 */
                WorkflowStarted.create = function create(properties) {
                    return new WorkflowStarted(properties);
                };

                /**
                 * Encodes the specified WorkflowStarted message. Does not implicitly {@link streem.api.AnalyticEvent.WorkflowStarted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {streem.api.AnalyticEvent.IWorkflowStarted} m WorkflowStarted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WorkflowStarted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.workflowSid != null && Object.hasOwnProperty.call(m, "workflowSid"))
                        w.uint32(10).string(m.workflowSid);
                    if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                        w.uint32(18).string(m.type);
                    return w;
                };

                /**
                 * Decodes a WorkflowStarted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.WorkflowStarted} WorkflowStarted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WorkflowStarted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.WorkflowStarted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.workflowSid = r.string();
                                break;
                            }
                        case 2: {
                                m.type = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a WorkflowStarted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.WorkflowStarted} WorkflowStarted
                 */
                WorkflowStarted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.WorkflowStarted)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.WorkflowStarted();
                    if (d.workflowSid != null) {
                        m.workflowSid = String(d.workflowSid);
                    }
                    if (d.type != null) {
                        m.type = String(d.type);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a WorkflowStarted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {streem.api.AnalyticEvent.WorkflowStarted} m WorkflowStarted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkflowStarted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.workflowSid = "";
                        d.type = "";
                    }
                    if (m.workflowSid != null && m.hasOwnProperty("workflowSid")) {
                        d.workflowSid = m.workflowSid;
                    }
                    if (m.type != null && m.hasOwnProperty("type")) {
                        d.type = m.type;
                    }
                    return d;
                };

                /**
                 * Converts this WorkflowStarted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkflowStarted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WorkflowStarted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.WorkflowStarted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WorkflowStarted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.WorkflowStarted";
                };

                return WorkflowStarted;
            })();

            AnalyticEvent.WorkflowAssociated = (function() {

                /**
                 * Properties of a WorkflowAssociated.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IWorkflowAssociated
                 * @property {string|null} [workflowSid] WorkflowAssociated workflowSid
                 * @property {string|null} [associationSid] WorkflowAssociated associationSid
                 * @property {streem.api.AnalyticEvent.WorkflowAssociated.AssociationType|null} [associationType] WorkflowAssociated associationType
                 */

                /**
                 * Constructs a new WorkflowAssociated.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a WorkflowAssociated.
                 * @implements IWorkflowAssociated
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IWorkflowAssociated=} [p] Properties to set
                 */
                function WorkflowAssociated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * WorkflowAssociated workflowSid.
                 * @member {string} workflowSid
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @instance
                 */
                WorkflowAssociated.prototype.workflowSid = "";

                /**
                 * WorkflowAssociated associationSid.
                 * @member {string} associationSid
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @instance
                 */
                WorkflowAssociated.prototype.associationSid = "";

                /**
                 * WorkflowAssociated associationType.
                 * @member {streem.api.AnalyticEvent.WorkflowAssociated.AssociationType} associationType
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @instance
                 */
                WorkflowAssociated.prototype.associationType = 0;

                /**
                 * Creates a new WorkflowAssociated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {streem.api.AnalyticEvent.IWorkflowAssociated=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.WorkflowAssociated} WorkflowAssociated instance
                 */
                WorkflowAssociated.create = function create(properties) {
                    return new WorkflowAssociated(properties);
                };

                /**
                 * Encodes the specified WorkflowAssociated message. Does not implicitly {@link streem.api.AnalyticEvent.WorkflowAssociated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {streem.api.AnalyticEvent.IWorkflowAssociated} m WorkflowAssociated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WorkflowAssociated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.workflowSid != null && Object.hasOwnProperty.call(m, "workflowSid"))
                        w.uint32(10).string(m.workflowSid);
                    if (m.associationSid != null && Object.hasOwnProperty.call(m, "associationSid"))
                        w.uint32(18).string(m.associationSid);
                    if (m.associationType != null && Object.hasOwnProperty.call(m, "associationType"))
                        w.uint32(24).int32(m.associationType);
                    return w;
                };

                /**
                 * Decodes a WorkflowAssociated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.WorkflowAssociated} WorkflowAssociated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WorkflowAssociated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.WorkflowAssociated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.workflowSid = r.string();
                                break;
                            }
                        case 2: {
                                m.associationSid = r.string();
                                break;
                            }
                        case 3: {
                                m.associationType = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a WorkflowAssociated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.WorkflowAssociated} WorkflowAssociated
                 */
                WorkflowAssociated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.WorkflowAssociated)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.WorkflowAssociated();
                    if (d.workflowSid != null) {
                        m.workflowSid = String(d.workflowSid);
                    }
                    if (d.associationSid != null) {
                        m.associationSid = String(d.associationSid);
                    }
                    switch (d.associationType) {
                    default:
                        if (typeof d.associationType === "number") {
                            m.associationType = d.associationType;
                            break;
                        }
                        break;
                    case "ASSOCIATION_TYPE_INVALID":
                    case 0:
                        m.associationType = 0;
                        break;
                    case "ASSOCIATION_TYPE_ROOM":
                    case 1:
                        m.associationType = 1;
                        break;
                    case "ASSOCIATION_TYPE_INVITE":
                    case 2:
                        m.associationType = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a WorkflowAssociated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {streem.api.AnalyticEvent.WorkflowAssociated} m WorkflowAssociated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkflowAssociated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.workflowSid = "";
                        d.associationSid = "";
                        d.associationType = o.enums === String ? "ASSOCIATION_TYPE_INVALID" : 0;
                    }
                    if (m.workflowSid != null && m.hasOwnProperty("workflowSid")) {
                        d.workflowSid = m.workflowSid;
                    }
                    if (m.associationSid != null && m.hasOwnProperty("associationSid")) {
                        d.associationSid = m.associationSid;
                    }
                    if (m.associationType != null && m.hasOwnProperty("associationType")) {
                        d.associationType = o.enums === String ? $root.streem.api.AnalyticEvent.WorkflowAssociated.AssociationType[m.associationType] === undefined ? m.associationType : $root.streem.api.AnalyticEvent.WorkflowAssociated.AssociationType[m.associationType] : m.associationType;
                    }
                    return d;
                };

                /**
                 * Converts this WorkflowAssociated to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkflowAssociated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WorkflowAssociated
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.WorkflowAssociated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WorkflowAssociated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.WorkflowAssociated";
                };

                /**
                 * AssociationType enum.
                 * @name streem.api.AnalyticEvent.WorkflowAssociated.AssociationType
                 * @enum {number}
                 * @property {number} ASSOCIATION_TYPE_INVALID=0 ASSOCIATION_TYPE_INVALID value
                 * @property {number} ASSOCIATION_TYPE_ROOM=1 ASSOCIATION_TYPE_ROOM value
                 * @property {number} ASSOCIATION_TYPE_INVITE=2 ASSOCIATION_TYPE_INVITE value
                 */
                WorkflowAssociated.AssociationType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ASSOCIATION_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "ASSOCIATION_TYPE_ROOM"] = 1;
                    values[valuesById[2] = "ASSOCIATION_TYPE_INVITE"] = 2;
                    return values;
                })();

                return WorkflowAssociated;
            })();

            AnalyticEvent.ScreenViewed = (function() {

                /**
                 * Properties of a ScreenViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IScreenViewed
                 * @property {string|null} [screenId] ScreenViewed screenId
                 */

                /**
                 * Constructs a new ScreenViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ScreenViewed.
                 * @implements IScreenViewed
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IScreenViewed=} [p] Properties to set
                 */
                function ScreenViewed(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ScreenViewed screenId.
                 * @member {string} screenId
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @instance
                 */
                ScreenViewed.prototype.screenId = "";

                /**
                 * Creates a new ScreenViewed instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IScreenViewed=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ScreenViewed} ScreenViewed instance
                 */
                ScreenViewed.create = function create(properties) {
                    return new ScreenViewed(properties);
                };

                /**
                 * Encodes the specified ScreenViewed message. Does not implicitly {@link streem.api.AnalyticEvent.ScreenViewed.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IScreenViewed} m ScreenViewed message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ScreenViewed.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.screenId != null && Object.hasOwnProperty.call(m, "screenId"))
                        w.uint32(10).string(m.screenId);
                    return w;
                };

                /**
                 * Decodes a ScreenViewed message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ScreenViewed} ScreenViewed
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ScreenViewed.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ScreenViewed();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.screenId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ScreenViewed message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ScreenViewed} ScreenViewed
                 */
                ScreenViewed.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ScreenViewed)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ScreenViewed();
                    if (d.screenId != null) {
                        m.screenId = String(d.screenId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ScreenViewed message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.ScreenViewed} m ScreenViewed
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ScreenViewed.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.screenId = "";
                    }
                    if (m.screenId != null && m.hasOwnProperty("screenId")) {
                        d.screenId = m.screenId;
                    }
                    return d;
                };

                /**
                 * Converts this ScreenViewed to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ScreenViewed.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ScreenViewed
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ScreenViewed
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ScreenViewed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ScreenViewed";
                };

                return ScreenViewed;
            })();

            AnalyticEvent.ElementClicked = (function() {

                /**
                 * Properties of an ElementClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IElementClicked
                 * @property {string|null} [elementId] ElementClicked elementId
                 */

                /**
                 * Constructs a new ElementClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ElementClicked.
                 * @implements IElementClicked
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IElementClicked=} [p] Properties to set
                 */
                function ElementClicked(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ElementClicked elementId.
                 * @member {string} elementId
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @instance
                 */
                ElementClicked.prototype.elementId = "";

                /**
                 * Creates a new ElementClicked instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IElementClicked=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ElementClicked} ElementClicked instance
                 */
                ElementClicked.create = function create(properties) {
                    return new ElementClicked(properties);
                };

                /**
                 * Encodes the specified ElementClicked message. Does not implicitly {@link streem.api.AnalyticEvent.ElementClicked.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IElementClicked} m ElementClicked message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElementClicked.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                        w.uint32(10).string(m.elementId);
                    return w;
                };

                /**
                 * Decodes an ElementClicked message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ElementClicked} ElementClicked
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElementClicked.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ElementClicked();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.elementId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ElementClicked message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ElementClicked} ElementClicked
                 */
                ElementClicked.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ElementClicked)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ElementClicked();
                    if (d.elementId != null) {
                        m.elementId = String(d.elementId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ElementClicked message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.ElementClicked} m ElementClicked
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ElementClicked.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.elementId = "";
                    }
                    if (m.elementId != null && m.hasOwnProperty("elementId")) {
                        d.elementId = m.elementId;
                    }
                    return d;
                };

                /**
                 * Converts this ElementClicked to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ElementClicked.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ElementClicked
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ElementClicked
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ElementClicked.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ElementClicked";
                };

                return ElementClicked;
            })();

            AnalyticEvent.ModalViewed = (function() {

                /**
                 * Properties of a ModalViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IModalViewed
                 * @property {string|null} [modalId] ModalViewed modalId
                 */

                /**
                 * Constructs a new ModalViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ModalViewed.
                 * @implements IModalViewed
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IModalViewed=} [p] Properties to set
                 */
                function ModalViewed(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ModalViewed modalId.
                 * @member {string} modalId
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @instance
                 */
                ModalViewed.prototype.modalId = "";

                /**
                 * Creates a new ModalViewed instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IModalViewed=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ModalViewed} ModalViewed instance
                 */
                ModalViewed.create = function create(properties) {
                    return new ModalViewed(properties);
                };

                /**
                 * Encodes the specified ModalViewed message. Does not implicitly {@link streem.api.AnalyticEvent.ModalViewed.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IModalViewed} m ModalViewed message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModalViewed.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.modalId != null && Object.hasOwnProperty.call(m, "modalId"))
                        w.uint32(10).string(m.modalId);
                    return w;
                };

                /**
                 * Decodes a ModalViewed message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ModalViewed} ModalViewed
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModalViewed.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ModalViewed();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.modalId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ModalViewed message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ModalViewed} ModalViewed
                 */
                ModalViewed.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ModalViewed)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ModalViewed();
                    if (d.modalId != null) {
                        m.modalId = String(d.modalId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ModalViewed message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.ModalViewed} m ModalViewed
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ModalViewed.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.modalId = "";
                    }
                    if (m.modalId != null && m.hasOwnProperty("modalId")) {
                        d.modalId = m.modalId;
                    }
                    return d;
                };

                /**
                 * Converts this ModalViewed to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ModalViewed.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ModalViewed
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ModalViewed
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ModalViewed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ModalViewed";
                };

                return ModalViewed;
            })();

            AnalyticEvent.TooltipViewed = (function() {

                /**
                 * Properties of a TooltipViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ITooltipViewed
                 * @property {string|null} [tooltipId] TooltipViewed tooltipId
                 */

                /**
                 * Constructs a new TooltipViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a TooltipViewed.
                 * @implements ITooltipViewed
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ITooltipViewed=} [p] Properties to set
                 */
                function TooltipViewed(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * TooltipViewed tooltipId.
                 * @member {string} tooltipId
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @instance
                 */
                TooltipViewed.prototype.tooltipId = "";

                /**
                 * Creates a new TooltipViewed instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.ITooltipViewed=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.TooltipViewed} TooltipViewed instance
                 */
                TooltipViewed.create = function create(properties) {
                    return new TooltipViewed(properties);
                };

                /**
                 * Encodes the specified TooltipViewed message. Does not implicitly {@link streem.api.AnalyticEvent.TooltipViewed.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.ITooltipViewed} m TooltipViewed message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TooltipViewed.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.tooltipId != null && Object.hasOwnProperty.call(m, "tooltipId"))
                        w.uint32(10).string(m.tooltipId);
                    return w;
                };

                /**
                 * Decodes a TooltipViewed message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.TooltipViewed} TooltipViewed
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TooltipViewed.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.TooltipViewed();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.tooltipId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a TooltipViewed message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.TooltipViewed} TooltipViewed
                 */
                TooltipViewed.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.TooltipViewed)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.TooltipViewed();
                    if (d.tooltipId != null) {
                        m.tooltipId = String(d.tooltipId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a TooltipViewed message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.TooltipViewed} m TooltipViewed
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TooltipViewed.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.tooltipId = "";
                    }
                    if (m.tooltipId != null && m.hasOwnProperty("tooltipId")) {
                        d.tooltipId = m.tooltipId;
                    }
                    return d;
                };

                /**
                 * Converts this TooltipViewed to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TooltipViewed.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TooltipViewed
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.TooltipViewed
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TooltipViewed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.TooltipViewed";
                };

                return TooltipViewed;
            })();

            AnalyticEvent.PermissionRequested = (function() {

                /**
                 * Properties of a PermissionRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IPermissionRequested
                 * @property {streem.api.AnalyticEvent.PermissionRequested.Permission|null} [permission] PermissionRequested permission
                 * @property {boolean|null} [granted] PermissionRequested granted
                 * @property {string|null} [inviteSid] PermissionRequested inviteSid
                 * @property {string|null} [groupReservationSid] PermissionRequested groupReservationSid
                 * @property {boolean|null} [promptShown] PermissionRequested promptShown
                 * @property {streem.api.AnalyticEvent.PermissionRequested.Reason|null} [reason] PermissionRequested reason
                 */

                /**
                 * Constructs a new PermissionRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a PermissionRequested.
                 * @implements IPermissionRequested
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IPermissionRequested=} [p] Properties to set
                 */
                function PermissionRequested(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * PermissionRequested permission.
                 * @member {streem.api.AnalyticEvent.PermissionRequested.Permission} permission
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.permission = 0;

                /**
                 * PermissionRequested granted.
                 * @member {boolean} granted
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.granted = false;

                /**
                 * PermissionRequested inviteSid.
                 * @member {string} inviteSid
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.inviteSid = "";

                /**
                 * PermissionRequested groupReservationSid.
                 * @member {string} groupReservationSid
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.groupReservationSid = "";

                /**
                 * PermissionRequested promptShown.
                 * @member {boolean} promptShown
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.promptShown = false;

                /**
                 * PermissionRequested reason.
                 * @member {streem.api.AnalyticEvent.PermissionRequested.Reason} reason
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 */
                PermissionRequested.prototype.reason = 0;

                /**
                 * Creates a new PermissionRequested instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.IPermissionRequested=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.PermissionRequested} PermissionRequested instance
                 */
                PermissionRequested.create = function create(properties) {
                    return new PermissionRequested(properties);
                };

                /**
                 * Encodes the specified PermissionRequested message. Does not implicitly {@link streem.api.AnalyticEvent.PermissionRequested.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.IPermissionRequested} m PermissionRequested message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PermissionRequested.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.permission != null && Object.hasOwnProperty.call(m, "permission"))
                        w.uint32(8).int32(m.permission);
                    if (m.granted != null && Object.hasOwnProperty.call(m, "granted"))
                        w.uint32(16).bool(m.granted);
                    if (m.inviteSid != null && Object.hasOwnProperty.call(m, "inviteSid"))
                        w.uint32(26).string(m.inviteSid);
                    if (m.promptShown != null && Object.hasOwnProperty.call(m, "promptShown"))
                        w.uint32(32).bool(m.promptShown);
                    if (m.reason != null && Object.hasOwnProperty.call(m, "reason"))
                        w.uint32(40).int32(m.reason);
                    if (m.groupReservationSid != null && Object.hasOwnProperty.call(m, "groupReservationSid"))
                        w.uint32(50).string(m.groupReservationSid);
                    return w;
                };

                /**
                 * Decodes a PermissionRequested message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.PermissionRequested} PermissionRequested
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PermissionRequested.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.PermissionRequested();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.permission = r.int32();
                                break;
                            }
                        case 2: {
                                m.granted = r.bool();
                                break;
                            }
                        case 3: {
                                m.inviteSid = r.string();
                                break;
                            }
                        case 6: {
                                m.groupReservationSid = r.string();
                                break;
                            }
                        case 4: {
                                m.promptShown = r.bool();
                                break;
                            }
                        case 5: {
                                m.reason = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a PermissionRequested message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.PermissionRequested} PermissionRequested
                 */
                PermissionRequested.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.PermissionRequested)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.PermissionRequested();
                    switch (d.permission) {
                    default:
                        if (typeof d.permission === "number") {
                            m.permission = d.permission;
                            break;
                        }
                        break;
                    case "PERMISSION_INVALID":
                    case 0:
                        m.permission = 0;
                        break;
                    case "PERMISSION_CAMERA":
                    case 1:
                        m.permission = 1;
                        break;
                    case "PERMISSION_MICROPHONE":
                    case 2:
                        m.permission = 2;
                        break;
                    case "PERMISSION_LOCATION":
                    case 3:
                        m.permission = 3;
                        break;
                    }
                    if (d.granted != null) {
                        m.granted = Boolean(d.granted);
                    }
                    if (d.inviteSid != null) {
                        m.inviteSid = String(d.inviteSid);
                    }
                    if (d.groupReservationSid != null) {
                        m.groupReservationSid = String(d.groupReservationSid);
                    }
                    if (d.promptShown != null) {
                        m.promptShown = Boolean(d.promptShown);
                    }
                    switch (d.reason) {
                    default:
                        if (typeof d.reason === "number") {
                            m.reason = d.reason;
                            break;
                        }
                        break;
                    case "REASON_INVALID":
                    case 0:
                        m.reason = 0;
                        break;
                    case "REASON_CALL":
                    case 1:
                        m.reason = 1;
                        break;
                    case "REASON_GUIDED_SCAN":
                    case 2:
                        m.reason = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a PermissionRequested message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.PermissionRequested} m PermissionRequested
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PermissionRequested.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.permission = o.enums === String ? "PERMISSION_INVALID" : 0;
                        d.granted = false;
                        d.inviteSid = "";
                        d.promptShown = false;
                        d.reason = o.enums === String ? "REASON_INVALID" : 0;
                        d.groupReservationSid = "";
                    }
                    if (m.permission != null && m.hasOwnProperty("permission")) {
                        d.permission = o.enums === String ? $root.streem.api.AnalyticEvent.PermissionRequested.Permission[m.permission] === undefined ? m.permission : $root.streem.api.AnalyticEvent.PermissionRequested.Permission[m.permission] : m.permission;
                    }
                    if (m.granted != null && m.hasOwnProperty("granted")) {
                        d.granted = m.granted;
                    }
                    if (m.inviteSid != null && m.hasOwnProperty("inviteSid")) {
                        d.inviteSid = m.inviteSid;
                    }
                    if (m.promptShown != null && m.hasOwnProperty("promptShown")) {
                        d.promptShown = m.promptShown;
                    }
                    if (m.reason != null && m.hasOwnProperty("reason")) {
                        d.reason = o.enums === String ? $root.streem.api.AnalyticEvent.PermissionRequested.Reason[m.reason] === undefined ? m.reason : $root.streem.api.AnalyticEvent.PermissionRequested.Reason[m.reason] : m.reason;
                    }
                    if (m.groupReservationSid != null && m.hasOwnProperty("groupReservationSid")) {
                        d.groupReservationSid = m.groupReservationSid;
                    }
                    return d;
                };

                /**
                 * Converts this PermissionRequested to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PermissionRequested.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PermissionRequested
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.PermissionRequested
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PermissionRequested.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.PermissionRequested";
                };

                /**
                 * Permission enum.
                 * @name streem.api.AnalyticEvent.PermissionRequested.Permission
                 * @enum {number}
                 * @property {number} PERMISSION_INVALID=0 PERMISSION_INVALID value
                 * @property {number} PERMISSION_CAMERA=1 PERMISSION_CAMERA value
                 * @property {number} PERMISSION_MICROPHONE=2 PERMISSION_MICROPHONE value
                 * @property {number} PERMISSION_LOCATION=3 PERMISSION_LOCATION value
                 */
                PermissionRequested.Permission = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PERMISSION_INVALID"] = 0;
                    values[valuesById[1] = "PERMISSION_CAMERA"] = 1;
                    values[valuesById[2] = "PERMISSION_MICROPHONE"] = 2;
                    values[valuesById[3] = "PERMISSION_LOCATION"] = 3;
                    return values;
                })();

                /**
                 * Reason enum.
                 * @name streem.api.AnalyticEvent.PermissionRequested.Reason
                 * @enum {number}
                 * @property {number} REASON_INVALID=0 REASON_INVALID value
                 * @property {number} REASON_CALL=1 REASON_CALL value
                 * @property {number} REASON_GUIDED_SCAN=2 REASON_GUIDED_SCAN value
                 */
                PermissionRequested.Reason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REASON_INVALID"] = 0;
                    values[valuesById[1] = "REASON_CALL"] = 1;
                    values[valuesById[2] = "REASON_GUIDED_SCAN"] = 2;
                    return values;
                })();

                return PermissionRequested;
            })();

            AnalyticEvent.LoginAttempted = (function() {

                /**
                 * Properties of a LoginAttempted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ILoginAttempted
                 * @property {streem.api.AnalyticEvent.LoginAttempted.LoginMethod|null} [method] LoginAttempted method
                 * @property {boolean|null} [successful] LoginAttempted successful
                 * @property {string|null} [inviteSid] LoginAttempted inviteSid
                 */

                /**
                 * Constructs a new LoginAttempted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a LoginAttempted.
                 * @implements ILoginAttempted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ILoginAttempted=} [p] Properties to set
                 */
                function LoginAttempted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LoginAttempted method.
                 * @member {streem.api.AnalyticEvent.LoginAttempted.LoginMethod} method
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @instance
                 */
                LoginAttempted.prototype.method = 0;

                /**
                 * LoginAttempted successful.
                 * @member {boolean} successful
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @instance
                 */
                LoginAttempted.prototype.successful = false;

                /**
                 * LoginAttempted inviteSid.
                 * @member {string} inviteSid
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @instance
                 */
                LoginAttempted.prototype.inviteSid = "";

                /**
                 * Creates a new LoginAttempted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.ILoginAttempted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.LoginAttempted} LoginAttempted instance
                 */
                LoginAttempted.create = function create(properties) {
                    return new LoginAttempted(properties);
                };

                /**
                 * Encodes the specified LoginAttempted message. Does not implicitly {@link streem.api.AnalyticEvent.LoginAttempted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.ILoginAttempted} m LoginAttempted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoginAttempted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                        w.uint32(8).int32(m.method);
                    if (m.successful != null && Object.hasOwnProperty.call(m, "successful"))
                        w.uint32(16).bool(m.successful);
                    if (m.inviteSid != null && Object.hasOwnProperty.call(m, "inviteSid"))
                        w.uint32(26).string(m.inviteSid);
                    return w;
                };

                /**
                 * Decodes a LoginAttempted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.LoginAttempted} LoginAttempted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoginAttempted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.LoginAttempted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.method = r.int32();
                                break;
                            }
                        case 2: {
                                m.successful = r.bool();
                                break;
                            }
                        case 3: {
                                m.inviteSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LoginAttempted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.LoginAttempted} LoginAttempted
                 */
                LoginAttempted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.LoginAttempted)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.LoginAttempted();
                    switch (d.method) {
                    default:
                        if (typeof d.method === "number") {
                            m.method = d.method;
                            break;
                        }
                        break;
                    case "LOGIN_METHOD_INVALID":
                    case 0:
                        m.method = 0;
                        break;
                    case "LOGIN_METHOD_USER_PASS":
                    case 1:
                        m.method = 1;
                        break;
                    case "LOGIN_METHOD_INVITATION":
                    case 2:
                        m.method = 2;
                        break;
                    case "LOGIN_METHOD_SSO":
                    case 3:
                        m.method = 3;
                        break;
                    }
                    if (d.successful != null) {
                        m.successful = Boolean(d.successful);
                    }
                    if (d.inviteSid != null) {
                        m.inviteSid = String(d.inviteSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LoginAttempted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.LoginAttempted} m LoginAttempted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoginAttempted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.method = o.enums === String ? "LOGIN_METHOD_INVALID" : 0;
                        d.successful = false;
                        d.inviteSid = "";
                    }
                    if (m.method != null && m.hasOwnProperty("method")) {
                        d.method = o.enums === String ? $root.streem.api.AnalyticEvent.LoginAttempted.LoginMethod[m.method] === undefined ? m.method : $root.streem.api.AnalyticEvent.LoginAttempted.LoginMethod[m.method] : m.method;
                    }
                    if (m.successful != null && m.hasOwnProperty("successful")) {
                        d.successful = m.successful;
                    }
                    if (m.inviteSid != null && m.hasOwnProperty("inviteSid")) {
                        d.inviteSid = m.inviteSid;
                    }
                    return d;
                };

                /**
                 * Converts this LoginAttempted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoginAttempted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LoginAttempted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.LoginAttempted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LoginAttempted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.LoginAttempted";
                };

                /**
                 * LoginMethod enum.
                 * @name streem.api.AnalyticEvent.LoginAttempted.LoginMethod
                 * @enum {number}
                 * @property {number} LOGIN_METHOD_INVALID=0 LOGIN_METHOD_INVALID value
                 * @property {number} LOGIN_METHOD_USER_PASS=1 LOGIN_METHOD_USER_PASS value
                 * @property {number} LOGIN_METHOD_INVITATION=2 LOGIN_METHOD_INVITATION value
                 * @property {number} LOGIN_METHOD_SSO=3 LOGIN_METHOD_SSO value
                 */
                LoginAttempted.LoginMethod = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "LOGIN_METHOD_INVALID"] = 0;
                    values[valuesById[1] = "LOGIN_METHOD_USER_PASS"] = 1;
                    values[valuesById[2] = "LOGIN_METHOD_INVITATION"] = 2;
                    values[valuesById[3] = "LOGIN_METHOD_SSO"] = 3;
                    return values;
                })();

                return LoginAttempted;
            })();

            AnalyticEvent.IdentifyAttempted = (function() {

                /**
                 * Properties of an IdentifyAttempted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IIdentifyAttempted
                 * @property {boolean|null} [successful] IdentifyAttempted successful
                 * @property {string|null} [groupReservationSid] IdentifyAttempted groupReservationSid
                 */

                /**
                 * Constructs a new IdentifyAttempted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an IdentifyAttempted.
                 * @implements IIdentifyAttempted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IIdentifyAttempted=} [p] Properties to set
                 */
                function IdentifyAttempted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * IdentifyAttempted successful.
                 * @member {boolean} successful
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @instance
                 */
                IdentifyAttempted.prototype.successful = false;

                /**
                 * IdentifyAttempted groupReservationSid.
                 * @member {string} groupReservationSid
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @instance
                 */
                IdentifyAttempted.prototype.groupReservationSid = "";

                /**
                 * Creates a new IdentifyAttempted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.IIdentifyAttempted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.IdentifyAttempted} IdentifyAttempted instance
                 */
                IdentifyAttempted.create = function create(properties) {
                    return new IdentifyAttempted(properties);
                };

                /**
                 * Encodes the specified IdentifyAttempted message. Does not implicitly {@link streem.api.AnalyticEvent.IdentifyAttempted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.IIdentifyAttempted} m IdentifyAttempted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdentifyAttempted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.successful != null && Object.hasOwnProperty.call(m, "successful"))
                        w.uint32(8).bool(m.successful);
                    if (m.groupReservationSid != null && Object.hasOwnProperty.call(m, "groupReservationSid"))
                        w.uint32(18).string(m.groupReservationSid);
                    return w;
                };

                /**
                 * Decodes an IdentifyAttempted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.IdentifyAttempted} IdentifyAttempted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdentifyAttempted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.IdentifyAttempted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.successful = r.bool();
                                break;
                            }
                        case 2: {
                                m.groupReservationSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an IdentifyAttempted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.IdentifyAttempted} IdentifyAttempted
                 */
                IdentifyAttempted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.IdentifyAttempted)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.IdentifyAttempted();
                    if (d.successful != null) {
                        m.successful = Boolean(d.successful);
                    }
                    if (d.groupReservationSid != null) {
                        m.groupReservationSid = String(d.groupReservationSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an IdentifyAttempted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {streem.api.AnalyticEvent.IdentifyAttempted} m IdentifyAttempted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IdentifyAttempted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.successful = false;
                        d.groupReservationSid = "";
                    }
                    if (m.successful != null && m.hasOwnProperty("successful")) {
                        d.successful = m.successful;
                    }
                    if (m.groupReservationSid != null && m.hasOwnProperty("groupReservationSid")) {
                        d.groupReservationSid = m.groupReservationSid;
                    }
                    return d;
                };

                /**
                 * Converts this IdentifyAttempted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IdentifyAttempted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for IdentifyAttempted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.IdentifyAttempted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IdentifyAttempted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.IdentifyAttempted";
                };

                return IdentifyAttempted;
            })();

            AnalyticEvent.LoggedOut = (function() {

                /**
                 * Properties of a LoggedOut.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ILoggedOut
                 */

                /**
                 * Constructs a new LoggedOut.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a LoggedOut.
                 * @implements ILoggedOut
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ILoggedOut=} [p] Properties to set
                 */
                function LoggedOut(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new LoggedOut instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {streem.api.AnalyticEvent.ILoggedOut=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.LoggedOut} LoggedOut instance
                 */
                LoggedOut.create = function create(properties) {
                    return new LoggedOut(properties);
                };

                /**
                 * Encodes the specified LoggedOut message. Does not implicitly {@link streem.api.AnalyticEvent.LoggedOut.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {streem.api.AnalyticEvent.ILoggedOut} m LoggedOut message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoggedOut.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a LoggedOut message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.LoggedOut} LoggedOut
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoggedOut.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.LoggedOut();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LoggedOut message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.LoggedOut} LoggedOut
                 */
                LoggedOut.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.LoggedOut)
                        return d;
                    return new $root.streem.api.AnalyticEvent.LoggedOut();
                };

                /**
                 * Creates a plain object from a LoggedOut message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {streem.api.AnalyticEvent.LoggedOut} m LoggedOut
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoggedOut.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this LoggedOut to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoggedOut.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LoggedOut
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.LoggedOut
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LoggedOut.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.LoggedOut";
                };

                return LoggedOut;
            })();

            AnalyticEvent.InviteCreated = (function() {

                /**
                 * Properties of an InviteCreated.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IInviteCreated
                 * @property {string|null} [inviteSid] InviteCreated inviteSid
                 * @property {streem.api.DeliveryType|null} [method] InviteCreated method
                 * @property {boolean|null} [successful] InviteCreated successful
                 */

                /**
                 * Constructs a new InviteCreated.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an InviteCreated.
                 * @implements IInviteCreated
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IInviteCreated=} [p] Properties to set
                 */
                function InviteCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * InviteCreated inviteSid.
                 * @member {string} inviteSid
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @instance
                 */
                InviteCreated.prototype.inviteSid = "";

                /**
                 * InviteCreated method.
                 * @member {streem.api.DeliveryType} method
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @instance
                 */
                InviteCreated.prototype.method = 0;

                /**
                 * InviteCreated successful.
                 * @member {boolean} successful
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @instance
                 */
                InviteCreated.prototype.successful = false;

                /**
                 * Creates a new InviteCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {streem.api.AnalyticEvent.IInviteCreated=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.InviteCreated} InviteCreated instance
                 */
                InviteCreated.create = function create(properties) {
                    return new InviteCreated(properties);
                };

                /**
                 * Encodes the specified InviteCreated message. Does not implicitly {@link streem.api.AnalyticEvent.InviteCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {streem.api.AnalyticEvent.IInviteCreated} m InviteCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InviteCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.inviteSid != null && Object.hasOwnProperty.call(m, "inviteSid"))
                        w.uint32(10).string(m.inviteSid);
                    if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                        w.uint32(16).int32(m.method);
                    if (m.successful != null && Object.hasOwnProperty.call(m, "successful"))
                        w.uint32(24).bool(m.successful);
                    return w;
                };

                /**
                 * Decodes an InviteCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.InviteCreated} InviteCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InviteCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.InviteCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.inviteSid = r.string();
                                break;
                            }
                        case 2: {
                                m.method = r.int32();
                                break;
                            }
                        case 3: {
                                m.successful = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an InviteCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.InviteCreated} InviteCreated
                 */
                InviteCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.InviteCreated)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.InviteCreated();
                    if (d.inviteSid != null) {
                        m.inviteSid = String(d.inviteSid);
                    }
                    switch (d.method) {
                    default:
                        if (typeof d.method === "number") {
                            m.method = d.method;
                            break;
                        }
                        break;
                    case "DELIVERY_TYPE_INVALID":
                    case 0:
                        m.method = 0;
                        break;
                    case "DELIVERY_TYPE_SMS":
                    case 1:
                        m.method = 1;
                        break;
                    case "DELIVERY_TYPE_EMAIL":
                    case 2:
                        m.method = 2;
                        break;
                    case "DELIVERY_TYPE_LINK":
                    case 3:
                        m.method = 3;
                        break;
                    }
                    if (d.successful != null) {
                        m.successful = Boolean(d.successful);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an InviteCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {streem.api.AnalyticEvent.InviteCreated} m InviteCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InviteCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.inviteSid = "";
                        d.method = o.enums === String ? "DELIVERY_TYPE_INVALID" : 0;
                        d.successful = false;
                    }
                    if (m.inviteSid != null && m.hasOwnProperty("inviteSid")) {
                        d.inviteSid = m.inviteSid;
                    }
                    if (m.method != null && m.hasOwnProperty("method")) {
                        d.method = o.enums === String ? $root.streem.api.DeliveryType[m.method] === undefined ? m.method : $root.streem.api.DeliveryType[m.method] : m.method;
                    }
                    if (m.successful != null && m.hasOwnProperty("successful")) {
                        d.successful = m.successful;
                    }
                    return d;
                };

                /**
                 * Converts this InviteCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InviteCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InviteCreated
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.InviteCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InviteCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.InviteCreated";
                };

                return InviteCreated;
            })();

            AnalyticEvent.InviteClicked = (function() {

                /**
                 * Properties of an InviteClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IInviteClicked
                 * @property {string|null} [inviteSid] InviteClicked inviteSid
                 * @property {string|null} [userAgent] InviteClicked userAgent
                 */

                /**
                 * Constructs a new InviteClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an InviteClicked.
                 * @implements IInviteClicked
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IInviteClicked=} [p] Properties to set
                 */
                function InviteClicked(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * InviteClicked inviteSid.
                 * @member {string} inviteSid
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @instance
                 */
                InviteClicked.prototype.inviteSid = "";

                /**
                 * InviteClicked userAgent.
                 * @member {string} userAgent
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @instance
                 */
                InviteClicked.prototype.userAgent = "";

                /**
                 * Creates a new InviteClicked instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IInviteClicked=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.InviteClicked} InviteClicked instance
                 */
                InviteClicked.create = function create(properties) {
                    return new InviteClicked(properties);
                };

                /**
                 * Encodes the specified InviteClicked message. Does not implicitly {@link streem.api.AnalyticEvent.InviteClicked.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IInviteClicked} m InviteClicked message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InviteClicked.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.inviteSid != null && Object.hasOwnProperty.call(m, "inviteSid"))
                        w.uint32(10).string(m.inviteSid);
                    if (m.userAgent != null && Object.hasOwnProperty.call(m, "userAgent"))
                        w.uint32(18).string(m.userAgent);
                    return w;
                };

                /**
                 * Decodes an InviteClicked message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.InviteClicked} InviteClicked
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InviteClicked.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.InviteClicked();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.inviteSid = r.string();
                                break;
                            }
                        case 2: {
                                m.userAgent = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an InviteClicked message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.InviteClicked} InviteClicked
                 */
                InviteClicked.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.InviteClicked)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.InviteClicked();
                    if (d.inviteSid != null) {
                        m.inviteSid = String(d.inviteSid);
                    }
                    if (d.userAgent != null) {
                        m.userAgent = String(d.userAgent);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an InviteClicked message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.InviteClicked} m InviteClicked
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InviteClicked.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.inviteSid = "";
                        d.userAgent = "";
                    }
                    if (m.inviteSid != null && m.hasOwnProperty("inviteSid")) {
                        d.inviteSid = m.inviteSid;
                    }
                    if (m.userAgent != null && m.hasOwnProperty("userAgent")) {
                        d.userAgent = m.userAgent;
                    }
                    return d;
                };

                /**
                 * Converts this InviteClicked to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InviteClicked.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InviteClicked
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.InviteClicked
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InviteClicked.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.InviteClicked";
                };

                return InviteClicked;
            })();

            AnalyticEvent.ConnectionStatusChanged = (function() {

                /**
                 * Properties of a ConnectionStatusChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IConnectionStatusChanged
                 * @property {string|null} [roomSid] ConnectionStatusChanged roomSid
                 * @property {number|null} [newStrength] ConnectionStatusChanged newStrength
                 * @property {streem.api.ConnectionMethod|null} [method] ConnectionStatusChanged method
                 */

                /**
                 * Constructs a new ConnectionStatusChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ConnectionStatusChanged.
                 * @implements IConnectionStatusChanged
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IConnectionStatusChanged=} [p] Properties to set
                 */
                function ConnectionStatusChanged(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ConnectionStatusChanged roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @instance
                 */
                ConnectionStatusChanged.prototype.roomSid = "";

                /**
                 * ConnectionStatusChanged newStrength.
                 * @member {number} newStrength
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @instance
                 */
                ConnectionStatusChanged.prototype.newStrength = 0;

                /**
                 * ConnectionStatusChanged method.
                 * @member {streem.api.ConnectionMethod} method
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @instance
                 */
                ConnectionStatusChanged.prototype.method = 0;

                /**
                 * Creates a new ConnectionStatusChanged instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IConnectionStatusChanged=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ConnectionStatusChanged} ConnectionStatusChanged instance
                 */
                ConnectionStatusChanged.create = function create(properties) {
                    return new ConnectionStatusChanged(properties);
                };

                /**
                 * Encodes the specified ConnectionStatusChanged message. Does not implicitly {@link streem.api.AnalyticEvent.ConnectionStatusChanged.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IConnectionStatusChanged} m ConnectionStatusChanged message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConnectionStatusChanged.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.newStrength != null && Object.hasOwnProperty.call(m, "newStrength"))
                        w.uint32(16).int32(m.newStrength);
                    if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                        w.uint32(24).int32(m.method);
                    return w;
                };

                /**
                 * Decodes a ConnectionStatusChanged message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ConnectionStatusChanged} ConnectionStatusChanged
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConnectionStatusChanged.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ConnectionStatusChanged();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.newStrength = r.int32();
                                break;
                            }
                        case 3: {
                                m.method = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ConnectionStatusChanged message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ConnectionStatusChanged} ConnectionStatusChanged
                 */
                ConnectionStatusChanged.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ConnectionStatusChanged)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ConnectionStatusChanged();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    if (d.newStrength != null) {
                        m.newStrength = d.newStrength | 0;
                    }
                    switch (d.method) {
                    default:
                        if (typeof d.method === "number") {
                            m.method = d.method;
                            break;
                        }
                        break;
                    case "CONNECTION_METHOD_INVALID":
                    case 0:
                        m.method = 0;
                        break;
                    case "CONNECTION_METHOD_WIFI":
                    case 1:
                        m.method = 1;
                        break;
                    case "CONNECTION_METHOD_CELLULAR":
                    case 2:
                        m.method = 2;
                        break;
                    case "CONNECTION_METHOD_UNKNOWN":
                    case 3:
                        m.method = 3;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ConnectionStatusChanged message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.ConnectionStatusChanged} m ConnectionStatusChanged
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConnectionStatusChanged.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.newStrength = 0;
                        d.method = o.enums === String ? "CONNECTION_METHOD_INVALID" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.newStrength != null && m.hasOwnProperty("newStrength")) {
                        d.newStrength = m.newStrength;
                    }
                    if (m.method != null && m.hasOwnProperty("method")) {
                        d.method = o.enums === String ? $root.streem.api.ConnectionMethod[m.method] === undefined ? m.method : $root.streem.api.ConnectionMethod[m.method] : m.method;
                    }
                    return d;
                };

                /**
                 * Converts this ConnectionStatusChanged to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConnectionStatusChanged.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ConnectionStatusChanged
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ConnectionStatusChanged
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConnectionStatusChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ConnectionStatusChanged";
                };

                return ConnectionStatusChanged;
            })();

            AnalyticEvent.ResolutionChanged = (function() {

                /**
                 * Properties of a ResolutionChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IResolutionChanged
                 * @property {string|null} [roomSid] ResolutionChanged roomSid
                 * @property {streem.api.ISize|null} [size] ResolutionChanged size
                 */

                /**
                 * Constructs a new ResolutionChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ResolutionChanged.
                 * @implements IResolutionChanged
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IResolutionChanged=} [p] Properties to set
                 */
                function ResolutionChanged(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ResolutionChanged roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @instance
                 */
                ResolutionChanged.prototype.roomSid = "";

                /**
                 * ResolutionChanged size.
                 * @member {streem.api.ISize|null|undefined} size
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @instance
                 */
                ResolutionChanged.prototype.size = null;

                /**
                 * Creates a new ResolutionChanged instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IResolutionChanged=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ResolutionChanged} ResolutionChanged instance
                 */
                ResolutionChanged.create = function create(properties) {
                    return new ResolutionChanged(properties);
                };

                /**
                 * Encodes the specified ResolutionChanged message. Does not implicitly {@link streem.api.AnalyticEvent.ResolutionChanged.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IResolutionChanged} m ResolutionChanged message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolutionChanged.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.size != null && Object.hasOwnProperty.call(m, "size"))
                        $root.streem.api.Size.encode(m.size, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a ResolutionChanged message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ResolutionChanged} ResolutionChanged
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolutionChanged.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ResolutionChanged();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.size = $root.streem.api.Size.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ResolutionChanged message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ResolutionChanged} ResolutionChanged
                 */
                ResolutionChanged.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ResolutionChanged)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ResolutionChanged();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    if (d.size != null) {
                        if (typeof d.size !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ResolutionChanged.size: object expected");
                        m.size = $root.streem.api.Size.fromObject(d.size);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ResolutionChanged message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.ResolutionChanged} m ResolutionChanged
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolutionChanged.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.size = null;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.size != null && m.hasOwnProperty("size")) {
                        d.size = $root.streem.api.Size.toObject(m.size, o);
                    }
                    return d;
                };

                /**
                 * Converts this ResolutionChanged to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolutionChanged.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ResolutionChanged
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ResolutionChanged
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResolutionChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ResolutionChanged";
                };

                return ResolutionChanged;
            })();

            AnalyticEvent.LocalVideoStatsMeasured = (function() {

                /**
                 * Properties of a LocalVideoStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ILocalVideoStatsMeasured
                 * @property {number|null} [packetsLost] LocalVideoStatsMeasured packetsLost
                 * @property {string|null} [codec] LocalVideoStatsMeasured codec
                 * @property {Long|null} [bytesSent] LocalVideoStatsMeasured bytesSent
                 * @property {number|null} [packetsSent] LocalVideoStatsMeasured packetsSent
                 * @property {Long|null} [roundTripTimeMs] LocalVideoStatsMeasured roundTripTimeMs
                 * @property {number|null} [captureFrameRate] LocalVideoStatsMeasured captureFrameRate
                 * @property {number|null} [frameRate] LocalVideoStatsMeasured frameRate
                 * @property {streem.api.ISize|null} [captureDimensions] LocalVideoStatsMeasured captureDimensions
                 * @property {streem.api.ISize|null} [dimensions] LocalVideoStatsMeasured dimensions
                 * @property {string|null} [trackSid] LocalVideoStatsMeasured trackSid
                 * @property {string|null} [roomSid] LocalVideoStatsMeasured roomSid
                 */

                /**
                 * Constructs a new LocalVideoStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a LocalVideoStatsMeasured.
                 * @implements ILocalVideoStatsMeasured
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ILocalVideoStatsMeasured=} [p] Properties to set
                 */
                function LocalVideoStatsMeasured(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LocalVideoStatsMeasured packetsLost.
                 * @member {number} packetsLost
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.packetsLost = 0;

                /**
                 * LocalVideoStatsMeasured codec.
                 * @member {string} codec
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.codec = "";

                /**
                 * LocalVideoStatsMeasured bytesSent.
                 * @member {Long} bytesSent
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.bytesSent = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalVideoStatsMeasured packetsSent.
                 * @member {number} packetsSent
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.packetsSent = 0;

                /**
                 * LocalVideoStatsMeasured roundTripTimeMs.
                 * @member {Long} roundTripTimeMs
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.roundTripTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalVideoStatsMeasured captureFrameRate.
                 * @member {number} captureFrameRate
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.captureFrameRate = 0;

                /**
                 * LocalVideoStatsMeasured frameRate.
                 * @member {number} frameRate
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.frameRate = 0;

                /**
                 * LocalVideoStatsMeasured captureDimensions.
                 * @member {streem.api.ISize|null|undefined} captureDimensions
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.captureDimensions = null;

                /**
                 * LocalVideoStatsMeasured dimensions.
                 * @member {streem.api.ISize|null|undefined} dimensions
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.dimensions = null;

                /**
                 * LocalVideoStatsMeasured trackSid.
                 * @member {string} trackSid
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.trackSid = "";

                /**
                 * LocalVideoStatsMeasured roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 */
                LocalVideoStatsMeasured.prototype.roomSid = "";

                /**
                 * Creates a new LocalVideoStatsMeasured instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.ILocalVideoStatsMeasured=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.LocalVideoStatsMeasured} LocalVideoStatsMeasured instance
                 */
                LocalVideoStatsMeasured.create = function create(properties) {
                    return new LocalVideoStatsMeasured(properties);
                };

                /**
                 * Encodes the specified LocalVideoStatsMeasured message. Does not implicitly {@link streem.api.AnalyticEvent.LocalVideoStatsMeasured.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.ILocalVideoStatsMeasured} m LocalVideoStatsMeasured message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalVideoStatsMeasured.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.packetsLost != null && Object.hasOwnProperty.call(m, "packetsLost"))
                        w.uint32(8).int32(m.packetsLost);
                    if (m.codec != null && Object.hasOwnProperty.call(m, "codec"))
                        w.uint32(18).string(m.codec);
                    if (m.bytesSent != null && Object.hasOwnProperty.call(m, "bytesSent"))
                        w.uint32(24).int64(m.bytesSent);
                    if (m.packetsSent != null && Object.hasOwnProperty.call(m, "packetsSent"))
                        w.uint32(32).int32(m.packetsSent);
                    if (m.roundTripTimeMs != null && Object.hasOwnProperty.call(m, "roundTripTimeMs"))
                        w.uint32(40).int64(m.roundTripTimeMs);
                    if (m.captureFrameRate != null && Object.hasOwnProperty.call(m, "captureFrameRate"))
                        w.uint32(48).int32(m.captureFrameRate);
                    if (m.frameRate != null && Object.hasOwnProperty.call(m, "frameRate"))
                        w.uint32(56).int32(m.frameRate);
                    if (m.captureDimensions != null && Object.hasOwnProperty.call(m, "captureDimensions"))
                        $root.streem.api.Size.encode(m.captureDimensions, w.uint32(66).fork()).ldelim();
                    if (m.dimensions != null && Object.hasOwnProperty.call(m, "dimensions"))
                        $root.streem.api.Size.encode(m.dimensions, w.uint32(74).fork()).ldelim();
                    if (m.trackSid != null && Object.hasOwnProperty.call(m, "trackSid"))
                        w.uint32(82).string(m.trackSid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(90).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a LocalVideoStatsMeasured message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.LocalVideoStatsMeasured} LocalVideoStatsMeasured
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalVideoStatsMeasured.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.packetsLost = r.int32();
                                break;
                            }
                        case 2: {
                                m.codec = r.string();
                                break;
                            }
                        case 3: {
                                m.bytesSent = r.int64();
                                break;
                            }
                        case 4: {
                                m.packetsSent = r.int32();
                                break;
                            }
                        case 5: {
                                m.roundTripTimeMs = r.int64();
                                break;
                            }
                        case 6: {
                                m.captureFrameRate = r.int32();
                                break;
                            }
                        case 7: {
                                m.frameRate = r.int32();
                                break;
                            }
                        case 8: {
                                m.captureDimensions = $root.streem.api.Size.decode(r, r.uint32());
                                break;
                            }
                        case 9: {
                                m.dimensions = $root.streem.api.Size.decode(r, r.uint32());
                                break;
                            }
                        case 10: {
                                m.trackSid = r.string();
                                break;
                            }
                        case 11: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LocalVideoStatsMeasured message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.LocalVideoStatsMeasured} LocalVideoStatsMeasured
                 */
                LocalVideoStatsMeasured.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.LocalVideoStatsMeasured();
                    if (d.packetsLost != null) {
                        m.packetsLost = d.packetsLost | 0;
                    }
                    if (d.codec != null) {
                        m.codec = String(d.codec);
                    }
                    if (d.bytesSent != null) {
                        if ($util.Long)
                            (m.bytesSent = $util.Long.fromValue(d.bytesSent)).unsigned = false;
                        else if (typeof d.bytesSent === "string")
                            m.bytesSent = parseInt(d.bytesSent, 10);
                        else if (typeof d.bytesSent === "number")
                            m.bytesSent = d.bytesSent;
                        else if (typeof d.bytesSent === "object")
                            m.bytesSent = new $util.LongBits(d.bytesSent.low >>> 0, d.bytesSent.high >>> 0).toNumber();
                    }
                    if (d.packetsSent != null) {
                        m.packetsSent = d.packetsSent | 0;
                    }
                    if (d.roundTripTimeMs != null) {
                        if ($util.Long)
                            (m.roundTripTimeMs = $util.Long.fromValue(d.roundTripTimeMs)).unsigned = false;
                        else if (typeof d.roundTripTimeMs === "string")
                            m.roundTripTimeMs = parseInt(d.roundTripTimeMs, 10);
                        else if (typeof d.roundTripTimeMs === "number")
                            m.roundTripTimeMs = d.roundTripTimeMs;
                        else if (typeof d.roundTripTimeMs === "object")
                            m.roundTripTimeMs = new $util.LongBits(d.roundTripTimeMs.low >>> 0, d.roundTripTimeMs.high >>> 0).toNumber();
                    }
                    if (d.captureFrameRate != null) {
                        m.captureFrameRate = d.captureFrameRate | 0;
                    }
                    if (d.frameRate != null) {
                        m.frameRate = d.frameRate | 0;
                    }
                    if (d.captureDimensions != null) {
                        if (typeof d.captureDimensions !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.LocalVideoStatsMeasured.captureDimensions: object expected");
                        m.captureDimensions = $root.streem.api.Size.fromObject(d.captureDimensions);
                    }
                    if (d.dimensions != null) {
                        if (typeof d.dimensions !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.LocalVideoStatsMeasured.dimensions: object expected");
                        m.dimensions = $root.streem.api.Size.fromObject(d.dimensions);
                    }
                    if (d.trackSid != null) {
                        m.trackSid = String(d.trackSid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LocalVideoStatsMeasured message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.LocalVideoStatsMeasured} m LocalVideoStatsMeasured
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalVideoStatsMeasured.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.packetsLost = 0;
                        d.codec = "";
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.bytesSent = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.bytesSent = o.longs === String ? "0" : 0;
                        d.packetsSent = 0;
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.roundTripTimeMs = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.roundTripTimeMs = o.longs === String ? "0" : 0;
                        d.captureFrameRate = 0;
                        d.frameRate = 0;
                        d.captureDimensions = null;
                        d.dimensions = null;
                        d.trackSid = "";
                        d.roomSid = "";
                    }
                    if (m.packetsLost != null && m.hasOwnProperty("packetsLost")) {
                        d.packetsLost = m.packetsLost;
                    }
                    if (m.codec != null && m.hasOwnProperty("codec")) {
                        d.codec = m.codec;
                    }
                    if (m.bytesSent != null && m.hasOwnProperty("bytesSent")) {
                        if (typeof m.bytesSent === "number")
                            d.bytesSent = o.longs === String ? String(m.bytesSent) : m.bytesSent;
                        else
                            d.bytesSent = o.longs === String ? $util.Long.prototype.toString.call(m.bytesSent) : o.longs === Number ? new $util.LongBits(m.bytesSent.low >>> 0, m.bytesSent.high >>> 0).toNumber() : m.bytesSent;
                    }
                    if (m.packetsSent != null && m.hasOwnProperty("packetsSent")) {
                        d.packetsSent = m.packetsSent;
                    }
                    if (m.roundTripTimeMs != null && m.hasOwnProperty("roundTripTimeMs")) {
                        if (typeof m.roundTripTimeMs === "number")
                            d.roundTripTimeMs = o.longs === String ? String(m.roundTripTimeMs) : m.roundTripTimeMs;
                        else
                            d.roundTripTimeMs = o.longs === String ? $util.Long.prototype.toString.call(m.roundTripTimeMs) : o.longs === Number ? new $util.LongBits(m.roundTripTimeMs.low >>> 0, m.roundTripTimeMs.high >>> 0).toNumber() : m.roundTripTimeMs;
                    }
                    if (m.captureFrameRate != null && m.hasOwnProperty("captureFrameRate")) {
                        d.captureFrameRate = m.captureFrameRate;
                    }
                    if (m.frameRate != null && m.hasOwnProperty("frameRate")) {
                        d.frameRate = m.frameRate;
                    }
                    if (m.captureDimensions != null && m.hasOwnProperty("captureDimensions")) {
                        d.captureDimensions = $root.streem.api.Size.toObject(m.captureDimensions, o);
                    }
                    if (m.dimensions != null && m.hasOwnProperty("dimensions")) {
                        d.dimensions = $root.streem.api.Size.toObject(m.dimensions, o);
                    }
                    if (m.trackSid != null && m.hasOwnProperty("trackSid")) {
                        d.trackSid = m.trackSid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this LocalVideoStatsMeasured to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalVideoStatsMeasured.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalVideoStatsMeasured
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.LocalVideoStatsMeasured
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalVideoStatsMeasured.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.LocalVideoStatsMeasured";
                };

                return LocalVideoStatsMeasured;
            })();

            AnalyticEvent.LocalAudioStatsMeasured = (function() {

                /**
                 * Properties of a LocalAudioStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ILocalAudioStatsMeasured
                 * @property {number|null} [packetsLost] LocalAudioStatsMeasured packetsLost
                 * @property {string|null} [codec] LocalAudioStatsMeasured codec
                 * @property {Long|null} [bytesSent] LocalAudioStatsMeasured bytesSent
                 * @property {number|null} [packetsSent] LocalAudioStatsMeasured packetsSent
                 * @property {Long|null} [roundTripTimeMs] LocalAudioStatsMeasured roundTripTimeMs
                 * @property {number|null} [audioLevel] LocalAudioStatsMeasured audioLevel
                 * @property {number|null} [jitterMs] LocalAudioStatsMeasured jitterMs
                 * @property {string|null} [trackSid] LocalAudioStatsMeasured trackSid
                 * @property {string|null} [roomSid] LocalAudioStatsMeasured roomSid
                 */

                /**
                 * Constructs a new LocalAudioStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a LocalAudioStatsMeasured.
                 * @implements ILocalAudioStatsMeasured
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ILocalAudioStatsMeasured=} [p] Properties to set
                 */
                function LocalAudioStatsMeasured(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LocalAudioStatsMeasured packetsLost.
                 * @member {number} packetsLost
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.packetsLost = 0;

                /**
                 * LocalAudioStatsMeasured codec.
                 * @member {string} codec
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.codec = "";

                /**
                 * LocalAudioStatsMeasured bytesSent.
                 * @member {Long} bytesSent
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.bytesSent = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalAudioStatsMeasured packetsSent.
                 * @member {number} packetsSent
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.packetsSent = 0;

                /**
                 * LocalAudioStatsMeasured roundTripTimeMs.
                 * @member {Long} roundTripTimeMs
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.roundTripTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalAudioStatsMeasured audioLevel.
                 * @member {number} audioLevel
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.audioLevel = 0;

                /**
                 * LocalAudioStatsMeasured jitterMs.
                 * @member {number} jitterMs
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.jitterMs = 0;

                /**
                 * LocalAudioStatsMeasured trackSid.
                 * @member {string} trackSid
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.trackSid = "";

                /**
                 * LocalAudioStatsMeasured roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 */
                LocalAudioStatsMeasured.prototype.roomSid = "";

                /**
                 * Creates a new LocalAudioStatsMeasured instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.ILocalAudioStatsMeasured=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.LocalAudioStatsMeasured} LocalAudioStatsMeasured instance
                 */
                LocalAudioStatsMeasured.create = function create(properties) {
                    return new LocalAudioStatsMeasured(properties);
                };

                /**
                 * Encodes the specified LocalAudioStatsMeasured message. Does not implicitly {@link streem.api.AnalyticEvent.LocalAudioStatsMeasured.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.ILocalAudioStatsMeasured} m LocalAudioStatsMeasured message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalAudioStatsMeasured.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.packetsLost != null && Object.hasOwnProperty.call(m, "packetsLost"))
                        w.uint32(8).int32(m.packetsLost);
                    if (m.codec != null && Object.hasOwnProperty.call(m, "codec"))
                        w.uint32(18).string(m.codec);
                    if (m.bytesSent != null && Object.hasOwnProperty.call(m, "bytesSent"))
                        w.uint32(24).int64(m.bytesSent);
                    if (m.packetsSent != null && Object.hasOwnProperty.call(m, "packetsSent"))
                        w.uint32(32).int32(m.packetsSent);
                    if (m.roundTripTimeMs != null && Object.hasOwnProperty.call(m, "roundTripTimeMs"))
                        w.uint32(40).int64(m.roundTripTimeMs);
                    if (m.audioLevel != null && Object.hasOwnProperty.call(m, "audioLevel"))
                        w.uint32(48).int32(m.audioLevel);
                    if (m.jitterMs != null && Object.hasOwnProperty.call(m, "jitterMs"))
                        w.uint32(56).int32(m.jitterMs);
                    if (m.trackSid != null && Object.hasOwnProperty.call(m, "trackSid"))
                        w.uint32(66).string(m.trackSid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(74).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a LocalAudioStatsMeasured message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.LocalAudioStatsMeasured} LocalAudioStatsMeasured
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalAudioStatsMeasured.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.packetsLost = r.int32();
                                break;
                            }
                        case 2: {
                                m.codec = r.string();
                                break;
                            }
                        case 3: {
                                m.bytesSent = r.int64();
                                break;
                            }
                        case 4: {
                                m.packetsSent = r.int32();
                                break;
                            }
                        case 5: {
                                m.roundTripTimeMs = r.int64();
                                break;
                            }
                        case 6: {
                                m.audioLevel = r.int32();
                                break;
                            }
                        case 7: {
                                m.jitterMs = r.int32();
                                break;
                            }
                        case 8: {
                                m.trackSid = r.string();
                                break;
                            }
                        case 9: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LocalAudioStatsMeasured message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.LocalAudioStatsMeasured} LocalAudioStatsMeasured
                 */
                LocalAudioStatsMeasured.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.LocalAudioStatsMeasured();
                    if (d.packetsLost != null) {
                        m.packetsLost = d.packetsLost | 0;
                    }
                    if (d.codec != null) {
                        m.codec = String(d.codec);
                    }
                    if (d.bytesSent != null) {
                        if ($util.Long)
                            (m.bytesSent = $util.Long.fromValue(d.bytesSent)).unsigned = false;
                        else if (typeof d.bytesSent === "string")
                            m.bytesSent = parseInt(d.bytesSent, 10);
                        else if (typeof d.bytesSent === "number")
                            m.bytesSent = d.bytesSent;
                        else if (typeof d.bytesSent === "object")
                            m.bytesSent = new $util.LongBits(d.bytesSent.low >>> 0, d.bytesSent.high >>> 0).toNumber();
                    }
                    if (d.packetsSent != null) {
                        m.packetsSent = d.packetsSent | 0;
                    }
                    if (d.roundTripTimeMs != null) {
                        if ($util.Long)
                            (m.roundTripTimeMs = $util.Long.fromValue(d.roundTripTimeMs)).unsigned = false;
                        else if (typeof d.roundTripTimeMs === "string")
                            m.roundTripTimeMs = parseInt(d.roundTripTimeMs, 10);
                        else if (typeof d.roundTripTimeMs === "number")
                            m.roundTripTimeMs = d.roundTripTimeMs;
                        else if (typeof d.roundTripTimeMs === "object")
                            m.roundTripTimeMs = new $util.LongBits(d.roundTripTimeMs.low >>> 0, d.roundTripTimeMs.high >>> 0).toNumber();
                    }
                    if (d.audioLevel != null) {
                        m.audioLevel = d.audioLevel | 0;
                    }
                    if (d.jitterMs != null) {
                        m.jitterMs = d.jitterMs | 0;
                    }
                    if (d.trackSid != null) {
                        m.trackSid = String(d.trackSid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LocalAudioStatsMeasured message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.LocalAudioStatsMeasured} m LocalAudioStatsMeasured
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalAudioStatsMeasured.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.packetsLost = 0;
                        d.codec = "";
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.bytesSent = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.bytesSent = o.longs === String ? "0" : 0;
                        d.packetsSent = 0;
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.roundTripTimeMs = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.roundTripTimeMs = o.longs === String ? "0" : 0;
                        d.audioLevel = 0;
                        d.jitterMs = 0;
                        d.trackSid = "";
                        d.roomSid = "";
                    }
                    if (m.packetsLost != null && m.hasOwnProperty("packetsLost")) {
                        d.packetsLost = m.packetsLost;
                    }
                    if (m.codec != null && m.hasOwnProperty("codec")) {
                        d.codec = m.codec;
                    }
                    if (m.bytesSent != null && m.hasOwnProperty("bytesSent")) {
                        if (typeof m.bytesSent === "number")
                            d.bytesSent = o.longs === String ? String(m.bytesSent) : m.bytesSent;
                        else
                            d.bytesSent = o.longs === String ? $util.Long.prototype.toString.call(m.bytesSent) : o.longs === Number ? new $util.LongBits(m.bytesSent.low >>> 0, m.bytesSent.high >>> 0).toNumber() : m.bytesSent;
                    }
                    if (m.packetsSent != null && m.hasOwnProperty("packetsSent")) {
                        d.packetsSent = m.packetsSent;
                    }
                    if (m.roundTripTimeMs != null && m.hasOwnProperty("roundTripTimeMs")) {
                        if (typeof m.roundTripTimeMs === "number")
                            d.roundTripTimeMs = o.longs === String ? String(m.roundTripTimeMs) : m.roundTripTimeMs;
                        else
                            d.roundTripTimeMs = o.longs === String ? $util.Long.prototype.toString.call(m.roundTripTimeMs) : o.longs === Number ? new $util.LongBits(m.roundTripTimeMs.low >>> 0, m.roundTripTimeMs.high >>> 0).toNumber() : m.roundTripTimeMs;
                    }
                    if (m.audioLevel != null && m.hasOwnProperty("audioLevel")) {
                        d.audioLevel = m.audioLevel;
                    }
                    if (m.jitterMs != null && m.hasOwnProperty("jitterMs")) {
                        d.jitterMs = m.jitterMs;
                    }
                    if (m.trackSid != null && m.hasOwnProperty("trackSid")) {
                        d.trackSid = m.trackSid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this LocalAudioStatsMeasured to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalAudioStatsMeasured.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalAudioStatsMeasured
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.LocalAudioStatsMeasured
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalAudioStatsMeasured.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.LocalAudioStatsMeasured";
                };

                return LocalAudioStatsMeasured;
            })();

            AnalyticEvent.RemoteVideoStatsMeasured = (function() {

                /**
                 * Properties of a RemoteVideoStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IRemoteVideoStatsMeasured
                 * @property {number|null} [packetsLost] RemoteVideoStatsMeasured packetsLost
                 * @property {string|null} [codec] RemoteVideoStatsMeasured codec
                 * @property {Long|null} [bytesReceived] RemoteVideoStatsMeasured bytesReceived
                 * @property {number|null} [packetsReceived] RemoteVideoStatsMeasured packetsReceived
                 * @property {number|null} [frameRate] RemoteVideoStatsMeasured frameRate
                 * @property {streem.api.ISize|null} [dimensions] RemoteVideoStatsMeasured dimensions
                 * @property {string|null} [trackSid] RemoteVideoStatsMeasured trackSid
                 * @property {string|null} [roomSid] RemoteVideoStatsMeasured roomSid
                 */

                /**
                 * Constructs a new RemoteVideoStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a RemoteVideoStatsMeasured.
                 * @implements IRemoteVideoStatsMeasured
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IRemoteVideoStatsMeasured=} [p] Properties to set
                 */
                function RemoteVideoStatsMeasured(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RemoteVideoStatsMeasured packetsLost.
                 * @member {number} packetsLost
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.packetsLost = 0;

                /**
                 * RemoteVideoStatsMeasured codec.
                 * @member {string} codec
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.codec = "";

                /**
                 * RemoteVideoStatsMeasured bytesReceived.
                 * @member {Long} bytesReceived
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.bytesReceived = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RemoteVideoStatsMeasured packetsReceived.
                 * @member {number} packetsReceived
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.packetsReceived = 0;

                /**
                 * RemoteVideoStatsMeasured frameRate.
                 * @member {number} frameRate
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.frameRate = 0;

                /**
                 * RemoteVideoStatsMeasured dimensions.
                 * @member {streem.api.ISize|null|undefined} dimensions
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.dimensions = null;

                /**
                 * RemoteVideoStatsMeasured trackSid.
                 * @member {string} trackSid
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.trackSid = "";

                /**
                 * RemoteVideoStatsMeasured roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 */
                RemoteVideoStatsMeasured.prototype.roomSid = "";

                /**
                 * Creates a new RemoteVideoStatsMeasured instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.IRemoteVideoStatsMeasured=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.RemoteVideoStatsMeasured} RemoteVideoStatsMeasured instance
                 */
                RemoteVideoStatsMeasured.create = function create(properties) {
                    return new RemoteVideoStatsMeasured(properties);
                };

                /**
                 * Encodes the specified RemoteVideoStatsMeasured message. Does not implicitly {@link streem.api.AnalyticEvent.RemoteVideoStatsMeasured.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.IRemoteVideoStatsMeasured} m RemoteVideoStatsMeasured message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoteVideoStatsMeasured.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.packetsLost != null && Object.hasOwnProperty.call(m, "packetsLost"))
                        w.uint32(8).int32(m.packetsLost);
                    if (m.codec != null && Object.hasOwnProperty.call(m, "codec"))
                        w.uint32(18).string(m.codec);
                    if (m.bytesReceived != null && Object.hasOwnProperty.call(m, "bytesReceived"))
                        w.uint32(24).int64(m.bytesReceived);
                    if (m.packetsReceived != null && Object.hasOwnProperty.call(m, "packetsReceived"))
                        w.uint32(32).int32(m.packetsReceived);
                    if (m.frameRate != null && Object.hasOwnProperty.call(m, "frameRate"))
                        w.uint32(40).int32(m.frameRate);
                    if (m.dimensions != null && Object.hasOwnProperty.call(m, "dimensions"))
                        $root.streem.api.Size.encode(m.dimensions, w.uint32(50).fork()).ldelim();
                    if (m.trackSid != null && Object.hasOwnProperty.call(m, "trackSid"))
                        w.uint32(58).string(m.trackSid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(66).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a RemoteVideoStatsMeasured message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.RemoteVideoStatsMeasured} RemoteVideoStatsMeasured
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoteVideoStatsMeasured.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.packetsLost = r.int32();
                                break;
                            }
                        case 2: {
                                m.codec = r.string();
                                break;
                            }
                        case 3: {
                                m.bytesReceived = r.int64();
                                break;
                            }
                        case 4: {
                                m.packetsReceived = r.int32();
                                break;
                            }
                        case 5: {
                                m.frameRate = r.int32();
                                break;
                            }
                        case 6: {
                                m.dimensions = $root.streem.api.Size.decode(r, r.uint32());
                                break;
                            }
                        case 7: {
                                m.trackSid = r.string();
                                break;
                            }
                        case 8: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RemoteVideoStatsMeasured message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.RemoteVideoStatsMeasured} RemoteVideoStatsMeasured
                 */
                RemoteVideoStatsMeasured.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.RemoteVideoStatsMeasured();
                    if (d.packetsLost != null) {
                        m.packetsLost = d.packetsLost | 0;
                    }
                    if (d.codec != null) {
                        m.codec = String(d.codec);
                    }
                    if (d.bytesReceived != null) {
                        if ($util.Long)
                            (m.bytesReceived = $util.Long.fromValue(d.bytesReceived)).unsigned = false;
                        else if (typeof d.bytesReceived === "string")
                            m.bytesReceived = parseInt(d.bytesReceived, 10);
                        else if (typeof d.bytesReceived === "number")
                            m.bytesReceived = d.bytesReceived;
                        else if (typeof d.bytesReceived === "object")
                            m.bytesReceived = new $util.LongBits(d.bytesReceived.low >>> 0, d.bytesReceived.high >>> 0).toNumber();
                    }
                    if (d.packetsReceived != null) {
                        m.packetsReceived = d.packetsReceived | 0;
                    }
                    if (d.frameRate != null) {
                        m.frameRate = d.frameRate | 0;
                    }
                    if (d.dimensions != null) {
                        if (typeof d.dimensions !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.RemoteVideoStatsMeasured.dimensions: object expected");
                        m.dimensions = $root.streem.api.Size.fromObject(d.dimensions);
                    }
                    if (d.trackSid != null) {
                        m.trackSid = String(d.trackSid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RemoteVideoStatsMeasured message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.RemoteVideoStatsMeasured} m RemoteVideoStatsMeasured
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoteVideoStatsMeasured.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.packetsLost = 0;
                        d.codec = "";
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.bytesReceived = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.bytesReceived = o.longs === String ? "0" : 0;
                        d.packetsReceived = 0;
                        d.frameRate = 0;
                        d.dimensions = null;
                        d.trackSid = "";
                        d.roomSid = "";
                    }
                    if (m.packetsLost != null && m.hasOwnProperty("packetsLost")) {
                        d.packetsLost = m.packetsLost;
                    }
                    if (m.codec != null && m.hasOwnProperty("codec")) {
                        d.codec = m.codec;
                    }
                    if (m.bytesReceived != null && m.hasOwnProperty("bytesReceived")) {
                        if (typeof m.bytesReceived === "number")
                            d.bytesReceived = o.longs === String ? String(m.bytesReceived) : m.bytesReceived;
                        else
                            d.bytesReceived = o.longs === String ? $util.Long.prototype.toString.call(m.bytesReceived) : o.longs === Number ? new $util.LongBits(m.bytesReceived.low >>> 0, m.bytesReceived.high >>> 0).toNumber() : m.bytesReceived;
                    }
                    if (m.packetsReceived != null && m.hasOwnProperty("packetsReceived")) {
                        d.packetsReceived = m.packetsReceived;
                    }
                    if (m.frameRate != null && m.hasOwnProperty("frameRate")) {
                        d.frameRate = m.frameRate;
                    }
                    if (m.dimensions != null && m.hasOwnProperty("dimensions")) {
                        d.dimensions = $root.streem.api.Size.toObject(m.dimensions, o);
                    }
                    if (m.trackSid != null && m.hasOwnProperty("trackSid")) {
                        d.trackSid = m.trackSid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this RemoteVideoStatsMeasured to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoteVideoStatsMeasured.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RemoteVideoStatsMeasured
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.RemoteVideoStatsMeasured
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoteVideoStatsMeasured.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.RemoteVideoStatsMeasured";
                };

                return RemoteVideoStatsMeasured;
            })();

            AnalyticEvent.RemoteAudioStatsMeasured = (function() {

                /**
                 * Properties of a RemoteAudioStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IRemoteAudioStatsMeasured
                 * @property {number|null} [packetsLost] RemoteAudioStatsMeasured packetsLost
                 * @property {string|null} [codec] RemoteAudioStatsMeasured codec
                 * @property {Long|null} [bytesReceived] RemoteAudioStatsMeasured bytesReceived
                 * @property {number|null} [packetsReceived] RemoteAudioStatsMeasured packetsReceived
                 * @property {number|null} [audioLevel] RemoteAudioStatsMeasured audioLevel
                 * @property {number|null} [jitterMs] RemoteAudioStatsMeasured jitterMs
                 * @property {string|null} [trackSid] RemoteAudioStatsMeasured trackSid
                 * @property {string|null} [roomSid] RemoteAudioStatsMeasured roomSid
                 */

                /**
                 * Constructs a new RemoteAudioStatsMeasured.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a RemoteAudioStatsMeasured.
                 * @implements IRemoteAudioStatsMeasured
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IRemoteAudioStatsMeasured=} [p] Properties to set
                 */
                function RemoteAudioStatsMeasured(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RemoteAudioStatsMeasured packetsLost.
                 * @member {number} packetsLost
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.packetsLost = 0;

                /**
                 * RemoteAudioStatsMeasured codec.
                 * @member {string} codec
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.codec = "";

                /**
                 * RemoteAudioStatsMeasured bytesReceived.
                 * @member {Long} bytesReceived
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.bytesReceived = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RemoteAudioStatsMeasured packetsReceived.
                 * @member {number} packetsReceived
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.packetsReceived = 0;

                /**
                 * RemoteAudioStatsMeasured audioLevel.
                 * @member {number} audioLevel
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.audioLevel = 0;

                /**
                 * RemoteAudioStatsMeasured jitterMs.
                 * @member {number} jitterMs
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.jitterMs = 0;

                /**
                 * RemoteAudioStatsMeasured trackSid.
                 * @member {string} trackSid
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.trackSid = "";

                /**
                 * RemoteAudioStatsMeasured roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 */
                RemoteAudioStatsMeasured.prototype.roomSid = "";

                /**
                 * Creates a new RemoteAudioStatsMeasured instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.IRemoteAudioStatsMeasured=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.RemoteAudioStatsMeasured} RemoteAudioStatsMeasured instance
                 */
                RemoteAudioStatsMeasured.create = function create(properties) {
                    return new RemoteAudioStatsMeasured(properties);
                };

                /**
                 * Encodes the specified RemoteAudioStatsMeasured message. Does not implicitly {@link streem.api.AnalyticEvent.RemoteAudioStatsMeasured.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.IRemoteAudioStatsMeasured} m RemoteAudioStatsMeasured message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoteAudioStatsMeasured.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.packetsLost != null && Object.hasOwnProperty.call(m, "packetsLost"))
                        w.uint32(8).int32(m.packetsLost);
                    if (m.codec != null && Object.hasOwnProperty.call(m, "codec"))
                        w.uint32(18).string(m.codec);
                    if (m.bytesReceived != null && Object.hasOwnProperty.call(m, "bytesReceived"))
                        w.uint32(24).int64(m.bytesReceived);
                    if (m.packetsReceived != null && Object.hasOwnProperty.call(m, "packetsReceived"))
                        w.uint32(32).int32(m.packetsReceived);
                    if (m.audioLevel != null && Object.hasOwnProperty.call(m, "audioLevel"))
                        w.uint32(40).int32(m.audioLevel);
                    if (m.jitterMs != null && Object.hasOwnProperty.call(m, "jitterMs"))
                        w.uint32(48).int32(m.jitterMs);
                    if (m.trackSid != null && Object.hasOwnProperty.call(m, "trackSid"))
                        w.uint32(58).string(m.trackSid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(66).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a RemoteAudioStatsMeasured message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.RemoteAudioStatsMeasured} RemoteAudioStatsMeasured
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoteAudioStatsMeasured.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.packetsLost = r.int32();
                                break;
                            }
                        case 2: {
                                m.codec = r.string();
                                break;
                            }
                        case 3: {
                                m.bytesReceived = r.int64();
                                break;
                            }
                        case 4: {
                                m.packetsReceived = r.int32();
                                break;
                            }
                        case 5: {
                                m.audioLevel = r.int32();
                                break;
                            }
                        case 6: {
                                m.jitterMs = r.int32();
                                break;
                            }
                        case 7: {
                                m.trackSid = r.string();
                                break;
                            }
                        case 8: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RemoteAudioStatsMeasured message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.RemoteAudioStatsMeasured} RemoteAudioStatsMeasured
                 */
                RemoteAudioStatsMeasured.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.RemoteAudioStatsMeasured();
                    if (d.packetsLost != null) {
                        m.packetsLost = d.packetsLost | 0;
                    }
                    if (d.codec != null) {
                        m.codec = String(d.codec);
                    }
                    if (d.bytesReceived != null) {
                        if ($util.Long)
                            (m.bytesReceived = $util.Long.fromValue(d.bytesReceived)).unsigned = false;
                        else if (typeof d.bytesReceived === "string")
                            m.bytesReceived = parseInt(d.bytesReceived, 10);
                        else if (typeof d.bytesReceived === "number")
                            m.bytesReceived = d.bytesReceived;
                        else if (typeof d.bytesReceived === "object")
                            m.bytesReceived = new $util.LongBits(d.bytesReceived.low >>> 0, d.bytesReceived.high >>> 0).toNumber();
                    }
                    if (d.packetsReceived != null) {
                        m.packetsReceived = d.packetsReceived | 0;
                    }
                    if (d.audioLevel != null) {
                        m.audioLevel = d.audioLevel | 0;
                    }
                    if (d.jitterMs != null) {
                        m.jitterMs = d.jitterMs | 0;
                    }
                    if (d.trackSid != null) {
                        m.trackSid = String(d.trackSid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RemoteAudioStatsMeasured message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {streem.api.AnalyticEvent.RemoteAudioStatsMeasured} m RemoteAudioStatsMeasured
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoteAudioStatsMeasured.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.packetsLost = 0;
                        d.codec = "";
                        if ($util.Long) {
                            var n = new $util.Long(0, 0, false);
                            d.bytesReceived = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                        } else
                            d.bytesReceived = o.longs === String ? "0" : 0;
                        d.packetsReceived = 0;
                        d.audioLevel = 0;
                        d.jitterMs = 0;
                        d.trackSid = "";
                        d.roomSid = "";
                    }
                    if (m.packetsLost != null && m.hasOwnProperty("packetsLost")) {
                        d.packetsLost = m.packetsLost;
                    }
                    if (m.codec != null && m.hasOwnProperty("codec")) {
                        d.codec = m.codec;
                    }
                    if (m.bytesReceived != null && m.hasOwnProperty("bytesReceived")) {
                        if (typeof m.bytesReceived === "number")
                            d.bytesReceived = o.longs === String ? String(m.bytesReceived) : m.bytesReceived;
                        else
                            d.bytesReceived = o.longs === String ? $util.Long.prototype.toString.call(m.bytesReceived) : o.longs === Number ? new $util.LongBits(m.bytesReceived.low >>> 0, m.bytesReceived.high >>> 0).toNumber() : m.bytesReceived;
                    }
                    if (m.packetsReceived != null && m.hasOwnProperty("packetsReceived")) {
                        d.packetsReceived = m.packetsReceived;
                    }
                    if (m.audioLevel != null && m.hasOwnProperty("audioLevel")) {
                        d.audioLevel = m.audioLevel;
                    }
                    if (m.jitterMs != null && m.hasOwnProperty("jitterMs")) {
                        d.jitterMs = m.jitterMs;
                    }
                    if (m.trackSid != null && m.hasOwnProperty("trackSid")) {
                        d.trackSid = m.trackSid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this RemoteAudioStatsMeasured to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoteAudioStatsMeasured.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RemoteAudioStatsMeasured
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.RemoteAudioStatsMeasured
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoteAudioStatsMeasured.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.RemoteAudioStatsMeasured";
                };

                return RemoteAudioStatsMeasured;
            })();

            AnalyticEvent.CameraSubjectChangeRequested = (function() {

                /**
                 * Properties of a CameraSubjectChangeRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ICameraSubjectChangeRequested
                 * @property {string|null} [roomSid] CameraSubjectChangeRequested roomSid
                 * @property {CameraSubject|null} [toSubject] CameraSubjectChangeRequested toSubject
                 */

                /**
                 * Constructs a new CameraSubjectChangeRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a CameraSubjectChangeRequested.
                 * @implements ICameraSubjectChangeRequested
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRequested=} [p] Properties to set
                 */
                function CameraSubjectChangeRequested(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CameraSubjectChangeRequested roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @instance
                 */
                CameraSubjectChangeRequested.prototype.roomSid = "";

                /**
                 * CameraSubjectChangeRequested toSubject.
                 * @member {CameraSubject} toSubject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @instance
                 */
                CameraSubjectChangeRequested.prototype.toSubject = 0;

                /**
                 * Creates a new CameraSubjectChangeRequested instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRequested=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRequested} CameraSubjectChangeRequested instance
                 */
                CameraSubjectChangeRequested.create = function create(properties) {
                    return new CameraSubjectChangeRequested(properties);
                };

                /**
                 * Encodes the specified CameraSubjectChangeRequested message. Does not implicitly {@link streem.api.AnalyticEvent.CameraSubjectChangeRequested.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRequested} m CameraSubjectChangeRequested message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CameraSubjectChangeRequested.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.toSubject != null && Object.hasOwnProperty.call(m, "toSubject"))
                        w.uint32(16).int32(m.toSubject);
                    return w;
                };

                /**
                 * Decodes a CameraSubjectChangeRequested message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRequested} CameraSubjectChangeRequested
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CameraSubjectChangeRequested.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.toSubject = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CameraSubjectChangeRequested message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRequested} CameraSubjectChangeRequested
                 */
                CameraSubjectChangeRequested.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeRequested();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.toSubject) {
                    default:
                        if (typeof d.toSubject === "number") {
                            m.toSubject = d.toSubject;
                            break;
                        }
                        break;
                    case "CAMERA_SUBJECT_WORLD":
                    case 0:
                        m.toSubject = 0;
                        break;
                    case "CAMERA_SUBJECT_PERSON":
                    case 1:
                        m.toSubject = 1;
                        break;
                    case "CAMERA_SUBJECT_SCREEN":
                    case 2:
                        m.toSubject = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CameraSubjectChangeRequested message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.CameraSubjectChangeRequested} m CameraSubjectChangeRequested
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CameraSubjectChangeRequested.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.toSubject = o.enums === String ? "CAMERA_SUBJECT_WORLD" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.toSubject != null && m.hasOwnProperty("toSubject")) {
                        d.toSubject = o.enums === String ? $root.CameraSubject[m.toSubject] === undefined ? m.toSubject : $root.CameraSubject[m.toSubject] : m.toSubject;
                    }
                    return d;
                };

                /**
                 * Converts this CameraSubjectChangeRequested to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CameraSubjectChangeRequested.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CameraSubjectChangeRequested
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRequested
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CameraSubjectChangeRequested.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.CameraSubjectChangeRequested";
                };

                return CameraSubjectChangeRequested;
            })();

            AnalyticEvent.CameraSubjectChangeRejected = (function() {

                /**
                 * Properties of a CameraSubjectChangeRejected.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ICameraSubjectChangeRejected
                 * @property {string|null} [roomSid] CameraSubjectChangeRejected roomSid
                 */

                /**
                 * Constructs a new CameraSubjectChangeRejected.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a CameraSubjectChangeRejected.
                 * @implements ICameraSubjectChangeRejected
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRejected=} [p] Properties to set
                 */
                function CameraSubjectChangeRejected(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CameraSubjectChangeRejected roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @instance
                 */
                CameraSubjectChangeRejected.prototype.roomSid = "";

                /**
                 * Creates a new CameraSubjectChangeRejected instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRejected=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRejected} CameraSubjectChangeRejected instance
                 */
                CameraSubjectChangeRejected.create = function create(properties) {
                    return new CameraSubjectChangeRejected(properties);
                };

                /**
                 * Encodes the specified CameraSubjectChangeRejected message. Does not implicitly {@link streem.api.AnalyticEvent.CameraSubjectChangeRejected.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeRejected} m CameraSubjectChangeRejected message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CameraSubjectChangeRejected.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a CameraSubjectChangeRejected message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRejected} CameraSubjectChangeRejected
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CameraSubjectChangeRejected.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CameraSubjectChangeRejected message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeRejected} CameraSubjectChangeRejected
                 */
                CameraSubjectChangeRejected.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeRejected();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CameraSubjectChangeRejected message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.CameraSubjectChangeRejected} m CameraSubjectChangeRejected
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CameraSubjectChangeRejected.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this CameraSubjectChangeRejected to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CameraSubjectChangeRejected.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CameraSubjectChangeRejected
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeRejected
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CameraSubjectChangeRejected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.CameraSubjectChangeRejected";
                };

                return CameraSubjectChangeRejected;
            })();

            AnalyticEvent.CameraSubjectChangeAborted = (function() {

                /**
                 * Properties of a CameraSubjectChangeAborted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ICameraSubjectChangeAborted
                 * @property {string|null} [roomSid] CameraSubjectChangeAborted roomSid
                 */

                /**
                 * Constructs a new CameraSubjectChangeAborted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a CameraSubjectChangeAborted.
                 * @implements ICameraSubjectChangeAborted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeAborted=} [p] Properties to set
                 */
                function CameraSubjectChangeAborted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CameraSubjectChangeAborted roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @instance
                 */
                CameraSubjectChangeAborted.prototype.roomSid = "";

                /**
                 * Creates a new CameraSubjectChangeAborted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeAborted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeAborted} CameraSubjectChangeAborted instance
                 */
                CameraSubjectChangeAborted.create = function create(properties) {
                    return new CameraSubjectChangeAborted(properties);
                };

                /**
                 * Encodes the specified CameraSubjectChangeAborted message. Does not implicitly {@link streem.api.AnalyticEvent.CameraSubjectChangeAborted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectChangeAborted} m CameraSubjectChangeAborted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CameraSubjectChangeAborted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    return w;
                };

                /**
                 * Decodes a CameraSubjectChangeAborted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeAborted} CameraSubjectChangeAborted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CameraSubjectChangeAborted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CameraSubjectChangeAborted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.CameraSubjectChangeAborted} CameraSubjectChangeAborted
                 */
                CameraSubjectChangeAborted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.CameraSubjectChangeAborted();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CameraSubjectChangeAborted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {streem.api.AnalyticEvent.CameraSubjectChangeAborted} m CameraSubjectChangeAborted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CameraSubjectChangeAborted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    return d;
                };

                /**
                 * Converts this CameraSubjectChangeAborted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CameraSubjectChangeAborted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CameraSubjectChangeAborted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.CameraSubjectChangeAborted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CameraSubjectChangeAborted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.CameraSubjectChangeAborted";
                };

                return CameraSubjectChangeAborted;
            })();

            AnalyticEvent.CameraSubjectSet = (function() {

                /**
                 * Properties of a CameraSubjectSet.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ICameraSubjectSet
                 * @property {string|null} [roomSid] CameraSubjectSet roomSid
                 * @property {CameraSubject|null} [subject] CameraSubjectSet subject
                 */

                /**
                 * Constructs a new CameraSubjectSet.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a CameraSubjectSet.
                 * @implements ICameraSubjectSet
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ICameraSubjectSet=} [p] Properties to set
                 */
                function CameraSubjectSet(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CameraSubjectSet roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @instance
                 */
                CameraSubjectSet.prototype.roomSid = "";

                /**
                 * CameraSubjectSet subject.
                 * @member {CameraSubject} subject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @instance
                 */
                CameraSubjectSet.prototype.subject = 0;

                /**
                 * Creates a new CameraSubjectSet instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectSet=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.CameraSubjectSet} CameraSubjectSet instance
                 */
                CameraSubjectSet.create = function create(properties) {
                    return new CameraSubjectSet(properties);
                };

                /**
                 * Encodes the specified CameraSubjectSet message. Does not implicitly {@link streem.api.AnalyticEvent.CameraSubjectSet.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraSubjectSet} m CameraSubjectSet message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CameraSubjectSet.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.subject != null && Object.hasOwnProperty.call(m, "subject"))
                        w.uint32(16).int32(m.subject);
                    return w;
                };

                /**
                 * Decodes a CameraSubjectSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.CameraSubjectSet} CameraSubjectSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CameraSubjectSet.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.CameraSubjectSet();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.subject = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CameraSubjectSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.CameraSubjectSet} CameraSubjectSet
                 */
                CameraSubjectSet.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.CameraSubjectSet)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.CameraSubjectSet();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.subject) {
                    default:
                        if (typeof d.subject === "number") {
                            m.subject = d.subject;
                            break;
                        }
                        break;
                    case "CAMERA_SUBJECT_WORLD":
                    case 0:
                        m.subject = 0;
                        break;
                    case "CAMERA_SUBJECT_PERSON":
                    case 1:
                        m.subject = 1;
                        break;
                    case "CAMERA_SUBJECT_SCREEN":
                    case 2:
                        m.subject = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CameraSubjectSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {streem.api.AnalyticEvent.CameraSubjectSet} m CameraSubjectSet
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CameraSubjectSet.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.subject = o.enums === String ? "CAMERA_SUBJECT_WORLD" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.subject != null && m.hasOwnProperty("subject")) {
                        d.subject = o.enums === String ? $root.CameraSubject[m.subject] === undefined ? m.subject : $root.CameraSubject[m.subject] : m.subject;
                    }
                    return d;
                };

                /**
                 * Converts this CameraSubjectSet to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CameraSubjectSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CameraSubjectSet
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.CameraSubjectSet
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CameraSubjectSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.CameraSubjectSet";
                };

                return CameraSubjectSet;
            })();

            AnalyticEvent.CameraStatusSet = (function() {

                /**
                 * Properties of a CameraStatusSet.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ICameraStatusSet
                 * @property {string|null} [roomSid] CameraStatusSet roomSid
                 * @property {boolean|null} [enabled] CameraStatusSet enabled
                 */

                /**
                 * Constructs a new CameraStatusSet.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a CameraStatusSet.
                 * @implements ICameraStatusSet
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ICameraStatusSet=} [p] Properties to set
                 */
                function CameraStatusSet(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CameraStatusSet roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @instance
                 */
                CameraStatusSet.prototype.roomSid = "";

                /**
                 * CameraStatusSet enabled.
                 * @member {boolean} enabled
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @instance
                 */
                CameraStatusSet.prototype.enabled = false;

                /**
                 * Creates a new CameraStatusSet instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraStatusSet=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.CameraStatusSet} CameraStatusSet instance
                 */
                CameraStatusSet.create = function create(properties) {
                    return new CameraStatusSet(properties);
                };

                /**
                 * Encodes the specified CameraStatusSet message. Does not implicitly {@link streem.api.AnalyticEvent.CameraStatusSet.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.ICameraStatusSet} m CameraStatusSet message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CameraStatusSet.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.enabled != null && Object.hasOwnProperty.call(m, "enabled"))
                        w.uint32(16).bool(m.enabled);
                    return w;
                };

                /**
                 * Decodes a CameraStatusSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.CameraStatusSet} CameraStatusSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CameraStatusSet.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.CameraStatusSet();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.enabled = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CameraStatusSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.CameraStatusSet} CameraStatusSet
                 */
                CameraStatusSet.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.CameraStatusSet)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.CameraStatusSet();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    if (d.enabled != null) {
                        m.enabled = Boolean(d.enabled);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CameraStatusSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.CameraStatusSet} m CameraStatusSet
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CameraStatusSet.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.enabled = false;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.enabled != null && m.hasOwnProperty("enabled")) {
                        d.enabled = m.enabled;
                    }
                    return d;
                };

                /**
                 * Converts this CameraStatusSet to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CameraStatusSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CameraStatusSet
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.CameraStatusSet
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CameraStatusSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.CameraStatusSet";
                };

                return CameraStatusSet;
            })();

            AnalyticEvent.MicrophoneStatusSet = (function() {

                /**
                 * Properties of a MicrophoneStatusSet.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IMicrophoneStatusSet
                 * @property {string|null} [roomSid] MicrophoneStatusSet roomSid
                 * @property {boolean|null} [enabled] MicrophoneStatusSet enabled
                 */

                /**
                 * Constructs a new MicrophoneStatusSet.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a MicrophoneStatusSet.
                 * @implements IMicrophoneStatusSet
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IMicrophoneStatusSet=} [p] Properties to set
                 */
                function MicrophoneStatusSet(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * MicrophoneStatusSet roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @instance
                 */
                MicrophoneStatusSet.prototype.roomSid = "";

                /**
                 * MicrophoneStatusSet enabled.
                 * @member {boolean} enabled
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @instance
                 */
                MicrophoneStatusSet.prototype.enabled = false;

                /**
                 * Creates a new MicrophoneStatusSet instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.IMicrophoneStatusSet=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.MicrophoneStatusSet} MicrophoneStatusSet instance
                 */
                MicrophoneStatusSet.create = function create(properties) {
                    return new MicrophoneStatusSet(properties);
                };

                /**
                 * Encodes the specified MicrophoneStatusSet message. Does not implicitly {@link streem.api.AnalyticEvent.MicrophoneStatusSet.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.IMicrophoneStatusSet} m MicrophoneStatusSet message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MicrophoneStatusSet.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.enabled != null && Object.hasOwnProperty.call(m, "enabled"))
                        w.uint32(16).bool(m.enabled);
                    return w;
                };

                /**
                 * Decodes a MicrophoneStatusSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.MicrophoneStatusSet} MicrophoneStatusSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MicrophoneStatusSet.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.MicrophoneStatusSet();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.enabled = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a MicrophoneStatusSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.MicrophoneStatusSet} MicrophoneStatusSet
                 */
                MicrophoneStatusSet.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.MicrophoneStatusSet)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.MicrophoneStatusSet();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    if (d.enabled != null) {
                        m.enabled = Boolean(d.enabled);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a MicrophoneStatusSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {streem.api.AnalyticEvent.MicrophoneStatusSet} m MicrophoneStatusSet
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MicrophoneStatusSet.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.enabled = false;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.enabled != null && m.hasOwnProperty("enabled")) {
                        d.enabled = m.enabled;
                    }
                    return d;
                };

                /**
                 * Converts this MicrophoneStatusSet to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MicrophoneStatusSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MicrophoneStatusSet
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.MicrophoneStatusSet
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MicrophoneStatusSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.MicrophoneStatusSet";
                };

                return MicrophoneStatusSet;
            })();

            /**
             * CallStatus enum.
             * @name streem.api.AnalyticEvent.CallStatus
             * @enum {number}
             * @property {number} CALL_STATUS_INVALID=0 CALL_STATUS_INVALID value
             * @property {number} CALL_STATUS_NO_CALL=1 CALL_STATUS_NO_CALL value
             * @property {number} CALL_STATUS_IN_CALL=2 CALL_STATUS_IN_CALL value
             * @property {number} CALL_STATUS_POST_CALL=3 CALL_STATUS_POST_CALL value
             */
            AnalyticEvent.CallStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CALL_STATUS_INVALID"] = 0;
                values[valuesById[1] = "CALL_STATUS_NO_CALL"] = 1;
                values[valuesById[2] = "CALL_STATUS_IN_CALL"] = 2;
                values[valuesById[3] = "CALL_STATUS_POST_CALL"] = 3;
                return values;
            })();

            AnalyticEvent.Tool = (function() {

                /**
                 * Properties of a Tool.
                 * @memberof streem.api.AnalyticEvent
                 * @interface ITool
                 */

                /**
                 * Constructs a new Tool.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a Tool.
                 * @implements ITool
                 * @constructor
                 * @param {streem.api.AnalyticEvent.ITool=} [p] Properties to set
                 */
                function Tool(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new Tool instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {streem.api.AnalyticEvent.ITool=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.Tool} Tool instance
                 */
                Tool.create = function create(properties) {
                    return new Tool(properties);
                };

                /**
                 * Encodes the specified Tool message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {streem.api.AnalyticEvent.ITool} m Tool message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tool.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a Tool message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.Tool} Tool
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tool.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Tool message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.Tool} Tool
                 */
                Tool.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.Tool)
                        return d;
                    return new $root.streem.api.AnalyticEvent.Tool();
                };

                /**
                 * Creates a plain object from a Tool message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {streem.api.AnalyticEvent.Tool} m Tool
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tool.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Tool to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tool.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Tool
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.Tool
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Tool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool";
                };

                Tool.Laser = (function() {

                    /**
                     * Properties of a Laser.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface ILaser
                     * @property {google.protobuf.IDuration|null} [duration] Laser duration
                     */

                    /**
                     * Constructs a new Laser.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Laser.
                     * @implements ILaser
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.ILaser=} [p] Properties to set
                     */
                    function Laser(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Laser duration.
                     * @member {google.protobuf.IDuration|null|undefined} duration
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @instance
                     */
                    Laser.prototype.duration = null;

                    /**
                     * Creates a new Laser instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.ILaser=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Laser} Laser instance
                     */
                    Laser.create = function create(properties) {
                        return new Laser(properties);
                    };

                    /**
                     * Encodes the specified Laser message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Laser.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.ILaser} m Laser message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Laser.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                            $root.google.protobuf.Duration.encode(m.duration, w.uint32(10).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Laser message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Laser} Laser
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Laser.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Laser();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.duration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Laser message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Laser} Laser
                     */
                    Laser.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Laser)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Laser();
                        if (d.duration != null) {
                            if (typeof d.duration !== "object")
                                throw TypeError(".streem.api.AnalyticEvent.Tool.Laser.duration: object expected");
                            m.duration = $root.google.protobuf.Duration.fromObject(d.duration);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Laser message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Laser} m Laser
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Laser.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.duration = null;
                        }
                        if (m.duration != null && m.hasOwnProperty("duration")) {
                            d.duration = $root.google.protobuf.Duration.toObject(m.duration, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Laser to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Laser.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Laser
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Laser
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Laser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Laser";
                    };

                    return Laser;
                })();

                Tool.Draw = (function() {

                    /**
                     * Properties of a Draw.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IDraw
                     * @property {google.protobuf.IDuration|null} [duration] Draw duration
                     */

                    /**
                     * Constructs a new Draw.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Draw.
                     * @implements IDraw
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IDraw=} [p] Properties to set
                     */
                    function Draw(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Draw duration.
                     * @member {google.protobuf.IDuration|null|undefined} duration
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @instance
                     */
                    Draw.prototype.duration = null;

                    /**
                     * Creates a new Draw instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IDraw=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Draw} Draw instance
                     */
                    Draw.create = function create(properties) {
                        return new Draw(properties);
                    };

                    /**
                     * Encodes the specified Draw message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Draw.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IDraw} m Draw message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Draw.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                            $root.google.protobuf.Duration.encode(m.duration, w.uint32(10).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Draw message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Draw} Draw
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Draw.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Draw();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.duration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Draw message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Draw} Draw
                     */
                    Draw.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Draw)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Draw();
                        if (d.duration != null) {
                            if (typeof d.duration !== "object")
                                throw TypeError(".streem.api.AnalyticEvent.Tool.Draw.duration: object expected");
                            m.duration = $root.google.protobuf.Duration.fromObject(d.duration);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Draw message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Draw} m Draw
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Draw.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.duration = null;
                        }
                        if (m.duration != null && m.hasOwnProperty("duration")) {
                            d.duration = $root.google.protobuf.Duration.toObject(m.duration, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Draw to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Draw.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Draw
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Draw
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Draw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Draw";
                    };

                    return Draw;
                })();

                Tool.Flashlight = (function() {

                    /**
                     * Properties of a Flashlight.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IFlashlight
                     * @property {google.protobuf.IDuration|null} [duration] Flashlight duration
                     */

                    /**
                     * Constructs a new Flashlight.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Flashlight.
                     * @implements IFlashlight
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IFlashlight=} [p] Properties to set
                     */
                    function Flashlight(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Flashlight duration.
                     * @member {google.protobuf.IDuration|null|undefined} duration
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @instance
                     */
                    Flashlight.prototype.duration = null;

                    /**
                     * Creates a new Flashlight instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IFlashlight=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Flashlight} Flashlight instance
                     */
                    Flashlight.create = function create(properties) {
                        return new Flashlight(properties);
                    };

                    /**
                     * Encodes the specified Flashlight message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Flashlight.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IFlashlight} m Flashlight message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Flashlight.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                            $root.google.protobuf.Duration.encode(m.duration, w.uint32(10).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Flashlight message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Flashlight} Flashlight
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Flashlight.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Flashlight();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.duration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Flashlight message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Flashlight} Flashlight
                     */
                    Flashlight.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Flashlight)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Flashlight();
                        if (d.duration != null) {
                            if (typeof d.duration !== "object")
                                throw TypeError(".streem.api.AnalyticEvent.Tool.Flashlight.duration: object expected");
                            m.duration = $root.google.protobuf.Duration.fromObject(d.duration);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Flashlight message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Flashlight} m Flashlight
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Flashlight.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.duration = null;
                        }
                        if (m.duration != null && m.hasOwnProperty("duration")) {
                            d.duration = $root.google.protobuf.Duration.toObject(m.duration, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Flashlight to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Flashlight.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Flashlight
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Flashlight
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Flashlight.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Flashlight";
                    };

                    return Flashlight;
                })();

                Tool.Arrow = (function() {

                    /**
                     * Properties of an Arrow.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IArrow
                     * @property {string|null} [elementId] Arrow elementId
                     */

                    /**
                     * Constructs a new Arrow.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents an Arrow.
                     * @implements IArrow
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IArrow=} [p] Properties to set
                     */
                    function Arrow(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Arrow elementId.
                     * @member {string} elementId
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @instance
                     */
                    Arrow.prototype.elementId = "";

                    /**
                     * Creates a new Arrow instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IArrow=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Arrow} Arrow instance
                     */
                    Arrow.create = function create(properties) {
                        return new Arrow(properties);
                    };

                    /**
                     * Encodes the specified Arrow message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Arrow.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IArrow} m Arrow message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Arrow.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(10).string(m.elementId);
                        return w;
                    };

                    /**
                     * Decodes an Arrow message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Arrow} Arrow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Arrow.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Arrow();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.elementId = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an Arrow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Arrow} Arrow
                     */
                    Arrow.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Arrow)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Arrow();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an Arrow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Arrow} m Arrow
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Arrow.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        return d;
                    };

                    /**
                     * Converts this Arrow to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Arrow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Arrow
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Arrow
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Arrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Arrow";
                    };

                    return Arrow;
                })();

                Tool.MeasurementAnchor = (function() {

                    /**
                     * Properties of a MeasurementAnchor.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IMeasurementAnchor
                     * @property {string|null} [elementId] MeasurementAnchor elementId
                     */

                    /**
                     * Constructs a new MeasurementAnchor.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a MeasurementAnchor.
                     * @implements IMeasurementAnchor
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IMeasurementAnchor=} [p] Properties to set
                     */
                    function MeasurementAnchor(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * MeasurementAnchor elementId.
                     * @member {string} elementId
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @instance
                     */
                    MeasurementAnchor.prototype.elementId = "";

                    /**
                     * Creates a new MeasurementAnchor instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IMeasurementAnchor=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.MeasurementAnchor} MeasurementAnchor instance
                     */
                    MeasurementAnchor.create = function create(properties) {
                        return new MeasurementAnchor(properties);
                    };

                    /**
                     * Encodes the specified MeasurementAnchor message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.MeasurementAnchor.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IMeasurementAnchor} m MeasurementAnchor message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MeasurementAnchor.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(10).string(m.elementId);
                        return w;
                    };

                    /**
                     * Decodes a MeasurementAnchor message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.MeasurementAnchor} MeasurementAnchor
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MeasurementAnchor.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.elementId = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a MeasurementAnchor message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.MeasurementAnchor} MeasurementAnchor
                     */
                    MeasurementAnchor.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a MeasurementAnchor message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.MeasurementAnchor} m MeasurementAnchor
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MeasurementAnchor.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        return d;
                    };

                    /**
                     * Converts this MeasurementAnchor to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MeasurementAnchor.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for MeasurementAnchor
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.MeasurementAnchor
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    MeasurementAnchor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.MeasurementAnchor";
                    };

                    return MeasurementAnchor;
                })();

                Tool.Measure = (function() {

                    /**
                     * Properties of a Measure.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IMeasure
                     * @property {string|null} [elementId] Measure elementId
                     * @property {number|null} [lengthMeters] Measure lengthMeters
                     */

                    /**
                     * Constructs a new Measure.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Measure.
                     * @implements IMeasure
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IMeasure=} [p] Properties to set
                     */
                    function Measure(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Measure elementId.
                     * @member {string} elementId
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @instance
                     */
                    Measure.prototype.elementId = "";

                    /**
                     * Measure lengthMeters.
                     * @member {number} lengthMeters
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @instance
                     */
                    Measure.prototype.lengthMeters = 0;

                    /**
                     * Creates a new Measure instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IMeasure=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Measure} Measure instance
                     */
                    Measure.create = function create(properties) {
                        return new Measure(properties);
                    };

                    /**
                     * Encodes the specified Measure message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Measure.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IMeasure} m Measure message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Measure.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(10).string(m.elementId);
                        if (m.lengthMeters != null && Object.hasOwnProperty.call(m, "lengthMeters"))
                            w.uint32(21).float(m.lengthMeters);
                        return w;
                    };

                    /**
                     * Decodes a Measure message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Measure} Measure
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Measure.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Measure();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.elementId = r.string();
                                    break;
                                }
                            case 2: {
                                    m.lengthMeters = r.float();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Measure message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Measure} Measure
                     */
                    Measure.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Measure)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Measure();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        if (d.lengthMeters != null) {
                            m.lengthMeters = Number(d.lengthMeters);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Measure message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Measure} m Measure
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Measure.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                            d.lengthMeters = 0;
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        if (m.lengthMeters != null && m.hasOwnProperty("lengthMeters")) {
                            d.lengthMeters = o.json && !isFinite(m.lengthMeters) ? String(m.lengthMeters) : m.lengthMeters;
                        }
                        return d;
                    };

                    /**
                     * Converts this Measure to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Measure.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Measure
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Measure
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Measure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Measure";
                    };

                    return Measure;
                })();

                Tool.Text = (function() {

                    /**
                     * Properties of a Text.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IText
                     * @property {string|null} [elementId] Text elementId
                     * @property {number|null} [charCount] Text charCount
                     */

                    /**
                     * Constructs a new Text.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Text.
                     * @implements IText
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IText=} [p] Properties to set
                     */
                    function Text(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Text elementId.
                     * @member {string} elementId
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @instance
                     */
                    Text.prototype.elementId = "";

                    /**
                     * Text charCount.
                     * @member {number} charCount
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @instance
                     */
                    Text.prototype.charCount = 0;

                    /**
                     * Creates a new Text instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IText=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Text} Text instance
                     */
                    Text.create = function create(properties) {
                        return new Text(properties);
                    };

                    /**
                     * Encodes the specified Text message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Text.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IText} m Text message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Text.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(10).string(m.elementId);
                        if (m.charCount != null && Object.hasOwnProperty.call(m, "charCount"))
                            w.uint32(16).int32(m.charCount);
                        return w;
                    };

                    /**
                     * Decodes a Text message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Text} Text
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Text.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Text();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.elementId = r.string();
                                    break;
                                }
                            case 2: {
                                    m.charCount = r.int32();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Text message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Text} Text
                     */
                    Text.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Text)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Text();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        if (d.charCount != null) {
                            m.charCount = d.charCount | 0;
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Text message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Text} m Text
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Text.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                            d.charCount = 0;
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        if (m.charCount != null && m.hasOwnProperty("charCount")) {
                            d.charCount = m.charCount;
                        }
                        return d;
                    };

                    /**
                     * Converts this Text to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Text.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Text
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Text
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Text";
                    };

                    return Text;
                })();

                Tool.Model3d = (function() {

                    /**
                     * Properties of a Model3d.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @interface IModel3d
                     * @property {string|null} [elementId] Model3d elementId
                     * @property {string|null} [modelId] Model3d modelId
                     */

                    /**
                     * Constructs a new Model3d.
                     * @memberof streem.api.AnalyticEvent.Tool
                     * @classdesc Represents a Model3d.
                     * @implements IModel3d
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Tool.IModel3d=} [p] Properties to set
                     */
                    function Model3d(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Model3d elementId.
                     * @member {string} elementId
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @instance
                     */
                    Model3d.prototype.elementId = "";

                    /**
                     * Model3d modelId.
                     * @member {string} modelId
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @instance
                     */
                    Model3d.prototype.modelId = "";

                    /**
                     * Creates a new Model3d instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IModel3d=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Tool.Model3d} Model3d instance
                     */
                    Model3d.create = function create(properties) {
                        return new Model3d(properties);
                    };

                    /**
                     * Encodes the specified Model3d message. Does not implicitly {@link streem.api.AnalyticEvent.Tool.Model3d.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.IModel3d} m Model3d message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Model3d.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                            w.uint32(10).string(m.elementId);
                        if (m.modelId != null && Object.hasOwnProperty.call(m, "modelId"))
                            w.uint32(18).string(m.modelId);
                        return w;
                    };

                    /**
                     * Decodes a Model3d message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Tool.Model3d} Model3d
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Model3d.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Tool.Model3d();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.elementId = r.string();
                                    break;
                                }
                            case 2: {
                                    m.modelId = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Model3d message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Tool.Model3d} Model3d
                     */
                    Model3d.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Tool.Model3d)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Tool.Model3d();
                        if (d.elementId != null) {
                            m.elementId = String(d.elementId);
                        }
                        if (d.modelId != null) {
                            m.modelId = String(d.modelId);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Model3d message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {streem.api.AnalyticEvent.Tool.Model3d} m Model3d
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Model3d.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.elementId = "";
                            d.modelId = "";
                        }
                        if (m.elementId != null && m.hasOwnProperty("elementId")) {
                            d.elementId = m.elementId;
                        }
                        if (m.modelId != null && m.hasOwnProperty("modelId")) {
                            d.modelId = m.modelId;
                        }
                        return d;
                    };

                    /**
                     * Converts this Model3d to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Model3d.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Model3d
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Tool.Model3d
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Model3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Tool.Model3d";
                    };

                    return Model3d;
                })();

                return Tool;
            })();

            AnalyticEvent.ToolPlaced = (function() {

                /**
                 * Properties of a ToolPlaced.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IToolPlaced
                 * @property {string|null} [roomSid] ToolPlaced roomSid
                 * @property {streem.api.AnalyticEvent.CallStatus|null} [callStatus] ToolPlaced callStatus
                 * @property {streem.api.AnalyticEvent.Tool.ILaser|null} [laser] ToolPlaced laser
                 * @property {streem.api.AnalyticEvent.Tool.IDraw|null} [draw] ToolPlaced draw
                 * @property {streem.api.AnalyticEvent.Tool.IFlashlight|null} [flashlight] ToolPlaced flashlight
                 * @property {streem.api.AnalyticEvent.Tool.IArrow|null} [arrow] ToolPlaced arrow
                 * @property {streem.api.AnalyticEvent.Tool.IMeasure|null} [measure] ToolPlaced measure
                 * @property {streem.api.AnalyticEvent.Tool.IText|null} [text] ToolPlaced text
                 * @property {streem.api.AnalyticEvent.Tool.IModel3d|null} [model_3d] ToolPlaced model_3d
                 * @property {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null} [measureAnchor] ToolPlaced measureAnchor
                 */

                /**
                 * Constructs a new ToolPlaced.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ToolPlaced.
                 * @implements IToolPlaced
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IToolPlaced=} [p] Properties to set
                 */
                function ToolPlaced(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ToolPlaced roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.roomSid = "";

                /**
                 * ToolPlaced callStatus.
                 * @member {streem.api.AnalyticEvent.CallStatus} callStatus
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.callStatus = 0;

                /**
                 * ToolPlaced laser.
                 * @member {streem.api.AnalyticEvent.Tool.ILaser|null|undefined} laser
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.laser = null;

                /**
                 * ToolPlaced draw.
                 * @member {streem.api.AnalyticEvent.Tool.IDraw|null|undefined} draw
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.draw = null;

                /**
                 * ToolPlaced flashlight.
                 * @member {streem.api.AnalyticEvent.Tool.IFlashlight|null|undefined} flashlight
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.flashlight = null;

                /**
                 * ToolPlaced arrow.
                 * @member {streem.api.AnalyticEvent.Tool.IArrow|null|undefined} arrow
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.arrow = null;

                /**
                 * ToolPlaced measure.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasure|null|undefined} measure
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.measure = null;

                /**
                 * ToolPlaced text.
                 * @member {streem.api.AnalyticEvent.Tool.IText|null|undefined} text
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.text = null;

                /**
                 * ToolPlaced model_3d.
                 * @member {streem.api.AnalyticEvent.Tool.IModel3d|null|undefined} model_3d
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.model_3d = null;

                /**
                 * ToolPlaced measureAnchor.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null|undefined} measureAnchor
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                ToolPlaced.prototype.measureAnchor = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ToolPlaced tool.
                 * @member {"laser"|"draw"|"flashlight"|"arrow"|"measure"|"text"|"model_3d"|"measureAnchor"|undefined} tool
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 */
                Object.defineProperty(ToolPlaced.prototype, "tool", {
                    get: $util.oneOfGetter($oneOfFields = ["laser", "draw", "flashlight", "arrow", "measure", "text", "model_3d", "measureAnchor"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ToolPlaced instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolPlaced=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ToolPlaced} ToolPlaced instance
                 */
                ToolPlaced.create = function create(properties) {
                    return new ToolPlaced(properties);
                };

                /**
                 * Encodes the specified ToolPlaced message. Does not implicitly {@link streem.api.AnalyticEvent.ToolPlaced.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolPlaced} m ToolPlaced message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ToolPlaced.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.callStatus != null && Object.hasOwnProperty.call(m, "callStatus"))
                        w.uint32(16).int32(m.callStatus);
                    if (m.laser != null && Object.hasOwnProperty.call(m, "laser"))
                        $root.streem.api.AnalyticEvent.Tool.Laser.encode(m.laser, w.uint32(802).fork()).ldelim();
                    if (m.draw != null && Object.hasOwnProperty.call(m, "draw"))
                        $root.streem.api.AnalyticEvent.Tool.Draw.encode(m.draw, w.uint32(810).fork()).ldelim();
                    if (m.flashlight != null && Object.hasOwnProperty.call(m, "flashlight"))
                        $root.streem.api.AnalyticEvent.Tool.Flashlight.encode(m.flashlight, w.uint32(818).fork()).ldelim();
                    if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
                        $root.streem.api.AnalyticEvent.Tool.Arrow.encode(m.arrow, w.uint32(1602).fork()).ldelim();
                    if (m.measure != null && Object.hasOwnProperty.call(m, "measure"))
                        $root.streem.api.AnalyticEvent.Tool.Measure.encode(m.measure, w.uint32(1610).fork()).ldelim();
                    if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                        $root.streem.api.AnalyticEvent.Tool.Text.encode(m.text, w.uint32(1618).fork()).ldelim();
                    if (m.model_3d != null && Object.hasOwnProperty.call(m, "model_3d"))
                        $root.streem.api.AnalyticEvent.Tool.Model3d.encode(m.model_3d, w.uint32(1626).fork()).ldelim();
                    if (m.measureAnchor != null && Object.hasOwnProperty.call(m, "measureAnchor"))
                        $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.encode(m.measureAnchor, w.uint32(1634).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a ToolPlaced message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ToolPlaced} ToolPlaced
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ToolPlaced.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ToolPlaced();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.callStatus = r.int32();
                                break;
                            }
                        case 100: {
                                m.laser = $root.streem.api.AnalyticEvent.Tool.Laser.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.draw = $root.streem.api.AnalyticEvent.Tool.Draw.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.flashlight = $root.streem.api.AnalyticEvent.Tool.Flashlight.decode(r, r.uint32());
                                break;
                            }
                        case 200: {
                                m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.decode(r, r.uint32());
                                break;
                            }
                        case 201: {
                                m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.decode(r, r.uint32());
                                break;
                            }
                        case 202: {
                                m.text = $root.streem.api.AnalyticEvent.Tool.Text.decode(r, r.uint32());
                                break;
                            }
                        case 203: {
                                m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.decode(r, r.uint32());
                                break;
                            }
                        case 204: {
                                m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ToolPlaced message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ToolPlaced} ToolPlaced
                 */
                ToolPlaced.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ToolPlaced)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ToolPlaced();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.callStatus) {
                    default:
                        if (typeof d.callStatus === "number") {
                            m.callStatus = d.callStatus;
                            break;
                        }
                        break;
                    case "CALL_STATUS_INVALID":
                    case 0:
                        m.callStatus = 0;
                        break;
                    case "CALL_STATUS_NO_CALL":
                    case 1:
                        m.callStatus = 1;
                        break;
                    case "CALL_STATUS_IN_CALL":
                    case 2:
                        m.callStatus = 2;
                        break;
                    case "CALL_STATUS_POST_CALL":
                    case 3:
                        m.callStatus = 3;
                        break;
                    }
                    if (d.laser != null) {
                        if (typeof d.laser !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.laser: object expected");
                        m.laser = $root.streem.api.AnalyticEvent.Tool.Laser.fromObject(d.laser);
                    }
                    if (d.draw != null) {
                        if (typeof d.draw !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.draw: object expected");
                        m.draw = $root.streem.api.AnalyticEvent.Tool.Draw.fromObject(d.draw);
                    }
                    if (d.flashlight != null) {
                        if (typeof d.flashlight !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.flashlight: object expected");
                        m.flashlight = $root.streem.api.AnalyticEvent.Tool.Flashlight.fromObject(d.flashlight);
                    }
                    if (d.arrow != null) {
                        if (typeof d.arrow !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.arrow: object expected");
                        m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.fromObject(d.arrow);
                    }
                    if (d.measure != null) {
                        if (typeof d.measure !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.measure: object expected");
                        m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.fromObject(d.measure);
                    }
                    if (d.text != null) {
                        if (typeof d.text !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.text: object expected");
                        m.text = $root.streem.api.AnalyticEvent.Tool.Text.fromObject(d.text);
                    }
                    if (d.model_3d != null) {
                        if (typeof d.model_3d !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.model_3d: object expected");
                        m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.fromObject(d.model_3d);
                    }
                    if (d.measureAnchor != null) {
                        if (typeof d.measureAnchor !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolPlaced.measureAnchor: object expected");
                        m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.fromObject(d.measureAnchor);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ToolPlaced message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {streem.api.AnalyticEvent.ToolPlaced} m ToolPlaced
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ToolPlaced.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.callStatus = o.enums === String ? "CALL_STATUS_INVALID" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.callStatus != null && m.hasOwnProperty("callStatus")) {
                        d.callStatus = o.enums === String ? $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] === undefined ? m.callStatus : $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] : m.callStatus;
                    }
                    if (m.laser != null && m.hasOwnProperty("laser")) {
                        d.laser = $root.streem.api.AnalyticEvent.Tool.Laser.toObject(m.laser, o);
                        if (o.oneofs)
                            d.tool = "laser";
                    }
                    if (m.draw != null && m.hasOwnProperty("draw")) {
                        d.draw = $root.streem.api.AnalyticEvent.Tool.Draw.toObject(m.draw, o);
                        if (o.oneofs)
                            d.tool = "draw";
                    }
                    if (m.flashlight != null && m.hasOwnProperty("flashlight")) {
                        d.flashlight = $root.streem.api.AnalyticEvent.Tool.Flashlight.toObject(m.flashlight, o);
                        if (o.oneofs)
                            d.tool = "flashlight";
                    }
                    if (m.arrow != null && m.hasOwnProperty("arrow")) {
                        d.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.toObject(m.arrow, o);
                        if (o.oneofs)
                            d.tool = "arrow";
                    }
                    if (m.measure != null && m.hasOwnProperty("measure")) {
                        d.measure = $root.streem.api.AnalyticEvent.Tool.Measure.toObject(m.measure, o);
                        if (o.oneofs)
                            d.tool = "measure";
                    }
                    if (m.text != null && m.hasOwnProperty("text")) {
                        d.text = $root.streem.api.AnalyticEvent.Tool.Text.toObject(m.text, o);
                        if (o.oneofs)
                            d.tool = "text";
                    }
                    if (m.model_3d != null && m.hasOwnProperty("model_3d")) {
                        d.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.toObject(m.model_3d, o);
                        if (o.oneofs)
                            d.tool = "model_3d";
                    }
                    if (m.measureAnchor != null && m.hasOwnProperty("measureAnchor")) {
                        d.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.toObject(m.measureAnchor, o);
                        if (o.oneofs)
                            d.tool = "measureAnchor";
                    }
                    return d;
                };

                /**
                 * Converts this ToolPlaced to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ToolPlaced.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ToolPlaced
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ToolPlaced
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ToolPlaced.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ToolPlaced";
                };

                return ToolPlaced;
            })();

            AnalyticEvent.ToolMoved = (function() {

                /**
                 * Properties of a ToolMoved.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IToolMoved
                 * @property {string|null} [roomSid] ToolMoved roomSid
                 * @property {streem.api.AnalyticEvent.CallStatus|null} [callStatus] ToolMoved callStatus
                 * @property {streem.api.AnalyticEvent.Tool.IArrow|null} [arrow] ToolMoved arrow
                 * @property {streem.api.AnalyticEvent.Tool.IMeasure|null} [measure] ToolMoved measure
                 * @property {streem.api.AnalyticEvent.Tool.IText|null} [text] ToolMoved text
                 * @property {streem.api.AnalyticEvent.Tool.IModel3d|null} [model_3d] ToolMoved model_3d
                 * @property {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null} [measureAnchor] ToolMoved measureAnchor
                 */

                /**
                 * Constructs a new ToolMoved.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ToolMoved.
                 * @implements IToolMoved
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IToolMoved=} [p] Properties to set
                 */
                function ToolMoved(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ToolMoved roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.roomSid = "";

                /**
                 * ToolMoved callStatus.
                 * @member {streem.api.AnalyticEvent.CallStatus} callStatus
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.callStatus = 0;

                /**
                 * ToolMoved arrow.
                 * @member {streem.api.AnalyticEvent.Tool.IArrow|null|undefined} arrow
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.arrow = null;

                /**
                 * ToolMoved measure.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasure|null|undefined} measure
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.measure = null;

                /**
                 * ToolMoved text.
                 * @member {streem.api.AnalyticEvent.Tool.IText|null|undefined} text
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.text = null;

                /**
                 * ToolMoved model_3d.
                 * @member {streem.api.AnalyticEvent.Tool.IModel3d|null|undefined} model_3d
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.model_3d = null;

                /**
                 * ToolMoved measureAnchor.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null|undefined} measureAnchor
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                ToolMoved.prototype.measureAnchor = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ToolMoved tool.
                 * @member {"arrow"|"measure"|"text"|"model_3d"|"measureAnchor"|undefined} tool
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 */
                Object.defineProperty(ToolMoved.prototype, "tool", {
                    get: $util.oneOfGetter($oneOfFields = ["arrow", "measure", "text", "model_3d", "measureAnchor"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ToolMoved instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolMoved=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ToolMoved} ToolMoved instance
                 */
                ToolMoved.create = function create(properties) {
                    return new ToolMoved(properties);
                };

                /**
                 * Encodes the specified ToolMoved message. Does not implicitly {@link streem.api.AnalyticEvent.ToolMoved.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolMoved} m ToolMoved message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ToolMoved.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.callStatus != null && Object.hasOwnProperty.call(m, "callStatus"))
                        w.uint32(16).int32(m.callStatus);
                    if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
                        $root.streem.api.AnalyticEvent.Tool.Arrow.encode(m.arrow, w.uint32(802).fork()).ldelim();
                    if (m.measure != null && Object.hasOwnProperty.call(m, "measure"))
                        $root.streem.api.AnalyticEvent.Tool.Measure.encode(m.measure, w.uint32(810).fork()).ldelim();
                    if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                        $root.streem.api.AnalyticEvent.Tool.Text.encode(m.text, w.uint32(818).fork()).ldelim();
                    if (m.model_3d != null && Object.hasOwnProperty.call(m, "model_3d"))
                        $root.streem.api.AnalyticEvent.Tool.Model3d.encode(m.model_3d, w.uint32(826).fork()).ldelim();
                    if (m.measureAnchor != null && Object.hasOwnProperty.call(m, "measureAnchor"))
                        $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.encode(m.measureAnchor, w.uint32(834).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a ToolMoved message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ToolMoved} ToolMoved
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ToolMoved.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ToolMoved();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.callStatus = r.int32();
                                break;
                            }
                        case 100: {
                                m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.text = $root.streem.api.AnalyticEvent.Tool.Text.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.decode(r, r.uint32());
                                break;
                            }
                        case 104: {
                                m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ToolMoved message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ToolMoved} ToolMoved
                 */
                ToolMoved.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ToolMoved)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ToolMoved();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.callStatus) {
                    default:
                        if (typeof d.callStatus === "number") {
                            m.callStatus = d.callStatus;
                            break;
                        }
                        break;
                    case "CALL_STATUS_INVALID":
                    case 0:
                        m.callStatus = 0;
                        break;
                    case "CALL_STATUS_NO_CALL":
                    case 1:
                        m.callStatus = 1;
                        break;
                    case "CALL_STATUS_IN_CALL":
                    case 2:
                        m.callStatus = 2;
                        break;
                    case "CALL_STATUS_POST_CALL":
                    case 3:
                        m.callStatus = 3;
                        break;
                    }
                    if (d.arrow != null) {
                        if (typeof d.arrow !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolMoved.arrow: object expected");
                        m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.fromObject(d.arrow);
                    }
                    if (d.measure != null) {
                        if (typeof d.measure !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolMoved.measure: object expected");
                        m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.fromObject(d.measure);
                    }
                    if (d.text != null) {
                        if (typeof d.text !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolMoved.text: object expected");
                        m.text = $root.streem.api.AnalyticEvent.Tool.Text.fromObject(d.text);
                    }
                    if (d.model_3d != null) {
                        if (typeof d.model_3d !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolMoved.model_3d: object expected");
                        m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.fromObject(d.model_3d);
                    }
                    if (d.measureAnchor != null) {
                        if (typeof d.measureAnchor !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolMoved.measureAnchor: object expected");
                        m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.fromObject(d.measureAnchor);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ToolMoved message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {streem.api.AnalyticEvent.ToolMoved} m ToolMoved
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ToolMoved.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.callStatus = o.enums === String ? "CALL_STATUS_INVALID" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.callStatus != null && m.hasOwnProperty("callStatus")) {
                        d.callStatus = o.enums === String ? $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] === undefined ? m.callStatus : $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] : m.callStatus;
                    }
                    if (m.arrow != null && m.hasOwnProperty("arrow")) {
                        d.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.toObject(m.arrow, o);
                        if (o.oneofs)
                            d.tool = "arrow";
                    }
                    if (m.measure != null && m.hasOwnProperty("measure")) {
                        d.measure = $root.streem.api.AnalyticEvent.Tool.Measure.toObject(m.measure, o);
                        if (o.oneofs)
                            d.tool = "measure";
                    }
                    if (m.text != null && m.hasOwnProperty("text")) {
                        d.text = $root.streem.api.AnalyticEvent.Tool.Text.toObject(m.text, o);
                        if (o.oneofs)
                            d.tool = "text";
                    }
                    if (m.model_3d != null && m.hasOwnProperty("model_3d")) {
                        d.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.toObject(m.model_3d, o);
                        if (o.oneofs)
                            d.tool = "model_3d";
                    }
                    if (m.measureAnchor != null && m.hasOwnProperty("measureAnchor")) {
                        d.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.toObject(m.measureAnchor, o);
                        if (o.oneofs)
                            d.tool = "measureAnchor";
                    }
                    return d;
                };

                /**
                 * Converts this ToolMoved to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ToolMoved.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ToolMoved
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ToolMoved
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ToolMoved.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ToolMoved";
                };

                return ToolMoved;
            })();

            AnalyticEvent.ToolRemoved = (function() {

                /**
                 * Properties of a ToolRemoved.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IToolRemoved
                 * @property {string|null} [roomSid] ToolRemoved roomSid
                 * @property {streem.api.AnalyticEvent.CallStatus|null} [callStatus] ToolRemoved callStatus
                 * @property {streem.api.AnalyticEvent.Tool.IArrow|null} [arrow] ToolRemoved arrow
                 * @property {streem.api.AnalyticEvent.Tool.IMeasure|null} [measure] ToolRemoved measure
                 * @property {streem.api.AnalyticEvent.Tool.IText|null} [text] ToolRemoved text
                 * @property {streem.api.AnalyticEvent.Tool.IModel3d|null} [model_3d] ToolRemoved model_3d
                 * @property {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null} [measureAnchor] ToolRemoved measureAnchor
                 */

                /**
                 * Constructs a new ToolRemoved.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a ToolRemoved.
                 * @implements IToolRemoved
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IToolRemoved=} [p] Properties to set
                 */
                function ToolRemoved(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ToolRemoved roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.roomSid = "";

                /**
                 * ToolRemoved callStatus.
                 * @member {streem.api.AnalyticEvent.CallStatus} callStatus
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.callStatus = 0;

                /**
                 * ToolRemoved arrow.
                 * @member {streem.api.AnalyticEvent.Tool.IArrow|null|undefined} arrow
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.arrow = null;

                /**
                 * ToolRemoved measure.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasure|null|undefined} measure
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.measure = null;

                /**
                 * ToolRemoved text.
                 * @member {streem.api.AnalyticEvent.Tool.IText|null|undefined} text
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.text = null;

                /**
                 * ToolRemoved model_3d.
                 * @member {streem.api.AnalyticEvent.Tool.IModel3d|null|undefined} model_3d
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.model_3d = null;

                /**
                 * ToolRemoved measureAnchor.
                 * @member {streem.api.AnalyticEvent.Tool.IMeasurementAnchor|null|undefined} measureAnchor
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                ToolRemoved.prototype.measureAnchor = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ToolRemoved tool.
                 * @member {"arrow"|"measure"|"text"|"model_3d"|"measureAnchor"|undefined} tool
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 */
                Object.defineProperty(ToolRemoved.prototype, "tool", {
                    get: $util.oneOfGetter($oneOfFields = ["arrow", "measure", "text", "model_3d", "measureAnchor"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ToolRemoved instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolRemoved=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ToolRemoved} ToolRemoved instance
                 */
                ToolRemoved.create = function create(properties) {
                    return new ToolRemoved(properties);
                };

                /**
                 * Encodes the specified ToolRemoved message. Does not implicitly {@link streem.api.AnalyticEvent.ToolRemoved.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {streem.api.AnalyticEvent.IToolRemoved} m ToolRemoved message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ToolRemoved.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(10).string(m.roomSid);
                    if (m.callStatus != null && Object.hasOwnProperty.call(m, "callStatus"))
                        w.uint32(16).int32(m.callStatus);
                    if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
                        $root.streem.api.AnalyticEvent.Tool.Arrow.encode(m.arrow, w.uint32(802).fork()).ldelim();
                    if (m.measure != null && Object.hasOwnProperty.call(m, "measure"))
                        $root.streem.api.AnalyticEvent.Tool.Measure.encode(m.measure, w.uint32(810).fork()).ldelim();
                    if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                        $root.streem.api.AnalyticEvent.Tool.Text.encode(m.text, w.uint32(818).fork()).ldelim();
                    if (m.model_3d != null && Object.hasOwnProperty.call(m, "model_3d"))
                        $root.streem.api.AnalyticEvent.Tool.Model3d.encode(m.model_3d, w.uint32(826).fork()).ldelim();
                    if (m.measureAnchor != null && Object.hasOwnProperty.call(m, "measureAnchor"))
                        $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.encode(m.measureAnchor, w.uint32(834).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a ToolRemoved message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ToolRemoved} ToolRemoved
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ToolRemoved.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ToolRemoved();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 2: {
                                m.callStatus = r.int32();
                                break;
                            }
                        case 100: {
                                m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.text = $root.streem.api.AnalyticEvent.Tool.Text.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.decode(r, r.uint32());
                                break;
                            }
                        case 104: {
                                m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ToolRemoved message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ToolRemoved} ToolRemoved
                 */
                ToolRemoved.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ToolRemoved)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ToolRemoved();
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.callStatus) {
                    default:
                        if (typeof d.callStatus === "number") {
                            m.callStatus = d.callStatus;
                            break;
                        }
                        break;
                    case "CALL_STATUS_INVALID":
                    case 0:
                        m.callStatus = 0;
                        break;
                    case "CALL_STATUS_NO_CALL":
                    case 1:
                        m.callStatus = 1;
                        break;
                    case "CALL_STATUS_IN_CALL":
                    case 2:
                        m.callStatus = 2;
                        break;
                    case "CALL_STATUS_POST_CALL":
                    case 3:
                        m.callStatus = 3;
                        break;
                    }
                    if (d.arrow != null) {
                        if (typeof d.arrow !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolRemoved.arrow: object expected");
                        m.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.fromObject(d.arrow);
                    }
                    if (d.measure != null) {
                        if (typeof d.measure !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolRemoved.measure: object expected");
                        m.measure = $root.streem.api.AnalyticEvent.Tool.Measure.fromObject(d.measure);
                    }
                    if (d.text != null) {
                        if (typeof d.text !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolRemoved.text: object expected");
                        m.text = $root.streem.api.AnalyticEvent.Tool.Text.fromObject(d.text);
                    }
                    if (d.model_3d != null) {
                        if (typeof d.model_3d !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolRemoved.model_3d: object expected");
                        m.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.fromObject(d.model_3d);
                    }
                    if (d.measureAnchor != null) {
                        if (typeof d.measureAnchor !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ToolRemoved.measureAnchor: object expected");
                        m.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.fromObject(d.measureAnchor);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ToolRemoved message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {streem.api.AnalyticEvent.ToolRemoved} m ToolRemoved
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ToolRemoved.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.roomSid = "";
                        d.callStatus = o.enums === String ? "CALL_STATUS_INVALID" : 0;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.callStatus != null && m.hasOwnProperty("callStatus")) {
                        d.callStatus = o.enums === String ? $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] === undefined ? m.callStatus : $root.streem.api.AnalyticEvent.CallStatus[m.callStatus] : m.callStatus;
                    }
                    if (m.arrow != null && m.hasOwnProperty("arrow")) {
                        d.arrow = $root.streem.api.AnalyticEvent.Tool.Arrow.toObject(m.arrow, o);
                        if (o.oneofs)
                            d.tool = "arrow";
                    }
                    if (m.measure != null && m.hasOwnProperty("measure")) {
                        d.measure = $root.streem.api.AnalyticEvent.Tool.Measure.toObject(m.measure, o);
                        if (o.oneofs)
                            d.tool = "measure";
                    }
                    if (m.text != null && m.hasOwnProperty("text")) {
                        d.text = $root.streem.api.AnalyticEvent.Tool.Text.toObject(m.text, o);
                        if (o.oneofs)
                            d.tool = "text";
                    }
                    if (m.model_3d != null && m.hasOwnProperty("model_3d")) {
                        d.model_3d = $root.streem.api.AnalyticEvent.Tool.Model3d.toObject(m.model_3d, o);
                        if (o.oneofs)
                            d.tool = "model_3d";
                    }
                    if (m.measureAnchor != null && m.hasOwnProperty("measureAnchor")) {
                        d.measureAnchor = $root.streem.api.AnalyticEvent.Tool.MeasurementAnchor.toObject(m.measureAnchor, o);
                        if (o.oneofs)
                            d.tool = "measureAnchor";
                    }
                    return d;
                };

                /**
                 * Converts this ToolRemoved to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ToolRemoved.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ToolRemoved
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ToolRemoved
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ToolRemoved.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ToolRemoved";
                };

                return ToolRemoved;
            })();

            AnalyticEvent.Artifact = (function() {

                /**
                 * Properties of an Artifact.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifact
                 * @property {string|null} [artifactSid] Artifact artifactSid
                 * @property {string|null} [roomSid] Artifact roomSid
                 * @property {streem.api.AnalyticEvent.Artifact.IStreemshot|null} [streemshot] Artifact streemshot
                 * @property {streem.api.AnalyticEvent.Artifact.IMesh|null} [mesh] Artifact mesh
                 * @property {streem.api.AnalyticEvent.Artifact.IGps|null} [gps] Artifact gps
                 * @property {streem.api.AnalyticEvent.Artifact.IRecording|null} [recording] Artifact recording
                 * @property {streem.api.AnalyticEvent.Artifact.ISceneshot|null} [sceneshot] Artifact sceneshot
                 * @property {streem.api.AnalyticEvent.Artifact.ILayoutEstimation|null} [layoutEstimation] Artifact layoutEstimation
                 * @property {streem.api.AnalyticEvent.Artifact.IRecordingTrack|null} [recordingTrack] Artifact recordingTrack
                 * @property {streem.api.AnalyticEvent.Artifact.ITranscription|null} [transcription] Artifact transcription
                 * @property {streem.api.AnalyticEvent.Artifact.ITexturedMesh|null} [texturedMesh] Artifact texturedMesh
                 * @property {streem.api.AnalyticEvent.Artifact.IBookmark|null} [bookmark] Artifact bookmark
                 */

                /**
                 * Constructs a new Artifact.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an Artifact.
                 * @implements IArtifact
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifact=} [p] Properties to set
                 */
                function Artifact(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Artifact artifactSid.
                 * @member {string} artifactSid
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.artifactSid = "";

                /**
                 * Artifact roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.roomSid = "";

                /**
                 * Artifact streemshot.
                 * @member {streem.api.AnalyticEvent.Artifact.IStreemshot|null|undefined} streemshot
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.streemshot = null;

                /**
                 * Artifact mesh.
                 * @member {streem.api.AnalyticEvent.Artifact.IMesh|null|undefined} mesh
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.mesh = null;

                /**
                 * Artifact gps.
                 * @member {streem.api.AnalyticEvent.Artifact.IGps|null|undefined} gps
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.gps = null;

                /**
                 * Artifact recording.
                 * @member {streem.api.AnalyticEvent.Artifact.IRecording|null|undefined} recording
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.recording = null;

                /**
                 * Artifact sceneshot.
                 * @member {streem.api.AnalyticEvent.Artifact.ISceneshot|null|undefined} sceneshot
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.sceneshot = null;

                /**
                 * Artifact layoutEstimation.
                 * @member {streem.api.AnalyticEvent.Artifact.ILayoutEstimation|null|undefined} layoutEstimation
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.layoutEstimation = null;

                /**
                 * Artifact recordingTrack.
                 * @member {streem.api.AnalyticEvent.Artifact.IRecordingTrack|null|undefined} recordingTrack
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.recordingTrack = null;

                /**
                 * Artifact transcription.
                 * @member {streem.api.AnalyticEvent.Artifact.ITranscription|null|undefined} transcription
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.transcription = null;

                /**
                 * Artifact texturedMesh.
                 * @member {streem.api.AnalyticEvent.Artifact.ITexturedMesh|null|undefined} texturedMesh
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.texturedMesh = null;

                /**
                 * Artifact bookmark.
                 * @member {streem.api.AnalyticEvent.Artifact.IBookmark|null|undefined} bookmark
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Artifact.prototype.bookmark = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Artifact type.
                 * @member {"streemshot"|"mesh"|"gps"|"recording"|"sceneshot"|"layoutEstimation"|"recordingTrack"|"transcription"|"texturedMesh"|"bookmark"|undefined} type
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 */
                Object.defineProperty(Artifact.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["streemshot", "mesh", "gps", "recording", "sceneshot", "layoutEstimation", "recordingTrack", "transcription", "texturedMesh", "bookmark"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Artifact instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifact=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.Artifact} Artifact instance
                 */
                Artifact.create = function create(properties) {
                    return new Artifact(properties);
                };

                /**
                 * Encodes the specified Artifact message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifact} m Artifact message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Artifact.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifactSid != null && Object.hasOwnProperty.call(m, "artifactSid"))
                        w.uint32(10).string(m.artifactSid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(18).string(m.roomSid);
                    if (m.streemshot != null && Object.hasOwnProperty.call(m, "streemshot"))
                        $root.streem.api.AnalyticEvent.Artifact.Streemshot.encode(m.streemshot, w.uint32(802).fork()).ldelim();
                    if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                        $root.streem.api.AnalyticEvent.Artifact.Mesh.encode(m.mesh, w.uint32(810).fork()).ldelim();
                    if (m.gps != null && Object.hasOwnProperty.call(m, "gps"))
                        $root.streem.api.AnalyticEvent.Artifact.Gps.encode(m.gps, w.uint32(818).fork()).ldelim();
                    if (m.recording != null && Object.hasOwnProperty.call(m, "recording"))
                        $root.streem.api.AnalyticEvent.Artifact.Recording.encode(m.recording, w.uint32(826).fork()).ldelim();
                    if (m.sceneshot != null && Object.hasOwnProperty.call(m, "sceneshot"))
                        $root.streem.api.AnalyticEvent.Artifact.Sceneshot.encode(m.sceneshot, w.uint32(834).fork()).ldelim();
                    if (m.layoutEstimation != null && Object.hasOwnProperty.call(m, "layoutEstimation"))
                        $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation.encode(m.layoutEstimation, w.uint32(842).fork()).ldelim();
                    if (m.recordingTrack != null && Object.hasOwnProperty.call(m, "recordingTrack"))
                        $root.streem.api.AnalyticEvent.Artifact.RecordingTrack.encode(m.recordingTrack, w.uint32(850).fork()).ldelim();
                    if (m.transcription != null && Object.hasOwnProperty.call(m, "transcription"))
                        $root.streem.api.AnalyticEvent.Artifact.Transcription.encode(m.transcription, w.uint32(858).fork()).ldelim();
                    if (m.texturedMesh != null && Object.hasOwnProperty.call(m, "texturedMesh"))
                        $root.streem.api.AnalyticEvent.Artifact.TexturedMesh.encode(m.texturedMesh, w.uint32(866).fork()).ldelim();
                    if (m.bookmark != null && Object.hasOwnProperty.call(m, "bookmark"))
                        $root.streem.api.AnalyticEvent.Artifact.Bookmark.encode(m.bookmark, w.uint32(874).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an Artifact message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.Artifact} Artifact
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Artifact.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifactSid = r.string();
                                break;
                            }
                        case 2: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 100: {
                                m.streemshot = $root.streem.api.AnalyticEvent.Artifact.Streemshot.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.mesh = $root.streem.api.AnalyticEvent.Artifact.Mesh.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.gps = $root.streem.api.AnalyticEvent.Artifact.Gps.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m.recording = $root.streem.api.AnalyticEvent.Artifact.Recording.decode(r, r.uint32());
                                break;
                            }
                        case 104: {
                                m.sceneshot = $root.streem.api.AnalyticEvent.Artifact.Sceneshot.decode(r, r.uint32());
                                break;
                            }
                        case 105: {
                                m.layoutEstimation = $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation.decode(r, r.uint32());
                                break;
                            }
                        case 106: {
                                m.recordingTrack = $root.streem.api.AnalyticEvent.Artifact.RecordingTrack.decode(r, r.uint32());
                                break;
                            }
                        case 107: {
                                m.transcription = $root.streem.api.AnalyticEvent.Artifact.Transcription.decode(r, r.uint32());
                                break;
                            }
                        case 108: {
                                m.texturedMesh = $root.streem.api.AnalyticEvent.Artifact.TexturedMesh.decode(r, r.uint32());
                                break;
                            }
                        case 109: {
                                m.bookmark = $root.streem.api.AnalyticEvent.Artifact.Bookmark.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Artifact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.Artifact} Artifact
                 */
                Artifact.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.Artifact)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.Artifact();
                    if (d.artifactSid != null) {
                        m.artifactSid = String(d.artifactSid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    if (d.streemshot != null) {
                        if (typeof d.streemshot !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.streemshot: object expected");
                        m.streemshot = $root.streem.api.AnalyticEvent.Artifact.Streemshot.fromObject(d.streemshot);
                    }
                    if (d.mesh != null) {
                        if (typeof d.mesh !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.mesh: object expected");
                        m.mesh = $root.streem.api.AnalyticEvent.Artifact.Mesh.fromObject(d.mesh);
                    }
                    if (d.gps != null) {
                        if (typeof d.gps !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.gps: object expected");
                        m.gps = $root.streem.api.AnalyticEvent.Artifact.Gps.fromObject(d.gps);
                    }
                    if (d.recording != null) {
                        if (typeof d.recording !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.recording: object expected");
                        m.recording = $root.streem.api.AnalyticEvent.Artifact.Recording.fromObject(d.recording);
                    }
                    if (d.sceneshot != null) {
                        if (typeof d.sceneshot !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.sceneshot: object expected");
                        m.sceneshot = $root.streem.api.AnalyticEvent.Artifact.Sceneshot.fromObject(d.sceneshot);
                    }
                    if (d.layoutEstimation != null) {
                        if (typeof d.layoutEstimation !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.layoutEstimation: object expected");
                        m.layoutEstimation = $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation.fromObject(d.layoutEstimation);
                    }
                    if (d.recordingTrack != null) {
                        if (typeof d.recordingTrack !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.recordingTrack: object expected");
                        m.recordingTrack = $root.streem.api.AnalyticEvent.Artifact.RecordingTrack.fromObject(d.recordingTrack);
                    }
                    if (d.transcription != null) {
                        if (typeof d.transcription !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.transcription: object expected");
                        m.transcription = $root.streem.api.AnalyticEvent.Artifact.Transcription.fromObject(d.transcription);
                    }
                    if (d.texturedMesh != null) {
                        if (typeof d.texturedMesh !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.texturedMesh: object expected");
                        m.texturedMesh = $root.streem.api.AnalyticEvent.Artifact.TexturedMesh.fromObject(d.texturedMesh);
                    }
                    if (d.bookmark != null) {
                        if (typeof d.bookmark !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.Artifact.bookmark: object expected");
                        m.bookmark = $root.streem.api.AnalyticEvent.Artifact.Bookmark.fromObject(d.bookmark);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Artifact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {streem.api.AnalyticEvent.Artifact} m Artifact
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Artifact.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifactSid = "";
                        d.roomSid = "";
                    }
                    if (m.artifactSid != null && m.hasOwnProperty("artifactSid")) {
                        d.artifactSid = m.artifactSid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.streemshot != null && m.hasOwnProperty("streemshot")) {
                        d.streemshot = $root.streem.api.AnalyticEvent.Artifact.Streemshot.toObject(m.streemshot, o);
                        if (o.oneofs)
                            d.type = "streemshot";
                    }
                    if (m.mesh != null && m.hasOwnProperty("mesh")) {
                        d.mesh = $root.streem.api.AnalyticEvent.Artifact.Mesh.toObject(m.mesh, o);
                        if (o.oneofs)
                            d.type = "mesh";
                    }
                    if (m.gps != null && m.hasOwnProperty("gps")) {
                        d.gps = $root.streem.api.AnalyticEvent.Artifact.Gps.toObject(m.gps, o);
                        if (o.oneofs)
                            d.type = "gps";
                    }
                    if (m.recording != null && m.hasOwnProperty("recording")) {
                        d.recording = $root.streem.api.AnalyticEvent.Artifact.Recording.toObject(m.recording, o);
                        if (o.oneofs)
                            d.type = "recording";
                    }
                    if (m.sceneshot != null && m.hasOwnProperty("sceneshot")) {
                        d.sceneshot = $root.streem.api.AnalyticEvent.Artifact.Sceneshot.toObject(m.sceneshot, o);
                        if (o.oneofs)
                            d.type = "sceneshot";
                    }
                    if (m.layoutEstimation != null && m.hasOwnProperty("layoutEstimation")) {
                        d.layoutEstimation = $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation.toObject(m.layoutEstimation, o);
                        if (o.oneofs)
                            d.type = "layoutEstimation";
                    }
                    if (m.recordingTrack != null && m.hasOwnProperty("recordingTrack")) {
                        d.recordingTrack = $root.streem.api.AnalyticEvent.Artifact.RecordingTrack.toObject(m.recordingTrack, o);
                        if (o.oneofs)
                            d.type = "recordingTrack";
                    }
                    if (m.transcription != null && m.hasOwnProperty("transcription")) {
                        d.transcription = $root.streem.api.AnalyticEvent.Artifact.Transcription.toObject(m.transcription, o);
                        if (o.oneofs)
                            d.type = "transcription";
                    }
                    if (m.texturedMesh != null && m.hasOwnProperty("texturedMesh")) {
                        d.texturedMesh = $root.streem.api.AnalyticEvent.Artifact.TexturedMesh.toObject(m.texturedMesh, o);
                        if (o.oneofs)
                            d.type = "texturedMesh";
                    }
                    if (m.bookmark != null && m.hasOwnProperty("bookmark")) {
                        d.bookmark = $root.streem.api.AnalyticEvent.Artifact.Bookmark.toObject(m.bookmark, o);
                        if (o.oneofs)
                            d.type = "bookmark";
                    }
                    return d;
                };

                /**
                 * Converts this Artifact to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Artifact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Artifact
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.Artifact
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Artifact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact";
                };

                Artifact.Gps = (function() {

                    /**
                     * Properties of a Gps.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IGps
                     */

                    /**
                     * Constructs a new Gps.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Gps.
                     * @implements IGps
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IGps=} [p] Properties to set
                     */
                    function Gps(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Gps instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IGps=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Gps} Gps instance
                     */
                    Gps.create = function create(properties) {
                        return new Gps(properties);
                    };

                    /**
                     * Encodes the specified Gps message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Gps.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IGps} m Gps message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Gps.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Gps message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Gps} Gps
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Gps.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Gps();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Gps message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Gps} Gps
                     */
                    Gps.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Gps)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Gps();
                    };

                    /**
                     * Creates a plain object from a Gps message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Gps} m Gps
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Gps.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Gps to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Gps.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Gps
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Gps
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Gps.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Gps";
                    };

                    return Gps;
                })();

                Artifact.Recording = (function() {

                    /**
                     * Properties of a Recording.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IRecording
                     * @property {google.protobuf.IDuration|null} [duration] Recording duration
                     */

                    /**
                     * Constructs a new Recording.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Recording.
                     * @implements IRecording
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IRecording=} [p] Properties to set
                     */
                    function Recording(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Recording duration.
                     * @member {google.protobuf.IDuration|null|undefined} duration
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @instance
                     */
                    Recording.prototype.duration = null;

                    /**
                     * Creates a new Recording instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IRecording=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Recording} Recording instance
                     */
                    Recording.create = function create(properties) {
                        return new Recording(properties);
                    };

                    /**
                     * Encodes the specified Recording message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Recording.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IRecording} m Recording message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Recording.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                            $root.google.protobuf.Duration.encode(m.duration, w.uint32(10).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Recording message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Recording} Recording
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Recording.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Recording();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.duration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Recording message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Recording} Recording
                     */
                    Recording.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Recording)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.Artifact.Recording();
                        if (d.duration != null) {
                            if (typeof d.duration !== "object")
                                throw TypeError(".streem.api.AnalyticEvent.Artifact.Recording.duration: object expected");
                            m.duration = $root.google.protobuf.Duration.fromObject(d.duration);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Recording message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Recording} m Recording
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Recording.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.duration = null;
                        }
                        if (m.duration != null && m.hasOwnProperty("duration")) {
                            d.duration = $root.google.protobuf.Duration.toObject(m.duration, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Recording to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Recording.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Recording
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Recording
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Recording.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Recording";
                    };

                    return Recording;
                })();

                Artifact.Streemshot = (function() {

                    /**
                     * Properties of a Streemshot.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IStreemshot
                     */

                    /**
                     * Constructs a new Streemshot.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Streemshot.
                     * @implements IStreemshot
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IStreemshot=} [p] Properties to set
                     */
                    function Streemshot(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Streemshot instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IStreemshot=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Streemshot} Streemshot instance
                     */
                    Streemshot.create = function create(properties) {
                        return new Streemshot(properties);
                    };

                    /**
                     * Encodes the specified Streemshot message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Streemshot.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IStreemshot} m Streemshot message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Streemshot.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Streemshot message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Streemshot} Streemshot
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Streemshot.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Streemshot();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Streemshot message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Streemshot} Streemshot
                     */
                    Streemshot.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Streemshot)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Streemshot();
                    };

                    /**
                     * Creates a plain object from a Streemshot message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Streemshot} m Streemshot
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Streemshot.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Streemshot to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Streemshot.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Streemshot
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Streemshot
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Streemshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Streemshot";
                    };

                    return Streemshot;
                })();

                Artifact.Mesh = (function() {

                    /**
                     * Properties of a Mesh.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IMesh
                     */

                    /**
                     * Constructs a new Mesh.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Mesh.
                     * @implements IMesh
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IMesh=} [p] Properties to set
                     */
                    function Mesh(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Mesh instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IMesh=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Mesh} Mesh instance
                     */
                    Mesh.create = function create(properties) {
                        return new Mesh(properties);
                    };

                    /**
                     * Encodes the specified Mesh message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Mesh.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IMesh} m Mesh message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mesh.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Mesh message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Mesh} Mesh
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mesh.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Mesh();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Mesh message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Mesh} Mesh
                     */
                    Mesh.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Mesh)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Mesh();
                    };

                    /**
                     * Creates a plain object from a Mesh message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Mesh} m Mesh
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Mesh.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Mesh to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Mesh.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Mesh
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Mesh
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Mesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Mesh";
                    };

                    return Mesh;
                })();

                Artifact.Transcription = (function() {

                    /**
                     * Properties of a Transcription.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface ITranscription
                     */

                    /**
                     * Constructs a new Transcription.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Transcription.
                     * @implements ITranscription
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.ITranscription=} [p] Properties to set
                     */
                    function Transcription(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Transcription instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ITranscription=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Transcription} Transcription instance
                     */
                    Transcription.create = function create(properties) {
                        return new Transcription(properties);
                    };

                    /**
                     * Encodes the specified Transcription message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Transcription.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ITranscription} m Transcription message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Transcription.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Transcription message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Transcription} Transcription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Transcription.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Transcription();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Transcription message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Transcription} Transcription
                     */
                    Transcription.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Transcription)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Transcription();
                    };

                    /**
                     * Creates a plain object from a Transcription message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Transcription} m Transcription
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Transcription.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Transcription to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Transcription.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Transcription
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Transcription
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Transcription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Transcription";
                    };

                    return Transcription;
                })();

                Artifact.TexturedMesh = (function() {

                    /**
                     * Properties of a TexturedMesh.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface ITexturedMesh
                     */

                    /**
                     * Constructs a new TexturedMesh.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a TexturedMesh.
                     * @implements ITexturedMesh
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.ITexturedMesh=} [p] Properties to set
                     */
                    function TexturedMesh(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new TexturedMesh instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ITexturedMesh=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.TexturedMesh} TexturedMesh instance
                     */
                    TexturedMesh.create = function create(properties) {
                        return new TexturedMesh(properties);
                    };

                    /**
                     * Encodes the specified TexturedMesh message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.TexturedMesh.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ITexturedMesh} m TexturedMesh message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TexturedMesh.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a TexturedMesh message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.TexturedMesh} TexturedMesh
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TexturedMesh.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.TexturedMesh();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a TexturedMesh message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.TexturedMesh} TexturedMesh
                     */
                    TexturedMesh.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.TexturedMesh)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.TexturedMesh();
                    };

                    /**
                     * Creates a plain object from a TexturedMesh message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.TexturedMesh} m TexturedMesh
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TexturedMesh.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this TexturedMesh to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TexturedMesh.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TexturedMesh
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.TexturedMesh
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TexturedMesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.TexturedMesh";
                    };

                    return TexturedMesh;
                })();

                Artifact.Sceneshot = (function() {

                    /**
                     * Properties of a Sceneshot.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface ISceneshot
                     */

                    /**
                     * Constructs a new Sceneshot.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Sceneshot.
                     * @implements ISceneshot
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.ISceneshot=} [p] Properties to set
                     */
                    function Sceneshot(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Sceneshot instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ISceneshot=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Sceneshot} Sceneshot instance
                     */
                    Sceneshot.create = function create(properties) {
                        return new Sceneshot(properties);
                    };

                    /**
                     * Encodes the specified Sceneshot message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Sceneshot.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ISceneshot} m Sceneshot message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Sceneshot.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Sceneshot message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Sceneshot} Sceneshot
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Sceneshot.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Sceneshot();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Sceneshot message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Sceneshot} Sceneshot
                     */
                    Sceneshot.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Sceneshot)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Sceneshot();
                    };

                    /**
                     * Creates a plain object from a Sceneshot message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Sceneshot} m Sceneshot
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Sceneshot.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Sceneshot to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Sceneshot.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Sceneshot
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Sceneshot
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Sceneshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Sceneshot";
                    };

                    return Sceneshot;
                })();

                Artifact.LayoutEstimation = (function() {

                    /**
                     * Properties of a LayoutEstimation.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface ILayoutEstimation
                     */

                    /**
                     * Constructs a new LayoutEstimation.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a LayoutEstimation.
                     * @implements ILayoutEstimation
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.ILayoutEstimation=} [p] Properties to set
                     */
                    function LayoutEstimation(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new LayoutEstimation instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ILayoutEstimation=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.LayoutEstimation} LayoutEstimation instance
                     */
                    LayoutEstimation.create = function create(properties) {
                        return new LayoutEstimation(properties);
                    };

                    /**
                     * Encodes the specified LayoutEstimation message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.LayoutEstimation.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.ILayoutEstimation} m LayoutEstimation message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LayoutEstimation.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a LayoutEstimation message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.LayoutEstimation} LayoutEstimation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LayoutEstimation.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a LayoutEstimation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.LayoutEstimation} LayoutEstimation
                     */
                    LayoutEstimation.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.LayoutEstimation();
                    };

                    /**
                     * Creates a plain object from a LayoutEstimation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.LayoutEstimation} m LayoutEstimation
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LayoutEstimation.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this LayoutEstimation to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LayoutEstimation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for LayoutEstimation
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.LayoutEstimation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    LayoutEstimation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.LayoutEstimation";
                    };

                    return LayoutEstimation;
                })();

                Artifact.RecordingTrack = (function() {

                    /**
                     * Properties of a RecordingTrack.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IRecordingTrack
                     */

                    /**
                     * Constructs a new RecordingTrack.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a RecordingTrack.
                     * @implements IRecordingTrack
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IRecordingTrack=} [p] Properties to set
                     */
                    function RecordingTrack(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new RecordingTrack instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IRecordingTrack=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.RecordingTrack} RecordingTrack instance
                     */
                    RecordingTrack.create = function create(properties) {
                        return new RecordingTrack(properties);
                    };

                    /**
                     * Encodes the specified RecordingTrack message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.RecordingTrack.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IRecordingTrack} m RecordingTrack message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecordingTrack.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a RecordingTrack message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.RecordingTrack} RecordingTrack
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecordingTrack.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.RecordingTrack();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a RecordingTrack message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.RecordingTrack} RecordingTrack
                     */
                    RecordingTrack.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.RecordingTrack)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.RecordingTrack();
                    };

                    /**
                     * Creates a plain object from a RecordingTrack message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.RecordingTrack} m RecordingTrack
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RecordingTrack.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this RecordingTrack to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RecordingTrack.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for RecordingTrack
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.RecordingTrack
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    RecordingTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.RecordingTrack";
                    };

                    return RecordingTrack;
                })();

                Artifact.Bookmark = (function() {

                    /**
                     * Properties of a Bookmark.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @interface IBookmark
                     */

                    /**
                     * Constructs a new Bookmark.
                     * @memberof streem.api.AnalyticEvent.Artifact
                     * @classdesc Represents a Bookmark.
                     * @implements IBookmark
                     * @constructor
                     * @param {streem.api.AnalyticEvent.Artifact.IBookmark=} [p] Properties to set
                     */
                    function Bookmark(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new Bookmark instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IBookmark=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.Artifact.Bookmark} Bookmark instance
                     */
                    Bookmark.create = function create(properties) {
                        return new Bookmark(properties);
                    };

                    /**
                     * Encodes the specified Bookmark message. Does not implicitly {@link streem.api.AnalyticEvent.Artifact.Bookmark.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.IBookmark} m Bookmark message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Bookmark.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a Bookmark message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.Artifact.Bookmark} Bookmark
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Bookmark.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.Artifact.Bookmark();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Bookmark message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.Artifact.Bookmark} Bookmark
                     */
                    Bookmark.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.Artifact.Bookmark)
                            return d;
                        return new $root.streem.api.AnalyticEvent.Artifact.Bookmark();
                    };

                    /**
                     * Creates a plain object from a Bookmark message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {streem.api.AnalyticEvent.Artifact.Bookmark} m Bookmark
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Bookmark.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Bookmark to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Bookmark.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Bookmark
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.Artifact.Bookmark
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Bookmark.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.Artifact.Bookmark";
                    };

                    return Bookmark;
                })();

                return Artifact;
            })();

            AnalyticEvent.ArtifactRequested = (function() {

                /**
                 * Properties of an ArtifactRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactRequested
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactRequested artifact
                 */

                /**
                 * Constructs a new ArtifactRequested.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactRequested.
                 * @implements IArtifactRequested
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactRequested=} [p] Properties to set
                 */
                function ArtifactRequested(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactRequested artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @instance
                 */
                ArtifactRequested.prototype.artifact = null;

                /**
                 * Creates a new ArtifactRequested instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactRequested=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactRequested} ArtifactRequested instance
                 */
                ArtifactRequested.create = function create(properties) {
                    return new ArtifactRequested(properties);
                };

                /**
                 * Encodes the specified ArtifactRequested message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactRequested.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactRequested} m ArtifactRequested message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactRequested.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactRequested message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactRequested} ArtifactRequested
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactRequested.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactRequested();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactRequested message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactRequested} ArtifactRequested
                 */
                ArtifactRequested.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactRequested)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactRequested();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactRequested.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactRequested message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactRequested} m ArtifactRequested
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactRequested.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactRequested to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactRequested.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactRequested
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactRequested
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactRequested.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactRequested";
                };

                return ArtifactRequested;
            })();

            AnalyticEvent.ArtifactRequestRejected = (function() {

                /**
                 * Properties of an ArtifactRequestRejected.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactRequestRejected
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactRequestRejected artifact
                 */

                /**
                 * Constructs a new ArtifactRequestRejected.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactRequestRejected.
                 * @implements IArtifactRequestRejected
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactRequestRejected=} [p] Properties to set
                 */
                function ArtifactRequestRejected(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactRequestRejected artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @instance
                 */
                ArtifactRequestRejected.prototype.artifact = null;

                /**
                 * Creates a new ArtifactRequestRejected instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactRequestRejected=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactRequestRejected} ArtifactRequestRejected instance
                 */
                ArtifactRequestRejected.create = function create(properties) {
                    return new ArtifactRequestRejected(properties);
                };

                /**
                 * Encodes the specified ArtifactRequestRejected message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactRequestRejected.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactRequestRejected} m ArtifactRequestRejected message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactRequestRejected.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactRequestRejected message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactRequestRejected} ArtifactRequestRejected
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactRequestRejected.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactRequestRejected();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactRequestRejected message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactRequestRejected} ArtifactRequestRejected
                 */
                ArtifactRequestRejected.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactRequestRejected)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactRequestRejected();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactRequestRejected.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactRequestRejected message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactRequestRejected} m ArtifactRequestRejected
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactRequestRejected.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactRequestRejected to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactRequestRejected.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactRequestRejected
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactRequestRejected
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactRequestRejected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactRequestRejected";
                };

                return ArtifactRequestRejected;
            })();

            AnalyticEvent.ArtifactCaptured = (function() {

                /**
                 * Properties of an ArtifactCaptured.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactCaptured
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactCaptured artifact
                 */

                /**
                 * Constructs a new ArtifactCaptured.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactCaptured.
                 * @implements IArtifactCaptured
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactCaptured=} [p] Properties to set
                 */
                function ArtifactCaptured(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactCaptured artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @instance
                 */
                ArtifactCaptured.prototype.artifact = null;

                /**
                 * Creates a new ArtifactCaptured instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactCaptured=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactCaptured} ArtifactCaptured instance
                 */
                ArtifactCaptured.create = function create(properties) {
                    return new ArtifactCaptured(properties);
                };

                /**
                 * Encodes the specified ArtifactCaptured message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactCaptured.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactCaptured} m ArtifactCaptured message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactCaptured.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactCaptured message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactCaptured} ArtifactCaptured
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactCaptured.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactCaptured();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactCaptured message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactCaptured} ArtifactCaptured
                 */
                ArtifactCaptured.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactCaptured)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactCaptured();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactCaptured.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactCaptured message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactCaptured} m ArtifactCaptured
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactCaptured.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactCaptured to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactCaptured.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactCaptured
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactCaptured
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactCaptured.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactCaptured";
                };

                return ArtifactCaptured;
            })();

            AnalyticEvent.ArtifactFulfilled = (function() {

                /**
                 * Properties of an ArtifactFulfilled.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactFulfilled
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactFulfilled artifact
                 */

                /**
                 * Constructs a new ArtifactFulfilled.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactFulfilled.
                 * @implements IArtifactFulfilled
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactFulfilled=} [p] Properties to set
                 */
                function ArtifactFulfilled(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactFulfilled artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @instance
                 */
                ArtifactFulfilled.prototype.artifact = null;

                /**
                 * Creates a new ArtifactFulfilled instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactFulfilled=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactFulfilled} ArtifactFulfilled instance
                 */
                ArtifactFulfilled.create = function create(properties) {
                    return new ArtifactFulfilled(properties);
                };

                /**
                 * Encodes the specified ArtifactFulfilled message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactFulfilled.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactFulfilled} m ArtifactFulfilled message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactFulfilled.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactFulfilled message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactFulfilled} ArtifactFulfilled
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactFulfilled.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactFulfilled();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactFulfilled message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactFulfilled} ArtifactFulfilled
                 */
                ArtifactFulfilled.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactFulfilled)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactFulfilled();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactFulfilled.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactFulfilled message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactFulfilled} m ArtifactFulfilled
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactFulfilled.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactFulfilled to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactFulfilled.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactFulfilled
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactFulfilled
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactFulfilled.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactFulfilled";
                };

                return ArtifactFulfilled;
            })();

            AnalyticEvent.ArtifactUpdated = (function() {

                /**
                 * Properties of an ArtifactUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactUpdated
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactUpdated artifact
                 */

                /**
                 * Constructs a new ArtifactUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactUpdated.
                 * @implements IArtifactUpdated
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactUpdated=} [p] Properties to set
                 */
                function ArtifactUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactUpdated artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @instance
                 */
                ArtifactUpdated.prototype.artifact = null;

                /**
                 * Creates a new ArtifactUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactUpdated=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactUpdated} ArtifactUpdated instance
                 */
                ArtifactUpdated.create = function create(properties) {
                    return new ArtifactUpdated(properties);
                };

                /**
                 * Encodes the specified ArtifactUpdated message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactUpdated} m ArtifactUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactUpdated} ArtifactUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactUpdated} ArtifactUpdated
                 */
                ArtifactUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactUpdated)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactUpdated();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactUpdated.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactUpdated} m ArtifactUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactUpdated";
                };

                return ArtifactUpdated;
            })();

            AnalyticEvent.ArtifactDeleted = (function() {

                /**
                 * Properties of an ArtifactDeleted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactDeleted
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactDeleted artifact
                 */

                /**
                 * Constructs a new ArtifactDeleted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactDeleted.
                 * @implements IArtifactDeleted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactDeleted=} [p] Properties to set
                 */
                function ArtifactDeleted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactDeleted artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @instance
                 */
                ArtifactDeleted.prototype.artifact = null;

                /**
                 * Creates a new ArtifactDeleted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactDeleted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactDeleted} ArtifactDeleted instance
                 */
                ArtifactDeleted.create = function create(properties) {
                    return new ArtifactDeleted(properties);
                };

                /**
                 * Encodes the specified ArtifactDeleted message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactDeleted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactDeleted} m ArtifactDeleted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactDeleted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactDeleted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactDeleted} ArtifactDeleted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactDeleted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactDeleted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactDeleted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactDeleted} ArtifactDeleted
                 */
                ArtifactDeleted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactDeleted)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactDeleted();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactDeleted.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactDeleted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactDeleted} m ArtifactDeleted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactDeleted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactDeleted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactDeleted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactDeleted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactDeleted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactDeleted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactDeleted";
                };

                return ArtifactDeleted;
            })();

            AnalyticEvent.ArtifactViewed = (function() {

                /**
                 * Properties of an ArtifactViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IArtifactViewed
                 * @property {streem.api.AnalyticEvent.IArtifact|null} [artifact] ArtifactViewed artifact
                 */

                /**
                 * Constructs a new ArtifactViewed.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an ArtifactViewed.
                 * @implements IArtifactViewed
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IArtifactViewed=} [p] Properties to set
                 */
                function ArtifactViewed(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactViewed artifact.
                 * @member {streem.api.AnalyticEvent.IArtifact|null|undefined} artifact
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @instance
                 */
                ArtifactViewed.prototype.artifact = null;

                /**
                 * Creates a new ArtifactViewed instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactViewed=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.ArtifactViewed} ArtifactViewed instance
                 */
                ArtifactViewed.create = function create(properties) {
                    return new ArtifactViewed(properties);
                };

                /**
                 * Encodes the specified ArtifactViewed message. Does not implicitly {@link streem.api.AnalyticEvent.ArtifactViewed.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.IArtifactViewed} m ArtifactViewed message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactViewed.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.AnalyticEvent.Artifact.encode(m.artifact, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactViewed message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.ArtifactViewed} ArtifactViewed
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactViewed.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.ArtifactViewed();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.artifact = $root.streem.api.AnalyticEvent.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactViewed message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.ArtifactViewed} ArtifactViewed
                 */
                ArtifactViewed.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.ArtifactViewed)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.ArtifactViewed();
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.ArtifactViewed.artifact: object expected");
                        m.artifact = $root.streem.api.AnalyticEvent.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactViewed message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {streem.api.AnalyticEvent.ArtifactViewed} m ArtifactViewed
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactViewed.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.artifact = null;
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.AnalyticEvent.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactViewed to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactViewed.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactViewed
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.ArtifactViewed
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactViewed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.ArtifactViewed";
                };

                return ArtifactViewed;
            })();

            AnalyticEvent.GuidedExperiencePhaseUpdated = (function() {

                /**
                 * Properties of a GuidedExperiencePhaseUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IGuidedExperiencePhaseUpdated
                 * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IMeshQualityEstimate|null} [quality] GuidedExperiencePhaseUpdated quality
                 * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IIntroductionPhase|null} [introduction] GuidedExperiencePhaseUpdated introduction
                 * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAssetCapturePhase|null} [assetCapture] GuidedExperiencePhaseUpdated assetCapture
                 * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAddDetailPhase|null} [addDetail] GuidedExperiencePhaseUpdated addDetail
                 * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.ICompletePhase|null} [complete] GuidedExperiencePhaseUpdated complete
                 */

                /**
                 * Constructs a new GuidedExperiencePhaseUpdated.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a GuidedExperiencePhaseUpdated.
                 * @implements IGuidedExperiencePhaseUpdated
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IGuidedExperiencePhaseUpdated=} [p] Properties to set
                 */
                function GuidedExperiencePhaseUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GuidedExperiencePhaseUpdated quality.
                 * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IMeshQualityEstimate|null|undefined} quality
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                GuidedExperiencePhaseUpdated.prototype.quality = null;

                /**
                 * GuidedExperiencePhaseUpdated introduction.
                 * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IIntroductionPhase|null|undefined} introduction
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                GuidedExperiencePhaseUpdated.prototype.introduction = null;

                /**
                 * GuidedExperiencePhaseUpdated assetCapture.
                 * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAssetCapturePhase|null|undefined} assetCapture
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                GuidedExperiencePhaseUpdated.prototype.assetCapture = null;

                /**
                 * GuidedExperiencePhaseUpdated addDetail.
                 * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAddDetailPhase|null|undefined} addDetail
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                GuidedExperiencePhaseUpdated.prototype.addDetail = null;

                /**
                 * GuidedExperiencePhaseUpdated complete.
                 * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.ICompletePhase|null|undefined} complete
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                GuidedExperiencePhaseUpdated.prototype.complete = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * GuidedExperiencePhaseUpdated phase.
                 * @member {"introduction"|"assetCapture"|"addDetail"|"complete"|undefined} phase
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 */
                Object.defineProperty(GuidedExperiencePhaseUpdated.prototype, "phase", {
                    get: $util.oneOfGetter($oneOfFields = ["introduction", "assetCapture", "addDetail", "complete"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GuidedExperiencePhaseUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IGuidedExperiencePhaseUpdated=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated} GuidedExperiencePhaseUpdated instance
                 */
                GuidedExperiencePhaseUpdated.create = function create(properties) {
                    return new GuidedExperiencePhaseUpdated(properties);
                };

                /**
                 * Encodes the specified GuidedExperiencePhaseUpdated message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.IGuidedExperiencePhaseUpdated} m GuidedExperiencePhaseUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GuidedExperiencePhaseUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.quality != null && Object.hasOwnProperty.call(m, "quality"))
                        $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate.encode(m.quality, w.uint32(10).fork()).ldelim();
                    if (m.introduction != null && Object.hasOwnProperty.call(m, "introduction"))
                        $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase.encode(m.introduction, w.uint32(802).fork()).ldelim();
                    if (m.assetCapture != null && Object.hasOwnProperty.call(m, "assetCapture"))
                        $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.encode(m.assetCapture, w.uint32(810).fork()).ldelim();
                    if (m.addDetail != null && Object.hasOwnProperty.call(m, "addDetail"))
                        $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase.encode(m.addDetail, w.uint32(818).fork()).ldelim();
                    if (m.complete != null && Object.hasOwnProperty.call(m, "complete"))
                        $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase.encode(m.complete, w.uint32(826).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GuidedExperiencePhaseUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated} GuidedExperiencePhaseUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GuidedExperiencePhaseUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.quality = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate.decode(r, r.uint32());
                                break;
                            }
                        case 100: {
                                m.introduction = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase.decode(r, r.uint32());
                                break;
                            }
                        case 101: {
                                m.assetCapture = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.decode(r, r.uint32());
                                break;
                            }
                        case 102: {
                                m.addDetail = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase.decode(r, r.uint32());
                                break;
                            }
                        case 103: {
                                m.complete = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GuidedExperiencePhaseUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated} GuidedExperiencePhaseUpdated
                 */
                GuidedExperiencePhaseUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated();
                    if (d.quality != null) {
                        if (typeof d.quality !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.quality: object expected");
                        m.quality = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate.fromObject(d.quality);
                    }
                    if (d.introduction != null) {
                        if (typeof d.introduction !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.introduction: object expected");
                        m.introduction = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase.fromObject(d.introduction);
                    }
                    if (d.assetCapture != null) {
                        if (typeof d.assetCapture !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.assetCapture: object expected");
                        m.assetCapture = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.fromObject(d.assetCapture);
                    }
                    if (d.addDetail != null) {
                        if (typeof d.addDetail !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.addDetail: object expected");
                        m.addDetail = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase.fromObject(d.addDetail);
                    }
                    if (d.complete != null) {
                        if (typeof d.complete !== "object")
                            throw TypeError(".streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.complete: object expected");
                        m.complete = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase.fromObject(d.complete);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GuidedExperiencePhaseUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated} m GuidedExperiencePhaseUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GuidedExperiencePhaseUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.quality = null;
                    }
                    if (m.quality != null && m.hasOwnProperty("quality")) {
                        d.quality = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate.toObject(m.quality, o);
                    }
                    if (m.introduction != null && m.hasOwnProperty("introduction")) {
                        d.introduction = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase.toObject(m.introduction, o);
                        if (o.oneofs)
                            d.phase = "introduction";
                    }
                    if (m.assetCapture != null && m.hasOwnProperty("assetCapture")) {
                        d.assetCapture = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.toObject(m.assetCapture, o);
                        if (o.oneofs)
                            d.phase = "assetCapture";
                    }
                    if (m.addDetail != null && m.hasOwnProperty("addDetail")) {
                        d.addDetail = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase.toObject(m.addDetail, o);
                        if (o.oneofs)
                            d.phase = "addDetail";
                    }
                    if (m.complete != null && m.hasOwnProperty("complete")) {
                        d.complete = $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase.toObject(m.complete, o);
                        if (o.oneofs)
                            d.phase = "complete";
                    }
                    return d;
                };

                /**
                 * Converts this GuidedExperiencePhaseUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GuidedExperiencePhaseUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GuidedExperiencePhaseUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GuidedExperiencePhaseUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated";
                };

                GuidedExperiencePhaseUpdated.MeshQualityEstimate = (function() {

                    /**
                     * Properties of a MeshQualityEstimate.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @interface IMeshQualityEstimate
                     * @property {number|null} [quality] MeshQualityEstimate quality
                     */

                    /**
                     * Constructs a new MeshQualityEstimate.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @classdesc Represents a MeshQualityEstimate.
                     * @implements IMeshQualityEstimate
                     * @constructor
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IMeshQualityEstimate=} [p] Properties to set
                     */
                    function MeshQualityEstimate(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * MeshQualityEstimate quality.
                     * @member {number} quality
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @instance
                     */
                    MeshQualityEstimate.prototype.quality = 0;

                    /**
                     * Creates a new MeshQualityEstimate instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IMeshQualityEstimate=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate} MeshQualityEstimate instance
                     */
                    MeshQualityEstimate.create = function create(properties) {
                        return new MeshQualityEstimate(properties);
                    };

                    /**
                     * Encodes the specified MeshQualityEstimate message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IMeshQualityEstimate} m MeshQualityEstimate message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MeshQualityEstimate.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.quality != null && Object.hasOwnProperty.call(m, "quality"))
                            w.uint32(13).float(m.quality);
                        return w;
                    };

                    /**
                     * Decodes a MeshQualityEstimate message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate} MeshQualityEstimate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MeshQualityEstimate.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.quality = r.float();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a MeshQualityEstimate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate} MeshQualityEstimate
                     */
                    MeshQualityEstimate.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate();
                        if (d.quality != null) {
                            m.quality = Number(d.quality);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a MeshQualityEstimate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate} m MeshQualityEstimate
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MeshQualityEstimate.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.quality = 0;
                        }
                        if (m.quality != null && m.hasOwnProperty("quality")) {
                            d.quality = o.json && !isFinite(m.quality) ? String(m.quality) : m.quality;
                        }
                        return d;
                    };

                    /**
                     * Converts this MeshQualityEstimate to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MeshQualityEstimate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for MeshQualityEstimate
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    MeshQualityEstimate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.MeshQualityEstimate";
                    };

                    return MeshQualityEstimate;
                })();

                GuidedExperiencePhaseUpdated.IntroductionPhase = (function() {

                    /**
                     * Properties of an IntroductionPhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @interface IIntroductionPhase
                     */

                    /**
                     * Constructs a new IntroductionPhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @classdesc Represents an IntroductionPhase.
                     * @implements IIntroductionPhase
                     * @constructor
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IIntroductionPhase=} [p] Properties to set
                     */
                    function IntroductionPhase(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new IntroductionPhase instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IIntroductionPhase=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase} IntroductionPhase instance
                     */
                    IntroductionPhase.create = function create(properties) {
                        return new IntroductionPhase(properties);
                    };

                    /**
                     * Encodes the specified IntroductionPhase message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IIntroductionPhase} m IntroductionPhase message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IntroductionPhase.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes an IntroductionPhase message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase} IntroductionPhase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IntroductionPhase.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an IntroductionPhase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase} IntroductionPhase
                     */
                    IntroductionPhase.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase)
                            return d;
                        return new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase();
                    };

                    /**
                     * Creates a plain object from an IntroductionPhase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase} m IntroductionPhase
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IntroductionPhase.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this IntroductionPhase to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IntroductionPhase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for IntroductionPhase
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    IntroductionPhase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IntroductionPhase";
                    };

                    return IntroductionPhase;
                })();

                GuidedExperiencePhaseUpdated.AssetCapturePhase = (function() {

                    /**
                     * Properties of an AssetCapturePhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @interface IAssetCapturePhase
                     * @property {number|null} [assetsCount] AssetCapturePhase assetsCount
                     * @property {number|null} [assetsTotal] AssetCapturePhase assetsTotal
                     * @property {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.AssetStatus|null} [status] AssetCapturePhase status
                     */

                    /**
                     * Constructs a new AssetCapturePhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @classdesc Represents an AssetCapturePhase.
                     * @implements IAssetCapturePhase
                     * @constructor
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAssetCapturePhase=} [p] Properties to set
                     */
                    function AssetCapturePhase(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * AssetCapturePhase assetsCount.
                     * @member {number} assetsCount
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @instance
                     */
                    AssetCapturePhase.prototype.assetsCount = 0;

                    /**
                     * AssetCapturePhase assetsTotal.
                     * @member {number} assetsTotal
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @instance
                     */
                    AssetCapturePhase.prototype.assetsTotal = 0;

                    /**
                     * AssetCapturePhase status.
                     * @member {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.AssetStatus} status
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @instance
                     */
                    AssetCapturePhase.prototype.status = 0;

                    /**
                     * Creates a new AssetCapturePhase instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAssetCapturePhase=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase} AssetCapturePhase instance
                     */
                    AssetCapturePhase.create = function create(properties) {
                        return new AssetCapturePhase(properties);
                    };

                    /**
                     * Encodes the specified AssetCapturePhase message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAssetCapturePhase} m AssetCapturePhase message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AssetCapturePhase.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.assetsCount != null && Object.hasOwnProperty.call(m, "assetsCount"))
                            w.uint32(8).int32(m.assetsCount);
                        if (m.assetsTotal != null && Object.hasOwnProperty.call(m, "assetsTotal"))
                            w.uint32(16).int32(m.assetsTotal);
                        if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                            w.uint32(24).int32(m.status);
                        return w;
                    };

                    /**
                     * Decodes an AssetCapturePhase message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase} AssetCapturePhase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AssetCapturePhase.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.assetsCount = r.int32();
                                    break;
                                }
                            case 2: {
                                    m.assetsTotal = r.int32();
                                    break;
                                }
                            case 3: {
                                    m.status = r.int32();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an AssetCapturePhase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase} AssetCapturePhase
                     */
                    AssetCapturePhase.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase)
                            return d;
                        var m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase();
                        if (d.assetsCount != null) {
                            m.assetsCount = d.assetsCount | 0;
                        }
                        if (d.assetsTotal != null) {
                            m.assetsTotal = d.assetsTotal | 0;
                        }
                        switch (d.status) {
                        default:
                            if (typeof d.status === "number") {
                                m.status = d.status;
                                break;
                            }
                            break;
                        case "ASSET_STATUS_INVALID":
                        case 0:
                            m.status = 0;
                            break;
                        case "ASSET_STATUS_PLACED":
                        case 1:
                            m.status = 1;
                            break;
                        case "ASSET_STATUS_MOVED":
                        case 2:
                            m.status = 2;
                            break;
                        case "ASSET_STATUS_CAPTURED":
                        case 3:
                            m.status = 3;
                            break;
                        case "ASSET_STATUS_REMOVED":
                        case 4:
                            m.status = 4;
                            break;
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an AssetCapturePhase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase} m AssetCapturePhase
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AssetCapturePhase.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.assetsCount = 0;
                            d.assetsTotal = 0;
                            d.status = o.enums === String ? "ASSET_STATUS_INVALID" : 0;
                        }
                        if (m.assetsCount != null && m.hasOwnProperty("assetsCount")) {
                            d.assetsCount = m.assetsCount;
                        }
                        if (m.assetsTotal != null && m.hasOwnProperty("assetsTotal")) {
                            d.assetsTotal = m.assetsTotal;
                        }
                        if (m.status != null && m.hasOwnProperty("status")) {
                            d.status = o.enums === String ? $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.AssetStatus[m.status] === undefined ? m.status : $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.AssetStatus[m.status] : m.status;
                        }
                        return d;
                    };

                    /**
                     * Converts this AssetCapturePhase to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AssetCapturePhase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for AssetCapturePhase
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    AssetCapturePhase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase";
                    };

                    /**
                     * AssetStatus enum.
                     * @name streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AssetCapturePhase.AssetStatus
                     * @enum {number}
                     * @property {number} ASSET_STATUS_INVALID=0 ASSET_STATUS_INVALID value
                     * @property {number} ASSET_STATUS_PLACED=1 ASSET_STATUS_PLACED value
                     * @property {number} ASSET_STATUS_MOVED=2 ASSET_STATUS_MOVED value
                     * @property {number} ASSET_STATUS_CAPTURED=3 ASSET_STATUS_CAPTURED value
                     * @property {number} ASSET_STATUS_REMOVED=4 ASSET_STATUS_REMOVED value
                     */
                    AssetCapturePhase.AssetStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "ASSET_STATUS_INVALID"] = 0;
                        values[valuesById[1] = "ASSET_STATUS_PLACED"] = 1;
                        values[valuesById[2] = "ASSET_STATUS_MOVED"] = 2;
                        values[valuesById[3] = "ASSET_STATUS_CAPTURED"] = 3;
                        values[valuesById[4] = "ASSET_STATUS_REMOVED"] = 4;
                        return values;
                    })();

                    return AssetCapturePhase;
                })();

                GuidedExperiencePhaseUpdated.AddDetailPhase = (function() {

                    /**
                     * Properties of an AddDetailPhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @interface IAddDetailPhase
                     */

                    /**
                     * Constructs a new AddDetailPhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @classdesc Represents an AddDetailPhase.
                     * @implements IAddDetailPhase
                     * @constructor
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAddDetailPhase=} [p] Properties to set
                     */
                    function AddDetailPhase(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new AddDetailPhase instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAddDetailPhase=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase} AddDetailPhase instance
                     */
                    AddDetailPhase.create = function create(properties) {
                        return new AddDetailPhase(properties);
                    };

                    /**
                     * Encodes the specified AddDetailPhase message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.IAddDetailPhase} m AddDetailPhase message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddDetailPhase.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes an AddDetailPhase message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase} AddDetailPhase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddDetailPhase.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an AddDetailPhase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase} AddDetailPhase
                     */
                    AddDetailPhase.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase)
                            return d;
                        return new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase();
                    };

                    /**
                     * Creates a plain object from an AddDetailPhase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase} m AddDetailPhase
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddDetailPhase.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this AddDetailPhase to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddDetailPhase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for AddDetailPhase
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    AddDetailPhase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.AddDetailPhase";
                    };

                    return AddDetailPhase;
                })();

                GuidedExperiencePhaseUpdated.CompletePhase = (function() {

                    /**
                     * Properties of a CompletePhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @interface ICompletePhase
                     */

                    /**
                     * Constructs a new CompletePhase.
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated
                     * @classdesc Represents a CompletePhase.
                     * @implements ICompletePhase
                     * @constructor
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.ICompletePhase=} [p] Properties to set
                     */
                    function CompletePhase(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Creates a new CompletePhase instance using the specified properties.
                     * @function create
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.ICompletePhase=} [properties] Properties to set
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase} CompletePhase instance
                     */
                    CompletePhase.create = function create(properties) {
                        return new CompletePhase(properties);
                    };

                    /**
                     * Encodes the specified CompletePhase message. Does not implicitly {@link streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.ICompletePhase} m CompletePhase message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CompletePhase.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    /**
                     * Decodes a CompletePhase message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase} CompletePhase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CompletePhase.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a CompletePhase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase} CompletePhase
                     */
                    CompletePhase.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase)
                            return d;
                        return new $root.streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase();
                    };

                    /**
                     * Creates a plain object from a CompletePhase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase} m CompletePhase
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CompletePhase.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this CompletePhase to JSON.
                     * @function toJSON
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CompletePhase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CompletePhase
                     * @function getTypeUrl
                     * @memberof streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CompletePhase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.AnalyticEvent.GuidedExperiencePhaseUpdated.CompletePhase";
                    };

                    return CompletePhase;
                })();

                return GuidedExperiencePhaseUpdated;
            })();

            AnalyticEvent.NoteSaved = (function() {

                /**
                 * Properties of a NoteSaved.
                 * @memberof streem.api.AnalyticEvent
                 * @interface INoteSaved
                 * @property {number|null} [charCount] NoteSaved charCount
                 * @property {string|null} [scopeSid] NoteSaved scopeSid
                 */

                /**
                 * Constructs a new NoteSaved.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a NoteSaved.
                 * @implements INoteSaved
                 * @constructor
                 * @param {streem.api.AnalyticEvent.INoteSaved=} [p] Properties to set
                 */
                function NoteSaved(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * NoteSaved charCount.
                 * @member {number} charCount
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @instance
                 */
                NoteSaved.prototype.charCount = 0;

                /**
                 * NoteSaved scopeSid.
                 * @member {string} scopeSid
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @instance
                 */
                NoteSaved.prototype.scopeSid = "";

                /**
                 * Creates a new NoteSaved instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {streem.api.AnalyticEvent.INoteSaved=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.NoteSaved} NoteSaved instance
                 */
                NoteSaved.create = function create(properties) {
                    return new NoteSaved(properties);
                };

                /**
                 * Encodes the specified NoteSaved message. Does not implicitly {@link streem.api.AnalyticEvent.NoteSaved.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {streem.api.AnalyticEvent.INoteSaved} m NoteSaved message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoteSaved.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.charCount != null && Object.hasOwnProperty.call(m, "charCount"))
                        w.uint32(8).int32(m.charCount);
                    if (m.scopeSid != null && Object.hasOwnProperty.call(m, "scopeSid"))
                        w.uint32(18).string(m.scopeSid);
                    return w;
                };

                /**
                 * Decodes a NoteSaved message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.NoteSaved} NoteSaved
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoteSaved.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.NoteSaved();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.charCount = r.int32();
                                break;
                            }
                        case 2: {
                                m.scopeSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a NoteSaved message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.NoteSaved} NoteSaved
                 */
                NoteSaved.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.NoteSaved)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.NoteSaved();
                    if (d.charCount != null) {
                        m.charCount = d.charCount | 0;
                    }
                    if (d.scopeSid != null) {
                        m.scopeSid = String(d.scopeSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a NoteSaved message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {streem.api.AnalyticEvent.NoteSaved} m NoteSaved
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoteSaved.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.charCount = 0;
                        d.scopeSid = "";
                    }
                    if (m.charCount != null && m.hasOwnProperty("charCount")) {
                        d.charCount = m.charCount;
                    }
                    if (m.scopeSid != null && m.hasOwnProperty("scopeSid")) {
                        d.scopeSid = m.scopeSid;
                    }
                    return d;
                };

                /**
                 * Converts this NoteSaved to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoteSaved.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoteSaved
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.NoteSaved
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoteSaved.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.NoteSaved";
                };

                return NoteSaved;
            })();

            AnalyticEvent.NoteDeleted = (function() {

                /**
                 * Properties of a NoteDeleted.
                 * @memberof streem.api.AnalyticEvent
                 * @interface INoteDeleted
                 */

                /**
                 * Constructs a new NoteDeleted.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a NoteDeleted.
                 * @implements INoteDeleted
                 * @constructor
                 * @param {streem.api.AnalyticEvent.INoteDeleted=} [p] Properties to set
                 */
                function NoteDeleted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new NoteDeleted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.INoteDeleted=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.NoteDeleted} NoteDeleted instance
                 */
                NoteDeleted.create = function create(properties) {
                    return new NoteDeleted(properties);
                };

                /**
                 * Encodes the specified NoteDeleted message. Does not implicitly {@link streem.api.AnalyticEvent.NoteDeleted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.INoteDeleted} m NoteDeleted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoteDeleted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a NoteDeleted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.NoteDeleted} NoteDeleted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoteDeleted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.NoteDeleted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a NoteDeleted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.NoteDeleted} NoteDeleted
                 */
                NoteDeleted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.NoteDeleted)
                        return d;
                    return new $root.streem.api.AnalyticEvent.NoteDeleted();
                };

                /**
                 * Creates a plain object from a NoteDeleted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {streem.api.AnalyticEvent.NoteDeleted} m NoteDeleted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoteDeleted.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this NoteDeleted to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoteDeleted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoteDeleted
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.NoteDeleted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoteDeleted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.NoteDeleted";
                };

                return NoteDeleted;
            })();

            AnalyticEvent.AppBackgrounded = (function() {

                /**
                 * Properties of an AppBackgrounded.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IAppBackgrounded
                 */

                /**
                 * Constructs a new AppBackgrounded.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an AppBackgrounded.
                 * @implements IAppBackgrounded
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IAppBackgrounded=} [p] Properties to set
                 */
                function AppBackgrounded(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new AppBackgrounded instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.IAppBackgrounded=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.AppBackgrounded} AppBackgrounded instance
                 */
                AppBackgrounded.create = function create(properties) {
                    return new AppBackgrounded(properties);
                };

                /**
                 * Encodes the specified AppBackgrounded message. Does not implicitly {@link streem.api.AnalyticEvent.AppBackgrounded.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.IAppBackgrounded} m AppBackgrounded message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppBackgrounded.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes an AppBackgrounded message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.AppBackgrounded} AppBackgrounded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppBackgrounded.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.AppBackgrounded();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an AppBackgrounded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.AppBackgrounded} AppBackgrounded
                 */
                AppBackgrounded.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.AppBackgrounded)
                        return d;
                    return new $root.streem.api.AnalyticEvent.AppBackgrounded();
                };

                /**
                 * Creates a plain object from an AppBackgrounded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.AppBackgrounded} m AppBackgrounded
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppBackgrounded.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AppBackgrounded to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppBackgrounded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AppBackgrounded
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.AppBackgrounded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AppBackgrounded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.AppBackgrounded";
                };

                return AppBackgrounded;
            })();

            AnalyticEvent.AppForegrounded = (function() {

                /**
                 * Properties of an AppForegrounded.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IAppForegrounded
                 */

                /**
                 * Constructs a new AppForegrounded.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents an AppForegrounded.
                 * @implements IAppForegrounded
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IAppForegrounded=} [p] Properties to set
                 */
                function AppForegrounded(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new AppForegrounded instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.IAppForegrounded=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.AppForegrounded} AppForegrounded instance
                 */
                AppForegrounded.create = function create(properties) {
                    return new AppForegrounded(properties);
                };

                /**
                 * Encodes the specified AppForegrounded message. Does not implicitly {@link streem.api.AnalyticEvent.AppForegrounded.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.IAppForegrounded} m AppForegrounded message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppForegrounded.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes an AppForegrounded message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.AppForegrounded} AppForegrounded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppForegrounded.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.AppForegrounded();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an AppForegrounded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.AppForegrounded} AppForegrounded
                 */
                AppForegrounded.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.AppForegrounded)
                        return d;
                    return new $root.streem.api.AnalyticEvent.AppForegrounded();
                };

                /**
                 * Creates a plain object from an AppForegrounded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {streem.api.AnalyticEvent.AppForegrounded} m AppForegrounded
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppForegrounded.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AppForegrounded to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppForegrounded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AppForegrounded
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.AppForegrounded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AppForegrounded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.AppForegrounded";
                };

                return AppForegrounded;
            })();

            AnalyticEvent.VignetteElementSelected = (function() {

                /**
                 * Properties of a VignetteElementSelected.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IVignetteElementSelected
                 * @property {string|null} [vignettElementId] VignetteElementSelected vignettElementId
                 * @property {string|null} [finishId] VignetteElementSelected finishId
                 * @property {streem.api.AnalyticEvent.VignetteElementSelected.TriggerType|null} [triggerType] VignetteElementSelected triggerType
                 */

                /**
                 * Constructs a new VignetteElementSelected.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a VignetteElementSelected.
                 * @implements IVignetteElementSelected
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IVignetteElementSelected=} [p] Properties to set
                 */
                function VignetteElementSelected(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteElementSelected vignettElementId.
                 * @member {string} vignettElementId
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @instance
                 */
                VignetteElementSelected.prototype.vignettElementId = "";

                /**
                 * VignetteElementSelected finishId.
                 * @member {string} finishId
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @instance
                 */
                VignetteElementSelected.prototype.finishId = "";

                /**
                 * VignetteElementSelected triggerType.
                 * @member {streem.api.AnalyticEvent.VignetteElementSelected.TriggerType} triggerType
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @instance
                 */
                VignetteElementSelected.prototype.triggerType = 0;

                /**
                 * Creates a new VignetteElementSelected instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteElementSelected=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.VignetteElementSelected} VignetteElementSelected instance
                 */
                VignetteElementSelected.create = function create(properties) {
                    return new VignetteElementSelected(properties);
                };

                /**
                 * Encodes the specified VignetteElementSelected message. Does not implicitly {@link streem.api.AnalyticEvent.VignetteElementSelected.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteElementSelected} m VignetteElementSelected message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteElementSelected.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.vignettElementId != null && Object.hasOwnProperty.call(m, "vignettElementId"))
                        w.uint32(10).string(m.vignettElementId);
                    if (m.finishId != null && Object.hasOwnProperty.call(m, "finishId"))
                        w.uint32(18).string(m.finishId);
                    if (m.triggerType != null && Object.hasOwnProperty.call(m, "triggerType"))
                        w.uint32(24).int32(m.triggerType);
                    return w;
                };

                /**
                 * Decodes a VignetteElementSelected message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.VignetteElementSelected} VignetteElementSelected
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteElementSelected.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.VignetteElementSelected();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.vignettElementId = r.string();
                                break;
                            }
                        case 2: {
                                m.finishId = r.string();
                                break;
                            }
                        case 3: {
                                m.triggerType = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteElementSelected message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.VignetteElementSelected} VignetteElementSelected
                 */
                VignetteElementSelected.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.VignetteElementSelected)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.VignetteElementSelected();
                    if (d.vignettElementId != null) {
                        m.vignettElementId = String(d.vignettElementId);
                    }
                    if (d.finishId != null) {
                        m.finishId = String(d.finishId);
                    }
                    switch (d.triggerType) {
                    default:
                        if (typeof d.triggerType === "number") {
                            m.triggerType = d.triggerType;
                            break;
                        }
                        break;
                    case "TRIGGER_TYPE_INVALID":
                    case 0:
                        m.triggerType = 0;
                        break;
                    case "TRIGGER_TYPE_SWAP":
                    case 1:
                        m.triggerType = 1;
                        break;
                    case "TRIGGER_TYPE_HOTSPOT":
                    case 2:
                        m.triggerType = 2;
                        break;
                    case "TRIGGER_TYPE_CENTER_OF_VIEW":
                    case 3:
                        m.triggerType = 3;
                        break;
                    case "TRIGGER_TYPE_TAP":
                    case 4:
                        m.triggerType = 4;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteElementSelected message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {streem.api.AnalyticEvent.VignetteElementSelected} m VignetteElementSelected
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteElementSelected.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.vignettElementId = "";
                        d.finishId = "";
                        d.triggerType = o.enums === String ? "TRIGGER_TYPE_INVALID" : 0;
                    }
                    if (m.vignettElementId != null && m.hasOwnProperty("vignettElementId")) {
                        d.vignettElementId = m.vignettElementId;
                    }
                    if (m.finishId != null && m.hasOwnProperty("finishId")) {
                        d.finishId = m.finishId;
                    }
                    if (m.triggerType != null && m.hasOwnProperty("triggerType")) {
                        d.triggerType = o.enums === String ? $root.streem.api.AnalyticEvent.VignetteElementSelected.TriggerType[m.triggerType] === undefined ? m.triggerType : $root.streem.api.AnalyticEvent.VignetteElementSelected.TriggerType[m.triggerType] : m.triggerType;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteElementSelected to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteElementSelected.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteElementSelected
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.VignetteElementSelected
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteElementSelected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.VignetteElementSelected";
                };

                /**
                 * TriggerType enum.
                 * @name streem.api.AnalyticEvent.VignetteElementSelected.TriggerType
                 * @enum {number}
                 * @property {number} TRIGGER_TYPE_INVALID=0 TRIGGER_TYPE_INVALID value
                 * @property {number} TRIGGER_TYPE_SWAP=1 TRIGGER_TYPE_SWAP value
                 * @property {number} TRIGGER_TYPE_HOTSPOT=2 TRIGGER_TYPE_HOTSPOT value
                 * @property {number} TRIGGER_TYPE_CENTER_OF_VIEW=3 TRIGGER_TYPE_CENTER_OF_VIEW value
                 * @property {number} TRIGGER_TYPE_TAP=4 TRIGGER_TYPE_TAP value
                 */
                VignetteElementSelected.TriggerType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "TRIGGER_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "TRIGGER_TYPE_SWAP"] = 1;
                    values[valuesById[2] = "TRIGGER_TYPE_HOTSPOT"] = 2;
                    values[valuesById[3] = "TRIGGER_TYPE_CENTER_OF_VIEW"] = 3;
                    values[valuesById[4] = "TRIGGER_TYPE_TAP"] = 4;
                    return values;
                })();

                return VignetteElementSelected;
            })();

            AnalyticEvent.VignetteDrawerExpanded = (function() {

                /**
                 * Properties of a VignetteDrawerExpanded.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IVignetteDrawerExpanded
                 * @property {string|null} [vignettElementId] VignetteDrawerExpanded vignettElementId
                 * @property {string|null} [finishId] VignetteDrawerExpanded finishId
                 * @property {streem.api.AnalyticEvent.VignetteDrawerExpanded.SnapPoint|null} [snapPoint] VignetteDrawerExpanded snapPoint
                 */

                /**
                 * Constructs a new VignetteDrawerExpanded.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a VignetteDrawerExpanded.
                 * @implements IVignetteDrawerExpanded
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IVignetteDrawerExpanded=} [p] Properties to set
                 */
                function VignetteDrawerExpanded(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteDrawerExpanded vignettElementId.
                 * @member {string} vignettElementId
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @instance
                 */
                VignetteDrawerExpanded.prototype.vignettElementId = "";

                /**
                 * VignetteDrawerExpanded finishId.
                 * @member {string} finishId
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @instance
                 */
                VignetteDrawerExpanded.prototype.finishId = "";

                /**
                 * VignetteDrawerExpanded snapPoint.
                 * @member {streem.api.AnalyticEvent.VignetteDrawerExpanded.SnapPoint} snapPoint
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @instance
                 */
                VignetteDrawerExpanded.prototype.snapPoint = 0;

                /**
                 * Creates a new VignetteDrawerExpanded instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteDrawerExpanded=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.VignetteDrawerExpanded} VignetteDrawerExpanded instance
                 */
                VignetteDrawerExpanded.create = function create(properties) {
                    return new VignetteDrawerExpanded(properties);
                };

                /**
                 * Encodes the specified VignetteDrawerExpanded message. Does not implicitly {@link streem.api.AnalyticEvent.VignetteDrawerExpanded.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteDrawerExpanded} m VignetteDrawerExpanded message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteDrawerExpanded.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.vignettElementId != null && Object.hasOwnProperty.call(m, "vignettElementId"))
                        w.uint32(10).string(m.vignettElementId);
                    if (m.finishId != null && Object.hasOwnProperty.call(m, "finishId"))
                        w.uint32(18).string(m.finishId);
                    if (m.snapPoint != null && Object.hasOwnProperty.call(m, "snapPoint"))
                        w.uint32(32).int32(m.snapPoint);
                    return w;
                };

                /**
                 * Decodes a VignetteDrawerExpanded message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.VignetteDrawerExpanded} VignetteDrawerExpanded
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteDrawerExpanded.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.VignetteDrawerExpanded();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.vignettElementId = r.string();
                                break;
                            }
                        case 2: {
                                m.finishId = r.string();
                                break;
                            }
                        case 4: {
                                m.snapPoint = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteDrawerExpanded message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.VignetteDrawerExpanded} VignetteDrawerExpanded
                 */
                VignetteDrawerExpanded.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.VignetteDrawerExpanded)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.VignetteDrawerExpanded();
                    if (d.vignettElementId != null) {
                        m.vignettElementId = String(d.vignettElementId);
                    }
                    if (d.finishId != null) {
                        m.finishId = String(d.finishId);
                    }
                    switch (d.snapPoint) {
                    default:
                        if (typeof d.snapPoint === "number") {
                            m.snapPoint = d.snapPoint;
                            break;
                        }
                        break;
                    case "SNAP_POINT_INVALID":
                    case 0:
                        m.snapPoint = 0;
                        break;
                    case "SNAP_POINT_PEEK_HEIGHT":
                    case 1:
                        m.snapPoint = 1;
                        break;
                    case "SNAP_POINT_HALF_EXTENDED":
                    case 2:
                        m.snapPoint = 2;
                        break;
                    case "SNAP_POINT_FULL_HEIGHT":
                    case 3:
                        m.snapPoint = 3;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteDrawerExpanded message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {streem.api.AnalyticEvent.VignetteDrawerExpanded} m VignetteDrawerExpanded
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteDrawerExpanded.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.vignettElementId = "";
                        d.finishId = "";
                        d.snapPoint = o.enums === String ? "SNAP_POINT_INVALID" : 0;
                    }
                    if (m.vignettElementId != null && m.hasOwnProperty("vignettElementId")) {
                        d.vignettElementId = m.vignettElementId;
                    }
                    if (m.finishId != null && m.hasOwnProperty("finishId")) {
                        d.finishId = m.finishId;
                    }
                    if (m.snapPoint != null && m.hasOwnProperty("snapPoint")) {
                        d.snapPoint = o.enums === String ? $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.SnapPoint[m.snapPoint] === undefined ? m.snapPoint : $root.streem.api.AnalyticEvent.VignetteDrawerExpanded.SnapPoint[m.snapPoint] : m.snapPoint;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteDrawerExpanded to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteDrawerExpanded.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteDrawerExpanded
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.VignetteDrawerExpanded
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteDrawerExpanded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.VignetteDrawerExpanded";
                };

                /**
                 * SnapPoint enum.
                 * @name streem.api.AnalyticEvent.VignetteDrawerExpanded.SnapPoint
                 * @enum {number}
                 * @property {number} SNAP_POINT_INVALID=0 SNAP_POINT_INVALID value
                 * @property {number} SNAP_POINT_PEEK_HEIGHT=1 SNAP_POINT_PEEK_HEIGHT value
                 * @property {number} SNAP_POINT_HALF_EXTENDED=2 SNAP_POINT_HALF_EXTENDED value
                 * @property {number} SNAP_POINT_FULL_HEIGHT=3 SNAP_POINT_FULL_HEIGHT value
                 */
                VignetteDrawerExpanded.SnapPoint = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SNAP_POINT_INVALID"] = 0;
                    values[valuesById[1] = "SNAP_POINT_PEEK_HEIGHT"] = 1;
                    values[valuesById[2] = "SNAP_POINT_HALF_EXTENDED"] = 2;
                    values[valuesById[3] = "SNAP_POINT_FULL_HEIGHT"] = 3;
                    return values;
                })();

                return VignetteDrawerExpanded;
            })();

            AnalyticEvent.VignetteFinishChanged = (function() {

                /**
                 * Properties of a VignetteFinishChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IVignetteFinishChanged
                 * @property {string|null} [vignettElementId] VignetteFinishChanged vignettElementId
                 * @property {string|null} [finishId] VignetteFinishChanged finishId
                 */

                /**
                 * Constructs a new VignetteFinishChanged.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a VignetteFinishChanged.
                 * @implements IVignetteFinishChanged
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IVignetteFinishChanged=} [p] Properties to set
                 */
                function VignetteFinishChanged(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteFinishChanged vignettElementId.
                 * @member {string} vignettElementId
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @instance
                 */
                VignetteFinishChanged.prototype.vignettElementId = "";

                /**
                 * VignetteFinishChanged finishId.
                 * @member {string} finishId
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @instance
                 */
                VignetteFinishChanged.prototype.finishId = "";

                /**
                 * Creates a new VignetteFinishChanged instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteFinishChanged=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.VignetteFinishChanged} VignetteFinishChanged instance
                 */
                VignetteFinishChanged.create = function create(properties) {
                    return new VignetteFinishChanged(properties);
                };

                /**
                 * Encodes the specified VignetteFinishChanged message. Does not implicitly {@link streem.api.AnalyticEvent.VignetteFinishChanged.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteFinishChanged} m VignetteFinishChanged message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteFinishChanged.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.vignettElementId != null && Object.hasOwnProperty.call(m, "vignettElementId"))
                        w.uint32(10).string(m.vignettElementId);
                    if (m.finishId != null && Object.hasOwnProperty.call(m, "finishId"))
                        w.uint32(18).string(m.finishId);
                    return w;
                };

                /**
                 * Decodes a VignetteFinishChanged message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.VignetteFinishChanged} VignetteFinishChanged
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteFinishChanged.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.VignetteFinishChanged();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.vignettElementId = r.string();
                                break;
                            }
                        case 2: {
                                m.finishId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteFinishChanged message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.VignetteFinishChanged} VignetteFinishChanged
                 */
                VignetteFinishChanged.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.VignetteFinishChanged)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.VignetteFinishChanged();
                    if (d.vignettElementId != null) {
                        m.vignettElementId = String(d.vignettElementId);
                    }
                    if (d.finishId != null) {
                        m.finishId = String(d.finishId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteFinishChanged message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {streem.api.AnalyticEvent.VignetteFinishChanged} m VignetteFinishChanged
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteFinishChanged.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.vignettElementId = "";
                        d.finishId = "";
                    }
                    if (m.vignettElementId != null && m.hasOwnProperty("vignettElementId")) {
                        d.vignettElementId = m.vignettElementId;
                    }
                    if (m.finishId != null && m.hasOwnProperty("finishId")) {
                        d.finishId = m.finishId;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteFinishChanged to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteFinishChanged.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteFinishChanged
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.VignetteFinishChanged
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteFinishChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.VignetteFinishChanged";
                };

                return VignetteFinishChanged;
            })();

            AnalyticEvent.VignettePortalVisited = (function() {

                /**
                 * Properties of a VignettePortalVisited.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IVignettePortalVisited
                 * @property {string|null} [hotspotId] VignettePortalVisited hotspotId
                 * @property {string|null} [previousHotspotId] VignettePortalVisited previousHotspotId
                 */

                /**
                 * Constructs a new VignettePortalVisited.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a VignettePortalVisited.
                 * @implements IVignettePortalVisited
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IVignettePortalVisited=} [p] Properties to set
                 */
                function VignettePortalVisited(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignettePortalVisited hotspotId.
                 * @member {string} hotspotId
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @instance
                 */
                VignettePortalVisited.prototype.hotspotId = "";

                /**
                 * VignettePortalVisited previousHotspotId.
                 * @member {string} previousHotspotId
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @instance
                 */
                VignettePortalVisited.prototype.previousHotspotId = "";

                /**
                 * Creates a new VignettePortalVisited instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignettePortalVisited=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.VignettePortalVisited} VignettePortalVisited instance
                 */
                VignettePortalVisited.create = function create(properties) {
                    return new VignettePortalVisited(properties);
                };

                /**
                 * Encodes the specified VignettePortalVisited message. Does not implicitly {@link streem.api.AnalyticEvent.VignettePortalVisited.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignettePortalVisited} m VignettePortalVisited message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignettePortalVisited.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.hotspotId != null && Object.hasOwnProperty.call(m, "hotspotId"))
                        w.uint32(10).string(m.hotspotId);
                    if (m.previousHotspotId != null && Object.hasOwnProperty.call(m, "previousHotspotId"))
                        w.uint32(18).string(m.previousHotspotId);
                    return w;
                };

                /**
                 * Decodes a VignettePortalVisited message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.VignettePortalVisited} VignettePortalVisited
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignettePortalVisited.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.VignettePortalVisited();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.hotspotId = r.string();
                                break;
                            }
                        case 2: {
                                m.previousHotspotId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignettePortalVisited message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.VignettePortalVisited} VignettePortalVisited
                 */
                VignettePortalVisited.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.VignettePortalVisited)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.VignettePortalVisited();
                    if (d.hotspotId != null) {
                        m.hotspotId = String(d.hotspotId);
                    }
                    if (d.previousHotspotId != null) {
                        m.previousHotspotId = String(d.previousHotspotId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignettePortalVisited message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {streem.api.AnalyticEvent.VignettePortalVisited} m VignettePortalVisited
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignettePortalVisited.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.hotspotId = "";
                        d.previousHotspotId = "";
                    }
                    if (m.hotspotId != null && m.hasOwnProperty("hotspotId")) {
                        d.hotspotId = m.hotspotId;
                    }
                    if (m.previousHotspotId != null && m.hasOwnProperty("previousHotspotId")) {
                        d.previousHotspotId = m.previousHotspotId;
                    }
                    return d;
                };

                /**
                 * Converts this VignettePortalVisited to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignettePortalVisited.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignettePortalVisited
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.VignettePortalVisited
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignettePortalVisited.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.VignettePortalVisited";
                };

                return VignettePortalVisited;
            })();

            AnalyticEvent.VignetteHotspotClicked = (function() {

                /**
                 * Properties of a VignetteHotspotClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @interface IVignetteHotspotClicked
                 * @property {string|null} [hotspotId] VignetteHotspotClicked hotspotId
                 * @property {streem.api.AnalyticEvent.VignetteHotspotClicked.HotspotType|null} [hotspotType] VignetteHotspotClicked hotspotType
                 */

                /**
                 * Constructs a new VignetteHotspotClicked.
                 * @memberof streem.api.AnalyticEvent
                 * @classdesc Represents a VignetteHotspotClicked.
                 * @implements IVignetteHotspotClicked
                 * @constructor
                 * @param {streem.api.AnalyticEvent.IVignetteHotspotClicked=} [p] Properties to set
                 */
                function VignetteHotspotClicked(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * VignetteHotspotClicked hotspotId.
                 * @member {string} hotspotId
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @instance
                 */
                VignetteHotspotClicked.prototype.hotspotId = "";

                /**
                 * VignetteHotspotClicked hotspotType.
                 * @member {streem.api.AnalyticEvent.VignetteHotspotClicked.HotspotType} hotspotType
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @instance
                 */
                VignetteHotspotClicked.prototype.hotspotType = 0;

                /**
                 * Creates a new VignetteHotspotClicked instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteHotspotClicked=} [properties] Properties to set
                 * @returns {streem.api.AnalyticEvent.VignetteHotspotClicked} VignetteHotspotClicked instance
                 */
                VignetteHotspotClicked.create = function create(properties) {
                    return new VignetteHotspotClicked(properties);
                };

                /**
                 * Encodes the specified VignetteHotspotClicked message. Does not implicitly {@link streem.api.AnalyticEvent.VignetteHotspotClicked.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.IVignetteHotspotClicked} m VignetteHotspotClicked message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VignetteHotspotClicked.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.hotspotId != null && Object.hasOwnProperty.call(m, "hotspotId"))
                        w.uint32(10).string(m.hotspotId);
                    if (m.hotspotType != null && Object.hasOwnProperty.call(m, "hotspotType"))
                        w.uint32(16).int32(m.hotspotType);
                    return w;
                };

                /**
                 * Decodes a VignetteHotspotClicked message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AnalyticEvent.VignetteHotspotClicked} VignetteHotspotClicked
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VignetteHotspotClicked.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AnalyticEvent.VignetteHotspotClicked();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.hotspotId = r.string();
                                break;
                            }
                        case 2: {
                                m.hotspotType = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a VignetteHotspotClicked message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AnalyticEvent.VignetteHotspotClicked} VignetteHotspotClicked
                 */
                VignetteHotspotClicked.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AnalyticEvent.VignetteHotspotClicked)
                        return d;
                    var m = new $root.streem.api.AnalyticEvent.VignetteHotspotClicked();
                    if (d.hotspotId != null) {
                        m.hotspotId = String(d.hotspotId);
                    }
                    switch (d.hotspotType) {
                    default:
                        if (typeof d.hotspotType === "number") {
                            m.hotspotType = d.hotspotType;
                            break;
                        }
                        break;
                    case "HOTSPOT_TYPE_INVALID":
                    case 0:
                        m.hotspotType = 0;
                        break;
                    case "HOTSPOT_TYPE_PORTAL":
                    case 1:
                        m.hotspotType = 1;
                        break;
                    case "HOTSPOT_TYPE_MODAL":
                    case 2:
                        m.hotspotType = 2;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a VignetteHotspotClicked message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {streem.api.AnalyticEvent.VignetteHotspotClicked} m VignetteHotspotClicked
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VignetteHotspotClicked.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.hotspotId = "";
                        d.hotspotType = o.enums === String ? "HOTSPOT_TYPE_INVALID" : 0;
                    }
                    if (m.hotspotId != null && m.hasOwnProperty("hotspotId")) {
                        d.hotspotId = m.hotspotId;
                    }
                    if (m.hotspotType != null && m.hasOwnProperty("hotspotType")) {
                        d.hotspotType = o.enums === String ? $root.streem.api.AnalyticEvent.VignetteHotspotClicked.HotspotType[m.hotspotType] === undefined ? m.hotspotType : $root.streem.api.AnalyticEvent.VignetteHotspotClicked.HotspotType[m.hotspotType] : m.hotspotType;
                    }
                    return d;
                };

                /**
                 * Converts this VignetteHotspotClicked to JSON.
                 * @function toJSON
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VignetteHotspotClicked.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VignetteHotspotClicked
                 * @function getTypeUrl
                 * @memberof streem.api.AnalyticEvent.VignetteHotspotClicked
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VignetteHotspotClicked.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AnalyticEvent.VignetteHotspotClicked";
                };

                /**
                 * HotspotType enum.
                 * @name streem.api.AnalyticEvent.VignetteHotspotClicked.HotspotType
                 * @enum {number}
                 * @property {number} HOTSPOT_TYPE_INVALID=0 HOTSPOT_TYPE_INVALID value
                 * @property {number} HOTSPOT_TYPE_PORTAL=1 HOTSPOT_TYPE_PORTAL value
                 * @property {number} HOTSPOT_TYPE_MODAL=2 HOTSPOT_TYPE_MODAL value
                 */
                VignetteHotspotClicked.HotspotType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "HOTSPOT_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "HOTSPOT_TYPE_PORTAL"] = 1;
                    values[valuesById[2] = "HOTSPOT_TYPE_MODAL"] = 2;
                    return values;
                })();

                return VignetteHotspotClicked;
            })();

            return AnalyticEvent;
        })();

        /**
         * WorkflowType enum.
         * @name streem.api.WorkflowType
         * @enum {number}
         * @property {number} WORKFLOW_TYPE_INVALID=0 WORKFLOW_TYPE_INVALID value
         * @property {number} WORKFLOW_TYPE_STREEM_SUCCESS=2 WORKFLOW_TYPE_STREEM_SUCCESS value
         * @property {number} WORKFLOW_TYPE_GUIDED_SCAN=3 WORKFLOW_TYPE_GUIDED_SCAN value
         */
        api.WorkflowType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WORKFLOW_TYPE_INVALID"] = 0;
            values[valuesById[2] = "WORKFLOW_TYPE_STREEM_SUCCESS"] = 2;
            values[valuesById[3] = "WORKFLOW_TYPE_GUIDED_SCAN"] = 3;
            return values;
        })();

        api.Apps = (function() {

            /**
             * Constructs a new Apps service.
             * @memberof streem.api
             * @classdesc Represents an Apps
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Apps(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Apps.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Apps;

            /**
             * Creates new Apps service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Apps
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Apps} RPC service. Useful where requests and/or responses are streamed.
             */
            Apps.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Apps#listApps}.
             * @memberof streem.api.Apps
             * @typedef ListAppsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListAppsResponse} [response] ListAppsResponse
             */

            /**
             * Calls ListApps.
             * @function listApps
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IListAppsRequest} request ListAppsRequest message or plain object
             * @param {streem.api.Apps.ListAppsCallback} callback Node-style callback called with the error, if any, and ListAppsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Apps.prototype.listApps = function listApps(request, callback) {
                return this.rpcCall(listApps, $root.streem.api.ListAppsRequest, $root.streem.api.ListAppsResponse, request, callback);
            }, "name", { value: "ListApps" });

            /**
             * Calls ListApps.
             * @function listApps
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IListAppsRequest} request ListAppsRequest message or plain object
             * @returns {Promise<streem.api.ListAppsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Apps#createApp}.
             * @memberof streem.api.Apps
             * @typedef CreateAppCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateAppResponse} [response] CreateAppResponse
             */

            /**
             * Calls CreateApp.
             * @function createApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.ICreateAppRequest} request CreateAppRequest message or plain object
             * @param {streem.api.Apps.CreateAppCallback} callback Node-style callback called with the error, if any, and CreateAppResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Apps.prototype.createApp = function createApp(request, callback) {
                return this.rpcCall(createApp, $root.streem.api.CreateAppRequest, $root.streem.api.CreateAppResponse, request, callback);
            }, "name", { value: "CreateApp" });

            /**
             * Calls CreateApp.
             * @function createApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.ICreateAppRequest} request CreateAppRequest message or plain object
             * @returns {Promise<streem.api.CreateAppResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Apps#importGlobalApp}.
             * @memberof streem.api.Apps
             * @typedef ImportGlobalAppCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ImportGlobalAppResponse} [response] ImportGlobalAppResponse
             */

            /**
             * Calls ImportGlobalApp.
             * @function importGlobalApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IImportGlobalAppRequest} request ImportGlobalAppRequest message or plain object
             * @param {streem.api.Apps.ImportGlobalAppCallback} callback Node-style callback called with the error, if any, and ImportGlobalAppResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Apps.prototype.importGlobalApp = function importGlobalApp(request, callback) {
                return this.rpcCall(importGlobalApp, $root.streem.api.ImportGlobalAppRequest, $root.streem.api.ImportGlobalAppResponse, request, callback);
            }, "name", { value: "ImportGlobalApp" });

            /**
             * Calls ImportGlobalApp.
             * @function importGlobalApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IImportGlobalAppRequest} request ImportGlobalAppRequest message or plain object
             * @returns {Promise<streem.api.ImportGlobalAppResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Apps#getApp}.
             * @memberof streem.api.Apps
             * @typedef GetAppCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetAppResponse} [response] GetAppResponse
             */

            /**
             * Calls GetApp.
             * @function getApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IGetAppRequest} request GetAppRequest message or plain object
             * @param {streem.api.Apps.GetAppCallback} callback Node-style callback called with the error, if any, and GetAppResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Apps.prototype.getApp = function getApp(request, callback) {
                return this.rpcCall(getApp, $root.streem.api.GetAppRequest, $root.streem.api.GetAppResponse, request, callback);
            }, "name", { value: "GetApp" });

            /**
             * Calls GetApp.
             * @function getApp
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IGetAppRequest} request GetAppRequest message or plain object
             * @returns {Promise<streem.api.GetAppResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Apps#updateAppIosVoipCredentials}.
             * @memberof streem.api.Apps
             * @typedef UpdateAppIosVoipCredentialsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateAppIosVoipCredentials.
             * @function updateAppIosVoipCredentials
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IUpdateAppIosVoipCredentialsRequest} request UpdateAppIosVoipCredentialsRequest message or plain object
             * @param {streem.api.Apps.UpdateAppIosVoipCredentialsCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Apps.prototype.updateAppIosVoipCredentials = function updateAppIosVoipCredentials(request, callback) {
                return this.rpcCall(updateAppIosVoipCredentials, $root.streem.api.UpdateAppIosVoipCredentialsRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateAppIosVoipCredentials" });

            /**
             * Calls UpdateAppIosVoipCredentials.
             * @function updateAppIosVoipCredentials
             * @memberof streem.api.Apps
             * @instance
             * @param {streem.api.IUpdateAppIosVoipCredentialsRequest} request UpdateAppIosVoipCredentialsRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Apps;
        })();

        api.CreateAppRequest = (function() {

            /**
             * Properties of a CreateAppRequest.
             * @memberof streem.api
             * @interface ICreateAppRequest
             * @property {string|null} [companySid] CreateAppRequest companySid
             * @property {streem.api.AppPlatform|null} [platform] CreateAppRequest platform
             * @property {string|null} [platformAppId] CreateAppRequest platformAppId
             * @property {boolean|null} [allowMultiCompanyLogin] CreateAppRequest allowMultiCompanyLogin
             */

            /**
             * Constructs a new CreateAppRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateAppRequest.
             * @implements ICreateAppRequest
             * @constructor
             * @param {streem.api.ICreateAppRequest=} [p] Properties to set
             */
            function CreateAppRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateAppRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.CreateAppRequest
             * @instance
             */
            CreateAppRequest.prototype.companySid = "";

            /**
             * CreateAppRequest platform.
             * @member {streem.api.AppPlatform} platform
             * @memberof streem.api.CreateAppRequest
             * @instance
             */
            CreateAppRequest.prototype.platform = 0;

            /**
             * CreateAppRequest platformAppId.
             * @member {string} platformAppId
             * @memberof streem.api.CreateAppRequest
             * @instance
             */
            CreateAppRequest.prototype.platformAppId = "";

            /**
             * CreateAppRequest allowMultiCompanyLogin.
             * @member {boolean} allowMultiCompanyLogin
             * @memberof streem.api.CreateAppRequest
             * @instance
             */
            CreateAppRequest.prototype.allowMultiCompanyLogin = false;

            /**
             * Creates a new CreateAppRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {streem.api.ICreateAppRequest=} [properties] Properties to set
             * @returns {streem.api.CreateAppRequest} CreateAppRequest instance
             */
            CreateAppRequest.create = function create(properties) {
                return new CreateAppRequest(properties);
            };

            /**
             * Encodes the specified CreateAppRequest message. Does not implicitly {@link streem.api.CreateAppRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {streem.api.ICreateAppRequest} m CreateAppRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAppRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.platform != null && Object.hasOwnProperty.call(m, "platform"))
                    w.uint32(24).int32(m.platform);
                if (m.platformAppId != null && Object.hasOwnProperty.call(m, "platformAppId"))
                    w.uint32(34).string(m.platformAppId);
                if (m.allowMultiCompanyLogin != null && Object.hasOwnProperty.call(m, "allowMultiCompanyLogin"))
                    w.uint32(40).bool(m.allowMultiCompanyLogin);
                return w;
            };

            /**
             * Decodes a CreateAppRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateAppRequest} CreateAppRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAppRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateAppRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 3: {
                            m.platform = r.int32();
                            break;
                        }
                    case 4: {
                            m.platformAppId = r.string();
                            break;
                        }
                    case 5: {
                            m.allowMultiCompanyLogin = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateAppRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateAppRequest} CreateAppRequest
             */
            CreateAppRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateAppRequest)
                    return d;
                var m = new $root.streem.api.CreateAppRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                switch (d.platform) {
                default:
                    if (typeof d.platform === "number") {
                        m.platform = d.platform;
                        break;
                    }
                    break;
                case "APP_PLATFORM_INVALID":
                case 0:
                    m.platform = 0;
                    break;
                case "APP_PLATFORM_IOS":
                case 1:
                    m.platform = 1;
                    break;
                case "APP_PLATFORM_ANDROID":
                case 2:
                    m.platform = 2;
                    break;
                case "APP_PLATFORM_WEB":
                case 3:
                    m.platform = 3;
                    break;
                }
                if (d.platformAppId != null) {
                    m.platformAppId = String(d.platformAppId);
                }
                if (d.allowMultiCompanyLogin != null) {
                    m.allowMultiCompanyLogin = Boolean(d.allowMultiCompanyLogin);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateAppRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {streem.api.CreateAppRequest} m CreateAppRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAppRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companySid = "";
                    d.platform = o.enums === String ? "APP_PLATFORM_INVALID" : 0;
                    d.platformAppId = "";
                    d.allowMultiCompanyLogin = false;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.platform != null && m.hasOwnProperty("platform")) {
                    d.platform = o.enums === String ? $root.streem.api.AppPlatform[m.platform] === undefined ? m.platform : $root.streem.api.AppPlatform[m.platform] : m.platform;
                }
                if (m.platformAppId != null && m.hasOwnProperty("platformAppId")) {
                    d.platformAppId = m.platformAppId;
                }
                if (m.allowMultiCompanyLogin != null && m.hasOwnProperty("allowMultiCompanyLogin")) {
                    d.allowMultiCompanyLogin = m.allowMultiCompanyLogin;
                }
                return d;
            };

            /**
             * Converts this CreateAppRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateAppRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAppRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateAppRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateAppRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateAppRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateAppRequest";
            };

            return CreateAppRequest;
        })();

        api.CreateAppResponse = (function() {

            /**
             * Properties of a CreateAppResponse.
             * @memberof streem.api
             * @interface ICreateAppResponse
             * @property {streem.api.IApp|null} [app] CreateAppResponse app
             */

            /**
             * Constructs a new CreateAppResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateAppResponse.
             * @implements ICreateAppResponse
             * @constructor
             * @param {streem.api.ICreateAppResponse=} [p] Properties to set
             */
            function CreateAppResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateAppResponse app.
             * @member {streem.api.IApp|null|undefined} app
             * @memberof streem.api.CreateAppResponse
             * @instance
             */
            CreateAppResponse.prototype.app = null;

            /**
             * Creates a new CreateAppResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {streem.api.ICreateAppResponse=} [properties] Properties to set
             * @returns {streem.api.CreateAppResponse} CreateAppResponse instance
             */
            CreateAppResponse.create = function create(properties) {
                return new CreateAppResponse(properties);
            };

            /**
             * Encodes the specified CreateAppResponse message. Does not implicitly {@link streem.api.CreateAppResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {streem.api.ICreateAppResponse} m CreateAppResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAppResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                    $root.streem.api.App.encode(m.app, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateAppResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateAppResponse} CreateAppResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAppResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateAppResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.app = $root.streem.api.App.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateAppResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateAppResponse} CreateAppResponse
             */
            CreateAppResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateAppResponse)
                    return d;
                var m = new $root.streem.api.CreateAppResponse();
                if (d.app != null) {
                    if (typeof d.app !== "object")
                        throw TypeError(".streem.api.CreateAppResponse.app: object expected");
                    m.app = $root.streem.api.App.fromObject(d.app);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateAppResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {streem.api.CreateAppResponse} m CreateAppResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAppResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.app = null;
                }
                if (m.app != null && m.hasOwnProperty("app")) {
                    d.app = $root.streem.api.App.toObject(m.app, o);
                }
                return d;
            };

            /**
             * Converts this CreateAppResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateAppResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAppResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateAppResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateAppResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateAppResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateAppResponse";
            };

            return CreateAppResponse;
        })();

        api.ImportGlobalAppRequest = (function() {

            /**
             * Properties of an ImportGlobalAppRequest.
             * @memberof streem.api
             * @interface IImportGlobalAppRequest
             * @property {string|null} [globalAppSid] ImportGlobalAppRequest globalAppSid
             */

            /**
             * Constructs a new ImportGlobalAppRequest.
             * @memberof streem.api
             * @classdesc Represents an ImportGlobalAppRequest.
             * @implements IImportGlobalAppRequest
             * @constructor
             * @param {streem.api.IImportGlobalAppRequest=} [p] Properties to set
             */
            function ImportGlobalAppRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ImportGlobalAppRequest globalAppSid.
             * @member {string} globalAppSid
             * @memberof streem.api.ImportGlobalAppRequest
             * @instance
             */
            ImportGlobalAppRequest.prototype.globalAppSid = "";

            /**
             * Creates a new ImportGlobalAppRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {streem.api.IImportGlobalAppRequest=} [properties] Properties to set
             * @returns {streem.api.ImportGlobalAppRequest} ImportGlobalAppRequest instance
             */
            ImportGlobalAppRequest.create = function create(properties) {
                return new ImportGlobalAppRequest(properties);
            };

            /**
             * Encodes the specified ImportGlobalAppRequest message. Does not implicitly {@link streem.api.ImportGlobalAppRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {streem.api.IImportGlobalAppRequest} m ImportGlobalAppRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImportGlobalAppRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.globalAppSid != null && Object.hasOwnProperty.call(m, "globalAppSid"))
                    w.uint32(10).string(m.globalAppSid);
                return w;
            };

            /**
             * Decodes an ImportGlobalAppRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ImportGlobalAppRequest} ImportGlobalAppRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImportGlobalAppRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ImportGlobalAppRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.globalAppSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ImportGlobalAppRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ImportGlobalAppRequest} ImportGlobalAppRequest
             */
            ImportGlobalAppRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ImportGlobalAppRequest)
                    return d;
                var m = new $root.streem.api.ImportGlobalAppRequest();
                if (d.globalAppSid != null) {
                    m.globalAppSid = String(d.globalAppSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an ImportGlobalAppRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {streem.api.ImportGlobalAppRequest} m ImportGlobalAppRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImportGlobalAppRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.globalAppSid = "";
                }
                if (m.globalAppSid != null && m.hasOwnProperty("globalAppSid")) {
                    d.globalAppSid = m.globalAppSid;
                }
                return d;
            };

            /**
             * Converts this ImportGlobalAppRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ImportGlobalAppRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImportGlobalAppRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ImportGlobalAppRequest
             * @function getTypeUrl
             * @memberof streem.api.ImportGlobalAppRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ImportGlobalAppRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ImportGlobalAppRequest";
            };

            return ImportGlobalAppRequest;
        })();

        api.ImportGlobalAppResponse = (function() {

            /**
             * Properties of an ImportGlobalAppResponse.
             * @memberof streem.api
             * @interface IImportGlobalAppResponse
             * @property {streem.api.IApp|null} [app] ImportGlobalAppResponse app
             */

            /**
             * Constructs a new ImportGlobalAppResponse.
             * @memberof streem.api
             * @classdesc Represents an ImportGlobalAppResponse.
             * @implements IImportGlobalAppResponse
             * @constructor
             * @param {streem.api.IImportGlobalAppResponse=} [p] Properties to set
             */
            function ImportGlobalAppResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ImportGlobalAppResponse app.
             * @member {streem.api.IApp|null|undefined} app
             * @memberof streem.api.ImportGlobalAppResponse
             * @instance
             */
            ImportGlobalAppResponse.prototype.app = null;

            /**
             * Creates a new ImportGlobalAppResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {streem.api.IImportGlobalAppResponse=} [properties] Properties to set
             * @returns {streem.api.ImportGlobalAppResponse} ImportGlobalAppResponse instance
             */
            ImportGlobalAppResponse.create = function create(properties) {
                return new ImportGlobalAppResponse(properties);
            };

            /**
             * Encodes the specified ImportGlobalAppResponse message. Does not implicitly {@link streem.api.ImportGlobalAppResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {streem.api.IImportGlobalAppResponse} m ImportGlobalAppResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImportGlobalAppResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                    $root.streem.api.App.encode(m.app, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an ImportGlobalAppResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ImportGlobalAppResponse} ImportGlobalAppResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImportGlobalAppResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ImportGlobalAppResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.app = $root.streem.api.App.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ImportGlobalAppResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ImportGlobalAppResponse} ImportGlobalAppResponse
             */
            ImportGlobalAppResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ImportGlobalAppResponse)
                    return d;
                var m = new $root.streem.api.ImportGlobalAppResponse();
                if (d.app != null) {
                    if (typeof d.app !== "object")
                        throw TypeError(".streem.api.ImportGlobalAppResponse.app: object expected");
                    m.app = $root.streem.api.App.fromObject(d.app);
                }
                return m;
            };

            /**
             * Creates a plain object from an ImportGlobalAppResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {streem.api.ImportGlobalAppResponse} m ImportGlobalAppResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImportGlobalAppResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.app = null;
                }
                if (m.app != null && m.hasOwnProperty("app")) {
                    d.app = $root.streem.api.App.toObject(m.app, o);
                }
                return d;
            };

            /**
             * Converts this ImportGlobalAppResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ImportGlobalAppResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImportGlobalAppResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ImportGlobalAppResponse
             * @function getTypeUrl
             * @memberof streem.api.ImportGlobalAppResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ImportGlobalAppResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ImportGlobalAppResponse";
            };

            return ImportGlobalAppResponse;
        })();

        api.ListAppsRequest = (function() {

            /**
             * Properties of a ListAppsRequest.
             * @memberof streem.api
             * @interface IListAppsRequest
             * @property {number|null} [pageSize] ListAppsRequest pageSize
             * @property {string|null} [pageToken] ListAppsRequest pageToken
             * @property {string|null} [companySid] ListAppsRequest companySid
             * @property {google.protobuf.IBoolValue|null} [multiCompanyLoginAllowed] ListAppsRequest multiCompanyLoginAllowed
             */

            /**
             * Constructs a new ListAppsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListAppsRequest.
             * @implements IListAppsRequest
             * @constructor
             * @param {streem.api.IListAppsRequest=} [p] Properties to set
             */
            function ListAppsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListAppsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListAppsRequest
             * @instance
             */
            ListAppsRequest.prototype.pageSize = 0;

            /**
             * ListAppsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListAppsRequest
             * @instance
             */
            ListAppsRequest.prototype.pageToken = "";

            /**
             * ListAppsRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.ListAppsRequest
             * @instance
             */
            ListAppsRequest.prototype.companySid = "";

            /**
             * ListAppsRequest multiCompanyLoginAllowed.
             * @member {google.protobuf.IBoolValue|null|undefined} multiCompanyLoginAllowed
             * @memberof streem.api.ListAppsRequest
             * @instance
             */
            ListAppsRequest.prototype.multiCompanyLoginAllowed = null;

            /**
             * Creates a new ListAppsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {streem.api.IListAppsRequest=} [properties] Properties to set
             * @returns {streem.api.ListAppsRequest} ListAppsRequest instance
             */
            ListAppsRequest.create = function create(properties) {
                return new ListAppsRequest(properties);
            };

            /**
             * Encodes the specified ListAppsRequest message. Does not implicitly {@link streem.api.ListAppsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {streem.api.IListAppsRequest} m ListAppsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAppsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(8).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(18).string(m.pageToken);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(26).string(m.companySid);
                if (m.multiCompanyLoginAllowed != null && Object.hasOwnProperty.call(m, "multiCompanyLoginAllowed"))
                    $root.google.protobuf.BoolValue.encode(m.multiCompanyLoginAllowed, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ListAppsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListAppsRequest} ListAppsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAppsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListAppsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 2: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.companySid = r.string();
                            break;
                        }
                    case 4: {
                            m.multiCompanyLoginAllowed = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListAppsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListAppsRequest} ListAppsRequest
             */
            ListAppsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListAppsRequest)
                    return d;
                var m = new $root.streem.api.ListAppsRequest();
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.multiCompanyLoginAllowed != null) {
                    if (typeof d.multiCompanyLoginAllowed !== "object")
                        throw TypeError(".streem.api.ListAppsRequest.multiCompanyLoginAllowed: object expected");
                    m.multiCompanyLoginAllowed = $root.google.protobuf.BoolValue.fromObject(d.multiCompanyLoginAllowed);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListAppsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {streem.api.ListAppsRequest} m ListAppsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListAppsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.companySid = "";
                    d.multiCompanyLoginAllowed = null;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.multiCompanyLoginAllowed != null && m.hasOwnProperty("multiCompanyLoginAllowed")) {
                    d.multiCompanyLoginAllowed = $root.google.protobuf.BoolValue.toObject(m.multiCompanyLoginAllowed, o);
                }
                return d;
            };

            /**
             * Converts this ListAppsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListAppsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListAppsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListAppsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListAppsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListAppsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListAppsRequest";
            };

            return ListAppsRequest;
        })();

        api.ListAppsResponse = (function() {

            /**
             * Properties of a ListAppsResponse.
             * @memberof streem.api
             * @interface IListAppsResponse
             * @property {Array.<streem.api.IApp>|null} [apps] ListAppsResponse apps
             * @property {string|null} [nextPageToken] ListAppsResponse nextPageToken
             * @property {number|null} [totalSize] ListAppsResponse totalSize
             */

            /**
             * Constructs a new ListAppsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListAppsResponse.
             * @implements IListAppsResponse
             * @constructor
             * @param {streem.api.IListAppsResponse=} [p] Properties to set
             */
            function ListAppsResponse(p) {
                this.apps = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListAppsResponse apps.
             * @member {Array.<streem.api.IApp>} apps
             * @memberof streem.api.ListAppsResponse
             * @instance
             */
            ListAppsResponse.prototype.apps = $util.emptyArray;

            /**
             * ListAppsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListAppsResponse
             * @instance
             */
            ListAppsResponse.prototype.nextPageToken = "";

            /**
             * ListAppsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListAppsResponse
             * @instance
             */
            ListAppsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListAppsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {streem.api.IListAppsResponse=} [properties] Properties to set
             * @returns {streem.api.ListAppsResponse} ListAppsResponse instance
             */
            ListAppsResponse.create = function create(properties) {
                return new ListAppsResponse(properties);
            };

            /**
             * Encodes the specified ListAppsResponse message. Does not implicitly {@link streem.api.ListAppsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {streem.api.IListAppsResponse} m ListAppsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListAppsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apps != null && m.apps.length) {
                    for (var i = 0; i < m.apps.length; ++i)
                        $root.streem.api.App.encode(m.apps[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListAppsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListAppsResponse} ListAppsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListAppsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListAppsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.apps && m.apps.length))
                                m.apps = [];
                            m.apps.push($root.streem.api.App.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListAppsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListAppsResponse} ListAppsResponse
             */
            ListAppsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListAppsResponse)
                    return d;
                var m = new $root.streem.api.ListAppsResponse();
                if (d.apps) {
                    if (!Array.isArray(d.apps))
                        throw TypeError(".streem.api.ListAppsResponse.apps: array expected");
                    m.apps = [];
                    for (var i = 0; i < d.apps.length; ++i) {
                        if (typeof d.apps[i] !== "object")
                            throw TypeError(".streem.api.ListAppsResponse.apps: object expected");
                        m.apps[i] = $root.streem.api.App.fromObject(d.apps[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListAppsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {streem.api.ListAppsResponse} m ListAppsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListAppsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.apps = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.apps && m.apps.length) {
                    d.apps = [];
                    for (var j = 0; j < m.apps.length; ++j) {
                        d.apps[j] = $root.streem.api.App.toObject(m.apps[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListAppsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListAppsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListAppsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListAppsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListAppsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListAppsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListAppsResponse";
            };

            return ListAppsResponse;
        })();

        api.GetAppRequest = (function() {

            /**
             * Properties of a GetAppRequest.
             * @memberof streem.api
             * @interface IGetAppRequest
             * @property {string|null} [appSid] GetAppRequest appSid
             */

            /**
             * Constructs a new GetAppRequest.
             * @memberof streem.api
             * @classdesc Represents a GetAppRequest.
             * @implements IGetAppRequest
             * @constructor
             * @param {streem.api.IGetAppRequest=} [p] Properties to set
             */
            function GetAppRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetAppRequest appSid.
             * @member {string} appSid
             * @memberof streem.api.GetAppRequest
             * @instance
             */
            GetAppRequest.prototype.appSid = "";

            /**
             * Creates a new GetAppRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {streem.api.IGetAppRequest=} [properties] Properties to set
             * @returns {streem.api.GetAppRequest} GetAppRequest instance
             */
            GetAppRequest.create = function create(properties) {
                return new GetAppRequest(properties);
            };

            /**
             * Encodes the specified GetAppRequest message. Does not implicitly {@link streem.api.GetAppRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {streem.api.IGetAppRequest} m GetAppRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAppRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                    w.uint32(10).string(m.appSid);
                return w;
            };

            /**
             * Decodes a GetAppRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAppRequest} GetAppRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAppRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAppRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.appSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAppRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAppRequest} GetAppRequest
             */
            GetAppRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAppRequest)
                    return d;
                var m = new $root.streem.api.GetAppRequest();
                if (d.appSid != null) {
                    m.appSid = String(d.appSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetAppRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {streem.api.GetAppRequest} m GetAppRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAppRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.appSid = "";
                }
                if (m.appSid != null && m.hasOwnProperty("appSid")) {
                    d.appSid = m.appSid;
                }
                return d;
            };

            /**
             * Converts this GetAppRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetAppRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAppRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAppRequest
             * @function getTypeUrl
             * @memberof streem.api.GetAppRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAppRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAppRequest";
            };

            return GetAppRequest;
        })();

        api.GetAppResponse = (function() {

            /**
             * Properties of a GetAppResponse.
             * @memberof streem.api
             * @interface IGetAppResponse
             * @property {streem.api.IApp|null} [app] GetAppResponse app
             */

            /**
             * Constructs a new GetAppResponse.
             * @memberof streem.api
             * @classdesc Represents a GetAppResponse.
             * @implements IGetAppResponse
             * @constructor
             * @param {streem.api.IGetAppResponse=} [p] Properties to set
             */
            function GetAppResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetAppResponse app.
             * @member {streem.api.IApp|null|undefined} app
             * @memberof streem.api.GetAppResponse
             * @instance
             */
            GetAppResponse.prototype.app = null;

            /**
             * Creates a new GetAppResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {streem.api.IGetAppResponse=} [properties] Properties to set
             * @returns {streem.api.GetAppResponse} GetAppResponse instance
             */
            GetAppResponse.create = function create(properties) {
                return new GetAppResponse(properties);
            };

            /**
             * Encodes the specified GetAppResponse message. Does not implicitly {@link streem.api.GetAppResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {streem.api.IGetAppResponse} m GetAppResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAppResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                    $root.streem.api.App.encode(m.app, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetAppResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAppResponse} GetAppResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAppResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAppResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.app = $root.streem.api.App.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAppResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAppResponse} GetAppResponse
             */
            GetAppResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAppResponse)
                    return d;
                var m = new $root.streem.api.GetAppResponse();
                if (d.app != null) {
                    if (typeof d.app !== "object")
                        throw TypeError(".streem.api.GetAppResponse.app: object expected");
                    m.app = $root.streem.api.App.fromObject(d.app);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetAppResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {streem.api.GetAppResponse} m GetAppResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAppResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.app = null;
                }
                if (m.app != null && m.hasOwnProperty("app")) {
                    d.app = $root.streem.api.App.toObject(m.app, o);
                }
                return d;
            };

            /**
             * Converts this GetAppResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetAppResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAppResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAppResponse
             * @function getTypeUrl
             * @memberof streem.api.GetAppResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAppResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAppResponse";
            };

            return GetAppResponse;
        })();

        api.UpdateAppIosVoipCredentialsRequest = (function() {

            /**
             * Properties of an UpdateAppIosVoipCredentialsRequest.
             * @memberof streem.api
             * @interface IUpdateAppIosVoipCredentialsRequest
             * @property {string|null} [appSid] UpdateAppIosVoipCredentialsRequest appSid
             * @property {string|null} [sslCertificate] UpdateAppIosVoipCredentialsRequest sslCertificate
             * @property {string|null} [privateKey] UpdateAppIosVoipCredentialsRequest privateKey
             */

            /**
             * Constructs a new UpdateAppIosVoipCredentialsRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateAppIosVoipCredentialsRequest.
             * @implements IUpdateAppIosVoipCredentialsRequest
             * @constructor
             * @param {streem.api.IUpdateAppIosVoipCredentialsRequest=} [p] Properties to set
             */
            function UpdateAppIosVoipCredentialsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateAppIosVoipCredentialsRequest appSid.
             * @member {string} appSid
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @instance
             */
            UpdateAppIosVoipCredentialsRequest.prototype.appSid = "";

            /**
             * UpdateAppIosVoipCredentialsRequest sslCertificate.
             * @member {string} sslCertificate
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @instance
             */
            UpdateAppIosVoipCredentialsRequest.prototype.sslCertificate = "";

            /**
             * UpdateAppIosVoipCredentialsRequest privateKey.
             * @member {string} privateKey
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @instance
             */
            UpdateAppIosVoipCredentialsRequest.prototype.privateKey = "";

            /**
             * Creates a new UpdateAppIosVoipCredentialsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {streem.api.IUpdateAppIosVoipCredentialsRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateAppIosVoipCredentialsRequest} UpdateAppIosVoipCredentialsRequest instance
             */
            UpdateAppIosVoipCredentialsRequest.create = function create(properties) {
                return new UpdateAppIosVoipCredentialsRequest(properties);
            };

            /**
             * Encodes the specified UpdateAppIosVoipCredentialsRequest message. Does not implicitly {@link streem.api.UpdateAppIosVoipCredentialsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {streem.api.IUpdateAppIosVoipCredentialsRequest} m UpdateAppIosVoipCredentialsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateAppIosVoipCredentialsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                    w.uint32(10).string(m.appSid);
                if (m.sslCertificate != null && Object.hasOwnProperty.call(m, "sslCertificate"))
                    w.uint32(18).string(m.sslCertificate);
                if (m.privateKey != null && Object.hasOwnProperty.call(m, "privateKey"))
                    w.uint32(26).string(m.privateKey);
                return w;
            };

            /**
             * Decodes an UpdateAppIosVoipCredentialsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateAppIosVoipCredentialsRequest} UpdateAppIosVoipCredentialsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateAppIosVoipCredentialsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateAppIosVoipCredentialsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.appSid = r.string();
                            break;
                        }
                    case 2: {
                            m.sslCertificate = r.string();
                            break;
                        }
                    case 3: {
                            m.privateKey = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateAppIosVoipCredentialsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateAppIosVoipCredentialsRequest} UpdateAppIosVoipCredentialsRequest
             */
            UpdateAppIosVoipCredentialsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateAppIosVoipCredentialsRequest)
                    return d;
                var m = new $root.streem.api.UpdateAppIosVoipCredentialsRequest();
                if (d.appSid != null) {
                    m.appSid = String(d.appSid);
                }
                if (d.sslCertificate != null) {
                    m.sslCertificate = String(d.sslCertificate);
                }
                if (d.privateKey != null) {
                    m.privateKey = String(d.privateKey);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateAppIosVoipCredentialsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {streem.api.UpdateAppIosVoipCredentialsRequest} m UpdateAppIosVoipCredentialsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateAppIosVoipCredentialsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.appSid = "";
                    d.sslCertificate = "";
                    d.privateKey = "";
                }
                if (m.appSid != null && m.hasOwnProperty("appSid")) {
                    d.appSid = m.appSid;
                }
                if (m.sslCertificate != null && m.hasOwnProperty("sslCertificate")) {
                    d.sslCertificate = m.sslCertificate;
                }
                if (m.privateKey != null && m.hasOwnProperty("privateKey")) {
                    d.privateKey = m.privateKey;
                }
                return d;
            };

            /**
             * Converts this UpdateAppIosVoipCredentialsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateAppIosVoipCredentialsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateAppIosVoipCredentialsRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateAppIosVoipCredentialsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateAppIosVoipCredentialsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateAppIosVoipCredentialsRequest";
            };

            return UpdateAppIosVoipCredentialsRequest;
        })();

        api.Tag = (function() {

            /**
             * Properties of a Tag.
             * @memberof streem.api
             * @interface ITag
             * @property {string|null} [name] Tag name
             * @property {string|null} [value] Tag value
             */

            /**
             * Constructs a new Tag.
             * @memberof streem.api
             * @classdesc Represents a Tag.
             * @implements ITag
             * @constructor
             * @param {streem.api.ITag=} [p] Properties to set
             */
            function Tag(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Tag name.
             * @member {string} name
             * @memberof streem.api.Tag
             * @instance
             */
            Tag.prototype.name = "";

            /**
             * Tag value.
             * @member {string} value
             * @memberof streem.api.Tag
             * @instance
             */
            Tag.prototype.value = "";

            /**
             * Creates a new Tag instance using the specified properties.
             * @function create
             * @memberof streem.api.Tag
             * @static
             * @param {streem.api.ITag=} [properties] Properties to set
             * @returns {streem.api.Tag} Tag instance
             */
            Tag.create = function create(properties) {
                return new Tag(properties);
            };

            /**
             * Encodes the specified Tag message. Does not implicitly {@link streem.api.Tag.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Tag
             * @static
             * @param {streem.api.ITag} m Tag message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tag.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(18).string(m.value);
                return w;
            };

            /**
             * Decodes a Tag message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Tag
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Tag} Tag
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tag.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Tag();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.value = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Tag message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Tag
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Tag} Tag
             */
            Tag.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Tag)
                    return d;
                var m = new $root.streem.api.Tag();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a Tag message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Tag
             * @static
             * @param {streem.api.Tag} m Tag
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tag.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.value = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this Tag to JSON.
             * @function toJSON
             * @memberof streem.api.Tag
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tag.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Tag
             * @function getTypeUrl
             * @memberof streem.api.Tag
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Tag";
            };

            return Tag;
        })();

        api.TagDefinition = (function() {

            /**
             * Properties of a TagDefinition.
             * @memberof streem.api
             * @interface ITagDefinition
             * @property {string|null} [name] TagDefinition name
             */

            /**
             * Constructs a new TagDefinition.
             * @memberof streem.api
             * @classdesc Represents a TagDefinition.
             * @implements ITagDefinition
             * @constructor
             * @param {streem.api.ITagDefinition=} [p] Properties to set
             */
            function TagDefinition(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TagDefinition name.
             * @member {string} name
             * @memberof streem.api.TagDefinition
             * @instance
             */
            TagDefinition.prototype.name = "";

            /**
             * Creates a new TagDefinition instance using the specified properties.
             * @function create
             * @memberof streem.api.TagDefinition
             * @static
             * @param {streem.api.ITagDefinition=} [properties] Properties to set
             * @returns {streem.api.TagDefinition} TagDefinition instance
             */
            TagDefinition.create = function create(properties) {
                return new TagDefinition(properties);
            };

            /**
             * Encodes the specified TagDefinition message. Does not implicitly {@link streem.api.TagDefinition.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TagDefinition
             * @static
             * @param {streem.api.ITagDefinition} m TagDefinition message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TagDefinition.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                return w;
            };

            /**
             * Decodes a TagDefinition message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TagDefinition
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TagDefinition} TagDefinition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TagDefinition.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TagDefinition();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TagDefinition message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TagDefinition
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TagDefinition} TagDefinition
             */
            TagDefinition.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TagDefinition)
                    return d;
                var m = new $root.streem.api.TagDefinition();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            /**
             * Creates a plain object from a TagDefinition message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TagDefinition
             * @static
             * @param {streem.api.TagDefinition} m TagDefinition
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TagDefinition.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            /**
             * Converts this TagDefinition to JSON.
             * @function toJSON
             * @memberof streem.api.TagDefinition
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TagDefinition.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TagDefinition
             * @function getTypeUrl
             * @memberof streem.api.TagDefinition
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TagDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TagDefinition";
            };

            return TagDefinition;
        })();

        api.CentralLogin = (function() {

            /**
             * Constructs a new CentralLogin service.
             * @memberof streem.api
             * @classdesc Represents a CentralLogin
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function CentralLogin(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (CentralLogin.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CentralLogin;

            /**
             * Creates new CentralLogin service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.CentralLogin
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {CentralLogin} RPC service. Useful where requests and/or responses are streamed.
             */
            CentralLogin.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.CentralLogin#getCentralLoginPublicLoginConfig}.
             * @memberof streem.api.CentralLogin
             * @typedef GetCentralLoginPublicLoginConfigCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCentralLoginPublicLoginConfigResponse} [response] GetCentralLoginPublicLoginConfigResponse
             */

            /**
             * Calls GetCentralLoginPublicLoginConfig.
             * @function getCentralLoginPublicLoginConfig
             * @memberof streem.api.CentralLogin
             * @instance
             * @param {streem.api.IGetCentralLoginPublicLoginConfigRequest} request GetCentralLoginPublicLoginConfigRequest message or plain object
             * @param {streem.api.CentralLogin.GetCentralLoginPublicLoginConfigCallback} callback Node-style callback called with the error, if any, and GetCentralLoginPublicLoginConfigResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(CentralLogin.prototype.getCentralLoginPublicLoginConfig = function getCentralLoginPublicLoginConfig(request, callback) {
                return this.rpcCall(getCentralLoginPublicLoginConfig, $root.streem.api.GetCentralLoginPublicLoginConfigRequest, $root.streem.api.GetCentralLoginPublicLoginConfigResponse, request, callback);
            }, "name", { value: "GetCentralLoginPublicLoginConfig" });

            /**
             * Calls GetCentralLoginPublicLoginConfig.
             * @function getCentralLoginPublicLoginConfig
             * @memberof streem.api.CentralLogin
             * @instance
             * @param {streem.api.IGetCentralLoginPublicLoginConfigRequest} request GetCentralLoginPublicLoginConfigRequest message or plain object
             * @returns {Promise<streem.api.GetCentralLoginPublicLoginConfigResponse>} Promise
             * @variation 2
             */

            return CentralLogin;
        })();

        api.GetCentralLoginPublicLoginConfigRequest = (function() {

            /**
             * Properties of a GetCentralLoginPublicLoginConfigRequest.
             * @memberof streem.api
             * @interface IGetCentralLoginPublicLoginConfigRequest
             */

            /**
             * Constructs a new GetCentralLoginPublicLoginConfigRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCentralLoginPublicLoginConfigRequest.
             * @implements IGetCentralLoginPublicLoginConfigRequest
             * @constructor
             * @param {streem.api.IGetCentralLoginPublicLoginConfigRequest=} [p] Properties to set
             */
            function GetCentralLoginPublicLoginConfigRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCentralLoginPublicLoginConfigRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {streem.api.IGetCentralLoginPublicLoginConfigRequest=} [properties] Properties to set
             * @returns {streem.api.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest instance
             */
            GetCentralLoginPublicLoginConfigRequest.create = function create(properties) {
                return new GetCentralLoginPublicLoginConfigRequest(properties);
            };

            /**
             * Encodes the specified GetCentralLoginPublicLoginConfigRequest message. Does not implicitly {@link streem.api.GetCentralLoginPublicLoginConfigRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {streem.api.IGetCentralLoginPublicLoginConfigRequest} m GetCentralLoginPublicLoginConfigRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCentralLoginPublicLoginConfigRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCentralLoginPublicLoginConfigRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCentralLoginPublicLoginConfigRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCentralLoginPublicLoginConfigRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCentralLoginPublicLoginConfigRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest
             */
            GetCentralLoginPublicLoginConfigRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCentralLoginPublicLoginConfigRequest)
                    return d;
                return new $root.streem.api.GetCentralLoginPublicLoginConfigRequest();
            };

            /**
             * Creates a plain object from a GetCentralLoginPublicLoginConfigRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {streem.api.GetCentralLoginPublicLoginConfigRequest} m GetCentralLoginPublicLoginConfigRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCentralLoginPublicLoginConfigRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCentralLoginPublicLoginConfigRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCentralLoginPublicLoginConfigRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCentralLoginPublicLoginConfigRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCentralLoginPublicLoginConfigRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCentralLoginPublicLoginConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCentralLoginPublicLoginConfigRequest";
            };

            return GetCentralLoginPublicLoginConfigRequest;
        })();

        api.GetCentralLoginPublicLoginConfigResponse = (function() {

            /**
             * Properties of a GetCentralLoginPublicLoginConfigResponse.
             * @memberof streem.api
             * @interface IGetCentralLoginPublicLoginConfigResponse
             * @property {streem.api.IPublicLoginConfiguration|null} [loginConfiguration] GetCentralLoginPublicLoginConfigResponse loginConfiguration
             */

            /**
             * Constructs a new GetCentralLoginPublicLoginConfigResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCentralLoginPublicLoginConfigResponse.
             * @implements IGetCentralLoginPublicLoginConfigResponse
             * @constructor
             * @param {streem.api.IGetCentralLoginPublicLoginConfigResponse=} [p] Properties to set
             */
            function GetCentralLoginPublicLoginConfigResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCentralLoginPublicLoginConfigResponse loginConfiguration.
             * @member {streem.api.IPublicLoginConfiguration|null|undefined} loginConfiguration
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @instance
             */
            GetCentralLoginPublicLoginConfigResponse.prototype.loginConfiguration = null;

            /**
             * Creates a new GetCentralLoginPublicLoginConfigResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {streem.api.IGetCentralLoginPublicLoginConfigResponse=} [properties] Properties to set
             * @returns {streem.api.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse instance
             */
            GetCentralLoginPublicLoginConfigResponse.create = function create(properties) {
                return new GetCentralLoginPublicLoginConfigResponse(properties);
            };

            /**
             * Encodes the specified GetCentralLoginPublicLoginConfigResponse message. Does not implicitly {@link streem.api.GetCentralLoginPublicLoginConfigResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {streem.api.IGetCentralLoginPublicLoginConfigResponse} m GetCentralLoginPublicLoginConfigResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCentralLoginPublicLoginConfigResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.loginConfiguration != null && Object.hasOwnProperty.call(m, "loginConfiguration"))
                    $root.streem.api.PublicLoginConfiguration.encode(m.loginConfiguration, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCentralLoginPublicLoginConfigResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCentralLoginPublicLoginConfigResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCentralLoginPublicLoginConfigResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCentralLoginPublicLoginConfigResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse
             */
            GetCentralLoginPublicLoginConfigResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCentralLoginPublicLoginConfigResponse)
                    return d;
                var m = new $root.streem.api.GetCentralLoginPublicLoginConfigResponse();
                if (d.loginConfiguration != null) {
                    if (typeof d.loginConfiguration !== "object")
                        throw TypeError(".streem.api.GetCentralLoginPublicLoginConfigResponse.loginConfiguration: object expected");
                    m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.fromObject(d.loginConfiguration);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCentralLoginPublicLoginConfigResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {streem.api.GetCentralLoginPublicLoginConfigResponse} m GetCentralLoginPublicLoginConfigResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCentralLoginPublicLoginConfigResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.loginConfiguration = null;
                }
                if (m.loginConfiguration != null && m.hasOwnProperty("loginConfiguration")) {
                    d.loginConfiguration = $root.streem.api.PublicLoginConfiguration.toObject(m.loginConfiguration, o);
                }
                return d;
            };

            /**
             * Converts this GetCentralLoginPublicLoginConfigResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCentralLoginPublicLoginConfigResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCentralLoginPublicLoginConfigResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCentralLoginPublicLoginConfigResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCentralLoginPublicLoginConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCentralLoginPublicLoginConfigResponse";
            };

            return GetCentralLoginPublicLoginConfigResponse;
        })();

        api.User = (function() {

            /**
             * Properties of a User.
             * @memberof streem.api
             * @interface IUser
             * @property {string|null} [sid] User sid
             * @property {string|null} [companySid] User companySid
             * @property {string|null} [externalUserId] User externalUserId
             * @property {boolean|null} [isExpert] User isExpert
             * @property {string|null} [email] User email
             * @property {string|null} [name] User name
             * @property {string|null} [avatarUrl] User avatarUrl
             * @property {string|null} [phone] User phone
             * @property {boolean|null} [active] User active
             * @property {Array.<string>|null} [roles] User roles
             * @property {google.protobuf.ITimestamp|null} [activeStatusModifiedAt] User activeStatusModifiedAt
             * @property {Array.<streem.api.User.UserType>|null} [userTypes] User userTypes
             * @property {boolean|null} [deleted] User deleted
             * @property {google.protobuf.ITimestamp|null} [deletedAt] User deletedAt
             * @property {string|null} [bio] User bio
             * @property {streem.api.AvailabilityStatus|null} [availabilityStatus] User availabilityStatus
             */

            /**
             * Constructs a new User.
             * @memberof streem.api
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {streem.api.IUser=} [p] Properties to set
             */
            function User(p) {
                this.roles = [];
                this.userTypes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * User sid.
             * @member {string} sid
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.sid = "";

            /**
             * User companySid.
             * @member {string} companySid
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.companySid = "";

            /**
             * User externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.externalUserId = "";

            /**
             * User isExpert.
             * @member {boolean} isExpert
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.isExpert = false;

            /**
             * User email.
             * @member {string} email
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.email = "";

            /**
             * User name.
             * @member {string} name
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.name = "";

            /**
             * User avatarUrl.
             * @member {string} avatarUrl
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.avatarUrl = "";

            /**
             * User phone.
             * @member {string} phone
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.phone = "";

            /**
             * User active.
             * @member {boolean} active
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.active = false;

            /**
             * User roles.
             * @member {Array.<string>} roles
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.roles = $util.emptyArray;

            /**
             * User activeStatusModifiedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} activeStatusModifiedAt
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.activeStatusModifiedAt = null;

            /**
             * User userTypes.
             * @member {Array.<streem.api.User.UserType>} userTypes
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.userTypes = $util.emptyArray;

            /**
             * User deleted.
             * @member {boolean} deleted
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.deleted = false;

            /**
             * User deletedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.deletedAt = null;

            /**
             * User bio.
             * @member {string} bio
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.bio = "";

            /**
             * User availabilityStatus.
             * @member {streem.api.AvailabilityStatus} availabilityStatus
             * @memberof streem.api.User
             * @instance
             */
            User.prototype.availabilityStatus = 0;

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof streem.api.User
             * @static
             * @param {streem.api.IUser=} [properties] Properties to set
             * @returns {streem.api.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link streem.api.User.verify|verify} messages.
             * @function encode
             * @memberof streem.api.User
             * @static
             * @param {streem.api.IUser} m User message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(26).string(m.externalUserId);
                if (m.isExpert != null && Object.hasOwnProperty.call(m, "isExpert"))
                    w.uint32(32).bool(m.isExpert);
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(42).string(m.email);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(50).string(m.name);
                if (m.avatarUrl != null && Object.hasOwnProperty.call(m, "avatarUrl"))
                    w.uint32(58).string(m.avatarUrl);
                if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                    w.uint32(66).string(m.phone);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(72).bool(m.active);
                if (m.roles != null && m.roles.length) {
                    for (var i = 0; i < m.roles.length; ++i)
                        w.uint32(82).string(m.roles[i]);
                }
                if (m.activeStatusModifiedAt != null && Object.hasOwnProperty.call(m, "activeStatusModifiedAt"))
                    $root.google.protobuf.Timestamp.encode(m.activeStatusModifiedAt, w.uint32(90).fork()).ldelim();
                if (m.userTypes != null && m.userTypes.length) {
                    w.uint32(98).fork();
                    for (var i = 0; i < m.userTypes.length; ++i)
                        w.int32(m.userTypes[i]);
                    w.ldelim();
                }
                if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                    w.uint32(104).bool(m.deleted);
                if (m.deletedAt != null && Object.hasOwnProperty.call(m, "deletedAt"))
                    $root.google.protobuf.Timestamp.encode(m.deletedAt, w.uint32(114).fork()).ldelim();
                if (m.bio != null && Object.hasOwnProperty.call(m, "bio"))
                    w.uint32(122).string(m.bio);
                if (m.availabilityStatus != null && Object.hasOwnProperty.call(m, "availabilityStatus"))
                    w.uint32(128).int32(m.availabilityStatus);
                return w;
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.User();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 3: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 4: {
                            m.isExpert = r.bool();
                            break;
                        }
                    case 5: {
                            m.email = r.string();
                            break;
                        }
                    case 6: {
                            m.name = r.string();
                            break;
                        }
                    case 7: {
                            m.avatarUrl = r.string();
                            break;
                        }
                    case 8: {
                            m.phone = r.string();
                            break;
                        }
                    case 9: {
                            m.active = r.bool();
                            break;
                        }
                    case 10: {
                            if (!(m.roles && m.roles.length))
                                m.roles = [];
                            m.roles.push(r.string());
                            break;
                        }
                    case 11: {
                            m.activeStatusModifiedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            if (!(m.userTypes && m.userTypes.length))
                                m.userTypes = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.userTypes.push(r.int32());
                            } else
                                m.userTypes.push(r.int32());
                            break;
                        }
                    case 13: {
                            m.deleted = r.bool();
                            break;
                        }
                    case 14: {
                            m.deletedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 15: {
                            m.bio = r.string();
                            break;
                        }
                    case 16: {
                            m.availabilityStatus = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.User
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.User} User
             */
            User.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.User)
                    return d;
                var m = new $root.streem.api.User();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.isExpert != null) {
                    m.isExpert = Boolean(d.isExpert);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.avatarUrl != null) {
                    m.avatarUrl = String(d.avatarUrl);
                }
                if (d.phone != null) {
                    m.phone = String(d.phone);
                }
                if (d.active != null) {
                    m.active = Boolean(d.active);
                }
                if (d.roles) {
                    if (!Array.isArray(d.roles))
                        throw TypeError(".streem.api.User.roles: array expected");
                    m.roles = [];
                    for (var i = 0; i < d.roles.length; ++i) {
                        m.roles[i] = String(d.roles[i]);
                    }
                }
                if (d.activeStatusModifiedAt != null) {
                    if (typeof d.activeStatusModifiedAt !== "object")
                        throw TypeError(".streem.api.User.activeStatusModifiedAt: object expected");
                    m.activeStatusModifiedAt = $root.google.protobuf.Timestamp.fromObject(d.activeStatusModifiedAt);
                }
                if (d.userTypes) {
                    if (!Array.isArray(d.userTypes))
                        throw TypeError(".streem.api.User.userTypes: array expected");
                    m.userTypes = [];
                    for (var i = 0; i < d.userTypes.length; ++i) {
                        switch (d.userTypes[i]) {
                        default:
                            if (typeof d.userTypes[i] === "number") {
                                m.userTypes[i] = d.userTypes[i];
                                break;
                            }
                        case "USER_TYPE_UNSPECIFIED":
                        case 0:
                            m.userTypes[i] = 0;
                            break;
                        case "USER_TYPE_LOGIN":
                        case 1:
                            m.userTypes[i] = 1;
                            break;
                        case "USER_TYPE_SDK":
                        case 2:
                            m.userTypes[i] = 2;
                            break;
                        case "USER_TYPE_INVITED":
                        case 3:
                            m.userTypes[i] = 3;
                            break;
                        }
                    }
                }
                if (d.deleted != null) {
                    m.deleted = Boolean(d.deleted);
                }
                if (d.deletedAt != null) {
                    if (typeof d.deletedAt !== "object")
                        throw TypeError(".streem.api.User.deletedAt: object expected");
                    m.deletedAt = $root.google.protobuf.Timestamp.fromObject(d.deletedAt);
                }
                if (d.bio != null) {
                    m.bio = String(d.bio);
                }
                switch (d.availabilityStatus) {
                default:
                    if (typeof d.availabilityStatus === "number") {
                        m.availabilityStatus = d.availabilityStatus;
                        break;
                    }
                    break;
                case "AVAILABILITY_STATUS_INVALID":
                case 0:
                    m.availabilityStatus = 0;
                    break;
                case "AVAILABILITY_STATUS_AVAILABLE":
                case 1:
                    m.availabilityStatus = 1;
                    break;
                case "AVAILABILITY_STATUS_NOT_AVAILABLE":
                case 2:
                    m.availabilityStatus = 2;
                    break;
                case "AVAILABILITY_STATUS_RESERVED":
                case 3:
                    m.availabilityStatus = 3;
                    break;
                case "AVAILABILITY_STATUS_IN_CALL":
                case 4:
                    m.availabilityStatus = 4;
                    break;
                case "AVAILABILITY_STATUS_CONFIRMING":
                case 5:
                    m.availabilityStatus = 5;
                    break;
                case "AVAILABILITY_STATUS_OFFLINE":
                case 6:
                    m.availabilityStatus = 6;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.User
             * @static
             * @param {streem.api.User} m User
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roles = [];
                    d.userTypes = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.companySid = "";
                    d.externalUserId = "";
                    d.isExpert = false;
                    d.email = "";
                    d.name = "";
                    d.avatarUrl = "";
                    d.phone = "";
                    d.active = false;
                    d.activeStatusModifiedAt = null;
                    d.deleted = false;
                    d.deletedAt = null;
                    d.bio = "";
                    d.availabilityStatus = o.enums === String ? "AVAILABILITY_STATUS_INVALID" : 0;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.isExpert != null && m.hasOwnProperty("isExpert")) {
                    d.isExpert = m.isExpert;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.avatarUrl != null && m.hasOwnProperty("avatarUrl")) {
                    d.avatarUrl = m.avatarUrl;
                }
                if (m.phone != null && m.hasOwnProperty("phone")) {
                    d.phone = m.phone;
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = m.active;
                }
                if (m.roles && m.roles.length) {
                    d.roles = [];
                    for (var j = 0; j < m.roles.length; ++j) {
                        d.roles[j] = m.roles[j];
                    }
                }
                if (m.activeStatusModifiedAt != null && m.hasOwnProperty("activeStatusModifiedAt")) {
                    d.activeStatusModifiedAt = $root.google.protobuf.Timestamp.toObject(m.activeStatusModifiedAt, o);
                }
                if (m.userTypes && m.userTypes.length) {
                    d.userTypes = [];
                    for (var j = 0; j < m.userTypes.length; ++j) {
                        d.userTypes[j] = o.enums === String ? $root.streem.api.User.UserType[m.userTypes[j]] === undefined ? m.userTypes[j] : $root.streem.api.User.UserType[m.userTypes[j]] : m.userTypes[j];
                    }
                }
                if (m.deleted != null && m.hasOwnProperty("deleted")) {
                    d.deleted = m.deleted;
                }
                if (m.deletedAt != null && m.hasOwnProperty("deletedAt")) {
                    d.deletedAt = $root.google.protobuf.Timestamp.toObject(m.deletedAt, o);
                }
                if (m.bio != null && m.hasOwnProperty("bio")) {
                    d.bio = m.bio;
                }
                if (m.availabilityStatus != null && m.hasOwnProperty("availabilityStatus")) {
                    d.availabilityStatus = o.enums === String ? $root.streem.api.AvailabilityStatus[m.availabilityStatus] === undefined ? m.availabilityStatus : $root.streem.api.AvailabilityStatus[m.availabilityStatus] : m.availabilityStatus;
                }
                return d;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof streem.api.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof streem.api.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.User";
            };

            /**
             * UserType enum.
             * @name streem.api.User.UserType
             * @enum {number}
             * @property {number} USER_TYPE_UNSPECIFIED=0 USER_TYPE_UNSPECIFIED value
             * @property {number} USER_TYPE_LOGIN=1 USER_TYPE_LOGIN value
             * @property {number} USER_TYPE_SDK=2 USER_TYPE_SDK value
             * @property {number} USER_TYPE_INVITED=3 USER_TYPE_INVITED value
             */
            User.UserType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "USER_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "USER_TYPE_LOGIN"] = 1;
                values[valuesById[2] = "USER_TYPE_SDK"] = 2;
                values[valuesById[3] = "USER_TYPE_INVITED"] = 3;
                return values;
            })();

            return User;
        })();

        /**
         * State diagram:
         * 
         * @startuml
         * [*] --> NOT_AVAILABLE : Initial State
         * NOT_AVAILABLE --> AVAILABLE : Expert indicates available for call
         * AVAILABLE --> NOT_AVAILABLE : Expert indicates not available for call
         * AVAILABLE --> CONFIRMING : Waiting for the frontend to confirm\n reservation to expert match...
         * CONFIRMING --> RESERVED : Expert has been to be assigned\n to the reservation
         * CONFIRMING --> AVAILABLE : Reservation explicitly cancelled
         * RESERVED --> IN_CALL : Participant\n enters a room
         * RESERVED --> AVAILABLE : Reservation expires
         * RESERVED --> NOT_AVAILABLE : Expert never joins, rejects call or times out
         * IN_CALL --> NOT_AVAILABLE : Call Ends
         * NOT_AVAILABLE --> OFFLINE : User has signed out
         * AVAILABLE --> OFFLINE : User misses too many UserWall heartbeats
         * CONFIRMING --> OFFLINE : User misses too many UserWall heartbeats
         * RESERVED --> OFFLINE : User misses too many UserWall heartbeats
         * NOT_AVAILABLE --> OFFLINE : User misses too many UserWall heartbeats
         * @enduml
         * @name streem.api.AvailabilityStatus
         * @enum {number}
         * @property {number} AVAILABILITY_STATUS_INVALID=0 AVAILABILITY_STATUS_INVALID value
         * @property {number} AVAILABILITY_STATUS_AVAILABLE=1 AVAILABILITY_STATUS_AVAILABLE value
         * @property {number} AVAILABILITY_STATUS_NOT_AVAILABLE=2 AVAILABILITY_STATUS_NOT_AVAILABLE value
         * @property {number} AVAILABILITY_STATUS_RESERVED=3 AVAILABILITY_STATUS_RESERVED value
         * @property {number} AVAILABILITY_STATUS_IN_CALL=4 AVAILABILITY_STATUS_IN_CALL value
         * @property {number} AVAILABILITY_STATUS_CONFIRMING=5 AVAILABILITY_STATUS_CONFIRMING value
         * @property {number} AVAILABILITY_STATUS_OFFLINE=6 AVAILABILITY_STATUS_OFFLINE value
         */
        api.AvailabilityStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AVAILABILITY_STATUS_INVALID"] = 0;
            values[valuesById[1] = "AVAILABILITY_STATUS_AVAILABLE"] = 1;
            values[valuesById[2] = "AVAILABILITY_STATUS_NOT_AVAILABLE"] = 2;
            values[valuesById[3] = "AVAILABILITY_STATUS_RESERVED"] = 3;
            values[valuesById[4] = "AVAILABILITY_STATUS_IN_CALL"] = 4;
            values[valuesById[5] = "AVAILABILITY_STATUS_CONFIRMING"] = 5;
            values[valuesById[6] = "AVAILABILITY_STATUS_OFFLINE"] = 6;
            return values;
        })();

        api.UserAvailability = (function() {

            /**
             * Properties of a UserAvailability.
             * @memberof streem.api
             * @interface IUserAvailability
             * @property {string|null} [userSid] UserAvailability userSid
             * @property {streem.api.AvailabilityStatus|null} [availabilityStatus] UserAvailability availabilityStatus
             * @property {string|null} [reservationSid] UserAvailability reservationSid
             */

            /**
             * Constructs a new UserAvailability.
             * @memberof streem.api
             * @classdesc Represents a UserAvailability.
             * @implements IUserAvailability
             * @constructor
             * @param {streem.api.IUserAvailability=} [p] Properties to set
             */
            function UserAvailability(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserAvailability userSid.
             * @member {string} userSid
             * @memberof streem.api.UserAvailability
             * @instance
             */
            UserAvailability.prototype.userSid = "";

            /**
             * UserAvailability availabilityStatus.
             * @member {streem.api.AvailabilityStatus} availabilityStatus
             * @memberof streem.api.UserAvailability
             * @instance
             */
            UserAvailability.prototype.availabilityStatus = 0;

            /**
             * UserAvailability reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.UserAvailability
             * @instance
             */
            UserAvailability.prototype.reservationSid = "";

            /**
             * Creates a new UserAvailability instance using the specified properties.
             * @function create
             * @memberof streem.api.UserAvailability
             * @static
             * @param {streem.api.IUserAvailability=} [properties] Properties to set
             * @returns {streem.api.UserAvailability} UserAvailability instance
             */
            UserAvailability.create = function create(properties) {
                return new UserAvailability(properties);
            };

            /**
             * Encodes the specified UserAvailability message. Does not implicitly {@link streem.api.UserAvailability.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UserAvailability
             * @static
             * @param {streem.api.IUserAvailability} m UserAvailability message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserAvailability.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.availabilityStatus != null && Object.hasOwnProperty.call(m, "availabilityStatus"))
                    w.uint32(16).int32(m.availabilityStatus);
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(26).string(m.reservationSid);
                return w;
            };

            /**
             * Decodes a UserAvailability message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UserAvailability
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UserAvailability} UserAvailability
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserAvailability.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserAvailability();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.availabilityStatus = r.int32();
                            break;
                        }
                    case 3: {
                            m.reservationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UserAvailability message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UserAvailability
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UserAvailability} UserAvailability
             */
            UserAvailability.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UserAvailability)
                    return d;
                var m = new $root.streem.api.UserAvailability();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                switch (d.availabilityStatus) {
                default:
                    if (typeof d.availabilityStatus === "number") {
                        m.availabilityStatus = d.availabilityStatus;
                        break;
                    }
                    break;
                case "AVAILABILITY_STATUS_INVALID":
                case 0:
                    m.availabilityStatus = 0;
                    break;
                case "AVAILABILITY_STATUS_AVAILABLE":
                case 1:
                    m.availabilityStatus = 1;
                    break;
                case "AVAILABILITY_STATUS_NOT_AVAILABLE":
                case 2:
                    m.availabilityStatus = 2;
                    break;
                case "AVAILABILITY_STATUS_RESERVED":
                case 3:
                    m.availabilityStatus = 3;
                    break;
                case "AVAILABILITY_STATUS_IN_CALL":
                case 4:
                    m.availabilityStatus = 4;
                    break;
                case "AVAILABILITY_STATUS_CONFIRMING":
                case 5:
                    m.availabilityStatus = 5;
                    break;
                case "AVAILABILITY_STATUS_OFFLINE":
                case 6:
                    m.availabilityStatus = 6;
                    break;
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserAvailability message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UserAvailability
             * @static
             * @param {streem.api.UserAvailability} m UserAvailability
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserAvailability.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.availabilityStatus = o.enums === String ? "AVAILABILITY_STATUS_INVALID" : 0;
                    d.reservationSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.availabilityStatus != null && m.hasOwnProperty("availabilityStatus")) {
                    d.availabilityStatus = o.enums === String ? $root.streem.api.AvailabilityStatus[m.availabilityStatus] === undefined ? m.availabilityStatus : $root.streem.api.AvailabilityStatus[m.availabilityStatus] : m.availabilityStatus;
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                return d;
            };

            /**
             * Converts this UserAvailability to JSON.
             * @function toJSON
             * @memberof streem.api.UserAvailability
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserAvailability.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserAvailability
             * @function getTypeUrl
             * @memberof streem.api.UserAvailability
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserAvailability.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UserAvailability";
            };

            return UserAvailability;
        })();

        api.UserWallCredentials = (function() {

            /**
             * Properties of a UserWallCredentials.
             * @memberof streem.api
             * @interface IUserWallCredentials
             * @property {streem.api.UserWallCredentials.ITwilioSync|null} [twilioSync] UserWallCredentials twilioSync
             * @property {streem.api.UserWallCredentials.IStreemWall|null} [streemWall] UserWallCredentials streemWall
             */

            /**
             * Constructs a new UserWallCredentials.
             * @memberof streem.api
             * @classdesc Represents a UserWallCredentials.
             * @implements IUserWallCredentials
             * @constructor
             * @param {streem.api.IUserWallCredentials=} [p] Properties to set
             */
            function UserWallCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserWallCredentials twilioSync.
             * @member {streem.api.UserWallCredentials.ITwilioSync|null|undefined} twilioSync
             * @memberof streem.api.UserWallCredentials
             * @instance
             */
            UserWallCredentials.prototype.twilioSync = null;

            /**
             * UserWallCredentials streemWall.
             * @member {streem.api.UserWallCredentials.IStreemWall|null|undefined} streemWall
             * @memberof streem.api.UserWallCredentials
             * @instance
             */
            UserWallCredentials.prototype.streemWall = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UserWallCredentials provider.
             * @member {"twilioSync"|"streemWall"|undefined} provider
             * @memberof streem.api.UserWallCredentials
             * @instance
             */
            Object.defineProperty(UserWallCredentials.prototype, "provider", {
                get: $util.oneOfGetter($oneOfFields = ["twilioSync", "streemWall"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UserWallCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {streem.api.IUserWallCredentials=} [properties] Properties to set
             * @returns {streem.api.UserWallCredentials} UserWallCredentials instance
             */
            UserWallCredentials.create = function create(properties) {
                return new UserWallCredentials(properties);
            };

            /**
             * Encodes the specified UserWallCredentials message. Does not implicitly {@link streem.api.UserWallCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {streem.api.IUserWallCredentials} m UserWallCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWallCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.twilioSync != null && Object.hasOwnProperty.call(m, "twilioSync"))
                    $root.streem.api.UserWallCredentials.TwilioSync.encode(m.twilioSync, w.uint32(10).fork()).ldelim();
                if (m.streemWall != null && Object.hasOwnProperty.call(m, "streemWall"))
                    $root.streem.api.UserWallCredentials.StreemWall.encode(m.streemWall, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a UserWallCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UserWallCredentials} UserWallCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWallCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserWallCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.twilioSync = $root.streem.api.UserWallCredentials.TwilioSync.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.streemWall = $root.streem.api.UserWallCredentials.StreemWall.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UserWallCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UserWallCredentials} UserWallCredentials
             */
            UserWallCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UserWallCredentials)
                    return d;
                var m = new $root.streem.api.UserWallCredentials();
                if (d.twilioSync != null) {
                    if (typeof d.twilioSync !== "object")
                        throw TypeError(".streem.api.UserWallCredentials.twilioSync: object expected");
                    m.twilioSync = $root.streem.api.UserWallCredentials.TwilioSync.fromObject(d.twilioSync);
                }
                if (d.streemWall != null) {
                    if (typeof d.streemWall !== "object")
                        throw TypeError(".streem.api.UserWallCredentials.streemWall: object expected");
                    m.streemWall = $root.streem.api.UserWallCredentials.StreemWall.fromObject(d.streemWall);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserWallCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {streem.api.UserWallCredentials} m UserWallCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserWallCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.twilioSync != null && m.hasOwnProperty("twilioSync")) {
                    d.twilioSync = $root.streem.api.UserWallCredentials.TwilioSync.toObject(m.twilioSync, o);
                    if (o.oneofs)
                        d.provider = "twilioSync";
                }
                if (m.streemWall != null && m.hasOwnProperty("streemWall")) {
                    d.streemWall = $root.streem.api.UserWallCredentials.StreemWall.toObject(m.streemWall, o);
                    if (o.oneofs)
                        d.provider = "streemWall";
                }
                return d;
            };

            /**
             * Converts this UserWallCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.UserWallCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserWallCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserWallCredentials
             * @function getTypeUrl
             * @memberof streem.api.UserWallCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserWallCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UserWallCredentials";
            };

            UserWallCredentials.TwilioSync = (function() {

                /**
                 * Properties of a TwilioSync.
                 * @memberof streem.api.UserWallCredentials
                 * @interface ITwilioSync
                 * @property {string|null} [token] TwilioSync token
                 * @property {string|null} [mapName] TwilioSync mapName
                 */

                /**
                 * Constructs a new TwilioSync.
                 * @memberof streem.api.UserWallCredentials
                 * @classdesc Represents a TwilioSync.
                 * @implements ITwilioSync
                 * @constructor
                 * @param {streem.api.UserWallCredentials.ITwilioSync=} [p] Properties to set
                 */
                function TwilioSync(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * TwilioSync token.
                 * @member {string} token
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @instance
                 */
                TwilioSync.prototype.token = "";

                /**
                 * TwilioSync mapName.
                 * @member {string} mapName
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @instance
                 */
                TwilioSync.prototype.mapName = "";

                /**
                 * Creates a new TwilioSync instance using the specified properties.
                 * @function create
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {streem.api.UserWallCredentials.ITwilioSync=} [properties] Properties to set
                 * @returns {streem.api.UserWallCredentials.TwilioSync} TwilioSync instance
                 */
                TwilioSync.create = function create(properties) {
                    return new TwilioSync(properties);
                };

                /**
                 * Encodes the specified TwilioSync message. Does not implicitly {@link streem.api.UserWallCredentials.TwilioSync.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {streem.api.UserWallCredentials.ITwilioSync} m TwilioSync message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TwilioSync.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                        w.uint32(10).string(m.token);
                    if (m.mapName != null && Object.hasOwnProperty.call(m, "mapName"))
                        w.uint32(18).string(m.mapName);
                    return w;
                };

                /**
                 * Decodes a TwilioSync message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.UserWallCredentials.TwilioSync} TwilioSync
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TwilioSync.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserWallCredentials.TwilioSync();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.token = r.string();
                                break;
                            }
                        case 2: {
                                m.mapName = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a TwilioSync message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.UserWallCredentials.TwilioSync} TwilioSync
                 */
                TwilioSync.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.UserWallCredentials.TwilioSync)
                        return d;
                    var m = new $root.streem.api.UserWallCredentials.TwilioSync();
                    if (d.token != null) {
                        m.token = String(d.token);
                    }
                    if (d.mapName != null) {
                        m.mapName = String(d.mapName);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a TwilioSync message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {streem.api.UserWallCredentials.TwilioSync} m TwilioSync
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TwilioSync.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.token = "";
                        d.mapName = "";
                    }
                    if (m.token != null && m.hasOwnProperty("token")) {
                        d.token = m.token;
                    }
                    if (m.mapName != null && m.hasOwnProperty("mapName")) {
                        d.mapName = m.mapName;
                    }
                    return d;
                };

                /**
                 * Converts this TwilioSync to JSON.
                 * @function toJSON
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TwilioSync.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TwilioSync
                 * @function getTypeUrl
                 * @memberof streem.api.UserWallCredentials.TwilioSync
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TwilioSync.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.UserWallCredentials.TwilioSync";
                };

                return TwilioSync;
            })();

            UserWallCredentials.StreemWall = (function() {

                /**
                 * Properties of a StreemWall.
                 * @memberof streem.api.UserWallCredentials
                 * @interface IStreemWall
                 * @property {string|null} [token] StreemWall token
                 * @property {string|null} [mapName] StreemWall mapName
                 */

                /**
                 * Constructs a new StreemWall.
                 * @memberof streem.api.UserWallCredentials
                 * @classdesc Represents a StreemWall.
                 * @implements IStreemWall
                 * @constructor
                 * @param {streem.api.UserWallCredentials.IStreemWall=} [p] Properties to set
                 */
                function StreemWall(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * StreemWall token.
                 * @member {string} token
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @instance
                 */
                StreemWall.prototype.token = "";

                /**
                 * StreemWall mapName.
                 * @member {string} mapName
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @instance
                 */
                StreemWall.prototype.mapName = "";

                /**
                 * Creates a new StreemWall instance using the specified properties.
                 * @function create
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {streem.api.UserWallCredentials.IStreemWall=} [properties] Properties to set
                 * @returns {streem.api.UserWallCredentials.StreemWall} StreemWall instance
                 */
                StreemWall.create = function create(properties) {
                    return new StreemWall(properties);
                };

                /**
                 * Encodes the specified StreemWall message. Does not implicitly {@link streem.api.UserWallCredentials.StreemWall.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {streem.api.UserWallCredentials.IStreemWall} m StreemWall message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StreemWall.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                        w.uint32(10).string(m.token);
                    if (m.mapName != null && Object.hasOwnProperty.call(m, "mapName"))
                        w.uint32(18).string(m.mapName);
                    return w;
                };

                /**
                 * Decodes a StreemWall message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.UserWallCredentials.StreemWall} StreemWall
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StreemWall.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserWallCredentials.StreemWall();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.token = r.string();
                                break;
                            }
                        case 2: {
                                m.mapName = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a StreemWall message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.UserWallCredentials.StreemWall} StreemWall
                 */
                StreemWall.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.UserWallCredentials.StreemWall)
                        return d;
                    var m = new $root.streem.api.UserWallCredentials.StreemWall();
                    if (d.token != null) {
                        m.token = String(d.token);
                    }
                    if (d.mapName != null) {
                        m.mapName = String(d.mapName);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a StreemWall message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {streem.api.UserWallCredentials.StreemWall} m StreemWall
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StreemWall.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.token = "";
                        d.mapName = "";
                    }
                    if (m.token != null && m.hasOwnProperty("token")) {
                        d.token = m.token;
                    }
                    if (m.mapName != null && m.hasOwnProperty("mapName")) {
                        d.mapName = m.mapName;
                    }
                    return d;
                };

                /**
                 * Converts this StreemWall to JSON.
                 * @function toJSON
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StreemWall.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StreemWall
                 * @function getTypeUrl
                 * @memberof streem.api.UserWallCredentials.StreemWall
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StreemWall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.UserWallCredentials.StreemWall";
                };

                return StreemWall;
            })();

            return UserWallCredentials;
        })();

        /**
         * UserUploadType enum.
         * @name streem.api.UserUploadType
         * @enum {number}
         * @property {number} USER_UPLOAD_TYPE_INVALID=0 USER_UPLOAD_TYPE_INVALID value
         * @property {number} USER_UPLOAD_TYPE_AVATAR=1 USER_UPLOAD_TYPE_AVATAR value
         */
        api.UserUploadType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "USER_UPLOAD_TYPE_INVALID"] = 0;
            values[valuesById[1] = "USER_UPLOAD_TYPE_AVATAR"] = 1;
            return values;
        })();

        api.UserTermsStatus = (function() {

            /**
             * Properties of a UserTermsStatus.
             * @memberof streem.api
             * @interface IUserTermsStatus
             * @property {boolean|null} [acceptedLatest] UserTermsStatus acceptedLatest
             */

            /**
             * Constructs a new UserTermsStatus.
             * @memberof streem.api
             * @classdesc Represents a UserTermsStatus.
             * @implements IUserTermsStatus
             * @constructor
             * @param {streem.api.IUserTermsStatus=} [p] Properties to set
             */
            function UserTermsStatus(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserTermsStatus acceptedLatest.
             * @member {boolean} acceptedLatest
             * @memberof streem.api.UserTermsStatus
             * @instance
             */
            UserTermsStatus.prototype.acceptedLatest = false;

            /**
             * Creates a new UserTermsStatus instance using the specified properties.
             * @function create
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {streem.api.IUserTermsStatus=} [properties] Properties to set
             * @returns {streem.api.UserTermsStatus} UserTermsStatus instance
             */
            UserTermsStatus.create = function create(properties) {
                return new UserTermsStatus(properties);
            };

            /**
             * Encodes the specified UserTermsStatus message. Does not implicitly {@link streem.api.UserTermsStatus.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {streem.api.IUserTermsStatus} m UserTermsStatus message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserTermsStatus.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.acceptedLatest != null && Object.hasOwnProperty.call(m, "acceptedLatest"))
                    w.uint32(8).bool(m.acceptedLatest);
                return w;
            };

            /**
             * Decodes a UserTermsStatus message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UserTermsStatus} UserTermsStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserTermsStatus.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserTermsStatus();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.acceptedLatest = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UserTermsStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UserTermsStatus} UserTermsStatus
             */
            UserTermsStatus.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UserTermsStatus)
                    return d;
                var m = new $root.streem.api.UserTermsStatus();
                if (d.acceptedLatest != null) {
                    m.acceptedLatest = Boolean(d.acceptedLatest);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserTermsStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {streem.api.UserTermsStatus} m UserTermsStatus
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserTermsStatus.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.acceptedLatest = false;
                }
                if (m.acceptedLatest != null && m.hasOwnProperty("acceptedLatest")) {
                    d.acceptedLatest = m.acceptedLatest;
                }
                return d;
            };

            /**
             * Converts this UserTermsStatus to JSON.
             * @function toJSON
             * @memberof streem.api.UserTermsStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserTermsStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserTermsStatus
             * @function getTypeUrl
             * @memberof streem.api.UserTermsStatus
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserTermsStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UserTermsStatus";
            };

            return UserTermsStatus;
        })();

        api.CompanyOwner = (function() {

            /**
             * Properties of a CompanyOwner.
             * @memberof streem.api
             * @interface ICompanyOwner
             * @property {string|null} [sid] CompanyOwner sid
             * @property {string|null} [email] CompanyOwner email
             * @property {string|null} [name] CompanyOwner name
             * @property {boolean|null} [active] CompanyOwner active
             * @property {number|null} [ownerIndex] CompanyOwner ownerIndex
             * @property {streem.api.ICompany|null} [company] CompanyOwner company
             * @property {string|null} [parentCompanyCode] CompanyOwner parentCompanyCode
             */

            /**
             * Constructs a new CompanyOwner.
             * @memberof streem.api
             * @classdesc Represents a CompanyOwner.
             * @implements ICompanyOwner
             * @constructor
             * @param {streem.api.ICompanyOwner=} [p] Properties to set
             */
            function CompanyOwner(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CompanyOwner sid.
             * @member {string} sid
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.sid = "";

            /**
             * CompanyOwner email.
             * @member {string} email
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.email = "";

            /**
             * CompanyOwner name.
             * @member {string} name
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.name = "";

            /**
             * CompanyOwner active.
             * @member {boolean} active
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.active = false;

            /**
             * CompanyOwner ownerIndex.
             * @member {number} ownerIndex
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.ownerIndex = 0;

            /**
             * CompanyOwner company.
             * @member {streem.api.ICompany|null|undefined} company
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.company = null;

            /**
             * CompanyOwner parentCompanyCode.
             * @member {string} parentCompanyCode
             * @memberof streem.api.CompanyOwner
             * @instance
             */
            CompanyOwner.prototype.parentCompanyCode = "";

            /**
             * Creates a new CompanyOwner instance using the specified properties.
             * @function create
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {streem.api.ICompanyOwner=} [properties] Properties to set
             * @returns {streem.api.CompanyOwner} CompanyOwner instance
             */
            CompanyOwner.create = function create(properties) {
                return new CompanyOwner(properties);
            };

            /**
             * Encodes the specified CompanyOwner message. Does not implicitly {@link streem.api.CompanyOwner.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {streem.api.ICompanyOwner} m CompanyOwner message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyOwner.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(18).string(m.email);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(32).bool(m.active);
                if (m.ownerIndex != null && Object.hasOwnProperty.call(m, "ownerIndex"))
                    w.uint32(40).int32(m.ownerIndex);
                if (m.company != null && Object.hasOwnProperty.call(m, "company"))
                    $root.streem.api.Company.encode(m.company, w.uint32(50).fork()).ldelim();
                if (m.parentCompanyCode != null && Object.hasOwnProperty.call(m, "parentCompanyCode"))
                    w.uint32(58).string(m.parentCompanyCode);
                return w;
            };

            /**
             * Decodes a CompanyOwner message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CompanyOwner} CompanyOwner
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyOwner.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyOwner();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.email = r.string();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    case 4: {
                            m.active = r.bool();
                            break;
                        }
                    case 5: {
                            m.ownerIndex = r.int32();
                            break;
                        }
                    case 6: {
                            m.company = $root.streem.api.Company.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            m.parentCompanyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CompanyOwner message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CompanyOwner} CompanyOwner
             */
            CompanyOwner.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CompanyOwner)
                    return d;
                var m = new $root.streem.api.CompanyOwner();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.active != null) {
                    m.active = Boolean(d.active);
                }
                if (d.ownerIndex != null) {
                    m.ownerIndex = d.ownerIndex | 0;
                }
                if (d.company != null) {
                    if (typeof d.company !== "object")
                        throw TypeError(".streem.api.CompanyOwner.company: object expected");
                    m.company = $root.streem.api.Company.fromObject(d.company);
                }
                if (d.parentCompanyCode != null) {
                    m.parentCompanyCode = String(d.parentCompanyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a CompanyOwner message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {streem.api.CompanyOwner} m CompanyOwner
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyOwner.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.email = "";
                    d.name = "";
                    d.active = false;
                    d.ownerIndex = 0;
                    d.company = null;
                    d.parentCompanyCode = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = m.active;
                }
                if (m.ownerIndex != null && m.hasOwnProperty("ownerIndex")) {
                    d.ownerIndex = m.ownerIndex;
                }
                if (m.company != null && m.hasOwnProperty("company")) {
                    d.company = $root.streem.api.Company.toObject(m.company, o);
                }
                if (m.parentCompanyCode != null && m.hasOwnProperty("parentCompanyCode")) {
                    d.parentCompanyCode = m.parentCompanyCode;
                }
                return d;
            };

            /**
             * Converts this CompanyOwner to JSON.
             * @function toJSON
             * @memberof streem.api.CompanyOwner
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyOwner.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CompanyOwner
             * @function getTypeUrl
             * @memberof streem.api.CompanyOwner
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CompanyOwner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CompanyOwner";
            };

            return CompanyOwner;
        })();

        api.GroupMember = (function() {

            /**
             * Properties of a GroupMember.
             * @memberof streem.api
             * @interface IGroupMember
             * @property {string|null} [userSid] GroupMember userSid
             * @property {string|null} [groupSid] GroupMember groupSid
             * @property {string|null} [groupName] GroupMember groupName
             */

            /**
             * Constructs a new GroupMember.
             * @memberof streem.api
             * @classdesc Represents a GroupMember.
             * @implements IGroupMember
             * @constructor
             * @param {streem.api.IGroupMember=} [p] Properties to set
             */
            function GroupMember(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GroupMember userSid.
             * @member {string} userSid
             * @memberof streem.api.GroupMember
             * @instance
             */
            GroupMember.prototype.userSid = "";

            /**
             * GroupMember groupSid.
             * @member {string} groupSid
             * @memberof streem.api.GroupMember
             * @instance
             */
            GroupMember.prototype.groupSid = "";

            /**
             * GroupMember groupName.
             * @member {string} groupName
             * @memberof streem.api.GroupMember
             * @instance
             */
            GroupMember.prototype.groupName = "";

            /**
             * Creates a new GroupMember instance using the specified properties.
             * @function create
             * @memberof streem.api.GroupMember
             * @static
             * @param {streem.api.IGroupMember=} [properties] Properties to set
             * @returns {streem.api.GroupMember} GroupMember instance
             */
            GroupMember.create = function create(properties) {
                return new GroupMember(properties);
            };

            /**
             * Encodes the specified GroupMember message. Does not implicitly {@link streem.api.GroupMember.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GroupMember
             * @static
             * @param {streem.api.IGroupMember} m GroupMember message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GroupMember.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.groupSid != null && Object.hasOwnProperty.call(m, "groupSid"))
                    w.uint32(18).string(m.groupSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(26).string(m.groupName);
                return w;
            };

            /**
             * Decodes a GroupMember message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GroupMember
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GroupMember} GroupMember
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GroupMember.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GroupMember();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupSid = r.string();
                            break;
                        }
                    case 3: {
                            m.groupName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GroupMember message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GroupMember
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GroupMember} GroupMember
             */
            GroupMember.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GroupMember)
                    return d;
                var m = new $root.streem.api.GroupMember();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.groupSid != null) {
                    m.groupSid = String(d.groupSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GroupMember message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GroupMember
             * @static
             * @param {streem.api.GroupMember} m GroupMember
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GroupMember.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.groupSid = "";
                    d.groupName = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.groupSid != null && m.hasOwnProperty("groupSid")) {
                    d.groupSid = m.groupSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                return d;
            };

            /**
             * Converts this GroupMember to JSON.
             * @function toJSON
             * @memberof streem.api.GroupMember
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GroupMember.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GroupMember
             * @function getTypeUrl
             * @memberof streem.api.GroupMember
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GroupMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GroupMember";
            };

            return GroupMember;
        })();

        api.StreemSurveyStatus = (function() {

            /**
             * Properties of a StreemSurveyStatus.
             * @memberof streem.api
             * @interface IStreemSurveyStatus
             * @property {boolean|null} [shouldViewSurvey] StreemSurveyStatus shouldViewSurvey
             */

            /**
             * Constructs a new StreemSurveyStatus.
             * @memberof streem.api
             * @classdesc Represents a StreemSurveyStatus.
             * @implements IStreemSurveyStatus
             * @constructor
             * @param {streem.api.IStreemSurveyStatus=} [p] Properties to set
             */
            function StreemSurveyStatus(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemSurveyStatus shouldViewSurvey.
             * @member {boolean} shouldViewSurvey
             * @memberof streem.api.StreemSurveyStatus
             * @instance
             */
            StreemSurveyStatus.prototype.shouldViewSurvey = false;

            /**
             * Creates a new StreemSurveyStatus instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {streem.api.IStreemSurveyStatus=} [properties] Properties to set
             * @returns {streem.api.StreemSurveyStatus} StreemSurveyStatus instance
             */
            StreemSurveyStatus.create = function create(properties) {
                return new StreemSurveyStatus(properties);
            };

            /**
             * Encodes the specified StreemSurveyStatus message. Does not implicitly {@link streem.api.StreemSurveyStatus.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {streem.api.IStreemSurveyStatus} m StreemSurveyStatus message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemSurveyStatus.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.shouldViewSurvey != null && Object.hasOwnProperty.call(m, "shouldViewSurvey"))
                    w.uint32(8).bool(m.shouldViewSurvey);
                return w;
            };

            /**
             * Decodes a StreemSurveyStatus message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemSurveyStatus} StreemSurveyStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemSurveyStatus.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemSurveyStatus();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.shouldViewSurvey = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemSurveyStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemSurveyStatus} StreemSurveyStatus
             */
            StreemSurveyStatus.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemSurveyStatus)
                    return d;
                var m = new $root.streem.api.StreemSurveyStatus();
                if (d.shouldViewSurvey != null) {
                    m.shouldViewSurvey = Boolean(d.shouldViewSurvey);
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemSurveyStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {streem.api.StreemSurveyStatus} m StreemSurveyStatus
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemSurveyStatus.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.shouldViewSurvey = false;
                }
                if (m.shouldViewSurvey != null && m.hasOwnProperty("shouldViewSurvey")) {
                    d.shouldViewSurvey = m.shouldViewSurvey;
                }
                return d;
            };

            /**
             * Converts this StreemSurveyStatus to JSON.
             * @function toJSON
             * @memberof streem.api.StreemSurveyStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemSurveyStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemSurveyStatus
             * @function getTypeUrl
             * @memberof streem.api.StreemSurveyStatus
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemSurveyStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemSurveyStatus";
            };

            return StreemSurveyStatus;
        })();

        api.UserHeartbeat = (function() {

            /**
             * Properties of a UserHeartbeat.
             * @memberof streem.api
             * @interface IUserHeartbeat
             * @property {string|null} [userSid] UserHeartbeat userSid
             * @property {google.protobuf.ITimestamp|null} [heartbeat] UserHeartbeat heartbeat
             * @property {number|null} [count] UserHeartbeat count
             */

            /**
             * Constructs a new UserHeartbeat.
             * @memberof streem.api
             * @classdesc Represents a UserHeartbeat.
             * @implements IUserHeartbeat
             * @constructor
             * @param {streem.api.IUserHeartbeat=} [p] Properties to set
             */
            function UserHeartbeat(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserHeartbeat userSid.
             * @member {string} userSid
             * @memberof streem.api.UserHeartbeat
             * @instance
             */
            UserHeartbeat.prototype.userSid = "";

            /**
             * UserHeartbeat heartbeat.
             * @member {google.protobuf.ITimestamp|null|undefined} heartbeat
             * @memberof streem.api.UserHeartbeat
             * @instance
             */
            UserHeartbeat.prototype.heartbeat = null;

            /**
             * UserHeartbeat count.
             * @member {number} count
             * @memberof streem.api.UserHeartbeat
             * @instance
             */
            UserHeartbeat.prototype.count = 0;

            /**
             * Creates a new UserHeartbeat instance using the specified properties.
             * @function create
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {streem.api.IUserHeartbeat=} [properties] Properties to set
             * @returns {streem.api.UserHeartbeat} UserHeartbeat instance
             */
            UserHeartbeat.create = function create(properties) {
                return new UserHeartbeat(properties);
            };

            /**
             * Encodes the specified UserHeartbeat message. Does not implicitly {@link streem.api.UserHeartbeat.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {streem.api.IUserHeartbeat} m UserHeartbeat message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserHeartbeat.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.heartbeat != null && Object.hasOwnProperty.call(m, "heartbeat"))
                    $root.google.protobuf.Timestamp.encode(m.heartbeat, w.uint32(18).fork()).ldelim();
                if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                    w.uint32(24).int32(m.count);
                return w;
            };

            /**
             * Decodes a UserHeartbeat message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UserHeartbeat} UserHeartbeat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserHeartbeat.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UserHeartbeat();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.heartbeat = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.count = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UserHeartbeat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UserHeartbeat} UserHeartbeat
             */
            UserHeartbeat.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UserHeartbeat)
                    return d;
                var m = new $root.streem.api.UserHeartbeat();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.heartbeat != null) {
                    if (typeof d.heartbeat !== "object")
                        throw TypeError(".streem.api.UserHeartbeat.heartbeat: object expected");
                    m.heartbeat = $root.google.protobuf.Timestamp.fromObject(d.heartbeat);
                }
                if (d.count != null) {
                    m.count = d.count | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a UserHeartbeat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {streem.api.UserHeartbeat} m UserHeartbeat
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserHeartbeat.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.heartbeat = null;
                    d.count = 0;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.heartbeat != null && m.hasOwnProperty("heartbeat")) {
                    d.heartbeat = $root.google.protobuf.Timestamp.toObject(m.heartbeat, o);
                }
                if (m.count != null && m.hasOwnProperty("count")) {
                    d.count = m.count;
                }
                return d;
            };

            /**
             * Converts this UserHeartbeat to JSON.
             * @function toJSON
             * @memberof streem.api.UserHeartbeat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserHeartbeat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserHeartbeat
             * @function getTypeUrl
             * @memberof streem.api.UserHeartbeat
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UserHeartbeat";
            };

            return UserHeartbeat;
        })();

        api.Artifact = (function() {

            /**
             * Properties of an Artifact.
             * @memberof streem.api
             * @interface IArtifact
             * @property {string|null} [sid] Artifact sid
             * @property {string|null} [roomSid] Artifact roomSid
             * @property {streem.api.Artifact.Type|null} [type] Artifact type
             * @property {boolean|null} [deleted] Artifact deleted
             * @property {google.protobuf.ITimestamp|null} [scheduledForDeletionAt] Artifact scheduledForDeletionAt
             * @property {google.protobuf.ITimestamp|null} [deletedAt] Artifact deletedAt
             * @property {streem.api.Artifact.IGpsPosition|null} [gpsPosition] Artifact gpsPosition
             * @property {streem.api.Artifact.IMesh|null} [mesh] Artifact mesh
             * @property {streem.api.Artifact.IRecordingTrack|null} [recordingTrack] Artifact recordingTrack
             * @property {streem.api.Artifact.IRecording|null} [recording] Artifact recording
             * @property {streem.api.Artifact.IStreemshot|null} [streemshot] Artifact streemshot
             * @property {streem.api.Artifact.ITranscription|null} [transcription] Artifact transcription
             * @property {streem.api.Artifact.ISceneshot|null} [sceneshot] Artifact sceneshot
             * @property {streem.api.Artifact.ILayoutEstimation|null} [layoutEstimation] Artifact layoutEstimation
             * @property {streem.api.Artifact.ITexturedMesh|null} [texturedMesh] Artifact texturedMesh
             * @property {streem.api.Artifact.IBookmark|null} [bookmark] Artifact bookmark
             * @property {streem.api.Artifact.IRoomOutcomeReport|null} [roomOutcomeReport] Artifact roomOutcomeReport
             */

            /**
             * Constructs a new Artifact.
             * @memberof streem.api
             * @classdesc Represents an Artifact.
             * @implements IArtifact
             * @constructor
             * @param {streem.api.IArtifact=} [p] Properties to set
             */
            function Artifact(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Artifact sid.
             * @member {string} sid
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.sid = "";

            /**
             * Artifact roomSid.
             * @member {string} roomSid
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.roomSid = "";

            /**
             * Artifact type.
             * @member {streem.api.Artifact.Type} type
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.type = 0;

            /**
             * Artifact deleted.
             * @member {boolean} deleted
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.deleted = false;

            /**
             * Artifact scheduledForDeletionAt.
             * @member {google.protobuf.ITimestamp|null|undefined} scheduledForDeletionAt
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.scheduledForDeletionAt = null;

            /**
             * Artifact deletedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.deletedAt = null;

            /**
             * Artifact gpsPosition.
             * @member {streem.api.Artifact.IGpsPosition|null|undefined} gpsPosition
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.gpsPosition = null;

            /**
             * Artifact mesh.
             * @member {streem.api.Artifact.IMesh|null|undefined} mesh
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.mesh = null;

            /**
             * Artifact recordingTrack.
             * @member {streem.api.Artifact.IRecordingTrack|null|undefined} recordingTrack
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.recordingTrack = null;

            /**
             * Artifact recording.
             * @member {streem.api.Artifact.IRecording|null|undefined} recording
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.recording = null;

            /**
             * Artifact streemshot.
             * @member {streem.api.Artifact.IStreemshot|null|undefined} streemshot
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.streemshot = null;

            /**
             * Artifact transcription.
             * @member {streem.api.Artifact.ITranscription|null|undefined} transcription
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.transcription = null;

            /**
             * Artifact sceneshot.
             * @member {streem.api.Artifact.ISceneshot|null|undefined} sceneshot
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.sceneshot = null;

            /**
             * Artifact layoutEstimation.
             * @member {streem.api.Artifact.ILayoutEstimation|null|undefined} layoutEstimation
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.layoutEstimation = null;

            /**
             * Artifact texturedMesh.
             * @member {streem.api.Artifact.ITexturedMesh|null|undefined} texturedMesh
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.texturedMesh = null;

            /**
             * Artifact bookmark.
             * @member {streem.api.Artifact.IBookmark|null|undefined} bookmark
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.bookmark = null;

            /**
             * Artifact roomOutcomeReport.
             * @member {streem.api.Artifact.IRoomOutcomeReport|null|undefined} roomOutcomeReport
             * @memberof streem.api.Artifact
             * @instance
             */
            Artifact.prototype.roomOutcomeReport = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Artifact typeData.
             * @member {"gpsPosition"|"mesh"|"recordingTrack"|"recording"|"streemshot"|"transcription"|"sceneshot"|"layoutEstimation"|"texturedMesh"|"bookmark"|"roomOutcomeReport"|undefined} typeData
             * @memberof streem.api.Artifact
             * @instance
             */
            Object.defineProperty(Artifact.prototype, "typeData", {
                get: $util.oneOfGetter($oneOfFields = ["gpsPosition", "mesh", "recordingTrack", "recording", "streemshot", "transcription", "sceneshot", "layoutEstimation", "texturedMesh", "bookmark", "roomOutcomeReport"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Artifact instance using the specified properties.
             * @function create
             * @memberof streem.api.Artifact
             * @static
             * @param {streem.api.IArtifact=} [properties] Properties to set
             * @returns {streem.api.Artifact} Artifact instance
             */
            Artifact.create = function create(properties) {
                return new Artifact(properties);
            };

            /**
             * Encodes the specified Artifact message. Does not implicitly {@link streem.api.Artifact.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Artifact
             * @static
             * @param {streem.api.IArtifact} m Artifact message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Artifact.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(32).int32(m.type);
                if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                    w.uint32(40).bool(m.deleted);
                if (m.scheduledForDeletionAt != null && Object.hasOwnProperty.call(m, "scheduledForDeletionAt"))
                    $root.google.protobuf.Timestamp.encode(m.scheduledForDeletionAt, w.uint32(50).fork()).ldelim();
                if (m.deletedAt != null && Object.hasOwnProperty.call(m, "deletedAt"))
                    $root.google.protobuf.Timestamp.encode(m.deletedAt, w.uint32(58).fork()).ldelim();
                if (m.gpsPosition != null && Object.hasOwnProperty.call(m, "gpsPosition"))
                    $root.streem.api.Artifact.GpsPosition.encode(m.gpsPosition, w.uint32(802).fork()).ldelim();
                if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                    $root.streem.api.Artifact.Mesh.encode(m.mesh, w.uint32(810).fork()).ldelim();
                if (m.recordingTrack != null && Object.hasOwnProperty.call(m, "recordingTrack"))
                    $root.streem.api.Artifact.RecordingTrack.encode(m.recordingTrack, w.uint32(818).fork()).ldelim();
                if (m.recording != null && Object.hasOwnProperty.call(m, "recording"))
                    $root.streem.api.Artifact.Recording.encode(m.recording, w.uint32(826).fork()).ldelim();
                if (m.streemshot != null && Object.hasOwnProperty.call(m, "streemshot"))
                    $root.streem.api.Artifact.Streemshot.encode(m.streemshot, w.uint32(834).fork()).ldelim();
                if (m.transcription != null && Object.hasOwnProperty.call(m, "transcription"))
                    $root.streem.api.Artifact.Transcription.encode(m.transcription, w.uint32(842).fork()).ldelim();
                if (m.sceneshot != null && Object.hasOwnProperty.call(m, "sceneshot"))
                    $root.streem.api.Artifact.Sceneshot.encode(m.sceneshot, w.uint32(850).fork()).ldelim();
                if (m.layoutEstimation != null && Object.hasOwnProperty.call(m, "layoutEstimation"))
                    $root.streem.api.Artifact.LayoutEstimation.encode(m.layoutEstimation, w.uint32(858).fork()).ldelim();
                if (m.texturedMesh != null && Object.hasOwnProperty.call(m, "texturedMesh"))
                    $root.streem.api.Artifact.TexturedMesh.encode(m.texturedMesh, w.uint32(866).fork()).ldelim();
                if (m.bookmark != null && Object.hasOwnProperty.call(m, "bookmark"))
                    $root.streem.api.Artifact.Bookmark.encode(m.bookmark, w.uint32(874).fork()).ldelim();
                if (m.roomOutcomeReport != null && Object.hasOwnProperty.call(m, "roomOutcomeReport"))
                    $root.streem.api.Artifact.RoomOutcomeReport.encode(m.roomOutcomeReport, w.uint32(882).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an Artifact message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Artifact
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Artifact} Artifact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Artifact.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 4: {
                            m.type = r.int32();
                            break;
                        }
                    case 5: {
                            m.deleted = r.bool();
                            break;
                        }
                    case 6: {
                            m.scheduledForDeletionAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            m.deletedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 100: {
                            m.gpsPosition = $root.streem.api.Artifact.GpsPosition.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.mesh = $root.streem.api.Artifact.Mesh.decode(r, r.uint32());
                            break;
                        }
                    case 102: {
                            m.recordingTrack = $root.streem.api.Artifact.RecordingTrack.decode(r, r.uint32());
                            break;
                        }
                    case 103: {
                            m.recording = $root.streem.api.Artifact.Recording.decode(r, r.uint32());
                            break;
                        }
                    case 104: {
                            m.streemshot = $root.streem.api.Artifact.Streemshot.decode(r, r.uint32());
                            break;
                        }
                    case 105: {
                            m.transcription = $root.streem.api.Artifact.Transcription.decode(r, r.uint32());
                            break;
                        }
                    case 106: {
                            m.sceneshot = $root.streem.api.Artifact.Sceneshot.decode(r, r.uint32());
                            break;
                        }
                    case 107: {
                            m.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.decode(r, r.uint32());
                            break;
                        }
                    case 108: {
                            m.texturedMesh = $root.streem.api.Artifact.TexturedMesh.decode(r, r.uint32());
                            break;
                        }
                    case 109: {
                            m.bookmark = $root.streem.api.Artifact.Bookmark.decode(r, r.uint32());
                            break;
                        }
                    case 110: {
                            m.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Artifact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Artifact
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Artifact} Artifact
             */
            Artifact.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Artifact)
                    return d;
                var m = new $root.streem.api.Artifact();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    m.type = 0;
                    break;
                case "STREEMSHOT":
                case 1:
                    m.type = 1;
                    break;
                case "RECORDING":
                case 2:
                    m.type = 2;
                    break;
                case "RECORDING_TRACK":
                case 3:
                    m.type = 3;
                    break;
                case "GPS_POSITION":
                case 4:
                    m.type = 4;
                    break;
                case "MESH":
                case 5:
                    m.type = 5;
                    break;
                case "TRANSCRIPTION":
                case 6:
                    m.type = 6;
                    break;
                case "SCENESHOT":
                case 7:
                    m.type = 7;
                    break;
                case "LAYOUT_ESTIMATION":
                case 8:
                    m.type = 8;
                    break;
                case "TEXTURED_MESH":
                case 9:
                    m.type = 9;
                    break;
                case "BOOKMARK":
                case 10:
                    m.type = 10;
                    break;
                case "ROOM_OUTCOME_REPORT":
                case 11:
                    m.type = 11;
                    break;
                }
                if (d.deleted != null) {
                    m.deleted = Boolean(d.deleted);
                }
                if (d.scheduledForDeletionAt != null) {
                    if (typeof d.scheduledForDeletionAt !== "object")
                        throw TypeError(".streem.api.Artifact.scheduledForDeletionAt: object expected");
                    m.scheduledForDeletionAt = $root.google.protobuf.Timestamp.fromObject(d.scheduledForDeletionAt);
                }
                if (d.deletedAt != null) {
                    if (typeof d.deletedAt !== "object")
                        throw TypeError(".streem.api.Artifact.deletedAt: object expected");
                    m.deletedAt = $root.google.protobuf.Timestamp.fromObject(d.deletedAt);
                }
                if (d.gpsPosition != null) {
                    if (typeof d.gpsPosition !== "object")
                        throw TypeError(".streem.api.Artifact.gpsPosition: object expected");
                    m.gpsPosition = $root.streem.api.Artifact.GpsPosition.fromObject(d.gpsPosition);
                }
                if (d.mesh != null) {
                    if (typeof d.mesh !== "object")
                        throw TypeError(".streem.api.Artifact.mesh: object expected");
                    m.mesh = $root.streem.api.Artifact.Mesh.fromObject(d.mesh);
                }
                if (d.recordingTrack != null) {
                    if (typeof d.recordingTrack !== "object")
                        throw TypeError(".streem.api.Artifact.recordingTrack: object expected");
                    m.recordingTrack = $root.streem.api.Artifact.RecordingTrack.fromObject(d.recordingTrack);
                }
                if (d.recording != null) {
                    if (typeof d.recording !== "object")
                        throw TypeError(".streem.api.Artifact.recording: object expected");
                    m.recording = $root.streem.api.Artifact.Recording.fromObject(d.recording);
                }
                if (d.streemshot != null) {
                    if (typeof d.streemshot !== "object")
                        throw TypeError(".streem.api.Artifact.streemshot: object expected");
                    m.streemshot = $root.streem.api.Artifact.Streemshot.fromObject(d.streemshot);
                }
                if (d.transcription != null) {
                    if (typeof d.transcription !== "object")
                        throw TypeError(".streem.api.Artifact.transcription: object expected");
                    m.transcription = $root.streem.api.Artifact.Transcription.fromObject(d.transcription);
                }
                if (d.sceneshot != null) {
                    if (typeof d.sceneshot !== "object")
                        throw TypeError(".streem.api.Artifact.sceneshot: object expected");
                    m.sceneshot = $root.streem.api.Artifact.Sceneshot.fromObject(d.sceneshot);
                }
                if (d.layoutEstimation != null) {
                    if (typeof d.layoutEstimation !== "object")
                        throw TypeError(".streem.api.Artifact.layoutEstimation: object expected");
                    m.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.fromObject(d.layoutEstimation);
                }
                if (d.texturedMesh != null) {
                    if (typeof d.texturedMesh !== "object")
                        throw TypeError(".streem.api.Artifact.texturedMesh: object expected");
                    m.texturedMesh = $root.streem.api.Artifact.TexturedMesh.fromObject(d.texturedMesh);
                }
                if (d.bookmark != null) {
                    if (typeof d.bookmark !== "object")
                        throw TypeError(".streem.api.Artifact.bookmark: object expected");
                    m.bookmark = $root.streem.api.Artifact.Bookmark.fromObject(d.bookmark);
                }
                if (d.roomOutcomeReport != null) {
                    if (typeof d.roomOutcomeReport !== "object")
                        throw TypeError(".streem.api.Artifact.roomOutcomeReport: object expected");
                    m.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.fromObject(d.roomOutcomeReport);
                }
                return m;
            };

            /**
             * Creates a plain object from an Artifact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Artifact
             * @static
             * @param {streem.api.Artifact} m Artifact
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Artifact.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.roomSid = "";
                    d.type = o.enums === String ? "UNKNOWN" : 0;
                    d.deleted = false;
                    d.scheduledForDeletionAt = null;
                    d.deletedAt = null;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.Artifact.Type[m.type] === undefined ? m.type : $root.streem.api.Artifact.Type[m.type] : m.type;
                }
                if (m.deleted != null && m.hasOwnProperty("deleted")) {
                    d.deleted = m.deleted;
                }
                if (m.scheduledForDeletionAt != null && m.hasOwnProperty("scheduledForDeletionAt")) {
                    d.scheduledForDeletionAt = $root.google.protobuf.Timestamp.toObject(m.scheduledForDeletionAt, o);
                }
                if (m.deletedAt != null && m.hasOwnProperty("deletedAt")) {
                    d.deletedAt = $root.google.protobuf.Timestamp.toObject(m.deletedAt, o);
                }
                if (m.gpsPosition != null && m.hasOwnProperty("gpsPosition")) {
                    d.gpsPosition = $root.streem.api.Artifact.GpsPosition.toObject(m.gpsPosition, o);
                    if (o.oneofs)
                        d.typeData = "gpsPosition";
                }
                if (m.mesh != null && m.hasOwnProperty("mesh")) {
                    d.mesh = $root.streem.api.Artifact.Mesh.toObject(m.mesh, o);
                    if (o.oneofs)
                        d.typeData = "mesh";
                }
                if (m.recordingTrack != null && m.hasOwnProperty("recordingTrack")) {
                    d.recordingTrack = $root.streem.api.Artifact.RecordingTrack.toObject(m.recordingTrack, o);
                    if (o.oneofs)
                        d.typeData = "recordingTrack";
                }
                if (m.recording != null && m.hasOwnProperty("recording")) {
                    d.recording = $root.streem.api.Artifact.Recording.toObject(m.recording, o);
                    if (o.oneofs)
                        d.typeData = "recording";
                }
                if (m.streemshot != null && m.hasOwnProperty("streemshot")) {
                    d.streemshot = $root.streem.api.Artifact.Streemshot.toObject(m.streemshot, o);
                    if (o.oneofs)
                        d.typeData = "streemshot";
                }
                if (m.transcription != null && m.hasOwnProperty("transcription")) {
                    d.transcription = $root.streem.api.Artifact.Transcription.toObject(m.transcription, o);
                    if (o.oneofs)
                        d.typeData = "transcription";
                }
                if (m.sceneshot != null && m.hasOwnProperty("sceneshot")) {
                    d.sceneshot = $root.streem.api.Artifact.Sceneshot.toObject(m.sceneshot, o);
                    if (o.oneofs)
                        d.typeData = "sceneshot";
                }
                if (m.layoutEstimation != null && m.hasOwnProperty("layoutEstimation")) {
                    d.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.toObject(m.layoutEstimation, o);
                    if (o.oneofs)
                        d.typeData = "layoutEstimation";
                }
                if (m.texturedMesh != null && m.hasOwnProperty("texturedMesh")) {
                    d.texturedMesh = $root.streem.api.Artifact.TexturedMesh.toObject(m.texturedMesh, o);
                    if (o.oneofs)
                        d.typeData = "texturedMesh";
                }
                if (m.bookmark != null && m.hasOwnProperty("bookmark")) {
                    d.bookmark = $root.streem.api.Artifact.Bookmark.toObject(m.bookmark, o);
                    if (o.oneofs)
                        d.typeData = "bookmark";
                }
                if (m.roomOutcomeReport != null && m.hasOwnProperty("roomOutcomeReport")) {
                    d.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.toObject(m.roomOutcomeReport, o);
                    if (o.oneofs)
                        d.typeData = "roomOutcomeReport";
                }
                return d;
            };

            /**
             * Converts this Artifact to JSON.
             * @function toJSON
             * @memberof streem.api.Artifact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Artifact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Artifact
             * @function getTypeUrl
             * @memberof streem.api.Artifact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Artifact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Artifact";
            };

            /**
             * Type enum.
             * @name streem.api.Artifact.Type
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} STREEMSHOT=1 STREEMSHOT value
             * @property {number} RECORDING=2 RECORDING value
             * @property {number} RECORDING_TRACK=3 RECORDING_TRACK value
             * @property {number} GPS_POSITION=4 GPS_POSITION value
             * @property {number} MESH=5 MESH value
             * @property {number} TRANSCRIPTION=6 TRANSCRIPTION value
             * @property {number} SCENESHOT=7 SCENESHOT value
             * @property {number} LAYOUT_ESTIMATION=8 LAYOUT_ESTIMATION value
             * @property {number} TEXTURED_MESH=9 TEXTURED_MESH value
             * @property {number} BOOKMARK=10 BOOKMARK value
             * @property {number} ROOM_OUTCOME_REPORT=11 ROOM_OUTCOME_REPORT value
             */
            Artifact.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "STREEMSHOT"] = 1;
                values[valuesById[2] = "RECORDING"] = 2;
                values[valuesById[3] = "RECORDING_TRACK"] = 3;
                values[valuesById[4] = "GPS_POSITION"] = 4;
                values[valuesById[5] = "MESH"] = 5;
                values[valuesById[6] = "TRANSCRIPTION"] = 6;
                values[valuesById[7] = "SCENESHOT"] = 7;
                values[valuesById[8] = "LAYOUT_ESTIMATION"] = 8;
                values[valuesById[9] = "TEXTURED_MESH"] = 9;
                values[valuesById[10] = "BOOKMARK"] = 10;
                values[valuesById[11] = "ROOM_OUTCOME_REPORT"] = 11;
                return values;
            })();

            /**
             * State diagram:
             * 
             * @startuml
             * [*] --> STATUS_REJECTED : Request rejected
             * [*] --> STATUS_ERROR
             * [*] --> STATUS_STARTED : Request accepted,\n started capturing artifact\n (e.g. a video recording)
             * [*] --> STATUS_CAPTURED : Request accepted,\n artifact captured but not yet fulfilled\n (e.g. a Streemshot)
             * [*] --> STATUS_FULFILLED : Request accepted,\n artifact is immediately fulfilled\n (e.g. GPS)
             * STATUS_REJECTED --> [*]
             * STATUS_ERROR --> [*]
             * STATUS_STARTED --> STATUS_ERROR
             * STATUS_STARTED --> STATUS_CAPTURED : Artifact capture complete\n (e.g. a video recording)
             * STATUS_CAPTURED --> STATUS_ERROR
             * STATUS_CAPTURED --> STATUS_FULFILLED : Artifact is fulfilled\n (e.g. finished uploading)
             * STATUS_FULFILLED --> [*]
             * @enduml
             * @name streem.api.Artifact.Status
             * @enum {number}
             * @property {number} STATUS_UNKNOWN=0 STATUS_UNKNOWN value
             * @property {number} STATUS_REJECTED=1 STATUS_REJECTED value
             * @property {number} STATUS_ERROR=2 STATUS_ERROR value
             * @property {number} STATUS_STARTED=3 STATUS_STARTED value
             * @property {number} STATUS_CAPTURED=4 STATUS_CAPTURED value
             * @property {number} STATUS_FULFILLED=5 STATUS_FULFILLED value
             */
            Artifact.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "STATUS_REJECTED"] = 1;
                values[valuesById[2] = "STATUS_ERROR"] = 2;
                values[valuesById[3] = "STATUS_STARTED"] = 3;
                values[valuesById[4] = "STATUS_CAPTURED"] = 4;
                values[valuesById[5] = "STATUS_FULFILLED"] = 5;
                return values;
            })();

            Artifact.GpsPosition = (function() {

                /**
                 * Properties of a GpsPosition.
                 * @memberof streem.api.Artifact
                 * @interface IGpsPosition
                 * @property {string|null} [sourcedFromUserSid] GpsPosition sourcedFromUserSid
                 * @property {string|null} [sourcedFromDeviceSid] GpsPosition sourcedFromDeviceSid
                 * @property {google.protobuf.ITimestamp|null} [clientCreatedAt] GpsPosition clientCreatedAt
                 * @property {number|null} [latitudeDegrees] GpsPosition latitudeDegrees
                 * @property {number|null} [longitudeDegrees] GpsPosition longitudeDegrees
                 * @property {number|null} [accuracyMeters] GpsPosition accuracyMeters
                 * @property {string|null} [formattedAddress] GpsPosition formattedAddress
                 */

                /**
                 * Constructs a new GpsPosition.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a GpsPosition.
                 * @implements IGpsPosition
                 * @constructor
                 * @param {streem.api.Artifact.IGpsPosition=} [p] Properties to set
                 */
                function GpsPosition(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GpsPosition sourcedFromUserSid.
                 * @member {string} sourcedFromUserSid
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.sourcedFromUserSid = "";

                /**
                 * GpsPosition sourcedFromDeviceSid.
                 * @member {string} sourcedFromDeviceSid
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.sourcedFromDeviceSid = "";

                /**
                 * GpsPosition clientCreatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} clientCreatedAt
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.clientCreatedAt = null;

                /**
                 * GpsPosition latitudeDegrees.
                 * @member {number} latitudeDegrees
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.latitudeDegrees = 0;

                /**
                 * GpsPosition longitudeDegrees.
                 * @member {number} longitudeDegrees
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.longitudeDegrees = 0;

                /**
                 * GpsPosition accuracyMeters.
                 * @member {number} accuracyMeters
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.accuracyMeters = 0;

                /**
                 * GpsPosition formattedAddress.
                 * @member {string} formattedAddress
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 */
                GpsPosition.prototype.formattedAddress = "";

                /**
                 * Creates a new GpsPosition instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {streem.api.Artifact.IGpsPosition=} [properties] Properties to set
                 * @returns {streem.api.Artifact.GpsPosition} GpsPosition instance
                 */
                GpsPosition.create = function create(properties) {
                    return new GpsPosition(properties);
                };

                /**
                 * Encodes the specified GpsPosition message. Does not implicitly {@link streem.api.Artifact.GpsPosition.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {streem.api.Artifact.IGpsPosition} m GpsPosition message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GpsPosition.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.sourcedFromUserSid != null && Object.hasOwnProperty.call(m, "sourcedFromUserSid"))
                        w.uint32(10).string(m.sourcedFromUserSid);
                    if (m.sourcedFromDeviceSid != null && Object.hasOwnProperty.call(m, "sourcedFromDeviceSid"))
                        w.uint32(18).string(m.sourcedFromDeviceSid);
                    if (m.clientCreatedAt != null && Object.hasOwnProperty.call(m, "clientCreatedAt"))
                        $root.google.protobuf.Timestamp.encode(m.clientCreatedAt, w.uint32(26).fork()).ldelim();
                    if (m.latitudeDegrees != null && Object.hasOwnProperty.call(m, "latitudeDegrees"))
                        w.uint32(33).double(m.latitudeDegrees);
                    if (m.longitudeDegrees != null && Object.hasOwnProperty.call(m, "longitudeDegrees"))
                        w.uint32(41).double(m.longitudeDegrees);
                    if (m.accuracyMeters != null && Object.hasOwnProperty.call(m, "accuracyMeters"))
                        w.uint32(49).double(m.accuracyMeters);
                    if (m.formattedAddress != null && Object.hasOwnProperty.call(m, "formattedAddress"))
                        w.uint32(58).string(m.formattedAddress);
                    return w;
                };

                /**
                 * Decodes a GpsPosition message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.GpsPosition} GpsPosition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GpsPosition.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.GpsPosition();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.sourcedFromUserSid = r.string();
                                break;
                            }
                        case 2: {
                                m.sourcedFromDeviceSid = r.string();
                                break;
                            }
                        case 3: {
                                m.clientCreatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.latitudeDegrees = r.double();
                                break;
                            }
                        case 5: {
                                m.longitudeDegrees = r.double();
                                break;
                            }
                        case 6: {
                                m.accuracyMeters = r.double();
                                break;
                            }
                        case 7: {
                                m.formattedAddress = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GpsPosition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.GpsPosition} GpsPosition
                 */
                GpsPosition.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.GpsPosition)
                        return d;
                    var m = new $root.streem.api.Artifact.GpsPosition();
                    if (d.sourcedFromUserSid != null) {
                        m.sourcedFromUserSid = String(d.sourcedFromUserSid);
                    }
                    if (d.sourcedFromDeviceSid != null) {
                        m.sourcedFromDeviceSid = String(d.sourcedFromDeviceSid);
                    }
                    if (d.clientCreatedAt != null) {
                        if (typeof d.clientCreatedAt !== "object")
                            throw TypeError(".streem.api.Artifact.GpsPosition.clientCreatedAt: object expected");
                        m.clientCreatedAt = $root.google.protobuf.Timestamp.fromObject(d.clientCreatedAt);
                    }
                    if (d.latitudeDegrees != null) {
                        m.latitudeDegrees = Number(d.latitudeDegrees);
                    }
                    if (d.longitudeDegrees != null) {
                        m.longitudeDegrees = Number(d.longitudeDegrees);
                    }
                    if (d.accuracyMeters != null) {
                        m.accuracyMeters = Number(d.accuracyMeters);
                    }
                    if (d.formattedAddress != null) {
                        m.formattedAddress = String(d.formattedAddress);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GpsPosition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {streem.api.Artifact.GpsPosition} m GpsPosition
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GpsPosition.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.sourcedFromUserSid = "";
                        d.sourcedFromDeviceSid = "";
                        d.clientCreatedAt = null;
                        d.latitudeDegrees = 0;
                        d.longitudeDegrees = 0;
                        d.accuracyMeters = 0;
                        d.formattedAddress = "";
                    }
                    if (m.sourcedFromUserSid != null && m.hasOwnProperty("sourcedFromUserSid")) {
                        d.sourcedFromUserSid = m.sourcedFromUserSid;
                    }
                    if (m.sourcedFromDeviceSid != null && m.hasOwnProperty("sourcedFromDeviceSid")) {
                        d.sourcedFromDeviceSid = m.sourcedFromDeviceSid;
                    }
                    if (m.clientCreatedAt != null && m.hasOwnProperty("clientCreatedAt")) {
                        d.clientCreatedAt = $root.google.protobuf.Timestamp.toObject(m.clientCreatedAt, o);
                    }
                    if (m.latitudeDegrees != null && m.hasOwnProperty("latitudeDegrees")) {
                        d.latitudeDegrees = o.json && !isFinite(m.latitudeDegrees) ? String(m.latitudeDegrees) : m.latitudeDegrees;
                    }
                    if (m.longitudeDegrees != null && m.hasOwnProperty("longitudeDegrees")) {
                        d.longitudeDegrees = o.json && !isFinite(m.longitudeDegrees) ? String(m.longitudeDegrees) : m.longitudeDegrees;
                    }
                    if (m.accuracyMeters != null && m.hasOwnProperty("accuracyMeters")) {
                        d.accuracyMeters = o.json && !isFinite(m.accuracyMeters) ? String(m.accuracyMeters) : m.accuracyMeters;
                    }
                    if (m.formattedAddress != null && m.hasOwnProperty("formattedAddress")) {
                        d.formattedAddress = m.formattedAddress;
                    }
                    return d;
                };

                /**
                 * Converts this GpsPosition to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.GpsPosition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GpsPosition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GpsPosition
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.GpsPosition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GpsPosition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.GpsPosition";
                };

                return GpsPosition;
            })();

            Artifact.Mesh = (function() {

                /**
                 * Properties of a Mesh.
                 * @memberof streem.api.Artifact
                 * @interface IMesh
                 * @property {string|null} [name] Mesh name
                 * @property {streem.api.Artifact.Mesh.Status|null} [status] Mesh status
                 * @property {string|null} [meshStorageUrl] Mesh meshStorageUrl
                 * @property {string|null} [metadataStorageUrl] Mesh metadataStorageUrl
                 * @property {google.protobuf.ITimestamp|null} [requestedAt] Mesh requestedAt
                 * @property {google.protobuf.ITimestamp|null} [startedAt] Mesh startedAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] Mesh erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] Mesh fulfilledAt
                 * @property {string|null} [url] Mesh url
                 */

                /**
                 * Constructs a new Mesh.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Mesh.
                 * @implements IMesh
                 * @constructor
                 * @param {streem.api.Artifact.IMesh=} [p] Properties to set
                 */
                function Mesh(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Mesh name.
                 * @member {string} name
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.name = "";

                /**
                 * Mesh status.
                 * @member {streem.api.Artifact.Mesh.Status} status
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.status = 0;

                /**
                 * Mesh meshStorageUrl.
                 * @member {string} meshStorageUrl
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.meshStorageUrl = "";

                /**
                 * Mesh metadataStorageUrl.
                 * @member {string} metadataStorageUrl
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.metadataStorageUrl = "";

                /**
                 * Mesh requestedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.requestedAt = null;

                /**
                 * Mesh startedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.startedAt = null;

                /**
                 * Mesh erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.erroredAt = null;

                /**
                 * Mesh fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.fulfilledAt = null;

                /**
                 * Mesh url.
                 * @member {string} url
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 */
                Mesh.prototype.url = "";

                /**
                 * Creates a new Mesh instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {streem.api.Artifact.IMesh=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Mesh} Mesh instance
                 */
                Mesh.create = function create(properties) {
                    return new Mesh(properties);
                };

                /**
                 * Encodes the specified Mesh message. Does not implicitly {@link streem.api.Artifact.Mesh.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {streem.api.Artifact.IMesh} m Mesh message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Mesh.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(10).string(m.name);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(16).int32(m.status);
                    if (m.meshStorageUrl != null && Object.hasOwnProperty.call(m, "meshStorageUrl"))
                        w.uint32(26).string(m.meshStorageUrl);
                    if (m.metadataStorageUrl != null && Object.hasOwnProperty.call(m, "metadataStorageUrl"))
                        w.uint32(34).string(m.metadataStorageUrl);
                    if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                        $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(42).fork()).ldelim();
                    if (m.startedAt != null && Object.hasOwnProperty.call(m, "startedAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedAt, w.uint32(50).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(58).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(66).fork()).ldelim();
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(74).string(m.url);
                    return w;
                };

                /**
                 * Decodes a Mesh message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Mesh} Mesh
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Mesh.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Mesh();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.name = r.string();
                                break;
                            }
                        case 2: {
                                m.status = r.int32();
                                break;
                            }
                        case 3: {
                                m.meshStorageUrl = r.string();
                                break;
                            }
                        case 4: {
                                m.metadataStorageUrl = r.string();
                                break;
                            }
                        case 5: {
                                m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 6: {
                                m.startedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 7: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 8: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 9: {
                                m.url = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Mesh message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Mesh} Mesh
                 */
                Mesh.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Mesh)
                        return d;
                    var m = new $root.streem.api.Artifact.Mesh();
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_INVALID":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REQUESTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_STARTED":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_ERROR":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_FULFILLED":
                    case 4:
                        m.status = 4;
                        break;
                    }
                    if (d.meshStorageUrl != null) {
                        m.meshStorageUrl = String(d.meshStorageUrl);
                    }
                    if (d.metadataStorageUrl != null) {
                        m.metadataStorageUrl = String(d.metadataStorageUrl);
                    }
                    if (d.requestedAt != null) {
                        if (typeof d.requestedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Mesh.requestedAt: object expected");
                        m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                    }
                    if (d.startedAt != null) {
                        if (typeof d.startedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Mesh.startedAt: object expected");
                        m.startedAt = $root.google.protobuf.Timestamp.fromObject(d.startedAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.Mesh.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.Mesh.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Mesh message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {streem.api.Artifact.Mesh} m Mesh
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Mesh.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.name = "";
                        d.status = o.enums === String ? "STATUS_INVALID" : 0;
                        d.meshStorageUrl = "";
                        d.metadataStorageUrl = "";
                        d.requestedAt = null;
                        d.startedAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.url = "";
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Mesh.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Mesh.Status[m.status] : m.status;
                    }
                    if (m.meshStorageUrl != null && m.hasOwnProperty("meshStorageUrl")) {
                        d.meshStorageUrl = m.meshStorageUrl;
                    }
                    if (m.metadataStorageUrl != null && m.hasOwnProperty("metadataStorageUrl")) {
                        d.metadataStorageUrl = m.metadataStorageUrl;
                    }
                    if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                        d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                    }
                    if (m.startedAt != null && m.hasOwnProperty("startedAt")) {
                        d.startedAt = $root.google.protobuf.Timestamp.toObject(m.startedAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    return d;
                };

                /**
                 * Converts this Mesh to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Mesh
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Mesh.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Mesh
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Mesh
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Mesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Mesh";
                };

                /**
                 * Status enum.
                 * @name streem.api.Artifact.Mesh.Status
                 * @enum {number}
                 * @property {number} STATUS_INVALID=0 STATUS_INVALID value
                 * @property {number} STATUS_REQUESTED=1 STATUS_REQUESTED value
                 * @property {number} STATUS_STARTED=2 STATUS_STARTED value
                 * @property {number} STATUS_ERROR=3 STATUS_ERROR value
                 * @property {number} STATUS_FULFILLED=4 STATUS_FULFILLED value
                 */
                Mesh.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_INVALID"] = 0;
                    values[valuesById[1] = "STATUS_REQUESTED"] = 1;
                    values[valuesById[2] = "STATUS_STARTED"] = 2;
                    values[valuesById[3] = "STATUS_ERROR"] = 3;
                    values[valuesById[4] = "STATUS_FULFILLED"] = 4;
                    return values;
                })();

                return Mesh;
            })();

            Artifact.RecordingTrack = (function() {

                /**
                 * Properties of a RecordingTrack.
                 * @memberof streem.api.Artifact
                 * @interface IRecordingTrack
                 * @property {string|null} [bucket] RecordingTrack bucket
                 * @property {string|null} [filename] RecordingTrack filename
                 * @property {string|null} [userSid] RecordingTrack userSid
                 * @property {string|null} [trackType] RecordingTrack trackType
                 * @property {string|null} [codec] RecordingTrack codec
                 * @property {string|null} [storageUrl] RecordingTrack storageUrl
                 * @property {streem.api.Artifact.RecordingTrack.Status|null} [status] RecordingTrack status
                 * @property {google.protobuf.ITimestamp|null} [requestedAt] RecordingTrack requestedAt
                 * @property {google.protobuf.ITimestamp|null} [startedRecordingAt] RecordingTrack startedRecordingAt
                 * @property {google.protobuf.ITimestamp|null} [finishedRecordingAt] RecordingTrack finishedRecordingAt
                 * @property {google.protobuf.ITimestamp|null} [startedTransferringAt] RecordingTrack startedTransferringAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] RecordingTrack erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] RecordingTrack fulfilledAt
                 * @property {string|null} [url] RecordingTrack url
                 * @property {google.protobuf.ITimestamp|null} [createdAt] RecordingTrack createdAt
                 * @property {streem.api.Artifact.RecordingTrack.Source|null} [recordingSource] RecordingTrack recordingSource
                 * @property {string|null} [sourceRecordingTrackSid] RecordingTrack sourceRecordingTrackSid
                 */

                /**
                 * Constructs a new RecordingTrack.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a RecordingTrack.
                 * @implements IRecordingTrack
                 * @constructor
                 * @param {streem.api.Artifact.IRecordingTrack=} [p] Properties to set
                 */
                function RecordingTrack(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RecordingTrack bucket.
                 * @member {string} bucket
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.bucket = "";

                /**
                 * RecordingTrack filename.
                 * @member {string} filename
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.filename = "";

                /**
                 * RecordingTrack userSid.
                 * @member {string} userSid
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.userSid = "";

                /**
                 * RecordingTrack trackType.
                 * @member {string} trackType
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.trackType = "";

                /**
                 * RecordingTrack codec.
                 * @member {string} codec
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.codec = "";

                /**
                 * RecordingTrack storageUrl.
                 * @member {string} storageUrl
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.storageUrl = "";

                /**
                 * RecordingTrack status.
                 * @member {streem.api.Artifact.RecordingTrack.Status} status
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.status = 0;

                /**
                 * RecordingTrack requestedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.requestedAt = null;

                /**
                 * RecordingTrack startedRecordingAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedRecordingAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.startedRecordingAt = null;

                /**
                 * RecordingTrack finishedRecordingAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} finishedRecordingAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.finishedRecordingAt = null;

                /**
                 * RecordingTrack startedTransferringAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedTransferringAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.startedTransferringAt = null;

                /**
                 * RecordingTrack erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.erroredAt = null;

                /**
                 * RecordingTrack fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.fulfilledAt = null;

                /**
                 * RecordingTrack url.
                 * @member {string} url
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.url = "";

                /**
                 * RecordingTrack createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.createdAt = null;

                /**
                 * RecordingTrack recordingSource.
                 * @member {streem.api.Artifact.RecordingTrack.Source} recordingSource
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.recordingSource = 0;

                /**
                 * RecordingTrack sourceRecordingTrackSid.
                 * @member {string} sourceRecordingTrackSid
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 */
                RecordingTrack.prototype.sourceRecordingTrackSid = "";

                /**
                 * Creates a new RecordingTrack instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {streem.api.Artifact.IRecordingTrack=} [properties] Properties to set
                 * @returns {streem.api.Artifact.RecordingTrack} RecordingTrack instance
                 */
                RecordingTrack.create = function create(properties) {
                    return new RecordingTrack(properties);
                };

                /**
                 * Encodes the specified RecordingTrack message. Does not implicitly {@link streem.api.Artifact.RecordingTrack.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {streem.api.Artifact.IRecordingTrack} m RecordingTrack message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordingTrack.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.bucket != null && Object.hasOwnProperty.call(m, "bucket"))
                        w.uint32(18).string(m.bucket);
                    if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                        w.uint32(26).string(m.filename);
                    if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                        w.uint32(34).string(m.userSid);
                    if (m.trackType != null && Object.hasOwnProperty.call(m, "trackType"))
                        w.uint32(42).string(m.trackType);
                    if (m.codec != null && Object.hasOwnProperty.call(m, "codec"))
                        w.uint32(50).string(m.codec);
                    if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                        w.uint32(66).string(m.storageUrl);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(72).int32(m.status);
                    if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                        $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(82).fork()).ldelim();
                    if (m.startedRecordingAt != null && Object.hasOwnProperty.call(m, "startedRecordingAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedRecordingAt, w.uint32(90).fork()).ldelim();
                    if (m.finishedRecordingAt != null && Object.hasOwnProperty.call(m, "finishedRecordingAt"))
                        $root.google.protobuf.Timestamp.encode(m.finishedRecordingAt, w.uint32(98).fork()).ldelim();
                    if (m.startedTransferringAt != null && Object.hasOwnProperty.call(m, "startedTransferringAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedTransferringAt, w.uint32(106).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(114).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(122).fork()).ldelim();
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(130).string(m.url);
                    if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(138).fork()).ldelim();
                    if (m.recordingSource != null && Object.hasOwnProperty.call(m, "recordingSource"))
                        w.uint32(160).int32(m.recordingSource);
                    if (m.sourceRecordingTrackSid != null && Object.hasOwnProperty.call(m, "sourceRecordingTrackSid"))
                        w.uint32(170).string(m.sourceRecordingTrackSid);
                    return w;
                };

                /**
                 * Decodes a RecordingTrack message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.RecordingTrack} RecordingTrack
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordingTrack.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.RecordingTrack();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 2: {
                                m.bucket = r.string();
                                break;
                            }
                        case 3: {
                                m.filename = r.string();
                                break;
                            }
                        case 4: {
                                m.userSid = r.string();
                                break;
                            }
                        case 5: {
                                m.trackType = r.string();
                                break;
                            }
                        case 6: {
                                m.codec = r.string();
                                break;
                            }
                        case 8: {
                                m.storageUrl = r.string();
                                break;
                            }
                        case 9: {
                                m.status = r.int32();
                                break;
                            }
                        case 10: {
                                m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 11: {
                                m.startedRecordingAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 12: {
                                m.finishedRecordingAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 13: {
                                m.startedTransferringAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 14: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 15: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 16: {
                                m.url = r.string();
                                break;
                            }
                        case 17: {
                                m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 20: {
                                m.recordingSource = r.int32();
                                break;
                            }
                        case 21: {
                                m.sourceRecordingTrackSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RecordingTrack message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.RecordingTrack} RecordingTrack
                 */
                RecordingTrack.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.RecordingTrack)
                        return d;
                    var m = new $root.streem.api.Artifact.RecordingTrack();
                    if (d.bucket != null) {
                        m.bucket = String(d.bucket);
                    }
                    if (d.filename != null) {
                        m.filename = String(d.filename);
                    }
                    if (d.userSid != null) {
                        m.userSid = String(d.userSid);
                    }
                    if (d.trackType != null) {
                        m.trackType = String(d.trackType);
                    }
                    if (d.codec != null) {
                        m.codec = String(d.codec);
                    }
                    if (d.storageUrl != null) {
                        m.storageUrl = String(d.storageUrl);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_INVALID":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_STARTED_RECORDING":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_FINISHED_RECORDING":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_TRANSFERRING":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_ERROR":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.requestedAt != null) {
                        if (typeof d.requestedAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.requestedAt: object expected");
                        m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                    }
                    if (d.startedRecordingAt != null) {
                        if (typeof d.startedRecordingAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.startedRecordingAt: object expected");
                        m.startedRecordingAt = $root.google.protobuf.Timestamp.fromObject(d.startedRecordingAt);
                    }
                    if (d.finishedRecordingAt != null) {
                        if (typeof d.finishedRecordingAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.finishedRecordingAt: object expected");
                        m.finishedRecordingAt = $root.google.protobuf.Timestamp.fromObject(d.finishedRecordingAt);
                    }
                    if (d.startedTransferringAt != null) {
                        if (typeof d.startedTransferringAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.startedTransferringAt: object expected");
                        m.startedTransferringAt = $root.google.protobuf.Timestamp.fromObject(d.startedTransferringAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    if (d.createdAt != null) {
                        if (typeof d.createdAt !== "object")
                            throw TypeError(".streem.api.Artifact.RecordingTrack.createdAt: object expected");
                        m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                    }
                    switch (d.recordingSource) {
                    default:
                        if (typeof d.recordingSource === "number") {
                            m.recordingSource = d.recordingSource;
                            break;
                        }
                        break;
                    case "SOURCE_INVALID":
                    case 0:
                        m.recordingSource = 0;
                        break;
                    case "SOURCE_TWILIO":
                    case 1:
                        m.recordingSource = 1;
                        break;
                    case "SOURCE_EXTERNAL":
                    case 2:
                        m.recordingSource = 2;
                        break;
                    case "SOURCE_MUXER":
                    case 3:
                        m.recordingSource = 3;
                        break;
                    case "SOURCE_ARAAS":
                    case 4:
                        m.recordingSource = 4;
                        break;
                    case "SOURCE_CHIME":
                    case 5:
                        m.recordingSource = 5;
                        break;
                    }
                    if (d.sourceRecordingTrackSid != null) {
                        m.sourceRecordingTrackSid = String(d.sourceRecordingTrackSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RecordingTrack message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {streem.api.Artifact.RecordingTrack} m RecordingTrack
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordingTrack.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.bucket = "";
                        d.filename = "";
                        d.userSid = "";
                        d.trackType = "";
                        d.codec = "";
                        d.storageUrl = "";
                        d.status = o.enums === String ? "STATUS_INVALID" : 0;
                        d.requestedAt = null;
                        d.startedRecordingAt = null;
                        d.finishedRecordingAt = null;
                        d.startedTransferringAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.url = "";
                        d.createdAt = null;
                        d.recordingSource = o.enums === String ? "SOURCE_INVALID" : 0;
                        d.sourceRecordingTrackSid = "";
                    }
                    if (m.bucket != null && m.hasOwnProperty("bucket")) {
                        d.bucket = m.bucket;
                    }
                    if (m.filename != null && m.hasOwnProperty("filename")) {
                        d.filename = m.filename;
                    }
                    if (m.userSid != null && m.hasOwnProperty("userSid")) {
                        d.userSid = m.userSid;
                    }
                    if (m.trackType != null && m.hasOwnProperty("trackType")) {
                        d.trackType = m.trackType;
                    }
                    if (m.codec != null && m.hasOwnProperty("codec")) {
                        d.codec = m.codec;
                    }
                    if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                        d.storageUrl = m.storageUrl;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.RecordingTrack.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.RecordingTrack.Status[m.status] : m.status;
                    }
                    if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                        d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                    }
                    if (m.startedRecordingAt != null && m.hasOwnProperty("startedRecordingAt")) {
                        d.startedRecordingAt = $root.google.protobuf.Timestamp.toObject(m.startedRecordingAt, o);
                    }
                    if (m.finishedRecordingAt != null && m.hasOwnProperty("finishedRecordingAt")) {
                        d.finishedRecordingAt = $root.google.protobuf.Timestamp.toObject(m.finishedRecordingAt, o);
                    }
                    if (m.startedTransferringAt != null && m.hasOwnProperty("startedTransferringAt")) {
                        d.startedTransferringAt = $root.google.protobuf.Timestamp.toObject(m.startedTransferringAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                        d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                    }
                    if (m.recordingSource != null && m.hasOwnProperty("recordingSource")) {
                        d.recordingSource = o.enums === String ? $root.streem.api.Artifact.RecordingTrack.Source[m.recordingSource] === undefined ? m.recordingSource : $root.streem.api.Artifact.RecordingTrack.Source[m.recordingSource] : m.recordingSource;
                    }
                    if (m.sourceRecordingTrackSid != null && m.hasOwnProperty("sourceRecordingTrackSid")) {
                        d.sourceRecordingTrackSid = m.sourceRecordingTrackSid;
                    }
                    return d;
                };

                /**
                 * Converts this RecordingTrack to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordingTrack.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RecordingTrack
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.RecordingTrack
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordingTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.RecordingTrack";
                };

                /**
                 * Status enum.
                 * @name streem.api.Artifact.RecordingTrack.Status
                 * @enum {number}
                 * @property {number} STATUS_INVALID=0 STATUS_INVALID value
                 * @property {number} STATUS_STARTED_RECORDING=1 STATUS_STARTED_RECORDING value
                 * @property {number} STATUS_FINISHED_RECORDING=2 STATUS_FINISHED_RECORDING value
                 * @property {number} STATUS_TRANSFERRING=3 STATUS_TRANSFERRING value
                 * @property {number} STATUS_ERROR=4 STATUS_ERROR value
                 * @property {number} STATUS_FULFILLED=5 STATUS_FULFILLED value
                 */
                RecordingTrack.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_INVALID"] = 0;
                    values[valuesById[1] = "STATUS_STARTED_RECORDING"] = 1;
                    values[valuesById[2] = "STATUS_FINISHED_RECORDING"] = 2;
                    values[valuesById[3] = "STATUS_TRANSFERRING"] = 3;
                    values[valuesById[4] = "STATUS_ERROR"] = 4;
                    values[valuesById[5] = "STATUS_FULFILLED"] = 5;
                    return values;
                })();

                /**
                 * Source enum.
                 * @name streem.api.Artifact.RecordingTrack.Source
                 * @enum {number}
                 * @property {number} SOURCE_INVALID=0 SOURCE_INVALID value
                 * @property {number} SOURCE_TWILIO=1 SOURCE_TWILIO value
                 * @property {number} SOURCE_EXTERNAL=2 SOURCE_EXTERNAL value
                 * @property {number} SOURCE_MUXER=3 SOURCE_MUXER value
                 * @property {number} SOURCE_ARAAS=4 SOURCE_ARAAS value
                 * @property {number} SOURCE_CHIME=5 SOURCE_CHIME value
                 */
                RecordingTrack.Source = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SOURCE_INVALID"] = 0;
                    values[valuesById[1] = "SOURCE_TWILIO"] = 1;
                    values[valuesById[2] = "SOURCE_EXTERNAL"] = 2;
                    values[valuesById[3] = "SOURCE_MUXER"] = 3;
                    values[valuesById[4] = "SOURCE_ARAAS"] = 4;
                    values[valuesById[5] = "SOURCE_CHIME"] = 5;
                    return values;
                })();

                return RecordingTrack;
            })();

            /**
             * State diagram:
             * 
             * @startuml
             * [*] --> Status
             * state Status {
             * Status: Top level information about the recording artifact state
             * STATUS_REQUESTED --> STATUS_ERROR
             * STATUS_REQUESTED --> RecordingStatus
             * RecordingStatus --> STATUS_TRANSFERRING
             * RecordingStatus --> STATUS_ERROR
             * STATUS_TRANSFERRING --> STATUS_FULFILLED
             * state RecordingStatus {
             * RecordingStatus: Detailed information on the status of the recording capture process
             * [*] --> RECORDING_STATUS_NONE: Default status
             * RECORDING_STATUS_NONE --> RECORDING_STATUS_PAUSED: Recording paused
             * RECORDING_STATUS_PAUSED--> RECORDING_STATUS_RESUMED: Recording has been paused
             * RECORDING_STATUS_RESUMED--> RECORDING_STATUS_PAUSED: Recording has been resumed after a pause
             * RECORDING_STATUS_TEMPORARY_FAILURE--> RECORDING_STATUS_RESUMED: Recording resumed after a temporary failure
             * RECORDING_STATUS_NONE --> RECORDING_STATUS_TEMPORARY_FAILURE: Recording has failed, attempting to restart
             * }
             * }
             * @enduml
             * @name streem.api.Artifact.RecordingStatus
             * @enum {number}
             * @property {number} RECORDING_STATUS_INVALID=0 RECORDING_STATUS_INVALID value
             * @property {number} RECORDING_STATUS_NONE=1 RECORDING_STATUS_NONE value
             * @property {number} RECORDING_STATUS_PAUSED=2 RECORDING_STATUS_PAUSED value
             * @property {number} RECORDING_STATUS_RESUMED=3 RECORDING_STATUS_RESUMED value
             * @property {number} RECORDING_STATUS_TEMPORARY_FAILURE=4 RECORDING_STATUS_TEMPORARY_FAILURE value
             */
            Artifact.RecordingStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RECORDING_STATUS_INVALID"] = 0;
                values[valuesById[1] = "RECORDING_STATUS_NONE"] = 1;
                values[valuesById[2] = "RECORDING_STATUS_PAUSED"] = 2;
                values[valuesById[3] = "RECORDING_STATUS_RESUMED"] = 3;
                values[valuesById[4] = "RECORDING_STATUS_TEMPORARY_FAILURE"] = 4;
                return values;
            })();

            Artifact.Recording = (function() {

                /**
                 * Properties of a Recording.
                 * @memberof streem.api.Artifact
                 * @interface IRecording
                 * @property {string|null} [bucket] Recording bucket
                 * @property {string|null} [filename] Recording filename
                 * @property {string|null} [storageUrl] Recording storageUrl
                 * @property {streem.api.Artifact.Recording.Status|null} [status] Recording status
                 * @property {google.protobuf.ITimestamp|null} [requestedAt] Recording requestedAt
                 * @property {google.protobuf.ITimestamp|null} [startedTransferringAt] Recording startedTransferringAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] Recording erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] Recording fulfilledAt
                 * @property {google.protobuf.ITimestamp|null} [createdAt] Recording createdAt
                 * @property {string|null} [url] Recording url
                 * @property {streem.api.Artifact.Recording.Source|null} [recordingSource] Recording recordingSource
                 * @property {string|null} [previewStorageUrl] Recording previewStorageUrl
                 * @property {string|null} [previewUrl] Recording previewUrl
                 * @property {google.protobuf.IDuration|null} [duration] Recording duration
                 * @property {streem.api.Artifact.RecordingStatus|null} [recordingStatus] Recording recordingStatus
                 */

                /**
                 * Constructs a new Recording.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Recording.
                 * @implements IRecording
                 * @constructor
                 * @param {streem.api.Artifact.IRecording=} [p] Properties to set
                 */
                function Recording(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Recording bucket.
                 * @member {string} bucket
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.bucket = "";

                /**
                 * Recording filename.
                 * @member {string} filename
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.filename = "";

                /**
                 * Recording storageUrl.
                 * @member {string} storageUrl
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.storageUrl = "";

                /**
                 * Recording status.
                 * @member {streem.api.Artifact.Recording.Status} status
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.status = 0;

                /**
                 * Recording requestedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.requestedAt = null;

                /**
                 * Recording startedTransferringAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedTransferringAt
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.startedTransferringAt = null;

                /**
                 * Recording erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.erroredAt = null;

                /**
                 * Recording fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.fulfilledAt = null;

                /**
                 * Recording createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.createdAt = null;

                /**
                 * Recording url.
                 * @member {string} url
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.url = "";

                /**
                 * Recording recordingSource.
                 * @member {streem.api.Artifact.Recording.Source} recordingSource
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.recordingSource = 0;

                /**
                 * Recording previewStorageUrl.
                 * @member {string} previewStorageUrl
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.previewStorageUrl = "";

                /**
                 * Recording previewUrl.
                 * @member {string} previewUrl
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.previewUrl = "";

                /**
                 * Recording duration.
                 * @member {google.protobuf.IDuration|null|undefined} duration
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.duration = null;

                /**
                 * Recording recordingStatus.
                 * @member {streem.api.Artifact.RecordingStatus} recordingStatus
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 */
                Recording.prototype.recordingStatus = 0;

                /**
                 * Creates a new Recording instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {streem.api.Artifact.IRecording=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Recording} Recording instance
                 */
                Recording.create = function create(properties) {
                    return new Recording(properties);
                };

                /**
                 * Encodes the specified Recording message. Does not implicitly {@link streem.api.Artifact.Recording.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {streem.api.Artifact.IRecording} m Recording message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Recording.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.bucket != null && Object.hasOwnProperty.call(m, "bucket"))
                        w.uint32(10).string(m.bucket);
                    if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                        w.uint32(18).string(m.filename);
                    if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                        w.uint32(26).string(m.storageUrl);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(48).int32(m.status);
                    if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                        $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(58).fork()).ldelim();
                    if (m.startedTransferringAt != null && Object.hasOwnProperty.call(m, "startedTransferringAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedTransferringAt, w.uint32(66).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(74).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(82).fork()).ldelim();
                    if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(90).fork()).ldelim();
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(98).string(m.url);
                    if (m.recordingSource != null && Object.hasOwnProperty.call(m, "recordingSource"))
                        w.uint32(120).int32(m.recordingSource);
                    if (m.previewStorageUrl != null && Object.hasOwnProperty.call(m, "previewStorageUrl"))
                        w.uint32(130).string(m.previewStorageUrl);
                    if (m.previewUrl != null && Object.hasOwnProperty.call(m, "previewUrl"))
                        w.uint32(138).string(m.previewUrl);
                    if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                        $root.google.protobuf.Duration.encode(m.duration, w.uint32(146).fork()).ldelim();
                    if (m.recordingStatus != null && Object.hasOwnProperty.call(m, "recordingStatus"))
                        w.uint32(152).int32(m.recordingStatus);
                    return w;
                };

                /**
                 * Decodes a Recording message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Recording} Recording
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Recording.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Recording();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.bucket = r.string();
                                break;
                            }
                        case 2: {
                                m.filename = r.string();
                                break;
                            }
                        case 3: {
                                m.storageUrl = r.string();
                                break;
                            }
                        case 6: {
                                m.status = r.int32();
                                break;
                            }
                        case 7: {
                                m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 8: {
                                m.startedTransferringAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 9: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 10: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 11: {
                                m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 12: {
                                m.url = r.string();
                                break;
                            }
                        case 15: {
                                m.recordingSource = r.int32();
                                break;
                            }
                        case 16: {
                                m.previewStorageUrl = r.string();
                                break;
                            }
                        case 17: {
                                m.previewUrl = r.string();
                                break;
                            }
                        case 18: {
                                m.duration = $root.google.protobuf.Duration.decode(r, r.uint32());
                                break;
                            }
                        case 19: {
                                m.recordingStatus = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Recording message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Recording} Recording
                 */
                Recording.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Recording)
                        return d;
                    var m = new $root.streem.api.Artifact.Recording();
                    if (d.bucket != null) {
                        m.bucket = String(d.bucket);
                    }
                    if (d.filename != null) {
                        m.filename = String(d.filename);
                    }
                    if (d.storageUrl != null) {
                        m.storageUrl = String(d.storageUrl);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_INVALID":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REQUESTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_TRANSFERRING":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_ERROR":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_FULFILLED":
                    case 4:
                        m.status = 4;
                        break;
                    }
                    if (d.requestedAt != null) {
                        if (typeof d.requestedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.requestedAt: object expected");
                        m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                    }
                    if (d.startedTransferringAt != null) {
                        if (typeof d.startedTransferringAt !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.startedTransferringAt: object expected");
                        m.startedTransferringAt = $root.google.protobuf.Timestamp.fromObject(d.startedTransferringAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.createdAt != null) {
                        if (typeof d.createdAt !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.createdAt: object expected");
                        m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    switch (d.recordingSource) {
                    default:
                        if (typeof d.recordingSource === "number") {
                            m.recordingSource = d.recordingSource;
                            break;
                        }
                        break;
                    case "SOURCE_INVALID":
                    case 0:
                        m.recordingSource = 0;
                        break;
                    case "SOURCE_TWILIO":
                    case 1:
                        m.recordingSource = 1;
                        break;
                    case "SOURCE_MUXER":
                    case 2:
                        m.recordingSource = 2;
                        break;
                    case "SOURCE_ON_DEVICE":
                    case 3:
                        m.recordingSource = 3;
                        break;
                    case "SOURCE_ARAAS":
                    case 4:
                        m.recordingSource = 4;
                        break;
                    case "SOURCE_CHIME":
                    case 5:
                        m.recordingSource = 5;
                        break;
                    }
                    if (d.previewStorageUrl != null) {
                        m.previewStorageUrl = String(d.previewStorageUrl);
                    }
                    if (d.previewUrl != null) {
                        m.previewUrl = String(d.previewUrl);
                    }
                    if (d.duration != null) {
                        if (typeof d.duration !== "object")
                            throw TypeError(".streem.api.Artifact.Recording.duration: object expected");
                        m.duration = $root.google.protobuf.Duration.fromObject(d.duration);
                    }
                    switch (d.recordingStatus) {
                    default:
                        if (typeof d.recordingStatus === "number") {
                            m.recordingStatus = d.recordingStatus;
                            break;
                        }
                        break;
                    case "RECORDING_STATUS_INVALID":
                    case 0:
                        m.recordingStatus = 0;
                        break;
                    case "RECORDING_STATUS_NONE":
                    case 1:
                        m.recordingStatus = 1;
                        break;
                    case "RECORDING_STATUS_PAUSED":
                    case 2:
                        m.recordingStatus = 2;
                        break;
                    case "RECORDING_STATUS_RESUMED":
                    case 3:
                        m.recordingStatus = 3;
                        break;
                    case "RECORDING_STATUS_TEMPORARY_FAILURE":
                    case 4:
                        m.recordingStatus = 4;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Recording message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {streem.api.Artifact.Recording} m Recording
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Recording.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.bucket = "";
                        d.filename = "";
                        d.storageUrl = "";
                        d.status = o.enums === String ? "STATUS_INVALID" : 0;
                        d.requestedAt = null;
                        d.startedTransferringAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.createdAt = null;
                        d.url = "";
                        d.recordingSource = o.enums === String ? "SOURCE_INVALID" : 0;
                        d.previewStorageUrl = "";
                        d.previewUrl = "";
                        d.duration = null;
                        d.recordingStatus = o.enums === String ? "RECORDING_STATUS_INVALID" : 0;
                    }
                    if (m.bucket != null && m.hasOwnProperty("bucket")) {
                        d.bucket = m.bucket;
                    }
                    if (m.filename != null && m.hasOwnProperty("filename")) {
                        d.filename = m.filename;
                    }
                    if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                        d.storageUrl = m.storageUrl;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Recording.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Recording.Status[m.status] : m.status;
                    }
                    if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                        d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                    }
                    if (m.startedTransferringAt != null && m.hasOwnProperty("startedTransferringAt")) {
                        d.startedTransferringAt = $root.google.protobuf.Timestamp.toObject(m.startedTransferringAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                        d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    if (m.recordingSource != null && m.hasOwnProperty("recordingSource")) {
                        d.recordingSource = o.enums === String ? $root.streem.api.Artifact.Recording.Source[m.recordingSource] === undefined ? m.recordingSource : $root.streem.api.Artifact.Recording.Source[m.recordingSource] : m.recordingSource;
                    }
                    if (m.previewStorageUrl != null && m.hasOwnProperty("previewStorageUrl")) {
                        d.previewStorageUrl = m.previewStorageUrl;
                    }
                    if (m.previewUrl != null && m.hasOwnProperty("previewUrl")) {
                        d.previewUrl = m.previewUrl;
                    }
                    if (m.duration != null && m.hasOwnProperty("duration")) {
                        d.duration = $root.google.protobuf.Duration.toObject(m.duration, o);
                    }
                    if (m.recordingStatus != null && m.hasOwnProperty("recordingStatus")) {
                        d.recordingStatus = o.enums === String ? $root.streem.api.Artifact.RecordingStatus[m.recordingStatus] === undefined ? m.recordingStatus : $root.streem.api.Artifact.RecordingStatus[m.recordingStatus] : m.recordingStatus;
                    }
                    return d;
                };

                /**
                 * Converts this Recording to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Recording
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Recording.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Recording
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Recording
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Recording.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Recording";
                };

                /**
                 * Status enum.
                 * @name streem.api.Artifact.Recording.Status
                 * @enum {number}
                 * @property {number} STATUS_INVALID=0 STATUS_INVALID value
                 * @property {number} STATUS_REQUESTED=1 STATUS_REQUESTED value
                 * @property {number} STATUS_TRANSFERRING=2 STATUS_TRANSFERRING value
                 * @property {number} STATUS_ERROR=3 STATUS_ERROR value
                 * @property {number} STATUS_FULFILLED=4 STATUS_FULFILLED value
                 */
                Recording.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_INVALID"] = 0;
                    values[valuesById[1] = "STATUS_REQUESTED"] = 1;
                    values[valuesById[2] = "STATUS_TRANSFERRING"] = 2;
                    values[valuesById[3] = "STATUS_ERROR"] = 3;
                    values[valuesById[4] = "STATUS_FULFILLED"] = 4;
                    return values;
                })();

                /**
                 * Source enum.
                 * @name streem.api.Artifact.Recording.Source
                 * @enum {number}
                 * @property {number} SOURCE_INVALID=0 SOURCE_INVALID value
                 * @property {number} SOURCE_TWILIO=1 SOURCE_TWILIO value
                 * @property {number} SOURCE_MUXER=2 SOURCE_MUXER value
                 * @property {number} SOURCE_ON_DEVICE=3 SOURCE_ON_DEVICE value
                 * @property {number} SOURCE_ARAAS=4 SOURCE_ARAAS value
                 * @property {number} SOURCE_CHIME=5 SOURCE_CHIME value
                 */
                Recording.Source = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SOURCE_INVALID"] = 0;
                    values[valuesById[1] = "SOURCE_TWILIO"] = 1;
                    values[valuesById[2] = "SOURCE_MUXER"] = 2;
                    values[valuesById[3] = "SOURCE_ON_DEVICE"] = 3;
                    values[valuesById[4] = "SOURCE_ARAAS"] = 4;
                    values[valuesById[5] = "SOURCE_CHIME"] = 5;
                    return values;
                })();

                return Recording;
            })();

            Artifact.Streemshot = (function() {

                /**
                 * Properties of a Streemshot.
                 * @memberof streem.api.Artifact
                 * @interface IStreemshot
                 * @property {streem.api.Artifact.Streemshot.Status|null} [status] Streemshot status
                 * @property {google.protobuf.ITimestamp|null} [requestedAt] Streemshot requestedAt
                 * @property {string|null} [requestedByUserSid] Streemshot requestedByUserSid
                 * @property {string|null} [requestedByDeviceSid] Streemshot requestedByDeviceSid
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] Streemshot fulfilledAt
                 * @property {string|null} [fulfilledByUserSid] Streemshot fulfilledByUserSid
                 * @property {string|null} [fulfilledByDeviceSid] Streemshot fulfilledByDeviceSid
                 * @property {streem.api.Artifact.Streemshot.ISource|null} [source] Streemshot source
                 * @property {Array.<streem.api.IDetection>|null} [detections] Streemshot detections
                 * @property {streem.api.Artifact.Streemshot.IRevision|null} [currentRevision] Streemshot currentRevision
                 * @property {number|null} [revisionNumber] Streemshot revisionNumber
                 * @property {streem.api.INote|null} [note] Streemshot note
                 * @property {streem.api.Artifact.Streemshot.IDepthMap|null} [depthMap] Streemshot depthMap
                 */

                /**
                 * Constructs a new Streemshot.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Streemshot.
                 * @implements IStreemshot
                 * @constructor
                 * @param {streem.api.Artifact.IStreemshot=} [p] Properties to set
                 */
                function Streemshot(p) {
                    this.detections = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Streemshot status.
                 * @member {streem.api.Artifact.Streemshot.Status} status
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.status = 0;

                /**
                 * Streemshot requestedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.requestedAt = null;

                /**
                 * Streemshot requestedByUserSid.
                 * @member {string} requestedByUserSid
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.requestedByUserSid = "";

                /**
                 * Streemshot requestedByDeviceSid.
                 * @member {string} requestedByDeviceSid
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.requestedByDeviceSid = "";

                /**
                 * Streemshot fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.fulfilledAt = null;

                /**
                 * Streemshot fulfilledByUserSid.
                 * @member {string} fulfilledByUserSid
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.fulfilledByUserSid = "";

                /**
                 * Streemshot fulfilledByDeviceSid.
                 * @member {string} fulfilledByDeviceSid
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.fulfilledByDeviceSid = "";

                /**
                 * Streemshot source.
                 * @member {streem.api.Artifact.Streemshot.ISource|null|undefined} source
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.source = null;

                /**
                 * Streemshot detections.
                 * @member {Array.<streem.api.IDetection>} detections
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.detections = $util.emptyArray;

                /**
                 * Streemshot currentRevision.
                 * @member {streem.api.Artifact.Streemshot.IRevision|null|undefined} currentRevision
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.currentRevision = null;

                /**
                 * Streemshot revisionNumber.
                 * @member {number} revisionNumber
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.revisionNumber = 0;

                /**
                 * Streemshot note.
                 * @member {streem.api.INote|null|undefined} note
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.note = null;

                /**
                 * Streemshot depthMap.
                 * @member {streem.api.Artifact.Streemshot.IDepthMap|null|undefined} depthMap
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 */
                Streemshot.prototype.depthMap = null;

                /**
                 * Creates a new Streemshot instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {streem.api.Artifact.IStreemshot=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Streemshot} Streemshot instance
                 */
                Streemshot.create = function create(properties) {
                    return new Streemshot(properties);
                };

                /**
                 * Encodes the specified Streemshot message. Does not implicitly {@link streem.api.Artifact.Streemshot.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {streem.api.Artifact.IStreemshot} m Streemshot message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Streemshot.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(8).int32(m.status);
                    if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                        $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(18).fork()).ldelim();
                    if (m.requestedByUserSid != null && Object.hasOwnProperty.call(m, "requestedByUserSid"))
                        w.uint32(26).string(m.requestedByUserSid);
                    if (m.requestedByDeviceSid != null && Object.hasOwnProperty.call(m, "requestedByDeviceSid"))
                        w.uint32(34).string(m.requestedByDeviceSid);
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(42).fork()).ldelim();
                    if (m.fulfilledByUserSid != null && Object.hasOwnProperty.call(m, "fulfilledByUserSid"))
                        w.uint32(50).string(m.fulfilledByUserSid);
                    if (m.fulfilledByDeviceSid != null && Object.hasOwnProperty.call(m, "fulfilledByDeviceSid"))
                        w.uint32(58).string(m.fulfilledByDeviceSid);
                    if (m.source != null && Object.hasOwnProperty.call(m, "source"))
                        $root.streem.api.Artifact.Streemshot.Source.encode(m.source, w.uint32(66).fork()).ldelim();
                    if (m.detections != null && m.detections.length) {
                        for (var i = 0; i < m.detections.length; ++i)
                            $root.streem.api.Detection.encode(m.detections[i], w.uint32(74).fork()).ldelim();
                    }
                    if (m.currentRevision != null && Object.hasOwnProperty.call(m, "currentRevision"))
                        $root.streem.api.Artifact.Streemshot.Revision.encode(m.currentRevision, w.uint32(82).fork()).ldelim();
                    if (m.revisionNumber != null && Object.hasOwnProperty.call(m, "revisionNumber"))
                        w.uint32(88).int32(m.revisionNumber);
                    if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                        $root.streem.api.Note.encode(m.note, w.uint32(98).fork()).ldelim();
                    if (m.depthMap != null && Object.hasOwnProperty.call(m, "depthMap"))
                        $root.streem.api.Artifact.Streemshot.DepthMap.encode(m.depthMap, w.uint32(106).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Streemshot message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Streemshot} Streemshot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Streemshot.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Streemshot();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.status = r.int32();
                                break;
                            }
                        case 2: {
                                m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.requestedByUserSid = r.string();
                                break;
                            }
                        case 4: {
                                m.requestedByDeviceSid = r.string();
                                break;
                            }
                        case 5: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 6: {
                                m.fulfilledByUserSid = r.string();
                                break;
                            }
                        case 7: {
                                m.fulfilledByDeviceSid = r.string();
                                break;
                            }
                        case 8: {
                                m.source = $root.streem.api.Artifact.Streemshot.Source.decode(r, r.uint32());
                                break;
                            }
                        case 9: {
                                if (!(m.detections && m.detections.length))
                                    m.detections = [];
                                m.detections.push($root.streem.api.Detection.decode(r, r.uint32()));
                                break;
                            }
                        case 10: {
                                m.currentRevision = $root.streem.api.Artifact.Streemshot.Revision.decode(r, r.uint32());
                                break;
                            }
                        case 11: {
                                m.revisionNumber = r.int32();
                                break;
                            }
                        case 12: {
                                m.note = $root.streem.api.Note.decode(r, r.uint32());
                                break;
                            }
                        case 13: {
                                m.depthMap = $root.streem.api.Artifact.Streemshot.DepthMap.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Streemshot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Streemshot} Streemshot
                 */
                Streemshot.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Streemshot)
                        return d;
                    var m = new $root.streem.api.Artifact.Streemshot();
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_INVALID":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REQUESTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_FULFILLED":
                    case 2:
                        m.status = 2;
                        break;
                    }
                    if (d.requestedAt != null) {
                        if (typeof d.requestedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.requestedAt: object expected");
                        m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                    }
                    if (d.requestedByUserSid != null) {
                        m.requestedByUserSid = String(d.requestedByUserSid);
                    }
                    if (d.requestedByDeviceSid != null) {
                        m.requestedByDeviceSid = String(d.requestedByDeviceSid);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.fulfilledByUserSid != null) {
                        m.fulfilledByUserSid = String(d.fulfilledByUserSid);
                    }
                    if (d.fulfilledByDeviceSid != null) {
                        m.fulfilledByDeviceSid = String(d.fulfilledByDeviceSid);
                    }
                    if (d.source != null) {
                        if (typeof d.source !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.source: object expected");
                        m.source = $root.streem.api.Artifact.Streemshot.Source.fromObject(d.source);
                    }
                    if (d.detections) {
                        if (!Array.isArray(d.detections))
                            throw TypeError(".streem.api.Artifact.Streemshot.detections: array expected");
                        m.detections = [];
                        for (var i = 0; i < d.detections.length; ++i) {
                            if (typeof d.detections[i] !== "object")
                                throw TypeError(".streem.api.Artifact.Streemshot.detections: object expected");
                            m.detections[i] = $root.streem.api.Detection.fromObject(d.detections[i]);
                        }
                    }
                    if (d.currentRevision != null) {
                        if (typeof d.currentRevision !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.currentRevision: object expected");
                        m.currentRevision = $root.streem.api.Artifact.Streemshot.Revision.fromObject(d.currentRevision);
                    }
                    if (d.revisionNumber != null) {
                        m.revisionNumber = d.revisionNumber | 0;
                    }
                    if (d.note != null) {
                        if (typeof d.note !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.note: object expected");
                        m.note = $root.streem.api.Note.fromObject(d.note);
                    }
                    if (d.depthMap != null) {
                        if (typeof d.depthMap !== "object")
                            throw TypeError(".streem.api.Artifact.Streemshot.depthMap: object expected");
                        m.depthMap = $root.streem.api.Artifact.Streemshot.DepthMap.fromObject(d.depthMap);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Streemshot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {streem.api.Artifact.Streemshot} m Streemshot
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Streemshot.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.detections = [];
                    }
                    if (o.defaults) {
                        d.status = o.enums === String ? "STATUS_INVALID" : 0;
                        d.requestedAt = null;
                        d.requestedByUserSid = "";
                        d.requestedByDeviceSid = "";
                        d.fulfilledAt = null;
                        d.fulfilledByUserSid = "";
                        d.fulfilledByDeviceSid = "";
                        d.source = null;
                        d.currentRevision = null;
                        d.revisionNumber = 0;
                        d.note = null;
                        d.depthMap = null;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Streemshot.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Streemshot.Status[m.status] : m.status;
                    }
                    if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                        d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                    }
                    if (m.requestedByUserSid != null && m.hasOwnProperty("requestedByUserSid")) {
                        d.requestedByUserSid = m.requestedByUserSid;
                    }
                    if (m.requestedByDeviceSid != null && m.hasOwnProperty("requestedByDeviceSid")) {
                        d.requestedByDeviceSid = m.requestedByDeviceSid;
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.fulfilledByUserSid != null && m.hasOwnProperty("fulfilledByUserSid")) {
                        d.fulfilledByUserSid = m.fulfilledByUserSid;
                    }
                    if (m.fulfilledByDeviceSid != null && m.hasOwnProperty("fulfilledByDeviceSid")) {
                        d.fulfilledByDeviceSid = m.fulfilledByDeviceSid;
                    }
                    if (m.source != null && m.hasOwnProperty("source")) {
                        d.source = $root.streem.api.Artifact.Streemshot.Source.toObject(m.source, o);
                    }
                    if (m.detections && m.detections.length) {
                        d.detections = [];
                        for (var j = 0; j < m.detections.length; ++j) {
                            d.detections[j] = $root.streem.api.Detection.toObject(m.detections[j], o);
                        }
                    }
                    if (m.currentRevision != null && m.hasOwnProperty("currentRevision")) {
                        d.currentRevision = $root.streem.api.Artifact.Streemshot.Revision.toObject(m.currentRevision, o);
                    }
                    if (m.revisionNumber != null && m.hasOwnProperty("revisionNumber")) {
                        d.revisionNumber = m.revisionNumber;
                    }
                    if (m.note != null && m.hasOwnProperty("note")) {
                        d.note = $root.streem.api.Note.toObject(m.note, o);
                    }
                    if (m.depthMap != null && m.hasOwnProperty("depthMap")) {
                        d.depthMap = $root.streem.api.Artifact.Streemshot.DepthMap.toObject(m.depthMap, o);
                    }
                    return d;
                };

                /**
                 * Converts this Streemshot to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Streemshot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Streemshot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Streemshot
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Streemshot
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Streemshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Streemshot";
                };

                /**
                 * Status enum.
                 * @name streem.api.Artifact.Streemshot.Status
                 * @enum {number}
                 * @property {number} STATUS_INVALID=0 STATUS_INVALID value
                 * @property {number} STATUS_REQUESTED=1 STATUS_REQUESTED value
                 * @property {number} STATUS_FULFILLED=2 STATUS_FULFILLED value
                 */
                Streemshot.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_INVALID"] = 0;
                    values[valuesById[1] = "STATUS_REQUESTED"] = 1;
                    values[valuesById[2] = "STATUS_FULFILLED"] = 2;
                    return values;
                })();

                Streemshot.Source = (function() {

                    /**
                     * Properties of a Source.
                     * @memberof streem.api.Artifact.Streemshot
                     * @interface ISource
                     * @property {string|null} [url] Source url
                     * @property {streem.api.IPose|null} [pose] Source pose
                     * @property {Array.<streem.api.IPoint>|null} [featurePoints] Source featurePoints
                     * @property {ICameraIntrinsics|null} [cameraIntrinsics] Source cameraIntrinsics
                     */

                    /**
                     * Constructs a new Source.
                     * @memberof streem.api.Artifact.Streemshot
                     * @classdesc Represents a Source.
                     * @implements ISource
                     * @constructor
                     * @param {streem.api.Artifact.Streemshot.ISource=} [p] Properties to set
                     */
                    function Source(p) {
                        this.featurePoints = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Source url.
                     * @member {string} url
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @instance
                     */
                    Source.prototype.url = "";

                    /**
                     * Source pose.
                     * @member {streem.api.IPose|null|undefined} pose
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @instance
                     */
                    Source.prototype.pose = null;

                    /**
                     * Source featurePoints.
                     * @member {Array.<streem.api.IPoint>} featurePoints
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @instance
                     */
                    Source.prototype.featurePoints = $util.emptyArray;

                    /**
                     * Source cameraIntrinsics.
                     * @member {ICameraIntrinsics|null|undefined} cameraIntrinsics
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @instance
                     */
                    Source.prototype.cameraIntrinsics = null;

                    /**
                     * Creates a new Source instance using the specified properties.
                     * @function create
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {streem.api.Artifact.Streemshot.ISource=} [properties] Properties to set
                     * @returns {streem.api.Artifact.Streemshot.Source} Source instance
                     */
                    Source.create = function create(properties) {
                        return new Source(properties);
                    };

                    /**
                     * Encodes the specified Source message. Does not implicitly {@link streem.api.Artifact.Streemshot.Source.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {streem.api.Artifact.Streemshot.ISource} m Source message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Source.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                            w.uint32(10).string(m.url);
                        if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                            $root.streem.api.Pose.encode(m.pose, w.uint32(18).fork()).ldelim();
                        if (m.featurePoints != null && m.featurePoints.length) {
                            for (var i = 0; i < m.featurePoints.length; ++i)
                                $root.streem.api.Point.encode(m.featurePoints[i], w.uint32(26).fork()).ldelim();
                        }
                        if (m.cameraIntrinsics != null && Object.hasOwnProperty.call(m, "cameraIntrinsics"))
                            $root.CameraIntrinsics.encode(m.cameraIntrinsics, w.uint32(34).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Source message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.Artifact.Streemshot.Source} Source
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Source.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Streemshot.Source();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.url = r.string();
                                    break;
                                }
                            case 2: {
                                    m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                                    break;
                                }
                            case 3: {
                                    if (!(m.featurePoints && m.featurePoints.length))
                                        m.featurePoints = [];
                                    m.featurePoints.push($root.streem.api.Point.decode(r, r.uint32()));
                                    break;
                                }
                            case 4: {
                                    m.cameraIntrinsics = $root.CameraIntrinsics.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Source message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.Artifact.Streemshot.Source} Source
                     */
                    Source.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.Artifact.Streemshot.Source)
                            return d;
                        var m = new $root.streem.api.Artifact.Streemshot.Source();
                        if (d.url != null) {
                            m.url = String(d.url);
                        }
                        if (d.pose != null) {
                            if (typeof d.pose !== "object")
                                throw TypeError(".streem.api.Artifact.Streemshot.Source.pose: object expected");
                            m.pose = $root.streem.api.Pose.fromObject(d.pose);
                        }
                        if (d.featurePoints) {
                            if (!Array.isArray(d.featurePoints))
                                throw TypeError(".streem.api.Artifact.Streemshot.Source.featurePoints: array expected");
                            m.featurePoints = [];
                            for (var i = 0; i < d.featurePoints.length; ++i) {
                                if (typeof d.featurePoints[i] !== "object")
                                    throw TypeError(".streem.api.Artifact.Streemshot.Source.featurePoints: object expected");
                                m.featurePoints[i] = $root.streem.api.Point.fromObject(d.featurePoints[i]);
                            }
                        }
                        if (d.cameraIntrinsics != null) {
                            if (typeof d.cameraIntrinsics !== "object")
                                throw TypeError(".streem.api.Artifact.Streemshot.Source.cameraIntrinsics: object expected");
                            m.cameraIntrinsics = $root.CameraIntrinsics.fromObject(d.cameraIntrinsics);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Source message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {streem.api.Artifact.Streemshot.Source} m Source
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Source.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.featurePoints = [];
                        }
                        if (o.defaults) {
                            d.url = "";
                            d.pose = null;
                            d.cameraIntrinsics = null;
                        }
                        if (m.url != null && m.hasOwnProperty("url")) {
                            d.url = m.url;
                        }
                        if (m.pose != null && m.hasOwnProperty("pose")) {
                            d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                        }
                        if (m.featurePoints && m.featurePoints.length) {
                            d.featurePoints = [];
                            for (var j = 0; j < m.featurePoints.length; ++j) {
                                d.featurePoints[j] = $root.streem.api.Point.toObject(m.featurePoints[j], o);
                            }
                        }
                        if (m.cameraIntrinsics != null && m.hasOwnProperty("cameraIntrinsics")) {
                            d.cameraIntrinsics = $root.CameraIntrinsics.toObject(m.cameraIntrinsics, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Source to JSON.
                     * @function toJSON
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Source.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Source
                     * @function getTypeUrl
                     * @memberof streem.api.Artifact.Streemshot.Source
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Source.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.Artifact.Streemshot.Source";
                    };

                    return Source;
                })();

                Streemshot.Revision = (function() {

                    /**
                     * Properties of a Revision.
                     * @memberof streem.api.Artifact.Streemshot
                     * @interface IRevision
                     * @property {number|null} [revisionNumber] Revision revisionNumber
                     * @property {string|null} [streemshotSid] Revision streemshotSid
                     * @property {string|null} [storageUrl] Revision storageUrl
                     * @property {Array.<streem.api.IArElement>|null} [arElements] Revision arElements
                     * @property {string|null} [url] Revision url
                     * @property {boolean|null} [edited] Revision edited
                     */

                    /**
                     * Constructs a new Revision.
                     * @memberof streem.api.Artifact.Streemshot
                     * @classdesc Represents a Revision.
                     * @implements IRevision
                     * @constructor
                     * @param {streem.api.Artifact.Streemshot.IRevision=} [p] Properties to set
                     */
                    function Revision(p) {
                        this.arElements = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Revision revisionNumber.
                     * @member {number} revisionNumber
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.revisionNumber = 0;

                    /**
                     * Revision streemshotSid.
                     * @member {string} streemshotSid
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.streemshotSid = "";

                    /**
                     * Revision storageUrl.
                     * @member {string} storageUrl
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.storageUrl = "";

                    /**
                     * Revision arElements.
                     * @member {Array.<streem.api.IArElement>} arElements
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.arElements = $util.emptyArray;

                    /**
                     * Revision url.
                     * @member {string} url
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.url = "";

                    /**
                     * Revision edited.
                     * @member {boolean} edited
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     */
                    Revision.prototype.edited = false;

                    /**
                     * Creates a new Revision instance using the specified properties.
                     * @function create
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {streem.api.Artifact.Streemshot.IRevision=} [properties] Properties to set
                     * @returns {streem.api.Artifact.Streemshot.Revision} Revision instance
                     */
                    Revision.create = function create(properties) {
                        return new Revision(properties);
                    };

                    /**
                     * Encodes the specified Revision message. Does not implicitly {@link streem.api.Artifact.Streemshot.Revision.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {streem.api.Artifact.Streemshot.IRevision} m Revision message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Revision.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.revisionNumber != null && Object.hasOwnProperty.call(m, "revisionNumber"))
                            w.uint32(8).int32(m.revisionNumber);
                        if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                            w.uint32(18).string(m.streemshotSid);
                        if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                            w.uint32(26).string(m.storageUrl);
                        if (m.arElements != null && m.arElements.length) {
                            for (var i = 0; i < m.arElements.length; ++i)
                                $root.streem.api.ArElement.encode(m.arElements[i], w.uint32(34).fork()).ldelim();
                        }
                        if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                            w.uint32(42).string(m.url);
                        if (m.edited != null && Object.hasOwnProperty.call(m, "edited"))
                            w.uint32(48).bool(m.edited);
                        return w;
                    };

                    /**
                     * Decodes a Revision message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.Artifact.Streemshot.Revision} Revision
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Revision.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Streemshot.Revision();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.revisionNumber = r.int32();
                                    break;
                                }
                            case 2: {
                                    m.streemshotSid = r.string();
                                    break;
                                }
                            case 3: {
                                    m.storageUrl = r.string();
                                    break;
                                }
                            case 4: {
                                    if (!(m.arElements && m.arElements.length))
                                        m.arElements = [];
                                    m.arElements.push($root.streem.api.ArElement.decode(r, r.uint32()));
                                    break;
                                }
                            case 5: {
                                    m.url = r.string();
                                    break;
                                }
                            case 6: {
                                    m.edited = r.bool();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Revision message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.Artifact.Streemshot.Revision} Revision
                     */
                    Revision.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.Artifact.Streemshot.Revision)
                            return d;
                        var m = new $root.streem.api.Artifact.Streemshot.Revision();
                        if (d.revisionNumber != null) {
                            m.revisionNumber = d.revisionNumber | 0;
                        }
                        if (d.streemshotSid != null) {
                            m.streemshotSid = String(d.streemshotSid);
                        }
                        if (d.storageUrl != null) {
                            m.storageUrl = String(d.storageUrl);
                        }
                        if (d.arElements) {
                            if (!Array.isArray(d.arElements))
                                throw TypeError(".streem.api.Artifact.Streemshot.Revision.arElements: array expected");
                            m.arElements = [];
                            for (var i = 0; i < d.arElements.length; ++i) {
                                if (typeof d.arElements[i] !== "object")
                                    throw TypeError(".streem.api.Artifact.Streemshot.Revision.arElements: object expected");
                                m.arElements[i] = $root.streem.api.ArElement.fromObject(d.arElements[i]);
                            }
                        }
                        if (d.url != null) {
                            m.url = String(d.url);
                        }
                        if (d.edited != null) {
                            m.edited = Boolean(d.edited);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Revision message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {streem.api.Artifact.Streemshot.Revision} m Revision
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Revision.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.arElements = [];
                        }
                        if (o.defaults) {
                            d.revisionNumber = 0;
                            d.streemshotSid = "";
                            d.storageUrl = "";
                            d.url = "";
                            d.edited = false;
                        }
                        if (m.revisionNumber != null && m.hasOwnProperty("revisionNumber")) {
                            d.revisionNumber = m.revisionNumber;
                        }
                        if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                            d.streemshotSid = m.streemshotSid;
                        }
                        if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                            d.storageUrl = m.storageUrl;
                        }
                        if (m.arElements && m.arElements.length) {
                            d.arElements = [];
                            for (var j = 0; j < m.arElements.length; ++j) {
                                d.arElements[j] = $root.streem.api.ArElement.toObject(m.arElements[j], o);
                            }
                        }
                        if (m.url != null && m.hasOwnProperty("url")) {
                            d.url = m.url;
                        }
                        if (m.edited != null && m.hasOwnProperty("edited")) {
                            d.edited = m.edited;
                        }
                        return d;
                    };

                    /**
                     * Converts this Revision to JSON.
                     * @function toJSON
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Revision.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Revision
                     * @function getTypeUrl
                     * @memberof streem.api.Artifact.Streemshot.Revision
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Revision.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.Artifact.Streemshot.Revision";
                    };

                    return Revision;
                })();

                Streemshot.DepthMap = (function() {

                    /**
                     * Properties of a DepthMap.
                     * @memberof streem.api.Artifact.Streemshot
                     * @interface IDepthMap
                     * @property {string|null} [streemshotSid] DepthMap streemshotSid
                     * @property {string|null} [storageUrl] DepthMap storageUrl
                     * @property {string|null} [url] DepthMap url
                     */

                    /**
                     * Constructs a new DepthMap.
                     * @memberof streem.api.Artifact.Streemshot
                     * @classdesc Represents a DepthMap.
                     * @implements IDepthMap
                     * @constructor
                     * @param {streem.api.Artifact.Streemshot.IDepthMap=} [p] Properties to set
                     */
                    function DepthMap(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * DepthMap streemshotSid.
                     * @member {string} streemshotSid
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @instance
                     */
                    DepthMap.prototype.streemshotSid = "";

                    /**
                     * DepthMap storageUrl.
                     * @member {string} storageUrl
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @instance
                     */
                    DepthMap.prototype.storageUrl = "";

                    /**
                     * DepthMap url.
                     * @member {string} url
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @instance
                     */
                    DepthMap.prototype.url = "";

                    /**
                     * Creates a new DepthMap instance using the specified properties.
                     * @function create
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {streem.api.Artifact.Streemshot.IDepthMap=} [properties] Properties to set
                     * @returns {streem.api.Artifact.Streemshot.DepthMap} DepthMap instance
                     */
                    DepthMap.create = function create(properties) {
                        return new DepthMap(properties);
                    };

                    /**
                     * Encodes the specified DepthMap message. Does not implicitly {@link streem.api.Artifact.Streemshot.DepthMap.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {streem.api.Artifact.Streemshot.IDepthMap} m DepthMap message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DepthMap.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                            w.uint32(10).string(m.streemshotSid);
                        if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                            w.uint32(18).string(m.storageUrl);
                        if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                            w.uint32(26).string(m.url);
                        return w;
                    };

                    /**
                     * Decodes a DepthMap message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.Artifact.Streemshot.DepthMap} DepthMap
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DepthMap.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Streemshot.DepthMap();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.streemshotSid = r.string();
                                    break;
                                }
                            case 2: {
                                    m.storageUrl = r.string();
                                    break;
                                }
                            case 3: {
                                    m.url = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a DepthMap message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.Artifact.Streemshot.DepthMap} DepthMap
                     */
                    DepthMap.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.Artifact.Streemshot.DepthMap)
                            return d;
                        var m = new $root.streem.api.Artifact.Streemshot.DepthMap();
                        if (d.streemshotSid != null) {
                            m.streemshotSid = String(d.streemshotSid);
                        }
                        if (d.storageUrl != null) {
                            m.storageUrl = String(d.storageUrl);
                        }
                        if (d.url != null) {
                            m.url = String(d.url);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a DepthMap message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {streem.api.Artifact.Streemshot.DepthMap} m DepthMap
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DepthMap.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.streemshotSid = "";
                            d.storageUrl = "";
                            d.url = "";
                        }
                        if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                            d.streemshotSid = m.streemshotSid;
                        }
                        if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                            d.storageUrl = m.storageUrl;
                        }
                        if (m.url != null && m.hasOwnProperty("url")) {
                            d.url = m.url;
                        }
                        return d;
                    };

                    /**
                     * Converts this DepthMap to JSON.
                     * @function toJSON
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DepthMap.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for DepthMap
                     * @function getTypeUrl
                     * @memberof streem.api.Artifact.Streemshot.DepthMap
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DepthMap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.Artifact.Streemshot.DepthMap";
                    };

                    return DepthMap;
                })();

                return Streemshot;
            })();

            Artifact.Transcription = (function() {

                /**
                 * Properties of a Transcription.
                 * @memberof streem.api.Artifact
                 * @interface ITranscription
                 * @property {streem.api.Artifact.Status|null} [status] Transcription status
                 * @property {string|null} [storageUrl] Transcription storageUrl
                 * @property {google.protobuf.ITimestamp|null} [startedAt] Transcription startedAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] Transcription erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] Transcription fulfilledAt
                 * @property {streem.api.Artifact.Transcription.Type|null} [type] Transcription type
                 * @property {string|null} [url] Transcription url
                 */

                /**
                 * Constructs a new Transcription.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Transcription.
                 * @implements ITranscription
                 * @constructor
                 * @param {streem.api.Artifact.ITranscription=} [p] Properties to set
                 */
                function Transcription(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Transcription status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.status = 0;

                /**
                 * Transcription storageUrl.
                 * @member {string} storageUrl
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.storageUrl = "";

                /**
                 * Transcription startedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.startedAt = null;

                /**
                 * Transcription erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.erroredAt = null;

                /**
                 * Transcription fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.fulfilledAt = null;

                /**
                 * Transcription type.
                 * @member {streem.api.Artifact.Transcription.Type} type
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.type = 0;

                /**
                 * Transcription url.
                 * @member {string} url
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 */
                Transcription.prototype.url = "";

                /**
                 * Creates a new Transcription instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {streem.api.Artifact.ITranscription=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Transcription} Transcription instance
                 */
                Transcription.create = function create(properties) {
                    return new Transcription(properties);
                };

                /**
                 * Encodes the specified Transcription message. Does not implicitly {@link streem.api.Artifact.Transcription.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {streem.api.Artifact.ITranscription} m Transcription message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Transcription.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(8).int32(m.status);
                    if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                        w.uint32(18).string(m.storageUrl);
                    if (m.startedAt != null && Object.hasOwnProperty.call(m, "startedAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedAt, w.uint32(26).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(34).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(42).fork()).ldelim();
                    if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                        w.uint32(48).int32(m.type);
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(58).string(m.url);
                    return w;
                };

                /**
                 * Decodes a Transcription message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Transcription} Transcription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Transcription.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Transcription();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.status = r.int32();
                                break;
                            }
                        case 2: {
                                m.storageUrl = r.string();
                                break;
                            }
                        case 3: {
                                m.startedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 5: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 6: {
                                m.type = r.int32();
                                break;
                            }
                        case 7: {
                                m.url = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Transcription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Transcription} Transcription
                 */
                Transcription.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Transcription)
                        return d;
                    var m = new $root.streem.api.Artifact.Transcription();
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.storageUrl != null) {
                        m.storageUrl = String(d.storageUrl);
                    }
                    if (d.startedAt != null) {
                        if (typeof d.startedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Transcription.startedAt: object expected");
                        m.startedAt = $root.google.protobuf.Timestamp.fromObject(d.startedAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.Transcription.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.Transcription.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    switch (d.type) {
                    default:
                        if (typeof d.type === "number") {
                            m.type = d.type;
                            break;
                        }
                        break;
                    case "VTT":
                    case 0:
                        m.type = 0;
                        break;
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Transcription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {streem.api.Artifact.Transcription} m Transcription
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Transcription.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                        d.storageUrl = "";
                        d.startedAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.type = o.enums === String ? "VTT" : 0;
                        d.url = "";
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                        d.storageUrl = m.storageUrl;
                    }
                    if (m.startedAt != null && m.hasOwnProperty("startedAt")) {
                        d.startedAt = $root.google.protobuf.Timestamp.toObject(m.startedAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.type != null && m.hasOwnProperty("type")) {
                        d.type = o.enums === String ? $root.streem.api.Artifact.Transcription.Type[m.type] === undefined ? m.type : $root.streem.api.Artifact.Transcription.Type[m.type] : m.type;
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    return d;
                };

                /**
                 * Converts this Transcription to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Transcription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Transcription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Transcription
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Transcription
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Transcription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Transcription";
                };

                /**
                 * Type enum.
                 * @name streem.api.Artifact.Transcription.Type
                 * @enum {number}
                 * @property {number} VTT=0 VTT value
                 */
                Transcription.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "VTT"] = 0;
                    return values;
                })();

                return Transcription;
            })();

            Artifact.Sceneshot = (function() {

                /**
                 * Properties of a Sceneshot.
                 * @memberof streem.api.Artifact
                 * @interface ISceneshot
                 * @property {string|null} [meshSid] Sceneshot meshSid
                 * @property {string|null} [previewStorageUrl] Sceneshot previewStorageUrl
                 * @property {string|null} [name] Sceneshot name
                 * @property {streem.api.IPose|null} [pose] Sceneshot pose
                 * @property {Array.<streem.api.IArElement>|null} [arElements] Sceneshot arElements
                 * @property {streem.api.Artifact.Status|null} [status] Sceneshot status
                 * @property {google.protobuf.ITimestamp|null} [startedAt] Sceneshot startedAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] Sceneshot erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] Sceneshot fulfilledAt
                 * @property {google.protobuf.ITimestamp|null} [capturedAt] Sceneshot capturedAt
                 */

                /**
                 * Constructs a new Sceneshot.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Sceneshot.
                 * @implements ISceneshot
                 * @constructor
                 * @param {streem.api.Artifact.ISceneshot=} [p] Properties to set
                 */
                function Sceneshot(p) {
                    this.arElements = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Sceneshot meshSid.
                 * @member {string} meshSid
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.meshSid = "";

                /**
                 * Sceneshot previewStorageUrl.
                 * @member {string} previewStorageUrl
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.previewStorageUrl = "";

                /**
                 * Sceneshot name.
                 * @member {string} name
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.name = "";

                /**
                 * Sceneshot pose.
                 * @member {streem.api.IPose|null|undefined} pose
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.pose = null;

                /**
                 * Sceneshot arElements.
                 * @member {Array.<streem.api.IArElement>} arElements
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.arElements = $util.emptyArray;

                /**
                 * Sceneshot status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.status = 0;

                /**
                 * Sceneshot startedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.startedAt = null;

                /**
                 * Sceneshot erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.erroredAt = null;

                /**
                 * Sceneshot fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.fulfilledAt = null;

                /**
                 * Sceneshot capturedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} capturedAt
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 */
                Sceneshot.prototype.capturedAt = null;

                /**
                 * Creates a new Sceneshot instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {streem.api.Artifact.ISceneshot=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Sceneshot} Sceneshot instance
                 */
                Sceneshot.create = function create(properties) {
                    return new Sceneshot(properties);
                };

                /**
                 * Encodes the specified Sceneshot message. Does not implicitly {@link streem.api.Artifact.Sceneshot.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {streem.api.Artifact.ISceneshot} m Sceneshot message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Sceneshot.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                        w.uint32(10).string(m.meshSid);
                    if (m.previewStorageUrl != null && Object.hasOwnProperty.call(m, "previewStorageUrl"))
                        w.uint32(18).string(m.previewStorageUrl);
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(26).string(m.name);
                    if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                        $root.streem.api.Pose.encode(m.pose, w.uint32(34).fork()).ldelim();
                    if (m.arElements != null && m.arElements.length) {
                        for (var i = 0; i < m.arElements.length; ++i)
                            $root.streem.api.ArElement.encode(m.arElements[i], w.uint32(42).fork()).ldelim();
                    }
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(48).int32(m.status);
                    if (m.startedAt != null && Object.hasOwnProperty.call(m, "startedAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedAt, w.uint32(58).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(66).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(74).fork()).ldelim();
                    if (m.capturedAt != null && Object.hasOwnProperty.call(m, "capturedAt"))
                        $root.google.protobuf.Timestamp.encode(m.capturedAt, w.uint32(82).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Sceneshot message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Sceneshot} Sceneshot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Sceneshot.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Sceneshot();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.meshSid = r.string();
                                break;
                            }
                        case 2: {
                                m.previewStorageUrl = r.string();
                                break;
                            }
                        case 3: {
                                m.name = r.string();
                                break;
                            }
                        case 4: {
                                m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                                break;
                            }
                        case 5: {
                                if (!(m.arElements && m.arElements.length))
                                    m.arElements = [];
                                m.arElements.push($root.streem.api.ArElement.decode(r, r.uint32()));
                                break;
                            }
                        case 6: {
                                m.status = r.int32();
                                break;
                            }
                        case 7: {
                                m.startedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 8: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 9: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 10: {
                                m.capturedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Sceneshot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Sceneshot} Sceneshot
                 */
                Sceneshot.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Sceneshot)
                        return d;
                    var m = new $root.streem.api.Artifact.Sceneshot();
                    if (d.meshSid != null) {
                        m.meshSid = String(d.meshSid);
                    }
                    if (d.previewStorageUrl != null) {
                        m.previewStorageUrl = String(d.previewStorageUrl);
                    }
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    if (d.pose != null) {
                        if (typeof d.pose !== "object")
                            throw TypeError(".streem.api.Artifact.Sceneshot.pose: object expected");
                        m.pose = $root.streem.api.Pose.fromObject(d.pose);
                    }
                    if (d.arElements) {
                        if (!Array.isArray(d.arElements))
                            throw TypeError(".streem.api.Artifact.Sceneshot.arElements: array expected");
                        m.arElements = [];
                        for (var i = 0; i < d.arElements.length; ++i) {
                            if (typeof d.arElements[i] !== "object")
                                throw TypeError(".streem.api.Artifact.Sceneshot.arElements: object expected");
                            m.arElements[i] = $root.streem.api.ArElement.fromObject(d.arElements[i]);
                        }
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.startedAt != null) {
                        if (typeof d.startedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Sceneshot.startedAt: object expected");
                        m.startedAt = $root.google.protobuf.Timestamp.fromObject(d.startedAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.Sceneshot.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.Sceneshot.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.capturedAt != null) {
                        if (typeof d.capturedAt !== "object")
                            throw TypeError(".streem.api.Artifact.Sceneshot.capturedAt: object expected");
                        m.capturedAt = $root.google.protobuf.Timestamp.fromObject(d.capturedAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Sceneshot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {streem.api.Artifact.Sceneshot} m Sceneshot
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Sceneshot.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.arElements = [];
                    }
                    if (o.defaults) {
                        d.meshSid = "";
                        d.previewStorageUrl = "";
                        d.name = "";
                        d.pose = null;
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                        d.startedAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.capturedAt = null;
                    }
                    if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                        d.meshSid = m.meshSid;
                    }
                    if (m.previewStorageUrl != null && m.hasOwnProperty("previewStorageUrl")) {
                        d.previewStorageUrl = m.previewStorageUrl;
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    if (m.pose != null && m.hasOwnProperty("pose")) {
                        d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                    }
                    if (m.arElements && m.arElements.length) {
                        d.arElements = [];
                        for (var j = 0; j < m.arElements.length; ++j) {
                            d.arElements[j] = $root.streem.api.ArElement.toObject(m.arElements[j], o);
                        }
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    if (m.startedAt != null && m.hasOwnProperty("startedAt")) {
                        d.startedAt = $root.google.protobuf.Timestamp.toObject(m.startedAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.capturedAt != null && m.hasOwnProperty("capturedAt")) {
                        d.capturedAt = $root.google.protobuf.Timestamp.toObject(m.capturedAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this Sceneshot to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Sceneshot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Sceneshot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Sceneshot
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Sceneshot
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Sceneshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Sceneshot";
                };

                return Sceneshot;
            })();

            Artifact.LayoutEstimation = (function() {

                /**
                 * Properties of a LayoutEstimation.
                 * @memberof streem.api.Artifact
                 * @interface ILayoutEstimation
                 * @property {string|null} [meshSid] LayoutEstimation meshSid
                 * @property {string|null} [storageUrl] LayoutEstimation storageUrl
                 * @property {streem.api.Artifact.Status|null} [status] LayoutEstimation status
                 * @property {google.protobuf.ITimestamp|null} [startedAt] LayoutEstimation startedAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] LayoutEstimation erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] LayoutEstimation fulfilledAt
                 * @property {string|null} [url] LayoutEstimation url
                 * @property {google.protobuf.ITimestamp|null} [capturedAt] LayoutEstimation capturedAt
                 */

                /**
                 * Constructs a new LayoutEstimation.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a LayoutEstimation.
                 * @implements ILayoutEstimation
                 * @constructor
                 * @param {streem.api.Artifact.ILayoutEstimation=} [p] Properties to set
                 */
                function LayoutEstimation(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LayoutEstimation meshSid.
                 * @member {string} meshSid
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.meshSid = "";

                /**
                 * LayoutEstimation storageUrl.
                 * @member {string} storageUrl
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.storageUrl = "";

                /**
                 * LayoutEstimation status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.status = 0;

                /**
                 * LayoutEstimation startedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.startedAt = null;

                /**
                 * LayoutEstimation erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.erroredAt = null;

                /**
                 * LayoutEstimation fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.fulfilledAt = null;

                /**
                 * LayoutEstimation url.
                 * @member {string} url
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.url = "";

                /**
                 * LayoutEstimation capturedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} capturedAt
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 */
                LayoutEstimation.prototype.capturedAt = null;

                /**
                 * Creates a new LayoutEstimation instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {streem.api.Artifact.ILayoutEstimation=} [properties] Properties to set
                 * @returns {streem.api.Artifact.LayoutEstimation} LayoutEstimation instance
                 */
                LayoutEstimation.create = function create(properties) {
                    return new LayoutEstimation(properties);
                };

                /**
                 * Encodes the specified LayoutEstimation message. Does not implicitly {@link streem.api.Artifact.LayoutEstimation.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {streem.api.Artifact.ILayoutEstimation} m LayoutEstimation message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LayoutEstimation.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                        w.uint32(10).string(m.meshSid);
                    if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                        w.uint32(18).string(m.storageUrl);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(24).int32(m.status);
                    if (m.startedAt != null && Object.hasOwnProperty.call(m, "startedAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedAt, w.uint32(34).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(42).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(50).fork()).ldelim();
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(58).string(m.url);
                    if (m.capturedAt != null && Object.hasOwnProperty.call(m, "capturedAt"))
                        $root.google.protobuf.Timestamp.encode(m.capturedAt, w.uint32(66).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a LayoutEstimation message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.LayoutEstimation} LayoutEstimation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LayoutEstimation.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.LayoutEstimation();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.meshSid = r.string();
                                break;
                            }
                        case 2: {
                                m.storageUrl = r.string();
                                break;
                            }
                        case 3: {
                                m.status = r.int32();
                                break;
                            }
                        case 4: {
                                m.startedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 5: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 6: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 7: {
                                m.url = r.string();
                                break;
                            }
                        case 8: {
                                m.capturedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a LayoutEstimation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.LayoutEstimation} LayoutEstimation
                 */
                LayoutEstimation.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.LayoutEstimation)
                        return d;
                    var m = new $root.streem.api.Artifact.LayoutEstimation();
                    if (d.meshSid != null) {
                        m.meshSid = String(d.meshSid);
                    }
                    if (d.storageUrl != null) {
                        m.storageUrl = String(d.storageUrl);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.startedAt != null) {
                        if (typeof d.startedAt !== "object")
                            throw TypeError(".streem.api.Artifact.LayoutEstimation.startedAt: object expected");
                        m.startedAt = $root.google.protobuf.Timestamp.fromObject(d.startedAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.LayoutEstimation.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.LayoutEstimation.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    if (d.capturedAt != null) {
                        if (typeof d.capturedAt !== "object")
                            throw TypeError(".streem.api.Artifact.LayoutEstimation.capturedAt: object expected");
                        m.capturedAt = $root.google.protobuf.Timestamp.fromObject(d.capturedAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LayoutEstimation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {streem.api.Artifact.LayoutEstimation} m LayoutEstimation
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LayoutEstimation.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.meshSid = "";
                        d.storageUrl = "";
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                        d.startedAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.url = "";
                        d.capturedAt = null;
                    }
                    if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                        d.meshSid = m.meshSid;
                    }
                    if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                        d.storageUrl = m.storageUrl;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    if (m.startedAt != null && m.hasOwnProperty("startedAt")) {
                        d.startedAt = $root.google.protobuf.Timestamp.toObject(m.startedAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    if (m.capturedAt != null && m.hasOwnProperty("capturedAt")) {
                        d.capturedAt = $root.google.protobuf.Timestamp.toObject(m.capturedAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this LayoutEstimation to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LayoutEstimation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LayoutEstimation
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.LayoutEstimation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LayoutEstimation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.LayoutEstimation";
                };

                return LayoutEstimation;
            })();

            Artifact.TexturedMesh = (function() {

                /**
                 * Properties of a TexturedMesh.
                 * @memberof streem.api.Artifact
                 * @interface ITexturedMesh
                 * @property {string|null} [meshSid] TexturedMesh meshSid
                 * @property {string|null} [glbStorageUrl] TexturedMesh glbStorageUrl
                 * @property {string|null} [usdzStorageUrl] TexturedMesh usdzStorageUrl
                 * @property {streem.api.Artifact.Status|null} [status] TexturedMesh status
                 * @property {google.protobuf.ITimestamp|null} [startedAt] TexturedMesh startedAt
                 * @property {google.protobuf.ITimestamp|null} [erroredAt] TexturedMesh erroredAt
                 * @property {google.protobuf.ITimestamp|null} [fulfilledAt] TexturedMesh fulfilledAt
                 * @property {string|null} [glbUrl] TexturedMesh glbUrl
                 * @property {string|null} [usdzUrl] TexturedMesh usdzUrl
                 * @property {string|null} [videoRecordingTrackSid] TexturedMesh videoRecordingTrackSid
                 * @property {string|null} [videoRecordingSid] TexturedMesh videoRecordingSid
                 */

                /**
                 * Constructs a new TexturedMesh.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a TexturedMesh.
                 * @implements ITexturedMesh
                 * @constructor
                 * @param {streem.api.Artifact.ITexturedMesh=} [p] Properties to set
                 */
                function TexturedMesh(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * TexturedMesh meshSid.
                 * @member {string} meshSid
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.meshSid = "";

                /**
                 * TexturedMesh glbStorageUrl.
                 * @member {string} glbStorageUrl
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.glbStorageUrl = "";

                /**
                 * TexturedMesh usdzStorageUrl.
                 * @member {string} usdzStorageUrl
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.usdzStorageUrl = "";

                /**
                 * TexturedMesh status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.status = 0;

                /**
                 * TexturedMesh startedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.startedAt = null;

                /**
                 * TexturedMesh erroredAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} erroredAt
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.erroredAt = null;

                /**
                 * TexturedMesh fulfilledAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.fulfilledAt = null;

                /**
                 * TexturedMesh glbUrl.
                 * @member {string} glbUrl
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.glbUrl = "";

                /**
                 * TexturedMesh usdzUrl.
                 * @member {string} usdzUrl
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.usdzUrl = "";

                /**
                 * TexturedMesh videoRecordingTrackSid.
                 * @member {string} videoRecordingTrackSid
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.videoRecordingTrackSid = "";

                /**
                 * TexturedMesh videoRecordingSid.
                 * @member {string} videoRecordingSid
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 */
                TexturedMesh.prototype.videoRecordingSid = "";

                /**
                 * Creates a new TexturedMesh instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {streem.api.Artifact.ITexturedMesh=} [properties] Properties to set
                 * @returns {streem.api.Artifact.TexturedMesh} TexturedMesh instance
                 */
                TexturedMesh.create = function create(properties) {
                    return new TexturedMesh(properties);
                };

                /**
                 * Encodes the specified TexturedMesh message. Does not implicitly {@link streem.api.Artifact.TexturedMesh.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {streem.api.Artifact.ITexturedMesh} m TexturedMesh message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TexturedMesh.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                        w.uint32(10).string(m.meshSid);
                    if (m.glbStorageUrl != null && Object.hasOwnProperty.call(m, "glbStorageUrl"))
                        w.uint32(18).string(m.glbStorageUrl);
                    if (m.usdzStorageUrl != null && Object.hasOwnProperty.call(m, "usdzStorageUrl"))
                        w.uint32(26).string(m.usdzStorageUrl);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(32).int32(m.status);
                    if (m.startedAt != null && Object.hasOwnProperty.call(m, "startedAt"))
                        $root.google.protobuf.Timestamp.encode(m.startedAt, w.uint32(42).fork()).ldelim();
                    if (m.erroredAt != null && Object.hasOwnProperty.call(m, "erroredAt"))
                        $root.google.protobuf.Timestamp.encode(m.erroredAt, w.uint32(50).fork()).ldelim();
                    if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                        $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(58).fork()).ldelim();
                    if (m.glbUrl != null && Object.hasOwnProperty.call(m, "glbUrl"))
                        w.uint32(66).string(m.glbUrl);
                    if (m.usdzUrl != null && Object.hasOwnProperty.call(m, "usdzUrl"))
                        w.uint32(74).string(m.usdzUrl);
                    if (m.videoRecordingTrackSid != null && Object.hasOwnProperty.call(m, "videoRecordingTrackSid"))
                        w.uint32(82).string(m.videoRecordingTrackSid);
                    if (m.videoRecordingSid != null && Object.hasOwnProperty.call(m, "videoRecordingSid"))
                        w.uint32(90).string(m.videoRecordingSid);
                    return w;
                };

                /**
                 * Decodes a TexturedMesh message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.TexturedMesh} TexturedMesh
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TexturedMesh.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.TexturedMesh();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.meshSid = r.string();
                                break;
                            }
                        case 2: {
                                m.glbStorageUrl = r.string();
                                break;
                            }
                        case 3: {
                                m.usdzStorageUrl = r.string();
                                break;
                            }
                        case 4: {
                                m.status = r.int32();
                                break;
                            }
                        case 5: {
                                m.startedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 6: {
                                m.erroredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 7: {
                                m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 8: {
                                m.glbUrl = r.string();
                                break;
                            }
                        case 9: {
                                m.usdzUrl = r.string();
                                break;
                            }
                        case 10: {
                                m.videoRecordingTrackSid = r.string();
                                break;
                            }
                        case 11: {
                                m.videoRecordingSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a TexturedMesh message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.TexturedMesh} TexturedMesh
                 */
                TexturedMesh.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.TexturedMesh)
                        return d;
                    var m = new $root.streem.api.Artifact.TexturedMesh();
                    if (d.meshSid != null) {
                        m.meshSid = String(d.meshSid);
                    }
                    if (d.glbStorageUrl != null) {
                        m.glbStorageUrl = String(d.glbStorageUrl);
                    }
                    if (d.usdzStorageUrl != null) {
                        m.usdzStorageUrl = String(d.usdzStorageUrl);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.startedAt != null) {
                        if (typeof d.startedAt !== "object")
                            throw TypeError(".streem.api.Artifact.TexturedMesh.startedAt: object expected");
                        m.startedAt = $root.google.protobuf.Timestamp.fromObject(d.startedAt);
                    }
                    if (d.erroredAt != null) {
                        if (typeof d.erroredAt !== "object")
                            throw TypeError(".streem.api.Artifact.TexturedMesh.erroredAt: object expected");
                        m.erroredAt = $root.google.protobuf.Timestamp.fromObject(d.erroredAt);
                    }
                    if (d.fulfilledAt != null) {
                        if (typeof d.fulfilledAt !== "object")
                            throw TypeError(".streem.api.Artifact.TexturedMesh.fulfilledAt: object expected");
                        m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                    }
                    if (d.glbUrl != null) {
                        m.glbUrl = String(d.glbUrl);
                    }
                    if (d.usdzUrl != null) {
                        m.usdzUrl = String(d.usdzUrl);
                    }
                    if (d.videoRecordingTrackSid != null) {
                        m.videoRecordingTrackSid = String(d.videoRecordingTrackSid);
                    }
                    if (d.videoRecordingSid != null) {
                        m.videoRecordingSid = String(d.videoRecordingSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a TexturedMesh message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {streem.api.Artifact.TexturedMesh} m TexturedMesh
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TexturedMesh.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.meshSid = "";
                        d.glbStorageUrl = "";
                        d.usdzStorageUrl = "";
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                        d.startedAt = null;
                        d.erroredAt = null;
                        d.fulfilledAt = null;
                        d.glbUrl = "";
                        d.usdzUrl = "";
                        d.videoRecordingTrackSid = "";
                        d.videoRecordingSid = "";
                    }
                    if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                        d.meshSid = m.meshSid;
                    }
                    if (m.glbStorageUrl != null && m.hasOwnProperty("glbStorageUrl")) {
                        d.glbStorageUrl = m.glbStorageUrl;
                    }
                    if (m.usdzStorageUrl != null && m.hasOwnProperty("usdzStorageUrl")) {
                        d.usdzStorageUrl = m.usdzStorageUrl;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    if (m.startedAt != null && m.hasOwnProperty("startedAt")) {
                        d.startedAt = $root.google.protobuf.Timestamp.toObject(m.startedAt, o);
                    }
                    if (m.erroredAt != null && m.hasOwnProperty("erroredAt")) {
                        d.erroredAt = $root.google.protobuf.Timestamp.toObject(m.erroredAt, o);
                    }
                    if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                        d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                    }
                    if (m.glbUrl != null && m.hasOwnProperty("glbUrl")) {
                        d.glbUrl = m.glbUrl;
                    }
                    if (m.usdzUrl != null && m.hasOwnProperty("usdzUrl")) {
                        d.usdzUrl = m.usdzUrl;
                    }
                    if (m.videoRecordingTrackSid != null && m.hasOwnProperty("videoRecordingTrackSid")) {
                        d.videoRecordingTrackSid = m.videoRecordingTrackSid;
                    }
                    if (m.videoRecordingSid != null && m.hasOwnProperty("videoRecordingSid")) {
                        d.videoRecordingSid = m.videoRecordingSid;
                    }
                    return d;
                };

                /**
                 * Converts this TexturedMesh to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TexturedMesh.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TexturedMesh
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.TexturedMesh
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TexturedMesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.TexturedMesh";
                };

                return TexturedMesh;
            })();

            Artifact.Bookmark = (function() {

                /**
                 * Properties of a Bookmark.
                 * @memberof streem.api.Artifact
                 * @interface IBookmark
                 * @property {string|null} [label] Bookmark label
                 * @property {google.protobuf.ITimestamp|null} [timestamp] Bookmark timestamp
                 * @property {streem.api.Artifact.Status|null} [status] Bookmark status
                 */

                /**
                 * Constructs a new Bookmark.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a Bookmark.
                 * @implements IBookmark
                 * @constructor
                 * @param {streem.api.Artifact.IBookmark=} [p] Properties to set
                 */
                function Bookmark(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Bookmark label.
                 * @member {string} label
                 * @memberof streem.api.Artifact.Bookmark
                 * @instance
                 */
                Bookmark.prototype.label = "";

                /**
                 * Bookmark timestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} timestamp
                 * @memberof streem.api.Artifact.Bookmark
                 * @instance
                 */
                Bookmark.prototype.timestamp = null;

                /**
                 * Bookmark status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.Bookmark
                 * @instance
                 */
                Bookmark.prototype.status = 0;

                /**
                 * Creates a new Bookmark instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {streem.api.Artifact.IBookmark=} [properties] Properties to set
                 * @returns {streem.api.Artifact.Bookmark} Bookmark instance
                 */
                Bookmark.create = function create(properties) {
                    return new Bookmark(properties);
                };

                /**
                 * Encodes the specified Bookmark message. Does not implicitly {@link streem.api.Artifact.Bookmark.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {streem.api.Artifact.IBookmark} m Bookmark message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Bookmark.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                        w.uint32(10).string(m.label);
                    if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                        $root.google.protobuf.Timestamp.encode(m.timestamp, w.uint32(18).fork()).ldelim();
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(24).int32(m.status);
                    return w;
                };

                /**
                 * Decodes a Bookmark message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.Bookmark} Bookmark
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Bookmark.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.Bookmark();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.label = r.string();
                                break;
                            }
                        case 2: {
                                m.timestamp = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.status = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Bookmark message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.Bookmark} Bookmark
                 */
                Bookmark.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.Bookmark)
                        return d;
                    var m = new $root.streem.api.Artifact.Bookmark();
                    if (d.label != null) {
                        m.label = String(d.label);
                    }
                    if (d.timestamp != null) {
                        if (typeof d.timestamp !== "object")
                            throw TypeError(".streem.api.Artifact.Bookmark.timestamp: object expected");
                        m.timestamp = $root.google.protobuf.Timestamp.fromObject(d.timestamp);
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Bookmark message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {streem.api.Artifact.Bookmark} m Bookmark
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Bookmark.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.label = "";
                        d.timestamp = null;
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                    }
                    if (m.label != null && m.hasOwnProperty("label")) {
                        d.label = m.label;
                    }
                    if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                        d.timestamp = $root.google.protobuf.Timestamp.toObject(m.timestamp, o);
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    return d;
                };

                /**
                 * Converts this Bookmark to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.Bookmark
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Bookmark.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Bookmark
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.Bookmark
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Bookmark.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.Bookmark";
                };

                return Bookmark;
            })();

            Artifact.RoomOutcomeReport = (function() {

                /**
                 * Properties of a RoomOutcomeReport.
                 * @memberof streem.api.Artifact
                 * @interface IRoomOutcomeReport
                 * @property {streem.api.Artifact.Status|null} [status] RoomOutcomeReport status
                 * @property {Array.<streem.api.Artifact.RoomOutcomeReport.IEntry>|null} [diagnoses] RoomOutcomeReport diagnoses
                 * @property {streem.api.Artifact.RoomOutcomeReport.IEntry|null} [disposition] RoomOutcomeReport disposition
                 * @property {Object.<string,google.protobuf.IValue>|null} [customFields] RoomOutcomeReport customFields
                 * @property {google.protobuf.ITimestamp|null} [createdAt] RoomOutcomeReport createdAt
                 */

                /**
                 * Constructs a new RoomOutcomeReport.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents a RoomOutcomeReport.
                 * @implements IRoomOutcomeReport
                 * @constructor
                 * @param {streem.api.Artifact.IRoomOutcomeReport=} [p] Properties to set
                 */
                function RoomOutcomeReport(p) {
                    this.diagnoses = [];
                    this.customFields = {};
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomOutcomeReport status.
                 * @member {streem.api.Artifact.Status} status
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 */
                RoomOutcomeReport.prototype.status = 0;

                /**
                 * RoomOutcomeReport diagnoses.
                 * @member {Array.<streem.api.Artifact.RoomOutcomeReport.IEntry>} diagnoses
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 */
                RoomOutcomeReport.prototype.diagnoses = $util.emptyArray;

                /**
                 * RoomOutcomeReport disposition.
                 * @member {streem.api.Artifact.RoomOutcomeReport.IEntry|null|undefined} disposition
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 */
                RoomOutcomeReport.prototype.disposition = null;

                /**
                 * RoomOutcomeReport customFields.
                 * @member {Object.<string,google.protobuf.IValue>} customFields
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 */
                RoomOutcomeReport.prototype.customFields = $util.emptyObject;

                /**
                 * RoomOutcomeReport createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 */
                RoomOutcomeReport.prototype.createdAt = null;

                /**
                 * Creates a new RoomOutcomeReport instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {streem.api.Artifact.IRoomOutcomeReport=} [properties] Properties to set
                 * @returns {streem.api.Artifact.RoomOutcomeReport} RoomOutcomeReport instance
                 */
                RoomOutcomeReport.create = function create(properties) {
                    return new RoomOutcomeReport(properties);
                };

                /**
                 * Encodes the specified RoomOutcomeReport message. Does not implicitly {@link streem.api.Artifact.RoomOutcomeReport.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {streem.api.Artifact.IRoomOutcomeReport} m RoomOutcomeReport message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomOutcomeReport.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(8).int32(m.status);
                    if (m.diagnoses != null && m.diagnoses.length) {
                        for (var i = 0; i < m.diagnoses.length; ++i)
                            $root.streem.api.Artifact.RoomOutcomeReport.Entry.encode(m.diagnoses[i], w.uint32(18).fork()).ldelim();
                    }
                    if (m.disposition != null && Object.hasOwnProperty.call(m, "disposition"))
                        $root.streem.api.Artifact.RoomOutcomeReport.Entry.encode(m.disposition, w.uint32(26).fork()).ldelim();
                    if (m.customFields != null && Object.hasOwnProperty.call(m, "customFields")) {
                        for (var ks = Object.keys(m.customFields), i = 0; i < ks.length; ++i) {
                            w.uint32(34).fork().uint32(10).string(ks[i]);
                            $root.google.protobuf.Value.encode(m.customFields[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                        }
                    }
                    if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(42).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomOutcomeReport message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.RoomOutcomeReport} RoomOutcomeReport
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomOutcomeReport.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.RoomOutcomeReport(), k, value;
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.status = r.int32();
                                break;
                            }
                        case 2: {
                                if (!(m.diagnoses && m.diagnoses.length))
                                    m.diagnoses = [];
                                m.diagnoses.push($root.streem.api.Artifact.RoomOutcomeReport.Entry.decode(r, r.uint32()));
                                break;
                            }
                        case 3: {
                                m.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                if (m.customFields === $util.emptyObject)
                                    m.customFields = {};
                                var c2 = r.uint32() + r.pos;
                                k = "";
                                value = null;
                                while (r.pos < c2) {
                                    var tag2 = r.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        k = r.string();
                                        break;
                                    case 2:
                                        value = $root.google.protobuf.Value.decode(r, r.uint32());
                                        break;
                                    default:
                                        r.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                m.customFields[k] = value;
                                break;
                            }
                        case 5: {
                                m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomOutcomeReport message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.RoomOutcomeReport} RoomOutcomeReport
                 */
                RoomOutcomeReport.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.RoomOutcomeReport)
                        return d;
                    var m = new $root.streem.api.Artifact.RoomOutcomeReport();
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_REJECTED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ERROR":
                    case 2:
                        m.status = 2;
                        break;
                    case "STATUS_STARTED":
                    case 3:
                        m.status = 3;
                        break;
                    case "STATUS_CAPTURED":
                    case 4:
                        m.status = 4;
                        break;
                    case "STATUS_FULFILLED":
                    case 5:
                        m.status = 5;
                        break;
                    }
                    if (d.diagnoses) {
                        if (!Array.isArray(d.diagnoses))
                            throw TypeError(".streem.api.Artifact.RoomOutcomeReport.diagnoses: array expected");
                        m.diagnoses = [];
                        for (var i = 0; i < d.diagnoses.length; ++i) {
                            if (typeof d.diagnoses[i] !== "object")
                                throw TypeError(".streem.api.Artifact.RoomOutcomeReport.diagnoses: object expected");
                            m.diagnoses[i] = $root.streem.api.Artifact.RoomOutcomeReport.Entry.fromObject(d.diagnoses[i]);
                        }
                    }
                    if (d.disposition != null) {
                        if (typeof d.disposition !== "object")
                            throw TypeError(".streem.api.Artifact.RoomOutcomeReport.disposition: object expected");
                        m.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.fromObject(d.disposition);
                    }
                    if (d.customFields) {
                        if (typeof d.customFields !== "object")
                            throw TypeError(".streem.api.Artifact.RoomOutcomeReport.customFields: object expected");
                        m.customFields = {};
                        for (var ks = Object.keys(d.customFields), i = 0; i < ks.length; ++i) {
                            if (typeof d.customFields[ks[i]] !== "object")
                                throw TypeError(".streem.api.Artifact.RoomOutcomeReport.customFields: object expected");
                            m.customFields[ks[i]] = $root.google.protobuf.Value.fromObject(d.customFields[ks[i]]);
                        }
                    }
                    if (d.createdAt != null) {
                        if (typeof d.createdAt !== "object")
                            throw TypeError(".streem.api.Artifact.RoomOutcomeReport.createdAt: object expected");
                        m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomOutcomeReport message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {streem.api.Artifact.RoomOutcomeReport} m RoomOutcomeReport
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomOutcomeReport.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.diagnoses = [];
                    }
                    if (o.objects || o.defaults) {
                        d.customFields = {};
                    }
                    if (o.defaults) {
                        d.status = o.enums === String ? "STATUS_UNKNOWN" : 0;
                        d.disposition = null;
                        d.createdAt = null;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.Status[m.status] : m.status;
                    }
                    if (m.diagnoses && m.diagnoses.length) {
                        d.diagnoses = [];
                        for (var j = 0; j < m.diagnoses.length; ++j) {
                            d.diagnoses[j] = $root.streem.api.Artifact.RoomOutcomeReport.Entry.toObject(m.diagnoses[j], o);
                        }
                    }
                    if (m.disposition != null && m.hasOwnProperty("disposition")) {
                        d.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.toObject(m.disposition, o);
                    }
                    var ks2;
                    if (m.customFields && (ks2 = Object.keys(m.customFields)).length) {
                        d.customFields = {};
                        for (var j = 0; j < ks2.length; ++j) {
                            d.customFields[ks2[j]] = $root.google.protobuf.Value.toObject(m.customFields[ks2[j]], o);
                        }
                    }
                    if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                        d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomOutcomeReport to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomOutcomeReport.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomOutcomeReport
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.RoomOutcomeReport
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomOutcomeReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.RoomOutcomeReport";
                };

                RoomOutcomeReport.Entry = (function() {

                    /**
                     * Properties of an Entry.
                     * @memberof streem.api.Artifact.RoomOutcomeReport
                     * @interface IEntry
                     * @property {string|null} [code] Entry code
                     * @property {string|null} [label] Entry label
                     */

                    /**
                     * Constructs a new Entry.
                     * @memberof streem.api.Artifact.RoomOutcomeReport
                     * @classdesc Represents an Entry.
                     * @implements IEntry
                     * @constructor
                     * @param {streem.api.Artifact.RoomOutcomeReport.IEntry=} [p] Properties to set
                     */
                    function Entry(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Entry code.
                     * @member {string} code
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @instance
                     */
                    Entry.prototype.code = "";

                    /**
                     * Entry label.
                     * @member {string} label
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @instance
                     */
                    Entry.prototype.label = "";

                    /**
                     * Creates a new Entry instance using the specified properties.
                     * @function create
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {streem.api.Artifact.RoomOutcomeReport.IEntry=} [properties] Properties to set
                     * @returns {streem.api.Artifact.RoomOutcomeReport.Entry} Entry instance
                     */
                    Entry.create = function create(properties) {
                        return new Entry(properties);
                    };

                    /**
                     * Encodes the specified Entry message. Does not implicitly {@link streem.api.Artifact.RoomOutcomeReport.Entry.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {streem.api.Artifact.RoomOutcomeReport.IEntry} m Entry message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Entry.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                            w.uint32(10).string(m.code);
                        if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                            w.uint32(18).string(m.label);
                        return w;
                    };

                    /**
                     * Decodes an Entry message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.Artifact.RoomOutcomeReport.Entry} Entry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Entry.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.RoomOutcomeReport.Entry();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.code = r.string();
                                    break;
                                }
                            case 2: {
                                    m.label = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an Entry message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.Artifact.RoomOutcomeReport.Entry} Entry
                     */
                    Entry.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.Artifact.RoomOutcomeReport.Entry)
                            return d;
                        var m = new $root.streem.api.Artifact.RoomOutcomeReport.Entry();
                        if (d.code != null) {
                            m.code = String(d.code);
                        }
                        if (d.label != null) {
                            m.label = String(d.label);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an Entry message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {streem.api.Artifact.RoomOutcomeReport.Entry} m Entry
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Entry.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.code = "";
                            d.label = "";
                        }
                        if (m.code != null && m.hasOwnProperty("code")) {
                            d.code = m.code;
                        }
                        if (m.label != null && m.hasOwnProperty("label")) {
                            d.label = m.label;
                        }
                        return d;
                    };

                    /**
                     * Converts this Entry to JSON.
                     * @function toJSON
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Entry.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Entry
                     * @function getTypeUrl
                     * @memberof streem.api.Artifact.RoomOutcomeReport.Entry
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Entry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.Artifact.RoomOutcomeReport.Entry";
                    };

                    return Entry;
                })();

                return RoomOutcomeReport;
            })();

            Artifact.ArchivedArtifact = (function() {

                /**
                 * Properties of an ArchivedArtifact.
                 * @memberof streem.api.Artifact
                 * @interface IArchivedArtifact
                 * @property {string|null} [sid] ArchivedArtifact sid
                 * @property {string|null} [roomSid] ArchivedArtifact roomSid
                 * @property {streem.api.Artifact.Type|null} [type] ArchivedArtifact type
                 * @property {streem.api.Artifact.ArchivedArtifact.Status|null} [status] ArchivedArtifact status
                 * @property {google.protobuf.ITimestamp|null} [archivedAt] ArchivedArtifact archivedAt
                 */

                /**
                 * Constructs a new ArchivedArtifact.
                 * @memberof streem.api.Artifact
                 * @classdesc Represents an ArchivedArtifact.
                 * @implements IArchivedArtifact
                 * @constructor
                 * @param {streem.api.Artifact.IArchivedArtifact=} [p] Properties to set
                 */
                function ArchivedArtifact(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArchivedArtifact sid.
                 * @member {string} sid
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 */
                ArchivedArtifact.prototype.sid = "";

                /**
                 * ArchivedArtifact roomSid.
                 * @member {string} roomSid
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 */
                ArchivedArtifact.prototype.roomSid = "";

                /**
                 * ArchivedArtifact type.
                 * @member {streem.api.Artifact.Type} type
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 */
                ArchivedArtifact.prototype.type = 0;

                /**
                 * ArchivedArtifact status.
                 * @member {streem.api.Artifact.ArchivedArtifact.Status} status
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 */
                ArchivedArtifact.prototype.status = 0;

                /**
                 * ArchivedArtifact archivedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} archivedAt
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 */
                ArchivedArtifact.prototype.archivedAt = null;

                /**
                 * Creates a new ArchivedArtifact instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {streem.api.Artifact.IArchivedArtifact=} [properties] Properties to set
                 * @returns {streem.api.Artifact.ArchivedArtifact} ArchivedArtifact instance
                 */
                ArchivedArtifact.create = function create(properties) {
                    return new ArchivedArtifact(properties);
                };

                /**
                 * Encodes the specified ArchivedArtifact message. Does not implicitly {@link streem.api.Artifact.ArchivedArtifact.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {streem.api.Artifact.IArchivedArtifact} m ArchivedArtifact message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArchivedArtifact.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                        w.uint32(10).string(m.sid);
                    if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                        w.uint32(18).string(m.roomSid);
                    if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                        w.uint32(24).int32(m.type);
                    if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                        w.uint32(32).int32(m.status);
                    if (m.archivedAt != null && Object.hasOwnProperty.call(m, "archivedAt"))
                        $root.google.protobuf.Timestamp.encode(m.archivedAt, w.uint32(42).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArchivedArtifact message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Artifact.ArchivedArtifact} ArchivedArtifact
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArchivedArtifact.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Artifact.ArchivedArtifact();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.sid = r.string();
                                break;
                            }
                        case 2: {
                                m.roomSid = r.string();
                                break;
                            }
                        case 3: {
                                m.type = r.int32();
                                break;
                            }
                        case 4: {
                                m.status = r.int32();
                                break;
                            }
                        case 5: {
                                m.archivedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArchivedArtifact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Artifact.ArchivedArtifact} ArchivedArtifact
                 */
                ArchivedArtifact.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Artifact.ArchivedArtifact)
                        return d;
                    var m = new $root.streem.api.Artifact.ArchivedArtifact();
                    if (d.sid != null) {
                        m.sid = String(d.sid);
                    }
                    if (d.roomSid != null) {
                        m.roomSid = String(d.roomSid);
                    }
                    switch (d.type) {
                    default:
                        if (typeof d.type === "number") {
                            m.type = d.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        m.type = 0;
                        break;
                    case "STREEMSHOT":
                    case 1:
                        m.type = 1;
                        break;
                    case "RECORDING":
                    case 2:
                        m.type = 2;
                        break;
                    case "RECORDING_TRACK":
                    case 3:
                        m.type = 3;
                        break;
                    case "GPS_POSITION":
                    case 4:
                        m.type = 4;
                        break;
                    case "MESH":
                    case 5:
                        m.type = 5;
                        break;
                    case "TRANSCRIPTION":
                    case 6:
                        m.type = 6;
                        break;
                    case "SCENESHOT":
                    case 7:
                        m.type = 7;
                        break;
                    case "LAYOUT_ESTIMATION":
                    case 8:
                        m.type = 8;
                        break;
                    case "TEXTURED_MESH":
                    case 9:
                        m.type = 9;
                        break;
                    case "BOOKMARK":
                    case 10:
                        m.type = 10;
                        break;
                    case "ROOM_OUTCOME_REPORT":
                    case 11:
                        m.type = 11;
                        break;
                    }
                    switch (d.status) {
                    default:
                        if (typeof d.status === "number") {
                            m.status = d.status;
                            break;
                        }
                        break;
                    case "STATUS_INVALID":
                    case 0:
                        m.status = 0;
                        break;
                    case "STATUS_SCHEDULED":
                    case 1:
                        m.status = 1;
                        break;
                    case "STATUS_ARCHIVED":
                    case 2:
                        m.status = 2;
                        break;
                    }
                    if (d.archivedAt != null) {
                        if (typeof d.archivedAt !== "object")
                            throw TypeError(".streem.api.Artifact.ArchivedArtifact.archivedAt: object expected");
                        m.archivedAt = $root.google.protobuf.Timestamp.fromObject(d.archivedAt);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArchivedArtifact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {streem.api.Artifact.ArchivedArtifact} m ArchivedArtifact
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArchivedArtifact.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.sid = "";
                        d.roomSid = "";
                        d.type = o.enums === String ? "UNKNOWN" : 0;
                        d.status = o.enums === String ? "STATUS_INVALID" : 0;
                        d.archivedAt = null;
                    }
                    if (m.sid != null && m.hasOwnProperty("sid")) {
                        d.sid = m.sid;
                    }
                    if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                        d.roomSid = m.roomSid;
                    }
                    if (m.type != null && m.hasOwnProperty("type")) {
                        d.type = o.enums === String ? $root.streem.api.Artifact.Type[m.type] === undefined ? m.type : $root.streem.api.Artifact.Type[m.type] : m.type;
                    }
                    if (m.status != null && m.hasOwnProperty("status")) {
                        d.status = o.enums === String ? $root.streem.api.Artifact.ArchivedArtifact.Status[m.status] === undefined ? m.status : $root.streem.api.Artifact.ArchivedArtifact.Status[m.status] : m.status;
                    }
                    if (m.archivedAt != null && m.hasOwnProperty("archivedAt")) {
                        d.archivedAt = $root.google.protobuf.Timestamp.toObject(m.archivedAt, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArchivedArtifact to JSON.
                 * @function toJSON
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArchivedArtifact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArchivedArtifact
                 * @function getTypeUrl
                 * @memberof streem.api.Artifact.ArchivedArtifact
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArchivedArtifact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Artifact.ArchivedArtifact";
                };

                /**
                 * State diagram:
                 * 
                 * @startuml
                 * [*] --> STATUS_SCHEDULED  : Mark Artifact for Archiving
                 * STATUS_SCHEDULED --> STATUS_ARCHIVED : Archiving complete
                 * @enduml
                 * @name streem.api.Artifact.ArchivedArtifact.Status
                 * @enum {number}
                 * @property {number} STATUS_INVALID=0 STATUS_INVALID value
                 * @property {number} STATUS_SCHEDULED=1 STATUS_SCHEDULED value
                 * @property {number} STATUS_ARCHIVED=2 STATUS_ARCHIVED value
                 */
                ArchivedArtifact.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_INVALID"] = 0;
                    values[valuesById[1] = "STATUS_SCHEDULED"] = 1;
                    values[valuesById[2] = "STATUS_ARCHIVED"] = 2;
                    return values;
                })();

                return ArchivedArtifact;
            })();

            return Artifact;
        })();

        api.ArtifactCounts = (function() {

            /**
             * Properties of an ArtifactCounts.
             * @memberof streem.api
             * @interface IArtifactCounts
             * @property {number|null} [gpsPosition] ArtifactCounts gpsPosition
             * @property {number|null} [mesh] ArtifactCounts mesh
             * @property {number|null} [recording] ArtifactCounts recording
             * @property {number|null} [recordingTrack] ArtifactCounts recordingTrack
             * @property {number|null} [streemshot] ArtifactCounts streemshot
             * @property {number|null} [transcription] ArtifactCounts transcription
             * @property {number|null} [sceneshot] ArtifactCounts sceneshot
             * @property {number|null} [layoutEstimation] ArtifactCounts layoutEstimation
             * @property {number|null} [texturedMesh] ArtifactCounts texturedMesh
             * @property {number|null} [bookmark] ArtifactCounts bookmark
             * @property {number|null} [roomOutcomeReport] ArtifactCounts roomOutcomeReport
             */

            /**
             * Constructs a new ArtifactCounts.
             * @memberof streem.api
             * @classdesc Represents an ArtifactCounts.
             * @implements IArtifactCounts
             * @constructor
             * @param {streem.api.IArtifactCounts=} [p] Properties to set
             */
            function ArtifactCounts(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArtifactCounts gpsPosition.
             * @member {number} gpsPosition
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.gpsPosition = 0;

            /**
             * ArtifactCounts mesh.
             * @member {number} mesh
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.mesh = 0;

            /**
             * ArtifactCounts recording.
             * @member {number} recording
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.recording = 0;

            /**
             * ArtifactCounts recordingTrack.
             * @member {number} recordingTrack
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.recordingTrack = 0;

            /**
             * ArtifactCounts streemshot.
             * @member {number} streemshot
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.streemshot = 0;

            /**
             * ArtifactCounts transcription.
             * @member {number} transcription
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.transcription = 0;

            /**
             * ArtifactCounts sceneshot.
             * @member {number} sceneshot
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.sceneshot = 0;

            /**
             * ArtifactCounts layoutEstimation.
             * @member {number} layoutEstimation
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.layoutEstimation = 0;

            /**
             * ArtifactCounts texturedMesh.
             * @member {number} texturedMesh
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.texturedMesh = 0;

            /**
             * ArtifactCounts bookmark.
             * @member {number} bookmark
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.bookmark = 0;

            /**
             * ArtifactCounts roomOutcomeReport.
             * @member {number} roomOutcomeReport
             * @memberof streem.api.ArtifactCounts
             * @instance
             */
            ArtifactCounts.prototype.roomOutcomeReport = 0;

            /**
             * Creates a new ArtifactCounts instance using the specified properties.
             * @function create
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {streem.api.IArtifactCounts=} [properties] Properties to set
             * @returns {streem.api.ArtifactCounts} ArtifactCounts instance
             */
            ArtifactCounts.create = function create(properties) {
                return new ArtifactCounts(properties);
            };

            /**
             * Encodes the specified ArtifactCounts message. Does not implicitly {@link streem.api.ArtifactCounts.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {streem.api.IArtifactCounts} m ArtifactCounts message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArtifactCounts.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.gpsPosition != null && Object.hasOwnProperty.call(m, "gpsPosition"))
                    w.uint32(8).int32(m.gpsPosition);
                if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                    w.uint32(16).int32(m.mesh);
                if (m.recording != null && Object.hasOwnProperty.call(m, "recording"))
                    w.uint32(24).int32(m.recording);
                if (m.recordingTrack != null && Object.hasOwnProperty.call(m, "recordingTrack"))
                    w.uint32(32).int32(m.recordingTrack);
                if (m.streemshot != null && Object.hasOwnProperty.call(m, "streemshot"))
                    w.uint32(40).int32(m.streemshot);
                if (m.transcription != null && Object.hasOwnProperty.call(m, "transcription"))
                    w.uint32(48).int32(m.transcription);
                if (m.sceneshot != null && Object.hasOwnProperty.call(m, "sceneshot"))
                    w.uint32(56).int32(m.sceneshot);
                if (m.layoutEstimation != null && Object.hasOwnProperty.call(m, "layoutEstimation"))
                    w.uint32(64).int32(m.layoutEstimation);
                if (m.texturedMesh != null && Object.hasOwnProperty.call(m, "texturedMesh"))
                    w.uint32(72).int32(m.texturedMesh);
                if (m.bookmark != null && Object.hasOwnProperty.call(m, "bookmark"))
                    w.uint32(80).int32(m.bookmark);
                if (m.roomOutcomeReport != null && Object.hasOwnProperty.call(m, "roomOutcomeReport"))
                    w.uint32(88).int32(m.roomOutcomeReport);
                return w;
            };

            /**
             * Decodes an ArtifactCounts message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArtifactCounts} ArtifactCounts
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArtifactCounts.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArtifactCounts();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.gpsPosition = r.int32();
                            break;
                        }
                    case 2: {
                            m.mesh = r.int32();
                            break;
                        }
                    case 3: {
                            m.recording = r.int32();
                            break;
                        }
                    case 4: {
                            m.recordingTrack = r.int32();
                            break;
                        }
                    case 5: {
                            m.streemshot = r.int32();
                            break;
                        }
                    case 6: {
                            m.transcription = r.int32();
                            break;
                        }
                    case 7: {
                            m.sceneshot = r.int32();
                            break;
                        }
                    case 8: {
                            m.layoutEstimation = r.int32();
                            break;
                        }
                    case 9: {
                            m.texturedMesh = r.int32();
                            break;
                        }
                    case 10: {
                            m.bookmark = r.int32();
                            break;
                        }
                    case 11: {
                            m.roomOutcomeReport = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArtifactCounts message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArtifactCounts} ArtifactCounts
             */
            ArtifactCounts.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArtifactCounts)
                    return d;
                var m = new $root.streem.api.ArtifactCounts();
                if (d.gpsPosition != null) {
                    m.gpsPosition = d.gpsPosition | 0;
                }
                if (d.mesh != null) {
                    m.mesh = d.mesh | 0;
                }
                if (d.recording != null) {
                    m.recording = d.recording | 0;
                }
                if (d.recordingTrack != null) {
                    m.recordingTrack = d.recordingTrack | 0;
                }
                if (d.streemshot != null) {
                    m.streemshot = d.streemshot | 0;
                }
                if (d.transcription != null) {
                    m.transcription = d.transcription | 0;
                }
                if (d.sceneshot != null) {
                    m.sceneshot = d.sceneshot | 0;
                }
                if (d.layoutEstimation != null) {
                    m.layoutEstimation = d.layoutEstimation | 0;
                }
                if (d.texturedMesh != null) {
                    m.texturedMesh = d.texturedMesh | 0;
                }
                if (d.bookmark != null) {
                    m.bookmark = d.bookmark | 0;
                }
                if (d.roomOutcomeReport != null) {
                    m.roomOutcomeReport = d.roomOutcomeReport | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from an ArtifactCounts message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {streem.api.ArtifactCounts} m ArtifactCounts
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArtifactCounts.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.gpsPosition = 0;
                    d.mesh = 0;
                    d.recording = 0;
                    d.recordingTrack = 0;
                    d.streemshot = 0;
                    d.transcription = 0;
                    d.sceneshot = 0;
                    d.layoutEstimation = 0;
                    d.texturedMesh = 0;
                    d.bookmark = 0;
                    d.roomOutcomeReport = 0;
                }
                if (m.gpsPosition != null && m.hasOwnProperty("gpsPosition")) {
                    d.gpsPosition = m.gpsPosition;
                }
                if (m.mesh != null && m.hasOwnProperty("mesh")) {
                    d.mesh = m.mesh;
                }
                if (m.recording != null && m.hasOwnProperty("recording")) {
                    d.recording = m.recording;
                }
                if (m.recordingTrack != null && m.hasOwnProperty("recordingTrack")) {
                    d.recordingTrack = m.recordingTrack;
                }
                if (m.streemshot != null && m.hasOwnProperty("streemshot")) {
                    d.streemshot = m.streemshot;
                }
                if (m.transcription != null && m.hasOwnProperty("transcription")) {
                    d.transcription = m.transcription;
                }
                if (m.sceneshot != null && m.hasOwnProperty("sceneshot")) {
                    d.sceneshot = m.sceneshot;
                }
                if (m.layoutEstimation != null && m.hasOwnProperty("layoutEstimation")) {
                    d.layoutEstimation = m.layoutEstimation;
                }
                if (m.texturedMesh != null && m.hasOwnProperty("texturedMesh")) {
                    d.texturedMesh = m.texturedMesh;
                }
                if (m.bookmark != null && m.hasOwnProperty("bookmark")) {
                    d.bookmark = m.bookmark;
                }
                if (m.roomOutcomeReport != null && m.hasOwnProperty("roomOutcomeReport")) {
                    d.roomOutcomeReport = m.roomOutcomeReport;
                }
                return d;
            };

            /**
             * Converts this ArtifactCounts to JSON.
             * @function toJSON
             * @memberof streem.api.ArtifactCounts
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArtifactCounts.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArtifactCounts
             * @function getTypeUrl
             * @memberof streem.api.ArtifactCounts
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArtifactCounts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArtifactCounts";
            };

            return ArtifactCounts;
        })();

        api.ArElementList = (function() {

            /**
             * Properties of an ArElementList.
             * @memberof streem.api
             * @interface IArElementList
             * @property {Array.<streem.api.IArElement>|null} [arElements] ArElementList arElements
             */

            /**
             * Constructs a new ArElementList.
             * @memberof streem.api
             * @classdesc Represents an ArElementList.
             * @implements IArElementList
             * @constructor
             * @param {streem.api.IArElementList=} [p] Properties to set
             */
            function ArElementList(p) {
                this.arElements = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArElementList arElements.
             * @member {Array.<streem.api.IArElement>} arElements
             * @memberof streem.api.ArElementList
             * @instance
             */
            ArElementList.prototype.arElements = $util.emptyArray;

            /**
             * Creates a new ArElementList instance using the specified properties.
             * @function create
             * @memberof streem.api.ArElementList
             * @static
             * @param {streem.api.IArElementList=} [properties] Properties to set
             * @returns {streem.api.ArElementList} ArElementList instance
             */
            ArElementList.create = function create(properties) {
                return new ArElementList(properties);
            };

            /**
             * Encodes the specified ArElementList message. Does not implicitly {@link streem.api.ArElementList.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArElementList
             * @static
             * @param {streem.api.IArElementList} m ArElementList message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArElementList.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.arElements != null && m.arElements.length) {
                    for (var i = 0; i < m.arElements.length; ++i)
                        $root.streem.api.ArElement.encode(m.arElements[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an ArElementList message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArElementList
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArElementList} ArElementList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArElementList.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElementList();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.arElements && m.arElements.length))
                                m.arElements = [];
                            m.arElements.push($root.streem.api.ArElement.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArElementList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArElementList
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArElementList} ArElementList
             */
            ArElementList.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArElementList)
                    return d;
                var m = new $root.streem.api.ArElementList();
                if (d.arElements) {
                    if (!Array.isArray(d.arElements))
                        throw TypeError(".streem.api.ArElementList.arElements: array expected");
                    m.arElements = [];
                    for (var i = 0; i < d.arElements.length; ++i) {
                        if (typeof d.arElements[i] !== "object")
                            throw TypeError(".streem.api.ArElementList.arElements: object expected");
                        m.arElements[i] = $root.streem.api.ArElement.fromObject(d.arElements[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an ArElementList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArElementList
             * @static
             * @param {streem.api.ArElementList} m ArElementList
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArElementList.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.arElements = [];
                }
                if (m.arElements && m.arElements.length) {
                    d.arElements = [];
                    for (var j = 0; j < m.arElements.length; ++j) {
                        d.arElements[j] = $root.streem.api.ArElement.toObject(m.arElements[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ArElementList to JSON.
             * @function toJSON
             * @memberof streem.api.ArElementList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArElementList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArElementList
             * @function getTypeUrl
             * @memberof streem.api.ArElementList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArElementList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArElementList";
            };

            return ArElementList;
        })();

        api.ArElement = (function() {

            /**
             * Properties of an ArElement.
             * @memberof streem.api
             * @interface IArElement
             * @property {streem.api.ArElement.IArrow|null} [arrow] ArElement arrow
             * @property {streem.api.ArElement.IMeasurement|null} [measurement] ArElement measurement
             * @property {streem.api.ArElement.IModel3d|null} [model3d] ArElement model3d
             * @property {streem.api.ArElement.IText|null} [text] ArElement text
             */

            /**
             * Constructs a new ArElement.
             * @memberof streem.api
             * @classdesc Represents an ArElement.
             * @implements IArElement
             * @constructor
             * @param {streem.api.IArElement=} [p] Properties to set
             */
            function ArElement(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArElement arrow.
             * @member {streem.api.ArElement.IArrow|null|undefined} arrow
             * @memberof streem.api.ArElement
             * @instance
             */
            ArElement.prototype.arrow = null;

            /**
             * ArElement measurement.
             * @member {streem.api.ArElement.IMeasurement|null|undefined} measurement
             * @memberof streem.api.ArElement
             * @instance
             */
            ArElement.prototype.measurement = null;

            /**
             * ArElement model3d.
             * @member {streem.api.ArElement.IModel3d|null|undefined} model3d
             * @memberof streem.api.ArElement
             * @instance
             */
            ArElement.prototype.model3d = null;

            /**
             * ArElement text.
             * @member {streem.api.ArElement.IText|null|undefined} text
             * @memberof streem.api.ArElement
             * @instance
             */
            ArElement.prototype.text = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ArElement type.
             * @member {"arrow"|"measurement"|"model3d"|"text"|undefined} type
             * @memberof streem.api.ArElement
             * @instance
             */
            Object.defineProperty(ArElement.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["arrow", "measurement", "model3d", "text"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ArElement instance using the specified properties.
             * @function create
             * @memberof streem.api.ArElement
             * @static
             * @param {streem.api.IArElement=} [properties] Properties to set
             * @returns {streem.api.ArElement} ArElement instance
             */
            ArElement.create = function create(properties) {
                return new ArElement(properties);
            };

            /**
             * Encodes the specified ArElement message. Does not implicitly {@link streem.api.ArElement.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArElement
             * @static
             * @param {streem.api.IArElement} m ArElement message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArElement.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
                    $root.streem.api.ArElement.Arrow.encode(m.arrow, w.uint32(10).fork()).ldelim();
                if (m.measurement != null && Object.hasOwnProperty.call(m, "measurement"))
                    $root.streem.api.ArElement.Measurement.encode(m.measurement, w.uint32(18).fork()).ldelim();
                if (m.model3d != null && Object.hasOwnProperty.call(m, "model3d"))
                    $root.streem.api.ArElement.Model3d.encode(m.model3d, w.uint32(26).fork()).ldelim();
                if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                    $root.streem.api.ArElement.Text.encode(m.text, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an ArElement message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArElement
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArElement} ArElement
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArElement.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElement();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.arrow = $root.streem.api.ArElement.Arrow.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.measurement = $root.streem.api.ArElement.Measurement.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.model3d = $root.streem.api.ArElement.Model3d.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.text = $root.streem.api.ArElement.Text.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArElement message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArElement
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArElement} ArElement
             */
            ArElement.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArElement)
                    return d;
                var m = new $root.streem.api.ArElement();
                if (d.arrow != null) {
                    if (typeof d.arrow !== "object")
                        throw TypeError(".streem.api.ArElement.arrow: object expected");
                    m.arrow = $root.streem.api.ArElement.Arrow.fromObject(d.arrow);
                }
                if (d.measurement != null) {
                    if (typeof d.measurement !== "object")
                        throw TypeError(".streem.api.ArElement.measurement: object expected");
                    m.measurement = $root.streem.api.ArElement.Measurement.fromObject(d.measurement);
                }
                if (d.model3d != null) {
                    if (typeof d.model3d !== "object")
                        throw TypeError(".streem.api.ArElement.model3d: object expected");
                    m.model3d = $root.streem.api.ArElement.Model3d.fromObject(d.model3d);
                }
                if (d.text != null) {
                    if (typeof d.text !== "object")
                        throw TypeError(".streem.api.ArElement.text: object expected");
                    m.text = $root.streem.api.ArElement.Text.fromObject(d.text);
                }
                return m;
            };

            /**
             * Creates a plain object from an ArElement message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArElement
             * @static
             * @param {streem.api.ArElement} m ArElement
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArElement.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.arrow != null && m.hasOwnProperty("arrow")) {
                    d.arrow = $root.streem.api.ArElement.Arrow.toObject(m.arrow, o);
                    if (o.oneofs)
                        d.type = "arrow";
                }
                if (m.measurement != null && m.hasOwnProperty("measurement")) {
                    d.measurement = $root.streem.api.ArElement.Measurement.toObject(m.measurement, o);
                    if (o.oneofs)
                        d.type = "measurement";
                }
                if (m.model3d != null && m.hasOwnProperty("model3d")) {
                    d.model3d = $root.streem.api.ArElement.Model3d.toObject(m.model3d, o);
                    if (o.oneofs)
                        d.type = "model3d";
                }
                if (m.text != null && m.hasOwnProperty("text")) {
                    d.text = $root.streem.api.ArElement.Text.toObject(m.text, o);
                    if (o.oneofs)
                        d.type = "text";
                }
                return d;
            };

            /**
             * Converts this ArElement to JSON.
             * @function toJSON
             * @memberof streem.api.ArElement
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArElement.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArElement
             * @function getTypeUrl
             * @memberof streem.api.ArElement
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArElement";
            };

            ArElement.Arrow = (function() {

                /**
                 * Properties of an Arrow.
                 * @memberof streem.api.ArElement
                 * @interface IArrow
                 * @property {streem.api.ITransform|null} [transform] Arrow transform
                 * @property {number|null} [rotation] Arrow rotation
                 */

                /**
                 * Constructs a new Arrow.
                 * @memberof streem.api.ArElement
                 * @classdesc Represents an Arrow.
                 * @implements IArrow
                 * @constructor
                 * @param {streem.api.ArElement.IArrow=} [p] Properties to set
                 */
                function Arrow(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Arrow transform.
                 * @member {streem.api.ITransform|null|undefined} transform
                 * @memberof streem.api.ArElement.Arrow
                 * @instance
                 */
                Arrow.prototype.transform = null;

                /**
                 * Arrow rotation.
                 * @member {number} rotation
                 * @memberof streem.api.ArElement.Arrow
                 * @instance
                 */
                Arrow.prototype.rotation = 0;

                /**
                 * Creates a new Arrow instance using the specified properties.
                 * @function create
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {streem.api.ArElement.IArrow=} [properties] Properties to set
                 * @returns {streem.api.ArElement.Arrow} Arrow instance
                 */
                Arrow.create = function create(properties) {
                    return new Arrow(properties);
                };

                /**
                 * Encodes the specified Arrow message. Does not implicitly {@link streem.api.ArElement.Arrow.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {streem.api.ArElement.IArrow} m Arrow message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Arrow.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                        $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                    if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                        w.uint32(17).double(m.rotation);
                    return w;
                };

                /**
                 * Decodes an Arrow message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.ArElement.Arrow} Arrow
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Arrow.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElement.Arrow();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.rotation = r.double();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Arrow message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.ArElement.Arrow} Arrow
                 */
                Arrow.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.ArElement.Arrow)
                        return d;
                    var m = new $root.streem.api.ArElement.Arrow();
                    if (d.transform != null) {
                        if (typeof d.transform !== "object")
                            throw TypeError(".streem.api.ArElement.Arrow.transform: object expected");
                        m.transform = $root.streem.api.Transform.fromObject(d.transform);
                    }
                    if (d.rotation != null) {
                        m.rotation = Number(d.rotation);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Arrow message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {streem.api.ArElement.Arrow} m Arrow
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Arrow.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.transform = null;
                        d.rotation = 0;
                    }
                    if (m.transform != null && m.hasOwnProperty("transform")) {
                        d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                    }
                    if (m.rotation != null && m.hasOwnProperty("rotation")) {
                        d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                    }
                    return d;
                };

                /**
                 * Converts this Arrow to JSON.
                 * @function toJSON
                 * @memberof streem.api.ArElement.Arrow
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Arrow.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Arrow
                 * @function getTypeUrl
                 * @memberof streem.api.ArElement.Arrow
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Arrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.ArElement.Arrow";
                };

                return Arrow;
            })();

            ArElement.Measurement = (function() {

                /**
                 * Properties of a Measurement.
                 * @memberof streem.api.ArElement
                 * @interface IMeasurement
                 * @property {streem.api.IPoint|null} [from] Measurement from
                 * @property {streem.api.IPoint|null} [to] Measurement to
                 */

                /**
                 * Constructs a new Measurement.
                 * @memberof streem.api.ArElement
                 * @classdesc Represents a Measurement.
                 * @implements IMeasurement
                 * @constructor
                 * @param {streem.api.ArElement.IMeasurement=} [p] Properties to set
                 */
                function Measurement(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Measurement from.
                 * @member {streem.api.IPoint|null|undefined} from
                 * @memberof streem.api.ArElement.Measurement
                 * @instance
                 */
                Measurement.prototype.from = null;

                /**
                 * Measurement to.
                 * @member {streem.api.IPoint|null|undefined} to
                 * @memberof streem.api.ArElement.Measurement
                 * @instance
                 */
                Measurement.prototype.to = null;

                /**
                 * Creates a new Measurement instance using the specified properties.
                 * @function create
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {streem.api.ArElement.IMeasurement=} [properties] Properties to set
                 * @returns {streem.api.ArElement.Measurement} Measurement instance
                 */
                Measurement.create = function create(properties) {
                    return new Measurement(properties);
                };

                /**
                 * Encodes the specified Measurement message. Does not implicitly {@link streem.api.ArElement.Measurement.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {streem.api.ArElement.IMeasurement} m Measurement message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Measurement.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.from != null && Object.hasOwnProperty.call(m, "from"))
                        $root.streem.api.Point.encode(m.from, w.uint32(10).fork()).ldelim();
                    if (m.to != null && Object.hasOwnProperty.call(m, "to"))
                        $root.streem.api.Point.encode(m.to, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Measurement message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.ArElement.Measurement} Measurement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Measurement.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElement.Measurement();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.from = $root.streem.api.Point.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.to = $root.streem.api.Point.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Measurement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.ArElement.Measurement} Measurement
                 */
                Measurement.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.ArElement.Measurement)
                        return d;
                    var m = new $root.streem.api.ArElement.Measurement();
                    if (d.from != null) {
                        if (typeof d.from !== "object")
                            throw TypeError(".streem.api.ArElement.Measurement.from: object expected");
                        m.from = $root.streem.api.Point.fromObject(d.from);
                    }
                    if (d.to != null) {
                        if (typeof d.to !== "object")
                            throw TypeError(".streem.api.ArElement.Measurement.to: object expected");
                        m.to = $root.streem.api.Point.fromObject(d.to);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Measurement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {streem.api.ArElement.Measurement} m Measurement
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Measurement.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.from = null;
                        d.to = null;
                    }
                    if (m.from != null && m.hasOwnProperty("from")) {
                        d.from = $root.streem.api.Point.toObject(m.from, o);
                    }
                    if (m.to != null && m.hasOwnProperty("to")) {
                        d.to = $root.streem.api.Point.toObject(m.to, o);
                    }
                    return d;
                };

                /**
                 * Converts this Measurement to JSON.
                 * @function toJSON
                 * @memberof streem.api.ArElement.Measurement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Measurement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Measurement
                 * @function getTypeUrl
                 * @memberof streem.api.ArElement.Measurement
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Measurement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.ArElement.Measurement";
                };

                return Measurement;
            })();

            ArElement.Model3d = (function() {

                /**
                 * Properties of a Model3d.
                 * @memberof streem.api.ArElement
                 * @interface IModel3d
                 * @property {streem.api.ITransform|null} [transform] Model3d transform
                 * @property {number|null} [rotation] Model3d rotation
                 * @property {string|null} [name] Model3d name
                 */

                /**
                 * Constructs a new Model3d.
                 * @memberof streem.api.ArElement
                 * @classdesc Represents a Model3d.
                 * @implements IModel3d
                 * @constructor
                 * @param {streem.api.ArElement.IModel3d=} [p] Properties to set
                 */
                function Model3d(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Model3d transform.
                 * @member {streem.api.ITransform|null|undefined} transform
                 * @memberof streem.api.ArElement.Model3d
                 * @instance
                 */
                Model3d.prototype.transform = null;

                /**
                 * Model3d rotation.
                 * @member {number} rotation
                 * @memberof streem.api.ArElement.Model3d
                 * @instance
                 */
                Model3d.prototype.rotation = 0;

                /**
                 * Model3d name.
                 * @member {string} name
                 * @memberof streem.api.ArElement.Model3d
                 * @instance
                 */
                Model3d.prototype.name = "";

                /**
                 * Creates a new Model3d instance using the specified properties.
                 * @function create
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {streem.api.ArElement.IModel3d=} [properties] Properties to set
                 * @returns {streem.api.ArElement.Model3d} Model3d instance
                 */
                Model3d.create = function create(properties) {
                    return new Model3d(properties);
                };

                /**
                 * Encodes the specified Model3d message. Does not implicitly {@link streem.api.ArElement.Model3d.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {streem.api.ArElement.IModel3d} m Model3d message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Model3d.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                        $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                    if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                        w.uint32(17).double(m.rotation);
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(26).string(m.name);
                    return w;
                };

                /**
                 * Decodes a Model3d message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.ArElement.Model3d} Model3d
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Model3d.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElement.Model3d();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.rotation = r.double();
                                break;
                            }
                        case 3: {
                                m.name = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Model3d message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.ArElement.Model3d} Model3d
                 */
                Model3d.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.ArElement.Model3d)
                        return d;
                    var m = new $root.streem.api.ArElement.Model3d();
                    if (d.transform != null) {
                        if (typeof d.transform !== "object")
                            throw TypeError(".streem.api.ArElement.Model3d.transform: object expected");
                        m.transform = $root.streem.api.Transform.fromObject(d.transform);
                    }
                    if (d.rotation != null) {
                        m.rotation = Number(d.rotation);
                    }
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Model3d message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {streem.api.ArElement.Model3d} m Model3d
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Model3d.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.transform = null;
                        d.rotation = 0;
                        d.name = "";
                    }
                    if (m.transform != null && m.hasOwnProperty("transform")) {
                        d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                    }
                    if (m.rotation != null && m.hasOwnProperty("rotation")) {
                        d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    return d;
                };

                /**
                 * Converts this Model3d to JSON.
                 * @function toJSON
                 * @memberof streem.api.ArElement.Model3d
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Model3d.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Model3d
                 * @function getTypeUrl
                 * @memberof streem.api.ArElement.Model3d
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Model3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.ArElement.Model3d";
                };

                return Model3d;
            })();

            ArElement.Text = (function() {

                /**
                 * Properties of a Text.
                 * @memberof streem.api.ArElement
                 * @interface IText
                 * @property {streem.api.ITransform|null} [transform] Text transform
                 * @property {number|null} [rotation] Text rotation
                 * @property {string|null} [text] Text text
                 */

                /**
                 * Constructs a new Text.
                 * @memberof streem.api.ArElement
                 * @classdesc Represents a Text.
                 * @implements IText
                 * @constructor
                 * @param {streem.api.ArElement.IText=} [p] Properties to set
                 */
                function Text(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Text transform.
                 * @member {streem.api.ITransform|null|undefined} transform
                 * @memberof streem.api.ArElement.Text
                 * @instance
                 */
                Text.prototype.transform = null;

                /**
                 * Text rotation.
                 * @member {number} rotation
                 * @memberof streem.api.ArElement.Text
                 * @instance
                 */
                Text.prototype.rotation = 0;

                /**
                 * Text text.
                 * @member {string} text
                 * @memberof streem.api.ArElement.Text
                 * @instance
                 */
                Text.prototype.text = "";

                /**
                 * Creates a new Text instance using the specified properties.
                 * @function create
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {streem.api.ArElement.IText=} [properties] Properties to set
                 * @returns {streem.api.ArElement.Text} Text instance
                 */
                Text.create = function create(properties) {
                    return new Text(properties);
                };

                /**
                 * Encodes the specified Text message. Does not implicitly {@link streem.api.ArElement.Text.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {streem.api.ArElement.IText} m Text message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Text.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                        $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                    if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                        w.uint32(17).double(m.rotation);
                    if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                        w.uint32(26).string(m.text);
                    return w;
                };

                /**
                 * Decodes a Text message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.ArElement.Text} Text
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Text.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArElement.Text();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.rotation = r.double();
                                break;
                            }
                        case 3: {
                                m.text = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Text message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.ArElement.Text} Text
                 */
                Text.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.ArElement.Text)
                        return d;
                    var m = new $root.streem.api.ArElement.Text();
                    if (d.transform != null) {
                        if (typeof d.transform !== "object")
                            throw TypeError(".streem.api.ArElement.Text.transform: object expected");
                        m.transform = $root.streem.api.Transform.fromObject(d.transform);
                    }
                    if (d.rotation != null) {
                        m.rotation = Number(d.rotation);
                    }
                    if (d.text != null) {
                        m.text = String(d.text);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Text message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {streem.api.ArElement.Text} m Text
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Text.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.transform = null;
                        d.rotation = 0;
                        d.text = "";
                    }
                    if (m.transform != null && m.hasOwnProperty("transform")) {
                        d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                    }
                    if (m.rotation != null && m.hasOwnProperty("rotation")) {
                        d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                    }
                    if (m.text != null && m.hasOwnProperty("text")) {
                        d.text = m.text;
                    }
                    return d;
                };

                /**
                 * Converts this Text to JSON.
                 * @function toJSON
                 * @memberof streem.api.ArElement.Text
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Text.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Text
                 * @function getTypeUrl
                 * @memberof streem.api.ArElement.Text
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.ArElement.Text";
                };

                return Text;
            })();

            return ArElement;
        })();

        api.Note = (function() {

            /**
             * Properties of a Note.
             * @memberof streem.api
             * @interface INote
             * @property {string|null} [sid] Note sid
             * @property {string|null} [createdByUserSid] Note createdByUserSid
             * @property {google.protobuf.ITimestamp|null} [createdAt] Note createdAt
             * @property {streem.api.Note.Scope|null} [scope] Note scope
             * @property {string|null} [scopeSid] Note scopeSid
             * @property {string|null} [lastUpdatedByUserSid] Note lastUpdatedByUserSid
             * @property {google.protobuf.ITimestamp|null} [lastUpdatedAt] Note lastUpdatedAt
             * @property {string|null} [text] Note text
             */

            /**
             * Constructs a new Note.
             * @memberof streem.api
             * @classdesc Represents a Note.
             * @implements INote
             * @constructor
             * @param {streem.api.INote=} [p] Properties to set
             */
            function Note(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Note sid.
             * @member {string} sid
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.sid = "";

            /**
             * Note createdByUserSid.
             * @member {string} createdByUserSid
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.createdByUserSid = "";

            /**
             * Note createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.createdAt = null;

            /**
             * Note scope.
             * @member {streem.api.Note.Scope} scope
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.scope = 0;

            /**
             * Note scopeSid.
             * @member {string} scopeSid
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.scopeSid = "";

            /**
             * Note lastUpdatedByUserSid.
             * @member {string} lastUpdatedByUserSid
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.lastUpdatedByUserSid = "";

            /**
             * Note lastUpdatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdatedAt
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.lastUpdatedAt = null;

            /**
             * Note text.
             * @member {string} text
             * @memberof streem.api.Note
             * @instance
             */
            Note.prototype.text = "";

            /**
             * Creates a new Note instance using the specified properties.
             * @function create
             * @memberof streem.api.Note
             * @static
             * @param {streem.api.INote=} [properties] Properties to set
             * @returns {streem.api.Note} Note instance
             */
            Note.create = function create(properties) {
                return new Note(properties);
            };

            /**
             * Encodes the specified Note message. Does not implicitly {@link streem.api.Note.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Note
             * @static
             * @param {streem.api.INote} m Note message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Note.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.createdByUserSid != null && Object.hasOwnProperty.call(m, "createdByUserSid"))
                    w.uint32(18).string(m.createdByUserSid);
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(26).fork()).ldelim();
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(32).int32(m.scope);
                if (m.scopeSid != null && Object.hasOwnProperty.call(m, "scopeSid"))
                    w.uint32(42).string(m.scopeSid);
                if (m.lastUpdatedByUserSid != null && Object.hasOwnProperty.call(m, "lastUpdatedByUserSid"))
                    w.uint32(50).string(m.lastUpdatedByUserSid);
                if (m.lastUpdatedAt != null && Object.hasOwnProperty.call(m, "lastUpdatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.lastUpdatedAt, w.uint32(58).fork()).ldelim();
                if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                    w.uint32(66).string(m.text);
                return w;
            };

            /**
             * Decodes a Note message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Note
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Note} Note
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Note.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Note();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.createdByUserSid = r.string();
                            break;
                        }
                    case 3: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.scope = r.int32();
                            break;
                        }
                    case 5: {
                            m.scopeSid = r.string();
                            break;
                        }
                    case 6: {
                            m.lastUpdatedByUserSid = r.string();
                            break;
                        }
                    case 7: {
                            m.lastUpdatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            m.text = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Note message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Note
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Note} Note
             */
            Note.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Note)
                    return d;
                var m = new $root.streem.api.Note();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.createdByUserSid != null) {
                    m.createdByUserSid = String(d.createdByUserSid);
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.Note.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                switch (d.scope) {
                default:
                    if (typeof d.scope === "number") {
                        m.scope = d.scope;
                        break;
                    }
                    break;
                case "SCOPE_INVALID":
                case 0:
                    m.scope = 0;
                    break;
                case "SCOPE_ROOM":
                case 1:
                    m.scope = 1;
                    break;
                case "SCOPE_STREEMSHOT":
                case 2:
                    m.scope = 2;
                    break;
                }
                if (d.scopeSid != null) {
                    m.scopeSid = String(d.scopeSid);
                }
                if (d.lastUpdatedByUserSid != null) {
                    m.lastUpdatedByUserSid = String(d.lastUpdatedByUserSid);
                }
                if (d.lastUpdatedAt != null) {
                    if (typeof d.lastUpdatedAt !== "object")
                        throw TypeError(".streem.api.Note.lastUpdatedAt: object expected");
                    m.lastUpdatedAt = $root.google.protobuf.Timestamp.fromObject(d.lastUpdatedAt);
                }
                if (d.text != null) {
                    m.text = String(d.text);
                }
                return m;
            };

            /**
             * Creates a plain object from a Note message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Note
             * @static
             * @param {streem.api.Note} m Note
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Note.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.createdByUserSid = "";
                    d.createdAt = null;
                    d.scope = o.enums === String ? "SCOPE_INVALID" : 0;
                    d.scopeSid = "";
                    d.lastUpdatedByUserSid = "";
                    d.lastUpdatedAt = null;
                    d.text = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.createdByUserSid != null && m.hasOwnProperty("createdByUserSid")) {
                    d.createdByUserSid = m.createdByUserSid;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = o.enums === String ? $root.streem.api.Note.Scope[m.scope] === undefined ? m.scope : $root.streem.api.Note.Scope[m.scope] : m.scope;
                }
                if (m.scopeSid != null && m.hasOwnProperty("scopeSid")) {
                    d.scopeSid = m.scopeSid;
                }
                if (m.lastUpdatedByUserSid != null && m.hasOwnProperty("lastUpdatedByUserSid")) {
                    d.lastUpdatedByUserSid = m.lastUpdatedByUserSid;
                }
                if (m.lastUpdatedAt != null && m.hasOwnProperty("lastUpdatedAt")) {
                    d.lastUpdatedAt = $root.google.protobuf.Timestamp.toObject(m.lastUpdatedAt, o);
                }
                if (m.text != null && m.hasOwnProperty("text")) {
                    d.text = m.text;
                }
                return d;
            };

            /**
             * Converts this Note to JSON.
             * @function toJSON
             * @memberof streem.api.Note
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Note.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Note
             * @function getTypeUrl
             * @memberof streem.api.Note
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Note.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Note";
            };

            /**
             * Scope enum.
             * @name streem.api.Note.Scope
             * @enum {number}
             * @property {number} SCOPE_INVALID=0 SCOPE_INVALID value
             * @property {number} SCOPE_ROOM=1 SCOPE_ROOM value
             * @property {number} SCOPE_STREEMSHOT=2 SCOPE_STREEMSHOT value
             */
            Note.Scope = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SCOPE_INVALID"] = 0;
                values[valuesById[1] = "SCOPE_ROOM"] = 1;
                values[valuesById[2] = "SCOPE_STREEMSHOT"] = 2;
                return values;
            })();

            return Note;
        })();

        api.RoomInvitation = (function() {

            /**
             * Properties of a RoomInvitation.
             * @memberof streem.api
             * @interface IRoomInvitation
             * @property {string|null} [sid] RoomInvitation sid
             * @property {string|null} [roomSid] RoomInvitation roomSid
             * @property {streem.api.RoomInvitation.Status|null} [status] RoomInvitation status
             * @property {string|null} [fromUserSid] RoomInvitation fromUserSid
             * @property {string|null} [fromDeviceSid] RoomInvitation fromDeviceSid
             * @property {string|null} [toUserSid] RoomInvitation toUserSid
             * @property {string|null} [toDeviceSid] RoomInvitation toDeviceSid
             * @property {google.protobuf.ITimestamp|null} [pendingAt] RoomInvitation pendingAt
             * @property {google.protobuf.ITimestamp|null} [acceptedAt] RoomInvitation acceptedAt
             * @property {google.protobuf.ITimestamp|null} [rejectedAt] RoomInvitation rejectedAt
             * @property {google.protobuf.ITimestamp|null} [timedOutAt] RoomInvitation timedOutAt
             * @property {google.protobuf.ITimestamp|null} [canceledAt] RoomInvitation canceledAt
             */

            /**
             * Constructs a new RoomInvitation.
             * @memberof streem.api
             * @classdesc Represents a RoomInvitation.
             * @implements IRoomInvitation
             * @constructor
             * @param {streem.api.IRoomInvitation=} [p] Properties to set
             */
            function RoomInvitation(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RoomInvitation sid.
             * @member {string} sid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.sid = "";

            /**
             * RoomInvitation roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.roomSid = "";

            /**
             * RoomInvitation status.
             * @member {streem.api.RoomInvitation.Status} status
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.status = 0;

            /**
             * RoomInvitation fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.fromUserSid = "";

            /**
             * RoomInvitation fromDeviceSid.
             * @member {string} fromDeviceSid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.fromDeviceSid = "";

            /**
             * RoomInvitation toUserSid.
             * @member {string} toUserSid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.toUserSid = "";

            /**
             * RoomInvitation toDeviceSid.
             * @member {string} toDeviceSid
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.toDeviceSid = "";

            /**
             * RoomInvitation pendingAt.
             * @member {google.protobuf.ITimestamp|null|undefined} pendingAt
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.pendingAt = null;

            /**
             * RoomInvitation acceptedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} acceptedAt
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.acceptedAt = null;

            /**
             * RoomInvitation rejectedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} rejectedAt
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.rejectedAt = null;

            /**
             * RoomInvitation timedOutAt.
             * @member {google.protobuf.ITimestamp|null|undefined} timedOutAt
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.timedOutAt = null;

            /**
             * RoomInvitation canceledAt.
             * @member {google.protobuf.ITimestamp|null|undefined} canceledAt
             * @memberof streem.api.RoomInvitation
             * @instance
             */
            RoomInvitation.prototype.canceledAt = null;

            /**
             * Creates a new RoomInvitation instance using the specified properties.
             * @function create
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {streem.api.IRoomInvitation=} [properties] Properties to set
             * @returns {streem.api.RoomInvitation} RoomInvitation instance
             */
            RoomInvitation.create = function create(properties) {
                return new RoomInvitation(properties);
            };

            /**
             * Encodes the specified RoomInvitation message. Does not implicitly {@link streem.api.RoomInvitation.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {streem.api.IRoomInvitation} m RoomInvitation message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomInvitation.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(24).int32(m.status);
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(34).string(m.fromUserSid);
                if (m.fromDeviceSid != null && Object.hasOwnProperty.call(m, "fromDeviceSid"))
                    w.uint32(42).string(m.fromDeviceSid);
                if (m.toUserSid != null && Object.hasOwnProperty.call(m, "toUserSid"))
                    w.uint32(50).string(m.toUserSid);
                if (m.toDeviceSid != null && Object.hasOwnProperty.call(m, "toDeviceSid"))
                    w.uint32(58).string(m.toDeviceSid);
                if (m.pendingAt != null && Object.hasOwnProperty.call(m, "pendingAt"))
                    $root.google.protobuf.Timestamp.encode(m.pendingAt, w.uint32(66).fork()).ldelim();
                if (m.acceptedAt != null && Object.hasOwnProperty.call(m, "acceptedAt"))
                    $root.google.protobuf.Timestamp.encode(m.acceptedAt, w.uint32(74).fork()).ldelim();
                if (m.rejectedAt != null && Object.hasOwnProperty.call(m, "rejectedAt"))
                    $root.google.protobuf.Timestamp.encode(m.rejectedAt, w.uint32(82).fork()).ldelim();
                if (m.timedOutAt != null && Object.hasOwnProperty.call(m, "timedOutAt"))
                    $root.google.protobuf.Timestamp.encode(m.timedOutAt, w.uint32(90).fork()).ldelim();
                if (m.canceledAt != null && Object.hasOwnProperty.call(m, "canceledAt"))
                    $root.google.protobuf.Timestamp.encode(m.canceledAt, w.uint32(98).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a RoomInvitation message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RoomInvitation} RoomInvitation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomInvitation.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RoomInvitation();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 3: {
                            m.status = r.int32();
                            break;
                        }
                    case 4: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 5: {
                            m.fromDeviceSid = r.string();
                            break;
                        }
                    case 6: {
                            m.toUserSid = r.string();
                            break;
                        }
                    case 7: {
                            m.toDeviceSid = r.string();
                            break;
                        }
                    case 8: {
                            m.pendingAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            m.acceptedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 10: {
                            m.rejectedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 11: {
                            m.timedOutAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.canceledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RoomInvitation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RoomInvitation} RoomInvitation
             */
            RoomInvitation.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RoomInvitation)
                    return d;
                var m = new $root.streem.api.RoomInvitation();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_PENDING":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_ACCEPTED":
                case 2:
                    m.status = 2;
                    break;
                case "STATUS_REJECTED":
                case 3:
                    m.status = 3;
                    break;
                case "STATUS_CANCELED":
                case 4:
                    m.status = 4;
                    break;
                case "STATUS_TIMED_OUT":
                case 5:
                    m.status = 5;
                    break;
                }
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.fromDeviceSid != null) {
                    m.fromDeviceSid = String(d.fromDeviceSid);
                }
                if (d.toUserSid != null) {
                    m.toUserSid = String(d.toUserSid);
                }
                if (d.toDeviceSid != null) {
                    m.toDeviceSid = String(d.toDeviceSid);
                }
                if (d.pendingAt != null) {
                    if (typeof d.pendingAt !== "object")
                        throw TypeError(".streem.api.RoomInvitation.pendingAt: object expected");
                    m.pendingAt = $root.google.protobuf.Timestamp.fromObject(d.pendingAt);
                }
                if (d.acceptedAt != null) {
                    if (typeof d.acceptedAt !== "object")
                        throw TypeError(".streem.api.RoomInvitation.acceptedAt: object expected");
                    m.acceptedAt = $root.google.protobuf.Timestamp.fromObject(d.acceptedAt);
                }
                if (d.rejectedAt != null) {
                    if (typeof d.rejectedAt !== "object")
                        throw TypeError(".streem.api.RoomInvitation.rejectedAt: object expected");
                    m.rejectedAt = $root.google.protobuf.Timestamp.fromObject(d.rejectedAt);
                }
                if (d.timedOutAt != null) {
                    if (typeof d.timedOutAt !== "object")
                        throw TypeError(".streem.api.RoomInvitation.timedOutAt: object expected");
                    m.timedOutAt = $root.google.protobuf.Timestamp.fromObject(d.timedOutAt);
                }
                if (d.canceledAt != null) {
                    if (typeof d.canceledAt !== "object")
                        throw TypeError(".streem.api.RoomInvitation.canceledAt: object expected");
                    m.canceledAt = $root.google.protobuf.Timestamp.fromObject(d.canceledAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a RoomInvitation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {streem.api.RoomInvitation} m RoomInvitation
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomInvitation.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.roomSid = "";
                    d.status = o.enums === String ? "STATUS_INVALID" : 0;
                    d.fromUserSid = "";
                    d.fromDeviceSid = "";
                    d.toUserSid = "";
                    d.toDeviceSid = "";
                    d.pendingAt = null;
                    d.acceptedAt = null;
                    d.rejectedAt = null;
                    d.timedOutAt = null;
                    d.canceledAt = null;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.RoomInvitation.Status[m.status] === undefined ? m.status : $root.streem.api.RoomInvitation.Status[m.status] : m.status;
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.fromDeviceSid != null && m.hasOwnProperty("fromDeviceSid")) {
                    d.fromDeviceSid = m.fromDeviceSid;
                }
                if (m.toUserSid != null && m.hasOwnProperty("toUserSid")) {
                    d.toUserSid = m.toUserSid;
                }
                if (m.toDeviceSid != null && m.hasOwnProperty("toDeviceSid")) {
                    d.toDeviceSid = m.toDeviceSid;
                }
                if (m.pendingAt != null && m.hasOwnProperty("pendingAt")) {
                    d.pendingAt = $root.google.protobuf.Timestamp.toObject(m.pendingAt, o);
                }
                if (m.acceptedAt != null && m.hasOwnProperty("acceptedAt")) {
                    d.acceptedAt = $root.google.protobuf.Timestamp.toObject(m.acceptedAt, o);
                }
                if (m.rejectedAt != null && m.hasOwnProperty("rejectedAt")) {
                    d.rejectedAt = $root.google.protobuf.Timestamp.toObject(m.rejectedAt, o);
                }
                if (m.timedOutAt != null && m.hasOwnProperty("timedOutAt")) {
                    d.timedOutAt = $root.google.protobuf.Timestamp.toObject(m.timedOutAt, o);
                }
                if (m.canceledAt != null && m.hasOwnProperty("canceledAt")) {
                    d.canceledAt = $root.google.protobuf.Timestamp.toObject(m.canceledAt, o);
                }
                return d;
            };

            /**
             * Converts this RoomInvitation to JSON.
             * @function toJSON
             * @memberof streem.api.RoomInvitation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomInvitation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoomInvitation
             * @function getTypeUrl
             * @memberof streem.api.RoomInvitation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomInvitation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RoomInvitation";
            };

            /**
             * Status enum.
             * @name streem.api.RoomInvitation.Status
             * @enum {number}
             * @property {number} STATUS_INVALID=0 STATUS_INVALID value
             * @property {number} STATUS_PENDING=1 STATUS_PENDING value
             * @property {number} STATUS_ACCEPTED=2 STATUS_ACCEPTED value
             * @property {number} STATUS_REJECTED=3 STATUS_REJECTED value
             * @property {number} STATUS_CANCELED=4 STATUS_CANCELED value
             * @property {number} STATUS_TIMED_OUT=5 STATUS_TIMED_OUT value
             */
            RoomInvitation.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_INVALID"] = 0;
                values[valuesById[1] = "STATUS_PENDING"] = 1;
                values[valuesById[2] = "STATUS_ACCEPTED"] = 2;
                values[valuesById[3] = "STATUS_REJECTED"] = 3;
                values[valuesById[4] = "STATUS_CANCELED"] = 4;
                values[valuesById[5] = "STATUS_TIMED_OUT"] = 5;
                return values;
            })();

            return RoomInvitation;
        })();

        api.SortRequest = (function() {

            /**
             * Properties of a SortRequest.
             * @memberof streem.api
             * @interface ISortRequest
             * @property {Array.<streem.api.SortRequest.IField>|null} [fields] SortRequest fields
             */

            /**
             * Constructs a new SortRequest.
             * @memberof streem.api
             * @classdesc Represents a SortRequest.
             * @implements ISortRequest
             * @constructor
             * @param {streem.api.ISortRequest=} [p] Properties to set
             */
            function SortRequest(p) {
                this.fields = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SortRequest fields.
             * @member {Array.<streem.api.SortRequest.IField>} fields
             * @memberof streem.api.SortRequest
             * @instance
             */
            SortRequest.prototype.fields = $util.emptyArray;

            /**
             * Creates a new SortRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SortRequest
             * @static
             * @param {streem.api.ISortRequest=} [properties] Properties to set
             * @returns {streem.api.SortRequest} SortRequest instance
             */
            SortRequest.create = function create(properties) {
                return new SortRequest(properties);
            };

            /**
             * Encodes the specified SortRequest message. Does not implicitly {@link streem.api.SortRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SortRequest
             * @static
             * @param {streem.api.ISortRequest} m SortRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SortRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.fields != null && m.fields.length) {
                    for (var i = 0; i < m.fields.length; ++i)
                        $root.streem.api.SortRequest.Field.encode(m.fields[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a SortRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SortRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SortRequest} SortRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SortRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SortRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.fields && m.fields.length))
                                m.fields = [];
                            m.fields.push($root.streem.api.SortRequest.Field.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SortRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SortRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SortRequest} SortRequest
             */
            SortRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SortRequest)
                    return d;
                var m = new $root.streem.api.SortRequest();
                if (d.fields) {
                    if (!Array.isArray(d.fields))
                        throw TypeError(".streem.api.SortRequest.fields: array expected");
                    m.fields = [];
                    for (var i = 0; i < d.fields.length; ++i) {
                        if (typeof d.fields[i] !== "object")
                            throw TypeError(".streem.api.SortRequest.fields: object expected");
                        m.fields[i] = $root.streem.api.SortRequest.Field.fromObject(d.fields[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a SortRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SortRequest
             * @static
             * @param {streem.api.SortRequest} m SortRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SortRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.fields = [];
                }
                if (m.fields && m.fields.length) {
                    d.fields = [];
                    for (var j = 0; j < m.fields.length; ++j) {
                        d.fields[j] = $root.streem.api.SortRequest.Field.toObject(m.fields[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this SortRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SortRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SortRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SortRequest
             * @function getTypeUrl
             * @memberof streem.api.SortRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SortRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SortRequest";
            };

            SortRequest.Field = (function() {

                /**
                 * Properties of a Field.
                 * @memberof streem.api.SortRequest
                 * @interface IField
                 * @property {string|null} [field] Field field
                 * @property {streem.api.SortRequest.Direction|null} [direction] Field direction
                 */

                /**
                 * Constructs a new Field.
                 * @memberof streem.api.SortRequest
                 * @classdesc Represents a Field.
                 * @implements IField
                 * @constructor
                 * @param {streem.api.SortRequest.IField=} [p] Properties to set
                 */
                function Field(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Field field.
                 * @member {string} field
                 * @memberof streem.api.SortRequest.Field
                 * @instance
                 */
                Field.prototype.field = "";

                /**
                 * Field direction.
                 * @member {streem.api.SortRequest.Direction} direction
                 * @memberof streem.api.SortRequest.Field
                 * @instance
                 */
                Field.prototype.direction = 0;

                /**
                 * Creates a new Field instance using the specified properties.
                 * @function create
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {streem.api.SortRequest.IField=} [properties] Properties to set
                 * @returns {streem.api.SortRequest.Field} Field instance
                 */
                Field.create = function create(properties) {
                    return new Field(properties);
                };

                /**
                 * Encodes the specified Field message. Does not implicitly {@link streem.api.SortRequest.Field.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {streem.api.SortRequest.IField} m Field message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Field.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.field != null && Object.hasOwnProperty.call(m, "field"))
                        w.uint32(10).string(m.field);
                    if (m.direction != null && Object.hasOwnProperty.call(m, "direction"))
                        w.uint32(16).int32(m.direction);
                    return w;
                };

                /**
                 * Decodes a Field message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.SortRequest.Field} Field
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Field.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SortRequest.Field();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.field = r.string();
                                break;
                            }
                        case 2: {
                                m.direction = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Field message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.SortRequest.Field} Field
                 */
                Field.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.SortRequest.Field)
                        return d;
                    var m = new $root.streem.api.SortRequest.Field();
                    if (d.field != null) {
                        m.field = String(d.field);
                    }
                    switch (d.direction) {
                    default:
                        if (typeof d.direction === "number") {
                            m.direction = d.direction;
                            break;
                        }
                        break;
                    case "DIRECTION_ASC":
                    case 0:
                        m.direction = 0;
                        break;
                    case "DIRECTION_DESC":
                    case 1:
                        m.direction = 1;
                        break;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Field message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {streem.api.SortRequest.Field} m Field
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Field.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.field = "";
                        d.direction = o.enums === String ? "DIRECTION_ASC" : 0;
                    }
                    if (m.field != null && m.hasOwnProperty("field")) {
                        d.field = m.field;
                    }
                    if (m.direction != null && m.hasOwnProperty("direction")) {
                        d.direction = o.enums === String ? $root.streem.api.SortRequest.Direction[m.direction] === undefined ? m.direction : $root.streem.api.SortRequest.Direction[m.direction] : m.direction;
                    }
                    return d;
                };

                /**
                 * Converts this Field to JSON.
                 * @function toJSON
                 * @memberof streem.api.SortRequest.Field
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Field.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Field
                 * @function getTypeUrl
                 * @memberof streem.api.SortRequest.Field
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Field.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.SortRequest.Field";
                };

                return Field;
            })();

            /**
             * Direction enum.
             * @name streem.api.SortRequest.Direction
             * @enum {number}
             * @property {number} DIRECTION_ASC=0 DIRECTION_ASC value
             * @property {number} DIRECTION_DESC=1 DIRECTION_DESC value
             */
            SortRequest.Direction = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DIRECTION_ASC"] = 0;
                values[valuesById[1] = "DIRECTION_DESC"] = 1;
                return values;
            })();

            return SortRequest;
        })();

        api.PageToken = (function() {

            /**
             * Properties of a PageToken.
             * @memberof streem.api
             * @interface IPageToken
             * @property {streem.api.PageToken.IOffset|null} [offset] PageToken offset
             */

            /**
             * Constructs a new PageToken.
             * @memberof streem.api
             * @classdesc Represents a PageToken.
             * @implements IPageToken
             * @constructor
             * @param {streem.api.IPageToken=} [p] Properties to set
             */
            function PageToken(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PageToken offset.
             * @member {streem.api.PageToken.IOffset|null|undefined} offset
             * @memberof streem.api.PageToken
             * @instance
             */
            PageToken.prototype.offset = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * PageToken type.
             * @member {"offset"|undefined} type
             * @memberof streem.api.PageToken
             * @instance
             */
            Object.defineProperty(PageToken.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["offset"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new PageToken instance using the specified properties.
             * @function create
             * @memberof streem.api.PageToken
             * @static
             * @param {streem.api.IPageToken=} [properties] Properties to set
             * @returns {streem.api.PageToken} PageToken instance
             */
            PageToken.create = function create(properties) {
                return new PageToken(properties);
            };

            /**
             * Encodes the specified PageToken message. Does not implicitly {@link streem.api.PageToken.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PageToken
             * @static
             * @param {streem.api.IPageToken} m PageToken message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PageToken.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                    $root.streem.api.PageToken.Offset.encode(m.offset, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a PageToken message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PageToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PageToken} PageToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PageToken.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PageToken();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.offset = $root.streem.api.PageToken.Offset.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PageToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PageToken
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PageToken} PageToken
             */
            PageToken.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PageToken)
                    return d;
                var m = new $root.streem.api.PageToken();
                if (d.offset != null) {
                    if (typeof d.offset !== "object")
                        throw TypeError(".streem.api.PageToken.offset: object expected");
                    m.offset = $root.streem.api.PageToken.Offset.fromObject(d.offset);
                }
                return m;
            };

            /**
             * Creates a plain object from a PageToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PageToken
             * @static
             * @param {streem.api.PageToken} m PageToken
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PageToken.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.offset != null && m.hasOwnProperty("offset")) {
                    d.offset = $root.streem.api.PageToken.Offset.toObject(m.offset, o);
                    if (o.oneofs)
                        d.type = "offset";
                }
                return d;
            };

            /**
             * Converts this PageToken to JSON.
             * @function toJSON
             * @memberof streem.api.PageToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PageToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PageToken
             * @function getTypeUrl
             * @memberof streem.api.PageToken
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PageToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PageToken";
            };

            PageToken.Offset = (function() {

                /**
                 * Properties of an Offset.
                 * @memberof streem.api.PageToken
                 * @interface IOffset
                 * @property {string|null} [queryParamsHash] Offset queryParamsHash
                 * @property {number|null} [offset] Offset offset
                 */

                /**
                 * Constructs a new Offset.
                 * @memberof streem.api.PageToken
                 * @classdesc Represents an Offset.
                 * @implements IOffset
                 * @constructor
                 * @param {streem.api.PageToken.IOffset=} [p] Properties to set
                 */
                function Offset(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Offset queryParamsHash.
                 * @member {string} queryParamsHash
                 * @memberof streem.api.PageToken.Offset
                 * @instance
                 */
                Offset.prototype.queryParamsHash = "";

                /**
                 * Offset offset.
                 * @member {number} offset
                 * @memberof streem.api.PageToken.Offset
                 * @instance
                 */
                Offset.prototype.offset = 0;

                /**
                 * Creates a new Offset instance using the specified properties.
                 * @function create
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {streem.api.PageToken.IOffset=} [properties] Properties to set
                 * @returns {streem.api.PageToken.Offset} Offset instance
                 */
                Offset.create = function create(properties) {
                    return new Offset(properties);
                };

                /**
                 * Encodes the specified Offset message. Does not implicitly {@link streem.api.PageToken.Offset.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {streem.api.PageToken.IOffset} m Offset message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Offset.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.queryParamsHash != null && Object.hasOwnProperty.call(m, "queryParamsHash"))
                        w.uint32(10).string(m.queryParamsHash);
                    if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                        w.uint32(16).int32(m.offset);
                    return w;
                };

                /**
                 * Decodes an Offset message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.PageToken.Offset} Offset
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Offset.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PageToken.Offset();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.queryParamsHash = r.string();
                                break;
                            }
                        case 2: {
                                m.offset = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Offset message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.PageToken.Offset} Offset
                 */
                Offset.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.PageToken.Offset)
                        return d;
                    var m = new $root.streem.api.PageToken.Offset();
                    if (d.queryParamsHash != null) {
                        m.queryParamsHash = String(d.queryParamsHash);
                    }
                    if (d.offset != null) {
                        m.offset = d.offset | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Offset message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {streem.api.PageToken.Offset} m Offset
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Offset.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.queryParamsHash = "";
                        d.offset = 0;
                    }
                    if (m.queryParamsHash != null && m.hasOwnProperty("queryParamsHash")) {
                        d.queryParamsHash = m.queryParamsHash;
                    }
                    if (m.offset != null && m.hasOwnProperty("offset")) {
                        d.offset = m.offset;
                    }
                    return d;
                };

                /**
                 * Converts this Offset to JSON.
                 * @function toJSON
                 * @memberof streem.api.PageToken.Offset
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Offset.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Offset
                 * @function getTypeUrl
                 * @memberof streem.api.PageToken.Offset
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Offset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.PageToken.Offset";
                };

                return Offset;
            })();

            return PageToken;
        })();

        api.PageTokenAndSize = (function() {

            /**
             * Properties of a PageTokenAndSize.
             * @memberof streem.api
             * @interface IPageTokenAndSize
             * @property {streem.api.IPageToken|null} [pageToken] PageTokenAndSize pageToken
             * @property {number|null} [pageSize] PageTokenAndSize pageSize
             */

            /**
             * Constructs a new PageTokenAndSize.
             * @memberof streem.api
             * @classdesc Represents a PageTokenAndSize.
             * @implements IPageTokenAndSize
             * @constructor
             * @param {streem.api.IPageTokenAndSize=} [p] Properties to set
             */
            function PageTokenAndSize(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PageTokenAndSize pageToken.
             * @member {streem.api.IPageToken|null|undefined} pageToken
             * @memberof streem.api.PageTokenAndSize
             * @instance
             */
            PageTokenAndSize.prototype.pageToken = null;

            /**
             * PageTokenAndSize pageSize.
             * @member {number} pageSize
             * @memberof streem.api.PageTokenAndSize
             * @instance
             */
            PageTokenAndSize.prototype.pageSize = 0;

            /**
             * Creates a new PageTokenAndSize instance using the specified properties.
             * @function create
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {streem.api.IPageTokenAndSize=} [properties] Properties to set
             * @returns {streem.api.PageTokenAndSize} PageTokenAndSize instance
             */
            PageTokenAndSize.create = function create(properties) {
                return new PageTokenAndSize(properties);
            };

            /**
             * Encodes the specified PageTokenAndSize message. Does not implicitly {@link streem.api.PageTokenAndSize.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {streem.api.IPageTokenAndSize} m PageTokenAndSize message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PageTokenAndSize.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    $root.streem.api.PageToken.encode(m.pageToken, w.uint32(10).fork()).ldelim();
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                return w;
            };

            /**
             * Decodes a PageTokenAndSize message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PageTokenAndSize} PageTokenAndSize
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PageTokenAndSize.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PageTokenAndSize();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageToken = $root.streem.api.PageToken.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PageTokenAndSize message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PageTokenAndSize} PageTokenAndSize
             */
            PageTokenAndSize.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PageTokenAndSize)
                    return d;
                var m = new $root.streem.api.PageTokenAndSize();
                if (d.pageToken != null) {
                    if (typeof d.pageToken !== "object")
                        throw TypeError(".streem.api.PageTokenAndSize.pageToken: object expected");
                    m.pageToken = $root.streem.api.PageToken.fromObject(d.pageToken);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a PageTokenAndSize message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {streem.api.PageTokenAndSize} m PageTokenAndSize
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PageTokenAndSize.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageToken = null;
                    d.pageSize = 0;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = $root.streem.api.PageToken.toObject(m.pageToken, o);
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                return d;
            };

            /**
             * Converts this PageTokenAndSize to JSON.
             * @function toJSON
             * @memberof streem.api.PageTokenAndSize
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PageTokenAndSize.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PageTokenAndSize
             * @function getTypeUrl
             * @memberof streem.api.PageTokenAndSize
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PageTokenAndSize.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PageTokenAndSize";
            };

            return PageTokenAndSize;
        })();

        api.Audit = (function() {

            /**
             * Constructs a new Audit service.
             * @memberof streem.api
             * @classdesc Represents an Audit
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Audit(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Audit.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Audit;

            /**
             * Creates new Audit service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Audit
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Audit} RPC service. Useful where requests and/or responses are streamed.
             */
            Audit.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Audit#getAuditLogs}.
             * @memberof streem.api.Audit
             * @typedef GetAuditLogsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetAuditLogsResponse} [response] GetAuditLogsResponse
             */

            /**
             * Calls GetAuditLogs.
             * @function getAuditLogs
             * @memberof streem.api.Audit
             * @instance
             * @param {streem.api.IGetAuditLogsRequest} request GetAuditLogsRequest message or plain object
             * @param {streem.api.Audit.GetAuditLogsCallback} callback Node-style callback called with the error, if any, and GetAuditLogsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Audit.prototype.getAuditLogs = function getAuditLogs(request, callback) {
                return this.rpcCall(getAuditLogs, $root.streem.api.GetAuditLogsRequest, $root.streem.api.GetAuditLogsResponse, request, callback);
            }, "name", { value: "GetAuditLogs" });

            /**
             * Calls GetAuditLogs.
             * @function getAuditLogs
             * @memberof streem.api.Audit
             * @instance
             * @param {streem.api.IGetAuditLogsRequest} request GetAuditLogsRequest message or plain object
             * @returns {Promise<streem.api.GetAuditLogsResponse>} Promise
             * @variation 2
             */

            return Audit;
        })();

        api.GetAuditLogsRequest = (function() {

            /**
             * Properties of a GetAuditLogsRequest.
             * @memberof streem.api
             * @interface IGetAuditLogsRequest
             */

            /**
             * Constructs a new GetAuditLogsRequest.
             * @memberof streem.api
             * @classdesc Represents a GetAuditLogsRequest.
             * @implements IGetAuditLogsRequest
             * @constructor
             * @param {streem.api.IGetAuditLogsRequest=} [p] Properties to set
             */
            function GetAuditLogsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetAuditLogsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {streem.api.IGetAuditLogsRequest=} [properties] Properties to set
             * @returns {streem.api.GetAuditLogsRequest} GetAuditLogsRequest instance
             */
            GetAuditLogsRequest.create = function create(properties) {
                return new GetAuditLogsRequest(properties);
            };

            /**
             * Encodes the specified GetAuditLogsRequest message. Does not implicitly {@link streem.api.GetAuditLogsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {streem.api.IGetAuditLogsRequest} m GetAuditLogsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAuditLogsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetAuditLogsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAuditLogsRequest} GetAuditLogsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAuditLogsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAuditLogsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAuditLogsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAuditLogsRequest} GetAuditLogsRequest
             */
            GetAuditLogsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAuditLogsRequest)
                    return d;
                return new $root.streem.api.GetAuditLogsRequest();
            };

            /**
             * Creates a plain object from a GetAuditLogsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {streem.api.GetAuditLogsRequest} m GetAuditLogsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAuditLogsRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetAuditLogsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetAuditLogsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAuditLogsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAuditLogsRequest
             * @function getTypeUrl
             * @memberof streem.api.GetAuditLogsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAuditLogsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAuditLogsRequest";
            };

            return GetAuditLogsRequest;
        })();

        api.GetAuditLogsResponse = (function() {

            /**
             * Properties of a GetAuditLogsResponse.
             * @memberof streem.api
             * @interface IGetAuditLogsResponse
             * @property {Array.<string>|null} [downloadUrls] GetAuditLogsResponse downloadUrls
             */

            /**
             * Constructs a new GetAuditLogsResponse.
             * @memberof streem.api
             * @classdesc Represents a GetAuditLogsResponse.
             * @implements IGetAuditLogsResponse
             * @constructor
             * @param {streem.api.IGetAuditLogsResponse=} [p] Properties to set
             */
            function GetAuditLogsResponse(p) {
                this.downloadUrls = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetAuditLogsResponse downloadUrls.
             * @member {Array.<string>} downloadUrls
             * @memberof streem.api.GetAuditLogsResponse
             * @instance
             */
            GetAuditLogsResponse.prototype.downloadUrls = $util.emptyArray;

            /**
             * Creates a new GetAuditLogsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {streem.api.IGetAuditLogsResponse=} [properties] Properties to set
             * @returns {streem.api.GetAuditLogsResponse} GetAuditLogsResponse instance
             */
            GetAuditLogsResponse.create = function create(properties) {
                return new GetAuditLogsResponse(properties);
            };

            /**
             * Encodes the specified GetAuditLogsResponse message. Does not implicitly {@link streem.api.GetAuditLogsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {streem.api.IGetAuditLogsResponse} m GetAuditLogsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAuditLogsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.downloadUrls != null && m.downloadUrls.length) {
                    for (var i = 0; i < m.downloadUrls.length; ++i)
                        w.uint32(10).string(m.downloadUrls[i]);
                }
                return w;
            };

            /**
             * Decodes a GetAuditLogsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAuditLogsResponse} GetAuditLogsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAuditLogsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAuditLogsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.downloadUrls && m.downloadUrls.length))
                                m.downloadUrls = [];
                            m.downloadUrls.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAuditLogsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAuditLogsResponse} GetAuditLogsResponse
             */
            GetAuditLogsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAuditLogsResponse)
                    return d;
                var m = new $root.streem.api.GetAuditLogsResponse();
                if (d.downloadUrls) {
                    if (!Array.isArray(d.downloadUrls))
                        throw TypeError(".streem.api.GetAuditLogsResponse.downloadUrls: array expected");
                    m.downloadUrls = [];
                    for (var i = 0; i < d.downloadUrls.length; ++i) {
                        m.downloadUrls[i] = String(d.downloadUrls[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetAuditLogsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {streem.api.GetAuditLogsResponse} m GetAuditLogsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAuditLogsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.downloadUrls = [];
                }
                if (m.downloadUrls && m.downloadUrls.length) {
                    d.downloadUrls = [];
                    for (var j = 0; j < m.downloadUrls.length; ++j) {
                        d.downloadUrls[j] = m.downloadUrls[j];
                    }
                }
                return d;
            };

            /**
             * Converts this GetAuditLogsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetAuditLogsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAuditLogsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAuditLogsResponse
             * @function getTypeUrl
             * @memberof streem.api.GetAuditLogsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAuditLogsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAuditLogsResponse";
            };

            return GetAuditLogsResponse;
        })();

        api.Rooms = (function() {

            /**
             * Constructs a new Rooms service.
             * @memberof streem.api
             * @classdesc Represents a Rooms
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Rooms(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Rooms.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Rooms;

            /**
             * Creates new Rooms service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Rooms
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Rooms} RPC service. Useful where requests and/or responses are streamed.
             */
            Rooms.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Rooms#createReadonlyRoomToken}.
             * @memberof streem.api.Rooms
             * @typedef CreateReadonlyRoomTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateReadonlyRoomTokenResponse} [response] CreateReadonlyRoomTokenResponse
             */

            /**
             * Calls CreateReadonlyRoomToken.
             * @function createReadonlyRoomToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateReadonlyRoomTokenRequest} request CreateReadonlyRoomTokenRequest message or plain object
             * @param {streem.api.Rooms.CreateReadonlyRoomTokenCallback} callback Node-style callback called with the error, if any, and CreateReadonlyRoomTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createReadonlyRoomToken = function createReadonlyRoomToken(request, callback) {
                return this.rpcCall(createReadonlyRoomToken, $root.streem.api.CreateReadonlyRoomTokenRequest, $root.streem.api.CreateReadonlyRoomTokenResponse, request, callback);
            }, "name", { value: "CreateReadonlyRoomToken" });

            /**
             * Calls CreateReadonlyRoomToken.
             * @function createReadonlyRoomToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateReadonlyRoomTokenRequest} request CreateReadonlyRoomTokenRequest message or plain object
             * @returns {Promise<streem.api.CreateReadonlyRoomTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#createRoom}.
             * @memberof streem.api.Rooms
             * @typedef CreateRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomResponse} [response] CreateRoomResponse
             */

            /**
             * Calls CreateRoom.
             * @function createRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomRequest} request CreateRoomRequest message or plain object
             * @param {streem.api.Rooms.CreateRoomCallback} callback Node-style callback called with the error, if any, and CreateRoomResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createRoom = function createRoom(request, callback) {
                return this.rpcCall(createRoom, $root.streem.api.CreateRoomRequest, $root.streem.api.CreateRoomResponse, request, callback);
            }, "name", { value: "CreateRoom" });

            /**
             * Calls CreateRoom.
             * @function createRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomRequest} request CreateRoomRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#createRoomShareToken}.
             * @memberof streem.api.Rooms
             * @typedef CreateRoomShareTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomShareTokenResponse} [response] CreateRoomShareTokenResponse
             */

            /**
             * Calls CreateRoomShareToken.
             * @function createRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomShareTokenRequest} request CreateRoomShareTokenRequest message or plain object
             * @param {streem.api.Rooms.CreateRoomShareTokenCallback} callback Node-style callback called with the error, if any, and CreateRoomShareTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createRoomShareToken = function createRoomShareToken(request, callback) {
                return this.rpcCall(createRoomShareToken, $root.streem.api.CreateRoomShareTokenRequest, $root.streem.api.CreateRoomShareTokenResponse, request, callback);
            }, "name", { value: "CreateRoomShareToken" });

            /**
             * Calls CreateRoomShareToken.
             * @function createRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomShareTokenRequest} request CreateRoomShareTokenRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomShareTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#createRoomToken}.
             * @memberof streem.api.Rooms
             * @typedef CreateRoomTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomTokenResponse} [response] CreateRoomTokenResponse
             */

            /**
             * Calls CreateRoomToken.
             * @function createRoomToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomTokenRequest} request CreateRoomTokenRequest message or plain object
             * @param {streem.api.Rooms.CreateRoomTokenCallback} callback Node-style callback called with the error, if any, and CreateRoomTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createRoomToken = function createRoomToken(request, callback) {
                return this.rpcCall(createRoomToken, $root.streem.api.CreateRoomTokenRequest, $root.streem.api.CreateRoomTokenResponse, request, callback);
            }, "name", { value: "CreateRoomToken" });

            /**
             * Calls CreateRoomToken.
             * @function createRoomToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomTokenRequest} request CreateRoomTokenRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#createRoomVideoCredentials}.
             * @memberof streem.api.Rooms
             * @typedef CreateRoomVideoCredentialsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomVideoCredentialsResponse} [response] CreateRoomVideoCredentialsResponse
             */

            /**
             * Calls CreateRoomVideoCredentials.
             * @function createRoomVideoCredentials
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomVideoCredentialsRequest} request CreateRoomVideoCredentialsRequest message or plain object
             * @param {streem.api.Rooms.CreateRoomVideoCredentialsCallback} callback Node-style callback called with the error, if any, and CreateRoomVideoCredentialsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createRoomVideoCredentials = function createRoomVideoCredentials(request, callback) {
                return this.rpcCall(createRoomVideoCredentials, $root.streem.api.CreateRoomVideoCredentialsRequest, $root.streem.api.CreateRoomVideoCredentialsResponse, request, callback);
            }, "name", { value: "CreateRoomVideoCredentials" });

            /**
             * Calls CreateRoomVideoCredentials.
             * @function createRoomVideoCredentials
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomVideoCredentialsRequest} request CreateRoomVideoCredentialsRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomVideoCredentialsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#createRoomWallCredentials}.
             * @memberof streem.api.Rooms
             * @typedef CreateRoomWallCredentialsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomWallCredentialsResponse} [response] CreateRoomWallCredentialsResponse
             */

            /**
             * Calls CreateRoomWallCredentials.
             * @function createRoomWallCredentials
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomWallCredentialsRequest} request CreateRoomWallCredentialsRequest message or plain object
             * @param {streem.api.Rooms.CreateRoomWallCredentialsCallback} callback Node-style callback called with the error, if any, and CreateRoomWallCredentialsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.createRoomWallCredentials = function createRoomWallCredentials(request, callback) {
                return this.rpcCall(createRoomWallCredentials, $root.streem.api.CreateRoomWallCredentialsRequest, $root.streem.api.CreateRoomWallCredentialsResponse, request, callback);
            }, "name", { value: "CreateRoomWallCredentials" });

            /**
             * Calls CreateRoomWallCredentials.
             * @function createRoomWallCredentials
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ICreateRoomWallCredentialsRequest} request CreateRoomWallCredentialsRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomWallCredentialsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#deleteRoom}.
             * @memberof streem.api.Rooms
             * @typedef DeleteRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteRoom.
             * @function deleteRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IDeleteRoomRequest} request DeleteRoomRequest message or plain object
             * @param {streem.api.Rooms.DeleteRoomCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.deleteRoom = function deleteRoom(request, callback) {
                return this.rpcCall(deleteRoom, $root.streem.api.DeleteRoomRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteRoom" });

            /**
             * Calls DeleteRoom.
             * @function deleteRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IDeleteRoomRequest} request DeleteRoomRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#archiveAndDeleteRoom}.
             * @memberof streem.api.Rooms
             * @typedef ArchiveAndDeleteRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ArchiveAndDeleteRoomResponse} [response] ArchiveAndDeleteRoomResponse
             */

            /**
             * Calls ArchiveAndDeleteRoom.
             * @function archiveAndDeleteRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IArchiveAndDeleteRoomRequest} request ArchiveAndDeleteRoomRequest message or plain object
             * @param {streem.api.Rooms.ArchiveAndDeleteRoomCallback} callback Node-style callback called with the error, if any, and ArchiveAndDeleteRoomResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.archiveAndDeleteRoom = function archiveAndDeleteRoom(request, callback) {
                return this.rpcCall(archiveAndDeleteRoom, $root.streem.api.ArchiveAndDeleteRoomRequest, $root.streem.api.ArchiveAndDeleteRoomResponse, request, callback);
            }, "name", { value: "ArchiveAndDeleteRoom" });

            /**
             * Calls ArchiveAndDeleteRoom.
             * @function archiveAndDeleteRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IArchiveAndDeleteRoomRequest} request ArchiveAndDeleteRoomRequest message or plain object
             * @returns {Promise<streem.api.ArchiveAndDeleteRoomResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#getRoom}.
             * @memberof streem.api.Rooms
             * @typedef GetRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomResponse} [response] GetRoomResponse
             */

            /**
             * Calls GetRoom.
             * @function getRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomRequest} request GetRoomRequest message or plain object
             * @param {streem.api.Rooms.GetRoomCallback} callback Node-style callback called with the error, if any, and GetRoomResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.getRoom = function getRoom(request, callback) {
                return this.rpcCall(getRoom, $root.streem.api.GetRoomRequest, $root.streem.api.GetRoomResponse, request, callback);
            }, "name", { value: "GetRoom" });

            /**
             * Calls GetRoom.
             * @function getRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomRequest} request GetRoomRequest message or plain object
             * @returns {Promise<streem.api.GetRoomResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#getRoomCallLogEntry}.
             * @memberof streem.api.Rooms
             * @typedef GetRoomCallLogEntryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomCallLogEntryResponse} [response] GetRoomCallLogEntryResponse
             */

            /**
             * Calls GetRoomCallLogEntry.
             * @function getRoomCallLogEntry
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomCallLogEntryRequest} request GetRoomCallLogEntryRequest message or plain object
             * @param {streem.api.Rooms.GetRoomCallLogEntryCallback} callback Node-style callback called with the error, if any, and GetRoomCallLogEntryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.getRoomCallLogEntry = function getRoomCallLogEntry(request, callback) {
                return this.rpcCall(getRoomCallLogEntry, $root.streem.api.GetRoomCallLogEntryRequest, $root.streem.api.GetRoomCallLogEntryResponse, request, callback);
            }, "name", { value: "GetRoomCallLogEntry" });

            /**
             * Calls GetRoomCallLogEntry.
             * @function getRoomCallLogEntry
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomCallLogEntryRequest} request GetRoomCallLogEntryRequest message or plain object
             * @returns {Promise<streem.api.GetRoomCallLogEntryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#getRoomShareToken}.
             * @memberof streem.api.Rooms
             * @typedef GetRoomShareTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomShareTokenResponse} [response] GetRoomShareTokenResponse
             */

            /**
             * Calls GetRoomShareToken.
             * @function getRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomShareTokenRequest} request GetRoomShareTokenRequest message or plain object
             * @param {streem.api.Rooms.GetRoomShareTokenCallback} callback Node-style callback called with the error, if any, and GetRoomShareTokenResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.getRoomShareToken = function getRoomShareToken(request, callback) {
                return this.rpcCall(getRoomShareToken, $root.streem.api.GetRoomShareTokenRequest, $root.streem.api.GetRoomShareTokenResponse, request, callback);
            }, "name", { value: "GetRoomShareToken" });

            /**
             * Calls GetRoomShareToken.
             * @function getRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomShareTokenRequest} request GetRoomShareTokenRequest message or plain object
             * @returns {Promise<streem.api.GetRoomShareTokenResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#getRoomWallItems}.
             * @memberof streem.api.Rooms
             * @typedef GetRoomWallItemsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomWallItemsResponse} [response] GetRoomWallItemsResponse
             */

            /**
             * Calls GetRoomWallItems.
             * @function getRoomWallItems
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomWallItemsRequest} request GetRoomWallItemsRequest message or plain object
             * @param {streem.api.Rooms.GetRoomWallItemsCallback} callback Node-style callback called with the error, if any, and GetRoomWallItemsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.getRoomWallItems = function getRoomWallItems(request, callback) {
                return this.rpcCall(getRoomWallItems, $root.streem.api.GetRoomWallItemsRequest, $root.streem.api.GetRoomWallItemsResponse, request, callback);
            }, "name", { value: "GetRoomWallItems" });

            /**
             * Calls GetRoomWallItems.
             * @function getRoomWallItems
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IGetRoomWallItemsRequest} request GetRoomWallItemsRequest message or plain object
             * @returns {Promise<streem.api.GetRoomWallItemsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#leaveRoom}.
             * @memberof streem.api.Rooms
             * @typedef LeaveRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.LeaveRoomResponse} [response] LeaveRoomResponse
             */

            /**
             * Calls LeaveRoom.
             * @function leaveRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ILeaveRoomRequest} request LeaveRoomRequest message or plain object
             * @param {streem.api.Rooms.LeaveRoomCallback} callback Node-style callback called with the error, if any, and LeaveRoomResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.leaveRoom = function leaveRoom(request, callback) {
                return this.rpcCall(leaveRoom, $root.streem.api.LeaveRoomRequest, $root.streem.api.LeaveRoomResponse, request, callback);
            }, "name", { value: "LeaveRoom" });

            /**
             * Calls LeaveRoom.
             * @function leaveRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.ILeaveRoomRequest} request LeaveRoomRequest message or plain object
             * @returns {Promise<streem.api.LeaveRoomResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#listRooms}.
             * @memberof streem.api.Rooms
             * @typedef ListRoomsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRoomsResponse} [response] ListRoomsResponse
             */

            /**
             * Calls ListRooms.
             * @function listRooms
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomsRequest} request ListRoomsRequest message or plain object
             * @param {streem.api.Rooms.ListRoomsCallback} callback Node-style callback called with the error, if any, and ListRoomsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.listRooms = function listRooms(request, callback) {
                return this.rpcCall(listRooms, $root.streem.api.ListRoomsRequest, $root.streem.api.ListRoomsResponse, request, callback);
            }, "name", { value: "ListRooms" });

            /**
             * Calls ListRooms.
             * @function listRooms
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomsRequest} request ListRoomsRequest message or plain object
             * @returns {Promise<streem.api.ListRoomsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#listRoomParticipants}.
             * @memberof streem.api.Rooms
             * @typedef ListRoomParticipantsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRoomParticipantsResponse} [response] ListRoomParticipantsResponse
             */

            /**
             * Calls ListRoomParticipants.
             * @function listRoomParticipants
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomParticipantsRequest} request ListRoomParticipantsRequest message or plain object
             * @param {streem.api.Rooms.ListRoomParticipantsCallback} callback Node-style callback called with the error, if any, and ListRoomParticipantsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.listRoomParticipants = function listRoomParticipants(request, callback) {
                return this.rpcCall(listRoomParticipants, $root.streem.api.ListRoomParticipantsRequest, $root.streem.api.ListRoomParticipantsResponse, request, callback);
            }, "name", { value: "ListRoomParticipants" });

            /**
             * Calls ListRoomParticipants.
             * @function listRoomParticipants
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomParticipantsRequest} request ListRoomParticipantsRequest message or plain object
             * @returns {Promise<streem.api.ListRoomParticipantsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#listRoomShareTokens}.
             * @memberof streem.api.Rooms
             * @typedef ListRoomShareTokensCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRoomShareTokensResponse} [response] ListRoomShareTokensResponse
             */

            /**
             * Calls ListRoomShareTokens.
             * @function listRoomShareTokens
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomShareTokensRequest} request ListRoomShareTokensRequest message or plain object
             * @param {streem.api.Rooms.ListRoomShareTokensCallback} callback Node-style callback called with the error, if any, and ListRoomShareTokensResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.listRoomShareTokens = function listRoomShareTokens(request, callback) {
                return this.rpcCall(listRoomShareTokens, $root.streem.api.ListRoomShareTokensRequest, $root.streem.api.ListRoomShareTokensResponse, request, callback);
            }, "name", { value: "ListRoomShareTokens" });

            /**
             * Calls ListRoomShareTokens.
             * @function listRoomShareTokens
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IListRoomShareTokensRequest} request ListRoomShareTokensRequest message or plain object
             * @returns {Promise<streem.api.ListRoomShareTokensResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#pauseRoomRecording}.
             * @memberof streem.api.Rooms
             * @typedef PauseRoomRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.PauseRoomRecordingResponse} [response] PauseRoomRecordingResponse
             */

            /**
             * Calls PauseRoomRecording.
             * @function pauseRoomRecording
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IPauseRoomRecordingRequest} request PauseRoomRecordingRequest message or plain object
             * @param {streem.api.Rooms.PauseRoomRecordingCallback} callback Node-style callback called with the error, if any, and PauseRoomRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.pauseRoomRecording = function pauseRoomRecording(request, callback) {
                return this.rpcCall(pauseRoomRecording, $root.streem.api.PauseRoomRecordingRequest, $root.streem.api.PauseRoomRecordingResponse, request, callback);
            }, "name", { value: "PauseRoomRecording" });

            /**
             * Calls PauseRoomRecording.
             * @function pauseRoomRecording
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IPauseRoomRecordingRequest} request PauseRoomRecordingRequest message or plain object
             * @returns {Promise<streem.api.PauseRoomRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#resumeRoomRecording}.
             * @memberof streem.api.Rooms
             * @typedef ResumeRoomRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ResumeRoomRecordingResponse} [response] ResumeRoomRecordingResponse
             */

            /**
             * Calls ResumeRoomRecording.
             * @function resumeRoomRecording
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IResumeRoomRecordingRequest} request ResumeRoomRecordingRequest message or plain object
             * @param {streem.api.Rooms.ResumeRoomRecordingCallback} callback Node-style callback called with the error, if any, and ResumeRoomRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.resumeRoomRecording = function resumeRoomRecording(request, callback) {
                return this.rpcCall(resumeRoomRecording, $root.streem.api.ResumeRoomRecordingRequest, $root.streem.api.ResumeRoomRecordingResponse, request, callback);
            }, "name", { value: "ResumeRoomRecording" });

            /**
             * Calls ResumeRoomRecording.
             * @function resumeRoomRecording
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IResumeRoomRecordingRequest} request ResumeRoomRecordingRequest message or plain object
             * @returns {Promise<streem.api.ResumeRoomRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#revokeRoomShareToken}.
             * @memberof streem.api.Rooms
             * @typedef RevokeRoomShareTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls RevokeRoomShareToken.
             * @function revokeRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IRevokeRoomShareTokenRequest} request RevokeRoomShareTokenRequest message or plain object
             * @param {streem.api.Rooms.RevokeRoomShareTokenCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.revokeRoomShareToken = function revokeRoomShareToken(request, callback) {
                return this.rpcCall(revokeRoomShareToken, $root.streem.api.RevokeRoomShareTokenRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "RevokeRoomShareToken" });

            /**
             * Calls RevokeRoomShareToken.
             * @function revokeRoomShareToken
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IRevokeRoomShareTokenRequest} request RevokeRoomShareTokenRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Rooms#updateRoom}.
             * @memberof streem.api.Rooms
             * @typedef UpdateRoomCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateRoomResponse} [response] UpdateRoomResponse
             */

            /**
             * Calls UpdateRoom.
             * @function updateRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IUpdateRoomRequest} request UpdateRoomRequest message or plain object
             * @param {streem.api.Rooms.UpdateRoomCallback} callback Node-style callback called with the error, if any, and UpdateRoomResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Rooms.prototype.updateRoom = function updateRoom(request, callback) {
                return this.rpcCall(updateRoom, $root.streem.api.UpdateRoomRequest, $root.streem.api.UpdateRoomResponse, request, callback);
            }, "name", { value: "UpdateRoom" });

            /**
             * Calls UpdateRoom.
             * @function updateRoom
             * @memberof streem.api.Rooms
             * @instance
             * @param {streem.api.IUpdateRoomRequest} request UpdateRoomRequest message or plain object
             * @returns {Promise<streem.api.UpdateRoomResponse>} Promise
             * @variation 2
             */

            return Rooms;
        })();

        api.CreateReadonlyRoomTokenRequest = (function() {

            /**
             * Properties of a CreateReadonlyRoomTokenRequest.
             * @memberof streem.api
             * @interface ICreateReadonlyRoomTokenRequest
             * @property {string|null} [roomSid] CreateReadonlyRoomTokenRequest roomSid
             */

            /**
             * Constructs a new CreateReadonlyRoomTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateReadonlyRoomTokenRequest.
             * @implements ICreateReadonlyRoomTokenRequest
             * @constructor
             * @param {streem.api.ICreateReadonlyRoomTokenRequest=} [p] Properties to set
             */
            function CreateReadonlyRoomTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateReadonlyRoomTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @instance
             */
            CreateReadonlyRoomTokenRequest.prototype.roomSid = "";

            /**
             * Creates a new CreateReadonlyRoomTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {streem.api.ICreateReadonlyRoomTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateReadonlyRoomTokenRequest} CreateReadonlyRoomTokenRequest instance
             */
            CreateReadonlyRoomTokenRequest.create = function create(properties) {
                return new CreateReadonlyRoomTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateReadonlyRoomTokenRequest message. Does not implicitly {@link streem.api.CreateReadonlyRoomTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {streem.api.ICreateReadonlyRoomTokenRequest} m CreateReadonlyRoomTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateReadonlyRoomTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a CreateReadonlyRoomTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateReadonlyRoomTokenRequest} CreateReadonlyRoomTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateReadonlyRoomTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateReadonlyRoomTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateReadonlyRoomTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateReadonlyRoomTokenRequest} CreateReadonlyRoomTokenRequest
             */
            CreateReadonlyRoomTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateReadonlyRoomTokenRequest)
                    return d;
                var m = new $root.streem.api.CreateReadonlyRoomTokenRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateReadonlyRoomTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {streem.api.CreateReadonlyRoomTokenRequest} m CreateReadonlyRoomTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateReadonlyRoomTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this CreateReadonlyRoomTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateReadonlyRoomTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateReadonlyRoomTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateReadonlyRoomTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateReadonlyRoomTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateReadonlyRoomTokenRequest";
            };

            return CreateReadonlyRoomTokenRequest;
        })();

        api.CreateReadonlyRoomTokenResponse = (function() {

            /**
             * Properties of a CreateReadonlyRoomTokenResponse.
             * @memberof streem.api
             * @interface ICreateReadonlyRoomTokenResponse
             * @property {streem.api.IStreemRoomCredentials|null} [streemRoomCredentials] CreateReadonlyRoomTokenResponse streemRoomCredentials
             */

            /**
             * Constructs a new CreateReadonlyRoomTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateReadonlyRoomTokenResponse.
             * @implements ICreateReadonlyRoomTokenResponse
             * @constructor
             * @param {streem.api.ICreateReadonlyRoomTokenResponse=} [p] Properties to set
             */
            function CreateReadonlyRoomTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateReadonlyRoomTokenResponse streemRoomCredentials.
             * @member {streem.api.IStreemRoomCredentials|null|undefined} streemRoomCredentials
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @instance
             */
            CreateReadonlyRoomTokenResponse.prototype.streemRoomCredentials = null;

            /**
             * Creates a new CreateReadonlyRoomTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {streem.api.ICreateReadonlyRoomTokenResponse=} [properties] Properties to set
             * @returns {streem.api.CreateReadonlyRoomTokenResponse} CreateReadonlyRoomTokenResponse instance
             */
            CreateReadonlyRoomTokenResponse.create = function create(properties) {
                return new CreateReadonlyRoomTokenResponse(properties);
            };

            /**
             * Encodes the specified CreateReadonlyRoomTokenResponse message. Does not implicitly {@link streem.api.CreateReadonlyRoomTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {streem.api.ICreateReadonlyRoomTokenResponse} m CreateReadonlyRoomTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateReadonlyRoomTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemRoomCredentials != null && Object.hasOwnProperty.call(m, "streemRoomCredentials"))
                    $root.streem.api.StreemRoomCredentials.encode(m.streemRoomCredentials, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateReadonlyRoomTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateReadonlyRoomTokenResponse} CreateReadonlyRoomTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateReadonlyRoomTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateReadonlyRoomTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateReadonlyRoomTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateReadonlyRoomTokenResponse} CreateReadonlyRoomTokenResponse
             */
            CreateReadonlyRoomTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateReadonlyRoomTokenResponse)
                    return d;
                var m = new $root.streem.api.CreateReadonlyRoomTokenResponse();
                if (d.streemRoomCredentials != null) {
                    if (typeof d.streemRoomCredentials !== "object")
                        throw TypeError(".streem.api.CreateReadonlyRoomTokenResponse.streemRoomCredentials: object expected");
                    m.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.fromObject(d.streemRoomCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateReadonlyRoomTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {streem.api.CreateReadonlyRoomTokenResponse} m CreateReadonlyRoomTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateReadonlyRoomTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemRoomCredentials = null;
                }
                if (m.streemRoomCredentials != null && m.hasOwnProperty("streemRoomCredentials")) {
                    d.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.toObject(m.streemRoomCredentials, o);
                }
                return d;
            };

            /**
             * Converts this CreateReadonlyRoomTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateReadonlyRoomTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateReadonlyRoomTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateReadonlyRoomTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateReadonlyRoomTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateReadonlyRoomTokenResponse";
            };

            return CreateReadonlyRoomTokenResponse;
        })();

        api.CreateRoomRequest = (function() {

            /**
             * Properties of a CreateRoomRequest.
             * @memberof streem.api
             * @interface ICreateRoomRequest
             * @property {string|null} [createdByUserSid] CreateRoomRequest createdByUserSid
             * @property {streem.api.VideoCodec|null} [preferredCodec] CreateRoomRequest preferredCodec
             * @property {string|null} [referenceId] CreateRoomRequest referenceId
             * @property {string|null} [integrationId] CreateRoomRequest integrationId
             */

            /**
             * Constructs a new CreateRoomRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomRequest.
             * @implements ICreateRoomRequest
             * @constructor
             * @param {streem.api.ICreateRoomRequest=} [p] Properties to set
             */
            function CreateRoomRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomRequest createdByUserSid.
             * @member {string} createdByUserSid
             * @memberof streem.api.CreateRoomRequest
             * @instance
             */
            CreateRoomRequest.prototype.createdByUserSid = "";

            /**
             * CreateRoomRequest preferredCodec.
             * @member {streem.api.VideoCodec} preferredCodec
             * @memberof streem.api.CreateRoomRequest
             * @instance
             */
            CreateRoomRequest.prototype.preferredCodec = 0;

            /**
             * CreateRoomRequest referenceId.
             * @member {string} referenceId
             * @memberof streem.api.CreateRoomRequest
             * @instance
             */
            CreateRoomRequest.prototype.referenceId = "";

            /**
             * CreateRoomRequest integrationId.
             * @member {string} integrationId
             * @memberof streem.api.CreateRoomRequest
             * @instance
             */
            CreateRoomRequest.prototype.integrationId = "";

            /**
             * Creates a new CreateRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {streem.api.ICreateRoomRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomRequest} CreateRoomRequest instance
             */
            CreateRoomRequest.create = function create(properties) {
                return new CreateRoomRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomRequest message. Does not implicitly {@link streem.api.CreateRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {streem.api.ICreateRoomRequest} m CreateRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.createdByUserSid != null && Object.hasOwnProperty.call(m, "createdByUserSid"))
                    w.uint32(10).string(m.createdByUserSid);
                if (m.preferredCodec != null && Object.hasOwnProperty.call(m, "preferredCodec"))
                    w.uint32(16).int32(m.preferredCodec);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(26).string(m.referenceId);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(34).string(m.integrationId);
                return w;
            };

            /**
             * Decodes a CreateRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomRequest} CreateRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.createdByUserSid = r.string();
                            break;
                        }
                    case 2: {
                            m.preferredCodec = r.int32();
                            break;
                        }
                    case 3: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 4: {
                            m.integrationId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomRequest} CreateRoomRequest
             */
            CreateRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomRequest();
                if (d.createdByUserSid != null) {
                    m.createdByUserSid = String(d.createdByUserSid);
                }
                switch (d.preferredCodec) {
                default:
                    if (typeof d.preferredCodec === "number") {
                        m.preferredCodec = d.preferredCodec;
                        break;
                    }
                    break;
                case "VIDEO_CODEC_INVALID":
                case 0:
                    m.preferredCodec = 0;
                    break;
                case "VIDEO_CODEC_H264":
                case 1:
                    m.preferredCodec = 1;
                    break;
                case "VIDEO_CODEC_VP8":
                case 2:
                    m.preferredCodec = 2;
                    break;
                case "VIDEO_CODEC_VP9":
                case 3:
                    m.preferredCodec = 3;
                    break;
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {streem.api.CreateRoomRequest} m CreateRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.createdByUserSid = "";
                    d.preferredCodec = o.enums === String ? "VIDEO_CODEC_INVALID" : 0;
                    d.referenceId = "";
                    d.integrationId = "";
                }
                if (m.createdByUserSid != null && m.hasOwnProperty("createdByUserSid")) {
                    d.createdByUserSid = m.createdByUserSid;
                }
                if (m.preferredCodec != null && m.hasOwnProperty("preferredCodec")) {
                    d.preferredCodec = o.enums === String ? $root.streem.api.VideoCodec[m.preferredCodec] === undefined ? m.preferredCodec : $root.streem.api.VideoCodec[m.preferredCodec] : m.preferredCodec;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                return d;
            };

            /**
             * Converts this CreateRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomRequest";
            };

            return CreateRoomRequest;
        })();

        api.CreateRoomResponse = (function() {

            /**
             * Properties of a CreateRoomResponse.
             * @memberof streem.api
             * @interface ICreateRoomResponse
             * @property {streem.api.IRoom|null} [room] CreateRoomResponse room
             */

            /**
             * Constructs a new CreateRoomResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomResponse.
             * @implements ICreateRoomResponse
             * @constructor
             * @param {streem.api.ICreateRoomResponse=} [p] Properties to set
             */
            function CreateRoomResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomResponse room.
             * @member {streem.api.IRoom|null|undefined} room
             * @memberof streem.api.CreateRoomResponse
             * @instance
             */
            CreateRoomResponse.prototype.room = null;

            /**
             * Creates a new CreateRoomResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {streem.api.ICreateRoomResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomResponse} CreateRoomResponse instance
             */
            CreateRoomResponse.create = function create(properties) {
                return new CreateRoomResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomResponse message. Does not implicitly {@link streem.api.CreateRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {streem.api.ICreateRoomResponse} m CreateRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                    $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomResponse} CreateRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.room = $root.streem.api.Room.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomResponse} CreateRoomResponse
             */
            CreateRoomResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomResponse();
                if (d.room != null) {
                    if (typeof d.room !== "object")
                        throw TypeError(".streem.api.CreateRoomResponse.room: object expected");
                    m.room = $root.streem.api.Room.fromObject(d.room);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {streem.api.CreateRoomResponse} m CreateRoomResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.room = null;
                }
                if (m.room != null && m.hasOwnProperty("room")) {
                    d.room = $root.streem.api.Room.toObject(m.room, o);
                }
                return d;
            };

            /**
             * Converts this CreateRoomResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomResponse";
            };

            return CreateRoomResponse;
        })();

        api.CreateRoomShareTokenRequest = (function() {

            /**
             * Properties of a CreateRoomShareTokenRequest.
             * @memberof streem.api
             * @interface ICreateRoomShareTokenRequest
             * @property {string|null} [roomSid] CreateRoomShareTokenRequest roomSid
             * @property {google.protobuf.ITimestamp|null} [expiresAt] CreateRoomShareTokenRequest expiresAt
             * @property {string|null} [label] CreateRoomShareTokenRequest label
             */

            /**
             * Constructs a new CreateRoomShareTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomShareTokenRequest.
             * @implements ICreateRoomShareTokenRequest
             * @constructor
             * @param {streem.api.ICreateRoomShareTokenRequest=} [p] Properties to set
             */
            function CreateRoomShareTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomShareTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @instance
             */
            CreateRoomShareTokenRequest.prototype.roomSid = "";

            /**
             * CreateRoomShareTokenRequest expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @instance
             */
            CreateRoomShareTokenRequest.prototype.expiresAt = null;

            /**
             * CreateRoomShareTokenRequest label.
             * @member {string} label
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @instance
             */
            CreateRoomShareTokenRequest.prototype.label = "";

            /**
             * Creates a new CreateRoomShareTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {streem.api.ICreateRoomShareTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomShareTokenRequest} CreateRoomShareTokenRequest instance
             */
            CreateRoomShareTokenRequest.create = function create(properties) {
                return new CreateRoomShareTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomShareTokenRequest message. Does not implicitly {@link streem.api.CreateRoomShareTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {streem.api.ICreateRoomShareTokenRequest} m CreateRoomShareTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomShareTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(18).fork()).ldelim();
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(26).string(m.label);
                return w;
            };

            /**
             * Decodes a CreateRoomShareTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomShareTokenRequest} CreateRoomShareTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomShareTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomShareTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.label = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomShareTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomShareTokenRequest} CreateRoomShareTokenRequest
             */
            CreateRoomShareTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomShareTokenRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomShareTokenRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.CreateRoomShareTokenRequest.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomShareTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {streem.api.CreateRoomShareTokenRequest} m CreateRoomShareTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomShareTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.expiresAt = null;
                    d.label = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                return d;
            };

            /**
             * Converts this CreateRoomShareTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomShareTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomShareTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomShareTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomShareTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomShareTokenRequest";
            };

            return CreateRoomShareTokenRequest;
        })();

        api.CreateRoomShareTokenResponse = (function() {

            /**
             * Properties of a CreateRoomShareTokenResponse.
             * @memberof streem.api
             * @interface ICreateRoomShareTokenResponse
             * @property {streem.api.IRoomShareToken|null} [shareToken] CreateRoomShareTokenResponse shareToken
             */

            /**
             * Constructs a new CreateRoomShareTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomShareTokenResponse.
             * @implements ICreateRoomShareTokenResponse
             * @constructor
             * @param {streem.api.ICreateRoomShareTokenResponse=} [p] Properties to set
             */
            function CreateRoomShareTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomShareTokenResponse shareToken.
             * @member {streem.api.IRoomShareToken|null|undefined} shareToken
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @instance
             */
            CreateRoomShareTokenResponse.prototype.shareToken = null;

            /**
             * Creates a new CreateRoomShareTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {streem.api.ICreateRoomShareTokenResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomShareTokenResponse} CreateRoomShareTokenResponse instance
             */
            CreateRoomShareTokenResponse.create = function create(properties) {
                return new CreateRoomShareTokenResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomShareTokenResponse message. Does not implicitly {@link streem.api.CreateRoomShareTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {streem.api.ICreateRoomShareTokenResponse} m CreateRoomShareTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomShareTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.shareToken != null && Object.hasOwnProperty.call(m, "shareToken"))
                    $root.streem.api.RoomShareToken.encode(m.shareToken, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomShareTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomShareTokenResponse} CreateRoomShareTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomShareTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomShareTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.shareToken = $root.streem.api.RoomShareToken.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomShareTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomShareTokenResponse} CreateRoomShareTokenResponse
             */
            CreateRoomShareTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomShareTokenResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomShareTokenResponse();
                if (d.shareToken != null) {
                    if (typeof d.shareToken !== "object")
                        throw TypeError(".streem.api.CreateRoomShareTokenResponse.shareToken: object expected");
                    m.shareToken = $root.streem.api.RoomShareToken.fromObject(d.shareToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomShareTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {streem.api.CreateRoomShareTokenResponse} m CreateRoomShareTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomShareTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.shareToken = null;
                }
                if (m.shareToken != null && m.hasOwnProperty("shareToken")) {
                    d.shareToken = $root.streem.api.RoomShareToken.toObject(m.shareToken, o);
                }
                return d;
            };

            /**
             * Converts this CreateRoomShareTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomShareTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomShareTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomShareTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomShareTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomShareTokenResponse";
            };

            return CreateRoomShareTokenResponse;
        })();

        api.CreateRoomTokenRequest = (function() {

            /**
             * Properties of a CreateRoomTokenRequest.
             * @memberof streem.api
             * @interface ICreateRoomTokenRequest
             * @property {string|null} [roomSid] CreateRoomTokenRequest roomSid
             * @property {string|null} [userSid] CreateRoomTokenRequest userSid
             * @property {boolean|null} [preflight] CreateRoomTokenRequest preflight
             */

            /**
             * Constructs a new CreateRoomTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomTokenRequest.
             * @implements ICreateRoomTokenRequest
             * @constructor
             * @param {streem.api.ICreateRoomTokenRequest=} [p] Properties to set
             */
            function CreateRoomTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomTokenRequest
             * @instance
             */
            CreateRoomTokenRequest.prototype.roomSid = "";

            /**
             * CreateRoomTokenRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateRoomTokenRequest
             * @instance
             */
            CreateRoomTokenRequest.prototype.userSid = "";

            /**
             * CreateRoomTokenRequest preflight.
             * @member {boolean} preflight
             * @memberof streem.api.CreateRoomTokenRequest
             * @instance
             */
            CreateRoomTokenRequest.prototype.preflight = false;

            /**
             * Creates a new CreateRoomTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {streem.api.ICreateRoomTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomTokenRequest} CreateRoomTokenRequest instance
             */
            CreateRoomTokenRequest.create = function create(properties) {
                return new CreateRoomTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomTokenRequest message. Does not implicitly {@link streem.api.CreateRoomTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {streem.api.ICreateRoomTokenRequest} m CreateRoomTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(18).string(m.userSid);
                if (m.preflight != null && Object.hasOwnProperty.call(m, "preflight"))
                    w.uint32(24).bool(m.preflight);
                return w;
            };

            /**
             * Decodes a CreateRoomTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomTokenRequest} CreateRoomTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userSid = r.string();
                            break;
                        }
                    case 3: {
                            m.preflight = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomTokenRequest} CreateRoomTokenRequest
             */
            CreateRoomTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomTokenRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomTokenRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.preflight != null) {
                    m.preflight = Boolean(d.preflight);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {streem.api.CreateRoomTokenRequest} m CreateRoomTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.userSid = "";
                    d.preflight = false;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.preflight != null && m.hasOwnProperty("preflight")) {
                    d.preflight = m.preflight;
                }
                return d;
            };

            /**
             * Converts this CreateRoomTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomTokenRequest";
            };

            return CreateRoomTokenRequest;
        })();

        api.CreateRoomTokenResponse = (function() {

            /**
             * Properties of a CreateRoomTokenResponse.
             * @memberof streem.api
             * @interface ICreateRoomTokenResponse
             * @property {streem.api.IStreemRoomCredentials|null} [streemRoomCredentials] CreateRoomTokenResponse streemRoomCredentials
             */

            /**
             * Constructs a new CreateRoomTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomTokenResponse.
             * @implements ICreateRoomTokenResponse
             * @constructor
             * @param {streem.api.ICreateRoomTokenResponse=} [p] Properties to set
             */
            function CreateRoomTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomTokenResponse streemRoomCredentials.
             * @member {streem.api.IStreemRoomCredentials|null|undefined} streemRoomCredentials
             * @memberof streem.api.CreateRoomTokenResponse
             * @instance
             */
            CreateRoomTokenResponse.prototype.streemRoomCredentials = null;

            /**
             * Creates a new CreateRoomTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {streem.api.ICreateRoomTokenResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomTokenResponse} CreateRoomTokenResponse instance
             */
            CreateRoomTokenResponse.create = function create(properties) {
                return new CreateRoomTokenResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomTokenResponse message. Does not implicitly {@link streem.api.CreateRoomTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {streem.api.ICreateRoomTokenResponse} m CreateRoomTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemRoomCredentials != null && Object.hasOwnProperty.call(m, "streemRoomCredentials"))
                    $root.streem.api.StreemRoomCredentials.encode(m.streemRoomCredentials, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomTokenResponse} CreateRoomTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomTokenResponse} CreateRoomTokenResponse
             */
            CreateRoomTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomTokenResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomTokenResponse();
                if (d.streemRoomCredentials != null) {
                    if (typeof d.streemRoomCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomTokenResponse.streemRoomCredentials: object expected");
                    m.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.fromObject(d.streemRoomCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {streem.api.CreateRoomTokenResponse} m CreateRoomTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemRoomCredentials = null;
                }
                if (m.streemRoomCredentials != null && m.hasOwnProperty("streemRoomCredentials")) {
                    d.streemRoomCredentials = $root.streem.api.StreemRoomCredentials.toObject(m.streemRoomCredentials, o);
                }
                return d;
            };

            /**
             * Converts this CreateRoomTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomTokenResponse";
            };

            return CreateRoomTokenResponse;
        })();

        api.CreateRoomVideoCredentialsRequest = (function() {

            /**
             * Properties of a CreateRoomVideoCredentialsRequest.
             * @memberof streem.api
             * @interface ICreateRoomVideoCredentialsRequest
             * @property {string|null} [roomSid] CreateRoomVideoCredentialsRequest roomSid
             * @property {string|null} [userSid] CreateRoomVideoCredentialsRequest userSid
             * @property {boolean|null} [preflight] CreateRoomVideoCredentialsRequest preflight
             * @property {string|null} [mediaRegion] CreateRoomVideoCredentialsRequest mediaRegion
             */

            /**
             * Constructs a new CreateRoomVideoCredentialsRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomVideoCredentialsRequest.
             * @implements ICreateRoomVideoCredentialsRequest
             * @constructor
             * @param {streem.api.ICreateRoomVideoCredentialsRequest=} [p] Properties to set
             */
            function CreateRoomVideoCredentialsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomVideoCredentialsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @instance
             */
            CreateRoomVideoCredentialsRequest.prototype.roomSid = "";

            /**
             * CreateRoomVideoCredentialsRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @instance
             */
            CreateRoomVideoCredentialsRequest.prototype.userSid = "";

            /**
             * CreateRoomVideoCredentialsRequest preflight.
             * @member {boolean} preflight
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @instance
             */
            CreateRoomVideoCredentialsRequest.prototype.preflight = false;

            /**
             * CreateRoomVideoCredentialsRequest mediaRegion.
             * @member {string} mediaRegion
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @instance
             */
            CreateRoomVideoCredentialsRequest.prototype.mediaRegion = "";

            /**
             * Creates a new CreateRoomVideoCredentialsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {streem.api.ICreateRoomVideoCredentialsRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomVideoCredentialsRequest} CreateRoomVideoCredentialsRequest instance
             */
            CreateRoomVideoCredentialsRequest.create = function create(properties) {
                return new CreateRoomVideoCredentialsRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomVideoCredentialsRequest message. Does not implicitly {@link streem.api.CreateRoomVideoCredentialsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {streem.api.ICreateRoomVideoCredentialsRequest} m CreateRoomVideoCredentialsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomVideoCredentialsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(18).string(m.userSid);
                if (m.preflight != null && Object.hasOwnProperty.call(m, "preflight"))
                    w.uint32(24).bool(m.preflight);
                if (m.mediaRegion != null && Object.hasOwnProperty.call(m, "mediaRegion"))
                    w.uint32(34).string(m.mediaRegion);
                return w;
            };

            /**
             * Decodes a CreateRoomVideoCredentialsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomVideoCredentialsRequest} CreateRoomVideoCredentialsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomVideoCredentialsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomVideoCredentialsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userSid = r.string();
                            break;
                        }
                    case 3: {
                            m.preflight = r.bool();
                            break;
                        }
                    case 4: {
                            m.mediaRegion = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomVideoCredentialsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomVideoCredentialsRequest} CreateRoomVideoCredentialsRequest
             */
            CreateRoomVideoCredentialsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomVideoCredentialsRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomVideoCredentialsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.preflight != null) {
                    m.preflight = Boolean(d.preflight);
                }
                if (d.mediaRegion != null) {
                    m.mediaRegion = String(d.mediaRegion);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomVideoCredentialsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {streem.api.CreateRoomVideoCredentialsRequest} m CreateRoomVideoCredentialsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomVideoCredentialsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.userSid = "";
                    d.preflight = false;
                    d.mediaRegion = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.preflight != null && m.hasOwnProperty("preflight")) {
                    d.preflight = m.preflight;
                }
                if (m.mediaRegion != null && m.hasOwnProperty("mediaRegion")) {
                    d.mediaRegion = m.mediaRegion;
                }
                return d;
            };

            /**
             * Converts this CreateRoomVideoCredentialsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomVideoCredentialsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomVideoCredentialsRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomVideoCredentialsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomVideoCredentialsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomVideoCredentialsRequest";
            };

            return CreateRoomVideoCredentialsRequest;
        })();

        api.CreateRoomVideoCredentialsResponse = (function() {

            /**
             * Properties of a CreateRoomVideoCredentialsResponse.
             * @memberof streem.api
             * @interface ICreateRoomVideoCredentialsResponse
             * @property {string|null} [roomSid] CreateRoomVideoCredentialsResponse roomSid
             * @property {streem.api.ITwilioRoomVideoCredentials|null} [twilioRoomVideoCredentials] CreateRoomVideoCredentialsResponse twilioRoomVideoCredentials
             * @property {streem.api.IAraasRoomVideoCredentials|null} [araasRoomVideoCredentials] CreateRoomVideoCredentialsResponse araasRoomVideoCredentials
             * @property {streem.api.IChimeRoomVideoCredentials|null} [chimeRoomVideoCredentials] CreateRoomVideoCredentialsResponse chimeRoomVideoCredentials
             */

            /**
             * Constructs a new CreateRoomVideoCredentialsResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomVideoCredentialsResponse.
             * @implements ICreateRoomVideoCredentialsResponse
             * @constructor
             * @param {streem.api.ICreateRoomVideoCredentialsResponse=} [p] Properties to set
             */
            function CreateRoomVideoCredentialsResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomVideoCredentialsResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             */
            CreateRoomVideoCredentialsResponse.prototype.roomSid = "";

            /**
             * CreateRoomVideoCredentialsResponse twilioRoomVideoCredentials.
             * @member {streem.api.ITwilioRoomVideoCredentials|null|undefined} twilioRoomVideoCredentials
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             */
            CreateRoomVideoCredentialsResponse.prototype.twilioRoomVideoCredentials = null;

            /**
             * CreateRoomVideoCredentialsResponse araasRoomVideoCredentials.
             * @member {streem.api.IAraasRoomVideoCredentials|null|undefined} araasRoomVideoCredentials
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             */
            CreateRoomVideoCredentialsResponse.prototype.araasRoomVideoCredentials = null;

            /**
             * CreateRoomVideoCredentialsResponse chimeRoomVideoCredentials.
             * @member {streem.api.IChimeRoomVideoCredentials|null|undefined} chimeRoomVideoCredentials
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             */
            CreateRoomVideoCredentialsResponse.prototype.chimeRoomVideoCredentials = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CreateRoomVideoCredentialsResponse credentials.
             * @member {"twilioRoomVideoCredentials"|"araasRoomVideoCredentials"|"chimeRoomVideoCredentials"|undefined} credentials
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             */
            Object.defineProperty(CreateRoomVideoCredentialsResponse.prototype, "credentials", {
                get: $util.oneOfGetter($oneOfFields = ["twilioRoomVideoCredentials", "araasRoomVideoCredentials", "chimeRoomVideoCredentials"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CreateRoomVideoCredentialsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {streem.api.ICreateRoomVideoCredentialsResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomVideoCredentialsResponse} CreateRoomVideoCredentialsResponse instance
             */
            CreateRoomVideoCredentialsResponse.create = function create(properties) {
                return new CreateRoomVideoCredentialsResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomVideoCredentialsResponse message. Does not implicitly {@link streem.api.CreateRoomVideoCredentialsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {streem.api.ICreateRoomVideoCredentialsResponse} m CreateRoomVideoCredentialsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomVideoCredentialsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.twilioRoomVideoCredentials != null && Object.hasOwnProperty.call(m, "twilioRoomVideoCredentials"))
                    $root.streem.api.TwilioRoomVideoCredentials.encode(m.twilioRoomVideoCredentials, w.uint32(802).fork()).ldelim();
                if (m.araasRoomVideoCredentials != null && Object.hasOwnProperty.call(m, "araasRoomVideoCredentials"))
                    $root.streem.api.AraasRoomVideoCredentials.encode(m.araasRoomVideoCredentials, w.uint32(810).fork()).ldelim();
                if (m.chimeRoomVideoCredentials != null && Object.hasOwnProperty.call(m, "chimeRoomVideoCredentials"))
                    $root.streem.api.ChimeRoomVideoCredentials.encode(m.chimeRoomVideoCredentials, w.uint32(818).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomVideoCredentialsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomVideoCredentialsResponse} CreateRoomVideoCredentialsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomVideoCredentialsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomVideoCredentialsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 100: {
                            m.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.decode(r, r.uint32());
                            break;
                        }
                    case 102: {
                            m.chimeRoomVideoCredentials = $root.streem.api.ChimeRoomVideoCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomVideoCredentialsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomVideoCredentialsResponse} CreateRoomVideoCredentialsResponse
             */
            CreateRoomVideoCredentialsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomVideoCredentialsResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomVideoCredentialsResponse();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.twilioRoomVideoCredentials != null) {
                    if (typeof d.twilioRoomVideoCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomVideoCredentialsResponse.twilioRoomVideoCredentials: object expected");
                    m.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.fromObject(d.twilioRoomVideoCredentials);
                }
                if (d.araasRoomVideoCredentials != null) {
                    if (typeof d.araasRoomVideoCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomVideoCredentialsResponse.araasRoomVideoCredentials: object expected");
                    m.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.fromObject(d.araasRoomVideoCredentials);
                }
                if (d.chimeRoomVideoCredentials != null) {
                    if (typeof d.chimeRoomVideoCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomVideoCredentialsResponse.chimeRoomVideoCredentials: object expected");
                    m.chimeRoomVideoCredentials = $root.streem.api.ChimeRoomVideoCredentials.fromObject(d.chimeRoomVideoCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomVideoCredentialsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {streem.api.CreateRoomVideoCredentialsResponse} m CreateRoomVideoCredentialsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomVideoCredentialsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.twilioRoomVideoCredentials != null && m.hasOwnProperty("twilioRoomVideoCredentials")) {
                    d.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.toObject(m.twilioRoomVideoCredentials, o);
                    if (o.oneofs)
                        d.credentials = "twilioRoomVideoCredentials";
                }
                if (m.araasRoomVideoCredentials != null && m.hasOwnProperty("araasRoomVideoCredentials")) {
                    d.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.toObject(m.araasRoomVideoCredentials, o);
                    if (o.oneofs)
                        d.credentials = "araasRoomVideoCredentials";
                }
                if (m.chimeRoomVideoCredentials != null && m.hasOwnProperty("chimeRoomVideoCredentials")) {
                    d.chimeRoomVideoCredentials = $root.streem.api.ChimeRoomVideoCredentials.toObject(m.chimeRoomVideoCredentials, o);
                    if (o.oneofs)
                        d.credentials = "chimeRoomVideoCredentials";
                }
                return d;
            };

            /**
             * Converts this CreateRoomVideoCredentialsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomVideoCredentialsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomVideoCredentialsResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomVideoCredentialsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomVideoCredentialsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomVideoCredentialsResponse";
            };

            return CreateRoomVideoCredentialsResponse;
        })();

        api.CreateRoomWallCredentialsRequest = (function() {

            /**
             * Properties of a CreateRoomWallCredentialsRequest.
             * @memberof streem.api
             * @interface ICreateRoomWallCredentialsRequest
             * @property {string|null} [roomSid] CreateRoomWallCredentialsRequest roomSid
             * @property {string|null} [userSid] CreateRoomWallCredentialsRequest userSid
             */

            /**
             * Constructs a new CreateRoomWallCredentialsRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomWallCredentialsRequest.
             * @implements ICreateRoomWallCredentialsRequest
             * @constructor
             * @param {streem.api.ICreateRoomWallCredentialsRequest=} [p] Properties to set
             */
            function CreateRoomWallCredentialsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomWallCredentialsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @instance
             */
            CreateRoomWallCredentialsRequest.prototype.roomSid = "";

            /**
             * CreateRoomWallCredentialsRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @instance
             */
            CreateRoomWallCredentialsRequest.prototype.userSid = "";

            /**
             * Creates a new CreateRoomWallCredentialsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {streem.api.ICreateRoomWallCredentialsRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomWallCredentialsRequest} CreateRoomWallCredentialsRequest instance
             */
            CreateRoomWallCredentialsRequest.create = function create(properties) {
                return new CreateRoomWallCredentialsRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomWallCredentialsRequest message. Does not implicitly {@link streem.api.CreateRoomWallCredentialsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {streem.api.ICreateRoomWallCredentialsRequest} m CreateRoomWallCredentialsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomWallCredentialsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(18).string(m.userSid);
                return w;
            };

            /**
             * Decodes a CreateRoomWallCredentialsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomWallCredentialsRequest} CreateRoomWallCredentialsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomWallCredentialsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomWallCredentialsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomWallCredentialsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomWallCredentialsRequest} CreateRoomWallCredentialsRequest
             */
            CreateRoomWallCredentialsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomWallCredentialsRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomWallCredentialsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomWallCredentialsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {streem.api.CreateRoomWallCredentialsRequest} m CreateRoomWallCredentialsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomWallCredentialsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.userSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this CreateRoomWallCredentialsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomWallCredentialsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomWallCredentialsRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomWallCredentialsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomWallCredentialsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomWallCredentialsRequest";
            };

            return CreateRoomWallCredentialsRequest;
        })();

        api.CreateRoomWallCredentialsResponse = (function() {

            /**
             * Properties of a CreateRoomWallCredentialsResponse.
             * @memberof streem.api
             * @interface ICreateRoomWallCredentialsResponse
             * @property {streem.api.ITwilioRoomWallCredentials|null} [twilioRoomWallCredentials] CreateRoomWallCredentialsResponse twilioRoomWallCredentials
             * @property {streem.api.IStreemRoomWallCredentials|null} [streemRoomWallCredentials] CreateRoomWallCredentialsResponse streemRoomWallCredentials
             */

            /**
             * Constructs a new CreateRoomWallCredentialsResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomWallCredentialsResponse.
             * @implements ICreateRoomWallCredentialsResponse
             * @constructor
             * @param {streem.api.ICreateRoomWallCredentialsResponse=} [p] Properties to set
             */
            function CreateRoomWallCredentialsResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomWallCredentialsResponse twilioRoomWallCredentials.
             * @member {streem.api.ITwilioRoomWallCredentials|null|undefined} twilioRoomWallCredentials
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @instance
             */
            CreateRoomWallCredentialsResponse.prototype.twilioRoomWallCredentials = null;

            /**
             * CreateRoomWallCredentialsResponse streemRoomWallCredentials.
             * @member {streem.api.IStreemRoomWallCredentials|null|undefined} streemRoomWallCredentials
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @instance
             */
            CreateRoomWallCredentialsResponse.prototype.streemRoomWallCredentials = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CreateRoomWallCredentialsResponse credentials.
             * @member {"twilioRoomWallCredentials"|"streemRoomWallCredentials"|undefined} credentials
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @instance
             */
            Object.defineProperty(CreateRoomWallCredentialsResponse.prototype, "credentials", {
                get: $util.oneOfGetter($oneOfFields = ["twilioRoomWallCredentials", "streemRoomWallCredentials"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CreateRoomWallCredentialsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {streem.api.ICreateRoomWallCredentialsResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomWallCredentialsResponse} CreateRoomWallCredentialsResponse instance
             */
            CreateRoomWallCredentialsResponse.create = function create(properties) {
                return new CreateRoomWallCredentialsResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomWallCredentialsResponse message. Does not implicitly {@link streem.api.CreateRoomWallCredentialsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {streem.api.ICreateRoomWallCredentialsResponse} m CreateRoomWallCredentialsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomWallCredentialsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.twilioRoomWallCredentials != null && Object.hasOwnProperty.call(m, "twilioRoomWallCredentials"))
                    $root.streem.api.TwilioRoomWallCredentials.encode(m.twilioRoomWallCredentials, w.uint32(10).fork()).ldelim();
                if (m.streemRoomWallCredentials != null && Object.hasOwnProperty.call(m, "streemRoomWallCredentials"))
                    $root.streem.api.StreemRoomWallCredentials.encode(m.streemRoomWallCredentials, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomWallCredentialsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomWallCredentialsResponse} CreateRoomWallCredentialsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomWallCredentialsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomWallCredentialsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.twilioRoomWallCredentials = $root.streem.api.TwilioRoomWallCredentials.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.streemRoomWallCredentials = $root.streem.api.StreemRoomWallCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomWallCredentialsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomWallCredentialsResponse} CreateRoomWallCredentialsResponse
             */
            CreateRoomWallCredentialsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomWallCredentialsResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomWallCredentialsResponse();
                if (d.twilioRoomWallCredentials != null) {
                    if (typeof d.twilioRoomWallCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomWallCredentialsResponse.twilioRoomWallCredentials: object expected");
                    m.twilioRoomWallCredentials = $root.streem.api.TwilioRoomWallCredentials.fromObject(d.twilioRoomWallCredentials);
                }
                if (d.streemRoomWallCredentials != null) {
                    if (typeof d.streemRoomWallCredentials !== "object")
                        throw TypeError(".streem.api.CreateRoomWallCredentialsResponse.streemRoomWallCredentials: object expected");
                    m.streemRoomWallCredentials = $root.streem.api.StreemRoomWallCredentials.fromObject(d.streemRoomWallCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomWallCredentialsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {streem.api.CreateRoomWallCredentialsResponse} m CreateRoomWallCredentialsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomWallCredentialsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.twilioRoomWallCredentials != null && m.hasOwnProperty("twilioRoomWallCredentials")) {
                    d.twilioRoomWallCredentials = $root.streem.api.TwilioRoomWallCredentials.toObject(m.twilioRoomWallCredentials, o);
                    if (o.oneofs)
                        d.credentials = "twilioRoomWallCredentials";
                }
                if (m.streemRoomWallCredentials != null && m.hasOwnProperty("streemRoomWallCredentials")) {
                    d.streemRoomWallCredentials = $root.streem.api.StreemRoomWallCredentials.toObject(m.streemRoomWallCredentials, o);
                    if (o.oneofs)
                        d.credentials = "streemRoomWallCredentials";
                }
                return d;
            };

            /**
             * Converts this CreateRoomWallCredentialsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomWallCredentialsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomWallCredentialsResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomWallCredentialsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomWallCredentialsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomWallCredentialsResponse";
            };

            return CreateRoomWallCredentialsResponse;
        })();

        api.DeleteRoomRequest = (function() {

            /**
             * Properties of a DeleteRoomRequest.
             * @memberof streem.api
             * @interface IDeleteRoomRequest
             * @property {string|null} [roomSid] DeleteRoomRequest roomSid
             */

            /**
             * Constructs a new DeleteRoomRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteRoomRequest.
             * @implements IDeleteRoomRequest
             * @constructor
             * @param {streem.api.IDeleteRoomRequest=} [p] Properties to set
             */
            function DeleteRoomRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteRoomRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteRoomRequest
             * @instance
             */
            DeleteRoomRequest.prototype.roomSid = "";

            /**
             * Creates a new DeleteRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {streem.api.IDeleteRoomRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteRoomRequest} DeleteRoomRequest instance
             */
            DeleteRoomRequest.create = function create(properties) {
                return new DeleteRoomRequest(properties);
            };

            /**
             * Encodes the specified DeleteRoomRequest message. Does not implicitly {@link streem.api.DeleteRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {streem.api.IDeleteRoomRequest} m DeleteRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a DeleteRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRoomRequest} DeleteRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRoomRequest} DeleteRoomRequest
             */
            DeleteRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRoomRequest)
                    return d;
                var m = new $root.streem.api.DeleteRoomRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {streem.api.DeleteRoomRequest} m DeleteRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this DeleteRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRoomRequest";
            };

            return DeleteRoomRequest;
        })();

        api.ArchiveAndDeleteRoomRequest = (function() {

            /**
             * Properties of an ArchiveAndDeleteRoomRequest.
             * @memberof streem.api
             * @interface IArchiveAndDeleteRoomRequest
             * @property {string|null} [roomSid] ArchiveAndDeleteRoomRequest roomSid
             */

            /**
             * Constructs a new ArchiveAndDeleteRoomRequest.
             * @memberof streem.api
             * @classdesc Represents an ArchiveAndDeleteRoomRequest.
             * @implements IArchiveAndDeleteRoomRequest
             * @constructor
             * @param {streem.api.IArchiveAndDeleteRoomRequest=} [p] Properties to set
             */
            function ArchiveAndDeleteRoomRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArchiveAndDeleteRoomRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @instance
             */
            ArchiveAndDeleteRoomRequest.prototype.roomSid = "";

            /**
             * Creates a new ArchiveAndDeleteRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {streem.api.IArchiveAndDeleteRoomRequest=} [properties] Properties to set
             * @returns {streem.api.ArchiveAndDeleteRoomRequest} ArchiveAndDeleteRoomRequest instance
             */
            ArchiveAndDeleteRoomRequest.create = function create(properties) {
                return new ArchiveAndDeleteRoomRequest(properties);
            };

            /**
             * Encodes the specified ArchiveAndDeleteRoomRequest message. Does not implicitly {@link streem.api.ArchiveAndDeleteRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {streem.api.IArchiveAndDeleteRoomRequest} m ArchiveAndDeleteRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArchiveAndDeleteRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes an ArchiveAndDeleteRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArchiveAndDeleteRoomRequest} ArchiveAndDeleteRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArchiveAndDeleteRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArchiveAndDeleteRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArchiveAndDeleteRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArchiveAndDeleteRoomRequest} ArchiveAndDeleteRoomRequest
             */
            ArchiveAndDeleteRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArchiveAndDeleteRoomRequest)
                    return d;
                var m = new $root.streem.api.ArchiveAndDeleteRoomRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an ArchiveAndDeleteRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {streem.api.ArchiveAndDeleteRoomRequest} m ArchiveAndDeleteRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArchiveAndDeleteRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this ArchiveAndDeleteRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArchiveAndDeleteRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArchiveAndDeleteRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.ArchiveAndDeleteRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArchiveAndDeleteRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArchiveAndDeleteRoomRequest";
            };

            return ArchiveAndDeleteRoomRequest;
        })();

        api.ArchiveAndDeleteRoomResponse = (function() {

            /**
             * Properties of an ArchiveAndDeleteRoomResponse.
             * @memberof streem.api
             * @interface IArchiveAndDeleteRoomResponse
             * @property {boolean|null} [nonExpertRoomParticipantDeactivated] ArchiveAndDeleteRoomResponse nonExpertRoomParticipantDeactivated
             */

            /**
             * Constructs a new ArchiveAndDeleteRoomResponse.
             * @memberof streem.api
             * @classdesc Represents an ArchiveAndDeleteRoomResponse.
             * @implements IArchiveAndDeleteRoomResponse
             * @constructor
             * @param {streem.api.IArchiveAndDeleteRoomResponse=} [p] Properties to set
             */
            function ArchiveAndDeleteRoomResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ArchiveAndDeleteRoomResponse nonExpertRoomParticipantDeactivated.
             * @member {boolean} nonExpertRoomParticipantDeactivated
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @instance
             */
            ArchiveAndDeleteRoomResponse.prototype.nonExpertRoomParticipantDeactivated = false;

            /**
             * Creates a new ArchiveAndDeleteRoomResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {streem.api.IArchiveAndDeleteRoomResponse=} [properties] Properties to set
             * @returns {streem.api.ArchiveAndDeleteRoomResponse} ArchiveAndDeleteRoomResponse instance
             */
            ArchiveAndDeleteRoomResponse.create = function create(properties) {
                return new ArchiveAndDeleteRoomResponse(properties);
            };

            /**
             * Encodes the specified ArchiveAndDeleteRoomResponse message. Does not implicitly {@link streem.api.ArchiveAndDeleteRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {streem.api.IArchiveAndDeleteRoomResponse} m ArchiveAndDeleteRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ArchiveAndDeleteRoomResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.nonExpertRoomParticipantDeactivated != null && Object.hasOwnProperty.call(m, "nonExpertRoomParticipantDeactivated"))
                    w.uint32(16).bool(m.nonExpertRoomParticipantDeactivated);
                return w;
            };

            /**
             * Decodes an ArchiveAndDeleteRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ArchiveAndDeleteRoomResponse} ArchiveAndDeleteRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ArchiveAndDeleteRoomResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ArchiveAndDeleteRoomResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            m.nonExpertRoomParticipantDeactivated = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ArchiveAndDeleteRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ArchiveAndDeleteRoomResponse} ArchiveAndDeleteRoomResponse
             */
            ArchiveAndDeleteRoomResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ArchiveAndDeleteRoomResponse)
                    return d;
                var m = new $root.streem.api.ArchiveAndDeleteRoomResponse();
                if (d.nonExpertRoomParticipantDeactivated != null) {
                    m.nonExpertRoomParticipantDeactivated = Boolean(d.nonExpertRoomParticipantDeactivated);
                }
                return m;
            };

            /**
             * Creates a plain object from an ArchiveAndDeleteRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {streem.api.ArchiveAndDeleteRoomResponse} m ArchiveAndDeleteRoomResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ArchiveAndDeleteRoomResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.nonExpertRoomParticipantDeactivated = false;
                }
                if (m.nonExpertRoomParticipantDeactivated != null && m.hasOwnProperty("nonExpertRoomParticipantDeactivated")) {
                    d.nonExpertRoomParticipantDeactivated = m.nonExpertRoomParticipantDeactivated;
                }
                return d;
            };

            /**
             * Converts this ArchiveAndDeleteRoomResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ArchiveAndDeleteRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ArchiveAndDeleteRoomResponse
             * @function getTypeUrl
             * @memberof streem.api.ArchiveAndDeleteRoomResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ArchiveAndDeleteRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ArchiveAndDeleteRoomResponse";
            };

            return ArchiveAndDeleteRoomResponse;
        })();

        api.GetRoomRequest = (function() {

            /**
             * Properties of a GetRoomRequest.
             * @memberof streem.api
             * @interface IGetRoomRequest
             * @property {string|null} [roomSid] GetRoomRequest roomSid
             */

            /**
             * Constructs a new GetRoomRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomRequest.
             * @implements IGetRoomRequest
             * @constructor
             * @param {streem.api.IGetRoomRequest=} [p] Properties to set
             */
            function GetRoomRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomRequest
             * @instance
             */
            GetRoomRequest.prototype.roomSid = "";

            /**
             * Creates a new GetRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {streem.api.IGetRoomRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomRequest} GetRoomRequest instance
             */
            GetRoomRequest.create = function create(properties) {
                return new GetRoomRequest(properties);
            };

            /**
             * Encodes the specified GetRoomRequest message. Does not implicitly {@link streem.api.GetRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {streem.api.IGetRoomRequest} m GetRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomRequest} GetRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomRequest} GetRoomRequest
             */
            GetRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomRequest)
                    return d;
                var m = new $root.streem.api.GetRoomRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {streem.api.GetRoomRequest} m GetRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomRequest";
            };

            return GetRoomRequest;
        })();

        api.GetRoomResponse = (function() {

            /**
             * Properties of a GetRoomResponse.
             * @memberof streem.api
             * @interface IGetRoomResponse
             * @property {streem.api.IRoom|null} [room] GetRoomResponse room
             */

            /**
             * Constructs a new GetRoomResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomResponse.
             * @implements IGetRoomResponse
             * @constructor
             * @param {streem.api.IGetRoomResponse=} [p] Properties to set
             */
            function GetRoomResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomResponse room.
             * @member {streem.api.IRoom|null|undefined} room
             * @memberof streem.api.GetRoomResponse
             * @instance
             */
            GetRoomResponse.prototype.room = null;

            /**
             * Creates a new GetRoomResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {streem.api.IGetRoomResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomResponse} GetRoomResponse instance
             */
            GetRoomResponse.create = function create(properties) {
                return new GetRoomResponse(properties);
            };

            /**
             * Encodes the specified GetRoomResponse message. Does not implicitly {@link streem.api.GetRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {streem.api.IGetRoomResponse} m GetRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                    $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomResponse} GetRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.room = $root.streem.api.Room.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomResponse} GetRoomResponse
             */
            GetRoomResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomResponse)
                    return d;
                var m = new $root.streem.api.GetRoomResponse();
                if (d.room != null) {
                    if (typeof d.room !== "object")
                        throw TypeError(".streem.api.GetRoomResponse.room: object expected");
                    m.room = $root.streem.api.Room.fromObject(d.room);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {streem.api.GetRoomResponse} m GetRoomResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.room = null;
                }
                if (m.room != null && m.hasOwnProperty("room")) {
                    d.room = $root.streem.api.Room.toObject(m.room, o);
                }
                return d;
            };

            /**
             * Converts this GetRoomResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomResponse";
            };

            return GetRoomResponse;
        })();

        api.GetRoomCallLogEntryRequest = (function() {

            /**
             * Properties of a GetRoomCallLogEntryRequest.
             * @memberof streem.api
             * @interface IGetRoomCallLogEntryRequest
             * @property {string|null} [roomSid] GetRoomCallLogEntryRequest roomSid
             */

            /**
             * Constructs a new GetRoomCallLogEntryRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomCallLogEntryRequest.
             * @implements IGetRoomCallLogEntryRequest
             * @constructor
             * @param {streem.api.IGetRoomCallLogEntryRequest=} [p] Properties to set
             */
            function GetRoomCallLogEntryRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomCallLogEntryRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @instance
             */
            GetRoomCallLogEntryRequest.prototype.roomSid = "";

            /**
             * Creates a new GetRoomCallLogEntryRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {streem.api.IGetRoomCallLogEntryRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomCallLogEntryRequest} GetRoomCallLogEntryRequest instance
             */
            GetRoomCallLogEntryRequest.create = function create(properties) {
                return new GetRoomCallLogEntryRequest(properties);
            };

            /**
             * Encodes the specified GetRoomCallLogEntryRequest message. Does not implicitly {@link streem.api.GetRoomCallLogEntryRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {streem.api.IGetRoomCallLogEntryRequest} m GetRoomCallLogEntryRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomCallLogEntryRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomCallLogEntryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomCallLogEntryRequest} GetRoomCallLogEntryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomCallLogEntryRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomCallLogEntryRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomCallLogEntryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomCallLogEntryRequest} GetRoomCallLogEntryRequest
             */
            GetRoomCallLogEntryRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomCallLogEntryRequest)
                    return d;
                var m = new $root.streem.api.GetRoomCallLogEntryRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomCallLogEntryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {streem.api.GetRoomCallLogEntryRequest} m GetRoomCallLogEntryRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomCallLogEntryRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomCallLogEntryRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomCallLogEntryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomCallLogEntryRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomCallLogEntryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomCallLogEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomCallLogEntryRequest";
            };

            return GetRoomCallLogEntryRequest;
        })();

        api.GetRoomCallLogEntryResponse = (function() {

            /**
             * Properties of a GetRoomCallLogEntryResponse.
             * @memberof streem.api
             * @interface IGetRoomCallLogEntryResponse
             * @property {streem.api.ICallLogEntry|null} [callLogEntry] GetRoomCallLogEntryResponse callLogEntry
             */

            /**
             * Constructs a new GetRoomCallLogEntryResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomCallLogEntryResponse.
             * @implements IGetRoomCallLogEntryResponse
             * @constructor
             * @param {streem.api.IGetRoomCallLogEntryResponse=} [p] Properties to set
             */
            function GetRoomCallLogEntryResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomCallLogEntryResponse callLogEntry.
             * @member {streem.api.ICallLogEntry|null|undefined} callLogEntry
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @instance
             */
            GetRoomCallLogEntryResponse.prototype.callLogEntry = null;

            /**
             * Creates a new GetRoomCallLogEntryResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {streem.api.IGetRoomCallLogEntryResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomCallLogEntryResponse} GetRoomCallLogEntryResponse instance
             */
            GetRoomCallLogEntryResponse.create = function create(properties) {
                return new GetRoomCallLogEntryResponse(properties);
            };

            /**
             * Encodes the specified GetRoomCallLogEntryResponse message. Does not implicitly {@link streem.api.GetRoomCallLogEntryResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {streem.api.IGetRoomCallLogEntryResponse} m GetRoomCallLogEntryResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomCallLogEntryResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntry != null && Object.hasOwnProperty.call(m, "callLogEntry"))
                    $root.streem.api.CallLogEntry.encode(m.callLogEntry, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRoomCallLogEntryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomCallLogEntryResponse} GetRoomCallLogEntryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomCallLogEntryResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomCallLogEntryResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callLogEntry = $root.streem.api.CallLogEntry.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomCallLogEntryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomCallLogEntryResponse} GetRoomCallLogEntryResponse
             */
            GetRoomCallLogEntryResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomCallLogEntryResponse)
                    return d;
                var m = new $root.streem.api.GetRoomCallLogEntryResponse();
                if (d.callLogEntry != null) {
                    if (typeof d.callLogEntry !== "object")
                        throw TypeError(".streem.api.GetRoomCallLogEntryResponse.callLogEntry: object expected");
                    m.callLogEntry = $root.streem.api.CallLogEntry.fromObject(d.callLogEntry);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomCallLogEntryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {streem.api.GetRoomCallLogEntryResponse} m GetRoomCallLogEntryResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomCallLogEntryResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callLogEntry = null;
                }
                if (m.callLogEntry != null && m.hasOwnProperty("callLogEntry")) {
                    d.callLogEntry = $root.streem.api.CallLogEntry.toObject(m.callLogEntry, o);
                }
                return d;
            };

            /**
             * Converts this GetRoomCallLogEntryResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomCallLogEntryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomCallLogEntryResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomCallLogEntryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomCallLogEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomCallLogEntryResponse";
            };

            return GetRoomCallLogEntryResponse;
        })();

        api.GetRoomShareTokenRequest = (function() {

            /**
             * Properties of a GetRoomShareTokenRequest.
             * @memberof streem.api
             * @interface IGetRoomShareTokenRequest
             * @property {string|null} [roomSid] GetRoomShareTokenRequest roomSid
             * @property {string|null} [tokenSid] GetRoomShareTokenRequest tokenSid
             */

            /**
             * Constructs a new GetRoomShareTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomShareTokenRequest.
             * @implements IGetRoomShareTokenRequest
             * @constructor
             * @param {streem.api.IGetRoomShareTokenRequest=} [p] Properties to set
             */
            function GetRoomShareTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomShareTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomShareTokenRequest
             * @instance
             */
            GetRoomShareTokenRequest.prototype.roomSid = "";

            /**
             * GetRoomShareTokenRequest tokenSid.
             * @member {string} tokenSid
             * @memberof streem.api.GetRoomShareTokenRequest
             * @instance
             */
            GetRoomShareTokenRequest.prototype.tokenSid = "";

            /**
             * Creates a new GetRoomShareTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {streem.api.IGetRoomShareTokenRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomShareTokenRequest} GetRoomShareTokenRequest instance
             */
            GetRoomShareTokenRequest.create = function create(properties) {
                return new GetRoomShareTokenRequest(properties);
            };

            /**
             * Encodes the specified GetRoomShareTokenRequest message. Does not implicitly {@link streem.api.GetRoomShareTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {streem.api.IGetRoomShareTokenRequest} m GetRoomShareTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomShareTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.tokenSid != null && Object.hasOwnProperty.call(m, "tokenSid"))
                    w.uint32(18).string(m.tokenSid);
                return w;
            };

            /**
             * Decodes a GetRoomShareTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomShareTokenRequest} GetRoomShareTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomShareTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomShareTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.tokenSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomShareTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomShareTokenRequest} GetRoomShareTokenRequest
             */
            GetRoomShareTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomShareTokenRequest)
                    return d;
                var m = new $root.streem.api.GetRoomShareTokenRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.tokenSid != null) {
                    m.tokenSid = String(d.tokenSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomShareTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {streem.api.GetRoomShareTokenRequest} m GetRoomShareTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomShareTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.tokenSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.tokenSid != null && m.hasOwnProperty("tokenSid")) {
                    d.tokenSid = m.tokenSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomShareTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomShareTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomShareTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomShareTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomShareTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomShareTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomShareTokenRequest";
            };

            return GetRoomShareTokenRequest;
        })();

        api.GetRoomShareTokenResponse = (function() {

            /**
             * Properties of a GetRoomShareTokenResponse.
             * @memberof streem.api
             * @interface IGetRoomShareTokenResponse
             * @property {streem.api.IRoomShareToken|null} [shareToken] GetRoomShareTokenResponse shareToken
             */

            /**
             * Constructs a new GetRoomShareTokenResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomShareTokenResponse.
             * @implements IGetRoomShareTokenResponse
             * @constructor
             * @param {streem.api.IGetRoomShareTokenResponse=} [p] Properties to set
             */
            function GetRoomShareTokenResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomShareTokenResponse shareToken.
             * @member {streem.api.IRoomShareToken|null|undefined} shareToken
             * @memberof streem.api.GetRoomShareTokenResponse
             * @instance
             */
            GetRoomShareTokenResponse.prototype.shareToken = null;

            /**
             * Creates a new GetRoomShareTokenResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {streem.api.IGetRoomShareTokenResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomShareTokenResponse} GetRoomShareTokenResponse instance
             */
            GetRoomShareTokenResponse.create = function create(properties) {
                return new GetRoomShareTokenResponse(properties);
            };

            /**
             * Encodes the specified GetRoomShareTokenResponse message. Does not implicitly {@link streem.api.GetRoomShareTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {streem.api.IGetRoomShareTokenResponse} m GetRoomShareTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomShareTokenResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.shareToken != null && Object.hasOwnProperty.call(m, "shareToken"))
                    $root.streem.api.RoomShareToken.encode(m.shareToken, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRoomShareTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomShareTokenResponse} GetRoomShareTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomShareTokenResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomShareTokenResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.shareToken = $root.streem.api.RoomShareToken.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomShareTokenResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomShareTokenResponse} GetRoomShareTokenResponse
             */
            GetRoomShareTokenResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomShareTokenResponse)
                    return d;
                var m = new $root.streem.api.GetRoomShareTokenResponse();
                if (d.shareToken != null) {
                    if (typeof d.shareToken !== "object")
                        throw TypeError(".streem.api.GetRoomShareTokenResponse.shareToken: object expected");
                    m.shareToken = $root.streem.api.RoomShareToken.fromObject(d.shareToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomShareTokenResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {streem.api.GetRoomShareTokenResponse} m GetRoomShareTokenResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomShareTokenResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.shareToken = null;
                }
                if (m.shareToken != null && m.hasOwnProperty("shareToken")) {
                    d.shareToken = $root.streem.api.RoomShareToken.toObject(m.shareToken, o);
                }
                return d;
            };

            /**
             * Converts this GetRoomShareTokenResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomShareTokenResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomShareTokenResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomShareTokenResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomShareTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomShareTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomShareTokenResponse";
            };

            return GetRoomShareTokenResponse;
        })();

        api.GetRoomWallItemsRequest = (function() {

            /**
             * Properties of a GetRoomWallItemsRequest.
             * @memberof streem.api
             * @interface IGetRoomWallItemsRequest
             * @property {string|null} [roomSid] GetRoomWallItemsRequest roomSid
             */

            /**
             * Constructs a new GetRoomWallItemsRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomWallItemsRequest.
             * @implements IGetRoomWallItemsRequest
             * @constructor
             * @param {streem.api.IGetRoomWallItemsRequest=} [p] Properties to set
             */
            function GetRoomWallItemsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomWallItemsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomWallItemsRequest
             * @instance
             */
            GetRoomWallItemsRequest.prototype.roomSid = "";

            /**
             * Creates a new GetRoomWallItemsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {streem.api.IGetRoomWallItemsRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomWallItemsRequest} GetRoomWallItemsRequest instance
             */
            GetRoomWallItemsRequest.create = function create(properties) {
                return new GetRoomWallItemsRequest(properties);
            };

            /**
             * Encodes the specified GetRoomWallItemsRequest message. Does not implicitly {@link streem.api.GetRoomWallItemsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {streem.api.IGetRoomWallItemsRequest} m GetRoomWallItemsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomWallItemsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomWallItemsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomWallItemsRequest} GetRoomWallItemsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomWallItemsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomWallItemsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomWallItemsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomWallItemsRequest} GetRoomWallItemsRequest
             */
            GetRoomWallItemsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomWallItemsRequest)
                    return d;
                var m = new $root.streem.api.GetRoomWallItemsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomWallItemsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {streem.api.GetRoomWallItemsRequest} m GetRoomWallItemsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomWallItemsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomWallItemsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomWallItemsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomWallItemsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomWallItemsRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomWallItemsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomWallItemsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomWallItemsRequest";
            };

            return GetRoomWallItemsRequest;
        })();

        api.GetRoomWallItemsResponse = (function() {

            /**
             * Properties of a GetRoomWallItemsResponse.
             * @memberof streem.api
             * @interface IGetRoomWallItemsResponse
             * @property {string|null} [roomSid] GetRoomWallItemsResponse roomSid
             * @property {number|null} [wallItemCount] GetRoomWallItemsResponse wallItemCount
             * @property {number|null} [encryptedWallItemCount] GetRoomWallItemsResponse encryptedWallItemCount
             * @property {Array.<IWallItem>|null} [wallItems] GetRoomWallItemsResponse wallItems
             * @property {Array.<IWallItem>|null} [encryptedWallItems] GetRoomWallItemsResponse encryptedWallItems
             */

            /**
             * Constructs a new GetRoomWallItemsResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomWallItemsResponse.
             * @implements IGetRoomWallItemsResponse
             * @constructor
             * @param {streem.api.IGetRoomWallItemsResponse=} [p] Properties to set
             */
            function GetRoomWallItemsResponse(p) {
                this.wallItems = [];
                this.encryptedWallItems = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomWallItemsResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             */
            GetRoomWallItemsResponse.prototype.roomSid = "";

            /**
             * GetRoomWallItemsResponse wallItemCount.
             * @member {number} wallItemCount
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             */
            GetRoomWallItemsResponse.prototype.wallItemCount = 0;

            /**
             * GetRoomWallItemsResponse encryptedWallItemCount.
             * @member {number} encryptedWallItemCount
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             */
            GetRoomWallItemsResponse.prototype.encryptedWallItemCount = 0;

            /**
             * GetRoomWallItemsResponse wallItems.
             * @member {Array.<IWallItem>} wallItems
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             */
            GetRoomWallItemsResponse.prototype.wallItems = $util.emptyArray;

            /**
             * GetRoomWallItemsResponse encryptedWallItems.
             * @member {Array.<IWallItem>} encryptedWallItems
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             */
            GetRoomWallItemsResponse.prototype.encryptedWallItems = $util.emptyArray;

            /**
             * Creates a new GetRoomWallItemsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {streem.api.IGetRoomWallItemsResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomWallItemsResponse} GetRoomWallItemsResponse instance
             */
            GetRoomWallItemsResponse.create = function create(properties) {
                return new GetRoomWallItemsResponse(properties);
            };

            /**
             * Encodes the specified GetRoomWallItemsResponse message. Does not implicitly {@link streem.api.GetRoomWallItemsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {streem.api.IGetRoomWallItemsResponse} m GetRoomWallItemsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomWallItemsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.wallItemCount != null && Object.hasOwnProperty.call(m, "wallItemCount"))
                    w.uint32(16).int32(m.wallItemCount);
                if (m.encryptedWallItemCount != null && Object.hasOwnProperty.call(m, "encryptedWallItemCount"))
                    w.uint32(24).int32(m.encryptedWallItemCount);
                if (m.wallItems != null && m.wallItems.length) {
                    for (var i = 0; i < m.wallItems.length; ++i)
                        $root.WallItem.encode(m.wallItems[i], w.uint32(34).fork()).ldelim();
                }
                if (m.encryptedWallItems != null && m.encryptedWallItems.length) {
                    for (var i = 0; i < m.encryptedWallItems.length; ++i)
                        $root.WallItem.encode(m.encryptedWallItems[i], w.uint32(42).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetRoomWallItemsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomWallItemsResponse} GetRoomWallItemsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomWallItemsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomWallItemsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.wallItemCount = r.int32();
                            break;
                        }
                    case 3: {
                            m.encryptedWallItemCount = r.int32();
                            break;
                        }
                    case 4: {
                            if (!(m.wallItems && m.wallItems.length))
                                m.wallItems = [];
                            m.wallItems.push($root.WallItem.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(m.encryptedWallItems && m.encryptedWallItems.length))
                                m.encryptedWallItems = [];
                            m.encryptedWallItems.push($root.WallItem.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomWallItemsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomWallItemsResponse} GetRoomWallItemsResponse
             */
            GetRoomWallItemsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomWallItemsResponse)
                    return d;
                var m = new $root.streem.api.GetRoomWallItemsResponse();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.wallItemCount != null) {
                    m.wallItemCount = d.wallItemCount | 0;
                }
                if (d.encryptedWallItemCount != null) {
                    m.encryptedWallItemCount = d.encryptedWallItemCount | 0;
                }
                if (d.wallItems) {
                    if (!Array.isArray(d.wallItems))
                        throw TypeError(".streem.api.GetRoomWallItemsResponse.wallItems: array expected");
                    m.wallItems = [];
                    for (var i = 0; i < d.wallItems.length; ++i) {
                        if (typeof d.wallItems[i] !== "object")
                            throw TypeError(".streem.api.GetRoomWallItemsResponse.wallItems: object expected");
                        m.wallItems[i] = $root.WallItem.fromObject(d.wallItems[i]);
                    }
                }
                if (d.encryptedWallItems) {
                    if (!Array.isArray(d.encryptedWallItems))
                        throw TypeError(".streem.api.GetRoomWallItemsResponse.encryptedWallItems: array expected");
                    m.encryptedWallItems = [];
                    for (var i = 0; i < d.encryptedWallItems.length; ++i) {
                        if (typeof d.encryptedWallItems[i] !== "object")
                            throw TypeError(".streem.api.GetRoomWallItemsResponse.encryptedWallItems: object expected");
                        m.encryptedWallItems[i] = $root.WallItem.fromObject(d.encryptedWallItems[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomWallItemsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {streem.api.GetRoomWallItemsResponse} m GetRoomWallItemsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomWallItemsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.wallItems = [];
                    d.encryptedWallItems = [];
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.wallItemCount = 0;
                    d.encryptedWallItemCount = 0;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.wallItemCount != null && m.hasOwnProperty("wallItemCount")) {
                    d.wallItemCount = m.wallItemCount;
                }
                if (m.encryptedWallItemCount != null && m.hasOwnProperty("encryptedWallItemCount")) {
                    d.encryptedWallItemCount = m.encryptedWallItemCount;
                }
                if (m.wallItems && m.wallItems.length) {
                    d.wallItems = [];
                    for (var j = 0; j < m.wallItems.length; ++j) {
                        d.wallItems[j] = $root.WallItem.toObject(m.wallItems[j], o);
                    }
                }
                if (m.encryptedWallItems && m.encryptedWallItems.length) {
                    d.encryptedWallItems = [];
                    for (var j = 0; j < m.encryptedWallItems.length; ++j) {
                        d.encryptedWallItems[j] = $root.WallItem.toObject(m.encryptedWallItems[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetRoomWallItemsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomWallItemsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomWallItemsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomWallItemsResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomWallItemsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomWallItemsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomWallItemsResponse";
            };

            return GetRoomWallItemsResponse;
        })();

        api.LeaveRoomRequest = (function() {

            /**
             * Properties of a LeaveRoomRequest.
             * @memberof streem.api
             * @interface ILeaveRoomRequest
             * @property {string|null} [roomSid] LeaveRoomRequest roomSid
             * @property {string|null} [userSid] LeaveRoomRequest userSid
             */

            /**
             * Constructs a new LeaveRoomRequest.
             * @memberof streem.api
             * @classdesc Represents a LeaveRoomRequest.
             * @implements ILeaveRoomRequest
             * @constructor
             * @param {streem.api.ILeaveRoomRequest=} [p] Properties to set
             */
            function LeaveRoomRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LeaveRoomRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.LeaveRoomRequest
             * @instance
             */
            LeaveRoomRequest.prototype.roomSid = "";

            /**
             * LeaveRoomRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.LeaveRoomRequest
             * @instance
             */
            LeaveRoomRequest.prototype.userSid = "";

            /**
             * Creates a new LeaveRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {streem.api.ILeaveRoomRequest=} [properties] Properties to set
             * @returns {streem.api.LeaveRoomRequest} LeaveRoomRequest instance
             */
            LeaveRoomRequest.create = function create(properties) {
                return new LeaveRoomRequest(properties);
            };

            /**
             * Encodes the specified LeaveRoomRequest message. Does not implicitly {@link streem.api.LeaveRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {streem.api.ILeaveRoomRequest} m LeaveRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LeaveRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(18).string(m.userSid);
                return w;
            };

            /**
             * Decodes a LeaveRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.LeaveRoomRequest} LeaveRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LeaveRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.LeaveRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LeaveRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.LeaveRoomRequest} LeaveRoomRequest
             */
            LeaveRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.LeaveRoomRequest)
                    return d;
                var m = new $root.streem.api.LeaveRoomRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a LeaveRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {streem.api.LeaveRoomRequest} m LeaveRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LeaveRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.userSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this LeaveRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.LeaveRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LeaveRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LeaveRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.LeaveRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LeaveRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.LeaveRoomRequest";
            };

            return LeaveRoomRequest;
        })();

        api.LeaveRoomResponse = (function() {

            /**
             * Properties of a LeaveRoomResponse.
             * @memberof streem.api
             * @interface ILeaveRoomResponse
             * @property {streem.api.IRoomParticipant|null} [roomParticipant] LeaveRoomResponse roomParticipant
             */

            /**
             * Constructs a new LeaveRoomResponse.
             * @memberof streem.api
             * @classdesc Represents a LeaveRoomResponse.
             * @implements ILeaveRoomResponse
             * @constructor
             * @param {streem.api.ILeaveRoomResponse=} [p] Properties to set
             */
            function LeaveRoomResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LeaveRoomResponse roomParticipant.
             * @member {streem.api.IRoomParticipant|null|undefined} roomParticipant
             * @memberof streem.api.LeaveRoomResponse
             * @instance
             */
            LeaveRoomResponse.prototype.roomParticipant = null;

            /**
             * Creates a new LeaveRoomResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {streem.api.ILeaveRoomResponse=} [properties] Properties to set
             * @returns {streem.api.LeaveRoomResponse} LeaveRoomResponse instance
             */
            LeaveRoomResponse.create = function create(properties) {
                return new LeaveRoomResponse(properties);
            };

            /**
             * Encodes the specified LeaveRoomResponse message. Does not implicitly {@link streem.api.LeaveRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {streem.api.ILeaveRoomResponse} m LeaveRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LeaveRoomResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomParticipant != null && Object.hasOwnProperty.call(m, "roomParticipant"))
                    $root.streem.api.RoomParticipant.encode(m.roomParticipant, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a LeaveRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.LeaveRoomResponse} LeaveRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LeaveRoomResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.LeaveRoomResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomParticipant = $root.streem.api.RoomParticipant.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a LeaveRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.LeaveRoomResponse} LeaveRoomResponse
             */
            LeaveRoomResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.LeaveRoomResponse)
                    return d;
                var m = new $root.streem.api.LeaveRoomResponse();
                if (d.roomParticipant != null) {
                    if (typeof d.roomParticipant !== "object")
                        throw TypeError(".streem.api.LeaveRoomResponse.roomParticipant: object expected");
                    m.roomParticipant = $root.streem.api.RoomParticipant.fromObject(d.roomParticipant);
                }
                return m;
            };

            /**
             * Creates a plain object from a LeaveRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {streem.api.LeaveRoomResponse} m LeaveRoomResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LeaveRoomResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomParticipant = null;
                }
                if (m.roomParticipant != null && m.hasOwnProperty("roomParticipant")) {
                    d.roomParticipant = $root.streem.api.RoomParticipant.toObject(m.roomParticipant, o);
                }
                return d;
            };

            /**
             * Converts this LeaveRoomResponse to JSON.
             * @function toJSON
             * @memberof streem.api.LeaveRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LeaveRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LeaveRoomResponse
             * @function getTypeUrl
             * @memberof streem.api.LeaveRoomResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LeaveRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.LeaveRoomResponse";
            };

            return LeaveRoomResponse;
        })();

        api.ListRoomsRequest = (function() {

            /**
             * Properties of a ListRoomsRequest.
             * @memberof streem.api
             * @interface IListRoomsRequest
             * @property {string|null} [createdByUserSid] ListRoomsRequest createdByUserSid
             * @property {string|null} [companySid] ListRoomsRequest companySid
             * @property {string|null} [referenceId] ListRoomsRequest referenceId
             * @property {string|null} [twilioRoomSid] ListRoomsRequest twilioRoomSid
             * @property {number|null} [pageSize] ListRoomsRequest pageSize
             * @property {string|null} [pageToken] ListRoomsRequest pageToken
             */

            /**
             * Constructs a new ListRoomsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRoomsRequest.
             * @implements IListRoomsRequest
             * @constructor
             * @param {streem.api.IListRoomsRequest=} [p] Properties to set
             */
            function ListRoomsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomsRequest createdByUserSid.
             * @member {string} createdByUserSid
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.createdByUserSid = "";

            /**
             * ListRoomsRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.companySid = "";

            /**
             * ListRoomsRequest referenceId.
             * @member {string} referenceId
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.referenceId = "";

            /**
             * ListRoomsRequest twilioRoomSid.
             * @member {string} twilioRoomSid
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.twilioRoomSid = "";

            /**
             * ListRoomsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.pageSize = 0;

            /**
             * ListRoomsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRoomsRequest
             * @instance
             */
            ListRoomsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRoomsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {streem.api.IListRoomsRequest=} [properties] Properties to set
             * @returns {streem.api.ListRoomsRequest} ListRoomsRequest instance
             */
            ListRoomsRequest.create = function create(properties) {
                return new ListRoomsRequest(properties);
            };

            /**
             * Encodes the specified ListRoomsRequest message. Does not implicitly {@link streem.api.ListRoomsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {streem.api.IListRoomsRequest} m ListRoomsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.createdByUserSid != null && Object.hasOwnProperty.call(m, "createdByUserSid"))
                    w.uint32(10).string(m.createdByUserSid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(26).string(m.referenceId);
                if (m.twilioRoomSid != null && Object.hasOwnProperty.call(m, "twilioRoomSid"))
                    w.uint32(34).string(m.twilioRoomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(40).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(50).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRoomsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomsRequest} ListRoomsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.createdByUserSid = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 3: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 4: {
                            m.twilioRoomSid = r.string();
                            break;
                        }
                    case 5: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 6: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomsRequest} ListRoomsRequest
             */
            ListRoomsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomsRequest)
                    return d;
                var m = new $root.streem.api.ListRoomsRequest();
                if (d.createdByUserSid != null) {
                    m.createdByUserSid = String(d.createdByUserSid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.twilioRoomSid != null) {
                    m.twilioRoomSid = String(d.twilioRoomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {streem.api.ListRoomsRequest} m ListRoomsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.createdByUserSid = "";
                    d.companySid = "";
                    d.referenceId = "";
                    d.twilioRoomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.createdByUserSid != null && m.hasOwnProperty("createdByUserSid")) {
                    d.createdByUserSid = m.createdByUserSid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.twilioRoomSid != null && m.hasOwnProperty("twilioRoomSid")) {
                    d.twilioRoomSid = m.twilioRoomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRoomsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRoomsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomsRequest";
            };

            return ListRoomsRequest;
        })();

        api.ListRoomsResponse = (function() {

            /**
             * Properties of a ListRoomsResponse.
             * @memberof streem.api
             * @interface IListRoomsResponse
             * @property {Array.<streem.api.IRoom>|null} [rooms] ListRoomsResponse rooms
             * @property {string|null} [nextPageToken] ListRoomsResponse nextPageToken
             * @property {number|null} [totalSize] ListRoomsResponse totalSize
             */

            /**
             * Constructs a new ListRoomsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRoomsResponse.
             * @implements IListRoomsResponse
             * @constructor
             * @param {streem.api.IListRoomsResponse=} [p] Properties to set
             */
            function ListRoomsResponse(p) {
                this.rooms = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomsResponse rooms.
             * @member {Array.<streem.api.IRoom>} rooms
             * @memberof streem.api.ListRoomsResponse
             * @instance
             */
            ListRoomsResponse.prototype.rooms = $util.emptyArray;

            /**
             * ListRoomsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRoomsResponse
             * @instance
             */
            ListRoomsResponse.prototype.nextPageToken = "";

            /**
             * ListRoomsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRoomsResponse
             * @instance
             */
            ListRoomsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRoomsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {streem.api.IListRoomsResponse=} [properties] Properties to set
             * @returns {streem.api.ListRoomsResponse} ListRoomsResponse instance
             */
            ListRoomsResponse.create = function create(properties) {
                return new ListRoomsResponse(properties);
            };

            /**
             * Encodes the specified ListRoomsResponse message. Does not implicitly {@link streem.api.ListRoomsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {streem.api.IListRoomsResponse} m ListRoomsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rooms != null && m.rooms.length) {
                    for (var i = 0; i < m.rooms.length; ++i)
                        $root.streem.api.Room.encode(m.rooms[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRoomsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomsResponse} ListRoomsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.rooms && m.rooms.length))
                                m.rooms = [];
                            m.rooms.push($root.streem.api.Room.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomsResponse} ListRoomsResponse
             */
            ListRoomsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomsResponse)
                    return d;
                var m = new $root.streem.api.ListRoomsResponse();
                if (d.rooms) {
                    if (!Array.isArray(d.rooms))
                        throw TypeError(".streem.api.ListRoomsResponse.rooms: array expected");
                    m.rooms = [];
                    for (var i = 0; i < d.rooms.length; ++i) {
                        if (typeof d.rooms[i] !== "object")
                            throw TypeError(".streem.api.ListRoomsResponse.rooms: object expected");
                        m.rooms[i] = $root.streem.api.Room.fromObject(d.rooms[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {streem.api.ListRoomsResponse} m ListRoomsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.rooms = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.rooms && m.rooms.length) {
                    d.rooms = [];
                    for (var j = 0; j < m.rooms.length; ++j) {
                        d.rooms[j] = $root.streem.api.Room.toObject(m.rooms[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRoomsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRoomsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomsResponse";
            };

            return ListRoomsResponse;
        })();

        api.ListRoomParticipantsRequest = (function() {

            /**
             * Properties of a ListRoomParticipantsRequest.
             * @memberof streem.api
             * @interface IListRoomParticipantsRequest
             * @property {string|null} [roomSid] ListRoomParticipantsRequest roomSid
             * @property {number|null} [pageSize] ListRoomParticipantsRequest pageSize
             * @property {string|null} [pageToken] ListRoomParticipantsRequest pageToken
             */

            /**
             * Constructs a new ListRoomParticipantsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRoomParticipantsRequest.
             * @implements IListRoomParticipantsRequest
             * @constructor
             * @param {streem.api.IListRoomParticipantsRequest=} [p] Properties to set
             */
            function ListRoomParticipantsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomParticipantsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListRoomParticipantsRequest
             * @instance
             */
            ListRoomParticipantsRequest.prototype.roomSid = "";

            /**
             * ListRoomParticipantsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRoomParticipantsRequest
             * @instance
             */
            ListRoomParticipantsRequest.prototype.pageSize = 0;

            /**
             * ListRoomParticipantsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRoomParticipantsRequest
             * @instance
             */
            ListRoomParticipantsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRoomParticipantsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {streem.api.IListRoomParticipantsRequest=} [properties] Properties to set
             * @returns {streem.api.ListRoomParticipantsRequest} ListRoomParticipantsRequest instance
             */
            ListRoomParticipantsRequest.create = function create(properties) {
                return new ListRoomParticipantsRequest(properties);
            };

            /**
             * Encodes the specified ListRoomParticipantsRequest message. Does not implicitly {@link streem.api.ListRoomParticipantsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {streem.api.IListRoomParticipantsRequest} m ListRoomParticipantsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomParticipantsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRoomParticipantsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomParticipantsRequest} ListRoomParticipantsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomParticipantsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomParticipantsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomParticipantsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomParticipantsRequest} ListRoomParticipantsRequest
             */
            ListRoomParticipantsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomParticipantsRequest)
                    return d;
                var m = new $root.streem.api.ListRoomParticipantsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomParticipantsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {streem.api.ListRoomParticipantsRequest} m ListRoomParticipantsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomParticipantsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRoomParticipantsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomParticipantsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomParticipantsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomParticipantsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRoomParticipantsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomParticipantsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomParticipantsRequest";
            };

            return ListRoomParticipantsRequest;
        })();

        api.ListRoomParticipantsResponse = (function() {

            /**
             * Properties of a ListRoomParticipantsResponse.
             * @memberof streem.api
             * @interface IListRoomParticipantsResponse
             * @property {Array.<streem.api.IRoomParticipant>|null} [roomParticipants] ListRoomParticipantsResponse roomParticipants
             * @property {string|null} [nextPageToken] ListRoomParticipantsResponse nextPageToken
             * @property {number|null} [totalSize] ListRoomParticipantsResponse totalSize
             */

            /**
             * Constructs a new ListRoomParticipantsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRoomParticipantsResponse.
             * @implements IListRoomParticipantsResponse
             * @constructor
             * @param {streem.api.IListRoomParticipantsResponse=} [p] Properties to set
             */
            function ListRoomParticipantsResponse(p) {
                this.roomParticipants = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomParticipantsResponse roomParticipants.
             * @member {Array.<streem.api.IRoomParticipant>} roomParticipants
             * @memberof streem.api.ListRoomParticipantsResponse
             * @instance
             */
            ListRoomParticipantsResponse.prototype.roomParticipants = $util.emptyArray;

            /**
             * ListRoomParticipantsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRoomParticipantsResponse
             * @instance
             */
            ListRoomParticipantsResponse.prototype.nextPageToken = "";

            /**
             * ListRoomParticipantsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRoomParticipantsResponse
             * @instance
             */
            ListRoomParticipantsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRoomParticipantsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {streem.api.IListRoomParticipantsResponse=} [properties] Properties to set
             * @returns {streem.api.ListRoomParticipantsResponse} ListRoomParticipantsResponse instance
             */
            ListRoomParticipantsResponse.create = function create(properties) {
                return new ListRoomParticipantsResponse(properties);
            };

            /**
             * Encodes the specified ListRoomParticipantsResponse message. Does not implicitly {@link streem.api.ListRoomParticipantsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {streem.api.IListRoomParticipantsResponse} m ListRoomParticipantsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomParticipantsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomParticipants != null && m.roomParticipants.length) {
                    for (var i = 0; i < m.roomParticipants.length; ++i)
                        $root.streem.api.RoomParticipant.encode(m.roomParticipants[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRoomParticipantsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomParticipantsResponse} ListRoomParticipantsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomParticipantsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomParticipantsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomParticipants && m.roomParticipants.length))
                                m.roomParticipants = [];
                            m.roomParticipants.push($root.streem.api.RoomParticipant.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomParticipantsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomParticipantsResponse} ListRoomParticipantsResponse
             */
            ListRoomParticipantsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomParticipantsResponse)
                    return d;
                var m = new $root.streem.api.ListRoomParticipantsResponse();
                if (d.roomParticipants) {
                    if (!Array.isArray(d.roomParticipants))
                        throw TypeError(".streem.api.ListRoomParticipantsResponse.roomParticipants: array expected");
                    m.roomParticipants = [];
                    for (var i = 0; i < d.roomParticipants.length; ++i) {
                        if (typeof d.roomParticipants[i] !== "object")
                            throw TypeError(".streem.api.ListRoomParticipantsResponse.roomParticipants: object expected");
                        m.roomParticipants[i] = $root.streem.api.RoomParticipant.fromObject(d.roomParticipants[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomParticipantsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {streem.api.ListRoomParticipantsResponse} m ListRoomParticipantsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomParticipantsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomParticipants = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.roomParticipants && m.roomParticipants.length) {
                    d.roomParticipants = [];
                    for (var j = 0; j < m.roomParticipants.length; ++j) {
                        d.roomParticipants[j] = $root.streem.api.RoomParticipant.toObject(m.roomParticipants[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRoomParticipantsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomParticipantsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomParticipantsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomParticipantsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRoomParticipantsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomParticipantsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomParticipantsResponse";
            };

            return ListRoomParticipantsResponse;
        })();

        api.ListRoomShareTokensRequest = (function() {

            /**
             * Properties of a ListRoomShareTokensRequest.
             * @memberof streem.api
             * @interface IListRoomShareTokensRequest
             * @property {string|null} [roomSid] ListRoomShareTokensRequest roomSid
             * @property {boolean|null} [includeExpired] ListRoomShareTokensRequest includeExpired
             * @property {streem.api.ListRoomShareTokensRequest.Expires|null} [expires] ListRoomShareTokensRequest expires
             * @property {number|null} [pageSize] ListRoomShareTokensRequest pageSize
             * @property {string|null} [pageToken] ListRoomShareTokensRequest pageToken
             * @property {streem.api.RoomShareToken.View|null} [view] ListRoomShareTokensRequest view
             */

            /**
             * Constructs a new ListRoomShareTokensRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRoomShareTokensRequest.
             * @implements IListRoomShareTokensRequest
             * @constructor
             * @param {streem.api.IListRoomShareTokensRequest=} [p] Properties to set
             */
            function ListRoomShareTokensRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomShareTokensRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.roomSid = "";

            /**
             * ListRoomShareTokensRequest includeExpired.
             * @member {boolean} includeExpired
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.includeExpired = false;

            /**
             * ListRoomShareTokensRequest expires.
             * @member {streem.api.ListRoomShareTokensRequest.Expires} expires
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.expires = 0;

            /**
             * ListRoomShareTokensRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.pageSize = 0;

            /**
             * ListRoomShareTokensRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.pageToken = "";

            /**
             * ListRoomShareTokensRequest view.
             * @member {streem.api.RoomShareToken.View} view
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             */
            ListRoomShareTokensRequest.prototype.view = 0;

            /**
             * Creates a new ListRoomShareTokensRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {streem.api.IListRoomShareTokensRequest=} [properties] Properties to set
             * @returns {streem.api.ListRoomShareTokensRequest} ListRoomShareTokensRequest instance
             */
            ListRoomShareTokensRequest.create = function create(properties) {
                return new ListRoomShareTokensRequest(properties);
            };

            /**
             * Encodes the specified ListRoomShareTokensRequest message. Does not implicitly {@link streem.api.ListRoomShareTokensRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {streem.api.IListRoomShareTokensRequest} m ListRoomShareTokensRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomShareTokensRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.includeExpired != null && Object.hasOwnProperty.call(m, "includeExpired"))
                    w.uint32(16).bool(m.includeExpired);
                if (m.expires != null && Object.hasOwnProperty.call(m, "expires"))
                    w.uint32(24).int32(m.expires);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(32).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(42).string(m.pageToken);
                if (m.view != null && Object.hasOwnProperty.call(m, "view"))
                    w.uint32(48).int32(m.view);
                return w;
            };

            /**
             * Decodes a ListRoomShareTokensRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomShareTokensRequest} ListRoomShareTokensRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomShareTokensRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomShareTokensRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.includeExpired = r.bool();
                            break;
                        }
                    case 3: {
                            m.expires = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 5: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 6: {
                            m.view = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomShareTokensRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomShareTokensRequest} ListRoomShareTokensRequest
             */
            ListRoomShareTokensRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomShareTokensRequest)
                    return d;
                var m = new $root.streem.api.ListRoomShareTokensRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.includeExpired != null) {
                    m.includeExpired = Boolean(d.includeExpired);
                }
                switch (d.expires) {
                default:
                    if (typeof d.expires === "number") {
                        m.expires = d.expires;
                        break;
                    }
                    break;
                case "EXPIRES_ANY":
                case 0:
                    m.expires = 0;
                    break;
                case "EXPIRES_NO":
                case 1:
                    m.expires = 1;
                    break;
                case "EXPIRES_YES":
                case 2:
                    m.expires = 2;
                    break;
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                switch (d.view) {
                default:
                    if (typeof d.view === "number") {
                        m.view = d.view;
                        break;
                    }
                    break;
                case "VIEW_INVALID":
                case 0:
                    m.view = 0;
                    break;
                case "VIEW_BASIC":
                case 1:
                    m.view = 1;
                    break;
                case "VIEW_FULL":
                case 2:
                    m.view = 2;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomShareTokensRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {streem.api.ListRoomShareTokensRequest} m ListRoomShareTokensRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomShareTokensRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.includeExpired = false;
                    d.expires = o.enums === String ? "EXPIRES_ANY" : 0;
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.view = o.enums === String ? "VIEW_INVALID" : 0;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.includeExpired != null && m.hasOwnProperty("includeExpired")) {
                    d.includeExpired = m.includeExpired;
                }
                if (m.expires != null && m.hasOwnProperty("expires")) {
                    d.expires = o.enums === String ? $root.streem.api.ListRoomShareTokensRequest.Expires[m.expires] === undefined ? m.expires : $root.streem.api.ListRoomShareTokensRequest.Expires[m.expires] : m.expires;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.view != null && m.hasOwnProperty("view")) {
                    d.view = o.enums === String ? $root.streem.api.RoomShareToken.View[m.view] === undefined ? m.view : $root.streem.api.RoomShareToken.View[m.view] : m.view;
                }
                return d;
            };

            /**
             * Converts this ListRoomShareTokensRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomShareTokensRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomShareTokensRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomShareTokensRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRoomShareTokensRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomShareTokensRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomShareTokensRequest";
            };

            /**
             * Expires enum.
             * @name streem.api.ListRoomShareTokensRequest.Expires
             * @enum {number}
             * @property {number} EXPIRES_ANY=0 EXPIRES_ANY value
             * @property {number} EXPIRES_NO=1 EXPIRES_NO value
             * @property {number} EXPIRES_YES=2 EXPIRES_YES value
             */
            ListRoomShareTokensRequest.Expires = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "EXPIRES_ANY"] = 0;
                values[valuesById[1] = "EXPIRES_NO"] = 1;
                values[valuesById[2] = "EXPIRES_YES"] = 2;
                return values;
            })();

            return ListRoomShareTokensRequest;
        })();

        api.ListRoomShareTokensResponse = (function() {

            /**
             * Properties of a ListRoomShareTokensResponse.
             * @memberof streem.api
             * @interface IListRoomShareTokensResponse
             * @property {Array.<streem.api.IRoomShareToken>|null} [shareTokens] ListRoomShareTokensResponse shareTokens
             * @property {string|null} [nextPageToken] ListRoomShareTokensResponse nextPageToken
             * @property {number|null} [totalSize] ListRoomShareTokensResponse totalSize
             */

            /**
             * Constructs a new ListRoomShareTokensResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRoomShareTokensResponse.
             * @implements IListRoomShareTokensResponse
             * @constructor
             * @param {streem.api.IListRoomShareTokensResponse=} [p] Properties to set
             */
            function ListRoomShareTokensResponse(p) {
                this.shareTokens = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomShareTokensResponse shareTokens.
             * @member {Array.<streem.api.IRoomShareToken>} shareTokens
             * @memberof streem.api.ListRoomShareTokensResponse
             * @instance
             */
            ListRoomShareTokensResponse.prototype.shareTokens = $util.emptyArray;

            /**
             * ListRoomShareTokensResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRoomShareTokensResponse
             * @instance
             */
            ListRoomShareTokensResponse.prototype.nextPageToken = "";

            /**
             * ListRoomShareTokensResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRoomShareTokensResponse
             * @instance
             */
            ListRoomShareTokensResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRoomShareTokensResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {streem.api.IListRoomShareTokensResponse=} [properties] Properties to set
             * @returns {streem.api.ListRoomShareTokensResponse} ListRoomShareTokensResponse instance
             */
            ListRoomShareTokensResponse.create = function create(properties) {
                return new ListRoomShareTokensResponse(properties);
            };

            /**
             * Encodes the specified ListRoomShareTokensResponse message. Does not implicitly {@link streem.api.ListRoomShareTokensResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {streem.api.IListRoomShareTokensResponse} m ListRoomShareTokensResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomShareTokensResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.shareTokens != null && m.shareTokens.length) {
                    for (var i = 0; i < m.shareTokens.length; ++i)
                        $root.streem.api.RoomShareToken.encode(m.shareTokens[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRoomShareTokensResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomShareTokensResponse} ListRoomShareTokensResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomShareTokensResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomShareTokensResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.shareTokens && m.shareTokens.length))
                                m.shareTokens = [];
                            m.shareTokens.push($root.streem.api.RoomShareToken.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomShareTokensResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomShareTokensResponse} ListRoomShareTokensResponse
             */
            ListRoomShareTokensResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomShareTokensResponse)
                    return d;
                var m = new $root.streem.api.ListRoomShareTokensResponse();
                if (d.shareTokens) {
                    if (!Array.isArray(d.shareTokens))
                        throw TypeError(".streem.api.ListRoomShareTokensResponse.shareTokens: array expected");
                    m.shareTokens = [];
                    for (var i = 0; i < d.shareTokens.length; ++i) {
                        if (typeof d.shareTokens[i] !== "object")
                            throw TypeError(".streem.api.ListRoomShareTokensResponse.shareTokens: object expected");
                        m.shareTokens[i] = $root.streem.api.RoomShareToken.fromObject(d.shareTokens[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomShareTokensResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {streem.api.ListRoomShareTokensResponse} m ListRoomShareTokensResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomShareTokensResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.shareTokens = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.shareTokens && m.shareTokens.length) {
                    d.shareTokens = [];
                    for (var j = 0; j < m.shareTokens.length; ++j) {
                        d.shareTokens[j] = $root.streem.api.RoomShareToken.toObject(m.shareTokens[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRoomShareTokensResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomShareTokensResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomShareTokensResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomShareTokensResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRoomShareTokensResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomShareTokensResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomShareTokensResponse";
            };

            return ListRoomShareTokensResponse;
        })();

        api.RevokeRoomShareTokenRequest = (function() {

            /**
             * Properties of a RevokeRoomShareTokenRequest.
             * @memberof streem.api
             * @interface IRevokeRoomShareTokenRequest
             * @property {string|null} [roomSid] RevokeRoomShareTokenRequest roomSid
             * @property {string|null} [tokenSid] RevokeRoomShareTokenRequest tokenSid
             */

            /**
             * Constructs a new RevokeRoomShareTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a RevokeRoomShareTokenRequest.
             * @implements IRevokeRoomShareTokenRequest
             * @constructor
             * @param {streem.api.IRevokeRoomShareTokenRequest=} [p] Properties to set
             */
            function RevokeRoomShareTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RevokeRoomShareTokenRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @instance
             */
            RevokeRoomShareTokenRequest.prototype.roomSid = "";

            /**
             * RevokeRoomShareTokenRequest tokenSid.
             * @member {string} tokenSid
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @instance
             */
            RevokeRoomShareTokenRequest.prototype.tokenSid = "";

            /**
             * Creates a new RevokeRoomShareTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {streem.api.IRevokeRoomShareTokenRequest=} [properties] Properties to set
             * @returns {streem.api.RevokeRoomShareTokenRequest} RevokeRoomShareTokenRequest instance
             */
            RevokeRoomShareTokenRequest.create = function create(properties) {
                return new RevokeRoomShareTokenRequest(properties);
            };

            /**
             * Encodes the specified RevokeRoomShareTokenRequest message. Does not implicitly {@link streem.api.RevokeRoomShareTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {streem.api.IRevokeRoomShareTokenRequest} m RevokeRoomShareTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RevokeRoomShareTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.tokenSid != null && Object.hasOwnProperty.call(m, "tokenSid"))
                    w.uint32(18).string(m.tokenSid);
                return w;
            };

            /**
             * Decodes a RevokeRoomShareTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RevokeRoomShareTokenRequest} RevokeRoomShareTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RevokeRoomShareTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RevokeRoomShareTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.tokenSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RevokeRoomShareTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RevokeRoomShareTokenRequest} RevokeRoomShareTokenRequest
             */
            RevokeRoomShareTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RevokeRoomShareTokenRequest)
                    return d;
                var m = new $root.streem.api.RevokeRoomShareTokenRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.tokenSid != null) {
                    m.tokenSid = String(d.tokenSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a RevokeRoomShareTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {streem.api.RevokeRoomShareTokenRequest} m RevokeRoomShareTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RevokeRoomShareTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.tokenSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.tokenSid != null && m.hasOwnProperty("tokenSid")) {
                    d.tokenSid = m.tokenSid;
                }
                return d;
            };

            /**
             * Converts this RevokeRoomShareTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RevokeRoomShareTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RevokeRoomShareTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.RevokeRoomShareTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RevokeRoomShareTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RevokeRoomShareTokenRequest";
            };

            return RevokeRoomShareTokenRequest;
        })();

        api.PauseRoomRecordingRequest = (function() {

            /**
             * Properties of a PauseRoomRecordingRequest.
             * @memberof streem.api
             * @interface IPauseRoomRecordingRequest
             * @property {string|null} [roomSid] PauseRoomRecordingRequest roomSid
             */

            /**
             * Constructs a new PauseRoomRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a PauseRoomRecordingRequest.
             * @implements IPauseRoomRecordingRequest
             * @constructor
             * @param {streem.api.IPauseRoomRecordingRequest=} [p] Properties to set
             */
            function PauseRoomRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PauseRoomRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.PauseRoomRecordingRequest
             * @instance
             */
            PauseRoomRecordingRequest.prototype.roomSid = "";

            /**
             * Creates a new PauseRoomRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {streem.api.IPauseRoomRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.PauseRoomRecordingRequest} PauseRoomRecordingRequest instance
             */
            PauseRoomRecordingRequest.create = function create(properties) {
                return new PauseRoomRecordingRequest(properties);
            };

            /**
             * Encodes the specified PauseRoomRecordingRequest message. Does not implicitly {@link streem.api.PauseRoomRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {streem.api.IPauseRoomRecordingRequest} m PauseRoomRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PauseRoomRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a PauseRoomRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PauseRoomRecordingRequest} PauseRoomRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PauseRoomRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PauseRoomRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PauseRoomRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PauseRoomRecordingRequest} PauseRoomRecordingRequest
             */
            PauseRoomRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PauseRoomRecordingRequest)
                    return d;
                var m = new $root.streem.api.PauseRoomRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a PauseRoomRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {streem.api.PauseRoomRecordingRequest} m PauseRoomRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PauseRoomRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this PauseRoomRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.PauseRoomRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PauseRoomRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PauseRoomRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.PauseRoomRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PauseRoomRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PauseRoomRecordingRequest";
            };

            return PauseRoomRecordingRequest;
        })();

        api.PauseRoomRecordingResponse = (function() {

            /**
             * Properties of a PauseRoomRecordingResponse.
             * @memberof streem.api
             * @interface IPauseRoomRecordingResponse
             */

            /**
             * Constructs a new PauseRoomRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a PauseRoomRecordingResponse.
             * @implements IPauseRoomRecordingResponse
             * @constructor
             * @param {streem.api.IPauseRoomRecordingResponse=} [p] Properties to set
             */
            function PauseRoomRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new PauseRoomRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {streem.api.IPauseRoomRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.PauseRoomRecordingResponse} PauseRoomRecordingResponse instance
             */
            PauseRoomRecordingResponse.create = function create(properties) {
                return new PauseRoomRecordingResponse(properties);
            };

            /**
             * Encodes the specified PauseRoomRecordingResponse message. Does not implicitly {@link streem.api.PauseRoomRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {streem.api.IPauseRoomRecordingResponse} m PauseRoomRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PauseRoomRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a PauseRoomRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PauseRoomRecordingResponse} PauseRoomRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PauseRoomRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PauseRoomRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PauseRoomRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PauseRoomRecordingResponse} PauseRoomRecordingResponse
             */
            PauseRoomRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PauseRoomRecordingResponse)
                    return d;
                return new $root.streem.api.PauseRoomRecordingResponse();
            };

            /**
             * Creates a plain object from a PauseRoomRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {streem.api.PauseRoomRecordingResponse} m PauseRoomRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PauseRoomRecordingResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PauseRoomRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.PauseRoomRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PauseRoomRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PauseRoomRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.PauseRoomRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PauseRoomRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PauseRoomRecordingResponse";
            };

            return PauseRoomRecordingResponse;
        })();

        api.ResumeRoomRecordingRequest = (function() {

            /**
             * Properties of a ResumeRoomRecordingRequest.
             * @memberof streem.api
             * @interface IResumeRoomRecordingRequest
             * @property {string|null} [roomSid] ResumeRoomRecordingRequest roomSid
             */

            /**
             * Constructs a new ResumeRoomRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a ResumeRoomRecordingRequest.
             * @implements IResumeRoomRecordingRequest
             * @constructor
             * @param {streem.api.IResumeRoomRecordingRequest=} [p] Properties to set
             */
            function ResumeRoomRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ResumeRoomRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @instance
             */
            ResumeRoomRecordingRequest.prototype.roomSid = "";

            /**
             * Creates a new ResumeRoomRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {streem.api.IResumeRoomRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.ResumeRoomRecordingRequest} ResumeRoomRecordingRequest instance
             */
            ResumeRoomRecordingRequest.create = function create(properties) {
                return new ResumeRoomRecordingRequest(properties);
            };

            /**
             * Encodes the specified ResumeRoomRecordingRequest message. Does not implicitly {@link streem.api.ResumeRoomRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {streem.api.IResumeRoomRecordingRequest} m ResumeRoomRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResumeRoomRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a ResumeRoomRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ResumeRoomRecordingRequest} ResumeRoomRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResumeRoomRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ResumeRoomRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ResumeRoomRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ResumeRoomRecordingRequest} ResumeRoomRecordingRequest
             */
            ResumeRoomRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ResumeRoomRecordingRequest)
                    return d;
                var m = new $root.streem.api.ResumeRoomRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ResumeRoomRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {streem.api.ResumeRoomRecordingRequest} m ResumeRoomRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResumeRoomRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this ResumeRoomRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResumeRoomRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResumeRoomRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.ResumeRoomRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResumeRoomRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ResumeRoomRecordingRequest";
            };

            return ResumeRoomRecordingRequest;
        })();

        api.ResumeRoomRecordingResponse = (function() {

            /**
             * Properties of a ResumeRoomRecordingResponse.
             * @memberof streem.api
             * @interface IResumeRoomRecordingResponse
             */

            /**
             * Constructs a new ResumeRoomRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a ResumeRoomRecordingResponse.
             * @implements IResumeRoomRecordingResponse
             * @constructor
             * @param {streem.api.IResumeRoomRecordingResponse=} [p] Properties to set
             */
            function ResumeRoomRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new ResumeRoomRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {streem.api.IResumeRoomRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.ResumeRoomRecordingResponse} ResumeRoomRecordingResponse instance
             */
            ResumeRoomRecordingResponse.create = function create(properties) {
                return new ResumeRoomRecordingResponse(properties);
            };

            /**
             * Encodes the specified ResumeRoomRecordingResponse message. Does not implicitly {@link streem.api.ResumeRoomRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {streem.api.IResumeRoomRecordingResponse} m ResumeRoomRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResumeRoomRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a ResumeRoomRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ResumeRoomRecordingResponse} ResumeRoomRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResumeRoomRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ResumeRoomRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ResumeRoomRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ResumeRoomRecordingResponse} ResumeRoomRecordingResponse
             */
            ResumeRoomRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ResumeRoomRecordingResponse)
                    return d;
                return new $root.streem.api.ResumeRoomRecordingResponse();
            };

            /**
             * Creates a plain object from a ResumeRoomRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {streem.api.ResumeRoomRecordingResponse} m ResumeRoomRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResumeRoomRecordingResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ResumeRoomRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResumeRoomRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResumeRoomRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.ResumeRoomRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResumeRoomRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ResumeRoomRecordingResponse";
            };

            return ResumeRoomRecordingResponse;
        })();

        api.StreemRoomVideoCredentials = (function() {

            /**
             * Properties of a StreemRoomVideoCredentials.
             * @memberof streem.api
             * @interface IStreemRoomVideoCredentials
             * @property {streem.api.ITwilioRoomVideoCredentials|null} [twilioRoomVideoCredentials] StreemRoomVideoCredentials twilioRoomVideoCredentials
             * @property {streem.api.IAraasRoomVideoCredentials|null} [araasRoomVideoCredentials] StreemRoomVideoCredentials araasRoomVideoCredentials
             */

            /**
             * Constructs a new StreemRoomVideoCredentials.
             * @memberof streem.api
             * @classdesc Represents a StreemRoomVideoCredentials.
             * @implements IStreemRoomVideoCredentials
             * @constructor
             * @param {streem.api.IStreemRoomVideoCredentials=} [p] Properties to set
             */
            function StreemRoomVideoCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemRoomVideoCredentials twilioRoomVideoCredentials.
             * @member {streem.api.ITwilioRoomVideoCredentials|null|undefined} twilioRoomVideoCredentials
             * @memberof streem.api.StreemRoomVideoCredentials
             * @instance
             */
            StreemRoomVideoCredentials.prototype.twilioRoomVideoCredentials = null;

            /**
             * StreemRoomVideoCredentials araasRoomVideoCredentials.
             * @member {streem.api.IAraasRoomVideoCredentials|null|undefined} araasRoomVideoCredentials
             * @memberof streem.api.StreemRoomVideoCredentials
             * @instance
             */
            StreemRoomVideoCredentials.prototype.araasRoomVideoCredentials = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * StreemRoomVideoCredentials credentials.
             * @member {"twilioRoomVideoCredentials"|"araasRoomVideoCredentials"|undefined} credentials
             * @memberof streem.api.StreemRoomVideoCredentials
             * @instance
             */
            Object.defineProperty(StreemRoomVideoCredentials.prototype, "credentials", {
                get: $util.oneOfGetter($oneOfFields = ["twilioRoomVideoCredentials", "araasRoomVideoCredentials"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new StreemRoomVideoCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {streem.api.IStreemRoomVideoCredentials=} [properties] Properties to set
             * @returns {streem.api.StreemRoomVideoCredentials} StreemRoomVideoCredentials instance
             */
            StreemRoomVideoCredentials.create = function create(properties) {
                return new StreemRoomVideoCredentials(properties);
            };

            /**
             * Encodes the specified StreemRoomVideoCredentials message. Does not implicitly {@link streem.api.StreemRoomVideoCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {streem.api.IStreemRoomVideoCredentials} m StreemRoomVideoCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemRoomVideoCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.twilioRoomVideoCredentials != null && Object.hasOwnProperty.call(m, "twilioRoomVideoCredentials"))
                    $root.streem.api.TwilioRoomVideoCredentials.encode(m.twilioRoomVideoCredentials, w.uint32(802).fork()).ldelim();
                if (m.araasRoomVideoCredentials != null && Object.hasOwnProperty.call(m, "araasRoomVideoCredentials"))
                    $root.streem.api.AraasRoomVideoCredentials.encode(m.araasRoomVideoCredentials, w.uint32(810).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a StreemRoomVideoCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemRoomVideoCredentials} StreemRoomVideoCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemRoomVideoCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemRoomVideoCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 100: {
                            m.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemRoomVideoCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemRoomVideoCredentials} StreemRoomVideoCredentials
             */
            StreemRoomVideoCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemRoomVideoCredentials)
                    return d;
                var m = new $root.streem.api.StreemRoomVideoCredentials();
                if (d.twilioRoomVideoCredentials != null) {
                    if (typeof d.twilioRoomVideoCredentials !== "object")
                        throw TypeError(".streem.api.StreemRoomVideoCredentials.twilioRoomVideoCredentials: object expected");
                    m.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.fromObject(d.twilioRoomVideoCredentials);
                }
                if (d.araasRoomVideoCredentials != null) {
                    if (typeof d.araasRoomVideoCredentials !== "object")
                        throw TypeError(".streem.api.StreemRoomVideoCredentials.araasRoomVideoCredentials: object expected");
                    m.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.fromObject(d.araasRoomVideoCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemRoomVideoCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {streem.api.StreemRoomVideoCredentials} m StreemRoomVideoCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemRoomVideoCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.twilioRoomVideoCredentials != null && m.hasOwnProperty("twilioRoomVideoCredentials")) {
                    d.twilioRoomVideoCredentials = $root.streem.api.TwilioRoomVideoCredentials.toObject(m.twilioRoomVideoCredentials, o);
                    if (o.oneofs)
                        d.credentials = "twilioRoomVideoCredentials";
                }
                if (m.araasRoomVideoCredentials != null && m.hasOwnProperty("araasRoomVideoCredentials")) {
                    d.araasRoomVideoCredentials = $root.streem.api.AraasRoomVideoCredentials.toObject(m.araasRoomVideoCredentials, o);
                    if (o.oneofs)
                        d.credentials = "araasRoomVideoCredentials";
                }
                return d;
            };

            /**
             * Converts this StreemRoomVideoCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.StreemRoomVideoCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemRoomVideoCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemRoomVideoCredentials
             * @function getTypeUrl
             * @memberof streem.api.StreemRoomVideoCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemRoomVideoCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemRoomVideoCredentials";
            };

            return StreemRoomVideoCredentials;
        })();

        api.UpdateRoomRequest = (function() {

            /**
             * Properties of an UpdateRoomRequest.
             * @memberof streem.api
             * @interface IUpdateRoomRequest
             * @property {google.protobuf.IStringValue|null} [referenceId] UpdateRoomRequest referenceId
             * @property {Array.<streem.api.Room.IDetail>|null} [details] UpdateRoomRequest details
             */

            /**
             * Constructs a new UpdateRoomRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateRoomRequest.
             * @implements IUpdateRoomRequest
             * @constructor
             * @param {streem.api.IUpdateRoomRequest=} [p] Properties to set
             */
            function UpdateRoomRequest(p) {
                this.details = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateRoomRequest referenceId.
             * @member {google.protobuf.IStringValue|null|undefined} referenceId
             * @memberof streem.api.UpdateRoomRequest
             * @instance
             */
            UpdateRoomRequest.prototype.referenceId = null;

            /**
             * UpdateRoomRequest details.
             * @member {Array.<streem.api.Room.IDetail>} details
             * @memberof streem.api.UpdateRoomRequest
             * @instance
             */
            UpdateRoomRequest.prototype.details = $util.emptyArray;

            /**
             * Creates a new UpdateRoomRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {streem.api.IUpdateRoomRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateRoomRequest} UpdateRoomRequest instance
             */
            UpdateRoomRequest.create = function create(properties) {
                return new UpdateRoomRequest(properties);
            };

            /**
             * Encodes the specified UpdateRoomRequest message. Does not implicitly {@link streem.api.UpdateRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {streem.api.IUpdateRoomRequest} m UpdateRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateRoomRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    $root.google.protobuf.StringValue.encode(m.referenceId, w.uint32(10).fork()).ldelim();
                if (m.details != null && m.details.length) {
                    for (var i = 0; i < m.details.length; ++i)
                        $root.streem.api.Room.Detail.encode(m.details[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an UpdateRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateRoomRequest} UpdateRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateRoomRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateRoomRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.referenceId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            if (!(m.details && m.details.length))
                                m.details = [];
                            m.details.push($root.streem.api.Room.Detail.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateRoomRequest} UpdateRoomRequest
             */
            UpdateRoomRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateRoomRequest)
                    return d;
                var m = new $root.streem.api.UpdateRoomRequest();
                if (d.referenceId != null) {
                    if (typeof d.referenceId !== "object")
                        throw TypeError(".streem.api.UpdateRoomRequest.referenceId: object expected");
                    m.referenceId = $root.google.protobuf.StringValue.fromObject(d.referenceId);
                }
                if (d.details) {
                    if (!Array.isArray(d.details))
                        throw TypeError(".streem.api.UpdateRoomRequest.details: array expected");
                    m.details = [];
                    for (var i = 0; i < d.details.length; ++i) {
                        if (typeof d.details[i] !== "object")
                            throw TypeError(".streem.api.UpdateRoomRequest.details: object expected");
                        m.details[i] = $root.streem.api.Room.Detail.fromObject(d.details[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {streem.api.UpdateRoomRequest} m UpdateRoomRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateRoomRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.details = [];
                }
                if (o.defaults) {
                    d.referenceId = null;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = $root.google.protobuf.StringValue.toObject(m.referenceId, o);
                }
                if (m.details && m.details.length) {
                    d.details = [];
                    for (var j = 0; j < m.details.length; ++j) {
                        d.details[j] = $root.streem.api.Room.Detail.toObject(m.details[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UpdateRoomRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateRoomRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateRoomRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateRoomRequest";
            };

            return UpdateRoomRequest;
        })();

        api.UpdateRoomResponse = (function() {

            /**
             * Properties of an UpdateRoomResponse.
             * @memberof streem.api
             * @interface IUpdateRoomResponse
             * @property {streem.api.IRoom|null} [room] UpdateRoomResponse room
             */

            /**
             * Constructs a new UpdateRoomResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateRoomResponse.
             * @implements IUpdateRoomResponse
             * @constructor
             * @param {streem.api.IUpdateRoomResponse=} [p] Properties to set
             */
            function UpdateRoomResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateRoomResponse room.
             * @member {streem.api.IRoom|null|undefined} room
             * @memberof streem.api.UpdateRoomResponse
             * @instance
             */
            UpdateRoomResponse.prototype.room = null;

            /**
             * Creates a new UpdateRoomResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {streem.api.IUpdateRoomResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateRoomResponse} UpdateRoomResponse instance
             */
            UpdateRoomResponse.create = function create(properties) {
                return new UpdateRoomResponse(properties);
            };

            /**
             * Encodes the specified UpdateRoomResponse message. Does not implicitly {@link streem.api.UpdateRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {streem.api.IUpdateRoomResponse} m UpdateRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateRoomResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                    $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateRoomResponse} UpdateRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateRoomResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateRoomResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.room = $root.streem.api.Room.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateRoomResponse} UpdateRoomResponse
             */
            UpdateRoomResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateRoomResponse)
                    return d;
                var m = new $root.streem.api.UpdateRoomResponse();
                if (d.room != null) {
                    if (typeof d.room !== "object")
                        throw TypeError(".streem.api.UpdateRoomResponse.room: object expected");
                    m.room = $root.streem.api.Room.fromObject(d.room);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {streem.api.UpdateRoomResponse} m UpdateRoomResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateRoomResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.room = null;
                }
                if (m.room != null && m.hasOwnProperty("room")) {
                    d.room = $root.streem.api.Room.toObject(m.room, o);
                }
                return d;
            };

            /**
             * Converts this UpdateRoomResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateRoomResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateRoomResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateRoomResponse";
            };

            return UpdateRoomResponse;
        })();

        api.Uploads = (function() {

            /**
             * Constructs a new Uploads service.
             * @memberof streem.api
             * @classdesc Represents an Uploads
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Uploads(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Uploads.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Uploads;

            /**
             * Creates new Uploads service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Uploads
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Uploads} RPC service. Useful where requests and/or responses are streamed.
             */
            Uploads.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Uploads#createSignedUploadUrl}.
             * @memberof streem.api.Uploads
             * @typedef CreateSignedUploadUrlCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateSignedUploadUrlResponse} [response] CreateSignedUploadUrlResponse
             */

            /**
             * Calls CreateSignedUploadUrl.
             * @function createSignedUploadUrl
             * @memberof streem.api.Uploads
             * @instance
             * @param {streem.api.ICreateSignedUploadUrlRequest} request CreateSignedUploadUrlRequest message or plain object
             * @param {streem.api.Uploads.CreateSignedUploadUrlCallback} callback Node-style callback called with the error, if any, and CreateSignedUploadUrlResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Uploads.prototype.createSignedUploadUrl = function createSignedUploadUrl(request, callback) {
                return this.rpcCall(createSignedUploadUrl, $root.streem.api.CreateSignedUploadUrlRequest, $root.streem.api.CreateSignedUploadUrlResponse, request, callback);
            }, "name", { value: "CreateSignedUploadUrl" });

            /**
             * Calls CreateSignedUploadUrl.
             * @function createSignedUploadUrl
             * @memberof streem.api.Uploads
             * @instance
             * @param {streem.api.ICreateSignedUploadUrlRequest} request CreateSignedUploadUrlRequest message or plain object
             * @returns {Promise<streem.api.CreateSignedUploadUrlResponse>} Promise
             * @variation 2
             */

            return Uploads;
        })();

        api.CreateSignedUploadUrlRequest = (function() {

            /**
             * Properties of a CreateSignedUploadUrlRequest.
             * @memberof streem.api
             * @interface ICreateSignedUploadUrlRequest
             * @property {streem.api.UploadType|null} [type] CreateSignedUploadUrlRequest type
             * @property {string|null} [group] CreateSignedUploadUrlRequest group
             * @property {string|null} [sid] CreateSignedUploadUrlRequest sid
             * @property {string|null} [filename] CreateSignedUploadUrlRequest filename
             * @property {string|null} [companyCode] CreateSignedUploadUrlRequest companyCode
             */

            /**
             * Constructs a new CreateSignedUploadUrlRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateSignedUploadUrlRequest.
             * @implements ICreateSignedUploadUrlRequest
             * @constructor
             * @param {streem.api.ICreateSignedUploadUrlRequest=} [p] Properties to set
             */
            function CreateSignedUploadUrlRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSignedUploadUrlRequest type.
             * @member {streem.api.UploadType} type
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             */
            CreateSignedUploadUrlRequest.prototype.type = 0;

            /**
             * CreateSignedUploadUrlRequest group.
             * @member {string} group
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             */
            CreateSignedUploadUrlRequest.prototype.group = "";

            /**
             * CreateSignedUploadUrlRequest sid.
             * @member {string} sid
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             */
            CreateSignedUploadUrlRequest.prototype.sid = "";

            /**
             * CreateSignedUploadUrlRequest filename.
             * @member {string} filename
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             */
            CreateSignedUploadUrlRequest.prototype.filename = "";

            /**
             * CreateSignedUploadUrlRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             */
            CreateSignedUploadUrlRequest.prototype.companyCode = "";

            /**
             * Creates a new CreateSignedUploadUrlRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {streem.api.ICreateSignedUploadUrlRequest=} [properties] Properties to set
             * @returns {streem.api.CreateSignedUploadUrlRequest} CreateSignedUploadUrlRequest instance
             */
            CreateSignedUploadUrlRequest.create = function create(properties) {
                return new CreateSignedUploadUrlRequest(properties);
            };

            /**
             * Encodes the specified CreateSignedUploadUrlRequest message. Does not implicitly {@link streem.api.CreateSignedUploadUrlRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {streem.api.ICreateSignedUploadUrlRequest} m CreateSignedUploadUrlRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSignedUploadUrlRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(8).int32(m.type);
                if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                    w.uint32(18).string(m.group);
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(26).string(m.sid);
                if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                    w.uint32(34).string(m.filename);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(42).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a CreateSignedUploadUrlRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSignedUploadUrlRequest} CreateSignedUploadUrlRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSignedUploadUrlRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSignedUploadUrlRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.type = r.int32();
                            break;
                        }
                    case 2: {
                            m.group = r.string();
                            break;
                        }
                    case 3: {
                            m.sid = r.string();
                            break;
                        }
                    case 4: {
                            m.filename = r.string();
                            break;
                        }
                    case 5: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSignedUploadUrlRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSignedUploadUrlRequest} CreateSignedUploadUrlRequest
             */
            CreateSignedUploadUrlRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSignedUploadUrlRequest)
                    return d;
                var m = new $root.streem.api.CreateSignedUploadUrlRequest();
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "UPLOAD_TYPE_UNKNOWN":
                case 0:
                    m.type = 0;
                    break;
                case "UPLOAD_TYPE_STREEMSHOT":
                case 1:
                    m.type = 1;
                    break;
                case "UPLOAD_TYPE_RECORDING":
                case 2:
                    m.type = 2;
                    break;
                case "UPLOAD_TYPE_RECORDING_TRACK":
                case 3:
                    m.type = 3;
                    break;
                case "UPLOAD_TYPE_GPS_POSITION":
                case 4:
                    m.type = 4;
                    break;
                case "UPLOAD_TYPE_MESH":
                case 5:
                    m.type = 5;
                    break;
                case "UPLOAD_TYPE_SCENESHOT":
                case 6:
                    m.type = 6;
                    break;
                case "UPLOAD_TYPE_TRANSCRIPTION":
                case 7:
                    m.type = 7;
                    break;
                case "UPLOAD_TYPE_LAYOUT_ESTIMATION":
                case 8:
                    m.type = 8;
                    break;
                case "UPLOAD_TYPE_TEXTURED_MESH":
                case 9:
                    m.type = 9;
                    break;
                case "UPLOAD_TYPE_ROOM_NOTE":
                case 10:
                    m.type = 10;
                    break;
                case "UPLOAD_TYPE_STREEMSHOT_NOTE":
                case 11:
                    m.type = 11;
                    break;
                }
                if (d.group != null) {
                    m.group = String(d.group);
                }
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSignedUploadUrlRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {streem.api.CreateSignedUploadUrlRequest} m CreateSignedUploadUrlRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSignedUploadUrlRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "UPLOAD_TYPE_UNKNOWN" : 0;
                    d.group = "";
                    d.sid = "";
                    d.filename = "";
                    d.companyCode = "";
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.UploadType[m.type] === undefined ? m.type : $root.streem.api.UploadType[m.type] : m.type;
                }
                if (m.group != null && m.hasOwnProperty("group")) {
                    d.group = m.group;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this CreateSignedUploadUrlRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSignedUploadUrlRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSignedUploadUrlRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateSignedUploadUrlRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSignedUploadUrlRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSignedUploadUrlRequest";
            };

            return CreateSignedUploadUrlRequest;
        })();

        api.CreateSignedUploadUrlResponse = (function() {

            /**
             * Properties of a CreateSignedUploadUrlResponse.
             * @memberof streem.api
             * @interface ICreateSignedUploadUrlResponse
             * @property {string|null} [uploadUrl] CreateSignedUploadUrlResponse uploadUrl
             * @property {string|null} [storageUrl] CreateSignedUploadUrlResponse storageUrl
             */

            /**
             * Constructs a new CreateSignedUploadUrlResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateSignedUploadUrlResponse.
             * @implements ICreateSignedUploadUrlResponse
             * @constructor
             * @param {streem.api.ICreateSignedUploadUrlResponse=} [p] Properties to set
             */
            function CreateSignedUploadUrlResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateSignedUploadUrlResponse uploadUrl.
             * @member {string} uploadUrl
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @instance
             */
            CreateSignedUploadUrlResponse.prototype.uploadUrl = "";

            /**
             * CreateSignedUploadUrlResponse storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @instance
             */
            CreateSignedUploadUrlResponse.prototype.storageUrl = "";

            /**
             * Creates a new CreateSignedUploadUrlResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {streem.api.ICreateSignedUploadUrlResponse=} [properties] Properties to set
             * @returns {streem.api.CreateSignedUploadUrlResponse} CreateSignedUploadUrlResponse instance
             */
            CreateSignedUploadUrlResponse.create = function create(properties) {
                return new CreateSignedUploadUrlResponse(properties);
            };

            /**
             * Encodes the specified CreateSignedUploadUrlResponse message. Does not implicitly {@link streem.api.CreateSignedUploadUrlResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {streem.api.ICreateSignedUploadUrlResponse} m CreateSignedUploadUrlResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSignedUploadUrlResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uploadUrl != null && Object.hasOwnProperty.call(m, "uploadUrl"))
                    w.uint32(10).string(m.uploadUrl);
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(18).string(m.storageUrl);
                return w;
            };

            /**
             * Decodes a CreateSignedUploadUrlResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateSignedUploadUrlResponse} CreateSignedUploadUrlResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSignedUploadUrlResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateSignedUploadUrlResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.uploadUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.storageUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateSignedUploadUrlResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateSignedUploadUrlResponse} CreateSignedUploadUrlResponse
             */
            CreateSignedUploadUrlResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateSignedUploadUrlResponse)
                    return d;
                var m = new $root.streem.api.CreateSignedUploadUrlResponse();
                if (d.uploadUrl != null) {
                    m.uploadUrl = String(d.uploadUrl);
                }
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateSignedUploadUrlResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {streem.api.CreateSignedUploadUrlResponse} m CreateSignedUploadUrlResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSignedUploadUrlResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.uploadUrl = "";
                    d.storageUrl = "";
                }
                if (m.uploadUrl != null && m.hasOwnProperty("uploadUrl")) {
                    d.uploadUrl = m.uploadUrl;
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                return d;
            };

            /**
             * Converts this CreateSignedUploadUrlResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSignedUploadUrlResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSignedUploadUrlResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateSignedUploadUrlResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSignedUploadUrlResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateSignedUploadUrlResponse";
            };

            return CreateSignedUploadUrlResponse;
        })();

        api.Group = (function() {

            /**
             * Properties of a Group.
             * @memberof streem.api
             * @interface IGroup
             * @property {string|null} [sid] Group sid
             * @property {string|null} [name] Group name
             * @property {string|null} [description] Group description
             */

            /**
             * Constructs a new Group.
             * @memberof streem.api
             * @classdesc Represents a Group.
             * @implements IGroup
             * @constructor
             * @param {streem.api.IGroup=} [p] Properties to set
             */
            function Group(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Group sid.
             * @member {string} sid
             * @memberof streem.api.Group
             * @instance
             */
            Group.prototype.sid = "";

            /**
             * Group name.
             * @member {string} name
             * @memberof streem.api.Group
             * @instance
             */
            Group.prototype.name = "";

            /**
             * Group description.
             * @member {string} description
             * @memberof streem.api.Group
             * @instance
             */
            Group.prototype.description = "";

            /**
             * Creates a new Group instance using the specified properties.
             * @function create
             * @memberof streem.api.Group
             * @static
             * @param {streem.api.IGroup=} [properties] Properties to set
             * @returns {streem.api.Group} Group instance
             */
            Group.create = function create(properties) {
                return new Group(properties);
            };

            /**
             * Encodes the specified Group message. Does not implicitly {@link streem.api.Group.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Group
             * @static
             * @param {streem.api.IGroup} m Group message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Group.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(18).string(m.description);
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(26).string(m.sid);
                return w;
            };

            /**
             * Decodes a Group message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Group
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Group} Group
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Group.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Group();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 3: {
                            m.sid = r.string();
                            break;
                        }
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.description = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Group message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Group
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Group} Group
             */
            Group.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Group)
                    return d;
                var m = new $root.streem.api.Group();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                return m;
            };

            /**
             * Creates a plain object from a Group message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Group
             * @static
             * @param {streem.api.Group} m Group
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Group.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.description = "";
                    d.sid = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                return d;
            };

            /**
             * Converts this Group to JSON.
             * @function toJSON
             * @memberof streem.api.Group
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Group.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Group
             * @function getTypeUrl
             * @memberof streem.api.Group
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Group.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Group";
            };

            return Group;
        })();

        /**
         * State diagram:
         * 
         * @startuml
         * [*] --> QUEUED : Reservation queued
         * [*] --> NOT_MATCHED : queue=false, no expert\n was immediately available
         * QUEUED --> CANCELED : Reservation\nexplicitly\ncanceled
         * QUEUED --> CONFIRMING : Waiting for the frontend to confirm\n reservation to expert match...
         * CONFIRMING --> MATCHED : Expert confirmed, but no call yet
         * CONFIRMING --> QUEUED : When Reservation is in CONFIRMING status state for longer than 10 seconds.
         * CONFIRMING --> CANCELED : Customer canceled call while confirming match.
         * MATCHED --> INITIATED : Customer started call before\n expiration. (Room has been created)
         * INITIATED --> CANCELED : Customer canceled the\ncall to the Expert
         * INITIATED --> CONNECTED : Expert active on call
         * INITIATED --> FAILED : Expert never joined call
         * MATCHED --> EXPIRED : Matched, but no call\nconnected before expiration (90secs)
         * MATCHED --> CANCELED : Canceled reservation after\nbeing matched with expert
         * CONNECTED --> COMPLETED : Completed reservation after\nbeing connected to expert
         * @enduml
         * @name streem.api.GroupReservationStatus
         * @enum {number}
         * @property {number} GROUP_RESERVATION_STATUS_INVALID=0 GROUP_RESERVATION_STATUS_INVALID value
         * @property {number} GROUP_RESERVATION_STATUS_QUEUED=1 GROUP_RESERVATION_STATUS_QUEUED value
         * @property {number} GROUP_RESERVATION_STATUS_CANCELED=2 GROUP_RESERVATION_STATUS_CANCELED value
         * @property {number} GROUP_RESERVATION_STATUS_MATCHED=3 GROUP_RESERVATION_STATUS_MATCHED value
         * @property {number} GROUP_RESERVATION_STATUS_CONNECTED=4 GROUP_RESERVATION_STATUS_CONNECTED value
         * @property {number} GROUP_RESERVATION_STATUS_EXPIRED=5 GROUP_RESERVATION_STATUS_EXPIRED value
         * @property {number} GROUP_RESERVATION_STATUS_NOT_MATCHED=6 GROUP_RESERVATION_STATUS_NOT_MATCHED value
         * @property {number} GROUP_RESERVATION_STATUS_COMPLETED=7 GROUP_RESERVATION_STATUS_COMPLETED value
         * @property {number} GROUP_RESERVATION_STATUS_CONFIRMING=8 GROUP_RESERVATION_STATUS_CONFIRMING value
         * @property {number} GROUP_RESERVATION_STATUS_INITIATED=9 GROUP_RESERVATION_STATUS_INITIATED value
         * @property {number} GROUP_RESERVATION_STATUS_FAILED=10 GROUP_RESERVATION_STATUS_FAILED value
         */
        api.GroupReservationStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GROUP_RESERVATION_STATUS_INVALID"] = 0;
            values[valuesById[1] = "GROUP_RESERVATION_STATUS_QUEUED"] = 1;
            values[valuesById[2] = "GROUP_RESERVATION_STATUS_CANCELED"] = 2;
            values[valuesById[3] = "GROUP_RESERVATION_STATUS_MATCHED"] = 3;
            values[valuesById[4] = "GROUP_RESERVATION_STATUS_CONNECTED"] = 4;
            values[valuesById[5] = "GROUP_RESERVATION_STATUS_EXPIRED"] = 5;
            values[valuesById[6] = "GROUP_RESERVATION_STATUS_NOT_MATCHED"] = 6;
            values[valuesById[7] = "GROUP_RESERVATION_STATUS_COMPLETED"] = 7;
            values[valuesById[8] = "GROUP_RESERVATION_STATUS_CONFIRMING"] = 8;
            values[valuesById[9] = "GROUP_RESERVATION_STATUS_INITIATED"] = 9;
            values[valuesById[10] = "GROUP_RESERVATION_STATUS_FAILED"] = 10;
            return values;
        })();

        api.GroupReservation = (function() {

            /**
             * Properties of a GroupReservation.
             * @memberof streem.api
             * @interface IGroupReservation
             * @property {string|null} [companySid] GroupReservation companySid
             * @property {streem.api.IGroup|null} [group] GroupReservation group
             * @property {string|null} [reservationSid] GroupReservation reservationSid
             * @property {string|null} [externalUserId] GroupReservation externalUserId
             * @property {number|null} [priority] GroupReservation priority
             * @property {number|null} [reserveForSeconds] GroupReservation reserveForSeconds
             * @property {streem.api.GroupReservationStatus|null} [reservationStatus] GroupReservation reservationStatus
             * @property {number|null} [queuePosition] GroupReservation queuePosition
             * @property {streem.api.IUser|null} [reservedUser] GroupReservation reservedUser
             * @property {google.protobuf.ITimestamp|null} [reservedUntil] GroupReservation reservedUntil
             * @property {google.protobuf.ITimestamp|null} [connectedAt] GroupReservation connectedAt
             * @property {google.protobuf.ITimestamp|null} [completedAt] GroupReservation completedAt
             * @property {Array.<streem.api.GroupReservation.IDetail>|null} [details] GroupReservation details
             * @property {string|null} [roomSid] GroupReservation roomSid
             * @property {google.protobuf.ITimestamp|null} [initiatedAt] GroupReservation initiatedAt
             * @property {google.protobuf.ITimestamp|null} [failedAt] GroupReservation failedAt
             * @property {google.protobuf.ITimestamp|null} [expiredAt] GroupReservation expiredAt
             * @property {google.protobuf.ITimestamp|null} [requestedAt] GroupReservation requestedAt
             * @property {google.protobuf.ITimestamp|null} [estimatedWaitUntil] GroupReservation estimatedWaitUntil
             * @property {string|null} [referenceId] GroupReservation referenceId
             * @property {string|null} [integrationId] GroupReservation integrationId
             * @property {string|null} [reconnectPreviousReservationSid] GroupReservation reconnectPreviousReservationSid
             * @property {streem.api.ICallDetail|null} [callDetail] GroupReservation callDetail
             */

            /**
             * Constructs a new GroupReservation.
             * @memberof streem.api
             * @classdesc Represents a GroupReservation.
             * @implements IGroupReservation
             * @constructor
             * @param {streem.api.IGroupReservation=} [p] Properties to set
             */
            function GroupReservation(p) {
                this.details = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GroupReservation companySid.
             * @member {string} companySid
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.companySid = "";

            /**
             * GroupReservation group.
             * @member {streem.api.IGroup|null|undefined} group
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.group = null;

            /**
             * GroupReservation reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reservationSid = "";

            /**
             * GroupReservation externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.externalUserId = "";

            /**
             * GroupReservation priority.
             * @member {number} priority
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.priority = 0;

            /**
             * GroupReservation reserveForSeconds.
             * @member {number} reserveForSeconds
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reserveForSeconds = 0;

            /**
             * GroupReservation reservationStatus.
             * @member {streem.api.GroupReservationStatus} reservationStatus
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reservationStatus = 0;

            /**
             * GroupReservation queuePosition.
             * @member {number} queuePosition
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.queuePosition = 0;

            /**
             * GroupReservation reservedUser.
             * @member {streem.api.IUser|null|undefined} reservedUser
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reservedUser = null;

            /**
             * GroupReservation reservedUntil.
             * @member {google.protobuf.ITimestamp|null|undefined} reservedUntil
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reservedUntil = null;

            /**
             * GroupReservation connectedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} connectedAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.connectedAt = null;

            /**
             * GroupReservation completedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} completedAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.completedAt = null;

            /**
             * GroupReservation details.
             * @member {Array.<streem.api.GroupReservation.IDetail>} details
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.details = $util.emptyArray;

            /**
             * GroupReservation roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.roomSid = "";

            /**
             * GroupReservation initiatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} initiatedAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.initiatedAt = null;

            /**
             * GroupReservation failedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} failedAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.failedAt = null;

            /**
             * GroupReservation expiredAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiredAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.expiredAt = null;

            /**
             * GroupReservation requestedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.requestedAt = null;

            /**
             * GroupReservation estimatedWaitUntil.
             * @member {google.protobuf.ITimestamp|null|undefined} estimatedWaitUntil
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.estimatedWaitUntil = null;

            /**
             * GroupReservation referenceId.
             * @member {string} referenceId
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.referenceId = "";

            /**
             * GroupReservation integrationId.
             * @member {string} integrationId
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.integrationId = "";

            /**
             * GroupReservation reconnectPreviousReservationSid.
             * @member {string} reconnectPreviousReservationSid
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.reconnectPreviousReservationSid = "";

            /**
             * GroupReservation callDetail.
             * @member {streem.api.ICallDetail|null|undefined} callDetail
             * @memberof streem.api.GroupReservation
             * @instance
             */
            GroupReservation.prototype.callDetail = null;

            /**
             * Creates a new GroupReservation instance using the specified properties.
             * @function create
             * @memberof streem.api.GroupReservation
             * @static
             * @param {streem.api.IGroupReservation=} [properties] Properties to set
             * @returns {streem.api.GroupReservation} GroupReservation instance
             */
            GroupReservation.create = function create(properties) {
                return new GroupReservation(properties);
            };

            /**
             * Encodes the specified GroupReservation message. Does not implicitly {@link streem.api.GroupReservation.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GroupReservation
             * @static
             * @param {streem.api.IGroupReservation} m GroupReservation message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GroupReservation.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                    $root.streem.api.Group.encode(m.group, w.uint32(18).fork()).ldelim();
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(26).string(m.reservationSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(34).string(m.externalUserId);
                if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                    w.uint32(40).int32(m.priority);
                if (m.reserveForSeconds != null && Object.hasOwnProperty.call(m, "reserveForSeconds"))
                    w.uint32(48).int32(m.reserveForSeconds);
                if (m.reservationStatus != null && Object.hasOwnProperty.call(m, "reservationStatus"))
                    w.uint32(56).int32(m.reservationStatus);
                if (m.queuePosition != null && Object.hasOwnProperty.call(m, "queuePosition"))
                    w.uint32(64).int32(m.queuePosition);
                if (m.reservedUser != null && Object.hasOwnProperty.call(m, "reservedUser"))
                    $root.streem.api.User.encode(m.reservedUser, w.uint32(74).fork()).ldelim();
                if (m.reservedUntil != null && Object.hasOwnProperty.call(m, "reservedUntil"))
                    $root.google.protobuf.Timestamp.encode(m.reservedUntil, w.uint32(82).fork()).ldelim();
                if (m.connectedAt != null && Object.hasOwnProperty.call(m, "connectedAt"))
                    $root.google.protobuf.Timestamp.encode(m.connectedAt, w.uint32(90).fork()).ldelim();
                if (m.completedAt != null && Object.hasOwnProperty.call(m, "completedAt"))
                    $root.google.protobuf.Timestamp.encode(m.completedAt, w.uint32(98).fork()).ldelim();
                if (m.details != null && m.details.length) {
                    for (var i = 0; i < m.details.length; ++i)
                        $root.streem.api.GroupReservation.Detail.encode(m.details[i], w.uint32(106).fork()).ldelim();
                }
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(114).string(m.roomSid);
                if (m.initiatedAt != null && Object.hasOwnProperty.call(m, "initiatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.initiatedAt, w.uint32(122).fork()).ldelim();
                if (m.failedAt != null && Object.hasOwnProperty.call(m, "failedAt"))
                    $root.google.protobuf.Timestamp.encode(m.failedAt, w.uint32(130).fork()).ldelim();
                if (m.expiredAt != null && Object.hasOwnProperty.call(m, "expiredAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiredAt, w.uint32(138).fork()).ldelim();
                if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                    $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(146).fork()).ldelim();
                if (m.estimatedWaitUntil != null && Object.hasOwnProperty.call(m, "estimatedWaitUntil"))
                    $root.google.protobuf.Timestamp.encode(m.estimatedWaitUntil, w.uint32(154).fork()).ldelim();
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(162).string(m.referenceId);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(170).string(m.integrationId);
                if (m.reconnectPreviousReservationSid != null && Object.hasOwnProperty.call(m, "reconnectPreviousReservationSid"))
                    w.uint32(178).string(m.reconnectPreviousReservationSid);
                if (m.callDetail != null && Object.hasOwnProperty.call(m, "callDetail"))
                    $root.streem.api.CallDetail.encode(m.callDetail, w.uint32(186).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GroupReservation message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GroupReservation
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GroupReservation} GroupReservation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GroupReservation.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GroupReservation();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    case 2: {
                            m.group = $root.streem.api.Group.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.reservationSid = r.string();
                            break;
                        }
                    case 4: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 5: {
                            m.priority = r.int32();
                            break;
                        }
                    case 6: {
                            m.reserveForSeconds = r.int32();
                            break;
                        }
                    case 7: {
                            m.reservationStatus = r.int32();
                            break;
                        }
                    case 8: {
                            m.queuePosition = r.int32();
                            break;
                        }
                    case 9: {
                            m.reservedUser = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    case 10: {
                            m.reservedUntil = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 11: {
                            m.connectedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.completedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 13: {
                            if (!(m.details && m.details.length))
                                m.details = [];
                            m.details.push($root.streem.api.GroupReservation.Detail.decode(r, r.uint32()));
                            break;
                        }
                    case 14: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 15: {
                            m.initiatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 16: {
                            m.failedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 17: {
                            m.expiredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 18: {
                            m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 19: {
                            m.estimatedWaitUntil = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 20: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 21: {
                            m.integrationId = r.string();
                            break;
                        }
                    case 22: {
                            m.reconnectPreviousReservationSid = r.string();
                            break;
                        }
                    case 23: {
                            m.callDetail = $root.streem.api.CallDetail.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GroupReservation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GroupReservation
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GroupReservation} GroupReservation
             */
            GroupReservation.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GroupReservation)
                    return d;
                var m = new $root.streem.api.GroupReservation();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.group != null) {
                    if (typeof d.group !== "object")
                        throw TypeError(".streem.api.GroupReservation.group: object expected");
                    m.group = $root.streem.api.Group.fromObject(d.group);
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.priority != null) {
                    m.priority = d.priority | 0;
                }
                if (d.reserveForSeconds != null) {
                    m.reserveForSeconds = d.reserveForSeconds | 0;
                }
                switch (d.reservationStatus) {
                default:
                    if (typeof d.reservationStatus === "number") {
                        m.reservationStatus = d.reservationStatus;
                        break;
                    }
                    break;
                case "GROUP_RESERVATION_STATUS_INVALID":
                case 0:
                    m.reservationStatus = 0;
                    break;
                case "GROUP_RESERVATION_STATUS_QUEUED":
                case 1:
                    m.reservationStatus = 1;
                    break;
                case "GROUP_RESERVATION_STATUS_CANCELED":
                case 2:
                    m.reservationStatus = 2;
                    break;
                case "GROUP_RESERVATION_STATUS_MATCHED":
                case 3:
                    m.reservationStatus = 3;
                    break;
                case "GROUP_RESERVATION_STATUS_CONNECTED":
                case 4:
                    m.reservationStatus = 4;
                    break;
                case "GROUP_RESERVATION_STATUS_EXPIRED":
                case 5:
                    m.reservationStatus = 5;
                    break;
                case "GROUP_RESERVATION_STATUS_NOT_MATCHED":
                case 6:
                    m.reservationStatus = 6;
                    break;
                case "GROUP_RESERVATION_STATUS_COMPLETED":
                case 7:
                    m.reservationStatus = 7;
                    break;
                case "GROUP_RESERVATION_STATUS_CONFIRMING":
                case 8:
                    m.reservationStatus = 8;
                    break;
                case "GROUP_RESERVATION_STATUS_INITIATED":
                case 9:
                    m.reservationStatus = 9;
                    break;
                case "GROUP_RESERVATION_STATUS_FAILED":
                case 10:
                    m.reservationStatus = 10;
                    break;
                }
                if (d.queuePosition != null) {
                    m.queuePosition = d.queuePosition | 0;
                }
                if (d.reservedUser != null) {
                    if (typeof d.reservedUser !== "object")
                        throw TypeError(".streem.api.GroupReservation.reservedUser: object expected");
                    m.reservedUser = $root.streem.api.User.fromObject(d.reservedUser);
                }
                if (d.reservedUntil != null) {
                    if (typeof d.reservedUntil !== "object")
                        throw TypeError(".streem.api.GroupReservation.reservedUntil: object expected");
                    m.reservedUntil = $root.google.protobuf.Timestamp.fromObject(d.reservedUntil);
                }
                if (d.connectedAt != null) {
                    if (typeof d.connectedAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.connectedAt: object expected");
                    m.connectedAt = $root.google.protobuf.Timestamp.fromObject(d.connectedAt);
                }
                if (d.completedAt != null) {
                    if (typeof d.completedAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.completedAt: object expected");
                    m.completedAt = $root.google.protobuf.Timestamp.fromObject(d.completedAt);
                }
                if (d.details) {
                    if (!Array.isArray(d.details))
                        throw TypeError(".streem.api.GroupReservation.details: array expected");
                    m.details = [];
                    for (var i = 0; i < d.details.length; ++i) {
                        if (typeof d.details[i] !== "object")
                            throw TypeError(".streem.api.GroupReservation.details: object expected");
                        m.details[i] = $root.streem.api.GroupReservation.Detail.fromObject(d.details[i]);
                    }
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.initiatedAt != null) {
                    if (typeof d.initiatedAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.initiatedAt: object expected");
                    m.initiatedAt = $root.google.protobuf.Timestamp.fromObject(d.initiatedAt);
                }
                if (d.failedAt != null) {
                    if (typeof d.failedAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.failedAt: object expected");
                    m.failedAt = $root.google.protobuf.Timestamp.fromObject(d.failedAt);
                }
                if (d.expiredAt != null) {
                    if (typeof d.expiredAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.expiredAt: object expected");
                    m.expiredAt = $root.google.protobuf.Timestamp.fromObject(d.expiredAt);
                }
                if (d.requestedAt != null) {
                    if (typeof d.requestedAt !== "object")
                        throw TypeError(".streem.api.GroupReservation.requestedAt: object expected");
                    m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                }
                if (d.estimatedWaitUntil != null) {
                    if (typeof d.estimatedWaitUntil !== "object")
                        throw TypeError(".streem.api.GroupReservation.estimatedWaitUntil: object expected");
                    m.estimatedWaitUntil = $root.google.protobuf.Timestamp.fromObject(d.estimatedWaitUntil);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                if (d.reconnectPreviousReservationSid != null) {
                    m.reconnectPreviousReservationSid = String(d.reconnectPreviousReservationSid);
                }
                if (d.callDetail != null) {
                    if (typeof d.callDetail !== "object")
                        throw TypeError(".streem.api.GroupReservation.callDetail: object expected");
                    m.callDetail = $root.streem.api.CallDetail.fromObject(d.callDetail);
                }
                return m;
            };

            /**
             * Creates a plain object from a GroupReservation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GroupReservation
             * @static
             * @param {streem.api.GroupReservation} m GroupReservation
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GroupReservation.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.details = [];
                }
                if (o.defaults) {
                    d.companySid = "";
                    d.group = null;
                    d.reservationSid = "";
                    d.externalUserId = "";
                    d.priority = 0;
                    d.reserveForSeconds = 0;
                    d.reservationStatus = o.enums === String ? "GROUP_RESERVATION_STATUS_INVALID" : 0;
                    d.queuePosition = 0;
                    d.reservedUser = null;
                    d.reservedUntil = null;
                    d.connectedAt = null;
                    d.completedAt = null;
                    d.roomSid = "";
                    d.initiatedAt = null;
                    d.failedAt = null;
                    d.expiredAt = null;
                    d.requestedAt = null;
                    d.estimatedWaitUntil = null;
                    d.referenceId = "";
                    d.integrationId = "";
                    d.reconnectPreviousReservationSid = "";
                    d.callDetail = null;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.group != null && m.hasOwnProperty("group")) {
                    d.group = $root.streem.api.Group.toObject(m.group, o);
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.priority != null && m.hasOwnProperty("priority")) {
                    d.priority = m.priority;
                }
                if (m.reserveForSeconds != null && m.hasOwnProperty("reserveForSeconds")) {
                    d.reserveForSeconds = m.reserveForSeconds;
                }
                if (m.reservationStatus != null && m.hasOwnProperty("reservationStatus")) {
                    d.reservationStatus = o.enums === String ? $root.streem.api.GroupReservationStatus[m.reservationStatus] === undefined ? m.reservationStatus : $root.streem.api.GroupReservationStatus[m.reservationStatus] : m.reservationStatus;
                }
                if (m.queuePosition != null && m.hasOwnProperty("queuePosition")) {
                    d.queuePosition = m.queuePosition;
                }
                if (m.reservedUser != null && m.hasOwnProperty("reservedUser")) {
                    d.reservedUser = $root.streem.api.User.toObject(m.reservedUser, o);
                }
                if (m.reservedUntil != null && m.hasOwnProperty("reservedUntil")) {
                    d.reservedUntil = $root.google.protobuf.Timestamp.toObject(m.reservedUntil, o);
                }
                if (m.connectedAt != null && m.hasOwnProperty("connectedAt")) {
                    d.connectedAt = $root.google.protobuf.Timestamp.toObject(m.connectedAt, o);
                }
                if (m.completedAt != null && m.hasOwnProperty("completedAt")) {
                    d.completedAt = $root.google.protobuf.Timestamp.toObject(m.completedAt, o);
                }
                if (m.details && m.details.length) {
                    d.details = [];
                    for (var j = 0; j < m.details.length; ++j) {
                        d.details[j] = $root.streem.api.GroupReservation.Detail.toObject(m.details[j], o);
                    }
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.initiatedAt != null && m.hasOwnProperty("initiatedAt")) {
                    d.initiatedAt = $root.google.protobuf.Timestamp.toObject(m.initiatedAt, o);
                }
                if (m.failedAt != null && m.hasOwnProperty("failedAt")) {
                    d.failedAt = $root.google.protobuf.Timestamp.toObject(m.failedAt, o);
                }
                if (m.expiredAt != null && m.hasOwnProperty("expiredAt")) {
                    d.expiredAt = $root.google.protobuf.Timestamp.toObject(m.expiredAt, o);
                }
                if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                    d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                }
                if (m.estimatedWaitUntil != null && m.hasOwnProperty("estimatedWaitUntil")) {
                    d.estimatedWaitUntil = $root.google.protobuf.Timestamp.toObject(m.estimatedWaitUntil, o);
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                if (m.reconnectPreviousReservationSid != null && m.hasOwnProperty("reconnectPreviousReservationSid")) {
                    d.reconnectPreviousReservationSid = m.reconnectPreviousReservationSid;
                }
                if (m.callDetail != null && m.hasOwnProperty("callDetail")) {
                    d.callDetail = $root.streem.api.CallDetail.toObject(m.callDetail, o);
                }
                return d;
            };

            /**
             * Converts this GroupReservation to JSON.
             * @function toJSON
             * @memberof streem.api.GroupReservation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GroupReservation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GroupReservation
             * @function getTypeUrl
             * @memberof streem.api.GroupReservation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GroupReservation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GroupReservation";
            };

            GroupReservation.Detail = (function() {

                /**
                 * Properties of a Detail.
                 * @memberof streem.api.GroupReservation
                 * @interface IDetail
                 * @property {string|null} [label] Detail label
                 * @property {string|null} [value] Detail value
                 */

                /**
                 * Constructs a new Detail.
                 * @memberof streem.api.GroupReservation
                 * @classdesc Represents a Detail.
                 * @implements IDetail
                 * @constructor
                 * @param {streem.api.GroupReservation.IDetail=} [p] Properties to set
                 */
                function Detail(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Detail label.
                 * @member {string} label
                 * @memberof streem.api.GroupReservation.Detail
                 * @instance
                 */
                Detail.prototype.label = "";

                /**
                 * Detail value.
                 * @member {string} value
                 * @memberof streem.api.GroupReservation.Detail
                 * @instance
                 */
                Detail.prototype.value = "";

                /**
                 * Creates a new Detail instance using the specified properties.
                 * @function create
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {streem.api.GroupReservation.IDetail=} [properties] Properties to set
                 * @returns {streem.api.GroupReservation.Detail} Detail instance
                 */
                Detail.create = function create(properties) {
                    return new Detail(properties);
                };

                /**
                 * Encodes the specified Detail message. Does not implicitly {@link streem.api.GroupReservation.Detail.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {streem.api.GroupReservation.IDetail} m Detail message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Detail.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                        w.uint32(10).string(m.label);
                    if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                        w.uint32(18).string(m.value);
                    return w;
                };

                /**
                 * Decodes a Detail message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.GroupReservation.Detail} Detail
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Detail.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GroupReservation.Detail();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.label = r.string();
                                break;
                            }
                        case 2: {
                                m.value = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Detail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.GroupReservation.Detail} Detail
                 */
                Detail.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.GroupReservation.Detail)
                        return d;
                    var m = new $root.streem.api.GroupReservation.Detail();
                    if (d.label != null) {
                        m.label = String(d.label);
                    }
                    if (d.value != null) {
                        m.value = String(d.value);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Detail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {streem.api.GroupReservation.Detail} m Detail
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Detail.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.label = "";
                        d.value = "";
                    }
                    if (m.label != null && m.hasOwnProperty("label")) {
                        d.label = m.label;
                    }
                    if (m.value != null && m.hasOwnProperty("value")) {
                        d.value = m.value;
                    }
                    return d;
                };

                /**
                 * Converts this Detail to JSON.
                 * @function toJSON
                 * @memberof streem.api.GroupReservation.Detail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Detail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Detail
                 * @function getTypeUrl
                 * @memberof streem.api.GroupReservation.Detail
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Detail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.GroupReservation.Detail";
                };

                return Detail;
            })();

            return GroupReservation;
        })();

        api.PriorityWaitTime = (function() {

            /**
             * Properties of a PriorityWaitTime.
             * @memberof streem.api
             * @interface IPriorityWaitTime
             * @property {number|null} [priority] PriorityWaitTime priority
             * @property {google.protobuf.ITimestamp|null} [estimatedWaitUntil] PriorityWaitTime estimatedWaitUntil
             */

            /**
             * Constructs a new PriorityWaitTime.
             * @memberof streem.api
             * @classdesc Represents a PriorityWaitTime.
             * @implements IPriorityWaitTime
             * @constructor
             * @param {streem.api.IPriorityWaitTime=} [p] Properties to set
             */
            function PriorityWaitTime(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PriorityWaitTime priority.
             * @member {number} priority
             * @memberof streem.api.PriorityWaitTime
             * @instance
             */
            PriorityWaitTime.prototype.priority = 0;

            /**
             * PriorityWaitTime estimatedWaitUntil.
             * @member {google.protobuf.ITimestamp|null|undefined} estimatedWaitUntil
             * @memberof streem.api.PriorityWaitTime
             * @instance
             */
            PriorityWaitTime.prototype.estimatedWaitUntil = null;

            /**
             * Creates a new PriorityWaitTime instance using the specified properties.
             * @function create
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {streem.api.IPriorityWaitTime=} [properties] Properties to set
             * @returns {streem.api.PriorityWaitTime} PriorityWaitTime instance
             */
            PriorityWaitTime.create = function create(properties) {
                return new PriorityWaitTime(properties);
            };

            /**
             * Encodes the specified PriorityWaitTime message. Does not implicitly {@link streem.api.PriorityWaitTime.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {streem.api.IPriorityWaitTime} m PriorityWaitTime message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PriorityWaitTime.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                    w.uint32(8).int32(m.priority);
                if (m.estimatedWaitUntil != null && Object.hasOwnProperty.call(m, "estimatedWaitUntil"))
                    $root.google.protobuf.Timestamp.encode(m.estimatedWaitUntil, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a PriorityWaitTime message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PriorityWaitTime} PriorityWaitTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PriorityWaitTime.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PriorityWaitTime();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.priority = r.int32();
                            break;
                        }
                    case 2: {
                            m.estimatedWaitUntil = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PriorityWaitTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PriorityWaitTime} PriorityWaitTime
             */
            PriorityWaitTime.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PriorityWaitTime)
                    return d;
                var m = new $root.streem.api.PriorityWaitTime();
                if (d.priority != null) {
                    m.priority = d.priority | 0;
                }
                if (d.estimatedWaitUntil != null) {
                    if (typeof d.estimatedWaitUntil !== "object")
                        throw TypeError(".streem.api.PriorityWaitTime.estimatedWaitUntil: object expected");
                    m.estimatedWaitUntil = $root.google.protobuf.Timestamp.fromObject(d.estimatedWaitUntil);
                }
                return m;
            };

            /**
             * Creates a plain object from a PriorityWaitTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {streem.api.PriorityWaitTime} m PriorityWaitTime
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PriorityWaitTime.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.priority = 0;
                    d.estimatedWaitUntil = null;
                }
                if (m.priority != null && m.hasOwnProperty("priority")) {
                    d.priority = m.priority;
                }
                if (m.estimatedWaitUntil != null && m.hasOwnProperty("estimatedWaitUntil")) {
                    d.estimatedWaitUntil = $root.google.protobuf.Timestamp.toObject(m.estimatedWaitUntil, o);
                }
                return d;
            };

            /**
             * Converts this PriorityWaitTime to JSON.
             * @function toJSON
             * @memberof streem.api.PriorityWaitTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PriorityWaitTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PriorityWaitTime
             * @function getTypeUrl
             * @memberof streem.api.PriorityWaitTime
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PriorityWaitTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PriorityWaitTime";
            };

            return PriorityWaitTime;
        })();

        api.Image = (function() {

            /**
             * Constructs a new Image service.
             * @memberof streem.api
             * @classdesc Represents an Image
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Image(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Image.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Image;

            /**
             * Creates new Image service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Image
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Image} RPC service. Useful where requests and/or responses are streamed.
             */
            Image.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Image#runImageDetector}.
             * @memberof streem.api.Image
             * @typedef RunImageDetectorCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.RunImageDetectorResponse} [response] RunImageDetectorResponse
             */

            /**
             * Calls RunImageDetector.
             * @function runImageDetector
             * @memberof streem.api.Image
             * @instance
             * @param {streem.api.IRunImageDetectorRequest} request RunImageDetectorRequest message or plain object
             * @param {streem.api.Image.RunImageDetectorCallback} callback Node-style callback called with the error, if any, and RunImageDetectorResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Image.prototype.runImageDetector = function runImageDetector(request, callback) {
                return this.rpcCall(runImageDetector, $root.streem.api.RunImageDetectorRequest, $root.streem.api.RunImageDetectorResponse, request, callback);
            }, "name", { value: "RunImageDetector" });

            /**
             * Calls RunImageDetector.
             * @function runImageDetector
             * @memberof streem.api.Image
             * @instance
             * @param {streem.api.IRunImageDetectorRequest} request RunImageDetectorRequest message or plain object
             * @returns {Promise<streem.api.RunImageDetectorResponse>} Promise
             * @variation 2
             */

            return Image;
        })();

        api.RunImageDetectorRequest = (function() {

            /**
             * Properties of a RunImageDetectorRequest.
             * @memberof streem.api
             * @interface IRunImageDetectorRequest
             * @property {streem.api.RunImageDetectorRequest.IImageSource|null} [image] RunImageDetectorRequest image
             * @property {string|null} [callbackUrl] RunImageDetectorRequest callbackUrl
             */

            /**
             * Constructs a new RunImageDetectorRequest.
             * @memberof streem.api
             * @classdesc Represents a RunImageDetectorRequest.
             * @implements IRunImageDetectorRequest
             * @constructor
             * @param {streem.api.IRunImageDetectorRequest=} [p] Properties to set
             */
            function RunImageDetectorRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RunImageDetectorRequest image.
             * @member {streem.api.RunImageDetectorRequest.IImageSource|null|undefined} image
             * @memberof streem.api.RunImageDetectorRequest
             * @instance
             */
            RunImageDetectorRequest.prototype.image = null;

            /**
             * RunImageDetectorRequest callbackUrl.
             * @member {string} callbackUrl
             * @memberof streem.api.RunImageDetectorRequest
             * @instance
             */
            RunImageDetectorRequest.prototype.callbackUrl = "";

            /**
             * Creates a new RunImageDetectorRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {streem.api.IRunImageDetectorRequest=} [properties] Properties to set
             * @returns {streem.api.RunImageDetectorRequest} RunImageDetectorRequest instance
             */
            RunImageDetectorRequest.create = function create(properties) {
                return new RunImageDetectorRequest(properties);
            };

            /**
             * Encodes the specified RunImageDetectorRequest message. Does not implicitly {@link streem.api.RunImageDetectorRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {streem.api.IRunImageDetectorRequest} m RunImageDetectorRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RunImageDetectorRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                    $root.streem.api.RunImageDetectorRequest.ImageSource.encode(m.image, w.uint32(10).fork()).ldelim();
                if (m.callbackUrl != null && Object.hasOwnProperty.call(m, "callbackUrl"))
                    w.uint32(18).string(m.callbackUrl);
                return w;
            };

            /**
             * Decodes a RunImageDetectorRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RunImageDetectorRequest} RunImageDetectorRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RunImageDetectorRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RunImageDetectorRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.image = $root.streem.api.RunImageDetectorRequest.ImageSource.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.callbackUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RunImageDetectorRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RunImageDetectorRequest} RunImageDetectorRequest
             */
            RunImageDetectorRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RunImageDetectorRequest)
                    return d;
                var m = new $root.streem.api.RunImageDetectorRequest();
                if (d.image != null) {
                    if (typeof d.image !== "object")
                        throw TypeError(".streem.api.RunImageDetectorRequest.image: object expected");
                    m.image = $root.streem.api.RunImageDetectorRequest.ImageSource.fromObject(d.image);
                }
                if (d.callbackUrl != null) {
                    m.callbackUrl = String(d.callbackUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a RunImageDetectorRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {streem.api.RunImageDetectorRequest} m RunImageDetectorRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RunImageDetectorRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.image = null;
                    d.callbackUrl = "";
                }
                if (m.image != null && m.hasOwnProperty("image")) {
                    d.image = $root.streem.api.RunImageDetectorRequest.ImageSource.toObject(m.image, o);
                }
                if (m.callbackUrl != null && m.hasOwnProperty("callbackUrl")) {
                    d.callbackUrl = m.callbackUrl;
                }
                return d;
            };

            /**
             * Converts this RunImageDetectorRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RunImageDetectorRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RunImageDetectorRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RunImageDetectorRequest
             * @function getTypeUrl
             * @memberof streem.api.RunImageDetectorRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RunImageDetectorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RunImageDetectorRequest";
            };

            RunImageDetectorRequest.ImageSource = (function() {

                /**
                 * Properties of an ImageSource.
                 * @memberof streem.api.RunImageDetectorRequest
                 * @interface IImageSource
                 * @property {Uint8Array|null} [imageBytes] ImageSource imageBytes
                 * @property {string|null} [url] ImageSource url
                 */

                /**
                 * Constructs a new ImageSource.
                 * @memberof streem.api.RunImageDetectorRequest
                 * @classdesc Represents an ImageSource.
                 * @implements IImageSource
                 * @constructor
                 * @param {streem.api.RunImageDetectorRequest.IImageSource=} [p] Properties to set
                 */
                function ImageSource(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ImageSource imageBytes.
                 * @member {Uint8Array|null|undefined} imageBytes
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @instance
                 */
                ImageSource.prototype.imageBytes = null;

                /**
                 * ImageSource url.
                 * @member {string|null|undefined} url
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @instance
                 */
                ImageSource.prototype.url = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ImageSource source.
                 * @member {"imageBytes"|"url"|undefined} source
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @instance
                 */
                Object.defineProperty(ImageSource.prototype, "source", {
                    get: $util.oneOfGetter($oneOfFields = ["imageBytes", "url"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ImageSource instance using the specified properties.
                 * @function create
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {streem.api.RunImageDetectorRequest.IImageSource=} [properties] Properties to set
                 * @returns {streem.api.RunImageDetectorRequest.ImageSource} ImageSource instance
                 */
                ImageSource.create = function create(properties) {
                    return new ImageSource(properties);
                };

                /**
                 * Encodes the specified ImageSource message. Does not implicitly {@link streem.api.RunImageDetectorRequest.ImageSource.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {streem.api.RunImageDetectorRequest.IImageSource} m ImageSource message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ImageSource.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.imageBytes != null && Object.hasOwnProperty.call(m, "imageBytes"))
                        w.uint32(10).bytes(m.imageBytes);
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(18).string(m.url);
                    return w;
                };

                /**
                 * Decodes an ImageSource message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.RunImageDetectorRequest.ImageSource} ImageSource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ImageSource.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RunImageDetectorRequest.ImageSource();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.imageBytes = r.bytes();
                                break;
                            }
                        case 2: {
                                m.url = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ImageSource message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.RunImageDetectorRequest.ImageSource} ImageSource
                 */
                ImageSource.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.RunImageDetectorRequest.ImageSource)
                        return d;
                    var m = new $root.streem.api.RunImageDetectorRequest.ImageSource();
                    if (d.imageBytes != null) {
                        if (typeof d.imageBytes === "string")
                            $util.base64.decode(d.imageBytes, m.imageBytes = $util.newBuffer($util.base64.length(d.imageBytes)), 0);
                        else if (d.imageBytes.length >= 0)
                            m.imageBytes = d.imageBytes;
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ImageSource message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {streem.api.RunImageDetectorRequest.ImageSource} m ImageSource
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageSource.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (m.imageBytes != null && m.hasOwnProperty("imageBytes")) {
                        d.imageBytes = o.bytes === String ? $util.base64.encode(m.imageBytes, 0, m.imageBytes.length) : o.bytes === Array ? Array.prototype.slice.call(m.imageBytes) : m.imageBytes;
                        if (o.oneofs)
                            d.source = "imageBytes";
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                        if (o.oneofs)
                            d.source = "url";
                    }
                    return d;
                };

                /**
                 * Converts this ImageSource to JSON.
                 * @function toJSON
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageSource.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ImageSource
                 * @function getTypeUrl
                 * @memberof streem.api.RunImageDetectorRequest.ImageSource
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ImageSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.RunImageDetectorRequest.ImageSource";
                };

                return ImageSource;
            })();

            return RunImageDetectorRequest;
        })();

        api.RunImageDetectorResponse = (function() {

            /**
             * Properties of a RunImageDetectorResponse.
             * @memberof streem.api
             * @interface IRunImageDetectorResponse
             * @property {Array.<streem.api.IDetectionResult>|null} [detections] RunImageDetectorResponse detections
             */

            /**
             * Constructs a new RunImageDetectorResponse.
             * @memberof streem.api
             * @classdesc Represents a RunImageDetectorResponse.
             * @implements IRunImageDetectorResponse
             * @constructor
             * @param {streem.api.IRunImageDetectorResponse=} [p] Properties to set
             */
            function RunImageDetectorResponse(p) {
                this.detections = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RunImageDetectorResponse detections.
             * @member {Array.<streem.api.IDetectionResult>} detections
             * @memberof streem.api.RunImageDetectorResponse
             * @instance
             */
            RunImageDetectorResponse.prototype.detections = $util.emptyArray;

            /**
             * Creates a new RunImageDetectorResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {streem.api.IRunImageDetectorResponse=} [properties] Properties to set
             * @returns {streem.api.RunImageDetectorResponse} RunImageDetectorResponse instance
             */
            RunImageDetectorResponse.create = function create(properties) {
                return new RunImageDetectorResponse(properties);
            };

            /**
             * Encodes the specified RunImageDetectorResponse message. Does not implicitly {@link streem.api.RunImageDetectorResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {streem.api.IRunImageDetectorResponse} m RunImageDetectorResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RunImageDetectorResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.detections != null && m.detections.length) {
                    for (var i = 0; i < m.detections.length; ++i)
                        $root.streem.api.DetectionResult.encode(m.detections[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a RunImageDetectorResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RunImageDetectorResponse} RunImageDetectorResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RunImageDetectorResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RunImageDetectorResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.detections && m.detections.length))
                                m.detections = [];
                            m.detections.push($root.streem.api.DetectionResult.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RunImageDetectorResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RunImageDetectorResponse} RunImageDetectorResponse
             */
            RunImageDetectorResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RunImageDetectorResponse)
                    return d;
                var m = new $root.streem.api.RunImageDetectorResponse();
                if (d.detections) {
                    if (!Array.isArray(d.detections))
                        throw TypeError(".streem.api.RunImageDetectorResponse.detections: array expected");
                    m.detections = [];
                    for (var i = 0; i < d.detections.length; ++i) {
                        if (typeof d.detections[i] !== "object")
                            throw TypeError(".streem.api.RunImageDetectorResponse.detections: object expected");
                        m.detections[i] = $root.streem.api.DetectionResult.fromObject(d.detections[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a RunImageDetectorResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {streem.api.RunImageDetectorResponse} m RunImageDetectorResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RunImageDetectorResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.detections = [];
                }
                if (m.detections && m.detections.length) {
                    d.detections = [];
                    for (var j = 0; j < m.detections.length; ++j) {
                        d.detections[j] = $root.streem.api.DetectionResult.toObject(m.detections[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this RunImageDetectorResponse to JSON.
             * @function toJSON
             * @memberof streem.api.RunImageDetectorResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RunImageDetectorResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RunImageDetectorResponse
             * @function getTypeUrl
             * @memberof streem.api.RunImageDetectorResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RunImageDetectorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RunImageDetectorResponse";
            };

            return RunImageDetectorResponse;
        })();

        api.Groups = (function() {

            /**
             * Constructs a new Groups service.
             * @memberof streem.api
             * @classdesc Represents a Groups
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Groups(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Groups.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Groups;

            /**
             * Creates new Groups service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Groups
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Groups} RPC service. Useful where requests and/or responses are streamed.
             */
            Groups.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Groups#addGroupUser}.
             * @memberof streem.api.Groups
             * @typedef AddGroupUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls AddGroupUser.
             * @function addGroupUser
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IAddGroupUserRequest} request AddGroupUserRequest message or plain object
             * @param {streem.api.Groups.AddGroupUserCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.addGroupUser = function addGroupUser(request, callback) {
                return this.rpcCall(addGroupUser, $root.streem.api.AddGroupUserRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "AddGroupUser" });

            /**
             * Calls AddGroupUser.
             * @function addGroupUser
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IAddGroupUserRequest} request AddGroupUserRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#createGroup}.
             * @memberof streem.api.Groups
             * @typedef CreateGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateGroupResponse} [response] CreateGroupResponse
             */

            /**
             * Calls CreateGroup.
             * @function createGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICreateGroupRequest} request CreateGroupRequest message or plain object
             * @param {streem.api.Groups.CreateGroupCallback} callback Node-style callback called with the error, if any, and CreateGroupResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.createGroup = function createGroup(request, callback) {
                return this.rpcCall(createGroup, $root.streem.api.CreateGroupRequest, $root.streem.api.CreateGroupResponse, request, callback);
            }, "name", { value: "CreateGroup" });

            /**
             * Calls CreateGroup.
             * @function createGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICreateGroupRequest} request CreateGroupRequest message or plain object
             * @returns {Promise<streem.api.CreateGroupResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#deleteGroup}.
             * @memberof streem.api.Groups
             * @typedef DeleteGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteGroup.
             * @function deleteGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IDeleteGroupRequest} request DeleteGroupRequest message or plain object
             * @param {streem.api.Groups.DeleteGroupCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.deleteGroup = function deleteGroup(request, callback) {
                return this.rpcCall(deleteGroup, $root.streem.api.DeleteGroupRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteGroup" });

            /**
             * Calls DeleteGroup.
             * @function deleteGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IDeleteGroupRequest} request DeleteGroupRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#deleteGroupUser}.
             * @memberof streem.api.Groups
             * @typedef DeleteGroupUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteGroupUser.
             * @function deleteGroupUser
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IDeleteGroupUserRequest} request DeleteGroupUserRequest message or plain object
             * @param {streem.api.Groups.DeleteGroupUserCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.deleteGroupUser = function deleteGroupUser(request, callback) {
                return this.rpcCall(deleteGroupUser, $root.streem.api.DeleteGroupUserRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteGroupUser" });

            /**
             * Calls DeleteGroupUser.
             * @function deleteGroupUser
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IDeleteGroupUserRequest} request DeleteGroupUserRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#getGroup}.
             * @memberof streem.api.Groups
             * @typedef GetGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetGroupResponse} [response] GetGroupResponse
             */

            /**
             * Calls GetGroup.
             * @function getGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupRequest} request GetGroupRequest message or plain object
             * @param {streem.api.Groups.GetGroupCallback} callback Node-style callback called with the error, if any, and GetGroupResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.getGroup = function getGroup(request, callback) {
                return this.rpcCall(getGroup, $root.streem.api.GetGroupRequest, $root.streem.api.GetGroupResponse, request, callback);
            }, "name", { value: "GetGroup" });

            /**
             * Calls GetGroup.
             * @function getGroup
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupRequest} request GetGroupRequest message or plain object
             * @returns {Promise<streem.api.GetGroupResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#listGroups}.
             * @memberof streem.api.Groups
             * @typedef ListGroupsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListGroupsResponse} [response] ListGroupsResponse
             */

            /**
             * Calls ListGroups.
             * @function listGroups
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupsRequest} request ListGroupsRequest message or plain object
             * @param {streem.api.Groups.ListGroupsCallback} callback Node-style callback called with the error, if any, and ListGroupsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.listGroups = function listGroups(request, callback) {
                return this.rpcCall(listGroups, $root.streem.api.ListGroupsRequest, $root.streem.api.ListGroupsResponse, request, callback);
            }, "name", { value: "ListGroups" });

            /**
             * Calls ListGroups.
             * @function listGroups
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupsRequest} request ListGroupsRequest message or plain object
             * @returns {Promise<streem.api.ListGroupsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#listGroupUsers}.
             * @memberof streem.api.Groups
             * @typedef ListGroupUsersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListGroupUsersResponse} [response] ListGroupUsersResponse
             */

            /**
             * Calls ListGroupUsers.
             * @function listGroupUsers
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupUsersRequest} request ListGroupUsersRequest message or plain object
             * @param {streem.api.Groups.ListGroupUsersCallback} callback Node-style callback called with the error, if any, and ListGroupUsersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.listGroupUsers = function listGroupUsers(request, callback) {
                return this.rpcCall(listGroupUsers, $root.streem.api.ListGroupUsersRequest, $root.streem.api.ListGroupUsersResponse, request, callback);
            }, "name", { value: "ListGroupUsers" });

            /**
             * Calls ListGroupUsers.
             * @function listGroupUsers
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupUsersRequest} request ListGroupUsersRequest message or plain object
             * @returns {Promise<streem.api.ListGroupUsersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#listUserGroups}.
             * @memberof streem.api.Groups
             * @typedef ListUserGroupsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListUserGroupsResponse} [response] ListUserGroupsResponse
             */

            /**
             * Calls ListUserGroups.
             * @function listUserGroups
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListUserGroupsRequest} request ListUserGroupsRequest message or plain object
             * @param {streem.api.Groups.ListUserGroupsCallback} callback Node-style callback called with the error, if any, and ListUserGroupsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.listUserGroups = function listUserGroups(request, callback) {
                return this.rpcCall(listUserGroups, $root.streem.api.ListUserGroupsRequest, $root.streem.api.ListUserGroupsResponse, request, callback);
            }, "name", { value: "ListUserGroups" });

            /**
             * Calls ListUserGroups.
             * @function listUserGroups
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListUserGroupsRequest} request ListUserGroupsRequest message or plain object
             * @returns {Promise<streem.api.ListUserGroupsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#createGroupReservation}.
             * @memberof streem.api.Groups
             * @typedef CreateGroupReservationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateGroupReservationResponse} [response] CreateGroupReservationResponse
             */

            /**
             * Calls CreateGroupReservation.
             * @function createGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICreateGroupReservationRequest} request CreateGroupReservationRequest message or plain object
             * @param {streem.api.Groups.CreateGroupReservationCallback} callback Node-style callback called with the error, if any, and CreateGroupReservationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.createGroupReservation = function createGroupReservation(request, callback) {
                return this.rpcCall(createGroupReservation, $root.streem.api.CreateGroupReservationRequest, $root.streem.api.CreateGroupReservationResponse, request, callback);
            }, "name", { value: "CreateGroupReservation" });

            /**
             * Calls CreateGroupReservation.
             * @function createGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICreateGroupReservationRequest} request CreateGroupReservationRequest message or plain object
             * @returns {Promise<streem.api.CreateGroupReservationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#getGroupReservation}.
             * @memberof streem.api.Groups
             * @typedef GetGroupReservationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetGroupReservationResponse} [response] GetGroupReservationResponse
             */

            /**
             * Calls GetGroupReservation.
             * @function getGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupReservationRequest} request GetGroupReservationRequest message or plain object
             * @param {streem.api.Groups.GetGroupReservationCallback} callback Node-style callback called with the error, if any, and GetGroupReservationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.getGroupReservation = function getGroupReservation(request, callback) {
                return this.rpcCall(getGroupReservation, $root.streem.api.GetGroupReservationRequest, $root.streem.api.GetGroupReservationResponse, request, callback);
            }, "name", { value: "GetGroupReservation" });

            /**
             * Calls GetGroupReservation.
             * @function getGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupReservationRequest} request GetGroupReservationRequest message or plain object
             * @returns {Promise<streem.api.GetGroupReservationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#cancelGroupReservation}.
             * @memberof streem.api.Groups
             * @typedef CancelGroupReservationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CancelGroupReservationResponse} [response] CancelGroupReservationResponse
             */

            /**
             * Calls CancelGroupReservation.
             * @function cancelGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICancelGroupReservationRequest} request CancelGroupReservationRequest message or plain object
             * @param {streem.api.Groups.CancelGroupReservationCallback} callback Node-style callback called with the error, if any, and CancelGroupReservationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.cancelGroupReservation = function cancelGroupReservation(request, callback) {
                return this.rpcCall(cancelGroupReservation, $root.streem.api.CancelGroupReservationRequest, $root.streem.api.CancelGroupReservationResponse, request, callback);
            }, "name", { value: "CancelGroupReservation" });

            /**
             * Calls CancelGroupReservation.
             * @function cancelGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.ICancelGroupReservationRequest} request CancelGroupReservationRequest message or plain object
             * @returns {Promise<streem.api.CancelGroupReservationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#confirmMatchedGroupReservation}.
             * @memberof streem.api.Groups
             * @typedef ConfirmMatchedGroupReservationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ConfirmMatchedGroupReservationResponse} [response] ConfirmMatchedGroupReservationResponse
             */

            /**
             * Calls ConfirmMatchedGroupReservation.
             * @function confirmMatchedGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IConfirmMatchedGroupReservationRequest} request ConfirmMatchedGroupReservationRequest message or plain object
             * @param {streem.api.Groups.ConfirmMatchedGroupReservationCallback} callback Node-style callback called with the error, if any, and ConfirmMatchedGroupReservationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.confirmMatchedGroupReservation = function confirmMatchedGroupReservation(request, callback) {
                return this.rpcCall(confirmMatchedGroupReservation, $root.streem.api.ConfirmMatchedGroupReservationRequest, $root.streem.api.ConfirmMatchedGroupReservationResponse, request, callback);
            }, "name", { value: "ConfirmMatchedGroupReservation" });

            /**
             * Calls ConfirmMatchedGroupReservation.
             * @function confirmMatchedGroupReservation
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IConfirmMatchedGroupReservationRequest} request ConfirmMatchedGroupReservationRequest message or plain object
             * @returns {Promise<streem.api.ConfirmMatchedGroupReservationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#listGroupAvailability}.
             * @memberof streem.api.Groups
             * @typedef ListGroupAvailabilityCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListGroupAvailabilityResponse} [response] ListGroupAvailabilityResponse
             */

            /**
             * Calls ListGroupAvailability.
             * @function listGroupAvailability
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupAvailabilityRequest} request ListGroupAvailabilityRequest message or plain object
             * @param {streem.api.Groups.ListGroupAvailabilityCallback} callback Node-style callback called with the error, if any, and ListGroupAvailabilityResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.listGroupAvailability = function listGroupAvailability(request, callback) {
                return this.rpcCall(listGroupAvailability, $root.streem.api.ListGroupAvailabilityRequest, $root.streem.api.ListGroupAvailabilityResponse, request, callback);
            }, "name", { value: "ListGroupAvailability" });

            /**
             * Calls ListGroupAvailability.
             * @function listGroupAvailability
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IListGroupAvailabilityRequest} request ListGroupAvailabilityRequest message or plain object
             * @returns {Promise<streem.api.ListGroupAvailabilityResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Groups#getGroupReservationWaitTime}.
             * @memberof streem.api.Groups
             * @typedef GetGroupReservationWaitTimeCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetGroupReservationWaitTimeResponse} [response] GetGroupReservationWaitTimeResponse
             */

            /**
             * Calls GetGroupReservationWaitTime.
             * @function getGroupReservationWaitTime
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupReservationWaitTimeRequest} request GetGroupReservationWaitTimeRequest message or plain object
             * @param {streem.api.Groups.GetGroupReservationWaitTimeCallback} callback Node-style callback called with the error, if any, and GetGroupReservationWaitTimeResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Groups.prototype.getGroupReservationWaitTime = function getGroupReservationWaitTime(request, callback) {
                return this.rpcCall(getGroupReservationWaitTime, $root.streem.api.GetGroupReservationWaitTimeRequest, $root.streem.api.GetGroupReservationWaitTimeResponse, request, callback);
            }, "name", { value: "GetGroupReservationWaitTime" });

            /**
             * Calls GetGroupReservationWaitTime.
             * @function getGroupReservationWaitTime
             * @memberof streem.api.Groups
             * @instance
             * @param {streem.api.IGetGroupReservationWaitTimeRequest} request GetGroupReservationWaitTimeRequest message or plain object
             * @returns {Promise<streem.api.GetGroupReservationWaitTimeResponse>} Promise
             * @variation 2
             */

            return Groups;
        })();

        api.AddGroupUserRequest = (function() {

            /**
             * Properties of an AddGroupUserRequest.
             * @memberof streem.api
             * @interface IAddGroupUserRequest
             * @property {string|null} [companyCodeOrSid] AddGroupUserRequest companyCodeOrSid
             * @property {string|null} [groupName] AddGroupUserRequest groupName
             * @property {string|null} [userId] AddGroupUserRequest userId
             */

            /**
             * Constructs a new AddGroupUserRequest.
             * @memberof streem.api
             * @classdesc Represents an AddGroupUserRequest.
             * @implements IAddGroupUserRequest
             * @constructor
             * @param {streem.api.IAddGroupUserRequest=} [p] Properties to set
             */
            function AddGroupUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AddGroupUserRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.AddGroupUserRequest
             * @instance
             */
            AddGroupUserRequest.prototype.companyCodeOrSid = "";

            /**
             * AddGroupUserRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.AddGroupUserRequest
             * @instance
             */
            AddGroupUserRequest.prototype.groupName = "";

            /**
             * AddGroupUserRequest userId.
             * @member {string} userId
             * @memberof streem.api.AddGroupUserRequest
             * @instance
             */
            AddGroupUserRequest.prototype.userId = "";

            /**
             * Creates a new AddGroupUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {streem.api.IAddGroupUserRequest=} [properties] Properties to set
             * @returns {streem.api.AddGroupUserRequest} AddGroupUserRequest instance
             */
            AddGroupUserRequest.create = function create(properties) {
                return new AddGroupUserRequest(properties);
            };

            /**
             * Encodes the specified AddGroupUserRequest message. Does not implicitly {@link streem.api.AddGroupUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {streem.api.IAddGroupUserRequest} m AddGroupUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddGroupUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(26).string(m.userId);
                return w;
            };

            /**
             * Decodes an AddGroupUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AddGroupUserRequest} AddGroupUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddGroupUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AddGroupUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.userId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AddGroupUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AddGroupUserRequest} AddGroupUserRequest
             */
            AddGroupUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AddGroupUserRequest)
                    return d;
                var m = new $root.streem.api.AddGroupUserRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                return m;
            };

            /**
             * Creates a plain object from an AddGroupUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {streem.api.AddGroupUserRequest} m AddGroupUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddGroupUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.userId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                return d;
            };

            /**
             * Converts this AddGroupUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AddGroupUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddGroupUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddGroupUserRequest
             * @function getTypeUrl
             * @memberof streem.api.AddGroupUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddGroupUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AddGroupUserRequest";
            };

            return AddGroupUserRequest;
        })();

        api.CreateGroupRequest = (function() {

            /**
             * Properties of a CreateGroupRequest.
             * @memberof streem.api
             * @interface ICreateGroupRequest
             * @property {string|null} [companyCodeOrSid] CreateGroupRequest companyCodeOrSid
             * @property {string|null} [name] CreateGroupRequest name
             * @property {string|null} [description] CreateGroupRequest description
             */

            /**
             * Constructs a new CreateGroupRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateGroupRequest.
             * @implements ICreateGroupRequest
             * @constructor
             * @param {streem.api.ICreateGroupRequest=} [p] Properties to set
             */
            function CreateGroupRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateGroupRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateGroupRequest
             * @instance
             */
            CreateGroupRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateGroupRequest name.
             * @member {string} name
             * @memberof streem.api.CreateGroupRequest
             * @instance
             */
            CreateGroupRequest.prototype.name = "";

            /**
             * CreateGroupRequest description.
             * @member {string} description
             * @memberof streem.api.CreateGroupRequest
             * @instance
             */
            CreateGroupRequest.prototype.description = "";

            /**
             * Creates a new CreateGroupRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {streem.api.ICreateGroupRequest=} [properties] Properties to set
             * @returns {streem.api.CreateGroupRequest} CreateGroupRequest instance
             */
            CreateGroupRequest.create = function create(properties) {
                return new CreateGroupRequest(properties);
            };

            /**
             * Encodes the specified CreateGroupRequest message. Does not implicitly {@link streem.api.CreateGroupRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {streem.api.ICreateGroupRequest} m CreateGroupRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroupRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(26).string(m.description);
                return w;
            };

            /**
             * Decodes a CreateGroupRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateGroupRequest} CreateGroupRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroupRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.description = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateGroupRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateGroupRequest} CreateGroupRequest
             */
            CreateGroupRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateGroupRequest)
                    return d;
                var m = new $root.streem.api.CreateGroupRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateGroupRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {streem.api.CreateGroupRequest} m CreateGroupRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroupRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.name = "";
                    d.description = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                return d;
            };

            /**
             * Converts this CreateGroupRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateGroupRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroupRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateGroupRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateGroupRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateGroupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateGroupRequest";
            };

            return CreateGroupRequest;
        })();

        api.CreateGroupResponse = (function() {

            /**
             * Properties of a CreateGroupResponse.
             * @memberof streem.api
             * @interface ICreateGroupResponse
             * @property {streem.api.IGroup|null} [group] CreateGroupResponse group
             */

            /**
             * Constructs a new CreateGroupResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateGroupResponse.
             * @implements ICreateGroupResponse
             * @constructor
             * @param {streem.api.ICreateGroupResponse=} [p] Properties to set
             */
            function CreateGroupResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateGroupResponse group.
             * @member {streem.api.IGroup|null|undefined} group
             * @memberof streem.api.CreateGroupResponse
             * @instance
             */
            CreateGroupResponse.prototype.group = null;

            /**
             * Creates a new CreateGroupResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {streem.api.ICreateGroupResponse=} [properties] Properties to set
             * @returns {streem.api.CreateGroupResponse} CreateGroupResponse instance
             */
            CreateGroupResponse.create = function create(properties) {
                return new CreateGroupResponse(properties);
            };

            /**
             * Encodes the specified CreateGroupResponse message. Does not implicitly {@link streem.api.CreateGroupResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {streem.api.ICreateGroupResponse} m CreateGroupResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroupResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                    $root.streem.api.Group.encode(m.group, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateGroupResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateGroupResponse} CreateGroupResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroupResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.group = $root.streem.api.Group.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateGroupResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateGroupResponse} CreateGroupResponse
             */
            CreateGroupResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateGroupResponse)
                    return d;
                var m = new $root.streem.api.CreateGroupResponse();
                if (d.group != null) {
                    if (typeof d.group !== "object")
                        throw TypeError(".streem.api.CreateGroupResponse.group: object expected");
                    m.group = $root.streem.api.Group.fromObject(d.group);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateGroupResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {streem.api.CreateGroupResponse} m CreateGroupResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroupResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.group = null;
                }
                if (m.group != null && m.hasOwnProperty("group")) {
                    d.group = $root.streem.api.Group.toObject(m.group, o);
                }
                return d;
            };

            /**
             * Converts this CreateGroupResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateGroupResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroupResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateGroupResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateGroupResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateGroupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateGroupResponse";
            };

            return CreateGroupResponse;
        })();

        api.DeleteGroupRequest = (function() {

            /**
             * Properties of a DeleteGroupRequest.
             * @memberof streem.api
             * @interface IDeleteGroupRequest
             * @property {string|null} [companyCodeOrSid] DeleteGroupRequest companyCodeOrSid
             * @property {string|null} [groupName] DeleteGroupRequest groupName
             */

            /**
             * Constructs a new DeleteGroupRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteGroupRequest.
             * @implements IDeleteGroupRequest
             * @constructor
             * @param {streem.api.IDeleteGroupRequest=} [p] Properties to set
             */
            function DeleteGroupRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteGroupRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.DeleteGroupRequest
             * @instance
             */
            DeleteGroupRequest.prototype.companyCodeOrSid = "";

            /**
             * DeleteGroupRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.DeleteGroupRequest
             * @instance
             */
            DeleteGroupRequest.prototype.groupName = "";

            /**
             * Creates a new DeleteGroupRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {streem.api.IDeleteGroupRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteGroupRequest} DeleteGroupRequest instance
             */
            DeleteGroupRequest.create = function create(properties) {
                return new DeleteGroupRequest(properties);
            };

            /**
             * Encodes the specified DeleteGroupRequest message. Does not implicitly {@link streem.api.DeleteGroupRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {streem.api.IDeleteGroupRequest} m DeleteGroupRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGroupRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                return w;
            };

            /**
             * Decodes a DeleteGroupRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteGroupRequest} DeleteGroupRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGroupRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteGroupRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteGroupRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteGroupRequest} DeleteGroupRequest
             */
            DeleteGroupRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteGroupRequest)
                    return d;
                var m = new $root.streem.api.DeleteGroupRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteGroupRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {streem.api.DeleteGroupRequest} m DeleteGroupRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGroupRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                return d;
            };

            /**
             * Converts this DeleteGroupRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteGroupRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGroupRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteGroupRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteGroupRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteGroupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteGroupRequest";
            };

            return DeleteGroupRequest;
        })();

        api.DeleteGroupUserRequest = (function() {

            /**
             * Properties of a DeleteGroupUserRequest.
             * @memberof streem.api
             * @interface IDeleteGroupUserRequest
             * @property {string|null} [companyCodeOrSid] DeleteGroupUserRequest companyCodeOrSid
             * @property {string|null} [groupName] DeleteGroupUserRequest groupName
             * @property {string|null} [userId] DeleteGroupUserRequest userId
             */

            /**
             * Constructs a new DeleteGroupUserRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteGroupUserRequest.
             * @implements IDeleteGroupUserRequest
             * @constructor
             * @param {streem.api.IDeleteGroupUserRequest=} [p] Properties to set
             */
            function DeleteGroupUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteGroupUserRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.DeleteGroupUserRequest
             * @instance
             */
            DeleteGroupUserRequest.prototype.companyCodeOrSid = "";

            /**
             * DeleteGroupUserRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.DeleteGroupUserRequest
             * @instance
             */
            DeleteGroupUserRequest.prototype.groupName = "";

            /**
             * DeleteGroupUserRequest userId.
             * @member {string} userId
             * @memberof streem.api.DeleteGroupUserRequest
             * @instance
             */
            DeleteGroupUserRequest.prototype.userId = "";

            /**
             * Creates a new DeleteGroupUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {streem.api.IDeleteGroupUserRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteGroupUserRequest} DeleteGroupUserRequest instance
             */
            DeleteGroupUserRequest.create = function create(properties) {
                return new DeleteGroupUserRequest(properties);
            };

            /**
             * Encodes the specified DeleteGroupUserRequest message. Does not implicitly {@link streem.api.DeleteGroupUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {streem.api.IDeleteGroupUserRequest} m DeleteGroupUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGroupUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(26).string(m.userId);
                return w;
            };

            /**
             * Decodes a DeleteGroupUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteGroupUserRequest} DeleteGroupUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGroupUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteGroupUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.userId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteGroupUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteGroupUserRequest} DeleteGroupUserRequest
             */
            DeleteGroupUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteGroupUserRequest)
                    return d;
                var m = new $root.streem.api.DeleteGroupUserRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteGroupUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {streem.api.DeleteGroupUserRequest} m DeleteGroupUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGroupUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.userId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                return d;
            };

            /**
             * Converts this DeleteGroupUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteGroupUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGroupUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteGroupUserRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteGroupUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteGroupUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteGroupUserRequest";
            };

            return DeleteGroupUserRequest;
        })();

        api.GetGroupRequest = (function() {

            /**
             * Properties of a GetGroupRequest.
             * @memberof streem.api
             * @interface IGetGroupRequest
             * @property {string|null} [companyCodeOrSid] GetGroupRequest companyCodeOrSid
             * @property {string|null} [groupName] GetGroupRequest groupName
             */

            /**
             * Constructs a new GetGroupRequest.
             * @memberof streem.api
             * @classdesc Represents a GetGroupRequest.
             * @implements IGetGroupRequest
             * @constructor
             * @param {streem.api.IGetGroupRequest=} [p] Properties to set
             */
            function GetGroupRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetGroupRequest
             * @instance
             */
            GetGroupRequest.prototype.companyCodeOrSid = "";

            /**
             * GetGroupRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.GetGroupRequest
             * @instance
             */
            GetGroupRequest.prototype.groupName = "";

            /**
             * Creates a new GetGroupRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {streem.api.IGetGroupRequest=} [properties] Properties to set
             * @returns {streem.api.GetGroupRequest} GetGroupRequest instance
             */
            GetGroupRequest.create = function create(properties) {
                return new GetGroupRequest(properties);
            };

            /**
             * Encodes the specified GetGroupRequest message. Does not implicitly {@link streem.api.GetGroupRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {streem.api.IGetGroupRequest} m GetGroupRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                return w;
            };

            /**
             * Decodes a GetGroupRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupRequest} GetGroupRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupRequest} GetGroupRequest
             */
            GetGroupRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupRequest)
                    return d;
                var m = new $root.streem.api.GetGroupRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {streem.api.GetGroupRequest} m GetGroupRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                return d;
            };

            /**
             * Converts this GetGroupRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupRequest
             * @function getTypeUrl
             * @memberof streem.api.GetGroupRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupRequest";
            };

            return GetGroupRequest;
        })();

        api.GetGroupResponse = (function() {

            /**
             * Properties of a GetGroupResponse.
             * @memberof streem.api
             * @interface IGetGroupResponse
             * @property {streem.api.IGroup|null} [group] GetGroupResponse group
             */

            /**
             * Constructs a new GetGroupResponse.
             * @memberof streem.api
             * @classdesc Represents a GetGroupResponse.
             * @implements IGetGroupResponse
             * @constructor
             * @param {streem.api.IGetGroupResponse=} [p] Properties to set
             */
            function GetGroupResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupResponse group.
             * @member {streem.api.IGroup|null|undefined} group
             * @memberof streem.api.GetGroupResponse
             * @instance
             */
            GetGroupResponse.prototype.group = null;

            /**
             * Creates a new GetGroupResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {streem.api.IGetGroupResponse=} [properties] Properties to set
             * @returns {streem.api.GetGroupResponse} GetGroupResponse instance
             */
            GetGroupResponse.create = function create(properties) {
                return new GetGroupResponse(properties);
            };

            /**
             * Encodes the specified GetGroupResponse message. Does not implicitly {@link streem.api.GetGroupResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {streem.api.IGetGroupResponse} m GetGroupResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                    $root.streem.api.Group.encode(m.group, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetGroupResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupResponse} GetGroupResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.group = $root.streem.api.Group.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupResponse} GetGroupResponse
             */
            GetGroupResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupResponse)
                    return d;
                var m = new $root.streem.api.GetGroupResponse();
                if (d.group != null) {
                    if (typeof d.group !== "object")
                        throw TypeError(".streem.api.GetGroupResponse.group: object expected");
                    m.group = $root.streem.api.Group.fromObject(d.group);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {streem.api.GetGroupResponse} m GetGroupResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.group = null;
                }
                if (m.group != null && m.hasOwnProperty("group")) {
                    d.group = $root.streem.api.Group.toObject(m.group, o);
                }
                return d;
            };

            /**
             * Converts this GetGroupResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupResponse
             * @function getTypeUrl
             * @memberof streem.api.GetGroupResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupResponse";
            };

            return GetGroupResponse;
        })();

        api.ListGroupsRequest = (function() {

            /**
             * Properties of a ListGroupsRequest.
             * @memberof streem.api
             * @interface IListGroupsRequest
             * @property {string|null} [companyCodeOrSid] ListGroupsRequest companyCodeOrSid
             * @property {number|null} [pageSize] ListGroupsRequest pageSize
             * @property {string|null} [pageToken] ListGroupsRequest pageToken
             */

            /**
             * Constructs a new ListGroupsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListGroupsRequest.
             * @implements IListGroupsRequest
             * @constructor
             * @param {streem.api.IListGroupsRequest=} [p] Properties to set
             */
            function ListGroupsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupsRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListGroupsRequest
             * @instance
             */
            ListGroupsRequest.prototype.companyCodeOrSid = "";

            /**
             * ListGroupsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListGroupsRequest
             * @instance
             */
            ListGroupsRequest.prototype.pageSize = 0;

            /**
             * ListGroupsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListGroupsRequest
             * @instance
             */
            ListGroupsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListGroupsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {streem.api.IListGroupsRequest=} [properties] Properties to set
             * @returns {streem.api.ListGroupsRequest} ListGroupsRequest instance
             */
            ListGroupsRequest.create = function create(properties) {
                return new ListGroupsRequest(properties);
            };

            /**
             * Encodes the specified ListGroupsRequest message. Does not implicitly {@link streem.api.ListGroupsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {streem.api.IListGroupsRequest} m ListGroupsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListGroupsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupsRequest} ListGroupsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupsRequest} ListGroupsRequest
             */
            ListGroupsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupsRequest)
                    return d;
                var m = new $root.streem.api.ListGroupsRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {streem.api.ListGroupsRequest} m ListGroupsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListGroupsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListGroupsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupsRequest";
            };

            return ListGroupsRequest;
        })();

        api.ListGroupsResponse = (function() {

            /**
             * Properties of a ListGroupsResponse.
             * @memberof streem.api
             * @interface IListGroupsResponse
             * @property {Array.<streem.api.IGroup>|null} [groups] ListGroupsResponse groups
             * @property {string|null} [nextPageToken] ListGroupsResponse nextPageToken
             * @property {number|null} [totalSize] ListGroupsResponse totalSize
             */

            /**
             * Constructs a new ListGroupsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListGroupsResponse.
             * @implements IListGroupsResponse
             * @constructor
             * @param {streem.api.IListGroupsResponse=} [p] Properties to set
             */
            function ListGroupsResponse(p) {
                this.groups = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupsResponse groups.
             * @member {Array.<streem.api.IGroup>} groups
             * @memberof streem.api.ListGroupsResponse
             * @instance
             */
            ListGroupsResponse.prototype.groups = $util.emptyArray;

            /**
             * ListGroupsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListGroupsResponse
             * @instance
             */
            ListGroupsResponse.prototype.nextPageToken = "";

            /**
             * ListGroupsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListGroupsResponse
             * @instance
             */
            ListGroupsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListGroupsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {streem.api.IListGroupsResponse=} [properties] Properties to set
             * @returns {streem.api.ListGroupsResponse} ListGroupsResponse instance
             */
            ListGroupsResponse.create = function create(properties) {
                return new ListGroupsResponse(properties);
            };

            /**
             * Encodes the specified ListGroupsResponse message. Does not implicitly {@link streem.api.ListGroupsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {streem.api.IListGroupsResponse} m ListGroupsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.groups != null && m.groups.length) {
                    for (var i = 0; i < m.groups.length; ++i)
                        $root.streem.api.Group.encode(m.groups[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListGroupsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupsResponse} ListGroupsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.groups && m.groups.length))
                                m.groups = [];
                            m.groups.push($root.streem.api.Group.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupsResponse} ListGroupsResponse
             */
            ListGroupsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupsResponse)
                    return d;
                var m = new $root.streem.api.ListGroupsResponse();
                if (d.groups) {
                    if (!Array.isArray(d.groups))
                        throw TypeError(".streem.api.ListGroupsResponse.groups: array expected");
                    m.groups = [];
                    for (var i = 0; i < d.groups.length; ++i) {
                        if (typeof d.groups[i] !== "object")
                            throw TypeError(".streem.api.ListGroupsResponse.groups: object expected");
                        m.groups[i] = $root.streem.api.Group.fromObject(d.groups[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {streem.api.ListGroupsResponse} m ListGroupsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groups = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.groups && m.groups.length) {
                    d.groups = [];
                    for (var j = 0; j < m.groups.length; ++j) {
                        d.groups[j] = $root.streem.api.Group.toObject(m.groups[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListGroupsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListGroupsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupsResponse";
            };

            return ListGroupsResponse;
        })();

        api.ListGroupUsersRequest = (function() {

            /**
             * Properties of a ListGroupUsersRequest.
             * @memberof streem.api
             * @interface IListGroupUsersRequest
             * @property {string|null} [groupName] ListGroupUsersRequest groupName
             * @property {string|null} [companyCodeOrSid] ListGroupUsersRequest companyCodeOrSid
             * @property {number|null} [pageSize] ListGroupUsersRequest pageSize
             * @property {string|null} [pageToken] ListGroupUsersRequest pageToken
             */

            /**
             * Constructs a new ListGroupUsersRequest.
             * @memberof streem.api
             * @classdesc Represents a ListGroupUsersRequest.
             * @implements IListGroupUsersRequest
             * @constructor
             * @param {streem.api.IListGroupUsersRequest=} [p] Properties to set
             */
            function ListGroupUsersRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupUsersRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.ListGroupUsersRequest
             * @instance
             */
            ListGroupUsersRequest.prototype.groupName = "";

            /**
             * ListGroupUsersRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListGroupUsersRequest
             * @instance
             */
            ListGroupUsersRequest.prototype.companyCodeOrSid = "";

            /**
             * ListGroupUsersRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListGroupUsersRequest
             * @instance
             */
            ListGroupUsersRequest.prototype.pageSize = 0;

            /**
             * ListGroupUsersRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListGroupUsersRequest
             * @instance
             */
            ListGroupUsersRequest.prototype.pageToken = "";

            /**
             * Creates a new ListGroupUsersRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {streem.api.IListGroupUsersRequest=} [properties] Properties to set
             * @returns {streem.api.ListGroupUsersRequest} ListGroupUsersRequest instance
             */
            ListGroupUsersRequest.create = function create(properties) {
                return new ListGroupUsersRequest(properties);
            };

            /**
             * Encodes the specified ListGroupUsersRequest message. Does not implicitly {@link streem.api.ListGroupUsersRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {streem.api.IListGroupUsersRequest} m ListGroupUsersRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupUsersRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(10).string(m.groupName);
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(18).string(m.companyCodeOrSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(24).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(34).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListGroupUsersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupUsersRequest} ListGroupUsersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupUsersRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupUsersRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.groupName = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 3: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupUsersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupUsersRequest} ListGroupUsersRequest
             */
            ListGroupUsersRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupUsersRequest)
                    return d;
                var m = new $root.streem.api.ListGroupUsersRequest();
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupUsersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {streem.api.ListGroupUsersRequest} m ListGroupUsersRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupUsersRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.groupName = "";
                    d.companyCodeOrSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListGroupUsersRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupUsersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupUsersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupUsersRequest
             * @function getTypeUrl
             * @memberof streem.api.ListGroupUsersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupUsersRequest";
            };

            return ListGroupUsersRequest;
        })();

        api.ListGroupUsersResponse = (function() {

            /**
             * Properties of a ListGroupUsersResponse.
             * @memberof streem.api
             * @interface IListGroupUsersResponse
             * @property {Array.<streem.api.IUser>|null} [users] ListGroupUsersResponse users
             * @property {string|null} [nextPageToken] ListGroupUsersResponse nextPageToken
             * @property {number|null} [totalSize] ListGroupUsersResponse totalSize
             */

            /**
             * Constructs a new ListGroupUsersResponse.
             * @memberof streem.api
             * @classdesc Represents a ListGroupUsersResponse.
             * @implements IListGroupUsersResponse
             * @constructor
             * @param {streem.api.IListGroupUsersResponse=} [p] Properties to set
             */
            function ListGroupUsersResponse(p) {
                this.users = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupUsersResponse users.
             * @member {Array.<streem.api.IUser>} users
             * @memberof streem.api.ListGroupUsersResponse
             * @instance
             */
            ListGroupUsersResponse.prototype.users = $util.emptyArray;

            /**
             * ListGroupUsersResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListGroupUsersResponse
             * @instance
             */
            ListGroupUsersResponse.prototype.nextPageToken = "";

            /**
             * ListGroupUsersResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListGroupUsersResponse
             * @instance
             */
            ListGroupUsersResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListGroupUsersResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {streem.api.IListGroupUsersResponse=} [properties] Properties to set
             * @returns {streem.api.ListGroupUsersResponse} ListGroupUsersResponse instance
             */
            ListGroupUsersResponse.create = function create(properties) {
                return new ListGroupUsersResponse(properties);
            };

            /**
             * Encodes the specified ListGroupUsersResponse message. Does not implicitly {@link streem.api.ListGroupUsersResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {streem.api.IListGroupUsersResponse} m ListGroupUsersResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupUsersResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.users != null && m.users.length) {
                    for (var i = 0; i < m.users.length; ++i)
                        $root.streem.api.User.encode(m.users[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListGroupUsersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupUsersResponse} ListGroupUsersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupUsersResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupUsersResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.users && m.users.length))
                                m.users = [];
                            m.users.push($root.streem.api.User.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupUsersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupUsersResponse} ListGroupUsersResponse
             */
            ListGroupUsersResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupUsersResponse)
                    return d;
                var m = new $root.streem.api.ListGroupUsersResponse();
                if (d.users) {
                    if (!Array.isArray(d.users))
                        throw TypeError(".streem.api.ListGroupUsersResponse.users: array expected");
                    m.users = [];
                    for (var i = 0; i < d.users.length; ++i) {
                        if (typeof d.users[i] !== "object")
                            throw TypeError(".streem.api.ListGroupUsersResponse.users: object expected");
                        m.users[i] = $root.streem.api.User.fromObject(d.users[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupUsersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {streem.api.ListGroupUsersResponse} m ListGroupUsersResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupUsersResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.users = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.users && m.users.length) {
                    d.users = [];
                    for (var j = 0; j < m.users.length; ++j) {
                        d.users[j] = $root.streem.api.User.toObject(m.users[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListGroupUsersResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupUsersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupUsersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupUsersResponse
             * @function getTypeUrl
             * @memberof streem.api.ListGroupUsersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupUsersResponse";
            };

            return ListGroupUsersResponse;
        })();

        api.ListUserGroupsRequest = (function() {

            /**
             * Properties of a ListUserGroupsRequest.
             * @memberof streem.api
             * @interface IListUserGroupsRequest
             * @property {string|null} [companyCodeOrSid] ListUserGroupsRequest companyCodeOrSid
             * @property {string|null} [userId] ListUserGroupsRequest userId
             * @property {number|null} [pageSize] ListUserGroupsRequest pageSize
             * @property {string|null} [pageToken] ListUserGroupsRequest pageToken
             */

            /**
             * Constructs a new ListUserGroupsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListUserGroupsRequest.
             * @implements IListUserGroupsRequest
             * @constructor
             * @param {streem.api.IListUserGroupsRequest=} [p] Properties to set
             */
            function ListUserGroupsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserGroupsRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListUserGroupsRequest
             * @instance
             */
            ListUserGroupsRequest.prototype.companyCodeOrSid = "";

            /**
             * ListUserGroupsRequest userId.
             * @member {string} userId
             * @memberof streem.api.ListUserGroupsRequest
             * @instance
             */
            ListUserGroupsRequest.prototype.userId = "";

            /**
             * ListUserGroupsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListUserGroupsRequest
             * @instance
             */
            ListUserGroupsRequest.prototype.pageSize = 0;

            /**
             * ListUserGroupsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListUserGroupsRequest
             * @instance
             */
            ListUserGroupsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListUserGroupsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {streem.api.IListUserGroupsRequest=} [properties] Properties to set
             * @returns {streem.api.ListUserGroupsRequest} ListUserGroupsRequest instance
             */
            ListUserGroupsRequest.create = function create(properties) {
                return new ListUserGroupsRequest(properties);
            };

            /**
             * Encodes the specified ListUserGroupsRequest message. Does not implicitly {@link streem.api.ListUserGroupsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {streem.api.IListUserGroupsRequest} m ListUserGroupsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserGroupsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(18).string(m.userId);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(24).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(34).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListUserGroupsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserGroupsRequest} ListUserGroupsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserGroupsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserGroupsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userId = r.string();
                            break;
                        }
                    case 3: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserGroupsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserGroupsRequest} ListUserGroupsRequest
             */
            ListUserGroupsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserGroupsRequest)
                    return d;
                var m = new $root.streem.api.ListUserGroupsRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserGroupsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {streem.api.ListUserGroupsRequest} m ListUserGroupsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserGroupsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.userId = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListUserGroupsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserGroupsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserGroupsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserGroupsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListUserGroupsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserGroupsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserGroupsRequest";
            };

            return ListUserGroupsRequest;
        })();

        api.ListUserGroupsResponse = (function() {

            /**
             * Properties of a ListUserGroupsResponse.
             * @memberof streem.api
             * @interface IListUserGroupsResponse
             * @property {Array.<streem.api.IGroup>|null} [groups] ListUserGroupsResponse groups
             * @property {string|null} [nextPageToken] ListUserGroupsResponse nextPageToken
             * @property {number|null} [totalSize] ListUserGroupsResponse totalSize
             */

            /**
             * Constructs a new ListUserGroupsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListUserGroupsResponse.
             * @implements IListUserGroupsResponse
             * @constructor
             * @param {streem.api.IListUserGroupsResponse=} [p] Properties to set
             */
            function ListUserGroupsResponse(p) {
                this.groups = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserGroupsResponse groups.
             * @member {Array.<streem.api.IGroup>} groups
             * @memberof streem.api.ListUserGroupsResponse
             * @instance
             */
            ListUserGroupsResponse.prototype.groups = $util.emptyArray;

            /**
             * ListUserGroupsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListUserGroupsResponse
             * @instance
             */
            ListUserGroupsResponse.prototype.nextPageToken = "";

            /**
             * ListUserGroupsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListUserGroupsResponse
             * @instance
             */
            ListUserGroupsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListUserGroupsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {streem.api.IListUserGroupsResponse=} [properties] Properties to set
             * @returns {streem.api.ListUserGroupsResponse} ListUserGroupsResponse instance
             */
            ListUserGroupsResponse.create = function create(properties) {
                return new ListUserGroupsResponse(properties);
            };

            /**
             * Encodes the specified ListUserGroupsResponse message. Does not implicitly {@link streem.api.ListUserGroupsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {streem.api.IListUserGroupsResponse} m ListUserGroupsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserGroupsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.groups != null && m.groups.length) {
                    for (var i = 0; i < m.groups.length; ++i)
                        $root.streem.api.Group.encode(m.groups[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListUserGroupsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserGroupsResponse} ListUserGroupsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserGroupsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserGroupsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.groups && m.groups.length))
                                m.groups = [];
                            m.groups.push($root.streem.api.Group.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserGroupsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserGroupsResponse} ListUserGroupsResponse
             */
            ListUserGroupsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserGroupsResponse)
                    return d;
                var m = new $root.streem.api.ListUserGroupsResponse();
                if (d.groups) {
                    if (!Array.isArray(d.groups))
                        throw TypeError(".streem.api.ListUserGroupsResponse.groups: array expected");
                    m.groups = [];
                    for (var i = 0; i < d.groups.length; ++i) {
                        if (typeof d.groups[i] !== "object")
                            throw TypeError(".streem.api.ListUserGroupsResponse.groups: object expected");
                        m.groups[i] = $root.streem.api.Group.fromObject(d.groups[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserGroupsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {streem.api.ListUserGroupsResponse} m ListUserGroupsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserGroupsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groups = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.groups && m.groups.length) {
                    d.groups = [];
                    for (var j = 0; j < m.groups.length; ++j) {
                        d.groups[j] = $root.streem.api.Group.toObject(m.groups[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListUserGroupsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserGroupsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserGroupsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserGroupsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListUserGroupsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserGroupsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserGroupsResponse";
            };

            return ListUserGroupsResponse;
        })();

        api.CreateGroupReservationRequest = (function() {

            /**
             * Properties of a CreateGroupReservationRequest.
             * @memberof streem.api
             * @interface ICreateGroupReservationRequest
             * @property {string|null} [companyCodeOrSid] CreateGroupReservationRequest companyCodeOrSid
             * @property {string|null} [groupName] CreateGroupReservationRequest groupName
             * @property {string|null} [externalUserId] CreateGroupReservationRequest externalUserId
             * @property {boolean|null} [queue] CreateGroupReservationRequest queue
             * @property {number|null} [priority] CreateGroupReservationRequest priority
             * @property {number|null} [reserveForSeconds] CreateGroupReservationRequest reserveForSeconds
             * @property {Array.<streem.api.GroupReservation.IDetail>|null} [details] CreateGroupReservationRequest details
             * @property {string|null} [referenceId] CreateGroupReservationRequest referenceId
             * @property {string|null} [integrationId] CreateGroupReservationRequest integrationId
             * @property {string|null} [reconnectPreviousReservationSid] CreateGroupReservationRequest reconnectPreviousReservationSid
             * @property {streem.api.CreateGroupReservationRequest.ICallDetail|null} [callDetail] CreateGroupReservationRequest callDetail
             */

            /**
             * Constructs a new CreateGroupReservationRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateGroupReservationRequest.
             * @implements ICreateGroupReservationRequest
             * @constructor
             * @param {streem.api.ICreateGroupReservationRequest=} [p] Properties to set
             */
            function CreateGroupReservationRequest(p) {
                this.details = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateGroupReservationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateGroupReservationRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.groupName = "";

            /**
             * CreateGroupReservationRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.externalUserId = "";

            /**
             * CreateGroupReservationRequest queue.
             * @member {boolean} queue
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.queue = false;

            /**
             * CreateGroupReservationRequest priority.
             * @member {number} priority
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.priority = 0;

            /**
             * CreateGroupReservationRequest reserveForSeconds.
             * @member {number} reserveForSeconds
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.reserveForSeconds = 0;

            /**
             * CreateGroupReservationRequest details.
             * @member {Array.<streem.api.GroupReservation.IDetail>} details
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.details = $util.emptyArray;

            /**
             * CreateGroupReservationRequest referenceId.
             * @member {string} referenceId
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.referenceId = "";

            /**
             * CreateGroupReservationRequest integrationId.
             * @member {string} integrationId
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.integrationId = "";

            /**
             * CreateGroupReservationRequest reconnectPreviousReservationSid.
             * @member {string} reconnectPreviousReservationSid
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.reconnectPreviousReservationSid = "";

            /**
             * CreateGroupReservationRequest callDetail.
             * @member {streem.api.CreateGroupReservationRequest.ICallDetail|null|undefined} callDetail
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             */
            CreateGroupReservationRequest.prototype.callDetail = null;

            /**
             * Creates a new CreateGroupReservationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {streem.api.ICreateGroupReservationRequest=} [properties] Properties to set
             * @returns {streem.api.CreateGroupReservationRequest} CreateGroupReservationRequest instance
             */
            CreateGroupReservationRequest.create = function create(properties) {
                return new CreateGroupReservationRequest(properties);
            };

            /**
             * Encodes the specified CreateGroupReservationRequest message. Does not implicitly {@link streem.api.CreateGroupReservationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {streem.api.ICreateGroupReservationRequest} m CreateGroupReservationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroupReservationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(26).string(m.externalUserId);
                if (m.queue != null && Object.hasOwnProperty.call(m, "queue"))
                    w.uint32(32).bool(m.queue);
                if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                    w.uint32(56).int32(m.priority);
                if (m.reserveForSeconds != null && Object.hasOwnProperty.call(m, "reserveForSeconds"))
                    w.uint32(64).int32(m.reserveForSeconds);
                if (m.details != null && m.details.length) {
                    for (var i = 0; i < m.details.length; ++i)
                        $root.streem.api.GroupReservation.Detail.encode(m.details[i], w.uint32(74).fork()).ldelim();
                }
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(82).string(m.referenceId);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(90).string(m.integrationId);
                if (m.reconnectPreviousReservationSid != null && Object.hasOwnProperty.call(m, "reconnectPreviousReservationSid"))
                    w.uint32(98).string(m.reconnectPreviousReservationSid);
                if (m.callDetail != null && Object.hasOwnProperty.call(m, "callDetail"))
                    $root.streem.api.CreateGroupReservationRequest.CallDetail.encode(m.callDetail, w.uint32(106).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateGroupReservationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateGroupReservationRequest} CreateGroupReservationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroupReservationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupReservationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 4: {
                            m.queue = r.bool();
                            break;
                        }
                    case 7: {
                            m.priority = r.int32();
                            break;
                        }
                    case 8: {
                            m.reserveForSeconds = r.int32();
                            break;
                        }
                    case 9: {
                            if (!(m.details && m.details.length))
                                m.details = [];
                            m.details.push($root.streem.api.GroupReservation.Detail.decode(r, r.uint32()));
                            break;
                        }
                    case 10: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 11: {
                            m.integrationId = r.string();
                            break;
                        }
                    case 12: {
                            m.reconnectPreviousReservationSid = r.string();
                            break;
                        }
                    case 13: {
                            m.callDetail = $root.streem.api.CreateGroupReservationRequest.CallDetail.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateGroupReservationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateGroupReservationRequest} CreateGroupReservationRequest
             */
            CreateGroupReservationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateGroupReservationRequest)
                    return d;
                var m = new $root.streem.api.CreateGroupReservationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.queue != null) {
                    m.queue = Boolean(d.queue);
                }
                if (d.priority != null) {
                    m.priority = d.priority | 0;
                }
                if (d.reserveForSeconds != null) {
                    m.reserveForSeconds = d.reserveForSeconds | 0;
                }
                if (d.details) {
                    if (!Array.isArray(d.details))
                        throw TypeError(".streem.api.CreateGroupReservationRequest.details: array expected");
                    m.details = [];
                    for (var i = 0; i < d.details.length; ++i) {
                        if (typeof d.details[i] !== "object")
                            throw TypeError(".streem.api.CreateGroupReservationRequest.details: object expected");
                        m.details[i] = $root.streem.api.GroupReservation.Detail.fromObject(d.details[i]);
                    }
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                if (d.reconnectPreviousReservationSid != null) {
                    m.reconnectPreviousReservationSid = String(d.reconnectPreviousReservationSid);
                }
                if (d.callDetail != null) {
                    if (typeof d.callDetail !== "object")
                        throw TypeError(".streem.api.CreateGroupReservationRequest.callDetail: object expected");
                    m.callDetail = $root.streem.api.CreateGroupReservationRequest.CallDetail.fromObject(d.callDetail);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateGroupReservationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {streem.api.CreateGroupReservationRequest} m CreateGroupReservationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroupReservationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.details = [];
                }
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.externalUserId = "";
                    d.queue = false;
                    d.priority = 0;
                    d.reserveForSeconds = 0;
                    d.referenceId = "";
                    d.integrationId = "";
                    d.reconnectPreviousReservationSid = "";
                    d.callDetail = null;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.queue != null && m.hasOwnProperty("queue")) {
                    d.queue = m.queue;
                }
                if (m.priority != null && m.hasOwnProperty("priority")) {
                    d.priority = m.priority;
                }
                if (m.reserveForSeconds != null && m.hasOwnProperty("reserveForSeconds")) {
                    d.reserveForSeconds = m.reserveForSeconds;
                }
                if (m.details && m.details.length) {
                    d.details = [];
                    for (var j = 0; j < m.details.length; ++j) {
                        d.details[j] = $root.streem.api.GroupReservation.Detail.toObject(m.details[j], o);
                    }
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                if (m.reconnectPreviousReservationSid != null && m.hasOwnProperty("reconnectPreviousReservationSid")) {
                    d.reconnectPreviousReservationSid = m.reconnectPreviousReservationSid;
                }
                if (m.callDetail != null && m.hasOwnProperty("callDetail")) {
                    d.callDetail = $root.streem.api.CreateGroupReservationRequest.CallDetail.toObject(m.callDetail, o);
                }
                return d;
            };

            /**
             * Converts this CreateGroupReservationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateGroupReservationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroupReservationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateGroupReservationRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateGroupReservationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateGroupReservationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateGroupReservationRequest";
            };

            CreateGroupReservationRequest.CallDetail = (function() {

                /**
                 * Properties of a CallDetail.
                 * @memberof streem.api.CreateGroupReservationRequest
                 * @interface ICallDetail
                 * @property {string|null} [callSourceCode] CallDetail callSourceCode
                 * @property {Array.<streem.api.CreateGroupReservationRequest.CallDetail.IItem>|null} [items] CallDetail items
                 * @property {streem.api.CreateGroupReservationRequest.CallDetail.ICustomer|null} [customer] CallDetail customer
                 */

                /**
                 * Constructs a new CallDetail.
                 * @memberof streem.api.CreateGroupReservationRequest
                 * @classdesc Represents a CallDetail.
                 * @implements ICallDetail
                 * @constructor
                 * @param {streem.api.CreateGroupReservationRequest.ICallDetail=} [p] Properties to set
                 */
                function CallDetail(p) {
                    this.items = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CallDetail callSourceCode.
                 * @member {string} callSourceCode
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @instance
                 */
                CallDetail.prototype.callSourceCode = "";

                /**
                 * CallDetail items.
                 * @member {Array.<streem.api.CreateGroupReservationRequest.CallDetail.IItem>} items
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @instance
                 */
                CallDetail.prototype.items = $util.emptyArray;

                /**
                 * CallDetail customer.
                 * @member {streem.api.CreateGroupReservationRequest.CallDetail.ICustomer|null|undefined} customer
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @instance
                 */
                CallDetail.prototype.customer = null;

                /**
                 * Creates a new CallDetail instance using the specified properties.
                 * @function create
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {streem.api.CreateGroupReservationRequest.ICallDetail=} [properties] Properties to set
                 * @returns {streem.api.CreateGroupReservationRequest.CallDetail} CallDetail instance
                 */
                CallDetail.create = function create(properties) {
                    return new CallDetail(properties);
                };

                /**
                 * Encodes the specified CallDetail message. Does not implicitly {@link streem.api.CreateGroupReservationRequest.CallDetail.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {streem.api.CreateGroupReservationRequest.ICallDetail} m CallDetail message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallDetail.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.callSourceCode != null && Object.hasOwnProperty.call(m, "callSourceCode"))
                        w.uint32(10).string(m.callSourceCode);
                    if (m.items != null && m.items.length) {
                        for (var i = 0; i < m.items.length; ++i)
                            $root.streem.api.CreateGroupReservationRequest.CallDetail.Item.encode(m.items[i], w.uint32(18).fork()).ldelim();
                    }
                    if (m.customer != null && Object.hasOwnProperty.call(m, "customer"))
                        $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer.encode(m.customer, w.uint32(26).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a CallDetail message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.CreateGroupReservationRequest.CallDetail} CallDetail
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallDetail.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupReservationRequest.CallDetail();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.callSourceCode = r.string();
                                break;
                            }
                        case 2: {
                                if (!(m.items && m.items.length))
                                    m.items = [];
                                m.items.push($root.streem.api.CreateGroupReservationRequest.CallDetail.Item.decode(r, r.uint32()));
                                break;
                            }
                        case 3: {
                                m.customer = $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CallDetail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.CreateGroupReservationRequest.CallDetail} CallDetail
                 */
                CallDetail.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.CreateGroupReservationRequest.CallDetail)
                        return d;
                    var m = new $root.streem.api.CreateGroupReservationRequest.CallDetail();
                    if (d.callSourceCode != null) {
                        m.callSourceCode = String(d.callSourceCode);
                    }
                    if (d.items) {
                        if (!Array.isArray(d.items))
                            throw TypeError(".streem.api.CreateGroupReservationRequest.CallDetail.items: array expected");
                        m.items = [];
                        for (var i = 0; i < d.items.length; ++i) {
                            if (typeof d.items[i] !== "object")
                                throw TypeError(".streem.api.CreateGroupReservationRequest.CallDetail.items: object expected");
                            m.items[i] = $root.streem.api.CreateGroupReservationRequest.CallDetail.Item.fromObject(d.items[i]);
                        }
                    }
                    if (d.customer != null) {
                        if (typeof d.customer !== "object")
                            throw TypeError(".streem.api.CreateGroupReservationRequest.CallDetail.customer: object expected");
                        m.customer = $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer.fromObject(d.customer);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CallDetail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {streem.api.CreateGroupReservationRequest.CallDetail} m CallDetail
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallDetail.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.items = [];
                    }
                    if (o.defaults) {
                        d.callSourceCode = "";
                        d.customer = null;
                    }
                    if (m.callSourceCode != null && m.hasOwnProperty("callSourceCode")) {
                        d.callSourceCode = m.callSourceCode;
                    }
                    if (m.items && m.items.length) {
                        d.items = [];
                        for (var j = 0; j < m.items.length; ++j) {
                            d.items[j] = $root.streem.api.CreateGroupReservationRequest.CallDetail.Item.toObject(m.items[j], o);
                        }
                    }
                    if (m.customer != null && m.hasOwnProperty("customer")) {
                        d.customer = $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer.toObject(m.customer, o);
                    }
                    return d;
                };

                /**
                 * Converts this CallDetail to JSON.
                 * @function toJSON
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallDetail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CallDetail
                 * @function getTypeUrl
                 * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.CreateGroupReservationRequest.CallDetail";
                };

                CallDetail.Item = (function() {

                    /**
                     * Properties of an Item.
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                     * @interface IItem
                     * @property {string|null} [description] Item description
                     * @property {string|null} [manufacturer] Item manufacturer
                     * @property {string|null} [brand] Item brand
                     * @property {string|null} [modelNumber] Item modelNumber
                     * @property {Array.<string>|null} [symptoms] Item symptoms
                     * @property {string|null} [tradeDescription] Item tradeDescription
                     */

                    /**
                     * Constructs a new Item.
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                     * @classdesc Represents an Item.
                     * @implements IItem
                     * @constructor
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.IItem=} [p] Properties to set
                     */
                    function Item(p) {
                        this.symptoms = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Item description.
                     * @member {string|null|undefined} description
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.description = null;

                    /**
                     * Item manufacturer.
                     * @member {string|null|undefined} manufacturer
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.manufacturer = null;

                    /**
                     * Item brand.
                     * @member {string|null|undefined} brand
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.brand = null;

                    /**
                     * Item modelNumber.
                     * @member {string|null|undefined} modelNumber
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.modelNumber = null;

                    /**
                     * Item symptoms.
                     * @member {Array.<string>} symptoms
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.symptoms = $util.emptyArray;

                    /**
                     * Item tradeDescription.
                     * @member {string|null|undefined} tradeDescription
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Item.prototype.tradeDescription = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * Item _description.
                     * @member {"description"|undefined} _description
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Object.defineProperty(Item.prototype, "_description", {
                        get: $util.oneOfGetter($oneOfFields = ["description"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Item _manufacturer.
                     * @member {"manufacturer"|undefined} _manufacturer
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Object.defineProperty(Item.prototype, "_manufacturer", {
                        get: $util.oneOfGetter($oneOfFields = ["manufacturer"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Item _brand.
                     * @member {"brand"|undefined} _brand
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Object.defineProperty(Item.prototype, "_brand", {
                        get: $util.oneOfGetter($oneOfFields = ["brand"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Item _modelNumber.
                     * @member {"modelNumber"|undefined} _modelNumber
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Object.defineProperty(Item.prototype, "_modelNumber", {
                        get: $util.oneOfGetter($oneOfFields = ["modelNumber"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Item _tradeDescription.
                     * @member {"tradeDescription"|undefined} _tradeDescription
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     */
                    Object.defineProperty(Item.prototype, "_tradeDescription", {
                        get: $util.oneOfGetter($oneOfFields = ["tradeDescription"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new Item instance using the specified properties.
                     * @function create
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.IItem=} [properties] Properties to set
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Item} Item instance
                     */
                    Item.create = function create(properties) {
                        return new Item(properties);
                    };

                    /**
                     * Encodes the specified Item message. Does not implicitly {@link streem.api.CreateGroupReservationRequest.CallDetail.Item.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.IItem} m Item message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Item.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                            w.uint32(10).string(m.description);
                        if (m.manufacturer != null && Object.hasOwnProperty.call(m, "manufacturer"))
                            w.uint32(18).string(m.manufacturer);
                        if (m.brand != null && Object.hasOwnProperty.call(m, "brand"))
                            w.uint32(26).string(m.brand);
                        if (m.modelNumber != null && Object.hasOwnProperty.call(m, "modelNumber"))
                            w.uint32(34).string(m.modelNumber);
                        if (m.symptoms != null && m.symptoms.length) {
                            for (var i = 0; i < m.symptoms.length; ++i)
                                w.uint32(42).string(m.symptoms[i]);
                        }
                        if (m.tradeDescription != null && Object.hasOwnProperty.call(m, "tradeDescription"))
                            w.uint32(50).string(m.tradeDescription);
                        return w;
                    };

                    /**
                     * Decodes an Item message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Item} Item
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Item.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupReservationRequest.CallDetail.Item();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.description = r.string();
                                    break;
                                }
                            case 2: {
                                    m.manufacturer = r.string();
                                    break;
                                }
                            case 3: {
                                    m.brand = r.string();
                                    break;
                                }
                            case 4: {
                                    m.modelNumber = r.string();
                                    break;
                                }
                            case 5: {
                                    if (!(m.symptoms && m.symptoms.length))
                                        m.symptoms = [];
                                    m.symptoms.push(r.string());
                                    break;
                                }
                            case 6: {
                                    m.tradeDescription = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an Item message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Item} Item
                     */
                    Item.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.CreateGroupReservationRequest.CallDetail.Item)
                            return d;
                        var m = new $root.streem.api.CreateGroupReservationRequest.CallDetail.Item();
                        if (d.description != null) {
                            m.description = String(d.description);
                        }
                        if (d.manufacturer != null) {
                            m.manufacturer = String(d.manufacturer);
                        }
                        if (d.brand != null) {
                            m.brand = String(d.brand);
                        }
                        if (d.modelNumber != null) {
                            m.modelNumber = String(d.modelNumber);
                        }
                        if (d.symptoms) {
                            if (!Array.isArray(d.symptoms))
                                throw TypeError(".streem.api.CreateGroupReservationRequest.CallDetail.Item.symptoms: array expected");
                            m.symptoms = [];
                            for (var i = 0; i < d.symptoms.length; ++i) {
                                m.symptoms[i] = String(d.symptoms[i]);
                            }
                        }
                        if (d.tradeDescription != null) {
                            m.tradeDescription = String(d.tradeDescription);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an Item message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.Item} m Item
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Item.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.symptoms = [];
                        }
                        if (m.description != null && m.hasOwnProperty("description")) {
                            d.description = m.description;
                            if (o.oneofs)
                                d._description = "description";
                        }
                        if (m.manufacturer != null && m.hasOwnProperty("manufacturer")) {
                            d.manufacturer = m.manufacturer;
                            if (o.oneofs)
                                d._manufacturer = "manufacturer";
                        }
                        if (m.brand != null && m.hasOwnProperty("brand")) {
                            d.brand = m.brand;
                            if (o.oneofs)
                                d._brand = "brand";
                        }
                        if (m.modelNumber != null && m.hasOwnProperty("modelNumber")) {
                            d.modelNumber = m.modelNumber;
                            if (o.oneofs)
                                d._modelNumber = "modelNumber";
                        }
                        if (m.symptoms && m.symptoms.length) {
                            d.symptoms = [];
                            for (var j = 0; j < m.symptoms.length; ++j) {
                                d.symptoms[j] = m.symptoms[j];
                            }
                        }
                        if (m.tradeDescription != null && m.hasOwnProperty("tradeDescription")) {
                            d.tradeDescription = m.tradeDescription;
                            if (o.oneofs)
                                d._tradeDescription = "tradeDescription";
                        }
                        return d;
                    };

                    /**
                     * Converts this Item to JSON.
                     * @function toJSON
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Item.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Item
                     * @function getTypeUrl
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Item
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.CreateGroupReservationRequest.CallDetail.Item";
                    };

                    return Item;
                })();

                CallDetail.Customer = (function() {

                    /**
                     * Properties of a Customer.
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                     * @interface ICustomer
                     * @property {string|null} [name] Customer name
                     * @property {string|null} [planType] Customer planType
                     * @property {string|null} [contractNumber] Customer contractNumber
                     * @property {number|null} [callsAvailable] Customer callsAvailable
                     * @property {string|null} [address] Customer address
                     */

                    /**
                     * Constructs a new Customer.
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail
                     * @classdesc Represents a Customer.
                     * @implements ICustomer
                     * @constructor
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.ICustomer=} [p] Properties to set
                     */
                    function Customer(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Customer name.
                     * @member {string|null|undefined} name
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Customer.prototype.name = null;

                    /**
                     * Customer planType.
                     * @member {string|null|undefined} planType
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Customer.prototype.planType = null;

                    /**
                     * Customer contractNumber.
                     * @member {string|null|undefined} contractNumber
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Customer.prototype.contractNumber = null;

                    /**
                     * Customer callsAvailable.
                     * @member {number|null|undefined} callsAvailable
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Customer.prototype.callsAvailable = null;

                    /**
                     * Customer address.
                     * @member {string|null|undefined} address
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Customer.prototype.address = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * Customer _name.
                     * @member {"name"|undefined} _name
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Object.defineProperty(Customer.prototype, "_name", {
                        get: $util.oneOfGetter($oneOfFields = ["name"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Customer _planType.
                     * @member {"planType"|undefined} _planType
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Object.defineProperty(Customer.prototype, "_planType", {
                        get: $util.oneOfGetter($oneOfFields = ["planType"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Customer _contractNumber.
                     * @member {"contractNumber"|undefined} _contractNumber
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Object.defineProperty(Customer.prototype, "_contractNumber", {
                        get: $util.oneOfGetter($oneOfFields = ["contractNumber"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Customer _callsAvailable.
                     * @member {"callsAvailable"|undefined} _callsAvailable
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Object.defineProperty(Customer.prototype, "_callsAvailable", {
                        get: $util.oneOfGetter($oneOfFields = ["callsAvailable"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Customer _address.
                     * @member {"address"|undefined} _address
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     */
                    Object.defineProperty(Customer.prototype, "_address", {
                        get: $util.oneOfGetter($oneOfFields = ["address"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new Customer instance using the specified properties.
                     * @function create
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.ICustomer=} [properties] Properties to set
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Customer} Customer instance
                     */
                    Customer.create = function create(properties) {
                        return new Customer(properties);
                    };

                    /**
                     * Encodes the specified Customer message. Does not implicitly {@link streem.api.CreateGroupReservationRequest.CallDetail.Customer.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.ICustomer} m Customer message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Customer.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                            w.uint32(10).string(m.name);
                        if (m.planType != null && Object.hasOwnProperty.call(m, "planType"))
                            w.uint32(18).string(m.planType);
                        if (m.contractNumber != null && Object.hasOwnProperty.call(m, "contractNumber"))
                            w.uint32(26).string(m.contractNumber);
                        if (m.callsAvailable != null && Object.hasOwnProperty.call(m, "callsAvailable"))
                            w.uint32(32).int32(m.callsAvailable);
                        if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                            w.uint32(42).string(m.address);
                        return w;
                    };

                    /**
                     * Decodes a Customer message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Customer} Customer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Customer.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.name = r.string();
                                    break;
                                }
                            case 2: {
                                    m.planType = r.string();
                                    break;
                                }
                            case 3: {
                                    m.contractNumber = r.string();
                                    break;
                                }
                            case 4: {
                                    m.callsAvailable = r.int32();
                                    break;
                                }
                            case 5: {
                                    m.address = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Customer message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.CreateGroupReservationRequest.CallDetail.Customer} Customer
                     */
                    Customer.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer)
                            return d;
                        var m = new $root.streem.api.CreateGroupReservationRequest.CallDetail.Customer();
                        if (d.name != null) {
                            m.name = String(d.name);
                        }
                        if (d.planType != null) {
                            m.planType = String(d.planType);
                        }
                        if (d.contractNumber != null) {
                            m.contractNumber = String(d.contractNumber);
                        }
                        if (d.callsAvailable != null) {
                            m.callsAvailable = d.callsAvailable | 0;
                        }
                        if (d.address != null) {
                            m.address = String(d.address);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Customer message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {streem.api.CreateGroupReservationRequest.CallDetail.Customer} m Customer
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Customer.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (m.name != null && m.hasOwnProperty("name")) {
                            d.name = m.name;
                            if (o.oneofs)
                                d._name = "name";
                        }
                        if (m.planType != null && m.hasOwnProperty("planType")) {
                            d.planType = m.planType;
                            if (o.oneofs)
                                d._planType = "planType";
                        }
                        if (m.contractNumber != null && m.hasOwnProperty("contractNumber")) {
                            d.contractNumber = m.contractNumber;
                            if (o.oneofs)
                                d._contractNumber = "contractNumber";
                        }
                        if (m.callsAvailable != null && m.hasOwnProperty("callsAvailable")) {
                            d.callsAvailable = m.callsAvailable;
                            if (o.oneofs)
                                d._callsAvailable = "callsAvailable";
                        }
                        if (m.address != null && m.hasOwnProperty("address")) {
                            d.address = m.address;
                            if (o.oneofs)
                                d._address = "address";
                        }
                        return d;
                    };

                    /**
                     * Converts this Customer to JSON.
                     * @function toJSON
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Customer.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Customer
                     * @function getTypeUrl
                     * @memberof streem.api.CreateGroupReservationRequest.CallDetail.Customer
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Customer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.CreateGroupReservationRequest.CallDetail.Customer";
                    };

                    return Customer;
                })();

                return CallDetail;
            })();

            return CreateGroupReservationRequest;
        })();

        api.CreateGroupReservationResponse = (function() {

            /**
             * Properties of a CreateGroupReservationResponse.
             * @memberof streem.api
             * @interface ICreateGroupReservationResponse
             * @property {streem.api.IGroupReservation|null} [groupReservation] CreateGroupReservationResponse groupReservation
             */

            /**
             * Constructs a new CreateGroupReservationResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateGroupReservationResponse.
             * @implements ICreateGroupReservationResponse
             * @constructor
             * @param {streem.api.ICreateGroupReservationResponse=} [p] Properties to set
             */
            function CreateGroupReservationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateGroupReservationResponse groupReservation.
             * @member {streem.api.IGroupReservation|null|undefined} groupReservation
             * @memberof streem.api.CreateGroupReservationResponse
             * @instance
             */
            CreateGroupReservationResponse.prototype.groupReservation = null;

            /**
             * Creates a new CreateGroupReservationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {streem.api.ICreateGroupReservationResponse=} [properties] Properties to set
             * @returns {streem.api.CreateGroupReservationResponse} CreateGroupReservationResponse instance
             */
            CreateGroupReservationResponse.create = function create(properties) {
                return new CreateGroupReservationResponse(properties);
            };

            /**
             * Encodes the specified CreateGroupReservationResponse message. Does not implicitly {@link streem.api.CreateGroupReservationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {streem.api.ICreateGroupReservationResponse} m CreateGroupReservationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroupReservationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.groupReservation != null && Object.hasOwnProperty.call(m, "groupReservation"))
                    $root.streem.api.GroupReservation.encode(m.groupReservation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateGroupReservationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateGroupReservationResponse} CreateGroupReservationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroupReservationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateGroupReservationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.groupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateGroupReservationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateGroupReservationResponse} CreateGroupReservationResponse
             */
            CreateGroupReservationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateGroupReservationResponse)
                    return d;
                var m = new $root.streem.api.CreateGroupReservationResponse();
                if (d.groupReservation != null) {
                    if (typeof d.groupReservation !== "object")
                        throw TypeError(".streem.api.CreateGroupReservationResponse.groupReservation: object expected");
                    m.groupReservation = $root.streem.api.GroupReservation.fromObject(d.groupReservation);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateGroupReservationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {streem.api.CreateGroupReservationResponse} m CreateGroupReservationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroupReservationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.groupReservation = null;
                }
                if (m.groupReservation != null && m.hasOwnProperty("groupReservation")) {
                    d.groupReservation = $root.streem.api.GroupReservation.toObject(m.groupReservation, o);
                }
                return d;
            };

            /**
             * Converts this CreateGroupReservationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateGroupReservationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroupReservationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateGroupReservationResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateGroupReservationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateGroupReservationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateGroupReservationResponse";
            };

            return CreateGroupReservationResponse;
        })();

        api.GetGroupReservationRequest = (function() {

            /**
             * Properties of a GetGroupReservationRequest.
             * @memberof streem.api
             * @interface IGetGroupReservationRequest
             * @property {string|null} [companyCodeOrSid] GetGroupReservationRequest companyCodeOrSid
             * @property {string|null} [groupName] GetGroupReservationRequest groupName
             * @property {string|null} [reservationSid] GetGroupReservationRequest reservationSid
             */

            /**
             * Constructs a new GetGroupReservationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetGroupReservationRequest.
             * @implements IGetGroupReservationRequest
             * @constructor
             * @param {streem.api.IGetGroupReservationRequest=} [p] Properties to set
             */
            function GetGroupReservationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupReservationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetGroupReservationRequest
             * @instance
             */
            GetGroupReservationRequest.prototype.companyCodeOrSid = "";

            /**
             * GetGroupReservationRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.GetGroupReservationRequest
             * @instance
             */
            GetGroupReservationRequest.prototype.groupName = "";

            /**
             * GetGroupReservationRequest reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.GetGroupReservationRequest
             * @instance
             */
            GetGroupReservationRequest.prototype.reservationSid = "";

            /**
             * Creates a new GetGroupReservationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {streem.api.IGetGroupReservationRequest=} [properties] Properties to set
             * @returns {streem.api.GetGroupReservationRequest} GetGroupReservationRequest instance
             */
            GetGroupReservationRequest.create = function create(properties) {
                return new GetGroupReservationRequest(properties);
            };

            /**
             * Encodes the specified GetGroupReservationRequest message. Does not implicitly {@link streem.api.GetGroupReservationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {streem.api.IGetGroupReservationRequest} m GetGroupReservationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupReservationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(26).string(m.reservationSid);
                return w;
            };

            /**
             * Decodes a GetGroupReservationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupReservationRequest} GetGroupReservationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupReservationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupReservationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.reservationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupReservationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupReservationRequest} GetGroupReservationRequest
             */
            GetGroupReservationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupReservationRequest)
                    return d;
                var m = new $root.streem.api.GetGroupReservationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupReservationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {streem.api.GetGroupReservationRequest} m GetGroupReservationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupReservationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.reservationSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                return d;
            };

            /**
             * Converts this GetGroupReservationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupReservationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupReservationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupReservationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetGroupReservationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupReservationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupReservationRequest";
            };

            return GetGroupReservationRequest;
        })();

        api.GetGroupReservationResponse = (function() {

            /**
             * Properties of a GetGroupReservationResponse.
             * @memberof streem.api
             * @interface IGetGroupReservationResponse
             * @property {streem.api.IGroupReservation|null} [groupReservation] GetGroupReservationResponse groupReservation
             */

            /**
             * Constructs a new GetGroupReservationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetGroupReservationResponse.
             * @implements IGetGroupReservationResponse
             * @constructor
             * @param {streem.api.IGetGroupReservationResponse=} [p] Properties to set
             */
            function GetGroupReservationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupReservationResponse groupReservation.
             * @member {streem.api.IGroupReservation|null|undefined} groupReservation
             * @memberof streem.api.GetGroupReservationResponse
             * @instance
             */
            GetGroupReservationResponse.prototype.groupReservation = null;

            /**
             * Creates a new GetGroupReservationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {streem.api.IGetGroupReservationResponse=} [properties] Properties to set
             * @returns {streem.api.GetGroupReservationResponse} GetGroupReservationResponse instance
             */
            GetGroupReservationResponse.create = function create(properties) {
                return new GetGroupReservationResponse(properties);
            };

            /**
             * Encodes the specified GetGroupReservationResponse message. Does not implicitly {@link streem.api.GetGroupReservationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {streem.api.IGetGroupReservationResponse} m GetGroupReservationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupReservationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.groupReservation != null && Object.hasOwnProperty.call(m, "groupReservation"))
                    $root.streem.api.GroupReservation.encode(m.groupReservation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetGroupReservationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupReservationResponse} GetGroupReservationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupReservationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupReservationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.groupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupReservationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupReservationResponse} GetGroupReservationResponse
             */
            GetGroupReservationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupReservationResponse)
                    return d;
                var m = new $root.streem.api.GetGroupReservationResponse();
                if (d.groupReservation != null) {
                    if (typeof d.groupReservation !== "object")
                        throw TypeError(".streem.api.GetGroupReservationResponse.groupReservation: object expected");
                    m.groupReservation = $root.streem.api.GroupReservation.fromObject(d.groupReservation);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupReservationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {streem.api.GetGroupReservationResponse} m GetGroupReservationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupReservationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.groupReservation = null;
                }
                if (m.groupReservation != null && m.hasOwnProperty("groupReservation")) {
                    d.groupReservation = $root.streem.api.GroupReservation.toObject(m.groupReservation, o);
                }
                return d;
            };

            /**
             * Converts this GetGroupReservationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupReservationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupReservationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupReservationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetGroupReservationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupReservationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupReservationResponse";
            };

            return GetGroupReservationResponse;
        })();

        api.CancelGroupReservationRequest = (function() {

            /**
             * Properties of a CancelGroupReservationRequest.
             * @memberof streem.api
             * @interface ICancelGroupReservationRequest
             * @property {string|null} [companyCodeOrSid] CancelGroupReservationRequest companyCodeOrSid
             * @property {string|null} [groupName] CancelGroupReservationRequest groupName
             * @property {string|null} [reservationSid] CancelGroupReservationRequest reservationSid
             */

            /**
             * Constructs a new CancelGroupReservationRequest.
             * @memberof streem.api
             * @classdesc Represents a CancelGroupReservationRequest.
             * @implements ICancelGroupReservationRequest
             * @constructor
             * @param {streem.api.ICancelGroupReservationRequest=} [p] Properties to set
             */
            function CancelGroupReservationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CancelGroupReservationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CancelGroupReservationRequest
             * @instance
             */
            CancelGroupReservationRequest.prototype.companyCodeOrSid = "";

            /**
             * CancelGroupReservationRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.CancelGroupReservationRequest
             * @instance
             */
            CancelGroupReservationRequest.prototype.groupName = "";

            /**
             * CancelGroupReservationRequest reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.CancelGroupReservationRequest
             * @instance
             */
            CancelGroupReservationRequest.prototype.reservationSid = "";

            /**
             * Creates a new CancelGroupReservationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {streem.api.ICancelGroupReservationRequest=} [properties] Properties to set
             * @returns {streem.api.CancelGroupReservationRequest} CancelGroupReservationRequest instance
             */
            CancelGroupReservationRequest.create = function create(properties) {
                return new CancelGroupReservationRequest(properties);
            };

            /**
             * Encodes the specified CancelGroupReservationRequest message. Does not implicitly {@link streem.api.CancelGroupReservationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {streem.api.ICancelGroupReservationRequest} m CancelGroupReservationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelGroupReservationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(26).string(m.reservationSid);
                return w;
            };

            /**
             * Decodes a CancelGroupReservationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CancelGroupReservationRequest} CancelGroupReservationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelGroupReservationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CancelGroupReservationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.reservationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CancelGroupReservationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CancelGroupReservationRequest} CancelGroupReservationRequest
             */
            CancelGroupReservationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CancelGroupReservationRequest)
                    return d;
                var m = new $root.streem.api.CancelGroupReservationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CancelGroupReservationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {streem.api.CancelGroupReservationRequest} m CancelGroupReservationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelGroupReservationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.reservationSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                return d;
            };

            /**
             * Converts this CancelGroupReservationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CancelGroupReservationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelGroupReservationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelGroupReservationRequest
             * @function getTypeUrl
             * @memberof streem.api.CancelGroupReservationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelGroupReservationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CancelGroupReservationRequest";
            };

            return CancelGroupReservationRequest;
        })();

        api.CancelGroupReservationResponse = (function() {

            /**
             * Properties of a CancelGroupReservationResponse.
             * @memberof streem.api
             * @interface ICancelGroupReservationResponse
             */

            /**
             * Constructs a new CancelGroupReservationResponse.
             * @memberof streem.api
             * @classdesc Represents a CancelGroupReservationResponse.
             * @implements ICancelGroupReservationResponse
             * @constructor
             * @param {streem.api.ICancelGroupReservationResponse=} [p] Properties to set
             */
            function CancelGroupReservationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new CancelGroupReservationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {streem.api.ICancelGroupReservationResponse=} [properties] Properties to set
             * @returns {streem.api.CancelGroupReservationResponse} CancelGroupReservationResponse instance
             */
            CancelGroupReservationResponse.create = function create(properties) {
                return new CancelGroupReservationResponse(properties);
            };

            /**
             * Encodes the specified CancelGroupReservationResponse message. Does not implicitly {@link streem.api.CancelGroupReservationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {streem.api.ICancelGroupReservationResponse} m CancelGroupReservationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelGroupReservationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a CancelGroupReservationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CancelGroupReservationResponse} CancelGroupReservationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelGroupReservationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CancelGroupReservationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CancelGroupReservationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CancelGroupReservationResponse} CancelGroupReservationResponse
             */
            CancelGroupReservationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CancelGroupReservationResponse)
                    return d;
                return new $root.streem.api.CancelGroupReservationResponse();
            };

            /**
             * Creates a plain object from a CancelGroupReservationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {streem.api.CancelGroupReservationResponse} m CancelGroupReservationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelGroupReservationResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CancelGroupReservationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CancelGroupReservationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelGroupReservationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CancelGroupReservationResponse
             * @function getTypeUrl
             * @memberof streem.api.CancelGroupReservationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelGroupReservationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CancelGroupReservationResponse";
            };

            return CancelGroupReservationResponse;
        })();

        api.ConfirmMatchedGroupReservationRequest = (function() {

            /**
             * Properties of a ConfirmMatchedGroupReservationRequest.
             * @memberof streem.api
             * @interface IConfirmMatchedGroupReservationRequest
             * @property {string|null} [companyCodeOrSid] ConfirmMatchedGroupReservationRequest companyCodeOrSid
             * @property {string|null} [groupName] ConfirmMatchedGroupReservationRequest groupName
             * @property {string|null} [reservationSid] ConfirmMatchedGroupReservationRequest reservationSid
             */

            /**
             * Constructs a new ConfirmMatchedGroupReservationRequest.
             * @memberof streem.api
             * @classdesc Represents a ConfirmMatchedGroupReservationRequest.
             * @implements IConfirmMatchedGroupReservationRequest
             * @constructor
             * @param {streem.api.IConfirmMatchedGroupReservationRequest=} [p] Properties to set
             */
            function ConfirmMatchedGroupReservationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ConfirmMatchedGroupReservationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @instance
             */
            ConfirmMatchedGroupReservationRequest.prototype.companyCodeOrSid = "";

            /**
             * ConfirmMatchedGroupReservationRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @instance
             */
            ConfirmMatchedGroupReservationRequest.prototype.groupName = "";

            /**
             * ConfirmMatchedGroupReservationRequest reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @instance
             */
            ConfirmMatchedGroupReservationRequest.prototype.reservationSid = "";

            /**
             * Creates a new ConfirmMatchedGroupReservationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {streem.api.IConfirmMatchedGroupReservationRequest=} [properties] Properties to set
             * @returns {streem.api.ConfirmMatchedGroupReservationRequest} ConfirmMatchedGroupReservationRequest instance
             */
            ConfirmMatchedGroupReservationRequest.create = function create(properties) {
                return new ConfirmMatchedGroupReservationRequest(properties);
            };

            /**
             * Encodes the specified ConfirmMatchedGroupReservationRequest message. Does not implicitly {@link streem.api.ConfirmMatchedGroupReservationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {streem.api.IConfirmMatchedGroupReservationRequest} m ConfirmMatchedGroupReservationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmMatchedGroupReservationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(26).string(m.reservationSid);
                return w;
            };

            /**
             * Decodes a ConfirmMatchedGroupReservationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ConfirmMatchedGroupReservationRequest} ConfirmMatchedGroupReservationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmMatchedGroupReservationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ConfirmMatchedGroupReservationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.reservationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ConfirmMatchedGroupReservationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ConfirmMatchedGroupReservationRequest} ConfirmMatchedGroupReservationRequest
             */
            ConfirmMatchedGroupReservationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ConfirmMatchedGroupReservationRequest)
                    return d;
                var m = new $root.streem.api.ConfirmMatchedGroupReservationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ConfirmMatchedGroupReservationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {streem.api.ConfirmMatchedGroupReservationRequest} m ConfirmMatchedGroupReservationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmMatchedGroupReservationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.reservationSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                return d;
            };

            /**
             * Converts this ConfirmMatchedGroupReservationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmMatchedGroupReservationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConfirmMatchedGroupReservationRequest
             * @function getTypeUrl
             * @memberof streem.api.ConfirmMatchedGroupReservationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfirmMatchedGroupReservationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ConfirmMatchedGroupReservationRequest";
            };

            return ConfirmMatchedGroupReservationRequest;
        })();

        api.ConfirmMatchedGroupReservationResponse = (function() {

            /**
             * Properties of a ConfirmMatchedGroupReservationResponse.
             * @memberof streem.api
             * @interface IConfirmMatchedGroupReservationResponse
             */

            /**
             * Constructs a new ConfirmMatchedGroupReservationResponse.
             * @memberof streem.api
             * @classdesc Represents a ConfirmMatchedGroupReservationResponse.
             * @implements IConfirmMatchedGroupReservationResponse
             * @constructor
             * @param {streem.api.IConfirmMatchedGroupReservationResponse=} [p] Properties to set
             */
            function ConfirmMatchedGroupReservationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new ConfirmMatchedGroupReservationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {streem.api.IConfirmMatchedGroupReservationResponse=} [properties] Properties to set
             * @returns {streem.api.ConfirmMatchedGroupReservationResponse} ConfirmMatchedGroupReservationResponse instance
             */
            ConfirmMatchedGroupReservationResponse.create = function create(properties) {
                return new ConfirmMatchedGroupReservationResponse(properties);
            };

            /**
             * Encodes the specified ConfirmMatchedGroupReservationResponse message. Does not implicitly {@link streem.api.ConfirmMatchedGroupReservationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {streem.api.IConfirmMatchedGroupReservationResponse} m ConfirmMatchedGroupReservationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmMatchedGroupReservationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a ConfirmMatchedGroupReservationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ConfirmMatchedGroupReservationResponse} ConfirmMatchedGroupReservationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmMatchedGroupReservationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ConfirmMatchedGroupReservationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ConfirmMatchedGroupReservationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ConfirmMatchedGroupReservationResponse} ConfirmMatchedGroupReservationResponse
             */
            ConfirmMatchedGroupReservationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ConfirmMatchedGroupReservationResponse)
                    return d;
                return new $root.streem.api.ConfirmMatchedGroupReservationResponse();
            };

            /**
             * Creates a plain object from a ConfirmMatchedGroupReservationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {streem.api.ConfirmMatchedGroupReservationResponse} m ConfirmMatchedGroupReservationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmMatchedGroupReservationResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ConfirmMatchedGroupReservationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmMatchedGroupReservationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConfirmMatchedGroupReservationResponse
             * @function getTypeUrl
             * @memberof streem.api.ConfirmMatchedGroupReservationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfirmMatchedGroupReservationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ConfirmMatchedGroupReservationResponse";
            };

            return ConfirmMatchedGroupReservationResponse;
        })();

        api.ListGroupAvailabilityRequest = (function() {

            /**
             * Properties of a ListGroupAvailabilityRequest.
             * @memberof streem.api
             * @interface IListGroupAvailabilityRequest
             * @property {string|null} [companyCodeOrSid] ListGroupAvailabilityRequest companyCodeOrSid
             * @property {string|null} [groupName] ListGroupAvailabilityRequest groupName
             * @property {streem.api.AvailabilityStatus|null} [status] ListGroupAvailabilityRequest status
             * @property {string|null} [pageToken] ListGroupAvailabilityRequest pageToken
             * @property {string|null} [pageSize] ListGroupAvailabilityRequest pageSize
             */

            /**
             * Constructs a new ListGroupAvailabilityRequest.
             * @memberof streem.api
             * @classdesc Represents a ListGroupAvailabilityRequest.
             * @implements IListGroupAvailabilityRequest
             * @constructor
             * @param {streem.api.IListGroupAvailabilityRequest=} [p] Properties to set
             */
            function ListGroupAvailabilityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupAvailabilityRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             */
            ListGroupAvailabilityRequest.prototype.companyCodeOrSid = "";

            /**
             * ListGroupAvailabilityRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             */
            ListGroupAvailabilityRequest.prototype.groupName = "";

            /**
             * ListGroupAvailabilityRequest status.
             * @member {streem.api.AvailabilityStatus} status
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             */
            ListGroupAvailabilityRequest.prototype.status = 0;

            /**
             * ListGroupAvailabilityRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             */
            ListGroupAvailabilityRequest.prototype.pageToken = "";

            /**
             * ListGroupAvailabilityRequest pageSize.
             * @member {string} pageSize
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             */
            ListGroupAvailabilityRequest.prototype.pageSize = "";

            /**
             * Creates a new ListGroupAvailabilityRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {streem.api.IListGroupAvailabilityRequest=} [properties] Properties to set
             * @returns {streem.api.ListGroupAvailabilityRequest} ListGroupAvailabilityRequest instance
             */
            ListGroupAvailabilityRequest.create = function create(properties) {
                return new ListGroupAvailabilityRequest(properties);
            };

            /**
             * Encodes the specified ListGroupAvailabilityRequest message. Does not implicitly {@link streem.api.ListGroupAvailabilityRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {streem.api.IListGroupAvailabilityRequest} m ListGroupAvailabilityRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupAvailabilityRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(24).int32(m.status);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(34).string(m.pageToken);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(42).string(m.pageSize);
                return w;
            };

            /**
             * Decodes a ListGroupAvailabilityRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupAvailabilityRequest} ListGroupAvailabilityRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupAvailabilityRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupAvailabilityRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.status = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 5: {
                            m.pageSize = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupAvailabilityRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupAvailabilityRequest} ListGroupAvailabilityRequest
             */
            ListGroupAvailabilityRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupAvailabilityRequest)
                    return d;
                var m = new $root.streem.api.ListGroupAvailabilityRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "AVAILABILITY_STATUS_INVALID":
                case 0:
                    m.status = 0;
                    break;
                case "AVAILABILITY_STATUS_AVAILABLE":
                case 1:
                    m.status = 1;
                    break;
                case "AVAILABILITY_STATUS_NOT_AVAILABLE":
                case 2:
                    m.status = 2;
                    break;
                case "AVAILABILITY_STATUS_RESERVED":
                case 3:
                    m.status = 3;
                    break;
                case "AVAILABILITY_STATUS_IN_CALL":
                case 4:
                    m.status = 4;
                    break;
                case "AVAILABILITY_STATUS_CONFIRMING":
                case 5:
                    m.status = 5;
                    break;
                case "AVAILABILITY_STATUS_OFFLINE":
                case 6:
                    m.status = 6;
                    break;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.pageSize != null) {
                    m.pageSize = String(d.pageSize);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupAvailabilityRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {streem.api.ListGroupAvailabilityRequest} m ListGroupAvailabilityRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupAvailabilityRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.status = o.enums === String ? "AVAILABILITY_STATUS_INVALID" : 0;
                    d.pageToken = "";
                    d.pageSize = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.AvailabilityStatus[m.status] === undefined ? m.status : $root.streem.api.AvailabilityStatus[m.status] : m.status;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                return d;
            };

            /**
             * Converts this ListGroupAvailabilityRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupAvailabilityRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupAvailabilityRequest
             * @function getTypeUrl
             * @memberof streem.api.ListGroupAvailabilityRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupAvailabilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupAvailabilityRequest";
            };

            return ListGroupAvailabilityRequest;
        })();

        api.ListGroupAvailabilityResponse = (function() {

            /**
             * Properties of a ListGroupAvailabilityResponse.
             * @memberof streem.api
             * @interface IListGroupAvailabilityResponse
             * @property {Array.<streem.api.IUserAvailability>|null} [userAvailabilities] ListGroupAvailabilityResponse userAvailabilities
             * @property {string|null} [nextPageToken] ListGroupAvailabilityResponse nextPageToken
             * @property {number|null} [totalSize] ListGroupAvailabilityResponse totalSize
             */

            /**
             * Constructs a new ListGroupAvailabilityResponse.
             * @memberof streem.api
             * @classdesc Represents a ListGroupAvailabilityResponse.
             * @implements IListGroupAvailabilityResponse
             * @constructor
             * @param {streem.api.IListGroupAvailabilityResponse=} [p] Properties to set
             */
            function ListGroupAvailabilityResponse(p) {
                this.userAvailabilities = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGroupAvailabilityResponse userAvailabilities.
             * @member {Array.<streem.api.IUserAvailability>} userAvailabilities
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @instance
             */
            ListGroupAvailabilityResponse.prototype.userAvailabilities = $util.emptyArray;

            /**
             * ListGroupAvailabilityResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @instance
             */
            ListGroupAvailabilityResponse.prototype.nextPageToken = "";

            /**
             * ListGroupAvailabilityResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @instance
             */
            ListGroupAvailabilityResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListGroupAvailabilityResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {streem.api.IListGroupAvailabilityResponse=} [properties] Properties to set
             * @returns {streem.api.ListGroupAvailabilityResponse} ListGroupAvailabilityResponse instance
             */
            ListGroupAvailabilityResponse.create = function create(properties) {
                return new ListGroupAvailabilityResponse(properties);
            };

            /**
             * Encodes the specified ListGroupAvailabilityResponse message. Does not implicitly {@link streem.api.ListGroupAvailabilityResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {streem.api.IListGroupAvailabilityResponse} m ListGroupAvailabilityResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGroupAvailabilityResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userAvailabilities != null && m.userAvailabilities.length) {
                    for (var i = 0; i < m.userAvailabilities.length; ++i)
                        $root.streem.api.UserAvailability.encode(m.userAvailabilities[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListGroupAvailabilityResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGroupAvailabilityResponse} ListGroupAvailabilityResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGroupAvailabilityResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGroupAvailabilityResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.userAvailabilities && m.userAvailabilities.length))
                                m.userAvailabilities = [];
                            m.userAvailabilities.push($root.streem.api.UserAvailability.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGroupAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGroupAvailabilityResponse} ListGroupAvailabilityResponse
             */
            ListGroupAvailabilityResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGroupAvailabilityResponse)
                    return d;
                var m = new $root.streem.api.ListGroupAvailabilityResponse();
                if (d.userAvailabilities) {
                    if (!Array.isArray(d.userAvailabilities))
                        throw TypeError(".streem.api.ListGroupAvailabilityResponse.userAvailabilities: array expected");
                    m.userAvailabilities = [];
                    for (var i = 0; i < d.userAvailabilities.length; ++i) {
                        if (typeof d.userAvailabilities[i] !== "object")
                            throw TypeError(".streem.api.ListGroupAvailabilityResponse.userAvailabilities: object expected");
                        m.userAvailabilities[i] = $root.streem.api.UserAvailability.fromObject(d.userAvailabilities[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGroupAvailabilityResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {streem.api.ListGroupAvailabilityResponse} m ListGroupAvailabilityResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGroupAvailabilityResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.userAvailabilities = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.userAvailabilities && m.userAvailabilities.length) {
                    d.userAvailabilities = [];
                    for (var j = 0; j < m.userAvailabilities.length; ++j) {
                        d.userAvailabilities[j] = $root.streem.api.UserAvailability.toObject(m.userAvailabilities[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListGroupAvailabilityResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGroupAvailabilityResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGroupAvailabilityResponse
             * @function getTypeUrl
             * @memberof streem.api.ListGroupAvailabilityResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGroupAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGroupAvailabilityResponse";
            };

            return ListGroupAvailabilityResponse;
        })();

        api.GetGroupReservationWaitTimeRequest = (function() {

            /**
             * Properties of a GetGroupReservationWaitTimeRequest.
             * @memberof streem.api
             * @interface IGetGroupReservationWaitTimeRequest
             * @property {string|null} [companyCodeOrSid] GetGroupReservationWaitTimeRequest companyCodeOrSid
             * @property {string|null} [groupName] GetGroupReservationWaitTimeRequest groupName
             * @property {number|null} [priority] GetGroupReservationWaitTimeRequest priority
             */

            /**
             * Constructs a new GetGroupReservationWaitTimeRequest.
             * @memberof streem.api
             * @classdesc Represents a GetGroupReservationWaitTimeRequest.
             * @implements IGetGroupReservationWaitTimeRequest
             * @constructor
             * @param {streem.api.IGetGroupReservationWaitTimeRequest=} [p] Properties to set
             */
            function GetGroupReservationWaitTimeRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupReservationWaitTimeRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @instance
             */
            GetGroupReservationWaitTimeRequest.prototype.companyCodeOrSid = "";

            /**
             * GetGroupReservationWaitTimeRequest groupName.
             * @member {string} groupName
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @instance
             */
            GetGroupReservationWaitTimeRequest.prototype.groupName = "";

            /**
             * GetGroupReservationWaitTimeRequest priority.
             * @member {number} priority
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @instance
             */
            GetGroupReservationWaitTimeRequest.prototype.priority = 0;

            /**
             * Creates a new GetGroupReservationWaitTimeRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {streem.api.IGetGroupReservationWaitTimeRequest=} [properties] Properties to set
             * @returns {streem.api.GetGroupReservationWaitTimeRequest} GetGroupReservationWaitTimeRequest instance
             */
            GetGroupReservationWaitTimeRequest.create = function create(properties) {
                return new GetGroupReservationWaitTimeRequest(properties);
            };

            /**
             * Encodes the specified GetGroupReservationWaitTimeRequest message. Does not implicitly {@link streem.api.GetGroupReservationWaitTimeRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {streem.api.IGetGroupReservationWaitTimeRequest} m GetGroupReservationWaitTimeRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupReservationWaitTimeRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                    w.uint32(18).string(m.groupName);
                if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                    w.uint32(24).int32(m.priority);
                return w;
            };

            /**
             * Decodes a GetGroupReservationWaitTimeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupReservationWaitTimeRequest} GetGroupReservationWaitTimeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupReservationWaitTimeRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupReservationWaitTimeRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.groupName = r.string();
                            break;
                        }
                    case 3: {
                            m.priority = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupReservationWaitTimeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupReservationWaitTimeRequest} GetGroupReservationWaitTimeRequest
             */
            GetGroupReservationWaitTimeRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupReservationWaitTimeRequest)
                    return d;
                var m = new $root.streem.api.GetGroupReservationWaitTimeRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.groupName != null) {
                    m.groupName = String(d.groupName);
                }
                if (d.priority != null) {
                    m.priority = d.priority | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupReservationWaitTimeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {streem.api.GetGroupReservationWaitTimeRequest} m GetGroupReservationWaitTimeRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupReservationWaitTimeRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.groupName = "";
                    d.priority = 0;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.groupName != null && m.hasOwnProperty("groupName")) {
                    d.groupName = m.groupName;
                }
                if (m.priority != null && m.hasOwnProperty("priority")) {
                    d.priority = m.priority;
                }
                return d;
            };

            /**
             * Converts this GetGroupReservationWaitTimeRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupReservationWaitTimeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupReservationWaitTimeRequest
             * @function getTypeUrl
             * @memberof streem.api.GetGroupReservationWaitTimeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupReservationWaitTimeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupReservationWaitTimeRequest";
            };

            return GetGroupReservationWaitTimeRequest;
        })();

        api.GetGroupReservationWaitTimeResponse = (function() {

            /**
             * Properties of a GetGroupReservationWaitTimeResponse.
             * @memberof streem.api
             * @interface IGetGroupReservationWaitTimeResponse
             * @property {google.protobuf.ITimestamp|null} [estimatedWaitTime] GetGroupReservationWaitTimeResponse estimatedWaitTime
             */

            /**
             * Constructs a new GetGroupReservationWaitTimeResponse.
             * @memberof streem.api
             * @classdesc Represents a GetGroupReservationWaitTimeResponse.
             * @implements IGetGroupReservationWaitTimeResponse
             * @constructor
             * @param {streem.api.IGetGroupReservationWaitTimeResponse=} [p] Properties to set
             */
            function GetGroupReservationWaitTimeResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGroupReservationWaitTimeResponse estimatedWaitTime.
             * @member {google.protobuf.ITimestamp|null|undefined} estimatedWaitTime
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @instance
             */
            GetGroupReservationWaitTimeResponse.prototype.estimatedWaitTime = null;

            /**
             * Creates a new GetGroupReservationWaitTimeResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {streem.api.IGetGroupReservationWaitTimeResponse=} [properties] Properties to set
             * @returns {streem.api.GetGroupReservationWaitTimeResponse} GetGroupReservationWaitTimeResponse instance
             */
            GetGroupReservationWaitTimeResponse.create = function create(properties) {
                return new GetGroupReservationWaitTimeResponse(properties);
            };

            /**
             * Encodes the specified GetGroupReservationWaitTimeResponse message. Does not implicitly {@link streem.api.GetGroupReservationWaitTimeResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {streem.api.IGetGroupReservationWaitTimeResponse} m GetGroupReservationWaitTimeResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupReservationWaitTimeResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.estimatedWaitTime != null && Object.hasOwnProperty.call(m, "estimatedWaitTime"))
                    $root.google.protobuf.Timestamp.encode(m.estimatedWaitTime, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetGroupReservationWaitTimeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGroupReservationWaitTimeResponse} GetGroupReservationWaitTimeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupReservationWaitTimeResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGroupReservationWaitTimeResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.estimatedWaitTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGroupReservationWaitTimeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGroupReservationWaitTimeResponse} GetGroupReservationWaitTimeResponse
             */
            GetGroupReservationWaitTimeResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGroupReservationWaitTimeResponse)
                    return d;
                var m = new $root.streem.api.GetGroupReservationWaitTimeResponse();
                if (d.estimatedWaitTime != null) {
                    if (typeof d.estimatedWaitTime !== "object")
                        throw TypeError(".streem.api.GetGroupReservationWaitTimeResponse.estimatedWaitTime: object expected");
                    m.estimatedWaitTime = $root.google.protobuf.Timestamp.fromObject(d.estimatedWaitTime);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGroupReservationWaitTimeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {streem.api.GetGroupReservationWaitTimeResponse} m GetGroupReservationWaitTimeResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupReservationWaitTimeResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.estimatedWaitTime = null;
                }
                if (m.estimatedWaitTime != null && m.hasOwnProperty("estimatedWaitTime")) {
                    d.estimatedWaitTime = $root.google.protobuf.Timestamp.toObject(m.estimatedWaitTime, o);
                }
                return d;
            };

            /**
             * Converts this GetGroupReservationWaitTimeResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupReservationWaitTimeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGroupReservationWaitTimeResponse
             * @function getTypeUrl
             * @memberof streem.api.GetGroupReservationWaitTimeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGroupReservationWaitTimeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGroupReservationWaitTimeResponse";
            };

            return GetGroupReservationWaitTimeResponse;
        })();

        api.ApiCredential = (function() {

            /**
             * Properties of an ApiCredential.
             * @memberof streem.api
             * @interface IApiCredential
             * @property {string|null} [sid] ApiCredential sid
             * @property {streem.api.ApiCredential.Scope|null} [scope] ApiCredential scope
             * @property {string|null} [scopeSid] ApiCredential scopeSid
             * @property {google.protobuf.ITimestamp|null} [expiresAt] ApiCredential expiresAt
             * @property {string|null} [label] ApiCredential label
             * @property {string|null} [encodedPrivateKey] ApiCredential encodedPrivateKey
             * @property {string|null} [encodedPublicKey] ApiCredential encodedPublicKey
             */

            /**
             * Constructs a new ApiCredential.
             * @memberof streem.api
             * @classdesc Represents an ApiCredential.
             * @implements IApiCredential
             * @constructor
             * @param {streem.api.IApiCredential=} [p] Properties to set
             */
            function ApiCredential(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ApiCredential sid.
             * @member {string} sid
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.sid = "";

            /**
             * ApiCredential scope.
             * @member {streem.api.ApiCredential.Scope} scope
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.scope = 0;

            /**
             * ApiCredential scopeSid.
             * @member {string} scopeSid
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.scopeSid = "";

            /**
             * ApiCredential expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.expiresAt = null;

            /**
             * ApiCredential label.
             * @member {string} label
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.label = "";

            /**
             * ApiCredential encodedPrivateKey.
             * @member {string} encodedPrivateKey
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.encodedPrivateKey = "";

            /**
             * ApiCredential encodedPublicKey.
             * @member {string} encodedPublicKey
             * @memberof streem.api.ApiCredential
             * @instance
             */
            ApiCredential.prototype.encodedPublicKey = "";

            /**
             * Creates a new ApiCredential instance using the specified properties.
             * @function create
             * @memberof streem.api.ApiCredential
             * @static
             * @param {streem.api.IApiCredential=} [properties] Properties to set
             * @returns {streem.api.ApiCredential} ApiCredential instance
             */
            ApiCredential.create = function create(properties) {
                return new ApiCredential(properties);
            };

            /**
             * Encodes the specified ApiCredential message. Does not implicitly {@link streem.api.ApiCredential.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ApiCredential
             * @static
             * @param {streem.api.IApiCredential} m ApiCredential message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApiCredential.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                    w.uint32(16).int32(m.scope);
                if (m.scopeSid != null && Object.hasOwnProperty.call(m, "scopeSid"))
                    w.uint32(26).string(m.scopeSid);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(34).fork()).ldelim();
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(50).string(m.label);
                if (m.encodedPrivateKey != null && Object.hasOwnProperty.call(m, "encodedPrivateKey"))
                    w.uint32(58).string(m.encodedPrivateKey);
                if (m.encodedPublicKey != null && Object.hasOwnProperty.call(m, "encodedPublicKey"))
                    w.uint32(66).string(m.encodedPublicKey);
                return w;
            };

            /**
             * Decodes an ApiCredential message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ApiCredential
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ApiCredential} ApiCredential
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApiCredential.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ApiCredential();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.scope = r.int32();
                            break;
                        }
                    case 3: {
                            m.scopeSid = r.string();
                            break;
                        }
                    case 4: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.label = r.string();
                            break;
                        }
                    case 7: {
                            m.encodedPrivateKey = r.string();
                            break;
                        }
                    case 8: {
                            m.encodedPublicKey = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ApiCredential message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ApiCredential
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ApiCredential} ApiCredential
             */
            ApiCredential.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ApiCredential)
                    return d;
                var m = new $root.streem.api.ApiCredential();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                switch (d.scope) {
                default:
                    if (typeof d.scope === "number") {
                        m.scope = d.scope;
                        break;
                    }
                    break;
                case "SCOPE_INVALID":
                case 0:
                    m.scope = 0;
                    break;
                case "SCOPE_ROOM":
                case 1:
                    m.scope = 1;
                    break;
                case "SCOPE_COMPANY":
                case 2:
                    m.scope = 2;
                    break;
                }
                if (d.scopeSid != null) {
                    m.scopeSid = String(d.scopeSid);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.ApiCredential.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                if (d.encodedPrivateKey != null) {
                    m.encodedPrivateKey = String(d.encodedPrivateKey);
                }
                if (d.encodedPublicKey != null) {
                    m.encodedPublicKey = String(d.encodedPublicKey);
                }
                return m;
            };

            /**
             * Creates a plain object from an ApiCredential message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ApiCredential
             * @static
             * @param {streem.api.ApiCredential} m ApiCredential
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApiCredential.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.scope = o.enums === String ? "SCOPE_INVALID" : 0;
                    d.scopeSid = "";
                    d.expiresAt = null;
                    d.label = "";
                    d.encodedPrivateKey = "";
                    d.encodedPublicKey = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.scope != null && m.hasOwnProperty("scope")) {
                    d.scope = o.enums === String ? $root.streem.api.ApiCredential.Scope[m.scope] === undefined ? m.scope : $root.streem.api.ApiCredential.Scope[m.scope] : m.scope;
                }
                if (m.scopeSid != null && m.hasOwnProperty("scopeSid")) {
                    d.scopeSid = m.scopeSid;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                if (m.encodedPrivateKey != null && m.hasOwnProperty("encodedPrivateKey")) {
                    d.encodedPrivateKey = m.encodedPrivateKey;
                }
                if (m.encodedPublicKey != null && m.hasOwnProperty("encodedPublicKey")) {
                    d.encodedPublicKey = m.encodedPublicKey;
                }
                return d;
            };

            /**
             * Converts this ApiCredential to JSON.
             * @function toJSON
             * @memberof streem.api.ApiCredential
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApiCredential.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ApiCredential
             * @function getTypeUrl
             * @memberof streem.api.ApiCredential
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ApiCredential.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ApiCredential";
            };

            /**
             * Scope enum.
             * @name streem.api.ApiCredential.Scope
             * @enum {number}
             * @property {number} SCOPE_INVALID=0 SCOPE_INVALID value
             * @property {number} SCOPE_ROOM=1 SCOPE_ROOM value
             * @property {number} SCOPE_COMPANY=2 SCOPE_COMPANY value
             */
            ApiCredential.Scope = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SCOPE_INVALID"] = 0;
                values[valuesById[1] = "SCOPE_ROOM"] = 1;
                values[valuesById[2] = "SCOPE_COMPANY"] = 2;
                return values;
            })();

            return ApiCredential;
        })();

        api.CompanyApiKey = (function() {

            /**
             * Properties of a CompanyApiKey.
             * @memberof streem.api
             * @interface ICompanyApiKey
             * @property {string|null} [apiKeyId] CompanyApiKey apiKeyId
             * @property {string|null} [apiKeySecret] CompanyApiKey apiKeySecret
             * @property {google.protobuf.ITimestamp|null} [expiresAt] CompanyApiKey expiresAt
             * @property {string|null} [companySid] CompanyApiKey companySid
             * @property {string|null} [label] CompanyApiKey label
             */

            /**
             * Constructs a new CompanyApiKey.
             * @memberof streem.api
             * @classdesc Represents a CompanyApiKey.
             * @implements ICompanyApiKey
             * @constructor
             * @param {streem.api.ICompanyApiKey=} [p] Properties to set
             */
            function CompanyApiKey(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CompanyApiKey apiKeyId.
             * @member {string} apiKeyId
             * @memberof streem.api.CompanyApiKey
             * @instance
             */
            CompanyApiKey.prototype.apiKeyId = "";

            /**
             * CompanyApiKey apiKeySecret.
             * @member {string} apiKeySecret
             * @memberof streem.api.CompanyApiKey
             * @instance
             */
            CompanyApiKey.prototype.apiKeySecret = "";

            /**
             * CompanyApiKey expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.CompanyApiKey
             * @instance
             */
            CompanyApiKey.prototype.expiresAt = null;

            /**
             * CompanyApiKey companySid.
             * @member {string} companySid
             * @memberof streem.api.CompanyApiKey
             * @instance
             */
            CompanyApiKey.prototype.companySid = "";

            /**
             * CompanyApiKey label.
             * @member {string} label
             * @memberof streem.api.CompanyApiKey
             * @instance
             */
            CompanyApiKey.prototype.label = "";

            /**
             * Creates a new CompanyApiKey instance using the specified properties.
             * @function create
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {streem.api.ICompanyApiKey=} [properties] Properties to set
             * @returns {streem.api.CompanyApiKey} CompanyApiKey instance
             */
            CompanyApiKey.create = function create(properties) {
                return new CompanyApiKey(properties);
            };

            /**
             * Encodes the specified CompanyApiKey message. Does not implicitly {@link streem.api.CompanyApiKey.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {streem.api.ICompanyApiKey} m CompanyApiKey message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyApiKey.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiKeyId != null && Object.hasOwnProperty.call(m, "apiKeyId"))
                    w.uint32(10).string(m.apiKeyId);
                if (m.apiKeySecret != null && Object.hasOwnProperty.call(m, "apiKeySecret"))
                    w.uint32(18).string(m.apiKeySecret);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(26).fork()).ldelim();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(34).string(m.companySid);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(42).string(m.label);
                return w;
            };

            /**
             * Decodes a CompanyApiKey message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CompanyApiKey} CompanyApiKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyApiKey.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CompanyApiKey();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiKeyId = r.string();
                            break;
                        }
                    case 2: {
                            m.apiKeySecret = r.string();
                            break;
                        }
                    case 3: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.companySid = r.string();
                            break;
                        }
                    case 5: {
                            m.label = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CompanyApiKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CompanyApiKey} CompanyApiKey
             */
            CompanyApiKey.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CompanyApiKey)
                    return d;
                var m = new $root.streem.api.CompanyApiKey();
                if (d.apiKeyId != null) {
                    m.apiKeyId = String(d.apiKeyId);
                }
                if (d.apiKeySecret != null) {
                    m.apiKeySecret = String(d.apiKeySecret);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.CompanyApiKey.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from a CompanyApiKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {streem.api.CompanyApiKey} m CompanyApiKey
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyApiKey.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiKeyId = "";
                    d.apiKeySecret = "";
                    d.expiresAt = null;
                    d.companySid = "";
                    d.label = "";
                }
                if (m.apiKeyId != null && m.hasOwnProperty("apiKeyId")) {
                    d.apiKeyId = m.apiKeyId;
                }
                if (m.apiKeySecret != null && m.hasOwnProperty("apiKeySecret")) {
                    d.apiKeySecret = m.apiKeySecret;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                return d;
            };

            /**
             * Converts this CompanyApiKey to JSON.
             * @function toJSON
             * @memberof streem.api.CompanyApiKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyApiKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CompanyApiKey
             * @function getTypeUrl
             * @memberof streem.api.CompanyApiKey
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CompanyApiKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CompanyApiKey";
            };

            return CompanyApiKey;
        })();

        api.RoomShareToken = (function() {

            /**
             * Properties of a RoomShareToken.
             * @memberof streem.api
             * @interface IRoomShareToken
             * @property {string|null} [sid] RoomShareToken sid
             * @property {google.protobuf.ITimestamp|null} [expiresAt] RoomShareToken expiresAt
             * @property {string|null} [roomSid] RoomShareToken roomSid
             * @property {string|null} [label] RoomShareToken label
             * @property {number|null} [usageCount] RoomShareToken usageCount
             * @property {string|null} [shareUrl] RoomShareToken shareUrl
             */

            /**
             * Constructs a new RoomShareToken.
             * @memberof streem.api
             * @classdesc Represents a RoomShareToken.
             * @implements IRoomShareToken
             * @constructor
             * @param {streem.api.IRoomShareToken=} [p] Properties to set
             */
            function RoomShareToken(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RoomShareToken sid.
             * @member {string} sid
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.sid = "";

            /**
             * RoomShareToken expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.expiresAt = null;

            /**
             * RoomShareToken roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.roomSid = "";

            /**
             * RoomShareToken label.
             * @member {string} label
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.label = "";

            /**
             * RoomShareToken usageCount.
             * @member {number} usageCount
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.usageCount = 0;

            /**
             * RoomShareToken shareUrl.
             * @member {string} shareUrl
             * @memberof streem.api.RoomShareToken
             * @instance
             */
            RoomShareToken.prototype.shareUrl = "";

            /**
             * Creates a new RoomShareToken instance using the specified properties.
             * @function create
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {streem.api.IRoomShareToken=} [properties] Properties to set
             * @returns {streem.api.RoomShareToken} RoomShareToken instance
             */
            RoomShareToken.create = function create(properties) {
                return new RoomShareToken(properties);
            };

            /**
             * Encodes the specified RoomShareToken message. Does not implicitly {@link streem.api.RoomShareToken.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {streem.api.IRoomShareToken} m RoomShareToken message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomShareToken.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(34).fork()).ldelim();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(42).string(m.roomSid);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(50).string(m.label);
                if (m.usageCount != null && Object.hasOwnProperty.call(m, "usageCount"))
                    w.uint32(56).int32(m.usageCount);
                if (m.shareUrl != null && Object.hasOwnProperty.call(m, "shareUrl"))
                    w.uint32(66).string(m.shareUrl);
                return w;
            };

            /**
             * Decodes a RoomShareToken message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RoomShareToken} RoomShareToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomShareToken.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RoomShareToken();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 4: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 6: {
                            m.label = r.string();
                            break;
                        }
                    case 7: {
                            m.usageCount = r.int32();
                            break;
                        }
                    case 8: {
                            m.shareUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RoomShareToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RoomShareToken} RoomShareToken
             */
            RoomShareToken.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RoomShareToken)
                    return d;
                var m = new $root.streem.api.RoomShareToken();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.RoomShareToken.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                if (d.usageCount != null) {
                    m.usageCount = d.usageCount | 0;
                }
                if (d.shareUrl != null) {
                    m.shareUrl = String(d.shareUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a RoomShareToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {streem.api.RoomShareToken} m RoomShareToken
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomShareToken.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.expiresAt = null;
                    d.roomSid = "";
                    d.label = "";
                    d.usageCount = 0;
                    d.shareUrl = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                if (m.usageCount != null && m.hasOwnProperty("usageCount")) {
                    d.usageCount = m.usageCount;
                }
                if (m.shareUrl != null && m.hasOwnProperty("shareUrl")) {
                    d.shareUrl = m.shareUrl;
                }
                return d;
            };

            /**
             * Converts this RoomShareToken to JSON.
             * @function toJSON
             * @memberof streem.api.RoomShareToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomShareToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoomShareToken
             * @function getTypeUrl
             * @memberof streem.api.RoomShareToken
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomShareToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RoomShareToken";
            };

            /**
             * View enum.
             * @name streem.api.RoomShareToken.View
             * @enum {number}
             * @property {number} VIEW_INVALID=0 VIEW_INVALID value
             * @property {number} VIEW_BASIC=1 VIEW_BASIC value
             * @property {number} VIEW_FULL=2 VIEW_FULL value
             */
            RoomShareToken.View = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "VIEW_INVALID"] = 0;
                values[valuesById[1] = "VIEW_BASIC"] = 1;
                values[valuesById[2] = "VIEW_FULL"] = 2;
                return values;
            })();

            return RoomShareToken;
        })();

        api.Licenses = (function() {

            /**
             * Constructs a new Licenses service.
             * @memberof streem.api
             * @classdesc Represents a Licenses
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Licenses(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Licenses.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Licenses;

            /**
             * Creates new Licenses service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Licenses
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Licenses} RPC service. Useful where requests and/or responses are streamed.
             */
            Licenses.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Licenses#listLicenses}.
             * @memberof streem.api.Licenses
             * @typedef ListLicensesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListLicensesResponse} [response] ListLicensesResponse
             */

            /**
             * Calls ListLicenses.
             * @function listLicenses
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.IListLicensesRequest} request ListLicensesRequest message or plain object
             * @param {streem.api.Licenses.ListLicensesCallback} callback Node-style callback called with the error, if any, and ListLicensesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Licenses.prototype.listLicenses = function listLicenses(request, callback) {
                return this.rpcCall(listLicenses, $root.streem.api.ListLicensesRequest, $root.streem.api.ListLicensesResponse, request, callback);
            }, "name", { value: "ListLicenses" });

            /**
             * Calls ListLicenses.
             * @function listLicenses
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.IListLicensesRequest} request ListLicensesRequest message or plain object
             * @returns {Promise<streem.api.ListLicensesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Licenses#getCompanyLicense}.
             * @memberof streem.api.Licenses
             * @typedef GetCompanyLicenseCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyLicenseResponse} [response] GetCompanyLicenseResponse
             */

            /**
             * Calls GetCompanyLicense.
             * @function getCompanyLicense
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.IGetCompanyLicenseRequest} request GetCompanyLicenseRequest message or plain object
             * @param {streem.api.Licenses.GetCompanyLicenseCallback} callback Node-style callback called with the error, if any, and GetCompanyLicenseResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Licenses.prototype.getCompanyLicense = function getCompanyLicense(request, callback) {
                return this.rpcCall(getCompanyLicense, $root.streem.api.GetCompanyLicenseRequest, $root.streem.api.GetCompanyLicenseResponse, request, callback);
            }, "name", { value: "GetCompanyLicense" });

            /**
             * Calls GetCompanyLicense.
             * @function getCompanyLicense
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.IGetCompanyLicenseRequest} request GetCompanyLicenseRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyLicenseResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Licenses#saveCompanyLicense}.
             * @memberof streem.api.Licenses
             * @typedef SaveCompanyLicenseCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveCompanyLicenseResponse} [response] SaveCompanyLicenseResponse
             */

            /**
             * Calls SaveCompanyLicense.
             * @function saveCompanyLicense
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.ISaveCompanyLicenseRequest} request SaveCompanyLicenseRequest message or plain object
             * @param {streem.api.Licenses.SaveCompanyLicenseCallback} callback Node-style callback called with the error, if any, and SaveCompanyLicenseResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Licenses.prototype.saveCompanyLicense = function saveCompanyLicense(request, callback) {
                return this.rpcCall(saveCompanyLicense, $root.streem.api.SaveCompanyLicenseRequest, $root.streem.api.SaveCompanyLicenseResponse, request, callback);
            }, "name", { value: "SaveCompanyLicense" });

            /**
             * Calls SaveCompanyLicense.
             * @function saveCompanyLicense
             * @memberof streem.api.Licenses
             * @instance
             * @param {streem.api.ISaveCompanyLicenseRequest} request SaveCompanyLicenseRequest message or plain object
             * @returns {Promise<streem.api.SaveCompanyLicenseResponse>} Promise
             * @variation 2
             */

            return Licenses;
        })();

        api.ListLicensesRequest = (function() {

            /**
             * Properties of a ListLicensesRequest.
             * @memberof streem.api
             * @interface IListLicensesRequest
             */

            /**
             * Constructs a new ListLicensesRequest.
             * @memberof streem.api
             * @classdesc Represents a ListLicensesRequest.
             * @implements IListLicensesRequest
             * @constructor
             * @param {streem.api.IListLicensesRequest=} [p] Properties to set
             */
            function ListLicensesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new ListLicensesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {streem.api.IListLicensesRequest=} [properties] Properties to set
             * @returns {streem.api.ListLicensesRequest} ListLicensesRequest instance
             */
            ListLicensesRequest.create = function create(properties) {
                return new ListLicensesRequest(properties);
            };

            /**
             * Encodes the specified ListLicensesRequest message. Does not implicitly {@link streem.api.ListLicensesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {streem.api.IListLicensesRequest} m ListLicensesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListLicensesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a ListLicensesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListLicensesRequest} ListLicensesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListLicensesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListLicensesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListLicensesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListLicensesRequest} ListLicensesRequest
             */
            ListLicensesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListLicensesRequest)
                    return d;
                return new $root.streem.api.ListLicensesRequest();
            };

            /**
             * Creates a plain object from a ListLicensesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {streem.api.ListLicensesRequest} m ListLicensesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListLicensesRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ListLicensesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListLicensesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListLicensesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListLicensesRequest
             * @function getTypeUrl
             * @memberof streem.api.ListLicensesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListLicensesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListLicensesRequest";
            };

            return ListLicensesRequest;
        })();

        api.ListLicensesResponse = (function() {

            /**
             * Properties of a ListLicensesResponse.
             * @memberof streem.api
             * @interface IListLicensesResponse
             * @property {Array.<streem.api.ILicense>|null} [licenses] ListLicensesResponse licenses
             */

            /**
             * Constructs a new ListLicensesResponse.
             * @memberof streem.api
             * @classdesc Represents a ListLicensesResponse.
             * @implements IListLicensesResponse
             * @constructor
             * @param {streem.api.IListLicensesResponse=} [p] Properties to set
             */
            function ListLicensesResponse(p) {
                this.licenses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListLicensesResponse licenses.
             * @member {Array.<streem.api.ILicense>} licenses
             * @memberof streem.api.ListLicensesResponse
             * @instance
             */
            ListLicensesResponse.prototype.licenses = $util.emptyArray;

            /**
             * Creates a new ListLicensesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {streem.api.IListLicensesResponse=} [properties] Properties to set
             * @returns {streem.api.ListLicensesResponse} ListLicensesResponse instance
             */
            ListLicensesResponse.create = function create(properties) {
                return new ListLicensesResponse(properties);
            };

            /**
             * Encodes the specified ListLicensesResponse message. Does not implicitly {@link streem.api.ListLicensesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {streem.api.IListLicensesResponse} m ListLicensesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListLicensesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.licenses != null && m.licenses.length) {
                    for (var i = 0; i < m.licenses.length; ++i)
                        $root.streem.api.License.encode(m.licenses[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListLicensesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListLicensesResponse} ListLicensesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListLicensesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListLicensesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.licenses && m.licenses.length))
                                m.licenses = [];
                            m.licenses.push($root.streem.api.License.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListLicensesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListLicensesResponse} ListLicensesResponse
             */
            ListLicensesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListLicensesResponse)
                    return d;
                var m = new $root.streem.api.ListLicensesResponse();
                if (d.licenses) {
                    if (!Array.isArray(d.licenses))
                        throw TypeError(".streem.api.ListLicensesResponse.licenses: array expected");
                    m.licenses = [];
                    for (var i = 0; i < d.licenses.length; ++i) {
                        if (typeof d.licenses[i] !== "object")
                            throw TypeError(".streem.api.ListLicensesResponse.licenses: object expected");
                        m.licenses[i] = $root.streem.api.License.fromObject(d.licenses[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListLicensesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {streem.api.ListLicensesResponse} m ListLicensesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListLicensesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.licenses = [];
                }
                if (m.licenses && m.licenses.length) {
                    d.licenses = [];
                    for (var j = 0; j < m.licenses.length; ++j) {
                        d.licenses[j] = $root.streem.api.License.toObject(m.licenses[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListLicensesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListLicensesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListLicensesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListLicensesResponse
             * @function getTypeUrl
             * @memberof streem.api.ListLicensesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListLicensesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListLicensesResponse";
            };

            return ListLicensesResponse;
        })();

        api.GetCompanyLicenseRequest = (function() {

            /**
             * Properties of a GetCompanyLicenseRequest.
             * @memberof streem.api
             * @interface IGetCompanyLicenseRequest
             * @property {string|null} [companySid] GetCompanyLicenseRequest companySid
             */

            /**
             * Constructs a new GetCompanyLicenseRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyLicenseRequest.
             * @implements IGetCompanyLicenseRequest
             * @constructor
             * @param {streem.api.IGetCompanyLicenseRequest=} [p] Properties to set
             */
            function GetCompanyLicenseRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyLicenseRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.GetCompanyLicenseRequest
             * @instance
             */
            GetCompanyLicenseRequest.prototype.companySid = "";

            /**
             * Creates a new GetCompanyLicenseRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {streem.api.IGetCompanyLicenseRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyLicenseRequest} GetCompanyLicenseRequest instance
             */
            GetCompanyLicenseRequest.create = function create(properties) {
                return new GetCompanyLicenseRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyLicenseRequest message. Does not implicitly {@link streem.api.GetCompanyLicenseRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {streem.api.IGetCompanyLicenseRequest} m GetCompanyLicenseRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyLicenseRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                return w;
            };

            /**
             * Decodes a GetCompanyLicenseRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyLicenseRequest} GetCompanyLicenseRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyLicenseRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyLicenseRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyLicenseRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyLicenseRequest} GetCompanyLicenseRequest
             */
            GetCompanyLicenseRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyLicenseRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyLicenseRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyLicenseRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {streem.api.GetCompanyLicenseRequest} m GetCompanyLicenseRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyLicenseRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companySid = "";
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                return d;
            };

            /**
             * Converts this GetCompanyLicenseRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyLicenseRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyLicenseRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyLicenseRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyLicenseRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyLicenseRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyLicenseRequest";
            };

            return GetCompanyLicenseRequest;
        })();

        api.GetCompanyLicenseResponse = (function() {

            /**
             * Properties of a GetCompanyLicenseResponse.
             * @memberof streem.api
             * @interface IGetCompanyLicenseResponse
             * @property {streem.api.ILicense|null} [license] GetCompanyLicenseResponse license
             */

            /**
             * Constructs a new GetCompanyLicenseResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyLicenseResponse.
             * @implements IGetCompanyLicenseResponse
             * @constructor
             * @param {streem.api.IGetCompanyLicenseResponse=} [p] Properties to set
             */
            function GetCompanyLicenseResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyLicenseResponse license.
             * @member {streem.api.ILicense|null|undefined} license
             * @memberof streem.api.GetCompanyLicenseResponse
             * @instance
             */
            GetCompanyLicenseResponse.prototype.license = null;

            /**
             * Creates a new GetCompanyLicenseResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {streem.api.IGetCompanyLicenseResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyLicenseResponse} GetCompanyLicenseResponse instance
             */
            GetCompanyLicenseResponse.create = function create(properties) {
                return new GetCompanyLicenseResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyLicenseResponse message. Does not implicitly {@link streem.api.GetCompanyLicenseResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {streem.api.IGetCompanyLicenseResponse} m GetCompanyLicenseResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyLicenseResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.license != null && Object.hasOwnProperty.call(m, "license"))
                    $root.streem.api.License.encode(m.license, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanyLicenseResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyLicenseResponse} GetCompanyLicenseResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyLicenseResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyLicenseResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.license = $root.streem.api.License.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyLicenseResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyLicenseResponse} GetCompanyLicenseResponse
             */
            GetCompanyLicenseResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyLicenseResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyLicenseResponse();
                if (d.license != null) {
                    if (typeof d.license !== "object")
                        throw TypeError(".streem.api.GetCompanyLicenseResponse.license: object expected");
                    m.license = $root.streem.api.License.fromObject(d.license);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyLicenseResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {streem.api.GetCompanyLicenseResponse} m GetCompanyLicenseResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyLicenseResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.license = null;
                }
                if (m.license != null && m.hasOwnProperty("license")) {
                    d.license = $root.streem.api.License.toObject(m.license, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanyLicenseResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyLicenseResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyLicenseResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyLicenseResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyLicenseResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyLicenseResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyLicenseResponse";
            };

            return GetCompanyLicenseResponse;
        })();

        api.SaveCompanyLicenseRequest = (function() {

            /**
             * Properties of a SaveCompanyLicenseRequest.
             * @memberof streem.api
             * @interface ISaveCompanyLicenseRequest
             * @property {string|null} [companySid] SaveCompanyLicenseRequest companySid
             * @property {string|null} [licenseName] SaveCompanyLicenseRequest licenseName
             */

            /**
             * Constructs a new SaveCompanyLicenseRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanyLicenseRequest.
             * @implements ISaveCompanyLicenseRequest
             * @constructor
             * @param {streem.api.ISaveCompanyLicenseRequest=} [p] Properties to set
             */
            function SaveCompanyLicenseRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanyLicenseRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @instance
             */
            SaveCompanyLicenseRequest.prototype.companySid = "";

            /**
             * SaveCompanyLicenseRequest licenseName.
             * @member {string} licenseName
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @instance
             */
            SaveCompanyLicenseRequest.prototype.licenseName = "";

            /**
             * Creates a new SaveCompanyLicenseRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {streem.api.ISaveCompanyLicenseRequest=} [properties] Properties to set
             * @returns {streem.api.SaveCompanyLicenseRequest} SaveCompanyLicenseRequest instance
             */
            SaveCompanyLicenseRequest.create = function create(properties) {
                return new SaveCompanyLicenseRequest(properties);
            };

            /**
             * Encodes the specified SaveCompanyLicenseRequest message. Does not implicitly {@link streem.api.SaveCompanyLicenseRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {streem.api.ISaveCompanyLicenseRequest} m SaveCompanyLicenseRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanyLicenseRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                if (m.licenseName != null && Object.hasOwnProperty.call(m, "licenseName"))
                    w.uint32(18).string(m.licenseName);
                return w;
            };

            /**
             * Decodes a SaveCompanyLicenseRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanyLicenseRequest} SaveCompanyLicenseRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanyLicenseRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanyLicenseRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    case 2: {
                            m.licenseName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanyLicenseRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanyLicenseRequest} SaveCompanyLicenseRequest
             */
            SaveCompanyLicenseRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanyLicenseRequest)
                    return d;
                var m = new $root.streem.api.SaveCompanyLicenseRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.licenseName != null) {
                    m.licenseName = String(d.licenseName);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanyLicenseRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {streem.api.SaveCompanyLicenseRequest} m SaveCompanyLicenseRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanyLicenseRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companySid = "";
                    d.licenseName = "";
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.licenseName != null && m.hasOwnProperty("licenseName")) {
                    d.licenseName = m.licenseName;
                }
                return d;
            };

            /**
             * Converts this SaveCompanyLicenseRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanyLicenseRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanyLicenseRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanyLicenseRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanyLicenseRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanyLicenseRequest";
            };

            return SaveCompanyLicenseRequest;
        })();

        api.SaveCompanyLicenseResponse = (function() {

            /**
             * Properties of a SaveCompanyLicenseResponse.
             * @memberof streem.api
             * @interface ISaveCompanyLicenseResponse
             * @property {streem.api.ILicense|null} [license] SaveCompanyLicenseResponse license
             */

            /**
             * Constructs a new SaveCompanyLicenseResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanyLicenseResponse.
             * @implements ISaveCompanyLicenseResponse
             * @constructor
             * @param {streem.api.ISaveCompanyLicenseResponse=} [p] Properties to set
             */
            function SaveCompanyLicenseResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanyLicenseResponse license.
             * @member {streem.api.ILicense|null|undefined} license
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @instance
             */
            SaveCompanyLicenseResponse.prototype.license = null;

            /**
             * Creates a new SaveCompanyLicenseResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {streem.api.ISaveCompanyLicenseResponse=} [properties] Properties to set
             * @returns {streem.api.SaveCompanyLicenseResponse} SaveCompanyLicenseResponse instance
             */
            SaveCompanyLicenseResponse.create = function create(properties) {
                return new SaveCompanyLicenseResponse(properties);
            };

            /**
             * Encodes the specified SaveCompanyLicenseResponse message. Does not implicitly {@link streem.api.SaveCompanyLicenseResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {streem.api.ISaveCompanyLicenseResponse} m SaveCompanyLicenseResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanyLicenseResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.license != null && Object.hasOwnProperty.call(m, "license"))
                    $root.streem.api.License.encode(m.license, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveCompanyLicenseResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanyLicenseResponse} SaveCompanyLicenseResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanyLicenseResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanyLicenseResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.license = $root.streem.api.License.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanyLicenseResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanyLicenseResponse} SaveCompanyLicenseResponse
             */
            SaveCompanyLicenseResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanyLicenseResponse)
                    return d;
                var m = new $root.streem.api.SaveCompanyLicenseResponse();
                if (d.license != null) {
                    if (typeof d.license !== "object")
                        throw TypeError(".streem.api.SaveCompanyLicenseResponse.license: object expected");
                    m.license = $root.streem.api.License.fromObject(d.license);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanyLicenseResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {streem.api.SaveCompanyLicenseResponse} m SaveCompanyLicenseResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanyLicenseResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.license = null;
                }
                if (m.license != null && m.hasOwnProperty("license")) {
                    d.license = $root.streem.api.License.toObject(m.license, o);
                }
                return d;
            };

            /**
             * Converts this SaveCompanyLicenseResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanyLicenseResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanyLicenseResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanyLicenseResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanyLicenseResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanyLicenseResponse";
            };

            return SaveCompanyLicenseResponse;
        })();

        api.RoomArtifacts = (function() {

            /**
             * Constructs a new RoomArtifacts service.
             * @memberof streem.api
             * @classdesc Represents a RoomArtifacts
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function RoomArtifacts(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (RoomArtifacts.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RoomArtifacts;

            /**
             * Creates new RoomArtifacts service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.RoomArtifacts
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {RoomArtifacts} RPC service. Useful where requests and/or responses are streamed.
             */
            RoomArtifacts.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#acceptExternalAudio}.
             * @memberof streem.api.RoomArtifacts
             * @typedef AcceptExternalAudioCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.AcceptExternalAudioResponse} [response] AcceptExternalAudioResponse
             */

            /**
             * Calls AcceptExternalAudio.
             * @function acceptExternalAudio
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IAcceptExternalAudioRequest} request AcceptExternalAudioRequest message or plain object
             * @param {streem.api.RoomArtifacts.AcceptExternalAudioCallback} callback Node-style callback called with the error, if any, and AcceptExternalAudioResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.acceptExternalAudio = function acceptExternalAudio(request, callback) {
                return this.rpcCall(acceptExternalAudio, $root.streem.api.AcceptExternalAudioRequest, $root.streem.api.AcceptExternalAudioResponse, request, callback);
            }, "name", { value: "AcceptExternalAudio" });

            /**
             * Calls AcceptExternalAudio.
             * @function acceptExternalAudio
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IAcceptExternalAudioRequest} request AcceptExternalAudioRequest message or plain object
             * @returns {Promise<streem.api.AcceptExternalAudioResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#batchGetLastGpsPosition}.
             * @memberof streem.api.RoomArtifacts
             * @typedef BatchGetLastGpsPositionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.BatchGetLastGpsPositionResponse} [response] BatchGetLastGpsPositionResponse
             */

            /**
             * Calls BatchGetLastGpsPosition.
             * @function batchGetLastGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetLastGpsPositionRequest} request BatchGetLastGpsPositionRequest message or plain object
             * @param {streem.api.RoomArtifacts.BatchGetLastGpsPositionCallback} callback Node-style callback called with the error, if any, and BatchGetLastGpsPositionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.batchGetLastGpsPosition = function batchGetLastGpsPosition(request, callback) {
                return this.rpcCall(batchGetLastGpsPosition, $root.streem.api.BatchGetLastGpsPositionRequest, $root.streem.api.BatchGetLastGpsPositionResponse, request, callback);
            }, "name", { value: "BatchGetLastGpsPosition" });

            /**
             * Calls BatchGetLastGpsPosition.
             * @function batchGetLastGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetLastGpsPositionRequest} request BatchGetLastGpsPositionRequest message or plain object
             * @returns {Promise<streem.api.BatchGetLastGpsPositionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#batchGetRoomArtifactCounts}.
             * @memberof streem.api.RoomArtifacts
             * @typedef BatchGetRoomArtifactCountsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.BatchGetRoomArtifactCountsResponse} [response] BatchGetRoomArtifactCountsResponse
             */

            /**
             * Calls BatchGetRoomArtifactCounts.
             * @function batchGetRoomArtifactCounts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetRoomArtifactCountsRequest} request BatchGetRoomArtifactCountsRequest message or plain object
             * @param {streem.api.RoomArtifacts.BatchGetRoomArtifactCountsCallback} callback Node-style callback called with the error, if any, and BatchGetRoomArtifactCountsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.batchGetRoomArtifactCounts = function batchGetRoomArtifactCounts(request, callback) {
                return this.rpcCall(batchGetRoomArtifactCounts, $root.streem.api.BatchGetRoomArtifactCountsRequest, $root.streem.api.BatchGetRoomArtifactCountsResponse, request, callback);
            }, "name", { value: "BatchGetRoomArtifactCounts" });

            /**
             * Calls BatchGetRoomArtifactCounts.
             * @function batchGetRoomArtifactCounts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetRoomArtifactCountsRequest} request BatchGetRoomArtifactCountsRequest message or plain object
             * @returns {Promise<streem.api.BatchGetRoomArtifactCountsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#batchGetRoomNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef BatchGetRoomNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.BatchGetRoomNoteResponse} [response] BatchGetRoomNoteResponse
             */

            /**
             * Calls BatchGetRoomNote.
             * @function batchGetRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetRoomNoteRequest} request BatchGetRoomNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.BatchGetRoomNoteCallback} callback Node-style callback called with the error, if any, and BatchGetRoomNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.batchGetRoomNote = function batchGetRoomNote(request, callback) {
                return this.rpcCall(batchGetRoomNote, $root.streem.api.BatchGetRoomNoteRequest, $root.streem.api.BatchGetRoomNoteResponse, request, callback);
            }, "name", { value: "BatchGetRoomNote" });

            /**
             * Calls BatchGetRoomNote.
             * @function batchGetRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IBatchGetRoomNoteRequest} request BatchGetRoomNoteRequest message or plain object
             * @returns {Promise<streem.api.BatchGetRoomNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createLayoutEstimation}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateLayoutEstimationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateLayoutEstimationResponse} [response] CreateLayoutEstimationResponse
             */

            /**
             * Calls CreateLayoutEstimation.
             * @function createLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateLayoutEstimationRequest} request CreateLayoutEstimationRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateLayoutEstimationCallback} callback Node-style callback called with the error, if any, and CreateLayoutEstimationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createLayoutEstimation = function createLayoutEstimation(request, callback) {
                return this.rpcCall(createLayoutEstimation, $root.streem.api.CreateLayoutEstimationRequest, $root.streem.api.CreateLayoutEstimationResponse, request, callback);
            }, "name", { value: "CreateLayoutEstimation" });

            /**
             * Calls CreateLayoutEstimation.
             * @function createLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateLayoutEstimationRequest} request CreateLayoutEstimationRequest message or plain object
             * @returns {Promise<streem.api.CreateLayoutEstimationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateMeshResponse} [response] CreateMeshResponse
             */

            /**
             * Calls CreateMesh.
             * @function createMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateMeshRequest} request CreateMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateMeshCallback} callback Node-style callback called with the error, if any, and CreateMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createMesh = function createMesh(request, callback) {
                return this.rpcCall(createMesh, $root.streem.api.CreateMeshRequest, $root.streem.api.CreateMeshResponse, request, callback);
            }, "name", { value: "CreateMesh" });

            /**
             * Calls CreateMesh.
             * @function createMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateMeshRequest} request CreateMeshRequest message or plain object
             * @returns {Promise<streem.api.CreateMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRecordingResponse} [response] CreateRecordingResponse
             */

            /**
             * Calls CreateRecording.
             * @function createRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateRecordingRequest} request CreateRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateRecordingCallback} callback Node-style callback called with the error, if any, and CreateRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createRecording = function createRecording(request, callback) {
                return this.rpcCall(createRecording, $root.streem.api.CreateRecordingRequest, $root.streem.api.CreateRecordingResponse, request, callback);
            }, "name", { value: "CreateRecording" });

            /**
             * Calls CreateRecording.
             * @function createRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateRecordingRequest} request CreateRecordingRequest message or plain object
             * @returns {Promise<streem.api.CreateRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createRoomOutcomeReport}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateRoomOutcomeReportCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateRoomOutcomeReportResponse} [response] CreateRoomOutcomeReportResponse
             */

            /**
             * Calls CreateRoomOutcomeReport.
             * @function createRoomOutcomeReport
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateRoomOutcomeReportRequest} request CreateRoomOutcomeReportRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateRoomOutcomeReportCallback} callback Node-style callback called with the error, if any, and CreateRoomOutcomeReportResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createRoomOutcomeReport = function createRoomOutcomeReport(request, callback) {
                return this.rpcCall(createRoomOutcomeReport, $root.streem.api.CreateRoomOutcomeReportRequest, $root.streem.api.CreateRoomOutcomeReportResponse, request, callback);
            }, "name", { value: "CreateRoomOutcomeReport" });

            /**
             * Calls CreateRoomOutcomeReport.
             * @function createRoomOutcomeReport
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateRoomOutcomeReportRequest} request CreateRoomOutcomeReportRequest message or plain object
             * @returns {Promise<streem.api.CreateRoomOutcomeReportResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createStreemshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateStreemshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateStreemshotResponse} [response] CreateStreemshotResponse
             */

            /**
             * Calls CreateStreemshot.
             * @function createStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateStreemshotRequest} request CreateStreemshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateStreemshotCallback} callback Node-style callback called with the error, if any, and CreateStreemshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createStreemshot = function createStreemshot(request, callback) {
                return this.rpcCall(createStreemshot, $root.streem.api.CreateStreemshotRequest, $root.streem.api.CreateStreemshotResponse, request, callback);
            }, "name", { value: "CreateStreemshot" });

            /**
             * Calls CreateStreemshot.
             * @function createStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateStreemshotRequest} request CreateStreemshotRequest message or plain object
             * @returns {Promise<streem.api.CreateStreemshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createStreemshotRevision}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateStreemshotRevisionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateStreemshotRevisionResponse} [response] CreateStreemshotRevisionResponse
             */

            /**
             * Calls CreateStreemshotRevision.
             * @function createStreemshotRevision
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateStreemshotRevisionRequest} request CreateStreemshotRevisionRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateStreemshotRevisionCallback} callback Node-style callback called with the error, if any, and CreateStreemshotRevisionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createStreemshotRevision = function createStreemshotRevision(request, callback) {
                return this.rpcCall(createStreemshotRevision, $root.streem.api.CreateStreemshotRevisionRequest, $root.streem.api.CreateStreemshotRevisionResponse, request, callback);
            }, "name", { value: "CreateStreemshotRevision" });

            /**
             * Calls CreateStreemshotRevision.
             * @function createStreemshotRevision
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateStreemshotRevisionRequest} request CreateStreemshotRevisionRequest message or plain object
             * @returns {Promise<streem.api.CreateStreemshotRevisionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#createTexturedMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef CreateTexturedMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateTexturedMeshResponse} [response] CreateTexturedMeshResponse
             */

            /**
             * Calls CreateTexturedMesh.
             * @function createTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateTexturedMeshRequest} request CreateTexturedMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.CreateTexturedMeshCallback} callback Node-style callback called with the error, if any, and CreateTexturedMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.createTexturedMesh = function createTexturedMesh(request, callback) {
                return this.rpcCall(createTexturedMesh, $root.streem.api.CreateTexturedMeshRequest, $root.streem.api.CreateTexturedMeshResponse, request, callback);
            }, "name", { value: "CreateTexturedMesh" });

            /**
             * Calls CreateTexturedMesh.
             * @function createTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ICreateTexturedMeshRequest} request CreateTexturedMeshRequest message or plain object
             * @returns {Promise<streem.api.CreateTexturedMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#startRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef StartRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.StartRecordingResponse} [response] StartRecordingResponse
             */

            /**
             * Calls StartRecording.
             * @function startRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IStartRecordingRequest} request StartRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.StartRecordingCallback} callback Node-style callback called with the error, if any, and StartRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.startRecording = function startRecording(request, callback) {
                return this.rpcCall(startRecording, $root.streem.api.StartRecordingRequest, $root.streem.api.StartRecordingResponse, request, callback);
            }, "name", { value: "StartRecording" });

            /**
             * Calls StartRecording.
             * @function startRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IStartRecordingRequest} request StartRecordingRequest message or plain object
             * @returns {Promise<streem.api.StartRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#pauseRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef PauseRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.PauseRecordingResponse} [response] PauseRecordingResponse
             */

            /**
             * Calls PauseRecording.
             * @function pauseRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IPauseRecordingRequest} request PauseRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.PauseRecordingCallback} callback Node-style callback called with the error, if any, and PauseRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.pauseRecording = function pauseRecording(request, callback) {
                return this.rpcCall(pauseRecording, $root.streem.api.PauseRecordingRequest, $root.streem.api.PauseRecordingResponse, request, callback);
            }, "name", { value: "PauseRecording" });

            /**
             * Calls PauseRecording.
             * @function pauseRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IPauseRecordingRequest} request PauseRecordingRequest message or plain object
             * @returns {Promise<streem.api.PauseRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteBookmark}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteBookmarkCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteBookmarkResponse} [response] DeleteBookmarkResponse
             */

            /**
             * Calls DeleteBookmark.
             * @function deleteBookmark
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteBookmarkRequest} request DeleteBookmarkRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteBookmarkCallback} callback Node-style callback called with the error, if any, and DeleteBookmarkResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteBookmark = function deleteBookmark(request, callback) {
                return this.rpcCall(deleteBookmark, $root.streem.api.DeleteBookmarkRequest, $root.streem.api.DeleteBookmarkResponse, request, callback);
            }, "name", { value: "DeleteBookmark" });

            /**
             * Calls DeleteBookmark.
             * @function deleteBookmark
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteBookmarkRequest} request DeleteBookmarkRequest message or plain object
             * @returns {Promise<streem.api.DeleteBookmarkResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteExternalAudio}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteExternalAudioCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteExternalAudioResponse} [response] DeleteExternalAudioResponse
             */

            /**
             * Calls DeleteExternalAudio.
             * @function deleteExternalAudio
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteExternalAudioRequest} request DeleteExternalAudioRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteExternalAudioCallback} callback Node-style callback called with the error, if any, and DeleteExternalAudioResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteExternalAudio = function deleteExternalAudio(request, callback) {
                return this.rpcCall(deleteExternalAudio, $root.streem.api.DeleteExternalAudioRequest, $root.streem.api.DeleteExternalAudioResponse, request, callback);
            }, "name", { value: "DeleteExternalAudio" });

            /**
             * Calls DeleteExternalAudio.
             * @function deleteExternalAudio
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteExternalAudioRequest} request DeleteExternalAudioRequest message or plain object
             * @returns {Promise<streem.api.DeleteExternalAudioResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteGpsPosition}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteGpsPositionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteGpsPositionResponse} [response] DeleteGpsPositionResponse
             */

            /**
             * Calls DeleteGpsPosition.
             * @function deleteGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteGpsPositionRequest} request DeleteGpsPositionRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteGpsPositionCallback} callback Node-style callback called with the error, if any, and DeleteGpsPositionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteGpsPosition = function deleteGpsPosition(request, callback) {
                return this.rpcCall(deleteGpsPosition, $root.streem.api.DeleteGpsPositionRequest, $root.streem.api.DeleteGpsPositionResponse, request, callback);
            }, "name", { value: "DeleteGpsPosition" });

            /**
             * Calls DeleteGpsPosition.
             * @function deleteGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteGpsPositionRequest} request DeleteGpsPositionRequest message or plain object
             * @returns {Promise<streem.api.DeleteGpsPositionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteLayoutEstimation}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteLayoutEstimationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteLayoutEstimationResponse} [response] DeleteLayoutEstimationResponse
             */

            /**
             * Calls DeleteLayoutEstimation.
             * @function deleteLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteLayoutEstimationRequest} request DeleteLayoutEstimationRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteLayoutEstimationCallback} callback Node-style callback called with the error, if any, and DeleteLayoutEstimationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteLayoutEstimation = function deleteLayoutEstimation(request, callback) {
                return this.rpcCall(deleteLayoutEstimation, $root.streem.api.DeleteLayoutEstimationRequest, $root.streem.api.DeleteLayoutEstimationResponse, request, callback);
            }, "name", { value: "DeleteLayoutEstimation" });

            /**
             * Calls DeleteLayoutEstimation.
             * @function deleteLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteLayoutEstimationRequest} request DeleteLayoutEstimationRequest message or plain object
             * @returns {Promise<streem.api.DeleteLayoutEstimationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteMeshResponse} [response] DeleteMeshResponse
             */

            /**
             * Calls DeleteMesh.
             * @function deleteMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteMeshRequest} request DeleteMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteMeshCallback} callback Node-style callback called with the error, if any, and DeleteMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteMesh = function deleteMesh(request, callback) {
                return this.rpcCall(deleteMesh, $root.streem.api.DeleteMeshRequest, $root.streem.api.DeleteMeshResponse, request, callback);
            }, "name", { value: "DeleteMesh" });

            /**
             * Calls DeleteMesh.
             * @function deleteMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteMeshRequest} request DeleteMeshRequest message or plain object
             * @returns {Promise<streem.api.DeleteMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteRecordingResponse} [response] DeleteRecordingResponse
             */

            /**
             * Calls DeleteRecording.
             * @function deleteRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRecordingRequest} request DeleteRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteRecordingCallback} callback Node-style callback called with the error, if any, and DeleteRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteRecording = function deleteRecording(request, callback) {
                return this.rpcCall(deleteRecording, $root.streem.api.DeleteRecordingRequest, $root.streem.api.DeleteRecordingResponse, request, callback);
            }, "name", { value: "DeleteRecording" });

            /**
             * Calls DeleteRecording.
             * @function deleteRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRecordingRequest} request DeleteRecordingRequest message or plain object
             * @returns {Promise<streem.api.DeleteRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteRoomOutcomeReports}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteRoomOutcomeReportsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteRoomOutcomeReportsResponse} [response] DeleteRoomOutcomeReportsResponse
             */

            /**
             * Calls DeleteRoomOutcomeReports.
             * @function deleteRoomOutcomeReports
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRoomOutcomeReportsRequest} request DeleteRoomOutcomeReportsRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteRoomOutcomeReportsCallback} callback Node-style callback called with the error, if any, and DeleteRoomOutcomeReportsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteRoomOutcomeReports = function deleteRoomOutcomeReports(request, callback) {
                return this.rpcCall(deleteRoomOutcomeReports, $root.streem.api.DeleteRoomOutcomeReportsRequest, $root.streem.api.DeleteRoomOutcomeReportsResponse, request, callback);
            }, "name", { value: "DeleteRoomOutcomeReports" });

            /**
             * Calls DeleteRoomOutcomeReports.
             * @function deleteRoomOutcomeReports
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRoomOutcomeReportsRequest} request DeleteRoomOutcomeReportsRequest message or plain object
             * @returns {Promise<streem.api.DeleteRoomOutcomeReportsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteRoomNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteRoomNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteRoomNoteResponse} [response] DeleteRoomNoteResponse
             */

            /**
             * Calls DeleteRoomNote.
             * @function deleteRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRoomNoteRequest} request DeleteRoomNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteRoomNoteCallback} callback Node-style callback called with the error, if any, and DeleteRoomNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteRoomNote = function deleteRoomNote(request, callback) {
                return this.rpcCall(deleteRoomNote, $root.streem.api.DeleteRoomNoteRequest, $root.streem.api.DeleteRoomNoteResponse, request, callback);
            }, "name", { value: "DeleteRoomNote" });

            /**
             * Calls DeleteRoomNote.
             * @function deleteRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteRoomNoteRequest} request DeleteRoomNoteRequest message or plain object
             * @returns {Promise<streem.api.DeleteRoomNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteSceneshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteSceneshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteSceneshotResponse} [response] DeleteSceneshotResponse
             */

            /**
             * Calls DeleteSceneshot.
             * @function deleteSceneshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteSceneshotRequest} request DeleteSceneshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteSceneshotCallback} callback Node-style callback called with the error, if any, and DeleteSceneshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteSceneshot = function deleteSceneshot(request, callback) {
                return this.rpcCall(deleteSceneshot, $root.streem.api.DeleteSceneshotRequest, $root.streem.api.DeleteSceneshotResponse, request, callback);
            }, "name", { value: "DeleteSceneshot" });

            /**
             * Calls DeleteSceneshot.
             * @function deleteSceneshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteSceneshotRequest} request DeleteSceneshotRequest message or plain object
             * @returns {Promise<streem.api.DeleteSceneshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteStreemshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteStreemshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteStreemshotResponse} [response] DeleteStreemshotResponse
             */

            /**
             * Calls DeleteStreemshot.
             * @function deleteStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteStreemshotRequest} request DeleteStreemshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteStreemshotCallback} callback Node-style callback called with the error, if any, and DeleteStreemshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteStreemshot = function deleteStreemshot(request, callback) {
                return this.rpcCall(deleteStreemshot, $root.streem.api.DeleteStreemshotRequest, $root.streem.api.DeleteStreemshotResponse, request, callback);
            }, "name", { value: "DeleteStreemshot" });

            /**
             * Calls DeleteStreemshot.
             * @function deleteStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteStreemshotRequest} request DeleteStreemshotRequest message or plain object
             * @returns {Promise<streem.api.DeleteStreemshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteStreemshotNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteStreemshotNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteStreemshotNoteResponse} [response] DeleteStreemshotNoteResponse
             */

            /**
             * Calls DeleteStreemshotNote.
             * @function deleteStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteStreemshotNoteRequest} request DeleteStreemshotNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteStreemshotNoteCallback} callback Node-style callback called with the error, if any, and DeleteStreemshotNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteStreemshotNote = function deleteStreemshotNote(request, callback) {
                return this.rpcCall(deleteStreemshotNote, $root.streem.api.DeleteStreemshotNoteRequest, $root.streem.api.DeleteStreemshotNoteResponse, request, callback);
            }, "name", { value: "DeleteStreemshotNote" });

            /**
             * Calls DeleteStreemshotNote.
             * @function deleteStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteStreemshotNoteRequest} request DeleteStreemshotNoteRequest message or plain object
             * @returns {Promise<streem.api.DeleteStreemshotNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#deleteTexturedMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef DeleteTexturedMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteTexturedMeshResponse} [response] DeleteTexturedMeshResponse
             */

            /**
             * Calls DeleteTexturedMesh.
             * @function deleteTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteTexturedMeshRequest} request DeleteTexturedMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.DeleteTexturedMeshCallback} callback Node-style callback called with the error, if any, and DeleteTexturedMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.deleteTexturedMesh = function deleteTexturedMesh(request, callback) {
                return this.rpcCall(deleteTexturedMesh, $root.streem.api.DeleteTexturedMeshRequest, $root.streem.api.DeleteTexturedMeshResponse, request, callback);
            }, "name", { value: "DeleteTexturedMesh" });

            /**
             * Calls DeleteTexturedMesh.
             * @function deleteTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IDeleteTexturedMeshRequest} request DeleteTexturedMeshRequest message or plain object
             * @returns {Promise<streem.api.DeleteTexturedMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#failMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FailMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FailMeshResponse} [response] FailMeshResponse
             */

            /**
             * Calls FailMesh.
             * @function failMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailMeshRequest} request FailMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.FailMeshCallback} callback Node-style callback called with the error, if any, and FailMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.failMesh = function failMesh(request, callback) {
                return this.rpcCall(failMesh, $root.streem.api.FailMeshRequest, $root.streem.api.FailMeshResponse, request, callback);
            }, "name", { value: "FailMesh" });

            /**
             * Calls FailMesh.
             * @function failMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailMeshRequest} request FailMeshRequest message or plain object
             * @returns {Promise<streem.api.FailMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#failRecordingMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FailRecordingMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FailRecordingMuxResponse} [response] FailRecordingMuxResponse
             */

            /**
             * Calls FailRecordingMux.
             * @function failRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailRecordingMuxRequest} request FailRecordingMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.FailRecordingMuxCallback} callback Node-style callback called with the error, if any, and FailRecordingMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.failRecordingMux = function failRecordingMux(request, callback) {
                return this.rpcCall(failRecordingMux, $root.streem.api.FailRecordingMuxRequest, $root.streem.api.FailRecordingMuxResponse, request, callback);
            }, "name", { value: "FailRecordingMux" });

            /**
             * Calls FailRecordingMux.
             * @function failRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailRecordingMuxRequest} request FailRecordingMuxRequest message or plain object
             * @returns {Promise<streem.api.FailRecordingMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#failRecordingTrackMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FailRecordingTrackMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FailRecordingTrackMuxResponse} [response] FailRecordingTrackMuxResponse
             */

            /**
             * Calls FailRecordingTrackMux.
             * @function failRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailRecordingTrackMuxRequest} request FailRecordingTrackMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.FailRecordingTrackMuxCallback} callback Node-style callback called with the error, if any, and FailRecordingTrackMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.failRecordingTrackMux = function failRecordingTrackMux(request, callback) {
                return this.rpcCall(failRecordingTrackMux, $root.streem.api.FailRecordingTrackMuxRequest, $root.streem.api.FailRecordingTrackMuxResponse, request, callback);
            }, "name", { value: "FailRecordingTrackMux" });

            /**
             * Calls FailRecordingTrackMux.
             * @function failRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFailRecordingTrackMuxRequest} request FailRecordingTrackMuxRequest message or plain object
             * @returns {Promise<streem.api.FailRecordingTrackMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#fulfillRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FulfillRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FulfillRecordingResponse} [response] FulfillRecordingResponse
             */

            /**
             * Calls FulfillRecording.
             * @function fulfillRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingRequest} request FulfillRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.FulfillRecordingCallback} callback Node-style callback called with the error, if any, and FulfillRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.fulfillRecording = function fulfillRecording(request, callback) {
                return this.rpcCall(fulfillRecording, $root.streem.api.FulfillRecordingRequest, $root.streem.api.FulfillRecordingResponse, request, callback);
            }, "name", { value: "FulfillRecording" });

            /**
             * Calls FulfillRecording.
             * @function fulfillRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingRequest} request FulfillRecordingRequest message or plain object
             * @returns {Promise<streem.api.FulfillRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#fulfillMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FulfillMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FulfillMeshResponse} [response] FulfillMeshResponse
             */

            /**
             * Calls FulfillMesh.
             * @function fulfillMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillMeshRequest} request FulfillMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.FulfillMeshCallback} callback Node-style callback called with the error, if any, and FulfillMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.fulfillMesh = function fulfillMesh(request, callback) {
                return this.rpcCall(fulfillMesh, $root.streem.api.FulfillMeshRequest, $root.streem.api.FulfillMeshResponse, request, callback);
            }, "name", { value: "FulfillMesh" });

            /**
             * Calls FulfillMesh.
             * @function fulfillMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillMeshRequest} request FulfillMeshRequest message or plain object
             * @returns {Promise<streem.api.FulfillMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#fulfillRecordingMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FulfillRecordingMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FulfillRecordingMuxResponse} [response] FulfillRecordingMuxResponse
             */

            /**
             * Calls FulfillRecordingMux.
             * @function fulfillRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingMuxRequest} request FulfillRecordingMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.FulfillRecordingMuxCallback} callback Node-style callback called with the error, if any, and FulfillRecordingMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.fulfillRecordingMux = function fulfillRecordingMux(request, callback) {
                return this.rpcCall(fulfillRecordingMux, $root.streem.api.FulfillRecordingMuxRequest, $root.streem.api.FulfillRecordingMuxResponse, request, callback);
            }, "name", { value: "FulfillRecordingMux" });

            /**
             * Calls FulfillRecordingMux.
             * @function fulfillRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingMuxRequest} request FulfillRecordingMuxRequest message or plain object
             * @returns {Promise<streem.api.FulfillRecordingMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#fulfillRecordingTrackMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FulfillRecordingTrackMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FulfillRecordingTrackMuxResponse} [response] FulfillRecordingTrackMuxResponse
             */

            /**
             * Calls FulfillRecordingTrackMux.
             * @function fulfillRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingTrackMuxRequest} request FulfillRecordingTrackMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.FulfillRecordingTrackMuxCallback} callback Node-style callback called with the error, if any, and FulfillRecordingTrackMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.fulfillRecordingTrackMux = function fulfillRecordingTrackMux(request, callback) {
                return this.rpcCall(fulfillRecordingTrackMux, $root.streem.api.FulfillRecordingTrackMuxRequest, $root.streem.api.FulfillRecordingTrackMuxResponse, request, callback);
            }, "name", { value: "FulfillRecordingTrackMux" });

            /**
             * Calls FulfillRecordingTrackMux.
             * @function fulfillRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillRecordingTrackMuxRequest} request FulfillRecordingTrackMuxRequest message or plain object
             * @returns {Promise<streem.api.FulfillRecordingTrackMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#fulfillStreemshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef FulfillStreemshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.FulfillStreemshotResponse} [response] FulfillStreemshotResponse
             */

            /**
             * Calls FulfillStreemshot.
             * @function fulfillStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillStreemshotRequest} request FulfillStreemshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.FulfillStreemshotCallback} callback Node-style callback called with the error, if any, and FulfillStreemshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.fulfillStreemshot = function fulfillStreemshot(request, callback) {
                return this.rpcCall(fulfillStreemshot, $root.streem.api.FulfillStreemshotRequest, $root.streem.api.FulfillStreemshotResponse, request, callback);
            }, "name", { value: "FulfillStreemshot" });

            /**
             * Calls FulfillStreemshot.
             * @function fulfillStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IFulfillStreemshotRequest} request FulfillStreemshotRequest message or plain object
             * @returns {Promise<streem.api.FulfillStreemshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getBookmark}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetBookmarkCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetBookmarkResponse} [response] GetBookmarkResponse
             */

            /**
             * Calls GetBookmark.
             * @function getBookmark
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetBookmarkRequest} request GetBookmarkRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetBookmarkCallback} callback Node-style callback called with the error, if any, and GetBookmarkResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getBookmark = function getBookmark(request, callback) {
                return this.rpcCall(getBookmark, $root.streem.api.GetBookmarkRequest, $root.streem.api.GetBookmarkResponse, request, callback);
            }, "name", { value: "GetBookmark" });

            /**
             * Calls GetBookmark.
             * @function getBookmark
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetBookmarkRequest} request GetBookmarkRequest message or plain object
             * @returns {Promise<streem.api.GetBookmarkResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getGpsPosition}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetGpsPositionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetGpsPositionResponse} [response] GetGpsPositionResponse
             */

            /**
             * Calls GetGpsPosition.
             * @function getGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetGpsPositionRequest} request GetGpsPositionRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetGpsPositionCallback} callback Node-style callback called with the error, if any, and GetGpsPositionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getGpsPosition = function getGpsPosition(request, callback) {
                return this.rpcCall(getGpsPosition, $root.streem.api.GetGpsPositionRequest, $root.streem.api.GetGpsPositionResponse, request, callback);
            }, "name", { value: "GetGpsPosition" });

            /**
             * Calls GetGpsPosition.
             * @function getGpsPosition
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetGpsPositionRequest} request GetGpsPositionRequest message or plain object
             * @returns {Promise<streem.api.GetGpsPositionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getLayoutEstimation}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetLayoutEstimationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetLayoutEstimationResponse} [response] GetLayoutEstimationResponse
             */

            /**
             * Calls GetLayoutEstimation.
             * @function getLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetLayoutEstimationRequest} request GetLayoutEstimationRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetLayoutEstimationCallback} callback Node-style callback called with the error, if any, and GetLayoutEstimationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getLayoutEstimation = function getLayoutEstimation(request, callback) {
                return this.rpcCall(getLayoutEstimation, $root.streem.api.GetLayoutEstimationRequest, $root.streem.api.GetLayoutEstimationResponse, request, callback);
            }, "name", { value: "GetLayoutEstimation" });

            /**
             * Calls GetLayoutEstimation.
             * @function getLayoutEstimation
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetLayoutEstimationRequest} request GetLayoutEstimationRequest message or plain object
             * @returns {Promise<streem.api.GetLayoutEstimationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetMeshResponse} [response] GetMeshResponse
             */

            /**
             * Calls GetMesh.
             * @function getMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetMeshRequest} request GetMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetMeshCallback} callback Node-style callback called with the error, if any, and GetMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getMesh = function getMesh(request, callback) {
                return this.rpcCall(getMesh, $root.streem.api.GetMeshRequest, $root.streem.api.GetMeshResponse, request, callback);
            }, "name", { value: "GetMesh" });

            /**
             * Calls GetMesh.
             * @function getMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetMeshRequest} request GetMeshRequest message or plain object
             * @returns {Promise<streem.api.GetMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getRecording}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetRecordingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRecordingResponse} [response] GetRecordingResponse
             */

            /**
             * Calls GetRecording.
             * @function getRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRecordingRequest} request GetRecordingRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetRecordingCallback} callback Node-style callback called with the error, if any, and GetRecordingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getRecording = function getRecording(request, callback) {
                return this.rpcCall(getRecording, $root.streem.api.GetRecordingRequest, $root.streem.api.GetRecordingResponse, request, callback);
            }, "name", { value: "GetRecording" });

            /**
             * Calls GetRecording.
             * @function getRecording
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRecordingRequest} request GetRecordingRequest message or plain object
             * @returns {Promise<streem.api.GetRecordingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getRoomArtifactCounts}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetRoomArtifactCountsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomArtifactCountsResponse} [response] GetRoomArtifactCountsResponse
             */

            /**
             * Calls GetRoomArtifactCounts.
             * @function getRoomArtifactCounts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomArtifactCountsRequest} request GetRoomArtifactCountsRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetRoomArtifactCountsCallback} callback Node-style callback called with the error, if any, and GetRoomArtifactCountsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getRoomArtifactCounts = function getRoomArtifactCounts(request, callback) {
                return this.rpcCall(getRoomArtifactCounts, $root.streem.api.GetRoomArtifactCountsRequest, $root.streem.api.GetRoomArtifactCountsResponse, request, callback);
            }, "name", { value: "GetRoomArtifactCounts" });

            /**
             * Calls GetRoomArtifactCounts.
             * @function getRoomArtifactCounts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomArtifactCountsRequest} request GetRoomArtifactCountsRequest message or plain object
             * @returns {Promise<streem.api.GetRoomArtifactCountsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getRoomNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetRoomNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomNoteResponse} [response] GetRoomNoteResponse
             */

            /**
             * Calls GetRoomNote.
             * @function getRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomNoteRequest} request GetRoomNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetRoomNoteCallback} callback Node-style callback called with the error, if any, and GetRoomNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getRoomNote = function getRoomNote(request, callback) {
                return this.rpcCall(getRoomNote, $root.streem.api.GetRoomNoteRequest, $root.streem.api.GetRoomNoteResponse, request, callback);
            }, "name", { value: "GetRoomNote" });

            /**
             * Calls GetRoomNote.
             * @function getRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomNoteRequest} request GetRoomNoteRequest message or plain object
             * @returns {Promise<streem.api.GetRoomNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getRoomOutcomeReport}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetRoomOutcomeReportCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetRoomOutcomeReportResponse} [response] GetRoomOutcomeReportResponse
             */

            /**
             * Calls GetRoomOutcomeReport.
             * @function getRoomOutcomeReport
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomOutcomeReportRequest} request GetRoomOutcomeReportRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetRoomOutcomeReportCallback} callback Node-style callback called with the error, if any, and GetRoomOutcomeReportResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getRoomOutcomeReport = function getRoomOutcomeReport(request, callback) {
                return this.rpcCall(getRoomOutcomeReport, $root.streem.api.GetRoomOutcomeReportRequest, $root.streem.api.GetRoomOutcomeReportResponse, request, callback);
            }, "name", { value: "GetRoomOutcomeReport" });

            /**
             * Calls GetRoomOutcomeReport.
             * @function getRoomOutcomeReport
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetRoomOutcomeReportRequest} request GetRoomOutcomeReportRequest message or plain object
             * @returns {Promise<streem.api.GetRoomOutcomeReportResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getSceneshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetSceneshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetSceneshotResponse} [response] GetSceneshotResponse
             */

            /**
             * Calls GetSceneshot.
             * @function getSceneshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetSceneshotRequest} request GetSceneshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetSceneshotCallback} callback Node-style callback called with the error, if any, and GetSceneshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getSceneshot = function getSceneshot(request, callback) {
                return this.rpcCall(getSceneshot, $root.streem.api.GetSceneshotRequest, $root.streem.api.GetSceneshotResponse, request, callback);
            }, "name", { value: "GetSceneshot" });

            /**
             * Calls GetSceneshot.
             * @function getSceneshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetSceneshotRequest} request GetSceneshotRequest message or plain object
             * @returns {Promise<streem.api.GetSceneshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getStreemshot}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetStreemshotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetStreemshotResponse} [response] GetStreemshotResponse
             */

            /**
             * Calls GetStreemshot.
             * @function getStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetStreemshotRequest} request GetStreemshotRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetStreemshotCallback} callback Node-style callback called with the error, if any, and GetStreemshotResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getStreemshot = function getStreemshot(request, callback) {
                return this.rpcCall(getStreemshot, $root.streem.api.GetStreemshotRequest, $root.streem.api.GetStreemshotResponse, request, callback);
            }, "name", { value: "GetStreemshot" });

            /**
             * Calls GetStreemshot.
             * @function getStreemshot
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetStreemshotRequest} request GetStreemshotRequest message or plain object
             * @returns {Promise<streem.api.GetStreemshotResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getStreemshotNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetStreemshotNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetStreemshotNoteResponse} [response] GetStreemshotNoteResponse
             */

            /**
             * Calls GetStreemshotNote.
             * @function getStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetStreemshotNoteRequest} request GetStreemshotNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetStreemshotNoteCallback} callback Node-style callback called with the error, if any, and GetStreemshotNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getStreemshotNote = function getStreemshotNote(request, callback) {
                return this.rpcCall(getStreemshotNote, $root.streem.api.GetStreemshotNoteRequest, $root.streem.api.GetStreemshotNoteResponse, request, callback);
            }, "name", { value: "GetStreemshotNote" });

            /**
             * Calls GetStreemshotNote.
             * @function getStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetStreemshotNoteRequest} request GetStreemshotNoteRequest message or plain object
             * @returns {Promise<streem.api.GetStreemshotNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#getTexturedMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef GetTexturedMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetTexturedMeshResponse} [response] GetTexturedMeshResponse
             */

            /**
             * Calls GetTexturedMesh.
             * @function getTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetTexturedMeshRequest} request GetTexturedMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.GetTexturedMeshCallback} callback Node-style callback called with the error, if any, and GetTexturedMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.getTexturedMesh = function getTexturedMesh(request, callback) {
                return this.rpcCall(getTexturedMesh, $root.streem.api.GetTexturedMeshRequest, $root.streem.api.GetTexturedMeshResponse, request, callback);
            }, "name", { value: "GetTexturedMesh" });

            /**
             * Calls GetTexturedMesh.
             * @function getTexturedMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IGetTexturedMeshRequest} request GetTexturedMeshRequest message or plain object
             * @returns {Promise<streem.api.GetTexturedMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listGpsPositions}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListGpsPositionsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListGpsPositionsResponse} [response] ListGpsPositionsResponse
             */

            /**
             * Calls ListGpsPositions.
             * @function listGpsPositions
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListGpsPositionsRequest} request ListGpsPositionsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListGpsPositionsCallback} callback Node-style callback called with the error, if any, and ListGpsPositionsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listGpsPositions = function listGpsPositions(request, callback) {
                return this.rpcCall(listGpsPositions, $root.streem.api.ListGpsPositionsRequest, $root.streem.api.ListGpsPositionsResponse, request, callback);
            }, "name", { value: "ListGpsPositions" });

            /**
             * Calls ListGpsPositions.
             * @function listGpsPositions
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListGpsPositionsRequest} request ListGpsPositionsRequest message or plain object
             * @returns {Promise<streem.api.ListGpsPositionsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listMeshes}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListMeshesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListMeshesResponse} [response] ListMeshesResponse
             */

            /**
             * Calls ListMeshes.
             * @function listMeshes
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListMeshesRequest} request ListMeshesRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListMeshesCallback} callback Node-style callback called with the error, if any, and ListMeshesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listMeshes = function listMeshes(request, callback) {
                return this.rpcCall(listMeshes, $root.streem.api.ListMeshesRequest, $root.streem.api.ListMeshesResponse, request, callback);
            }, "name", { value: "ListMeshes" });

            /**
             * Calls ListMeshes.
             * @function listMeshes
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListMeshesRequest} request ListMeshesRequest message or plain object
             * @returns {Promise<streem.api.ListMeshesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listRecordings}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListRecordingsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRecordingsResponse} [response] ListRecordingsResponse
             */

            /**
             * Calls ListRecordings.
             * @function listRecordings
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRecordingsRequest} request ListRecordingsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListRecordingsCallback} callback Node-style callback called with the error, if any, and ListRecordingsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listRecordings = function listRecordings(request, callback) {
                return this.rpcCall(listRecordings, $root.streem.api.ListRecordingsRequest, $root.streem.api.ListRecordingsResponse, request, callback);
            }, "name", { value: "ListRecordings" });

            /**
             * Calls ListRecordings.
             * @function listRecordings
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRecordingsRequest} request ListRecordingsRequest message or plain object
             * @returns {Promise<streem.api.ListRecordingsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listRoomArtifacts}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListRoomArtifactsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRoomArtifactsResponse} [response] ListRoomArtifactsResponse
             */

            /**
             * Calls ListRoomArtifacts.
             * @function listRoomArtifacts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRoomArtifactsRequest} request ListRoomArtifactsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListRoomArtifactsCallback} callback Node-style callback called with the error, if any, and ListRoomArtifactsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listRoomArtifacts = function listRoomArtifacts(request, callback) {
                return this.rpcCall(listRoomArtifacts, $root.streem.api.ListRoomArtifactsRequest, $root.streem.api.ListRoomArtifactsResponse, request, callback);
            }, "name", { value: "ListRoomArtifacts" });

            /**
             * Calls ListRoomArtifacts.
             * @function listRoomArtifacts
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRoomArtifactsRequest} request ListRoomArtifactsRequest message or plain object
             * @returns {Promise<streem.api.ListRoomArtifactsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listRoomOutcomeReports}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListRoomOutcomeReportsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRoomOutcomeReportsResponse} [response] ListRoomOutcomeReportsResponse
             */

            /**
             * Calls ListRoomOutcomeReports.
             * @function listRoomOutcomeReports
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRoomOutcomeReportsRequest} request ListRoomOutcomeReportsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListRoomOutcomeReportsCallback} callback Node-style callback called with the error, if any, and ListRoomOutcomeReportsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listRoomOutcomeReports = function listRoomOutcomeReports(request, callback) {
                return this.rpcCall(listRoomOutcomeReports, $root.streem.api.ListRoomOutcomeReportsRequest, $root.streem.api.ListRoomOutcomeReportsResponse, request, callback);
            }, "name", { value: "ListRoomOutcomeReports" });

            /**
             * Calls ListRoomOutcomeReports.
             * @function listRoomOutcomeReports
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListRoomOutcomeReportsRequest} request ListRoomOutcomeReportsRequest message or plain object
             * @returns {Promise<streem.api.ListRoomOutcomeReportsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listSceneshots}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListSceneshotsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListSceneshotsResponse} [response] ListSceneshotsResponse
             */

            /**
             * Calls ListSceneshots.
             * @function listSceneshots
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListSceneshotsRequest} request ListSceneshotsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListSceneshotsCallback} callback Node-style callback called with the error, if any, and ListSceneshotsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listSceneshots = function listSceneshots(request, callback) {
                return this.rpcCall(listSceneshots, $root.streem.api.ListSceneshotsRequest, $root.streem.api.ListSceneshotsResponse, request, callback);
            }, "name", { value: "ListSceneshots" });

            /**
             * Calls ListSceneshots.
             * @function listSceneshots
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListSceneshotsRequest} request ListSceneshotsRequest message or plain object
             * @returns {Promise<streem.api.ListSceneshotsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#listStreemshots}.
             * @memberof streem.api.RoomArtifacts
             * @typedef ListStreemshotsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListStreemshotsResponse} [response] ListStreemshotsResponse
             */

            /**
             * Calls ListStreemshots.
             * @function listStreemshots
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListStreemshotsRequest} request ListStreemshotsRequest message or plain object
             * @param {streem.api.RoomArtifacts.ListStreemshotsCallback} callback Node-style callback called with the error, if any, and ListStreemshotsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.listStreemshots = function listStreemshots(request, callback) {
                return this.rpcCall(listStreemshots, $root.streem.api.ListStreemshotsRequest, $root.streem.api.ListStreemshotsResponse, request, callback);
            }, "name", { value: "ListStreemshots" });

            /**
             * Calls ListStreemshots.
             * @function listStreemshots
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IListStreemshotsRequest} request ListStreemshotsRequest message or plain object
             * @returns {Promise<streem.api.ListStreemshotsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#startMesh}.
             * @memberof streem.api.RoomArtifacts
             * @typedef StartMeshCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.StartMeshResponse} [response] StartMeshResponse
             */

            /**
             * Calls StartMesh.
             * @function startMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IStartMeshRequest} request StartMeshRequest message or plain object
             * @param {streem.api.RoomArtifacts.StartMeshCallback} callback Node-style callback called with the error, if any, and StartMeshResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.startMesh = function startMesh(request, callback) {
                return this.rpcCall(startMesh, $root.streem.api.StartMeshRequest, $root.streem.api.StartMeshResponse, request, callback);
            }, "name", { value: "StartMesh" });

            /**
             * Calls StartMesh.
             * @function startMesh
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IStartMeshRequest} request StartMeshRequest message or plain object
             * @returns {Promise<streem.api.StartMeshResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#transferRecordingMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef TransferRecordingMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.TransferRecordingMuxResponse} [response] TransferRecordingMuxResponse
             */

            /**
             * Calls TransferRecordingMux.
             * @function transferRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ITransferRecordingMuxRequest} request TransferRecordingMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.TransferRecordingMuxCallback} callback Node-style callback called with the error, if any, and TransferRecordingMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.transferRecordingMux = function transferRecordingMux(request, callback) {
                return this.rpcCall(transferRecordingMux, $root.streem.api.TransferRecordingMuxRequest, $root.streem.api.TransferRecordingMuxResponse, request, callback);
            }, "name", { value: "TransferRecordingMux" });

            /**
             * Calls TransferRecordingMux.
             * @function transferRecordingMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ITransferRecordingMuxRequest} request TransferRecordingMuxRequest message or plain object
             * @returns {Promise<streem.api.TransferRecordingMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#transferRecordingTrackMux}.
             * @memberof streem.api.RoomArtifacts
             * @typedef TransferRecordingTrackMuxCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.TransferRecordingTrackMuxResponse} [response] TransferRecordingTrackMuxResponse
             */

            /**
             * Calls TransferRecordingTrackMux.
             * @function transferRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ITransferRecordingTrackMuxRequest} request TransferRecordingTrackMuxRequest message or plain object
             * @param {streem.api.RoomArtifacts.TransferRecordingTrackMuxCallback} callback Node-style callback called with the error, if any, and TransferRecordingTrackMuxResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.transferRecordingTrackMux = function transferRecordingTrackMux(request, callback) {
                return this.rpcCall(transferRecordingTrackMux, $root.streem.api.TransferRecordingTrackMuxRequest, $root.streem.api.TransferRecordingTrackMuxResponse, request, callback);
            }, "name", { value: "TransferRecordingTrackMux" });

            /**
             * Calls TransferRecordingTrackMux.
             * @function transferRecordingTrackMux
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.ITransferRecordingTrackMuxRequest} request TransferRecordingTrackMuxRequest message or plain object
             * @returns {Promise<streem.api.TransferRecordingTrackMuxResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#updateRoomNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef UpdateRoomNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateRoomNoteResponse} [response] UpdateRoomNoteResponse
             */

            /**
             * Calls UpdateRoomNote.
             * @function updateRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IUpdateRoomNoteRequest} request UpdateRoomNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.UpdateRoomNoteCallback} callback Node-style callback called with the error, if any, and UpdateRoomNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.updateRoomNote = function updateRoomNote(request, callback) {
                return this.rpcCall(updateRoomNote, $root.streem.api.UpdateRoomNoteRequest, $root.streem.api.UpdateRoomNoteResponse, request, callback);
            }, "name", { value: "UpdateRoomNote" });

            /**
             * Calls UpdateRoomNote.
             * @function updateRoomNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IUpdateRoomNoteRequest} request UpdateRoomNoteRequest message or plain object
             * @returns {Promise<streem.api.UpdateRoomNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.RoomArtifacts#updateStreemshotNote}.
             * @memberof streem.api.RoomArtifacts
             * @typedef UpdateStreemshotNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateStreemshotNoteResponse} [response] UpdateStreemshotNoteResponse
             */

            /**
             * Calls UpdateStreemshotNote.
             * @function updateStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IUpdateStreemshotNoteRequest} request UpdateStreemshotNoteRequest message or plain object
             * @param {streem.api.RoomArtifacts.UpdateStreemshotNoteCallback} callback Node-style callback called with the error, if any, and UpdateStreemshotNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(RoomArtifacts.prototype.updateStreemshotNote = function updateStreemshotNote(request, callback) {
                return this.rpcCall(updateStreemshotNote, $root.streem.api.UpdateStreemshotNoteRequest, $root.streem.api.UpdateStreemshotNoteResponse, request, callback);
            }, "name", { value: "UpdateStreemshotNote" });

            /**
             * Calls UpdateStreemshotNote.
             * @function updateStreemshotNote
             * @memberof streem.api.RoomArtifacts
             * @instance
             * @param {streem.api.IUpdateStreemshotNoteRequest} request UpdateStreemshotNoteRequest message or plain object
             * @returns {Promise<streem.api.UpdateStreemshotNoteResponse>} Promise
             * @variation 2
             */

            return RoomArtifacts;
        })();

        api.AcceptExternalAudioRequest = (function() {

            /**
             * Properties of an AcceptExternalAudioRequest.
             * @memberof streem.api
             * @interface IAcceptExternalAudioRequest
             * @property {string|null} [filename] AcceptExternalAudioRequest filename
             * @property {string|null} [roomSid] AcceptExternalAudioRequest roomSid
             * @property {google.protobuf.ITimestamp|null} [startTime] AcceptExternalAudioRequest startTime
             * @property {string|null} [audioFileUrl] AcceptExternalAudioRequest audioFileUrl
             */

            /**
             * Constructs a new AcceptExternalAudioRequest.
             * @memberof streem.api
             * @classdesc Represents an AcceptExternalAudioRequest.
             * @implements IAcceptExternalAudioRequest
             * @constructor
             * @param {streem.api.IAcceptExternalAudioRequest=} [p] Properties to set
             */
            function AcceptExternalAudioRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcceptExternalAudioRequest filename.
             * @member {string} filename
             * @memberof streem.api.AcceptExternalAudioRequest
             * @instance
             */
            AcceptExternalAudioRequest.prototype.filename = "";

            /**
             * AcceptExternalAudioRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.AcceptExternalAudioRequest
             * @instance
             */
            AcceptExternalAudioRequest.prototype.roomSid = "";

            /**
             * AcceptExternalAudioRequest startTime.
             * @member {google.protobuf.ITimestamp|null|undefined} startTime
             * @memberof streem.api.AcceptExternalAudioRequest
             * @instance
             */
            AcceptExternalAudioRequest.prototype.startTime = null;

            /**
             * AcceptExternalAudioRequest audioFileUrl.
             * @member {string} audioFileUrl
             * @memberof streem.api.AcceptExternalAudioRequest
             * @instance
             */
            AcceptExternalAudioRequest.prototype.audioFileUrl = "";

            /**
             * Creates a new AcceptExternalAudioRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {streem.api.IAcceptExternalAudioRequest=} [properties] Properties to set
             * @returns {streem.api.AcceptExternalAudioRequest} AcceptExternalAudioRequest instance
             */
            AcceptExternalAudioRequest.create = function create(properties) {
                return new AcceptExternalAudioRequest(properties);
            };

            /**
             * Encodes the specified AcceptExternalAudioRequest message. Does not implicitly {@link streem.api.AcceptExternalAudioRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {streem.api.IAcceptExternalAudioRequest} m AcceptExternalAudioRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcceptExternalAudioRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                    w.uint32(10).string(m.filename);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                    $root.google.protobuf.Timestamp.encode(m.startTime, w.uint32(34).fork()).ldelim();
                if (m.audioFileUrl != null && Object.hasOwnProperty.call(m, "audioFileUrl"))
                    w.uint32(42).string(m.audioFileUrl);
                return w;
            };

            /**
             * Decodes an AcceptExternalAudioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcceptExternalAudioRequest} AcceptExternalAudioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcceptExternalAudioRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcceptExternalAudioRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.filename = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 4: {
                            m.startTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.audioFileUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcceptExternalAudioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcceptExternalAudioRequest} AcceptExternalAudioRequest
             */
            AcceptExternalAudioRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcceptExternalAudioRequest)
                    return d;
                var m = new $root.streem.api.AcceptExternalAudioRequest();
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.startTime != null) {
                    if (typeof d.startTime !== "object")
                        throw TypeError(".streem.api.AcceptExternalAudioRequest.startTime: object expected");
                    m.startTime = $root.google.protobuf.Timestamp.fromObject(d.startTime);
                }
                if (d.audioFileUrl != null) {
                    m.audioFileUrl = String(d.audioFileUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcceptExternalAudioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {streem.api.AcceptExternalAudioRequest} m AcceptExternalAudioRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcceptExternalAudioRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.filename = "";
                    d.roomSid = "";
                    d.startTime = null;
                    d.audioFileUrl = "";
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.startTime != null && m.hasOwnProperty("startTime")) {
                    d.startTime = $root.google.protobuf.Timestamp.toObject(m.startTime, o);
                }
                if (m.audioFileUrl != null && m.hasOwnProperty("audioFileUrl")) {
                    d.audioFileUrl = m.audioFileUrl;
                }
                return d;
            };

            /**
             * Converts this AcceptExternalAudioRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AcceptExternalAudioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcceptExternalAudioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcceptExternalAudioRequest
             * @function getTypeUrl
             * @memberof streem.api.AcceptExternalAudioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcceptExternalAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcceptExternalAudioRequest";
            };

            return AcceptExternalAudioRequest;
        })();

        api.AcceptExternalAudioResponse = (function() {

            /**
             * Properties of an AcceptExternalAudioResponse.
             * @memberof streem.api
             * @interface IAcceptExternalAudioResponse
             * @property {streem.api.Artifact.IRecordingTrack|null} [recordingTrack] AcceptExternalAudioResponse recordingTrack
             */

            /**
             * Constructs a new AcceptExternalAudioResponse.
             * @memberof streem.api
             * @classdesc Represents an AcceptExternalAudioResponse.
             * @implements IAcceptExternalAudioResponse
             * @constructor
             * @param {streem.api.IAcceptExternalAudioResponse=} [p] Properties to set
             */
            function AcceptExternalAudioResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcceptExternalAudioResponse recordingTrack.
             * @member {streem.api.Artifact.IRecordingTrack|null|undefined} recordingTrack
             * @memberof streem.api.AcceptExternalAudioResponse
             * @instance
             */
            AcceptExternalAudioResponse.prototype.recordingTrack = null;

            /**
             * Creates a new AcceptExternalAudioResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {streem.api.IAcceptExternalAudioResponse=} [properties] Properties to set
             * @returns {streem.api.AcceptExternalAudioResponse} AcceptExternalAudioResponse instance
             */
            AcceptExternalAudioResponse.create = function create(properties) {
                return new AcceptExternalAudioResponse(properties);
            };

            /**
             * Encodes the specified AcceptExternalAudioResponse message. Does not implicitly {@link streem.api.AcceptExternalAudioResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {streem.api.IAcceptExternalAudioResponse} m AcceptExternalAudioResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcceptExternalAudioResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingTrack != null && Object.hasOwnProperty.call(m, "recordingTrack"))
                    $root.streem.api.Artifact.RecordingTrack.encode(m.recordingTrack, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AcceptExternalAudioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcceptExternalAudioResponse} AcceptExternalAudioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcceptExternalAudioResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcceptExternalAudioResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingTrack = $root.streem.api.Artifact.RecordingTrack.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcceptExternalAudioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcceptExternalAudioResponse} AcceptExternalAudioResponse
             */
            AcceptExternalAudioResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcceptExternalAudioResponse)
                    return d;
                var m = new $root.streem.api.AcceptExternalAudioResponse();
                if (d.recordingTrack != null) {
                    if (typeof d.recordingTrack !== "object")
                        throw TypeError(".streem.api.AcceptExternalAudioResponse.recordingTrack: object expected");
                    m.recordingTrack = $root.streem.api.Artifact.RecordingTrack.fromObject(d.recordingTrack);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcceptExternalAudioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {streem.api.AcceptExternalAudioResponse} m AcceptExternalAudioResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcceptExternalAudioResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingTrack = null;
                }
                if (m.recordingTrack != null && m.hasOwnProperty("recordingTrack")) {
                    d.recordingTrack = $root.streem.api.Artifact.RecordingTrack.toObject(m.recordingTrack, o);
                }
                return d;
            };

            /**
             * Converts this AcceptExternalAudioResponse to JSON.
             * @function toJSON
             * @memberof streem.api.AcceptExternalAudioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcceptExternalAudioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcceptExternalAudioResponse
             * @function getTypeUrl
             * @memberof streem.api.AcceptExternalAudioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcceptExternalAudioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcceptExternalAudioResponse";
            };

            return AcceptExternalAudioResponse;
        })();

        api.DeleteExternalAudioRequest = (function() {

            /**
             * Properties of a DeleteExternalAudioRequest.
             * @memberof streem.api
             * @interface IDeleteExternalAudioRequest
             * @property {string|null} [roomSid] DeleteExternalAudioRequest roomSid
             */

            /**
             * Constructs a new DeleteExternalAudioRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteExternalAudioRequest.
             * @implements IDeleteExternalAudioRequest
             * @constructor
             * @param {streem.api.IDeleteExternalAudioRequest=} [p] Properties to set
             */
            function DeleteExternalAudioRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteExternalAudioRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteExternalAudioRequest
             * @instance
             */
            DeleteExternalAudioRequest.prototype.roomSid = "";

            /**
             * Creates a new DeleteExternalAudioRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {streem.api.IDeleteExternalAudioRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteExternalAudioRequest} DeleteExternalAudioRequest instance
             */
            DeleteExternalAudioRequest.create = function create(properties) {
                return new DeleteExternalAudioRequest(properties);
            };

            /**
             * Encodes the specified DeleteExternalAudioRequest message. Does not implicitly {@link streem.api.DeleteExternalAudioRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {streem.api.IDeleteExternalAudioRequest} m DeleteExternalAudioRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteExternalAudioRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a DeleteExternalAudioRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteExternalAudioRequest} DeleteExternalAudioRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteExternalAudioRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteExternalAudioRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteExternalAudioRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteExternalAudioRequest} DeleteExternalAudioRequest
             */
            DeleteExternalAudioRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteExternalAudioRequest)
                    return d;
                var m = new $root.streem.api.DeleteExternalAudioRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteExternalAudioRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {streem.api.DeleteExternalAudioRequest} m DeleteExternalAudioRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteExternalAudioRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this DeleteExternalAudioRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteExternalAudioRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteExternalAudioRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteExternalAudioRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteExternalAudioRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteExternalAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteExternalAudioRequest";
            };

            return DeleteExternalAudioRequest;
        })();

        api.DeleteExternalAudioResponse = (function() {

            /**
             * Properties of a DeleteExternalAudioResponse.
             * @memberof streem.api
             * @interface IDeleteExternalAudioResponse
             */

            /**
             * Constructs a new DeleteExternalAudioResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteExternalAudioResponse.
             * @implements IDeleteExternalAudioResponse
             * @constructor
             * @param {streem.api.IDeleteExternalAudioResponse=} [p] Properties to set
             */
            function DeleteExternalAudioResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteExternalAudioResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {streem.api.IDeleteExternalAudioResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteExternalAudioResponse} DeleteExternalAudioResponse instance
             */
            DeleteExternalAudioResponse.create = function create(properties) {
                return new DeleteExternalAudioResponse(properties);
            };

            /**
             * Encodes the specified DeleteExternalAudioResponse message. Does not implicitly {@link streem.api.DeleteExternalAudioResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {streem.api.IDeleteExternalAudioResponse} m DeleteExternalAudioResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteExternalAudioResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteExternalAudioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteExternalAudioResponse} DeleteExternalAudioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteExternalAudioResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteExternalAudioResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteExternalAudioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteExternalAudioResponse} DeleteExternalAudioResponse
             */
            DeleteExternalAudioResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteExternalAudioResponse)
                    return d;
                return new $root.streem.api.DeleteExternalAudioResponse();
            };

            /**
             * Creates a plain object from a DeleteExternalAudioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {streem.api.DeleteExternalAudioResponse} m DeleteExternalAudioResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteExternalAudioResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteExternalAudioResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteExternalAudioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteExternalAudioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteExternalAudioResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteExternalAudioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteExternalAudioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteExternalAudioResponse";
            };

            return DeleteExternalAudioResponse;
        })();

        api.BatchGetLastGpsPositionRequest = (function() {

            /**
             * Properties of a BatchGetLastGpsPositionRequest.
             * @memberof streem.api
             * @interface IBatchGetLastGpsPositionRequest
             * @property {Array.<string>|null} [roomSids] BatchGetLastGpsPositionRequest roomSids
             */

            /**
             * Constructs a new BatchGetLastGpsPositionRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchGetLastGpsPositionRequest.
             * @implements IBatchGetLastGpsPositionRequest
             * @constructor
             * @param {streem.api.IBatchGetLastGpsPositionRequest=} [p] Properties to set
             */
            function BatchGetLastGpsPositionRequest(p) {
                this.roomSids = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetLastGpsPositionRequest roomSids.
             * @member {Array.<string>} roomSids
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @instance
             */
            BatchGetLastGpsPositionRequest.prototype.roomSids = $util.emptyArray;

            /**
             * Creates a new BatchGetLastGpsPositionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {streem.api.IBatchGetLastGpsPositionRequest=} [properties] Properties to set
             * @returns {streem.api.BatchGetLastGpsPositionRequest} BatchGetLastGpsPositionRequest instance
             */
            BatchGetLastGpsPositionRequest.create = function create(properties) {
                return new BatchGetLastGpsPositionRequest(properties);
            };

            /**
             * Encodes the specified BatchGetLastGpsPositionRequest message. Does not implicitly {@link streem.api.BatchGetLastGpsPositionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {streem.api.IBatchGetLastGpsPositionRequest} m BatchGetLastGpsPositionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetLastGpsPositionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSids != null && m.roomSids.length) {
                    for (var i = 0; i < m.roomSids.length; ++i)
                        w.uint32(10).string(m.roomSids[i]);
                }
                return w;
            };

            /**
             * Decodes a BatchGetLastGpsPositionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetLastGpsPositionRequest} BatchGetLastGpsPositionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetLastGpsPositionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetLastGpsPositionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomSids && m.roomSids.length))
                                m.roomSids = [];
                            m.roomSids.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetLastGpsPositionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetLastGpsPositionRequest} BatchGetLastGpsPositionRequest
             */
            BatchGetLastGpsPositionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetLastGpsPositionRequest)
                    return d;
                var m = new $root.streem.api.BatchGetLastGpsPositionRequest();
                if (d.roomSids) {
                    if (!Array.isArray(d.roomSids))
                        throw TypeError(".streem.api.BatchGetLastGpsPositionRequest.roomSids: array expected");
                    m.roomSids = [];
                    for (var i = 0; i < d.roomSids.length; ++i) {
                        m.roomSids[i] = String(d.roomSids[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetLastGpsPositionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {streem.api.BatchGetLastGpsPositionRequest} m BatchGetLastGpsPositionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetLastGpsPositionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomSids = [];
                }
                if (m.roomSids && m.roomSids.length) {
                    d.roomSids = [];
                    for (var j = 0; j < m.roomSids.length; ++j) {
                        d.roomSids[j] = m.roomSids[j];
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetLastGpsPositionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetLastGpsPositionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetLastGpsPositionRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchGetLastGpsPositionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetLastGpsPositionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetLastGpsPositionRequest";
            };

            return BatchGetLastGpsPositionRequest;
        })();

        api.BatchGetLastGpsPositionResponse = (function() {

            /**
             * Properties of a BatchGetLastGpsPositionResponse.
             * @memberof streem.api
             * @interface IBatchGetLastGpsPositionResponse
             * @property {Array.<streem.api.IGetGpsPositionResponse>|null} [responses] BatchGetLastGpsPositionResponse responses
             */

            /**
             * Constructs a new BatchGetLastGpsPositionResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchGetLastGpsPositionResponse.
             * @implements IBatchGetLastGpsPositionResponse
             * @constructor
             * @param {streem.api.IBatchGetLastGpsPositionResponse=} [p] Properties to set
             */
            function BatchGetLastGpsPositionResponse(p) {
                this.responses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetLastGpsPositionResponse responses.
             * @member {Array.<streem.api.IGetGpsPositionResponse>} responses
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @instance
             */
            BatchGetLastGpsPositionResponse.prototype.responses = $util.emptyArray;

            /**
             * Creates a new BatchGetLastGpsPositionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {streem.api.IBatchGetLastGpsPositionResponse=} [properties] Properties to set
             * @returns {streem.api.BatchGetLastGpsPositionResponse} BatchGetLastGpsPositionResponse instance
             */
            BatchGetLastGpsPositionResponse.create = function create(properties) {
                return new BatchGetLastGpsPositionResponse(properties);
            };

            /**
             * Encodes the specified BatchGetLastGpsPositionResponse message. Does not implicitly {@link streem.api.BatchGetLastGpsPositionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {streem.api.IBatchGetLastGpsPositionResponse} m BatchGetLastGpsPositionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetLastGpsPositionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.responses != null && m.responses.length) {
                    for (var i = 0; i < m.responses.length; ++i)
                        $root.streem.api.GetGpsPositionResponse.encode(m.responses[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchGetLastGpsPositionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetLastGpsPositionResponse} BatchGetLastGpsPositionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetLastGpsPositionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetLastGpsPositionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.responses && m.responses.length))
                                m.responses = [];
                            m.responses.push($root.streem.api.GetGpsPositionResponse.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetLastGpsPositionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetLastGpsPositionResponse} BatchGetLastGpsPositionResponse
             */
            BatchGetLastGpsPositionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetLastGpsPositionResponse)
                    return d;
                var m = new $root.streem.api.BatchGetLastGpsPositionResponse();
                if (d.responses) {
                    if (!Array.isArray(d.responses))
                        throw TypeError(".streem.api.BatchGetLastGpsPositionResponse.responses: array expected");
                    m.responses = [];
                    for (var i = 0; i < d.responses.length; ++i) {
                        if (typeof d.responses[i] !== "object")
                            throw TypeError(".streem.api.BatchGetLastGpsPositionResponse.responses: object expected");
                        m.responses[i] = $root.streem.api.GetGpsPositionResponse.fromObject(d.responses[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetLastGpsPositionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {streem.api.BatchGetLastGpsPositionResponse} m BatchGetLastGpsPositionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetLastGpsPositionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.responses = [];
                }
                if (m.responses && m.responses.length) {
                    d.responses = [];
                    for (var j = 0; j < m.responses.length; ++j) {
                        d.responses[j] = $root.streem.api.GetGpsPositionResponse.toObject(m.responses[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetLastGpsPositionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetLastGpsPositionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetLastGpsPositionResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchGetLastGpsPositionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetLastGpsPositionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetLastGpsPositionResponse";
            };

            return BatchGetLastGpsPositionResponse;
        })();

        api.BatchGetRoomArtifactCountsRequest = (function() {

            /**
             * Properties of a BatchGetRoomArtifactCountsRequest.
             * @memberof streem.api
             * @interface IBatchGetRoomArtifactCountsRequest
             * @property {Array.<string>|null} [roomSids] BatchGetRoomArtifactCountsRequest roomSids
             */

            /**
             * Constructs a new BatchGetRoomArtifactCountsRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchGetRoomArtifactCountsRequest.
             * @implements IBatchGetRoomArtifactCountsRequest
             * @constructor
             * @param {streem.api.IBatchGetRoomArtifactCountsRequest=} [p] Properties to set
             */
            function BatchGetRoomArtifactCountsRequest(p) {
                this.roomSids = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetRoomArtifactCountsRequest roomSids.
             * @member {Array.<string>} roomSids
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @instance
             */
            BatchGetRoomArtifactCountsRequest.prototype.roomSids = $util.emptyArray;

            /**
             * Creates a new BatchGetRoomArtifactCountsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.IBatchGetRoomArtifactCountsRequest=} [properties] Properties to set
             * @returns {streem.api.BatchGetRoomArtifactCountsRequest} BatchGetRoomArtifactCountsRequest instance
             */
            BatchGetRoomArtifactCountsRequest.create = function create(properties) {
                return new BatchGetRoomArtifactCountsRequest(properties);
            };

            /**
             * Encodes the specified BatchGetRoomArtifactCountsRequest message. Does not implicitly {@link streem.api.BatchGetRoomArtifactCountsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.IBatchGetRoomArtifactCountsRequest} m BatchGetRoomArtifactCountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetRoomArtifactCountsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSids != null && m.roomSids.length) {
                    for (var i = 0; i < m.roomSids.length; ++i)
                        w.uint32(10).string(m.roomSids[i]);
                }
                return w;
            };

            /**
             * Decodes a BatchGetRoomArtifactCountsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetRoomArtifactCountsRequest} BatchGetRoomArtifactCountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetRoomArtifactCountsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetRoomArtifactCountsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomSids && m.roomSids.length))
                                m.roomSids = [];
                            m.roomSids.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetRoomArtifactCountsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetRoomArtifactCountsRequest} BatchGetRoomArtifactCountsRequest
             */
            BatchGetRoomArtifactCountsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetRoomArtifactCountsRequest)
                    return d;
                var m = new $root.streem.api.BatchGetRoomArtifactCountsRequest();
                if (d.roomSids) {
                    if (!Array.isArray(d.roomSids))
                        throw TypeError(".streem.api.BatchGetRoomArtifactCountsRequest.roomSids: array expected");
                    m.roomSids = [];
                    for (var i = 0; i < d.roomSids.length; ++i) {
                        m.roomSids[i] = String(d.roomSids[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetRoomArtifactCountsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.BatchGetRoomArtifactCountsRequest} m BatchGetRoomArtifactCountsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetRoomArtifactCountsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomSids = [];
                }
                if (m.roomSids && m.roomSids.length) {
                    d.roomSids = [];
                    for (var j = 0; j < m.roomSids.length; ++j) {
                        d.roomSids[j] = m.roomSids[j];
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetRoomArtifactCountsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetRoomArtifactCountsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetRoomArtifactCountsRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchGetRoomArtifactCountsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetRoomArtifactCountsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetRoomArtifactCountsRequest";
            };

            return BatchGetRoomArtifactCountsRequest;
        })();

        api.BatchGetRoomArtifactCountsResponse = (function() {

            /**
             * Properties of a BatchGetRoomArtifactCountsResponse.
             * @memberof streem.api
             * @interface IBatchGetRoomArtifactCountsResponse
             * @property {Array.<streem.api.IGetRoomArtifactCountsResponse>|null} [responses] BatchGetRoomArtifactCountsResponse responses
             */

            /**
             * Constructs a new BatchGetRoomArtifactCountsResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchGetRoomArtifactCountsResponse.
             * @implements IBatchGetRoomArtifactCountsResponse
             * @constructor
             * @param {streem.api.IBatchGetRoomArtifactCountsResponse=} [p] Properties to set
             */
            function BatchGetRoomArtifactCountsResponse(p) {
                this.responses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetRoomArtifactCountsResponse responses.
             * @member {Array.<streem.api.IGetRoomArtifactCountsResponse>} responses
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @instance
             */
            BatchGetRoomArtifactCountsResponse.prototype.responses = $util.emptyArray;

            /**
             * Creates a new BatchGetRoomArtifactCountsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.IBatchGetRoomArtifactCountsResponse=} [properties] Properties to set
             * @returns {streem.api.BatchGetRoomArtifactCountsResponse} BatchGetRoomArtifactCountsResponse instance
             */
            BatchGetRoomArtifactCountsResponse.create = function create(properties) {
                return new BatchGetRoomArtifactCountsResponse(properties);
            };

            /**
             * Encodes the specified BatchGetRoomArtifactCountsResponse message. Does not implicitly {@link streem.api.BatchGetRoomArtifactCountsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.IBatchGetRoomArtifactCountsResponse} m BatchGetRoomArtifactCountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetRoomArtifactCountsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.responses != null && m.responses.length) {
                    for (var i = 0; i < m.responses.length; ++i)
                        $root.streem.api.GetRoomArtifactCountsResponse.encode(m.responses[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchGetRoomArtifactCountsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetRoomArtifactCountsResponse} BatchGetRoomArtifactCountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetRoomArtifactCountsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetRoomArtifactCountsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.responses && m.responses.length))
                                m.responses = [];
                            m.responses.push($root.streem.api.GetRoomArtifactCountsResponse.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetRoomArtifactCountsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetRoomArtifactCountsResponse} BatchGetRoomArtifactCountsResponse
             */
            BatchGetRoomArtifactCountsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetRoomArtifactCountsResponse)
                    return d;
                var m = new $root.streem.api.BatchGetRoomArtifactCountsResponse();
                if (d.responses) {
                    if (!Array.isArray(d.responses))
                        throw TypeError(".streem.api.BatchGetRoomArtifactCountsResponse.responses: array expected");
                    m.responses = [];
                    for (var i = 0; i < d.responses.length; ++i) {
                        if (typeof d.responses[i] !== "object")
                            throw TypeError(".streem.api.BatchGetRoomArtifactCountsResponse.responses: object expected");
                        m.responses[i] = $root.streem.api.GetRoomArtifactCountsResponse.fromObject(d.responses[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetRoomArtifactCountsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.BatchGetRoomArtifactCountsResponse} m BatchGetRoomArtifactCountsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetRoomArtifactCountsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.responses = [];
                }
                if (m.responses && m.responses.length) {
                    d.responses = [];
                    for (var j = 0; j < m.responses.length; ++j) {
                        d.responses[j] = $root.streem.api.GetRoomArtifactCountsResponse.toObject(m.responses[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetRoomArtifactCountsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetRoomArtifactCountsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetRoomArtifactCountsResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchGetRoomArtifactCountsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetRoomArtifactCountsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetRoomArtifactCountsResponse";
            };

            return BatchGetRoomArtifactCountsResponse;
        })();

        api.BatchGetRoomNoteRequest = (function() {

            /**
             * Properties of a BatchGetRoomNoteRequest.
             * @memberof streem.api
             * @interface IBatchGetRoomNoteRequest
             * @property {Array.<string>|null} [roomSids] BatchGetRoomNoteRequest roomSids
             */

            /**
             * Constructs a new BatchGetRoomNoteRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchGetRoomNoteRequest.
             * @implements IBatchGetRoomNoteRequest
             * @constructor
             * @param {streem.api.IBatchGetRoomNoteRequest=} [p] Properties to set
             */
            function BatchGetRoomNoteRequest(p) {
                this.roomSids = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetRoomNoteRequest roomSids.
             * @member {Array.<string>} roomSids
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @instance
             */
            BatchGetRoomNoteRequest.prototype.roomSids = $util.emptyArray;

            /**
             * Creates a new BatchGetRoomNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {streem.api.IBatchGetRoomNoteRequest=} [properties] Properties to set
             * @returns {streem.api.BatchGetRoomNoteRequest} BatchGetRoomNoteRequest instance
             */
            BatchGetRoomNoteRequest.create = function create(properties) {
                return new BatchGetRoomNoteRequest(properties);
            };

            /**
             * Encodes the specified BatchGetRoomNoteRequest message. Does not implicitly {@link streem.api.BatchGetRoomNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {streem.api.IBatchGetRoomNoteRequest} m BatchGetRoomNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetRoomNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSids != null && m.roomSids.length) {
                    for (var i = 0; i < m.roomSids.length; ++i)
                        w.uint32(10).string(m.roomSids[i]);
                }
                return w;
            };

            /**
             * Decodes a BatchGetRoomNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetRoomNoteRequest} BatchGetRoomNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetRoomNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetRoomNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomSids && m.roomSids.length))
                                m.roomSids = [];
                            m.roomSids.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetRoomNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetRoomNoteRequest} BatchGetRoomNoteRequest
             */
            BatchGetRoomNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetRoomNoteRequest)
                    return d;
                var m = new $root.streem.api.BatchGetRoomNoteRequest();
                if (d.roomSids) {
                    if (!Array.isArray(d.roomSids))
                        throw TypeError(".streem.api.BatchGetRoomNoteRequest.roomSids: array expected");
                    m.roomSids = [];
                    for (var i = 0; i < d.roomSids.length; ++i) {
                        m.roomSids[i] = String(d.roomSids[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetRoomNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {streem.api.BatchGetRoomNoteRequest} m BatchGetRoomNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetRoomNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomSids = [];
                }
                if (m.roomSids && m.roomSids.length) {
                    d.roomSids = [];
                    for (var j = 0; j < m.roomSids.length; ++j) {
                        d.roomSids[j] = m.roomSids[j];
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetRoomNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetRoomNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetRoomNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchGetRoomNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetRoomNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetRoomNoteRequest";
            };

            return BatchGetRoomNoteRequest;
        })();

        api.BatchGetRoomNoteResponse = (function() {

            /**
             * Properties of a BatchGetRoomNoteResponse.
             * @memberof streem.api
             * @interface IBatchGetRoomNoteResponse
             * @property {Array.<streem.api.IGetRoomNoteResponse>|null} [responses] BatchGetRoomNoteResponse responses
             */

            /**
             * Constructs a new BatchGetRoomNoteResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchGetRoomNoteResponse.
             * @implements IBatchGetRoomNoteResponse
             * @constructor
             * @param {streem.api.IBatchGetRoomNoteResponse=} [p] Properties to set
             */
            function BatchGetRoomNoteResponse(p) {
                this.responses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchGetRoomNoteResponse responses.
             * @member {Array.<streem.api.IGetRoomNoteResponse>} responses
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @instance
             */
            BatchGetRoomNoteResponse.prototype.responses = $util.emptyArray;

            /**
             * Creates a new BatchGetRoomNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {streem.api.IBatchGetRoomNoteResponse=} [properties] Properties to set
             * @returns {streem.api.BatchGetRoomNoteResponse} BatchGetRoomNoteResponse instance
             */
            BatchGetRoomNoteResponse.create = function create(properties) {
                return new BatchGetRoomNoteResponse(properties);
            };

            /**
             * Encodes the specified BatchGetRoomNoteResponse message. Does not implicitly {@link streem.api.BatchGetRoomNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {streem.api.IBatchGetRoomNoteResponse} m BatchGetRoomNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchGetRoomNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.responses != null && m.responses.length) {
                    for (var i = 0; i < m.responses.length; ++i)
                        $root.streem.api.GetRoomNoteResponse.encode(m.responses[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchGetRoomNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchGetRoomNoteResponse} BatchGetRoomNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchGetRoomNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchGetRoomNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.responses && m.responses.length))
                                m.responses = [];
                            m.responses.push($root.streem.api.GetRoomNoteResponse.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchGetRoomNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchGetRoomNoteResponse} BatchGetRoomNoteResponse
             */
            BatchGetRoomNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchGetRoomNoteResponse)
                    return d;
                var m = new $root.streem.api.BatchGetRoomNoteResponse();
                if (d.responses) {
                    if (!Array.isArray(d.responses))
                        throw TypeError(".streem.api.BatchGetRoomNoteResponse.responses: array expected");
                    m.responses = [];
                    for (var i = 0; i < d.responses.length; ++i) {
                        if (typeof d.responses[i] !== "object")
                            throw TypeError(".streem.api.BatchGetRoomNoteResponse.responses: object expected");
                        m.responses[i] = $root.streem.api.GetRoomNoteResponse.fromObject(d.responses[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchGetRoomNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {streem.api.BatchGetRoomNoteResponse} m BatchGetRoomNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchGetRoomNoteResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.responses = [];
                }
                if (m.responses && m.responses.length) {
                    d.responses = [];
                    for (var j = 0; j < m.responses.length; ++j) {
                        d.responses[j] = $root.streem.api.GetRoomNoteResponse.toObject(m.responses[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchGetRoomNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchGetRoomNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchGetRoomNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchGetRoomNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchGetRoomNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchGetRoomNoteResponse";
            };

            return BatchGetRoomNoteResponse;
        })();

        api.CreateLayoutEstimationRequest = (function() {

            /**
             * Properties of a CreateLayoutEstimationRequest.
             * @memberof streem.api
             * @interface ICreateLayoutEstimationRequest
             * @property {string|null} [roomSid] CreateLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] CreateLayoutEstimationRequest layoutEstimationSid
             * @property {string|null} [meshSid] CreateLayoutEstimationRequest meshSid
             */

            /**
             * Constructs a new CreateLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateLayoutEstimationRequest.
             * @implements ICreateLayoutEstimationRequest
             * @constructor
             * @param {streem.api.ICreateLayoutEstimationRequest=} [p] Properties to set
             */
            function CreateLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @instance
             */
            CreateLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * CreateLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @instance
             */
            CreateLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * CreateLayoutEstimationRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @instance
             */
            CreateLayoutEstimationRequest.prototype.meshSid = "";

            /**
             * Creates a new CreateLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {streem.api.ICreateLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.CreateLayoutEstimationRequest} CreateLayoutEstimationRequest instance
             */
            CreateLayoutEstimationRequest.create = function create(properties) {
                return new CreateLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified CreateLayoutEstimationRequest message. Does not implicitly {@link streem.api.CreateLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {streem.api.ICreateLayoutEstimationRequest} m CreateLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(26).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a CreateLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateLayoutEstimationRequest} CreateLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    case 3: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateLayoutEstimationRequest} CreateLayoutEstimationRequest
             */
            CreateLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.CreateLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {streem.api.CreateLayoutEstimationRequest} m CreateLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this CreateLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateLayoutEstimationRequest";
            };

            return CreateLayoutEstimationRequest;
        })();

        api.CreateLayoutEstimationResponse = (function() {

            /**
             * Properties of a CreateLayoutEstimationResponse.
             * @memberof streem.api
             * @interface ICreateLayoutEstimationResponse
             * @property {streem.api.IArtifact|null} [layoutEstimationArtifact] CreateLayoutEstimationResponse layoutEstimationArtifact
             */

            /**
             * Constructs a new CreateLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateLayoutEstimationResponse.
             * @implements ICreateLayoutEstimationResponse
             * @constructor
             * @param {streem.api.ICreateLayoutEstimationResponse=} [p] Properties to set
             */
            function CreateLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateLayoutEstimationResponse layoutEstimationArtifact.
             * @member {streem.api.IArtifact|null|undefined} layoutEstimationArtifact
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @instance
             */
            CreateLayoutEstimationResponse.prototype.layoutEstimationArtifact = null;

            /**
             * Creates a new CreateLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {streem.api.ICreateLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.CreateLayoutEstimationResponse} CreateLayoutEstimationResponse instance
             */
            CreateLayoutEstimationResponse.create = function create(properties) {
                return new CreateLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified CreateLayoutEstimationResponse message. Does not implicitly {@link streem.api.CreateLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {streem.api.ICreateLayoutEstimationResponse} m CreateLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.layoutEstimationArtifact != null && Object.hasOwnProperty.call(m, "layoutEstimationArtifact"))
                    $root.streem.api.Artifact.encode(m.layoutEstimationArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateLayoutEstimationResponse} CreateLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.layoutEstimationArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateLayoutEstimationResponse} CreateLayoutEstimationResponse
             */
            CreateLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateLayoutEstimationResponse)
                    return d;
                var m = new $root.streem.api.CreateLayoutEstimationResponse();
                if (d.layoutEstimationArtifact != null) {
                    if (typeof d.layoutEstimationArtifact !== "object")
                        throw TypeError(".streem.api.CreateLayoutEstimationResponse.layoutEstimationArtifact: object expected");
                    m.layoutEstimationArtifact = $root.streem.api.Artifact.fromObject(d.layoutEstimationArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {streem.api.CreateLayoutEstimationResponse} m CreateLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateLayoutEstimationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.layoutEstimationArtifact = null;
                }
                if (m.layoutEstimationArtifact != null && m.hasOwnProperty("layoutEstimationArtifact")) {
                    d.layoutEstimationArtifact = $root.streem.api.Artifact.toObject(m.layoutEstimationArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateLayoutEstimationResponse";
            };

            return CreateLayoutEstimationResponse;
        })();

        api.CreateMeshRequest = (function() {

            /**
             * Properties of a CreateMeshRequest.
             * @memberof streem.api
             * @interface ICreateMeshRequest
             * @property {string|null} [roomSid] CreateMeshRequest roomSid
             * @property {string|null} [meshSid] CreateMeshRequest meshSid
             * @property {string|null} [name] CreateMeshRequest name
             * @property {Array.<string>|null} [streemshotSids] CreateMeshRequest streemshotSids
             * @property {string|null} [companyCode] CreateMeshRequest companyCode
             */

            /**
             * Constructs a new CreateMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateMeshRequest.
             * @implements ICreateMeshRequest
             * @constructor
             * @param {streem.api.ICreateMeshRequest=} [p] Properties to set
             */
            function CreateMeshRequest(p) {
                this.streemshotSids = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateMeshRequest
             * @instance
             */
            CreateMeshRequest.prototype.roomSid = "";

            /**
             * CreateMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.CreateMeshRequest
             * @instance
             */
            CreateMeshRequest.prototype.meshSid = "";

            /**
             * CreateMeshRequest name.
             * @member {string} name
             * @memberof streem.api.CreateMeshRequest
             * @instance
             */
            CreateMeshRequest.prototype.name = "";

            /**
             * CreateMeshRequest streemshotSids.
             * @member {Array.<string>} streemshotSids
             * @memberof streem.api.CreateMeshRequest
             * @instance
             */
            CreateMeshRequest.prototype.streemshotSids = $util.emptyArray;

            /**
             * CreateMeshRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.CreateMeshRequest
             * @instance
             */
            CreateMeshRequest.prototype.companyCode = "";

            /**
             * Creates a new CreateMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {streem.api.ICreateMeshRequest=} [properties] Properties to set
             * @returns {streem.api.CreateMeshRequest} CreateMeshRequest instance
             */
            CreateMeshRequest.create = function create(properties) {
                return new CreateMeshRequest(properties);
            };

            /**
             * Encodes the specified CreateMeshRequest message. Does not implicitly {@link streem.api.CreateMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {streem.api.ICreateMeshRequest} m CreateMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.streemshotSids != null && m.streemshotSids.length) {
                    for (var i = 0; i < m.streemshotSids.length; ++i)
                        w.uint32(34).string(m.streemshotSids[i]);
                }
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(42).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a CreateMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateMeshRequest} CreateMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    case 4: {
                            if (!(m.streemshotSids && m.streemshotSids.length))
                                m.streemshotSids = [];
                            m.streemshotSids.push(r.string());
                            break;
                        }
                    case 5: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateMeshRequest} CreateMeshRequest
             */
            CreateMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateMeshRequest)
                    return d;
                var m = new $root.streem.api.CreateMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.streemshotSids) {
                    if (!Array.isArray(d.streemshotSids))
                        throw TypeError(".streem.api.CreateMeshRequest.streemshotSids: array expected");
                    m.streemshotSids = [];
                    for (var i = 0; i < d.streemshotSids.length; ++i) {
                        m.streemshotSids[i] = String(d.streemshotSids[i]);
                    }
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {streem.api.CreateMeshRequest} m CreateMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.streemshotSids = [];
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                    d.name = "";
                    d.companyCode = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.streemshotSids && m.streemshotSids.length) {
                    d.streemshotSids = [];
                    for (var j = 0; j < m.streemshotSids.length; ++j) {
                        d.streemshotSids[j] = m.streemshotSids[j];
                    }
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this CreateMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateMeshRequest";
            };

            return CreateMeshRequest;
        })();

        api.CreateMeshResponse = (function() {

            /**
             * Properties of a CreateMeshResponse.
             * @memberof streem.api
             * @interface ICreateMeshResponse
             * @property {streem.api.IArtifact|null} [meshArtifact] CreateMeshResponse meshArtifact
             */

            /**
             * Constructs a new CreateMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateMeshResponse.
             * @implements ICreateMeshResponse
             * @constructor
             * @param {streem.api.ICreateMeshResponse=} [p] Properties to set
             */
            function CreateMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateMeshResponse meshArtifact.
             * @member {streem.api.IArtifact|null|undefined} meshArtifact
             * @memberof streem.api.CreateMeshResponse
             * @instance
             */
            CreateMeshResponse.prototype.meshArtifact = null;

            /**
             * Creates a new CreateMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {streem.api.ICreateMeshResponse=} [properties] Properties to set
             * @returns {streem.api.CreateMeshResponse} CreateMeshResponse instance
             */
            CreateMeshResponse.create = function create(properties) {
                return new CreateMeshResponse(properties);
            };

            /**
             * Encodes the specified CreateMeshResponse message. Does not implicitly {@link streem.api.CreateMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {streem.api.ICreateMeshResponse} m CreateMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifact != null && Object.hasOwnProperty.call(m, "meshArtifact"))
                    $root.streem.api.Artifact.encode(m.meshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateMeshResponse} CreateMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateMeshResponse} CreateMeshResponse
             */
            CreateMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateMeshResponse)
                    return d;
                var m = new $root.streem.api.CreateMeshResponse();
                if (d.meshArtifact != null) {
                    if (typeof d.meshArtifact !== "object")
                        throw TypeError(".streem.api.CreateMeshResponse.meshArtifact: object expected");
                    m.meshArtifact = $root.streem.api.Artifact.fromObject(d.meshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {streem.api.CreateMeshResponse} m CreateMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meshArtifact = null;
                }
                if (m.meshArtifact != null && m.hasOwnProperty("meshArtifact")) {
                    d.meshArtifact = $root.streem.api.Artifact.toObject(m.meshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateMeshResponse";
            };

            return CreateMeshResponse;
        })();

        api.CreateRecordingRequest = (function() {

            /**
             * Properties of a CreateRecordingRequest.
             * @memberof streem.api
             * @interface ICreateRecordingRequest
             * @property {string|null} [roomSid] CreateRecordingRequest roomSid
             */

            /**
             * Constructs a new CreateRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRecordingRequest.
             * @implements ICreateRecordingRequest
             * @constructor
             * @param {streem.api.ICreateRecordingRequest=} [p] Properties to set
             */
            function CreateRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRecordingRequest
             * @instance
             */
            CreateRecordingRequest.prototype.roomSid = "";

            /**
             * Creates a new CreateRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {streem.api.ICreateRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRecordingRequest} CreateRecordingRequest instance
             */
            CreateRecordingRequest.create = function create(properties) {
                return new CreateRecordingRequest(properties);
            };

            /**
             * Encodes the specified CreateRecordingRequest message. Does not implicitly {@link streem.api.CreateRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {streem.api.ICreateRecordingRequest} m CreateRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a CreateRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRecordingRequest} CreateRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRecordingRequest} CreateRecordingRequest
             */
            CreateRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRecordingRequest)
                    return d;
                var m = new $root.streem.api.CreateRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {streem.api.CreateRecordingRequest} m CreateRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this CreateRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRecordingRequest";
            };

            return CreateRecordingRequest;
        })();

        api.CreateRecordingResponse = (function() {

            /**
             * Properties of a CreateRecordingResponse.
             * @memberof streem.api
             * @interface ICreateRecordingResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] CreateRecordingResponse recordingArtifact
             */

            /**
             * Constructs a new CreateRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRecordingResponse.
             * @implements ICreateRecordingResponse
             * @constructor
             * @param {streem.api.ICreateRecordingResponse=} [p] Properties to set
             */
            function CreateRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRecordingResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.CreateRecordingResponse
             * @instance
             */
            CreateRecordingResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new CreateRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {streem.api.ICreateRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRecordingResponse} CreateRecordingResponse instance
             */
            CreateRecordingResponse.create = function create(properties) {
                return new CreateRecordingResponse(properties);
            };

            /**
             * Encodes the specified CreateRecordingResponse message. Does not implicitly {@link streem.api.CreateRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {streem.api.ICreateRecordingResponse} m CreateRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRecordingResponse} CreateRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRecordingResponse} CreateRecordingResponse
             */
            CreateRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRecordingResponse)
                    return d;
                var m = new $root.streem.api.CreateRecordingResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.CreateRecordingResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {streem.api.CreateRecordingResponse} m CreateRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRecordingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRecordingResponse";
            };

            return CreateRecordingResponse;
        })();

        api.CreateRoomOutcomeReportRequest = (function() {

            /**
             * Properties of a CreateRoomOutcomeReportRequest.
             * @memberof streem.api
             * @interface ICreateRoomOutcomeReportRequest
             * @property {string|null} [roomSid] CreateRoomOutcomeReportRequest roomSid
             * @property {Array.<streem.api.Artifact.RoomOutcomeReport.IEntry>|null} [diagnoses] CreateRoomOutcomeReportRequest diagnoses
             * @property {streem.api.Artifact.RoomOutcomeReport.IEntry|null} [disposition] CreateRoomOutcomeReportRequest disposition
             * @property {Object.<string,google.protobuf.IValue>|null} [customFields] CreateRoomOutcomeReportRequest customFields
             */

            /**
             * Constructs a new CreateRoomOutcomeReportRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomOutcomeReportRequest.
             * @implements ICreateRoomOutcomeReportRequest
             * @constructor
             * @param {streem.api.ICreateRoomOutcomeReportRequest=} [p] Properties to set
             */
            function CreateRoomOutcomeReportRequest(p) {
                this.diagnoses = [];
                this.customFields = {};
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomOutcomeReportRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @instance
             */
            CreateRoomOutcomeReportRequest.prototype.roomSid = "";

            /**
             * CreateRoomOutcomeReportRequest diagnoses.
             * @member {Array.<streem.api.Artifact.RoomOutcomeReport.IEntry>} diagnoses
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @instance
             */
            CreateRoomOutcomeReportRequest.prototype.diagnoses = $util.emptyArray;

            /**
             * CreateRoomOutcomeReportRequest disposition.
             * @member {streem.api.Artifact.RoomOutcomeReport.IEntry|null|undefined} disposition
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @instance
             */
            CreateRoomOutcomeReportRequest.prototype.disposition = null;

            /**
             * CreateRoomOutcomeReportRequest customFields.
             * @member {Object.<string,google.protobuf.IValue>} customFields
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @instance
             */
            CreateRoomOutcomeReportRequest.prototype.customFields = $util.emptyObject;

            /**
             * Creates a new CreateRoomOutcomeReportRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {streem.api.ICreateRoomOutcomeReportRequest=} [properties] Properties to set
             * @returns {streem.api.CreateRoomOutcomeReportRequest} CreateRoomOutcomeReportRequest instance
             */
            CreateRoomOutcomeReportRequest.create = function create(properties) {
                return new CreateRoomOutcomeReportRequest(properties);
            };

            /**
             * Encodes the specified CreateRoomOutcomeReportRequest message. Does not implicitly {@link streem.api.CreateRoomOutcomeReportRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {streem.api.ICreateRoomOutcomeReportRequest} m CreateRoomOutcomeReportRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomOutcomeReportRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.diagnoses != null && m.diagnoses.length) {
                    for (var i = 0; i < m.diagnoses.length; ++i)
                        $root.streem.api.Artifact.RoomOutcomeReport.Entry.encode(m.diagnoses[i], w.uint32(18).fork()).ldelim();
                }
                if (m.disposition != null && Object.hasOwnProperty.call(m, "disposition"))
                    $root.streem.api.Artifact.RoomOutcomeReport.Entry.encode(m.disposition, w.uint32(26).fork()).ldelim();
                if (m.customFields != null && Object.hasOwnProperty.call(m, "customFields")) {
                    for (var ks = Object.keys(m.customFields), i = 0; i < ks.length; ++i) {
                        w.uint32(34).fork().uint32(10).string(ks[i]);
                        $root.google.protobuf.Value.encode(m.customFields[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                    }
                }
                return w;
            };

            /**
             * Decodes a CreateRoomOutcomeReportRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomOutcomeReportRequest} CreateRoomOutcomeReportRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomOutcomeReportRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomOutcomeReportRequest(), k, value;
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.diagnoses && m.diagnoses.length))
                                m.diagnoses = [];
                            m.diagnoses.push($root.streem.api.Artifact.RoomOutcomeReport.Entry.decode(r, r.uint32()));
                            break;
                        }
                    case 3: {
                            m.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            if (m.customFields === $util.emptyObject)
                                m.customFields = {};
                            var c2 = r.uint32() + r.pos;
                            k = "";
                            value = null;
                            while (r.pos < c2) {
                                var tag2 = r.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    k = r.string();
                                    break;
                                case 2:
                                    value = $root.google.protobuf.Value.decode(r, r.uint32());
                                    break;
                                default:
                                    r.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            m.customFields[k] = value;
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomOutcomeReportRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomOutcomeReportRequest} CreateRoomOutcomeReportRequest
             */
            CreateRoomOutcomeReportRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomOutcomeReportRequest)
                    return d;
                var m = new $root.streem.api.CreateRoomOutcomeReportRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.diagnoses) {
                    if (!Array.isArray(d.diagnoses))
                        throw TypeError(".streem.api.CreateRoomOutcomeReportRequest.diagnoses: array expected");
                    m.diagnoses = [];
                    for (var i = 0; i < d.diagnoses.length; ++i) {
                        if (typeof d.diagnoses[i] !== "object")
                            throw TypeError(".streem.api.CreateRoomOutcomeReportRequest.diagnoses: object expected");
                        m.diagnoses[i] = $root.streem.api.Artifact.RoomOutcomeReport.Entry.fromObject(d.diagnoses[i]);
                    }
                }
                if (d.disposition != null) {
                    if (typeof d.disposition !== "object")
                        throw TypeError(".streem.api.CreateRoomOutcomeReportRequest.disposition: object expected");
                    m.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.fromObject(d.disposition);
                }
                if (d.customFields) {
                    if (typeof d.customFields !== "object")
                        throw TypeError(".streem.api.CreateRoomOutcomeReportRequest.customFields: object expected");
                    m.customFields = {};
                    for (var ks = Object.keys(d.customFields), i = 0; i < ks.length; ++i) {
                        if (typeof d.customFields[ks[i]] !== "object")
                            throw TypeError(".streem.api.CreateRoomOutcomeReportRequest.customFields: object expected");
                        m.customFields[ks[i]] = $root.google.protobuf.Value.fromObject(d.customFields[ks[i]]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomOutcomeReportRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {streem.api.CreateRoomOutcomeReportRequest} m CreateRoomOutcomeReportRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomOutcomeReportRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.diagnoses = [];
                }
                if (o.objects || o.defaults) {
                    d.customFields = {};
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.disposition = null;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.diagnoses && m.diagnoses.length) {
                    d.diagnoses = [];
                    for (var j = 0; j < m.diagnoses.length; ++j) {
                        d.diagnoses[j] = $root.streem.api.Artifact.RoomOutcomeReport.Entry.toObject(m.diagnoses[j], o);
                    }
                }
                if (m.disposition != null && m.hasOwnProperty("disposition")) {
                    d.disposition = $root.streem.api.Artifact.RoomOutcomeReport.Entry.toObject(m.disposition, o);
                }
                var ks2;
                if (m.customFields && (ks2 = Object.keys(m.customFields)).length) {
                    d.customFields = {};
                    for (var j = 0; j < ks2.length; ++j) {
                        d.customFields[ks2[j]] = $root.google.protobuf.Value.toObject(m.customFields[ks2[j]], o);
                    }
                }
                return d;
            };

            /**
             * Converts this CreateRoomOutcomeReportRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomOutcomeReportRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomOutcomeReportRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomOutcomeReportRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomOutcomeReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomOutcomeReportRequest";
            };

            return CreateRoomOutcomeReportRequest;
        })();

        api.CreateRoomOutcomeReportResponse = (function() {

            /**
             * Properties of a CreateRoomOutcomeReportResponse.
             * @memberof streem.api
             * @interface ICreateRoomOutcomeReportResponse
             * @property {streem.api.IArtifact|null} [roomOutcomeReportArtifact] CreateRoomOutcomeReportResponse roomOutcomeReportArtifact
             */

            /**
             * Constructs a new CreateRoomOutcomeReportResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateRoomOutcomeReportResponse.
             * @implements ICreateRoomOutcomeReportResponse
             * @constructor
             * @param {streem.api.ICreateRoomOutcomeReportResponse=} [p] Properties to set
             */
            function CreateRoomOutcomeReportResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateRoomOutcomeReportResponse roomOutcomeReportArtifact.
             * @member {streem.api.IArtifact|null|undefined} roomOutcomeReportArtifact
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @instance
             */
            CreateRoomOutcomeReportResponse.prototype.roomOutcomeReportArtifact = null;

            /**
             * Creates a new CreateRoomOutcomeReportResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {streem.api.ICreateRoomOutcomeReportResponse=} [properties] Properties to set
             * @returns {streem.api.CreateRoomOutcomeReportResponse} CreateRoomOutcomeReportResponse instance
             */
            CreateRoomOutcomeReportResponse.create = function create(properties) {
                return new CreateRoomOutcomeReportResponse(properties);
            };

            /**
             * Encodes the specified CreateRoomOutcomeReportResponse message. Does not implicitly {@link streem.api.CreateRoomOutcomeReportResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {streem.api.ICreateRoomOutcomeReportResponse} m CreateRoomOutcomeReportResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateRoomOutcomeReportResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomOutcomeReportArtifact != null && Object.hasOwnProperty.call(m, "roomOutcomeReportArtifact"))
                    $root.streem.api.Artifact.encode(m.roomOutcomeReportArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateRoomOutcomeReportResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateRoomOutcomeReportResponse} CreateRoomOutcomeReportResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateRoomOutcomeReportResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateRoomOutcomeReportResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomOutcomeReportArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateRoomOutcomeReportResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateRoomOutcomeReportResponse} CreateRoomOutcomeReportResponse
             */
            CreateRoomOutcomeReportResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateRoomOutcomeReportResponse)
                    return d;
                var m = new $root.streem.api.CreateRoomOutcomeReportResponse();
                if (d.roomOutcomeReportArtifact != null) {
                    if (typeof d.roomOutcomeReportArtifact !== "object")
                        throw TypeError(".streem.api.CreateRoomOutcomeReportResponse.roomOutcomeReportArtifact: object expected");
                    m.roomOutcomeReportArtifact = $root.streem.api.Artifact.fromObject(d.roomOutcomeReportArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateRoomOutcomeReportResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {streem.api.CreateRoomOutcomeReportResponse} m CreateRoomOutcomeReportResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateRoomOutcomeReportResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomOutcomeReportArtifact = null;
                }
                if (m.roomOutcomeReportArtifact != null && m.hasOwnProperty("roomOutcomeReportArtifact")) {
                    d.roomOutcomeReportArtifact = $root.streem.api.Artifact.toObject(m.roomOutcomeReportArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateRoomOutcomeReportResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateRoomOutcomeReportResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateRoomOutcomeReportResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateRoomOutcomeReportResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateRoomOutcomeReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateRoomOutcomeReportResponse";
            };

            return CreateRoomOutcomeReportResponse;
        })();

        api.FulfillRecordingRequest = (function() {

            /**
             * Properties of a FulfillRecordingRequest.
             * @memberof streem.api
             * @interface IFulfillRecordingRequest
             * @property {string|null} [storageUrl] FulfillRecordingRequest storageUrl
             */

            /**
             * Constructs a new FulfillRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingRequest.
             * @implements IFulfillRecordingRequest
             * @constructor
             * @param {streem.api.IFulfillRecordingRequest=} [p] Properties to set
             */
            function FulfillRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingRequest storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.FulfillRecordingRequest
             * @instance
             */
            FulfillRecordingRequest.prototype.storageUrl = "";

            /**
             * Creates a new FulfillRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {streem.api.IFulfillRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingRequest} FulfillRecordingRequest instance
             */
            FulfillRecordingRequest.create = function create(properties) {
                return new FulfillRecordingRequest(properties);
            };

            /**
             * Encodes the specified FulfillRecordingRequest message. Does not implicitly {@link streem.api.FulfillRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {streem.api.IFulfillRecordingRequest} m FulfillRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(10).string(m.storageUrl);
                return w;
            };

            /**
             * Decodes a FulfillRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingRequest} FulfillRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.storageUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingRequest} FulfillRecordingRequest
             */
            FulfillRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingRequest)
                    return d;
                var m = new $root.streem.api.FulfillRecordingRequest();
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {streem.api.FulfillRecordingRequest} m FulfillRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storageUrl = "";
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingRequest";
            };

            return FulfillRecordingRequest;
        })();

        api.FulfillRecordingResponse = (function() {

            /**
             * Properties of a FulfillRecordingResponse.
             * @memberof streem.api
             * @interface IFulfillRecordingResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] FulfillRecordingResponse recordingArtifact
             */

            /**
             * Constructs a new FulfillRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingResponse.
             * @implements IFulfillRecordingResponse
             * @constructor
             * @param {streem.api.IFulfillRecordingResponse=} [p] Properties to set
             */
            function FulfillRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.FulfillRecordingResponse
             * @instance
             */
            FulfillRecordingResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new FulfillRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {streem.api.IFulfillRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingResponse} FulfillRecordingResponse instance
             */
            FulfillRecordingResponse.create = function create(properties) {
                return new FulfillRecordingResponse(properties);
            };

            /**
             * Encodes the specified FulfillRecordingResponse message. Does not implicitly {@link streem.api.FulfillRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {streem.api.IFulfillRecordingResponse} m FulfillRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingResponse} FulfillRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingResponse} FulfillRecordingResponse
             */
            FulfillRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingResponse)
                    return d;
                var m = new $root.streem.api.FulfillRecordingResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.FulfillRecordingResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {streem.api.FulfillRecordingResponse} m FulfillRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingResponse";
            };

            return FulfillRecordingResponse;
        })();

        api.CreateStreemshotRequest = (function() {

            /**
             * Properties of a CreateStreemshotRequest.
             * @memberof streem.api
             * @interface ICreateStreemshotRequest
             * @property {string|null} [roomSid] CreateStreemshotRequest roomSid
             * @property {string|null} [streemshotSid] CreateStreemshotRequest streemshotSid
             * @property {google.protobuf.ITimestamp|null} [requestedAt] CreateStreemshotRequest requestedAt
             * @property {string|null} [requestedByUserSid] CreateStreemshotRequest requestedByUserSid
             * @property {string|null} [requestedByDeviceSid] CreateStreemshotRequest requestedByDeviceSid
             * @property {string|null} [requestedForUserSid] CreateStreemshotRequest requestedForUserSid
             * @property {string|null} [requestedForDeviceSid] CreateStreemshotRequest requestedForDeviceSid
             */

            /**
             * Constructs a new CreateStreemshotRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemshotRequest.
             * @implements ICreateStreemshotRequest
             * @constructor
             * @param {streem.api.ICreateStreemshotRequest=} [p] Properties to set
             */
            function CreateStreemshotRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateStreemshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.roomSid = "";

            /**
             * CreateStreemshotRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.streemshotSid = "";

            /**
             * CreateStreemshotRequest requestedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} requestedAt
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.requestedAt = null;

            /**
             * CreateStreemshotRequest requestedByUserSid.
             * @member {string} requestedByUserSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.requestedByUserSid = "";

            /**
             * CreateStreemshotRequest requestedByDeviceSid.
             * @member {string} requestedByDeviceSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.requestedByDeviceSid = "";

            /**
             * CreateStreemshotRequest requestedForUserSid.
             * @member {string} requestedForUserSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.requestedForUserSid = "";

            /**
             * CreateStreemshotRequest requestedForDeviceSid.
             * @member {string} requestedForDeviceSid
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             */
            CreateStreemshotRequest.prototype.requestedForDeviceSid = "";

            /**
             * Creates a new CreateStreemshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {streem.api.ICreateStreemshotRequest=} [properties] Properties to set
             * @returns {streem.api.CreateStreemshotRequest} CreateStreemshotRequest instance
             */
            CreateStreemshotRequest.create = function create(properties) {
                return new CreateStreemshotRequest(properties);
            };

            /**
             * Encodes the specified CreateStreemshotRequest message. Does not implicitly {@link streem.api.CreateStreemshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {streem.api.ICreateStreemshotRequest} m CreateStreemshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                if (m.requestedAt != null && Object.hasOwnProperty.call(m, "requestedAt"))
                    $root.google.protobuf.Timestamp.encode(m.requestedAt, w.uint32(26).fork()).ldelim();
                if (m.requestedByUserSid != null && Object.hasOwnProperty.call(m, "requestedByUserSid"))
                    w.uint32(34).string(m.requestedByUserSid);
                if (m.requestedByDeviceSid != null && Object.hasOwnProperty.call(m, "requestedByDeviceSid"))
                    w.uint32(42).string(m.requestedByDeviceSid);
                if (m.requestedForUserSid != null && Object.hasOwnProperty.call(m, "requestedForUserSid"))
                    w.uint32(50).string(m.requestedForUserSid);
                if (m.requestedForDeviceSid != null && Object.hasOwnProperty.call(m, "requestedForDeviceSid"))
                    w.uint32(58).string(m.requestedForDeviceSid);
                return w;
            };

            /**
             * Decodes a CreateStreemshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemshotRequest} CreateStreemshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    case 3: {
                            m.requestedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.requestedByUserSid = r.string();
                            break;
                        }
                    case 5: {
                            m.requestedByDeviceSid = r.string();
                            break;
                        }
                    case 6: {
                            m.requestedForUserSid = r.string();
                            break;
                        }
                    case 7: {
                            m.requestedForDeviceSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemshotRequest} CreateStreemshotRequest
             */
            CreateStreemshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemshotRequest)
                    return d;
                var m = new $root.streem.api.CreateStreemshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                if (d.requestedAt != null) {
                    if (typeof d.requestedAt !== "object")
                        throw TypeError(".streem.api.CreateStreemshotRequest.requestedAt: object expected");
                    m.requestedAt = $root.google.protobuf.Timestamp.fromObject(d.requestedAt);
                }
                if (d.requestedByUserSid != null) {
                    m.requestedByUserSid = String(d.requestedByUserSid);
                }
                if (d.requestedByDeviceSid != null) {
                    m.requestedByDeviceSid = String(d.requestedByDeviceSid);
                }
                if (d.requestedForUserSid != null) {
                    m.requestedForUserSid = String(d.requestedForUserSid);
                }
                if (d.requestedForDeviceSid != null) {
                    m.requestedForDeviceSid = String(d.requestedForDeviceSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateStreemshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {streem.api.CreateStreemshotRequest} m CreateStreemshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                    d.requestedAt = null;
                    d.requestedByUserSid = "";
                    d.requestedByDeviceSid = "";
                    d.requestedForUserSid = "";
                    d.requestedForDeviceSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                if (m.requestedAt != null && m.hasOwnProperty("requestedAt")) {
                    d.requestedAt = $root.google.protobuf.Timestamp.toObject(m.requestedAt, o);
                }
                if (m.requestedByUserSid != null && m.hasOwnProperty("requestedByUserSid")) {
                    d.requestedByUserSid = m.requestedByUserSid;
                }
                if (m.requestedByDeviceSid != null && m.hasOwnProperty("requestedByDeviceSid")) {
                    d.requestedByDeviceSid = m.requestedByDeviceSid;
                }
                if (m.requestedForUserSid != null && m.hasOwnProperty("requestedForUserSid")) {
                    d.requestedForUserSid = m.requestedForUserSid;
                }
                if (m.requestedForDeviceSid != null && m.hasOwnProperty("requestedForDeviceSid")) {
                    d.requestedForDeviceSid = m.requestedForDeviceSid;
                }
                return d;
            };

            /**
             * Converts this CreateStreemshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemshotRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemshotRequest";
            };

            return CreateStreemshotRequest;
        })();

        api.CreateStreemshotResponse = (function() {

            /**
             * Properties of a CreateStreemshotResponse.
             * @memberof streem.api
             * @interface ICreateStreemshotResponse
             * @property {streem.api.IArtifact|null} [streemshotArtifact] CreateStreemshotResponse streemshotArtifact
             */

            /**
             * Constructs a new CreateStreemshotResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemshotResponse.
             * @implements ICreateStreemshotResponse
             * @constructor
             * @param {streem.api.ICreateStreemshotResponse=} [p] Properties to set
             */
            function CreateStreemshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateStreemshotResponse streemshotArtifact.
             * @member {streem.api.IArtifact|null|undefined} streemshotArtifact
             * @memberof streem.api.CreateStreemshotResponse
             * @instance
             */
            CreateStreemshotResponse.prototype.streemshotArtifact = null;

            /**
             * Creates a new CreateStreemshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {streem.api.ICreateStreemshotResponse=} [properties] Properties to set
             * @returns {streem.api.CreateStreemshotResponse} CreateStreemshotResponse instance
             */
            CreateStreemshotResponse.create = function create(properties) {
                return new CreateStreemshotResponse(properties);
            };

            /**
             * Encodes the specified CreateStreemshotResponse message. Does not implicitly {@link streem.api.CreateStreemshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {streem.api.ICreateStreemshotResponse} m CreateStreemshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotArtifact != null && Object.hasOwnProperty.call(m, "streemshotArtifact"))
                    $root.streem.api.Artifact.encode(m.streemshotArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateStreemshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemshotResponse} CreateStreemshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemshotArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemshotResponse} CreateStreemshotResponse
             */
            CreateStreemshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemshotResponse)
                    return d;
                var m = new $root.streem.api.CreateStreemshotResponse();
                if (d.streemshotArtifact != null) {
                    if (typeof d.streemshotArtifact !== "object")
                        throw TypeError(".streem.api.CreateStreemshotResponse.streemshotArtifact: object expected");
                    m.streemshotArtifact = $root.streem.api.Artifact.fromObject(d.streemshotArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateStreemshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {streem.api.CreateStreemshotResponse} m CreateStreemshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemshotResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemshotArtifact = null;
                }
                if (m.streemshotArtifact != null && m.hasOwnProperty("streemshotArtifact")) {
                    d.streemshotArtifact = $root.streem.api.Artifact.toObject(m.streemshotArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateStreemshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemshotResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemshotResponse";
            };

            return CreateStreemshotResponse;
        })();

        api.CreateStreemshotRevisionRequest = (function() {

            /**
             * Properties of a CreateStreemshotRevisionRequest.
             * @memberof streem.api
             * @interface ICreateStreemshotRevisionRequest
             * @property {string|null} [roomSid] CreateStreemshotRevisionRequest roomSid
             * @property {string|null} [streemshotSid] CreateStreemshotRevisionRequest streemshotSid
             * @property {string|null} [storageUrl] CreateStreemshotRevisionRequest storageUrl
             * @property {Array.<streem.api.IArElement>|null} [arElements] CreateStreemshotRevisionRequest arElements
             * @property {boolean|null} [edited] CreateStreemshotRevisionRequest edited
             */

            /**
             * Constructs a new CreateStreemshotRevisionRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemshotRevisionRequest.
             * @implements ICreateStreemshotRevisionRequest
             * @constructor
             * @param {streem.api.ICreateStreemshotRevisionRequest=} [p] Properties to set
             */
            function CreateStreemshotRevisionRequest(p) {
                this.arElements = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateStreemshotRevisionRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             */
            CreateStreemshotRevisionRequest.prototype.roomSid = "";

            /**
             * CreateStreemshotRevisionRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             */
            CreateStreemshotRevisionRequest.prototype.streemshotSid = "";

            /**
             * CreateStreemshotRevisionRequest storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             */
            CreateStreemshotRevisionRequest.prototype.storageUrl = "";

            /**
             * CreateStreemshotRevisionRequest arElements.
             * @member {Array.<streem.api.IArElement>} arElements
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             */
            CreateStreemshotRevisionRequest.prototype.arElements = $util.emptyArray;

            /**
             * CreateStreemshotRevisionRequest edited.
             * @member {boolean} edited
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             */
            CreateStreemshotRevisionRequest.prototype.edited = false;

            /**
             * Creates a new CreateStreemshotRevisionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {streem.api.ICreateStreemshotRevisionRequest=} [properties] Properties to set
             * @returns {streem.api.CreateStreemshotRevisionRequest} CreateStreemshotRevisionRequest instance
             */
            CreateStreemshotRevisionRequest.create = function create(properties) {
                return new CreateStreemshotRevisionRequest(properties);
            };

            /**
             * Encodes the specified CreateStreemshotRevisionRequest message. Does not implicitly {@link streem.api.CreateStreemshotRevisionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {streem.api.ICreateStreemshotRevisionRequest} m CreateStreemshotRevisionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemshotRevisionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(26).string(m.storageUrl);
                if (m.arElements != null && m.arElements.length) {
                    for (var i = 0; i < m.arElements.length; ++i)
                        $root.streem.api.ArElement.encode(m.arElements[i], w.uint32(34).fork()).ldelim();
                }
                if (m.edited != null && Object.hasOwnProperty.call(m, "edited"))
                    w.uint32(40).bool(m.edited);
                return w;
            };

            /**
             * Decodes a CreateStreemshotRevisionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemshotRevisionRequest} CreateStreemshotRevisionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemshotRevisionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemshotRevisionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    case 3: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 4: {
                            if (!(m.arElements && m.arElements.length))
                                m.arElements = [];
                            m.arElements.push($root.streem.api.ArElement.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            m.edited = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemshotRevisionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemshotRevisionRequest} CreateStreemshotRevisionRequest
             */
            CreateStreemshotRevisionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemshotRevisionRequest)
                    return d;
                var m = new $root.streem.api.CreateStreemshotRevisionRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                if (d.arElements) {
                    if (!Array.isArray(d.arElements))
                        throw TypeError(".streem.api.CreateStreemshotRevisionRequest.arElements: array expected");
                    m.arElements = [];
                    for (var i = 0; i < d.arElements.length; ++i) {
                        if (typeof d.arElements[i] !== "object")
                            throw TypeError(".streem.api.CreateStreemshotRevisionRequest.arElements: object expected");
                        m.arElements[i] = $root.streem.api.ArElement.fromObject(d.arElements[i]);
                    }
                }
                if (d.edited != null) {
                    m.edited = Boolean(d.edited);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateStreemshotRevisionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {streem.api.CreateStreemshotRevisionRequest} m CreateStreemshotRevisionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemshotRevisionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.arElements = [];
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                    d.storageUrl = "";
                    d.edited = false;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.arElements && m.arElements.length) {
                    d.arElements = [];
                    for (var j = 0; j < m.arElements.length; ++j) {
                        d.arElements[j] = $root.streem.api.ArElement.toObject(m.arElements[j], o);
                    }
                }
                if (m.edited != null && m.hasOwnProperty("edited")) {
                    d.edited = m.edited;
                }
                return d;
            };

            /**
             * Converts this CreateStreemshotRevisionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemshotRevisionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemshotRevisionRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemshotRevisionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemshotRevisionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemshotRevisionRequest";
            };

            return CreateStreemshotRevisionRequest;
        })();

        api.CreateStreemshotRevisionResponse = (function() {

            /**
             * Properties of a CreateStreemshotRevisionResponse.
             * @memberof streem.api
             * @interface ICreateStreemshotRevisionResponse
             * @property {streem.api.IArtifact|null} [streemshotArtifact] CreateStreemshotRevisionResponse streemshotArtifact
             */

            /**
             * Constructs a new CreateStreemshotRevisionResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateStreemshotRevisionResponse.
             * @implements ICreateStreemshotRevisionResponse
             * @constructor
             * @param {streem.api.ICreateStreemshotRevisionResponse=} [p] Properties to set
             */
            function CreateStreemshotRevisionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateStreemshotRevisionResponse streemshotArtifact.
             * @member {streem.api.IArtifact|null|undefined} streemshotArtifact
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @instance
             */
            CreateStreemshotRevisionResponse.prototype.streemshotArtifact = null;

            /**
             * Creates a new CreateStreemshotRevisionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {streem.api.ICreateStreemshotRevisionResponse=} [properties] Properties to set
             * @returns {streem.api.CreateStreemshotRevisionResponse} CreateStreemshotRevisionResponse instance
             */
            CreateStreemshotRevisionResponse.create = function create(properties) {
                return new CreateStreemshotRevisionResponse(properties);
            };

            /**
             * Encodes the specified CreateStreemshotRevisionResponse message. Does not implicitly {@link streem.api.CreateStreemshotRevisionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {streem.api.ICreateStreemshotRevisionResponse} m CreateStreemshotRevisionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateStreemshotRevisionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotArtifact != null && Object.hasOwnProperty.call(m, "streemshotArtifact"))
                    $root.streem.api.Artifact.encode(m.streemshotArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateStreemshotRevisionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateStreemshotRevisionResponse} CreateStreemshotRevisionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateStreemshotRevisionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateStreemshotRevisionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemshotArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateStreemshotRevisionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateStreemshotRevisionResponse} CreateStreemshotRevisionResponse
             */
            CreateStreemshotRevisionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateStreemshotRevisionResponse)
                    return d;
                var m = new $root.streem.api.CreateStreemshotRevisionResponse();
                if (d.streemshotArtifact != null) {
                    if (typeof d.streemshotArtifact !== "object")
                        throw TypeError(".streem.api.CreateStreemshotRevisionResponse.streemshotArtifact: object expected");
                    m.streemshotArtifact = $root.streem.api.Artifact.fromObject(d.streemshotArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateStreemshotRevisionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {streem.api.CreateStreemshotRevisionResponse} m CreateStreemshotRevisionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateStreemshotRevisionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemshotArtifact = null;
                }
                if (m.streemshotArtifact != null && m.hasOwnProperty("streemshotArtifact")) {
                    d.streemshotArtifact = $root.streem.api.Artifact.toObject(m.streemshotArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateStreemshotRevisionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateStreemshotRevisionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateStreemshotRevisionResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateStreemshotRevisionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateStreemshotRevisionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateStreemshotRevisionResponse";
            };

            return CreateStreemshotRevisionResponse;
        })();

        api.CreateTexturedMeshRequest = (function() {

            /**
             * Properties of a CreateTexturedMeshRequest.
             * @memberof streem.api
             * @interface ICreateTexturedMeshRequest
             * @property {string|null} [roomSid] CreateTexturedMeshRequest roomSid
             * @property {string|null} [texturedMeshSid] CreateTexturedMeshRequest texturedMeshSid
             * @property {string|null} [meshSid] CreateTexturedMeshRequest meshSid
             * @property {string|null} [videoRecordingTrackSid] CreateTexturedMeshRequest videoRecordingTrackSid
             */

            /**
             * Constructs a new CreateTexturedMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateTexturedMeshRequest.
             * @implements ICreateTexturedMeshRequest
             * @constructor
             * @param {streem.api.ICreateTexturedMeshRequest=} [p] Properties to set
             */
            function CreateTexturedMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateTexturedMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CreateTexturedMeshRequest
             * @instance
             */
            CreateTexturedMeshRequest.prototype.roomSid = "";

            /**
             * CreateTexturedMeshRequest texturedMeshSid.
             * @member {string} texturedMeshSid
             * @memberof streem.api.CreateTexturedMeshRequest
             * @instance
             */
            CreateTexturedMeshRequest.prototype.texturedMeshSid = "";

            /**
             * CreateTexturedMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.CreateTexturedMeshRequest
             * @instance
             */
            CreateTexturedMeshRequest.prototype.meshSid = "";

            /**
             * CreateTexturedMeshRequest videoRecordingTrackSid.
             * @member {string} videoRecordingTrackSid
             * @memberof streem.api.CreateTexturedMeshRequest
             * @instance
             */
            CreateTexturedMeshRequest.prototype.videoRecordingTrackSid = "";

            /**
             * Creates a new CreateTexturedMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {streem.api.ICreateTexturedMeshRequest=} [properties] Properties to set
             * @returns {streem.api.CreateTexturedMeshRequest} CreateTexturedMeshRequest instance
             */
            CreateTexturedMeshRequest.create = function create(properties) {
                return new CreateTexturedMeshRequest(properties);
            };

            /**
             * Encodes the specified CreateTexturedMeshRequest message. Does not implicitly {@link streem.api.CreateTexturedMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {streem.api.ICreateTexturedMeshRequest} m CreateTexturedMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTexturedMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.texturedMeshSid != null && Object.hasOwnProperty.call(m, "texturedMeshSid"))
                    w.uint32(18).string(m.texturedMeshSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(26).string(m.meshSid);
                if (m.videoRecordingTrackSid != null && Object.hasOwnProperty.call(m, "videoRecordingTrackSid"))
                    w.uint32(34).string(m.videoRecordingTrackSid);
                return w;
            };

            /**
             * Decodes a CreateTexturedMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateTexturedMeshRequest} CreateTexturedMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTexturedMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateTexturedMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.texturedMeshSid = r.string();
                            break;
                        }
                    case 3: {
                            m.meshSid = r.string();
                            break;
                        }
                    case 4: {
                            m.videoRecordingTrackSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateTexturedMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateTexturedMeshRequest} CreateTexturedMeshRequest
             */
            CreateTexturedMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateTexturedMeshRequest)
                    return d;
                var m = new $root.streem.api.CreateTexturedMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.texturedMeshSid != null) {
                    m.texturedMeshSid = String(d.texturedMeshSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                if (d.videoRecordingTrackSid != null) {
                    m.videoRecordingTrackSid = String(d.videoRecordingTrackSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateTexturedMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {streem.api.CreateTexturedMeshRequest} m CreateTexturedMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateTexturedMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.texturedMeshSid = "";
                    d.meshSid = "";
                    d.videoRecordingTrackSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.texturedMeshSid != null && m.hasOwnProperty("texturedMeshSid")) {
                    d.texturedMeshSid = m.texturedMeshSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                if (m.videoRecordingTrackSid != null && m.hasOwnProperty("videoRecordingTrackSid")) {
                    d.videoRecordingTrackSid = m.videoRecordingTrackSid;
                }
                return d;
            };

            /**
             * Converts this CreateTexturedMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateTexturedMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateTexturedMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateTexturedMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateTexturedMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateTexturedMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateTexturedMeshRequest";
            };

            return CreateTexturedMeshRequest;
        })();

        api.CreateTexturedMeshResponse = (function() {

            /**
             * Properties of a CreateTexturedMeshResponse.
             * @memberof streem.api
             * @interface ICreateTexturedMeshResponse
             * @property {streem.api.IArtifact|null} [texturedMeshArtifact] CreateTexturedMeshResponse texturedMeshArtifact
             */

            /**
             * Constructs a new CreateTexturedMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateTexturedMeshResponse.
             * @implements ICreateTexturedMeshResponse
             * @constructor
             * @param {streem.api.ICreateTexturedMeshResponse=} [p] Properties to set
             */
            function CreateTexturedMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateTexturedMeshResponse texturedMeshArtifact.
             * @member {streem.api.IArtifact|null|undefined} texturedMeshArtifact
             * @memberof streem.api.CreateTexturedMeshResponse
             * @instance
             */
            CreateTexturedMeshResponse.prototype.texturedMeshArtifact = null;

            /**
             * Creates a new CreateTexturedMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {streem.api.ICreateTexturedMeshResponse=} [properties] Properties to set
             * @returns {streem.api.CreateTexturedMeshResponse} CreateTexturedMeshResponse instance
             */
            CreateTexturedMeshResponse.create = function create(properties) {
                return new CreateTexturedMeshResponse(properties);
            };

            /**
             * Encodes the specified CreateTexturedMeshResponse message. Does not implicitly {@link streem.api.CreateTexturedMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {streem.api.ICreateTexturedMeshResponse} m CreateTexturedMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTexturedMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.texturedMeshArtifact != null && Object.hasOwnProperty.call(m, "texturedMeshArtifact"))
                    $root.streem.api.Artifact.encode(m.texturedMeshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateTexturedMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateTexturedMeshResponse} CreateTexturedMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTexturedMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateTexturedMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.texturedMeshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateTexturedMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateTexturedMeshResponse} CreateTexturedMeshResponse
             */
            CreateTexturedMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateTexturedMeshResponse)
                    return d;
                var m = new $root.streem.api.CreateTexturedMeshResponse();
                if (d.texturedMeshArtifact != null) {
                    if (typeof d.texturedMeshArtifact !== "object")
                        throw TypeError(".streem.api.CreateTexturedMeshResponse.texturedMeshArtifact: object expected");
                    m.texturedMeshArtifact = $root.streem.api.Artifact.fromObject(d.texturedMeshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateTexturedMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {streem.api.CreateTexturedMeshResponse} m CreateTexturedMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateTexturedMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.texturedMeshArtifact = null;
                }
                if (m.texturedMeshArtifact != null && m.hasOwnProperty("texturedMeshArtifact")) {
                    d.texturedMeshArtifact = $root.streem.api.Artifact.toObject(m.texturedMeshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this CreateTexturedMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateTexturedMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateTexturedMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateTexturedMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateTexturedMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateTexturedMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateTexturedMeshResponse";
            };

            return CreateTexturedMeshResponse;
        })();

        api.DeleteBookmarkRequest = (function() {

            /**
             * Properties of a DeleteBookmarkRequest.
             * @memberof streem.api
             * @interface IDeleteBookmarkRequest
             * @property {string|null} [roomSid] DeleteBookmarkRequest roomSid
             * @property {string|null} [bookmarkSid] DeleteBookmarkRequest bookmarkSid
             */

            /**
             * Constructs a new DeleteBookmarkRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteBookmarkRequest.
             * @implements IDeleteBookmarkRequest
             * @constructor
             * @param {streem.api.IDeleteBookmarkRequest=} [p] Properties to set
             */
            function DeleteBookmarkRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteBookmarkRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteBookmarkRequest
             * @instance
             */
            DeleteBookmarkRequest.prototype.roomSid = "";

            /**
             * DeleteBookmarkRequest bookmarkSid.
             * @member {string} bookmarkSid
             * @memberof streem.api.DeleteBookmarkRequest
             * @instance
             */
            DeleteBookmarkRequest.prototype.bookmarkSid = "";

            /**
             * Creates a new DeleteBookmarkRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {streem.api.IDeleteBookmarkRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteBookmarkRequest} DeleteBookmarkRequest instance
             */
            DeleteBookmarkRequest.create = function create(properties) {
                return new DeleteBookmarkRequest(properties);
            };

            /**
             * Encodes the specified DeleteBookmarkRequest message. Does not implicitly {@link streem.api.DeleteBookmarkRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {streem.api.IDeleteBookmarkRequest} m DeleteBookmarkRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteBookmarkRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.bookmarkSid != null && Object.hasOwnProperty.call(m, "bookmarkSid"))
                    w.uint32(18).string(m.bookmarkSid);
                return w;
            };

            /**
             * Decodes a DeleteBookmarkRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteBookmarkRequest} DeleteBookmarkRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteBookmarkRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteBookmarkRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.bookmarkSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteBookmarkRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteBookmarkRequest} DeleteBookmarkRequest
             */
            DeleteBookmarkRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteBookmarkRequest)
                    return d;
                var m = new $root.streem.api.DeleteBookmarkRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.bookmarkSid != null) {
                    m.bookmarkSid = String(d.bookmarkSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteBookmarkRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {streem.api.DeleteBookmarkRequest} m DeleteBookmarkRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteBookmarkRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.bookmarkSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.bookmarkSid != null && m.hasOwnProperty("bookmarkSid")) {
                    d.bookmarkSid = m.bookmarkSid;
                }
                return d;
            };

            /**
             * Converts this DeleteBookmarkRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteBookmarkRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteBookmarkRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteBookmarkRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteBookmarkRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteBookmarkRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteBookmarkRequest";
            };

            return DeleteBookmarkRequest;
        })();

        api.DeleteBookmarkResponse = (function() {

            /**
             * Properties of a DeleteBookmarkResponse.
             * @memberof streem.api
             * @interface IDeleteBookmarkResponse
             */

            /**
             * Constructs a new DeleteBookmarkResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteBookmarkResponse.
             * @implements IDeleteBookmarkResponse
             * @constructor
             * @param {streem.api.IDeleteBookmarkResponse=} [p] Properties to set
             */
            function DeleteBookmarkResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteBookmarkResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {streem.api.IDeleteBookmarkResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteBookmarkResponse} DeleteBookmarkResponse instance
             */
            DeleteBookmarkResponse.create = function create(properties) {
                return new DeleteBookmarkResponse(properties);
            };

            /**
             * Encodes the specified DeleteBookmarkResponse message. Does not implicitly {@link streem.api.DeleteBookmarkResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {streem.api.IDeleteBookmarkResponse} m DeleteBookmarkResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteBookmarkResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteBookmarkResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteBookmarkResponse} DeleteBookmarkResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteBookmarkResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteBookmarkResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteBookmarkResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteBookmarkResponse} DeleteBookmarkResponse
             */
            DeleteBookmarkResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteBookmarkResponse)
                    return d;
                return new $root.streem.api.DeleteBookmarkResponse();
            };

            /**
             * Creates a plain object from a DeleteBookmarkResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {streem.api.DeleteBookmarkResponse} m DeleteBookmarkResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteBookmarkResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteBookmarkResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteBookmarkResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteBookmarkResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteBookmarkResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteBookmarkResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteBookmarkResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteBookmarkResponse";
            };

            return DeleteBookmarkResponse;
        })();

        api.DeleteGpsPositionRequest = (function() {

            /**
             * Properties of a DeleteGpsPositionRequest.
             * @memberof streem.api
             * @interface IDeleteGpsPositionRequest
             * @property {string|null} [roomSid] DeleteGpsPositionRequest roomSid
             * @property {string|null} [gpsPositionSid] DeleteGpsPositionRequest gpsPositionSid
             */

            /**
             * Constructs a new DeleteGpsPositionRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteGpsPositionRequest.
             * @implements IDeleteGpsPositionRequest
             * @constructor
             * @param {streem.api.IDeleteGpsPositionRequest=} [p] Properties to set
             */
            function DeleteGpsPositionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteGpsPositionRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteGpsPositionRequest
             * @instance
             */
            DeleteGpsPositionRequest.prototype.roomSid = "";

            /**
             * DeleteGpsPositionRequest gpsPositionSid.
             * @member {string} gpsPositionSid
             * @memberof streem.api.DeleteGpsPositionRequest
             * @instance
             */
            DeleteGpsPositionRequest.prototype.gpsPositionSid = "";

            /**
             * Creates a new DeleteGpsPositionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {streem.api.IDeleteGpsPositionRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteGpsPositionRequest} DeleteGpsPositionRequest instance
             */
            DeleteGpsPositionRequest.create = function create(properties) {
                return new DeleteGpsPositionRequest(properties);
            };

            /**
             * Encodes the specified DeleteGpsPositionRequest message. Does not implicitly {@link streem.api.DeleteGpsPositionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {streem.api.IDeleteGpsPositionRequest} m DeleteGpsPositionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGpsPositionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.gpsPositionSid != null && Object.hasOwnProperty.call(m, "gpsPositionSid"))
                    w.uint32(18).string(m.gpsPositionSid);
                return w;
            };

            /**
             * Decodes a DeleteGpsPositionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteGpsPositionRequest} DeleteGpsPositionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGpsPositionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteGpsPositionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.gpsPositionSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteGpsPositionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteGpsPositionRequest} DeleteGpsPositionRequest
             */
            DeleteGpsPositionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteGpsPositionRequest)
                    return d;
                var m = new $root.streem.api.DeleteGpsPositionRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.gpsPositionSid != null) {
                    m.gpsPositionSid = String(d.gpsPositionSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteGpsPositionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {streem.api.DeleteGpsPositionRequest} m DeleteGpsPositionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGpsPositionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.gpsPositionSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.gpsPositionSid != null && m.hasOwnProperty("gpsPositionSid")) {
                    d.gpsPositionSid = m.gpsPositionSid;
                }
                return d;
            };

            /**
             * Converts this DeleteGpsPositionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteGpsPositionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGpsPositionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteGpsPositionRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteGpsPositionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteGpsPositionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteGpsPositionRequest";
            };

            return DeleteGpsPositionRequest;
        })();

        api.DeleteGpsPositionResponse = (function() {

            /**
             * Properties of a DeleteGpsPositionResponse.
             * @memberof streem.api
             * @interface IDeleteGpsPositionResponse
             */

            /**
             * Constructs a new DeleteGpsPositionResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteGpsPositionResponse.
             * @implements IDeleteGpsPositionResponse
             * @constructor
             * @param {streem.api.IDeleteGpsPositionResponse=} [p] Properties to set
             */
            function DeleteGpsPositionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteGpsPositionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {streem.api.IDeleteGpsPositionResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteGpsPositionResponse} DeleteGpsPositionResponse instance
             */
            DeleteGpsPositionResponse.create = function create(properties) {
                return new DeleteGpsPositionResponse(properties);
            };

            /**
             * Encodes the specified DeleteGpsPositionResponse message. Does not implicitly {@link streem.api.DeleteGpsPositionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {streem.api.IDeleteGpsPositionResponse} m DeleteGpsPositionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGpsPositionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteGpsPositionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteGpsPositionResponse} DeleteGpsPositionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGpsPositionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteGpsPositionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteGpsPositionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteGpsPositionResponse} DeleteGpsPositionResponse
             */
            DeleteGpsPositionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteGpsPositionResponse)
                    return d;
                return new $root.streem.api.DeleteGpsPositionResponse();
            };

            /**
             * Creates a plain object from a DeleteGpsPositionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {streem.api.DeleteGpsPositionResponse} m DeleteGpsPositionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGpsPositionResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteGpsPositionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteGpsPositionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGpsPositionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteGpsPositionResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteGpsPositionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteGpsPositionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteGpsPositionResponse";
            };

            return DeleteGpsPositionResponse;
        })();

        api.DeleteLayoutEstimationRequest = (function() {

            /**
             * Properties of a DeleteLayoutEstimationRequest.
             * @memberof streem.api
             * @interface IDeleteLayoutEstimationRequest
             * @property {string|null} [roomSid] DeleteLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] DeleteLayoutEstimationRequest layoutEstimationSid
             */

            /**
             * Constructs a new DeleteLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteLayoutEstimationRequest.
             * @implements IDeleteLayoutEstimationRequest
             * @constructor
             * @param {streem.api.IDeleteLayoutEstimationRequest=} [p] Properties to set
             */
            function DeleteLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @instance
             */
            DeleteLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * DeleteLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @instance
             */
            DeleteLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * Creates a new DeleteLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {streem.api.IDeleteLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteLayoutEstimationRequest} DeleteLayoutEstimationRequest instance
             */
            DeleteLayoutEstimationRequest.create = function create(properties) {
                return new DeleteLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified DeleteLayoutEstimationRequest message. Does not implicitly {@link streem.api.DeleteLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {streem.api.IDeleteLayoutEstimationRequest} m DeleteLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                return w;
            };

            /**
             * Decodes a DeleteLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteLayoutEstimationRequest} DeleteLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteLayoutEstimationRequest} DeleteLayoutEstimationRequest
             */
            DeleteLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.DeleteLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {streem.api.DeleteLayoutEstimationRequest} m DeleteLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                return d;
            };

            /**
             * Converts this DeleteLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteLayoutEstimationRequest";
            };

            return DeleteLayoutEstimationRequest;
        })();

        api.DeleteLayoutEstimationResponse = (function() {

            /**
             * Properties of a DeleteLayoutEstimationResponse.
             * @memberof streem.api
             * @interface IDeleteLayoutEstimationResponse
             */

            /**
             * Constructs a new DeleteLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteLayoutEstimationResponse.
             * @implements IDeleteLayoutEstimationResponse
             * @constructor
             * @param {streem.api.IDeleteLayoutEstimationResponse=} [p] Properties to set
             */
            function DeleteLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {streem.api.IDeleteLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteLayoutEstimationResponse} DeleteLayoutEstimationResponse instance
             */
            DeleteLayoutEstimationResponse.create = function create(properties) {
                return new DeleteLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified DeleteLayoutEstimationResponse message. Does not implicitly {@link streem.api.DeleteLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {streem.api.IDeleteLayoutEstimationResponse} m DeleteLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteLayoutEstimationResponse} DeleteLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteLayoutEstimationResponse} DeleteLayoutEstimationResponse
             */
            DeleteLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteLayoutEstimationResponse)
                    return d;
                return new $root.streem.api.DeleteLayoutEstimationResponse();
            };

            /**
             * Creates a plain object from a DeleteLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {streem.api.DeleteLayoutEstimationResponse} m DeleteLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteLayoutEstimationResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteLayoutEstimationResponse";
            };

            return DeleteLayoutEstimationResponse;
        })();

        api.DeleteMeshRequest = (function() {

            /**
             * Properties of a DeleteMeshRequest.
             * @memberof streem.api
             * @interface IDeleteMeshRequest
             * @property {string|null} [roomSid] DeleteMeshRequest roomSid
             * @property {string|null} [meshSid] DeleteMeshRequest meshSid
             */

            /**
             * Constructs a new DeleteMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteMeshRequest.
             * @implements IDeleteMeshRequest
             * @constructor
             * @param {streem.api.IDeleteMeshRequest=} [p] Properties to set
             */
            function DeleteMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteMeshRequest
             * @instance
             */
            DeleteMeshRequest.prototype.roomSid = "";

            /**
             * DeleteMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.DeleteMeshRequest
             * @instance
             */
            DeleteMeshRequest.prototype.meshSid = "";

            /**
             * Creates a new DeleteMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {streem.api.IDeleteMeshRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteMeshRequest} DeleteMeshRequest instance
             */
            DeleteMeshRequest.create = function create(properties) {
                return new DeleteMeshRequest(properties);
            };

            /**
             * Encodes the specified DeleteMeshRequest message. Does not implicitly {@link streem.api.DeleteMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {streem.api.IDeleteMeshRequest} m DeleteMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a DeleteMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteMeshRequest} DeleteMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteMeshRequest} DeleteMeshRequest
             */
            DeleteMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteMeshRequest)
                    return d;
                var m = new $root.streem.api.DeleteMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {streem.api.DeleteMeshRequest} m DeleteMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this DeleteMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteMeshRequest";
            };

            return DeleteMeshRequest;
        })();

        api.DeleteMeshResponse = (function() {

            /**
             * Properties of a DeleteMeshResponse.
             * @memberof streem.api
             * @interface IDeleteMeshResponse
             */

            /**
             * Constructs a new DeleteMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteMeshResponse.
             * @implements IDeleteMeshResponse
             * @constructor
             * @param {streem.api.IDeleteMeshResponse=} [p] Properties to set
             */
            function DeleteMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {streem.api.IDeleteMeshResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteMeshResponse} DeleteMeshResponse instance
             */
            DeleteMeshResponse.create = function create(properties) {
                return new DeleteMeshResponse(properties);
            };

            /**
             * Encodes the specified DeleteMeshResponse message. Does not implicitly {@link streem.api.DeleteMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {streem.api.IDeleteMeshResponse} m DeleteMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteMeshResponse} DeleteMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteMeshResponse} DeleteMeshResponse
             */
            DeleteMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteMeshResponse)
                    return d;
                return new $root.streem.api.DeleteMeshResponse();
            };

            /**
             * Creates a plain object from a DeleteMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {streem.api.DeleteMeshResponse} m DeleteMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteMeshResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteMeshResponse";
            };

            return DeleteMeshResponse;
        })();

        api.DeleteRecordingRequest = (function() {

            /**
             * Properties of a DeleteRecordingRequest.
             * @memberof streem.api
             * @interface IDeleteRecordingRequest
             * @property {string|null} [roomSid] DeleteRecordingRequest roomSid
             * @property {string|null} [recordingSid] DeleteRecordingRequest recordingSid
             */

            /**
             * Constructs a new DeleteRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteRecordingRequest.
             * @implements IDeleteRecordingRequest
             * @constructor
             * @param {streem.api.IDeleteRecordingRequest=} [p] Properties to set
             */
            function DeleteRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteRecordingRequest
             * @instance
             */
            DeleteRecordingRequest.prototype.roomSid = "";

            /**
             * DeleteRecordingRequest recordingSid.
             * @member {string} recordingSid
             * @memberof streem.api.DeleteRecordingRequest
             * @instance
             */
            DeleteRecordingRequest.prototype.recordingSid = "";

            /**
             * Creates a new DeleteRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {streem.api.IDeleteRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteRecordingRequest} DeleteRecordingRequest instance
             */
            DeleteRecordingRequest.create = function create(properties) {
                return new DeleteRecordingRequest(properties);
            };

            /**
             * Encodes the specified DeleteRecordingRequest message. Does not implicitly {@link streem.api.DeleteRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {streem.api.IDeleteRecordingRequest} m DeleteRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingSid != null && Object.hasOwnProperty.call(m, "recordingSid"))
                    w.uint32(18).string(m.recordingSid);
                return w;
            };

            /**
             * Decodes a DeleteRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRecordingRequest} DeleteRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRecordingRequest} DeleteRecordingRequest
             */
            DeleteRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRecordingRequest)
                    return d;
                var m = new $root.streem.api.DeleteRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingSid != null) {
                    m.recordingSid = String(d.recordingSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {streem.api.DeleteRecordingRequest} m DeleteRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingSid != null && m.hasOwnProperty("recordingSid")) {
                    d.recordingSid = m.recordingSid;
                }
                return d;
            };

            /**
             * Converts this DeleteRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRecordingRequest";
            };

            return DeleteRecordingRequest;
        })();

        api.DeleteRecordingResponse = (function() {

            /**
             * Properties of a DeleteRecordingResponse.
             * @memberof streem.api
             * @interface IDeleteRecordingResponse
             */

            /**
             * Constructs a new DeleteRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteRecordingResponse.
             * @implements IDeleteRecordingResponse
             * @constructor
             * @param {streem.api.IDeleteRecordingResponse=} [p] Properties to set
             */
            function DeleteRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {streem.api.IDeleteRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteRecordingResponse} DeleteRecordingResponse instance
             */
            DeleteRecordingResponse.create = function create(properties) {
                return new DeleteRecordingResponse(properties);
            };

            /**
             * Encodes the specified DeleteRecordingResponse message. Does not implicitly {@link streem.api.DeleteRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {streem.api.IDeleteRecordingResponse} m DeleteRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRecordingResponse} DeleteRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRecordingResponse} DeleteRecordingResponse
             */
            DeleteRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRecordingResponse)
                    return d;
                return new $root.streem.api.DeleteRecordingResponse();
            };

            /**
             * Creates a plain object from a DeleteRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {streem.api.DeleteRecordingResponse} m DeleteRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRecordingResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRecordingResponse";
            };

            return DeleteRecordingResponse;
        })();

        api.DeleteRoomOutcomeReportsRequest = (function() {

            /**
             * Properties of a DeleteRoomOutcomeReportsRequest.
             * @memberof streem.api
             * @interface IDeleteRoomOutcomeReportsRequest
             * @property {string|null} [roomSid] DeleteRoomOutcomeReportsRequest roomSid
             */

            /**
             * Constructs a new DeleteRoomOutcomeReportsRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteRoomOutcomeReportsRequest.
             * @implements IDeleteRoomOutcomeReportsRequest
             * @constructor
             * @param {streem.api.IDeleteRoomOutcomeReportsRequest=} [p] Properties to set
             */
            function DeleteRoomOutcomeReportsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteRoomOutcomeReportsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @instance
             */
            DeleteRoomOutcomeReportsRequest.prototype.roomSid = "";

            /**
             * Creates a new DeleteRoomOutcomeReportsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.IDeleteRoomOutcomeReportsRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteRoomOutcomeReportsRequest} DeleteRoomOutcomeReportsRequest instance
             */
            DeleteRoomOutcomeReportsRequest.create = function create(properties) {
                return new DeleteRoomOutcomeReportsRequest(properties);
            };

            /**
             * Encodes the specified DeleteRoomOutcomeReportsRequest message. Does not implicitly {@link streem.api.DeleteRoomOutcomeReportsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.IDeleteRoomOutcomeReportsRequest} m DeleteRoomOutcomeReportsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRoomOutcomeReportsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a DeleteRoomOutcomeReportsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRoomOutcomeReportsRequest} DeleteRoomOutcomeReportsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRoomOutcomeReportsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRoomOutcomeReportsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRoomOutcomeReportsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRoomOutcomeReportsRequest} DeleteRoomOutcomeReportsRequest
             */
            DeleteRoomOutcomeReportsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRoomOutcomeReportsRequest)
                    return d;
                var m = new $root.streem.api.DeleteRoomOutcomeReportsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteRoomOutcomeReportsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.DeleteRoomOutcomeReportsRequest} m DeleteRoomOutcomeReportsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRoomOutcomeReportsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this DeleteRoomOutcomeReportsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRoomOutcomeReportsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRoomOutcomeReportsRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteRoomOutcomeReportsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRoomOutcomeReportsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRoomOutcomeReportsRequest";
            };

            return DeleteRoomOutcomeReportsRequest;
        })();

        api.DeleteRoomOutcomeReportsResponse = (function() {

            /**
             * Properties of a DeleteRoomOutcomeReportsResponse.
             * @memberof streem.api
             * @interface IDeleteRoomOutcomeReportsResponse
             */

            /**
             * Constructs a new DeleteRoomOutcomeReportsResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteRoomOutcomeReportsResponse.
             * @implements IDeleteRoomOutcomeReportsResponse
             * @constructor
             * @param {streem.api.IDeleteRoomOutcomeReportsResponse=} [p] Properties to set
             */
            function DeleteRoomOutcomeReportsResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteRoomOutcomeReportsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.IDeleteRoomOutcomeReportsResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteRoomOutcomeReportsResponse} DeleteRoomOutcomeReportsResponse instance
             */
            DeleteRoomOutcomeReportsResponse.create = function create(properties) {
                return new DeleteRoomOutcomeReportsResponse(properties);
            };

            /**
             * Encodes the specified DeleteRoomOutcomeReportsResponse message. Does not implicitly {@link streem.api.DeleteRoomOutcomeReportsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.IDeleteRoomOutcomeReportsResponse} m DeleteRoomOutcomeReportsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRoomOutcomeReportsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteRoomOutcomeReportsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRoomOutcomeReportsResponse} DeleteRoomOutcomeReportsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRoomOutcomeReportsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRoomOutcomeReportsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRoomOutcomeReportsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRoomOutcomeReportsResponse} DeleteRoomOutcomeReportsResponse
             */
            DeleteRoomOutcomeReportsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRoomOutcomeReportsResponse)
                    return d;
                return new $root.streem.api.DeleteRoomOutcomeReportsResponse();
            };

            /**
             * Creates a plain object from a DeleteRoomOutcomeReportsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.DeleteRoomOutcomeReportsResponse} m DeleteRoomOutcomeReportsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRoomOutcomeReportsResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteRoomOutcomeReportsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRoomOutcomeReportsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRoomOutcomeReportsResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteRoomOutcomeReportsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRoomOutcomeReportsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRoomOutcomeReportsResponse";
            };

            return DeleteRoomOutcomeReportsResponse;
        })();

        api.DeleteRoomNoteRequest = (function() {

            /**
             * Properties of a DeleteRoomNoteRequest.
             * @memberof streem.api
             * @interface IDeleteRoomNoteRequest
             * @property {string|null} [roomSid] DeleteRoomNoteRequest roomSid
             */

            /**
             * Constructs a new DeleteRoomNoteRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteRoomNoteRequest.
             * @implements IDeleteRoomNoteRequest
             * @constructor
             * @param {streem.api.IDeleteRoomNoteRequest=} [p] Properties to set
             */
            function DeleteRoomNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteRoomNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteRoomNoteRequest
             * @instance
             */
            DeleteRoomNoteRequest.prototype.roomSid = "";

            /**
             * Creates a new DeleteRoomNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {streem.api.IDeleteRoomNoteRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteRoomNoteRequest} DeleteRoomNoteRequest instance
             */
            DeleteRoomNoteRequest.create = function create(properties) {
                return new DeleteRoomNoteRequest(properties);
            };

            /**
             * Encodes the specified DeleteRoomNoteRequest message. Does not implicitly {@link streem.api.DeleteRoomNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {streem.api.IDeleteRoomNoteRequest} m DeleteRoomNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRoomNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a DeleteRoomNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRoomNoteRequest} DeleteRoomNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRoomNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRoomNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRoomNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRoomNoteRequest} DeleteRoomNoteRequest
             */
            DeleteRoomNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRoomNoteRequest)
                    return d;
                var m = new $root.streem.api.DeleteRoomNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteRoomNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {streem.api.DeleteRoomNoteRequest} m DeleteRoomNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRoomNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this DeleteRoomNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRoomNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRoomNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRoomNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteRoomNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRoomNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRoomNoteRequest";
            };

            return DeleteRoomNoteRequest;
        })();

        api.DeleteRoomNoteResponse = (function() {

            /**
             * Properties of a DeleteRoomNoteResponse.
             * @memberof streem.api
             * @interface IDeleteRoomNoteResponse
             */

            /**
             * Constructs a new DeleteRoomNoteResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteRoomNoteResponse.
             * @implements IDeleteRoomNoteResponse
             * @constructor
             * @param {streem.api.IDeleteRoomNoteResponse=} [p] Properties to set
             */
            function DeleteRoomNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteRoomNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {streem.api.IDeleteRoomNoteResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteRoomNoteResponse} DeleteRoomNoteResponse instance
             */
            DeleteRoomNoteResponse.create = function create(properties) {
                return new DeleteRoomNoteResponse(properties);
            };

            /**
             * Encodes the specified DeleteRoomNoteResponse message. Does not implicitly {@link streem.api.DeleteRoomNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {streem.api.IDeleteRoomNoteResponse} m DeleteRoomNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteRoomNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteRoomNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteRoomNoteResponse} DeleteRoomNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteRoomNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteRoomNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteRoomNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteRoomNoteResponse} DeleteRoomNoteResponse
             */
            DeleteRoomNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteRoomNoteResponse)
                    return d;
                return new $root.streem.api.DeleteRoomNoteResponse();
            };

            /**
             * Creates a plain object from a DeleteRoomNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {streem.api.DeleteRoomNoteResponse} m DeleteRoomNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteRoomNoteResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteRoomNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteRoomNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteRoomNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteRoomNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteRoomNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteRoomNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteRoomNoteResponse";
            };

            return DeleteRoomNoteResponse;
        })();

        api.DeleteSceneshotRequest = (function() {

            /**
             * Properties of a DeleteSceneshotRequest.
             * @memberof streem.api
             * @interface IDeleteSceneshotRequest
             * @property {string|null} [roomSid] DeleteSceneshotRequest roomSid
             * @property {string|null} [sceneshotSid] DeleteSceneshotRequest sceneshotSid
             */

            /**
             * Constructs a new DeleteSceneshotRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteSceneshotRequest.
             * @implements IDeleteSceneshotRequest
             * @constructor
             * @param {streem.api.IDeleteSceneshotRequest=} [p] Properties to set
             */
            function DeleteSceneshotRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteSceneshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteSceneshotRequest
             * @instance
             */
            DeleteSceneshotRequest.prototype.roomSid = "";

            /**
             * DeleteSceneshotRequest sceneshotSid.
             * @member {string} sceneshotSid
             * @memberof streem.api.DeleteSceneshotRequest
             * @instance
             */
            DeleteSceneshotRequest.prototype.sceneshotSid = "";

            /**
             * Creates a new DeleteSceneshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {streem.api.IDeleteSceneshotRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteSceneshotRequest} DeleteSceneshotRequest instance
             */
            DeleteSceneshotRequest.create = function create(properties) {
                return new DeleteSceneshotRequest(properties);
            };

            /**
             * Encodes the specified DeleteSceneshotRequest message. Does not implicitly {@link streem.api.DeleteSceneshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {streem.api.IDeleteSceneshotRequest} m DeleteSceneshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSceneshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.sceneshotSid != null && Object.hasOwnProperty.call(m, "sceneshotSid"))
                    w.uint32(18).string(m.sceneshotSid);
                return w;
            };

            /**
             * Decodes a DeleteSceneshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSceneshotRequest} DeleteSceneshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSceneshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSceneshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.sceneshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSceneshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSceneshotRequest} DeleteSceneshotRequest
             */
            DeleteSceneshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSceneshotRequest)
                    return d;
                var m = new $root.streem.api.DeleteSceneshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.sceneshotSid != null) {
                    m.sceneshotSid = String(d.sceneshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteSceneshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {streem.api.DeleteSceneshotRequest} m DeleteSceneshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSceneshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.sceneshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.sceneshotSid != null && m.hasOwnProperty("sceneshotSid")) {
                    d.sceneshotSid = m.sceneshotSid;
                }
                return d;
            };

            /**
             * Converts this DeleteSceneshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSceneshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSceneshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSceneshotRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteSceneshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSceneshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSceneshotRequest";
            };

            return DeleteSceneshotRequest;
        })();

        api.DeleteSceneshotResponse = (function() {

            /**
             * Properties of a DeleteSceneshotResponse.
             * @memberof streem.api
             * @interface IDeleteSceneshotResponse
             */

            /**
             * Constructs a new DeleteSceneshotResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteSceneshotResponse.
             * @implements IDeleteSceneshotResponse
             * @constructor
             * @param {streem.api.IDeleteSceneshotResponse=} [p] Properties to set
             */
            function DeleteSceneshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteSceneshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {streem.api.IDeleteSceneshotResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteSceneshotResponse} DeleteSceneshotResponse instance
             */
            DeleteSceneshotResponse.create = function create(properties) {
                return new DeleteSceneshotResponse(properties);
            };

            /**
             * Encodes the specified DeleteSceneshotResponse message. Does not implicitly {@link streem.api.DeleteSceneshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {streem.api.IDeleteSceneshotResponse} m DeleteSceneshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSceneshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteSceneshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSceneshotResponse} DeleteSceneshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSceneshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSceneshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSceneshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSceneshotResponse} DeleteSceneshotResponse
             */
            DeleteSceneshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSceneshotResponse)
                    return d;
                return new $root.streem.api.DeleteSceneshotResponse();
            };

            /**
             * Creates a plain object from a DeleteSceneshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {streem.api.DeleteSceneshotResponse} m DeleteSceneshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSceneshotResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteSceneshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSceneshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSceneshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSceneshotResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteSceneshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSceneshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSceneshotResponse";
            };

            return DeleteSceneshotResponse;
        })();

        api.DeleteStreemshotRequest = (function() {

            /**
             * Properties of a DeleteStreemshotRequest.
             * @memberof streem.api
             * @interface IDeleteStreemshotRequest
             * @property {string|null} [roomSid] DeleteStreemshotRequest roomSid
             * @property {string|null} [streemshotSid] DeleteStreemshotRequest streemshotSid
             */

            /**
             * Constructs a new DeleteStreemshotRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteStreemshotRequest.
             * @implements IDeleteStreemshotRequest
             * @constructor
             * @param {streem.api.IDeleteStreemshotRequest=} [p] Properties to set
             */
            function DeleteStreemshotRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteStreemshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteStreemshotRequest
             * @instance
             */
            DeleteStreemshotRequest.prototype.roomSid = "";

            /**
             * DeleteStreemshotRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.DeleteStreemshotRequest
             * @instance
             */
            DeleteStreemshotRequest.prototype.streemshotSid = "";

            /**
             * Creates a new DeleteStreemshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {streem.api.IDeleteStreemshotRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteStreemshotRequest} DeleteStreemshotRequest instance
             */
            DeleteStreemshotRequest.create = function create(properties) {
                return new DeleteStreemshotRequest(properties);
            };

            /**
             * Encodes the specified DeleteStreemshotRequest message. Does not implicitly {@link streem.api.DeleteStreemshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {streem.api.IDeleteStreemshotRequest} m DeleteStreemshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteStreemshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                return w;
            };

            /**
             * Decodes a DeleteStreemshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteStreemshotRequest} DeleteStreemshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteStreemshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteStreemshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteStreemshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteStreemshotRequest} DeleteStreemshotRequest
             */
            DeleteStreemshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteStreemshotRequest)
                    return d;
                var m = new $root.streem.api.DeleteStreemshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteStreemshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {streem.api.DeleteStreemshotRequest} m DeleteStreemshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteStreemshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                return d;
            };

            /**
             * Converts this DeleteStreemshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteStreemshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteStreemshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteStreemshotRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteStreemshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteStreemshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteStreemshotRequest";
            };

            return DeleteStreemshotRequest;
        })();

        api.DeleteStreemshotResponse = (function() {

            /**
             * Properties of a DeleteStreemshotResponse.
             * @memberof streem.api
             * @interface IDeleteStreemshotResponse
             */

            /**
             * Constructs a new DeleteStreemshotResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteStreemshotResponse.
             * @implements IDeleteStreemshotResponse
             * @constructor
             * @param {streem.api.IDeleteStreemshotResponse=} [p] Properties to set
             */
            function DeleteStreemshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteStreemshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {streem.api.IDeleteStreemshotResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteStreemshotResponse} DeleteStreemshotResponse instance
             */
            DeleteStreemshotResponse.create = function create(properties) {
                return new DeleteStreemshotResponse(properties);
            };

            /**
             * Encodes the specified DeleteStreemshotResponse message. Does not implicitly {@link streem.api.DeleteStreemshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {streem.api.IDeleteStreemshotResponse} m DeleteStreemshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteStreemshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteStreemshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteStreemshotResponse} DeleteStreemshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteStreemshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteStreemshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteStreemshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteStreemshotResponse} DeleteStreemshotResponse
             */
            DeleteStreemshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteStreemshotResponse)
                    return d;
                return new $root.streem.api.DeleteStreemshotResponse();
            };

            /**
             * Creates a plain object from a DeleteStreemshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {streem.api.DeleteStreemshotResponse} m DeleteStreemshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteStreemshotResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteStreemshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteStreemshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteStreemshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteStreemshotResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteStreemshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteStreemshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteStreemshotResponse";
            };

            return DeleteStreemshotResponse;
        })();

        api.DeleteStreemshotNoteRequest = (function() {

            /**
             * Properties of a DeleteStreemshotNoteRequest.
             * @memberof streem.api
             * @interface IDeleteStreemshotNoteRequest
             * @property {string|null} [roomSid] DeleteStreemshotNoteRequest roomSid
             * @property {string|null} [streemshotSid] DeleteStreemshotNoteRequest streemshotSid
             */

            /**
             * Constructs a new DeleteStreemshotNoteRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteStreemshotNoteRequest.
             * @implements IDeleteStreemshotNoteRequest
             * @constructor
             * @param {streem.api.IDeleteStreemshotNoteRequest=} [p] Properties to set
             */
            function DeleteStreemshotNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteStreemshotNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @instance
             */
            DeleteStreemshotNoteRequest.prototype.roomSid = "";

            /**
             * DeleteStreemshotNoteRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @instance
             */
            DeleteStreemshotNoteRequest.prototype.streemshotSid = "";

            /**
             * Creates a new DeleteStreemshotNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {streem.api.IDeleteStreemshotNoteRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteStreemshotNoteRequest} DeleteStreemshotNoteRequest instance
             */
            DeleteStreemshotNoteRequest.create = function create(properties) {
                return new DeleteStreemshotNoteRequest(properties);
            };

            /**
             * Encodes the specified DeleteStreemshotNoteRequest message. Does not implicitly {@link streem.api.DeleteStreemshotNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {streem.api.IDeleteStreemshotNoteRequest} m DeleteStreemshotNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteStreemshotNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                return w;
            };

            /**
             * Decodes a DeleteStreemshotNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteStreemshotNoteRequest} DeleteStreemshotNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteStreemshotNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteStreemshotNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteStreemshotNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteStreemshotNoteRequest} DeleteStreemshotNoteRequest
             */
            DeleteStreemshotNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteStreemshotNoteRequest)
                    return d;
                var m = new $root.streem.api.DeleteStreemshotNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteStreemshotNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {streem.api.DeleteStreemshotNoteRequest} m DeleteStreemshotNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteStreemshotNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                return d;
            };

            /**
             * Converts this DeleteStreemshotNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteStreemshotNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteStreemshotNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteStreemshotNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteStreemshotNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteStreemshotNoteRequest";
            };

            return DeleteStreemshotNoteRequest;
        })();

        api.DeleteStreemshotNoteResponse = (function() {

            /**
             * Properties of a DeleteStreemshotNoteResponse.
             * @memberof streem.api
             * @interface IDeleteStreemshotNoteResponse
             */

            /**
             * Constructs a new DeleteStreemshotNoteResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteStreemshotNoteResponse.
             * @implements IDeleteStreemshotNoteResponse
             * @constructor
             * @param {streem.api.IDeleteStreemshotNoteResponse=} [p] Properties to set
             */
            function DeleteStreemshotNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteStreemshotNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {streem.api.IDeleteStreemshotNoteResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteStreemshotNoteResponse} DeleteStreemshotNoteResponse instance
             */
            DeleteStreemshotNoteResponse.create = function create(properties) {
                return new DeleteStreemshotNoteResponse(properties);
            };

            /**
             * Encodes the specified DeleteStreemshotNoteResponse message. Does not implicitly {@link streem.api.DeleteStreemshotNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {streem.api.IDeleteStreemshotNoteResponse} m DeleteStreemshotNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteStreemshotNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteStreemshotNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteStreemshotNoteResponse} DeleteStreemshotNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteStreemshotNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteStreemshotNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteStreemshotNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteStreemshotNoteResponse} DeleteStreemshotNoteResponse
             */
            DeleteStreemshotNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteStreemshotNoteResponse)
                    return d;
                return new $root.streem.api.DeleteStreemshotNoteResponse();
            };

            /**
             * Creates a plain object from a DeleteStreemshotNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {streem.api.DeleteStreemshotNoteResponse} m DeleteStreemshotNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteStreemshotNoteResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteStreemshotNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteStreemshotNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteStreemshotNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteStreemshotNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteStreemshotNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteStreemshotNoteResponse";
            };

            return DeleteStreemshotNoteResponse;
        })();

        api.DeleteTexturedMeshRequest = (function() {

            /**
             * Properties of a DeleteTexturedMeshRequest.
             * @memberof streem.api
             * @interface IDeleteTexturedMeshRequest
             * @property {string|null} [roomSid] DeleteTexturedMeshRequest roomSid
             * @property {string|null} [texturedMeshSid] DeleteTexturedMeshRequest texturedMeshSid
             */

            /**
             * Constructs a new DeleteTexturedMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteTexturedMeshRequest.
             * @implements IDeleteTexturedMeshRequest
             * @constructor
             * @param {streem.api.IDeleteTexturedMeshRequest=} [p] Properties to set
             */
            function DeleteTexturedMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteTexturedMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @instance
             */
            DeleteTexturedMeshRequest.prototype.roomSid = "";

            /**
             * DeleteTexturedMeshRequest texturedMeshSid.
             * @member {string} texturedMeshSid
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @instance
             */
            DeleteTexturedMeshRequest.prototype.texturedMeshSid = "";

            /**
             * Creates a new DeleteTexturedMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {streem.api.IDeleteTexturedMeshRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteTexturedMeshRequest} DeleteTexturedMeshRequest instance
             */
            DeleteTexturedMeshRequest.create = function create(properties) {
                return new DeleteTexturedMeshRequest(properties);
            };

            /**
             * Encodes the specified DeleteTexturedMeshRequest message. Does not implicitly {@link streem.api.DeleteTexturedMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {streem.api.IDeleteTexturedMeshRequest} m DeleteTexturedMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTexturedMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.texturedMeshSid != null && Object.hasOwnProperty.call(m, "texturedMeshSid"))
                    w.uint32(18).string(m.texturedMeshSid);
                return w;
            };

            /**
             * Decodes a DeleteTexturedMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteTexturedMeshRequest} DeleteTexturedMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTexturedMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteTexturedMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.texturedMeshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteTexturedMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteTexturedMeshRequest} DeleteTexturedMeshRequest
             */
            DeleteTexturedMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteTexturedMeshRequest)
                    return d;
                var m = new $root.streem.api.DeleteTexturedMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.texturedMeshSid != null) {
                    m.texturedMeshSid = String(d.texturedMeshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteTexturedMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {streem.api.DeleteTexturedMeshRequest} m DeleteTexturedMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteTexturedMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.texturedMeshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.texturedMeshSid != null && m.hasOwnProperty("texturedMeshSid")) {
                    d.texturedMeshSid = m.texturedMeshSid;
                }
                return d;
            };

            /**
             * Converts this DeleteTexturedMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteTexturedMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteTexturedMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteTexturedMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteTexturedMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteTexturedMeshRequest";
            };

            return DeleteTexturedMeshRequest;
        })();

        api.DeleteTexturedMeshResponse = (function() {

            /**
             * Properties of a DeleteTexturedMeshResponse.
             * @memberof streem.api
             * @interface IDeleteTexturedMeshResponse
             */

            /**
             * Constructs a new DeleteTexturedMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteTexturedMeshResponse.
             * @implements IDeleteTexturedMeshResponse
             * @constructor
             * @param {streem.api.IDeleteTexturedMeshResponse=} [p] Properties to set
             */
            function DeleteTexturedMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteTexturedMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {streem.api.IDeleteTexturedMeshResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteTexturedMeshResponse} DeleteTexturedMeshResponse instance
             */
            DeleteTexturedMeshResponse.create = function create(properties) {
                return new DeleteTexturedMeshResponse(properties);
            };

            /**
             * Encodes the specified DeleteTexturedMeshResponse message. Does not implicitly {@link streem.api.DeleteTexturedMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {streem.api.IDeleteTexturedMeshResponse} m DeleteTexturedMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTexturedMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteTexturedMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteTexturedMeshResponse} DeleteTexturedMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTexturedMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteTexturedMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteTexturedMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteTexturedMeshResponse} DeleteTexturedMeshResponse
             */
            DeleteTexturedMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteTexturedMeshResponse)
                    return d;
                return new $root.streem.api.DeleteTexturedMeshResponse();
            };

            /**
             * Creates a plain object from a DeleteTexturedMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {streem.api.DeleteTexturedMeshResponse} m DeleteTexturedMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteTexturedMeshResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteTexturedMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteTexturedMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteTexturedMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteTexturedMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteTexturedMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteTexturedMeshResponse";
            };

            return DeleteTexturedMeshResponse;
        })();

        api.FailLayoutEstimationRequest = (function() {

            /**
             * Properties of a FailLayoutEstimationRequest.
             * @memberof streem.api
             * @interface IFailLayoutEstimationRequest
             * @property {string|null} [roomSid] FailLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] FailLayoutEstimationRequest layoutEstimationSid
             */

            /**
             * Constructs a new FailLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a FailLayoutEstimationRequest.
             * @implements IFailLayoutEstimationRequest
             * @constructor
             * @param {streem.api.IFailLayoutEstimationRequest=} [p] Properties to set
             */
            function FailLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FailLayoutEstimationRequest
             * @instance
             */
            FailLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * FailLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.FailLayoutEstimationRequest
             * @instance
             */
            FailLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * Creates a new FailLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {streem.api.IFailLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.FailLayoutEstimationRequest} FailLayoutEstimationRequest instance
             */
            FailLayoutEstimationRequest.create = function create(properties) {
                return new FailLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified FailLayoutEstimationRequest message. Does not implicitly {@link streem.api.FailLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {streem.api.IFailLayoutEstimationRequest} m FailLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                return w;
            };

            /**
             * Decodes a FailLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailLayoutEstimationRequest} FailLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailLayoutEstimationRequest} FailLayoutEstimationRequest
             */
            FailLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.FailLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {streem.api.FailLayoutEstimationRequest} m FailLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                return d;
            };

            /**
             * Converts this FailLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FailLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.FailLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailLayoutEstimationRequest";
            };

            return FailLayoutEstimationRequest;
        })();

        api.FailLayoutEstimationResponse = (function() {

            /**
             * Properties of a FailLayoutEstimationResponse.
             * @memberof streem.api
             * @interface IFailLayoutEstimationResponse
             * @property {streem.api.IArtifact|null} [layoutEstimationArtifact] FailLayoutEstimationResponse layoutEstimationArtifact
             */

            /**
             * Constructs a new FailLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a FailLayoutEstimationResponse.
             * @implements IFailLayoutEstimationResponse
             * @constructor
             * @param {streem.api.IFailLayoutEstimationResponse=} [p] Properties to set
             */
            function FailLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailLayoutEstimationResponse layoutEstimationArtifact.
             * @member {streem.api.IArtifact|null|undefined} layoutEstimationArtifact
             * @memberof streem.api.FailLayoutEstimationResponse
             * @instance
             */
            FailLayoutEstimationResponse.prototype.layoutEstimationArtifact = null;

            /**
             * Creates a new FailLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {streem.api.IFailLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.FailLayoutEstimationResponse} FailLayoutEstimationResponse instance
             */
            FailLayoutEstimationResponse.create = function create(properties) {
                return new FailLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified FailLayoutEstimationResponse message. Does not implicitly {@link streem.api.FailLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {streem.api.IFailLayoutEstimationResponse} m FailLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.layoutEstimationArtifact != null && Object.hasOwnProperty.call(m, "layoutEstimationArtifact"))
                    $root.streem.api.Artifact.encode(m.layoutEstimationArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FailLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailLayoutEstimationResponse} FailLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.layoutEstimationArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailLayoutEstimationResponse} FailLayoutEstimationResponse
             */
            FailLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailLayoutEstimationResponse)
                    return d;
                var m = new $root.streem.api.FailLayoutEstimationResponse();
                if (d.layoutEstimationArtifact != null) {
                    if (typeof d.layoutEstimationArtifact !== "object")
                        throw TypeError(".streem.api.FailLayoutEstimationResponse.layoutEstimationArtifact: object expected");
                    m.layoutEstimationArtifact = $root.streem.api.Artifact.fromObject(d.layoutEstimationArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {streem.api.FailLayoutEstimationResponse} m FailLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailLayoutEstimationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.layoutEstimationArtifact = null;
                }
                if (m.layoutEstimationArtifact != null && m.hasOwnProperty("layoutEstimationArtifact")) {
                    d.layoutEstimationArtifact = $root.streem.api.Artifact.toObject(m.layoutEstimationArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FailLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FailLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.FailLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailLayoutEstimationResponse";
            };

            return FailLayoutEstimationResponse;
        })();

        api.FailMeshRequest = (function() {

            /**
             * Properties of a FailMeshRequest.
             * @memberof streem.api
             * @interface IFailMeshRequest
             * @property {string|null} [roomSid] FailMeshRequest roomSid
             * @property {string|null} [meshSid] FailMeshRequest meshSid
             */

            /**
             * Constructs a new FailMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a FailMeshRequest.
             * @implements IFailMeshRequest
             * @constructor
             * @param {streem.api.IFailMeshRequest=} [p] Properties to set
             */
            function FailMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FailMeshRequest
             * @instance
             */
            FailMeshRequest.prototype.roomSid = "";

            /**
             * FailMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.FailMeshRequest
             * @instance
             */
            FailMeshRequest.prototype.meshSid = "";

            /**
             * Creates a new FailMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {streem.api.IFailMeshRequest=} [properties] Properties to set
             * @returns {streem.api.FailMeshRequest} FailMeshRequest instance
             */
            FailMeshRequest.create = function create(properties) {
                return new FailMeshRequest(properties);
            };

            /**
             * Encodes the specified FailMeshRequest message. Does not implicitly {@link streem.api.FailMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {streem.api.IFailMeshRequest} m FailMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a FailMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailMeshRequest} FailMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailMeshRequest} FailMeshRequest
             */
            FailMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailMeshRequest)
                    return d;
                var m = new $root.streem.api.FailMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {streem.api.FailMeshRequest} m FailMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this FailMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FailMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.FailMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailMeshRequest";
            };

            return FailMeshRequest;
        })();

        api.FailMeshResponse = (function() {

            /**
             * Properties of a FailMeshResponse.
             * @memberof streem.api
             * @interface IFailMeshResponse
             * @property {streem.api.IArtifact|null} [meshArtifact] FailMeshResponse meshArtifact
             */

            /**
             * Constructs a new FailMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a FailMeshResponse.
             * @implements IFailMeshResponse
             * @constructor
             * @param {streem.api.IFailMeshResponse=} [p] Properties to set
             */
            function FailMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailMeshResponse meshArtifact.
             * @member {streem.api.IArtifact|null|undefined} meshArtifact
             * @memberof streem.api.FailMeshResponse
             * @instance
             */
            FailMeshResponse.prototype.meshArtifact = null;

            /**
             * Creates a new FailMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {streem.api.IFailMeshResponse=} [properties] Properties to set
             * @returns {streem.api.FailMeshResponse} FailMeshResponse instance
             */
            FailMeshResponse.create = function create(properties) {
                return new FailMeshResponse(properties);
            };

            /**
             * Encodes the specified FailMeshResponse message. Does not implicitly {@link streem.api.FailMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {streem.api.IFailMeshResponse} m FailMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifact != null && Object.hasOwnProperty.call(m, "meshArtifact"))
                    $root.streem.api.Artifact.encode(m.meshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FailMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailMeshResponse} FailMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailMeshResponse} FailMeshResponse
             */
            FailMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailMeshResponse)
                    return d;
                var m = new $root.streem.api.FailMeshResponse();
                if (d.meshArtifact != null) {
                    if (typeof d.meshArtifact !== "object")
                        throw TypeError(".streem.api.FailMeshResponse.meshArtifact: object expected");
                    m.meshArtifact = $root.streem.api.Artifact.fromObject(d.meshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {streem.api.FailMeshResponse} m FailMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meshArtifact = null;
                }
                if (m.meshArtifact != null && m.hasOwnProperty("meshArtifact")) {
                    d.meshArtifact = $root.streem.api.Artifact.toObject(m.meshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FailMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FailMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.FailMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailMeshResponse";
            };

            return FailMeshResponse;
        })();

        api.FailRecordingMuxRequest = (function() {

            /**
             * Properties of a FailRecordingMuxRequest.
             * @memberof streem.api
             * @interface IFailRecordingMuxRequest
             * @property {string|null} [roomSid] FailRecordingMuxRequest roomSid
             * @property {string|null} [recordingSid] FailRecordingMuxRequest recordingSid
             */

            /**
             * Constructs a new FailRecordingMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a FailRecordingMuxRequest.
             * @implements IFailRecordingMuxRequest
             * @constructor
             * @param {streem.api.IFailRecordingMuxRequest=} [p] Properties to set
             */
            function FailRecordingMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailRecordingMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FailRecordingMuxRequest
             * @instance
             */
            FailRecordingMuxRequest.prototype.roomSid = "";

            /**
             * FailRecordingMuxRequest recordingSid.
             * @member {string} recordingSid
             * @memberof streem.api.FailRecordingMuxRequest
             * @instance
             */
            FailRecordingMuxRequest.prototype.recordingSid = "";

            /**
             * Creates a new FailRecordingMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {streem.api.IFailRecordingMuxRequest=} [properties] Properties to set
             * @returns {streem.api.FailRecordingMuxRequest} FailRecordingMuxRequest instance
             */
            FailRecordingMuxRequest.create = function create(properties) {
                return new FailRecordingMuxRequest(properties);
            };

            /**
             * Encodes the specified FailRecordingMuxRequest message. Does not implicitly {@link streem.api.FailRecordingMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {streem.api.IFailRecordingMuxRequest} m FailRecordingMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailRecordingMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingSid != null && Object.hasOwnProperty.call(m, "recordingSid"))
                    w.uint32(18).string(m.recordingSid);
                return w;
            };

            /**
             * Decodes a FailRecordingMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailRecordingMuxRequest} FailRecordingMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailRecordingMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailRecordingMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailRecordingMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailRecordingMuxRequest} FailRecordingMuxRequest
             */
            FailRecordingMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailRecordingMuxRequest)
                    return d;
                var m = new $root.streem.api.FailRecordingMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingSid != null) {
                    m.recordingSid = String(d.recordingSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailRecordingMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {streem.api.FailRecordingMuxRequest} m FailRecordingMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailRecordingMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingSid != null && m.hasOwnProperty("recordingSid")) {
                    d.recordingSid = m.recordingSid;
                }
                return d;
            };

            /**
             * Converts this FailRecordingMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FailRecordingMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailRecordingMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailRecordingMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.FailRecordingMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailRecordingMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailRecordingMuxRequest";
            };

            return FailRecordingMuxRequest;
        })();

        api.FailRecordingMuxResponse = (function() {

            /**
             * Properties of a FailRecordingMuxResponse.
             * @memberof streem.api
             * @interface IFailRecordingMuxResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] FailRecordingMuxResponse recordingArtifact
             */

            /**
             * Constructs a new FailRecordingMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a FailRecordingMuxResponse.
             * @implements IFailRecordingMuxResponse
             * @constructor
             * @param {streem.api.IFailRecordingMuxResponse=} [p] Properties to set
             */
            function FailRecordingMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailRecordingMuxResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.FailRecordingMuxResponse
             * @instance
             */
            FailRecordingMuxResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new FailRecordingMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {streem.api.IFailRecordingMuxResponse=} [properties] Properties to set
             * @returns {streem.api.FailRecordingMuxResponse} FailRecordingMuxResponse instance
             */
            FailRecordingMuxResponse.create = function create(properties) {
                return new FailRecordingMuxResponse(properties);
            };

            /**
             * Encodes the specified FailRecordingMuxResponse message. Does not implicitly {@link streem.api.FailRecordingMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {streem.api.IFailRecordingMuxResponse} m FailRecordingMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailRecordingMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FailRecordingMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailRecordingMuxResponse} FailRecordingMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailRecordingMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailRecordingMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailRecordingMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailRecordingMuxResponse} FailRecordingMuxResponse
             */
            FailRecordingMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailRecordingMuxResponse)
                    return d;
                var m = new $root.streem.api.FailRecordingMuxResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.FailRecordingMuxResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailRecordingMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {streem.api.FailRecordingMuxResponse} m FailRecordingMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailRecordingMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FailRecordingMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FailRecordingMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailRecordingMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailRecordingMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.FailRecordingMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailRecordingMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailRecordingMuxResponse";
            };

            return FailRecordingMuxResponse;
        })();

        api.FailRecordingTrackMuxRequest = (function() {

            /**
             * Properties of a FailRecordingTrackMuxRequest.
             * @memberof streem.api
             * @interface IFailRecordingTrackMuxRequest
             * @property {string|null} [roomSid] FailRecordingTrackMuxRequest roomSid
             * @property {string|null} [recordingTrackSid] FailRecordingTrackMuxRequest recordingTrackSid
             */

            /**
             * Constructs a new FailRecordingTrackMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a FailRecordingTrackMuxRequest.
             * @implements IFailRecordingTrackMuxRequest
             * @constructor
             * @param {streem.api.IFailRecordingTrackMuxRequest=} [p] Properties to set
             */
            function FailRecordingTrackMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailRecordingTrackMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @instance
             */
            FailRecordingTrackMuxRequest.prototype.roomSid = "";

            /**
             * FailRecordingTrackMuxRequest recordingTrackSid.
             * @member {string} recordingTrackSid
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @instance
             */
            FailRecordingTrackMuxRequest.prototype.recordingTrackSid = "";

            /**
             * Creates a new FailRecordingTrackMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {streem.api.IFailRecordingTrackMuxRequest=} [properties] Properties to set
             * @returns {streem.api.FailRecordingTrackMuxRequest} FailRecordingTrackMuxRequest instance
             */
            FailRecordingTrackMuxRequest.create = function create(properties) {
                return new FailRecordingTrackMuxRequest(properties);
            };

            /**
             * Encodes the specified FailRecordingTrackMuxRequest message. Does not implicitly {@link streem.api.FailRecordingTrackMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {streem.api.IFailRecordingTrackMuxRequest} m FailRecordingTrackMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailRecordingTrackMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingTrackSid != null && Object.hasOwnProperty.call(m, "recordingTrackSid"))
                    w.uint32(18).string(m.recordingTrackSid);
                return w;
            };

            /**
             * Decodes a FailRecordingTrackMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailRecordingTrackMuxRequest} FailRecordingTrackMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailRecordingTrackMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailRecordingTrackMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingTrackSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailRecordingTrackMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailRecordingTrackMuxRequest} FailRecordingTrackMuxRequest
             */
            FailRecordingTrackMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailRecordingTrackMuxRequest)
                    return d;
                var m = new $root.streem.api.FailRecordingTrackMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingTrackSid != null) {
                    m.recordingTrackSid = String(d.recordingTrackSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailRecordingTrackMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {streem.api.FailRecordingTrackMuxRequest} m FailRecordingTrackMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailRecordingTrackMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingTrackSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingTrackSid != null && m.hasOwnProperty("recordingTrackSid")) {
                    d.recordingTrackSid = m.recordingTrackSid;
                }
                return d;
            };

            /**
             * Converts this FailRecordingTrackMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailRecordingTrackMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailRecordingTrackMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.FailRecordingTrackMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailRecordingTrackMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailRecordingTrackMuxRequest";
            };

            return FailRecordingTrackMuxRequest;
        })();

        api.FailRecordingTrackMuxResponse = (function() {

            /**
             * Properties of a FailRecordingTrackMuxResponse.
             * @memberof streem.api
             * @interface IFailRecordingTrackMuxResponse
             * @property {streem.api.IArtifact|null} [recordingTrackArtifact] FailRecordingTrackMuxResponse recordingTrackArtifact
             */

            /**
             * Constructs a new FailRecordingTrackMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a FailRecordingTrackMuxResponse.
             * @implements IFailRecordingTrackMuxResponse
             * @constructor
             * @param {streem.api.IFailRecordingTrackMuxResponse=} [p] Properties to set
             */
            function FailRecordingTrackMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FailRecordingTrackMuxResponse recordingTrackArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingTrackArtifact
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @instance
             */
            FailRecordingTrackMuxResponse.prototype.recordingTrackArtifact = null;

            /**
             * Creates a new FailRecordingTrackMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {streem.api.IFailRecordingTrackMuxResponse=} [properties] Properties to set
             * @returns {streem.api.FailRecordingTrackMuxResponse} FailRecordingTrackMuxResponse instance
             */
            FailRecordingTrackMuxResponse.create = function create(properties) {
                return new FailRecordingTrackMuxResponse(properties);
            };

            /**
             * Encodes the specified FailRecordingTrackMuxResponse message. Does not implicitly {@link streem.api.FailRecordingTrackMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {streem.api.IFailRecordingTrackMuxResponse} m FailRecordingTrackMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FailRecordingTrackMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingTrackArtifact != null && Object.hasOwnProperty.call(m, "recordingTrackArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingTrackArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FailRecordingTrackMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FailRecordingTrackMuxResponse} FailRecordingTrackMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FailRecordingTrackMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FailRecordingTrackMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingTrackArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FailRecordingTrackMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FailRecordingTrackMuxResponse} FailRecordingTrackMuxResponse
             */
            FailRecordingTrackMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FailRecordingTrackMuxResponse)
                    return d;
                var m = new $root.streem.api.FailRecordingTrackMuxResponse();
                if (d.recordingTrackArtifact != null) {
                    if (typeof d.recordingTrackArtifact !== "object")
                        throw TypeError(".streem.api.FailRecordingTrackMuxResponse.recordingTrackArtifact: object expected");
                    m.recordingTrackArtifact = $root.streem.api.Artifact.fromObject(d.recordingTrackArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FailRecordingTrackMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {streem.api.FailRecordingTrackMuxResponse} m FailRecordingTrackMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FailRecordingTrackMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingTrackArtifact = null;
                }
                if (m.recordingTrackArtifact != null && m.hasOwnProperty("recordingTrackArtifact")) {
                    d.recordingTrackArtifact = $root.streem.api.Artifact.toObject(m.recordingTrackArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FailRecordingTrackMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FailRecordingTrackMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FailRecordingTrackMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.FailRecordingTrackMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FailRecordingTrackMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FailRecordingTrackMuxResponse";
            };

            return FailRecordingTrackMuxResponse;
        })();

        api.FulfillLayoutEstimationRequest = (function() {

            /**
             * Properties of a FulfillLayoutEstimationRequest.
             * @memberof streem.api
             * @interface IFulfillLayoutEstimationRequest
             * @property {string|null} [roomSid] FulfillLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] FulfillLayoutEstimationRequest layoutEstimationSid
             */

            /**
             * Constructs a new FulfillLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillLayoutEstimationRequest.
             * @implements IFulfillLayoutEstimationRequest
             * @constructor
             * @param {streem.api.IFulfillLayoutEstimationRequest=} [p] Properties to set
             */
            function FulfillLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @instance
             */
            FulfillLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * FulfillLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @instance
             */
            FulfillLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * Creates a new FulfillLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {streem.api.IFulfillLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillLayoutEstimationRequest} FulfillLayoutEstimationRequest instance
             */
            FulfillLayoutEstimationRequest.create = function create(properties) {
                return new FulfillLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified FulfillLayoutEstimationRequest message. Does not implicitly {@link streem.api.FulfillLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {streem.api.IFulfillLayoutEstimationRequest} m FulfillLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                return w;
            };

            /**
             * Decodes a FulfillLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillLayoutEstimationRequest} FulfillLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillLayoutEstimationRequest} FulfillLayoutEstimationRequest
             */
            FulfillLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.FulfillLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {streem.api.FulfillLayoutEstimationRequest} m FulfillLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                return d;
            };

            /**
             * Converts this FulfillLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillLayoutEstimationRequest";
            };

            return FulfillLayoutEstimationRequest;
        })();

        api.FulfillLayoutEstimationResponse = (function() {

            /**
             * Properties of a FulfillLayoutEstimationResponse.
             * @memberof streem.api
             * @interface IFulfillLayoutEstimationResponse
             * @property {streem.api.IArtifact|null} [layoutEstimationArtifact] FulfillLayoutEstimationResponse layoutEstimationArtifact
             */

            /**
             * Constructs a new FulfillLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillLayoutEstimationResponse.
             * @implements IFulfillLayoutEstimationResponse
             * @constructor
             * @param {streem.api.IFulfillLayoutEstimationResponse=} [p] Properties to set
             */
            function FulfillLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillLayoutEstimationResponse layoutEstimationArtifact.
             * @member {streem.api.IArtifact|null|undefined} layoutEstimationArtifact
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @instance
             */
            FulfillLayoutEstimationResponse.prototype.layoutEstimationArtifact = null;

            /**
             * Creates a new FulfillLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {streem.api.IFulfillLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillLayoutEstimationResponse} FulfillLayoutEstimationResponse instance
             */
            FulfillLayoutEstimationResponse.create = function create(properties) {
                return new FulfillLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified FulfillLayoutEstimationResponse message. Does not implicitly {@link streem.api.FulfillLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {streem.api.IFulfillLayoutEstimationResponse} m FulfillLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.layoutEstimationArtifact != null && Object.hasOwnProperty.call(m, "layoutEstimationArtifact"))
                    $root.streem.api.Artifact.encode(m.layoutEstimationArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillLayoutEstimationResponse} FulfillLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.layoutEstimationArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillLayoutEstimationResponse} FulfillLayoutEstimationResponse
             */
            FulfillLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillLayoutEstimationResponse)
                    return d;
                var m = new $root.streem.api.FulfillLayoutEstimationResponse();
                if (d.layoutEstimationArtifact != null) {
                    if (typeof d.layoutEstimationArtifact !== "object")
                        throw TypeError(".streem.api.FulfillLayoutEstimationResponse.layoutEstimationArtifact: object expected");
                    m.layoutEstimationArtifact = $root.streem.api.Artifact.fromObject(d.layoutEstimationArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {streem.api.FulfillLayoutEstimationResponse} m FulfillLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillLayoutEstimationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.layoutEstimationArtifact = null;
                }
                if (m.layoutEstimationArtifact != null && m.hasOwnProperty("layoutEstimationArtifact")) {
                    d.layoutEstimationArtifact = $root.streem.api.Artifact.toObject(m.layoutEstimationArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillLayoutEstimationResponse";
            };

            return FulfillLayoutEstimationResponse;
        })();

        api.FulfillMeshRequest = (function() {

            /**
             * Properties of a FulfillMeshRequest.
             * @memberof streem.api
             * @interface IFulfillMeshRequest
             * @property {string|null} [roomSid] FulfillMeshRequest roomSid
             * @property {string|null} [meshSid] FulfillMeshRequest meshSid
             */

            /**
             * Constructs a new FulfillMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillMeshRequest.
             * @implements IFulfillMeshRequest
             * @constructor
             * @param {streem.api.IFulfillMeshRequest=} [p] Properties to set
             */
            function FulfillMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FulfillMeshRequest
             * @instance
             */
            FulfillMeshRequest.prototype.roomSid = "";

            /**
             * FulfillMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.FulfillMeshRequest
             * @instance
             */
            FulfillMeshRequest.prototype.meshSid = "";

            /**
             * Creates a new FulfillMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {streem.api.IFulfillMeshRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillMeshRequest} FulfillMeshRequest instance
             */
            FulfillMeshRequest.create = function create(properties) {
                return new FulfillMeshRequest(properties);
            };

            /**
             * Encodes the specified FulfillMeshRequest message. Does not implicitly {@link streem.api.FulfillMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {streem.api.IFulfillMeshRequest} m FulfillMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a FulfillMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillMeshRequest} FulfillMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillMeshRequest} FulfillMeshRequest
             */
            FulfillMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillMeshRequest)
                    return d;
                var m = new $root.streem.api.FulfillMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {streem.api.FulfillMeshRequest} m FulfillMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this FulfillMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillMeshRequest";
            };

            return FulfillMeshRequest;
        })();

        api.FulfillMeshResponse = (function() {

            /**
             * Properties of a FulfillMeshResponse.
             * @memberof streem.api
             * @interface IFulfillMeshResponse
             * @property {streem.api.IArtifact|null} [meshArtifact] FulfillMeshResponse meshArtifact
             */

            /**
             * Constructs a new FulfillMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillMeshResponse.
             * @implements IFulfillMeshResponse
             * @constructor
             * @param {streem.api.IFulfillMeshResponse=} [p] Properties to set
             */
            function FulfillMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillMeshResponse meshArtifact.
             * @member {streem.api.IArtifact|null|undefined} meshArtifact
             * @memberof streem.api.FulfillMeshResponse
             * @instance
             */
            FulfillMeshResponse.prototype.meshArtifact = null;

            /**
             * Creates a new FulfillMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {streem.api.IFulfillMeshResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillMeshResponse} FulfillMeshResponse instance
             */
            FulfillMeshResponse.create = function create(properties) {
                return new FulfillMeshResponse(properties);
            };

            /**
             * Encodes the specified FulfillMeshResponse message. Does not implicitly {@link streem.api.FulfillMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {streem.api.IFulfillMeshResponse} m FulfillMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifact != null && Object.hasOwnProperty.call(m, "meshArtifact"))
                    $root.streem.api.Artifact.encode(m.meshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillMeshResponse} FulfillMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillMeshResponse} FulfillMeshResponse
             */
            FulfillMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillMeshResponse)
                    return d;
                var m = new $root.streem.api.FulfillMeshResponse();
                if (d.meshArtifact != null) {
                    if (typeof d.meshArtifact !== "object")
                        throw TypeError(".streem.api.FulfillMeshResponse.meshArtifact: object expected");
                    m.meshArtifact = $root.streem.api.Artifact.fromObject(d.meshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {streem.api.FulfillMeshResponse} m FulfillMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meshArtifact = null;
                }
                if (m.meshArtifact != null && m.hasOwnProperty("meshArtifact")) {
                    d.meshArtifact = $root.streem.api.Artifact.toObject(m.meshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillMeshResponse";
            };

            return FulfillMeshResponse;
        })();

        api.FulfillRecordingMuxRequest = (function() {

            /**
             * Properties of a FulfillRecordingMuxRequest.
             * @memberof streem.api
             * @interface IFulfillRecordingMuxRequest
             * @property {string|null} [roomSid] FulfillRecordingMuxRequest roomSid
             * @property {string|null} [recordingSid] FulfillRecordingMuxRequest recordingSid
             */

            /**
             * Constructs a new FulfillRecordingMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingMuxRequest.
             * @implements IFulfillRecordingMuxRequest
             * @constructor
             * @param {streem.api.IFulfillRecordingMuxRequest=} [p] Properties to set
             */
            function FulfillRecordingMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @instance
             */
            FulfillRecordingMuxRequest.prototype.roomSid = "";

            /**
             * FulfillRecordingMuxRequest recordingSid.
             * @member {string} recordingSid
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @instance
             */
            FulfillRecordingMuxRequest.prototype.recordingSid = "";

            /**
             * Creates a new FulfillRecordingMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {streem.api.IFulfillRecordingMuxRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingMuxRequest} FulfillRecordingMuxRequest instance
             */
            FulfillRecordingMuxRequest.create = function create(properties) {
                return new FulfillRecordingMuxRequest(properties);
            };

            /**
             * Encodes the specified FulfillRecordingMuxRequest message. Does not implicitly {@link streem.api.FulfillRecordingMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {streem.api.IFulfillRecordingMuxRequest} m FulfillRecordingMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingSid != null && Object.hasOwnProperty.call(m, "recordingSid"))
                    w.uint32(18).string(m.recordingSid);
                return w;
            };

            /**
             * Decodes a FulfillRecordingMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingMuxRequest} FulfillRecordingMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingMuxRequest} FulfillRecordingMuxRequest
             */
            FulfillRecordingMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingMuxRequest)
                    return d;
                var m = new $root.streem.api.FulfillRecordingMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingSid != null) {
                    m.recordingSid = String(d.recordingSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {streem.api.FulfillRecordingMuxRequest} m FulfillRecordingMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingSid != null && m.hasOwnProperty("recordingSid")) {
                    d.recordingSid = m.recordingSid;
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingMuxRequest";
            };

            return FulfillRecordingMuxRequest;
        })();

        api.FulfillRecordingMuxResponse = (function() {

            /**
             * Properties of a FulfillRecordingMuxResponse.
             * @memberof streem.api
             * @interface IFulfillRecordingMuxResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] FulfillRecordingMuxResponse recordingArtifact
             */

            /**
             * Constructs a new FulfillRecordingMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingMuxResponse.
             * @implements IFulfillRecordingMuxResponse
             * @constructor
             * @param {streem.api.IFulfillRecordingMuxResponse=} [p] Properties to set
             */
            function FulfillRecordingMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingMuxResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @instance
             */
            FulfillRecordingMuxResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new FulfillRecordingMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {streem.api.IFulfillRecordingMuxResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingMuxResponse} FulfillRecordingMuxResponse instance
             */
            FulfillRecordingMuxResponse.create = function create(properties) {
                return new FulfillRecordingMuxResponse(properties);
            };

            /**
             * Encodes the specified FulfillRecordingMuxResponse message. Does not implicitly {@link streem.api.FulfillRecordingMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {streem.api.IFulfillRecordingMuxResponse} m FulfillRecordingMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillRecordingMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingMuxResponse} FulfillRecordingMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingMuxResponse} FulfillRecordingMuxResponse
             */
            FulfillRecordingMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingMuxResponse)
                    return d;
                var m = new $root.streem.api.FulfillRecordingMuxResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.FulfillRecordingMuxResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {streem.api.FulfillRecordingMuxResponse} m FulfillRecordingMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingMuxResponse";
            };

            return FulfillRecordingMuxResponse;
        })();

        api.FulfillRecordingTrackMuxRequest = (function() {

            /**
             * Properties of a FulfillRecordingTrackMuxRequest.
             * @memberof streem.api
             * @interface IFulfillRecordingTrackMuxRequest
             * @property {string|null} [roomSid] FulfillRecordingTrackMuxRequest roomSid
             * @property {string|null} [recordingTrackSid] FulfillRecordingTrackMuxRequest recordingTrackSid
             */

            /**
             * Constructs a new FulfillRecordingTrackMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingTrackMuxRequest.
             * @implements IFulfillRecordingTrackMuxRequest
             * @constructor
             * @param {streem.api.IFulfillRecordingTrackMuxRequest=} [p] Properties to set
             */
            function FulfillRecordingTrackMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingTrackMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @instance
             */
            FulfillRecordingTrackMuxRequest.prototype.roomSid = "";

            /**
             * FulfillRecordingTrackMuxRequest recordingTrackSid.
             * @member {string} recordingTrackSid
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @instance
             */
            FulfillRecordingTrackMuxRequest.prototype.recordingTrackSid = "";

            /**
             * Creates a new FulfillRecordingTrackMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {streem.api.IFulfillRecordingTrackMuxRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingTrackMuxRequest} FulfillRecordingTrackMuxRequest instance
             */
            FulfillRecordingTrackMuxRequest.create = function create(properties) {
                return new FulfillRecordingTrackMuxRequest(properties);
            };

            /**
             * Encodes the specified FulfillRecordingTrackMuxRequest message. Does not implicitly {@link streem.api.FulfillRecordingTrackMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {streem.api.IFulfillRecordingTrackMuxRequest} m FulfillRecordingTrackMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingTrackMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingTrackSid != null && Object.hasOwnProperty.call(m, "recordingTrackSid"))
                    w.uint32(18).string(m.recordingTrackSid);
                return w;
            };

            /**
             * Decodes a FulfillRecordingTrackMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingTrackMuxRequest} FulfillRecordingTrackMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingTrackMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingTrackMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingTrackSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingTrackMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingTrackMuxRequest} FulfillRecordingTrackMuxRequest
             */
            FulfillRecordingTrackMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingTrackMuxRequest)
                    return d;
                var m = new $root.streem.api.FulfillRecordingTrackMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingTrackSid != null) {
                    m.recordingTrackSid = String(d.recordingTrackSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingTrackMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {streem.api.FulfillRecordingTrackMuxRequest} m FulfillRecordingTrackMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingTrackMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingTrackSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingTrackSid != null && m.hasOwnProperty("recordingTrackSid")) {
                    d.recordingTrackSid = m.recordingTrackSid;
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingTrackMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingTrackMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingTrackMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingTrackMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingTrackMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingTrackMuxRequest";
            };

            return FulfillRecordingTrackMuxRequest;
        })();

        api.FulfillRecordingTrackMuxResponse = (function() {

            /**
             * Properties of a FulfillRecordingTrackMuxResponse.
             * @memberof streem.api
             * @interface IFulfillRecordingTrackMuxResponse
             * @property {streem.api.IArtifact|null} [recordingTrackArtifact] FulfillRecordingTrackMuxResponse recordingTrackArtifact
             */

            /**
             * Constructs a new FulfillRecordingTrackMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillRecordingTrackMuxResponse.
             * @implements IFulfillRecordingTrackMuxResponse
             * @constructor
             * @param {streem.api.IFulfillRecordingTrackMuxResponse=} [p] Properties to set
             */
            function FulfillRecordingTrackMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillRecordingTrackMuxResponse recordingTrackArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingTrackArtifact
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @instance
             */
            FulfillRecordingTrackMuxResponse.prototype.recordingTrackArtifact = null;

            /**
             * Creates a new FulfillRecordingTrackMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {streem.api.IFulfillRecordingTrackMuxResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillRecordingTrackMuxResponse} FulfillRecordingTrackMuxResponse instance
             */
            FulfillRecordingTrackMuxResponse.create = function create(properties) {
                return new FulfillRecordingTrackMuxResponse(properties);
            };

            /**
             * Encodes the specified FulfillRecordingTrackMuxResponse message. Does not implicitly {@link streem.api.FulfillRecordingTrackMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {streem.api.IFulfillRecordingTrackMuxResponse} m FulfillRecordingTrackMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillRecordingTrackMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingTrackArtifact != null && Object.hasOwnProperty.call(m, "recordingTrackArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingTrackArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillRecordingTrackMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillRecordingTrackMuxResponse} FulfillRecordingTrackMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillRecordingTrackMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillRecordingTrackMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingTrackArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillRecordingTrackMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillRecordingTrackMuxResponse} FulfillRecordingTrackMuxResponse
             */
            FulfillRecordingTrackMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillRecordingTrackMuxResponse)
                    return d;
                var m = new $root.streem.api.FulfillRecordingTrackMuxResponse();
                if (d.recordingTrackArtifact != null) {
                    if (typeof d.recordingTrackArtifact !== "object")
                        throw TypeError(".streem.api.FulfillRecordingTrackMuxResponse.recordingTrackArtifact: object expected");
                    m.recordingTrackArtifact = $root.streem.api.Artifact.fromObject(d.recordingTrackArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillRecordingTrackMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {streem.api.FulfillRecordingTrackMuxResponse} m FulfillRecordingTrackMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillRecordingTrackMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingTrackArtifact = null;
                }
                if (m.recordingTrackArtifact != null && m.hasOwnProperty("recordingTrackArtifact")) {
                    d.recordingTrackArtifact = $root.streem.api.Artifact.toObject(m.recordingTrackArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillRecordingTrackMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillRecordingTrackMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillRecordingTrackMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillRecordingTrackMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillRecordingTrackMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillRecordingTrackMuxResponse";
            };

            return FulfillRecordingTrackMuxResponse;
        })();

        api.FulfillStreemshotRequest = (function() {

            /**
             * Properties of a FulfillStreemshotRequest.
             * @memberof streem.api
             * @interface IFulfillStreemshotRequest
             * @property {string|null} [roomSid] FulfillStreemshotRequest roomSid
             * @property {string|null} [streemshotSid] FulfillStreemshotRequest streemshotSid
             * @property {google.protobuf.ITimestamp|null} [fulfilledAt] FulfillStreemshotRequest fulfilledAt
             * @property {string|null} [fulfilledByUserSid] FulfillStreemshotRequest fulfilledByUserSid
             * @property {string|null} [fulfilledByDeviceSid] FulfillStreemshotRequest fulfilledByDeviceSid
             * @property {string|null} [storageUrl] FulfillStreemshotRequest storageUrl
             * @property {streem.api.IPose|null} [pose] FulfillStreemshotRequest pose
             * @property {Array.<streem.api.IPoint>|null} [featurePoints] FulfillStreemshotRequest featurePoints
             * @property {ICameraIntrinsics|null} [cameraIntrinsics] FulfillStreemshotRequest cameraIntrinsics
             */

            /**
             * Constructs a new FulfillStreemshotRequest.
             * @memberof streem.api
             * @classdesc Represents a FulfillStreemshotRequest.
             * @implements IFulfillStreemshotRequest
             * @constructor
             * @param {streem.api.IFulfillStreemshotRequest=} [p] Properties to set
             */
            function FulfillStreemshotRequest(p) {
                this.featurePoints = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillStreemshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.roomSid = "";

            /**
             * FulfillStreemshotRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.streemshotSid = "";

            /**
             * FulfillStreemshotRequest fulfilledAt.
             * @member {google.protobuf.ITimestamp|null|undefined} fulfilledAt
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.fulfilledAt = null;

            /**
             * FulfillStreemshotRequest fulfilledByUserSid.
             * @member {string} fulfilledByUserSid
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.fulfilledByUserSid = "";

            /**
             * FulfillStreemshotRequest fulfilledByDeviceSid.
             * @member {string} fulfilledByDeviceSid
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.fulfilledByDeviceSid = "";

            /**
             * FulfillStreemshotRequest storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.storageUrl = "";

            /**
             * FulfillStreemshotRequest pose.
             * @member {streem.api.IPose|null|undefined} pose
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.pose = null;

            /**
             * FulfillStreemshotRequest featurePoints.
             * @member {Array.<streem.api.IPoint>} featurePoints
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.featurePoints = $util.emptyArray;

            /**
             * FulfillStreemshotRequest cameraIntrinsics.
             * @member {ICameraIntrinsics|null|undefined} cameraIntrinsics
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             */
            FulfillStreemshotRequest.prototype.cameraIntrinsics = null;

            /**
             * Creates a new FulfillStreemshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {streem.api.IFulfillStreemshotRequest=} [properties] Properties to set
             * @returns {streem.api.FulfillStreemshotRequest} FulfillStreemshotRequest instance
             */
            FulfillStreemshotRequest.create = function create(properties) {
                return new FulfillStreemshotRequest(properties);
            };

            /**
             * Encodes the specified FulfillStreemshotRequest message. Does not implicitly {@link streem.api.FulfillStreemshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {streem.api.IFulfillStreemshotRequest} m FulfillStreemshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillStreemshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                if (m.fulfilledAt != null && Object.hasOwnProperty.call(m, "fulfilledAt"))
                    $root.google.protobuf.Timestamp.encode(m.fulfilledAt, w.uint32(26).fork()).ldelim();
                if (m.fulfilledByUserSid != null && Object.hasOwnProperty.call(m, "fulfilledByUserSid"))
                    w.uint32(34).string(m.fulfilledByUserSid);
                if (m.fulfilledByDeviceSid != null && Object.hasOwnProperty.call(m, "fulfilledByDeviceSid"))
                    w.uint32(42).string(m.fulfilledByDeviceSid);
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(50).string(m.storageUrl);
                if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                    $root.streem.api.Pose.encode(m.pose, w.uint32(58).fork()).ldelim();
                if (m.featurePoints != null && m.featurePoints.length) {
                    for (var i = 0; i < m.featurePoints.length; ++i)
                        $root.streem.api.Point.encode(m.featurePoints[i], w.uint32(66).fork()).ldelim();
                }
                if (m.cameraIntrinsics != null && Object.hasOwnProperty.call(m, "cameraIntrinsics"))
                    $root.CameraIntrinsics.encode(m.cameraIntrinsics, w.uint32(74).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillStreemshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillStreemshotRequest} FulfillStreemshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillStreemshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillStreemshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    case 3: {
                            m.fulfilledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.fulfilledByUserSid = r.string();
                            break;
                        }
                    case 5: {
                            m.fulfilledByDeviceSid = r.string();
                            break;
                        }
                    case 6: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 7: {
                            m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            if (!(m.featurePoints && m.featurePoints.length))
                                m.featurePoints = [];
                            m.featurePoints.push($root.streem.api.Point.decode(r, r.uint32()));
                            break;
                        }
                    case 9: {
                            m.cameraIntrinsics = $root.CameraIntrinsics.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillStreemshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillStreemshotRequest} FulfillStreemshotRequest
             */
            FulfillStreemshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillStreemshotRequest)
                    return d;
                var m = new $root.streem.api.FulfillStreemshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                if (d.fulfilledAt != null) {
                    if (typeof d.fulfilledAt !== "object")
                        throw TypeError(".streem.api.FulfillStreemshotRequest.fulfilledAt: object expected");
                    m.fulfilledAt = $root.google.protobuf.Timestamp.fromObject(d.fulfilledAt);
                }
                if (d.fulfilledByUserSid != null) {
                    m.fulfilledByUserSid = String(d.fulfilledByUserSid);
                }
                if (d.fulfilledByDeviceSid != null) {
                    m.fulfilledByDeviceSid = String(d.fulfilledByDeviceSid);
                }
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                if (d.pose != null) {
                    if (typeof d.pose !== "object")
                        throw TypeError(".streem.api.FulfillStreemshotRequest.pose: object expected");
                    m.pose = $root.streem.api.Pose.fromObject(d.pose);
                }
                if (d.featurePoints) {
                    if (!Array.isArray(d.featurePoints))
                        throw TypeError(".streem.api.FulfillStreemshotRequest.featurePoints: array expected");
                    m.featurePoints = [];
                    for (var i = 0; i < d.featurePoints.length; ++i) {
                        if (typeof d.featurePoints[i] !== "object")
                            throw TypeError(".streem.api.FulfillStreemshotRequest.featurePoints: object expected");
                        m.featurePoints[i] = $root.streem.api.Point.fromObject(d.featurePoints[i]);
                    }
                }
                if (d.cameraIntrinsics != null) {
                    if (typeof d.cameraIntrinsics !== "object")
                        throw TypeError(".streem.api.FulfillStreemshotRequest.cameraIntrinsics: object expected");
                    m.cameraIntrinsics = $root.CameraIntrinsics.fromObject(d.cameraIntrinsics);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillStreemshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {streem.api.FulfillStreemshotRequest} m FulfillStreemshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillStreemshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.featurePoints = [];
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                    d.fulfilledAt = null;
                    d.fulfilledByUserSid = "";
                    d.fulfilledByDeviceSid = "";
                    d.storageUrl = "";
                    d.pose = null;
                    d.cameraIntrinsics = null;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                if (m.fulfilledAt != null && m.hasOwnProperty("fulfilledAt")) {
                    d.fulfilledAt = $root.google.protobuf.Timestamp.toObject(m.fulfilledAt, o);
                }
                if (m.fulfilledByUserSid != null && m.hasOwnProperty("fulfilledByUserSid")) {
                    d.fulfilledByUserSid = m.fulfilledByUserSid;
                }
                if (m.fulfilledByDeviceSid != null && m.hasOwnProperty("fulfilledByDeviceSid")) {
                    d.fulfilledByDeviceSid = m.fulfilledByDeviceSid;
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.pose != null && m.hasOwnProperty("pose")) {
                    d.pose = $root.streem.api.Pose.toObject(m.pose, o);
                }
                if (m.featurePoints && m.featurePoints.length) {
                    d.featurePoints = [];
                    for (var j = 0; j < m.featurePoints.length; ++j) {
                        d.featurePoints[j] = $root.streem.api.Point.toObject(m.featurePoints[j], o);
                    }
                }
                if (m.cameraIntrinsics != null && m.hasOwnProperty("cameraIntrinsics")) {
                    d.cameraIntrinsics = $root.CameraIntrinsics.toObject(m.cameraIntrinsics, o);
                }
                return d;
            };

            /**
             * Converts this FulfillStreemshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillStreemshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillStreemshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillStreemshotRequest
             * @function getTypeUrl
             * @memberof streem.api.FulfillStreemshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillStreemshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillStreemshotRequest";
            };

            return FulfillStreemshotRequest;
        })();

        api.FulfillStreemshotResponse = (function() {

            /**
             * Properties of a FulfillStreemshotResponse.
             * @memberof streem.api
             * @interface IFulfillStreemshotResponse
             * @property {streem.api.IArtifact|null} [streemshotArtifact] FulfillStreemshotResponse streemshotArtifact
             */

            /**
             * Constructs a new FulfillStreemshotResponse.
             * @memberof streem.api
             * @classdesc Represents a FulfillStreemshotResponse.
             * @implements IFulfillStreemshotResponse
             * @constructor
             * @param {streem.api.IFulfillStreemshotResponse=} [p] Properties to set
             */
            function FulfillStreemshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FulfillStreemshotResponse streemshotArtifact.
             * @member {streem.api.IArtifact|null|undefined} streemshotArtifact
             * @memberof streem.api.FulfillStreemshotResponse
             * @instance
             */
            FulfillStreemshotResponse.prototype.streemshotArtifact = null;

            /**
             * Creates a new FulfillStreemshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {streem.api.IFulfillStreemshotResponse=} [properties] Properties to set
             * @returns {streem.api.FulfillStreemshotResponse} FulfillStreemshotResponse instance
             */
            FulfillStreemshotResponse.create = function create(properties) {
                return new FulfillStreemshotResponse(properties);
            };

            /**
             * Encodes the specified FulfillStreemshotResponse message. Does not implicitly {@link streem.api.FulfillStreemshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {streem.api.IFulfillStreemshotResponse} m FulfillStreemshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FulfillStreemshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotArtifact != null && Object.hasOwnProperty.call(m, "streemshotArtifact"))
                    $root.streem.api.Artifact.encode(m.streemshotArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a FulfillStreemshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FulfillStreemshotResponse} FulfillStreemshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FulfillStreemshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FulfillStreemshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemshotArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FulfillStreemshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FulfillStreemshotResponse} FulfillStreemshotResponse
             */
            FulfillStreemshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FulfillStreemshotResponse)
                    return d;
                var m = new $root.streem.api.FulfillStreemshotResponse();
                if (d.streemshotArtifact != null) {
                    if (typeof d.streemshotArtifact !== "object")
                        throw TypeError(".streem.api.FulfillStreemshotResponse.streemshotArtifact: object expected");
                    m.streemshotArtifact = $root.streem.api.Artifact.fromObject(d.streemshotArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a FulfillStreemshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {streem.api.FulfillStreemshotResponse} m FulfillStreemshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FulfillStreemshotResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemshotArtifact = null;
                }
                if (m.streemshotArtifact != null && m.hasOwnProperty("streemshotArtifact")) {
                    d.streemshotArtifact = $root.streem.api.Artifact.toObject(m.streemshotArtifact, o);
                }
                return d;
            };

            /**
             * Converts this FulfillStreemshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FulfillStreemshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FulfillStreemshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FulfillStreemshotResponse
             * @function getTypeUrl
             * @memberof streem.api.FulfillStreemshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FulfillStreemshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FulfillStreemshotResponse";
            };

            return FulfillStreemshotResponse;
        })();

        api.GetBookmarkRequest = (function() {

            /**
             * Properties of a GetBookmarkRequest.
             * @memberof streem.api
             * @interface IGetBookmarkRequest
             * @property {string|null} [roomSid] GetBookmarkRequest roomSid
             * @property {string|null} [bookmarkSid] GetBookmarkRequest bookmarkSid
             */

            /**
             * Constructs a new GetBookmarkRequest.
             * @memberof streem.api
             * @classdesc Represents a GetBookmarkRequest.
             * @implements IGetBookmarkRequest
             * @constructor
             * @param {streem.api.IGetBookmarkRequest=} [p] Properties to set
             */
            function GetBookmarkRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetBookmarkRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetBookmarkRequest
             * @instance
             */
            GetBookmarkRequest.prototype.roomSid = "";

            /**
             * GetBookmarkRequest bookmarkSid.
             * @member {string} bookmarkSid
             * @memberof streem.api.GetBookmarkRequest
             * @instance
             */
            GetBookmarkRequest.prototype.bookmarkSid = "";

            /**
             * Creates a new GetBookmarkRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {streem.api.IGetBookmarkRequest=} [properties] Properties to set
             * @returns {streem.api.GetBookmarkRequest} GetBookmarkRequest instance
             */
            GetBookmarkRequest.create = function create(properties) {
                return new GetBookmarkRequest(properties);
            };

            /**
             * Encodes the specified GetBookmarkRequest message. Does not implicitly {@link streem.api.GetBookmarkRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {streem.api.IGetBookmarkRequest} m GetBookmarkRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBookmarkRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.bookmarkSid != null && Object.hasOwnProperty.call(m, "bookmarkSid"))
                    w.uint32(18).string(m.bookmarkSid);
                return w;
            };

            /**
             * Decodes a GetBookmarkRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetBookmarkRequest} GetBookmarkRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBookmarkRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetBookmarkRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.bookmarkSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetBookmarkRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetBookmarkRequest} GetBookmarkRequest
             */
            GetBookmarkRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetBookmarkRequest)
                    return d;
                var m = new $root.streem.api.GetBookmarkRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.bookmarkSid != null) {
                    m.bookmarkSid = String(d.bookmarkSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetBookmarkRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {streem.api.GetBookmarkRequest} m GetBookmarkRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBookmarkRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.bookmarkSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.bookmarkSid != null && m.hasOwnProperty("bookmarkSid")) {
                    d.bookmarkSid = m.bookmarkSid;
                }
                return d;
            };

            /**
             * Converts this GetBookmarkRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetBookmarkRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBookmarkRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetBookmarkRequest
             * @function getTypeUrl
             * @memberof streem.api.GetBookmarkRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBookmarkRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetBookmarkRequest";
            };

            return GetBookmarkRequest;
        })();

        api.GetBookmarkResponse = (function() {

            /**
             * Properties of a GetBookmarkResponse.
             * @memberof streem.api
             * @interface IGetBookmarkResponse
             * @property {streem.api.IArtifact|null} [bookmarkArtifact] GetBookmarkResponse bookmarkArtifact
             * @property {string|null} [roomSid] GetBookmarkResponse roomSid
             */

            /**
             * Constructs a new GetBookmarkResponse.
             * @memberof streem.api
             * @classdesc Represents a GetBookmarkResponse.
             * @implements IGetBookmarkResponse
             * @constructor
             * @param {streem.api.IGetBookmarkResponse=} [p] Properties to set
             */
            function GetBookmarkResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetBookmarkResponse bookmarkArtifact.
             * @member {streem.api.IArtifact|null|undefined} bookmarkArtifact
             * @memberof streem.api.GetBookmarkResponse
             * @instance
             */
            GetBookmarkResponse.prototype.bookmarkArtifact = null;

            /**
             * GetBookmarkResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetBookmarkResponse
             * @instance
             */
            GetBookmarkResponse.prototype.roomSid = "";

            /**
             * Creates a new GetBookmarkResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {streem.api.IGetBookmarkResponse=} [properties] Properties to set
             * @returns {streem.api.GetBookmarkResponse} GetBookmarkResponse instance
             */
            GetBookmarkResponse.create = function create(properties) {
                return new GetBookmarkResponse(properties);
            };

            /**
             * Encodes the specified GetBookmarkResponse message. Does not implicitly {@link streem.api.GetBookmarkResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {streem.api.IGetBookmarkResponse} m GetBookmarkResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBookmarkResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.bookmarkArtifact != null && Object.hasOwnProperty.call(m, "bookmarkArtifact"))
                    $root.streem.api.Artifact.encode(m.bookmarkArtifact, w.uint32(10).fork()).ldelim();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetBookmarkResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetBookmarkResponse} GetBookmarkResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBookmarkResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetBookmarkResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.bookmarkArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetBookmarkResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetBookmarkResponse} GetBookmarkResponse
             */
            GetBookmarkResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetBookmarkResponse)
                    return d;
                var m = new $root.streem.api.GetBookmarkResponse();
                if (d.bookmarkArtifact != null) {
                    if (typeof d.bookmarkArtifact !== "object")
                        throw TypeError(".streem.api.GetBookmarkResponse.bookmarkArtifact: object expected");
                    m.bookmarkArtifact = $root.streem.api.Artifact.fromObject(d.bookmarkArtifact);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetBookmarkResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {streem.api.GetBookmarkResponse} m GetBookmarkResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBookmarkResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.bookmarkArtifact = null;
                    d.roomSid = "";
                }
                if (m.bookmarkArtifact != null && m.hasOwnProperty("bookmarkArtifact")) {
                    d.bookmarkArtifact = $root.streem.api.Artifact.toObject(m.bookmarkArtifact, o);
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetBookmarkResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetBookmarkResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBookmarkResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetBookmarkResponse
             * @function getTypeUrl
             * @memberof streem.api.GetBookmarkResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBookmarkResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetBookmarkResponse";
            };

            return GetBookmarkResponse;
        })();

        api.GetGpsPositionRequest = (function() {

            /**
             * Properties of a GetGpsPositionRequest.
             * @memberof streem.api
             * @interface IGetGpsPositionRequest
             * @property {string|null} [roomSid] GetGpsPositionRequest roomSid
             * @property {string|null} [gpsPositionSid] GetGpsPositionRequest gpsPositionSid
             */

            /**
             * Constructs a new GetGpsPositionRequest.
             * @memberof streem.api
             * @classdesc Represents a GetGpsPositionRequest.
             * @implements IGetGpsPositionRequest
             * @constructor
             * @param {streem.api.IGetGpsPositionRequest=} [p] Properties to set
             */
            function GetGpsPositionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGpsPositionRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetGpsPositionRequest
             * @instance
             */
            GetGpsPositionRequest.prototype.roomSid = "";

            /**
             * GetGpsPositionRequest gpsPositionSid.
             * @member {string} gpsPositionSid
             * @memberof streem.api.GetGpsPositionRequest
             * @instance
             */
            GetGpsPositionRequest.prototype.gpsPositionSid = "";

            /**
             * Creates a new GetGpsPositionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {streem.api.IGetGpsPositionRequest=} [properties] Properties to set
             * @returns {streem.api.GetGpsPositionRequest} GetGpsPositionRequest instance
             */
            GetGpsPositionRequest.create = function create(properties) {
                return new GetGpsPositionRequest(properties);
            };

            /**
             * Encodes the specified GetGpsPositionRequest message. Does not implicitly {@link streem.api.GetGpsPositionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {streem.api.IGetGpsPositionRequest} m GetGpsPositionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGpsPositionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.gpsPositionSid != null && Object.hasOwnProperty.call(m, "gpsPositionSid"))
                    w.uint32(18).string(m.gpsPositionSid);
                return w;
            };

            /**
             * Decodes a GetGpsPositionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGpsPositionRequest} GetGpsPositionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGpsPositionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGpsPositionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.gpsPositionSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGpsPositionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGpsPositionRequest} GetGpsPositionRequest
             */
            GetGpsPositionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGpsPositionRequest)
                    return d;
                var m = new $root.streem.api.GetGpsPositionRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.gpsPositionSid != null) {
                    m.gpsPositionSid = String(d.gpsPositionSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGpsPositionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {streem.api.GetGpsPositionRequest} m GetGpsPositionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGpsPositionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.gpsPositionSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.gpsPositionSid != null && m.hasOwnProperty("gpsPositionSid")) {
                    d.gpsPositionSid = m.gpsPositionSid;
                }
                return d;
            };

            /**
             * Converts this GetGpsPositionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetGpsPositionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGpsPositionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGpsPositionRequest
             * @function getTypeUrl
             * @memberof streem.api.GetGpsPositionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGpsPositionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGpsPositionRequest";
            };

            return GetGpsPositionRequest;
        })();

        api.GetGpsPositionResponse = (function() {

            /**
             * Properties of a GetGpsPositionResponse.
             * @memberof streem.api
             * @interface IGetGpsPositionResponse
             * @property {streem.api.IArtifact|null} [gpsPositionArtifact] GetGpsPositionResponse gpsPositionArtifact
             * @property {string|null} [roomSid] GetGpsPositionResponse roomSid
             */

            /**
             * Constructs a new GetGpsPositionResponse.
             * @memberof streem.api
             * @classdesc Represents a GetGpsPositionResponse.
             * @implements IGetGpsPositionResponse
             * @constructor
             * @param {streem.api.IGetGpsPositionResponse=} [p] Properties to set
             */
            function GetGpsPositionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetGpsPositionResponse gpsPositionArtifact.
             * @member {streem.api.IArtifact|null|undefined} gpsPositionArtifact
             * @memberof streem.api.GetGpsPositionResponse
             * @instance
             */
            GetGpsPositionResponse.prototype.gpsPositionArtifact = null;

            /**
             * GetGpsPositionResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetGpsPositionResponse
             * @instance
             */
            GetGpsPositionResponse.prototype.roomSid = "";

            /**
             * Creates a new GetGpsPositionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {streem.api.IGetGpsPositionResponse=} [properties] Properties to set
             * @returns {streem.api.GetGpsPositionResponse} GetGpsPositionResponse instance
             */
            GetGpsPositionResponse.create = function create(properties) {
                return new GetGpsPositionResponse(properties);
            };

            /**
             * Encodes the specified GetGpsPositionResponse message. Does not implicitly {@link streem.api.GetGpsPositionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {streem.api.IGetGpsPositionResponse} m GetGpsPositionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGpsPositionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.gpsPositionArtifact != null && Object.hasOwnProperty.call(m, "gpsPositionArtifact"))
                    $root.streem.api.Artifact.encode(m.gpsPositionArtifact, w.uint32(10).fork()).ldelim();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetGpsPositionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetGpsPositionResponse} GetGpsPositionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGpsPositionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetGpsPositionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.gpsPositionArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetGpsPositionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetGpsPositionResponse} GetGpsPositionResponse
             */
            GetGpsPositionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetGpsPositionResponse)
                    return d;
                var m = new $root.streem.api.GetGpsPositionResponse();
                if (d.gpsPositionArtifact != null) {
                    if (typeof d.gpsPositionArtifact !== "object")
                        throw TypeError(".streem.api.GetGpsPositionResponse.gpsPositionArtifact: object expected");
                    m.gpsPositionArtifact = $root.streem.api.Artifact.fromObject(d.gpsPositionArtifact);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetGpsPositionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {streem.api.GetGpsPositionResponse} m GetGpsPositionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGpsPositionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.gpsPositionArtifact = null;
                    d.roomSid = "";
                }
                if (m.gpsPositionArtifact != null && m.hasOwnProperty("gpsPositionArtifact")) {
                    d.gpsPositionArtifact = $root.streem.api.Artifact.toObject(m.gpsPositionArtifact, o);
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetGpsPositionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetGpsPositionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGpsPositionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetGpsPositionResponse
             * @function getTypeUrl
             * @memberof streem.api.GetGpsPositionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetGpsPositionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetGpsPositionResponse";
            };

            return GetGpsPositionResponse;
        })();

        api.GetLayoutEstimationRequest = (function() {

            /**
             * Properties of a GetLayoutEstimationRequest.
             * @memberof streem.api
             * @interface IGetLayoutEstimationRequest
             * @property {string|null} [roomSid] GetLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] GetLayoutEstimationRequest layoutEstimationSid
             */

            /**
             * Constructs a new GetLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetLayoutEstimationRequest.
             * @implements IGetLayoutEstimationRequest
             * @constructor
             * @param {streem.api.IGetLayoutEstimationRequest=} [p] Properties to set
             */
            function GetLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetLayoutEstimationRequest
             * @instance
             */
            GetLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * GetLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.GetLayoutEstimationRequest
             * @instance
             */
            GetLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * Creates a new GetLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {streem.api.IGetLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.GetLayoutEstimationRequest} GetLayoutEstimationRequest instance
             */
            GetLayoutEstimationRequest.create = function create(properties) {
                return new GetLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified GetLayoutEstimationRequest message. Does not implicitly {@link streem.api.GetLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {streem.api.IGetLayoutEstimationRequest} m GetLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                return w;
            };

            /**
             * Decodes a GetLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetLayoutEstimationRequest} GetLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetLayoutEstimationRequest} GetLayoutEstimationRequest
             */
            GetLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.GetLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {streem.api.GetLayoutEstimationRequest} m GetLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                return d;
            };

            /**
             * Converts this GetLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetLayoutEstimationRequest";
            };

            return GetLayoutEstimationRequest;
        })();

        api.GetLayoutEstimationResponse = (function() {

            /**
             * Properties of a GetLayoutEstimationResponse.
             * @memberof streem.api
             * @interface IGetLayoutEstimationResponse
             * @property {streem.api.IArtifact|null} [layoutEstimationArtifact] GetLayoutEstimationResponse layoutEstimationArtifact
             */

            /**
             * Constructs a new GetLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetLayoutEstimationResponse.
             * @implements IGetLayoutEstimationResponse
             * @constructor
             * @param {streem.api.IGetLayoutEstimationResponse=} [p] Properties to set
             */
            function GetLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetLayoutEstimationResponse layoutEstimationArtifact.
             * @member {streem.api.IArtifact|null|undefined} layoutEstimationArtifact
             * @memberof streem.api.GetLayoutEstimationResponse
             * @instance
             */
            GetLayoutEstimationResponse.prototype.layoutEstimationArtifact = null;

            /**
             * Creates a new GetLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {streem.api.IGetLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.GetLayoutEstimationResponse} GetLayoutEstimationResponse instance
             */
            GetLayoutEstimationResponse.create = function create(properties) {
                return new GetLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified GetLayoutEstimationResponse message. Does not implicitly {@link streem.api.GetLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {streem.api.IGetLayoutEstimationResponse} m GetLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.layoutEstimationArtifact != null && Object.hasOwnProperty.call(m, "layoutEstimationArtifact"))
                    $root.streem.api.Artifact.encode(m.layoutEstimationArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetLayoutEstimationResponse} GetLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.layoutEstimationArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetLayoutEstimationResponse} GetLayoutEstimationResponse
             */
            GetLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetLayoutEstimationResponse)
                    return d;
                var m = new $root.streem.api.GetLayoutEstimationResponse();
                if (d.layoutEstimationArtifact != null) {
                    if (typeof d.layoutEstimationArtifact !== "object")
                        throw TypeError(".streem.api.GetLayoutEstimationResponse.layoutEstimationArtifact: object expected");
                    m.layoutEstimationArtifact = $root.streem.api.Artifact.fromObject(d.layoutEstimationArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {streem.api.GetLayoutEstimationResponse} m GetLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLayoutEstimationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.layoutEstimationArtifact = null;
                }
                if (m.layoutEstimationArtifact != null && m.hasOwnProperty("layoutEstimationArtifact")) {
                    d.layoutEstimationArtifact = $root.streem.api.Artifact.toObject(m.layoutEstimationArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetLayoutEstimationResponse";
            };

            return GetLayoutEstimationResponse;
        })();

        api.GetMeshRequest = (function() {

            /**
             * Properties of a GetMeshRequest.
             * @memberof streem.api
             * @interface IGetMeshRequest
             * @property {string|null} [roomSid] GetMeshRequest roomSid
             * @property {string|null} [meshSid] GetMeshRequest meshSid
             */

            /**
             * Constructs a new GetMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a GetMeshRequest.
             * @implements IGetMeshRequest
             * @constructor
             * @param {streem.api.IGetMeshRequest=} [p] Properties to set
             */
            function GetMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetMeshRequest
             * @instance
             */
            GetMeshRequest.prototype.roomSid = "";

            /**
             * GetMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.GetMeshRequest
             * @instance
             */
            GetMeshRequest.prototype.meshSid = "";

            /**
             * Creates a new GetMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {streem.api.IGetMeshRequest=} [properties] Properties to set
             * @returns {streem.api.GetMeshRequest} GetMeshRequest instance
             */
            GetMeshRequest.create = function create(properties) {
                return new GetMeshRequest(properties);
            };

            /**
             * Encodes the specified GetMeshRequest message. Does not implicitly {@link streem.api.GetMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {streem.api.IGetMeshRequest} m GetMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a GetMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetMeshRequest} GetMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetMeshRequest} GetMeshRequest
             */
            GetMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetMeshRequest)
                    return d;
                var m = new $root.streem.api.GetMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {streem.api.GetMeshRequest} m GetMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this GetMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.GetMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetMeshRequest";
            };

            return GetMeshRequest;
        })();

        api.GetMeshResponse = (function() {

            /**
             * Properties of a GetMeshResponse.
             * @memberof streem.api
             * @interface IGetMeshResponse
             * @property {streem.api.IArtifact|null} [meshArtifact] GetMeshResponse meshArtifact
             */

            /**
             * Constructs a new GetMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a GetMeshResponse.
             * @implements IGetMeshResponse
             * @constructor
             * @param {streem.api.IGetMeshResponse=} [p] Properties to set
             */
            function GetMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetMeshResponse meshArtifact.
             * @member {streem.api.IArtifact|null|undefined} meshArtifact
             * @memberof streem.api.GetMeshResponse
             * @instance
             */
            GetMeshResponse.prototype.meshArtifact = null;

            /**
             * Creates a new GetMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {streem.api.IGetMeshResponse=} [properties] Properties to set
             * @returns {streem.api.GetMeshResponse} GetMeshResponse instance
             */
            GetMeshResponse.create = function create(properties) {
                return new GetMeshResponse(properties);
            };

            /**
             * Encodes the specified GetMeshResponse message. Does not implicitly {@link streem.api.GetMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {streem.api.IGetMeshResponse} m GetMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifact != null && Object.hasOwnProperty.call(m, "meshArtifact"))
                    $root.streem.api.Artifact.encode(m.meshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetMeshResponse} GetMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetMeshResponse} GetMeshResponse
             */
            GetMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetMeshResponse)
                    return d;
                var m = new $root.streem.api.GetMeshResponse();
                if (d.meshArtifact != null) {
                    if (typeof d.meshArtifact !== "object")
                        throw TypeError(".streem.api.GetMeshResponse.meshArtifact: object expected");
                    m.meshArtifact = $root.streem.api.Artifact.fromObject(d.meshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {streem.api.GetMeshResponse} m GetMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meshArtifact = null;
                }
                if (m.meshArtifact != null && m.hasOwnProperty("meshArtifact")) {
                    d.meshArtifact = $root.streem.api.Artifact.toObject(m.meshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.GetMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetMeshResponse";
            };

            return GetMeshResponse;
        })();

        api.GetRecordingRequest = (function() {

            /**
             * Properties of a GetRecordingRequest.
             * @memberof streem.api
             * @interface IGetRecordingRequest
             * @property {string|null} [roomSid] GetRecordingRequest roomSid
             * @property {string|null} [recordingSid] GetRecordingRequest recordingSid
             */

            /**
             * Constructs a new GetRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRecordingRequest.
             * @implements IGetRecordingRequest
             * @constructor
             * @param {streem.api.IGetRecordingRequest=} [p] Properties to set
             */
            function GetRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRecordingRequest
             * @instance
             */
            GetRecordingRequest.prototype.roomSid = "";

            /**
             * GetRecordingRequest recordingSid.
             * @member {string} recordingSid
             * @memberof streem.api.GetRecordingRequest
             * @instance
             */
            GetRecordingRequest.prototype.recordingSid = "";

            /**
             * Creates a new GetRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {streem.api.IGetRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.GetRecordingRequest} GetRecordingRequest instance
             */
            GetRecordingRequest.create = function create(properties) {
                return new GetRecordingRequest(properties);
            };

            /**
             * Encodes the specified GetRecordingRequest message. Does not implicitly {@link streem.api.GetRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {streem.api.IGetRecordingRequest} m GetRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingSid != null && Object.hasOwnProperty.call(m, "recordingSid"))
                    w.uint32(18).string(m.recordingSid);
                return w;
            };

            /**
             * Decodes a GetRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRecordingRequest} GetRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRecordingRequest} GetRecordingRequest
             */
            GetRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRecordingRequest)
                    return d;
                var m = new $root.streem.api.GetRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingSid != null) {
                    m.recordingSid = String(d.recordingSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {streem.api.GetRecordingRequest} m GetRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingSid != null && m.hasOwnProperty("recordingSid")) {
                    d.recordingSid = m.recordingSid;
                }
                return d;
            };

            /**
             * Converts this GetRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRecordingRequest";
            };

            return GetRecordingRequest;
        })();

        api.GetRecordingResponse = (function() {

            /**
             * Properties of a GetRecordingResponse.
             * @memberof streem.api
             * @interface IGetRecordingResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] GetRecordingResponse recordingArtifact
             */

            /**
             * Constructs a new GetRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRecordingResponse.
             * @implements IGetRecordingResponse
             * @constructor
             * @param {streem.api.IGetRecordingResponse=} [p] Properties to set
             */
            function GetRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRecordingResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.GetRecordingResponse
             * @instance
             */
            GetRecordingResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new GetRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {streem.api.IGetRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.GetRecordingResponse} GetRecordingResponse instance
             */
            GetRecordingResponse.create = function create(properties) {
                return new GetRecordingResponse(properties);
            };

            /**
             * Encodes the specified GetRecordingResponse message. Does not implicitly {@link streem.api.GetRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {streem.api.IGetRecordingResponse} m GetRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRecordingResponse} GetRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRecordingResponse} GetRecordingResponse
             */
            GetRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRecordingResponse)
                    return d;
                var m = new $root.streem.api.GetRecordingResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.GetRecordingResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {streem.api.GetRecordingResponse} m GetRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRecordingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRecordingResponse";
            };

            return GetRecordingResponse;
        })();

        api.GetRoomArtifactCountsRequest = (function() {

            /**
             * Properties of a GetRoomArtifactCountsRequest.
             * @memberof streem.api
             * @interface IGetRoomArtifactCountsRequest
             * @property {string|null} [roomSid] GetRoomArtifactCountsRequest roomSid
             */

            /**
             * Constructs a new GetRoomArtifactCountsRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomArtifactCountsRequest.
             * @implements IGetRoomArtifactCountsRequest
             * @constructor
             * @param {streem.api.IGetRoomArtifactCountsRequest=} [p] Properties to set
             */
            function GetRoomArtifactCountsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomArtifactCountsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @instance
             */
            GetRoomArtifactCountsRequest.prototype.roomSid = "";

            /**
             * Creates a new GetRoomArtifactCountsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.IGetRoomArtifactCountsRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomArtifactCountsRequest} GetRoomArtifactCountsRequest instance
             */
            GetRoomArtifactCountsRequest.create = function create(properties) {
                return new GetRoomArtifactCountsRequest(properties);
            };

            /**
             * Encodes the specified GetRoomArtifactCountsRequest message. Does not implicitly {@link streem.api.GetRoomArtifactCountsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.IGetRoomArtifactCountsRequest} m GetRoomArtifactCountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomArtifactCountsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomArtifactCountsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomArtifactCountsRequest} GetRoomArtifactCountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomArtifactCountsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomArtifactCountsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomArtifactCountsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomArtifactCountsRequest} GetRoomArtifactCountsRequest
             */
            GetRoomArtifactCountsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomArtifactCountsRequest)
                    return d;
                var m = new $root.streem.api.GetRoomArtifactCountsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomArtifactCountsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {streem.api.GetRoomArtifactCountsRequest} m GetRoomArtifactCountsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomArtifactCountsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomArtifactCountsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomArtifactCountsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomArtifactCountsRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomArtifactCountsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomArtifactCountsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomArtifactCountsRequest";
            };

            return GetRoomArtifactCountsRequest;
        })();

        api.GetRoomArtifactCountsResponse = (function() {

            /**
             * Properties of a GetRoomArtifactCountsResponse.
             * @memberof streem.api
             * @interface IGetRoomArtifactCountsResponse
             * @property {streem.api.IArtifactCounts|null} [artifactCounts] GetRoomArtifactCountsResponse artifactCounts
             * @property {string|null} [roomSid] GetRoomArtifactCountsResponse roomSid
             */

            /**
             * Constructs a new GetRoomArtifactCountsResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomArtifactCountsResponse.
             * @implements IGetRoomArtifactCountsResponse
             * @constructor
             * @param {streem.api.IGetRoomArtifactCountsResponse=} [p] Properties to set
             */
            function GetRoomArtifactCountsResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomArtifactCountsResponse artifactCounts.
             * @member {streem.api.IArtifactCounts|null|undefined} artifactCounts
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @instance
             */
            GetRoomArtifactCountsResponse.prototype.artifactCounts = null;

            /**
             * GetRoomArtifactCountsResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @instance
             */
            GetRoomArtifactCountsResponse.prototype.roomSid = "";

            /**
             * Creates a new GetRoomArtifactCountsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.IGetRoomArtifactCountsResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomArtifactCountsResponse} GetRoomArtifactCountsResponse instance
             */
            GetRoomArtifactCountsResponse.create = function create(properties) {
                return new GetRoomArtifactCountsResponse(properties);
            };

            /**
             * Encodes the specified GetRoomArtifactCountsResponse message. Does not implicitly {@link streem.api.GetRoomArtifactCountsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.IGetRoomArtifactCountsResponse} m GetRoomArtifactCountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomArtifactCountsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.artifactCounts != null && Object.hasOwnProperty.call(m, "artifactCounts"))
                    $root.streem.api.ArtifactCounts.encode(m.artifactCounts, w.uint32(10).fork()).ldelim();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomArtifactCountsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomArtifactCountsResponse} GetRoomArtifactCountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomArtifactCountsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomArtifactCountsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.artifactCounts = $root.streem.api.ArtifactCounts.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomArtifactCountsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomArtifactCountsResponse} GetRoomArtifactCountsResponse
             */
            GetRoomArtifactCountsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomArtifactCountsResponse)
                    return d;
                var m = new $root.streem.api.GetRoomArtifactCountsResponse();
                if (d.artifactCounts != null) {
                    if (typeof d.artifactCounts !== "object")
                        throw TypeError(".streem.api.GetRoomArtifactCountsResponse.artifactCounts: object expected");
                    m.artifactCounts = $root.streem.api.ArtifactCounts.fromObject(d.artifactCounts);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomArtifactCountsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {streem.api.GetRoomArtifactCountsResponse} m GetRoomArtifactCountsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomArtifactCountsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.artifactCounts = null;
                    d.roomSid = "";
                }
                if (m.artifactCounts != null && m.hasOwnProperty("artifactCounts")) {
                    d.artifactCounts = $root.streem.api.ArtifactCounts.toObject(m.artifactCounts, o);
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomArtifactCountsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomArtifactCountsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomArtifactCountsResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomArtifactCountsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomArtifactCountsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomArtifactCountsResponse";
            };

            return GetRoomArtifactCountsResponse;
        })();

        api.GetRoomNoteRequest = (function() {

            /**
             * Properties of a GetRoomNoteRequest.
             * @memberof streem.api
             * @interface IGetRoomNoteRequest
             * @property {string|null} [roomSid] GetRoomNoteRequest roomSid
             */

            /**
             * Constructs a new GetRoomNoteRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomNoteRequest.
             * @implements IGetRoomNoteRequest
             * @constructor
             * @param {streem.api.IGetRoomNoteRequest=} [p] Properties to set
             */
            function GetRoomNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomNoteRequest
             * @instance
             */
            GetRoomNoteRequest.prototype.roomSid = "";

            /**
             * Creates a new GetRoomNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {streem.api.IGetRoomNoteRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomNoteRequest} GetRoomNoteRequest instance
             */
            GetRoomNoteRequest.create = function create(properties) {
                return new GetRoomNoteRequest(properties);
            };

            /**
             * Encodes the specified GetRoomNoteRequest message. Does not implicitly {@link streem.api.GetRoomNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {streem.api.IGetRoomNoteRequest} m GetRoomNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomNoteRequest} GetRoomNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomNoteRequest} GetRoomNoteRequest
             */
            GetRoomNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomNoteRequest)
                    return d;
                var m = new $root.streem.api.GetRoomNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {streem.api.GetRoomNoteRequest} m GetRoomNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomNoteRequest";
            };

            return GetRoomNoteRequest;
        })();

        api.GetRoomNoteResponse = (function() {

            /**
             * Properties of a GetRoomNoteResponse.
             * @memberof streem.api
             * @interface IGetRoomNoteResponse
             * @property {streem.api.INote|null} [note] GetRoomNoteResponse note
             * @property {string|null} [roomSid] GetRoomNoteResponse roomSid
             */

            /**
             * Constructs a new GetRoomNoteResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomNoteResponse.
             * @implements IGetRoomNoteResponse
             * @constructor
             * @param {streem.api.IGetRoomNoteResponse=} [p] Properties to set
             */
            function GetRoomNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomNoteResponse note.
             * @member {streem.api.INote|null|undefined} note
             * @memberof streem.api.GetRoomNoteResponse
             * @instance
             */
            GetRoomNoteResponse.prototype.note = null;

            /**
             * GetRoomNoteResponse roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomNoteResponse
             * @instance
             */
            GetRoomNoteResponse.prototype.roomSid = "";

            /**
             * Creates a new GetRoomNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {streem.api.IGetRoomNoteResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomNoteResponse} GetRoomNoteResponse instance
             */
            GetRoomNoteResponse.create = function create(properties) {
                return new GetRoomNoteResponse(properties);
            };

            /**
             * Encodes the specified GetRoomNoteResponse message. Does not implicitly {@link streem.api.GetRoomNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {streem.api.IGetRoomNoteResponse} m GetRoomNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                    $root.streem.api.Note.encode(m.note, w.uint32(10).fork()).ldelim();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetRoomNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomNoteResponse} GetRoomNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.note = $root.streem.api.Note.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomNoteResponse} GetRoomNoteResponse
             */
            GetRoomNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomNoteResponse)
                    return d;
                var m = new $root.streem.api.GetRoomNoteResponse();
                if (d.note != null) {
                    if (typeof d.note !== "object")
                        throw TypeError(".streem.api.GetRoomNoteResponse.note: object expected");
                    m.note = $root.streem.api.Note.fromObject(d.note);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {streem.api.GetRoomNoteResponse} m GetRoomNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomNoteResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.note = null;
                    d.roomSid = "";
                }
                if (m.note != null && m.hasOwnProperty("note")) {
                    d.note = $root.streem.api.Note.toObject(m.note, o);
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomNoteResponse";
            };

            return GetRoomNoteResponse;
        })();

        api.GetRoomOutcomeReportRequest = (function() {

            /**
             * Properties of a GetRoomOutcomeReportRequest.
             * @memberof streem.api
             * @interface IGetRoomOutcomeReportRequest
             * @property {string|null} [roomSid] GetRoomOutcomeReportRequest roomSid
             * @property {string|null} [roomOutcomeReportSid] GetRoomOutcomeReportRequest roomOutcomeReportSid
             */

            /**
             * Constructs a new GetRoomOutcomeReportRequest.
             * @memberof streem.api
             * @classdesc Represents a GetRoomOutcomeReportRequest.
             * @implements IGetRoomOutcomeReportRequest
             * @constructor
             * @param {streem.api.IGetRoomOutcomeReportRequest=} [p] Properties to set
             */
            function GetRoomOutcomeReportRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomOutcomeReportRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @instance
             */
            GetRoomOutcomeReportRequest.prototype.roomSid = "";

            /**
             * GetRoomOutcomeReportRequest roomOutcomeReportSid.
             * @member {string} roomOutcomeReportSid
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @instance
             */
            GetRoomOutcomeReportRequest.prototype.roomOutcomeReportSid = "";

            /**
             * Creates a new GetRoomOutcomeReportRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {streem.api.IGetRoomOutcomeReportRequest=} [properties] Properties to set
             * @returns {streem.api.GetRoomOutcomeReportRequest} GetRoomOutcomeReportRequest instance
             */
            GetRoomOutcomeReportRequest.create = function create(properties) {
                return new GetRoomOutcomeReportRequest(properties);
            };

            /**
             * Encodes the specified GetRoomOutcomeReportRequest message. Does not implicitly {@link streem.api.GetRoomOutcomeReportRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {streem.api.IGetRoomOutcomeReportRequest} m GetRoomOutcomeReportRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomOutcomeReportRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.roomOutcomeReportSid != null && Object.hasOwnProperty.call(m, "roomOutcomeReportSid"))
                    w.uint32(18).string(m.roomOutcomeReportSid);
                return w;
            };

            /**
             * Decodes a GetRoomOutcomeReportRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomOutcomeReportRequest} GetRoomOutcomeReportRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomOutcomeReportRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomOutcomeReportRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomOutcomeReportSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomOutcomeReportRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomOutcomeReportRequest} GetRoomOutcomeReportRequest
             */
            GetRoomOutcomeReportRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomOutcomeReportRequest)
                    return d;
                var m = new $root.streem.api.GetRoomOutcomeReportRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.roomOutcomeReportSid != null) {
                    m.roomOutcomeReportSid = String(d.roomOutcomeReportSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomOutcomeReportRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {streem.api.GetRoomOutcomeReportRequest} m GetRoomOutcomeReportRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomOutcomeReportRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.roomOutcomeReportSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.roomOutcomeReportSid != null && m.hasOwnProperty("roomOutcomeReportSid")) {
                    d.roomOutcomeReportSid = m.roomOutcomeReportSid;
                }
                return d;
            };

            /**
             * Converts this GetRoomOutcomeReportRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomOutcomeReportRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomOutcomeReportRequest
             * @function getTypeUrl
             * @memberof streem.api.GetRoomOutcomeReportRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomOutcomeReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomOutcomeReportRequest";
            };

            return GetRoomOutcomeReportRequest;
        })();

        api.GetRoomOutcomeReportResponse = (function() {

            /**
             * Properties of a GetRoomOutcomeReportResponse.
             * @memberof streem.api
             * @interface IGetRoomOutcomeReportResponse
             * @property {streem.api.IArtifact|null} [roomOutcomeReportArtifact] GetRoomOutcomeReportResponse roomOutcomeReportArtifact
             */

            /**
             * Constructs a new GetRoomOutcomeReportResponse.
             * @memberof streem.api
             * @classdesc Represents a GetRoomOutcomeReportResponse.
             * @implements IGetRoomOutcomeReportResponse
             * @constructor
             * @param {streem.api.IGetRoomOutcomeReportResponse=} [p] Properties to set
             */
            function GetRoomOutcomeReportResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetRoomOutcomeReportResponse roomOutcomeReportArtifact.
             * @member {streem.api.IArtifact|null|undefined} roomOutcomeReportArtifact
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @instance
             */
            GetRoomOutcomeReportResponse.prototype.roomOutcomeReportArtifact = null;

            /**
             * Creates a new GetRoomOutcomeReportResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {streem.api.IGetRoomOutcomeReportResponse=} [properties] Properties to set
             * @returns {streem.api.GetRoomOutcomeReportResponse} GetRoomOutcomeReportResponse instance
             */
            GetRoomOutcomeReportResponse.create = function create(properties) {
                return new GetRoomOutcomeReportResponse(properties);
            };

            /**
             * Encodes the specified GetRoomOutcomeReportResponse message. Does not implicitly {@link streem.api.GetRoomOutcomeReportResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {streem.api.IGetRoomOutcomeReportResponse} m GetRoomOutcomeReportResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetRoomOutcomeReportResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomOutcomeReportArtifact != null && Object.hasOwnProperty.call(m, "roomOutcomeReportArtifact"))
                    $root.streem.api.Artifact.encode(m.roomOutcomeReportArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetRoomOutcomeReportResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetRoomOutcomeReportResponse} GetRoomOutcomeReportResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetRoomOutcomeReportResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetRoomOutcomeReportResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomOutcomeReportArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetRoomOutcomeReportResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetRoomOutcomeReportResponse} GetRoomOutcomeReportResponse
             */
            GetRoomOutcomeReportResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetRoomOutcomeReportResponse)
                    return d;
                var m = new $root.streem.api.GetRoomOutcomeReportResponse();
                if (d.roomOutcomeReportArtifact != null) {
                    if (typeof d.roomOutcomeReportArtifact !== "object")
                        throw TypeError(".streem.api.GetRoomOutcomeReportResponse.roomOutcomeReportArtifact: object expected");
                    m.roomOutcomeReportArtifact = $root.streem.api.Artifact.fromObject(d.roomOutcomeReportArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetRoomOutcomeReportResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {streem.api.GetRoomOutcomeReportResponse} m GetRoomOutcomeReportResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetRoomOutcomeReportResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomOutcomeReportArtifact = null;
                }
                if (m.roomOutcomeReportArtifact != null && m.hasOwnProperty("roomOutcomeReportArtifact")) {
                    d.roomOutcomeReportArtifact = $root.streem.api.Artifact.toObject(m.roomOutcomeReportArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetRoomOutcomeReportResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetRoomOutcomeReportResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetRoomOutcomeReportResponse
             * @function getTypeUrl
             * @memberof streem.api.GetRoomOutcomeReportResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetRoomOutcomeReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetRoomOutcomeReportResponse";
            };

            return GetRoomOutcomeReportResponse;
        })();

        api.GetSceneshotRequest = (function() {

            /**
             * Properties of a GetSceneshotRequest.
             * @memberof streem.api
             * @interface IGetSceneshotRequest
             * @property {string|null} [roomSid] GetSceneshotRequest roomSid
             * @property {string|null} [sceneshotSid] GetSceneshotRequest sceneshotSid
             */

            /**
             * Constructs a new GetSceneshotRequest.
             * @memberof streem.api
             * @classdesc Represents a GetSceneshotRequest.
             * @implements IGetSceneshotRequest
             * @constructor
             * @param {streem.api.IGetSceneshotRequest=} [p] Properties to set
             */
            function GetSceneshotRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSceneshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetSceneshotRequest
             * @instance
             */
            GetSceneshotRequest.prototype.roomSid = "";

            /**
             * GetSceneshotRequest sceneshotSid.
             * @member {string} sceneshotSid
             * @memberof streem.api.GetSceneshotRequest
             * @instance
             */
            GetSceneshotRequest.prototype.sceneshotSid = "";

            /**
             * Creates a new GetSceneshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {streem.api.IGetSceneshotRequest=} [properties] Properties to set
             * @returns {streem.api.GetSceneshotRequest} GetSceneshotRequest instance
             */
            GetSceneshotRequest.create = function create(properties) {
                return new GetSceneshotRequest(properties);
            };

            /**
             * Encodes the specified GetSceneshotRequest message. Does not implicitly {@link streem.api.GetSceneshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {streem.api.IGetSceneshotRequest} m GetSceneshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSceneshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.sceneshotSid != null && Object.hasOwnProperty.call(m, "sceneshotSid"))
                    w.uint32(18).string(m.sceneshotSid);
                return w;
            };

            /**
             * Decodes a GetSceneshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSceneshotRequest} GetSceneshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSceneshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSceneshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.sceneshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSceneshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSceneshotRequest} GetSceneshotRequest
             */
            GetSceneshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSceneshotRequest)
                    return d;
                var m = new $root.streem.api.GetSceneshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.sceneshotSid != null) {
                    m.sceneshotSid = String(d.sceneshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSceneshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {streem.api.GetSceneshotRequest} m GetSceneshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSceneshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.sceneshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.sceneshotSid != null && m.hasOwnProperty("sceneshotSid")) {
                    d.sceneshotSid = m.sceneshotSid;
                }
                return d;
            };

            /**
             * Converts this GetSceneshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetSceneshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSceneshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSceneshotRequest
             * @function getTypeUrl
             * @memberof streem.api.GetSceneshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSceneshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSceneshotRequest";
            };

            return GetSceneshotRequest;
        })();

        api.GetSceneshotResponse = (function() {

            /**
             * Properties of a GetSceneshotResponse.
             * @memberof streem.api
             * @interface IGetSceneshotResponse
             * @property {streem.api.IArtifact|null} [sceneshotArtifact] GetSceneshotResponse sceneshotArtifact
             */

            /**
             * Constructs a new GetSceneshotResponse.
             * @memberof streem.api
             * @classdesc Represents a GetSceneshotResponse.
             * @implements IGetSceneshotResponse
             * @constructor
             * @param {streem.api.IGetSceneshotResponse=} [p] Properties to set
             */
            function GetSceneshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSceneshotResponse sceneshotArtifact.
             * @member {streem.api.IArtifact|null|undefined} sceneshotArtifact
             * @memberof streem.api.GetSceneshotResponse
             * @instance
             */
            GetSceneshotResponse.prototype.sceneshotArtifact = null;

            /**
             * Creates a new GetSceneshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {streem.api.IGetSceneshotResponse=} [properties] Properties to set
             * @returns {streem.api.GetSceneshotResponse} GetSceneshotResponse instance
             */
            GetSceneshotResponse.create = function create(properties) {
                return new GetSceneshotResponse(properties);
            };

            /**
             * Encodes the specified GetSceneshotResponse message. Does not implicitly {@link streem.api.GetSceneshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {streem.api.IGetSceneshotResponse} m GetSceneshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSceneshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sceneshotArtifact != null && Object.hasOwnProperty.call(m, "sceneshotArtifact"))
                    $root.streem.api.Artifact.encode(m.sceneshotArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetSceneshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSceneshotResponse} GetSceneshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSceneshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSceneshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sceneshotArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSceneshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSceneshotResponse} GetSceneshotResponse
             */
            GetSceneshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSceneshotResponse)
                    return d;
                var m = new $root.streem.api.GetSceneshotResponse();
                if (d.sceneshotArtifact != null) {
                    if (typeof d.sceneshotArtifact !== "object")
                        throw TypeError(".streem.api.GetSceneshotResponse.sceneshotArtifact: object expected");
                    m.sceneshotArtifact = $root.streem.api.Artifact.fromObject(d.sceneshotArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSceneshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {streem.api.GetSceneshotResponse} m GetSceneshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSceneshotResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sceneshotArtifact = null;
                }
                if (m.sceneshotArtifact != null && m.hasOwnProperty("sceneshotArtifact")) {
                    d.sceneshotArtifact = $root.streem.api.Artifact.toObject(m.sceneshotArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetSceneshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetSceneshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSceneshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSceneshotResponse
             * @function getTypeUrl
             * @memberof streem.api.GetSceneshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSceneshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSceneshotResponse";
            };

            return GetSceneshotResponse;
        })();

        api.GetStreemshotRequest = (function() {

            /**
             * Properties of a GetStreemshotRequest.
             * @memberof streem.api
             * @interface IGetStreemshotRequest
             * @property {string|null} [roomSid] GetStreemshotRequest roomSid
             * @property {string|null} [streemshotSid] GetStreemshotRequest streemshotSid
             */

            /**
             * Constructs a new GetStreemshotRequest.
             * @memberof streem.api
             * @classdesc Represents a GetStreemshotRequest.
             * @implements IGetStreemshotRequest
             * @constructor
             * @param {streem.api.IGetStreemshotRequest=} [p] Properties to set
             */
            function GetStreemshotRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemshotRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetStreemshotRequest
             * @instance
             */
            GetStreemshotRequest.prototype.roomSid = "";

            /**
             * GetStreemshotRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.GetStreemshotRequest
             * @instance
             */
            GetStreemshotRequest.prototype.streemshotSid = "";

            /**
             * Creates a new GetStreemshotRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {streem.api.IGetStreemshotRequest=} [properties] Properties to set
             * @returns {streem.api.GetStreemshotRequest} GetStreemshotRequest instance
             */
            GetStreemshotRequest.create = function create(properties) {
                return new GetStreemshotRequest(properties);
            };

            /**
             * Encodes the specified GetStreemshotRequest message. Does not implicitly {@link streem.api.GetStreemshotRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {streem.api.IGetStreemshotRequest} m GetStreemshotRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemshotRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                return w;
            };

            /**
             * Decodes a GetStreemshotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemshotRequest} GetStreemshotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemshotRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemshotRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemshotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemshotRequest} GetStreemshotRequest
             */
            GetStreemshotRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemshotRequest)
                    return d;
                var m = new $root.streem.api.GetStreemshotRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemshotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {streem.api.GetStreemshotRequest} m GetStreemshotRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemshotRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                return d;
            };

            /**
             * Converts this GetStreemshotRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemshotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemshotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemshotRequest
             * @function getTypeUrl
             * @memberof streem.api.GetStreemshotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemshotRequest";
            };

            return GetStreemshotRequest;
        })();

        api.GetStreemshotResponse = (function() {

            /**
             * Properties of a GetStreemshotResponse.
             * @memberof streem.api
             * @interface IGetStreemshotResponse
             * @property {streem.api.IArtifact|null} [streemshotArtifact] GetStreemshotResponse streemshotArtifact
             */

            /**
             * Constructs a new GetStreemshotResponse.
             * @memberof streem.api
             * @classdesc Represents a GetStreemshotResponse.
             * @implements IGetStreemshotResponse
             * @constructor
             * @param {streem.api.IGetStreemshotResponse=} [p] Properties to set
             */
            function GetStreemshotResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemshotResponse streemshotArtifact.
             * @member {streem.api.IArtifact|null|undefined} streemshotArtifact
             * @memberof streem.api.GetStreemshotResponse
             * @instance
             */
            GetStreemshotResponse.prototype.streemshotArtifact = null;

            /**
             * Creates a new GetStreemshotResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {streem.api.IGetStreemshotResponse=} [properties] Properties to set
             * @returns {streem.api.GetStreemshotResponse} GetStreemshotResponse instance
             */
            GetStreemshotResponse.create = function create(properties) {
                return new GetStreemshotResponse(properties);
            };

            /**
             * Encodes the specified GetStreemshotResponse message. Does not implicitly {@link streem.api.GetStreemshotResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {streem.api.IGetStreemshotResponse} m GetStreemshotResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemshotResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotArtifact != null && Object.hasOwnProperty.call(m, "streemshotArtifact"))
                    $root.streem.api.Artifact.encode(m.streemshotArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetStreemshotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemshotResponse} GetStreemshotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemshotResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemshotResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemshotArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemshotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemshotResponse} GetStreemshotResponse
             */
            GetStreemshotResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemshotResponse)
                    return d;
                var m = new $root.streem.api.GetStreemshotResponse();
                if (d.streemshotArtifact != null) {
                    if (typeof d.streemshotArtifact !== "object")
                        throw TypeError(".streem.api.GetStreemshotResponse.streemshotArtifact: object expected");
                    m.streemshotArtifact = $root.streem.api.Artifact.fromObject(d.streemshotArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemshotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {streem.api.GetStreemshotResponse} m GetStreemshotResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemshotResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.streemshotArtifact = null;
                }
                if (m.streemshotArtifact != null && m.hasOwnProperty("streemshotArtifact")) {
                    d.streemshotArtifact = $root.streem.api.Artifact.toObject(m.streemshotArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetStreemshotResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemshotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemshotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemshotResponse
             * @function getTypeUrl
             * @memberof streem.api.GetStreemshotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemshotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemshotResponse";
            };

            return GetStreemshotResponse;
        })();

        api.GetStreemshotNoteRequest = (function() {

            /**
             * Properties of a GetStreemshotNoteRequest.
             * @memberof streem.api
             * @interface IGetStreemshotNoteRequest
             * @property {string|null} [roomSid] GetStreemshotNoteRequest roomSid
             * @property {string|null} [streemshotSid] GetStreemshotNoteRequest streemshotSid
             */

            /**
             * Constructs a new GetStreemshotNoteRequest.
             * @memberof streem.api
             * @classdesc Represents a GetStreemshotNoteRequest.
             * @implements IGetStreemshotNoteRequest
             * @constructor
             * @param {streem.api.IGetStreemshotNoteRequest=} [p] Properties to set
             */
            function GetStreemshotNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemshotNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetStreemshotNoteRequest
             * @instance
             */
            GetStreemshotNoteRequest.prototype.roomSid = "";

            /**
             * GetStreemshotNoteRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.GetStreemshotNoteRequest
             * @instance
             */
            GetStreemshotNoteRequest.prototype.streemshotSid = "";

            /**
             * Creates a new GetStreemshotNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {streem.api.IGetStreemshotNoteRequest=} [properties] Properties to set
             * @returns {streem.api.GetStreemshotNoteRequest} GetStreemshotNoteRequest instance
             */
            GetStreemshotNoteRequest.create = function create(properties) {
                return new GetStreemshotNoteRequest(properties);
            };

            /**
             * Encodes the specified GetStreemshotNoteRequest message. Does not implicitly {@link streem.api.GetStreemshotNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {streem.api.IGetStreemshotNoteRequest} m GetStreemshotNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemshotNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                return w;
            };

            /**
             * Decodes a GetStreemshotNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemshotNoteRequest} GetStreemshotNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemshotNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemshotNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemshotNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemshotNoteRequest} GetStreemshotNoteRequest
             */
            GetStreemshotNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemshotNoteRequest)
                    return d;
                var m = new $root.streem.api.GetStreemshotNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemshotNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {streem.api.GetStreemshotNoteRequest} m GetStreemshotNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemshotNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                return d;
            };

            /**
             * Converts this GetStreemshotNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemshotNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemshotNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemshotNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.GetStreemshotNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemshotNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemshotNoteRequest";
            };

            return GetStreemshotNoteRequest;
        })();

        api.GetStreemshotNoteResponse = (function() {

            /**
             * Properties of a GetStreemshotNoteResponse.
             * @memberof streem.api
             * @interface IGetStreemshotNoteResponse
             * @property {streem.api.INote|null} [note] GetStreemshotNoteResponse note
             * @property {string|null} [streemshotSid] GetStreemshotNoteResponse streemshotSid
             */

            /**
             * Constructs a new GetStreemshotNoteResponse.
             * @memberof streem.api
             * @classdesc Represents a GetStreemshotNoteResponse.
             * @implements IGetStreemshotNoteResponse
             * @constructor
             * @param {streem.api.IGetStreemshotNoteResponse=} [p] Properties to set
             */
            function GetStreemshotNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemshotNoteResponse note.
             * @member {streem.api.INote|null|undefined} note
             * @memberof streem.api.GetStreemshotNoteResponse
             * @instance
             */
            GetStreemshotNoteResponse.prototype.note = null;

            /**
             * GetStreemshotNoteResponse streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.GetStreemshotNoteResponse
             * @instance
             */
            GetStreemshotNoteResponse.prototype.streemshotSid = "";

            /**
             * Creates a new GetStreemshotNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {streem.api.IGetStreemshotNoteResponse=} [properties] Properties to set
             * @returns {streem.api.GetStreemshotNoteResponse} GetStreemshotNoteResponse instance
             */
            GetStreemshotNoteResponse.create = function create(properties) {
                return new GetStreemshotNoteResponse(properties);
            };

            /**
             * Encodes the specified GetStreemshotNoteResponse message. Does not implicitly {@link streem.api.GetStreemshotNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {streem.api.IGetStreemshotNoteResponse} m GetStreemshotNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemshotNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                    $root.streem.api.Note.encode(m.note, w.uint32(10).fork()).ldelim();
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                return w;
            };

            /**
             * Decodes a GetStreemshotNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemshotNoteResponse} GetStreemshotNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemshotNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemshotNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.note = $root.streem.api.Note.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemshotNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemshotNoteResponse} GetStreemshotNoteResponse
             */
            GetStreemshotNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemshotNoteResponse)
                    return d;
                var m = new $root.streem.api.GetStreemshotNoteResponse();
                if (d.note != null) {
                    if (typeof d.note !== "object")
                        throw TypeError(".streem.api.GetStreemshotNoteResponse.note: object expected");
                    m.note = $root.streem.api.Note.fromObject(d.note);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemshotNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {streem.api.GetStreemshotNoteResponse} m GetStreemshotNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemshotNoteResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.note = null;
                    d.streemshotSid = "";
                }
                if (m.note != null && m.hasOwnProperty("note")) {
                    d.note = $root.streem.api.Note.toObject(m.note, o);
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                return d;
            };

            /**
             * Converts this GetStreemshotNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemshotNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemshotNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemshotNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.GetStreemshotNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemshotNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemshotNoteResponse";
            };

            return GetStreemshotNoteResponse;
        })();

        api.GetTexturedMeshRequest = (function() {

            /**
             * Properties of a GetTexturedMeshRequest.
             * @memberof streem.api
             * @interface IGetTexturedMeshRequest
             * @property {string|null} [roomSid] GetTexturedMeshRequest roomSid
             * @property {string|null} [texturedMeshSid] GetTexturedMeshRequest texturedMeshSid
             */

            /**
             * Constructs a new GetTexturedMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a GetTexturedMeshRequest.
             * @implements IGetTexturedMeshRequest
             * @constructor
             * @param {streem.api.IGetTexturedMeshRequest=} [p] Properties to set
             */
            function GetTexturedMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetTexturedMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetTexturedMeshRequest
             * @instance
             */
            GetTexturedMeshRequest.prototype.roomSid = "";

            /**
             * GetTexturedMeshRequest texturedMeshSid.
             * @member {string} texturedMeshSid
             * @memberof streem.api.GetTexturedMeshRequest
             * @instance
             */
            GetTexturedMeshRequest.prototype.texturedMeshSid = "";

            /**
             * Creates a new GetTexturedMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {streem.api.IGetTexturedMeshRequest=} [properties] Properties to set
             * @returns {streem.api.GetTexturedMeshRequest} GetTexturedMeshRequest instance
             */
            GetTexturedMeshRequest.create = function create(properties) {
                return new GetTexturedMeshRequest(properties);
            };

            /**
             * Encodes the specified GetTexturedMeshRequest message. Does not implicitly {@link streem.api.GetTexturedMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {streem.api.IGetTexturedMeshRequest} m GetTexturedMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTexturedMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.texturedMeshSid != null && Object.hasOwnProperty.call(m, "texturedMeshSid"))
                    w.uint32(18).string(m.texturedMeshSid);
                return w;
            };

            /**
             * Decodes a GetTexturedMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTexturedMeshRequest} GetTexturedMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTexturedMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTexturedMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.texturedMeshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTexturedMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTexturedMeshRequest} GetTexturedMeshRequest
             */
            GetTexturedMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTexturedMeshRequest)
                    return d;
                var m = new $root.streem.api.GetTexturedMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.texturedMeshSid != null) {
                    m.texturedMeshSid = String(d.texturedMeshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetTexturedMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {streem.api.GetTexturedMeshRequest} m GetTexturedMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTexturedMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.texturedMeshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.texturedMeshSid != null && m.hasOwnProperty("texturedMeshSid")) {
                    d.texturedMeshSid = m.texturedMeshSid;
                }
                return d;
            };

            /**
             * Converts this GetTexturedMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetTexturedMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTexturedMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTexturedMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.GetTexturedMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTexturedMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTexturedMeshRequest";
            };

            return GetTexturedMeshRequest;
        })();

        api.GetTexturedMeshResponse = (function() {

            /**
             * Properties of a GetTexturedMeshResponse.
             * @memberof streem.api
             * @interface IGetTexturedMeshResponse
             * @property {streem.api.IArtifact|null} [texturedMeshArtifact] GetTexturedMeshResponse texturedMeshArtifact
             */

            /**
             * Constructs a new GetTexturedMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a GetTexturedMeshResponse.
             * @implements IGetTexturedMeshResponse
             * @constructor
             * @param {streem.api.IGetTexturedMeshResponse=} [p] Properties to set
             */
            function GetTexturedMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetTexturedMeshResponse texturedMeshArtifact.
             * @member {streem.api.IArtifact|null|undefined} texturedMeshArtifact
             * @memberof streem.api.GetTexturedMeshResponse
             * @instance
             */
            GetTexturedMeshResponse.prototype.texturedMeshArtifact = null;

            /**
             * Creates a new GetTexturedMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {streem.api.IGetTexturedMeshResponse=} [properties] Properties to set
             * @returns {streem.api.GetTexturedMeshResponse} GetTexturedMeshResponse instance
             */
            GetTexturedMeshResponse.create = function create(properties) {
                return new GetTexturedMeshResponse(properties);
            };

            /**
             * Encodes the specified GetTexturedMeshResponse message. Does not implicitly {@link streem.api.GetTexturedMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {streem.api.IGetTexturedMeshResponse} m GetTexturedMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTexturedMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.texturedMeshArtifact != null && Object.hasOwnProperty.call(m, "texturedMeshArtifact"))
                    $root.streem.api.Artifact.encode(m.texturedMeshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetTexturedMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetTexturedMeshResponse} GetTexturedMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTexturedMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetTexturedMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.texturedMeshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetTexturedMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetTexturedMeshResponse} GetTexturedMeshResponse
             */
            GetTexturedMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetTexturedMeshResponse)
                    return d;
                var m = new $root.streem.api.GetTexturedMeshResponse();
                if (d.texturedMeshArtifact != null) {
                    if (typeof d.texturedMeshArtifact !== "object")
                        throw TypeError(".streem.api.GetTexturedMeshResponse.texturedMeshArtifact: object expected");
                    m.texturedMeshArtifact = $root.streem.api.Artifact.fromObject(d.texturedMeshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetTexturedMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {streem.api.GetTexturedMeshResponse} m GetTexturedMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTexturedMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.texturedMeshArtifact = null;
                }
                if (m.texturedMeshArtifact != null && m.hasOwnProperty("texturedMeshArtifact")) {
                    d.texturedMeshArtifact = $root.streem.api.Artifact.toObject(m.texturedMeshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this GetTexturedMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetTexturedMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTexturedMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetTexturedMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.GetTexturedMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTexturedMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetTexturedMeshResponse";
            };

            return GetTexturedMeshResponse;
        })();

        api.ListGpsPositionsRequest = (function() {

            /**
             * Properties of a ListGpsPositionsRequest.
             * @memberof streem.api
             * @interface IListGpsPositionsRequest
             * @property {string|null} [roomSid] ListGpsPositionsRequest roomSid
             * @property {number|null} [pageSize] ListGpsPositionsRequest pageSize
             * @property {string|null} [pageToken] ListGpsPositionsRequest pageToken
             */

            /**
             * Constructs a new ListGpsPositionsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListGpsPositionsRequest.
             * @implements IListGpsPositionsRequest
             * @constructor
             * @param {streem.api.IListGpsPositionsRequest=} [p] Properties to set
             */
            function ListGpsPositionsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGpsPositionsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListGpsPositionsRequest
             * @instance
             */
            ListGpsPositionsRequest.prototype.roomSid = "";

            /**
             * ListGpsPositionsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListGpsPositionsRequest
             * @instance
             */
            ListGpsPositionsRequest.prototype.pageSize = 0;

            /**
             * ListGpsPositionsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListGpsPositionsRequest
             * @instance
             */
            ListGpsPositionsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListGpsPositionsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {streem.api.IListGpsPositionsRequest=} [properties] Properties to set
             * @returns {streem.api.ListGpsPositionsRequest} ListGpsPositionsRequest instance
             */
            ListGpsPositionsRequest.create = function create(properties) {
                return new ListGpsPositionsRequest(properties);
            };

            /**
             * Encodes the specified ListGpsPositionsRequest message. Does not implicitly {@link streem.api.ListGpsPositionsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {streem.api.IListGpsPositionsRequest} m ListGpsPositionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGpsPositionsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListGpsPositionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGpsPositionsRequest} ListGpsPositionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGpsPositionsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGpsPositionsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGpsPositionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGpsPositionsRequest} ListGpsPositionsRequest
             */
            ListGpsPositionsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGpsPositionsRequest)
                    return d;
                var m = new $root.streem.api.ListGpsPositionsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGpsPositionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {streem.api.ListGpsPositionsRequest} m ListGpsPositionsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGpsPositionsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListGpsPositionsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListGpsPositionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGpsPositionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGpsPositionsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListGpsPositionsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGpsPositionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGpsPositionsRequest";
            };

            return ListGpsPositionsRequest;
        })();

        api.ListGpsPositionsResponse = (function() {

            /**
             * Properties of a ListGpsPositionsResponse.
             * @memberof streem.api
             * @interface IListGpsPositionsResponse
             * @property {Array.<streem.api.IArtifact>|null} [gpsPositions] ListGpsPositionsResponse gpsPositions
             * @property {string|null} [nextPageToken] ListGpsPositionsResponse nextPageToken
             * @property {number|null} [totalSize] ListGpsPositionsResponse totalSize
             */

            /**
             * Constructs a new ListGpsPositionsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListGpsPositionsResponse.
             * @implements IListGpsPositionsResponse
             * @constructor
             * @param {streem.api.IListGpsPositionsResponse=} [p] Properties to set
             */
            function ListGpsPositionsResponse(p) {
                this.gpsPositions = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListGpsPositionsResponse gpsPositions.
             * @member {Array.<streem.api.IArtifact>} gpsPositions
             * @memberof streem.api.ListGpsPositionsResponse
             * @instance
             */
            ListGpsPositionsResponse.prototype.gpsPositions = $util.emptyArray;

            /**
             * ListGpsPositionsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListGpsPositionsResponse
             * @instance
             */
            ListGpsPositionsResponse.prototype.nextPageToken = "";

            /**
             * ListGpsPositionsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListGpsPositionsResponse
             * @instance
             */
            ListGpsPositionsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListGpsPositionsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {streem.api.IListGpsPositionsResponse=} [properties] Properties to set
             * @returns {streem.api.ListGpsPositionsResponse} ListGpsPositionsResponse instance
             */
            ListGpsPositionsResponse.create = function create(properties) {
                return new ListGpsPositionsResponse(properties);
            };

            /**
             * Encodes the specified ListGpsPositionsResponse message. Does not implicitly {@link streem.api.ListGpsPositionsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {streem.api.IListGpsPositionsResponse} m ListGpsPositionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListGpsPositionsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.gpsPositions != null && m.gpsPositions.length) {
                    for (var i = 0; i < m.gpsPositions.length; ++i)
                        $root.streem.api.Artifact.encode(m.gpsPositions[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListGpsPositionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListGpsPositionsResponse} ListGpsPositionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListGpsPositionsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListGpsPositionsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.gpsPositions && m.gpsPositions.length))
                                m.gpsPositions = [];
                            m.gpsPositions.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListGpsPositionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListGpsPositionsResponse} ListGpsPositionsResponse
             */
            ListGpsPositionsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListGpsPositionsResponse)
                    return d;
                var m = new $root.streem.api.ListGpsPositionsResponse();
                if (d.gpsPositions) {
                    if (!Array.isArray(d.gpsPositions))
                        throw TypeError(".streem.api.ListGpsPositionsResponse.gpsPositions: array expected");
                    m.gpsPositions = [];
                    for (var i = 0; i < d.gpsPositions.length; ++i) {
                        if (typeof d.gpsPositions[i] !== "object")
                            throw TypeError(".streem.api.ListGpsPositionsResponse.gpsPositions: object expected");
                        m.gpsPositions[i] = $root.streem.api.Artifact.fromObject(d.gpsPositions[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListGpsPositionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {streem.api.ListGpsPositionsResponse} m ListGpsPositionsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListGpsPositionsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.gpsPositions = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.gpsPositions && m.gpsPositions.length) {
                    d.gpsPositions = [];
                    for (var j = 0; j < m.gpsPositions.length; ++j) {
                        d.gpsPositions[j] = $root.streem.api.Artifact.toObject(m.gpsPositions[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListGpsPositionsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListGpsPositionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListGpsPositionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListGpsPositionsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListGpsPositionsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListGpsPositionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListGpsPositionsResponse";
            };

            return ListGpsPositionsResponse;
        })();

        api.ListMeshesRequest = (function() {

            /**
             * Properties of a ListMeshesRequest.
             * @memberof streem.api
             * @interface IListMeshesRequest
             * @property {string|null} [roomSid] ListMeshesRequest roomSid
             * @property {number|null} [pageSize] ListMeshesRequest pageSize
             * @property {string|null} [pageToken] ListMeshesRequest pageToken
             */

            /**
             * Constructs a new ListMeshesRequest.
             * @memberof streem.api
             * @classdesc Represents a ListMeshesRequest.
             * @implements IListMeshesRequest
             * @constructor
             * @param {streem.api.IListMeshesRequest=} [p] Properties to set
             */
            function ListMeshesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListMeshesRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListMeshesRequest
             * @instance
             */
            ListMeshesRequest.prototype.roomSid = "";

            /**
             * ListMeshesRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListMeshesRequest
             * @instance
             */
            ListMeshesRequest.prototype.pageSize = 0;

            /**
             * ListMeshesRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListMeshesRequest
             * @instance
             */
            ListMeshesRequest.prototype.pageToken = "";

            /**
             * Creates a new ListMeshesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {streem.api.IListMeshesRequest=} [properties] Properties to set
             * @returns {streem.api.ListMeshesRequest} ListMeshesRequest instance
             */
            ListMeshesRequest.create = function create(properties) {
                return new ListMeshesRequest(properties);
            };

            /**
             * Encodes the specified ListMeshesRequest message. Does not implicitly {@link streem.api.ListMeshesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {streem.api.IListMeshesRequest} m ListMeshesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListMeshesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListMeshesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListMeshesRequest} ListMeshesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListMeshesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListMeshesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListMeshesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListMeshesRequest} ListMeshesRequest
             */
            ListMeshesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListMeshesRequest)
                    return d;
                var m = new $root.streem.api.ListMeshesRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListMeshesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {streem.api.ListMeshesRequest} m ListMeshesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListMeshesRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListMeshesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListMeshesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListMeshesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListMeshesRequest
             * @function getTypeUrl
             * @memberof streem.api.ListMeshesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListMeshesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListMeshesRequest";
            };

            return ListMeshesRequest;
        })();

        api.ListMeshesResponse = (function() {

            /**
             * Properties of a ListMeshesResponse.
             * @memberof streem.api
             * @interface IListMeshesResponse
             * @property {Array.<streem.api.IArtifact>|null} [meshArtifacts] ListMeshesResponse meshArtifacts
             * @property {string|null} [nextPageToken] ListMeshesResponse nextPageToken
             * @property {number|null} [totalSize] ListMeshesResponse totalSize
             */

            /**
             * Constructs a new ListMeshesResponse.
             * @memberof streem.api
             * @classdesc Represents a ListMeshesResponse.
             * @implements IListMeshesResponse
             * @constructor
             * @param {streem.api.IListMeshesResponse=} [p] Properties to set
             */
            function ListMeshesResponse(p) {
                this.meshArtifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListMeshesResponse meshArtifacts.
             * @member {Array.<streem.api.IArtifact>} meshArtifacts
             * @memberof streem.api.ListMeshesResponse
             * @instance
             */
            ListMeshesResponse.prototype.meshArtifacts = $util.emptyArray;

            /**
             * ListMeshesResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListMeshesResponse
             * @instance
             */
            ListMeshesResponse.prototype.nextPageToken = "";

            /**
             * ListMeshesResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListMeshesResponse
             * @instance
             */
            ListMeshesResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListMeshesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {streem.api.IListMeshesResponse=} [properties] Properties to set
             * @returns {streem.api.ListMeshesResponse} ListMeshesResponse instance
             */
            ListMeshesResponse.create = function create(properties) {
                return new ListMeshesResponse(properties);
            };

            /**
             * Encodes the specified ListMeshesResponse message. Does not implicitly {@link streem.api.ListMeshesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {streem.api.IListMeshesResponse} m ListMeshesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListMeshesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifacts != null && m.meshArtifacts.length) {
                    for (var i = 0; i < m.meshArtifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.meshArtifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListMeshesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListMeshesResponse} ListMeshesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListMeshesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListMeshesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.meshArtifacts && m.meshArtifacts.length))
                                m.meshArtifacts = [];
                            m.meshArtifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListMeshesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListMeshesResponse} ListMeshesResponse
             */
            ListMeshesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListMeshesResponse)
                    return d;
                var m = new $root.streem.api.ListMeshesResponse();
                if (d.meshArtifacts) {
                    if (!Array.isArray(d.meshArtifacts))
                        throw TypeError(".streem.api.ListMeshesResponse.meshArtifacts: array expected");
                    m.meshArtifacts = [];
                    for (var i = 0; i < d.meshArtifacts.length; ++i) {
                        if (typeof d.meshArtifacts[i] !== "object")
                            throw TypeError(".streem.api.ListMeshesResponse.meshArtifacts: object expected");
                        m.meshArtifacts[i] = $root.streem.api.Artifact.fromObject(d.meshArtifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListMeshesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {streem.api.ListMeshesResponse} m ListMeshesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListMeshesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.meshArtifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.meshArtifacts && m.meshArtifacts.length) {
                    d.meshArtifacts = [];
                    for (var j = 0; j < m.meshArtifacts.length; ++j) {
                        d.meshArtifacts[j] = $root.streem.api.Artifact.toObject(m.meshArtifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListMeshesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListMeshesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListMeshesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListMeshesResponse
             * @function getTypeUrl
             * @memberof streem.api.ListMeshesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListMeshesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListMeshesResponse";
            };

            return ListMeshesResponse;
        })();

        api.ListRecordingsRequest = (function() {

            /**
             * Properties of a ListRecordingsRequest.
             * @memberof streem.api
             * @interface IListRecordingsRequest
             * @property {string|null} [roomSid] ListRecordingsRequest roomSid
             * @property {number|null} [pageSize] ListRecordingsRequest pageSize
             * @property {string|null} [pageToken] ListRecordingsRequest pageToken
             */

            /**
             * Constructs a new ListRecordingsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRecordingsRequest.
             * @implements IListRecordingsRequest
             * @constructor
             * @param {streem.api.IListRecordingsRequest=} [p] Properties to set
             */
            function ListRecordingsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRecordingsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListRecordingsRequest
             * @instance
             */
            ListRecordingsRequest.prototype.roomSid = "";

            /**
             * ListRecordingsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRecordingsRequest
             * @instance
             */
            ListRecordingsRequest.prototype.pageSize = 0;

            /**
             * ListRecordingsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRecordingsRequest
             * @instance
             */
            ListRecordingsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRecordingsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {streem.api.IListRecordingsRequest=} [properties] Properties to set
             * @returns {streem.api.ListRecordingsRequest} ListRecordingsRequest instance
             */
            ListRecordingsRequest.create = function create(properties) {
                return new ListRecordingsRequest(properties);
            };

            /**
             * Encodes the specified ListRecordingsRequest message. Does not implicitly {@link streem.api.ListRecordingsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {streem.api.IListRecordingsRequest} m ListRecordingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRecordingsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRecordingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRecordingsRequest} ListRecordingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRecordingsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRecordingsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRecordingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRecordingsRequest} ListRecordingsRequest
             */
            ListRecordingsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRecordingsRequest)
                    return d;
                var m = new $root.streem.api.ListRecordingsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRecordingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {streem.api.ListRecordingsRequest} m ListRecordingsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRecordingsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRecordingsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRecordingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRecordingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRecordingsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRecordingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRecordingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRecordingsRequest";
            };

            return ListRecordingsRequest;
        })();

        api.ListRecordingsResponse = (function() {

            /**
             * Properties of a ListRecordingsResponse.
             * @memberof streem.api
             * @interface IListRecordingsResponse
             * @property {Array.<streem.api.IArtifact>|null} [recordingArtifacts] ListRecordingsResponse recordingArtifacts
             * @property {string|null} [nextPageToken] ListRecordingsResponse nextPageToken
             * @property {number|null} [totalSize] ListRecordingsResponse totalSize
             */

            /**
             * Constructs a new ListRecordingsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRecordingsResponse.
             * @implements IListRecordingsResponse
             * @constructor
             * @param {streem.api.IListRecordingsResponse=} [p] Properties to set
             */
            function ListRecordingsResponse(p) {
                this.recordingArtifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRecordingsResponse recordingArtifacts.
             * @member {Array.<streem.api.IArtifact>} recordingArtifacts
             * @memberof streem.api.ListRecordingsResponse
             * @instance
             */
            ListRecordingsResponse.prototype.recordingArtifacts = $util.emptyArray;

            /**
             * ListRecordingsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRecordingsResponse
             * @instance
             */
            ListRecordingsResponse.prototype.nextPageToken = "";

            /**
             * ListRecordingsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRecordingsResponse
             * @instance
             */
            ListRecordingsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRecordingsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {streem.api.IListRecordingsResponse=} [properties] Properties to set
             * @returns {streem.api.ListRecordingsResponse} ListRecordingsResponse instance
             */
            ListRecordingsResponse.create = function create(properties) {
                return new ListRecordingsResponse(properties);
            };

            /**
             * Encodes the specified ListRecordingsResponse message. Does not implicitly {@link streem.api.ListRecordingsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {streem.api.IListRecordingsResponse} m ListRecordingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRecordingsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifacts != null && m.recordingArtifacts.length) {
                    for (var i = 0; i < m.recordingArtifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.recordingArtifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRecordingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRecordingsResponse} ListRecordingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRecordingsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRecordingsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.recordingArtifacts && m.recordingArtifacts.length))
                                m.recordingArtifacts = [];
                            m.recordingArtifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRecordingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRecordingsResponse} ListRecordingsResponse
             */
            ListRecordingsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRecordingsResponse)
                    return d;
                var m = new $root.streem.api.ListRecordingsResponse();
                if (d.recordingArtifacts) {
                    if (!Array.isArray(d.recordingArtifacts))
                        throw TypeError(".streem.api.ListRecordingsResponse.recordingArtifacts: array expected");
                    m.recordingArtifacts = [];
                    for (var i = 0; i < d.recordingArtifacts.length; ++i) {
                        if (typeof d.recordingArtifacts[i] !== "object")
                            throw TypeError(".streem.api.ListRecordingsResponse.recordingArtifacts: object expected");
                        m.recordingArtifacts[i] = $root.streem.api.Artifact.fromObject(d.recordingArtifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRecordingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {streem.api.ListRecordingsResponse} m ListRecordingsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRecordingsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.recordingArtifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.recordingArtifacts && m.recordingArtifacts.length) {
                    d.recordingArtifacts = [];
                    for (var j = 0; j < m.recordingArtifacts.length; ++j) {
                        d.recordingArtifacts[j] = $root.streem.api.Artifact.toObject(m.recordingArtifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRecordingsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRecordingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRecordingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRecordingsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRecordingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRecordingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRecordingsResponse";
            };

            return ListRecordingsResponse;
        })();

        api.ListRoomArtifactsRequest = (function() {

            /**
             * Properties of a ListRoomArtifactsRequest.
             * @memberof streem.api
             * @interface IListRoomArtifactsRequest
             * @property {string|null} [roomSid] ListRoomArtifactsRequest roomSid
             * @property {number|null} [pageSize] ListRoomArtifactsRequest pageSize
             * @property {string|null} [pageToken] ListRoomArtifactsRequest pageToken
             */

            /**
             * Constructs a new ListRoomArtifactsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRoomArtifactsRequest.
             * @implements IListRoomArtifactsRequest
             * @constructor
             * @param {streem.api.IListRoomArtifactsRequest=} [p] Properties to set
             */
            function ListRoomArtifactsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomArtifactsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListRoomArtifactsRequest
             * @instance
             */
            ListRoomArtifactsRequest.prototype.roomSid = "";

            /**
             * ListRoomArtifactsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRoomArtifactsRequest
             * @instance
             */
            ListRoomArtifactsRequest.prototype.pageSize = 0;

            /**
             * ListRoomArtifactsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRoomArtifactsRequest
             * @instance
             */
            ListRoomArtifactsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRoomArtifactsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {streem.api.IListRoomArtifactsRequest=} [properties] Properties to set
             * @returns {streem.api.ListRoomArtifactsRequest} ListRoomArtifactsRequest instance
             */
            ListRoomArtifactsRequest.create = function create(properties) {
                return new ListRoomArtifactsRequest(properties);
            };

            /**
             * Encodes the specified ListRoomArtifactsRequest message. Does not implicitly {@link streem.api.ListRoomArtifactsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {streem.api.IListRoomArtifactsRequest} m ListRoomArtifactsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomArtifactsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRoomArtifactsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomArtifactsRequest} ListRoomArtifactsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomArtifactsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomArtifactsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomArtifactsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomArtifactsRequest} ListRoomArtifactsRequest
             */
            ListRoomArtifactsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomArtifactsRequest)
                    return d;
                var m = new $root.streem.api.ListRoomArtifactsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomArtifactsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {streem.api.ListRoomArtifactsRequest} m ListRoomArtifactsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomArtifactsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRoomArtifactsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomArtifactsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomArtifactsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomArtifactsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRoomArtifactsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomArtifactsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomArtifactsRequest";
            };

            return ListRoomArtifactsRequest;
        })();

        api.ListRoomArtifactsResponse = (function() {

            /**
             * Properties of a ListRoomArtifactsResponse.
             * @memberof streem.api
             * @interface IListRoomArtifactsResponse
             * @property {Array.<streem.api.IArtifact>|null} [artifacts] ListRoomArtifactsResponse artifacts
             * @property {string|null} [nextPageToken] ListRoomArtifactsResponse nextPageToken
             * @property {number|null} [totalSize] ListRoomArtifactsResponse totalSize
             */

            /**
             * Constructs a new ListRoomArtifactsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRoomArtifactsResponse.
             * @implements IListRoomArtifactsResponse
             * @constructor
             * @param {streem.api.IListRoomArtifactsResponse=} [p] Properties to set
             */
            function ListRoomArtifactsResponse(p) {
                this.artifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomArtifactsResponse artifacts.
             * @member {Array.<streem.api.IArtifact>} artifacts
             * @memberof streem.api.ListRoomArtifactsResponse
             * @instance
             */
            ListRoomArtifactsResponse.prototype.artifacts = $util.emptyArray;

            /**
             * ListRoomArtifactsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRoomArtifactsResponse
             * @instance
             */
            ListRoomArtifactsResponse.prototype.nextPageToken = "";

            /**
             * ListRoomArtifactsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRoomArtifactsResponse
             * @instance
             */
            ListRoomArtifactsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRoomArtifactsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {streem.api.IListRoomArtifactsResponse=} [properties] Properties to set
             * @returns {streem.api.ListRoomArtifactsResponse} ListRoomArtifactsResponse instance
             */
            ListRoomArtifactsResponse.create = function create(properties) {
                return new ListRoomArtifactsResponse(properties);
            };

            /**
             * Encodes the specified ListRoomArtifactsResponse message. Does not implicitly {@link streem.api.ListRoomArtifactsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {streem.api.IListRoomArtifactsResponse} m ListRoomArtifactsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomArtifactsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.artifacts != null && m.artifacts.length) {
                    for (var i = 0; i < m.artifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.artifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRoomArtifactsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomArtifactsResponse} ListRoomArtifactsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomArtifactsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomArtifactsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.artifacts && m.artifacts.length))
                                m.artifacts = [];
                            m.artifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomArtifactsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomArtifactsResponse} ListRoomArtifactsResponse
             */
            ListRoomArtifactsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomArtifactsResponse)
                    return d;
                var m = new $root.streem.api.ListRoomArtifactsResponse();
                if (d.artifacts) {
                    if (!Array.isArray(d.artifacts))
                        throw TypeError(".streem.api.ListRoomArtifactsResponse.artifacts: array expected");
                    m.artifacts = [];
                    for (var i = 0; i < d.artifacts.length; ++i) {
                        if (typeof d.artifacts[i] !== "object")
                            throw TypeError(".streem.api.ListRoomArtifactsResponse.artifacts: object expected");
                        m.artifacts[i] = $root.streem.api.Artifact.fromObject(d.artifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomArtifactsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {streem.api.ListRoomArtifactsResponse} m ListRoomArtifactsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomArtifactsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.artifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.artifacts && m.artifacts.length) {
                    d.artifacts = [];
                    for (var j = 0; j < m.artifacts.length; ++j) {
                        d.artifacts[j] = $root.streem.api.Artifact.toObject(m.artifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRoomArtifactsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomArtifactsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomArtifactsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomArtifactsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRoomArtifactsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomArtifactsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomArtifactsResponse";
            };

            return ListRoomArtifactsResponse;
        })();

        api.ListRoomOutcomeReportsRequest = (function() {

            /**
             * Properties of a ListRoomOutcomeReportsRequest.
             * @memberof streem.api
             * @interface IListRoomOutcomeReportsRequest
             * @property {string|null} [roomSid] ListRoomOutcomeReportsRequest roomSid
             * @property {number|null} [pageSize] ListRoomOutcomeReportsRequest pageSize
             * @property {string|null} [pageToken] ListRoomOutcomeReportsRequest pageToken
             */

            /**
             * Constructs a new ListRoomOutcomeReportsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRoomOutcomeReportsRequest.
             * @implements IListRoomOutcomeReportsRequest
             * @constructor
             * @param {streem.api.IListRoomOutcomeReportsRequest=} [p] Properties to set
             */
            function ListRoomOutcomeReportsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomOutcomeReportsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @instance
             */
            ListRoomOutcomeReportsRequest.prototype.roomSid = "";

            /**
             * ListRoomOutcomeReportsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @instance
             */
            ListRoomOutcomeReportsRequest.prototype.pageSize = 0;

            /**
             * ListRoomOutcomeReportsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @instance
             */
            ListRoomOutcomeReportsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRoomOutcomeReportsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.IListRoomOutcomeReportsRequest=} [properties] Properties to set
             * @returns {streem.api.ListRoomOutcomeReportsRequest} ListRoomOutcomeReportsRequest instance
             */
            ListRoomOutcomeReportsRequest.create = function create(properties) {
                return new ListRoomOutcomeReportsRequest(properties);
            };

            /**
             * Encodes the specified ListRoomOutcomeReportsRequest message. Does not implicitly {@link streem.api.ListRoomOutcomeReportsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.IListRoomOutcomeReportsRequest} m ListRoomOutcomeReportsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomOutcomeReportsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRoomOutcomeReportsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomOutcomeReportsRequest} ListRoomOutcomeReportsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomOutcomeReportsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomOutcomeReportsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomOutcomeReportsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomOutcomeReportsRequest} ListRoomOutcomeReportsRequest
             */
            ListRoomOutcomeReportsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomOutcomeReportsRequest)
                    return d;
                var m = new $root.streem.api.ListRoomOutcomeReportsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomOutcomeReportsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {streem.api.ListRoomOutcomeReportsRequest} m ListRoomOutcomeReportsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomOutcomeReportsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRoomOutcomeReportsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomOutcomeReportsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomOutcomeReportsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRoomOutcomeReportsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomOutcomeReportsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomOutcomeReportsRequest";
            };

            return ListRoomOutcomeReportsRequest;
        })();

        api.ListRoomOutcomeReportsResponse = (function() {

            /**
             * Properties of a ListRoomOutcomeReportsResponse.
             * @memberof streem.api
             * @interface IListRoomOutcomeReportsResponse
             * @property {Array.<streem.api.IArtifact>|null} [roomOutcomeReportArtifacts] ListRoomOutcomeReportsResponse roomOutcomeReportArtifacts
             * @property {string|null} [nextPageToken] ListRoomOutcomeReportsResponse nextPageToken
             * @property {number|null} [totalSize] ListRoomOutcomeReportsResponse totalSize
             */

            /**
             * Constructs a new ListRoomOutcomeReportsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRoomOutcomeReportsResponse.
             * @implements IListRoomOutcomeReportsResponse
             * @constructor
             * @param {streem.api.IListRoomOutcomeReportsResponse=} [p] Properties to set
             */
            function ListRoomOutcomeReportsResponse(p) {
                this.roomOutcomeReportArtifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRoomOutcomeReportsResponse roomOutcomeReportArtifacts.
             * @member {Array.<streem.api.IArtifact>} roomOutcomeReportArtifacts
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @instance
             */
            ListRoomOutcomeReportsResponse.prototype.roomOutcomeReportArtifacts = $util.emptyArray;

            /**
             * ListRoomOutcomeReportsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @instance
             */
            ListRoomOutcomeReportsResponse.prototype.nextPageToken = "";

            /**
             * ListRoomOutcomeReportsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @instance
             */
            ListRoomOutcomeReportsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRoomOutcomeReportsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.IListRoomOutcomeReportsResponse=} [properties] Properties to set
             * @returns {streem.api.ListRoomOutcomeReportsResponse} ListRoomOutcomeReportsResponse instance
             */
            ListRoomOutcomeReportsResponse.create = function create(properties) {
                return new ListRoomOutcomeReportsResponse(properties);
            };

            /**
             * Encodes the specified ListRoomOutcomeReportsResponse message. Does not implicitly {@link streem.api.ListRoomOutcomeReportsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.IListRoomOutcomeReportsResponse} m ListRoomOutcomeReportsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRoomOutcomeReportsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomOutcomeReportArtifacts != null && m.roomOutcomeReportArtifacts.length) {
                    for (var i = 0; i < m.roomOutcomeReportArtifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.roomOutcomeReportArtifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRoomOutcomeReportsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRoomOutcomeReportsResponse} ListRoomOutcomeReportsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRoomOutcomeReportsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRoomOutcomeReportsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomOutcomeReportArtifacts && m.roomOutcomeReportArtifacts.length))
                                m.roomOutcomeReportArtifacts = [];
                            m.roomOutcomeReportArtifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRoomOutcomeReportsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRoomOutcomeReportsResponse} ListRoomOutcomeReportsResponse
             */
            ListRoomOutcomeReportsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRoomOutcomeReportsResponse)
                    return d;
                var m = new $root.streem.api.ListRoomOutcomeReportsResponse();
                if (d.roomOutcomeReportArtifacts) {
                    if (!Array.isArray(d.roomOutcomeReportArtifacts))
                        throw TypeError(".streem.api.ListRoomOutcomeReportsResponse.roomOutcomeReportArtifacts: array expected");
                    m.roomOutcomeReportArtifacts = [];
                    for (var i = 0; i < d.roomOutcomeReportArtifacts.length; ++i) {
                        if (typeof d.roomOutcomeReportArtifacts[i] !== "object")
                            throw TypeError(".streem.api.ListRoomOutcomeReportsResponse.roomOutcomeReportArtifacts: object expected");
                        m.roomOutcomeReportArtifacts[i] = $root.streem.api.Artifact.fromObject(d.roomOutcomeReportArtifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRoomOutcomeReportsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {streem.api.ListRoomOutcomeReportsResponse} m ListRoomOutcomeReportsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRoomOutcomeReportsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomOutcomeReportArtifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.roomOutcomeReportArtifacts && m.roomOutcomeReportArtifacts.length) {
                    d.roomOutcomeReportArtifacts = [];
                    for (var j = 0; j < m.roomOutcomeReportArtifacts.length; ++j) {
                        d.roomOutcomeReportArtifacts[j] = $root.streem.api.Artifact.toObject(m.roomOutcomeReportArtifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRoomOutcomeReportsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRoomOutcomeReportsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRoomOutcomeReportsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRoomOutcomeReportsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRoomOutcomeReportsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRoomOutcomeReportsResponse";
            };

            return ListRoomOutcomeReportsResponse;
        })();

        api.ListSceneshotsRequest = (function() {

            /**
             * Properties of a ListSceneshotsRequest.
             * @memberof streem.api
             * @interface IListSceneshotsRequest
             * @property {string|null} [roomSid] ListSceneshotsRequest roomSid
             * @property {number|null} [pageSize] ListSceneshotsRequest pageSize
             * @property {string|null} [pageToken] ListSceneshotsRequest pageToken
             */

            /**
             * Constructs a new ListSceneshotsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListSceneshotsRequest.
             * @implements IListSceneshotsRequest
             * @constructor
             * @param {streem.api.IListSceneshotsRequest=} [p] Properties to set
             */
            function ListSceneshotsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSceneshotsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListSceneshotsRequest
             * @instance
             */
            ListSceneshotsRequest.prototype.roomSid = "";

            /**
             * ListSceneshotsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListSceneshotsRequest
             * @instance
             */
            ListSceneshotsRequest.prototype.pageSize = 0;

            /**
             * ListSceneshotsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListSceneshotsRequest
             * @instance
             */
            ListSceneshotsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListSceneshotsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {streem.api.IListSceneshotsRequest=} [properties] Properties to set
             * @returns {streem.api.ListSceneshotsRequest} ListSceneshotsRequest instance
             */
            ListSceneshotsRequest.create = function create(properties) {
                return new ListSceneshotsRequest(properties);
            };

            /**
             * Encodes the specified ListSceneshotsRequest message. Does not implicitly {@link streem.api.ListSceneshotsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {streem.api.IListSceneshotsRequest} m ListSceneshotsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSceneshotsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListSceneshotsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSceneshotsRequest} ListSceneshotsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSceneshotsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSceneshotsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSceneshotsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSceneshotsRequest} ListSceneshotsRequest
             */
            ListSceneshotsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSceneshotsRequest)
                    return d;
                var m = new $root.streem.api.ListSceneshotsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSceneshotsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {streem.api.ListSceneshotsRequest} m ListSceneshotsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSceneshotsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListSceneshotsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListSceneshotsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSceneshotsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSceneshotsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListSceneshotsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSceneshotsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSceneshotsRequest";
            };

            return ListSceneshotsRequest;
        })();

        api.ListSceneshotsResponse = (function() {

            /**
             * Properties of a ListSceneshotsResponse.
             * @memberof streem.api
             * @interface IListSceneshotsResponse
             * @property {Array.<streem.api.IArtifact>|null} [sceneshotArtifacts] ListSceneshotsResponse sceneshotArtifacts
             * @property {string|null} [nextPageToken] ListSceneshotsResponse nextPageToken
             * @property {number|null} [totalSize] ListSceneshotsResponse totalSize
             */

            /**
             * Constructs a new ListSceneshotsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListSceneshotsResponse.
             * @implements IListSceneshotsResponse
             * @constructor
             * @param {streem.api.IListSceneshotsResponse=} [p] Properties to set
             */
            function ListSceneshotsResponse(p) {
                this.sceneshotArtifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSceneshotsResponse sceneshotArtifacts.
             * @member {Array.<streem.api.IArtifact>} sceneshotArtifacts
             * @memberof streem.api.ListSceneshotsResponse
             * @instance
             */
            ListSceneshotsResponse.prototype.sceneshotArtifacts = $util.emptyArray;

            /**
             * ListSceneshotsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListSceneshotsResponse
             * @instance
             */
            ListSceneshotsResponse.prototype.nextPageToken = "";

            /**
             * ListSceneshotsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListSceneshotsResponse
             * @instance
             */
            ListSceneshotsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListSceneshotsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {streem.api.IListSceneshotsResponse=} [properties] Properties to set
             * @returns {streem.api.ListSceneshotsResponse} ListSceneshotsResponse instance
             */
            ListSceneshotsResponse.create = function create(properties) {
                return new ListSceneshotsResponse(properties);
            };

            /**
             * Encodes the specified ListSceneshotsResponse message. Does not implicitly {@link streem.api.ListSceneshotsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {streem.api.IListSceneshotsResponse} m ListSceneshotsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSceneshotsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sceneshotArtifacts != null && m.sceneshotArtifacts.length) {
                    for (var i = 0; i < m.sceneshotArtifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.sceneshotArtifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListSceneshotsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSceneshotsResponse} ListSceneshotsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSceneshotsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSceneshotsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.sceneshotArtifacts && m.sceneshotArtifacts.length))
                                m.sceneshotArtifacts = [];
                            m.sceneshotArtifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSceneshotsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSceneshotsResponse} ListSceneshotsResponse
             */
            ListSceneshotsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSceneshotsResponse)
                    return d;
                var m = new $root.streem.api.ListSceneshotsResponse();
                if (d.sceneshotArtifacts) {
                    if (!Array.isArray(d.sceneshotArtifacts))
                        throw TypeError(".streem.api.ListSceneshotsResponse.sceneshotArtifacts: array expected");
                    m.sceneshotArtifacts = [];
                    for (var i = 0; i < d.sceneshotArtifacts.length; ++i) {
                        if (typeof d.sceneshotArtifacts[i] !== "object")
                            throw TypeError(".streem.api.ListSceneshotsResponse.sceneshotArtifacts: object expected");
                        m.sceneshotArtifacts[i] = $root.streem.api.Artifact.fromObject(d.sceneshotArtifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSceneshotsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {streem.api.ListSceneshotsResponse} m ListSceneshotsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSceneshotsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.sceneshotArtifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.sceneshotArtifacts && m.sceneshotArtifacts.length) {
                    d.sceneshotArtifacts = [];
                    for (var j = 0; j < m.sceneshotArtifacts.length; ++j) {
                        d.sceneshotArtifacts[j] = $root.streem.api.Artifact.toObject(m.sceneshotArtifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListSceneshotsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListSceneshotsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSceneshotsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSceneshotsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListSceneshotsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSceneshotsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSceneshotsResponse";
            };

            return ListSceneshotsResponse;
        })();

        api.ListStreemshotsRequest = (function() {

            /**
             * Properties of a ListStreemshotsRequest.
             * @memberof streem.api
             * @interface IListStreemshotsRequest
             * @property {string|null} [roomSid] ListStreemshotsRequest roomSid
             * @property {number|null} [pageSize] ListStreemshotsRequest pageSize
             * @property {string|null} [pageToken] ListStreemshotsRequest pageToken
             */

            /**
             * Constructs a new ListStreemshotsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListStreemshotsRequest.
             * @implements IListStreemshotsRequest
             * @constructor
             * @param {streem.api.IListStreemshotsRequest=} [p] Properties to set
             */
            function ListStreemshotsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListStreemshotsRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.ListStreemshotsRequest
             * @instance
             */
            ListStreemshotsRequest.prototype.roomSid = "";

            /**
             * ListStreemshotsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListStreemshotsRequest
             * @instance
             */
            ListStreemshotsRequest.prototype.pageSize = 0;

            /**
             * ListStreemshotsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListStreemshotsRequest
             * @instance
             */
            ListStreemshotsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListStreemshotsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {streem.api.IListStreemshotsRequest=} [properties] Properties to set
             * @returns {streem.api.ListStreemshotsRequest} ListStreemshotsRequest instance
             */
            ListStreemshotsRequest.create = function create(properties) {
                return new ListStreemshotsRequest(properties);
            };

            /**
             * Encodes the specified ListStreemshotsRequest message. Does not implicitly {@link streem.api.ListStreemshotsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {streem.api.IListStreemshotsRequest} m ListStreemshotsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListStreemshotsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListStreemshotsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListStreemshotsRequest} ListStreemshotsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListStreemshotsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListStreemshotsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListStreemshotsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListStreemshotsRequest} ListStreemshotsRequest
             */
            ListStreemshotsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListStreemshotsRequest)
                    return d;
                var m = new $root.streem.api.ListStreemshotsRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListStreemshotsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {streem.api.ListStreemshotsRequest} m ListStreemshotsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListStreemshotsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListStreemshotsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListStreemshotsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListStreemshotsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListStreemshotsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListStreemshotsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListStreemshotsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListStreemshotsRequest";
            };

            return ListStreemshotsRequest;
        })();

        api.ListStreemshotsResponse = (function() {

            /**
             * Properties of a ListStreemshotsResponse.
             * @memberof streem.api
             * @interface IListStreemshotsResponse
             * @property {Array.<streem.api.IArtifact>|null} [streemshotArtifacts] ListStreemshotsResponse streemshotArtifacts
             * @property {string|null} [nextPageToken] ListStreemshotsResponse nextPageToken
             * @property {number|null} [totalSize] ListStreemshotsResponse totalSize
             */

            /**
             * Constructs a new ListStreemshotsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListStreemshotsResponse.
             * @implements IListStreemshotsResponse
             * @constructor
             * @param {streem.api.IListStreemshotsResponse=} [p] Properties to set
             */
            function ListStreemshotsResponse(p) {
                this.streemshotArtifacts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListStreemshotsResponse streemshotArtifacts.
             * @member {Array.<streem.api.IArtifact>} streemshotArtifacts
             * @memberof streem.api.ListStreemshotsResponse
             * @instance
             */
            ListStreemshotsResponse.prototype.streemshotArtifacts = $util.emptyArray;

            /**
             * ListStreemshotsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListStreemshotsResponse
             * @instance
             */
            ListStreemshotsResponse.prototype.nextPageToken = "";

            /**
             * ListStreemshotsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListStreemshotsResponse
             * @instance
             */
            ListStreemshotsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListStreemshotsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {streem.api.IListStreemshotsResponse=} [properties] Properties to set
             * @returns {streem.api.ListStreemshotsResponse} ListStreemshotsResponse instance
             */
            ListStreemshotsResponse.create = function create(properties) {
                return new ListStreemshotsResponse(properties);
            };

            /**
             * Encodes the specified ListStreemshotsResponse message. Does not implicitly {@link streem.api.ListStreemshotsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {streem.api.IListStreemshotsResponse} m ListStreemshotsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListStreemshotsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotArtifacts != null && m.streemshotArtifacts.length) {
                    for (var i = 0; i < m.streemshotArtifacts.length; ++i)
                        $root.streem.api.Artifact.encode(m.streemshotArtifacts[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListStreemshotsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListStreemshotsResponse} ListStreemshotsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListStreemshotsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListStreemshotsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.streemshotArtifacts && m.streemshotArtifacts.length))
                                m.streemshotArtifacts = [];
                            m.streemshotArtifacts.push($root.streem.api.Artifact.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListStreemshotsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListStreemshotsResponse} ListStreemshotsResponse
             */
            ListStreemshotsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListStreemshotsResponse)
                    return d;
                var m = new $root.streem.api.ListStreemshotsResponse();
                if (d.streemshotArtifacts) {
                    if (!Array.isArray(d.streemshotArtifacts))
                        throw TypeError(".streem.api.ListStreemshotsResponse.streemshotArtifacts: array expected");
                    m.streemshotArtifacts = [];
                    for (var i = 0; i < d.streemshotArtifacts.length; ++i) {
                        if (typeof d.streemshotArtifacts[i] !== "object")
                            throw TypeError(".streem.api.ListStreemshotsResponse.streemshotArtifacts: object expected");
                        m.streemshotArtifacts[i] = $root.streem.api.Artifact.fromObject(d.streemshotArtifacts[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListStreemshotsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {streem.api.ListStreemshotsResponse} m ListStreemshotsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListStreemshotsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.streemshotArtifacts = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.streemshotArtifacts && m.streemshotArtifacts.length) {
                    d.streemshotArtifacts = [];
                    for (var j = 0; j < m.streemshotArtifacts.length; ++j) {
                        d.streemshotArtifacts[j] = $root.streem.api.Artifact.toObject(m.streemshotArtifacts[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListStreemshotsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListStreemshotsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListStreemshotsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListStreemshotsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListStreemshotsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListStreemshotsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListStreemshotsResponse";
            };

            return ListStreemshotsResponse;
        })();

        api.StartLayoutEstimationRequest = (function() {

            /**
             * Properties of a StartLayoutEstimationRequest.
             * @memberof streem.api
             * @interface IStartLayoutEstimationRequest
             * @property {string|null} [roomSid] StartLayoutEstimationRequest roomSid
             * @property {string|null} [layoutEstimationSid] StartLayoutEstimationRequest layoutEstimationSid
             */

            /**
             * Constructs a new StartLayoutEstimationRequest.
             * @memberof streem.api
             * @classdesc Represents a StartLayoutEstimationRequest.
             * @implements IStartLayoutEstimationRequest
             * @constructor
             * @param {streem.api.IStartLayoutEstimationRequest=} [p] Properties to set
             */
            function StartLayoutEstimationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartLayoutEstimationRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StartLayoutEstimationRequest
             * @instance
             */
            StartLayoutEstimationRequest.prototype.roomSid = "";

            /**
             * StartLayoutEstimationRequest layoutEstimationSid.
             * @member {string} layoutEstimationSid
             * @memberof streem.api.StartLayoutEstimationRequest
             * @instance
             */
            StartLayoutEstimationRequest.prototype.layoutEstimationSid = "";

            /**
             * Creates a new StartLayoutEstimationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {streem.api.IStartLayoutEstimationRequest=} [properties] Properties to set
             * @returns {streem.api.StartLayoutEstimationRequest} StartLayoutEstimationRequest instance
             */
            StartLayoutEstimationRequest.create = function create(properties) {
                return new StartLayoutEstimationRequest(properties);
            };

            /**
             * Encodes the specified StartLayoutEstimationRequest message. Does not implicitly {@link streem.api.StartLayoutEstimationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {streem.api.IStartLayoutEstimationRequest} m StartLayoutEstimationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartLayoutEstimationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.layoutEstimationSid != null && Object.hasOwnProperty.call(m, "layoutEstimationSid"))
                    w.uint32(18).string(m.layoutEstimationSid);
                return w;
            };

            /**
             * Decodes a StartLayoutEstimationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartLayoutEstimationRequest} StartLayoutEstimationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartLayoutEstimationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartLayoutEstimationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.layoutEstimationSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartLayoutEstimationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartLayoutEstimationRequest} StartLayoutEstimationRequest
             */
            StartLayoutEstimationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartLayoutEstimationRequest)
                    return d;
                var m = new $root.streem.api.StartLayoutEstimationRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.layoutEstimationSid != null) {
                    m.layoutEstimationSid = String(d.layoutEstimationSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartLayoutEstimationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {streem.api.StartLayoutEstimationRequest} m StartLayoutEstimationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartLayoutEstimationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.layoutEstimationSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.layoutEstimationSid != null && m.hasOwnProperty("layoutEstimationSid")) {
                    d.layoutEstimationSid = m.layoutEstimationSid;
                }
                return d;
            };

            /**
             * Converts this StartLayoutEstimationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.StartLayoutEstimationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartLayoutEstimationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartLayoutEstimationRequest
             * @function getTypeUrl
             * @memberof streem.api.StartLayoutEstimationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartLayoutEstimationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartLayoutEstimationRequest";
            };

            return StartLayoutEstimationRequest;
        })();

        api.StartLayoutEstimationResponse = (function() {

            /**
             * Properties of a StartLayoutEstimationResponse.
             * @memberof streem.api
             * @interface IStartLayoutEstimationResponse
             * @property {streem.api.IArtifact|null} [layoutEstimationArtifact] StartLayoutEstimationResponse layoutEstimationArtifact
             */

            /**
             * Constructs a new StartLayoutEstimationResponse.
             * @memberof streem.api
             * @classdesc Represents a StartLayoutEstimationResponse.
             * @implements IStartLayoutEstimationResponse
             * @constructor
             * @param {streem.api.IStartLayoutEstimationResponse=} [p] Properties to set
             */
            function StartLayoutEstimationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartLayoutEstimationResponse layoutEstimationArtifact.
             * @member {streem.api.IArtifact|null|undefined} layoutEstimationArtifact
             * @memberof streem.api.StartLayoutEstimationResponse
             * @instance
             */
            StartLayoutEstimationResponse.prototype.layoutEstimationArtifact = null;

            /**
             * Creates a new StartLayoutEstimationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {streem.api.IStartLayoutEstimationResponse=} [properties] Properties to set
             * @returns {streem.api.StartLayoutEstimationResponse} StartLayoutEstimationResponse instance
             */
            StartLayoutEstimationResponse.create = function create(properties) {
                return new StartLayoutEstimationResponse(properties);
            };

            /**
             * Encodes the specified StartLayoutEstimationResponse message. Does not implicitly {@link streem.api.StartLayoutEstimationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {streem.api.IStartLayoutEstimationResponse} m StartLayoutEstimationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartLayoutEstimationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.layoutEstimationArtifact != null && Object.hasOwnProperty.call(m, "layoutEstimationArtifact"))
                    $root.streem.api.Artifact.encode(m.layoutEstimationArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a StartLayoutEstimationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartLayoutEstimationResponse} StartLayoutEstimationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartLayoutEstimationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartLayoutEstimationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.layoutEstimationArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartLayoutEstimationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartLayoutEstimationResponse} StartLayoutEstimationResponse
             */
            StartLayoutEstimationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartLayoutEstimationResponse)
                    return d;
                var m = new $root.streem.api.StartLayoutEstimationResponse();
                if (d.layoutEstimationArtifact != null) {
                    if (typeof d.layoutEstimationArtifact !== "object")
                        throw TypeError(".streem.api.StartLayoutEstimationResponse.layoutEstimationArtifact: object expected");
                    m.layoutEstimationArtifact = $root.streem.api.Artifact.fromObject(d.layoutEstimationArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartLayoutEstimationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {streem.api.StartLayoutEstimationResponse} m StartLayoutEstimationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartLayoutEstimationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.layoutEstimationArtifact = null;
                }
                if (m.layoutEstimationArtifact != null && m.hasOwnProperty("layoutEstimationArtifact")) {
                    d.layoutEstimationArtifact = $root.streem.api.Artifact.toObject(m.layoutEstimationArtifact, o);
                }
                return d;
            };

            /**
             * Converts this StartLayoutEstimationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.StartLayoutEstimationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartLayoutEstimationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartLayoutEstimationResponse
             * @function getTypeUrl
             * @memberof streem.api.StartLayoutEstimationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartLayoutEstimationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartLayoutEstimationResponse";
            };

            return StartLayoutEstimationResponse;
        })();

        api.StartMeshRequest = (function() {

            /**
             * Properties of a StartMeshRequest.
             * @memberof streem.api
             * @interface IStartMeshRequest
             * @property {string|null} [roomSid] StartMeshRequest roomSid
             * @property {string|null} [meshSid] StartMeshRequest meshSid
             */

            /**
             * Constructs a new StartMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a StartMeshRequest.
             * @implements IStartMeshRequest
             * @constructor
             * @param {streem.api.IStartMeshRequest=} [p] Properties to set
             */
            function StartMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StartMeshRequest
             * @instance
             */
            StartMeshRequest.prototype.roomSid = "";

            /**
             * StartMeshRequest meshSid.
             * @member {string} meshSid
             * @memberof streem.api.StartMeshRequest
             * @instance
             */
            StartMeshRequest.prototype.meshSid = "";

            /**
             * Creates a new StartMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {streem.api.IStartMeshRequest=} [properties] Properties to set
             * @returns {streem.api.StartMeshRequest} StartMeshRequest instance
             */
            StartMeshRequest.create = function create(properties) {
                return new StartMeshRequest(properties);
            };

            /**
             * Encodes the specified StartMeshRequest message. Does not implicitly {@link streem.api.StartMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {streem.api.IStartMeshRequest} m StartMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                    w.uint32(18).string(m.meshSid);
                return w;
            };

            /**
             * Decodes a StartMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartMeshRequest} StartMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.meshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartMeshRequest} StartMeshRequest
             */
            StartMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartMeshRequest)
                    return d;
                var m = new $root.streem.api.StartMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.meshSid != null) {
                    m.meshSid = String(d.meshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {streem.api.StartMeshRequest} m StartMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.meshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                    d.meshSid = m.meshSid;
                }
                return d;
            };

            /**
             * Converts this StartMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.StartMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.StartMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartMeshRequest";
            };

            return StartMeshRequest;
        })();

        api.StartMeshResponse = (function() {

            /**
             * Properties of a StartMeshResponse.
             * @memberof streem.api
             * @interface IStartMeshResponse
             * @property {streem.api.IArtifact|null} [meshArtifact] StartMeshResponse meshArtifact
             */

            /**
             * Constructs a new StartMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a StartMeshResponse.
             * @implements IStartMeshResponse
             * @constructor
             * @param {streem.api.IStartMeshResponse=} [p] Properties to set
             */
            function StartMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartMeshResponse meshArtifact.
             * @member {streem.api.IArtifact|null|undefined} meshArtifact
             * @memberof streem.api.StartMeshResponse
             * @instance
             */
            StartMeshResponse.prototype.meshArtifact = null;

            /**
             * Creates a new StartMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {streem.api.IStartMeshResponse=} [properties] Properties to set
             * @returns {streem.api.StartMeshResponse} StartMeshResponse instance
             */
            StartMeshResponse.create = function create(properties) {
                return new StartMeshResponse(properties);
            };

            /**
             * Encodes the specified StartMeshResponse message. Does not implicitly {@link streem.api.StartMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {streem.api.IStartMeshResponse} m StartMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meshArtifact != null && Object.hasOwnProperty.call(m, "meshArtifact"))
                    $root.streem.api.Artifact.encode(m.meshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a StartMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartMeshResponse} StartMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartMeshResponse} StartMeshResponse
             */
            StartMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartMeshResponse)
                    return d;
                var m = new $root.streem.api.StartMeshResponse();
                if (d.meshArtifact != null) {
                    if (typeof d.meshArtifact !== "object")
                        throw TypeError(".streem.api.StartMeshResponse.meshArtifact: object expected");
                    m.meshArtifact = $root.streem.api.Artifact.fromObject(d.meshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {streem.api.StartMeshResponse} m StartMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meshArtifact = null;
                }
                if (m.meshArtifact != null && m.hasOwnProperty("meshArtifact")) {
                    d.meshArtifact = $root.streem.api.Artifact.toObject(m.meshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this StartMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.StartMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.StartMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartMeshResponse";
            };

            return StartMeshResponse;
        })();

        api.StartTexturedMeshRequest = (function() {

            /**
             * Properties of a StartTexturedMeshRequest.
             * @memberof streem.api
             * @interface IStartTexturedMeshRequest
             * @property {string|null} [roomSid] StartTexturedMeshRequest roomSid
             * @property {string|null} [texturedMeshSid] StartTexturedMeshRequest texturedMeshSid
             */

            /**
             * Constructs a new StartTexturedMeshRequest.
             * @memberof streem.api
             * @classdesc Represents a StartTexturedMeshRequest.
             * @implements IStartTexturedMeshRequest
             * @constructor
             * @param {streem.api.IStartTexturedMeshRequest=} [p] Properties to set
             */
            function StartTexturedMeshRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartTexturedMeshRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StartTexturedMeshRequest
             * @instance
             */
            StartTexturedMeshRequest.prototype.roomSid = "";

            /**
             * StartTexturedMeshRequest texturedMeshSid.
             * @member {string} texturedMeshSid
             * @memberof streem.api.StartTexturedMeshRequest
             * @instance
             */
            StartTexturedMeshRequest.prototype.texturedMeshSid = "";

            /**
             * Creates a new StartTexturedMeshRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {streem.api.IStartTexturedMeshRequest=} [properties] Properties to set
             * @returns {streem.api.StartTexturedMeshRequest} StartTexturedMeshRequest instance
             */
            StartTexturedMeshRequest.create = function create(properties) {
                return new StartTexturedMeshRequest(properties);
            };

            /**
             * Encodes the specified StartTexturedMeshRequest message. Does not implicitly {@link streem.api.StartTexturedMeshRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {streem.api.IStartTexturedMeshRequest} m StartTexturedMeshRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartTexturedMeshRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.texturedMeshSid != null && Object.hasOwnProperty.call(m, "texturedMeshSid"))
                    w.uint32(18).string(m.texturedMeshSid);
                return w;
            };

            /**
             * Decodes a StartTexturedMeshRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartTexturedMeshRequest} StartTexturedMeshRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartTexturedMeshRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartTexturedMeshRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.texturedMeshSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartTexturedMeshRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartTexturedMeshRequest} StartTexturedMeshRequest
             */
            StartTexturedMeshRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartTexturedMeshRequest)
                    return d;
                var m = new $root.streem.api.StartTexturedMeshRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.texturedMeshSid != null) {
                    m.texturedMeshSid = String(d.texturedMeshSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartTexturedMeshRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {streem.api.StartTexturedMeshRequest} m StartTexturedMeshRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartTexturedMeshRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.texturedMeshSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.texturedMeshSid != null && m.hasOwnProperty("texturedMeshSid")) {
                    d.texturedMeshSid = m.texturedMeshSid;
                }
                return d;
            };

            /**
             * Converts this StartTexturedMeshRequest to JSON.
             * @function toJSON
             * @memberof streem.api.StartTexturedMeshRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartTexturedMeshRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartTexturedMeshRequest
             * @function getTypeUrl
             * @memberof streem.api.StartTexturedMeshRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartTexturedMeshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartTexturedMeshRequest";
            };

            return StartTexturedMeshRequest;
        })();

        api.StartTexturedMeshResponse = (function() {

            /**
             * Properties of a StartTexturedMeshResponse.
             * @memberof streem.api
             * @interface IStartTexturedMeshResponse
             * @property {streem.api.IArtifact|null} [texturedMeshArtifact] StartTexturedMeshResponse texturedMeshArtifact
             */

            /**
             * Constructs a new StartTexturedMeshResponse.
             * @memberof streem.api
             * @classdesc Represents a StartTexturedMeshResponse.
             * @implements IStartTexturedMeshResponse
             * @constructor
             * @param {streem.api.IStartTexturedMeshResponse=} [p] Properties to set
             */
            function StartTexturedMeshResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartTexturedMeshResponse texturedMeshArtifact.
             * @member {streem.api.IArtifact|null|undefined} texturedMeshArtifact
             * @memberof streem.api.StartTexturedMeshResponse
             * @instance
             */
            StartTexturedMeshResponse.prototype.texturedMeshArtifact = null;

            /**
             * Creates a new StartTexturedMeshResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {streem.api.IStartTexturedMeshResponse=} [properties] Properties to set
             * @returns {streem.api.StartTexturedMeshResponse} StartTexturedMeshResponse instance
             */
            StartTexturedMeshResponse.create = function create(properties) {
                return new StartTexturedMeshResponse(properties);
            };

            /**
             * Encodes the specified StartTexturedMeshResponse message. Does not implicitly {@link streem.api.StartTexturedMeshResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {streem.api.IStartTexturedMeshResponse} m StartTexturedMeshResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartTexturedMeshResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.texturedMeshArtifact != null && Object.hasOwnProperty.call(m, "texturedMeshArtifact"))
                    $root.streem.api.Artifact.encode(m.texturedMeshArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a StartTexturedMeshResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartTexturedMeshResponse} StartTexturedMeshResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartTexturedMeshResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartTexturedMeshResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.texturedMeshArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartTexturedMeshResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartTexturedMeshResponse} StartTexturedMeshResponse
             */
            StartTexturedMeshResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartTexturedMeshResponse)
                    return d;
                var m = new $root.streem.api.StartTexturedMeshResponse();
                if (d.texturedMeshArtifact != null) {
                    if (typeof d.texturedMeshArtifact !== "object")
                        throw TypeError(".streem.api.StartTexturedMeshResponse.texturedMeshArtifact: object expected");
                    m.texturedMeshArtifact = $root.streem.api.Artifact.fromObject(d.texturedMeshArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartTexturedMeshResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {streem.api.StartTexturedMeshResponse} m StartTexturedMeshResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartTexturedMeshResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.texturedMeshArtifact = null;
                }
                if (m.texturedMeshArtifact != null && m.hasOwnProperty("texturedMeshArtifact")) {
                    d.texturedMeshArtifact = $root.streem.api.Artifact.toObject(m.texturedMeshArtifact, o);
                }
                return d;
            };

            /**
             * Converts this StartTexturedMeshResponse to JSON.
             * @function toJSON
             * @memberof streem.api.StartTexturedMeshResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartTexturedMeshResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartTexturedMeshResponse
             * @function getTypeUrl
             * @memberof streem.api.StartTexturedMeshResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartTexturedMeshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartTexturedMeshResponse";
            };

            return StartTexturedMeshResponse;
        })();

        api.TransferRecordingMuxRequest = (function() {

            /**
             * Properties of a TransferRecordingMuxRequest.
             * @memberof streem.api
             * @interface ITransferRecordingMuxRequest
             * @property {string|null} [roomSid] TransferRecordingMuxRequest roomSid
             * @property {string|null} [recordingSid] TransferRecordingMuxRequest recordingSid
             */

            /**
             * Constructs a new TransferRecordingMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a TransferRecordingMuxRequest.
             * @implements ITransferRecordingMuxRequest
             * @constructor
             * @param {streem.api.ITransferRecordingMuxRequest=} [p] Properties to set
             */
            function TransferRecordingMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TransferRecordingMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.TransferRecordingMuxRequest
             * @instance
             */
            TransferRecordingMuxRequest.prototype.roomSid = "";

            /**
             * TransferRecordingMuxRequest recordingSid.
             * @member {string} recordingSid
             * @memberof streem.api.TransferRecordingMuxRequest
             * @instance
             */
            TransferRecordingMuxRequest.prototype.recordingSid = "";

            /**
             * Creates a new TransferRecordingMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {streem.api.ITransferRecordingMuxRequest=} [properties] Properties to set
             * @returns {streem.api.TransferRecordingMuxRequest} TransferRecordingMuxRequest instance
             */
            TransferRecordingMuxRequest.create = function create(properties) {
                return new TransferRecordingMuxRequest(properties);
            };

            /**
             * Encodes the specified TransferRecordingMuxRequest message. Does not implicitly {@link streem.api.TransferRecordingMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {streem.api.ITransferRecordingMuxRequest} m TransferRecordingMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferRecordingMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingSid != null && Object.hasOwnProperty.call(m, "recordingSid"))
                    w.uint32(18).string(m.recordingSid);
                return w;
            };

            /**
             * Decodes a TransferRecordingMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TransferRecordingMuxRequest} TransferRecordingMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferRecordingMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TransferRecordingMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TransferRecordingMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TransferRecordingMuxRequest} TransferRecordingMuxRequest
             */
            TransferRecordingMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TransferRecordingMuxRequest)
                    return d;
                var m = new $root.streem.api.TransferRecordingMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingSid != null) {
                    m.recordingSid = String(d.recordingSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a TransferRecordingMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {streem.api.TransferRecordingMuxRequest} m TransferRecordingMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferRecordingMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingSid != null && m.hasOwnProperty("recordingSid")) {
                    d.recordingSid = m.recordingSid;
                }
                return d;
            };

            /**
             * Converts this TransferRecordingMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.TransferRecordingMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferRecordingMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TransferRecordingMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.TransferRecordingMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferRecordingMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TransferRecordingMuxRequest";
            };

            return TransferRecordingMuxRequest;
        })();

        api.TransferRecordingMuxResponse = (function() {

            /**
             * Properties of a TransferRecordingMuxResponse.
             * @memberof streem.api
             * @interface ITransferRecordingMuxResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] TransferRecordingMuxResponse recordingArtifact
             */

            /**
             * Constructs a new TransferRecordingMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a TransferRecordingMuxResponse.
             * @implements ITransferRecordingMuxResponse
             * @constructor
             * @param {streem.api.ITransferRecordingMuxResponse=} [p] Properties to set
             */
            function TransferRecordingMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TransferRecordingMuxResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.TransferRecordingMuxResponse
             * @instance
             */
            TransferRecordingMuxResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new TransferRecordingMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {streem.api.ITransferRecordingMuxResponse=} [properties] Properties to set
             * @returns {streem.api.TransferRecordingMuxResponse} TransferRecordingMuxResponse instance
             */
            TransferRecordingMuxResponse.create = function create(properties) {
                return new TransferRecordingMuxResponse(properties);
            };

            /**
             * Encodes the specified TransferRecordingMuxResponse message. Does not implicitly {@link streem.api.TransferRecordingMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {streem.api.ITransferRecordingMuxResponse} m TransferRecordingMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferRecordingMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TransferRecordingMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TransferRecordingMuxResponse} TransferRecordingMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferRecordingMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TransferRecordingMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TransferRecordingMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TransferRecordingMuxResponse} TransferRecordingMuxResponse
             */
            TransferRecordingMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TransferRecordingMuxResponse)
                    return d;
                var m = new $root.streem.api.TransferRecordingMuxResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.TransferRecordingMuxResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a TransferRecordingMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {streem.api.TransferRecordingMuxResponse} m TransferRecordingMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferRecordingMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this TransferRecordingMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.TransferRecordingMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferRecordingMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TransferRecordingMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.TransferRecordingMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferRecordingMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TransferRecordingMuxResponse";
            };

            return TransferRecordingMuxResponse;
        })();

        api.TransferRecordingTrackMuxRequest = (function() {

            /**
             * Properties of a TransferRecordingTrackMuxRequest.
             * @memberof streem.api
             * @interface ITransferRecordingTrackMuxRequest
             * @property {string|null} [roomSid] TransferRecordingTrackMuxRequest roomSid
             * @property {string|null} [recordingTrackSid] TransferRecordingTrackMuxRequest recordingTrackSid
             */

            /**
             * Constructs a new TransferRecordingTrackMuxRequest.
             * @memberof streem.api
             * @classdesc Represents a TransferRecordingTrackMuxRequest.
             * @implements ITransferRecordingTrackMuxRequest
             * @constructor
             * @param {streem.api.ITransferRecordingTrackMuxRequest=} [p] Properties to set
             */
            function TransferRecordingTrackMuxRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TransferRecordingTrackMuxRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @instance
             */
            TransferRecordingTrackMuxRequest.prototype.roomSid = "";

            /**
             * TransferRecordingTrackMuxRequest recordingTrackSid.
             * @member {string} recordingTrackSid
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @instance
             */
            TransferRecordingTrackMuxRequest.prototype.recordingTrackSid = "";

            /**
             * Creates a new TransferRecordingTrackMuxRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {streem.api.ITransferRecordingTrackMuxRequest=} [properties] Properties to set
             * @returns {streem.api.TransferRecordingTrackMuxRequest} TransferRecordingTrackMuxRequest instance
             */
            TransferRecordingTrackMuxRequest.create = function create(properties) {
                return new TransferRecordingTrackMuxRequest(properties);
            };

            /**
             * Encodes the specified TransferRecordingTrackMuxRequest message. Does not implicitly {@link streem.api.TransferRecordingTrackMuxRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {streem.api.ITransferRecordingTrackMuxRequest} m TransferRecordingTrackMuxRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferRecordingTrackMuxRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingTrackSid != null && Object.hasOwnProperty.call(m, "recordingTrackSid"))
                    w.uint32(18).string(m.recordingTrackSid);
                return w;
            };

            /**
             * Decodes a TransferRecordingTrackMuxRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TransferRecordingTrackMuxRequest} TransferRecordingTrackMuxRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferRecordingTrackMuxRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TransferRecordingTrackMuxRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingTrackSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TransferRecordingTrackMuxRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TransferRecordingTrackMuxRequest} TransferRecordingTrackMuxRequest
             */
            TransferRecordingTrackMuxRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TransferRecordingTrackMuxRequest)
                    return d;
                var m = new $root.streem.api.TransferRecordingTrackMuxRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.recordingTrackSid != null) {
                    m.recordingTrackSid = String(d.recordingTrackSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a TransferRecordingTrackMuxRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {streem.api.TransferRecordingTrackMuxRequest} m TransferRecordingTrackMuxRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferRecordingTrackMuxRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingTrackSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingTrackSid != null && m.hasOwnProperty("recordingTrackSid")) {
                    d.recordingTrackSid = m.recordingTrackSid;
                }
                return d;
            };

            /**
             * Converts this TransferRecordingTrackMuxRequest to JSON.
             * @function toJSON
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferRecordingTrackMuxRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TransferRecordingTrackMuxRequest
             * @function getTypeUrl
             * @memberof streem.api.TransferRecordingTrackMuxRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferRecordingTrackMuxRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TransferRecordingTrackMuxRequest";
            };

            return TransferRecordingTrackMuxRequest;
        })();

        api.TransferRecordingTrackMuxResponse = (function() {

            /**
             * Properties of a TransferRecordingTrackMuxResponse.
             * @memberof streem.api
             * @interface ITransferRecordingTrackMuxResponse
             * @property {streem.api.IArtifact|null} [recordingTrackArtifact] TransferRecordingTrackMuxResponse recordingTrackArtifact
             */

            /**
             * Constructs a new TransferRecordingTrackMuxResponse.
             * @memberof streem.api
             * @classdesc Represents a TransferRecordingTrackMuxResponse.
             * @implements ITransferRecordingTrackMuxResponse
             * @constructor
             * @param {streem.api.ITransferRecordingTrackMuxResponse=} [p] Properties to set
             */
            function TransferRecordingTrackMuxResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TransferRecordingTrackMuxResponse recordingTrackArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingTrackArtifact
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @instance
             */
            TransferRecordingTrackMuxResponse.prototype.recordingTrackArtifact = null;

            /**
             * Creates a new TransferRecordingTrackMuxResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {streem.api.ITransferRecordingTrackMuxResponse=} [properties] Properties to set
             * @returns {streem.api.TransferRecordingTrackMuxResponse} TransferRecordingTrackMuxResponse instance
             */
            TransferRecordingTrackMuxResponse.create = function create(properties) {
                return new TransferRecordingTrackMuxResponse(properties);
            };

            /**
             * Encodes the specified TransferRecordingTrackMuxResponse message. Does not implicitly {@link streem.api.TransferRecordingTrackMuxResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {streem.api.ITransferRecordingTrackMuxResponse} m TransferRecordingTrackMuxResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferRecordingTrackMuxResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingTrackArtifact != null && Object.hasOwnProperty.call(m, "recordingTrackArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingTrackArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TransferRecordingTrackMuxResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TransferRecordingTrackMuxResponse} TransferRecordingTrackMuxResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferRecordingTrackMuxResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TransferRecordingTrackMuxResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingTrackArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TransferRecordingTrackMuxResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TransferRecordingTrackMuxResponse} TransferRecordingTrackMuxResponse
             */
            TransferRecordingTrackMuxResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TransferRecordingTrackMuxResponse)
                    return d;
                var m = new $root.streem.api.TransferRecordingTrackMuxResponse();
                if (d.recordingTrackArtifact != null) {
                    if (typeof d.recordingTrackArtifact !== "object")
                        throw TypeError(".streem.api.TransferRecordingTrackMuxResponse.recordingTrackArtifact: object expected");
                    m.recordingTrackArtifact = $root.streem.api.Artifact.fromObject(d.recordingTrackArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a TransferRecordingTrackMuxResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {streem.api.TransferRecordingTrackMuxResponse} m TransferRecordingTrackMuxResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferRecordingTrackMuxResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingTrackArtifact = null;
                }
                if (m.recordingTrackArtifact != null && m.hasOwnProperty("recordingTrackArtifact")) {
                    d.recordingTrackArtifact = $root.streem.api.Artifact.toObject(m.recordingTrackArtifact, o);
                }
                return d;
            };

            /**
             * Converts this TransferRecordingTrackMuxResponse to JSON.
             * @function toJSON
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferRecordingTrackMuxResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TransferRecordingTrackMuxResponse
             * @function getTypeUrl
             * @memberof streem.api.TransferRecordingTrackMuxResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferRecordingTrackMuxResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TransferRecordingTrackMuxResponse";
            };

            return TransferRecordingTrackMuxResponse;
        })();

        api.UpdateRoomNoteRequest = (function() {

            /**
             * Properties of an UpdateRoomNoteRequest.
             * @memberof streem.api
             * @interface IUpdateRoomNoteRequest
             * @property {string|null} [roomSid] UpdateRoomNoteRequest roomSid
             * @property {string|null} [text] UpdateRoomNoteRequest text
             * @property {string|null} [authorSid] UpdateRoomNoteRequest authorSid
             */

            /**
             * Constructs a new UpdateRoomNoteRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateRoomNoteRequest.
             * @implements IUpdateRoomNoteRequest
             * @constructor
             * @param {streem.api.IUpdateRoomNoteRequest=} [p] Properties to set
             */
            function UpdateRoomNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateRoomNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.UpdateRoomNoteRequest
             * @instance
             */
            UpdateRoomNoteRequest.prototype.roomSid = "";

            /**
             * UpdateRoomNoteRequest text.
             * @member {string} text
             * @memberof streem.api.UpdateRoomNoteRequest
             * @instance
             */
            UpdateRoomNoteRequest.prototype.text = "";

            /**
             * UpdateRoomNoteRequest authorSid.
             * @member {string} authorSid
             * @memberof streem.api.UpdateRoomNoteRequest
             * @instance
             */
            UpdateRoomNoteRequest.prototype.authorSid = "";

            /**
             * Creates a new UpdateRoomNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {streem.api.IUpdateRoomNoteRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateRoomNoteRequest} UpdateRoomNoteRequest instance
             */
            UpdateRoomNoteRequest.create = function create(properties) {
                return new UpdateRoomNoteRequest(properties);
            };

            /**
             * Encodes the specified UpdateRoomNoteRequest message. Does not implicitly {@link streem.api.UpdateRoomNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {streem.api.IUpdateRoomNoteRequest} m UpdateRoomNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateRoomNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                    w.uint32(18).string(m.text);
                if (m.authorSid != null && Object.hasOwnProperty.call(m, "authorSid"))
                    w.uint32(26).string(m.authorSid);
                return w;
            };

            /**
             * Decodes an UpdateRoomNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateRoomNoteRequest} UpdateRoomNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateRoomNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateRoomNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.text = r.string();
                            break;
                        }
                    case 3: {
                            m.authorSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateRoomNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateRoomNoteRequest} UpdateRoomNoteRequest
             */
            UpdateRoomNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateRoomNoteRequest)
                    return d;
                var m = new $root.streem.api.UpdateRoomNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.text != null) {
                    m.text = String(d.text);
                }
                if (d.authorSid != null) {
                    m.authorSid = String(d.authorSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateRoomNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {streem.api.UpdateRoomNoteRequest} m UpdateRoomNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateRoomNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.text = "";
                    d.authorSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.text != null && m.hasOwnProperty("text")) {
                    d.text = m.text;
                }
                if (m.authorSid != null && m.hasOwnProperty("authorSid")) {
                    d.authorSid = m.authorSid;
                }
                return d;
            };

            /**
             * Converts this UpdateRoomNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateRoomNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateRoomNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateRoomNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateRoomNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateRoomNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateRoomNoteRequest";
            };

            return UpdateRoomNoteRequest;
        })();

        api.UpdateRoomNoteResponse = (function() {

            /**
             * Properties of an UpdateRoomNoteResponse.
             * @memberof streem.api
             * @interface IUpdateRoomNoteResponse
             * @property {streem.api.INote|null} [note] UpdateRoomNoteResponse note
             */

            /**
             * Constructs a new UpdateRoomNoteResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateRoomNoteResponse.
             * @implements IUpdateRoomNoteResponse
             * @constructor
             * @param {streem.api.IUpdateRoomNoteResponse=} [p] Properties to set
             */
            function UpdateRoomNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateRoomNoteResponse note.
             * @member {streem.api.INote|null|undefined} note
             * @memberof streem.api.UpdateRoomNoteResponse
             * @instance
             */
            UpdateRoomNoteResponse.prototype.note = null;

            /**
             * Creates a new UpdateRoomNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {streem.api.IUpdateRoomNoteResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateRoomNoteResponse} UpdateRoomNoteResponse instance
             */
            UpdateRoomNoteResponse.create = function create(properties) {
                return new UpdateRoomNoteResponse(properties);
            };

            /**
             * Encodes the specified UpdateRoomNoteResponse message. Does not implicitly {@link streem.api.UpdateRoomNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {streem.api.IUpdateRoomNoteResponse} m UpdateRoomNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateRoomNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                    $root.streem.api.Note.encode(m.note, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateRoomNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateRoomNoteResponse} UpdateRoomNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateRoomNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateRoomNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.note = $root.streem.api.Note.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateRoomNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateRoomNoteResponse} UpdateRoomNoteResponse
             */
            UpdateRoomNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateRoomNoteResponse)
                    return d;
                var m = new $root.streem.api.UpdateRoomNoteResponse();
                if (d.note != null) {
                    if (typeof d.note !== "object")
                        throw TypeError(".streem.api.UpdateRoomNoteResponse.note: object expected");
                    m.note = $root.streem.api.Note.fromObject(d.note);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateRoomNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {streem.api.UpdateRoomNoteResponse} m UpdateRoomNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateRoomNoteResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.note = null;
                }
                if (m.note != null && m.hasOwnProperty("note")) {
                    d.note = $root.streem.api.Note.toObject(m.note, o);
                }
                return d;
            };

            /**
             * Converts this UpdateRoomNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateRoomNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateRoomNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateRoomNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateRoomNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateRoomNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateRoomNoteResponse";
            };

            return UpdateRoomNoteResponse;
        })();

        api.UpdateStreemshotNoteRequest = (function() {

            /**
             * Properties of an UpdateStreemshotNoteRequest.
             * @memberof streem.api
             * @interface IUpdateStreemshotNoteRequest
             * @property {string|null} [roomSid] UpdateStreemshotNoteRequest roomSid
             * @property {string|null} [streemshotSid] UpdateStreemshotNoteRequest streemshotSid
             * @property {string|null} [text] UpdateStreemshotNoteRequest text
             * @property {string|null} [authorSid] UpdateStreemshotNoteRequest authorSid
             */

            /**
             * Constructs a new UpdateStreemshotNoteRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateStreemshotNoteRequest.
             * @implements IUpdateStreemshotNoteRequest
             * @constructor
             * @param {streem.api.IUpdateStreemshotNoteRequest=} [p] Properties to set
             */
            function UpdateStreemshotNoteRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateStreemshotNoteRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @instance
             */
            UpdateStreemshotNoteRequest.prototype.roomSid = "";

            /**
             * UpdateStreemshotNoteRequest streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @instance
             */
            UpdateStreemshotNoteRequest.prototype.streemshotSid = "";

            /**
             * UpdateStreemshotNoteRequest text.
             * @member {string} text
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @instance
             */
            UpdateStreemshotNoteRequest.prototype.text = "";

            /**
             * UpdateStreemshotNoteRequest authorSid.
             * @member {string} authorSid
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @instance
             */
            UpdateStreemshotNoteRequest.prototype.authorSid = "";

            /**
             * Creates a new UpdateStreemshotNoteRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {streem.api.IUpdateStreemshotNoteRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateStreemshotNoteRequest} UpdateStreemshotNoteRequest instance
             */
            UpdateStreemshotNoteRequest.create = function create(properties) {
                return new UpdateStreemshotNoteRequest(properties);
            };

            /**
             * Encodes the specified UpdateStreemshotNoteRequest message. Does not implicitly {@link streem.api.UpdateStreemshotNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {streem.api.IUpdateStreemshotNoteRequest} m UpdateStreemshotNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateStreemshotNoteRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(18).string(m.streemshotSid);
                if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                    w.uint32(26).string(m.text);
                if (m.authorSid != null && Object.hasOwnProperty.call(m, "authorSid"))
                    w.uint32(34).string(m.authorSid);
                return w;
            };

            /**
             * Decodes an UpdateStreemshotNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateStreemshotNoteRequest} UpdateStreemshotNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateStreemshotNoteRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateStreemshotNoteRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    case 3: {
                            m.text = r.string();
                            break;
                        }
                    case 4: {
                            m.authorSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateStreemshotNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateStreemshotNoteRequest} UpdateStreemshotNoteRequest
             */
            UpdateStreemshotNoteRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateStreemshotNoteRequest)
                    return d;
                var m = new $root.streem.api.UpdateStreemshotNoteRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                if (d.text != null) {
                    m.text = String(d.text);
                }
                if (d.authorSid != null) {
                    m.authorSid = String(d.authorSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateStreemshotNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {streem.api.UpdateStreemshotNoteRequest} m UpdateStreemshotNoteRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateStreemshotNoteRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.streemshotSid = "";
                    d.text = "";
                    d.authorSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                if (m.text != null && m.hasOwnProperty("text")) {
                    d.text = m.text;
                }
                if (m.authorSid != null && m.hasOwnProperty("authorSid")) {
                    d.authorSid = m.authorSid;
                }
                return d;
            };

            /**
             * Converts this UpdateStreemshotNoteRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateStreemshotNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateStreemshotNoteRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateStreemshotNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateStreemshotNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateStreemshotNoteRequest";
            };

            return UpdateStreemshotNoteRequest;
        })();

        api.UpdateStreemshotNoteResponse = (function() {

            /**
             * Properties of an UpdateStreemshotNoteResponse.
             * @memberof streem.api
             * @interface IUpdateStreemshotNoteResponse
             * @property {streem.api.INote|null} [note] UpdateStreemshotNoteResponse note
             */

            /**
             * Constructs a new UpdateStreemshotNoteResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateStreemshotNoteResponse.
             * @implements IUpdateStreemshotNoteResponse
             * @constructor
             * @param {streem.api.IUpdateStreemshotNoteResponse=} [p] Properties to set
             */
            function UpdateStreemshotNoteResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateStreemshotNoteResponse note.
             * @member {streem.api.INote|null|undefined} note
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @instance
             */
            UpdateStreemshotNoteResponse.prototype.note = null;

            /**
             * Creates a new UpdateStreemshotNoteResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {streem.api.IUpdateStreemshotNoteResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateStreemshotNoteResponse} UpdateStreemshotNoteResponse instance
             */
            UpdateStreemshotNoteResponse.create = function create(properties) {
                return new UpdateStreemshotNoteResponse(properties);
            };

            /**
             * Encodes the specified UpdateStreemshotNoteResponse message. Does not implicitly {@link streem.api.UpdateStreemshotNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {streem.api.IUpdateStreemshotNoteResponse} m UpdateStreemshotNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateStreemshotNoteResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                    $root.streem.api.Note.encode(m.note, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateStreemshotNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateStreemshotNoteResponse} UpdateStreemshotNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateStreemshotNoteResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateStreemshotNoteResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.note = $root.streem.api.Note.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateStreemshotNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateStreemshotNoteResponse} UpdateStreemshotNoteResponse
             */
            UpdateStreemshotNoteResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateStreemshotNoteResponse)
                    return d;
                var m = new $root.streem.api.UpdateStreemshotNoteResponse();
                if (d.note != null) {
                    if (typeof d.note !== "object")
                        throw TypeError(".streem.api.UpdateStreemshotNoteResponse.note: object expected");
                    m.note = $root.streem.api.Note.fromObject(d.note);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateStreemshotNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {streem.api.UpdateStreemshotNoteResponse} m UpdateStreemshotNoteResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateStreemshotNoteResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.note = null;
                }
                if (m.note != null && m.hasOwnProperty("note")) {
                    d.note = $root.streem.api.Note.toObject(m.note, o);
                }
                return d;
            };

            /**
             * Converts this UpdateStreemshotNoteResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateStreemshotNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateStreemshotNoteResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateStreemshotNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateStreemshotNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateStreemshotNoteResponse";
            };

            return UpdateStreemshotNoteResponse;
        })();

        api.StartRecordingRequest = (function() {

            /**
             * Properties of a StartRecordingRequest.
             * @memberof streem.api
             * @interface IStartRecordingRequest
             * @property {string|null} [roomSid] StartRecordingRequest roomSid
             * @property {streem.api.VideoPlatform|null} [videoPlatform] StartRecordingRequest videoPlatform
             * @property {string|null} [companyCode] StartRecordingRequest companyCode
             * @property {string|null} [chimeRoomArn] StartRecordingRequest chimeRoomArn
             * @property {string|null} [chimeRoomSid] StartRecordingRequest chimeRoomSid
             */

            /**
             * Constructs a new StartRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a StartRecordingRequest.
             * @implements IStartRecordingRequest
             * @constructor
             * @param {streem.api.IStartRecordingRequest=} [p] Properties to set
             */
            function StartRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StartRecordingRequest
             * @instance
             */
            StartRecordingRequest.prototype.roomSid = "";

            /**
             * StartRecordingRequest videoPlatform.
             * @member {streem.api.VideoPlatform} videoPlatform
             * @memberof streem.api.StartRecordingRequest
             * @instance
             */
            StartRecordingRequest.prototype.videoPlatform = 0;

            /**
             * StartRecordingRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.StartRecordingRequest
             * @instance
             */
            StartRecordingRequest.prototype.companyCode = "";

            /**
             * StartRecordingRequest chimeRoomArn.
             * @member {string} chimeRoomArn
             * @memberof streem.api.StartRecordingRequest
             * @instance
             */
            StartRecordingRequest.prototype.chimeRoomArn = "";

            /**
             * StartRecordingRequest chimeRoomSid.
             * @member {string} chimeRoomSid
             * @memberof streem.api.StartRecordingRequest
             * @instance
             */
            StartRecordingRequest.prototype.chimeRoomSid = "";

            /**
             * Creates a new StartRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {streem.api.IStartRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.StartRecordingRequest} StartRecordingRequest instance
             */
            StartRecordingRequest.create = function create(properties) {
                return new StartRecordingRequest(properties);
            };

            /**
             * Encodes the specified StartRecordingRequest message. Does not implicitly {@link streem.api.StartRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {streem.api.IStartRecordingRequest} m StartRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.videoPlatform != null && Object.hasOwnProperty.call(m, "videoPlatform"))
                    w.uint32(16).int32(m.videoPlatform);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(26).string(m.companyCode);
                if (m.chimeRoomArn != null && Object.hasOwnProperty.call(m, "chimeRoomArn"))
                    w.uint32(34).string(m.chimeRoomArn);
                if (m.chimeRoomSid != null && Object.hasOwnProperty.call(m, "chimeRoomSid"))
                    w.uint32(42).string(m.chimeRoomSid);
                return w;
            };

            /**
             * Decodes a StartRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartRecordingRequest} StartRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.videoPlatform = r.int32();
                            break;
                        }
                    case 3: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 4: {
                            m.chimeRoomArn = r.string();
                            break;
                        }
                    case 5: {
                            m.chimeRoomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartRecordingRequest} StartRecordingRequest
             */
            StartRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartRecordingRequest)
                    return d;
                var m = new $root.streem.api.StartRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                switch (d.videoPlatform) {
                default:
                    if (typeof d.videoPlatform === "number") {
                        m.videoPlatform = d.videoPlatform;
                        break;
                    }
                    break;
                case "VIDEO_PLATFORM_INVALID":
                case 0:
                    m.videoPlatform = 0;
                    break;
                case "VIDEO_PLATFORM_TWILIO":
                case 1:
                    m.videoPlatform = 1;
                    break;
                case "VIDEO_PLATFORM_ARAAS":
                case 2:
                    m.videoPlatform = 2;
                    break;
                case "VIDEO_PLATFORM_CHIME":
                case 3:
                    m.videoPlatform = 3;
                    break;
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.chimeRoomArn != null) {
                    m.chimeRoomArn = String(d.chimeRoomArn);
                }
                if (d.chimeRoomSid != null) {
                    m.chimeRoomSid = String(d.chimeRoomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {streem.api.StartRecordingRequest} m StartRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.videoPlatform = o.enums === String ? "VIDEO_PLATFORM_INVALID" : 0;
                    d.companyCode = "";
                    d.chimeRoomArn = "";
                    d.chimeRoomSid = "";
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.videoPlatform != null && m.hasOwnProperty("videoPlatform")) {
                    d.videoPlatform = o.enums === String ? $root.streem.api.VideoPlatform[m.videoPlatform] === undefined ? m.videoPlatform : $root.streem.api.VideoPlatform[m.videoPlatform] : m.videoPlatform;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.chimeRoomArn != null && m.hasOwnProperty("chimeRoomArn")) {
                    d.chimeRoomArn = m.chimeRoomArn;
                }
                if (m.chimeRoomSid != null && m.hasOwnProperty("chimeRoomSid")) {
                    d.chimeRoomSid = m.chimeRoomSid;
                }
                return d;
            };

            /**
             * Converts this StartRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.StartRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.StartRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartRecordingRequest";
            };

            return StartRecordingRequest;
        })();

        api.StartRecordingResponse = (function() {

            /**
             * Properties of a StartRecordingResponse.
             * @memberof streem.api
             * @interface IStartRecordingResponse
             * @property {streem.api.IArtifact|null} [recordingArtifact] StartRecordingResponse recordingArtifact
             */

            /**
             * Constructs a new StartRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a StartRecordingResponse.
             * @implements IStartRecordingResponse
             * @constructor
             * @param {streem.api.IStartRecordingResponse=} [p] Properties to set
             */
            function StartRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StartRecordingResponse recordingArtifact.
             * @member {streem.api.IArtifact|null|undefined} recordingArtifact
             * @memberof streem.api.StartRecordingResponse
             * @instance
             */
            StartRecordingResponse.prototype.recordingArtifact = null;

            /**
             * Creates a new StartRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {streem.api.IStartRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.StartRecordingResponse} StartRecordingResponse instance
             */
            StartRecordingResponse.create = function create(properties) {
                return new StartRecordingResponse(properties);
            };

            /**
             * Encodes the specified StartRecordingResponse message. Does not implicitly {@link streem.api.StartRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {streem.api.IStartRecordingResponse} m StartRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recordingArtifact != null && Object.hasOwnProperty.call(m, "recordingArtifact"))
                    $root.streem.api.Artifact.encode(m.recordingArtifact, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a StartRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StartRecordingResponse} StartRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StartRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.recordingArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StartRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StartRecordingResponse} StartRecordingResponse
             */
            StartRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StartRecordingResponse)
                    return d;
                var m = new $root.streem.api.StartRecordingResponse();
                if (d.recordingArtifact != null) {
                    if (typeof d.recordingArtifact !== "object")
                        throw TypeError(".streem.api.StartRecordingResponse.recordingArtifact: object expected");
                    m.recordingArtifact = $root.streem.api.Artifact.fromObject(d.recordingArtifact);
                }
                return m;
            };

            /**
             * Creates a plain object from a StartRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {streem.api.StartRecordingResponse} m StartRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartRecordingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.recordingArtifact = null;
                }
                if (m.recordingArtifact != null && m.hasOwnProperty("recordingArtifact")) {
                    d.recordingArtifact = $root.streem.api.Artifact.toObject(m.recordingArtifact, o);
                }
                return d;
            };

            /**
             * Converts this StartRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.StartRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.StartRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StartRecordingResponse";
            };

            return StartRecordingResponse;
        })();

        api.PauseRecordingRequest = (function() {

            /**
             * Properties of a PauseRecordingRequest.
             * @memberof streem.api
             * @interface IPauseRecordingRequest
             * @property {string|null} [roomSid] PauseRecordingRequest roomSid
             * @property {streem.api.VideoPlatform|null} [videoPlatform] PauseRecordingRequest videoPlatform
             */

            /**
             * Constructs a new PauseRecordingRequest.
             * @memberof streem.api
             * @classdesc Represents a PauseRecordingRequest.
             * @implements IPauseRecordingRequest
             * @constructor
             * @param {streem.api.IPauseRecordingRequest=} [p] Properties to set
             */
            function PauseRecordingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PauseRecordingRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.PauseRecordingRequest
             * @instance
             */
            PauseRecordingRequest.prototype.roomSid = "";

            /**
             * PauseRecordingRequest videoPlatform.
             * @member {streem.api.VideoPlatform} videoPlatform
             * @memberof streem.api.PauseRecordingRequest
             * @instance
             */
            PauseRecordingRequest.prototype.videoPlatform = 0;

            /**
             * Creates a new PauseRecordingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {streem.api.IPauseRecordingRequest=} [properties] Properties to set
             * @returns {streem.api.PauseRecordingRequest} PauseRecordingRequest instance
             */
            PauseRecordingRequest.create = function create(properties) {
                return new PauseRecordingRequest(properties);
            };

            /**
             * Encodes the specified PauseRecordingRequest message. Does not implicitly {@link streem.api.PauseRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {streem.api.IPauseRecordingRequest} m PauseRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PauseRecordingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.videoPlatform != null && Object.hasOwnProperty.call(m, "videoPlatform"))
                    w.uint32(16).int32(m.videoPlatform);
                return w;
            };

            /**
             * Decodes a PauseRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PauseRecordingRequest} PauseRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PauseRecordingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PauseRecordingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.videoPlatform = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PauseRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PauseRecordingRequest} PauseRecordingRequest
             */
            PauseRecordingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PauseRecordingRequest)
                    return d;
                var m = new $root.streem.api.PauseRecordingRequest();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                switch (d.videoPlatform) {
                default:
                    if (typeof d.videoPlatform === "number") {
                        m.videoPlatform = d.videoPlatform;
                        break;
                    }
                    break;
                case "VIDEO_PLATFORM_INVALID":
                case 0:
                    m.videoPlatform = 0;
                    break;
                case "VIDEO_PLATFORM_TWILIO":
                case 1:
                    m.videoPlatform = 1;
                    break;
                case "VIDEO_PLATFORM_ARAAS":
                case 2:
                    m.videoPlatform = 2;
                    break;
                case "VIDEO_PLATFORM_CHIME":
                case 3:
                    m.videoPlatform = 3;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a PauseRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {streem.api.PauseRecordingRequest} m PauseRecordingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PauseRecordingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.videoPlatform = o.enums === String ? "VIDEO_PLATFORM_INVALID" : 0;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.videoPlatform != null && m.hasOwnProperty("videoPlatform")) {
                    d.videoPlatform = o.enums === String ? $root.streem.api.VideoPlatform[m.videoPlatform] === undefined ? m.videoPlatform : $root.streem.api.VideoPlatform[m.videoPlatform] : m.videoPlatform;
                }
                return d;
            };

            /**
             * Converts this PauseRecordingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.PauseRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PauseRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PauseRecordingRequest
             * @function getTypeUrl
             * @memberof streem.api.PauseRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PauseRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PauseRecordingRequest";
            };

            return PauseRecordingRequest;
        })();

        api.PauseRecordingResponse = (function() {

            /**
             * Properties of a PauseRecordingResponse.
             * @memberof streem.api
             * @interface IPauseRecordingResponse
             */

            /**
             * Constructs a new PauseRecordingResponse.
             * @memberof streem.api
             * @classdesc Represents a PauseRecordingResponse.
             * @implements IPauseRecordingResponse
             * @constructor
             * @param {streem.api.IPauseRecordingResponse=} [p] Properties to set
             */
            function PauseRecordingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new PauseRecordingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {streem.api.IPauseRecordingResponse=} [properties] Properties to set
             * @returns {streem.api.PauseRecordingResponse} PauseRecordingResponse instance
             */
            PauseRecordingResponse.create = function create(properties) {
                return new PauseRecordingResponse(properties);
            };

            /**
             * Encodes the specified PauseRecordingResponse message. Does not implicitly {@link streem.api.PauseRecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {streem.api.IPauseRecordingResponse} m PauseRecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PauseRecordingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a PauseRecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PauseRecordingResponse} PauseRecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PauseRecordingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PauseRecordingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PauseRecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PauseRecordingResponse} PauseRecordingResponse
             */
            PauseRecordingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PauseRecordingResponse)
                    return d;
                return new $root.streem.api.PauseRecordingResponse();
            };

            /**
             * Creates a plain object from a PauseRecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {streem.api.PauseRecordingResponse} m PauseRecordingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PauseRecordingResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PauseRecordingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.PauseRecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PauseRecordingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PauseRecordingResponse
             * @function getTypeUrl
             * @memberof streem.api.PauseRecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PauseRecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PauseRecordingResponse";
            };

            return PauseRecordingResponse;
        })();

        api.Detection = (function() {

            /**
             * Properties of a Detection.
             * @memberof streem.api
             * @interface IDetection
             * @property {string|null} [detectionSid] Detection detectionSid
             * @property {streem.api.Detection.Type|null} [type] Detection type
             * @property {string|null} [description] Detection description
             * @property {number|null} [confidence] Detection confidence
             * @property {streem.api.Detection.IBoundingBox|null} [boundingBox] Detection boundingBox
             * @property {streem.api.Detection.Source|null} [source] Detection source
             * @property {boolean|null} [hidden] Detection hidden
             * @property {string|null} [extractor] Detection extractor
             * @property {google.protobuf.ITimestamp|null} [lastUpdatedAt] Detection lastUpdatedAt
             */

            /**
             * Constructs a new Detection.
             * @memberof streem.api
             * @classdesc Represents a Detection.
             * @implements IDetection
             * @constructor
             * @param {streem.api.IDetection=} [p] Properties to set
             */
            function Detection(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Detection detectionSid.
             * @member {string} detectionSid
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.detectionSid = "";

            /**
             * Detection type.
             * @member {streem.api.Detection.Type} type
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.type = 0;

            /**
             * Detection description.
             * @member {string} description
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.description = "";

            /**
             * Detection confidence.
             * @member {number} confidence
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.confidence = 0;

            /**
             * Detection boundingBox.
             * @member {streem.api.Detection.IBoundingBox|null|undefined} boundingBox
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.boundingBox = null;

            /**
             * Detection source.
             * @member {streem.api.Detection.Source} source
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.source = 0;

            /**
             * Detection hidden.
             * @member {boolean} hidden
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.hidden = false;

            /**
             * Detection extractor.
             * @member {string} extractor
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.extractor = "";

            /**
             * Detection lastUpdatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdatedAt
             * @memberof streem.api.Detection
             * @instance
             */
            Detection.prototype.lastUpdatedAt = null;

            /**
             * Creates a new Detection instance using the specified properties.
             * @function create
             * @memberof streem.api.Detection
             * @static
             * @param {streem.api.IDetection=} [properties] Properties to set
             * @returns {streem.api.Detection} Detection instance
             */
            Detection.create = function create(properties) {
                return new Detection(properties);
            };

            /**
             * Encodes the specified Detection message. Does not implicitly {@link streem.api.Detection.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Detection
             * @static
             * @param {streem.api.IDetection} m Detection message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Detection.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.detectionSid != null && Object.hasOwnProperty.call(m, "detectionSid"))
                    w.uint32(10).string(m.detectionSid);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(16).int32(m.type);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(26).string(m.description);
                if (m.confidence != null && Object.hasOwnProperty.call(m, "confidence"))
                    w.uint32(37).float(m.confidence);
                if (m.boundingBox != null && Object.hasOwnProperty.call(m, "boundingBox"))
                    $root.streem.api.Detection.BoundingBox.encode(m.boundingBox, w.uint32(42).fork()).ldelim();
                if (m.source != null && Object.hasOwnProperty.call(m, "source"))
                    w.uint32(48).int32(m.source);
                if (m.hidden != null && Object.hasOwnProperty.call(m, "hidden"))
                    w.uint32(56).bool(m.hidden);
                if (m.extractor != null && Object.hasOwnProperty.call(m, "extractor"))
                    w.uint32(66).string(m.extractor);
                if (m.lastUpdatedAt != null && Object.hasOwnProperty.call(m, "lastUpdatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.lastUpdatedAt, w.uint32(74).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a Detection message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Detection
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Detection} Detection
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Detection.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Detection();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.detectionSid = r.string();
                            break;
                        }
                    case 2: {
                            m.type = r.int32();
                            break;
                        }
                    case 3: {
                            m.description = r.string();
                            break;
                        }
                    case 4: {
                            m.confidence = r.float();
                            break;
                        }
                    case 5: {
                            m.boundingBox = $root.streem.api.Detection.BoundingBox.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.source = r.int32();
                            break;
                        }
                    case 7: {
                            m.hidden = r.bool();
                            break;
                        }
                    case 8: {
                            m.extractor = r.string();
                            break;
                        }
                    case 9: {
                            m.lastUpdatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Detection message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Detection
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Detection} Detection
             */
            Detection.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Detection)
                    return d;
                var m = new $root.streem.api.Detection();
                if (d.detectionSid != null) {
                    m.detectionSid = String(d.detectionSid);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "DETECTION_TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "DETECTION_TYPE_LOGO":
                case 1:
                    m.type = 1;
                    break;
                case "DETECTION_TYPE_LABEL":
                case 2:
                    m.type = 2;
                    break;
                case "DETECTION_TYPE_TEXT":
                case 3:
                    m.type = 3;
                    break;
                case "DETECTION_TYPE_FULL_TEXT":
                case 4:
                    m.type = 4;
                    break;
                case "DETECTION_TYPE_SAFE_SEARCH":
                case 5:
                    m.type = 5;
                    break;
                case "DETECTION_TYPE_SERIAL":
                case 6:
                    m.type = 6;
                    break;
                case "DETECTION_TYPE_MODEL":
                case 7:
                    m.type = 7;
                    break;
                case "DETECTION_TYPE_OBJECT":
                case 8:
                    m.type = 8;
                    break;
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                if (d.confidence != null) {
                    m.confidence = Number(d.confidence);
                }
                if (d.boundingBox != null) {
                    if (typeof d.boundingBox !== "object")
                        throw TypeError(".streem.api.Detection.boundingBox: object expected");
                    m.boundingBox = $root.streem.api.Detection.BoundingBox.fromObject(d.boundingBox);
                }
                switch (d.source) {
                default:
                    if (typeof d.source === "number") {
                        m.source = d.source;
                        break;
                    }
                    break;
                case "DETECTION_SOURCE_INVALID":
                case 0:
                    m.source = 0;
                    break;
                case "DETECTION_SOURCE_OCR":
                case 1:
                    m.source = 1;
                    break;
                case "DETECTION_SOURCE_USER":
                case 2:
                    m.source = 2;
                    break;
                case "DETECTION_SOURCE_AUTO":
                case 3:
                    m.source = 3;
                    break;
                }
                if (d.hidden != null) {
                    m.hidden = Boolean(d.hidden);
                }
                if (d.extractor != null) {
                    m.extractor = String(d.extractor);
                }
                if (d.lastUpdatedAt != null) {
                    if (typeof d.lastUpdatedAt !== "object")
                        throw TypeError(".streem.api.Detection.lastUpdatedAt: object expected");
                    m.lastUpdatedAt = $root.google.protobuf.Timestamp.fromObject(d.lastUpdatedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a Detection message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Detection
             * @static
             * @param {streem.api.Detection} m Detection
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Detection.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.detectionSid = "";
                    d.type = o.enums === String ? "DETECTION_TYPE_INVALID" : 0;
                    d.description = "";
                    d.confidence = 0;
                    d.boundingBox = null;
                    d.source = o.enums === String ? "DETECTION_SOURCE_INVALID" : 0;
                    d.hidden = false;
                    d.extractor = "";
                    d.lastUpdatedAt = null;
                }
                if (m.detectionSid != null && m.hasOwnProperty("detectionSid")) {
                    d.detectionSid = m.detectionSid;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.Detection.Type[m.type] === undefined ? m.type : $root.streem.api.Detection.Type[m.type] : m.type;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.confidence != null && m.hasOwnProperty("confidence")) {
                    d.confidence = o.json && !isFinite(m.confidence) ? String(m.confidence) : m.confidence;
                }
                if (m.boundingBox != null && m.hasOwnProperty("boundingBox")) {
                    d.boundingBox = $root.streem.api.Detection.BoundingBox.toObject(m.boundingBox, o);
                }
                if (m.source != null && m.hasOwnProperty("source")) {
                    d.source = o.enums === String ? $root.streem.api.Detection.Source[m.source] === undefined ? m.source : $root.streem.api.Detection.Source[m.source] : m.source;
                }
                if (m.hidden != null && m.hasOwnProperty("hidden")) {
                    d.hidden = m.hidden;
                }
                if (m.extractor != null && m.hasOwnProperty("extractor")) {
                    d.extractor = m.extractor;
                }
                if (m.lastUpdatedAt != null && m.hasOwnProperty("lastUpdatedAt")) {
                    d.lastUpdatedAt = $root.google.protobuf.Timestamp.toObject(m.lastUpdatedAt, o);
                }
                return d;
            };

            /**
             * Converts this Detection to JSON.
             * @function toJSON
             * @memberof streem.api.Detection
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Detection.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Detection
             * @function getTypeUrl
             * @memberof streem.api.Detection
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Detection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Detection";
            };

            Detection.BoundingBox = (function() {

                /**
                 * Properties of a BoundingBox.
                 * @memberof streem.api.Detection
                 * @interface IBoundingBox
                 * @property {number|null} [x] BoundingBox x
                 * @property {number|null} [y] BoundingBox y
                 * @property {number|null} [width] BoundingBox width
                 * @property {number|null} [height] BoundingBox height
                 */

                /**
                 * Constructs a new BoundingBox.
                 * @memberof streem.api.Detection
                 * @classdesc Represents a BoundingBox.
                 * @implements IBoundingBox
                 * @constructor
                 * @param {streem.api.Detection.IBoundingBox=} [p] Properties to set
                 */
                function BoundingBox(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * BoundingBox x.
                 * @member {number} x
                 * @memberof streem.api.Detection.BoundingBox
                 * @instance
                 */
                BoundingBox.prototype.x = 0;

                /**
                 * BoundingBox y.
                 * @member {number} y
                 * @memberof streem.api.Detection.BoundingBox
                 * @instance
                 */
                BoundingBox.prototype.y = 0;

                /**
                 * BoundingBox width.
                 * @member {number} width
                 * @memberof streem.api.Detection.BoundingBox
                 * @instance
                 */
                BoundingBox.prototype.width = 0;

                /**
                 * BoundingBox height.
                 * @member {number} height
                 * @memberof streem.api.Detection.BoundingBox
                 * @instance
                 */
                BoundingBox.prototype.height = 0;

                /**
                 * Creates a new BoundingBox instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {streem.api.Detection.IBoundingBox=} [properties] Properties to set
                 * @returns {streem.api.Detection.BoundingBox} BoundingBox instance
                 */
                BoundingBox.create = function create(properties) {
                    return new BoundingBox(properties);
                };

                /**
                 * Encodes the specified BoundingBox message. Does not implicitly {@link streem.api.Detection.BoundingBox.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {streem.api.Detection.IBoundingBox} m BoundingBox message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoundingBox.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.x != null && Object.hasOwnProperty.call(m, "x"))
                        w.uint32(13).float(m.x);
                    if (m.y != null && Object.hasOwnProperty.call(m, "y"))
                        w.uint32(21).float(m.y);
                    if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                        w.uint32(29).float(m.width);
                    if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                        w.uint32(37).float(m.height);
                    return w;
                };

                /**
                 * Decodes a BoundingBox message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Detection.BoundingBox} BoundingBox
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoundingBox.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Detection.BoundingBox();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.x = r.float();
                                break;
                            }
                        case 2: {
                                m.y = r.float();
                                break;
                            }
                        case 3: {
                                m.width = r.float();
                                break;
                            }
                        case 4: {
                                m.height = r.float();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a BoundingBox message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Detection.BoundingBox} BoundingBox
                 */
                BoundingBox.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Detection.BoundingBox)
                        return d;
                    var m = new $root.streem.api.Detection.BoundingBox();
                    if (d.x != null) {
                        m.x = Number(d.x);
                    }
                    if (d.y != null) {
                        m.y = Number(d.y);
                    }
                    if (d.width != null) {
                        m.width = Number(d.width);
                    }
                    if (d.height != null) {
                        m.height = Number(d.height);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a BoundingBox message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {streem.api.Detection.BoundingBox} m BoundingBox
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BoundingBox.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.x = 0;
                        d.y = 0;
                        d.width = 0;
                        d.height = 0;
                    }
                    if (m.x != null && m.hasOwnProperty("x")) {
                        d.x = o.json && !isFinite(m.x) ? String(m.x) : m.x;
                    }
                    if (m.y != null && m.hasOwnProperty("y")) {
                        d.y = o.json && !isFinite(m.y) ? String(m.y) : m.y;
                    }
                    if (m.width != null && m.hasOwnProperty("width")) {
                        d.width = o.json && !isFinite(m.width) ? String(m.width) : m.width;
                    }
                    if (m.height != null && m.hasOwnProperty("height")) {
                        d.height = o.json && !isFinite(m.height) ? String(m.height) : m.height;
                    }
                    return d;
                };

                /**
                 * Converts this BoundingBox to JSON.
                 * @function toJSON
                 * @memberof streem.api.Detection.BoundingBox
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BoundingBox.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BoundingBox
                 * @function getTypeUrl
                 * @memberof streem.api.Detection.BoundingBox
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BoundingBox.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Detection.BoundingBox";
                };

                return BoundingBox;
            })();

            /**
             * Type enum.
             * @name streem.api.Detection.Type
             * @enum {number}
             * @property {number} DETECTION_TYPE_INVALID=0 DETECTION_TYPE_INVALID value
             * @property {number} DETECTION_TYPE_LOGO=1 DETECTION_TYPE_LOGO value
             * @property {number} DETECTION_TYPE_LABEL=2 DETECTION_TYPE_LABEL value
             * @property {number} DETECTION_TYPE_TEXT=3 DETECTION_TYPE_TEXT value
             * @property {number} DETECTION_TYPE_FULL_TEXT=4 DETECTION_TYPE_FULL_TEXT value
             * @property {number} DETECTION_TYPE_SAFE_SEARCH=5 DETECTION_TYPE_SAFE_SEARCH value
             * @property {number} DETECTION_TYPE_SERIAL=6 DETECTION_TYPE_SERIAL value
             * @property {number} DETECTION_TYPE_MODEL=7 DETECTION_TYPE_MODEL value
             * @property {number} DETECTION_TYPE_OBJECT=8 DETECTION_TYPE_OBJECT value
             */
            Detection.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DETECTION_TYPE_INVALID"] = 0;
                values[valuesById[1] = "DETECTION_TYPE_LOGO"] = 1;
                values[valuesById[2] = "DETECTION_TYPE_LABEL"] = 2;
                values[valuesById[3] = "DETECTION_TYPE_TEXT"] = 3;
                values[valuesById[4] = "DETECTION_TYPE_FULL_TEXT"] = 4;
                values[valuesById[5] = "DETECTION_TYPE_SAFE_SEARCH"] = 5;
                values[valuesById[6] = "DETECTION_TYPE_SERIAL"] = 6;
                values[valuesById[7] = "DETECTION_TYPE_MODEL"] = 7;
                values[valuesById[8] = "DETECTION_TYPE_OBJECT"] = 8;
                return values;
            })();

            /**
             * Source enum.
             * @name streem.api.Detection.Source
             * @enum {number}
             * @property {number} DETECTION_SOURCE_INVALID=0 DETECTION_SOURCE_INVALID value
             * @property {number} DETECTION_SOURCE_OCR=1 DETECTION_SOURCE_OCR value
             * @property {number} DETECTION_SOURCE_USER=2 DETECTION_SOURCE_USER value
             * @property {number} DETECTION_SOURCE_AUTO=3 DETECTION_SOURCE_AUTO value
             */
            Detection.Source = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DETECTION_SOURCE_INVALID"] = 0;
                values[valuesById[1] = "DETECTION_SOURCE_OCR"] = 1;
                values[valuesById[2] = "DETECTION_SOURCE_USER"] = 2;
                values[valuesById[3] = "DETECTION_SOURCE_AUTO"] = 3;
                return values;
            })();

            return Detection;
        })();

        api.StreemshotDetections = (function() {

            /**
             * Properties of a StreemshotDetections.
             * @memberof streem.api
             * @interface IStreemshotDetections
             * @property {string|null} [streemshotSid] StreemshotDetections streemshotSid
             * @property {Array.<streem.api.IDetection>|null} [detections] StreemshotDetections detections
             */

            /**
             * Constructs a new StreemshotDetections.
             * @memberof streem.api
             * @classdesc Represents a StreemshotDetections.
             * @implements IStreemshotDetections
             * @constructor
             * @param {streem.api.IStreemshotDetections=} [p] Properties to set
             */
            function StreemshotDetections(p) {
                this.detections = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemshotDetections streemshotSid.
             * @member {string} streemshotSid
             * @memberof streem.api.StreemshotDetections
             * @instance
             */
            StreemshotDetections.prototype.streemshotSid = "";

            /**
             * StreemshotDetections detections.
             * @member {Array.<streem.api.IDetection>} detections
             * @memberof streem.api.StreemshotDetections
             * @instance
             */
            StreemshotDetections.prototype.detections = $util.emptyArray;

            /**
             * Creates a new StreemshotDetections instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {streem.api.IStreemshotDetections=} [properties] Properties to set
             * @returns {streem.api.StreemshotDetections} StreemshotDetections instance
             */
            StreemshotDetections.create = function create(properties) {
                return new StreemshotDetections(properties);
            };

            /**
             * Encodes the specified StreemshotDetections message. Does not implicitly {@link streem.api.StreemshotDetections.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {streem.api.IStreemshotDetections} m StreemshotDetections message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemshotDetections.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.streemshotSid != null && Object.hasOwnProperty.call(m, "streemshotSid"))
                    w.uint32(10).string(m.streemshotSid);
                if (m.detections != null && m.detections.length) {
                    for (var i = 0; i < m.detections.length; ++i)
                        $root.streem.api.Detection.encode(m.detections[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a StreemshotDetections message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemshotDetections} StreemshotDetections
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemshotDetections.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemshotDetections();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.streemshotSid = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.detections && m.detections.length))
                                m.detections = [];
                            m.detections.push($root.streem.api.Detection.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemshotDetections message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemshotDetections} StreemshotDetections
             */
            StreemshotDetections.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemshotDetections)
                    return d;
                var m = new $root.streem.api.StreemshotDetections();
                if (d.streemshotSid != null) {
                    m.streemshotSid = String(d.streemshotSid);
                }
                if (d.detections) {
                    if (!Array.isArray(d.detections))
                        throw TypeError(".streem.api.StreemshotDetections.detections: array expected");
                    m.detections = [];
                    for (var i = 0; i < d.detections.length; ++i) {
                        if (typeof d.detections[i] !== "object")
                            throw TypeError(".streem.api.StreemshotDetections.detections: object expected");
                        m.detections[i] = $root.streem.api.Detection.fromObject(d.detections[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemshotDetections message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {streem.api.StreemshotDetections} m StreemshotDetections
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemshotDetections.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.detections = [];
                }
                if (o.defaults) {
                    d.streemshotSid = "";
                }
                if (m.streemshotSid != null && m.hasOwnProperty("streemshotSid")) {
                    d.streemshotSid = m.streemshotSid;
                }
                if (m.detections && m.detections.length) {
                    d.detections = [];
                    for (var j = 0; j < m.detections.length; ++j) {
                        d.detections[j] = $root.streem.api.Detection.toObject(m.detections[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this StreemshotDetections to JSON.
             * @function toJSON
             * @memberof streem.api.StreemshotDetections
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemshotDetections.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemshotDetections
             * @function getTypeUrl
             * @memberof streem.api.StreemshotDetections
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemshotDetections.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemshotDetections";
            };

            return StreemshotDetections;
        })();

        api.DetectionResult = (function() {

            /**
             * Properties of a DetectionResult.
             * @memberof streem.api
             * @interface IDetectionResult
             * @property {streem.api.IModelNo|null} [modelNo] DetectionResult modelNo
             * @property {streem.api.ISerialNo|null} [serialNo] DetectionResult serialNo
             */

            /**
             * Constructs a new DetectionResult.
             * @memberof streem.api
             * @classdesc Represents a DetectionResult.
             * @implements IDetectionResult
             * @constructor
             * @param {streem.api.IDetectionResult=} [p] Properties to set
             */
            function DetectionResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DetectionResult modelNo.
             * @member {streem.api.IModelNo|null|undefined} modelNo
             * @memberof streem.api.DetectionResult
             * @instance
             */
            DetectionResult.prototype.modelNo = null;

            /**
             * DetectionResult serialNo.
             * @member {streem.api.ISerialNo|null|undefined} serialNo
             * @memberof streem.api.DetectionResult
             * @instance
             */
            DetectionResult.prototype.serialNo = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * DetectionResult type.
             * @member {"modelNo"|"serialNo"|undefined} type
             * @memberof streem.api.DetectionResult
             * @instance
             */
            Object.defineProperty(DetectionResult.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["modelNo", "serialNo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new DetectionResult instance using the specified properties.
             * @function create
             * @memberof streem.api.DetectionResult
             * @static
             * @param {streem.api.IDetectionResult=} [properties] Properties to set
             * @returns {streem.api.DetectionResult} DetectionResult instance
             */
            DetectionResult.create = function create(properties) {
                return new DetectionResult(properties);
            };

            /**
             * Encodes the specified DetectionResult message. Does not implicitly {@link streem.api.DetectionResult.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DetectionResult
             * @static
             * @param {streem.api.IDetectionResult} m DetectionResult message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DetectionResult.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.modelNo != null && Object.hasOwnProperty.call(m, "modelNo"))
                    $root.streem.api.ModelNo.encode(m.modelNo, w.uint32(10).fork()).ldelim();
                if (m.serialNo != null && Object.hasOwnProperty.call(m, "serialNo"))
                    $root.streem.api.SerialNo.encode(m.serialNo, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a DetectionResult message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DetectionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DetectionResult} DetectionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DetectionResult.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DetectionResult();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.modelNo = $root.streem.api.ModelNo.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.serialNo = $root.streem.api.SerialNo.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DetectionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DetectionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DetectionResult} DetectionResult
             */
            DetectionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DetectionResult)
                    return d;
                var m = new $root.streem.api.DetectionResult();
                if (d.modelNo != null) {
                    if (typeof d.modelNo !== "object")
                        throw TypeError(".streem.api.DetectionResult.modelNo: object expected");
                    m.modelNo = $root.streem.api.ModelNo.fromObject(d.modelNo);
                }
                if (d.serialNo != null) {
                    if (typeof d.serialNo !== "object")
                        throw TypeError(".streem.api.DetectionResult.serialNo: object expected");
                    m.serialNo = $root.streem.api.SerialNo.fromObject(d.serialNo);
                }
                return m;
            };

            /**
             * Creates a plain object from a DetectionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DetectionResult
             * @static
             * @param {streem.api.DetectionResult} m DetectionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DetectionResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.modelNo != null && m.hasOwnProperty("modelNo")) {
                    d.modelNo = $root.streem.api.ModelNo.toObject(m.modelNo, o);
                    if (o.oneofs)
                        d.type = "modelNo";
                }
                if (m.serialNo != null && m.hasOwnProperty("serialNo")) {
                    d.serialNo = $root.streem.api.SerialNo.toObject(m.serialNo, o);
                    if (o.oneofs)
                        d.type = "serialNo";
                }
                return d;
            };

            /**
             * Converts this DetectionResult to JSON.
             * @function toJSON
             * @memberof streem.api.DetectionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DetectionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DetectionResult
             * @function getTypeUrl
             * @memberof streem.api.DetectionResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DetectionResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DetectionResult";
            };

            return DetectionResult;
        })();

        api.ModelNo = (function() {

            /**
             * Properties of a ModelNo.
             * @memberof streem.api
             * @interface IModelNo
             * @property {string|null} [value] ModelNo value
             * @property {number|null} [confidence] ModelNo confidence
             */

            /**
             * Constructs a new ModelNo.
             * @memberof streem.api
             * @classdesc Represents a ModelNo.
             * @implements IModelNo
             * @constructor
             * @param {streem.api.IModelNo=} [p] Properties to set
             */
            function ModelNo(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ModelNo value.
             * @member {string} value
             * @memberof streem.api.ModelNo
             * @instance
             */
            ModelNo.prototype.value = "";

            /**
             * ModelNo confidence.
             * @member {number} confidence
             * @memberof streem.api.ModelNo
             * @instance
             */
            ModelNo.prototype.confidence = 0;

            /**
             * Creates a new ModelNo instance using the specified properties.
             * @function create
             * @memberof streem.api.ModelNo
             * @static
             * @param {streem.api.IModelNo=} [properties] Properties to set
             * @returns {streem.api.ModelNo} ModelNo instance
             */
            ModelNo.create = function create(properties) {
                return new ModelNo(properties);
            };

            /**
             * Encodes the specified ModelNo message. Does not implicitly {@link streem.api.ModelNo.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ModelNo
             * @static
             * @param {streem.api.IModelNo} m ModelNo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ModelNo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).string(m.value);
                if (m.confidence != null && Object.hasOwnProperty.call(m, "confidence"))
                    w.uint32(21).float(m.confidence);
                return w;
            };

            /**
             * Decodes a ModelNo message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ModelNo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ModelNo} ModelNo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ModelNo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ModelNo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.string();
                            break;
                        }
                    case 2: {
                            m.confidence = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ModelNo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ModelNo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ModelNo} ModelNo
             */
            ModelNo.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ModelNo)
                    return d;
                var m = new $root.streem.api.ModelNo();
                if (d.value != null) {
                    m.value = String(d.value);
                }
                if (d.confidence != null) {
                    m.confidence = Number(d.confidence);
                }
                return m;
            };

            /**
             * Creates a plain object from a ModelNo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ModelNo
             * @static
             * @param {streem.api.ModelNo} m ModelNo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ModelNo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = "";
                    d.confidence = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                if (m.confidence != null && m.hasOwnProperty("confidence")) {
                    d.confidence = o.json && !isFinite(m.confidence) ? String(m.confidence) : m.confidence;
                }
                return d;
            };

            /**
             * Converts this ModelNo to JSON.
             * @function toJSON
             * @memberof streem.api.ModelNo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ModelNo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ModelNo
             * @function getTypeUrl
             * @memberof streem.api.ModelNo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ModelNo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ModelNo";
            };

            return ModelNo;
        })();

        api.SerialNo = (function() {

            /**
             * Properties of a SerialNo.
             * @memberof streem.api
             * @interface ISerialNo
             * @property {string|null} [value] SerialNo value
             * @property {number|null} [confidence] SerialNo confidence
             */

            /**
             * Constructs a new SerialNo.
             * @memberof streem.api
             * @classdesc Represents a SerialNo.
             * @implements ISerialNo
             * @constructor
             * @param {streem.api.ISerialNo=} [p] Properties to set
             */
            function SerialNo(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SerialNo value.
             * @member {string} value
             * @memberof streem.api.SerialNo
             * @instance
             */
            SerialNo.prototype.value = "";

            /**
             * SerialNo confidence.
             * @member {number} confidence
             * @memberof streem.api.SerialNo
             * @instance
             */
            SerialNo.prototype.confidence = 0;

            /**
             * Creates a new SerialNo instance using the specified properties.
             * @function create
             * @memberof streem.api.SerialNo
             * @static
             * @param {streem.api.ISerialNo=} [properties] Properties to set
             * @returns {streem.api.SerialNo} SerialNo instance
             */
            SerialNo.create = function create(properties) {
                return new SerialNo(properties);
            };

            /**
             * Encodes the specified SerialNo message. Does not implicitly {@link streem.api.SerialNo.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SerialNo
             * @static
             * @param {streem.api.ISerialNo} m SerialNo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SerialNo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).string(m.value);
                if (m.confidence != null && Object.hasOwnProperty.call(m, "confidence"))
                    w.uint32(21).float(m.confidence);
                return w;
            };

            /**
             * Decodes a SerialNo message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SerialNo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SerialNo} SerialNo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SerialNo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SerialNo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.string();
                            break;
                        }
                    case 2: {
                            m.confidence = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SerialNo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SerialNo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SerialNo} SerialNo
             */
            SerialNo.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SerialNo)
                    return d;
                var m = new $root.streem.api.SerialNo();
                if (d.value != null) {
                    m.value = String(d.value);
                }
                if (d.confidence != null) {
                    m.confidence = Number(d.confidence);
                }
                return m;
            };

            /**
             * Creates a plain object from a SerialNo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SerialNo
             * @static
             * @param {streem.api.SerialNo} m SerialNo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SerialNo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = "";
                    d.confidence = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                if (m.confidence != null && m.hasOwnProperty("confidence")) {
                    d.confidence = o.json && !isFinite(m.confidence) ? String(m.confidence) : m.confidence;
                }
                return d;
            };

            /**
             * Converts this SerialNo to JSON.
             * @function toJSON
             * @memberof streem.api.SerialNo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SerialNo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SerialNo
             * @function getTypeUrl
             * @memberof streem.api.SerialNo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SerialNo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SerialNo";
            };

            return SerialNo;
        })();

        api.AuthLogin = (function() {

            /**
             * Constructs a new AuthLogin service.
             * @memberof streem.api
             * @classdesc Represents an AuthLogin
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function AuthLogin(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (AuthLogin.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AuthLogin;

            /**
             * Creates new AuthLogin service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.AuthLogin
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {AuthLogin} RPC service. Useful where requests and/or responses are streamed.
             */
            AuthLogin.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.AuthLogin#streemAuthLogin}.
             * @memberof streem.api.AuthLogin
             * @typedef StreemAuthLoginCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.StreemAuthLoginResponse} [response] StreemAuthLoginResponse
             */

            /**
             * Calls StreemAuthLogin.
             * @function streemAuthLogin
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IStreemAuthLoginRequest} request StreemAuthLoginRequest message or plain object
             * @param {streem.api.AuthLogin.StreemAuthLoginCallback} callback Node-style callback called with the error, if any, and StreemAuthLoginResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthLogin.prototype.streemAuthLogin = function streemAuthLogin(request, callback) {
                return this.rpcCall(streemAuthLogin, $root.streem.api.StreemAuthLoginRequest, $root.streem.api.StreemAuthLoginResponse, request, callback);
            }, "name", { value: "StreemAuthLogin" });

            /**
             * Calls StreemAuthLogin.
             * @function streemAuthLogin
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IStreemAuthLoginRequest} request StreemAuthLoginRequest message or plain object
             * @returns {Promise<streem.api.StreemAuthLoginResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthLogin#initiatePasswordReset}.
             * @memberof streem.api.AuthLogin
             * @typedef InitiatePasswordResetCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls InitiatePasswordReset.
             * @function initiatePasswordReset
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IInitiatePasswordResetRequest} request InitiatePasswordResetRequest message or plain object
             * @param {streem.api.AuthLogin.InitiatePasswordResetCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthLogin.prototype.initiatePasswordReset = function initiatePasswordReset(request, callback) {
                return this.rpcCall(initiatePasswordReset, $root.streem.api.InitiatePasswordResetRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "InitiatePasswordReset" });

            /**
             * Calls InitiatePasswordReset.
             * @function initiatePasswordReset
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IInitiatePasswordResetRequest} request InitiatePasswordResetRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthLogin#confirmPasswordReset}.
             * @memberof streem.api.AuthLogin
             * @typedef ConfirmPasswordResetCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ConfirmPasswordReset.
             * @function confirmPasswordReset
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IConfirmPasswordResetRequest} request ConfirmPasswordResetRequest message or plain object
             * @param {streem.api.AuthLogin.ConfirmPasswordResetCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthLogin.prototype.confirmPasswordReset = function confirmPasswordReset(request, callback) {
                return this.rpcCall(confirmPasswordReset, $root.streem.api.ConfirmPasswordResetRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ConfirmPasswordReset" });

            /**
             * Calls ConfirmPasswordReset.
             * @function confirmPasswordReset
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IConfirmPasswordResetRequest} request ConfirmPasswordResetRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthLogin#confirmNewUser}.
             * @memberof streem.api.AuthLogin
             * @typedef ConfirmNewUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ConfirmNewUser.
             * @function confirmNewUser
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IConfirmNewUserRequest} request ConfirmNewUserRequest message or plain object
             * @param {streem.api.AuthLogin.ConfirmNewUserCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthLogin.prototype.confirmNewUser = function confirmNewUser(request, callback) {
                return this.rpcCall(confirmNewUser, $root.streem.api.ConfirmNewUserRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ConfirmNewUser" });

            /**
             * Calls ConfirmNewUser.
             * @function confirmNewUser
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IConfirmNewUserRequest} request ConfirmNewUserRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.AuthLogin#changePassword}.
             * @memberof streem.api.AuthLogin
             * @typedef ChangePasswordCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ChangePassword.
             * @function changePassword
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IChangePasswordRequest} request ChangePasswordRequest message or plain object
             * @param {streem.api.AuthLogin.ChangePasswordCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AuthLogin.prototype.changePassword = function changePassword(request, callback) {
                return this.rpcCall(changePassword, $root.streem.api.ChangePasswordRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ChangePassword" });

            /**
             * Calls ChangePassword.
             * @function changePassword
             * @memberof streem.api.AuthLogin
             * @instance
             * @param {streem.api.IChangePasswordRequest} request ChangePasswordRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return AuthLogin;
        })();

        api.StreemAuthLoginRequest = (function() {

            /**
             * Properties of a StreemAuthLoginRequest.
             * @memberof streem.api
             * @interface IStreemAuthLoginRequest
             * @property {string|null} [email] StreemAuthLoginRequest email
             * @property {string|null} [password] StreemAuthLoginRequest password
             * @property {string|null} [companyCode] StreemAuthLoginRequest companyCode
             */

            /**
             * Constructs a new StreemAuthLoginRequest.
             * @memberof streem.api
             * @classdesc Represents a StreemAuthLoginRequest.
             * @implements IStreemAuthLoginRequest
             * @constructor
             * @param {streem.api.IStreemAuthLoginRequest=} [p] Properties to set
             */
            function StreemAuthLoginRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemAuthLoginRequest email.
             * @member {string} email
             * @memberof streem.api.StreemAuthLoginRequest
             * @instance
             */
            StreemAuthLoginRequest.prototype.email = "";

            /**
             * StreemAuthLoginRequest password.
             * @member {string} password
             * @memberof streem.api.StreemAuthLoginRequest
             * @instance
             */
            StreemAuthLoginRequest.prototype.password = "";

            /**
             * StreemAuthLoginRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.StreemAuthLoginRequest
             * @instance
             */
            StreemAuthLoginRequest.prototype.companyCode = "";

            /**
             * Creates a new StreemAuthLoginRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {streem.api.IStreemAuthLoginRequest=} [properties] Properties to set
             * @returns {streem.api.StreemAuthLoginRequest} StreemAuthLoginRequest instance
             */
            StreemAuthLoginRequest.create = function create(properties) {
                return new StreemAuthLoginRequest(properties);
            };

            /**
             * Encodes the specified StreemAuthLoginRequest message. Does not implicitly {@link streem.api.StreemAuthLoginRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {streem.api.IStreemAuthLoginRequest} m StreemAuthLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemAuthLoginRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(10).string(m.email);
                if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                    w.uint32(18).string(m.password);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(26).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a StreemAuthLoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemAuthLoginRequest} StreemAuthLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemAuthLoginRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemAuthLoginRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.email = r.string();
                            break;
                        }
                    case 2: {
                            m.password = r.string();
                            break;
                        }
                    case 3: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemAuthLoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemAuthLoginRequest} StreemAuthLoginRequest
             */
            StreemAuthLoginRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemAuthLoginRequest)
                    return d;
                var m = new $root.streem.api.StreemAuthLoginRequest();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemAuthLoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {streem.api.StreemAuthLoginRequest} m StreemAuthLoginRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemAuthLoginRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.password = "";
                    d.companyCode = "";
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this StreemAuthLoginRequest to JSON.
             * @function toJSON
             * @memberof streem.api.StreemAuthLoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemAuthLoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemAuthLoginRequest
             * @function getTypeUrl
             * @memberof streem.api.StreemAuthLoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemAuthLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemAuthLoginRequest";
            };

            return StreemAuthLoginRequest;
        })();

        api.StreemAuthLoginResponse = (function() {

            /**
             * Properties of a StreemAuthLoginResponse.
             * @memberof streem.api
             * @interface IStreemAuthLoginResponse
             * @property {string|null} [idToken] StreemAuthLoginResponse idToken
             */

            /**
             * Constructs a new StreemAuthLoginResponse.
             * @memberof streem.api
             * @classdesc Represents a StreemAuthLoginResponse.
             * @implements IStreemAuthLoginResponse
             * @constructor
             * @param {streem.api.IStreemAuthLoginResponse=} [p] Properties to set
             */
            function StreemAuthLoginResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemAuthLoginResponse idToken.
             * @member {string} idToken
             * @memberof streem.api.StreemAuthLoginResponse
             * @instance
             */
            StreemAuthLoginResponse.prototype.idToken = "";

            /**
             * Creates a new StreemAuthLoginResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {streem.api.IStreemAuthLoginResponse=} [properties] Properties to set
             * @returns {streem.api.StreemAuthLoginResponse} StreemAuthLoginResponse instance
             */
            StreemAuthLoginResponse.create = function create(properties) {
                return new StreemAuthLoginResponse(properties);
            };

            /**
             * Encodes the specified StreemAuthLoginResponse message. Does not implicitly {@link streem.api.StreemAuthLoginResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {streem.api.IStreemAuthLoginResponse} m StreemAuthLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemAuthLoginResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.idToken != null && Object.hasOwnProperty.call(m, "idToken"))
                    w.uint32(10).string(m.idToken);
                return w;
            };

            /**
             * Decodes a StreemAuthLoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemAuthLoginResponse} StreemAuthLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemAuthLoginResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemAuthLoginResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.idToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemAuthLoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemAuthLoginResponse} StreemAuthLoginResponse
             */
            StreemAuthLoginResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemAuthLoginResponse)
                    return d;
                var m = new $root.streem.api.StreemAuthLoginResponse();
                if (d.idToken != null) {
                    m.idToken = String(d.idToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemAuthLoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {streem.api.StreemAuthLoginResponse} m StreemAuthLoginResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemAuthLoginResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.idToken = "";
                }
                if (m.idToken != null && m.hasOwnProperty("idToken")) {
                    d.idToken = m.idToken;
                }
                return d;
            };

            /**
             * Converts this StreemAuthLoginResponse to JSON.
             * @function toJSON
             * @memberof streem.api.StreemAuthLoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemAuthLoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemAuthLoginResponse
             * @function getTypeUrl
             * @memberof streem.api.StreemAuthLoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemAuthLoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemAuthLoginResponse";
            };

            return StreemAuthLoginResponse;
        })();

        api.InitiatePasswordResetRequest = (function() {

            /**
             * Properties of an InitiatePasswordResetRequest.
             * @memberof streem.api
             * @interface IInitiatePasswordResetRequest
             * @property {string|null} [email] InitiatePasswordResetRequest email
             * @property {string|null} [companyCode] InitiatePasswordResetRequest companyCode
             */

            /**
             * Constructs a new InitiatePasswordResetRequest.
             * @memberof streem.api
             * @classdesc Represents an InitiatePasswordResetRequest.
             * @implements IInitiatePasswordResetRequest
             * @constructor
             * @param {streem.api.IInitiatePasswordResetRequest=} [p] Properties to set
             */
            function InitiatePasswordResetRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * InitiatePasswordResetRequest email.
             * @member {string} email
             * @memberof streem.api.InitiatePasswordResetRequest
             * @instance
             */
            InitiatePasswordResetRequest.prototype.email = "";

            /**
             * InitiatePasswordResetRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.InitiatePasswordResetRequest
             * @instance
             */
            InitiatePasswordResetRequest.prototype.companyCode = "";

            /**
             * Creates a new InitiatePasswordResetRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {streem.api.IInitiatePasswordResetRequest=} [properties] Properties to set
             * @returns {streem.api.InitiatePasswordResetRequest} InitiatePasswordResetRequest instance
             */
            InitiatePasswordResetRequest.create = function create(properties) {
                return new InitiatePasswordResetRequest(properties);
            };

            /**
             * Encodes the specified InitiatePasswordResetRequest message. Does not implicitly {@link streem.api.InitiatePasswordResetRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {streem.api.IInitiatePasswordResetRequest} m InitiatePasswordResetRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InitiatePasswordResetRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(10).string(m.email);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                return w;
            };

            /**
             * Decodes an InitiatePasswordResetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.InitiatePasswordResetRequest} InitiatePasswordResetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InitiatePasswordResetRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.InitiatePasswordResetRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.email = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an InitiatePasswordResetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.InitiatePasswordResetRequest} InitiatePasswordResetRequest
             */
            InitiatePasswordResetRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.InitiatePasswordResetRequest)
                    return d;
                var m = new $root.streem.api.InitiatePasswordResetRequest();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from an InitiatePasswordResetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {streem.api.InitiatePasswordResetRequest} m InitiatePasswordResetRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InitiatePasswordResetRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.companyCode = "";
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this InitiatePasswordResetRequest to JSON.
             * @function toJSON
             * @memberof streem.api.InitiatePasswordResetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InitiatePasswordResetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for InitiatePasswordResetRequest
             * @function getTypeUrl
             * @memberof streem.api.InitiatePasswordResetRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InitiatePasswordResetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.InitiatePasswordResetRequest";
            };

            return InitiatePasswordResetRequest;
        })();

        api.ConfirmPasswordResetRequest = (function() {

            /**
             * Properties of a ConfirmPasswordResetRequest.
             * @memberof streem.api
             * @interface IConfirmPasswordResetRequest
             * @property {string|null} [resetCode] ConfirmPasswordResetRequest resetCode
             * @property {string|null} [email] ConfirmPasswordResetRequest email
             * @property {string|null} [password] ConfirmPasswordResetRequest password
             * @property {string|null} [companyCode] ConfirmPasswordResetRequest companyCode
             * @property {string|null} [externalUser] ConfirmPasswordResetRequest externalUser
             */

            /**
             * Constructs a new ConfirmPasswordResetRequest.
             * @memberof streem.api
             * @classdesc Represents a ConfirmPasswordResetRequest.
             * @implements IConfirmPasswordResetRequest
             * @constructor
             * @param {streem.api.IConfirmPasswordResetRequest=} [p] Properties to set
             */
            function ConfirmPasswordResetRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ConfirmPasswordResetRequest resetCode.
             * @member {string} resetCode
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             */
            ConfirmPasswordResetRequest.prototype.resetCode = "";

            /**
             * ConfirmPasswordResetRequest email.
             * @member {string} email
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             */
            ConfirmPasswordResetRequest.prototype.email = "";

            /**
             * ConfirmPasswordResetRequest password.
             * @member {string} password
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             */
            ConfirmPasswordResetRequest.prototype.password = "";

            /**
             * ConfirmPasswordResetRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             */
            ConfirmPasswordResetRequest.prototype.companyCode = "";

            /**
             * ConfirmPasswordResetRequest externalUser.
             * @member {string} externalUser
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             */
            ConfirmPasswordResetRequest.prototype.externalUser = "";

            /**
             * Creates a new ConfirmPasswordResetRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {streem.api.IConfirmPasswordResetRequest=} [properties] Properties to set
             * @returns {streem.api.ConfirmPasswordResetRequest} ConfirmPasswordResetRequest instance
             */
            ConfirmPasswordResetRequest.create = function create(properties) {
                return new ConfirmPasswordResetRequest(properties);
            };

            /**
             * Encodes the specified ConfirmPasswordResetRequest message. Does not implicitly {@link streem.api.ConfirmPasswordResetRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {streem.api.IConfirmPasswordResetRequest} m ConfirmPasswordResetRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmPasswordResetRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.resetCode != null && Object.hasOwnProperty.call(m, "resetCode"))
                    w.uint32(10).string(m.resetCode);
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(18).string(m.email);
                if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                    w.uint32(26).string(m.password);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(34).string(m.companyCode);
                if (m.externalUser != null && Object.hasOwnProperty.call(m, "externalUser"))
                    w.uint32(42).string(m.externalUser);
                return w;
            };

            /**
             * Decodes a ConfirmPasswordResetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ConfirmPasswordResetRequest} ConfirmPasswordResetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmPasswordResetRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ConfirmPasswordResetRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.resetCode = r.string();
                            break;
                        }
                    case 2: {
                            m.email = r.string();
                            break;
                        }
                    case 3: {
                            m.password = r.string();
                            break;
                        }
                    case 4: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 5: {
                            m.externalUser = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ConfirmPasswordResetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ConfirmPasswordResetRequest} ConfirmPasswordResetRequest
             */
            ConfirmPasswordResetRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ConfirmPasswordResetRequest)
                    return d;
                var m = new $root.streem.api.ConfirmPasswordResetRequest();
                if (d.resetCode != null) {
                    m.resetCode = String(d.resetCode);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.externalUser != null) {
                    m.externalUser = String(d.externalUser);
                }
                return m;
            };

            /**
             * Creates a plain object from a ConfirmPasswordResetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {streem.api.ConfirmPasswordResetRequest} m ConfirmPasswordResetRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmPasswordResetRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.resetCode = "";
                    d.email = "";
                    d.password = "";
                    d.companyCode = "";
                    d.externalUser = "";
                }
                if (m.resetCode != null && m.hasOwnProperty("resetCode")) {
                    d.resetCode = m.resetCode;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.externalUser != null && m.hasOwnProperty("externalUser")) {
                    d.externalUser = m.externalUser;
                }
                return d;
            };

            /**
             * Converts this ConfirmPasswordResetRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmPasswordResetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConfirmPasswordResetRequest
             * @function getTypeUrl
             * @memberof streem.api.ConfirmPasswordResetRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfirmPasswordResetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ConfirmPasswordResetRequest";
            };

            return ConfirmPasswordResetRequest;
        })();

        api.ConfirmNewUserRequest = (function() {

            /**
             * Properties of a ConfirmNewUserRequest.
             * @memberof streem.api
             * @interface IConfirmNewUserRequest
             * @property {string|null} [temporaryPassword] ConfirmNewUserRequest temporaryPassword
             * @property {string|null} [password] ConfirmNewUserRequest password
             * @property {string|null} [companyCode] ConfirmNewUserRequest companyCode
             * @property {string|null} [externalUser] ConfirmNewUserRequest externalUser
             * @property {string|null} [session] ConfirmNewUserRequest session
             * @property {string|null} [userId] ConfirmNewUserRequest userId
             */

            /**
             * Constructs a new ConfirmNewUserRequest.
             * @memberof streem.api
             * @classdesc Represents a ConfirmNewUserRequest.
             * @implements IConfirmNewUserRequest
             * @constructor
             * @param {streem.api.IConfirmNewUserRequest=} [p] Properties to set
             */
            function ConfirmNewUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ConfirmNewUserRequest temporaryPassword.
             * @member {string} temporaryPassword
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.temporaryPassword = "";

            /**
             * ConfirmNewUserRequest password.
             * @member {string} password
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.password = "";

            /**
             * ConfirmNewUserRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.companyCode = "";

            /**
             * ConfirmNewUserRequest externalUser.
             * @member {string} externalUser
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.externalUser = "";

            /**
             * ConfirmNewUserRequest session.
             * @member {string} session
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.session = "";

            /**
             * ConfirmNewUserRequest userId.
             * @member {string} userId
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             */
            ConfirmNewUserRequest.prototype.userId = "";

            /**
             * Creates a new ConfirmNewUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {streem.api.IConfirmNewUserRequest=} [properties] Properties to set
             * @returns {streem.api.ConfirmNewUserRequest} ConfirmNewUserRequest instance
             */
            ConfirmNewUserRequest.create = function create(properties) {
                return new ConfirmNewUserRequest(properties);
            };

            /**
             * Encodes the specified ConfirmNewUserRequest message. Does not implicitly {@link streem.api.ConfirmNewUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {streem.api.IConfirmNewUserRequest} m ConfirmNewUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmNewUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.temporaryPassword != null && Object.hasOwnProperty.call(m, "temporaryPassword"))
                    w.uint32(10).string(m.temporaryPassword);
                if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                    w.uint32(18).string(m.password);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(26).string(m.companyCode);
                if (m.externalUser != null && Object.hasOwnProperty.call(m, "externalUser"))
                    w.uint32(34).string(m.externalUser);
                if (m.session != null && Object.hasOwnProperty.call(m, "session"))
                    w.uint32(42).string(m.session);
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(50).string(m.userId);
                return w;
            };

            /**
             * Decodes a ConfirmNewUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ConfirmNewUserRequest} ConfirmNewUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmNewUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ConfirmNewUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.temporaryPassword = r.string();
                            break;
                        }
                    case 2: {
                            m.password = r.string();
                            break;
                        }
                    case 3: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 4: {
                            m.externalUser = r.string();
                            break;
                        }
                    case 5: {
                            m.session = r.string();
                            break;
                        }
                    case 6: {
                            m.userId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ConfirmNewUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ConfirmNewUserRequest} ConfirmNewUserRequest
             */
            ConfirmNewUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ConfirmNewUserRequest)
                    return d;
                var m = new $root.streem.api.ConfirmNewUserRequest();
                if (d.temporaryPassword != null) {
                    m.temporaryPassword = String(d.temporaryPassword);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.externalUser != null) {
                    m.externalUser = String(d.externalUser);
                }
                if (d.session != null) {
                    m.session = String(d.session);
                }
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                return m;
            };

            /**
             * Creates a plain object from a ConfirmNewUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {streem.api.ConfirmNewUserRequest} m ConfirmNewUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmNewUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.temporaryPassword = "";
                    d.password = "";
                    d.companyCode = "";
                    d.externalUser = "";
                    d.session = "";
                    d.userId = "";
                }
                if (m.temporaryPassword != null && m.hasOwnProperty("temporaryPassword")) {
                    d.temporaryPassword = m.temporaryPassword;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.externalUser != null && m.hasOwnProperty("externalUser")) {
                    d.externalUser = m.externalUser;
                }
                if (m.session != null && m.hasOwnProperty("session")) {
                    d.session = m.session;
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                return d;
            };

            /**
             * Converts this ConfirmNewUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ConfirmNewUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmNewUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConfirmNewUserRequest
             * @function getTypeUrl
             * @memberof streem.api.ConfirmNewUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfirmNewUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ConfirmNewUserRequest";
            };

            return ConfirmNewUserRequest;
        })();

        api.NewPasswordRequiredResponse = (function() {

            /**
             * Properties of a NewPasswordRequiredResponse.
             * @memberof streem.api
             * @interface INewPasswordRequiredResponse
             * @property {string|null} [code] NewPasswordRequiredResponse code
             * @property {string|null} [session] NewPasswordRequiredResponse session
             */

            /**
             * Constructs a new NewPasswordRequiredResponse.
             * @memberof streem.api
             * @classdesc Represents a NewPasswordRequiredResponse.
             * @implements INewPasswordRequiredResponse
             * @constructor
             * @param {streem.api.INewPasswordRequiredResponse=} [p] Properties to set
             */
            function NewPasswordRequiredResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * NewPasswordRequiredResponse code.
             * @member {string} code
             * @memberof streem.api.NewPasswordRequiredResponse
             * @instance
             */
            NewPasswordRequiredResponse.prototype.code = "";

            /**
             * NewPasswordRequiredResponse session.
             * @member {string} session
             * @memberof streem.api.NewPasswordRequiredResponse
             * @instance
             */
            NewPasswordRequiredResponse.prototype.session = "";

            /**
             * Creates a new NewPasswordRequiredResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {streem.api.INewPasswordRequiredResponse=} [properties] Properties to set
             * @returns {streem.api.NewPasswordRequiredResponse} NewPasswordRequiredResponse instance
             */
            NewPasswordRequiredResponse.create = function create(properties) {
                return new NewPasswordRequiredResponse(properties);
            };

            /**
             * Encodes the specified NewPasswordRequiredResponse message. Does not implicitly {@link streem.api.NewPasswordRequiredResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {streem.api.INewPasswordRequiredResponse} m NewPasswordRequiredResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewPasswordRequiredResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(10).string(m.code);
                if (m.session != null && Object.hasOwnProperty.call(m, "session"))
                    w.uint32(18).string(m.session);
                return w;
            };

            /**
             * Decodes a NewPasswordRequiredResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.NewPasswordRequiredResponse} NewPasswordRequiredResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewPasswordRequiredResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.NewPasswordRequiredResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.code = r.string();
                            break;
                        }
                    case 2: {
                            m.session = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a NewPasswordRequiredResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.NewPasswordRequiredResponse} NewPasswordRequiredResponse
             */
            NewPasswordRequiredResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.NewPasswordRequiredResponse)
                    return d;
                var m = new $root.streem.api.NewPasswordRequiredResponse();
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.session != null) {
                    m.session = String(d.session);
                }
                return m;
            };

            /**
             * Creates a plain object from a NewPasswordRequiredResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {streem.api.NewPasswordRequiredResponse} m NewPasswordRequiredResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewPasswordRequiredResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.code = "";
                    d.session = "";
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.session != null && m.hasOwnProperty("session")) {
                    d.session = m.session;
                }
                return d;
            };

            /**
             * Converts this NewPasswordRequiredResponse to JSON.
             * @function toJSON
             * @memberof streem.api.NewPasswordRequiredResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewPasswordRequiredResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewPasswordRequiredResponse
             * @function getTypeUrl
             * @memberof streem.api.NewPasswordRequiredResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewPasswordRequiredResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.NewPasswordRequiredResponse";
            };

            return NewPasswordRequiredResponse;
        })();

        api.ChangePasswordRequest = (function() {

            /**
             * Properties of a ChangePasswordRequest.
             * @memberof streem.api
             * @interface IChangePasswordRequest
             * @property {string|null} [userSid] ChangePasswordRequest userSid
             * @property {string|null} [password] ChangePasswordRequest password
             */

            /**
             * Constructs a new ChangePasswordRequest.
             * @memberof streem.api
             * @classdesc Represents a ChangePasswordRequest.
             * @implements IChangePasswordRequest
             * @constructor
             * @param {streem.api.IChangePasswordRequest=} [p] Properties to set
             */
            function ChangePasswordRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ChangePasswordRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.ChangePasswordRequest
             * @instance
             */
            ChangePasswordRequest.prototype.userSid = "";

            /**
             * ChangePasswordRequest password.
             * @member {string} password
             * @memberof streem.api.ChangePasswordRequest
             * @instance
             */
            ChangePasswordRequest.prototype.password = "";

            /**
             * Creates a new ChangePasswordRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {streem.api.IChangePasswordRequest=} [properties] Properties to set
             * @returns {streem.api.ChangePasswordRequest} ChangePasswordRequest instance
             */
            ChangePasswordRequest.create = function create(properties) {
                return new ChangePasswordRequest(properties);
            };

            /**
             * Encodes the specified ChangePasswordRequest message. Does not implicitly {@link streem.api.ChangePasswordRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {streem.api.IChangePasswordRequest} m ChangePasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangePasswordRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                    w.uint32(18).string(m.password);
                return w;
            };

            /**
             * Decodes a ChangePasswordRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ChangePasswordRequest} ChangePasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangePasswordRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ChangePasswordRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.password = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ChangePasswordRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ChangePasswordRequest} ChangePasswordRequest
             */
            ChangePasswordRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ChangePasswordRequest)
                    return d;
                var m = new $root.streem.api.ChangePasswordRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                return m;
            };

            /**
             * Creates a plain object from a ChangePasswordRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {streem.api.ChangePasswordRequest} m ChangePasswordRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangePasswordRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.password = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                return d;
            };

            /**
             * Converts this ChangePasswordRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ChangePasswordRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangePasswordRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChangePasswordRequest
             * @function getTypeUrl
             * @memberof streem.api.ChangePasswordRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChangePasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ChangePasswordRequest";
            };

            return ChangePasswordRequest;
        })();

        api.FirstLoginRequest = (function() {

            /**
             * Properties of a FirstLoginRequest.
             * @memberof streem.api
             * @interface IFirstLoginRequest
             * @property {string|null} [temporaryPassword] FirstLoginRequest temporaryPassword
             * @property {string|null} [newPassword] FirstLoginRequest newPassword
             * @property {string|null} [externalUserId] FirstLoginRequest externalUserId
             * @property {string|null} [companyCode] FirstLoginRequest companyCode
             */

            /**
             * Constructs a new FirstLoginRequest.
             * @memberof streem.api
             * @classdesc Represents a FirstLoginRequest.
             * @implements IFirstLoginRequest
             * @constructor
             * @param {streem.api.IFirstLoginRequest=} [p] Properties to set
             */
            function FirstLoginRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FirstLoginRequest temporaryPassword.
             * @member {string} temporaryPassword
             * @memberof streem.api.FirstLoginRequest
             * @instance
             */
            FirstLoginRequest.prototype.temporaryPassword = "";

            /**
             * FirstLoginRequest newPassword.
             * @member {string} newPassword
             * @memberof streem.api.FirstLoginRequest
             * @instance
             */
            FirstLoginRequest.prototype.newPassword = "";

            /**
             * FirstLoginRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.FirstLoginRequest
             * @instance
             */
            FirstLoginRequest.prototype.externalUserId = "";

            /**
             * FirstLoginRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.FirstLoginRequest
             * @instance
             */
            FirstLoginRequest.prototype.companyCode = "";

            /**
             * Creates a new FirstLoginRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {streem.api.IFirstLoginRequest=} [properties] Properties to set
             * @returns {streem.api.FirstLoginRequest} FirstLoginRequest instance
             */
            FirstLoginRequest.create = function create(properties) {
                return new FirstLoginRequest(properties);
            };

            /**
             * Encodes the specified FirstLoginRequest message. Does not implicitly {@link streem.api.FirstLoginRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {streem.api.IFirstLoginRequest} m FirstLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FirstLoginRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.temporaryPassword != null && Object.hasOwnProperty.call(m, "temporaryPassword"))
                    w.uint32(10).string(m.temporaryPassword);
                if (m.newPassword != null && Object.hasOwnProperty.call(m, "newPassword"))
                    w.uint32(18).string(m.newPassword);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(26).string(m.externalUserId);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(34).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a FirstLoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FirstLoginRequest} FirstLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FirstLoginRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FirstLoginRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.temporaryPassword = r.string();
                            break;
                        }
                    case 2: {
                            m.newPassword = r.string();
                            break;
                        }
                    case 3: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 4: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FirstLoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FirstLoginRequest} FirstLoginRequest
             */
            FirstLoginRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FirstLoginRequest)
                    return d;
                var m = new $root.streem.api.FirstLoginRequest();
                if (d.temporaryPassword != null) {
                    m.temporaryPassword = String(d.temporaryPassword);
                }
                if (d.newPassword != null) {
                    m.newPassword = String(d.newPassword);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a FirstLoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {streem.api.FirstLoginRequest} m FirstLoginRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FirstLoginRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.temporaryPassword = "";
                    d.newPassword = "";
                    d.externalUserId = "";
                    d.companyCode = "";
                }
                if (m.temporaryPassword != null && m.hasOwnProperty("temporaryPassword")) {
                    d.temporaryPassword = m.temporaryPassword;
                }
                if (m.newPassword != null && m.hasOwnProperty("newPassword")) {
                    d.newPassword = m.newPassword;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this FirstLoginRequest to JSON.
             * @function toJSON
             * @memberof streem.api.FirstLoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FirstLoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FirstLoginRequest
             * @function getTypeUrl
             * @memberof streem.api.FirstLoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FirstLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FirstLoginRequest";
            };

            return FirstLoginRequest;
        })();

        api.FirstLoginResponse = (function() {

            /**
             * Properties of a FirstLoginResponse.
             * @memberof streem.api
             * @interface IFirstLoginResponse
             * @property {string|null} [idToken] FirstLoginResponse idToken
             */

            /**
             * Constructs a new FirstLoginResponse.
             * @memberof streem.api
             * @classdesc Represents a FirstLoginResponse.
             * @implements IFirstLoginResponse
             * @constructor
             * @param {streem.api.IFirstLoginResponse=} [p] Properties to set
             */
            function FirstLoginResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FirstLoginResponse idToken.
             * @member {string} idToken
             * @memberof streem.api.FirstLoginResponse
             * @instance
             */
            FirstLoginResponse.prototype.idToken = "";

            /**
             * Creates a new FirstLoginResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {streem.api.IFirstLoginResponse=} [properties] Properties to set
             * @returns {streem.api.FirstLoginResponse} FirstLoginResponse instance
             */
            FirstLoginResponse.create = function create(properties) {
                return new FirstLoginResponse(properties);
            };

            /**
             * Encodes the specified FirstLoginResponse message. Does not implicitly {@link streem.api.FirstLoginResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {streem.api.IFirstLoginResponse} m FirstLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FirstLoginResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.idToken != null && Object.hasOwnProperty.call(m, "idToken"))
                    w.uint32(10).string(m.idToken);
                return w;
            };

            /**
             * Decodes a FirstLoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.FirstLoginResponse} FirstLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FirstLoginResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.FirstLoginResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.idToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FirstLoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.FirstLoginResponse} FirstLoginResponse
             */
            FirstLoginResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.FirstLoginResponse)
                    return d;
                var m = new $root.streem.api.FirstLoginResponse();
                if (d.idToken != null) {
                    m.idToken = String(d.idToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a FirstLoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {streem.api.FirstLoginResponse} m FirstLoginResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FirstLoginResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.idToken = "";
                }
                if (m.idToken != null && m.hasOwnProperty("idToken")) {
                    d.idToken = m.idToken;
                }
                return d;
            };

            /**
             * Converts this FirstLoginResponse to JSON.
             * @function toJSON
             * @memberof streem.api.FirstLoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FirstLoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FirstLoginResponse
             * @function getTypeUrl
             * @memberof streem.api.FirstLoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FirstLoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.FirstLoginResponse";
            };

            return FirstLoginResponse;
        })();

        api.PhoneDetail = (function() {

            /**
             * Properties of a PhoneDetail.
             * @memberof streem.api
             * @interface IPhoneDetail
             * @property {string|null} [phoneNumber] PhoneDetail phoneNumber
             * @property {string|null} [callerName] PhoneDetail callerName
             * @property {string|null} [countryCode] PhoneDetail countryCode
             * @property {string|null} [nationalFormat] PhoneDetail nationalFormat
             * @property {string|null} [mobileCountryCode] PhoneDetail mobileCountryCode
             * @property {string|null} [mobileNetworkCode] PhoneDetail mobileNetworkCode
             * @property {string|null} [carrierName] PhoneDetail carrierName
             * @property {string|null} [phoneType] PhoneDetail phoneType
             * @property {string|null} [errorCode] PhoneDetail errorCode
             */

            /**
             * Constructs a new PhoneDetail.
             * @memberof streem.api
             * @classdesc Represents a PhoneDetail.
             * @implements IPhoneDetail
             * @constructor
             * @param {streem.api.IPhoneDetail=} [p] Properties to set
             */
            function PhoneDetail(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PhoneDetail phoneNumber.
             * @member {string} phoneNumber
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.phoneNumber = "";

            /**
             * PhoneDetail callerName.
             * @member {string} callerName
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.callerName = "";

            /**
             * PhoneDetail countryCode.
             * @member {string} countryCode
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.countryCode = "";

            /**
             * PhoneDetail nationalFormat.
             * @member {string} nationalFormat
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.nationalFormat = "";

            /**
             * PhoneDetail mobileCountryCode.
             * @member {string} mobileCountryCode
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.mobileCountryCode = "";

            /**
             * PhoneDetail mobileNetworkCode.
             * @member {string} mobileNetworkCode
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.mobileNetworkCode = "";

            /**
             * PhoneDetail carrierName.
             * @member {string} carrierName
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.carrierName = "";

            /**
             * PhoneDetail phoneType.
             * @member {string} phoneType
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.phoneType = "";

            /**
             * PhoneDetail errorCode.
             * @member {string} errorCode
             * @memberof streem.api.PhoneDetail
             * @instance
             */
            PhoneDetail.prototype.errorCode = "";

            /**
             * Creates a new PhoneDetail instance using the specified properties.
             * @function create
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {streem.api.IPhoneDetail=} [properties] Properties to set
             * @returns {streem.api.PhoneDetail} PhoneDetail instance
             */
            PhoneDetail.create = function create(properties) {
                return new PhoneDetail(properties);
            };

            /**
             * Encodes the specified PhoneDetail message. Does not implicitly {@link streem.api.PhoneDetail.verify|verify} messages.
             * @function encode
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {streem.api.IPhoneDetail} m PhoneDetail message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhoneDetail.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.phoneNumber != null && Object.hasOwnProperty.call(m, "phoneNumber"))
                    w.uint32(10).string(m.phoneNumber);
                if (m.callerName != null && Object.hasOwnProperty.call(m, "callerName"))
                    w.uint32(18).string(m.callerName);
                if (m.countryCode != null && Object.hasOwnProperty.call(m, "countryCode"))
                    w.uint32(26).string(m.countryCode);
                if (m.nationalFormat != null && Object.hasOwnProperty.call(m, "nationalFormat"))
                    w.uint32(34).string(m.nationalFormat);
                if (m.mobileCountryCode != null && Object.hasOwnProperty.call(m, "mobileCountryCode"))
                    w.uint32(42).string(m.mobileCountryCode);
                if (m.mobileNetworkCode != null && Object.hasOwnProperty.call(m, "mobileNetworkCode"))
                    w.uint32(50).string(m.mobileNetworkCode);
                if (m.carrierName != null && Object.hasOwnProperty.call(m, "carrierName"))
                    w.uint32(58).string(m.carrierName);
                if (m.phoneType != null && Object.hasOwnProperty.call(m, "phoneType"))
                    w.uint32(66).string(m.phoneType);
                if (m.errorCode != null && Object.hasOwnProperty.call(m, "errorCode"))
                    w.uint32(74).string(m.errorCode);
                return w;
            };

            /**
             * Decodes a PhoneDetail message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.PhoneDetail} PhoneDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhoneDetail.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.PhoneDetail();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.phoneNumber = r.string();
                            break;
                        }
                    case 2: {
                            m.callerName = r.string();
                            break;
                        }
                    case 3: {
                            m.countryCode = r.string();
                            break;
                        }
                    case 4: {
                            m.nationalFormat = r.string();
                            break;
                        }
                    case 5: {
                            m.mobileCountryCode = r.string();
                            break;
                        }
                    case 6: {
                            m.mobileNetworkCode = r.string();
                            break;
                        }
                    case 7: {
                            m.carrierName = r.string();
                            break;
                        }
                    case 8: {
                            m.phoneType = r.string();
                            break;
                        }
                    case 9: {
                            m.errorCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PhoneDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.PhoneDetail} PhoneDetail
             */
            PhoneDetail.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.PhoneDetail)
                    return d;
                var m = new $root.streem.api.PhoneDetail();
                if (d.phoneNumber != null) {
                    m.phoneNumber = String(d.phoneNumber);
                }
                if (d.callerName != null) {
                    m.callerName = String(d.callerName);
                }
                if (d.countryCode != null) {
                    m.countryCode = String(d.countryCode);
                }
                if (d.nationalFormat != null) {
                    m.nationalFormat = String(d.nationalFormat);
                }
                if (d.mobileCountryCode != null) {
                    m.mobileCountryCode = String(d.mobileCountryCode);
                }
                if (d.mobileNetworkCode != null) {
                    m.mobileNetworkCode = String(d.mobileNetworkCode);
                }
                if (d.carrierName != null) {
                    m.carrierName = String(d.carrierName);
                }
                if (d.phoneType != null) {
                    m.phoneType = String(d.phoneType);
                }
                if (d.errorCode != null) {
                    m.errorCode = String(d.errorCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a PhoneDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {streem.api.PhoneDetail} m PhoneDetail
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhoneDetail.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.phoneNumber = "";
                    d.callerName = "";
                    d.countryCode = "";
                    d.nationalFormat = "";
                    d.mobileCountryCode = "";
                    d.mobileNetworkCode = "";
                    d.carrierName = "";
                    d.phoneType = "";
                    d.errorCode = "";
                }
                if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                    d.phoneNumber = m.phoneNumber;
                }
                if (m.callerName != null && m.hasOwnProperty("callerName")) {
                    d.callerName = m.callerName;
                }
                if (m.countryCode != null && m.hasOwnProperty("countryCode")) {
                    d.countryCode = m.countryCode;
                }
                if (m.nationalFormat != null && m.hasOwnProperty("nationalFormat")) {
                    d.nationalFormat = m.nationalFormat;
                }
                if (m.mobileCountryCode != null && m.hasOwnProperty("mobileCountryCode")) {
                    d.mobileCountryCode = m.mobileCountryCode;
                }
                if (m.mobileNetworkCode != null && m.hasOwnProperty("mobileNetworkCode")) {
                    d.mobileNetworkCode = m.mobileNetworkCode;
                }
                if (m.carrierName != null && m.hasOwnProperty("carrierName")) {
                    d.carrierName = m.carrierName;
                }
                if (m.phoneType != null && m.hasOwnProperty("phoneType")) {
                    d.phoneType = m.phoneType;
                }
                if (m.errorCode != null && m.hasOwnProperty("errorCode")) {
                    d.errorCode = m.errorCode;
                }
                return d;
            };

            /**
             * Converts this PhoneDetail to JSON.
             * @function toJSON
             * @memberof streem.api.PhoneDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhoneDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PhoneDetail
             * @function getTypeUrl
             * @memberof streem.api.PhoneDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhoneDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.PhoneDetail";
            };

            return PhoneDetail;
        })();

        api.Invitation = (function() {

            /**
             * Properties of an Invitation.
             * @memberof streem.api
             * @interface IInvitation
             * @property {string|null} [invitationSid] Invitation invitationSid
             * @property {string|null} [companySid] Invitation companySid
             * @property {string|null} [fromUserSid] Invitation fromUserSid
             * @property {boolean|null} [consumed] Invitation consumed
             * @property {string|null} [code] Invitation code
             * @property {google.protobuf.ITimestamp|null} [expiresAt] Invitation expiresAt
             * @property {string|null} [toUserName] Invitation toUserName
             * @property {string|null} [toUserExternalUserId] Invitation toUserExternalUserId
             * @property {string|null} [referenceId] Invitation referenceId
             * @property {string|null} [roomSid] Invitation roomSid
             * @property {string|null} [integrationId] Invitation integrationId
             */

            /**
             * Constructs a new Invitation.
             * @memberof streem.api
             * @classdesc Represents an Invitation.
             * @implements IInvitation
             * @constructor
             * @param {streem.api.IInvitation=} [p] Properties to set
             */
            function Invitation(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Invitation invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.invitationSid = "";

            /**
             * Invitation companySid.
             * @member {string} companySid
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.companySid = "";

            /**
             * Invitation fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.fromUserSid = "";

            /**
             * Invitation consumed.
             * @member {boolean} consumed
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.consumed = false;

            /**
             * Invitation code.
             * @member {string} code
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.code = "";

            /**
             * Invitation expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.expiresAt = null;

            /**
             * Invitation toUserName.
             * @member {string} toUserName
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.toUserName = "";

            /**
             * Invitation toUserExternalUserId.
             * @member {string} toUserExternalUserId
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.toUserExternalUserId = "";

            /**
             * Invitation referenceId.
             * @member {string} referenceId
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.referenceId = "";

            /**
             * Invitation roomSid.
             * @member {string} roomSid
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.roomSid = "";

            /**
             * Invitation integrationId.
             * @member {string} integrationId
             * @memberof streem.api.Invitation
             * @instance
             */
            Invitation.prototype.integrationId = "";

            /**
             * Creates a new Invitation instance using the specified properties.
             * @function create
             * @memberof streem.api.Invitation
             * @static
             * @param {streem.api.IInvitation=} [properties] Properties to set
             * @returns {streem.api.Invitation} Invitation instance
             */
            Invitation.create = function create(properties) {
                return new Invitation(properties);
            };

            /**
             * Encodes the specified Invitation message. Does not implicitly {@link streem.api.Invitation.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Invitation
             * @static
             * @param {streem.api.IInvitation} m Invitation message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Invitation.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(10).string(m.invitationSid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(26).string(m.fromUserSid);
                if (m.consumed != null && Object.hasOwnProperty.call(m, "consumed"))
                    w.uint32(32).bool(m.consumed);
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(42).string(m.code);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(50).fork()).ldelim();
                if (m.toUserName != null && Object.hasOwnProperty.call(m, "toUserName"))
                    w.uint32(58).string(m.toUserName);
                if (m.toUserExternalUserId != null && Object.hasOwnProperty.call(m, "toUserExternalUserId"))
                    w.uint32(66).string(m.toUserExternalUserId);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(74).string(m.referenceId);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(82).string(m.roomSid);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(98).string(m.integrationId);
                return w;
            };

            /**
             * Decodes an Invitation message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Invitation
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Invitation} Invitation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Invitation.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Invitation();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitationSid = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 3: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 4: {
                            m.consumed = r.bool();
                            break;
                        }
                    case 5: {
                            m.code = r.string();
                            break;
                        }
                    case 6: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            m.toUserName = r.string();
                            break;
                        }
                    case 8: {
                            m.toUserExternalUserId = r.string();
                            break;
                        }
                    case 9: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 10: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 12: {
                            m.integrationId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Invitation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Invitation
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Invitation} Invitation
             */
            Invitation.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Invitation)
                    return d;
                var m = new $root.streem.api.Invitation();
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.consumed != null) {
                    m.consumed = Boolean(d.consumed);
                }
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.Invitation.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.toUserName != null) {
                    m.toUserName = String(d.toUserName);
                }
                if (d.toUserExternalUserId != null) {
                    m.toUserExternalUserId = String(d.toUserExternalUserId);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                return m;
            };

            /**
             * Creates a plain object from an Invitation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Invitation
             * @static
             * @param {streem.api.Invitation} m Invitation
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Invitation.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitationSid = "";
                    d.companySid = "";
                    d.fromUserSid = "";
                    d.consumed = false;
                    d.code = "";
                    d.expiresAt = null;
                    d.toUserName = "";
                    d.toUserExternalUserId = "";
                    d.referenceId = "";
                    d.roomSid = "";
                    d.integrationId = "";
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.consumed != null && m.hasOwnProperty("consumed")) {
                    d.consumed = m.consumed;
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.toUserName != null && m.hasOwnProperty("toUserName")) {
                    d.toUserName = m.toUserName;
                }
                if (m.toUserExternalUserId != null && m.hasOwnProperty("toUserExternalUserId")) {
                    d.toUserExternalUserId = m.toUserExternalUserId;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                return d;
            };

            /**
             * Converts this Invitation to JSON.
             * @function toJSON
             * @memberof streem.api.Invitation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Invitation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Invitation
             * @function getTypeUrl
             * @memberof streem.api.Invitation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Invitation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Invitation";
            };

            return Invitation;
        })();

        /**
         * DeliveryType enum.
         * @name streem.api.DeliveryType
         * @enum {number}
         * @property {number} DELIVERY_TYPE_INVALID=0 DELIVERY_TYPE_INVALID value
         * @property {number} DELIVERY_TYPE_SMS=1 DELIVERY_TYPE_SMS value
         * @property {number} DELIVERY_TYPE_EMAIL=2 DELIVERY_TYPE_EMAIL value
         * @property {number} DELIVERY_TYPE_LINK=3 DELIVERY_TYPE_LINK value
         */
        api.DeliveryType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DELIVERY_TYPE_INVALID"] = 0;
            values[valuesById[1] = "DELIVERY_TYPE_SMS"] = 1;
            values[valuesById[2] = "DELIVERY_TYPE_EMAIL"] = 2;
            values[valuesById[3] = "DELIVERY_TYPE_LINK"] = 3;
            return values;
        })();

        api.SmsDeliveryStatus = (function() {

            /**
             * Properties of a SmsDeliveryStatus.
             * @memberof streem.api
             * @interface ISmsDeliveryStatus
             * @property {string|null} [smsDeliveryStatusSid] SmsDeliveryStatus smsDeliveryStatusSid
             * @property {string|null} [messageId] SmsDeliveryStatus messageId
             * @property {string|null} [deliveryStatus] SmsDeliveryStatus deliveryStatus
             * @property {string|null} [invitationSid] SmsDeliveryStatus invitationSid
             * @property {string|null} [errorCode] SmsDeliveryStatus errorCode
             * @property {streem.api.SmsPlatform|null} [smsPlatform] SmsDeliveryStatus smsPlatform
             */

            /**
             * Constructs a new SmsDeliveryStatus.
             * @memberof streem.api
             * @classdesc Represents a SmsDeliveryStatus.
             * @implements ISmsDeliveryStatus
             * @constructor
             * @param {streem.api.ISmsDeliveryStatus=} [p] Properties to set
             */
            function SmsDeliveryStatus(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SmsDeliveryStatus smsDeliveryStatusSid.
             * @member {string} smsDeliveryStatusSid
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.smsDeliveryStatusSid = "";

            /**
             * SmsDeliveryStatus messageId.
             * @member {string} messageId
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.messageId = "";

            /**
             * SmsDeliveryStatus deliveryStatus.
             * @member {string} deliveryStatus
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.deliveryStatus = "";

            /**
             * SmsDeliveryStatus invitationSid.
             * @member {string} invitationSid
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.invitationSid = "";

            /**
             * SmsDeliveryStatus errorCode.
             * @member {string} errorCode
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.errorCode = "";

            /**
             * SmsDeliveryStatus smsPlatform.
             * @member {streem.api.SmsPlatform} smsPlatform
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             */
            SmsDeliveryStatus.prototype.smsPlatform = 0;

            /**
             * Creates a new SmsDeliveryStatus instance using the specified properties.
             * @function create
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {streem.api.ISmsDeliveryStatus=} [properties] Properties to set
             * @returns {streem.api.SmsDeliveryStatus} SmsDeliveryStatus instance
             */
            SmsDeliveryStatus.create = function create(properties) {
                return new SmsDeliveryStatus(properties);
            };

            /**
             * Encodes the specified SmsDeliveryStatus message. Does not implicitly {@link streem.api.SmsDeliveryStatus.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {streem.api.ISmsDeliveryStatus} m SmsDeliveryStatus message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SmsDeliveryStatus.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.smsDeliveryStatusSid != null && Object.hasOwnProperty.call(m, "smsDeliveryStatusSid"))
                    w.uint32(10).string(m.smsDeliveryStatusSid);
                if (m.messageId != null && Object.hasOwnProperty.call(m, "messageId"))
                    w.uint32(18).string(m.messageId);
                if (m.deliveryStatus != null && Object.hasOwnProperty.call(m, "deliveryStatus"))
                    w.uint32(26).string(m.deliveryStatus);
                if (m.invitationSid != null && Object.hasOwnProperty.call(m, "invitationSid"))
                    w.uint32(34).string(m.invitationSid);
                if (m.errorCode != null && Object.hasOwnProperty.call(m, "errorCode"))
                    w.uint32(42).string(m.errorCode);
                if (m.smsPlatform != null && Object.hasOwnProperty.call(m, "smsPlatform"))
                    w.uint32(48).int32(m.smsPlatform);
                return w;
            };

            /**
             * Decodes a SmsDeliveryStatus message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SmsDeliveryStatus} SmsDeliveryStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SmsDeliveryStatus.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SmsDeliveryStatus();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.smsDeliveryStatusSid = r.string();
                            break;
                        }
                    case 2: {
                            m.messageId = r.string();
                            break;
                        }
                    case 3: {
                            m.deliveryStatus = r.string();
                            break;
                        }
                    case 4: {
                            m.invitationSid = r.string();
                            break;
                        }
                    case 5: {
                            m.errorCode = r.string();
                            break;
                        }
                    case 6: {
                            m.smsPlatform = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SmsDeliveryStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SmsDeliveryStatus} SmsDeliveryStatus
             */
            SmsDeliveryStatus.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SmsDeliveryStatus)
                    return d;
                var m = new $root.streem.api.SmsDeliveryStatus();
                if (d.smsDeliveryStatusSid != null) {
                    m.smsDeliveryStatusSid = String(d.smsDeliveryStatusSid);
                }
                if (d.messageId != null) {
                    m.messageId = String(d.messageId);
                }
                if (d.deliveryStatus != null) {
                    m.deliveryStatus = String(d.deliveryStatus);
                }
                if (d.invitationSid != null) {
                    m.invitationSid = String(d.invitationSid);
                }
                if (d.errorCode != null) {
                    m.errorCode = String(d.errorCode);
                }
                switch (d.smsPlatform) {
                default:
                    if (typeof d.smsPlatform === "number") {
                        m.smsPlatform = d.smsPlatform;
                        break;
                    }
                    break;
                case "SMS_PLATFORM_INVALID":
                case 0:
                    m.smsPlatform = 0;
                    break;
                case "SMS_PLATFORM_TWILIO":
                case 1:
                    m.smsPlatform = 1;
                    break;
                case "SMS_PLATFORM_TELNYX":
                case 2:
                    m.smsPlatform = 2;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a SmsDeliveryStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {streem.api.SmsDeliveryStatus} m SmsDeliveryStatus
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SmsDeliveryStatus.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.smsDeliveryStatusSid = "";
                    d.messageId = "";
                    d.deliveryStatus = "";
                    d.invitationSid = "";
                    d.errorCode = "";
                    d.smsPlatform = o.enums === String ? "SMS_PLATFORM_INVALID" : 0;
                }
                if (m.smsDeliveryStatusSid != null && m.hasOwnProperty("smsDeliveryStatusSid")) {
                    d.smsDeliveryStatusSid = m.smsDeliveryStatusSid;
                }
                if (m.messageId != null && m.hasOwnProperty("messageId")) {
                    d.messageId = m.messageId;
                }
                if (m.deliveryStatus != null && m.hasOwnProperty("deliveryStatus")) {
                    d.deliveryStatus = m.deliveryStatus;
                }
                if (m.invitationSid != null && m.hasOwnProperty("invitationSid")) {
                    d.invitationSid = m.invitationSid;
                }
                if (m.errorCode != null && m.hasOwnProperty("errorCode")) {
                    d.errorCode = m.errorCode;
                }
                if (m.smsPlatform != null && m.hasOwnProperty("smsPlatform")) {
                    d.smsPlatform = o.enums === String ? $root.streem.api.SmsPlatform[m.smsPlatform] === undefined ? m.smsPlatform : $root.streem.api.SmsPlatform[m.smsPlatform] : m.smsPlatform;
                }
                return d;
            };

            /**
             * Converts this SmsDeliveryStatus to JSON.
             * @function toJSON
             * @memberof streem.api.SmsDeliveryStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SmsDeliveryStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SmsDeliveryStatus
             * @function getTypeUrl
             * @memberof streem.api.SmsDeliveryStatus
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SmsDeliveryStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SmsDeliveryStatus";
            };

            return SmsDeliveryStatus;
        })();

        api.LoginIdentities = (function() {

            /**
             * Constructs a new LoginIdentities service.
             * @memberof streem.api
             * @classdesc Represents a LoginIdentities
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function LoginIdentities(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (LoginIdentities.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LoginIdentities;

            /**
             * Creates new LoginIdentities service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.LoginIdentities
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {LoginIdentities} RPC service. Useful where requests and/or responses are streamed.
             */
            LoginIdentities.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.LoginIdentities#listCompaniesForLoginIdentity}.
             * @memberof streem.api.LoginIdentities
             * @typedef ListCompaniesForLoginIdentityCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompaniesForLoginIdentityResponse} [response] ListCompaniesForLoginIdentityResponse
             */

            /**
             * Calls ListCompaniesForLoginIdentity.
             * @function listCompaniesForLoginIdentity
             * @memberof streem.api.LoginIdentities
             * @instance
             * @param {streem.api.IListCompaniesForLoginIdentityRequest} request ListCompaniesForLoginIdentityRequest message or plain object
             * @param {streem.api.LoginIdentities.ListCompaniesForLoginIdentityCallback} callback Node-style callback called with the error, if any, and ListCompaniesForLoginIdentityResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(LoginIdentities.prototype.listCompaniesForLoginIdentity = function listCompaniesForLoginIdentity(request, callback) {
                return this.rpcCall(listCompaniesForLoginIdentity, $root.streem.api.ListCompaniesForLoginIdentityRequest, $root.streem.api.ListCompaniesForLoginIdentityResponse, request, callback);
            }, "name", { value: "ListCompaniesForLoginIdentity" });

            /**
             * Calls ListCompaniesForLoginIdentity.
             * @function listCompaniesForLoginIdentity
             * @memberof streem.api.LoginIdentities
             * @instance
             * @param {streem.api.IListCompaniesForLoginIdentityRequest} request ListCompaniesForLoginIdentityRequest message or plain object
             * @returns {Promise<streem.api.ListCompaniesForLoginIdentityResponse>} Promise
             * @variation 2
             */

            return LoginIdentities;
        })();

        api.ListCompaniesForLoginIdentityRequest = (function() {

            /**
             * Properties of a ListCompaniesForLoginIdentityRequest.
             * @memberof streem.api
             * @interface IListCompaniesForLoginIdentityRequest
             * @property {string|null} [loginIdentitySid] ListCompaniesForLoginIdentityRequest loginIdentitySid
             */

            /**
             * Constructs a new ListCompaniesForLoginIdentityRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompaniesForLoginIdentityRequest.
             * @implements IListCompaniesForLoginIdentityRequest
             * @constructor
             * @param {streem.api.IListCompaniesForLoginIdentityRequest=} [p] Properties to set
             */
            function ListCompaniesForLoginIdentityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompaniesForLoginIdentityRequest loginIdentitySid.
             * @member {string} loginIdentitySid
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @instance
             */
            ListCompaniesForLoginIdentityRequest.prototype.loginIdentitySid = "";

            /**
             * Creates a new ListCompaniesForLoginIdentityRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {streem.api.IListCompaniesForLoginIdentityRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompaniesForLoginIdentityRequest} ListCompaniesForLoginIdentityRequest instance
             */
            ListCompaniesForLoginIdentityRequest.create = function create(properties) {
                return new ListCompaniesForLoginIdentityRequest(properties);
            };

            /**
             * Encodes the specified ListCompaniesForLoginIdentityRequest message. Does not implicitly {@link streem.api.ListCompaniesForLoginIdentityRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {streem.api.IListCompaniesForLoginIdentityRequest} m ListCompaniesForLoginIdentityRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompaniesForLoginIdentityRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.loginIdentitySid != null && Object.hasOwnProperty.call(m, "loginIdentitySid"))
                    w.uint32(10).string(m.loginIdentitySid);
                return w;
            };

            /**
             * Decodes a ListCompaniesForLoginIdentityRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompaniesForLoginIdentityRequest} ListCompaniesForLoginIdentityRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompaniesForLoginIdentityRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompaniesForLoginIdentityRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.loginIdentitySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompaniesForLoginIdentityRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompaniesForLoginIdentityRequest} ListCompaniesForLoginIdentityRequest
             */
            ListCompaniesForLoginIdentityRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompaniesForLoginIdentityRequest)
                    return d;
                var m = new $root.streem.api.ListCompaniesForLoginIdentityRequest();
                if (d.loginIdentitySid != null) {
                    m.loginIdentitySid = String(d.loginIdentitySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompaniesForLoginIdentityRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {streem.api.ListCompaniesForLoginIdentityRequest} m ListCompaniesForLoginIdentityRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompaniesForLoginIdentityRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.loginIdentitySid = "";
                }
                if (m.loginIdentitySid != null && m.hasOwnProperty("loginIdentitySid")) {
                    d.loginIdentitySid = m.loginIdentitySid;
                }
                return d;
            };

            /**
             * Converts this ListCompaniesForLoginIdentityRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompaniesForLoginIdentityRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompaniesForLoginIdentityRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompaniesForLoginIdentityRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompaniesForLoginIdentityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompaniesForLoginIdentityRequest";
            };

            return ListCompaniesForLoginIdentityRequest;
        })();

        api.ListCompaniesForLoginIdentityResponse = (function() {

            /**
             * Properties of a ListCompaniesForLoginIdentityResponse.
             * @memberof streem.api
             * @interface IListCompaniesForLoginIdentityResponse
             * @property {Array.<streem.api.ICompany>|null} [companies] ListCompaniesForLoginIdentityResponse companies
             */

            /**
             * Constructs a new ListCompaniesForLoginIdentityResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompaniesForLoginIdentityResponse.
             * @implements IListCompaniesForLoginIdentityResponse
             * @constructor
             * @param {streem.api.IListCompaniesForLoginIdentityResponse=} [p] Properties to set
             */
            function ListCompaniesForLoginIdentityResponse(p) {
                this.companies = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompaniesForLoginIdentityResponse companies.
             * @member {Array.<streem.api.ICompany>} companies
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @instance
             */
            ListCompaniesForLoginIdentityResponse.prototype.companies = $util.emptyArray;

            /**
             * Creates a new ListCompaniesForLoginIdentityResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {streem.api.IListCompaniesForLoginIdentityResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompaniesForLoginIdentityResponse} ListCompaniesForLoginIdentityResponse instance
             */
            ListCompaniesForLoginIdentityResponse.create = function create(properties) {
                return new ListCompaniesForLoginIdentityResponse(properties);
            };

            /**
             * Encodes the specified ListCompaniesForLoginIdentityResponse message. Does not implicitly {@link streem.api.ListCompaniesForLoginIdentityResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {streem.api.IListCompaniesForLoginIdentityResponse} m ListCompaniesForLoginIdentityResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompaniesForLoginIdentityResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companies != null && m.companies.length) {
                    for (var i = 0; i < m.companies.length; ++i)
                        $root.streem.api.Company.encode(m.companies[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListCompaniesForLoginIdentityResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompaniesForLoginIdentityResponse} ListCompaniesForLoginIdentityResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompaniesForLoginIdentityResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompaniesForLoginIdentityResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.companies && m.companies.length))
                                m.companies = [];
                            m.companies.push($root.streem.api.Company.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompaniesForLoginIdentityResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompaniesForLoginIdentityResponse} ListCompaniesForLoginIdentityResponse
             */
            ListCompaniesForLoginIdentityResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompaniesForLoginIdentityResponse)
                    return d;
                var m = new $root.streem.api.ListCompaniesForLoginIdentityResponse();
                if (d.companies) {
                    if (!Array.isArray(d.companies))
                        throw TypeError(".streem.api.ListCompaniesForLoginIdentityResponse.companies: array expected");
                    m.companies = [];
                    for (var i = 0; i < d.companies.length; ++i) {
                        if (typeof d.companies[i] !== "object")
                            throw TypeError(".streem.api.ListCompaniesForLoginIdentityResponse.companies: object expected");
                        m.companies[i] = $root.streem.api.Company.fromObject(d.companies[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompaniesForLoginIdentityResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {streem.api.ListCompaniesForLoginIdentityResponse} m ListCompaniesForLoginIdentityResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompaniesForLoginIdentityResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.companies = [];
                }
                if (m.companies && m.companies.length) {
                    d.companies = [];
                    for (var j = 0; j < m.companies.length; ++j) {
                        d.companies[j] = $root.streem.api.Company.toObject(m.companies[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListCompaniesForLoginIdentityResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompaniesForLoginIdentityResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompaniesForLoginIdentityResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompaniesForLoginIdentityResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompaniesForLoginIdentityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompaniesForLoginIdentityResponse";
            };

            return ListCompaniesForLoginIdentityResponse;
        })();

        api.License = (function() {

            /**
             * Properties of a License.
             * @memberof streem.api
             * @interface ILicense
             * @property {string|null} [sid] License sid
             * @property {string|null} [name] License name
             * @property {number|null} [tier] License tier
             * @property {number|null} [version] License version
             * @property {Array.<streem.api.ISetting>|null} [settings] License settings
             */

            /**
             * Constructs a new License.
             * @memberof streem.api
             * @classdesc Represents a License.
             * @implements ILicense
             * @constructor
             * @param {streem.api.ILicense=} [p] Properties to set
             */
            function License(p) {
                this.settings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * License sid.
             * @member {string} sid
             * @memberof streem.api.License
             * @instance
             */
            License.prototype.sid = "";

            /**
             * License name.
             * @member {string} name
             * @memberof streem.api.License
             * @instance
             */
            License.prototype.name = "";

            /**
             * License tier.
             * @member {number} tier
             * @memberof streem.api.License
             * @instance
             */
            License.prototype.tier = 0;

            /**
             * License version.
             * @member {number} version
             * @memberof streem.api.License
             * @instance
             */
            License.prototype.version = 0;

            /**
             * License settings.
             * @member {Array.<streem.api.ISetting>} settings
             * @memberof streem.api.License
             * @instance
             */
            License.prototype.settings = $util.emptyArray;

            /**
             * Creates a new License instance using the specified properties.
             * @function create
             * @memberof streem.api.License
             * @static
             * @param {streem.api.ILicense=} [properties] Properties to set
             * @returns {streem.api.License} License instance
             */
            License.create = function create(properties) {
                return new License(properties);
            };

            /**
             * Encodes the specified License message. Does not implicitly {@link streem.api.License.verify|verify} messages.
             * @function encode
             * @memberof streem.api.License
             * @static
             * @param {streem.api.ILicense} m License message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            License.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.tier != null && Object.hasOwnProperty.call(m, "tier"))
                    w.uint32(24).int32(m.tier);
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(32).int32(m.version);
                if (m.settings != null && m.settings.length) {
                    for (var i = 0; i < m.settings.length; ++i)
                        $root.streem.api.Setting.encode(m.settings[i], w.uint32(42).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a License message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.License
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.License} License
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            License.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.License();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.tier = r.int32();
                            break;
                        }
                    case 4: {
                            m.version = r.int32();
                            break;
                        }
                    case 5: {
                            if (!(m.settings && m.settings.length))
                                m.settings = [];
                            m.settings.push($root.streem.api.Setting.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a License message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.License
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.License} License
             */
            License.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.License)
                    return d;
                var m = new $root.streem.api.License();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.tier != null) {
                    m.tier = d.tier | 0;
                }
                if (d.version != null) {
                    m.version = d.version | 0;
                }
                if (d.settings) {
                    if (!Array.isArray(d.settings))
                        throw TypeError(".streem.api.License.settings: array expected");
                    m.settings = [];
                    for (var i = 0; i < d.settings.length; ++i) {
                        if (typeof d.settings[i] !== "object")
                            throw TypeError(".streem.api.License.settings: object expected");
                        m.settings[i] = $root.streem.api.Setting.fromObject(d.settings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a License message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.License
             * @static
             * @param {streem.api.License} m License
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            License.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.settings = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.name = "";
                    d.tier = 0;
                    d.version = 0;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.tier != null && m.hasOwnProperty("tier")) {
                    d.tier = m.tier;
                }
                if (m.version != null && m.hasOwnProperty("version")) {
                    d.version = m.version;
                }
                if (m.settings && m.settings.length) {
                    d.settings = [];
                    for (var j = 0; j < m.settings.length; ++j) {
                        d.settings[j] = $root.streem.api.Setting.toObject(m.settings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this License to JSON.
             * @function toJSON
             * @memberof streem.api.License
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            License.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for License
             * @function getTypeUrl
             * @memberof streem.api.License
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            License.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.License";
            };

            return License;
        })();

        api.Analytics = (function() {

            /**
             * Constructs a new Analytics service.
             * @memberof streem.api
             * @classdesc Represents an Analytics
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Analytics(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Analytics.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Analytics;

            /**
             * Creates new Analytics service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Analytics
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Analytics} RPC service. Useful where requests and/or responses are streamed.
             */
            Analytics.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Analytics#recordEvents}.
             * @memberof streem.api.Analytics
             * @typedef RecordEventsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.RecordEventsResponse} [response] RecordEventsResponse
             */

            /**
             * Calls RecordEvents.
             * @function recordEvents
             * @memberof streem.api.Analytics
             * @instance
             * @param {streem.api.IRecordEventsRequest} request RecordEventsRequest message or plain object
             * @param {streem.api.Analytics.RecordEventsCallback} callback Node-style callback called with the error, if any, and RecordEventsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Analytics.prototype.recordEvents = function recordEvents(request, callback) {
                return this.rpcCall(recordEvents, $root.streem.api.RecordEventsRequest, $root.streem.api.RecordEventsResponse, request, callback);
            }, "name", { value: "RecordEvents" });

            /**
             * Calls RecordEvents.
             * @function recordEvents
             * @memberof streem.api.Analytics
             * @instance
             * @param {streem.api.IRecordEventsRequest} request RecordEventsRequest message or plain object
             * @returns {Promise<streem.api.RecordEventsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Analytics#aggregateRoomMetricsForCompany}.
             * @memberof streem.api.Analytics
             * @typedef AggregateRoomMetricsForCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.AggregateRoomMetricsForCompanyResponse} [response] AggregateRoomMetricsForCompanyResponse
             */

            /**
             * Calls AggregateRoomMetricsForCompany.
             * @function aggregateRoomMetricsForCompany
             * @memberof streem.api.Analytics
             * @instance
             * @param {streem.api.IAggregateRoomMetricsForCompanyRequest} request AggregateRoomMetricsForCompanyRequest message or plain object
             * @param {streem.api.Analytics.AggregateRoomMetricsForCompanyCallback} callback Node-style callback called with the error, if any, and AggregateRoomMetricsForCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Analytics.prototype.aggregateRoomMetricsForCompany = function aggregateRoomMetricsForCompany(request, callback) {
                return this.rpcCall(aggregateRoomMetricsForCompany, $root.streem.api.AggregateRoomMetricsForCompanyRequest, $root.streem.api.AggregateRoomMetricsForCompanyResponse, request, callback);
            }, "name", { value: "AggregateRoomMetricsForCompany" });

            /**
             * Calls AggregateRoomMetricsForCompany.
             * @function aggregateRoomMetricsForCompany
             * @memberof streem.api.Analytics
             * @instance
             * @param {streem.api.IAggregateRoomMetricsForCompanyRequest} request AggregateRoomMetricsForCompanyRequest message or plain object
             * @returns {Promise<streem.api.AggregateRoomMetricsForCompanyResponse>} Promise
             * @variation 2
             */

            return Analytics;
        })();

        api.RecordEventsRequest = (function() {

            /**
             * Properties of a RecordEventsRequest.
             * @memberof streem.api
             * @interface IRecordEventsRequest
             * @property {Array.<streem.api.IAnalyticEvent>|null} [events] RecordEventsRequest events
             */

            /**
             * Constructs a new RecordEventsRequest.
             * @memberof streem.api
             * @classdesc Represents a RecordEventsRequest.
             * @implements IRecordEventsRequest
             * @constructor
             * @param {streem.api.IRecordEventsRequest=} [p] Properties to set
             */
            function RecordEventsRequest(p) {
                this.events = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RecordEventsRequest events.
             * @member {Array.<streem.api.IAnalyticEvent>} events
             * @memberof streem.api.RecordEventsRequest
             * @instance
             */
            RecordEventsRequest.prototype.events = $util.emptyArray;

            /**
             * Creates a new RecordEventsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {streem.api.IRecordEventsRequest=} [properties] Properties to set
             * @returns {streem.api.RecordEventsRequest} RecordEventsRequest instance
             */
            RecordEventsRequest.create = function create(properties) {
                return new RecordEventsRequest(properties);
            };

            /**
             * Encodes the specified RecordEventsRequest message. Does not implicitly {@link streem.api.RecordEventsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {streem.api.IRecordEventsRequest} m RecordEventsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordEventsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.events != null && m.events.length) {
                    for (var i = 0; i < m.events.length; ++i)
                        $root.streem.api.AnalyticEvent.encode(m.events[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a RecordEventsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RecordEventsRequest} RecordEventsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordEventsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RecordEventsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.events && m.events.length))
                                m.events = [];
                            m.events.push($root.streem.api.AnalyticEvent.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RecordEventsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RecordEventsRequest} RecordEventsRequest
             */
            RecordEventsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RecordEventsRequest)
                    return d;
                var m = new $root.streem.api.RecordEventsRequest();
                if (d.events) {
                    if (!Array.isArray(d.events))
                        throw TypeError(".streem.api.RecordEventsRequest.events: array expected");
                    m.events = [];
                    for (var i = 0; i < d.events.length; ++i) {
                        if (typeof d.events[i] !== "object")
                            throw TypeError(".streem.api.RecordEventsRequest.events: object expected");
                        m.events[i] = $root.streem.api.AnalyticEvent.fromObject(d.events[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a RecordEventsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {streem.api.RecordEventsRequest} m RecordEventsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecordEventsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.events = [];
                }
                if (m.events && m.events.length) {
                    d.events = [];
                    for (var j = 0; j < m.events.length; ++j) {
                        d.events[j] = $root.streem.api.AnalyticEvent.toObject(m.events[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this RecordEventsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RecordEventsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecordEventsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RecordEventsRequest
             * @function getTypeUrl
             * @memberof streem.api.RecordEventsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecordEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RecordEventsRequest";
            };

            return RecordEventsRequest;
        })();

        api.RecordEventsResponse = (function() {

            /**
             * Properties of a RecordEventsResponse.
             * @memberof streem.api
             * @interface IRecordEventsResponse
             * @property {Array.<streem.api.RecordEventsResponse.IFailure>|null} [failures] RecordEventsResponse failures
             */

            /**
             * Constructs a new RecordEventsResponse.
             * @memberof streem.api
             * @classdesc Represents a RecordEventsResponse.
             * @implements IRecordEventsResponse
             * @constructor
             * @param {streem.api.IRecordEventsResponse=} [p] Properties to set
             */
            function RecordEventsResponse(p) {
                this.failures = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RecordEventsResponse failures.
             * @member {Array.<streem.api.RecordEventsResponse.IFailure>} failures
             * @memberof streem.api.RecordEventsResponse
             * @instance
             */
            RecordEventsResponse.prototype.failures = $util.emptyArray;

            /**
             * Creates a new RecordEventsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {streem.api.IRecordEventsResponse=} [properties] Properties to set
             * @returns {streem.api.RecordEventsResponse} RecordEventsResponse instance
             */
            RecordEventsResponse.create = function create(properties) {
                return new RecordEventsResponse(properties);
            };

            /**
             * Encodes the specified RecordEventsResponse message. Does not implicitly {@link streem.api.RecordEventsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {streem.api.IRecordEventsResponse} m RecordEventsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordEventsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.failures != null && m.failures.length) {
                    for (var i = 0; i < m.failures.length; ++i)
                        $root.streem.api.RecordEventsResponse.Failure.encode(m.failures[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a RecordEventsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RecordEventsResponse} RecordEventsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordEventsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RecordEventsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.failures && m.failures.length))
                                m.failures = [];
                            m.failures.push($root.streem.api.RecordEventsResponse.Failure.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RecordEventsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RecordEventsResponse} RecordEventsResponse
             */
            RecordEventsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RecordEventsResponse)
                    return d;
                var m = new $root.streem.api.RecordEventsResponse();
                if (d.failures) {
                    if (!Array.isArray(d.failures))
                        throw TypeError(".streem.api.RecordEventsResponse.failures: array expected");
                    m.failures = [];
                    for (var i = 0; i < d.failures.length; ++i) {
                        if (typeof d.failures[i] !== "object")
                            throw TypeError(".streem.api.RecordEventsResponse.failures: object expected");
                        m.failures[i] = $root.streem.api.RecordEventsResponse.Failure.fromObject(d.failures[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a RecordEventsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {streem.api.RecordEventsResponse} m RecordEventsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecordEventsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.failures = [];
                }
                if (m.failures && m.failures.length) {
                    d.failures = [];
                    for (var j = 0; j < m.failures.length; ++j) {
                        d.failures[j] = $root.streem.api.RecordEventsResponse.Failure.toObject(m.failures[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this RecordEventsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.RecordEventsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecordEventsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RecordEventsResponse
             * @function getTypeUrl
             * @memberof streem.api.RecordEventsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecordEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RecordEventsResponse";
            };

            RecordEventsResponse.Failure = (function() {

                /**
                 * Properties of a Failure.
                 * @memberof streem.api.RecordEventsResponse
                 * @interface IFailure
                 * @property {number|null} [index] Failure index
                 * @property {streem.api.IErrorResponse|null} [error] Failure error
                 */

                /**
                 * Constructs a new Failure.
                 * @memberof streem.api.RecordEventsResponse
                 * @classdesc Represents a Failure.
                 * @implements IFailure
                 * @constructor
                 * @param {streem.api.RecordEventsResponse.IFailure=} [p] Properties to set
                 */
                function Failure(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Failure index.
                 * @member {number} index
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @instance
                 */
                Failure.prototype.index = 0;

                /**
                 * Failure error.
                 * @member {streem.api.IErrorResponse|null|undefined} error
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @instance
                 */
                Failure.prototype.error = null;

                /**
                 * Creates a new Failure instance using the specified properties.
                 * @function create
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {streem.api.RecordEventsResponse.IFailure=} [properties] Properties to set
                 * @returns {streem.api.RecordEventsResponse.Failure} Failure instance
                 */
                Failure.create = function create(properties) {
                    return new Failure(properties);
                };

                /**
                 * Encodes the specified Failure message. Does not implicitly {@link streem.api.RecordEventsResponse.Failure.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {streem.api.RecordEventsResponse.IFailure} m Failure message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Failure.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.index != null && Object.hasOwnProperty.call(m, "index"))
                        w.uint32(8).int32(m.index);
                    if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                        $root.streem.api.ErrorResponse.encode(m.error, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a Failure message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.RecordEventsResponse.Failure} Failure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Failure.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RecordEventsResponse.Failure();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.index = r.int32();
                                break;
                            }
                        case 2: {
                                m.error = $root.streem.api.ErrorResponse.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Failure message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.RecordEventsResponse.Failure} Failure
                 */
                Failure.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.RecordEventsResponse.Failure)
                        return d;
                    var m = new $root.streem.api.RecordEventsResponse.Failure();
                    if (d.index != null) {
                        m.index = d.index | 0;
                    }
                    if (d.error != null) {
                        if (typeof d.error !== "object")
                            throw TypeError(".streem.api.RecordEventsResponse.Failure.error: object expected");
                        m.error = $root.streem.api.ErrorResponse.fromObject(d.error);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Failure message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {streem.api.RecordEventsResponse.Failure} m Failure
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Failure.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.index = 0;
                        d.error = null;
                    }
                    if (m.index != null && m.hasOwnProperty("index")) {
                        d.index = m.index;
                    }
                    if (m.error != null && m.hasOwnProperty("error")) {
                        d.error = $root.streem.api.ErrorResponse.toObject(m.error, o);
                    }
                    return d;
                };

                /**
                 * Converts this Failure to JSON.
                 * @function toJSON
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Failure.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Failure
                 * @function getTypeUrl
                 * @memberof streem.api.RecordEventsResponse.Failure
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Failure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.RecordEventsResponse.Failure";
                };

                return Failure;
            })();

            return RecordEventsResponse;
        })();

        api.AggregateRoomMetricsForCompanyRequest = (function() {

            /**
             * Properties of an AggregateRoomMetricsForCompanyRequest.
             * @memberof streem.api
             * @interface IAggregateRoomMetricsForCompanyRequest
             * @property {streem.api.AggregateRoomMetricsForCompanyRequest.IFilter|null} [filter] AggregateRoomMetricsForCompanyRequest filter
             * @property {streem.api.AggregatedRoomMetrics.AggregateBy|null} [aggregateBy] AggregateRoomMetricsForCompanyRequest aggregateBy
             * @property {number|null} [pageSize] AggregateRoomMetricsForCompanyRequest pageSize
             * @property {string|null} [pageToken] AggregateRoomMetricsForCompanyRequest pageToken
             */

            /**
             * Constructs a new AggregateRoomMetricsForCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents an AggregateRoomMetricsForCompanyRequest.
             * @implements IAggregateRoomMetricsForCompanyRequest
             * @constructor
             * @param {streem.api.IAggregateRoomMetricsForCompanyRequest=} [p] Properties to set
             */
            function AggregateRoomMetricsForCompanyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AggregateRoomMetricsForCompanyRequest filter.
             * @member {streem.api.AggregateRoomMetricsForCompanyRequest.IFilter|null|undefined} filter
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @instance
             */
            AggregateRoomMetricsForCompanyRequest.prototype.filter = null;

            /**
             * AggregateRoomMetricsForCompanyRequest aggregateBy.
             * @member {streem.api.AggregatedRoomMetrics.AggregateBy} aggregateBy
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @instance
             */
            AggregateRoomMetricsForCompanyRequest.prototype.aggregateBy = 0;

            /**
             * AggregateRoomMetricsForCompanyRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @instance
             */
            AggregateRoomMetricsForCompanyRequest.prototype.pageSize = 0;

            /**
             * AggregateRoomMetricsForCompanyRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @instance
             */
            AggregateRoomMetricsForCompanyRequest.prototype.pageToken = "";

            /**
             * Creates a new AggregateRoomMetricsForCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {streem.api.IAggregateRoomMetricsForCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.AggregateRoomMetricsForCompanyRequest} AggregateRoomMetricsForCompanyRequest instance
             */
            AggregateRoomMetricsForCompanyRequest.create = function create(properties) {
                return new AggregateRoomMetricsForCompanyRequest(properties);
            };

            /**
             * Encodes the specified AggregateRoomMetricsForCompanyRequest message. Does not implicitly {@link streem.api.AggregateRoomMetricsForCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {streem.api.IAggregateRoomMetricsForCompanyRequest} m AggregateRoomMetricsForCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AggregateRoomMetricsForCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                    $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter.encode(m.filter, w.uint32(10).fork()).ldelim();
                if (m.aggregateBy != null && Object.hasOwnProperty.call(m, "aggregateBy"))
                    w.uint32(16).int32(m.aggregateBy);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(24).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(34).string(m.pageToken);
                return w;
            };

            /**
             * Decodes an AggregateRoomMetricsForCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AggregateRoomMetricsForCompanyRequest} AggregateRoomMetricsForCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AggregateRoomMetricsForCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregateRoomMetricsForCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.filter = $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.aggregateBy = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AggregateRoomMetricsForCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AggregateRoomMetricsForCompanyRequest} AggregateRoomMetricsForCompanyRequest
             */
            AggregateRoomMetricsForCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AggregateRoomMetricsForCompanyRequest)
                    return d;
                var m = new $root.streem.api.AggregateRoomMetricsForCompanyRequest();
                if (d.filter != null) {
                    if (typeof d.filter !== "object")
                        throw TypeError(".streem.api.AggregateRoomMetricsForCompanyRequest.filter: object expected");
                    m.filter = $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter.fromObject(d.filter);
                }
                switch (d.aggregateBy) {
                default:
                    if (typeof d.aggregateBy === "number") {
                        m.aggregateBy = d.aggregateBy;
                        break;
                    }
                    break;
                case "AGGREGATE_BY_USER_SID":
                case 0:
                    m.aggregateBy = 0;
                    break;
                case "AGGREGATE_BY_GROUP_SID":
                case 1:
                    m.aggregateBy = 1;
                    break;
                case "AGGREGATE_BY_COMPANY_SID":
                case 2:
                    m.aggregateBy = 2;
                    break;
                case "AGGREGATE_BY_GROUP_NAME":
                case 3:
                    m.aggregateBy = 3;
                    break;
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from an AggregateRoomMetricsForCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {streem.api.AggregateRoomMetricsForCompanyRequest} m AggregateRoomMetricsForCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AggregateRoomMetricsForCompanyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.filter = null;
                    d.aggregateBy = o.enums === String ? "AGGREGATE_BY_USER_SID" : 0;
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.filter != null && m.hasOwnProperty("filter")) {
                    d.filter = $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter.toObject(m.filter, o);
                }
                if (m.aggregateBy != null && m.hasOwnProperty("aggregateBy")) {
                    d.aggregateBy = o.enums === String ? $root.streem.api.AggregatedRoomMetrics.AggregateBy[m.aggregateBy] === undefined ? m.aggregateBy : $root.streem.api.AggregatedRoomMetrics.AggregateBy[m.aggregateBy] : m.aggregateBy;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this AggregateRoomMetricsForCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AggregateRoomMetricsForCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AggregateRoomMetricsForCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AggregateRoomMetricsForCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AggregateRoomMetricsForCompanyRequest";
            };

            AggregateRoomMetricsForCompanyRequest.Filter = (function() {

                /**
                 * Properties of a Filter.
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
                 * @interface IFilter
                 * @property {string|null} [userSid] Filter userSid
                 * @property {string|null} [groupSid] Filter groupSid
                 * @property {string|null} [groupName] Filter groupName
                 */

                /**
                 * Constructs a new Filter.
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest
                 * @classdesc Represents a Filter.
                 * @implements IFilter
                 * @constructor
                 * @param {streem.api.AggregateRoomMetricsForCompanyRequest.IFilter=} [p] Properties to set
                 */
                function Filter(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Filter userSid.
                 * @member {string} userSid
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @instance
                 */
                Filter.prototype.userSid = "";

                /**
                 * Filter groupSid.
                 * @member {string} groupSid
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @instance
                 */
                Filter.prototype.groupSid = "";

                /**
                 * Filter groupName.
                 * @member {string} groupName
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @instance
                 */
                Filter.prototype.groupName = "";

                /**
                 * Creates a new Filter instance using the specified properties.
                 * @function create
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {streem.api.AggregateRoomMetricsForCompanyRequest.IFilter=} [properties] Properties to set
                 * @returns {streem.api.AggregateRoomMetricsForCompanyRequest.Filter} Filter instance
                 */
                Filter.create = function create(properties) {
                    return new Filter(properties);
                };

                /**
                 * Encodes the specified Filter message. Does not implicitly {@link streem.api.AggregateRoomMetricsForCompanyRequest.Filter.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {streem.api.AggregateRoomMetricsForCompanyRequest.IFilter} m Filter message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Filter.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                        w.uint32(10).string(m.userSid);
                    if (m.groupSid != null && Object.hasOwnProperty.call(m, "groupSid"))
                        w.uint32(26).string(m.groupSid);
                    if (m.groupName != null && Object.hasOwnProperty.call(m, "groupName"))
                        w.uint32(34).string(m.groupName);
                    return w;
                };

                /**
                 * Decodes a Filter message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.AggregateRoomMetricsForCompanyRequest.Filter} Filter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Filter.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.userSid = r.string();
                                break;
                            }
                        case 3: {
                                m.groupSid = r.string();
                                break;
                            }
                        case 4: {
                                m.groupName = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Filter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.AggregateRoomMetricsForCompanyRequest.Filter} Filter
                 */
                Filter.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter)
                        return d;
                    var m = new $root.streem.api.AggregateRoomMetricsForCompanyRequest.Filter();
                    if (d.userSid != null) {
                        m.userSid = String(d.userSid);
                    }
                    if (d.groupSid != null) {
                        m.groupSid = String(d.groupSid);
                    }
                    if (d.groupName != null) {
                        m.groupName = String(d.groupName);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Filter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {streem.api.AggregateRoomMetricsForCompanyRequest.Filter} m Filter
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Filter.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.userSid = "";
                        d.groupSid = "";
                        d.groupName = "";
                    }
                    if (m.userSid != null && m.hasOwnProperty("userSid")) {
                        d.userSid = m.userSid;
                    }
                    if (m.groupSid != null && m.hasOwnProperty("groupSid")) {
                        d.groupSid = m.groupSid;
                    }
                    if (m.groupName != null && m.hasOwnProperty("groupName")) {
                        d.groupName = m.groupName;
                    }
                    return d;
                };

                /**
                 * Converts this Filter to JSON.
                 * @function toJSON
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Filter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Filter
                 * @function getTypeUrl
                 * @memberof streem.api.AggregateRoomMetricsForCompanyRequest.Filter
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Filter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.AggregateRoomMetricsForCompanyRequest.Filter";
                };

                return Filter;
            })();

            return AggregateRoomMetricsForCompanyRequest;
        })();

        api.AggregateRoomMetricsForCompanyResponse = (function() {

            /**
             * Properties of an AggregateRoomMetricsForCompanyResponse.
             * @memberof streem.api
             * @interface IAggregateRoomMetricsForCompanyResponse
             * @property {Array.<streem.api.IAggregatedRoomMetrics>|null} [results] AggregateRoomMetricsForCompanyResponse results
             * @property {number|null} [totalSize] AggregateRoomMetricsForCompanyResponse totalSize
             * @property {string|null} [nextPageToken] AggregateRoomMetricsForCompanyResponse nextPageToken
             */

            /**
             * Constructs a new AggregateRoomMetricsForCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents an AggregateRoomMetricsForCompanyResponse.
             * @implements IAggregateRoomMetricsForCompanyResponse
             * @constructor
             * @param {streem.api.IAggregateRoomMetricsForCompanyResponse=} [p] Properties to set
             */
            function AggregateRoomMetricsForCompanyResponse(p) {
                this.results = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AggregateRoomMetricsForCompanyResponse results.
             * @member {Array.<streem.api.IAggregatedRoomMetrics>} results
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @instance
             */
            AggregateRoomMetricsForCompanyResponse.prototype.results = $util.emptyArray;

            /**
             * AggregateRoomMetricsForCompanyResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @instance
             */
            AggregateRoomMetricsForCompanyResponse.prototype.totalSize = 0;

            /**
             * AggregateRoomMetricsForCompanyResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @instance
             */
            AggregateRoomMetricsForCompanyResponse.prototype.nextPageToken = "";

            /**
             * Creates a new AggregateRoomMetricsForCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {streem.api.IAggregateRoomMetricsForCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.AggregateRoomMetricsForCompanyResponse} AggregateRoomMetricsForCompanyResponse instance
             */
            AggregateRoomMetricsForCompanyResponse.create = function create(properties) {
                return new AggregateRoomMetricsForCompanyResponse(properties);
            };

            /**
             * Encodes the specified AggregateRoomMetricsForCompanyResponse message. Does not implicitly {@link streem.api.AggregateRoomMetricsForCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {streem.api.IAggregateRoomMetricsForCompanyResponse} m AggregateRoomMetricsForCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AggregateRoomMetricsForCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.results != null && m.results.length) {
                    for (var i = 0; i < m.results.length; ++i)
                        $root.streem.api.AggregatedRoomMetrics.encode(m.results[i], w.uint32(10).fork()).ldelim();
                }
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(16).int32(m.totalSize);
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(26).string(m.nextPageToken);
                return w;
            };

            /**
             * Decodes an AggregateRoomMetricsForCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AggregateRoomMetricsForCompanyResponse} AggregateRoomMetricsForCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AggregateRoomMetricsForCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AggregateRoomMetricsForCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.results && m.results.length))
                                m.results = [];
                            m.results.push($root.streem.api.AggregatedRoomMetrics.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.totalSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AggregateRoomMetricsForCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AggregateRoomMetricsForCompanyResponse} AggregateRoomMetricsForCompanyResponse
             */
            AggregateRoomMetricsForCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AggregateRoomMetricsForCompanyResponse)
                    return d;
                var m = new $root.streem.api.AggregateRoomMetricsForCompanyResponse();
                if (d.results) {
                    if (!Array.isArray(d.results))
                        throw TypeError(".streem.api.AggregateRoomMetricsForCompanyResponse.results: array expected");
                    m.results = [];
                    for (var i = 0; i < d.results.length; ++i) {
                        if (typeof d.results[i] !== "object")
                            throw TypeError(".streem.api.AggregateRoomMetricsForCompanyResponse.results: object expected");
                        m.results[i] = $root.streem.api.AggregatedRoomMetrics.fromObject(d.results[i]);
                    }
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from an AggregateRoomMetricsForCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {streem.api.AggregateRoomMetricsForCompanyResponse} m AggregateRoomMetricsForCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AggregateRoomMetricsForCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.results = [];
                }
                if (o.defaults) {
                    d.totalSize = 0;
                    d.nextPageToken = "";
                }
                if (m.results && m.results.length) {
                    d.results = [];
                    for (var j = 0; j < m.results.length; ++j) {
                        d.results[j] = $root.streem.api.AggregatedRoomMetrics.toObject(m.results[j], o);
                    }
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                return d;
            };

            /**
             * Converts this AggregateRoomMetricsForCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AggregateRoomMetricsForCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AggregateRoomMetricsForCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.AggregateRoomMetricsForCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AggregateRoomMetricsForCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AggregateRoomMetricsForCompanyResponse";
            };

            return AggregateRoomMetricsForCompanyResponse;
        })();

        api.ClientVersionTooOldError = (function() {

            /**
             * Properties of a ClientVersionTooOldError.
             * @memberof streem.api
             * @interface IClientVersionTooOldError
             * @property {boolean|null} [updateRequired] ClientVersionTooOldError updateRequired
             */

            /**
             * Constructs a new ClientVersionTooOldError.
             * @memberof streem.api
             * @classdesc Represents a ClientVersionTooOldError.
             * @implements IClientVersionTooOldError
             * @constructor
             * @param {streem.api.IClientVersionTooOldError=} [p] Properties to set
             */
            function ClientVersionTooOldError(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ClientVersionTooOldError updateRequired.
             * @member {boolean} updateRequired
             * @memberof streem.api.ClientVersionTooOldError
             * @instance
             */
            ClientVersionTooOldError.prototype.updateRequired = false;

            /**
             * Creates a new ClientVersionTooOldError instance using the specified properties.
             * @function create
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {streem.api.IClientVersionTooOldError=} [properties] Properties to set
             * @returns {streem.api.ClientVersionTooOldError} ClientVersionTooOldError instance
             */
            ClientVersionTooOldError.create = function create(properties) {
                return new ClientVersionTooOldError(properties);
            };

            /**
             * Encodes the specified ClientVersionTooOldError message. Does not implicitly {@link streem.api.ClientVersionTooOldError.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {streem.api.IClientVersionTooOldError} m ClientVersionTooOldError message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientVersionTooOldError.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.updateRequired != null && Object.hasOwnProperty.call(m, "updateRequired"))
                    w.uint32(8).bool(m.updateRequired);
                return w;
            };

            /**
             * Decodes a ClientVersionTooOldError message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ClientVersionTooOldError} ClientVersionTooOldError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientVersionTooOldError.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ClientVersionTooOldError();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.updateRequired = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ClientVersionTooOldError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ClientVersionTooOldError} ClientVersionTooOldError
             */
            ClientVersionTooOldError.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ClientVersionTooOldError)
                    return d;
                var m = new $root.streem.api.ClientVersionTooOldError();
                if (d.updateRequired != null) {
                    m.updateRequired = Boolean(d.updateRequired);
                }
                return m;
            };

            /**
             * Creates a plain object from a ClientVersionTooOldError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {streem.api.ClientVersionTooOldError} m ClientVersionTooOldError
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientVersionTooOldError.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.updateRequired = false;
                }
                if (m.updateRequired != null && m.hasOwnProperty("updateRequired")) {
                    d.updateRequired = m.updateRequired;
                }
                return d;
            };

            /**
             * Converts this ClientVersionTooOldError to JSON.
             * @function toJSON
             * @memberof streem.api.ClientVersionTooOldError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientVersionTooOldError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ClientVersionTooOldError
             * @function getTypeUrl
             * @memberof streem.api.ClientVersionTooOldError
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientVersionTooOldError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ClientVersionTooOldError";
            };

            return ClientVersionTooOldError;
        })();

        api.Users = (function() {

            /**
             * Constructs a new Users service.
             * @memberof streem.api
             * @classdesc Represents a Users
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Users(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Users.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Users;

            /**
             * Creates new Users service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Users
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Users} RPC service. Useful where requests and/or responses are streamed.
             */
            Users.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Users#listRecentlyIdentifiedUsers}.
             * @memberof streem.api.Users
             * @typedef ListRecentlyIdentifiedUsersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListRecentlyIdentifiedUsersResponse} [response] ListRecentlyIdentifiedUsersResponse
             */

            /**
             * Calls ListRecentlyIdentifiedUsers.
             * @function listRecentlyIdentifiedUsers
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListRecentlyIdentifiedUsersRequest} request ListRecentlyIdentifiedUsersRequest message or plain object
             * @param {streem.api.Users.ListRecentlyIdentifiedUsersCallback} callback Node-style callback called with the error, if any, and ListRecentlyIdentifiedUsersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.listRecentlyIdentifiedUsers = function listRecentlyIdentifiedUsers(request, callback) {
                return this.rpcCall(listRecentlyIdentifiedUsers, $root.streem.api.ListRecentlyIdentifiedUsersRequest, $root.streem.api.ListRecentlyIdentifiedUsersResponse, request, callback);
            }, "name", { value: "ListRecentlyIdentifiedUsers" });

            /**
             * Calls ListRecentlyIdentifiedUsers.
             * @function listRecentlyIdentifiedUsers
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListRecentlyIdentifiedUsersRequest} request ListRecentlyIdentifiedUsersRequest message or plain object
             * @returns {Promise<streem.api.ListRecentlyIdentifiedUsersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getUser}.
             * @memberof streem.api.Users
             * @typedef GetUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserResponse} [response] GetUserResponse
             */

            /**
             * Calls GetUser.
             * @function getUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserRequest} request GetUserRequest message or plain object
             * @param {streem.api.Users.GetUserCallback} callback Node-style callback called with the error, if any, and GetUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getUser = function getUser(request, callback) {
                return this.rpcCall(getUser, $root.streem.api.GetUserRequest, $root.streem.api.GetUserResponse, request, callback);
            }, "name", { value: "GetUser" });

            /**
             * Calls GetUser.
             * @function getUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserRequest} request GetUserRequest message or plain object
             * @returns {Promise<streem.api.GetUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#deleteUser}.
             * @memberof streem.api.Users
             * @typedef DeleteUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteUserResponse} [response] DeleteUserResponse
             */

            /**
             * Calls DeleteUser.
             * @function deleteUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IDeleteUserRequest} request DeleteUserRequest message or plain object
             * @param {streem.api.Users.DeleteUserCallback} callback Node-style callback called with the error, if any, and DeleteUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.deleteUser = function deleteUser(request, callback) {
                return this.rpcCall(deleteUser, $root.streem.api.DeleteUserRequest, $root.streem.api.DeleteUserResponse, request, callback);
            }, "name", { value: "DeleteUser" });

            /**
             * Calls DeleteUser.
             * @function deleteUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IDeleteUserRequest} request DeleteUserRequest message or plain object
             * @returns {Promise<streem.api.DeleteUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#identifyUser}.
             * @memberof streem.api.Users
             * @typedef IdentifyUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.IdentifyUserResponse} [response] IdentifyUserResponse
             */

            /**
             * Calls IdentifyUser.
             * @function identifyUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IIdentifyUserRequest} request IdentifyUserRequest message or plain object
             * @param {streem.api.Users.IdentifyUserCallback} callback Node-style callback called with the error, if any, and IdentifyUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.identifyUser = function identifyUser(request, callback) {
                return this.rpcCall(identifyUser, $root.streem.api.IdentifyUserRequest, $root.streem.api.IdentifyUserResponse, request, callback);
            }, "name", { value: "IdentifyUser" });

            /**
             * Calls IdentifyUser.
             * @function identifyUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IIdentifyUserRequest} request IdentifyUserRequest message or plain object
             * @returns {Promise<streem.api.IdentifyUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getUserAvailability}.
             * @memberof streem.api.Users
             * @typedef GetUserAvailabilityCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserAvailabilityResponse} [response] GetUserAvailabilityResponse
             */

            /**
             * Calls GetUserAvailability.
             * @function getUserAvailability
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserAvailabilityRequest} request GetUserAvailabilityRequest message or plain object
             * @param {streem.api.Users.GetUserAvailabilityCallback} callback Node-style callback called with the error, if any, and GetUserAvailabilityResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getUserAvailability = function getUserAvailability(request, callback) {
                return this.rpcCall(getUserAvailability, $root.streem.api.GetUserAvailabilityRequest, $root.streem.api.GetUserAvailabilityResponse, request, callback);
            }, "name", { value: "GetUserAvailability" });

            /**
             * Calls GetUserAvailability.
             * @function getUserAvailability
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserAvailabilityRequest} request GetUserAvailabilityRequest message or plain object
             * @returns {Promise<streem.api.GetUserAvailabilityResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#updateUserAvailability}.
             * @memberof streem.api.Users
             * @typedef UpdateUserAvailabilityCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateUserAvailabilityResponse} [response] UpdateUserAvailabilityResponse
             */

            /**
             * Calls UpdateUserAvailability.
             * @function updateUserAvailability
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserAvailabilityRequest} request UpdateUserAvailabilityRequest message or plain object
             * @param {streem.api.Users.UpdateUserAvailabilityCallback} callback Node-style callback called with the error, if any, and UpdateUserAvailabilityResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.updateUserAvailability = function updateUserAvailability(request, callback) {
                return this.rpcCall(updateUserAvailability, $root.streem.api.UpdateUserAvailabilityRequest, $root.streem.api.UpdateUserAvailabilityResponse, request, callback);
            }, "name", { value: "UpdateUserAvailability" });

            /**
             * Calls UpdateUserAvailability.
             * @function updateUserAvailability
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserAvailabilityRequest} request UpdateUserAvailabilityRequest message or plain object
             * @returns {Promise<streem.api.UpdateUserAvailabilityResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#batchCreateUser}.
             * @memberof streem.api.Users
             * @typedef BatchCreateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.BatchCreateUserResponse} [response] BatchCreateUserResponse
             */

            /**
             * Calls BatchCreateUser.
             * @function batchCreateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IBatchCreateUserRequest} request BatchCreateUserRequest message or plain object
             * @param {streem.api.Users.BatchCreateUserCallback} callback Node-style callback called with the error, if any, and BatchCreateUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.batchCreateUser = function batchCreateUser(request, callback) {
                return this.rpcCall(batchCreateUser, $root.streem.api.BatchCreateUserRequest, $root.streem.api.BatchCreateUserResponse, request, callback);
            }, "name", { value: "BatchCreateUser" });

            /**
             * Calls BatchCreateUser.
             * @function batchCreateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IBatchCreateUserRequest} request BatchCreateUserRequest message or plain object
             * @returns {Promise<streem.api.BatchCreateUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#listUserInvitations}.
             * @memberof streem.api.Users
             * @typedef ListUserInvitationsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListUserInvitationsResponse} [response] ListUserInvitationsResponse
             */

            /**
             * Calls ListUserInvitations.
             * @function listUserInvitations
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListUserInvitationsRequest} request ListUserInvitationsRequest message or plain object
             * @param {streem.api.Users.ListUserInvitationsCallback} callback Node-style callback called with the error, if any, and ListUserInvitationsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.listUserInvitations = function listUserInvitations(request, callback) {
                return this.rpcCall(listUserInvitations, $root.streem.api.ListUserInvitationsRequest, $root.streem.api.ListUserInvitationsResponse, request, callback);
            }, "name", { value: "ListUserInvitations" });

            /**
             * Calls ListUserInvitations.
             * @function listUserInvitations
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListUserInvitationsRequest} request ListUserInvitationsRequest message or plain object
             * @returns {Promise<streem.api.ListUserInvitationsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#createUserTokens}.
             * @memberof streem.api.Users
             * @typedef CreateUserTokensCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateUserTokensResponse} [response] CreateUserTokensResponse
             */

            /**
             * Calls CreateUserTokens.
             * @function createUserTokens
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserTokensRequest} request CreateUserTokensRequest message or plain object
             * @param {streem.api.Users.CreateUserTokensCallback} callback Node-style callback called with the error, if any, and CreateUserTokensResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.createUserTokens = function createUserTokens(request, callback) {
                return this.rpcCall(createUserTokens, $root.streem.api.CreateUserTokensRequest, $root.streem.api.CreateUserTokensResponse, request, callback);
            }, "name", { value: "CreateUserTokens" });

            /**
             * Calls CreateUserTokens.
             * @function createUserTokens
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserTokensRequest} request CreateUserTokensRequest message or plain object
             * @returns {Promise<streem.api.CreateUserTokensResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#createUserVoipToken}.
             * @memberof streem.api.Users
             * @typedef CreateUserVoipTokenCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls CreateUserVoipToken.
             * @function createUserVoipToken
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserVoipTokenRequest} request CreateUserVoipTokenRequest message or plain object
             * @param {streem.api.Users.CreateUserVoipTokenCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.createUserVoipToken = function createUserVoipToken(request, callback) {
                return this.rpcCall(createUserVoipToken, $root.streem.api.CreateUserVoipTokenRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "CreateUserVoipToken" });

            /**
             * Calls CreateUserVoipToken.
             * @function createUserVoipToken
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserVoipTokenRequest} request CreateUserVoipTokenRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getUserCallLog}.
             * @memberof streem.api.Users
             * @typedef GetUserCallLogCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserCallLogResponse} [response] GetUserCallLogResponse
             */

            /**
             * Calls GetUserCallLog.
             * @function getUserCallLog
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserCallLogRequest} request GetUserCallLogRequest message or plain object
             * @param {streem.api.Users.GetUserCallLogCallback} callback Node-style callback called with the error, if any, and GetUserCallLogResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getUserCallLog = function getUserCallLog(request, callback) {
                return this.rpcCall(getUserCallLog, $root.streem.api.GetUserCallLogRequest, $root.streem.api.GetUserCallLogResponse, request, callback);
            }, "name", { value: "GetUserCallLog" });

            /**
             * Calls GetUserCallLog.
             * @function getUserCallLog
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserCallLogRequest} request GetUserCallLogRequest message or plain object
             * @returns {Promise<streem.api.GetUserCallLogResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getUserCallLogEntry}.
             * @memberof streem.api.Users
             * @typedef GetUserCallLogEntryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserCallLogEntryResponse} [response] GetUserCallLogEntryResponse
             */

            /**
             * Calls GetUserCallLogEntry.
             * @function getUserCallLogEntry
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserCallLogEntryRequest} request GetUserCallLogEntryRequest message or plain object
             * @param {streem.api.Users.GetUserCallLogEntryCallback} callback Node-style callback called with the error, if any, and GetUserCallLogEntryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getUserCallLogEntry = function getUserCallLogEntry(request, callback) {
                return this.rpcCall(getUserCallLogEntry, $root.streem.api.GetUserCallLogEntryRequest, $root.streem.api.GetUserCallLogEntryResponse, request, callback);
            }, "name", { value: "GetUserCallLogEntry" });

            /**
             * Calls GetUserCallLogEntry.
             * @function getUserCallLogEntry
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserCallLogEntryRequest} request GetUserCallLogEntryRequest message or plain object
             * @returns {Promise<streem.api.GetUserCallLogEntryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#updateUserCallLogEntry}.
             * @memberof streem.api.Users
             * @typedef UpdateUserCallLogEntryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateUserCallLogEntryResponse} [response] UpdateUserCallLogEntryResponse
             */

            /**
             * Calls UpdateUserCallLogEntry.
             * @function updateUserCallLogEntry
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserCallLogEntryRequest} request UpdateUserCallLogEntryRequest message or plain object
             * @param {streem.api.Users.UpdateUserCallLogEntryCallback} callback Node-style callback called with the error, if any, and UpdateUserCallLogEntryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.updateUserCallLogEntry = function updateUserCallLogEntry(request, callback) {
                return this.rpcCall(updateUserCallLogEntry, $root.streem.api.UpdateUserCallLogEntryRequest, $root.streem.api.UpdateUserCallLogEntryResponse, request, callback);
            }, "name", { value: "UpdateUserCallLogEntry" });

            /**
             * Calls UpdateUserCallLogEntry.
             * @function updateUserCallLogEntry
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserCallLogEntryRequest} request UpdateUserCallLogEntryRequest message or plain object
             * @returns {Promise<streem.api.UpdateUserCallLogEntryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#listUserDevices}.
             * @memberof streem.api.Users
             * @typedef ListUserDevicesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListUserDevicesResponse} [response] ListUserDevicesResponse
             */

            /**
             * Calls ListUserDevices.
             * @function listUserDevices
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListUserDevicesRequest} request ListUserDevicesRequest message or plain object
             * @param {streem.api.Users.ListUserDevicesCallback} callback Node-style callback called with the error, if any, and ListUserDevicesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.listUserDevices = function listUserDevices(request, callback) {
                return this.rpcCall(listUserDevices, $root.streem.api.ListUserDevicesRequest, $root.streem.api.ListUserDevicesResponse, request, callback);
            }, "name", { value: "ListUserDevices" });

            /**
             * Calls ListUserDevices.
             * @function listUserDevices
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IListUserDevicesRequest} request ListUserDevicesRequest message or plain object
             * @returns {Promise<streem.api.ListUserDevicesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#activateUser}.
             * @memberof streem.api.Users
             * @typedef ActivateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ActivateUser.
             * @function activateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IActivateUserRequest} request ActivateUserRequest message or plain object
             * @param {streem.api.Users.ActivateUserCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.activateUser = function activateUser(request, callback) {
                return this.rpcCall(activateUser, $root.streem.api.ActivateUserRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ActivateUser" });

            /**
             * Calls ActivateUser.
             * @function activateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IActivateUserRequest} request ActivateUserRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#deactivateUser}.
             * @memberof streem.api.Users
             * @typedef DeactivateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeactivateUser.
             * @function deactivateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IDeactivateUserRequest} request DeactivateUserRequest message or plain object
             * @param {streem.api.Users.DeactivateUserCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.deactivateUser = function deactivateUser(request, callback) {
                return this.rpcCall(deactivateUser, $root.streem.api.DeactivateUserRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeactivateUser" });

            /**
             * Calls DeactivateUser.
             * @function deactivateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IDeactivateUserRequest} request DeactivateUserRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getAssociatedCompanies}.
             * @memberof streem.api.Users
             * @typedef GetAssociatedCompaniesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetAssociatedCompaniesResponse} [response] GetAssociatedCompaniesResponse
             */

            /**
             * Calls GetAssociatedCompanies.
             * @function getAssociatedCompanies
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetAssociatedCompaniesRequest} request GetAssociatedCompaniesRequest message or plain object
             * @param {streem.api.Users.GetAssociatedCompaniesCallback} callback Node-style callback called with the error, if any, and GetAssociatedCompaniesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getAssociatedCompanies = function getAssociatedCompanies(request, callback) {
                return this.rpcCall(getAssociatedCompanies, $root.streem.api.GetAssociatedCompaniesRequest, $root.streem.api.GetAssociatedCompaniesResponse, request, callback);
            }, "name", { value: "GetAssociatedCompanies" });

            /**
             * Calls GetAssociatedCompanies.
             * @function getAssociatedCompanies
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetAssociatedCompaniesRequest} request GetAssociatedCompaniesRequest message or plain object
             * @returns {Promise<streem.api.GetAssociatedCompaniesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getUserTermsStatus}.
             * @memberof streem.api.Users
             * @typedef GetUserTermsStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserTermsStatusResponse} [response] GetUserTermsStatusResponse
             */

            /**
             * Calls GetUserTermsStatus.
             * @function getUserTermsStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserTermsStatusRequest} request GetUserTermsStatusRequest message or plain object
             * @param {streem.api.Users.GetUserTermsStatusCallback} callback Node-style callback called with the error, if any, and GetUserTermsStatusResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getUserTermsStatus = function getUserTermsStatus(request, callback) {
                return this.rpcCall(getUserTermsStatus, $root.streem.api.GetUserTermsStatusRequest, $root.streem.api.GetUserTermsStatusResponse, request, callback);
            }, "name", { value: "GetUserTermsStatus" });

            /**
             * Calls GetUserTermsStatus.
             * @function getUserTermsStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetUserTermsStatusRequest} request GetUserTermsStatusRequest message or plain object
             * @returns {Promise<streem.api.GetUserTermsStatusResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#saveUserTermsStatus}.
             * @memberof streem.api.Users
             * @typedef SaveUserTermsStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveUserTermsStatusResponse} [response] SaveUserTermsStatusResponse
             */

            /**
             * Calls SaveUserTermsStatus.
             * @function saveUserTermsStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ISaveUserTermsStatusRequest} request SaveUserTermsStatusRequest message or plain object
             * @param {streem.api.Users.SaveUserTermsStatusCallback} callback Node-style callback called with the error, if any, and SaveUserTermsStatusResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.saveUserTermsStatus = function saveUserTermsStatus(request, callback) {
                return this.rpcCall(saveUserTermsStatus, $root.streem.api.SaveUserTermsStatusRequest, $root.streem.api.SaveUserTermsStatusResponse, request, callback);
            }, "name", { value: "SaveUserTermsStatus" });

            /**
             * Calls SaveUserTermsStatus.
             * @function saveUserTermsStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ISaveUserTermsStatusRequest} request SaveUserTermsStatusRequest message or plain object
             * @returns {Promise<streem.api.SaveUserTermsStatusResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#updateUser}.
             * @memberof streem.api.Users
             * @typedef UpdateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateUserResponse} [response] UpdateUserResponse
             */

            /**
             * Calls UpdateUser.
             * @function updateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserRequest} request UpdateUserRequest message or plain object
             * @param {streem.api.Users.UpdateUserCallback} callback Node-style callback called with the error, if any, and UpdateUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.updateUser = function updateUser(request, callback) {
                return this.rpcCall(updateUser, $root.streem.api.UpdateUserRequest, $root.streem.api.UpdateUserResponse, request, callback);
            }, "name", { value: "UpdateUser" });

            /**
             * Calls UpdateUser.
             * @function updateUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserRequest} request UpdateUserRequest message or plain object
             * @returns {Promise<streem.api.UpdateUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#updateGroupsForUser}.
             * @memberof streem.api.Users
             * @typedef UpdateGroupsForUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateGroupsForUserResponse} [response] UpdateGroupsForUserResponse
             */

            /**
             * Calls UpdateGroupsForUser.
             * @function updateGroupsForUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateGroupsForUserRequest} request UpdateGroupsForUserRequest message or plain object
             * @param {streem.api.Users.UpdateGroupsForUserCallback} callback Node-style callback called with the error, if any, and UpdateGroupsForUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.updateGroupsForUser = function updateGroupsForUser(request, callback) {
                return this.rpcCall(updateGroupsForUser, $root.streem.api.UpdateGroupsForUserRequest, $root.streem.api.UpdateGroupsForUserResponse, request, callback);
            }, "name", { value: "UpdateGroupsForUser" });

            /**
             * Calls UpdateGroupsForUser.
             * @function updateGroupsForUser
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateGroupsForUserRequest} request UpdateGroupsForUserRequest message or plain object
             * @returns {Promise<streem.api.UpdateGroupsForUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#updateUserEmail}.
             * @memberof streem.api.Users
             * @typedef UpdateUserEmailCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateUserEmailResponse} [response] UpdateUserEmailResponse
             */

            /**
             * Calls UpdateUserEmail.
             * @function updateUserEmail
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserEmailRequest} request UpdateUserEmailRequest message or plain object
             * @param {streem.api.Users.UpdateUserEmailCallback} callback Node-style callback called with the error, if any, and UpdateUserEmailResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.updateUserEmail = function updateUserEmail(request, callback) {
                return this.rpcCall(updateUserEmail, $root.streem.api.UpdateUserEmailRequest, $root.streem.api.UpdateUserEmailResponse, request, callback);
            }, "name", { value: "UpdateUserEmail" });

            /**
             * Calls UpdateUserEmail.
             * @function updateUserEmail
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IUpdateUserEmailRequest} request UpdateUserEmailRequest message or plain object
             * @returns {Promise<streem.api.UpdateUserEmailResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#createUserAsset}.
             * @memberof streem.api.Users
             * @typedef CreateUserAssetCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateUserAssetResponse} [response] CreateUserAssetResponse
             */

            /**
             * Calls CreateUserAsset.
             * @function createUserAsset
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserAssetRequest} request CreateUserAssetRequest message or plain object
             * @param {streem.api.Users.CreateUserAssetCallback} callback Node-style callback called with the error, if any, and CreateUserAssetResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.createUserAsset = function createUserAsset(request, callback) {
                return this.rpcCall(createUserAsset, $root.streem.api.CreateUserAssetRequest, $root.streem.api.CreateUserAssetResponse, request, callback);
            }, "name", { value: "CreateUserAsset" });

            /**
             * Calls CreateUserAsset.
             * @function createUserAsset
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ICreateUserAssetRequest} request CreateUserAssetRequest message or plain object
             * @returns {Promise<streem.api.CreateUserAssetResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#getStreemSurveyStatus}.
             * @memberof streem.api.Users
             * @typedef GetStreemSurveyStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetStreemSurveyStatusResponse} [response] GetStreemSurveyStatusResponse
             */

            /**
             * Calls GetStreemSurveyStatus.
             * @function getStreemSurveyStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetStreemSurveyStatusRequest} request GetStreemSurveyStatusRequest message or plain object
             * @param {streem.api.Users.GetStreemSurveyStatusCallback} callback Node-style callback called with the error, if any, and GetStreemSurveyStatusResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.getStreemSurveyStatus = function getStreemSurveyStatus(request, callback) {
                return this.rpcCall(getStreemSurveyStatus, $root.streem.api.GetStreemSurveyStatusRequest, $root.streem.api.GetStreemSurveyStatusResponse, request, callback);
            }, "name", { value: "GetStreemSurveyStatus" });

            /**
             * Calls GetStreemSurveyStatus.
             * @function getStreemSurveyStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.IGetStreemSurveyStatusRequest} request GetStreemSurveyStatusRequest message or plain object
             * @returns {Promise<streem.api.GetStreemSurveyStatusResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Users#saveStreemSurveyStatus}.
             * @memberof streem.api.Users
             * @typedef SaveStreemSurveyStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls SaveStreemSurveyStatus.
             * @function saveStreemSurveyStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ISaveStreemSurveyStatusRequest} request SaveStreemSurveyStatusRequest message or plain object
             * @param {streem.api.Users.SaveStreemSurveyStatusCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Users.prototype.saveStreemSurveyStatus = function saveStreemSurveyStatus(request, callback) {
                return this.rpcCall(saveStreemSurveyStatus, $root.streem.api.SaveStreemSurveyStatusRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "SaveStreemSurveyStatus" });

            /**
             * Calls SaveStreemSurveyStatus.
             * @function saveStreemSurveyStatus
             * @memberof streem.api.Users
             * @instance
             * @param {streem.api.ISaveStreemSurveyStatusRequest} request SaveStreemSurveyStatusRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Users;
        })();

        api.ListRecentlyIdentifiedUsersRequest = (function() {

            /**
             * Properties of a ListRecentlyIdentifiedUsersRequest.
             * @memberof streem.api
             * @interface IListRecentlyIdentifiedUsersRequest
             * @property {boolean|null} [isExpert] ListRecentlyIdentifiedUsersRequest isExpert
             * @property {number|null} [pageSize] ListRecentlyIdentifiedUsersRequest pageSize
             * @property {string|null} [pageToken] ListRecentlyIdentifiedUsersRequest pageToken
             */

            /**
             * Constructs a new ListRecentlyIdentifiedUsersRequest.
             * @memberof streem.api
             * @classdesc Represents a ListRecentlyIdentifiedUsersRequest.
             * @implements IListRecentlyIdentifiedUsersRequest
             * @constructor
             * @param {streem.api.IListRecentlyIdentifiedUsersRequest=} [p] Properties to set
             */
            function ListRecentlyIdentifiedUsersRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRecentlyIdentifiedUsersRequest isExpert.
             * @member {boolean} isExpert
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @instance
             */
            ListRecentlyIdentifiedUsersRequest.prototype.isExpert = false;

            /**
             * ListRecentlyIdentifiedUsersRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @instance
             */
            ListRecentlyIdentifiedUsersRequest.prototype.pageSize = 0;

            /**
             * ListRecentlyIdentifiedUsersRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @instance
             */
            ListRecentlyIdentifiedUsersRequest.prototype.pageToken = "";

            /**
             * Creates a new ListRecentlyIdentifiedUsersRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {streem.api.IListRecentlyIdentifiedUsersRequest=} [properties] Properties to set
             * @returns {streem.api.ListRecentlyIdentifiedUsersRequest} ListRecentlyIdentifiedUsersRequest instance
             */
            ListRecentlyIdentifiedUsersRequest.create = function create(properties) {
                return new ListRecentlyIdentifiedUsersRequest(properties);
            };

            /**
             * Encodes the specified ListRecentlyIdentifiedUsersRequest message. Does not implicitly {@link streem.api.ListRecentlyIdentifiedUsersRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {streem.api.IListRecentlyIdentifiedUsersRequest} m ListRecentlyIdentifiedUsersRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRecentlyIdentifiedUsersRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.isExpert != null && Object.hasOwnProperty.call(m, "isExpert"))
                    w.uint32(8).bool(m.isExpert);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListRecentlyIdentifiedUsersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRecentlyIdentifiedUsersRequest} ListRecentlyIdentifiedUsersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRecentlyIdentifiedUsersRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRecentlyIdentifiedUsersRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.isExpert = r.bool();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRecentlyIdentifiedUsersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRecentlyIdentifiedUsersRequest} ListRecentlyIdentifiedUsersRequest
             */
            ListRecentlyIdentifiedUsersRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRecentlyIdentifiedUsersRequest)
                    return d;
                var m = new $root.streem.api.ListRecentlyIdentifiedUsersRequest();
                if (d.isExpert != null) {
                    m.isExpert = Boolean(d.isExpert);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRecentlyIdentifiedUsersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {streem.api.ListRecentlyIdentifiedUsersRequest} m ListRecentlyIdentifiedUsersRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRecentlyIdentifiedUsersRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.isExpert = false;
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.isExpert != null && m.hasOwnProperty("isExpert")) {
                    d.isExpert = m.isExpert;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListRecentlyIdentifiedUsersRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRecentlyIdentifiedUsersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRecentlyIdentifiedUsersRequest
             * @function getTypeUrl
             * @memberof streem.api.ListRecentlyIdentifiedUsersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRecentlyIdentifiedUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRecentlyIdentifiedUsersRequest";
            };

            return ListRecentlyIdentifiedUsersRequest;
        })();

        api.ListRecentlyIdentifiedUsersResponse = (function() {

            /**
             * Properties of a ListRecentlyIdentifiedUsersResponse.
             * @memberof streem.api
             * @interface IListRecentlyIdentifiedUsersResponse
             * @property {Array.<streem.api.IUser>|null} [users] ListRecentlyIdentifiedUsersResponse users
             * @property {string|null} [nextPageToken] ListRecentlyIdentifiedUsersResponse nextPageToken
             * @property {number|null} [totalSize] ListRecentlyIdentifiedUsersResponse totalSize
             */

            /**
             * Constructs a new ListRecentlyIdentifiedUsersResponse.
             * @memberof streem.api
             * @classdesc Represents a ListRecentlyIdentifiedUsersResponse.
             * @implements IListRecentlyIdentifiedUsersResponse
             * @constructor
             * @param {streem.api.IListRecentlyIdentifiedUsersResponse=} [p] Properties to set
             */
            function ListRecentlyIdentifiedUsersResponse(p) {
                this.users = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListRecentlyIdentifiedUsersResponse users.
             * @member {Array.<streem.api.IUser>} users
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @instance
             */
            ListRecentlyIdentifiedUsersResponse.prototype.users = $util.emptyArray;

            /**
             * ListRecentlyIdentifiedUsersResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @instance
             */
            ListRecentlyIdentifiedUsersResponse.prototype.nextPageToken = "";

            /**
             * ListRecentlyIdentifiedUsersResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @instance
             */
            ListRecentlyIdentifiedUsersResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListRecentlyIdentifiedUsersResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {streem.api.IListRecentlyIdentifiedUsersResponse=} [properties] Properties to set
             * @returns {streem.api.ListRecentlyIdentifiedUsersResponse} ListRecentlyIdentifiedUsersResponse instance
             */
            ListRecentlyIdentifiedUsersResponse.create = function create(properties) {
                return new ListRecentlyIdentifiedUsersResponse(properties);
            };

            /**
             * Encodes the specified ListRecentlyIdentifiedUsersResponse message. Does not implicitly {@link streem.api.ListRecentlyIdentifiedUsersResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {streem.api.IListRecentlyIdentifiedUsersResponse} m ListRecentlyIdentifiedUsersResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListRecentlyIdentifiedUsersResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.users != null && m.users.length) {
                    for (var i = 0; i < m.users.length; ++i)
                        $root.streem.api.User.encode(m.users[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListRecentlyIdentifiedUsersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListRecentlyIdentifiedUsersResponse} ListRecentlyIdentifiedUsersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListRecentlyIdentifiedUsersResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListRecentlyIdentifiedUsersResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.users && m.users.length))
                                m.users = [];
                            m.users.push($root.streem.api.User.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListRecentlyIdentifiedUsersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListRecentlyIdentifiedUsersResponse} ListRecentlyIdentifiedUsersResponse
             */
            ListRecentlyIdentifiedUsersResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListRecentlyIdentifiedUsersResponse)
                    return d;
                var m = new $root.streem.api.ListRecentlyIdentifiedUsersResponse();
                if (d.users) {
                    if (!Array.isArray(d.users))
                        throw TypeError(".streem.api.ListRecentlyIdentifiedUsersResponse.users: array expected");
                    m.users = [];
                    for (var i = 0; i < d.users.length; ++i) {
                        if (typeof d.users[i] !== "object")
                            throw TypeError(".streem.api.ListRecentlyIdentifiedUsersResponse.users: object expected");
                        m.users[i] = $root.streem.api.User.fromObject(d.users[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListRecentlyIdentifiedUsersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {streem.api.ListRecentlyIdentifiedUsersResponse} m ListRecentlyIdentifiedUsersResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListRecentlyIdentifiedUsersResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.users = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.users && m.users.length) {
                    d.users = [];
                    for (var j = 0; j < m.users.length; ++j) {
                        d.users[j] = $root.streem.api.User.toObject(m.users[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListRecentlyIdentifiedUsersResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListRecentlyIdentifiedUsersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListRecentlyIdentifiedUsersResponse
             * @function getTypeUrl
             * @memberof streem.api.ListRecentlyIdentifiedUsersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListRecentlyIdentifiedUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListRecentlyIdentifiedUsersResponse";
            };

            return ListRecentlyIdentifiedUsersResponse;
        })();

        api.GetUserRequest = (function() {

            /**
             * Properties of a GetUserRequest.
             * @memberof streem.api
             * @interface IGetUserRequest
             * @property {string|null} [userSid] GetUserRequest userSid
             */

            /**
             * Constructs a new GetUserRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserRequest.
             * @implements IGetUserRequest
             * @constructor
             * @param {streem.api.IGetUserRequest=} [p] Properties to set
             */
            function GetUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetUserRequest
             * @instance
             */
            GetUserRequest.prototype.userSid = "";

            /**
             * Creates a new GetUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {streem.api.IGetUserRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserRequest} GetUserRequest instance
             */
            GetUserRequest.create = function create(properties) {
                return new GetUserRequest(properties);
            };

            /**
             * Encodes the specified GetUserRequest message. Does not implicitly {@link streem.api.GetUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {streem.api.IGetUserRequest} m GetUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a GetUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserRequest} GetUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserRequest} GetUserRequest
             */
            GetUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserRequest)
                    return d;
                var m = new $root.streem.api.GetUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {streem.api.GetUserRequest} m GetUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this GetUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserRequest";
            };

            return GetUserRequest;
        })();

        api.GetUserResponse = (function() {

            /**
             * Properties of a GetUserResponse.
             * @memberof streem.api
             * @interface IGetUserResponse
             * @property {streem.api.IUser|null} [user] GetUserResponse user
             */

            /**
             * Constructs a new GetUserResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserResponse.
             * @implements IGetUserResponse
             * @constructor
             * @param {streem.api.IGetUserResponse=} [p] Properties to set
             */
            function GetUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.GetUserResponse
             * @instance
             */
            GetUserResponse.prototype.user = null;

            /**
             * Creates a new GetUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {streem.api.IGetUserResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserResponse} GetUserResponse instance
             */
            GetUserResponse.create = function create(properties) {
                return new GetUserResponse(properties);
            };

            /**
             * Encodes the specified GetUserResponse message. Does not implicitly {@link streem.api.GetUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {streem.api.IGetUserResponse} m GetUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserResponse} GetUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserResponse} GetUserResponse
             */
            GetUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserResponse)
                    return d;
                var m = new $root.streem.api.GetUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.GetUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {streem.api.GetUserResponse} m GetUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this GetUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserResponse";
            };

            return GetUserResponse;
        })();

        api.IdentifyUserRequest = (function() {

            /**
             * Properties of an IdentifyUserRequest.
             * @memberof streem.api
             * @interface IIdentifyUserRequest
             * @property {string|null} [userSid] IdentifyUserRequest userSid
             * @property {string|null} [deviceSid] IdentifyUserRequest deviceSid
             * @property {boolean|null} [isExpert] IdentifyUserRequest isExpert
             * @property {string|null} [email] IdentifyUserRequest email
             * @property {string|null} [name] IdentifyUserRequest name
             * @property {string|null} [avatarUrl] IdentifyUserRequest avatarUrl
             * @property {string|null} [phone] IdentifyUserRequest phone
             */

            /**
             * Constructs a new IdentifyUserRequest.
             * @memberof streem.api
             * @classdesc Represents an IdentifyUserRequest.
             * @implements IIdentifyUserRequest
             * @constructor
             * @param {streem.api.IIdentifyUserRequest=} [p] Properties to set
             */
            function IdentifyUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * IdentifyUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.userSid = "";

            /**
             * IdentifyUserRequest deviceSid.
             * @member {string} deviceSid
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.deviceSid = "";

            /**
             * IdentifyUserRequest isExpert.
             * @member {boolean} isExpert
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.isExpert = false;

            /**
             * IdentifyUserRequest email.
             * @member {string} email
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.email = "";

            /**
             * IdentifyUserRequest name.
             * @member {string} name
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.name = "";

            /**
             * IdentifyUserRequest avatarUrl.
             * @member {string} avatarUrl
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.avatarUrl = "";

            /**
             * IdentifyUserRequest phone.
             * @member {string} phone
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             */
            IdentifyUserRequest.prototype.phone = "";

            /**
             * Creates a new IdentifyUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {streem.api.IIdentifyUserRequest=} [properties] Properties to set
             * @returns {streem.api.IdentifyUserRequest} IdentifyUserRequest instance
             */
            IdentifyUserRequest.create = function create(properties) {
                return new IdentifyUserRequest(properties);
            };

            /**
             * Encodes the specified IdentifyUserRequest message. Does not implicitly {@link streem.api.IdentifyUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {streem.api.IIdentifyUserRequest} m IdentifyUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IdentifyUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                    w.uint32(18).string(m.deviceSid);
                if (m.isExpert != null && Object.hasOwnProperty.call(m, "isExpert"))
                    w.uint32(24).bool(m.isExpert);
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(34).string(m.email);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(42).string(m.name);
                if (m.avatarUrl != null && Object.hasOwnProperty.call(m, "avatarUrl"))
                    w.uint32(50).string(m.avatarUrl);
                if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                    w.uint32(58).string(m.phone);
                return w;
            };

            /**
             * Decodes an IdentifyUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.IdentifyUserRequest} IdentifyUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IdentifyUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.IdentifyUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.deviceSid = r.string();
                            break;
                        }
                    case 3: {
                            m.isExpert = r.bool();
                            break;
                        }
                    case 4: {
                            m.email = r.string();
                            break;
                        }
                    case 5: {
                            m.name = r.string();
                            break;
                        }
                    case 6: {
                            m.avatarUrl = r.string();
                            break;
                        }
                    case 7: {
                            m.phone = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an IdentifyUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.IdentifyUserRequest} IdentifyUserRequest
             */
            IdentifyUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.IdentifyUserRequest)
                    return d;
                var m = new $root.streem.api.IdentifyUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.deviceSid != null) {
                    m.deviceSid = String(d.deviceSid);
                }
                if (d.isExpert != null) {
                    m.isExpert = Boolean(d.isExpert);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.avatarUrl != null) {
                    m.avatarUrl = String(d.avatarUrl);
                }
                if (d.phone != null) {
                    m.phone = String(d.phone);
                }
                return m;
            };

            /**
             * Creates a plain object from an IdentifyUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {streem.api.IdentifyUserRequest} m IdentifyUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IdentifyUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.deviceSid = "";
                    d.isExpert = false;
                    d.email = "";
                    d.name = "";
                    d.avatarUrl = "";
                    d.phone = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                    d.deviceSid = m.deviceSid;
                }
                if (m.isExpert != null && m.hasOwnProperty("isExpert")) {
                    d.isExpert = m.isExpert;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.avatarUrl != null && m.hasOwnProperty("avatarUrl")) {
                    d.avatarUrl = m.avatarUrl;
                }
                if (m.phone != null && m.hasOwnProperty("phone")) {
                    d.phone = m.phone;
                }
                return d;
            };

            /**
             * Converts this IdentifyUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.IdentifyUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IdentifyUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for IdentifyUserRequest
             * @function getTypeUrl
             * @memberof streem.api.IdentifyUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            IdentifyUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.IdentifyUserRequest";
            };

            return IdentifyUserRequest;
        })();

        api.IdentifyUserResponse = (function() {

            /**
             * Properties of an IdentifyUserResponse.
             * @memberof streem.api
             * @interface IIdentifyUserResponse
             * @property {streem.api.IUser|null} [user] IdentifyUserResponse user
             */

            /**
             * Constructs a new IdentifyUserResponse.
             * @memberof streem.api
             * @classdesc Represents an IdentifyUserResponse.
             * @implements IIdentifyUserResponse
             * @constructor
             * @param {streem.api.IIdentifyUserResponse=} [p] Properties to set
             */
            function IdentifyUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * IdentifyUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.IdentifyUserResponse
             * @instance
             */
            IdentifyUserResponse.prototype.user = null;

            /**
             * Creates a new IdentifyUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {streem.api.IIdentifyUserResponse=} [properties] Properties to set
             * @returns {streem.api.IdentifyUserResponse} IdentifyUserResponse instance
             */
            IdentifyUserResponse.create = function create(properties) {
                return new IdentifyUserResponse(properties);
            };

            /**
             * Encodes the specified IdentifyUserResponse message. Does not implicitly {@link streem.api.IdentifyUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {streem.api.IIdentifyUserResponse} m IdentifyUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IdentifyUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an IdentifyUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.IdentifyUserResponse} IdentifyUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IdentifyUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.IdentifyUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an IdentifyUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.IdentifyUserResponse} IdentifyUserResponse
             */
            IdentifyUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.IdentifyUserResponse)
                    return d;
                var m = new $root.streem.api.IdentifyUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.IdentifyUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from an IdentifyUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {streem.api.IdentifyUserResponse} m IdentifyUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IdentifyUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this IdentifyUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.IdentifyUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IdentifyUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for IdentifyUserResponse
             * @function getTypeUrl
             * @memberof streem.api.IdentifyUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            IdentifyUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.IdentifyUserResponse";
            };

            return IdentifyUserResponse;
        })();

        api.BatchCreateUserRequest = (function() {

            /**
             * Properties of a BatchCreateUserRequest.
             * @memberof streem.api
             * @interface IBatchCreateUserRequest
             * @property {string|null} [csvData] BatchCreateUserRequest csvData
             */

            /**
             * Constructs a new BatchCreateUserRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchCreateUserRequest.
             * @implements IBatchCreateUserRequest
             * @constructor
             * @param {streem.api.IBatchCreateUserRequest=} [p] Properties to set
             */
            function BatchCreateUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchCreateUserRequest csvData.
             * @member {string} csvData
             * @memberof streem.api.BatchCreateUserRequest
             * @instance
             */
            BatchCreateUserRequest.prototype.csvData = "";

            /**
             * Creates a new BatchCreateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {streem.api.IBatchCreateUserRequest=} [properties] Properties to set
             * @returns {streem.api.BatchCreateUserRequest} BatchCreateUserRequest instance
             */
            BatchCreateUserRequest.create = function create(properties) {
                return new BatchCreateUserRequest(properties);
            };

            /**
             * Encodes the specified BatchCreateUserRequest message. Does not implicitly {@link streem.api.BatchCreateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {streem.api.IBatchCreateUserRequest} m BatchCreateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchCreateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.csvData != null && Object.hasOwnProperty.call(m, "csvData"))
                    w.uint32(10).string(m.csvData);
                return w;
            };

            /**
             * Decodes a BatchCreateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchCreateUserRequest} BatchCreateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchCreateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.csvData = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchCreateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchCreateUserRequest} BatchCreateUserRequest
             */
            BatchCreateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchCreateUserRequest)
                    return d;
                var m = new $root.streem.api.BatchCreateUserRequest();
                if (d.csvData != null) {
                    m.csvData = String(d.csvData);
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchCreateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {streem.api.BatchCreateUserRequest} m BatchCreateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchCreateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.csvData = "";
                }
                if (m.csvData != null && m.hasOwnProperty("csvData")) {
                    d.csvData = m.csvData;
                }
                return d;
            };

            /**
             * Converts this BatchCreateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchCreateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchCreateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchCreateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchCreateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchCreateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchCreateUserRequest";
            };

            return BatchCreateUserRequest;
        })();

        api.BatchCreateUserResponse = (function() {

            /**
             * Properties of a BatchCreateUserResponse.
             * @memberof streem.api
             * @interface IBatchCreateUserResponse
             * @property {Array.<streem.api.ICreateCompanyUserResponse>|null} [created] BatchCreateUserResponse created
             * @property {Array.<streem.api.BatchCreateUserResponse.IFailure>|null} [failed] BatchCreateUserResponse failed
             */

            /**
             * Constructs a new BatchCreateUserResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchCreateUserResponse.
             * @implements IBatchCreateUserResponse
             * @constructor
             * @param {streem.api.IBatchCreateUserResponse=} [p] Properties to set
             */
            function BatchCreateUserResponse(p) {
                this.created = [];
                this.failed = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchCreateUserResponse created.
             * @member {Array.<streem.api.ICreateCompanyUserResponse>} created
             * @memberof streem.api.BatchCreateUserResponse
             * @instance
             */
            BatchCreateUserResponse.prototype.created = $util.emptyArray;

            /**
             * BatchCreateUserResponse failed.
             * @member {Array.<streem.api.BatchCreateUserResponse.IFailure>} failed
             * @memberof streem.api.BatchCreateUserResponse
             * @instance
             */
            BatchCreateUserResponse.prototype.failed = $util.emptyArray;

            /**
             * Creates a new BatchCreateUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {streem.api.IBatchCreateUserResponse=} [properties] Properties to set
             * @returns {streem.api.BatchCreateUserResponse} BatchCreateUserResponse instance
             */
            BatchCreateUserResponse.create = function create(properties) {
                return new BatchCreateUserResponse(properties);
            };

            /**
             * Encodes the specified BatchCreateUserResponse message. Does not implicitly {@link streem.api.BatchCreateUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {streem.api.IBatchCreateUserResponse} m BatchCreateUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchCreateUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.created != null && m.created.length) {
                    for (var i = 0; i < m.created.length; ++i)
                        $root.streem.api.CreateCompanyUserResponse.encode(m.created[i], w.uint32(10).fork()).ldelim();
                }
                if (m.failed != null && m.failed.length) {
                    for (var i = 0; i < m.failed.length; ++i)
                        $root.streem.api.BatchCreateUserResponse.Failure.encode(m.failed[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchCreateUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchCreateUserResponse} BatchCreateUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchCreateUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.created && m.created.length))
                                m.created = [];
                            m.created.push($root.streem.api.CreateCompanyUserResponse.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(m.failed && m.failed.length))
                                m.failed = [];
                            m.failed.push($root.streem.api.BatchCreateUserResponse.Failure.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchCreateUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchCreateUserResponse} BatchCreateUserResponse
             */
            BatchCreateUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchCreateUserResponse)
                    return d;
                var m = new $root.streem.api.BatchCreateUserResponse();
                if (d.created) {
                    if (!Array.isArray(d.created))
                        throw TypeError(".streem.api.BatchCreateUserResponse.created: array expected");
                    m.created = [];
                    for (var i = 0; i < d.created.length; ++i) {
                        if (typeof d.created[i] !== "object")
                            throw TypeError(".streem.api.BatchCreateUserResponse.created: object expected");
                        m.created[i] = $root.streem.api.CreateCompanyUserResponse.fromObject(d.created[i]);
                    }
                }
                if (d.failed) {
                    if (!Array.isArray(d.failed))
                        throw TypeError(".streem.api.BatchCreateUserResponse.failed: array expected");
                    m.failed = [];
                    for (var i = 0; i < d.failed.length; ++i) {
                        if (typeof d.failed[i] !== "object")
                            throw TypeError(".streem.api.BatchCreateUserResponse.failed: object expected");
                        m.failed[i] = $root.streem.api.BatchCreateUserResponse.Failure.fromObject(d.failed[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchCreateUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {streem.api.BatchCreateUserResponse} m BatchCreateUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchCreateUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.created = [];
                    d.failed = [];
                }
                if (m.created && m.created.length) {
                    d.created = [];
                    for (var j = 0; j < m.created.length; ++j) {
                        d.created[j] = $root.streem.api.CreateCompanyUserResponse.toObject(m.created[j], o);
                    }
                }
                if (m.failed && m.failed.length) {
                    d.failed = [];
                    for (var j = 0; j < m.failed.length; ++j) {
                        d.failed[j] = $root.streem.api.BatchCreateUserResponse.Failure.toObject(m.failed[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchCreateUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchCreateUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchCreateUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchCreateUserResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchCreateUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchCreateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchCreateUserResponse";
            };

            BatchCreateUserResponse.Failure = (function() {

                /**
                 * Properties of a Failure.
                 * @memberof streem.api.BatchCreateUserResponse
                 * @interface IFailure
                 * @property {streem.api.IErrorResponse|null} [error] Failure error
                 * @property {string|null} [name] Failure name
                 * @property {string|null} [email] Failure email
                 * @property {string|null} [companyCode] Failure companyCode
                 */

                /**
                 * Constructs a new Failure.
                 * @memberof streem.api.BatchCreateUserResponse
                 * @classdesc Represents a Failure.
                 * @implements IFailure
                 * @constructor
                 * @param {streem.api.BatchCreateUserResponse.IFailure=} [p] Properties to set
                 */
                function Failure(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Failure error.
                 * @member {streem.api.IErrorResponse|null|undefined} error
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.error = null;

                /**
                 * Failure name.
                 * @member {string} name
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.name = "";

                /**
                 * Failure email.
                 * @member {string} email
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.email = "";

                /**
                 * Failure companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.companyCode = "";

                /**
                 * Creates a new Failure instance using the specified properties.
                 * @function create
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateUserResponse.IFailure=} [properties] Properties to set
                 * @returns {streem.api.BatchCreateUserResponse.Failure} Failure instance
                 */
                Failure.create = function create(properties) {
                    return new Failure(properties);
                };

                /**
                 * Encodes the specified Failure message. Does not implicitly {@link streem.api.BatchCreateUserResponse.Failure.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateUserResponse.IFailure} m Failure message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Failure.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                        $root.streem.api.ErrorResponse.encode(m.error, w.uint32(10).fork()).ldelim();
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(18).string(m.name);
                    if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                        w.uint32(26).string(m.email);
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(34).string(m.companyCode);
                    return w;
                };

                /**
                 * Decodes a Failure message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.BatchCreateUserResponse.Failure} Failure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Failure.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateUserResponse.Failure();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.error = $root.streem.api.ErrorResponse.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.name = r.string();
                                break;
                            }
                        case 3: {
                                m.email = r.string();
                                break;
                            }
                        case 4: {
                                m.companyCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Failure message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.BatchCreateUserResponse.Failure} Failure
                 */
                Failure.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.BatchCreateUserResponse.Failure)
                        return d;
                    var m = new $root.streem.api.BatchCreateUserResponse.Failure();
                    if (d.error != null) {
                        if (typeof d.error !== "object")
                            throw TypeError(".streem.api.BatchCreateUserResponse.Failure.error: object expected");
                        m.error = $root.streem.api.ErrorResponse.fromObject(d.error);
                    }
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    if (d.email != null) {
                        m.email = String(d.email);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Failure message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateUserResponse.Failure} m Failure
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Failure.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.error = null;
                        d.name = "";
                        d.email = "";
                        d.companyCode = "";
                    }
                    if (m.error != null && m.hasOwnProperty("error")) {
                        d.error = $root.streem.api.ErrorResponse.toObject(m.error, o);
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    if (m.email != null && m.hasOwnProperty("email")) {
                        d.email = m.email;
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    return d;
                };

                /**
                 * Converts this Failure to JSON.
                 * @function toJSON
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Failure.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Failure
                 * @function getTypeUrl
                 * @memberof streem.api.BatchCreateUserResponse.Failure
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Failure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.BatchCreateUserResponse.Failure";
                };

                return Failure;
            })();

            return BatchCreateUserResponse;
        })();

        api.BatchDeactivateUserRequest = (function() {

            /**
             * Properties of a BatchDeactivateUserRequest.
             * @memberof streem.api
             * @interface IBatchDeactivateUserRequest
             * @property {string|null} [csvData] BatchDeactivateUserRequest csvData
             */

            /**
             * Constructs a new BatchDeactivateUserRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchDeactivateUserRequest.
             * @implements IBatchDeactivateUserRequest
             * @constructor
             * @param {streem.api.IBatchDeactivateUserRequest=} [p] Properties to set
             */
            function BatchDeactivateUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchDeactivateUserRequest csvData.
             * @member {string} csvData
             * @memberof streem.api.BatchDeactivateUserRequest
             * @instance
             */
            BatchDeactivateUserRequest.prototype.csvData = "";

            /**
             * Creates a new BatchDeactivateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {streem.api.IBatchDeactivateUserRequest=} [properties] Properties to set
             * @returns {streem.api.BatchDeactivateUserRequest} BatchDeactivateUserRequest instance
             */
            BatchDeactivateUserRequest.create = function create(properties) {
                return new BatchDeactivateUserRequest(properties);
            };

            /**
             * Encodes the specified BatchDeactivateUserRequest message. Does not implicitly {@link streem.api.BatchDeactivateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {streem.api.IBatchDeactivateUserRequest} m BatchDeactivateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchDeactivateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.csvData != null && Object.hasOwnProperty.call(m, "csvData"))
                    w.uint32(10).string(m.csvData);
                return w;
            };

            /**
             * Decodes a BatchDeactivateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchDeactivateUserRequest} BatchDeactivateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchDeactivateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchDeactivateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.csvData = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchDeactivateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchDeactivateUserRequest} BatchDeactivateUserRequest
             */
            BatchDeactivateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchDeactivateUserRequest)
                    return d;
                var m = new $root.streem.api.BatchDeactivateUserRequest();
                if (d.csvData != null) {
                    m.csvData = String(d.csvData);
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchDeactivateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {streem.api.BatchDeactivateUserRequest} m BatchDeactivateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchDeactivateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.csvData = "";
                }
                if (m.csvData != null && m.hasOwnProperty("csvData")) {
                    d.csvData = m.csvData;
                }
                return d;
            };

            /**
             * Converts this BatchDeactivateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchDeactivateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchDeactivateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchDeactivateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchDeactivateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchDeactivateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchDeactivateUserRequest";
            };

            return BatchDeactivateUserRequest;
        })();

        api.BatchDeactivateUserResponse = (function() {

            /**
             * Properties of a BatchDeactivateUserResponse.
             * @memberof streem.api
             * @interface IBatchDeactivateUserResponse
             * @property {Array.<streem.api.IDeactivateUserResponse>|null} [deactivated] BatchDeactivateUserResponse deactivated
             * @property {Array.<streem.api.BatchDeactivateUserResponse.IFailure>|null} [failed] BatchDeactivateUserResponse failed
             */

            /**
             * Constructs a new BatchDeactivateUserResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchDeactivateUserResponse.
             * @implements IBatchDeactivateUserResponse
             * @constructor
             * @param {streem.api.IBatchDeactivateUserResponse=} [p] Properties to set
             */
            function BatchDeactivateUserResponse(p) {
                this.deactivated = [];
                this.failed = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchDeactivateUserResponse deactivated.
             * @member {Array.<streem.api.IDeactivateUserResponse>} deactivated
             * @memberof streem.api.BatchDeactivateUserResponse
             * @instance
             */
            BatchDeactivateUserResponse.prototype.deactivated = $util.emptyArray;

            /**
             * BatchDeactivateUserResponse failed.
             * @member {Array.<streem.api.BatchDeactivateUserResponse.IFailure>} failed
             * @memberof streem.api.BatchDeactivateUserResponse
             * @instance
             */
            BatchDeactivateUserResponse.prototype.failed = $util.emptyArray;

            /**
             * Creates a new BatchDeactivateUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {streem.api.IBatchDeactivateUserResponse=} [properties] Properties to set
             * @returns {streem.api.BatchDeactivateUserResponse} BatchDeactivateUserResponse instance
             */
            BatchDeactivateUserResponse.create = function create(properties) {
                return new BatchDeactivateUserResponse(properties);
            };

            /**
             * Encodes the specified BatchDeactivateUserResponse message. Does not implicitly {@link streem.api.BatchDeactivateUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {streem.api.IBatchDeactivateUserResponse} m BatchDeactivateUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchDeactivateUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deactivated != null && m.deactivated.length) {
                    for (var i = 0; i < m.deactivated.length; ++i)
                        $root.streem.api.DeactivateUserResponse.encode(m.deactivated[i], w.uint32(10).fork()).ldelim();
                }
                if (m.failed != null && m.failed.length) {
                    for (var i = 0; i < m.failed.length; ++i)
                        $root.streem.api.BatchDeactivateUserResponse.Failure.encode(m.failed[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchDeactivateUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchDeactivateUserResponse} BatchDeactivateUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchDeactivateUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchDeactivateUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.deactivated && m.deactivated.length))
                                m.deactivated = [];
                            m.deactivated.push($root.streem.api.DeactivateUserResponse.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(m.failed && m.failed.length))
                                m.failed = [];
                            m.failed.push($root.streem.api.BatchDeactivateUserResponse.Failure.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchDeactivateUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchDeactivateUserResponse} BatchDeactivateUserResponse
             */
            BatchDeactivateUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchDeactivateUserResponse)
                    return d;
                var m = new $root.streem.api.BatchDeactivateUserResponse();
                if (d.deactivated) {
                    if (!Array.isArray(d.deactivated))
                        throw TypeError(".streem.api.BatchDeactivateUserResponse.deactivated: array expected");
                    m.deactivated = [];
                    for (var i = 0; i < d.deactivated.length; ++i) {
                        if (typeof d.deactivated[i] !== "object")
                            throw TypeError(".streem.api.BatchDeactivateUserResponse.deactivated: object expected");
                        m.deactivated[i] = $root.streem.api.DeactivateUserResponse.fromObject(d.deactivated[i]);
                    }
                }
                if (d.failed) {
                    if (!Array.isArray(d.failed))
                        throw TypeError(".streem.api.BatchDeactivateUserResponse.failed: array expected");
                    m.failed = [];
                    for (var i = 0; i < d.failed.length; ++i) {
                        if (typeof d.failed[i] !== "object")
                            throw TypeError(".streem.api.BatchDeactivateUserResponse.failed: object expected");
                        m.failed[i] = $root.streem.api.BatchDeactivateUserResponse.Failure.fromObject(d.failed[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchDeactivateUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {streem.api.BatchDeactivateUserResponse} m BatchDeactivateUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchDeactivateUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.deactivated = [];
                    d.failed = [];
                }
                if (m.deactivated && m.deactivated.length) {
                    d.deactivated = [];
                    for (var j = 0; j < m.deactivated.length; ++j) {
                        d.deactivated[j] = $root.streem.api.DeactivateUserResponse.toObject(m.deactivated[j], o);
                    }
                }
                if (m.failed && m.failed.length) {
                    d.failed = [];
                    for (var j = 0; j < m.failed.length; ++j) {
                        d.failed[j] = $root.streem.api.BatchDeactivateUserResponse.Failure.toObject(m.failed[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchDeactivateUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchDeactivateUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchDeactivateUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchDeactivateUserResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchDeactivateUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchDeactivateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchDeactivateUserResponse";
            };

            BatchDeactivateUserResponse.Failure = (function() {

                /**
                 * Properties of a Failure.
                 * @memberof streem.api.BatchDeactivateUserResponse
                 * @interface IFailure
                 * @property {streem.api.IErrorResponse|null} [error] Failure error
                 * @property {string|null} [companyCode] Failure companyCode
                 * @property {string|null} [externalUserId] Failure externalUserId
                 */

                /**
                 * Constructs a new Failure.
                 * @memberof streem.api.BatchDeactivateUserResponse
                 * @classdesc Represents a Failure.
                 * @implements IFailure
                 * @constructor
                 * @param {streem.api.BatchDeactivateUserResponse.IFailure=} [p] Properties to set
                 */
                function Failure(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Failure error.
                 * @member {streem.api.IErrorResponse|null|undefined} error
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.error = null;

                /**
                 * Failure companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.companyCode = "";

                /**
                 * Failure externalUserId.
                 * @member {string} externalUserId
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @instance
                 */
                Failure.prototype.externalUserId = "";

                /**
                 * Creates a new Failure instance using the specified properties.
                 * @function create
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchDeactivateUserResponse.IFailure=} [properties] Properties to set
                 * @returns {streem.api.BatchDeactivateUserResponse.Failure} Failure instance
                 */
                Failure.create = function create(properties) {
                    return new Failure(properties);
                };

                /**
                 * Encodes the specified Failure message. Does not implicitly {@link streem.api.BatchDeactivateUserResponse.Failure.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchDeactivateUserResponse.IFailure} m Failure message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Failure.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                        $root.streem.api.ErrorResponse.encode(m.error, w.uint32(10).fork()).ldelim();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                        w.uint32(26).string(m.externalUserId);
                    return w;
                };

                /**
                 * Decodes a Failure message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.BatchDeactivateUserResponse.Failure} Failure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Failure.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchDeactivateUserResponse.Failure();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.error = $root.streem.api.ErrorResponse.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 3: {
                                m.externalUserId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Failure message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.BatchDeactivateUserResponse.Failure} Failure
                 */
                Failure.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.BatchDeactivateUserResponse.Failure)
                        return d;
                    var m = new $root.streem.api.BatchDeactivateUserResponse.Failure();
                    if (d.error != null) {
                        if (typeof d.error !== "object")
                            throw TypeError(".streem.api.BatchDeactivateUserResponse.Failure.error: object expected");
                        m.error = $root.streem.api.ErrorResponse.fromObject(d.error);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.externalUserId != null) {
                        m.externalUserId = String(d.externalUserId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Failure message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {streem.api.BatchDeactivateUserResponse.Failure} m Failure
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Failure.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.error = null;
                        d.companyCode = "";
                        d.externalUserId = "";
                    }
                    if (m.error != null && m.hasOwnProperty("error")) {
                        d.error = $root.streem.api.ErrorResponse.toObject(m.error, o);
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                        d.externalUserId = m.externalUserId;
                    }
                    return d;
                };

                /**
                 * Converts this Failure to JSON.
                 * @function toJSON
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Failure.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Failure
                 * @function getTypeUrl
                 * @memberof streem.api.BatchDeactivateUserResponse.Failure
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Failure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.BatchDeactivateUserResponse.Failure";
                };

                return Failure;
            })();

            return BatchDeactivateUserResponse;
        })();

        api.ListUserInvitationsRequest = (function() {

            /**
             * Properties of a ListUserInvitationsRequest.
             * @memberof streem.api
             * @interface IListUserInvitationsRequest
             * @property {string|null} [userSid] ListUserInvitationsRequest userSid
             * @property {string|null} [deviceSid] ListUserInvitationsRequest deviceSid
             * @property {streem.api.RoomInvitation.Status|null} [status] ListUserInvitationsRequest status
             * @property {number|null} [pageSize] ListUserInvitationsRequest pageSize
             * @property {string|null} [pageToken] ListUserInvitationsRequest pageToken
             */

            /**
             * Constructs a new ListUserInvitationsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListUserInvitationsRequest.
             * @implements IListUserInvitationsRequest
             * @constructor
             * @param {streem.api.IListUserInvitationsRequest=} [p] Properties to set
             */
            function ListUserInvitationsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserInvitationsRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             */
            ListUserInvitationsRequest.prototype.userSid = "";

            /**
             * ListUserInvitationsRequest deviceSid.
             * @member {string} deviceSid
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             */
            ListUserInvitationsRequest.prototype.deviceSid = "";

            /**
             * ListUserInvitationsRequest status.
             * @member {streem.api.RoomInvitation.Status} status
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             */
            ListUserInvitationsRequest.prototype.status = 0;

            /**
             * ListUserInvitationsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             */
            ListUserInvitationsRequest.prototype.pageSize = 0;

            /**
             * ListUserInvitationsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             */
            ListUserInvitationsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListUserInvitationsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {streem.api.IListUserInvitationsRequest=} [properties] Properties to set
             * @returns {streem.api.ListUserInvitationsRequest} ListUserInvitationsRequest instance
             */
            ListUserInvitationsRequest.create = function create(properties) {
                return new ListUserInvitationsRequest(properties);
            };

            /**
             * Encodes the specified ListUserInvitationsRequest message. Does not implicitly {@link streem.api.ListUserInvitationsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {streem.api.IListUserInvitationsRequest} m ListUserInvitationsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserInvitationsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                    w.uint32(18).string(m.deviceSid);
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(24).int32(m.status);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(32).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(42).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListUserInvitationsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserInvitationsRequest} ListUserInvitationsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserInvitationsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserInvitationsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.deviceSid = r.string();
                            break;
                        }
                    case 3: {
                            m.status = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 5: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserInvitationsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserInvitationsRequest} ListUserInvitationsRequest
             */
            ListUserInvitationsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserInvitationsRequest)
                    return d;
                var m = new $root.streem.api.ListUserInvitationsRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.deviceSid != null) {
                    m.deviceSid = String(d.deviceSid);
                }
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_PENDING":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_ACCEPTED":
                case 2:
                    m.status = 2;
                    break;
                case "STATUS_REJECTED":
                case 3:
                    m.status = 3;
                    break;
                case "STATUS_CANCELED":
                case 4:
                    m.status = 4;
                    break;
                case "STATUS_TIMED_OUT":
                case 5:
                    m.status = 5;
                    break;
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserInvitationsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {streem.api.ListUserInvitationsRequest} m ListUserInvitationsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserInvitationsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.deviceSid = "";
                    d.status = o.enums === String ? "STATUS_INVALID" : 0;
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                    d.deviceSid = m.deviceSid;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.RoomInvitation.Status[m.status] === undefined ? m.status : $root.streem.api.RoomInvitation.Status[m.status] : m.status;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListUserInvitationsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserInvitationsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserInvitationsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserInvitationsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListUserInvitationsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserInvitationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserInvitationsRequest";
            };

            return ListUserInvitationsRequest;
        })();

        api.ListUserInvitationsResponse = (function() {

            /**
             * Properties of a ListUserInvitationsResponse.
             * @memberof streem.api
             * @interface IListUserInvitationsResponse
             * @property {Array.<streem.api.IRoomInvitation>|null} [roomInvitations] ListUserInvitationsResponse roomInvitations
             * @property {string|null} [nextPageToken] ListUserInvitationsResponse nextPageToken
             * @property {number|null} [totalSize] ListUserInvitationsResponse totalSize
             */

            /**
             * Constructs a new ListUserInvitationsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListUserInvitationsResponse.
             * @implements IListUserInvitationsResponse
             * @constructor
             * @param {streem.api.IListUserInvitationsResponse=} [p] Properties to set
             */
            function ListUserInvitationsResponse(p) {
                this.roomInvitations = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserInvitationsResponse roomInvitations.
             * @member {Array.<streem.api.IRoomInvitation>} roomInvitations
             * @memberof streem.api.ListUserInvitationsResponse
             * @instance
             */
            ListUserInvitationsResponse.prototype.roomInvitations = $util.emptyArray;

            /**
             * ListUserInvitationsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListUserInvitationsResponse
             * @instance
             */
            ListUserInvitationsResponse.prototype.nextPageToken = "";

            /**
             * ListUserInvitationsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListUserInvitationsResponse
             * @instance
             */
            ListUserInvitationsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListUserInvitationsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {streem.api.IListUserInvitationsResponse=} [properties] Properties to set
             * @returns {streem.api.ListUserInvitationsResponse} ListUserInvitationsResponse instance
             */
            ListUserInvitationsResponse.create = function create(properties) {
                return new ListUserInvitationsResponse(properties);
            };

            /**
             * Encodes the specified ListUserInvitationsResponse message. Does not implicitly {@link streem.api.ListUserInvitationsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {streem.api.IListUserInvitationsResponse} m ListUserInvitationsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserInvitationsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomInvitations != null && m.roomInvitations.length) {
                    for (var i = 0; i < m.roomInvitations.length; ++i)
                        $root.streem.api.RoomInvitation.encode(m.roomInvitations[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListUserInvitationsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserInvitationsResponse} ListUserInvitationsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserInvitationsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserInvitationsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.roomInvitations && m.roomInvitations.length))
                                m.roomInvitations = [];
                            m.roomInvitations.push($root.streem.api.RoomInvitation.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserInvitationsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserInvitationsResponse} ListUserInvitationsResponse
             */
            ListUserInvitationsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserInvitationsResponse)
                    return d;
                var m = new $root.streem.api.ListUserInvitationsResponse();
                if (d.roomInvitations) {
                    if (!Array.isArray(d.roomInvitations))
                        throw TypeError(".streem.api.ListUserInvitationsResponse.roomInvitations: array expected");
                    m.roomInvitations = [];
                    for (var i = 0; i < d.roomInvitations.length; ++i) {
                        if (typeof d.roomInvitations[i] !== "object")
                            throw TypeError(".streem.api.ListUserInvitationsResponse.roomInvitations: object expected");
                        m.roomInvitations[i] = $root.streem.api.RoomInvitation.fromObject(d.roomInvitations[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserInvitationsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {streem.api.ListUserInvitationsResponse} m ListUserInvitationsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserInvitationsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.roomInvitations = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.roomInvitations && m.roomInvitations.length) {
                    d.roomInvitations = [];
                    for (var j = 0; j < m.roomInvitations.length; ++j) {
                        d.roomInvitations[j] = $root.streem.api.RoomInvitation.toObject(m.roomInvitations[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListUserInvitationsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserInvitationsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserInvitationsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserInvitationsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListUserInvitationsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserInvitationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserInvitationsResponse";
            };

            return ListUserInvitationsResponse;
        })();

        api.GetUserAvailabilityRequest = (function() {

            /**
             * Properties of a GetUserAvailabilityRequest.
             * @memberof streem.api
             * @interface IGetUserAvailabilityRequest
             * @property {string|null} [userSid] GetUserAvailabilityRequest userSid
             */

            /**
             * Constructs a new GetUserAvailabilityRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserAvailabilityRequest.
             * @implements IGetUserAvailabilityRequest
             * @constructor
             * @param {streem.api.IGetUserAvailabilityRequest=} [p] Properties to set
             */
            function GetUserAvailabilityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserAvailabilityRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetUserAvailabilityRequest
             * @instance
             */
            GetUserAvailabilityRequest.prototype.userSid = "";

            /**
             * Creates a new GetUserAvailabilityRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {streem.api.IGetUserAvailabilityRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserAvailabilityRequest} GetUserAvailabilityRequest instance
             */
            GetUserAvailabilityRequest.create = function create(properties) {
                return new GetUserAvailabilityRequest(properties);
            };

            /**
             * Encodes the specified GetUserAvailabilityRequest message. Does not implicitly {@link streem.api.GetUserAvailabilityRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {streem.api.IGetUserAvailabilityRequest} m GetUserAvailabilityRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserAvailabilityRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a GetUserAvailabilityRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserAvailabilityRequest} GetUserAvailabilityRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserAvailabilityRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserAvailabilityRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserAvailabilityRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserAvailabilityRequest} GetUserAvailabilityRequest
             */
            GetUserAvailabilityRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserAvailabilityRequest)
                    return d;
                var m = new $root.streem.api.GetUserAvailabilityRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserAvailabilityRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {streem.api.GetUserAvailabilityRequest} m GetUserAvailabilityRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserAvailabilityRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this GetUserAvailabilityRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserAvailabilityRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserAvailabilityRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserAvailabilityRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserAvailabilityRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserAvailabilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserAvailabilityRequest";
            };

            return GetUserAvailabilityRequest;
        })();

        api.GetUserAvailabilityResponse = (function() {

            /**
             * Properties of a GetUserAvailabilityResponse.
             * @memberof streem.api
             * @interface IGetUserAvailabilityResponse
             * @property {streem.api.IUserAvailability|null} [userAvailability] GetUserAvailabilityResponse userAvailability
             */

            /**
             * Constructs a new GetUserAvailabilityResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserAvailabilityResponse.
             * @implements IGetUserAvailabilityResponse
             * @constructor
             * @param {streem.api.IGetUserAvailabilityResponse=} [p] Properties to set
             */
            function GetUserAvailabilityResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserAvailabilityResponse userAvailability.
             * @member {streem.api.IUserAvailability|null|undefined} userAvailability
             * @memberof streem.api.GetUserAvailabilityResponse
             * @instance
             */
            GetUserAvailabilityResponse.prototype.userAvailability = null;

            /**
             * Creates a new GetUserAvailabilityResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {streem.api.IGetUserAvailabilityResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserAvailabilityResponse} GetUserAvailabilityResponse instance
             */
            GetUserAvailabilityResponse.create = function create(properties) {
                return new GetUserAvailabilityResponse(properties);
            };

            /**
             * Encodes the specified GetUserAvailabilityResponse message. Does not implicitly {@link streem.api.GetUserAvailabilityResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {streem.api.IGetUserAvailabilityResponse} m GetUserAvailabilityResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserAvailabilityResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userAvailability != null && Object.hasOwnProperty.call(m, "userAvailability"))
                    $root.streem.api.UserAvailability.encode(m.userAvailability, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetUserAvailabilityResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserAvailabilityResponse} GetUserAvailabilityResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserAvailabilityResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserAvailabilityResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userAvailability = $root.streem.api.UserAvailability.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserAvailabilityResponse} GetUserAvailabilityResponse
             */
            GetUserAvailabilityResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserAvailabilityResponse)
                    return d;
                var m = new $root.streem.api.GetUserAvailabilityResponse();
                if (d.userAvailability != null) {
                    if (typeof d.userAvailability !== "object")
                        throw TypeError(".streem.api.GetUserAvailabilityResponse.userAvailability: object expected");
                    m.userAvailability = $root.streem.api.UserAvailability.fromObject(d.userAvailability);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserAvailabilityResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {streem.api.GetUserAvailabilityResponse} m GetUserAvailabilityResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserAvailabilityResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userAvailability = null;
                }
                if (m.userAvailability != null && m.hasOwnProperty("userAvailability")) {
                    d.userAvailability = $root.streem.api.UserAvailability.toObject(m.userAvailability, o);
                }
                return d;
            };

            /**
             * Converts this GetUserAvailabilityResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserAvailabilityResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserAvailabilityResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserAvailabilityResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserAvailabilityResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserAvailabilityResponse";
            };

            return GetUserAvailabilityResponse;
        })();

        api.UpdateUserAvailabilityRequest = (function() {

            /**
             * Properties of an UpdateUserAvailabilityRequest.
             * @memberof streem.api
             * @interface IUpdateUserAvailabilityRequest
             * @property {string|null} [userSid] UpdateUserAvailabilityRequest userSid
             * @property {streem.api.AvailabilityStatus|null} [availabilityStatus] UpdateUserAvailabilityRequest availabilityStatus
             */

            /**
             * Constructs a new UpdateUserAvailabilityRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserAvailabilityRequest.
             * @implements IUpdateUserAvailabilityRequest
             * @constructor
             * @param {streem.api.IUpdateUserAvailabilityRequest=} [p] Properties to set
             */
            function UpdateUserAvailabilityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserAvailabilityRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @instance
             */
            UpdateUserAvailabilityRequest.prototype.userSid = "";

            /**
             * UpdateUserAvailabilityRequest availabilityStatus.
             * @member {streem.api.AvailabilityStatus} availabilityStatus
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @instance
             */
            UpdateUserAvailabilityRequest.prototype.availabilityStatus = 0;

            /**
             * Creates a new UpdateUserAvailabilityRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {streem.api.IUpdateUserAvailabilityRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateUserAvailabilityRequest} UpdateUserAvailabilityRequest instance
             */
            UpdateUserAvailabilityRequest.create = function create(properties) {
                return new UpdateUserAvailabilityRequest(properties);
            };

            /**
             * Encodes the specified UpdateUserAvailabilityRequest message. Does not implicitly {@link streem.api.UpdateUserAvailabilityRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {streem.api.IUpdateUserAvailabilityRequest} m UpdateUserAvailabilityRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserAvailabilityRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.availabilityStatus != null && Object.hasOwnProperty.call(m, "availabilityStatus"))
                    w.uint32(16).int32(m.availabilityStatus);
                return w;
            };

            /**
             * Decodes an UpdateUserAvailabilityRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserAvailabilityRequest} UpdateUserAvailabilityRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserAvailabilityRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserAvailabilityRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.availabilityStatus = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserAvailabilityRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserAvailabilityRequest} UpdateUserAvailabilityRequest
             */
            UpdateUserAvailabilityRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserAvailabilityRequest)
                    return d;
                var m = new $root.streem.api.UpdateUserAvailabilityRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                switch (d.availabilityStatus) {
                default:
                    if (typeof d.availabilityStatus === "number") {
                        m.availabilityStatus = d.availabilityStatus;
                        break;
                    }
                    break;
                case "AVAILABILITY_STATUS_INVALID":
                case 0:
                    m.availabilityStatus = 0;
                    break;
                case "AVAILABILITY_STATUS_AVAILABLE":
                case 1:
                    m.availabilityStatus = 1;
                    break;
                case "AVAILABILITY_STATUS_NOT_AVAILABLE":
                case 2:
                    m.availabilityStatus = 2;
                    break;
                case "AVAILABILITY_STATUS_RESERVED":
                case 3:
                    m.availabilityStatus = 3;
                    break;
                case "AVAILABILITY_STATUS_IN_CALL":
                case 4:
                    m.availabilityStatus = 4;
                    break;
                case "AVAILABILITY_STATUS_CONFIRMING":
                case 5:
                    m.availabilityStatus = 5;
                    break;
                case "AVAILABILITY_STATUS_OFFLINE":
                case 6:
                    m.availabilityStatus = 6;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserAvailabilityRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {streem.api.UpdateUserAvailabilityRequest} m UpdateUserAvailabilityRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserAvailabilityRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.availabilityStatus = o.enums === String ? "AVAILABILITY_STATUS_INVALID" : 0;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.availabilityStatus != null && m.hasOwnProperty("availabilityStatus")) {
                    d.availabilityStatus = o.enums === String ? $root.streem.api.AvailabilityStatus[m.availabilityStatus] === undefined ? m.availabilityStatus : $root.streem.api.AvailabilityStatus[m.availabilityStatus] : m.availabilityStatus;
                }
                return d;
            };

            /**
             * Converts this UpdateUserAvailabilityRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserAvailabilityRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserAvailabilityRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserAvailabilityRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserAvailabilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserAvailabilityRequest";
            };

            return UpdateUserAvailabilityRequest;
        })();

        api.UpdateUserAvailabilityResponse = (function() {

            /**
             * Properties of an UpdateUserAvailabilityResponse.
             * @memberof streem.api
             * @interface IUpdateUserAvailabilityResponse
             * @property {streem.api.IUserAvailability|null} [userAvailability] UpdateUserAvailabilityResponse userAvailability
             */

            /**
             * Constructs a new UpdateUserAvailabilityResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserAvailabilityResponse.
             * @implements IUpdateUserAvailabilityResponse
             * @constructor
             * @param {streem.api.IUpdateUserAvailabilityResponse=} [p] Properties to set
             */
            function UpdateUserAvailabilityResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserAvailabilityResponse userAvailability.
             * @member {streem.api.IUserAvailability|null|undefined} userAvailability
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @instance
             */
            UpdateUserAvailabilityResponse.prototype.userAvailability = null;

            /**
             * Creates a new UpdateUserAvailabilityResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {streem.api.IUpdateUserAvailabilityResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateUserAvailabilityResponse} UpdateUserAvailabilityResponse instance
             */
            UpdateUserAvailabilityResponse.create = function create(properties) {
                return new UpdateUserAvailabilityResponse(properties);
            };

            /**
             * Encodes the specified UpdateUserAvailabilityResponse message. Does not implicitly {@link streem.api.UpdateUserAvailabilityResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {streem.api.IUpdateUserAvailabilityResponse} m UpdateUserAvailabilityResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserAvailabilityResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userAvailability != null && Object.hasOwnProperty.call(m, "userAvailability"))
                    $root.streem.api.UserAvailability.encode(m.userAvailability, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserAvailabilityResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserAvailabilityResponse} UpdateUserAvailabilityResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserAvailabilityResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserAvailabilityResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userAvailability = $root.streem.api.UserAvailability.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserAvailabilityResponse} UpdateUserAvailabilityResponse
             */
            UpdateUserAvailabilityResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserAvailabilityResponse)
                    return d;
                var m = new $root.streem.api.UpdateUserAvailabilityResponse();
                if (d.userAvailability != null) {
                    if (typeof d.userAvailability !== "object")
                        throw TypeError(".streem.api.UpdateUserAvailabilityResponse.userAvailability: object expected");
                    m.userAvailability = $root.streem.api.UserAvailability.fromObject(d.userAvailability);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserAvailabilityResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {streem.api.UpdateUserAvailabilityResponse} m UpdateUserAvailabilityResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserAvailabilityResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userAvailability = null;
                }
                if (m.userAvailability != null && m.hasOwnProperty("userAvailability")) {
                    d.userAvailability = $root.streem.api.UserAvailability.toObject(m.userAvailability, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserAvailabilityResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserAvailabilityResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserAvailabilityResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserAvailabilityResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserAvailabilityResponse";
            };

            return UpdateUserAvailabilityResponse;
        })();

        api.CreateUserTokensRequest = (function() {

            /**
             * Properties of a CreateUserTokensRequest.
             * @memberof streem.api
             * @interface ICreateUserTokensRequest
             * @property {string|null} [userSid] CreateUserTokensRequest userSid
             */

            /**
             * Constructs a new CreateUserTokensRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateUserTokensRequest.
             * @implements ICreateUserTokensRequest
             * @constructor
             * @param {streem.api.ICreateUserTokensRequest=} [p] Properties to set
             */
            function CreateUserTokensRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateUserTokensRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateUserTokensRequest
             * @instance
             */
            CreateUserTokensRequest.prototype.userSid = "";

            /**
             * Creates a new CreateUserTokensRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {streem.api.ICreateUserTokensRequest=} [properties] Properties to set
             * @returns {streem.api.CreateUserTokensRequest} CreateUserTokensRequest instance
             */
            CreateUserTokensRequest.create = function create(properties) {
                return new CreateUserTokensRequest(properties);
            };

            /**
             * Encodes the specified CreateUserTokensRequest message. Does not implicitly {@link streem.api.CreateUserTokensRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {streem.api.ICreateUserTokensRequest} m CreateUserTokensRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateUserTokensRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a CreateUserTokensRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateUserTokensRequest} CreateUserTokensRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateUserTokensRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateUserTokensRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateUserTokensRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateUserTokensRequest} CreateUserTokensRequest
             */
            CreateUserTokensRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateUserTokensRequest)
                    return d;
                var m = new $root.streem.api.CreateUserTokensRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateUserTokensRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {streem.api.CreateUserTokensRequest} m CreateUserTokensRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateUserTokensRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this CreateUserTokensRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateUserTokensRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateUserTokensRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateUserTokensRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateUserTokensRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateUserTokensRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateUserTokensRequest";
            };

            return CreateUserTokensRequest;
        })();

        api.CreateUserTokensResponse = (function() {

            /**
             * Properties of a CreateUserTokensResponse.
             * @memberof streem.api
             * @interface ICreateUserTokensResponse
             * @property {streem.api.IUserWallCredentials|null} [userWallCredentials] CreateUserTokensResponse userWallCredentials
             */

            /**
             * Constructs a new CreateUserTokensResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateUserTokensResponse.
             * @implements ICreateUserTokensResponse
             * @constructor
             * @param {streem.api.ICreateUserTokensResponse=} [p] Properties to set
             */
            function CreateUserTokensResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateUserTokensResponse userWallCredentials.
             * @member {streem.api.IUserWallCredentials|null|undefined} userWallCredentials
             * @memberof streem.api.CreateUserTokensResponse
             * @instance
             */
            CreateUserTokensResponse.prototype.userWallCredentials = null;

            /**
             * Creates a new CreateUserTokensResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {streem.api.ICreateUserTokensResponse=} [properties] Properties to set
             * @returns {streem.api.CreateUserTokensResponse} CreateUserTokensResponse instance
             */
            CreateUserTokensResponse.create = function create(properties) {
                return new CreateUserTokensResponse(properties);
            };

            /**
             * Encodes the specified CreateUserTokensResponse message. Does not implicitly {@link streem.api.CreateUserTokensResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {streem.api.ICreateUserTokensResponse} m CreateUserTokensResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateUserTokensResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userWallCredentials != null && Object.hasOwnProperty.call(m, "userWallCredentials"))
                    $root.streem.api.UserWallCredentials.encode(m.userWallCredentials, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateUserTokensResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateUserTokensResponse} CreateUserTokensResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateUserTokensResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateUserTokensResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userWallCredentials = $root.streem.api.UserWallCredentials.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateUserTokensResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateUserTokensResponse} CreateUserTokensResponse
             */
            CreateUserTokensResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateUserTokensResponse)
                    return d;
                var m = new $root.streem.api.CreateUserTokensResponse();
                if (d.userWallCredentials != null) {
                    if (typeof d.userWallCredentials !== "object")
                        throw TypeError(".streem.api.CreateUserTokensResponse.userWallCredentials: object expected");
                    m.userWallCredentials = $root.streem.api.UserWallCredentials.fromObject(d.userWallCredentials);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateUserTokensResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {streem.api.CreateUserTokensResponse} m CreateUserTokensResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateUserTokensResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userWallCredentials = null;
                }
                if (m.userWallCredentials != null && m.hasOwnProperty("userWallCredentials")) {
                    d.userWallCredentials = $root.streem.api.UserWallCredentials.toObject(m.userWallCredentials, o);
                }
                return d;
            };

            /**
             * Converts this CreateUserTokensResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateUserTokensResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateUserTokensResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateUserTokensResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateUserTokensResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateUserTokensResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateUserTokensResponse";
            };

            return CreateUserTokensResponse;
        })();

        api.CreateUserVoipTokenRequest = (function() {

            /**
             * Properties of a CreateUserVoipTokenRequest.
             * @memberof streem.api
             * @interface ICreateUserVoipTokenRequest
             * @property {string|null} [userSid] CreateUserVoipTokenRequest userSid
             * @property {string|null} [deviceSid] CreateUserVoipTokenRequest deviceSid
             * @property {string|null} [token] CreateUserVoipTokenRequest token
             */

            /**
             * Constructs a new CreateUserVoipTokenRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateUserVoipTokenRequest.
             * @implements ICreateUserVoipTokenRequest
             * @constructor
             * @param {streem.api.ICreateUserVoipTokenRequest=} [p] Properties to set
             */
            function CreateUserVoipTokenRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateUserVoipTokenRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @instance
             */
            CreateUserVoipTokenRequest.prototype.userSid = "";

            /**
             * CreateUserVoipTokenRequest deviceSid.
             * @member {string} deviceSid
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @instance
             */
            CreateUserVoipTokenRequest.prototype.deviceSid = "";

            /**
             * CreateUserVoipTokenRequest token.
             * @member {string} token
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @instance
             */
            CreateUserVoipTokenRequest.prototype.token = "";

            /**
             * Creates a new CreateUserVoipTokenRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {streem.api.ICreateUserVoipTokenRequest=} [properties] Properties to set
             * @returns {streem.api.CreateUserVoipTokenRequest} CreateUserVoipTokenRequest instance
             */
            CreateUserVoipTokenRequest.create = function create(properties) {
                return new CreateUserVoipTokenRequest(properties);
            };

            /**
             * Encodes the specified CreateUserVoipTokenRequest message. Does not implicitly {@link streem.api.CreateUserVoipTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {streem.api.ICreateUserVoipTokenRequest} m CreateUserVoipTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateUserVoipTokenRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.deviceSid != null && Object.hasOwnProperty.call(m, "deviceSid"))
                    w.uint32(18).string(m.deviceSid);
                if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                    w.uint32(26).string(m.token);
                return w;
            };

            /**
             * Decodes a CreateUserVoipTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateUserVoipTokenRequest} CreateUserVoipTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateUserVoipTokenRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateUserVoipTokenRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.deviceSid = r.string();
                            break;
                        }
                    case 3: {
                            m.token = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateUserVoipTokenRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateUserVoipTokenRequest} CreateUserVoipTokenRequest
             */
            CreateUserVoipTokenRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateUserVoipTokenRequest)
                    return d;
                var m = new $root.streem.api.CreateUserVoipTokenRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.deviceSid != null) {
                    m.deviceSid = String(d.deviceSid);
                }
                if (d.token != null) {
                    m.token = String(d.token);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateUserVoipTokenRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {streem.api.CreateUserVoipTokenRequest} m CreateUserVoipTokenRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateUserVoipTokenRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.deviceSid = "";
                    d.token = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.deviceSid != null && m.hasOwnProperty("deviceSid")) {
                    d.deviceSid = m.deviceSid;
                }
                if (m.token != null && m.hasOwnProperty("token")) {
                    d.token = m.token;
                }
                return d;
            };

            /**
             * Converts this CreateUserVoipTokenRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateUserVoipTokenRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateUserVoipTokenRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateUserVoipTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateUserVoipTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateUserVoipTokenRequest";
            };

            return CreateUserVoipTokenRequest;
        })();

        api.GetUserCallLogRequest = (function() {

            /**
             * Properties of a GetUserCallLogRequest.
             * @memberof streem.api
             * @interface IGetUserCallLogRequest
             * @property {string|null} [userSid] GetUserCallLogRequest userSid
             * @property {number|null} [pageSize] GetUserCallLogRequest pageSize
             * @property {string|null} [pageToken] GetUserCallLogRequest pageToken
             * @property {string|null} [filter] GetUserCallLogRequest filter
             * @property {boolean|null} [favoritesOnly] GetUserCallLogRequest favoritesOnly
             */

            /**
             * Constructs a new GetUserCallLogRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserCallLogRequest.
             * @implements IGetUserCallLogRequest
             * @constructor
             * @param {streem.api.IGetUserCallLogRequest=} [p] Properties to set
             */
            function GetUserCallLogRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserCallLogRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             */
            GetUserCallLogRequest.prototype.userSid = "";

            /**
             * GetUserCallLogRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             */
            GetUserCallLogRequest.prototype.pageSize = 0;

            /**
             * GetUserCallLogRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             */
            GetUserCallLogRequest.prototype.pageToken = "";

            /**
             * GetUserCallLogRequest filter.
             * @member {string} filter
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             */
            GetUserCallLogRequest.prototype.filter = "";

            /**
             * GetUserCallLogRequest favoritesOnly.
             * @member {boolean} favoritesOnly
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             */
            GetUserCallLogRequest.prototype.favoritesOnly = false;

            /**
             * Creates a new GetUserCallLogRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {streem.api.IGetUserCallLogRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserCallLogRequest} GetUserCallLogRequest instance
             */
            GetUserCallLogRequest.create = function create(properties) {
                return new GetUserCallLogRequest(properties);
            };

            /**
             * Encodes the specified GetUserCallLogRequest message. Does not implicitly {@link streem.api.GetUserCallLogRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {streem.api.IGetUserCallLogRequest} m GetUserCallLogRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserCallLogRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                    w.uint32(34).string(m.filter);
                if (m.favoritesOnly != null && Object.hasOwnProperty.call(m, "favoritesOnly"))
                    w.uint32(40).bool(m.favoritesOnly);
                return w;
            };

            /**
             * Decodes a GetUserCallLogRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserCallLogRequest} GetUserCallLogRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserCallLogRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserCallLogRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 4: {
                            m.filter = r.string();
                            break;
                        }
                    case 5: {
                            m.favoritesOnly = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserCallLogRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserCallLogRequest} GetUserCallLogRequest
             */
            GetUserCallLogRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserCallLogRequest)
                    return d;
                var m = new $root.streem.api.GetUserCallLogRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.filter != null) {
                    m.filter = String(d.filter);
                }
                if (d.favoritesOnly != null) {
                    m.favoritesOnly = Boolean(d.favoritesOnly);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserCallLogRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {streem.api.GetUserCallLogRequest} m GetUserCallLogRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserCallLogRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.filter = "";
                    d.favoritesOnly = false;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.filter != null && m.hasOwnProperty("filter")) {
                    d.filter = m.filter;
                }
                if (m.favoritesOnly != null && m.hasOwnProperty("favoritesOnly")) {
                    d.favoritesOnly = m.favoritesOnly;
                }
                return d;
            };

            /**
             * Converts this GetUserCallLogRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserCallLogRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserCallLogRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserCallLogRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserCallLogRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserCallLogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserCallLogRequest";
            };

            return GetUserCallLogRequest;
        })();

        api.GetUserCallLogResponse = (function() {

            /**
             * Properties of a GetUserCallLogResponse.
             * @memberof streem.api
             * @interface IGetUserCallLogResponse
             * @property {Array.<streem.api.ICallLogEntry>|null} [callLogEntries] GetUserCallLogResponse callLogEntries
             * @property {string|null} [nextPageToken] GetUserCallLogResponse nextPageToken
             * @property {number|null} [totalSize] GetUserCallLogResponse totalSize
             */

            /**
             * Constructs a new GetUserCallLogResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserCallLogResponse.
             * @implements IGetUserCallLogResponse
             * @constructor
             * @param {streem.api.IGetUserCallLogResponse=} [p] Properties to set
             */
            function GetUserCallLogResponse(p) {
                this.callLogEntries = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserCallLogResponse callLogEntries.
             * @member {Array.<streem.api.ICallLogEntry>} callLogEntries
             * @memberof streem.api.GetUserCallLogResponse
             * @instance
             */
            GetUserCallLogResponse.prototype.callLogEntries = $util.emptyArray;

            /**
             * GetUserCallLogResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.GetUserCallLogResponse
             * @instance
             */
            GetUserCallLogResponse.prototype.nextPageToken = "";

            /**
             * GetUserCallLogResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.GetUserCallLogResponse
             * @instance
             */
            GetUserCallLogResponse.prototype.totalSize = 0;

            /**
             * Creates a new GetUserCallLogResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {streem.api.IGetUserCallLogResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserCallLogResponse} GetUserCallLogResponse instance
             */
            GetUserCallLogResponse.create = function create(properties) {
                return new GetUserCallLogResponse(properties);
            };

            /**
             * Encodes the specified GetUserCallLogResponse message. Does not implicitly {@link streem.api.GetUserCallLogResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {streem.api.IGetUserCallLogResponse} m GetUserCallLogResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserCallLogResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntries != null && m.callLogEntries.length) {
                    for (var i = 0; i < m.callLogEntries.length; ++i)
                        $root.streem.api.CallLogEntry.encode(m.callLogEntries[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a GetUserCallLogResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserCallLogResponse} GetUserCallLogResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserCallLogResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserCallLogResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.callLogEntries && m.callLogEntries.length))
                                m.callLogEntries = [];
                            m.callLogEntries.push($root.streem.api.CallLogEntry.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserCallLogResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserCallLogResponse} GetUserCallLogResponse
             */
            GetUserCallLogResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserCallLogResponse)
                    return d;
                var m = new $root.streem.api.GetUserCallLogResponse();
                if (d.callLogEntries) {
                    if (!Array.isArray(d.callLogEntries))
                        throw TypeError(".streem.api.GetUserCallLogResponse.callLogEntries: array expected");
                    m.callLogEntries = [];
                    for (var i = 0; i < d.callLogEntries.length; ++i) {
                        if (typeof d.callLogEntries[i] !== "object")
                            throw TypeError(".streem.api.GetUserCallLogResponse.callLogEntries: object expected");
                        m.callLogEntries[i] = $root.streem.api.CallLogEntry.fromObject(d.callLogEntries[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserCallLogResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {streem.api.GetUserCallLogResponse} m GetUserCallLogResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserCallLogResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.callLogEntries = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.callLogEntries && m.callLogEntries.length) {
                    d.callLogEntries = [];
                    for (var j = 0; j < m.callLogEntries.length; ++j) {
                        d.callLogEntries[j] = $root.streem.api.CallLogEntry.toObject(m.callLogEntries[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this GetUserCallLogResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserCallLogResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserCallLogResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserCallLogResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserCallLogResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserCallLogResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserCallLogResponse";
            };

            return GetUserCallLogResponse;
        })();

        api.GetUserCallLogEntryRequest = (function() {

            /**
             * Properties of a GetUserCallLogEntryRequest.
             * @memberof streem.api
             * @interface IGetUserCallLogEntryRequest
             * @property {string|null} [userSid] GetUserCallLogEntryRequest userSid
             * @property {string|null} [roomSid] GetUserCallLogEntryRequest roomSid
             */

            /**
             * Constructs a new GetUserCallLogEntryRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserCallLogEntryRequest.
             * @implements IGetUserCallLogEntryRequest
             * @constructor
             * @param {streem.api.IGetUserCallLogEntryRequest=} [p] Properties to set
             */
            function GetUserCallLogEntryRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserCallLogEntryRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @instance
             */
            GetUserCallLogEntryRequest.prototype.userSid = "";

            /**
             * GetUserCallLogEntryRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @instance
             */
            GetUserCallLogEntryRequest.prototype.roomSid = "";

            /**
             * Creates a new GetUserCallLogEntryRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {streem.api.IGetUserCallLogEntryRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserCallLogEntryRequest} GetUserCallLogEntryRequest instance
             */
            GetUserCallLogEntryRequest.create = function create(properties) {
                return new GetUserCallLogEntryRequest(properties);
            };

            /**
             * Encodes the specified GetUserCallLogEntryRequest message. Does not implicitly {@link streem.api.GetUserCallLogEntryRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {streem.api.IGetUserCallLogEntryRequest} m GetUserCallLogEntryRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserCallLogEntryRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                return w;
            };

            /**
             * Decodes a GetUserCallLogEntryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserCallLogEntryRequest} GetUserCallLogEntryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserCallLogEntryRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserCallLogEntryRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserCallLogEntryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserCallLogEntryRequest} GetUserCallLogEntryRequest
             */
            GetUserCallLogEntryRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserCallLogEntryRequest)
                    return d;
                var m = new $root.streem.api.GetUserCallLogEntryRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserCallLogEntryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {streem.api.GetUserCallLogEntryRequest} m GetUserCallLogEntryRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserCallLogEntryRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.roomSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                return d;
            };

            /**
             * Converts this GetUserCallLogEntryRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserCallLogEntryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserCallLogEntryRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserCallLogEntryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserCallLogEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserCallLogEntryRequest";
            };

            return GetUserCallLogEntryRequest;
        })();

        api.GetUserCallLogEntryResponse = (function() {

            /**
             * Properties of a GetUserCallLogEntryResponse.
             * @memberof streem.api
             * @interface IGetUserCallLogEntryResponse
             * @property {streem.api.ICallLogEntry|null} [callLogEntry] GetUserCallLogEntryResponse callLogEntry
             */

            /**
             * Constructs a new GetUserCallLogEntryResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserCallLogEntryResponse.
             * @implements IGetUserCallLogEntryResponse
             * @constructor
             * @param {streem.api.IGetUserCallLogEntryResponse=} [p] Properties to set
             */
            function GetUserCallLogEntryResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserCallLogEntryResponse callLogEntry.
             * @member {streem.api.ICallLogEntry|null|undefined} callLogEntry
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @instance
             */
            GetUserCallLogEntryResponse.prototype.callLogEntry = null;

            /**
             * Creates a new GetUserCallLogEntryResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {streem.api.IGetUserCallLogEntryResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserCallLogEntryResponse} GetUserCallLogEntryResponse instance
             */
            GetUserCallLogEntryResponse.create = function create(properties) {
                return new GetUserCallLogEntryResponse(properties);
            };

            /**
             * Encodes the specified GetUserCallLogEntryResponse message. Does not implicitly {@link streem.api.GetUserCallLogEntryResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {streem.api.IGetUserCallLogEntryResponse} m GetUserCallLogEntryResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserCallLogEntryResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntry != null && Object.hasOwnProperty.call(m, "callLogEntry"))
                    $root.streem.api.CallLogEntry.encode(m.callLogEntry, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetUserCallLogEntryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserCallLogEntryResponse} GetUserCallLogEntryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserCallLogEntryResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserCallLogEntryResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callLogEntry = $root.streem.api.CallLogEntry.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserCallLogEntryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserCallLogEntryResponse} GetUserCallLogEntryResponse
             */
            GetUserCallLogEntryResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserCallLogEntryResponse)
                    return d;
                var m = new $root.streem.api.GetUserCallLogEntryResponse();
                if (d.callLogEntry != null) {
                    if (typeof d.callLogEntry !== "object")
                        throw TypeError(".streem.api.GetUserCallLogEntryResponse.callLogEntry: object expected");
                    m.callLogEntry = $root.streem.api.CallLogEntry.fromObject(d.callLogEntry);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserCallLogEntryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {streem.api.GetUserCallLogEntryResponse} m GetUserCallLogEntryResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserCallLogEntryResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callLogEntry = null;
                }
                if (m.callLogEntry != null && m.hasOwnProperty("callLogEntry")) {
                    d.callLogEntry = $root.streem.api.CallLogEntry.toObject(m.callLogEntry, o);
                }
                return d;
            };

            /**
             * Converts this GetUserCallLogEntryResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserCallLogEntryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserCallLogEntryResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserCallLogEntryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserCallLogEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserCallLogEntryResponse";
            };

            return GetUserCallLogEntryResponse;
        })();

        api.UpdateUserCallLogEntryRequest = (function() {

            /**
             * Properties of an UpdateUserCallLogEntryRequest.
             * @memberof streem.api
             * @interface IUpdateUserCallLogEntryRequest
             * @property {string|null} [userSid] UpdateUserCallLogEntryRequest userSid
             * @property {string|null} [roomSid] UpdateUserCallLogEntryRequest roomSid
             * @property {google.protobuf.IBoolValue|null} [favorite] UpdateUserCallLogEntryRequest favorite
             */

            /**
             * Constructs a new UpdateUserCallLogEntryRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserCallLogEntryRequest.
             * @implements IUpdateUserCallLogEntryRequest
             * @constructor
             * @param {streem.api.IUpdateUserCallLogEntryRequest=} [p] Properties to set
             */
            function UpdateUserCallLogEntryRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserCallLogEntryRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @instance
             */
            UpdateUserCallLogEntryRequest.prototype.userSid = "";

            /**
             * UpdateUserCallLogEntryRequest roomSid.
             * @member {string} roomSid
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @instance
             */
            UpdateUserCallLogEntryRequest.prototype.roomSid = "";

            /**
             * UpdateUserCallLogEntryRequest favorite.
             * @member {google.protobuf.IBoolValue|null|undefined} favorite
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @instance
             */
            UpdateUserCallLogEntryRequest.prototype.favorite = null;

            /**
             * Creates a new UpdateUserCallLogEntryRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {streem.api.IUpdateUserCallLogEntryRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateUserCallLogEntryRequest} UpdateUserCallLogEntryRequest instance
             */
            UpdateUserCallLogEntryRequest.create = function create(properties) {
                return new UpdateUserCallLogEntryRequest(properties);
            };

            /**
             * Encodes the specified UpdateUserCallLogEntryRequest message. Does not implicitly {@link streem.api.UpdateUserCallLogEntryRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {streem.api.IUpdateUserCallLogEntryRequest} m UpdateUserCallLogEntryRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserCallLogEntryRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.favorite != null && Object.hasOwnProperty.call(m, "favorite"))
                    $root.google.protobuf.BoolValue.encode(m.favorite, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserCallLogEntryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserCallLogEntryRequest} UpdateUserCallLogEntryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserCallLogEntryRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserCallLogEntryRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 3: {
                            m.favorite = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserCallLogEntryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserCallLogEntryRequest} UpdateUserCallLogEntryRequest
             */
            UpdateUserCallLogEntryRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserCallLogEntryRequest)
                    return d;
                var m = new $root.streem.api.UpdateUserCallLogEntryRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.favorite != null) {
                    if (typeof d.favorite !== "object")
                        throw TypeError(".streem.api.UpdateUserCallLogEntryRequest.favorite: object expected");
                    m.favorite = $root.google.protobuf.BoolValue.fromObject(d.favorite);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserCallLogEntryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {streem.api.UpdateUserCallLogEntryRequest} m UpdateUserCallLogEntryRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserCallLogEntryRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.roomSid = "";
                    d.favorite = null;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.favorite != null && m.hasOwnProperty("favorite")) {
                    d.favorite = $root.google.protobuf.BoolValue.toObject(m.favorite, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserCallLogEntryRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserCallLogEntryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserCallLogEntryRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserCallLogEntryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserCallLogEntryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserCallLogEntryRequest";
            };

            return UpdateUserCallLogEntryRequest;
        })();

        api.UpdateUserCallLogEntryResponse = (function() {

            /**
             * Properties of an UpdateUserCallLogEntryResponse.
             * @memberof streem.api
             * @interface IUpdateUserCallLogEntryResponse
             * @property {streem.api.ICallLogEntry|null} [callLogEntry] UpdateUserCallLogEntryResponse callLogEntry
             */

            /**
             * Constructs a new UpdateUserCallLogEntryResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserCallLogEntryResponse.
             * @implements IUpdateUserCallLogEntryResponse
             * @constructor
             * @param {streem.api.IUpdateUserCallLogEntryResponse=} [p] Properties to set
             */
            function UpdateUserCallLogEntryResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserCallLogEntryResponse callLogEntry.
             * @member {streem.api.ICallLogEntry|null|undefined} callLogEntry
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @instance
             */
            UpdateUserCallLogEntryResponse.prototype.callLogEntry = null;

            /**
             * Creates a new UpdateUserCallLogEntryResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {streem.api.IUpdateUserCallLogEntryResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateUserCallLogEntryResponse} UpdateUserCallLogEntryResponse instance
             */
            UpdateUserCallLogEntryResponse.create = function create(properties) {
                return new UpdateUserCallLogEntryResponse(properties);
            };

            /**
             * Encodes the specified UpdateUserCallLogEntryResponse message. Does not implicitly {@link streem.api.UpdateUserCallLogEntryResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {streem.api.IUpdateUserCallLogEntryResponse} m UpdateUserCallLogEntryResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserCallLogEntryResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntry != null && Object.hasOwnProperty.call(m, "callLogEntry"))
                    $root.streem.api.CallLogEntry.encode(m.callLogEntry, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserCallLogEntryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserCallLogEntryResponse} UpdateUserCallLogEntryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserCallLogEntryResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserCallLogEntryResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callLogEntry = $root.streem.api.CallLogEntry.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserCallLogEntryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserCallLogEntryResponse} UpdateUserCallLogEntryResponse
             */
            UpdateUserCallLogEntryResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserCallLogEntryResponse)
                    return d;
                var m = new $root.streem.api.UpdateUserCallLogEntryResponse();
                if (d.callLogEntry != null) {
                    if (typeof d.callLogEntry !== "object")
                        throw TypeError(".streem.api.UpdateUserCallLogEntryResponse.callLogEntry: object expected");
                    m.callLogEntry = $root.streem.api.CallLogEntry.fromObject(d.callLogEntry);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserCallLogEntryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {streem.api.UpdateUserCallLogEntryResponse} m UpdateUserCallLogEntryResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserCallLogEntryResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callLogEntry = null;
                }
                if (m.callLogEntry != null && m.hasOwnProperty("callLogEntry")) {
                    d.callLogEntry = $root.streem.api.CallLogEntry.toObject(m.callLogEntry, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserCallLogEntryResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserCallLogEntryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserCallLogEntryResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserCallLogEntryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserCallLogEntryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserCallLogEntryResponse";
            };

            return UpdateUserCallLogEntryResponse;
        })();

        api.DeleteUserRequest = (function() {

            /**
             * Properties of a DeleteUserRequest.
             * @memberof streem.api
             * @interface IDeleteUserRequest
             * @property {string|null} [userSid] DeleteUserRequest userSid
             */

            /**
             * Constructs a new DeleteUserRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteUserRequest.
             * @implements IDeleteUserRequest
             * @constructor
             * @param {streem.api.IDeleteUserRequest=} [p] Properties to set
             */
            function DeleteUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.DeleteUserRequest
             * @instance
             */
            DeleteUserRequest.prototype.userSid = "";

            /**
             * Creates a new DeleteUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {streem.api.IDeleteUserRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteUserRequest} DeleteUserRequest instance
             */
            DeleteUserRequest.create = function create(properties) {
                return new DeleteUserRequest(properties);
            };

            /**
             * Encodes the specified DeleteUserRequest message. Does not implicitly {@link streem.api.DeleteUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {streem.api.IDeleteUserRequest} m DeleteUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a DeleteUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteUserRequest} DeleteUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteUserRequest} DeleteUserRequest
             */
            DeleteUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteUserRequest)
                    return d;
                var m = new $root.streem.api.DeleteUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {streem.api.DeleteUserRequest} m DeleteUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this DeleteUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteUserRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteUserRequest";
            };

            return DeleteUserRequest;
        })();

        api.DeleteUserResponse = (function() {

            /**
             * Properties of a DeleteUserResponse.
             * @memberof streem.api
             * @interface IDeleteUserResponse
             */

            /**
             * Constructs a new DeleteUserResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteUserResponse.
             * @implements IDeleteUserResponse
             * @constructor
             * @param {streem.api.IDeleteUserResponse=} [p] Properties to set
             */
            function DeleteUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {streem.api.IDeleteUserResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteUserResponse} DeleteUserResponse instance
             */
            DeleteUserResponse.create = function create(properties) {
                return new DeleteUserResponse(properties);
            };

            /**
             * Encodes the specified DeleteUserResponse message. Does not implicitly {@link streem.api.DeleteUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {streem.api.IDeleteUserResponse} m DeleteUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteUserResponse} DeleteUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteUserResponse} DeleteUserResponse
             */
            DeleteUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteUserResponse)
                    return d;
                return new $root.streem.api.DeleteUserResponse();
            };

            /**
             * Creates a plain object from a DeleteUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {streem.api.DeleteUserResponse} m DeleteUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteUserResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteUserResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteUserResponse";
            };

            return DeleteUserResponse;
        })();

        api.ListUserDevicesRequest = (function() {

            /**
             * Properties of a ListUserDevicesRequest.
             * @memberof streem.api
             * @interface IListUserDevicesRequest
             * @property {string|null} [userSid] ListUserDevicesRequest userSid
             * @property {number|null} [pageSize] ListUserDevicesRequest pageSize
             * @property {string|null} [pageToken] ListUserDevicesRequest pageToken
             */

            /**
             * Constructs a new ListUserDevicesRequest.
             * @memberof streem.api
             * @classdesc Represents a ListUserDevicesRequest.
             * @implements IListUserDevicesRequest
             * @constructor
             * @param {streem.api.IListUserDevicesRequest=} [p] Properties to set
             */
            function ListUserDevicesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserDevicesRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.ListUserDevicesRequest
             * @instance
             */
            ListUserDevicesRequest.prototype.userSid = "";

            /**
             * ListUserDevicesRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListUserDevicesRequest
             * @instance
             */
            ListUserDevicesRequest.prototype.pageSize = 0;

            /**
             * ListUserDevicesRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListUserDevicesRequest
             * @instance
             */
            ListUserDevicesRequest.prototype.pageToken = "";

            /**
             * Creates a new ListUserDevicesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {streem.api.IListUserDevicesRequest=} [properties] Properties to set
             * @returns {streem.api.ListUserDevicesRequest} ListUserDevicesRequest instance
             */
            ListUserDevicesRequest.create = function create(properties) {
                return new ListUserDevicesRequest(properties);
            };

            /**
             * Encodes the specified ListUserDevicesRequest message. Does not implicitly {@link streem.api.ListUserDevicesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {streem.api.IListUserDevicesRequest} m ListUserDevicesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserDevicesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListUserDevicesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserDevicesRequest} ListUserDevicesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserDevicesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserDevicesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserDevicesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserDevicesRequest} ListUserDevicesRequest
             */
            ListUserDevicesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserDevicesRequest)
                    return d;
                var m = new $root.streem.api.ListUserDevicesRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserDevicesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {streem.api.ListUserDevicesRequest} m ListUserDevicesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserDevicesRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListUserDevicesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserDevicesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserDevicesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserDevicesRequest
             * @function getTypeUrl
             * @memberof streem.api.ListUserDevicesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserDevicesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserDevicesRequest";
            };

            return ListUserDevicesRequest;
        })();

        api.ListUserDevicesResponse = (function() {

            /**
             * Properties of a ListUserDevicesResponse.
             * @memberof streem.api
             * @interface IListUserDevicesResponse
             * @property {Array.<streem.api.IDevice>|null} [devices] ListUserDevicesResponse devices
             * @property {string|null} [nextPageToken] ListUserDevicesResponse nextPageToken
             * @property {number|null} [totalSize] ListUserDevicesResponse totalSize
             */

            /**
             * Constructs a new ListUserDevicesResponse.
             * @memberof streem.api
             * @classdesc Represents a ListUserDevicesResponse.
             * @implements IListUserDevicesResponse
             * @constructor
             * @param {streem.api.IListUserDevicesResponse=} [p] Properties to set
             */
            function ListUserDevicesResponse(p) {
                this.devices = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListUserDevicesResponse devices.
             * @member {Array.<streem.api.IDevice>} devices
             * @memberof streem.api.ListUserDevicesResponse
             * @instance
             */
            ListUserDevicesResponse.prototype.devices = $util.emptyArray;

            /**
             * ListUserDevicesResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListUserDevicesResponse
             * @instance
             */
            ListUserDevicesResponse.prototype.nextPageToken = "";

            /**
             * ListUserDevicesResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListUserDevicesResponse
             * @instance
             */
            ListUserDevicesResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListUserDevicesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {streem.api.IListUserDevicesResponse=} [properties] Properties to set
             * @returns {streem.api.ListUserDevicesResponse} ListUserDevicesResponse instance
             */
            ListUserDevicesResponse.create = function create(properties) {
                return new ListUserDevicesResponse(properties);
            };

            /**
             * Encodes the specified ListUserDevicesResponse message. Does not implicitly {@link streem.api.ListUserDevicesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {streem.api.IListUserDevicesResponse} m ListUserDevicesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListUserDevicesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.devices != null && m.devices.length) {
                    for (var i = 0; i < m.devices.length; ++i)
                        $root.streem.api.Device.encode(m.devices[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListUserDevicesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListUserDevicesResponse} ListUserDevicesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListUserDevicesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListUserDevicesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.devices && m.devices.length))
                                m.devices = [];
                            m.devices.push($root.streem.api.Device.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListUserDevicesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListUserDevicesResponse} ListUserDevicesResponse
             */
            ListUserDevicesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListUserDevicesResponse)
                    return d;
                var m = new $root.streem.api.ListUserDevicesResponse();
                if (d.devices) {
                    if (!Array.isArray(d.devices))
                        throw TypeError(".streem.api.ListUserDevicesResponse.devices: array expected");
                    m.devices = [];
                    for (var i = 0; i < d.devices.length; ++i) {
                        if (typeof d.devices[i] !== "object")
                            throw TypeError(".streem.api.ListUserDevicesResponse.devices: object expected");
                        m.devices[i] = $root.streem.api.Device.fromObject(d.devices[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListUserDevicesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {streem.api.ListUserDevicesResponse} m ListUserDevicesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListUserDevicesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.devices = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.devices && m.devices.length) {
                    d.devices = [];
                    for (var j = 0; j < m.devices.length; ++j) {
                        d.devices[j] = $root.streem.api.Device.toObject(m.devices[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListUserDevicesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListUserDevicesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListUserDevicesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListUserDevicesResponse
             * @function getTypeUrl
             * @memberof streem.api.ListUserDevicesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListUserDevicesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListUserDevicesResponse";
            };

            return ListUserDevicesResponse;
        })();

        api.CreateUserAssetRequest = (function() {

            /**
             * Properties of a CreateUserAssetRequest.
             * @memberof streem.api
             * @interface ICreateUserAssetRequest
             * @property {streem.api.UserUploadType|null} [type] CreateUserAssetRequest type
             * @property {string|null} [companyCodeOrSid] CreateUserAssetRequest companyCodeOrSid
             * @property {string|null} [userSid] CreateUserAssetRequest userSid
             * @property {google.api.IHttpBody|null} [httpBody] CreateUserAssetRequest httpBody
             * @property {string|null} [filename] CreateUserAssetRequest filename
             */

            /**
             * Constructs a new CreateUserAssetRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateUserAssetRequest.
             * @implements ICreateUserAssetRequest
             * @constructor
             * @param {streem.api.ICreateUserAssetRequest=} [p] Properties to set
             */
            function CreateUserAssetRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateUserAssetRequest type.
             * @member {streem.api.UserUploadType} type
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             */
            CreateUserAssetRequest.prototype.type = 0;

            /**
             * CreateUserAssetRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             */
            CreateUserAssetRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateUserAssetRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             */
            CreateUserAssetRequest.prototype.userSid = "";

            /**
             * CreateUserAssetRequest httpBody.
             * @member {google.api.IHttpBody|null|undefined} httpBody
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             */
            CreateUserAssetRequest.prototype.httpBody = null;

            /**
             * CreateUserAssetRequest filename.
             * @member {string} filename
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             */
            CreateUserAssetRequest.prototype.filename = "";

            /**
             * Creates a new CreateUserAssetRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {streem.api.ICreateUserAssetRequest=} [properties] Properties to set
             * @returns {streem.api.CreateUserAssetRequest} CreateUserAssetRequest instance
             */
            CreateUserAssetRequest.create = function create(properties) {
                return new CreateUserAssetRequest(properties);
            };

            /**
             * Encodes the specified CreateUserAssetRequest message. Does not implicitly {@link streem.api.CreateUserAssetRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {streem.api.ICreateUserAssetRequest} m CreateUserAssetRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateUserAssetRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(8).int32(m.type);
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(18).string(m.companyCodeOrSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(26).string(m.userSid);
                if (m.httpBody != null && Object.hasOwnProperty.call(m, "httpBody"))
                    $root.google.api.HttpBody.encode(m.httpBody, w.uint32(34).fork()).ldelim();
                if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                    w.uint32(42).string(m.filename);
                return w;
            };

            /**
             * Decodes a CreateUserAssetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateUserAssetRequest} CreateUserAssetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateUserAssetRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateUserAssetRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.type = r.int32();
                            break;
                        }
                    case 2: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 3: {
                            m.userSid = r.string();
                            break;
                        }
                    case 4: {
                            m.httpBody = $root.google.api.HttpBody.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.filename = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateUserAssetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateUserAssetRequest} CreateUserAssetRequest
             */
            CreateUserAssetRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateUserAssetRequest)
                    return d;
                var m = new $root.streem.api.CreateUserAssetRequest();
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "USER_UPLOAD_TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "USER_UPLOAD_TYPE_AVATAR":
                case 1:
                    m.type = 1;
                    break;
                }
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.httpBody != null) {
                    if (typeof d.httpBody !== "object")
                        throw TypeError(".streem.api.CreateUserAssetRequest.httpBody: object expected");
                    m.httpBody = $root.google.api.HttpBody.fromObject(d.httpBody);
                }
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateUserAssetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {streem.api.CreateUserAssetRequest} m CreateUserAssetRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateUserAssetRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "USER_UPLOAD_TYPE_INVALID" : 0;
                    d.companyCodeOrSid = "";
                    d.userSid = "";
                    d.httpBody = null;
                    d.filename = "";
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.UserUploadType[m.type] === undefined ? m.type : $root.streem.api.UserUploadType[m.type] : m.type;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.httpBody != null && m.hasOwnProperty("httpBody")) {
                    d.httpBody = $root.google.api.HttpBody.toObject(m.httpBody, o);
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                return d;
            };

            /**
             * Converts this CreateUserAssetRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateUserAssetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateUserAssetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateUserAssetRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateUserAssetRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateUserAssetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateUserAssetRequest";
            };

            return CreateUserAssetRequest;
        })();

        api.CreateUserAssetResponse = (function() {

            /**
             * Properties of a CreateUserAssetResponse.
             * @memberof streem.api
             * @interface ICreateUserAssetResponse
             * @property {string|null} [storageUrl] CreateUserAssetResponse storageUrl
             * @property {string|null} [downloadUrl] CreateUserAssetResponse downloadUrl
             */

            /**
             * Constructs a new CreateUserAssetResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateUserAssetResponse.
             * @implements ICreateUserAssetResponse
             * @constructor
             * @param {streem.api.ICreateUserAssetResponse=} [p] Properties to set
             */
            function CreateUserAssetResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateUserAssetResponse storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CreateUserAssetResponse
             * @instance
             */
            CreateUserAssetResponse.prototype.storageUrl = "";

            /**
             * CreateUserAssetResponse downloadUrl.
             * @member {string} downloadUrl
             * @memberof streem.api.CreateUserAssetResponse
             * @instance
             */
            CreateUserAssetResponse.prototype.downloadUrl = "";

            /**
             * Creates a new CreateUserAssetResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {streem.api.ICreateUserAssetResponse=} [properties] Properties to set
             * @returns {streem.api.CreateUserAssetResponse} CreateUserAssetResponse instance
             */
            CreateUserAssetResponse.create = function create(properties) {
                return new CreateUserAssetResponse(properties);
            };

            /**
             * Encodes the specified CreateUserAssetResponse message. Does not implicitly {@link streem.api.CreateUserAssetResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {streem.api.ICreateUserAssetResponse} m CreateUserAssetResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateUserAssetResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(10).string(m.storageUrl);
                if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                    w.uint32(18).string(m.downloadUrl);
                return w;
            };

            /**
             * Decodes a CreateUserAssetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateUserAssetResponse} CreateUserAssetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateUserAssetResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateUserAssetResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.downloadUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateUserAssetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateUserAssetResponse} CreateUserAssetResponse
             */
            CreateUserAssetResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateUserAssetResponse)
                    return d;
                var m = new $root.streem.api.CreateUserAssetResponse();
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                if (d.downloadUrl != null) {
                    m.downloadUrl = String(d.downloadUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateUserAssetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {streem.api.CreateUserAssetResponse} m CreateUserAssetResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateUserAssetResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storageUrl = "";
                    d.downloadUrl = "";
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                    d.downloadUrl = m.downloadUrl;
                }
                return d;
            };

            /**
             * Converts this CreateUserAssetResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateUserAssetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateUserAssetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateUserAssetResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateUserAssetResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateUserAssetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateUserAssetResponse";
            };

            return CreateUserAssetResponse;
        })();

        api.ActivateUserRequest = (function() {

            /**
             * Properties of an ActivateUserRequest.
             * @memberof streem.api
             * @interface IActivateUserRequest
             * @property {string|null} [userSid] ActivateUserRequest userSid
             */

            /**
             * Constructs a new ActivateUserRequest.
             * @memberof streem.api
             * @classdesc Represents an ActivateUserRequest.
             * @implements IActivateUserRequest
             * @constructor
             * @param {streem.api.IActivateUserRequest=} [p] Properties to set
             */
            function ActivateUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ActivateUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.ActivateUserRequest
             * @instance
             */
            ActivateUserRequest.prototype.userSid = "";

            /**
             * Creates a new ActivateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {streem.api.IActivateUserRequest=} [properties] Properties to set
             * @returns {streem.api.ActivateUserRequest} ActivateUserRequest instance
             */
            ActivateUserRequest.create = function create(properties) {
                return new ActivateUserRequest(properties);
            };

            /**
             * Encodes the specified ActivateUserRequest message. Does not implicitly {@link streem.api.ActivateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {streem.api.IActivateUserRequest} m ActivateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActivateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes an ActivateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ActivateUserRequest} ActivateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActivateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ActivateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ActivateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ActivateUserRequest} ActivateUserRequest
             */
            ActivateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ActivateUserRequest)
                    return d;
                var m = new $root.streem.api.ActivateUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an ActivateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {streem.api.ActivateUserRequest} m ActivateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ActivateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this ActivateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ActivateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ActivateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ActivateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.ActivateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ActivateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ActivateUserRequest";
            };

            return ActivateUserRequest;
        })();

        api.DeactivateUserRequest = (function() {

            /**
             * Properties of a DeactivateUserRequest.
             * @memberof streem.api
             * @interface IDeactivateUserRequest
             * @property {string|null} [userSid] DeactivateUserRequest userSid
             */

            /**
             * Constructs a new DeactivateUserRequest.
             * @memberof streem.api
             * @classdesc Represents a DeactivateUserRequest.
             * @implements IDeactivateUserRequest
             * @constructor
             * @param {streem.api.IDeactivateUserRequest=} [p] Properties to set
             */
            function DeactivateUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeactivateUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.DeactivateUserRequest
             * @instance
             */
            DeactivateUserRequest.prototype.userSid = "";

            /**
             * Creates a new DeactivateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {streem.api.IDeactivateUserRequest=} [properties] Properties to set
             * @returns {streem.api.DeactivateUserRequest} DeactivateUserRequest instance
             */
            DeactivateUserRequest.create = function create(properties) {
                return new DeactivateUserRequest(properties);
            };

            /**
             * Encodes the specified DeactivateUserRequest message. Does not implicitly {@link streem.api.DeactivateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {streem.api.IDeactivateUserRequest} m DeactivateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeactivateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a DeactivateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeactivateUserRequest} DeactivateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeactivateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeactivateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeactivateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeactivateUserRequest} DeactivateUserRequest
             */
            DeactivateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeactivateUserRequest)
                    return d;
                var m = new $root.streem.api.DeactivateUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeactivateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {streem.api.DeactivateUserRequest} m DeactivateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeactivateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this DeactivateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeactivateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeactivateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeactivateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.DeactivateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeactivateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeactivateUserRequest";
            };

            return DeactivateUserRequest;
        })();

        api.GetUserTermsStatusRequest = (function() {

            /**
             * Properties of a GetUserTermsStatusRequest.
             * @memberof streem.api
             * @interface IGetUserTermsStatusRequest
             * @property {string|null} [userSid] GetUserTermsStatusRequest userSid
             */

            /**
             * Constructs a new GetUserTermsStatusRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserTermsStatusRequest.
             * @implements IGetUserTermsStatusRequest
             * @constructor
             * @param {streem.api.IGetUserTermsStatusRequest=} [p] Properties to set
             */
            function GetUserTermsStatusRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserTermsStatusRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetUserTermsStatusRequest
             * @instance
             */
            GetUserTermsStatusRequest.prototype.userSid = "";

            /**
             * Creates a new GetUserTermsStatusRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {streem.api.IGetUserTermsStatusRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserTermsStatusRequest} GetUserTermsStatusRequest instance
             */
            GetUserTermsStatusRequest.create = function create(properties) {
                return new GetUserTermsStatusRequest(properties);
            };

            /**
             * Encodes the specified GetUserTermsStatusRequest message. Does not implicitly {@link streem.api.GetUserTermsStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {streem.api.IGetUserTermsStatusRequest} m GetUserTermsStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserTermsStatusRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a GetUserTermsStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserTermsStatusRequest} GetUserTermsStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserTermsStatusRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserTermsStatusRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserTermsStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserTermsStatusRequest} GetUserTermsStatusRequest
             */
            GetUserTermsStatusRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserTermsStatusRequest)
                    return d;
                var m = new $root.streem.api.GetUserTermsStatusRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserTermsStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {streem.api.GetUserTermsStatusRequest} m GetUserTermsStatusRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserTermsStatusRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this GetUserTermsStatusRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserTermsStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserTermsStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserTermsStatusRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserTermsStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserTermsStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserTermsStatusRequest";
            };

            return GetUserTermsStatusRequest;
        })();

        api.GetUserTermsStatusResponse = (function() {

            /**
             * Properties of a GetUserTermsStatusResponse.
             * @memberof streem.api
             * @interface IGetUserTermsStatusResponse
             * @property {streem.api.IUserTermsStatus|null} [status] GetUserTermsStatusResponse status
             */

            /**
             * Constructs a new GetUserTermsStatusResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserTermsStatusResponse.
             * @implements IGetUserTermsStatusResponse
             * @constructor
             * @param {streem.api.IGetUserTermsStatusResponse=} [p] Properties to set
             */
            function GetUserTermsStatusResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserTermsStatusResponse status.
             * @member {streem.api.IUserTermsStatus|null|undefined} status
             * @memberof streem.api.GetUserTermsStatusResponse
             * @instance
             */
            GetUserTermsStatusResponse.prototype.status = null;

            /**
             * Creates a new GetUserTermsStatusResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {streem.api.IGetUserTermsStatusResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserTermsStatusResponse} GetUserTermsStatusResponse instance
             */
            GetUserTermsStatusResponse.create = function create(properties) {
                return new GetUserTermsStatusResponse(properties);
            };

            /**
             * Encodes the specified GetUserTermsStatusResponse message. Does not implicitly {@link streem.api.GetUserTermsStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {streem.api.IGetUserTermsStatusResponse} m GetUserTermsStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserTermsStatusResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    $root.streem.api.UserTermsStatus.encode(m.status, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetUserTermsStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserTermsStatusResponse} GetUserTermsStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserTermsStatusResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserTermsStatusResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.status = $root.streem.api.UserTermsStatus.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserTermsStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserTermsStatusResponse} GetUserTermsStatusResponse
             */
            GetUserTermsStatusResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserTermsStatusResponse)
                    return d;
                var m = new $root.streem.api.GetUserTermsStatusResponse();
                if (d.status != null) {
                    if (typeof d.status !== "object")
                        throw TypeError(".streem.api.GetUserTermsStatusResponse.status: object expected");
                    m.status = $root.streem.api.UserTermsStatus.fromObject(d.status);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserTermsStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {streem.api.GetUserTermsStatusResponse} m GetUserTermsStatusResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserTermsStatusResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.status = null;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = $root.streem.api.UserTermsStatus.toObject(m.status, o);
                }
                return d;
            };

            /**
             * Converts this GetUserTermsStatusResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserTermsStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserTermsStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserTermsStatusResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserTermsStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserTermsStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserTermsStatusResponse";
            };

            return GetUserTermsStatusResponse;
        })();

        api.SaveUserTermsStatusRequest = (function() {

            /**
             * Properties of a SaveUserTermsStatusRequest.
             * @memberof streem.api
             * @interface ISaveUserTermsStatusRequest
             * @property {string|null} [userSid] SaveUserTermsStatusRequest userSid
             * @property {boolean|null} [acceptedLatest] SaveUserTermsStatusRequest acceptedLatest
             */

            /**
             * Constructs a new SaveUserTermsStatusRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveUserTermsStatusRequest.
             * @implements ISaveUserTermsStatusRequest
             * @constructor
             * @param {streem.api.ISaveUserTermsStatusRequest=} [p] Properties to set
             */
            function SaveUserTermsStatusRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveUserTermsStatusRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @instance
             */
            SaveUserTermsStatusRequest.prototype.userSid = "";

            /**
             * SaveUserTermsStatusRequest acceptedLatest.
             * @member {boolean} acceptedLatest
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @instance
             */
            SaveUserTermsStatusRequest.prototype.acceptedLatest = false;

            /**
             * Creates a new SaveUserTermsStatusRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {streem.api.ISaveUserTermsStatusRequest=} [properties] Properties to set
             * @returns {streem.api.SaveUserTermsStatusRequest} SaveUserTermsStatusRequest instance
             */
            SaveUserTermsStatusRequest.create = function create(properties) {
                return new SaveUserTermsStatusRequest(properties);
            };

            /**
             * Encodes the specified SaveUserTermsStatusRequest message. Does not implicitly {@link streem.api.SaveUserTermsStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {streem.api.ISaveUserTermsStatusRequest} m SaveUserTermsStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveUserTermsStatusRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.acceptedLatest != null && Object.hasOwnProperty.call(m, "acceptedLatest"))
                    w.uint32(16).bool(m.acceptedLatest);
                return w;
            };

            /**
             * Decodes a SaveUserTermsStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveUserTermsStatusRequest} SaveUserTermsStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveUserTermsStatusRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveUserTermsStatusRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.acceptedLatest = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveUserTermsStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveUserTermsStatusRequest} SaveUserTermsStatusRequest
             */
            SaveUserTermsStatusRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveUserTermsStatusRequest)
                    return d;
                var m = new $root.streem.api.SaveUserTermsStatusRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.acceptedLatest != null) {
                    m.acceptedLatest = Boolean(d.acceptedLatest);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveUserTermsStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {streem.api.SaveUserTermsStatusRequest} m SaveUserTermsStatusRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveUserTermsStatusRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.acceptedLatest = false;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.acceptedLatest != null && m.hasOwnProperty("acceptedLatest")) {
                    d.acceptedLatest = m.acceptedLatest;
                }
                return d;
            };

            /**
             * Converts this SaveUserTermsStatusRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveUserTermsStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveUserTermsStatusRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveUserTermsStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveUserTermsStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveUserTermsStatusRequest";
            };

            return SaveUserTermsStatusRequest;
        })();

        api.SaveUserTermsStatusResponse = (function() {

            /**
             * Properties of a SaveUserTermsStatusResponse.
             * @memberof streem.api
             * @interface ISaveUserTermsStatusResponse
             * @property {streem.api.IUserTermsStatus|null} [status] SaveUserTermsStatusResponse status
             */

            /**
             * Constructs a new SaveUserTermsStatusResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveUserTermsStatusResponse.
             * @implements ISaveUserTermsStatusResponse
             * @constructor
             * @param {streem.api.ISaveUserTermsStatusResponse=} [p] Properties to set
             */
            function SaveUserTermsStatusResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveUserTermsStatusResponse status.
             * @member {streem.api.IUserTermsStatus|null|undefined} status
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @instance
             */
            SaveUserTermsStatusResponse.prototype.status = null;

            /**
             * Creates a new SaveUserTermsStatusResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {streem.api.ISaveUserTermsStatusResponse=} [properties] Properties to set
             * @returns {streem.api.SaveUserTermsStatusResponse} SaveUserTermsStatusResponse instance
             */
            SaveUserTermsStatusResponse.create = function create(properties) {
                return new SaveUserTermsStatusResponse(properties);
            };

            /**
             * Encodes the specified SaveUserTermsStatusResponse message. Does not implicitly {@link streem.api.SaveUserTermsStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {streem.api.ISaveUserTermsStatusResponse} m SaveUserTermsStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveUserTermsStatusResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    $root.streem.api.UserTermsStatus.encode(m.status, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveUserTermsStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveUserTermsStatusResponse} SaveUserTermsStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveUserTermsStatusResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveUserTermsStatusResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.status = $root.streem.api.UserTermsStatus.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveUserTermsStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveUserTermsStatusResponse} SaveUserTermsStatusResponse
             */
            SaveUserTermsStatusResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveUserTermsStatusResponse)
                    return d;
                var m = new $root.streem.api.SaveUserTermsStatusResponse();
                if (d.status != null) {
                    if (typeof d.status !== "object")
                        throw TypeError(".streem.api.SaveUserTermsStatusResponse.status: object expected");
                    m.status = $root.streem.api.UserTermsStatus.fromObject(d.status);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveUserTermsStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {streem.api.SaveUserTermsStatusResponse} m SaveUserTermsStatusResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveUserTermsStatusResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.status = null;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = $root.streem.api.UserTermsStatus.toObject(m.status, o);
                }
                return d;
            };

            /**
             * Converts this SaveUserTermsStatusResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveUserTermsStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveUserTermsStatusResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveUserTermsStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveUserTermsStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveUserTermsStatusResponse";
            };

            return SaveUserTermsStatusResponse;
        })();

        api.GetAssociatedCompaniesRequest = (function() {

            /**
             * Properties of a GetAssociatedCompaniesRequest.
             * @memberof streem.api
             * @interface IGetAssociatedCompaniesRequest
             * @property {string|null} [userSid] GetAssociatedCompaniesRequest userSid
             */

            /**
             * Constructs a new GetAssociatedCompaniesRequest.
             * @memberof streem.api
             * @classdesc Represents a GetAssociatedCompaniesRequest.
             * @implements IGetAssociatedCompaniesRequest
             * @constructor
             * @param {streem.api.IGetAssociatedCompaniesRequest=} [p] Properties to set
             */
            function GetAssociatedCompaniesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetAssociatedCompaniesRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @instance
             */
            GetAssociatedCompaniesRequest.prototype.userSid = "";

            /**
             * Creates a new GetAssociatedCompaniesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {streem.api.IGetAssociatedCompaniesRequest=} [properties] Properties to set
             * @returns {streem.api.GetAssociatedCompaniesRequest} GetAssociatedCompaniesRequest instance
             */
            GetAssociatedCompaniesRequest.create = function create(properties) {
                return new GetAssociatedCompaniesRequest(properties);
            };

            /**
             * Encodes the specified GetAssociatedCompaniesRequest message. Does not implicitly {@link streem.api.GetAssociatedCompaniesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {streem.api.IGetAssociatedCompaniesRequest} m GetAssociatedCompaniesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAssociatedCompaniesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                return w;
            };

            /**
             * Decodes a GetAssociatedCompaniesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAssociatedCompaniesRequest} GetAssociatedCompaniesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAssociatedCompaniesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAssociatedCompaniesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAssociatedCompaniesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAssociatedCompaniesRequest} GetAssociatedCompaniesRequest
             */
            GetAssociatedCompaniesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAssociatedCompaniesRequest)
                    return d;
                var m = new $root.streem.api.GetAssociatedCompaniesRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetAssociatedCompaniesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {streem.api.GetAssociatedCompaniesRequest} m GetAssociatedCompaniesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAssociatedCompaniesRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                return d;
            };

            /**
             * Converts this GetAssociatedCompaniesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAssociatedCompaniesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAssociatedCompaniesRequest
             * @function getTypeUrl
             * @memberof streem.api.GetAssociatedCompaniesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAssociatedCompaniesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAssociatedCompaniesRequest";
            };

            return GetAssociatedCompaniesRequest;
        })();

        api.GetAssociatedCompaniesResponse = (function() {

            /**
             * Properties of a GetAssociatedCompaniesResponse.
             * @memberof streem.api
             * @interface IGetAssociatedCompaniesResponse
             * @property {Array.<streem.api.ICompany>|null} [companies] GetAssociatedCompaniesResponse companies
             */

            /**
             * Constructs a new GetAssociatedCompaniesResponse.
             * @memberof streem.api
             * @classdesc Represents a GetAssociatedCompaniesResponse.
             * @implements IGetAssociatedCompaniesResponse
             * @constructor
             * @param {streem.api.IGetAssociatedCompaniesResponse=} [p] Properties to set
             */
            function GetAssociatedCompaniesResponse(p) {
                this.companies = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetAssociatedCompaniesResponse companies.
             * @member {Array.<streem.api.ICompany>} companies
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @instance
             */
            GetAssociatedCompaniesResponse.prototype.companies = $util.emptyArray;

            /**
             * Creates a new GetAssociatedCompaniesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {streem.api.IGetAssociatedCompaniesResponse=} [properties] Properties to set
             * @returns {streem.api.GetAssociatedCompaniesResponse} GetAssociatedCompaniesResponse instance
             */
            GetAssociatedCompaniesResponse.create = function create(properties) {
                return new GetAssociatedCompaniesResponse(properties);
            };

            /**
             * Encodes the specified GetAssociatedCompaniesResponse message. Does not implicitly {@link streem.api.GetAssociatedCompaniesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {streem.api.IGetAssociatedCompaniesResponse} m GetAssociatedCompaniesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAssociatedCompaniesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companies != null && m.companies.length) {
                    for (var i = 0; i < m.companies.length; ++i)
                        $root.streem.api.Company.encode(m.companies[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetAssociatedCompaniesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetAssociatedCompaniesResponse} GetAssociatedCompaniesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAssociatedCompaniesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetAssociatedCompaniesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.companies && m.companies.length))
                                m.companies = [];
                            m.companies.push($root.streem.api.Company.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetAssociatedCompaniesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetAssociatedCompaniesResponse} GetAssociatedCompaniesResponse
             */
            GetAssociatedCompaniesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetAssociatedCompaniesResponse)
                    return d;
                var m = new $root.streem.api.GetAssociatedCompaniesResponse();
                if (d.companies) {
                    if (!Array.isArray(d.companies))
                        throw TypeError(".streem.api.GetAssociatedCompaniesResponse.companies: array expected");
                    m.companies = [];
                    for (var i = 0; i < d.companies.length; ++i) {
                        if (typeof d.companies[i] !== "object")
                            throw TypeError(".streem.api.GetAssociatedCompaniesResponse.companies: object expected");
                        m.companies[i] = $root.streem.api.Company.fromObject(d.companies[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetAssociatedCompaniesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {streem.api.GetAssociatedCompaniesResponse} m GetAssociatedCompaniesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAssociatedCompaniesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.companies = [];
                }
                if (m.companies && m.companies.length) {
                    d.companies = [];
                    for (var j = 0; j < m.companies.length; ++j) {
                        d.companies[j] = $root.streem.api.Company.toObject(m.companies[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetAssociatedCompaniesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAssociatedCompaniesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAssociatedCompaniesResponse
             * @function getTypeUrl
             * @memberof streem.api.GetAssociatedCompaniesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAssociatedCompaniesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetAssociatedCompaniesResponse";
            };

            return GetAssociatedCompaniesResponse;
        })();

        api.UpdateUserRequest = (function() {

            /**
             * Properties of an UpdateUserRequest.
             * @memberof streem.api
             * @interface IUpdateUserRequest
             * @property {string|null} [userSid] UpdateUserRequest userSid
             * @property {google.protobuf.IStringValue|null} [name] UpdateUserRequest name
             * @property {google.protobuf.IStringValue|null} [email] UpdateUserRequest email
             * @property {google.protobuf.IStringValue|null} [phone] UpdateUserRequest phone
             * @property {google.protobuf.IStringValue|null} [avatarUrl] UpdateUserRequest avatarUrl
             * @property {google.protobuf.IStringValue|null} [bio] UpdateUserRequest bio
             */

            /**
             * Constructs a new UpdateUserRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserRequest.
             * @implements IUpdateUserRequest
             * @constructor
             * @param {streem.api.IUpdateUserRequest=} [p] Properties to set
             */
            function UpdateUserRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.userSid = "";

            /**
             * UpdateUserRequest name.
             * @member {google.protobuf.IStringValue|null|undefined} name
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.name = null;

            /**
             * UpdateUserRequest email.
             * @member {google.protobuf.IStringValue|null|undefined} email
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.email = null;

            /**
             * UpdateUserRequest phone.
             * @member {google.protobuf.IStringValue|null|undefined} phone
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.phone = null;

            /**
             * UpdateUserRequest avatarUrl.
             * @member {google.protobuf.IStringValue|null|undefined} avatarUrl
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.avatarUrl = null;

            /**
             * UpdateUserRequest bio.
             * @member {google.protobuf.IStringValue|null|undefined} bio
             * @memberof streem.api.UpdateUserRequest
             * @instance
             */
            UpdateUserRequest.prototype.bio = null;

            /**
             * Creates a new UpdateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {streem.api.IUpdateUserRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateUserRequest} UpdateUserRequest instance
             */
            UpdateUserRequest.create = function create(properties) {
                return new UpdateUserRequest(properties);
            };

            /**
             * Encodes the specified UpdateUserRequest message. Does not implicitly {@link streem.api.UpdateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {streem.api.IUpdateUserRequest} m UpdateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    $root.google.protobuf.StringValue.encode(m.name, w.uint32(18).fork()).ldelim();
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    $root.google.protobuf.StringValue.encode(m.email, w.uint32(26).fork()).ldelim();
                if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                    $root.google.protobuf.StringValue.encode(m.phone, w.uint32(34).fork()).ldelim();
                if (m.avatarUrl != null && Object.hasOwnProperty.call(m, "avatarUrl"))
                    $root.google.protobuf.StringValue.encode(m.avatarUrl, w.uint32(42).fork()).ldelim();
                if (m.bio != null && Object.hasOwnProperty.call(m, "bio"))
                    $root.google.protobuf.StringValue.encode(m.bio, w.uint32(58).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserRequest} UpdateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.email = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.phone = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.avatarUrl = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            m.bio = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserRequest} UpdateUserRequest
             */
            UpdateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserRequest)
                    return d;
                var m = new $root.streem.api.UpdateUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.name != null) {
                    if (typeof d.name !== "object")
                        throw TypeError(".streem.api.UpdateUserRequest.name: object expected");
                    m.name = $root.google.protobuf.StringValue.fromObject(d.name);
                }
                if (d.email != null) {
                    if (typeof d.email !== "object")
                        throw TypeError(".streem.api.UpdateUserRequest.email: object expected");
                    m.email = $root.google.protobuf.StringValue.fromObject(d.email);
                }
                if (d.phone != null) {
                    if (typeof d.phone !== "object")
                        throw TypeError(".streem.api.UpdateUserRequest.phone: object expected");
                    m.phone = $root.google.protobuf.StringValue.fromObject(d.phone);
                }
                if (d.avatarUrl != null) {
                    if (typeof d.avatarUrl !== "object")
                        throw TypeError(".streem.api.UpdateUserRequest.avatarUrl: object expected");
                    m.avatarUrl = $root.google.protobuf.StringValue.fromObject(d.avatarUrl);
                }
                if (d.bio != null) {
                    if (typeof d.bio !== "object")
                        throw TypeError(".streem.api.UpdateUserRequest.bio: object expected");
                    m.bio = $root.google.protobuf.StringValue.fromObject(d.bio);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {streem.api.UpdateUserRequest} m UpdateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.name = null;
                    d.email = null;
                    d.phone = null;
                    d.avatarUrl = null;
                    d.bio = null;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = $root.google.protobuf.StringValue.toObject(m.name, o);
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = $root.google.protobuf.StringValue.toObject(m.email, o);
                }
                if (m.phone != null && m.hasOwnProperty("phone")) {
                    d.phone = $root.google.protobuf.StringValue.toObject(m.phone, o);
                }
                if (m.avatarUrl != null && m.hasOwnProperty("avatarUrl")) {
                    d.avatarUrl = $root.google.protobuf.StringValue.toObject(m.avatarUrl, o);
                }
                if (m.bio != null && m.hasOwnProperty("bio")) {
                    d.bio = $root.google.protobuf.StringValue.toObject(m.bio, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserRequest";
            };

            return UpdateUserRequest;
        })();

        api.UpdateUserResponse = (function() {

            /**
             * Properties of an UpdateUserResponse.
             * @memberof streem.api
             * @interface IUpdateUserResponse
             * @property {streem.api.IUser|null} [user] UpdateUserResponse user
             */

            /**
             * Constructs a new UpdateUserResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserResponse.
             * @implements IUpdateUserResponse
             * @constructor
             * @param {streem.api.IUpdateUserResponse=} [p] Properties to set
             */
            function UpdateUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.UpdateUserResponse
             * @instance
             */
            UpdateUserResponse.prototype.user = null;

            /**
             * Creates a new UpdateUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {streem.api.IUpdateUserResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateUserResponse} UpdateUserResponse instance
             */
            UpdateUserResponse.create = function create(properties) {
                return new UpdateUserResponse(properties);
            };

            /**
             * Encodes the specified UpdateUserResponse message. Does not implicitly {@link streem.api.UpdateUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {streem.api.IUpdateUserResponse} m UpdateUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserResponse} UpdateUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserResponse} UpdateUserResponse
             */
            UpdateUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserResponse)
                    return d;
                var m = new $root.streem.api.UpdateUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.UpdateUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {streem.api.UpdateUserResponse} m UpdateUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserResponse";
            };

            return UpdateUserResponse;
        })();

        api.UpdateGroupsForUserRequest = (function() {

            /**
             * Properties of an UpdateGroupsForUserRequest.
             * @memberof streem.api
             * @interface IUpdateGroupsForUserRequest
             * @property {string|null} [userSid] UpdateGroupsForUserRequest userSid
             * @property {Array.<string>|null} [groups] UpdateGroupsForUserRequest groups
             */

            /**
             * Constructs a new UpdateGroupsForUserRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateGroupsForUserRequest.
             * @implements IUpdateGroupsForUserRequest
             * @constructor
             * @param {streem.api.IUpdateGroupsForUserRequest=} [p] Properties to set
             */
            function UpdateGroupsForUserRequest(p) {
                this.groups = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateGroupsForUserRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @instance
             */
            UpdateGroupsForUserRequest.prototype.userSid = "";

            /**
             * UpdateGroupsForUserRequest groups.
             * @member {Array.<string>} groups
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @instance
             */
            UpdateGroupsForUserRequest.prototype.groups = $util.emptyArray;

            /**
             * Creates a new UpdateGroupsForUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {streem.api.IUpdateGroupsForUserRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateGroupsForUserRequest} UpdateGroupsForUserRequest instance
             */
            UpdateGroupsForUserRequest.create = function create(properties) {
                return new UpdateGroupsForUserRequest(properties);
            };

            /**
             * Encodes the specified UpdateGroupsForUserRequest message. Does not implicitly {@link streem.api.UpdateGroupsForUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {streem.api.IUpdateGroupsForUserRequest} m UpdateGroupsForUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGroupsForUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.groups != null && m.groups.length) {
                    for (var i = 0; i < m.groups.length; ++i)
                        w.uint32(18).string(m.groups[i]);
                }
                return w;
            };

            /**
             * Decodes an UpdateGroupsForUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateGroupsForUserRequest} UpdateGroupsForUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGroupsForUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateGroupsForUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.groups && m.groups.length))
                                m.groups = [];
                            m.groups.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateGroupsForUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateGroupsForUserRequest} UpdateGroupsForUserRequest
             */
            UpdateGroupsForUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateGroupsForUserRequest)
                    return d;
                var m = new $root.streem.api.UpdateGroupsForUserRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.groups) {
                    if (!Array.isArray(d.groups))
                        throw TypeError(".streem.api.UpdateGroupsForUserRequest.groups: array expected");
                    m.groups = [];
                    for (var i = 0; i < d.groups.length; ++i) {
                        m.groups[i] = String(d.groups[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateGroupsForUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {streem.api.UpdateGroupsForUserRequest} m UpdateGroupsForUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateGroupsForUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groups = [];
                }
                if (o.defaults) {
                    d.userSid = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.groups && m.groups.length) {
                    d.groups = [];
                    for (var j = 0; j < m.groups.length; ++j) {
                        d.groups[j] = m.groups[j];
                    }
                }
                return d;
            };

            /**
             * Converts this UpdateGroupsForUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateGroupsForUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateGroupsForUserRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateGroupsForUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateGroupsForUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateGroupsForUserRequest";
            };

            return UpdateGroupsForUserRequest;
        })();

        api.UpdateGroupsForUserResponse = (function() {

            /**
             * Properties of an UpdateGroupsForUserResponse.
             * @memberof streem.api
             * @interface IUpdateGroupsForUserResponse
             * @property {streem.api.IUser|null} [user] UpdateGroupsForUserResponse user
             */

            /**
             * Constructs a new UpdateGroupsForUserResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateGroupsForUserResponse.
             * @implements IUpdateGroupsForUserResponse
             * @constructor
             * @param {streem.api.IUpdateGroupsForUserResponse=} [p] Properties to set
             */
            function UpdateGroupsForUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateGroupsForUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @instance
             */
            UpdateGroupsForUserResponse.prototype.user = null;

            /**
             * Creates a new UpdateGroupsForUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {streem.api.IUpdateGroupsForUserResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateGroupsForUserResponse} UpdateGroupsForUserResponse instance
             */
            UpdateGroupsForUserResponse.create = function create(properties) {
                return new UpdateGroupsForUserResponse(properties);
            };

            /**
             * Encodes the specified UpdateGroupsForUserResponse message. Does not implicitly {@link streem.api.UpdateGroupsForUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {streem.api.IUpdateGroupsForUserResponse} m UpdateGroupsForUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGroupsForUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateGroupsForUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateGroupsForUserResponse} UpdateGroupsForUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGroupsForUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateGroupsForUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateGroupsForUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateGroupsForUserResponse} UpdateGroupsForUserResponse
             */
            UpdateGroupsForUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateGroupsForUserResponse)
                    return d;
                var m = new $root.streem.api.UpdateGroupsForUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.UpdateGroupsForUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateGroupsForUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {streem.api.UpdateGroupsForUserResponse} m UpdateGroupsForUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateGroupsForUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this UpdateGroupsForUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateGroupsForUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateGroupsForUserResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateGroupsForUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateGroupsForUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateGroupsForUserResponse";
            };

            return UpdateGroupsForUserResponse;
        })();

        api.UpdateUserEmailRequest = (function() {

            /**
             * Properties of an UpdateUserEmailRequest.
             * @memberof streem.api
             * @interface IUpdateUserEmailRequest
             * @property {string|null} [userSid] UpdateUserEmailRequest userSid
             * @property {string|null} [companyCode] UpdateUserEmailRequest companyCode
             * @property {string|null} [email] UpdateUserEmailRequest email
             */

            /**
             * Constructs a new UpdateUserEmailRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserEmailRequest.
             * @implements IUpdateUserEmailRequest
             * @constructor
             * @param {streem.api.IUpdateUserEmailRequest=} [p] Properties to set
             */
            function UpdateUserEmailRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserEmailRequest userSid.
             * @member {string} userSid
             * @memberof streem.api.UpdateUserEmailRequest
             * @instance
             */
            UpdateUserEmailRequest.prototype.userSid = "";

            /**
             * UpdateUserEmailRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.UpdateUserEmailRequest
             * @instance
             */
            UpdateUserEmailRequest.prototype.companyCode = "";

            /**
             * UpdateUserEmailRequest email.
             * @member {string} email
             * @memberof streem.api.UpdateUserEmailRequest
             * @instance
             */
            UpdateUserEmailRequest.prototype.email = "";

            /**
             * Creates a new UpdateUserEmailRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {streem.api.IUpdateUserEmailRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateUserEmailRequest} UpdateUserEmailRequest instance
             */
            UpdateUserEmailRequest.create = function create(properties) {
                return new UpdateUserEmailRequest(properties);
            };

            /**
             * Encodes the specified UpdateUserEmailRequest message. Does not implicitly {@link streem.api.UpdateUserEmailRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {streem.api.IUpdateUserEmailRequest} m UpdateUserEmailRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserEmailRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(26).string(m.email);
                return w;
            };

            /**
             * Decodes an UpdateUserEmailRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserEmailRequest} UpdateUserEmailRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserEmailRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserEmailRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 3: {
                            m.email = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserEmailRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserEmailRequest} UpdateUserEmailRequest
             */
            UpdateUserEmailRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserEmailRequest)
                    return d;
                var m = new $root.streem.api.UpdateUserEmailRequest();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserEmailRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {streem.api.UpdateUserEmailRequest} m UpdateUserEmailRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserEmailRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.companyCode = "";
                    d.email = "";
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                return d;
            };

            /**
             * Converts this UpdateUserEmailRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserEmailRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserEmailRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserEmailRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserEmailRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserEmailRequest";
            };

            return UpdateUserEmailRequest;
        })();

        api.UpdateUserEmailResponse = (function() {

            /**
             * Properties of an UpdateUserEmailResponse.
             * @memberof streem.api
             * @interface IUpdateUserEmailResponse
             * @property {streem.api.IUser|null} [user] UpdateUserEmailResponse user
             */

            /**
             * Constructs a new UpdateUserEmailResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateUserEmailResponse.
             * @implements IUpdateUserEmailResponse
             * @constructor
             * @param {streem.api.IUpdateUserEmailResponse=} [p] Properties to set
             */
            function UpdateUserEmailResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateUserEmailResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.UpdateUserEmailResponse
             * @instance
             */
            UpdateUserEmailResponse.prototype.user = null;

            /**
             * Creates a new UpdateUserEmailResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {streem.api.IUpdateUserEmailResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateUserEmailResponse} UpdateUserEmailResponse instance
             */
            UpdateUserEmailResponse.create = function create(properties) {
                return new UpdateUserEmailResponse(properties);
            };

            /**
             * Encodes the specified UpdateUserEmailResponse message. Does not implicitly {@link streem.api.UpdateUserEmailResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {streem.api.IUpdateUserEmailResponse} m UpdateUserEmailResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserEmailResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateUserEmailResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateUserEmailResponse} UpdateUserEmailResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserEmailResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateUserEmailResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateUserEmailResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateUserEmailResponse} UpdateUserEmailResponse
             */
            UpdateUserEmailResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateUserEmailResponse)
                    return d;
                var m = new $root.streem.api.UpdateUserEmailResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.UpdateUserEmailResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateUserEmailResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {streem.api.UpdateUserEmailResponse} m UpdateUserEmailResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserEmailResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this UpdateUserEmailResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateUserEmailResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserEmailResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserEmailResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateUserEmailResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateUserEmailResponse";
            };

            return UpdateUserEmailResponse;
        })();

        api.GetStreemSurveyStatusRequest = (function() {

            /**
             * Properties of a GetStreemSurveyStatusRequest.
             * @memberof streem.api
             * @interface IGetStreemSurveyStatusRequest
             * @property {string|null} [userId] GetStreemSurveyStatusRequest userId
             */

            /**
             * Constructs a new GetStreemSurveyStatusRequest.
             * @memberof streem.api
             * @classdesc Represents a GetStreemSurveyStatusRequest.
             * @implements IGetStreemSurveyStatusRequest
             * @constructor
             * @param {streem.api.IGetStreemSurveyStatusRequest=} [p] Properties to set
             */
            function GetStreemSurveyStatusRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemSurveyStatusRequest userId.
             * @member {string} userId
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @instance
             */
            GetStreemSurveyStatusRequest.prototype.userId = "";

            /**
             * Creates a new GetStreemSurveyStatusRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {streem.api.IGetStreemSurveyStatusRequest=} [properties] Properties to set
             * @returns {streem.api.GetStreemSurveyStatusRequest} GetStreemSurveyStatusRequest instance
             */
            GetStreemSurveyStatusRequest.create = function create(properties) {
                return new GetStreemSurveyStatusRequest(properties);
            };

            /**
             * Encodes the specified GetStreemSurveyStatusRequest message. Does not implicitly {@link streem.api.GetStreemSurveyStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {streem.api.IGetStreemSurveyStatusRequest} m GetStreemSurveyStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemSurveyStatusRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(10).string(m.userId);
                return w;
            };

            /**
             * Decodes a GetStreemSurveyStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemSurveyStatusRequest} GetStreemSurveyStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemSurveyStatusRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemSurveyStatusRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemSurveyStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemSurveyStatusRequest} GetStreemSurveyStatusRequest
             */
            GetStreemSurveyStatusRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemSurveyStatusRequest)
                    return d;
                var m = new $root.streem.api.GetStreemSurveyStatusRequest();
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemSurveyStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {streem.api.GetStreemSurveyStatusRequest} m GetStreemSurveyStatusRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemSurveyStatusRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userId = "";
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                return d;
            };

            /**
             * Converts this GetStreemSurveyStatusRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemSurveyStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemSurveyStatusRequest
             * @function getTypeUrl
             * @memberof streem.api.GetStreemSurveyStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemSurveyStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemSurveyStatusRequest";
            };

            return GetStreemSurveyStatusRequest;
        })();

        api.GetStreemSurveyStatusResponse = (function() {

            /**
             * Properties of a GetStreemSurveyStatusResponse.
             * @memberof streem.api
             * @interface IGetStreemSurveyStatusResponse
             * @property {streem.api.IStreemSurveyStatus|null} [status] GetStreemSurveyStatusResponse status
             */

            /**
             * Constructs a new GetStreemSurveyStatusResponse.
             * @memberof streem.api
             * @classdesc Represents a GetStreemSurveyStatusResponse.
             * @implements IGetStreemSurveyStatusResponse
             * @constructor
             * @param {streem.api.IGetStreemSurveyStatusResponse=} [p] Properties to set
             */
            function GetStreemSurveyStatusResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetStreemSurveyStatusResponse status.
             * @member {streem.api.IStreemSurveyStatus|null|undefined} status
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @instance
             */
            GetStreemSurveyStatusResponse.prototype.status = null;

            /**
             * Creates a new GetStreemSurveyStatusResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {streem.api.IGetStreemSurveyStatusResponse=} [properties] Properties to set
             * @returns {streem.api.GetStreemSurveyStatusResponse} GetStreemSurveyStatusResponse instance
             */
            GetStreemSurveyStatusResponse.create = function create(properties) {
                return new GetStreemSurveyStatusResponse(properties);
            };

            /**
             * Encodes the specified GetStreemSurveyStatusResponse message. Does not implicitly {@link streem.api.GetStreemSurveyStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {streem.api.IGetStreemSurveyStatusResponse} m GetStreemSurveyStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStreemSurveyStatusResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    $root.streem.api.StreemSurveyStatus.encode(m.status, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetStreemSurveyStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetStreemSurveyStatusResponse} GetStreemSurveyStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStreemSurveyStatusResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetStreemSurveyStatusResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.status = $root.streem.api.StreemSurveyStatus.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetStreemSurveyStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetStreemSurveyStatusResponse} GetStreemSurveyStatusResponse
             */
            GetStreemSurveyStatusResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetStreemSurveyStatusResponse)
                    return d;
                var m = new $root.streem.api.GetStreemSurveyStatusResponse();
                if (d.status != null) {
                    if (typeof d.status !== "object")
                        throw TypeError(".streem.api.GetStreemSurveyStatusResponse.status: object expected");
                    m.status = $root.streem.api.StreemSurveyStatus.fromObject(d.status);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetStreemSurveyStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {streem.api.GetStreemSurveyStatusResponse} m GetStreemSurveyStatusResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStreemSurveyStatusResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.status = null;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = $root.streem.api.StreemSurveyStatus.toObject(m.status, o);
                }
                return d;
            };

            /**
             * Converts this GetStreemSurveyStatusResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStreemSurveyStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStreemSurveyStatusResponse
             * @function getTypeUrl
             * @memberof streem.api.GetStreemSurveyStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStreemSurveyStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetStreemSurveyStatusResponse";
            };

            return GetStreemSurveyStatusResponse;
        })();

        api.SaveStreemSurveyStatusRequest = (function() {

            /**
             * Properties of a SaveStreemSurveyStatusRequest.
             * @memberof streem.api
             * @interface ISaveStreemSurveyStatusRequest
             * @property {string|null} [userId] SaveStreemSurveyStatusRequest userId
             */

            /**
             * Constructs a new SaveStreemSurveyStatusRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveStreemSurveyStatusRequest.
             * @implements ISaveStreemSurveyStatusRequest
             * @constructor
             * @param {streem.api.ISaveStreemSurveyStatusRequest=} [p] Properties to set
             */
            function SaveStreemSurveyStatusRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveStreemSurveyStatusRequest userId.
             * @member {string} userId
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @instance
             */
            SaveStreemSurveyStatusRequest.prototype.userId = "";

            /**
             * Creates a new SaveStreemSurveyStatusRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {streem.api.ISaveStreemSurveyStatusRequest=} [properties] Properties to set
             * @returns {streem.api.SaveStreemSurveyStatusRequest} SaveStreemSurveyStatusRequest instance
             */
            SaveStreemSurveyStatusRequest.create = function create(properties) {
                return new SaveStreemSurveyStatusRequest(properties);
            };

            /**
             * Encodes the specified SaveStreemSurveyStatusRequest message. Does not implicitly {@link streem.api.SaveStreemSurveyStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {streem.api.ISaveStreemSurveyStatusRequest} m SaveStreemSurveyStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveStreemSurveyStatusRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(10).string(m.userId);
                return w;
            };

            /**
             * Decodes a SaveStreemSurveyStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveStreemSurveyStatusRequest} SaveStreemSurveyStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveStreemSurveyStatusRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveStreemSurveyStatusRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveStreemSurveyStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveStreemSurveyStatusRequest} SaveStreemSurveyStatusRequest
             */
            SaveStreemSurveyStatusRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveStreemSurveyStatusRequest)
                    return d;
                var m = new $root.streem.api.SaveStreemSurveyStatusRequest();
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveStreemSurveyStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {streem.api.SaveStreemSurveyStatusRequest} m SaveStreemSurveyStatusRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveStreemSurveyStatusRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userId = "";
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                return d;
            };

            /**
             * Converts this SaveStreemSurveyStatusRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveStreemSurveyStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveStreemSurveyStatusRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveStreemSurveyStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveStreemSurveyStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveStreemSurveyStatusRequest";
            };

            return SaveStreemSurveyStatusRequest;
        })();

        api.Point = (function() {

            /**
             * Properties of a Point.
             * @memberof streem.api
             * @interface IPoint
             * @property {number|null} [x] Point x
             * @property {number|null} [y] Point y
             * @property {number|null} [z] Point z
             */

            /**
             * Constructs a new Point.
             * @memberof streem.api
             * @classdesc Represents a Point.
             * @implements IPoint
             * @constructor
             * @param {streem.api.IPoint=} [p] Properties to set
             */
            function Point(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Point x.
             * @member {number} x
             * @memberof streem.api.Point
             * @instance
             */
            Point.prototype.x = 0;

            /**
             * Point y.
             * @member {number} y
             * @memberof streem.api.Point
             * @instance
             */
            Point.prototype.y = 0;

            /**
             * Point z.
             * @member {number} z
             * @memberof streem.api.Point
             * @instance
             */
            Point.prototype.z = 0;

            /**
             * Creates a new Point instance using the specified properties.
             * @function create
             * @memberof streem.api.Point
             * @static
             * @param {streem.api.IPoint=} [properties] Properties to set
             * @returns {streem.api.Point} Point instance
             */
            Point.create = function create(properties) {
                return new Point(properties);
            };

            /**
             * Encodes the specified Point message. Does not implicitly {@link streem.api.Point.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Point
             * @static
             * @param {streem.api.IPoint} m Point message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Point.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.x != null && Object.hasOwnProperty.call(m, "x"))
                    w.uint32(13).float(m.x);
                if (m.y != null && Object.hasOwnProperty.call(m, "y"))
                    w.uint32(21).float(m.y);
                if (m.z != null && Object.hasOwnProperty.call(m, "z"))
                    w.uint32(29).float(m.z);
                return w;
            };

            /**
             * Decodes a Point message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Point
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Point} Point
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Point.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Point();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.x = r.float();
                            break;
                        }
                    case 2: {
                            m.y = r.float();
                            break;
                        }
                    case 3: {
                            m.z = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Point message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Point
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Point} Point
             */
            Point.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Point)
                    return d;
                var m = new $root.streem.api.Point();
                if (d.x != null) {
                    m.x = Number(d.x);
                }
                if (d.y != null) {
                    m.y = Number(d.y);
                }
                if (d.z != null) {
                    m.z = Number(d.z);
                }
                return m;
            };

            /**
             * Creates a plain object from a Point message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Point
             * @static
             * @param {streem.api.Point} m Point
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Point.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.x = 0;
                    d.y = 0;
                    d.z = 0;
                }
                if (m.x != null && m.hasOwnProperty("x")) {
                    d.x = o.json && !isFinite(m.x) ? String(m.x) : m.x;
                }
                if (m.y != null && m.hasOwnProperty("y")) {
                    d.y = o.json && !isFinite(m.y) ? String(m.y) : m.y;
                }
                if (m.z != null && m.hasOwnProperty("z")) {
                    d.z = o.json && !isFinite(m.z) ? String(m.z) : m.z;
                }
                return d;
            };

            /**
             * Converts this Point to JSON.
             * @function toJSON
             * @memberof streem.api.Point
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Point.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Point
             * @function getTypeUrl
             * @memberof streem.api.Point
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Point.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Point";
            };

            return Point;
        })();

        api.Size = (function() {

            /**
             * Properties of a Size.
             * @memberof streem.api
             * @interface ISize
             * @property {number|null} [width] Size width
             * @property {number|null} [height] Size height
             */

            /**
             * Constructs a new Size.
             * @memberof streem.api
             * @classdesc Represents a Size.
             * @implements ISize
             * @constructor
             * @param {streem.api.ISize=} [p] Properties to set
             */
            function Size(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Size width.
             * @member {number} width
             * @memberof streem.api.Size
             * @instance
             */
            Size.prototype.width = 0;

            /**
             * Size height.
             * @member {number} height
             * @memberof streem.api.Size
             * @instance
             */
            Size.prototype.height = 0;

            /**
             * Creates a new Size instance using the specified properties.
             * @function create
             * @memberof streem.api.Size
             * @static
             * @param {streem.api.ISize=} [properties] Properties to set
             * @returns {streem.api.Size} Size instance
             */
            Size.create = function create(properties) {
                return new Size(properties);
            };

            /**
             * Encodes the specified Size message. Does not implicitly {@link streem.api.Size.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Size
             * @static
             * @param {streem.api.ISize} m Size message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Size.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                    w.uint32(13).float(m.width);
                if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                    w.uint32(21).float(m.height);
                return w;
            };

            /**
             * Decodes a Size message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Size
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Size} Size
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Size.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Size();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.width = r.float();
                            break;
                        }
                    case 2: {
                            m.height = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Size message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Size
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Size} Size
             */
            Size.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Size)
                    return d;
                var m = new $root.streem.api.Size();
                if (d.width != null) {
                    m.width = Number(d.width);
                }
                if (d.height != null) {
                    m.height = Number(d.height);
                }
                return m;
            };

            /**
             * Creates a plain object from a Size message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Size
             * @static
             * @param {streem.api.Size} m Size
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Size.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.width = 0;
                    d.height = 0;
                }
                if (m.width != null && m.hasOwnProperty("width")) {
                    d.width = o.json && !isFinite(m.width) ? String(m.width) : m.width;
                }
                if (m.height != null && m.hasOwnProperty("height")) {
                    d.height = o.json && !isFinite(m.height) ? String(m.height) : m.height;
                }
                return d;
            };

            /**
             * Converts this Size to JSON.
             * @function toJSON
             * @memberof streem.api.Size
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Size.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Size
             * @function getTypeUrl
             * @memberof streem.api.Size
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Size.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Size";
            };

            return Size;
        })();

        api.Pose = (function() {

            /**
             * Properties of a Pose.
             * @memberof streem.api
             * @interface IPose
             * @property {Array.<number>|null} [position] Pose position
             * @property {Array.<number>|null} [rotation] Pose rotation
             */

            /**
             * Constructs a new Pose.
             * @memberof streem.api
             * @classdesc Represents a Pose.
             * @implements IPose
             * @constructor
             * @param {streem.api.IPose=} [p] Properties to set
             */
            function Pose(p) {
                this.position = [];
                this.rotation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Pose position.
             * @member {Array.<number>} position
             * @memberof streem.api.Pose
             * @instance
             */
            Pose.prototype.position = $util.emptyArray;

            /**
             * Pose rotation.
             * @member {Array.<number>} rotation
             * @memberof streem.api.Pose
             * @instance
             */
            Pose.prototype.rotation = $util.emptyArray;

            /**
             * Creates a new Pose instance using the specified properties.
             * @function create
             * @memberof streem.api.Pose
             * @static
             * @param {streem.api.IPose=} [properties] Properties to set
             * @returns {streem.api.Pose} Pose instance
             */
            Pose.create = function create(properties) {
                return new Pose(properties);
            };

            /**
             * Encodes the specified Pose message. Does not implicitly {@link streem.api.Pose.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Pose
             * @static
             * @param {streem.api.IPose} m Pose message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pose.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.position != null && m.position.length) {
                    w.uint32(10).fork();
                    for (var i = 0; i < m.position.length; ++i)
                        w.float(m.position[i]);
                    w.ldelim();
                }
                if (m.rotation != null && m.rotation.length) {
                    w.uint32(18).fork();
                    for (var i = 0; i < m.rotation.length; ++i)
                        w.float(m.rotation[i]);
                    w.ldelim();
                }
                return w;
            };

            /**
             * Decodes a Pose message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Pose
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Pose} Pose
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pose.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Pose();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.position && m.position.length))
                                m.position = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.position.push(r.float());
                            } else
                                m.position.push(r.float());
                            break;
                        }
                    case 2: {
                            if (!(m.rotation && m.rotation.length))
                                m.rotation = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.rotation.push(r.float());
                            } else
                                m.rotation.push(r.float());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Pose message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Pose
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Pose} Pose
             */
            Pose.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Pose)
                    return d;
                var m = new $root.streem.api.Pose();
                if (d.position) {
                    if (!Array.isArray(d.position))
                        throw TypeError(".streem.api.Pose.position: array expected");
                    m.position = [];
                    for (var i = 0; i < d.position.length; ++i) {
                        m.position[i] = Number(d.position[i]);
                    }
                }
                if (d.rotation) {
                    if (!Array.isArray(d.rotation))
                        throw TypeError(".streem.api.Pose.rotation: array expected");
                    m.rotation = [];
                    for (var i = 0; i < d.rotation.length; ++i) {
                        m.rotation[i] = Number(d.rotation[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Pose message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Pose
             * @static
             * @param {streem.api.Pose} m Pose
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pose.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.position = [];
                    d.rotation = [];
                }
                if (m.position && m.position.length) {
                    d.position = [];
                    for (var j = 0; j < m.position.length; ++j) {
                        d.position[j] = o.json && !isFinite(m.position[j]) ? String(m.position[j]) : m.position[j];
                    }
                }
                if (m.rotation && m.rotation.length) {
                    d.rotation = [];
                    for (var j = 0; j < m.rotation.length; ++j) {
                        d.rotation[j] = o.json && !isFinite(m.rotation[j]) ? String(m.rotation[j]) : m.rotation[j];
                    }
                }
                return d;
            };

            /**
             * Converts this Pose to JSON.
             * @function toJSON
             * @memberof streem.api.Pose
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pose.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Pose
             * @function getTypeUrl
             * @memberof streem.api.Pose
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Pose.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Pose";
            };

            return Pose;
        })();

        api.Transform = (function() {

            /**
             * Properties of a Transform.
             * @memberof streem.api
             * @interface ITransform
             * @property {Array.<number>|null} [position] Transform position
             * @property {Array.<number>|null} [rotation] Transform rotation
             * @property {Array.<number>|null} [scale] Transform scale
             */

            /**
             * Constructs a new Transform.
             * @memberof streem.api
             * @classdesc Represents a Transform.
             * @implements ITransform
             * @constructor
             * @param {streem.api.ITransform=} [p] Properties to set
             */
            function Transform(p) {
                this.position = [];
                this.rotation = [];
                this.scale = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Transform position.
             * @member {Array.<number>} position
             * @memberof streem.api.Transform
             * @instance
             */
            Transform.prototype.position = $util.emptyArray;

            /**
             * Transform rotation.
             * @member {Array.<number>} rotation
             * @memberof streem.api.Transform
             * @instance
             */
            Transform.prototype.rotation = $util.emptyArray;

            /**
             * Transform scale.
             * @member {Array.<number>} scale
             * @memberof streem.api.Transform
             * @instance
             */
            Transform.prototype.scale = $util.emptyArray;

            /**
             * Creates a new Transform instance using the specified properties.
             * @function create
             * @memberof streem.api.Transform
             * @static
             * @param {streem.api.ITransform=} [properties] Properties to set
             * @returns {streem.api.Transform} Transform instance
             */
            Transform.create = function create(properties) {
                return new Transform(properties);
            };

            /**
             * Encodes the specified Transform message. Does not implicitly {@link streem.api.Transform.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Transform
             * @static
             * @param {streem.api.ITransform} m Transform message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Transform.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.position != null && m.position.length) {
                    w.uint32(10).fork();
                    for (var i = 0; i < m.position.length; ++i)
                        w.float(m.position[i]);
                    w.ldelim();
                }
                if (m.rotation != null && m.rotation.length) {
                    w.uint32(18).fork();
                    for (var i = 0; i < m.rotation.length; ++i)
                        w.float(m.rotation[i]);
                    w.ldelim();
                }
                if (m.scale != null && m.scale.length) {
                    w.uint32(26).fork();
                    for (var i = 0; i < m.scale.length; ++i)
                        w.float(m.scale[i]);
                    w.ldelim();
                }
                return w;
            };

            /**
             * Decodes a Transform message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Transform
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Transform} Transform
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Transform.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Transform();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.position && m.position.length))
                                m.position = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.position.push(r.float());
                            } else
                                m.position.push(r.float());
                            break;
                        }
                    case 2: {
                            if (!(m.rotation && m.rotation.length))
                                m.rotation = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.rotation.push(r.float());
                            } else
                                m.rotation.push(r.float());
                            break;
                        }
                    case 3: {
                            if (!(m.scale && m.scale.length))
                                m.scale = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.scale.push(r.float());
                            } else
                                m.scale.push(r.float());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Transform message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Transform
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Transform} Transform
             */
            Transform.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Transform)
                    return d;
                var m = new $root.streem.api.Transform();
                if (d.position) {
                    if (!Array.isArray(d.position))
                        throw TypeError(".streem.api.Transform.position: array expected");
                    m.position = [];
                    for (var i = 0; i < d.position.length; ++i) {
                        m.position[i] = Number(d.position[i]);
                    }
                }
                if (d.rotation) {
                    if (!Array.isArray(d.rotation))
                        throw TypeError(".streem.api.Transform.rotation: array expected");
                    m.rotation = [];
                    for (var i = 0; i < d.rotation.length; ++i) {
                        m.rotation[i] = Number(d.rotation[i]);
                    }
                }
                if (d.scale) {
                    if (!Array.isArray(d.scale))
                        throw TypeError(".streem.api.Transform.scale: array expected");
                    m.scale = [];
                    for (var i = 0; i < d.scale.length; ++i) {
                        m.scale[i] = Number(d.scale[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Transform message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Transform
             * @static
             * @param {streem.api.Transform} m Transform
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Transform.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.position = [];
                    d.rotation = [];
                    d.scale = [];
                }
                if (m.position && m.position.length) {
                    d.position = [];
                    for (var j = 0; j < m.position.length; ++j) {
                        d.position[j] = o.json && !isFinite(m.position[j]) ? String(m.position[j]) : m.position[j];
                    }
                }
                if (m.rotation && m.rotation.length) {
                    d.rotation = [];
                    for (var j = 0; j < m.rotation.length; ++j) {
                        d.rotation[j] = o.json && !isFinite(m.rotation[j]) ? String(m.rotation[j]) : m.rotation[j];
                    }
                }
                if (m.scale && m.scale.length) {
                    d.scale = [];
                    for (var j = 0; j < m.scale.length; ++j) {
                        d.scale[j] = o.json && !isFinite(m.scale[j]) ? String(m.scale[j]) : m.scale[j];
                    }
                }
                return d;
            };

            /**
             * Converts this Transform to JSON.
             * @function toJSON
             * @memberof streem.api.Transform
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Transform.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Transform
             * @function getTypeUrl
             * @memberof streem.api.Transform
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Transform.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Transform";
            };

            return Transform;
        })();

        /**
         * VideoCodec enum.
         * @name streem.api.VideoCodec
         * @enum {number}
         * @property {number} VIDEO_CODEC_INVALID=0 VIDEO_CODEC_INVALID value
         * @property {number} VIDEO_CODEC_H264=1 VIDEO_CODEC_H264 value
         * @property {number} VIDEO_CODEC_VP8=2 VIDEO_CODEC_VP8 value
         * @property {number} VIDEO_CODEC_VP9=3 VIDEO_CODEC_VP9 value
         */
        api.VideoCodec = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VIDEO_CODEC_INVALID"] = 0;
            values[valuesById[1] = "VIDEO_CODEC_H264"] = 1;
            values[valuesById[2] = "VIDEO_CODEC_VP8"] = 2;
            values[valuesById[3] = "VIDEO_CODEC_VP9"] = 3;
            return values;
        })();

        api.ErrorResponse = (function() {

            /**
             * Properties of an ErrorResponse.
             * @memberof streem.api
             * @interface IErrorResponse
             * @property {number|null} [httpCode] ErrorResponse httpCode
             * @property {string|null} [message] ErrorResponse message
             * @property {streem.api.ErrorResponse.IError|null} [error] ErrorResponse error
             */

            /**
             * Constructs a new ErrorResponse.
             * @memberof streem.api
             * @classdesc Represents an ErrorResponse.
             * @implements IErrorResponse
             * @constructor
             * @param {streem.api.IErrorResponse=} [p] Properties to set
             */
            function ErrorResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ErrorResponse httpCode.
             * @member {number} httpCode
             * @memberof streem.api.ErrorResponse
             * @instance
             */
            ErrorResponse.prototype.httpCode = 0;

            /**
             * ErrorResponse message.
             * @member {string} message
             * @memberof streem.api.ErrorResponse
             * @instance
             */
            ErrorResponse.prototype.message = "";

            /**
             * ErrorResponse error.
             * @member {streem.api.ErrorResponse.IError|null|undefined} error
             * @memberof streem.api.ErrorResponse
             * @instance
             */
            ErrorResponse.prototype.error = null;

            /**
             * Creates a new ErrorResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {streem.api.IErrorResponse=} [properties] Properties to set
             * @returns {streem.api.ErrorResponse} ErrorResponse instance
             */
            ErrorResponse.create = function create(properties) {
                return new ErrorResponse(properties);
            };

            /**
             * Encodes the specified ErrorResponse message. Does not implicitly {@link streem.api.ErrorResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {streem.api.IErrorResponse} m ErrorResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ErrorResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.httpCode != null && Object.hasOwnProperty.call(m, "httpCode"))
                    w.uint32(8).int32(m.httpCode);
                if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                    w.uint32(18).string(m.message);
                if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                    $root.streem.api.ErrorResponse.Error.encode(m.error, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an ErrorResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ErrorResponse} ErrorResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ErrorResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ErrorResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.httpCode = r.int32();
                            break;
                        }
                    case 2: {
                            m.message = r.string();
                            break;
                        }
                    case 3: {
                            m.error = $root.streem.api.ErrorResponse.Error.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an ErrorResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ErrorResponse} ErrorResponse
             */
            ErrorResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ErrorResponse)
                    return d;
                var m = new $root.streem.api.ErrorResponse();
                if (d.httpCode != null) {
                    m.httpCode = d.httpCode | 0;
                }
                if (d.message != null) {
                    m.message = String(d.message);
                }
                if (d.error != null) {
                    if (typeof d.error !== "object")
                        throw TypeError(".streem.api.ErrorResponse.error: object expected");
                    m.error = $root.streem.api.ErrorResponse.Error.fromObject(d.error);
                }
                return m;
            };

            /**
             * Creates a plain object from an ErrorResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {streem.api.ErrorResponse} m ErrorResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ErrorResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.httpCode = 0;
                    d.message = "";
                    d.error = null;
                }
                if (m.httpCode != null && m.hasOwnProperty("httpCode")) {
                    d.httpCode = m.httpCode;
                }
                if (m.message != null && m.hasOwnProperty("message")) {
                    d.message = m.message;
                }
                if (m.error != null && m.hasOwnProperty("error")) {
                    d.error = $root.streem.api.ErrorResponse.Error.toObject(m.error, o);
                }
                return d;
            };

            /**
             * Converts this ErrorResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ErrorResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ErrorResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ErrorResponse
             * @function getTypeUrl
             * @memberof streem.api.ErrorResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ErrorResponse";
            };

            ErrorResponse.Error = (function() {

                /**
                 * Properties of an Error.
                 * @memberof streem.api.ErrorResponse
                 * @interface IError
                 * @property {string|null} [code] Error code
                 * @property {string|null} [message] Error message
                 * @property {Array.<google.protobuf.IAny>|null} [meta] Error meta
                 */

                /**
                 * Constructs a new Error.
                 * @memberof streem.api.ErrorResponse
                 * @classdesc Represents an Error.
                 * @implements IError
                 * @constructor
                 * @param {streem.api.ErrorResponse.IError=} [p] Properties to set
                 */
                function Error(p) {
                    this.meta = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Error code.
                 * @member {string} code
                 * @memberof streem.api.ErrorResponse.Error
                 * @instance
                 */
                Error.prototype.code = "";

                /**
                 * Error message.
                 * @member {string} message
                 * @memberof streem.api.ErrorResponse.Error
                 * @instance
                 */
                Error.prototype.message = "";

                /**
                 * Error meta.
                 * @member {Array.<google.protobuf.IAny>} meta
                 * @memberof streem.api.ErrorResponse.Error
                 * @instance
                 */
                Error.prototype.meta = $util.emptyArray;

                /**
                 * Creates a new Error instance using the specified properties.
                 * @function create
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {streem.api.ErrorResponse.IError=} [properties] Properties to set
                 * @returns {streem.api.ErrorResponse.Error} Error instance
                 */
                Error.create = function create(properties) {
                    return new Error(properties);
                };

                /**
                 * Encodes the specified Error message. Does not implicitly {@link streem.api.ErrorResponse.Error.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {streem.api.ErrorResponse.IError} m Error message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Error.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                        w.uint32(10).string(m.code);
                    if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                        w.uint32(18).string(m.message);
                    if (m.meta != null && m.meta.length) {
                        for (var i = 0; i < m.meta.length; ++i)
                            $root.google.protobuf.Any.encode(m.meta[i], w.uint32(26).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes an Error message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.ErrorResponse.Error} Error
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Error.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ErrorResponse.Error();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.code = r.string();
                                break;
                            }
                        case 2: {
                                m.message = r.string();
                                break;
                            }
                        case 3: {
                                if (!(m.meta && m.meta.length))
                                    m.meta = [];
                                m.meta.push($root.google.protobuf.Any.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Error message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.ErrorResponse.Error} Error
                 */
                Error.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.ErrorResponse.Error)
                        return d;
                    var m = new $root.streem.api.ErrorResponse.Error();
                    if (d.code != null) {
                        m.code = String(d.code);
                    }
                    if (d.message != null) {
                        m.message = String(d.message);
                    }
                    if (d.meta) {
                        if (!Array.isArray(d.meta))
                            throw TypeError(".streem.api.ErrorResponse.Error.meta: array expected");
                        m.meta = [];
                        for (var i = 0; i < d.meta.length; ++i) {
                            if (typeof d.meta[i] !== "object")
                                throw TypeError(".streem.api.ErrorResponse.Error.meta: object expected");
                            m.meta[i] = $root.google.protobuf.Any.fromObject(d.meta[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Error message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {streem.api.ErrorResponse.Error} m Error
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Error.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.meta = [];
                    }
                    if (o.defaults) {
                        d.code = "";
                        d.message = "";
                    }
                    if (m.code != null && m.hasOwnProperty("code")) {
                        d.code = m.code;
                    }
                    if (m.message != null && m.hasOwnProperty("message")) {
                        d.message = m.message;
                    }
                    if (m.meta && m.meta.length) {
                        d.meta = [];
                        for (var j = 0; j < m.meta.length; ++j) {
                            d.meta[j] = $root.google.protobuf.Any.toObject(m.meta[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Error to JSON.
                 * @function toJSON
                 * @memberof streem.api.ErrorResponse.Error
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Error.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Error
                 * @function getTypeUrl
                 * @memberof streem.api.ErrorResponse.Error
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Error.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.ErrorResponse.Error";
                };

                return Error;
            })();

            return ErrorResponse;
        })();

        /**
         * VideoPlatform enum.
         * @name streem.api.VideoPlatform
         * @enum {number}
         * @property {number} VIDEO_PLATFORM_INVALID=0 VIDEO_PLATFORM_INVALID value
         * @property {number} VIDEO_PLATFORM_TWILIO=1 VIDEO_PLATFORM_TWILIO value
         * @property {number} VIDEO_PLATFORM_ARAAS=2 VIDEO_PLATFORM_ARAAS value
         * @property {number} VIDEO_PLATFORM_CHIME=3 VIDEO_PLATFORM_CHIME value
         */
        api.VideoPlatform = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VIDEO_PLATFORM_INVALID"] = 0;
            values[valuesById[1] = "VIDEO_PLATFORM_TWILIO"] = 1;
            values[valuesById[2] = "VIDEO_PLATFORM_ARAAS"] = 2;
            values[valuesById[3] = "VIDEO_PLATFORM_CHIME"] = 3;
            return values;
        })();

        /**
         * WallPlatform enum.
         * @name streem.api.WallPlatform
         * @enum {number}
         * @property {number} WALL_PLATFORM_INVALID=0 WALL_PLATFORM_INVALID value
         * @property {number} WALL_PLATFORM_TWILIO=1 WALL_PLATFORM_TWILIO value
         * @property {number} WALL_PLATFORM_STREEM=2 WALL_PLATFORM_STREEM value
         */
        api.WallPlatform = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WALL_PLATFORM_INVALID"] = 0;
            values[valuesById[1] = "WALL_PLATFORM_TWILIO"] = 1;
            values[valuesById[2] = "WALL_PLATFORM_STREEM"] = 2;
            return values;
        })();

        /**
         * SmsPlatform enum.
         * @name streem.api.SmsPlatform
         * @enum {number}
         * @property {number} SMS_PLATFORM_INVALID=0 SMS_PLATFORM_INVALID value
         * @property {number} SMS_PLATFORM_TWILIO=1 SMS_PLATFORM_TWILIO value
         * @property {number} SMS_PLATFORM_TELNYX=2 SMS_PLATFORM_TELNYX value
         */
        api.SmsPlatform = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SMS_PLATFORM_INVALID"] = 0;
            values[valuesById[1] = "SMS_PLATFORM_TWILIO"] = 1;
            values[valuesById[2] = "SMS_PLATFORM_TELNYX"] = 2;
            return values;
        })();

        /**
         * ConnectionMethod enum.
         * @name streem.api.ConnectionMethod
         * @enum {number}
         * @property {number} CONNECTION_METHOD_INVALID=0 CONNECTION_METHOD_INVALID value
         * @property {number} CONNECTION_METHOD_WIFI=1 CONNECTION_METHOD_WIFI value
         * @property {number} CONNECTION_METHOD_CELLULAR=2 CONNECTION_METHOD_CELLULAR value
         * @property {number} CONNECTION_METHOD_UNKNOWN=3 CONNECTION_METHOD_UNKNOWN value
         */
        api.ConnectionMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CONNECTION_METHOD_INVALID"] = 0;
            values[valuesById[1] = "CONNECTION_METHOD_WIFI"] = 1;
            values[valuesById[2] = "CONNECTION_METHOD_CELLULAR"] = 2;
            values[valuesById[3] = "CONNECTION_METHOD_UNKNOWN"] = 3;
            return values;
        })();

        api.SsoProviderData = (function() {

            /**
             * Properties of a SsoProviderData.
             * @memberof streem.api
             * @interface ISsoProviderData
             * @property {string|null} [ssoProviderCode] SsoProviderData ssoProviderCode
             * @property {string|null} [name] SsoProviderData name
             * @property {streem.api.SsoProviderData.Type|null} [type] SsoProviderData type
             * @property {Array.<streem.api.ISsoProviderCompanyMapping>|null} [companyMappings] SsoProviderData companyMappings
             * @property {string|null} [fusionAuthIdentityProviderId] SsoProviderData fusionAuthIdentityProviderId
             * @property {string|null} [fusionAuthLambdaId] SsoProviderData fusionAuthLambdaId
             * @property {string|null} [fusionAuthSamlKeyId] SsoProviderData fusionAuthSamlKeyId
             */

            /**
             * Constructs a new SsoProviderData.
             * @memberof streem.api
             * @classdesc Represents a SsoProviderData.
             * @implements ISsoProviderData
             * @constructor
             * @param {streem.api.ISsoProviderData=} [p] Properties to set
             */
            function SsoProviderData(p) {
                this.companyMappings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SsoProviderData ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.ssoProviderCode = "";

            /**
             * SsoProviderData name.
             * @member {string} name
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.name = "";

            /**
             * SsoProviderData type.
             * @member {streem.api.SsoProviderData.Type} type
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.type = 0;

            /**
             * SsoProviderData companyMappings.
             * @member {Array.<streem.api.ISsoProviderCompanyMapping>} companyMappings
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.companyMappings = $util.emptyArray;

            /**
             * SsoProviderData fusionAuthIdentityProviderId.
             * @member {string} fusionAuthIdentityProviderId
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.fusionAuthIdentityProviderId = "";

            /**
             * SsoProviderData fusionAuthLambdaId.
             * @member {string} fusionAuthLambdaId
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.fusionAuthLambdaId = "";

            /**
             * SsoProviderData fusionAuthSamlKeyId.
             * @member {string} fusionAuthSamlKeyId
             * @memberof streem.api.SsoProviderData
             * @instance
             */
            SsoProviderData.prototype.fusionAuthSamlKeyId = "";

            /**
             * Creates a new SsoProviderData instance using the specified properties.
             * @function create
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {streem.api.ISsoProviderData=} [properties] Properties to set
             * @returns {streem.api.SsoProviderData} SsoProviderData instance
             */
            SsoProviderData.create = function create(properties) {
                return new SsoProviderData(properties);
            };

            /**
             * Encodes the specified SsoProviderData message. Does not implicitly {@link streem.api.SsoProviderData.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {streem.api.ISsoProviderData} m SsoProviderData message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SsoProviderData.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(24).int32(m.type);
                if (m.companyMappings != null && m.companyMappings.length) {
                    for (var i = 0; i < m.companyMappings.length; ++i)
                        $root.streem.api.SsoProviderCompanyMapping.encode(m.companyMappings[i], w.uint32(34).fork()).ldelim();
                }
                if (m.fusionAuthIdentityProviderId != null && Object.hasOwnProperty.call(m, "fusionAuthIdentityProviderId"))
                    w.uint32(42).string(m.fusionAuthIdentityProviderId);
                if (m.fusionAuthLambdaId != null && Object.hasOwnProperty.call(m, "fusionAuthLambdaId"))
                    w.uint32(50).string(m.fusionAuthLambdaId);
                if (m.fusionAuthSamlKeyId != null && Object.hasOwnProperty.call(m, "fusionAuthSamlKeyId"))
                    w.uint32(58).string(m.fusionAuthSamlKeyId);
                return w;
            };

            /**
             * Decodes a SsoProviderData message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SsoProviderData} SsoProviderData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SsoProviderData.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProviderData();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.type = r.int32();
                            break;
                        }
                    case 4: {
                            if (!(m.companyMappings && m.companyMappings.length))
                                m.companyMappings = [];
                            m.companyMappings.push($root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            m.fusionAuthIdentityProviderId = r.string();
                            break;
                        }
                    case 6: {
                            m.fusionAuthLambdaId = r.string();
                            break;
                        }
                    case 7: {
                            m.fusionAuthSamlKeyId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SsoProviderData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SsoProviderData} SsoProviderData
             */
            SsoProviderData.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SsoProviderData)
                    return d;
                var m = new $root.streem.api.SsoProviderData();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "TYPE_CUSTOM":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_OPENID_CONNECT":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_SAML_V2":
                case 3:
                    m.type = 3;
                    break;
                }
                if (d.companyMappings) {
                    if (!Array.isArray(d.companyMappings))
                        throw TypeError(".streem.api.SsoProviderData.companyMappings: array expected");
                    m.companyMappings = [];
                    for (var i = 0; i < d.companyMappings.length; ++i) {
                        if (typeof d.companyMappings[i] !== "object")
                            throw TypeError(".streem.api.SsoProviderData.companyMappings: object expected");
                        m.companyMappings[i] = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.companyMappings[i]);
                    }
                }
                if (d.fusionAuthIdentityProviderId != null) {
                    m.fusionAuthIdentityProviderId = String(d.fusionAuthIdentityProviderId);
                }
                if (d.fusionAuthLambdaId != null) {
                    m.fusionAuthLambdaId = String(d.fusionAuthLambdaId);
                }
                if (d.fusionAuthSamlKeyId != null) {
                    m.fusionAuthSamlKeyId = String(d.fusionAuthSamlKeyId);
                }
                return m;
            };

            /**
             * Creates a plain object from a SsoProviderData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {streem.api.SsoProviderData} m SsoProviderData
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SsoProviderData.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.companyMappings = [];
                }
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.name = "";
                    d.type = o.enums === String ? "TYPE_INVALID" : 0;
                    d.fusionAuthIdentityProviderId = "";
                    d.fusionAuthLambdaId = "";
                    d.fusionAuthSamlKeyId = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.SsoProviderData.Type[m.type] === undefined ? m.type : $root.streem.api.SsoProviderData.Type[m.type] : m.type;
                }
                if (m.companyMappings && m.companyMappings.length) {
                    d.companyMappings = [];
                    for (var j = 0; j < m.companyMappings.length; ++j) {
                        d.companyMappings[j] = $root.streem.api.SsoProviderCompanyMapping.toObject(m.companyMappings[j], o);
                    }
                }
                if (m.fusionAuthIdentityProviderId != null && m.hasOwnProperty("fusionAuthIdentityProviderId")) {
                    d.fusionAuthIdentityProviderId = m.fusionAuthIdentityProviderId;
                }
                if (m.fusionAuthLambdaId != null && m.hasOwnProperty("fusionAuthLambdaId")) {
                    d.fusionAuthLambdaId = m.fusionAuthLambdaId;
                }
                if (m.fusionAuthSamlKeyId != null && m.hasOwnProperty("fusionAuthSamlKeyId")) {
                    d.fusionAuthSamlKeyId = m.fusionAuthSamlKeyId;
                }
                return d;
            };

            /**
             * Converts this SsoProviderData to JSON.
             * @function toJSON
             * @memberof streem.api.SsoProviderData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SsoProviderData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SsoProviderData
             * @function getTypeUrl
             * @memberof streem.api.SsoProviderData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SsoProviderData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SsoProviderData";
            };

            /**
             * Type enum.
             * @name streem.api.SsoProviderData.Type
             * @enum {number}
             * @property {number} TYPE_INVALID=0 TYPE_INVALID value
             * @property {number} TYPE_CUSTOM=1 TYPE_CUSTOM value
             * @property {number} TYPE_OPENID_CONNECT=2 TYPE_OPENID_CONNECT value
             * @property {number} TYPE_SAML_V2=3 TYPE_SAML_V2 value
             */
            SsoProviderData.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_INVALID"] = 0;
                values[valuesById[1] = "TYPE_CUSTOM"] = 1;
                values[valuesById[2] = "TYPE_OPENID_CONNECT"] = 2;
                values[valuesById[3] = "TYPE_SAML_V2"] = 3;
                return values;
            })();

            return SsoProviderData;
        })();

        api.SsoProvider = (function() {

            /**
             * Properties of a SsoProvider.
             * @memberof streem.api
             * @interface ISsoProvider
             * @property {string|null} [ssoProviderCode] SsoProvider ssoProviderCode
             * @property {string|null} [name] SsoProvider name
             * @property {string|null} [fusionAuthIdentityProviderId] SsoProvider fusionAuthIdentityProviderId
             * @property {streem.api.SsoProvider.IOidc|null} [oidc] SsoProvider oidc
             * @property {streem.api.SsoProvider.ISamlV2|null} [samlV2] SsoProvider samlV2
             */

            /**
             * Constructs a new SsoProvider.
             * @memberof streem.api
             * @classdesc Represents a SsoProvider.
             * @implements ISsoProvider
             * @constructor
             * @param {streem.api.ISsoProvider=} [p] Properties to set
             */
            function SsoProvider(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SsoProvider ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.SsoProvider
             * @instance
             */
            SsoProvider.prototype.ssoProviderCode = "";

            /**
             * SsoProvider name.
             * @member {string} name
             * @memberof streem.api.SsoProvider
             * @instance
             */
            SsoProvider.prototype.name = "";

            /**
             * SsoProvider fusionAuthIdentityProviderId.
             * @member {string|null|undefined} fusionAuthIdentityProviderId
             * @memberof streem.api.SsoProvider
             * @instance
             */
            SsoProvider.prototype.fusionAuthIdentityProviderId = null;

            /**
             * SsoProvider oidc.
             * @member {streem.api.SsoProvider.IOidc|null|undefined} oidc
             * @memberof streem.api.SsoProvider
             * @instance
             */
            SsoProvider.prototype.oidc = null;

            /**
             * SsoProvider samlV2.
             * @member {streem.api.SsoProvider.ISamlV2|null|undefined} samlV2
             * @memberof streem.api.SsoProvider
             * @instance
             */
            SsoProvider.prototype.samlV2 = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * SsoProvider provider.
             * @member {"fusionAuthIdentityProviderId"|"oidc"|"samlV2"|undefined} provider
             * @memberof streem.api.SsoProvider
             * @instance
             */
            Object.defineProperty(SsoProvider.prototype, "provider", {
                get: $util.oneOfGetter($oneOfFields = ["fusionAuthIdentityProviderId", "oidc", "samlV2"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SsoProvider instance using the specified properties.
             * @function create
             * @memberof streem.api.SsoProvider
             * @static
             * @param {streem.api.ISsoProvider=} [properties] Properties to set
             * @returns {streem.api.SsoProvider} SsoProvider instance
             */
            SsoProvider.create = function create(properties) {
                return new SsoProvider(properties);
            };

            /**
             * Encodes the specified SsoProvider message. Does not implicitly {@link streem.api.SsoProvider.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SsoProvider
             * @static
             * @param {streem.api.ISsoProvider} m SsoProvider message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SsoProvider.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.fusionAuthIdentityProviderId != null && Object.hasOwnProperty.call(m, "fusionAuthIdentityProviderId"))
                    w.uint32(82).string(m.fusionAuthIdentityProviderId);
                if (m.oidc != null && Object.hasOwnProperty.call(m, "oidc"))
                    $root.streem.api.SsoProvider.Oidc.encode(m.oidc, w.uint32(90).fork()).ldelim();
                if (m.samlV2 != null && Object.hasOwnProperty.call(m, "samlV2"))
                    $root.streem.api.SsoProvider.SamlV2.encode(m.samlV2, w.uint32(98).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SsoProvider message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SsoProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SsoProvider} SsoProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SsoProvider.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProvider();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 10: {
                            m.fusionAuthIdentityProviderId = r.string();
                            break;
                        }
                    case 11: {
                            m.oidc = $root.streem.api.SsoProvider.Oidc.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.samlV2 = $root.streem.api.SsoProvider.SamlV2.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SsoProvider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SsoProvider
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SsoProvider} SsoProvider
             */
            SsoProvider.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SsoProvider)
                    return d;
                var m = new $root.streem.api.SsoProvider();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.fusionAuthIdentityProviderId != null) {
                    m.fusionAuthIdentityProviderId = String(d.fusionAuthIdentityProviderId);
                }
                if (d.oidc != null) {
                    if (typeof d.oidc !== "object")
                        throw TypeError(".streem.api.SsoProvider.oidc: object expected");
                    m.oidc = $root.streem.api.SsoProvider.Oidc.fromObject(d.oidc);
                }
                if (d.samlV2 != null) {
                    if (typeof d.samlV2 !== "object")
                        throw TypeError(".streem.api.SsoProvider.samlV2: object expected");
                    m.samlV2 = $root.streem.api.SsoProvider.SamlV2.fromObject(d.samlV2);
                }
                return m;
            };

            /**
             * Creates a plain object from a SsoProvider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SsoProvider
             * @static
             * @param {streem.api.SsoProvider} m SsoProvider
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SsoProvider.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.name = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.fusionAuthIdentityProviderId != null && m.hasOwnProperty("fusionAuthIdentityProviderId")) {
                    d.fusionAuthIdentityProviderId = m.fusionAuthIdentityProviderId;
                    if (o.oneofs)
                        d.provider = "fusionAuthIdentityProviderId";
                }
                if (m.oidc != null && m.hasOwnProperty("oidc")) {
                    d.oidc = $root.streem.api.SsoProvider.Oidc.toObject(m.oidc, o);
                    if (o.oneofs)
                        d.provider = "oidc";
                }
                if (m.samlV2 != null && m.hasOwnProperty("samlV2")) {
                    d.samlV2 = $root.streem.api.SsoProvider.SamlV2.toObject(m.samlV2, o);
                    if (o.oneofs)
                        d.provider = "samlV2";
                }
                return d;
            };

            /**
             * Converts this SsoProvider to JSON.
             * @function toJSON
             * @memberof streem.api.SsoProvider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SsoProvider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SsoProvider
             * @function getTypeUrl
             * @memberof streem.api.SsoProvider
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SsoProvider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SsoProvider";
            };

            SsoProvider.Oidc = (function() {

                /**
                 * Properties of an Oidc.
                 * @memberof streem.api.SsoProvider
                 * @interface IOidc
                 * @property {string|null} [clientId] Oidc clientId
                 * @property {string|null} [clientSecret] Oidc clientSecret
                 * @property {string|null} [scope] Oidc scope
                 * @property {string|null} [emailClaim] Oidc emailClaim
                 * @property {Array.<string>|null} [managedDomains] Oidc managedDomains
                 * @property {streem.api.SsoProvider.Oidc.IDiscoverEndpoints|null} [discoverEndpoints] Oidc discoverEndpoints
                 * @property {streem.api.SsoProvider.Oidc.IExplicitEndpoints|null} [explicitEndpoints] Oidc explicitEndpoints
                 * @property {string|null} [fusionAuthLambdaId] Oidc fusionAuthLambdaId
                 * @property {string|null} [usernameClaim] Oidc usernameClaim
                 */

                /**
                 * Constructs a new Oidc.
                 * @memberof streem.api.SsoProvider
                 * @classdesc Represents an Oidc.
                 * @implements IOidc
                 * @constructor
                 * @param {streem.api.SsoProvider.IOidc=} [p] Properties to set
                 */
                function Oidc(p) {
                    this.managedDomains = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Oidc clientId.
                 * @member {string} clientId
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.clientId = "";

                /**
                 * Oidc clientSecret.
                 * @member {string} clientSecret
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.clientSecret = "";

                /**
                 * Oidc scope.
                 * @member {string} scope
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.scope = "";

                /**
                 * Oidc emailClaim.
                 * @member {string} emailClaim
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.emailClaim = "";

                /**
                 * Oidc managedDomains.
                 * @member {Array.<string>} managedDomains
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.managedDomains = $util.emptyArray;

                /**
                 * Oidc discoverEndpoints.
                 * @member {streem.api.SsoProvider.Oidc.IDiscoverEndpoints|null|undefined} discoverEndpoints
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.discoverEndpoints = null;

                /**
                 * Oidc explicitEndpoints.
                 * @member {streem.api.SsoProvider.Oidc.IExplicitEndpoints|null|undefined} explicitEndpoints
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.explicitEndpoints = null;

                /**
                 * Oidc fusionAuthLambdaId.
                 * @member {string|null|undefined} fusionAuthLambdaId
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.fusionAuthLambdaId = null;

                /**
                 * Oidc usernameClaim.
                 * @member {string|null|undefined} usernameClaim
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Oidc.prototype.usernameClaim = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Oidc endpoints.
                 * @member {"discoverEndpoints"|"explicitEndpoints"|undefined} endpoints
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Object.defineProperty(Oidc.prototype, "endpoints", {
                    get: $util.oneOfGetter($oneOfFields = ["discoverEndpoints", "explicitEndpoints"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Oidc reconcile.
                 * @member {"fusionAuthLambdaId"|"usernameClaim"|undefined} reconcile
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 */
                Object.defineProperty(Oidc.prototype, "reconcile", {
                    get: $util.oneOfGetter($oneOfFields = ["fusionAuthLambdaId", "usernameClaim"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Oidc instance using the specified properties.
                 * @function create
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {streem.api.SsoProvider.IOidc=} [properties] Properties to set
                 * @returns {streem.api.SsoProvider.Oidc} Oidc instance
                 */
                Oidc.create = function create(properties) {
                    return new Oidc(properties);
                };

                /**
                 * Encodes the specified Oidc message. Does not implicitly {@link streem.api.SsoProvider.Oidc.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {streem.api.SsoProvider.IOidc} m Oidc message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Oidc.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.clientId != null && Object.hasOwnProperty.call(m, "clientId"))
                        w.uint32(10).string(m.clientId);
                    if (m.clientSecret != null && Object.hasOwnProperty.call(m, "clientSecret"))
                        w.uint32(18).string(m.clientSecret);
                    if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                        w.uint32(26).string(m.scope);
                    if (m.emailClaim != null && Object.hasOwnProperty.call(m, "emailClaim"))
                        w.uint32(34).string(m.emailClaim);
                    if (m.managedDomains != null && m.managedDomains.length) {
                        for (var i = 0; i < m.managedDomains.length; ++i)
                            w.uint32(50).string(m.managedDomains[i]);
                    }
                    if (m.discoverEndpoints != null && Object.hasOwnProperty.call(m, "discoverEndpoints"))
                        $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints.encode(m.discoverEndpoints, w.uint32(82).fork()).ldelim();
                    if (m.explicitEndpoints != null && Object.hasOwnProperty.call(m, "explicitEndpoints"))
                        $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints.encode(m.explicitEndpoints, w.uint32(90).fork()).ldelim();
                    if (m.fusionAuthLambdaId != null && Object.hasOwnProperty.call(m, "fusionAuthLambdaId"))
                        w.uint32(162).string(m.fusionAuthLambdaId);
                    if (m.usernameClaim != null && Object.hasOwnProperty.call(m, "usernameClaim"))
                        w.uint32(170).string(m.usernameClaim);
                    return w;
                };

                /**
                 * Decodes an Oidc message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.SsoProvider.Oidc} Oidc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Oidc.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProvider.Oidc();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.clientId = r.string();
                                break;
                            }
                        case 2: {
                                m.clientSecret = r.string();
                                break;
                            }
                        case 3: {
                                m.scope = r.string();
                                break;
                            }
                        case 4: {
                                m.emailClaim = r.string();
                                break;
                            }
                        case 6: {
                                if (!(m.managedDomains && m.managedDomains.length))
                                    m.managedDomains = [];
                                m.managedDomains.push(r.string());
                                break;
                            }
                        case 10: {
                                m.discoverEndpoints = $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints.decode(r, r.uint32());
                                break;
                            }
                        case 11: {
                                m.explicitEndpoints = $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints.decode(r, r.uint32());
                                break;
                            }
                        case 20: {
                                m.fusionAuthLambdaId = r.string();
                                break;
                            }
                        case 21: {
                                m.usernameClaim = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Oidc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.SsoProvider.Oidc} Oidc
                 */
                Oidc.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.SsoProvider.Oidc)
                        return d;
                    var m = new $root.streem.api.SsoProvider.Oidc();
                    if (d.clientId != null) {
                        m.clientId = String(d.clientId);
                    }
                    if (d.clientSecret != null) {
                        m.clientSecret = String(d.clientSecret);
                    }
                    if (d.scope != null) {
                        m.scope = String(d.scope);
                    }
                    if (d.emailClaim != null) {
                        m.emailClaim = String(d.emailClaim);
                    }
                    if (d.managedDomains) {
                        if (!Array.isArray(d.managedDomains))
                            throw TypeError(".streem.api.SsoProvider.Oidc.managedDomains: array expected");
                        m.managedDomains = [];
                        for (var i = 0; i < d.managedDomains.length; ++i) {
                            m.managedDomains[i] = String(d.managedDomains[i]);
                        }
                    }
                    if (d.discoverEndpoints != null) {
                        if (typeof d.discoverEndpoints !== "object")
                            throw TypeError(".streem.api.SsoProvider.Oidc.discoverEndpoints: object expected");
                        m.discoverEndpoints = $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints.fromObject(d.discoverEndpoints);
                    }
                    if (d.explicitEndpoints != null) {
                        if (typeof d.explicitEndpoints !== "object")
                            throw TypeError(".streem.api.SsoProvider.Oidc.explicitEndpoints: object expected");
                        m.explicitEndpoints = $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints.fromObject(d.explicitEndpoints);
                    }
                    if (d.fusionAuthLambdaId != null) {
                        m.fusionAuthLambdaId = String(d.fusionAuthLambdaId);
                    }
                    if (d.usernameClaim != null) {
                        m.usernameClaim = String(d.usernameClaim);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Oidc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {streem.api.SsoProvider.Oidc} m Oidc
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Oidc.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.managedDomains = [];
                    }
                    if (o.defaults) {
                        d.clientId = "";
                        d.clientSecret = "";
                        d.scope = "";
                        d.emailClaim = "";
                    }
                    if (m.clientId != null && m.hasOwnProperty("clientId")) {
                        d.clientId = m.clientId;
                    }
                    if (m.clientSecret != null && m.hasOwnProperty("clientSecret")) {
                        d.clientSecret = m.clientSecret;
                    }
                    if (m.scope != null && m.hasOwnProperty("scope")) {
                        d.scope = m.scope;
                    }
                    if (m.emailClaim != null && m.hasOwnProperty("emailClaim")) {
                        d.emailClaim = m.emailClaim;
                    }
                    if (m.managedDomains && m.managedDomains.length) {
                        d.managedDomains = [];
                        for (var j = 0; j < m.managedDomains.length; ++j) {
                            d.managedDomains[j] = m.managedDomains[j];
                        }
                    }
                    if (m.discoverEndpoints != null && m.hasOwnProperty("discoverEndpoints")) {
                        d.discoverEndpoints = $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints.toObject(m.discoverEndpoints, o);
                        if (o.oneofs)
                            d.endpoints = "discoverEndpoints";
                    }
                    if (m.explicitEndpoints != null && m.hasOwnProperty("explicitEndpoints")) {
                        d.explicitEndpoints = $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints.toObject(m.explicitEndpoints, o);
                        if (o.oneofs)
                            d.endpoints = "explicitEndpoints";
                    }
                    if (m.fusionAuthLambdaId != null && m.hasOwnProperty("fusionAuthLambdaId")) {
                        d.fusionAuthLambdaId = m.fusionAuthLambdaId;
                        if (o.oneofs)
                            d.reconcile = "fusionAuthLambdaId";
                    }
                    if (m.usernameClaim != null && m.hasOwnProperty("usernameClaim")) {
                        d.usernameClaim = m.usernameClaim;
                        if (o.oneofs)
                            d.reconcile = "usernameClaim";
                    }
                    return d;
                };

                /**
                 * Converts this Oidc to JSON.
                 * @function toJSON
                 * @memberof streem.api.SsoProvider.Oidc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Oidc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Oidc
                 * @function getTypeUrl
                 * @memberof streem.api.SsoProvider.Oidc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Oidc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.SsoProvider.Oidc";
                };

                Oidc.DiscoverEndpoints = (function() {

                    /**
                     * Properties of a DiscoverEndpoints.
                     * @memberof streem.api.SsoProvider.Oidc
                     * @interface IDiscoverEndpoints
                     * @property {string|null} [issuer] DiscoverEndpoints issuer
                     */

                    /**
                     * Constructs a new DiscoverEndpoints.
                     * @memberof streem.api.SsoProvider.Oidc
                     * @classdesc Represents a DiscoverEndpoints.
                     * @implements IDiscoverEndpoints
                     * @constructor
                     * @param {streem.api.SsoProvider.Oidc.IDiscoverEndpoints=} [p] Properties to set
                     */
                    function DiscoverEndpoints(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * DiscoverEndpoints issuer.
                     * @member {string} issuer
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @instance
                     */
                    DiscoverEndpoints.prototype.issuer = "";

                    /**
                     * Creates a new DiscoverEndpoints instance using the specified properties.
                     * @function create
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.IDiscoverEndpoints=} [properties] Properties to set
                     * @returns {streem.api.SsoProvider.Oidc.DiscoverEndpoints} DiscoverEndpoints instance
                     */
                    DiscoverEndpoints.create = function create(properties) {
                        return new DiscoverEndpoints(properties);
                    };

                    /**
                     * Encodes the specified DiscoverEndpoints message. Does not implicitly {@link streem.api.SsoProvider.Oidc.DiscoverEndpoints.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.IDiscoverEndpoints} m DiscoverEndpoints message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiscoverEndpoints.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.issuer != null && Object.hasOwnProperty.call(m, "issuer"))
                            w.uint32(10).string(m.issuer);
                        return w;
                    };

                    /**
                     * Decodes a DiscoverEndpoints message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.SsoProvider.Oidc.DiscoverEndpoints} DiscoverEndpoints
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiscoverEndpoints.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.issuer = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a DiscoverEndpoints message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.SsoProvider.Oidc.DiscoverEndpoints} DiscoverEndpoints
                     */
                    DiscoverEndpoints.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints)
                            return d;
                        var m = new $root.streem.api.SsoProvider.Oidc.DiscoverEndpoints();
                        if (d.issuer != null) {
                            m.issuer = String(d.issuer);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a DiscoverEndpoints message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.DiscoverEndpoints} m DiscoverEndpoints
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DiscoverEndpoints.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.issuer = "";
                        }
                        if (m.issuer != null && m.hasOwnProperty("issuer")) {
                            d.issuer = m.issuer;
                        }
                        return d;
                    };

                    /**
                     * Converts this DiscoverEndpoints to JSON.
                     * @function toJSON
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DiscoverEndpoints.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for DiscoverEndpoints
                     * @function getTypeUrl
                     * @memberof streem.api.SsoProvider.Oidc.DiscoverEndpoints
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DiscoverEndpoints.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.SsoProvider.Oidc.DiscoverEndpoints";
                    };

                    return DiscoverEndpoints;
                })();

                Oidc.ExplicitEndpoints = (function() {

                    /**
                     * Properties of an ExplicitEndpoints.
                     * @memberof streem.api.SsoProvider.Oidc
                     * @interface IExplicitEndpoints
                     * @property {string|null} [authorization] ExplicitEndpoints authorization
                     * @property {string|null} [token] ExplicitEndpoints token
                     * @property {string|null} [userInfo] ExplicitEndpoints userInfo
                     */

                    /**
                     * Constructs a new ExplicitEndpoints.
                     * @memberof streem.api.SsoProvider.Oidc
                     * @classdesc Represents an ExplicitEndpoints.
                     * @implements IExplicitEndpoints
                     * @constructor
                     * @param {streem.api.SsoProvider.Oidc.IExplicitEndpoints=} [p] Properties to set
                     */
                    function ExplicitEndpoints(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * ExplicitEndpoints authorization.
                     * @member {string} authorization
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @instance
                     */
                    ExplicitEndpoints.prototype.authorization = "";

                    /**
                     * ExplicitEndpoints token.
                     * @member {string} token
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @instance
                     */
                    ExplicitEndpoints.prototype.token = "";

                    /**
                     * ExplicitEndpoints userInfo.
                     * @member {string} userInfo
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @instance
                     */
                    ExplicitEndpoints.prototype.userInfo = "";

                    /**
                     * Creates a new ExplicitEndpoints instance using the specified properties.
                     * @function create
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.IExplicitEndpoints=} [properties] Properties to set
                     * @returns {streem.api.SsoProvider.Oidc.ExplicitEndpoints} ExplicitEndpoints instance
                     */
                    ExplicitEndpoints.create = function create(properties) {
                        return new ExplicitEndpoints(properties);
                    };

                    /**
                     * Encodes the specified ExplicitEndpoints message. Does not implicitly {@link streem.api.SsoProvider.Oidc.ExplicitEndpoints.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.IExplicitEndpoints} m ExplicitEndpoints message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExplicitEndpoints.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.authorization != null && Object.hasOwnProperty.call(m, "authorization"))
                            w.uint32(10).string(m.authorization);
                        if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                            w.uint32(18).string(m.token);
                        if (m.userInfo != null && Object.hasOwnProperty.call(m, "userInfo"))
                            w.uint32(26).string(m.userInfo);
                        return w;
                    };

                    /**
                     * Decodes an ExplicitEndpoints message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.SsoProvider.Oidc.ExplicitEndpoints} ExplicitEndpoints
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExplicitEndpoints.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.authorization = r.string();
                                    break;
                                }
                            case 2: {
                                    m.token = r.string();
                                    break;
                                }
                            case 3: {
                                    m.userInfo = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates an ExplicitEndpoints message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.SsoProvider.Oidc.ExplicitEndpoints} ExplicitEndpoints
                     */
                    ExplicitEndpoints.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints)
                            return d;
                        var m = new $root.streem.api.SsoProvider.Oidc.ExplicitEndpoints();
                        if (d.authorization != null) {
                            m.authorization = String(d.authorization);
                        }
                        if (d.token != null) {
                            m.token = String(d.token);
                        }
                        if (d.userInfo != null) {
                            m.userInfo = String(d.userInfo);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an ExplicitEndpoints message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {streem.api.SsoProvider.Oidc.ExplicitEndpoints} m ExplicitEndpoints
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExplicitEndpoints.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.authorization = "";
                            d.token = "";
                            d.userInfo = "";
                        }
                        if (m.authorization != null && m.hasOwnProperty("authorization")) {
                            d.authorization = m.authorization;
                        }
                        if (m.token != null && m.hasOwnProperty("token")) {
                            d.token = m.token;
                        }
                        if (m.userInfo != null && m.hasOwnProperty("userInfo")) {
                            d.userInfo = m.userInfo;
                        }
                        return d;
                    };

                    /**
                     * Converts this ExplicitEndpoints to JSON.
                     * @function toJSON
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExplicitEndpoints.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for ExplicitEndpoints
                     * @function getTypeUrl
                     * @memberof streem.api.SsoProvider.Oidc.ExplicitEndpoints
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ExplicitEndpoints.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.SsoProvider.Oidc.ExplicitEndpoints";
                    };

                    return ExplicitEndpoints;
                })();

                return Oidc;
            })();

            SsoProvider.SamlV2 = (function() {

                /**
                 * Properties of a SamlV2.
                 * @memberof streem.api.SsoProvider
                 * @interface ISamlV2
                 * @property {string|null} [loginEndpoint] SamlV2 loginEndpoint
                 * @property {Array.<string>|null} [managedDomains] SamlV2 managedDomains
                 * @property {boolean|null} [useNameIdForEmail] SamlV2 useNameIdForEmail
                 * @property {string|null} [emailClaim] SamlV2 emailClaim
                 * @property {string|null} [fusionAuthKeyId] SamlV2 fusionAuthKeyId
                 * @property {string|null} [verificationPublicKey] SamlV2 verificationPublicKey
                 * @property {string|null} [verificationCertificate] SamlV2 verificationCertificate
                 * @property {string|null} [fusionAuthLambdaId] SamlV2 fusionAuthLambdaId
                 * @property {string|null} [usernameClaim] SamlV2 usernameClaim
                 */

                /**
                 * Constructs a new SamlV2.
                 * @memberof streem.api.SsoProvider
                 * @classdesc Represents a SamlV2.
                 * @implements ISamlV2
                 * @constructor
                 * @param {streem.api.SsoProvider.ISamlV2=} [p] Properties to set
                 */
                function SamlV2(p) {
                    this.managedDomains = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SamlV2 loginEndpoint.
                 * @member {string} loginEndpoint
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.loginEndpoint = "";

                /**
                 * SamlV2 managedDomains.
                 * @member {Array.<string>} managedDomains
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.managedDomains = $util.emptyArray;

                /**
                 * SamlV2 useNameIdForEmail.
                 * @member {boolean|null|undefined} useNameIdForEmail
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.useNameIdForEmail = null;

                /**
                 * SamlV2 emailClaim.
                 * @member {string|null|undefined} emailClaim
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.emailClaim = null;

                /**
                 * SamlV2 fusionAuthKeyId.
                 * @member {string|null|undefined} fusionAuthKeyId
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.fusionAuthKeyId = null;

                /**
                 * SamlV2 verificationPublicKey.
                 * @member {string|null|undefined} verificationPublicKey
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.verificationPublicKey = null;

                /**
                 * SamlV2 verificationCertificate.
                 * @member {string|null|undefined} verificationCertificate
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.verificationCertificate = null;

                /**
                 * SamlV2 fusionAuthLambdaId.
                 * @member {string|null|undefined} fusionAuthLambdaId
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.fusionAuthLambdaId = null;

                /**
                 * SamlV2 usernameClaim.
                 * @member {string|null|undefined} usernameClaim
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                SamlV2.prototype.usernameClaim = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * SamlV2 email.
                 * @member {"useNameIdForEmail"|"emailClaim"|undefined} email
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                Object.defineProperty(SamlV2.prototype, "email", {
                    get: $util.oneOfGetter($oneOfFields = ["useNameIdForEmail", "emailClaim"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * SamlV2 key.
                 * @member {"fusionAuthKeyId"|"verificationPublicKey"|"verificationCertificate"|undefined} key
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                Object.defineProperty(SamlV2.prototype, "key", {
                    get: $util.oneOfGetter($oneOfFields = ["fusionAuthKeyId", "verificationPublicKey", "verificationCertificate"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * SamlV2 reconcile.
                 * @member {"fusionAuthLambdaId"|"usernameClaim"|undefined} reconcile
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 */
                Object.defineProperty(SamlV2.prototype, "reconcile", {
                    get: $util.oneOfGetter($oneOfFields = ["fusionAuthLambdaId", "usernameClaim"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new SamlV2 instance using the specified properties.
                 * @function create
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {streem.api.SsoProvider.ISamlV2=} [properties] Properties to set
                 * @returns {streem.api.SsoProvider.SamlV2} SamlV2 instance
                 */
                SamlV2.create = function create(properties) {
                    return new SamlV2(properties);
                };

                /**
                 * Encodes the specified SamlV2 message. Does not implicitly {@link streem.api.SsoProvider.SamlV2.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {streem.api.SsoProvider.ISamlV2} m SamlV2 message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SamlV2.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.loginEndpoint != null && Object.hasOwnProperty.call(m, "loginEndpoint"))
                        w.uint32(10).string(m.loginEndpoint);
                    if (m.managedDomains != null && m.managedDomains.length) {
                        for (var i = 0; i < m.managedDomains.length; ++i)
                            w.uint32(18).string(m.managedDomains[i]);
                    }
                    if (m.useNameIdForEmail != null && Object.hasOwnProperty.call(m, "useNameIdForEmail"))
                        w.uint32(80).bool(m.useNameIdForEmail);
                    if (m.emailClaim != null && Object.hasOwnProperty.call(m, "emailClaim"))
                        w.uint32(90).string(m.emailClaim);
                    if (m.fusionAuthKeyId != null && Object.hasOwnProperty.call(m, "fusionAuthKeyId"))
                        w.uint32(162).string(m.fusionAuthKeyId);
                    if (m.verificationPublicKey != null && Object.hasOwnProperty.call(m, "verificationPublicKey"))
                        w.uint32(170).string(m.verificationPublicKey);
                    if (m.verificationCertificate != null && Object.hasOwnProperty.call(m, "verificationCertificate"))
                        w.uint32(178).string(m.verificationCertificate);
                    if (m.fusionAuthLambdaId != null && Object.hasOwnProperty.call(m, "fusionAuthLambdaId"))
                        w.uint32(242).string(m.fusionAuthLambdaId);
                    if (m.usernameClaim != null && Object.hasOwnProperty.call(m, "usernameClaim"))
                        w.uint32(250).string(m.usernameClaim);
                    return w;
                };

                /**
                 * Decodes a SamlV2 message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.SsoProvider.SamlV2} SamlV2
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SamlV2.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProvider.SamlV2();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.loginEndpoint = r.string();
                                break;
                            }
                        case 2: {
                                if (!(m.managedDomains && m.managedDomains.length))
                                    m.managedDomains = [];
                                m.managedDomains.push(r.string());
                                break;
                            }
                        case 10: {
                                m.useNameIdForEmail = r.bool();
                                break;
                            }
                        case 11: {
                                m.emailClaim = r.string();
                                break;
                            }
                        case 20: {
                                m.fusionAuthKeyId = r.string();
                                break;
                            }
                        case 21: {
                                m.verificationPublicKey = r.string();
                                break;
                            }
                        case 22: {
                                m.verificationCertificate = r.string();
                                break;
                            }
                        case 30: {
                                m.fusionAuthLambdaId = r.string();
                                break;
                            }
                        case 31: {
                                m.usernameClaim = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SamlV2 message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.SsoProvider.SamlV2} SamlV2
                 */
                SamlV2.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.SsoProvider.SamlV2)
                        return d;
                    var m = new $root.streem.api.SsoProvider.SamlV2();
                    if (d.loginEndpoint != null) {
                        m.loginEndpoint = String(d.loginEndpoint);
                    }
                    if (d.managedDomains) {
                        if (!Array.isArray(d.managedDomains))
                            throw TypeError(".streem.api.SsoProvider.SamlV2.managedDomains: array expected");
                        m.managedDomains = [];
                        for (var i = 0; i < d.managedDomains.length; ++i) {
                            m.managedDomains[i] = String(d.managedDomains[i]);
                        }
                    }
                    if (d.useNameIdForEmail != null) {
                        m.useNameIdForEmail = Boolean(d.useNameIdForEmail);
                    }
                    if (d.emailClaim != null) {
                        m.emailClaim = String(d.emailClaim);
                    }
                    if (d.fusionAuthKeyId != null) {
                        m.fusionAuthKeyId = String(d.fusionAuthKeyId);
                    }
                    if (d.verificationPublicKey != null) {
                        m.verificationPublicKey = String(d.verificationPublicKey);
                    }
                    if (d.verificationCertificate != null) {
                        m.verificationCertificate = String(d.verificationCertificate);
                    }
                    if (d.fusionAuthLambdaId != null) {
                        m.fusionAuthLambdaId = String(d.fusionAuthLambdaId);
                    }
                    if (d.usernameClaim != null) {
                        m.usernameClaim = String(d.usernameClaim);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SamlV2 message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {streem.api.SsoProvider.SamlV2} m SamlV2
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SamlV2.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.managedDomains = [];
                    }
                    if (o.defaults) {
                        d.loginEndpoint = "";
                    }
                    if (m.loginEndpoint != null && m.hasOwnProperty("loginEndpoint")) {
                        d.loginEndpoint = m.loginEndpoint;
                    }
                    if (m.managedDomains && m.managedDomains.length) {
                        d.managedDomains = [];
                        for (var j = 0; j < m.managedDomains.length; ++j) {
                            d.managedDomains[j] = m.managedDomains[j];
                        }
                    }
                    if (m.useNameIdForEmail != null && m.hasOwnProperty("useNameIdForEmail")) {
                        d.useNameIdForEmail = m.useNameIdForEmail;
                        if (o.oneofs)
                            d.email = "useNameIdForEmail";
                    }
                    if (m.emailClaim != null && m.hasOwnProperty("emailClaim")) {
                        d.emailClaim = m.emailClaim;
                        if (o.oneofs)
                            d.email = "emailClaim";
                    }
                    if (m.fusionAuthKeyId != null && m.hasOwnProperty("fusionAuthKeyId")) {
                        d.fusionAuthKeyId = m.fusionAuthKeyId;
                        if (o.oneofs)
                            d.key = "fusionAuthKeyId";
                    }
                    if (m.verificationPublicKey != null && m.hasOwnProperty("verificationPublicKey")) {
                        d.verificationPublicKey = m.verificationPublicKey;
                        if (o.oneofs)
                            d.key = "verificationPublicKey";
                    }
                    if (m.verificationCertificate != null && m.hasOwnProperty("verificationCertificate")) {
                        d.verificationCertificate = m.verificationCertificate;
                        if (o.oneofs)
                            d.key = "verificationCertificate";
                    }
                    if (m.fusionAuthLambdaId != null && m.hasOwnProperty("fusionAuthLambdaId")) {
                        d.fusionAuthLambdaId = m.fusionAuthLambdaId;
                        if (o.oneofs)
                            d.reconcile = "fusionAuthLambdaId";
                    }
                    if (m.usernameClaim != null && m.hasOwnProperty("usernameClaim")) {
                        d.usernameClaim = m.usernameClaim;
                        if (o.oneofs)
                            d.reconcile = "usernameClaim";
                    }
                    return d;
                };

                /**
                 * Converts this SamlV2 to JSON.
                 * @function toJSON
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SamlV2.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SamlV2
                 * @function getTypeUrl
                 * @memberof streem.api.SsoProvider.SamlV2
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SamlV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.SsoProvider.SamlV2";
                };

                return SamlV2;
            })();

            return SsoProvider;
        })();

        api.SsoProviderCompanyMapping = (function() {

            /**
             * Properties of a SsoProviderCompanyMapping.
             * @memberof streem.api
             * @interface ISsoProviderCompanyMapping
             * @property {string|null} [ssoProviderCode] SsoProviderCompanyMapping ssoProviderCode
             * @property {string|null} [companyCode] SsoProviderCompanyMapping companyCode
             * @property {boolean|null} [automaticRegistration] SsoProviderCompanyMapping automaticRegistration
             */

            /**
             * Constructs a new SsoProviderCompanyMapping.
             * @memberof streem.api
             * @classdesc Represents a SsoProviderCompanyMapping.
             * @implements ISsoProviderCompanyMapping
             * @constructor
             * @param {streem.api.ISsoProviderCompanyMapping=} [p] Properties to set
             */
            function SsoProviderCompanyMapping(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SsoProviderCompanyMapping ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.SsoProviderCompanyMapping
             * @instance
             */
            SsoProviderCompanyMapping.prototype.ssoProviderCode = "";

            /**
             * SsoProviderCompanyMapping companyCode.
             * @member {string} companyCode
             * @memberof streem.api.SsoProviderCompanyMapping
             * @instance
             */
            SsoProviderCompanyMapping.prototype.companyCode = "";

            /**
             * SsoProviderCompanyMapping automaticRegistration.
             * @member {boolean} automaticRegistration
             * @memberof streem.api.SsoProviderCompanyMapping
             * @instance
             */
            SsoProviderCompanyMapping.prototype.automaticRegistration = false;

            /**
             * Creates a new SsoProviderCompanyMapping instance using the specified properties.
             * @function create
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {streem.api.ISsoProviderCompanyMapping=} [properties] Properties to set
             * @returns {streem.api.SsoProviderCompanyMapping} SsoProviderCompanyMapping instance
             */
            SsoProviderCompanyMapping.create = function create(properties) {
                return new SsoProviderCompanyMapping(properties);
            };

            /**
             * Encodes the specified SsoProviderCompanyMapping message. Does not implicitly {@link streem.api.SsoProviderCompanyMapping.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {streem.api.ISsoProviderCompanyMapping} m SsoProviderCompanyMapping message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SsoProviderCompanyMapping.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                if (m.automaticRegistration != null && Object.hasOwnProperty.call(m, "automaticRegistration"))
                    w.uint32(24).bool(m.automaticRegistration);
                return w;
            };

            /**
             * Decodes a SsoProviderCompanyMapping message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SsoProviderCompanyMapping} SsoProviderCompanyMapping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SsoProviderCompanyMapping.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SsoProviderCompanyMapping();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 3: {
                            m.automaticRegistration = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SsoProviderCompanyMapping message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SsoProviderCompanyMapping} SsoProviderCompanyMapping
             */
            SsoProviderCompanyMapping.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SsoProviderCompanyMapping)
                    return d;
                var m = new $root.streem.api.SsoProviderCompanyMapping();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.automaticRegistration != null) {
                    m.automaticRegistration = Boolean(d.automaticRegistration);
                }
                return m;
            };

            /**
             * Creates a plain object from a SsoProviderCompanyMapping message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {streem.api.SsoProviderCompanyMapping} m SsoProviderCompanyMapping
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SsoProviderCompanyMapping.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.companyCode = "";
                    d.automaticRegistration = false;
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.automaticRegistration != null && m.hasOwnProperty("automaticRegistration")) {
                    d.automaticRegistration = m.automaticRegistration;
                }
                return d;
            };

            /**
             * Converts this SsoProviderCompanyMapping to JSON.
             * @function toJSON
             * @memberof streem.api.SsoProviderCompanyMapping
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SsoProviderCompanyMapping.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SsoProviderCompanyMapping
             * @function getTypeUrl
             * @memberof streem.api.SsoProviderCompanyMapping
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SsoProviderCompanyMapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SsoProviderCompanyMapping";
            };

            return SsoProviderCompanyMapping;
        })();

        api.events = (function() {

            /**
             * Namespace events.
             * @memberof streem.api
             * @namespace
             */
            var events = {};

            events.RoomCreated = (function() {

                /**
                 * Properties of a RoomCreated.
                 * @memberof streem.api.events
                 * @interface IRoomCreated
                 * @property {streem.api.IRoom|null} [room] RoomCreated room
                 */

                /**
                 * Constructs a new RoomCreated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomCreated.
                 * @implements IRoomCreated
                 * @constructor
                 * @param {streem.api.events.IRoomCreated=} [p] Properties to set
                 */
                function RoomCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomCreated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomCreated
                 * @instance
                 */
                RoomCreated.prototype.room = null;

                /**
                 * Creates a new RoomCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {streem.api.events.IRoomCreated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomCreated} RoomCreated instance
                 */
                RoomCreated.create = function create(properties) {
                    return new RoomCreated(properties);
                };

                /**
                 * Encodes the specified RoomCreated message. Does not implicitly {@link streem.api.events.RoomCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {streem.api.events.IRoomCreated} m RoomCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomCreated} RoomCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomCreated} RoomCreated
                 */
                RoomCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomCreated)
                        return d;
                    var m = new $root.streem.api.events.RoomCreated();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomCreated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {streem.api.events.RoomCreated} m RoomCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomCreated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomCreated";
                };

                return RoomCreated;
            })();

            events.RoomUpdated = (function() {

                /**
                 * Properties of a RoomUpdated.
                 * @memberof streem.api.events
                 * @interface IRoomUpdated
                 * @property {streem.api.IRoom|null} [previousRoom] RoomUpdated previousRoom
                 * @property {streem.api.IRoom|null} [updatedRoom] RoomUpdated updatedRoom
                 */

                /**
                 * Constructs a new RoomUpdated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomUpdated.
                 * @implements IRoomUpdated
                 * @constructor
                 * @param {streem.api.events.IRoomUpdated=} [p] Properties to set
                 */
                function RoomUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomUpdated previousRoom.
                 * @member {streem.api.IRoom|null|undefined} previousRoom
                 * @memberof streem.api.events.RoomUpdated
                 * @instance
                 */
                RoomUpdated.prototype.previousRoom = null;

                /**
                 * RoomUpdated updatedRoom.
                 * @member {streem.api.IRoom|null|undefined} updatedRoom
                 * @memberof streem.api.events.RoomUpdated
                 * @instance
                 */
                RoomUpdated.prototype.updatedRoom = null;

                /**
                 * Creates a new RoomUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {streem.api.events.IRoomUpdated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomUpdated} RoomUpdated instance
                 */
                RoomUpdated.create = function create(properties) {
                    return new RoomUpdated(properties);
                };

                /**
                 * Encodes the specified RoomUpdated message. Does not implicitly {@link streem.api.events.RoomUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {streem.api.events.IRoomUpdated} m RoomUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.previousRoom != null && Object.hasOwnProperty.call(m, "previousRoom"))
                        $root.streem.api.Room.encode(m.previousRoom, w.uint32(10).fork()).ldelim();
                    if (m.updatedRoom != null && Object.hasOwnProperty.call(m, "updatedRoom"))
                        $root.streem.api.Room.encode(m.updatedRoom, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomUpdated} RoomUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.previousRoom = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.updatedRoom = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomUpdated} RoomUpdated
                 */
                RoomUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomUpdated)
                        return d;
                    var m = new $root.streem.api.events.RoomUpdated();
                    if (d.previousRoom != null) {
                        if (typeof d.previousRoom !== "object")
                            throw TypeError(".streem.api.events.RoomUpdated.previousRoom: object expected");
                        m.previousRoom = $root.streem.api.Room.fromObject(d.previousRoom);
                    }
                    if (d.updatedRoom != null) {
                        if (typeof d.updatedRoom !== "object")
                            throw TypeError(".streem.api.events.RoomUpdated.updatedRoom: object expected");
                        m.updatedRoom = $root.streem.api.Room.fromObject(d.updatedRoom);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {streem.api.events.RoomUpdated} m RoomUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.previousRoom = null;
                        d.updatedRoom = null;
                    }
                    if (m.previousRoom != null && m.hasOwnProperty("previousRoom")) {
                        d.previousRoom = $root.streem.api.Room.toObject(m.previousRoom, o);
                    }
                    if (m.updatedRoom != null && m.hasOwnProperty("updatedRoom")) {
                        d.updatedRoom = $root.streem.api.Room.toObject(m.updatedRoom, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomUpdated";
                };

                return RoomUpdated;
            })();

            events.RoomArchived = (function() {

                /**
                 * Properties of a RoomArchived.
                 * @memberof streem.api.events
                 * @interface IRoomArchived
                 * @property {streem.api.IRoom|null} [room] RoomArchived room
                 * @property {google.protobuf.ITimestamp|null} [archivedAt] RoomArchived archivedAt
                 * @property {string|null} [archiveS3FolderPath] RoomArchived archiveS3FolderPath
                 * @property {string|null} [recordingArtifactId] RoomArchived recordingArtifactId
                 */

                /**
                 * Constructs a new RoomArchived.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomArchived.
                 * @implements IRoomArchived
                 * @constructor
                 * @param {streem.api.events.IRoomArchived=} [p] Properties to set
                 */
                function RoomArchived(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomArchived room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomArchived
                 * @instance
                 */
                RoomArchived.prototype.room = null;

                /**
                 * RoomArchived archivedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} archivedAt
                 * @memberof streem.api.events.RoomArchived
                 * @instance
                 */
                RoomArchived.prototype.archivedAt = null;

                /**
                 * RoomArchived archiveS3FolderPath.
                 * @member {string} archiveS3FolderPath
                 * @memberof streem.api.events.RoomArchived
                 * @instance
                 */
                RoomArchived.prototype.archiveS3FolderPath = "";

                /**
                 * RoomArchived recordingArtifactId.
                 * @member {string} recordingArtifactId
                 * @memberof streem.api.events.RoomArchived
                 * @instance
                 */
                RoomArchived.prototype.recordingArtifactId = "";

                /**
                 * Creates a new RoomArchived instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {streem.api.events.IRoomArchived=} [properties] Properties to set
                 * @returns {streem.api.events.RoomArchived} RoomArchived instance
                 */
                RoomArchived.create = function create(properties) {
                    return new RoomArchived(properties);
                };

                /**
                 * Encodes the specified RoomArchived message. Does not implicitly {@link streem.api.events.RoomArchived.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {streem.api.events.IRoomArchived} m RoomArchived message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomArchived.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.archivedAt != null && Object.hasOwnProperty.call(m, "archivedAt"))
                        $root.google.protobuf.Timestamp.encode(m.archivedAt, w.uint32(18).fork()).ldelim();
                    if (m.archiveS3FolderPath != null && Object.hasOwnProperty.call(m, "archiveS3FolderPath"))
                        w.uint32(26).string(m.archiveS3FolderPath);
                    if (m.recordingArtifactId != null && Object.hasOwnProperty.call(m, "recordingArtifactId"))
                        w.uint32(34).string(m.recordingArtifactId);
                    return w;
                };

                /**
                 * Decodes a RoomArchived message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomArchived} RoomArchived
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomArchived.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomArchived();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.archivedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.archiveS3FolderPath = r.string();
                                break;
                            }
                        case 4: {
                                m.recordingArtifactId = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomArchived message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomArchived} RoomArchived
                 */
                RoomArchived.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomArchived)
                        return d;
                    var m = new $root.streem.api.events.RoomArchived();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomArchived.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.archivedAt != null) {
                        if (typeof d.archivedAt !== "object")
                            throw TypeError(".streem.api.events.RoomArchived.archivedAt: object expected");
                        m.archivedAt = $root.google.protobuf.Timestamp.fromObject(d.archivedAt);
                    }
                    if (d.archiveS3FolderPath != null) {
                        m.archiveS3FolderPath = String(d.archiveS3FolderPath);
                    }
                    if (d.recordingArtifactId != null) {
                        m.recordingArtifactId = String(d.recordingArtifactId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomArchived message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {streem.api.events.RoomArchived} m RoomArchived
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomArchived.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.archivedAt = null;
                        d.archiveS3FolderPath = "";
                        d.recordingArtifactId = "";
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.archivedAt != null && m.hasOwnProperty("archivedAt")) {
                        d.archivedAt = $root.google.protobuf.Timestamp.toObject(m.archivedAt, o);
                    }
                    if (m.archiveS3FolderPath != null && m.hasOwnProperty("archiveS3FolderPath")) {
                        d.archiveS3FolderPath = m.archiveS3FolderPath;
                    }
                    if (m.recordingArtifactId != null && m.hasOwnProperty("recordingArtifactId")) {
                        d.recordingArtifactId = m.recordingArtifactId;
                    }
                    return d;
                };

                /**
                 * Converts this RoomArchived to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomArchived
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomArchived.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomArchived
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomArchived
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomArchived.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomArchived";
                };

                return RoomArchived;
            })();

            events.RoomParticipantCreated = (function() {

                /**
                 * Properties of a RoomParticipantCreated.
                 * @memberof streem.api.events
                 * @interface IRoomParticipantCreated
                 * @property {streem.api.IUser|null} [user] RoomParticipantCreated user
                 * @property {streem.api.IRoom|null} [room] RoomParticipantCreated room
                 * @property {streem.api.IRoomParticipant|null} [participant] RoomParticipantCreated participant
                 */

                /**
                 * Constructs a new RoomParticipantCreated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomParticipantCreated.
                 * @implements IRoomParticipantCreated
                 * @constructor
                 * @param {streem.api.events.IRoomParticipantCreated=} [p] Properties to set
                 */
                function RoomParticipantCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomParticipantCreated user.
                 * @member {streem.api.IUser|null|undefined} user
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @instance
                 */
                RoomParticipantCreated.prototype.user = null;

                /**
                 * RoomParticipantCreated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @instance
                 */
                RoomParticipantCreated.prototype.room = null;

                /**
                 * RoomParticipantCreated participant.
                 * @member {streem.api.IRoomParticipant|null|undefined} participant
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @instance
                 */
                RoomParticipantCreated.prototype.participant = null;

                /**
                 * Creates a new RoomParticipantCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {streem.api.events.IRoomParticipantCreated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomParticipantCreated} RoomParticipantCreated instance
                 */
                RoomParticipantCreated.create = function create(properties) {
                    return new RoomParticipantCreated(properties);
                };

                /**
                 * Encodes the specified RoomParticipantCreated message. Does not implicitly {@link streem.api.events.RoomParticipantCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {streem.api.events.IRoomParticipantCreated} m RoomParticipantCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomParticipantCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                        $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(18).fork()).ldelim();
                    if (m.participant != null && Object.hasOwnProperty.call(m, "participant"))
                        $root.streem.api.RoomParticipant.encode(m.participant, w.uint32(26).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomParticipantCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomParticipantCreated} RoomParticipantCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomParticipantCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomParticipantCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.user = $root.streem.api.User.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.participant = $root.streem.api.RoomParticipant.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomParticipantCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomParticipantCreated} RoomParticipantCreated
                 */
                RoomParticipantCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomParticipantCreated)
                        return d;
                    var m = new $root.streem.api.events.RoomParticipantCreated();
                    if (d.user != null) {
                        if (typeof d.user !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantCreated.user: object expected");
                        m.user = $root.streem.api.User.fromObject(d.user);
                    }
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantCreated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.participant != null) {
                        if (typeof d.participant !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantCreated.participant: object expected");
                        m.participant = $root.streem.api.RoomParticipant.fromObject(d.participant);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomParticipantCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {streem.api.events.RoomParticipantCreated} m RoomParticipantCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomParticipantCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.user = null;
                        d.room = null;
                        d.participant = null;
                    }
                    if (m.user != null && m.hasOwnProperty("user")) {
                        d.user = $root.streem.api.User.toObject(m.user, o);
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.participant != null && m.hasOwnProperty("participant")) {
                        d.participant = $root.streem.api.RoomParticipant.toObject(m.participant, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomParticipantCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomParticipantCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomParticipantCreated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomParticipantCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomParticipantCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomParticipantCreated";
                };

                return RoomParticipantCreated;
            })();

            events.RoomParticipantUpdated = (function() {

                /**
                 * Properties of a RoomParticipantUpdated.
                 * @memberof streem.api.events
                 * @interface IRoomParticipantUpdated
                 * @property {streem.api.IUser|null} [user] RoomParticipantUpdated user
                 * @property {streem.api.IRoom|null} [room] RoomParticipantUpdated room
                 * @property {streem.api.IRoomParticipant|null} [previousParticipant] RoomParticipantUpdated previousParticipant
                 * @property {streem.api.IRoomParticipant|null} [updatedParticipant] RoomParticipantUpdated updatedParticipant
                 */

                /**
                 * Constructs a new RoomParticipantUpdated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomParticipantUpdated.
                 * @implements IRoomParticipantUpdated
                 * @constructor
                 * @param {streem.api.events.IRoomParticipantUpdated=} [p] Properties to set
                 */
                function RoomParticipantUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomParticipantUpdated user.
                 * @member {streem.api.IUser|null|undefined} user
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @instance
                 */
                RoomParticipantUpdated.prototype.user = null;

                /**
                 * RoomParticipantUpdated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @instance
                 */
                RoomParticipantUpdated.prototype.room = null;

                /**
                 * RoomParticipantUpdated previousParticipant.
                 * @member {streem.api.IRoomParticipant|null|undefined} previousParticipant
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @instance
                 */
                RoomParticipantUpdated.prototype.previousParticipant = null;

                /**
                 * RoomParticipantUpdated updatedParticipant.
                 * @member {streem.api.IRoomParticipant|null|undefined} updatedParticipant
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @instance
                 */
                RoomParticipantUpdated.prototype.updatedParticipant = null;

                /**
                 * Creates a new RoomParticipantUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {streem.api.events.IRoomParticipantUpdated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomParticipantUpdated} RoomParticipantUpdated instance
                 */
                RoomParticipantUpdated.create = function create(properties) {
                    return new RoomParticipantUpdated(properties);
                };

                /**
                 * Encodes the specified RoomParticipantUpdated message. Does not implicitly {@link streem.api.events.RoomParticipantUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {streem.api.events.IRoomParticipantUpdated} m RoomParticipantUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomParticipantUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                        $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(18).fork()).ldelim();
                    if (m.previousParticipant != null && Object.hasOwnProperty.call(m, "previousParticipant"))
                        $root.streem.api.RoomParticipant.encode(m.previousParticipant, w.uint32(26).fork()).ldelim();
                    if (m.updatedParticipant != null && Object.hasOwnProperty.call(m, "updatedParticipant"))
                        $root.streem.api.RoomParticipant.encode(m.updatedParticipant, w.uint32(34).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomParticipantUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomParticipantUpdated} RoomParticipantUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomParticipantUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomParticipantUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.user = $root.streem.api.User.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.previousParticipant = $root.streem.api.RoomParticipant.decode(r, r.uint32());
                                break;
                            }
                        case 4: {
                                m.updatedParticipant = $root.streem.api.RoomParticipant.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomParticipantUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomParticipantUpdated} RoomParticipantUpdated
                 */
                RoomParticipantUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomParticipantUpdated)
                        return d;
                    var m = new $root.streem.api.events.RoomParticipantUpdated();
                    if (d.user != null) {
                        if (typeof d.user !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantUpdated.user: object expected");
                        m.user = $root.streem.api.User.fromObject(d.user);
                    }
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantUpdated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.previousParticipant != null) {
                        if (typeof d.previousParticipant !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantUpdated.previousParticipant: object expected");
                        m.previousParticipant = $root.streem.api.RoomParticipant.fromObject(d.previousParticipant);
                    }
                    if (d.updatedParticipant != null) {
                        if (typeof d.updatedParticipant !== "object")
                            throw TypeError(".streem.api.events.RoomParticipantUpdated.updatedParticipant: object expected");
                        m.updatedParticipant = $root.streem.api.RoomParticipant.fromObject(d.updatedParticipant);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomParticipantUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {streem.api.events.RoomParticipantUpdated} m RoomParticipantUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomParticipantUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.user = null;
                        d.room = null;
                        d.previousParticipant = null;
                        d.updatedParticipant = null;
                    }
                    if (m.user != null && m.hasOwnProperty("user")) {
                        d.user = $root.streem.api.User.toObject(m.user, o);
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.previousParticipant != null && m.hasOwnProperty("previousParticipant")) {
                        d.previousParticipant = $root.streem.api.RoomParticipant.toObject(m.previousParticipant, o);
                    }
                    if (m.updatedParticipant != null && m.hasOwnProperty("updatedParticipant")) {
                        d.updatedParticipant = $root.streem.api.RoomParticipant.toObject(m.updatedParticipant, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomParticipantUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomParticipantUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomParticipantUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomParticipantUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomParticipantUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomParticipantUpdated";
                };

                return RoomParticipantUpdated;
            })();

            events.GroupReservationCreated = (function() {

                /**
                 * Properties of a GroupReservationCreated.
                 * @memberof streem.api.events
                 * @interface IGroupReservationCreated
                 * @property {streem.api.IGroupReservation|null} [groupReservation] GroupReservationCreated groupReservation
                 */

                /**
                 * Constructs a new GroupReservationCreated.
                 * @memberof streem.api.events
                 * @classdesc Represents a GroupReservationCreated.
                 * @implements IGroupReservationCreated
                 * @constructor
                 * @param {streem.api.events.IGroupReservationCreated=} [p] Properties to set
                 */
                function GroupReservationCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GroupReservationCreated groupReservation.
                 * @member {streem.api.IGroupReservation|null|undefined} groupReservation
                 * @memberof streem.api.events.GroupReservationCreated
                 * @instance
                 */
                GroupReservationCreated.prototype.groupReservation = null;

                /**
                 * Creates a new GroupReservationCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {streem.api.events.IGroupReservationCreated=} [properties] Properties to set
                 * @returns {streem.api.events.GroupReservationCreated} GroupReservationCreated instance
                 */
                GroupReservationCreated.create = function create(properties) {
                    return new GroupReservationCreated(properties);
                };

                /**
                 * Encodes the specified GroupReservationCreated message. Does not implicitly {@link streem.api.events.GroupReservationCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {streem.api.events.IGroupReservationCreated} m GroupReservationCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GroupReservationCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.groupReservation != null && Object.hasOwnProperty.call(m, "groupReservation"))
                        $root.streem.api.GroupReservation.encode(m.groupReservation, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GroupReservationCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.GroupReservationCreated} GroupReservationCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GroupReservationCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.GroupReservationCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.groupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GroupReservationCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.GroupReservationCreated} GroupReservationCreated
                 */
                GroupReservationCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.GroupReservationCreated)
                        return d;
                    var m = new $root.streem.api.events.GroupReservationCreated();
                    if (d.groupReservation != null) {
                        if (typeof d.groupReservation !== "object")
                            throw TypeError(".streem.api.events.GroupReservationCreated.groupReservation: object expected");
                        m.groupReservation = $root.streem.api.GroupReservation.fromObject(d.groupReservation);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GroupReservationCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {streem.api.events.GroupReservationCreated} m GroupReservationCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GroupReservationCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.groupReservation = null;
                    }
                    if (m.groupReservation != null && m.hasOwnProperty("groupReservation")) {
                        d.groupReservation = $root.streem.api.GroupReservation.toObject(m.groupReservation, o);
                    }
                    return d;
                };

                /**
                 * Converts this GroupReservationCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.GroupReservationCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GroupReservationCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GroupReservationCreated
                 * @function getTypeUrl
                 * @memberof streem.api.events.GroupReservationCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GroupReservationCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.GroupReservationCreated";
                };

                return GroupReservationCreated;
            })();

            events.GroupReservationUpdated = (function() {

                /**
                 * Properties of a GroupReservationUpdated.
                 * @memberof streem.api.events
                 * @interface IGroupReservationUpdated
                 * @property {streem.api.IGroupReservation|null} [previousGroupReservation] GroupReservationUpdated previousGroupReservation
                 * @property {streem.api.IGroupReservation|null} [updatedGroupReservation] GroupReservationUpdated updatedGroupReservation
                 */

                /**
                 * Constructs a new GroupReservationUpdated.
                 * @memberof streem.api.events
                 * @classdesc Represents a GroupReservationUpdated.
                 * @implements IGroupReservationUpdated
                 * @constructor
                 * @param {streem.api.events.IGroupReservationUpdated=} [p] Properties to set
                 */
                function GroupReservationUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GroupReservationUpdated previousGroupReservation.
                 * @member {streem.api.IGroupReservation|null|undefined} previousGroupReservation
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @instance
                 */
                GroupReservationUpdated.prototype.previousGroupReservation = null;

                /**
                 * GroupReservationUpdated updatedGroupReservation.
                 * @member {streem.api.IGroupReservation|null|undefined} updatedGroupReservation
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @instance
                 */
                GroupReservationUpdated.prototype.updatedGroupReservation = null;

                /**
                 * Creates a new GroupReservationUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {streem.api.events.IGroupReservationUpdated=} [properties] Properties to set
                 * @returns {streem.api.events.GroupReservationUpdated} GroupReservationUpdated instance
                 */
                GroupReservationUpdated.create = function create(properties) {
                    return new GroupReservationUpdated(properties);
                };

                /**
                 * Encodes the specified GroupReservationUpdated message. Does not implicitly {@link streem.api.events.GroupReservationUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {streem.api.events.IGroupReservationUpdated} m GroupReservationUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GroupReservationUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.previousGroupReservation != null && Object.hasOwnProperty.call(m, "previousGroupReservation"))
                        $root.streem.api.GroupReservation.encode(m.previousGroupReservation, w.uint32(10).fork()).ldelim();
                    if (m.updatedGroupReservation != null && Object.hasOwnProperty.call(m, "updatedGroupReservation"))
                        $root.streem.api.GroupReservation.encode(m.updatedGroupReservation, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GroupReservationUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.GroupReservationUpdated} GroupReservationUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GroupReservationUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.GroupReservationUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.previousGroupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.updatedGroupReservation = $root.streem.api.GroupReservation.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GroupReservationUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.GroupReservationUpdated} GroupReservationUpdated
                 */
                GroupReservationUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.GroupReservationUpdated)
                        return d;
                    var m = new $root.streem.api.events.GroupReservationUpdated();
                    if (d.previousGroupReservation != null) {
                        if (typeof d.previousGroupReservation !== "object")
                            throw TypeError(".streem.api.events.GroupReservationUpdated.previousGroupReservation: object expected");
                        m.previousGroupReservation = $root.streem.api.GroupReservation.fromObject(d.previousGroupReservation);
                    }
                    if (d.updatedGroupReservation != null) {
                        if (typeof d.updatedGroupReservation !== "object")
                            throw TypeError(".streem.api.events.GroupReservationUpdated.updatedGroupReservation: object expected");
                        m.updatedGroupReservation = $root.streem.api.GroupReservation.fromObject(d.updatedGroupReservation);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GroupReservationUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {streem.api.events.GroupReservationUpdated} m GroupReservationUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GroupReservationUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.previousGroupReservation = null;
                        d.updatedGroupReservation = null;
                    }
                    if (m.previousGroupReservation != null && m.hasOwnProperty("previousGroupReservation")) {
                        d.previousGroupReservation = $root.streem.api.GroupReservation.toObject(m.previousGroupReservation, o);
                    }
                    if (m.updatedGroupReservation != null && m.hasOwnProperty("updatedGroupReservation")) {
                        d.updatedGroupReservation = $root.streem.api.GroupReservation.toObject(m.updatedGroupReservation, o);
                    }
                    return d;
                };

                /**
                 * Converts this GroupReservationUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GroupReservationUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GroupReservationUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.events.GroupReservationUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GroupReservationUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.GroupReservationUpdated";
                };

                return GroupReservationUpdated;
            })();

            events.ArtifactCreated = (function() {

                /**
                 * Properties of an ArtifactCreated.
                 * @memberof streem.api.events
                 * @interface IArtifactCreated
                 * @property {streem.api.IRoom|null} [room] ArtifactCreated room
                 * @property {streem.api.IArtifact|null} [artifact] ArtifactCreated artifact
                 */

                /**
                 * Constructs a new ArtifactCreated.
                 * @memberof streem.api.events
                 * @classdesc Represents an ArtifactCreated.
                 * @implements IArtifactCreated
                 * @constructor
                 * @param {streem.api.events.IArtifactCreated=} [p] Properties to set
                 */
                function ArtifactCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactCreated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.ArtifactCreated
                 * @instance
                 */
                ArtifactCreated.prototype.room = null;

                /**
                 * ArtifactCreated artifact.
                 * @member {streem.api.IArtifact|null|undefined} artifact
                 * @memberof streem.api.events.ArtifactCreated
                 * @instance
                 */
                ArtifactCreated.prototype.artifact = null;

                /**
                 * Creates a new ArtifactCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {streem.api.events.IArtifactCreated=} [properties] Properties to set
                 * @returns {streem.api.events.ArtifactCreated} ArtifactCreated instance
                 */
                ArtifactCreated.create = function create(properties) {
                    return new ArtifactCreated(properties);
                };

                /**
                 * Encodes the specified ArtifactCreated message. Does not implicitly {@link streem.api.events.ArtifactCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {streem.api.events.IArtifactCreated} m ArtifactCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
                        $root.streem.api.Artifact.encode(m.artifact, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.ArtifactCreated} ArtifactCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.ArtifactCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.artifact = $root.streem.api.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.ArtifactCreated} ArtifactCreated
                 */
                ArtifactCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.ArtifactCreated)
                        return d;
                    var m = new $root.streem.api.events.ArtifactCreated();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.ArtifactCreated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.artifact != null) {
                        if (typeof d.artifact !== "object")
                            throw TypeError(".streem.api.events.ArtifactCreated.artifact: object expected");
                        m.artifact = $root.streem.api.Artifact.fromObject(d.artifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {streem.api.events.ArtifactCreated} m ArtifactCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.artifact = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.artifact != null && m.hasOwnProperty("artifact")) {
                        d.artifact = $root.streem.api.Artifact.toObject(m.artifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.ArtifactCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactCreated
                 * @function getTypeUrl
                 * @memberof streem.api.events.ArtifactCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.ArtifactCreated";
                };

                return ArtifactCreated;
            })();

            events.ArtifactUpdated = (function() {

                /**
                 * Properties of an ArtifactUpdated.
                 * @memberof streem.api.events
                 * @interface IArtifactUpdated
                 * @property {streem.api.IRoom|null} [room] ArtifactUpdated room
                 * @property {streem.api.IArtifact|null} [previousArtifact] ArtifactUpdated previousArtifact
                 * @property {streem.api.IArtifact|null} [updatedArtifact] ArtifactUpdated updatedArtifact
                 */

                /**
                 * Constructs a new ArtifactUpdated.
                 * @memberof streem.api.events
                 * @classdesc Represents an ArtifactUpdated.
                 * @implements IArtifactUpdated
                 * @constructor
                 * @param {streem.api.events.IArtifactUpdated=} [p] Properties to set
                 */
                function ArtifactUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactUpdated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.ArtifactUpdated
                 * @instance
                 */
                ArtifactUpdated.prototype.room = null;

                /**
                 * ArtifactUpdated previousArtifact.
                 * @member {streem.api.IArtifact|null|undefined} previousArtifact
                 * @memberof streem.api.events.ArtifactUpdated
                 * @instance
                 */
                ArtifactUpdated.prototype.previousArtifact = null;

                /**
                 * ArtifactUpdated updatedArtifact.
                 * @member {streem.api.IArtifact|null|undefined} updatedArtifact
                 * @memberof streem.api.events.ArtifactUpdated
                 * @instance
                 */
                ArtifactUpdated.prototype.updatedArtifact = null;

                /**
                 * Creates a new ArtifactUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {streem.api.events.IArtifactUpdated=} [properties] Properties to set
                 * @returns {streem.api.events.ArtifactUpdated} ArtifactUpdated instance
                 */
                ArtifactUpdated.create = function create(properties) {
                    return new ArtifactUpdated(properties);
                };

                /**
                 * Encodes the specified ArtifactUpdated message. Does not implicitly {@link streem.api.events.ArtifactUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {streem.api.events.IArtifactUpdated} m ArtifactUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.previousArtifact != null && Object.hasOwnProperty.call(m, "previousArtifact"))
                        $root.streem.api.Artifact.encode(m.previousArtifact, w.uint32(18).fork()).ldelim();
                    if (m.updatedArtifact != null && Object.hasOwnProperty.call(m, "updatedArtifact"))
                        $root.streem.api.Artifact.encode(m.updatedArtifact, w.uint32(26).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.ArtifactUpdated} ArtifactUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.ArtifactUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.previousArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.updatedArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.ArtifactUpdated} ArtifactUpdated
                 */
                ArtifactUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.ArtifactUpdated)
                        return d;
                    var m = new $root.streem.api.events.ArtifactUpdated();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.ArtifactUpdated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.previousArtifact != null) {
                        if (typeof d.previousArtifact !== "object")
                            throw TypeError(".streem.api.events.ArtifactUpdated.previousArtifact: object expected");
                        m.previousArtifact = $root.streem.api.Artifact.fromObject(d.previousArtifact);
                    }
                    if (d.updatedArtifact != null) {
                        if (typeof d.updatedArtifact !== "object")
                            throw TypeError(".streem.api.events.ArtifactUpdated.updatedArtifact: object expected");
                        m.updatedArtifact = $root.streem.api.Artifact.fromObject(d.updatedArtifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {streem.api.events.ArtifactUpdated} m ArtifactUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.previousArtifact = null;
                        d.updatedArtifact = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.previousArtifact != null && m.hasOwnProperty("previousArtifact")) {
                        d.previousArtifact = $root.streem.api.Artifact.toObject(m.previousArtifact, o);
                    }
                    if (m.updatedArtifact != null && m.hasOwnProperty("updatedArtifact")) {
                        d.updatedArtifact = $root.streem.api.Artifact.toObject(m.updatedArtifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.ArtifactUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.events.ArtifactUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.ArtifactUpdated";
                };

                return ArtifactUpdated;
            })();

            events.ArtifactDeleted = (function() {

                /**
                 * Properties of an ArtifactDeleted.
                 * @memberof streem.api.events
                 * @interface IArtifactDeleted
                 * @property {streem.api.IRoom|null} [room] ArtifactDeleted room
                 * @property {streem.api.IArtifact|null} [deletedArtifact] ArtifactDeleted deletedArtifact
                 */

                /**
                 * Constructs a new ArtifactDeleted.
                 * @memberof streem.api.events
                 * @classdesc Represents an ArtifactDeleted.
                 * @implements IArtifactDeleted
                 * @constructor
                 * @param {streem.api.events.IArtifactDeleted=} [p] Properties to set
                 */
                function ArtifactDeleted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ArtifactDeleted room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.ArtifactDeleted
                 * @instance
                 */
                ArtifactDeleted.prototype.room = null;

                /**
                 * ArtifactDeleted deletedArtifact.
                 * @member {streem.api.IArtifact|null|undefined} deletedArtifact
                 * @memberof streem.api.events.ArtifactDeleted
                 * @instance
                 */
                ArtifactDeleted.prototype.deletedArtifact = null;

                /**
                 * Creates a new ArtifactDeleted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {streem.api.events.IArtifactDeleted=} [properties] Properties to set
                 * @returns {streem.api.events.ArtifactDeleted} ArtifactDeleted instance
                 */
                ArtifactDeleted.create = function create(properties) {
                    return new ArtifactDeleted(properties);
                };

                /**
                 * Encodes the specified ArtifactDeleted message. Does not implicitly {@link streem.api.events.ArtifactDeleted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {streem.api.events.IArtifactDeleted} m ArtifactDeleted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ArtifactDeleted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.deletedArtifact != null && Object.hasOwnProperty.call(m, "deletedArtifact"))
                        $root.streem.api.Artifact.encode(m.deletedArtifact, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an ArtifactDeleted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.ArtifactDeleted} ArtifactDeleted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ArtifactDeleted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.ArtifactDeleted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.deletedArtifact = $root.streem.api.Artifact.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ArtifactDeleted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.ArtifactDeleted} ArtifactDeleted
                 */
                ArtifactDeleted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.ArtifactDeleted)
                        return d;
                    var m = new $root.streem.api.events.ArtifactDeleted();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.ArtifactDeleted.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.deletedArtifact != null) {
                        if (typeof d.deletedArtifact !== "object")
                            throw TypeError(".streem.api.events.ArtifactDeleted.deletedArtifact: object expected");
                        m.deletedArtifact = $root.streem.api.Artifact.fromObject(d.deletedArtifact);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ArtifactDeleted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {streem.api.events.ArtifactDeleted} m ArtifactDeleted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ArtifactDeleted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.deletedArtifact = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.deletedArtifact != null && m.hasOwnProperty("deletedArtifact")) {
                        d.deletedArtifact = $root.streem.api.Artifact.toObject(m.deletedArtifact, o);
                    }
                    return d;
                };

                /**
                 * Converts this ArtifactDeleted to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.ArtifactDeleted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ArtifactDeleted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ArtifactDeleted
                 * @function getTypeUrl
                 * @memberof streem.api.events.ArtifactDeleted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ArtifactDeleted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.ArtifactDeleted";
                };

                return ArtifactDeleted;
            })();

            events.RoomNoteCreated = (function() {

                /**
                 * Properties of a RoomNoteCreated.
                 * @memberof streem.api.events
                 * @interface IRoomNoteCreated
                 * @property {streem.api.IRoom|null} [room] RoomNoteCreated room
                 * @property {streem.api.INote|null} [roomNote] RoomNoteCreated roomNote
                 */

                /**
                 * Constructs a new RoomNoteCreated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomNoteCreated.
                 * @implements IRoomNoteCreated
                 * @constructor
                 * @param {streem.api.events.IRoomNoteCreated=} [p] Properties to set
                 */
                function RoomNoteCreated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomNoteCreated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomNoteCreated
                 * @instance
                 */
                RoomNoteCreated.prototype.room = null;

                /**
                 * RoomNoteCreated roomNote.
                 * @member {streem.api.INote|null|undefined} roomNote
                 * @memberof streem.api.events.RoomNoteCreated
                 * @instance
                 */
                RoomNoteCreated.prototype.roomNote = null;

                /**
                 * Creates a new RoomNoteCreated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {streem.api.events.IRoomNoteCreated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomNoteCreated} RoomNoteCreated instance
                 */
                RoomNoteCreated.create = function create(properties) {
                    return new RoomNoteCreated(properties);
                };

                /**
                 * Encodes the specified RoomNoteCreated message. Does not implicitly {@link streem.api.events.RoomNoteCreated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {streem.api.events.IRoomNoteCreated} m RoomNoteCreated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomNoteCreated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.roomNote != null && Object.hasOwnProperty.call(m, "roomNote"))
                        $root.streem.api.Note.encode(m.roomNote, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomNoteCreated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomNoteCreated} RoomNoteCreated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomNoteCreated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomNoteCreated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.roomNote = $root.streem.api.Note.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomNoteCreated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomNoteCreated} RoomNoteCreated
                 */
                RoomNoteCreated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomNoteCreated)
                        return d;
                    var m = new $root.streem.api.events.RoomNoteCreated();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomNoteCreated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.roomNote != null) {
                        if (typeof d.roomNote !== "object")
                            throw TypeError(".streem.api.events.RoomNoteCreated.roomNote: object expected");
                        m.roomNote = $root.streem.api.Note.fromObject(d.roomNote);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomNoteCreated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {streem.api.events.RoomNoteCreated} m RoomNoteCreated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomNoteCreated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.roomNote = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.roomNote != null && m.hasOwnProperty("roomNote")) {
                        d.roomNote = $root.streem.api.Note.toObject(m.roomNote, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomNoteCreated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomNoteCreated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomNoteCreated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomNoteCreated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomNoteCreated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomNoteCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomNoteCreated";
                };

                return RoomNoteCreated;
            })();

            events.RoomNoteUpdated = (function() {

                /**
                 * Properties of a RoomNoteUpdated.
                 * @memberof streem.api.events
                 * @interface IRoomNoteUpdated
                 * @property {streem.api.IRoom|null} [room] RoomNoteUpdated room
                 * @property {streem.api.INote|null} [previousRoomNote] RoomNoteUpdated previousRoomNote
                 * @property {streem.api.INote|null} [updatedRoomNote] RoomNoteUpdated updatedRoomNote
                 */

                /**
                 * Constructs a new RoomNoteUpdated.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomNoteUpdated.
                 * @implements IRoomNoteUpdated
                 * @constructor
                 * @param {streem.api.events.IRoomNoteUpdated=} [p] Properties to set
                 */
                function RoomNoteUpdated(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomNoteUpdated room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @instance
                 */
                RoomNoteUpdated.prototype.room = null;

                /**
                 * RoomNoteUpdated previousRoomNote.
                 * @member {streem.api.INote|null|undefined} previousRoomNote
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @instance
                 */
                RoomNoteUpdated.prototype.previousRoomNote = null;

                /**
                 * RoomNoteUpdated updatedRoomNote.
                 * @member {streem.api.INote|null|undefined} updatedRoomNote
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @instance
                 */
                RoomNoteUpdated.prototype.updatedRoomNote = null;

                /**
                 * Creates a new RoomNoteUpdated instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {streem.api.events.IRoomNoteUpdated=} [properties] Properties to set
                 * @returns {streem.api.events.RoomNoteUpdated} RoomNoteUpdated instance
                 */
                RoomNoteUpdated.create = function create(properties) {
                    return new RoomNoteUpdated(properties);
                };

                /**
                 * Encodes the specified RoomNoteUpdated message. Does not implicitly {@link streem.api.events.RoomNoteUpdated.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {streem.api.events.IRoomNoteUpdated} m RoomNoteUpdated message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomNoteUpdated.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.previousRoomNote != null && Object.hasOwnProperty.call(m, "previousRoomNote"))
                        $root.streem.api.Note.encode(m.previousRoomNote, w.uint32(18).fork()).ldelim();
                    if (m.updatedRoomNote != null && Object.hasOwnProperty.call(m, "updatedRoomNote"))
                        $root.streem.api.Note.encode(m.updatedRoomNote, w.uint32(26).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomNoteUpdated message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomNoteUpdated} RoomNoteUpdated
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomNoteUpdated.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomNoteUpdated();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.previousRoomNote = $root.streem.api.Note.decode(r, r.uint32());
                                break;
                            }
                        case 3: {
                                m.updatedRoomNote = $root.streem.api.Note.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomNoteUpdated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomNoteUpdated} RoomNoteUpdated
                 */
                RoomNoteUpdated.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomNoteUpdated)
                        return d;
                    var m = new $root.streem.api.events.RoomNoteUpdated();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomNoteUpdated.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.previousRoomNote != null) {
                        if (typeof d.previousRoomNote !== "object")
                            throw TypeError(".streem.api.events.RoomNoteUpdated.previousRoomNote: object expected");
                        m.previousRoomNote = $root.streem.api.Note.fromObject(d.previousRoomNote);
                    }
                    if (d.updatedRoomNote != null) {
                        if (typeof d.updatedRoomNote !== "object")
                            throw TypeError(".streem.api.events.RoomNoteUpdated.updatedRoomNote: object expected");
                        m.updatedRoomNote = $root.streem.api.Note.fromObject(d.updatedRoomNote);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomNoteUpdated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {streem.api.events.RoomNoteUpdated} m RoomNoteUpdated
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomNoteUpdated.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.previousRoomNote = null;
                        d.updatedRoomNote = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.previousRoomNote != null && m.hasOwnProperty("previousRoomNote")) {
                        d.previousRoomNote = $root.streem.api.Note.toObject(m.previousRoomNote, o);
                    }
                    if (m.updatedRoomNote != null && m.hasOwnProperty("updatedRoomNote")) {
                        d.updatedRoomNote = $root.streem.api.Note.toObject(m.updatedRoomNote, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomNoteUpdated to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomNoteUpdated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomNoteUpdated
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomNoteUpdated
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomNoteUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomNoteUpdated";
                };

                return RoomNoteUpdated;
            })();

            events.RoomNoteDeleted = (function() {

                /**
                 * Properties of a RoomNoteDeleted.
                 * @memberof streem.api.events
                 * @interface IRoomNoteDeleted
                 * @property {streem.api.IRoom|null} [room] RoomNoteDeleted room
                 * @property {streem.api.INote|null} [deletedRoomNote] RoomNoteDeleted deletedRoomNote
                 */

                /**
                 * Constructs a new RoomNoteDeleted.
                 * @memberof streem.api.events
                 * @classdesc Represents a RoomNoteDeleted.
                 * @implements IRoomNoteDeleted
                 * @constructor
                 * @param {streem.api.events.IRoomNoteDeleted=} [p] Properties to set
                 */
                function RoomNoteDeleted(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RoomNoteDeleted room.
                 * @member {streem.api.IRoom|null|undefined} room
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @instance
                 */
                RoomNoteDeleted.prototype.room = null;

                /**
                 * RoomNoteDeleted deletedRoomNote.
                 * @member {streem.api.INote|null|undefined} deletedRoomNote
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @instance
                 */
                RoomNoteDeleted.prototype.deletedRoomNote = null;

                /**
                 * Creates a new RoomNoteDeleted instance using the specified properties.
                 * @function create
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {streem.api.events.IRoomNoteDeleted=} [properties] Properties to set
                 * @returns {streem.api.events.RoomNoteDeleted} RoomNoteDeleted instance
                 */
                RoomNoteDeleted.create = function create(properties) {
                    return new RoomNoteDeleted(properties);
                };

                /**
                 * Encodes the specified RoomNoteDeleted message. Does not implicitly {@link streem.api.events.RoomNoteDeleted.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {streem.api.events.IRoomNoteDeleted} m RoomNoteDeleted message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RoomNoteDeleted.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.room != null && Object.hasOwnProperty.call(m, "room"))
                        $root.streem.api.Room.encode(m.room, w.uint32(10).fork()).ldelim();
                    if (m.deletedRoomNote != null && Object.hasOwnProperty.call(m, "deletedRoomNote"))
                        $root.streem.api.Note.encode(m.deletedRoomNote, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a RoomNoteDeleted message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.events.RoomNoteDeleted} RoomNoteDeleted
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RoomNoteDeleted.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.events.RoomNoteDeleted();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.room = $root.streem.api.Room.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.deletedRoomNote = $root.streem.api.Note.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RoomNoteDeleted message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.events.RoomNoteDeleted} RoomNoteDeleted
                 */
                RoomNoteDeleted.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.events.RoomNoteDeleted)
                        return d;
                    var m = new $root.streem.api.events.RoomNoteDeleted();
                    if (d.room != null) {
                        if (typeof d.room !== "object")
                            throw TypeError(".streem.api.events.RoomNoteDeleted.room: object expected");
                        m.room = $root.streem.api.Room.fromObject(d.room);
                    }
                    if (d.deletedRoomNote != null) {
                        if (typeof d.deletedRoomNote !== "object")
                            throw TypeError(".streem.api.events.RoomNoteDeleted.deletedRoomNote: object expected");
                        m.deletedRoomNote = $root.streem.api.Note.fromObject(d.deletedRoomNote);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RoomNoteDeleted message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {streem.api.events.RoomNoteDeleted} m RoomNoteDeleted
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoomNoteDeleted.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.room = null;
                        d.deletedRoomNote = null;
                    }
                    if (m.room != null && m.hasOwnProperty("room")) {
                        d.room = $root.streem.api.Room.toObject(m.room, o);
                    }
                    if (m.deletedRoomNote != null && m.hasOwnProperty("deletedRoomNote")) {
                        d.deletedRoomNote = $root.streem.api.Note.toObject(m.deletedRoomNote, o);
                    }
                    return d;
                };

                /**
                 * Converts this RoomNoteDeleted to JSON.
                 * @function toJSON
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoomNoteDeleted.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RoomNoteDeleted
                 * @function getTypeUrl
                 * @memberof streem.api.events.RoomNoteDeleted
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RoomNoteDeleted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.events.RoomNoteDeleted";
                };

                return RoomNoteDeleted;
            })();

            return events;
        })();

        api.SsoProviders = (function() {

            /**
             * Constructs a new SsoProviders service.
             * @memberof streem.api
             * @classdesc Represents a SsoProviders
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function SsoProviders(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (SsoProviders.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SsoProviders;

            /**
             * Creates new SsoProviders service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.SsoProviders
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {SsoProviders} RPC service. Useful where requests and/or responses are streamed.
             */
            SsoProviders.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.SsoProviders#listSsoProviders}.
             * @memberof streem.api.SsoProviders
             * @typedef ListSsoProvidersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListSsoProvidersResponse} [response] ListSsoProvidersResponse
             */

            /**
             * Calls ListSsoProviders.
             * @function listSsoProviders
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IListSsoProvidersRequest} request ListSsoProvidersRequest message or plain object
             * @param {streem.api.SsoProviders.ListSsoProvidersCallback} callback Node-style callback called with the error, if any, and ListSsoProvidersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.listSsoProviders = function listSsoProviders(request, callback) {
                return this.rpcCall(listSsoProviders, $root.streem.api.ListSsoProvidersRequest, $root.streem.api.ListSsoProvidersResponse, request, callback);
            }, "name", { value: "ListSsoProviders" });

            /**
             * Calls ListSsoProviders.
             * @function listSsoProviders
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IListSsoProvidersRequest} request ListSsoProvidersRequest message or plain object
             * @returns {Promise<streem.api.ListSsoProvidersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#saveSsoProvider}.
             * @memberof streem.api.SsoProviders
             * @typedef SaveSsoProviderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveSsoProviderResponse} [response] SaveSsoProviderResponse
             */

            /**
             * Calls SaveSsoProvider.
             * @function saveSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.ISaveSsoProviderRequest} request SaveSsoProviderRequest message or plain object
             * @param {streem.api.SsoProviders.SaveSsoProviderCallback} callback Node-style callback called with the error, if any, and SaveSsoProviderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.saveSsoProvider = function saveSsoProvider(request, callback) {
                return this.rpcCall(saveSsoProvider, $root.streem.api.SaveSsoProviderRequest, $root.streem.api.SaveSsoProviderResponse, request, callback);
            }, "name", { value: "SaveSsoProvider" });

            /**
             * Calls SaveSsoProvider.
             * @function saveSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.ISaveSsoProviderRequest} request SaveSsoProviderRequest message or plain object
             * @returns {Promise<streem.api.SaveSsoProviderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#getSsoProvider}.
             * @memberof streem.api.SsoProviders
             * @typedef GetSsoProviderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetSsoProviderResponse} [response] GetSsoProviderResponse
             */

            /**
             * Calls GetSsoProvider.
             * @function getSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IGetSsoProviderRequest} request GetSsoProviderRequest message or plain object
             * @param {streem.api.SsoProviders.GetSsoProviderCallback} callback Node-style callback called with the error, if any, and GetSsoProviderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.getSsoProvider = function getSsoProvider(request, callback) {
                return this.rpcCall(getSsoProvider, $root.streem.api.GetSsoProviderRequest, $root.streem.api.GetSsoProviderResponse, request, callback);
            }, "name", { value: "GetSsoProvider" });

            /**
             * Calls GetSsoProvider.
             * @function getSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IGetSsoProviderRequest} request GetSsoProviderRequest message or plain object
             * @returns {Promise<streem.api.GetSsoProviderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#deleteSsoProvider}.
             * @memberof streem.api.SsoProviders
             * @typedef DeleteSsoProviderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteSsoProvider.
             * @function deleteSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IDeleteSsoProviderRequest} request DeleteSsoProviderRequest message or plain object
             * @param {streem.api.SsoProviders.DeleteSsoProviderCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.deleteSsoProvider = function deleteSsoProvider(request, callback) {
                return this.rpcCall(deleteSsoProvider, $root.streem.api.DeleteSsoProviderRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteSsoProvider" });

            /**
             * Calls DeleteSsoProvider.
             * @function deleteSsoProvider
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IDeleteSsoProviderRequest} request DeleteSsoProviderRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#listSsoProviderCompanyMappings}.
             * @memberof streem.api.SsoProviders
             * @typedef ListSsoProviderCompanyMappingsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListSsoProviderCompanyMappingsResponse} [response] ListSsoProviderCompanyMappingsResponse
             */

            /**
             * Calls ListSsoProviderCompanyMappings.
             * @function listSsoProviderCompanyMappings
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IListSsoProviderCompanyMappingsRequest} request ListSsoProviderCompanyMappingsRequest message or plain object
             * @param {streem.api.SsoProviders.ListSsoProviderCompanyMappingsCallback} callback Node-style callback called with the error, if any, and ListSsoProviderCompanyMappingsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.listSsoProviderCompanyMappings = function listSsoProviderCompanyMappings(request, callback) {
                return this.rpcCall(listSsoProviderCompanyMappings, $root.streem.api.ListSsoProviderCompanyMappingsRequest, $root.streem.api.ListSsoProviderCompanyMappingsResponse, request, callback);
            }, "name", { value: "ListSsoProviderCompanyMappings" });

            /**
             * Calls ListSsoProviderCompanyMappings.
             * @function listSsoProviderCompanyMappings
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IListSsoProviderCompanyMappingsRequest} request ListSsoProviderCompanyMappingsRequest message or plain object
             * @returns {Promise<streem.api.ListSsoProviderCompanyMappingsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#getSsoProviderCompanyMapping}.
             * @memberof streem.api.SsoProviders
             * @typedef GetSsoProviderCompanyMappingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetSsoProviderCompanyMappingResponse} [response] GetSsoProviderCompanyMappingResponse
             */

            /**
             * Calls GetSsoProviderCompanyMapping.
             * @function getSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IGetSsoProviderCompanyMappingRequest} request GetSsoProviderCompanyMappingRequest message or plain object
             * @param {streem.api.SsoProviders.GetSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and GetSsoProviderCompanyMappingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.getSsoProviderCompanyMapping = function getSsoProviderCompanyMapping(request, callback) {
                return this.rpcCall(getSsoProviderCompanyMapping, $root.streem.api.GetSsoProviderCompanyMappingRequest, $root.streem.api.GetSsoProviderCompanyMappingResponse, request, callback);
            }, "name", { value: "GetSsoProviderCompanyMapping" });

            /**
             * Calls GetSsoProviderCompanyMapping.
             * @function getSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IGetSsoProviderCompanyMappingRequest} request GetSsoProviderCompanyMappingRequest message or plain object
             * @returns {Promise<streem.api.GetSsoProviderCompanyMappingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#updateSsoProviderCompanyMapping}.
             * @memberof streem.api.SsoProviders
             * @typedef UpdateSsoProviderCompanyMappingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateSsoProviderCompanyMappingResponse} [response] UpdateSsoProviderCompanyMappingResponse
             */

            /**
             * Calls UpdateSsoProviderCompanyMapping.
             * @function updateSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IUpdateSsoProviderCompanyMappingRequest} request UpdateSsoProviderCompanyMappingRequest message or plain object
             * @param {streem.api.SsoProviders.UpdateSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and UpdateSsoProviderCompanyMappingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.updateSsoProviderCompanyMapping = function updateSsoProviderCompanyMapping(request, callback) {
                return this.rpcCall(updateSsoProviderCompanyMapping, $root.streem.api.UpdateSsoProviderCompanyMappingRequest, $root.streem.api.UpdateSsoProviderCompanyMappingResponse, request, callback);
            }, "name", { value: "UpdateSsoProviderCompanyMapping" });

            /**
             * Calls UpdateSsoProviderCompanyMapping.
             * @function updateSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IUpdateSsoProviderCompanyMappingRequest} request UpdateSsoProviderCompanyMappingRequest message or plain object
             * @returns {Promise<streem.api.UpdateSsoProviderCompanyMappingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.SsoProviders#deleteSsoProviderCompanyMapping}.
             * @memberof streem.api.SsoProviders
             * @typedef DeleteSsoProviderCompanyMappingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteSsoProviderCompanyMapping.
             * @function deleteSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IDeleteSsoProviderCompanyMappingRequest} request DeleteSsoProviderCompanyMappingRequest message or plain object
             * @param {streem.api.SsoProviders.DeleteSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SsoProviders.prototype.deleteSsoProviderCompanyMapping = function deleteSsoProviderCompanyMapping(request, callback) {
                return this.rpcCall(deleteSsoProviderCompanyMapping, $root.streem.api.DeleteSsoProviderCompanyMappingRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteSsoProviderCompanyMapping" });

            /**
             * Calls DeleteSsoProviderCompanyMapping.
             * @function deleteSsoProviderCompanyMapping
             * @memberof streem.api.SsoProviders
             * @instance
             * @param {streem.api.IDeleteSsoProviderCompanyMappingRequest} request DeleteSsoProviderCompanyMappingRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return SsoProviders;
        })();

        api.SaveSsoProviderRequest = (function() {

            /**
             * Properties of a SaveSsoProviderRequest.
             * @memberof streem.api
             * @interface ISaveSsoProviderRequest
             * @property {string|null} [ssoProviderCode] SaveSsoProviderRequest ssoProviderCode
             * @property {streem.api.ISsoProvider|null} [ssoProvider] SaveSsoProviderRequest ssoProvider
             */

            /**
             * Constructs a new SaveSsoProviderRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveSsoProviderRequest.
             * @implements ISaveSsoProviderRequest
             * @constructor
             * @param {streem.api.ISaveSsoProviderRequest=} [p] Properties to set
             */
            function SaveSsoProviderRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveSsoProviderRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.SaveSsoProviderRequest
             * @instance
             */
            SaveSsoProviderRequest.prototype.ssoProviderCode = "";

            /**
             * SaveSsoProviderRequest ssoProvider.
             * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
             * @memberof streem.api.SaveSsoProviderRequest
             * @instance
             */
            SaveSsoProviderRequest.prototype.ssoProvider = null;

            /**
             * Creates a new SaveSsoProviderRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {streem.api.ISaveSsoProviderRequest=} [properties] Properties to set
             * @returns {streem.api.SaveSsoProviderRequest} SaveSsoProviderRequest instance
             */
            SaveSsoProviderRequest.create = function create(properties) {
                return new SaveSsoProviderRequest(properties);
            };

            /**
             * Encodes the specified SaveSsoProviderRequest message. Does not implicitly {@link streem.api.SaveSsoProviderRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {streem.api.ISaveSsoProviderRequest} m SaveSsoProviderRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveSsoProviderRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                    $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveSsoProviderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveSsoProviderRequest} SaveSsoProviderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveSsoProviderRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveSsoProviderRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveSsoProviderRequest} SaveSsoProviderRequest
             */
            SaveSsoProviderRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveSsoProviderRequest)
                    return d;
                var m = new $root.streem.api.SaveSsoProviderRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.ssoProvider != null) {
                    if (typeof d.ssoProvider !== "object")
                        throw TypeError(".streem.api.SaveSsoProviderRequest.ssoProvider: object expected");
                    m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveSsoProviderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {streem.api.SaveSsoProviderRequest} m SaveSsoProviderRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveSsoProviderRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.ssoProvider = null;
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                    d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                }
                return d;
            };

            /**
             * Converts this SaveSsoProviderRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveSsoProviderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveSsoProviderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveSsoProviderRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveSsoProviderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveSsoProviderRequest";
            };

            return SaveSsoProviderRequest;
        })();

        api.SaveSsoProviderResponse = (function() {

            /**
             * Properties of a SaveSsoProviderResponse.
             * @memberof streem.api
             * @interface ISaveSsoProviderResponse
             * @property {streem.api.ISsoProvider|null} [ssoProvider] SaveSsoProviderResponse ssoProvider
             */

            /**
             * Constructs a new SaveSsoProviderResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveSsoProviderResponse.
             * @implements ISaveSsoProviderResponse
             * @constructor
             * @param {streem.api.ISaveSsoProviderResponse=} [p] Properties to set
             */
            function SaveSsoProviderResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveSsoProviderResponse ssoProvider.
             * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
             * @memberof streem.api.SaveSsoProviderResponse
             * @instance
             */
            SaveSsoProviderResponse.prototype.ssoProvider = null;

            /**
             * Creates a new SaveSsoProviderResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {streem.api.ISaveSsoProviderResponse=} [properties] Properties to set
             * @returns {streem.api.SaveSsoProviderResponse} SaveSsoProviderResponse instance
             */
            SaveSsoProviderResponse.create = function create(properties) {
                return new SaveSsoProviderResponse(properties);
            };

            /**
             * Encodes the specified SaveSsoProviderResponse message. Does not implicitly {@link streem.api.SaveSsoProviderResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {streem.api.ISaveSsoProviderResponse} m SaveSsoProviderResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveSsoProviderResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                    $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveSsoProviderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveSsoProviderResponse} SaveSsoProviderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveSsoProviderResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveSsoProviderResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveSsoProviderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveSsoProviderResponse} SaveSsoProviderResponse
             */
            SaveSsoProviderResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveSsoProviderResponse)
                    return d;
                var m = new $root.streem.api.SaveSsoProviderResponse();
                if (d.ssoProvider != null) {
                    if (typeof d.ssoProvider !== "object")
                        throw TypeError(".streem.api.SaveSsoProviderResponse.ssoProvider: object expected");
                    m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveSsoProviderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {streem.api.SaveSsoProviderResponse} m SaveSsoProviderResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveSsoProviderResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProvider = null;
                }
                if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                    d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                }
                return d;
            };

            /**
             * Converts this SaveSsoProviderResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveSsoProviderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveSsoProviderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveSsoProviderResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveSsoProviderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveSsoProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveSsoProviderResponse";
            };

            return SaveSsoProviderResponse;
        })();

        api.ListSsoProvidersRequest = (function() {

            /**
             * Properties of a ListSsoProvidersRequest.
             * @memberof streem.api
             * @interface IListSsoProvidersRequest
             * @property {string|null} [pageToken] ListSsoProvidersRequest pageToken
             * @property {number|null} [pageSize] ListSsoProvidersRequest pageSize
             */

            /**
             * Constructs a new ListSsoProvidersRequest.
             * @memberof streem.api
             * @classdesc Represents a ListSsoProvidersRequest.
             * @implements IListSsoProvidersRequest
             * @constructor
             * @param {streem.api.IListSsoProvidersRequest=} [p] Properties to set
             */
            function ListSsoProvidersRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSsoProvidersRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListSsoProvidersRequest
             * @instance
             */
            ListSsoProvidersRequest.prototype.pageToken = "";

            /**
             * ListSsoProvidersRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListSsoProvidersRequest
             * @instance
             */
            ListSsoProvidersRequest.prototype.pageSize = 0;

            /**
             * Creates a new ListSsoProvidersRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {streem.api.IListSsoProvidersRequest=} [properties] Properties to set
             * @returns {streem.api.ListSsoProvidersRequest} ListSsoProvidersRequest instance
             */
            ListSsoProvidersRequest.create = function create(properties) {
                return new ListSsoProvidersRequest(properties);
            };

            /**
             * Encodes the specified ListSsoProvidersRequest message. Does not implicitly {@link streem.api.ListSsoProvidersRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {streem.api.IListSsoProvidersRequest} m ListSsoProvidersRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSsoProvidersRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(10).string(m.pageToken);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                return w;
            };

            /**
             * Decodes a ListSsoProvidersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSsoProvidersRequest} ListSsoProvidersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSsoProvidersRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSsoProvidersRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSsoProvidersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSsoProvidersRequest} ListSsoProvidersRequest
             */
            ListSsoProvidersRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSsoProvidersRequest)
                    return d;
                var m = new $root.streem.api.ListSsoProvidersRequest();
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSsoProvidersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {streem.api.ListSsoProvidersRequest} m ListSsoProvidersRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSsoProvidersRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageToken = "";
                    d.pageSize = 0;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                return d;
            };

            /**
             * Converts this ListSsoProvidersRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListSsoProvidersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSsoProvidersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSsoProvidersRequest
             * @function getTypeUrl
             * @memberof streem.api.ListSsoProvidersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSsoProvidersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSsoProvidersRequest";
            };

            return ListSsoProvidersRequest;
        })();

        api.ListSsoProvidersResponse = (function() {

            /**
             * Properties of a ListSsoProvidersResponse.
             * @memberof streem.api
             * @interface IListSsoProvidersResponse
             * @property {Array.<streem.api.ISsoProvider>|null} [ssoProviders] ListSsoProvidersResponse ssoProviders
             * @property {string|null} [nextPageToken] ListSsoProvidersResponse nextPageToken
             * @property {number|null} [totalSize] ListSsoProvidersResponse totalSize
             */

            /**
             * Constructs a new ListSsoProvidersResponse.
             * @memberof streem.api
             * @classdesc Represents a ListSsoProvidersResponse.
             * @implements IListSsoProvidersResponse
             * @constructor
             * @param {streem.api.IListSsoProvidersResponse=} [p] Properties to set
             */
            function ListSsoProvidersResponse(p) {
                this.ssoProviders = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSsoProvidersResponse ssoProviders.
             * @member {Array.<streem.api.ISsoProvider>} ssoProviders
             * @memberof streem.api.ListSsoProvidersResponse
             * @instance
             */
            ListSsoProvidersResponse.prototype.ssoProviders = $util.emptyArray;

            /**
             * ListSsoProvidersResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListSsoProvidersResponse
             * @instance
             */
            ListSsoProvidersResponse.prototype.nextPageToken = "";

            /**
             * ListSsoProvidersResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListSsoProvidersResponse
             * @instance
             */
            ListSsoProvidersResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListSsoProvidersResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {streem.api.IListSsoProvidersResponse=} [properties] Properties to set
             * @returns {streem.api.ListSsoProvidersResponse} ListSsoProvidersResponse instance
             */
            ListSsoProvidersResponse.create = function create(properties) {
                return new ListSsoProvidersResponse(properties);
            };

            /**
             * Encodes the specified ListSsoProvidersResponse message. Does not implicitly {@link streem.api.ListSsoProvidersResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {streem.api.IListSsoProvidersResponse} m ListSsoProvidersResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSsoProvidersResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviders != null && m.ssoProviders.length) {
                    for (var i = 0; i < m.ssoProviders.length; ++i)
                        $root.streem.api.SsoProvider.encode(m.ssoProviders[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListSsoProvidersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSsoProvidersResponse} ListSsoProvidersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSsoProvidersResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSsoProvidersResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.ssoProviders && m.ssoProviders.length))
                                m.ssoProviders = [];
                            m.ssoProviders.push($root.streem.api.SsoProvider.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSsoProvidersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSsoProvidersResponse} ListSsoProvidersResponse
             */
            ListSsoProvidersResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSsoProvidersResponse)
                    return d;
                var m = new $root.streem.api.ListSsoProvidersResponse();
                if (d.ssoProviders) {
                    if (!Array.isArray(d.ssoProviders))
                        throw TypeError(".streem.api.ListSsoProvidersResponse.ssoProviders: array expected");
                    m.ssoProviders = [];
                    for (var i = 0; i < d.ssoProviders.length; ++i) {
                        if (typeof d.ssoProviders[i] !== "object")
                            throw TypeError(".streem.api.ListSsoProvidersResponse.ssoProviders: object expected");
                        m.ssoProviders[i] = $root.streem.api.SsoProvider.fromObject(d.ssoProviders[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSsoProvidersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {streem.api.ListSsoProvidersResponse} m ListSsoProvidersResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSsoProvidersResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.ssoProviders = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.ssoProviders && m.ssoProviders.length) {
                    d.ssoProviders = [];
                    for (var j = 0; j < m.ssoProviders.length; ++j) {
                        d.ssoProviders[j] = $root.streem.api.SsoProvider.toObject(m.ssoProviders[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListSsoProvidersResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListSsoProvidersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSsoProvidersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSsoProvidersResponse
             * @function getTypeUrl
             * @memberof streem.api.ListSsoProvidersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSsoProvidersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSsoProvidersResponse";
            };

            return ListSsoProvidersResponse;
        })();

        api.GetSsoProviderRequest = (function() {

            /**
             * Properties of a GetSsoProviderRequest.
             * @memberof streem.api
             * @interface IGetSsoProviderRequest
             * @property {string|null} [ssoProviderCode] GetSsoProviderRequest ssoProviderCode
             */

            /**
             * Constructs a new GetSsoProviderRequest.
             * @memberof streem.api
             * @classdesc Represents a GetSsoProviderRequest.
             * @implements IGetSsoProviderRequest
             * @constructor
             * @param {streem.api.IGetSsoProviderRequest=} [p] Properties to set
             */
            function GetSsoProviderRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSsoProviderRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.GetSsoProviderRequest
             * @instance
             */
            GetSsoProviderRequest.prototype.ssoProviderCode = "";

            /**
             * Creates a new GetSsoProviderRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {streem.api.IGetSsoProviderRequest=} [properties] Properties to set
             * @returns {streem.api.GetSsoProviderRequest} GetSsoProviderRequest instance
             */
            GetSsoProviderRequest.create = function create(properties) {
                return new GetSsoProviderRequest(properties);
            };

            /**
             * Encodes the specified GetSsoProviderRequest message. Does not implicitly {@link streem.api.GetSsoProviderRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {streem.api.IGetSsoProviderRequest} m GetSsoProviderRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSsoProviderRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                return w;
            };

            /**
             * Decodes a GetSsoProviderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSsoProviderRequest} GetSsoProviderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSsoProviderRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSsoProviderRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSsoProviderRequest} GetSsoProviderRequest
             */
            GetSsoProviderRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSsoProviderRequest)
                    return d;
                var m = new $root.streem.api.GetSsoProviderRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSsoProviderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {streem.api.GetSsoProviderRequest} m GetSsoProviderRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSsoProviderRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                return d;
            };

            /**
             * Converts this GetSsoProviderRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetSsoProviderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSsoProviderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSsoProviderRequest
             * @function getTypeUrl
             * @memberof streem.api.GetSsoProviderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSsoProviderRequest";
            };

            return GetSsoProviderRequest;
        })();

        api.GetSsoProviderResponse = (function() {

            /**
             * Properties of a GetSsoProviderResponse.
             * @memberof streem.api
             * @interface IGetSsoProviderResponse
             * @property {streem.api.ISsoProvider|null} [ssoProvider] GetSsoProviderResponse ssoProvider
             */

            /**
             * Constructs a new GetSsoProviderResponse.
             * @memberof streem.api
             * @classdesc Represents a GetSsoProviderResponse.
             * @implements IGetSsoProviderResponse
             * @constructor
             * @param {streem.api.IGetSsoProviderResponse=} [p] Properties to set
             */
            function GetSsoProviderResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSsoProviderResponse ssoProvider.
             * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
             * @memberof streem.api.GetSsoProviderResponse
             * @instance
             */
            GetSsoProviderResponse.prototype.ssoProvider = null;

            /**
             * Creates a new GetSsoProviderResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {streem.api.IGetSsoProviderResponse=} [properties] Properties to set
             * @returns {streem.api.GetSsoProviderResponse} GetSsoProviderResponse instance
             */
            GetSsoProviderResponse.create = function create(properties) {
                return new GetSsoProviderResponse(properties);
            };

            /**
             * Encodes the specified GetSsoProviderResponse message. Does not implicitly {@link streem.api.GetSsoProviderResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {streem.api.IGetSsoProviderResponse} m GetSsoProviderResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSsoProviderResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                    $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetSsoProviderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSsoProviderResponse} GetSsoProviderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSsoProviderResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSsoProviderResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSsoProviderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSsoProviderResponse} GetSsoProviderResponse
             */
            GetSsoProviderResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSsoProviderResponse)
                    return d;
                var m = new $root.streem.api.GetSsoProviderResponse();
                if (d.ssoProvider != null) {
                    if (typeof d.ssoProvider !== "object")
                        throw TypeError(".streem.api.GetSsoProviderResponse.ssoProvider: object expected");
                    m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSsoProviderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {streem.api.GetSsoProviderResponse} m GetSsoProviderResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSsoProviderResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProvider = null;
                }
                if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                    d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                }
                return d;
            };

            /**
             * Converts this GetSsoProviderResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetSsoProviderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSsoProviderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSsoProviderResponse
             * @function getTypeUrl
             * @memberof streem.api.GetSsoProviderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSsoProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSsoProviderResponse";
            };

            return GetSsoProviderResponse;
        })();

        api.DeleteSsoProviderRequest = (function() {

            /**
             * Properties of a DeleteSsoProviderRequest.
             * @memberof streem.api
             * @interface IDeleteSsoProviderRequest
             * @property {string|null} [ssoProviderCode] DeleteSsoProviderRequest ssoProviderCode
             */

            /**
             * Constructs a new DeleteSsoProviderRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteSsoProviderRequest.
             * @implements IDeleteSsoProviderRequest
             * @constructor
             * @param {streem.api.IDeleteSsoProviderRequest=} [p] Properties to set
             */
            function DeleteSsoProviderRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteSsoProviderRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.DeleteSsoProviderRequest
             * @instance
             */
            DeleteSsoProviderRequest.prototype.ssoProviderCode = "";

            /**
             * Creates a new DeleteSsoProviderRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {streem.api.IDeleteSsoProviderRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteSsoProviderRequest} DeleteSsoProviderRequest instance
             */
            DeleteSsoProviderRequest.create = function create(properties) {
                return new DeleteSsoProviderRequest(properties);
            };

            /**
             * Encodes the specified DeleteSsoProviderRequest message. Does not implicitly {@link streem.api.DeleteSsoProviderRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {streem.api.IDeleteSsoProviderRequest} m DeleteSsoProviderRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSsoProviderRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                return w;
            };

            /**
             * Decodes a DeleteSsoProviderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSsoProviderRequest} DeleteSsoProviderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSsoProviderRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSsoProviderRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSsoProviderRequest} DeleteSsoProviderRequest
             */
            DeleteSsoProviderRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSsoProviderRequest)
                    return d;
                var m = new $root.streem.api.DeleteSsoProviderRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteSsoProviderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {streem.api.DeleteSsoProviderRequest} m DeleteSsoProviderRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSsoProviderRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                return d;
            };

            /**
             * Converts this DeleteSsoProviderRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSsoProviderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSsoProviderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSsoProviderRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteSsoProviderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSsoProviderRequest";
            };

            return DeleteSsoProviderRequest;
        })();

        api.ListSsoProviderCompanyMappingsRequest = (function() {

            /**
             * Properties of a ListSsoProviderCompanyMappingsRequest.
             * @memberof streem.api
             * @interface IListSsoProviderCompanyMappingsRequest
             * @property {string|null} [ssoProviderCode] ListSsoProviderCompanyMappingsRequest ssoProviderCode
             */

            /**
             * Constructs a new ListSsoProviderCompanyMappingsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListSsoProviderCompanyMappingsRequest.
             * @implements IListSsoProviderCompanyMappingsRequest
             * @constructor
             * @param {streem.api.IListSsoProviderCompanyMappingsRequest=} [p] Properties to set
             */
            function ListSsoProviderCompanyMappingsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSsoProviderCompanyMappingsRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @instance
             */
            ListSsoProviderCompanyMappingsRequest.prototype.ssoProviderCode = "";

            /**
             * Creates a new ListSsoProviderCompanyMappingsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {streem.api.IListSsoProviderCompanyMappingsRequest=} [properties] Properties to set
             * @returns {streem.api.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest instance
             */
            ListSsoProviderCompanyMappingsRequest.create = function create(properties) {
                return new ListSsoProviderCompanyMappingsRequest(properties);
            };

            /**
             * Encodes the specified ListSsoProviderCompanyMappingsRequest message. Does not implicitly {@link streem.api.ListSsoProviderCompanyMappingsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {streem.api.IListSsoProviderCompanyMappingsRequest} m ListSsoProviderCompanyMappingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSsoProviderCompanyMappingsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                return w;
            };

            /**
             * Decodes a ListSsoProviderCompanyMappingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSsoProviderCompanyMappingsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSsoProviderCompanyMappingsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSsoProviderCompanyMappingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest
             */
            ListSsoProviderCompanyMappingsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSsoProviderCompanyMappingsRequest)
                    return d;
                var m = new $root.streem.api.ListSsoProviderCompanyMappingsRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSsoProviderCompanyMappingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {streem.api.ListSsoProviderCompanyMappingsRequest} m ListSsoProviderCompanyMappingsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSsoProviderCompanyMappingsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                return d;
            };

            /**
             * Converts this ListSsoProviderCompanyMappingsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSsoProviderCompanyMappingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSsoProviderCompanyMappingsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListSsoProviderCompanyMappingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSsoProviderCompanyMappingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSsoProviderCompanyMappingsRequest";
            };

            return ListSsoProviderCompanyMappingsRequest;
        })();

        api.ListSsoProviderCompanyMappingsResponse = (function() {

            /**
             * Properties of a ListSsoProviderCompanyMappingsResponse.
             * @memberof streem.api
             * @interface IListSsoProviderCompanyMappingsResponse
             * @property {Array.<streem.api.ISsoProviderCompanyMapping>|null} [ssoProviderCompanyMappings] ListSsoProviderCompanyMappingsResponse ssoProviderCompanyMappings
             */

            /**
             * Constructs a new ListSsoProviderCompanyMappingsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListSsoProviderCompanyMappingsResponse.
             * @implements IListSsoProviderCompanyMappingsResponse
             * @constructor
             * @param {streem.api.IListSsoProviderCompanyMappingsResponse=} [p] Properties to set
             */
            function ListSsoProviderCompanyMappingsResponse(p) {
                this.ssoProviderCompanyMappings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListSsoProviderCompanyMappingsResponse ssoProviderCompanyMappings.
             * @member {Array.<streem.api.ISsoProviderCompanyMapping>} ssoProviderCompanyMappings
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @instance
             */
            ListSsoProviderCompanyMappingsResponse.prototype.ssoProviderCompanyMappings = $util.emptyArray;

            /**
             * Creates a new ListSsoProviderCompanyMappingsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {streem.api.IListSsoProviderCompanyMappingsResponse=} [properties] Properties to set
             * @returns {streem.api.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse instance
             */
            ListSsoProviderCompanyMappingsResponse.create = function create(properties) {
                return new ListSsoProviderCompanyMappingsResponse(properties);
            };

            /**
             * Encodes the specified ListSsoProviderCompanyMappingsResponse message. Does not implicitly {@link streem.api.ListSsoProviderCompanyMappingsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {streem.api.IListSsoProviderCompanyMappingsResponse} m ListSsoProviderCompanyMappingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListSsoProviderCompanyMappingsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCompanyMappings != null && m.ssoProviderCompanyMappings.length) {
                    for (var i = 0; i < m.ssoProviderCompanyMappings.length; ++i)
                        $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMappings[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListSsoProviderCompanyMappingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListSsoProviderCompanyMappingsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListSsoProviderCompanyMappingsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.ssoProviderCompanyMappings && m.ssoProviderCompanyMappings.length))
                                m.ssoProviderCompanyMappings = [];
                            m.ssoProviderCompanyMappings.push($root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListSsoProviderCompanyMappingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse
             */
            ListSsoProviderCompanyMappingsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListSsoProviderCompanyMappingsResponse)
                    return d;
                var m = new $root.streem.api.ListSsoProviderCompanyMappingsResponse();
                if (d.ssoProviderCompanyMappings) {
                    if (!Array.isArray(d.ssoProviderCompanyMappings))
                        throw TypeError(".streem.api.ListSsoProviderCompanyMappingsResponse.ssoProviderCompanyMappings: array expected");
                    m.ssoProviderCompanyMappings = [];
                    for (var i = 0; i < d.ssoProviderCompanyMappings.length; ++i) {
                        if (typeof d.ssoProviderCompanyMappings[i] !== "object")
                            throw TypeError(".streem.api.ListSsoProviderCompanyMappingsResponse.ssoProviderCompanyMappings: object expected");
                        m.ssoProviderCompanyMappings[i] = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMappings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListSsoProviderCompanyMappingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {streem.api.ListSsoProviderCompanyMappingsResponse} m ListSsoProviderCompanyMappingsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListSsoProviderCompanyMappingsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.ssoProviderCompanyMappings = [];
                }
                if (m.ssoProviderCompanyMappings && m.ssoProviderCompanyMappings.length) {
                    d.ssoProviderCompanyMappings = [];
                    for (var j = 0; j < m.ssoProviderCompanyMappings.length; ++j) {
                        d.ssoProviderCompanyMappings[j] = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMappings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListSsoProviderCompanyMappingsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListSsoProviderCompanyMappingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListSsoProviderCompanyMappingsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListSsoProviderCompanyMappingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListSsoProviderCompanyMappingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListSsoProviderCompanyMappingsResponse";
            };

            return ListSsoProviderCompanyMappingsResponse;
        })();

        api.GetSsoProviderCompanyMappingRequest = (function() {

            /**
             * Properties of a GetSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @interface IGetSsoProviderCompanyMappingRequest
             * @property {string|null} [ssoProviderCode] GetSsoProviderCompanyMappingRequest ssoProviderCode
             * @property {string|null} [companyCode] GetSsoProviderCompanyMappingRequest companyCode
             */

            /**
             * Constructs a new GetSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @classdesc Represents a GetSsoProviderCompanyMappingRequest.
             * @implements IGetSsoProviderCompanyMappingRequest
             * @constructor
             * @param {streem.api.IGetSsoProviderCompanyMappingRequest=} [p] Properties to set
             */
            function GetSsoProviderCompanyMappingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSsoProviderCompanyMappingRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @instance
             */
            GetSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

            /**
             * GetSsoProviderCompanyMappingRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @instance
             */
            GetSsoProviderCompanyMappingRequest.prototype.companyCode = "";

            /**
             * Creates a new GetSsoProviderCompanyMappingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IGetSsoProviderCompanyMappingRequest=} [properties] Properties to set
             * @returns {streem.api.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest instance
             */
            GetSsoProviderCompanyMappingRequest.create = function create(properties) {
                return new GetSsoProviderCompanyMappingRequest(properties);
            };

            /**
             * Encodes the specified GetSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.GetSsoProviderCompanyMappingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IGetSsoProviderCompanyMappingRequest} m GetSsoProviderCompanyMappingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a GetSsoProviderCompanyMappingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSsoProviderCompanyMappingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest
             */
            GetSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSsoProviderCompanyMappingRequest)
                    return d;
                var m = new $root.streem.api.GetSsoProviderCompanyMappingRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.GetSsoProviderCompanyMappingRequest} m GetSsoProviderCompanyMappingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.companyCode = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this GetSsoProviderCompanyMappingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSsoProviderCompanyMappingRequest
             * @function getTypeUrl
             * @memberof streem.api.GetSsoProviderCompanyMappingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSsoProviderCompanyMappingRequest";
            };

            return GetSsoProviderCompanyMappingRequest;
        })();

        api.GetSsoProviderCompanyMappingResponse = (function() {

            /**
             * Properties of a GetSsoProviderCompanyMappingResponse.
             * @memberof streem.api
             * @interface IGetSsoProviderCompanyMappingResponse
             * @property {streem.api.ISsoProviderCompanyMapping|null} [ssoProviderCompanyMapping] GetSsoProviderCompanyMappingResponse ssoProviderCompanyMapping
             */

            /**
             * Constructs a new GetSsoProviderCompanyMappingResponse.
             * @memberof streem.api
             * @classdesc Represents a GetSsoProviderCompanyMappingResponse.
             * @implements IGetSsoProviderCompanyMappingResponse
             * @constructor
             * @param {streem.api.IGetSsoProviderCompanyMappingResponse=} [p] Properties to set
             */
            function GetSsoProviderCompanyMappingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSsoProviderCompanyMappingResponse ssoProviderCompanyMapping.
             * @member {streem.api.ISsoProviderCompanyMapping|null|undefined} ssoProviderCompanyMapping
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @instance
             */
            GetSsoProviderCompanyMappingResponse.prototype.ssoProviderCompanyMapping = null;

            /**
             * Creates a new GetSsoProviderCompanyMappingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.IGetSsoProviderCompanyMappingResponse=} [properties] Properties to set
             * @returns {streem.api.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse instance
             */
            GetSsoProviderCompanyMappingResponse.create = function create(properties) {
                return new GetSsoProviderCompanyMappingResponse(properties);
            };

            /**
             * Encodes the specified GetSsoProviderCompanyMappingResponse message. Does not implicitly {@link streem.api.GetSsoProviderCompanyMappingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.IGetSsoProviderCompanyMappingResponse} m GetSsoProviderCompanyMappingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSsoProviderCompanyMappingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCompanyMapping != null && Object.hasOwnProperty.call(m, "ssoProviderCompanyMapping"))
                    $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMapping, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetSsoProviderCompanyMappingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSsoProviderCompanyMappingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSsoProviderCompanyMappingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSsoProviderCompanyMappingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse
             */
            GetSsoProviderCompanyMappingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSsoProviderCompanyMappingResponse)
                    return d;
                var m = new $root.streem.api.GetSsoProviderCompanyMappingResponse();
                if (d.ssoProviderCompanyMapping != null) {
                    if (typeof d.ssoProviderCompanyMapping !== "object")
                        throw TypeError(".streem.api.GetSsoProviderCompanyMappingResponse.ssoProviderCompanyMapping: object expected");
                    m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMapping);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSsoProviderCompanyMappingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.GetSsoProviderCompanyMappingResponse} m GetSsoProviderCompanyMappingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSsoProviderCompanyMappingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCompanyMapping = null;
                }
                if (m.ssoProviderCompanyMapping != null && m.hasOwnProperty("ssoProviderCompanyMapping")) {
                    d.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMapping, o);
                }
                return d;
            };

            /**
             * Converts this GetSsoProviderCompanyMappingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSsoProviderCompanyMappingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSsoProviderCompanyMappingResponse
             * @function getTypeUrl
             * @memberof streem.api.GetSsoProviderCompanyMappingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSsoProviderCompanyMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSsoProviderCompanyMappingResponse";
            };

            return GetSsoProviderCompanyMappingResponse;
        })();

        api.UpdateSsoProviderCompanyMappingRequest = (function() {

            /**
             * Properties of an UpdateSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @interface IUpdateSsoProviderCompanyMappingRequest
             * @property {string|null} [ssoProviderCode] UpdateSsoProviderCompanyMappingRequest ssoProviderCode
             * @property {string|null} [companyCode] UpdateSsoProviderCompanyMappingRequest companyCode
             * @property {boolean|null} [automaticRegistration] UpdateSsoProviderCompanyMappingRequest automaticRegistration
             */

            /**
             * Constructs a new UpdateSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateSsoProviderCompanyMappingRequest.
             * @implements IUpdateSsoProviderCompanyMappingRequest
             * @constructor
             * @param {streem.api.IUpdateSsoProviderCompanyMappingRequest=} [p] Properties to set
             */
            function UpdateSsoProviderCompanyMappingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateSsoProviderCompanyMappingRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @instance
             */
            UpdateSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

            /**
             * UpdateSsoProviderCompanyMappingRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @instance
             */
            UpdateSsoProviderCompanyMappingRequest.prototype.companyCode = "";

            /**
             * UpdateSsoProviderCompanyMappingRequest automaticRegistration.
             * @member {boolean} automaticRegistration
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @instance
             */
            UpdateSsoProviderCompanyMappingRequest.prototype.automaticRegistration = false;

            /**
             * Creates a new UpdateSsoProviderCompanyMappingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IUpdateSsoProviderCompanyMappingRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest instance
             */
            UpdateSsoProviderCompanyMappingRequest.create = function create(properties) {
                return new UpdateSsoProviderCompanyMappingRequest(properties);
            };

            /**
             * Encodes the specified UpdateSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.UpdateSsoProviderCompanyMappingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IUpdateSsoProviderCompanyMappingRequest} m UpdateSsoProviderCompanyMappingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                if (m.automaticRegistration != null && Object.hasOwnProperty.call(m, "automaticRegistration"))
                    w.uint32(24).bool(m.automaticRegistration);
                return w;
            };

            /**
             * Decodes an UpdateSsoProviderCompanyMappingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateSsoProviderCompanyMappingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    case 3: {
                            m.automaticRegistration = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest
             */
            UpdateSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateSsoProviderCompanyMappingRequest)
                    return d;
                var m = new $root.streem.api.UpdateSsoProviderCompanyMappingRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                if (d.automaticRegistration != null) {
                    m.automaticRegistration = Boolean(d.automaticRegistration);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.UpdateSsoProviderCompanyMappingRequest} m UpdateSsoProviderCompanyMappingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.companyCode = "";
                    d.automaticRegistration = false;
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                if (m.automaticRegistration != null && m.hasOwnProperty("automaticRegistration")) {
                    d.automaticRegistration = m.automaticRegistration;
                }
                return d;
            };

            /**
             * Converts this UpdateSsoProviderCompanyMappingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateSsoProviderCompanyMappingRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateSsoProviderCompanyMappingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateSsoProviderCompanyMappingRequest";
            };

            return UpdateSsoProviderCompanyMappingRequest;
        })();

        api.UpdateSsoProviderCompanyMappingResponse = (function() {

            /**
             * Properties of an UpdateSsoProviderCompanyMappingResponse.
             * @memberof streem.api
             * @interface IUpdateSsoProviderCompanyMappingResponse
             * @property {streem.api.ISsoProviderCompanyMapping|null} [ssoProviderCompanyMapping] UpdateSsoProviderCompanyMappingResponse ssoProviderCompanyMapping
             */

            /**
             * Constructs a new UpdateSsoProviderCompanyMappingResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateSsoProviderCompanyMappingResponse.
             * @implements IUpdateSsoProviderCompanyMappingResponse
             * @constructor
             * @param {streem.api.IUpdateSsoProviderCompanyMappingResponse=} [p] Properties to set
             */
            function UpdateSsoProviderCompanyMappingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateSsoProviderCompanyMappingResponse ssoProviderCompanyMapping.
             * @member {streem.api.ISsoProviderCompanyMapping|null|undefined} ssoProviderCompanyMapping
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @instance
             */
            UpdateSsoProviderCompanyMappingResponse.prototype.ssoProviderCompanyMapping = null;

            /**
             * Creates a new UpdateSsoProviderCompanyMappingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.IUpdateSsoProviderCompanyMappingResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse instance
             */
            UpdateSsoProviderCompanyMappingResponse.create = function create(properties) {
                return new UpdateSsoProviderCompanyMappingResponse(properties);
            };

            /**
             * Encodes the specified UpdateSsoProviderCompanyMappingResponse message. Does not implicitly {@link streem.api.UpdateSsoProviderCompanyMappingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.IUpdateSsoProviderCompanyMappingResponse} m UpdateSsoProviderCompanyMappingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSsoProviderCompanyMappingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCompanyMapping != null && Object.hasOwnProperty.call(m, "ssoProviderCompanyMapping"))
                    $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMapping, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateSsoProviderCompanyMappingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSsoProviderCompanyMappingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateSsoProviderCompanyMappingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateSsoProviderCompanyMappingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse
             */
            UpdateSsoProviderCompanyMappingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateSsoProviderCompanyMappingResponse)
                    return d;
                var m = new $root.streem.api.UpdateSsoProviderCompanyMappingResponse();
                if (d.ssoProviderCompanyMapping != null) {
                    if (typeof d.ssoProviderCompanyMapping !== "object")
                        throw TypeError(".streem.api.UpdateSsoProviderCompanyMappingResponse.ssoProviderCompanyMapping: object expected");
                    m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMapping);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateSsoProviderCompanyMappingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {streem.api.UpdateSsoProviderCompanyMappingResponse} m UpdateSsoProviderCompanyMappingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSsoProviderCompanyMappingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCompanyMapping = null;
                }
                if (m.ssoProviderCompanyMapping != null && m.hasOwnProperty("ssoProviderCompanyMapping")) {
                    d.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMapping, o);
                }
                return d;
            };

            /**
             * Converts this UpdateSsoProviderCompanyMappingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSsoProviderCompanyMappingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateSsoProviderCompanyMappingResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateSsoProviderCompanyMappingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSsoProviderCompanyMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateSsoProviderCompanyMappingResponse";
            };

            return UpdateSsoProviderCompanyMappingResponse;
        })();

        api.DeleteSsoProviderCompanyMappingRequest = (function() {

            /**
             * Properties of a DeleteSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @interface IDeleteSsoProviderCompanyMappingRequest
             * @property {string|null} [ssoProviderCode] DeleteSsoProviderCompanyMappingRequest ssoProviderCode
             * @property {string|null} [companyCode] DeleteSsoProviderCompanyMappingRequest companyCode
             */

            /**
             * Constructs a new DeleteSsoProviderCompanyMappingRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteSsoProviderCompanyMappingRequest.
             * @implements IDeleteSsoProviderCompanyMappingRequest
             * @constructor
             * @param {streem.api.IDeleteSsoProviderCompanyMappingRequest=} [p] Properties to set
             */
            function DeleteSsoProviderCompanyMappingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteSsoProviderCompanyMappingRequest ssoProviderCode.
             * @member {string} ssoProviderCode
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @instance
             */
            DeleteSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

            /**
             * DeleteSsoProviderCompanyMappingRequest companyCode.
             * @member {string} companyCode
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @instance
             */
            DeleteSsoProviderCompanyMappingRequest.prototype.companyCode = "";

            /**
             * Creates a new DeleteSsoProviderCompanyMappingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IDeleteSsoProviderCompanyMappingRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest instance
             */
            DeleteSsoProviderCompanyMappingRequest.create = function create(properties) {
                return new DeleteSsoProviderCompanyMappingRequest(properties);
            };

            /**
             * Encodes the specified DeleteSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.DeleteSsoProviderCompanyMappingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.IDeleteSsoProviderCompanyMappingRequest} m DeleteSsoProviderCompanyMappingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                    w.uint32(10).string(m.ssoProviderCode);
                if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                    w.uint32(18).string(m.companyCode);
                return w;
            };

            /**
             * Decodes a DeleteSsoProviderCompanyMappingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteSsoProviderCompanyMappingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ssoProviderCode = r.string();
                            break;
                        }
                    case 2: {
                            m.companyCode = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest
             */
            DeleteSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteSsoProviderCompanyMappingRequest)
                    return d;
                var m = new $root.streem.api.DeleteSsoProviderCompanyMappingRequest();
                if (d.ssoProviderCode != null) {
                    m.ssoProviderCode = String(d.ssoProviderCode);
                }
                if (d.companyCode != null) {
                    m.companyCode = String(d.companyCode);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {streem.api.DeleteSsoProviderCompanyMappingRequest} m DeleteSsoProviderCompanyMappingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.ssoProviderCode = "";
                    d.companyCode = "";
                }
                if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                    d.ssoProviderCode = m.ssoProviderCode;
                }
                if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                    d.companyCode = m.companyCode;
                }
                return d;
            };

            /**
             * Converts this DeleteSsoProviderCompanyMappingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteSsoProviderCompanyMappingRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteSsoProviderCompanyMappingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteSsoProviderCompanyMappingRequest";
            };

            return DeleteSsoProviderCompanyMappingRequest;
        })();

        api.Webhook = (function() {

            /**
             * Properties of a Webhook.
             * @memberof streem.api
             * @interface IWebhook
             * @property {string|null} [sid] Webhook sid
             * @property {string|null} [companySid] Webhook companySid
             * @property {string|null} [label] Webhook label
             * @property {string|null} [url] Webhook url
             * @property {string|null} [method] Webhook method
             * @property {number|null} [timeoutMs] Webhook timeoutMs
             * @property {number|null} [maxAttempts] Webhook maxAttempts
             * @property {Array.<streem.api.Webhook.IHeader>|null} [headers] Webhook headers
             */

            /**
             * Constructs a new Webhook.
             * @memberof streem.api
             * @classdesc Represents a Webhook.
             * @implements IWebhook
             * @constructor
             * @param {streem.api.IWebhook=} [p] Properties to set
             */
            function Webhook(p) {
                this.headers = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Webhook sid.
             * @member {string} sid
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.sid = "";

            /**
             * Webhook companySid.
             * @member {string} companySid
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.companySid = "";

            /**
             * Webhook label.
             * @member {string} label
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.label = "";

            /**
             * Webhook url.
             * @member {string} url
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.url = "";

            /**
             * Webhook method.
             * @member {string} method
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.method = "";

            /**
             * Webhook timeoutMs.
             * @member {number} timeoutMs
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.timeoutMs = 0;

            /**
             * Webhook maxAttempts.
             * @member {number} maxAttempts
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.maxAttempts = 0;

            /**
             * Webhook headers.
             * @member {Array.<streem.api.Webhook.IHeader>} headers
             * @memberof streem.api.Webhook
             * @instance
             */
            Webhook.prototype.headers = $util.emptyArray;

            /**
             * Creates a new Webhook instance using the specified properties.
             * @function create
             * @memberof streem.api.Webhook
             * @static
             * @param {streem.api.IWebhook=} [properties] Properties to set
             * @returns {streem.api.Webhook} Webhook instance
             */
            Webhook.create = function create(properties) {
                return new Webhook(properties);
            };

            /**
             * Encodes the specified Webhook message. Does not implicitly {@link streem.api.Webhook.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Webhook
             * @static
             * @param {streem.api.IWebhook} m Webhook message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Webhook.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(34).string(m.label);
                if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                    w.uint32(42).string(m.url);
                if (m.method != null && Object.hasOwnProperty.call(m, "method"))
                    w.uint32(50).string(m.method);
                if (m.timeoutMs != null && Object.hasOwnProperty.call(m, "timeoutMs"))
                    w.uint32(56).int32(m.timeoutMs);
                if (m.maxAttempts != null && Object.hasOwnProperty.call(m, "maxAttempts"))
                    w.uint32(64).int32(m.maxAttempts);
                if (m.headers != null && m.headers.length) {
                    for (var i = 0; i < m.headers.length; ++i)
                        $root.streem.api.Webhook.Header.encode(m.headers[i], w.uint32(74).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a Webhook message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Webhook
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Webhook} Webhook
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Webhook.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Webhook();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    case 4: {
                            m.label = r.string();
                            break;
                        }
                    case 5: {
                            m.url = r.string();
                            break;
                        }
                    case 6: {
                            m.method = r.string();
                            break;
                        }
                    case 7: {
                            m.timeoutMs = r.int32();
                            break;
                        }
                    case 8: {
                            m.maxAttempts = r.int32();
                            break;
                        }
                    case 9: {
                            if (!(m.headers && m.headers.length))
                                m.headers = [];
                            m.headers.push($root.streem.api.Webhook.Header.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Webhook message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Webhook
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Webhook} Webhook
             */
            Webhook.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Webhook)
                    return d;
                var m = new $root.streem.api.Webhook();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                if (d.url != null) {
                    m.url = String(d.url);
                }
                if (d.method != null) {
                    m.method = String(d.method);
                }
                if (d.timeoutMs != null) {
                    m.timeoutMs = d.timeoutMs | 0;
                }
                if (d.maxAttempts != null) {
                    m.maxAttempts = d.maxAttempts | 0;
                }
                if (d.headers) {
                    if (!Array.isArray(d.headers))
                        throw TypeError(".streem.api.Webhook.headers: array expected");
                    m.headers = [];
                    for (var i = 0; i < d.headers.length; ++i) {
                        if (typeof d.headers[i] !== "object")
                            throw TypeError(".streem.api.Webhook.headers: object expected");
                        m.headers[i] = $root.streem.api.Webhook.Header.fromObject(d.headers[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Webhook message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Webhook
             * @static
             * @param {streem.api.Webhook} m Webhook
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Webhook.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.headers = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.companySid = "";
                    d.label = "";
                    d.url = "";
                    d.method = "";
                    d.timeoutMs = 0;
                    d.maxAttempts = 0;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = m.url;
                }
                if (m.method != null && m.hasOwnProperty("method")) {
                    d.method = m.method;
                }
                if (m.timeoutMs != null && m.hasOwnProperty("timeoutMs")) {
                    d.timeoutMs = m.timeoutMs;
                }
                if (m.maxAttempts != null && m.hasOwnProperty("maxAttempts")) {
                    d.maxAttempts = m.maxAttempts;
                }
                if (m.headers && m.headers.length) {
                    d.headers = [];
                    for (var j = 0; j < m.headers.length; ++j) {
                        d.headers[j] = $root.streem.api.Webhook.Header.toObject(m.headers[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this Webhook to JSON.
             * @function toJSON
             * @memberof streem.api.Webhook
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Webhook.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Webhook
             * @function getTypeUrl
             * @memberof streem.api.Webhook
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Webhook.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Webhook";
            };

            Webhook.Header = (function() {

                /**
                 * Properties of a Header.
                 * @memberof streem.api.Webhook
                 * @interface IHeader
                 * @property {string|null} [name] Header name
                 * @property {string|null} [value] Header value
                 * @property {boolean|null} [includeInRequestSignature] Header includeInRequestSignature
                 */

                /**
                 * Constructs a new Header.
                 * @memberof streem.api.Webhook
                 * @classdesc Represents a Header.
                 * @implements IHeader
                 * @constructor
                 * @param {streem.api.Webhook.IHeader=} [p] Properties to set
                 */
                function Header(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Header name.
                 * @member {string} name
                 * @memberof streem.api.Webhook.Header
                 * @instance
                 */
                Header.prototype.name = "";

                /**
                 * Header value.
                 * @member {string} value
                 * @memberof streem.api.Webhook.Header
                 * @instance
                 */
                Header.prototype.value = "";

                /**
                 * Header includeInRequestSignature.
                 * @member {boolean} includeInRequestSignature
                 * @memberof streem.api.Webhook.Header
                 * @instance
                 */
                Header.prototype.includeInRequestSignature = false;

                /**
                 * Creates a new Header instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {streem.api.Webhook.IHeader=} [properties] Properties to set
                 * @returns {streem.api.Webhook.Header} Header instance
                 */
                Header.create = function create(properties) {
                    return new Header(properties);
                };

                /**
                 * Encodes the specified Header message. Does not implicitly {@link streem.api.Webhook.Header.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {streem.api.Webhook.IHeader} m Header message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Header.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(10).string(m.name);
                    if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                        w.uint32(18).string(m.value);
                    if (m.includeInRequestSignature != null && Object.hasOwnProperty.call(m, "includeInRequestSignature"))
                        w.uint32(24).bool(m.includeInRequestSignature);
                    return w;
                };

                /**
                 * Decodes a Header message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Webhook.Header} Header
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Header.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Webhook.Header();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.name = r.string();
                                break;
                            }
                        case 2: {
                                m.value = r.string();
                                break;
                            }
                        case 3: {
                                m.includeInRequestSignature = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Header message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Webhook.Header} Header
                 */
                Header.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Webhook.Header)
                        return d;
                    var m = new $root.streem.api.Webhook.Header();
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    if (d.value != null) {
                        m.value = String(d.value);
                    }
                    if (d.includeInRequestSignature != null) {
                        m.includeInRequestSignature = Boolean(d.includeInRequestSignature);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Header message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {streem.api.Webhook.Header} m Header
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Header.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.name = "";
                        d.value = "";
                        d.includeInRequestSignature = false;
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    if (m.value != null && m.hasOwnProperty("value")) {
                        d.value = m.value;
                    }
                    if (m.includeInRequestSignature != null && m.hasOwnProperty("includeInRequestSignature")) {
                        d.includeInRequestSignature = m.includeInRequestSignature;
                    }
                    return d;
                };

                /**
                 * Converts this Header to JSON.
                 * @function toJSON
                 * @memberof streem.api.Webhook.Header
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Header.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Header
                 * @function getTypeUrl
                 * @memberof streem.api.Webhook.Header
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Header.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Webhook.Header";
                };

                return Header;
            })();

            return Webhook;
        })();

        api.WebhookSigningKey = (function() {

            /**
             * Properties of a WebhookSigningKey.
             * @memberof streem.api
             * @interface IWebhookSigningKey
             * @property {string|null} [sid] WebhookSigningKey sid
             * @property {string|null} [webhookSid] WebhookSigningKey webhookSid
             * @property {string|null} [sharedSecret] WebhookSigningKey sharedSecret
             * @property {google.protobuf.ITimestamp|null} [expiresAt] WebhookSigningKey expiresAt
             * @property {string|null} [label] WebhookSigningKey label
             */

            /**
             * Constructs a new WebhookSigningKey.
             * @memberof streem.api
             * @classdesc Represents a WebhookSigningKey.
             * @implements IWebhookSigningKey
             * @constructor
             * @param {streem.api.IWebhookSigningKey=} [p] Properties to set
             */
            function WebhookSigningKey(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * WebhookSigningKey sid.
             * @member {string} sid
             * @memberof streem.api.WebhookSigningKey
             * @instance
             */
            WebhookSigningKey.prototype.sid = "";

            /**
             * WebhookSigningKey webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.WebhookSigningKey
             * @instance
             */
            WebhookSigningKey.prototype.webhookSid = "";

            /**
             * WebhookSigningKey sharedSecret.
             * @member {string} sharedSecret
             * @memberof streem.api.WebhookSigningKey
             * @instance
             */
            WebhookSigningKey.prototype.sharedSecret = "";

            /**
             * WebhookSigningKey expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.WebhookSigningKey
             * @instance
             */
            WebhookSigningKey.prototype.expiresAt = null;

            /**
             * WebhookSigningKey label.
             * @member {string} label
             * @memberof streem.api.WebhookSigningKey
             * @instance
             */
            WebhookSigningKey.prototype.label = "";

            /**
             * Creates a new WebhookSigningKey instance using the specified properties.
             * @function create
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {streem.api.IWebhookSigningKey=} [properties] Properties to set
             * @returns {streem.api.WebhookSigningKey} WebhookSigningKey instance
             */
            WebhookSigningKey.create = function create(properties) {
                return new WebhookSigningKey(properties);
            };

            /**
             * Encodes the specified WebhookSigningKey message. Does not implicitly {@link streem.api.WebhookSigningKey.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {streem.api.IWebhookSigningKey} m WebhookSigningKey message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WebhookSigningKey.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(18).string(m.webhookSid);
                if (m.sharedSecret != null && Object.hasOwnProperty.call(m, "sharedSecret"))
                    w.uint32(26).string(m.sharedSecret);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(34).fork()).ldelim();
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(42).string(m.label);
                return w;
            };

            /**
             * Decodes a WebhookSigningKey message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WebhookSigningKey} WebhookSigningKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WebhookSigningKey.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WebhookSigningKey();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 3: {
                            m.sharedSecret = r.string();
                            break;
                        }
                    case 4: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.label = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WebhookSigningKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WebhookSigningKey} WebhookSigningKey
             */
            WebhookSigningKey.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WebhookSigningKey)
                    return d;
                var m = new $root.streem.api.WebhookSigningKey();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.sharedSecret != null) {
                    m.sharedSecret = String(d.sharedSecret);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.WebhookSigningKey.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from a WebhookSigningKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {streem.api.WebhookSigningKey} m WebhookSigningKey
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WebhookSigningKey.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.sid = "";
                    d.webhookSid = "";
                    d.sharedSecret = "";
                    d.expiresAt = null;
                    d.label = "";
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.sharedSecret != null && m.hasOwnProperty("sharedSecret")) {
                    d.sharedSecret = m.sharedSecret;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                return d;
            };

            /**
             * Converts this WebhookSigningKey to JSON.
             * @function toJSON
             * @memberof streem.api.WebhookSigningKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WebhookSigningKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WebhookSigningKey
             * @function getTypeUrl
             * @memberof streem.api.WebhookSigningKey
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WebhookSigningKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WebhookSigningKey";
            };

            return WebhookSigningKey;
        })();

        api.WebhookRequestBody = (function() {

            /**
             * Properties of a WebhookRequestBody.
             * @memberof streem.api
             * @interface IWebhookRequestBody
             * @property {string|null} [requestSid] WebhookRequestBody requestSid
             * @property {string|null} [webhookSid] WebhookRequestBody webhookSid
             * @property {string|null} [companySid] WebhookRequestBody companySid
             * @property {google.protobuf.ITimestamp|null} [sentAt] WebhookRequestBody sentAt
             * @property {streem.api.IWebhookEvent|null} [event] WebhookRequestBody event
             */

            /**
             * Constructs a new WebhookRequestBody.
             * @memberof streem.api
             * @classdesc Represents a WebhookRequestBody.
             * @implements IWebhookRequestBody
             * @constructor
             * @param {streem.api.IWebhookRequestBody=} [p] Properties to set
             */
            function WebhookRequestBody(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * WebhookRequestBody requestSid.
             * @member {string} requestSid
             * @memberof streem.api.WebhookRequestBody
             * @instance
             */
            WebhookRequestBody.prototype.requestSid = "";

            /**
             * WebhookRequestBody webhookSid.
             * @member {string} webhookSid
             * @memberof streem.api.WebhookRequestBody
             * @instance
             */
            WebhookRequestBody.prototype.webhookSid = "";

            /**
             * WebhookRequestBody companySid.
             * @member {string} companySid
             * @memberof streem.api.WebhookRequestBody
             * @instance
             */
            WebhookRequestBody.prototype.companySid = "";

            /**
             * WebhookRequestBody sentAt.
             * @member {google.protobuf.ITimestamp|null|undefined} sentAt
             * @memberof streem.api.WebhookRequestBody
             * @instance
             */
            WebhookRequestBody.prototype.sentAt = null;

            /**
             * WebhookRequestBody event.
             * @member {streem.api.IWebhookEvent|null|undefined} event
             * @memberof streem.api.WebhookRequestBody
             * @instance
             */
            WebhookRequestBody.prototype.event = null;

            /**
             * Creates a new WebhookRequestBody instance using the specified properties.
             * @function create
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {streem.api.IWebhookRequestBody=} [properties] Properties to set
             * @returns {streem.api.WebhookRequestBody} WebhookRequestBody instance
             */
            WebhookRequestBody.create = function create(properties) {
                return new WebhookRequestBody(properties);
            };

            /**
             * Encodes the specified WebhookRequestBody message. Does not implicitly {@link streem.api.WebhookRequestBody.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {streem.api.IWebhookRequestBody} m WebhookRequestBody message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WebhookRequestBody.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.requestSid != null && Object.hasOwnProperty.call(m, "requestSid"))
                    w.uint32(10).string(m.requestSid);
                if (m.webhookSid != null && Object.hasOwnProperty.call(m, "webhookSid"))
                    w.uint32(18).string(m.webhookSid);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(26).string(m.companySid);
                if (m.sentAt != null && Object.hasOwnProperty.call(m, "sentAt"))
                    $root.google.protobuf.Timestamp.encode(m.sentAt, w.uint32(34).fork()).ldelim();
                if (m.event != null && Object.hasOwnProperty.call(m, "event"))
                    $root.streem.api.WebhookEvent.encode(m.event, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a WebhookRequestBody message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WebhookRequestBody} WebhookRequestBody
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WebhookRequestBody.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WebhookRequestBody();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.requestSid = r.string();
                            break;
                        }
                    case 2: {
                            m.webhookSid = r.string();
                            break;
                        }
                    case 3: {
                            m.companySid = r.string();
                            break;
                        }
                    case 4: {
                            m.sentAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.event = $root.streem.api.WebhookEvent.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WebhookRequestBody message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WebhookRequestBody} WebhookRequestBody
             */
            WebhookRequestBody.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WebhookRequestBody)
                    return d;
                var m = new $root.streem.api.WebhookRequestBody();
                if (d.requestSid != null) {
                    m.requestSid = String(d.requestSid);
                }
                if (d.webhookSid != null) {
                    m.webhookSid = String(d.webhookSid);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.sentAt != null) {
                    if (typeof d.sentAt !== "object")
                        throw TypeError(".streem.api.WebhookRequestBody.sentAt: object expected");
                    m.sentAt = $root.google.protobuf.Timestamp.fromObject(d.sentAt);
                }
                if (d.event != null) {
                    if (typeof d.event !== "object")
                        throw TypeError(".streem.api.WebhookRequestBody.event: object expected");
                    m.event = $root.streem.api.WebhookEvent.fromObject(d.event);
                }
                return m;
            };

            /**
             * Creates a plain object from a WebhookRequestBody message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {streem.api.WebhookRequestBody} m WebhookRequestBody
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WebhookRequestBody.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.requestSid = "";
                    d.webhookSid = "";
                    d.companySid = "";
                    d.sentAt = null;
                    d.event = null;
                }
                if (m.requestSid != null && m.hasOwnProperty("requestSid")) {
                    d.requestSid = m.requestSid;
                }
                if (m.webhookSid != null && m.hasOwnProperty("webhookSid")) {
                    d.webhookSid = m.webhookSid;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.sentAt != null && m.hasOwnProperty("sentAt")) {
                    d.sentAt = $root.google.protobuf.Timestamp.toObject(m.sentAt, o);
                }
                if (m.event != null && m.hasOwnProperty("event")) {
                    d.event = $root.streem.api.WebhookEvent.toObject(m.event, o);
                }
                return d;
            };

            /**
             * Converts this WebhookRequestBody to JSON.
             * @function toJSON
             * @memberof streem.api.WebhookRequestBody
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WebhookRequestBody.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WebhookRequestBody
             * @function getTypeUrl
             * @memberof streem.api.WebhookRequestBody
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WebhookRequestBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WebhookRequestBody";
            };

            return WebhookRequestBody;
        })();

        api.WebhookEvent = (function() {

            /**
             * Properties of a WebhookEvent.
             * @memberof streem.api
             * @interface IWebhookEvent
             * @property {string|null} [eventSid] WebhookEvent eventSid
             * @property {string|null} [eventType] WebhookEvent eventType
             * @property {google.protobuf.ITimestamp|null} [occurredAt] WebhookEvent occurredAt
             * @property {google.protobuf.IAny|null} [payload] WebhookEvent payload
             */

            /**
             * Constructs a new WebhookEvent.
             * @memberof streem.api
             * @classdesc Represents a WebhookEvent.
             * @implements IWebhookEvent
             * @constructor
             * @param {streem.api.IWebhookEvent=} [p] Properties to set
             */
            function WebhookEvent(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * WebhookEvent eventSid.
             * @member {string} eventSid
             * @memberof streem.api.WebhookEvent
             * @instance
             */
            WebhookEvent.prototype.eventSid = "";

            /**
             * WebhookEvent eventType.
             * @member {string} eventType
             * @memberof streem.api.WebhookEvent
             * @instance
             */
            WebhookEvent.prototype.eventType = "";

            /**
             * WebhookEvent occurredAt.
             * @member {google.protobuf.ITimestamp|null|undefined} occurredAt
             * @memberof streem.api.WebhookEvent
             * @instance
             */
            WebhookEvent.prototype.occurredAt = null;

            /**
             * WebhookEvent payload.
             * @member {google.protobuf.IAny|null|undefined} payload
             * @memberof streem.api.WebhookEvent
             * @instance
             */
            WebhookEvent.prototype.payload = null;

            /**
             * Creates a new WebhookEvent instance using the specified properties.
             * @function create
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {streem.api.IWebhookEvent=} [properties] Properties to set
             * @returns {streem.api.WebhookEvent} WebhookEvent instance
             */
            WebhookEvent.create = function create(properties) {
                return new WebhookEvent(properties);
            };

            /**
             * Encodes the specified WebhookEvent message. Does not implicitly {@link streem.api.WebhookEvent.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {streem.api.IWebhookEvent} m WebhookEvent message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WebhookEvent.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.eventSid != null && Object.hasOwnProperty.call(m, "eventSid"))
                    w.uint32(10).string(m.eventSid);
                if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                    w.uint32(18).string(m.eventType);
                if (m.occurredAt != null && Object.hasOwnProperty.call(m, "occurredAt"))
                    $root.google.protobuf.Timestamp.encode(m.occurredAt, w.uint32(26).fork()).ldelim();
                if (m.payload != null && Object.hasOwnProperty.call(m, "payload"))
                    $root.google.protobuf.Any.encode(m.payload, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a WebhookEvent message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WebhookEvent} WebhookEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WebhookEvent.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WebhookEvent();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.eventSid = r.string();
                            break;
                        }
                    case 2: {
                            m.eventType = r.string();
                            break;
                        }
                    case 3: {
                            m.occurredAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.payload = $root.google.protobuf.Any.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WebhookEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WebhookEvent} WebhookEvent
             */
            WebhookEvent.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WebhookEvent)
                    return d;
                var m = new $root.streem.api.WebhookEvent();
                if (d.eventSid != null) {
                    m.eventSid = String(d.eventSid);
                }
                if (d.eventType != null) {
                    m.eventType = String(d.eventType);
                }
                if (d.occurredAt != null) {
                    if (typeof d.occurredAt !== "object")
                        throw TypeError(".streem.api.WebhookEvent.occurredAt: object expected");
                    m.occurredAt = $root.google.protobuf.Timestamp.fromObject(d.occurredAt);
                }
                if (d.payload != null) {
                    if (typeof d.payload !== "object")
                        throw TypeError(".streem.api.WebhookEvent.payload: object expected");
                    m.payload = $root.google.protobuf.Any.fromObject(d.payload);
                }
                return m;
            };

            /**
             * Creates a plain object from a WebhookEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {streem.api.WebhookEvent} m WebhookEvent
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WebhookEvent.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.eventSid = "";
                    d.eventType = "";
                    d.occurredAt = null;
                    d.payload = null;
                }
                if (m.eventSid != null && m.hasOwnProperty("eventSid")) {
                    d.eventSid = m.eventSid;
                }
                if (m.eventType != null && m.hasOwnProperty("eventType")) {
                    d.eventType = m.eventType;
                }
                if (m.occurredAt != null && m.hasOwnProperty("occurredAt")) {
                    d.occurredAt = $root.google.protobuf.Timestamp.toObject(m.occurredAt, o);
                }
                if (m.payload != null && m.hasOwnProperty("payload")) {
                    d.payload = $root.google.protobuf.Any.toObject(m.payload, o);
                }
                return d;
            };

            /**
             * Converts this WebhookEvent to JSON.
             * @function toJSON
             * @memberof streem.api.WebhookEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WebhookEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WebhookEvent
             * @function getTypeUrl
             * @memberof streem.api.WebhookEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WebhookEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WebhookEvent";
            };

            return WebhookEvent;
        })();

        api.WebhookEventPayload = (function() {

            /**
             * Properties of a WebhookEventPayload.
             * @memberof streem.api
             * @interface IWebhookEventPayload
             * @property {streem.api.events.IRoomCreated|null} [roomCreated] WebhookEventPayload roomCreated
             * @property {streem.api.events.IRoomUpdated|null} [roomUpdated] WebhookEventPayload roomUpdated
             * @property {streem.api.events.IRoomArchived|null} [roomArchived] WebhookEventPayload roomArchived
             * @property {streem.api.events.IRoomParticipantCreated|null} [roomParticipantCreated] WebhookEventPayload roomParticipantCreated
             * @property {streem.api.events.IRoomParticipantUpdated|null} [roomParticipantUpdated] WebhookEventPayload roomParticipantUpdated
             * @property {streem.api.events.IGroupReservationCreated|null} [groupReservationCreated] WebhookEventPayload groupReservationCreated
             * @property {streem.api.events.IGroupReservationUpdated|null} [groupReservationUpdated] WebhookEventPayload groupReservationUpdated
             * @property {streem.api.events.IArtifactCreated|null} [artifactCreated] WebhookEventPayload artifactCreated
             * @property {streem.api.events.IArtifactUpdated|null} [artifactUpdated] WebhookEventPayload artifactUpdated
             * @property {streem.api.events.IArtifactDeleted|null} [artifactDeleted] WebhookEventPayload artifactDeleted
             * @property {streem.api.events.IRoomNoteCreated|null} [roomNoteCreated] WebhookEventPayload roomNoteCreated
             * @property {streem.api.events.IRoomNoteUpdated|null} [roomNoteUpdated] WebhookEventPayload roomNoteUpdated
             * @property {streem.api.events.IRoomNoteDeleted|null} [roomNoteDeleted] WebhookEventPayload roomNoteDeleted
             */

            /**
             * Constructs a new WebhookEventPayload.
             * @memberof streem.api
             * @classdesc Represents a WebhookEventPayload.
             * @implements IWebhookEventPayload
             * @constructor
             * @param {streem.api.IWebhookEventPayload=} [p] Properties to set
             */
            function WebhookEventPayload(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * WebhookEventPayload roomCreated.
             * @member {streem.api.events.IRoomCreated|null|undefined} roomCreated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomCreated = null;

            /**
             * WebhookEventPayload roomUpdated.
             * @member {streem.api.events.IRoomUpdated|null|undefined} roomUpdated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomUpdated = null;

            /**
             * WebhookEventPayload roomArchived.
             * @member {streem.api.events.IRoomArchived|null|undefined} roomArchived
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomArchived = null;

            /**
             * WebhookEventPayload roomParticipantCreated.
             * @member {streem.api.events.IRoomParticipantCreated|null|undefined} roomParticipantCreated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomParticipantCreated = null;

            /**
             * WebhookEventPayload roomParticipantUpdated.
             * @member {streem.api.events.IRoomParticipantUpdated|null|undefined} roomParticipantUpdated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomParticipantUpdated = null;

            /**
             * WebhookEventPayload groupReservationCreated.
             * @member {streem.api.events.IGroupReservationCreated|null|undefined} groupReservationCreated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.groupReservationCreated = null;

            /**
             * WebhookEventPayload groupReservationUpdated.
             * @member {streem.api.events.IGroupReservationUpdated|null|undefined} groupReservationUpdated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.groupReservationUpdated = null;

            /**
             * WebhookEventPayload artifactCreated.
             * @member {streem.api.events.IArtifactCreated|null|undefined} artifactCreated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.artifactCreated = null;

            /**
             * WebhookEventPayload artifactUpdated.
             * @member {streem.api.events.IArtifactUpdated|null|undefined} artifactUpdated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.artifactUpdated = null;

            /**
             * WebhookEventPayload artifactDeleted.
             * @member {streem.api.events.IArtifactDeleted|null|undefined} artifactDeleted
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.artifactDeleted = null;

            /**
             * WebhookEventPayload roomNoteCreated.
             * @member {streem.api.events.IRoomNoteCreated|null|undefined} roomNoteCreated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomNoteCreated = null;

            /**
             * WebhookEventPayload roomNoteUpdated.
             * @member {streem.api.events.IRoomNoteUpdated|null|undefined} roomNoteUpdated
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomNoteUpdated = null;

            /**
             * WebhookEventPayload roomNoteDeleted.
             * @member {streem.api.events.IRoomNoteDeleted|null|undefined} roomNoteDeleted
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            WebhookEventPayload.prototype.roomNoteDeleted = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * WebhookEventPayload type.
             * @member {"roomCreated"|"roomUpdated"|"roomArchived"|"roomParticipantCreated"|"roomParticipantUpdated"|"groupReservationCreated"|"groupReservationUpdated"|"artifactCreated"|"artifactUpdated"|"artifactDeleted"|"roomNoteCreated"|"roomNoteUpdated"|"roomNoteDeleted"|undefined} type
             * @memberof streem.api.WebhookEventPayload
             * @instance
             */
            Object.defineProperty(WebhookEventPayload.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["roomCreated", "roomUpdated", "roomArchived", "roomParticipantCreated", "roomParticipantUpdated", "groupReservationCreated", "groupReservationUpdated", "artifactCreated", "artifactUpdated", "artifactDeleted", "roomNoteCreated", "roomNoteUpdated", "roomNoteDeleted"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new WebhookEventPayload instance using the specified properties.
             * @function create
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {streem.api.IWebhookEventPayload=} [properties] Properties to set
             * @returns {streem.api.WebhookEventPayload} WebhookEventPayload instance
             */
            WebhookEventPayload.create = function create(properties) {
                return new WebhookEventPayload(properties);
            };

            /**
             * Encodes the specified WebhookEventPayload message. Does not implicitly {@link streem.api.WebhookEventPayload.verify|verify} messages.
             * @function encode
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {streem.api.IWebhookEventPayload} m WebhookEventPayload message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WebhookEventPayload.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomCreated != null && Object.hasOwnProperty.call(m, "roomCreated"))
                    $root.streem.api.events.RoomCreated.encode(m.roomCreated, w.uint32(802).fork()).ldelim();
                if (m.roomUpdated != null && Object.hasOwnProperty.call(m, "roomUpdated"))
                    $root.streem.api.events.RoomUpdated.encode(m.roomUpdated, w.uint32(810).fork()).ldelim();
                if (m.roomArchived != null && Object.hasOwnProperty.call(m, "roomArchived"))
                    $root.streem.api.events.RoomArchived.encode(m.roomArchived, w.uint32(826).fork()).ldelim();
                if (m.roomParticipantCreated != null && Object.hasOwnProperty.call(m, "roomParticipantCreated"))
                    $root.streem.api.events.RoomParticipantCreated.encode(m.roomParticipantCreated, w.uint32(882).fork()).ldelim();
                if (m.roomParticipantUpdated != null && Object.hasOwnProperty.call(m, "roomParticipantUpdated"))
                    $root.streem.api.events.RoomParticipantUpdated.encode(m.roomParticipantUpdated, w.uint32(890).fork()).ldelim();
                if (m.groupReservationCreated != null && Object.hasOwnProperty.call(m, "groupReservationCreated"))
                    $root.streem.api.events.GroupReservationCreated.encode(m.groupReservationCreated, w.uint32(962).fork()).ldelim();
                if (m.groupReservationUpdated != null && Object.hasOwnProperty.call(m, "groupReservationUpdated"))
                    $root.streem.api.events.GroupReservationUpdated.encode(m.groupReservationUpdated, w.uint32(970).fork()).ldelim();
                if (m.artifactCreated != null && Object.hasOwnProperty.call(m, "artifactCreated"))
                    $root.streem.api.events.ArtifactCreated.encode(m.artifactCreated, w.uint32(1042).fork()).ldelim();
                if (m.artifactUpdated != null && Object.hasOwnProperty.call(m, "artifactUpdated"))
                    $root.streem.api.events.ArtifactUpdated.encode(m.artifactUpdated, w.uint32(1050).fork()).ldelim();
                if (m.artifactDeleted != null && Object.hasOwnProperty.call(m, "artifactDeleted"))
                    $root.streem.api.events.ArtifactDeleted.encode(m.artifactDeleted, w.uint32(1058).fork()).ldelim();
                if (m.roomNoteCreated != null && Object.hasOwnProperty.call(m, "roomNoteCreated"))
                    $root.streem.api.events.RoomNoteCreated.encode(m.roomNoteCreated, w.uint32(1282).fork()).ldelim();
                if (m.roomNoteUpdated != null && Object.hasOwnProperty.call(m, "roomNoteUpdated"))
                    $root.streem.api.events.RoomNoteUpdated.encode(m.roomNoteUpdated, w.uint32(1290).fork()).ldelim();
                if (m.roomNoteDeleted != null && Object.hasOwnProperty.call(m, "roomNoteDeleted"))
                    $root.streem.api.events.RoomNoteDeleted.encode(m.roomNoteDeleted, w.uint32(1298).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a WebhookEventPayload message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.WebhookEventPayload} WebhookEventPayload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WebhookEventPayload.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.WebhookEventPayload();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 100: {
                            m.roomCreated = $root.streem.api.events.RoomCreated.decode(r, r.uint32());
                            break;
                        }
                    case 101: {
                            m.roomUpdated = $root.streem.api.events.RoomUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 103: {
                            m.roomArchived = $root.streem.api.events.RoomArchived.decode(r, r.uint32());
                            break;
                        }
                    case 110: {
                            m.roomParticipantCreated = $root.streem.api.events.RoomParticipantCreated.decode(r, r.uint32());
                            break;
                        }
                    case 111: {
                            m.roomParticipantUpdated = $root.streem.api.events.RoomParticipantUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 120: {
                            m.groupReservationCreated = $root.streem.api.events.GroupReservationCreated.decode(r, r.uint32());
                            break;
                        }
                    case 121: {
                            m.groupReservationUpdated = $root.streem.api.events.GroupReservationUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 130: {
                            m.artifactCreated = $root.streem.api.events.ArtifactCreated.decode(r, r.uint32());
                            break;
                        }
                    case 131: {
                            m.artifactUpdated = $root.streem.api.events.ArtifactUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 132: {
                            m.artifactDeleted = $root.streem.api.events.ArtifactDeleted.decode(r, r.uint32());
                            break;
                        }
                    case 160: {
                            m.roomNoteCreated = $root.streem.api.events.RoomNoteCreated.decode(r, r.uint32());
                            break;
                        }
                    case 161: {
                            m.roomNoteUpdated = $root.streem.api.events.RoomNoteUpdated.decode(r, r.uint32());
                            break;
                        }
                    case 162: {
                            m.roomNoteDeleted = $root.streem.api.events.RoomNoteDeleted.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a WebhookEventPayload message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.WebhookEventPayload} WebhookEventPayload
             */
            WebhookEventPayload.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.WebhookEventPayload)
                    return d;
                var m = new $root.streem.api.WebhookEventPayload();
                if (d.roomCreated != null) {
                    if (typeof d.roomCreated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomCreated: object expected");
                    m.roomCreated = $root.streem.api.events.RoomCreated.fromObject(d.roomCreated);
                }
                if (d.roomUpdated != null) {
                    if (typeof d.roomUpdated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomUpdated: object expected");
                    m.roomUpdated = $root.streem.api.events.RoomUpdated.fromObject(d.roomUpdated);
                }
                if (d.roomArchived != null) {
                    if (typeof d.roomArchived !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomArchived: object expected");
                    m.roomArchived = $root.streem.api.events.RoomArchived.fromObject(d.roomArchived);
                }
                if (d.roomParticipantCreated != null) {
                    if (typeof d.roomParticipantCreated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomParticipantCreated: object expected");
                    m.roomParticipantCreated = $root.streem.api.events.RoomParticipantCreated.fromObject(d.roomParticipantCreated);
                }
                if (d.roomParticipantUpdated != null) {
                    if (typeof d.roomParticipantUpdated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomParticipantUpdated: object expected");
                    m.roomParticipantUpdated = $root.streem.api.events.RoomParticipantUpdated.fromObject(d.roomParticipantUpdated);
                }
                if (d.groupReservationCreated != null) {
                    if (typeof d.groupReservationCreated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.groupReservationCreated: object expected");
                    m.groupReservationCreated = $root.streem.api.events.GroupReservationCreated.fromObject(d.groupReservationCreated);
                }
                if (d.groupReservationUpdated != null) {
                    if (typeof d.groupReservationUpdated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.groupReservationUpdated: object expected");
                    m.groupReservationUpdated = $root.streem.api.events.GroupReservationUpdated.fromObject(d.groupReservationUpdated);
                }
                if (d.artifactCreated != null) {
                    if (typeof d.artifactCreated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.artifactCreated: object expected");
                    m.artifactCreated = $root.streem.api.events.ArtifactCreated.fromObject(d.artifactCreated);
                }
                if (d.artifactUpdated != null) {
                    if (typeof d.artifactUpdated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.artifactUpdated: object expected");
                    m.artifactUpdated = $root.streem.api.events.ArtifactUpdated.fromObject(d.artifactUpdated);
                }
                if (d.artifactDeleted != null) {
                    if (typeof d.artifactDeleted !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.artifactDeleted: object expected");
                    m.artifactDeleted = $root.streem.api.events.ArtifactDeleted.fromObject(d.artifactDeleted);
                }
                if (d.roomNoteCreated != null) {
                    if (typeof d.roomNoteCreated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomNoteCreated: object expected");
                    m.roomNoteCreated = $root.streem.api.events.RoomNoteCreated.fromObject(d.roomNoteCreated);
                }
                if (d.roomNoteUpdated != null) {
                    if (typeof d.roomNoteUpdated !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomNoteUpdated: object expected");
                    m.roomNoteUpdated = $root.streem.api.events.RoomNoteUpdated.fromObject(d.roomNoteUpdated);
                }
                if (d.roomNoteDeleted != null) {
                    if (typeof d.roomNoteDeleted !== "object")
                        throw TypeError(".streem.api.WebhookEventPayload.roomNoteDeleted: object expected");
                    m.roomNoteDeleted = $root.streem.api.events.RoomNoteDeleted.fromObject(d.roomNoteDeleted);
                }
                return m;
            };

            /**
             * Creates a plain object from a WebhookEventPayload message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {streem.api.WebhookEventPayload} m WebhookEventPayload
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WebhookEventPayload.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.roomCreated != null && m.hasOwnProperty("roomCreated")) {
                    d.roomCreated = $root.streem.api.events.RoomCreated.toObject(m.roomCreated, o);
                    if (o.oneofs)
                        d.type = "roomCreated";
                }
                if (m.roomUpdated != null && m.hasOwnProperty("roomUpdated")) {
                    d.roomUpdated = $root.streem.api.events.RoomUpdated.toObject(m.roomUpdated, o);
                    if (o.oneofs)
                        d.type = "roomUpdated";
                }
                if (m.roomArchived != null && m.hasOwnProperty("roomArchived")) {
                    d.roomArchived = $root.streem.api.events.RoomArchived.toObject(m.roomArchived, o);
                    if (o.oneofs)
                        d.type = "roomArchived";
                }
                if (m.roomParticipantCreated != null && m.hasOwnProperty("roomParticipantCreated")) {
                    d.roomParticipantCreated = $root.streem.api.events.RoomParticipantCreated.toObject(m.roomParticipantCreated, o);
                    if (o.oneofs)
                        d.type = "roomParticipantCreated";
                }
                if (m.roomParticipantUpdated != null && m.hasOwnProperty("roomParticipantUpdated")) {
                    d.roomParticipantUpdated = $root.streem.api.events.RoomParticipantUpdated.toObject(m.roomParticipantUpdated, o);
                    if (o.oneofs)
                        d.type = "roomParticipantUpdated";
                }
                if (m.groupReservationCreated != null && m.hasOwnProperty("groupReservationCreated")) {
                    d.groupReservationCreated = $root.streem.api.events.GroupReservationCreated.toObject(m.groupReservationCreated, o);
                    if (o.oneofs)
                        d.type = "groupReservationCreated";
                }
                if (m.groupReservationUpdated != null && m.hasOwnProperty("groupReservationUpdated")) {
                    d.groupReservationUpdated = $root.streem.api.events.GroupReservationUpdated.toObject(m.groupReservationUpdated, o);
                    if (o.oneofs)
                        d.type = "groupReservationUpdated";
                }
                if (m.artifactCreated != null && m.hasOwnProperty("artifactCreated")) {
                    d.artifactCreated = $root.streem.api.events.ArtifactCreated.toObject(m.artifactCreated, o);
                    if (o.oneofs)
                        d.type = "artifactCreated";
                }
                if (m.artifactUpdated != null && m.hasOwnProperty("artifactUpdated")) {
                    d.artifactUpdated = $root.streem.api.events.ArtifactUpdated.toObject(m.artifactUpdated, o);
                    if (o.oneofs)
                        d.type = "artifactUpdated";
                }
                if (m.artifactDeleted != null && m.hasOwnProperty("artifactDeleted")) {
                    d.artifactDeleted = $root.streem.api.events.ArtifactDeleted.toObject(m.artifactDeleted, o);
                    if (o.oneofs)
                        d.type = "artifactDeleted";
                }
                if (m.roomNoteCreated != null && m.hasOwnProperty("roomNoteCreated")) {
                    d.roomNoteCreated = $root.streem.api.events.RoomNoteCreated.toObject(m.roomNoteCreated, o);
                    if (o.oneofs)
                        d.type = "roomNoteCreated";
                }
                if (m.roomNoteUpdated != null && m.hasOwnProperty("roomNoteUpdated")) {
                    d.roomNoteUpdated = $root.streem.api.events.RoomNoteUpdated.toObject(m.roomNoteUpdated, o);
                    if (o.oneofs)
                        d.type = "roomNoteUpdated";
                }
                if (m.roomNoteDeleted != null && m.hasOwnProperty("roomNoteDeleted")) {
                    d.roomNoteDeleted = $root.streem.api.events.RoomNoteDeleted.toObject(m.roomNoteDeleted, o);
                    if (o.oneofs)
                        d.type = "roomNoteDeleted";
                }
                return d;
            };

            /**
             * Converts this WebhookEventPayload to JSON.
             * @function toJSON
             * @memberof streem.api.WebhookEventPayload
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WebhookEventPayload.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WebhookEventPayload
             * @function getTypeUrl
             * @memberof streem.api.WebhookEventPayload
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WebhookEventPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.WebhookEventPayload";
            };

            return WebhookEventPayload;
        })();

        api.global = (function() {

            /**
             * Namespace global.
             * @memberof streem.api
             * @namespace
             */
            var global = {};

            global.GlobalAnalytics = (function() {

                /**
                 * Constructs a new GlobalAnalytics service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalAnalytics
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalAnalytics(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalAnalytics.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalAnalytics;

                /**
                 * Creates new GlobalAnalytics service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalAnalytics
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalAnalytics} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalAnalytics.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalAnalytics#recordEvents}.
                 * @memberof streem.api.global.GlobalAnalytics
                 * @typedef RecordEventsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.RecordEventsResponse} [response] RecordEventsResponse
                 */

                /**
                 * Calls RecordEvents.
                 * @function recordEvents
                 * @memberof streem.api.global.GlobalAnalytics
                 * @instance
                 * @param {streem.api.global.IRecordEventsRequest} request RecordEventsRequest message or plain object
                 * @param {streem.api.global.GlobalAnalytics.RecordEventsCallback} callback Node-style callback called with the error, if any, and RecordEventsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalAnalytics.prototype.recordEvents = function recordEvents(request, callback) {
                    return this.rpcCall(recordEvents, $root.streem.api.global.RecordEventsRequest, $root.streem.api.global.RecordEventsResponse, request, callback);
                }, "name", { value: "RecordEvents" });

                /**
                 * Calls RecordEvents.
                 * @function recordEvents
                 * @memberof streem.api.global.GlobalAnalytics
                 * @instance
                 * @param {streem.api.global.IRecordEventsRequest} request RecordEventsRequest message or plain object
                 * @returns {Promise<streem.api.global.RecordEventsResponse>} Promise
                 * @variation 2
                 */

                return GlobalAnalytics;
            })();

            global.RecordEventsRequest = (function() {

                /**
                 * Properties of a RecordEventsRequest.
                 * @memberof streem.api.global
                 * @interface IRecordEventsRequest
                 * @property {Array.<streem.api.IAnalyticEvent>|null} [events] RecordEventsRequest events
                 */

                /**
                 * Constructs a new RecordEventsRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a RecordEventsRequest.
                 * @implements IRecordEventsRequest
                 * @constructor
                 * @param {streem.api.global.IRecordEventsRequest=} [p] Properties to set
                 */
                function RecordEventsRequest(p) {
                    this.events = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RecordEventsRequest events.
                 * @member {Array.<streem.api.IAnalyticEvent>} events
                 * @memberof streem.api.global.RecordEventsRequest
                 * @instance
                 */
                RecordEventsRequest.prototype.events = $util.emptyArray;

                /**
                 * Creates a new RecordEventsRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {streem.api.global.IRecordEventsRequest=} [properties] Properties to set
                 * @returns {streem.api.global.RecordEventsRequest} RecordEventsRequest instance
                 */
                RecordEventsRequest.create = function create(properties) {
                    return new RecordEventsRequest(properties);
                };

                /**
                 * Encodes the specified RecordEventsRequest message. Does not implicitly {@link streem.api.global.RecordEventsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {streem.api.global.IRecordEventsRequest} m RecordEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordEventsRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.events != null && m.events.length) {
                        for (var i = 0; i < m.events.length; ++i)
                            $root.streem.api.AnalyticEvent.encode(m.events[i], w.uint32(10).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a RecordEventsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.RecordEventsRequest} RecordEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordEventsRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.RecordEventsRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.events && m.events.length))
                                    m.events = [];
                                m.events.push($root.streem.api.AnalyticEvent.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RecordEventsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.RecordEventsRequest} RecordEventsRequest
                 */
                RecordEventsRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.RecordEventsRequest)
                        return d;
                    var m = new $root.streem.api.global.RecordEventsRequest();
                    if (d.events) {
                        if (!Array.isArray(d.events))
                            throw TypeError(".streem.api.global.RecordEventsRequest.events: array expected");
                        m.events = [];
                        for (var i = 0; i < d.events.length; ++i) {
                            if (typeof d.events[i] !== "object")
                                throw TypeError(".streem.api.global.RecordEventsRequest.events: object expected");
                            m.events[i] = $root.streem.api.AnalyticEvent.fromObject(d.events[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RecordEventsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {streem.api.global.RecordEventsRequest} m RecordEventsRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordEventsRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.events = [];
                    }
                    if (m.events && m.events.length) {
                        d.events = [];
                        for (var j = 0; j < m.events.length; ++j) {
                            d.events[j] = $root.streem.api.AnalyticEvent.toObject(m.events[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this RecordEventsRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.RecordEventsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordEventsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RecordEventsRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.RecordEventsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.RecordEventsRequest";
                };

                return RecordEventsRequest;
            })();

            global.RecordEventsResponse = (function() {

                /**
                 * Properties of a RecordEventsResponse.
                 * @memberof streem.api.global
                 * @interface IRecordEventsResponse
                 * @property {Array.<streem.api.global.RecordEventsResponse.IFailure>|null} [failures] RecordEventsResponse failures
                 */

                /**
                 * Constructs a new RecordEventsResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a RecordEventsResponse.
                 * @implements IRecordEventsResponse
                 * @constructor
                 * @param {streem.api.global.IRecordEventsResponse=} [p] Properties to set
                 */
                function RecordEventsResponse(p) {
                    this.failures = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * RecordEventsResponse failures.
                 * @member {Array.<streem.api.global.RecordEventsResponse.IFailure>} failures
                 * @memberof streem.api.global.RecordEventsResponse
                 * @instance
                 */
                RecordEventsResponse.prototype.failures = $util.emptyArray;

                /**
                 * Creates a new RecordEventsResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {streem.api.global.IRecordEventsResponse=} [properties] Properties to set
                 * @returns {streem.api.global.RecordEventsResponse} RecordEventsResponse instance
                 */
                RecordEventsResponse.create = function create(properties) {
                    return new RecordEventsResponse(properties);
                };

                /**
                 * Encodes the specified RecordEventsResponse message. Does not implicitly {@link streem.api.global.RecordEventsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {streem.api.global.IRecordEventsResponse} m RecordEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordEventsResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.failures != null && m.failures.length) {
                        for (var i = 0; i < m.failures.length; ++i)
                            $root.streem.api.global.RecordEventsResponse.Failure.encode(m.failures[i], w.uint32(10).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a RecordEventsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.RecordEventsResponse} RecordEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordEventsResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.RecordEventsResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.failures && m.failures.length))
                                    m.failures = [];
                                m.failures.push($root.streem.api.global.RecordEventsResponse.Failure.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a RecordEventsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.RecordEventsResponse} RecordEventsResponse
                 */
                RecordEventsResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.RecordEventsResponse)
                        return d;
                    var m = new $root.streem.api.global.RecordEventsResponse();
                    if (d.failures) {
                        if (!Array.isArray(d.failures))
                            throw TypeError(".streem.api.global.RecordEventsResponse.failures: array expected");
                        m.failures = [];
                        for (var i = 0; i < d.failures.length; ++i) {
                            if (typeof d.failures[i] !== "object")
                                throw TypeError(".streem.api.global.RecordEventsResponse.failures: object expected");
                            m.failures[i] = $root.streem.api.global.RecordEventsResponse.Failure.fromObject(d.failures[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a RecordEventsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {streem.api.global.RecordEventsResponse} m RecordEventsResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordEventsResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.failures = [];
                    }
                    if (m.failures && m.failures.length) {
                        d.failures = [];
                        for (var j = 0; j < m.failures.length; ++j) {
                            d.failures[j] = $root.streem.api.global.RecordEventsResponse.Failure.toObject(m.failures[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this RecordEventsResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.RecordEventsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordEventsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RecordEventsResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.RecordEventsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.RecordEventsResponse";
                };

                RecordEventsResponse.Failure = (function() {

                    /**
                     * Properties of a Failure.
                     * @memberof streem.api.global.RecordEventsResponse
                     * @interface IFailure
                     * @property {number|null} [index] Failure index
                     * @property {streem.api.IErrorResponse|null} [error] Failure error
                     */

                    /**
                     * Constructs a new Failure.
                     * @memberof streem.api.global.RecordEventsResponse
                     * @classdesc Represents a Failure.
                     * @implements IFailure
                     * @constructor
                     * @param {streem.api.global.RecordEventsResponse.IFailure=} [p] Properties to set
                     */
                    function Failure(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Failure index.
                     * @member {number} index
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @instance
                     */
                    Failure.prototype.index = 0;

                    /**
                     * Failure error.
                     * @member {streem.api.IErrorResponse|null|undefined} error
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @instance
                     */
                    Failure.prototype.error = null;

                    /**
                     * Creates a new Failure instance using the specified properties.
                     * @function create
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {streem.api.global.RecordEventsResponse.IFailure=} [properties] Properties to set
                     * @returns {streem.api.global.RecordEventsResponse.Failure} Failure instance
                     */
                    Failure.create = function create(properties) {
                        return new Failure(properties);
                    };

                    /**
                     * Encodes the specified Failure message. Does not implicitly {@link streem.api.global.RecordEventsResponse.Failure.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {streem.api.global.RecordEventsResponse.IFailure} m Failure message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Failure.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.index != null && Object.hasOwnProperty.call(m, "index"))
                            w.uint32(8).int32(m.index);
                        if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                            $root.streem.api.ErrorResponse.encode(m.error, w.uint32(18).fork()).ldelim();
                        return w;
                    };

                    /**
                     * Decodes a Failure message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.global.RecordEventsResponse.Failure} Failure
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Failure.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.RecordEventsResponse.Failure();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.index = r.int32();
                                    break;
                                }
                            case 2: {
                                    m.error = $root.streem.api.ErrorResponse.decode(r, r.uint32());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Failure message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.global.RecordEventsResponse.Failure} Failure
                     */
                    Failure.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.global.RecordEventsResponse.Failure)
                            return d;
                        var m = new $root.streem.api.global.RecordEventsResponse.Failure();
                        if (d.index != null) {
                            m.index = d.index | 0;
                        }
                        if (d.error != null) {
                            if (typeof d.error !== "object")
                                throw TypeError(".streem.api.global.RecordEventsResponse.Failure.error: object expected");
                            m.error = $root.streem.api.ErrorResponse.fromObject(d.error);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Failure message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {streem.api.global.RecordEventsResponse.Failure} m Failure
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Failure.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.index = 0;
                            d.error = null;
                        }
                        if (m.index != null && m.hasOwnProperty("index")) {
                            d.index = m.index;
                        }
                        if (m.error != null && m.hasOwnProperty("error")) {
                            d.error = $root.streem.api.ErrorResponse.toObject(m.error, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this Failure to JSON.
                     * @function toJSON
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Failure.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Failure
                     * @function getTypeUrl
                     * @memberof streem.api.global.RecordEventsResponse.Failure
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Failure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.global.RecordEventsResponse.Failure";
                    };

                    return Failure;
                })();

                return RecordEventsResponse;
            })();

            global.GlobalCompanies = (function() {

                /**
                 * Constructs a new GlobalCompanies service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalCompanies
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalCompanies(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalCompanies.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalCompanies;

                /**
                 * Creates new GlobalCompanies service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalCompanies
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalCompanies} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalCompanies.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalCompanies#getPublicLoginConfiguration}.
                 * @memberof streem.api.global.GlobalCompanies
                 * @typedef GetPublicLoginConfigurationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetPublicLoginConfigurationResponse} [response] GetPublicLoginConfigurationResponse
                 */

                /**
                 * Calls GetPublicLoginConfiguration.
                 * @function getPublicLoginConfiguration
                 * @memberof streem.api.global.GlobalCompanies
                 * @instance
                 * @param {streem.api.global.IGetPublicLoginConfigurationRequest} request GetPublicLoginConfigurationRequest message or plain object
                 * @param {streem.api.global.GlobalCompanies.GetPublicLoginConfigurationCallback} callback Node-style callback called with the error, if any, and GetPublicLoginConfigurationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalCompanies.prototype.getPublicLoginConfiguration = function getPublicLoginConfiguration(request, callback) {
                    return this.rpcCall(getPublicLoginConfiguration, $root.streem.api.global.GetPublicLoginConfigurationRequest, $root.streem.api.global.GetPublicLoginConfigurationResponse, request, callback);
                }, "name", { value: "GetPublicLoginConfiguration" });

                /**
                 * Calls GetPublicLoginConfiguration.
                 * @function getPublicLoginConfiguration
                 * @memberof streem.api.global.GlobalCompanies
                 * @instance
                 * @param {streem.api.global.IGetPublicLoginConfigurationRequest} request GetPublicLoginConfigurationRequest message or plain object
                 * @returns {Promise<streem.api.global.GetPublicLoginConfigurationResponse>} Promise
                 * @variation 2
                 */

                return GlobalCompanies;
            })();

            global.GetPublicLoginConfigurationRequest = (function() {

                /**
                 * Properties of a GetPublicLoginConfigurationRequest.
                 * @memberof streem.api.global
                 * @interface IGetPublicLoginConfigurationRequest
                 * @property {string|null} [companyCode] GetPublicLoginConfigurationRequest companyCode
                 */

                /**
                 * Constructs a new GetPublicLoginConfigurationRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetPublicLoginConfigurationRequest.
                 * @implements IGetPublicLoginConfigurationRequest
                 * @constructor
                 * @param {streem.api.global.IGetPublicLoginConfigurationRequest=} [p] Properties to set
                 */
                function GetPublicLoginConfigurationRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetPublicLoginConfigurationRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @instance
                 */
                GetPublicLoginConfigurationRequest.prototype.companyCode = "";

                /**
                 * Creates a new GetPublicLoginConfigurationRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {streem.api.global.IGetPublicLoginConfigurationRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest instance
                 */
                GetPublicLoginConfigurationRequest.create = function create(properties) {
                    return new GetPublicLoginConfigurationRequest(properties);
                };

                /**
                 * Encodes the specified GetPublicLoginConfigurationRequest message. Does not implicitly {@link streem.api.global.GetPublicLoginConfigurationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {streem.api.global.IGetPublicLoginConfigurationRequest} m GetPublicLoginConfigurationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicLoginConfigurationRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(10).string(m.companyCode);
                    return w;
                };

                /**
                 * Decodes a GetPublicLoginConfigurationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicLoginConfigurationRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetPublicLoginConfigurationRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.companyCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetPublicLoginConfigurationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest
                 */
                GetPublicLoginConfigurationRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetPublicLoginConfigurationRequest)
                        return d;
                    var m = new $root.streem.api.global.GetPublicLoginConfigurationRequest();
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetPublicLoginConfigurationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {streem.api.global.GetPublicLoginConfigurationRequest} m GetPublicLoginConfigurationRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPublicLoginConfigurationRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyCode = "";
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    return d;
                };

                /**
                 * Converts this GetPublicLoginConfigurationRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPublicLoginConfigurationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetPublicLoginConfigurationRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetPublicLoginConfigurationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPublicLoginConfigurationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetPublicLoginConfigurationRequest";
                };

                return GetPublicLoginConfigurationRequest;
            })();

            global.GetPublicLoginConfigurationResponse = (function() {

                /**
                 * Properties of a GetPublicLoginConfigurationResponse.
                 * @memberof streem.api.global
                 * @interface IGetPublicLoginConfigurationResponse
                 * @property {streem.api.IPublicLoginConfiguration|null} [loginConfiguration] GetPublicLoginConfigurationResponse loginConfiguration
                 */

                /**
                 * Constructs a new GetPublicLoginConfigurationResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetPublicLoginConfigurationResponse.
                 * @implements IGetPublicLoginConfigurationResponse
                 * @constructor
                 * @param {streem.api.global.IGetPublicLoginConfigurationResponse=} [p] Properties to set
                 */
                function GetPublicLoginConfigurationResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetPublicLoginConfigurationResponse loginConfiguration.
                 * @member {streem.api.IPublicLoginConfiguration|null|undefined} loginConfiguration
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @instance
                 */
                GetPublicLoginConfigurationResponse.prototype.loginConfiguration = null;

                /**
                 * Creates a new GetPublicLoginConfigurationResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {streem.api.global.IGetPublicLoginConfigurationResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse instance
                 */
                GetPublicLoginConfigurationResponse.create = function create(properties) {
                    return new GetPublicLoginConfigurationResponse(properties);
                };

                /**
                 * Encodes the specified GetPublicLoginConfigurationResponse message. Does not implicitly {@link streem.api.global.GetPublicLoginConfigurationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {streem.api.global.IGetPublicLoginConfigurationResponse} m GetPublicLoginConfigurationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicLoginConfigurationResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.loginConfiguration != null && Object.hasOwnProperty.call(m, "loginConfiguration"))
                        $root.streem.api.PublicLoginConfiguration.encode(m.loginConfiguration, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetPublicLoginConfigurationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicLoginConfigurationResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetPublicLoginConfigurationResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetPublicLoginConfigurationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse
                 */
                GetPublicLoginConfigurationResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetPublicLoginConfigurationResponse)
                        return d;
                    var m = new $root.streem.api.global.GetPublicLoginConfigurationResponse();
                    if (d.loginConfiguration != null) {
                        if (typeof d.loginConfiguration !== "object")
                            throw TypeError(".streem.api.global.GetPublicLoginConfigurationResponse.loginConfiguration: object expected");
                        m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.fromObject(d.loginConfiguration);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetPublicLoginConfigurationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {streem.api.global.GetPublicLoginConfigurationResponse} m GetPublicLoginConfigurationResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPublicLoginConfigurationResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.loginConfiguration = null;
                    }
                    if (m.loginConfiguration != null && m.hasOwnProperty("loginConfiguration")) {
                        d.loginConfiguration = $root.streem.api.PublicLoginConfiguration.toObject(m.loginConfiguration, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetPublicLoginConfigurationResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPublicLoginConfigurationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetPublicLoginConfigurationResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetPublicLoginConfigurationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPublicLoginConfigurationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetPublicLoginConfigurationResponse";
                };

                return GetPublicLoginConfigurationResponse;
            })();

            global.CompanyRegistration = (function() {

                /**
                 * Properties of a CompanyRegistration.
                 * @memberof streem.api.global
                 * @interface ICompanyRegistration
                 * @property {string|null} [companyCode] CompanyRegistration companyCode
                 * @property {string|null} [regionCode] CompanyRegistration regionCode
                 * @property {string|null} [companyName] CompanyRegistration companyName
                 * @property {string|null} [companyLogoUrl] CompanyRegistration companyLogoUrl
                 * @property {boolean|null} [switchWorkspacesEnabled] CompanyRegistration switchWorkspacesEnabled
                 * @property {string|null} [companySid] CompanyRegistration companySid
                 * @property {boolean|null} [companyActive] CompanyRegistration companyActive
                 */

                /**
                 * Constructs a new CompanyRegistration.
                 * @memberof streem.api.global
                 * @classdesc Represents a CompanyRegistration.
                 * @implements ICompanyRegistration
                 * @constructor
                 * @param {streem.api.global.ICompanyRegistration=} [p] Properties to set
                 */
                function CompanyRegistration(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CompanyRegistration companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.companyCode = "";

                /**
                 * CompanyRegistration regionCode.
                 * @member {string} regionCode
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.regionCode = "";

                /**
                 * CompanyRegistration companyName.
                 * @member {string} companyName
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.companyName = "";

                /**
                 * CompanyRegistration companyLogoUrl.
                 * @member {string} companyLogoUrl
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.companyLogoUrl = "";

                /**
                 * CompanyRegistration switchWorkspacesEnabled.
                 * @member {boolean} switchWorkspacesEnabled
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.switchWorkspacesEnabled = false;

                /**
                 * CompanyRegistration companySid.
                 * @member {string} companySid
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.companySid = "";

                /**
                 * CompanyRegistration companyActive.
                 * @member {boolean} companyActive
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 */
                CompanyRegistration.prototype.companyActive = false;

                /**
                 * Creates a new CompanyRegistration instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {streem.api.global.ICompanyRegistration=} [properties] Properties to set
                 * @returns {streem.api.global.CompanyRegistration} CompanyRegistration instance
                 */
                CompanyRegistration.create = function create(properties) {
                    return new CompanyRegistration(properties);
                };

                /**
                 * Encodes the specified CompanyRegistration message. Does not implicitly {@link streem.api.global.CompanyRegistration.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {streem.api.global.ICompanyRegistration} m CompanyRegistration message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompanyRegistration.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(10).string(m.companyCode);
                    if (m.regionCode != null && Object.hasOwnProperty.call(m, "regionCode"))
                        w.uint32(18).string(m.regionCode);
                    if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                        w.uint32(26).string(m.companyName);
                    if (m.companyLogoUrl != null && Object.hasOwnProperty.call(m, "companyLogoUrl"))
                        w.uint32(34).string(m.companyLogoUrl);
                    if (m.switchWorkspacesEnabled != null && Object.hasOwnProperty.call(m, "switchWorkspacesEnabled"))
                        w.uint32(40).bool(m.switchWorkspacesEnabled);
                    if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                        w.uint32(50).string(m.companySid);
                    if (m.companyActive != null && Object.hasOwnProperty.call(m, "companyActive"))
                        w.uint32(56).bool(m.companyActive);
                    return w;
                };

                /**
                 * Decodes a CompanyRegistration message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.CompanyRegistration} CompanyRegistration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompanyRegistration.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.CompanyRegistration();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 2: {
                                m.regionCode = r.string();
                                break;
                            }
                        case 3: {
                                m.companyName = r.string();
                                break;
                            }
                        case 4: {
                                m.companyLogoUrl = r.string();
                                break;
                            }
                        case 5: {
                                m.switchWorkspacesEnabled = r.bool();
                                break;
                            }
                        case 6: {
                                m.companySid = r.string();
                                break;
                            }
                        case 7: {
                                m.companyActive = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CompanyRegistration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.CompanyRegistration} CompanyRegistration
                 */
                CompanyRegistration.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.CompanyRegistration)
                        return d;
                    var m = new $root.streem.api.global.CompanyRegistration();
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.regionCode != null) {
                        m.regionCode = String(d.regionCode);
                    }
                    if (d.companyName != null) {
                        m.companyName = String(d.companyName);
                    }
                    if (d.companyLogoUrl != null) {
                        m.companyLogoUrl = String(d.companyLogoUrl);
                    }
                    if (d.switchWorkspacesEnabled != null) {
                        m.switchWorkspacesEnabled = Boolean(d.switchWorkspacesEnabled);
                    }
                    if (d.companySid != null) {
                        m.companySid = String(d.companySid);
                    }
                    if (d.companyActive != null) {
                        m.companyActive = Boolean(d.companyActive);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CompanyRegistration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {streem.api.global.CompanyRegistration} m CompanyRegistration
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyRegistration.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyCode = "";
                        d.regionCode = "";
                        d.companyName = "";
                        d.companyLogoUrl = "";
                        d.switchWorkspacesEnabled = false;
                        d.companySid = "";
                        d.companyActive = false;
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.regionCode != null && m.hasOwnProperty("regionCode")) {
                        d.regionCode = m.regionCode;
                    }
                    if (m.companyName != null && m.hasOwnProperty("companyName")) {
                        d.companyName = m.companyName;
                    }
                    if (m.companyLogoUrl != null && m.hasOwnProperty("companyLogoUrl")) {
                        d.companyLogoUrl = m.companyLogoUrl;
                    }
                    if (m.switchWorkspacesEnabled != null && m.hasOwnProperty("switchWorkspacesEnabled")) {
                        d.switchWorkspacesEnabled = m.switchWorkspacesEnabled;
                    }
                    if (m.companySid != null && m.hasOwnProperty("companySid")) {
                        d.companySid = m.companySid;
                    }
                    if (m.companyActive != null && m.hasOwnProperty("companyActive")) {
                        d.companyActive = m.companyActive;
                    }
                    return d;
                };

                /**
                 * Converts this CompanyRegistration to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.CompanyRegistration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyRegistration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CompanyRegistration
                 * @function getTypeUrl
                 * @memberof streem.api.global.CompanyRegistration
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompanyRegistration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.CompanyRegistration";
                };

                return CompanyRegistration;
            })();

            global.AppRegistration = (function() {

                /**
                 * Properties of an AppRegistration.
                 * @memberof streem.api.global
                 * @interface IAppRegistration
                 * @property {string|null} [appSid] AppRegistration appSid
                 * @property {string|null} [regionCode] AppRegistration regionCode
                 * @property {streem.api.AppPlatform|null} [platform] AppRegistration platform
                 * @property {string|null} [platformAppId] AppRegistration platformAppId
                 * @property {boolean|null} [multiCompanyLoginAllowed] AppRegistration multiCompanyLoginAllowed
                 */

                /**
                 * Constructs a new AppRegistration.
                 * @memberof streem.api.global
                 * @classdesc Represents an AppRegistration.
                 * @implements IAppRegistration
                 * @constructor
                 * @param {streem.api.global.IAppRegistration=} [p] Properties to set
                 */
                function AppRegistration(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * AppRegistration appSid.
                 * @member {string} appSid
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 */
                AppRegistration.prototype.appSid = "";

                /**
                 * AppRegistration regionCode.
                 * @member {string} regionCode
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 */
                AppRegistration.prototype.regionCode = "";

                /**
                 * AppRegistration platform.
                 * @member {streem.api.AppPlatform} platform
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 */
                AppRegistration.prototype.platform = 0;

                /**
                 * AppRegistration platformAppId.
                 * @member {string} platformAppId
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 */
                AppRegistration.prototype.platformAppId = "";

                /**
                 * AppRegistration multiCompanyLoginAllowed.
                 * @member {boolean} multiCompanyLoginAllowed
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 */
                AppRegistration.prototype.multiCompanyLoginAllowed = false;

                /**
                 * Creates a new AppRegistration instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {streem.api.global.IAppRegistration=} [properties] Properties to set
                 * @returns {streem.api.global.AppRegistration} AppRegistration instance
                 */
                AppRegistration.create = function create(properties) {
                    return new AppRegistration(properties);
                };

                /**
                 * Encodes the specified AppRegistration message. Does not implicitly {@link streem.api.global.AppRegistration.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {streem.api.global.IAppRegistration} m AppRegistration message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppRegistration.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                        w.uint32(10).string(m.appSid);
                    if (m.regionCode != null && Object.hasOwnProperty.call(m, "regionCode"))
                        w.uint32(18).string(m.regionCode);
                    if (m.platform != null && Object.hasOwnProperty.call(m, "platform"))
                        w.uint32(24).int32(m.platform);
                    if (m.platformAppId != null && Object.hasOwnProperty.call(m, "platformAppId"))
                        w.uint32(34).string(m.platformAppId);
                    if (m.multiCompanyLoginAllowed != null && Object.hasOwnProperty.call(m, "multiCompanyLoginAllowed"))
                        w.uint32(40).bool(m.multiCompanyLoginAllowed);
                    return w;
                };

                /**
                 * Decodes an AppRegistration message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.AppRegistration} AppRegistration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppRegistration.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.AppRegistration();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.appSid = r.string();
                                break;
                            }
                        case 2: {
                                m.regionCode = r.string();
                                break;
                            }
                        case 3: {
                                m.platform = r.int32();
                                break;
                            }
                        case 4: {
                                m.platformAppId = r.string();
                                break;
                            }
                        case 5: {
                                m.multiCompanyLoginAllowed = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an AppRegistration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.AppRegistration} AppRegistration
                 */
                AppRegistration.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.AppRegistration)
                        return d;
                    var m = new $root.streem.api.global.AppRegistration();
                    if (d.appSid != null) {
                        m.appSid = String(d.appSid);
                    }
                    if (d.regionCode != null) {
                        m.regionCode = String(d.regionCode);
                    }
                    switch (d.platform) {
                    default:
                        if (typeof d.platform === "number") {
                            m.platform = d.platform;
                            break;
                        }
                        break;
                    case "APP_PLATFORM_INVALID":
                    case 0:
                        m.platform = 0;
                        break;
                    case "APP_PLATFORM_IOS":
                    case 1:
                        m.platform = 1;
                        break;
                    case "APP_PLATFORM_ANDROID":
                    case 2:
                        m.platform = 2;
                        break;
                    case "APP_PLATFORM_WEB":
                    case 3:
                        m.platform = 3;
                        break;
                    }
                    if (d.platformAppId != null) {
                        m.platformAppId = String(d.platformAppId);
                    }
                    if (d.multiCompanyLoginAllowed != null) {
                        m.multiCompanyLoginAllowed = Boolean(d.multiCompanyLoginAllowed);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an AppRegistration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {streem.api.global.AppRegistration} m AppRegistration
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppRegistration.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.appSid = "";
                        d.regionCode = "";
                        d.platform = o.enums === String ? "APP_PLATFORM_INVALID" : 0;
                        d.platformAppId = "";
                        d.multiCompanyLoginAllowed = false;
                    }
                    if (m.appSid != null && m.hasOwnProperty("appSid")) {
                        d.appSid = m.appSid;
                    }
                    if (m.regionCode != null && m.hasOwnProperty("regionCode")) {
                        d.regionCode = m.regionCode;
                    }
                    if (m.platform != null && m.hasOwnProperty("platform")) {
                        d.platform = o.enums === String ? $root.streem.api.AppPlatform[m.platform] === undefined ? m.platform : $root.streem.api.AppPlatform[m.platform] : m.platform;
                    }
                    if (m.platformAppId != null && m.hasOwnProperty("platformAppId")) {
                        d.platformAppId = m.platformAppId;
                    }
                    if (m.multiCompanyLoginAllowed != null && m.hasOwnProperty("multiCompanyLoginAllowed")) {
                        d.multiCompanyLoginAllowed = m.multiCompanyLoginAllowed;
                    }
                    return d;
                };

                /**
                 * Converts this AppRegistration to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.AppRegistration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppRegistration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AppRegistration
                 * @function getTypeUrl
                 * @memberof streem.api.global.AppRegistration
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AppRegistration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.AppRegistration";
                };

                return AppRegistration;
            })();

            global.GlobalDirectory = (function() {

                /**
                 * Constructs a new GlobalDirectory service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalDirectory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalDirectory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalDirectory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalDirectory;

                /**
                 * Creates new GlobalDirectory service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalDirectory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalDirectory} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalDirectory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalDirectory#getCompanyRegistration}.
                 * @memberof streem.api.global.GlobalDirectory
                 * @typedef GetCompanyRegistrationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetCompanyRegistrationResponse} [response] GetCompanyRegistrationResponse
                 */

                /**
                 * Calls GetCompanyRegistration.
                 * @function getCompanyRegistration
                 * @memberof streem.api.global.GlobalDirectory
                 * @instance
                 * @param {streem.api.global.IGetCompanyRegistrationRequest} request GetCompanyRegistrationRequest message or plain object
                 * @param {streem.api.global.GlobalDirectory.GetCompanyRegistrationCallback} callback Node-style callback called with the error, if any, and GetCompanyRegistrationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalDirectory.prototype.getCompanyRegistration = function getCompanyRegistration(request, callback) {
                    return this.rpcCall(getCompanyRegistration, $root.streem.api.global.GetCompanyRegistrationRequest, $root.streem.api.global.GetCompanyRegistrationResponse, request, callback);
                }, "name", { value: "GetCompanyRegistration" });

                /**
                 * Calls GetCompanyRegistration.
                 * @function getCompanyRegistration
                 * @memberof streem.api.global.GlobalDirectory
                 * @instance
                 * @param {streem.api.global.IGetCompanyRegistrationRequest} request GetCompanyRegistrationRequest message or plain object
                 * @returns {Promise<streem.api.global.GetCompanyRegistrationResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalDirectory#getAppRegistration}.
                 * @memberof streem.api.global.GlobalDirectory
                 * @typedef GetAppRegistrationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetAppRegistrationResponse} [response] GetAppRegistrationResponse
                 */

                /**
                 * Calls GetAppRegistration.
                 * @function getAppRegistration
                 * @memberof streem.api.global.GlobalDirectory
                 * @instance
                 * @param {streem.api.global.IGetAppRegistrationRequest} request GetAppRegistrationRequest message or plain object
                 * @param {streem.api.global.GlobalDirectory.GetAppRegistrationCallback} callback Node-style callback called with the error, if any, and GetAppRegistrationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalDirectory.prototype.getAppRegistration = function getAppRegistration(request, callback) {
                    return this.rpcCall(getAppRegistration, $root.streem.api.global.GetAppRegistrationRequest, $root.streem.api.global.GetAppRegistrationResponse, request, callback);
                }, "name", { value: "GetAppRegistration" });

                /**
                 * Calls GetAppRegistration.
                 * @function getAppRegistration
                 * @memberof streem.api.global.GlobalDirectory
                 * @instance
                 * @param {streem.api.global.IGetAppRegistrationRequest} request GetAppRegistrationRequest message or plain object
                 * @returns {Promise<streem.api.global.GetAppRegistrationResponse>} Promise
                 * @variation 2
                 */

                return GlobalDirectory;
            })();

            global.GetCompanyRegistrationResponse = (function() {

                /**
                 * Properties of a GetCompanyRegistrationResponse.
                 * @memberof streem.api.global
                 * @interface IGetCompanyRegistrationResponse
                 * @property {streem.api.global.ICompanyRegistration|null} [companyRegistration] GetCompanyRegistrationResponse companyRegistration
                 * @property {string|null} [apiUrl] GetCompanyRegistrationResponse apiUrl
                 * @property {string|null} [loggingUrl] GetCompanyRegistrationResponse loggingUrl
                 * @property {string|null} [wallUrl] GetCompanyRegistrationResponse wallUrl
                 */

                /**
                 * Constructs a new GetCompanyRegistrationResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetCompanyRegistrationResponse.
                 * @implements IGetCompanyRegistrationResponse
                 * @constructor
                 * @param {streem.api.global.IGetCompanyRegistrationResponse=} [p] Properties to set
                 */
                function GetCompanyRegistrationResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetCompanyRegistrationResponse companyRegistration.
                 * @member {streem.api.global.ICompanyRegistration|null|undefined} companyRegistration
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @instance
                 */
                GetCompanyRegistrationResponse.prototype.companyRegistration = null;

                /**
                 * GetCompanyRegistrationResponse apiUrl.
                 * @member {string} apiUrl
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @instance
                 */
                GetCompanyRegistrationResponse.prototype.apiUrl = "";

                /**
                 * GetCompanyRegistrationResponse loggingUrl.
                 * @member {string} loggingUrl
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @instance
                 */
                GetCompanyRegistrationResponse.prototype.loggingUrl = "";

                /**
                 * GetCompanyRegistrationResponse wallUrl.
                 * @member {string} wallUrl
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @instance
                 */
                GetCompanyRegistrationResponse.prototype.wallUrl = "";

                /**
                 * Creates a new GetCompanyRegistrationResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {streem.api.global.IGetCompanyRegistrationResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetCompanyRegistrationResponse} GetCompanyRegistrationResponse instance
                 */
                GetCompanyRegistrationResponse.create = function create(properties) {
                    return new GetCompanyRegistrationResponse(properties);
                };

                /**
                 * Encodes the specified GetCompanyRegistrationResponse message. Does not implicitly {@link streem.api.global.GetCompanyRegistrationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {streem.api.global.IGetCompanyRegistrationResponse} m GetCompanyRegistrationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCompanyRegistrationResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyRegistration != null && Object.hasOwnProperty.call(m, "companyRegistration"))
                        $root.streem.api.global.CompanyRegistration.encode(m.companyRegistration, w.uint32(10).fork()).ldelim();
                    if (m.apiUrl != null && Object.hasOwnProperty.call(m, "apiUrl"))
                        w.uint32(18).string(m.apiUrl);
                    if (m.loggingUrl != null && Object.hasOwnProperty.call(m, "loggingUrl"))
                        w.uint32(26).string(m.loggingUrl);
                    if (m.wallUrl != null && Object.hasOwnProperty.call(m, "wallUrl"))
                        w.uint32(34).string(m.wallUrl);
                    return w;
                };

                /**
                 * Decodes a GetCompanyRegistrationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetCompanyRegistrationResponse} GetCompanyRegistrationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCompanyRegistrationResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetCompanyRegistrationResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.companyRegistration = $root.streem.api.global.CompanyRegistration.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.apiUrl = r.string();
                                break;
                            }
                        case 3: {
                                m.loggingUrl = r.string();
                                break;
                            }
                        case 4: {
                                m.wallUrl = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetCompanyRegistrationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetCompanyRegistrationResponse} GetCompanyRegistrationResponse
                 */
                GetCompanyRegistrationResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetCompanyRegistrationResponse)
                        return d;
                    var m = new $root.streem.api.global.GetCompanyRegistrationResponse();
                    if (d.companyRegistration != null) {
                        if (typeof d.companyRegistration !== "object")
                            throw TypeError(".streem.api.global.GetCompanyRegistrationResponse.companyRegistration: object expected");
                        m.companyRegistration = $root.streem.api.global.CompanyRegistration.fromObject(d.companyRegistration);
                    }
                    if (d.apiUrl != null) {
                        m.apiUrl = String(d.apiUrl);
                    }
                    if (d.loggingUrl != null) {
                        m.loggingUrl = String(d.loggingUrl);
                    }
                    if (d.wallUrl != null) {
                        m.wallUrl = String(d.wallUrl);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetCompanyRegistrationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {streem.api.global.GetCompanyRegistrationResponse} m GetCompanyRegistrationResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCompanyRegistrationResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyRegistration = null;
                        d.apiUrl = "";
                        d.loggingUrl = "";
                        d.wallUrl = "";
                    }
                    if (m.companyRegistration != null && m.hasOwnProperty("companyRegistration")) {
                        d.companyRegistration = $root.streem.api.global.CompanyRegistration.toObject(m.companyRegistration, o);
                    }
                    if (m.apiUrl != null && m.hasOwnProperty("apiUrl")) {
                        d.apiUrl = m.apiUrl;
                    }
                    if (m.loggingUrl != null && m.hasOwnProperty("loggingUrl")) {
                        d.loggingUrl = m.loggingUrl;
                    }
                    if (m.wallUrl != null && m.hasOwnProperty("wallUrl")) {
                        d.wallUrl = m.wallUrl;
                    }
                    return d;
                };

                /**
                 * Converts this GetCompanyRegistrationResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCompanyRegistrationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCompanyRegistrationResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetCompanyRegistrationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCompanyRegistrationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetCompanyRegistrationResponse";
                };

                return GetCompanyRegistrationResponse;
            })();

            global.GetCompanyRegistrationRequest = (function() {

                /**
                 * Properties of a GetCompanyRegistrationRequest.
                 * @memberof streem.api.global
                 * @interface IGetCompanyRegistrationRequest
                 * @property {string|null} [companyCode] GetCompanyRegistrationRequest companyCode
                 * @property {string|null} [forClient] GetCompanyRegistrationRequest forClient
                 */

                /**
                 * Constructs a new GetCompanyRegistrationRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetCompanyRegistrationRequest.
                 * @implements IGetCompanyRegistrationRequest
                 * @constructor
                 * @param {streem.api.global.IGetCompanyRegistrationRequest=} [p] Properties to set
                 */
                function GetCompanyRegistrationRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetCompanyRegistrationRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @instance
                 */
                GetCompanyRegistrationRequest.prototype.companyCode = "";

                /**
                 * GetCompanyRegistrationRequest forClient.
                 * @member {string} forClient
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @instance
                 */
                GetCompanyRegistrationRequest.prototype.forClient = "";

                /**
                 * Creates a new GetCompanyRegistrationRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {streem.api.global.IGetCompanyRegistrationRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetCompanyRegistrationRequest} GetCompanyRegistrationRequest instance
                 */
                GetCompanyRegistrationRequest.create = function create(properties) {
                    return new GetCompanyRegistrationRequest(properties);
                };

                /**
                 * Encodes the specified GetCompanyRegistrationRequest message. Does not implicitly {@link streem.api.global.GetCompanyRegistrationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {streem.api.global.IGetCompanyRegistrationRequest} m GetCompanyRegistrationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCompanyRegistrationRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(10).string(m.companyCode);
                    if (m.forClient != null && Object.hasOwnProperty.call(m, "forClient"))
                        w.uint32(18).string(m.forClient);
                    return w;
                };

                /**
                 * Decodes a GetCompanyRegistrationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetCompanyRegistrationRequest} GetCompanyRegistrationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCompanyRegistrationRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetCompanyRegistrationRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 2: {
                                m.forClient = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetCompanyRegistrationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetCompanyRegistrationRequest} GetCompanyRegistrationRequest
                 */
                GetCompanyRegistrationRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetCompanyRegistrationRequest)
                        return d;
                    var m = new $root.streem.api.global.GetCompanyRegistrationRequest();
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.forClient != null) {
                        m.forClient = String(d.forClient);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetCompanyRegistrationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {streem.api.global.GetCompanyRegistrationRequest} m GetCompanyRegistrationRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCompanyRegistrationRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyCode = "";
                        d.forClient = "";
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.forClient != null && m.hasOwnProperty("forClient")) {
                        d.forClient = m.forClient;
                    }
                    return d;
                };

                /**
                 * Converts this GetCompanyRegistrationRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCompanyRegistrationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCompanyRegistrationRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetCompanyRegistrationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCompanyRegistrationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetCompanyRegistrationRequest";
                };

                return GetCompanyRegistrationRequest;
            })();

            global.GetAppRegistrationRequest = (function() {

                /**
                 * Properties of a GetAppRegistrationRequest.
                 * @memberof streem.api.global
                 * @interface IGetAppRegistrationRequest
                 * @property {string|null} [appSid] GetAppRegistrationRequest appSid
                 */

                /**
                 * Constructs a new GetAppRegistrationRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetAppRegistrationRequest.
                 * @implements IGetAppRegistrationRequest
                 * @constructor
                 * @param {streem.api.global.IGetAppRegistrationRequest=} [p] Properties to set
                 */
                function GetAppRegistrationRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetAppRegistrationRequest appSid.
                 * @member {string} appSid
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @instance
                 */
                GetAppRegistrationRequest.prototype.appSid = "";

                /**
                 * Creates a new GetAppRegistrationRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {streem.api.global.IGetAppRegistrationRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetAppRegistrationRequest} GetAppRegistrationRequest instance
                 */
                GetAppRegistrationRequest.create = function create(properties) {
                    return new GetAppRegistrationRequest(properties);
                };

                /**
                 * Encodes the specified GetAppRegistrationRequest message. Does not implicitly {@link streem.api.global.GetAppRegistrationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {streem.api.global.IGetAppRegistrationRequest} m GetAppRegistrationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAppRegistrationRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.appSid != null && Object.hasOwnProperty.call(m, "appSid"))
                        w.uint32(10).string(m.appSid);
                    return w;
                };

                /**
                 * Decodes a GetAppRegistrationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetAppRegistrationRequest} GetAppRegistrationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAppRegistrationRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetAppRegistrationRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.appSid = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetAppRegistrationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetAppRegistrationRequest} GetAppRegistrationRequest
                 */
                GetAppRegistrationRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetAppRegistrationRequest)
                        return d;
                    var m = new $root.streem.api.global.GetAppRegistrationRequest();
                    if (d.appSid != null) {
                        m.appSid = String(d.appSid);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetAppRegistrationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {streem.api.global.GetAppRegistrationRequest} m GetAppRegistrationRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAppRegistrationRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.appSid = "";
                    }
                    if (m.appSid != null && m.hasOwnProperty("appSid")) {
                        d.appSid = m.appSid;
                    }
                    return d;
                };

                /**
                 * Converts this GetAppRegistrationRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAppRegistrationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetAppRegistrationRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetAppRegistrationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAppRegistrationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetAppRegistrationRequest";
                };

                return GetAppRegistrationRequest;
            })();

            global.GetAppRegistrationResponse = (function() {

                /**
                 * Properties of a GetAppRegistrationResponse.
                 * @memberof streem.api.global
                 * @interface IGetAppRegistrationResponse
                 * @property {streem.api.global.IAppRegistration|null} [appRegistration] GetAppRegistrationResponse appRegistration
                 */

                /**
                 * Constructs a new GetAppRegistrationResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetAppRegistrationResponse.
                 * @implements IGetAppRegistrationResponse
                 * @constructor
                 * @param {streem.api.global.IGetAppRegistrationResponse=} [p] Properties to set
                 */
                function GetAppRegistrationResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetAppRegistrationResponse appRegistration.
                 * @member {streem.api.global.IAppRegistration|null|undefined} appRegistration
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @instance
                 */
                GetAppRegistrationResponse.prototype.appRegistration = null;

                /**
                 * Creates a new GetAppRegistrationResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {streem.api.global.IGetAppRegistrationResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetAppRegistrationResponse} GetAppRegistrationResponse instance
                 */
                GetAppRegistrationResponse.create = function create(properties) {
                    return new GetAppRegistrationResponse(properties);
                };

                /**
                 * Encodes the specified GetAppRegistrationResponse message. Does not implicitly {@link streem.api.global.GetAppRegistrationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {streem.api.global.IGetAppRegistrationResponse} m GetAppRegistrationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAppRegistrationResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.appRegistration != null && Object.hasOwnProperty.call(m, "appRegistration"))
                        $root.streem.api.global.AppRegistration.encode(m.appRegistration, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetAppRegistrationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetAppRegistrationResponse} GetAppRegistrationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAppRegistrationResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetAppRegistrationResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.appRegistration = $root.streem.api.global.AppRegistration.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetAppRegistrationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetAppRegistrationResponse} GetAppRegistrationResponse
                 */
                GetAppRegistrationResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetAppRegistrationResponse)
                        return d;
                    var m = new $root.streem.api.global.GetAppRegistrationResponse();
                    if (d.appRegistration != null) {
                        if (typeof d.appRegistration !== "object")
                            throw TypeError(".streem.api.global.GetAppRegistrationResponse.appRegistration: object expected");
                        m.appRegistration = $root.streem.api.global.AppRegistration.fromObject(d.appRegistration);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetAppRegistrationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {streem.api.global.GetAppRegistrationResponse} m GetAppRegistrationResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAppRegistrationResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.appRegistration = null;
                    }
                    if (m.appRegistration != null && m.hasOwnProperty("appRegistration")) {
                        d.appRegistration = $root.streem.api.global.AppRegistration.toObject(m.appRegistration, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetAppRegistrationResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAppRegistrationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetAppRegistrationResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetAppRegistrationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAppRegistrationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetAppRegistrationResponse";
                };

                return GetAppRegistrationResponse;
            })();

            global.GlobalIntegration = (function() {

                /**
                 * Constructs a new GlobalIntegration service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalIntegration
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalIntegration(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalIntegration.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalIntegration;

                /**
                 * Creates new GlobalIntegration service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalIntegration
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalIntegration} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalIntegration.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalIntegration#createSalesforceIntegrationToken}.
                 * @memberof streem.api.global.GlobalIntegration
                 * @typedef CreateSalesforceIntegrationTokenCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.CreateSalesforceIntegrationTokenResponse} [response] CreateSalesforceIntegrationTokenResponse
                 */

                /**
                 * Calls CreateSalesforceIntegrationToken.
                 * @function createSalesforceIntegrationToken
                 * @memberof streem.api.global.GlobalIntegration
                 * @instance
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenRequest} request CreateSalesforceIntegrationTokenRequest message or plain object
                 * @param {streem.api.global.GlobalIntegration.CreateSalesforceIntegrationTokenCallback} callback Node-style callback called with the error, if any, and CreateSalesforceIntegrationTokenResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalIntegration.prototype.createSalesforceIntegrationToken = function createSalesforceIntegrationToken(request, callback) {
                    return this.rpcCall(createSalesforceIntegrationToken, $root.streem.api.global.CreateSalesforceIntegrationTokenRequest, $root.streem.api.global.CreateSalesforceIntegrationTokenResponse, request, callback);
                }, "name", { value: "CreateSalesforceIntegrationToken" });

                /**
                 * Calls CreateSalesforceIntegrationToken.
                 * @function createSalesforceIntegrationToken
                 * @memberof streem.api.global.GlobalIntegration
                 * @instance
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenRequest} request CreateSalesforceIntegrationTokenRequest message or plain object
                 * @returns {Promise<streem.api.global.CreateSalesforceIntegrationTokenResponse>} Promise
                 * @variation 2
                 */

                return GlobalIntegration;
            })();

            global.CreateSalesforceIntegrationTokenRequest = (function() {

                /**
                 * Properties of a CreateSalesforceIntegrationTokenRequest.
                 * @memberof streem.api.global
                 * @interface ICreateSalesforceIntegrationTokenRequest
                 * @property {string|null} [companyCode] CreateSalesforceIntegrationTokenRequest companyCode
                 * @property {string|null} [redirect] CreateSalesforceIntegrationTokenRequest redirect
                 * @property {string|null} [signedRequest] CreateSalesforceIntegrationTokenRequest signedRequest
                 */

                /**
                 * Constructs a new CreateSalesforceIntegrationTokenRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a CreateSalesforceIntegrationTokenRequest.
                 * @implements ICreateSalesforceIntegrationTokenRequest
                 * @constructor
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenRequest=} [p] Properties to set
                 */
                function CreateSalesforceIntegrationTokenRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CreateSalesforceIntegrationTokenRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @instance
                 */
                CreateSalesforceIntegrationTokenRequest.prototype.companyCode = "";

                /**
                 * CreateSalesforceIntegrationTokenRequest redirect.
                 * @member {string} redirect
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @instance
                 */
                CreateSalesforceIntegrationTokenRequest.prototype.redirect = "";

                /**
                 * CreateSalesforceIntegrationTokenRequest signedRequest.
                 * @member {string} signedRequest
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @instance
                 */
                CreateSalesforceIntegrationTokenRequest.prototype.signedRequest = "";

                /**
                 * Creates a new CreateSalesforceIntegrationTokenRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenRequest=} [properties] Properties to set
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest} CreateSalesforceIntegrationTokenRequest instance
                 */
                CreateSalesforceIntegrationTokenRequest.create = function create(properties) {
                    return new CreateSalesforceIntegrationTokenRequest(properties);
                };

                /**
                 * Encodes the specified CreateSalesforceIntegrationTokenRequest message. Does not implicitly {@link streem.api.global.CreateSalesforceIntegrationTokenRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenRequest} m CreateSalesforceIntegrationTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSalesforceIntegrationTokenRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    if (m.redirect != null && Object.hasOwnProperty.call(m, "redirect"))
                        w.uint32(26).string(m.redirect);
                    if (m.signedRequest != null && Object.hasOwnProperty.call(m, "signedRequest"))
                        w.uint32(34).string(m.signedRequest);
                    return w;
                };

                /**
                 * Decodes a CreateSalesforceIntegrationTokenRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest} CreateSalesforceIntegrationTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSalesforceIntegrationTokenRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.CreateSalesforceIntegrationTokenRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 3: {
                                m.redirect = r.string();
                                break;
                            }
                        case 4: {
                                m.signedRequest = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CreateSalesforceIntegrationTokenRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest} CreateSalesforceIntegrationTokenRequest
                 */
                CreateSalesforceIntegrationTokenRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.CreateSalesforceIntegrationTokenRequest)
                        return d;
                    var m = new $root.streem.api.global.CreateSalesforceIntegrationTokenRequest();
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.redirect != null) {
                        m.redirect = String(d.redirect);
                    }
                    if (d.signedRequest != null) {
                        m.signedRequest = String(d.signedRequest);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CreateSalesforceIntegrationTokenRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {streem.api.global.CreateSalesforceIntegrationTokenRequest} m CreateSalesforceIntegrationTokenRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSalesforceIntegrationTokenRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.companyCode = "";
                        d.redirect = "";
                        d.signedRequest = "";
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.redirect != null && m.hasOwnProperty("redirect")) {
                        d.redirect = m.redirect;
                    }
                    if (m.signedRequest != null && m.hasOwnProperty("signedRequest")) {
                        d.signedRequest = m.signedRequest;
                    }
                    return d;
                };

                /**
                 * Converts this CreateSalesforceIntegrationTokenRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSalesforceIntegrationTokenRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateSalesforceIntegrationTokenRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateSalesforceIntegrationTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.CreateSalesforceIntegrationTokenRequest";
                };

                CreateSalesforceIntegrationTokenRequest.RequestBody = (function() {

                    /**
                     * Properties of a RequestBody.
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                     * @interface IRequestBody
                     * @property {string|null} [signedRequest] RequestBody signedRequest
                     */

                    /**
                     * Constructs a new RequestBody.
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest
                     * @classdesc Represents a RequestBody.
                     * @implements IRequestBody
                     * @constructor
                     * @param {streem.api.global.CreateSalesforceIntegrationTokenRequest.IRequestBody=} [p] Properties to set
                     */
                    function RequestBody(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * RequestBody signedRequest.
                     * @member {string} signedRequest
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @instance
                     */
                    RequestBody.prototype.signedRequest = "";

                    /**
                     * Creates a new RequestBody instance using the specified properties.
                     * @function create
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {streem.api.global.CreateSalesforceIntegrationTokenRequest.IRequestBody=} [properties] Properties to set
                     * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody} RequestBody instance
                     */
                    RequestBody.create = function create(properties) {
                        return new RequestBody(properties);
                    };

                    /**
                     * Encodes the specified RequestBody message. Does not implicitly {@link streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {streem.api.global.CreateSalesforceIntegrationTokenRequest.IRequestBody} m RequestBody message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestBody.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.signedRequest != null && Object.hasOwnProperty.call(m, "signedRequest"))
                            w.uint32(10).string(m.signedRequest);
                        return w;
                    };

                    /**
                     * Decodes a RequestBody message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody} RequestBody
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestBody.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.signedRequest = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a RequestBody message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody} RequestBody
                     */
                    RequestBody.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody)
                            return d;
                        var m = new $root.streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody();
                        if (d.signedRequest != null) {
                            m.signedRequest = String(d.signedRequest);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a RequestBody message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody} m RequestBody
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RequestBody.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.signedRequest = "";
                        }
                        if (m.signedRequest != null && m.hasOwnProperty("signedRequest")) {
                            d.signedRequest = m.signedRequest;
                        }
                        return d;
                    };

                    /**
                     * Converts this RequestBody to JSON.
                     * @function toJSON
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RequestBody.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for RequestBody
                     * @function getTypeUrl
                     * @memberof streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    RequestBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.global.CreateSalesforceIntegrationTokenRequest.RequestBody";
                    };

                    return RequestBody;
                })();

                return CreateSalesforceIntegrationTokenRequest;
            })();

            global.CreateSalesforceIntegrationTokenResponse = (function() {

                /**
                 * Properties of a CreateSalesforceIntegrationTokenResponse.
                 * @memberof streem.api.global
                 * @interface ICreateSalesforceIntegrationTokenResponse
                 * @property {string|null} [redirectUrl] CreateSalesforceIntegrationTokenResponse redirectUrl
                 * @property {streem.api.global.ISalesforceResponseContext|null} [context] CreateSalesforceIntegrationTokenResponse context
                 */

                /**
                 * Constructs a new CreateSalesforceIntegrationTokenResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a CreateSalesforceIntegrationTokenResponse.
                 * @implements ICreateSalesforceIntegrationTokenResponse
                 * @constructor
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenResponse=} [p] Properties to set
                 */
                function CreateSalesforceIntegrationTokenResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * CreateSalesforceIntegrationTokenResponse redirectUrl.
                 * @member {string} redirectUrl
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @instance
                 */
                CreateSalesforceIntegrationTokenResponse.prototype.redirectUrl = "";

                /**
                 * CreateSalesforceIntegrationTokenResponse context.
                 * @member {streem.api.global.ISalesforceResponseContext|null|undefined} context
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @instance
                 */
                CreateSalesforceIntegrationTokenResponse.prototype.context = null;

                /**
                 * Creates a new CreateSalesforceIntegrationTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenResponse=} [properties] Properties to set
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenResponse} CreateSalesforceIntegrationTokenResponse instance
                 */
                CreateSalesforceIntegrationTokenResponse.create = function create(properties) {
                    return new CreateSalesforceIntegrationTokenResponse(properties);
                };

                /**
                 * Encodes the specified CreateSalesforceIntegrationTokenResponse message. Does not implicitly {@link streem.api.global.CreateSalesforceIntegrationTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {streem.api.global.ICreateSalesforceIntegrationTokenResponse} m CreateSalesforceIntegrationTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSalesforceIntegrationTokenResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.redirectUrl != null && Object.hasOwnProperty.call(m, "redirectUrl"))
                        w.uint32(10).string(m.redirectUrl);
                    if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                        $root.streem.api.global.SalesforceResponseContext.encode(m.context, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a CreateSalesforceIntegrationTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenResponse} CreateSalesforceIntegrationTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSalesforceIntegrationTokenResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.CreateSalesforceIntegrationTokenResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.redirectUrl = r.string();
                                break;
                            }
                        case 2: {
                                m.context = $root.streem.api.global.SalesforceResponseContext.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a CreateSalesforceIntegrationTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.CreateSalesforceIntegrationTokenResponse} CreateSalesforceIntegrationTokenResponse
                 */
                CreateSalesforceIntegrationTokenResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.CreateSalesforceIntegrationTokenResponse)
                        return d;
                    var m = new $root.streem.api.global.CreateSalesforceIntegrationTokenResponse();
                    if (d.redirectUrl != null) {
                        m.redirectUrl = String(d.redirectUrl);
                    }
                    if (d.context != null) {
                        if (typeof d.context !== "object")
                            throw TypeError(".streem.api.global.CreateSalesforceIntegrationTokenResponse.context: object expected");
                        m.context = $root.streem.api.global.SalesforceResponseContext.fromObject(d.context);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a CreateSalesforceIntegrationTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {streem.api.global.CreateSalesforceIntegrationTokenResponse} m CreateSalesforceIntegrationTokenResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSalesforceIntegrationTokenResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.redirectUrl = "";
                        d.context = null;
                    }
                    if (m.redirectUrl != null && m.hasOwnProperty("redirectUrl")) {
                        d.redirectUrl = m.redirectUrl;
                    }
                    if (m.context != null && m.hasOwnProperty("context")) {
                        d.context = $root.streem.api.global.SalesforceResponseContext.toObject(m.context, o);
                    }
                    return d;
                };

                /**
                 * Converts this CreateSalesforceIntegrationTokenResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSalesforceIntegrationTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateSalesforceIntegrationTokenResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.CreateSalesforceIntegrationTokenResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateSalesforceIntegrationTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.CreateSalesforceIntegrationTokenResponse";
                };

                return CreateSalesforceIntegrationTokenResponse;
            })();

            global.SalesforceResponseContext = (function() {

                /**
                 * Properties of a SalesforceResponseContext.
                 * @memberof streem.api.global
                 * @interface ISalesforceResponseContext
                 * @property {streem.api.global.ISalesforceClient|null} [client] SalesforceResponseContext client
                 */

                /**
                 * Constructs a new SalesforceResponseContext.
                 * @memberof streem.api.global
                 * @classdesc Represents a SalesforceResponseContext.
                 * @implements ISalesforceResponseContext
                 * @constructor
                 * @param {streem.api.global.ISalesforceResponseContext=} [p] Properties to set
                 */
                function SalesforceResponseContext(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SalesforceResponseContext client.
                 * @member {streem.api.global.ISalesforceClient|null|undefined} client
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @instance
                 */
                SalesforceResponseContext.prototype.client = null;

                /**
                 * Creates a new SalesforceResponseContext instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {streem.api.global.ISalesforceResponseContext=} [properties] Properties to set
                 * @returns {streem.api.global.SalesforceResponseContext} SalesforceResponseContext instance
                 */
                SalesforceResponseContext.create = function create(properties) {
                    return new SalesforceResponseContext(properties);
                };

                /**
                 * Encodes the specified SalesforceResponseContext message. Does not implicitly {@link streem.api.global.SalesforceResponseContext.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {streem.api.global.ISalesforceResponseContext} m SalesforceResponseContext message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SalesforceResponseContext.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.client != null && Object.hasOwnProperty.call(m, "client"))
                        $root.streem.api.global.SalesforceClient.encode(m.client, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a SalesforceResponseContext message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.SalesforceResponseContext} SalesforceResponseContext
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SalesforceResponseContext.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.SalesforceResponseContext();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.client = $root.streem.api.global.SalesforceClient.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SalesforceResponseContext message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.SalesforceResponseContext} SalesforceResponseContext
                 */
                SalesforceResponseContext.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.SalesforceResponseContext)
                        return d;
                    var m = new $root.streem.api.global.SalesforceResponseContext();
                    if (d.client != null) {
                        if (typeof d.client !== "object")
                            throw TypeError(".streem.api.global.SalesforceResponseContext.client: object expected");
                        m.client = $root.streem.api.global.SalesforceClient.fromObject(d.client);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SalesforceResponseContext message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {streem.api.global.SalesforceResponseContext} m SalesforceResponseContext
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SalesforceResponseContext.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.client = null;
                    }
                    if (m.client != null && m.hasOwnProperty("client")) {
                        d.client = $root.streem.api.global.SalesforceClient.toObject(m.client, o);
                    }
                    return d;
                };

                /**
                 * Converts this SalesforceResponseContext to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SalesforceResponseContext.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SalesforceResponseContext
                 * @function getTypeUrl
                 * @memberof streem.api.global.SalesforceResponseContext
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SalesforceResponseContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.SalesforceResponseContext";
                };

                return SalesforceResponseContext;
            })();

            global.SalesforceClient = (function() {

                /**
                 * Properties of a SalesforceClient.
                 * @memberof streem.api.global
                 * @interface ISalesforceClient
                 * @property {string|null} [instanceId] SalesforceClient instanceId
                 * @property {string|null} [targetOrigin] SalesforceClient targetOrigin
                 * @property {string|null} [oauthToken] SalesforceClient oauthToken
                 * @property {string|null} [instanceUrl] SalesforceClient instanceUrl
                 * @property {string|null} [refreshToken] SalesforceClient refreshToken
                 */

                /**
                 * Constructs a new SalesforceClient.
                 * @memberof streem.api.global
                 * @classdesc Represents a SalesforceClient.
                 * @implements ISalesforceClient
                 * @constructor
                 * @param {streem.api.global.ISalesforceClient=} [p] Properties to set
                 */
                function SalesforceClient(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SalesforceClient instanceId.
                 * @member {string} instanceId
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 */
                SalesforceClient.prototype.instanceId = "";

                /**
                 * SalesforceClient targetOrigin.
                 * @member {string} targetOrigin
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 */
                SalesforceClient.prototype.targetOrigin = "";

                /**
                 * SalesforceClient oauthToken.
                 * @member {string} oauthToken
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 */
                SalesforceClient.prototype.oauthToken = "";

                /**
                 * SalesforceClient instanceUrl.
                 * @member {string} instanceUrl
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 */
                SalesforceClient.prototype.instanceUrl = "";

                /**
                 * SalesforceClient refreshToken.
                 * @member {string} refreshToken
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 */
                SalesforceClient.prototype.refreshToken = "";

                /**
                 * Creates a new SalesforceClient instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {streem.api.global.ISalesforceClient=} [properties] Properties to set
                 * @returns {streem.api.global.SalesforceClient} SalesforceClient instance
                 */
                SalesforceClient.create = function create(properties) {
                    return new SalesforceClient(properties);
                };

                /**
                 * Encodes the specified SalesforceClient message. Does not implicitly {@link streem.api.global.SalesforceClient.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {streem.api.global.ISalesforceClient} m SalesforceClient message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SalesforceClient.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.instanceId != null && Object.hasOwnProperty.call(m, "instanceId"))
                        w.uint32(10).string(m.instanceId);
                    if (m.targetOrigin != null && Object.hasOwnProperty.call(m, "targetOrigin"))
                        w.uint32(18).string(m.targetOrigin);
                    if (m.oauthToken != null && Object.hasOwnProperty.call(m, "oauthToken"))
                        w.uint32(26).string(m.oauthToken);
                    if (m.instanceUrl != null && Object.hasOwnProperty.call(m, "instanceUrl"))
                        w.uint32(34).string(m.instanceUrl);
                    if (m.refreshToken != null && Object.hasOwnProperty.call(m, "refreshToken"))
                        w.uint32(42).string(m.refreshToken);
                    return w;
                };

                /**
                 * Decodes a SalesforceClient message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.SalesforceClient} SalesforceClient
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SalesforceClient.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.SalesforceClient();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.instanceId = r.string();
                                break;
                            }
                        case 2: {
                                m.targetOrigin = r.string();
                                break;
                            }
                        case 3: {
                                m.oauthToken = r.string();
                                break;
                            }
                        case 4: {
                                m.instanceUrl = r.string();
                                break;
                            }
                        case 5: {
                                m.refreshToken = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SalesforceClient message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.SalesforceClient} SalesforceClient
                 */
                SalesforceClient.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.SalesforceClient)
                        return d;
                    var m = new $root.streem.api.global.SalesforceClient();
                    if (d.instanceId != null) {
                        m.instanceId = String(d.instanceId);
                    }
                    if (d.targetOrigin != null) {
                        m.targetOrigin = String(d.targetOrigin);
                    }
                    if (d.oauthToken != null) {
                        m.oauthToken = String(d.oauthToken);
                    }
                    if (d.instanceUrl != null) {
                        m.instanceUrl = String(d.instanceUrl);
                    }
                    if (d.refreshToken != null) {
                        m.refreshToken = String(d.refreshToken);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SalesforceClient message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {streem.api.global.SalesforceClient} m SalesforceClient
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SalesforceClient.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.instanceId = "";
                        d.targetOrigin = "";
                        d.oauthToken = "";
                        d.instanceUrl = "";
                        d.refreshToken = "";
                    }
                    if (m.instanceId != null && m.hasOwnProperty("instanceId")) {
                        d.instanceId = m.instanceId;
                    }
                    if (m.targetOrigin != null && m.hasOwnProperty("targetOrigin")) {
                        d.targetOrigin = m.targetOrigin;
                    }
                    if (m.oauthToken != null && m.hasOwnProperty("oauthToken")) {
                        d.oauthToken = m.oauthToken;
                    }
                    if (m.instanceUrl != null && m.hasOwnProperty("instanceUrl")) {
                        d.instanceUrl = m.instanceUrl;
                    }
                    if (m.refreshToken != null && m.hasOwnProperty("refreshToken")) {
                        d.refreshToken = m.refreshToken;
                    }
                    return d;
                };

                /**
                 * Converts this SalesforceClient to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.SalesforceClient
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SalesforceClient.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SalesforceClient
                 * @function getTypeUrl
                 * @memberof streem.api.global.SalesforceClient
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SalesforceClient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.SalesforceClient";
                };

                return SalesforceClient;
            })();

            global.GlobalSsoProviders = (function() {

                /**
                 * Constructs a new GlobalSsoProviders service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalSsoProviders
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalSsoProviders(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalSsoProviders.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalSsoProviders;

                /**
                 * Creates new GlobalSsoProviders service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalSsoProviders} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalSsoProviders.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#listSsoProviders}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef ListSsoProvidersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.ListSsoProvidersResponse} [response] ListSsoProvidersResponse
                 */

                /**
                 * Calls ListSsoProviders.
                 * @function listSsoProviders
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IListSsoProvidersRequest} request ListSsoProvidersRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.ListSsoProvidersCallback} callback Node-style callback called with the error, if any, and ListSsoProvidersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.listSsoProviders = function listSsoProviders(request, callback) {
                    return this.rpcCall(listSsoProviders, $root.streem.api.global.ListSsoProvidersRequest, $root.streem.api.global.ListSsoProvidersResponse, request, callback);
                }, "name", { value: "ListSsoProviders" });

                /**
                 * Calls ListSsoProviders.
                 * @function listSsoProviders
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IListSsoProvidersRequest} request ListSsoProvidersRequest message or plain object
                 * @returns {Promise<streem.api.global.ListSsoProvidersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#saveSsoProvider}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef SaveSsoProviderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.SaveSsoProviderResponse} [response] SaveSsoProviderResponse
                 */

                /**
                 * Calls SaveSsoProvider.
                 * @function saveSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.ISaveSsoProviderRequest} request SaveSsoProviderRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.SaveSsoProviderCallback} callback Node-style callback called with the error, if any, and SaveSsoProviderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.saveSsoProvider = function saveSsoProvider(request, callback) {
                    return this.rpcCall(saveSsoProvider, $root.streem.api.global.SaveSsoProviderRequest, $root.streem.api.global.SaveSsoProviderResponse, request, callback);
                }, "name", { value: "SaveSsoProvider" });

                /**
                 * Calls SaveSsoProvider.
                 * @function saveSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.ISaveSsoProviderRequest} request SaveSsoProviderRequest message or plain object
                 * @returns {Promise<streem.api.global.SaveSsoProviderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#getSsoProvider}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef GetSsoProviderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetSsoProviderResponse} [response] GetSsoProviderResponse
                 */

                /**
                 * Calls GetSsoProvider.
                 * @function getSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IGetSsoProviderRequest} request GetSsoProviderRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.GetSsoProviderCallback} callback Node-style callback called with the error, if any, and GetSsoProviderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.getSsoProvider = function getSsoProvider(request, callback) {
                    return this.rpcCall(getSsoProvider, $root.streem.api.global.GetSsoProviderRequest, $root.streem.api.global.GetSsoProviderResponse, request, callback);
                }, "name", { value: "GetSsoProvider" });

                /**
                 * Calls GetSsoProvider.
                 * @function getSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IGetSsoProviderRequest} request GetSsoProviderRequest message or plain object
                 * @returns {Promise<streem.api.global.GetSsoProviderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#deleteSsoProvider}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef DeleteSsoProviderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.DeleteSsoProviderResponse} [response] DeleteSsoProviderResponse
                 */

                /**
                 * Calls DeleteSsoProvider.
                 * @function deleteSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IDeleteSsoProviderRequest} request DeleteSsoProviderRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.DeleteSsoProviderCallback} callback Node-style callback called with the error, if any, and DeleteSsoProviderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.deleteSsoProvider = function deleteSsoProvider(request, callback) {
                    return this.rpcCall(deleteSsoProvider, $root.streem.api.global.DeleteSsoProviderRequest, $root.streem.api.global.DeleteSsoProviderResponse, request, callback);
                }, "name", { value: "DeleteSsoProvider" });

                /**
                 * Calls DeleteSsoProvider.
                 * @function deleteSsoProvider
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IDeleteSsoProviderRequest} request DeleteSsoProviderRequest message or plain object
                 * @returns {Promise<streem.api.global.DeleteSsoProviderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#listSsoProviderCompanyMappings}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef ListSsoProviderCompanyMappingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.ListSsoProviderCompanyMappingsResponse} [response] ListSsoProviderCompanyMappingsResponse
                 */

                /**
                 * Calls ListSsoProviderCompanyMappings.
                 * @function listSsoProviderCompanyMappings
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsRequest} request ListSsoProviderCompanyMappingsRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.ListSsoProviderCompanyMappingsCallback} callback Node-style callback called with the error, if any, and ListSsoProviderCompanyMappingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.listSsoProviderCompanyMappings = function listSsoProviderCompanyMappings(request, callback) {
                    return this.rpcCall(listSsoProviderCompanyMappings, $root.streem.api.global.ListSsoProviderCompanyMappingsRequest, $root.streem.api.global.ListSsoProviderCompanyMappingsResponse, request, callback);
                }, "name", { value: "ListSsoProviderCompanyMappings" });

                /**
                 * Calls ListSsoProviderCompanyMappings.
                 * @function listSsoProviderCompanyMappings
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsRequest} request ListSsoProviderCompanyMappingsRequest message or plain object
                 * @returns {Promise<streem.api.global.ListSsoProviderCompanyMappingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#getSsoProviderCompanyMapping}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef GetSsoProviderCompanyMappingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetSsoProviderCompanyMappingResponse} [response] GetSsoProviderCompanyMappingResponse
                 */

                /**
                 * Calls GetSsoProviderCompanyMapping.
                 * @function getSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingRequest} request GetSsoProviderCompanyMappingRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.GetSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and GetSsoProviderCompanyMappingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.getSsoProviderCompanyMapping = function getSsoProviderCompanyMapping(request, callback) {
                    return this.rpcCall(getSsoProviderCompanyMapping, $root.streem.api.global.GetSsoProviderCompanyMappingRequest, $root.streem.api.global.GetSsoProviderCompanyMappingResponse, request, callback);
                }, "name", { value: "GetSsoProviderCompanyMapping" });

                /**
                 * Calls GetSsoProviderCompanyMapping.
                 * @function getSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingRequest} request GetSsoProviderCompanyMappingRequest message or plain object
                 * @returns {Promise<streem.api.global.GetSsoProviderCompanyMappingResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#updateSsoProviderCompanyMapping}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef UpdateSsoProviderCompanyMappingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.UpdateSsoProviderCompanyMappingResponse} [response] UpdateSsoProviderCompanyMappingResponse
                 */

                /**
                 * Calls UpdateSsoProviderCompanyMapping.
                 * @function updateSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingRequest} request UpdateSsoProviderCompanyMappingRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.UpdateSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and UpdateSsoProviderCompanyMappingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.updateSsoProviderCompanyMapping = function updateSsoProviderCompanyMapping(request, callback) {
                    return this.rpcCall(updateSsoProviderCompanyMapping, $root.streem.api.global.UpdateSsoProviderCompanyMappingRequest, $root.streem.api.global.UpdateSsoProviderCompanyMappingResponse, request, callback);
                }, "name", { value: "UpdateSsoProviderCompanyMapping" });

                /**
                 * Calls UpdateSsoProviderCompanyMapping.
                 * @function updateSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingRequest} request UpdateSsoProviderCompanyMappingRequest message or plain object
                 * @returns {Promise<streem.api.global.UpdateSsoProviderCompanyMappingResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link streem.api.global.GlobalSsoProviders#deleteSsoProviderCompanyMapping}.
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @typedef DeleteSsoProviderCompanyMappingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.DeleteSsoProviderCompanyMappingResponse} [response] DeleteSsoProviderCompanyMappingResponse
                 */

                /**
                 * Calls DeleteSsoProviderCompanyMapping.
                 * @function deleteSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingRequest} request DeleteSsoProviderCompanyMappingRequest message or plain object
                 * @param {streem.api.global.GlobalSsoProviders.DeleteSsoProviderCompanyMappingCallback} callback Node-style callback called with the error, if any, and DeleteSsoProviderCompanyMappingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalSsoProviders.prototype.deleteSsoProviderCompanyMapping = function deleteSsoProviderCompanyMapping(request, callback) {
                    return this.rpcCall(deleteSsoProviderCompanyMapping, $root.streem.api.global.DeleteSsoProviderCompanyMappingRequest, $root.streem.api.global.DeleteSsoProviderCompanyMappingResponse, request, callback);
                }, "name", { value: "DeleteSsoProviderCompanyMapping" });

                /**
                 * Calls DeleteSsoProviderCompanyMapping.
                 * @function deleteSsoProviderCompanyMapping
                 * @memberof streem.api.global.GlobalSsoProviders
                 * @instance
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingRequest} request DeleteSsoProviderCompanyMappingRequest message or plain object
                 * @returns {Promise<streem.api.global.DeleteSsoProviderCompanyMappingResponse>} Promise
                 * @variation 2
                 */

                return GlobalSsoProviders;
            })();

            global.SaveSsoProviderRequest = (function() {

                /**
                 * Properties of a SaveSsoProviderRequest.
                 * @memberof streem.api.global
                 * @interface ISaveSsoProviderRequest
                 * @property {string|null} [ssoProviderCode] SaveSsoProviderRequest ssoProviderCode
                 * @property {streem.api.ISsoProvider|null} [ssoProvider] SaveSsoProviderRequest ssoProvider
                 */

                /**
                 * Constructs a new SaveSsoProviderRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a SaveSsoProviderRequest.
                 * @implements ISaveSsoProviderRequest
                 * @constructor
                 * @param {streem.api.global.ISaveSsoProviderRequest=} [p] Properties to set
                 */
                function SaveSsoProviderRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SaveSsoProviderRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @instance
                 */
                SaveSsoProviderRequest.prototype.ssoProviderCode = "";

                /**
                 * SaveSsoProviderRequest ssoProvider.
                 * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @instance
                 */
                SaveSsoProviderRequest.prototype.ssoProvider = null;

                /**
                 * Creates a new SaveSsoProviderRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {streem.api.global.ISaveSsoProviderRequest=} [properties] Properties to set
                 * @returns {streem.api.global.SaveSsoProviderRequest} SaveSsoProviderRequest instance
                 */
                SaveSsoProviderRequest.create = function create(properties) {
                    return new SaveSsoProviderRequest(properties);
                };

                /**
                 * Encodes the specified SaveSsoProviderRequest message. Does not implicitly {@link streem.api.global.SaveSsoProviderRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {streem.api.global.ISaveSsoProviderRequest} m SaveSsoProviderRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveSsoProviderRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                        $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(18).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a SaveSsoProviderRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.SaveSsoProviderRequest} SaveSsoProviderRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveSsoProviderRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.SaveSsoProviderRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        case 2: {
                                m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SaveSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.SaveSsoProviderRequest} SaveSsoProviderRequest
                 */
                SaveSsoProviderRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.SaveSsoProviderRequest)
                        return d;
                    var m = new $root.streem.api.global.SaveSsoProviderRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    if (d.ssoProvider != null) {
                        if (typeof d.ssoProvider !== "object")
                            throw TypeError(".streem.api.global.SaveSsoProviderRequest.ssoProvider: object expected");
                        m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SaveSsoProviderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {streem.api.global.SaveSsoProviderRequest} m SaveSsoProviderRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveSsoProviderRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                        d.ssoProvider = null;
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                        d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                    }
                    return d;
                };

                /**
                 * Converts this SaveSsoProviderRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveSsoProviderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SaveSsoProviderRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.SaveSsoProviderRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.SaveSsoProviderRequest";
                };

                return SaveSsoProviderRequest;
            })();

            global.SaveSsoProviderResponse = (function() {

                /**
                 * Properties of a SaveSsoProviderResponse.
                 * @memberof streem.api.global
                 * @interface ISaveSsoProviderResponse
                 * @property {streem.api.ISsoProvider|null} [ssoProvider] SaveSsoProviderResponse ssoProvider
                 */

                /**
                 * Constructs a new SaveSsoProviderResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a SaveSsoProviderResponse.
                 * @implements ISaveSsoProviderResponse
                 * @constructor
                 * @param {streem.api.global.ISaveSsoProviderResponse=} [p] Properties to set
                 */
                function SaveSsoProviderResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * SaveSsoProviderResponse ssoProvider.
                 * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @instance
                 */
                SaveSsoProviderResponse.prototype.ssoProvider = null;

                /**
                 * Creates a new SaveSsoProviderResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {streem.api.global.ISaveSsoProviderResponse=} [properties] Properties to set
                 * @returns {streem.api.global.SaveSsoProviderResponse} SaveSsoProviderResponse instance
                 */
                SaveSsoProviderResponse.create = function create(properties) {
                    return new SaveSsoProviderResponse(properties);
                };

                /**
                 * Encodes the specified SaveSsoProviderResponse message. Does not implicitly {@link streem.api.global.SaveSsoProviderResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {streem.api.global.ISaveSsoProviderResponse} m SaveSsoProviderResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveSsoProviderResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                        $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a SaveSsoProviderResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.SaveSsoProviderResponse} SaveSsoProviderResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveSsoProviderResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.SaveSsoProviderResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a SaveSsoProviderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.SaveSsoProviderResponse} SaveSsoProviderResponse
                 */
                SaveSsoProviderResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.SaveSsoProviderResponse)
                        return d;
                    var m = new $root.streem.api.global.SaveSsoProviderResponse();
                    if (d.ssoProvider != null) {
                        if (typeof d.ssoProvider !== "object")
                            throw TypeError(".streem.api.global.SaveSsoProviderResponse.ssoProvider: object expected");
                        m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a SaveSsoProviderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {streem.api.global.SaveSsoProviderResponse} m SaveSsoProviderResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveSsoProviderResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProvider = null;
                    }
                    if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                        d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                    }
                    return d;
                };

                /**
                 * Converts this SaveSsoProviderResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveSsoProviderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SaveSsoProviderResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.SaveSsoProviderResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveSsoProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.SaveSsoProviderResponse";
                };

                return SaveSsoProviderResponse;
            })();

            global.ListSsoProvidersRequest = (function() {

                /**
                 * Properties of a ListSsoProvidersRequest.
                 * @memberof streem.api.global
                 * @interface IListSsoProvidersRequest
                 * @property {string|null} [pageToken] ListSsoProvidersRequest pageToken
                 * @property {number|null} [pageSize] ListSsoProvidersRequest pageSize
                 */

                /**
                 * Constructs a new ListSsoProvidersRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListSsoProvidersRequest.
                 * @implements IListSsoProvidersRequest
                 * @constructor
                 * @param {streem.api.global.IListSsoProvidersRequest=} [p] Properties to set
                 */
                function ListSsoProvidersRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListSsoProvidersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @instance
                 */
                ListSsoProvidersRequest.prototype.pageToken = "";

                /**
                 * ListSsoProvidersRequest pageSize.
                 * @member {number} pageSize
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @instance
                 */
                ListSsoProvidersRequest.prototype.pageSize = 0;

                /**
                 * Creates a new ListSsoProvidersRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {streem.api.global.IListSsoProvidersRequest=} [properties] Properties to set
                 * @returns {streem.api.global.ListSsoProvidersRequest} ListSsoProvidersRequest instance
                 */
                ListSsoProvidersRequest.create = function create(properties) {
                    return new ListSsoProvidersRequest(properties);
                };

                /**
                 * Encodes the specified ListSsoProvidersRequest message. Does not implicitly {@link streem.api.global.ListSsoProvidersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {streem.api.global.IListSsoProvidersRequest} m ListSsoProvidersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListSsoProvidersRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                        w.uint32(10).string(m.pageToken);
                    if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                        w.uint32(16).int32(m.pageSize);
                    return w;
                };

                /**
                 * Decodes a ListSsoProvidersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListSsoProvidersRequest} ListSsoProvidersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListSsoProvidersRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListSsoProvidersRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.pageToken = r.string();
                                break;
                            }
                        case 2: {
                                m.pageSize = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListSsoProvidersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListSsoProvidersRequest} ListSsoProvidersRequest
                 */
                ListSsoProvidersRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListSsoProvidersRequest)
                        return d;
                    var m = new $root.streem.api.global.ListSsoProvidersRequest();
                    if (d.pageToken != null) {
                        m.pageToken = String(d.pageToken);
                    }
                    if (d.pageSize != null) {
                        m.pageSize = d.pageSize | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListSsoProvidersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {streem.api.global.ListSsoProvidersRequest} m ListSsoProvidersRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSsoProvidersRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.pageToken = "";
                        d.pageSize = 0;
                    }
                    if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                        d.pageToken = m.pageToken;
                    }
                    if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                        d.pageSize = m.pageSize;
                    }
                    return d;
                };

                /**
                 * Converts this ListSsoProvidersRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSsoProvidersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListSsoProvidersRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListSsoProvidersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListSsoProvidersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListSsoProvidersRequest";
                };

                return ListSsoProvidersRequest;
            })();

            global.ListSsoProvidersResponse = (function() {

                /**
                 * Properties of a ListSsoProvidersResponse.
                 * @memberof streem.api.global
                 * @interface IListSsoProvidersResponse
                 * @property {Array.<streem.api.ISsoProvider>|null} [ssoProviders] ListSsoProvidersResponse ssoProviders
                 * @property {string|null} [nextPageToken] ListSsoProvidersResponse nextPageToken
                 * @property {number|null} [totalSize] ListSsoProvidersResponse totalSize
                 */

                /**
                 * Constructs a new ListSsoProvidersResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListSsoProvidersResponse.
                 * @implements IListSsoProvidersResponse
                 * @constructor
                 * @param {streem.api.global.IListSsoProvidersResponse=} [p] Properties to set
                 */
                function ListSsoProvidersResponse(p) {
                    this.ssoProviders = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListSsoProvidersResponse ssoProviders.
                 * @member {Array.<streem.api.ISsoProvider>} ssoProviders
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @instance
                 */
                ListSsoProvidersResponse.prototype.ssoProviders = $util.emptyArray;

                /**
                 * ListSsoProvidersResponse nextPageToken.
                 * @member {string} nextPageToken
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @instance
                 */
                ListSsoProvidersResponse.prototype.nextPageToken = "";

                /**
                 * ListSsoProvidersResponse totalSize.
                 * @member {number} totalSize
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @instance
                 */
                ListSsoProvidersResponse.prototype.totalSize = 0;

                /**
                 * Creates a new ListSsoProvidersResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {streem.api.global.IListSsoProvidersResponse=} [properties] Properties to set
                 * @returns {streem.api.global.ListSsoProvidersResponse} ListSsoProvidersResponse instance
                 */
                ListSsoProvidersResponse.create = function create(properties) {
                    return new ListSsoProvidersResponse(properties);
                };

                /**
                 * Encodes the specified ListSsoProvidersResponse message. Does not implicitly {@link streem.api.global.ListSsoProvidersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {streem.api.global.IListSsoProvidersResponse} m ListSsoProvidersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListSsoProvidersResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviders != null && m.ssoProviders.length) {
                        for (var i = 0; i < m.ssoProviders.length; ++i)
                            $root.streem.api.SsoProvider.encode(m.ssoProviders[i], w.uint32(10).fork()).ldelim();
                    }
                    if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                        w.uint32(18).string(m.nextPageToken);
                    if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                        w.uint32(24).int32(m.totalSize);
                    return w;
                };

                /**
                 * Decodes a ListSsoProvidersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListSsoProvidersResponse} ListSsoProvidersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListSsoProvidersResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListSsoProvidersResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.ssoProviders && m.ssoProviders.length))
                                    m.ssoProviders = [];
                                m.ssoProviders.push($root.streem.api.SsoProvider.decode(r, r.uint32()));
                                break;
                            }
                        case 2: {
                                m.nextPageToken = r.string();
                                break;
                            }
                        case 3: {
                                m.totalSize = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListSsoProvidersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListSsoProvidersResponse} ListSsoProvidersResponse
                 */
                ListSsoProvidersResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListSsoProvidersResponse)
                        return d;
                    var m = new $root.streem.api.global.ListSsoProvidersResponse();
                    if (d.ssoProviders) {
                        if (!Array.isArray(d.ssoProviders))
                            throw TypeError(".streem.api.global.ListSsoProvidersResponse.ssoProviders: array expected");
                        m.ssoProviders = [];
                        for (var i = 0; i < d.ssoProviders.length; ++i) {
                            if (typeof d.ssoProviders[i] !== "object")
                                throw TypeError(".streem.api.global.ListSsoProvidersResponse.ssoProviders: object expected");
                            m.ssoProviders[i] = $root.streem.api.SsoProvider.fromObject(d.ssoProviders[i]);
                        }
                    }
                    if (d.nextPageToken != null) {
                        m.nextPageToken = String(d.nextPageToken);
                    }
                    if (d.totalSize != null) {
                        m.totalSize = d.totalSize | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListSsoProvidersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {streem.api.global.ListSsoProvidersResponse} m ListSsoProvidersResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSsoProvidersResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.ssoProviders = [];
                    }
                    if (o.defaults) {
                        d.nextPageToken = "";
                        d.totalSize = 0;
                    }
                    if (m.ssoProviders && m.ssoProviders.length) {
                        d.ssoProviders = [];
                        for (var j = 0; j < m.ssoProviders.length; ++j) {
                            d.ssoProviders[j] = $root.streem.api.SsoProvider.toObject(m.ssoProviders[j], o);
                        }
                    }
                    if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                        d.nextPageToken = m.nextPageToken;
                    }
                    if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                        d.totalSize = m.totalSize;
                    }
                    return d;
                };

                /**
                 * Converts this ListSsoProvidersResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSsoProvidersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListSsoProvidersResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListSsoProvidersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListSsoProvidersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListSsoProvidersResponse";
                };

                return ListSsoProvidersResponse;
            })();

            global.GetSsoProviderRequest = (function() {

                /**
                 * Properties of a GetSsoProviderRequest.
                 * @memberof streem.api.global
                 * @interface IGetSsoProviderRequest
                 * @property {string|null} [ssoProviderCode] GetSsoProviderRequest ssoProviderCode
                 */

                /**
                 * Constructs a new GetSsoProviderRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetSsoProviderRequest.
                 * @implements IGetSsoProviderRequest
                 * @constructor
                 * @param {streem.api.global.IGetSsoProviderRequest=} [p] Properties to set
                 */
                function GetSsoProviderRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetSsoProviderRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @instance
                 */
                GetSsoProviderRequest.prototype.ssoProviderCode = "";

                /**
                 * Creates a new GetSsoProviderRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {streem.api.global.IGetSsoProviderRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetSsoProviderRequest} GetSsoProviderRequest instance
                 */
                GetSsoProviderRequest.create = function create(properties) {
                    return new GetSsoProviderRequest(properties);
                };

                /**
                 * Encodes the specified GetSsoProviderRequest message. Does not implicitly {@link streem.api.global.GetSsoProviderRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {streem.api.global.IGetSsoProviderRequest} m GetSsoProviderRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSsoProviderRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    return w;
                };

                /**
                 * Decodes a GetSsoProviderRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetSsoProviderRequest} GetSsoProviderRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSsoProviderRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetSsoProviderRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetSsoProviderRequest} GetSsoProviderRequest
                 */
                GetSsoProviderRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetSsoProviderRequest)
                        return d;
                    var m = new $root.streem.api.global.GetSsoProviderRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetSsoProviderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {streem.api.global.GetSsoProviderRequest} m GetSsoProviderRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSsoProviderRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    return d;
                };

                /**
                 * Converts this GetSsoProviderRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSsoProviderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetSsoProviderRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetSsoProviderRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetSsoProviderRequest";
                };

                return GetSsoProviderRequest;
            })();

            global.GetSsoProviderResponse = (function() {

                /**
                 * Properties of a GetSsoProviderResponse.
                 * @memberof streem.api.global
                 * @interface IGetSsoProviderResponse
                 * @property {streem.api.ISsoProvider|null} [ssoProvider] GetSsoProviderResponse ssoProvider
                 */

                /**
                 * Constructs a new GetSsoProviderResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetSsoProviderResponse.
                 * @implements IGetSsoProviderResponse
                 * @constructor
                 * @param {streem.api.global.IGetSsoProviderResponse=} [p] Properties to set
                 */
                function GetSsoProviderResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetSsoProviderResponse ssoProvider.
                 * @member {streem.api.ISsoProvider|null|undefined} ssoProvider
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @instance
                 */
                GetSsoProviderResponse.prototype.ssoProvider = null;

                /**
                 * Creates a new GetSsoProviderResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {streem.api.global.IGetSsoProviderResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetSsoProviderResponse} GetSsoProviderResponse instance
                 */
                GetSsoProviderResponse.create = function create(properties) {
                    return new GetSsoProviderResponse(properties);
                };

                /**
                 * Encodes the specified GetSsoProviderResponse message. Does not implicitly {@link streem.api.global.GetSsoProviderResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {streem.api.global.IGetSsoProviderResponse} m GetSsoProviderResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSsoProviderResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProvider != null && Object.hasOwnProperty.call(m, "ssoProvider"))
                        $root.streem.api.SsoProvider.encode(m.ssoProvider, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetSsoProviderResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetSsoProviderResponse} GetSsoProviderResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSsoProviderResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetSsoProviderResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProvider = $root.streem.api.SsoProvider.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetSsoProviderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetSsoProviderResponse} GetSsoProviderResponse
                 */
                GetSsoProviderResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetSsoProviderResponse)
                        return d;
                    var m = new $root.streem.api.global.GetSsoProviderResponse();
                    if (d.ssoProvider != null) {
                        if (typeof d.ssoProvider !== "object")
                            throw TypeError(".streem.api.global.GetSsoProviderResponse.ssoProvider: object expected");
                        m.ssoProvider = $root.streem.api.SsoProvider.fromObject(d.ssoProvider);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetSsoProviderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {streem.api.global.GetSsoProviderResponse} m GetSsoProviderResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSsoProviderResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProvider = null;
                    }
                    if (m.ssoProvider != null && m.hasOwnProperty("ssoProvider")) {
                        d.ssoProvider = $root.streem.api.SsoProvider.toObject(m.ssoProvider, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetSsoProviderResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSsoProviderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetSsoProviderResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetSsoProviderResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSsoProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetSsoProviderResponse";
                };

                return GetSsoProviderResponse;
            })();

            global.DeleteSsoProviderRequest = (function() {

                /**
                 * Properties of a DeleteSsoProviderRequest.
                 * @memberof streem.api.global
                 * @interface IDeleteSsoProviderRequest
                 * @property {string|null} [ssoProviderCode] DeleteSsoProviderRequest ssoProviderCode
                 */

                /**
                 * Constructs a new DeleteSsoProviderRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a DeleteSsoProviderRequest.
                 * @implements IDeleteSsoProviderRequest
                 * @constructor
                 * @param {streem.api.global.IDeleteSsoProviderRequest=} [p] Properties to set
                 */
                function DeleteSsoProviderRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * DeleteSsoProviderRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @instance
                 */
                DeleteSsoProviderRequest.prototype.ssoProviderCode = "";

                /**
                 * Creates a new DeleteSsoProviderRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderRequest=} [properties] Properties to set
                 * @returns {streem.api.global.DeleteSsoProviderRequest} DeleteSsoProviderRequest instance
                 */
                DeleteSsoProviderRequest.create = function create(properties) {
                    return new DeleteSsoProviderRequest(properties);
                };

                /**
                 * Encodes the specified DeleteSsoProviderRequest message. Does not implicitly {@link streem.api.global.DeleteSsoProviderRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderRequest} m DeleteSsoProviderRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSsoProviderRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    return w;
                };

                /**
                 * Decodes a DeleteSsoProviderRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.DeleteSsoProviderRequest} DeleteSsoProviderRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSsoProviderRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.DeleteSsoProviderRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteSsoProviderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.DeleteSsoProviderRequest} DeleteSsoProviderRequest
                 */
                DeleteSsoProviderRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.DeleteSsoProviderRequest)
                        return d;
                    var m = new $root.streem.api.global.DeleteSsoProviderRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a DeleteSsoProviderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {streem.api.global.DeleteSsoProviderRequest} m DeleteSsoProviderRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSsoProviderRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    return d;
                };

                /**
                 * Converts this DeleteSsoProviderRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSsoProviderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteSsoProviderRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.DeleteSsoProviderRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSsoProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.DeleteSsoProviderRequest";
                };

                return DeleteSsoProviderRequest;
            })();

            global.DeleteSsoProviderResponse = (function() {

                /**
                 * Properties of a DeleteSsoProviderResponse.
                 * @memberof streem.api.global
                 * @interface IDeleteSsoProviderResponse
                 */

                /**
                 * Constructs a new DeleteSsoProviderResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a DeleteSsoProviderResponse.
                 * @implements IDeleteSsoProviderResponse
                 * @constructor
                 * @param {streem.api.global.IDeleteSsoProviderResponse=} [p] Properties to set
                 */
                function DeleteSsoProviderResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new DeleteSsoProviderResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderResponse=} [properties] Properties to set
                 * @returns {streem.api.global.DeleteSsoProviderResponse} DeleteSsoProviderResponse instance
                 */
                DeleteSsoProviderResponse.create = function create(properties) {
                    return new DeleteSsoProviderResponse(properties);
                };

                /**
                 * Encodes the specified DeleteSsoProviderResponse message. Does not implicitly {@link streem.api.global.DeleteSsoProviderResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderResponse} m DeleteSsoProviderResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSsoProviderResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a DeleteSsoProviderResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.DeleteSsoProviderResponse} DeleteSsoProviderResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSsoProviderResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.DeleteSsoProviderResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteSsoProviderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.DeleteSsoProviderResponse} DeleteSsoProviderResponse
                 */
                DeleteSsoProviderResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.DeleteSsoProviderResponse)
                        return d;
                    return new $root.streem.api.global.DeleteSsoProviderResponse();
                };

                /**
                 * Creates a plain object from a DeleteSsoProviderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {streem.api.global.DeleteSsoProviderResponse} m DeleteSsoProviderResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSsoProviderResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DeleteSsoProviderResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSsoProviderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteSsoProviderResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.DeleteSsoProviderResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSsoProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.DeleteSsoProviderResponse";
                };

                return DeleteSsoProviderResponse;
            })();

            global.ListSsoProviderCompanyMappingsRequest = (function() {

                /**
                 * Properties of a ListSsoProviderCompanyMappingsRequest.
                 * @memberof streem.api.global
                 * @interface IListSsoProviderCompanyMappingsRequest
                 * @property {string|null} [ssoProviderCode] ListSsoProviderCompanyMappingsRequest ssoProviderCode
                 */

                /**
                 * Constructs a new ListSsoProviderCompanyMappingsRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListSsoProviderCompanyMappingsRequest.
                 * @implements IListSsoProviderCompanyMappingsRequest
                 * @constructor
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsRequest=} [p] Properties to set
                 */
                function ListSsoProviderCompanyMappingsRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListSsoProviderCompanyMappingsRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @instance
                 */
                ListSsoProviderCompanyMappingsRequest.prototype.ssoProviderCode = "";

                /**
                 * Creates a new ListSsoProviderCompanyMappingsRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsRequest=} [properties] Properties to set
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest instance
                 */
                ListSsoProviderCompanyMappingsRequest.create = function create(properties) {
                    return new ListSsoProviderCompanyMappingsRequest(properties);
                };

                /**
                 * Encodes the specified ListSsoProviderCompanyMappingsRequest message. Does not implicitly {@link streem.api.global.ListSsoProviderCompanyMappingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsRequest} m ListSsoProviderCompanyMappingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListSsoProviderCompanyMappingsRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    return w;
                };

                /**
                 * Decodes a ListSsoProviderCompanyMappingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListSsoProviderCompanyMappingsRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListSsoProviderCompanyMappingsRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListSsoProviderCompanyMappingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsRequest} ListSsoProviderCompanyMappingsRequest
                 */
                ListSsoProviderCompanyMappingsRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListSsoProviderCompanyMappingsRequest)
                        return d;
                    var m = new $root.streem.api.global.ListSsoProviderCompanyMappingsRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListSsoProviderCompanyMappingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {streem.api.global.ListSsoProviderCompanyMappingsRequest} m ListSsoProviderCompanyMappingsRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSsoProviderCompanyMappingsRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    return d;
                };

                /**
                 * Converts this ListSsoProviderCompanyMappingsRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSsoProviderCompanyMappingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListSsoProviderCompanyMappingsRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListSsoProviderCompanyMappingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListSsoProviderCompanyMappingsRequest";
                };

                return ListSsoProviderCompanyMappingsRequest;
            })();

            global.ListSsoProviderCompanyMappingsResponse = (function() {

                /**
                 * Properties of a ListSsoProviderCompanyMappingsResponse.
                 * @memberof streem.api.global
                 * @interface IListSsoProviderCompanyMappingsResponse
                 * @property {Array.<streem.api.ISsoProviderCompanyMapping>|null} [ssoProviderCompanyMappings] ListSsoProviderCompanyMappingsResponse ssoProviderCompanyMappings
                 */

                /**
                 * Constructs a new ListSsoProviderCompanyMappingsResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListSsoProviderCompanyMappingsResponse.
                 * @implements IListSsoProviderCompanyMappingsResponse
                 * @constructor
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsResponse=} [p] Properties to set
                 */
                function ListSsoProviderCompanyMappingsResponse(p) {
                    this.ssoProviderCompanyMappings = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListSsoProviderCompanyMappingsResponse ssoProviderCompanyMappings.
                 * @member {Array.<streem.api.ISsoProviderCompanyMapping>} ssoProviderCompanyMappings
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @instance
                 */
                ListSsoProviderCompanyMappingsResponse.prototype.ssoProviderCompanyMappings = $util.emptyArray;

                /**
                 * Creates a new ListSsoProviderCompanyMappingsResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsResponse=} [properties] Properties to set
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse instance
                 */
                ListSsoProviderCompanyMappingsResponse.create = function create(properties) {
                    return new ListSsoProviderCompanyMappingsResponse(properties);
                };

                /**
                 * Encodes the specified ListSsoProviderCompanyMappingsResponse message. Does not implicitly {@link streem.api.global.ListSsoProviderCompanyMappingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {streem.api.global.IListSsoProviderCompanyMappingsResponse} m ListSsoProviderCompanyMappingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListSsoProviderCompanyMappingsResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCompanyMappings != null && m.ssoProviderCompanyMappings.length) {
                        for (var i = 0; i < m.ssoProviderCompanyMappings.length; ++i)
                            $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMappings[i], w.uint32(10).fork()).ldelim();
                    }
                    return w;
                };

                /**
                 * Decodes a ListSsoProviderCompanyMappingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListSsoProviderCompanyMappingsResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListSsoProviderCompanyMappingsResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.ssoProviderCompanyMappings && m.ssoProviderCompanyMappings.length))
                                    m.ssoProviderCompanyMappings = [];
                                m.ssoProviderCompanyMappings.push($root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListSsoProviderCompanyMappingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListSsoProviderCompanyMappingsResponse} ListSsoProviderCompanyMappingsResponse
                 */
                ListSsoProviderCompanyMappingsResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListSsoProviderCompanyMappingsResponse)
                        return d;
                    var m = new $root.streem.api.global.ListSsoProviderCompanyMappingsResponse();
                    if (d.ssoProviderCompanyMappings) {
                        if (!Array.isArray(d.ssoProviderCompanyMappings))
                            throw TypeError(".streem.api.global.ListSsoProviderCompanyMappingsResponse.ssoProviderCompanyMappings: array expected");
                        m.ssoProviderCompanyMappings = [];
                        for (var i = 0; i < d.ssoProviderCompanyMappings.length; ++i) {
                            if (typeof d.ssoProviderCompanyMappings[i] !== "object")
                                throw TypeError(".streem.api.global.ListSsoProviderCompanyMappingsResponse.ssoProviderCompanyMappings: object expected");
                            m.ssoProviderCompanyMappings[i] = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMappings[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListSsoProviderCompanyMappingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {streem.api.global.ListSsoProviderCompanyMappingsResponse} m ListSsoProviderCompanyMappingsResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSsoProviderCompanyMappingsResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.ssoProviderCompanyMappings = [];
                    }
                    if (m.ssoProviderCompanyMappings && m.ssoProviderCompanyMappings.length) {
                        d.ssoProviderCompanyMappings = [];
                        for (var j = 0; j < m.ssoProviderCompanyMappings.length; ++j) {
                            d.ssoProviderCompanyMappings[j] = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMappings[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this ListSsoProviderCompanyMappingsResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSsoProviderCompanyMappingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListSsoProviderCompanyMappingsResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListSsoProviderCompanyMappingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListSsoProviderCompanyMappingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListSsoProviderCompanyMappingsResponse";
                };

                return ListSsoProviderCompanyMappingsResponse;
            })();

            global.GetSsoProviderCompanyMappingRequest = (function() {

                /**
                 * Properties of a GetSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @interface IGetSsoProviderCompanyMappingRequest
                 * @property {string|null} [ssoProviderCode] GetSsoProviderCompanyMappingRequest ssoProviderCode
                 * @property {string|null} [companyCode] GetSsoProviderCompanyMappingRequest companyCode
                 */

                /**
                 * Constructs a new GetSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetSsoProviderCompanyMappingRequest.
                 * @implements IGetSsoProviderCompanyMappingRequest
                 * @constructor
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingRequest=} [p] Properties to set
                 */
                function GetSsoProviderCompanyMappingRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetSsoProviderCompanyMappingRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @instance
                 */
                GetSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

                /**
                 * GetSsoProviderCompanyMappingRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @instance
                 */
                GetSsoProviderCompanyMappingRequest.prototype.companyCode = "";

                /**
                 * Creates a new GetSsoProviderCompanyMappingRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest instance
                 */
                GetSsoProviderCompanyMappingRequest.create = function create(properties) {
                    return new GetSsoProviderCompanyMappingRequest(properties);
                };

                /**
                 * Encodes the specified GetSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.global.GetSsoProviderCompanyMappingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingRequest} m GetSsoProviderCompanyMappingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    return w;
                };

                /**
                 * Decodes a GetSsoProviderCompanyMappingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetSsoProviderCompanyMappingRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingRequest} GetSsoProviderCompanyMappingRequest
                 */
                GetSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetSsoProviderCompanyMappingRequest)
                        return d;
                    var m = new $root.streem.api.global.GetSsoProviderCompanyMappingRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.GetSsoProviderCompanyMappingRequest} m GetSsoProviderCompanyMappingRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                        d.companyCode = "";
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    return d;
                };

                /**
                 * Converts this GetSsoProviderCompanyMappingRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetSsoProviderCompanyMappingRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetSsoProviderCompanyMappingRequest";
                };

                return GetSsoProviderCompanyMappingRequest;
            })();

            global.GetSsoProviderCompanyMappingResponse = (function() {

                /**
                 * Properties of a GetSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @interface IGetSsoProviderCompanyMappingResponse
                 * @property {streem.api.ISsoProviderCompanyMapping|null} [ssoProviderCompanyMapping] GetSsoProviderCompanyMappingResponse ssoProviderCompanyMapping
                 */

                /**
                 * Constructs a new GetSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetSsoProviderCompanyMappingResponse.
                 * @implements IGetSsoProviderCompanyMappingResponse
                 * @constructor
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingResponse=} [p] Properties to set
                 */
                function GetSsoProviderCompanyMappingResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetSsoProviderCompanyMappingResponse ssoProviderCompanyMapping.
                 * @member {streem.api.ISsoProviderCompanyMapping|null|undefined} ssoProviderCompanyMapping
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @instance
                 */
                GetSsoProviderCompanyMappingResponse.prototype.ssoProviderCompanyMapping = null;

                /**
                 * Creates a new GetSsoProviderCompanyMappingResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse instance
                 */
                GetSsoProviderCompanyMappingResponse.create = function create(properties) {
                    return new GetSsoProviderCompanyMappingResponse(properties);
                };

                /**
                 * Encodes the specified GetSsoProviderCompanyMappingResponse message. Does not implicitly {@link streem.api.global.GetSsoProviderCompanyMappingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IGetSsoProviderCompanyMappingResponse} m GetSsoProviderCompanyMappingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSsoProviderCompanyMappingResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCompanyMapping != null && Object.hasOwnProperty.call(m, "ssoProviderCompanyMapping"))
                        $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMapping, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetSsoProviderCompanyMappingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSsoProviderCompanyMappingResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetSsoProviderCompanyMappingResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetSsoProviderCompanyMappingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetSsoProviderCompanyMappingResponse} GetSsoProviderCompanyMappingResponse
                 */
                GetSsoProviderCompanyMappingResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetSsoProviderCompanyMappingResponse)
                        return d;
                    var m = new $root.streem.api.global.GetSsoProviderCompanyMappingResponse();
                    if (d.ssoProviderCompanyMapping != null) {
                        if (typeof d.ssoProviderCompanyMapping !== "object")
                            throw TypeError(".streem.api.global.GetSsoProviderCompanyMappingResponse.ssoProviderCompanyMapping: object expected");
                        m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMapping);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetSsoProviderCompanyMappingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.GetSsoProviderCompanyMappingResponse} m GetSsoProviderCompanyMappingResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSsoProviderCompanyMappingResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCompanyMapping = null;
                    }
                    if (m.ssoProviderCompanyMapping != null && m.hasOwnProperty("ssoProviderCompanyMapping")) {
                        d.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMapping, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetSsoProviderCompanyMappingResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSsoProviderCompanyMappingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetSsoProviderCompanyMappingResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetSsoProviderCompanyMappingResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSsoProviderCompanyMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetSsoProviderCompanyMappingResponse";
                };

                return GetSsoProviderCompanyMappingResponse;
            })();

            global.UpdateSsoProviderCompanyMappingRequest = (function() {

                /**
                 * Properties of an UpdateSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @interface IUpdateSsoProviderCompanyMappingRequest
                 * @property {string|null} [ssoProviderCode] UpdateSsoProviderCompanyMappingRequest ssoProviderCode
                 * @property {string|null} [companyCode] UpdateSsoProviderCompanyMappingRequest companyCode
                 * @property {boolean|null} [automaticRegistration] UpdateSsoProviderCompanyMappingRequest automaticRegistration
                 */

                /**
                 * Constructs a new UpdateSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents an UpdateSsoProviderCompanyMappingRequest.
                 * @implements IUpdateSsoProviderCompanyMappingRequest
                 * @constructor
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingRequest=} [p] Properties to set
                 */
                function UpdateSsoProviderCompanyMappingRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UpdateSsoProviderCompanyMappingRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @instance
                 */
                UpdateSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

                /**
                 * UpdateSsoProviderCompanyMappingRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @instance
                 */
                UpdateSsoProviderCompanyMappingRequest.prototype.companyCode = "";

                /**
                 * UpdateSsoProviderCompanyMappingRequest automaticRegistration.
                 * @member {boolean} automaticRegistration
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @instance
                 */
                UpdateSsoProviderCompanyMappingRequest.prototype.automaticRegistration = false;

                /**
                 * Creates a new UpdateSsoProviderCompanyMappingRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingRequest=} [properties] Properties to set
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest instance
                 */
                UpdateSsoProviderCompanyMappingRequest.create = function create(properties) {
                    return new UpdateSsoProviderCompanyMappingRequest(properties);
                };

                /**
                 * Encodes the specified UpdateSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.global.UpdateSsoProviderCompanyMappingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingRequest} m UpdateSsoProviderCompanyMappingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    if (m.automaticRegistration != null && Object.hasOwnProperty.call(m, "automaticRegistration"))
                        w.uint32(24).bool(m.automaticRegistration);
                    return w;
                };

                /**
                 * Decodes an UpdateSsoProviderCompanyMappingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.UpdateSsoProviderCompanyMappingRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 3: {
                                m.automaticRegistration = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an UpdateSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingRequest} UpdateSsoProviderCompanyMappingRequest
                 */
                UpdateSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.UpdateSsoProviderCompanyMappingRequest)
                        return d;
                    var m = new $root.streem.api.global.UpdateSsoProviderCompanyMappingRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.automaticRegistration != null) {
                        m.automaticRegistration = Boolean(d.automaticRegistration);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an UpdateSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.UpdateSsoProviderCompanyMappingRequest} m UpdateSsoProviderCompanyMappingRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                        d.companyCode = "";
                        d.automaticRegistration = false;
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.automaticRegistration != null && m.hasOwnProperty("automaticRegistration")) {
                        d.automaticRegistration = m.automaticRegistration;
                    }
                    return d;
                };

                /**
                 * Converts this UpdateSsoProviderCompanyMappingRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateSsoProviderCompanyMappingRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.UpdateSsoProviderCompanyMappingRequest";
                };

                return UpdateSsoProviderCompanyMappingRequest;
            })();

            global.UpdateSsoProviderCompanyMappingResponse = (function() {

                /**
                 * Properties of an UpdateSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @interface IUpdateSsoProviderCompanyMappingResponse
                 * @property {streem.api.ISsoProviderCompanyMapping|null} [ssoProviderCompanyMapping] UpdateSsoProviderCompanyMappingResponse ssoProviderCompanyMapping
                 */

                /**
                 * Constructs a new UpdateSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents an UpdateSsoProviderCompanyMappingResponse.
                 * @implements IUpdateSsoProviderCompanyMappingResponse
                 * @constructor
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingResponse=} [p] Properties to set
                 */
                function UpdateSsoProviderCompanyMappingResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UpdateSsoProviderCompanyMappingResponse ssoProviderCompanyMapping.
                 * @member {streem.api.ISsoProviderCompanyMapping|null|undefined} ssoProviderCompanyMapping
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @instance
                 */
                UpdateSsoProviderCompanyMappingResponse.prototype.ssoProviderCompanyMapping = null;

                /**
                 * Creates a new UpdateSsoProviderCompanyMappingResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingResponse=} [properties] Properties to set
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse instance
                 */
                UpdateSsoProviderCompanyMappingResponse.create = function create(properties) {
                    return new UpdateSsoProviderCompanyMappingResponse(properties);
                };

                /**
                 * Encodes the specified UpdateSsoProviderCompanyMappingResponse message. Does not implicitly {@link streem.api.global.UpdateSsoProviderCompanyMappingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IUpdateSsoProviderCompanyMappingResponse} m UpdateSsoProviderCompanyMappingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSsoProviderCompanyMappingResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCompanyMapping != null && Object.hasOwnProperty.call(m, "ssoProviderCompanyMapping"))
                        $root.streem.api.SsoProviderCompanyMapping.encode(m.ssoProviderCompanyMapping, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an UpdateSsoProviderCompanyMappingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSsoProviderCompanyMappingResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.UpdateSsoProviderCompanyMappingResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an UpdateSsoProviderCompanyMappingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.UpdateSsoProviderCompanyMappingResponse} UpdateSsoProviderCompanyMappingResponse
                 */
                UpdateSsoProviderCompanyMappingResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.UpdateSsoProviderCompanyMappingResponse)
                        return d;
                    var m = new $root.streem.api.global.UpdateSsoProviderCompanyMappingResponse();
                    if (d.ssoProviderCompanyMapping != null) {
                        if (typeof d.ssoProviderCompanyMapping !== "object")
                            throw TypeError(".streem.api.global.UpdateSsoProviderCompanyMappingResponse.ssoProviderCompanyMapping: object expected");
                        m.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.fromObject(d.ssoProviderCompanyMapping);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an UpdateSsoProviderCompanyMappingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.UpdateSsoProviderCompanyMappingResponse} m UpdateSsoProviderCompanyMappingResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSsoProviderCompanyMappingResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCompanyMapping = null;
                    }
                    if (m.ssoProviderCompanyMapping != null && m.hasOwnProperty("ssoProviderCompanyMapping")) {
                        d.ssoProviderCompanyMapping = $root.streem.api.SsoProviderCompanyMapping.toObject(m.ssoProviderCompanyMapping, o);
                    }
                    return d;
                };

                /**
                 * Converts this UpdateSsoProviderCompanyMappingResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSsoProviderCompanyMappingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateSsoProviderCompanyMappingResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.UpdateSsoProviderCompanyMappingResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateSsoProviderCompanyMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.UpdateSsoProviderCompanyMappingResponse";
                };

                return UpdateSsoProviderCompanyMappingResponse;
            })();

            global.DeleteSsoProviderCompanyMappingRequest = (function() {

                /**
                 * Properties of a DeleteSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @interface IDeleteSsoProviderCompanyMappingRequest
                 * @property {string|null} [ssoProviderCode] DeleteSsoProviderCompanyMappingRequest ssoProviderCode
                 * @property {string|null} [companyCode] DeleteSsoProviderCompanyMappingRequest companyCode
                 */

                /**
                 * Constructs a new DeleteSsoProviderCompanyMappingRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a DeleteSsoProviderCompanyMappingRequest.
                 * @implements IDeleteSsoProviderCompanyMappingRequest
                 * @constructor
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingRequest=} [p] Properties to set
                 */
                function DeleteSsoProviderCompanyMappingRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * DeleteSsoProviderCompanyMappingRequest ssoProviderCode.
                 * @member {string} ssoProviderCode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @instance
                 */
                DeleteSsoProviderCompanyMappingRequest.prototype.ssoProviderCode = "";

                /**
                 * DeleteSsoProviderCompanyMappingRequest companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @instance
                 */
                DeleteSsoProviderCompanyMappingRequest.prototype.companyCode = "";

                /**
                 * Creates a new DeleteSsoProviderCompanyMappingRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingRequest=} [properties] Properties to set
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest instance
                 */
                DeleteSsoProviderCompanyMappingRequest.create = function create(properties) {
                    return new DeleteSsoProviderCompanyMappingRequest(properties);
                };

                /**
                 * Encodes the specified DeleteSsoProviderCompanyMappingRequest message. Does not implicitly {@link streem.api.global.DeleteSsoProviderCompanyMappingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingRequest} m DeleteSsoProviderCompanyMappingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSsoProviderCompanyMappingRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.ssoProviderCode != null && Object.hasOwnProperty.call(m, "ssoProviderCode"))
                        w.uint32(10).string(m.ssoProviderCode);
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    return w;
                };

                /**
                 * Decodes a DeleteSsoProviderCompanyMappingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSsoProviderCompanyMappingRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.DeleteSsoProviderCompanyMappingRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.ssoProviderCode = r.string();
                                break;
                            }
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteSsoProviderCompanyMappingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingRequest} DeleteSsoProviderCompanyMappingRequest
                 */
                DeleteSsoProviderCompanyMappingRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.DeleteSsoProviderCompanyMappingRequest)
                        return d;
                    var m = new $root.streem.api.global.DeleteSsoProviderCompanyMappingRequest();
                    if (d.ssoProviderCode != null) {
                        m.ssoProviderCode = String(d.ssoProviderCode);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a DeleteSsoProviderCompanyMappingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {streem.api.global.DeleteSsoProviderCompanyMappingRequest} m DeleteSsoProviderCompanyMappingRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSsoProviderCompanyMappingRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.ssoProviderCode = "";
                        d.companyCode = "";
                    }
                    if (m.ssoProviderCode != null && m.hasOwnProperty("ssoProviderCode")) {
                        d.ssoProviderCode = m.ssoProviderCode;
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    return d;
                };

                /**
                 * Converts this DeleteSsoProviderCompanyMappingRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSsoProviderCompanyMappingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteSsoProviderCompanyMappingRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSsoProviderCompanyMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.DeleteSsoProviderCompanyMappingRequest";
                };

                return DeleteSsoProviderCompanyMappingRequest;
            })();

            global.DeleteSsoProviderCompanyMappingResponse = (function() {

                /**
                 * Properties of a DeleteSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @interface IDeleteSsoProviderCompanyMappingResponse
                 */

                /**
                 * Constructs a new DeleteSsoProviderCompanyMappingResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a DeleteSsoProviderCompanyMappingResponse.
                 * @implements IDeleteSsoProviderCompanyMappingResponse
                 * @constructor
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingResponse=} [p] Properties to set
                 */
                function DeleteSsoProviderCompanyMappingResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new DeleteSsoProviderCompanyMappingResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingResponse=} [properties] Properties to set
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingResponse} DeleteSsoProviderCompanyMappingResponse instance
                 */
                DeleteSsoProviderCompanyMappingResponse.create = function create(properties) {
                    return new DeleteSsoProviderCompanyMappingResponse(properties);
                };

                /**
                 * Encodes the specified DeleteSsoProviderCompanyMappingResponse message. Does not implicitly {@link streem.api.global.DeleteSsoProviderCompanyMappingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.IDeleteSsoProviderCompanyMappingResponse} m DeleteSsoProviderCompanyMappingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSsoProviderCompanyMappingResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a DeleteSsoProviderCompanyMappingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingResponse} DeleteSsoProviderCompanyMappingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSsoProviderCompanyMappingResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.DeleteSsoProviderCompanyMappingResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a DeleteSsoProviderCompanyMappingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.DeleteSsoProviderCompanyMappingResponse} DeleteSsoProviderCompanyMappingResponse
                 */
                DeleteSsoProviderCompanyMappingResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.DeleteSsoProviderCompanyMappingResponse)
                        return d;
                    return new $root.streem.api.global.DeleteSsoProviderCompanyMappingResponse();
                };

                /**
                 * Creates a plain object from a DeleteSsoProviderCompanyMappingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {streem.api.global.DeleteSsoProviderCompanyMappingResponse} m DeleteSsoProviderCompanyMappingResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSsoProviderCompanyMappingResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DeleteSsoProviderCompanyMappingResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSsoProviderCompanyMappingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteSsoProviderCompanyMappingResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.DeleteSsoProviderCompanyMappingResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSsoProviderCompanyMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.DeleteSsoProviderCompanyMappingResponse";
                };

                return DeleteSsoProviderCompanyMappingResponse;
            })();

            global.GlobalIdentity = (function() {

                /**
                 * Constructs a new GlobalIdentity service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalIdentity
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalIdentity(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalIdentity.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalIdentity;

                /**
                 * Creates new GlobalIdentity service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalIdentity
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalIdentity} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalIdentity.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalIdentity#listLoginIdentityCompanies}.
                 * @memberof streem.api.global.GlobalIdentity
                 * @typedef ListLoginIdentityCompaniesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.ListLoginIdentityCompaniesResponse} [response] ListLoginIdentityCompaniesResponse
                 */

                /**
                 * Calls ListLoginIdentityCompanies.
                 * @function listLoginIdentityCompanies
                 * @memberof streem.api.global.GlobalIdentity
                 * @instance
                 * @param {streem.api.global.IListLoginIdentityCompaniesRequest} request ListLoginIdentityCompaniesRequest message or plain object
                 * @param {streem.api.global.GlobalIdentity.ListLoginIdentityCompaniesCallback} callback Node-style callback called with the error, if any, and ListLoginIdentityCompaniesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalIdentity.prototype.listLoginIdentityCompanies = function listLoginIdentityCompanies(request, callback) {
                    return this.rpcCall(listLoginIdentityCompanies, $root.streem.api.global.ListLoginIdentityCompaniesRequest, $root.streem.api.global.ListLoginIdentityCompaniesResponse, request, callback);
                }, "name", { value: "ListLoginIdentityCompanies" });

                /**
                 * Calls ListLoginIdentityCompanies.
                 * @function listLoginIdentityCompanies
                 * @memberof streem.api.global.GlobalIdentity
                 * @instance
                 * @param {streem.api.global.IListLoginIdentityCompaniesRequest} request ListLoginIdentityCompaniesRequest message or plain object
                 * @returns {Promise<streem.api.global.ListLoginIdentityCompaniesResponse>} Promise
                 * @variation 2
                 */

                return GlobalIdentity;
            })();

            global.ListLoginIdentityCompaniesRequest = (function() {

                /**
                 * Properties of a ListLoginIdentityCompaniesRequest.
                 * @memberof streem.api.global
                 * @interface IListLoginIdentityCompaniesRequest
                 * @property {string|null} [loginIdentitySid] ListLoginIdentityCompaniesRequest loginIdentitySid
                 * @property {string|null} [pageToken] ListLoginIdentityCompaniesRequest pageToken
                 * @property {number|null} [pageSize] ListLoginIdentityCompaniesRequest pageSize
                 * @property {boolean|null} [switchWorkspacesEnabled] ListLoginIdentityCompaniesRequest switchWorkspacesEnabled
                 * @property {string|null} [forClient] ListLoginIdentityCompaniesRequest forClient
                 */

                /**
                 * Constructs a new ListLoginIdentityCompaniesRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListLoginIdentityCompaniesRequest.
                 * @implements IListLoginIdentityCompaniesRequest
                 * @constructor
                 * @param {streem.api.global.IListLoginIdentityCompaniesRequest=} [p] Properties to set
                 */
                function ListLoginIdentityCompaniesRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListLoginIdentityCompaniesRequest loginIdentitySid.
                 * @member {string} loginIdentitySid
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 */
                ListLoginIdentityCompaniesRequest.prototype.loginIdentitySid = "";

                /**
                 * ListLoginIdentityCompaniesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 */
                ListLoginIdentityCompaniesRequest.prototype.pageToken = "";

                /**
                 * ListLoginIdentityCompaniesRequest pageSize.
                 * @member {number} pageSize
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 */
                ListLoginIdentityCompaniesRequest.prototype.pageSize = 0;

                /**
                 * ListLoginIdentityCompaniesRequest switchWorkspacesEnabled.
                 * @member {boolean} switchWorkspacesEnabled
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 */
                ListLoginIdentityCompaniesRequest.prototype.switchWorkspacesEnabled = false;

                /**
                 * ListLoginIdentityCompaniesRequest forClient.
                 * @member {string} forClient
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 */
                ListLoginIdentityCompaniesRequest.prototype.forClient = "";

                /**
                 * Creates a new ListLoginIdentityCompaniesRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {streem.api.global.IListLoginIdentityCompaniesRequest=} [properties] Properties to set
                 * @returns {streem.api.global.ListLoginIdentityCompaniesRequest} ListLoginIdentityCompaniesRequest instance
                 */
                ListLoginIdentityCompaniesRequest.create = function create(properties) {
                    return new ListLoginIdentityCompaniesRequest(properties);
                };

                /**
                 * Encodes the specified ListLoginIdentityCompaniesRequest message. Does not implicitly {@link streem.api.global.ListLoginIdentityCompaniesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {streem.api.global.IListLoginIdentityCompaniesRequest} m ListLoginIdentityCompaniesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListLoginIdentityCompaniesRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.loginIdentitySid != null && Object.hasOwnProperty.call(m, "loginIdentitySid"))
                        w.uint32(10).string(m.loginIdentitySid);
                    if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                        w.uint32(18).string(m.pageToken);
                    if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                        w.uint32(24).int32(m.pageSize);
                    if (m.switchWorkspacesEnabled != null && Object.hasOwnProperty.call(m, "switchWorkspacesEnabled"))
                        w.uint32(32).bool(m.switchWorkspacesEnabled);
                    if (m.forClient != null && Object.hasOwnProperty.call(m, "forClient"))
                        w.uint32(42).string(m.forClient);
                    return w;
                };

                /**
                 * Decodes a ListLoginIdentityCompaniesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListLoginIdentityCompaniesRequest} ListLoginIdentityCompaniesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListLoginIdentityCompaniesRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListLoginIdentityCompaniesRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.loginIdentitySid = r.string();
                                break;
                            }
                        case 2: {
                                m.pageToken = r.string();
                                break;
                            }
                        case 3: {
                                m.pageSize = r.int32();
                                break;
                            }
                        case 4: {
                                m.switchWorkspacesEnabled = r.bool();
                                break;
                            }
                        case 5: {
                                m.forClient = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListLoginIdentityCompaniesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListLoginIdentityCompaniesRequest} ListLoginIdentityCompaniesRequest
                 */
                ListLoginIdentityCompaniesRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListLoginIdentityCompaniesRequest)
                        return d;
                    var m = new $root.streem.api.global.ListLoginIdentityCompaniesRequest();
                    if (d.loginIdentitySid != null) {
                        m.loginIdentitySid = String(d.loginIdentitySid);
                    }
                    if (d.pageToken != null) {
                        m.pageToken = String(d.pageToken);
                    }
                    if (d.pageSize != null) {
                        m.pageSize = d.pageSize | 0;
                    }
                    if (d.switchWorkspacesEnabled != null) {
                        m.switchWorkspacesEnabled = Boolean(d.switchWorkspacesEnabled);
                    }
                    if (d.forClient != null) {
                        m.forClient = String(d.forClient);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListLoginIdentityCompaniesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {streem.api.global.ListLoginIdentityCompaniesRequest} m ListLoginIdentityCompaniesRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLoginIdentityCompaniesRequest.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.loginIdentitySid = "";
                        d.pageToken = "";
                        d.pageSize = 0;
                        d.switchWorkspacesEnabled = false;
                        d.forClient = "";
                    }
                    if (m.loginIdentitySid != null && m.hasOwnProperty("loginIdentitySid")) {
                        d.loginIdentitySid = m.loginIdentitySid;
                    }
                    if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                        d.pageToken = m.pageToken;
                    }
                    if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                        d.pageSize = m.pageSize;
                    }
                    if (m.switchWorkspacesEnabled != null && m.hasOwnProperty("switchWorkspacesEnabled")) {
                        d.switchWorkspacesEnabled = m.switchWorkspacesEnabled;
                    }
                    if (m.forClient != null && m.hasOwnProperty("forClient")) {
                        d.forClient = m.forClient;
                    }
                    return d;
                };

                /**
                 * Converts this ListLoginIdentityCompaniesRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLoginIdentityCompaniesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListLoginIdentityCompaniesRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListLoginIdentityCompaniesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListLoginIdentityCompaniesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListLoginIdentityCompaniesRequest";
                };

                return ListLoginIdentityCompaniesRequest;
            })();

            global.ListLoginIdentityCompaniesResponse = (function() {

                /**
                 * Properties of a ListLoginIdentityCompaniesResponse.
                 * @memberof streem.api.global
                 * @interface IListLoginIdentityCompaniesResponse
                 * @property {Array.<streem.api.global.ICompanyRegistration>|null} [companies] ListLoginIdentityCompaniesResponse companies
                 * @property {string|null} [nextPageToken] ListLoginIdentityCompaniesResponse nextPageToken
                 * @property {number|null} [totalSize] ListLoginIdentityCompaniesResponse totalSize
                 */

                /**
                 * Constructs a new ListLoginIdentityCompaniesResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a ListLoginIdentityCompaniesResponse.
                 * @implements IListLoginIdentityCompaniesResponse
                 * @constructor
                 * @param {streem.api.global.IListLoginIdentityCompaniesResponse=} [p] Properties to set
                 */
                function ListLoginIdentityCompaniesResponse(p) {
                    this.companies = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ListLoginIdentityCompaniesResponse companies.
                 * @member {Array.<streem.api.global.ICompanyRegistration>} companies
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @instance
                 */
                ListLoginIdentityCompaniesResponse.prototype.companies = $util.emptyArray;

                /**
                 * ListLoginIdentityCompaniesResponse nextPageToken.
                 * @member {string} nextPageToken
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @instance
                 */
                ListLoginIdentityCompaniesResponse.prototype.nextPageToken = "";

                /**
                 * ListLoginIdentityCompaniesResponse totalSize.
                 * @member {number} totalSize
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @instance
                 */
                ListLoginIdentityCompaniesResponse.prototype.totalSize = 0;

                /**
                 * Creates a new ListLoginIdentityCompaniesResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {streem.api.global.IListLoginIdentityCompaniesResponse=} [properties] Properties to set
                 * @returns {streem.api.global.ListLoginIdentityCompaniesResponse} ListLoginIdentityCompaniesResponse instance
                 */
                ListLoginIdentityCompaniesResponse.create = function create(properties) {
                    return new ListLoginIdentityCompaniesResponse(properties);
                };

                /**
                 * Encodes the specified ListLoginIdentityCompaniesResponse message. Does not implicitly {@link streem.api.global.ListLoginIdentityCompaniesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {streem.api.global.IListLoginIdentityCompaniesResponse} m ListLoginIdentityCompaniesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListLoginIdentityCompaniesResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.companies != null && m.companies.length) {
                        for (var i = 0; i < m.companies.length; ++i)
                            $root.streem.api.global.CompanyRegistration.encode(m.companies[i], w.uint32(10).fork()).ldelim();
                    }
                    if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                        w.uint32(18).string(m.nextPageToken);
                    if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                        w.uint32(24).int32(m.totalSize);
                    return w;
                };

                /**
                 * Decodes a ListLoginIdentityCompaniesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.ListLoginIdentityCompaniesResponse} ListLoginIdentityCompaniesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListLoginIdentityCompaniesResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.ListLoginIdentityCompaniesResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.companies && m.companies.length))
                                    m.companies = [];
                                m.companies.push($root.streem.api.global.CompanyRegistration.decode(r, r.uint32()));
                                break;
                            }
                        case 2: {
                                m.nextPageToken = r.string();
                                break;
                            }
                        case 3: {
                                m.totalSize = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ListLoginIdentityCompaniesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.ListLoginIdentityCompaniesResponse} ListLoginIdentityCompaniesResponse
                 */
                ListLoginIdentityCompaniesResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.ListLoginIdentityCompaniesResponse)
                        return d;
                    var m = new $root.streem.api.global.ListLoginIdentityCompaniesResponse();
                    if (d.companies) {
                        if (!Array.isArray(d.companies))
                            throw TypeError(".streem.api.global.ListLoginIdentityCompaniesResponse.companies: array expected");
                        m.companies = [];
                        for (var i = 0; i < d.companies.length; ++i) {
                            if (typeof d.companies[i] !== "object")
                                throw TypeError(".streem.api.global.ListLoginIdentityCompaniesResponse.companies: object expected");
                            m.companies[i] = $root.streem.api.global.CompanyRegistration.fromObject(d.companies[i]);
                        }
                    }
                    if (d.nextPageToken != null) {
                        m.nextPageToken = String(d.nextPageToken);
                    }
                    if (d.totalSize != null) {
                        m.totalSize = d.totalSize | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ListLoginIdentityCompaniesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {streem.api.global.ListLoginIdentityCompaniesResponse} m ListLoginIdentityCompaniesResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLoginIdentityCompaniesResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.companies = [];
                    }
                    if (o.defaults) {
                        d.nextPageToken = "";
                        d.totalSize = 0;
                    }
                    if (m.companies && m.companies.length) {
                        d.companies = [];
                        for (var j = 0; j < m.companies.length; ++j) {
                            d.companies[j] = $root.streem.api.global.CompanyRegistration.toObject(m.companies[j], o);
                        }
                    }
                    if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                        d.nextPageToken = m.nextPageToken;
                    }
                    if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                        d.totalSize = m.totalSize;
                    }
                    return d;
                };

                /**
                 * Converts this ListLoginIdentityCompaniesResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLoginIdentityCompaniesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ListLoginIdentityCompaniesResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.ListLoginIdentityCompaniesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListLoginIdentityCompaniesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.ListLoginIdentityCompaniesResponse";
                };

                return ListLoginIdentityCompaniesResponse;
            })();

            global.GlobalCentralLogin = (function() {

                /**
                 * Constructs a new GlobalCentralLogin service.
                 * @memberof streem.api.global
                 * @classdesc Represents a GlobalCentralLogin
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function GlobalCentralLogin(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (GlobalCentralLogin.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GlobalCentralLogin;

                /**
                 * Creates new GlobalCentralLogin service using the specified rpc implementation.
                 * @function create
                 * @memberof streem.api.global.GlobalCentralLogin
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {GlobalCentralLogin} RPC service. Useful where requests and/or responses are streamed.
                 */
                GlobalCentralLogin.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link streem.api.global.GlobalCentralLogin#getCentralLoginPublicLoginConfig}.
                 * @memberof streem.api.global.GlobalCentralLogin
                 * @typedef GetCentralLoginPublicLoginConfigCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {streem.api.global.GetCentralLoginPublicLoginConfigResponse} [response] GetCentralLoginPublicLoginConfigResponse
                 */

                /**
                 * Calls GetCentralLoginPublicLoginConfig.
                 * @function getCentralLoginPublicLoginConfig
                 * @memberof streem.api.global.GlobalCentralLogin
                 * @instance
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigRequest} request GetCentralLoginPublicLoginConfigRequest message or plain object
                 * @param {streem.api.global.GlobalCentralLogin.GetCentralLoginPublicLoginConfigCallback} callback Node-style callback called with the error, if any, and GetCentralLoginPublicLoginConfigResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(GlobalCentralLogin.prototype.getCentralLoginPublicLoginConfig = function getCentralLoginPublicLoginConfig(request, callback) {
                    return this.rpcCall(getCentralLoginPublicLoginConfig, $root.streem.api.global.GetCentralLoginPublicLoginConfigRequest, $root.streem.api.global.GetCentralLoginPublicLoginConfigResponse, request, callback);
                }, "name", { value: "GetCentralLoginPublicLoginConfig" });

                /**
                 * Calls GetCentralLoginPublicLoginConfig.
                 * @function getCentralLoginPublicLoginConfig
                 * @memberof streem.api.global.GlobalCentralLogin
                 * @instance
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigRequest} request GetCentralLoginPublicLoginConfigRequest message or plain object
                 * @returns {Promise<streem.api.global.GetCentralLoginPublicLoginConfigResponse>} Promise
                 * @variation 2
                 */

                return GlobalCentralLogin;
            })();

            global.GetCentralLoginPublicLoginConfigRequest = (function() {

                /**
                 * Properties of a GetCentralLoginPublicLoginConfigRequest.
                 * @memberof streem.api.global
                 * @interface IGetCentralLoginPublicLoginConfigRequest
                 */

                /**
                 * Constructs a new GetCentralLoginPublicLoginConfigRequest.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetCentralLoginPublicLoginConfigRequest.
                 * @implements IGetCentralLoginPublicLoginConfigRequest
                 * @constructor
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigRequest=} [p] Properties to set
                 */
                function GetCentralLoginPublicLoginConfigRequest(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new GetCentralLoginPublicLoginConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigRequest=} [properties] Properties to set
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest instance
                 */
                GetCentralLoginPublicLoginConfigRequest.create = function create(properties) {
                    return new GetCentralLoginPublicLoginConfigRequest(properties);
                };

                /**
                 * Encodes the specified GetCentralLoginPublicLoginConfigRequest message. Does not implicitly {@link streem.api.global.GetCentralLoginPublicLoginConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigRequest} m GetCentralLoginPublicLoginConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCentralLoginPublicLoginConfigRequest.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                /**
                 * Decodes a GetCentralLoginPublicLoginConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCentralLoginPublicLoginConfigRequest.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetCentralLoginPublicLoginConfigRequest();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetCentralLoginPublicLoginConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigRequest} GetCentralLoginPublicLoginConfigRequest
                 */
                GetCentralLoginPublicLoginConfigRequest.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetCentralLoginPublicLoginConfigRequest)
                        return d;
                    return new $root.streem.api.global.GetCentralLoginPublicLoginConfigRequest();
                };

                /**
                 * Creates a plain object from a GetCentralLoginPublicLoginConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {streem.api.global.GetCentralLoginPublicLoginConfigRequest} m GetCentralLoginPublicLoginConfigRequest
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCentralLoginPublicLoginConfigRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCentralLoginPublicLoginConfigRequest to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCentralLoginPublicLoginConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCentralLoginPublicLoginConfigRequest
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCentralLoginPublicLoginConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetCentralLoginPublicLoginConfigRequest";
                };

                return GetCentralLoginPublicLoginConfigRequest;
            })();

            global.GetCentralLoginPublicLoginConfigResponse = (function() {

                /**
                 * Properties of a GetCentralLoginPublicLoginConfigResponse.
                 * @memberof streem.api.global
                 * @interface IGetCentralLoginPublicLoginConfigResponse
                 * @property {streem.api.IPublicLoginConfiguration|null} [loginConfiguration] GetCentralLoginPublicLoginConfigResponse loginConfiguration
                 */

                /**
                 * Constructs a new GetCentralLoginPublicLoginConfigResponse.
                 * @memberof streem.api.global
                 * @classdesc Represents a GetCentralLoginPublicLoginConfigResponse.
                 * @implements IGetCentralLoginPublicLoginConfigResponse
                 * @constructor
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigResponse=} [p] Properties to set
                 */
                function GetCentralLoginPublicLoginConfigResponse(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * GetCentralLoginPublicLoginConfigResponse loginConfiguration.
                 * @member {streem.api.IPublicLoginConfiguration|null|undefined} loginConfiguration
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @instance
                 */
                GetCentralLoginPublicLoginConfigResponse.prototype.loginConfiguration = null;

                /**
                 * Creates a new GetCentralLoginPublicLoginConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigResponse=} [properties] Properties to set
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse instance
                 */
                GetCentralLoginPublicLoginConfigResponse.create = function create(properties) {
                    return new GetCentralLoginPublicLoginConfigResponse(properties);
                };

                /**
                 * Encodes the specified GetCentralLoginPublicLoginConfigResponse message. Does not implicitly {@link streem.api.global.GetCentralLoginPublicLoginConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {streem.api.global.IGetCentralLoginPublicLoginConfigResponse} m GetCentralLoginPublicLoginConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCentralLoginPublicLoginConfigResponse.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.loginConfiguration != null && Object.hasOwnProperty.call(m, "loginConfiguration"))
                        $root.streem.api.PublicLoginConfiguration.encode(m.loginConfiguration, w.uint32(10).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes a GetCentralLoginPublicLoginConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCentralLoginPublicLoginConfigResponse.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.global.GetCentralLoginPublicLoginConfigResponse();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a GetCentralLoginPublicLoginConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.global.GetCentralLoginPublicLoginConfigResponse} GetCentralLoginPublicLoginConfigResponse
                 */
                GetCentralLoginPublicLoginConfigResponse.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.global.GetCentralLoginPublicLoginConfigResponse)
                        return d;
                    var m = new $root.streem.api.global.GetCentralLoginPublicLoginConfigResponse();
                    if (d.loginConfiguration != null) {
                        if (typeof d.loginConfiguration !== "object")
                            throw TypeError(".streem.api.global.GetCentralLoginPublicLoginConfigResponse.loginConfiguration: object expected");
                        m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.fromObject(d.loginConfiguration);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a GetCentralLoginPublicLoginConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {streem.api.global.GetCentralLoginPublicLoginConfigResponse} m GetCentralLoginPublicLoginConfigResponse
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCentralLoginPublicLoginConfigResponse.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.loginConfiguration = null;
                    }
                    if (m.loginConfiguration != null && m.hasOwnProperty("loginConfiguration")) {
                        d.loginConfiguration = $root.streem.api.PublicLoginConfiguration.toObject(m.loginConfiguration, o);
                    }
                    return d;
                };

                /**
                 * Converts this GetCentralLoginPublicLoginConfigResponse to JSON.
                 * @function toJSON
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCentralLoginPublicLoginConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetCentralLoginPublicLoginConfigResponse
                 * @function getTypeUrl
                 * @memberof streem.api.global.GetCentralLoginPublicLoginConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCentralLoginPublicLoginConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.global.GetCentralLoginPublicLoginConfigResponse";
                };

                return GetCentralLoginPublicLoginConfigResponse;
            })();

            return global;
        })();

        api.TypeformResponse = (function() {

            /**
             * Properties of a TypeformResponse.
             * @memberof streem.api
             * @interface ITypeformResponse
             * @property {google.protobuf.ITimestamp|null} [submittedAt] TypeformResponse submittedAt
             * @property {string|null} [formId] TypeformResponse formId
             * @property {Array.<streem.api.TypeformResponse.IAnswer>|null} [answers] TypeformResponse answers
             * @property {google.protobuf.IStruct|null} [payload] TypeformResponse payload
             */

            /**
             * Constructs a new TypeformResponse.
             * @memberof streem.api
             * @classdesc Represents a TypeformResponse.
             * @implements ITypeformResponse
             * @constructor
             * @param {streem.api.ITypeformResponse=} [p] Properties to set
             */
            function TypeformResponse(p) {
                this.answers = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TypeformResponse submittedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} submittedAt
             * @memberof streem.api.TypeformResponse
             * @instance
             */
            TypeformResponse.prototype.submittedAt = null;

            /**
             * TypeformResponse formId.
             * @member {string} formId
             * @memberof streem.api.TypeformResponse
             * @instance
             */
            TypeformResponse.prototype.formId = "";

            /**
             * TypeformResponse answers.
             * @member {Array.<streem.api.TypeformResponse.IAnswer>} answers
             * @memberof streem.api.TypeformResponse
             * @instance
             */
            TypeformResponse.prototype.answers = $util.emptyArray;

            /**
             * TypeformResponse payload.
             * @member {google.protobuf.IStruct|null|undefined} payload
             * @memberof streem.api.TypeformResponse
             * @instance
             */
            TypeformResponse.prototype.payload = null;

            /**
             * Creates a new TypeformResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {streem.api.ITypeformResponse=} [properties] Properties to set
             * @returns {streem.api.TypeformResponse} TypeformResponse instance
             */
            TypeformResponse.create = function create(properties) {
                return new TypeformResponse(properties);
            };

            /**
             * Encodes the specified TypeformResponse message. Does not implicitly {@link streem.api.TypeformResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {streem.api.ITypeformResponse} m TypeformResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TypeformResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.submittedAt != null && Object.hasOwnProperty.call(m, "submittedAt"))
                    $root.google.protobuf.Timestamp.encode(m.submittedAt, w.uint32(10).fork()).ldelim();
                if (m.formId != null && Object.hasOwnProperty.call(m, "formId"))
                    w.uint32(18).string(m.formId);
                if (m.answers != null && m.answers.length) {
                    for (var i = 0; i < m.answers.length; ++i)
                        $root.streem.api.TypeformResponse.Answer.encode(m.answers[i], w.uint32(26).fork()).ldelim();
                }
                if (m.payload != null && Object.hasOwnProperty.call(m, "payload"))
                    $root.google.protobuf.Struct.encode(m.payload, w.uint32(82).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TypeformResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TypeformResponse} TypeformResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TypeformResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.submittedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.formId = r.string();
                            break;
                        }
                    case 3: {
                            if (!(m.answers && m.answers.length))
                                m.answers = [];
                            m.answers.push($root.streem.api.TypeformResponse.Answer.decode(r, r.uint32()));
                            break;
                        }
                    case 10: {
                            m.payload = $root.google.protobuf.Struct.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TypeformResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TypeformResponse} TypeformResponse
             */
            TypeformResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TypeformResponse)
                    return d;
                var m = new $root.streem.api.TypeformResponse();
                if (d.submittedAt != null) {
                    if (typeof d.submittedAt !== "object")
                        throw TypeError(".streem.api.TypeformResponse.submittedAt: object expected");
                    m.submittedAt = $root.google.protobuf.Timestamp.fromObject(d.submittedAt);
                }
                if (d.formId != null) {
                    m.formId = String(d.formId);
                }
                if (d.answers) {
                    if (!Array.isArray(d.answers))
                        throw TypeError(".streem.api.TypeformResponse.answers: array expected");
                    m.answers = [];
                    for (var i = 0; i < d.answers.length; ++i) {
                        if (typeof d.answers[i] !== "object")
                            throw TypeError(".streem.api.TypeformResponse.answers: object expected");
                        m.answers[i] = $root.streem.api.TypeformResponse.Answer.fromObject(d.answers[i]);
                    }
                }
                if (d.payload != null) {
                    if (typeof d.payload !== "object")
                        throw TypeError(".streem.api.TypeformResponse.payload: object expected");
                    m.payload = $root.google.protobuf.Struct.fromObject(d.payload);
                }
                return m;
            };

            /**
             * Creates a plain object from a TypeformResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {streem.api.TypeformResponse} m TypeformResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TypeformResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.answers = [];
                }
                if (o.defaults) {
                    d.submittedAt = null;
                    d.formId = "";
                    d.payload = null;
                }
                if (m.submittedAt != null && m.hasOwnProperty("submittedAt")) {
                    d.submittedAt = $root.google.protobuf.Timestamp.toObject(m.submittedAt, o);
                }
                if (m.formId != null && m.hasOwnProperty("formId")) {
                    d.formId = m.formId;
                }
                if (m.answers && m.answers.length) {
                    d.answers = [];
                    for (var j = 0; j < m.answers.length; ++j) {
                        d.answers[j] = $root.streem.api.TypeformResponse.Answer.toObject(m.answers[j], o);
                    }
                }
                if (m.payload != null && m.hasOwnProperty("payload")) {
                    d.payload = $root.google.protobuf.Struct.toObject(m.payload, o);
                }
                return d;
            };

            /**
             * Converts this TypeformResponse to JSON.
             * @function toJSON
             * @memberof streem.api.TypeformResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TypeformResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TypeformResponse
             * @function getTypeUrl
             * @memberof streem.api.TypeformResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TypeformResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TypeformResponse";
            };

            TypeformResponse.Answer = (function() {

                /**
                 * Properties of an Answer.
                 * @memberof streem.api.TypeformResponse
                 * @interface IAnswer
                 * @property {string|null} [type] Answer type
                 * @property {streem.api.TypeformResponse.Answer.IField|null} [field] Answer field
                 * @property {string|null} [text] Answer text
                 * @property {streem.api.TypeformResponse.Answer.IChoice|null} [choice] Answer choice
                 * @property {streem.api.TypeformResponse.Answer.IChoices|null} [choices] Answer choices
                 * @property {string|null} [email] Answer email
                 * @property {string|null} [date] Answer date
                 * @property {boolean|null} [boolean] Answer boolean
                 * @property {string|null} [url] Answer url
                 * @property {number|null} [number] Answer number
                 * @property {string|null} [fileUrl] Answer fileUrl
                 * @property {streem.api.TypeformResponse.Answer.IPayment|null} [payment] Answer payment
                 */

                /**
                 * Constructs a new Answer.
                 * @memberof streem.api.TypeformResponse
                 * @classdesc Represents an Answer.
                 * @implements IAnswer
                 * @constructor
                 * @param {streem.api.TypeformResponse.IAnswer=} [p] Properties to set
                 */
                function Answer(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Answer type.
                 * @member {string} type
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.type = "";

                /**
                 * Answer field.
                 * @member {streem.api.TypeformResponse.Answer.IField|null|undefined} field
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.field = null;

                /**
                 * Answer text.
                 * @member {string|null|undefined} text
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.text = null;

                /**
                 * Answer choice.
                 * @member {streem.api.TypeformResponse.Answer.IChoice|null|undefined} choice
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.choice = null;

                /**
                 * Answer choices.
                 * @member {streem.api.TypeformResponse.Answer.IChoices|null|undefined} choices
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.choices = null;

                /**
                 * Answer email.
                 * @member {string|null|undefined} email
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.email = null;

                /**
                 * Answer date.
                 * @member {string|null|undefined} date
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.date = null;

                /**
                 * Answer boolean.
                 * @member {boolean|null|undefined} boolean
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.boolean = null;

                /**
                 * Answer url.
                 * @member {string|null|undefined} url
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.url = null;

                /**
                 * Answer number.
                 * @member {number|null|undefined} number
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.number = null;

                /**
                 * Answer fileUrl.
                 * @member {string|null|undefined} fileUrl
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.fileUrl = null;

                /**
                 * Answer payment.
                 * @member {streem.api.TypeformResponse.Answer.IPayment|null|undefined} payment
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Answer.prototype.payment = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Answer answer.
                 * @member {"text"|"choice"|"choices"|"email"|"date"|"boolean"|"url"|"number"|"fileUrl"|"payment"|undefined} answer
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 */
                Object.defineProperty(Answer.prototype, "answer", {
                    get: $util.oneOfGetter($oneOfFields = ["text", "choice", "choices", "email", "date", "boolean", "url", "number", "fileUrl", "payment"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Answer instance using the specified properties.
                 * @function create
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {streem.api.TypeformResponse.IAnswer=} [properties] Properties to set
                 * @returns {streem.api.TypeformResponse.Answer} Answer instance
                 */
                Answer.create = function create(properties) {
                    return new Answer(properties);
                };

                /**
                 * Encodes the specified Answer message. Does not implicitly {@link streem.api.TypeformResponse.Answer.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {streem.api.TypeformResponse.IAnswer} m Answer message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Answer.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                        w.uint32(10).string(m.type);
                    if (m.field != null && Object.hasOwnProperty.call(m, "field"))
                        $root.streem.api.TypeformResponse.Answer.Field.encode(m.field, w.uint32(18).fork()).ldelim();
                    if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                        w.uint32(82).string(m.text);
                    if (m.choice != null && Object.hasOwnProperty.call(m, "choice"))
                        $root.streem.api.TypeformResponse.Answer.Choice.encode(m.choice, w.uint32(90).fork()).ldelim();
                    if (m.choices != null && Object.hasOwnProperty.call(m, "choices"))
                        $root.streem.api.TypeformResponse.Answer.Choices.encode(m.choices, w.uint32(98).fork()).ldelim();
                    if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                        w.uint32(106).string(m.email);
                    if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                        w.uint32(114).string(m.date);
                    if (m.boolean != null && Object.hasOwnProperty.call(m, "boolean"))
                        w.uint32(120).bool(m.boolean);
                    if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                        w.uint32(130).string(m.url);
                    if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                        w.uint32(136).int32(m.number);
                    if (m.fileUrl != null && Object.hasOwnProperty.call(m, "fileUrl"))
                        w.uint32(146).string(m.fileUrl);
                    if (m.payment != null && Object.hasOwnProperty.call(m, "payment"))
                        $root.streem.api.TypeformResponse.Answer.Payment.encode(m.payment, w.uint32(154).fork()).ldelim();
                    return w;
                };

                /**
                 * Decodes an Answer message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.TypeformResponse.Answer} Answer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Answer.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse.Answer();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.type = r.string();
                                break;
                            }
                        case 2: {
                                m.field = $root.streem.api.TypeformResponse.Answer.Field.decode(r, r.uint32());
                                break;
                            }
                        case 10: {
                                m.text = r.string();
                                break;
                            }
                        case 11: {
                                m.choice = $root.streem.api.TypeformResponse.Answer.Choice.decode(r, r.uint32());
                                break;
                            }
                        case 12: {
                                m.choices = $root.streem.api.TypeformResponse.Answer.Choices.decode(r, r.uint32());
                                break;
                            }
                        case 13: {
                                m.email = r.string();
                                break;
                            }
                        case 14: {
                                m.date = r.string();
                                break;
                            }
                        case 15: {
                                m.boolean = r.bool();
                                break;
                            }
                        case 16: {
                                m.url = r.string();
                                break;
                            }
                        case 17: {
                                m.number = r.int32();
                                break;
                            }
                        case 18: {
                                m.fileUrl = r.string();
                                break;
                            }
                        case 19: {
                                m.payment = $root.streem.api.TypeformResponse.Answer.Payment.decode(r, r.uint32());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Answer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.TypeformResponse.Answer} Answer
                 */
                Answer.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.TypeformResponse.Answer)
                        return d;
                    var m = new $root.streem.api.TypeformResponse.Answer();
                    if (d.type != null) {
                        m.type = String(d.type);
                    }
                    if (d.field != null) {
                        if (typeof d.field !== "object")
                            throw TypeError(".streem.api.TypeformResponse.Answer.field: object expected");
                        m.field = $root.streem.api.TypeformResponse.Answer.Field.fromObject(d.field);
                    }
                    if (d.text != null) {
                        m.text = String(d.text);
                    }
                    if (d.choice != null) {
                        if (typeof d.choice !== "object")
                            throw TypeError(".streem.api.TypeformResponse.Answer.choice: object expected");
                        m.choice = $root.streem.api.TypeformResponse.Answer.Choice.fromObject(d.choice);
                    }
                    if (d.choices != null) {
                        if (typeof d.choices !== "object")
                            throw TypeError(".streem.api.TypeformResponse.Answer.choices: object expected");
                        m.choices = $root.streem.api.TypeformResponse.Answer.Choices.fromObject(d.choices);
                    }
                    if (d.email != null) {
                        m.email = String(d.email);
                    }
                    if (d.date != null) {
                        m.date = String(d.date);
                    }
                    if (d.boolean != null) {
                        m.boolean = Boolean(d.boolean);
                    }
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    if (d.number != null) {
                        m.number = d.number | 0;
                    }
                    if (d.fileUrl != null) {
                        m.fileUrl = String(d.fileUrl);
                    }
                    if (d.payment != null) {
                        if (typeof d.payment !== "object")
                            throw TypeError(".streem.api.TypeformResponse.Answer.payment: object expected");
                        m.payment = $root.streem.api.TypeformResponse.Answer.Payment.fromObject(d.payment);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Answer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {streem.api.TypeformResponse.Answer} m Answer
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Answer.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.type = "";
                        d.field = null;
                    }
                    if (m.type != null && m.hasOwnProperty("type")) {
                        d.type = m.type;
                    }
                    if (m.field != null && m.hasOwnProperty("field")) {
                        d.field = $root.streem.api.TypeformResponse.Answer.Field.toObject(m.field, o);
                    }
                    if (m.text != null && m.hasOwnProperty("text")) {
                        d.text = m.text;
                        if (o.oneofs)
                            d.answer = "text";
                    }
                    if (m.choice != null && m.hasOwnProperty("choice")) {
                        d.choice = $root.streem.api.TypeformResponse.Answer.Choice.toObject(m.choice, o);
                        if (o.oneofs)
                            d.answer = "choice";
                    }
                    if (m.choices != null && m.hasOwnProperty("choices")) {
                        d.choices = $root.streem.api.TypeformResponse.Answer.Choices.toObject(m.choices, o);
                        if (o.oneofs)
                            d.answer = "choices";
                    }
                    if (m.email != null && m.hasOwnProperty("email")) {
                        d.email = m.email;
                        if (o.oneofs)
                            d.answer = "email";
                    }
                    if (m.date != null && m.hasOwnProperty("date")) {
                        d.date = m.date;
                        if (o.oneofs)
                            d.answer = "date";
                    }
                    if (m.boolean != null && m.hasOwnProperty("boolean")) {
                        d.boolean = m.boolean;
                        if (o.oneofs)
                            d.answer = "boolean";
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                        if (o.oneofs)
                            d.answer = "url";
                    }
                    if (m.number != null && m.hasOwnProperty("number")) {
                        d.number = m.number;
                        if (o.oneofs)
                            d.answer = "number";
                    }
                    if (m.fileUrl != null && m.hasOwnProperty("fileUrl")) {
                        d.fileUrl = m.fileUrl;
                        if (o.oneofs)
                            d.answer = "fileUrl";
                    }
                    if (m.payment != null && m.hasOwnProperty("payment")) {
                        d.payment = $root.streem.api.TypeformResponse.Answer.Payment.toObject(m.payment, o);
                        if (o.oneofs)
                            d.answer = "payment";
                    }
                    return d;
                };

                /**
                 * Converts this Answer to JSON.
                 * @function toJSON
                 * @memberof streem.api.TypeformResponse.Answer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Answer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Answer
                 * @function getTypeUrl
                 * @memberof streem.api.TypeformResponse.Answer
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Answer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.TypeformResponse.Answer";
                };

                Answer.Field = (function() {

                    /**
                     * Properties of a Field.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @interface IField
                     * @property {string|null} [id] Field id
                     * @property {string|null} [type] Field type
                     */

                    /**
                     * Constructs a new Field.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @classdesc Represents a Field.
                     * @implements IField
                     * @constructor
                     * @param {streem.api.TypeformResponse.Answer.IField=} [p] Properties to set
                     */
                    function Field(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Field id.
                     * @member {string} id
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @instance
                     */
                    Field.prototype.id = "";

                    /**
                     * Field type.
                     * @member {string} type
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @instance
                     */
                    Field.prototype.type = "";

                    /**
                     * Creates a new Field instance using the specified properties.
                     * @function create
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IField=} [properties] Properties to set
                     * @returns {streem.api.TypeformResponse.Answer.Field} Field instance
                     */
                    Field.create = function create(properties) {
                        return new Field(properties);
                    };

                    /**
                     * Encodes the specified Field message. Does not implicitly {@link streem.api.TypeformResponse.Answer.Field.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IField} m Field message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                            w.uint32(10).string(m.id);
                        if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                            w.uint32(18).string(m.type);
                        return w;
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.TypeformResponse.Answer.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse.Answer.Field();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.id = r.string();
                                    break;
                                }
                            case 2: {
                                    m.type = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Field message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.TypeformResponse.Answer.Field} Field
                     */
                    Field.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.TypeformResponse.Answer.Field)
                            return d;
                        var m = new $root.streem.api.TypeformResponse.Answer.Field();
                        if (d.id != null) {
                            m.id = String(d.id);
                        }
                        if (d.type != null) {
                            m.type = String(d.type);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Field message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.Field} m Field
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Field.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.id = "";
                            d.type = "";
                        }
                        if (m.id != null && m.hasOwnProperty("id")) {
                            d.id = m.id;
                        }
                        if (m.type != null && m.hasOwnProperty("type")) {
                            d.type = m.type;
                        }
                        return d;
                    };

                    /**
                     * Converts this Field to JSON.
                     * @function toJSON
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Field.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Field
                     * @function getTypeUrl
                     * @memberof streem.api.TypeformResponse.Answer.Field
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Field.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.TypeformResponse.Answer.Field";
                    };

                    return Field;
                })();

                Answer.Choice = (function() {

                    /**
                     * Properties of a Choice.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @interface IChoice
                     * @property {string|null} [label] Choice label
                     * @property {string|null} [other] Choice other
                     */

                    /**
                     * Constructs a new Choice.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @classdesc Represents a Choice.
                     * @implements IChoice
                     * @constructor
                     * @param {streem.api.TypeformResponse.Answer.IChoice=} [p] Properties to set
                     */
                    function Choice(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Choice label.
                     * @member {string} label
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @instance
                     */
                    Choice.prototype.label = "";

                    /**
                     * Choice other.
                     * @member {string} other
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @instance
                     */
                    Choice.prototype.other = "";

                    /**
                     * Creates a new Choice instance using the specified properties.
                     * @function create
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IChoice=} [properties] Properties to set
                     * @returns {streem.api.TypeformResponse.Answer.Choice} Choice instance
                     */
                    Choice.create = function create(properties) {
                        return new Choice(properties);
                    };

                    /**
                     * Encodes the specified Choice message. Does not implicitly {@link streem.api.TypeformResponse.Answer.Choice.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IChoice} m Choice message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Choice.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                            w.uint32(10).string(m.label);
                        if (m.other != null && Object.hasOwnProperty.call(m, "other"))
                            w.uint32(18).string(m.other);
                        return w;
                    };

                    /**
                     * Decodes a Choice message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.TypeformResponse.Answer.Choice} Choice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Choice.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse.Answer.Choice();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.label = r.string();
                                    break;
                                }
                            case 2: {
                                    m.other = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Choice message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.TypeformResponse.Answer.Choice} Choice
                     */
                    Choice.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.TypeformResponse.Answer.Choice)
                            return d;
                        var m = new $root.streem.api.TypeformResponse.Answer.Choice();
                        if (d.label != null) {
                            m.label = String(d.label);
                        }
                        if (d.other != null) {
                            m.other = String(d.other);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Choice message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.Choice} m Choice
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Choice.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.label = "";
                            d.other = "";
                        }
                        if (m.label != null && m.hasOwnProperty("label")) {
                            d.label = m.label;
                        }
                        if (m.other != null && m.hasOwnProperty("other")) {
                            d.other = m.other;
                        }
                        return d;
                    };

                    /**
                     * Converts this Choice to JSON.
                     * @function toJSON
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Choice.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Choice
                     * @function getTypeUrl
                     * @memberof streem.api.TypeformResponse.Answer.Choice
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Choice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.TypeformResponse.Answer.Choice";
                    };

                    return Choice;
                })();

                Answer.Choices = (function() {

                    /**
                     * Properties of a Choices.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @interface IChoices
                     * @property {Array.<string>|null} [labels] Choices labels
                     * @property {string|null} [other] Choices other
                     */

                    /**
                     * Constructs a new Choices.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @classdesc Represents a Choices.
                     * @implements IChoices
                     * @constructor
                     * @param {streem.api.TypeformResponse.Answer.IChoices=} [p] Properties to set
                     */
                    function Choices(p) {
                        this.labels = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Choices labels.
                     * @member {Array.<string>} labels
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @instance
                     */
                    Choices.prototype.labels = $util.emptyArray;

                    /**
                     * Choices other.
                     * @member {string} other
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @instance
                     */
                    Choices.prototype.other = "";

                    /**
                     * Creates a new Choices instance using the specified properties.
                     * @function create
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IChoices=} [properties] Properties to set
                     * @returns {streem.api.TypeformResponse.Answer.Choices} Choices instance
                     */
                    Choices.create = function create(properties) {
                        return new Choices(properties);
                    };

                    /**
                     * Encodes the specified Choices message. Does not implicitly {@link streem.api.TypeformResponse.Answer.Choices.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IChoices} m Choices message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Choices.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.labels != null && m.labels.length) {
                            for (var i = 0; i < m.labels.length; ++i)
                                w.uint32(10).string(m.labels[i]);
                        }
                        if (m.other != null && Object.hasOwnProperty.call(m, "other"))
                            w.uint32(18).string(m.other);
                        return w;
                    };

                    /**
                     * Decodes a Choices message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.TypeformResponse.Answer.Choices} Choices
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Choices.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse.Answer.Choices();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    if (!(m.labels && m.labels.length))
                                        m.labels = [];
                                    m.labels.push(r.string());
                                    break;
                                }
                            case 2: {
                                    m.other = r.string();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Choices message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.TypeformResponse.Answer.Choices} Choices
                     */
                    Choices.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.TypeformResponse.Answer.Choices)
                            return d;
                        var m = new $root.streem.api.TypeformResponse.Answer.Choices();
                        if (d.labels) {
                            if (!Array.isArray(d.labels))
                                throw TypeError(".streem.api.TypeformResponse.Answer.Choices.labels: array expected");
                            m.labels = [];
                            for (var i = 0; i < d.labels.length; ++i) {
                                m.labels[i] = String(d.labels[i]);
                            }
                        }
                        if (d.other != null) {
                            m.other = String(d.other);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Choices message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.Choices} m Choices
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Choices.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.labels = [];
                        }
                        if (o.defaults) {
                            d.other = "";
                        }
                        if (m.labels && m.labels.length) {
                            d.labels = [];
                            for (var j = 0; j < m.labels.length; ++j) {
                                d.labels[j] = m.labels[j];
                            }
                        }
                        if (m.other != null && m.hasOwnProperty("other")) {
                            d.other = m.other;
                        }
                        return d;
                    };

                    /**
                     * Converts this Choices to JSON.
                     * @function toJSON
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Choices.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Choices
                     * @function getTypeUrl
                     * @memberof streem.api.TypeformResponse.Answer.Choices
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Choices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.TypeformResponse.Answer.Choices";
                    };

                    return Choices;
                })();

                Answer.Payment = (function() {

                    /**
                     * Properties of a Payment.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @interface IPayment
                     * @property {string|null} [amount] Payment amount
                     * @property {string|null} [last4] Payment last4
                     * @property {string|null} [name] Payment name
                     * @property {boolean|null} [success] Payment success
                     */

                    /**
                     * Constructs a new Payment.
                     * @memberof streem.api.TypeformResponse.Answer
                     * @classdesc Represents a Payment.
                     * @implements IPayment
                     * @constructor
                     * @param {streem.api.TypeformResponse.Answer.IPayment=} [p] Properties to set
                     */
                    function Payment(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * Payment amount.
                     * @member {string} amount
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @instance
                     */
                    Payment.prototype.amount = "";

                    /**
                     * Payment last4.
                     * @member {string} last4
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @instance
                     */
                    Payment.prototype.last4 = "";

                    /**
                     * Payment name.
                     * @member {string} name
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @instance
                     */
                    Payment.prototype.name = "";

                    /**
                     * Payment success.
                     * @member {boolean} success
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @instance
                     */
                    Payment.prototype.success = false;

                    /**
                     * Creates a new Payment instance using the specified properties.
                     * @function create
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IPayment=} [properties] Properties to set
                     * @returns {streem.api.TypeformResponse.Answer.Payment} Payment instance
                     */
                    Payment.create = function create(properties) {
                        return new Payment(properties);
                    };

                    /**
                     * Encodes the specified Payment message. Does not implicitly {@link streem.api.TypeformResponse.Answer.Payment.verify|verify} messages.
                     * @function encode
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.IPayment} m Payment message or plain object to encode
                     * @param {$protobuf.Writer} [w] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Payment.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.amount != null && Object.hasOwnProperty.call(m, "amount"))
                            w.uint32(10).string(m.amount);
                        if (m.last4 != null && Object.hasOwnProperty.call(m, "last4"))
                            w.uint32(18).string(m.last4);
                        if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                            w.uint32(26).string(m.name);
                        if (m.success != null && Object.hasOwnProperty.call(m, "success"))
                            w.uint32(32).bool(m.success);
                        return w;
                    };

                    /**
                     * Decodes a Payment message from the specified reader or buffer.
                     * @function decode
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                     * @param {number} [l] Message length if known beforehand
                     * @returns {streem.api.TypeformResponse.Answer.Payment} Payment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Payment.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TypeformResponse.Answer.Payment();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.amount = r.string();
                                    break;
                                }
                            case 2: {
                                    m.last4 = r.string();
                                    break;
                                }
                            case 3: {
                                    m.name = r.string();
                                    break;
                                }
                            case 4: {
                                    m.success = r.bool();
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a Payment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {streem.api.TypeformResponse.Answer.Payment} Payment
                     */
                    Payment.fromObject = function fromObject(d) {
                        if (d instanceof $root.streem.api.TypeformResponse.Answer.Payment)
                            return d;
                        var m = new $root.streem.api.TypeformResponse.Answer.Payment();
                        if (d.amount != null) {
                            m.amount = String(d.amount);
                        }
                        if (d.last4 != null) {
                            m.last4 = String(d.last4);
                        }
                        if (d.name != null) {
                            m.name = String(d.name);
                        }
                        if (d.success != null) {
                            m.success = Boolean(d.success);
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a Payment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {streem.api.TypeformResponse.Answer.Payment} m Payment
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Payment.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.amount = "";
                            d.last4 = "";
                            d.name = "";
                            d.success = false;
                        }
                        if (m.amount != null && m.hasOwnProperty("amount")) {
                            d.amount = m.amount;
                        }
                        if (m.last4 != null && m.hasOwnProperty("last4")) {
                            d.last4 = m.last4;
                        }
                        if (m.name != null && m.hasOwnProperty("name")) {
                            d.name = m.name;
                        }
                        if (m.success != null && m.hasOwnProperty("success")) {
                            d.success = m.success;
                        }
                        return d;
                    };

                    /**
                     * Converts this Payment to JSON.
                     * @function toJSON
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Payment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Payment
                     * @function getTypeUrl
                     * @memberof streem.api.TypeformResponse.Answer.Payment
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/streem.api.TypeformResponse.Answer.Payment";
                    };

                    return Payment;
                })();

                return Answer;
            })();

            return TypeformResponse;
        })();

        api.Companies = (function() {

            /**
             * Constructs a new Companies service.
             * @memberof streem.api
             * @classdesc Represents a Companies
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Companies(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Companies.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Companies;

            /**
             * Creates new Companies service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.Companies
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Companies} RPC service. Useful where requests and/or responses are streamed.
             */
            Companies.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.Companies#listCompanies}.
             * @memberof streem.api.Companies
             * @typedef ListCompaniesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompaniesResponse} [response] ListCompaniesResponse
             */

            /**
             * Calls ListCompanies.
             * @function listCompanies
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompaniesRequest} request ListCompaniesRequest message or plain object
             * @param {streem.api.Companies.ListCompaniesCallback} callback Node-style callback called with the error, if any, and ListCompaniesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanies = function listCompanies(request, callback) {
                return this.rpcCall(listCompanies, $root.streem.api.ListCompaniesRequest, $root.streem.api.ListCompaniesResponse, request, callback);
            }, "name", { value: "ListCompanies" });

            /**
             * Calls ListCompanies.
             * @function listCompanies
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompaniesRequest} request ListCompaniesRequest message or plain object
             * @returns {Promise<streem.api.ListCompaniesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompany}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyResponse} [response] CreateCompanyResponse
             */

            /**
             * Calls CreateCompany.
             * @function createCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyRequest} request CreateCompanyRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyCallback} callback Node-style callback called with the error, if any, and CreateCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompany = function createCompany(request, callback) {
                return this.rpcCall(createCompany, $root.streem.api.CreateCompanyRequest, $root.streem.api.CreateCompanyResponse, request, callback);
            }, "name", { value: "CreateCompany" });

            /**
             * Calls CreateCompany.
             * @function createCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyRequest} request CreateCompanyRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#batchCreateCompany}.
             * @memberof streem.api.Companies
             * @typedef BatchCreateCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.BatchCreateCompanyResponse} [response] BatchCreateCompanyResponse
             */

            /**
             * Calls BatchCreateCompany.
             * @function batchCreateCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IBatchCreateCompanyRequest} request BatchCreateCompanyRequest message or plain object
             * @param {streem.api.Companies.BatchCreateCompanyCallback} callback Node-style callback called with the error, if any, and BatchCreateCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.batchCreateCompany = function batchCreateCompany(request, callback) {
                return this.rpcCall(batchCreateCompany, $root.streem.api.BatchCreateCompanyRequest, $root.streem.api.BatchCreateCompanyResponse, request, callback);
            }, "name", { value: "BatchCreateCompany" });

            /**
             * Calls BatchCreateCompany.
             * @function batchCreateCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IBatchCreateCompanyRequest} request BatchCreateCompanyRequest message or plain object
             * @returns {Promise<streem.api.BatchCreateCompanyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#searchCompaniesForUserEmail}.
             * @memberof streem.api.Companies
             * @typedef SearchCompaniesForUserEmailCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SearchCompaniesForUserEmailResponse} [response] SearchCompaniesForUserEmailResponse
             */

            /**
             * Calls SearchCompaniesForUserEmail.
             * @function searchCompaniesForUserEmail
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISearchCompaniesForUserEmailRequest} request SearchCompaniesForUserEmailRequest message or plain object
             * @param {streem.api.Companies.SearchCompaniesForUserEmailCallback} callback Node-style callback called with the error, if any, and SearchCompaniesForUserEmailResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.searchCompaniesForUserEmail = function searchCompaniesForUserEmail(request, callback) {
                return this.rpcCall(searchCompaniesForUserEmail, $root.streem.api.SearchCompaniesForUserEmailRequest, $root.streem.api.SearchCompaniesForUserEmailResponse, request, callback);
            }, "name", { value: "SearchCompaniesForUserEmail" });

            /**
             * Calls SearchCompaniesForUserEmail.
             * @function searchCompaniesForUserEmail
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISearchCompaniesForUserEmailRequest} request SearchCompaniesForUserEmailRequest message or plain object
             * @returns {Promise<streem.api.SearchCompaniesForUserEmailResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCurrentCompany}.
             * @memberof streem.api.Companies
             * @typedef GetCurrentCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCurrentCompanyResponse} [response] GetCurrentCompanyResponse
             */

            /**
             * Calls GetCurrentCompany.
             * @function getCurrentCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCurrentCompanyRequest} request GetCurrentCompanyRequest message or plain object
             * @param {streem.api.Companies.GetCurrentCompanyCallback} callback Node-style callback called with the error, if any, and GetCurrentCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCurrentCompany = function getCurrentCompany(request, callback) {
                return this.rpcCall(getCurrentCompany, $root.streem.api.GetCurrentCompanyRequest, $root.streem.api.GetCurrentCompanyResponse, request, callback);
            }, "name", { value: "GetCurrentCompany" });

            /**
             * Calls GetCurrentCompany.
             * @function getCurrentCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCurrentCompanyRequest} request GetCurrentCompanyRequest message or plain object
             * @returns {Promise<streem.api.GetCurrentCompanyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompany}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyResponse} [response] GetCompanyResponse
             */

            /**
             * Calls GetCompany.
             * @function getCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyRequest} request GetCompanyRequest message or plain object
             * @param {streem.api.Companies.GetCompanyCallback} callback Node-style callback called with the error, if any, and GetCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompany = function getCompany(request, callback) {
                return this.rpcCall(getCompany, $root.streem.api.GetCompanyRequest, $root.streem.api.GetCompanyResponse, request, callback);
            }, "name", { value: "GetCompany" });

            /**
             * Calls GetCompany.
             * @function getCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyRequest} request GetCompanyRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#updateCompany}.
             * @memberof streem.api.Companies
             * @typedef UpdateCompanyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateCompanyResponse} [response] UpdateCompanyResponse
             */

            /**
             * Calls UpdateCompany.
             * @function updateCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyRequest} request UpdateCompanyRequest message or plain object
             * @param {streem.api.Companies.UpdateCompanyCallback} callback Node-style callback called with the error, if any, and UpdateCompanyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.updateCompany = function updateCompany(request, callback) {
                return this.rpcCall(updateCompany, $root.streem.api.UpdateCompanyRequest, $root.streem.api.UpdateCompanyResponse, request, callback);
            }, "name", { value: "UpdateCompany" });

            /**
             * Calls UpdateCompany.
             * @function updateCompany
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyRequest} request UpdateCompanyRequest message or plain object
             * @returns {Promise<streem.api.UpdateCompanyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#deleteCompanyAndAssets}.
             * @memberof streem.api.Companies
             * @typedef DeleteCompanyAndAssetsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteCompanyAndAssets.
             * @function deleteCompanyAndAssets
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyAndAssetsRequest} request DeleteCompanyAndAssetsRequest message or plain object
             * @param {streem.api.Companies.DeleteCompanyAndAssetsCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.deleteCompanyAndAssets = function deleteCompanyAndAssets(request, callback) {
                return this.rpcCall(deleteCompanyAndAssets, $root.streem.api.DeleteCompanyAndAssetsRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteCompanyAndAssets" });

            /**
             * Calls DeleteCompanyAndAssets.
             * @function deleteCompanyAndAssets
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyAndAssetsRequest} request DeleteCompanyAndAssetsRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#deleteCompanyCallSource}.
             * @memberof streem.api.Companies
             * @typedef DeleteCompanyCallSourceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteCompanyCallSourceResponse} [response] DeleteCompanyCallSourceResponse
             */

            /**
             * Calls DeleteCompanyCallSource.
             * @function deleteCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyCallSourceRequest} request DeleteCompanyCallSourceRequest message or plain object
             * @param {streem.api.Companies.DeleteCompanyCallSourceCallback} callback Node-style callback called with the error, if any, and DeleteCompanyCallSourceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.deleteCompanyCallSource = function deleteCompanyCallSource(request, callback) {
                return this.rpcCall(deleteCompanyCallSource, $root.streem.api.DeleteCompanyCallSourceRequest, $root.streem.api.DeleteCompanyCallSourceResponse, request, callback);
            }, "name", { value: "DeleteCompanyCallSource" });

            /**
             * Calls DeleteCompanyCallSource.
             * @function deleteCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyCallSourceRequest} request DeleteCompanyCallSourceRequest message or plain object
             * @returns {Promise<streem.api.DeleteCompanyCallSourceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getPublicLoginConfiguration}.
             * @memberof streem.api.Companies
             * @typedef GetPublicLoginConfigurationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetPublicLoginConfigurationResponse} [response] GetPublicLoginConfigurationResponse
             */

            /**
             * Calls GetPublicLoginConfiguration.
             * @function getPublicLoginConfiguration
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetPublicLoginConfigurationRequest} request GetPublicLoginConfigurationRequest message or plain object
             * @param {streem.api.Companies.GetPublicLoginConfigurationCallback} callback Node-style callback called with the error, if any, and GetPublicLoginConfigurationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getPublicLoginConfiguration = function getPublicLoginConfiguration(request, callback) {
                return this.rpcCall(getPublicLoginConfiguration, $root.streem.api.GetPublicLoginConfigurationRequest, $root.streem.api.GetPublicLoginConfigurationResponse, request, callback);
            }, "name", { value: "GetPublicLoginConfiguration" });

            /**
             * Calls GetPublicLoginConfiguration.
             * @function getPublicLoginConfiguration
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetPublicLoginConfigurationRequest} request GetPublicLoginConfigurationRequest message or plain object
             * @returns {Promise<streem.api.GetPublicLoginConfigurationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyIdentityProvider}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyIdentityProviderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyIdentityProviderResponse} [response] GetCompanyIdentityProviderResponse
             */

            /**
             * Calls GetCompanyIdentityProvider.
             * @function getCompanyIdentityProvider
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyIdentityProviderRequest} request GetCompanyIdentityProviderRequest message or plain object
             * @param {streem.api.Companies.GetCompanyIdentityProviderCallback} callback Node-style callback called with the error, if any, and GetCompanyIdentityProviderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyIdentityProvider = function getCompanyIdentityProvider(request, callback) {
                return this.rpcCall(getCompanyIdentityProvider, $root.streem.api.GetCompanyIdentityProviderRequest, $root.streem.api.GetCompanyIdentityProviderResponse, request, callback);
            }, "name", { value: "GetCompanyIdentityProvider" });

            /**
             * Calls GetCompanyIdentityProvider.
             * @function getCompanyIdentityProvider
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyIdentityProviderRequest} request GetCompanyIdentityProviderRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyIdentityProviderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyCallSources}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyCallSourcesCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyCallSourcesResponse} [response] GetCompanyCallSourcesResponse
             */

            /**
             * Calls GetCompanyCallSources.
             * @function getCompanyCallSources
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallSourcesRequest} request GetCompanyCallSourcesRequest message or plain object
             * @param {streem.api.Companies.GetCompanyCallSourcesCallback} callback Node-style callback called with the error, if any, and GetCompanyCallSourcesResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyCallSources = function getCompanyCallSources(request, callback) {
                return this.rpcCall(getCompanyCallSources, $root.streem.api.GetCompanyCallSourcesRequest, $root.streem.api.GetCompanyCallSourcesResponse, request, callback);
            }, "name", { value: "GetCompanyCallSources" });

            /**
             * Calls GetCompanyCallSources.
             * @function getCompanyCallSources
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallSourcesRequest} request GetCompanyCallSourcesRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyCallSourcesResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyCallSource}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyCallSourceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyCallSourceResponse} [response] GetCompanyCallSourceResponse
             */

            /**
             * Calls GetCompanyCallSource.
             * @function getCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallSourceRequest} request GetCompanyCallSourceRequest message or plain object
             * @param {streem.api.Companies.GetCompanyCallSourceCallback} callback Node-style callback called with the error, if any, and GetCompanyCallSourceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyCallSource = function getCompanyCallSource(request, callback) {
                return this.rpcCall(getCompanyCallSource, $root.streem.api.GetCompanyCallSourceRequest, $root.streem.api.GetCompanyCallSourceResponse, request, callback);
            }, "name", { value: "GetCompanyCallSource" });

            /**
             * Calls GetCompanyCallSource.
             * @function getCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallSourceRequest} request GetCompanyCallSourceRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyCallSourceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#updateCompanyIdentityProvider}.
             * @memberof streem.api.Companies
             * @typedef UpdateCompanyIdentityProviderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateCompanyIdentityProviderResponse} [response] UpdateCompanyIdentityProviderResponse
             */

            /**
             * Calls UpdateCompanyIdentityProvider.
             * @function updateCompanyIdentityProvider
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyIdentityProviderRequest} request UpdateCompanyIdentityProviderRequest message or plain object
             * @param {streem.api.Companies.UpdateCompanyIdentityProviderCallback} callback Node-style callback called with the error, if any, and UpdateCompanyIdentityProviderResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.updateCompanyIdentityProvider = function updateCompanyIdentityProvider(request, callback) {
                return this.rpcCall(updateCompanyIdentityProvider, $root.streem.api.UpdateCompanyIdentityProviderRequest, $root.streem.api.UpdateCompanyIdentityProviderResponse, request, callback);
            }, "name", { value: "UpdateCompanyIdentityProvider" });

            /**
             * Calls UpdateCompanyIdentityProvider.
             * @function updateCompanyIdentityProvider
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyIdentityProviderRequest} request UpdateCompanyIdentityProviderRequest message or plain object
             * @returns {Promise<streem.api.UpdateCompanyIdentityProviderResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#listCompanyUsers}.
             * @memberof streem.api.Companies
             * @typedef ListCompanyUsersCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompanyUsersResponse} [response] ListCompanyUsersResponse
             */

            /**
             * Calls ListCompanyUsers.
             * @function listCompanyUsers
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyUsersRequest} request ListCompanyUsersRequest message or plain object
             * @param {streem.api.Companies.ListCompanyUsersCallback} callback Node-style callback called with the error, if any, and ListCompanyUsersResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanyUsers = function listCompanyUsers(request, callback) {
                return this.rpcCall(listCompanyUsers, $root.streem.api.ListCompanyUsersRequest, $root.streem.api.ListCompanyUsersResponse, request, callback);
            }, "name", { value: "ListCompanyUsers" });

            /**
             * Calls ListCompanyUsers.
             * @function listCompanyUsers
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyUsersRequest} request ListCompanyUsersRequest message or plain object
             * @returns {Promise<streem.api.ListCompanyUsersResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompanyUser}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyUserResponse} [response] CreateCompanyUserResponse
             */

            /**
             * Calls CreateCompanyUser.
             * @function createCompanyUser
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyUserRequest} request CreateCompanyUserRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyUserCallback} callback Node-style callback called with the error, if any, and CreateCompanyUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompanyUser = function createCompanyUser(request, callback) {
                return this.rpcCall(createCompanyUser, $root.streem.api.CreateCompanyUserRequest, $root.streem.api.CreateCompanyUserResponse, request, callback);
            }, "name", { value: "CreateCompanyUser" });

            /**
             * Calls CreateCompanyUser.
             * @function createCompanyUser
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyUserRequest} request CreateCompanyUserRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompanyCallSource}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyCallSourceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyCallSourceResponse} [response] CreateCompanyCallSourceResponse
             */

            /**
             * Calls CreateCompanyCallSource.
             * @function createCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyCallSourceRequest} request CreateCompanyCallSourceRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyCallSourceCallback} callback Node-style callback called with the error, if any, and CreateCompanyCallSourceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompanyCallSource = function createCompanyCallSource(request, callback) {
                return this.rpcCall(createCompanyCallSource, $root.streem.api.CreateCompanyCallSourceRequest, $root.streem.api.CreateCompanyCallSourceResponse, request, callback);
            }, "name", { value: "CreateCompanyCallSource" });

            /**
             * Calls CreateCompanyCallSource.
             * @function createCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyCallSourceRequest} request CreateCompanyCallSourceRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyCallSourceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#updateCompanyCallSource}.
             * @memberof streem.api.Companies
             * @typedef UpdateCompanyCallSourceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UpdateCompanyCallSourceResponse} [response] UpdateCompanyCallSourceResponse
             */

            /**
             * Calls UpdateCompanyCallSource.
             * @function updateCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyCallSourceRequest} request UpdateCompanyCallSourceRequest message or plain object
             * @param {streem.api.Companies.UpdateCompanyCallSourceCallback} callback Node-style callback called with the error, if any, and UpdateCompanyCallSourceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.updateCompanyCallSource = function updateCompanyCallSource(request, callback) {
                return this.rpcCall(updateCompanyCallSource, $root.streem.api.UpdateCompanyCallSourceRequest, $root.streem.api.UpdateCompanyCallSourceResponse, request, callback);
            }, "name", { value: "UpdateCompanyCallSource" });

            /**
             * Calls UpdateCompanyCallSource.
             * @function updateCompanyCallSource
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUpdateCompanyCallSourceRequest} request UpdateCompanyCallSourceRequest message or plain object
             * @returns {Promise<streem.api.UpdateCompanyCallSourceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#uploadCompanyCallSourceLogo}.
             * @memberof streem.api.Companies
             * @typedef UploadCompanyCallSourceLogoCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.UploadCompanyCallSourceLogoResponse} [response] UploadCompanyCallSourceLogoResponse
             */

            /**
             * Calls UploadCompanyCallSourceLogo.
             * @function uploadCompanyCallSourceLogo
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUploadCompanyCallSourceLogoRequest} request UploadCompanyCallSourceLogoRequest message or plain object
             * @param {streem.api.Companies.UploadCompanyCallSourceLogoCallback} callback Node-style callback called with the error, if any, and UploadCompanyCallSourceLogoResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.uploadCompanyCallSourceLogo = function uploadCompanyCallSourceLogo(request, callback) {
                return this.rpcCall(uploadCompanyCallSourceLogo, $root.streem.api.UploadCompanyCallSourceLogoRequest, $root.streem.api.UploadCompanyCallSourceLogoResponse, request, callback);
            }, "name", { value: "UploadCompanyCallSourceLogo" });

            /**
             * Calls UploadCompanyCallSourceLogo.
             * @function uploadCompanyCallSourceLogo
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IUploadCompanyCallSourceLogoRequest} request UploadCompanyCallSourceLogoRequest message or plain object
             * @returns {Promise<streem.api.UploadCompanyCallSourceLogoResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getOrCreateUser}.
             * @memberof streem.api.Companies
             * @typedef GetOrCreateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetOrCreateUserResponse} [response] GetOrCreateUserResponse
             */

            /**
             * Calls GetOrCreateUser.
             * @function getOrCreateUser
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetOrCreateUserRequest} request GetOrCreateUserRequest message or plain object
             * @param {streem.api.Companies.GetOrCreateUserCallback} callback Node-style callback called with the error, if any, and GetOrCreateUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getOrCreateUser = function getOrCreateUser(request, callback) {
                return this.rpcCall(getOrCreateUser, $root.streem.api.GetOrCreateUserRequest, $root.streem.api.GetOrCreateUserResponse, request, callback);
            }, "name", { value: "GetOrCreateUser" });

            /**
             * Calls GetOrCreateUser.
             * @function getOrCreateUser
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetOrCreateUserRequest} request GetOrCreateUserRequest message or plain object
             * @returns {Promise<streem.api.GetOrCreateUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getUserByExternalUserId}.
             * @memberof streem.api.Companies
             * @typedef GetUserByExternalUserIdCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetUserByExternalUserIdResponse} [response] GetUserByExternalUserIdResponse
             */

            /**
             * Calls GetUserByExternalUserId.
             * @function getUserByExternalUserId
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetUserByExternalUserIdRequest} request GetUserByExternalUserIdRequest message or plain object
             * @param {streem.api.Companies.GetUserByExternalUserIdCallback} callback Node-style callback called with the error, if any, and GetUserByExternalUserIdResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getUserByExternalUserId = function getUserByExternalUserId(request, callback) {
                return this.rpcCall(getUserByExternalUserId, $root.streem.api.GetUserByExternalUserIdRequest, $root.streem.api.GetUserByExternalUserIdResponse, request, callback);
            }, "name", { value: "GetUserByExternalUserId" });

            /**
             * Calls GetUserByExternalUserId.
             * @function getUserByExternalUserId
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetUserByExternalUserIdRequest} request GetUserByExternalUserIdRequest message or plain object
             * @returns {Promise<streem.api.GetUserByExternalUserIdResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#changeExternalUserId}.
             * @memberof streem.api.Companies
             * @typedef ChangeExternalUserIdCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ChangeExternalUserIdResponse} [response] ChangeExternalUserIdResponse
             */

            /**
             * Calls ChangeExternalUserId.
             * @function changeExternalUserId
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IChangeExternalUserIdRequest} request ChangeExternalUserIdRequest message or plain object
             * @param {streem.api.Companies.ChangeExternalUserIdCallback} callback Node-style callback called with the error, if any, and ChangeExternalUserIdResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.changeExternalUserId = function changeExternalUserId(request, callback) {
                return this.rpcCall(changeExternalUserId, $root.streem.api.ChangeExternalUserIdRequest, $root.streem.api.ChangeExternalUserIdResponse, request, callback);
            }, "name", { value: "ChangeExternalUserId" });

            /**
             * Calls ChangeExternalUserId.
             * @function changeExternalUserId
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IChangeExternalUserIdRequest} request ChangeExternalUserIdRequest message or plain object
             * @returns {Promise<streem.api.ChangeExternalUserIdResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#listCompanySettings}.
             * @memberof streem.api.Companies
             * @typedef ListCompanySettingsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompanySettingsResponse} [response] ListCompanySettingsResponse
             */

            /**
             * Calls ListCompanySettings.
             * @function listCompanySettings
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanySettingsRequest} request ListCompanySettingsRequest message or plain object
             * @param {streem.api.Companies.ListCompanySettingsCallback} callback Node-style callback called with the error, if any, and ListCompanySettingsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanySettings = function listCompanySettings(request, callback) {
                return this.rpcCall(listCompanySettings, $root.streem.api.ListCompanySettingsRequest, $root.streem.api.ListCompanySettingsResponse, request, callback);
            }, "name", { value: "ListCompanySettings" });

            /**
             * Calls ListCompanySettings.
             * @function listCompanySettings
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanySettingsRequest} request ListCompanySettingsRequest message or plain object
             * @returns {Promise<streem.api.ListCompanySettingsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#saveCompanySetting}.
             * @memberof streem.api.Companies
             * @typedef SaveCompanySettingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveCompanySettingResponse} [response] SaveCompanySettingResponse
             */

            /**
             * Calls SaveCompanySetting.
             * @function saveCompanySetting
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISaveCompanySettingRequest} request SaveCompanySettingRequest message or plain object
             * @param {streem.api.Companies.SaveCompanySettingCallback} callback Node-style callback called with the error, if any, and SaveCompanySettingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.saveCompanySetting = function saveCompanySetting(request, callback) {
                return this.rpcCall(saveCompanySetting, $root.streem.api.SaveCompanySettingRequest, $root.streem.api.SaveCompanySettingResponse, request, callback);
            }, "name", { value: "SaveCompanySetting" });

            /**
             * Calls SaveCompanySetting.
             * @function saveCompanySetting
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISaveCompanySettingRequest} request SaveCompanySettingRequest message or plain object
             * @returns {Promise<streem.api.SaveCompanySettingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#deleteCompanySetting}.
             * @memberof streem.api.Companies
             * @typedef DeleteCompanySettingCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.DeleteCompanySettingResponse} [response] DeleteCompanySettingResponse
             */

            /**
             * Calls DeleteCompanySetting.
             * @function deleteCompanySetting
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanySettingRequest} request DeleteCompanySettingRequest message or plain object
             * @param {streem.api.Companies.DeleteCompanySettingCallback} callback Node-style callback called with the error, if any, and DeleteCompanySettingResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.deleteCompanySetting = function deleteCompanySetting(request, callback) {
                return this.rpcCall(deleteCompanySetting, $root.streem.api.DeleteCompanySettingRequest, $root.streem.api.DeleteCompanySettingResponse, request, callback);
            }, "name", { value: "DeleteCompanySetting" });

            /**
             * Calls DeleteCompanySetting.
             * @function deleteCompanySetting
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanySettingRequest} request DeleteCompanySettingRequest message or plain object
             * @returns {Promise<streem.api.DeleteCompanySettingResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#listCompanyPublicSettings}.
             * @memberof streem.api.Companies
             * @typedef ListCompanyPublicSettingsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompanyPublicSettingsResponse} [response] ListCompanyPublicSettingsResponse
             */

            /**
             * Calls ListCompanyPublicSettings.
             * @function listCompanyPublicSettings
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyPublicSettingsRequest} request ListCompanyPublicSettingsRequest message or plain object
             * @param {streem.api.Companies.ListCompanyPublicSettingsCallback} callback Node-style callback called with the error, if any, and ListCompanyPublicSettingsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanyPublicSettings = function listCompanyPublicSettings(request, callback) {
                return this.rpcCall(listCompanyPublicSettings, $root.streem.api.ListCompanyPublicSettingsRequest, $root.streem.api.ListCompanyPublicSettingsResponse, request, callback);
            }, "name", { value: "ListCompanyPublicSettings" });

            /**
             * Calls ListCompanyPublicSettings.
             * @function listCompanyPublicSettings
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyPublicSettingsRequest} request ListCompanyPublicSettingsRequest message or plain object
             * @returns {Promise<streem.api.ListCompanyPublicSettingsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#saveCompanyTag}.
             * @memberof streem.api.Companies
             * @typedef SaveCompanyTagCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.SaveCompanyTagResponse} [response] SaveCompanyTagResponse
             */

            /**
             * Calls SaveCompanyTag.
             * @function saveCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISaveCompanyTagRequest} request SaveCompanyTagRequest message or plain object
             * @param {streem.api.Companies.SaveCompanyTagCallback} callback Node-style callback called with the error, if any, and SaveCompanyTagResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.saveCompanyTag = function saveCompanyTag(request, callback) {
                return this.rpcCall(saveCompanyTag, $root.streem.api.SaveCompanyTagRequest, $root.streem.api.SaveCompanyTagResponse, request, callback);
            }, "name", { value: "SaveCompanyTag" });

            /**
             * Calls SaveCompanyTag.
             * @function saveCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ISaveCompanyTagRequest} request SaveCompanyTagRequest message or plain object
             * @returns {Promise<streem.api.SaveCompanyTagResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#deleteCompanyTag}.
             * @memberof streem.api.Companies
             * @typedef DeleteCompanyTagCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteCompanyTag.
             * @function deleteCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyTagRequest} request DeleteCompanyTagRequest message or plain object
             * @param {streem.api.Companies.DeleteCompanyTagCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.deleteCompanyTag = function deleteCompanyTag(request, callback) {
                return this.rpcCall(deleteCompanyTag, $root.streem.api.DeleteCompanyTagRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteCompanyTag" });

            /**
             * Calls DeleteCompanyTag.
             * @function deleteCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IDeleteCompanyTagRequest} request DeleteCompanyTagRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#listCompanyTags}.
             * @memberof streem.api.Companies
             * @typedef ListCompanyTagsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompanyTagsResponse} [response] ListCompanyTagsResponse
             */

            /**
             * Calls ListCompanyTags.
             * @function listCompanyTags
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyTagsRequest} request ListCompanyTagsRequest message or plain object
             * @param {streem.api.Companies.ListCompanyTagsCallback} callback Node-style callback called with the error, if any, and ListCompanyTagsResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanyTags = function listCompanyTags(request, callback) {
                return this.rpcCall(listCompanyTags, $root.streem.api.ListCompanyTagsRequest, $root.streem.api.ListCompanyTagsResponse, request, callback);
            }, "name", { value: "ListCompanyTags" });

            /**
             * Calls ListCompanyTags.
             * @function listCompanyTags
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyTagsRequest} request ListCompanyTagsRequest message or plain object
             * @returns {Promise<streem.api.ListCompanyTagsResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyTag}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyTagCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyTagResponse} [response] GetCompanyTagResponse
             */

            /**
             * Calls GetCompanyTag.
             * @function getCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyTagRequest} request GetCompanyTagRequest message or plain object
             * @param {streem.api.Companies.GetCompanyTagCallback} callback Node-style callback called with the error, if any, and GetCompanyTagResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyTag = function getCompanyTag(request, callback) {
                return this.rpcCall(getCompanyTag, $root.streem.api.GetCompanyTagRequest, $root.streem.api.GetCompanyTagResponse, request, callback);
            }, "name", { value: "GetCompanyTag" });

            /**
             * Calls GetCompanyTag.
             * @function getCompanyTag
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyTagRequest} request GetCompanyTagRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyTagResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyCallLog}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyCallLogCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyCallLogResponse} [response] GetCompanyCallLogResponse
             */

            /**
             * Calls GetCompanyCallLog.
             * @function getCompanyCallLog
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallLogRequest} request GetCompanyCallLogRequest message or plain object
             * @param {streem.api.Companies.GetCompanyCallLogCallback} callback Node-style callback called with the error, if any, and GetCompanyCallLogResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyCallLog = function getCompanyCallLog(request, callback) {
                return this.rpcCall(getCompanyCallLog, $root.streem.api.GetCompanyCallLogRequest, $root.streem.api.GetCompanyCallLogResponse, request, callback);
            }, "name", { value: "GetCompanyCallLog" });

            /**
             * Calls GetCompanyCallLog.
             * @function getCompanyCallLog
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyCallLogRequest} request GetCompanyCallLogRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyCallLogResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createInvitation}.
             * @memberof streem.api.Companies
             * @typedef CreateInvitationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateInvitationResponse} [response] CreateInvitationResponse
             */

            /**
             * Calls CreateInvitation.
             * @function createInvitation
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateInvitationRequest} request CreateInvitationRequest message or plain object
             * @param {streem.api.Companies.CreateInvitationCallback} callback Node-style callback called with the error, if any, and CreateInvitationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createInvitation = function createInvitation(request, callback) {
                return this.rpcCall(createInvitation, $root.streem.api.CreateInvitationRequest, $root.streem.api.CreateInvitationResponse, request, callback);
            }, "name", { value: "CreateInvitation" });

            /**
             * Calls CreateInvitation.
             * @function createInvitation
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateInvitationRequest} request CreateInvitationRequest message or plain object
             * @returns {Promise<streem.api.CreateInvitationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompanyAsset}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyAssetCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyAssetResponse} [response] CreateCompanyAssetResponse
             */

            /**
             * Calls CreateCompanyAsset.
             * @function createCompanyAsset
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyAssetRequest} request CreateCompanyAssetRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyAssetCallback} callback Node-style callback called with the error, if any, and CreateCompanyAssetResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompanyAsset = function createCompanyAsset(request, callback) {
                return this.rpcCall(createCompanyAsset, $root.streem.api.CreateCompanyAssetRequest, $root.streem.api.CreateCompanyAssetResponse, request, callback);
            }, "name", { value: "CreateCompanyAsset" });

            /**
             * Calls CreateCompanyAsset.
             * @function createCompanyAsset
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyAssetRequest} request CreateCompanyAssetRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyAssetResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#listCompanyApiKeys}.
             * @memberof streem.api.Companies
             * @typedef ListCompanyApiKeysCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.ListCompanyApiKeysResponse} [response] ListCompanyApiKeysResponse
             */

            /**
             * Calls ListCompanyApiKeys.
             * @function listCompanyApiKeys
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyApiKeysRequest} request ListCompanyApiKeysRequest message or plain object
             * @param {streem.api.Companies.ListCompanyApiKeysCallback} callback Node-style callback called with the error, if any, and ListCompanyApiKeysResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.listCompanyApiKeys = function listCompanyApiKeys(request, callback) {
                return this.rpcCall(listCompanyApiKeys, $root.streem.api.ListCompanyApiKeysRequest, $root.streem.api.ListCompanyApiKeysResponse, request, callback);
            }, "name", { value: "ListCompanyApiKeys" });

            /**
             * Calls ListCompanyApiKeys.
             * @function listCompanyApiKeys
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IListCompanyApiKeysRequest} request ListCompanyApiKeysRequest message or plain object
             * @returns {Promise<streem.api.ListCompanyApiKeysResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompanyApiKey}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyApiKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyApiKeyResponse} [response] CreateCompanyApiKeyResponse
             */

            /**
             * Calls CreateCompanyApiKey.
             * @function createCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyApiKeyRequest} request CreateCompanyApiKeyRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyApiKeyCallback} callback Node-style callback called with the error, if any, and CreateCompanyApiKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompanyApiKey = function createCompanyApiKey(request, callback) {
                return this.rpcCall(createCompanyApiKey, $root.streem.api.CreateCompanyApiKeyRequest, $root.streem.api.CreateCompanyApiKeyResponse, request, callback);
            }, "name", { value: "CreateCompanyApiKey" });

            /**
             * Calls CreateCompanyApiKey.
             * @function createCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyApiKeyRequest} request CreateCompanyApiKeyRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyApiKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyApiKey}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyApiKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyApiKeyResponse} [response] GetCompanyApiKeyResponse
             */

            /**
             * Calls GetCompanyApiKey.
             * @function getCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyApiKeyRequest} request GetCompanyApiKeyRequest message or plain object
             * @param {streem.api.Companies.GetCompanyApiKeyCallback} callback Node-style callback called with the error, if any, and GetCompanyApiKeyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyApiKey = function getCompanyApiKey(request, callback) {
                return this.rpcCall(getCompanyApiKey, $root.streem.api.GetCompanyApiKeyRequest, $root.streem.api.GetCompanyApiKeyResponse, request, callback);
            }, "name", { value: "GetCompanyApiKey" });

            /**
             * Calls GetCompanyApiKey.
             * @function getCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyApiKeyRequest} request GetCompanyApiKeyRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyApiKeyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#revokeCompanyApiKey}.
             * @memberof streem.api.Companies
             * @typedef RevokeCompanyApiKeyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls RevokeCompanyApiKey.
             * @function revokeCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IRevokeCompanyApiKeyRequest} request RevokeCompanyApiKeyRequest message or plain object
             * @param {streem.api.Companies.RevokeCompanyApiKeyCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.revokeCompanyApiKey = function revokeCompanyApiKey(request, callback) {
                return this.rpcCall(revokeCompanyApiKey, $root.streem.api.RevokeCompanyApiKeyRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "RevokeCompanyApiKey" });

            /**
             * Calls RevokeCompanyApiKey.
             * @function revokeCompanyApiKey
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IRevokeCompanyApiKeyRequest} request RevokeCompanyApiKeyRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createPiiRequest}.
             * @memberof streem.api.Companies
             * @typedef CreatePiiRequestCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreatePiiRequestResponse} [response] CreatePiiRequestResponse
             */

            /**
             * Calls CreatePiiRequest.
             * @function createPiiRequest
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreatePiiRequestRequest} request CreatePiiRequestRequest message or plain object
             * @param {streem.api.Companies.CreatePiiRequestCallback} callback Node-style callback called with the error, if any, and CreatePiiRequestResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createPiiRequest = function createPiiRequest(request, callback) {
                return this.rpcCall(createPiiRequest, $root.streem.api.CreatePiiRequestRequest, $root.streem.api.CreatePiiRequestResponse, request, callback);
            }, "name", { value: "CreatePiiRequest" });

            /**
             * Calls CreatePiiRequest.
             * @function createPiiRequest
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreatePiiRequestRequest} request CreatePiiRequestRequest message or plain object
             * @returns {Promise<streem.api.CreatePiiRequestResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#createCompanyRoomOutcomeConfig}.
             * @memberof streem.api.Companies
             * @typedef CreateCompanyRoomOutcomeConfigCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.CreateCompanyRoomOutcomeConfigResponse} [response] CreateCompanyRoomOutcomeConfigResponse
             */

            /**
             * Calls CreateCompanyRoomOutcomeConfig.
             * @function createCompanyRoomOutcomeConfig
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigRequest} request CreateCompanyRoomOutcomeConfigRequest message or plain object
             * @param {streem.api.Companies.CreateCompanyRoomOutcomeConfigCallback} callback Node-style callback called with the error, if any, and CreateCompanyRoomOutcomeConfigResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.createCompanyRoomOutcomeConfig = function createCompanyRoomOutcomeConfig(request, callback) {
                return this.rpcCall(createCompanyRoomOutcomeConfig, $root.streem.api.CreateCompanyRoomOutcomeConfigRequest, $root.streem.api.CreateCompanyRoomOutcomeConfigResponse, request, callback);
            }, "name", { value: "CreateCompanyRoomOutcomeConfig" });

            /**
             * Calls CreateCompanyRoomOutcomeConfig.
             * @function createCompanyRoomOutcomeConfig
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigRequest} request CreateCompanyRoomOutcomeConfigRequest message or plain object
             * @returns {Promise<streem.api.CreateCompanyRoomOutcomeConfigResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.Companies#getCompanyRoomOutcomeConfig}.
             * @memberof streem.api.Companies
             * @typedef GetCompanyRoomOutcomeConfigCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.GetCompanyRoomOutcomeConfigResponse} [response] GetCompanyRoomOutcomeConfigResponse
             */

            /**
             * Calls GetCompanyRoomOutcomeConfig.
             * @function getCompanyRoomOutcomeConfig
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyRoomOutcomeConfigRequest} request GetCompanyRoomOutcomeConfigRequest message or plain object
             * @param {streem.api.Companies.GetCompanyRoomOutcomeConfigCallback} callback Node-style callback called with the error, if any, and GetCompanyRoomOutcomeConfigResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Companies.prototype.getCompanyRoomOutcomeConfig = function getCompanyRoomOutcomeConfig(request, callback) {
                return this.rpcCall(getCompanyRoomOutcomeConfig, $root.streem.api.GetCompanyRoomOutcomeConfigRequest, $root.streem.api.GetCompanyRoomOutcomeConfigResponse, request, callback);
            }, "name", { value: "GetCompanyRoomOutcomeConfig" });

            /**
             * Calls GetCompanyRoomOutcomeConfig.
             * @function getCompanyRoomOutcomeConfig
             * @memberof streem.api.Companies
             * @instance
             * @param {streem.api.IGetCompanyRoomOutcomeConfigRequest} request GetCompanyRoomOutcomeConfigRequest message or plain object
             * @returns {Promise<streem.api.GetCompanyRoomOutcomeConfigResponse>} Promise
             * @variation 2
             */

            return Companies;
        })();

        api.ListCompaniesRequest = (function() {

            /**
             * Properties of a ListCompaniesRequest.
             * @memberof streem.api
             * @interface IListCompaniesRequest
             * @property {string|null} [pageToken] ListCompaniesRequest pageToken
             * @property {number|null} [pageSize] ListCompaniesRequest pageSize
             * @property {string|null} [sort] ListCompaniesRequest sort
             * @property {string|null} [filter] ListCompaniesRequest filter
             * @property {google.protobuf.IBoolValue|null} [active] ListCompaniesRequest active
             * @property {string|null} [parentCompanySid] ListCompaniesRequest parentCompanySid
             */

            /**
             * Constructs a new ListCompaniesRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompaniesRequest.
             * @implements IListCompaniesRequest
             * @constructor
             * @param {streem.api.IListCompaniesRequest=} [p] Properties to set
             */
            function ListCompaniesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompaniesRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.pageToken = "";

            /**
             * ListCompaniesRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.pageSize = 0;

            /**
             * ListCompaniesRequest sort.
             * @member {string} sort
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.sort = "";

            /**
             * ListCompaniesRequest filter.
             * @member {string} filter
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.filter = "";

            /**
             * ListCompaniesRequest active.
             * @member {google.protobuf.IBoolValue|null|undefined} active
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.active = null;

            /**
             * ListCompaniesRequest parentCompanySid.
             * @member {string} parentCompanySid
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             */
            ListCompaniesRequest.prototype.parentCompanySid = "";

            /**
             * Creates a new ListCompaniesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {streem.api.IListCompaniesRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompaniesRequest} ListCompaniesRequest instance
             */
            ListCompaniesRequest.create = function create(properties) {
                return new ListCompaniesRequest(properties);
            };

            /**
             * Encodes the specified ListCompaniesRequest message. Does not implicitly {@link streem.api.ListCompaniesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {streem.api.IListCompaniesRequest} m ListCompaniesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompaniesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(10).string(m.pageToken);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.sort != null && Object.hasOwnProperty.call(m, "sort"))
                    w.uint32(26).string(m.sort);
                if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                    w.uint32(34).string(m.filter);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    $root.google.protobuf.BoolValue.encode(m.active, w.uint32(42).fork()).ldelim();
                if (m.parentCompanySid != null && Object.hasOwnProperty.call(m, "parentCompanySid"))
                    w.uint32(50).string(m.parentCompanySid);
                return w;
            };

            /**
             * Decodes a ListCompaniesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompaniesRequest} ListCompaniesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompaniesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompaniesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.sort = r.string();
                            break;
                        }
                    case 4: {
                            m.filter = r.string();
                            break;
                        }
                    case 5: {
                            m.active = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.parentCompanySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompaniesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompaniesRequest} ListCompaniesRequest
             */
            ListCompaniesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompaniesRequest)
                    return d;
                var m = new $root.streem.api.ListCompaniesRequest();
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.sort != null) {
                    m.sort = String(d.sort);
                }
                if (d.filter != null) {
                    m.filter = String(d.filter);
                }
                if (d.active != null) {
                    if (typeof d.active !== "object")
                        throw TypeError(".streem.api.ListCompaniesRequest.active: object expected");
                    m.active = $root.google.protobuf.BoolValue.fromObject(d.active);
                }
                if (d.parentCompanySid != null) {
                    m.parentCompanySid = String(d.parentCompanySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompaniesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {streem.api.ListCompaniesRequest} m ListCompaniesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompaniesRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.pageToken = "";
                    d.pageSize = 0;
                    d.sort = "";
                    d.filter = "";
                    d.active = null;
                    d.parentCompanySid = "";
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.sort != null && m.hasOwnProperty("sort")) {
                    d.sort = m.sort;
                }
                if (m.filter != null && m.hasOwnProperty("filter")) {
                    d.filter = m.filter;
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = $root.google.protobuf.BoolValue.toObject(m.active, o);
                }
                if (m.parentCompanySid != null && m.hasOwnProperty("parentCompanySid")) {
                    d.parentCompanySid = m.parentCompanySid;
                }
                return d;
            };

            /**
             * Converts this ListCompaniesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompaniesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompaniesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompaniesRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompaniesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompaniesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompaniesRequest";
            };

            return ListCompaniesRequest;
        })();

        api.ListCompaniesResponse = (function() {

            /**
             * Properties of a ListCompaniesResponse.
             * @memberof streem.api
             * @interface IListCompaniesResponse
             * @property {Array.<streem.api.ICompany>|null} [companies] ListCompaniesResponse companies
             * @property {string|null} [nextPageToken] ListCompaniesResponse nextPageToken
             * @property {number|null} [totalSize] ListCompaniesResponse totalSize
             */

            /**
             * Constructs a new ListCompaniesResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompaniesResponse.
             * @implements IListCompaniesResponse
             * @constructor
             * @param {streem.api.IListCompaniesResponse=} [p] Properties to set
             */
            function ListCompaniesResponse(p) {
                this.companies = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompaniesResponse companies.
             * @member {Array.<streem.api.ICompany>} companies
             * @memberof streem.api.ListCompaniesResponse
             * @instance
             */
            ListCompaniesResponse.prototype.companies = $util.emptyArray;

            /**
             * ListCompaniesResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListCompaniesResponse
             * @instance
             */
            ListCompaniesResponse.prototype.nextPageToken = "";

            /**
             * ListCompaniesResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListCompaniesResponse
             * @instance
             */
            ListCompaniesResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListCompaniesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {streem.api.IListCompaniesResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompaniesResponse} ListCompaniesResponse instance
             */
            ListCompaniesResponse.create = function create(properties) {
                return new ListCompaniesResponse(properties);
            };

            /**
             * Encodes the specified ListCompaniesResponse message. Does not implicitly {@link streem.api.ListCompaniesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {streem.api.IListCompaniesResponse} m ListCompaniesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompaniesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companies != null && m.companies.length) {
                    for (var i = 0; i < m.companies.length; ++i)
                        $root.streem.api.Company.encode(m.companies[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListCompaniesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompaniesResponse} ListCompaniesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompaniesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompaniesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.companies && m.companies.length))
                                m.companies = [];
                            m.companies.push($root.streem.api.Company.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompaniesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompaniesResponse} ListCompaniesResponse
             */
            ListCompaniesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompaniesResponse)
                    return d;
                var m = new $root.streem.api.ListCompaniesResponse();
                if (d.companies) {
                    if (!Array.isArray(d.companies))
                        throw TypeError(".streem.api.ListCompaniesResponse.companies: array expected");
                    m.companies = [];
                    for (var i = 0; i < d.companies.length; ++i) {
                        if (typeof d.companies[i] !== "object")
                            throw TypeError(".streem.api.ListCompaniesResponse.companies: object expected");
                        m.companies[i] = $root.streem.api.Company.fromObject(d.companies[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompaniesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {streem.api.ListCompaniesResponse} m ListCompaniesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompaniesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.companies = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.companies && m.companies.length) {
                    d.companies = [];
                    for (var j = 0; j < m.companies.length; ++j) {
                        d.companies[j] = $root.streem.api.Company.toObject(m.companies[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListCompaniesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompaniesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompaniesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompaniesResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompaniesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompaniesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompaniesResponse";
            };

            return ListCompaniesResponse;
        })();

        api.CreateCompanyRequest = (function() {

            /**
             * Properties of a CreateCompanyRequest.
             * @memberof streem.api
             * @interface ICreateCompanyRequest
             * @property {string|null} [code] CreateCompanyRequest code
             * @property {string|null} [name] CreateCompanyRequest name
             * @property {string|null} [parentCompanySid] CreateCompanyRequest parentCompanySid
             * @property {string|null} [ownerEmail] CreateCompanyRequest ownerEmail
             * @property {string|null} [ownerName] CreateCompanyRequest ownerName
             */

            /**
             * Constructs a new CreateCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyRequest.
             * @implements ICreateCompanyRequest
             * @constructor
             * @param {streem.api.ICreateCompanyRequest=} [p] Properties to set
             */
            function CreateCompanyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyRequest code.
             * @member {string} code
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             */
            CreateCompanyRequest.prototype.code = "";

            /**
             * CreateCompanyRequest name.
             * @member {string} name
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             */
            CreateCompanyRequest.prototype.name = "";

            /**
             * CreateCompanyRequest parentCompanySid.
             * @member {string} parentCompanySid
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             */
            CreateCompanyRequest.prototype.parentCompanySid = "";

            /**
             * CreateCompanyRequest ownerEmail.
             * @member {string} ownerEmail
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             */
            CreateCompanyRequest.prototype.ownerEmail = "";

            /**
             * CreateCompanyRequest ownerName.
             * @member {string} ownerName
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             */
            CreateCompanyRequest.prototype.ownerName = "";

            /**
             * Creates a new CreateCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {streem.api.ICreateCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyRequest} CreateCompanyRequest instance
             */
            CreateCompanyRequest.create = function create(properties) {
                return new CreateCompanyRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyRequest message. Does not implicitly {@link streem.api.CreateCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {streem.api.ICreateCompanyRequest} m CreateCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(18).string(m.code);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.parentCompanySid != null && Object.hasOwnProperty.call(m, "parentCompanySid"))
                    w.uint32(34).string(m.parentCompanySid);
                if (m.ownerEmail != null && Object.hasOwnProperty.call(m, "ownerEmail"))
                    w.uint32(42).string(m.ownerEmail);
                if (m.ownerName != null && Object.hasOwnProperty.call(m, "ownerName"))
                    w.uint32(50).string(m.ownerName);
                return w;
            };

            /**
             * Decodes a CreateCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyRequest} CreateCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            m.code = r.string();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    case 4: {
                            m.parentCompanySid = r.string();
                            break;
                        }
                    case 5: {
                            m.ownerEmail = r.string();
                            break;
                        }
                    case 6: {
                            m.ownerName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyRequest} CreateCompanyRequest
             */
            CreateCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyRequest();
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.parentCompanySid != null) {
                    m.parentCompanySid = String(d.parentCompanySid);
                }
                if (d.ownerEmail != null) {
                    m.ownerEmail = String(d.ownerEmail);
                }
                if (d.ownerName != null) {
                    m.ownerName = String(d.ownerName);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {streem.api.CreateCompanyRequest} m CreateCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.code = "";
                    d.name = "";
                    d.parentCompanySid = "";
                    d.ownerEmail = "";
                    d.ownerName = "";
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.parentCompanySid != null && m.hasOwnProperty("parentCompanySid")) {
                    d.parentCompanySid = m.parentCompanySid;
                }
                if (m.ownerEmail != null && m.hasOwnProperty("ownerEmail")) {
                    d.ownerEmail = m.ownerEmail;
                }
                if (m.ownerName != null && m.hasOwnProperty("ownerName")) {
                    d.ownerName = m.ownerName;
                }
                return d;
            };

            /**
             * Converts this CreateCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyRequest";
            };

            return CreateCompanyRequest;
        })();

        api.CreateCompanyResponse = (function() {

            /**
             * Properties of a CreateCompanyResponse.
             * @memberof streem.api
             * @interface ICreateCompanyResponse
             * @property {streem.api.ICompany|null} [company] CreateCompanyResponse company
             * @property {string|null} [oidcIssuer] CreateCompanyResponse oidcIssuer
             * @property {streem.api.IUser|null} [ownerUser] CreateCompanyResponse ownerUser
             */

            /**
             * Constructs a new CreateCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyResponse.
             * @implements ICreateCompanyResponse
             * @constructor
             * @param {streem.api.ICreateCompanyResponse=} [p] Properties to set
             */
            function CreateCompanyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyResponse company.
             * @member {streem.api.ICompany|null|undefined} company
             * @memberof streem.api.CreateCompanyResponse
             * @instance
             */
            CreateCompanyResponse.prototype.company = null;

            /**
             * CreateCompanyResponse oidcIssuer.
             * @member {string} oidcIssuer
             * @memberof streem.api.CreateCompanyResponse
             * @instance
             */
            CreateCompanyResponse.prototype.oidcIssuer = "";

            /**
             * CreateCompanyResponse ownerUser.
             * @member {streem.api.IUser|null|undefined} ownerUser
             * @memberof streem.api.CreateCompanyResponse
             * @instance
             */
            CreateCompanyResponse.prototype.ownerUser = null;

            /**
             * Creates a new CreateCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {streem.api.ICreateCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyResponse} CreateCompanyResponse instance
             */
            CreateCompanyResponse.create = function create(properties) {
                return new CreateCompanyResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyResponse message. Does not implicitly {@link streem.api.CreateCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {streem.api.ICreateCompanyResponse} m CreateCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.company != null && Object.hasOwnProperty.call(m, "company"))
                    $root.streem.api.Company.encode(m.company, w.uint32(10).fork()).ldelim();
                if (m.oidcIssuer != null && Object.hasOwnProperty.call(m, "oidcIssuer"))
                    w.uint32(18).string(m.oidcIssuer);
                if (m.ownerUser != null && Object.hasOwnProperty.call(m, "ownerUser"))
                    $root.streem.api.User.encode(m.ownerUser, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyResponse} CreateCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.company = $root.streem.api.Company.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.oidcIssuer = r.string();
                            break;
                        }
                    case 3: {
                            m.ownerUser = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyResponse} CreateCompanyResponse
             */
            CreateCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyResponse)
                    return d;
                var m = new $root.streem.api.CreateCompanyResponse();
                if (d.company != null) {
                    if (typeof d.company !== "object")
                        throw TypeError(".streem.api.CreateCompanyResponse.company: object expected");
                    m.company = $root.streem.api.Company.fromObject(d.company);
                }
                if (d.oidcIssuer != null) {
                    m.oidcIssuer = String(d.oidcIssuer);
                }
                if (d.ownerUser != null) {
                    if (typeof d.ownerUser !== "object")
                        throw TypeError(".streem.api.CreateCompanyResponse.ownerUser: object expected");
                    m.ownerUser = $root.streem.api.User.fromObject(d.ownerUser);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {streem.api.CreateCompanyResponse} m CreateCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.company = null;
                    d.oidcIssuer = "";
                    d.ownerUser = null;
                }
                if (m.company != null && m.hasOwnProperty("company")) {
                    d.company = $root.streem.api.Company.toObject(m.company, o);
                }
                if (m.oidcIssuer != null && m.hasOwnProperty("oidcIssuer")) {
                    d.oidcIssuer = m.oidcIssuer;
                }
                if (m.ownerUser != null && m.hasOwnProperty("ownerUser")) {
                    d.ownerUser = $root.streem.api.User.toObject(m.ownerUser, o);
                }
                return d;
            };

            /**
             * Converts this CreateCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyResponse";
            };

            return CreateCompanyResponse;
        })();

        api.BatchCreateCompanyRequest = (function() {

            /**
             * Properties of a BatchCreateCompanyRequest.
             * @memberof streem.api
             * @interface IBatchCreateCompanyRequest
             * @property {string|null} [csvData] BatchCreateCompanyRequest csvData
             * @property {Array.<streem.api.ISetting>|null} [settings] BatchCreateCompanyRequest settings
             */

            /**
             * Constructs a new BatchCreateCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents a BatchCreateCompanyRequest.
             * @implements IBatchCreateCompanyRequest
             * @constructor
             * @param {streem.api.IBatchCreateCompanyRequest=} [p] Properties to set
             */
            function BatchCreateCompanyRequest(p) {
                this.settings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchCreateCompanyRequest csvData.
             * @member {string} csvData
             * @memberof streem.api.BatchCreateCompanyRequest
             * @instance
             */
            BatchCreateCompanyRequest.prototype.csvData = "";

            /**
             * BatchCreateCompanyRequest settings.
             * @member {Array.<streem.api.ISetting>} settings
             * @memberof streem.api.BatchCreateCompanyRequest
             * @instance
             */
            BatchCreateCompanyRequest.prototype.settings = $util.emptyArray;

            /**
             * Creates a new BatchCreateCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {streem.api.IBatchCreateCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.BatchCreateCompanyRequest} BatchCreateCompanyRequest instance
             */
            BatchCreateCompanyRequest.create = function create(properties) {
                return new BatchCreateCompanyRequest(properties);
            };

            /**
             * Encodes the specified BatchCreateCompanyRequest message. Does not implicitly {@link streem.api.BatchCreateCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {streem.api.IBatchCreateCompanyRequest} m BatchCreateCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchCreateCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.csvData != null && Object.hasOwnProperty.call(m, "csvData"))
                    w.uint32(10).string(m.csvData);
                if (m.settings != null && m.settings.length) {
                    for (var i = 0; i < m.settings.length; ++i)
                        $root.streem.api.Setting.encode(m.settings[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchCreateCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchCreateCompanyRequest} BatchCreateCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchCreateCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.csvData = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.settings && m.settings.length))
                                m.settings = [];
                            m.settings.push($root.streem.api.Setting.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchCreateCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchCreateCompanyRequest} BatchCreateCompanyRequest
             */
            BatchCreateCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchCreateCompanyRequest)
                    return d;
                var m = new $root.streem.api.BatchCreateCompanyRequest();
                if (d.csvData != null) {
                    m.csvData = String(d.csvData);
                }
                if (d.settings) {
                    if (!Array.isArray(d.settings))
                        throw TypeError(".streem.api.BatchCreateCompanyRequest.settings: array expected");
                    m.settings = [];
                    for (var i = 0; i < d.settings.length; ++i) {
                        if (typeof d.settings[i] !== "object")
                            throw TypeError(".streem.api.BatchCreateCompanyRequest.settings: object expected");
                        m.settings[i] = $root.streem.api.Setting.fromObject(d.settings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchCreateCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {streem.api.BatchCreateCompanyRequest} m BatchCreateCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchCreateCompanyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.settings = [];
                }
                if (o.defaults) {
                    d.csvData = "";
                }
                if (m.csvData != null && m.hasOwnProperty("csvData")) {
                    d.csvData = m.csvData;
                }
                if (m.settings && m.settings.length) {
                    d.settings = [];
                    for (var j = 0; j < m.settings.length; ++j) {
                        d.settings[j] = $root.streem.api.Setting.toObject(m.settings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchCreateCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.BatchCreateCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchCreateCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchCreateCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.BatchCreateCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchCreateCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchCreateCompanyRequest";
            };

            return BatchCreateCompanyRequest;
        })();

        api.BatchCreateCompanyResponse = (function() {

            /**
             * Properties of a BatchCreateCompanyResponse.
             * @memberof streem.api
             * @interface IBatchCreateCompanyResponse
             * @property {Array.<streem.api.ICreateCompanyResponse>|null} [created] BatchCreateCompanyResponse created
             * @property {Array.<streem.api.BatchCreateCompanyResponse.IFailure>|null} [failed] BatchCreateCompanyResponse failed
             */

            /**
             * Constructs a new BatchCreateCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents a BatchCreateCompanyResponse.
             * @implements IBatchCreateCompanyResponse
             * @constructor
             * @param {streem.api.IBatchCreateCompanyResponse=} [p] Properties to set
             */
            function BatchCreateCompanyResponse(p) {
                this.created = [];
                this.failed = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BatchCreateCompanyResponse created.
             * @member {Array.<streem.api.ICreateCompanyResponse>} created
             * @memberof streem.api.BatchCreateCompanyResponse
             * @instance
             */
            BatchCreateCompanyResponse.prototype.created = $util.emptyArray;

            /**
             * BatchCreateCompanyResponse failed.
             * @member {Array.<streem.api.BatchCreateCompanyResponse.IFailure>} failed
             * @memberof streem.api.BatchCreateCompanyResponse
             * @instance
             */
            BatchCreateCompanyResponse.prototype.failed = $util.emptyArray;

            /**
             * Creates a new BatchCreateCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {streem.api.IBatchCreateCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.BatchCreateCompanyResponse} BatchCreateCompanyResponse instance
             */
            BatchCreateCompanyResponse.create = function create(properties) {
                return new BatchCreateCompanyResponse(properties);
            };

            /**
             * Encodes the specified BatchCreateCompanyResponse message. Does not implicitly {@link streem.api.BatchCreateCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {streem.api.IBatchCreateCompanyResponse} m BatchCreateCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BatchCreateCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.created != null && m.created.length) {
                    for (var i = 0; i < m.created.length; ++i)
                        $root.streem.api.CreateCompanyResponse.encode(m.created[i], w.uint32(10).fork()).ldelim();
                }
                if (m.failed != null && m.failed.length) {
                    for (var i = 0; i < m.failed.length; ++i)
                        $root.streem.api.BatchCreateCompanyResponse.Failure.encode(m.failed[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a BatchCreateCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.BatchCreateCompanyResponse} BatchCreateCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BatchCreateCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.created && m.created.length))
                                m.created = [];
                            m.created.push($root.streem.api.CreateCompanyResponse.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(m.failed && m.failed.length))
                                m.failed = [];
                            m.failed.push($root.streem.api.BatchCreateCompanyResponse.Failure.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BatchCreateCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.BatchCreateCompanyResponse} BatchCreateCompanyResponse
             */
            BatchCreateCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.BatchCreateCompanyResponse)
                    return d;
                var m = new $root.streem.api.BatchCreateCompanyResponse();
                if (d.created) {
                    if (!Array.isArray(d.created))
                        throw TypeError(".streem.api.BatchCreateCompanyResponse.created: array expected");
                    m.created = [];
                    for (var i = 0; i < d.created.length; ++i) {
                        if (typeof d.created[i] !== "object")
                            throw TypeError(".streem.api.BatchCreateCompanyResponse.created: object expected");
                        m.created[i] = $root.streem.api.CreateCompanyResponse.fromObject(d.created[i]);
                    }
                }
                if (d.failed) {
                    if (!Array.isArray(d.failed))
                        throw TypeError(".streem.api.BatchCreateCompanyResponse.failed: array expected");
                    m.failed = [];
                    for (var i = 0; i < d.failed.length; ++i) {
                        if (typeof d.failed[i] !== "object")
                            throw TypeError(".streem.api.BatchCreateCompanyResponse.failed: object expected");
                        m.failed[i] = $root.streem.api.BatchCreateCompanyResponse.Failure.fromObject(d.failed[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a BatchCreateCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {streem.api.BatchCreateCompanyResponse} m BatchCreateCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BatchCreateCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.created = [];
                    d.failed = [];
                }
                if (m.created && m.created.length) {
                    d.created = [];
                    for (var j = 0; j < m.created.length; ++j) {
                        d.created[j] = $root.streem.api.CreateCompanyResponse.toObject(m.created[j], o);
                    }
                }
                if (m.failed && m.failed.length) {
                    d.failed = [];
                    for (var j = 0; j < m.failed.length; ++j) {
                        d.failed[j] = $root.streem.api.BatchCreateCompanyResponse.Failure.toObject(m.failed[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this BatchCreateCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.BatchCreateCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BatchCreateCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BatchCreateCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.BatchCreateCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BatchCreateCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.BatchCreateCompanyResponse";
            };

            BatchCreateCompanyResponse.Failure = (function() {

                /**
                 * Properties of a Failure.
                 * @memberof streem.api.BatchCreateCompanyResponse
                 * @interface IFailure
                 * @property {streem.api.IErrorResponse|null} [error] Failure error
                 * @property {string|null} [companyCode] Failure companyCode
                 * @property {string|null} [ownerEmail] Failure ownerEmail
                 */

                /**
                 * Constructs a new Failure.
                 * @memberof streem.api.BatchCreateCompanyResponse
                 * @classdesc Represents a Failure.
                 * @implements IFailure
                 * @constructor
                 * @param {streem.api.BatchCreateCompanyResponse.IFailure=} [p] Properties to set
                 */
                function Failure(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Failure error.
                 * @member {streem.api.IErrorResponse|null|undefined} error
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @instance
                 */
                Failure.prototype.error = null;

                /**
                 * Failure companyCode.
                 * @member {string} companyCode
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @instance
                 */
                Failure.prototype.companyCode = "";

                /**
                 * Failure ownerEmail.
                 * @member {string} ownerEmail
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @instance
                 */
                Failure.prototype.ownerEmail = "";

                /**
                 * Creates a new Failure instance using the specified properties.
                 * @function create
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateCompanyResponse.IFailure=} [properties] Properties to set
                 * @returns {streem.api.BatchCreateCompanyResponse.Failure} Failure instance
                 */
                Failure.create = function create(properties) {
                    return new Failure(properties);
                };

                /**
                 * Encodes the specified Failure message. Does not implicitly {@link streem.api.BatchCreateCompanyResponse.Failure.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateCompanyResponse.IFailure} m Failure message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Failure.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                        $root.streem.api.ErrorResponse.encode(m.error, w.uint32(10).fork()).ldelim();
                    if (m.companyCode != null && Object.hasOwnProperty.call(m, "companyCode"))
                        w.uint32(18).string(m.companyCode);
                    if (m.ownerEmail != null && Object.hasOwnProperty.call(m, "ownerEmail"))
                        w.uint32(26).string(m.ownerEmail);
                    return w;
                };

                /**
                 * Decodes a Failure message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.BatchCreateCompanyResponse.Failure} Failure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Failure.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.BatchCreateCompanyResponse.Failure();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.error = $root.streem.api.ErrorResponse.decode(r, r.uint32());
                                break;
                            }
                        case 2: {
                                m.companyCode = r.string();
                                break;
                            }
                        case 3: {
                                m.ownerEmail = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Failure message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.BatchCreateCompanyResponse.Failure} Failure
                 */
                Failure.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.BatchCreateCompanyResponse.Failure)
                        return d;
                    var m = new $root.streem.api.BatchCreateCompanyResponse.Failure();
                    if (d.error != null) {
                        if (typeof d.error !== "object")
                            throw TypeError(".streem.api.BatchCreateCompanyResponse.Failure.error: object expected");
                        m.error = $root.streem.api.ErrorResponse.fromObject(d.error);
                    }
                    if (d.companyCode != null) {
                        m.companyCode = String(d.companyCode);
                    }
                    if (d.ownerEmail != null) {
                        m.ownerEmail = String(d.ownerEmail);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Failure message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {streem.api.BatchCreateCompanyResponse.Failure} m Failure
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Failure.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.error = null;
                        d.companyCode = "";
                        d.ownerEmail = "";
                    }
                    if (m.error != null && m.hasOwnProperty("error")) {
                        d.error = $root.streem.api.ErrorResponse.toObject(m.error, o);
                    }
                    if (m.companyCode != null && m.hasOwnProperty("companyCode")) {
                        d.companyCode = m.companyCode;
                    }
                    if (m.ownerEmail != null && m.hasOwnProperty("ownerEmail")) {
                        d.ownerEmail = m.ownerEmail;
                    }
                    return d;
                };

                /**
                 * Converts this Failure to JSON.
                 * @function toJSON
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Failure.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Failure
                 * @function getTypeUrl
                 * @memberof streem.api.BatchCreateCompanyResponse.Failure
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Failure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.BatchCreateCompanyResponse.Failure";
                };

                return Failure;
            })();

            return BatchCreateCompanyResponse;
        })();

        api.SearchCompaniesForUserEmailRequest = (function() {

            /**
             * Properties of a SearchCompaniesForUserEmailRequest.
             * @memberof streem.api
             * @interface ISearchCompaniesForUserEmailRequest
             * @property {string|null} [email] SearchCompaniesForUserEmailRequest email
             * @property {google.protobuf.IBoolValue|null} [companyIsActive] SearchCompaniesForUserEmailRequest companyIsActive
             * @property {google.protobuf.IBoolValue|null} [userIsActive] SearchCompaniesForUserEmailRequest userIsActive
             * @property {google.protobuf.IBoolValue|null} [userIsExpert] SearchCompaniesForUserEmailRequest userIsExpert
             * @property {google.protobuf.IBoolValue|null} [userIsTypeLogin] SearchCompaniesForUserEmailRequest userIsTypeLogin
             * @property {google.protobuf.IBoolValue|null} [switchWorkspacesEnabled] SearchCompaniesForUserEmailRequest switchWorkspacesEnabled
             */

            /**
             * Constructs a new SearchCompaniesForUserEmailRequest.
             * @memberof streem.api
             * @classdesc Represents a SearchCompaniesForUserEmailRequest.
             * @implements ISearchCompaniesForUserEmailRequest
             * @constructor
             * @param {streem.api.ISearchCompaniesForUserEmailRequest=} [p] Properties to set
             */
            function SearchCompaniesForUserEmailRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SearchCompaniesForUserEmailRequest email.
             * @member {string} email
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.email = "";

            /**
             * SearchCompaniesForUserEmailRequest companyIsActive.
             * @member {google.protobuf.IBoolValue|null|undefined} companyIsActive
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.companyIsActive = null;

            /**
             * SearchCompaniesForUserEmailRequest userIsActive.
             * @member {google.protobuf.IBoolValue|null|undefined} userIsActive
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.userIsActive = null;

            /**
             * SearchCompaniesForUserEmailRequest userIsExpert.
             * @member {google.protobuf.IBoolValue|null|undefined} userIsExpert
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.userIsExpert = null;

            /**
             * SearchCompaniesForUserEmailRequest userIsTypeLogin.
             * @member {google.protobuf.IBoolValue|null|undefined} userIsTypeLogin
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.userIsTypeLogin = null;

            /**
             * SearchCompaniesForUserEmailRequest switchWorkspacesEnabled.
             * @member {google.protobuf.IBoolValue|null|undefined} switchWorkspacesEnabled
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             */
            SearchCompaniesForUserEmailRequest.prototype.switchWorkspacesEnabled = null;

            /**
             * Creates a new SearchCompaniesForUserEmailRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {streem.api.ISearchCompaniesForUserEmailRequest=} [properties] Properties to set
             * @returns {streem.api.SearchCompaniesForUserEmailRequest} SearchCompaniesForUserEmailRequest instance
             */
            SearchCompaniesForUserEmailRequest.create = function create(properties) {
                return new SearchCompaniesForUserEmailRequest(properties);
            };

            /**
             * Encodes the specified SearchCompaniesForUserEmailRequest message. Does not implicitly {@link streem.api.SearchCompaniesForUserEmailRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {streem.api.ISearchCompaniesForUserEmailRequest} m SearchCompaniesForUserEmailRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SearchCompaniesForUserEmailRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(10).string(m.email);
                if (m.companyIsActive != null && Object.hasOwnProperty.call(m, "companyIsActive"))
                    $root.google.protobuf.BoolValue.encode(m.companyIsActive, w.uint32(18).fork()).ldelim();
                if (m.userIsActive != null && Object.hasOwnProperty.call(m, "userIsActive"))
                    $root.google.protobuf.BoolValue.encode(m.userIsActive, w.uint32(26).fork()).ldelim();
                if (m.userIsExpert != null && Object.hasOwnProperty.call(m, "userIsExpert"))
                    $root.google.protobuf.BoolValue.encode(m.userIsExpert, w.uint32(34).fork()).ldelim();
                if (m.userIsTypeLogin != null && Object.hasOwnProperty.call(m, "userIsTypeLogin"))
                    $root.google.protobuf.BoolValue.encode(m.userIsTypeLogin, w.uint32(42).fork()).ldelim();
                if (m.switchWorkspacesEnabled != null && Object.hasOwnProperty.call(m, "switchWorkspacesEnabled"))
                    $root.google.protobuf.BoolValue.encode(m.switchWorkspacesEnabled, w.uint32(50).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SearchCompaniesForUserEmailRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SearchCompaniesForUserEmailRequest} SearchCompaniesForUserEmailRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SearchCompaniesForUserEmailRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SearchCompaniesForUserEmailRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.email = r.string();
                            break;
                        }
                    case 2: {
                            m.companyIsActive = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.userIsActive = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.userIsExpert = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.userIsTypeLogin = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.switchWorkspacesEnabled = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SearchCompaniesForUserEmailRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SearchCompaniesForUserEmailRequest} SearchCompaniesForUserEmailRequest
             */
            SearchCompaniesForUserEmailRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SearchCompaniesForUserEmailRequest)
                    return d;
                var m = new $root.streem.api.SearchCompaniesForUserEmailRequest();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.companyIsActive != null) {
                    if (typeof d.companyIsActive !== "object")
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailRequest.companyIsActive: object expected");
                    m.companyIsActive = $root.google.protobuf.BoolValue.fromObject(d.companyIsActive);
                }
                if (d.userIsActive != null) {
                    if (typeof d.userIsActive !== "object")
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailRequest.userIsActive: object expected");
                    m.userIsActive = $root.google.protobuf.BoolValue.fromObject(d.userIsActive);
                }
                if (d.userIsExpert != null) {
                    if (typeof d.userIsExpert !== "object")
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailRequest.userIsExpert: object expected");
                    m.userIsExpert = $root.google.protobuf.BoolValue.fromObject(d.userIsExpert);
                }
                if (d.userIsTypeLogin != null) {
                    if (typeof d.userIsTypeLogin !== "object")
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailRequest.userIsTypeLogin: object expected");
                    m.userIsTypeLogin = $root.google.protobuf.BoolValue.fromObject(d.userIsTypeLogin);
                }
                if (d.switchWorkspacesEnabled != null) {
                    if (typeof d.switchWorkspacesEnabled !== "object")
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailRequest.switchWorkspacesEnabled: object expected");
                    m.switchWorkspacesEnabled = $root.google.protobuf.BoolValue.fromObject(d.switchWorkspacesEnabled);
                }
                return m;
            };

            /**
             * Creates a plain object from a SearchCompaniesForUserEmailRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {streem.api.SearchCompaniesForUserEmailRequest} m SearchCompaniesForUserEmailRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SearchCompaniesForUserEmailRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.companyIsActive = null;
                    d.userIsActive = null;
                    d.userIsExpert = null;
                    d.userIsTypeLogin = null;
                    d.switchWorkspacesEnabled = null;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.companyIsActive != null && m.hasOwnProperty("companyIsActive")) {
                    d.companyIsActive = $root.google.protobuf.BoolValue.toObject(m.companyIsActive, o);
                }
                if (m.userIsActive != null && m.hasOwnProperty("userIsActive")) {
                    d.userIsActive = $root.google.protobuf.BoolValue.toObject(m.userIsActive, o);
                }
                if (m.userIsExpert != null && m.hasOwnProperty("userIsExpert")) {
                    d.userIsExpert = $root.google.protobuf.BoolValue.toObject(m.userIsExpert, o);
                }
                if (m.userIsTypeLogin != null && m.hasOwnProperty("userIsTypeLogin")) {
                    d.userIsTypeLogin = $root.google.protobuf.BoolValue.toObject(m.userIsTypeLogin, o);
                }
                if (m.switchWorkspacesEnabled != null && m.hasOwnProperty("switchWorkspacesEnabled")) {
                    d.switchWorkspacesEnabled = $root.google.protobuf.BoolValue.toObject(m.switchWorkspacesEnabled, o);
                }
                return d;
            };

            /**
             * Converts this SearchCompaniesForUserEmailRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SearchCompaniesForUserEmailRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SearchCompaniesForUserEmailRequest
             * @function getTypeUrl
             * @memberof streem.api.SearchCompaniesForUserEmailRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SearchCompaniesForUserEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SearchCompaniesForUserEmailRequest";
            };

            return SearchCompaniesForUserEmailRequest;
        })();

        api.SearchCompaniesForUserEmailResponse = (function() {

            /**
             * Properties of a SearchCompaniesForUserEmailResponse.
             * @memberof streem.api
             * @interface ISearchCompaniesForUserEmailResponse
             * @property {Array.<streem.api.ICompany>|null} [companies] SearchCompaniesForUserEmailResponse companies
             */

            /**
             * Constructs a new SearchCompaniesForUserEmailResponse.
             * @memberof streem.api
             * @classdesc Represents a SearchCompaniesForUserEmailResponse.
             * @implements ISearchCompaniesForUserEmailResponse
             * @constructor
             * @param {streem.api.ISearchCompaniesForUserEmailResponse=} [p] Properties to set
             */
            function SearchCompaniesForUserEmailResponse(p) {
                this.companies = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SearchCompaniesForUserEmailResponse companies.
             * @member {Array.<streem.api.ICompany>} companies
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @instance
             */
            SearchCompaniesForUserEmailResponse.prototype.companies = $util.emptyArray;

            /**
             * Creates a new SearchCompaniesForUserEmailResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {streem.api.ISearchCompaniesForUserEmailResponse=} [properties] Properties to set
             * @returns {streem.api.SearchCompaniesForUserEmailResponse} SearchCompaniesForUserEmailResponse instance
             */
            SearchCompaniesForUserEmailResponse.create = function create(properties) {
                return new SearchCompaniesForUserEmailResponse(properties);
            };

            /**
             * Encodes the specified SearchCompaniesForUserEmailResponse message. Does not implicitly {@link streem.api.SearchCompaniesForUserEmailResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {streem.api.ISearchCompaniesForUserEmailResponse} m SearchCompaniesForUserEmailResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SearchCompaniesForUserEmailResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companies != null && m.companies.length) {
                    for (var i = 0; i < m.companies.length; ++i)
                        $root.streem.api.Company.encode(m.companies[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a SearchCompaniesForUserEmailResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SearchCompaniesForUserEmailResponse} SearchCompaniesForUserEmailResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SearchCompaniesForUserEmailResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SearchCompaniesForUserEmailResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.companies && m.companies.length))
                                m.companies = [];
                            m.companies.push($root.streem.api.Company.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SearchCompaniesForUserEmailResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SearchCompaniesForUserEmailResponse} SearchCompaniesForUserEmailResponse
             */
            SearchCompaniesForUserEmailResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SearchCompaniesForUserEmailResponse)
                    return d;
                var m = new $root.streem.api.SearchCompaniesForUserEmailResponse();
                if (d.companies) {
                    if (!Array.isArray(d.companies))
                        throw TypeError(".streem.api.SearchCompaniesForUserEmailResponse.companies: array expected");
                    m.companies = [];
                    for (var i = 0; i < d.companies.length; ++i) {
                        if (typeof d.companies[i] !== "object")
                            throw TypeError(".streem.api.SearchCompaniesForUserEmailResponse.companies: object expected");
                        m.companies[i] = $root.streem.api.Company.fromObject(d.companies[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a SearchCompaniesForUserEmailResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {streem.api.SearchCompaniesForUserEmailResponse} m SearchCompaniesForUserEmailResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SearchCompaniesForUserEmailResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.companies = [];
                }
                if (m.companies && m.companies.length) {
                    d.companies = [];
                    for (var j = 0; j < m.companies.length; ++j) {
                        d.companies[j] = $root.streem.api.Company.toObject(m.companies[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this SearchCompaniesForUserEmailResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SearchCompaniesForUserEmailResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SearchCompaniesForUserEmailResponse
             * @function getTypeUrl
             * @memberof streem.api.SearchCompaniesForUserEmailResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SearchCompaniesForUserEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SearchCompaniesForUserEmailResponse";
            };

            return SearchCompaniesForUserEmailResponse;
        })();

        api.GetCurrentCompanyRequest = (function() {

            /**
             * Properties of a GetCurrentCompanyRequest.
             * @memberof streem.api
             * @interface IGetCurrentCompanyRequest
             */

            /**
             * Constructs a new GetCurrentCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentCompanyRequest.
             * @implements IGetCurrentCompanyRequest
             * @constructor
             * @param {streem.api.IGetCurrentCompanyRequest=} [p] Properties to set
             */
            function GetCurrentCompanyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCurrentCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {streem.api.IGetCurrentCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.GetCurrentCompanyRequest} GetCurrentCompanyRequest instance
             */
            GetCurrentCompanyRequest.create = function create(properties) {
                return new GetCurrentCompanyRequest(properties);
            };

            /**
             * Encodes the specified GetCurrentCompanyRequest message. Does not implicitly {@link streem.api.GetCurrentCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {streem.api.IGetCurrentCompanyRequest} m GetCurrentCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCurrentCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentCompanyRequest} GetCurrentCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentCompanyRequest} GetCurrentCompanyRequest
             */
            GetCurrentCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentCompanyRequest)
                    return d;
                return new $root.streem.api.GetCurrentCompanyRequest();
            };

            /**
             * Creates a plain object from a GetCurrentCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {streem.api.GetCurrentCompanyRequest} m GetCurrentCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentCompanyRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentCompanyRequest";
            };

            return GetCurrentCompanyRequest;
        })();

        api.GetCurrentCompanyResponse = (function() {

            /**
             * Properties of a GetCurrentCompanyResponse.
             * @memberof streem.api
             * @interface IGetCurrentCompanyResponse
             * @property {streem.api.ICompany|null} [company] GetCurrentCompanyResponse company
             */

            /**
             * Constructs a new GetCurrentCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCurrentCompanyResponse.
             * @implements IGetCurrentCompanyResponse
             * @constructor
             * @param {streem.api.IGetCurrentCompanyResponse=} [p] Properties to set
             */
            function GetCurrentCompanyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCurrentCompanyResponse company.
             * @member {streem.api.ICompany|null|undefined} company
             * @memberof streem.api.GetCurrentCompanyResponse
             * @instance
             */
            GetCurrentCompanyResponse.prototype.company = null;

            /**
             * Creates a new GetCurrentCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {streem.api.IGetCurrentCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.GetCurrentCompanyResponse} GetCurrentCompanyResponse instance
             */
            GetCurrentCompanyResponse.create = function create(properties) {
                return new GetCurrentCompanyResponse(properties);
            };

            /**
             * Encodes the specified GetCurrentCompanyResponse message. Does not implicitly {@link streem.api.GetCurrentCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {streem.api.IGetCurrentCompanyResponse} m GetCurrentCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.company != null && Object.hasOwnProperty.call(m, "company"))
                    $root.streem.api.Company.encode(m.company, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCurrentCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCurrentCompanyResponse} GetCurrentCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCurrentCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.company = $root.streem.api.Company.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCurrentCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCurrentCompanyResponse} GetCurrentCompanyResponse
             */
            GetCurrentCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCurrentCompanyResponse)
                    return d;
                var m = new $root.streem.api.GetCurrentCompanyResponse();
                if (d.company != null) {
                    if (typeof d.company !== "object")
                        throw TypeError(".streem.api.GetCurrentCompanyResponse.company: object expected");
                    m.company = $root.streem.api.Company.fromObject(d.company);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCurrentCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {streem.api.GetCurrentCompanyResponse} m GetCurrentCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.company = null;
                }
                if (m.company != null && m.hasOwnProperty("company")) {
                    d.company = $root.streem.api.Company.toObject(m.company, o);
                }
                return d;
            };

            /**
             * Converts this GetCurrentCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCurrentCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCurrentCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCurrentCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCurrentCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCurrentCompanyResponse";
            };

            return GetCurrentCompanyResponse;
        })();

        api.GetCompanyRequest = (function() {

            /**
             * Properties of a GetCompanyRequest.
             * @memberof streem.api
             * @interface IGetCompanyRequest
             * @property {string|null} [companyCodeOrSid] GetCompanyRequest companyCodeOrSid
             */

            /**
             * Constructs a new GetCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyRequest.
             * @implements IGetCompanyRequest
             * @constructor
             * @param {streem.api.IGetCompanyRequest=} [p] Properties to set
             */
            function GetCompanyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetCompanyRequest
             * @instance
             */
            GetCompanyRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new GetCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {streem.api.IGetCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyRequest} GetCompanyRequest instance
             */
            GetCompanyRequest.create = function create(properties) {
                return new GetCompanyRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyRequest message. Does not implicitly {@link streem.api.GetCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {streem.api.IGetCompanyRequest} m GetCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a GetCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyRequest} GetCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyRequest} GetCompanyRequest
             */
            GetCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {streem.api.GetCompanyRequest} m GetCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this GetCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyRequest";
            };

            return GetCompanyRequest;
        })();

        api.GetCompanyResponse = (function() {

            /**
             * Properties of a GetCompanyResponse.
             * @memberof streem.api
             * @interface IGetCompanyResponse
             * @property {streem.api.ICompany|null} [company] GetCompanyResponse company
             */

            /**
             * Constructs a new GetCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyResponse.
             * @implements IGetCompanyResponse
             * @constructor
             * @param {streem.api.IGetCompanyResponse=} [p] Properties to set
             */
            function GetCompanyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyResponse company.
             * @member {streem.api.ICompany|null|undefined} company
             * @memberof streem.api.GetCompanyResponse
             * @instance
             */
            GetCompanyResponse.prototype.company = null;

            /**
             * Creates a new GetCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {streem.api.IGetCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyResponse} GetCompanyResponse instance
             */
            GetCompanyResponse.create = function create(properties) {
                return new GetCompanyResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyResponse message. Does not implicitly {@link streem.api.GetCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {streem.api.IGetCompanyResponse} m GetCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.company != null && Object.hasOwnProperty.call(m, "company"))
                    $root.streem.api.Company.encode(m.company, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyResponse} GetCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.company = $root.streem.api.Company.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyResponse} GetCompanyResponse
             */
            GetCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyResponse();
                if (d.company != null) {
                    if (typeof d.company !== "object")
                        throw TypeError(".streem.api.GetCompanyResponse.company: object expected");
                    m.company = $root.streem.api.Company.fromObject(d.company);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {streem.api.GetCompanyResponse} m GetCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.company = null;
                }
                if (m.company != null && m.hasOwnProperty("company")) {
                    d.company = $root.streem.api.Company.toObject(m.company, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyResponse";
            };

            return GetCompanyResponse;
        })();

        api.DeleteCompanyAndAssetsRequest = (function() {

            /**
             * Properties of a DeleteCompanyAndAssetsRequest.
             * @memberof streem.api
             * @interface IDeleteCompanyAndAssetsRequest
             * @property {boolean|null} [deactivateCompany] DeleteCompanyAndAssetsRequest deactivateCompany
             * @property {boolean|null} [rooms] DeleteCompanyAndAssetsRequest rooms
             * @property {boolean|null} [roomNotes] DeleteCompanyAndAssetsRequest roomNotes
             * @property {boolean|null} [roomRecordings] DeleteCompanyAndAssetsRequest roomRecordings
             * @property {boolean|null} [meshes] DeleteCompanyAndAssetsRequest meshes
             * @property {boolean|null} [streemshots] DeleteCompanyAndAssetsRequest streemshots
             * @property {boolean|null} [streemshotNotes] DeleteCompanyAndAssetsRequest streemshotNotes
             * @property {boolean|null} [gpsPositions] DeleteCompanyAndAssetsRequest gpsPositions
             * @property {boolean|null} [deactivateUsers] DeleteCompanyAndAssetsRequest deactivateUsers
             * @property {boolean|null} [sceneshots] DeleteCompanyAndAssetsRequest sceneshots
             */

            /**
             * Constructs a new DeleteCompanyAndAssetsRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanyAndAssetsRequest.
             * @implements IDeleteCompanyAndAssetsRequest
             * @constructor
             * @param {streem.api.IDeleteCompanyAndAssetsRequest=} [p] Properties to set
             */
            function DeleteCompanyAndAssetsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteCompanyAndAssetsRequest deactivateCompany.
             * @member {boolean} deactivateCompany
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.deactivateCompany = false;

            /**
             * DeleteCompanyAndAssetsRequest rooms.
             * @member {boolean} rooms
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.rooms = false;

            /**
             * DeleteCompanyAndAssetsRequest roomNotes.
             * @member {boolean} roomNotes
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.roomNotes = false;

            /**
             * DeleteCompanyAndAssetsRequest roomRecordings.
             * @member {boolean} roomRecordings
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.roomRecordings = false;

            /**
             * DeleteCompanyAndAssetsRequest meshes.
             * @member {boolean} meshes
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.meshes = false;

            /**
             * DeleteCompanyAndAssetsRequest streemshots.
             * @member {boolean} streemshots
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.streemshots = false;

            /**
             * DeleteCompanyAndAssetsRequest streemshotNotes.
             * @member {boolean} streemshotNotes
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.streemshotNotes = false;

            /**
             * DeleteCompanyAndAssetsRequest gpsPositions.
             * @member {boolean} gpsPositions
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.gpsPositions = false;

            /**
             * DeleteCompanyAndAssetsRequest deactivateUsers.
             * @member {boolean} deactivateUsers
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.deactivateUsers = false;

            /**
             * DeleteCompanyAndAssetsRequest sceneshots.
             * @member {boolean} sceneshots
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             */
            DeleteCompanyAndAssetsRequest.prototype.sceneshots = false;

            /**
             * Creates a new DeleteCompanyAndAssetsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {streem.api.IDeleteCompanyAndAssetsRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanyAndAssetsRequest} DeleteCompanyAndAssetsRequest instance
             */
            DeleteCompanyAndAssetsRequest.create = function create(properties) {
                return new DeleteCompanyAndAssetsRequest(properties);
            };

            /**
             * Encodes the specified DeleteCompanyAndAssetsRequest message. Does not implicitly {@link streem.api.DeleteCompanyAndAssetsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {streem.api.IDeleteCompanyAndAssetsRequest} m DeleteCompanyAndAssetsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanyAndAssetsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deactivateCompany != null && Object.hasOwnProperty.call(m, "deactivateCompany"))
                    w.uint32(8).bool(m.deactivateCompany);
                if (m.rooms != null && Object.hasOwnProperty.call(m, "rooms"))
                    w.uint32(16).bool(m.rooms);
                if (m.roomNotes != null && Object.hasOwnProperty.call(m, "roomNotes"))
                    w.uint32(24).bool(m.roomNotes);
                if (m.roomRecordings != null && Object.hasOwnProperty.call(m, "roomRecordings"))
                    w.uint32(32).bool(m.roomRecordings);
                if (m.meshes != null && Object.hasOwnProperty.call(m, "meshes"))
                    w.uint32(40).bool(m.meshes);
                if (m.streemshots != null && Object.hasOwnProperty.call(m, "streemshots"))
                    w.uint32(48).bool(m.streemshots);
                if (m.streemshotNotes != null && Object.hasOwnProperty.call(m, "streemshotNotes"))
                    w.uint32(56).bool(m.streemshotNotes);
                if (m.gpsPositions != null && Object.hasOwnProperty.call(m, "gpsPositions"))
                    w.uint32(64).bool(m.gpsPositions);
                if (m.deactivateUsers != null && Object.hasOwnProperty.call(m, "deactivateUsers"))
                    w.uint32(72).bool(m.deactivateUsers);
                if (m.sceneshots != null && Object.hasOwnProperty.call(m, "sceneshots"))
                    w.uint32(80).bool(m.sceneshots);
                return w;
            };

            /**
             * Decodes a DeleteCompanyAndAssetsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanyAndAssetsRequest} DeleteCompanyAndAssetsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanyAndAssetsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanyAndAssetsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.deactivateCompany = r.bool();
                            break;
                        }
                    case 2: {
                            m.rooms = r.bool();
                            break;
                        }
                    case 3: {
                            m.roomNotes = r.bool();
                            break;
                        }
                    case 4: {
                            m.roomRecordings = r.bool();
                            break;
                        }
                    case 5: {
                            m.meshes = r.bool();
                            break;
                        }
                    case 6: {
                            m.streemshots = r.bool();
                            break;
                        }
                    case 7: {
                            m.streemshotNotes = r.bool();
                            break;
                        }
                    case 8: {
                            m.gpsPositions = r.bool();
                            break;
                        }
                    case 9: {
                            m.deactivateUsers = r.bool();
                            break;
                        }
                    case 10: {
                            m.sceneshots = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanyAndAssetsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanyAndAssetsRequest} DeleteCompanyAndAssetsRequest
             */
            DeleteCompanyAndAssetsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanyAndAssetsRequest)
                    return d;
                var m = new $root.streem.api.DeleteCompanyAndAssetsRequest();
                if (d.deactivateCompany != null) {
                    m.deactivateCompany = Boolean(d.deactivateCompany);
                }
                if (d.rooms != null) {
                    m.rooms = Boolean(d.rooms);
                }
                if (d.roomNotes != null) {
                    m.roomNotes = Boolean(d.roomNotes);
                }
                if (d.roomRecordings != null) {
                    m.roomRecordings = Boolean(d.roomRecordings);
                }
                if (d.meshes != null) {
                    m.meshes = Boolean(d.meshes);
                }
                if (d.streemshots != null) {
                    m.streemshots = Boolean(d.streemshots);
                }
                if (d.streemshotNotes != null) {
                    m.streemshotNotes = Boolean(d.streemshotNotes);
                }
                if (d.gpsPositions != null) {
                    m.gpsPositions = Boolean(d.gpsPositions);
                }
                if (d.deactivateUsers != null) {
                    m.deactivateUsers = Boolean(d.deactivateUsers);
                }
                if (d.sceneshots != null) {
                    m.sceneshots = Boolean(d.sceneshots);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteCompanyAndAssetsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {streem.api.DeleteCompanyAndAssetsRequest} m DeleteCompanyAndAssetsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanyAndAssetsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.deactivateCompany = false;
                    d.rooms = false;
                    d.roomNotes = false;
                    d.roomRecordings = false;
                    d.meshes = false;
                    d.streemshots = false;
                    d.streemshotNotes = false;
                    d.gpsPositions = false;
                    d.deactivateUsers = false;
                    d.sceneshots = false;
                }
                if (m.deactivateCompany != null && m.hasOwnProperty("deactivateCompany")) {
                    d.deactivateCompany = m.deactivateCompany;
                }
                if (m.rooms != null && m.hasOwnProperty("rooms")) {
                    d.rooms = m.rooms;
                }
                if (m.roomNotes != null && m.hasOwnProperty("roomNotes")) {
                    d.roomNotes = m.roomNotes;
                }
                if (m.roomRecordings != null && m.hasOwnProperty("roomRecordings")) {
                    d.roomRecordings = m.roomRecordings;
                }
                if (m.meshes != null && m.hasOwnProperty("meshes")) {
                    d.meshes = m.meshes;
                }
                if (m.streemshots != null && m.hasOwnProperty("streemshots")) {
                    d.streemshots = m.streemshots;
                }
                if (m.streemshotNotes != null && m.hasOwnProperty("streemshotNotes")) {
                    d.streemshotNotes = m.streemshotNotes;
                }
                if (m.gpsPositions != null && m.hasOwnProperty("gpsPositions")) {
                    d.gpsPositions = m.gpsPositions;
                }
                if (m.deactivateUsers != null && m.hasOwnProperty("deactivateUsers")) {
                    d.deactivateUsers = m.deactivateUsers;
                }
                if (m.sceneshots != null && m.hasOwnProperty("sceneshots")) {
                    d.sceneshots = m.sceneshots;
                }
                return d;
            };

            /**
             * Converts this DeleteCompanyAndAssetsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanyAndAssetsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanyAndAssetsRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanyAndAssetsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanyAndAssetsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanyAndAssetsRequest";
            };

            return DeleteCompanyAndAssetsRequest;
        })();

        api.UpdateCompanyRequest = (function() {

            /**
             * Properties of an UpdateCompanyRequest.
             * @memberof streem.api
             * @interface IUpdateCompanyRequest
             * @property {string|null} [companyCodeOrSid] UpdateCompanyRequest companyCodeOrSid
             * @property {google.protobuf.IStringValue|null} [name] UpdateCompanyRequest name
             * @property {google.protobuf.IBoolValue|null} [active] UpdateCompanyRequest active
             * @property {google.protobuf.IStringValue|null} [parentCompanySid] UpdateCompanyRequest parentCompanySid
             * @property {google.protobuf.IInt32Value|null} [subCompanyLimit] UpdateCompanyRequest subCompanyLimit
             */

            /**
             * Constructs a new UpdateCompanyRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyRequest.
             * @implements IUpdateCompanyRequest
             * @constructor
             * @param {streem.api.IUpdateCompanyRequest=} [p] Properties to set
             */
            function UpdateCompanyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             */
            UpdateCompanyRequest.prototype.companyCodeOrSid = "";

            /**
             * UpdateCompanyRequest name.
             * @member {google.protobuf.IStringValue|null|undefined} name
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             */
            UpdateCompanyRequest.prototype.name = null;

            /**
             * UpdateCompanyRequest active.
             * @member {google.protobuf.IBoolValue|null|undefined} active
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             */
            UpdateCompanyRequest.prototype.active = null;

            /**
             * UpdateCompanyRequest parentCompanySid.
             * @member {google.protobuf.IStringValue|null|undefined} parentCompanySid
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             */
            UpdateCompanyRequest.prototype.parentCompanySid = null;

            /**
             * UpdateCompanyRequest subCompanyLimit.
             * @member {google.protobuf.IInt32Value|null|undefined} subCompanyLimit
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             */
            UpdateCompanyRequest.prototype.subCompanyLimit = null;

            /**
             * Creates a new UpdateCompanyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {streem.api.IUpdateCompanyRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyRequest} UpdateCompanyRequest instance
             */
            UpdateCompanyRequest.create = function create(properties) {
                return new UpdateCompanyRequest(properties);
            };

            /**
             * Encodes the specified UpdateCompanyRequest message. Does not implicitly {@link streem.api.UpdateCompanyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {streem.api.IUpdateCompanyRequest} m UpdateCompanyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    $root.google.protobuf.StringValue.encode(m.name, w.uint32(18).fork()).ldelim();
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    $root.google.protobuf.BoolValue.encode(m.active, w.uint32(26).fork()).ldelim();
                if (m.parentCompanySid != null && Object.hasOwnProperty.call(m, "parentCompanySid"))
                    $root.google.protobuf.StringValue.encode(m.parentCompanySid, w.uint32(34).fork()).ldelim();
                if (m.subCompanyLimit != null && Object.hasOwnProperty.call(m, "subCompanyLimit"))
                    $root.google.protobuf.Int32Value.encode(m.subCompanyLimit, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateCompanyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyRequest} UpdateCompanyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.active = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.parentCompanySid = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.subCompanyLimit = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyRequest} UpdateCompanyRequest
             */
            UpdateCompanyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyRequest)
                    return d;
                var m = new $root.streem.api.UpdateCompanyRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.name != null) {
                    if (typeof d.name !== "object")
                        throw TypeError(".streem.api.UpdateCompanyRequest.name: object expected");
                    m.name = $root.google.protobuf.StringValue.fromObject(d.name);
                }
                if (d.active != null) {
                    if (typeof d.active !== "object")
                        throw TypeError(".streem.api.UpdateCompanyRequest.active: object expected");
                    m.active = $root.google.protobuf.BoolValue.fromObject(d.active);
                }
                if (d.parentCompanySid != null) {
                    if (typeof d.parentCompanySid !== "object")
                        throw TypeError(".streem.api.UpdateCompanyRequest.parentCompanySid: object expected");
                    m.parentCompanySid = $root.google.protobuf.StringValue.fromObject(d.parentCompanySid);
                }
                if (d.subCompanyLimit != null) {
                    if (typeof d.subCompanyLimit !== "object")
                        throw TypeError(".streem.api.UpdateCompanyRequest.subCompanyLimit: object expected");
                    m.subCompanyLimit = $root.google.protobuf.Int32Value.fromObject(d.subCompanyLimit);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {streem.api.UpdateCompanyRequest} m UpdateCompanyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.name = null;
                    d.active = null;
                    d.parentCompanySid = null;
                    d.subCompanyLimit = null;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = $root.google.protobuf.StringValue.toObject(m.name, o);
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = $root.google.protobuf.BoolValue.toObject(m.active, o);
                }
                if (m.parentCompanySid != null && m.hasOwnProperty("parentCompanySid")) {
                    d.parentCompanySid = $root.google.protobuf.StringValue.toObject(m.parentCompanySid, o);
                }
                if (m.subCompanyLimit != null && m.hasOwnProperty("subCompanyLimit")) {
                    d.subCompanyLimit = $root.google.protobuf.Int32Value.toObject(m.subCompanyLimit, o);
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyRequest";
            };

            return UpdateCompanyRequest;
        })();

        api.UpdateCompanyResponse = (function() {

            /**
             * Properties of an UpdateCompanyResponse.
             * @memberof streem.api
             * @interface IUpdateCompanyResponse
             * @property {streem.api.ICompany|null} [company] UpdateCompanyResponse company
             */

            /**
             * Constructs a new UpdateCompanyResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyResponse.
             * @implements IUpdateCompanyResponse
             * @constructor
             * @param {streem.api.IUpdateCompanyResponse=} [p] Properties to set
             */
            function UpdateCompanyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyResponse company.
             * @member {streem.api.ICompany|null|undefined} company
             * @memberof streem.api.UpdateCompanyResponse
             * @instance
             */
            UpdateCompanyResponse.prototype.company = null;

            /**
             * Creates a new UpdateCompanyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {streem.api.IUpdateCompanyResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyResponse} UpdateCompanyResponse instance
             */
            UpdateCompanyResponse.create = function create(properties) {
                return new UpdateCompanyResponse(properties);
            };

            /**
             * Encodes the specified UpdateCompanyResponse message. Does not implicitly {@link streem.api.UpdateCompanyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {streem.api.IUpdateCompanyResponse} m UpdateCompanyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.company != null && Object.hasOwnProperty.call(m, "company"))
                    $root.streem.api.Company.encode(m.company, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateCompanyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyResponse} UpdateCompanyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.company = $root.streem.api.Company.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyResponse} UpdateCompanyResponse
             */
            UpdateCompanyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyResponse)
                    return d;
                var m = new $root.streem.api.UpdateCompanyResponse();
                if (d.company != null) {
                    if (typeof d.company !== "object")
                        throw TypeError(".streem.api.UpdateCompanyResponse.company: object expected");
                    m.company = $root.streem.api.Company.fromObject(d.company);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {streem.api.UpdateCompanyResponse} m UpdateCompanyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.company = null;
                }
                if (m.company != null && m.hasOwnProperty("company")) {
                    d.company = $root.streem.api.Company.toObject(m.company, o);
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyResponse";
            };

            return UpdateCompanyResponse;
        })();

        api.GetPublicLoginConfigurationRequest = (function() {

            /**
             * Properties of a GetPublicLoginConfigurationRequest.
             * @memberof streem.api
             * @interface IGetPublicLoginConfigurationRequest
             * @property {string|null} [companyCodeOrSid] GetPublicLoginConfigurationRequest companyCodeOrSid
             */

            /**
             * Constructs a new GetPublicLoginConfigurationRequest.
             * @memberof streem.api
             * @classdesc Represents a GetPublicLoginConfigurationRequest.
             * @implements IGetPublicLoginConfigurationRequest
             * @constructor
             * @param {streem.api.IGetPublicLoginConfigurationRequest=} [p] Properties to set
             */
            function GetPublicLoginConfigurationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetPublicLoginConfigurationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @instance
             */
            GetPublicLoginConfigurationRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new GetPublicLoginConfigurationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {streem.api.IGetPublicLoginConfigurationRequest=} [properties] Properties to set
             * @returns {streem.api.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest instance
             */
            GetPublicLoginConfigurationRequest.create = function create(properties) {
                return new GetPublicLoginConfigurationRequest(properties);
            };

            /**
             * Encodes the specified GetPublicLoginConfigurationRequest message. Does not implicitly {@link streem.api.GetPublicLoginConfigurationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {streem.api.IGetPublicLoginConfigurationRequest} m GetPublicLoginConfigurationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPublicLoginConfigurationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a GetPublicLoginConfigurationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPublicLoginConfigurationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetPublicLoginConfigurationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetPublicLoginConfigurationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetPublicLoginConfigurationRequest} GetPublicLoginConfigurationRequest
             */
            GetPublicLoginConfigurationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetPublicLoginConfigurationRequest)
                    return d;
                var m = new $root.streem.api.GetPublicLoginConfigurationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetPublicLoginConfigurationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {streem.api.GetPublicLoginConfigurationRequest} m GetPublicLoginConfigurationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPublicLoginConfigurationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this GetPublicLoginConfigurationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPublicLoginConfigurationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPublicLoginConfigurationRequest
             * @function getTypeUrl
             * @memberof streem.api.GetPublicLoginConfigurationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPublicLoginConfigurationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetPublicLoginConfigurationRequest";
            };

            return GetPublicLoginConfigurationRequest;
        })();

        api.GetPublicLoginConfigurationResponse = (function() {

            /**
             * Properties of a GetPublicLoginConfigurationResponse.
             * @memberof streem.api
             * @interface IGetPublicLoginConfigurationResponse
             * @property {streem.api.IPublicLoginConfiguration|null} [loginConfiguration] GetPublicLoginConfigurationResponse loginConfiguration
             */

            /**
             * Constructs a new GetPublicLoginConfigurationResponse.
             * @memberof streem.api
             * @classdesc Represents a GetPublicLoginConfigurationResponse.
             * @implements IGetPublicLoginConfigurationResponse
             * @constructor
             * @param {streem.api.IGetPublicLoginConfigurationResponse=} [p] Properties to set
             */
            function GetPublicLoginConfigurationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetPublicLoginConfigurationResponse loginConfiguration.
             * @member {streem.api.IPublicLoginConfiguration|null|undefined} loginConfiguration
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @instance
             */
            GetPublicLoginConfigurationResponse.prototype.loginConfiguration = null;

            /**
             * Creates a new GetPublicLoginConfigurationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {streem.api.IGetPublicLoginConfigurationResponse=} [properties] Properties to set
             * @returns {streem.api.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse instance
             */
            GetPublicLoginConfigurationResponse.create = function create(properties) {
                return new GetPublicLoginConfigurationResponse(properties);
            };

            /**
             * Encodes the specified GetPublicLoginConfigurationResponse message. Does not implicitly {@link streem.api.GetPublicLoginConfigurationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {streem.api.IGetPublicLoginConfigurationResponse} m GetPublicLoginConfigurationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPublicLoginConfigurationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.loginConfiguration != null && Object.hasOwnProperty.call(m, "loginConfiguration"))
                    $root.streem.api.PublicLoginConfiguration.encode(m.loginConfiguration, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetPublicLoginConfigurationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPublicLoginConfigurationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetPublicLoginConfigurationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetPublicLoginConfigurationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetPublicLoginConfigurationResponse} GetPublicLoginConfigurationResponse
             */
            GetPublicLoginConfigurationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetPublicLoginConfigurationResponse)
                    return d;
                var m = new $root.streem.api.GetPublicLoginConfigurationResponse();
                if (d.loginConfiguration != null) {
                    if (typeof d.loginConfiguration !== "object")
                        throw TypeError(".streem.api.GetPublicLoginConfigurationResponse.loginConfiguration: object expected");
                    m.loginConfiguration = $root.streem.api.PublicLoginConfiguration.fromObject(d.loginConfiguration);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetPublicLoginConfigurationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {streem.api.GetPublicLoginConfigurationResponse} m GetPublicLoginConfigurationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPublicLoginConfigurationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.loginConfiguration = null;
                }
                if (m.loginConfiguration != null && m.hasOwnProperty("loginConfiguration")) {
                    d.loginConfiguration = $root.streem.api.PublicLoginConfiguration.toObject(m.loginConfiguration, o);
                }
                return d;
            };

            /**
             * Converts this GetPublicLoginConfigurationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPublicLoginConfigurationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPublicLoginConfigurationResponse
             * @function getTypeUrl
             * @memberof streem.api.GetPublicLoginConfigurationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPublicLoginConfigurationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetPublicLoginConfigurationResponse";
            };

            return GetPublicLoginConfigurationResponse;
        })();

        api.GetCompanyIdentityProviderRequest = (function() {

            /**
             * Properties of a GetCompanyIdentityProviderRequest.
             * @memberof streem.api
             * @interface IGetCompanyIdentityProviderRequest
             * @property {string|null} [companyCodeOrSid] GetCompanyIdentityProviderRequest companyCodeOrSid
             */

            /**
             * Constructs a new GetCompanyIdentityProviderRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyIdentityProviderRequest.
             * @implements IGetCompanyIdentityProviderRequest
             * @constructor
             * @param {streem.api.IGetCompanyIdentityProviderRequest=} [p] Properties to set
             */
            function GetCompanyIdentityProviderRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyIdentityProviderRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @instance
             */
            GetCompanyIdentityProviderRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new GetCompanyIdentityProviderRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.IGetCompanyIdentityProviderRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyIdentityProviderRequest} GetCompanyIdentityProviderRequest instance
             */
            GetCompanyIdentityProviderRequest.create = function create(properties) {
                return new GetCompanyIdentityProviderRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyIdentityProviderRequest message. Does not implicitly {@link streem.api.GetCompanyIdentityProviderRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.IGetCompanyIdentityProviderRequest} m GetCompanyIdentityProviderRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyIdentityProviderRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a GetCompanyIdentityProviderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyIdentityProviderRequest} GetCompanyIdentityProviderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyIdentityProviderRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyIdentityProviderRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyIdentityProviderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyIdentityProviderRequest} GetCompanyIdentityProviderRequest
             */
            GetCompanyIdentityProviderRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyIdentityProviderRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyIdentityProviderRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyIdentityProviderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.GetCompanyIdentityProviderRequest} m GetCompanyIdentityProviderRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyIdentityProviderRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this GetCompanyIdentityProviderRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyIdentityProviderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyIdentityProviderRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyIdentityProviderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyIdentityProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyIdentityProviderRequest";
            };

            return GetCompanyIdentityProviderRequest;
        })();

        api.GetCompanyIdentityProviderResponse = (function() {

            /**
             * Properties of a GetCompanyIdentityProviderResponse.
             * @memberof streem.api
             * @interface IGetCompanyIdentityProviderResponse
             * @property {streem.api.Company.IdentityProvider|null} [provider] GetCompanyIdentityProviderResponse provider
             * @property {string|null} [groupingId] GetCompanyIdentityProviderResponse groupingId
             */

            /**
             * Constructs a new GetCompanyIdentityProviderResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyIdentityProviderResponse.
             * @implements IGetCompanyIdentityProviderResponse
             * @constructor
             * @param {streem.api.IGetCompanyIdentityProviderResponse=} [p] Properties to set
             */
            function GetCompanyIdentityProviderResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyIdentityProviderResponse provider.
             * @member {streem.api.Company.IdentityProvider} provider
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @instance
             */
            GetCompanyIdentityProviderResponse.prototype.provider = 0;

            /**
             * GetCompanyIdentityProviderResponse groupingId.
             * @member {string} groupingId
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @instance
             */
            GetCompanyIdentityProviderResponse.prototype.groupingId = "";

            /**
             * Creates a new GetCompanyIdentityProviderResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.IGetCompanyIdentityProviderResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyIdentityProviderResponse} GetCompanyIdentityProviderResponse instance
             */
            GetCompanyIdentityProviderResponse.create = function create(properties) {
                return new GetCompanyIdentityProviderResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyIdentityProviderResponse message. Does not implicitly {@link streem.api.GetCompanyIdentityProviderResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.IGetCompanyIdentityProviderResponse} m GetCompanyIdentityProviderResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyIdentityProviderResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.provider != null && Object.hasOwnProperty.call(m, "provider"))
                    w.uint32(8).int32(m.provider);
                if (m.groupingId != null && Object.hasOwnProperty.call(m, "groupingId"))
                    w.uint32(18).string(m.groupingId);
                return w;
            };

            /**
             * Decodes a GetCompanyIdentityProviderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyIdentityProviderResponse} GetCompanyIdentityProviderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyIdentityProviderResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyIdentityProviderResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.provider = r.int32();
                            break;
                        }
                    case 2: {
                            m.groupingId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyIdentityProviderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyIdentityProviderResponse} GetCompanyIdentityProviderResponse
             */
            GetCompanyIdentityProviderResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyIdentityProviderResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyIdentityProviderResponse();
                switch (d.provider) {
                default:
                    if (typeof d.provider === "number") {
                        m.provider = d.provider;
                        break;
                    }
                    break;
                case "IDENTITY_PROVIDER_INVALID":
                case 0:
                    m.provider = 0;
                    break;
                case "IDENTITY_PROVIDER_COGNITO":
                case 1:
                    m.provider = 1;
                    break;
                case "IDENTITY_PROVIDER_FUSION_AUTH":
                case 2:
                    m.provider = 2;
                    break;
                }
                if (d.groupingId != null) {
                    m.groupingId = String(d.groupingId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyIdentityProviderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.GetCompanyIdentityProviderResponse} m GetCompanyIdentityProviderResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyIdentityProviderResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.provider = o.enums === String ? "IDENTITY_PROVIDER_INVALID" : 0;
                    d.groupingId = "";
                }
                if (m.provider != null && m.hasOwnProperty("provider")) {
                    d.provider = o.enums === String ? $root.streem.api.Company.IdentityProvider[m.provider] === undefined ? m.provider : $root.streem.api.Company.IdentityProvider[m.provider] : m.provider;
                }
                if (m.groupingId != null && m.hasOwnProperty("groupingId")) {
                    d.groupingId = m.groupingId;
                }
                return d;
            };

            /**
             * Converts this GetCompanyIdentityProviderResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyIdentityProviderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyIdentityProviderResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyIdentityProviderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyIdentityProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyIdentityProviderResponse";
            };

            return GetCompanyIdentityProviderResponse;
        })();

        api.UpdateCompanyIdentityProviderRequest = (function() {

            /**
             * Properties of an UpdateCompanyIdentityProviderRequest.
             * @memberof streem.api
             * @interface IUpdateCompanyIdentityProviderRequest
             * @property {string|null} [companyCodeOrSid] UpdateCompanyIdentityProviderRequest companyCodeOrSid
             * @property {streem.api.Company.IdentityProvider|null} [provider] UpdateCompanyIdentityProviderRequest provider
             * @property {string|null} [groupingId] UpdateCompanyIdentityProviderRequest groupingId
             */

            /**
             * Constructs a new UpdateCompanyIdentityProviderRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyIdentityProviderRequest.
             * @implements IUpdateCompanyIdentityProviderRequest
             * @constructor
             * @param {streem.api.IUpdateCompanyIdentityProviderRequest=} [p] Properties to set
             */
            function UpdateCompanyIdentityProviderRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyIdentityProviderRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @instance
             */
            UpdateCompanyIdentityProviderRequest.prototype.companyCodeOrSid = "";

            /**
             * UpdateCompanyIdentityProviderRequest provider.
             * @member {streem.api.Company.IdentityProvider} provider
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @instance
             */
            UpdateCompanyIdentityProviderRequest.prototype.provider = 0;

            /**
             * UpdateCompanyIdentityProviderRequest groupingId.
             * @member {string} groupingId
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @instance
             */
            UpdateCompanyIdentityProviderRequest.prototype.groupingId = "";

            /**
             * Creates a new UpdateCompanyIdentityProviderRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.IUpdateCompanyIdentityProviderRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyIdentityProviderRequest} UpdateCompanyIdentityProviderRequest instance
             */
            UpdateCompanyIdentityProviderRequest.create = function create(properties) {
                return new UpdateCompanyIdentityProviderRequest(properties);
            };

            /**
             * Encodes the specified UpdateCompanyIdentityProviderRequest message. Does not implicitly {@link streem.api.UpdateCompanyIdentityProviderRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.IUpdateCompanyIdentityProviderRequest} m UpdateCompanyIdentityProviderRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyIdentityProviderRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.provider != null && Object.hasOwnProperty.call(m, "provider"))
                    w.uint32(16).int32(m.provider);
                if (m.groupingId != null && Object.hasOwnProperty.call(m, "groupingId"))
                    w.uint32(26).string(m.groupingId);
                return w;
            };

            /**
             * Decodes an UpdateCompanyIdentityProviderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyIdentityProviderRequest} UpdateCompanyIdentityProviderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyIdentityProviderRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyIdentityProviderRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.provider = r.int32();
                            break;
                        }
                    case 3: {
                            m.groupingId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyIdentityProviderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyIdentityProviderRequest} UpdateCompanyIdentityProviderRequest
             */
            UpdateCompanyIdentityProviderRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyIdentityProviderRequest)
                    return d;
                var m = new $root.streem.api.UpdateCompanyIdentityProviderRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                switch (d.provider) {
                default:
                    if (typeof d.provider === "number") {
                        m.provider = d.provider;
                        break;
                    }
                    break;
                case "IDENTITY_PROVIDER_INVALID":
                case 0:
                    m.provider = 0;
                    break;
                case "IDENTITY_PROVIDER_COGNITO":
                case 1:
                    m.provider = 1;
                    break;
                case "IDENTITY_PROVIDER_FUSION_AUTH":
                case 2:
                    m.provider = 2;
                    break;
                }
                if (d.groupingId != null) {
                    m.groupingId = String(d.groupingId);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyIdentityProviderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {streem.api.UpdateCompanyIdentityProviderRequest} m UpdateCompanyIdentityProviderRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyIdentityProviderRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.provider = o.enums === String ? "IDENTITY_PROVIDER_INVALID" : 0;
                    d.groupingId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.provider != null && m.hasOwnProperty("provider")) {
                    d.provider = o.enums === String ? $root.streem.api.Company.IdentityProvider[m.provider] === undefined ? m.provider : $root.streem.api.Company.IdentityProvider[m.provider] : m.provider;
                }
                if (m.groupingId != null && m.hasOwnProperty("groupingId")) {
                    d.groupingId = m.groupingId;
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyIdentityProviderRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyIdentityProviderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyIdentityProviderRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyIdentityProviderRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyIdentityProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyIdentityProviderRequest";
            };

            return UpdateCompanyIdentityProviderRequest;
        })();

        api.UpdateCompanyIdentityProviderResponse = (function() {

            /**
             * Properties of an UpdateCompanyIdentityProviderResponse.
             * @memberof streem.api
             * @interface IUpdateCompanyIdentityProviderResponse
             * @property {streem.api.Company.IdentityProvider|null} [provider] UpdateCompanyIdentityProviderResponse provider
             * @property {string|null} [groupingId] UpdateCompanyIdentityProviderResponse groupingId
             */

            /**
             * Constructs a new UpdateCompanyIdentityProviderResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyIdentityProviderResponse.
             * @implements IUpdateCompanyIdentityProviderResponse
             * @constructor
             * @param {streem.api.IUpdateCompanyIdentityProviderResponse=} [p] Properties to set
             */
            function UpdateCompanyIdentityProviderResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyIdentityProviderResponse provider.
             * @member {streem.api.Company.IdentityProvider} provider
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @instance
             */
            UpdateCompanyIdentityProviderResponse.prototype.provider = 0;

            /**
             * UpdateCompanyIdentityProviderResponse groupingId.
             * @member {string} groupingId
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @instance
             */
            UpdateCompanyIdentityProviderResponse.prototype.groupingId = "";

            /**
             * Creates a new UpdateCompanyIdentityProviderResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.IUpdateCompanyIdentityProviderResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyIdentityProviderResponse} UpdateCompanyIdentityProviderResponse instance
             */
            UpdateCompanyIdentityProviderResponse.create = function create(properties) {
                return new UpdateCompanyIdentityProviderResponse(properties);
            };

            /**
             * Encodes the specified UpdateCompanyIdentityProviderResponse message. Does not implicitly {@link streem.api.UpdateCompanyIdentityProviderResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.IUpdateCompanyIdentityProviderResponse} m UpdateCompanyIdentityProviderResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyIdentityProviderResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.provider != null && Object.hasOwnProperty.call(m, "provider"))
                    w.uint32(8).int32(m.provider);
                if (m.groupingId != null && Object.hasOwnProperty.call(m, "groupingId"))
                    w.uint32(18).string(m.groupingId);
                return w;
            };

            /**
             * Decodes an UpdateCompanyIdentityProviderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyIdentityProviderResponse} UpdateCompanyIdentityProviderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyIdentityProviderResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyIdentityProviderResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.provider = r.int32();
                            break;
                        }
                    case 2: {
                            m.groupingId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyIdentityProviderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyIdentityProviderResponse} UpdateCompanyIdentityProviderResponse
             */
            UpdateCompanyIdentityProviderResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyIdentityProviderResponse)
                    return d;
                var m = new $root.streem.api.UpdateCompanyIdentityProviderResponse();
                switch (d.provider) {
                default:
                    if (typeof d.provider === "number") {
                        m.provider = d.provider;
                        break;
                    }
                    break;
                case "IDENTITY_PROVIDER_INVALID":
                case 0:
                    m.provider = 0;
                    break;
                case "IDENTITY_PROVIDER_COGNITO":
                case 1:
                    m.provider = 1;
                    break;
                case "IDENTITY_PROVIDER_FUSION_AUTH":
                case 2:
                    m.provider = 2;
                    break;
                }
                if (d.groupingId != null) {
                    m.groupingId = String(d.groupingId);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyIdentityProviderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {streem.api.UpdateCompanyIdentityProviderResponse} m UpdateCompanyIdentityProviderResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyIdentityProviderResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.provider = o.enums === String ? "IDENTITY_PROVIDER_INVALID" : 0;
                    d.groupingId = "";
                }
                if (m.provider != null && m.hasOwnProperty("provider")) {
                    d.provider = o.enums === String ? $root.streem.api.Company.IdentityProvider[m.provider] === undefined ? m.provider : $root.streem.api.Company.IdentityProvider[m.provider] : m.provider;
                }
                if (m.groupingId != null && m.hasOwnProperty("groupingId")) {
                    d.groupingId = m.groupingId;
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyIdentityProviderResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyIdentityProviderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyIdentityProviderResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyIdentityProviderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyIdentityProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyIdentityProviderResponse";
            };

            return UpdateCompanyIdentityProviderResponse;
        })();

        api.GetOrCreateUserRequest = (function() {

            /**
             * Properties of a GetOrCreateUserRequest.
             * @memberof streem.api
             * @interface IGetOrCreateUserRequest
             * @property {string|null} [companyCodeOrSid] GetOrCreateUserRequest companyCodeOrSid
             * @property {string|null} [externalUserId] GetOrCreateUserRequest externalUserId
             * @property {streem.api.User.UserType|null} [markAsUserType] GetOrCreateUserRequest markAsUserType
             * @property {Array.<string>|null} [groups] GetOrCreateUserRequest groups
             */

            /**
             * Constructs a new GetOrCreateUserRequest.
             * @memberof streem.api
             * @classdesc Represents a GetOrCreateUserRequest.
             * @implements IGetOrCreateUserRequest
             * @constructor
             * @param {streem.api.IGetOrCreateUserRequest=} [p] Properties to set
             */
            function GetOrCreateUserRequest(p) {
                this.groups = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetOrCreateUserRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetOrCreateUserRequest
             * @instance
             */
            GetOrCreateUserRequest.prototype.companyCodeOrSid = "";

            /**
             * GetOrCreateUserRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.GetOrCreateUserRequest
             * @instance
             */
            GetOrCreateUserRequest.prototype.externalUserId = "";

            /**
             * GetOrCreateUserRequest markAsUserType.
             * @member {streem.api.User.UserType} markAsUserType
             * @memberof streem.api.GetOrCreateUserRequest
             * @instance
             */
            GetOrCreateUserRequest.prototype.markAsUserType = 0;

            /**
             * GetOrCreateUserRequest groups.
             * @member {Array.<string>} groups
             * @memberof streem.api.GetOrCreateUserRequest
             * @instance
             */
            GetOrCreateUserRequest.prototype.groups = $util.emptyArray;

            /**
             * Creates a new GetOrCreateUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {streem.api.IGetOrCreateUserRequest=} [properties] Properties to set
             * @returns {streem.api.GetOrCreateUserRequest} GetOrCreateUserRequest instance
             */
            GetOrCreateUserRequest.create = function create(properties) {
                return new GetOrCreateUserRequest(properties);
            };

            /**
             * Encodes the specified GetOrCreateUserRequest message. Does not implicitly {@link streem.api.GetOrCreateUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {streem.api.IGetOrCreateUserRequest} m GetOrCreateUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrCreateUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(18).string(m.externalUserId);
                if (m.markAsUserType != null && Object.hasOwnProperty.call(m, "markAsUserType"))
                    w.uint32(24).int32(m.markAsUserType);
                if (m.groups != null && m.groups.length) {
                    for (var i = 0; i < m.groups.length; ++i)
                        w.uint32(34).string(m.groups[i]);
                }
                return w;
            };

            /**
             * Decodes a GetOrCreateUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetOrCreateUserRequest} GetOrCreateUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrCreateUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetOrCreateUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 3: {
                            m.markAsUserType = r.int32();
                            break;
                        }
                    case 4: {
                            if (!(m.groups && m.groups.length))
                                m.groups = [];
                            m.groups.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetOrCreateUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetOrCreateUserRequest} GetOrCreateUserRequest
             */
            GetOrCreateUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetOrCreateUserRequest)
                    return d;
                var m = new $root.streem.api.GetOrCreateUserRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                switch (d.markAsUserType) {
                default:
                    if (typeof d.markAsUserType === "number") {
                        m.markAsUserType = d.markAsUserType;
                        break;
                    }
                    break;
                case "USER_TYPE_UNSPECIFIED":
                case 0:
                    m.markAsUserType = 0;
                    break;
                case "USER_TYPE_LOGIN":
                case 1:
                    m.markAsUserType = 1;
                    break;
                case "USER_TYPE_SDK":
                case 2:
                    m.markAsUserType = 2;
                    break;
                case "USER_TYPE_INVITED":
                case 3:
                    m.markAsUserType = 3;
                    break;
                }
                if (d.groups) {
                    if (!Array.isArray(d.groups))
                        throw TypeError(".streem.api.GetOrCreateUserRequest.groups: array expected");
                    m.groups = [];
                    for (var i = 0; i < d.groups.length; ++i) {
                        m.groups[i] = String(d.groups[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetOrCreateUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {streem.api.GetOrCreateUserRequest} m GetOrCreateUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrCreateUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groups = [];
                }
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.externalUserId = "";
                    d.markAsUserType = o.enums === String ? "USER_TYPE_UNSPECIFIED" : 0;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.markAsUserType != null && m.hasOwnProperty("markAsUserType")) {
                    d.markAsUserType = o.enums === String ? $root.streem.api.User.UserType[m.markAsUserType] === undefined ? m.markAsUserType : $root.streem.api.User.UserType[m.markAsUserType] : m.markAsUserType;
                }
                if (m.groups && m.groups.length) {
                    d.groups = [];
                    for (var j = 0; j < m.groups.length; ++j) {
                        d.groups[j] = m.groups[j];
                    }
                }
                return d;
            };

            /**
             * Converts this GetOrCreateUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetOrCreateUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrCreateUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetOrCreateUserRequest
             * @function getTypeUrl
             * @memberof streem.api.GetOrCreateUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrCreateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetOrCreateUserRequest";
            };

            return GetOrCreateUserRequest;
        })();

        api.GetOrCreateUserResponse = (function() {

            /**
             * Properties of a GetOrCreateUserResponse.
             * @memberof streem.api
             * @interface IGetOrCreateUserResponse
             * @property {streem.api.IExternalUser|null} [externalUser] GetOrCreateUserResponse externalUser
             */

            /**
             * Constructs a new GetOrCreateUserResponse.
             * @memberof streem.api
             * @classdesc Represents a GetOrCreateUserResponse.
             * @implements IGetOrCreateUserResponse
             * @constructor
             * @param {streem.api.IGetOrCreateUserResponse=} [p] Properties to set
             */
            function GetOrCreateUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetOrCreateUserResponse externalUser.
             * @member {streem.api.IExternalUser|null|undefined} externalUser
             * @memberof streem.api.GetOrCreateUserResponse
             * @instance
             */
            GetOrCreateUserResponse.prototype.externalUser = null;

            /**
             * Creates a new GetOrCreateUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {streem.api.IGetOrCreateUserResponse=} [properties] Properties to set
             * @returns {streem.api.GetOrCreateUserResponse} GetOrCreateUserResponse instance
             */
            GetOrCreateUserResponse.create = function create(properties) {
                return new GetOrCreateUserResponse(properties);
            };

            /**
             * Encodes the specified GetOrCreateUserResponse message. Does not implicitly {@link streem.api.GetOrCreateUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {streem.api.IGetOrCreateUserResponse} m GetOrCreateUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrCreateUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalUser != null && Object.hasOwnProperty.call(m, "externalUser"))
                    $root.streem.api.ExternalUser.encode(m.externalUser, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetOrCreateUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetOrCreateUserResponse} GetOrCreateUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrCreateUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetOrCreateUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalUser = $root.streem.api.ExternalUser.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetOrCreateUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetOrCreateUserResponse} GetOrCreateUserResponse
             */
            GetOrCreateUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetOrCreateUserResponse)
                    return d;
                var m = new $root.streem.api.GetOrCreateUserResponse();
                if (d.externalUser != null) {
                    if (typeof d.externalUser !== "object")
                        throw TypeError(".streem.api.GetOrCreateUserResponse.externalUser: object expected");
                    m.externalUser = $root.streem.api.ExternalUser.fromObject(d.externalUser);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetOrCreateUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {streem.api.GetOrCreateUserResponse} m GetOrCreateUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrCreateUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.externalUser = null;
                }
                if (m.externalUser != null && m.hasOwnProperty("externalUser")) {
                    d.externalUser = $root.streem.api.ExternalUser.toObject(m.externalUser, o);
                }
                return d;
            };

            /**
             * Converts this GetOrCreateUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetOrCreateUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrCreateUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetOrCreateUserResponse
             * @function getTypeUrl
             * @memberof streem.api.GetOrCreateUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrCreateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetOrCreateUserResponse";
            };

            return GetOrCreateUserResponse;
        })();

        api.GetUserByExternalUserIdRequest = (function() {

            /**
             * Properties of a GetUserByExternalUserIdRequest.
             * @memberof streem.api
             * @interface IGetUserByExternalUserIdRequest
             * @property {string|null} [companyCodeOrSid] GetUserByExternalUserIdRequest companyCodeOrSid
             * @property {string|null} [externalUserId] GetUserByExternalUserIdRequest externalUserId
             */

            /**
             * Constructs a new GetUserByExternalUserIdRequest.
             * @memberof streem.api
             * @classdesc Represents a GetUserByExternalUserIdRequest.
             * @implements IGetUserByExternalUserIdRequest
             * @constructor
             * @param {streem.api.IGetUserByExternalUserIdRequest=} [p] Properties to set
             */
            function GetUserByExternalUserIdRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserByExternalUserIdRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @instance
             */
            GetUserByExternalUserIdRequest.prototype.companyCodeOrSid = "";

            /**
             * GetUserByExternalUserIdRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @instance
             */
            GetUserByExternalUserIdRequest.prototype.externalUserId = "";

            /**
             * Creates a new GetUserByExternalUserIdRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {streem.api.IGetUserByExternalUserIdRequest=} [properties] Properties to set
             * @returns {streem.api.GetUserByExternalUserIdRequest} GetUserByExternalUserIdRequest instance
             */
            GetUserByExternalUserIdRequest.create = function create(properties) {
                return new GetUserByExternalUserIdRequest(properties);
            };

            /**
             * Encodes the specified GetUserByExternalUserIdRequest message. Does not implicitly {@link streem.api.GetUserByExternalUserIdRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {streem.api.IGetUserByExternalUserIdRequest} m GetUserByExternalUserIdRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserByExternalUserIdRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(18).string(m.externalUserId);
                return w;
            };

            /**
             * Decodes a GetUserByExternalUserIdRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserByExternalUserIdRequest} GetUserByExternalUserIdRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserByExternalUserIdRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserByExternalUserIdRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.externalUserId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserByExternalUserIdRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserByExternalUserIdRequest} GetUserByExternalUserIdRequest
             */
            GetUserByExternalUserIdRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserByExternalUserIdRequest)
                    return d;
                var m = new $root.streem.api.GetUserByExternalUserIdRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserByExternalUserIdRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {streem.api.GetUserByExternalUserIdRequest} m GetUserByExternalUserIdRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserByExternalUserIdRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.externalUserId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                return d;
            };

            /**
             * Converts this GetUserByExternalUserIdRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserByExternalUserIdRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserByExternalUserIdRequest
             * @function getTypeUrl
             * @memberof streem.api.GetUserByExternalUserIdRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserByExternalUserIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserByExternalUserIdRequest";
            };

            return GetUserByExternalUserIdRequest;
        })();

        api.GetUserByExternalUserIdResponse = (function() {

            /**
             * Properties of a GetUserByExternalUserIdResponse.
             * @memberof streem.api
             * @interface IGetUserByExternalUserIdResponse
             * @property {streem.api.IExternalUser|null} [externalUser] GetUserByExternalUserIdResponse externalUser
             * @property {streem.api.IUser|null} [user] GetUserByExternalUserIdResponse user
             */

            /**
             * Constructs a new GetUserByExternalUserIdResponse.
             * @memberof streem.api
             * @classdesc Represents a GetUserByExternalUserIdResponse.
             * @implements IGetUserByExternalUserIdResponse
             * @constructor
             * @param {streem.api.IGetUserByExternalUserIdResponse=} [p] Properties to set
             */
            function GetUserByExternalUserIdResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetUserByExternalUserIdResponse externalUser.
             * @member {streem.api.IExternalUser|null|undefined} externalUser
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @instance
             */
            GetUserByExternalUserIdResponse.prototype.externalUser = null;

            /**
             * GetUserByExternalUserIdResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @instance
             */
            GetUserByExternalUserIdResponse.prototype.user = null;

            /**
             * Creates a new GetUserByExternalUserIdResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {streem.api.IGetUserByExternalUserIdResponse=} [properties] Properties to set
             * @returns {streem.api.GetUserByExternalUserIdResponse} GetUserByExternalUserIdResponse instance
             */
            GetUserByExternalUserIdResponse.create = function create(properties) {
                return new GetUserByExternalUserIdResponse(properties);
            };

            /**
             * Encodes the specified GetUserByExternalUserIdResponse message. Does not implicitly {@link streem.api.GetUserByExternalUserIdResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {streem.api.IGetUserByExternalUserIdResponse} m GetUserByExternalUserIdResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserByExternalUserIdResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalUser != null && Object.hasOwnProperty.call(m, "externalUser"))
                    $root.streem.api.ExternalUser.encode(m.externalUser, w.uint32(10).fork()).ldelim();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetUserByExternalUserIdResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetUserByExternalUserIdResponse} GetUserByExternalUserIdResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserByExternalUserIdResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetUserByExternalUserIdResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalUser = $root.streem.api.ExternalUser.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetUserByExternalUserIdResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetUserByExternalUserIdResponse} GetUserByExternalUserIdResponse
             */
            GetUserByExternalUserIdResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetUserByExternalUserIdResponse)
                    return d;
                var m = new $root.streem.api.GetUserByExternalUserIdResponse();
                if (d.externalUser != null) {
                    if (typeof d.externalUser !== "object")
                        throw TypeError(".streem.api.GetUserByExternalUserIdResponse.externalUser: object expected");
                    m.externalUser = $root.streem.api.ExternalUser.fromObject(d.externalUser);
                }
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.GetUserByExternalUserIdResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetUserByExternalUserIdResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {streem.api.GetUserByExternalUserIdResponse} m GetUserByExternalUserIdResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserByExternalUserIdResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.externalUser = null;
                    d.user = null;
                }
                if (m.externalUser != null && m.hasOwnProperty("externalUser")) {
                    d.externalUser = $root.streem.api.ExternalUser.toObject(m.externalUser, o);
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this GetUserByExternalUserIdResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserByExternalUserIdResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserByExternalUserIdResponse
             * @function getTypeUrl
             * @memberof streem.api.GetUserByExternalUserIdResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserByExternalUserIdResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetUserByExternalUserIdResponse";
            };

            return GetUserByExternalUserIdResponse;
        })();

        api.ChangeExternalUserIdRequest = (function() {

            /**
             * Properties of a ChangeExternalUserIdRequest.
             * @memberof streem.api
             * @interface IChangeExternalUserIdRequest
             * @property {string|null} [companyCodeOrSid] ChangeExternalUserIdRequest companyCodeOrSid
             * @property {string|null} [externalUserId] ChangeExternalUserIdRequest externalUserId
             * @property {string|null} [updateExternalUserId] ChangeExternalUserIdRequest updateExternalUserId
             */

            /**
             * Constructs a new ChangeExternalUserIdRequest.
             * @memberof streem.api
             * @classdesc Represents a ChangeExternalUserIdRequest.
             * @implements IChangeExternalUserIdRequest
             * @constructor
             * @param {streem.api.IChangeExternalUserIdRequest=} [p] Properties to set
             */
            function ChangeExternalUserIdRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ChangeExternalUserIdRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @instance
             */
            ChangeExternalUserIdRequest.prototype.companyCodeOrSid = "";

            /**
             * ChangeExternalUserIdRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @instance
             */
            ChangeExternalUserIdRequest.prototype.externalUserId = "";

            /**
             * ChangeExternalUserIdRequest updateExternalUserId.
             * @member {string} updateExternalUserId
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @instance
             */
            ChangeExternalUserIdRequest.prototype.updateExternalUserId = "";

            /**
             * Creates a new ChangeExternalUserIdRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {streem.api.IChangeExternalUserIdRequest=} [properties] Properties to set
             * @returns {streem.api.ChangeExternalUserIdRequest} ChangeExternalUserIdRequest instance
             */
            ChangeExternalUserIdRequest.create = function create(properties) {
                return new ChangeExternalUserIdRequest(properties);
            };

            /**
             * Encodes the specified ChangeExternalUserIdRequest message. Does not implicitly {@link streem.api.ChangeExternalUserIdRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {streem.api.IChangeExternalUserIdRequest} m ChangeExternalUserIdRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangeExternalUserIdRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(18).string(m.externalUserId);
                if (m.updateExternalUserId != null && Object.hasOwnProperty.call(m, "updateExternalUserId"))
                    w.uint32(26).string(m.updateExternalUserId);
                return w;
            };

            /**
             * Decodes a ChangeExternalUserIdRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ChangeExternalUserIdRequest} ChangeExternalUserIdRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangeExternalUserIdRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ChangeExternalUserIdRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 3: {
                            m.updateExternalUserId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ChangeExternalUserIdRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ChangeExternalUserIdRequest} ChangeExternalUserIdRequest
             */
            ChangeExternalUserIdRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ChangeExternalUserIdRequest)
                    return d;
                var m = new $root.streem.api.ChangeExternalUserIdRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.updateExternalUserId != null) {
                    m.updateExternalUserId = String(d.updateExternalUserId);
                }
                return m;
            };

            /**
             * Creates a plain object from a ChangeExternalUserIdRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {streem.api.ChangeExternalUserIdRequest} m ChangeExternalUserIdRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangeExternalUserIdRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.externalUserId = "";
                    d.updateExternalUserId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.updateExternalUserId != null && m.hasOwnProperty("updateExternalUserId")) {
                    d.updateExternalUserId = m.updateExternalUserId;
                }
                return d;
            };

            /**
             * Converts this ChangeExternalUserIdRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangeExternalUserIdRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChangeExternalUserIdRequest
             * @function getTypeUrl
             * @memberof streem.api.ChangeExternalUserIdRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChangeExternalUserIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ChangeExternalUserIdRequest";
            };

            return ChangeExternalUserIdRequest;
        })();

        api.ChangeExternalUserIdResponse = (function() {

            /**
             * Properties of a ChangeExternalUserIdResponse.
             * @memberof streem.api
             * @interface IChangeExternalUserIdResponse
             * @property {streem.api.IExternalUser|null} [externalUser] ChangeExternalUserIdResponse externalUser
             */

            /**
             * Constructs a new ChangeExternalUserIdResponse.
             * @memberof streem.api
             * @classdesc Represents a ChangeExternalUserIdResponse.
             * @implements IChangeExternalUserIdResponse
             * @constructor
             * @param {streem.api.IChangeExternalUserIdResponse=} [p] Properties to set
             */
            function ChangeExternalUserIdResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ChangeExternalUserIdResponse externalUser.
             * @member {streem.api.IExternalUser|null|undefined} externalUser
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @instance
             */
            ChangeExternalUserIdResponse.prototype.externalUser = null;

            /**
             * Creates a new ChangeExternalUserIdResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {streem.api.IChangeExternalUserIdResponse=} [properties] Properties to set
             * @returns {streem.api.ChangeExternalUserIdResponse} ChangeExternalUserIdResponse instance
             */
            ChangeExternalUserIdResponse.create = function create(properties) {
                return new ChangeExternalUserIdResponse(properties);
            };

            /**
             * Encodes the specified ChangeExternalUserIdResponse message. Does not implicitly {@link streem.api.ChangeExternalUserIdResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {streem.api.IChangeExternalUserIdResponse} m ChangeExternalUserIdResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangeExternalUserIdResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalUser != null && Object.hasOwnProperty.call(m, "externalUser"))
                    $root.streem.api.ExternalUser.encode(m.externalUser, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ChangeExternalUserIdResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ChangeExternalUserIdResponse} ChangeExternalUserIdResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangeExternalUserIdResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ChangeExternalUserIdResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalUser = $root.streem.api.ExternalUser.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ChangeExternalUserIdResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ChangeExternalUserIdResponse} ChangeExternalUserIdResponse
             */
            ChangeExternalUserIdResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ChangeExternalUserIdResponse)
                    return d;
                var m = new $root.streem.api.ChangeExternalUserIdResponse();
                if (d.externalUser != null) {
                    if (typeof d.externalUser !== "object")
                        throw TypeError(".streem.api.ChangeExternalUserIdResponse.externalUser: object expected");
                    m.externalUser = $root.streem.api.ExternalUser.fromObject(d.externalUser);
                }
                return m;
            };

            /**
             * Creates a plain object from a ChangeExternalUserIdResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {streem.api.ChangeExternalUserIdResponse} m ChangeExternalUserIdResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangeExternalUserIdResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.externalUser = null;
                }
                if (m.externalUser != null && m.hasOwnProperty("externalUser")) {
                    d.externalUser = $root.streem.api.ExternalUser.toObject(m.externalUser, o);
                }
                return d;
            };

            /**
             * Converts this ChangeExternalUserIdResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangeExternalUserIdResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChangeExternalUserIdResponse
             * @function getTypeUrl
             * @memberof streem.api.ChangeExternalUserIdResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChangeExternalUserIdResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ChangeExternalUserIdResponse";
            };

            return ChangeExternalUserIdResponse;
        })();

        api.ListCompanyUsersRequest = (function() {

            /**
             * Properties of a ListCompanyUsersRequest.
             * @memberof streem.api
             * @interface IListCompanyUsersRequest
             * @property {string|null} [companyCodeOrSid] ListCompanyUsersRequest companyCodeOrSid
             * @property {number|null} [pageSize] ListCompanyUsersRequest pageSize
             * @property {string|null} [pageToken] ListCompanyUsersRequest pageToken
             * @property {google.protobuf.IStringValue|null} [filter] ListCompanyUsersRequest filter
             * @property {google.protobuf.IStringValue|null} [sort] ListCompanyUsersRequest sort
             * @property {google.protobuf.IBoolValue|null} [active] ListCompanyUsersRequest active
             * @property {Array.<streem.api.User.UserType>|null} [userTypes] ListCompanyUsersRequest userTypes
             * @property {google.protobuf.IBoolValue|null} [deleted] ListCompanyUsersRequest deleted
             * @property {boolean|null} [includeAvailabilityStatus] ListCompanyUsersRequest includeAvailabilityStatus
             */

            /**
             * Constructs a new ListCompanyUsersRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyUsersRequest.
             * @implements IListCompanyUsersRequest
             * @constructor
             * @param {streem.api.IListCompanyUsersRequest=} [p] Properties to set
             */
            function ListCompanyUsersRequest(p) {
                this.userTypes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyUsersRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.companyCodeOrSid = "";

            /**
             * ListCompanyUsersRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.pageSize = 0;

            /**
             * ListCompanyUsersRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.pageToken = "";

            /**
             * ListCompanyUsersRequest filter.
             * @member {google.protobuf.IStringValue|null|undefined} filter
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.filter = null;

            /**
             * ListCompanyUsersRequest sort.
             * @member {google.protobuf.IStringValue|null|undefined} sort
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.sort = null;

            /**
             * ListCompanyUsersRequest active.
             * @member {google.protobuf.IBoolValue|null|undefined} active
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.active = null;

            /**
             * ListCompanyUsersRequest userTypes.
             * @member {Array.<streem.api.User.UserType>} userTypes
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.userTypes = $util.emptyArray;

            /**
             * ListCompanyUsersRequest deleted.
             * @member {google.protobuf.IBoolValue|null|undefined} deleted
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.deleted = null;

            /**
             * ListCompanyUsersRequest includeAvailabilityStatus.
             * @member {boolean} includeAvailabilityStatus
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             */
            ListCompanyUsersRequest.prototype.includeAvailabilityStatus = false;

            /**
             * Creates a new ListCompanyUsersRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {streem.api.IListCompanyUsersRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompanyUsersRequest} ListCompanyUsersRequest instance
             */
            ListCompanyUsersRequest.create = function create(properties) {
                return new ListCompanyUsersRequest(properties);
            };

            /**
             * Encodes the specified ListCompanyUsersRequest message. Does not implicitly {@link streem.api.ListCompanyUsersRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {streem.api.IListCompanyUsersRequest} m ListCompanyUsersRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyUsersRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                    $root.google.protobuf.StringValue.encode(m.filter, w.uint32(34).fork()).ldelim();
                if (m.sort != null && Object.hasOwnProperty.call(m, "sort"))
                    $root.google.protobuf.StringValue.encode(m.sort, w.uint32(42).fork()).ldelim();
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    $root.google.protobuf.BoolValue.encode(m.active, w.uint32(50).fork()).ldelim();
                if (m.userTypes != null && m.userTypes.length) {
                    w.uint32(58).fork();
                    for (var i = 0; i < m.userTypes.length; ++i)
                        w.int32(m.userTypes[i]);
                    w.ldelim();
                }
                if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                    $root.google.protobuf.BoolValue.encode(m.deleted, w.uint32(66).fork()).ldelim();
                if (m.includeAvailabilityStatus != null && Object.hasOwnProperty.call(m, "includeAvailabilityStatus"))
                    w.uint32(72).bool(m.includeAvailabilityStatus);
                return w;
            };

            /**
             * Decodes a ListCompanyUsersRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyUsersRequest} ListCompanyUsersRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyUsersRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyUsersRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 4: {
                            m.filter = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.sort = $root.google.protobuf.StringValue.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.active = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            if (!(m.userTypes && m.userTypes.length))
                                m.userTypes = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.userTypes.push(r.int32());
                            } else
                                m.userTypes.push(r.int32());
                            break;
                        }
                    case 8: {
                            m.deleted = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            m.includeAvailabilityStatus = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyUsersRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyUsersRequest} ListCompanyUsersRequest
             */
            ListCompanyUsersRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyUsersRequest)
                    return d;
                var m = new $root.streem.api.ListCompanyUsersRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.filter != null) {
                    if (typeof d.filter !== "object")
                        throw TypeError(".streem.api.ListCompanyUsersRequest.filter: object expected");
                    m.filter = $root.google.protobuf.StringValue.fromObject(d.filter);
                }
                if (d.sort != null) {
                    if (typeof d.sort !== "object")
                        throw TypeError(".streem.api.ListCompanyUsersRequest.sort: object expected");
                    m.sort = $root.google.protobuf.StringValue.fromObject(d.sort);
                }
                if (d.active != null) {
                    if (typeof d.active !== "object")
                        throw TypeError(".streem.api.ListCompanyUsersRequest.active: object expected");
                    m.active = $root.google.protobuf.BoolValue.fromObject(d.active);
                }
                if (d.userTypes) {
                    if (!Array.isArray(d.userTypes))
                        throw TypeError(".streem.api.ListCompanyUsersRequest.userTypes: array expected");
                    m.userTypes = [];
                    for (var i = 0; i < d.userTypes.length; ++i) {
                        switch (d.userTypes[i]) {
                        default:
                            if (typeof d.userTypes[i] === "number") {
                                m.userTypes[i] = d.userTypes[i];
                                break;
                            }
                        case "USER_TYPE_UNSPECIFIED":
                        case 0:
                            m.userTypes[i] = 0;
                            break;
                        case "USER_TYPE_LOGIN":
                        case 1:
                            m.userTypes[i] = 1;
                            break;
                        case "USER_TYPE_SDK":
                        case 2:
                            m.userTypes[i] = 2;
                            break;
                        case "USER_TYPE_INVITED":
                        case 3:
                            m.userTypes[i] = 3;
                            break;
                        }
                    }
                }
                if (d.deleted != null) {
                    if (typeof d.deleted !== "object")
                        throw TypeError(".streem.api.ListCompanyUsersRequest.deleted: object expected");
                    m.deleted = $root.google.protobuf.BoolValue.fromObject(d.deleted);
                }
                if (d.includeAvailabilityStatus != null) {
                    m.includeAvailabilityStatus = Boolean(d.includeAvailabilityStatus);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyUsersRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {streem.api.ListCompanyUsersRequest} m ListCompanyUsersRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyUsersRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.userTypes = [];
                }
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.filter = null;
                    d.sort = null;
                    d.active = null;
                    d.deleted = null;
                    d.includeAvailabilityStatus = false;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.filter != null && m.hasOwnProperty("filter")) {
                    d.filter = $root.google.protobuf.StringValue.toObject(m.filter, o);
                }
                if (m.sort != null && m.hasOwnProperty("sort")) {
                    d.sort = $root.google.protobuf.StringValue.toObject(m.sort, o);
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = $root.google.protobuf.BoolValue.toObject(m.active, o);
                }
                if (m.userTypes && m.userTypes.length) {
                    d.userTypes = [];
                    for (var j = 0; j < m.userTypes.length; ++j) {
                        d.userTypes[j] = o.enums === String ? $root.streem.api.User.UserType[m.userTypes[j]] === undefined ? m.userTypes[j] : $root.streem.api.User.UserType[m.userTypes[j]] : m.userTypes[j];
                    }
                }
                if (m.deleted != null && m.hasOwnProperty("deleted")) {
                    d.deleted = $root.google.protobuf.BoolValue.toObject(m.deleted, o);
                }
                if (m.includeAvailabilityStatus != null && m.hasOwnProperty("includeAvailabilityStatus")) {
                    d.includeAvailabilityStatus = m.includeAvailabilityStatus;
                }
                return d;
            };

            /**
             * Converts this ListCompanyUsersRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyUsersRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyUsersRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyUsersRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyUsersRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyUsersRequest";
            };

            return ListCompanyUsersRequest;
        })();

        api.ListCompanyUsersResponse = (function() {

            /**
             * Properties of a ListCompanyUsersResponse.
             * @memberof streem.api
             * @interface IListCompanyUsersResponse
             * @property {Array.<streem.api.IUser>|null} [users] ListCompanyUsersResponse users
             * @property {string|null} [nextPageToken] ListCompanyUsersResponse nextPageToken
             * @property {number|null} [totalSize] ListCompanyUsersResponse totalSize
             */

            /**
             * Constructs a new ListCompanyUsersResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyUsersResponse.
             * @implements IListCompanyUsersResponse
             * @constructor
             * @param {streem.api.IListCompanyUsersResponse=} [p] Properties to set
             */
            function ListCompanyUsersResponse(p) {
                this.users = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyUsersResponse users.
             * @member {Array.<streem.api.IUser>} users
             * @memberof streem.api.ListCompanyUsersResponse
             * @instance
             */
            ListCompanyUsersResponse.prototype.users = $util.emptyArray;

            /**
             * ListCompanyUsersResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListCompanyUsersResponse
             * @instance
             */
            ListCompanyUsersResponse.prototype.nextPageToken = "";

            /**
             * ListCompanyUsersResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListCompanyUsersResponse
             * @instance
             */
            ListCompanyUsersResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListCompanyUsersResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {streem.api.IListCompanyUsersResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompanyUsersResponse} ListCompanyUsersResponse instance
             */
            ListCompanyUsersResponse.create = function create(properties) {
                return new ListCompanyUsersResponse(properties);
            };

            /**
             * Encodes the specified ListCompanyUsersResponse message. Does not implicitly {@link streem.api.ListCompanyUsersResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {streem.api.IListCompanyUsersResponse} m ListCompanyUsersResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyUsersResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.users != null && m.users.length) {
                    for (var i = 0; i < m.users.length; ++i)
                        $root.streem.api.User.encode(m.users[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListCompanyUsersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyUsersResponse} ListCompanyUsersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyUsersResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyUsersResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.users && m.users.length))
                                m.users = [];
                            m.users.push($root.streem.api.User.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyUsersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyUsersResponse} ListCompanyUsersResponse
             */
            ListCompanyUsersResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyUsersResponse)
                    return d;
                var m = new $root.streem.api.ListCompanyUsersResponse();
                if (d.users) {
                    if (!Array.isArray(d.users))
                        throw TypeError(".streem.api.ListCompanyUsersResponse.users: array expected");
                    m.users = [];
                    for (var i = 0; i < d.users.length; ++i) {
                        if (typeof d.users[i] !== "object")
                            throw TypeError(".streem.api.ListCompanyUsersResponse.users: object expected");
                        m.users[i] = $root.streem.api.User.fromObject(d.users[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyUsersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {streem.api.ListCompanyUsersResponse} m ListCompanyUsersResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyUsersResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.users = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.users && m.users.length) {
                    d.users = [];
                    for (var j = 0; j < m.users.length; ++j) {
                        d.users[j] = $root.streem.api.User.toObject(m.users[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListCompanyUsersResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyUsersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyUsersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyUsersResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyUsersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyUsersResponse";
            };

            return ListCompanyUsersResponse;
        })();

        api.GetCompanySettingRequest = (function() {

            /**
             * Properties of a GetCompanySettingRequest.
             * @memberof streem.api
             * @interface IGetCompanySettingRequest
             * @property {string|null} [settingName] GetCompanySettingRequest settingName
             * @property {string|null} [companySid] GetCompanySettingRequest companySid
             */

            /**
             * Constructs a new GetCompanySettingRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanySettingRequest.
             * @implements IGetCompanySettingRequest
             * @constructor
             * @param {streem.api.IGetCompanySettingRequest=} [p] Properties to set
             */
            function GetCompanySettingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanySettingRequest settingName.
             * @member {string} settingName
             * @memberof streem.api.GetCompanySettingRequest
             * @instance
             */
            GetCompanySettingRequest.prototype.settingName = "";

            /**
             * GetCompanySettingRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.GetCompanySettingRequest
             * @instance
             */
            GetCompanySettingRequest.prototype.companySid = "";

            /**
             * Creates a new GetCompanySettingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {streem.api.IGetCompanySettingRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanySettingRequest} GetCompanySettingRequest instance
             */
            GetCompanySettingRequest.create = function create(properties) {
                return new GetCompanySettingRequest(properties);
            };

            /**
             * Encodes the specified GetCompanySettingRequest message. Does not implicitly {@link streem.api.GetCompanySettingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {streem.api.IGetCompanySettingRequest} m GetCompanySettingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanySettingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settingName != null && Object.hasOwnProperty.call(m, "settingName"))
                    w.uint32(10).string(m.settingName);
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(18).string(m.companySid);
                return w;
            };

            /**
             * Decodes a GetCompanySettingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanySettingRequest} GetCompanySettingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanySettingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanySettingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.settingName = r.string();
                            break;
                        }
                    case 2: {
                            m.companySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanySettingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanySettingRequest} GetCompanySettingRequest
             */
            GetCompanySettingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanySettingRequest)
                    return d;
                var m = new $root.streem.api.GetCompanySettingRequest();
                if (d.settingName != null) {
                    m.settingName = String(d.settingName);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanySettingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {streem.api.GetCompanySettingRequest} m GetCompanySettingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanySettingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.settingName = "";
                    d.companySid = "";
                }
                if (m.settingName != null && m.hasOwnProperty("settingName")) {
                    d.settingName = m.settingName;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                return d;
            };

            /**
             * Converts this GetCompanySettingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanySettingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanySettingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanySettingRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanySettingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanySettingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanySettingRequest";
            };

            return GetCompanySettingRequest;
        })();

        api.GetCompanySettingResponse = (function() {

            /**
             * Properties of a GetCompanySettingResponse.
             * @memberof streem.api
             * @interface IGetCompanySettingResponse
             * @property {streem.api.ISetting|null} [setting] GetCompanySettingResponse setting
             */

            /**
             * Constructs a new GetCompanySettingResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanySettingResponse.
             * @implements IGetCompanySettingResponse
             * @constructor
             * @param {streem.api.IGetCompanySettingResponse=} [p] Properties to set
             */
            function GetCompanySettingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanySettingResponse setting.
             * @member {streem.api.ISetting|null|undefined} setting
             * @memberof streem.api.GetCompanySettingResponse
             * @instance
             */
            GetCompanySettingResponse.prototype.setting = null;

            /**
             * Creates a new GetCompanySettingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {streem.api.IGetCompanySettingResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanySettingResponse} GetCompanySettingResponse instance
             */
            GetCompanySettingResponse.create = function create(properties) {
                return new GetCompanySettingResponse(properties);
            };

            /**
             * Encodes the specified GetCompanySettingResponse message. Does not implicitly {@link streem.api.GetCompanySettingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {streem.api.IGetCompanySettingResponse} m GetCompanySettingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanySettingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                    $root.streem.api.Setting.encode(m.setting, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanySettingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanySettingResponse} GetCompanySettingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanySettingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanySettingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.setting = $root.streem.api.Setting.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanySettingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanySettingResponse} GetCompanySettingResponse
             */
            GetCompanySettingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanySettingResponse)
                    return d;
                var m = new $root.streem.api.GetCompanySettingResponse();
                if (d.setting != null) {
                    if (typeof d.setting !== "object")
                        throw TypeError(".streem.api.GetCompanySettingResponse.setting: object expected");
                    m.setting = $root.streem.api.Setting.fromObject(d.setting);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanySettingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {streem.api.GetCompanySettingResponse} m GetCompanySettingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanySettingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.setting = null;
                }
                if (m.setting != null && m.hasOwnProperty("setting")) {
                    d.setting = $root.streem.api.Setting.toObject(m.setting, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanySettingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanySettingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanySettingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanySettingResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanySettingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanySettingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanySettingResponse";
            };

            return GetCompanySettingResponse;
        })();

        api.GetCompanyPublicSettingsRequest = (function() {

            /**
             * Properties of a GetCompanyPublicSettingsRequest.
             * @memberof streem.api
             * @interface IGetCompanyPublicSettingsRequest
             * @property {string|null} [companySid] GetCompanyPublicSettingsRequest companySid
             */

            /**
             * Constructs a new GetCompanyPublicSettingsRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyPublicSettingsRequest.
             * @implements IGetCompanyPublicSettingsRequest
             * @constructor
             * @param {streem.api.IGetCompanyPublicSettingsRequest=} [p] Properties to set
             */
            function GetCompanyPublicSettingsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyPublicSettingsRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @instance
             */
            GetCompanyPublicSettingsRequest.prototype.companySid = "";

            /**
             * Creates a new GetCompanyPublicSettingsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.IGetCompanyPublicSettingsRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyPublicSettingsRequest} GetCompanyPublicSettingsRequest instance
             */
            GetCompanyPublicSettingsRequest.create = function create(properties) {
                return new GetCompanyPublicSettingsRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyPublicSettingsRequest message. Does not implicitly {@link streem.api.GetCompanyPublicSettingsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.IGetCompanyPublicSettingsRequest} m GetCompanyPublicSettingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyPublicSettingsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                return w;
            };

            /**
             * Decodes a GetCompanyPublicSettingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyPublicSettingsRequest} GetCompanyPublicSettingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyPublicSettingsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyPublicSettingsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyPublicSettingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyPublicSettingsRequest} GetCompanyPublicSettingsRequest
             */
            GetCompanyPublicSettingsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyPublicSettingsRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyPublicSettingsRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyPublicSettingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.GetCompanyPublicSettingsRequest} m GetCompanyPublicSettingsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyPublicSettingsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companySid = "";
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                return d;
            };

            /**
             * Converts this GetCompanyPublicSettingsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyPublicSettingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyPublicSettingsRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyPublicSettingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyPublicSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyPublicSettingsRequest";
            };

            return GetCompanyPublicSettingsRequest;
        })();

        api.GetCompanyPublicSettingsResponse = (function() {

            /**
             * Properties of a GetCompanyPublicSettingsResponse.
             * @memberof streem.api
             * @interface IGetCompanyPublicSettingsResponse
             * @property {Array.<streem.api.ISetting>|null} [settings] GetCompanyPublicSettingsResponse settings
             */

            /**
             * Constructs a new GetCompanyPublicSettingsResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyPublicSettingsResponse.
             * @implements IGetCompanyPublicSettingsResponse
             * @constructor
             * @param {streem.api.IGetCompanyPublicSettingsResponse=} [p] Properties to set
             */
            function GetCompanyPublicSettingsResponse(p) {
                this.settings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyPublicSettingsResponse settings.
             * @member {Array.<streem.api.ISetting>} settings
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @instance
             */
            GetCompanyPublicSettingsResponse.prototype.settings = $util.emptyArray;

            /**
             * Creates a new GetCompanyPublicSettingsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.IGetCompanyPublicSettingsResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyPublicSettingsResponse} GetCompanyPublicSettingsResponse instance
             */
            GetCompanyPublicSettingsResponse.create = function create(properties) {
                return new GetCompanyPublicSettingsResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyPublicSettingsResponse message. Does not implicitly {@link streem.api.GetCompanyPublicSettingsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.IGetCompanyPublicSettingsResponse} m GetCompanyPublicSettingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyPublicSettingsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settings != null && m.settings.length) {
                    for (var i = 0; i < m.settings.length; ++i)
                        $root.streem.api.Setting.encode(m.settings[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetCompanyPublicSettingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyPublicSettingsResponse} GetCompanyPublicSettingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyPublicSettingsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyPublicSettingsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.settings && m.settings.length))
                                m.settings = [];
                            m.settings.push($root.streem.api.Setting.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyPublicSettingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyPublicSettingsResponse} GetCompanyPublicSettingsResponse
             */
            GetCompanyPublicSettingsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyPublicSettingsResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyPublicSettingsResponse();
                if (d.settings) {
                    if (!Array.isArray(d.settings))
                        throw TypeError(".streem.api.GetCompanyPublicSettingsResponse.settings: array expected");
                    m.settings = [];
                    for (var i = 0; i < d.settings.length; ++i) {
                        if (typeof d.settings[i] !== "object")
                            throw TypeError(".streem.api.GetCompanyPublicSettingsResponse.settings: object expected");
                        m.settings[i] = $root.streem.api.Setting.fromObject(d.settings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyPublicSettingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.GetCompanyPublicSettingsResponse} m GetCompanyPublicSettingsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyPublicSettingsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.settings = [];
                }
                if (m.settings && m.settings.length) {
                    d.settings = [];
                    for (var j = 0; j < m.settings.length; ++j) {
                        d.settings[j] = $root.streem.api.Setting.toObject(m.settings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetCompanyPublicSettingsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyPublicSettingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyPublicSettingsResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyPublicSettingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyPublicSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyPublicSettingsResponse";
            };

            return GetCompanyPublicSettingsResponse;
        })();

        api.GetSettingDefinitionRequest = (function() {

            /**
             * Properties of a GetSettingDefinitionRequest.
             * @memberof streem.api
             * @interface IGetSettingDefinitionRequest
             * @property {string|null} [settingName] GetSettingDefinitionRequest settingName
             */

            /**
             * Constructs a new GetSettingDefinitionRequest.
             * @memberof streem.api
             * @classdesc Represents a GetSettingDefinitionRequest.
             * @implements IGetSettingDefinitionRequest
             * @constructor
             * @param {streem.api.IGetSettingDefinitionRequest=} [p] Properties to set
             */
            function GetSettingDefinitionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSettingDefinitionRequest settingName.
             * @member {string} settingName
             * @memberof streem.api.GetSettingDefinitionRequest
             * @instance
             */
            GetSettingDefinitionRequest.prototype.settingName = "";

            /**
             * Creates a new GetSettingDefinitionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {streem.api.IGetSettingDefinitionRequest=} [properties] Properties to set
             * @returns {streem.api.GetSettingDefinitionRequest} GetSettingDefinitionRequest instance
             */
            GetSettingDefinitionRequest.create = function create(properties) {
                return new GetSettingDefinitionRequest(properties);
            };

            /**
             * Encodes the specified GetSettingDefinitionRequest message. Does not implicitly {@link streem.api.GetSettingDefinitionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {streem.api.IGetSettingDefinitionRequest} m GetSettingDefinitionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSettingDefinitionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settingName != null && Object.hasOwnProperty.call(m, "settingName"))
                    w.uint32(10).string(m.settingName);
                return w;
            };

            /**
             * Decodes a GetSettingDefinitionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSettingDefinitionRequest} GetSettingDefinitionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSettingDefinitionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSettingDefinitionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.settingName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSettingDefinitionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSettingDefinitionRequest} GetSettingDefinitionRequest
             */
            GetSettingDefinitionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSettingDefinitionRequest)
                    return d;
                var m = new $root.streem.api.GetSettingDefinitionRequest();
                if (d.settingName != null) {
                    m.settingName = String(d.settingName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSettingDefinitionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {streem.api.GetSettingDefinitionRequest} m GetSettingDefinitionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSettingDefinitionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.settingName = "";
                }
                if (m.settingName != null && m.hasOwnProperty("settingName")) {
                    d.settingName = m.settingName;
                }
                return d;
            };

            /**
             * Converts this GetSettingDefinitionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetSettingDefinitionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSettingDefinitionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSettingDefinitionRequest
             * @function getTypeUrl
             * @memberof streem.api.GetSettingDefinitionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSettingDefinitionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSettingDefinitionRequest";
            };

            return GetSettingDefinitionRequest;
        })();

        api.GetSettingDefinitionResponse = (function() {

            /**
             * Properties of a GetSettingDefinitionResponse.
             * @memberof streem.api
             * @interface IGetSettingDefinitionResponse
             * @property {streem.api.ISettingDefinition|null} [settingDefinition] GetSettingDefinitionResponse settingDefinition
             */

            /**
             * Constructs a new GetSettingDefinitionResponse.
             * @memberof streem.api
             * @classdesc Represents a GetSettingDefinitionResponse.
             * @implements IGetSettingDefinitionResponse
             * @constructor
             * @param {streem.api.IGetSettingDefinitionResponse=} [p] Properties to set
             */
            function GetSettingDefinitionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetSettingDefinitionResponse settingDefinition.
             * @member {streem.api.ISettingDefinition|null|undefined} settingDefinition
             * @memberof streem.api.GetSettingDefinitionResponse
             * @instance
             */
            GetSettingDefinitionResponse.prototype.settingDefinition = null;

            /**
             * Creates a new GetSettingDefinitionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {streem.api.IGetSettingDefinitionResponse=} [properties] Properties to set
             * @returns {streem.api.GetSettingDefinitionResponse} GetSettingDefinitionResponse instance
             */
            GetSettingDefinitionResponse.create = function create(properties) {
                return new GetSettingDefinitionResponse(properties);
            };

            /**
             * Encodes the specified GetSettingDefinitionResponse message. Does not implicitly {@link streem.api.GetSettingDefinitionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {streem.api.IGetSettingDefinitionResponse} m GetSettingDefinitionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSettingDefinitionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settingDefinition != null && Object.hasOwnProperty.call(m, "settingDefinition"))
                    $root.streem.api.SettingDefinition.encode(m.settingDefinition, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetSettingDefinitionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetSettingDefinitionResponse} GetSettingDefinitionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSettingDefinitionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetSettingDefinitionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.settingDefinition = $root.streem.api.SettingDefinition.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetSettingDefinitionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetSettingDefinitionResponse} GetSettingDefinitionResponse
             */
            GetSettingDefinitionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetSettingDefinitionResponse)
                    return d;
                var m = new $root.streem.api.GetSettingDefinitionResponse();
                if (d.settingDefinition != null) {
                    if (typeof d.settingDefinition !== "object")
                        throw TypeError(".streem.api.GetSettingDefinitionResponse.settingDefinition: object expected");
                    m.settingDefinition = $root.streem.api.SettingDefinition.fromObject(d.settingDefinition);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetSettingDefinitionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {streem.api.GetSettingDefinitionResponse} m GetSettingDefinitionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSettingDefinitionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.settingDefinition = null;
                }
                if (m.settingDefinition != null && m.hasOwnProperty("settingDefinition")) {
                    d.settingDefinition = $root.streem.api.SettingDefinition.toObject(m.settingDefinition, o);
                }
                return d;
            };

            /**
             * Converts this GetSettingDefinitionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetSettingDefinitionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSettingDefinitionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSettingDefinitionResponse
             * @function getTypeUrl
             * @memberof streem.api.GetSettingDefinitionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSettingDefinitionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetSettingDefinitionResponse";
            };

            return GetSettingDefinitionResponse;
        })();

        api.ListCompanySettingsRequest = (function() {

            /**
             * Properties of a ListCompanySettingsRequest.
             * @memberof streem.api
             * @interface IListCompanySettingsRequest
             * @property {string|null} [companyCodeOrSid] ListCompanySettingsRequest companyCodeOrSid
             */

            /**
             * Constructs a new ListCompanySettingsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompanySettingsRequest.
             * @implements IListCompanySettingsRequest
             * @constructor
             * @param {streem.api.IListCompanySettingsRequest=} [p] Properties to set
             */
            function ListCompanySettingsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanySettingsRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListCompanySettingsRequest
             * @instance
             */
            ListCompanySettingsRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new ListCompanySettingsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {streem.api.IListCompanySettingsRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompanySettingsRequest} ListCompanySettingsRequest instance
             */
            ListCompanySettingsRequest.create = function create(properties) {
                return new ListCompanySettingsRequest(properties);
            };

            /**
             * Encodes the specified ListCompanySettingsRequest message. Does not implicitly {@link streem.api.ListCompanySettingsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {streem.api.IListCompanySettingsRequest} m ListCompanySettingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanySettingsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a ListCompanySettingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanySettingsRequest} ListCompanySettingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanySettingsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanySettingsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanySettingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanySettingsRequest} ListCompanySettingsRequest
             */
            ListCompanySettingsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanySettingsRequest)
                    return d;
                var m = new $root.streem.api.ListCompanySettingsRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanySettingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {streem.api.ListCompanySettingsRequest} m ListCompanySettingsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanySettingsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this ListCompanySettingsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanySettingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanySettingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanySettingsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompanySettingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanySettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanySettingsRequest";
            };

            return ListCompanySettingsRequest;
        })();

        api.ListCompanySettingsResponse = (function() {

            /**
             * Properties of a ListCompanySettingsResponse.
             * @memberof streem.api
             * @interface IListCompanySettingsResponse
             * @property {Array.<streem.api.ISetting>|null} [settings] ListCompanySettingsResponse settings
             */

            /**
             * Constructs a new ListCompanySettingsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompanySettingsResponse.
             * @implements IListCompanySettingsResponse
             * @constructor
             * @param {streem.api.IListCompanySettingsResponse=} [p] Properties to set
             */
            function ListCompanySettingsResponse(p) {
                this.settings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanySettingsResponse settings.
             * @member {Array.<streem.api.ISetting>} settings
             * @memberof streem.api.ListCompanySettingsResponse
             * @instance
             */
            ListCompanySettingsResponse.prototype.settings = $util.emptyArray;

            /**
             * Creates a new ListCompanySettingsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {streem.api.IListCompanySettingsResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompanySettingsResponse} ListCompanySettingsResponse instance
             */
            ListCompanySettingsResponse.create = function create(properties) {
                return new ListCompanySettingsResponse(properties);
            };

            /**
             * Encodes the specified ListCompanySettingsResponse message. Does not implicitly {@link streem.api.ListCompanySettingsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {streem.api.IListCompanySettingsResponse} m ListCompanySettingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanySettingsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settings != null && m.settings.length) {
                    for (var i = 0; i < m.settings.length; ++i)
                        $root.streem.api.Setting.encode(m.settings[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListCompanySettingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanySettingsResponse} ListCompanySettingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanySettingsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanySettingsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.settings && m.settings.length))
                                m.settings = [];
                            m.settings.push($root.streem.api.Setting.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanySettingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanySettingsResponse} ListCompanySettingsResponse
             */
            ListCompanySettingsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanySettingsResponse)
                    return d;
                var m = new $root.streem.api.ListCompanySettingsResponse();
                if (d.settings) {
                    if (!Array.isArray(d.settings))
                        throw TypeError(".streem.api.ListCompanySettingsResponse.settings: array expected");
                    m.settings = [];
                    for (var i = 0; i < d.settings.length; ++i) {
                        if (typeof d.settings[i] !== "object")
                            throw TypeError(".streem.api.ListCompanySettingsResponse.settings: object expected");
                        m.settings[i] = $root.streem.api.Setting.fromObject(d.settings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanySettingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {streem.api.ListCompanySettingsResponse} m ListCompanySettingsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanySettingsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.settings = [];
                }
                if (m.settings && m.settings.length) {
                    d.settings = [];
                    for (var j = 0; j < m.settings.length; ++j) {
                        d.settings[j] = $root.streem.api.Setting.toObject(m.settings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListCompanySettingsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanySettingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanySettingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanySettingsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompanySettingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanySettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanySettingsResponse";
            };

            return ListCompanySettingsResponse;
        })();

        api.ListCompanyPublicSettingsRequest = (function() {

            /**
             * Properties of a ListCompanyPublicSettingsRequest.
             * @memberof streem.api
             * @interface IListCompanyPublicSettingsRequest
             * @property {string|null} [companyCodeOrSid] ListCompanyPublicSettingsRequest companyCodeOrSid
             */

            /**
             * Constructs a new ListCompanyPublicSettingsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyPublicSettingsRequest.
             * @implements IListCompanyPublicSettingsRequest
             * @constructor
             * @param {streem.api.IListCompanyPublicSettingsRequest=} [p] Properties to set
             */
            function ListCompanyPublicSettingsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyPublicSettingsRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @instance
             */
            ListCompanyPublicSettingsRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new ListCompanyPublicSettingsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.IListCompanyPublicSettingsRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompanyPublicSettingsRequest} ListCompanyPublicSettingsRequest instance
             */
            ListCompanyPublicSettingsRequest.create = function create(properties) {
                return new ListCompanyPublicSettingsRequest(properties);
            };

            /**
             * Encodes the specified ListCompanyPublicSettingsRequest message. Does not implicitly {@link streem.api.ListCompanyPublicSettingsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.IListCompanyPublicSettingsRequest} m ListCompanyPublicSettingsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyPublicSettingsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a ListCompanyPublicSettingsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyPublicSettingsRequest} ListCompanyPublicSettingsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyPublicSettingsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyPublicSettingsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyPublicSettingsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyPublicSettingsRequest} ListCompanyPublicSettingsRequest
             */
            ListCompanyPublicSettingsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyPublicSettingsRequest)
                    return d;
                var m = new $root.streem.api.ListCompanyPublicSettingsRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyPublicSettingsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {streem.api.ListCompanyPublicSettingsRequest} m ListCompanyPublicSettingsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyPublicSettingsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this ListCompanyPublicSettingsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyPublicSettingsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyPublicSettingsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyPublicSettingsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyPublicSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyPublicSettingsRequest";
            };

            return ListCompanyPublicSettingsRequest;
        })();

        api.ListCompanyPublicSettingsResponse = (function() {

            /**
             * Properties of a ListCompanyPublicSettingsResponse.
             * @memberof streem.api
             * @interface IListCompanyPublicSettingsResponse
             * @property {Array.<streem.api.ISetting>|null} [settings] ListCompanyPublicSettingsResponse settings
             */

            /**
             * Constructs a new ListCompanyPublicSettingsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyPublicSettingsResponse.
             * @implements IListCompanyPublicSettingsResponse
             * @constructor
             * @param {streem.api.IListCompanyPublicSettingsResponse=} [p] Properties to set
             */
            function ListCompanyPublicSettingsResponse(p) {
                this.settings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyPublicSettingsResponse settings.
             * @member {Array.<streem.api.ISetting>} settings
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @instance
             */
            ListCompanyPublicSettingsResponse.prototype.settings = $util.emptyArray;

            /**
             * Creates a new ListCompanyPublicSettingsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.IListCompanyPublicSettingsResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompanyPublicSettingsResponse} ListCompanyPublicSettingsResponse instance
             */
            ListCompanyPublicSettingsResponse.create = function create(properties) {
                return new ListCompanyPublicSettingsResponse(properties);
            };

            /**
             * Encodes the specified ListCompanyPublicSettingsResponse message. Does not implicitly {@link streem.api.ListCompanyPublicSettingsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.IListCompanyPublicSettingsResponse} m ListCompanyPublicSettingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyPublicSettingsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.settings != null && m.settings.length) {
                    for (var i = 0; i < m.settings.length; ++i)
                        $root.streem.api.Setting.encode(m.settings[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListCompanyPublicSettingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyPublicSettingsResponse} ListCompanyPublicSettingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyPublicSettingsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyPublicSettingsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.settings && m.settings.length))
                                m.settings = [];
                            m.settings.push($root.streem.api.Setting.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyPublicSettingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyPublicSettingsResponse} ListCompanyPublicSettingsResponse
             */
            ListCompanyPublicSettingsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyPublicSettingsResponse)
                    return d;
                var m = new $root.streem.api.ListCompanyPublicSettingsResponse();
                if (d.settings) {
                    if (!Array.isArray(d.settings))
                        throw TypeError(".streem.api.ListCompanyPublicSettingsResponse.settings: array expected");
                    m.settings = [];
                    for (var i = 0; i < d.settings.length; ++i) {
                        if (typeof d.settings[i] !== "object")
                            throw TypeError(".streem.api.ListCompanyPublicSettingsResponse.settings: object expected");
                        m.settings[i] = $root.streem.api.Setting.fromObject(d.settings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyPublicSettingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {streem.api.ListCompanyPublicSettingsResponse} m ListCompanyPublicSettingsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyPublicSettingsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.settings = [];
                }
                if (m.settings && m.settings.length) {
                    d.settings = [];
                    for (var j = 0; j < m.settings.length; ++j) {
                        d.settings[j] = $root.streem.api.Setting.toObject(m.settings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListCompanyPublicSettingsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyPublicSettingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyPublicSettingsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyPublicSettingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyPublicSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyPublicSettingsResponse";
            };

            return ListCompanyPublicSettingsResponse;
        })();

        api.SaveCompanySettingRequest = (function() {

            /**
             * Properties of a SaveCompanySettingRequest.
             * @memberof streem.api
             * @interface ISaveCompanySettingRequest
             * @property {string|null} [companyCodeOrSid] SaveCompanySettingRequest companyCodeOrSid
             * @property {string|null} [settingName] SaveCompanySettingRequest settingName
             * @property {string|null} [value] SaveCompanySettingRequest value
             */

            /**
             * Constructs a new SaveCompanySettingRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanySettingRequest.
             * @implements ISaveCompanySettingRequest
             * @constructor
             * @param {streem.api.ISaveCompanySettingRequest=} [p] Properties to set
             */
            function SaveCompanySettingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanySettingRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.SaveCompanySettingRequest
             * @instance
             */
            SaveCompanySettingRequest.prototype.companyCodeOrSid = "";

            /**
             * SaveCompanySettingRequest settingName.
             * @member {string} settingName
             * @memberof streem.api.SaveCompanySettingRequest
             * @instance
             */
            SaveCompanySettingRequest.prototype.settingName = "";

            /**
             * SaveCompanySettingRequest value.
             * @member {string} value
             * @memberof streem.api.SaveCompanySettingRequest
             * @instance
             */
            SaveCompanySettingRequest.prototype.value = "";

            /**
             * Creates a new SaveCompanySettingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {streem.api.ISaveCompanySettingRequest=} [properties] Properties to set
             * @returns {streem.api.SaveCompanySettingRequest} SaveCompanySettingRequest instance
             */
            SaveCompanySettingRequest.create = function create(properties) {
                return new SaveCompanySettingRequest(properties);
            };

            /**
             * Encodes the specified SaveCompanySettingRequest message. Does not implicitly {@link streem.api.SaveCompanySettingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {streem.api.ISaveCompanySettingRequest} m SaveCompanySettingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanySettingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.settingName != null && Object.hasOwnProperty.call(m, "settingName"))
                    w.uint32(18).string(m.settingName);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(26).string(m.value);
                return w;
            };

            /**
             * Decodes a SaveCompanySettingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanySettingRequest} SaveCompanySettingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanySettingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanySettingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.settingName = r.string();
                            break;
                        }
                    case 3: {
                            m.value = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanySettingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanySettingRequest} SaveCompanySettingRequest
             */
            SaveCompanySettingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanySettingRequest)
                    return d;
                var m = new $root.streem.api.SaveCompanySettingRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.settingName != null) {
                    m.settingName = String(d.settingName);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanySettingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {streem.api.SaveCompanySettingRequest} m SaveCompanySettingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanySettingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.settingName = "";
                    d.value = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.settingName != null && m.hasOwnProperty("settingName")) {
                    d.settingName = m.settingName;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this SaveCompanySettingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanySettingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanySettingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanySettingRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanySettingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanySettingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanySettingRequest";
            };

            return SaveCompanySettingRequest;
        })();

        api.SaveCompanySettingResponse = (function() {

            /**
             * Properties of a SaveCompanySettingResponse.
             * @memberof streem.api
             * @interface ISaveCompanySettingResponse
             * @property {streem.api.ISetting|null} [setting] SaveCompanySettingResponse setting
             */

            /**
             * Constructs a new SaveCompanySettingResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanySettingResponse.
             * @implements ISaveCompanySettingResponse
             * @constructor
             * @param {streem.api.ISaveCompanySettingResponse=} [p] Properties to set
             */
            function SaveCompanySettingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanySettingResponse setting.
             * @member {streem.api.ISetting|null|undefined} setting
             * @memberof streem.api.SaveCompanySettingResponse
             * @instance
             */
            SaveCompanySettingResponse.prototype.setting = null;

            /**
             * Creates a new SaveCompanySettingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {streem.api.ISaveCompanySettingResponse=} [properties] Properties to set
             * @returns {streem.api.SaveCompanySettingResponse} SaveCompanySettingResponse instance
             */
            SaveCompanySettingResponse.create = function create(properties) {
                return new SaveCompanySettingResponse(properties);
            };

            /**
             * Encodes the specified SaveCompanySettingResponse message. Does not implicitly {@link streem.api.SaveCompanySettingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {streem.api.ISaveCompanySettingResponse} m SaveCompanySettingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanySettingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                    $root.streem.api.Setting.encode(m.setting, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveCompanySettingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanySettingResponse} SaveCompanySettingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanySettingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanySettingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.setting = $root.streem.api.Setting.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanySettingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanySettingResponse} SaveCompanySettingResponse
             */
            SaveCompanySettingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanySettingResponse)
                    return d;
                var m = new $root.streem.api.SaveCompanySettingResponse();
                if (d.setting != null) {
                    if (typeof d.setting !== "object")
                        throw TypeError(".streem.api.SaveCompanySettingResponse.setting: object expected");
                    m.setting = $root.streem.api.Setting.fromObject(d.setting);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanySettingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {streem.api.SaveCompanySettingResponse} m SaveCompanySettingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanySettingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.setting = null;
                }
                if (m.setting != null && m.hasOwnProperty("setting")) {
                    d.setting = $root.streem.api.Setting.toObject(m.setting, o);
                }
                return d;
            };

            /**
             * Converts this SaveCompanySettingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanySettingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanySettingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanySettingResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanySettingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanySettingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanySettingResponse";
            };

            return SaveCompanySettingResponse;
        })();

        api.DeleteCompanySettingRequest = (function() {

            /**
             * Properties of a DeleteCompanySettingRequest.
             * @memberof streem.api
             * @interface IDeleteCompanySettingRequest
             * @property {string|null} [companyCodeOrSid] DeleteCompanySettingRequest companyCodeOrSid
             * @property {string|null} [settingName] DeleteCompanySettingRequest settingName
             */

            /**
             * Constructs a new DeleteCompanySettingRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanySettingRequest.
             * @implements IDeleteCompanySettingRequest
             * @constructor
             * @param {streem.api.IDeleteCompanySettingRequest=} [p] Properties to set
             */
            function DeleteCompanySettingRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteCompanySettingRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.DeleteCompanySettingRequest
             * @instance
             */
            DeleteCompanySettingRequest.prototype.companyCodeOrSid = "";

            /**
             * DeleteCompanySettingRequest settingName.
             * @member {string} settingName
             * @memberof streem.api.DeleteCompanySettingRequest
             * @instance
             */
            DeleteCompanySettingRequest.prototype.settingName = "";

            /**
             * Creates a new DeleteCompanySettingRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {streem.api.IDeleteCompanySettingRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanySettingRequest} DeleteCompanySettingRequest instance
             */
            DeleteCompanySettingRequest.create = function create(properties) {
                return new DeleteCompanySettingRequest(properties);
            };

            /**
             * Encodes the specified DeleteCompanySettingRequest message. Does not implicitly {@link streem.api.DeleteCompanySettingRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {streem.api.IDeleteCompanySettingRequest} m DeleteCompanySettingRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanySettingRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.settingName != null && Object.hasOwnProperty.call(m, "settingName"))
                    w.uint32(18).string(m.settingName);
                return w;
            };

            /**
             * Decodes a DeleteCompanySettingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanySettingRequest} DeleteCompanySettingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanySettingRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanySettingRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.settingName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanySettingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanySettingRequest} DeleteCompanySettingRequest
             */
            DeleteCompanySettingRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanySettingRequest)
                    return d;
                var m = new $root.streem.api.DeleteCompanySettingRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.settingName != null) {
                    m.settingName = String(d.settingName);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteCompanySettingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {streem.api.DeleteCompanySettingRequest} m DeleteCompanySettingRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanySettingRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.settingName = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.settingName != null && m.hasOwnProperty("settingName")) {
                    d.settingName = m.settingName;
                }
                return d;
            };

            /**
             * Converts this DeleteCompanySettingRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanySettingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanySettingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanySettingRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanySettingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanySettingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanySettingRequest";
            };

            return DeleteCompanySettingRequest;
        })();

        api.DeleteCompanySettingResponse = (function() {

            /**
             * Properties of a DeleteCompanySettingResponse.
             * @memberof streem.api
             * @interface IDeleteCompanySettingResponse
             * @property {streem.api.ISetting|null} [setting] DeleteCompanySettingResponse setting
             */

            /**
             * Constructs a new DeleteCompanySettingResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanySettingResponse.
             * @implements IDeleteCompanySettingResponse
             * @constructor
             * @param {streem.api.IDeleteCompanySettingResponse=} [p] Properties to set
             */
            function DeleteCompanySettingResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteCompanySettingResponse setting.
             * @member {streem.api.ISetting|null|undefined} setting
             * @memberof streem.api.DeleteCompanySettingResponse
             * @instance
             */
            DeleteCompanySettingResponse.prototype.setting = null;

            /**
             * Creates a new DeleteCompanySettingResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {streem.api.IDeleteCompanySettingResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanySettingResponse} DeleteCompanySettingResponse instance
             */
            DeleteCompanySettingResponse.create = function create(properties) {
                return new DeleteCompanySettingResponse(properties);
            };

            /**
             * Encodes the specified DeleteCompanySettingResponse message. Does not implicitly {@link streem.api.DeleteCompanySettingResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {streem.api.IDeleteCompanySettingResponse} m DeleteCompanySettingResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanySettingResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                    $root.streem.api.Setting.encode(m.setting, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a DeleteCompanySettingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanySettingResponse} DeleteCompanySettingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanySettingResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanySettingResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.setting = $root.streem.api.Setting.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanySettingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanySettingResponse} DeleteCompanySettingResponse
             */
            DeleteCompanySettingResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanySettingResponse)
                    return d;
                var m = new $root.streem.api.DeleteCompanySettingResponse();
                if (d.setting != null) {
                    if (typeof d.setting !== "object")
                        throw TypeError(".streem.api.DeleteCompanySettingResponse.setting: object expected");
                    m.setting = $root.streem.api.Setting.fromObject(d.setting);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteCompanySettingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {streem.api.DeleteCompanySettingResponse} m DeleteCompanySettingResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanySettingResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.setting = null;
                }
                if (m.setting != null && m.hasOwnProperty("setting")) {
                    d.setting = $root.streem.api.Setting.toObject(m.setting, o);
                }
                return d;
            };

            /**
             * Converts this DeleteCompanySettingResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanySettingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanySettingResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanySettingResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanySettingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanySettingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanySettingResponse";
            };

            return DeleteCompanySettingResponse;
        })();

        api.ListCompanyTagsRequest = (function() {

            /**
             * Properties of a ListCompanyTagsRequest.
             * @memberof streem.api
             * @interface IListCompanyTagsRequest
             * @property {string|null} [companyCodeOrSid] ListCompanyTagsRequest companyCodeOrSid
             * @property {number|null} [pageSize] ListCompanyTagsRequest pageSize
             * @property {string|null} [pageToken] ListCompanyTagsRequest pageToken
             */

            /**
             * Constructs a new ListCompanyTagsRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyTagsRequest.
             * @implements IListCompanyTagsRequest
             * @constructor
             * @param {streem.api.IListCompanyTagsRequest=} [p] Properties to set
             */
            function ListCompanyTagsRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyTagsRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListCompanyTagsRequest
             * @instance
             */
            ListCompanyTagsRequest.prototype.companyCodeOrSid = "";

            /**
             * ListCompanyTagsRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListCompanyTagsRequest
             * @instance
             */
            ListCompanyTagsRequest.prototype.pageSize = 0;

            /**
             * ListCompanyTagsRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListCompanyTagsRequest
             * @instance
             */
            ListCompanyTagsRequest.prototype.pageToken = "";

            /**
             * Creates a new ListCompanyTagsRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {streem.api.IListCompanyTagsRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompanyTagsRequest} ListCompanyTagsRequest instance
             */
            ListCompanyTagsRequest.create = function create(properties) {
                return new ListCompanyTagsRequest(properties);
            };

            /**
             * Encodes the specified ListCompanyTagsRequest message. Does not implicitly {@link streem.api.ListCompanyTagsRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {streem.api.IListCompanyTagsRequest} m ListCompanyTagsRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyTagsRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListCompanyTagsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyTagsRequest} ListCompanyTagsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyTagsRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyTagsRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyTagsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyTagsRequest} ListCompanyTagsRequest
             */
            ListCompanyTagsRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyTagsRequest)
                    return d;
                var m = new $root.streem.api.ListCompanyTagsRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyTagsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {streem.api.ListCompanyTagsRequest} m ListCompanyTagsRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyTagsRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListCompanyTagsRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyTagsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyTagsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyTagsRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyTagsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyTagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyTagsRequest";
            };

            return ListCompanyTagsRequest;
        })();

        api.ListCompanyTagsResponse = (function() {

            /**
             * Properties of a ListCompanyTagsResponse.
             * @memberof streem.api
             * @interface IListCompanyTagsResponse
             * @property {Array.<streem.api.ITag>|null} [tags] ListCompanyTagsResponse tags
             * @property {string|null} [nextPageToken] ListCompanyTagsResponse nextPageToken
             * @property {number|null} [totalSize] ListCompanyTagsResponse totalSize
             */

            /**
             * Constructs a new ListCompanyTagsResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyTagsResponse.
             * @implements IListCompanyTagsResponse
             * @constructor
             * @param {streem.api.IListCompanyTagsResponse=} [p] Properties to set
             */
            function ListCompanyTagsResponse(p) {
                this.tags = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyTagsResponse tags.
             * @member {Array.<streem.api.ITag>} tags
             * @memberof streem.api.ListCompanyTagsResponse
             * @instance
             */
            ListCompanyTagsResponse.prototype.tags = $util.emptyArray;

            /**
             * ListCompanyTagsResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListCompanyTagsResponse
             * @instance
             */
            ListCompanyTagsResponse.prototype.nextPageToken = "";

            /**
             * ListCompanyTagsResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListCompanyTagsResponse
             * @instance
             */
            ListCompanyTagsResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListCompanyTagsResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {streem.api.IListCompanyTagsResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompanyTagsResponse} ListCompanyTagsResponse instance
             */
            ListCompanyTagsResponse.create = function create(properties) {
                return new ListCompanyTagsResponse(properties);
            };

            /**
             * Encodes the specified ListCompanyTagsResponse message. Does not implicitly {@link streem.api.ListCompanyTagsResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {streem.api.IListCompanyTagsResponse} m ListCompanyTagsResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyTagsResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tags != null && m.tags.length) {
                    for (var i = 0; i < m.tags.length; ++i)
                        $root.streem.api.Tag.encode(m.tags[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListCompanyTagsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyTagsResponse} ListCompanyTagsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyTagsResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyTagsResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.tags && m.tags.length))
                                m.tags = [];
                            m.tags.push($root.streem.api.Tag.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyTagsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyTagsResponse} ListCompanyTagsResponse
             */
            ListCompanyTagsResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyTagsResponse)
                    return d;
                var m = new $root.streem.api.ListCompanyTagsResponse();
                if (d.tags) {
                    if (!Array.isArray(d.tags))
                        throw TypeError(".streem.api.ListCompanyTagsResponse.tags: array expected");
                    m.tags = [];
                    for (var i = 0; i < d.tags.length; ++i) {
                        if (typeof d.tags[i] !== "object")
                            throw TypeError(".streem.api.ListCompanyTagsResponse.tags: object expected");
                        m.tags[i] = $root.streem.api.Tag.fromObject(d.tags[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyTagsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {streem.api.ListCompanyTagsResponse} m ListCompanyTagsResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyTagsResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.tags = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.tags && m.tags.length) {
                    d.tags = [];
                    for (var j = 0; j < m.tags.length; ++j) {
                        d.tags[j] = $root.streem.api.Tag.toObject(m.tags[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListCompanyTagsResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyTagsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyTagsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyTagsResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyTagsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyTagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyTagsResponse";
            };

            return ListCompanyTagsResponse;
        })();

        api.GetCompanyTagRequest = (function() {

            /**
             * Properties of a GetCompanyTagRequest.
             * @memberof streem.api
             * @interface IGetCompanyTagRequest
             * @property {string|null} [companyCodeOrSid] GetCompanyTagRequest companyCodeOrSid
             * @property {string|null} [tagName] GetCompanyTagRequest tagName
             */

            /**
             * Constructs a new GetCompanyTagRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyTagRequest.
             * @implements IGetCompanyTagRequest
             * @constructor
             * @param {streem.api.IGetCompanyTagRequest=} [p] Properties to set
             */
            function GetCompanyTagRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyTagRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetCompanyTagRequest
             * @instance
             */
            GetCompanyTagRequest.prototype.companyCodeOrSid = "";

            /**
             * GetCompanyTagRequest tagName.
             * @member {string} tagName
             * @memberof streem.api.GetCompanyTagRequest
             * @instance
             */
            GetCompanyTagRequest.prototype.tagName = "";

            /**
             * Creates a new GetCompanyTagRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {streem.api.IGetCompanyTagRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyTagRequest} GetCompanyTagRequest instance
             */
            GetCompanyTagRequest.create = function create(properties) {
                return new GetCompanyTagRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyTagRequest message. Does not implicitly {@link streem.api.GetCompanyTagRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {streem.api.IGetCompanyTagRequest} m GetCompanyTagRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyTagRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.tagName != null && Object.hasOwnProperty.call(m, "tagName"))
                    w.uint32(18).string(m.tagName);
                return w;
            };

            /**
             * Decodes a GetCompanyTagRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyTagRequest} GetCompanyTagRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyTagRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyTagRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.tagName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyTagRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyTagRequest} GetCompanyTagRequest
             */
            GetCompanyTagRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyTagRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyTagRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.tagName != null) {
                    m.tagName = String(d.tagName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyTagRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {streem.api.GetCompanyTagRequest} m GetCompanyTagRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyTagRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.tagName = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.tagName != null && m.hasOwnProperty("tagName")) {
                    d.tagName = m.tagName;
                }
                return d;
            };

            /**
             * Converts this GetCompanyTagRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyTagRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyTagRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyTagRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyTagRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyTagRequest";
            };

            return GetCompanyTagRequest;
        })();

        api.GetCompanyTagResponse = (function() {

            /**
             * Properties of a GetCompanyTagResponse.
             * @memberof streem.api
             * @interface IGetCompanyTagResponse
             * @property {streem.api.ITag|null} [tag] GetCompanyTagResponse tag
             */

            /**
             * Constructs a new GetCompanyTagResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyTagResponse.
             * @implements IGetCompanyTagResponse
             * @constructor
             * @param {streem.api.IGetCompanyTagResponse=} [p] Properties to set
             */
            function GetCompanyTagResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyTagResponse tag.
             * @member {streem.api.ITag|null|undefined} tag
             * @memberof streem.api.GetCompanyTagResponse
             * @instance
             */
            GetCompanyTagResponse.prototype.tag = null;

            /**
             * Creates a new GetCompanyTagResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {streem.api.IGetCompanyTagResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyTagResponse} GetCompanyTagResponse instance
             */
            GetCompanyTagResponse.create = function create(properties) {
                return new GetCompanyTagResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyTagResponse message. Does not implicitly {@link streem.api.GetCompanyTagResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {streem.api.IGetCompanyTagResponse} m GetCompanyTagResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyTagResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tag != null && Object.hasOwnProperty.call(m, "tag"))
                    $root.streem.api.Tag.encode(m.tag, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanyTagResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyTagResponse} GetCompanyTagResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyTagResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyTagResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tag = $root.streem.api.Tag.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyTagResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyTagResponse} GetCompanyTagResponse
             */
            GetCompanyTagResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyTagResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyTagResponse();
                if (d.tag != null) {
                    if (typeof d.tag !== "object")
                        throw TypeError(".streem.api.GetCompanyTagResponse.tag: object expected");
                    m.tag = $root.streem.api.Tag.fromObject(d.tag);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyTagResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {streem.api.GetCompanyTagResponse} m GetCompanyTagResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyTagResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tag = null;
                }
                if (m.tag != null && m.hasOwnProperty("tag")) {
                    d.tag = $root.streem.api.Tag.toObject(m.tag, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanyTagResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyTagResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyTagResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyTagResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyTagResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyTagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyTagResponse";
            };

            return GetCompanyTagResponse;
        })();

        api.SaveCompanyTagRequest = (function() {

            /**
             * Properties of a SaveCompanyTagRequest.
             * @memberof streem.api
             * @interface ISaveCompanyTagRequest
             * @property {string|null} [companyCodeOrSid] SaveCompanyTagRequest companyCodeOrSid
             * @property {string|null} [tagName] SaveCompanyTagRequest tagName
             * @property {string|null} [value] SaveCompanyTagRequest value
             */

            /**
             * Constructs a new SaveCompanyTagRequest.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanyTagRequest.
             * @implements ISaveCompanyTagRequest
             * @constructor
             * @param {streem.api.ISaveCompanyTagRequest=} [p] Properties to set
             */
            function SaveCompanyTagRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanyTagRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.SaveCompanyTagRequest
             * @instance
             */
            SaveCompanyTagRequest.prototype.companyCodeOrSid = "";

            /**
             * SaveCompanyTagRequest tagName.
             * @member {string} tagName
             * @memberof streem.api.SaveCompanyTagRequest
             * @instance
             */
            SaveCompanyTagRequest.prototype.tagName = "";

            /**
             * SaveCompanyTagRequest value.
             * @member {string} value
             * @memberof streem.api.SaveCompanyTagRequest
             * @instance
             */
            SaveCompanyTagRequest.prototype.value = "";

            /**
             * Creates a new SaveCompanyTagRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {streem.api.ISaveCompanyTagRequest=} [properties] Properties to set
             * @returns {streem.api.SaveCompanyTagRequest} SaveCompanyTagRequest instance
             */
            SaveCompanyTagRequest.create = function create(properties) {
                return new SaveCompanyTagRequest(properties);
            };

            /**
             * Encodes the specified SaveCompanyTagRequest message. Does not implicitly {@link streem.api.SaveCompanyTagRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {streem.api.ISaveCompanyTagRequest} m SaveCompanyTagRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanyTagRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.tagName != null && Object.hasOwnProperty.call(m, "tagName"))
                    w.uint32(18).string(m.tagName);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(26).string(m.value);
                return w;
            };

            /**
             * Decodes a SaveCompanyTagRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanyTagRequest} SaveCompanyTagRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanyTagRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanyTagRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.tagName = r.string();
                            break;
                        }
                    case 3: {
                            m.value = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanyTagRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanyTagRequest} SaveCompanyTagRequest
             */
            SaveCompanyTagRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanyTagRequest)
                    return d;
                var m = new $root.streem.api.SaveCompanyTagRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.tagName != null) {
                    m.tagName = String(d.tagName);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanyTagRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {streem.api.SaveCompanyTagRequest} m SaveCompanyTagRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanyTagRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.tagName = "";
                    d.value = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.tagName != null && m.hasOwnProperty("tagName")) {
                    d.tagName = m.tagName;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this SaveCompanyTagRequest to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanyTagRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanyTagRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanyTagRequest
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanyTagRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanyTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanyTagRequest";
            };

            return SaveCompanyTagRequest;
        })();

        api.SaveCompanyTagResponse = (function() {

            /**
             * Properties of a SaveCompanyTagResponse.
             * @memberof streem.api
             * @interface ISaveCompanyTagResponse
             * @property {streem.api.ITag|null} [tag] SaveCompanyTagResponse tag
             */

            /**
             * Constructs a new SaveCompanyTagResponse.
             * @memberof streem.api
             * @classdesc Represents a SaveCompanyTagResponse.
             * @implements ISaveCompanyTagResponse
             * @constructor
             * @param {streem.api.ISaveCompanyTagResponse=} [p] Properties to set
             */
            function SaveCompanyTagResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SaveCompanyTagResponse tag.
             * @member {streem.api.ITag|null|undefined} tag
             * @memberof streem.api.SaveCompanyTagResponse
             * @instance
             */
            SaveCompanyTagResponse.prototype.tag = null;

            /**
             * Creates a new SaveCompanyTagResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {streem.api.ISaveCompanyTagResponse=} [properties] Properties to set
             * @returns {streem.api.SaveCompanyTagResponse} SaveCompanyTagResponse instance
             */
            SaveCompanyTagResponse.create = function create(properties) {
                return new SaveCompanyTagResponse(properties);
            };

            /**
             * Encodes the specified SaveCompanyTagResponse message. Does not implicitly {@link streem.api.SaveCompanyTagResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {streem.api.ISaveCompanyTagResponse} m SaveCompanyTagResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveCompanyTagResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.tag != null && Object.hasOwnProperty.call(m, "tag"))
                    $root.streem.api.Tag.encode(m.tag, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a SaveCompanyTagResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.SaveCompanyTagResponse} SaveCompanyTagResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveCompanyTagResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.SaveCompanyTagResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.tag = $root.streem.api.Tag.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SaveCompanyTagResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.SaveCompanyTagResponse} SaveCompanyTagResponse
             */
            SaveCompanyTagResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.SaveCompanyTagResponse)
                    return d;
                var m = new $root.streem.api.SaveCompanyTagResponse();
                if (d.tag != null) {
                    if (typeof d.tag !== "object")
                        throw TypeError(".streem.api.SaveCompanyTagResponse.tag: object expected");
                    m.tag = $root.streem.api.Tag.fromObject(d.tag);
                }
                return m;
            };

            /**
             * Creates a plain object from a SaveCompanyTagResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {streem.api.SaveCompanyTagResponse} m SaveCompanyTagResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveCompanyTagResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tag = null;
                }
                if (m.tag != null && m.hasOwnProperty("tag")) {
                    d.tag = $root.streem.api.Tag.toObject(m.tag, o);
                }
                return d;
            };

            /**
             * Converts this SaveCompanyTagResponse to JSON.
             * @function toJSON
             * @memberof streem.api.SaveCompanyTagResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveCompanyTagResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveCompanyTagResponse
             * @function getTypeUrl
             * @memberof streem.api.SaveCompanyTagResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveCompanyTagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.SaveCompanyTagResponse";
            };

            return SaveCompanyTagResponse;
        })();

        api.DeleteCompanyTagRequest = (function() {

            /**
             * Properties of a DeleteCompanyTagRequest.
             * @memberof streem.api
             * @interface IDeleteCompanyTagRequest
             * @property {string|null} [companyCodeOrSid] DeleteCompanyTagRequest companyCodeOrSid
             * @property {string|null} [tagName] DeleteCompanyTagRequest tagName
             */

            /**
             * Constructs a new DeleteCompanyTagRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanyTagRequest.
             * @implements IDeleteCompanyTagRequest
             * @constructor
             * @param {streem.api.IDeleteCompanyTagRequest=} [p] Properties to set
             */
            function DeleteCompanyTagRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeleteCompanyTagRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.DeleteCompanyTagRequest
             * @instance
             */
            DeleteCompanyTagRequest.prototype.companyCodeOrSid = "";

            /**
             * DeleteCompanyTagRequest tagName.
             * @member {string} tagName
             * @memberof streem.api.DeleteCompanyTagRequest
             * @instance
             */
            DeleteCompanyTagRequest.prototype.tagName = "";

            /**
             * Creates a new DeleteCompanyTagRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {streem.api.IDeleteCompanyTagRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanyTagRequest} DeleteCompanyTagRequest instance
             */
            DeleteCompanyTagRequest.create = function create(properties) {
                return new DeleteCompanyTagRequest(properties);
            };

            /**
             * Encodes the specified DeleteCompanyTagRequest message. Does not implicitly {@link streem.api.DeleteCompanyTagRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {streem.api.IDeleteCompanyTagRequest} m DeleteCompanyTagRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanyTagRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.tagName != null && Object.hasOwnProperty.call(m, "tagName"))
                    w.uint32(18).string(m.tagName);
                return w;
            };

            /**
             * Decodes a DeleteCompanyTagRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanyTagRequest} DeleteCompanyTagRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanyTagRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanyTagRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.tagName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanyTagRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanyTagRequest} DeleteCompanyTagRequest
             */
            DeleteCompanyTagRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanyTagRequest)
                    return d;
                var m = new $root.streem.api.DeleteCompanyTagRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.tagName != null) {
                    m.tagName = String(d.tagName);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeleteCompanyTagRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {streem.api.DeleteCompanyTagRequest} m DeleteCompanyTagRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanyTagRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.tagName = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.tagName != null && m.hasOwnProperty("tagName")) {
                    d.tagName = m.tagName;
                }
                return d;
            };

            /**
             * Converts this DeleteCompanyTagRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanyTagRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanyTagRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanyTagRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanyTagRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanyTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanyTagRequest";
            };

            return DeleteCompanyTagRequest;
        })();

        api.GetCompanyCallLogRequest = (function() {

            /**
             * Properties of a GetCompanyCallLogRequest.
             * @memberof streem.api
             * @interface IGetCompanyCallLogRequest
             * @property {string|null} [companyCodeOrSid] GetCompanyCallLogRequest companyCodeOrSid
             * @property {number|null} [pageSize] GetCompanyCallLogRequest pageSize
             * @property {string|null} [pageToken] GetCompanyCallLogRequest pageToken
             * @property {string|null} [filter] GetCompanyCallLogRequest filter
             * @property {google.protobuf.ITimestamp|null} [startTime] GetCompanyCallLogRequest startTime
             * @property {google.protobuf.ITimestamp|null} [endTime] GetCompanyCallLogRequest endTime
             * @property {Array.<string>|null} [groupNames] GetCompanyCallLogRequest groupNames
             * @property {Array.<string>|null} [callStatuses] GetCompanyCallLogRequest callStatuses
             * @property {boolean|null} [hasRoomNotes] GetCompanyCallLogRequest hasRoomNotes
             */

            /**
             * Constructs a new GetCompanyCallLogRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallLogRequest.
             * @implements IGetCompanyCallLogRequest
             * @constructor
             * @param {streem.api.IGetCompanyCallLogRequest=} [p] Properties to set
             */
            function GetCompanyCallLogRequest(p) {
                this.groupNames = [];
                this.callStatuses = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyCallLogRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.companyCodeOrSid = "";

            /**
             * GetCompanyCallLogRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.pageSize = 0;

            /**
             * GetCompanyCallLogRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.pageToken = "";

            /**
             * GetCompanyCallLogRequest filter.
             * @member {string} filter
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.filter = "";

            /**
             * GetCompanyCallLogRequest startTime.
             * @member {google.protobuf.ITimestamp|null|undefined} startTime
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.startTime = null;

            /**
             * GetCompanyCallLogRequest endTime.
             * @member {google.protobuf.ITimestamp|null|undefined} endTime
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.endTime = null;

            /**
             * GetCompanyCallLogRequest groupNames.
             * @member {Array.<string>} groupNames
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.groupNames = $util.emptyArray;

            /**
             * GetCompanyCallLogRequest callStatuses.
             * @member {Array.<string>} callStatuses
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.callStatuses = $util.emptyArray;

            /**
             * GetCompanyCallLogRequest hasRoomNotes.
             * @member {boolean} hasRoomNotes
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             */
            GetCompanyCallLogRequest.prototype.hasRoomNotes = false;

            /**
             * Creates a new GetCompanyCallLogRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {streem.api.IGetCompanyCallLogRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallLogRequest} GetCompanyCallLogRequest instance
             */
            GetCompanyCallLogRequest.create = function create(properties) {
                return new GetCompanyCallLogRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyCallLogRequest message. Does not implicitly {@link streem.api.GetCompanyCallLogRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {streem.api.IGetCompanyCallLogRequest} m GetCompanyCallLogRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallLogRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(16).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(26).string(m.pageToken);
                if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                    w.uint32(34).string(m.filter);
                if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                    $root.google.protobuf.Timestamp.encode(m.startTime, w.uint32(42).fork()).ldelim();
                if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                    $root.google.protobuf.Timestamp.encode(m.endTime, w.uint32(50).fork()).ldelim();
                if (m.groupNames != null && m.groupNames.length) {
                    for (var i = 0; i < m.groupNames.length; ++i)
                        w.uint32(58).string(m.groupNames[i]);
                }
                if (m.callStatuses != null && m.callStatuses.length) {
                    for (var i = 0; i < m.callStatuses.length; ++i)
                        w.uint32(66).string(m.callStatuses[i]);
                }
                if (m.hasRoomNotes != null && Object.hasOwnProperty.call(m, "hasRoomNotes"))
                    w.uint32(72).bool(m.hasRoomNotes);
                return w;
            };

            /**
             * Decodes a GetCompanyCallLogRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallLogRequest} GetCompanyCallLogRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallLogRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallLogRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 3: {
                            m.pageToken = r.string();
                            break;
                        }
                    case 4: {
                            m.filter = r.string();
                            break;
                        }
                    case 5: {
                            m.startTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.endTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            if (!(m.groupNames && m.groupNames.length))
                                m.groupNames = [];
                            m.groupNames.push(r.string());
                            break;
                        }
                    case 8: {
                            if (!(m.callStatuses && m.callStatuses.length))
                                m.callStatuses = [];
                            m.callStatuses.push(r.string());
                            break;
                        }
                    case 9: {
                            m.hasRoomNotes = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallLogRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallLogRequest} GetCompanyCallLogRequest
             */
            GetCompanyCallLogRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallLogRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyCallLogRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                if (d.filter != null) {
                    m.filter = String(d.filter);
                }
                if (d.startTime != null) {
                    if (typeof d.startTime !== "object")
                        throw TypeError(".streem.api.GetCompanyCallLogRequest.startTime: object expected");
                    m.startTime = $root.google.protobuf.Timestamp.fromObject(d.startTime);
                }
                if (d.endTime != null) {
                    if (typeof d.endTime !== "object")
                        throw TypeError(".streem.api.GetCompanyCallLogRequest.endTime: object expected");
                    m.endTime = $root.google.protobuf.Timestamp.fromObject(d.endTime);
                }
                if (d.groupNames) {
                    if (!Array.isArray(d.groupNames))
                        throw TypeError(".streem.api.GetCompanyCallLogRequest.groupNames: array expected");
                    m.groupNames = [];
                    for (var i = 0; i < d.groupNames.length; ++i) {
                        m.groupNames[i] = String(d.groupNames[i]);
                    }
                }
                if (d.callStatuses) {
                    if (!Array.isArray(d.callStatuses))
                        throw TypeError(".streem.api.GetCompanyCallLogRequest.callStatuses: array expected");
                    m.callStatuses = [];
                    for (var i = 0; i < d.callStatuses.length; ++i) {
                        m.callStatuses[i] = String(d.callStatuses[i]);
                    }
                }
                if (d.hasRoomNotes != null) {
                    m.hasRoomNotes = Boolean(d.hasRoomNotes);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyCallLogRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {streem.api.GetCompanyCallLogRequest} m GetCompanyCallLogRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallLogRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groupNames = [];
                    d.callStatuses = [];
                }
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.pageSize = 0;
                    d.pageToken = "";
                    d.filter = "";
                    d.startTime = null;
                    d.endTime = null;
                    d.hasRoomNotes = false;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                if (m.filter != null && m.hasOwnProperty("filter")) {
                    d.filter = m.filter;
                }
                if (m.startTime != null && m.hasOwnProperty("startTime")) {
                    d.startTime = $root.google.protobuf.Timestamp.toObject(m.startTime, o);
                }
                if (m.endTime != null && m.hasOwnProperty("endTime")) {
                    d.endTime = $root.google.protobuf.Timestamp.toObject(m.endTime, o);
                }
                if (m.groupNames && m.groupNames.length) {
                    d.groupNames = [];
                    for (var j = 0; j < m.groupNames.length; ++j) {
                        d.groupNames[j] = m.groupNames[j];
                    }
                }
                if (m.callStatuses && m.callStatuses.length) {
                    d.callStatuses = [];
                    for (var j = 0; j < m.callStatuses.length; ++j) {
                        d.callStatuses[j] = m.callStatuses[j];
                    }
                }
                if (m.hasRoomNotes != null && m.hasOwnProperty("hasRoomNotes")) {
                    d.hasRoomNotes = m.hasRoomNotes;
                }
                return d;
            };

            /**
             * Converts this GetCompanyCallLogRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallLogRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallLogRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallLogRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallLogRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallLogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallLogRequest";
            };

            return GetCompanyCallLogRequest;
        })();

        api.GetCompanyCallLogResponse = (function() {

            /**
             * Properties of a GetCompanyCallLogResponse.
             * @memberof streem.api
             * @interface IGetCompanyCallLogResponse
             * @property {Array.<streem.api.ICallLogEntry>|null} [callLogEntries] GetCompanyCallLogResponse callLogEntries
             * @property {string|null} [nextPageToken] GetCompanyCallLogResponse nextPageToken
             * @property {number|null} [totalSize] GetCompanyCallLogResponse totalSize
             */

            /**
             * Constructs a new GetCompanyCallLogResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallLogResponse.
             * @implements IGetCompanyCallLogResponse
             * @constructor
             * @param {streem.api.IGetCompanyCallLogResponse=} [p] Properties to set
             */
            function GetCompanyCallLogResponse(p) {
                this.callLogEntries = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyCallLogResponse callLogEntries.
             * @member {Array.<streem.api.ICallLogEntry>} callLogEntries
             * @memberof streem.api.GetCompanyCallLogResponse
             * @instance
             */
            GetCompanyCallLogResponse.prototype.callLogEntries = $util.emptyArray;

            /**
             * GetCompanyCallLogResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.GetCompanyCallLogResponse
             * @instance
             */
            GetCompanyCallLogResponse.prototype.nextPageToken = "";

            /**
             * GetCompanyCallLogResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.GetCompanyCallLogResponse
             * @instance
             */
            GetCompanyCallLogResponse.prototype.totalSize = 0;

            /**
             * Creates a new GetCompanyCallLogResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {streem.api.IGetCompanyCallLogResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallLogResponse} GetCompanyCallLogResponse instance
             */
            GetCompanyCallLogResponse.create = function create(properties) {
                return new GetCompanyCallLogResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyCallLogResponse message. Does not implicitly {@link streem.api.GetCompanyCallLogResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {streem.api.IGetCompanyCallLogResponse} m GetCompanyCallLogResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallLogResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callLogEntries != null && m.callLogEntries.length) {
                    for (var i = 0; i < m.callLogEntries.length; ++i)
                        $root.streem.api.CallLogEntry.encode(m.callLogEntries[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a GetCompanyCallLogResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallLogResponse} GetCompanyCallLogResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallLogResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallLogResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.callLogEntries && m.callLogEntries.length))
                                m.callLogEntries = [];
                            m.callLogEntries.push($root.streem.api.CallLogEntry.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallLogResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallLogResponse} GetCompanyCallLogResponse
             */
            GetCompanyCallLogResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallLogResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyCallLogResponse();
                if (d.callLogEntries) {
                    if (!Array.isArray(d.callLogEntries))
                        throw TypeError(".streem.api.GetCompanyCallLogResponse.callLogEntries: array expected");
                    m.callLogEntries = [];
                    for (var i = 0; i < d.callLogEntries.length; ++i) {
                        if (typeof d.callLogEntries[i] !== "object")
                            throw TypeError(".streem.api.GetCompanyCallLogResponse.callLogEntries: object expected");
                        m.callLogEntries[i] = $root.streem.api.CallLogEntry.fromObject(d.callLogEntries[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyCallLogResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {streem.api.GetCompanyCallLogResponse} m GetCompanyCallLogResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallLogResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.callLogEntries = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.callLogEntries && m.callLogEntries.length) {
                    d.callLogEntries = [];
                    for (var j = 0; j < m.callLogEntries.length; ++j) {
                        d.callLogEntries[j] = $root.streem.api.CallLogEntry.toObject(m.callLogEntries[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this GetCompanyCallLogResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallLogResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallLogResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallLogResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallLogResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallLogResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallLogResponse";
            };

            return GetCompanyCallLogResponse;
        })();

        api.CreateInvitationRequest = (function() {

            /**
             * Properties of a CreateInvitationRequest.
             * @memberof streem.api
             * @interface ICreateInvitationRequest
             * @property {string|null} [companyCodeOrSid] CreateInvitationRequest companyCodeOrSid
             * @property {string|null} [fromUserSid] CreateInvitationRequest fromUserSid
             * @property {string|null} [toUserName] CreateInvitationRequest toUserName
             * @property {string|null} [toUserExternalUserId] CreateInvitationRequest toUserExternalUserId
             * @property {string|null} [referenceId] CreateInvitationRequest referenceId
             * @property {string|null} [integrationId] CreateInvitationRequest integrationId
             */

            /**
             * Constructs a new CreateInvitationRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateInvitationRequest.
             * @implements ICreateInvitationRequest
             * @constructor
             * @param {streem.api.ICreateInvitationRequest=} [p] Properties to set
             */
            function CreateInvitationRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateInvitationRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateInvitationRequest fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.fromUserSid = "";

            /**
             * CreateInvitationRequest toUserName.
             * @member {string} toUserName
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.toUserName = "";

            /**
             * CreateInvitationRequest toUserExternalUserId.
             * @member {string} toUserExternalUserId
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.toUserExternalUserId = "";

            /**
             * CreateInvitationRequest referenceId.
             * @member {string} referenceId
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.referenceId = "";

            /**
             * CreateInvitationRequest integrationId.
             * @member {string} integrationId
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             */
            CreateInvitationRequest.prototype.integrationId = "";

            /**
             * Creates a new CreateInvitationRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {streem.api.ICreateInvitationRequest=} [properties] Properties to set
             * @returns {streem.api.CreateInvitationRequest} CreateInvitationRequest instance
             */
            CreateInvitationRequest.create = function create(properties) {
                return new CreateInvitationRequest(properties);
            };

            /**
             * Encodes the specified CreateInvitationRequest message. Does not implicitly {@link streem.api.CreateInvitationRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {streem.api.ICreateInvitationRequest} m CreateInvitationRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInvitationRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(18).string(m.fromUserSid);
                if (m.toUserName != null && Object.hasOwnProperty.call(m, "toUserName"))
                    w.uint32(26).string(m.toUserName);
                if (m.toUserExternalUserId != null && Object.hasOwnProperty.call(m, "toUserExternalUserId"))
                    w.uint32(34).string(m.toUserExternalUserId);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(42).string(m.referenceId);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(50).string(m.integrationId);
                return w;
            };

            /**
             * Decodes a CreateInvitationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateInvitationRequest} CreateInvitationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInvitationRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateInvitationRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 3: {
                            m.toUserName = r.string();
                            break;
                        }
                    case 4: {
                            m.toUserExternalUserId = r.string();
                            break;
                        }
                    case 5: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 6: {
                            m.integrationId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateInvitationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateInvitationRequest} CreateInvitationRequest
             */
            CreateInvitationRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateInvitationRequest)
                    return d;
                var m = new $root.streem.api.CreateInvitationRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.toUserName != null) {
                    m.toUserName = String(d.toUserName);
                }
                if (d.toUserExternalUserId != null) {
                    m.toUserExternalUserId = String(d.toUserExternalUserId);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateInvitationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {streem.api.CreateInvitationRequest} m CreateInvitationRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateInvitationRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.fromUserSid = "";
                    d.toUserName = "";
                    d.toUserExternalUserId = "";
                    d.referenceId = "";
                    d.integrationId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.toUserName != null && m.hasOwnProperty("toUserName")) {
                    d.toUserName = m.toUserName;
                }
                if (m.toUserExternalUserId != null && m.hasOwnProperty("toUserExternalUserId")) {
                    d.toUserExternalUserId = m.toUserExternalUserId;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                return d;
            };

            /**
             * Converts this CreateInvitationRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateInvitationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateInvitationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateInvitationRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateInvitationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateInvitationRequest";
            };

            return CreateInvitationRequest;
        })();

        api.CreateInvitationResponse = (function() {

            /**
             * Properties of a CreateInvitationResponse.
             * @memberof streem.api
             * @interface ICreateInvitationResponse
             * @property {streem.api.IInvitation|null} [invitation] CreateInvitationResponse invitation
             */

            /**
             * Constructs a new CreateInvitationResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateInvitationResponse.
             * @implements ICreateInvitationResponse
             * @constructor
             * @param {streem.api.ICreateInvitationResponse=} [p] Properties to set
             */
            function CreateInvitationResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateInvitationResponse invitation.
             * @member {streem.api.IInvitation|null|undefined} invitation
             * @memberof streem.api.CreateInvitationResponse
             * @instance
             */
            CreateInvitationResponse.prototype.invitation = null;

            /**
             * Creates a new CreateInvitationResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {streem.api.ICreateInvitationResponse=} [properties] Properties to set
             * @returns {streem.api.CreateInvitationResponse} CreateInvitationResponse instance
             */
            CreateInvitationResponse.create = function create(properties) {
                return new CreateInvitationResponse(properties);
            };

            /**
             * Encodes the specified CreateInvitationResponse message. Does not implicitly {@link streem.api.CreateInvitationResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {streem.api.ICreateInvitationResponse} m CreateInvitationResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateInvitationResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.invitation != null && Object.hasOwnProperty.call(m, "invitation"))
                    $root.streem.api.Invitation.encode(m.invitation, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateInvitationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateInvitationResponse} CreateInvitationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateInvitationResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateInvitationResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.invitation = $root.streem.api.Invitation.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateInvitationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateInvitationResponse} CreateInvitationResponse
             */
            CreateInvitationResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateInvitationResponse)
                    return d;
                var m = new $root.streem.api.CreateInvitationResponse();
                if (d.invitation != null) {
                    if (typeof d.invitation !== "object")
                        throw TypeError(".streem.api.CreateInvitationResponse.invitation: object expected");
                    m.invitation = $root.streem.api.Invitation.fromObject(d.invitation);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateInvitationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {streem.api.CreateInvitationResponse} m CreateInvitationResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateInvitationResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.invitation = null;
                }
                if (m.invitation != null && m.hasOwnProperty("invitation")) {
                    d.invitation = $root.streem.api.Invitation.toObject(m.invitation, o);
                }
                return d;
            };

            /**
             * Converts this CreateInvitationResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateInvitationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateInvitationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateInvitationResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateInvitationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateInvitationResponse";
            };

            return CreateInvitationResponse;
        })();

        api.CreateCompanyAssetRequest = (function() {

            /**
             * Properties of a CreateCompanyAssetRequest.
             * @memberof streem.api
             * @interface ICreateCompanyAssetRequest
             * @property {streem.api.CompanyUploadType|null} [type] CreateCompanyAssetRequest type
             * @property {string|null} [companyCodeOrSid] CreateCompanyAssetRequest companyCodeOrSid
             * @property {google.api.IHttpBody|null} [httpBody] CreateCompanyAssetRequest httpBody
             * @property {string|null} [filename] CreateCompanyAssetRequest filename
             */

            /**
             * Constructs a new CreateCompanyAssetRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyAssetRequest.
             * @implements ICreateCompanyAssetRequest
             * @constructor
             * @param {streem.api.ICreateCompanyAssetRequest=} [p] Properties to set
             */
            function CreateCompanyAssetRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyAssetRequest type.
             * @member {streem.api.CompanyUploadType} type
             * @memberof streem.api.CreateCompanyAssetRequest
             * @instance
             */
            CreateCompanyAssetRequest.prototype.type = 0;

            /**
             * CreateCompanyAssetRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateCompanyAssetRequest
             * @instance
             */
            CreateCompanyAssetRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateCompanyAssetRequest httpBody.
             * @member {google.api.IHttpBody|null|undefined} httpBody
             * @memberof streem.api.CreateCompanyAssetRequest
             * @instance
             */
            CreateCompanyAssetRequest.prototype.httpBody = null;

            /**
             * CreateCompanyAssetRequest filename.
             * @member {string} filename
             * @memberof streem.api.CreateCompanyAssetRequest
             * @instance
             */
            CreateCompanyAssetRequest.prototype.filename = "";

            /**
             * Creates a new CreateCompanyAssetRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {streem.api.ICreateCompanyAssetRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyAssetRequest} CreateCompanyAssetRequest instance
             */
            CreateCompanyAssetRequest.create = function create(properties) {
                return new CreateCompanyAssetRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyAssetRequest message. Does not implicitly {@link streem.api.CreateCompanyAssetRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {streem.api.ICreateCompanyAssetRequest} m CreateCompanyAssetRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyAssetRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(8).int32(m.type);
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(18).string(m.companyCodeOrSid);
                if (m.httpBody != null && Object.hasOwnProperty.call(m, "httpBody"))
                    $root.google.api.HttpBody.encode(m.httpBody, w.uint32(26).fork()).ldelim();
                if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                    w.uint32(34).string(m.filename);
                return w;
            };

            /**
             * Decodes a CreateCompanyAssetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyAssetRequest} CreateCompanyAssetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyAssetRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyAssetRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.type = r.int32();
                            break;
                        }
                    case 2: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 3: {
                            m.httpBody = $root.google.api.HttpBody.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.filename = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyAssetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyAssetRequest} CreateCompanyAssetRequest
             */
            CreateCompanyAssetRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyAssetRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyAssetRequest();
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "COMPANY_UPLOAD_TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "COMPANY_UPLOAD_TYPE_LOGO":
                case 1:
                    m.type = 1;
                    break;
                case "COMPANY_UPLOAD_TYPE_WATERMARK":
                case 2:
                    m.type = 2;
                    break;
                case "COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG":
                case 3:
                    m.type = 3;
                    break;
                case "COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO":
                case 4:
                    m.type = 4;
                    break;
                }
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.httpBody != null) {
                    if (typeof d.httpBody !== "object")
                        throw TypeError(".streem.api.CreateCompanyAssetRequest.httpBody: object expected");
                    m.httpBody = $root.google.api.HttpBody.fromObject(d.httpBody);
                }
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyAssetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {streem.api.CreateCompanyAssetRequest} m CreateCompanyAssetRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyAssetRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "COMPANY_UPLOAD_TYPE_INVALID" : 0;
                    d.companyCodeOrSid = "";
                    d.httpBody = null;
                    d.filename = "";
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.CompanyUploadType[m.type] === undefined ? m.type : $root.streem.api.CompanyUploadType[m.type] : m.type;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.httpBody != null && m.hasOwnProperty("httpBody")) {
                    d.httpBody = $root.google.api.HttpBody.toObject(m.httpBody, o);
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                return d;
            };

            /**
             * Converts this CreateCompanyAssetRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyAssetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyAssetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyAssetRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyAssetRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyAssetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyAssetRequest";
            };

            return CreateCompanyAssetRequest;
        })();

        api.CreateCompanyAssetResponse = (function() {

            /**
             * Properties of a CreateCompanyAssetResponse.
             * @memberof streem.api
             * @interface ICreateCompanyAssetResponse
             * @property {string|null} [storageUrl] CreateCompanyAssetResponse storageUrl
             * @property {string|null} [downloadUrl] CreateCompanyAssetResponse downloadUrl
             */

            /**
             * Constructs a new CreateCompanyAssetResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyAssetResponse.
             * @implements ICreateCompanyAssetResponse
             * @constructor
             * @param {streem.api.ICreateCompanyAssetResponse=} [p] Properties to set
             */
            function CreateCompanyAssetResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyAssetResponse storageUrl.
             * @member {string} storageUrl
             * @memberof streem.api.CreateCompanyAssetResponse
             * @instance
             */
            CreateCompanyAssetResponse.prototype.storageUrl = "";

            /**
             * CreateCompanyAssetResponse downloadUrl.
             * @member {string} downloadUrl
             * @memberof streem.api.CreateCompanyAssetResponse
             * @instance
             */
            CreateCompanyAssetResponse.prototype.downloadUrl = "";

            /**
             * Creates a new CreateCompanyAssetResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {streem.api.ICreateCompanyAssetResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyAssetResponse} CreateCompanyAssetResponse instance
             */
            CreateCompanyAssetResponse.create = function create(properties) {
                return new CreateCompanyAssetResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyAssetResponse message. Does not implicitly {@link streem.api.CreateCompanyAssetResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {streem.api.ICreateCompanyAssetResponse} m CreateCompanyAssetResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyAssetResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                    w.uint32(10).string(m.storageUrl);
                if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                    w.uint32(18).string(m.downloadUrl);
                return w;
            };

            /**
             * Decodes a CreateCompanyAssetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyAssetResponse} CreateCompanyAssetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyAssetResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyAssetResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.storageUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.downloadUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyAssetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyAssetResponse} CreateCompanyAssetResponse
             */
            CreateCompanyAssetResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyAssetResponse)
                    return d;
                var m = new $root.streem.api.CreateCompanyAssetResponse();
                if (d.storageUrl != null) {
                    m.storageUrl = String(d.storageUrl);
                }
                if (d.downloadUrl != null) {
                    m.downloadUrl = String(d.downloadUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyAssetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {streem.api.CreateCompanyAssetResponse} m CreateCompanyAssetResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyAssetResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storageUrl = "";
                    d.downloadUrl = "";
                }
                if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                    d.storageUrl = m.storageUrl;
                }
                if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                    d.downloadUrl = m.downloadUrl;
                }
                return d;
            };

            /**
             * Converts this CreateCompanyAssetResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyAssetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyAssetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyAssetResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyAssetResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyAssetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyAssetResponse";
            };

            return CreateCompanyAssetResponse;
        })();

        api.ListCompanyApiKeysRequest = (function() {

            /**
             * Properties of a ListCompanyApiKeysRequest.
             * @memberof streem.api
             * @interface IListCompanyApiKeysRequest
             * @property {string|null} [companyCodeOrSid] ListCompanyApiKeysRequest companyCodeOrSid
             * @property {boolean|null} [includeExpired] ListCompanyApiKeysRequest includeExpired
             * @property {streem.api.ListCompanyApiKeysRequest.Expires|null} [expires] ListCompanyApiKeysRequest expires
             * @property {number|null} [pageSize] ListCompanyApiKeysRequest pageSize
             * @property {string|null} [pageToken] ListCompanyApiKeysRequest pageToken
             */

            /**
             * Constructs a new ListCompanyApiKeysRequest.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyApiKeysRequest.
             * @implements IListCompanyApiKeysRequest
             * @constructor
             * @param {streem.api.IListCompanyApiKeysRequest=} [p] Properties to set
             */
            function ListCompanyApiKeysRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyApiKeysRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             */
            ListCompanyApiKeysRequest.prototype.companyCodeOrSid = "";

            /**
             * ListCompanyApiKeysRequest includeExpired.
             * @member {boolean} includeExpired
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             */
            ListCompanyApiKeysRequest.prototype.includeExpired = false;

            /**
             * ListCompanyApiKeysRequest expires.
             * @member {streem.api.ListCompanyApiKeysRequest.Expires} expires
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             */
            ListCompanyApiKeysRequest.prototype.expires = 0;

            /**
             * ListCompanyApiKeysRequest pageSize.
             * @member {number} pageSize
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             */
            ListCompanyApiKeysRequest.prototype.pageSize = 0;

            /**
             * ListCompanyApiKeysRequest pageToken.
             * @member {string} pageToken
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             */
            ListCompanyApiKeysRequest.prototype.pageToken = "";

            /**
             * Creates a new ListCompanyApiKeysRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {streem.api.IListCompanyApiKeysRequest=} [properties] Properties to set
             * @returns {streem.api.ListCompanyApiKeysRequest} ListCompanyApiKeysRequest instance
             */
            ListCompanyApiKeysRequest.create = function create(properties) {
                return new ListCompanyApiKeysRequest(properties);
            };

            /**
             * Encodes the specified ListCompanyApiKeysRequest message. Does not implicitly {@link streem.api.ListCompanyApiKeysRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {streem.api.IListCompanyApiKeysRequest} m ListCompanyApiKeysRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyApiKeysRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.includeExpired != null && Object.hasOwnProperty.call(m, "includeExpired"))
                    w.uint32(16).bool(m.includeExpired);
                if (m.expires != null && Object.hasOwnProperty.call(m, "expires"))
                    w.uint32(24).int32(m.expires);
                if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                    w.uint32(32).int32(m.pageSize);
                if (m.pageToken != null && Object.hasOwnProperty.call(m, "pageToken"))
                    w.uint32(42).string(m.pageToken);
                return w;
            };

            /**
             * Decodes a ListCompanyApiKeysRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyApiKeysRequest} ListCompanyApiKeysRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyApiKeysRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyApiKeysRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.includeExpired = r.bool();
                            break;
                        }
                    case 3: {
                            m.expires = r.int32();
                            break;
                        }
                    case 4: {
                            m.pageSize = r.int32();
                            break;
                        }
                    case 5: {
                            m.pageToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyApiKeysRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyApiKeysRequest} ListCompanyApiKeysRequest
             */
            ListCompanyApiKeysRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyApiKeysRequest)
                    return d;
                var m = new $root.streem.api.ListCompanyApiKeysRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.includeExpired != null) {
                    m.includeExpired = Boolean(d.includeExpired);
                }
                switch (d.expires) {
                default:
                    if (typeof d.expires === "number") {
                        m.expires = d.expires;
                        break;
                    }
                    break;
                case "EXPIRES_ANY":
                case 0:
                    m.expires = 0;
                    break;
                case "EXPIRES_NO":
                case 1:
                    m.expires = 1;
                    break;
                case "EXPIRES_YES":
                case 2:
                    m.expires = 2;
                    break;
                }
                if (d.pageSize != null) {
                    m.pageSize = d.pageSize | 0;
                }
                if (d.pageToken != null) {
                    m.pageToken = String(d.pageToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyApiKeysRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {streem.api.ListCompanyApiKeysRequest} m ListCompanyApiKeysRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyApiKeysRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.includeExpired = false;
                    d.expires = o.enums === String ? "EXPIRES_ANY" : 0;
                    d.pageSize = 0;
                    d.pageToken = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.includeExpired != null && m.hasOwnProperty("includeExpired")) {
                    d.includeExpired = m.includeExpired;
                }
                if (m.expires != null && m.hasOwnProperty("expires")) {
                    d.expires = o.enums === String ? $root.streem.api.ListCompanyApiKeysRequest.Expires[m.expires] === undefined ? m.expires : $root.streem.api.ListCompanyApiKeysRequest.Expires[m.expires] : m.expires;
                }
                if (m.pageSize != null && m.hasOwnProperty("pageSize")) {
                    d.pageSize = m.pageSize;
                }
                if (m.pageToken != null && m.hasOwnProperty("pageToken")) {
                    d.pageToken = m.pageToken;
                }
                return d;
            };

            /**
             * Converts this ListCompanyApiKeysRequest to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyApiKeysRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyApiKeysRequest
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyApiKeysRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyApiKeysRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyApiKeysRequest";
            };

            /**
             * Expires enum.
             * @name streem.api.ListCompanyApiKeysRequest.Expires
             * @enum {number}
             * @property {number} EXPIRES_ANY=0 EXPIRES_ANY value
             * @property {number} EXPIRES_NO=1 EXPIRES_NO value
             * @property {number} EXPIRES_YES=2 EXPIRES_YES value
             */
            ListCompanyApiKeysRequest.Expires = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "EXPIRES_ANY"] = 0;
                values[valuesById[1] = "EXPIRES_NO"] = 1;
                values[valuesById[2] = "EXPIRES_YES"] = 2;
                return values;
            })();

            return ListCompanyApiKeysRequest;
        })();

        api.ListCompanyApiKeysResponse = (function() {

            /**
             * Properties of a ListCompanyApiKeysResponse.
             * @memberof streem.api
             * @interface IListCompanyApiKeysResponse
             * @property {Array.<streem.api.ICompanyApiKey>|null} [apiKeys] ListCompanyApiKeysResponse apiKeys
             * @property {string|null} [nextPageToken] ListCompanyApiKeysResponse nextPageToken
             * @property {number|null} [totalSize] ListCompanyApiKeysResponse totalSize
             */

            /**
             * Constructs a new ListCompanyApiKeysResponse.
             * @memberof streem.api
             * @classdesc Represents a ListCompanyApiKeysResponse.
             * @implements IListCompanyApiKeysResponse
             * @constructor
             * @param {streem.api.IListCompanyApiKeysResponse=} [p] Properties to set
             */
            function ListCompanyApiKeysResponse(p) {
                this.apiKeys = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListCompanyApiKeysResponse apiKeys.
             * @member {Array.<streem.api.ICompanyApiKey>} apiKeys
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @instance
             */
            ListCompanyApiKeysResponse.prototype.apiKeys = $util.emptyArray;

            /**
             * ListCompanyApiKeysResponse nextPageToken.
             * @member {string} nextPageToken
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @instance
             */
            ListCompanyApiKeysResponse.prototype.nextPageToken = "";

            /**
             * ListCompanyApiKeysResponse totalSize.
             * @member {number} totalSize
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @instance
             */
            ListCompanyApiKeysResponse.prototype.totalSize = 0;

            /**
             * Creates a new ListCompanyApiKeysResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {streem.api.IListCompanyApiKeysResponse=} [properties] Properties to set
             * @returns {streem.api.ListCompanyApiKeysResponse} ListCompanyApiKeysResponse instance
             */
            ListCompanyApiKeysResponse.create = function create(properties) {
                return new ListCompanyApiKeysResponse(properties);
            };

            /**
             * Encodes the specified ListCompanyApiKeysResponse message. Does not implicitly {@link streem.api.ListCompanyApiKeysResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {streem.api.IListCompanyApiKeysResponse} m ListCompanyApiKeysResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListCompanyApiKeysResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiKeys != null && m.apiKeys.length) {
                    for (var i = 0; i < m.apiKeys.length; ++i)
                        $root.streem.api.CompanyApiKey.encode(m.apiKeys[i], w.uint32(10).fork()).ldelim();
                }
                if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                    w.uint32(18).string(m.nextPageToken);
                if (m.totalSize != null && Object.hasOwnProperty.call(m, "totalSize"))
                    w.uint32(24).int32(m.totalSize);
                return w;
            };

            /**
             * Decodes a ListCompanyApiKeysResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ListCompanyApiKeysResponse} ListCompanyApiKeysResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListCompanyApiKeysResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ListCompanyApiKeysResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.apiKeys && m.apiKeys.length))
                                m.apiKeys = [];
                            m.apiKeys.push($root.streem.api.CompanyApiKey.decode(r, r.uint32()));
                            break;
                        }
                    case 2: {
                            m.nextPageToken = r.string();
                            break;
                        }
                    case 3: {
                            m.totalSize = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListCompanyApiKeysResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ListCompanyApiKeysResponse} ListCompanyApiKeysResponse
             */
            ListCompanyApiKeysResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ListCompanyApiKeysResponse)
                    return d;
                var m = new $root.streem.api.ListCompanyApiKeysResponse();
                if (d.apiKeys) {
                    if (!Array.isArray(d.apiKeys))
                        throw TypeError(".streem.api.ListCompanyApiKeysResponse.apiKeys: array expected");
                    m.apiKeys = [];
                    for (var i = 0; i < d.apiKeys.length; ++i) {
                        if (typeof d.apiKeys[i] !== "object")
                            throw TypeError(".streem.api.ListCompanyApiKeysResponse.apiKeys: object expected");
                        m.apiKeys[i] = $root.streem.api.CompanyApiKey.fromObject(d.apiKeys[i]);
                    }
                }
                if (d.nextPageToken != null) {
                    m.nextPageToken = String(d.nextPageToken);
                }
                if (d.totalSize != null) {
                    m.totalSize = d.totalSize | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ListCompanyApiKeysResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {streem.api.ListCompanyApiKeysResponse} m ListCompanyApiKeysResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListCompanyApiKeysResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.apiKeys = [];
                }
                if (o.defaults) {
                    d.nextPageToken = "";
                    d.totalSize = 0;
                }
                if (m.apiKeys && m.apiKeys.length) {
                    d.apiKeys = [];
                    for (var j = 0; j < m.apiKeys.length; ++j) {
                        d.apiKeys[j] = $root.streem.api.CompanyApiKey.toObject(m.apiKeys[j], o);
                    }
                }
                if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                    d.nextPageToken = m.nextPageToken;
                }
                if (m.totalSize != null && m.hasOwnProperty("totalSize")) {
                    d.totalSize = m.totalSize;
                }
                return d;
            };

            /**
             * Converts this ListCompanyApiKeysResponse to JSON.
             * @function toJSON
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListCompanyApiKeysResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListCompanyApiKeysResponse
             * @function getTypeUrl
             * @memberof streem.api.ListCompanyApiKeysResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListCompanyApiKeysResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ListCompanyApiKeysResponse";
            };

            return ListCompanyApiKeysResponse;
        })();

        api.CreateCompanyApiKeyRequest = (function() {

            /**
             * Properties of a CreateCompanyApiKeyRequest.
             * @memberof streem.api
             * @interface ICreateCompanyApiKeyRequest
             * @property {string|null} [companyCodeOrSid] CreateCompanyApiKeyRequest companyCodeOrSid
             * @property {google.protobuf.ITimestamp|null} [expiresAt] CreateCompanyApiKeyRequest expiresAt
             * @property {string|null} [label] CreateCompanyApiKeyRequest label
             */

            /**
             * Constructs a new CreateCompanyApiKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyApiKeyRequest.
             * @implements ICreateCompanyApiKeyRequest
             * @constructor
             * @param {streem.api.ICreateCompanyApiKeyRequest=} [p] Properties to set
             */
            function CreateCompanyApiKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyApiKeyRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @instance
             */
            CreateCompanyApiKeyRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateCompanyApiKeyRequest expiresAt.
             * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @instance
             */
            CreateCompanyApiKeyRequest.prototype.expiresAt = null;

            /**
             * CreateCompanyApiKeyRequest label.
             * @member {string} label
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @instance
             */
            CreateCompanyApiKeyRequest.prototype.label = "";

            /**
             * Creates a new CreateCompanyApiKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {streem.api.ICreateCompanyApiKeyRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyApiKeyRequest} CreateCompanyApiKeyRequest instance
             */
            CreateCompanyApiKeyRequest.create = function create(properties) {
                return new CreateCompanyApiKeyRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyApiKeyRequest message. Does not implicitly {@link streem.api.CreateCompanyApiKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {streem.api.ICreateCompanyApiKeyRequest} m CreateCompanyApiKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyApiKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.expiresAt != null && Object.hasOwnProperty.call(m, "expiresAt"))
                    $root.google.protobuf.Timestamp.encode(m.expiresAt, w.uint32(18).fork()).ldelim();
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(26).string(m.label);
                return w;
            };

            /**
             * Decodes a CreateCompanyApiKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyApiKeyRequest} CreateCompanyApiKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyApiKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyApiKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.expiresAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.label = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyApiKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyApiKeyRequest} CreateCompanyApiKeyRequest
             */
            CreateCompanyApiKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyApiKeyRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyApiKeyRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.expiresAt != null) {
                    if (typeof d.expiresAt !== "object")
                        throw TypeError(".streem.api.CreateCompanyApiKeyRequest.expiresAt: object expected");
                    m.expiresAt = $root.google.protobuf.Timestamp.fromObject(d.expiresAt);
                }
                if (d.label != null) {
                    m.label = String(d.label);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyApiKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {streem.api.CreateCompanyApiKeyRequest} m CreateCompanyApiKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyApiKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.expiresAt = null;
                    d.label = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                    d.expiresAt = $root.google.protobuf.Timestamp.toObject(m.expiresAt, o);
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = m.label;
                }
                return d;
            };

            /**
             * Converts this CreateCompanyApiKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyApiKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyApiKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyApiKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyApiKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyApiKeyRequest";
            };

            return CreateCompanyApiKeyRequest;
        })();

        api.CreateCompanyApiKeyResponse = (function() {

            /**
             * Properties of a CreateCompanyApiKeyResponse.
             * @memberof streem.api
             * @interface ICreateCompanyApiKeyResponse
             * @property {streem.api.ICompanyApiKey|null} [apiKey] CreateCompanyApiKeyResponse apiKey
             */

            /**
             * Constructs a new CreateCompanyApiKeyResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyApiKeyResponse.
             * @implements ICreateCompanyApiKeyResponse
             * @constructor
             * @param {streem.api.ICreateCompanyApiKeyResponse=} [p] Properties to set
             */
            function CreateCompanyApiKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyApiKeyResponse apiKey.
             * @member {streem.api.ICompanyApiKey|null|undefined} apiKey
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @instance
             */
            CreateCompanyApiKeyResponse.prototype.apiKey = null;

            /**
             * Creates a new CreateCompanyApiKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {streem.api.ICreateCompanyApiKeyResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyApiKeyResponse} CreateCompanyApiKeyResponse instance
             */
            CreateCompanyApiKeyResponse.create = function create(properties) {
                return new CreateCompanyApiKeyResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyApiKeyResponse message. Does not implicitly {@link streem.api.CreateCompanyApiKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {streem.api.ICreateCompanyApiKeyResponse} m CreateCompanyApiKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyApiKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiKey != null && Object.hasOwnProperty.call(m, "apiKey"))
                    $root.streem.api.CompanyApiKey.encode(m.apiKey, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCompanyApiKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyApiKeyResponse} CreateCompanyApiKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyApiKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyApiKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiKey = $root.streem.api.CompanyApiKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyApiKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyApiKeyResponse} CreateCompanyApiKeyResponse
             */
            CreateCompanyApiKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyApiKeyResponse)
                    return d;
                var m = new $root.streem.api.CreateCompanyApiKeyResponse();
                if (d.apiKey != null) {
                    if (typeof d.apiKey !== "object")
                        throw TypeError(".streem.api.CreateCompanyApiKeyResponse.apiKey: object expected");
                    m.apiKey = $root.streem.api.CompanyApiKey.fromObject(d.apiKey);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyApiKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {streem.api.CreateCompanyApiKeyResponse} m CreateCompanyApiKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyApiKeyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiKey = null;
                }
                if (m.apiKey != null && m.hasOwnProperty("apiKey")) {
                    d.apiKey = $root.streem.api.CompanyApiKey.toObject(m.apiKey, o);
                }
                return d;
            };

            /**
             * Converts this CreateCompanyApiKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyApiKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyApiKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyApiKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyApiKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyApiKeyResponse";
            };

            return CreateCompanyApiKeyResponse;
        })();

        api.GetCompanyApiKeyRequest = (function() {

            /**
             * Properties of a GetCompanyApiKeyRequest.
             * @memberof streem.api
             * @interface IGetCompanyApiKeyRequest
             * @property {string|null} [companyCodeOrSid] GetCompanyApiKeyRequest companyCodeOrSid
             * @property {string|null} [apiKeyId] GetCompanyApiKeyRequest apiKeyId
             */

            /**
             * Constructs a new GetCompanyApiKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyApiKeyRequest.
             * @implements IGetCompanyApiKeyRequest
             * @constructor
             * @param {streem.api.IGetCompanyApiKeyRequest=} [p] Properties to set
             */
            function GetCompanyApiKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyApiKeyRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @instance
             */
            GetCompanyApiKeyRequest.prototype.companyCodeOrSid = "";

            /**
             * GetCompanyApiKeyRequest apiKeyId.
             * @member {string} apiKeyId
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @instance
             */
            GetCompanyApiKeyRequest.prototype.apiKeyId = "";

            /**
             * Creates a new GetCompanyApiKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {streem.api.IGetCompanyApiKeyRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyApiKeyRequest} GetCompanyApiKeyRequest instance
             */
            GetCompanyApiKeyRequest.create = function create(properties) {
                return new GetCompanyApiKeyRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyApiKeyRequest message. Does not implicitly {@link streem.api.GetCompanyApiKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {streem.api.IGetCompanyApiKeyRequest} m GetCompanyApiKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyApiKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.apiKeyId != null && Object.hasOwnProperty.call(m, "apiKeyId"))
                    w.uint32(18).string(m.apiKeyId);
                return w;
            };

            /**
             * Decodes a GetCompanyApiKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyApiKeyRequest} GetCompanyApiKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyApiKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyApiKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.apiKeyId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyApiKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyApiKeyRequest} GetCompanyApiKeyRequest
             */
            GetCompanyApiKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyApiKeyRequest)
                    return d;
                var m = new $root.streem.api.GetCompanyApiKeyRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.apiKeyId != null) {
                    m.apiKeyId = String(d.apiKeyId);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyApiKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {streem.api.GetCompanyApiKeyRequest} m GetCompanyApiKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyApiKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.apiKeyId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.apiKeyId != null && m.hasOwnProperty("apiKeyId")) {
                    d.apiKeyId = m.apiKeyId;
                }
                return d;
            };

            /**
             * Converts this GetCompanyApiKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyApiKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyApiKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyApiKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyApiKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyApiKeyRequest";
            };

            return GetCompanyApiKeyRequest;
        })();

        api.GetCompanyApiKeyResponse = (function() {

            /**
             * Properties of a GetCompanyApiKeyResponse.
             * @memberof streem.api
             * @interface IGetCompanyApiKeyResponse
             * @property {streem.api.ICompanyApiKey|null} [apiKey] GetCompanyApiKeyResponse apiKey
             */

            /**
             * Constructs a new GetCompanyApiKeyResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyApiKeyResponse.
             * @implements IGetCompanyApiKeyResponse
             * @constructor
             * @param {streem.api.IGetCompanyApiKeyResponse=} [p] Properties to set
             */
            function GetCompanyApiKeyResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyApiKeyResponse apiKey.
             * @member {streem.api.ICompanyApiKey|null|undefined} apiKey
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @instance
             */
            GetCompanyApiKeyResponse.prototype.apiKey = null;

            /**
             * Creates a new GetCompanyApiKeyResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {streem.api.IGetCompanyApiKeyResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyApiKeyResponse} GetCompanyApiKeyResponse instance
             */
            GetCompanyApiKeyResponse.create = function create(properties) {
                return new GetCompanyApiKeyResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyApiKeyResponse message. Does not implicitly {@link streem.api.GetCompanyApiKeyResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {streem.api.IGetCompanyApiKeyResponse} m GetCompanyApiKeyResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyApiKeyResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiKey != null && Object.hasOwnProperty.call(m, "apiKey"))
                    $root.streem.api.CompanyApiKey.encode(m.apiKey, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanyApiKeyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyApiKeyResponse} GetCompanyApiKeyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyApiKeyResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyApiKeyResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiKey = $root.streem.api.CompanyApiKey.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyApiKeyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyApiKeyResponse} GetCompanyApiKeyResponse
             */
            GetCompanyApiKeyResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyApiKeyResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyApiKeyResponse();
                if (d.apiKey != null) {
                    if (typeof d.apiKey !== "object")
                        throw TypeError(".streem.api.GetCompanyApiKeyResponse.apiKey: object expected");
                    m.apiKey = $root.streem.api.CompanyApiKey.fromObject(d.apiKey);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyApiKeyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {streem.api.GetCompanyApiKeyResponse} m GetCompanyApiKeyResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyApiKeyResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiKey = null;
                }
                if (m.apiKey != null && m.hasOwnProperty("apiKey")) {
                    d.apiKey = $root.streem.api.CompanyApiKey.toObject(m.apiKey, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanyApiKeyResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyApiKeyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyApiKeyResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyApiKeyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyApiKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyApiKeyResponse";
            };

            return GetCompanyApiKeyResponse;
        })();

        api.RevokeCompanyApiKeyRequest = (function() {

            /**
             * Properties of a RevokeCompanyApiKeyRequest.
             * @memberof streem.api
             * @interface IRevokeCompanyApiKeyRequest
             * @property {string|null} [companyCodeOrSid] RevokeCompanyApiKeyRequest companyCodeOrSid
             * @property {string|null} [apiKeyId] RevokeCompanyApiKeyRequest apiKeyId
             */

            /**
             * Constructs a new RevokeCompanyApiKeyRequest.
             * @memberof streem.api
             * @classdesc Represents a RevokeCompanyApiKeyRequest.
             * @implements IRevokeCompanyApiKeyRequest
             * @constructor
             * @param {streem.api.IRevokeCompanyApiKeyRequest=} [p] Properties to set
             */
            function RevokeCompanyApiKeyRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RevokeCompanyApiKeyRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @instance
             */
            RevokeCompanyApiKeyRequest.prototype.companyCodeOrSid = "";

            /**
             * RevokeCompanyApiKeyRequest apiKeyId.
             * @member {string} apiKeyId
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @instance
             */
            RevokeCompanyApiKeyRequest.prototype.apiKeyId = "";

            /**
             * Creates a new RevokeCompanyApiKeyRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {streem.api.IRevokeCompanyApiKeyRequest=} [properties] Properties to set
             * @returns {streem.api.RevokeCompanyApiKeyRequest} RevokeCompanyApiKeyRequest instance
             */
            RevokeCompanyApiKeyRequest.create = function create(properties) {
                return new RevokeCompanyApiKeyRequest(properties);
            };

            /**
             * Encodes the specified RevokeCompanyApiKeyRequest message. Does not implicitly {@link streem.api.RevokeCompanyApiKeyRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {streem.api.IRevokeCompanyApiKeyRequest} m RevokeCompanyApiKeyRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RevokeCompanyApiKeyRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                if (m.apiKeyId != null && Object.hasOwnProperty.call(m, "apiKeyId"))
                    w.uint32(18).string(m.apiKeyId);
                return w;
            };

            /**
             * Decodes a RevokeCompanyApiKeyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RevokeCompanyApiKeyRequest} RevokeCompanyApiKeyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RevokeCompanyApiKeyRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RevokeCompanyApiKeyRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 2: {
                            m.apiKeyId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RevokeCompanyApiKeyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RevokeCompanyApiKeyRequest} RevokeCompanyApiKeyRequest
             */
            RevokeCompanyApiKeyRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RevokeCompanyApiKeyRequest)
                    return d;
                var m = new $root.streem.api.RevokeCompanyApiKeyRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                if (d.apiKeyId != null) {
                    m.apiKeyId = String(d.apiKeyId);
                }
                return m;
            };

            /**
             * Creates a plain object from a RevokeCompanyApiKeyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {streem.api.RevokeCompanyApiKeyRequest} m RevokeCompanyApiKeyRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RevokeCompanyApiKeyRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                    d.apiKeyId = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.apiKeyId != null && m.hasOwnProperty("apiKeyId")) {
                    d.apiKeyId = m.apiKeyId;
                }
                return d;
            };

            /**
             * Converts this RevokeCompanyApiKeyRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RevokeCompanyApiKeyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RevokeCompanyApiKeyRequest
             * @function getTypeUrl
             * @memberof streem.api.RevokeCompanyApiKeyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RevokeCompanyApiKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RevokeCompanyApiKeyRequest";
            };

            return RevokeCompanyApiKeyRequest;
        })();

        api.CreateCompanyUserRequest = (function() {

            /**
             * Properties of a CreateCompanyUserRequest.
             * @memberof streem.api
             * @interface ICreateCompanyUserRequest
             * @property {string|null} [email] CreateCompanyUserRequest email
             * @property {string|null} [name] CreateCompanyUserRequest name
             * @property {string|null} [companyCodeOrSid] CreateCompanyUserRequest companyCodeOrSid
             * @property {streem.api.User.UserType|null} [userType] CreateCompanyUserRequest userType
             * @property {Array.<string>|null} [groups] CreateCompanyUserRequest groups
             * @property {string|null} [bio] CreateCompanyUserRequest bio
             */

            /**
             * Constructs a new CreateCompanyUserRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyUserRequest.
             * @implements ICreateCompanyUserRequest
             * @constructor
             * @param {streem.api.ICreateCompanyUserRequest=} [p] Properties to set
             */
            function CreateCompanyUserRequest(p) {
                this.groups = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyUserRequest email.
             * @member {string} email
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.email = "";

            /**
             * CreateCompanyUserRequest name.
             * @member {string} name
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.name = "";

            /**
             * CreateCompanyUserRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.companyCodeOrSid = "";

            /**
             * CreateCompanyUserRequest userType.
             * @member {streem.api.User.UserType} userType
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.userType = 0;

            /**
             * CreateCompanyUserRequest groups.
             * @member {Array.<string>} groups
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.groups = $util.emptyArray;

            /**
             * CreateCompanyUserRequest bio.
             * @member {string} bio
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             */
            CreateCompanyUserRequest.prototype.bio = "";

            /**
             * Creates a new CreateCompanyUserRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {streem.api.ICreateCompanyUserRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyUserRequest} CreateCompanyUserRequest instance
             */
            CreateCompanyUserRequest.create = function create(properties) {
                return new CreateCompanyUserRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyUserRequest message. Does not implicitly {@link streem.api.CreateCompanyUserRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {streem.api.ICreateCompanyUserRequest} m CreateCompanyUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyUserRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                    w.uint32(10).string(m.email);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(26).string(m.companyCodeOrSid);
                if (m.userType != null && Object.hasOwnProperty.call(m, "userType"))
                    w.uint32(32).int32(m.userType);
                if (m.groups != null && m.groups.length) {
                    for (var i = 0; i < m.groups.length; ++i)
                        w.uint32(42).string(m.groups[i]);
                }
                if (m.bio != null && Object.hasOwnProperty.call(m, "bio"))
                    w.uint32(50).string(m.bio);
                return w;
            };

            /**
             * Decodes a CreateCompanyUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyUserRequest} CreateCompanyUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyUserRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyUserRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.email = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    case 4: {
                            m.userType = r.int32();
                            break;
                        }
                    case 5: {
                            if (!(m.groups && m.groups.length))
                                m.groups = [];
                            m.groups.push(r.string());
                            break;
                        }
                    case 6: {
                            m.bio = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyUserRequest} CreateCompanyUserRequest
             */
            CreateCompanyUserRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyUserRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyUserRequest();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                switch (d.userType) {
                default:
                    if (typeof d.userType === "number") {
                        m.userType = d.userType;
                        break;
                    }
                    break;
                case "USER_TYPE_UNSPECIFIED":
                case 0:
                    m.userType = 0;
                    break;
                case "USER_TYPE_LOGIN":
                case 1:
                    m.userType = 1;
                    break;
                case "USER_TYPE_SDK":
                case 2:
                    m.userType = 2;
                    break;
                case "USER_TYPE_INVITED":
                case 3:
                    m.userType = 3;
                    break;
                }
                if (d.groups) {
                    if (!Array.isArray(d.groups))
                        throw TypeError(".streem.api.CreateCompanyUserRequest.groups: array expected");
                    m.groups = [];
                    for (var i = 0; i < d.groups.length; ++i) {
                        m.groups[i] = String(d.groups[i]);
                    }
                }
                if (d.bio != null) {
                    m.bio = String(d.bio);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {streem.api.CreateCompanyUserRequest} m CreateCompanyUserRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyUserRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.groups = [];
                }
                if (o.defaults) {
                    d.email = "";
                    d.name = "";
                    d.companyCodeOrSid = "";
                    d.userType = o.enums === String ? "USER_TYPE_UNSPECIFIED" : 0;
                    d.bio = "";
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                if (m.userType != null && m.hasOwnProperty("userType")) {
                    d.userType = o.enums === String ? $root.streem.api.User.UserType[m.userType] === undefined ? m.userType : $root.streem.api.User.UserType[m.userType] : m.userType;
                }
                if (m.groups && m.groups.length) {
                    d.groups = [];
                    for (var j = 0; j < m.groups.length; ++j) {
                        d.groups[j] = m.groups[j];
                    }
                }
                if (m.bio != null && m.hasOwnProperty("bio")) {
                    d.bio = m.bio;
                }
                return d;
            };

            /**
             * Converts this CreateCompanyUserRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyUserRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyUserRequest";
            };

            return CreateCompanyUserRequest;
        })();

        api.CreateCompanyUserResponse = (function() {

            /**
             * Properties of a CreateCompanyUserResponse.
             * @memberof streem.api
             * @interface ICreateCompanyUserResponse
             * @property {streem.api.IUser|null} [user] CreateCompanyUserResponse user
             */

            /**
             * Constructs a new CreateCompanyUserResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyUserResponse.
             * @implements ICreateCompanyUserResponse
             * @constructor
             * @param {streem.api.ICreateCompanyUserResponse=} [p] Properties to set
             */
            function CreateCompanyUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.CreateCompanyUserResponse
             * @instance
             */
            CreateCompanyUserResponse.prototype.user = null;

            /**
             * Creates a new CreateCompanyUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {streem.api.ICreateCompanyUserResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyUserResponse} CreateCompanyUserResponse instance
             */
            CreateCompanyUserResponse.create = function create(properties) {
                return new CreateCompanyUserResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyUserResponse message. Does not implicitly {@link streem.api.CreateCompanyUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {streem.api.ICreateCompanyUserResponse} m CreateCompanyUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCompanyUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyUserResponse} CreateCompanyUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyUserResponse} CreateCompanyUserResponse
             */
            CreateCompanyUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyUserResponse)
                    return d;
                var m = new $root.streem.api.CreateCompanyUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.CreateCompanyUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {streem.api.CreateCompanyUserResponse} m CreateCompanyUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this CreateCompanyUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyUserResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyUserResponse";
            };

            return CreateCompanyUserResponse;
        })();

        api.DeactivateUserResponse = (function() {

            /**
             * Properties of a DeactivateUserResponse.
             * @memberof streem.api
             * @interface IDeactivateUserResponse
             * @property {streem.api.IUser|null} [user] DeactivateUserResponse user
             */

            /**
             * Constructs a new DeactivateUserResponse.
             * @memberof streem.api
             * @classdesc Represents a DeactivateUserResponse.
             * @implements IDeactivateUserResponse
             * @constructor
             * @param {streem.api.IDeactivateUserResponse=} [p] Properties to set
             */
            function DeactivateUserResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DeactivateUserResponse user.
             * @member {streem.api.IUser|null|undefined} user
             * @memberof streem.api.DeactivateUserResponse
             * @instance
             */
            DeactivateUserResponse.prototype.user = null;

            /**
             * Creates a new DeactivateUserResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {streem.api.IDeactivateUserResponse=} [properties] Properties to set
             * @returns {streem.api.DeactivateUserResponse} DeactivateUserResponse instance
             */
            DeactivateUserResponse.create = function create(properties) {
                return new DeactivateUserResponse(properties);
            };

            /**
             * Encodes the specified DeactivateUserResponse message. Does not implicitly {@link streem.api.DeactivateUserResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {streem.api.IDeactivateUserResponse} m DeactivateUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeactivateUserResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                    $root.streem.api.User.encode(m.user, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a DeactivateUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeactivateUserResponse} DeactivateUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeactivateUserResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeactivateUserResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.user = $root.streem.api.User.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeactivateUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeactivateUserResponse} DeactivateUserResponse
             */
            DeactivateUserResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeactivateUserResponse)
                    return d;
                var m = new $root.streem.api.DeactivateUserResponse();
                if (d.user != null) {
                    if (typeof d.user !== "object")
                        throw TypeError(".streem.api.DeactivateUserResponse.user: object expected");
                    m.user = $root.streem.api.User.fromObject(d.user);
                }
                return m;
            };

            /**
             * Creates a plain object from a DeactivateUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {streem.api.DeactivateUserResponse} m DeactivateUserResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeactivateUserResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.user = null;
                }
                if (m.user != null && m.hasOwnProperty("user")) {
                    d.user = $root.streem.api.User.toObject(m.user, o);
                }
                return d;
            };

            /**
             * Converts this DeactivateUserResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeactivateUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeactivateUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeactivateUserResponse
             * @function getTypeUrl
             * @memberof streem.api.DeactivateUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeactivateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeactivateUserResponse";
            };

            return DeactivateUserResponse;
        })();

        api.CreatePiiRequestRequest = (function() {

            /**
             * Properties of a CreatePiiRequestRequest.
             * @memberof streem.api
             * @interface ICreatePiiRequestRequest
             * @property {string|null} [externalUserId] CreatePiiRequestRequest externalUserId
             * @property {boolean|null} [includeSubCompanies] CreatePiiRequestRequest includeSubCompanies
             * @property {string|null} [customIdentifier] CreatePiiRequestRequest customIdentifier
             * @property {string|null} [webhookUrl] CreatePiiRequestRequest webhookUrl
             * @property {string|null} [oauthUrl] CreatePiiRequestRequest oauthUrl
             * @property {streem.api.IOAuthUrlProperties|null} [oauthUrlProperties] CreatePiiRequestRequest oauthUrlProperties
             */

            /**
             * Constructs a new CreatePiiRequestRequest.
             * @memberof streem.api
             * @classdesc Represents a CreatePiiRequestRequest.
             * @implements ICreatePiiRequestRequest
             * @constructor
             * @param {streem.api.ICreatePiiRequestRequest=} [p] Properties to set
             */
            function CreatePiiRequestRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreatePiiRequestRequest externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.externalUserId = "";

            /**
             * CreatePiiRequestRequest includeSubCompanies.
             * @member {boolean} includeSubCompanies
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.includeSubCompanies = false;

            /**
             * CreatePiiRequestRequest customIdentifier.
             * @member {string} customIdentifier
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.customIdentifier = "";

            /**
             * CreatePiiRequestRequest webhookUrl.
             * @member {string} webhookUrl
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.webhookUrl = "";

            /**
             * CreatePiiRequestRequest oauthUrl.
             * @member {string} oauthUrl
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.oauthUrl = "";

            /**
             * CreatePiiRequestRequest oauthUrlProperties.
             * @member {streem.api.IOAuthUrlProperties|null|undefined} oauthUrlProperties
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             */
            CreatePiiRequestRequest.prototype.oauthUrlProperties = null;

            /**
             * Creates a new CreatePiiRequestRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {streem.api.ICreatePiiRequestRequest=} [properties] Properties to set
             * @returns {streem.api.CreatePiiRequestRequest} CreatePiiRequestRequest instance
             */
            CreatePiiRequestRequest.create = function create(properties) {
                return new CreatePiiRequestRequest(properties);
            };

            /**
             * Encodes the specified CreatePiiRequestRequest message. Does not implicitly {@link streem.api.CreatePiiRequestRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {streem.api.ICreatePiiRequestRequest} m CreatePiiRequestRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePiiRequestRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(10).string(m.externalUserId);
                if (m.includeSubCompanies != null && Object.hasOwnProperty.call(m, "includeSubCompanies"))
                    w.uint32(16).bool(m.includeSubCompanies);
                if (m.customIdentifier != null && Object.hasOwnProperty.call(m, "customIdentifier"))
                    w.uint32(26).string(m.customIdentifier);
                if (m.webhookUrl != null && Object.hasOwnProperty.call(m, "webhookUrl"))
                    w.uint32(34).string(m.webhookUrl);
                if (m.oauthUrl != null && Object.hasOwnProperty.call(m, "oauthUrl"))
                    w.uint32(42).string(m.oauthUrl);
                if (m.oauthUrlProperties != null && Object.hasOwnProperty.call(m, "oauthUrlProperties"))
                    $root.streem.api.OAuthUrlProperties.encode(m.oauthUrlProperties, w.uint32(50).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreatePiiRequestRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreatePiiRequestRequest} CreatePiiRequestRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePiiRequestRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreatePiiRequestRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 2: {
                            m.includeSubCompanies = r.bool();
                            break;
                        }
                    case 3: {
                            m.customIdentifier = r.string();
                            break;
                        }
                    case 4: {
                            m.webhookUrl = r.string();
                            break;
                        }
                    case 5: {
                            m.oauthUrl = r.string();
                            break;
                        }
                    case 6: {
                            m.oauthUrlProperties = $root.streem.api.OAuthUrlProperties.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreatePiiRequestRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreatePiiRequestRequest} CreatePiiRequestRequest
             */
            CreatePiiRequestRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreatePiiRequestRequest)
                    return d;
                var m = new $root.streem.api.CreatePiiRequestRequest();
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.includeSubCompanies != null) {
                    m.includeSubCompanies = Boolean(d.includeSubCompanies);
                }
                if (d.customIdentifier != null) {
                    m.customIdentifier = String(d.customIdentifier);
                }
                if (d.webhookUrl != null) {
                    m.webhookUrl = String(d.webhookUrl);
                }
                if (d.oauthUrl != null) {
                    m.oauthUrl = String(d.oauthUrl);
                }
                if (d.oauthUrlProperties != null) {
                    if (typeof d.oauthUrlProperties !== "object")
                        throw TypeError(".streem.api.CreatePiiRequestRequest.oauthUrlProperties: object expected");
                    m.oauthUrlProperties = $root.streem.api.OAuthUrlProperties.fromObject(d.oauthUrlProperties);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreatePiiRequestRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {streem.api.CreatePiiRequestRequest} m CreatePiiRequestRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreatePiiRequestRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.externalUserId = "";
                    d.includeSubCompanies = false;
                    d.customIdentifier = "";
                    d.webhookUrl = "";
                    d.oauthUrl = "";
                    d.oauthUrlProperties = null;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.includeSubCompanies != null && m.hasOwnProperty("includeSubCompanies")) {
                    d.includeSubCompanies = m.includeSubCompanies;
                }
                if (m.customIdentifier != null && m.hasOwnProperty("customIdentifier")) {
                    d.customIdentifier = m.customIdentifier;
                }
                if (m.webhookUrl != null && m.hasOwnProperty("webhookUrl")) {
                    d.webhookUrl = m.webhookUrl;
                }
                if (m.oauthUrl != null && m.hasOwnProperty("oauthUrl")) {
                    d.oauthUrl = m.oauthUrl;
                }
                if (m.oauthUrlProperties != null && m.hasOwnProperty("oauthUrlProperties")) {
                    d.oauthUrlProperties = $root.streem.api.OAuthUrlProperties.toObject(m.oauthUrlProperties, o);
                }
                return d;
            };

            /**
             * Converts this CreatePiiRequestRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreatePiiRequestRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreatePiiRequestRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreatePiiRequestRequest
             * @function getTypeUrl
             * @memberof streem.api.CreatePiiRequestRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreatePiiRequestRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreatePiiRequestRequest";
            };

            return CreatePiiRequestRequest;
        })();

        api.CreatePiiRequestResponse = (function() {

            /**
             * Properties of a CreatePiiRequestResponse.
             * @memberof streem.api
             * @interface ICreatePiiRequestResponse
             * @property {string|null} [piiRequestSid] CreatePiiRequestResponse piiRequestSid
             * @property {string|null} [customIdentifier] CreatePiiRequestResponse customIdentifier
             */

            /**
             * Constructs a new CreatePiiRequestResponse.
             * @memberof streem.api
             * @classdesc Represents a CreatePiiRequestResponse.
             * @implements ICreatePiiRequestResponse
             * @constructor
             * @param {streem.api.ICreatePiiRequestResponse=} [p] Properties to set
             */
            function CreatePiiRequestResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreatePiiRequestResponse piiRequestSid.
             * @member {string} piiRequestSid
             * @memberof streem.api.CreatePiiRequestResponse
             * @instance
             */
            CreatePiiRequestResponse.prototype.piiRequestSid = "";

            /**
             * CreatePiiRequestResponse customIdentifier.
             * @member {string} customIdentifier
             * @memberof streem.api.CreatePiiRequestResponse
             * @instance
             */
            CreatePiiRequestResponse.prototype.customIdentifier = "";

            /**
             * Creates a new CreatePiiRequestResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {streem.api.ICreatePiiRequestResponse=} [properties] Properties to set
             * @returns {streem.api.CreatePiiRequestResponse} CreatePiiRequestResponse instance
             */
            CreatePiiRequestResponse.create = function create(properties) {
                return new CreatePiiRequestResponse(properties);
            };

            /**
             * Encodes the specified CreatePiiRequestResponse message. Does not implicitly {@link streem.api.CreatePiiRequestResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {streem.api.ICreatePiiRequestResponse} m CreatePiiRequestResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePiiRequestResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.piiRequestSid != null && Object.hasOwnProperty.call(m, "piiRequestSid"))
                    w.uint32(10).string(m.piiRequestSid);
                if (m.customIdentifier != null && Object.hasOwnProperty.call(m, "customIdentifier"))
                    w.uint32(18).string(m.customIdentifier);
                return w;
            };

            /**
             * Decodes a CreatePiiRequestResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreatePiiRequestResponse} CreatePiiRequestResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePiiRequestResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreatePiiRequestResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.piiRequestSid = r.string();
                            break;
                        }
                    case 2: {
                            m.customIdentifier = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreatePiiRequestResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreatePiiRequestResponse} CreatePiiRequestResponse
             */
            CreatePiiRequestResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreatePiiRequestResponse)
                    return d;
                var m = new $root.streem.api.CreatePiiRequestResponse();
                if (d.piiRequestSid != null) {
                    m.piiRequestSid = String(d.piiRequestSid);
                }
                if (d.customIdentifier != null) {
                    m.customIdentifier = String(d.customIdentifier);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreatePiiRequestResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {streem.api.CreatePiiRequestResponse} m CreatePiiRequestResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreatePiiRequestResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.piiRequestSid = "";
                    d.customIdentifier = "";
                }
                if (m.piiRequestSid != null && m.hasOwnProperty("piiRequestSid")) {
                    d.piiRequestSid = m.piiRequestSid;
                }
                if (m.customIdentifier != null && m.hasOwnProperty("customIdentifier")) {
                    d.customIdentifier = m.customIdentifier;
                }
                return d;
            };

            /**
             * Converts this CreatePiiRequestResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreatePiiRequestResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreatePiiRequestResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreatePiiRequestResponse
             * @function getTypeUrl
             * @memberof streem.api.CreatePiiRequestResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreatePiiRequestResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreatePiiRequestResponse";
            };

            return CreatePiiRequestResponse;
        })();

        api.CreateCompanyRoomOutcomeConfigRequest = (function() {

            /**
             * Properties of a CreateCompanyRoomOutcomeConfigRequest.
             * @memberof streem.api
             * @interface ICreateCompanyRoomOutcomeConfigRequest
             * @property {string|null} [companySid] CreateCompanyRoomOutcomeConfigRequest companySid
             * @property {streem.api.CompanyRoomOutcomeConfig.ISection|null} [diagnosis] CreateCompanyRoomOutcomeConfigRequest diagnosis
             * @property {streem.api.CompanyRoomOutcomeConfig.ISection|null} [disposition] CreateCompanyRoomOutcomeConfigRequest disposition
             * @property {Object.<string,streem.api.CompanyRoomOutcomeConfig.ISection>|null} [customFields] CreateCompanyRoomOutcomeConfigRequest customFields
             */

            /**
             * Constructs a new CreateCompanyRoomOutcomeConfigRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyRoomOutcomeConfigRequest.
             * @implements ICreateCompanyRoomOutcomeConfigRequest
             * @constructor
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigRequest=} [p] Properties to set
             */
            function CreateCompanyRoomOutcomeConfigRequest(p) {
                this.customFields = {};
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyRoomOutcomeConfigRequest companySid.
             * @member {string} companySid
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @instance
             */
            CreateCompanyRoomOutcomeConfigRequest.prototype.companySid = "";

            /**
             * CreateCompanyRoomOutcomeConfigRequest diagnosis.
             * @member {streem.api.CompanyRoomOutcomeConfig.ISection|null|undefined} diagnosis
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @instance
             */
            CreateCompanyRoomOutcomeConfigRequest.prototype.diagnosis = null;

            /**
             * CreateCompanyRoomOutcomeConfigRequest disposition.
             * @member {streem.api.CompanyRoomOutcomeConfig.ISection|null|undefined} disposition
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @instance
             */
            CreateCompanyRoomOutcomeConfigRequest.prototype.disposition = null;

            /**
             * CreateCompanyRoomOutcomeConfigRequest customFields.
             * @member {Object.<string,streem.api.CompanyRoomOutcomeConfig.ISection>} customFields
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @instance
             */
            CreateCompanyRoomOutcomeConfigRequest.prototype.customFields = $util.emptyObject;

            /**
             * Creates a new CreateCompanyRoomOutcomeConfigRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigRequest} CreateCompanyRoomOutcomeConfigRequest instance
             */
            CreateCompanyRoomOutcomeConfigRequest.create = function create(properties) {
                return new CreateCompanyRoomOutcomeConfigRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyRoomOutcomeConfigRequest message. Does not implicitly {@link streem.api.CreateCompanyRoomOutcomeConfigRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigRequest} m CreateCompanyRoomOutcomeConfigRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyRoomOutcomeConfigRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(10).string(m.companySid);
                if (m.diagnosis != null && Object.hasOwnProperty.call(m, "diagnosis"))
                    $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.diagnosis, w.uint32(18).fork()).ldelim();
                if (m.disposition != null && Object.hasOwnProperty.call(m, "disposition"))
                    $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.disposition, w.uint32(26).fork()).ldelim();
                if (m.customFields != null && Object.hasOwnProperty.call(m, "customFields")) {
                    for (var ks = Object.keys(m.customFields), i = 0; i < ks.length; ++i) {
                        w.uint32(34).fork().uint32(10).string(ks[i]);
                        $root.streem.api.CompanyRoomOutcomeConfig.Section.encode(m.customFields[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                    }
                }
                return w;
            };

            /**
             * Decodes a CreateCompanyRoomOutcomeConfigRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigRequest} CreateCompanyRoomOutcomeConfigRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyRoomOutcomeConfigRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyRoomOutcomeConfigRequest(), k, value;
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companySid = r.string();
                            break;
                        }
                    case 2: {
                            m.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            if (m.customFields === $util.emptyObject)
                                m.customFields = {};
                            var c2 = r.uint32() + r.pos;
                            k = "";
                            value = null;
                            while (r.pos < c2) {
                                var tag2 = r.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    k = r.string();
                                    break;
                                case 2:
                                    value = $root.streem.api.CompanyRoomOutcomeConfig.Section.decode(r, r.uint32());
                                    break;
                                default:
                                    r.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            m.customFields[k] = value;
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyRoomOutcomeConfigRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigRequest} CreateCompanyRoomOutcomeConfigRequest
             */
            CreateCompanyRoomOutcomeConfigRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyRoomOutcomeConfigRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyRoomOutcomeConfigRequest();
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.diagnosis != null) {
                    if (typeof d.diagnosis !== "object")
                        throw TypeError(".streem.api.CreateCompanyRoomOutcomeConfigRequest.diagnosis: object expected");
                    m.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.diagnosis);
                }
                if (d.disposition != null) {
                    if (typeof d.disposition !== "object")
                        throw TypeError(".streem.api.CreateCompanyRoomOutcomeConfigRequest.disposition: object expected");
                    m.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.disposition);
                }
                if (d.customFields) {
                    if (typeof d.customFields !== "object")
                        throw TypeError(".streem.api.CreateCompanyRoomOutcomeConfigRequest.customFields: object expected");
                    m.customFields = {};
                    for (var ks = Object.keys(d.customFields), i = 0; i < ks.length; ++i) {
                        if (typeof d.customFields[ks[i]] !== "object")
                            throw TypeError(".streem.api.CreateCompanyRoomOutcomeConfigRequest.customFields: object expected");
                        m.customFields[ks[i]] = $root.streem.api.CompanyRoomOutcomeConfig.Section.fromObject(d.customFields[ks[i]]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyRoomOutcomeConfigRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.CreateCompanyRoomOutcomeConfigRequest} m CreateCompanyRoomOutcomeConfigRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyRoomOutcomeConfigRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.objects || o.defaults) {
                    d.customFields = {};
                }
                if (o.defaults) {
                    d.companySid = "";
                    d.diagnosis = null;
                    d.disposition = null;
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.diagnosis != null && m.hasOwnProperty("diagnosis")) {
                    d.diagnosis = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.diagnosis, o);
                }
                if (m.disposition != null && m.hasOwnProperty("disposition")) {
                    d.disposition = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.disposition, o);
                }
                var ks2;
                if (m.customFields && (ks2 = Object.keys(m.customFields)).length) {
                    d.customFields = {};
                    for (var j = 0; j < ks2.length; ++j) {
                        d.customFields[ks2[j]] = $root.streem.api.CompanyRoomOutcomeConfig.Section.toObject(m.customFields[ks2[j]], o);
                    }
                }
                return d;
            };

            /**
             * Converts this CreateCompanyRoomOutcomeConfigRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyRoomOutcomeConfigRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyRoomOutcomeConfigRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyRoomOutcomeConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyRoomOutcomeConfigRequest";
            };

            return CreateCompanyRoomOutcomeConfigRequest;
        })();

        api.CreateCompanyRoomOutcomeConfigResponse = (function() {

            /**
             * Properties of a CreateCompanyRoomOutcomeConfigResponse.
             * @memberof streem.api
             * @interface ICreateCompanyRoomOutcomeConfigResponse
             */

            /**
             * Constructs a new CreateCompanyRoomOutcomeConfigResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyRoomOutcomeConfigResponse.
             * @implements ICreateCompanyRoomOutcomeConfigResponse
             * @constructor
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigResponse=} [p] Properties to set
             */
            function CreateCompanyRoomOutcomeConfigResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new CreateCompanyRoomOutcomeConfigResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigResponse} CreateCompanyRoomOutcomeConfigResponse instance
             */
            CreateCompanyRoomOutcomeConfigResponse.create = function create(properties) {
                return new CreateCompanyRoomOutcomeConfigResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyRoomOutcomeConfigResponse message. Does not implicitly {@link streem.api.CreateCompanyRoomOutcomeConfigResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.ICreateCompanyRoomOutcomeConfigResponse} m CreateCompanyRoomOutcomeConfigResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyRoomOutcomeConfigResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a CreateCompanyRoomOutcomeConfigResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigResponse} CreateCompanyRoomOutcomeConfigResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyRoomOutcomeConfigResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyRoomOutcomeConfigResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyRoomOutcomeConfigResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyRoomOutcomeConfigResponse} CreateCompanyRoomOutcomeConfigResponse
             */
            CreateCompanyRoomOutcomeConfigResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyRoomOutcomeConfigResponse)
                    return d;
                return new $root.streem.api.CreateCompanyRoomOutcomeConfigResponse();
            };

            /**
             * Creates a plain object from a CreateCompanyRoomOutcomeConfigResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.CreateCompanyRoomOutcomeConfigResponse} m CreateCompanyRoomOutcomeConfigResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyRoomOutcomeConfigResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateCompanyRoomOutcomeConfigResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyRoomOutcomeConfigResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyRoomOutcomeConfigResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyRoomOutcomeConfigResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyRoomOutcomeConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyRoomOutcomeConfigResponse";
            };

            return CreateCompanyRoomOutcomeConfigResponse;
        })();

        api.GetCompanyRoomOutcomeConfigRequest = (function() {

            /**
             * Properties of a GetCompanyRoomOutcomeConfigRequest.
             * @memberof streem.api
             * @interface IGetCompanyRoomOutcomeConfigRequest
             */

            /**
             * Constructs a new GetCompanyRoomOutcomeConfigRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyRoomOutcomeConfigRequest.
             * @implements IGetCompanyRoomOutcomeConfigRequest
             * @constructor
             * @param {streem.api.IGetCompanyRoomOutcomeConfigRequest=} [p] Properties to set
             */
            function GetCompanyRoomOutcomeConfigRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCompanyRoomOutcomeConfigRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.IGetCompanyRoomOutcomeConfigRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyRoomOutcomeConfigRequest} GetCompanyRoomOutcomeConfigRequest instance
             */
            GetCompanyRoomOutcomeConfigRequest.create = function create(properties) {
                return new GetCompanyRoomOutcomeConfigRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyRoomOutcomeConfigRequest message. Does not implicitly {@link streem.api.GetCompanyRoomOutcomeConfigRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.IGetCompanyRoomOutcomeConfigRequest} m GetCompanyRoomOutcomeConfigRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyRoomOutcomeConfigRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCompanyRoomOutcomeConfigRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyRoomOutcomeConfigRequest} GetCompanyRoomOutcomeConfigRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyRoomOutcomeConfigRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyRoomOutcomeConfigRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyRoomOutcomeConfigRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyRoomOutcomeConfigRequest} GetCompanyRoomOutcomeConfigRequest
             */
            GetCompanyRoomOutcomeConfigRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyRoomOutcomeConfigRequest)
                    return d;
                return new $root.streem.api.GetCompanyRoomOutcomeConfigRequest();
            };

            /**
             * Creates a plain object from a GetCompanyRoomOutcomeConfigRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {streem.api.GetCompanyRoomOutcomeConfigRequest} m GetCompanyRoomOutcomeConfigRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyRoomOutcomeConfigRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCompanyRoomOutcomeConfigRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyRoomOutcomeConfigRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyRoomOutcomeConfigRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyRoomOutcomeConfigRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyRoomOutcomeConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyRoomOutcomeConfigRequest";
            };

            return GetCompanyRoomOutcomeConfigRequest;
        })();

        api.GetCompanyRoomOutcomeConfigResponse = (function() {

            /**
             * Properties of a GetCompanyRoomOutcomeConfigResponse.
             * @memberof streem.api
             * @interface IGetCompanyRoomOutcomeConfigResponse
             * @property {string|null} [roomOutcomeConfigUrl] GetCompanyRoomOutcomeConfigResponse roomOutcomeConfigUrl
             * @property {string|null} [fileName] GetCompanyRoomOutcomeConfigResponse fileName
             */

            /**
             * Constructs a new GetCompanyRoomOutcomeConfigResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyRoomOutcomeConfigResponse.
             * @implements IGetCompanyRoomOutcomeConfigResponse
             * @constructor
             * @param {streem.api.IGetCompanyRoomOutcomeConfigResponse=} [p] Properties to set
             */
            function GetCompanyRoomOutcomeConfigResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyRoomOutcomeConfigResponse roomOutcomeConfigUrl.
             * @member {string} roomOutcomeConfigUrl
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @instance
             */
            GetCompanyRoomOutcomeConfigResponse.prototype.roomOutcomeConfigUrl = "";

            /**
             * GetCompanyRoomOutcomeConfigResponse fileName.
             * @member {string} fileName
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @instance
             */
            GetCompanyRoomOutcomeConfigResponse.prototype.fileName = "";

            /**
             * Creates a new GetCompanyRoomOutcomeConfigResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.IGetCompanyRoomOutcomeConfigResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyRoomOutcomeConfigResponse} GetCompanyRoomOutcomeConfigResponse instance
             */
            GetCompanyRoomOutcomeConfigResponse.create = function create(properties) {
                return new GetCompanyRoomOutcomeConfigResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyRoomOutcomeConfigResponse message. Does not implicitly {@link streem.api.GetCompanyRoomOutcomeConfigResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.IGetCompanyRoomOutcomeConfigResponse} m GetCompanyRoomOutcomeConfigResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyRoomOutcomeConfigResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomOutcomeConfigUrl != null && Object.hasOwnProperty.call(m, "roomOutcomeConfigUrl"))
                    w.uint32(10).string(m.roomOutcomeConfigUrl);
                if (m.fileName != null && Object.hasOwnProperty.call(m, "fileName"))
                    w.uint32(18).string(m.fileName);
                return w;
            };

            /**
             * Decodes a GetCompanyRoomOutcomeConfigResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyRoomOutcomeConfigResponse} GetCompanyRoomOutcomeConfigResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyRoomOutcomeConfigResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyRoomOutcomeConfigResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomOutcomeConfigUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.fileName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyRoomOutcomeConfigResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyRoomOutcomeConfigResponse} GetCompanyRoomOutcomeConfigResponse
             */
            GetCompanyRoomOutcomeConfigResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyRoomOutcomeConfigResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyRoomOutcomeConfigResponse();
                if (d.roomOutcomeConfigUrl != null) {
                    m.roomOutcomeConfigUrl = String(d.roomOutcomeConfigUrl);
                }
                if (d.fileName != null) {
                    m.fileName = String(d.fileName);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyRoomOutcomeConfigResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {streem.api.GetCompanyRoomOutcomeConfigResponse} m GetCompanyRoomOutcomeConfigResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyRoomOutcomeConfigResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomOutcomeConfigUrl = "";
                    d.fileName = "";
                }
                if (m.roomOutcomeConfigUrl != null && m.hasOwnProperty("roomOutcomeConfigUrl")) {
                    d.roomOutcomeConfigUrl = m.roomOutcomeConfigUrl;
                }
                if (m.fileName != null && m.hasOwnProperty("fileName")) {
                    d.fileName = m.fileName;
                }
                return d;
            };

            /**
             * Converts this GetCompanyRoomOutcomeConfigResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyRoomOutcomeConfigResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyRoomOutcomeConfigResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyRoomOutcomeConfigResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyRoomOutcomeConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyRoomOutcomeConfigResponse";
            };

            return GetCompanyRoomOutcomeConfigResponse;
        })();

        api.CreateCompanyCallSourceRequest = (function() {

            /**
             * Properties of a CreateCompanyCallSourceRequest.
             * @memberof streem.api
             * @interface ICreateCompanyCallSourceRequest
             * @property {string|null} [code] CreateCompanyCallSourceRequest code
             * @property {string|null} [name] CreateCompanyCallSourceRequest name
             * @property {number|null} [maxDiagnoses] CreateCompanyCallSourceRequest maxDiagnoses
             * @property {boolean|null} [isDefault] CreateCompanyCallSourceRequest isDefault
             * @property {streem.api.ICustomTheme|null} [customTheme] CreateCompanyCallSourceRequest customTheme
             */

            /**
             * Constructs a new CreateCompanyCallSourceRequest.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyCallSourceRequest.
             * @implements ICreateCompanyCallSourceRequest
             * @constructor
             * @param {streem.api.ICreateCompanyCallSourceRequest=} [p] Properties to set
             */
            function CreateCompanyCallSourceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyCallSourceRequest code.
             * @member {string} code
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            CreateCompanyCallSourceRequest.prototype.code = "";

            /**
             * CreateCompanyCallSourceRequest name.
             * @member {string} name
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            CreateCompanyCallSourceRequest.prototype.name = "";

            /**
             * CreateCompanyCallSourceRequest maxDiagnoses.
             * @member {number|null|undefined} maxDiagnoses
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            CreateCompanyCallSourceRequest.prototype.maxDiagnoses = null;

            /**
             * CreateCompanyCallSourceRequest isDefault.
             * @member {boolean|null|undefined} isDefault
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            CreateCompanyCallSourceRequest.prototype.isDefault = null;

            /**
             * CreateCompanyCallSourceRequest customTheme.
             * @member {streem.api.ICustomTheme|null|undefined} customTheme
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            CreateCompanyCallSourceRequest.prototype.customTheme = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CreateCompanyCallSourceRequest _maxDiagnoses.
             * @member {"maxDiagnoses"|undefined} _maxDiagnoses
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(CreateCompanyCallSourceRequest.prototype, "_maxDiagnoses", {
                get: $util.oneOfGetter($oneOfFields = ["maxDiagnoses"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CreateCompanyCallSourceRequest _isDefault.
             * @member {"isDefault"|undefined} _isDefault
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(CreateCompanyCallSourceRequest.prototype, "_isDefault", {
                get: $util.oneOfGetter($oneOfFields = ["isDefault"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CreateCompanyCallSourceRequest _customTheme.
             * @member {"customTheme"|undefined} _customTheme
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(CreateCompanyCallSourceRequest.prototype, "_customTheme", {
                get: $util.oneOfGetter($oneOfFields = ["customTheme"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CreateCompanyCallSourceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {streem.api.ICreateCompanyCallSourceRequest=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyCallSourceRequest} CreateCompanyCallSourceRequest instance
             */
            CreateCompanyCallSourceRequest.create = function create(properties) {
                return new CreateCompanyCallSourceRequest(properties);
            };

            /**
             * Encodes the specified CreateCompanyCallSourceRequest message. Does not implicitly {@link streem.api.CreateCompanyCallSourceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {streem.api.ICreateCompanyCallSourceRequest} m CreateCompanyCallSourceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyCallSourceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(10).string(m.code);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.maxDiagnoses != null && Object.hasOwnProperty.call(m, "maxDiagnoses"))
                    w.uint32(24).int32(m.maxDiagnoses);
                if (m.isDefault != null && Object.hasOwnProperty.call(m, "isDefault"))
                    w.uint32(32).bool(m.isDefault);
                if (m.customTheme != null && Object.hasOwnProperty.call(m, "customTheme"))
                    $root.streem.api.CustomTheme.encode(m.customTheme, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCompanyCallSourceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyCallSourceRequest} CreateCompanyCallSourceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyCallSourceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyCallSourceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.code = r.string();
                            break;
                        }
                    case 2: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.maxDiagnoses = r.int32();
                            break;
                        }
                    case 4: {
                            m.isDefault = r.bool();
                            break;
                        }
                    case 5: {
                            m.customTheme = $root.streem.api.CustomTheme.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyCallSourceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyCallSourceRequest} CreateCompanyCallSourceRequest
             */
            CreateCompanyCallSourceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyCallSourceRequest)
                    return d;
                var m = new $root.streem.api.CreateCompanyCallSourceRequest();
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.maxDiagnoses != null) {
                    m.maxDiagnoses = d.maxDiagnoses | 0;
                }
                if (d.isDefault != null) {
                    m.isDefault = Boolean(d.isDefault);
                }
                if (d.customTheme != null) {
                    if (typeof d.customTheme !== "object")
                        throw TypeError(".streem.api.CreateCompanyCallSourceRequest.customTheme: object expected");
                    m.customTheme = $root.streem.api.CustomTheme.fromObject(d.customTheme);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyCallSourceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {streem.api.CreateCompanyCallSourceRequest} m CreateCompanyCallSourceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyCallSourceRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.code = "";
                    d.name = "";
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.maxDiagnoses != null && m.hasOwnProperty("maxDiagnoses")) {
                    d.maxDiagnoses = m.maxDiagnoses;
                    if (o.oneofs)
                        d._maxDiagnoses = "maxDiagnoses";
                }
                if (m.isDefault != null && m.hasOwnProperty("isDefault")) {
                    d.isDefault = m.isDefault;
                    if (o.oneofs)
                        d._isDefault = "isDefault";
                }
                if (m.customTheme != null && m.hasOwnProperty("customTheme")) {
                    d.customTheme = $root.streem.api.CustomTheme.toObject(m.customTheme, o);
                    if (o.oneofs)
                        d._customTheme = "customTheme";
                }
                return d;
            };

            /**
             * Converts this CreateCompanyCallSourceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyCallSourceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyCallSourceRequest
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyCallSourceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyCallSourceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyCallSourceRequest";
            };

            return CreateCompanyCallSourceRequest;
        })();

        api.GetCompanyCallSourceRequest = (function() {

            /**
             * Properties of a GetCompanyCallSourceRequest.
             * @memberof streem.api
             * @interface IGetCompanyCallSourceRequest
             */

            /**
             * Constructs a new GetCompanyCallSourceRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallSourceRequest.
             * @implements IGetCompanyCallSourceRequest
             * @constructor
             * @param {streem.api.IGetCompanyCallSourceRequest=} [p] Properties to set
             */
            function GetCompanyCallSourceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCompanyCallSourceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {streem.api.IGetCompanyCallSourceRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallSourceRequest} GetCompanyCallSourceRequest instance
             */
            GetCompanyCallSourceRequest.create = function create(properties) {
                return new GetCompanyCallSourceRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyCallSourceRequest message. Does not implicitly {@link streem.api.GetCompanyCallSourceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {streem.api.IGetCompanyCallSourceRequest} m GetCompanyCallSourceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallSourceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCompanyCallSourceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallSourceRequest} GetCompanyCallSourceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallSourceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallSourceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallSourceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallSourceRequest} GetCompanyCallSourceRequest
             */
            GetCompanyCallSourceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallSourceRequest)
                    return d;
                return new $root.streem.api.GetCompanyCallSourceRequest();
            };

            /**
             * Creates a plain object from a GetCompanyCallSourceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {streem.api.GetCompanyCallSourceRequest} m GetCompanyCallSourceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallSourceRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCompanyCallSourceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallSourceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallSourceRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallSourceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallSourceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallSourceRequest";
            };

            return GetCompanyCallSourceRequest;
        })();

        api.GetCompanyCallSourcesRequest = (function() {

            /**
             * Properties of a GetCompanyCallSourcesRequest.
             * @memberof streem.api
             * @interface IGetCompanyCallSourcesRequest
             */

            /**
             * Constructs a new GetCompanyCallSourcesRequest.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallSourcesRequest.
             * @implements IGetCompanyCallSourcesRequest
             * @constructor
             * @param {streem.api.IGetCompanyCallSourcesRequest=} [p] Properties to set
             */
            function GetCompanyCallSourcesRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new GetCompanyCallSourcesRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {streem.api.IGetCompanyCallSourcesRequest=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallSourcesRequest} GetCompanyCallSourcesRequest instance
             */
            GetCompanyCallSourcesRequest.create = function create(properties) {
                return new GetCompanyCallSourcesRequest(properties);
            };

            /**
             * Encodes the specified GetCompanyCallSourcesRequest message. Does not implicitly {@link streem.api.GetCompanyCallSourcesRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {streem.api.IGetCompanyCallSourcesRequest} m GetCompanyCallSourcesRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallSourcesRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a GetCompanyCallSourcesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallSourcesRequest} GetCompanyCallSourcesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallSourcesRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallSourcesRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallSourcesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallSourcesRequest} GetCompanyCallSourcesRequest
             */
            GetCompanyCallSourcesRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallSourcesRequest)
                    return d;
                return new $root.streem.api.GetCompanyCallSourcesRequest();
            };

            /**
             * Creates a plain object from a GetCompanyCallSourcesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {streem.api.GetCompanyCallSourcesRequest} m GetCompanyCallSourcesRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallSourcesRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCompanyCallSourcesRequest to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallSourcesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallSourcesRequest
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallSourcesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallSourcesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallSourcesRequest";
            };

            return GetCompanyCallSourcesRequest;
        })();

        api.CreateCompanyCallSourceResponse = (function() {

            /**
             * Properties of a CreateCompanyCallSourceResponse.
             * @memberof streem.api
             * @interface ICreateCompanyCallSourceResponse
             * @property {streem.api.ICallSource|null} [callSource] CreateCompanyCallSourceResponse callSource
             */

            /**
             * Constructs a new CreateCompanyCallSourceResponse.
             * @memberof streem.api
             * @classdesc Represents a CreateCompanyCallSourceResponse.
             * @implements ICreateCompanyCallSourceResponse
             * @constructor
             * @param {streem.api.ICreateCompanyCallSourceResponse=} [p] Properties to set
             */
            function CreateCompanyCallSourceResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateCompanyCallSourceResponse callSource.
             * @member {streem.api.ICallSource|null|undefined} callSource
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @instance
             */
            CreateCompanyCallSourceResponse.prototype.callSource = null;

            /**
             * Creates a new CreateCompanyCallSourceResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {streem.api.ICreateCompanyCallSourceResponse=} [properties] Properties to set
             * @returns {streem.api.CreateCompanyCallSourceResponse} CreateCompanyCallSourceResponse instance
             */
            CreateCompanyCallSourceResponse.create = function create(properties) {
                return new CreateCompanyCallSourceResponse(properties);
            };

            /**
             * Encodes the specified CreateCompanyCallSourceResponse message. Does not implicitly {@link streem.api.CreateCompanyCallSourceResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {streem.api.ICreateCompanyCallSourceResponse} m CreateCompanyCallSourceResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompanyCallSourceResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callSource != null && Object.hasOwnProperty.call(m, "callSource"))
                    $root.streem.api.CallSource.encode(m.callSource, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CreateCompanyCallSourceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CreateCompanyCallSourceResponse} CreateCompanyCallSourceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompanyCallSourceResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CreateCompanyCallSourceResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callSource = $root.streem.api.CallSource.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateCompanyCallSourceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CreateCompanyCallSourceResponse} CreateCompanyCallSourceResponse
             */
            CreateCompanyCallSourceResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CreateCompanyCallSourceResponse)
                    return d;
                var m = new $root.streem.api.CreateCompanyCallSourceResponse();
                if (d.callSource != null) {
                    if (typeof d.callSource !== "object")
                        throw TypeError(".streem.api.CreateCompanyCallSourceResponse.callSource: object expected");
                    m.callSource = $root.streem.api.CallSource.fromObject(d.callSource);
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateCompanyCallSourceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {streem.api.CreateCompanyCallSourceResponse} m CreateCompanyCallSourceResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompanyCallSourceResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callSource = null;
                }
                if (m.callSource != null && m.hasOwnProperty("callSource")) {
                    d.callSource = $root.streem.api.CallSource.toObject(m.callSource, o);
                }
                return d;
            };

            /**
             * Converts this CreateCompanyCallSourceResponse to JSON.
             * @function toJSON
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompanyCallSourceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCompanyCallSourceResponse
             * @function getTypeUrl
             * @memberof streem.api.CreateCompanyCallSourceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCompanyCallSourceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CreateCompanyCallSourceResponse";
            };

            return CreateCompanyCallSourceResponse;
        })();

        api.GetCompanyCallSourceResponse = (function() {

            /**
             * Properties of a GetCompanyCallSourceResponse.
             * @memberof streem.api
             * @interface IGetCompanyCallSourceResponse
             * @property {streem.api.ICallSource|null} [callSource] GetCompanyCallSourceResponse callSource
             */

            /**
             * Constructs a new GetCompanyCallSourceResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallSourceResponse.
             * @implements IGetCompanyCallSourceResponse
             * @constructor
             * @param {streem.api.IGetCompanyCallSourceResponse=} [p] Properties to set
             */
            function GetCompanyCallSourceResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyCallSourceResponse callSource.
             * @member {streem.api.ICallSource|null|undefined} callSource
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @instance
             */
            GetCompanyCallSourceResponse.prototype.callSource = null;

            /**
             * Creates a new GetCompanyCallSourceResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {streem.api.IGetCompanyCallSourceResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallSourceResponse} GetCompanyCallSourceResponse instance
             */
            GetCompanyCallSourceResponse.create = function create(properties) {
                return new GetCompanyCallSourceResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyCallSourceResponse message. Does not implicitly {@link streem.api.GetCompanyCallSourceResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {streem.api.IGetCompanyCallSourceResponse} m GetCompanyCallSourceResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallSourceResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callSource != null && Object.hasOwnProperty.call(m, "callSource"))
                    $root.streem.api.CallSource.encode(m.callSource, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a GetCompanyCallSourceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallSourceResponse} GetCompanyCallSourceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallSourceResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallSourceResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callSource = $root.streem.api.CallSource.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallSourceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallSourceResponse} GetCompanyCallSourceResponse
             */
            GetCompanyCallSourceResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallSourceResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyCallSourceResponse();
                if (d.callSource != null) {
                    if (typeof d.callSource !== "object")
                        throw TypeError(".streem.api.GetCompanyCallSourceResponse.callSource: object expected");
                    m.callSource = $root.streem.api.CallSource.fromObject(d.callSource);
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyCallSourceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {streem.api.GetCompanyCallSourceResponse} m GetCompanyCallSourceResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallSourceResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callSource = null;
                }
                if (m.callSource != null && m.hasOwnProperty("callSource")) {
                    d.callSource = $root.streem.api.CallSource.toObject(m.callSource, o);
                }
                return d;
            };

            /**
             * Converts this GetCompanyCallSourceResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallSourceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallSourceResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallSourceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallSourceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallSourceResponse";
            };

            return GetCompanyCallSourceResponse;
        })();

        api.GetCompanyCallSourcesResponse = (function() {

            /**
             * Properties of a GetCompanyCallSourcesResponse.
             * @memberof streem.api
             * @interface IGetCompanyCallSourcesResponse
             * @property {Array.<streem.api.ICallSource>|null} [callSources] GetCompanyCallSourcesResponse callSources
             */

            /**
             * Constructs a new GetCompanyCallSourcesResponse.
             * @memberof streem.api
             * @classdesc Represents a GetCompanyCallSourcesResponse.
             * @implements IGetCompanyCallSourcesResponse
             * @constructor
             * @param {streem.api.IGetCompanyCallSourcesResponse=} [p] Properties to set
             */
            function GetCompanyCallSourcesResponse(p) {
                this.callSources = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GetCompanyCallSourcesResponse callSources.
             * @member {Array.<streem.api.ICallSource>} callSources
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @instance
             */
            GetCompanyCallSourcesResponse.prototype.callSources = $util.emptyArray;

            /**
             * Creates a new GetCompanyCallSourcesResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {streem.api.IGetCompanyCallSourcesResponse=} [properties] Properties to set
             * @returns {streem.api.GetCompanyCallSourcesResponse} GetCompanyCallSourcesResponse instance
             */
            GetCompanyCallSourcesResponse.create = function create(properties) {
                return new GetCompanyCallSourcesResponse(properties);
            };

            /**
             * Encodes the specified GetCompanyCallSourcesResponse message. Does not implicitly {@link streem.api.GetCompanyCallSourcesResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {streem.api.IGetCompanyCallSourcesResponse} m GetCompanyCallSourcesResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyCallSourcesResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callSources != null && m.callSources.length) {
                    for (var i = 0; i < m.callSources.length; ++i)
                        $root.streem.api.CallSource.encode(m.callSources[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GetCompanyCallSourcesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.GetCompanyCallSourcesResponse} GetCompanyCallSourcesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyCallSourcesResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.GetCompanyCallSourcesResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.callSources && m.callSources.length))
                                m.callSources = [];
                            m.callSources.push($root.streem.api.CallSource.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GetCompanyCallSourcesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.GetCompanyCallSourcesResponse} GetCompanyCallSourcesResponse
             */
            GetCompanyCallSourcesResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.GetCompanyCallSourcesResponse)
                    return d;
                var m = new $root.streem.api.GetCompanyCallSourcesResponse();
                if (d.callSources) {
                    if (!Array.isArray(d.callSources))
                        throw TypeError(".streem.api.GetCompanyCallSourcesResponse.callSources: array expected");
                    m.callSources = [];
                    for (var i = 0; i < d.callSources.length; ++i) {
                        if (typeof d.callSources[i] !== "object")
                            throw TypeError(".streem.api.GetCompanyCallSourcesResponse.callSources: object expected");
                        m.callSources[i] = $root.streem.api.CallSource.fromObject(d.callSources[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GetCompanyCallSourcesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {streem.api.GetCompanyCallSourcesResponse} m GetCompanyCallSourcesResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyCallSourcesResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.callSources = [];
                }
                if (m.callSources && m.callSources.length) {
                    d.callSources = [];
                    for (var j = 0; j < m.callSources.length; ++j) {
                        d.callSources[j] = $root.streem.api.CallSource.toObject(m.callSources[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GetCompanyCallSourcesResponse to JSON.
             * @function toJSON
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyCallSourcesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCompanyCallSourcesResponse
             * @function getTypeUrl
             * @memberof streem.api.GetCompanyCallSourcesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCompanyCallSourcesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.GetCompanyCallSourcesResponse";
            };

            return GetCompanyCallSourcesResponse;
        })();

        api.DeleteCompanyCallSourceRequest = (function() {

            /**
             * Properties of a DeleteCompanyCallSourceRequest.
             * @memberof streem.api
             * @interface IDeleteCompanyCallSourceRequest
             */

            /**
             * Constructs a new DeleteCompanyCallSourceRequest.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanyCallSourceRequest.
             * @implements IDeleteCompanyCallSourceRequest
             * @constructor
             * @param {streem.api.IDeleteCompanyCallSourceRequest=} [p] Properties to set
             */
            function DeleteCompanyCallSourceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteCompanyCallSourceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {streem.api.IDeleteCompanyCallSourceRequest=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanyCallSourceRequest} DeleteCompanyCallSourceRequest instance
             */
            DeleteCompanyCallSourceRequest.create = function create(properties) {
                return new DeleteCompanyCallSourceRequest(properties);
            };

            /**
             * Encodes the specified DeleteCompanyCallSourceRequest message. Does not implicitly {@link streem.api.DeleteCompanyCallSourceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {streem.api.IDeleteCompanyCallSourceRequest} m DeleteCompanyCallSourceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanyCallSourceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteCompanyCallSourceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanyCallSourceRequest} DeleteCompanyCallSourceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanyCallSourceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanyCallSourceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanyCallSourceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanyCallSourceRequest} DeleteCompanyCallSourceRequest
             */
            DeleteCompanyCallSourceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanyCallSourceRequest)
                    return d;
                return new $root.streem.api.DeleteCompanyCallSourceRequest();
            };

            /**
             * Creates a plain object from a DeleteCompanyCallSourceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {streem.api.DeleteCompanyCallSourceRequest} m DeleteCompanyCallSourceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanyCallSourceRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteCompanyCallSourceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanyCallSourceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanyCallSourceRequest
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanyCallSourceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanyCallSourceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanyCallSourceRequest";
            };

            return DeleteCompanyCallSourceRequest;
        })();

        api.DeleteCompanyCallSourceResponse = (function() {

            /**
             * Properties of a DeleteCompanyCallSourceResponse.
             * @memberof streem.api
             * @interface IDeleteCompanyCallSourceResponse
             */

            /**
             * Constructs a new DeleteCompanyCallSourceResponse.
             * @memberof streem.api
             * @classdesc Represents a DeleteCompanyCallSourceResponse.
             * @implements IDeleteCompanyCallSourceResponse
             * @constructor
             * @param {streem.api.IDeleteCompanyCallSourceResponse=} [p] Properties to set
             */
            function DeleteCompanyCallSourceResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new DeleteCompanyCallSourceResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {streem.api.IDeleteCompanyCallSourceResponse=} [properties] Properties to set
             * @returns {streem.api.DeleteCompanyCallSourceResponse} DeleteCompanyCallSourceResponse instance
             */
            DeleteCompanyCallSourceResponse.create = function create(properties) {
                return new DeleteCompanyCallSourceResponse(properties);
            };

            /**
             * Encodes the specified DeleteCompanyCallSourceResponse message. Does not implicitly {@link streem.api.DeleteCompanyCallSourceResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {streem.api.IDeleteCompanyCallSourceResponse} m DeleteCompanyCallSourceResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompanyCallSourceResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes a DeleteCompanyCallSourceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.DeleteCompanyCallSourceResponse} DeleteCompanyCallSourceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompanyCallSourceResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.DeleteCompanyCallSourceResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DeleteCompanyCallSourceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.DeleteCompanyCallSourceResponse} DeleteCompanyCallSourceResponse
             */
            DeleteCompanyCallSourceResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.DeleteCompanyCallSourceResponse)
                    return d;
                return new $root.streem.api.DeleteCompanyCallSourceResponse();
            };

            /**
             * Creates a plain object from a DeleteCompanyCallSourceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {streem.api.DeleteCompanyCallSourceResponse} m DeleteCompanyCallSourceResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompanyCallSourceResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteCompanyCallSourceResponse to JSON.
             * @function toJSON
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompanyCallSourceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCompanyCallSourceResponse
             * @function getTypeUrl
             * @memberof streem.api.DeleteCompanyCallSourceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCompanyCallSourceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.DeleteCompanyCallSourceResponse";
            };

            return DeleteCompanyCallSourceResponse;
        })();

        api.UploadCompanyCallSourceLogoRequest = (function() {

            /**
             * Properties of an UploadCompanyCallSourceLogoRequest.
             * @memberof streem.api
             * @interface IUploadCompanyCallSourceLogoRequest
             * @property {google.api.IHttpBody|null} [httpBody] UploadCompanyCallSourceLogoRequest httpBody
             * @property {string|null} [fileName] UploadCompanyCallSourceLogoRequest fileName
             */

            /**
             * Constructs a new UploadCompanyCallSourceLogoRequest.
             * @memberof streem.api
             * @classdesc Represents an UploadCompanyCallSourceLogoRequest.
             * @implements IUploadCompanyCallSourceLogoRequest
             * @constructor
             * @param {streem.api.IUploadCompanyCallSourceLogoRequest=} [p] Properties to set
             */
            function UploadCompanyCallSourceLogoRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UploadCompanyCallSourceLogoRequest httpBody.
             * @member {google.api.IHttpBody|null|undefined} httpBody
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @instance
             */
            UploadCompanyCallSourceLogoRequest.prototype.httpBody = null;

            /**
             * UploadCompanyCallSourceLogoRequest fileName.
             * @member {string} fileName
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @instance
             */
            UploadCompanyCallSourceLogoRequest.prototype.fileName = "";

            /**
             * Creates a new UploadCompanyCallSourceLogoRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {streem.api.IUploadCompanyCallSourceLogoRequest=} [properties] Properties to set
             * @returns {streem.api.UploadCompanyCallSourceLogoRequest} UploadCompanyCallSourceLogoRequest instance
             */
            UploadCompanyCallSourceLogoRequest.create = function create(properties) {
                return new UploadCompanyCallSourceLogoRequest(properties);
            };

            /**
             * Encodes the specified UploadCompanyCallSourceLogoRequest message. Does not implicitly {@link streem.api.UploadCompanyCallSourceLogoRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {streem.api.IUploadCompanyCallSourceLogoRequest} m UploadCompanyCallSourceLogoRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UploadCompanyCallSourceLogoRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.httpBody != null && Object.hasOwnProperty.call(m, "httpBody"))
                    $root.google.api.HttpBody.encode(m.httpBody, w.uint32(10).fork()).ldelim();
                if (m.fileName != null && Object.hasOwnProperty.call(m, "fileName"))
                    w.uint32(18).string(m.fileName);
                return w;
            };

            /**
             * Decodes an UploadCompanyCallSourceLogoRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UploadCompanyCallSourceLogoRequest} UploadCompanyCallSourceLogoRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UploadCompanyCallSourceLogoRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UploadCompanyCallSourceLogoRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.httpBody = $root.google.api.HttpBody.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.fileName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UploadCompanyCallSourceLogoRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UploadCompanyCallSourceLogoRequest} UploadCompanyCallSourceLogoRequest
             */
            UploadCompanyCallSourceLogoRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UploadCompanyCallSourceLogoRequest)
                    return d;
                var m = new $root.streem.api.UploadCompanyCallSourceLogoRequest();
                if (d.httpBody != null) {
                    if (typeof d.httpBody !== "object")
                        throw TypeError(".streem.api.UploadCompanyCallSourceLogoRequest.httpBody: object expected");
                    m.httpBody = $root.google.api.HttpBody.fromObject(d.httpBody);
                }
                if (d.fileName != null) {
                    m.fileName = String(d.fileName);
                }
                return m;
            };

            /**
             * Creates a plain object from an UploadCompanyCallSourceLogoRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {streem.api.UploadCompanyCallSourceLogoRequest} m UploadCompanyCallSourceLogoRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UploadCompanyCallSourceLogoRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.httpBody = null;
                    d.fileName = "";
                }
                if (m.httpBody != null && m.hasOwnProperty("httpBody")) {
                    d.httpBody = $root.google.api.HttpBody.toObject(m.httpBody, o);
                }
                if (m.fileName != null && m.hasOwnProperty("fileName")) {
                    d.fileName = m.fileName;
                }
                return d;
            };

            /**
             * Converts this UploadCompanyCallSourceLogoRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UploadCompanyCallSourceLogoRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UploadCompanyCallSourceLogoRequest
             * @function getTypeUrl
             * @memberof streem.api.UploadCompanyCallSourceLogoRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UploadCompanyCallSourceLogoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UploadCompanyCallSourceLogoRequest";
            };

            return UploadCompanyCallSourceLogoRequest;
        })();

        api.UploadCompanyCallSourceLogoResponse = (function() {

            /**
             * Properties of an UploadCompanyCallSourceLogoResponse.
             * @memberof streem.api
             * @interface IUploadCompanyCallSourceLogoResponse
             * @property {streem.api.ICallSource|null} [callSource] UploadCompanyCallSourceLogoResponse callSource
             */

            /**
             * Constructs a new UploadCompanyCallSourceLogoResponse.
             * @memberof streem.api
             * @classdesc Represents an UploadCompanyCallSourceLogoResponse.
             * @implements IUploadCompanyCallSourceLogoResponse
             * @constructor
             * @param {streem.api.IUploadCompanyCallSourceLogoResponse=} [p] Properties to set
             */
            function UploadCompanyCallSourceLogoResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UploadCompanyCallSourceLogoResponse callSource.
             * @member {streem.api.ICallSource|null|undefined} callSource
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @instance
             */
            UploadCompanyCallSourceLogoResponse.prototype.callSource = null;

            /**
             * Creates a new UploadCompanyCallSourceLogoResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {streem.api.IUploadCompanyCallSourceLogoResponse=} [properties] Properties to set
             * @returns {streem.api.UploadCompanyCallSourceLogoResponse} UploadCompanyCallSourceLogoResponse instance
             */
            UploadCompanyCallSourceLogoResponse.create = function create(properties) {
                return new UploadCompanyCallSourceLogoResponse(properties);
            };

            /**
             * Encodes the specified UploadCompanyCallSourceLogoResponse message. Does not implicitly {@link streem.api.UploadCompanyCallSourceLogoResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {streem.api.IUploadCompanyCallSourceLogoResponse} m UploadCompanyCallSourceLogoResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UploadCompanyCallSourceLogoResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callSource != null && Object.hasOwnProperty.call(m, "callSource"))
                    $root.streem.api.CallSource.encode(m.callSource, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UploadCompanyCallSourceLogoResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UploadCompanyCallSourceLogoResponse} UploadCompanyCallSourceLogoResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UploadCompanyCallSourceLogoResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UploadCompanyCallSourceLogoResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callSource = $root.streem.api.CallSource.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UploadCompanyCallSourceLogoResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UploadCompanyCallSourceLogoResponse} UploadCompanyCallSourceLogoResponse
             */
            UploadCompanyCallSourceLogoResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UploadCompanyCallSourceLogoResponse)
                    return d;
                var m = new $root.streem.api.UploadCompanyCallSourceLogoResponse();
                if (d.callSource != null) {
                    if (typeof d.callSource !== "object")
                        throw TypeError(".streem.api.UploadCompanyCallSourceLogoResponse.callSource: object expected");
                    m.callSource = $root.streem.api.CallSource.fromObject(d.callSource);
                }
                return m;
            };

            /**
             * Creates a plain object from an UploadCompanyCallSourceLogoResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {streem.api.UploadCompanyCallSourceLogoResponse} m UploadCompanyCallSourceLogoResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UploadCompanyCallSourceLogoResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callSource = null;
                }
                if (m.callSource != null && m.hasOwnProperty("callSource")) {
                    d.callSource = $root.streem.api.CallSource.toObject(m.callSource, o);
                }
                return d;
            };

            /**
             * Converts this UploadCompanyCallSourceLogoResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UploadCompanyCallSourceLogoResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UploadCompanyCallSourceLogoResponse
             * @function getTypeUrl
             * @memberof streem.api.UploadCompanyCallSourceLogoResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UploadCompanyCallSourceLogoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UploadCompanyCallSourceLogoResponse";
            };

            return UploadCompanyCallSourceLogoResponse;
        })();

        api.UpdateCompanyCallSourceRequest = (function() {

            /**
             * Properties of an UpdateCompanyCallSourceRequest.
             * @memberof streem.api
             * @interface IUpdateCompanyCallSourceRequest
             * @property {string|null} [name] UpdateCompanyCallSourceRequest name
             * @property {number|null} [maxDiagnoses] UpdateCompanyCallSourceRequest maxDiagnoses
             * @property {boolean|null} [isDefault] UpdateCompanyCallSourceRequest isDefault
             * @property {streem.api.ICustomTheme|null} [customTheme] UpdateCompanyCallSourceRequest customTheme
             */

            /**
             * Constructs a new UpdateCompanyCallSourceRequest.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyCallSourceRequest.
             * @implements IUpdateCompanyCallSourceRequest
             * @constructor
             * @param {streem.api.IUpdateCompanyCallSourceRequest=} [p] Properties to set
             */
            function UpdateCompanyCallSourceRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyCallSourceRequest name.
             * @member {string|null|undefined} name
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            UpdateCompanyCallSourceRequest.prototype.name = null;

            /**
             * UpdateCompanyCallSourceRequest maxDiagnoses.
             * @member {number|null|undefined} maxDiagnoses
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            UpdateCompanyCallSourceRequest.prototype.maxDiagnoses = null;

            /**
             * UpdateCompanyCallSourceRequest isDefault.
             * @member {boolean|null|undefined} isDefault
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            UpdateCompanyCallSourceRequest.prototype.isDefault = null;

            /**
             * UpdateCompanyCallSourceRequest customTheme.
             * @member {streem.api.ICustomTheme|null|undefined} customTheme
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            UpdateCompanyCallSourceRequest.prototype.customTheme = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UpdateCompanyCallSourceRequest _name.
             * @member {"name"|undefined} _name
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(UpdateCompanyCallSourceRequest.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UpdateCompanyCallSourceRequest _maxDiagnoses.
             * @member {"maxDiagnoses"|undefined} _maxDiagnoses
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(UpdateCompanyCallSourceRequest.prototype, "_maxDiagnoses", {
                get: $util.oneOfGetter($oneOfFields = ["maxDiagnoses"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UpdateCompanyCallSourceRequest _isDefault.
             * @member {"isDefault"|undefined} _isDefault
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(UpdateCompanyCallSourceRequest.prototype, "_isDefault", {
                get: $util.oneOfGetter($oneOfFields = ["isDefault"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UpdateCompanyCallSourceRequest _customTheme.
             * @member {"customTheme"|undefined} _customTheme
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             */
            Object.defineProperty(UpdateCompanyCallSourceRequest.prototype, "_customTheme", {
                get: $util.oneOfGetter($oneOfFields = ["customTheme"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UpdateCompanyCallSourceRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {streem.api.IUpdateCompanyCallSourceRequest=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyCallSourceRequest} UpdateCompanyCallSourceRequest instance
             */
            UpdateCompanyCallSourceRequest.create = function create(properties) {
                return new UpdateCompanyCallSourceRequest(properties);
            };

            /**
             * Encodes the specified UpdateCompanyCallSourceRequest message. Does not implicitly {@link streem.api.UpdateCompanyCallSourceRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {streem.api.IUpdateCompanyCallSourceRequest} m UpdateCompanyCallSourceRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyCallSourceRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.maxDiagnoses != null && Object.hasOwnProperty.call(m, "maxDiagnoses"))
                    w.uint32(16).int32(m.maxDiagnoses);
                if (m.isDefault != null && Object.hasOwnProperty.call(m, "isDefault"))
                    w.uint32(24).bool(m.isDefault);
                if (m.customTheme != null && Object.hasOwnProperty.call(m, "customTheme"))
                    $root.streem.api.CustomTheme.encode(m.customTheme, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateCompanyCallSourceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyCallSourceRequest} UpdateCompanyCallSourceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyCallSourceRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyCallSourceRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.maxDiagnoses = r.int32();
                            break;
                        }
                    case 3: {
                            m.isDefault = r.bool();
                            break;
                        }
                    case 4: {
                            m.customTheme = $root.streem.api.CustomTheme.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyCallSourceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyCallSourceRequest} UpdateCompanyCallSourceRequest
             */
            UpdateCompanyCallSourceRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyCallSourceRequest)
                    return d;
                var m = new $root.streem.api.UpdateCompanyCallSourceRequest();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.maxDiagnoses != null) {
                    m.maxDiagnoses = d.maxDiagnoses | 0;
                }
                if (d.isDefault != null) {
                    m.isDefault = Boolean(d.isDefault);
                }
                if (d.customTheme != null) {
                    if (typeof d.customTheme !== "object")
                        throw TypeError(".streem.api.UpdateCompanyCallSourceRequest.customTheme: object expected");
                    m.customTheme = $root.streem.api.CustomTheme.fromObject(d.customTheme);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyCallSourceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {streem.api.UpdateCompanyCallSourceRequest} m UpdateCompanyCallSourceRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyCallSourceRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                    if (o.oneofs)
                        d._name = "name";
                }
                if (m.maxDiagnoses != null && m.hasOwnProperty("maxDiagnoses")) {
                    d.maxDiagnoses = m.maxDiagnoses;
                    if (o.oneofs)
                        d._maxDiagnoses = "maxDiagnoses";
                }
                if (m.isDefault != null && m.hasOwnProperty("isDefault")) {
                    d.isDefault = m.isDefault;
                    if (o.oneofs)
                        d._isDefault = "isDefault";
                }
                if (m.customTheme != null && m.hasOwnProperty("customTheme")) {
                    d.customTheme = $root.streem.api.CustomTheme.toObject(m.customTheme, o);
                    if (o.oneofs)
                        d._customTheme = "customTheme";
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyCallSourceRequest to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyCallSourceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyCallSourceRequest
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyCallSourceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyCallSourceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyCallSourceRequest";
            };

            return UpdateCompanyCallSourceRequest;
        })();

        api.UpdateCompanyCallSourceResponse = (function() {

            /**
             * Properties of an UpdateCompanyCallSourceResponse.
             * @memberof streem.api
             * @interface IUpdateCompanyCallSourceResponse
             * @property {streem.api.ICallSource|null} [callSource] UpdateCompanyCallSourceResponse callSource
             */

            /**
             * Constructs a new UpdateCompanyCallSourceResponse.
             * @memberof streem.api
             * @classdesc Represents an UpdateCompanyCallSourceResponse.
             * @implements IUpdateCompanyCallSourceResponse
             * @constructor
             * @param {streem.api.IUpdateCompanyCallSourceResponse=} [p] Properties to set
             */
            function UpdateCompanyCallSourceResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateCompanyCallSourceResponse callSource.
             * @member {streem.api.ICallSource|null|undefined} callSource
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @instance
             */
            UpdateCompanyCallSourceResponse.prototype.callSource = null;

            /**
             * Creates a new UpdateCompanyCallSourceResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {streem.api.IUpdateCompanyCallSourceResponse=} [properties] Properties to set
             * @returns {streem.api.UpdateCompanyCallSourceResponse} UpdateCompanyCallSourceResponse instance
             */
            UpdateCompanyCallSourceResponse.create = function create(properties) {
                return new UpdateCompanyCallSourceResponse(properties);
            };

            /**
             * Encodes the specified UpdateCompanyCallSourceResponse message. Does not implicitly {@link streem.api.UpdateCompanyCallSourceResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {streem.api.IUpdateCompanyCallSourceResponse} m UpdateCompanyCallSourceResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompanyCallSourceResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callSource != null && Object.hasOwnProperty.call(m, "callSource"))
                    $root.streem.api.CallSource.encode(m.callSource, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an UpdateCompanyCallSourceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.UpdateCompanyCallSourceResponse} UpdateCompanyCallSourceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompanyCallSourceResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.UpdateCompanyCallSourceResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callSource = $root.streem.api.CallSource.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateCompanyCallSourceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.UpdateCompanyCallSourceResponse} UpdateCompanyCallSourceResponse
             */
            UpdateCompanyCallSourceResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.UpdateCompanyCallSourceResponse)
                    return d;
                var m = new $root.streem.api.UpdateCompanyCallSourceResponse();
                if (d.callSource != null) {
                    if (typeof d.callSource !== "object")
                        throw TypeError(".streem.api.UpdateCompanyCallSourceResponse.callSource: object expected");
                    m.callSource = $root.streem.api.CallSource.fromObject(d.callSource);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateCompanyCallSourceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {streem.api.UpdateCompanyCallSourceResponse} m UpdateCompanyCallSourceResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompanyCallSourceResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callSource = null;
                }
                if (m.callSource != null && m.hasOwnProperty("callSource")) {
                    d.callSource = $root.streem.api.CallSource.toObject(m.callSource, o);
                }
                return d;
            };

            /**
             * Converts this UpdateCompanyCallSourceResponse to JSON.
             * @function toJSON
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompanyCallSourceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCompanyCallSourceResponse
             * @function getTypeUrl
             * @memberof streem.api.UpdateCompanyCallSourceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCompanyCallSourceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.UpdateCompanyCallSourceResponse";
            };

            return UpdateCompanyCallSourceResponse;
        })();

        api.Room = (function() {

            /**
             * Properties of a Room.
             * @memberof streem.api
             * @interface IRoom
             * @property {string|null} [sid] Room sid
             * @property {string|null} [createdByUserSid] Room createdByUserSid
             * @property {google.protobuf.ITimestamp|null} [createdAt] Room createdAt
             * @property {google.protobuf.ITimestamp|null} [openedAt] Room openedAt
             * @property {streem.api.VideoCodec|null} [preferredVideoCodec] Room preferredVideoCodec
             * @property {streem.api.Room.Status|null} [status] Room status
             * @property {google.protobuf.ITimestamp|null} [closedAt] Room closedAt
             * @property {string|null} [twilioRoomSid] Room twilioRoomSid
             * @property {string|null} [referenceId] Room referenceId
             * @property {Array.<streem.api.IRoomParticipant>|null} [participants] Room participants
             * @property {string|null} [companySid] Room companySid
             * @property {streem.api.VideoPlatform|null} [videoPlatform] Room videoPlatform
             * @property {boolean|null} [deleted] Room deleted
             * @property {google.protobuf.ITimestamp|null} [deletedAt] Room deletedAt
             * @property {boolean|null} [encryptedWallItems] Room encryptedWallItems
             * @property {streem.api.WallPlatform|null} [wallPlatform] Room wallPlatform
             * @property {string|null} [integrationId] Room integrationId
             * @property {Array.<streem.api.Room.IDetail>|null} [details] Room details
             * @property {string|null} [reservationSid] Room reservationSid
             * @property {Array.<streem.api.IRoomInvitation>|null} [roomInvitations] Room roomInvitations
             * @property {string|null} [chimeRoomSid] Room chimeRoomSid
             * @property {google.protobuf.ITimestamp|null} [recordingStartedAt] Room recordingStartedAt
             * @property {google.protobuf.IDuration|null} [callDuration] Room callDuration
             * @property {streem.api.ICallDetail|null} [callDetail] Room callDetail
             * @property {streem.api.IGroup|null} [reservationGroup] Room reservationGroup
             */

            /**
             * Constructs a new Room.
             * @memberof streem.api
             * @classdesc Represents a Room.
             * @implements IRoom
             * @constructor
             * @param {streem.api.IRoom=} [p] Properties to set
             */
            function Room(p) {
                this.participants = [];
                this.details = [];
                this.roomInvitations = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Room sid.
             * @member {string} sid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.sid = "";

            /**
             * Room createdByUserSid.
             * @member {string} createdByUserSid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.createdByUserSid = "";

            /**
             * Room createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.createdAt = null;

            /**
             * Room openedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} openedAt
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.openedAt = null;

            /**
             * Room preferredVideoCodec.
             * @member {streem.api.VideoCodec} preferredVideoCodec
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.preferredVideoCodec = 0;

            /**
             * Room status.
             * @member {streem.api.Room.Status} status
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.status = 0;

            /**
             * Room closedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} closedAt
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.closedAt = null;

            /**
             * Room twilioRoomSid.
             * @member {string} twilioRoomSid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.twilioRoomSid = "";

            /**
             * Room referenceId.
             * @member {string} referenceId
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.referenceId = "";

            /**
             * Room participants.
             * @member {Array.<streem.api.IRoomParticipant>} participants
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.participants = $util.emptyArray;

            /**
             * Room companySid.
             * @member {string} companySid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.companySid = "";

            /**
             * Room videoPlatform.
             * @member {streem.api.VideoPlatform} videoPlatform
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.videoPlatform = 0;

            /**
             * Room deleted.
             * @member {boolean} deleted
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.deleted = false;

            /**
             * Room deletedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.deletedAt = null;

            /**
             * Room encryptedWallItems.
             * @member {boolean} encryptedWallItems
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.encryptedWallItems = false;

            /**
             * Room wallPlatform.
             * @member {streem.api.WallPlatform} wallPlatform
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.wallPlatform = 0;

            /**
             * Room integrationId.
             * @member {string} integrationId
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.integrationId = "";

            /**
             * Room details.
             * @member {Array.<streem.api.Room.IDetail>} details
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.details = $util.emptyArray;

            /**
             * Room reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.reservationSid = "";

            /**
             * Room roomInvitations.
             * @member {Array.<streem.api.IRoomInvitation>} roomInvitations
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.roomInvitations = $util.emptyArray;

            /**
             * Room chimeRoomSid.
             * @member {string} chimeRoomSid
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.chimeRoomSid = "";

            /**
             * Room recordingStartedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} recordingStartedAt
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.recordingStartedAt = null;

            /**
             * Room callDuration.
             * @member {google.protobuf.IDuration|null|undefined} callDuration
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.callDuration = null;

            /**
             * Room callDetail.
             * @member {streem.api.ICallDetail|null|undefined} callDetail
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.callDetail = null;

            /**
             * Room reservationGroup.
             * @member {streem.api.IGroup|null|undefined} reservationGroup
             * @memberof streem.api.Room
             * @instance
             */
            Room.prototype.reservationGroup = null;

            /**
             * Creates a new Room instance using the specified properties.
             * @function create
             * @memberof streem.api.Room
             * @static
             * @param {streem.api.IRoom=} [properties] Properties to set
             * @returns {streem.api.Room} Room instance
             */
            Room.create = function create(properties) {
                return new Room(properties);
            };

            /**
             * Encodes the specified Room message. Does not implicitly {@link streem.api.Room.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Room
             * @static
             * @param {streem.api.IRoom} m Room message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Room.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.sid != null && Object.hasOwnProperty.call(m, "sid"))
                    w.uint32(10).string(m.sid);
                if (m.createdByUserSid != null && Object.hasOwnProperty.call(m, "createdByUserSid"))
                    w.uint32(18).string(m.createdByUserSid);
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(26).fork()).ldelim();
                if (m.openedAt != null && Object.hasOwnProperty.call(m, "openedAt"))
                    $root.google.protobuf.Timestamp.encode(m.openedAt, w.uint32(34).fork()).ldelim();
                if (m.preferredVideoCodec != null && Object.hasOwnProperty.call(m, "preferredVideoCodec"))
                    w.uint32(40).int32(m.preferredVideoCodec);
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(48).int32(m.status);
                if (m.closedAt != null && Object.hasOwnProperty.call(m, "closedAt"))
                    $root.google.protobuf.Timestamp.encode(m.closedAt, w.uint32(58).fork()).ldelim();
                if (m.twilioRoomSid != null && Object.hasOwnProperty.call(m, "twilioRoomSid"))
                    w.uint32(66).string(m.twilioRoomSid);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(74).string(m.referenceId);
                if (m.participants != null && m.participants.length) {
                    for (var i = 0; i < m.participants.length; ++i)
                        $root.streem.api.RoomParticipant.encode(m.participants[i], w.uint32(82).fork()).ldelim();
                }
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(90).string(m.companySid);
                if (m.videoPlatform != null && Object.hasOwnProperty.call(m, "videoPlatform"))
                    w.uint32(96).int32(m.videoPlatform);
                if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                    w.uint32(104).bool(m.deleted);
                if (m.deletedAt != null && Object.hasOwnProperty.call(m, "deletedAt"))
                    $root.google.protobuf.Timestamp.encode(m.deletedAt, w.uint32(114).fork()).ldelim();
                if (m.encryptedWallItems != null && Object.hasOwnProperty.call(m, "encryptedWallItems"))
                    w.uint32(120).bool(m.encryptedWallItems);
                if (m.wallPlatform != null && Object.hasOwnProperty.call(m, "wallPlatform"))
                    w.uint32(128).int32(m.wallPlatform);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(138).string(m.integrationId);
                if (m.details != null && m.details.length) {
                    for (var i = 0; i < m.details.length; ++i)
                        $root.streem.api.Room.Detail.encode(m.details[i], w.uint32(146).fork()).ldelim();
                }
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(154).string(m.reservationSid);
                if (m.roomInvitations != null && m.roomInvitations.length) {
                    for (var i = 0; i < m.roomInvitations.length; ++i)
                        $root.streem.api.RoomInvitation.encode(m.roomInvitations[i], w.uint32(162).fork()).ldelim();
                }
                if (m.chimeRoomSid != null && Object.hasOwnProperty.call(m, "chimeRoomSid"))
                    w.uint32(170).string(m.chimeRoomSid);
                if (m.recordingStartedAt != null && Object.hasOwnProperty.call(m, "recordingStartedAt"))
                    $root.google.protobuf.Timestamp.encode(m.recordingStartedAt, w.uint32(178).fork()).ldelim();
                if (m.callDuration != null && Object.hasOwnProperty.call(m, "callDuration"))
                    $root.google.protobuf.Duration.encode(m.callDuration, w.uint32(186).fork()).ldelim();
                if (m.callDetail != null && Object.hasOwnProperty.call(m, "callDetail"))
                    $root.streem.api.CallDetail.encode(m.callDetail, w.uint32(194).fork()).ldelim();
                if (m.reservationGroup != null && Object.hasOwnProperty.call(m, "reservationGroup"))
                    $root.streem.api.Group.encode(m.reservationGroup, w.uint32(202).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a Room message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Room
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Room} Room
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Room.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Room();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.sid = r.string();
                            break;
                        }
                    case 2: {
                            m.createdByUserSid = r.string();
                            break;
                        }
                    case 3: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.openedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.preferredVideoCodec = r.int32();
                            break;
                        }
                    case 6: {
                            m.status = r.int32();
                            break;
                        }
                    case 7: {
                            m.closedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            m.twilioRoomSid = r.string();
                            break;
                        }
                    case 9: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 10: {
                            if (!(m.participants && m.participants.length))
                                m.participants = [];
                            m.participants.push($root.streem.api.RoomParticipant.decode(r, r.uint32()));
                            break;
                        }
                    case 11: {
                            m.companySid = r.string();
                            break;
                        }
                    case 12: {
                            m.videoPlatform = r.int32();
                            break;
                        }
                    case 13: {
                            m.deleted = r.bool();
                            break;
                        }
                    case 14: {
                            m.deletedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 15: {
                            m.encryptedWallItems = r.bool();
                            break;
                        }
                    case 16: {
                            m.wallPlatform = r.int32();
                            break;
                        }
                    case 17: {
                            m.integrationId = r.string();
                            break;
                        }
                    case 18: {
                            if (!(m.details && m.details.length))
                                m.details = [];
                            m.details.push($root.streem.api.Room.Detail.decode(r, r.uint32()));
                            break;
                        }
                    case 19: {
                            m.reservationSid = r.string();
                            break;
                        }
                    case 20: {
                            if (!(m.roomInvitations && m.roomInvitations.length))
                                m.roomInvitations = [];
                            m.roomInvitations.push($root.streem.api.RoomInvitation.decode(r, r.uint32()));
                            break;
                        }
                    case 21: {
                            m.chimeRoomSid = r.string();
                            break;
                        }
                    case 22: {
                            m.recordingStartedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 23: {
                            m.callDuration = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 24: {
                            m.callDetail = $root.streem.api.CallDetail.decode(r, r.uint32());
                            break;
                        }
                    case 25: {
                            m.reservationGroup = $root.streem.api.Group.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Room message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Room
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Room} Room
             */
            Room.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Room)
                    return d;
                var m = new $root.streem.api.Room();
                if (d.sid != null) {
                    m.sid = String(d.sid);
                }
                if (d.createdByUserSid != null) {
                    m.createdByUserSid = String(d.createdByUserSid);
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.Room.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                if (d.openedAt != null) {
                    if (typeof d.openedAt !== "object")
                        throw TypeError(".streem.api.Room.openedAt: object expected");
                    m.openedAt = $root.google.protobuf.Timestamp.fromObject(d.openedAt);
                }
                switch (d.preferredVideoCodec) {
                default:
                    if (typeof d.preferredVideoCodec === "number") {
                        m.preferredVideoCodec = d.preferredVideoCodec;
                        break;
                    }
                    break;
                case "VIDEO_CODEC_INVALID":
                case 0:
                    m.preferredVideoCodec = 0;
                    break;
                case "VIDEO_CODEC_H264":
                case 1:
                    m.preferredVideoCodec = 1;
                    break;
                case "VIDEO_CODEC_VP8":
                case 2:
                    m.preferredVideoCodec = 2;
                    break;
                case "VIDEO_CODEC_VP9":
                case 3:
                    m.preferredVideoCodec = 3;
                    break;
                }
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_OPEN":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_CLOSED":
                case 2:
                    m.status = 2;
                    break;
                }
                if (d.closedAt != null) {
                    if (typeof d.closedAt !== "object")
                        throw TypeError(".streem.api.Room.closedAt: object expected");
                    m.closedAt = $root.google.protobuf.Timestamp.fromObject(d.closedAt);
                }
                if (d.twilioRoomSid != null) {
                    m.twilioRoomSid = String(d.twilioRoomSid);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.participants) {
                    if (!Array.isArray(d.participants))
                        throw TypeError(".streem.api.Room.participants: array expected");
                    m.participants = [];
                    for (var i = 0; i < d.participants.length; ++i) {
                        if (typeof d.participants[i] !== "object")
                            throw TypeError(".streem.api.Room.participants: object expected");
                        m.participants[i] = $root.streem.api.RoomParticipant.fromObject(d.participants[i]);
                    }
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                switch (d.videoPlatform) {
                default:
                    if (typeof d.videoPlatform === "number") {
                        m.videoPlatform = d.videoPlatform;
                        break;
                    }
                    break;
                case "VIDEO_PLATFORM_INVALID":
                case 0:
                    m.videoPlatform = 0;
                    break;
                case "VIDEO_PLATFORM_TWILIO":
                case 1:
                    m.videoPlatform = 1;
                    break;
                case "VIDEO_PLATFORM_ARAAS":
                case 2:
                    m.videoPlatform = 2;
                    break;
                case "VIDEO_PLATFORM_CHIME":
                case 3:
                    m.videoPlatform = 3;
                    break;
                }
                if (d.deleted != null) {
                    m.deleted = Boolean(d.deleted);
                }
                if (d.deletedAt != null) {
                    if (typeof d.deletedAt !== "object")
                        throw TypeError(".streem.api.Room.deletedAt: object expected");
                    m.deletedAt = $root.google.protobuf.Timestamp.fromObject(d.deletedAt);
                }
                if (d.encryptedWallItems != null) {
                    m.encryptedWallItems = Boolean(d.encryptedWallItems);
                }
                switch (d.wallPlatform) {
                default:
                    if (typeof d.wallPlatform === "number") {
                        m.wallPlatform = d.wallPlatform;
                        break;
                    }
                    break;
                case "WALL_PLATFORM_INVALID":
                case 0:
                    m.wallPlatform = 0;
                    break;
                case "WALL_PLATFORM_TWILIO":
                case 1:
                    m.wallPlatform = 1;
                    break;
                case "WALL_PLATFORM_STREEM":
                case 2:
                    m.wallPlatform = 2;
                    break;
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                if (d.details) {
                    if (!Array.isArray(d.details))
                        throw TypeError(".streem.api.Room.details: array expected");
                    m.details = [];
                    for (var i = 0; i < d.details.length; ++i) {
                        if (typeof d.details[i] !== "object")
                            throw TypeError(".streem.api.Room.details: object expected");
                        m.details[i] = $root.streem.api.Room.Detail.fromObject(d.details[i]);
                    }
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                if (d.roomInvitations) {
                    if (!Array.isArray(d.roomInvitations))
                        throw TypeError(".streem.api.Room.roomInvitations: array expected");
                    m.roomInvitations = [];
                    for (var i = 0; i < d.roomInvitations.length; ++i) {
                        if (typeof d.roomInvitations[i] !== "object")
                            throw TypeError(".streem.api.Room.roomInvitations: object expected");
                        m.roomInvitations[i] = $root.streem.api.RoomInvitation.fromObject(d.roomInvitations[i]);
                    }
                }
                if (d.chimeRoomSid != null) {
                    m.chimeRoomSid = String(d.chimeRoomSid);
                }
                if (d.recordingStartedAt != null) {
                    if (typeof d.recordingStartedAt !== "object")
                        throw TypeError(".streem.api.Room.recordingStartedAt: object expected");
                    m.recordingStartedAt = $root.google.protobuf.Timestamp.fromObject(d.recordingStartedAt);
                }
                if (d.callDuration != null) {
                    if (typeof d.callDuration !== "object")
                        throw TypeError(".streem.api.Room.callDuration: object expected");
                    m.callDuration = $root.google.protobuf.Duration.fromObject(d.callDuration);
                }
                if (d.callDetail != null) {
                    if (typeof d.callDetail !== "object")
                        throw TypeError(".streem.api.Room.callDetail: object expected");
                    m.callDetail = $root.streem.api.CallDetail.fromObject(d.callDetail);
                }
                if (d.reservationGroup != null) {
                    if (typeof d.reservationGroup !== "object")
                        throw TypeError(".streem.api.Room.reservationGroup: object expected");
                    m.reservationGroup = $root.streem.api.Group.fromObject(d.reservationGroup);
                }
                return m;
            };

            /**
             * Creates a plain object from a Room message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Room
             * @static
             * @param {streem.api.Room} m Room
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Room.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.participants = [];
                    d.details = [];
                    d.roomInvitations = [];
                }
                if (o.defaults) {
                    d.sid = "";
                    d.createdByUserSid = "";
                    d.createdAt = null;
                    d.openedAt = null;
                    d.preferredVideoCodec = o.enums === String ? "VIDEO_CODEC_INVALID" : 0;
                    d.status = o.enums === String ? "STATUS_INVALID" : 0;
                    d.closedAt = null;
                    d.twilioRoomSid = "";
                    d.referenceId = "";
                    d.companySid = "";
                    d.videoPlatform = o.enums === String ? "VIDEO_PLATFORM_INVALID" : 0;
                    d.deleted = false;
                    d.deletedAt = null;
                    d.encryptedWallItems = false;
                    d.wallPlatform = o.enums === String ? "WALL_PLATFORM_INVALID" : 0;
                    d.integrationId = "";
                    d.reservationSid = "";
                    d.chimeRoomSid = "";
                    d.recordingStartedAt = null;
                    d.callDuration = null;
                    d.callDetail = null;
                    d.reservationGroup = null;
                }
                if (m.sid != null && m.hasOwnProperty("sid")) {
                    d.sid = m.sid;
                }
                if (m.createdByUserSid != null && m.hasOwnProperty("createdByUserSid")) {
                    d.createdByUserSid = m.createdByUserSid;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.openedAt != null && m.hasOwnProperty("openedAt")) {
                    d.openedAt = $root.google.protobuf.Timestamp.toObject(m.openedAt, o);
                }
                if (m.preferredVideoCodec != null && m.hasOwnProperty("preferredVideoCodec")) {
                    d.preferredVideoCodec = o.enums === String ? $root.streem.api.VideoCodec[m.preferredVideoCodec] === undefined ? m.preferredVideoCodec : $root.streem.api.VideoCodec[m.preferredVideoCodec] : m.preferredVideoCodec;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.Room.Status[m.status] === undefined ? m.status : $root.streem.api.Room.Status[m.status] : m.status;
                }
                if (m.closedAt != null && m.hasOwnProperty("closedAt")) {
                    d.closedAt = $root.google.protobuf.Timestamp.toObject(m.closedAt, o);
                }
                if (m.twilioRoomSid != null && m.hasOwnProperty("twilioRoomSid")) {
                    d.twilioRoomSid = m.twilioRoomSid;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.participants && m.participants.length) {
                    d.participants = [];
                    for (var j = 0; j < m.participants.length; ++j) {
                        d.participants[j] = $root.streem.api.RoomParticipant.toObject(m.participants[j], o);
                    }
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.videoPlatform != null && m.hasOwnProperty("videoPlatform")) {
                    d.videoPlatform = o.enums === String ? $root.streem.api.VideoPlatform[m.videoPlatform] === undefined ? m.videoPlatform : $root.streem.api.VideoPlatform[m.videoPlatform] : m.videoPlatform;
                }
                if (m.deleted != null && m.hasOwnProperty("deleted")) {
                    d.deleted = m.deleted;
                }
                if (m.deletedAt != null && m.hasOwnProperty("deletedAt")) {
                    d.deletedAt = $root.google.protobuf.Timestamp.toObject(m.deletedAt, o);
                }
                if (m.encryptedWallItems != null && m.hasOwnProperty("encryptedWallItems")) {
                    d.encryptedWallItems = m.encryptedWallItems;
                }
                if (m.wallPlatform != null && m.hasOwnProperty("wallPlatform")) {
                    d.wallPlatform = o.enums === String ? $root.streem.api.WallPlatform[m.wallPlatform] === undefined ? m.wallPlatform : $root.streem.api.WallPlatform[m.wallPlatform] : m.wallPlatform;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                if (m.details && m.details.length) {
                    d.details = [];
                    for (var j = 0; j < m.details.length; ++j) {
                        d.details[j] = $root.streem.api.Room.Detail.toObject(m.details[j], o);
                    }
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                if (m.roomInvitations && m.roomInvitations.length) {
                    d.roomInvitations = [];
                    for (var j = 0; j < m.roomInvitations.length; ++j) {
                        d.roomInvitations[j] = $root.streem.api.RoomInvitation.toObject(m.roomInvitations[j], o);
                    }
                }
                if (m.chimeRoomSid != null && m.hasOwnProperty("chimeRoomSid")) {
                    d.chimeRoomSid = m.chimeRoomSid;
                }
                if (m.recordingStartedAt != null && m.hasOwnProperty("recordingStartedAt")) {
                    d.recordingStartedAt = $root.google.protobuf.Timestamp.toObject(m.recordingStartedAt, o);
                }
                if (m.callDuration != null && m.hasOwnProperty("callDuration")) {
                    d.callDuration = $root.google.protobuf.Duration.toObject(m.callDuration, o);
                }
                if (m.callDetail != null && m.hasOwnProperty("callDetail")) {
                    d.callDetail = $root.streem.api.CallDetail.toObject(m.callDetail, o);
                }
                if (m.reservationGroup != null && m.hasOwnProperty("reservationGroup")) {
                    d.reservationGroup = $root.streem.api.Group.toObject(m.reservationGroup, o);
                }
                return d;
            };

            /**
             * Converts this Room to JSON.
             * @function toJSON
             * @memberof streem.api.Room
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Room.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Room
             * @function getTypeUrl
             * @memberof streem.api.Room
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Room.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Room";
            };

            /**
             * Status enum.
             * @name streem.api.Room.Status
             * @enum {number}
             * @property {number} STATUS_INVALID=0 STATUS_INVALID value
             * @property {number} STATUS_OPEN=1 STATUS_OPEN value
             * @property {number} STATUS_CLOSED=2 STATUS_CLOSED value
             */
            Room.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_INVALID"] = 0;
                values[valuesById[1] = "STATUS_OPEN"] = 1;
                values[valuesById[2] = "STATUS_CLOSED"] = 2;
                return values;
            })();

            Room.Detail = (function() {

                /**
                 * Properties of a Detail.
                 * @memberof streem.api.Room
                 * @interface IDetail
                 * @property {string|null} [label] Detail label
                 * @property {string|null} [value] Detail value
                 */

                /**
                 * Constructs a new Detail.
                 * @memberof streem.api.Room
                 * @classdesc Represents a Detail.
                 * @implements IDetail
                 * @constructor
                 * @param {streem.api.Room.IDetail=} [p] Properties to set
                 */
                function Detail(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Detail label.
                 * @member {string} label
                 * @memberof streem.api.Room.Detail
                 * @instance
                 */
                Detail.prototype.label = "";

                /**
                 * Detail value.
                 * @member {string} value
                 * @memberof streem.api.Room.Detail
                 * @instance
                 */
                Detail.prototype.value = "";

                /**
                 * Creates a new Detail instance using the specified properties.
                 * @function create
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {streem.api.Room.IDetail=} [properties] Properties to set
                 * @returns {streem.api.Room.Detail} Detail instance
                 */
                Detail.create = function create(properties) {
                    return new Detail(properties);
                };

                /**
                 * Encodes the specified Detail message. Does not implicitly {@link streem.api.Room.Detail.verify|verify} messages.
                 * @function encode
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {streem.api.Room.IDetail} m Detail message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Detail.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                        w.uint32(10).string(m.label);
                    if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                        w.uint32(18).string(m.value);
                    return w;
                };

                /**
                 * Decodes a Detail message from the specified reader or buffer.
                 * @function decode
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {streem.api.Room.Detail} Detail
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Detail.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Room.Detail();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.label = r.string();
                                break;
                            }
                        case 2: {
                                m.value = r.string();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Detail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {streem.api.Room.Detail} Detail
                 */
                Detail.fromObject = function fromObject(d) {
                    if (d instanceof $root.streem.api.Room.Detail)
                        return d;
                    var m = new $root.streem.api.Room.Detail();
                    if (d.label != null) {
                        m.label = String(d.label);
                    }
                    if (d.value != null) {
                        m.value = String(d.value);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Detail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {streem.api.Room.Detail} m Detail
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Detail.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.label = "";
                        d.value = "";
                    }
                    if (m.label != null && m.hasOwnProperty("label")) {
                        d.label = m.label;
                    }
                    if (m.value != null && m.hasOwnProperty("value")) {
                        d.value = m.value;
                    }
                    return d;
                };

                /**
                 * Converts this Detail to JSON.
                 * @function toJSON
                 * @memberof streem.api.Room.Detail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Detail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Detail
                 * @function getTypeUrl
                 * @memberof streem.api.Room.Detail
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Detail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/streem.api.Room.Detail";
                };

                return Detail;
            })();

            return Room;
        })();

        api.RoomParticipant = (function() {

            /**
             * Properties of a RoomParticipant.
             * @memberof streem.api
             * @interface IRoomParticipant
             * @property {string|null} [roomSid] RoomParticipant roomSid
             * @property {string|null} [userSid] RoomParticipant userSid
             * @property {streem.api.RoomParticipant.Status|null} [status] RoomParticipant status
             */

            /**
             * Constructs a new RoomParticipant.
             * @memberof streem.api
             * @classdesc State diagram:
             * 
             * @startuml
             * [*] --> PENDING : Initial State
             * PENDING --> CONNECTED : Heartbeat received from participant
             * CONNECTED --> ACTIVE : Audio or Video Track received from participant
             * PENDING --> NEVER_ARRIVED : tbd
             * ACTIVE --> LEFT : tbd
             * ACTIVE --> RECONNECTING : tbd
             * RECONNECTING --> ACTIVE : tbd
             * RECONNECTING --> NEVER_ARRIVED : tbd
             * @enduml
             * @implements IRoomParticipant
             * @constructor
             * @param {streem.api.IRoomParticipant=} [p] Properties to set
             */
            function RoomParticipant(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RoomParticipant roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RoomParticipant
             * @instance
             */
            RoomParticipant.prototype.roomSid = "";

            /**
             * RoomParticipant userSid.
             * @member {string} userSid
             * @memberof streem.api.RoomParticipant
             * @instance
             */
            RoomParticipant.prototype.userSid = "";

            /**
             * RoomParticipant status.
             * @member {streem.api.RoomParticipant.Status} status
             * @memberof streem.api.RoomParticipant
             * @instance
             */
            RoomParticipant.prototype.status = 0;

            /**
             * Creates a new RoomParticipant instance using the specified properties.
             * @function create
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {streem.api.IRoomParticipant=} [properties] Properties to set
             * @returns {streem.api.RoomParticipant} RoomParticipant instance
             */
            RoomParticipant.create = function create(properties) {
                return new RoomParticipant(properties);
            };

            /**
             * Encodes the specified RoomParticipant message. Does not implicitly {@link streem.api.RoomParticipant.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {streem.api.IRoomParticipant} m RoomParticipant message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomParticipant.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(18).string(m.userSid);
                if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                    w.uint32(24).int32(m.status);
                return w;
            };

            /**
             * Decodes a RoomParticipant message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RoomParticipant} RoomParticipant
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomParticipant.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RoomParticipant();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.userSid = r.string();
                            break;
                        }
                    case 3: {
                            m.status = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RoomParticipant message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RoomParticipant} RoomParticipant
             */
            RoomParticipant.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RoomParticipant)
                    return d;
                var m = new $root.streem.api.RoomParticipant();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                switch (d.status) {
                default:
                    if (typeof d.status === "number") {
                        m.status = d.status;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.status = 0;
                    break;
                case "STATUS_PENDING":
                case 1:
                    m.status = 1;
                    break;
                case "STATUS_ACTIVE":
                case 2:
                    m.status = 2;
                    break;
                case "STATUS_LEFT":
                case 3:
                    m.status = 3;
                    break;
                case "STATUS_NEVER_ARRIVED":
                case 5:
                    m.status = 5;
                    break;
                case "STATUS_RECONNECTING":
                case 6:
                    m.status = 6;
                    break;
                case "STATUS_CONNECTED":
                case 7:
                    m.status = 7;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a RoomParticipant message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {streem.api.RoomParticipant} m RoomParticipant
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomParticipant.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.userSid = "";
                    d.status = o.enums === String ? "STATUS_INVALID" : 0;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = o.enums === String ? $root.streem.api.RoomParticipant.Status[m.status] === undefined ? m.status : $root.streem.api.RoomParticipant.Status[m.status] : m.status;
                }
                return d;
            };

            /**
             * Converts this RoomParticipant to JSON.
             * @function toJSON
             * @memberof streem.api.RoomParticipant
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomParticipant.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoomParticipant
             * @function getTypeUrl
             * @memberof streem.api.RoomParticipant
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RoomParticipant";
            };

            /**
             * Status enum.
             * @name streem.api.RoomParticipant.Status
             * @enum {number}
             * @property {number} STATUS_INVALID=0 STATUS_INVALID value
             * @property {number} STATUS_PENDING=1 STATUS_PENDING value
             * @property {number} STATUS_ACTIVE=2 STATUS_ACTIVE value
             * @property {number} STATUS_LEFT=3 STATUS_LEFT value
             * @property {number} STATUS_NEVER_ARRIVED=5 STATUS_NEVER_ARRIVED value
             * @property {number} STATUS_RECONNECTING=6 STATUS_RECONNECTING value
             * @property {number} STATUS_CONNECTED=7 STATUS_CONNECTED value
             */
            RoomParticipant.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_INVALID"] = 0;
                values[valuesById[1] = "STATUS_PENDING"] = 1;
                values[valuesById[2] = "STATUS_ACTIVE"] = 2;
                values[valuesById[3] = "STATUS_LEFT"] = 3;
                values[valuesById[5] = "STATUS_NEVER_ARRIVED"] = 5;
                values[valuesById[6] = "STATUS_RECONNECTING"] = 6;
                values[valuesById[7] = "STATUS_CONNECTED"] = 7;
                return values;
            })();

            return RoomParticipant;
        })();

        api.StreemRoomCredentials = (function() {

            /**
             * Properties of a StreemRoomCredentials.
             * @memberof streem.api
             * @interface IStreemRoomCredentials
             * @property {string|null} [roomSid] StreemRoomCredentials roomSid
             * @property {string|null} [token] StreemRoomCredentials token
             * @property {streem.api.IPreflight|null} [preflight] StreemRoomCredentials preflight
             * @property {Uint8Array|null} [dataKey] StreemRoomCredentials dataKey
             */

            /**
             * Constructs a new StreemRoomCredentials.
             * @memberof streem.api
             * @classdesc Represents a StreemRoomCredentials.
             * @implements IStreemRoomCredentials
             * @constructor
             * @param {streem.api.IStreemRoomCredentials=} [p] Properties to set
             */
            function StreemRoomCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemRoomCredentials roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StreemRoomCredentials
             * @instance
             */
            StreemRoomCredentials.prototype.roomSid = "";

            /**
             * StreemRoomCredentials token.
             * @member {string} token
             * @memberof streem.api.StreemRoomCredentials
             * @instance
             */
            StreemRoomCredentials.prototype.token = "";

            /**
             * StreemRoomCredentials preflight.
             * @member {streem.api.IPreflight|null|undefined} preflight
             * @memberof streem.api.StreemRoomCredentials
             * @instance
             */
            StreemRoomCredentials.prototype.preflight = null;

            /**
             * StreemRoomCredentials dataKey.
             * @member {Uint8Array} dataKey
             * @memberof streem.api.StreemRoomCredentials
             * @instance
             */
            StreemRoomCredentials.prototype.dataKey = $util.newBuffer([]);

            /**
             * Creates a new StreemRoomCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {streem.api.IStreemRoomCredentials=} [properties] Properties to set
             * @returns {streem.api.StreemRoomCredentials} StreemRoomCredentials instance
             */
            StreemRoomCredentials.create = function create(properties) {
                return new StreemRoomCredentials(properties);
            };

            /**
             * Encodes the specified StreemRoomCredentials message. Does not implicitly {@link streem.api.StreemRoomCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {streem.api.IStreemRoomCredentials} m StreemRoomCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemRoomCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                    w.uint32(18).string(m.token);
                if (m.preflight != null && Object.hasOwnProperty.call(m, "preflight"))
                    $root.streem.api.Preflight.encode(m.preflight, w.uint32(26).fork()).ldelim();
                if (m.dataKey != null && Object.hasOwnProperty.call(m, "dataKey"))
                    w.uint32(34).bytes(m.dataKey);
                return w;
            };

            /**
             * Decodes a StreemRoomCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemRoomCredentials} StreemRoomCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemRoomCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemRoomCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.token = r.string();
                            break;
                        }
                    case 3: {
                            m.preflight = $root.streem.api.Preflight.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.dataKey = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemRoomCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemRoomCredentials} StreemRoomCredentials
             */
            StreemRoomCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemRoomCredentials)
                    return d;
                var m = new $root.streem.api.StreemRoomCredentials();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.token != null) {
                    m.token = String(d.token);
                }
                if (d.preflight != null) {
                    if (typeof d.preflight !== "object")
                        throw TypeError(".streem.api.StreemRoomCredentials.preflight: object expected");
                    m.preflight = $root.streem.api.Preflight.fromObject(d.preflight);
                }
                if (d.dataKey != null) {
                    if (typeof d.dataKey === "string")
                        $util.base64.decode(d.dataKey, m.dataKey = $util.newBuffer($util.base64.length(d.dataKey)), 0);
                    else if (d.dataKey.length >= 0)
                        m.dataKey = d.dataKey;
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemRoomCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {streem.api.StreemRoomCredentials} m StreemRoomCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemRoomCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.token = "";
                    d.preflight = null;
                    if (o.bytes === String)
                        d.dataKey = "";
                    else {
                        d.dataKey = [];
                        if (o.bytes !== Array)
                            d.dataKey = $util.newBuffer(d.dataKey);
                    }
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.token != null && m.hasOwnProperty("token")) {
                    d.token = m.token;
                }
                if (m.preflight != null && m.hasOwnProperty("preflight")) {
                    d.preflight = $root.streem.api.Preflight.toObject(m.preflight, o);
                }
                if (m.dataKey != null && m.hasOwnProperty("dataKey")) {
                    d.dataKey = o.bytes === String ? $util.base64.encode(m.dataKey, 0, m.dataKey.length) : o.bytes === Array ? Array.prototype.slice.call(m.dataKey) : m.dataKey;
                }
                return d;
            };

            /**
             * Converts this StreemRoomCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.StreemRoomCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemRoomCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemRoomCredentials
             * @function getTypeUrl
             * @memberof streem.api.StreemRoomCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemRoomCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemRoomCredentials";
            };

            return StreemRoomCredentials;
        })();

        api.Preflight = (function() {

            /**
             * Properties of a Preflight.
             * @memberof streem.api
             * @interface IPreflight
             * @property {string|null} [testRoomId] Preflight testRoomId
             * @property {string|null} [publisherToken] Preflight publisherToken
             * @property {string|null} [subscriberToken] Preflight subscriberToken
             */

            /**
             * Constructs a new Preflight.
             * @memberof streem.api
             * @classdesc Represents a Preflight.
             * @implements IPreflight
             * @constructor
             * @param {streem.api.IPreflight=} [p] Properties to set
             */
            function Preflight(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Preflight testRoomId.
             * @member {string} testRoomId
             * @memberof streem.api.Preflight
             * @instance
             */
            Preflight.prototype.testRoomId = "";

            /**
             * Preflight publisherToken.
             * @member {string} publisherToken
             * @memberof streem.api.Preflight
             * @instance
             */
            Preflight.prototype.publisherToken = "";

            /**
             * Preflight subscriberToken.
             * @member {string} subscriberToken
             * @memberof streem.api.Preflight
             * @instance
             */
            Preflight.prototype.subscriberToken = "";

            /**
             * Creates a new Preflight instance using the specified properties.
             * @function create
             * @memberof streem.api.Preflight
             * @static
             * @param {streem.api.IPreflight=} [properties] Properties to set
             * @returns {streem.api.Preflight} Preflight instance
             */
            Preflight.create = function create(properties) {
                return new Preflight(properties);
            };

            /**
             * Encodes the specified Preflight message. Does not implicitly {@link streem.api.Preflight.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Preflight
             * @static
             * @param {streem.api.IPreflight} m Preflight message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Preflight.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.testRoomId != null && Object.hasOwnProperty.call(m, "testRoomId"))
                    w.uint32(10).string(m.testRoomId);
                if (m.publisherToken != null && Object.hasOwnProperty.call(m, "publisherToken"))
                    w.uint32(18).string(m.publisherToken);
                if (m.subscriberToken != null && Object.hasOwnProperty.call(m, "subscriberToken"))
                    w.uint32(26).string(m.subscriberToken);
                return w;
            };

            /**
             * Decodes a Preflight message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Preflight
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Preflight} Preflight
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Preflight.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Preflight();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.testRoomId = r.string();
                            break;
                        }
                    case 2: {
                            m.publisherToken = r.string();
                            break;
                        }
                    case 3: {
                            m.subscriberToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Preflight message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Preflight
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Preflight} Preflight
             */
            Preflight.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Preflight)
                    return d;
                var m = new $root.streem.api.Preflight();
                if (d.testRoomId != null) {
                    m.testRoomId = String(d.testRoomId);
                }
                if (d.publisherToken != null) {
                    m.publisherToken = String(d.publisherToken);
                }
                if (d.subscriberToken != null) {
                    m.subscriberToken = String(d.subscriberToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a Preflight message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Preflight
             * @static
             * @param {streem.api.Preflight} m Preflight
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Preflight.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.testRoomId = "";
                    d.publisherToken = "";
                    d.subscriberToken = "";
                }
                if (m.testRoomId != null && m.hasOwnProperty("testRoomId")) {
                    d.testRoomId = m.testRoomId;
                }
                if (m.publisherToken != null && m.hasOwnProperty("publisherToken")) {
                    d.publisherToken = m.publisherToken;
                }
                if (m.subscriberToken != null && m.hasOwnProperty("subscriberToken")) {
                    d.subscriberToken = m.subscriberToken;
                }
                return d;
            };

            /**
             * Converts this Preflight to JSON.
             * @function toJSON
             * @memberof streem.api.Preflight
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Preflight.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Preflight
             * @function getTypeUrl
             * @memberof streem.api.Preflight
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Preflight.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Preflight";
            };

            return Preflight;
        })();

        api.StreemRoomWallCredentials = (function() {

            /**
             * Properties of a StreemRoomWallCredentials.
             * @memberof streem.api
             * @interface IStreemRoomWallCredentials
             * @property {string|null} [roomSid] StreemRoomWallCredentials roomSid
             * @property {string|null} [token] StreemRoomWallCredentials token
             * @property {Uint8Array|null} [dataKey] StreemRoomWallCredentials dataKey
             */

            /**
             * Constructs a new StreemRoomWallCredentials.
             * @memberof streem.api
             * @classdesc Represents a StreemRoomWallCredentials.
             * @implements IStreemRoomWallCredentials
             * @constructor
             * @param {streem.api.IStreemRoomWallCredentials=} [p] Properties to set
             */
            function StreemRoomWallCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StreemRoomWallCredentials roomSid.
             * @member {string} roomSid
             * @memberof streem.api.StreemRoomWallCredentials
             * @instance
             */
            StreemRoomWallCredentials.prototype.roomSid = "";

            /**
             * StreemRoomWallCredentials token.
             * @member {string} token
             * @memberof streem.api.StreemRoomWallCredentials
             * @instance
             */
            StreemRoomWallCredentials.prototype.token = "";

            /**
             * StreemRoomWallCredentials dataKey.
             * @member {Uint8Array} dataKey
             * @memberof streem.api.StreemRoomWallCredentials
             * @instance
             */
            StreemRoomWallCredentials.prototype.dataKey = $util.newBuffer([]);

            /**
             * Creates a new StreemRoomWallCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {streem.api.IStreemRoomWallCredentials=} [properties] Properties to set
             * @returns {streem.api.StreemRoomWallCredentials} StreemRoomWallCredentials instance
             */
            StreemRoomWallCredentials.create = function create(properties) {
                return new StreemRoomWallCredentials(properties);
            };

            /**
             * Encodes the specified StreemRoomWallCredentials message. Does not implicitly {@link streem.api.StreemRoomWallCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {streem.api.IStreemRoomWallCredentials} m StreemRoomWallCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreemRoomWallCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                    w.uint32(18).string(m.token);
                if (m.dataKey != null && Object.hasOwnProperty.call(m, "dataKey"))
                    w.uint32(26).bytes(m.dataKey);
                return w;
            };

            /**
             * Decodes a StreemRoomWallCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.StreemRoomWallCredentials} StreemRoomWallCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreemRoomWallCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.StreemRoomWallCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.token = r.string();
                            break;
                        }
                    case 3: {
                            m.dataKey = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StreemRoomWallCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.StreemRoomWallCredentials} StreemRoomWallCredentials
             */
            StreemRoomWallCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.StreemRoomWallCredentials)
                    return d;
                var m = new $root.streem.api.StreemRoomWallCredentials();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.token != null) {
                    m.token = String(d.token);
                }
                if (d.dataKey != null) {
                    if (typeof d.dataKey === "string")
                        $util.base64.decode(d.dataKey, m.dataKey = $util.newBuffer($util.base64.length(d.dataKey)), 0);
                    else if (d.dataKey.length >= 0)
                        m.dataKey = d.dataKey;
                }
                return m;
            };

            /**
             * Creates a plain object from a StreemRoomWallCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {streem.api.StreemRoomWallCredentials} m StreemRoomWallCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreemRoomWallCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.token = "";
                    if (o.bytes === String)
                        d.dataKey = "";
                    else {
                        d.dataKey = [];
                        if (o.bytes !== Array)
                            d.dataKey = $util.newBuffer(d.dataKey);
                    }
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.token != null && m.hasOwnProperty("token")) {
                    d.token = m.token;
                }
                if (m.dataKey != null && m.hasOwnProperty("dataKey")) {
                    d.dataKey = o.bytes === String ? $util.base64.encode(m.dataKey, 0, m.dataKey.length) : o.bytes === Array ? Array.prototype.slice.call(m.dataKey) : m.dataKey;
                }
                return d;
            };

            /**
             * Converts this StreemRoomWallCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.StreemRoomWallCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreemRoomWallCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StreemRoomWallCredentials
             * @function getTypeUrl
             * @memberof streem.api.StreemRoomWallCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreemRoomWallCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.StreemRoomWallCredentials";
            };

            return StreemRoomWallCredentials;
        })();

        api.TwilioRoomWallCredentials = (function() {

            /**
             * Properties of a TwilioRoomWallCredentials.
             * @memberof streem.api
             * @interface ITwilioRoomWallCredentials
             * @property {string|null} [roomSid] TwilioRoomWallCredentials roomSid
             * @property {string|null} [token] TwilioRoomWallCredentials token
             * @property {Uint8Array|null} [dataKey] TwilioRoomWallCredentials dataKey
             */

            /**
             * Constructs a new TwilioRoomWallCredentials.
             * @memberof streem.api
             * @classdesc Represents a TwilioRoomWallCredentials.
             * @implements ITwilioRoomWallCredentials
             * @constructor
             * @param {streem.api.ITwilioRoomWallCredentials=} [p] Properties to set
             */
            function TwilioRoomWallCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TwilioRoomWallCredentials roomSid.
             * @member {string} roomSid
             * @memberof streem.api.TwilioRoomWallCredentials
             * @instance
             */
            TwilioRoomWallCredentials.prototype.roomSid = "";

            /**
             * TwilioRoomWallCredentials token.
             * @member {string} token
             * @memberof streem.api.TwilioRoomWallCredentials
             * @instance
             */
            TwilioRoomWallCredentials.prototype.token = "";

            /**
             * TwilioRoomWallCredentials dataKey.
             * @member {Uint8Array} dataKey
             * @memberof streem.api.TwilioRoomWallCredentials
             * @instance
             */
            TwilioRoomWallCredentials.prototype.dataKey = $util.newBuffer([]);

            /**
             * Creates a new TwilioRoomWallCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {streem.api.ITwilioRoomWallCredentials=} [properties] Properties to set
             * @returns {streem.api.TwilioRoomWallCredentials} TwilioRoomWallCredentials instance
             */
            TwilioRoomWallCredentials.create = function create(properties) {
                return new TwilioRoomWallCredentials(properties);
            };

            /**
             * Encodes the specified TwilioRoomWallCredentials message. Does not implicitly {@link streem.api.TwilioRoomWallCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {streem.api.ITwilioRoomWallCredentials} m TwilioRoomWallCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TwilioRoomWallCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                    w.uint32(18).string(m.token);
                if (m.dataKey != null && Object.hasOwnProperty.call(m, "dataKey"))
                    w.uint32(26).bytes(m.dataKey);
                return w;
            };

            /**
             * Decodes a TwilioRoomWallCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TwilioRoomWallCredentials} TwilioRoomWallCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TwilioRoomWallCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TwilioRoomWallCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.token = r.string();
                            break;
                        }
                    case 3: {
                            m.dataKey = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TwilioRoomWallCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TwilioRoomWallCredentials} TwilioRoomWallCredentials
             */
            TwilioRoomWallCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TwilioRoomWallCredentials)
                    return d;
                var m = new $root.streem.api.TwilioRoomWallCredentials();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.token != null) {
                    m.token = String(d.token);
                }
                if (d.dataKey != null) {
                    if (typeof d.dataKey === "string")
                        $util.base64.decode(d.dataKey, m.dataKey = $util.newBuffer($util.base64.length(d.dataKey)), 0);
                    else if (d.dataKey.length >= 0)
                        m.dataKey = d.dataKey;
                }
                return m;
            };

            /**
             * Creates a plain object from a TwilioRoomWallCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {streem.api.TwilioRoomWallCredentials} m TwilioRoomWallCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TwilioRoomWallCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.token = "";
                    if (o.bytes === String)
                        d.dataKey = "";
                    else {
                        d.dataKey = [];
                        if (o.bytes !== Array)
                            d.dataKey = $util.newBuffer(d.dataKey);
                    }
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.token != null && m.hasOwnProperty("token")) {
                    d.token = m.token;
                }
                if (m.dataKey != null && m.hasOwnProperty("dataKey")) {
                    d.dataKey = o.bytes === String ? $util.base64.encode(m.dataKey, 0, m.dataKey.length) : o.bytes === Array ? Array.prototype.slice.call(m.dataKey) : m.dataKey;
                }
                return d;
            };

            /**
             * Converts this TwilioRoomWallCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.TwilioRoomWallCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TwilioRoomWallCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TwilioRoomWallCredentials
             * @function getTypeUrl
             * @memberof streem.api.TwilioRoomWallCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TwilioRoomWallCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TwilioRoomWallCredentials";
            };

            return TwilioRoomWallCredentials;
        })();

        api.TwilioRoomVideoCredentials = (function() {

            /**
             * Properties of a TwilioRoomVideoCredentials.
             * @memberof streem.api
             * @interface ITwilioRoomVideoCredentials
             * @property {string|null} [token] TwilioRoomVideoCredentials token
             * @property {streem.api.IPreflight|null} [preflight] TwilioRoomVideoCredentials preflight
             */

            /**
             * Constructs a new TwilioRoomVideoCredentials.
             * @memberof streem.api
             * @classdesc Represents a TwilioRoomVideoCredentials.
             * @implements ITwilioRoomVideoCredentials
             * @constructor
             * @param {streem.api.ITwilioRoomVideoCredentials=} [p] Properties to set
             */
            function TwilioRoomVideoCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TwilioRoomVideoCredentials token.
             * @member {string} token
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @instance
             */
            TwilioRoomVideoCredentials.prototype.token = "";

            /**
             * TwilioRoomVideoCredentials preflight.
             * @member {streem.api.IPreflight|null|undefined} preflight
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @instance
             */
            TwilioRoomVideoCredentials.prototype.preflight = null;

            /**
             * Creates a new TwilioRoomVideoCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {streem.api.ITwilioRoomVideoCredentials=} [properties] Properties to set
             * @returns {streem.api.TwilioRoomVideoCredentials} TwilioRoomVideoCredentials instance
             */
            TwilioRoomVideoCredentials.create = function create(properties) {
                return new TwilioRoomVideoCredentials(properties);
            };

            /**
             * Encodes the specified TwilioRoomVideoCredentials message. Does not implicitly {@link streem.api.TwilioRoomVideoCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {streem.api.ITwilioRoomVideoCredentials} m TwilioRoomVideoCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TwilioRoomVideoCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                    w.uint32(10).string(m.token);
                if (m.preflight != null && Object.hasOwnProperty.call(m, "preflight"))
                    $root.streem.api.Preflight.encode(m.preflight, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TwilioRoomVideoCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TwilioRoomVideoCredentials} TwilioRoomVideoCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TwilioRoomVideoCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TwilioRoomVideoCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.token = r.string();
                            break;
                        }
                    case 2: {
                            m.preflight = $root.streem.api.Preflight.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TwilioRoomVideoCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TwilioRoomVideoCredentials} TwilioRoomVideoCredentials
             */
            TwilioRoomVideoCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TwilioRoomVideoCredentials)
                    return d;
                var m = new $root.streem.api.TwilioRoomVideoCredentials();
                if (d.token != null) {
                    m.token = String(d.token);
                }
                if (d.preflight != null) {
                    if (typeof d.preflight !== "object")
                        throw TypeError(".streem.api.TwilioRoomVideoCredentials.preflight: object expected");
                    m.preflight = $root.streem.api.Preflight.fromObject(d.preflight);
                }
                return m;
            };

            /**
             * Creates a plain object from a TwilioRoomVideoCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {streem.api.TwilioRoomVideoCredentials} m TwilioRoomVideoCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TwilioRoomVideoCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.token = "";
                    d.preflight = null;
                }
                if (m.token != null && m.hasOwnProperty("token")) {
                    d.token = m.token;
                }
                if (m.preflight != null && m.hasOwnProperty("preflight")) {
                    d.preflight = $root.streem.api.Preflight.toObject(m.preflight, o);
                }
                return d;
            };

            /**
             * Converts this TwilioRoomVideoCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TwilioRoomVideoCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TwilioRoomVideoCredentials
             * @function getTypeUrl
             * @memberof streem.api.TwilioRoomVideoCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TwilioRoomVideoCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TwilioRoomVideoCredentials";
            };

            return TwilioRoomVideoCredentials;
        })();

        api.AraasRoomVideoCredentials = (function() {

            /**
             * Properties of an AraasRoomVideoCredentials.
             * @memberof streem.api
             * @interface IAraasRoomVideoCredentials
             * @property {string|null} [webSocketUri] AraasRoomVideoCredentials webSocketUri
             */

            /**
             * Constructs a new AraasRoomVideoCredentials.
             * @memberof streem.api
             * @classdesc Represents an AraasRoomVideoCredentials.
             * @implements IAraasRoomVideoCredentials
             * @constructor
             * @param {streem.api.IAraasRoomVideoCredentials=} [p] Properties to set
             */
            function AraasRoomVideoCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AraasRoomVideoCredentials webSocketUri.
             * @member {string} webSocketUri
             * @memberof streem.api.AraasRoomVideoCredentials
             * @instance
             */
            AraasRoomVideoCredentials.prototype.webSocketUri = "";

            /**
             * Creates a new AraasRoomVideoCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {streem.api.IAraasRoomVideoCredentials=} [properties] Properties to set
             * @returns {streem.api.AraasRoomVideoCredentials} AraasRoomVideoCredentials instance
             */
            AraasRoomVideoCredentials.create = function create(properties) {
                return new AraasRoomVideoCredentials(properties);
            };

            /**
             * Encodes the specified AraasRoomVideoCredentials message. Does not implicitly {@link streem.api.AraasRoomVideoCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {streem.api.IAraasRoomVideoCredentials} m AraasRoomVideoCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AraasRoomVideoCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.webSocketUri != null && Object.hasOwnProperty.call(m, "webSocketUri"))
                    w.uint32(10).string(m.webSocketUri);
                return w;
            };

            /**
             * Decodes an AraasRoomVideoCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AraasRoomVideoCredentials} AraasRoomVideoCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AraasRoomVideoCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AraasRoomVideoCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.webSocketUri = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AraasRoomVideoCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AraasRoomVideoCredentials} AraasRoomVideoCredentials
             */
            AraasRoomVideoCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AraasRoomVideoCredentials)
                    return d;
                var m = new $root.streem.api.AraasRoomVideoCredentials();
                if (d.webSocketUri != null) {
                    m.webSocketUri = String(d.webSocketUri);
                }
                return m;
            };

            /**
             * Creates a plain object from an AraasRoomVideoCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {streem.api.AraasRoomVideoCredentials} m AraasRoomVideoCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AraasRoomVideoCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.webSocketUri = "";
                }
                if (m.webSocketUri != null && m.hasOwnProperty("webSocketUri")) {
                    d.webSocketUri = m.webSocketUri;
                }
                return d;
            };

            /**
             * Converts this AraasRoomVideoCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.AraasRoomVideoCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AraasRoomVideoCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AraasRoomVideoCredentials
             * @function getTypeUrl
             * @memberof streem.api.AraasRoomVideoCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AraasRoomVideoCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AraasRoomVideoCredentials";
            };

            return AraasRoomVideoCredentials;
        })();

        api.ChimeRoomVideoCredentials = (function() {

            /**
             * Properties of a ChimeRoomVideoCredentials.
             * @memberof streem.api
             * @interface IChimeRoomVideoCredentials
             * @property {streem.api.IMeeting|null} [meeting] ChimeRoomVideoCredentials meeting
             * @property {streem.api.IAttendee|null} [attendee] ChimeRoomVideoCredentials attendee
             */

            /**
             * Constructs a new ChimeRoomVideoCredentials.
             * @memberof streem.api
             * @classdesc Represents a ChimeRoomVideoCredentials.
             * @implements IChimeRoomVideoCredentials
             * @constructor
             * @param {streem.api.IChimeRoomVideoCredentials=} [p] Properties to set
             */
            function ChimeRoomVideoCredentials(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ChimeRoomVideoCredentials meeting.
             * @member {streem.api.IMeeting|null|undefined} meeting
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @instance
             */
            ChimeRoomVideoCredentials.prototype.meeting = null;

            /**
             * ChimeRoomVideoCredentials attendee.
             * @member {streem.api.IAttendee|null|undefined} attendee
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @instance
             */
            ChimeRoomVideoCredentials.prototype.attendee = null;

            /**
             * Creates a new ChimeRoomVideoCredentials instance using the specified properties.
             * @function create
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {streem.api.IChimeRoomVideoCredentials=} [properties] Properties to set
             * @returns {streem.api.ChimeRoomVideoCredentials} ChimeRoomVideoCredentials instance
             */
            ChimeRoomVideoCredentials.create = function create(properties) {
                return new ChimeRoomVideoCredentials(properties);
            };

            /**
             * Encodes the specified ChimeRoomVideoCredentials message. Does not implicitly {@link streem.api.ChimeRoomVideoCredentials.verify|verify} messages.
             * @function encode
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {streem.api.IChimeRoomVideoCredentials} m ChimeRoomVideoCredentials message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChimeRoomVideoCredentials.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.meeting != null && Object.hasOwnProperty.call(m, "meeting"))
                    $root.streem.api.Meeting.encode(m.meeting, w.uint32(10).fork()).ldelim();
                if (m.attendee != null && Object.hasOwnProperty.call(m, "attendee"))
                    $root.streem.api.Attendee.encode(m.attendee, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ChimeRoomVideoCredentials message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.ChimeRoomVideoCredentials} ChimeRoomVideoCredentials
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChimeRoomVideoCredentials.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.ChimeRoomVideoCredentials();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.meeting = $root.streem.api.Meeting.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.attendee = $root.streem.api.Attendee.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ChimeRoomVideoCredentials message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.ChimeRoomVideoCredentials} ChimeRoomVideoCredentials
             */
            ChimeRoomVideoCredentials.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.ChimeRoomVideoCredentials)
                    return d;
                var m = new $root.streem.api.ChimeRoomVideoCredentials();
                if (d.meeting != null) {
                    if (typeof d.meeting !== "object")
                        throw TypeError(".streem.api.ChimeRoomVideoCredentials.meeting: object expected");
                    m.meeting = $root.streem.api.Meeting.fromObject(d.meeting);
                }
                if (d.attendee != null) {
                    if (typeof d.attendee !== "object")
                        throw TypeError(".streem.api.ChimeRoomVideoCredentials.attendee: object expected");
                    m.attendee = $root.streem.api.Attendee.fromObject(d.attendee);
                }
                return m;
            };

            /**
             * Creates a plain object from a ChimeRoomVideoCredentials message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {streem.api.ChimeRoomVideoCredentials} m ChimeRoomVideoCredentials
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChimeRoomVideoCredentials.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.meeting = null;
                    d.attendee = null;
                }
                if (m.meeting != null && m.hasOwnProperty("meeting")) {
                    d.meeting = $root.streem.api.Meeting.toObject(m.meeting, o);
                }
                if (m.attendee != null && m.hasOwnProperty("attendee")) {
                    d.attendee = $root.streem.api.Attendee.toObject(m.attendee, o);
                }
                return d;
            };

            /**
             * Converts this ChimeRoomVideoCredentials to JSON.
             * @function toJSON
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChimeRoomVideoCredentials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChimeRoomVideoCredentials
             * @function getTypeUrl
             * @memberof streem.api.ChimeRoomVideoCredentials
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChimeRoomVideoCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.ChimeRoomVideoCredentials";
            };

            return ChimeRoomVideoCredentials;
        })();

        api.Meeting = (function() {

            /**
             * Properties of a Meeting.
             * @memberof streem.api
             * @interface IMeeting
             * @property {string|null} [externalMeetingId] Meeting externalMeetingId
             * @property {streem.api.IMediaPlacement|null} [mediaPlacement] Meeting mediaPlacement
             * @property {string|null} [mediaRegion] Meeting mediaRegion
             * @property {string|null} [meetingArn] Meeting meetingArn
             * @property {string|null} [meetingHostId] Meeting meetingHostId
             * @property {string|null} [meetingId] Meeting meetingId
             * @property {string|null} [primaryMeetingId] Meeting primaryMeetingId
             * @property {streem.api.IMeetingFeatures|null} [meetingFeatures] Meeting meetingFeatures
             * @property {Array.<string>|null} [tenantIds] Meeting tenantIds
             */

            /**
             * Constructs a new Meeting.
             * @memberof streem.api
             * @classdesc Represents a Meeting.
             * @implements IMeeting
             * @constructor
             * @param {streem.api.IMeeting=} [p] Properties to set
             */
            function Meeting(p) {
                this.tenantIds = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Meeting externalMeetingId.
             * @member {string} externalMeetingId
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.externalMeetingId = "";

            /**
             * Meeting mediaPlacement.
             * @member {streem.api.IMediaPlacement|null|undefined} mediaPlacement
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.mediaPlacement = null;

            /**
             * Meeting mediaRegion.
             * @member {string} mediaRegion
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.mediaRegion = "";

            /**
             * Meeting meetingArn.
             * @member {string} meetingArn
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.meetingArn = "";

            /**
             * Meeting meetingHostId.
             * @member {string} meetingHostId
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.meetingHostId = "";

            /**
             * Meeting meetingId.
             * @member {string} meetingId
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.meetingId = "";

            /**
             * Meeting primaryMeetingId.
             * @member {string} primaryMeetingId
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.primaryMeetingId = "";

            /**
             * Meeting meetingFeatures.
             * @member {streem.api.IMeetingFeatures|null|undefined} meetingFeatures
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.meetingFeatures = null;

            /**
             * Meeting tenantIds.
             * @member {Array.<string>} tenantIds
             * @memberof streem.api.Meeting
             * @instance
             */
            Meeting.prototype.tenantIds = $util.emptyArray;

            /**
             * Creates a new Meeting instance using the specified properties.
             * @function create
             * @memberof streem.api.Meeting
             * @static
             * @param {streem.api.IMeeting=} [properties] Properties to set
             * @returns {streem.api.Meeting} Meeting instance
             */
            Meeting.create = function create(properties) {
                return new Meeting(properties);
            };

            /**
             * Encodes the specified Meeting message. Does not implicitly {@link streem.api.Meeting.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Meeting
             * @static
             * @param {streem.api.IMeeting} m Meeting message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Meeting.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalMeetingId != null && Object.hasOwnProperty.call(m, "externalMeetingId"))
                    w.uint32(10).string(m.externalMeetingId);
                if (m.mediaPlacement != null && Object.hasOwnProperty.call(m, "mediaPlacement"))
                    $root.streem.api.MediaPlacement.encode(m.mediaPlacement, w.uint32(18).fork()).ldelim();
                if (m.mediaRegion != null && Object.hasOwnProperty.call(m, "mediaRegion"))
                    w.uint32(26).string(m.mediaRegion);
                if (m.meetingArn != null && Object.hasOwnProperty.call(m, "meetingArn"))
                    w.uint32(34).string(m.meetingArn);
                if (m.meetingHostId != null && Object.hasOwnProperty.call(m, "meetingHostId"))
                    w.uint32(42).string(m.meetingHostId);
                if (m.meetingId != null && Object.hasOwnProperty.call(m, "meetingId"))
                    w.uint32(50).string(m.meetingId);
                if (m.primaryMeetingId != null && Object.hasOwnProperty.call(m, "primaryMeetingId"))
                    w.uint32(58).string(m.primaryMeetingId);
                if (m.meetingFeatures != null && Object.hasOwnProperty.call(m, "meetingFeatures"))
                    $root.streem.api.MeetingFeatures.encode(m.meetingFeatures, w.uint32(66).fork()).ldelim();
                if (m.tenantIds != null && m.tenantIds.length) {
                    for (var i = 0; i < m.tenantIds.length; ++i)
                        w.uint32(74).string(m.tenantIds[i]);
                }
                return w;
            };

            /**
             * Decodes a Meeting message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Meeting
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Meeting} Meeting
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Meeting.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Meeting();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalMeetingId = r.string();
                            break;
                        }
                    case 2: {
                            m.mediaPlacement = $root.streem.api.MediaPlacement.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.mediaRegion = r.string();
                            break;
                        }
                    case 4: {
                            m.meetingArn = r.string();
                            break;
                        }
                    case 5: {
                            m.meetingHostId = r.string();
                            break;
                        }
                    case 6: {
                            m.meetingId = r.string();
                            break;
                        }
                    case 7: {
                            m.primaryMeetingId = r.string();
                            break;
                        }
                    case 8: {
                            m.meetingFeatures = $root.streem.api.MeetingFeatures.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            if (!(m.tenantIds && m.tenantIds.length))
                                m.tenantIds = [];
                            m.tenantIds.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Meeting message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Meeting
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Meeting} Meeting
             */
            Meeting.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Meeting)
                    return d;
                var m = new $root.streem.api.Meeting();
                if (d.externalMeetingId != null) {
                    m.externalMeetingId = String(d.externalMeetingId);
                }
                if (d.mediaPlacement != null) {
                    if (typeof d.mediaPlacement !== "object")
                        throw TypeError(".streem.api.Meeting.mediaPlacement: object expected");
                    m.mediaPlacement = $root.streem.api.MediaPlacement.fromObject(d.mediaPlacement);
                }
                if (d.mediaRegion != null) {
                    m.mediaRegion = String(d.mediaRegion);
                }
                if (d.meetingArn != null) {
                    m.meetingArn = String(d.meetingArn);
                }
                if (d.meetingHostId != null) {
                    m.meetingHostId = String(d.meetingHostId);
                }
                if (d.meetingId != null) {
                    m.meetingId = String(d.meetingId);
                }
                if (d.primaryMeetingId != null) {
                    m.primaryMeetingId = String(d.primaryMeetingId);
                }
                if (d.meetingFeatures != null) {
                    if (typeof d.meetingFeatures !== "object")
                        throw TypeError(".streem.api.Meeting.meetingFeatures: object expected");
                    m.meetingFeatures = $root.streem.api.MeetingFeatures.fromObject(d.meetingFeatures);
                }
                if (d.tenantIds) {
                    if (!Array.isArray(d.tenantIds))
                        throw TypeError(".streem.api.Meeting.tenantIds: array expected");
                    m.tenantIds = [];
                    for (var i = 0; i < d.tenantIds.length; ++i) {
                        m.tenantIds[i] = String(d.tenantIds[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Meeting message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Meeting
             * @static
             * @param {streem.api.Meeting} m Meeting
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Meeting.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.tenantIds = [];
                }
                if (o.defaults) {
                    d.externalMeetingId = "";
                    d.mediaPlacement = null;
                    d.mediaRegion = "";
                    d.meetingArn = "";
                    d.meetingHostId = "";
                    d.meetingId = "";
                    d.primaryMeetingId = "";
                    d.meetingFeatures = null;
                }
                if (m.externalMeetingId != null && m.hasOwnProperty("externalMeetingId")) {
                    d.externalMeetingId = m.externalMeetingId;
                }
                if (m.mediaPlacement != null && m.hasOwnProperty("mediaPlacement")) {
                    d.mediaPlacement = $root.streem.api.MediaPlacement.toObject(m.mediaPlacement, o);
                }
                if (m.mediaRegion != null && m.hasOwnProperty("mediaRegion")) {
                    d.mediaRegion = m.mediaRegion;
                }
                if (m.meetingArn != null && m.hasOwnProperty("meetingArn")) {
                    d.meetingArn = m.meetingArn;
                }
                if (m.meetingHostId != null && m.hasOwnProperty("meetingHostId")) {
                    d.meetingHostId = m.meetingHostId;
                }
                if (m.meetingId != null && m.hasOwnProperty("meetingId")) {
                    d.meetingId = m.meetingId;
                }
                if (m.primaryMeetingId != null && m.hasOwnProperty("primaryMeetingId")) {
                    d.primaryMeetingId = m.primaryMeetingId;
                }
                if (m.meetingFeatures != null && m.hasOwnProperty("meetingFeatures")) {
                    d.meetingFeatures = $root.streem.api.MeetingFeatures.toObject(m.meetingFeatures, o);
                }
                if (m.tenantIds && m.tenantIds.length) {
                    d.tenantIds = [];
                    for (var j = 0; j < m.tenantIds.length; ++j) {
                        d.tenantIds[j] = m.tenantIds[j];
                    }
                }
                return d;
            };

            /**
             * Converts this Meeting to JSON.
             * @function toJSON
             * @memberof streem.api.Meeting
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Meeting.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Meeting
             * @function getTypeUrl
             * @memberof streem.api.Meeting
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Meeting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Meeting";
            };

            return Meeting;
        })();

        api.MediaPlacement = (function() {

            /**
             * Properties of a MediaPlacement.
             * @memberof streem.api
             * @interface IMediaPlacement
             * @property {string|null} [audioFallbackUrl] MediaPlacement audioFallbackUrl
             * @property {string|null} [audioHostUrl] MediaPlacement audioHostUrl
             * @property {string|null} [eventIngestionUrl] MediaPlacement eventIngestionUrl
             * @property {string|null} [screenDataUrl] MediaPlacement screenDataUrl
             * @property {string|null} [screenSharingUrl] MediaPlacement screenSharingUrl
             * @property {string|null} [screenViewingUrl] MediaPlacement screenViewingUrl
             * @property {string|null} [signalingUrl] MediaPlacement signalingUrl
             * @property {string|null} [turnControlUrl] MediaPlacement turnControlUrl
             */

            /**
             * Constructs a new MediaPlacement.
             * @memberof streem.api
             * @classdesc Represents a MediaPlacement.
             * @implements IMediaPlacement
             * @constructor
             * @param {streem.api.IMediaPlacement=} [p] Properties to set
             */
            function MediaPlacement(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MediaPlacement audioFallbackUrl.
             * @member {string} audioFallbackUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.audioFallbackUrl = "";

            /**
             * MediaPlacement audioHostUrl.
             * @member {string} audioHostUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.audioHostUrl = "";

            /**
             * MediaPlacement eventIngestionUrl.
             * @member {string} eventIngestionUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.eventIngestionUrl = "";

            /**
             * MediaPlacement screenDataUrl.
             * @member {string} screenDataUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.screenDataUrl = "";

            /**
             * MediaPlacement screenSharingUrl.
             * @member {string} screenSharingUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.screenSharingUrl = "";

            /**
             * MediaPlacement screenViewingUrl.
             * @member {string} screenViewingUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.screenViewingUrl = "";

            /**
             * MediaPlacement signalingUrl.
             * @member {string} signalingUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.signalingUrl = "";

            /**
             * MediaPlacement turnControlUrl.
             * @member {string} turnControlUrl
             * @memberof streem.api.MediaPlacement
             * @instance
             */
            MediaPlacement.prototype.turnControlUrl = "";

            /**
             * Creates a new MediaPlacement instance using the specified properties.
             * @function create
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {streem.api.IMediaPlacement=} [properties] Properties to set
             * @returns {streem.api.MediaPlacement} MediaPlacement instance
             */
            MediaPlacement.create = function create(properties) {
                return new MediaPlacement(properties);
            };

            /**
             * Encodes the specified MediaPlacement message. Does not implicitly {@link streem.api.MediaPlacement.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {streem.api.IMediaPlacement} m MediaPlacement message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MediaPlacement.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.audioFallbackUrl != null && Object.hasOwnProperty.call(m, "audioFallbackUrl"))
                    w.uint32(10).string(m.audioFallbackUrl);
                if (m.audioHostUrl != null && Object.hasOwnProperty.call(m, "audioHostUrl"))
                    w.uint32(18).string(m.audioHostUrl);
                if (m.eventIngestionUrl != null && Object.hasOwnProperty.call(m, "eventIngestionUrl"))
                    w.uint32(26).string(m.eventIngestionUrl);
                if (m.screenDataUrl != null && Object.hasOwnProperty.call(m, "screenDataUrl"))
                    w.uint32(34).string(m.screenDataUrl);
                if (m.screenSharingUrl != null && Object.hasOwnProperty.call(m, "screenSharingUrl"))
                    w.uint32(42).string(m.screenSharingUrl);
                if (m.screenViewingUrl != null && Object.hasOwnProperty.call(m, "screenViewingUrl"))
                    w.uint32(50).string(m.screenViewingUrl);
                if (m.signalingUrl != null && Object.hasOwnProperty.call(m, "signalingUrl"))
                    w.uint32(58).string(m.signalingUrl);
                if (m.turnControlUrl != null && Object.hasOwnProperty.call(m, "turnControlUrl"))
                    w.uint32(66).string(m.turnControlUrl);
                return w;
            };

            /**
             * Decodes a MediaPlacement message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MediaPlacement} MediaPlacement
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MediaPlacement.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MediaPlacement();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.audioFallbackUrl = r.string();
                            break;
                        }
                    case 2: {
                            m.audioHostUrl = r.string();
                            break;
                        }
                    case 3: {
                            m.eventIngestionUrl = r.string();
                            break;
                        }
                    case 4: {
                            m.screenDataUrl = r.string();
                            break;
                        }
                    case 5: {
                            m.screenSharingUrl = r.string();
                            break;
                        }
                    case 6: {
                            m.screenViewingUrl = r.string();
                            break;
                        }
                    case 7: {
                            m.signalingUrl = r.string();
                            break;
                        }
                    case 8: {
                            m.turnControlUrl = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MediaPlacement message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MediaPlacement} MediaPlacement
             */
            MediaPlacement.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MediaPlacement)
                    return d;
                var m = new $root.streem.api.MediaPlacement();
                if (d.audioFallbackUrl != null) {
                    m.audioFallbackUrl = String(d.audioFallbackUrl);
                }
                if (d.audioHostUrl != null) {
                    m.audioHostUrl = String(d.audioHostUrl);
                }
                if (d.eventIngestionUrl != null) {
                    m.eventIngestionUrl = String(d.eventIngestionUrl);
                }
                if (d.screenDataUrl != null) {
                    m.screenDataUrl = String(d.screenDataUrl);
                }
                if (d.screenSharingUrl != null) {
                    m.screenSharingUrl = String(d.screenSharingUrl);
                }
                if (d.screenViewingUrl != null) {
                    m.screenViewingUrl = String(d.screenViewingUrl);
                }
                if (d.signalingUrl != null) {
                    m.signalingUrl = String(d.signalingUrl);
                }
                if (d.turnControlUrl != null) {
                    m.turnControlUrl = String(d.turnControlUrl);
                }
                return m;
            };

            /**
             * Creates a plain object from a MediaPlacement message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {streem.api.MediaPlacement} m MediaPlacement
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MediaPlacement.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.audioFallbackUrl = "";
                    d.audioHostUrl = "";
                    d.eventIngestionUrl = "";
                    d.screenDataUrl = "";
                    d.screenSharingUrl = "";
                    d.screenViewingUrl = "";
                    d.signalingUrl = "";
                    d.turnControlUrl = "";
                }
                if (m.audioFallbackUrl != null && m.hasOwnProperty("audioFallbackUrl")) {
                    d.audioFallbackUrl = m.audioFallbackUrl;
                }
                if (m.audioHostUrl != null && m.hasOwnProperty("audioHostUrl")) {
                    d.audioHostUrl = m.audioHostUrl;
                }
                if (m.eventIngestionUrl != null && m.hasOwnProperty("eventIngestionUrl")) {
                    d.eventIngestionUrl = m.eventIngestionUrl;
                }
                if (m.screenDataUrl != null && m.hasOwnProperty("screenDataUrl")) {
                    d.screenDataUrl = m.screenDataUrl;
                }
                if (m.screenSharingUrl != null && m.hasOwnProperty("screenSharingUrl")) {
                    d.screenSharingUrl = m.screenSharingUrl;
                }
                if (m.screenViewingUrl != null && m.hasOwnProperty("screenViewingUrl")) {
                    d.screenViewingUrl = m.screenViewingUrl;
                }
                if (m.signalingUrl != null && m.hasOwnProperty("signalingUrl")) {
                    d.signalingUrl = m.signalingUrl;
                }
                if (m.turnControlUrl != null && m.hasOwnProperty("turnControlUrl")) {
                    d.turnControlUrl = m.turnControlUrl;
                }
                return d;
            };

            /**
             * Converts this MediaPlacement to JSON.
             * @function toJSON
             * @memberof streem.api.MediaPlacement
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MediaPlacement.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MediaPlacement
             * @function getTypeUrl
             * @memberof streem.api.MediaPlacement
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MediaPlacement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MediaPlacement";
            };

            return MediaPlacement;
        })();

        api.MeetingFeatures = (function() {

            /**
             * Properties of a MeetingFeatures.
             * @memberof streem.api
             * @interface IMeetingFeatures
             * @property {streem.api.IAttendeeFeatures|null} [attendee] MeetingFeatures attendee
             * @property {streem.api.IMeetingFeaturesAudio|null} [audio] MeetingFeatures audio
             * @property {streem.api.IMeetingFeaturesContent|null} [content] MeetingFeatures content
             * @property {streem.api.IMeetingFeaturesVideo|null} [video] MeetingFeatures video
             */

            /**
             * Constructs a new MeetingFeatures.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeatures.
             * @implements IMeetingFeatures
             * @constructor
             * @param {streem.api.IMeetingFeatures=} [p] Properties to set
             */
            function MeetingFeatures(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeatures attendee.
             * @member {streem.api.IAttendeeFeatures|null|undefined} attendee
             * @memberof streem.api.MeetingFeatures
             * @instance
             */
            MeetingFeatures.prototype.attendee = null;

            /**
             * MeetingFeatures audio.
             * @member {streem.api.IMeetingFeaturesAudio|null|undefined} audio
             * @memberof streem.api.MeetingFeatures
             * @instance
             */
            MeetingFeatures.prototype.audio = null;

            /**
             * MeetingFeatures content.
             * @member {streem.api.IMeetingFeaturesContent|null|undefined} content
             * @memberof streem.api.MeetingFeatures
             * @instance
             */
            MeetingFeatures.prototype.content = null;

            /**
             * MeetingFeatures video.
             * @member {streem.api.IMeetingFeaturesVideo|null|undefined} video
             * @memberof streem.api.MeetingFeatures
             * @instance
             */
            MeetingFeatures.prototype.video = null;

            /**
             * Creates a new MeetingFeatures instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {streem.api.IMeetingFeatures=} [properties] Properties to set
             * @returns {streem.api.MeetingFeatures} MeetingFeatures instance
             */
            MeetingFeatures.create = function create(properties) {
                return new MeetingFeatures(properties);
            };

            /**
             * Encodes the specified MeetingFeatures message. Does not implicitly {@link streem.api.MeetingFeatures.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {streem.api.IMeetingFeatures} m MeetingFeatures message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeatures.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.attendee != null && Object.hasOwnProperty.call(m, "attendee"))
                    $root.streem.api.AttendeeFeatures.encode(m.attendee, w.uint32(10).fork()).ldelim();
                if (m.audio != null && Object.hasOwnProperty.call(m, "audio"))
                    $root.streem.api.MeetingFeaturesAudio.encode(m.audio, w.uint32(18).fork()).ldelim();
                if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                    $root.streem.api.MeetingFeaturesContent.encode(m.content, w.uint32(26).fork()).ldelim();
                if (m.video != null && Object.hasOwnProperty.call(m, "video"))
                    $root.streem.api.MeetingFeaturesVideo.encode(m.video, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a MeetingFeatures message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeatures} MeetingFeatures
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeatures.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeatures();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.attendee = $root.streem.api.AttendeeFeatures.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.audio = $root.streem.api.MeetingFeaturesAudio.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.content = $root.streem.api.MeetingFeaturesContent.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.video = $root.streem.api.MeetingFeaturesVideo.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeatures message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeatures} MeetingFeatures
             */
            MeetingFeatures.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeatures)
                    return d;
                var m = new $root.streem.api.MeetingFeatures();
                if (d.attendee != null) {
                    if (typeof d.attendee !== "object")
                        throw TypeError(".streem.api.MeetingFeatures.attendee: object expected");
                    m.attendee = $root.streem.api.AttendeeFeatures.fromObject(d.attendee);
                }
                if (d.audio != null) {
                    if (typeof d.audio !== "object")
                        throw TypeError(".streem.api.MeetingFeatures.audio: object expected");
                    m.audio = $root.streem.api.MeetingFeaturesAudio.fromObject(d.audio);
                }
                if (d.content != null) {
                    if (typeof d.content !== "object")
                        throw TypeError(".streem.api.MeetingFeatures.content: object expected");
                    m.content = $root.streem.api.MeetingFeaturesContent.fromObject(d.content);
                }
                if (d.video != null) {
                    if (typeof d.video !== "object")
                        throw TypeError(".streem.api.MeetingFeatures.video: object expected");
                    m.video = $root.streem.api.MeetingFeaturesVideo.fromObject(d.video);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeatures message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {streem.api.MeetingFeatures} m MeetingFeatures
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeatures.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.attendee = null;
                    d.audio = null;
                    d.content = null;
                    d.video = null;
                }
                if (m.attendee != null && m.hasOwnProperty("attendee")) {
                    d.attendee = $root.streem.api.AttendeeFeatures.toObject(m.attendee, o);
                }
                if (m.audio != null && m.hasOwnProperty("audio")) {
                    d.audio = $root.streem.api.MeetingFeaturesAudio.toObject(m.audio, o);
                }
                if (m.content != null && m.hasOwnProperty("content")) {
                    d.content = $root.streem.api.MeetingFeaturesContent.toObject(m.content, o);
                }
                if (m.video != null && m.hasOwnProperty("video")) {
                    d.video = $root.streem.api.MeetingFeaturesVideo.toObject(m.video, o);
                }
                return d;
            };

            /**
             * Converts this MeetingFeatures to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeatures
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeatures.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeatures
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeatures
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeatures.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeatures";
            };

            return MeetingFeatures;
        })();

        api.Attendee = (function() {

            /**
             * Properties of an Attendee.
             * @memberof streem.api
             * @interface IAttendee
             * @property {string|null} [externalUserId] Attendee externalUserId
             * @property {string|null} [attendeeId] Attendee attendeeId
             * @property {string|null} [joinToken] Attendee joinToken
             * @property {streem.api.IMeetingFeaturesAttendeeCapabilities|null} [attendeeCapabilities] Attendee attendeeCapabilities
             */

            /**
             * Constructs a new Attendee.
             * @memberof streem.api
             * @classdesc Represents an Attendee.
             * @implements IAttendee
             * @constructor
             * @param {streem.api.IAttendee=} [p] Properties to set
             */
            function Attendee(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Attendee externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.Attendee
             * @instance
             */
            Attendee.prototype.externalUserId = "";

            /**
             * Attendee attendeeId.
             * @member {string} attendeeId
             * @memberof streem.api.Attendee
             * @instance
             */
            Attendee.prototype.attendeeId = "";

            /**
             * Attendee joinToken.
             * @member {string} joinToken
             * @memberof streem.api.Attendee
             * @instance
             */
            Attendee.prototype.joinToken = "";

            /**
             * Attendee attendeeCapabilities.
             * @member {streem.api.IMeetingFeaturesAttendeeCapabilities|null|undefined} attendeeCapabilities
             * @memberof streem.api.Attendee
             * @instance
             */
            Attendee.prototype.attendeeCapabilities = null;

            /**
             * Creates a new Attendee instance using the specified properties.
             * @function create
             * @memberof streem.api.Attendee
             * @static
             * @param {streem.api.IAttendee=} [properties] Properties to set
             * @returns {streem.api.Attendee} Attendee instance
             */
            Attendee.create = function create(properties) {
                return new Attendee(properties);
            };

            /**
             * Encodes the specified Attendee message. Does not implicitly {@link streem.api.Attendee.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Attendee
             * @static
             * @param {streem.api.IAttendee} m Attendee message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Attendee.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(10).string(m.externalUserId);
                if (m.attendeeId != null && Object.hasOwnProperty.call(m, "attendeeId"))
                    w.uint32(18).string(m.attendeeId);
                if (m.joinToken != null && Object.hasOwnProperty.call(m, "joinToken"))
                    w.uint32(26).string(m.joinToken);
                if (m.attendeeCapabilities != null && Object.hasOwnProperty.call(m, "attendeeCapabilities"))
                    $root.streem.api.MeetingFeaturesAttendeeCapabilities.encode(m.attendeeCapabilities, w.uint32(34).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an Attendee message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Attendee
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Attendee} Attendee
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Attendee.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Attendee();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 2: {
                            m.attendeeId = r.string();
                            break;
                        }
                    case 3: {
                            m.joinToken = r.string();
                            break;
                        }
                    case 4: {
                            m.attendeeCapabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Attendee message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Attendee
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Attendee} Attendee
             */
            Attendee.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Attendee)
                    return d;
                var m = new $root.streem.api.Attendee();
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.attendeeId != null) {
                    m.attendeeId = String(d.attendeeId);
                }
                if (d.joinToken != null) {
                    m.joinToken = String(d.joinToken);
                }
                if (d.attendeeCapabilities != null) {
                    if (typeof d.attendeeCapabilities !== "object")
                        throw TypeError(".streem.api.Attendee.attendeeCapabilities: object expected");
                    m.attendeeCapabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.fromObject(d.attendeeCapabilities);
                }
                return m;
            };

            /**
             * Creates a plain object from an Attendee message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Attendee
             * @static
             * @param {streem.api.Attendee} m Attendee
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Attendee.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.externalUserId = "";
                    d.attendeeId = "";
                    d.joinToken = "";
                    d.attendeeCapabilities = null;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.attendeeId != null && m.hasOwnProperty("attendeeId")) {
                    d.attendeeId = m.attendeeId;
                }
                if (m.joinToken != null && m.hasOwnProperty("joinToken")) {
                    d.joinToken = m.joinToken;
                }
                if (m.attendeeCapabilities != null && m.hasOwnProperty("attendeeCapabilities")) {
                    d.attendeeCapabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.toObject(m.attendeeCapabilities, o);
                }
                return d;
            };

            /**
             * Converts this Attendee to JSON.
             * @function toJSON
             * @memberof streem.api.Attendee
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Attendee.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Attendee
             * @function getTypeUrl
             * @memberof streem.api.Attendee
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Attendee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Attendee";
            };

            return Attendee;
        })();

        api.MeetingFeaturesAudio = (function() {

            /**
             * Properties of a MeetingFeaturesAudio.
             * @memberof streem.api
             * @interface IMeetingFeaturesAudio
             * @property {string|null} [echoReduction] MeetingFeaturesAudio echoReduction
             */

            /**
             * Constructs a new MeetingFeaturesAudio.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeaturesAudio.
             * @implements IMeetingFeaturesAudio
             * @constructor
             * @param {streem.api.IMeetingFeaturesAudio=} [p] Properties to set
             */
            function MeetingFeaturesAudio(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeaturesAudio echoReduction.
             * @member {string} echoReduction
             * @memberof streem.api.MeetingFeaturesAudio
             * @instance
             */
            MeetingFeaturesAudio.prototype.echoReduction = "";

            /**
             * Creates a new MeetingFeaturesAudio instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {streem.api.IMeetingFeaturesAudio=} [properties] Properties to set
             * @returns {streem.api.MeetingFeaturesAudio} MeetingFeaturesAudio instance
             */
            MeetingFeaturesAudio.create = function create(properties) {
                return new MeetingFeaturesAudio(properties);
            };

            /**
             * Encodes the specified MeetingFeaturesAudio message. Does not implicitly {@link streem.api.MeetingFeaturesAudio.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {streem.api.IMeetingFeaturesAudio} m MeetingFeaturesAudio message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeaturesAudio.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.echoReduction != null && Object.hasOwnProperty.call(m, "echoReduction"))
                    w.uint32(10).string(m.echoReduction);
                return w;
            };

            /**
             * Decodes a MeetingFeaturesAudio message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeaturesAudio} MeetingFeaturesAudio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeaturesAudio.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeaturesAudio();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.echoReduction = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeaturesAudio message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeaturesAudio} MeetingFeaturesAudio
             */
            MeetingFeaturesAudio.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeaturesAudio)
                    return d;
                var m = new $root.streem.api.MeetingFeaturesAudio();
                if (d.echoReduction != null) {
                    m.echoReduction = String(d.echoReduction);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeaturesAudio message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {streem.api.MeetingFeaturesAudio} m MeetingFeaturesAudio
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeaturesAudio.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.echoReduction = "";
                }
                if (m.echoReduction != null && m.hasOwnProperty("echoReduction")) {
                    d.echoReduction = m.echoReduction;
                }
                return d;
            };

            /**
             * Converts this MeetingFeaturesAudio to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeaturesAudio
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeaturesAudio.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeaturesAudio
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeaturesAudio
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeaturesAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeaturesAudio";
            };

            return MeetingFeaturesAudio;
        })();

        api.MeetingFeaturesContent = (function() {

            /**
             * Properties of a MeetingFeaturesContent.
             * @memberof streem.api
             * @interface IMeetingFeaturesContent
             * @property {string|null} [maxResolution] MeetingFeaturesContent maxResolution
             */

            /**
             * Constructs a new MeetingFeaturesContent.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeaturesContent.
             * @implements IMeetingFeaturesContent
             * @constructor
             * @param {streem.api.IMeetingFeaturesContent=} [p] Properties to set
             */
            function MeetingFeaturesContent(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeaturesContent maxResolution.
             * @member {string} maxResolution
             * @memberof streem.api.MeetingFeaturesContent
             * @instance
             */
            MeetingFeaturesContent.prototype.maxResolution = "";

            /**
             * Creates a new MeetingFeaturesContent instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {streem.api.IMeetingFeaturesContent=} [properties] Properties to set
             * @returns {streem.api.MeetingFeaturesContent} MeetingFeaturesContent instance
             */
            MeetingFeaturesContent.create = function create(properties) {
                return new MeetingFeaturesContent(properties);
            };

            /**
             * Encodes the specified MeetingFeaturesContent message. Does not implicitly {@link streem.api.MeetingFeaturesContent.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {streem.api.IMeetingFeaturesContent} m MeetingFeaturesContent message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeaturesContent.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.maxResolution != null && Object.hasOwnProperty.call(m, "maxResolution"))
                    w.uint32(10).string(m.maxResolution);
                return w;
            };

            /**
             * Decodes a MeetingFeaturesContent message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeaturesContent} MeetingFeaturesContent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeaturesContent.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeaturesContent();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.maxResolution = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeaturesContent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeaturesContent} MeetingFeaturesContent
             */
            MeetingFeaturesContent.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeaturesContent)
                    return d;
                var m = new $root.streem.api.MeetingFeaturesContent();
                if (d.maxResolution != null) {
                    m.maxResolution = String(d.maxResolution);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeaturesContent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {streem.api.MeetingFeaturesContent} m MeetingFeaturesContent
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeaturesContent.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.maxResolution = "";
                }
                if (m.maxResolution != null && m.hasOwnProperty("maxResolution")) {
                    d.maxResolution = m.maxResolution;
                }
                return d;
            };

            /**
             * Converts this MeetingFeaturesContent to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeaturesContent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeaturesContent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeaturesContent
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeaturesContent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeaturesContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeaturesContent";
            };

            return MeetingFeaturesContent;
        })();

        api.MeetingFeaturesVideo = (function() {

            /**
             * Properties of a MeetingFeaturesVideo.
             * @memberof streem.api
             * @interface IMeetingFeaturesVideo
             * @property {string|null} [maxResolution] MeetingFeaturesVideo maxResolution
             */

            /**
             * Constructs a new MeetingFeaturesVideo.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeaturesVideo.
             * @implements IMeetingFeaturesVideo
             * @constructor
             * @param {streem.api.IMeetingFeaturesVideo=} [p] Properties to set
             */
            function MeetingFeaturesVideo(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeaturesVideo maxResolution.
             * @member {string} maxResolution
             * @memberof streem.api.MeetingFeaturesVideo
             * @instance
             */
            MeetingFeaturesVideo.prototype.maxResolution = "";

            /**
             * Creates a new MeetingFeaturesVideo instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {streem.api.IMeetingFeaturesVideo=} [properties] Properties to set
             * @returns {streem.api.MeetingFeaturesVideo} MeetingFeaturesVideo instance
             */
            MeetingFeaturesVideo.create = function create(properties) {
                return new MeetingFeaturesVideo(properties);
            };

            /**
             * Encodes the specified MeetingFeaturesVideo message. Does not implicitly {@link streem.api.MeetingFeaturesVideo.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {streem.api.IMeetingFeaturesVideo} m MeetingFeaturesVideo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeaturesVideo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.maxResolution != null && Object.hasOwnProperty.call(m, "maxResolution"))
                    w.uint32(10).string(m.maxResolution);
                return w;
            };

            /**
             * Decodes a MeetingFeaturesVideo message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeaturesVideo} MeetingFeaturesVideo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeaturesVideo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeaturesVideo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.maxResolution = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeaturesVideo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeaturesVideo} MeetingFeaturesVideo
             */
            MeetingFeaturesVideo.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeaturesVideo)
                    return d;
                var m = new $root.streem.api.MeetingFeaturesVideo();
                if (d.maxResolution != null) {
                    m.maxResolution = String(d.maxResolution);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeaturesVideo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {streem.api.MeetingFeaturesVideo} m MeetingFeaturesVideo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeaturesVideo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.maxResolution = "";
                }
                if (m.maxResolution != null && m.hasOwnProperty("maxResolution")) {
                    d.maxResolution = m.maxResolution;
                }
                return d;
            };

            /**
             * Converts this MeetingFeaturesVideo to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeaturesVideo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeaturesVideo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeaturesVideo
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeaturesVideo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeaturesVideo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeaturesVideo";
            };

            return MeetingFeaturesVideo;
        })();

        api.MeetingFeaturesAttendee = (function() {

            /**
             * Properties of a MeetingFeaturesAttendee.
             * @memberof streem.api
             * @interface IMeetingFeaturesAttendee
             * @property {streem.api.IAttendeeFeatures|null} [attendeeId] MeetingFeaturesAttendee attendeeId
             * @property {streem.api.IMeetingFeaturesAttendeeCapabilities|null} [capabilities] MeetingFeaturesAttendee capabilities
             * @property {string|null} [externalUserId] MeetingFeaturesAttendee externalUserId
             * @property {string|null} [joinToken] MeetingFeaturesAttendee joinToken
             */

            /**
             * Constructs a new MeetingFeaturesAttendee.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeaturesAttendee.
             * @implements IMeetingFeaturesAttendee
             * @constructor
             * @param {streem.api.IMeetingFeaturesAttendee=} [p] Properties to set
             */
            function MeetingFeaturesAttendee(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeaturesAttendee attendeeId.
             * @member {streem.api.IAttendeeFeatures|null|undefined} attendeeId
             * @memberof streem.api.MeetingFeaturesAttendee
             * @instance
             */
            MeetingFeaturesAttendee.prototype.attendeeId = null;

            /**
             * MeetingFeaturesAttendee capabilities.
             * @member {streem.api.IMeetingFeaturesAttendeeCapabilities|null|undefined} capabilities
             * @memberof streem.api.MeetingFeaturesAttendee
             * @instance
             */
            MeetingFeaturesAttendee.prototype.capabilities = null;

            /**
             * MeetingFeaturesAttendee externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.MeetingFeaturesAttendee
             * @instance
             */
            MeetingFeaturesAttendee.prototype.externalUserId = "";

            /**
             * MeetingFeaturesAttendee joinToken.
             * @member {string} joinToken
             * @memberof streem.api.MeetingFeaturesAttendee
             * @instance
             */
            MeetingFeaturesAttendee.prototype.joinToken = "";

            /**
             * Creates a new MeetingFeaturesAttendee instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {streem.api.IMeetingFeaturesAttendee=} [properties] Properties to set
             * @returns {streem.api.MeetingFeaturesAttendee} MeetingFeaturesAttendee instance
             */
            MeetingFeaturesAttendee.create = function create(properties) {
                return new MeetingFeaturesAttendee(properties);
            };

            /**
             * Encodes the specified MeetingFeaturesAttendee message. Does not implicitly {@link streem.api.MeetingFeaturesAttendee.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {streem.api.IMeetingFeaturesAttendee} m MeetingFeaturesAttendee message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeaturesAttendee.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.attendeeId != null && Object.hasOwnProperty.call(m, "attendeeId"))
                    $root.streem.api.AttendeeFeatures.encode(m.attendeeId, w.uint32(10).fork()).ldelim();
                if (m.capabilities != null && Object.hasOwnProperty.call(m, "capabilities"))
                    $root.streem.api.MeetingFeaturesAttendeeCapabilities.encode(m.capabilities, w.uint32(18).fork()).ldelim();
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(26).string(m.externalUserId);
                if (m.joinToken != null && Object.hasOwnProperty.call(m, "joinToken"))
                    w.uint32(34).string(m.joinToken);
                return w;
            };

            /**
             * Decodes a MeetingFeaturesAttendee message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeaturesAttendee} MeetingFeaturesAttendee
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeaturesAttendee.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeaturesAttendee();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.attendeeId = $root.streem.api.AttendeeFeatures.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.capabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 4: {
                            m.joinToken = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeaturesAttendee message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeaturesAttendee} MeetingFeaturesAttendee
             */
            MeetingFeaturesAttendee.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeaturesAttendee)
                    return d;
                var m = new $root.streem.api.MeetingFeaturesAttendee();
                if (d.attendeeId != null) {
                    if (typeof d.attendeeId !== "object")
                        throw TypeError(".streem.api.MeetingFeaturesAttendee.attendeeId: object expected");
                    m.attendeeId = $root.streem.api.AttendeeFeatures.fromObject(d.attendeeId);
                }
                if (d.capabilities != null) {
                    if (typeof d.capabilities !== "object")
                        throw TypeError(".streem.api.MeetingFeaturesAttendee.capabilities: object expected");
                    m.capabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.fromObject(d.capabilities);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.joinToken != null) {
                    m.joinToken = String(d.joinToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeaturesAttendee message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {streem.api.MeetingFeaturesAttendee} m MeetingFeaturesAttendee
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeaturesAttendee.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.attendeeId = null;
                    d.capabilities = null;
                    d.externalUserId = "";
                    d.joinToken = "";
                }
                if (m.attendeeId != null && m.hasOwnProperty("attendeeId")) {
                    d.attendeeId = $root.streem.api.AttendeeFeatures.toObject(m.attendeeId, o);
                }
                if (m.capabilities != null && m.hasOwnProperty("capabilities")) {
                    d.capabilities = $root.streem.api.MeetingFeaturesAttendeeCapabilities.toObject(m.capabilities, o);
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.joinToken != null && m.hasOwnProperty("joinToken")) {
                    d.joinToken = m.joinToken;
                }
                return d;
            };

            /**
             * Converts this MeetingFeaturesAttendee to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeaturesAttendee
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeaturesAttendee.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeaturesAttendee
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeaturesAttendee
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeaturesAttendee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeaturesAttendee";
            };

            return MeetingFeaturesAttendee;
        })();

        api.AttendeeFeatures = (function() {

            /**
             * Properties of an AttendeeFeatures.
             * @memberof streem.api
             * @interface IAttendeeFeatures
             * @property {number|null} [maxCount] AttendeeFeatures maxCount
             */

            /**
             * Constructs a new AttendeeFeatures.
             * @memberof streem.api
             * @classdesc Represents an AttendeeFeatures.
             * @implements IAttendeeFeatures
             * @constructor
             * @param {streem.api.IAttendeeFeatures=} [p] Properties to set
             */
            function AttendeeFeatures(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AttendeeFeatures maxCount.
             * @member {number} maxCount
             * @memberof streem.api.AttendeeFeatures
             * @instance
             */
            AttendeeFeatures.prototype.maxCount = 0;

            /**
             * Creates a new AttendeeFeatures instance using the specified properties.
             * @function create
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {streem.api.IAttendeeFeatures=} [properties] Properties to set
             * @returns {streem.api.AttendeeFeatures} AttendeeFeatures instance
             */
            AttendeeFeatures.create = function create(properties) {
                return new AttendeeFeatures(properties);
            };

            /**
             * Encodes the specified AttendeeFeatures message. Does not implicitly {@link streem.api.AttendeeFeatures.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {streem.api.IAttendeeFeatures} m AttendeeFeatures message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AttendeeFeatures.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.maxCount != null && Object.hasOwnProperty.call(m, "maxCount"))
                    w.uint32(8).int32(m.maxCount);
                return w;
            };

            /**
             * Decodes an AttendeeFeatures message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AttendeeFeatures} AttendeeFeatures
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AttendeeFeatures.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AttendeeFeatures();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.maxCount = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AttendeeFeatures message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AttendeeFeatures} AttendeeFeatures
             */
            AttendeeFeatures.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AttendeeFeatures)
                    return d;
                var m = new $root.streem.api.AttendeeFeatures();
                if (d.maxCount != null) {
                    m.maxCount = d.maxCount | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from an AttendeeFeatures message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {streem.api.AttendeeFeatures} m AttendeeFeatures
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AttendeeFeatures.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.maxCount = 0;
                }
                if (m.maxCount != null && m.hasOwnProperty("maxCount")) {
                    d.maxCount = m.maxCount;
                }
                return d;
            };

            /**
             * Converts this AttendeeFeatures to JSON.
             * @function toJSON
             * @memberof streem.api.AttendeeFeatures
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AttendeeFeatures.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AttendeeFeatures
             * @function getTypeUrl
             * @memberof streem.api.AttendeeFeatures
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AttendeeFeatures.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AttendeeFeatures";
            };

            return AttendeeFeatures;
        })();

        api.MeetingFeaturesAttendeeCapabilities = (function() {

            /**
             * Properties of a MeetingFeaturesAttendeeCapabilities.
             * @memberof streem.api
             * @interface IMeetingFeaturesAttendeeCapabilities
             * @property {string|null} [audio] MeetingFeaturesAttendeeCapabilities audio
             * @property {string|null} [content] MeetingFeaturesAttendeeCapabilities content
             * @property {string|null} [video] MeetingFeaturesAttendeeCapabilities video
             */

            /**
             * Constructs a new MeetingFeaturesAttendeeCapabilities.
             * @memberof streem.api
             * @classdesc Represents a MeetingFeaturesAttendeeCapabilities.
             * @implements IMeetingFeaturesAttendeeCapabilities
             * @constructor
             * @param {streem.api.IMeetingFeaturesAttendeeCapabilities=} [p] Properties to set
             */
            function MeetingFeaturesAttendeeCapabilities(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MeetingFeaturesAttendeeCapabilities audio.
             * @member {string} audio
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @instance
             */
            MeetingFeaturesAttendeeCapabilities.prototype.audio = "";

            /**
             * MeetingFeaturesAttendeeCapabilities content.
             * @member {string} content
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @instance
             */
            MeetingFeaturesAttendeeCapabilities.prototype.content = "";

            /**
             * MeetingFeaturesAttendeeCapabilities video.
             * @member {string} video
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @instance
             */
            MeetingFeaturesAttendeeCapabilities.prototype.video = "";

            /**
             * Creates a new MeetingFeaturesAttendeeCapabilities instance using the specified properties.
             * @function create
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {streem.api.IMeetingFeaturesAttendeeCapabilities=} [properties] Properties to set
             * @returns {streem.api.MeetingFeaturesAttendeeCapabilities} MeetingFeaturesAttendeeCapabilities instance
             */
            MeetingFeaturesAttendeeCapabilities.create = function create(properties) {
                return new MeetingFeaturesAttendeeCapabilities(properties);
            };

            /**
             * Encodes the specified MeetingFeaturesAttendeeCapabilities message. Does not implicitly {@link streem.api.MeetingFeaturesAttendeeCapabilities.verify|verify} messages.
             * @function encode
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {streem.api.IMeetingFeaturesAttendeeCapabilities} m MeetingFeaturesAttendeeCapabilities message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MeetingFeaturesAttendeeCapabilities.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.audio != null && Object.hasOwnProperty.call(m, "audio"))
                    w.uint32(10).string(m.audio);
                if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                    w.uint32(18).string(m.content);
                if (m.video != null && Object.hasOwnProperty.call(m, "video"))
                    w.uint32(26).string(m.video);
                return w;
            };

            /**
             * Decodes a MeetingFeaturesAttendeeCapabilities message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.MeetingFeaturesAttendeeCapabilities} MeetingFeaturesAttendeeCapabilities
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MeetingFeaturesAttendeeCapabilities.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.MeetingFeaturesAttendeeCapabilities();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.audio = r.string();
                            break;
                        }
                    case 2: {
                            m.content = r.string();
                            break;
                        }
                    case 3: {
                            m.video = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MeetingFeaturesAttendeeCapabilities message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.MeetingFeaturesAttendeeCapabilities} MeetingFeaturesAttendeeCapabilities
             */
            MeetingFeaturesAttendeeCapabilities.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.MeetingFeaturesAttendeeCapabilities)
                    return d;
                var m = new $root.streem.api.MeetingFeaturesAttendeeCapabilities();
                if (d.audio != null) {
                    m.audio = String(d.audio);
                }
                if (d.content != null) {
                    m.content = String(d.content);
                }
                if (d.video != null) {
                    m.video = String(d.video);
                }
                return m;
            };

            /**
             * Creates a plain object from a MeetingFeaturesAttendeeCapabilities message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {streem.api.MeetingFeaturesAttendeeCapabilities} m MeetingFeaturesAttendeeCapabilities
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MeetingFeaturesAttendeeCapabilities.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.audio = "";
                    d.content = "";
                    d.video = "";
                }
                if (m.audio != null && m.hasOwnProperty("audio")) {
                    d.audio = m.audio;
                }
                if (m.content != null && m.hasOwnProperty("content")) {
                    d.content = m.content;
                }
                if (m.video != null && m.hasOwnProperty("video")) {
                    d.video = m.video;
                }
                return d;
            };

            /**
             * Converts this MeetingFeaturesAttendeeCapabilities to JSON.
             * @function toJSON
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MeetingFeaturesAttendeeCapabilities.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MeetingFeaturesAttendeeCapabilities
             * @function getTypeUrl
             * @memberof streem.api.MeetingFeaturesAttendeeCapabilities
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MeetingFeaturesAttendeeCapabilities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.MeetingFeaturesAttendeeCapabilities";
            };

            return MeetingFeaturesAttendeeCapabilities;
        })();

        api.CallLogRoomInvitation = (function() {

            /**
             * Properties of a CallLogRoomInvitation.
             * @memberof streem.api
             * @interface ICallLogRoomInvitation
             * @property {streem.api.ICallLogParticipant|null} [fromUser] CallLogRoomInvitation fromUser
             * @property {streem.api.ICallLogParticipant|null} [toUser] CallLogRoomInvitation toUser
             */

            /**
             * Constructs a new CallLogRoomInvitation.
             * @memberof streem.api
             * @classdesc Represents a CallLogRoomInvitation.
             * @implements ICallLogRoomInvitation
             * @constructor
             * @param {streem.api.ICallLogRoomInvitation=} [p] Properties to set
             */
            function CallLogRoomInvitation(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CallLogRoomInvitation fromUser.
             * @member {streem.api.ICallLogParticipant|null|undefined} fromUser
             * @memberof streem.api.CallLogRoomInvitation
             * @instance
             */
            CallLogRoomInvitation.prototype.fromUser = null;

            /**
             * CallLogRoomInvitation toUser.
             * @member {streem.api.ICallLogParticipant|null|undefined} toUser
             * @memberof streem.api.CallLogRoomInvitation
             * @instance
             */
            CallLogRoomInvitation.prototype.toUser = null;

            /**
             * Creates a new CallLogRoomInvitation instance using the specified properties.
             * @function create
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {streem.api.ICallLogRoomInvitation=} [properties] Properties to set
             * @returns {streem.api.CallLogRoomInvitation} CallLogRoomInvitation instance
             */
            CallLogRoomInvitation.create = function create(properties) {
                return new CallLogRoomInvitation(properties);
            };

            /**
             * Encodes the specified CallLogRoomInvitation message. Does not implicitly {@link streem.api.CallLogRoomInvitation.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {streem.api.ICallLogRoomInvitation} m CallLogRoomInvitation message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallLogRoomInvitation.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.fromUser != null && Object.hasOwnProperty.call(m, "fromUser"))
                    $root.streem.api.CallLogParticipant.encode(m.fromUser, w.uint32(10).fork()).ldelim();
                if (m.toUser != null && Object.hasOwnProperty.call(m, "toUser"))
                    $root.streem.api.CallLogParticipant.encode(m.toUser, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CallLogRoomInvitation message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CallLogRoomInvitation} CallLogRoomInvitation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallLogRoomInvitation.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallLogRoomInvitation();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.fromUser = $root.streem.api.CallLogParticipant.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.toUser = $root.streem.api.CallLogParticipant.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CallLogRoomInvitation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CallLogRoomInvitation} CallLogRoomInvitation
             */
            CallLogRoomInvitation.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CallLogRoomInvitation)
                    return d;
                var m = new $root.streem.api.CallLogRoomInvitation();
                if (d.fromUser != null) {
                    if (typeof d.fromUser !== "object")
                        throw TypeError(".streem.api.CallLogRoomInvitation.fromUser: object expected");
                    m.fromUser = $root.streem.api.CallLogParticipant.fromObject(d.fromUser);
                }
                if (d.toUser != null) {
                    if (typeof d.toUser !== "object")
                        throw TypeError(".streem.api.CallLogRoomInvitation.toUser: object expected");
                    m.toUser = $root.streem.api.CallLogParticipant.fromObject(d.toUser);
                }
                return m;
            };

            /**
             * Creates a plain object from a CallLogRoomInvitation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {streem.api.CallLogRoomInvitation} m CallLogRoomInvitation
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallLogRoomInvitation.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.fromUser = null;
                    d.toUser = null;
                }
                if (m.fromUser != null && m.hasOwnProperty("fromUser")) {
                    d.fromUser = $root.streem.api.CallLogParticipant.toObject(m.fromUser, o);
                }
                if (m.toUser != null && m.hasOwnProperty("toUser")) {
                    d.toUser = $root.streem.api.CallLogParticipant.toObject(m.toUser, o);
                }
                return d;
            };

            /**
             * Converts this CallLogRoomInvitation to JSON.
             * @function toJSON
             * @memberof streem.api.CallLogRoomInvitation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallLogRoomInvitation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CallLogRoomInvitation
             * @function getTypeUrl
             * @memberof streem.api.CallLogRoomInvitation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallLogRoomInvitation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CallLogRoomInvitation";
            };

            return CallLogRoomInvitation;
        })();

        api.CallLogEntry = (function() {

            /**
             * Properties of a CallLogEntry.
             * @memberof streem.api
             * @interface ICallLogEntry
             * @property {string|null} [roomSid] CallLogEntry roomSid
             * @property {google.protobuf.ITimestamp|null} [startTime] CallLogEntry startTime
             * @property {google.protobuf.ITimestamp|null} [endTime] CallLogEntry endTime
             * @property {Array.<streem.api.ICallLogParticipant>|null} [participants] CallLogEntry participants
             * @property {boolean|null} [missed] CallLogEntry missed
             * @property {boolean|null} [onsite] CallLogEntry onsite
             * @property {streem.api.IArtifactCounts|null} [artifactCounts] CallLogEntry artifactCounts
             * @property {string|null} [shareUrl] CallLogEntry shareUrl
             * @property {string|null} [referenceId] CallLogEntry referenceId
             * @property {string|null} [createdByUserSid] CallLogEntry createdByUserSid
             * @property {streem.api.INote|null} [note] CallLogEntry note
             * @property {streem.api.Artifact.IGpsPosition|null} [latestGpsPosition] CallLogEntry latestGpsPosition
             * @property {Array.<streem.api.ICallLogRoomInvitation>|null} [invitations] CallLogEntry invitations
             * @property {boolean|null} [favorite] CallLogEntry favorite
             * @property {string|null} [integrationId] CallLogEntry integrationId
             * @property {streem.api.CallLogEntry.Status|null} [callStatus] CallLogEntry callStatus
             * @property {string|null} [canceledCallRingTimeInSecs] CallLogEntry canceledCallRingTimeInSecs
             * @property {google.protobuf.IDuration|null} [callDuration] CallLogEntry callDuration
             */

            /**
             * Constructs a new CallLogEntry.
             * @memberof streem.api
             * @classdesc Represents a CallLogEntry.
             * @implements ICallLogEntry
             * @constructor
             * @param {streem.api.ICallLogEntry=} [p] Properties to set
             */
            function CallLogEntry(p) {
                this.participants = [];
                this.invitations = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CallLogEntry roomSid.
             * @member {string} roomSid
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.roomSid = "";

            /**
             * CallLogEntry startTime.
             * @member {google.protobuf.ITimestamp|null|undefined} startTime
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.startTime = null;

            /**
             * CallLogEntry endTime.
             * @member {google.protobuf.ITimestamp|null|undefined} endTime
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.endTime = null;

            /**
             * CallLogEntry participants.
             * @member {Array.<streem.api.ICallLogParticipant>} participants
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.participants = $util.emptyArray;

            /**
             * CallLogEntry missed.
             * @member {boolean} missed
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.missed = false;

            /**
             * CallLogEntry onsite.
             * @member {boolean} onsite
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.onsite = false;

            /**
             * CallLogEntry artifactCounts.
             * @member {streem.api.IArtifactCounts|null|undefined} artifactCounts
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.artifactCounts = null;

            /**
             * CallLogEntry shareUrl.
             * @member {string} shareUrl
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.shareUrl = "";

            /**
             * CallLogEntry referenceId.
             * @member {string} referenceId
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.referenceId = "";

            /**
             * CallLogEntry createdByUserSid.
             * @member {string} createdByUserSid
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.createdByUserSid = "";

            /**
             * CallLogEntry note.
             * @member {streem.api.INote|null|undefined} note
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.note = null;

            /**
             * CallLogEntry latestGpsPosition.
             * @member {streem.api.Artifact.IGpsPosition|null|undefined} latestGpsPosition
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.latestGpsPosition = null;

            /**
             * CallLogEntry invitations.
             * @member {Array.<streem.api.ICallLogRoomInvitation>} invitations
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.invitations = $util.emptyArray;

            /**
             * CallLogEntry favorite.
             * @member {boolean} favorite
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.favorite = false;

            /**
             * CallLogEntry integrationId.
             * @member {string} integrationId
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.integrationId = "";

            /**
             * CallLogEntry callStatus.
             * @member {streem.api.CallLogEntry.Status} callStatus
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.callStatus = 0;

            /**
             * CallLogEntry canceledCallRingTimeInSecs.
             * @member {string} canceledCallRingTimeInSecs
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.canceledCallRingTimeInSecs = "";

            /**
             * CallLogEntry callDuration.
             * @member {google.protobuf.IDuration|null|undefined} callDuration
             * @memberof streem.api.CallLogEntry
             * @instance
             */
            CallLogEntry.prototype.callDuration = null;

            /**
             * Creates a new CallLogEntry instance using the specified properties.
             * @function create
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {streem.api.ICallLogEntry=} [properties] Properties to set
             * @returns {streem.api.CallLogEntry} CallLogEntry instance
             */
            CallLogEntry.create = function create(properties) {
                return new CallLogEntry(properties);
            };

            /**
             * Encodes the specified CallLogEntry message. Does not implicitly {@link streem.api.CallLogEntry.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {streem.api.ICallLogEntry} m CallLogEntry message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallLogEntry.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                    $root.google.protobuf.Timestamp.encode(m.startTime, w.uint32(18).fork()).ldelim();
                if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                    $root.google.protobuf.Timestamp.encode(m.endTime, w.uint32(26).fork()).ldelim();
                if (m.participants != null && m.participants.length) {
                    for (var i = 0; i < m.participants.length; ++i)
                        $root.streem.api.CallLogParticipant.encode(m.participants[i], w.uint32(34).fork()).ldelim();
                }
                if (m.missed != null && Object.hasOwnProperty.call(m, "missed"))
                    w.uint32(40).bool(m.missed);
                if (m.onsite != null && Object.hasOwnProperty.call(m, "onsite"))
                    w.uint32(48).bool(m.onsite);
                if (m.artifactCounts != null && Object.hasOwnProperty.call(m, "artifactCounts"))
                    $root.streem.api.ArtifactCounts.encode(m.artifactCounts, w.uint32(58).fork()).ldelim();
                if (m.shareUrl != null && Object.hasOwnProperty.call(m, "shareUrl"))
                    w.uint32(66).string(m.shareUrl);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(74).string(m.referenceId);
                if (m.createdByUserSid != null && Object.hasOwnProperty.call(m, "createdByUserSid"))
                    w.uint32(82).string(m.createdByUserSid);
                if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                    $root.streem.api.Note.encode(m.note, w.uint32(90).fork()).ldelim();
                if (m.latestGpsPosition != null && Object.hasOwnProperty.call(m, "latestGpsPosition"))
                    $root.streem.api.Artifact.GpsPosition.encode(m.latestGpsPosition, w.uint32(98).fork()).ldelim();
                if (m.invitations != null && m.invitations.length) {
                    for (var i = 0; i < m.invitations.length; ++i)
                        $root.streem.api.CallLogRoomInvitation.encode(m.invitations[i], w.uint32(106).fork()).ldelim();
                }
                if (m.favorite != null && Object.hasOwnProperty.call(m, "favorite"))
                    w.uint32(112).bool(m.favorite);
                if (m.integrationId != null && Object.hasOwnProperty.call(m, "integrationId"))
                    w.uint32(122).string(m.integrationId);
                if (m.callStatus != null && Object.hasOwnProperty.call(m, "callStatus"))
                    w.uint32(128).int32(m.callStatus);
                if (m.canceledCallRingTimeInSecs != null && Object.hasOwnProperty.call(m, "canceledCallRingTimeInSecs"))
                    w.uint32(138).string(m.canceledCallRingTimeInSecs);
                if (m.callDuration != null && Object.hasOwnProperty.call(m, "callDuration"))
                    $root.google.protobuf.Duration.encode(m.callDuration, w.uint32(146).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a CallLogEntry message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CallLogEntry} CallLogEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallLogEntry.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallLogEntry();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.startTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.endTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            if (!(m.participants && m.participants.length))
                                m.participants = [];
                            m.participants.push($root.streem.api.CallLogParticipant.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            m.missed = r.bool();
                            break;
                        }
                    case 6: {
                            m.onsite = r.bool();
                            break;
                        }
                    case 7: {
                            m.artifactCounts = $root.streem.api.ArtifactCounts.decode(r, r.uint32());
                            break;
                        }
                    case 8: {
                            m.shareUrl = r.string();
                            break;
                        }
                    case 9: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 10: {
                            m.createdByUserSid = r.string();
                            break;
                        }
                    case 11: {
                            m.note = $root.streem.api.Note.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.latestGpsPosition = $root.streem.api.Artifact.GpsPosition.decode(r, r.uint32());
                            break;
                        }
                    case 13: {
                            if (!(m.invitations && m.invitations.length))
                                m.invitations = [];
                            m.invitations.push($root.streem.api.CallLogRoomInvitation.decode(r, r.uint32()));
                            break;
                        }
                    case 14: {
                            m.favorite = r.bool();
                            break;
                        }
                    case 15: {
                            m.integrationId = r.string();
                            break;
                        }
                    case 16: {
                            m.callStatus = r.int32();
                            break;
                        }
                    case 17: {
                            m.canceledCallRingTimeInSecs = r.string();
                            break;
                        }
                    case 18: {
                            m.callDuration = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CallLogEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CallLogEntry} CallLogEntry
             */
            CallLogEntry.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CallLogEntry)
                    return d;
                var m = new $root.streem.api.CallLogEntry();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.startTime != null) {
                    if (typeof d.startTime !== "object")
                        throw TypeError(".streem.api.CallLogEntry.startTime: object expected");
                    m.startTime = $root.google.protobuf.Timestamp.fromObject(d.startTime);
                }
                if (d.endTime != null) {
                    if (typeof d.endTime !== "object")
                        throw TypeError(".streem.api.CallLogEntry.endTime: object expected");
                    m.endTime = $root.google.protobuf.Timestamp.fromObject(d.endTime);
                }
                if (d.participants) {
                    if (!Array.isArray(d.participants))
                        throw TypeError(".streem.api.CallLogEntry.participants: array expected");
                    m.participants = [];
                    for (var i = 0; i < d.participants.length; ++i) {
                        if (typeof d.participants[i] !== "object")
                            throw TypeError(".streem.api.CallLogEntry.participants: object expected");
                        m.participants[i] = $root.streem.api.CallLogParticipant.fromObject(d.participants[i]);
                    }
                }
                if (d.missed != null) {
                    m.missed = Boolean(d.missed);
                }
                if (d.onsite != null) {
                    m.onsite = Boolean(d.onsite);
                }
                if (d.artifactCounts != null) {
                    if (typeof d.artifactCounts !== "object")
                        throw TypeError(".streem.api.CallLogEntry.artifactCounts: object expected");
                    m.artifactCounts = $root.streem.api.ArtifactCounts.fromObject(d.artifactCounts);
                }
                if (d.shareUrl != null) {
                    m.shareUrl = String(d.shareUrl);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.createdByUserSid != null) {
                    m.createdByUserSid = String(d.createdByUserSid);
                }
                if (d.note != null) {
                    if (typeof d.note !== "object")
                        throw TypeError(".streem.api.CallLogEntry.note: object expected");
                    m.note = $root.streem.api.Note.fromObject(d.note);
                }
                if (d.latestGpsPosition != null) {
                    if (typeof d.latestGpsPosition !== "object")
                        throw TypeError(".streem.api.CallLogEntry.latestGpsPosition: object expected");
                    m.latestGpsPosition = $root.streem.api.Artifact.GpsPosition.fromObject(d.latestGpsPosition);
                }
                if (d.invitations) {
                    if (!Array.isArray(d.invitations))
                        throw TypeError(".streem.api.CallLogEntry.invitations: array expected");
                    m.invitations = [];
                    for (var i = 0; i < d.invitations.length; ++i) {
                        if (typeof d.invitations[i] !== "object")
                            throw TypeError(".streem.api.CallLogEntry.invitations: object expected");
                        m.invitations[i] = $root.streem.api.CallLogRoomInvitation.fromObject(d.invitations[i]);
                    }
                }
                if (d.favorite != null) {
                    m.favorite = Boolean(d.favorite);
                }
                if (d.integrationId != null) {
                    m.integrationId = String(d.integrationId);
                }
                switch (d.callStatus) {
                default:
                    if (typeof d.callStatus === "number") {
                        m.callStatus = d.callStatus;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.callStatus = 0;
                    break;
                case "STATUS_SUCCESSFUL":
                case 1:
                    m.callStatus = 1;
                    break;
                case "STATUS_CANCELED":
                case 2:
                    m.callStatus = 2;
                    break;
                case "STATUS_REJECTED":
                case 3:
                    m.callStatus = 3;
                    break;
                case "STATUS_TIMED_OUT":
                case 4:
                    m.callStatus = 4;
                    break;
                }
                if (d.canceledCallRingTimeInSecs != null) {
                    m.canceledCallRingTimeInSecs = String(d.canceledCallRingTimeInSecs);
                }
                if (d.callDuration != null) {
                    if (typeof d.callDuration !== "object")
                        throw TypeError(".streem.api.CallLogEntry.callDuration: object expected");
                    m.callDuration = $root.google.protobuf.Duration.fromObject(d.callDuration);
                }
                return m;
            };

            /**
             * Creates a plain object from a CallLogEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {streem.api.CallLogEntry} m CallLogEntry
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallLogEntry.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.participants = [];
                    d.invitations = [];
                }
                if (o.defaults) {
                    d.roomSid = "";
                    d.startTime = null;
                    d.endTime = null;
                    d.missed = false;
                    d.onsite = false;
                    d.artifactCounts = null;
                    d.shareUrl = "";
                    d.referenceId = "";
                    d.createdByUserSid = "";
                    d.note = null;
                    d.latestGpsPosition = null;
                    d.favorite = false;
                    d.integrationId = "";
                    d.callStatus = o.enums === String ? "STATUS_INVALID" : 0;
                    d.canceledCallRingTimeInSecs = "";
                    d.callDuration = null;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.startTime != null && m.hasOwnProperty("startTime")) {
                    d.startTime = $root.google.protobuf.Timestamp.toObject(m.startTime, o);
                }
                if (m.endTime != null && m.hasOwnProperty("endTime")) {
                    d.endTime = $root.google.protobuf.Timestamp.toObject(m.endTime, o);
                }
                if (m.participants && m.participants.length) {
                    d.participants = [];
                    for (var j = 0; j < m.participants.length; ++j) {
                        d.participants[j] = $root.streem.api.CallLogParticipant.toObject(m.participants[j], o);
                    }
                }
                if (m.missed != null && m.hasOwnProperty("missed")) {
                    d.missed = m.missed;
                }
                if (m.onsite != null && m.hasOwnProperty("onsite")) {
                    d.onsite = m.onsite;
                }
                if (m.artifactCounts != null && m.hasOwnProperty("artifactCounts")) {
                    d.artifactCounts = $root.streem.api.ArtifactCounts.toObject(m.artifactCounts, o);
                }
                if (m.shareUrl != null && m.hasOwnProperty("shareUrl")) {
                    d.shareUrl = m.shareUrl;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.createdByUserSid != null && m.hasOwnProperty("createdByUserSid")) {
                    d.createdByUserSid = m.createdByUserSid;
                }
                if (m.note != null && m.hasOwnProperty("note")) {
                    d.note = $root.streem.api.Note.toObject(m.note, o);
                }
                if (m.latestGpsPosition != null && m.hasOwnProperty("latestGpsPosition")) {
                    d.latestGpsPosition = $root.streem.api.Artifact.GpsPosition.toObject(m.latestGpsPosition, o);
                }
                if (m.invitations && m.invitations.length) {
                    d.invitations = [];
                    for (var j = 0; j < m.invitations.length; ++j) {
                        d.invitations[j] = $root.streem.api.CallLogRoomInvitation.toObject(m.invitations[j], o);
                    }
                }
                if (m.favorite != null && m.hasOwnProperty("favorite")) {
                    d.favorite = m.favorite;
                }
                if (m.integrationId != null && m.hasOwnProperty("integrationId")) {
                    d.integrationId = m.integrationId;
                }
                if (m.callStatus != null && m.hasOwnProperty("callStatus")) {
                    d.callStatus = o.enums === String ? $root.streem.api.CallLogEntry.Status[m.callStatus] === undefined ? m.callStatus : $root.streem.api.CallLogEntry.Status[m.callStatus] : m.callStatus;
                }
                if (m.canceledCallRingTimeInSecs != null && m.hasOwnProperty("canceledCallRingTimeInSecs")) {
                    d.canceledCallRingTimeInSecs = m.canceledCallRingTimeInSecs;
                }
                if (m.callDuration != null && m.hasOwnProperty("callDuration")) {
                    d.callDuration = $root.google.protobuf.Duration.toObject(m.callDuration, o);
                }
                return d;
            };

            /**
             * Converts this CallLogEntry to JSON.
             * @function toJSON
             * @memberof streem.api.CallLogEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallLogEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CallLogEntry
             * @function getTypeUrl
             * @memberof streem.api.CallLogEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallLogEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CallLogEntry";
            };

            /**
             * Status enum.
             * @name streem.api.CallLogEntry.Status
             * @enum {number}
             * @property {number} STATUS_INVALID=0 STATUS_INVALID value
             * @property {number} STATUS_SUCCESSFUL=1 STATUS_SUCCESSFUL value
             * @property {number} STATUS_CANCELED=2 STATUS_CANCELED value
             * @property {number} STATUS_REJECTED=3 STATUS_REJECTED value
             * @property {number} STATUS_TIMED_OUT=4 STATUS_TIMED_OUT value
             */
            CallLogEntry.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_INVALID"] = 0;
                values[valuesById[1] = "STATUS_SUCCESSFUL"] = 1;
                values[valuesById[2] = "STATUS_CANCELED"] = 2;
                values[valuesById[3] = "STATUS_REJECTED"] = 3;
                values[valuesById[4] = "STATUS_TIMED_OUT"] = 4;
                return values;
            })();

            return CallLogEntry;
        })();

        api.CallLogParticipant = (function() {

            /**
             * Properties of a CallLogParticipant.
             * @memberof streem.api
             * @interface ICallLogParticipant
             * @property {string|null} [userSid] CallLogParticipant userSid
             * @property {string|null} [externalUserId] CallLogParticipant externalUserId
             * @property {string|null} [name] CallLogParticipant name
             * @property {string|null} [avatarUrl] CallLogParticipant avatarUrl
             * @property {string|null} [phone] CallLogParticipant phone
             * @property {boolean|null} [isExpert] CallLogParticipant isExpert
             */

            /**
             * Constructs a new CallLogParticipant.
             * @memberof streem.api
             * @classdesc Represents a CallLogParticipant.
             * @implements ICallLogParticipant
             * @constructor
             * @param {streem.api.ICallLogParticipant=} [p] Properties to set
             */
            function CallLogParticipant(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CallLogParticipant userSid.
             * @member {string} userSid
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.userSid = "";

            /**
             * CallLogParticipant externalUserId.
             * @member {string} externalUserId
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.externalUserId = "";

            /**
             * CallLogParticipant name.
             * @member {string} name
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.name = "";

            /**
             * CallLogParticipant avatarUrl.
             * @member {string} avatarUrl
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.avatarUrl = "";

            /**
             * CallLogParticipant phone.
             * @member {string} phone
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.phone = "";

            /**
             * CallLogParticipant isExpert.
             * @member {boolean} isExpert
             * @memberof streem.api.CallLogParticipant
             * @instance
             */
            CallLogParticipant.prototype.isExpert = false;

            /**
             * Creates a new CallLogParticipant instance using the specified properties.
             * @function create
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {streem.api.ICallLogParticipant=} [properties] Properties to set
             * @returns {streem.api.CallLogParticipant} CallLogParticipant instance
             */
            CallLogParticipant.create = function create(properties) {
                return new CallLogParticipant(properties);
            };

            /**
             * Encodes the specified CallLogParticipant message. Does not implicitly {@link streem.api.CallLogParticipant.verify|verify} messages.
             * @function encode
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {streem.api.ICallLogParticipant} m CallLogParticipant message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallLogParticipant.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                    w.uint32(10).string(m.userSid);
                if (m.externalUserId != null && Object.hasOwnProperty.call(m, "externalUserId"))
                    w.uint32(18).string(m.externalUserId);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.avatarUrl != null && Object.hasOwnProperty.call(m, "avatarUrl"))
                    w.uint32(34).string(m.avatarUrl);
                if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                    w.uint32(42).string(m.phone);
                if (m.isExpert != null && Object.hasOwnProperty.call(m, "isExpert"))
                    w.uint32(48).bool(m.isExpert);
                return w;
            };

            /**
             * Decodes a CallLogParticipant message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.CallLogParticipant} CallLogParticipant
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallLogParticipant.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.CallLogParticipant();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userSid = r.string();
                            break;
                        }
                    case 2: {
                            m.externalUserId = r.string();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    case 4: {
                            m.avatarUrl = r.string();
                            break;
                        }
                    case 5: {
                            m.phone = r.string();
                            break;
                        }
                    case 6: {
                            m.isExpert = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CallLogParticipant message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.CallLogParticipant} CallLogParticipant
             */
            CallLogParticipant.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.CallLogParticipant)
                    return d;
                var m = new $root.streem.api.CallLogParticipant();
                if (d.userSid != null) {
                    m.userSid = String(d.userSid);
                }
                if (d.externalUserId != null) {
                    m.externalUserId = String(d.externalUserId);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.avatarUrl != null) {
                    m.avatarUrl = String(d.avatarUrl);
                }
                if (d.phone != null) {
                    m.phone = String(d.phone);
                }
                if (d.isExpert != null) {
                    m.isExpert = Boolean(d.isExpert);
                }
                return m;
            };

            /**
             * Creates a plain object from a CallLogParticipant message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {streem.api.CallLogParticipant} m CallLogParticipant
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallLogParticipant.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userSid = "";
                    d.externalUserId = "";
                    d.name = "";
                    d.avatarUrl = "";
                    d.phone = "";
                    d.isExpert = false;
                }
                if (m.userSid != null && m.hasOwnProperty("userSid")) {
                    d.userSid = m.userSid;
                }
                if (m.externalUserId != null && m.hasOwnProperty("externalUserId")) {
                    d.externalUserId = m.externalUserId;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.avatarUrl != null && m.hasOwnProperty("avatarUrl")) {
                    d.avatarUrl = m.avatarUrl;
                }
                if (m.phone != null && m.hasOwnProperty("phone")) {
                    d.phone = m.phone;
                }
                if (m.isExpert != null && m.hasOwnProperty("isExpert")) {
                    d.isExpert = m.isExpert;
                }
                return d;
            };

            /**
             * Converts this CallLogParticipant to JSON.
             * @function toJSON
             * @memberof streem.api.CallLogParticipant
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallLogParticipant.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CallLogParticipant
             * @function getTypeUrl
             * @memberof streem.api.CallLogParticipant
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallLogParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.CallLogParticipant";
            };

            return CallLogParticipant;
        })();

        api.RoomRecordingEvent = (function() {

            /**
             * Properties of a RoomRecordingEvent.
             * @memberof streem.api
             * @interface IRoomRecordingEvent
             * @property {string|null} [roomSid] RoomRecordingEvent roomSid
             * @property {streem.api.RoomRecordingEvent.Status|null} [recordingEventStatus] RoomRecordingEvent recordingEventStatus
             * @property {google.protobuf.ITimestamp|null} [eventTimestamp] RoomRecordingEvent eventTimestamp
             */

            /**
             * Constructs a new RoomRecordingEvent.
             * @memberof streem.api
             * @classdesc Represents a RoomRecordingEvent.
             * @implements IRoomRecordingEvent
             * @constructor
             * @param {streem.api.IRoomRecordingEvent=} [p] Properties to set
             */
            function RoomRecordingEvent(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RoomRecordingEvent roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RoomRecordingEvent
             * @instance
             */
            RoomRecordingEvent.prototype.roomSid = "";

            /**
             * RoomRecordingEvent recordingEventStatus.
             * @member {streem.api.RoomRecordingEvent.Status} recordingEventStatus
             * @memberof streem.api.RoomRecordingEvent
             * @instance
             */
            RoomRecordingEvent.prototype.recordingEventStatus = 0;

            /**
             * RoomRecordingEvent eventTimestamp.
             * @member {google.protobuf.ITimestamp|null|undefined} eventTimestamp
             * @memberof streem.api.RoomRecordingEvent
             * @instance
             */
            RoomRecordingEvent.prototype.eventTimestamp = null;

            /**
             * Creates a new RoomRecordingEvent instance using the specified properties.
             * @function create
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {streem.api.IRoomRecordingEvent=} [properties] Properties to set
             * @returns {streem.api.RoomRecordingEvent} RoomRecordingEvent instance
             */
            RoomRecordingEvent.create = function create(properties) {
                return new RoomRecordingEvent(properties);
            };

            /**
             * Encodes the specified RoomRecordingEvent message. Does not implicitly {@link streem.api.RoomRecordingEvent.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {streem.api.IRoomRecordingEvent} m RoomRecordingEvent message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomRecordingEvent.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(10).string(m.roomSid);
                if (m.recordingEventStatus != null && Object.hasOwnProperty.call(m, "recordingEventStatus"))
                    w.uint32(16).int32(m.recordingEventStatus);
                if (m.eventTimestamp != null && Object.hasOwnProperty.call(m, "eventTimestamp"))
                    $root.google.protobuf.Timestamp.encode(m.eventTimestamp, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a RoomRecordingEvent message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RoomRecordingEvent} RoomRecordingEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomRecordingEvent.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RoomRecordingEvent();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 2: {
                            m.recordingEventStatus = r.int32();
                            break;
                        }
                    case 3: {
                            m.eventTimestamp = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RoomRecordingEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RoomRecordingEvent} RoomRecordingEvent
             */
            RoomRecordingEvent.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RoomRecordingEvent)
                    return d;
                var m = new $root.streem.api.RoomRecordingEvent();
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                switch (d.recordingEventStatus) {
                default:
                    if (typeof d.recordingEventStatus === "number") {
                        m.recordingEventStatus = d.recordingEventStatus;
                        break;
                    }
                    break;
                case "STATUS_INVALID":
                case 0:
                    m.recordingEventStatus = 0;
                    break;
                case "STATUS_IN_PROGRESS":
                case 1:
                    m.recordingEventStatus = 1;
                    break;
                case "STATUS_PAUSED":
                case 2:
                    m.recordingEventStatus = 2;
                    break;
                }
                if (d.eventTimestamp != null) {
                    if (typeof d.eventTimestamp !== "object")
                        throw TypeError(".streem.api.RoomRecordingEvent.eventTimestamp: object expected");
                    m.eventTimestamp = $root.google.protobuf.Timestamp.fromObject(d.eventTimestamp);
                }
                return m;
            };

            /**
             * Creates a plain object from a RoomRecordingEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {streem.api.RoomRecordingEvent} m RoomRecordingEvent
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomRecordingEvent.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomSid = "";
                    d.recordingEventStatus = o.enums === String ? "STATUS_INVALID" : 0;
                    d.eventTimestamp = null;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.recordingEventStatus != null && m.hasOwnProperty("recordingEventStatus")) {
                    d.recordingEventStatus = o.enums === String ? $root.streem.api.RoomRecordingEvent.Status[m.recordingEventStatus] === undefined ? m.recordingEventStatus : $root.streem.api.RoomRecordingEvent.Status[m.recordingEventStatus] : m.recordingEventStatus;
                }
                if (m.eventTimestamp != null && m.hasOwnProperty("eventTimestamp")) {
                    d.eventTimestamp = $root.google.protobuf.Timestamp.toObject(m.eventTimestamp, o);
                }
                return d;
            };

            /**
             * Converts this RoomRecordingEvent to JSON.
             * @function toJSON
             * @memberof streem.api.RoomRecordingEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomRecordingEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoomRecordingEvent
             * @function getTypeUrl
             * @memberof streem.api.RoomRecordingEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomRecordingEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RoomRecordingEvent";
            };

            /**
             * Status enum.
             * @name streem.api.RoomRecordingEvent.Status
             * @enum {number}
             * @property {number} STATUS_INVALID=0 STATUS_INVALID value
             * @property {number} STATUS_IN_PROGRESS=1 STATUS_IN_PROGRESS value
             * @property {number} STATUS_PAUSED=2 STATUS_PAUSED value
             */
            RoomRecordingEvent.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_INVALID"] = 0;
                values[valuesById[1] = "STATUS_IN_PROGRESS"] = 1;
                values[valuesById[2] = "STATUS_PAUSED"] = 2;
                return values;
            })();

            return RoomRecordingEvent;
        })();

        api.RoomArchiveAudit = (function() {

            /**
             * Properties of a RoomArchiveAudit.
             * @memberof streem.api
             * @interface IRoomArchiveAudit
             * @property {string|null} [roomArchiveAuditSid] RoomArchiveAudit roomArchiveAuditSid
             * @property {string|null} [roomSid] RoomArchiveAudit roomSid
             * @property {google.protobuf.ITimestamp|null} [archiveScheduledAt] RoomArchiveAudit archiveScheduledAt
             * @property {google.protobuf.ITimestamp|null} [archivedAt] RoomArchiveAudit archivedAt
             * @property {string|null} [companySid] RoomArchiveAudit companySid
             * @property {string|null} [referenceId] RoomArchiveAudit referenceId
             * @property {string|null} [reservationSid] RoomArchiveAudit reservationSid
             * @property {string|null} [reservationGroupSid] RoomArchiveAudit reservationGroupSid
             * @property {string|null} [reservationGroupName] RoomArchiveAudit reservationGroupName
             * @property {google.protobuf.ITimestamp|null} [roomOpenedAt] RoomArchiveAudit roomOpenedAt
             * @property {google.protobuf.ITimestamp|null} [roomDeletedAt] RoomArchiveAudit roomDeletedAt
             * @property {string|null} [fromUserSid] RoomArchiveAudit fromUserSid
             * @property {string|null} [fromDeviceSid] RoomArchiveAudit fromDeviceSid
             * @property {string|null} [fromDeviceTimezone] RoomArchiveAudit fromDeviceTimezone
             * @property {string|null} [toUserSid] RoomArchiveAudit toUserSid
             * @property {string|null} [toDeviceSid] RoomArchiveAudit toDeviceSid
             * @property {string|null} [compositionArtifactSid] RoomArchiveAudit compositionArtifactSid
             * @property {string|null} [archiveS3Folder] RoomArchiveAudit archiveS3Folder
             * @property {string|null} [deactivatedByUserSid] RoomArchiveAudit deactivatedByUserSid
             * @property {string|null} [deactivatedByRole] RoomArchiveAudit deactivatedByRole
             * @property {google.protobuf.ITimestamp|null} [deactivatedAt] RoomArchiveAudit deactivatedAt
             * @property {google.protobuf.ITimestamp|null} [createdAt] RoomArchiveAudit createdAt
             * @property {google.protobuf.ITimestamp|null} [modifiedAt] RoomArchiveAudit modifiedAt
             */

            /**
             * Constructs a new RoomArchiveAudit.
             * @memberof streem.api
             * @classdesc Represents a RoomArchiveAudit.
             * @implements IRoomArchiveAudit
             * @constructor
             * @param {streem.api.IRoomArchiveAudit=} [p] Properties to set
             */
            function RoomArchiveAudit(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RoomArchiveAudit roomArchiveAuditSid.
             * @member {string} roomArchiveAuditSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.roomArchiveAuditSid = "";

            /**
             * RoomArchiveAudit roomSid.
             * @member {string} roomSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.roomSid = "";

            /**
             * RoomArchiveAudit archiveScheduledAt.
             * @member {google.protobuf.ITimestamp|null|undefined} archiveScheduledAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.archiveScheduledAt = null;

            /**
             * RoomArchiveAudit archivedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} archivedAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.archivedAt = null;

            /**
             * RoomArchiveAudit companySid.
             * @member {string} companySid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.companySid = "";

            /**
             * RoomArchiveAudit referenceId.
             * @member {string} referenceId
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.referenceId = "";

            /**
             * RoomArchiveAudit reservationSid.
             * @member {string} reservationSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.reservationSid = "";

            /**
             * RoomArchiveAudit reservationGroupSid.
             * @member {string} reservationGroupSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.reservationGroupSid = "";

            /**
             * RoomArchiveAudit reservationGroupName.
             * @member {string} reservationGroupName
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.reservationGroupName = "";

            /**
             * RoomArchiveAudit roomOpenedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} roomOpenedAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.roomOpenedAt = null;

            /**
             * RoomArchiveAudit roomDeletedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} roomDeletedAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.roomDeletedAt = null;

            /**
             * RoomArchiveAudit fromUserSid.
             * @member {string} fromUserSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.fromUserSid = "";

            /**
             * RoomArchiveAudit fromDeviceSid.
             * @member {string} fromDeviceSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.fromDeviceSid = "";

            /**
             * RoomArchiveAudit fromDeviceTimezone.
             * @member {string} fromDeviceTimezone
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.fromDeviceTimezone = "";

            /**
             * RoomArchiveAudit toUserSid.
             * @member {string} toUserSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.toUserSid = "";

            /**
             * RoomArchiveAudit toDeviceSid.
             * @member {string} toDeviceSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.toDeviceSid = "";

            /**
             * RoomArchiveAudit compositionArtifactSid.
             * @member {string} compositionArtifactSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.compositionArtifactSid = "";

            /**
             * RoomArchiveAudit archiveS3Folder.
             * @member {string} archiveS3Folder
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.archiveS3Folder = "";

            /**
             * RoomArchiveAudit deactivatedByUserSid.
             * @member {string} deactivatedByUserSid
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.deactivatedByUserSid = "";

            /**
             * RoomArchiveAudit deactivatedByRole.
             * @member {string} deactivatedByRole
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.deactivatedByRole = "";

            /**
             * RoomArchiveAudit deactivatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} deactivatedAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.deactivatedAt = null;

            /**
             * RoomArchiveAudit createdAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createdAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.createdAt = null;

            /**
             * RoomArchiveAudit modifiedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} modifiedAt
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             */
            RoomArchiveAudit.prototype.modifiedAt = null;

            /**
             * Creates a new RoomArchiveAudit instance using the specified properties.
             * @function create
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {streem.api.IRoomArchiveAudit=} [properties] Properties to set
             * @returns {streem.api.RoomArchiveAudit} RoomArchiveAudit instance
             */
            RoomArchiveAudit.create = function create(properties) {
                return new RoomArchiveAudit(properties);
            };

            /**
             * Encodes the specified RoomArchiveAudit message. Does not implicitly {@link streem.api.RoomArchiveAudit.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {streem.api.IRoomArchiveAudit} m RoomArchiveAudit message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomArchiveAudit.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.roomArchiveAuditSid != null && Object.hasOwnProperty.call(m, "roomArchiveAuditSid"))
                    w.uint32(10).string(m.roomArchiveAuditSid);
                if (m.roomSid != null && Object.hasOwnProperty.call(m, "roomSid"))
                    w.uint32(18).string(m.roomSid);
                if (m.archiveScheduledAt != null && Object.hasOwnProperty.call(m, "archiveScheduledAt"))
                    $root.google.protobuf.Timestamp.encode(m.archiveScheduledAt, w.uint32(26).fork()).ldelim();
                if (m.archivedAt != null && Object.hasOwnProperty.call(m, "archivedAt"))
                    $root.google.protobuf.Timestamp.encode(m.archivedAt, w.uint32(34).fork()).ldelim();
                if (m.companySid != null && Object.hasOwnProperty.call(m, "companySid"))
                    w.uint32(42).string(m.companySid);
                if (m.referenceId != null && Object.hasOwnProperty.call(m, "referenceId"))
                    w.uint32(50).string(m.referenceId);
                if (m.reservationSid != null && Object.hasOwnProperty.call(m, "reservationSid"))
                    w.uint32(58).string(m.reservationSid);
                if (m.reservationGroupSid != null && Object.hasOwnProperty.call(m, "reservationGroupSid"))
                    w.uint32(66).string(m.reservationGroupSid);
                if (m.reservationGroupName != null && Object.hasOwnProperty.call(m, "reservationGroupName"))
                    w.uint32(74).string(m.reservationGroupName);
                if (m.roomOpenedAt != null && Object.hasOwnProperty.call(m, "roomOpenedAt"))
                    $root.google.protobuf.Timestamp.encode(m.roomOpenedAt, w.uint32(82).fork()).ldelim();
                if (m.roomDeletedAt != null && Object.hasOwnProperty.call(m, "roomDeletedAt"))
                    $root.google.protobuf.Timestamp.encode(m.roomDeletedAt, w.uint32(90).fork()).ldelim();
                if (m.fromUserSid != null && Object.hasOwnProperty.call(m, "fromUserSid"))
                    w.uint32(98).string(m.fromUserSid);
                if (m.fromDeviceSid != null && Object.hasOwnProperty.call(m, "fromDeviceSid"))
                    w.uint32(106).string(m.fromDeviceSid);
                if (m.fromDeviceTimezone != null && Object.hasOwnProperty.call(m, "fromDeviceTimezone"))
                    w.uint32(114).string(m.fromDeviceTimezone);
                if (m.toUserSid != null && Object.hasOwnProperty.call(m, "toUserSid"))
                    w.uint32(122).string(m.toUserSid);
                if (m.toDeviceSid != null && Object.hasOwnProperty.call(m, "toDeviceSid"))
                    w.uint32(130).string(m.toDeviceSid);
                if (m.compositionArtifactSid != null && Object.hasOwnProperty.call(m, "compositionArtifactSid"))
                    w.uint32(138).string(m.compositionArtifactSid);
                if (m.archiveS3Folder != null && Object.hasOwnProperty.call(m, "archiveS3Folder"))
                    w.uint32(146).string(m.archiveS3Folder);
                if (m.deactivatedByUserSid != null && Object.hasOwnProperty.call(m, "deactivatedByUserSid"))
                    w.uint32(154).string(m.deactivatedByUserSid);
                if (m.deactivatedByRole != null && Object.hasOwnProperty.call(m, "deactivatedByRole"))
                    w.uint32(162).string(m.deactivatedByRole);
                if (m.deactivatedAt != null && Object.hasOwnProperty.call(m, "deactivatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.deactivatedAt, w.uint32(170).fork()).ldelim();
                if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(178).fork()).ldelim();
                if (m.modifiedAt != null && Object.hasOwnProperty.call(m, "modifiedAt"))
                    $root.google.protobuf.Timestamp.encode(m.modifiedAt, w.uint32(186).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a RoomArchiveAudit message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RoomArchiveAudit} RoomArchiveAudit
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomArchiveAudit.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RoomArchiveAudit();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.roomArchiveAuditSid = r.string();
                            break;
                        }
                    case 2: {
                            m.roomSid = r.string();
                            break;
                        }
                    case 3: {
                            m.archiveScheduledAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 4: {
                            m.archivedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.companySid = r.string();
                            break;
                        }
                    case 6: {
                            m.referenceId = r.string();
                            break;
                        }
                    case 7: {
                            m.reservationSid = r.string();
                            break;
                        }
                    case 8: {
                            m.reservationGroupSid = r.string();
                            break;
                        }
                    case 9: {
                            m.reservationGroupName = r.string();
                            break;
                        }
                    case 10: {
                            m.roomOpenedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 11: {
                            m.roomDeletedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.fromUserSid = r.string();
                            break;
                        }
                    case 13: {
                            m.fromDeviceSid = r.string();
                            break;
                        }
                    case 14: {
                            m.fromDeviceTimezone = r.string();
                            break;
                        }
                    case 15: {
                            m.toUserSid = r.string();
                            break;
                        }
                    case 16: {
                            m.toDeviceSid = r.string();
                            break;
                        }
                    case 17: {
                            m.compositionArtifactSid = r.string();
                            break;
                        }
                    case 18: {
                            m.archiveS3Folder = r.string();
                            break;
                        }
                    case 19: {
                            m.deactivatedByUserSid = r.string();
                            break;
                        }
                    case 20: {
                            m.deactivatedByRole = r.string();
                            break;
                        }
                    case 21: {
                            m.deactivatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 22: {
                            m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 23: {
                            m.modifiedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RoomArchiveAudit message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RoomArchiveAudit} RoomArchiveAudit
             */
            RoomArchiveAudit.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RoomArchiveAudit)
                    return d;
                var m = new $root.streem.api.RoomArchiveAudit();
                if (d.roomArchiveAuditSid != null) {
                    m.roomArchiveAuditSid = String(d.roomArchiveAuditSid);
                }
                if (d.roomSid != null) {
                    m.roomSid = String(d.roomSid);
                }
                if (d.archiveScheduledAt != null) {
                    if (typeof d.archiveScheduledAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.archiveScheduledAt: object expected");
                    m.archiveScheduledAt = $root.google.protobuf.Timestamp.fromObject(d.archiveScheduledAt);
                }
                if (d.archivedAt != null) {
                    if (typeof d.archivedAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.archivedAt: object expected");
                    m.archivedAt = $root.google.protobuf.Timestamp.fromObject(d.archivedAt);
                }
                if (d.companySid != null) {
                    m.companySid = String(d.companySid);
                }
                if (d.referenceId != null) {
                    m.referenceId = String(d.referenceId);
                }
                if (d.reservationSid != null) {
                    m.reservationSid = String(d.reservationSid);
                }
                if (d.reservationGroupSid != null) {
                    m.reservationGroupSid = String(d.reservationGroupSid);
                }
                if (d.reservationGroupName != null) {
                    m.reservationGroupName = String(d.reservationGroupName);
                }
                if (d.roomOpenedAt != null) {
                    if (typeof d.roomOpenedAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.roomOpenedAt: object expected");
                    m.roomOpenedAt = $root.google.protobuf.Timestamp.fromObject(d.roomOpenedAt);
                }
                if (d.roomDeletedAt != null) {
                    if (typeof d.roomDeletedAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.roomDeletedAt: object expected");
                    m.roomDeletedAt = $root.google.protobuf.Timestamp.fromObject(d.roomDeletedAt);
                }
                if (d.fromUserSid != null) {
                    m.fromUserSid = String(d.fromUserSid);
                }
                if (d.fromDeviceSid != null) {
                    m.fromDeviceSid = String(d.fromDeviceSid);
                }
                if (d.fromDeviceTimezone != null) {
                    m.fromDeviceTimezone = String(d.fromDeviceTimezone);
                }
                if (d.toUserSid != null) {
                    m.toUserSid = String(d.toUserSid);
                }
                if (d.toDeviceSid != null) {
                    m.toDeviceSid = String(d.toDeviceSid);
                }
                if (d.compositionArtifactSid != null) {
                    m.compositionArtifactSid = String(d.compositionArtifactSid);
                }
                if (d.archiveS3Folder != null) {
                    m.archiveS3Folder = String(d.archiveS3Folder);
                }
                if (d.deactivatedByUserSid != null) {
                    m.deactivatedByUserSid = String(d.deactivatedByUserSid);
                }
                if (d.deactivatedByRole != null) {
                    m.deactivatedByRole = String(d.deactivatedByRole);
                }
                if (d.deactivatedAt != null) {
                    if (typeof d.deactivatedAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.deactivatedAt: object expected");
                    m.deactivatedAt = $root.google.protobuf.Timestamp.fromObject(d.deactivatedAt);
                }
                if (d.createdAt != null) {
                    if (typeof d.createdAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.createdAt: object expected");
                    m.createdAt = $root.google.protobuf.Timestamp.fromObject(d.createdAt);
                }
                if (d.modifiedAt != null) {
                    if (typeof d.modifiedAt !== "object")
                        throw TypeError(".streem.api.RoomArchiveAudit.modifiedAt: object expected");
                    m.modifiedAt = $root.google.protobuf.Timestamp.fromObject(d.modifiedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a RoomArchiveAudit message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {streem.api.RoomArchiveAudit} m RoomArchiveAudit
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomArchiveAudit.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.roomArchiveAuditSid = "";
                    d.roomSid = "";
                    d.archiveScheduledAt = null;
                    d.archivedAt = null;
                    d.companySid = "";
                    d.referenceId = "";
                    d.reservationSid = "";
                    d.reservationGroupSid = "";
                    d.reservationGroupName = "";
                    d.roomOpenedAt = null;
                    d.roomDeletedAt = null;
                    d.fromUserSid = "";
                    d.fromDeviceSid = "";
                    d.fromDeviceTimezone = "";
                    d.toUserSid = "";
                    d.toDeviceSid = "";
                    d.compositionArtifactSid = "";
                    d.archiveS3Folder = "";
                    d.deactivatedByUserSid = "";
                    d.deactivatedByRole = "";
                    d.deactivatedAt = null;
                    d.createdAt = null;
                    d.modifiedAt = null;
                }
                if (m.roomArchiveAuditSid != null && m.hasOwnProperty("roomArchiveAuditSid")) {
                    d.roomArchiveAuditSid = m.roomArchiveAuditSid;
                }
                if (m.roomSid != null && m.hasOwnProperty("roomSid")) {
                    d.roomSid = m.roomSid;
                }
                if (m.archiveScheduledAt != null && m.hasOwnProperty("archiveScheduledAt")) {
                    d.archiveScheduledAt = $root.google.protobuf.Timestamp.toObject(m.archiveScheduledAt, o);
                }
                if (m.archivedAt != null && m.hasOwnProperty("archivedAt")) {
                    d.archivedAt = $root.google.protobuf.Timestamp.toObject(m.archivedAt, o);
                }
                if (m.companySid != null && m.hasOwnProperty("companySid")) {
                    d.companySid = m.companySid;
                }
                if (m.referenceId != null && m.hasOwnProperty("referenceId")) {
                    d.referenceId = m.referenceId;
                }
                if (m.reservationSid != null && m.hasOwnProperty("reservationSid")) {
                    d.reservationSid = m.reservationSid;
                }
                if (m.reservationGroupSid != null && m.hasOwnProperty("reservationGroupSid")) {
                    d.reservationGroupSid = m.reservationGroupSid;
                }
                if (m.reservationGroupName != null && m.hasOwnProperty("reservationGroupName")) {
                    d.reservationGroupName = m.reservationGroupName;
                }
                if (m.roomOpenedAt != null && m.hasOwnProperty("roomOpenedAt")) {
                    d.roomOpenedAt = $root.google.protobuf.Timestamp.toObject(m.roomOpenedAt, o);
                }
                if (m.roomDeletedAt != null && m.hasOwnProperty("roomDeletedAt")) {
                    d.roomDeletedAt = $root.google.protobuf.Timestamp.toObject(m.roomDeletedAt, o);
                }
                if (m.fromUserSid != null && m.hasOwnProperty("fromUserSid")) {
                    d.fromUserSid = m.fromUserSid;
                }
                if (m.fromDeviceSid != null && m.hasOwnProperty("fromDeviceSid")) {
                    d.fromDeviceSid = m.fromDeviceSid;
                }
                if (m.fromDeviceTimezone != null && m.hasOwnProperty("fromDeviceTimezone")) {
                    d.fromDeviceTimezone = m.fromDeviceTimezone;
                }
                if (m.toUserSid != null && m.hasOwnProperty("toUserSid")) {
                    d.toUserSid = m.toUserSid;
                }
                if (m.toDeviceSid != null && m.hasOwnProperty("toDeviceSid")) {
                    d.toDeviceSid = m.toDeviceSid;
                }
                if (m.compositionArtifactSid != null && m.hasOwnProperty("compositionArtifactSid")) {
                    d.compositionArtifactSid = m.compositionArtifactSid;
                }
                if (m.archiveS3Folder != null && m.hasOwnProperty("archiveS3Folder")) {
                    d.archiveS3Folder = m.archiveS3Folder;
                }
                if (m.deactivatedByUserSid != null && m.hasOwnProperty("deactivatedByUserSid")) {
                    d.deactivatedByUserSid = m.deactivatedByUserSid;
                }
                if (m.deactivatedByRole != null && m.hasOwnProperty("deactivatedByRole")) {
                    d.deactivatedByRole = m.deactivatedByRole;
                }
                if (m.deactivatedAt != null && m.hasOwnProperty("deactivatedAt")) {
                    d.deactivatedAt = $root.google.protobuf.Timestamp.toObject(m.deactivatedAt, o);
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = $root.google.protobuf.Timestamp.toObject(m.createdAt, o);
                }
                if (m.modifiedAt != null && m.hasOwnProperty("modifiedAt")) {
                    d.modifiedAt = $root.google.protobuf.Timestamp.toObject(m.modifiedAt, o);
                }
                return d;
            };

            /**
             * Converts this RoomArchiveAudit to JSON.
             * @function toJSON
             * @memberof streem.api.RoomArchiveAudit
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomArchiveAudit.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RoomArchiveAudit
             * @function getTypeUrl
             * @memberof streem.api.RoomArchiveAudit
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomArchiveAudit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RoomArchiveAudit";
            };

            return RoomArchiveAudit;
        })();

        api.EmbeddedReports = (function() {

            /**
             * Constructs a new EmbeddedReports service.
             * @memberof streem.api
             * @classdesc Represents an EmbeddedReports
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function EmbeddedReports(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (EmbeddedReports.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EmbeddedReports;

            /**
             * Creates new EmbeddedReports service using the specified rpc implementation.
             * @function create
             * @memberof streem.api.EmbeddedReports
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {EmbeddedReports} RPC service. Useful where requests and/or responses are streamed.
             */
            EmbeddedReports.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link streem.api.EmbeddedReports#acquireEmbedReportsSession}.
             * @memberof streem.api.EmbeddedReports
             * @typedef AcquireEmbedReportsSessionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.AcquireEmbedReportsSessionResponse} [response] AcquireEmbedReportsSessionResponse
             */

            /**
             * Calls AcquireEmbedReportsSession.
             * @function acquireEmbedReportsSession
             * @memberof streem.api.EmbeddedReports
             * @instance
             * @param {streem.api.IAcquireEmbedReportsSessionRequest} request AcquireEmbedReportsSessionRequest message or plain object
             * @param {streem.api.EmbeddedReports.AcquireEmbedReportsSessionCallback} callback Node-style callback called with the error, if any, and AcquireEmbedReportsSessionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(EmbeddedReports.prototype.acquireEmbedReportsSession = function acquireEmbedReportsSession(request, callback) {
                return this.rpcCall(acquireEmbedReportsSession, $root.streem.api.AcquireEmbedReportsSessionRequest, $root.streem.api.AcquireEmbedReportsSessionResponse, request, callback);
            }, "name", { value: "AcquireEmbedReportsSession" });

            /**
             * Calls AcquireEmbedReportsSession.
             * @function acquireEmbedReportsSession
             * @memberof streem.api.EmbeddedReports
             * @instance
             * @param {streem.api.IAcquireEmbedReportsSessionRequest} request AcquireEmbedReportsSessionRequest message or plain object
             * @returns {Promise<streem.api.AcquireEmbedReportsSessionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link streem.api.EmbeddedReports#refreshEmbedReportsSession}.
             * @memberof streem.api.EmbeddedReports
             * @typedef RefreshEmbedReportsSessionCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {streem.api.RefreshEmbedReportsSessionResponse} [response] RefreshEmbedReportsSessionResponse
             */

            /**
             * Calls RefreshEmbedReportsSession.
             * @function refreshEmbedReportsSession
             * @memberof streem.api.EmbeddedReports
             * @instance
             * @param {streem.api.IRefreshEmbedReportsSessionRequest} request RefreshEmbedReportsSessionRequest message or plain object
             * @param {streem.api.EmbeddedReports.RefreshEmbedReportsSessionCallback} callback Node-style callback called with the error, if any, and RefreshEmbedReportsSessionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(EmbeddedReports.prototype.refreshEmbedReportsSession = function refreshEmbedReportsSession(request, callback) {
                return this.rpcCall(refreshEmbedReportsSession, $root.streem.api.RefreshEmbedReportsSessionRequest, $root.streem.api.RefreshEmbedReportsSessionResponse, request, callback);
            }, "name", { value: "RefreshEmbedReportsSession" });

            /**
             * Calls RefreshEmbedReportsSession.
             * @function refreshEmbedReportsSession
             * @memberof streem.api.EmbeddedReports
             * @instance
             * @param {streem.api.IRefreshEmbedReportsSessionRequest} request RefreshEmbedReportsSessionRequest message or plain object
             * @returns {Promise<streem.api.RefreshEmbedReportsSessionResponse>} Promise
             * @variation 2
             */

            return EmbeddedReports;
        })();

        api.AcquireEmbedReportsSessionRequest = (function() {

            /**
             * Properties of an AcquireEmbedReportsSessionRequest.
             * @memberof streem.api
             * @interface IAcquireEmbedReportsSessionRequest
             * @property {string|null} [companyCodeOrSid] AcquireEmbedReportsSessionRequest companyCodeOrSid
             */

            /**
             * Constructs a new AcquireEmbedReportsSessionRequest.
             * @memberof streem.api
             * @classdesc Represents an AcquireEmbedReportsSessionRequest.
             * @implements IAcquireEmbedReportsSessionRequest
             * @constructor
             * @param {streem.api.IAcquireEmbedReportsSessionRequest=} [p] Properties to set
             */
            function AcquireEmbedReportsSessionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcquireEmbedReportsSessionRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @instance
             */
            AcquireEmbedReportsSessionRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new AcquireEmbedReportsSessionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {streem.api.IAcquireEmbedReportsSessionRequest=} [properties] Properties to set
             * @returns {streem.api.AcquireEmbedReportsSessionRequest} AcquireEmbedReportsSessionRequest instance
             */
            AcquireEmbedReportsSessionRequest.create = function create(properties) {
                return new AcquireEmbedReportsSessionRequest(properties);
            };

            /**
             * Encodes the specified AcquireEmbedReportsSessionRequest message. Does not implicitly {@link streem.api.AcquireEmbedReportsSessionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {streem.api.IAcquireEmbedReportsSessionRequest} m AcquireEmbedReportsSessionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcquireEmbedReportsSessionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(10).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes an AcquireEmbedReportsSessionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcquireEmbedReportsSessionRequest} AcquireEmbedReportsSessionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcquireEmbedReportsSessionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcquireEmbedReportsSessionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcquireEmbedReportsSessionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcquireEmbedReportsSessionRequest} AcquireEmbedReportsSessionRequest
             */
            AcquireEmbedReportsSessionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcquireEmbedReportsSessionRequest)
                    return d;
                var m = new $root.streem.api.AcquireEmbedReportsSessionRequest();
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcquireEmbedReportsSessionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {streem.api.AcquireEmbedReportsSessionRequest} m AcquireEmbedReportsSessionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcquireEmbedReportsSessionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.companyCodeOrSid = "";
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this AcquireEmbedReportsSessionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcquireEmbedReportsSessionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcquireEmbedReportsSessionRequest
             * @function getTypeUrl
             * @memberof streem.api.AcquireEmbedReportsSessionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcquireEmbedReportsSessionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcquireEmbedReportsSessionRequest";
            };

            return AcquireEmbedReportsSessionRequest;
        })();

        api.AcquireEmbedReportsSessionResponse = (function() {

            /**
             * Properties of an AcquireEmbedReportsSessionResponse.
             * @memberof streem.api
             * @interface IAcquireEmbedReportsSessionResponse
             * @property {string|null} [apiToken] AcquireEmbedReportsSessionResponse apiToken
             * @property {google.protobuf.IDuration|null} [apiTokenTtl] AcquireEmbedReportsSessionResponse apiTokenTtl
             * @property {string|null} [navigationToken] AcquireEmbedReportsSessionResponse navigationToken
             * @property {google.protobuf.IDuration|null} [navigationTokenTtl] AcquireEmbedReportsSessionResponse navigationTokenTtl
             * @property {string|null} [authenticationToken] AcquireEmbedReportsSessionResponse authenticationToken
             * @property {google.protobuf.IDuration|null} [authenticationTokenTtl] AcquireEmbedReportsSessionResponse authenticationTokenTtl
             * @property {google.protobuf.IDuration|null} [sessionReferenceTokenTtl] AcquireEmbedReportsSessionResponse sessionReferenceTokenTtl
             */

            /**
             * Constructs a new AcquireEmbedReportsSessionResponse.
             * @memberof streem.api
             * @classdesc Represents an AcquireEmbedReportsSessionResponse.
             * @implements IAcquireEmbedReportsSessionResponse
             * @constructor
             * @param {streem.api.IAcquireEmbedReportsSessionResponse=} [p] Properties to set
             */
            function AcquireEmbedReportsSessionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AcquireEmbedReportsSessionResponse apiToken.
             * @member {string} apiToken
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.apiToken = "";

            /**
             * AcquireEmbedReportsSessionResponse apiTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} apiTokenTtl
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.apiTokenTtl = null;

            /**
             * AcquireEmbedReportsSessionResponse navigationToken.
             * @member {string} navigationToken
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.navigationToken = "";

            /**
             * AcquireEmbedReportsSessionResponse navigationTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} navigationTokenTtl
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.navigationTokenTtl = null;

            /**
             * AcquireEmbedReportsSessionResponse authenticationToken.
             * @member {string} authenticationToken
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.authenticationToken = "";

            /**
             * AcquireEmbedReportsSessionResponse authenticationTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} authenticationTokenTtl
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.authenticationTokenTtl = null;

            /**
             * AcquireEmbedReportsSessionResponse sessionReferenceTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} sessionReferenceTokenTtl
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             */
            AcquireEmbedReportsSessionResponse.prototype.sessionReferenceTokenTtl = null;

            /**
             * Creates a new AcquireEmbedReportsSessionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {streem.api.IAcquireEmbedReportsSessionResponse=} [properties] Properties to set
             * @returns {streem.api.AcquireEmbedReportsSessionResponse} AcquireEmbedReportsSessionResponse instance
             */
            AcquireEmbedReportsSessionResponse.create = function create(properties) {
                return new AcquireEmbedReportsSessionResponse(properties);
            };

            /**
             * Encodes the specified AcquireEmbedReportsSessionResponse message. Does not implicitly {@link streem.api.AcquireEmbedReportsSessionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {streem.api.IAcquireEmbedReportsSessionResponse} m AcquireEmbedReportsSessionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcquireEmbedReportsSessionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiToken != null && Object.hasOwnProperty.call(m, "apiToken"))
                    w.uint32(10).string(m.apiToken);
                if (m.apiTokenTtl != null && Object.hasOwnProperty.call(m, "apiTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.apiTokenTtl, w.uint32(18).fork()).ldelim();
                if (m.navigationToken != null && Object.hasOwnProperty.call(m, "navigationToken"))
                    w.uint32(26).string(m.navigationToken);
                if (m.navigationTokenTtl != null && Object.hasOwnProperty.call(m, "navigationTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.navigationTokenTtl, w.uint32(34).fork()).ldelim();
                if (m.authenticationToken != null && Object.hasOwnProperty.call(m, "authenticationToken"))
                    w.uint32(42).string(m.authenticationToken);
                if (m.authenticationTokenTtl != null && Object.hasOwnProperty.call(m, "authenticationTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.authenticationTokenTtl, w.uint32(50).fork()).ldelim();
                if (m.sessionReferenceTokenTtl != null && Object.hasOwnProperty.call(m, "sessionReferenceTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.sessionReferenceTokenTtl, w.uint32(58).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an AcquireEmbedReportsSessionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.AcquireEmbedReportsSessionResponse} AcquireEmbedReportsSessionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcquireEmbedReportsSessionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.AcquireEmbedReportsSessionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiToken = r.string();
                            break;
                        }
                    case 2: {
                            m.apiTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.navigationToken = r.string();
                            break;
                        }
                    case 4: {
                            m.navigationTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.authenticationToken = r.string();
                            break;
                        }
                    case 6: {
                            m.authenticationTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 7: {
                            m.sessionReferenceTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an AcquireEmbedReportsSessionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.AcquireEmbedReportsSessionResponse} AcquireEmbedReportsSessionResponse
             */
            AcquireEmbedReportsSessionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.AcquireEmbedReportsSessionResponse)
                    return d;
                var m = new $root.streem.api.AcquireEmbedReportsSessionResponse();
                if (d.apiToken != null) {
                    m.apiToken = String(d.apiToken);
                }
                if (d.apiTokenTtl != null) {
                    if (typeof d.apiTokenTtl !== "object")
                        throw TypeError(".streem.api.AcquireEmbedReportsSessionResponse.apiTokenTtl: object expected");
                    m.apiTokenTtl = $root.google.protobuf.Duration.fromObject(d.apiTokenTtl);
                }
                if (d.navigationToken != null) {
                    m.navigationToken = String(d.navigationToken);
                }
                if (d.navigationTokenTtl != null) {
                    if (typeof d.navigationTokenTtl !== "object")
                        throw TypeError(".streem.api.AcquireEmbedReportsSessionResponse.navigationTokenTtl: object expected");
                    m.navigationTokenTtl = $root.google.protobuf.Duration.fromObject(d.navigationTokenTtl);
                }
                if (d.authenticationToken != null) {
                    m.authenticationToken = String(d.authenticationToken);
                }
                if (d.authenticationTokenTtl != null) {
                    if (typeof d.authenticationTokenTtl !== "object")
                        throw TypeError(".streem.api.AcquireEmbedReportsSessionResponse.authenticationTokenTtl: object expected");
                    m.authenticationTokenTtl = $root.google.protobuf.Duration.fromObject(d.authenticationTokenTtl);
                }
                if (d.sessionReferenceTokenTtl != null) {
                    if (typeof d.sessionReferenceTokenTtl !== "object")
                        throw TypeError(".streem.api.AcquireEmbedReportsSessionResponse.sessionReferenceTokenTtl: object expected");
                    m.sessionReferenceTokenTtl = $root.google.protobuf.Duration.fromObject(d.sessionReferenceTokenTtl);
                }
                return m;
            };

            /**
             * Creates a plain object from an AcquireEmbedReportsSessionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {streem.api.AcquireEmbedReportsSessionResponse} m AcquireEmbedReportsSessionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcquireEmbedReportsSessionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiToken = "";
                    d.apiTokenTtl = null;
                    d.navigationToken = "";
                    d.navigationTokenTtl = null;
                    d.authenticationToken = "";
                    d.authenticationTokenTtl = null;
                    d.sessionReferenceTokenTtl = null;
                }
                if (m.apiToken != null && m.hasOwnProperty("apiToken")) {
                    d.apiToken = m.apiToken;
                }
                if (m.apiTokenTtl != null && m.hasOwnProperty("apiTokenTtl")) {
                    d.apiTokenTtl = $root.google.protobuf.Duration.toObject(m.apiTokenTtl, o);
                }
                if (m.navigationToken != null && m.hasOwnProperty("navigationToken")) {
                    d.navigationToken = m.navigationToken;
                }
                if (m.navigationTokenTtl != null && m.hasOwnProperty("navigationTokenTtl")) {
                    d.navigationTokenTtl = $root.google.protobuf.Duration.toObject(m.navigationTokenTtl, o);
                }
                if (m.authenticationToken != null && m.hasOwnProperty("authenticationToken")) {
                    d.authenticationToken = m.authenticationToken;
                }
                if (m.authenticationTokenTtl != null && m.hasOwnProperty("authenticationTokenTtl")) {
                    d.authenticationTokenTtl = $root.google.protobuf.Duration.toObject(m.authenticationTokenTtl, o);
                }
                if (m.sessionReferenceTokenTtl != null && m.hasOwnProperty("sessionReferenceTokenTtl")) {
                    d.sessionReferenceTokenTtl = $root.google.protobuf.Duration.toObject(m.sessionReferenceTokenTtl, o);
                }
                return d;
            };

            /**
             * Converts this AcquireEmbedReportsSessionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcquireEmbedReportsSessionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AcquireEmbedReportsSessionResponse
             * @function getTypeUrl
             * @memberof streem.api.AcquireEmbedReportsSessionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AcquireEmbedReportsSessionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.AcquireEmbedReportsSessionResponse";
            };

            return AcquireEmbedReportsSessionResponse;
        })();

        api.RefreshEmbedReportsSessionRequest = (function() {

            /**
             * Properties of a RefreshEmbedReportsSessionRequest.
             * @memberof streem.api
             * @interface IRefreshEmbedReportsSessionRequest
             * @property {string|null} [apiToken] RefreshEmbedReportsSessionRequest apiToken
             * @property {string|null} [navigationToken] RefreshEmbedReportsSessionRequest navigationToken
             * @property {string|null} [companyCodeOrSid] RefreshEmbedReportsSessionRequest companyCodeOrSid
             */

            /**
             * Constructs a new RefreshEmbedReportsSessionRequest.
             * @memberof streem.api
             * @classdesc Represents a RefreshEmbedReportsSessionRequest.
             * @implements IRefreshEmbedReportsSessionRequest
             * @constructor
             * @param {streem.api.IRefreshEmbedReportsSessionRequest=} [p] Properties to set
             */
            function RefreshEmbedReportsSessionRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RefreshEmbedReportsSessionRequest apiToken.
             * @member {string} apiToken
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @instance
             */
            RefreshEmbedReportsSessionRequest.prototype.apiToken = "";

            /**
             * RefreshEmbedReportsSessionRequest navigationToken.
             * @member {string} navigationToken
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @instance
             */
            RefreshEmbedReportsSessionRequest.prototype.navigationToken = "";

            /**
             * RefreshEmbedReportsSessionRequest companyCodeOrSid.
             * @member {string} companyCodeOrSid
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @instance
             */
            RefreshEmbedReportsSessionRequest.prototype.companyCodeOrSid = "";

            /**
             * Creates a new RefreshEmbedReportsSessionRequest instance using the specified properties.
             * @function create
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {streem.api.IRefreshEmbedReportsSessionRequest=} [properties] Properties to set
             * @returns {streem.api.RefreshEmbedReportsSessionRequest} RefreshEmbedReportsSessionRequest instance
             */
            RefreshEmbedReportsSessionRequest.create = function create(properties) {
                return new RefreshEmbedReportsSessionRequest(properties);
            };

            /**
             * Encodes the specified RefreshEmbedReportsSessionRequest message. Does not implicitly {@link streem.api.RefreshEmbedReportsSessionRequest.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {streem.api.IRefreshEmbedReportsSessionRequest} m RefreshEmbedReportsSessionRequest message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RefreshEmbedReportsSessionRequest.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiToken != null && Object.hasOwnProperty.call(m, "apiToken"))
                    w.uint32(10).string(m.apiToken);
                if (m.navigationToken != null && Object.hasOwnProperty.call(m, "navigationToken"))
                    w.uint32(18).string(m.navigationToken);
                if (m.companyCodeOrSid != null && Object.hasOwnProperty.call(m, "companyCodeOrSid"))
                    w.uint32(26).string(m.companyCodeOrSid);
                return w;
            };

            /**
             * Decodes a RefreshEmbedReportsSessionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RefreshEmbedReportsSessionRequest} RefreshEmbedReportsSessionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RefreshEmbedReportsSessionRequest.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RefreshEmbedReportsSessionRequest();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiToken = r.string();
                            break;
                        }
                    case 2: {
                            m.navigationToken = r.string();
                            break;
                        }
                    case 3: {
                            m.companyCodeOrSid = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RefreshEmbedReportsSessionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RefreshEmbedReportsSessionRequest} RefreshEmbedReportsSessionRequest
             */
            RefreshEmbedReportsSessionRequest.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RefreshEmbedReportsSessionRequest)
                    return d;
                var m = new $root.streem.api.RefreshEmbedReportsSessionRequest();
                if (d.apiToken != null) {
                    m.apiToken = String(d.apiToken);
                }
                if (d.navigationToken != null) {
                    m.navigationToken = String(d.navigationToken);
                }
                if (d.companyCodeOrSid != null) {
                    m.companyCodeOrSid = String(d.companyCodeOrSid);
                }
                return m;
            };

            /**
             * Creates a plain object from a RefreshEmbedReportsSessionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {streem.api.RefreshEmbedReportsSessionRequest} m RefreshEmbedReportsSessionRequest
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RefreshEmbedReportsSessionRequest.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiToken = "";
                    d.navigationToken = "";
                    d.companyCodeOrSid = "";
                }
                if (m.apiToken != null && m.hasOwnProperty("apiToken")) {
                    d.apiToken = m.apiToken;
                }
                if (m.navigationToken != null && m.hasOwnProperty("navigationToken")) {
                    d.navigationToken = m.navigationToken;
                }
                if (m.companyCodeOrSid != null && m.hasOwnProperty("companyCodeOrSid")) {
                    d.companyCodeOrSid = m.companyCodeOrSid;
                }
                return d;
            };

            /**
             * Converts this RefreshEmbedReportsSessionRequest to JSON.
             * @function toJSON
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RefreshEmbedReportsSessionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RefreshEmbedReportsSessionRequest
             * @function getTypeUrl
             * @memberof streem.api.RefreshEmbedReportsSessionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RefreshEmbedReportsSessionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RefreshEmbedReportsSessionRequest";
            };

            return RefreshEmbedReportsSessionRequest;
        })();

        api.RefreshEmbedReportsSessionResponse = (function() {

            /**
             * Properties of a RefreshEmbedReportsSessionResponse.
             * @memberof streem.api
             * @interface IRefreshEmbedReportsSessionResponse
             * @property {string|null} [apiToken] RefreshEmbedReportsSessionResponse apiToken
             * @property {google.protobuf.IDuration|null} [apiTokenTtl] RefreshEmbedReportsSessionResponse apiTokenTtl
             * @property {string|null} [navigationToken] RefreshEmbedReportsSessionResponse navigationToken
             * @property {google.protobuf.IDuration|null} [navigationTokenTtl] RefreshEmbedReportsSessionResponse navigationTokenTtl
             * @property {google.protobuf.IDuration|null} [sessionReferenceTokenTtl] RefreshEmbedReportsSessionResponse sessionReferenceTokenTtl
             */

            /**
             * Constructs a new RefreshEmbedReportsSessionResponse.
             * @memberof streem.api
             * @classdesc Represents a RefreshEmbedReportsSessionResponse.
             * @implements IRefreshEmbedReportsSessionResponse
             * @constructor
             * @param {streem.api.IRefreshEmbedReportsSessionResponse=} [p] Properties to set
             */
            function RefreshEmbedReportsSessionResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RefreshEmbedReportsSessionResponse apiToken.
             * @member {string} apiToken
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             */
            RefreshEmbedReportsSessionResponse.prototype.apiToken = "";

            /**
             * RefreshEmbedReportsSessionResponse apiTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} apiTokenTtl
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             */
            RefreshEmbedReportsSessionResponse.prototype.apiTokenTtl = null;

            /**
             * RefreshEmbedReportsSessionResponse navigationToken.
             * @member {string} navigationToken
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             */
            RefreshEmbedReportsSessionResponse.prototype.navigationToken = "";

            /**
             * RefreshEmbedReportsSessionResponse navigationTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} navigationTokenTtl
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             */
            RefreshEmbedReportsSessionResponse.prototype.navigationTokenTtl = null;

            /**
             * RefreshEmbedReportsSessionResponse sessionReferenceTokenTtl.
             * @member {google.protobuf.IDuration|null|undefined} sessionReferenceTokenTtl
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             */
            RefreshEmbedReportsSessionResponse.prototype.sessionReferenceTokenTtl = null;

            /**
             * Creates a new RefreshEmbedReportsSessionResponse instance using the specified properties.
             * @function create
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {streem.api.IRefreshEmbedReportsSessionResponse=} [properties] Properties to set
             * @returns {streem.api.RefreshEmbedReportsSessionResponse} RefreshEmbedReportsSessionResponse instance
             */
            RefreshEmbedReportsSessionResponse.create = function create(properties) {
                return new RefreshEmbedReportsSessionResponse(properties);
            };

            /**
             * Encodes the specified RefreshEmbedReportsSessionResponse message. Does not implicitly {@link streem.api.RefreshEmbedReportsSessionResponse.verify|verify} messages.
             * @function encode
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {streem.api.IRefreshEmbedReportsSessionResponse} m RefreshEmbedReportsSessionResponse message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RefreshEmbedReportsSessionResponse.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.apiToken != null && Object.hasOwnProperty.call(m, "apiToken"))
                    w.uint32(10).string(m.apiToken);
                if (m.apiTokenTtl != null && Object.hasOwnProperty.call(m, "apiTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.apiTokenTtl, w.uint32(18).fork()).ldelim();
                if (m.navigationToken != null && Object.hasOwnProperty.call(m, "navigationToken"))
                    w.uint32(26).string(m.navigationToken);
                if (m.navigationTokenTtl != null && Object.hasOwnProperty.call(m, "navigationTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.navigationTokenTtl, w.uint32(34).fork()).ldelim();
                if (m.sessionReferenceTokenTtl != null && Object.hasOwnProperty.call(m, "sessionReferenceTokenTtl"))
                    $root.google.protobuf.Duration.encode(m.sessionReferenceTokenTtl, w.uint32(42).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a RefreshEmbedReportsSessionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.RefreshEmbedReportsSessionResponse} RefreshEmbedReportsSessionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RefreshEmbedReportsSessionResponse.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.RefreshEmbedReportsSessionResponse();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.apiToken = r.string();
                            break;
                        }
                    case 2: {
                            m.apiTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 3: {
                            m.navigationToken = r.string();
                            break;
                        }
                    case 4: {
                            m.navigationTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.sessionReferenceTokenTtl = $root.google.protobuf.Duration.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a RefreshEmbedReportsSessionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.RefreshEmbedReportsSessionResponse} RefreshEmbedReportsSessionResponse
             */
            RefreshEmbedReportsSessionResponse.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.RefreshEmbedReportsSessionResponse)
                    return d;
                var m = new $root.streem.api.RefreshEmbedReportsSessionResponse();
                if (d.apiToken != null) {
                    m.apiToken = String(d.apiToken);
                }
                if (d.apiTokenTtl != null) {
                    if (typeof d.apiTokenTtl !== "object")
                        throw TypeError(".streem.api.RefreshEmbedReportsSessionResponse.apiTokenTtl: object expected");
                    m.apiTokenTtl = $root.google.protobuf.Duration.fromObject(d.apiTokenTtl);
                }
                if (d.navigationToken != null) {
                    m.navigationToken = String(d.navigationToken);
                }
                if (d.navigationTokenTtl != null) {
                    if (typeof d.navigationTokenTtl !== "object")
                        throw TypeError(".streem.api.RefreshEmbedReportsSessionResponse.navigationTokenTtl: object expected");
                    m.navigationTokenTtl = $root.google.protobuf.Duration.fromObject(d.navigationTokenTtl);
                }
                if (d.sessionReferenceTokenTtl != null) {
                    if (typeof d.sessionReferenceTokenTtl !== "object")
                        throw TypeError(".streem.api.RefreshEmbedReportsSessionResponse.sessionReferenceTokenTtl: object expected");
                    m.sessionReferenceTokenTtl = $root.google.protobuf.Duration.fromObject(d.sessionReferenceTokenTtl);
                }
                return m;
            };

            /**
             * Creates a plain object from a RefreshEmbedReportsSessionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {streem.api.RefreshEmbedReportsSessionResponse} m RefreshEmbedReportsSessionResponse
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RefreshEmbedReportsSessionResponse.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.apiToken = "";
                    d.apiTokenTtl = null;
                    d.navigationToken = "";
                    d.navigationTokenTtl = null;
                    d.sessionReferenceTokenTtl = null;
                }
                if (m.apiToken != null && m.hasOwnProperty("apiToken")) {
                    d.apiToken = m.apiToken;
                }
                if (m.apiTokenTtl != null && m.hasOwnProperty("apiTokenTtl")) {
                    d.apiTokenTtl = $root.google.protobuf.Duration.toObject(m.apiTokenTtl, o);
                }
                if (m.navigationToken != null && m.hasOwnProperty("navigationToken")) {
                    d.navigationToken = m.navigationToken;
                }
                if (m.navigationTokenTtl != null && m.hasOwnProperty("navigationTokenTtl")) {
                    d.navigationTokenTtl = $root.google.protobuf.Duration.toObject(m.navigationTokenTtl, o);
                }
                if (m.sessionReferenceTokenTtl != null && m.hasOwnProperty("sessionReferenceTokenTtl")) {
                    d.sessionReferenceTokenTtl = $root.google.protobuf.Duration.toObject(m.sessionReferenceTokenTtl, o);
                }
                return d;
            };

            /**
             * Converts this RefreshEmbedReportsSessionResponse to JSON.
             * @function toJSON
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RefreshEmbedReportsSessionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RefreshEmbedReportsSessionResponse
             * @function getTypeUrl
             * @memberof streem.api.RefreshEmbedReportsSessionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RefreshEmbedReportsSessionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.RefreshEmbedReportsSessionResponse";
            };

            return RefreshEmbedReportsSessionResponse;
        })();

        api.TermsAccepted = (function() {

            /**
             * Properties of a TermsAccepted.
             * @memberof streem.api
             * @interface ITermsAccepted
             * @property {string|null} [userId] TermsAccepted userId
             * @property {google.protobuf.ITimestamp|null} [acceptedAt] TermsAccepted acceptedAt
             */

            /**
             * Constructs a new TermsAccepted.
             * @memberof streem.api
             * @classdesc Represents a TermsAccepted.
             * @implements ITermsAccepted
             * @constructor
             * @param {streem.api.ITermsAccepted=} [p] Properties to set
             */
            function TermsAccepted(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TermsAccepted userId.
             * @member {string} userId
             * @memberof streem.api.TermsAccepted
             * @instance
             */
            TermsAccepted.prototype.userId = "";

            /**
             * TermsAccepted acceptedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} acceptedAt
             * @memberof streem.api.TermsAccepted
             * @instance
             */
            TermsAccepted.prototype.acceptedAt = null;

            /**
             * Creates a new TermsAccepted instance using the specified properties.
             * @function create
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {streem.api.ITermsAccepted=} [properties] Properties to set
             * @returns {streem.api.TermsAccepted} TermsAccepted instance
             */
            TermsAccepted.create = function create(properties) {
                return new TermsAccepted(properties);
            };

            /**
             * Encodes the specified TermsAccepted message. Does not implicitly {@link streem.api.TermsAccepted.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {streem.api.ITermsAccepted} m TermsAccepted message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TermsAccepted.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                    w.uint32(10).string(m.userId);
                if (m.acceptedAt != null && Object.hasOwnProperty.call(m, "acceptedAt"))
                    $root.google.protobuf.Timestamp.encode(m.acceptedAt, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TermsAccepted message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TermsAccepted} TermsAccepted
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TermsAccepted.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TermsAccepted();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.userId = r.string();
                            break;
                        }
                    case 2: {
                            m.acceptedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TermsAccepted message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TermsAccepted} TermsAccepted
             */
            TermsAccepted.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TermsAccepted)
                    return d;
                var m = new $root.streem.api.TermsAccepted();
                if (d.userId != null) {
                    m.userId = String(d.userId);
                }
                if (d.acceptedAt != null) {
                    if (typeof d.acceptedAt !== "object")
                        throw TypeError(".streem.api.TermsAccepted.acceptedAt: object expected");
                    m.acceptedAt = $root.google.protobuf.Timestamp.fromObject(d.acceptedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a TermsAccepted message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {streem.api.TermsAccepted} m TermsAccepted
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TermsAccepted.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.userId = "";
                    d.acceptedAt = null;
                }
                if (m.userId != null && m.hasOwnProperty("userId")) {
                    d.userId = m.userId;
                }
                if (m.acceptedAt != null && m.hasOwnProperty("acceptedAt")) {
                    d.acceptedAt = $root.google.protobuf.Timestamp.toObject(m.acceptedAt, o);
                }
                return d;
            };

            /**
             * Converts this TermsAccepted to JSON.
             * @function toJSON
             * @memberof streem.api.TermsAccepted
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TermsAccepted.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TermsAccepted
             * @function getTypeUrl
             * @memberof streem.api.TermsAccepted
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TermsAccepted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TermsAccepted";
            };

            return TermsAccepted;
        })();

        api.TermsUpdated = (function() {

            /**
             * Properties of a TermsUpdated.
             * @memberof streem.api
             * @interface ITermsUpdated
             * @property {google.protobuf.ITimestamp|null} [updatedAt] TermsUpdated updatedAt
             */

            /**
             * Constructs a new TermsUpdated.
             * @memberof streem.api
             * @classdesc Represents a TermsUpdated.
             * @implements ITermsUpdated
             * @constructor
             * @param {streem.api.ITermsUpdated=} [p] Properties to set
             */
            function TermsUpdated(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TermsUpdated updatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
             * @memberof streem.api.TermsUpdated
             * @instance
             */
            TermsUpdated.prototype.updatedAt = null;

            /**
             * Creates a new TermsUpdated instance using the specified properties.
             * @function create
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {streem.api.ITermsUpdated=} [properties] Properties to set
             * @returns {streem.api.TermsUpdated} TermsUpdated instance
             */
            TermsUpdated.create = function create(properties) {
                return new TermsUpdated(properties);
            };

            /**
             * Encodes the specified TermsUpdated message. Does not implicitly {@link streem.api.TermsUpdated.verify|verify} messages.
             * @function encode
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {streem.api.ITermsUpdated} m TermsUpdated message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TermsUpdated.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.updatedAt != null && Object.hasOwnProperty.call(m, "updatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.updatedAt, w.uint32(10).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a TermsUpdated message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.TermsUpdated} TermsUpdated
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TermsUpdated.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.TermsUpdated();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.updatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a TermsUpdated message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.TermsUpdated} TermsUpdated
             */
            TermsUpdated.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.TermsUpdated)
                    return d;
                var m = new $root.streem.api.TermsUpdated();
                if (d.updatedAt != null) {
                    if (typeof d.updatedAt !== "object")
                        throw TypeError(".streem.api.TermsUpdated.updatedAt: object expected");
                    m.updatedAt = $root.google.protobuf.Timestamp.fromObject(d.updatedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a TermsUpdated message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {streem.api.TermsUpdated} m TermsUpdated
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TermsUpdated.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.updatedAt = null;
                }
                if (m.updatedAt != null && m.hasOwnProperty("updatedAt")) {
                    d.updatedAt = $root.google.protobuf.Timestamp.toObject(m.updatedAt, o);
                }
                return d;
            };

            /**
             * Converts this TermsUpdated to JSON.
             * @function toJSON
             * @memberof streem.api.TermsUpdated
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TermsUpdated.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TermsUpdated
             * @function getTypeUrl
             * @memberof streem.api.TermsUpdated
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TermsUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.TermsUpdated";
            };

            return TermsUpdated;
        })();

        api.Country = (function() {

            /**
             * Properties of a Country.
             * @memberof streem.api
             * @interface ICountry
             * @property {string|null} [callingCode] Country callingCode
             * @property {string|null} [isoCode] Country isoCode
             * @property {string|null} [countryName] Country countryName
             */

            /**
             * Constructs a new Country.
             * @memberof streem.api
             * @classdesc Represents a Country.
             * @implements ICountry
             * @constructor
             * @param {streem.api.ICountry=} [p] Properties to set
             */
            function Country(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Country callingCode.
             * @member {string} callingCode
             * @memberof streem.api.Country
             * @instance
             */
            Country.prototype.callingCode = "";

            /**
             * Country isoCode.
             * @member {string} isoCode
             * @memberof streem.api.Country
             * @instance
             */
            Country.prototype.isoCode = "";

            /**
             * Country countryName.
             * @member {string} countryName
             * @memberof streem.api.Country
             * @instance
             */
            Country.prototype.countryName = "";

            /**
             * Creates a new Country instance using the specified properties.
             * @function create
             * @memberof streem.api.Country
             * @static
             * @param {streem.api.ICountry=} [properties] Properties to set
             * @returns {streem.api.Country} Country instance
             */
            Country.create = function create(properties) {
                return new Country(properties);
            };

            /**
             * Encodes the specified Country message. Does not implicitly {@link streem.api.Country.verify|verify} messages.
             * @function encode
             * @memberof streem.api.Country
             * @static
             * @param {streem.api.ICountry} m Country message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Country.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.callingCode != null && Object.hasOwnProperty.call(m, "callingCode"))
                    w.uint32(10).string(m.callingCode);
                if (m.isoCode != null && Object.hasOwnProperty.call(m, "isoCode"))
                    w.uint32(18).string(m.isoCode);
                if (m.countryName != null && Object.hasOwnProperty.call(m, "countryName"))
                    w.uint32(26).string(m.countryName);
                return w;
            };

            /**
             * Decodes a Country message from the specified reader or buffer.
             * @function decode
             * @memberof streem.api.Country
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {streem.api.Country} Country
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Country.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.streem.api.Country();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.callingCode = r.string();
                            break;
                        }
                    case 2: {
                            m.isoCode = r.string();
                            break;
                        }
                    case 3: {
                            m.countryName = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Country message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof streem.api.Country
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {streem.api.Country} Country
             */
            Country.fromObject = function fromObject(d) {
                if (d instanceof $root.streem.api.Country)
                    return d;
                var m = new $root.streem.api.Country();
                if (d.callingCode != null) {
                    m.callingCode = String(d.callingCode);
                }
                if (d.isoCode != null) {
                    m.isoCode = String(d.isoCode);
                }
                if (d.countryName != null) {
                    m.countryName = String(d.countryName);
                }
                return m;
            };

            /**
             * Creates a plain object from a Country message. Also converts values to other types if specified.
             * @function toObject
             * @memberof streem.api.Country
             * @static
             * @param {streem.api.Country} m Country
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Country.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.callingCode = "";
                    d.isoCode = "";
                    d.countryName = "";
                }
                if (m.callingCode != null && m.hasOwnProperty("callingCode")) {
                    d.callingCode = m.callingCode;
                }
                if (m.isoCode != null && m.hasOwnProperty("isoCode")) {
                    d.isoCode = m.isoCode;
                }
                if (m.countryName != null && m.hasOwnProperty("countryName")) {
                    d.countryName = m.countryName;
                }
                return d;
            };

            /**
             * Converts this Country to JSON.
             * @function toJSON
             * @memberof streem.api.Country
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Country.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Country
             * @function getTypeUrl
             * @memberof streem.api.Country
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Country.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/streem.api.Country";
            };

            return Country;
        })();

        return api;
    })();

    return streem;
})();

$root.WallItem = (function() {

    /**
     * Properties of a WallItem.
     * @exports IWallItem
     * @interface IWallItem
     * @property {string|null} [id] WallItem id
     * @property {string|null} [ownerUserId] WallItem ownerUserId
     * @property {string|null} [ownerDeviceId] WallItem ownerDeviceId
     * @property {google.protobuf.ITimestamp|null} [clientCreatedAt] WallItem clientCreatedAt
     * @property {WallItem.IUser|null} [user] WallItem user
     * @property {WallItem.IHeartbeat|null} [heartbeat] WallItem heartbeat
     * @property {IRoomString|null} [roomString] WallItem roomString
     * @property {streem.api.IRoom|null} [room] WallItem room
     * @property {streem.api.IRoomInvitation|null} [roomInvitation] WallItem roomInvitation
     * @property {WallItem.ICapabilities|null} [capabilities] WallItem capabilities
     * @property {WallItem.IUserFavorite|null} [userFavorite] WallItem userFavorite
     * @property {streem.api.IDevice|null} [device] WallItem device
     * @property {WallItem.IDeviceState|null} [deviceState] WallItem deviceState
     * @property {WallItem.IAudioTrack|null} [audioTrack] WallItem audioTrack
     * @property {WallItem.IVideoTrack|null} [videoTrack] WallItem videoTrack
     * @property {WallItem.IDataTrack|null} [dataTrack] WallItem dataTrack
     * @property {WallItem.ICameraSubjectRequest|null} [cameraSubjectRequest] WallItem cameraSubjectRequest
     * @property {WallItem.ICameraSubjectResponse|null} [cameraSubjectResponse] WallItem cameraSubjectResponse
     * @property {ICatalogItem|null} [catalogItem] WallItem catalogItem
     * @property {IElementGroup|null} [elementGroup] WallItem elementGroup
     * @property {IStaticAnchor|null} [staticAnchor] WallItem staticAnchor
     * @property {WallItem.IStaticAnchorStatus|null} [staticAnchorStatus] WallItem staticAnchorStatus
     * @property {ILight|null} [light] WallItem light
     * @property {WallItem.ILaserTool|null} [laserTool] WallItem laserTool
     * @property {WallItem.IArrowTool|null} [arrowTool] WallItem arrowTool
     * @property {WallItem.IMeasureTool|null} [measureTool] WallItem measureTool
     * @property {WallItem.IModel3dTool|null} [model_3dTool] WallItem model_3dTool
     * @property {WallItem.IDrawTool|null} [drawTool] WallItem drawTool
     * @property {WallItem.IStreemshotRequest|null} [streemshotRequest] WallItem streemshotRequest
     * @property {WallItem.IStreemshot|null} [streemshot] WallItem streemshot
     * @property {WallItem.IStreemshotRevision|null} [streemshotRevision] WallItem streemshotRevision
     * @property {WallItem.IStreemshotDetections|null} [streemshotDetections] WallItem streemshotDetections
     * @property {WallItem.IUserDetectionFeedback|null} [userDetectionFeedback] WallItem userDetectionFeedback
     * @property {streem.api.INote|null} [note] WallItem note
     * @property {WallItem.IStreemshotDepthMap|null} [streemshotDepthMap] WallItem streemshotDepthMap
     * @property {WallItem.IARSource|null} [arSource] WallItem arSource
     * @property {WallItem.IArSessionStatus|null} [arSessionStatus] WallItem arSessionStatus
     * @property {WallItem.IArScanningRequest|null} [arScanningRequest] WallItem arScanningRequest
     * @property {WallItem.IArScanningStatus|null} [arScanningStatus] WallItem arScanningStatus
     * @property {WallItem.IArAnchorRequest|null} [arAnchorRequest] WallItem arAnchorRequest
     * @property {WallItem.IArAnchorStatus|null} [arAnchorStatus] WallItem arAnchorStatus
     * @property {WallItem.IArTrackableStatus|null} [arTrackableStatus] WallItem arTrackableStatus
     * @property {ITutorial|null} [tutorial] WallItem tutorial
     * @property {ITutorialState|null} [tutorialState] WallItem tutorialState
     * @property {ITutorialRequest|null} [tutorialRequest] WallItem tutorialRequest
     * @property {WallItem.IArtifactRequest|null} [artifactRequest] WallItem artifactRequest
     * @property {WallItem.IArtifactCompletionRequest|null} [artifactCompletionRequest] WallItem artifactCompletionRequest
     * @property {WallItem.IArtifact|null} [artifact] WallItem artifact
     */

    /**
     * Constructs a new WallItem.
     * @exports WallItem
     * @classdesc Represents a WallItem.
     * @implements IWallItem
     * @constructor
     * @param {IWallItem=} [p] Properties to set
     */
    function WallItem(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * WallItem id.
     * @member {string} id
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.id = "";

    /**
     * WallItem ownerUserId.
     * @member {string} ownerUserId
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.ownerUserId = "";

    /**
     * WallItem ownerDeviceId.
     * @member {string} ownerDeviceId
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.ownerDeviceId = "";

    /**
     * WallItem clientCreatedAt.
     * @member {google.protobuf.ITimestamp|null|undefined} clientCreatedAt
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.clientCreatedAt = null;

    /**
     * WallItem user.
     * @member {WallItem.IUser|null|undefined} user
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.user = null;

    /**
     * WallItem heartbeat.
     * @member {WallItem.IHeartbeat|null|undefined} heartbeat
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.heartbeat = null;

    /**
     * WallItem roomString.
     * @member {IRoomString|null|undefined} roomString
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.roomString = null;

    /**
     * WallItem room.
     * @member {streem.api.IRoom|null|undefined} room
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.room = null;

    /**
     * WallItem roomInvitation.
     * @member {streem.api.IRoomInvitation|null|undefined} roomInvitation
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.roomInvitation = null;

    /**
     * WallItem capabilities.
     * @member {WallItem.ICapabilities|null|undefined} capabilities
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.capabilities = null;

    /**
     * WallItem userFavorite.
     * @member {WallItem.IUserFavorite|null|undefined} userFavorite
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.userFavorite = null;

    /**
     * WallItem device.
     * @member {streem.api.IDevice|null|undefined} device
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.device = null;

    /**
     * WallItem deviceState.
     * @member {WallItem.IDeviceState|null|undefined} deviceState
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.deviceState = null;

    /**
     * WallItem audioTrack.
     * @member {WallItem.IAudioTrack|null|undefined} audioTrack
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.audioTrack = null;

    /**
     * WallItem videoTrack.
     * @member {WallItem.IVideoTrack|null|undefined} videoTrack
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.videoTrack = null;

    /**
     * WallItem dataTrack.
     * @member {WallItem.IDataTrack|null|undefined} dataTrack
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.dataTrack = null;

    /**
     * WallItem cameraSubjectRequest.
     * @member {WallItem.ICameraSubjectRequest|null|undefined} cameraSubjectRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.cameraSubjectRequest = null;

    /**
     * WallItem cameraSubjectResponse.
     * @member {WallItem.ICameraSubjectResponse|null|undefined} cameraSubjectResponse
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.cameraSubjectResponse = null;

    /**
     * WallItem catalogItem.
     * @member {ICatalogItem|null|undefined} catalogItem
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.catalogItem = null;

    /**
     * WallItem elementGroup.
     * @member {IElementGroup|null|undefined} elementGroup
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.elementGroup = null;

    /**
     * WallItem staticAnchor.
     * @member {IStaticAnchor|null|undefined} staticAnchor
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.staticAnchor = null;

    /**
     * WallItem staticAnchorStatus.
     * @member {WallItem.IStaticAnchorStatus|null|undefined} staticAnchorStatus
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.staticAnchorStatus = null;

    /**
     * WallItem light.
     * @member {ILight|null|undefined} light
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.light = null;

    /**
     * WallItem laserTool.
     * @member {WallItem.ILaserTool|null|undefined} laserTool
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.laserTool = null;

    /**
     * WallItem arrowTool.
     * @member {WallItem.IArrowTool|null|undefined} arrowTool
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arrowTool = null;

    /**
     * WallItem measureTool.
     * @member {WallItem.IMeasureTool|null|undefined} measureTool
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.measureTool = null;

    /**
     * WallItem model_3dTool.
     * @member {WallItem.IModel3dTool|null|undefined} model_3dTool
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.model_3dTool = null;

    /**
     * WallItem drawTool.
     * @member {WallItem.IDrawTool|null|undefined} drawTool
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.drawTool = null;

    /**
     * WallItem streemshotRequest.
     * @member {WallItem.IStreemshotRequest|null|undefined} streemshotRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.streemshotRequest = null;

    /**
     * WallItem streemshot.
     * @member {WallItem.IStreemshot|null|undefined} streemshot
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.streemshot = null;

    /**
     * WallItem streemshotRevision.
     * @member {WallItem.IStreemshotRevision|null|undefined} streemshotRevision
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.streemshotRevision = null;

    /**
     * WallItem streemshotDetections.
     * @member {WallItem.IStreemshotDetections|null|undefined} streemshotDetections
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.streemshotDetections = null;

    /**
     * WallItem userDetectionFeedback.
     * @member {WallItem.IUserDetectionFeedback|null|undefined} userDetectionFeedback
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.userDetectionFeedback = null;

    /**
     * WallItem note.
     * @member {streem.api.INote|null|undefined} note
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.note = null;

    /**
     * WallItem streemshotDepthMap.
     * @member {WallItem.IStreemshotDepthMap|null|undefined} streemshotDepthMap
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.streemshotDepthMap = null;

    /**
     * WallItem arSource.
     * @member {WallItem.IARSource|null|undefined} arSource
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arSource = null;

    /**
     * WallItem arSessionStatus.
     * @member {WallItem.IArSessionStatus|null|undefined} arSessionStatus
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arSessionStatus = null;

    /**
     * WallItem arScanningRequest.
     * @member {WallItem.IArScanningRequest|null|undefined} arScanningRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arScanningRequest = null;

    /**
     * WallItem arScanningStatus.
     * @member {WallItem.IArScanningStatus|null|undefined} arScanningStatus
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arScanningStatus = null;

    /**
     * WallItem arAnchorRequest.
     * @member {WallItem.IArAnchorRequest|null|undefined} arAnchorRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arAnchorRequest = null;

    /**
     * WallItem arAnchorStatus.
     * @member {WallItem.IArAnchorStatus|null|undefined} arAnchorStatus
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arAnchorStatus = null;

    /**
     * WallItem arTrackableStatus.
     * @member {WallItem.IArTrackableStatus|null|undefined} arTrackableStatus
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.arTrackableStatus = null;

    /**
     * WallItem tutorial.
     * @member {ITutorial|null|undefined} tutorial
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.tutorial = null;

    /**
     * WallItem tutorialState.
     * @member {ITutorialState|null|undefined} tutorialState
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.tutorialState = null;

    /**
     * WallItem tutorialRequest.
     * @member {ITutorialRequest|null|undefined} tutorialRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.tutorialRequest = null;

    /**
     * WallItem artifactRequest.
     * @member {WallItem.IArtifactRequest|null|undefined} artifactRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.artifactRequest = null;

    /**
     * WallItem artifactCompletionRequest.
     * @member {WallItem.IArtifactCompletionRequest|null|undefined} artifactCompletionRequest
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.artifactCompletionRequest = null;

    /**
     * WallItem artifact.
     * @member {WallItem.IArtifact|null|undefined} artifact
     * @memberof WallItem
     * @instance
     */
    WallItem.prototype.artifact = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * WallItem type.
     * @member {"user"|"heartbeat"|"roomString"|"room"|"roomInvitation"|"capabilities"|"userFavorite"|"device"|"deviceState"|"audioTrack"|"videoTrack"|"dataTrack"|"cameraSubjectRequest"|"cameraSubjectResponse"|"catalogItem"|"elementGroup"|"staticAnchor"|"staticAnchorStatus"|"light"|"laserTool"|"arrowTool"|"measureTool"|"model_3dTool"|"drawTool"|"streemshotRequest"|"streemshot"|"streemshotRevision"|"streemshotDetections"|"userDetectionFeedback"|"note"|"streemshotDepthMap"|"arSource"|"arSessionStatus"|"arScanningRequest"|"arScanningStatus"|"arAnchorRequest"|"arAnchorStatus"|"arTrackableStatus"|"tutorial"|"tutorialState"|"tutorialRequest"|"artifactRequest"|"artifactCompletionRequest"|"artifact"|undefined} type
     * @memberof WallItem
     * @instance
     */
    Object.defineProperty(WallItem.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["user", "heartbeat", "roomString", "room", "roomInvitation", "capabilities", "userFavorite", "device", "deviceState", "audioTrack", "videoTrack", "dataTrack", "cameraSubjectRequest", "cameraSubjectResponse", "catalogItem", "elementGroup", "staticAnchor", "staticAnchorStatus", "light", "laserTool", "arrowTool", "measureTool", "model_3dTool", "drawTool", "streemshotRequest", "streemshot", "streemshotRevision", "streemshotDetections", "userDetectionFeedback", "note", "streemshotDepthMap", "arSource", "arSessionStatus", "arScanningRequest", "arScanningStatus", "arAnchorRequest", "arAnchorStatus", "arTrackableStatus", "tutorial", "tutorialState", "tutorialRequest", "artifactRequest", "artifactCompletionRequest", "artifact"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new WallItem instance using the specified properties.
     * @function create
     * @memberof WallItem
     * @static
     * @param {IWallItem=} [properties] Properties to set
     * @returns {WallItem} WallItem instance
     */
    WallItem.create = function create(properties) {
        return new WallItem(properties);
    };

    /**
     * Encodes the specified WallItem message. Does not implicitly {@link WallItem.verify|verify} messages.
     * @function encode
     * @memberof WallItem
     * @static
     * @param {IWallItem} m WallItem message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WallItem.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.ownerUserId != null && Object.hasOwnProperty.call(m, "ownerUserId"))
            w.uint32(18).string(m.ownerUserId);
        if (m.ownerDeviceId != null && Object.hasOwnProperty.call(m, "ownerDeviceId"))
            w.uint32(26).string(m.ownerDeviceId);
        if (m.clientCreatedAt != null && Object.hasOwnProperty.call(m, "clientCreatedAt"))
            $root.google.protobuf.Timestamp.encode(m.clientCreatedAt, w.uint32(34).fork()).ldelim();
        if (m.user != null && Object.hasOwnProperty.call(m, "user"))
            $root.WallItem.User.encode(m.user, w.uint32(810).fork()).ldelim();
        if (m.heartbeat != null && Object.hasOwnProperty.call(m, "heartbeat"))
            $root.WallItem.Heartbeat.encode(m.heartbeat, w.uint32(818).fork()).ldelim();
        if (m.roomString != null && Object.hasOwnProperty.call(m, "roomString"))
            $root.RoomString.encode(m.roomString, w.uint32(826).fork()).ldelim();
        if (m.room != null && Object.hasOwnProperty.call(m, "room"))
            $root.streem.api.Room.encode(m.room, w.uint32(834).fork()).ldelim();
        if (m.roomInvitation != null && Object.hasOwnProperty.call(m, "roomInvitation"))
            $root.streem.api.RoomInvitation.encode(m.roomInvitation, w.uint32(842).fork()).ldelim();
        if (m.capabilities != null && Object.hasOwnProperty.call(m, "capabilities"))
            $root.WallItem.Capabilities.encode(m.capabilities, w.uint32(850).fork()).ldelim();
        if (m.userFavorite != null && Object.hasOwnProperty.call(m, "userFavorite"))
            $root.WallItem.UserFavorite.encode(m.userFavorite, w.uint32(858).fork()).ldelim();
        if (m.device != null && Object.hasOwnProperty.call(m, "device"))
            $root.streem.api.Device.encode(m.device, w.uint32(866).fork()).ldelim();
        if (m.deviceState != null && Object.hasOwnProperty.call(m, "deviceState"))
            $root.WallItem.DeviceState.encode(m.deviceState, w.uint32(874).fork()).ldelim();
        if (m.audioTrack != null && Object.hasOwnProperty.call(m, "audioTrack"))
            $root.WallItem.AudioTrack.encode(m.audioTrack, w.uint32(1602).fork()).ldelim();
        if (m.videoTrack != null && Object.hasOwnProperty.call(m, "videoTrack"))
            $root.WallItem.VideoTrack.encode(m.videoTrack, w.uint32(1610).fork()).ldelim();
        if (m.dataTrack != null && Object.hasOwnProperty.call(m, "dataTrack"))
            $root.WallItem.DataTrack.encode(m.dataTrack, w.uint32(1618).fork()).ldelim();
        if (m.cameraSubjectRequest != null && Object.hasOwnProperty.call(m, "cameraSubjectRequest"))
            $root.WallItem.CameraSubjectRequest.encode(m.cameraSubjectRequest, w.uint32(1626).fork()).ldelim();
        if (m.cameraSubjectResponse != null && Object.hasOwnProperty.call(m, "cameraSubjectResponse"))
            $root.WallItem.CameraSubjectResponse.encode(m.cameraSubjectResponse, w.uint32(1634).fork()).ldelim();
        if (m.catalogItem != null && Object.hasOwnProperty.call(m, "catalogItem"))
            $root.CatalogItem.encode(m.catalogItem, w.uint32(2002).fork()).ldelim();
        if (m.elementGroup != null && Object.hasOwnProperty.call(m, "elementGroup"))
            $root.ElementGroup.encode(m.elementGroup, w.uint32(2010).fork()).ldelim();
        if (m.staticAnchor != null && Object.hasOwnProperty.call(m, "staticAnchor"))
            $root.StaticAnchor.encode(m.staticAnchor, w.uint32(2018).fork()).ldelim();
        if (m.staticAnchorStatus != null && Object.hasOwnProperty.call(m, "staticAnchorStatus"))
            $root.WallItem.StaticAnchorStatus.encode(m.staticAnchorStatus, w.uint32(2026).fork()).ldelim();
        if (m.light != null && Object.hasOwnProperty.call(m, "light"))
            $root.Light.encode(m.light, w.uint32(2034).fork()).ldelim();
        if (m.laserTool != null && Object.hasOwnProperty.call(m, "laserTool"))
            $root.WallItem.LaserTool.encode(m.laserTool, w.uint32(2402).fork()).ldelim();
        if (m.arrowTool != null && Object.hasOwnProperty.call(m, "arrowTool"))
            $root.WallItem.ArrowTool.encode(m.arrowTool, w.uint32(2410).fork()).ldelim();
        if (m.measureTool != null && Object.hasOwnProperty.call(m, "measureTool"))
            $root.WallItem.MeasureTool.encode(m.measureTool, w.uint32(2418).fork()).ldelim();
        if (m.model_3dTool != null && Object.hasOwnProperty.call(m, "model_3dTool"))
            $root.WallItem.Model3dTool.encode(m.model_3dTool, w.uint32(2426).fork()).ldelim();
        if (m.drawTool != null && Object.hasOwnProperty.call(m, "drawTool"))
            $root.WallItem.DrawTool.encode(m.drawTool, w.uint32(2434).fork()).ldelim();
        if (m.streemshotRequest != null && Object.hasOwnProperty.call(m, "streemshotRequest"))
            $root.WallItem.StreemshotRequest.encode(m.streemshotRequest, w.uint32(3202).fork()).ldelim();
        if (m.streemshot != null && Object.hasOwnProperty.call(m, "streemshot"))
            $root.WallItem.Streemshot.encode(m.streemshot, w.uint32(3210).fork()).ldelim();
        if (m.streemshotRevision != null && Object.hasOwnProperty.call(m, "streemshotRevision"))
            $root.WallItem.StreemshotRevision.encode(m.streemshotRevision, w.uint32(3218).fork()).ldelim();
        if (m.streemshotDetections != null && Object.hasOwnProperty.call(m, "streemshotDetections"))
            $root.WallItem.StreemshotDetections.encode(m.streemshotDetections, w.uint32(3226).fork()).ldelim();
        if (m.userDetectionFeedback != null && Object.hasOwnProperty.call(m, "userDetectionFeedback"))
            $root.WallItem.UserDetectionFeedback.encode(m.userDetectionFeedback, w.uint32(3234).fork()).ldelim();
        if (m.note != null && Object.hasOwnProperty.call(m, "note"))
            $root.streem.api.Note.encode(m.note, w.uint32(3242).fork()).ldelim();
        if (m.streemshotDepthMap != null && Object.hasOwnProperty.call(m, "streemshotDepthMap"))
            $root.WallItem.StreemshotDepthMap.encode(m.streemshotDepthMap, w.uint32(3250).fork()).ldelim();
        if (m.arSource != null && Object.hasOwnProperty.call(m, "arSource"))
            $root.WallItem.ARSource.encode(m.arSource, w.uint32(4002).fork()).ldelim();
        if (m.arSessionStatus != null && Object.hasOwnProperty.call(m, "arSessionStatus"))
            $root.WallItem.ArSessionStatus.encode(m.arSessionStatus, w.uint32(4010).fork()).ldelim();
        if (m.arScanningRequest != null && Object.hasOwnProperty.call(m, "arScanningRequest"))
            $root.WallItem.ArScanningRequest.encode(m.arScanningRequest, w.uint32(4018).fork()).ldelim();
        if (m.arScanningStatus != null && Object.hasOwnProperty.call(m, "arScanningStatus"))
            $root.WallItem.ArScanningStatus.encode(m.arScanningStatus, w.uint32(4026).fork()).ldelim();
        if (m.arAnchorRequest != null && Object.hasOwnProperty.call(m, "arAnchorRequest"))
            $root.WallItem.ArAnchorRequest.encode(m.arAnchorRequest, w.uint32(4034).fork()).ldelim();
        if (m.arAnchorStatus != null && Object.hasOwnProperty.call(m, "arAnchorStatus"))
            $root.WallItem.ArAnchorStatus.encode(m.arAnchorStatus, w.uint32(4042).fork()).ldelim();
        if (m.arTrackableStatus != null && Object.hasOwnProperty.call(m, "arTrackableStatus"))
            $root.WallItem.ArTrackableStatus.encode(m.arTrackableStatus, w.uint32(4050).fork()).ldelim();
        if (m.tutorial != null && Object.hasOwnProperty.call(m, "tutorial"))
            $root.Tutorial.encode(m.tutorial, w.uint32(4802).fork()).ldelim();
        if (m.tutorialState != null && Object.hasOwnProperty.call(m, "tutorialState"))
            $root.TutorialState.encode(m.tutorialState, w.uint32(4810).fork()).ldelim();
        if (m.tutorialRequest != null && Object.hasOwnProperty.call(m, "tutorialRequest"))
            $root.TutorialRequest.encode(m.tutorialRequest, w.uint32(4818).fork()).ldelim();
        if (m.artifactRequest != null && Object.hasOwnProperty.call(m, "artifactRequest"))
            $root.WallItem.ArtifactRequest.encode(m.artifactRequest, w.uint32(6402).fork()).ldelim();
        if (m.artifact != null && Object.hasOwnProperty.call(m, "artifact"))
            $root.WallItem.Artifact.encode(m.artifact, w.uint32(6410).fork()).ldelim();
        if (m.artifactCompletionRequest != null && Object.hasOwnProperty.call(m, "artifactCompletionRequest"))
            $root.WallItem.ArtifactCompletionRequest.encode(m.artifactCompletionRequest, w.uint32(6418).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a WallItem message from the specified reader or buffer.
     * @function decode
     * @memberof WallItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {WallItem} WallItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WallItem.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.ownerUserId = r.string();
                    break;
                }
            case 3: {
                    m.ownerDeviceId = r.string();
                    break;
                }
            case 4: {
                    m.clientCreatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                }
            case 101: {
                    m.user = $root.WallItem.User.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.heartbeat = $root.WallItem.Heartbeat.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.roomString = $root.RoomString.decode(r, r.uint32());
                    break;
                }
            case 104: {
                    m.room = $root.streem.api.Room.decode(r, r.uint32());
                    break;
                }
            case 105: {
                    m.roomInvitation = $root.streem.api.RoomInvitation.decode(r, r.uint32());
                    break;
                }
            case 106: {
                    m.capabilities = $root.WallItem.Capabilities.decode(r, r.uint32());
                    break;
                }
            case 107: {
                    m.userFavorite = $root.WallItem.UserFavorite.decode(r, r.uint32());
                    break;
                }
            case 108: {
                    m.device = $root.streem.api.Device.decode(r, r.uint32());
                    break;
                }
            case 109: {
                    m.deviceState = $root.WallItem.DeviceState.decode(r, r.uint32());
                    break;
                }
            case 200: {
                    m.audioTrack = $root.WallItem.AudioTrack.decode(r, r.uint32());
                    break;
                }
            case 201: {
                    m.videoTrack = $root.WallItem.VideoTrack.decode(r, r.uint32());
                    break;
                }
            case 202: {
                    m.dataTrack = $root.WallItem.DataTrack.decode(r, r.uint32());
                    break;
                }
            case 203: {
                    m.cameraSubjectRequest = $root.WallItem.CameraSubjectRequest.decode(r, r.uint32());
                    break;
                }
            case 204: {
                    m.cameraSubjectResponse = $root.WallItem.CameraSubjectResponse.decode(r, r.uint32());
                    break;
                }
            case 250: {
                    m.catalogItem = $root.CatalogItem.decode(r, r.uint32());
                    break;
                }
            case 251: {
                    m.elementGroup = $root.ElementGroup.decode(r, r.uint32());
                    break;
                }
            case 252: {
                    m.staticAnchor = $root.StaticAnchor.decode(r, r.uint32());
                    break;
                }
            case 253: {
                    m.staticAnchorStatus = $root.WallItem.StaticAnchorStatus.decode(r, r.uint32());
                    break;
                }
            case 254: {
                    m.light = $root.Light.decode(r, r.uint32());
                    break;
                }
            case 300: {
                    m.laserTool = $root.WallItem.LaserTool.decode(r, r.uint32());
                    break;
                }
            case 301: {
                    m.arrowTool = $root.WallItem.ArrowTool.decode(r, r.uint32());
                    break;
                }
            case 302: {
                    m.measureTool = $root.WallItem.MeasureTool.decode(r, r.uint32());
                    break;
                }
            case 303: {
                    m.model_3dTool = $root.WallItem.Model3dTool.decode(r, r.uint32());
                    break;
                }
            case 304: {
                    m.drawTool = $root.WallItem.DrawTool.decode(r, r.uint32());
                    break;
                }
            case 400: {
                    m.streemshotRequest = $root.WallItem.StreemshotRequest.decode(r, r.uint32());
                    break;
                }
            case 401: {
                    m.streemshot = $root.WallItem.Streemshot.decode(r, r.uint32());
                    break;
                }
            case 402: {
                    m.streemshotRevision = $root.WallItem.StreemshotRevision.decode(r, r.uint32());
                    break;
                }
            case 403: {
                    m.streemshotDetections = $root.WallItem.StreemshotDetections.decode(r, r.uint32());
                    break;
                }
            case 404: {
                    m.userDetectionFeedback = $root.WallItem.UserDetectionFeedback.decode(r, r.uint32());
                    break;
                }
            case 405: {
                    m.note = $root.streem.api.Note.decode(r, r.uint32());
                    break;
                }
            case 406: {
                    m.streemshotDepthMap = $root.WallItem.StreemshotDepthMap.decode(r, r.uint32());
                    break;
                }
            case 500: {
                    m.arSource = $root.WallItem.ARSource.decode(r, r.uint32());
                    break;
                }
            case 501: {
                    m.arSessionStatus = $root.WallItem.ArSessionStatus.decode(r, r.uint32());
                    break;
                }
            case 502: {
                    m.arScanningRequest = $root.WallItem.ArScanningRequest.decode(r, r.uint32());
                    break;
                }
            case 503: {
                    m.arScanningStatus = $root.WallItem.ArScanningStatus.decode(r, r.uint32());
                    break;
                }
            case 504: {
                    m.arAnchorRequest = $root.WallItem.ArAnchorRequest.decode(r, r.uint32());
                    break;
                }
            case 505: {
                    m.arAnchorStatus = $root.WallItem.ArAnchorStatus.decode(r, r.uint32());
                    break;
                }
            case 506: {
                    m.arTrackableStatus = $root.WallItem.ArTrackableStatus.decode(r, r.uint32());
                    break;
                }
            case 600: {
                    m.tutorial = $root.Tutorial.decode(r, r.uint32());
                    break;
                }
            case 601: {
                    m.tutorialState = $root.TutorialState.decode(r, r.uint32());
                    break;
                }
            case 602: {
                    m.tutorialRequest = $root.TutorialRequest.decode(r, r.uint32());
                    break;
                }
            case 800: {
                    m.artifactRequest = $root.WallItem.ArtifactRequest.decode(r, r.uint32());
                    break;
                }
            case 802: {
                    m.artifactCompletionRequest = $root.WallItem.ArtifactCompletionRequest.decode(r, r.uint32());
                    break;
                }
            case 801: {
                    m.artifact = $root.WallItem.Artifact.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a WallItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WallItem
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {WallItem} WallItem
     */
    WallItem.fromObject = function fromObject(d) {
        if (d instanceof $root.WallItem)
            return d;
        var m = new $root.WallItem();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.ownerUserId != null) {
            m.ownerUserId = String(d.ownerUserId);
        }
        if (d.ownerDeviceId != null) {
            m.ownerDeviceId = String(d.ownerDeviceId);
        }
        if (d.clientCreatedAt != null) {
            if (typeof d.clientCreatedAt !== "object")
                throw TypeError(".WallItem.clientCreatedAt: object expected");
            m.clientCreatedAt = $root.google.protobuf.Timestamp.fromObject(d.clientCreatedAt);
        }
        if (d.user != null) {
            if (typeof d.user !== "object")
                throw TypeError(".WallItem.user: object expected");
            m.user = $root.WallItem.User.fromObject(d.user);
        }
        if (d.heartbeat != null) {
            if (typeof d.heartbeat !== "object")
                throw TypeError(".WallItem.heartbeat: object expected");
            m.heartbeat = $root.WallItem.Heartbeat.fromObject(d.heartbeat);
        }
        if (d.roomString != null) {
            if (typeof d.roomString !== "object")
                throw TypeError(".WallItem.roomString: object expected");
            m.roomString = $root.RoomString.fromObject(d.roomString);
        }
        if (d.room != null) {
            if (typeof d.room !== "object")
                throw TypeError(".WallItem.room: object expected");
            m.room = $root.streem.api.Room.fromObject(d.room);
        }
        if (d.roomInvitation != null) {
            if (typeof d.roomInvitation !== "object")
                throw TypeError(".WallItem.roomInvitation: object expected");
            m.roomInvitation = $root.streem.api.RoomInvitation.fromObject(d.roomInvitation);
        }
        if (d.capabilities != null) {
            if (typeof d.capabilities !== "object")
                throw TypeError(".WallItem.capabilities: object expected");
            m.capabilities = $root.WallItem.Capabilities.fromObject(d.capabilities);
        }
        if (d.userFavorite != null) {
            if (typeof d.userFavorite !== "object")
                throw TypeError(".WallItem.userFavorite: object expected");
            m.userFavorite = $root.WallItem.UserFavorite.fromObject(d.userFavorite);
        }
        if (d.device != null) {
            if (typeof d.device !== "object")
                throw TypeError(".WallItem.device: object expected");
            m.device = $root.streem.api.Device.fromObject(d.device);
        }
        if (d.deviceState != null) {
            if (typeof d.deviceState !== "object")
                throw TypeError(".WallItem.deviceState: object expected");
            m.deviceState = $root.WallItem.DeviceState.fromObject(d.deviceState);
        }
        if (d.audioTrack != null) {
            if (typeof d.audioTrack !== "object")
                throw TypeError(".WallItem.audioTrack: object expected");
            m.audioTrack = $root.WallItem.AudioTrack.fromObject(d.audioTrack);
        }
        if (d.videoTrack != null) {
            if (typeof d.videoTrack !== "object")
                throw TypeError(".WallItem.videoTrack: object expected");
            m.videoTrack = $root.WallItem.VideoTrack.fromObject(d.videoTrack);
        }
        if (d.dataTrack != null) {
            if (typeof d.dataTrack !== "object")
                throw TypeError(".WallItem.dataTrack: object expected");
            m.dataTrack = $root.WallItem.DataTrack.fromObject(d.dataTrack);
        }
        if (d.cameraSubjectRequest != null) {
            if (typeof d.cameraSubjectRequest !== "object")
                throw TypeError(".WallItem.cameraSubjectRequest: object expected");
            m.cameraSubjectRequest = $root.WallItem.CameraSubjectRequest.fromObject(d.cameraSubjectRequest);
        }
        if (d.cameraSubjectResponse != null) {
            if (typeof d.cameraSubjectResponse !== "object")
                throw TypeError(".WallItem.cameraSubjectResponse: object expected");
            m.cameraSubjectResponse = $root.WallItem.CameraSubjectResponse.fromObject(d.cameraSubjectResponse);
        }
        if (d.catalogItem != null) {
            if (typeof d.catalogItem !== "object")
                throw TypeError(".WallItem.catalogItem: object expected");
            m.catalogItem = $root.CatalogItem.fromObject(d.catalogItem);
        }
        if (d.elementGroup != null) {
            if (typeof d.elementGroup !== "object")
                throw TypeError(".WallItem.elementGroup: object expected");
            m.elementGroup = $root.ElementGroup.fromObject(d.elementGroup);
        }
        if (d.staticAnchor != null) {
            if (typeof d.staticAnchor !== "object")
                throw TypeError(".WallItem.staticAnchor: object expected");
            m.staticAnchor = $root.StaticAnchor.fromObject(d.staticAnchor);
        }
        if (d.staticAnchorStatus != null) {
            if (typeof d.staticAnchorStatus !== "object")
                throw TypeError(".WallItem.staticAnchorStatus: object expected");
            m.staticAnchorStatus = $root.WallItem.StaticAnchorStatus.fromObject(d.staticAnchorStatus);
        }
        if (d.light != null) {
            if (typeof d.light !== "object")
                throw TypeError(".WallItem.light: object expected");
            m.light = $root.Light.fromObject(d.light);
        }
        if (d.laserTool != null) {
            if (typeof d.laserTool !== "object")
                throw TypeError(".WallItem.laserTool: object expected");
            m.laserTool = $root.WallItem.LaserTool.fromObject(d.laserTool);
        }
        if (d.arrowTool != null) {
            if (typeof d.arrowTool !== "object")
                throw TypeError(".WallItem.arrowTool: object expected");
            m.arrowTool = $root.WallItem.ArrowTool.fromObject(d.arrowTool);
        }
        if (d.measureTool != null) {
            if (typeof d.measureTool !== "object")
                throw TypeError(".WallItem.measureTool: object expected");
            m.measureTool = $root.WallItem.MeasureTool.fromObject(d.measureTool);
        }
        if (d.model_3dTool != null) {
            if (typeof d.model_3dTool !== "object")
                throw TypeError(".WallItem.model_3dTool: object expected");
            m.model_3dTool = $root.WallItem.Model3dTool.fromObject(d.model_3dTool);
        }
        if (d.drawTool != null) {
            if (typeof d.drawTool !== "object")
                throw TypeError(".WallItem.drawTool: object expected");
            m.drawTool = $root.WallItem.DrawTool.fromObject(d.drawTool);
        }
        if (d.streemshotRequest != null) {
            if (typeof d.streemshotRequest !== "object")
                throw TypeError(".WallItem.streemshotRequest: object expected");
            m.streemshotRequest = $root.WallItem.StreemshotRequest.fromObject(d.streemshotRequest);
        }
        if (d.streemshot != null) {
            if (typeof d.streemshot !== "object")
                throw TypeError(".WallItem.streemshot: object expected");
            m.streemshot = $root.WallItem.Streemshot.fromObject(d.streemshot);
        }
        if (d.streemshotRevision != null) {
            if (typeof d.streemshotRevision !== "object")
                throw TypeError(".WallItem.streemshotRevision: object expected");
            m.streemshotRevision = $root.WallItem.StreemshotRevision.fromObject(d.streemshotRevision);
        }
        if (d.streemshotDetections != null) {
            if (typeof d.streemshotDetections !== "object")
                throw TypeError(".WallItem.streemshotDetections: object expected");
            m.streemshotDetections = $root.WallItem.StreemshotDetections.fromObject(d.streemshotDetections);
        }
        if (d.userDetectionFeedback != null) {
            if (typeof d.userDetectionFeedback !== "object")
                throw TypeError(".WallItem.userDetectionFeedback: object expected");
            m.userDetectionFeedback = $root.WallItem.UserDetectionFeedback.fromObject(d.userDetectionFeedback);
        }
        if (d.note != null) {
            if (typeof d.note !== "object")
                throw TypeError(".WallItem.note: object expected");
            m.note = $root.streem.api.Note.fromObject(d.note);
        }
        if (d.streemshotDepthMap != null) {
            if (typeof d.streemshotDepthMap !== "object")
                throw TypeError(".WallItem.streemshotDepthMap: object expected");
            m.streemshotDepthMap = $root.WallItem.StreemshotDepthMap.fromObject(d.streemshotDepthMap);
        }
        if (d.arSource != null) {
            if (typeof d.arSource !== "object")
                throw TypeError(".WallItem.arSource: object expected");
            m.arSource = $root.WallItem.ARSource.fromObject(d.arSource);
        }
        if (d.arSessionStatus != null) {
            if (typeof d.arSessionStatus !== "object")
                throw TypeError(".WallItem.arSessionStatus: object expected");
            m.arSessionStatus = $root.WallItem.ArSessionStatus.fromObject(d.arSessionStatus);
        }
        if (d.arScanningRequest != null) {
            if (typeof d.arScanningRequest !== "object")
                throw TypeError(".WallItem.arScanningRequest: object expected");
            m.arScanningRequest = $root.WallItem.ArScanningRequest.fromObject(d.arScanningRequest);
        }
        if (d.arScanningStatus != null) {
            if (typeof d.arScanningStatus !== "object")
                throw TypeError(".WallItem.arScanningStatus: object expected");
            m.arScanningStatus = $root.WallItem.ArScanningStatus.fromObject(d.arScanningStatus);
        }
        if (d.arAnchorRequest != null) {
            if (typeof d.arAnchorRequest !== "object")
                throw TypeError(".WallItem.arAnchorRequest: object expected");
            m.arAnchorRequest = $root.WallItem.ArAnchorRequest.fromObject(d.arAnchorRequest);
        }
        if (d.arAnchorStatus != null) {
            if (typeof d.arAnchorStatus !== "object")
                throw TypeError(".WallItem.arAnchorStatus: object expected");
            m.arAnchorStatus = $root.WallItem.ArAnchorStatus.fromObject(d.arAnchorStatus);
        }
        if (d.arTrackableStatus != null) {
            if (typeof d.arTrackableStatus !== "object")
                throw TypeError(".WallItem.arTrackableStatus: object expected");
            m.arTrackableStatus = $root.WallItem.ArTrackableStatus.fromObject(d.arTrackableStatus);
        }
        if (d.tutorial != null) {
            if (typeof d.tutorial !== "object")
                throw TypeError(".WallItem.tutorial: object expected");
            m.tutorial = $root.Tutorial.fromObject(d.tutorial);
        }
        if (d.tutorialState != null) {
            if (typeof d.tutorialState !== "object")
                throw TypeError(".WallItem.tutorialState: object expected");
            m.tutorialState = $root.TutorialState.fromObject(d.tutorialState);
        }
        if (d.tutorialRequest != null) {
            if (typeof d.tutorialRequest !== "object")
                throw TypeError(".WallItem.tutorialRequest: object expected");
            m.tutorialRequest = $root.TutorialRequest.fromObject(d.tutorialRequest);
        }
        if (d.artifactRequest != null) {
            if (typeof d.artifactRequest !== "object")
                throw TypeError(".WallItem.artifactRequest: object expected");
            m.artifactRequest = $root.WallItem.ArtifactRequest.fromObject(d.artifactRequest);
        }
        if (d.artifactCompletionRequest != null) {
            if (typeof d.artifactCompletionRequest !== "object")
                throw TypeError(".WallItem.artifactCompletionRequest: object expected");
            m.artifactCompletionRequest = $root.WallItem.ArtifactCompletionRequest.fromObject(d.artifactCompletionRequest);
        }
        if (d.artifact != null) {
            if (typeof d.artifact !== "object")
                throw TypeError(".WallItem.artifact: object expected");
            m.artifact = $root.WallItem.Artifact.fromObject(d.artifact);
        }
        return m;
    };

    /**
     * Creates a plain object from a WallItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WallItem
     * @static
     * @param {WallItem} m WallItem
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WallItem.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
            d.ownerUserId = "";
            d.ownerDeviceId = "";
            d.clientCreatedAt = null;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.ownerUserId != null && m.hasOwnProperty("ownerUserId")) {
            d.ownerUserId = m.ownerUserId;
        }
        if (m.ownerDeviceId != null && m.hasOwnProperty("ownerDeviceId")) {
            d.ownerDeviceId = m.ownerDeviceId;
        }
        if (m.clientCreatedAt != null && m.hasOwnProperty("clientCreatedAt")) {
            d.clientCreatedAt = $root.google.protobuf.Timestamp.toObject(m.clientCreatedAt, o);
        }
        if (m.user != null && m.hasOwnProperty("user")) {
            d.user = $root.WallItem.User.toObject(m.user, o);
            if (o.oneofs)
                d.type = "user";
        }
        if (m.heartbeat != null && m.hasOwnProperty("heartbeat")) {
            d.heartbeat = $root.WallItem.Heartbeat.toObject(m.heartbeat, o);
            if (o.oneofs)
                d.type = "heartbeat";
        }
        if (m.roomString != null && m.hasOwnProperty("roomString")) {
            d.roomString = $root.RoomString.toObject(m.roomString, o);
            if (o.oneofs)
                d.type = "roomString";
        }
        if (m.room != null && m.hasOwnProperty("room")) {
            d.room = $root.streem.api.Room.toObject(m.room, o);
            if (o.oneofs)
                d.type = "room";
        }
        if (m.roomInvitation != null && m.hasOwnProperty("roomInvitation")) {
            d.roomInvitation = $root.streem.api.RoomInvitation.toObject(m.roomInvitation, o);
            if (o.oneofs)
                d.type = "roomInvitation";
        }
        if (m.capabilities != null && m.hasOwnProperty("capabilities")) {
            d.capabilities = $root.WallItem.Capabilities.toObject(m.capabilities, o);
            if (o.oneofs)
                d.type = "capabilities";
        }
        if (m.userFavorite != null && m.hasOwnProperty("userFavorite")) {
            d.userFavorite = $root.WallItem.UserFavorite.toObject(m.userFavorite, o);
            if (o.oneofs)
                d.type = "userFavorite";
        }
        if (m.device != null && m.hasOwnProperty("device")) {
            d.device = $root.streem.api.Device.toObject(m.device, o);
            if (o.oneofs)
                d.type = "device";
        }
        if (m.deviceState != null && m.hasOwnProperty("deviceState")) {
            d.deviceState = $root.WallItem.DeviceState.toObject(m.deviceState, o);
            if (o.oneofs)
                d.type = "deviceState";
        }
        if (m.audioTrack != null && m.hasOwnProperty("audioTrack")) {
            d.audioTrack = $root.WallItem.AudioTrack.toObject(m.audioTrack, o);
            if (o.oneofs)
                d.type = "audioTrack";
        }
        if (m.videoTrack != null && m.hasOwnProperty("videoTrack")) {
            d.videoTrack = $root.WallItem.VideoTrack.toObject(m.videoTrack, o);
            if (o.oneofs)
                d.type = "videoTrack";
        }
        if (m.dataTrack != null && m.hasOwnProperty("dataTrack")) {
            d.dataTrack = $root.WallItem.DataTrack.toObject(m.dataTrack, o);
            if (o.oneofs)
                d.type = "dataTrack";
        }
        if (m.cameraSubjectRequest != null && m.hasOwnProperty("cameraSubjectRequest")) {
            d.cameraSubjectRequest = $root.WallItem.CameraSubjectRequest.toObject(m.cameraSubjectRequest, o);
            if (o.oneofs)
                d.type = "cameraSubjectRequest";
        }
        if (m.cameraSubjectResponse != null && m.hasOwnProperty("cameraSubjectResponse")) {
            d.cameraSubjectResponse = $root.WallItem.CameraSubjectResponse.toObject(m.cameraSubjectResponse, o);
            if (o.oneofs)
                d.type = "cameraSubjectResponse";
        }
        if (m.catalogItem != null && m.hasOwnProperty("catalogItem")) {
            d.catalogItem = $root.CatalogItem.toObject(m.catalogItem, o);
            if (o.oneofs)
                d.type = "catalogItem";
        }
        if (m.elementGroup != null && m.hasOwnProperty("elementGroup")) {
            d.elementGroup = $root.ElementGroup.toObject(m.elementGroup, o);
            if (o.oneofs)
                d.type = "elementGroup";
        }
        if (m.staticAnchor != null && m.hasOwnProperty("staticAnchor")) {
            d.staticAnchor = $root.StaticAnchor.toObject(m.staticAnchor, o);
            if (o.oneofs)
                d.type = "staticAnchor";
        }
        if (m.staticAnchorStatus != null && m.hasOwnProperty("staticAnchorStatus")) {
            d.staticAnchorStatus = $root.WallItem.StaticAnchorStatus.toObject(m.staticAnchorStatus, o);
            if (o.oneofs)
                d.type = "staticAnchorStatus";
        }
        if (m.light != null && m.hasOwnProperty("light")) {
            d.light = $root.Light.toObject(m.light, o);
            if (o.oneofs)
                d.type = "light";
        }
        if (m.laserTool != null && m.hasOwnProperty("laserTool")) {
            d.laserTool = $root.WallItem.LaserTool.toObject(m.laserTool, o);
            if (o.oneofs)
                d.type = "laserTool";
        }
        if (m.arrowTool != null && m.hasOwnProperty("arrowTool")) {
            d.arrowTool = $root.WallItem.ArrowTool.toObject(m.arrowTool, o);
            if (o.oneofs)
                d.type = "arrowTool";
        }
        if (m.measureTool != null && m.hasOwnProperty("measureTool")) {
            d.measureTool = $root.WallItem.MeasureTool.toObject(m.measureTool, o);
            if (o.oneofs)
                d.type = "measureTool";
        }
        if (m.model_3dTool != null && m.hasOwnProperty("model_3dTool")) {
            d.model_3dTool = $root.WallItem.Model3dTool.toObject(m.model_3dTool, o);
            if (o.oneofs)
                d.type = "model_3dTool";
        }
        if (m.drawTool != null && m.hasOwnProperty("drawTool")) {
            d.drawTool = $root.WallItem.DrawTool.toObject(m.drawTool, o);
            if (o.oneofs)
                d.type = "drawTool";
        }
        if (m.streemshotRequest != null && m.hasOwnProperty("streemshotRequest")) {
            d.streemshotRequest = $root.WallItem.StreemshotRequest.toObject(m.streemshotRequest, o);
            if (o.oneofs)
                d.type = "streemshotRequest";
        }
        if (m.streemshot != null && m.hasOwnProperty("streemshot")) {
            d.streemshot = $root.WallItem.Streemshot.toObject(m.streemshot, o);
            if (o.oneofs)
                d.type = "streemshot";
        }
        if (m.streemshotRevision != null && m.hasOwnProperty("streemshotRevision")) {
            d.streemshotRevision = $root.WallItem.StreemshotRevision.toObject(m.streemshotRevision, o);
            if (o.oneofs)
                d.type = "streemshotRevision";
        }
        if (m.streemshotDetections != null && m.hasOwnProperty("streemshotDetections")) {
            d.streemshotDetections = $root.WallItem.StreemshotDetections.toObject(m.streemshotDetections, o);
            if (o.oneofs)
                d.type = "streemshotDetections";
        }
        if (m.userDetectionFeedback != null && m.hasOwnProperty("userDetectionFeedback")) {
            d.userDetectionFeedback = $root.WallItem.UserDetectionFeedback.toObject(m.userDetectionFeedback, o);
            if (o.oneofs)
                d.type = "userDetectionFeedback";
        }
        if (m.note != null && m.hasOwnProperty("note")) {
            d.note = $root.streem.api.Note.toObject(m.note, o);
            if (o.oneofs)
                d.type = "note";
        }
        if (m.streemshotDepthMap != null && m.hasOwnProperty("streemshotDepthMap")) {
            d.streemshotDepthMap = $root.WallItem.StreemshotDepthMap.toObject(m.streemshotDepthMap, o);
            if (o.oneofs)
                d.type = "streemshotDepthMap";
        }
        if (m.arSource != null && m.hasOwnProperty("arSource")) {
            d.arSource = $root.WallItem.ARSource.toObject(m.arSource, o);
            if (o.oneofs)
                d.type = "arSource";
        }
        if (m.arSessionStatus != null && m.hasOwnProperty("arSessionStatus")) {
            d.arSessionStatus = $root.WallItem.ArSessionStatus.toObject(m.arSessionStatus, o);
            if (o.oneofs)
                d.type = "arSessionStatus";
        }
        if (m.arScanningRequest != null && m.hasOwnProperty("arScanningRequest")) {
            d.arScanningRequest = $root.WallItem.ArScanningRequest.toObject(m.arScanningRequest, o);
            if (o.oneofs)
                d.type = "arScanningRequest";
        }
        if (m.arScanningStatus != null && m.hasOwnProperty("arScanningStatus")) {
            d.arScanningStatus = $root.WallItem.ArScanningStatus.toObject(m.arScanningStatus, o);
            if (o.oneofs)
                d.type = "arScanningStatus";
        }
        if (m.arAnchorRequest != null && m.hasOwnProperty("arAnchorRequest")) {
            d.arAnchorRequest = $root.WallItem.ArAnchorRequest.toObject(m.arAnchorRequest, o);
            if (o.oneofs)
                d.type = "arAnchorRequest";
        }
        if (m.arAnchorStatus != null && m.hasOwnProperty("arAnchorStatus")) {
            d.arAnchorStatus = $root.WallItem.ArAnchorStatus.toObject(m.arAnchorStatus, o);
            if (o.oneofs)
                d.type = "arAnchorStatus";
        }
        if (m.arTrackableStatus != null && m.hasOwnProperty("arTrackableStatus")) {
            d.arTrackableStatus = $root.WallItem.ArTrackableStatus.toObject(m.arTrackableStatus, o);
            if (o.oneofs)
                d.type = "arTrackableStatus";
        }
        if (m.tutorial != null && m.hasOwnProperty("tutorial")) {
            d.tutorial = $root.Tutorial.toObject(m.tutorial, o);
            if (o.oneofs)
                d.type = "tutorial";
        }
        if (m.tutorialState != null && m.hasOwnProperty("tutorialState")) {
            d.tutorialState = $root.TutorialState.toObject(m.tutorialState, o);
            if (o.oneofs)
                d.type = "tutorialState";
        }
        if (m.tutorialRequest != null && m.hasOwnProperty("tutorialRequest")) {
            d.tutorialRequest = $root.TutorialRequest.toObject(m.tutorialRequest, o);
            if (o.oneofs)
                d.type = "tutorialRequest";
        }
        if (m.artifactRequest != null && m.hasOwnProperty("artifactRequest")) {
            d.artifactRequest = $root.WallItem.ArtifactRequest.toObject(m.artifactRequest, o);
            if (o.oneofs)
                d.type = "artifactRequest";
        }
        if (m.artifact != null && m.hasOwnProperty("artifact")) {
            d.artifact = $root.WallItem.Artifact.toObject(m.artifact, o);
            if (o.oneofs)
                d.type = "artifact";
        }
        if (m.artifactCompletionRequest != null && m.hasOwnProperty("artifactCompletionRequest")) {
            d.artifactCompletionRequest = $root.WallItem.ArtifactCompletionRequest.toObject(m.artifactCompletionRequest, o);
            if (o.oneofs)
                d.type = "artifactCompletionRequest";
        }
        return d;
    };

    /**
     * Converts this WallItem to JSON.
     * @function toJSON
     * @memberof WallItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WallItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WallItem
     * @function getTypeUrl
     * @memberof WallItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WallItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WallItem";
    };

    WallItem.User = (function() {

        /**
         * Properties of a User.
         * @memberof WallItem
         * @interface IUser
         * @property {string|null} [name] User name
         * @property {string|null} [avatarUrl] User avatarUrl
         * @property {string|null} [phoneNumber] User phoneNumber
         */

        /**
         * Constructs a new User.
         * @memberof WallItem
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {WallItem.IUser=} [p] Properties to set
         */
        function User(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * User name.
         * @member {string} name
         * @memberof WallItem.User
         * @instance
         */
        User.prototype.name = "";

        /**
         * User avatarUrl.
         * @member {string} avatarUrl
         * @memberof WallItem.User
         * @instance
         */
        User.prototype.avatarUrl = "";

        /**
         * User phoneNumber.
         * @member {string} phoneNumber
         * @memberof WallItem.User
         * @instance
         */
        User.prototype.phoneNumber = "";

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof WallItem.User
         * @static
         * @param {WallItem.IUser=} [properties] Properties to set
         * @returns {WallItem.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link WallItem.User.verify|verify} messages.
         * @function encode
         * @memberof WallItem.User
         * @static
         * @param {WallItem.IUser} m User message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.avatarUrl != null && Object.hasOwnProperty.call(m, "avatarUrl"))
                w.uint32(18).string(m.avatarUrl);
            if (m.phoneNumber != null && Object.hasOwnProperty.call(m, "phoneNumber"))
                w.uint32(26).string(m.phoneNumber);
            return w;
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.User();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.avatarUrl = r.string();
                        break;
                    }
                case 3: {
                        m.phoneNumber = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.User
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.User} User
         */
        User.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.User)
                return d;
            var m = new $root.WallItem.User();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.avatarUrl != null) {
                m.avatarUrl = String(d.avatarUrl);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            return m;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.User
         * @static
         * @param {WallItem.User} m User
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.avatarUrl = "";
                d.phoneNumber = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.avatarUrl != null && m.hasOwnProperty("avatarUrl")) {
                d.avatarUrl = m.avatarUrl;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            return d;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof WallItem.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for User
         * @function getTypeUrl
         * @memberof WallItem.User
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.User";
        };

        return User;
    })();

    WallItem.Heartbeat = (function() {

        /**
         * Properties of a Heartbeat.
         * @memberof WallItem
         * @interface IHeartbeat
         * @property {number|null} [count] Heartbeat count
         * @property {google.protobuf.ITimestamp|null} [timestamp] Heartbeat timestamp
         */

        /**
         * Constructs a new Heartbeat.
         * @memberof WallItem
         * @classdesc Represents a Heartbeat.
         * @implements IHeartbeat
         * @constructor
         * @param {WallItem.IHeartbeat=} [p] Properties to set
         */
        function Heartbeat(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Heartbeat count.
         * @member {number} count
         * @memberof WallItem.Heartbeat
         * @instance
         */
        Heartbeat.prototype.count = 0;

        /**
         * Heartbeat timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof WallItem.Heartbeat
         * @instance
         */
        Heartbeat.prototype.timestamp = null;

        /**
         * Creates a new Heartbeat instance using the specified properties.
         * @function create
         * @memberof WallItem.Heartbeat
         * @static
         * @param {WallItem.IHeartbeat=} [properties] Properties to set
         * @returns {WallItem.Heartbeat} Heartbeat instance
         */
        Heartbeat.create = function create(properties) {
            return new Heartbeat(properties);
        };

        /**
         * Encodes the specified Heartbeat message. Does not implicitly {@link WallItem.Heartbeat.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Heartbeat
         * @static
         * @param {WallItem.IHeartbeat} m Heartbeat message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Heartbeat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(8).uint32(m.count);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                $root.google.protobuf.Timestamp.encode(m.timestamp, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Heartbeat message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Heartbeat
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Heartbeat} Heartbeat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Heartbeat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Heartbeat();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.count = r.uint32();
                        break;
                    }
                case 2: {
                        m.timestamp = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Heartbeat
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Heartbeat} Heartbeat
         */
        Heartbeat.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Heartbeat)
                return d;
            var m = new $root.WallItem.Heartbeat();
            if (d.count != null) {
                m.count = d.count >>> 0;
            }
            if (d.timestamp != null) {
                if (typeof d.timestamp !== "object")
                    throw TypeError(".WallItem.Heartbeat.timestamp: object expected");
                m.timestamp = $root.google.protobuf.Timestamp.fromObject(d.timestamp);
            }
            return m;
        };

        /**
         * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Heartbeat
         * @static
         * @param {WallItem.Heartbeat} m Heartbeat
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Heartbeat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.count = 0;
                d.timestamp = null;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                d.timestamp = $root.google.protobuf.Timestamp.toObject(m.timestamp, o);
            }
            return d;
        };

        /**
         * Converts this Heartbeat to JSON.
         * @function toJSON
         * @memberof WallItem.Heartbeat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Heartbeat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Heartbeat
         * @function getTypeUrl
         * @memberof WallItem.Heartbeat
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Heartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Heartbeat";
        };

        return Heartbeat;
    })();

    WallItem.Capabilities = (function() {

        /**
         * Properties of a Capabilities.
         * @memberof WallItem
         * @interface ICapabilities
         * @property {Array.<streem.room.ar.TrackableType>|null} [canSendTrackableTypes] Capabilities canSendTrackableTypes
         * @property {boolean|null} [canReceiveNonUprightCameraImages] Capabilities canReceiveNonUprightCameraImages
         * @property {boolean|null} [sendsArScanningRequests] Capabilities sendsArScanningRequests
         * @property {boolean|null} [canReceiveCompressedDataTrackMessages] Capabilities canReceiveCompressedDataTrackMessages
         * @property {boolean|null} [supportsLightshowV2] Capabilities supportsLightshowV2
         * @property {boolean|null} [acceptsInsertableStreams] Capabilities acceptsInsertableStreams
         * @property {boolean|null} [canReceiveVideo] Capabilities canReceiveVideo
         * @property {Array.<CameraSubject>|null} [canSendCameraSubjects] Capabilities canSendCameraSubjects
         */

        /**
         * Constructs a new Capabilities.
         * @memberof WallItem
         * @classdesc Represents a Capabilities.
         * @implements ICapabilities
         * @constructor
         * @param {WallItem.ICapabilities=} [p] Properties to set
         */
        function Capabilities(p) {
            this.canSendTrackableTypes = [];
            this.canSendCameraSubjects = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Capabilities canSendTrackableTypes.
         * @member {Array.<streem.room.ar.TrackableType>} canSendTrackableTypes
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.canSendTrackableTypes = $util.emptyArray;

        /**
         * Capabilities canReceiveNonUprightCameraImages.
         * @member {boolean} canReceiveNonUprightCameraImages
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.canReceiveNonUprightCameraImages = false;

        /**
         * Capabilities sendsArScanningRequests.
         * @member {boolean} sendsArScanningRequests
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.sendsArScanningRequests = false;

        /**
         * Capabilities canReceiveCompressedDataTrackMessages.
         * @member {boolean} canReceiveCompressedDataTrackMessages
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.canReceiveCompressedDataTrackMessages = false;

        /**
         * Capabilities supportsLightshowV2.
         * @member {boolean} supportsLightshowV2
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.supportsLightshowV2 = false;

        /**
         * Capabilities acceptsInsertableStreams.
         * @member {boolean} acceptsInsertableStreams
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.acceptsInsertableStreams = false;

        /**
         * Capabilities canReceiveVideo.
         * @member {boolean} canReceiveVideo
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.canReceiveVideo = false;

        /**
         * Capabilities canSendCameraSubjects.
         * @member {Array.<CameraSubject>} canSendCameraSubjects
         * @memberof WallItem.Capabilities
         * @instance
         */
        Capabilities.prototype.canSendCameraSubjects = $util.emptyArray;

        /**
         * Creates a new Capabilities instance using the specified properties.
         * @function create
         * @memberof WallItem.Capabilities
         * @static
         * @param {WallItem.ICapabilities=} [properties] Properties to set
         * @returns {WallItem.Capabilities} Capabilities instance
         */
        Capabilities.create = function create(properties) {
            return new Capabilities(properties);
        };

        /**
         * Encodes the specified Capabilities message. Does not implicitly {@link WallItem.Capabilities.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Capabilities
         * @static
         * @param {WallItem.ICapabilities} m Capabilities message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Capabilities.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.canSendTrackableTypes != null && m.canSendTrackableTypes.length) {
                w.uint32(10).fork();
                for (var i = 0; i < m.canSendTrackableTypes.length; ++i)
                    w.int32(m.canSendTrackableTypes[i]);
                w.ldelim();
            }
            if (m.canReceiveNonUprightCameraImages != null && Object.hasOwnProperty.call(m, "canReceiveNonUprightCameraImages"))
                w.uint32(800).bool(m.canReceiveNonUprightCameraImages);
            if (m.sendsArScanningRequests != null && Object.hasOwnProperty.call(m, "sendsArScanningRequests"))
                w.uint32(808).bool(m.sendsArScanningRequests);
            if (m.canReceiveCompressedDataTrackMessages != null && Object.hasOwnProperty.call(m, "canReceiveCompressedDataTrackMessages"))
                w.uint32(816).bool(m.canReceiveCompressedDataTrackMessages);
            if (m.supportsLightshowV2 != null && Object.hasOwnProperty.call(m, "supportsLightshowV2"))
                w.uint32(824).bool(m.supportsLightshowV2);
            if (m.acceptsInsertableStreams != null && Object.hasOwnProperty.call(m, "acceptsInsertableStreams"))
                w.uint32(832).bool(m.acceptsInsertableStreams);
            if (m.canReceiveVideo != null && Object.hasOwnProperty.call(m, "canReceiveVideo"))
                w.uint32(840).bool(m.canReceiveVideo);
            if (m.canSendCameraSubjects != null && m.canSendCameraSubjects.length) {
                w.uint32(850).fork();
                for (var i = 0; i < m.canSendCameraSubjects.length; ++i)
                    w.int32(m.canSendCameraSubjects[i]);
                w.ldelim();
            }
            return w;
        };

        /**
         * Decodes a Capabilities message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Capabilities
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Capabilities} Capabilities
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Capabilities.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Capabilities();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.canSendTrackableTypes && m.canSendTrackableTypes.length))
                            m.canSendTrackableTypes = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.canSendTrackableTypes.push(r.int32());
                        } else
                            m.canSendTrackableTypes.push(r.int32());
                        break;
                    }
                case 100: {
                        m.canReceiveNonUprightCameraImages = r.bool();
                        break;
                    }
                case 101: {
                        m.sendsArScanningRequests = r.bool();
                        break;
                    }
                case 102: {
                        m.canReceiveCompressedDataTrackMessages = r.bool();
                        break;
                    }
                case 103: {
                        m.supportsLightshowV2 = r.bool();
                        break;
                    }
                case 104: {
                        m.acceptsInsertableStreams = r.bool();
                        break;
                    }
                case 105: {
                        m.canReceiveVideo = r.bool();
                        break;
                    }
                case 106: {
                        if (!(m.canSendCameraSubjects && m.canSendCameraSubjects.length))
                            m.canSendCameraSubjects = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.canSendCameraSubjects.push(r.int32());
                        } else
                            m.canSendCameraSubjects.push(r.int32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Capabilities message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Capabilities
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Capabilities} Capabilities
         */
        Capabilities.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Capabilities)
                return d;
            var m = new $root.WallItem.Capabilities();
            if (d.canSendTrackableTypes) {
                if (!Array.isArray(d.canSendTrackableTypes))
                    throw TypeError(".WallItem.Capabilities.canSendTrackableTypes: array expected");
                m.canSendTrackableTypes = [];
                for (var i = 0; i < d.canSendTrackableTypes.length; ++i) {
                    switch (d.canSendTrackableTypes[i]) {
                    default:
                        if (typeof d.canSendTrackableTypes[i] === "number") {
                            m.canSendTrackableTypes[i] = d.canSendTrackableTypes[i];
                            break;
                        }
                    case "TRACKABLE_TYPE_INVALID":
                    case 0:
                        m.canSendTrackableTypes[i] = 0;
                        break;
                    case "TRACKABLE_TYPE_MARKER_2D":
                    case 1:
                        m.canSendTrackableTypes[i] = 1;
                        break;
                    case "TRACKABLE_TYPE_MARKER_3D":
                    case 2:
                        m.canSendTrackableTypes[i] = 2;
                        break;
                    case "TRACKABLE_TYPE_PLANE":
                    case 3:
                        m.canSendTrackableTypes[i] = 3;
                        break;
                    case "TRACKABLE_TYPE_FEATURE_POINT":
                    case 4:
                        m.canSendTrackableTypes[i] = 4;
                        break;
                    case "TRACKABLE_TYPE_MESH":
                    case 5:
                        m.canSendTrackableTypes[i] = 5;
                        break;
                    case "TRACKABLE_TYPE_STATIC":
                    case 6:
                        m.canSendTrackableTypes[i] = 6;
                        break;
                    }
                }
            }
            if (d.canReceiveNonUprightCameraImages != null) {
                m.canReceiveNonUprightCameraImages = Boolean(d.canReceiveNonUprightCameraImages);
            }
            if (d.sendsArScanningRequests != null) {
                m.sendsArScanningRequests = Boolean(d.sendsArScanningRequests);
            }
            if (d.canReceiveCompressedDataTrackMessages != null) {
                m.canReceiveCompressedDataTrackMessages = Boolean(d.canReceiveCompressedDataTrackMessages);
            }
            if (d.supportsLightshowV2 != null) {
                m.supportsLightshowV2 = Boolean(d.supportsLightshowV2);
            }
            if (d.acceptsInsertableStreams != null) {
                m.acceptsInsertableStreams = Boolean(d.acceptsInsertableStreams);
            }
            if (d.canReceiveVideo != null) {
                m.canReceiveVideo = Boolean(d.canReceiveVideo);
            }
            if (d.canSendCameraSubjects) {
                if (!Array.isArray(d.canSendCameraSubjects))
                    throw TypeError(".WallItem.Capabilities.canSendCameraSubjects: array expected");
                m.canSendCameraSubjects = [];
                for (var i = 0; i < d.canSendCameraSubjects.length; ++i) {
                    switch (d.canSendCameraSubjects[i]) {
                    default:
                        if (typeof d.canSendCameraSubjects[i] === "number") {
                            m.canSendCameraSubjects[i] = d.canSendCameraSubjects[i];
                            break;
                        }
                    case "CAMERA_SUBJECT_WORLD":
                    case 0:
                        m.canSendCameraSubjects[i] = 0;
                        break;
                    case "CAMERA_SUBJECT_PERSON":
                    case 1:
                        m.canSendCameraSubjects[i] = 1;
                        break;
                    case "CAMERA_SUBJECT_SCREEN":
                    case 2:
                        m.canSendCameraSubjects[i] = 2;
                        break;
                    }
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a Capabilities message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Capabilities
         * @static
         * @param {WallItem.Capabilities} m Capabilities
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Capabilities.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.canSendTrackableTypes = [];
                d.canSendCameraSubjects = [];
            }
            if (o.defaults) {
                d.canReceiveNonUprightCameraImages = false;
                d.sendsArScanningRequests = false;
                d.canReceiveCompressedDataTrackMessages = false;
                d.supportsLightshowV2 = false;
                d.acceptsInsertableStreams = false;
                d.canReceiveVideo = false;
            }
            if (m.canSendTrackableTypes && m.canSendTrackableTypes.length) {
                d.canSendTrackableTypes = [];
                for (var j = 0; j < m.canSendTrackableTypes.length; ++j) {
                    d.canSendTrackableTypes[j] = o.enums === String ? $root.streem.room.ar.TrackableType[m.canSendTrackableTypes[j]] === undefined ? m.canSendTrackableTypes[j] : $root.streem.room.ar.TrackableType[m.canSendTrackableTypes[j]] : m.canSendTrackableTypes[j];
                }
            }
            if (m.canReceiveNonUprightCameraImages != null && m.hasOwnProperty("canReceiveNonUprightCameraImages")) {
                d.canReceiveNonUprightCameraImages = m.canReceiveNonUprightCameraImages;
            }
            if (m.sendsArScanningRequests != null && m.hasOwnProperty("sendsArScanningRequests")) {
                d.sendsArScanningRequests = m.sendsArScanningRequests;
            }
            if (m.canReceiveCompressedDataTrackMessages != null && m.hasOwnProperty("canReceiveCompressedDataTrackMessages")) {
                d.canReceiveCompressedDataTrackMessages = m.canReceiveCompressedDataTrackMessages;
            }
            if (m.supportsLightshowV2 != null && m.hasOwnProperty("supportsLightshowV2")) {
                d.supportsLightshowV2 = m.supportsLightshowV2;
            }
            if (m.acceptsInsertableStreams != null && m.hasOwnProperty("acceptsInsertableStreams")) {
                d.acceptsInsertableStreams = m.acceptsInsertableStreams;
            }
            if (m.canReceiveVideo != null && m.hasOwnProperty("canReceiveVideo")) {
                d.canReceiveVideo = m.canReceiveVideo;
            }
            if (m.canSendCameraSubjects && m.canSendCameraSubjects.length) {
                d.canSendCameraSubjects = [];
                for (var j = 0; j < m.canSendCameraSubjects.length; ++j) {
                    d.canSendCameraSubjects[j] = o.enums === String ? $root.CameraSubject[m.canSendCameraSubjects[j]] === undefined ? m.canSendCameraSubjects[j] : $root.CameraSubject[m.canSendCameraSubjects[j]] : m.canSendCameraSubjects[j];
                }
            }
            return d;
        };

        /**
         * Converts this Capabilities to JSON.
         * @function toJSON
         * @memberof WallItem.Capabilities
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Capabilities.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Capabilities
         * @function getTypeUrl
         * @memberof WallItem.Capabilities
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Capabilities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Capabilities";
        };

        return Capabilities;
    })();

    WallItem.UserFavorite = (function() {

        /**
         * Properties of a UserFavorite.
         * @memberof WallItem
         * @interface IUserFavorite
         * @property {string|null} [userSid] UserFavorite userSid
         */

        /**
         * Constructs a new UserFavorite.
         * @memberof WallItem
         * @classdesc Represents a UserFavorite.
         * @implements IUserFavorite
         * @constructor
         * @param {WallItem.IUserFavorite=} [p] Properties to set
         */
        function UserFavorite(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserFavorite userSid.
         * @member {string} userSid
         * @memberof WallItem.UserFavorite
         * @instance
         */
        UserFavorite.prototype.userSid = "";

        /**
         * Creates a new UserFavorite instance using the specified properties.
         * @function create
         * @memberof WallItem.UserFavorite
         * @static
         * @param {WallItem.IUserFavorite=} [properties] Properties to set
         * @returns {WallItem.UserFavorite} UserFavorite instance
         */
        UserFavorite.create = function create(properties) {
            return new UserFavorite(properties);
        };

        /**
         * Encodes the specified UserFavorite message. Does not implicitly {@link WallItem.UserFavorite.verify|verify} messages.
         * @function encode
         * @memberof WallItem.UserFavorite
         * @static
         * @param {WallItem.IUserFavorite} m UserFavorite message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserFavorite.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userSid != null && Object.hasOwnProperty.call(m, "userSid"))
                w.uint32(10).string(m.userSid);
            return w;
        };

        /**
         * Decodes a UserFavorite message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.UserFavorite
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.UserFavorite} UserFavorite
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserFavorite.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.UserFavorite();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userSid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserFavorite message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.UserFavorite
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.UserFavorite} UserFavorite
         */
        UserFavorite.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.UserFavorite)
                return d;
            var m = new $root.WallItem.UserFavorite();
            if (d.userSid != null) {
                m.userSid = String(d.userSid);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserFavorite message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.UserFavorite
         * @static
         * @param {WallItem.UserFavorite} m UserFavorite
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserFavorite.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userSid = "";
            }
            if (m.userSid != null && m.hasOwnProperty("userSid")) {
                d.userSid = m.userSid;
            }
            return d;
        };

        /**
         * Converts this UserFavorite to JSON.
         * @function toJSON
         * @memberof WallItem.UserFavorite
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserFavorite.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserFavorite
         * @function getTypeUrl
         * @memberof WallItem.UserFavorite
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserFavorite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.UserFavorite";
        };

        return UserFavorite;
    })();

    WallItem.DeviceState = (function() {

        /**
         * Properties of a DeviceState.
         * @memberof WallItem
         * @interface IDeviceState
         * @property {google.protobuf.ITimestamp|null} [updatedAt] DeviceState updatedAt
         * @property {boolean|null} [audioPermissionsGranted] DeviceState audioPermissionsGranted
         * @property {streem.api.ConnectionMethod|null} [connectionMethod] DeviceState connectionMethod
         * @property {number|null} [batteryLevel] DeviceState batteryLevel
         */

        /**
         * Constructs a new DeviceState.
         * @memberof WallItem
         * @classdesc Represents a DeviceState.
         * @implements IDeviceState
         * @constructor
         * @param {WallItem.IDeviceState=} [p] Properties to set
         */
        function DeviceState(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DeviceState updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof WallItem.DeviceState
         * @instance
         */
        DeviceState.prototype.updatedAt = null;

        /**
         * DeviceState audioPermissionsGranted.
         * @member {boolean} audioPermissionsGranted
         * @memberof WallItem.DeviceState
         * @instance
         */
        DeviceState.prototype.audioPermissionsGranted = false;

        /**
         * DeviceState connectionMethod.
         * @member {streem.api.ConnectionMethod} connectionMethod
         * @memberof WallItem.DeviceState
         * @instance
         */
        DeviceState.prototype.connectionMethod = 0;

        /**
         * DeviceState batteryLevel.
         * @member {number} batteryLevel
         * @memberof WallItem.DeviceState
         * @instance
         */
        DeviceState.prototype.batteryLevel = 0;

        /**
         * Creates a new DeviceState instance using the specified properties.
         * @function create
         * @memberof WallItem.DeviceState
         * @static
         * @param {WallItem.IDeviceState=} [properties] Properties to set
         * @returns {WallItem.DeviceState} DeviceState instance
         */
        DeviceState.create = function create(properties) {
            return new DeviceState(properties);
        };

        /**
         * Encodes the specified DeviceState message. Does not implicitly {@link WallItem.DeviceState.verify|verify} messages.
         * @function encode
         * @memberof WallItem.DeviceState
         * @static
         * @param {WallItem.IDeviceState} m DeviceState message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceState.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.updatedAt != null && Object.hasOwnProperty.call(m, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(m.updatedAt, w.uint32(10).fork()).ldelim();
            if (m.audioPermissionsGranted != null && Object.hasOwnProperty.call(m, "audioPermissionsGranted"))
                w.uint32(16).bool(m.audioPermissionsGranted);
            if (m.connectionMethod != null && Object.hasOwnProperty.call(m, "connectionMethod"))
                w.uint32(24).int32(m.connectionMethod);
            if (m.batteryLevel != null && Object.hasOwnProperty.call(m, "batteryLevel"))
                w.uint32(37).float(m.batteryLevel);
            return w;
        };

        /**
         * Decodes a DeviceState message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.DeviceState
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.DeviceState} DeviceState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceState.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.DeviceState();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.updatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.audioPermissionsGranted = r.bool();
                        break;
                    }
                case 3: {
                        m.connectionMethod = r.int32();
                        break;
                    }
                case 4: {
                        m.batteryLevel = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DeviceState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.DeviceState
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.DeviceState} DeviceState
         */
        DeviceState.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.DeviceState)
                return d;
            var m = new $root.WallItem.DeviceState();
            if (d.updatedAt != null) {
                if (typeof d.updatedAt !== "object")
                    throw TypeError(".WallItem.DeviceState.updatedAt: object expected");
                m.updatedAt = $root.google.protobuf.Timestamp.fromObject(d.updatedAt);
            }
            if (d.audioPermissionsGranted != null) {
                m.audioPermissionsGranted = Boolean(d.audioPermissionsGranted);
            }
            switch (d.connectionMethod) {
            default:
                if (typeof d.connectionMethod === "number") {
                    m.connectionMethod = d.connectionMethod;
                    break;
                }
                break;
            case "CONNECTION_METHOD_INVALID":
            case 0:
                m.connectionMethod = 0;
                break;
            case "CONNECTION_METHOD_WIFI":
            case 1:
                m.connectionMethod = 1;
                break;
            case "CONNECTION_METHOD_CELLULAR":
            case 2:
                m.connectionMethod = 2;
                break;
            case "CONNECTION_METHOD_UNKNOWN":
            case 3:
                m.connectionMethod = 3;
                break;
            }
            if (d.batteryLevel != null) {
                m.batteryLevel = Number(d.batteryLevel);
            }
            return m;
        };

        /**
         * Creates a plain object from a DeviceState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.DeviceState
         * @static
         * @param {WallItem.DeviceState} m DeviceState
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeviceState.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.updatedAt = null;
                d.audioPermissionsGranted = false;
                d.connectionMethod = o.enums === String ? "CONNECTION_METHOD_INVALID" : 0;
                d.batteryLevel = 0;
            }
            if (m.updatedAt != null && m.hasOwnProperty("updatedAt")) {
                d.updatedAt = $root.google.protobuf.Timestamp.toObject(m.updatedAt, o);
            }
            if (m.audioPermissionsGranted != null && m.hasOwnProperty("audioPermissionsGranted")) {
                d.audioPermissionsGranted = m.audioPermissionsGranted;
            }
            if (m.connectionMethod != null && m.hasOwnProperty("connectionMethod")) {
                d.connectionMethod = o.enums === String ? $root.streem.api.ConnectionMethod[m.connectionMethod] === undefined ? m.connectionMethod : $root.streem.api.ConnectionMethod[m.connectionMethod] : m.connectionMethod;
            }
            if (m.batteryLevel != null && m.hasOwnProperty("batteryLevel")) {
                d.batteryLevel = o.json && !isFinite(m.batteryLevel) ? String(m.batteryLevel) : m.batteryLevel;
            }
            return d;
        };

        /**
         * Converts this DeviceState to JSON.
         * @function toJSON
         * @memberof WallItem.DeviceState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeviceState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeviceState
         * @function getTypeUrl
         * @memberof WallItem.DeviceState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeviceState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.DeviceState";
        };

        return DeviceState;
    })();

    WallItem.AudioTrack = (function() {

        /**
         * Properties of an AudioTrack.
         * @memberof WallItem
         * @interface IAudioTrack
         * @property {boolean|null} [active] AudioTrack active
         * @property {boolean|null} [backgrounded] AudioTrack backgrounded
         */

        /**
         * Constructs a new AudioTrack.
         * @memberof WallItem
         * @classdesc Represents an AudioTrack.
         * @implements IAudioTrack
         * @constructor
         * @param {WallItem.IAudioTrack=} [p] Properties to set
         */
        function AudioTrack(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AudioTrack active.
         * @member {boolean} active
         * @memberof WallItem.AudioTrack
         * @instance
         */
        AudioTrack.prototype.active = false;

        /**
         * AudioTrack backgrounded.
         * @member {boolean} backgrounded
         * @memberof WallItem.AudioTrack
         * @instance
         */
        AudioTrack.prototype.backgrounded = false;

        /**
         * Creates a new AudioTrack instance using the specified properties.
         * @function create
         * @memberof WallItem.AudioTrack
         * @static
         * @param {WallItem.IAudioTrack=} [properties] Properties to set
         * @returns {WallItem.AudioTrack} AudioTrack instance
         */
        AudioTrack.create = function create(properties) {
            return new AudioTrack(properties);
        };

        /**
         * Encodes the specified AudioTrack message. Does not implicitly {@link WallItem.AudioTrack.verify|verify} messages.
         * @function encode
         * @memberof WallItem.AudioTrack
         * @static
         * @param {WallItem.IAudioTrack} m AudioTrack message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioTrack.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            if (m.backgrounded != null && Object.hasOwnProperty.call(m, "backgrounded"))
                w.uint32(16).bool(m.backgrounded);
            return w;
        };

        /**
         * Decodes an AudioTrack message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.AudioTrack
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.AudioTrack} AudioTrack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioTrack.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.AudioTrack();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                case 2: {
                        m.backgrounded = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AudioTrack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.AudioTrack
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.AudioTrack} AudioTrack
         */
        AudioTrack.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.AudioTrack)
                return d;
            var m = new $root.WallItem.AudioTrack();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            if (d.backgrounded != null) {
                m.backgrounded = Boolean(d.backgrounded);
            }
            return m;
        };

        /**
         * Creates a plain object from an AudioTrack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.AudioTrack
         * @static
         * @param {WallItem.AudioTrack} m AudioTrack
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioTrack.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
                d.backgrounded = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.backgrounded != null && m.hasOwnProperty("backgrounded")) {
                d.backgrounded = m.backgrounded;
            }
            return d;
        };

        /**
         * Converts this AudioTrack to JSON.
         * @function toJSON
         * @memberof WallItem.AudioTrack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioTrack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioTrack
         * @function getTypeUrl
         * @memberof WallItem.AudioTrack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.AudioTrack";
        };

        return AudioTrack;
    })();

    WallItem.VideoTrack = (function() {

        /**
         * Properties of a VideoTrack.
         * @memberof WallItem
         * @interface IVideoTrack
         * @property {boolean|null} [active] VideoTrack active
         * @property {WallItem.VideoTrack.Orientation|null} [orientation] VideoTrack orientation
         * @property {CameraSubject|null} [cameraSubject] VideoTrack cameraSubject
         * @property {string|null} [name] VideoTrack name
         * @property {boolean|null} [backgrounded] VideoTrack backgrounded
         */

        /**
         * Constructs a new VideoTrack.
         * @memberof WallItem
         * @classdesc Represents a VideoTrack.
         * @implements IVideoTrack
         * @constructor
         * @param {WallItem.IVideoTrack=} [p] Properties to set
         */
        function VideoTrack(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VideoTrack active.
         * @member {boolean} active
         * @memberof WallItem.VideoTrack
         * @instance
         */
        VideoTrack.prototype.active = false;

        /**
         * VideoTrack orientation.
         * @member {WallItem.VideoTrack.Orientation} orientation
         * @memberof WallItem.VideoTrack
         * @instance
         */
        VideoTrack.prototype.orientation = 0;

        /**
         * VideoTrack cameraSubject.
         * @member {CameraSubject} cameraSubject
         * @memberof WallItem.VideoTrack
         * @instance
         */
        VideoTrack.prototype.cameraSubject = 0;

        /**
         * VideoTrack name.
         * @member {string} name
         * @memberof WallItem.VideoTrack
         * @instance
         */
        VideoTrack.prototype.name = "";

        /**
         * VideoTrack backgrounded.
         * @member {boolean} backgrounded
         * @memberof WallItem.VideoTrack
         * @instance
         */
        VideoTrack.prototype.backgrounded = false;

        /**
         * Creates a new VideoTrack instance using the specified properties.
         * @function create
         * @memberof WallItem.VideoTrack
         * @static
         * @param {WallItem.IVideoTrack=} [properties] Properties to set
         * @returns {WallItem.VideoTrack} VideoTrack instance
         */
        VideoTrack.create = function create(properties) {
            return new VideoTrack(properties);
        };

        /**
         * Encodes the specified VideoTrack message. Does not implicitly {@link WallItem.VideoTrack.verify|verify} messages.
         * @function encode
         * @memberof WallItem.VideoTrack
         * @static
         * @param {WallItem.IVideoTrack} m VideoTrack message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideoTrack.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            if (m.orientation != null && Object.hasOwnProperty.call(m, "orientation"))
                w.uint32(16).int32(m.orientation);
            if (m.cameraSubject != null && Object.hasOwnProperty.call(m, "cameraSubject"))
                w.uint32(24).int32(m.cameraSubject);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.backgrounded != null && Object.hasOwnProperty.call(m, "backgrounded"))
                w.uint32(40).bool(m.backgrounded);
            return w;
        };

        /**
         * Decodes a VideoTrack message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.VideoTrack
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.VideoTrack} VideoTrack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideoTrack.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.VideoTrack();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                case 2: {
                        m.orientation = r.int32();
                        break;
                    }
                case 3: {
                        m.cameraSubject = r.int32();
                        break;
                    }
                case 4: {
                        m.name = r.string();
                        break;
                    }
                case 5: {
                        m.backgrounded = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a VideoTrack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.VideoTrack
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.VideoTrack} VideoTrack
         */
        VideoTrack.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.VideoTrack)
                return d;
            var m = new $root.WallItem.VideoTrack();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            switch (d.orientation) {
            default:
                if (typeof d.orientation === "number") {
                    m.orientation = d.orientation;
                    break;
                }
                break;
            case "UP":
            case 0:
                m.orientation = 0;
                break;
            case "LEFT":
            case 1:
                m.orientation = 1;
                break;
            case "DOWN":
            case 2:
                m.orientation = 2;
                break;
            case "RIGHT":
            case 3:
                m.orientation = 3;
                break;
            }
            switch (d.cameraSubject) {
            default:
                if (typeof d.cameraSubject === "number") {
                    m.cameraSubject = d.cameraSubject;
                    break;
                }
                break;
            case "CAMERA_SUBJECT_WORLD":
            case 0:
                m.cameraSubject = 0;
                break;
            case "CAMERA_SUBJECT_PERSON":
            case 1:
                m.cameraSubject = 1;
                break;
            case "CAMERA_SUBJECT_SCREEN":
            case 2:
                m.cameraSubject = 2;
                break;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.backgrounded != null) {
                m.backgrounded = Boolean(d.backgrounded);
            }
            return m;
        };

        /**
         * Creates a plain object from a VideoTrack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.VideoTrack
         * @static
         * @param {WallItem.VideoTrack} m VideoTrack
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideoTrack.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
                d.orientation = o.enums === String ? "UP" : 0;
                d.cameraSubject = o.enums === String ? "CAMERA_SUBJECT_WORLD" : 0;
                d.name = "";
                d.backgrounded = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.orientation != null && m.hasOwnProperty("orientation")) {
                d.orientation = o.enums === String ? $root.WallItem.VideoTrack.Orientation[m.orientation] === undefined ? m.orientation : $root.WallItem.VideoTrack.Orientation[m.orientation] : m.orientation;
            }
            if (m.cameraSubject != null && m.hasOwnProperty("cameraSubject")) {
                d.cameraSubject = o.enums === String ? $root.CameraSubject[m.cameraSubject] === undefined ? m.cameraSubject : $root.CameraSubject[m.cameraSubject] : m.cameraSubject;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.backgrounded != null && m.hasOwnProperty("backgrounded")) {
                d.backgrounded = m.backgrounded;
            }
            return d;
        };

        /**
         * Converts this VideoTrack to JSON.
         * @function toJSON
         * @memberof WallItem.VideoTrack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideoTrack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VideoTrack
         * @function getTypeUrl
         * @memberof WallItem.VideoTrack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VideoTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.VideoTrack";
        };

        /**
         * Orientation enum.
         * @name WallItem.VideoTrack.Orientation
         * @enum {number}
         * @property {number} UP=0 UP value
         * @property {number} LEFT=1 LEFT value
         * @property {number} DOWN=2 DOWN value
         * @property {number} RIGHT=3 RIGHT value
         */
        VideoTrack.Orientation = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UP"] = 0;
            values[valuesById[1] = "LEFT"] = 1;
            values[valuesById[2] = "DOWN"] = 2;
            values[valuesById[3] = "RIGHT"] = 3;
            return values;
        })();

        return VideoTrack;
    })();

    WallItem.CameraSubjectRequest = (function() {

        /**
         * Properties of a CameraSubjectRequest.
         * @memberof WallItem
         * @interface ICameraSubjectRequest
         * @property {string|null} [requestedForUserId] CameraSubjectRequest requestedForUserId
         * @property {string|null} [requestedForDeviceId] CameraSubjectRequest requestedForDeviceId
         * @property {CameraSubject|null} [requestedCameraSubject] CameraSubjectRequest requestedCameraSubject
         */

        /**
         * Constructs a new CameraSubjectRequest.
         * @memberof WallItem
         * @classdesc Represents a CameraSubjectRequest.
         * @implements ICameraSubjectRequest
         * @constructor
         * @param {WallItem.ICameraSubjectRequest=} [p] Properties to set
         */
        function CameraSubjectRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CameraSubjectRequest requestedForUserId.
         * @member {string} requestedForUserId
         * @memberof WallItem.CameraSubjectRequest
         * @instance
         */
        CameraSubjectRequest.prototype.requestedForUserId = "";

        /**
         * CameraSubjectRequest requestedForDeviceId.
         * @member {string} requestedForDeviceId
         * @memberof WallItem.CameraSubjectRequest
         * @instance
         */
        CameraSubjectRequest.prototype.requestedForDeviceId = "";

        /**
         * CameraSubjectRequest requestedCameraSubject.
         * @member {CameraSubject} requestedCameraSubject
         * @memberof WallItem.CameraSubjectRequest
         * @instance
         */
        CameraSubjectRequest.prototype.requestedCameraSubject = 0;

        /**
         * Creates a new CameraSubjectRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {WallItem.ICameraSubjectRequest=} [properties] Properties to set
         * @returns {WallItem.CameraSubjectRequest} CameraSubjectRequest instance
         */
        CameraSubjectRequest.create = function create(properties) {
            return new CameraSubjectRequest(properties);
        };

        /**
         * Encodes the specified CameraSubjectRequest message. Does not implicitly {@link WallItem.CameraSubjectRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {WallItem.ICameraSubjectRequest} m CameraSubjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CameraSubjectRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.requestedForUserId != null && Object.hasOwnProperty.call(m, "requestedForUserId"))
                w.uint32(10).string(m.requestedForUserId);
            if (m.requestedForDeviceId != null && Object.hasOwnProperty.call(m, "requestedForDeviceId"))
                w.uint32(18).string(m.requestedForDeviceId);
            if (m.requestedCameraSubject != null && Object.hasOwnProperty.call(m, "requestedCameraSubject"))
                w.uint32(24).int32(m.requestedCameraSubject);
            return w;
        };

        /**
         * Decodes a CameraSubjectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.CameraSubjectRequest} CameraSubjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CameraSubjectRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.CameraSubjectRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.requestedForUserId = r.string();
                        break;
                    }
                case 2: {
                        m.requestedForDeviceId = r.string();
                        break;
                    }
                case 3: {
                        m.requestedCameraSubject = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CameraSubjectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.CameraSubjectRequest} CameraSubjectRequest
         */
        CameraSubjectRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.CameraSubjectRequest)
                return d;
            var m = new $root.WallItem.CameraSubjectRequest();
            if (d.requestedForUserId != null) {
                m.requestedForUserId = String(d.requestedForUserId);
            }
            if (d.requestedForDeviceId != null) {
                m.requestedForDeviceId = String(d.requestedForDeviceId);
            }
            switch (d.requestedCameraSubject) {
            default:
                if (typeof d.requestedCameraSubject === "number") {
                    m.requestedCameraSubject = d.requestedCameraSubject;
                    break;
                }
                break;
            case "CAMERA_SUBJECT_WORLD":
            case 0:
                m.requestedCameraSubject = 0;
                break;
            case "CAMERA_SUBJECT_PERSON":
            case 1:
                m.requestedCameraSubject = 1;
                break;
            case "CAMERA_SUBJECT_SCREEN":
            case 2:
                m.requestedCameraSubject = 2;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a CameraSubjectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {WallItem.CameraSubjectRequest} m CameraSubjectRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CameraSubjectRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.requestedForUserId = "";
                d.requestedForDeviceId = "";
                d.requestedCameraSubject = o.enums === String ? "CAMERA_SUBJECT_WORLD" : 0;
            }
            if (m.requestedForUserId != null && m.hasOwnProperty("requestedForUserId")) {
                d.requestedForUserId = m.requestedForUserId;
            }
            if (m.requestedForDeviceId != null && m.hasOwnProperty("requestedForDeviceId")) {
                d.requestedForDeviceId = m.requestedForDeviceId;
            }
            if (m.requestedCameraSubject != null && m.hasOwnProperty("requestedCameraSubject")) {
                d.requestedCameraSubject = o.enums === String ? $root.CameraSubject[m.requestedCameraSubject] === undefined ? m.requestedCameraSubject : $root.CameraSubject[m.requestedCameraSubject] : m.requestedCameraSubject;
            }
            return d;
        };

        /**
         * Converts this CameraSubjectRequest to JSON.
         * @function toJSON
         * @memberof WallItem.CameraSubjectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CameraSubjectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CameraSubjectRequest
         * @function getTypeUrl
         * @memberof WallItem.CameraSubjectRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CameraSubjectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.CameraSubjectRequest";
        };

        return CameraSubjectRequest;
    })();

    WallItem.CameraSubjectResponse = (function() {

        /**
         * Properties of a CameraSubjectResponse.
         * @memberof WallItem
         * @interface ICameraSubjectResponse
         * @property {string|null} [inResponseToWallItemId] CameraSubjectResponse inResponseToWallItemId
         * @property {boolean|null} [accepted] CameraSubjectResponse accepted
         * @property {CameraSubjectResponseStatus|null} [status] CameraSubjectResponse status
         */

        /**
         * Constructs a new CameraSubjectResponse.
         * @memberof WallItem
         * @classdesc Represents a CameraSubjectResponse.
         * @implements ICameraSubjectResponse
         * @constructor
         * @param {WallItem.ICameraSubjectResponse=} [p] Properties to set
         */
        function CameraSubjectResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CameraSubjectResponse inResponseToWallItemId.
         * @member {string} inResponseToWallItemId
         * @memberof WallItem.CameraSubjectResponse
         * @instance
         */
        CameraSubjectResponse.prototype.inResponseToWallItemId = "";

        /**
         * CameraSubjectResponse accepted.
         * @member {boolean} accepted
         * @memberof WallItem.CameraSubjectResponse
         * @instance
         */
        CameraSubjectResponse.prototype.accepted = false;

        /**
         * CameraSubjectResponse status.
         * @member {CameraSubjectResponseStatus} status
         * @memberof WallItem.CameraSubjectResponse
         * @instance
         */
        CameraSubjectResponse.prototype.status = 0;

        /**
         * Creates a new CameraSubjectResponse instance using the specified properties.
         * @function create
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {WallItem.ICameraSubjectResponse=} [properties] Properties to set
         * @returns {WallItem.CameraSubjectResponse} CameraSubjectResponse instance
         */
        CameraSubjectResponse.create = function create(properties) {
            return new CameraSubjectResponse(properties);
        };

        /**
         * Encodes the specified CameraSubjectResponse message. Does not implicitly {@link WallItem.CameraSubjectResponse.verify|verify} messages.
         * @function encode
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {WallItem.ICameraSubjectResponse} m CameraSubjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CameraSubjectResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.inResponseToWallItemId != null && Object.hasOwnProperty.call(m, "inResponseToWallItemId"))
                w.uint32(10).string(m.inResponseToWallItemId);
            if (m.accepted != null && Object.hasOwnProperty.call(m, "accepted"))
                w.uint32(16).bool(m.accepted);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(24).int32(m.status);
            return w;
        };

        /**
         * Decodes a CameraSubjectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.CameraSubjectResponse} CameraSubjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CameraSubjectResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.CameraSubjectResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.inResponseToWallItemId = r.string();
                        break;
                    }
                case 2: {
                        m.accepted = r.bool();
                        break;
                    }
                case 3: {
                        m.status = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CameraSubjectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.CameraSubjectResponse} CameraSubjectResponse
         */
        CameraSubjectResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.CameraSubjectResponse)
                return d;
            var m = new $root.WallItem.CameraSubjectResponse();
            if (d.inResponseToWallItemId != null) {
                m.inResponseToWallItemId = String(d.inResponseToWallItemId);
            }
            if (d.accepted != null) {
                m.accepted = Boolean(d.accepted);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "CAMERA_SUBJECT_RESPONSE_STATUS_ACCEPTED":
            case 0:
                m.status = 0;
                break;
            case "CAMERA_SUBJECT_RESPONSE_STATUS_ABORTED":
            case 1:
                m.status = 1;
                break;
            case "CAMERA_SUBJECT_RESPONSE_STATUS_REJECTED":
            case 2:
                m.status = 2;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a CameraSubjectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {WallItem.CameraSubjectResponse} m CameraSubjectResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CameraSubjectResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.inResponseToWallItemId = "";
                d.accepted = false;
                d.status = o.enums === String ? "CAMERA_SUBJECT_RESPONSE_STATUS_ACCEPTED" : 0;
            }
            if (m.inResponseToWallItemId != null && m.hasOwnProperty("inResponseToWallItemId")) {
                d.inResponseToWallItemId = m.inResponseToWallItemId;
            }
            if (m.accepted != null && m.hasOwnProperty("accepted")) {
                d.accepted = m.accepted;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.CameraSubjectResponseStatus[m.status] === undefined ? m.status : $root.CameraSubjectResponseStatus[m.status] : m.status;
            }
            return d;
        };

        /**
         * Converts this CameraSubjectResponse to JSON.
         * @function toJSON
         * @memberof WallItem.CameraSubjectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CameraSubjectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CameraSubjectResponse
         * @function getTypeUrl
         * @memberof WallItem.CameraSubjectResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CameraSubjectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.CameraSubjectResponse";
        };

        return CameraSubjectResponse;
    })();

    WallItem.DataTrack = (function() {

        /**
         * Properties of a DataTrack.
         * @memberof WallItem
         * @interface IDataTrack
         */

        /**
         * Constructs a new DataTrack.
         * @memberof WallItem
         * @classdesc Represents a DataTrack.
         * @implements IDataTrack
         * @constructor
         * @param {WallItem.IDataTrack=} [p] Properties to set
         */
        function DataTrack(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new DataTrack instance using the specified properties.
         * @function create
         * @memberof WallItem.DataTrack
         * @static
         * @param {WallItem.IDataTrack=} [properties] Properties to set
         * @returns {WallItem.DataTrack} DataTrack instance
         */
        DataTrack.create = function create(properties) {
            return new DataTrack(properties);
        };

        /**
         * Encodes the specified DataTrack message. Does not implicitly {@link WallItem.DataTrack.verify|verify} messages.
         * @function encode
         * @memberof WallItem.DataTrack
         * @static
         * @param {WallItem.IDataTrack} m DataTrack message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataTrack.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes a DataTrack message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.DataTrack
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.DataTrack} DataTrack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataTrack.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.DataTrack();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DataTrack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.DataTrack
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.DataTrack} DataTrack
         */
        DataTrack.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.DataTrack)
                return d;
            return new $root.WallItem.DataTrack();
        };

        /**
         * Creates a plain object from a DataTrack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.DataTrack
         * @static
         * @param {WallItem.DataTrack} m DataTrack
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataTrack.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DataTrack to JSON.
         * @function toJSON
         * @memberof WallItem.DataTrack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataTrack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataTrack
         * @function getTypeUrl
         * @memberof WallItem.DataTrack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.DataTrack";
        };

        return DataTrack;
    })();

    WallItem.LaserTool = (function() {

        /**
         * Properties of a LaserTool.
         * @memberof WallItem
         * @interface ILaserTool
         * @property {boolean|null} [active] LaserTool active
         */

        /**
         * Constructs a new LaserTool.
         * @memberof WallItem
         * @classdesc Represents a LaserTool.
         * @implements ILaserTool
         * @constructor
         * @param {WallItem.ILaserTool=} [p] Properties to set
         */
        function LaserTool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LaserTool active.
         * @member {boolean} active
         * @memberof WallItem.LaserTool
         * @instance
         */
        LaserTool.prototype.active = false;

        /**
         * Creates a new LaserTool instance using the specified properties.
         * @function create
         * @memberof WallItem.LaserTool
         * @static
         * @param {WallItem.ILaserTool=} [properties] Properties to set
         * @returns {WallItem.LaserTool} LaserTool instance
         */
        LaserTool.create = function create(properties) {
            return new LaserTool(properties);
        };

        /**
         * Encodes the specified LaserTool message. Does not implicitly {@link WallItem.LaserTool.verify|verify} messages.
         * @function encode
         * @memberof WallItem.LaserTool
         * @static
         * @param {WallItem.ILaserTool} m LaserTool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LaserTool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            return w;
        };

        /**
         * Decodes a LaserTool message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.LaserTool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.LaserTool} LaserTool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LaserTool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.LaserTool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LaserTool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.LaserTool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.LaserTool} LaserTool
         */
        LaserTool.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.LaserTool)
                return d;
            var m = new $root.WallItem.LaserTool();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            return m;
        };

        /**
         * Creates a plain object from a LaserTool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.LaserTool
         * @static
         * @param {WallItem.LaserTool} m LaserTool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LaserTool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            return d;
        };

        /**
         * Converts this LaserTool to JSON.
         * @function toJSON
         * @memberof WallItem.LaserTool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LaserTool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LaserTool
         * @function getTypeUrl
         * @memberof WallItem.LaserTool
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LaserTool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.LaserTool";
        };

        return LaserTool;
    })();

    WallItem.ArrowTool = (function() {

        /**
         * Properties of an ArrowTool.
         * @memberof WallItem
         * @interface IArrowTool
         * @property {boolean|null} [active] ArrowTool active
         */

        /**
         * Constructs a new ArrowTool.
         * @memberof WallItem
         * @classdesc Represents an ArrowTool.
         * @implements IArrowTool
         * @constructor
         * @param {WallItem.IArrowTool=} [p] Properties to set
         */
        function ArrowTool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArrowTool active.
         * @member {boolean} active
         * @memberof WallItem.ArrowTool
         * @instance
         */
        ArrowTool.prototype.active = false;

        /**
         * Creates a new ArrowTool instance using the specified properties.
         * @function create
         * @memberof WallItem.ArrowTool
         * @static
         * @param {WallItem.IArrowTool=} [properties] Properties to set
         * @returns {WallItem.ArrowTool} ArrowTool instance
         */
        ArrowTool.create = function create(properties) {
            return new ArrowTool(properties);
        };

        /**
         * Encodes the specified ArrowTool message. Does not implicitly {@link WallItem.ArrowTool.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArrowTool
         * @static
         * @param {WallItem.IArrowTool} m ArrowTool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArrowTool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            return w;
        };

        /**
         * Decodes an ArrowTool message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArrowTool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArrowTool} ArrowTool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArrowTool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArrowTool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArrowTool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArrowTool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArrowTool} ArrowTool
         */
        ArrowTool.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArrowTool)
                return d;
            var m = new $root.WallItem.ArrowTool();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            return m;
        };

        /**
         * Creates a plain object from an ArrowTool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArrowTool
         * @static
         * @param {WallItem.ArrowTool} m ArrowTool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArrowTool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            return d;
        };

        /**
         * Converts this ArrowTool to JSON.
         * @function toJSON
         * @memberof WallItem.ArrowTool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArrowTool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArrowTool
         * @function getTypeUrl
         * @memberof WallItem.ArrowTool
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArrowTool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArrowTool";
        };

        return ArrowTool;
    })();

    WallItem.MeasureTool = (function() {

        /**
         * Properties of a MeasureTool.
         * @memberof WallItem
         * @interface IMeasureTool
         * @property {boolean|null} [active] MeasureTool active
         */

        /**
         * Constructs a new MeasureTool.
         * @memberof WallItem
         * @classdesc Represents a MeasureTool.
         * @implements IMeasureTool
         * @constructor
         * @param {WallItem.IMeasureTool=} [p] Properties to set
         */
        function MeasureTool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MeasureTool active.
         * @member {boolean} active
         * @memberof WallItem.MeasureTool
         * @instance
         */
        MeasureTool.prototype.active = false;

        /**
         * Creates a new MeasureTool instance using the specified properties.
         * @function create
         * @memberof WallItem.MeasureTool
         * @static
         * @param {WallItem.IMeasureTool=} [properties] Properties to set
         * @returns {WallItem.MeasureTool} MeasureTool instance
         */
        MeasureTool.create = function create(properties) {
            return new MeasureTool(properties);
        };

        /**
         * Encodes the specified MeasureTool message. Does not implicitly {@link WallItem.MeasureTool.verify|verify} messages.
         * @function encode
         * @memberof WallItem.MeasureTool
         * @static
         * @param {WallItem.IMeasureTool} m MeasureTool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeasureTool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            return w;
        };

        /**
         * Decodes a MeasureTool message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.MeasureTool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.MeasureTool} MeasureTool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeasureTool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.MeasureTool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MeasureTool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.MeasureTool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.MeasureTool} MeasureTool
         */
        MeasureTool.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.MeasureTool)
                return d;
            var m = new $root.WallItem.MeasureTool();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            return m;
        };

        /**
         * Creates a plain object from a MeasureTool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.MeasureTool
         * @static
         * @param {WallItem.MeasureTool} m MeasureTool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeasureTool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            return d;
        };

        /**
         * Converts this MeasureTool to JSON.
         * @function toJSON
         * @memberof WallItem.MeasureTool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeasureTool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeasureTool
         * @function getTypeUrl
         * @memberof WallItem.MeasureTool
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeasureTool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.MeasureTool";
        };

        return MeasureTool;
    })();

    WallItem.Model3dTool = (function() {

        /**
         * Properties of a Model3dTool.
         * @memberof WallItem
         * @interface IModel3dTool
         * @property {boolean|null} [active] Model3dTool active
         */

        /**
         * Constructs a new Model3dTool.
         * @memberof WallItem
         * @classdesc Represents a Model3dTool.
         * @implements IModel3dTool
         * @constructor
         * @param {WallItem.IModel3dTool=} [p] Properties to set
         */
        function Model3dTool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Model3dTool active.
         * @member {boolean} active
         * @memberof WallItem.Model3dTool
         * @instance
         */
        Model3dTool.prototype.active = false;

        /**
         * Creates a new Model3dTool instance using the specified properties.
         * @function create
         * @memberof WallItem.Model3dTool
         * @static
         * @param {WallItem.IModel3dTool=} [properties] Properties to set
         * @returns {WallItem.Model3dTool} Model3dTool instance
         */
        Model3dTool.create = function create(properties) {
            return new Model3dTool(properties);
        };

        /**
         * Encodes the specified Model3dTool message. Does not implicitly {@link WallItem.Model3dTool.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Model3dTool
         * @static
         * @param {WallItem.IModel3dTool} m Model3dTool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Model3dTool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            return w;
        };

        /**
         * Decodes a Model3dTool message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Model3dTool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Model3dTool} Model3dTool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Model3dTool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Model3dTool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Model3dTool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Model3dTool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Model3dTool} Model3dTool
         */
        Model3dTool.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Model3dTool)
                return d;
            var m = new $root.WallItem.Model3dTool();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            return m;
        };

        /**
         * Creates a plain object from a Model3dTool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Model3dTool
         * @static
         * @param {WallItem.Model3dTool} m Model3dTool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Model3dTool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            return d;
        };

        /**
         * Converts this Model3dTool to JSON.
         * @function toJSON
         * @memberof WallItem.Model3dTool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Model3dTool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Model3dTool
         * @function getTypeUrl
         * @memberof WallItem.Model3dTool
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Model3dTool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Model3dTool";
        };

        return Model3dTool;
    })();

    WallItem.DrawTool = (function() {

        /**
         * Properties of a DrawTool.
         * @memberof WallItem
         * @interface IDrawTool
         * @property {boolean|null} [active] DrawTool active
         */

        /**
         * Constructs a new DrawTool.
         * @memberof WallItem
         * @classdesc Represents a DrawTool.
         * @implements IDrawTool
         * @constructor
         * @param {WallItem.IDrawTool=} [p] Properties to set
         */
        function DrawTool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DrawTool active.
         * @member {boolean} active
         * @memberof WallItem.DrawTool
         * @instance
         */
        DrawTool.prototype.active = false;

        /**
         * Creates a new DrawTool instance using the specified properties.
         * @function create
         * @memberof WallItem.DrawTool
         * @static
         * @param {WallItem.IDrawTool=} [properties] Properties to set
         * @returns {WallItem.DrawTool} DrawTool instance
         */
        DrawTool.create = function create(properties) {
            return new DrawTool(properties);
        };

        /**
         * Encodes the specified DrawTool message. Does not implicitly {@link WallItem.DrawTool.verify|verify} messages.
         * @function encode
         * @memberof WallItem.DrawTool
         * @static
         * @param {WallItem.IDrawTool} m DrawTool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrawTool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(8).bool(m.active);
            return w;
        };

        /**
         * Decodes a DrawTool message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.DrawTool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.DrawTool} DrawTool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrawTool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.DrawTool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.active = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DrawTool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.DrawTool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.DrawTool} DrawTool
         */
        DrawTool.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.DrawTool)
                return d;
            var m = new $root.WallItem.DrawTool();
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            return m;
        };

        /**
         * Creates a plain object from a DrawTool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.DrawTool
         * @static
         * @param {WallItem.DrawTool} m DrawTool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrawTool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.active = false;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            return d;
        };

        /**
         * Converts this DrawTool to JSON.
         * @function toJSON
         * @memberof WallItem.DrawTool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrawTool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DrawTool
         * @function getTypeUrl
         * @memberof WallItem.DrawTool
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DrawTool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.DrawTool";
        };

        return DrawTool;
    })();

    WallItem.StreemshotRequest = (function() {

        /**
         * Properties of a StreemshotRequest.
         * @memberof WallItem
         * @interface IStreemshotRequest
         * @property {string|null} [streemshotId] StreemshotRequest streemshotId
         * @property {string|null} [requestedOfUserId] StreemshotRequest requestedOfUserId
         * @property {string|null} [requestedOfDeviceId] StreemshotRequest requestedOfDeviceId
         */

        /**
         * Constructs a new StreemshotRequest.
         * @memberof WallItem
         * @classdesc Represents a StreemshotRequest.
         * @implements IStreemshotRequest
         * @constructor
         * @param {WallItem.IStreemshotRequest=} [p] Properties to set
         */
        function StreemshotRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StreemshotRequest streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.StreemshotRequest
         * @instance
         */
        StreemshotRequest.prototype.streemshotId = "";

        /**
         * StreemshotRequest requestedOfUserId.
         * @member {string} requestedOfUserId
         * @memberof WallItem.StreemshotRequest
         * @instance
         */
        StreemshotRequest.prototype.requestedOfUserId = "";

        /**
         * StreemshotRequest requestedOfDeviceId.
         * @member {string} requestedOfDeviceId
         * @memberof WallItem.StreemshotRequest
         * @instance
         */
        StreemshotRequest.prototype.requestedOfDeviceId = "";

        /**
         * Creates a new StreemshotRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {WallItem.IStreemshotRequest=} [properties] Properties to set
         * @returns {WallItem.StreemshotRequest} StreemshotRequest instance
         */
        StreemshotRequest.create = function create(properties) {
            return new StreemshotRequest(properties);
        };

        /**
         * Encodes the specified StreemshotRequest message. Does not implicitly {@link WallItem.StreemshotRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {WallItem.IStreemshotRequest} m StreemshotRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreemshotRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(10).string(m.streemshotId);
            if (m.requestedOfUserId != null && Object.hasOwnProperty.call(m, "requestedOfUserId"))
                w.uint32(18).string(m.requestedOfUserId);
            if (m.requestedOfDeviceId != null && Object.hasOwnProperty.call(m, "requestedOfDeviceId"))
                w.uint32(26).string(m.requestedOfDeviceId);
            return w;
        };

        /**
         * Decodes a StreemshotRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.StreemshotRequest} StreemshotRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreemshotRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StreemshotRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.streemshotId = r.string();
                        break;
                    }
                case 2: {
                        m.requestedOfUserId = r.string();
                        break;
                    }
                case 3: {
                        m.requestedOfDeviceId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StreemshotRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.StreemshotRequest} StreemshotRequest
         */
        StreemshotRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.StreemshotRequest)
                return d;
            var m = new $root.WallItem.StreemshotRequest();
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            if (d.requestedOfUserId != null) {
                m.requestedOfUserId = String(d.requestedOfUserId);
            }
            if (d.requestedOfDeviceId != null) {
                m.requestedOfDeviceId = String(d.requestedOfDeviceId);
            }
            return m;
        };

        /**
         * Creates a plain object from a StreemshotRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {WallItem.StreemshotRequest} m StreemshotRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StreemshotRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.streemshotId = "";
                d.requestedOfUserId = "";
                d.requestedOfDeviceId = "";
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            if (m.requestedOfUserId != null && m.hasOwnProperty("requestedOfUserId")) {
                d.requestedOfUserId = m.requestedOfUserId;
            }
            if (m.requestedOfDeviceId != null && m.hasOwnProperty("requestedOfDeviceId")) {
                d.requestedOfDeviceId = m.requestedOfDeviceId;
            }
            return d;
        };

        /**
         * Converts this StreemshotRequest to JSON.
         * @function toJSON
         * @memberof WallItem.StreemshotRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StreemshotRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StreemshotRequest
         * @function getTypeUrl
         * @memberof WallItem.StreemshotRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StreemshotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.StreemshotRequest";
        };

        return StreemshotRequest;
    })();

    WallItem.Streemshot = (function() {

        /**
         * Properties of a Streemshot.
         * @memberof WallItem
         * @interface IStreemshot
         * @property {string|null} [inResponseToWallItemId] Streemshot inResponseToWallItemId
         * @property {string|null} [downloadUrl] Streemshot downloadUrl
         * @property {streem.api.IPose|null} [pose] Streemshot pose
         * @property {Array.<streem.api.IPoint>|null} [featurePoints] Streemshot featurePoints
         * @property {string|null} [storageUrl] Streemshot storageUrl
         * @property {ICameraIntrinsics|null} [cameraIntrinsics] Streemshot cameraIntrinsics
         * @property {streem.api.ISize|null} [imageDimensions] Streemshot imageDimensions
         */

        /**
         * Constructs a new Streemshot.
         * @memberof WallItem
         * @classdesc Represents a Streemshot.
         * @implements IStreemshot
         * @constructor
         * @param {WallItem.IStreemshot=} [p] Properties to set
         */
        function Streemshot(p) {
            this.featurePoints = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Streemshot inResponseToWallItemId.
         * @member {string} inResponseToWallItemId
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.inResponseToWallItemId = "";

        /**
         * Streemshot downloadUrl.
         * @member {string} downloadUrl
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.downloadUrl = "";

        /**
         * Streemshot pose.
         * @member {streem.api.IPose|null|undefined} pose
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.pose = null;

        /**
         * Streemshot featurePoints.
         * @member {Array.<streem.api.IPoint>} featurePoints
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.featurePoints = $util.emptyArray;

        /**
         * Streemshot storageUrl.
         * @member {string} storageUrl
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.storageUrl = "";

        /**
         * Streemshot cameraIntrinsics.
         * @member {ICameraIntrinsics|null|undefined} cameraIntrinsics
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.cameraIntrinsics = null;

        /**
         * Streemshot imageDimensions.
         * @member {streem.api.ISize|null|undefined} imageDimensions
         * @memberof WallItem.Streemshot
         * @instance
         */
        Streemshot.prototype.imageDimensions = null;

        /**
         * Creates a new Streemshot instance using the specified properties.
         * @function create
         * @memberof WallItem.Streemshot
         * @static
         * @param {WallItem.IStreemshot=} [properties] Properties to set
         * @returns {WallItem.Streemshot} Streemshot instance
         */
        Streemshot.create = function create(properties) {
            return new Streemshot(properties);
        };

        /**
         * Encodes the specified Streemshot message. Does not implicitly {@link WallItem.Streemshot.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Streemshot
         * @static
         * @param {WallItem.IStreemshot} m Streemshot message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Streemshot.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.inResponseToWallItemId != null && Object.hasOwnProperty.call(m, "inResponseToWallItemId"))
                w.uint32(10).string(m.inResponseToWallItemId);
            if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                w.uint32(18).string(m.downloadUrl);
            if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                $root.streem.api.Pose.encode(m.pose, w.uint32(26).fork()).ldelim();
            if (m.featurePoints != null && m.featurePoints.length) {
                for (var i = 0; i < m.featurePoints.length; ++i)
                    $root.streem.api.Point.encode(m.featurePoints[i], w.uint32(34).fork()).ldelim();
            }
            if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                w.uint32(42).string(m.storageUrl);
            if (m.cameraIntrinsics != null && Object.hasOwnProperty.call(m, "cameraIntrinsics"))
                $root.CameraIntrinsics.encode(m.cameraIntrinsics, w.uint32(50).fork()).ldelim();
            if (m.imageDimensions != null && Object.hasOwnProperty.call(m, "imageDimensions"))
                $root.streem.api.Size.encode(m.imageDimensions, w.uint32(58).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Streemshot message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Streemshot
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Streemshot} Streemshot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Streemshot.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Streemshot();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.inResponseToWallItemId = r.string();
                        break;
                    }
                case 2: {
                        m.downloadUrl = r.string();
                        break;
                    }
                case 3: {
                        m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        if (!(m.featurePoints && m.featurePoints.length))
                            m.featurePoints = [];
                        m.featurePoints.push($root.streem.api.Point.decode(r, r.uint32()));
                        break;
                    }
                case 5: {
                        m.storageUrl = r.string();
                        break;
                    }
                case 6: {
                        m.cameraIntrinsics = $root.CameraIntrinsics.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.imageDimensions = $root.streem.api.Size.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Streemshot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Streemshot
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Streemshot} Streemshot
         */
        Streemshot.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Streemshot)
                return d;
            var m = new $root.WallItem.Streemshot();
            if (d.inResponseToWallItemId != null) {
                m.inResponseToWallItemId = String(d.inResponseToWallItemId);
            }
            if (d.downloadUrl != null) {
                m.downloadUrl = String(d.downloadUrl);
            }
            if (d.pose != null) {
                if (typeof d.pose !== "object")
                    throw TypeError(".WallItem.Streemshot.pose: object expected");
                m.pose = $root.streem.api.Pose.fromObject(d.pose);
            }
            if (d.featurePoints) {
                if (!Array.isArray(d.featurePoints))
                    throw TypeError(".WallItem.Streemshot.featurePoints: array expected");
                m.featurePoints = [];
                for (var i = 0; i < d.featurePoints.length; ++i) {
                    if (typeof d.featurePoints[i] !== "object")
                        throw TypeError(".WallItem.Streemshot.featurePoints: object expected");
                    m.featurePoints[i] = $root.streem.api.Point.fromObject(d.featurePoints[i]);
                }
            }
            if (d.storageUrl != null) {
                m.storageUrl = String(d.storageUrl);
            }
            if (d.cameraIntrinsics != null) {
                if (typeof d.cameraIntrinsics !== "object")
                    throw TypeError(".WallItem.Streemshot.cameraIntrinsics: object expected");
                m.cameraIntrinsics = $root.CameraIntrinsics.fromObject(d.cameraIntrinsics);
            }
            if (d.imageDimensions != null) {
                if (typeof d.imageDimensions !== "object")
                    throw TypeError(".WallItem.Streemshot.imageDimensions: object expected");
                m.imageDimensions = $root.streem.api.Size.fromObject(d.imageDimensions);
            }
            return m;
        };

        /**
         * Creates a plain object from a Streemshot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Streemshot
         * @static
         * @param {WallItem.Streemshot} m Streemshot
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Streemshot.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.featurePoints = [];
            }
            if (o.defaults) {
                d.inResponseToWallItemId = "";
                d.downloadUrl = "";
                d.pose = null;
                d.storageUrl = "";
                d.cameraIntrinsics = null;
                d.imageDimensions = null;
            }
            if (m.inResponseToWallItemId != null && m.hasOwnProperty("inResponseToWallItemId")) {
                d.inResponseToWallItemId = m.inResponseToWallItemId;
            }
            if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                d.downloadUrl = m.downloadUrl;
            }
            if (m.pose != null && m.hasOwnProperty("pose")) {
                d.pose = $root.streem.api.Pose.toObject(m.pose, o);
            }
            if (m.featurePoints && m.featurePoints.length) {
                d.featurePoints = [];
                for (var j = 0; j < m.featurePoints.length; ++j) {
                    d.featurePoints[j] = $root.streem.api.Point.toObject(m.featurePoints[j], o);
                }
            }
            if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                d.storageUrl = m.storageUrl;
            }
            if (m.cameraIntrinsics != null && m.hasOwnProperty("cameraIntrinsics")) {
                d.cameraIntrinsics = $root.CameraIntrinsics.toObject(m.cameraIntrinsics, o);
            }
            if (m.imageDimensions != null && m.hasOwnProperty("imageDimensions")) {
                d.imageDimensions = $root.streem.api.Size.toObject(m.imageDimensions, o);
            }
            return d;
        };

        /**
         * Converts this Streemshot to JSON.
         * @function toJSON
         * @memberof WallItem.Streemshot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Streemshot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Streemshot
         * @function getTypeUrl
         * @memberof WallItem.Streemshot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Streemshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Streemshot";
        };

        return Streemshot;
    })();

    WallItem.StreemshotRevision = (function() {

        /**
         * Properties of a StreemshotRevision.
         * @memberof WallItem
         * @interface IStreemshotRevision
         * @property {string|null} [downloadUrl] StreemshotRevision downloadUrl
         * @property {Array.<WallItem.IARElement>|null} [arElements] StreemshotRevision arElements
         * @property {string|null} [storageUrl] StreemshotRevision storageUrl
         * @property {string|null} [streemshotId] StreemshotRevision streemshotId
         * @property {boolean|null} [edited] StreemshotRevision edited
         */

        /**
         * Constructs a new StreemshotRevision.
         * @memberof WallItem
         * @classdesc Represents a StreemshotRevision.
         * @implements IStreemshotRevision
         * @constructor
         * @param {WallItem.IStreemshotRevision=} [p] Properties to set
         */
        function StreemshotRevision(p) {
            this.arElements = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StreemshotRevision downloadUrl.
         * @member {string} downloadUrl
         * @memberof WallItem.StreemshotRevision
         * @instance
         */
        StreemshotRevision.prototype.downloadUrl = "";

        /**
         * StreemshotRevision arElements.
         * @member {Array.<WallItem.IARElement>} arElements
         * @memberof WallItem.StreemshotRevision
         * @instance
         */
        StreemshotRevision.prototype.arElements = $util.emptyArray;

        /**
         * StreemshotRevision storageUrl.
         * @member {string} storageUrl
         * @memberof WallItem.StreemshotRevision
         * @instance
         */
        StreemshotRevision.prototype.storageUrl = "";

        /**
         * StreemshotRevision streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.StreemshotRevision
         * @instance
         */
        StreemshotRevision.prototype.streemshotId = "";

        /**
         * StreemshotRevision edited.
         * @member {boolean} edited
         * @memberof WallItem.StreemshotRevision
         * @instance
         */
        StreemshotRevision.prototype.edited = false;

        /**
         * Creates a new StreemshotRevision instance using the specified properties.
         * @function create
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {WallItem.IStreemshotRevision=} [properties] Properties to set
         * @returns {WallItem.StreemshotRevision} StreemshotRevision instance
         */
        StreemshotRevision.create = function create(properties) {
            return new StreemshotRevision(properties);
        };

        /**
         * Encodes the specified StreemshotRevision message. Does not implicitly {@link WallItem.StreemshotRevision.verify|verify} messages.
         * @function encode
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {WallItem.IStreemshotRevision} m StreemshotRevision message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreemshotRevision.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                w.uint32(10).string(m.downloadUrl);
            if (m.arElements != null && m.arElements.length) {
                for (var i = 0; i < m.arElements.length; ++i)
                    $root.WallItem.ARElement.encode(m.arElements[i], w.uint32(18).fork()).ldelim();
            }
            if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                w.uint32(26).string(m.storageUrl);
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(34).string(m.streemshotId);
            if (m.edited != null && Object.hasOwnProperty.call(m, "edited"))
                w.uint32(40).bool(m.edited);
            return w;
        };

        /**
         * Decodes a StreemshotRevision message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.StreemshotRevision} StreemshotRevision
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreemshotRevision.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StreemshotRevision();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.downloadUrl = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.arElements && m.arElements.length))
                            m.arElements = [];
                        m.arElements.push($root.WallItem.ARElement.decode(r, r.uint32()));
                        break;
                    }
                case 3: {
                        m.storageUrl = r.string();
                        break;
                    }
                case 4: {
                        m.streemshotId = r.string();
                        break;
                    }
                case 5: {
                        m.edited = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StreemshotRevision message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.StreemshotRevision} StreemshotRevision
         */
        StreemshotRevision.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.StreemshotRevision)
                return d;
            var m = new $root.WallItem.StreemshotRevision();
            if (d.downloadUrl != null) {
                m.downloadUrl = String(d.downloadUrl);
            }
            if (d.arElements) {
                if (!Array.isArray(d.arElements))
                    throw TypeError(".WallItem.StreemshotRevision.arElements: array expected");
                m.arElements = [];
                for (var i = 0; i < d.arElements.length; ++i) {
                    if (typeof d.arElements[i] !== "object")
                        throw TypeError(".WallItem.StreemshotRevision.arElements: object expected");
                    m.arElements[i] = $root.WallItem.ARElement.fromObject(d.arElements[i]);
                }
            }
            if (d.storageUrl != null) {
                m.storageUrl = String(d.storageUrl);
            }
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            if (d.edited != null) {
                m.edited = Boolean(d.edited);
            }
            return m;
        };

        /**
         * Creates a plain object from a StreemshotRevision message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {WallItem.StreemshotRevision} m StreemshotRevision
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StreemshotRevision.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.arElements = [];
            }
            if (o.defaults) {
                d.downloadUrl = "";
                d.storageUrl = "";
                d.streemshotId = "";
                d.edited = false;
            }
            if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                d.downloadUrl = m.downloadUrl;
            }
            if (m.arElements && m.arElements.length) {
                d.arElements = [];
                for (var j = 0; j < m.arElements.length; ++j) {
                    d.arElements[j] = $root.WallItem.ARElement.toObject(m.arElements[j], o);
                }
            }
            if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                d.storageUrl = m.storageUrl;
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            if (m.edited != null && m.hasOwnProperty("edited")) {
                d.edited = m.edited;
            }
            return d;
        };

        /**
         * Converts this StreemshotRevision to JSON.
         * @function toJSON
         * @memberof WallItem.StreemshotRevision
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StreemshotRevision.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StreemshotRevision
         * @function getTypeUrl
         * @memberof WallItem.StreemshotRevision
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StreemshotRevision.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.StreemshotRevision";
        };

        return StreemshotRevision;
    })();

    WallItem.StreemshotDepthMap = (function() {

        /**
         * Properties of a StreemshotDepthMap.
         * @memberof WallItem
         * @interface IStreemshotDepthMap
         * @property {string|null} [storageUrl] StreemshotDepthMap storageUrl
         * @property {string|null} [streemshotId] StreemshotDepthMap streemshotId
         */

        /**
         * Constructs a new StreemshotDepthMap.
         * @memberof WallItem
         * @classdesc Represents a StreemshotDepthMap.
         * @implements IStreemshotDepthMap
         * @constructor
         * @param {WallItem.IStreemshotDepthMap=} [p] Properties to set
         */
        function StreemshotDepthMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StreemshotDepthMap storageUrl.
         * @member {string} storageUrl
         * @memberof WallItem.StreemshotDepthMap
         * @instance
         */
        StreemshotDepthMap.prototype.storageUrl = "";

        /**
         * StreemshotDepthMap streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.StreemshotDepthMap
         * @instance
         */
        StreemshotDepthMap.prototype.streemshotId = "";

        /**
         * Creates a new StreemshotDepthMap instance using the specified properties.
         * @function create
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {WallItem.IStreemshotDepthMap=} [properties] Properties to set
         * @returns {WallItem.StreemshotDepthMap} StreemshotDepthMap instance
         */
        StreemshotDepthMap.create = function create(properties) {
            return new StreemshotDepthMap(properties);
        };

        /**
         * Encodes the specified StreemshotDepthMap message. Does not implicitly {@link WallItem.StreemshotDepthMap.verify|verify} messages.
         * @function encode
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {WallItem.IStreemshotDepthMap} m StreemshotDepthMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreemshotDepthMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                w.uint32(10).string(m.storageUrl);
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(18).string(m.streemshotId);
            return w;
        };

        /**
         * Decodes a StreemshotDepthMap message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.StreemshotDepthMap} StreemshotDepthMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreemshotDepthMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StreemshotDepthMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.storageUrl = r.string();
                        break;
                    }
                case 2: {
                        m.streemshotId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StreemshotDepthMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.StreemshotDepthMap} StreemshotDepthMap
         */
        StreemshotDepthMap.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.StreemshotDepthMap)
                return d;
            var m = new $root.WallItem.StreemshotDepthMap();
            if (d.storageUrl != null) {
                m.storageUrl = String(d.storageUrl);
            }
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            return m;
        };

        /**
         * Creates a plain object from a StreemshotDepthMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {WallItem.StreemshotDepthMap} m StreemshotDepthMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StreemshotDepthMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.storageUrl = "";
                d.streemshotId = "";
            }
            if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                d.storageUrl = m.storageUrl;
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            return d;
        };

        /**
         * Converts this StreemshotDepthMap to JSON.
         * @function toJSON
         * @memberof WallItem.StreemshotDepthMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StreemshotDepthMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StreemshotDepthMap
         * @function getTypeUrl
         * @memberof WallItem.StreemshotDepthMap
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StreemshotDepthMap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.StreemshotDepthMap";
        };

        return StreemshotDepthMap;
    })();

    WallItem.ARElement = (function() {

        /**
         * Properties of a ARElement.
         * @memberof WallItem
         * @interface IARElement
         * @property {WallItem.ARElement.IArrow|null} [arrow] ARElement arrow
         * @property {WallItem.ARElement.IMeasurement|null} [measurement] ARElement measurement
         * @property {WallItem.ARElement.IText|null} [text] ARElement text
         * @property {WallItem.ARElement.IModel3d|null} [model3d] ARElement model3d
         */

        /**
         * Constructs a new ARElement.
         * @memberof WallItem
         * @classdesc Represents a ARElement.
         * @implements IARElement
         * @constructor
         * @param {WallItem.IARElement=} [p] Properties to set
         */
        function ARElement(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ARElement arrow.
         * @member {WallItem.ARElement.IArrow|null|undefined} arrow
         * @memberof WallItem.ARElement
         * @instance
         */
        ARElement.prototype.arrow = null;

        /**
         * ARElement measurement.
         * @member {WallItem.ARElement.IMeasurement|null|undefined} measurement
         * @memberof WallItem.ARElement
         * @instance
         */
        ARElement.prototype.measurement = null;

        /**
         * ARElement text.
         * @member {WallItem.ARElement.IText|null|undefined} text
         * @memberof WallItem.ARElement
         * @instance
         */
        ARElement.prototype.text = null;

        /**
         * ARElement model3d.
         * @member {WallItem.ARElement.IModel3d|null|undefined} model3d
         * @memberof WallItem.ARElement
         * @instance
         */
        ARElement.prototype.model3d = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ARElement type.
         * @member {"arrow"|"measurement"|"text"|"model3d"|undefined} type
         * @memberof WallItem.ARElement
         * @instance
         */
        Object.defineProperty(ARElement.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["arrow", "measurement", "text", "model3d"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ARElement instance using the specified properties.
         * @function create
         * @memberof WallItem.ARElement
         * @static
         * @param {WallItem.IARElement=} [properties] Properties to set
         * @returns {WallItem.ARElement} ARElement instance
         */
        ARElement.create = function create(properties) {
            return new ARElement(properties);
        };

        /**
         * Encodes the specified ARElement message. Does not implicitly {@link WallItem.ARElement.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ARElement
         * @static
         * @param {WallItem.IARElement} m ARElement message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ARElement.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
                $root.WallItem.ARElement.Arrow.encode(m.arrow, w.uint32(10).fork()).ldelim();
            if (m.measurement != null && Object.hasOwnProperty.call(m, "measurement"))
                $root.WallItem.ARElement.Measurement.encode(m.measurement, w.uint32(18).fork()).ldelim();
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                $root.WallItem.ARElement.Text.encode(m.text, w.uint32(26).fork()).ldelim();
            if (m.model3d != null && Object.hasOwnProperty.call(m, "model3d"))
                $root.WallItem.ARElement.Model3d.encode(m.model3d, w.uint32(34).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ARElement message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ARElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ARElement} ARElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ARElement.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARElement();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.arrow = $root.WallItem.ARElement.Arrow.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.measurement = $root.WallItem.ARElement.Measurement.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.text = $root.WallItem.ARElement.Text.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.model3d = $root.WallItem.ARElement.Model3d.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ARElement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ARElement
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ARElement} ARElement
         */
        ARElement.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ARElement)
                return d;
            var m = new $root.WallItem.ARElement();
            if (d.arrow != null) {
                if (typeof d.arrow !== "object")
                    throw TypeError(".WallItem.ARElement.arrow: object expected");
                m.arrow = $root.WallItem.ARElement.Arrow.fromObject(d.arrow);
            }
            if (d.measurement != null) {
                if (typeof d.measurement !== "object")
                    throw TypeError(".WallItem.ARElement.measurement: object expected");
                m.measurement = $root.WallItem.ARElement.Measurement.fromObject(d.measurement);
            }
            if (d.text != null) {
                if (typeof d.text !== "object")
                    throw TypeError(".WallItem.ARElement.text: object expected");
                m.text = $root.WallItem.ARElement.Text.fromObject(d.text);
            }
            if (d.model3d != null) {
                if (typeof d.model3d !== "object")
                    throw TypeError(".WallItem.ARElement.model3d: object expected");
                m.model3d = $root.WallItem.ARElement.Model3d.fromObject(d.model3d);
            }
            return m;
        };

        /**
         * Creates a plain object from a ARElement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ARElement
         * @static
         * @param {WallItem.ARElement} m ARElement
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ARElement.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (m.arrow != null && m.hasOwnProperty("arrow")) {
                d.arrow = $root.WallItem.ARElement.Arrow.toObject(m.arrow, o);
                if (o.oneofs)
                    d.type = "arrow";
            }
            if (m.measurement != null && m.hasOwnProperty("measurement")) {
                d.measurement = $root.WallItem.ARElement.Measurement.toObject(m.measurement, o);
                if (o.oneofs)
                    d.type = "measurement";
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = $root.WallItem.ARElement.Text.toObject(m.text, o);
                if (o.oneofs)
                    d.type = "text";
            }
            if (m.model3d != null && m.hasOwnProperty("model3d")) {
                d.model3d = $root.WallItem.ARElement.Model3d.toObject(m.model3d, o);
                if (o.oneofs)
                    d.type = "model3d";
            }
            return d;
        };

        /**
         * Converts this ARElement to JSON.
         * @function toJSON
         * @memberof WallItem.ARElement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ARElement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ARElement
         * @function getTypeUrl
         * @memberof WallItem.ARElement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ARElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ARElement";
        };

        ARElement.Arrow = (function() {

            /**
             * Properties of an Arrow.
             * @memberof WallItem.ARElement
             * @interface IArrow
             * @property {streem.api.ITransform|null} [transform] Arrow transform
             * @property {number|null} [rotation] Arrow rotation
             */

            /**
             * Constructs a new Arrow.
             * @memberof WallItem.ARElement
             * @classdesc Represents an Arrow.
             * @implements IArrow
             * @constructor
             * @param {WallItem.ARElement.IArrow=} [p] Properties to set
             */
            function Arrow(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Arrow transform.
             * @member {streem.api.ITransform|null|undefined} transform
             * @memberof WallItem.ARElement.Arrow
             * @instance
             */
            Arrow.prototype.transform = null;

            /**
             * Arrow rotation.
             * @member {number} rotation
             * @memberof WallItem.ARElement.Arrow
             * @instance
             */
            Arrow.prototype.rotation = 0;

            /**
             * Creates a new Arrow instance using the specified properties.
             * @function create
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {WallItem.ARElement.IArrow=} [properties] Properties to set
             * @returns {WallItem.ARElement.Arrow} Arrow instance
             */
            Arrow.create = function create(properties) {
                return new Arrow(properties);
            };

            /**
             * Encodes the specified Arrow message. Does not implicitly {@link WallItem.ARElement.Arrow.verify|verify} messages.
             * @function encode
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {WallItem.ARElement.IArrow} m Arrow message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Arrow.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                    $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                    w.uint32(21).float(m.rotation);
                return w;
            };

            /**
             * Decodes an Arrow message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.ARElement.Arrow} Arrow
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Arrow.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARElement.Arrow();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.rotation = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Arrow message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.ARElement.Arrow} Arrow
             */
            Arrow.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.ARElement.Arrow)
                    return d;
                var m = new $root.WallItem.ARElement.Arrow();
                if (d.transform != null) {
                    if (typeof d.transform !== "object")
                        throw TypeError(".WallItem.ARElement.Arrow.transform: object expected");
                    m.transform = $root.streem.api.Transform.fromObject(d.transform);
                }
                if (d.rotation != null) {
                    m.rotation = Number(d.rotation);
                }
                return m;
            };

            /**
             * Creates a plain object from an Arrow message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {WallItem.ARElement.Arrow} m Arrow
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Arrow.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.transform = null;
                    d.rotation = 0;
                }
                if (m.transform != null && m.hasOwnProperty("transform")) {
                    d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                }
                if (m.rotation != null && m.hasOwnProperty("rotation")) {
                    d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                }
                return d;
            };

            /**
             * Converts this Arrow to JSON.
             * @function toJSON
             * @memberof WallItem.ARElement.Arrow
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Arrow.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Arrow
             * @function getTypeUrl
             * @memberof WallItem.ARElement.Arrow
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Arrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.ARElement.Arrow";
            };

            return Arrow;
        })();

        ARElement.Measurement = (function() {

            /**
             * Properties of a Measurement.
             * @memberof WallItem.ARElement
             * @interface IMeasurement
             * @property {streem.api.IPoint|null} [from] Measurement from
             * @property {streem.api.IPoint|null} [to] Measurement to
             */

            /**
             * Constructs a new Measurement.
             * @memberof WallItem.ARElement
             * @classdesc Represents a Measurement.
             * @implements IMeasurement
             * @constructor
             * @param {WallItem.ARElement.IMeasurement=} [p] Properties to set
             */
            function Measurement(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Measurement from.
             * @member {streem.api.IPoint|null|undefined} from
             * @memberof WallItem.ARElement.Measurement
             * @instance
             */
            Measurement.prototype.from = null;

            /**
             * Measurement to.
             * @member {streem.api.IPoint|null|undefined} to
             * @memberof WallItem.ARElement.Measurement
             * @instance
             */
            Measurement.prototype.to = null;

            /**
             * Creates a new Measurement instance using the specified properties.
             * @function create
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {WallItem.ARElement.IMeasurement=} [properties] Properties to set
             * @returns {WallItem.ARElement.Measurement} Measurement instance
             */
            Measurement.create = function create(properties) {
                return new Measurement(properties);
            };

            /**
             * Encodes the specified Measurement message. Does not implicitly {@link WallItem.ARElement.Measurement.verify|verify} messages.
             * @function encode
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {WallItem.ARElement.IMeasurement} m Measurement message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Measurement.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.from != null && Object.hasOwnProperty.call(m, "from"))
                    $root.streem.api.Point.encode(m.from, w.uint32(10).fork()).ldelim();
                if (m.to != null && Object.hasOwnProperty.call(m, "to"))
                    $root.streem.api.Point.encode(m.to, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a Measurement message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.ARElement.Measurement} Measurement
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Measurement.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARElement.Measurement();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.from = $root.streem.api.Point.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.to = $root.streem.api.Point.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Measurement message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.ARElement.Measurement} Measurement
             */
            Measurement.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.ARElement.Measurement)
                    return d;
                var m = new $root.WallItem.ARElement.Measurement();
                if (d.from != null) {
                    if (typeof d.from !== "object")
                        throw TypeError(".WallItem.ARElement.Measurement.from: object expected");
                    m.from = $root.streem.api.Point.fromObject(d.from);
                }
                if (d.to != null) {
                    if (typeof d.to !== "object")
                        throw TypeError(".WallItem.ARElement.Measurement.to: object expected");
                    m.to = $root.streem.api.Point.fromObject(d.to);
                }
                return m;
            };

            /**
             * Creates a plain object from a Measurement message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {WallItem.ARElement.Measurement} m Measurement
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Measurement.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.from = null;
                    d.to = null;
                }
                if (m.from != null && m.hasOwnProperty("from")) {
                    d.from = $root.streem.api.Point.toObject(m.from, o);
                }
                if (m.to != null && m.hasOwnProperty("to")) {
                    d.to = $root.streem.api.Point.toObject(m.to, o);
                }
                return d;
            };

            /**
             * Converts this Measurement to JSON.
             * @function toJSON
             * @memberof WallItem.ARElement.Measurement
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Measurement.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Measurement
             * @function getTypeUrl
             * @memberof WallItem.ARElement.Measurement
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Measurement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.ARElement.Measurement";
            };

            return Measurement;
        })();

        ARElement.Text = (function() {

            /**
             * Properties of a Text.
             * @memberof WallItem.ARElement
             * @interface IText
             * @property {streem.api.ITransform|null} [transform] Text transform
             * @property {number|null} [rotation] Text rotation
             * @property {string|null} [text] Text text
             */

            /**
             * Constructs a new Text.
             * @memberof WallItem.ARElement
             * @classdesc Represents a Text.
             * @implements IText
             * @constructor
             * @param {WallItem.ARElement.IText=} [p] Properties to set
             */
            function Text(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Text transform.
             * @member {streem.api.ITransform|null|undefined} transform
             * @memberof WallItem.ARElement.Text
             * @instance
             */
            Text.prototype.transform = null;

            /**
             * Text rotation.
             * @member {number} rotation
             * @memberof WallItem.ARElement.Text
             * @instance
             */
            Text.prototype.rotation = 0;

            /**
             * Text text.
             * @member {string} text
             * @memberof WallItem.ARElement.Text
             * @instance
             */
            Text.prototype.text = "";

            /**
             * Creates a new Text instance using the specified properties.
             * @function create
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {WallItem.ARElement.IText=} [properties] Properties to set
             * @returns {WallItem.ARElement.Text} Text instance
             */
            Text.create = function create(properties) {
                return new Text(properties);
            };

            /**
             * Encodes the specified Text message. Does not implicitly {@link WallItem.ARElement.Text.verify|verify} messages.
             * @function encode
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {WallItem.ARElement.IText} m Text message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Text.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                    $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                    w.uint32(21).float(m.rotation);
                if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                    w.uint32(26).string(m.text);
                return w;
            };

            /**
             * Decodes a Text message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.ARElement.Text} Text
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Text.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARElement.Text();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.rotation = r.float();
                            break;
                        }
                    case 3: {
                            m.text = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Text message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.ARElement.Text} Text
             */
            Text.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.ARElement.Text)
                    return d;
                var m = new $root.WallItem.ARElement.Text();
                if (d.transform != null) {
                    if (typeof d.transform !== "object")
                        throw TypeError(".WallItem.ARElement.Text.transform: object expected");
                    m.transform = $root.streem.api.Transform.fromObject(d.transform);
                }
                if (d.rotation != null) {
                    m.rotation = Number(d.rotation);
                }
                if (d.text != null) {
                    m.text = String(d.text);
                }
                return m;
            };

            /**
             * Creates a plain object from a Text message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {WallItem.ARElement.Text} m Text
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Text.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.transform = null;
                    d.rotation = 0;
                    d.text = "";
                }
                if (m.transform != null && m.hasOwnProperty("transform")) {
                    d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                }
                if (m.rotation != null && m.hasOwnProperty("rotation")) {
                    d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                }
                if (m.text != null && m.hasOwnProperty("text")) {
                    d.text = m.text;
                }
                return d;
            };

            /**
             * Converts this Text to JSON.
             * @function toJSON
             * @memberof WallItem.ARElement.Text
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Text.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Text
             * @function getTypeUrl
             * @memberof WallItem.ARElement.Text
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.ARElement.Text";
            };

            return Text;
        })();

        ARElement.Model3d = (function() {

            /**
             * Properties of a Model3d.
             * @memberof WallItem.ARElement
             * @interface IModel3d
             * @property {streem.api.ITransform|null} [transform] Model3d transform
             * @property {number|null} [rotation] Model3d rotation
             * @property {string|null} [name] Model3d name
             */

            /**
             * Constructs a new Model3d.
             * @memberof WallItem.ARElement
             * @classdesc Represents a Model3d.
             * @implements IModel3d
             * @constructor
             * @param {WallItem.ARElement.IModel3d=} [p] Properties to set
             */
            function Model3d(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Model3d transform.
             * @member {streem.api.ITransform|null|undefined} transform
             * @memberof WallItem.ARElement.Model3d
             * @instance
             */
            Model3d.prototype.transform = null;

            /**
             * Model3d rotation.
             * @member {number} rotation
             * @memberof WallItem.ARElement.Model3d
             * @instance
             */
            Model3d.prototype.rotation = 0;

            /**
             * Model3d name.
             * @member {string} name
             * @memberof WallItem.ARElement.Model3d
             * @instance
             */
            Model3d.prototype.name = "";

            /**
             * Creates a new Model3d instance using the specified properties.
             * @function create
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {WallItem.ARElement.IModel3d=} [properties] Properties to set
             * @returns {WallItem.ARElement.Model3d} Model3d instance
             */
            Model3d.create = function create(properties) {
                return new Model3d(properties);
            };

            /**
             * Encodes the specified Model3d message. Does not implicitly {@link WallItem.ARElement.Model3d.verify|verify} messages.
             * @function encode
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {WallItem.ARElement.IModel3d} m Model3d message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Model3d.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
                    $root.streem.api.Transform.encode(m.transform, w.uint32(10).fork()).ldelim();
                if (m.rotation != null && Object.hasOwnProperty.call(m, "rotation"))
                    w.uint32(21).float(m.rotation);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                return w;
            };

            /**
             * Decodes a Model3d message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.ARElement.Model3d} Model3d
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Model3d.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARElement.Model3d();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.rotation = r.float();
                            break;
                        }
                    case 3: {
                            m.name = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Model3d message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.ARElement.Model3d} Model3d
             */
            Model3d.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.ARElement.Model3d)
                    return d;
                var m = new $root.WallItem.ARElement.Model3d();
                if (d.transform != null) {
                    if (typeof d.transform !== "object")
                        throw TypeError(".WallItem.ARElement.Model3d.transform: object expected");
                    m.transform = $root.streem.api.Transform.fromObject(d.transform);
                }
                if (d.rotation != null) {
                    m.rotation = Number(d.rotation);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            /**
             * Creates a plain object from a Model3d message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {WallItem.ARElement.Model3d} m Model3d
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Model3d.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.transform = null;
                    d.rotation = 0;
                    d.name = "";
                }
                if (m.transform != null && m.hasOwnProperty("transform")) {
                    d.transform = $root.streem.api.Transform.toObject(m.transform, o);
                }
                if (m.rotation != null && m.hasOwnProperty("rotation")) {
                    d.rotation = o.json && !isFinite(m.rotation) ? String(m.rotation) : m.rotation;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            /**
             * Converts this Model3d to JSON.
             * @function toJSON
             * @memberof WallItem.ARElement.Model3d
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Model3d.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Model3d
             * @function getTypeUrl
             * @memberof WallItem.ARElement.Model3d
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Model3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.ARElement.Model3d";
            };

            return Model3d;
        })();

        return ARElement;
    })();

    WallItem.StreemshotDetections = (function() {

        /**
         * Properties of a StreemshotDetections.
         * @memberof WallItem
         * @interface IStreemshotDetections
         * @property {Array.<WallItem.StreemshotDetections.IDetection>|null} [detections] StreemshotDetections detections
         * @property {string|null} [streemshotId] StreemshotDetections streemshotId
         */

        /**
         * Constructs a new StreemshotDetections.
         * @memberof WallItem
         * @classdesc Represents a StreemshotDetections.
         * @implements IStreemshotDetections
         * @constructor
         * @param {WallItem.IStreemshotDetections=} [p] Properties to set
         */
        function StreemshotDetections(p) {
            this.detections = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StreemshotDetections detections.
         * @member {Array.<WallItem.StreemshotDetections.IDetection>} detections
         * @memberof WallItem.StreemshotDetections
         * @instance
         */
        StreemshotDetections.prototype.detections = $util.emptyArray;

        /**
         * StreemshotDetections streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.StreemshotDetections
         * @instance
         */
        StreemshotDetections.prototype.streemshotId = "";

        /**
         * Creates a new StreemshotDetections instance using the specified properties.
         * @function create
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {WallItem.IStreemshotDetections=} [properties] Properties to set
         * @returns {WallItem.StreemshotDetections} StreemshotDetections instance
         */
        StreemshotDetections.create = function create(properties) {
            return new StreemshotDetections(properties);
        };

        /**
         * Encodes the specified StreemshotDetections message. Does not implicitly {@link WallItem.StreemshotDetections.verify|verify} messages.
         * @function encode
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {WallItem.IStreemshotDetections} m StreemshotDetections message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreemshotDetections.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.detections != null && m.detections.length) {
                for (var i = 0; i < m.detections.length; ++i)
                    $root.WallItem.StreemshotDetections.Detection.encode(m.detections[i], w.uint32(10).fork()).ldelim();
            }
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(18).string(m.streemshotId);
            return w;
        };

        /**
         * Decodes a StreemshotDetections message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.StreemshotDetections} StreemshotDetections
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreemshotDetections.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StreemshotDetections();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.detections && m.detections.length))
                            m.detections = [];
                        m.detections.push($root.WallItem.StreemshotDetections.Detection.decode(r, r.uint32()));
                        break;
                    }
                case 2: {
                        m.streemshotId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StreemshotDetections message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.StreemshotDetections} StreemshotDetections
         */
        StreemshotDetections.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.StreemshotDetections)
                return d;
            var m = new $root.WallItem.StreemshotDetections();
            if (d.detections) {
                if (!Array.isArray(d.detections))
                    throw TypeError(".WallItem.StreemshotDetections.detections: array expected");
                m.detections = [];
                for (var i = 0; i < d.detections.length; ++i) {
                    if (typeof d.detections[i] !== "object")
                        throw TypeError(".WallItem.StreemshotDetections.detections: object expected");
                    m.detections[i] = $root.WallItem.StreemshotDetections.Detection.fromObject(d.detections[i]);
                }
            }
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            return m;
        };

        /**
         * Creates a plain object from a StreemshotDetections message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {WallItem.StreemshotDetections} m StreemshotDetections
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StreemshotDetections.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.detections = [];
            }
            if (o.defaults) {
                d.streemshotId = "";
            }
            if (m.detections && m.detections.length) {
                d.detections = [];
                for (var j = 0; j < m.detections.length; ++j) {
                    d.detections[j] = $root.WallItem.StreemshotDetections.Detection.toObject(m.detections[j], o);
                }
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            return d;
        };

        /**
         * Converts this StreemshotDetections to JSON.
         * @function toJSON
         * @memberof WallItem.StreemshotDetections
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StreemshotDetections.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StreemshotDetections
         * @function getTypeUrl
         * @memberof WallItem.StreemshotDetections
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StreemshotDetections.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.StreemshotDetections";
        };

        StreemshotDetections.Detection = (function() {

            /**
             * Properties of a Detection.
             * @memberof WallItem.StreemshotDetections
             * @interface IDetection
             * @property {string|null} [detectionId] Detection detectionId
             * @property {streem.api.Detection.Type|null} [type] Detection type
             * @property {string|null} [description] Detection description
             * @property {number|null} [confidence] Detection confidence
             * @property {streem.api.Detection.IBoundingBox|null} [boundingBox] Detection boundingBox
             * @property {streem.api.Detection.Source|null} [source] Detection source
             * @property {string|null} [extractor] Detection extractor
             * @property {google.protobuf.ITimestamp|null} [lastUpdatedAt] Detection lastUpdatedAt
             */

            /**
             * Constructs a new Detection.
             * @memberof WallItem.StreemshotDetections
             * @classdesc Represents a Detection.
             * @implements IDetection
             * @constructor
             * @param {WallItem.StreemshotDetections.IDetection=} [p] Properties to set
             */
            function Detection(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Detection detectionId.
             * @member {string} detectionId
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.detectionId = "";

            /**
             * Detection type.
             * @member {streem.api.Detection.Type} type
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.type = 0;

            /**
             * Detection description.
             * @member {string} description
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.description = "";

            /**
             * Detection confidence.
             * @member {number} confidence
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.confidence = 0;

            /**
             * Detection boundingBox.
             * @member {streem.api.Detection.IBoundingBox|null|undefined} boundingBox
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.boundingBox = null;

            /**
             * Detection source.
             * @member {streem.api.Detection.Source} source
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.source = 0;

            /**
             * Detection extractor.
             * @member {string} extractor
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.extractor = "";

            /**
             * Detection lastUpdatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdatedAt
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             */
            Detection.prototype.lastUpdatedAt = null;

            /**
             * Creates a new Detection instance using the specified properties.
             * @function create
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {WallItem.StreemshotDetections.IDetection=} [properties] Properties to set
             * @returns {WallItem.StreemshotDetections.Detection} Detection instance
             */
            Detection.create = function create(properties) {
                return new Detection(properties);
            };

            /**
             * Encodes the specified Detection message. Does not implicitly {@link WallItem.StreemshotDetections.Detection.verify|verify} messages.
             * @function encode
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {WallItem.StreemshotDetections.IDetection} m Detection message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Detection.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.detectionId != null && Object.hasOwnProperty.call(m, "detectionId"))
                    w.uint32(10).string(m.detectionId);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(16).int32(m.type);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(26).string(m.description);
                if (m.confidence != null && Object.hasOwnProperty.call(m, "confidence"))
                    w.uint32(37).float(m.confidence);
                if (m.boundingBox != null && Object.hasOwnProperty.call(m, "boundingBox"))
                    $root.streem.api.Detection.BoundingBox.encode(m.boundingBox, w.uint32(42).fork()).ldelim();
                if (m.source != null && Object.hasOwnProperty.call(m, "source"))
                    w.uint32(48).int32(m.source);
                if (m.extractor != null && Object.hasOwnProperty.call(m, "extractor"))
                    w.uint32(58).string(m.extractor);
                if (m.lastUpdatedAt != null && Object.hasOwnProperty.call(m, "lastUpdatedAt"))
                    $root.google.protobuf.Timestamp.encode(m.lastUpdatedAt, w.uint32(66).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a Detection message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.StreemshotDetections.Detection} Detection
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Detection.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StreemshotDetections.Detection();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.detectionId = r.string();
                            break;
                        }
                    case 2: {
                            m.type = r.int32();
                            break;
                        }
                    case 3: {
                            m.description = r.string();
                            break;
                        }
                    case 4: {
                            m.confidence = r.float();
                            break;
                        }
                    case 5: {
                            m.boundingBox = $root.streem.api.Detection.BoundingBox.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.source = r.int32();
                            break;
                        }
                    case 7: {
                            m.extractor = r.string();
                            break;
                        }
                    case 8: {
                            m.lastUpdatedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Detection message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.StreemshotDetections.Detection} Detection
             */
            Detection.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.StreemshotDetections.Detection)
                    return d;
                var m = new $root.WallItem.StreemshotDetections.Detection();
                if (d.detectionId != null) {
                    m.detectionId = String(d.detectionId);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "DETECTION_TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "DETECTION_TYPE_LOGO":
                case 1:
                    m.type = 1;
                    break;
                case "DETECTION_TYPE_LABEL":
                case 2:
                    m.type = 2;
                    break;
                case "DETECTION_TYPE_TEXT":
                case 3:
                    m.type = 3;
                    break;
                case "DETECTION_TYPE_FULL_TEXT":
                case 4:
                    m.type = 4;
                    break;
                case "DETECTION_TYPE_SAFE_SEARCH":
                case 5:
                    m.type = 5;
                    break;
                case "DETECTION_TYPE_SERIAL":
                case 6:
                    m.type = 6;
                    break;
                case "DETECTION_TYPE_MODEL":
                case 7:
                    m.type = 7;
                    break;
                case "DETECTION_TYPE_OBJECT":
                case 8:
                    m.type = 8;
                    break;
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                if (d.confidence != null) {
                    m.confidence = Number(d.confidence);
                }
                if (d.boundingBox != null) {
                    if (typeof d.boundingBox !== "object")
                        throw TypeError(".WallItem.StreemshotDetections.Detection.boundingBox: object expected");
                    m.boundingBox = $root.streem.api.Detection.BoundingBox.fromObject(d.boundingBox);
                }
                switch (d.source) {
                default:
                    if (typeof d.source === "number") {
                        m.source = d.source;
                        break;
                    }
                    break;
                case "DETECTION_SOURCE_INVALID":
                case 0:
                    m.source = 0;
                    break;
                case "DETECTION_SOURCE_OCR":
                case 1:
                    m.source = 1;
                    break;
                case "DETECTION_SOURCE_USER":
                case 2:
                    m.source = 2;
                    break;
                case "DETECTION_SOURCE_AUTO":
                case 3:
                    m.source = 3;
                    break;
                }
                if (d.extractor != null) {
                    m.extractor = String(d.extractor);
                }
                if (d.lastUpdatedAt != null) {
                    if (typeof d.lastUpdatedAt !== "object")
                        throw TypeError(".WallItem.StreemshotDetections.Detection.lastUpdatedAt: object expected");
                    m.lastUpdatedAt = $root.google.protobuf.Timestamp.fromObject(d.lastUpdatedAt);
                }
                return m;
            };

            /**
             * Creates a plain object from a Detection message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {WallItem.StreemshotDetections.Detection} m Detection
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Detection.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.detectionId = "";
                    d.type = o.enums === String ? "DETECTION_TYPE_INVALID" : 0;
                    d.description = "";
                    d.confidence = 0;
                    d.boundingBox = null;
                    d.source = o.enums === String ? "DETECTION_SOURCE_INVALID" : 0;
                    d.extractor = "";
                    d.lastUpdatedAt = null;
                }
                if (m.detectionId != null && m.hasOwnProperty("detectionId")) {
                    d.detectionId = m.detectionId;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.Detection.Type[m.type] === undefined ? m.type : $root.streem.api.Detection.Type[m.type] : m.type;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.confidence != null && m.hasOwnProperty("confidence")) {
                    d.confidence = o.json && !isFinite(m.confidence) ? String(m.confidence) : m.confidence;
                }
                if (m.boundingBox != null && m.hasOwnProperty("boundingBox")) {
                    d.boundingBox = $root.streem.api.Detection.BoundingBox.toObject(m.boundingBox, o);
                }
                if (m.source != null && m.hasOwnProperty("source")) {
                    d.source = o.enums === String ? $root.streem.api.Detection.Source[m.source] === undefined ? m.source : $root.streem.api.Detection.Source[m.source] : m.source;
                }
                if (m.extractor != null && m.hasOwnProperty("extractor")) {
                    d.extractor = m.extractor;
                }
                if (m.lastUpdatedAt != null && m.hasOwnProperty("lastUpdatedAt")) {
                    d.lastUpdatedAt = $root.google.protobuf.Timestamp.toObject(m.lastUpdatedAt, o);
                }
                return d;
            };

            /**
             * Converts this Detection to JSON.
             * @function toJSON
             * @memberof WallItem.StreemshotDetections.Detection
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Detection.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Detection
             * @function getTypeUrl
             * @memberof WallItem.StreemshotDetections.Detection
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Detection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.StreemshotDetections.Detection";
            };

            return Detection;
        })();

        return StreemshotDetections;
    })();

    WallItem.UserDetectionFeedback = (function() {

        /**
         * Properties of a UserDetectionFeedback.
         * @memberof WallItem
         * @interface IUserDetectionFeedback
         * @property {string|null} [streemshotId] UserDetectionFeedback streemshotId
         * @property {string|null} [submittedByUserId] UserDetectionFeedback submittedByUserId
         * @property {WallItem.UserDetectionFeedback.ICreateDetection|null} [createDetection] UserDetectionFeedback createDetection
         * @property {WallItem.UserDetectionFeedback.IUpdateDetection|null} [updateDetection] UserDetectionFeedback updateDetection
         * @property {WallItem.UserDetectionFeedback.IHideDetection|null} [hideDetection] UserDetectionFeedback hideDetection
         */

        /**
         * Constructs a new UserDetectionFeedback.
         * @memberof WallItem
         * @classdesc Represents a UserDetectionFeedback.
         * @implements IUserDetectionFeedback
         * @constructor
         * @param {WallItem.IUserDetectionFeedback=} [p] Properties to set
         */
        function UserDetectionFeedback(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserDetectionFeedback streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        UserDetectionFeedback.prototype.streemshotId = "";

        /**
         * UserDetectionFeedback submittedByUserId.
         * @member {string} submittedByUserId
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        UserDetectionFeedback.prototype.submittedByUserId = "";

        /**
         * UserDetectionFeedback createDetection.
         * @member {WallItem.UserDetectionFeedback.ICreateDetection|null|undefined} createDetection
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        UserDetectionFeedback.prototype.createDetection = null;

        /**
         * UserDetectionFeedback updateDetection.
         * @member {WallItem.UserDetectionFeedback.IUpdateDetection|null|undefined} updateDetection
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        UserDetectionFeedback.prototype.updateDetection = null;

        /**
         * UserDetectionFeedback hideDetection.
         * @member {WallItem.UserDetectionFeedback.IHideDetection|null|undefined} hideDetection
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        UserDetectionFeedback.prototype.hideDetection = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UserDetectionFeedback type.
         * @member {"createDetection"|"updateDetection"|"hideDetection"|undefined} type
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         */
        Object.defineProperty(UserDetectionFeedback.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["createDetection", "updateDetection", "hideDetection"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UserDetectionFeedback instance using the specified properties.
         * @function create
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {WallItem.IUserDetectionFeedback=} [properties] Properties to set
         * @returns {WallItem.UserDetectionFeedback} UserDetectionFeedback instance
         */
        UserDetectionFeedback.create = function create(properties) {
            return new UserDetectionFeedback(properties);
        };

        /**
         * Encodes the specified UserDetectionFeedback message. Does not implicitly {@link WallItem.UserDetectionFeedback.verify|verify} messages.
         * @function encode
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {WallItem.IUserDetectionFeedback} m UserDetectionFeedback message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserDetectionFeedback.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(10).string(m.streemshotId);
            if (m.submittedByUserId != null && Object.hasOwnProperty.call(m, "submittedByUserId"))
                w.uint32(18).string(m.submittedByUserId);
            if (m.createDetection != null && Object.hasOwnProperty.call(m, "createDetection"))
                $root.WallItem.UserDetectionFeedback.CreateDetection.encode(m.createDetection, w.uint32(802).fork()).ldelim();
            if (m.updateDetection != null && Object.hasOwnProperty.call(m, "updateDetection"))
                $root.WallItem.UserDetectionFeedback.UpdateDetection.encode(m.updateDetection, w.uint32(1602).fork()).ldelim();
            if (m.hideDetection != null && Object.hasOwnProperty.call(m, "hideDetection"))
                $root.WallItem.UserDetectionFeedback.HideDetection.encode(m.hideDetection, w.uint32(2402).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a UserDetectionFeedback message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.UserDetectionFeedback} UserDetectionFeedback
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserDetectionFeedback.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.UserDetectionFeedback();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.streemshotId = r.string();
                        break;
                    }
                case 2: {
                        m.submittedByUserId = r.string();
                        break;
                    }
                case 100: {
                        m.createDetection = $root.WallItem.UserDetectionFeedback.CreateDetection.decode(r, r.uint32());
                        break;
                    }
                case 200: {
                        m.updateDetection = $root.WallItem.UserDetectionFeedback.UpdateDetection.decode(r, r.uint32());
                        break;
                    }
                case 300: {
                        m.hideDetection = $root.WallItem.UserDetectionFeedback.HideDetection.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserDetectionFeedback message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.UserDetectionFeedback} UserDetectionFeedback
         */
        UserDetectionFeedback.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.UserDetectionFeedback)
                return d;
            var m = new $root.WallItem.UserDetectionFeedback();
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            if (d.submittedByUserId != null) {
                m.submittedByUserId = String(d.submittedByUserId);
            }
            if (d.createDetection != null) {
                if (typeof d.createDetection !== "object")
                    throw TypeError(".WallItem.UserDetectionFeedback.createDetection: object expected");
                m.createDetection = $root.WallItem.UserDetectionFeedback.CreateDetection.fromObject(d.createDetection);
            }
            if (d.updateDetection != null) {
                if (typeof d.updateDetection !== "object")
                    throw TypeError(".WallItem.UserDetectionFeedback.updateDetection: object expected");
                m.updateDetection = $root.WallItem.UserDetectionFeedback.UpdateDetection.fromObject(d.updateDetection);
            }
            if (d.hideDetection != null) {
                if (typeof d.hideDetection !== "object")
                    throw TypeError(".WallItem.UserDetectionFeedback.hideDetection: object expected");
                m.hideDetection = $root.WallItem.UserDetectionFeedback.HideDetection.fromObject(d.hideDetection);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserDetectionFeedback message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {WallItem.UserDetectionFeedback} m UserDetectionFeedback
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserDetectionFeedback.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.streemshotId = "";
                d.submittedByUserId = "";
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            if (m.submittedByUserId != null && m.hasOwnProperty("submittedByUserId")) {
                d.submittedByUserId = m.submittedByUserId;
            }
            if (m.createDetection != null && m.hasOwnProperty("createDetection")) {
                d.createDetection = $root.WallItem.UserDetectionFeedback.CreateDetection.toObject(m.createDetection, o);
                if (o.oneofs)
                    d.type = "createDetection";
            }
            if (m.updateDetection != null && m.hasOwnProperty("updateDetection")) {
                d.updateDetection = $root.WallItem.UserDetectionFeedback.UpdateDetection.toObject(m.updateDetection, o);
                if (o.oneofs)
                    d.type = "updateDetection";
            }
            if (m.hideDetection != null && m.hasOwnProperty("hideDetection")) {
                d.hideDetection = $root.WallItem.UserDetectionFeedback.HideDetection.toObject(m.hideDetection, o);
                if (o.oneofs)
                    d.type = "hideDetection";
            }
            return d;
        };

        /**
         * Converts this UserDetectionFeedback to JSON.
         * @function toJSON
         * @memberof WallItem.UserDetectionFeedback
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserDetectionFeedback.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserDetectionFeedback
         * @function getTypeUrl
         * @memberof WallItem.UserDetectionFeedback
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserDetectionFeedback.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.UserDetectionFeedback";
        };

        UserDetectionFeedback.CreateDetection = (function() {

            /**
             * Properties of a CreateDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @interface ICreateDetection
             * @property {string|null} [description] CreateDetection description
             * @property {streem.api.Detection.Type|null} [type] CreateDetection type
             */

            /**
             * Constructs a new CreateDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @classdesc Represents a CreateDetection.
             * @implements ICreateDetection
             * @constructor
             * @param {WallItem.UserDetectionFeedback.ICreateDetection=} [p] Properties to set
             */
            function CreateDetection(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CreateDetection description.
             * @member {string} description
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @instance
             */
            CreateDetection.prototype.description = "";

            /**
             * CreateDetection type.
             * @member {streem.api.Detection.Type} type
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @instance
             */
            CreateDetection.prototype.type = 0;

            /**
             * Creates a new CreateDetection instance using the specified properties.
             * @function create
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.ICreateDetection=} [properties] Properties to set
             * @returns {WallItem.UserDetectionFeedback.CreateDetection} CreateDetection instance
             */
            CreateDetection.create = function create(properties) {
                return new CreateDetection(properties);
            };

            /**
             * Encodes the specified CreateDetection message. Does not implicitly {@link WallItem.UserDetectionFeedback.CreateDetection.verify|verify} messages.
             * @function encode
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.ICreateDetection} m CreateDetection message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateDetection.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(10).string(m.description);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(16).int32(m.type);
                return w;
            };

            /**
             * Decodes a CreateDetection message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.UserDetectionFeedback.CreateDetection} CreateDetection
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateDetection.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.UserDetectionFeedback.CreateDetection();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.description = r.string();
                            break;
                        }
                    case 2: {
                            m.type = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CreateDetection message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.UserDetectionFeedback.CreateDetection} CreateDetection
             */
            CreateDetection.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.UserDetectionFeedback.CreateDetection)
                    return d;
                var m = new $root.WallItem.UserDetectionFeedback.CreateDetection();
                if (d.description != null) {
                    m.description = String(d.description);
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "DETECTION_TYPE_INVALID":
                case 0:
                    m.type = 0;
                    break;
                case "DETECTION_TYPE_LOGO":
                case 1:
                    m.type = 1;
                    break;
                case "DETECTION_TYPE_LABEL":
                case 2:
                    m.type = 2;
                    break;
                case "DETECTION_TYPE_TEXT":
                case 3:
                    m.type = 3;
                    break;
                case "DETECTION_TYPE_FULL_TEXT":
                case 4:
                    m.type = 4;
                    break;
                case "DETECTION_TYPE_SAFE_SEARCH":
                case 5:
                    m.type = 5;
                    break;
                case "DETECTION_TYPE_SERIAL":
                case 6:
                    m.type = 6;
                    break;
                case "DETECTION_TYPE_MODEL":
                case 7:
                    m.type = 7;
                    break;
                case "DETECTION_TYPE_OBJECT":
                case 8:
                    m.type = 8;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a CreateDetection message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.CreateDetection} m CreateDetection
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateDetection.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.description = "";
                    d.type = o.enums === String ? "DETECTION_TYPE_INVALID" : 0;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.streem.api.Detection.Type[m.type] === undefined ? m.type : $root.streem.api.Detection.Type[m.type] : m.type;
                }
                return d;
            };

            /**
             * Converts this CreateDetection to JSON.
             * @function toJSON
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateDetection.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateDetection
             * @function getTypeUrl
             * @memberof WallItem.UserDetectionFeedback.CreateDetection
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateDetection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.UserDetectionFeedback.CreateDetection";
            };

            return CreateDetection;
        })();

        UserDetectionFeedback.UpdateDetection = (function() {

            /**
             * Properties of an UpdateDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @interface IUpdateDetection
             * @property {string|null} [detectionId] UpdateDetection detectionId
             * @property {string|null} [description] UpdateDetection description
             */

            /**
             * Constructs a new UpdateDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @classdesc Represents an UpdateDetection.
             * @implements IUpdateDetection
             * @constructor
             * @param {WallItem.UserDetectionFeedback.IUpdateDetection=} [p] Properties to set
             */
            function UpdateDetection(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UpdateDetection detectionId.
             * @member {string} detectionId
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @instance
             */
            UpdateDetection.prototype.detectionId = "";

            /**
             * UpdateDetection description.
             * @member {string} description
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @instance
             */
            UpdateDetection.prototype.description = "";

            /**
             * Creates a new UpdateDetection instance using the specified properties.
             * @function create
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.IUpdateDetection=} [properties] Properties to set
             * @returns {WallItem.UserDetectionFeedback.UpdateDetection} UpdateDetection instance
             */
            UpdateDetection.create = function create(properties) {
                return new UpdateDetection(properties);
            };

            /**
             * Encodes the specified UpdateDetection message. Does not implicitly {@link WallItem.UserDetectionFeedback.UpdateDetection.verify|verify} messages.
             * @function encode
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.IUpdateDetection} m UpdateDetection message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDetection.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.detectionId != null && Object.hasOwnProperty.call(m, "detectionId"))
                    w.uint32(10).string(m.detectionId);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(18).string(m.description);
                return w;
            };

            /**
             * Decodes an UpdateDetection message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.UserDetectionFeedback.UpdateDetection} UpdateDetection
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDetection.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.UserDetectionFeedback.UpdateDetection();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.detectionId = r.string();
                            break;
                        }
                    case 2: {
                            m.description = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UpdateDetection message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.UserDetectionFeedback.UpdateDetection} UpdateDetection
             */
            UpdateDetection.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.UserDetectionFeedback.UpdateDetection)
                    return d;
                var m = new $root.WallItem.UserDetectionFeedback.UpdateDetection();
                if (d.detectionId != null) {
                    m.detectionId = String(d.detectionId);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                return m;
            };

            /**
             * Creates a plain object from an UpdateDetection message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.UpdateDetection} m UpdateDetection
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDetection.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.detectionId = "";
                    d.description = "";
                }
                if (m.detectionId != null && m.hasOwnProperty("detectionId")) {
                    d.detectionId = m.detectionId;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                return d;
            };

            /**
             * Converts this UpdateDetection to JSON.
             * @function toJSON
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDetection.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateDetection
             * @function getTypeUrl
             * @memberof WallItem.UserDetectionFeedback.UpdateDetection
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateDetection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.UserDetectionFeedback.UpdateDetection";
            };

            return UpdateDetection;
        })();

        UserDetectionFeedback.HideDetection = (function() {

            /**
             * Properties of a HideDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @interface IHideDetection
             * @property {string|null} [detectionId] HideDetection detectionId
             */

            /**
             * Constructs a new HideDetection.
             * @memberof WallItem.UserDetectionFeedback
             * @classdesc Represents a HideDetection.
             * @implements IHideDetection
             * @constructor
             * @param {WallItem.UserDetectionFeedback.IHideDetection=} [p] Properties to set
             */
            function HideDetection(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * HideDetection detectionId.
             * @member {string} detectionId
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @instance
             */
            HideDetection.prototype.detectionId = "";

            /**
             * Creates a new HideDetection instance using the specified properties.
             * @function create
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.IHideDetection=} [properties] Properties to set
             * @returns {WallItem.UserDetectionFeedback.HideDetection} HideDetection instance
             */
            HideDetection.create = function create(properties) {
                return new HideDetection(properties);
            };

            /**
             * Encodes the specified HideDetection message. Does not implicitly {@link WallItem.UserDetectionFeedback.HideDetection.verify|verify} messages.
             * @function encode
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.IHideDetection} m HideDetection message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HideDetection.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.detectionId != null && Object.hasOwnProperty.call(m, "detectionId"))
                    w.uint32(10).string(m.detectionId);
                return w;
            };

            /**
             * Decodes a HideDetection message from the specified reader or buffer.
             * @function decode
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {WallItem.UserDetectionFeedback.HideDetection} HideDetection
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HideDetection.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.UserDetectionFeedback.HideDetection();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.detectionId = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a HideDetection message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {WallItem.UserDetectionFeedback.HideDetection} HideDetection
             */
            HideDetection.fromObject = function fromObject(d) {
                if (d instanceof $root.WallItem.UserDetectionFeedback.HideDetection)
                    return d;
                var m = new $root.WallItem.UserDetectionFeedback.HideDetection();
                if (d.detectionId != null) {
                    m.detectionId = String(d.detectionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a HideDetection message. Also converts values to other types if specified.
             * @function toObject
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {WallItem.UserDetectionFeedback.HideDetection} m HideDetection
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HideDetection.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.detectionId = "";
                }
                if (m.detectionId != null && m.hasOwnProperty("detectionId")) {
                    d.detectionId = m.detectionId;
                }
                return d;
            };

            /**
             * Converts this HideDetection to JSON.
             * @function toJSON
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HideDetection.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HideDetection
             * @function getTypeUrl
             * @memberof WallItem.UserDetectionFeedback.HideDetection
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HideDetection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/WallItem.UserDetectionFeedback.HideDetection";
            };

            return HideDetection;
        })();

        return UserDetectionFeedback;
    })();

    WallItem.ARSource = (function() {

        /**
         * Properties of a ARSource.
         * @memberof WallItem
         * @interface IARSource
         * @property {WallItem.ARSource.CameraPoseProvider|null} [cameraPoseProvider] ARSource cameraPoseProvider
         * @property {string|null} [streemshotId] ARSource streemshotId
         */

        /**
         * Constructs a new ARSource.
         * @memberof WallItem
         * @classdesc Represents a ARSource.
         * @implements IARSource
         * @constructor
         * @param {WallItem.IARSource=} [p] Properties to set
         */
        function ARSource(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ARSource cameraPoseProvider.
         * @member {WallItem.ARSource.CameraPoseProvider} cameraPoseProvider
         * @memberof WallItem.ARSource
         * @instance
         */
        ARSource.prototype.cameraPoseProvider = 0;

        /**
         * ARSource streemshotId.
         * @member {string} streemshotId
         * @memberof WallItem.ARSource
         * @instance
         */
        ARSource.prototype.streemshotId = "";

        /**
         * Creates a new ARSource instance using the specified properties.
         * @function create
         * @memberof WallItem.ARSource
         * @static
         * @param {WallItem.IARSource=} [properties] Properties to set
         * @returns {WallItem.ARSource} ARSource instance
         */
        ARSource.create = function create(properties) {
            return new ARSource(properties);
        };

        /**
         * Encodes the specified ARSource message. Does not implicitly {@link WallItem.ARSource.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ARSource
         * @static
         * @param {WallItem.IARSource} m ARSource message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ARSource.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.cameraPoseProvider != null && Object.hasOwnProperty.call(m, "cameraPoseProvider"))
                w.uint32(8).int32(m.cameraPoseProvider);
            if (m.streemshotId != null && Object.hasOwnProperty.call(m, "streemshotId"))
                w.uint32(26).string(m.streemshotId);
            return w;
        };

        /**
         * Decodes a ARSource message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ARSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ARSource} ARSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ARSource.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ARSource();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.cameraPoseProvider = r.int32();
                        break;
                    }
                case 3: {
                        m.streemshotId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ARSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ARSource
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ARSource} ARSource
         */
        ARSource.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ARSource)
                return d;
            var m = new $root.WallItem.ARSource();
            switch (d.cameraPoseProvider) {
            default:
                if (typeof d.cameraPoseProvider === "number") {
                    m.cameraPoseProvider = d.cameraPoseProvider;
                    break;
                }
                break;
            case "TWILIO_DATA_TRACK":
            case 0:
                m.cameraPoseProvider = 0;
                break;
            case "STREEMSHOT_CAMERA_POSE":
            case 1:
                m.cameraPoseProvider = 1;
                break;
            }
            if (d.streemshotId != null) {
                m.streemshotId = String(d.streemshotId);
            }
            return m;
        };

        /**
         * Creates a plain object from a ARSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ARSource
         * @static
         * @param {WallItem.ARSource} m ARSource
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ARSource.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.cameraPoseProvider = o.enums === String ? "TWILIO_DATA_TRACK" : 0;
                d.streemshotId = "";
            }
            if (m.cameraPoseProvider != null && m.hasOwnProperty("cameraPoseProvider")) {
                d.cameraPoseProvider = o.enums === String ? $root.WallItem.ARSource.CameraPoseProvider[m.cameraPoseProvider] === undefined ? m.cameraPoseProvider : $root.WallItem.ARSource.CameraPoseProvider[m.cameraPoseProvider] : m.cameraPoseProvider;
            }
            if (m.streemshotId != null && m.hasOwnProperty("streemshotId")) {
                d.streemshotId = m.streemshotId;
            }
            return d;
        };

        /**
         * Converts this ARSource to JSON.
         * @function toJSON
         * @memberof WallItem.ARSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ARSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ARSource
         * @function getTypeUrl
         * @memberof WallItem.ARSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ARSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ARSource";
        };

        /**
         * CameraPoseProvider enum.
         * @name WallItem.ARSource.CameraPoseProvider
         * @enum {number}
         * @property {number} TWILIO_DATA_TRACK=0 TWILIO_DATA_TRACK value
         * @property {number} STREEMSHOT_CAMERA_POSE=1 STREEMSHOT_CAMERA_POSE value
         */
        ARSource.CameraPoseProvider = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TWILIO_DATA_TRACK"] = 0;
            values[valuesById[1] = "STREEMSHOT_CAMERA_POSE"] = 1;
            return values;
        })();

        return ARSource;
    })();

    WallItem.ArSessionStatus = (function() {

        /**
         * Properties of an ArSessionStatus.
         * @memberof WallItem
         * @interface IArSessionStatus
         * @property {streem.room.ar.CameraTrackingStatus|null} [cameraTrackingStatus] ArSessionStatus cameraTrackingStatus
         * @property {streem.room.ar.CameraTrackingPausedReason|null} [cameraTrackingPausedReason] ArSessionStatus cameraTrackingPausedReason
         * @property {streem.room.ar.WorldMappingStatus|null} [worldMappingStatus] ArSessionStatus worldMappingStatus
         */

        /**
         * Constructs a new ArSessionStatus.
         * @memberof WallItem
         * @classdesc Represents an ArSessionStatus.
         * @implements IArSessionStatus
         * @constructor
         * @param {WallItem.IArSessionStatus=} [p] Properties to set
         */
        function ArSessionStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArSessionStatus cameraTrackingStatus.
         * @member {streem.room.ar.CameraTrackingStatus} cameraTrackingStatus
         * @memberof WallItem.ArSessionStatus
         * @instance
         */
        ArSessionStatus.prototype.cameraTrackingStatus = 0;

        /**
         * ArSessionStatus cameraTrackingPausedReason.
         * @member {streem.room.ar.CameraTrackingPausedReason} cameraTrackingPausedReason
         * @memberof WallItem.ArSessionStatus
         * @instance
         */
        ArSessionStatus.prototype.cameraTrackingPausedReason = 0;

        /**
         * ArSessionStatus worldMappingStatus.
         * @member {streem.room.ar.WorldMappingStatus} worldMappingStatus
         * @memberof WallItem.ArSessionStatus
         * @instance
         */
        ArSessionStatus.prototype.worldMappingStatus = 0;

        /**
         * Creates a new ArSessionStatus instance using the specified properties.
         * @function create
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {WallItem.IArSessionStatus=} [properties] Properties to set
         * @returns {WallItem.ArSessionStatus} ArSessionStatus instance
         */
        ArSessionStatus.create = function create(properties) {
            return new ArSessionStatus(properties);
        };

        /**
         * Encodes the specified ArSessionStatus message. Does not implicitly {@link WallItem.ArSessionStatus.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {WallItem.IArSessionStatus} m ArSessionStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArSessionStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.cameraTrackingStatus != null && Object.hasOwnProperty.call(m, "cameraTrackingStatus"))
                w.uint32(8).int32(m.cameraTrackingStatus);
            if (m.cameraTrackingPausedReason != null && Object.hasOwnProperty.call(m, "cameraTrackingPausedReason"))
                w.uint32(16).int32(m.cameraTrackingPausedReason);
            if (m.worldMappingStatus != null && Object.hasOwnProperty.call(m, "worldMappingStatus"))
                w.uint32(24).int32(m.worldMappingStatus);
            return w;
        };

        /**
         * Decodes an ArSessionStatus message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArSessionStatus} ArSessionStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArSessionStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArSessionStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.cameraTrackingStatus = r.int32();
                        break;
                    }
                case 2: {
                        m.cameraTrackingPausedReason = r.int32();
                        break;
                    }
                case 3: {
                        m.worldMappingStatus = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArSessionStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArSessionStatus} ArSessionStatus
         */
        ArSessionStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArSessionStatus)
                return d;
            var m = new $root.WallItem.ArSessionStatus();
            switch (d.cameraTrackingStatus) {
            default:
                if (typeof d.cameraTrackingStatus === "number") {
                    m.cameraTrackingStatus = d.cameraTrackingStatus;
                    break;
                }
                break;
            case "CAMERA_TRACKING_STATUS_STOPPED":
            case 0:
                m.cameraTrackingStatus = 0;
                break;
            case "CAMERA_TRACKING_STATUS_NORMAL":
            case 1:
                m.cameraTrackingStatus = 1;
                break;
            case "CAMERA_TRACKING_STATUS_PAUSED":
            case 2:
                m.cameraTrackingStatus = 2;
                break;
            }
            switch (d.cameraTrackingPausedReason) {
            default:
                if (typeof d.cameraTrackingPausedReason === "number") {
                    m.cameraTrackingPausedReason = d.cameraTrackingPausedReason;
                    break;
                }
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_UNKNOWN":
            case 0:
                m.cameraTrackingPausedReason = 0;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_INITIALIZING":
            case 1:
                m.cameraTrackingPausedReason = 1;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_RELOCALIZING":
            case 2:
                m.cameraTrackingPausedReason = 2;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_EXCESSIVE_MOTION":
            case 3:
                m.cameraTrackingPausedReason = 3;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_FEATURES":
            case 4:
                m.cameraTrackingPausedReason = 4;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_LIGHT":
            case 5:
                m.cameraTrackingPausedReason = 5;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_CAMERA_UNAVAILABLE":
            case 6:
                m.cameraTrackingPausedReason = 6;
                break;
            case "CAMERA_TRACKING_PAUSED_REASON_BAD_STATE":
            case 7:
                m.cameraTrackingPausedReason = 7;
                break;
            }
            switch (d.worldMappingStatus) {
            default:
                if (typeof d.worldMappingStatus === "number") {
                    m.worldMappingStatus = d.worldMappingStatus;
                    break;
                }
                break;
            case "WORLD_MAPPING_STATUS_UNKNOWN":
            case 0:
                m.worldMappingStatus = 0;
                break;
            case "WORLD_MAPPING_STATUS_INITIALIZING":
            case 1:
                m.worldMappingStatus = 1;
                break;
            case "WORLD_MAPPING_STATUS_LIMITED":
            case 2:
                m.worldMappingStatus = 2;
                break;
            case "WORLD_MAPPING_STATUS_EXTENDING":
            case 3:
                m.worldMappingStatus = 3;
                break;
            case "WORLD_MAPPING_STATUS_MAPPED":
            case 4:
                m.worldMappingStatus = 4;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from an ArSessionStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {WallItem.ArSessionStatus} m ArSessionStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArSessionStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.cameraTrackingStatus = o.enums === String ? "CAMERA_TRACKING_STATUS_STOPPED" : 0;
                d.cameraTrackingPausedReason = o.enums === String ? "CAMERA_TRACKING_PAUSED_REASON_UNKNOWN" : 0;
                d.worldMappingStatus = o.enums === String ? "WORLD_MAPPING_STATUS_UNKNOWN" : 0;
            }
            if (m.cameraTrackingStatus != null && m.hasOwnProperty("cameraTrackingStatus")) {
                d.cameraTrackingStatus = o.enums === String ? $root.streem.room.ar.CameraTrackingStatus[m.cameraTrackingStatus] === undefined ? m.cameraTrackingStatus : $root.streem.room.ar.CameraTrackingStatus[m.cameraTrackingStatus] : m.cameraTrackingStatus;
            }
            if (m.cameraTrackingPausedReason != null && m.hasOwnProperty("cameraTrackingPausedReason")) {
                d.cameraTrackingPausedReason = o.enums === String ? $root.streem.room.ar.CameraTrackingPausedReason[m.cameraTrackingPausedReason] === undefined ? m.cameraTrackingPausedReason : $root.streem.room.ar.CameraTrackingPausedReason[m.cameraTrackingPausedReason] : m.cameraTrackingPausedReason;
            }
            if (m.worldMappingStatus != null && m.hasOwnProperty("worldMappingStatus")) {
                d.worldMappingStatus = o.enums === String ? $root.streem.room.ar.WorldMappingStatus[m.worldMappingStatus] === undefined ? m.worldMappingStatus : $root.streem.room.ar.WorldMappingStatus[m.worldMappingStatus] : m.worldMappingStatus;
            }
            return d;
        };

        /**
         * Converts this ArSessionStatus to JSON.
         * @function toJSON
         * @memberof WallItem.ArSessionStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArSessionStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArSessionStatus
         * @function getTypeUrl
         * @memberof WallItem.ArSessionStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArSessionStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArSessionStatus";
        };

        return ArSessionStatus;
    })();

    WallItem.ArScanningRequest = (function() {

        /**
         * Properties of an ArScanningRequest.
         * @memberof WallItem
         * @interface IArScanningRequest
         * @property {string|null} [forUserId] ArScanningRequest forUserId
         * @property {string|null} [forDeviceId] ArScanningRequest forDeviceId
         * @property {streem.room.ar.ITrackableDefinition|null} [trackableDefinition] ArScanningRequest trackableDefinition
         */

        /**
         * Constructs a new ArScanningRequest.
         * @memberof WallItem
         * @classdesc Represents an ArScanningRequest.
         * @implements IArScanningRequest
         * @constructor
         * @param {WallItem.IArScanningRequest=} [p] Properties to set
         */
        function ArScanningRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArScanningRequest forUserId.
         * @member {string} forUserId
         * @memberof WallItem.ArScanningRequest
         * @instance
         */
        ArScanningRequest.prototype.forUserId = "";

        /**
         * ArScanningRequest forDeviceId.
         * @member {string} forDeviceId
         * @memberof WallItem.ArScanningRequest
         * @instance
         */
        ArScanningRequest.prototype.forDeviceId = "";

        /**
         * ArScanningRequest trackableDefinition.
         * @member {streem.room.ar.ITrackableDefinition|null|undefined} trackableDefinition
         * @memberof WallItem.ArScanningRequest
         * @instance
         */
        ArScanningRequest.prototype.trackableDefinition = null;

        /**
         * Creates a new ArScanningRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {WallItem.IArScanningRequest=} [properties] Properties to set
         * @returns {WallItem.ArScanningRequest} ArScanningRequest instance
         */
        ArScanningRequest.create = function create(properties) {
            return new ArScanningRequest(properties);
        };

        /**
         * Encodes the specified ArScanningRequest message. Does not implicitly {@link WallItem.ArScanningRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {WallItem.IArScanningRequest} m ArScanningRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArScanningRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.forUserId != null && Object.hasOwnProperty.call(m, "forUserId"))
                w.uint32(10).string(m.forUserId);
            if (m.forDeviceId != null && Object.hasOwnProperty.call(m, "forDeviceId"))
                w.uint32(18).string(m.forDeviceId);
            if (m.trackableDefinition != null && Object.hasOwnProperty.call(m, "trackableDefinition"))
                $root.streem.room.ar.TrackableDefinition.encode(m.trackableDefinition, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an ArScanningRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArScanningRequest} ArScanningRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArScanningRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArScanningRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.forUserId = r.string();
                        break;
                    }
                case 2: {
                        m.forDeviceId = r.string();
                        break;
                    }
                case 3: {
                        m.trackableDefinition = $root.streem.room.ar.TrackableDefinition.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArScanningRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArScanningRequest} ArScanningRequest
         */
        ArScanningRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArScanningRequest)
                return d;
            var m = new $root.WallItem.ArScanningRequest();
            if (d.forUserId != null) {
                m.forUserId = String(d.forUserId);
            }
            if (d.forDeviceId != null) {
                m.forDeviceId = String(d.forDeviceId);
            }
            if (d.trackableDefinition != null) {
                if (typeof d.trackableDefinition !== "object")
                    throw TypeError(".WallItem.ArScanningRequest.trackableDefinition: object expected");
                m.trackableDefinition = $root.streem.room.ar.TrackableDefinition.fromObject(d.trackableDefinition);
            }
            return m;
        };

        /**
         * Creates a plain object from an ArScanningRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {WallItem.ArScanningRequest} m ArScanningRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArScanningRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.forUserId = "";
                d.forDeviceId = "";
                d.trackableDefinition = null;
            }
            if (m.forUserId != null && m.hasOwnProperty("forUserId")) {
                d.forUserId = m.forUserId;
            }
            if (m.forDeviceId != null && m.hasOwnProperty("forDeviceId")) {
                d.forDeviceId = m.forDeviceId;
            }
            if (m.trackableDefinition != null && m.hasOwnProperty("trackableDefinition")) {
                d.trackableDefinition = $root.streem.room.ar.TrackableDefinition.toObject(m.trackableDefinition, o);
            }
            return d;
        };

        /**
         * Converts this ArScanningRequest to JSON.
         * @function toJSON
         * @memberof WallItem.ArScanningRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArScanningRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArScanningRequest
         * @function getTypeUrl
         * @memberof WallItem.ArScanningRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArScanningRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArScanningRequest";
        };

        return ArScanningRequest;
    })();

    WallItem.ArScanningStatus = (function() {

        /**
         * Properties of an ArScanningStatus.
         * @memberof WallItem
         * @interface IArScanningStatus
         * @property {string|null} [inResponseToWallItemId] ArScanningStatus inResponseToWallItemId
         * @property {streem.room.ar.TrackableScanningStatus|null} [status] ArScanningStatus status
         * @property {StreamingMessageProvider|null} [trackableUpdatesProvider] ArScanningStatus trackableUpdatesProvider
         */

        /**
         * Constructs a new ArScanningStatus.
         * @memberof WallItem
         * @classdesc Represents an ArScanningStatus.
         * @implements IArScanningStatus
         * @constructor
         * @param {WallItem.IArScanningStatus=} [p] Properties to set
         */
        function ArScanningStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArScanningStatus inResponseToWallItemId.
         * @member {string} inResponseToWallItemId
         * @memberof WallItem.ArScanningStatus
         * @instance
         */
        ArScanningStatus.prototype.inResponseToWallItemId = "";

        /**
         * ArScanningStatus status.
         * @member {streem.room.ar.TrackableScanningStatus} status
         * @memberof WallItem.ArScanningStatus
         * @instance
         */
        ArScanningStatus.prototype.status = 0;

        /**
         * ArScanningStatus trackableUpdatesProvider.
         * @member {StreamingMessageProvider} trackableUpdatesProvider
         * @memberof WallItem.ArScanningStatus
         * @instance
         */
        ArScanningStatus.prototype.trackableUpdatesProvider = 0;

        /**
         * Creates a new ArScanningStatus instance using the specified properties.
         * @function create
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {WallItem.IArScanningStatus=} [properties] Properties to set
         * @returns {WallItem.ArScanningStatus} ArScanningStatus instance
         */
        ArScanningStatus.create = function create(properties) {
            return new ArScanningStatus(properties);
        };

        /**
         * Encodes the specified ArScanningStatus message. Does not implicitly {@link WallItem.ArScanningStatus.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {WallItem.IArScanningStatus} m ArScanningStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArScanningStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.inResponseToWallItemId != null && Object.hasOwnProperty.call(m, "inResponseToWallItemId"))
                w.uint32(10).string(m.inResponseToWallItemId);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(16).int32(m.status);
            if (m.trackableUpdatesProvider != null && Object.hasOwnProperty.call(m, "trackableUpdatesProvider"))
                w.uint32(24).int32(m.trackableUpdatesProvider);
            return w;
        };

        /**
         * Decodes an ArScanningStatus message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArScanningStatus} ArScanningStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArScanningStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArScanningStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.inResponseToWallItemId = r.string();
                        break;
                    }
                case 2: {
                        m.status = r.int32();
                        break;
                    }
                case 3: {
                        m.trackableUpdatesProvider = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArScanningStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArScanningStatus} ArScanningStatus
         */
        ArScanningStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArScanningStatus)
                return d;
            var m = new $root.WallItem.ArScanningStatus();
            if (d.inResponseToWallItemId != null) {
                m.inResponseToWallItemId = String(d.inResponseToWallItemId);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "TRACKABLE_SCANNING_STATUS_INVALID":
            case 0:
                m.status = 0;
                break;
            case "TRACKABLE_SCANNING_STATUS_ERROR":
            case 1:
                m.status = 1;
                break;
            case "TRACKABLE_SCANNING_STATUS_SCANNING":
            case 2:
                m.status = 2;
                break;
            }
            switch (d.trackableUpdatesProvider) {
            default:
                if (typeof d.trackableUpdatesProvider === "number") {
                    m.trackableUpdatesProvider = d.trackableUpdatesProvider;
                    break;
                }
                break;
            case "STREAMING_MESSAGE_PROVIDER_INVALID":
            case 0:
                m.trackableUpdatesProvider = 0;
                break;
            case "STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM":
            case 1:
                m.trackableUpdatesProvider = 1;
                break;
            case "STREAMING_MESSAGE_PROVIDER_DATA_TRACK":
            case 2:
                m.trackableUpdatesProvider = 2;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from an ArScanningStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {WallItem.ArScanningStatus} m ArScanningStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArScanningStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.inResponseToWallItemId = "";
                d.status = o.enums === String ? "TRACKABLE_SCANNING_STATUS_INVALID" : 0;
                d.trackableUpdatesProvider = o.enums === String ? "STREAMING_MESSAGE_PROVIDER_INVALID" : 0;
            }
            if (m.inResponseToWallItemId != null && m.hasOwnProperty("inResponseToWallItemId")) {
                d.inResponseToWallItemId = m.inResponseToWallItemId;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.streem.room.ar.TrackableScanningStatus[m.status] === undefined ? m.status : $root.streem.room.ar.TrackableScanningStatus[m.status] : m.status;
            }
            if (m.trackableUpdatesProvider != null && m.hasOwnProperty("trackableUpdatesProvider")) {
                d.trackableUpdatesProvider = o.enums === String ? $root.StreamingMessageProvider[m.trackableUpdatesProvider] === undefined ? m.trackableUpdatesProvider : $root.StreamingMessageProvider[m.trackableUpdatesProvider] : m.trackableUpdatesProvider;
            }
            return d;
        };

        /**
         * Converts this ArScanningStatus to JSON.
         * @function toJSON
         * @memberof WallItem.ArScanningStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArScanningStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArScanningStatus
         * @function getTypeUrl
         * @memberof WallItem.ArScanningStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArScanningStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArScanningStatus";
        };

        return ArScanningStatus;
    })();

    WallItem.ArAnchorRequest = (function() {

        /**
         * Properties of an ArAnchorRequest.
         * @memberof WallItem
         * @interface IArAnchorRequest
         * @property {string|null} [forUserId] ArAnchorRequest forUserId
         * @property {string|null} [forDeviceId] ArAnchorRequest forDeviceId
         * @property {streem.room.ar.TrackableType|null} [trackableType] ArAnchorRequest trackableType
         * @property {Uint8Array|null} [trackableId] ArAnchorRequest trackableId
         * @property {string|null} [anchorId] ArAnchorRequest anchorId
         * @property {streem.api.IPose|null} [requestedPose] ArAnchorRequest requestedPose
         */

        /**
         * Constructs a new ArAnchorRequest.
         * @memberof WallItem
         * @classdesc Represents an ArAnchorRequest.
         * @implements IArAnchorRequest
         * @constructor
         * @param {WallItem.IArAnchorRequest=} [p] Properties to set
         */
        function ArAnchorRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArAnchorRequest forUserId.
         * @member {string} forUserId
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.forUserId = "";

        /**
         * ArAnchorRequest forDeviceId.
         * @member {string} forDeviceId
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.forDeviceId = "";

        /**
         * ArAnchorRequest trackableType.
         * @member {streem.room.ar.TrackableType} trackableType
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.trackableType = 0;

        /**
         * ArAnchorRequest trackableId.
         * @member {Uint8Array} trackableId
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.trackableId = $util.newBuffer([]);

        /**
         * ArAnchorRequest anchorId.
         * @member {string} anchorId
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.anchorId = "";

        /**
         * ArAnchorRequest requestedPose.
         * @member {streem.api.IPose|null|undefined} requestedPose
         * @memberof WallItem.ArAnchorRequest
         * @instance
         */
        ArAnchorRequest.prototype.requestedPose = null;

        /**
         * Creates a new ArAnchorRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {WallItem.IArAnchorRequest=} [properties] Properties to set
         * @returns {WallItem.ArAnchorRequest} ArAnchorRequest instance
         */
        ArAnchorRequest.create = function create(properties) {
            return new ArAnchorRequest(properties);
        };

        /**
         * Encodes the specified ArAnchorRequest message. Does not implicitly {@link WallItem.ArAnchorRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {WallItem.IArAnchorRequest} m ArAnchorRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArAnchorRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.forUserId != null && Object.hasOwnProperty.call(m, "forUserId"))
                w.uint32(10).string(m.forUserId);
            if (m.forDeviceId != null && Object.hasOwnProperty.call(m, "forDeviceId"))
                w.uint32(18).string(m.forDeviceId);
            if (m.trackableType != null && Object.hasOwnProperty.call(m, "trackableType"))
                w.uint32(24).int32(m.trackableType);
            if (m.trackableId != null && Object.hasOwnProperty.call(m, "trackableId"))
                w.uint32(34).bytes(m.trackableId);
            if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
                w.uint32(42).string(m.anchorId);
            if (m.requestedPose != null && Object.hasOwnProperty.call(m, "requestedPose"))
                $root.streem.api.Pose.encode(m.requestedPose, w.uint32(50).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an ArAnchorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArAnchorRequest} ArAnchorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArAnchorRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArAnchorRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.forUserId = r.string();
                        break;
                    }
                case 2: {
                        m.forDeviceId = r.string();
                        break;
                    }
                case 3: {
                        m.trackableType = r.int32();
                        break;
                    }
                case 4: {
                        m.trackableId = r.bytes();
                        break;
                    }
                case 5: {
                        m.anchorId = r.string();
                        break;
                    }
                case 6: {
                        m.requestedPose = $root.streem.api.Pose.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArAnchorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArAnchorRequest} ArAnchorRequest
         */
        ArAnchorRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArAnchorRequest)
                return d;
            var m = new $root.WallItem.ArAnchorRequest();
            if (d.forUserId != null) {
                m.forUserId = String(d.forUserId);
            }
            if (d.forDeviceId != null) {
                m.forDeviceId = String(d.forDeviceId);
            }
            switch (d.trackableType) {
            default:
                if (typeof d.trackableType === "number") {
                    m.trackableType = d.trackableType;
                    break;
                }
                break;
            case "TRACKABLE_TYPE_INVALID":
            case 0:
                m.trackableType = 0;
                break;
            case "TRACKABLE_TYPE_MARKER_2D":
            case 1:
                m.trackableType = 1;
                break;
            case "TRACKABLE_TYPE_MARKER_3D":
            case 2:
                m.trackableType = 2;
                break;
            case "TRACKABLE_TYPE_PLANE":
            case 3:
                m.trackableType = 3;
                break;
            case "TRACKABLE_TYPE_FEATURE_POINT":
            case 4:
                m.trackableType = 4;
                break;
            case "TRACKABLE_TYPE_MESH":
            case 5:
                m.trackableType = 5;
                break;
            case "TRACKABLE_TYPE_STATIC":
            case 6:
                m.trackableType = 6;
                break;
            }
            if (d.trackableId != null) {
                if (typeof d.trackableId === "string")
                    $util.base64.decode(d.trackableId, m.trackableId = $util.newBuffer($util.base64.length(d.trackableId)), 0);
                else if (d.trackableId.length >= 0)
                    m.trackableId = d.trackableId;
            }
            if (d.anchorId != null) {
                m.anchorId = String(d.anchorId);
            }
            if (d.requestedPose != null) {
                if (typeof d.requestedPose !== "object")
                    throw TypeError(".WallItem.ArAnchorRequest.requestedPose: object expected");
                m.requestedPose = $root.streem.api.Pose.fromObject(d.requestedPose);
            }
            return m;
        };

        /**
         * Creates a plain object from an ArAnchorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {WallItem.ArAnchorRequest} m ArAnchorRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArAnchorRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.forUserId = "";
                d.forDeviceId = "";
                d.trackableType = o.enums === String ? "TRACKABLE_TYPE_INVALID" : 0;
                if (o.bytes === String)
                    d.trackableId = "";
                else {
                    d.trackableId = [];
                    if (o.bytes !== Array)
                        d.trackableId = $util.newBuffer(d.trackableId);
                }
                d.anchorId = "";
                d.requestedPose = null;
            }
            if (m.forUserId != null && m.hasOwnProperty("forUserId")) {
                d.forUserId = m.forUserId;
            }
            if (m.forDeviceId != null && m.hasOwnProperty("forDeviceId")) {
                d.forDeviceId = m.forDeviceId;
            }
            if (m.trackableType != null && m.hasOwnProperty("trackableType")) {
                d.trackableType = o.enums === String ? $root.streem.room.ar.TrackableType[m.trackableType] === undefined ? m.trackableType : $root.streem.room.ar.TrackableType[m.trackableType] : m.trackableType;
            }
            if (m.trackableId != null && m.hasOwnProperty("trackableId")) {
                d.trackableId = o.bytes === String ? $util.base64.encode(m.trackableId, 0, m.trackableId.length) : o.bytes === Array ? Array.prototype.slice.call(m.trackableId) : m.trackableId;
            }
            if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
                d.anchorId = m.anchorId;
            }
            if (m.requestedPose != null && m.hasOwnProperty("requestedPose")) {
                d.requestedPose = $root.streem.api.Pose.toObject(m.requestedPose, o);
            }
            return d;
        };

        /**
         * Converts this ArAnchorRequest to JSON.
         * @function toJSON
         * @memberof WallItem.ArAnchorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArAnchorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArAnchorRequest
         * @function getTypeUrl
         * @memberof WallItem.ArAnchorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArAnchorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArAnchorRequest";
        };

        return ArAnchorRequest;
    })();

    WallItem.ArAnchorStatus = (function() {

        /**
         * Properties of an ArAnchorStatus.
         * @memberof WallItem
         * @interface IArAnchorStatus
         * @property {string|null} [anchorId] ArAnchorStatus anchorId
         * @property {streem.room.ar.AnchorTrackingStatus|null} [trackingStatus] ArAnchorStatus trackingStatus
         * @property {StreamingMessageProvider|null} [anchorUpdatesProvider] ArAnchorStatus anchorUpdatesProvider
         */

        /**
         * Constructs a new ArAnchorStatus.
         * @memberof WallItem
         * @classdesc Represents an ArAnchorStatus.
         * @implements IArAnchorStatus
         * @constructor
         * @param {WallItem.IArAnchorStatus=} [p] Properties to set
         */
        function ArAnchorStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArAnchorStatus anchorId.
         * @member {string} anchorId
         * @memberof WallItem.ArAnchorStatus
         * @instance
         */
        ArAnchorStatus.prototype.anchorId = "";

        /**
         * ArAnchorStatus trackingStatus.
         * @member {streem.room.ar.AnchorTrackingStatus} trackingStatus
         * @memberof WallItem.ArAnchorStatus
         * @instance
         */
        ArAnchorStatus.prototype.trackingStatus = 0;

        /**
         * ArAnchorStatus anchorUpdatesProvider.
         * @member {StreamingMessageProvider} anchorUpdatesProvider
         * @memberof WallItem.ArAnchorStatus
         * @instance
         */
        ArAnchorStatus.prototype.anchorUpdatesProvider = 0;

        /**
         * Creates a new ArAnchorStatus instance using the specified properties.
         * @function create
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {WallItem.IArAnchorStatus=} [properties] Properties to set
         * @returns {WallItem.ArAnchorStatus} ArAnchorStatus instance
         */
        ArAnchorStatus.create = function create(properties) {
            return new ArAnchorStatus(properties);
        };

        /**
         * Encodes the specified ArAnchorStatus message. Does not implicitly {@link WallItem.ArAnchorStatus.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {WallItem.IArAnchorStatus} m ArAnchorStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArAnchorStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
                w.uint32(10).string(m.anchorId);
            if (m.trackingStatus != null && Object.hasOwnProperty.call(m, "trackingStatus"))
                w.uint32(16).int32(m.trackingStatus);
            if (m.anchorUpdatesProvider != null && Object.hasOwnProperty.call(m, "anchorUpdatesProvider"))
                w.uint32(24).int32(m.anchorUpdatesProvider);
            return w;
        };

        /**
         * Decodes an ArAnchorStatus message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArAnchorStatus} ArAnchorStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArAnchorStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArAnchorStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.anchorId = r.string();
                        break;
                    }
                case 2: {
                        m.trackingStatus = r.int32();
                        break;
                    }
                case 3: {
                        m.anchorUpdatesProvider = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArAnchorStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArAnchorStatus} ArAnchorStatus
         */
        ArAnchorStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArAnchorStatus)
                return d;
            var m = new $root.WallItem.ArAnchorStatus();
            if (d.anchorId != null) {
                m.anchorId = String(d.anchorId);
            }
            switch (d.trackingStatus) {
            default:
                if (typeof d.trackingStatus === "number") {
                    m.trackingStatus = d.trackingStatus;
                    break;
                }
                break;
            case "ANCHOR_TRACKING_STATUS_INVALID":
            case 0:
                m.trackingStatus = 0;
                break;
            case "ANCHOR_TRACKING_STATUS_ERROR":
            case 1:
                m.trackingStatus = 1;
                break;
            case "ANCHOR_TRACKING_STATUS_NORMAL":
            case 2:
                m.trackingStatus = 2;
                break;
            case "ANCHOR_TRACKING_STATUS_PAUSED":
            case 3:
                m.trackingStatus = 3;
                break;
            case "ANCHOR_TRACKING_STATUS_STOPPED":
            case 4:
                m.trackingStatus = 4;
                break;
            }
            switch (d.anchorUpdatesProvider) {
            default:
                if (typeof d.anchorUpdatesProvider === "number") {
                    m.anchorUpdatesProvider = d.anchorUpdatesProvider;
                    break;
                }
                break;
            case "STREAMING_MESSAGE_PROVIDER_INVALID":
            case 0:
                m.anchorUpdatesProvider = 0;
                break;
            case "STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM":
            case 1:
                m.anchorUpdatesProvider = 1;
                break;
            case "STREAMING_MESSAGE_PROVIDER_DATA_TRACK":
            case 2:
                m.anchorUpdatesProvider = 2;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from an ArAnchorStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {WallItem.ArAnchorStatus} m ArAnchorStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArAnchorStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.anchorId = "";
                d.trackingStatus = o.enums === String ? "ANCHOR_TRACKING_STATUS_INVALID" : 0;
                d.anchorUpdatesProvider = o.enums === String ? "STREAMING_MESSAGE_PROVIDER_INVALID" : 0;
            }
            if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
                d.anchorId = m.anchorId;
            }
            if (m.trackingStatus != null && m.hasOwnProperty("trackingStatus")) {
                d.trackingStatus = o.enums === String ? $root.streem.room.ar.AnchorTrackingStatus[m.trackingStatus] === undefined ? m.trackingStatus : $root.streem.room.ar.AnchorTrackingStatus[m.trackingStatus] : m.trackingStatus;
            }
            if (m.anchorUpdatesProvider != null && m.hasOwnProperty("anchorUpdatesProvider")) {
                d.anchorUpdatesProvider = o.enums === String ? $root.StreamingMessageProvider[m.anchorUpdatesProvider] === undefined ? m.anchorUpdatesProvider : $root.StreamingMessageProvider[m.anchorUpdatesProvider] : m.anchorUpdatesProvider;
            }
            return d;
        };

        /**
         * Converts this ArAnchorStatus to JSON.
         * @function toJSON
         * @memberof WallItem.ArAnchorStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArAnchorStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArAnchorStatus
         * @function getTypeUrl
         * @memberof WallItem.ArAnchorStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArAnchorStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArAnchorStatus";
        };

        return ArAnchorStatus;
    })();

    WallItem.StaticAnchorStatus = (function() {

        /**
         * Properties of a StaticAnchorStatus.
         * @memberof WallItem
         * @interface IStaticAnchorStatus
         * @property {string|null} [anchorId] StaticAnchorStatus anchorId
         */

        /**
         * Constructs a new StaticAnchorStatus.
         * @memberof WallItem
         * @classdesc Represents a StaticAnchorStatus.
         * @implements IStaticAnchorStatus
         * @constructor
         * @param {WallItem.IStaticAnchorStatus=} [p] Properties to set
         */
        function StaticAnchorStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StaticAnchorStatus anchorId.
         * @member {string} anchorId
         * @memberof WallItem.StaticAnchorStatus
         * @instance
         */
        StaticAnchorStatus.prototype.anchorId = "";

        /**
         * Creates a new StaticAnchorStatus instance using the specified properties.
         * @function create
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {WallItem.IStaticAnchorStatus=} [properties] Properties to set
         * @returns {WallItem.StaticAnchorStatus} StaticAnchorStatus instance
         */
        StaticAnchorStatus.create = function create(properties) {
            return new StaticAnchorStatus(properties);
        };

        /**
         * Encodes the specified StaticAnchorStatus message. Does not implicitly {@link WallItem.StaticAnchorStatus.verify|verify} messages.
         * @function encode
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {WallItem.IStaticAnchorStatus} m StaticAnchorStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticAnchorStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
                w.uint32(10).string(m.anchorId);
            return w;
        };

        /**
         * Decodes a StaticAnchorStatus message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.StaticAnchorStatus} StaticAnchorStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticAnchorStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.StaticAnchorStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.anchorId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StaticAnchorStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.StaticAnchorStatus} StaticAnchorStatus
         */
        StaticAnchorStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.StaticAnchorStatus)
                return d;
            var m = new $root.WallItem.StaticAnchorStatus();
            if (d.anchorId != null) {
                m.anchorId = String(d.anchorId);
            }
            return m;
        };

        /**
         * Creates a plain object from a StaticAnchorStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {WallItem.StaticAnchorStatus} m StaticAnchorStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StaticAnchorStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.anchorId = "";
            }
            if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
                d.anchorId = m.anchorId;
            }
            return d;
        };

        /**
         * Converts this StaticAnchorStatus to JSON.
         * @function toJSON
         * @memberof WallItem.StaticAnchorStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StaticAnchorStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StaticAnchorStatus
         * @function getTypeUrl
         * @memberof WallItem.StaticAnchorStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StaticAnchorStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.StaticAnchorStatus";
        };

        return StaticAnchorStatus;
    })();

    WallItem.ArTrackableStatus = (function() {

        /**
         * Properties of an ArTrackableStatus.
         * @memberof WallItem
         * @interface IArTrackableStatus
         * @property {Uint8Array|null} [trackableId] ArTrackableStatus trackableId
         * @property {streem.room.ar.TrackableStatus|null} [trackableStatus] ArTrackableStatus trackableStatus
         * @property {StreamingMessageProvider|null} [trackableUpdatesProvider] ArTrackableStatus trackableUpdatesProvider
         * @property {streem.room.ar.TrackableType|null} [trackableType] ArTrackableStatus trackableType
         * @property {streem.room.ar.ITrackableDefinition|null} [trackableDefinition] ArTrackableStatus trackableDefinition
         */

        /**
         * Constructs a new ArTrackableStatus.
         * @memberof WallItem
         * @classdesc Represents an ArTrackableStatus.
         * @implements IArTrackableStatus
         * @constructor
         * @param {WallItem.IArTrackableStatus=} [p] Properties to set
         */
        function ArTrackableStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArTrackableStatus trackableId.
         * @member {Uint8Array} trackableId
         * @memberof WallItem.ArTrackableStatus
         * @instance
         */
        ArTrackableStatus.prototype.trackableId = $util.newBuffer([]);

        /**
         * ArTrackableStatus trackableStatus.
         * @member {streem.room.ar.TrackableStatus} trackableStatus
         * @memberof WallItem.ArTrackableStatus
         * @instance
         */
        ArTrackableStatus.prototype.trackableStatus = 0;

        /**
         * ArTrackableStatus trackableUpdatesProvider.
         * @member {StreamingMessageProvider} trackableUpdatesProvider
         * @memberof WallItem.ArTrackableStatus
         * @instance
         */
        ArTrackableStatus.prototype.trackableUpdatesProvider = 0;

        /**
         * ArTrackableStatus trackableType.
         * @member {streem.room.ar.TrackableType} trackableType
         * @memberof WallItem.ArTrackableStatus
         * @instance
         */
        ArTrackableStatus.prototype.trackableType = 0;

        /**
         * ArTrackableStatus trackableDefinition.
         * @member {streem.room.ar.ITrackableDefinition|null|undefined} trackableDefinition
         * @memberof WallItem.ArTrackableStatus
         * @instance
         */
        ArTrackableStatus.prototype.trackableDefinition = null;

        /**
         * Creates a new ArTrackableStatus instance using the specified properties.
         * @function create
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {WallItem.IArTrackableStatus=} [properties] Properties to set
         * @returns {WallItem.ArTrackableStatus} ArTrackableStatus instance
         */
        ArTrackableStatus.create = function create(properties) {
            return new ArTrackableStatus(properties);
        };

        /**
         * Encodes the specified ArTrackableStatus message. Does not implicitly {@link WallItem.ArTrackableStatus.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {WallItem.IArTrackableStatus} m ArTrackableStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArTrackableStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.trackableId != null && Object.hasOwnProperty.call(m, "trackableId"))
                w.uint32(10).bytes(m.trackableId);
            if (m.trackableStatus != null && Object.hasOwnProperty.call(m, "trackableStatus"))
                w.uint32(16).int32(m.trackableStatus);
            if (m.trackableUpdatesProvider != null && Object.hasOwnProperty.call(m, "trackableUpdatesProvider"))
                w.uint32(24).int32(m.trackableUpdatesProvider);
            if (m.trackableType != null && Object.hasOwnProperty.call(m, "trackableType"))
                w.uint32(32).int32(m.trackableType);
            if (m.trackableDefinition != null && Object.hasOwnProperty.call(m, "trackableDefinition"))
                $root.streem.room.ar.TrackableDefinition.encode(m.trackableDefinition, w.uint32(42).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an ArTrackableStatus message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArTrackableStatus} ArTrackableStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArTrackableStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArTrackableStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.trackableId = r.bytes();
                        break;
                    }
                case 2: {
                        m.trackableStatus = r.int32();
                        break;
                    }
                case 3: {
                        m.trackableUpdatesProvider = r.int32();
                        break;
                    }
                case 4: {
                        m.trackableType = r.int32();
                        break;
                    }
                case 5: {
                        m.trackableDefinition = $root.streem.room.ar.TrackableDefinition.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArTrackableStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArTrackableStatus} ArTrackableStatus
         */
        ArTrackableStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArTrackableStatus)
                return d;
            var m = new $root.WallItem.ArTrackableStatus();
            if (d.trackableId != null) {
                if (typeof d.trackableId === "string")
                    $util.base64.decode(d.trackableId, m.trackableId = $util.newBuffer($util.base64.length(d.trackableId)), 0);
                else if (d.trackableId.length >= 0)
                    m.trackableId = d.trackableId;
            }
            switch (d.trackableStatus) {
            default:
                if (typeof d.trackableStatus === "number") {
                    m.trackableStatus = d.trackableStatus;
                    break;
                }
                break;
            case "TRACKABLE_STATUS_INVALID":
            case 0:
                m.trackableStatus = 0;
                break;
            case "TRACKABLE_STATUS_NORMAL":
            case 1:
                m.trackableStatus = 1;
                break;
            case "TRACKABLE_STATUS_PAUSED":
            case 2:
                m.trackableStatus = 2;
                break;
            case "TRACKABLE_STATUS_STOPPED":
            case 3:
                m.trackableStatus = 3;
                break;
            }
            switch (d.trackableUpdatesProvider) {
            default:
                if (typeof d.trackableUpdatesProvider === "number") {
                    m.trackableUpdatesProvider = d.trackableUpdatesProvider;
                    break;
                }
                break;
            case "STREAMING_MESSAGE_PROVIDER_INVALID":
            case 0:
                m.trackableUpdatesProvider = 0;
                break;
            case "STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM":
            case 1:
                m.trackableUpdatesProvider = 1;
                break;
            case "STREAMING_MESSAGE_PROVIDER_DATA_TRACK":
            case 2:
                m.trackableUpdatesProvider = 2;
                break;
            }
            switch (d.trackableType) {
            default:
                if (typeof d.trackableType === "number") {
                    m.trackableType = d.trackableType;
                    break;
                }
                break;
            case "TRACKABLE_TYPE_INVALID":
            case 0:
                m.trackableType = 0;
                break;
            case "TRACKABLE_TYPE_MARKER_2D":
            case 1:
                m.trackableType = 1;
                break;
            case "TRACKABLE_TYPE_MARKER_3D":
            case 2:
                m.trackableType = 2;
                break;
            case "TRACKABLE_TYPE_PLANE":
            case 3:
                m.trackableType = 3;
                break;
            case "TRACKABLE_TYPE_FEATURE_POINT":
            case 4:
                m.trackableType = 4;
                break;
            case "TRACKABLE_TYPE_MESH":
            case 5:
                m.trackableType = 5;
                break;
            case "TRACKABLE_TYPE_STATIC":
            case 6:
                m.trackableType = 6;
                break;
            }
            if (d.trackableDefinition != null) {
                if (typeof d.trackableDefinition !== "object")
                    throw TypeError(".WallItem.ArTrackableStatus.trackableDefinition: object expected");
                m.trackableDefinition = $root.streem.room.ar.TrackableDefinition.fromObject(d.trackableDefinition);
            }
            return m;
        };

        /**
         * Creates a plain object from an ArTrackableStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {WallItem.ArTrackableStatus} m ArTrackableStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArTrackableStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if (o.bytes === String)
                    d.trackableId = "";
                else {
                    d.trackableId = [];
                    if (o.bytes !== Array)
                        d.trackableId = $util.newBuffer(d.trackableId);
                }
                d.trackableStatus = o.enums === String ? "TRACKABLE_STATUS_INVALID" : 0;
                d.trackableUpdatesProvider = o.enums === String ? "STREAMING_MESSAGE_PROVIDER_INVALID" : 0;
                d.trackableType = o.enums === String ? "TRACKABLE_TYPE_INVALID" : 0;
                d.trackableDefinition = null;
            }
            if (m.trackableId != null && m.hasOwnProperty("trackableId")) {
                d.trackableId = o.bytes === String ? $util.base64.encode(m.trackableId, 0, m.trackableId.length) : o.bytes === Array ? Array.prototype.slice.call(m.trackableId) : m.trackableId;
            }
            if (m.trackableStatus != null && m.hasOwnProperty("trackableStatus")) {
                d.trackableStatus = o.enums === String ? $root.streem.room.ar.TrackableStatus[m.trackableStatus] === undefined ? m.trackableStatus : $root.streem.room.ar.TrackableStatus[m.trackableStatus] : m.trackableStatus;
            }
            if (m.trackableUpdatesProvider != null && m.hasOwnProperty("trackableUpdatesProvider")) {
                d.trackableUpdatesProvider = o.enums === String ? $root.StreamingMessageProvider[m.trackableUpdatesProvider] === undefined ? m.trackableUpdatesProvider : $root.StreamingMessageProvider[m.trackableUpdatesProvider] : m.trackableUpdatesProvider;
            }
            if (m.trackableType != null && m.hasOwnProperty("trackableType")) {
                d.trackableType = o.enums === String ? $root.streem.room.ar.TrackableType[m.trackableType] === undefined ? m.trackableType : $root.streem.room.ar.TrackableType[m.trackableType] : m.trackableType;
            }
            if (m.trackableDefinition != null && m.hasOwnProperty("trackableDefinition")) {
                d.trackableDefinition = $root.streem.room.ar.TrackableDefinition.toObject(m.trackableDefinition, o);
            }
            return d;
        };

        /**
         * Converts this ArTrackableStatus to JSON.
         * @function toJSON
         * @memberof WallItem.ArTrackableStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArTrackableStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArTrackableStatus
         * @function getTypeUrl
         * @memberof WallItem.ArTrackableStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArTrackableStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArTrackableStatus";
        };

        return ArTrackableStatus;
    })();

    WallItem.ArtifactRequest = (function() {

        /**
         * Properties of an ArtifactRequest.
         * @memberof WallItem
         * @interface IArtifactRequest
         * @property {string|null} [artifactId] ArtifactRequest artifactId
         * @property {string|null} [requestedForUserId] ArtifactRequest requestedForUserId
         * @property {string|null} [requestedForDeviceId] ArtifactRequest requestedForDeviceId
         * @property {WallItem.ArtifactType|null} [artifactType] ArtifactRequest artifactType
         */

        /**
         * Constructs a new ArtifactRequest.
         * @memberof WallItem
         * @classdesc Represents an ArtifactRequest.
         * @implements IArtifactRequest
         * @constructor
         * @param {WallItem.IArtifactRequest=} [p] Properties to set
         */
        function ArtifactRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArtifactRequest artifactId.
         * @member {string} artifactId
         * @memberof WallItem.ArtifactRequest
         * @instance
         */
        ArtifactRequest.prototype.artifactId = "";

        /**
         * ArtifactRequest requestedForUserId.
         * @member {string} requestedForUserId
         * @memberof WallItem.ArtifactRequest
         * @instance
         */
        ArtifactRequest.prototype.requestedForUserId = "";

        /**
         * ArtifactRequest requestedForDeviceId.
         * @member {string} requestedForDeviceId
         * @memberof WallItem.ArtifactRequest
         * @instance
         */
        ArtifactRequest.prototype.requestedForDeviceId = "";

        /**
         * ArtifactRequest artifactType.
         * @member {WallItem.ArtifactType} artifactType
         * @memberof WallItem.ArtifactRequest
         * @instance
         */
        ArtifactRequest.prototype.artifactType = 0;

        /**
         * Creates a new ArtifactRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {WallItem.IArtifactRequest=} [properties] Properties to set
         * @returns {WallItem.ArtifactRequest} ArtifactRequest instance
         */
        ArtifactRequest.create = function create(properties) {
            return new ArtifactRequest(properties);
        };

        /**
         * Encodes the specified ArtifactRequest message. Does not implicitly {@link WallItem.ArtifactRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {WallItem.IArtifactRequest} m ArtifactRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArtifactRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.artifactId != null && Object.hasOwnProperty.call(m, "artifactId"))
                w.uint32(10).string(m.artifactId);
            if (m.requestedForUserId != null && Object.hasOwnProperty.call(m, "requestedForUserId"))
                w.uint32(18).string(m.requestedForUserId);
            if (m.requestedForDeviceId != null && Object.hasOwnProperty.call(m, "requestedForDeviceId"))
                w.uint32(26).string(m.requestedForDeviceId);
            if (m.artifactType != null && Object.hasOwnProperty.call(m, "artifactType"))
                w.uint32(48).int32(m.artifactType);
            return w;
        };

        /**
         * Decodes an ArtifactRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArtifactRequest} ArtifactRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArtifactRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArtifactRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.artifactId = r.string();
                        break;
                    }
                case 2: {
                        m.requestedForUserId = r.string();
                        break;
                    }
                case 3: {
                        m.requestedForDeviceId = r.string();
                        break;
                    }
                case 6: {
                        m.artifactType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArtifactRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArtifactRequest} ArtifactRequest
         */
        ArtifactRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArtifactRequest)
                return d;
            var m = new $root.WallItem.ArtifactRequest();
            if (d.artifactId != null) {
                m.artifactId = String(d.artifactId);
            }
            if (d.requestedForUserId != null) {
                m.requestedForUserId = String(d.requestedForUserId);
            }
            if (d.requestedForDeviceId != null) {
                m.requestedForDeviceId = String(d.requestedForDeviceId);
            }
            switch (d.artifactType) {
            default:
                if (typeof d.artifactType === "number") {
                    m.artifactType = d.artifactType;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.artifactType = 0;
                break;
            case "GPS_POSITION":
            case 1:
                m.artifactType = 1;
                break;
            case "RECORDING":
            case 2:
                m.artifactType = 2;
                break;
            case "STREEMSHOT":
            case 3:
                m.artifactType = 3;
                break;
            case "MESH":
            case 4:
                m.artifactType = 4;
                break;
            case "TRANSCRIPTION":
            case 5:
                m.artifactType = 5;
                break;
            case "SCENESHOT":
            case 6:
                m.artifactType = 6;
                break;
            case "LAYOUT_ESTIMATION":
            case 7:
                m.artifactType = 7;
                break;
            case "TEXTURED_MESH":
            case 8:
                m.artifactType = 8;
                break;
            case "BOOKMARK":
            case 9:
                m.artifactType = 9;
                break;
            case "ROOM_OUTCOME_REPORT":
            case 10:
                m.artifactType = 10;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from an ArtifactRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {WallItem.ArtifactRequest} m ArtifactRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArtifactRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.artifactId = "";
                d.requestedForUserId = "";
                d.requestedForDeviceId = "";
                d.artifactType = o.enums === String ? "UNKNOWN" : 0;
            }
            if (m.artifactId != null && m.hasOwnProperty("artifactId")) {
                d.artifactId = m.artifactId;
            }
            if (m.requestedForUserId != null && m.hasOwnProperty("requestedForUserId")) {
                d.requestedForUserId = m.requestedForUserId;
            }
            if (m.requestedForDeviceId != null && m.hasOwnProperty("requestedForDeviceId")) {
                d.requestedForDeviceId = m.requestedForDeviceId;
            }
            if (m.artifactType != null && m.hasOwnProperty("artifactType")) {
                d.artifactType = o.enums === String ? $root.WallItem.ArtifactType[m.artifactType] === undefined ? m.artifactType : $root.WallItem.ArtifactType[m.artifactType] : m.artifactType;
            }
            return d;
        };

        /**
         * Converts this ArtifactRequest to JSON.
         * @function toJSON
         * @memberof WallItem.ArtifactRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArtifactRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArtifactRequest
         * @function getTypeUrl
         * @memberof WallItem.ArtifactRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArtifactRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArtifactRequest";
        };

        return ArtifactRequest;
    })();

    WallItem.ArtifactCompletionRequest = (function() {

        /**
         * Properties of an ArtifactCompletionRequest.
         * @memberof WallItem
         * @interface IArtifactCompletionRequest
         * @property {string|null} [artifactId] ArtifactCompletionRequest artifactId
         */

        /**
         * Constructs a new ArtifactCompletionRequest.
         * @memberof WallItem
         * @classdesc Represents an ArtifactCompletionRequest.
         * @implements IArtifactCompletionRequest
         * @constructor
         * @param {WallItem.IArtifactCompletionRequest=} [p] Properties to set
         */
        function ArtifactCompletionRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ArtifactCompletionRequest artifactId.
         * @member {string} artifactId
         * @memberof WallItem.ArtifactCompletionRequest
         * @instance
         */
        ArtifactCompletionRequest.prototype.artifactId = "";

        /**
         * Creates a new ArtifactCompletionRequest instance using the specified properties.
         * @function create
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {WallItem.IArtifactCompletionRequest=} [properties] Properties to set
         * @returns {WallItem.ArtifactCompletionRequest} ArtifactCompletionRequest instance
         */
        ArtifactCompletionRequest.create = function create(properties) {
            return new ArtifactCompletionRequest(properties);
        };

        /**
         * Encodes the specified ArtifactCompletionRequest message. Does not implicitly {@link WallItem.ArtifactCompletionRequest.verify|verify} messages.
         * @function encode
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {WallItem.IArtifactCompletionRequest} m ArtifactCompletionRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArtifactCompletionRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.artifactId != null && Object.hasOwnProperty.call(m, "artifactId"))
                w.uint32(10).string(m.artifactId);
            return w;
        };

        /**
         * Decodes an ArtifactCompletionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.ArtifactCompletionRequest} ArtifactCompletionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArtifactCompletionRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.ArtifactCompletionRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.artifactId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ArtifactCompletionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.ArtifactCompletionRequest} ArtifactCompletionRequest
         */
        ArtifactCompletionRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.ArtifactCompletionRequest)
                return d;
            var m = new $root.WallItem.ArtifactCompletionRequest();
            if (d.artifactId != null) {
                m.artifactId = String(d.artifactId);
            }
            return m;
        };

        /**
         * Creates a plain object from an ArtifactCompletionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {WallItem.ArtifactCompletionRequest} m ArtifactCompletionRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArtifactCompletionRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.artifactId = "";
            }
            if (m.artifactId != null && m.hasOwnProperty("artifactId")) {
                d.artifactId = m.artifactId;
            }
            return d;
        };

        /**
         * Converts this ArtifactCompletionRequest to JSON.
         * @function toJSON
         * @memberof WallItem.ArtifactCompletionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArtifactCompletionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ArtifactCompletionRequest
         * @function getTypeUrl
         * @memberof WallItem.ArtifactCompletionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArtifactCompletionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.ArtifactCompletionRequest";
        };

        return ArtifactCompletionRequest;
    })();

    /**
     * ArtifactType enum.
     * @name WallItem.ArtifactType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} GPS_POSITION=1 GPS_POSITION value
     * @property {number} RECORDING=2 RECORDING value
     * @property {number} STREEMSHOT=3 STREEMSHOT value
     * @property {number} MESH=4 MESH value
     * @property {number} TRANSCRIPTION=5 TRANSCRIPTION value
     * @property {number} SCENESHOT=6 SCENESHOT value
     * @property {number} LAYOUT_ESTIMATION=7 LAYOUT_ESTIMATION value
     * @property {number} TEXTURED_MESH=8 TEXTURED_MESH value
     * @property {number} BOOKMARK=9 BOOKMARK value
     * @property {number} ROOM_OUTCOME_REPORT=10 ROOM_OUTCOME_REPORT value
     */
    WallItem.ArtifactType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "GPS_POSITION"] = 1;
        values[valuesById[2] = "RECORDING"] = 2;
        values[valuesById[3] = "STREEMSHOT"] = 3;
        values[valuesById[4] = "MESH"] = 4;
        values[valuesById[5] = "TRANSCRIPTION"] = 5;
        values[valuesById[6] = "SCENESHOT"] = 6;
        values[valuesById[7] = "LAYOUT_ESTIMATION"] = 7;
        values[valuesById[8] = "TEXTURED_MESH"] = 8;
        values[valuesById[9] = "BOOKMARK"] = 9;
        values[valuesById[10] = "ROOM_OUTCOME_REPORT"] = 10;
        return values;
    })();

    WallItem.Artifact = (function() {

        /**
         * Properties of an Artifact.
         * @memberof WallItem
         * @interface IArtifact
         * @property {string|null} [inResponseToWallItemId] Artifact inResponseToWallItemId
         * @property {WallItem.Artifact.Status|null} [status] Artifact status
         * @property {WallItem.ArtifactType|null} [artifactType] Artifact artifactType
         * @property {WallItem.IGPSPosition|null} [gpsPosition] Artifact gpsPosition
         * @property {WallItem.IRecording|null} [recording] Artifact recording
         * @property {WallItem.IStreemshot|null} [streemshot] Artifact streemshot
         * @property {WallItem.IMesh|null} [mesh] Artifact mesh
         * @property {streem.api.Artifact.ITranscription|null} [transcription] Artifact transcription
         * @property {WallItem.ISceneshot|null} [sceneshot] Artifact sceneshot
         * @property {streem.api.Artifact.ILayoutEstimation|null} [layoutEstimation] Artifact layoutEstimation
         * @property {streem.api.Artifact.ITexturedMesh|null} [texturedMesh] Artifact texturedMesh
         * @property {streem.api.Artifact.IBookmark|null} [bookmark] Artifact bookmark
         * @property {streem.api.Artifact.IRoomOutcomeReport|null} [roomOutcomeReport] Artifact roomOutcomeReport
         */

        /**
         * Constructs a new Artifact.
         * @memberof WallItem
         * @classdesc Represents an Artifact.
         * @implements IArtifact
         * @constructor
         * @param {WallItem.IArtifact=} [p] Properties to set
         */
        function Artifact(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Artifact inResponseToWallItemId.
         * @member {string} inResponseToWallItemId
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.inResponseToWallItemId = "";

        /**
         * Artifact status.
         * @member {WallItem.Artifact.Status} status
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.status = 0;

        /**
         * Artifact artifactType.
         * @member {WallItem.ArtifactType} artifactType
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.artifactType = 0;

        /**
         * Artifact gpsPosition.
         * @member {WallItem.IGPSPosition|null|undefined} gpsPosition
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.gpsPosition = null;

        /**
         * Artifact recording.
         * @member {WallItem.IRecording|null|undefined} recording
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.recording = null;

        /**
         * Artifact streemshot.
         * @member {WallItem.IStreemshot|null|undefined} streemshot
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.streemshot = null;

        /**
         * Artifact mesh.
         * @member {WallItem.IMesh|null|undefined} mesh
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.mesh = null;

        /**
         * Artifact transcription.
         * @member {streem.api.Artifact.ITranscription|null|undefined} transcription
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.transcription = null;

        /**
         * Artifact sceneshot.
         * @member {WallItem.ISceneshot|null|undefined} sceneshot
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.sceneshot = null;

        /**
         * Artifact layoutEstimation.
         * @member {streem.api.Artifact.ILayoutEstimation|null|undefined} layoutEstimation
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.layoutEstimation = null;

        /**
         * Artifact texturedMesh.
         * @member {streem.api.Artifact.ITexturedMesh|null|undefined} texturedMesh
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.texturedMesh = null;

        /**
         * Artifact bookmark.
         * @member {streem.api.Artifact.IBookmark|null|undefined} bookmark
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.bookmark = null;

        /**
         * Artifact roomOutcomeReport.
         * @member {streem.api.Artifact.IRoomOutcomeReport|null|undefined} roomOutcomeReport
         * @memberof WallItem.Artifact
         * @instance
         */
        Artifact.prototype.roomOutcomeReport = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Artifact type.
         * @member {"gpsPosition"|"recording"|"streemshot"|"mesh"|"transcription"|"sceneshot"|"layoutEstimation"|"texturedMesh"|"bookmark"|"roomOutcomeReport"|undefined} type
         * @memberof WallItem.Artifact
         * @instance
         */
        Object.defineProperty(Artifact.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["gpsPosition", "recording", "streemshot", "mesh", "transcription", "sceneshot", "layoutEstimation", "texturedMesh", "bookmark", "roomOutcomeReport"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Artifact instance using the specified properties.
         * @function create
         * @memberof WallItem.Artifact
         * @static
         * @param {WallItem.IArtifact=} [properties] Properties to set
         * @returns {WallItem.Artifact} Artifact instance
         */
        Artifact.create = function create(properties) {
            return new Artifact(properties);
        };

        /**
         * Encodes the specified Artifact message. Does not implicitly {@link WallItem.Artifact.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Artifact
         * @static
         * @param {WallItem.IArtifact} m Artifact message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Artifact.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.inResponseToWallItemId != null && Object.hasOwnProperty.call(m, "inResponseToWallItemId"))
                w.uint32(10).string(m.inResponseToWallItemId);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(16).int32(m.status);
            if (m.artifactType != null && Object.hasOwnProperty.call(m, "artifactType"))
                w.uint32(24).int32(m.artifactType);
            if (m.gpsPosition != null && Object.hasOwnProperty.call(m, "gpsPosition"))
                $root.WallItem.GPSPosition.encode(m.gpsPosition, w.uint32(802).fork()).ldelim();
            if (m.recording != null && Object.hasOwnProperty.call(m, "recording"))
                $root.WallItem.Recording.encode(m.recording, w.uint32(1602).fork()).ldelim();
            if (m.streemshot != null && Object.hasOwnProperty.call(m, "streemshot"))
                $root.WallItem.Streemshot.encode(m.streemshot, w.uint32(2402).fork()).ldelim();
            if (m.mesh != null && Object.hasOwnProperty.call(m, "mesh"))
                $root.WallItem.Mesh.encode(m.mesh, w.uint32(3202).fork()).ldelim();
            if (m.transcription != null && Object.hasOwnProperty.call(m, "transcription"))
                $root.streem.api.Artifact.Transcription.encode(m.transcription, w.uint32(4002).fork()).ldelim();
            if (m.sceneshot != null && Object.hasOwnProperty.call(m, "sceneshot"))
                $root.WallItem.Sceneshot.encode(m.sceneshot, w.uint32(4802).fork()).ldelim();
            if (m.layoutEstimation != null && Object.hasOwnProperty.call(m, "layoutEstimation"))
                $root.streem.api.Artifact.LayoutEstimation.encode(m.layoutEstimation, w.uint32(5602).fork()).ldelim();
            if (m.texturedMesh != null && Object.hasOwnProperty.call(m, "texturedMesh"))
                $root.streem.api.Artifact.TexturedMesh.encode(m.texturedMesh, w.uint32(6402).fork()).ldelim();
            if (m.bookmark != null && Object.hasOwnProperty.call(m, "bookmark"))
                $root.streem.api.Artifact.Bookmark.encode(m.bookmark, w.uint32(7202).fork()).ldelim();
            if (m.roomOutcomeReport != null && Object.hasOwnProperty.call(m, "roomOutcomeReport"))
                $root.streem.api.Artifact.RoomOutcomeReport.encode(m.roomOutcomeReport, w.uint32(8002).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an Artifact message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Artifact
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Artifact} Artifact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Artifact.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Artifact();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.inResponseToWallItemId = r.string();
                        break;
                    }
                case 2: {
                        m.status = r.int32();
                        break;
                    }
                case 3: {
                        m.artifactType = r.int32();
                        break;
                    }
                case 100: {
                        m.gpsPosition = $root.WallItem.GPSPosition.decode(r, r.uint32());
                        break;
                    }
                case 200: {
                        m.recording = $root.WallItem.Recording.decode(r, r.uint32());
                        break;
                    }
                case 300: {
                        m.streemshot = $root.WallItem.Streemshot.decode(r, r.uint32());
                        break;
                    }
                case 400: {
                        m.mesh = $root.WallItem.Mesh.decode(r, r.uint32());
                        break;
                    }
                case 500: {
                        m.transcription = $root.streem.api.Artifact.Transcription.decode(r, r.uint32());
                        break;
                    }
                case 600: {
                        m.sceneshot = $root.WallItem.Sceneshot.decode(r, r.uint32());
                        break;
                    }
                case 700: {
                        m.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.decode(r, r.uint32());
                        break;
                    }
                case 800: {
                        m.texturedMesh = $root.streem.api.Artifact.TexturedMesh.decode(r, r.uint32());
                        break;
                    }
                case 900: {
                        m.bookmark = $root.streem.api.Artifact.Bookmark.decode(r, r.uint32());
                        break;
                    }
                case 1000: {
                        m.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Artifact message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Artifact
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Artifact} Artifact
         */
        Artifact.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Artifact)
                return d;
            var m = new $root.WallItem.Artifact();
            if (d.inResponseToWallItemId != null) {
                m.inResponseToWallItemId = String(d.inResponseToWallItemId);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "FULFILLED":
            case 1:
                m.status = 1;
                break;
            case "REJECTED":
            case 2:
                m.status = 2;
                break;
            case "ERROR":
            case 3:
                m.status = 3;
                break;
            case "STARTED":
            case 4:
                m.status = 4;
                break;
            case "CAPTURED":
            case 5:
                m.status = 5;
                break;
            }
            switch (d.artifactType) {
            default:
                if (typeof d.artifactType === "number") {
                    m.artifactType = d.artifactType;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.artifactType = 0;
                break;
            case "GPS_POSITION":
            case 1:
                m.artifactType = 1;
                break;
            case "RECORDING":
            case 2:
                m.artifactType = 2;
                break;
            case "STREEMSHOT":
            case 3:
                m.artifactType = 3;
                break;
            case "MESH":
            case 4:
                m.artifactType = 4;
                break;
            case "TRANSCRIPTION":
            case 5:
                m.artifactType = 5;
                break;
            case "SCENESHOT":
            case 6:
                m.artifactType = 6;
                break;
            case "LAYOUT_ESTIMATION":
            case 7:
                m.artifactType = 7;
                break;
            case "TEXTURED_MESH":
            case 8:
                m.artifactType = 8;
                break;
            case "BOOKMARK":
            case 9:
                m.artifactType = 9;
                break;
            case "ROOM_OUTCOME_REPORT":
            case 10:
                m.artifactType = 10;
                break;
            }
            if (d.gpsPosition != null) {
                if (typeof d.gpsPosition !== "object")
                    throw TypeError(".WallItem.Artifact.gpsPosition: object expected");
                m.gpsPosition = $root.WallItem.GPSPosition.fromObject(d.gpsPosition);
            }
            if (d.recording != null) {
                if (typeof d.recording !== "object")
                    throw TypeError(".WallItem.Artifact.recording: object expected");
                m.recording = $root.WallItem.Recording.fromObject(d.recording);
            }
            if (d.streemshot != null) {
                if (typeof d.streemshot !== "object")
                    throw TypeError(".WallItem.Artifact.streemshot: object expected");
                m.streemshot = $root.WallItem.Streemshot.fromObject(d.streemshot);
            }
            if (d.mesh != null) {
                if (typeof d.mesh !== "object")
                    throw TypeError(".WallItem.Artifact.mesh: object expected");
                m.mesh = $root.WallItem.Mesh.fromObject(d.mesh);
            }
            if (d.transcription != null) {
                if (typeof d.transcription !== "object")
                    throw TypeError(".WallItem.Artifact.transcription: object expected");
                m.transcription = $root.streem.api.Artifact.Transcription.fromObject(d.transcription);
            }
            if (d.sceneshot != null) {
                if (typeof d.sceneshot !== "object")
                    throw TypeError(".WallItem.Artifact.sceneshot: object expected");
                m.sceneshot = $root.WallItem.Sceneshot.fromObject(d.sceneshot);
            }
            if (d.layoutEstimation != null) {
                if (typeof d.layoutEstimation !== "object")
                    throw TypeError(".WallItem.Artifact.layoutEstimation: object expected");
                m.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.fromObject(d.layoutEstimation);
            }
            if (d.texturedMesh != null) {
                if (typeof d.texturedMesh !== "object")
                    throw TypeError(".WallItem.Artifact.texturedMesh: object expected");
                m.texturedMesh = $root.streem.api.Artifact.TexturedMesh.fromObject(d.texturedMesh);
            }
            if (d.bookmark != null) {
                if (typeof d.bookmark !== "object")
                    throw TypeError(".WallItem.Artifact.bookmark: object expected");
                m.bookmark = $root.streem.api.Artifact.Bookmark.fromObject(d.bookmark);
            }
            if (d.roomOutcomeReport != null) {
                if (typeof d.roomOutcomeReport !== "object")
                    throw TypeError(".WallItem.Artifact.roomOutcomeReport: object expected");
                m.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.fromObject(d.roomOutcomeReport);
            }
            return m;
        };

        /**
         * Creates a plain object from an Artifact message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Artifact
         * @static
         * @param {WallItem.Artifact} m Artifact
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Artifact.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.inResponseToWallItemId = "";
                d.status = o.enums === String ? "UNKNOWN" : 0;
                d.artifactType = o.enums === String ? "UNKNOWN" : 0;
            }
            if (m.inResponseToWallItemId != null && m.hasOwnProperty("inResponseToWallItemId")) {
                d.inResponseToWallItemId = m.inResponseToWallItemId;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.WallItem.Artifact.Status[m.status] === undefined ? m.status : $root.WallItem.Artifact.Status[m.status] : m.status;
            }
            if (m.artifactType != null && m.hasOwnProperty("artifactType")) {
                d.artifactType = o.enums === String ? $root.WallItem.ArtifactType[m.artifactType] === undefined ? m.artifactType : $root.WallItem.ArtifactType[m.artifactType] : m.artifactType;
            }
            if (m.gpsPosition != null && m.hasOwnProperty("gpsPosition")) {
                d.gpsPosition = $root.WallItem.GPSPosition.toObject(m.gpsPosition, o);
                if (o.oneofs)
                    d.type = "gpsPosition";
            }
            if (m.recording != null && m.hasOwnProperty("recording")) {
                d.recording = $root.WallItem.Recording.toObject(m.recording, o);
                if (o.oneofs)
                    d.type = "recording";
            }
            if (m.streemshot != null && m.hasOwnProperty("streemshot")) {
                d.streemshot = $root.WallItem.Streemshot.toObject(m.streemshot, o);
                if (o.oneofs)
                    d.type = "streemshot";
            }
            if (m.mesh != null && m.hasOwnProperty("mesh")) {
                d.mesh = $root.WallItem.Mesh.toObject(m.mesh, o);
                if (o.oneofs)
                    d.type = "mesh";
            }
            if (m.transcription != null && m.hasOwnProperty("transcription")) {
                d.transcription = $root.streem.api.Artifact.Transcription.toObject(m.transcription, o);
                if (o.oneofs)
                    d.type = "transcription";
            }
            if (m.sceneshot != null && m.hasOwnProperty("sceneshot")) {
                d.sceneshot = $root.WallItem.Sceneshot.toObject(m.sceneshot, o);
                if (o.oneofs)
                    d.type = "sceneshot";
            }
            if (m.layoutEstimation != null && m.hasOwnProperty("layoutEstimation")) {
                d.layoutEstimation = $root.streem.api.Artifact.LayoutEstimation.toObject(m.layoutEstimation, o);
                if (o.oneofs)
                    d.type = "layoutEstimation";
            }
            if (m.texturedMesh != null && m.hasOwnProperty("texturedMesh")) {
                d.texturedMesh = $root.streem.api.Artifact.TexturedMesh.toObject(m.texturedMesh, o);
                if (o.oneofs)
                    d.type = "texturedMesh";
            }
            if (m.bookmark != null && m.hasOwnProperty("bookmark")) {
                d.bookmark = $root.streem.api.Artifact.Bookmark.toObject(m.bookmark, o);
                if (o.oneofs)
                    d.type = "bookmark";
            }
            if (m.roomOutcomeReport != null && m.hasOwnProperty("roomOutcomeReport")) {
                d.roomOutcomeReport = $root.streem.api.Artifact.RoomOutcomeReport.toObject(m.roomOutcomeReport, o);
                if (o.oneofs)
                    d.type = "roomOutcomeReport";
            }
            return d;
        };

        /**
         * Converts this Artifact to JSON.
         * @function toJSON
         * @memberof WallItem.Artifact
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Artifact.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Artifact
         * @function getTypeUrl
         * @memberof WallItem.Artifact
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Artifact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Artifact";
        };

        /**
         * Status enum.
         * @name WallItem.Artifact.Status
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} FULFILLED=1 FULFILLED value
         * @property {number} REJECTED=2 REJECTED value
         * @property {number} ERROR=3 ERROR value
         * @property {number} STARTED=4 STARTED value
         * @property {number} CAPTURED=5 CAPTURED value
         */
        Artifact.Status = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "FULFILLED"] = 1;
            values[valuesById[2] = "REJECTED"] = 2;
            values[valuesById[3] = "ERROR"] = 3;
            values[valuesById[4] = "STARTED"] = 4;
            values[valuesById[5] = "CAPTURED"] = 5;
            return values;
        })();

        return Artifact;
    })();

    WallItem.GPSPosition = (function() {

        /**
         * Properties of a GPSPosition.
         * @memberof WallItem
         * @interface IGPSPosition
         * @property {number|null} [latitude] GPSPosition latitude
         * @property {number|null} [longitude] GPSPosition longitude
         * @property {number|null} [accuracy] GPSPosition accuracy
         * @property {number|null} [altitude] GPSPosition altitude
         * @property {number|null} [altitudeAccuracy] GPSPosition altitudeAccuracy
         * @property {number|null} [heading] GPSPosition heading
         * @property {number|null} [speed] GPSPosition speed
         * @property {string|null} [formattedAddress] GPSPosition formattedAddress
         */

        /**
         * Constructs a new GPSPosition.
         * @memberof WallItem
         * @classdesc Represents a GPSPosition.
         * @implements IGPSPosition
         * @constructor
         * @param {WallItem.IGPSPosition=} [p] Properties to set
         */
        function GPSPosition(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GPSPosition latitude.
         * @member {number} latitude
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.latitude = 0;

        /**
         * GPSPosition longitude.
         * @member {number} longitude
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.longitude = 0;

        /**
         * GPSPosition accuracy.
         * @member {number} accuracy
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.accuracy = 0;

        /**
         * GPSPosition altitude.
         * @member {number} altitude
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.altitude = 0;

        /**
         * GPSPosition altitudeAccuracy.
         * @member {number} altitudeAccuracy
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.altitudeAccuracy = 0;

        /**
         * GPSPosition heading.
         * @member {number} heading
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.heading = 0;

        /**
         * GPSPosition speed.
         * @member {number} speed
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.speed = 0;

        /**
         * GPSPosition formattedAddress.
         * @member {string} formattedAddress
         * @memberof WallItem.GPSPosition
         * @instance
         */
        GPSPosition.prototype.formattedAddress = "";

        /**
         * Creates a new GPSPosition instance using the specified properties.
         * @function create
         * @memberof WallItem.GPSPosition
         * @static
         * @param {WallItem.IGPSPosition=} [properties] Properties to set
         * @returns {WallItem.GPSPosition} GPSPosition instance
         */
        GPSPosition.create = function create(properties) {
            return new GPSPosition(properties);
        };

        /**
         * Encodes the specified GPSPosition message. Does not implicitly {@link WallItem.GPSPosition.verify|verify} messages.
         * @function encode
         * @memberof WallItem.GPSPosition
         * @static
         * @param {WallItem.IGPSPosition} m GPSPosition message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GPSPosition.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                w.uint32(9).double(m.latitude);
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                w.uint32(17).double(m.longitude);
            if (m.accuracy != null && Object.hasOwnProperty.call(m, "accuracy"))
                w.uint32(25).double(m.accuracy);
            if (m.altitude != null && Object.hasOwnProperty.call(m, "altitude"))
                w.uint32(33).double(m.altitude);
            if (m.altitudeAccuracy != null && Object.hasOwnProperty.call(m, "altitudeAccuracy"))
                w.uint32(41).double(m.altitudeAccuracy);
            if (m.heading != null && Object.hasOwnProperty.call(m, "heading"))
                w.uint32(49).double(m.heading);
            if (m.speed != null && Object.hasOwnProperty.call(m, "speed"))
                w.uint32(57).double(m.speed);
            if (m.formattedAddress != null && Object.hasOwnProperty.call(m, "formattedAddress"))
                w.uint32(66).string(m.formattedAddress);
            return w;
        };

        /**
         * Decodes a GPSPosition message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.GPSPosition
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.GPSPosition} GPSPosition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GPSPosition.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.GPSPosition();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.latitude = r.double();
                        break;
                    }
                case 2: {
                        m.longitude = r.double();
                        break;
                    }
                case 3: {
                        m.accuracy = r.double();
                        break;
                    }
                case 4: {
                        m.altitude = r.double();
                        break;
                    }
                case 5: {
                        m.altitudeAccuracy = r.double();
                        break;
                    }
                case 6: {
                        m.heading = r.double();
                        break;
                    }
                case 7: {
                        m.speed = r.double();
                        break;
                    }
                case 8: {
                        m.formattedAddress = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a GPSPosition message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.GPSPosition
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.GPSPosition} GPSPosition
         */
        GPSPosition.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.GPSPosition)
                return d;
            var m = new $root.WallItem.GPSPosition();
            if (d.latitude != null) {
                m.latitude = Number(d.latitude);
            }
            if (d.longitude != null) {
                m.longitude = Number(d.longitude);
            }
            if (d.accuracy != null) {
                m.accuracy = Number(d.accuracy);
            }
            if (d.altitude != null) {
                m.altitude = Number(d.altitude);
            }
            if (d.altitudeAccuracy != null) {
                m.altitudeAccuracy = Number(d.altitudeAccuracy);
            }
            if (d.heading != null) {
                m.heading = Number(d.heading);
            }
            if (d.speed != null) {
                m.speed = Number(d.speed);
            }
            if (d.formattedAddress != null) {
                m.formattedAddress = String(d.formattedAddress);
            }
            return m;
        };

        /**
         * Creates a plain object from a GPSPosition message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.GPSPosition
         * @static
         * @param {WallItem.GPSPosition} m GPSPosition
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GPSPosition.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.latitude = 0;
                d.longitude = 0;
                d.accuracy = 0;
                d.altitude = 0;
                d.altitudeAccuracy = 0;
                d.heading = 0;
                d.speed = 0;
                d.formattedAddress = "";
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = o.json && !isFinite(m.latitude) ? String(m.latitude) : m.latitude;
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = o.json && !isFinite(m.longitude) ? String(m.longitude) : m.longitude;
            }
            if (m.accuracy != null && m.hasOwnProperty("accuracy")) {
                d.accuracy = o.json && !isFinite(m.accuracy) ? String(m.accuracy) : m.accuracy;
            }
            if (m.altitude != null && m.hasOwnProperty("altitude")) {
                d.altitude = o.json && !isFinite(m.altitude) ? String(m.altitude) : m.altitude;
            }
            if (m.altitudeAccuracy != null && m.hasOwnProperty("altitudeAccuracy")) {
                d.altitudeAccuracy = o.json && !isFinite(m.altitudeAccuracy) ? String(m.altitudeAccuracy) : m.altitudeAccuracy;
            }
            if (m.heading != null && m.hasOwnProperty("heading")) {
                d.heading = o.json && !isFinite(m.heading) ? String(m.heading) : m.heading;
            }
            if (m.speed != null && m.hasOwnProperty("speed")) {
                d.speed = o.json && !isFinite(m.speed) ? String(m.speed) : m.speed;
            }
            if (m.formattedAddress != null && m.hasOwnProperty("formattedAddress")) {
                d.formattedAddress = m.formattedAddress;
            }
            return d;
        };

        /**
         * Converts this GPSPosition to JSON.
         * @function toJSON
         * @memberof WallItem.GPSPosition
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GPSPosition.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GPSPosition
         * @function getTypeUrl
         * @memberof WallItem.GPSPosition
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GPSPosition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.GPSPosition";
        };

        return GPSPosition;
    })();

    WallItem.Recording = (function() {

        /**
         * Properties of a Recording.
         * @memberof WallItem
         * @interface IRecording
         * @property {string|null} [downloadUrl] Recording downloadUrl
         * @property {string|null} [storageUrl] Recording storageUrl
         * @property {string|null} [previewStorageUrl] Recording previewStorageUrl
         * @property {streem.api.Artifact.RecordingStatus|null} [recordingStatus] Recording recordingStatus
         */

        /**
         * Constructs a new Recording.
         * @memberof WallItem
         * @classdesc Represents a Recording.
         * @implements IRecording
         * @constructor
         * @param {WallItem.IRecording=} [p] Properties to set
         */
        function Recording(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Recording downloadUrl.
         * @member {string} downloadUrl
         * @memberof WallItem.Recording
         * @instance
         */
        Recording.prototype.downloadUrl = "";

        /**
         * Recording storageUrl.
         * @member {string} storageUrl
         * @memberof WallItem.Recording
         * @instance
         */
        Recording.prototype.storageUrl = "";

        /**
         * Recording previewStorageUrl.
         * @member {string} previewStorageUrl
         * @memberof WallItem.Recording
         * @instance
         */
        Recording.prototype.previewStorageUrl = "";

        /**
         * Recording recordingStatus.
         * @member {streem.api.Artifact.RecordingStatus} recordingStatus
         * @memberof WallItem.Recording
         * @instance
         */
        Recording.prototype.recordingStatus = 0;

        /**
         * Creates a new Recording instance using the specified properties.
         * @function create
         * @memberof WallItem.Recording
         * @static
         * @param {WallItem.IRecording=} [properties] Properties to set
         * @returns {WallItem.Recording} Recording instance
         */
        Recording.create = function create(properties) {
            return new Recording(properties);
        };

        /**
         * Encodes the specified Recording message. Does not implicitly {@link WallItem.Recording.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Recording
         * @static
         * @param {WallItem.IRecording} m Recording message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Recording.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.downloadUrl != null && Object.hasOwnProperty.call(m, "downloadUrl"))
                w.uint32(10).string(m.downloadUrl);
            if (m.storageUrl != null && Object.hasOwnProperty.call(m, "storageUrl"))
                w.uint32(18).string(m.storageUrl);
            if (m.previewStorageUrl != null && Object.hasOwnProperty.call(m, "previewStorageUrl"))
                w.uint32(26).string(m.previewStorageUrl);
            if (m.recordingStatus != null && Object.hasOwnProperty.call(m, "recordingStatus"))
                w.uint32(32).int32(m.recordingStatus);
            return w;
        };

        /**
         * Decodes a Recording message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Recording
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Recording} Recording
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Recording.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Recording();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.downloadUrl = r.string();
                        break;
                    }
                case 2: {
                        m.storageUrl = r.string();
                        break;
                    }
                case 3: {
                        m.previewStorageUrl = r.string();
                        break;
                    }
                case 4: {
                        m.recordingStatus = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Recording message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Recording
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Recording} Recording
         */
        Recording.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Recording)
                return d;
            var m = new $root.WallItem.Recording();
            if (d.downloadUrl != null) {
                m.downloadUrl = String(d.downloadUrl);
            }
            if (d.storageUrl != null) {
                m.storageUrl = String(d.storageUrl);
            }
            if (d.previewStorageUrl != null) {
                m.previewStorageUrl = String(d.previewStorageUrl);
            }
            switch (d.recordingStatus) {
            default:
                if (typeof d.recordingStatus === "number") {
                    m.recordingStatus = d.recordingStatus;
                    break;
                }
                break;
            case "RECORDING_STATUS_INVALID":
            case 0:
                m.recordingStatus = 0;
                break;
            case "RECORDING_STATUS_NONE":
            case 1:
                m.recordingStatus = 1;
                break;
            case "RECORDING_STATUS_PAUSED":
            case 2:
                m.recordingStatus = 2;
                break;
            case "RECORDING_STATUS_RESUMED":
            case 3:
                m.recordingStatus = 3;
                break;
            case "RECORDING_STATUS_TEMPORARY_FAILURE":
            case 4:
                m.recordingStatus = 4;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a Recording message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Recording
         * @static
         * @param {WallItem.Recording} m Recording
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Recording.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.downloadUrl = "";
                d.storageUrl = "";
                d.previewStorageUrl = "";
                d.recordingStatus = o.enums === String ? "RECORDING_STATUS_INVALID" : 0;
            }
            if (m.downloadUrl != null && m.hasOwnProperty("downloadUrl")) {
                d.downloadUrl = m.downloadUrl;
            }
            if (m.storageUrl != null && m.hasOwnProperty("storageUrl")) {
                d.storageUrl = m.storageUrl;
            }
            if (m.previewStorageUrl != null && m.hasOwnProperty("previewStorageUrl")) {
                d.previewStorageUrl = m.previewStorageUrl;
            }
            if (m.recordingStatus != null && m.hasOwnProperty("recordingStatus")) {
                d.recordingStatus = o.enums === String ? $root.streem.api.Artifact.RecordingStatus[m.recordingStatus] === undefined ? m.recordingStatus : $root.streem.api.Artifact.RecordingStatus[m.recordingStatus] : m.recordingStatus;
            }
            return d;
        };

        /**
         * Converts this Recording to JSON.
         * @function toJSON
         * @memberof WallItem.Recording
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Recording.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Recording
         * @function getTypeUrl
         * @memberof WallItem.Recording
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Recording.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Recording";
        };

        return Recording;
    })();

    WallItem.Mesh = (function() {

        /**
         * Properties of a Mesh.
         * @memberof WallItem
         * @interface IMesh
         * @property {string|null} [meshStorageUrl] Mesh meshStorageUrl
         */

        /**
         * Constructs a new Mesh.
         * @memberof WallItem
         * @classdesc Represents a Mesh.
         * @implements IMesh
         * @constructor
         * @param {WallItem.IMesh=} [p] Properties to set
         */
        function Mesh(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Mesh meshStorageUrl.
         * @member {string} meshStorageUrl
         * @memberof WallItem.Mesh
         * @instance
         */
        Mesh.prototype.meshStorageUrl = "";

        /**
         * Creates a new Mesh instance using the specified properties.
         * @function create
         * @memberof WallItem.Mesh
         * @static
         * @param {WallItem.IMesh=} [properties] Properties to set
         * @returns {WallItem.Mesh} Mesh instance
         */
        Mesh.create = function create(properties) {
            return new Mesh(properties);
        };

        /**
         * Encodes the specified Mesh message. Does not implicitly {@link WallItem.Mesh.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Mesh
         * @static
         * @param {WallItem.IMesh} m Mesh message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Mesh.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.meshStorageUrl != null && Object.hasOwnProperty.call(m, "meshStorageUrl"))
                w.uint32(10).string(m.meshStorageUrl);
            return w;
        };

        /**
         * Decodes a Mesh message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Mesh
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Mesh} Mesh
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Mesh.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Mesh();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.meshStorageUrl = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Mesh message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Mesh
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Mesh} Mesh
         */
        Mesh.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Mesh)
                return d;
            var m = new $root.WallItem.Mesh();
            if (d.meshStorageUrl != null) {
                m.meshStorageUrl = String(d.meshStorageUrl);
            }
            return m;
        };

        /**
         * Creates a plain object from a Mesh message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Mesh
         * @static
         * @param {WallItem.Mesh} m Mesh
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Mesh.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.meshStorageUrl = "";
            }
            if (m.meshStorageUrl != null && m.hasOwnProperty("meshStorageUrl")) {
                d.meshStorageUrl = m.meshStorageUrl;
            }
            return d;
        };

        /**
         * Converts this Mesh to JSON.
         * @function toJSON
         * @memberof WallItem.Mesh
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Mesh.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Mesh
         * @function getTypeUrl
         * @memberof WallItem.Mesh
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Mesh.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Mesh";
        };

        return Mesh;
    })();

    WallItem.Sceneshot = (function() {

        /**
         * Properties of a Sceneshot.
         * @memberof WallItem
         * @interface ISceneshot
         * @property {string|null} [meshSid] Sceneshot meshSid
         * @property {string|null} [previewStorageUrl] Sceneshot previewStorageUrl
         * @property {string|null} [name] Sceneshot name
         * @property {streem.api.IPose|null} [pose] Sceneshot pose
         * @property {Array.<WallItem.IARElement>|null} [arElements] Sceneshot arElements
         */

        /**
         * Constructs a new Sceneshot.
         * @memberof WallItem
         * @classdesc Represents a Sceneshot.
         * @implements ISceneshot
         * @constructor
         * @param {WallItem.ISceneshot=} [p] Properties to set
         */
        function Sceneshot(p) {
            this.arElements = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Sceneshot meshSid.
         * @member {string} meshSid
         * @memberof WallItem.Sceneshot
         * @instance
         */
        Sceneshot.prototype.meshSid = "";

        /**
         * Sceneshot previewStorageUrl.
         * @member {string} previewStorageUrl
         * @memberof WallItem.Sceneshot
         * @instance
         */
        Sceneshot.prototype.previewStorageUrl = "";

        /**
         * Sceneshot name.
         * @member {string} name
         * @memberof WallItem.Sceneshot
         * @instance
         */
        Sceneshot.prototype.name = "";

        /**
         * Sceneshot pose.
         * @member {streem.api.IPose|null|undefined} pose
         * @memberof WallItem.Sceneshot
         * @instance
         */
        Sceneshot.prototype.pose = null;

        /**
         * Sceneshot arElements.
         * @member {Array.<WallItem.IARElement>} arElements
         * @memberof WallItem.Sceneshot
         * @instance
         */
        Sceneshot.prototype.arElements = $util.emptyArray;

        /**
         * Creates a new Sceneshot instance using the specified properties.
         * @function create
         * @memberof WallItem.Sceneshot
         * @static
         * @param {WallItem.ISceneshot=} [properties] Properties to set
         * @returns {WallItem.Sceneshot} Sceneshot instance
         */
        Sceneshot.create = function create(properties) {
            return new Sceneshot(properties);
        };

        /**
         * Encodes the specified Sceneshot message. Does not implicitly {@link WallItem.Sceneshot.verify|verify} messages.
         * @function encode
         * @memberof WallItem.Sceneshot
         * @static
         * @param {WallItem.ISceneshot} m Sceneshot message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sceneshot.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.meshSid != null && Object.hasOwnProperty.call(m, "meshSid"))
                w.uint32(10).string(m.meshSid);
            if (m.previewStorageUrl != null && Object.hasOwnProperty.call(m, "previewStorageUrl"))
                w.uint32(18).string(m.previewStorageUrl);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
                $root.streem.api.Pose.encode(m.pose, w.uint32(34).fork()).ldelim();
            if (m.arElements != null && m.arElements.length) {
                for (var i = 0; i < m.arElements.length; ++i)
                    $root.WallItem.ARElement.encode(m.arElements[i], w.uint32(42).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a Sceneshot message from the specified reader or buffer.
         * @function decode
         * @memberof WallItem.Sceneshot
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WallItem.Sceneshot} Sceneshot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sceneshot.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WallItem.Sceneshot();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.meshSid = r.string();
                        break;
                    }
                case 2: {
                        m.previewStorageUrl = r.string();
                        break;
                    }
                case 3: {
                        m.name = r.string();
                        break;
                    }
                case 4: {
                        m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        if (!(m.arElements && m.arElements.length))
                            m.arElements = [];
                        m.arElements.push($root.WallItem.ARElement.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Sceneshot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WallItem.Sceneshot
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {WallItem.Sceneshot} Sceneshot
         */
        Sceneshot.fromObject = function fromObject(d) {
            if (d instanceof $root.WallItem.Sceneshot)
                return d;
            var m = new $root.WallItem.Sceneshot();
            if (d.meshSid != null) {
                m.meshSid = String(d.meshSid);
            }
            if (d.previewStorageUrl != null) {
                m.previewStorageUrl = String(d.previewStorageUrl);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.pose != null) {
                if (typeof d.pose !== "object")
                    throw TypeError(".WallItem.Sceneshot.pose: object expected");
                m.pose = $root.streem.api.Pose.fromObject(d.pose);
            }
            if (d.arElements) {
                if (!Array.isArray(d.arElements))
                    throw TypeError(".WallItem.Sceneshot.arElements: array expected");
                m.arElements = [];
                for (var i = 0; i < d.arElements.length; ++i) {
                    if (typeof d.arElements[i] !== "object")
                        throw TypeError(".WallItem.Sceneshot.arElements: object expected");
                    m.arElements[i] = $root.WallItem.ARElement.fromObject(d.arElements[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a Sceneshot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WallItem.Sceneshot
         * @static
         * @param {WallItem.Sceneshot} m Sceneshot
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sceneshot.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.arElements = [];
            }
            if (o.defaults) {
                d.meshSid = "";
                d.previewStorageUrl = "";
                d.name = "";
                d.pose = null;
            }
            if (m.meshSid != null && m.hasOwnProperty("meshSid")) {
                d.meshSid = m.meshSid;
            }
            if (m.previewStorageUrl != null && m.hasOwnProperty("previewStorageUrl")) {
                d.previewStorageUrl = m.previewStorageUrl;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.pose != null && m.hasOwnProperty("pose")) {
                d.pose = $root.streem.api.Pose.toObject(m.pose, o);
            }
            if (m.arElements && m.arElements.length) {
                d.arElements = [];
                for (var j = 0; j < m.arElements.length; ++j) {
                    d.arElements[j] = $root.WallItem.ARElement.toObject(m.arElements[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this Sceneshot to JSON.
         * @function toJSON
         * @memberof WallItem.Sceneshot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sceneshot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Sceneshot
         * @function getTypeUrl
         * @memberof WallItem.Sceneshot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Sceneshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WallItem.Sceneshot";
        };

        return Sceneshot;
    })();

    return WallItem;
})();

$root.CatalogItem = (function() {

    /**
     * Properties of a CatalogItem.
     * @exports ICatalogItem
     * @interface ICatalogItem
     * @property {string|null} [id] CatalogItem id
     * @property {boolean|null} [billboard] CatalogItem billboard
     * @property {boolean|null} [onCamera] CatalogItem onCamera
     * @property {boolean|null} [onGlass] CatalogItem onGlass
     * @property {Model3d.IItem|null} [model_3d] CatalogItem model_3d
     * @property {Image2d.IItem|null} [image_2d] CatalogItem image_2d
     * @property {Text.IItem|null} [text] CatalogItem text
     * @property {Arrow.IItem|null} [arrow] CatalogItem arrow
     * @property {Movie2d.IItem|null} [movie_2d] CatalogItem movie_2d
     * @property {PickList.IItem|null} [pickList] CatalogItem pickList
     * @property {Measure.IItem|null} [measure] CatalogItem measure
     */

    /**
     * Constructs a new CatalogItem.
     * @exports CatalogItem
     * @classdesc Represents a CatalogItem.
     * @implements ICatalogItem
     * @constructor
     * @param {ICatalogItem=} [p] Properties to set
     */
    function CatalogItem(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * CatalogItem id.
     * @member {string} id
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.id = "";

    /**
     * CatalogItem billboard.
     * @member {boolean} billboard
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.billboard = false;

    /**
     * CatalogItem onCamera.
     * @member {boolean} onCamera
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.onCamera = false;

    /**
     * CatalogItem onGlass.
     * @member {boolean} onGlass
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.onGlass = false;

    /**
     * CatalogItem model_3d.
     * @member {Model3d.IItem|null|undefined} model_3d
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.model_3d = null;

    /**
     * CatalogItem image_2d.
     * @member {Image2d.IItem|null|undefined} image_2d
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.image_2d = null;

    /**
     * CatalogItem text.
     * @member {Text.IItem|null|undefined} text
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.text = null;

    /**
     * CatalogItem arrow.
     * @member {Arrow.IItem|null|undefined} arrow
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.arrow = null;

    /**
     * CatalogItem movie_2d.
     * @member {Movie2d.IItem|null|undefined} movie_2d
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.movie_2d = null;

    /**
     * CatalogItem pickList.
     * @member {PickList.IItem|null|undefined} pickList
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.pickList = null;

    /**
     * CatalogItem measure.
     * @member {Measure.IItem|null|undefined} measure
     * @memberof CatalogItem
     * @instance
     */
    CatalogItem.prototype.measure = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * CatalogItem type.
     * @member {"model_3d"|"image_2d"|"text"|"arrow"|"movie_2d"|"pickList"|"measure"|undefined} type
     * @memberof CatalogItem
     * @instance
     */
    Object.defineProperty(CatalogItem.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["model_3d", "image_2d", "text", "arrow", "movie_2d", "pickList", "measure"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new CatalogItem instance using the specified properties.
     * @function create
     * @memberof CatalogItem
     * @static
     * @param {ICatalogItem=} [properties] Properties to set
     * @returns {CatalogItem} CatalogItem instance
     */
    CatalogItem.create = function create(properties) {
        return new CatalogItem(properties);
    };

    /**
     * Encodes the specified CatalogItem message. Does not implicitly {@link CatalogItem.verify|verify} messages.
     * @function encode
     * @memberof CatalogItem
     * @static
     * @param {ICatalogItem} m CatalogItem message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CatalogItem.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.billboard != null && Object.hasOwnProperty.call(m, "billboard"))
            w.uint32(16).bool(m.billboard);
        if (m.onCamera != null && Object.hasOwnProperty.call(m, "onCamera"))
            w.uint32(24).bool(m.onCamera);
        if (m.onGlass != null && Object.hasOwnProperty.call(m, "onGlass"))
            w.uint32(32).bool(m.onGlass);
        if (m.model_3d != null && Object.hasOwnProperty.call(m, "model_3d"))
            $root.Model3d.Item.encode(m.model_3d, w.uint32(810).fork()).ldelim();
        if (m.image_2d != null && Object.hasOwnProperty.call(m, "image_2d"))
            $root.Image2d.Item.encode(m.image_2d, w.uint32(818).fork()).ldelim();
        if (m.text != null && Object.hasOwnProperty.call(m, "text"))
            $root.Text.Item.encode(m.text, w.uint32(826).fork()).ldelim();
        if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
            $root.Arrow.Item.encode(m.arrow, w.uint32(834).fork()).ldelim();
        if (m.movie_2d != null && Object.hasOwnProperty.call(m, "movie_2d"))
            $root.Movie2d.Item.encode(m.movie_2d, w.uint32(842).fork()).ldelim();
        if (m.pickList != null && Object.hasOwnProperty.call(m, "pickList"))
            $root.PickList.Item.encode(m.pickList, w.uint32(850).fork()).ldelim();
        if (m.measure != null && Object.hasOwnProperty.call(m, "measure"))
            $root.Measure.Item.encode(m.measure, w.uint32(858).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a CatalogItem message from the specified reader or buffer.
     * @function decode
     * @memberof CatalogItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {CatalogItem} CatalogItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CatalogItem.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.CatalogItem();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.billboard = r.bool();
                    break;
                }
            case 3: {
                    m.onCamera = r.bool();
                    break;
                }
            case 4: {
                    m.onGlass = r.bool();
                    break;
                }
            case 101: {
                    m.model_3d = $root.Model3d.Item.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.image_2d = $root.Image2d.Item.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.text = $root.Text.Item.decode(r, r.uint32());
                    break;
                }
            case 104: {
                    m.arrow = $root.Arrow.Item.decode(r, r.uint32());
                    break;
                }
            case 105: {
                    m.movie_2d = $root.Movie2d.Item.decode(r, r.uint32());
                    break;
                }
            case 106: {
                    m.pickList = $root.PickList.Item.decode(r, r.uint32());
                    break;
                }
            case 107: {
                    m.measure = $root.Measure.Item.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a CatalogItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CatalogItem
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {CatalogItem} CatalogItem
     */
    CatalogItem.fromObject = function fromObject(d) {
        if (d instanceof $root.CatalogItem)
            return d;
        var m = new $root.CatalogItem();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.billboard != null) {
            m.billboard = Boolean(d.billboard);
        }
        if (d.onCamera != null) {
            m.onCamera = Boolean(d.onCamera);
        }
        if (d.onGlass != null) {
            m.onGlass = Boolean(d.onGlass);
        }
        if (d.model_3d != null) {
            if (typeof d.model_3d !== "object")
                throw TypeError(".CatalogItem.model_3d: object expected");
            m.model_3d = $root.Model3d.Item.fromObject(d.model_3d);
        }
        if (d.image_2d != null) {
            if (typeof d.image_2d !== "object")
                throw TypeError(".CatalogItem.image_2d: object expected");
            m.image_2d = $root.Image2d.Item.fromObject(d.image_2d);
        }
        if (d.text != null) {
            if (typeof d.text !== "object")
                throw TypeError(".CatalogItem.text: object expected");
            m.text = $root.Text.Item.fromObject(d.text);
        }
        if (d.arrow != null) {
            if (typeof d.arrow !== "object")
                throw TypeError(".CatalogItem.arrow: object expected");
            m.arrow = $root.Arrow.Item.fromObject(d.arrow);
        }
        if (d.movie_2d != null) {
            if (typeof d.movie_2d !== "object")
                throw TypeError(".CatalogItem.movie_2d: object expected");
            m.movie_2d = $root.Movie2d.Item.fromObject(d.movie_2d);
        }
        if (d.pickList != null) {
            if (typeof d.pickList !== "object")
                throw TypeError(".CatalogItem.pickList: object expected");
            m.pickList = $root.PickList.Item.fromObject(d.pickList);
        }
        if (d.measure != null) {
            if (typeof d.measure !== "object")
                throw TypeError(".CatalogItem.measure: object expected");
            m.measure = $root.Measure.Item.fromObject(d.measure);
        }
        return m;
    };

    /**
     * Creates a plain object from a CatalogItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CatalogItem
     * @static
     * @param {CatalogItem} m CatalogItem
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CatalogItem.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
            d.billboard = false;
            d.onCamera = false;
            d.onGlass = false;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.billboard != null && m.hasOwnProperty("billboard")) {
            d.billboard = m.billboard;
        }
        if (m.onCamera != null && m.hasOwnProperty("onCamera")) {
            d.onCamera = m.onCamera;
        }
        if (m.onGlass != null && m.hasOwnProperty("onGlass")) {
            d.onGlass = m.onGlass;
        }
        if (m.model_3d != null && m.hasOwnProperty("model_3d")) {
            d.model_3d = $root.Model3d.Item.toObject(m.model_3d, o);
            if (o.oneofs)
                d.type = "model_3d";
        }
        if (m.image_2d != null && m.hasOwnProperty("image_2d")) {
            d.image_2d = $root.Image2d.Item.toObject(m.image_2d, o);
            if (o.oneofs)
                d.type = "image_2d";
        }
        if (m.text != null && m.hasOwnProperty("text")) {
            d.text = $root.Text.Item.toObject(m.text, o);
            if (o.oneofs)
                d.type = "text";
        }
        if (m.arrow != null && m.hasOwnProperty("arrow")) {
            d.arrow = $root.Arrow.Item.toObject(m.arrow, o);
            if (o.oneofs)
                d.type = "arrow";
        }
        if (m.movie_2d != null && m.hasOwnProperty("movie_2d")) {
            d.movie_2d = $root.Movie2d.Item.toObject(m.movie_2d, o);
            if (o.oneofs)
                d.type = "movie_2d";
        }
        if (m.pickList != null && m.hasOwnProperty("pickList")) {
            d.pickList = $root.PickList.Item.toObject(m.pickList, o);
            if (o.oneofs)
                d.type = "pickList";
        }
        if (m.measure != null && m.hasOwnProperty("measure")) {
            d.measure = $root.Measure.Item.toObject(m.measure, o);
            if (o.oneofs)
                d.type = "measure";
        }
        return d;
    };

    /**
     * Converts this CatalogItem to JSON.
     * @function toJSON
     * @memberof CatalogItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CatalogItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CatalogItem
     * @function getTypeUrl
     * @memberof CatalogItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CatalogItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CatalogItem";
    };

    return CatalogItem;
})();

$root.Element = (function() {

    /**
     * Properties of an Element.
     * @exports IElement
     * @interface IElement
     * @property {string|null} [id] Element id
     * @property {string|null} [catalogId] Element catalogId
     */

    /**
     * Constructs a new Element.
     * @exports Element
     * @classdesc Represents an Element.
     * @implements IElement
     * @constructor
     * @param {IElement=} [p] Properties to set
     */
    function Element(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Element id.
     * @member {string} id
     * @memberof Element
     * @instance
     */
    Element.prototype.id = "";

    /**
     * Element catalogId.
     * @member {string} catalogId
     * @memberof Element
     * @instance
     */
    Element.prototype.catalogId = "";

    /**
     * Creates a new Element instance using the specified properties.
     * @function create
     * @memberof Element
     * @static
     * @param {IElement=} [properties] Properties to set
     * @returns {Element} Element instance
     */
    Element.create = function create(properties) {
        return new Element(properties);
    };

    /**
     * Encodes the specified Element message. Does not implicitly {@link Element.verify|verify} messages.
     * @function encode
     * @memberof Element
     * @static
     * @param {IElement} m Element message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Element.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.catalogId != null && Object.hasOwnProperty.call(m, "catalogId"))
            w.uint32(18).string(m.catalogId);
        return w;
    };

    /**
     * Decodes an Element message from the specified reader or buffer.
     * @function decode
     * @memberof Element
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Element} Element
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Element.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Element();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.catalogId = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an Element message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Element
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Element} Element
     */
    Element.fromObject = function fromObject(d) {
        if (d instanceof $root.Element)
            return d;
        var m = new $root.Element();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.catalogId != null) {
            m.catalogId = String(d.catalogId);
        }
        return m;
    };

    /**
     * Creates a plain object from an Element message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Element
     * @static
     * @param {Element} m Element
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Element.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
            d.catalogId = "";
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.catalogId != null && m.hasOwnProperty("catalogId")) {
            d.catalogId = m.catalogId;
        }
        return d;
    };

    /**
     * Converts this Element to JSON.
     * @function toJSON
     * @memberof Element
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Element.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Element
     * @function getTypeUrl
     * @memberof Element
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Element.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Element";
    };

    return Element;
})();

$root.Presentation = (function() {

    /**
     * Properties of a Presentation.
     * @exports IPresentation
     * @interface IPresentation
     * @property {string|null} [id] Presentation id
     * @property {streem.api.ITransform|null} [transform] Presentation transform
     * @property {Model3d.IPresentation|null} [model_3d] Presentation model_3d
     * @property {Text.IPresentation|null} [text] Presentation text
     * @property {Arrow.IPresentation|null} [arrow] Presentation arrow
     * @property {PickList.IPresentation|null} [pickList] Presentation pickList
     * @property {Measure.IPresentation|null} [measure] Presentation measure
     */

    /**
     * Constructs a new Presentation.
     * @exports Presentation
     * @classdesc Represents a Presentation.
     * @implements IPresentation
     * @constructor
     * @param {IPresentation=} [p] Properties to set
     */
    function Presentation(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Presentation id.
     * @member {string} id
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.id = "";

    /**
     * Presentation transform.
     * @member {streem.api.ITransform|null|undefined} transform
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.transform = null;

    /**
     * Presentation model_3d.
     * @member {Model3d.IPresentation|null|undefined} model_3d
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.model_3d = null;

    /**
     * Presentation text.
     * @member {Text.IPresentation|null|undefined} text
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.text = null;

    /**
     * Presentation arrow.
     * @member {Arrow.IPresentation|null|undefined} arrow
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.arrow = null;

    /**
     * Presentation pickList.
     * @member {PickList.IPresentation|null|undefined} pickList
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.pickList = null;

    /**
     * Presentation measure.
     * @member {Measure.IPresentation|null|undefined} measure
     * @memberof Presentation
     * @instance
     */
    Presentation.prototype.measure = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * Presentation type.
     * @member {"model_3d"|"text"|"arrow"|"pickList"|"measure"|undefined} type
     * @memberof Presentation
     * @instance
     */
    Object.defineProperty(Presentation.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["model_3d", "text", "arrow", "pickList", "measure"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Presentation instance using the specified properties.
     * @function create
     * @memberof Presentation
     * @static
     * @param {IPresentation=} [properties] Properties to set
     * @returns {Presentation} Presentation instance
     */
    Presentation.create = function create(properties) {
        return new Presentation(properties);
    };

    /**
     * Encodes the specified Presentation message. Does not implicitly {@link Presentation.verify|verify} messages.
     * @function encode
     * @memberof Presentation
     * @static
     * @param {IPresentation} m Presentation message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Presentation.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.transform != null && Object.hasOwnProperty.call(m, "transform"))
            $root.streem.api.Transform.encode(m.transform, w.uint32(18).fork()).ldelim();
        if (m.model_3d != null && Object.hasOwnProperty.call(m, "model_3d"))
            $root.Model3d.Presentation.encode(m.model_3d, w.uint32(810).fork()).ldelim();
        if (m.text != null && Object.hasOwnProperty.call(m, "text"))
            $root.Text.Presentation.encode(m.text, w.uint32(826).fork()).ldelim();
        if (m.arrow != null && Object.hasOwnProperty.call(m, "arrow"))
            $root.Arrow.Presentation.encode(m.arrow, w.uint32(834).fork()).ldelim();
        if (m.pickList != null && Object.hasOwnProperty.call(m, "pickList"))
            $root.PickList.Presentation.encode(m.pickList, w.uint32(850).fork()).ldelim();
        if (m.measure != null && Object.hasOwnProperty.call(m, "measure"))
            $root.Measure.Presentation.encode(m.measure, w.uint32(858).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a Presentation message from the specified reader or buffer.
     * @function decode
     * @memberof Presentation
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Presentation} Presentation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Presentation.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Presentation();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.transform = $root.streem.api.Transform.decode(r, r.uint32());
                    break;
                }
            case 101: {
                    m.model_3d = $root.Model3d.Presentation.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.text = $root.Text.Presentation.decode(r, r.uint32());
                    break;
                }
            case 104: {
                    m.arrow = $root.Arrow.Presentation.decode(r, r.uint32());
                    break;
                }
            case 106: {
                    m.pickList = $root.PickList.Presentation.decode(r, r.uint32());
                    break;
                }
            case 107: {
                    m.measure = $root.Measure.Presentation.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Presentation
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Presentation} Presentation
     */
    Presentation.fromObject = function fromObject(d) {
        if (d instanceof $root.Presentation)
            return d;
        var m = new $root.Presentation();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.transform != null) {
            if (typeof d.transform !== "object")
                throw TypeError(".Presentation.transform: object expected");
            m.transform = $root.streem.api.Transform.fromObject(d.transform);
        }
        if (d.model_3d != null) {
            if (typeof d.model_3d !== "object")
                throw TypeError(".Presentation.model_3d: object expected");
            m.model_3d = $root.Model3d.Presentation.fromObject(d.model_3d);
        }
        if (d.text != null) {
            if (typeof d.text !== "object")
                throw TypeError(".Presentation.text: object expected");
            m.text = $root.Text.Presentation.fromObject(d.text);
        }
        if (d.arrow != null) {
            if (typeof d.arrow !== "object")
                throw TypeError(".Presentation.arrow: object expected");
            m.arrow = $root.Arrow.Presentation.fromObject(d.arrow);
        }
        if (d.pickList != null) {
            if (typeof d.pickList !== "object")
                throw TypeError(".Presentation.pickList: object expected");
            m.pickList = $root.PickList.Presentation.fromObject(d.pickList);
        }
        if (d.measure != null) {
            if (typeof d.measure !== "object")
                throw TypeError(".Presentation.measure: object expected");
            m.measure = $root.Measure.Presentation.fromObject(d.measure);
        }
        return m;
    };

    /**
     * Creates a plain object from a Presentation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Presentation
     * @static
     * @param {Presentation} m Presentation
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Presentation.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
            d.transform = null;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.transform != null && m.hasOwnProperty("transform")) {
            d.transform = $root.streem.api.Transform.toObject(m.transform, o);
        }
        if (m.model_3d != null && m.hasOwnProperty("model_3d")) {
            d.model_3d = $root.Model3d.Presentation.toObject(m.model_3d, o);
            if (o.oneofs)
                d.type = "model_3d";
        }
        if (m.text != null && m.hasOwnProperty("text")) {
            d.text = $root.Text.Presentation.toObject(m.text, o);
            if (o.oneofs)
                d.type = "text";
        }
        if (m.arrow != null && m.hasOwnProperty("arrow")) {
            d.arrow = $root.Arrow.Presentation.toObject(m.arrow, o);
            if (o.oneofs)
                d.type = "arrow";
        }
        if (m.pickList != null && m.hasOwnProperty("pickList")) {
            d.pickList = $root.PickList.Presentation.toObject(m.pickList, o);
            if (o.oneofs)
                d.type = "pickList";
        }
        if (m.measure != null && m.hasOwnProperty("measure")) {
            d.measure = $root.Measure.Presentation.toObject(m.measure, o);
            if (o.oneofs)
                d.type = "measure";
        }
        return d;
    };

    /**
     * Converts this Presentation to JSON.
     * @function toJSON
     * @memberof Presentation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Presentation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Presentation
     * @function getTypeUrl
     * @memberof Presentation
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Presentation";
    };

    return Presentation;
})();

$root.StaticAnchor = (function() {

    /**
     * Properties of a StaticAnchor.
     * @exports IStaticAnchor
     * @interface IStaticAnchor
     * @property {string|null} [anchorId] StaticAnchor anchorId
     * @property {streem.api.IPose|null} [pose] StaticAnchor pose
     */

    /**
     * Constructs a new StaticAnchor.
     * @exports StaticAnchor
     * @classdesc Represents a StaticAnchor.
     * @implements IStaticAnchor
     * @constructor
     * @param {IStaticAnchor=} [p] Properties to set
     */
    function StaticAnchor(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * StaticAnchor anchorId.
     * @member {string} anchorId
     * @memberof StaticAnchor
     * @instance
     */
    StaticAnchor.prototype.anchorId = "";

    /**
     * StaticAnchor pose.
     * @member {streem.api.IPose|null|undefined} pose
     * @memberof StaticAnchor
     * @instance
     */
    StaticAnchor.prototype.pose = null;

    /**
     * Creates a new StaticAnchor instance using the specified properties.
     * @function create
     * @memberof StaticAnchor
     * @static
     * @param {IStaticAnchor=} [properties] Properties to set
     * @returns {StaticAnchor} StaticAnchor instance
     */
    StaticAnchor.create = function create(properties) {
        return new StaticAnchor(properties);
    };

    /**
     * Encodes the specified StaticAnchor message. Does not implicitly {@link StaticAnchor.verify|verify} messages.
     * @function encode
     * @memberof StaticAnchor
     * @static
     * @param {IStaticAnchor} m StaticAnchor message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StaticAnchor.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
            w.uint32(10).string(m.anchorId);
        if (m.pose != null && Object.hasOwnProperty.call(m, "pose"))
            $root.streem.api.Pose.encode(m.pose, w.uint32(18).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a StaticAnchor message from the specified reader or buffer.
     * @function decode
     * @memberof StaticAnchor
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {StaticAnchor} StaticAnchor
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StaticAnchor.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.StaticAnchor();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.anchorId = r.string();
                    break;
                }
            case 2: {
                    m.pose = $root.streem.api.Pose.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a StaticAnchor message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StaticAnchor
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {StaticAnchor} StaticAnchor
     */
    StaticAnchor.fromObject = function fromObject(d) {
        if (d instanceof $root.StaticAnchor)
            return d;
        var m = new $root.StaticAnchor();
        if (d.anchorId != null) {
            m.anchorId = String(d.anchorId);
        }
        if (d.pose != null) {
            if (typeof d.pose !== "object")
                throw TypeError(".StaticAnchor.pose: object expected");
            m.pose = $root.streem.api.Pose.fromObject(d.pose);
        }
        return m;
    };

    /**
     * Creates a plain object from a StaticAnchor message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StaticAnchor
     * @static
     * @param {StaticAnchor} m StaticAnchor
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StaticAnchor.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.anchorId = "";
            d.pose = null;
        }
        if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
            d.anchorId = m.anchorId;
        }
        if (m.pose != null && m.hasOwnProperty("pose")) {
            d.pose = $root.streem.api.Pose.toObject(m.pose, o);
        }
        return d;
    };

    /**
     * Converts this StaticAnchor to JSON.
     * @function toJSON
     * @memberof StaticAnchor
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StaticAnchor.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StaticAnchor
     * @function getTypeUrl
     * @memberof StaticAnchor
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StaticAnchor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StaticAnchor";
    };

    return StaticAnchor;
})();

$root.ElementGroup = (function() {

    /**
     * Properties of an ElementGroup.
     * @exports IElementGroup
     * @interface IElementGroup
     * @property {string|null} [id] ElementGroup id
     * @property {string|null} [anchorId] ElementGroup anchorId
     * @property {Array.<ElementGroup.IElementPresentation>|null} [elements] ElementGroup elements
     * @property {streem.api.ITransform|null} [offset] ElementGroup offset
     * @property {streem.api.IPoint|null} [focalPoint] ElementGroup focalPoint
     */

    /**
     * Constructs a new ElementGroup.
     * @exports ElementGroup
     * @classdesc Represents an ElementGroup.
     * @implements IElementGroup
     * @constructor
     * @param {IElementGroup=} [p] Properties to set
     */
    function ElementGroup(p) {
        this.elements = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * ElementGroup id.
     * @member {string} id
     * @memberof ElementGroup
     * @instance
     */
    ElementGroup.prototype.id = "";

    /**
     * ElementGroup anchorId.
     * @member {string} anchorId
     * @memberof ElementGroup
     * @instance
     */
    ElementGroup.prototype.anchorId = "";

    /**
     * ElementGroup elements.
     * @member {Array.<ElementGroup.IElementPresentation>} elements
     * @memberof ElementGroup
     * @instance
     */
    ElementGroup.prototype.elements = $util.emptyArray;

    /**
     * ElementGroup offset.
     * @member {streem.api.ITransform|null|undefined} offset
     * @memberof ElementGroup
     * @instance
     */
    ElementGroup.prototype.offset = null;

    /**
     * ElementGroup focalPoint.
     * @member {streem.api.IPoint|null|undefined} focalPoint
     * @memberof ElementGroup
     * @instance
     */
    ElementGroup.prototype.focalPoint = null;

    /**
     * Creates a new ElementGroup instance using the specified properties.
     * @function create
     * @memberof ElementGroup
     * @static
     * @param {IElementGroup=} [properties] Properties to set
     * @returns {ElementGroup} ElementGroup instance
     */
    ElementGroup.create = function create(properties) {
        return new ElementGroup(properties);
    };

    /**
     * Encodes the specified ElementGroup message. Does not implicitly {@link ElementGroup.verify|verify} messages.
     * @function encode
     * @memberof ElementGroup
     * @static
     * @param {IElementGroup} m ElementGroup message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ElementGroup.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
            w.uint32(18).string(m.anchorId);
        if (m.elements != null && m.elements.length) {
            for (var i = 0; i < m.elements.length; ++i)
                $root.ElementGroup.ElementPresentation.encode(m.elements[i], w.uint32(26).fork()).ldelim();
        }
        if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
            $root.streem.api.Transform.encode(m.offset, w.uint32(130).fork()).ldelim();
        if (m.focalPoint != null && Object.hasOwnProperty.call(m, "focalPoint"))
            $root.streem.api.Point.encode(m.focalPoint, w.uint32(138).fork()).ldelim();
        return w;
    };

    /**
     * Decodes an ElementGroup message from the specified reader or buffer.
     * @function decode
     * @memberof ElementGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {ElementGroup} ElementGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ElementGroup.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.ElementGroup();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.anchorId = r.string();
                    break;
                }
            case 3: {
                    if (!(m.elements && m.elements.length))
                        m.elements = [];
                    m.elements.push($root.ElementGroup.ElementPresentation.decode(r, r.uint32()));
                    break;
                }
            case 16: {
                    m.offset = $root.streem.api.Transform.decode(r, r.uint32());
                    break;
                }
            case 17: {
                    m.focalPoint = $root.streem.api.Point.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an ElementGroup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ElementGroup
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {ElementGroup} ElementGroup
     */
    ElementGroup.fromObject = function fromObject(d) {
        if (d instanceof $root.ElementGroup)
            return d;
        var m = new $root.ElementGroup();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.anchorId != null) {
            m.anchorId = String(d.anchorId);
        }
        if (d.elements) {
            if (!Array.isArray(d.elements))
                throw TypeError(".ElementGroup.elements: array expected");
            m.elements = [];
            for (var i = 0; i < d.elements.length; ++i) {
                if (typeof d.elements[i] !== "object")
                    throw TypeError(".ElementGroup.elements: object expected");
                m.elements[i] = $root.ElementGroup.ElementPresentation.fromObject(d.elements[i]);
            }
        }
        if (d.offset != null) {
            if (typeof d.offset !== "object")
                throw TypeError(".ElementGroup.offset: object expected");
            m.offset = $root.streem.api.Transform.fromObject(d.offset);
        }
        if (d.focalPoint != null) {
            if (typeof d.focalPoint !== "object")
                throw TypeError(".ElementGroup.focalPoint: object expected");
            m.focalPoint = $root.streem.api.Point.fromObject(d.focalPoint);
        }
        return m;
    };

    /**
     * Creates a plain object from an ElementGroup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ElementGroup
     * @static
     * @param {ElementGroup} m ElementGroup
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ElementGroup.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.elements = [];
        }
        if (o.defaults) {
            d.id = "";
            d.anchorId = "";
            d.offset = null;
            d.focalPoint = null;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
            d.anchorId = m.anchorId;
        }
        if (m.elements && m.elements.length) {
            d.elements = [];
            for (var j = 0; j < m.elements.length; ++j) {
                d.elements[j] = $root.ElementGroup.ElementPresentation.toObject(m.elements[j], o);
            }
        }
        if (m.offset != null && m.hasOwnProperty("offset")) {
            d.offset = $root.streem.api.Transform.toObject(m.offset, o);
        }
        if (m.focalPoint != null && m.hasOwnProperty("focalPoint")) {
            d.focalPoint = $root.streem.api.Point.toObject(m.focalPoint, o);
        }
        return d;
    };

    /**
     * Converts this ElementGroup to JSON.
     * @function toJSON
     * @memberof ElementGroup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ElementGroup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ElementGroup
     * @function getTypeUrl
     * @memberof ElementGroup
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ElementGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ElementGroup";
    };

    ElementGroup.ElementPresentation = (function() {

        /**
         * Properties of an ElementPresentation.
         * @memberof ElementGroup
         * @interface IElementPresentation
         * @property {IElement|null} [element] ElementPresentation element
         * @property {IPresentation|null} [presentation] ElementPresentation presentation
         */

        /**
         * Constructs a new ElementPresentation.
         * @memberof ElementGroup
         * @classdesc Represents an ElementPresentation.
         * @implements IElementPresentation
         * @constructor
         * @param {ElementGroup.IElementPresentation=} [p] Properties to set
         */
        function ElementPresentation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ElementPresentation element.
         * @member {IElement|null|undefined} element
         * @memberof ElementGroup.ElementPresentation
         * @instance
         */
        ElementPresentation.prototype.element = null;

        /**
         * ElementPresentation presentation.
         * @member {IPresentation|null|undefined} presentation
         * @memberof ElementGroup.ElementPresentation
         * @instance
         */
        ElementPresentation.prototype.presentation = null;

        /**
         * Creates a new ElementPresentation instance using the specified properties.
         * @function create
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {ElementGroup.IElementPresentation=} [properties] Properties to set
         * @returns {ElementGroup.ElementPresentation} ElementPresentation instance
         */
        ElementPresentation.create = function create(properties) {
            return new ElementPresentation(properties);
        };

        /**
         * Encodes the specified ElementPresentation message. Does not implicitly {@link ElementGroup.ElementPresentation.verify|verify} messages.
         * @function encode
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {ElementGroup.IElementPresentation} m ElementPresentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ElementPresentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.element != null && Object.hasOwnProperty.call(m, "element"))
                $root.Element.encode(m.element, w.uint32(10).fork()).ldelim();
            if (m.presentation != null && Object.hasOwnProperty.call(m, "presentation"))
                $root.Presentation.encode(m.presentation, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an ElementPresentation message from the specified reader or buffer.
         * @function decode
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ElementGroup.ElementPresentation} ElementPresentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ElementPresentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ElementGroup.ElementPresentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.element = $root.Element.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.presentation = $root.Presentation.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ElementPresentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ElementGroup.ElementPresentation} ElementPresentation
         */
        ElementPresentation.fromObject = function fromObject(d) {
            if (d instanceof $root.ElementGroup.ElementPresentation)
                return d;
            var m = new $root.ElementGroup.ElementPresentation();
            if (d.element != null) {
                if (typeof d.element !== "object")
                    throw TypeError(".ElementGroup.ElementPresentation.element: object expected");
                m.element = $root.Element.fromObject(d.element);
            }
            if (d.presentation != null) {
                if (typeof d.presentation !== "object")
                    throw TypeError(".ElementGroup.ElementPresentation.presentation: object expected");
                m.presentation = $root.Presentation.fromObject(d.presentation);
            }
            return m;
        };

        /**
         * Creates a plain object from an ElementPresentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {ElementGroup.ElementPresentation} m ElementPresentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ElementPresentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.element = null;
                d.presentation = null;
            }
            if (m.element != null && m.hasOwnProperty("element")) {
                d.element = $root.Element.toObject(m.element, o);
            }
            if (m.presentation != null && m.hasOwnProperty("presentation")) {
                d.presentation = $root.Presentation.toObject(m.presentation, o);
            }
            return d;
        };

        /**
         * Converts this ElementPresentation to JSON.
         * @function toJSON
         * @memberof ElementGroup.ElementPresentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ElementPresentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ElementPresentation
         * @function getTypeUrl
         * @memberof ElementGroup.ElementPresentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ElementPresentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ElementGroup.ElementPresentation";
        };

        return ElementPresentation;
    })();

    return ElementGroup;
})();

$root.Model3d = (function() {

    /**
     * Properties of a Model3d.
     * @exports IModel3d
     * @interface IModel3d
     */

    /**
     * Constructs a new Model3d.
     * @exports Model3d
     * @classdesc Represents a Model3d.
     * @implements IModel3d
     * @constructor
     * @param {IModel3d=} [p] Properties to set
     */
    function Model3d(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Model3d instance using the specified properties.
     * @function create
     * @memberof Model3d
     * @static
     * @param {IModel3d=} [properties] Properties to set
     * @returns {Model3d} Model3d instance
     */
    Model3d.create = function create(properties) {
        return new Model3d(properties);
    };

    /**
     * Encodes the specified Model3d message. Does not implicitly {@link Model3d.verify|verify} messages.
     * @function encode
     * @memberof Model3d
     * @static
     * @param {IModel3d} m Model3d message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Model3d.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes a Model3d message from the specified reader or buffer.
     * @function decode
     * @memberof Model3d
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Model3d} Model3d
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Model3d.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Model3d();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Model3d message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Model3d
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Model3d} Model3d
     */
    Model3d.fromObject = function fromObject(d) {
        if (d instanceof $root.Model3d)
            return d;
        return new $root.Model3d();
    };

    /**
     * Creates a plain object from a Model3d message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Model3d
     * @static
     * @param {Model3d} m Model3d
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Model3d.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Model3d to JSON.
     * @function toJSON
     * @memberof Model3d
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Model3d.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Model3d
     * @function getTypeUrl
     * @memberof Model3d
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Model3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Model3d";
    };

    Model3d.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Model3d
         * @interface IItem
         * @property {string|null} [file] Item file
         */

        /**
         * Constructs a new Item.
         * @memberof Model3d
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Model3d.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Item file.
         * @member {string} file
         * @memberof Model3d.Item
         * @instance
         */
        Item.prototype.file = "";

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Model3d.Item
         * @static
         * @param {Model3d.IItem=} [properties] Properties to set
         * @returns {Model3d.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Model3d.Item.verify|verify} messages.
         * @function encode
         * @memberof Model3d.Item
         * @static
         * @param {Model3d.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                w.uint32(10).string(m.file);
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Model3d.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Model3d.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Model3d.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.file = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Model3d.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Model3d.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Model3d.Item)
                return d;
            var m = new $root.Model3d.Item();
            if (d.file != null) {
                m.file = String(d.file);
            }
            return m;
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Model3d.Item
         * @static
         * @param {Model3d.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.file = "";
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = m.file;
            }
            return d;
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Model3d.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Model3d.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Model3d.Item";
        };

        return Item;
    })();

    Model3d.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof Model3d
         * @interface IPresentation
         * @property {Model3d.IAnimation|null} [animation] Presentation animation
         */

        /**
         * Constructs a new Presentation.
         * @memberof Model3d
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {Model3d.IPresentation=} [p] Properties to set
         */
        function Presentation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Presentation animation.
         * @member {Model3d.IAnimation|null|undefined} animation
         * @memberof Model3d.Presentation
         * @instance
         */
        Presentation.prototype.animation = null;

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof Model3d.Presentation
         * @static
         * @param {Model3d.IPresentation=} [properties] Properties to set
         * @returns {Model3d.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link Model3d.Presentation.verify|verify} messages.
         * @function encode
         * @memberof Model3d.Presentation
         * @static
         * @param {Model3d.IPresentation} m Presentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.animation != null && Object.hasOwnProperty.call(m, "animation"))
                $root.Model3d.Animation.encode(m.animation, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof Model3d.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Model3d.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Model3d.Presentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.animation = $root.Model3d.Animation.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Model3d.Presentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Model3d.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(d) {
            if (d instanceof $root.Model3d.Presentation)
                return d;
            var m = new $root.Model3d.Presentation();
            if (d.animation != null) {
                if (typeof d.animation !== "object")
                    throw TypeError(".Model3d.Presentation.animation: object expected");
                m.animation = $root.Model3d.Animation.fromObject(d.animation);
            }
            return m;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Model3d.Presentation
         * @static
         * @param {Model3d.Presentation} m Presentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.animation = null;
            }
            if (m.animation != null && m.hasOwnProperty("animation")) {
                d.animation = $root.Model3d.Animation.toObject(m.animation, o);
            }
            return d;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof Model3d.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Presentation
         * @function getTypeUrl
         * @memberof Model3d.Presentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Model3d.Presentation";
        };

        return Presentation;
    })();

    Model3d.Animation = (function() {

        /**
         * Properties of an Animation.
         * @memberof Model3d
         * @interface IAnimation
         * @property {boolean|null} [autoStart] Animation autoStart
         * @property {number|null} [animationIndex] Animation animationIndex
         * @property {number|null} [runCount] Animation runCount
         * @property {number|null} [delayMs] Animation delayMs
         * @property {number|null} [durationMs] Animation durationMs
         */

        /**
         * Constructs a new Animation.
         * @memberof Model3d
         * @classdesc Represents an Animation.
         * @implements IAnimation
         * @constructor
         * @param {Model3d.IAnimation=} [p] Properties to set
         */
        function Animation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Animation autoStart.
         * @member {boolean} autoStart
         * @memberof Model3d.Animation
         * @instance
         */
        Animation.prototype.autoStart = false;

        /**
         * Animation animationIndex.
         * @member {number} animationIndex
         * @memberof Model3d.Animation
         * @instance
         */
        Animation.prototype.animationIndex = 0;

        /**
         * Animation runCount.
         * @member {number} runCount
         * @memberof Model3d.Animation
         * @instance
         */
        Animation.prototype.runCount = 0;

        /**
         * Animation delayMs.
         * @member {number} delayMs
         * @memberof Model3d.Animation
         * @instance
         */
        Animation.prototype.delayMs = 0;

        /**
         * Animation durationMs.
         * @member {number} durationMs
         * @memberof Model3d.Animation
         * @instance
         */
        Animation.prototype.durationMs = 0;

        /**
         * Creates a new Animation instance using the specified properties.
         * @function create
         * @memberof Model3d.Animation
         * @static
         * @param {Model3d.IAnimation=} [properties] Properties to set
         * @returns {Model3d.Animation} Animation instance
         */
        Animation.create = function create(properties) {
            return new Animation(properties);
        };

        /**
         * Encodes the specified Animation message. Does not implicitly {@link Model3d.Animation.verify|verify} messages.
         * @function encode
         * @memberof Model3d.Animation
         * @static
         * @param {Model3d.IAnimation} m Animation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Animation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.autoStart != null && Object.hasOwnProperty.call(m, "autoStart"))
                w.uint32(8).bool(m.autoStart);
            if (m.animationIndex != null && Object.hasOwnProperty.call(m, "animationIndex"))
                w.uint32(16).uint32(m.animationIndex);
            if (m.runCount != null && Object.hasOwnProperty.call(m, "runCount"))
                w.uint32(24).int32(m.runCount);
            if (m.delayMs != null && Object.hasOwnProperty.call(m, "delayMs"))
                w.uint32(32).uint32(m.delayMs);
            if (m.durationMs != null && Object.hasOwnProperty.call(m, "durationMs"))
                w.uint32(40).uint32(m.durationMs);
            return w;
        };

        /**
         * Decodes an Animation message from the specified reader or buffer.
         * @function decode
         * @memberof Model3d.Animation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Model3d.Animation} Animation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Animation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Model3d.Animation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.autoStart = r.bool();
                        break;
                    }
                case 2: {
                        m.animationIndex = r.uint32();
                        break;
                    }
                case 3: {
                        m.runCount = r.int32();
                        break;
                    }
                case 4: {
                        m.delayMs = r.uint32();
                        break;
                    }
                case 5: {
                        m.durationMs = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Animation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Model3d.Animation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Model3d.Animation} Animation
         */
        Animation.fromObject = function fromObject(d) {
            if (d instanceof $root.Model3d.Animation)
                return d;
            var m = new $root.Model3d.Animation();
            if (d.autoStart != null) {
                m.autoStart = Boolean(d.autoStart);
            }
            if (d.animationIndex != null) {
                m.animationIndex = d.animationIndex >>> 0;
            }
            if (d.runCount != null) {
                m.runCount = d.runCount | 0;
            }
            if (d.delayMs != null) {
                m.delayMs = d.delayMs >>> 0;
            }
            if (d.durationMs != null) {
                m.durationMs = d.durationMs >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from an Animation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Model3d.Animation
         * @static
         * @param {Model3d.Animation} m Animation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Animation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.autoStart = false;
                d.animationIndex = 0;
                d.runCount = 0;
                d.delayMs = 0;
                d.durationMs = 0;
            }
            if (m.autoStart != null && m.hasOwnProperty("autoStart")) {
                d.autoStart = m.autoStart;
            }
            if (m.animationIndex != null && m.hasOwnProperty("animationIndex")) {
                d.animationIndex = m.animationIndex;
            }
            if (m.runCount != null && m.hasOwnProperty("runCount")) {
                d.runCount = m.runCount;
            }
            if (m.delayMs != null && m.hasOwnProperty("delayMs")) {
                d.delayMs = m.delayMs;
            }
            if (m.durationMs != null && m.hasOwnProperty("durationMs")) {
                d.durationMs = m.durationMs;
            }
            return d;
        };

        /**
         * Converts this Animation to JSON.
         * @function toJSON
         * @memberof Model3d.Animation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Animation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Animation
         * @function getTypeUrl
         * @memberof Model3d.Animation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Animation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Model3d.Animation";
        };

        return Animation;
    })();

    return Model3d;
})();

$root.Image2d = (function() {

    /**
     * Properties of an Image2d.
     * @exports IImage2d
     * @interface IImage2d
     */

    /**
     * Constructs a new Image2d.
     * @exports Image2d
     * @classdesc Represents an Image2d.
     * @implements IImage2d
     * @constructor
     * @param {IImage2d=} [p] Properties to set
     */
    function Image2d(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Image2d instance using the specified properties.
     * @function create
     * @memberof Image2d
     * @static
     * @param {IImage2d=} [properties] Properties to set
     * @returns {Image2d} Image2d instance
     */
    Image2d.create = function create(properties) {
        return new Image2d(properties);
    };

    /**
     * Encodes the specified Image2d message. Does not implicitly {@link Image2d.verify|verify} messages.
     * @function encode
     * @memberof Image2d
     * @static
     * @param {IImage2d} m Image2d message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Image2d.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes an Image2d message from the specified reader or buffer.
     * @function decode
     * @memberof Image2d
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Image2d} Image2d
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Image2d.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Image2d();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an Image2d message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Image2d
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Image2d} Image2d
     */
    Image2d.fromObject = function fromObject(d) {
        if (d instanceof $root.Image2d)
            return d;
        return new $root.Image2d();
    };

    /**
     * Creates a plain object from an Image2d message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Image2d
     * @static
     * @param {Image2d} m Image2d
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Image2d.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Image2d to JSON.
     * @function toJSON
     * @memberof Image2d
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Image2d.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Image2d
     * @function getTypeUrl
     * @memberof Image2d
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Image2d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Image2d";
    };

    Image2d.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Image2d
         * @interface IItem
         * @property {string|null} [file] Item file
         * @property {number|null} [widthInMeters] Item widthInMeters
         */

        /**
         * Constructs a new Item.
         * @memberof Image2d
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Image2d.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Item file.
         * @member {string} file
         * @memberof Image2d.Item
         * @instance
         */
        Item.prototype.file = "";

        /**
         * Item widthInMeters.
         * @member {number} widthInMeters
         * @memberof Image2d.Item
         * @instance
         */
        Item.prototype.widthInMeters = 0;

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Image2d.Item
         * @static
         * @param {Image2d.IItem=} [properties] Properties to set
         * @returns {Image2d.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Image2d.Item.verify|verify} messages.
         * @function encode
         * @memberof Image2d.Item
         * @static
         * @param {Image2d.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                w.uint32(10).string(m.file);
            if (m.widthInMeters != null && Object.hasOwnProperty.call(m, "widthInMeters"))
                w.uint32(21).float(m.widthInMeters);
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Image2d.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Image2d.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Image2d.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.file = r.string();
                        break;
                    }
                case 2: {
                        m.widthInMeters = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Image2d.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Image2d.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Image2d.Item)
                return d;
            var m = new $root.Image2d.Item();
            if (d.file != null) {
                m.file = String(d.file);
            }
            if (d.widthInMeters != null) {
                m.widthInMeters = Number(d.widthInMeters);
            }
            return m;
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Image2d.Item
         * @static
         * @param {Image2d.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.file = "";
                d.widthInMeters = 0;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = m.file;
            }
            if (m.widthInMeters != null && m.hasOwnProperty("widthInMeters")) {
                d.widthInMeters = o.json && !isFinite(m.widthInMeters) ? String(m.widthInMeters) : m.widthInMeters;
            }
            return d;
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Image2d.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Image2d.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Image2d.Item";
        };

        return Item;
    })();

    return Image2d;
})();

$root.Text = (function() {

    /**
     * Properties of a Text.
     * @exports IText
     * @interface IText
     */

    /**
     * Constructs a new Text.
     * @exports Text
     * @classdesc Represents a Text.
     * @implements IText
     * @constructor
     * @param {IText=} [p] Properties to set
     */
    function Text(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Text instance using the specified properties.
     * @function create
     * @memberof Text
     * @static
     * @param {IText=} [properties] Properties to set
     * @returns {Text} Text instance
     */
    Text.create = function create(properties) {
        return new Text(properties);
    };

    /**
     * Encodes the specified Text message. Does not implicitly {@link Text.verify|verify} messages.
     * @function encode
     * @memberof Text
     * @static
     * @param {IText} m Text message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Text.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes a Text message from the specified reader or buffer.
     * @function decode
     * @memberof Text
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Text} Text
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Text.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Text();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Text message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Text
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Text} Text
     */
    Text.fromObject = function fromObject(d) {
        if (d instanceof $root.Text)
            return d;
        return new $root.Text();
    };

    /**
     * Creates a plain object from a Text message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Text
     * @static
     * @param {Text} m Text
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Text.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Text to JSON.
     * @function toJSON
     * @memberof Text
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Text.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Text
     * @function getTypeUrl
     * @memberof Text
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Text";
    };

    Text.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Text
         * @interface IItem
         * @property {number|null} [widthInMeters] Item widthInMeters
         */

        /**
         * Constructs a new Item.
         * @memberof Text
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Text.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Item widthInMeters.
         * @member {number} widthInMeters
         * @memberof Text.Item
         * @instance
         */
        Item.prototype.widthInMeters = 0;

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Text.Item
         * @static
         * @param {Text.IItem=} [properties] Properties to set
         * @returns {Text.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Text.Item.verify|verify} messages.
         * @function encode
         * @memberof Text.Item
         * @static
         * @param {Text.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.widthInMeters != null && Object.hasOwnProperty.call(m, "widthInMeters"))
                w.uint32(13).float(m.widthInMeters);
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Text.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Text.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Text.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.widthInMeters = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Text.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Text.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Text.Item)
                return d;
            var m = new $root.Text.Item();
            if (d.widthInMeters != null) {
                m.widthInMeters = Number(d.widthInMeters);
            }
            return m;
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Text.Item
         * @static
         * @param {Text.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.widthInMeters = 0;
            }
            if (m.widthInMeters != null && m.hasOwnProperty("widthInMeters")) {
                d.widthInMeters = o.json && !isFinite(m.widthInMeters) ? String(m.widthInMeters) : m.widthInMeters;
            }
            return d;
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Text.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Text.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Text.Item";
        };

        return Item;
    })();

    Text.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof Text
         * @interface IPresentation
         * @property {string|null} [titleId] Presentation titleId
         * @property {string|null} [contentId] Presentation contentId
         * @property {ITextTheme|null} [themeOverride] Presentation themeOverride
         */

        /**
         * Constructs a new Presentation.
         * @memberof Text
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {Text.IPresentation=} [p] Properties to set
         */
        function Presentation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Presentation titleId.
         * @member {string} titleId
         * @memberof Text.Presentation
         * @instance
         */
        Presentation.prototype.titleId = "";

        /**
         * Presentation contentId.
         * @member {string} contentId
         * @memberof Text.Presentation
         * @instance
         */
        Presentation.prototype.contentId = "";

        /**
         * Presentation themeOverride.
         * @member {ITextTheme|null|undefined} themeOverride
         * @memberof Text.Presentation
         * @instance
         */
        Presentation.prototype.themeOverride = null;

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof Text.Presentation
         * @static
         * @param {Text.IPresentation=} [properties] Properties to set
         * @returns {Text.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link Text.Presentation.verify|verify} messages.
         * @function encode
         * @memberof Text.Presentation
         * @static
         * @param {Text.IPresentation} m Presentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.titleId != null && Object.hasOwnProperty.call(m, "titleId"))
                w.uint32(10).string(m.titleId);
            if (m.contentId != null && Object.hasOwnProperty.call(m, "contentId"))
                w.uint32(18).string(m.contentId);
            if (m.themeOverride != null && Object.hasOwnProperty.call(m, "themeOverride"))
                $root.TextTheme.encode(m.themeOverride, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof Text.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Text.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Text.Presentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.titleId = r.string();
                        break;
                    }
                case 2: {
                        m.contentId = r.string();
                        break;
                    }
                case 3: {
                        m.themeOverride = $root.TextTheme.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Text.Presentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Text.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(d) {
            if (d instanceof $root.Text.Presentation)
                return d;
            var m = new $root.Text.Presentation();
            if (d.titleId != null) {
                m.titleId = String(d.titleId);
            }
            if (d.contentId != null) {
                m.contentId = String(d.contentId);
            }
            if (d.themeOverride != null) {
                if (typeof d.themeOverride !== "object")
                    throw TypeError(".Text.Presentation.themeOverride: object expected");
                m.themeOverride = $root.TextTheme.fromObject(d.themeOverride);
            }
            return m;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Text.Presentation
         * @static
         * @param {Text.Presentation} m Presentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.titleId = "";
                d.contentId = "";
                d.themeOverride = null;
            }
            if (m.titleId != null && m.hasOwnProperty("titleId")) {
                d.titleId = m.titleId;
            }
            if (m.contentId != null && m.hasOwnProperty("contentId")) {
                d.contentId = m.contentId;
            }
            if (m.themeOverride != null && m.hasOwnProperty("themeOverride")) {
                d.themeOverride = $root.TextTheme.toObject(m.themeOverride, o);
            }
            return d;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof Text.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Presentation
         * @function getTypeUrl
         * @memberof Text.Presentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Text.Presentation";
        };

        return Presentation;
    })();

    return Text;
})();

$root.Arrow = (function() {

    /**
     * Properties of an Arrow.
     * @exports IArrow
     * @interface IArrow
     */

    /**
     * Constructs a new Arrow.
     * @exports Arrow
     * @classdesc Represents an Arrow.
     * @implements IArrow
     * @constructor
     * @param {IArrow=} [p] Properties to set
     */
    function Arrow(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Arrow instance using the specified properties.
     * @function create
     * @memberof Arrow
     * @static
     * @param {IArrow=} [properties] Properties to set
     * @returns {Arrow} Arrow instance
     */
    Arrow.create = function create(properties) {
        return new Arrow(properties);
    };

    /**
     * Encodes the specified Arrow message. Does not implicitly {@link Arrow.verify|verify} messages.
     * @function encode
     * @memberof Arrow
     * @static
     * @param {IArrow} m Arrow message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Arrow.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes an Arrow message from the specified reader or buffer.
     * @function decode
     * @memberof Arrow
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Arrow} Arrow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Arrow.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Arrow();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an Arrow message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Arrow
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Arrow} Arrow
     */
    Arrow.fromObject = function fromObject(d) {
        if (d instanceof $root.Arrow)
            return d;
        return new $root.Arrow();
    };

    /**
     * Creates a plain object from an Arrow message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Arrow
     * @static
     * @param {Arrow} m Arrow
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Arrow.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Arrow to JSON.
     * @function toJSON
     * @memberof Arrow
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Arrow.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Arrow
     * @function getTypeUrl
     * @memberof Arrow
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Arrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Arrow";
    };

    Arrow.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Arrow
         * @interface IItem
         */

        /**
         * Constructs a new Item.
         * @memberof Arrow
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Arrow.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Arrow.Item
         * @static
         * @param {Arrow.IItem=} [properties] Properties to set
         * @returns {Arrow.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Arrow.Item.verify|verify} messages.
         * @function encode
         * @memberof Arrow.Item
         * @static
         * @param {Arrow.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Arrow.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Arrow.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Arrow.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Arrow.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Arrow.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Arrow.Item)
                return d;
            return new $root.Arrow.Item();
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Arrow.Item
         * @static
         * @param {Arrow.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Arrow.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Arrow.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Arrow.Item";
        };

        return Item;
    })();

    Arrow.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof Arrow
         * @interface IPresentation
         * @property {string|null} [color] Presentation color
         */

        /**
         * Constructs a new Presentation.
         * @memberof Arrow
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {Arrow.IPresentation=} [p] Properties to set
         */
        function Presentation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Presentation color.
         * @member {string} color
         * @memberof Arrow.Presentation
         * @instance
         */
        Presentation.prototype.color = "";

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof Arrow.Presentation
         * @static
         * @param {Arrow.IPresentation=} [properties] Properties to set
         * @returns {Arrow.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link Arrow.Presentation.verify|verify} messages.
         * @function encode
         * @memberof Arrow.Presentation
         * @static
         * @param {Arrow.IPresentation} m Presentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(10).string(m.color);
            return w;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof Arrow.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Arrow.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Arrow.Presentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.color = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Arrow.Presentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Arrow.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(d) {
            if (d instanceof $root.Arrow.Presentation)
                return d;
            var m = new $root.Arrow.Presentation();
            if (d.color != null) {
                m.color = String(d.color);
            }
            return m;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Arrow.Presentation
         * @static
         * @param {Arrow.Presentation} m Presentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.color = "";
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            return d;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof Arrow.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Presentation
         * @function getTypeUrl
         * @memberof Arrow.Presentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Arrow.Presentation";
        };

        return Presentation;
    })();

    return Arrow;
})();

$root.Movie2d = (function() {

    /**
     * Properties of a Movie2d.
     * @exports IMovie2d
     * @interface IMovie2d
     */

    /**
     * Constructs a new Movie2d.
     * @exports Movie2d
     * @classdesc Represents a Movie2d.
     * @implements IMovie2d
     * @constructor
     * @param {IMovie2d=} [p] Properties to set
     */
    function Movie2d(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Movie2d instance using the specified properties.
     * @function create
     * @memberof Movie2d
     * @static
     * @param {IMovie2d=} [properties] Properties to set
     * @returns {Movie2d} Movie2d instance
     */
    Movie2d.create = function create(properties) {
        return new Movie2d(properties);
    };

    /**
     * Encodes the specified Movie2d message. Does not implicitly {@link Movie2d.verify|verify} messages.
     * @function encode
     * @memberof Movie2d
     * @static
     * @param {IMovie2d} m Movie2d message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Movie2d.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes a Movie2d message from the specified reader or buffer.
     * @function decode
     * @memberof Movie2d
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Movie2d} Movie2d
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Movie2d.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Movie2d();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Movie2d message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Movie2d
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Movie2d} Movie2d
     */
    Movie2d.fromObject = function fromObject(d) {
        if (d instanceof $root.Movie2d)
            return d;
        return new $root.Movie2d();
    };

    /**
     * Creates a plain object from a Movie2d message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Movie2d
     * @static
     * @param {Movie2d} m Movie2d
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Movie2d.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Movie2d to JSON.
     * @function toJSON
     * @memberof Movie2d
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Movie2d.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Movie2d
     * @function getTypeUrl
     * @memberof Movie2d
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Movie2d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Movie2d";
    };

    Movie2d.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Movie2d
         * @interface IItem
         * @property {string|null} [file] Item file
         * @property {number|null} [widthInMeters] Item widthInMeters
         */

        /**
         * Constructs a new Item.
         * @memberof Movie2d
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Movie2d.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Item file.
         * @member {string} file
         * @memberof Movie2d.Item
         * @instance
         */
        Item.prototype.file = "";

        /**
         * Item widthInMeters.
         * @member {number} widthInMeters
         * @memberof Movie2d.Item
         * @instance
         */
        Item.prototype.widthInMeters = 0;

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Movie2d.Item
         * @static
         * @param {Movie2d.IItem=} [properties] Properties to set
         * @returns {Movie2d.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Movie2d.Item.verify|verify} messages.
         * @function encode
         * @memberof Movie2d.Item
         * @static
         * @param {Movie2d.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                w.uint32(10).string(m.file);
            if (m.widthInMeters != null && Object.hasOwnProperty.call(m, "widthInMeters"))
                w.uint32(21).float(m.widthInMeters);
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Movie2d.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Movie2d.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Movie2d.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.file = r.string();
                        break;
                    }
                case 2: {
                        m.widthInMeters = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Movie2d.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Movie2d.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Movie2d.Item)
                return d;
            var m = new $root.Movie2d.Item();
            if (d.file != null) {
                m.file = String(d.file);
            }
            if (d.widthInMeters != null) {
                m.widthInMeters = Number(d.widthInMeters);
            }
            return m;
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Movie2d.Item
         * @static
         * @param {Movie2d.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.file = "";
                d.widthInMeters = 0;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = m.file;
            }
            if (m.widthInMeters != null && m.hasOwnProperty("widthInMeters")) {
                d.widthInMeters = o.json && !isFinite(m.widthInMeters) ? String(m.widthInMeters) : m.widthInMeters;
            }
            return d;
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Movie2d.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Movie2d.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Movie2d.Item";
        };

        return Item;
    })();

    return Movie2d;
})();

$root.PickList = (function() {

    /**
     * Properties of a PickList.
     * @exports IPickList
     * @interface IPickList
     */

    /**
     * Constructs a new PickList.
     * @exports PickList
     * @classdesc Represents a PickList.
     * @implements IPickList
     * @constructor
     * @param {IPickList=} [p] Properties to set
     */
    function PickList(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new PickList instance using the specified properties.
     * @function create
     * @memberof PickList
     * @static
     * @param {IPickList=} [properties] Properties to set
     * @returns {PickList} PickList instance
     */
    PickList.create = function create(properties) {
        return new PickList(properties);
    };

    /**
     * Encodes the specified PickList message. Does not implicitly {@link PickList.verify|verify} messages.
     * @function encode
     * @memberof PickList
     * @static
     * @param {IPickList} m PickList message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PickList.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes a PickList message from the specified reader or buffer.
     * @function decode
     * @memberof PickList
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {PickList} PickList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PickList.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.PickList();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a PickList message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PickList
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {PickList} PickList
     */
    PickList.fromObject = function fromObject(d) {
        if (d instanceof $root.PickList)
            return d;
        return new $root.PickList();
    };

    /**
     * Creates a plain object from a PickList message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PickList
     * @static
     * @param {PickList} m PickList
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PickList.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this PickList to JSON.
     * @function toJSON
     * @memberof PickList
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PickList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PickList
     * @function getTypeUrl
     * @memberof PickList
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PickList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PickList";
    };

    PickList.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof PickList
         * @interface IItem
         * @property {number|null} [widthInMeters] Item widthInMeters
         */

        /**
         * Constructs a new Item.
         * @memberof PickList
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {PickList.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Item widthInMeters.
         * @member {number} widthInMeters
         * @memberof PickList.Item
         * @instance
         */
        Item.prototype.widthInMeters = 0;

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof PickList.Item
         * @static
         * @param {PickList.IItem=} [properties] Properties to set
         * @returns {PickList.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link PickList.Item.verify|verify} messages.
         * @function encode
         * @memberof PickList.Item
         * @static
         * @param {PickList.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.widthInMeters != null && Object.hasOwnProperty.call(m, "widthInMeters"))
                w.uint32(13).float(m.widthInMeters);
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof PickList.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PickList.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PickList.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.widthInMeters = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PickList.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {PickList.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.PickList.Item)
                return d;
            var m = new $root.PickList.Item();
            if (d.widthInMeters != null) {
                m.widthInMeters = Number(d.widthInMeters);
            }
            return m;
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PickList.Item
         * @static
         * @param {PickList.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.widthInMeters = 0;
            }
            if (m.widthInMeters != null && m.hasOwnProperty("widthInMeters")) {
                d.widthInMeters = o.json && !isFinite(m.widthInMeters) ? String(m.widthInMeters) : m.widthInMeters;
            }
            return d;
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof PickList.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof PickList.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PickList.Item";
        };

        return Item;
    })();

    PickList.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof PickList
         * @interface IPresentation
         * @property {Array.<PickList.IOption>|null} [options] Presentation options
         * @property {IPickListTheme|null} [themeOverride] Presentation themeOverride
         */

        /**
         * Constructs a new Presentation.
         * @memberof PickList
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {PickList.IPresentation=} [p] Properties to set
         */
        function Presentation(p) {
            this.options = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Presentation options.
         * @member {Array.<PickList.IOption>} options
         * @memberof PickList.Presentation
         * @instance
         */
        Presentation.prototype.options = $util.emptyArray;

        /**
         * Presentation themeOverride.
         * @member {IPickListTheme|null|undefined} themeOverride
         * @memberof PickList.Presentation
         * @instance
         */
        Presentation.prototype.themeOverride = null;

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof PickList.Presentation
         * @static
         * @param {PickList.IPresentation=} [properties] Properties to set
         * @returns {PickList.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link PickList.Presentation.verify|verify} messages.
         * @function encode
         * @memberof PickList.Presentation
         * @static
         * @param {PickList.IPresentation} m Presentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.options != null && m.options.length) {
                for (var i = 0; i < m.options.length; ++i)
                    $root.PickList.Option.encode(m.options[i], w.uint32(10).fork()).ldelim();
            }
            if (m.themeOverride != null && Object.hasOwnProperty.call(m, "themeOverride"))
                $root.PickListTheme.encode(m.themeOverride, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof PickList.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PickList.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PickList.Presentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.options && m.options.length))
                            m.options = [];
                        m.options.push($root.PickList.Option.decode(r, r.uint32()));
                        break;
                    }
                case 2: {
                        m.themeOverride = $root.PickListTheme.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PickList.Presentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {PickList.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(d) {
            if (d instanceof $root.PickList.Presentation)
                return d;
            var m = new $root.PickList.Presentation();
            if (d.options) {
                if (!Array.isArray(d.options))
                    throw TypeError(".PickList.Presentation.options: array expected");
                m.options = [];
                for (var i = 0; i < d.options.length; ++i) {
                    if (typeof d.options[i] !== "object")
                        throw TypeError(".PickList.Presentation.options: object expected");
                    m.options[i] = $root.PickList.Option.fromObject(d.options[i]);
                }
            }
            if (d.themeOverride != null) {
                if (typeof d.themeOverride !== "object")
                    throw TypeError(".PickList.Presentation.themeOverride: object expected");
                m.themeOverride = $root.PickListTheme.fromObject(d.themeOverride);
            }
            return m;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PickList.Presentation
         * @static
         * @param {PickList.Presentation} m Presentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.options = [];
            }
            if (o.defaults) {
                d.themeOverride = null;
            }
            if (m.options && m.options.length) {
                d.options = [];
                for (var j = 0; j < m.options.length; ++j) {
                    d.options[j] = $root.PickList.Option.toObject(m.options[j], o);
                }
            }
            if (m.themeOverride != null && m.hasOwnProperty("themeOverride")) {
                d.themeOverride = $root.PickListTheme.toObject(m.themeOverride, o);
            }
            return d;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof PickList.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Presentation
         * @function getTypeUrl
         * @memberof PickList.Presentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PickList.Presentation";
        };

        return Presentation;
    })();

    PickList.Option = (function() {

        /**
         * Properties of an Option.
         * @memberof PickList
         * @interface IOption
         * @property {string|null} [textId] Option textId
         * @property {boolean|null} [hideInfoButton] Option hideInfoButton
         */

        /**
         * Constructs a new Option.
         * @memberof PickList
         * @classdesc Represents an Option.
         * @implements IOption
         * @constructor
         * @param {PickList.IOption=} [p] Properties to set
         */
        function Option(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Option textId.
         * @member {string} textId
         * @memberof PickList.Option
         * @instance
         */
        Option.prototype.textId = "";

        /**
         * Option hideInfoButton.
         * @member {boolean} hideInfoButton
         * @memberof PickList.Option
         * @instance
         */
        Option.prototype.hideInfoButton = false;

        /**
         * Creates a new Option instance using the specified properties.
         * @function create
         * @memberof PickList.Option
         * @static
         * @param {PickList.IOption=} [properties] Properties to set
         * @returns {PickList.Option} Option instance
         */
        Option.create = function create(properties) {
            return new Option(properties);
        };

        /**
         * Encodes the specified Option message. Does not implicitly {@link PickList.Option.verify|verify} messages.
         * @function encode
         * @memberof PickList.Option
         * @static
         * @param {PickList.IOption} m Option message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.textId != null && Object.hasOwnProperty.call(m, "textId"))
                w.uint32(10).string(m.textId);
            if (m.hideInfoButton != null && Object.hasOwnProperty.call(m, "hideInfoButton"))
                w.uint32(16).bool(m.hideInfoButton);
            return w;
        };

        /**
         * Decodes an Option message from the specified reader or buffer.
         * @function decode
         * @memberof PickList.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PickList.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PickList.Option();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.textId = r.string();
                        break;
                    }
                case 2: {
                        m.hideInfoButton = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Option message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PickList.Option
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {PickList.Option} Option
         */
        Option.fromObject = function fromObject(d) {
            if (d instanceof $root.PickList.Option)
                return d;
            var m = new $root.PickList.Option();
            if (d.textId != null) {
                m.textId = String(d.textId);
            }
            if (d.hideInfoButton != null) {
                m.hideInfoButton = Boolean(d.hideInfoButton);
            }
            return m;
        };

        /**
         * Creates a plain object from an Option message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PickList.Option
         * @static
         * @param {PickList.Option} m Option
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Option.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.textId = "";
                d.hideInfoButton = false;
            }
            if (m.textId != null && m.hasOwnProperty("textId")) {
                d.textId = m.textId;
            }
            if (m.hideInfoButton != null && m.hasOwnProperty("hideInfoButton")) {
                d.hideInfoButton = m.hideInfoButton;
            }
            return d;
        };

        /**
         * Converts this Option to JSON.
         * @function toJSON
         * @memberof PickList.Option
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Option.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Option
         * @function getTypeUrl
         * @memberof PickList.Option
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PickList.Option";
        };

        return Option;
    })();

    return PickList;
})();

$root.Measure = (function() {

    /**
     * Properties of a Measure.
     * @exports IMeasure
     * @interface IMeasure
     */

    /**
     * Constructs a new Measure.
     * @exports Measure
     * @classdesc Represents a Measure.
     * @implements IMeasure
     * @constructor
     * @param {IMeasure=} [p] Properties to set
     */
    function Measure(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Creates a new Measure instance using the specified properties.
     * @function create
     * @memberof Measure
     * @static
     * @param {IMeasure=} [properties] Properties to set
     * @returns {Measure} Measure instance
     */
    Measure.create = function create(properties) {
        return new Measure(properties);
    };

    /**
     * Encodes the specified Measure message. Does not implicitly {@link Measure.verify|verify} messages.
     * @function encode
     * @memberof Measure
     * @static
     * @param {IMeasure} m Measure message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Measure.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes a Measure message from the specified reader or buffer.
     * @function decode
     * @memberof Measure
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Measure} Measure
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Measure.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Measure();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Measure message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Measure
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Measure} Measure
     */
    Measure.fromObject = function fromObject(d) {
        if (d instanceof $root.Measure)
            return d;
        return new $root.Measure();
    };

    /**
     * Creates a plain object from a Measure message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Measure
     * @static
     * @param {Measure} m Measure
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Measure.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Measure to JSON.
     * @function toJSON
     * @memberof Measure
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Measure.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Measure
     * @function getTypeUrl
     * @memberof Measure
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Measure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Measure";
    };

    Measure.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof Measure
         * @interface IItem
         */

        /**
         * Constructs a new Item.
         * @memberof Measure
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {Measure.IItem=} [p] Properties to set
         */
        function Item(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof Measure.Item
         * @static
         * @param {Measure.IItem=} [properties] Properties to set
         * @returns {Measure.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link Measure.Item.verify|verify} messages.
         * @function encode
         * @memberof Measure.Item
         * @static
         * @param {Measure.IItem} m Item message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof Measure.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Measure.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Measure.Item();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Item message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Measure.Item
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Measure.Item} Item
         */
        Item.fromObject = function fromObject(d) {
            if (d instanceof $root.Measure.Item)
                return d;
            return new $root.Measure.Item();
        };

        /**
         * Creates a plain object from an Item message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Measure.Item
         * @static
         * @param {Measure.Item} m Item
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Item.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Item to JSON.
         * @function toJSON
         * @memberof Measure.Item
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Item.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof Measure.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Measure.Item";
        };

        return Item;
    })();

    Measure.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof Measure
         * @interface IPresentation
         * @property {streem.api.IPoint|null} [vertex_2Position] Presentation vertex_2Position
         * @property {Measure.Presentation.Units|null} [units] Presentation units
         */

        /**
         * Constructs a new Presentation.
         * @memberof Measure
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {Measure.IPresentation=} [p] Properties to set
         */
        function Presentation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Presentation vertex_2Position.
         * @member {streem.api.IPoint|null|undefined} vertex_2Position
         * @memberof Measure.Presentation
         * @instance
         */
        Presentation.prototype.vertex_2Position = null;

        /**
         * Presentation units.
         * @member {Measure.Presentation.Units} units
         * @memberof Measure.Presentation
         * @instance
         */
        Presentation.prototype.units = 0;

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof Measure.Presentation
         * @static
         * @param {Measure.IPresentation=} [properties] Properties to set
         * @returns {Measure.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link Measure.Presentation.verify|verify} messages.
         * @function encode
         * @memberof Measure.Presentation
         * @static
         * @param {Measure.IPresentation} m Presentation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.vertex_2Position != null && Object.hasOwnProperty.call(m, "vertex_2Position"))
                $root.streem.api.Point.encode(m.vertex_2Position, w.uint32(10).fork()).ldelim();
            if (m.units != null && Object.hasOwnProperty.call(m, "units"))
                w.uint32(16).int32(m.units);
            return w;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof Measure.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Measure.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Measure.Presentation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.vertex_2Position = $root.streem.api.Point.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.units = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Measure.Presentation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Measure.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(d) {
            if (d instanceof $root.Measure.Presentation)
                return d;
            var m = new $root.Measure.Presentation();
            if (d.vertex_2Position != null) {
                if (typeof d.vertex_2Position !== "object")
                    throw TypeError(".Measure.Presentation.vertex_2Position: object expected");
                m.vertex_2Position = $root.streem.api.Point.fromObject(d.vertex_2Position);
            }
            switch (d.units) {
            default:
                if (typeof d.units === "number") {
                    m.units = d.units;
                    break;
                }
                break;
            case "UNITS_INVALID":
            case 0:
                m.units = 0;
                break;
            case "UNITS_INCHES":
            case 1:
                m.units = 1;
                break;
            case "UNITS_FEET":
            case 2:
                m.units = 2;
                break;
            case "UNITS_MILLIMETERS":
            case 3:
                m.units = 3;
                break;
            case "UNITS_CENTIMETERS":
            case 4:
                m.units = 4;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Measure.Presentation
         * @static
         * @param {Measure.Presentation} m Presentation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.vertex_2Position = null;
                d.units = o.enums === String ? "UNITS_INVALID" : 0;
            }
            if (m.vertex_2Position != null && m.hasOwnProperty("vertex_2Position")) {
                d.vertex_2Position = $root.streem.api.Point.toObject(m.vertex_2Position, o);
            }
            if (m.units != null && m.hasOwnProperty("units")) {
                d.units = o.enums === String ? $root.Measure.Presentation.Units[m.units] === undefined ? m.units : $root.Measure.Presentation.Units[m.units] : m.units;
            }
            return d;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof Measure.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Presentation
         * @function getTypeUrl
         * @memberof Measure.Presentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Presentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Measure.Presentation";
        };

        /**
         * Units enum.
         * @name Measure.Presentation.Units
         * @enum {number}
         * @property {number} UNITS_INVALID=0 UNITS_INVALID value
         * @property {number} UNITS_INCHES=1 UNITS_INCHES value
         * @property {number} UNITS_FEET=2 UNITS_FEET value
         * @property {number} UNITS_MILLIMETERS=3 UNITS_MILLIMETERS value
         * @property {number} UNITS_CENTIMETERS=4 UNITS_CENTIMETERS value
         */
        Presentation.Units = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNITS_INVALID"] = 0;
            values[valuesById[1] = "UNITS_INCHES"] = 1;
            values[valuesById[2] = "UNITS_FEET"] = 2;
            values[valuesById[3] = "UNITS_MILLIMETERS"] = 3;
            values[valuesById[4] = "UNITS_CENTIMETERS"] = 4;
            return values;
        })();

        return Presentation;
    })();

    return Measure;
})();

$root.TextTheme = (function() {

    /**
     * Properties of a TextTheme.
     * @exports ITextTheme
     * @interface ITextTheme
     * @property {IViewStyle|null} [viewStyle] TextTheme viewStyle
     * @property {ITextStyle|null} [titleStyle] TextTheme titleStyle
     * @property {ITextStyle|null} [contentStyle] TextTheme contentStyle
     */

    /**
     * Constructs a new TextTheme.
     * @exports TextTheme
     * @classdesc Represents a TextTheme.
     * @implements ITextTheme
     * @constructor
     * @param {ITextTheme=} [p] Properties to set
     */
    function TextTheme(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TextTheme viewStyle.
     * @member {IViewStyle|null|undefined} viewStyle
     * @memberof TextTheme
     * @instance
     */
    TextTheme.prototype.viewStyle = null;

    /**
     * TextTheme titleStyle.
     * @member {ITextStyle|null|undefined} titleStyle
     * @memberof TextTheme
     * @instance
     */
    TextTheme.prototype.titleStyle = null;

    /**
     * TextTheme contentStyle.
     * @member {ITextStyle|null|undefined} contentStyle
     * @memberof TextTheme
     * @instance
     */
    TextTheme.prototype.contentStyle = null;

    /**
     * Creates a new TextTheme instance using the specified properties.
     * @function create
     * @memberof TextTheme
     * @static
     * @param {ITextTheme=} [properties] Properties to set
     * @returns {TextTheme} TextTheme instance
     */
    TextTheme.create = function create(properties) {
        return new TextTheme(properties);
    };

    /**
     * Encodes the specified TextTheme message. Does not implicitly {@link TextTheme.verify|verify} messages.
     * @function encode
     * @memberof TextTheme
     * @static
     * @param {ITextTheme} m TextTheme message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextTheme.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.viewStyle != null && Object.hasOwnProperty.call(m, "viewStyle"))
            $root.ViewStyle.encode(m.viewStyle, w.uint32(10).fork()).ldelim();
        if (m.titleStyle != null && Object.hasOwnProperty.call(m, "titleStyle"))
            $root.TextStyle.encode(m.titleStyle, w.uint32(18).fork()).ldelim();
        if (m.contentStyle != null && Object.hasOwnProperty.call(m, "contentStyle"))
            $root.TextStyle.encode(m.contentStyle, w.uint32(26).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a TextTheme message from the specified reader or buffer.
     * @function decode
     * @memberof TextTheme
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TextTheme} TextTheme
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextTheme.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TextTheme();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.viewStyle = $root.ViewStyle.decode(r, r.uint32());
                    break;
                }
            case 2: {
                    m.titleStyle = $root.TextStyle.decode(r, r.uint32());
                    break;
                }
            case 3: {
                    m.contentStyle = $root.TextStyle.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TextTheme message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TextTheme
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TextTheme} TextTheme
     */
    TextTheme.fromObject = function fromObject(d) {
        if (d instanceof $root.TextTheme)
            return d;
        var m = new $root.TextTheme();
        if (d.viewStyle != null) {
            if (typeof d.viewStyle !== "object")
                throw TypeError(".TextTheme.viewStyle: object expected");
            m.viewStyle = $root.ViewStyle.fromObject(d.viewStyle);
        }
        if (d.titleStyle != null) {
            if (typeof d.titleStyle !== "object")
                throw TypeError(".TextTheme.titleStyle: object expected");
            m.titleStyle = $root.TextStyle.fromObject(d.titleStyle);
        }
        if (d.contentStyle != null) {
            if (typeof d.contentStyle !== "object")
                throw TypeError(".TextTheme.contentStyle: object expected");
            m.contentStyle = $root.TextStyle.fromObject(d.contentStyle);
        }
        return m;
    };

    /**
     * Creates a plain object from a TextTheme message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TextTheme
     * @static
     * @param {TextTheme} m TextTheme
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TextTheme.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.viewStyle = null;
            d.titleStyle = null;
            d.contentStyle = null;
        }
        if (m.viewStyle != null && m.hasOwnProperty("viewStyle")) {
            d.viewStyle = $root.ViewStyle.toObject(m.viewStyle, o);
        }
        if (m.titleStyle != null && m.hasOwnProperty("titleStyle")) {
            d.titleStyle = $root.TextStyle.toObject(m.titleStyle, o);
        }
        if (m.contentStyle != null && m.hasOwnProperty("contentStyle")) {
            d.contentStyle = $root.TextStyle.toObject(m.contentStyle, o);
        }
        return d;
    };

    /**
     * Converts this TextTheme to JSON.
     * @function toJSON
     * @memberof TextTheme
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TextTheme.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TextTheme
     * @function getTypeUrl
     * @memberof TextTheme
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TextTheme.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TextTheme";
    };

    return TextTheme;
})();

$root.PickListTheme = (function() {

    /**
     * Properties of a PickListTheme.
     * @exports IPickListTheme
     * @interface IPickListTheme
     * @property {IViewStyle|null} [optionViewStyle] PickListTheme optionViewStyle
     * @property {ITextStyle|null} [optionTextStyle] PickListTheme optionTextStyle
     */

    /**
     * Constructs a new PickListTheme.
     * @exports PickListTheme
     * @classdesc Represents a PickListTheme.
     * @implements IPickListTheme
     * @constructor
     * @param {IPickListTheme=} [p] Properties to set
     */
    function PickListTheme(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * PickListTheme optionViewStyle.
     * @member {IViewStyle|null|undefined} optionViewStyle
     * @memberof PickListTheme
     * @instance
     */
    PickListTheme.prototype.optionViewStyle = null;

    /**
     * PickListTheme optionTextStyle.
     * @member {ITextStyle|null|undefined} optionTextStyle
     * @memberof PickListTheme
     * @instance
     */
    PickListTheme.prototype.optionTextStyle = null;

    /**
     * Creates a new PickListTheme instance using the specified properties.
     * @function create
     * @memberof PickListTheme
     * @static
     * @param {IPickListTheme=} [properties] Properties to set
     * @returns {PickListTheme} PickListTheme instance
     */
    PickListTheme.create = function create(properties) {
        return new PickListTheme(properties);
    };

    /**
     * Encodes the specified PickListTheme message. Does not implicitly {@link PickListTheme.verify|verify} messages.
     * @function encode
     * @memberof PickListTheme
     * @static
     * @param {IPickListTheme} m PickListTheme message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PickListTheme.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.optionViewStyle != null && Object.hasOwnProperty.call(m, "optionViewStyle"))
            $root.ViewStyle.encode(m.optionViewStyle, w.uint32(10).fork()).ldelim();
        if (m.optionTextStyle != null && Object.hasOwnProperty.call(m, "optionTextStyle"))
            $root.TextStyle.encode(m.optionTextStyle, w.uint32(18).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a PickListTheme message from the specified reader or buffer.
     * @function decode
     * @memberof PickListTheme
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {PickListTheme} PickListTheme
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PickListTheme.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.PickListTheme();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.optionViewStyle = $root.ViewStyle.decode(r, r.uint32());
                    break;
                }
            case 2: {
                    m.optionTextStyle = $root.TextStyle.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a PickListTheme message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PickListTheme
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {PickListTheme} PickListTheme
     */
    PickListTheme.fromObject = function fromObject(d) {
        if (d instanceof $root.PickListTheme)
            return d;
        var m = new $root.PickListTheme();
        if (d.optionViewStyle != null) {
            if (typeof d.optionViewStyle !== "object")
                throw TypeError(".PickListTheme.optionViewStyle: object expected");
            m.optionViewStyle = $root.ViewStyle.fromObject(d.optionViewStyle);
        }
        if (d.optionTextStyle != null) {
            if (typeof d.optionTextStyle !== "object")
                throw TypeError(".PickListTheme.optionTextStyle: object expected");
            m.optionTextStyle = $root.TextStyle.fromObject(d.optionTextStyle);
        }
        return m;
    };

    /**
     * Creates a plain object from a PickListTheme message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PickListTheme
     * @static
     * @param {PickListTheme} m PickListTheme
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PickListTheme.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.optionViewStyle = null;
            d.optionTextStyle = null;
        }
        if (m.optionViewStyle != null && m.hasOwnProperty("optionViewStyle")) {
            d.optionViewStyle = $root.ViewStyle.toObject(m.optionViewStyle, o);
        }
        if (m.optionTextStyle != null && m.hasOwnProperty("optionTextStyle")) {
            d.optionTextStyle = $root.TextStyle.toObject(m.optionTextStyle, o);
        }
        return d;
    };

    /**
     * Converts this PickListTheme to JSON.
     * @function toJSON
     * @memberof PickListTheme
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PickListTheme.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PickListTheme
     * @function getTypeUrl
     * @memberof PickListTheme
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PickListTheme.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PickListTheme";
    };

    return PickListTheme;
})();

$root.ViewStyle = (function() {

    /**
     * Properties of a ViewStyle.
     * @exports IViewStyle
     * @interface IViewStyle
     * @property {string|null} [backgroundColor] ViewStyle backgroundColor
     * @property {Array.<number>|null} [padding] ViewStyle padding
     * @property {Array.<number>|null} [borderRadius] ViewStyle borderRadius
     */

    /**
     * Constructs a new ViewStyle.
     * @exports ViewStyle
     * @classdesc Represents a ViewStyle.
     * @implements IViewStyle
     * @constructor
     * @param {IViewStyle=} [p] Properties to set
     */
    function ViewStyle(p) {
        this.padding = [];
        this.borderRadius = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * ViewStyle backgroundColor.
     * @member {string} backgroundColor
     * @memberof ViewStyle
     * @instance
     */
    ViewStyle.prototype.backgroundColor = "";

    /**
     * ViewStyle padding.
     * @member {Array.<number>} padding
     * @memberof ViewStyle
     * @instance
     */
    ViewStyle.prototype.padding = $util.emptyArray;

    /**
     * ViewStyle borderRadius.
     * @member {Array.<number>} borderRadius
     * @memberof ViewStyle
     * @instance
     */
    ViewStyle.prototype.borderRadius = $util.emptyArray;

    /**
     * Creates a new ViewStyle instance using the specified properties.
     * @function create
     * @memberof ViewStyle
     * @static
     * @param {IViewStyle=} [properties] Properties to set
     * @returns {ViewStyle} ViewStyle instance
     */
    ViewStyle.create = function create(properties) {
        return new ViewStyle(properties);
    };

    /**
     * Encodes the specified ViewStyle message. Does not implicitly {@link ViewStyle.verify|verify} messages.
     * @function encode
     * @memberof ViewStyle
     * @static
     * @param {IViewStyle} m ViewStyle message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ViewStyle.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.backgroundColor != null && Object.hasOwnProperty.call(m, "backgroundColor"))
            w.uint32(10).string(m.backgroundColor);
        if (m.padding != null && m.padding.length) {
            w.uint32(18).fork();
            for (var i = 0; i < m.padding.length; ++i)
                w.int32(m.padding[i]);
            w.ldelim();
        }
        if (m.borderRadius != null && m.borderRadius.length) {
            w.uint32(26).fork();
            for (var i = 0; i < m.borderRadius.length; ++i)
                w.float(m.borderRadius[i]);
            w.ldelim();
        }
        return w;
    };

    /**
     * Decodes a ViewStyle message from the specified reader or buffer.
     * @function decode
     * @memberof ViewStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {ViewStyle} ViewStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ViewStyle.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.ViewStyle();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.backgroundColor = r.string();
                    break;
                }
            case 2: {
                    if (!(m.padding && m.padding.length))
                        m.padding = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.padding.push(r.int32());
                    } else
                        m.padding.push(r.int32());
                    break;
                }
            case 3: {
                    if (!(m.borderRadius && m.borderRadius.length))
                        m.borderRadius = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.borderRadius.push(r.float());
                    } else
                        m.borderRadius.push(r.float());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a ViewStyle message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ViewStyle
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {ViewStyle} ViewStyle
     */
    ViewStyle.fromObject = function fromObject(d) {
        if (d instanceof $root.ViewStyle)
            return d;
        var m = new $root.ViewStyle();
        if (d.backgroundColor != null) {
            m.backgroundColor = String(d.backgroundColor);
        }
        if (d.padding) {
            if (!Array.isArray(d.padding))
                throw TypeError(".ViewStyle.padding: array expected");
            m.padding = [];
            for (var i = 0; i < d.padding.length; ++i) {
                m.padding[i] = d.padding[i] | 0;
            }
        }
        if (d.borderRadius) {
            if (!Array.isArray(d.borderRadius))
                throw TypeError(".ViewStyle.borderRadius: array expected");
            m.borderRadius = [];
            for (var i = 0; i < d.borderRadius.length; ++i) {
                m.borderRadius[i] = Number(d.borderRadius[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a ViewStyle message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ViewStyle
     * @static
     * @param {ViewStyle} m ViewStyle
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ViewStyle.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.padding = [];
            d.borderRadius = [];
        }
        if (o.defaults) {
            d.backgroundColor = "";
        }
        if (m.backgroundColor != null && m.hasOwnProperty("backgroundColor")) {
            d.backgroundColor = m.backgroundColor;
        }
        if (m.padding && m.padding.length) {
            d.padding = [];
            for (var j = 0; j < m.padding.length; ++j) {
                d.padding[j] = m.padding[j];
            }
        }
        if (m.borderRadius && m.borderRadius.length) {
            d.borderRadius = [];
            for (var j = 0; j < m.borderRadius.length; ++j) {
                d.borderRadius[j] = o.json && !isFinite(m.borderRadius[j]) ? String(m.borderRadius[j]) : m.borderRadius[j];
            }
        }
        return d;
    };

    /**
     * Converts this ViewStyle to JSON.
     * @function toJSON
     * @memberof ViewStyle
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ViewStyle.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ViewStyle
     * @function getTypeUrl
     * @memberof ViewStyle
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ViewStyle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ViewStyle";
    };

    return ViewStyle;
})();

$root.TextStyle = (function() {

    /**
     * Properties of a TextStyle.
     * @exports ITextStyle
     * @interface ITextStyle
     * @property {number|null} [size] TextStyle size
     * @property {string|null} [color] TextStyle color
     * @property {string|null} [font] TextStyle font
     */

    /**
     * Constructs a new TextStyle.
     * @exports TextStyle
     * @classdesc Represents a TextStyle.
     * @implements ITextStyle
     * @constructor
     * @param {ITextStyle=} [p] Properties to set
     */
    function TextStyle(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TextStyle size.
     * @member {number} size
     * @memberof TextStyle
     * @instance
     */
    TextStyle.prototype.size = 0;

    /**
     * TextStyle color.
     * @member {string} color
     * @memberof TextStyle
     * @instance
     */
    TextStyle.prototype.color = "";

    /**
     * TextStyle font.
     * @member {string} font
     * @memberof TextStyle
     * @instance
     */
    TextStyle.prototype.font = "";

    /**
     * Creates a new TextStyle instance using the specified properties.
     * @function create
     * @memberof TextStyle
     * @static
     * @param {ITextStyle=} [properties] Properties to set
     * @returns {TextStyle} TextStyle instance
     */
    TextStyle.create = function create(properties) {
        return new TextStyle(properties);
    };

    /**
     * Encodes the specified TextStyle message. Does not implicitly {@link TextStyle.verify|verify} messages.
     * @function encode
     * @memberof TextStyle
     * @static
     * @param {ITextStyle} m TextStyle message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextStyle.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.size != null && Object.hasOwnProperty.call(m, "size"))
            w.uint32(13).float(m.size);
        if (m.color != null && Object.hasOwnProperty.call(m, "color"))
            w.uint32(18).string(m.color);
        if (m.font != null && Object.hasOwnProperty.call(m, "font"))
            w.uint32(26).string(m.font);
        return w;
    };

    /**
     * Decodes a TextStyle message from the specified reader or buffer.
     * @function decode
     * @memberof TextStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TextStyle} TextStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextStyle.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TextStyle();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.size = r.float();
                    break;
                }
            case 2: {
                    m.color = r.string();
                    break;
                }
            case 3: {
                    m.font = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TextStyle message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TextStyle
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TextStyle} TextStyle
     */
    TextStyle.fromObject = function fromObject(d) {
        if (d instanceof $root.TextStyle)
            return d;
        var m = new $root.TextStyle();
        if (d.size != null) {
            m.size = Number(d.size);
        }
        if (d.color != null) {
            m.color = String(d.color);
        }
        if (d.font != null) {
            m.font = String(d.font);
        }
        return m;
    };

    /**
     * Creates a plain object from a TextStyle message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TextStyle
     * @static
     * @param {TextStyle} m TextStyle
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TextStyle.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.size = 0;
            d.color = "";
            d.font = "";
        }
        if (m.size != null && m.hasOwnProperty("size")) {
            d.size = o.json && !isFinite(m.size) ? String(m.size) : m.size;
        }
        if (m.color != null && m.hasOwnProperty("color")) {
            d.color = m.color;
        }
        if (m.font != null && m.hasOwnProperty("font")) {
            d.font = m.font;
        }
        return d;
    };

    /**
     * Converts this TextStyle to JSON.
     * @function toJSON
     * @memberof TextStyle
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TextStyle.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TextStyle
     * @function getTypeUrl
     * @memberof TextStyle
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TextStyle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TextStyle";
    };

    return TextStyle;
})();

$root.Light = (function() {

    /**
     * Properties of a Light.
     * @exports ILight
     * @interface ILight
     * @property {string|null} [id] Light id
     * @property {Light.IAmbient|null} [ambient] Light ambient
     * @property {Light.IPointLight|null} [pointLight] Light pointLight
     * @property {Light.IAndroidLightProbe|null} [androidLightProbe] Light androidLightProbe
     * @property {Light.IDirectionalLight|null} [directionalLight] Light directionalLight
     */

    /**
     * Constructs a new Light.
     * @exports Light
     * @classdesc Represents a Light.
     * @implements ILight
     * @constructor
     * @param {ILight=} [p] Properties to set
     */
    function Light(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Light id.
     * @member {string} id
     * @memberof Light
     * @instance
     */
    Light.prototype.id = "";

    /**
     * Light ambient.
     * @member {Light.IAmbient|null|undefined} ambient
     * @memberof Light
     * @instance
     */
    Light.prototype.ambient = null;

    /**
     * Light pointLight.
     * @member {Light.IPointLight|null|undefined} pointLight
     * @memberof Light
     * @instance
     */
    Light.prototype.pointLight = null;

    /**
     * Light androidLightProbe.
     * @member {Light.IAndroidLightProbe|null|undefined} androidLightProbe
     * @memberof Light
     * @instance
     */
    Light.prototype.androidLightProbe = null;

    /**
     * Light directionalLight.
     * @member {Light.IDirectionalLight|null|undefined} directionalLight
     * @memberof Light
     * @instance
     */
    Light.prototype.directionalLight = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * Light light.
     * @member {"ambient"|"pointLight"|"androidLightProbe"|"directionalLight"|undefined} light
     * @memberof Light
     * @instance
     */
    Object.defineProperty(Light.prototype, "light", {
        get: $util.oneOfGetter($oneOfFields = ["ambient", "pointLight", "androidLightProbe", "directionalLight"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Light instance using the specified properties.
     * @function create
     * @memberof Light
     * @static
     * @param {ILight=} [properties] Properties to set
     * @returns {Light} Light instance
     */
    Light.create = function create(properties) {
        return new Light(properties);
    };

    /**
     * Encodes the specified Light message. Does not implicitly {@link Light.verify|verify} messages.
     * @function encode
     * @memberof Light
     * @static
     * @param {ILight} m Light message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Light.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.ambient != null && Object.hasOwnProperty.call(m, "ambient"))
            $root.Light.Ambient.encode(m.ambient, w.uint32(802).fork()).ldelim();
        if (m.pointLight != null && Object.hasOwnProperty.call(m, "pointLight"))
            $root.Light.PointLight.encode(m.pointLight, w.uint32(810).fork()).ldelim();
        if (m.androidLightProbe != null && Object.hasOwnProperty.call(m, "androidLightProbe"))
            $root.Light.AndroidLightProbe.encode(m.androidLightProbe, w.uint32(818).fork()).ldelim();
        if (m.directionalLight != null && Object.hasOwnProperty.call(m, "directionalLight"))
            $root.Light.DirectionalLight.encode(m.directionalLight, w.uint32(826).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a Light message from the specified reader or buffer.
     * @function decode
     * @memberof Light
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Light} Light
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Light.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Light();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 100: {
                    m.ambient = $root.Light.Ambient.decode(r, r.uint32());
                    break;
                }
            case 101: {
                    m.pointLight = $root.Light.PointLight.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.androidLightProbe = $root.Light.AndroidLightProbe.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.directionalLight = $root.Light.DirectionalLight.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Light message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Light
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Light} Light
     */
    Light.fromObject = function fromObject(d) {
        if (d instanceof $root.Light)
            return d;
        var m = new $root.Light();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.ambient != null) {
            if (typeof d.ambient !== "object")
                throw TypeError(".Light.ambient: object expected");
            m.ambient = $root.Light.Ambient.fromObject(d.ambient);
        }
        if (d.pointLight != null) {
            if (typeof d.pointLight !== "object")
                throw TypeError(".Light.pointLight: object expected");
            m.pointLight = $root.Light.PointLight.fromObject(d.pointLight);
        }
        if (d.androidLightProbe != null) {
            if (typeof d.androidLightProbe !== "object")
                throw TypeError(".Light.androidLightProbe: object expected");
            m.androidLightProbe = $root.Light.AndroidLightProbe.fromObject(d.androidLightProbe);
        }
        if (d.directionalLight != null) {
            if (typeof d.directionalLight !== "object")
                throw TypeError(".Light.directionalLight: object expected");
            m.directionalLight = $root.Light.DirectionalLight.fromObject(d.directionalLight);
        }
        return m;
    };

    /**
     * Creates a plain object from a Light message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Light
     * @static
     * @param {Light} m Light
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Light.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.ambient != null && m.hasOwnProperty("ambient")) {
            d.ambient = $root.Light.Ambient.toObject(m.ambient, o);
            if (o.oneofs)
                d.light = "ambient";
        }
        if (m.pointLight != null && m.hasOwnProperty("pointLight")) {
            d.pointLight = $root.Light.PointLight.toObject(m.pointLight, o);
            if (o.oneofs)
                d.light = "pointLight";
        }
        if (m.androidLightProbe != null && m.hasOwnProperty("androidLightProbe")) {
            d.androidLightProbe = $root.Light.AndroidLightProbe.toObject(m.androidLightProbe, o);
            if (o.oneofs)
                d.light = "androidLightProbe";
        }
        if (m.directionalLight != null && m.hasOwnProperty("directionalLight")) {
            d.directionalLight = $root.Light.DirectionalLight.toObject(m.directionalLight, o);
            if (o.oneofs)
                d.light = "directionalLight";
        }
        return d;
    };

    /**
     * Converts this Light to JSON.
     * @function toJSON
     * @memberof Light
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Light.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Light
     * @function getTypeUrl
     * @memberof Light
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Light.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Light";
    };

    Light.Ambient = (function() {

        /**
         * Properties of an Ambient.
         * @memberof Light
         * @interface IAmbient
         * @property {number|null} [temperatureKelvin] Ambient temperatureKelvin
         * @property {number|null} [intensityLumens] Ambient intensityLumens
         */

        /**
         * Constructs a new Ambient.
         * @memberof Light
         * @classdesc Represents an Ambient.
         * @implements IAmbient
         * @constructor
         * @param {Light.IAmbient=} [p] Properties to set
         */
        function Ambient(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Ambient temperatureKelvin.
         * @member {number} temperatureKelvin
         * @memberof Light.Ambient
         * @instance
         */
        Ambient.prototype.temperatureKelvin = 0;

        /**
         * Ambient intensityLumens.
         * @member {number} intensityLumens
         * @memberof Light.Ambient
         * @instance
         */
        Ambient.prototype.intensityLumens = 0;

        /**
         * Creates a new Ambient instance using the specified properties.
         * @function create
         * @memberof Light.Ambient
         * @static
         * @param {Light.IAmbient=} [properties] Properties to set
         * @returns {Light.Ambient} Ambient instance
         */
        Ambient.create = function create(properties) {
            return new Ambient(properties);
        };

        /**
         * Encodes the specified Ambient message. Does not implicitly {@link Light.Ambient.verify|verify} messages.
         * @function encode
         * @memberof Light.Ambient
         * @static
         * @param {Light.IAmbient} m Ambient message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ambient.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.temperatureKelvin != null && Object.hasOwnProperty.call(m, "temperatureKelvin"))
                w.uint32(13).float(m.temperatureKelvin);
            if (m.intensityLumens != null && Object.hasOwnProperty.call(m, "intensityLumens"))
                w.uint32(21).float(m.intensityLumens);
            return w;
        };

        /**
         * Decodes an Ambient message from the specified reader or buffer.
         * @function decode
         * @memberof Light.Ambient
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Light.Ambient} Ambient
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ambient.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Light.Ambient();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.temperatureKelvin = r.float();
                        break;
                    }
                case 2: {
                        m.intensityLumens = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Ambient message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Light.Ambient
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Light.Ambient} Ambient
         */
        Ambient.fromObject = function fromObject(d) {
            if (d instanceof $root.Light.Ambient)
                return d;
            var m = new $root.Light.Ambient();
            if (d.temperatureKelvin != null) {
                m.temperatureKelvin = Number(d.temperatureKelvin);
            }
            if (d.intensityLumens != null) {
                m.intensityLumens = Number(d.intensityLumens);
            }
            return m;
        };

        /**
         * Creates a plain object from an Ambient message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Light.Ambient
         * @static
         * @param {Light.Ambient} m Ambient
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ambient.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.temperatureKelvin = 0;
                d.intensityLumens = 0;
            }
            if (m.temperatureKelvin != null && m.hasOwnProperty("temperatureKelvin")) {
                d.temperatureKelvin = o.json && !isFinite(m.temperatureKelvin) ? String(m.temperatureKelvin) : m.temperatureKelvin;
            }
            if (m.intensityLumens != null && m.hasOwnProperty("intensityLumens")) {
                d.intensityLumens = o.json && !isFinite(m.intensityLumens) ? String(m.intensityLumens) : m.intensityLumens;
            }
            return d;
        };

        /**
         * Converts this Ambient to JSON.
         * @function toJSON
         * @memberof Light.Ambient
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ambient.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Ambient
         * @function getTypeUrl
         * @memberof Light.Ambient
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Ambient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Light.Ambient";
        };

        return Ambient;
    })();

    Light.PointLight = (function() {

        /**
         * Properties of a PointLight.
         * @memberof Light
         * @interface IPointLight
         * @property {streem.api.IPoint|null} [position] PointLight position
         * @property {number|null} [temperatureKelvin] PointLight temperatureKelvin
         * @property {number|null} [intensityLumens] PointLight intensityLumens
         */

        /**
         * Constructs a new PointLight.
         * @memberof Light
         * @classdesc Represents a PointLight.
         * @implements IPointLight
         * @constructor
         * @param {Light.IPointLight=} [p] Properties to set
         */
        function PointLight(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PointLight position.
         * @member {streem.api.IPoint|null|undefined} position
         * @memberof Light.PointLight
         * @instance
         */
        PointLight.prototype.position = null;

        /**
         * PointLight temperatureKelvin.
         * @member {number} temperatureKelvin
         * @memberof Light.PointLight
         * @instance
         */
        PointLight.prototype.temperatureKelvin = 0;

        /**
         * PointLight intensityLumens.
         * @member {number} intensityLumens
         * @memberof Light.PointLight
         * @instance
         */
        PointLight.prototype.intensityLumens = 0;

        /**
         * Creates a new PointLight instance using the specified properties.
         * @function create
         * @memberof Light.PointLight
         * @static
         * @param {Light.IPointLight=} [properties] Properties to set
         * @returns {Light.PointLight} PointLight instance
         */
        PointLight.create = function create(properties) {
            return new PointLight(properties);
        };

        /**
         * Encodes the specified PointLight message. Does not implicitly {@link Light.PointLight.verify|verify} messages.
         * @function encode
         * @memberof Light.PointLight
         * @static
         * @param {Light.IPointLight} m PointLight message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PointLight.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.position != null && Object.hasOwnProperty.call(m, "position"))
                $root.streem.api.Point.encode(m.position, w.uint32(10).fork()).ldelim();
            if (m.temperatureKelvin != null && Object.hasOwnProperty.call(m, "temperatureKelvin"))
                w.uint32(21).float(m.temperatureKelvin);
            if (m.intensityLumens != null && Object.hasOwnProperty.call(m, "intensityLumens"))
                w.uint32(29).float(m.intensityLumens);
            return w;
        };

        /**
         * Decodes a PointLight message from the specified reader or buffer.
         * @function decode
         * @memberof Light.PointLight
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Light.PointLight} PointLight
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PointLight.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Light.PointLight();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.position = $root.streem.api.Point.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.temperatureKelvin = r.float();
                        break;
                    }
                case 3: {
                        m.intensityLumens = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PointLight message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Light.PointLight
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Light.PointLight} PointLight
         */
        PointLight.fromObject = function fromObject(d) {
            if (d instanceof $root.Light.PointLight)
                return d;
            var m = new $root.Light.PointLight();
            if (d.position != null) {
                if (typeof d.position !== "object")
                    throw TypeError(".Light.PointLight.position: object expected");
                m.position = $root.streem.api.Point.fromObject(d.position);
            }
            if (d.temperatureKelvin != null) {
                m.temperatureKelvin = Number(d.temperatureKelvin);
            }
            if (d.intensityLumens != null) {
                m.intensityLumens = Number(d.intensityLumens);
            }
            return m;
        };

        /**
         * Creates a plain object from a PointLight message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Light.PointLight
         * @static
         * @param {Light.PointLight} m PointLight
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PointLight.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.position = null;
                d.temperatureKelvin = 0;
                d.intensityLumens = 0;
            }
            if (m.position != null && m.hasOwnProperty("position")) {
                d.position = $root.streem.api.Point.toObject(m.position, o);
            }
            if (m.temperatureKelvin != null && m.hasOwnProperty("temperatureKelvin")) {
                d.temperatureKelvin = o.json && !isFinite(m.temperatureKelvin) ? String(m.temperatureKelvin) : m.temperatureKelvin;
            }
            if (m.intensityLumens != null && m.hasOwnProperty("intensityLumens")) {
                d.intensityLumens = o.json && !isFinite(m.intensityLumens) ? String(m.intensityLumens) : m.intensityLumens;
            }
            return d;
        };

        /**
         * Converts this PointLight to JSON.
         * @function toJSON
         * @memberof Light.PointLight
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PointLight.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PointLight
         * @function getTypeUrl
         * @memberof Light.PointLight
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PointLight.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Light.PointLight";
        };

        return PointLight;
    })();

    Light.AndroidLightProbe = (function() {

        /**
         * Properties of an AndroidLightProbe.
         * @memberof Light
         * @interface IAndroidLightProbe
         * @property {number|null} [temperatureKelvin] AndroidLightProbe temperatureKelvin
         * @property {number|null} [intensityLumens] AndroidLightProbe intensityLumens
         * @property {Array.<number>|null} [rotation] AndroidLightProbe rotation
         */

        /**
         * Constructs a new AndroidLightProbe.
         * @memberof Light
         * @classdesc Represents an AndroidLightProbe.
         * @implements IAndroidLightProbe
         * @constructor
         * @param {Light.IAndroidLightProbe=} [p] Properties to set
         */
        function AndroidLightProbe(p) {
            this.rotation = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AndroidLightProbe temperatureKelvin.
         * @member {number} temperatureKelvin
         * @memberof Light.AndroidLightProbe
         * @instance
         */
        AndroidLightProbe.prototype.temperatureKelvin = 0;

        /**
         * AndroidLightProbe intensityLumens.
         * @member {number} intensityLumens
         * @memberof Light.AndroidLightProbe
         * @instance
         */
        AndroidLightProbe.prototype.intensityLumens = 0;

        /**
         * AndroidLightProbe rotation.
         * @member {Array.<number>} rotation
         * @memberof Light.AndroidLightProbe
         * @instance
         */
        AndroidLightProbe.prototype.rotation = $util.emptyArray;

        /**
         * Creates a new AndroidLightProbe instance using the specified properties.
         * @function create
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {Light.IAndroidLightProbe=} [properties] Properties to set
         * @returns {Light.AndroidLightProbe} AndroidLightProbe instance
         */
        AndroidLightProbe.create = function create(properties) {
            return new AndroidLightProbe(properties);
        };

        /**
         * Encodes the specified AndroidLightProbe message. Does not implicitly {@link Light.AndroidLightProbe.verify|verify} messages.
         * @function encode
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {Light.IAndroidLightProbe} m AndroidLightProbe message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AndroidLightProbe.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.temperatureKelvin != null && Object.hasOwnProperty.call(m, "temperatureKelvin"))
                w.uint32(13).float(m.temperatureKelvin);
            if (m.intensityLumens != null && Object.hasOwnProperty.call(m, "intensityLumens"))
                w.uint32(21).float(m.intensityLumens);
            if (m.rotation != null && m.rotation.length) {
                w.uint32(26).fork();
                for (var i = 0; i < m.rotation.length; ++i)
                    w.float(m.rotation[i]);
                w.ldelim();
            }
            return w;
        };

        /**
         * Decodes an AndroidLightProbe message from the specified reader or buffer.
         * @function decode
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Light.AndroidLightProbe} AndroidLightProbe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AndroidLightProbe.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Light.AndroidLightProbe();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.temperatureKelvin = r.float();
                        break;
                    }
                case 2: {
                        m.intensityLumens = r.float();
                        break;
                    }
                case 3: {
                        if (!(m.rotation && m.rotation.length))
                            m.rotation = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.rotation.push(r.float());
                        } else
                            m.rotation.push(r.float());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AndroidLightProbe message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Light.AndroidLightProbe} AndroidLightProbe
         */
        AndroidLightProbe.fromObject = function fromObject(d) {
            if (d instanceof $root.Light.AndroidLightProbe)
                return d;
            var m = new $root.Light.AndroidLightProbe();
            if (d.temperatureKelvin != null) {
                m.temperatureKelvin = Number(d.temperatureKelvin);
            }
            if (d.intensityLumens != null) {
                m.intensityLumens = Number(d.intensityLumens);
            }
            if (d.rotation) {
                if (!Array.isArray(d.rotation))
                    throw TypeError(".Light.AndroidLightProbe.rotation: array expected");
                m.rotation = [];
                for (var i = 0; i < d.rotation.length; ++i) {
                    m.rotation[i] = Number(d.rotation[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an AndroidLightProbe message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {Light.AndroidLightProbe} m AndroidLightProbe
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AndroidLightProbe.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.rotation = [];
            }
            if (o.defaults) {
                d.temperatureKelvin = 0;
                d.intensityLumens = 0;
            }
            if (m.temperatureKelvin != null && m.hasOwnProperty("temperatureKelvin")) {
                d.temperatureKelvin = o.json && !isFinite(m.temperatureKelvin) ? String(m.temperatureKelvin) : m.temperatureKelvin;
            }
            if (m.intensityLumens != null && m.hasOwnProperty("intensityLumens")) {
                d.intensityLumens = o.json && !isFinite(m.intensityLumens) ? String(m.intensityLumens) : m.intensityLumens;
            }
            if (m.rotation && m.rotation.length) {
                d.rotation = [];
                for (var j = 0; j < m.rotation.length; ++j) {
                    d.rotation[j] = o.json && !isFinite(m.rotation[j]) ? String(m.rotation[j]) : m.rotation[j];
                }
            }
            return d;
        };

        /**
         * Converts this AndroidLightProbe to JSON.
         * @function toJSON
         * @memberof Light.AndroidLightProbe
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AndroidLightProbe.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AndroidLightProbe
         * @function getTypeUrl
         * @memberof Light.AndroidLightProbe
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AndroidLightProbe.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Light.AndroidLightProbe";
        };

        return AndroidLightProbe;
    })();

    Light.DirectionalLight = (function() {

        /**
         * Properties of a DirectionalLight.
         * @memberof Light
         * @interface IDirectionalLight
         * @property {Array.<number>|null} [direction] DirectionalLight direction
         * @property {string|null} [color] DirectionalLight color
         * @property {number|null} [intensityLux] DirectionalLight intensityLux
         * @property {boolean|null} [castsShadows] DirectionalLight castsShadows
         */

        /**
         * Constructs a new DirectionalLight.
         * @memberof Light
         * @classdesc Represents a DirectionalLight.
         * @implements IDirectionalLight
         * @constructor
         * @param {Light.IDirectionalLight=} [p] Properties to set
         */
        function DirectionalLight(p) {
            this.direction = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DirectionalLight direction.
         * @member {Array.<number>} direction
         * @memberof Light.DirectionalLight
         * @instance
         */
        DirectionalLight.prototype.direction = $util.emptyArray;

        /**
         * DirectionalLight color.
         * @member {string} color
         * @memberof Light.DirectionalLight
         * @instance
         */
        DirectionalLight.prototype.color = "";

        /**
         * DirectionalLight intensityLux.
         * @member {number} intensityLux
         * @memberof Light.DirectionalLight
         * @instance
         */
        DirectionalLight.prototype.intensityLux = 0;

        /**
         * DirectionalLight castsShadows.
         * @member {boolean} castsShadows
         * @memberof Light.DirectionalLight
         * @instance
         */
        DirectionalLight.prototype.castsShadows = false;

        /**
         * Creates a new DirectionalLight instance using the specified properties.
         * @function create
         * @memberof Light.DirectionalLight
         * @static
         * @param {Light.IDirectionalLight=} [properties] Properties to set
         * @returns {Light.DirectionalLight} DirectionalLight instance
         */
        DirectionalLight.create = function create(properties) {
            return new DirectionalLight(properties);
        };

        /**
         * Encodes the specified DirectionalLight message. Does not implicitly {@link Light.DirectionalLight.verify|verify} messages.
         * @function encode
         * @memberof Light.DirectionalLight
         * @static
         * @param {Light.IDirectionalLight} m DirectionalLight message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DirectionalLight.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.direction != null && m.direction.length) {
                w.uint32(10).fork();
                for (var i = 0; i < m.direction.length; ++i)
                    w.float(m.direction[i]);
                w.ldelim();
            }
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(18).string(m.color);
            if (m.intensityLux != null && Object.hasOwnProperty.call(m, "intensityLux"))
                w.uint32(29).float(m.intensityLux);
            if (m.castsShadows != null && Object.hasOwnProperty.call(m, "castsShadows"))
                w.uint32(32).bool(m.castsShadows);
            return w;
        };

        /**
         * Decodes a DirectionalLight message from the specified reader or buffer.
         * @function decode
         * @memberof Light.DirectionalLight
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Light.DirectionalLight} DirectionalLight
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DirectionalLight.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Light.DirectionalLight();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.direction && m.direction.length))
                            m.direction = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.direction.push(r.float());
                        } else
                            m.direction.push(r.float());
                        break;
                    }
                case 2: {
                        m.color = r.string();
                        break;
                    }
                case 3: {
                        m.intensityLux = r.float();
                        break;
                    }
                case 4: {
                        m.castsShadows = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DirectionalLight message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Light.DirectionalLight
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Light.DirectionalLight} DirectionalLight
         */
        DirectionalLight.fromObject = function fromObject(d) {
            if (d instanceof $root.Light.DirectionalLight)
                return d;
            var m = new $root.Light.DirectionalLight();
            if (d.direction) {
                if (!Array.isArray(d.direction))
                    throw TypeError(".Light.DirectionalLight.direction: array expected");
                m.direction = [];
                for (var i = 0; i < d.direction.length; ++i) {
                    m.direction[i] = Number(d.direction[i]);
                }
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            if (d.intensityLux != null) {
                m.intensityLux = Number(d.intensityLux);
            }
            if (d.castsShadows != null) {
                m.castsShadows = Boolean(d.castsShadows);
            }
            return m;
        };

        /**
         * Creates a plain object from a DirectionalLight message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Light.DirectionalLight
         * @static
         * @param {Light.DirectionalLight} m DirectionalLight
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DirectionalLight.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.direction = [];
            }
            if (o.defaults) {
                d.color = "";
                d.intensityLux = 0;
                d.castsShadows = false;
            }
            if (m.direction && m.direction.length) {
                d.direction = [];
                for (var j = 0; j < m.direction.length; ++j) {
                    d.direction[j] = o.json && !isFinite(m.direction[j]) ? String(m.direction[j]) : m.direction[j];
                }
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            if (m.intensityLux != null && m.hasOwnProperty("intensityLux")) {
                d.intensityLux = o.json && !isFinite(m.intensityLux) ? String(m.intensityLux) : m.intensityLux;
            }
            if (m.castsShadows != null && m.hasOwnProperty("castsShadows")) {
                d.castsShadows = m.castsShadows;
            }
            return d;
        };

        /**
         * Converts this DirectionalLight to JSON.
         * @function toJSON
         * @memberof Light.DirectionalLight
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DirectionalLight.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DirectionalLight
         * @function getTypeUrl
         * @memberof Light.DirectionalLight
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DirectionalLight.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Light.DirectionalLight";
        };

        return DirectionalLight;
    })();

    return Light;
})();

$root.RoomString = (function() {

    /**
     * Properties of a RoomString.
     * @exports IRoomString
     * @interface IRoomString
     * @property {string|null} [id] RoomString id
     * @property {string|null} [content] RoomString content
     */

    /**
     * Constructs a new RoomString.
     * @exports RoomString
     * @classdesc Represents a RoomString.
     * @implements IRoomString
     * @constructor
     * @param {IRoomString=} [p] Properties to set
     */
    function RoomString(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * RoomString id.
     * @member {string} id
     * @memberof RoomString
     * @instance
     */
    RoomString.prototype.id = "";

    /**
     * RoomString content.
     * @member {string} content
     * @memberof RoomString
     * @instance
     */
    RoomString.prototype.content = "";

    /**
     * Creates a new RoomString instance using the specified properties.
     * @function create
     * @memberof RoomString
     * @static
     * @param {IRoomString=} [properties] Properties to set
     * @returns {RoomString} RoomString instance
     */
    RoomString.create = function create(properties) {
        return new RoomString(properties);
    };

    /**
     * Encodes the specified RoomString message. Does not implicitly {@link RoomString.verify|verify} messages.
     * @function encode
     * @memberof RoomString
     * @static
     * @param {IRoomString} m RoomString message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RoomString.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.content != null && Object.hasOwnProperty.call(m, "content"))
            w.uint32(18).string(m.content);
        return w;
    };

    /**
     * Decodes a RoomString message from the specified reader or buffer.
     * @function decode
     * @memberof RoomString
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {RoomString} RoomString
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RoomString.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.RoomString();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    m.content = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a RoomString message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RoomString
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {RoomString} RoomString
     */
    RoomString.fromObject = function fromObject(d) {
        if (d instanceof $root.RoomString)
            return d;
        var m = new $root.RoomString();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.content != null) {
            m.content = String(d.content);
        }
        return m;
    };

    /**
     * Creates a plain object from a RoomString message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RoomString
     * @static
     * @param {RoomString} m RoomString
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RoomString.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.id = "";
            d.content = "";
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.content != null && m.hasOwnProperty("content")) {
            d.content = m.content;
        }
        return d;
    };

    /**
     * Converts this RoomString to JSON.
     * @function toJSON
     * @memberof RoomString
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RoomString.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RoomString
     * @function getTypeUrl
     * @memberof RoomString
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RoomString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RoomString";
    };

    return RoomString;
})();

$root.CameraIntrinsics = (function() {

    /**
     * Properties of a CameraIntrinsics.
     * @exports ICameraIntrinsics
     * @interface ICameraIntrinsics
     * @property {number|null} [focalLengthPxX] CameraIntrinsics focalLengthPxX
     * @property {number|null} [focalLengthPxY] CameraIntrinsics focalLengthPxY
     * @property {number|null} [axisSkew] CameraIntrinsics axisSkew
     * @property {number|null} [principalPointX] CameraIntrinsics principalPointX
     * @property {number|null} [principalPointY] CameraIntrinsics principalPointY
     */

    /**
     * Constructs a new CameraIntrinsics.
     * @exports CameraIntrinsics
     * @classdesc Represents a CameraIntrinsics.
     * @implements ICameraIntrinsics
     * @constructor
     * @param {ICameraIntrinsics=} [p] Properties to set
     */
    function CameraIntrinsics(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * CameraIntrinsics focalLengthPxX.
     * @member {number} focalLengthPxX
     * @memberof CameraIntrinsics
     * @instance
     */
    CameraIntrinsics.prototype.focalLengthPxX = 0;

    /**
     * CameraIntrinsics focalLengthPxY.
     * @member {number} focalLengthPxY
     * @memberof CameraIntrinsics
     * @instance
     */
    CameraIntrinsics.prototype.focalLengthPxY = 0;

    /**
     * CameraIntrinsics axisSkew.
     * @member {number} axisSkew
     * @memberof CameraIntrinsics
     * @instance
     */
    CameraIntrinsics.prototype.axisSkew = 0;

    /**
     * CameraIntrinsics principalPointX.
     * @member {number} principalPointX
     * @memberof CameraIntrinsics
     * @instance
     */
    CameraIntrinsics.prototype.principalPointX = 0;

    /**
     * CameraIntrinsics principalPointY.
     * @member {number} principalPointY
     * @memberof CameraIntrinsics
     * @instance
     */
    CameraIntrinsics.prototype.principalPointY = 0;

    /**
     * Creates a new CameraIntrinsics instance using the specified properties.
     * @function create
     * @memberof CameraIntrinsics
     * @static
     * @param {ICameraIntrinsics=} [properties] Properties to set
     * @returns {CameraIntrinsics} CameraIntrinsics instance
     */
    CameraIntrinsics.create = function create(properties) {
        return new CameraIntrinsics(properties);
    };

    /**
     * Encodes the specified CameraIntrinsics message. Does not implicitly {@link CameraIntrinsics.verify|verify} messages.
     * @function encode
     * @memberof CameraIntrinsics
     * @static
     * @param {ICameraIntrinsics} m CameraIntrinsics message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CameraIntrinsics.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.focalLengthPxX != null && Object.hasOwnProperty.call(m, "focalLengthPxX"))
            w.uint32(13).float(m.focalLengthPxX);
        if (m.focalLengthPxY != null && Object.hasOwnProperty.call(m, "focalLengthPxY"))
            w.uint32(21).float(m.focalLengthPxY);
        if (m.axisSkew != null && Object.hasOwnProperty.call(m, "axisSkew"))
            w.uint32(29).float(m.axisSkew);
        if (m.principalPointX != null && Object.hasOwnProperty.call(m, "principalPointX"))
            w.uint32(37).float(m.principalPointX);
        if (m.principalPointY != null && Object.hasOwnProperty.call(m, "principalPointY"))
            w.uint32(45).float(m.principalPointY);
        return w;
    };

    /**
     * Decodes a CameraIntrinsics message from the specified reader or buffer.
     * @function decode
     * @memberof CameraIntrinsics
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {CameraIntrinsics} CameraIntrinsics
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CameraIntrinsics.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.CameraIntrinsics();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.focalLengthPxX = r.float();
                    break;
                }
            case 2: {
                    m.focalLengthPxY = r.float();
                    break;
                }
            case 3: {
                    m.axisSkew = r.float();
                    break;
                }
            case 4: {
                    m.principalPointX = r.float();
                    break;
                }
            case 5: {
                    m.principalPointY = r.float();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a CameraIntrinsics message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CameraIntrinsics
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {CameraIntrinsics} CameraIntrinsics
     */
    CameraIntrinsics.fromObject = function fromObject(d) {
        if (d instanceof $root.CameraIntrinsics)
            return d;
        var m = new $root.CameraIntrinsics();
        if (d.focalLengthPxX != null) {
            m.focalLengthPxX = Number(d.focalLengthPxX);
        }
        if (d.focalLengthPxY != null) {
            m.focalLengthPxY = Number(d.focalLengthPxY);
        }
        if (d.axisSkew != null) {
            m.axisSkew = Number(d.axisSkew);
        }
        if (d.principalPointX != null) {
            m.principalPointX = Number(d.principalPointX);
        }
        if (d.principalPointY != null) {
            m.principalPointY = Number(d.principalPointY);
        }
        return m;
    };

    /**
     * Creates a plain object from a CameraIntrinsics message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CameraIntrinsics
     * @static
     * @param {CameraIntrinsics} m CameraIntrinsics
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CameraIntrinsics.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.focalLengthPxX = 0;
            d.focalLengthPxY = 0;
            d.axisSkew = 0;
            d.principalPointX = 0;
            d.principalPointY = 0;
        }
        if (m.focalLengthPxX != null && m.hasOwnProperty("focalLengthPxX")) {
            d.focalLengthPxX = o.json && !isFinite(m.focalLengthPxX) ? String(m.focalLengthPxX) : m.focalLengthPxX;
        }
        if (m.focalLengthPxY != null && m.hasOwnProperty("focalLengthPxY")) {
            d.focalLengthPxY = o.json && !isFinite(m.focalLengthPxY) ? String(m.focalLengthPxY) : m.focalLengthPxY;
        }
        if (m.axisSkew != null && m.hasOwnProperty("axisSkew")) {
            d.axisSkew = o.json && !isFinite(m.axisSkew) ? String(m.axisSkew) : m.axisSkew;
        }
        if (m.principalPointX != null && m.hasOwnProperty("principalPointX")) {
            d.principalPointX = o.json && !isFinite(m.principalPointX) ? String(m.principalPointX) : m.principalPointX;
        }
        if (m.principalPointY != null && m.hasOwnProperty("principalPointY")) {
            d.principalPointY = o.json && !isFinite(m.principalPointY) ? String(m.principalPointY) : m.principalPointY;
        }
        return d;
    };

    /**
     * Converts this CameraIntrinsics to JSON.
     * @function toJSON
     * @memberof CameraIntrinsics
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CameraIntrinsics.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CameraIntrinsics
     * @function getTypeUrl
     * @memberof CameraIntrinsics
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CameraIntrinsics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CameraIntrinsics";
    };

    return CameraIntrinsics;
})();

/**
 * CameraSubject enum.
 * @exports CameraSubject
 * @enum {number}
 * @property {number} CAMERA_SUBJECT_WORLD=0 CAMERA_SUBJECT_WORLD value
 * @property {number} CAMERA_SUBJECT_PERSON=1 CAMERA_SUBJECT_PERSON value
 * @property {number} CAMERA_SUBJECT_SCREEN=2 CAMERA_SUBJECT_SCREEN value
 */
$root.CameraSubject = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CAMERA_SUBJECT_WORLD"] = 0;
    values[valuesById[1] = "CAMERA_SUBJECT_PERSON"] = 1;
    values[valuesById[2] = "CAMERA_SUBJECT_SCREEN"] = 2;
    return values;
})();

/**
 * CameraSubjectResponseStatus enum.
 * @exports CameraSubjectResponseStatus
 * @enum {number}
 * @property {number} CAMERA_SUBJECT_RESPONSE_STATUS_ACCEPTED=0 CAMERA_SUBJECT_RESPONSE_STATUS_ACCEPTED value
 * @property {number} CAMERA_SUBJECT_RESPONSE_STATUS_ABORTED=1 CAMERA_SUBJECT_RESPONSE_STATUS_ABORTED value
 * @property {number} CAMERA_SUBJECT_RESPONSE_STATUS_REJECTED=2 CAMERA_SUBJECT_RESPONSE_STATUS_REJECTED value
 */
$root.CameraSubjectResponseStatus = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CAMERA_SUBJECT_RESPONSE_STATUS_ACCEPTED"] = 0;
    values[valuesById[1] = "CAMERA_SUBJECT_RESPONSE_STATUS_ABORTED"] = 1;
    values[valuesById[2] = "CAMERA_SUBJECT_RESPONSE_STATUS_REJECTED"] = 2;
    return values;
})();

/**
 * StreamingMessageProvider enum.
 * @exports StreamingMessageProvider
 * @enum {number}
 * @property {number} STREAMING_MESSAGE_PROVIDER_INVALID=0 STREAMING_MESSAGE_PROVIDER_INVALID value
 * @property {number} STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM=1 STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM value
 * @property {number} STREAMING_MESSAGE_PROVIDER_DATA_TRACK=2 STREAMING_MESSAGE_PROVIDER_DATA_TRACK value
 */
$root.StreamingMessageProvider = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "STREAMING_MESSAGE_PROVIDER_INVALID"] = 0;
    values[valuesById[1] = "STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM"] = 1;
    values[valuesById[2] = "STREAMING_MESSAGE_PROVIDER_DATA_TRACK"] = 2;
    return values;
})();

$root.VectorBuffer = (function() {

    /**
     * Properties of a VectorBuffer.
     * @exports IVectorBuffer
     * @interface IVectorBuffer
     * @property {Uint8Array|null} [bytes] VectorBuffer bytes
     * @property {number|null} [offset] VectorBuffer offset
     * @property {VectorBuffer.ComponentType|null} [componentType] VectorBuffer componentType
     * @property {number|null} [componentStride] VectorBuffer componentStride
     * @property {number|null} [componentsPerVector] VectorBuffer componentsPerVector
     * @property {number|null} [vectorStride] VectorBuffer vectorStride
     * @property {number|null} [vectorCount] VectorBuffer vectorCount
     * @property {VectorBuffer.ByteOrder|null} [byteOrder] VectorBuffer byteOrder
     */

    /**
     * Constructs a new VectorBuffer.
     * @exports VectorBuffer
     * @classdesc Represents a VectorBuffer.
     * @implements IVectorBuffer
     * @constructor
     * @param {IVectorBuffer=} [p] Properties to set
     */
    function VectorBuffer(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * VectorBuffer bytes.
     * @member {Uint8Array} bytes
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.bytes = $util.newBuffer([]);

    /**
     * VectorBuffer offset.
     * @member {number} offset
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.offset = 0;

    /**
     * VectorBuffer componentType.
     * @member {VectorBuffer.ComponentType} componentType
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.componentType = 0;

    /**
     * VectorBuffer componentStride.
     * @member {number} componentStride
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.componentStride = 0;

    /**
     * VectorBuffer componentsPerVector.
     * @member {number} componentsPerVector
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.componentsPerVector = 0;

    /**
     * VectorBuffer vectorStride.
     * @member {number} vectorStride
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.vectorStride = 0;

    /**
     * VectorBuffer vectorCount.
     * @member {number} vectorCount
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.vectorCount = 0;

    /**
     * VectorBuffer byteOrder.
     * @member {VectorBuffer.ByteOrder} byteOrder
     * @memberof VectorBuffer
     * @instance
     */
    VectorBuffer.prototype.byteOrder = 0;

    /**
     * Creates a new VectorBuffer instance using the specified properties.
     * @function create
     * @memberof VectorBuffer
     * @static
     * @param {IVectorBuffer=} [properties] Properties to set
     * @returns {VectorBuffer} VectorBuffer instance
     */
    VectorBuffer.create = function create(properties) {
        return new VectorBuffer(properties);
    };

    /**
     * Encodes the specified VectorBuffer message. Does not implicitly {@link VectorBuffer.verify|verify} messages.
     * @function encode
     * @memberof VectorBuffer
     * @static
     * @param {IVectorBuffer} m VectorBuffer message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VectorBuffer.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.bytes != null && Object.hasOwnProperty.call(m, "bytes"))
            w.uint32(10).bytes(m.bytes);
        if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
            w.uint32(16).int32(m.offset);
        if (m.componentType != null && Object.hasOwnProperty.call(m, "componentType"))
            w.uint32(24).int32(m.componentType);
        if (m.componentStride != null && Object.hasOwnProperty.call(m, "componentStride"))
            w.uint32(32).int32(m.componentStride);
        if (m.componentsPerVector != null && Object.hasOwnProperty.call(m, "componentsPerVector"))
            w.uint32(40).int32(m.componentsPerVector);
        if (m.vectorStride != null && Object.hasOwnProperty.call(m, "vectorStride"))
            w.uint32(48).int32(m.vectorStride);
        if (m.vectorCount != null && Object.hasOwnProperty.call(m, "vectorCount"))
            w.uint32(56).int32(m.vectorCount);
        if (m.byteOrder != null && Object.hasOwnProperty.call(m, "byteOrder"))
            w.uint32(64).int32(m.byteOrder);
        return w;
    };

    /**
     * Decodes a VectorBuffer message from the specified reader or buffer.
     * @function decode
     * @memberof VectorBuffer
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {VectorBuffer} VectorBuffer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VectorBuffer.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.VectorBuffer();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.bytes = r.bytes();
                    break;
                }
            case 2: {
                    m.offset = r.int32();
                    break;
                }
            case 3: {
                    m.componentType = r.int32();
                    break;
                }
            case 4: {
                    m.componentStride = r.int32();
                    break;
                }
            case 5: {
                    m.componentsPerVector = r.int32();
                    break;
                }
            case 6: {
                    m.vectorStride = r.int32();
                    break;
                }
            case 7: {
                    m.vectorCount = r.int32();
                    break;
                }
            case 8: {
                    m.byteOrder = r.int32();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a VectorBuffer message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof VectorBuffer
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {VectorBuffer} VectorBuffer
     */
    VectorBuffer.fromObject = function fromObject(d) {
        if (d instanceof $root.VectorBuffer)
            return d;
        var m = new $root.VectorBuffer();
        if (d.bytes != null) {
            if (typeof d.bytes === "string")
                $util.base64.decode(d.bytes, m.bytes = $util.newBuffer($util.base64.length(d.bytes)), 0);
            else if (d.bytes.length >= 0)
                m.bytes = d.bytes;
        }
        if (d.offset != null) {
            m.offset = d.offset | 0;
        }
        switch (d.componentType) {
        default:
            if (typeof d.componentType === "number") {
                m.componentType = d.componentType;
                break;
            }
            break;
        case "COMPONENT_TYPE_INVALID":
        case 0:
            m.componentType = 0;
            break;
        case "COMPONENT_TYPE_FLOAT":
        case 1:
            m.componentType = 1;
            break;
        case "COMPONENT_TYPE_DOUBLE":
        case 2:
            m.componentType = 2;
            break;
        case "COMPONENT_TYPE_UINT32":
        case 3:
            m.componentType = 3;
            break;
        case "COMPONENT_TYPE_UINT8":
        case 4:
            m.componentType = 4;
            break;
        case "COMPONENT_TYPE_UINT16":
        case 5:
            m.componentType = 5;
            break;
        }
        if (d.componentStride != null) {
            m.componentStride = d.componentStride | 0;
        }
        if (d.componentsPerVector != null) {
            m.componentsPerVector = d.componentsPerVector | 0;
        }
        if (d.vectorStride != null) {
            m.vectorStride = d.vectorStride | 0;
        }
        if (d.vectorCount != null) {
            m.vectorCount = d.vectorCount | 0;
        }
        switch (d.byteOrder) {
        default:
            if (typeof d.byteOrder === "number") {
                m.byteOrder = d.byteOrder;
                break;
            }
            break;
        case "BYTE_ORDER_INVALID":
        case 0:
            m.byteOrder = 0;
            break;
        case "BYTE_ORDER_BIG_ENDIAN":
        case 1:
            m.byteOrder = 1;
            break;
        case "BYTE_ORDER_LITTLE_ENDIAN":
        case 2:
            m.byteOrder = 2;
            break;
        }
        return m;
    };

    /**
     * Creates a plain object from a VectorBuffer message. Also converts values to other types if specified.
     * @function toObject
     * @memberof VectorBuffer
     * @static
     * @param {VectorBuffer} m VectorBuffer
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VectorBuffer.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            if (o.bytes === String)
                d.bytes = "";
            else {
                d.bytes = [];
                if (o.bytes !== Array)
                    d.bytes = $util.newBuffer(d.bytes);
            }
            d.offset = 0;
            d.componentType = o.enums === String ? "COMPONENT_TYPE_INVALID" : 0;
            d.componentStride = 0;
            d.componentsPerVector = 0;
            d.vectorStride = 0;
            d.vectorCount = 0;
            d.byteOrder = o.enums === String ? "BYTE_ORDER_INVALID" : 0;
        }
        if (m.bytes != null && m.hasOwnProperty("bytes")) {
            d.bytes = o.bytes === String ? $util.base64.encode(m.bytes, 0, m.bytes.length) : o.bytes === Array ? Array.prototype.slice.call(m.bytes) : m.bytes;
        }
        if (m.offset != null && m.hasOwnProperty("offset")) {
            d.offset = m.offset;
        }
        if (m.componentType != null && m.hasOwnProperty("componentType")) {
            d.componentType = o.enums === String ? $root.VectorBuffer.ComponentType[m.componentType] === undefined ? m.componentType : $root.VectorBuffer.ComponentType[m.componentType] : m.componentType;
        }
        if (m.componentStride != null && m.hasOwnProperty("componentStride")) {
            d.componentStride = m.componentStride;
        }
        if (m.componentsPerVector != null && m.hasOwnProperty("componentsPerVector")) {
            d.componentsPerVector = m.componentsPerVector;
        }
        if (m.vectorStride != null && m.hasOwnProperty("vectorStride")) {
            d.vectorStride = m.vectorStride;
        }
        if (m.vectorCount != null && m.hasOwnProperty("vectorCount")) {
            d.vectorCount = m.vectorCount;
        }
        if (m.byteOrder != null && m.hasOwnProperty("byteOrder")) {
            d.byteOrder = o.enums === String ? $root.VectorBuffer.ByteOrder[m.byteOrder] === undefined ? m.byteOrder : $root.VectorBuffer.ByteOrder[m.byteOrder] : m.byteOrder;
        }
        return d;
    };

    /**
     * Converts this VectorBuffer to JSON.
     * @function toJSON
     * @memberof VectorBuffer
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VectorBuffer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for VectorBuffer
     * @function getTypeUrl
     * @memberof VectorBuffer
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    VectorBuffer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VectorBuffer";
    };

    /**
     * ComponentType enum.
     * @name VectorBuffer.ComponentType
     * @enum {number}
     * @property {number} COMPONENT_TYPE_INVALID=0 COMPONENT_TYPE_INVALID value
     * @property {number} COMPONENT_TYPE_FLOAT=1 COMPONENT_TYPE_FLOAT value
     * @property {number} COMPONENT_TYPE_DOUBLE=2 COMPONENT_TYPE_DOUBLE value
     * @property {number} COMPONENT_TYPE_UINT32=3 COMPONENT_TYPE_UINT32 value
     * @property {number} COMPONENT_TYPE_UINT8=4 COMPONENT_TYPE_UINT8 value
     * @property {number} COMPONENT_TYPE_UINT16=5 COMPONENT_TYPE_UINT16 value
     */
    VectorBuffer.ComponentType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COMPONENT_TYPE_INVALID"] = 0;
        values[valuesById[1] = "COMPONENT_TYPE_FLOAT"] = 1;
        values[valuesById[2] = "COMPONENT_TYPE_DOUBLE"] = 2;
        values[valuesById[3] = "COMPONENT_TYPE_UINT32"] = 3;
        values[valuesById[4] = "COMPONENT_TYPE_UINT8"] = 4;
        values[valuesById[5] = "COMPONENT_TYPE_UINT16"] = 5;
        return values;
    })();

    /**
     * ByteOrder enum.
     * @name VectorBuffer.ByteOrder
     * @enum {number}
     * @property {number} BYTE_ORDER_INVALID=0 BYTE_ORDER_INVALID value
     * @property {number} BYTE_ORDER_BIG_ENDIAN=1 BYTE_ORDER_BIG_ENDIAN value
     * @property {number} BYTE_ORDER_LITTLE_ENDIAN=2 BYTE_ORDER_LITTLE_ENDIAN value
     */
    VectorBuffer.ByteOrder = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BYTE_ORDER_INVALID"] = 0;
        values[valuesById[1] = "BYTE_ORDER_BIG_ENDIAN"] = 1;
        values[valuesById[2] = "BYTE_ORDER_LITTLE_ENDIAN"] = 2;
        return values;
    })();

    return VectorBuffer;
})();

$root.TutorialList = (function() {

    /**
     * Properties of a TutorialList.
     * @exports ITutorialList
     * @interface ITutorialList
     * @property {Array.<ITutorial>|null} [tutorials] TutorialList tutorials
     */

    /**
     * Constructs a new TutorialList.
     * @exports TutorialList
     * @classdesc Represents a TutorialList.
     * @implements ITutorialList
     * @constructor
     * @param {ITutorialList=} [p] Properties to set
     */
    function TutorialList(p) {
        this.tutorials = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialList tutorials.
     * @member {Array.<ITutorial>} tutorials
     * @memberof TutorialList
     * @instance
     */
    TutorialList.prototype.tutorials = $util.emptyArray;

    /**
     * Creates a new TutorialList instance using the specified properties.
     * @function create
     * @memberof TutorialList
     * @static
     * @param {ITutorialList=} [properties] Properties to set
     * @returns {TutorialList} TutorialList instance
     */
    TutorialList.create = function create(properties) {
        return new TutorialList(properties);
    };

    /**
     * Encodes the specified TutorialList message. Does not implicitly {@link TutorialList.verify|verify} messages.
     * @function encode
     * @memberof TutorialList
     * @static
     * @param {ITutorialList} m TutorialList message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialList.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.tutorials != null && m.tutorials.length) {
            for (var i = 0; i < m.tutorials.length; ++i)
                $root.Tutorial.encode(m.tutorials[i], w.uint32(10).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a TutorialList message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialList
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialList} TutorialList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialList.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialList();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    if (!(m.tutorials && m.tutorials.length))
                        m.tutorials = [];
                    m.tutorials.push($root.Tutorial.decode(r, r.uint32()));
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialList message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialList
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialList} TutorialList
     */
    TutorialList.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialList)
            return d;
        var m = new $root.TutorialList();
        if (d.tutorials) {
            if (!Array.isArray(d.tutorials))
                throw TypeError(".TutorialList.tutorials: array expected");
            m.tutorials = [];
            for (var i = 0; i < d.tutorials.length; ++i) {
                if (typeof d.tutorials[i] !== "object")
                    throw TypeError(".TutorialList.tutorials: object expected");
                m.tutorials[i] = $root.Tutorial.fromObject(d.tutorials[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialList message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialList
     * @static
     * @param {TutorialList} m TutorialList
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialList.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.tutorials = [];
        }
        if (m.tutorials && m.tutorials.length) {
            d.tutorials = [];
            for (var j = 0; j < m.tutorials.length; ++j) {
                d.tutorials[j] = $root.Tutorial.toObject(m.tutorials[j], o);
            }
        }
        return d;
    };

    /**
     * Converts this TutorialList to JSON.
     * @function toJSON
     * @memberof TutorialList
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialList
     * @function getTypeUrl
     * @memberof TutorialList
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialList";
    };

    return TutorialList;
})();

$root.Tutorial = (function() {

    /**
     * Properties of a Tutorial.
     * @exports ITutorial
     * @interface ITutorial
     * @property {string|null} [version] Tutorial version
     * @property {string|null} [id] Tutorial id
     * @property {ITutorialAnchor|null} [anchor] Tutorial anchor
     * @property {Array.<IStep>|null} [steps] Tutorial steps
     * @property {IDestination|null} [start] Tutorial start
     * @property {ITutorialTheme|null} [theme] Tutorial theme
     * @property {Array.<IRoomString>|null} [strings] Tutorial strings
     * @property {Array.<ICatalogItem>|null} [catalog] Tutorial catalog
     * @property {Array.<ITutorialElement>|null} [elements] Tutorial elements
     */

    /**
     * Constructs a new Tutorial.
     * @exports Tutorial
     * @classdesc Represents a Tutorial.
     * @implements ITutorial
     * @constructor
     * @param {ITutorial=} [p] Properties to set
     */
    function Tutorial(p) {
        this.steps = [];
        this.strings = [];
        this.catalog = [];
        this.elements = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Tutorial version.
     * @member {string} version
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.version = "";

    /**
     * Tutorial id.
     * @member {string} id
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.id = "";

    /**
     * Tutorial anchor.
     * @member {ITutorialAnchor|null|undefined} anchor
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.anchor = null;

    /**
     * Tutorial steps.
     * @member {Array.<IStep>} steps
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.steps = $util.emptyArray;

    /**
     * Tutorial start.
     * @member {IDestination|null|undefined} start
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.start = null;

    /**
     * Tutorial theme.
     * @member {ITutorialTheme|null|undefined} theme
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.theme = null;

    /**
     * Tutorial strings.
     * @member {Array.<IRoomString>} strings
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.strings = $util.emptyArray;

    /**
     * Tutorial catalog.
     * @member {Array.<ICatalogItem>} catalog
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.catalog = $util.emptyArray;

    /**
     * Tutorial elements.
     * @member {Array.<ITutorialElement>} elements
     * @memberof Tutorial
     * @instance
     */
    Tutorial.prototype.elements = $util.emptyArray;

    /**
     * Creates a new Tutorial instance using the specified properties.
     * @function create
     * @memberof Tutorial
     * @static
     * @param {ITutorial=} [properties] Properties to set
     * @returns {Tutorial} Tutorial instance
     */
    Tutorial.create = function create(properties) {
        return new Tutorial(properties);
    };

    /**
     * Encodes the specified Tutorial message. Does not implicitly {@link Tutorial.verify|verify} messages.
     * @function encode
     * @memberof Tutorial
     * @static
     * @param {ITutorial} m Tutorial message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tutorial.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.version != null && Object.hasOwnProperty.call(m, "version"))
            w.uint32(10).string(m.version);
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(18).string(m.id);
        if (m.anchor != null && Object.hasOwnProperty.call(m, "anchor"))
            $root.TutorialAnchor.encode(m.anchor, w.uint32(26).fork()).ldelim();
        if (m.steps != null && m.steps.length) {
            for (var i = 0; i < m.steps.length; ++i)
                $root.Step.encode(m.steps[i], w.uint32(34).fork()).ldelim();
        }
        if (m.start != null && Object.hasOwnProperty.call(m, "start"))
            $root.Destination.encode(m.start, w.uint32(42).fork()).ldelim();
        if (m.theme != null && Object.hasOwnProperty.call(m, "theme"))
            $root.TutorialTheme.encode(m.theme, w.uint32(50).fork()).ldelim();
        if (m.strings != null && m.strings.length) {
            for (var i = 0; i < m.strings.length; ++i)
                $root.RoomString.encode(m.strings[i], w.uint32(58).fork()).ldelim();
        }
        if (m.catalog != null && m.catalog.length) {
            for (var i = 0; i < m.catalog.length; ++i)
                $root.CatalogItem.encode(m.catalog[i], w.uint32(66).fork()).ldelim();
        }
        if (m.elements != null && m.elements.length) {
            for (var i = 0; i < m.elements.length; ++i)
                $root.TutorialElement.encode(m.elements[i], w.uint32(74).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a Tutorial message from the specified reader or buffer.
     * @function decode
     * @memberof Tutorial
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Tutorial} Tutorial
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tutorial.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Tutorial();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.version = r.string();
                    break;
                }
            case 2: {
                    m.id = r.string();
                    break;
                }
            case 3: {
                    m.anchor = $root.TutorialAnchor.decode(r, r.uint32());
                    break;
                }
            case 4: {
                    if (!(m.steps && m.steps.length))
                        m.steps = [];
                    m.steps.push($root.Step.decode(r, r.uint32()));
                    break;
                }
            case 5: {
                    m.start = $root.Destination.decode(r, r.uint32());
                    break;
                }
            case 6: {
                    m.theme = $root.TutorialTheme.decode(r, r.uint32());
                    break;
                }
            case 7: {
                    if (!(m.strings && m.strings.length))
                        m.strings = [];
                    m.strings.push($root.RoomString.decode(r, r.uint32()));
                    break;
                }
            case 8: {
                    if (!(m.catalog && m.catalog.length))
                        m.catalog = [];
                    m.catalog.push($root.CatalogItem.decode(r, r.uint32()));
                    break;
                }
            case 9: {
                    if (!(m.elements && m.elements.length))
                        m.elements = [];
                    m.elements.push($root.TutorialElement.decode(r, r.uint32()));
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Tutorial message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Tutorial
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Tutorial} Tutorial
     */
    Tutorial.fromObject = function fromObject(d) {
        if (d instanceof $root.Tutorial)
            return d;
        var m = new $root.Tutorial();
        if (d.version != null) {
            m.version = String(d.version);
        }
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.anchor != null) {
            if (typeof d.anchor !== "object")
                throw TypeError(".Tutorial.anchor: object expected");
            m.anchor = $root.TutorialAnchor.fromObject(d.anchor);
        }
        if (d.steps) {
            if (!Array.isArray(d.steps))
                throw TypeError(".Tutorial.steps: array expected");
            m.steps = [];
            for (var i = 0; i < d.steps.length; ++i) {
                if (typeof d.steps[i] !== "object")
                    throw TypeError(".Tutorial.steps: object expected");
                m.steps[i] = $root.Step.fromObject(d.steps[i]);
            }
        }
        if (d.start != null) {
            if (typeof d.start !== "object")
                throw TypeError(".Tutorial.start: object expected");
            m.start = $root.Destination.fromObject(d.start);
        }
        if (d.theme != null) {
            if (typeof d.theme !== "object")
                throw TypeError(".Tutorial.theme: object expected");
            m.theme = $root.TutorialTheme.fromObject(d.theme);
        }
        if (d.strings) {
            if (!Array.isArray(d.strings))
                throw TypeError(".Tutorial.strings: array expected");
            m.strings = [];
            for (var i = 0; i < d.strings.length; ++i) {
                if (typeof d.strings[i] !== "object")
                    throw TypeError(".Tutorial.strings: object expected");
                m.strings[i] = $root.RoomString.fromObject(d.strings[i]);
            }
        }
        if (d.catalog) {
            if (!Array.isArray(d.catalog))
                throw TypeError(".Tutorial.catalog: array expected");
            m.catalog = [];
            for (var i = 0; i < d.catalog.length; ++i) {
                if (typeof d.catalog[i] !== "object")
                    throw TypeError(".Tutorial.catalog: object expected");
                m.catalog[i] = $root.CatalogItem.fromObject(d.catalog[i]);
            }
        }
        if (d.elements) {
            if (!Array.isArray(d.elements))
                throw TypeError(".Tutorial.elements: array expected");
            m.elements = [];
            for (var i = 0; i < d.elements.length; ++i) {
                if (typeof d.elements[i] !== "object")
                    throw TypeError(".Tutorial.elements: object expected");
                m.elements[i] = $root.TutorialElement.fromObject(d.elements[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a Tutorial message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Tutorial
     * @static
     * @param {Tutorial} m Tutorial
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Tutorial.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.steps = [];
            d.strings = [];
            d.catalog = [];
            d.elements = [];
        }
        if (o.defaults) {
            d.version = "";
            d.id = "";
            d.anchor = null;
            d.start = null;
            d.theme = null;
        }
        if (m.version != null && m.hasOwnProperty("version")) {
            d.version = m.version;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.anchor != null && m.hasOwnProperty("anchor")) {
            d.anchor = $root.TutorialAnchor.toObject(m.anchor, o);
        }
        if (m.steps && m.steps.length) {
            d.steps = [];
            for (var j = 0; j < m.steps.length; ++j) {
                d.steps[j] = $root.Step.toObject(m.steps[j], o);
            }
        }
        if (m.start != null && m.hasOwnProperty("start")) {
            d.start = $root.Destination.toObject(m.start, o);
        }
        if (m.theme != null && m.hasOwnProperty("theme")) {
            d.theme = $root.TutorialTheme.toObject(m.theme, o);
        }
        if (m.strings && m.strings.length) {
            d.strings = [];
            for (var j = 0; j < m.strings.length; ++j) {
                d.strings[j] = $root.RoomString.toObject(m.strings[j], o);
            }
        }
        if (m.catalog && m.catalog.length) {
            d.catalog = [];
            for (var j = 0; j < m.catalog.length; ++j) {
                d.catalog[j] = $root.CatalogItem.toObject(m.catalog[j], o);
            }
        }
        if (m.elements && m.elements.length) {
            d.elements = [];
            for (var j = 0; j < m.elements.length; ++j) {
                d.elements[j] = $root.TutorialElement.toObject(m.elements[j], o);
            }
        }
        return d;
    };

    /**
     * Converts this Tutorial to JSON.
     * @function toJSON
     * @memberof Tutorial
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Tutorial.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Tutorial
     * @function getTypeUrl
     * @memberof Tutorial
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Tutorial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Tutorial";
    };

    return Tutorial;
})();

$root.TutorialElement = (function() {

    /**
     * Properties of a TutorialElement.
     * @exports ITutorialElement
     * @interface ITutorialElement
     * @property {IElement|null} [element] TutorialElement element
     * @property {Array.<IPresentation>|null} [presentations] TutorialElement presentations
     */

    /**
     * Constructs a new TutorialElement.
     * @exports TutorialElement
     * @classdesc Represents a TutorialElement.
     * @implements ITutorialElement
     * @constructor
     * @param {ITutorialElement=} [p] Properties to set
     */
    function TutorialElement(p) {
        this.presentations = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialElement element.
     * @member {IElement|null|undefined} element
     * @memberof TutorialElement
     * @instance
     */
    TutorialElement.prototype.element = null;

    /**
     * TutorialElement presentations.
     * @member {Array.<IPresentation>} presentations
     * @memberof TutorialElement
     * @instance
     */
    TutorialElement.prototype.presentations = $util.emptyArray;

    /**
     * Creates a new TutorialElement instance using the specified properties.
     * @function create
     * @memberof TutorialElement
     * @static
     * @param {ITutorialElement=} [properties] Properties to set
     * @returns {TutorialElement} TutorialElement instance
     */
    TutorialElement.create = function create(properties) {
        return new TutorialElement(properties);
    };

    /**
     * Encodes the specified TutorialElement message. Does not implicitly {@link TutorialElement.verify|verify} messages.
     * @function encode
     * @memberof TutorialElement
     * @static
     * @param {ITutorialElement} m TutorialElement message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialElement.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.element != null && Object.hasOwnProperty.call(m, "element"))
            $root.Element.encode(m.element, w.uint32(10).fork()).ldelim();
        if (m.presentations != null && m.presentations.length) {
            for (var i = 0; i < m.presentations.length; ++i)
                $root.Presentation.encode(m.presentations[i], w.uint32(18).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a TutorialElement message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialElement
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialElement} TutorialElement
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialElement.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialElement();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.element = $root.Element.decode(r, r.uint32());
                    break;
                }
            case 2: {
                    if (!(m.presentations && m.presentations.length))
                        m.presentations = [];
                    m.presentations.push($root.Presentation.decode(r, r.uint32()));
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialElement message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialElement
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialElement} TutorialElement
     */
    TutorialElement.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialElement)
            return d;
        var m = new $root.TutorialElement();
        if (d.element != null) {
            if (typeof d.element !== "object")
                throw TypeError(".TutorialElement.element: object expected");
            m.element = $root.Element.fromObject(d.element);
        }
        if (d.presentations) {
            if (!Array.isArray(d.presentations))
                throw TypeError(".TutorialElement.presentations: array expected");
            m.presentations = [];
            for (var i = 0; i < d.presentations.length; ++i) {
                if (typeof d.presentations[i] !== "object")
                    throw TypeError(".TutorialElement.presentations: object expected");
                m.presentations[i] = $root.Presentation.fromObject(d.presentations[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialElement message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialElement
     * @static
     * @param {TutorialElement} m TutorialElement
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialElement.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.presentations = [];
        }
        if (o.defaults) {
            d.element = null;
        }
        if (m.element != null && m.hasOwnProperty("element")) {
            d.element = $root.Element.toObject(m.element, o);
        }
        if (m.presentations && m.presentations.length) {
            d.presentations = [];
            for (var j = 0; j < m.presentations.length; ++j) {
                d.presentations[j] = $root.Presentation.toObject(m.presentations[j], o);
            }
        }
        return d;
    };

    /**
     * Converts this TutorialElement to JSON.
     * @function toJSON
     * @memberof TutorialElement
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialElement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialElement
     * @function getTypeUrl
     * @memberof TutorialElement
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialElement";
    };

    return TutorialElement;
})();

$root.Destination = (function() {

    /**
     * Properties of a Destination.
     * @exports IDestination
     * @interface IDestination
     * @property {Destination.IStep|null} [step] Destination step
     * @property {Destination.ITutorial|null} [tutorial] Destination tutorial
     * @property {Destination.IExit|null} [exit] Destination exit
     */

    /**
     * Constructs a new Destination.
     * @exports Destination
     * @classdesc Represents a Destination.
     * @implements IDestination
     * @constructor
     * @param {IDestination=} [p] Properties to set
     */
    function Destination(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Destination step.
     * @member {Destination.IStep|null|undefined} step
     * @memberof Destination
     * @instance
     */
    Destination.prototype.step = null;

    /**
     * Destination tutorial.
     * @member {Destination.ITutorial|null|undefined} tutorial
     * @memberof Destination
     * @instance
     */
    Destination.prototype.tutorial = null;

    /**
     * Destination exit.
     * @member {Destination.IExit|null|undefined} exit
     * @memberof Destination
     * @instance
     */
    Destination.prototype.exit = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * Destination type.
     * @member {"step"|"tutorial"|"exit"|undefined} type
     * @memberof Destination
     * @instance
     */
    Object.defineProperty(Destination.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["step", "tutorial", "exit"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Destination instance using the specified properties.
     * @function create
     * @memberof Destination
     * @static
     * @param {IDestination=} [properties] Properties to set
     * @returns {Destination} Destination instance
     */
    Destination.create = function create(properties) {
        return new Destination(properties);
    };

    /**
     * Encodes the specified Destination message. Does not implicitly {@link Destination.verify|verify} messages.
     * @function encode
     * @memberof Destination
     * @static
     * @param {IDestination} m Destination message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Destination.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.step != null && Object.hasOwnProperty.call(m, "step"))
            $root.Destination.Step.encode(m.step, w.uint32(810).fork()).ldelim();
        if (m.tutorial != null && Object.hasOwnProperty.call(m, "tutorial"))
            $root.Destination.Tutorial.encode(m.tutorial, w.uint32(818).fork()).ldelim();
        if (m.exit != null && Object.hasOwnProperty.call(m, "exit"))
            $root.Destination.Exit.encode(m.exit, w.uint32(826).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a Destination message from the specified reader or buffer.
     * @function decode
     * @memberof Destination
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Destination} Destination
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Destination.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Destination();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 101: {
                    m.step = $root.Destination.Step.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.tutorial = $root.Destination.Tutorial.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.exit = $root.Destination.Exit.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Destination message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Destination
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Destination} Destination
     */
    Destination.fromObject = function fromObject(d) {
        if (d instanceof $root.Destination)
            return d;
        var m = new $root.Destination();
        if (d.step != null) {
            if (typeof d.step !== "object")
                throw TypeError(".Destination.step: object expected");
            m.step = $root.Destination.Step.fromObject(d.step);
        }
        if (d.tutorial != null) {
            if (typeof d.tutorial !== "object")
                throw TypeError(".Destination.tutorial: object expected");
            m.tutorial = $root.Destination.Tutorial.fromObject(d.tutorial);
        }
        if (d.exit != null) {
            if (typeof d.exit !== "object")
                throw TypeError(".Destination.exit: object expected");
            m.exit = $root.Destination.Exit.fromObject(d.exit);
        }
        return m;
    };

    /**
     * Creates a plain object from a Destination message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Destination
     * @static
     * @param {Destination} m Destination
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Destination.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (m.step != null && m.hasOwnProperty("step")) {
            d.step = $root.Destination.Step.toObject(m.step, o);
            if (o.oneofs)
                d.type = "step";
        }
        if (m.tutorial != null && m.hasOwnProperty("tutorial")) {
            d.tutorial = $root.Destination.Tutorial.toObject(m.tutorial, o);
            if (o.oneofs)
                d.type = "tutorial";
        }
        if (m.exit != null && m.hasOwnProperty("exit")) {
            d.exit = $root.Destination.Exit.toObject(m.exit, o);
            if (o.oneofs)
                d.type = "exit";
        }
        return d;
    };

    /**
     * Converts this Destination to JSON.
     * @function toJSON
     * @memberof Destination
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Destination.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Destination
     * @function getTypeUrl
     * @memberof Destination
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Destination.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Destination";
    };

    Destination.Step = (function() {

        /**
         * Properties of a Step.
         * @memberof Destination
         * @interface IStep
         * @property {string|null} [stepId] Step stepId
         */

        /**
         * Constructs a new Step.
         * @memberof Destination
         * @classdesc Represents a Step.
         * @implements IStep
         * @constructor
         * @param {Destination.IStep=} [p] Properties to set
         */
        function Step(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Step stepId.
         * @member {string} stepId
         * @memberof Destination.Step
         * @instance
         */
        Step.prototype.stepId = "";

        /**
         * Creates a new Step instance using the specified properties.
         * @function create
         * @memberof Destination.Step
         * @static
         * @param {Destination.IStep=} [properties] Properties to set
         * @returns {Destination.Step} Step instance
         */
        Step.create = function create(properties) {
            return new Step(properties);
        };

        /**
         * Encodes the specified Step message. Does not implicitly {@link Destination.Step.verify|verify} messages.
         * @function encode
         * @memberof Destination.Step
         * @static
         * @param {Destination.IStep} m Step message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Step.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.stepId != null && Object.hasOwnProperty.call(m, "stepId"))
                w.uint32(10).string(m.stepId);
            return w;
        };

        /**
         * Decodes a Step message from the specified reader or buffer.
         * @function decode
         * @memberof Destination.Step
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Destination.Step} Step
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Step.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Destination.Step();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.stepId = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Step message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Destination.Step
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Destination.Step} Step
         */
        Step.fromObject = function fromObject(d) {
            if (d instanceof $root.Destination.Step)
                return d;
            var m = new $root.Destination.Step();
            if (d.stepId != null) {
                m.stepId = String(d.stepId);
            }
            return m;
        };

        /**
         * Creates a plain object from a Step message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Destination.Step
         * @static
         * @param {Destination.Step} m Step
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Step.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.stepId = "";
            }
            if (m.stepId != null && m.hasOwnProperty("stepId")) {
                d.stepId = m.stepId;
            }
            return d;
        };

        /**
         * Converts this Step to JSON.
         * @function toJSON
         * @memberof Destination.Step
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Step.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Step
         * @function getTypeUrl
         * @memberof Destination.Step
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Step.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Destination.Step";
        };

        return Step;
    })();

    Destination.Tutorial = (function() {

        /**
         * Properties of a Tutorial.
         * @memberof Destination
         * @interface ITutorial
         * @property {string|null} [tutorialId] Tutorial tutorialId
         * @property {Object.<string,IDestination>|null} [exits] Tutorial exits
         */

        /**
         * Constructs a new Tutorial.
         * @memberof Destination
         * @classdesc Represents a Tutorial.
         * @implements ITutorial
         * @constructor
         * @param {Destination.ITutorial=} [p] Properties to set
         */
        function Tutorial(p) {
            this.exits = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Tutorial tutorialId.
         * @member {string} tutorialId
         * @memberof Destination.Tutorial
         * @instance
         */
        Tutorial.prototype.tutorialId = "";

        /**
         * Tutorial exits.
         * @member {Object.<string,IDestination>} exits
         * @memberof Destination.Tutorial
         * @instance
         */
        Tutorial.prototype.exits = $util.emptyObject;

        /**
         * Creates a new Tutorial instance using the specified properties.
         * @function create
         * @memberof Destination.Tutorial
         * @static
         * @param {Destination.ITutorial=} [properties] Properties to set
         * @returns {Destination.Tutorial} Tutorial instance
         */
        Tutorial.create = function create(properties) {
            return new Tutorial(properties);
        };

        /**
         * Encodes the specified Tutorial message. Does not implicitly {@link Destination.Tutorial.verify|verify} messages.
         * @function encode
         * @memberof Destination.Tutorial
         * @static
         * @param {Destination.ITutorial} m Tutorial message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tutorial.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.tutorialId != null && Object.hasOwnProperty.call(m, "tutorialId"))
                w.uint32(10).string(m.tutorialId);
            if (m.exits != null && Object.hasOwnProperty.call(m, "exits")) {
                for (var ks = Object.keys(m.exits), i = 0; i < ks.length; ++i) {
                    w.uint32(18).fork().uint32(10).string(ks[i]);
                    $root.Destination.encode(m.exits[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                }
            }
            return w;
        };

        /**
         * Decodes a Tutorial message from the specified reader or buffer.
         * @function decode
         * @memberof Destination.Tutorial
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Destination.Tutorial} Tutorial
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tutorial.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Destination.Tutorial(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.tutorialId = r.string();
                        break;
                    }
                case 2: {
                        if (m.exits === $util.emptyObject)
                            m.exits = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = null;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = $root.Destination.decode(r, r.uint32());
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.exits[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Tutorial message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Destination.Tutorial
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Destination.Tutorial} Tutorial
         */
        Tutorial.fromObject = function fromObject(d) {
            if (d instanceof $root.Destination.Tutorial)
                return d;
            var m = new $root.Destination.Tutorial();
            if (d.tutorialId != null) {
                m.tutorialId = String(d.tutorialId);
            }
            if (d.exits) {
                if (typeof d.exits !== "object")
                    throw TypeError(".Destination.Tutorial.exits: object expected");
                m.exits = {};
                for (var ks = Object.keys(d.exits), i = 0; i < ks.length; ++i) {
                    if (typeof d.exits[ks[i]] !== "object")
                        throw TypeError(".Destination.Tutorial.exits: object expected");
                    m.exits[ks[i]] = $root.Destination.fromObject(d.exits[ks[i]]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a Tutorial message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Destination.Tutorial
         * @static
         * @param {Destination.Tutorial} m Tutorial
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tutorial.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.exits = {};
            }
            if (o.defaults) {
                d.tutorialId = "";
            }
            if (m.tutorialId != null && m.hasOwnProperty("tutorialId")) {
                d.tutorialId = m.tutorialId;
            }
            var ks2;
            if (m.exits && (ks2 = Object.keys(m.exits)).length) {
                d.exits = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.exits[ks2[j]] = $root.Destination.toObject(m.exits[ks2[j]], o);
                }
            }
            return d;
        };

        /**
         * Converts this Tutorial to JSON.
         * @function toJSON
         * @memberof Destination.Tutorial
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tutorial.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Tutorial
         * @function getTypeUrl
         * @memberof Destination.Tutorial
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Tutorial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Destination.Tutorial";
        };

        return Tutorial;
    })();

    Destination.Exit = (function() {

        /**
         * Properties of an Exit.
         * @memberof Destination
         * @interface IExit
         * @property {string|null} [exitCode] Exit exitCode
         */

        /**
         * Constructs a new Exit.
         * @memberof Destination
         * @classdesc Represents an Exit.
         * @implements IExit
         * @constructor
         * @param {Destination.IExit=} [p] Properties to set
         */
        function Exit(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Exit exitCode.
         * @member {string} exitCode
         * @memberof Destination.Exit
         * @instance
         */
        Exit.prototype.exitCode = "";

        /**
         * Creates a new Exit instance using the specified properties.
         * @function create
         * @memberof Destination.Exit
         * @static
         * @param {Destination.IExit=} [properties] Properties to set
         * @returns {Destination.Exit} Exit instance
         */
        Exit.create = function create(properties) {
            return new Exit(properties);
        };

        /**
         * Encodes the specified Exit message. Does not implicitly {@link Destination.Exit.verify|verify} messages.
         * @function encode
         * @memberof Destination.Exit
         * @static
         * @param {Destination.IExit} m Exit message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Exit.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.exitCode != null && Object.hasOwnProperty.call(m, "exitCode"))
                w.uint32(10).string(m.exitCode);
            return w;
        };

        /**
         * Decodes an Exit message from the specified reader or buffer.
         * @function decode
         * @memberof Destination.Exit
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Destination.Exit} Exit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Exit.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Destination.Exit();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.exitCode = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Exit message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Destination.Exit
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Destination.Exit} Exit
         */
        Exit.fromObject = function fromObject(d) {
            if (d instanceof $root.Destination.Exit)
                return d;
            var m = new $root.Destination.Exit();
            if (d.exitCode != null) {
                m.exitCode = String(d.exitCode);
            }
            return m;
        };

        /**
         * Creates a plain object from an Exit message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Destination.Exit
         * @static
         * @param {Destination.Exit} m Exit
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Exit.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.exitCode = "";
            }
            if (m.exitCode != null && m.hasOwnProperty("exitCode")) {
                d.exitCode = m.exitCode;
            }
            return d;
        };

        /**
         * Converts this Exit to JSON.
         * @function toJSON
         * @memberof Destination.Exit
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Exit.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Exit
         * @function getTypeUrl
         * @memberof Destination.Exit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Exit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Destination.Exit";
        };

        return Exit;
    })();

    return Destination;
})();

$root.Transition = (function() {

    /**
     * Properties of a Transition.
     * @exports ITransition
     * @interface ITransition
     * @property {Transition.ILabeledDestination|null} [next] Transition next
     * @property {Transition.IYesNo|null} [yesNo] Transition yesNo
     * @property {Transition.IElementTap|null} [elementTap] Transition elementTap
     */

    /**
     * Constructs a new Transition.
     * @exports Transition
     * @classdesc Represents a Transition.
     * @implements ITransition
     * @constructor
     * @param {ITransition=} [p] Properties to set
     */
    function Transition(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Transition next.
     * @member {Transition.ILabeledDestination|null|undefined} next
     * @memberof Transition
     * @instance
     */
    Transition.prototype.next = null;

    /**
     * Transition yesNo.
     * @member {Transition.IYesNo|null|undefined} yesNo
     * @memberof Transition
     * @instance
     */
    Transition.prototype.yesNo = null;

    /**
     * Transition elementTap.
     * @member {Transition.IElementTap|null|undefined} elementTap
     * @memberof Transition
     * @instance
     */
    Transition.prototype.elementTap = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * Transition type.
     * @member {"next"|"yesNo"|"elementTap"|undefined} type
     * @memberof Transition
     * @instance
     */
    Object.defineProperty(Transition.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["next", "yesNo", "elementTap"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Transition instance using the specified properties.
     * @function create
     * @memberof Transition
     * @static
     * @param {ITransition=} [properties] Properties to set
     * @returns {Transition} Transition instance
     */
    Transition.create = function create(properties) {
        return new Transition(properties);
    };

    /**
     * Encodes the specified Transition message. Does not implicitly {@link Transition.verify|verify} messages.
     * @function encode
     * @memberof Transition
     * @static
     * @param {ITransition} m Transition message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Transition.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.next != null && Object.hasOwnProperty.call(m, "next"))
            $root.Transition.LabeledDestination.encode(m.next, w.uint32(810).fork()).ldelim();
        if (m.yesNo != null && Object.hasOwnProperty.call(m, "yesNo"))
            $root.Transition.YesNo.encode(m.yesNo, w.uint32(818).fork()).ldelim();
        if (m.elementTap != null && Object.hasOwnProperty.call(m, "elementTap"))
            $root.Transition.ElementTap.encode(m.elementTap, w.uint32(826).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a Transition message from the specified reader or buffer.
     * @function decode
     * @memberof Transition
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Transition} Transition
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Transition.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Transition();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 101: {
                    m.next = $root.Transition.LabeledDestination.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.yesNo = $root.Transition.YesNo.decode(r, r.uint32());
                    break;
                }
            case 103: {
                    m.elementTap = $root.Transition.ElementTap.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Transition message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Transition
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Transition} Transition
     */
    Transition.fromObject = function fromObject(d) {
        if (d instanceof $root.Transition)
            return d;
        var m = new $root.Transition();
        if (d.next != null) {
            if (typeof d.next !== "object")
                throw TypeError(".Transition.next: object expected");
            m.next = $root.Transition.LabeledDestination.fromObject(d.next);
        }
        if (d.yesNo != null) {
            if (typeof d.yesNo !== "object")
                throw TypeError(".Transition.yesNo: object expected");
            m.yesNo = $root.Transition.YesNo.fromObject(d.yesNo);
        }
        if (d.elementTap != null) {
            if (typeof d.elementTap !== "object")
                throw TypeError(".Transition.elementTap: object expected");
            m.elementTap = $root.Transition.ElementTap.fromObject(d.elementTap);
        }
        return m;
    };

    /**
     * Creates a plain object from a Transition message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Transition
     * @static
     * @param {Transition} m Transition
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Transition.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (m.next != null && m.hasOwnProperty("next")) {
            d.next = $root.Transition.LabeledDestination.toObject(m.next, o);
            if (o.oneofs)
                d.type = "next";
        }
        if (m.yesNo != null && m.hasOwnProperty("yesNo")) {
            d.yesNo = $root.Transition.YesNo.toObject(m.yesNo, o);
            if (o.oneofs)
                d.type = "yesNo";
        }
        if (m.elementTap != null && m.hasOwnProperty("elementTap")) {
            d.elementTap = $root.Transition.ElementTap.toObject(m.elementTap, o);
            if (o.oneofs)
                d.type = "elementTap";
        }
        return d;
    };

    /**
     * Converts this Transition to JSON.
     * @function toJSON
     * @memberof Transition
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Transition.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Transition
     * @function getTypeUrl
     * @memberof Transition
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Transition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Transition";
    };

    Transition.LabeledDestination = (function() {

        /**
         * Properties of a LabeledDestination.
         * @memberof Transition
         * @interface ILabeledDestination
         * @property {string|null} [text] LabeledDestination text
         * @property {IDestination|null} [destination] LabeledDestination destination
         */

        /**
         * Constructs a new LabeledDestination.
         * @memberof Transition
         * @classdesc Represents a LabeledDestination.
         * @implements ILabeledDestination
         * @constructor
         * @param {Transition.ILabeledDestination=} [p] Properties to set
         */
        function LabeledDestination(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LabeledDestination text.
         * @member {string} text
         * @memberof Transition.LabeledDestination
         * @instance
         */
        LabeledDestination.prototype.text = "";

        /**
         * LabeledDestination destination.
         * @member {IDestination|null|undefined} destination
         * @memberof Transition.LabeledDestination
         * @instance
         */
        LabeledDestination.prototype.destination = null;

        /**
         * Creates a new LabeledDestination instance using the specified properties.
         * @function create
         * @memberof Transition.LabeledDestination
         * @static
         * @param {Transition.ILabeledDestination=} [properties] Properties to set
         * @returns {Transition.LabeledDestination} LabeledDestination instance
         */
        LabeledDestination.create = function create(properties) {
            return new LabeledDestination(properties);
        };

        /**
         * Encodes the specified LabeledDestination message. Does not implicitly {@link Transition.LabeledDestination.verify|verify} messages.
         * @function encode
         * @memberof Transition.LabeledDestination
         * @static
         * @param {Transition.ILabeledDestination} m LabeledDestination message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabeledDestination.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(10).string(m.text);
            if (m.destination != null && Object.hasOwnProperty.call(m, "destination"))
                $root.Destination.encode(m.destination, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a LabeledDestination message from the specified reader or buffer.
         * @function decode
         * @memberof Transition.LabeledDestination
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Transition.LabeledDestination} LabeledDestination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabeledDestination.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Transition.LabeledDestination();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.text = r.string();
                        break;
                    }
                case 2: {
                        m.destination = $root.Destination.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LabeledDestination message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Transition.LabeledDestination
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Transition.LabeledDestination} LabeledDestination
         */
        LabeledDestination.fromObject = function fromObject(d) {
            if (d instanceof $root.Transition.LabeledDestination)
                return d;
            var m = new $root.Transition.LabeledDestination();
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.destination != null) {
                if (typeof d.destination !== "object")
                    throw TypeError(".Transition.LabeledDestination.destination: object expected");
                m.destination = $root.Destination.fromObject(d.destination);
            }
            return m;
        };

        /**
         * Creates a plain object from a LabeledDestination message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Transition.LabeledDestination
         * @static
         * @param {Transition.LabeledDestination} m LabeledDestination
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LabeledDestination.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.text = "";
                d.destination = null;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.destination != null && m.hasOwnProperty("destination")) {
                d.destination = $root.Destination.toObject(m.destination, o);
            }
            return d;
        };

        /**
         * Converts this LabeledDestination to JSON.
         * @function toJSON
         * @memberof Transition.LabeledDestination
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LabeledDestination.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LabeledDestination
         * @function getTypeUrl
         * @memberof Transition.LabeledDestination
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LabeledDestination.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Transition.LabeledDestination";
        };

        return LabeledDestination;
    })();

    Transition.YesNo = (function() {

        /**
         * Properties of a YesNo.
         * @memberof Transition
         * @interface IYesNo
         * @property {Transition.ILabeledDestination|null} [yes] YesNo yes
         * @property {Transition.ILabeledDestination|null} [no] YesNo no
         */

        /**
         * Constructs a new YesNo.
         * @memberof Transition
         * @classdesc Represents a YesNo.
         * @implements IYesNo
         * @constructor
         * @param {Transition.IYesNo=} [p] Properties to set
         */
        function YesNo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * YesNo yes.
         * @member {Transition.ILabeledDestination|null|undefined} yes
         * @memberof Transition.YesNo
         * @instance
         */
        YesNo.prototype.yes = null;

        /**
         * YesNo no.
         * @member {Transition.ILabeledDestination|null|undefined} no
         * @memberof Transition.YesNo
         * @instance
         */
        YesNo.prototype.no = null;

        /**
         * Creates a new YesNo instance using the specified properties.
         * @function create
         * @memberof Transition.YesNo
         * @static
         * @param {Transition.IYesNo=} [properties] Properties to set
         * @returns {Transition.YesNo} YesNo instance
         */
        YesNo.create = function create(properties) {
            return new YesNo(properties);
        };

        /**
         * Encodes the specified YesNo message. Does not implicitly {@link Transition.YesNo.verify|verify} messages.
         * @function encode
         * @memberof Transition.YesNo
         * @static
         * @param {Transition.IYesNo} m YesNo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        YesNo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.yes != null && Object.hasOwnProperty.call(m, "yes"))
                $root.Transition.LabeledDestination.encode(m.yes, w.uint32(10).fork()).ldelim();
            if (m.no != null && Object.hasOwnProperty.call(m, "no"))
                $root.Transition.LabeledDestination.encode(m.no, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a YesNo message from the specified reader or buffer.
         * @function decode
         * @memberof Transition.YesNo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Transition.YesNo} YesNo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        YesNo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Transition.YesNo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.yes = $root.Transition.LabeledDestination.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.no = $root.Transition.LabeledDestination.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a YesNo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Transition.YesNo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Transition.YesNo} YesNo
         */
        YesNo.fromObject = function fromObject(d) {
            if (d instanceof $root.Transition.YesNo)
                return d;
            var m = new $root.Transition.YesNo();
            if (d.yes != null) {
                if (typeof d.yes !== "object")
                    throw TypeError(".Transition.YesNo.yes: object expected");
                m.yes = $root.Transition.LabeledDestination.fromObject(d.yes);
            }
            if (d.no != null) {
                if (typeof d.no !== "object")
                    throw TypeError(".Transition.YesNo.no: object expected");
                m.no = $root.Transition.LabeledDestination.fromObject(d.no);
            }
            return m;
        };

        /**
         * Creates a plain object from a YesNo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Transition.YesNo
         * @static
         * @param {Transition.YesNo} m YesNo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        YesNo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.yes = null;
                d.no = null;
            }
            if (m.yes != null && m.hasOwnProperty("yes")) {
                d.yes = $root.Transition.LabeledDestination.toObject(m.yes, o);
            }
            if (m.no != null && m.hasOwnProperty("no")) {
                d.no = $root.Transition.LabeledDestination.toObject(m.no, o);
            }
            return d;
        };

        /**
         * Converts this YesNo to JSON.
         * @function toJSON
         * @memberof Transition.YesNo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        YesNo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for YesNo
         * @function getTypeUrl
         * @memberof Transition.YesNo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        YesNo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Transition.YesNo";
        };

        return YesNo;
    })();

    Transition.ElementTap = (function() {

        /**
         * Properties of an ElementTap.
         * @memberof Transition
         * @interface IElementTap
         * @property {string|null} [elementId] ElementTap elementId
         * @property {IDestination|null} [destination] ElementTap destination
         */

        /**
         * Constructs a new ElementTap.
         * @memberof Transition
         * @classdesc Represents an ElementTap.
         * @implements IElementTap
         * @constructor
         * @param {Transition.IElementTap=} [p] Properties to set
         */
        function ElementTap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ElementTap elementId.
         * @member {string} elementId
         * @memberof Transition.ElementTap
         * @instance
         */
        ElementTap.prototype.elementId = "";

        /**
         * ElementTap destination.
         * @member {IDestination|null|undefined} destination
         * @memberof Transition.ElementTap
         * @instance
         */
        ElementTap.prototype.destination = null;

        /**
         * Creates a new ElementTap instance using the specified properties.
         * @function create
         * @memberof Transition.ElementTap
         * @static
         * @param {Transition.IElementTap=} [properties] Properties to set
         * @returns {Transition.ElementTap} ElementTap instance
         */
        ElementTap.create = function create(properties) {
            return new ElementTap(properties);
        };

        /**
         * Encodes the specified ElementTap message. Does not implicitly {@link Transition.ElementTap.verify|verify} messages.
         * @function encode
         * @memberof Transition.ElementTap
         * @static
         * @param {Transition.IElementTap} m ElementTap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ElementTap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
                w.uint32(10).string(m.elementId);
            if (m.destination != null && Object.hasOwnProperty.call(m, "destination"))
                $root.Destination.encode(m.destination, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an ElementTap message from the specified reader or buffer.
         * @function decode
         * @memberof Transition.ElementTap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Transition.ElementTap} ElementTap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ElementTap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Transition.ElementTap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.elementId = r.string();
                        break;
                    }
                case 2: {
                        m.destination = $root.Destination.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an ElementTap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Transition.ElementTap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Transition.ElementTap} ElementTap
         */
        ElementTap.fromObject = function fromObject(d) {
            if (d instanceof $root.Transition.ElementTap)
                return d;
            var m = new $root.Transition.ElementTap();
            if (d.elementId != null) {
                m.elementId = String(d.elementId);
            }
            if (d.destination != null) {
                if (typeof d.destination !== "object")
                    throw TypeError(".Transition.ElementTap.destination: object expected");
                m.destination = $root.Destination.fromObject(d.destination);
            }
            return m;
        };

        /**
         * Creates a plain object from an ElementTap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Transition.ElementTap
         * @static
         * @param {Transition.ElementTap} m ElementTap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ElementTap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.elementId = "";
                d.destination = null;
            }
            if (m.elementId != null && m.hasOwnProperty("elementId")) {
                d.elementId = m.elementId;
            }
            if (m.destination != null && m.hasOwnProperty("destination")) {
                d.destination = $root.Destination.toObject(m.destination, o);
            }
            return d;
        };

        /**
         * Converts this ElementTap to JSON.
         * @function toJSON
         * @memberof Transition.ElementTap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ElementTap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ElementTap
         * @function getTypeUrl
         * @memberof Transition.ElementTap
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ElementTap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Transition.ElementTap";
        };

        return ElementTap;
    })();

    return Transition;
})();

$root.ElementPresentation = (function() {

    /**
     * Properties of an ElementPresentation.
     * @exports IElementPresentation
     * @interface IElementPresentation
     * @property {string|null} [elementId] ElementPresentation elementId
     * @property {string|null} [presentationId] ElementPresentation presentationId
     */

    /**
     * Constructs a new ElementPresentation.
     * @exports ElementPresentation
     * @classdesc Represents an ElementPresentation.
     * @implements IElementPresentation
     * @constructor
     * @param {IElementPresentation=} [p] Properties to set
     */
    function ElementPresentation(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * ElementPresentation elementId.
     * @member {string} elementId
     * @memberof ElementPresentation
     * @instance
     */
    ElementPresentation.prototype.elementId = "";

    /**
     * ElementPresentation presentationId.
     * @member {string} presentationId
     * @memberof ElementPresentation
     * @instance
     */
    ElementPresentation.prototype.presentationId = "";

    /**
     * Creates a new ElementPresentation instance using the specified properties.
     * @function create
     * @memberof ElementPresentation
     * @static
     * @param {IElementPresentation=} [properties] Properties to set
     * @returns {ElementPresentation} ElementPresentation instance
     */
    ElementPresentation.create = function create(properties) {
        return new ElementPresentation(properties);
    };

    /**
     * Encodes the specified ElementPresentation message. Does not implicitly {@link ElementPresentation.verify|verify} messages.
     * @function encode
     * @memberof ElementPresentation
     * @static
     * @param {IElementPresentation} m ElementPresentation message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ElementPresentation.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.elementId != null && Object.hasOwnProperty.call(m, "elementId"))
            w.uint32(10).string(m.elementId);
        if (m.presentationId != null && Object.hasOwnProperty.call(m, "presentationId"))
            w.uint32(18).string(m.presentationId);
        return w;
    };

    /**
     * Decodes an ElementPresentation message from the specified reader or buffer.
     * @function decode
     * @memberof ElementPresentation
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {ElementPresentation} ElementPresentation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ElementPresentation.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.ElementPresentation();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.elementId = r.string();
                    break;
                }
            case 2: {
                    m.presentationId = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates an ElementPresentation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ElementPresentation
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {ElementPresentation} ElementPresentation
     */
    ElementPresentation.fromObject = function fromObject(d) {
        if (d instanceof $root.ElementPresentation)
            return d;
        var m = new $root.ElementPresentation();
        if (d.elementId != null) {
            m.elementId = String(d.elementId);
        }
        if (d.presentationId != null) {
            m.presentationId = String(d.presentationId);
        }
        return m;
    };

    /**
     * Creates a plain object from an ElementPresentation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ElementPresentation
     * @static
     * @param {ElementPresentation} m ElementPresentation
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ElementPresentation.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.elementId = "";
            d.presentationId = "";
        }
        if (m.elementId != null && m.hasOwnProperty("elementId")) {
            d.elementId = m.elementId;
        }
        if (m.presentationId != null && m.hasOwnProperty("presentationId")) {
            d.presentationId = m.presentationId;
        }
        return d;
    };

    /**
     * Converts this ElementPresentation to JSON.
     * @function toJSON
     * @memberof ElementPresentation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ElementPresentation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ElementPresentation
     * @function getTypeUrl
     * @memberof ElementPresentation
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ElementPresentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ElementPresentation";
    };

    return ElementPresentation;
})();

$root.Step = (function() {

    /**
     * Properties of a Step.
     * @exports IStep
     * @interface IStep
     * @property {string|null} [id] Step id
     * @property {Array.<IElementPresentation>|null} [elements] Step elements
     * @property {Array.<ITransition>|null} [transitions] Step transitions
     * @property {string|null} [titleId] Step titleId
     * @property {string|null} [descriptionId] Step descriptionId
     * @property {string|null} [statusTextId] Step statusTextId
     * @property {streem.api.IPoint|null} [focalPoint] Step focalPoint
     */

    /**
     * Constructs a new Step.
     * @exports Step
     * @classdesc Represents a Step.
     * @implements IStep
     * @constructor
     * @param {IStep=} [p] Properties to set
     */
    function Step(p) {
        this.elements = [];
        this.transitions = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Step id.
     * @member {string} id
     * @memberof Step
     * @instance
     */
    Step.prototype.id = "";

    /**
     * Step elements.
     * @member {Array.<IElementPresentation>} elements
     * @memberof Step
     * @instance
     */
    Step.prototype.elements = $util.emptyArray;

    /**
     * Step transitions.
     * @member {Array.<ITransition>} transitions
     * @memberof Step
     * @instance
     */
    Step.prototype.transitions = $util.emptyArray;

    /**
     * Step titleId.
     * @member {string} titleId
     * @memberof Step
     * @instance
     */
    Step.prototype.titleId = "";

    /**
     * Step descriptionId.
     * @member {string} descriptionId
     * @memberof Step
     * @instance
     */
    Step.prototype.descriptionId = "";

    /**
     * Step statusTextId.
     * @member {string} statusTextId
     * @memberof Step
     * @instance
     */
    Step.prototype.statusTextId = "";

    /**
     * Step focalPoint.
     * @member {streem.api.IPoint|null|undefined} focalPoint
     * @memberof Step
     * @instance
     */
    Step.prototype.focalPoint = null;

    /**
     * Creates a new Step instance using the specified properties.
     * @function create
     * @memberof Step
     * @static
     * @param {IStep=} [properties] Properties to set
     * @returns {Step} Step instance
     */
    Step.create = function create(properties) {
        return new Step(properties);
    };

    /**
     * Encodes the specified Step message. Does not implicitly {@link Step.verify|verify} messages.
     * @function encode
     * @memberof Step
     * @static
     * @param {IStep} m Step message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Step.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.id != null && Object.hasOwnProperty.call(m, "id"))
            w.uint32(10).string(m.id);
        if (m.elements != null && m.elements.length) {
            for (var i = 0; i < m.elements.length; ++i)
                $root.ElementPresentation.encode(m.elements[i], w.uint32(18).fork()).ldelim();
        }
        if (m.transitions != null && m.transitions.length) {
            for (var i = 0; i < m.transitions.length; ++i)
                $root.Transition.encode(m.transitions[i], w.uint32(26).fork()).ldelim();
        }
        if (m.titleId != null && Object.hasOwnProperty.call(m, "titleId"))
            w.uint32(34).string(m.titleId);
        if (m.descriptionId != null && Object.hasOwnProperty.call(m, "descriptionId"))
            w.uint32(42).string(m.descriptionId);
        if (m.statusTextId != null && Object.hasOwnProperty.call(m, "statusTextId"))
            w.uint32(50).string(m.statusTextId);
        if (m.focalPoint != null && Object.hasOwnProperty.call(m, "focalPoint"))
            $root.streem.api.Point.encode(m.focalPoint, w.uint32(58).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a Step message from the specified reader or buffer.
     * @function decode
     * @memberof Step
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Step} Step
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Step.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Step();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.id = r.string();
                    break;
                }
            case 2: {
                    if (!(m.elements && m.elements.length))
                        m.elements = [];
                    m.elements.push($root.ElementPresentation.decode(r, r.uint32()));
                    break;
                }
            case 3: {
                    if (!(m.transitions && m.transitions.length))
                        m.transitions = [];
                    m.transitions.push($root.Transition.decode(r, r.uint32()));
                    break;
                }
            case 4: {
                    m.titleId = r.string();
                    break;
                }
            case 5: {
                    m.descriptionId = r.string();
                    break;
                }
            case 6: {
                    m.statusTextId = r.string();
                    break;
                }
            case 7: {
                    m.focalPoint = $root.streem.api.Point.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a Step message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Step
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Step} Step
     */
    Step.fromObject = function fromObject(d) {
        if (d instanceof $root.Step)
            return d;
        var m = new $root.Step();
        if (d.id != null) {
            m.id = String(d.id);
        }
        if (d.elements) {
            if (!Array.isArray(d.elements))
                throw TypeError(".Step.elements: array expected");
            m.elements = [];
            for (var i = 0; i < d.elements.length; ++i) {
                if (typeof d.elements[i] !== "object")
                    throw TypeError(".Step.elements: object expected");
                m.elements[i] = $root.ElementPresentation.fromObject(d.elements[i]);
            }
        }
        if (d.transitions) {
            if (!Array.isArray(d.transitions))
                throw TypeError(".Step.transitions: array expected");
            m.transitions = [];
            for (var i = 0; i < d.transitions.length; ++i) {
                if (typeof d.transitions[i] !== "object")
                    throw TypeError(".Step.transitions: object expected");
                m.transitions[i] = $root.Transition.fromObject(d.transitions[i]);
            }
        }
        if (d.titleId != null) {
            m.titleId = String(d.titleId);
        }
        if (d.descriptionId != null) {
            m.descriptionId = String(d.descriptionId);
        }
        if (d.statusTextId != null) {
            m.statusTextId = String(d.statusTextId);
        }
        if (d.focalPoint != null) {
            if (typeof d.focalPoint !== "object")
                throw TypeError(".Step.focalPoint: object expected");
            m.focalPoint = $root.streem.api.Point.fromObject(d.focalPoint);
        }
        return m;
    };

    /**
     * Creates a plain object from a Step message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Step
     * @static
     * @param {Step} m Step
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Step.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.elements = [];
            d.transitions = [];
        }
        if (o.defaults) {
            d.id = "";
            d.titleId = "";
            d.descriptionId = "";
            d.statusTextId = "";
            d.focalPoint = null;
        }
        if (m.id != null && m.hasOwnProperty("id")) {
            d.id = m.id;
        }
        if (m.elements && m.elements.length) {
            d.elements = [];
            for (var j = 0; j < m.elements.length; ++j) {
                d.elements[j] = $root.ElementPresentation.toObject(m.elements[j], o);
            }
        }
        if (m.transitions && m.transitions.length) {
            d.transitions = [];
            for (var j = 0; j < m.transitions.length; ++j) {
                d.transitions[j] = $root.Transition.toObject(m.transitions[j], o);
            }
        }
        if (m.titleId != null && m.hasOwnProperty("titleId")) {
            d.titleId = m.titleId;
        }
        if (m.descriptionId != null && m.hasOwnProperty("descriptionId")) {
            d.descriptionId = m.descriptionId;
        }
        if (m.statusTextId != null && m.hasOwnProperty("statusTextId")) {
            d.statusTextId = m.statusTextId;
        }
        if (m.focalPoint != null && m.hasOwnProperty("focalPoint")) {
            d.focalPoint = $root.streem.api.Point.toObject(m.focalPoint, o);
        }
        return d;
    };

    /**
     * Converts this Step to JSON.
     * @function toJSON
     * @memberof Step
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Step.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Step
     * @function getTypeUrl
     * @memberof Step
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Step.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Step";
    };

    return Step;
})();

$root.TutorialAnchor = (function() {

    /**
     * Properties of a TutorialAnchor.
     * @exports ITutorialAnchor
     * @interface ITutorialAnchor
     * @property {TutorialAnchor.IInstructions|null} [instructions] TutorialAnchor instructions
     * @property {streem.room.ar.ITrackableDefinition|null} [trackable] TutorialAnchor trackable
     * @property {streem.api.ITransform|null} [offset] TutorialAnchor offset
     * @property {Array.<IElementPresentation>|null} [anchorElements] TutorialAnchor anchorElements
     * @property {string|null} [anchorId] TutorialAnchor anchorId
     */

    /**
     * Constructs a new TutorialAnchor.
     * @exports TutorialAnchor
     * @classdesc Represents a TutorialAnchor.
     * @implements ITutorialAnchor
     * @constructor
     * @param {ITutorialAnchor=} [p] Properties to set
     */
    function TutorialAnchor(p) {
        this.anchorElements = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialAnchor instructions.
     * @member {TutorialAnchor.IInstructions|null|undefined} instructions
     * @memberof TutorialAnchor
     * @instance
     */
    TutorialAnchor.prototype.instructions = null;

    /**
     * TutorialAnchor trackable.
     * @member {streem.room.ar.ITrackableDefinition|null|undefined} trackable
     * @memberof TutorialAnchor
     * @instance
     */
    TutorialAnchor.prototype.trackable = null;

    /**
     * TutorialAnchor offset.
     * @member {streem.api.ITransform|null|undefined} offset
     * @memberof TutorialAnchor
     * @instance
     */
    TutorialAnchor.prototype.offset = null;

    /**
     * TutorialAnchor anchorElements.
     * @member {Array.<IElementPresentation>} anchorElements
     * @memberof TutorialAnchor
     * @instance
     */
    TutorialAnchor.prototype.anchorElements = $util.emptyArray;

    /**
     * TutorialAnchor anchorId.
     * @member {string} anchorId
     * @memberof TutorialAnchor
     * @instance
     */
    TutorialAnchor.prototype.anchorId = "";

    /**
     * Creates a new TutorialAnchor instance using the specified properties.
     * @function create
     * @memberof TutorialAnchor
     * @static
     * @param {ITutorialAnchor=} [properties] Properties to set
     * @returns {TutorialAnchor} TutorialAnchor instance
     */
    TutorialAnchor.create = function create(properties) {
        return new TutorialAnchor(properties);
    };

    /**
     * Encodes the specified TutorialAnchor message. Does not implicitly {@link TutorialAnchor.verify|verify} messages.
     * @function encode
     * @memberof TutorialAnchor
     * @static
     * @param {ITutorialAnchor} m TutorialAnchor message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialAnchor.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.instructions != null && Object.hasOwnProperty.call(m, "instructions"))
            $root.TutorialAnchor.Instructions.encode(m.instructions, w.uint32(10).fork()).ldelim();
        if (m.trackable != null && Object.hasOwnProperty.call(m, "trackable"))
            $root.streem.room.ar.TrackableDefinition.encode(m.trackable, w.uint32(18).fork()).ldelim();
        if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
            $root.streem.api.Transform.encode(m.offset, w.uint32(26).fork()).ldelim();
        if (m.anchorElements != null && m.anchorElements.length) {
            for (var i = 0; i < m.anchorElements.length; ++i)
                $root.ElementPresentation.encode(m.anchorElements[i], w.uint32(34).fork()).ldelim();
        }
        if (m.anchorId != null && Object.hasOwnProperty.call(m, "anchorId"))
            w.uint32(42).string(m.anchorId);
        return w;
    };

    /**
     * Decodes a TutorialAnchor message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialAnchor
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialAnchor} TutorialAnchor
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialAnchor.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialAnchor();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.instructions = $root.TutorialAnchor.Instructions.decode(r, r.uint32());
                    break;
                }
            case 2: {
                    m.trackable = $root.streem.room.ar.TrackableDefinition.decode(r, r.uint32());
                    break;
                }
            case 3: {
                    m.offset = $root.streem.api.Transform.decode(r, r.uint32());
                    break;
                }
            case 4: {
                    if (!(m.anchorElements && m.anchorElements.length))
                        m.anchorElements = [];
                    m.anchorElements.push($root.ElementPresentation.decode(r, r.uint32()));
                    break;
                }
            case 5: {
                    m.anchorId = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialAnchor message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialAnchor
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialAnchor} TutorialAnchor
     */
    TutorialAnchor.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialAnchor)
            return d;
        var m = new $root.TutorialAnchor();
        if (d.instructions != null) {
            if (typeof d.instructions !== "object")
                throw TypeError(".TutorialAnchor.instructions: object expected");
            m.instructions = $root.TutorialAnchor.Instructions.fromObject(d.instructions);
        }
        if (d.trackable != null) {
            if (typeof d.trackable !== "object")
                throw TypeError(".TutorialAnchor.trackable: object expected");
            m.trackable = $root.streem.room.ar.TrackableDefinition.fromObject(d.trackable);
        }
        if (d.offset != null) {
            if (typeof d.offset !== "object")
                throw TypeError(".TutorialAnchor.offset: object expected");
            m.offset = $root.streem.api.Transform.fromObject(d.offset);
        }
        if (d.anchorElements) {
            if (!Array.isArray(d.anchorElements))
                throw TypeError(".TutorialAnchor.anchorElements: array expected");
            m.anchorElements = [];
            for (var i = 0; i < d.anchorElements.length; ++i) {
                if (typeof d.anchorElements[i] !== "object")
                    throw TypeError(".TutorialAnchor.anchorElements: object expected");
                m.anchorElements[i] = $root.ElementPresentation.fromObject(d.anchorElements[i]);
            }
        }
        if (d.anchorId != null) {
            m.anchorId = String(d.anchorId);
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialAnchor message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialAnchor
     * @static
     * @param {TutorialAnchor} m TutorialAnchor
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialAnchor.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.anchorElements = [];
        }
        if (o.defaults) {
            d.instructions = null;
            d.trackable = null;
            d.offset = null;
            d.anchorId = "";
        }
        if (m.instructions != null && m.hasOwnProperty("instructions")) {
            d.instructions = $root.TutorialAnchor.Instructions.toObject(m.instructions, o);
        }
        if (m.trackable != null && m.hasOwnProperty("trackable")) {
            d.trackable = $root.streem.room.ar.TrackableDefinition.toObject(m.trackable, o);
        }
        if (m.offset != null && m.hasOwnProperty("offset")) {
            d.offset = $root.streem.api.Transform.toObject(m.offset, o);
        }
        if (m.anchorElements && m.anchorElements.length) {
            d.anchorElements = [];
            for (var j = 0; j < m.anchorElements.length; ++j) {
                d.anchorElements[j] = $root.ElementPresentation.toObject(m.anchorElements[j], o);
            }
        }
        if (m.anchorId != null && m.hasOwnProperty("anchorId")) {
            d.anchorId = m.anchorId;
        }
        return d;
    };

    /**
     * Converts this TutorialAnchor to JSON.
     * @function toJSON
     * @memberof TutorialAnchor
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialAnchor.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialAnchor
     * @function getTypeUrl
     * @memberof TutorialAnchor
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialAnchor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialAnchor";
    };

    TutorialAnchor.Instructions = (function() {

        /**
         * Properties of an Instructions.
         * @memberof TutorialAnchor
         * @interface IInstructions
         * @property {string|null} [textId] Instructions textId
         * @property {string|null} [overlayImage] Instructions overlayImage
         */

        /**
         * Constructs a new Instructions.
         * @memberof TutorialAnchor
         * @classdesc Represents an Instructions.
         * @implements IInstructions
         * @constructor
         * @param {TutorialAnchor.IInstructions=} [p] Properties to set
         */
        function Instructions(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Instructions textId.
         * @member {string} textId
         * @memberof TutorialAnchor.Instructions
         * @instance
         */
        Instructions.prototype.textId = "";

        /**
         * Instructions overlayImage.
         * @member {string} overlayImage
         * @memberof TutorialAnchor.Instructions
         * @instance
         */
        Instructions.prototype.overlayImage = "";

        /**
         * Creates a new Instructions instance using the specified properties.
         * @function create
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {TutorialAnchor.IInstructions=} [properties] Properties to set
         * @returns {TutorialAnchor.Instructions} Instructions instance
         */
        Instructions.create = function create(properties) {
            return new Instructions(properties);
        };

        /**
         * Encodes the specified Instructions message. Does not implicitly {@link TutorialAnchor.Instructions.verify|verify} messages.
         * @function encode
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {TutorialAnchor.IInstructions} m Instructions message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Instructions.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.textId != null && Object.hasOwnProperty.call(m, "textId"))
                w.uint32(10).string(m.textId);
            if (m.overlayImage != null && Object.hasOwnProperty.call(m, "overlayImage"))
                w.uint32(18).string(m.overlayImage);
            return w;
        };

        /**
         * Decodes an Instructions message from the specified reader or buffer.
         * @function decode
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {TutorialAnchor.Instructions} Instructions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Instructions.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialAnchor.Instructions();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.textId = r.string();
                        break;
                    }
                case 2: {
                        m.overlayImage = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Instructions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {TutorialAnchor.Instructions} Instructions
         */
        Instructions.fromObject = function fromObject(d) {
            if (d instanceof $root.TutorialAnchor.Instructions)
                return d;
            var m = new $root.TutorialAnchor.Instructions();
            if (d.textId != null) {
                m.textId = String(d.textId);
            }
            if (d.overlayImage != null) {
                m.overlayImage = String(d.overlayImage);
            }
            return m;
        };

        /**
         * Creates a plain object from an Instructions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {TutorialAnchor.Instructions} m Instructions
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Instructions.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.textId = "";
                d.overlayImage = "";
            }
            if (m.textId != null && m.hasOwnProperty("textId")) {
                d.textId = m.textId;
            }
            if (m.overlayImage != null && m.hasOwnProperty("overlayImage")) {
                d.overlayImage = m.overlayImage;
            }
            return d;
        };

        /**
         * Converts this Instructions to JSON.
         * @function toJSON
         * @memberof TutorialAnchor.Instructions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Instructions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Instructions
         * @function getTypeUrl
         * @memberof TutorialAnchor.Instructions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Instructions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TutorialAnchor.Instructions";
        };

        return Instructions;
    })();

    return TutorialAnchor;
})();

$root.TutorialState = (function() {

    /**
     * Properties of a TutorialState.
     * @exports ITutorialState
     * @interface ITutorialState
     * @property {string|null} [tutorialId] TutorialState tutorialId
     * @property {string|null} [inResponseToWallItemId] TutorialState inResponseToWallItemId
     * @property {ITutorialAnchor|null} [anchor] TutorialState anchor
     * @property {IStep|null} [step] TutorialState step
     * @property {TutorialState.IExited|null} [exited] TutorialState exited
     */

    /**
     * Constructs a new TutorialState.
     * @exports TutorialState
     * @classdesc Represents a TutorialState.
     * @implements ITutorialState
     * @constructor
     * @param {ITutorialState=} [p] Properties to set
     */
    function TutorialState(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialState tutorialId.
     * @member {string} tutorialId
     * @memberof TutorialState
     * @instance
     */
    TutorialState.prototype.tutorialId = "";

    /**
     * TutorialState inResponseToWallItemId.
     * @member {string} inResponseToWallItemId
     * @memberof TutorialState
     * @instance
     */
    TutorialState.prototype.inResponseToWallItemId = "";

    /**
     * TutorialState anchor.
     * @member {ITutorialAnchor|null|undefined} anchor
     * @memberof TutorialState
     * @instance
     */
    TutorialState.prototype.anchor = null;

    /**
     * TutorialState step.
     * @member {IStep|null|undefined} step
     * @memberof TutorialState
     * @instance
     */
    TutorialState.prototype.step = null;

    /**
     * TutorialState exited.
     * @member {TutorialState.IExited|null|undefined} exited
     * @memberof TutorialState
     * @instance
     */
    TutorialState.prototype.exited = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * TutorialState type.
     * @member {"anchor"|"step"|"exited"|undefined} type
     * @memberof TutorialState
     * @instance
     */
    Object.defineProperty(TutorialState.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["anchor", "step", "exited"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TutorialState instance using the specified properties.
     * @function create
     * @memberof TutorialState
     * @static
     * @param {ITutorialState=} [properties] Properties to set
     * @returns {TutorialState} TutorialState instance
     */
    TutorialState.create = function create(properties) {
        return new TutorialState(properties);
    };

    /**
     * Encodes the specified TutorialState message. Does not implicitly {@link TutorialState.verify|verify} messages.
     * @function encode
     * @memberof TutorialState
     * @static
     * @param {ITutorialState} m TutorialState message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialState.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.tutorialId != null && Object.hasOwnProperty.call(m, "tutorialId"))
            w.uint32(10).string(m.tutorialId);
        if (m.inResponseToWallItemId != null && Object.hasOwnProperty.call(m, "inResponseToWallItemId"))
            w.uint32(18).string(m.inResponseToWallItemId);
        if (m.anchor != null && Object.hasOwnProperty.call(m, "anchor"))
            $root.TutorialAnchor.encode(m.anchor, w.uint32(802).fork()).ldelim();
        if (m.step != null && Object.hasOwnProperty.call(m, "step"))
            $root.Step.encode(m.step, w.uint32(810).fork()).ldelim();
        if (m.exited != null && Object.hasOwnProperty.call(m, "exited"))
            $root.TutorialState.Exited.encode(m.exited, w.uint32(818).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a TutorialState message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialState
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialState} TutorialState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialState.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialState();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.tutorialId = r.string();
                    break;
                }
            case 2: {
                    m.inResponseToWallItemId = r.string();
                    break;
                }
            case 100: {
                    m.anchor = $root.TutorialAnchor.decode(r, r.uint32());
                    break;
                }
            case 101: {
                    m.step = $root.Step.decode(r, r.uint32());
                    break;
                }
            case 102: {
                    m.exited = $root.TutorialState.Exited.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialState
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialState} TutorialState
     */
    TutorialState.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialState)
            return d;
        var m = new $root.TutorialState();
        if (d.tutorialId != null) {
            m.tutorialId = String(d.tutorialId);
        }
        if (d.inResponseToWallItemId != null) {
            m.inResponseToWallItemId = String(d.inResponseToWallItemId);
        }
        if (d.anchor != null) {
            if (typeof d.anchor !== "object")
                throw TypeError(".TutorialState.anchor: object expected");
            m.anchor = $root.TutorialAnchor.fromObject(d.anchor);
        }
        if (d.step != null) {
            if (typeof d.step !== "object")
                throw TypeError(".TutorialState.step: object expected");
            m.step = $root.Step.fromObject(d.step);
        }
        if (d.exited != null) {
            if (typeof d.exited !== "object")
                throw TypeError(".TutorialState.exited: object expected");
            m.exited = $root.TutorialState.Exited.fromObject(d.exited);
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialState
     * @static
     * @param {TutorialState} m TutorialState
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialState.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.tutorialId = "";
            d.inResponseToWallItemId = "";
        }
        if (m.tutorialId != null && m.hasOwnProperty("tutorialId")) {
            d.tutorialId = m.tutorialId;
        }
        if (m.inResponseToWallItemId != null && m.hasOwnProperty("inResponseToWallItemId")) {
            d.inResponseToWallItemId = m.inResponseToWallItemId;
        }
        if (m.anchor != null && m.hasOwnProperty("anchor")) {
            d.anchor = $root.TutorialAnchor.toObject(m.anchor, o);
            if (o.oneofs)
                d.type = "anchor";
        }
        if (m.step != null && m.hasOwnProperty("step")) {
            d.step = $root.Step.toObject(m.step, o);
            if (o.oneofs)
                d.type = "step";
        }
        if (m.exited != null && m.hasOwnProperty("exited")) {
            d.exited = $root.TutorialState.Exited.toObject(m.exited, o);
            if (o.oneofs)
                d.type = "exited";
        }
        return d;
    };

    /**
     * Converts this TutorialState to JSON.
     * @function toJSON
     * @memberof TutorialState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialState
     * @function getTypeUrl
     * @memberof TutorialState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialState";
    };

    TutorialState.Exited = (function() {

        /**
         * Properties of an Exited.
         * @memberof TutorialState
         * @interface IExited
         * @property {string|null} [exitCode] Exited exitCode
         */

        /**
         * Constructs a new Exited.
         * @memberof TutorialState
         * @classdesc Represents an Exited.
         * @implements IExited
         * @constructor
         * @param {TutorialState.IExited=} [p] Properties to set
         */
        function Exited(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Exited exitCode.
         * @member {string} exitCode
         * @memberof TutorialState.Exited
         * @instance
         */
        Exited.prototype.exitCode = "";

        /**
         * Creates a new Exited instance using the specified properties.
         * @function create
         * @memberof TutorialState.Exited
         * @static
         * @param {TutorialState.IExited=} [properties] Properties to set
         * @returns {TutorialState.Exited} Exited instance
         */
        Exited.create = function create(properties) {
            return new Exited(properties);
        };

        /**
         * Encodes the specified Exited message. Does not implicitly {@link TutorialState.Exited.verify|verify} messages.
         * @function encode
         * @memberof TutorialState.Exited
         * @static
         * @param {TutorialState.IExited} m Exited message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Exited.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.exitCode != null && Object.hasOwnProperty.call(m, "exitCode"))
                w.uint32(10).string(m.exitCode);
            return w;
        };

        /**
         * Decodes an Exited message from the specified reader or buffer.
         * @function decode
         * @memberof TutorialState.Exited
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {TutorialState.Exited} Exited
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Exited.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialState.Exited();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.exitCode = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Exited message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TutorialState.Exited
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {TutorialState.Exited} Exited
         */
        Exited.fromObject = function fromObject(d) {
            if (d instanceof $root.TutorialState.Exited)
                return d;
            var m = new $root.TutorialState.Exited();
            if (d.exitCode != null) {
                m.exitCode = String(d.exitCode);
            }
            return m;
        };

        /**
         * Creates a plain object from an Exited message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TutorialState.Exited
         * @static
         * @param {TutorialState.Exited} m Exited
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Exited.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.exitCode = "";
            }
            if (m.exitCode != null && m.hasOwnProperty("exitCode")) {
                d.exitCode = m.exitCode;
            }
            return d;
        };

        /**
         * Converts this Exited to JSON.
         * @function toJSON
         * @memberof TutorialState.Exited
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Exited.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Exited
         * @function getTypeUrl
         * @memberof TutorialState.Exited
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Exited.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TutorialState.Exited";
        };

        return Exited;
    })();

    return TutorialState;
})();

$root.TutorialTheme = (function() {

    /**
     * Properties of a TutorialTheme.
     * @exports ITutorialTheme
     * @interface ITutorialTheme
     * @property {ITextTheme|null} [text] TutorialTheme text
     * @property {IPickListTheme|null} [pickList] TutorialTheme pickList
     */

    /**
     * Constructs a new TutorialTheme.
     * @exports TutorialTheme
     * @classdesc Represents a TutorialTheme.
     * @implements ITutorialTheme
     * @constructor
     * @param {ITutorialTheme=} [p] Properties to set
     */
    function TutorialTheme(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialTheme text.
     * @member {ITextTheme|null|undefined} text
     * @memberof TutorialTheme
     * @instance
     */
    TutorialTheme.prototype.text = null;

    /**
     * TutorialTheme pickList.
     * @member {IPickListTheme|null|undefined} pickList
     * @memberof TutorialTheme
     * @instance
     */
    TutorialTheme.prototype.pickList = null;

    /**
     * Creates a new TutorialTheme instance using the specified properties.
     * @function create
     * @memberof TutorialTheme
     * @static
     * @param {ITutorialTheme=} [properties] Properties to set
     * @returns {TutorialTheme} TutorialTheme instance
     */
    TutorialTheme.create = function create(properties) {
        return new TutorialTheme(properties);
    };

    /**
     * Encodes the specified TutorialTheme message. Does not implicitly {@link TutorialTheme.verify|verify} messages.
     * @function encode
     * @memberof TutorialTheme
     * @static
     * @param {ITutorialTheme} m TutorialTheme message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialTheme.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.text != null && Object.hasOwnProperty.call(m, "text"))
            $root.TextTheme.encode(m.text, w.uint32(10).fork()).ldelim();
        if (m.pickList != null && Object.hasOwnProperty.call(m, "pickList"))
            $root.PickListTheme.encode(m.pickList, w.uint32(18).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a TutorialTheme message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialTheme
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialTheme} TutorialTheme
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialTheme.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialTheme();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.text = $root.TextTheme.decode(r, r.uint32());
                    break;
                }
            case 2: {
                    m.pickList = $root.PickListTheme.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialTheme message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialTheme
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialTheme} TutorialTheme
     */
    TutorialTheme.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialTheme)
            return d;
        var m = new $root.TutorialTheme();
        if (d.text != null) {
            if (typeof d.text !== "object")
                throw TypeError(".TutorialTheme.text: object expected");
            m.text = $root.TextTheme.fromObject(d.text);
        }
        if (d.pickList != null) {
            if (typeof d.pickList !== "object")
                throw TypeError(".TutorialTheme.pickList: object expected");
            m.pickList = $root.PickListTheme.fromObject(d.pickList);
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialTheme message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialTheme
     * @static
     * @param {TutorialTheme} m TutorialTheme
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialTheme.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.text = null;
            d.pickList = null;
        }
        if (m.text != null && m.hasOwnProperty("text")) {
            d.text = $root.TextTheme.toObject(m.text, o);
        }
        if (m.pickList != null && m.hasOwnProperty("pickList")) {
            d.pickList = $root.PickListTheme.toObject(m.pickList, o);
        }
        return d;
    };

    /**
     * Converts this TutorialTheme to JSON.
     * @function toJSON
     * @memberof TutorialTheme
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialTheme.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialTheme
     * @function getTypeUrl
     * @memberof TutorialTheme
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialTheme.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialTheme";
    };

    return TutorialTheme;
})();

$root.TutorialRequest = (function() {

    /**
     * Properties of a TutorialRequest.
     * @exports ITutorialRequest
     * @interface ITutorialRequest
     * @property {string|null} [tutorialId] TutorialRequest tutorialId
     * @property {Array.<string>|null} [tutorialFiles] TutorialRequest tutorialFiles
     * @property {string|null} [requestedForUserId] TutorialRequest requestedForUserId
     * @property {string|null} [requestedForDeviceId] TutorialRequest requestedForDeviceId
     */

    /**
     * Constructs a new TutorialRequest.
     * @exports TutorialRequest
     * @classdesc Represents a TutorialRequest.
     * @implements ITutorialRequest
     * @constructor
     * @param {ITutorialRequest=} [p] Properties to set
     */
    function TutorialRequest(p) {
        this.tutorialFiles = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * TutorialRequest tutorialId.
     * @member {string} tutorialId
     * @memberof TutorialRequest
     * @instance
     */
    TutorialRequest.prototype.tutorialId = "";

    /**
     * TutorialRequest tutorialFiles.
     * @member {Array.<string>} tutorialFiles
     * @memberof TutorialRequest
     * @instance
     */
    TutorialRequest.prototype.tutorialFiles = $util.emptyArray;

    /**
     * TutorialRequest requestedForUserId.
     * @member {string} requestedForUserId
     * @memberof TutorialRequest
     * @instance
     */
    TutorialRequest.prototype.requestedForUserId = "";

    /**
     * TutorialRequest requestedForDeviceId.
     * @member {string} requestedForDeviceId
     * @memberof TutorialRequest
     * @instance
     */
    TutorialRequest.prototype.requestedForDeviceId = "";

    /**
     * Creates a new TutorialRequest instance using the specified properties.
     * @function create
     * @memberof TutorialRequest
     * @static
     * @param {ITutorialRequest=} [properties] Properties to set
     * @returns {TutorialRequest} TutorialRequest instance
     */
    TutorialRequest.create = function create(properties) {
        return new TutorialRequest(properties);
    };

    /**
     * Encodes the specified TutorialRequest message. Does not implicitly {@link TutorialRequest.verify|verify} messages.
     * @function encode
     * @memberof TutorialRequest
     * @static
     * @param {ITutorialRequest} m TutorialRequest message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TutorialRequest.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.tutorialId != null && Object.hasOwnProperty.call(m, "tutorialId"))
            w.uint32(10).string(m.tutorialId);
        if (m.tutorialFiles != null && m.tutorialFiles.length) {
            for (var i = 0; i < m.tutorialFiles.length; ++i)
                w.uint32(18).string(m.tutorialFiles[i]);
        }
        if (m.requestedForUserId != null && Object.hasOwnProperty.call(m, "requestedForUserId"))
            w.uint32(26).string(m.requestedForUserId);
        if (m.requestedForDeviceId != null && Object.hasOwnProperty.call(m, "requestedForDeviceId"))
            w.uint32(34).string(m.requestedForDeviceId);
        return w;
    };

    /**
     * Decodes a TutorialRequest message from the specified reader or buffer.
     * @function decode
     * @memberof TutorialRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {TutorialRequest} TutorialRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TutorialRequest.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.TutorialRequest();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.tutorialId = r.string();
                    break;
                }
            case 2: {
                    if (!(m.tutorialFiles && m.tutorialFiles.length))
                        m.tutorialFiles = [];
                    m.tutorialFiles.push(r.string());
                    break;
                }
            case 3: {
                    m.requestedForUserId = r.string();
                    break;
                }
            case 4: {
                    m.requestedForDeviceId = r.string();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Creates a TutorialRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TutorialRequest
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {TutorialRequest} TutorialRequest
     */
    TutorialRequest.fromObject = function fromObject(d) {
        if (d instanceof $root.TutorialRequest)
            return d;
        var m = new $root.TutorialRequest();
        if (d.tutorialId != null) {
            m.tutorialId = String(d.tutorialId);
        }
        if (d.tutorialFiles) {
            if (!Array.isArray(d.tutorialFiles))
                throw TypeError(".TutorialRequest.tutorialFiles: array expected");
            m.tutorialFiles = [];
            for (var i = 0; i < d.tutorialFiles.length; ++i) {
                m.tutorialFiles[i] = String(d.tutorialFiles[i]);
            }
        }
        if (d.requestedForUserId != null) {
            m.requestedForUserId = String(d.requestedForUserId);
        }
        if (d.requestedForDeviceId != null) {
            m.requestedForDeviceId = String(d.requestedForDeviceId);
        }
        return m;
    };

    /**
     * Creates a plain object from a TutorialRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TutorialRequest
     * @static
     * @param {TutorialRequest} m TutorialRequest
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TutorialRequest.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.tutorialFiles = [];
        }
        if (o.defaults) {
            d.tutorialId = "";
            d.requestedForUserId = "";
            d.requestedForDeviceId = "";
        }
        if (m.tutorialId != null && m.hasOwnProperty("tutorialId")) {
            d.tutorialId = m.tutorialId;
        }
        if (m.tutorialFiles && m.tutorialFiles.length) {
            d.tutorialFiles = [];
            for (var j = 0; j < m.tutorialFiles.length; ++j) {
                d.tutorialFiles[j] = m.tutorialFiles[j];
            }
        }
        if (m.requestedForUserId != null && m.hasOwnProperty("requestedForUserId")) {
            d.requestedForUserId = m.requestedForUserId;
        }
        if (m.requestedForDeviceId != null && m.hasOwnProperty("requestedForDeviceId")) {
            d.requestedForDeviceId = m.requestedForDeviceId;
        }
        return d;
    };

    /**
     * Converts this TutorialRequest to JSON.
     * @function toJSON
     * @memberof TutorialRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TutorialRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TutorialRequest
     * @function getTypeUrl
     * @memberof TutorialRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TutorialRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TutorialRequest";
    };

    return TutorialRequest;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [p] Properties to set
             */
            function Timestamp(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Timestamp seconds.
             * @member {Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} m Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.seconds != null && Object.hasOwnProperty.call(m, "seconds"))
                    w.uint32(8).int64(m.seconds);
                if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                    w.uint32(16).int32(m.nanos);
                return w;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Timestamp();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.seconds = r.int64();
                            break;
                        }
                    case 2: {
                            m.nanos = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Timestamp)
                    return d;
                var m = new $root.google.protobuf.Timestamp();
                if (d.seconds != null) {
                    if ($util.Long)
                        (m.seconds = $util.Long.fromValue(d.seconds)).unsigned = false;
                    else if (typeof d.seconds === "string")
                        m.seconds = parseInt(d.seconds, 10);
                    else if (typeof d.seconds === "number")
                        m.seconds = d.seconds;
                    else if (typeof d.seconds === "object")
                        m.seconds = new $util.LongBits(d.seconds.low >>> 0, d.seconds.high >>> 0).toNumber();
                }
                if (d.nanos != null) {
                    m.nanos = d.nanos | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} m Timestamp
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.seconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.seconds = o.longs === String ? "0" : 0;
                    d.nanos = 0;
                }
                if (m.seconds != null && m.hasOwnProperty("seconds")) {
                    if (typeof m.seconds === "number")
                        d.seconds = o.longs === String ? String(m.seconds) : m.seconds;
                    else
                        d.seconds = o.longs === String ? $util.Long.prototype.toString.call(m.seconds) : o.longs === Number ? new $util.LongBits(m.seconds.low >>> 0, m.seconds.high >>> 0).toNumber() : m.seconds;
                }
                if (m.nanos != null && m.hasOwnProperty("nanos")) {
                    d.nanos = m.nanos;
                }
                return d;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [p] Properties to set
             */
            function FileDescriptorSet(p) {
                this.file = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} m FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.file != null && m.file.length) {
                    for (var i = 0; i < m.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(m.file[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorSet();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.file && m.file.length))
                                m.file = [];
                            m.file.push($root.google.protobuf.FileDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileDescriptorSet)
                    return d;
                var m = new $root.google.protobuf.FileDescriptorSet();
                if (d.file) {
                    if (!Array.isArray(d.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    m.file = [];
                    for (var i = 0; i < d.file.length; ++i) {
                        if (typeof d.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        m.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(d.file[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} m FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.file = [];
                }
                if (m.file && m.file.length) {
                    d.file = [];
                    for (var j = 0; j < m.file.length; ++j) {
                        d.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(m.file[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorSet
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [p] Properties to set
             */
            function FileDescriptorProto(p) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} m FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m["package"] != null && Object.hasOwnProperty.call(m, "package"))
                    w.uint32(18).string(m["package"]);
                if (m.dependency != null && m.dependency.length) {
                    for (var i = 0; i < m.dependency.length; ++i)
                        w.uint32(26).string(m.dependency[i]);
                }
                if (m.messageType != null && m.messageType.length) {
                    for (var i = 0; i < m.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.messageType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(42).fork()).ldelim();
                }
                if (m.service != null && m.service.length) {
                    for (var i = 0; i < m.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(m.service[i], w.uint32(50).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(58).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.FileOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.sourceCodeInfo != null && Object.hasOwnProperty.call(m, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(m.sourceCodeInfo, w.uint32(74).fork()).ldelim();
                if (m.publicDependency != null && m.publicDependency.length) {
                    for (var i = 0; i < m.publicDependency.length; ++i)
                        w.uint32(80).int32(m.publicDependency[i]);
                }
                if (m.weakDependency != null && m.weakDependency.length) {
                    for (var i = 0; i < m.weakDependency.length; ++i)
                        w.uint32(88).int32(m.weakDependency[i]);
                }
                if (m.syntax != null && Object.hasOwnProperty.call(m, "syntax"))
                    w.uint32(98).string(m.syntax);
                return w;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m["package"] = r.string();
                            break;
                        }
                    case 3: {
                            if (!(m.dependency && m.dependency.length))
                                m.dependency = [];
                            m.dependency.push(r.string());
                            break;
                        }
                    case 10: {
                            if (!(m.publicDependency && m.publicDependency.length))
                                m.publicDependency = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.publicDependency.push(r.int32());
                            } else
                                m.publicDependency.push(r.int32());
                            break;
                        }
                    case 11: {
                            if (!(m.weakDependency && m.weakDependency.length))
                                m.weakDependency = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.weakDependency.push(r.int32());
                            } else
                                m.weakDependency.push(r.int32());
                            break;
                        }
                    case 4: {
                            if (!(m.messageType && m.messageType.length))
                                m.messageType = [];
                            m.messageType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(m.enumType && m.enumType.length))
                                m.enumType = [];
                            m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(m.service && m.service.length))
                                m.service = [];
                            m.service.push($root.google.protobuf.ServiceDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(m.extension && m.extension.length))
                                m.extension = [];
                            m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 8: {
                            m.options = $root.google.protobuf.FileOptions.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            m.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(r, r.uint32());
                            break;
                        }
                    case 12: {
                            m.syntax = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.FileDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d["package"] != null) {
                    m["package"] = String(d["package"]);
                }
                if (d.dependency) {
                    if (!Array.isArray(d.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    m.dependency = [];
                    for (var i = 0; i < d.dependency.length; ++i) {
                        m.dependency[i] = String(d.dependency[i]);
                    }
                }
                if (d.publicDependency) {
                    if (!Array.isArray(d.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    m.publicDependency = [];
                    for (var i = 0; i < d.publicDependency.length; ++i) {
                        m.publicDependency[i] = d.publicDependency[i] | 0;
                    }
                }
                if (d.weakDependency) {
                    if (!Array.isArray(d.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    m.weakDependency = [];
                    for (var i = 0; i < d.weakDependency.length; ++i) {
                        m.weakDependency[i] = d.weakDependency[i] | 0;
                    }
                }
                if (d.messageType) {
                    if (!Array.isArray(d.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    m.messageType = [];
                    for (var i = 0; i < d.messageType.length; ++i) {
                        if (typeof d.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        m.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(d.messageType[i]);
                    }
                }
                if (d.enumType) {
                    if (!Array.isArray(d.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    m.enumType = [];
                    for (var i = 0; i < d.enumType.length; ++i) {
                        if (typeof d.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        m.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(d.enumType[i]);
                    }
                }
                if (d.service) {
                    if (!Array.isArray(d.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    m.service = [];
                    for (var i = 0; i < d.service.length; ++i) {
                        if (typeof d.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        m.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(d.service[i]);
                    }
                }
                if (d.extension) {
                    if (!Array.isArray(d.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    m.extension = [];
                    for (var i = 0; i < d.extension.length; ++i) {
                        if (typeof d.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        m.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.extension[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.FileOptions.fromObject(d.options);
                }
                if (d.sourceCodeInfo != null) {
                    if (typeof d.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    m.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(d.sourceCodeInfo);
                }
                if (d.syntax != null) {
                    m.syntax = String(d.syntax);
                }
                return m;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} m FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.dependency = [];
                    d.messageType = [];
                    d.enumType = [];
                    d.service = [];
                    d.extension = [];
                    d.publicDependency = [];
                    d.weakDependency = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d["package"] = "";
                    d.options = null;
                    d.sourceCodeInfo = null;
                    d.syntax = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m["package"] != null && m.hasOwnProperty("package")) {
                    d["package"] = m["package"];
                }
                if (m.dependency && m.dependency.length) {
                    d.dependency = [];
                    for (var j = 0; j < m.dependency.length; ++j) {
                        d.dependency[j] = m.dependency[j];
                    }
                }
                if (m.messageType && m.messageType.length) {
                    d.messageType = [];
                    for (var j = 0; j < m.messageType.length; ++j) {
                        d.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(m.messageType[j], o);
                    }
                }
                if (m.enumType && m.enumType.length) {
                    d.enumType = [];
                    for (var j = 0; j < m.enumType.length; ++j) {
                        d.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(m.enumType[j], o);
                    }
                }
                if (m.service && m.service.length) {
                    d.service = [];
                    for (var j = 0; j < m.service.length; ++j) {
                        d.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(m.service[j], o);
                    }
                }
                if (m.extension && m.extension.length) {
                    d.extension = [];
                    for (var j = 0; j < m.extension.length; ++j) {
                        d.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.extension[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.FileOptions.toObject(m.options, o);
                }
                if (m.sourceCodeInfo != null && m.hasOwnProperty("sourceCodeInfo")) {
                    d.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(m.sourceCodeInfo, o);
                }
                if (m.publicDependency && m.publicDependency.length) {
                    d.publicDependency = [];
                    for (var j = 0; j < m.publicDependency.length; ++j) {
                        d.publicDependency[j] = m.publicDependency[j];
                    }
                }
                if (m.weakDependency && m.weakDependency.length) {
                    d.weakDependency = [];
                    for (var j = 0; j < m.weakDependency.length; ++j) {
                        d.weakDependency[j] = m.weakDependency[j];
                    }
                }
                if (m.syntax != null && m.hasOwnProperty("syntax")) {
                    d.syntax = m.syntax;
                }
                return d;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [p] Properties to set
             */
            function DescriptorProto(p) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} m DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.field != null && m.field.length) {
                    for (var i = 0; i < m.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.field[i], w.uint32(18).fork()).ldelim();
                }
                if (m.nestedType != null && m.nestedType.length) {
                    for (var i = 0; i < m.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.nestedType[i], w.uint32(26).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.extensionRange != null && m.extensionRange.length) {
                    for (var i = 0; i < m.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(m.extensionRange[i], w.uint32(42).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(50).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.MessageOptions.encode(m.options, w.uint32(58).fork()).ldelim();
                if (m.oneofDecl != null && m.oneofDecl.length) {
                    for (var i = 0; i < m.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(m.oneofDecl[i], w.uint32(66).fork()).ldelim();
                }
                if (m.reservedRange != null && m.reservedRange.length) {
                    for (var i = 0; i < m.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(m.reservedRange[i], w.uint32(74).fork()).ldelim();
                }
                if (m.reservedName != null && m.reservedName.length) {
                    for (var i = 0; i < m.reservedName.length; ++i)
                        w.uint32(82).string(m.reservedName[i]);
                }
                return w;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.field && m.field.length))
                                m.field = [];
                            m.field.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(m.extension && m.extension.length))
                                m.extension = [];
                            m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(m.nestedType && m.nestedType.length))
                                m.nestedType = [];
                            m.nestedType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(m.enumType && m.enumType.length))
                                m.enumType = [];
                            m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(m.extensionRange && m.extensionRange.length))
                                m.extensionRange = [];
                            m.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(r, r.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(m.oneofDecl && m.oneofDecl.length))
                                m.oneofDecl = [];
                            m.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 7: {
                            m.options = $root.google.protobuf.MessageOptions.decode(r, r.uint32());
                            break;
                        }
                    case 9: {
                            if (!(m.reservedRange && m.reservedRange.length))
                                m.reservedRange = [];
                            m.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(r, r.uint32()));
                            break;
                        }
                    case 10: {
                            if (!(m.reservedName && m.reservedName.length))
                                m.reservedName = [];
                            m.reservedName.push(r.string());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.DescriptorProto)
                    return d;
                var m = new $root.google.protobuf.DescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.field) {
                    if (!Array.isArray(d.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    m.field = [];
                    for (var i = 0; i < d.field.length; ++i) {
                        if (typeof d.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        m.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.field[i]);
                    }
                }
                if (d.extension) {
                    if (!Array.isArray(d.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    m.extension = [];
                    for (var i = 0; i < d.extension.length; ++i) {
                        if (typeof d.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        m.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.extension[i]);
                    }
                }
                if (d.nestedType) {
                    if (!Array.isArray(d.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    m.nestedType = [];
                    for (var i = 0; i < d.nestedType.length; ++i) {
                        if (typeof d.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        m.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(d.nestedType[i]);
                    }
                }
                if (d.enumType) {
                    if (!Array.isArray(d.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    m.enumType = [];
                    for (var i = 0; i < d.enumType.length; ++i) {
                        if (typeof d.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        m.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(d.enumType[i]);
                    }
                }
                if (d.extensionRange) {
                    if (!Array.isArray(d.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    m.extensionRange = [];
                    for (var i = 0; i < d.extensionRange.length; ++i) {
                        if (typeof d.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        m.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(d.extensionRange[i]);
                    }
                }
                if (d.oneofDecl) {
                    if (!Array.isArray(d.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    m.oneofDecl = [];
                    for (var i = 0; i < d.oneofDecl.length; ++i) {
                        if (typeof d.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        m.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(d.oneofDecl[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.MessageOptions.fromObject(d.options);
                }
                if (d.reservedRange) {
                    if (!Array.isArray(d.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    m.reservedRange = [];
                    for (var i = 0; i < d.reservedRange.length; ++i) {
                        if (typeof d.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        m.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(d.reservedRange[i]);
                    }
                }
                if (d.reservedName) {
                    if (!Array.isArray(d.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    m.reservedName = [];
                    for (var i = 0; i < d.reservedName.length; ++i) {
                        m.reservedName[i] = String(d.reservedName[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} m DescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.field = [];
                    d.nestedType = [];
                    d.enumType = [];
                    d.extensionRange = [];
                    d.extension = [];
                    d.oneofDecl = [];
                    d.reservedRange = [];
                    d.reservedName = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.field && m.field.length) {
                    d.field = [];
                    for (var j = 0; j < m.field.length; ++j) {
                        d.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.field[j], o);
                    }
                }
                if (m.nestedType && m.nestedType.length) {
                    d.nestedType = [];
                    for (var j = 0; j < m.nestedType.length; ++j) {
                        d.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(m.nestedType[j], o);
                    }
                }
                if (m.enumType && m.enumType.length) {
                    d.enumType = [];
                    for (var j = 0; j < m.enumType.length; ++j) {
                        d.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(m.enumType[j], o);
                    }
                }
                if (m.extensionRange && m.extensionRange.length) {
                    d.extensionRange = [];
                    for (var j = 0; j < m.extensionRange.length; ++j) {
                        d.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(m.extensionRange[j], o);
                    }
                }
                if (m.extension && m.extension.length) {
                    d.extension = [];
                    for (var j = 0; j < m.extension.length; ++j) {
                        d.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.extension[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.MessageOptions.toObject(m.options, o);
                }
                if (m.oneofDecl && m.oneofDecl.length) {
                    d.oneofDecl = [];
                    for (var j = 0; j < m.oneofDecl.length; ++j) {
                        d.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(m.oneofDecl[j], o);
                    }
                }
                if (m.reservedRange && m.reservedRange.length) {
                    d.reservedRange = [];
                    for (var j = 0; j < m.reservedRange.length; ++j) {
                        d.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(m.reservedRange[j], o);
                    }
                }
                if (m.reservedName && m.reservedName.length) {
                    d.reservedName = [];
                    for (var j = 0; j < m.reservedName.length; ++j) {
                        d.reservedName[j] = m.reservedName[j];
                    }
                }
                return d;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DescriptorProto";
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [p] Properties to set
                 */
                function ExtensionRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} m ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.start = r.int32();
                                break;
                            }
                        case 2: {
                                m.end = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return d;
                    var m = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (d.start != null) {
                        m.start = d.start | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} m ExtensionRange
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.start = 0;
                        d.end = 0;
                    }
                    if (m.start != null && m.hasOwnProperty("start")) {
                        d.start = m.start;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ExtensionRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [p] Properties to set
                 */
                function ReservedRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} m ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.start = r.int32();
                                break;
                            }
                        case 2: {
                                m.end = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return d;
                    var m = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (d.start != null) {
                        m.start = d.start | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} m ReservedRange
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.start = 0;
                        d.end = 0;
                    }
                    if (m.start != null && m.hasOwnProperty("start")) {
                        d.start = m.start;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ReservedRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [p] Properties to set
             */
            function FieldDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} m FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.extendee != null && Object.hasOwnProperty.call(m, "extendee"))
                    w.uint32(18).string(m.extendee);
                if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                    w.uint32(24).int32(m.number);
                if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                    w.uint32(32).int32(m.label);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(40).int32(m.type);
                if (m.typeName != null && Object.hasOwnProperty.call(m, "typeName"))
                    w.uint32(50).string(m.typeName);
                if (m.defaultValue != null && Object.hasOwnProperty.call(m, "defaultValue"))
                    w.uint32(58).string(m.defaultValue);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.FieldOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.oneofIndex != null && Object.hasOwnProperty.call(m, "oneofIndex"))
                    w.uint32(72).int32(m.oneofIndex);
                if (m.jsonName != null && Object.hasOwnProperty.call(m, "jsonName"))
                    w.uint32(82).string(m.jsonName);
                return w;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 3: {
                            m.number = r.int32();
                            break;
                        }
                    case 4: {
                            m.label = r.int32();
                            break;
                        }
                    case 5: {
                            m.type = r.int32();
                            break;
                        }
                    case 6: {
                            m.typeName = r.string();
                            break;
                        }
                    case 2: {
                            m.extendee = r.string();
                            break;
                        }
                    case 7: {
                            m.defaultValue = r.string();
                            break;
                        }
                    case 9: {
                            m.oneofIndex = r.int32();
                            break;
                        }
                    case 10: {
                            m.jsonName = r.string();
                            break;
                        }
                    case 8: {
                            m.options = $root.google.protobuf.FieldOptions.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FieldDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.FieldDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.number != null) {
                    m.number = d.number | 0;
                }
                switch (d.label) {
                default:
                    if (typeof d.label === "number") {
                        m.label = d.label;
                        break;
                    }
                    break;
                case "LABEL_OPTIONAL":
                case 1:
                    m.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    m.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    m.label = 3;
                    break;
                }
                switch (d.type) {
                default:
                    if (typeof d.type === "number") {
                        m.type = d.type;
                        break;
                    }
                    break;
                case "TYPE_DOUBLE":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    m.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    m.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    m.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    m.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    m.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    m.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    m.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    m.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    m.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    m.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    m.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    m.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    m.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    m.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    m.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    m.type = 18;
                    break;
                }
                if (d.typeName != null) {
                    m.typeName = String(d.typeName);
                }
                if (d.extendee != null) {
                    m.extendee = String(d.extendee);
                }
                if (d.defaultValue != null) {
                    m.defaultValue = String(d.defaultValue);
                }
                if (d.oneofIndex != null) {
                    m.oneofIndex = d.oneofIndex | 0;
                }
                if (d.jsonName != null) {
                    m.jsonName = String(d.jsonName);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.FieldOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} m FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.extendee = "";
                    d.number = 0;
                    d.label = o.enums === String ? "LABEL_OPTIONAL" : 1;
                    d.type = o.enums === String ? "TYPE_DOUBLE" : 1;
                    d.typeName = "";
                    d.defaultValue = "";
                    d.options = null;
                    d.oneofIndex = 0;
                    d.jsonName = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.extendee != null && m.hasOwnProperty("extendee")) {
                    d.extendee = m.extendee;
                }
                if (m.number != null && m.hasOwnProperty("number")) {
                    d.number = m.number;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = o.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[m.label] === undefined ? m.label : $root.google.protobuf.FieldDescriptorProto.Label[m.label] : m.label;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[m.type] === undefined ? m.type : $root.google.protobuf.FieldDescriptorProto.Type[m.type] : m.type;
                }
                if (m.typeName != null && m.hasOwnProperty("typeName")) {
                    d.typeName = m.typeName;
                }
                if (m.defaultValue != null && m.hasOwnProperty("defaultValue")) {
                    d.defaultValue = m.defaultValue;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.FieldOptions.toObject(m.options, o);
                }
                if (m.oneofIndex != null && m.hasOwnProperty("oneofIndex")) {
                    d.oneofIndex = m.oneofIndex;
                }
                if (m.jsonName != null && m.hasOwnProperty("jsonName")) {
                    d.jsonName = m.jsonName;
                }
                return d;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [p] Properties to set
             */
            function OneofDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} m OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.OneofOptions.encode(m.options, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.options = $root.google.protobuf.OneofOptions.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.OneofDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.OneofDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.OneofOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} m OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.OneofOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [p] Properties to set
             */
            function EnumDescriptorProto(p) {
                this.value = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} m EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.value != null && m.value.length) {
                    for (var i = 0; i < m.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(m.value[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.EnumOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.value && m.value.length))
                                m.value = [];
                            m.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 3: {
                            m.options = $root.google.protobuf.EnumOptions.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.EnumDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.value) {
                    if (!Array.isArray(d.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    m.value = [];
                    for (var i = 0; i < d.value.length; ++i) {
                        if (typeof d.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        m.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(d.value[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.EnumOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} m EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.value = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.value && m.value.length) {
                    d.value = [];
                    for (var j = 0; j < m.value.length; ++j) {
                        d.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(m.value[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.EnumOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [p] Properties to set
             */
            function EnumValueDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} m EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                    w.uint32(16).int32(m.number);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.number = r.int32();
                            break;
                        }
                    case 3: {
                            m.options = $root.google.protobuf.EnumValueOptions.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.EnumValueDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.number != null) {
                    m.number = d.number | 0;
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.EnumValueOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} m EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.number = 0;
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.number != null && m.hasOwnProperty("number")) {
                    d.number = m.number;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.EnumValueOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [p] Properties to set
             */
            function ServiceDescriptorProto(p) {
                this.method = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} m ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.method != null && m.method.length) {
                    for (var i = 0; i < m.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(m.method[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.ServiceOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            if (!(m.method && m.method.length))
                                m.method = [];
                            m.method.push($root.google.protobuf.MethodDescriptorProto.decode(r, r.uint32()));
                            break;
                        }
                    case 3: {
                            m.options = $root.google.protobuf.ServiceOptions.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.ServiceDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.method) {
                    if (!Array.isArray(d.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    m.method = [];
                    for (var i = 0; i < d.method.length; ++i) {
                        if (typeof d.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        m.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(d.method[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.ServiceOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} m ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.method = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.method && m.method.length) {
                    d.method = [];
                    for (var j = 0; j < m.method.length; ++j) {
                        d.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(m.method[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.ServiceOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [p] Properties to set
             */
            function MethodDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} m MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.inputType != null && Object.hasOwnProperty.call(m, "inputType"))
                    w.uint32(18).string(m.inputType);
                if (m.outputType != null && Object.hasOwnProperty.call(m, "outputType"))
                    w.uint32(26).string(m.outputType);
                if (m.options != null && Object.hasOwnProperty.call(m, "options"))
                    $root.google.protobuf.MethodOptions.encode(m.options, w.uint32(34).fork()).ldelim();
                if (m.clientStreaming != null && Object.hasOwnProperty.call(m, "clientStreaming"))
                    w.uint32(40).bool(m.clientStreaming);
                if (m.serverStreaming != null && Object.hasOwnProperty.call(m, "serverStreaming"))
                    w.uint32(48).bool(m.serverStreaming);
                return w;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.name = r.string();
                            break;
                        }
                    case 2: {
                            m.inputType = r.string();
                            break;
                        }
                    case 3: {
                            m.outputType = r.string();
                            break;
                        }
                    case 4: {
                            m.options = $root.google.protobuf.MethodOptions.decode(r, r.uint32());
                            break;
                        }
                    case 5: {
                            m.clientStreaming = r.bool();
                            break;
                        }
                    case 6: {
                            m.serverStreaming = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MethodDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.MethodDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.inputType != null) {
                    m.inputType = String(d.inputType);
                }
                if (d.outputType != null) {
                    m.outputType = String(d.outputType);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.MethodOptions.fromObject(d.options);
                }
                if (d.clientStreaming != null) {
                    m.clientStreaming = Boolean(d.clientStreaming);
                }
                if (d.serverStreaming != null) {
                    m.serverStreaming = Boolean(d.serverStreaming);
                }
                return m;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} m MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.inputType = "";
                    d.outputType = "";
                    d.options = null;
                    d.clientStreaming = false;
                    d.serverStreaming = false;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.inputType != null && m.hasOwnProperty("inputType")) {
                    d.inputType = m.inputType;
                }
                if (m.outputType != null && m.hasOwnProperty("outputType")) {
                    d.outputType = m.outputType;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.MethodOptions.toObject(m.options, o);
                }
                if (m.clientStreaming != null && m.hasOwnProperty("clientStreaming")) {
                    d.clientStreaming = m.clientStreaming;
                }
                if (m.serverStreaming != null && m.hasOwnProperty("serverStreaming")) {
                    d.serverStreaming = m.serverStreaming;
                }
                return d;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [p] Properties to set
             */
            function FileOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} m FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.javaPackage != null && Object.hasOwnProperty.call(m, "javaPackage"))
                    w.uint32(10).string(m.javaPackage);
                if (m.javaOuterClassname != null && Object.hasOwnProperty.call(m, "javaOuterClassname"))
                    w.uint32(66).string(m.javaOuterClassname);
                if (m.optimizeFor != null && Object.hasOwnProperty.call(m, "optimizeFor"))
                    w.uint32(72).int32(m.optimizeFor);
                if (m.javaMultipleFiles != null && Object.hasOwnProperty.call(m, "javaMultipleFiles"))
                    w.uint32(80).bool(m.javaMultipleFiles);
                if (m.goPackage != null && Object.hasOwnProperty.call(m, "goPackage"))
                    w.uint32(90).string(m.goPackage);
                if (m.ccGenericServices != null && Object.hasOwnProperty.call(m, "ccGenericServices"))
                    w.uint32(128).bool(m.ccGenericServices);
                if (m.javaGenericServices != null && Object.hasOwnProperty.call(m, "javaGenericServices"))
                    w.uint32(136).bool(m.javaGenericServices);
                if (m.pyGenericServices != null && Object.hasOwnProperty.call(m, "pyGenericServices"))
                    w.uint32(144).bool(m.pyGenericServices);
                if (m.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(m, "javaGenerateEqualsAndHash"))
                    w.uint32(160).bool(m.javaGenerateEqualsAndHash);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(184).bool(m.deprecated);
                if (m.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(m, "javaStringCheckUtf8"))
                    w.uint32(216).bool(m.javaStringCheckUtf8);
                if (m.ccEnableArenas != null && Object.hasOwnProperty.call(m, "ccEnableArenas"))
                    w.uint32(248).bool(m.ccEnableArenas);
                if (m.objcClassPrefix != null && Object.hasOwnProperty.call(m, "objcClassPrefix"))
                    w.uint32(290).string(m.objcClassPrefix);
                if (m.csharpNamespace != null && Object.hasOwnProperty.call(m, "csharpNamespace"))
                    w.uint32(298).string(m.csharpNamespace);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.javaPackage = r.string();
                            break;
                        }
                    case 8: {
                            m.javaOuterClassname = r.string();
                            break;
                        }
                    case 10: {
                            m.javaMultipleFiles = r.bool();
                            break;
                        }
                    case 20: {
                            m.javaGenerateEqualsAndHash = r.bool();
                            break;
                        }
                    case 27: {
                            m.javaStringCheckUtf8 = r.bool();
                            break;
                        }
                    case 9: {
                            m.optimizeFor = r.int32();
                            break;
                        }
                    case 11: {
                            m.goPackage = r.string();
                            break;
                        }
                    case 16: {
                            m.ccGenericServices = r.bool();
                            break;
                        }
                    case 17: {
                            m.javaGenericServices = r.bool();
                            break;
                        }
                    case 18: {
                            m.pyGenericServices = r.bool();
                            break;
                        }
                    case 23: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 31: {
                            m.ccEnableArenas = r.bool();
                            break;
                        }
                    case 36: {
                            m.objcClassPrefix = r.string();
                            break;
                        }
                    case 37: {
                            m.csharpNamespace = r.string();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileOptions)
                    return d;
                var m = new $root.google.protobuf.FileOptions();
                if (d.javaPackage != null) {
                    m.javaPackage = String(d.javaPackage);
                }
                if (d.javaOuterClassname != null) {
                    m.javaOuterClassname = String(d.javaOuterClassname);
                }
                if (d.javaMultipleFiles != null) {
                    m.javaMultipleFiles = Boolean(d.javaMultipleFiles);
                }
                if (d.javaGenerateEqualsAndHash != null) {
                    m.javaGenerateEqualsAndHash = Boolean(d.javaGenerateEqualsAndHash);
                }
                if (d.javaStringCheckUtf8 != null) {
                    m.javaStringCheckUtf8 = Boolean(d.javaStringCheckUtf8);
                }
                switch (d.optimizeFor) {
                default:
                    if (typeof d.optimizeFor === "number") {
                        m.optimizeFor = d.optimizeFor;
                        break;
                    }
                    break;
                case "SPEED":
                case 1:
                    m.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    m.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    m.optimizeFor = 3;
                    break;
                }
                if (d.goPackage != null) {
                    m.goPackage = String(d.goPackage);
                }
                if (d.ccGenericServices != null) {
                    m.ccGenericServices = Boolean(d.ccGenericServices);
                }
                if (d.javaGenericServices != null) {
                    m.javaGenericServices = Boolean(d.javaGenericServices);
                }
                if (d.pyGenericServices != null) {
                    m.pyGenericServices = Boolean(d.pyGenericServices);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.ccEnableArenas != null) {
                    m.ccEnableArenas = Boolean(d.ccEnableArenas);
                }
                if (d.objcClassPrefix != null) {
                    m.objcClassPrefix = String(d.objcClassPrefix);
                }
                if (d.csharpNamespace != null) {
                    m.csharpNamespace = String(d.csharpNamespace);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} m FileOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.javaPackage = "";
                    d.javaOuterClassname = "";
                    d.optimizeFor = o.enums === String ? "SPEED" : 1;
                    d.javaMultipleFiles = false;
                    d.goPackage = "";
                    d.ccGenericServices = false;
                    d.javaGenericServices = false;
                    d.pyGenericServices = false;
                    d.javaGenerateEqualsAndHash = false;
                    d.deprecated = false;
                    d.javaStringCheckUtf8 = false;
                    d.ccEnableArenas = false;
                    d.objcClassPrefix = "";
                    d.csharpNamespace = "";
                }
                if (m.javaPackage != null && m.hasOwnProperty("javaPackage")) {
                    d.javaPackage = m.javaPackage;
                }
                if (m.javaOuterClassname != null && m.hasOwnProperty("javaOuterClassname")) {
                    d.javaOuterClassname = m.javaOuterClassname;
                }
                if (m.optimizeFor != null && m.hasOwnProperty("optimizeFor")) {
                    d.optimizeFor = o.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[m.optimizeFor] === undefined ? m.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[m.optimizeFor] : m.optimizeFor;
                }
                if (m.javaMultipleFiles != null && m.hasOwnProperty("javaMultipleFiles")) {
                    d.javaMultipleFiles = m.javaMultipleFiles;
                }
                if (m.goPackage != null && m.hasOwnProperty("goPackage")) {
                    d.goPackage = m.goPackage;
                }
                if (m.ccGenericServices != null && m.hasOwnProperty("ccGenericServices")) {
                    d.ccGenericServices = m.ccGenericServices;
                }
                if (m.javaGenericServices != null && m.hasOwnProperty("javaGenericServices")) {
                    d.javaGenericServices = m.javaGenericServices;
                }
                if (m.pyGenericServices != null && m.hasOwnProperty("pyGenericServices")) {
                    d.pyGenericServices = m.pyGenericServices;
                }
                if (m.javaGenerateEqualsAndHash != null && m.hasOwnProperty("javaGenerateEqualsAndHash")) {
                    d.javaGenerateEqualsAndHash = m.javaGenerateEqualsAndHash;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.javaStringCheckUtf8 != null && m.hasOwnProperty("javaStringCheckUtf8")) {
                    d.javaStringCheckUtf8 = m.javaStringCheckUtf8;
                }
                if (m.ccEnableArenas != null && m.hasOwnProperty("ccEnableArenas")) {
                    d.ccEnableArenas = m.ccEnableArenas;
                }
                if (m.objcClassPrefix != null && m.hasOwnProperty("objcClassPrefix")) {
                    d.objcClassPrefix = m.objcClassPrefix;
                }
                if (m.csharpNamespace != null && m.hasOwnProperty("csharpNamespace")) {
                    d.csharpNamespace = m.csharpNamespace;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileOptions";
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [p] Properties to set
             */
            function MessageOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} m MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.messageSetWireFormat != null && Object.hasOwnProperty.call(m, "messageSetWireFormat"))
                    w.uint32(8).bool(m.messageSetWireFormat);
                if (m.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(m, "noStandardDescriptorAccessor"))
                    w.uint32(16).bool(m.noStandardDescriptorAccessor);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.mapEntry != null && Object.hasOwnProperty.call(m, "mapEntry"))
                    w.uint32(56).bool(m.mapEntry);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MessageOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.messageSetWireFormat = r.bool();
                            break;
                        }
                    case 2: {
                            m.noStandardDescriptorAccessor = r.bool();
                            break;
                        }
                    case 3: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 7: {
                            m.mapEntry = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MessageOptions)
                    return d;
                var m = new $root.google.protobuf.MessageOptions();
                if (d.messageSetWireFormat != null) {
                    m.messageSetWireFormat = Boolean(d.messageSetWireFormat);
                }
                if (d.noStandardDescriptorAccessor != null) {
                    m.noStandardDescriptorAccessor = Boolean(d.noStandardDescriptorAccessor);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.mapEntry != null) {
                    m.mapEntry = Boolean(d.mapEntry);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} m MessageOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.messageSetWireFormat = false;
                    d.noStandardDescriptorAccessor = false;
                    d.deprecated = false;
                    d.mapEntry = false;
                }
                if (m.messageSetWireFormat != null && m.hasOwnProperty("messageSetWireFormat")) {
                    d.messageSetWireFormat = m.messageSetWireFormat;
                }
                if (m.noStandardDescriptorAccessor != null && m.hasOwnProperty("noStandardDescriptorAccessor")) {
                    d.noStandardDescriptorAccessor = m.noStandardDescriptorAccessor;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.mapEntry != null && m.hasOwnProperty("mapEntry")) {
                    d.mapEntry = m.mapEntry;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MessageOptions";
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [p] Properties to set
             */
            function FieldOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} m FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ctype != null && Object.hasOwnProperty.call(m, "ctype"))
                    w.uint32(8).int32(m.ctype);
                if (m.packed != null && Object.hasOwnProperty.call(m, "packed"))
                    w.uint32(16).bool(m.packed);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.lazy != null && Object.hasOwnProperty.call(m, "lazy"))
                    w.uint32(40).bool(m.lazy);
                if (m.jstype != null && Object.hasOwnProperty.call(m, "jstype"))
                    w.uint32(48).int32(m.jstype);
                if (m.weak != null && Object.hasOwnProperty.call(m, "weak"))
                    w.uint32(80).bool(m.weak);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.ctype = r.int32();
                            break;
                        }
                    case 2: {
                            m.packed = r.bool();
                            break;
                        }
                    case 6: {
                            m.jstype = r.int32();
                            break;
                        }
                    case 5: {
                            m.lazy = r.bool();
                            break;
                        }
                    case 3: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 10: {
                            m.weak = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FieldOptions)
                    return d;
                var m = new $root.google.protobuf.FieldOptions();
                switch (d.ctype) {
                default:
                    if (typeof d.ctype === "number") {
                        m.ctype = d.ctype;
                        break;
                    }
                    break;
                case "STRING":
                case 0:
                    m.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    m.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    m.ctype = 2;
                    break;
                }
                if (d.packed != null) {
                    m.packed = Boolean(d.packed);
                }
                switch (d.jstype) {
                default:
                    if (typeof d.jstype === "number") {
                        m.jstype = d.jstype;
                        break;
                    }
                    break;
                case "JS_NORMAL":
                case 0:
                    m.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    m.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    m.jstype = 2;
                    break;
                }
                if (d.lazy != null) {
                    m.lazy = Boolean(d.lazy);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.weak != null) {
                    m.weak = Boolean(d.weak);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} m FieldOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.ctype = o.enums === String ? "STRING" : 0;
                    d.packed = false;
                    d.deprecated = false;
                    d.lazy = false;
                    d.jstype = o.enums === String ? "JS_NORMAL" : 0;
                    d.weak = false;
                }
                if (m.ctype != null && m.hasOwnProperty("ctype")) {
                    d.ctype = o.enums === String ? $root.google.protobuf.FieldOptions.CType[m.ctype] === undefined ? m.ctype : $root.google.protobuf.FieldOptions.CType[m.ctype] : m.ctype;
                }
                if (m.packed != null && m.hasOwnProperty("packed")) {
                    d.packed = m.packed;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.lazy != null && m.hasOwnProperty("lazy")) {
                    d.lazy = m.lazy;
                }
                if (m.jstype != null && m.hasOwnProperty("jstype")) {
                    d.jstype = o.enums === String ? $root.google.protobuf.FieldOptions.JSType[m.jstype] === undefined ? m.jstype : $root.google.protobuf.FieldOptions.JSType[m.jstype] : m.jstype;
                }
                if (m.weak != null && m.hasOwnProperty("weak")) {
                    d.weak = m.weak;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldOptions";
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [p] Properties to set
             */
            function OneofOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} m OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.OneofOptions)
                    return d;
                var m = new $root.google.protobuf.OneofOptions();
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} m OneofOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofOptions
             * @function getTypeUrl
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofOptions";
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [p] Properties to set
             */
            function EnumOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} m EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.allowAlias != null && Object.hasOwnProperty.call(m, "allowAlias"))
                    w.uint32(16).bool(m.allowAlias);
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            m.allowAlias = r.bool();
                            break;
                        }
                    case 3: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumOptions)
                    return d;
                var m = new $root.google.protobuf.EnumOptions();
                if (d.allowAlias != null) {
                    m.allowAlias = Boolean(d.allowAlias);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} m EnumOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.allowAlias = false;
                    d.deprecated = false;
                }
                if (m.allowAlias != null && m.hasOwnProperty("allowAlias")) {
                    d.allowAlias = m.allowAlias;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumOptions";
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [p] Properties to set
             */
            function EnumValueOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} m EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(8).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumValueOptions)
                    return d;
                var m = new $root.google.protobuf.EnumValueOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} m EnumValueOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [p] Properties to set
             */
            function ServiceOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} m ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.ServiceOptions)
                    return d;
                var m = new $root.google.protobuf.ServiceOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} m ServiceOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceOptions
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceOptions";
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             * @property {google.api.IHttpRule|null} [".google.api.http"] MethodOptions .google.api.http
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [p] Properties to set
             */
            function MethodOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MethodOptions .google.api.http.
             * @member {google.api.IHttpRule|null|undefined} .google.api.http
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".google.api.http"] = null;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} m MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && Object.hasOwnProperty.call(m, "deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                if (m[".google.api.http"] != null && Object.hasOwnProperty.call(m, ".google.api.http"))
                    $root.google.api.HttpRule.encode(m[".google.api.http"], w.uint32(578365826).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33: {
                            m.deprecated = r.bool();
                            break;
                        }
                    case 999: {
                            if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                                m.uninterpretedOption = [];
                            m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                            break;
                        }
                    case 72295728: {
                            m[".google.api.http"] = $root.google.api.HttpRule.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MethodOptions)
                    return d;
                var m = new $root.google.protobuf.MethodOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                if (d[".google.api.http"] != null) {
                    if (typeof d[".google.api.http"] !== "object")
                        throw TypeError(".google.protobuf.MethodOptions..google.api.http: object expected");
                    m[".google.api.http"] = $root.google.api.HttpRule.fromObject(d[".google.api.http"]);
                }
                return m;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} m MethodOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                    d[".google.api.http"] = null;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                if (m[".google.api.http"] != null && m.hasOwnProperty(".google.api.http")) {
                    d[".google.api.http"] = $root.google.api.HttpRule.toObject(m[".google.api.http"], o);
                }
                return d;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodOptions";
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [p] Properties to set
             */
            function UninterpretedOption(p) {
                this.name = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} m UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.name.length) {
                    for (var i = 0; i < m.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(m.name[i], w.uint32(18).fork()).ldelim();
                }
                if (m.identifierValue != null && Object.hasOwnProperty.call(m, "identifierValue"))
                    w.uint32(26).string(m.identifierValue);
                if (m.positiveIntValue != null && Object.hasOwnProperty.call(m, "positiveIntValue"))
                    w.uint32(32).uint64(m.positiveIntValue);
                if (m.negativeIntValue != null && Object.hasOwnProperty.call(m, "negativeIntValue"))
                    w.uint32(40).int64(m.negativeIntValue);
                if (m.doubleValue != null && Object.hasOwnProperty.call(m, "doubleValue"))
                    w.uint32(49).double(m.doubleValue);
                if (m.stringValue != null && Object.hasOwnProperty.call(m, "stringValue"))
                    w.uint32(58).bytes(m.stringValue);
                if (m.aggregateValue != null && Object.hasOwnProperty.call(m, "aggregateValue"))
                    w.uint32(66).string(m.aggregateValue);
                return w;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            if (!(m.name && m.name.length))
                                m.name = [];
                            m.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(r, r.uint32()));
                            break;
                        }
                    case 3: {
                            m.identifierValue = r.string();
                            break;
                        }
                    case 4: {
                            m.positiveIntValue = r.uint64();
                            break;
                        }
                    case 5: {
                            m.negativeIntValue = r.int64();
                            break;
                        }
                    case 6: {
                            m.doubleValue = r.double();
                            break;
                        }
                    case 7: {
                            m.stringValue = r.bytes();
                            break;
                        }
                    case 8: {
                            m.aggregateValue = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UninterpretedOption)
                    return d;
                var m = new $root.google.protobuf.UninterpretedOption();
                if (d.name) {
                    if (!Array.isArray(d.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    m.name = [];
                    for (var i = 0; i < d.name.length; ++i) {
                        if (typeof d.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        m.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(d.name[i]);
                    }
                }
                if (d.identifierValue != null) {
                    m.identifierValue = String(d.identifierValue);
                }
                if (d.positiveIntValue != null) {
                    if ($util.Long)
                        (m.positiveIntValue = $util.Long.fromValue(d.positiveIntValue)).unsigned = true;
                    else if (typeof d.positiveIntValue === "string")
                        m.positiveIntValue = parseInt(d.positiveIntValue, 10);
                    else if (typeof d.positiveIntValue === "number")
                        m.positiveIntValue = d.positiveIntValue;
                    else if (typeof d.positiveIntValue === "object")
                        m.positiveIntValue = new $util.LongBits(d.positiveIntValue.low >>> 0, d.positiveIntValue.high >>> 0).toNumber(true);
                }
                if (d.negativeIntValue != null) {
                    if ($util.Long)
                        (m.negativeIntValue = $util.Long.fromValue(d.negativeIntValue)).unsigned = false;
                    else if (typeof d.negativeIntValue === "string")
                        m.negativeIntValue = parseInt(d.negativeIntValue, 10);
                    else if (typeof d.negativeIntValue === "number")
                        m.negativeIntValue = d.negativeIntValue;
                    else if (typeof d.negativeIntValue === "object")
                        m.negativeIntValue = new $util.LongBits(d.negativeIntValue.low >>> 0, d.negativeIntValue.high >>> 0).toNumber();
                }
                if (d.doubleValue != null) {
                    m.doubleValue = Number(d.doubleValue);
                }
                if (d.stringValue != null) {
                    if (typeof d.stringValue === "string")
                        $util.base64.decode(d.stringValue, m.stringValue = $util.newBuffer($util.base64.length(d.stringValue)), 0);
                    else if (d.stringValue.length >= 0)
                        m.stringValue = d.stringValue;
                }
                if (d.aggregateValue != null) {
                    m.aggregateValue = String(d.aggregateValue);
                }
                return m;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} m UninterpretedOption
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.name = [];
                }
                if (o.defaults) {
                    d.identifierValue = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, true);
                        d.positiveIntValue = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.positiveIntValue = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.negativeIntValue = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.negativeIntValue = o.longs === String ? "0" : 0;
                    d.doubleValue = 0;
                    if (o.bytes === String)
                        d.stringValue = "";
                    else {
                        d.stringValue = [];
                        if (o.bytes !== Array)
                            d.stringValue = $util.newBuffer(d.stringValue);
                    }
                    d.aggregateValue = "";
                }
                if (m.name && m.name.length) {
                    d.name = [];
                    for (var j = 0; j < m.name.length; ++j) {
                        d.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(m.name[j], o);
                    }
                }
                if (m.identifierValue != null && m.hasOwnProperty("identifierValue")) {
                    d.identifierValue = m.identifierValue;
                }
                if (m.positiveIntValue != null && m.hasOwnProperty("positiveIntValue")) {
                    if (typeof m.positiveIntValue === "number")
                        d.positiveIntValue = o.longs === String ? String(m.positiveIntValue) : m.positiveIntValue;
                    else
                        d.positiveIntValue = o.longs === String ? $util.Long.prototype.toString.call(m.positiveIntValue) : o.longs === Number ? new $util.LongBits(m.positiveIntValue.low >>> 0, m.positiveIntValue.high >>> 0).toNumber(true) : m.positiveIntValue;
                }
                if (m.negativeIntValue != null && m.hasOwnProperty("negativeIntValue")) {
                    if (typeof m.negativeIntValue === "number")
                        d.negativeIntValue = o.longs === String ? String(m.negativeIntValue) : m.negativeIntValue;
                    else
                        d.negativeIntValue = o.longs === String ? $util.Long.prototype.toString.call(m.negativeIntValue) : o.longs === Number ? new $util.LongBits(m.negativeIntValue.low >>> 0, m.negativeIntValue.high >>> 0).toNumber() : m.negativeIntValue;
                }
                if (m.doubleValue != null && m.hasOwnProperty("doubleValue")) {
                    d.doubleValue = o.json && !isFinite(m.doubleValue) ? String(m.doubleValue) : m.doubleValue;
                }
                if (m.stringValue != null && m.hasOwnProperty("stringValue")) {
                    d.stringValue = o.bytes === String ? $util.base64.encode(m.stringValue, 0, m.stringValue.length) : o.bytes === Array ? Array.prototype.slice.call(m.stringValue) : m.stringValue;
                }
                if (m.aggregateValue != null && m.hasOwnProperty("aggregateValue")) {
                    d.aggregateValue = m.aggregateValue;
                }
                return d;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UninterpretedOption
             * @function getTypeUrl
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [p] Properties to set
                 */
                function NamePart(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} m NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    w.uint32(10).string(m.namePart);
                    w.uint32(16).bool(m.isExtension);
                    return w;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.namePart = r.string();
                                break;
                            }
                        case 2: {
                                m.isExtension = r.bool();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    if (!m.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: m });
                    if (!m.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: m });
                    return m;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return d;
                    var m = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (d.namePart != null) {
                        m.namePart = String(d.namePart);
                    }
                    if (d.isExtension != null) {
                        m.isExtension = Boolean(d.isExtension);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} m NamePart
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.namePart = "";
                        d.isExtension = false;
                    }
                    if (m.namePart != null && m.hasOwnProperty("namePart")) {
                        d.namePart = m.namePart;
                    }
                    if (m.isExtension != null && m.hasOwnProperty("isExtension")) {
                        d.isExtension = m.isExtension;
                    }
                    return d;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NamePart
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [p] Properties to set
             */
            function SourceCodeInfo(p) {
                this.location = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} m SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.location != null && m.location.length) {
                    for (var i = 0; i < m.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(m.location[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.location && m.location.length))
                                m.location = [];
                            m.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.SourceCodeInfo)
                    return d;
                var m = new $root.google.protobuf.SourceCodeInfo();
                if (d.location) {
                    if (!Array.isArray(d.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    m.location = [];
                    for (var i = 0; i < d.location.length; ++i) {
                        if (typeof d.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        m.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(d.location[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} m SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.location = [];
                }
                if (m.location && m.location.length) {
                    d.location = [];
                    for (var j = 0; j < m.location.length; ++j) {
                        d.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(m.location[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SourceCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [p] Properties to set
                 */
                function Location(p) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} m Location message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.span != null && m.span.length) {
                        w.uint32(18).fork();
                        for (var i = 0; i < m.span.length; ++i)
                            w.int32(m.span[i]);
                        w.ldelim();
                    }
                    if (m.leadingComments != null && Object.hasOwnProperty.call(m, "leadingComments"))
                        w.uint32(26).string(m.leadingComments);
                    if (m.trailingComments != null && Object.hasOwnProperty.call(m, "trailingComments"))
                        w.uint32(34).string(m.trailingComments);
                    if (m.leadingDetachedComments != null && m.leadingDetachedComments.length) {
                        for (var i = 0; i < m.leadingDetachedComments.length; ++i)
                            w.uint32(50).string(m.leadingDetachedComments[i]);
                    }
                    return w;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.path && m.path.length))
                                    m.path = [];
                                if ((t & 7) === 2) {
                                    var c2 = r.uint32() + r.pos;
                                    while (r.pos < c2)
                                        m.path.push(r.int32());
                                } else
                                    m.path.push(r.int32());
                                break;
                            }
                        case 2: {
                                if (!(m.span && m.span.length))
                                    m.span = [];
                                if ((t & 7) === 2) {
                                    var c2 = r.uint32() + r.pos;
                                    while (r.pos < c2)
                                        m.span.push(r.int32());
                                } else
                                    m.span.push(r.int32());
                                break;
                            }
                        case 3: {
                                m.leadingComments = r.string();
                                break;
                            }
                        case 4: {
                                m.trailingComments = r.string();
                                break;
                            }
                        case 6: {
                                if (!(m.leadingDetachedComments && m.leadingDetachedComments.length))
                                    m.leadingDetachedComments = [];
                                m.leadingDetachedComments.push(r.string());
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return d;
                    var m = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (d.path) {
                        if (!Array.isArray(d.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        m.path = [];
                        for (var i = 0; i < d.path.length; ++i) {
                            m.path[i] = d.path[i] | 0;
                        }
                    }
                    if (d.span) {
                        if (!Array.isArray(d.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        m.span = [];
                        for (var i = 0; i < d.span.length; ++i) {
                            m.span[i] = d.span[i] | 0;
                        }
                    }
                    if (d.leadingComments != null) {
                        m.leadingComments = String(d.leadingComments);
                    }
                    if (d.trailingComments != null) {
                        m.trailingComments = String(d.trailingComments);
                    }
                    if (d.leadingDetachedComments) {
                        if (!Array.isArray(d.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        m.leadingDetachedComments = [];
                        for (var i = 0; i < d.leadingDetachedComments.length; ++i) {
                            m.leadingDetachedComments[i] = String(d.leadingDetachedComments[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} m Location
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.path = [];
                        d.span = [];
                        d.leadingDetachedComments = [];
                    }
                    if (o.defaults) {
                        d.leadingComments = "";
                        d.trailingComments = "";
                    }
                    if (m.path && m.path.length) {
                        d.path = [];
                        for (var j = 0; j < m.path.length; ++j) {
                            d.path[j] = m.path[j];
                        }
                    }
                    if (m.span && m.span.length) {
                        d.span = [];
                        for (var j = 0; j < m.span.length; ++j) {
                            d.span[j] = m.span[j];
                        }
                    }
                    if (m.leadingComments != null && m.hasOwnProperty("leadingComments")) {
                        d.leadingComments = m.leadingComments;
                    }
                    if (m.trailingComments != null && m.hasOwnProperty("trailingComments")) {
                        d.trailingComments = m.trailingComments;
                    }
                    if (m.leadingDetachedComments && m.leadingDetachedComments.length) {
                        d.leadingDetachedComments = [];
                        for (var j = 0; j < m.leadingDetachedComments.length; ++j) {
                            d.leadingDetachedComments[j] = m.leadingDetachedComments[j];
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Location
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [p] Properties to set
             */
            function GeneratedCodeInfo(p) {
                this.annotation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} m GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.annotation != null && m.annotation.length) {
                    for (var i = 0; i < m.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(m.annotation[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.annotation && m.annotation.length))
                                m.annotation = [];
                            m.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return d;
                var m = new $root.google.protobuf.GeneratedCodeInfo();
                if (d.annotation) {
                    if (!Array.isArray(d.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    m.annotation = [];
                    for (var i = 0; i < d.annotation.length; ++i) {
                        if (typeof d.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        m.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(d.annotation[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} m GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.annotation = [];
                }
                if (m.annotation && m.annotation.length) {
                    d.annotation = [];
                    for (var j = 0; j < m.annotation.length; ++j) {
                        d.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(m.annotation[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeneratedCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [p] Properties to set
                 */
                function Annotation(p) {
                    this.path = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} m Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.sourceFile != null && Object.hasOwnProperty.call(m, "sourceFile"))
                        w.uint32(18).string(m.sourceFile);
                    if (m.begin != null && Object.hasOwnProperty.call(m, "begin"))
                        w.uint32(24).int32(m.begin);
                    if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                        w.uint32(32).int32(m.end);
                    return w;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                if (!(m.path && m.path.length))
                                    m.path = [];
                                if ((t & 7) === 2) {
                                    var c2 = r.uint32() + r.pos;
                                    while (r.pos < c2)
                                        m.path.push(r.int32());
                                } else
                                    m.path.push(r.int32());
                                break;
                            }
                        case 2: {
                                m.sourceFile = r.string();
                                break;
                            }
                        case 3: {
                                m.begin = r.int32();
                                break;
                            }
                        case 4: {
                                m.end = r.int32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return d;
                    var m = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (d.path) {
                        if (!Array.isArray(d.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        m.path = [];
                        for (var i = 0; i < d.path.length; ++i) {
                            m.path[i] = d.path[i] | 0;
                        }
                    }
                    if (d.sourceFile != null) {
                        m.sourceFile = String(d.sourceFile);
                    }
                    if (d.begin != null) {
                        m.begin = d.begin | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} m Annotation
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.path = [];
                    }
                    if (o.defaults) {
                        d.sourceFile = "";
                        d.begin = 0;
                        d.end = 0;
                    }
                    if (m.path && m.path.length) {
                        d.path = [];
                        for (var j = 0; j < m.path.length; ++j) {
                            d.path[j] = m.path[j];
                        }
                    }
                    if (m.sourceFile != null && m.hasOwnProperty("sourceFile")) {
                        d.sourceFile = m.sourceFile;
                    }
                    if (m.begin != null && m.hasOwnProperty("begin")) {
                        d.begin = m.begin;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Annotation
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [p] Properties to set
             */
            function Empty(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new Empty instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             * @returns {google.protobuf.Empty} Empty instance
             */
            Empty.create = function create(properties) {
                return new Empty(properties);
            };

            /**
             * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} m Empty message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                return w;
            };

            /**
             * Decodes an Empty message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Empty();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Empty)
                    return d;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} m Empty
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Empty
             * @function getTypeUrl
             * @memberof google.protobuf.Empty
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Empty";
            };

            return Empty;
        })();

        protobuf.Duration = (function() {

            /**
             * Properties of a Duration.
             * @memberof google.protobuf
             * @interface IDuration
             * @property {Long|null} [seconds] Duration seconds
             * @property {number|null} [nanos] Duration nanos
             */

            /**
             * Constructs a new Duration.
             * @memberof google.protobuf
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {google.protobuf.IDuration=} [p] Properties to set
             */
            function Duration(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Duration seconds.
             * @member {Long} seconds
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Duration nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.nanos = 0;

            /**
             * Creates a new Duration instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             * @returns {google.protobuf.Duration} Duration instance
             */
            Duration.create = function create(properties) {
                return new Duration(properties);
            };

            /**
             * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} m Duration message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.seconds != null && Object.hasOwnProperty.call(m, "seconds"))
                    w.uint32(8).int64(m.seconds);
                if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                    w.uint32(16).int32(m.nanos);
                return w;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Duration();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.seconds = r.int64();
                            break;
                        }
                    case 2: {
                            m.nanos = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Duration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Duration} Duration
             */
            Duration.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Duration)
                    return d;
                var m = new $root.google.protobuf.Duration();
                if (d.seconds != null) {
                    if ($util.Long)
                        (m.seconds = $util.Long.fromValue(d.seconds)).unsigned = false;
                    else if (typeof d.seconds === "string")
                        m.seconds = parseInt(d.seconds, 10);
                    else if (typeof d.seconds === "number")
                        m.seconds = d.seconds;
                    else if (typeof d.seconds === "object")
                        m.seconds = new $util.LongBits(d.seconds.low >>> 0, d.seconds.high >>> 0).toNumber();
                }
                if (d.nanos != null) {
                    m.nanos = d.nanos | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a Duration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.Duration} m Duration
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Duration.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.seconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.seconds = o.longs === String ? "0" : 0;
                    d.nanos = 0;
                }
                if (m.seconds != null && m.hasOwnProperty("seconds")) {
                    if (typeof m.seconds === "number")
                        d.seconds = o.longs === String ? String(m.seconds) : m.seconds;
                    else
                        d.seconds = o.longs === String ? $util.Long.prototype.toString.call(m.seconds) : o.longs === Number ? new $util.LongBits(m.seconds.low >>> 0, m.seconds.high >>> 0).toNumber() : m.seconds;
                }
                if (m.nanos != null && m.hasOwnProperty("nanos")) {
                    d.nanos = m.nanos;
                }
                return d;
            };

            /**
             * Converts this Duration to JSON.
             * @function toJSON
             * @memberof google.protobuf.Duration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Duration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Duration
             * @function getTypeUrl
             * @memberof google.protobuf.Duration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Duration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Duration";
            };

            return Duration;
        })();

        protobuf.DoubleValue = (function() {

            /**
             * Properties of a DoubleValue.
             * @memberof google.protobuf
             * @interface IDoubleValue
             * @property {number|null} [value] DoubleValue value
             */

            /**
             * Constructs a new DoubleValue.
             * @memberof google.protobuf
             * @classdesc Represents a DoubleValue.
             * @implements IDoubleValue
             * @constructor
             * @param {google.protobuf.IDoubleValue=} [p] Properties to set
             */
            function DoubleValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DoubleValue value.
             * @member {number} value
             * @memberof google.protobuf.DoubleValue
             * @instance
             */
            DoubleValue.prototype.value = 0;

            /**
             * Creates a new DoubleValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             * @returns {google.protobuf.DoubleValue} DoubleValue instance
             */
            DoubleValue.create = function create(properties) {
                return new DoubleValue(properties);
            };

            /**
             * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} m DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(9).double(m.value);
                return w;
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DoubleValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.double();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.DoubleValue} DoubleValue
             */
            DoubleValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.DoubleValue)
                    return d;
                var m = new $root.google.protobuf.DoubleValue();
                if (d.value != null) {
                    m.value = Number(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.DoubleValue} m DoubleValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DoubleValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
                }
                return d;
            };

            /**
             * Converts this DoubleValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.DoubleValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DoubleValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DoubleValue
             * @function getTypeUrl
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            /**
             * Properties of a FloatValue.
             * @memberof google.protobuf
             * @interface IFloatValue
             * @property {number|null} [value] FloatValue value
             */

            /**
             * Constructs a new FloatValue.
             * @memberof google.protobuf
             * @classdesc Represents a FloatValue.
             * @implements IFloatValue
             * @constructor
             * @param {google.protobuf.IFloatValue=} [p] Properties to set
             */
            function FloatValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FloatValue value.
             * @member {number} value
             * @memberof google.protobuf.FloatValue
             * @instance
             */
            FloatValue.prototype.value = 0;

            /**
             * Creates a new FloatValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             * @returns {google.protobuf.FloatValue} FloatValue instance
             */
            FloatValue.create = function create(properties) {
                return new FloatValue(properties);
            };

            /**
             * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} m FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(13).float(m.value);
                return w;
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FloatValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FloatValue} FloatValue
             */
            FloatValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FloatValue)
                    return d;
                var m = new $root.google.protobuf.FloatValue();
                if (d.value != null) {
                    m.value = Number(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.FloatValue} m FloatValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FloatValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
                }
                return d;
            };

            /**
             * Converts this FloatValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.FloatValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FloatValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FloatValue
             * @function getTypeUrl
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            /**
             * Properties of an Int64Value.
             * @memberof google.protobuf
             * @interface IInt64Value
             * @property {Long|null} [value] Int64Value value
             */

            /**
             * Constructs a new Int64Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int64Value.
             * @implements IInt64Value
             * @constructor
             * @param {google.protobuf.IInt64Value=} [p] Properties to set
             */
            function Int64Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Int64Value value.
             * @member {Long} value
             * @memberof google.protobuf.Int64Value
             * @instance
             */
            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Int64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.Int64Value} Int64Value instance
             */
            Int64Value.create = function create(properties) {
                return new Int64Value(properties);
            };

            /**
             * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} m Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).int64(m.value);
                return w;
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Int64Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.int64();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Int64Value} Int64Value
             */
            Int64Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Int64Value)
                    return d;
                var m = new $root.google.protobuf.Int64Value();
                if (d.value != null) {
                    if ($util.Long)
                        (m.value = $util.Long.fromValue(d.value)).unsigned = false;
                    else if (typeof d.value === "string")
                        m.value = parseInt(d.value, 10);
                    else if (typeof d.value === "number")
                        m.value = d.value;
                    else if (typeof d.value === "object")
                        m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.Int64Value} m Int64Value
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int64Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.value = o.longs === String ? "0" : 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value === "number")
                        d.value = o.longs === String ? String(m.value) : m.value;
                    else
                        d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber() : m.value;
                }
                return d;
            };

            /**
             * Converts this Int64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int64Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            /**
             * Properties of a UInt64Value.
             * @memberof google.protobuf
             * @interface IUInt64Value
             * @property {Long|null} [value] UInt64Value value
             */

            /**
             * Constructs a new UInt64Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt64Value.
             * @implements IUInt64Value
             * @constructor
             * @param {google.protobuf.IUInt64Value=} [p] Properties to set
             */
            function UInt64Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UInt64Value value.
             * @member {Long} value
             * @memberof google.protobuf.UInt64Value
             * @instance
             */
            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UInt64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt64Value} UInt64Value instance
             */
            UInt64Value.create = function create(properties) {
                return new UInt64Value(properties);
            };

            /**
             * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} m UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).uint64(m.value);
                return w;
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UInt64Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.uint64();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.UInt64Value} UInt64Value
             */
            UInt64Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UInt64Value)
                    return d;
                var m = new $root.google.protobuf.UInt64Value();
                if (d.value != null) {
                    if ($util.Long)
                        (m.value = $util.Long.fromValue(d.value)).unsigned = true;
                    else if (typeof d.value === "string")
                        m.value = parseInt(d.value, 10);
                    else if (typeof d.value === "number")
                        m.value = d.value;
                    else if (typeof d.value === "object")
                        m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber(true);
                }
                return m;
            };

            /**
             * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.UInt64Value} m UInt64Value
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt64Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, true);
                        d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.value = o.longs === String ? "0" : 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value === "number")
                        d.value = o.longs === String ? String(m.value) : m.value;
                    else
                        d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber(true) : m.value;
                }
                return d;
            };

            /**
             * Converts this UInt64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt64Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            /**
             * Properties of an Int32Value.
             * @memberof google.protobuf
             * @interface IInt32Value
             * @property {number|null} [value] Int32Value value
             */

            /**
             * Constructs a new Int32Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int32Value.
             * @implements IInt32Value
             * @constructor
             * @param {google.protobuf.IInt32Value=} [p] Properties to set
             */
            function Int32Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Int32Value value.
             * @member {number} value
             * @memberof google.protobuf.Int32Value
             * @instance
             */
            Int32Value.prototype.value = 0;

            /**
             * Creates a new Int32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.Int32Value} Int32Value instance
             */
            Int32Value.create = function create(properties) {
                return new Int32Value(properties);
            };

            /**
             * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} m Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).int32(m.value);
                return w;
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Int32Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Int32Value} Int32Value
             */
            Int32Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Int32Value)
                    return d;
                var m = new $root.google.protobuf.Int32Value();
                if (d.value != null) {
                    m.value = d.value | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.Int32Value} m Int32Value
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int32Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this Int32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int32Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            /**
             * Properties of a UInt32Value.
             * @memberof google.protobuf
             * @interface IUInt32Value
             * @property {number|null} [value] UInt32Value value
             */

            /**
             * Constructs a new UInt32Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt32Value.
             * @implements IUInt32Value
             * @constructor
             * @param {google.protobuf.IUInt32Value=} [p] Properties to set
             */
            function UInt32Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UInt32Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt32Value
             * @instance
             */
            UInt32Value.prototype.value = 0;

            /**
             * Creates a new UInt32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt32Value} UInt32Value instance
             */
            UInt32Value.create = function create(properties) {
                return new UInt32Value(properties);
            };

            /**
             * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} m UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).uint32(m.value);
                return w;
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UInt32Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.uint32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.UInt32Value} UInt32Value
             */
            UInt32Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UInt32Value)
                    return d;
                var m = new $root.google.protobuf.UInt32Value();
                if (d.value != null) {
                    m.value = d.value >>> 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.UInt32Value} m UInt32Value
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt32Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this UInt32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt32Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            /**
             * Properties of a BoolValue.
             * @memberof google.protobuf
             * @interface IBoolValue
             * @property {boolean|null} [value] BoolValue value
             */

            /**
             * Constructs a new BoolValue.
             * @memberof google.protobuf
             * @classdesc Represents a BoolValue.
             * @implements IBoolValue
             * @constructor
             * @param {google.protobuf.IBoolValue=} [p] Properties to set
             */
            function BoolValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BoolValue value.
             * @member {boolean} value
             * @memberof google.protobuf.BoolValue
             * @instance
             */
            BoolValue.prototype.value = false;

            /**
             * Creates a new BoolValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             * @returns {google.protobuf.BoolValue} BoolValue instance
             */
            BoolValue.create = function create(properties) {
                return new BoolValue(properties);
            };

            /**
             * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} m BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).bool(m.value);
                return w;
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.BoolValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.BoolValue} BoolValue
             */
            BoolValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.BoolValue)
                    return d;
                var m = new $root.google.protobuf.BoolValue();
                if (d.value != null) {
                    m.value = Boolean(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.BoolValue} m BoolValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BoolValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = false;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this BoolValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BoolValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BoolValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BoolValue
             * @function getTypeUrl
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            /**
             * Properties of a StringValue.
             * @memberof google.protobuf
             * @interface IStringValue
             * @property {string|null} [value] StringValue value
             */

            /**
             * Constructs a new StringValue.
             * @memberof google.protobuf
             * @classdesc Represents a StringValue.
             * @implements IStringValue
             * @constructor
             * @param {google.protobuf.IStringValue=} [p] Properties to set
             */
            function StringValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * StringValue value.
             * @member {string} value
             * @memberof google.protobuf.StringValue
             * @instance
             */
            StringValue.prototype.value = "";

            /**
             * Creates a new StringValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             * @returns {google.protobuf.StringValue} StringValue instance
             */
            StringValue.create = function create(properties) {
                return new StringValue(properties);
            };

            /**
             * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} m StringValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).string(m.value);
                return w;
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.StringValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.StringValue} StringValue
             */
            StringValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.StringValue)
                    return d;
                var m = new $root.google.protobuf.StringValue();
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a StringValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.StringValue} m StringValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StringValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = "";
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this StringValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.StringValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StringValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StringValue
             * @function getTypeUrl
             * @memberof google.protobuf.StringValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            /**
             * Properties of a BytesValue.
             * @memberof google.protobuf
             * @interface IBytesValue
             * @property {Uint8Array|null} [value] BytesValue value
             */

            /**
             * Constructs a new BytesValue.
             * @memberof google.protobuf
             * @classdesc Represents a BytesValue.
             * @implements IBytesValue
             * @constructor
             * @param {google.protobuf.IBytesValue=} [p] Properties to set
             */
            function BytesValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BytesValue value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.BytesValue
             * @instance
             */
            BytesValue.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new BytesValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             * @returns {google.protobuf.BytesValue} BytesValue instance
             */
            BytesValue.create = function create(properties) {
                return new BytesValue(properties);
            };

            /**
             * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} m BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).bytes(m.value);
                return w;
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.BytesValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.BytesValue} BytesValue
             */
            BytesValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.BytesValue)
                    return d;
                var m = new $root.google.protobuf.BytesValue();
                if (d.value != null) {
                    if (typeof d.value === "string")
                        $util.base64.decode(d.value, m.value = $util.newBuffer($util.base64.length(d.value)), 0);
                    else if (d.value.length >= 0)
                        m.value = d.value;
                }
                return m;
            };

            /**
             * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.BytesValue} m BytesValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BytesValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if (o.bytes === String)
                        d.value = "";
                    else {
                        d.value = [];
                        if (o.bytes !== Array)
                            d.value = $util.newBuffer(d.value);
                    }
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.bytes === String ? $util.base64.encode(m.value, 0, m.value.length) : o.bytes === Array ? Array.prototype.slice.call(m.value) : m.value;
                }
                return d;
            };

            /**
             * Converts this BytesValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BytesValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BytesValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BytesValue
             * @function getTypeUrl
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        protobuf.Struct = (function() {

            /**
             * Properties of a Struct.
             * @memberof google.protobuf
             * @interface IStruct
             * @property {Object.<string,google.protobuf.IValue>|null} [fields] Struct fields
             */

            /**
             * Constructs a new Struct.
             * @memberof google.protobuf
             * @classdesc Represents a Struct.
             * @implements IStruct
             * @constructor
             * @param {google.protobuf.IStruct=} [p] Properties to set
             */
            function Struct(p) {
                this.fields = {};
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Struct fields.
             * @member {Object.<string,google.protobuf.IValue>} fields
             * @memberof google.protobuf.Struct
             * @instance
             */
            Struct.prototype.fields = $util.emptyObject;

            /**
             * Creates a new Struct instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.IStruct=} [properties] Properties to set
             * @returns {google.protobuf.Struct} Struct instance
             */
            Struct.create = function create(properties) {
                return new Struct(properties);
            };

            /**
             * Encodes the specified Struct message. Does not implicitly {@link google.protobuf.Struct.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.IStruct} m Struct message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Struct.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.fields != null && Object.hasOwnProperty.call(m, "fields")) {
                    for (var ks = Object.keys(m.fields), i = 0; i < ks.length; ++i) {
                        w.uint32(10).fork().uint32(10).string(ks[i]);
                        $root.google.protobuf.Value.encode(m.fields[ks[i]], w.uint32(18).fork()).ldelim().ldelim();
                    }
                }
                return w;
            };

            /**
             * Decodes a Struct message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Struct
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Struct} Struct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Struct.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Struct(), k, value;
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (m.fields === $util.emptyObject)
                                m.fields = {};
                            var c2 = r.uint32() + r.pos;
                            k = "";
                            value = null;
                            while (r.pos < c2) {
                                var tag2 = r.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    k = r.string();
                                    break;
                                case 2:
                                    value = $root.google.protobuf.Value.decode(r, r.uint32());
                                    break;
                                default:
                                    r.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            m.fields[k] = value;
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Struct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Struct
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Struct} Struct
             */
            Struct.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Struct)
                    return d;
                var m = new $root.google.protobuf.Struct();
                if (d.fields) {
                    if (typeof d.fields !== "object")
                        throw TypeError(".google.protobuf.Struct.fields: object expected");
                    m.fields = {};
                    for (var ks = Object.keys(d.fields), i = 0; i < ks.length; ++i) {
                        if (typeof d.fields[ks[i]] !== "object")
                            throw TypeError(".google.protobuf.Struct.fields: object expected");
                        m.fields[ks[i]] = $root.google.protobuf.Value.fromObject(d.fields[ks[i]]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Struct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.Struct} m Struct
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Struct.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.objects || o.defaults) {
                    d.fields = {};
                }
                var ks2;
                if (m.fields && (ks2 = Object.keys(m.fields)).length) {
                    d.fields = {};
                    for (var j = 0; j < ks2.length; ++j) {
                        d.fields[ks2[j]] = $root.google.protobuf.Value.toObject(m.fields[ks2[j]], o);
                    }
                }
                return d;
            };

            /**
             * Converts this Struct to JSON.
             * @function toJSON
             * @memberof google.protobuf.Struct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Struct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Struct
             * @function getTypeUrl
             * @memberof google.protobuf.Struct
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Struct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Struct";
            };

            return Struct;
        })();

        protobuf.Value = (function() {

            /**
             * Properties of a Value.
             * @memberof google.protobuf
             * @interface IValue
             * @property {google.protobuf.NullValue|null} [nullValue] Value nullValue
             * @property {number|null} [numberValue] Value numberValue
             * @property {string|null} [stringValue] Value stringValue
             * @property {boolean|null} [boolValue] Value boolValue
             * @property {google.protobuf.IStruct|null} [structValue] Value structValue
             * @property {google.protobuf.IListValue|null} [listValue] Value listValue
             */

            /**
             * Constructs a new Value.
             * @memberof google.protobuf
             * @classdesc Represents a Value.
             * @implements IValue
             * @constructor
             * @param {google.protobuf.IValue=} [p] Properties to set
             */
            function Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Value nullValue.
             * @member {google.protobuf.NullValue|null|undefined} nullValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.nullValue = null;

            /**
             * Value numberValue.
             * @member {number|null|undefined} numberValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.numberValue = null;

            /**
             * Value stringValue.
             * @member {string|null|undefined} stringValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.stringValue = null;

            /**
             * Value boolValue.
             * @member {boolean|null|undefined} boolValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.boolValue = null;

            /**
             * Value structValue.
             * @member {google.protobuf.IStruct|null|undefined} structValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.structValue = null;

            /**
             * Value listValue.
             * @member {google.protobuf.IListValue|null|undefined} listValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.listValue = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Value kind.
             * @member {"nullValue"|"numberValue"|"stringValue"|"boolValue"|"structValue"|"listValue"|undefined} kind
             * @memberof google.protobuf.Value
             * @instance
             */
            Object.defineProperty(Value.prototype, "kind", {
                get: $util.oneOfGetter($oneOfFields = ["nullValue", "numberValue", "stringValue", "boolValue", "structValue", "listValue"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.IValue=} [properties] Properties to set
             * @returns {google.protobuf.Value} Value instance
             */
            Value.create = function create(properties) {
                return new Value(properties);
            };

            /**
             * Encodes the specified Value message. Does not implicitly {@link google.protobuf.Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.IValue} m Value message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.nullValue != null && Object.hasOwnProperty.call(m, "nullValue"))
                    w.uint32(8).int32(m.nullValue);
                if (m.numberValue != null && Object.hasOwnProperty.call(m, "numberValue"))
                    w.uint32(17).double(m.numberValue);
                if (m.stringValue != null && Object.hasOwnProperty.call(m, "stringValue"))
                    w.uint32(26).string(m.stringValue);
                if (m.boolValue != null && Object.hasOwnProperty.call(m, "boolValue"))
                    w.uint32(32).bool(m.boolValue);
                if (m.structValue != null && Object.hasOwnProperty.call(m, "structValue"))
                    $root.google.protobuf.Struct.encode(m.structValue, w.uint32(42).fork()).ldelim();
                if (m.listValue != null && Object.hasOwnProperty.call(m, "listValue"))
                    $root.google.protobuf.ListValue.encode(m.listValue, w.uint32(50).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Value} Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.nullValue = r.int32();
                            break;
                        }
                    case 2: {
                            m.numberValue = r.double();
                            break;
                        }
                    case 3: {
                            m.stringValue = r.string();
                            break;
                        }
                    case 4: {
                            m.boolValue = r.bool();
                            break;
                        }
                    case 5: {
                            m.structValue = $root.google.protobuf.Struct.decode(r, r.uint32());
                            break;
                        }
                    case 6: {
                            m.listValue = $root.google.protobuf.ListValue.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Value
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Value} Value
             */
            Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Value)
                    return d;
                var m = new $root.google.protobuf.Value();
                switch (d.nullValue) {
                default:
                    if (typeof d.nullValue === "number") {
                        m.nullValue = d.nullValue;
                        break;
                    }
                    break;
                case "NULL_VALUE":
                case 0:
                    m.nullValue = 0;
                    break;
                }
                if (d.numberValue != null) {
                    m.numberValue = Number(d.numberValue);
                }
                if (d.stringValue != null) {
                    m.stringValue = String(d.stringValue);
                }
                if (d.boolValue != null) {
                    m.boolValue = Boolean(d.boolValue);
                }
                if (d.structValue != null) {
                    if (typeof d.structValue !== "object")
                        throw TypeError(".google.protobuf.Value.structValue: object expected");
                    m.structValue = $root.google.protobuf.Struct.fromObject(d.structValue);
                }
                if (d.listValue != null) {
                    if (typeof d.listValue !== "object")
                        throw TypeError(".google.protobuf.Value.listValue: object expected");
                    m.listValue = $root.google.protobuf.ListValue.fromObject(d.listValue);
                }
                return m;
            };

            /**
             * Creates a plain object from a Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.Value} m Value
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (m.nullValue != null && m.hasOwnProperty("nullValue")) {
                    d.nullValue = o.enums === String ? $root.google.protobuf.NullValue[m.nullValue] === undefined ? m.nullValue : $root.google.protobuf.NullValue[m.nullValue] : m.nullValue;
                    if (o.oneofs)
                        d.kind = "nullValue";
                }
                if (m.numberValue != null && m.hasOwnProperty("numberValue")) {
                    d.numberValue = o.json && !isFinite(m.numberValue) ? String(m.numberValue) : m.numberValue;
                    if (o.oneofs)
                        d.kind = "numberValue";
                }
                if (m.stringValue != null && m.hasOwnProperty("stringValue")) {
                    d.stringValue = m.stringValue;
                    if (o.oneofs)
                        d.kind = "stringValue";
                }
                if (m.boolValue != null && m.hasOwnProperty("boolValue")) {
                    d.boolValue = m.boolValue;
                    if (o.oneofs)
                        d.kind = "boolValue";
                }
                if (m.structValue != null && m.hasOwnProperty("structValue")) {
                    d.structValue = $root.google.protobuf.Struct.toObject(m.structValue, o);
                    if (o.oneofs)
                        d.kind = "structValue";
                }
                if (m.listValue != null && m.hasOwnProperty("listValue")) {
                    d.listValue = $root.google.protobuf.ListValue.toObject(m.listValue, o);
                    if (o.oneofs)
                        d.kind = "listValue";
                }
                return d;
            };

            /**
             * Converts this Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Value
             * @function getTypeUrl
             * @memberof google.protobuf.Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Value";
            };

            return Value;
        })();

        /**
         * NullValue enum.
         * @name google.protobuf.NullValue
         * @enum {number}
         * @property {number} NULL_VALUE=0 NULL_VALUE value
         */
        protobuf.NullValue = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NULL_VALUE"] = 0;
            return values;
        })();

        protobuf.ListValue = (function() {

            /**
             * Properties of a ListValue.
             * @memberof google.protobuf
             * @interface IListValue
             * @property {Array.<google.protobuf.IValue>|null} [values] ListValue values
             */

            /**
             * Constructs a new ListValue.
             * @memberof google.protobuf
             * @classdesc Represents a ListValue.
             * @implements IListValue
             * @constructor
             * @param {google.protobuf.IListValue=} [p] Properties to set
             */
            function ListValue(p) {
                this.values = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ListValue values.
             * @member {Array.<google.protobuf.IValue>} values
             * @memberof google.protobuf.ListValue
             * @instance
             */
            ListValue.prototype.values = $util.emptyArray;

            /**
             * Creates a new ListValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.IListValue=} [properties] Properties to set
             * @returns {google.protobuf.ListValue} ListValue instance
             */
            ListValue.create = function create(properties) {
                return new ListValue(properties);
            };

            /**
             * Encodes the specified ListValue message. Does not implicitly {@link google.protobuf.ListValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.IListValue} m ListValue message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.values != null && m.values.length) {
                    for (var i = 0; i < m.values.length; ++i)
                        $root.google.protobuf.Value.encode(m.values[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a ListValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ListValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.ListValue} ListValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ListValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.values && m.values.length))
                                m.values = [];
                            m.values.push($root.google.protobuf.Value.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ListValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ListValue
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.ListValue} ListValue
             */
            ListValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.ListValue)
                    return d;
                var m = new $root.google.protobuf.ListValue();
                if (d.values) {
                    if (!Array.isArray(d.values))
                        throw TypeError(".google.protobuf.ListValue.values: array expected");
                    m.values = [];
                    for (var i = 0; i < d.values.length; ++i) {
                        if (typeof d.values[i] !== "object")
                            throw TypeError(".google.protobuf.ListValue.values: object expected");
                        m.values[i] = $root.google.protobuf.Value.fromObject(d.values[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ListValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.ListValue} m ListValue
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.values = [];
                }
                if (m.values && m.values.length) {
                    d.values = [];
                    for (var j = 0; j < m.values.length; ++j) {
                        d.values[j] = $root.google.protobuf.Value.toObject(m.values[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ListValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.ListValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListValue
             * @function getTypeUrl
             * @memberof google.protobuf.ListValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ListValue";
            };

            return ListValue;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [p] Properties to set
             */
            function Any(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} m Any message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.type_url != null && Object.hasOwnProperty.call(m, "type_url"))
                    w.uint32(10).string(m.type_url);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(18).bytes(m.value);
                return w;
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Any();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.type_url = r.string();
                            break;
                        }
                    case 2: {
                            m.value = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Any)
                    return d;
                var m = new $root.google.protobuf.Any();
                if (d.type_url != null) {
                    m.type_url = String(d.type_url);
                }
                if (d.value != null) {
                    if (typeof d.value === "string")
                        $util.base64.decode(d.value, m.value = $util.newBuffer($util.base64.length(d.value)), 0);
                    else if (d.value.length >= 0)
                        m.value = d.value;
                }
                return m;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} m Any
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type_url = "";
                    if (o.bytes === String)
                        d.value = "";
                    else {
                        d.value = [];
                        if (o.bytes !== Array)
                            d.value = $util.newBuffer(d.value);
                    }
                }
                if (m.type_url != null && m.hasOwnProperty("type_url")) {
                    d.type_url = m.type_url;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.bytes === String ? $util.base64.encode(m.value, 0, m.value.length) : o.bytes === Array ? Array.prototype.slice.call(m.value) : m.value;
                }
                return d;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Any
             * @function getTypeUrl
             * @memberof google.protobuf.Any
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Any";
            };

            return Any;
        })();

        return protobuf;
    })();

    google.api = (function() {

        /**
         * Namespace api.
         * @memberof google
         * @namespace
         */
        var api = {};

        api.Http = (function() {

            /**
             * Properties of a Http.
             * @memberof google.api
             * @interface IHttp
             * @property {Array.<google.api.IHttpRule>|null} [rules] Http rules
             */

            /**
             * Constructs a new Http.
             * @memberof google.api
             * @classdesc Represents a Http.
             * @implements IHttp
             * @constructor
             * @param {google.api.IHttp=} [p] Properties to set
             */
            function Http(p) {
                this.rules = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Http rules.
             * @member {Array.<google.api.IHttpRule>} rules
             * @memberof google.api.Http
             * @instance
             */
            Http.prototype.rules = $util.emptyArray;

            /**
             * Creates a new Http instance using the specified properties.
             * @function create
             * @memberof google.api.Http
             * @static
             * @param {google.api.IHttp=} [properties] Properties to set
             * @returns {google.api.Http} Http instance
             */
            Http.create = function create(properties) {
                return new Http(properties);
            };

            /**
             * Encodes the specified Http message. Does not implicitly {@link google.api.Http.verify|verify} messages.
             * @function encode
             * @memberof google.api.Http
             * @static
             * @param {google.api.IHttp} m Http message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Http.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rules != null && m.rules.length) {
                    for (var i = 0; i < m.rules.length; ++i)
                        $root.google.api.HttpRule.encode(m.rules[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a Http message from the specified reader or buffer.
             * @function decode
             * @memberof google.api.Http
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.api.Http} Http
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Http.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.Http();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            if (!(m.rules && m.rules.length))
                                m.rules = [];
                            m.rules.push($root.google.api.HttpRule.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Http message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.api.Http
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.api.Http} Http
             */
            Http.fromObject = function fromObject(d) {
                if (d instanceof $root.google.api.Http)
                    return d;
                var m = new $root.google.api.Http();
                if (d.rules) {
                    if (!Array.isArray(d.rules))
                        throw TypeError(".google.api.Http.rules: array expected");
                    m.rules = [];
                    for (var i = 0; i < d.rules.length; ++i) {
                        if (typeof d.rules[i] !== "object")
                            throw TypeError(".google.api.Http.rules: object expected");
                        m.rules[i] = $root.google.api.HttpRule.fromObject(d.rules[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Http message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.api.Http
             * @static
             * @param {google.api.Http} m Http
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Http.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.rules = [];
                }
                if (m.rules && m.rules.length) {
                    d.rules = [];
                    for (var j = 0; j < m.rules.length; ++j) {
                        d.rules[j] = $root.google.api.HttpRule.toObject(m.rules[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this Http to JSON.
             * @function toJSON
             * @memberof google.api.Http
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Http.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Http
             * @function getTypeUrl
             * @memberof google.api.Http
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Http.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.api.Http";
            };

            return Http;
        })();

        api.HttpRule = (function() {

            /**
             * Properties of a HttpRule.
             * @memberof google.api
             * @interface IHttpRule
             * @property {string|null} [get] HttpRule get
             * @property {string|null} [put] HttpRule put
             * @property {string|null} [post] HttpRule post
             * @property {string|null} ["delete"] HttpRule delete
             * @property {string|null} [patch] HttpRule patch
             * @property {google.api.ICustomHttpPattern|null} [custom] HttpRule custom
             * @property {string|null} [selector] HttpRule selector
             * @property {string|null} [body] HttpRule body
             * @property {Array.<google.api.IHttpRule>|null} [additionalBindings] HttpRule additionalBindings
             */

            /**
             * Constructs a new HttpRule.
             * @memberof google.api
             * @classdesc Represents a HttpRule.
             * @implements IHttpRule
             * @constructor
             * @param {google.api.IHttpRule=} [p] Properties to set
             */
            function HttpRule(p) {
                this.additionalBindings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * HttpRule get.
             * @member {string|null|undefined} get
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.get = null;

            /**
             * HttpRule put.
             * @member {string|null|undefined} put
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.put = null;

            /**
             * HttpRule post.
             * @member {string|null|undefined} post
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.post = null;

            /**
             * HttpRule delete.
             * @member {string|null|undefined} delete
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype["delete"] = null;

            /**
             * HttpRule patch.
             * @member {string|null|undefined} patch
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.patch = null;

            /**
             * HttpRule custom.
             * @member {google.api.ICustomHttpPattern|null|undefined} custom
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.custom = null;

            /**
             * HttpRule selector.
             * @member {string} selector
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.selector = "";

            /**
             * HttpRule body.
             * @member {string} body
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.body = "";

            /**
             * HttpRule additionalBindings.
             * @member {Array.<google.api.IHttpRule>} additionalBindings
             * @memberof google.api.HttpRule
             * @instance
             */
            HttpRule.prototype.additionalBindings = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * HttpRule pattern.
             * @member {"get"|"put"|"post"|"delete"|"patch"|"custom"|undefined} pattern
             * @memberof google.api.HttpRule
             * @instance
             */
            Object.defineProperty(HttpRule.prototype, "pattern", {
                get: $util.oneOfGetter($oneOfFields = ["get", "put", "post", "delete", "patch", "custom"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new HttpRule instance using the specified properties.
             * @function create
             * @memberof google.api.HttpRule
             * @static
             * @param {google.api.IHttpRule=} [properties] Properties to set
             * @returns {google.api.HttpRule} HttpRule instance
             */
            HttpRule.create = function create(properties) {
                return new HttpRule(properties);
            };

            /**
             * Encodes the specified HttpRule message. Does not implicitly {@link google.api.HttpRule.verify|verify} messages.
             * @function encode
             * @memberof google.api.HttpRule
             * @static
             * @param {google.api.IHttpRule} m HttpRule message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HttpRule.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.selector != null && Object.hasOwnProperty.call(m, "selector"))
                    w.uint32(10).string(m.selector);
                if (m.get != null && Object.hasOwnProperty.call(m, "get"))
                    w.uint32(18).string(m.get);
                if (m.put != null && Object.hasOwnProperty.call(m, "put"))
                    w.uint32(26).string(m.put);
                if (m.post != null && Object.hasOwnProperty.call(m, "post"))
                    w.uint32(34).string(m.post);
                if (m["delete"] != null && Object.hasOwnProperty.call(m, "delete"))
                    w.uint32(42).string(m["delete"]);
                if (m.patch != null && Object.hasOwnProperty.call(m, "patch"))
                    w.uint32(50).string(m.patch);
                if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                    w.uint32(58).string(m.body);
                if (m.custom != null && Object.hasOwnProperty.call(m, "custom"))
                    $root.google.api.CustomHttpPattern.encode(m.custom, w.uint32(66).fork()).ldelim();
                if (m.additionalBindings != null && m.additionalBindings.length) {
                    for (var i = 0; i < m.additionalBindings.length; ++i)
                        $root.google.api.HttpRule.encode(m.additionalBindings[i], w.uint32(90).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a HttpRule message from the specified reader or buffer.
             * @function decode
             * @memberof google.api.HttpRule
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.api.HttpRule} HttpRule
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HttpRule.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.HttpRule();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2: {
                            m.get = r.string();
                            break;
                        }
                    case 3: {
                            m.put = r.string();
                            break;
                        }
                    case 4: {
                            m.post = r.string();
                            break;
                        }
                    case 5: {
                            m["delete"] = r.string();
                            break;
                        }
                    case 6: {
                            m.patch = r.string();
                            break;
                        }
                    case 8: {
                            m.custom = $root.google.api.CustomHttpPattern.decode(r, r.uint32());
                            break;
                        }
                    case 1: {
                            m.selector = r.string();
                            break;
                        }
                    case 7: {
                            m.body = r.string();
                            break;
                        }
                    case 11: {
                            if (!(m.additionalBindings && m.additionalBindings.length))
                                m.additionalBindings = [];
                            m.additionalBindings.push($root.google.api.HttpRule.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a HttpRule message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.api.HttpRule
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.api.HttpRule} HttpRule
             */
            HttpRule.fromObject = function fromObject(d) {
                if (d instanceof $root.google.api.HttpRule)
                    return d;
                var m = new $root.google.api.HttpRule();
                if (d.get != null) {
                    m.get = String(d.get);
                }
                if (d.put != null) {
                    m.put = String(d.put);
                }
                if (d.post != null) {
                    m.post = String(d.post);
                }
                if (d["delete"] != null) {
                    m["delete"] = String(d["delete"]);
                }
                if (d.patch != null) {
                    m.patch = String(d.patch);
                }
                if (d.custom != null) {
                    if (typeof d.custom !== "object")
                        throw TypeError(".google.api.HttpRule.custom: object expected");
                    m.custom = $root.google.api.CustomHttpPattern.fromObject(d.custom);
                }
                if (d.selector != null) {
                    m.selector = String(d.selector);
                }
                if (d.body != null) {
                    m.body = String(d.body);
                }
                if (d.additionalBindings) {
                    if (!Array.isArray(d.additionalBindings))
                        throw TypeError(".google.api.HttpRule.additionalBindings: array expected");
                    m.additionalBindings = [];
                    for (var i = 0; i < d.additionalBindings.length; ++i) {
                        if (typeof d.additionalBindings[i] !== "object")
                            throw TypeError(".google.api.HttpRule.additionalBindings: object expected");
                        m.additionalBindings[i] = $root.google.api.HttpRule.fromObject(d.additionalBindings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a HttpRule message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.api.HttpRule
             * @static
             * @param {google.api.HttpRule} m HttpRule
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HttpRule.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.additionalBindings = [];
                }
                if (o.defaults) {
                    d.selector = "";
                    d.body = "";
                }
                if (m.selector != null && m.hasOwnProperty("selector")) {
                    d.selector = m.selector;
                }
                if (m.get != null && m.hasOwnProperty("get")) {
                    d.get = m.get;
                    if (o.oneofs)
                        d.pattern = "get";
                }
                if (m.put != null && m.hasOwnProperty("put")) {
                    d.put = m.put;
                    if (o.oneofs)
                        d.pattern = "put";
                }
                if (m.post != null && m.hasOwnProperty("post")) {
                    d.post = m.post;
                    if (o.oneofs)
                        d.pattern = "post";
                }
                if (m["delete"] != null && m.hasOwnProperty("delete")) {
                    d["delete"] = m["delete"];
                    if (o.oneofs)
                        d.pattern = "delete";
                }
                if (m.patch != null && m.hasOwnProperty("patch")) {
                    d.patch = m.patch;
                    if (o.oneofs)
                        d.pattern = "patch";
                }
                if (m.body != null && m.hasOwnProperty("body")) {
                    d.body = m.body;
                }
                if (m.custom != null && m.hasOwnProperty("custom")) {
                    d.custom = $root.google.api.CustomHttpPattern.toObject(m.custom, o);
                    if (o.oneofs)
                        d.pattern = "custom";
                }
                if (m.additionalBindings && m.additionalBindings.length) {
                    d.additionalBindings = [];
                    for (var j = 0; j < m.additionalBindings.length; ++j) {
                        d.additionalBindings[j] = $root.google.api.HttpRule.toObject(m.additionalBindings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this HttpRule to JSON.
             * @function toJSON
             * @memberof google.api.HttpRule
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HttpRule.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HttpRule
             * @function getTypeUrl
             * @memberof google.api.HttpRule
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HttpRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.api.HttpRule";
            };

            return HttpRule;
        })();

        api.CustomHttpPattern = (function() {

            /**
             * Properties of a CustomHttpPattern.
             * @memberof google.api
             * @interface ICustomHttpPattern
             * @property {string|null} [kind] CustomHttpPattern kind
             * @property {string|null} [path] CustomHttpPattern path
             */

            /**
             * Constructs a new CustomHttpPattern.
             * @memberof google.api
             * @classdesc Represents a CustomHttpPattern.
             * @implements ICustomHttpPattern
             * @constructor
             * @param {google.api.ICustomHttpPattern=} [p] Properties to set
             */
            function CustomHttpPattern(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CustomHttpPattern kind.
             * @member {string} kind
             * @memberof google.api.CustomHttpPattern
             * @instance
             */
            CustomHttpPattern.prototype.kind = "";

            /**
             * CustomHttpPattern path.
             * @member {string} path
             * @memberof google.api.CustomHttpPattern
             * @instance
             */
            CustomHttpPattern.prototype.path = "";

            /**
             * Creates a new CustomHttpPattern instance using the specified properties.
             * @function create
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {google.api.ICustomHttpPattern=} [properties] Properties to set
             * @returns {google.api.CustomHttpPattern} CustomHttpPattern instance
             */
            CustomHttpPattern.create = function create(properties) {
                return new CustomHttpPattern(properties);
            };

            /**
             * Encodes the specified CustomHttpPattern message. Does not implicitly {@link google.api.CustomHttpPattern.verify|verify} messages.
             * @function encode
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {google.api.ICustomHttpPattern} m CustomHttpPattern message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomHttpPattern.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.kind != null && Object.hasOwnProperty.call(m, "kind"))
                    w.uint32(10).string(m.kind);
                if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                    w.uint32(18).string(m.path);
                return w;
            };

            /**
             * Decodes a CustomHttpPattern message from the specified reader or buffer.
             * @function decode
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.api.CustomHttpPattern} CustomHttpPattern
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomHttpPattern.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.CustomHttpPattern();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.kind = r.string();
                            break;
                        }
                    case 2: {
                            m.path = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a CustomHttpPattern message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.api.CustomHttpPattern} CustomHttpPattern
             */
            CustomHttpPattern.fromObject = function fromObject(d) {
                if (d instanceof $root.google.api.CustomHttpPattern)
                    return d;
                var m = new $root.google.api.CustomHttpPattern();
                if (d.kind != null) {
                    m.kind = String(d.kind);
                }
                if (d.path != null) {
                    m.path = String(d.path);
                }
                return m;
            };

            /**
             * Creates a plain object from a CustomHttpPattern message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {google.api.CustomHttpPattern} m CustomHttpPattern
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomHttpPattern.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.kind = "";
                    d.path = "";
                }
                if (m.kind != null && m.hasOwnProperty("kind")) {
                    d.kind = m.kind;
                }
                if (m.path != null && m.hasOwnProperty("path")) {
                    d.path = m.path;
                }
                return d;
            };

            /**
             * Converts this CustomHttpPattern to JSON.
             * @function toJSON
             * @memberof google.api.CustomHttpPattern
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomHttpPattern.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CustomHttpPattern
             * @function getTypeUrl
             * @memberof google.api.CustomHttpPattern
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CustomHttpPattern.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.api.CustomHttpPattern";
            };

            return CustomHttpPattern;
        })();

        api.HttpBody = (function() {

            /**
             * Properties of a HttpBody.
             * @memberof google.api
             * @interface IHttpBody
             * @property {string|null} [contentType] HttpBody contentType
             * @property {Uint8Array|null} [data] HttpBody data
             * @property {Array.<google.protobuf.IAny>|null} [extensions] HttpBody extensions
             */

            /**
             * Constructs a new HttpBody.
             * @memberof google.api
             * @classdesc Represents a HttpBody.
             * @implements IHttpBody
             * @constructor
             * @param {google.api.IHttpBody=} [p] Properties to set
             */
            function HttpBody(p) {
                this.extensions = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * HttpBody contentType.
             * @member {string} contentType
             * @memberof google.api.HttpBody
             * @instance
             */
            HttpBody.prototype.contentType = "";

            /**
             * HttpBody data.
             * @member {Uint8Array} data
             * @memberof google.api.HttpBody
             * @instance
             */
            HttpBody.prototype.data = $util.newBuffer([]);

            /**
             * HttpBody extensions.
             * @member {Array.<google.protobuf.IAny>} extensions
             * @memberof google.api.HttpBody
             * @instance
             */
            HttpBody.prototype.extensions = $util.emptyArray;

            /**
             * Creates a new HttpBody instance using the specified properties.
             * @function create
             * @memberof google.api.HttpBody
             * @static
             * @param {google.api.IHttpBody=} [properties] Properties to set
             * @returns {google.api.HttpBody} HttpBody instance
             */
            HttpBody.create = function create(properties) {
                return new HttpBody(properties);
            };

            /**
             * Encodes the specified HttpBody message. Does not implicitly {@link google.api.HttpBody.verify|verify} messages.
             * @function encode
             * @memberof google.api.HttpBody
             * @static
             * @param {google.api.IHttpBody} m HttpBody message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HttpBody.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.contentType != null && Object.hasOwnProperty.call(m, "contentType"))
                    w.uint32(10).string(m.contentType);
                if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                    w.uint32(18).bytes(m.data);
                if (m.extensions != null && m.extensions.length) {
                    for (var i = 0; i < m.extensions.length; ++i)
                        $root.google.protobuf.Any.encode(m.extensions[i], w.uint32(26).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a HttpBody message from the specified reader or buffer.
             * @function decode
             * @memberof google.api.HttpBody
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.api.HttpBody} HttpBody
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HttpBody.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.HttpBody();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.contentType = r.string();
                            break;
                        }
                    case 2: {
                            m.data = r.bytes();
                            break;
                        }
                    case 3: {
                            if (!(m.extensions && m.extensions.length))
                                m.extensions = [];
                            m.extensions.push($root.google.protobuf.Any.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a HttpBody message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.api.HttpBody
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.api.HttpBody} HttpBody
             */
            HttpBody.fromObject = function fromObject(d) {
                if (d instanceof $root.google.api.HttpBody)
                    return d;
                var m = new $root.google.api.HttpBody();
                if (d.contentType != null) {
                    m.contentType = String(d.contentType);
                }
                if (d.data != null) {
                    if (typeof d.data === "string")
                        $util.base64.decode(d.data, m.data = $util.newBuffer($util.base64.length(d.data)), 0);
                    else if (d.data.length >= 0)
                        m.data = d.data;
                }
                if (d.extensions) {
                    if (!Array.isArray(d.extensions))
                        throw TypeError(".google.api.HttpBody.extensions: array expected");
                    m.extensions = [];
                    for (var i = 0; i < d.extensions.length; ++i) {
                        if (typeof d.extensions[i] !== "object")
                            throw TypeError(".google.api.HttpBody.extensions: object expected");
                        m.extensions[i] = $root.google.protobuf.Any.fromObject(d.extensions[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a HttpBody message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.api.HttpBody
             * @static
             * @param {google.api.HttpBody} m HttpBody
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HttpBody.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.extensions = [];
                }
                if (o.defaults) {
                    d.contentType = "";
                    if (o.bytes === String)
                        d.data = "";
                    else {
                        d.data = [];
                        if (o.bytes !== Array)
                            d.data = $util.newBuffer(d.data);
                    }
                }
                if (m.contentType != null && m.hasOwnProperty("contentType")) {
                    d.contentType = m.contentType;
                }
                if (m.data != null && m.hasOwnProperty("data")) {
                    d.data = o.bytes === String ? $util.base64.encode(m.data, 0, m.data.length) : o.bytes === Array ? Array.prototype.slice.call(m.data) : m.data;
                }
                if (m.extensions && m.extensions.length) {
                    d.extensions = [];
                    for (var j = 0; j < m.extensions.length; ++j) {
                        d.extensions[j] = $root.google.protobuf.Any.toObject(m.extensions[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this HttpBody to JSON.
             * @function toJSON
             * @memberof google.api.HttpBody
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HttpBody.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HttpBody
             * @function getTypeUrl
             * @memberof google.api.HttpBody
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HttpBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.api.HttpBody";
            };

            return HttpBody;
        })();

        return api;
    })();

    google.type = (function() {

        /**
         * Namespace type.
         * @memberof google
         * @namespace
         */
        var type = {};

        type.Interval = (function() {

            /**
             * Properties of an Interval.
             * @memberof google.type
             * @interface IInterval
             * @property {google.protobuf.ITimestamp|null} [startTime] Interval startTime
             * @property {google.protobuf.ITimestamp|null} [endTime] Interval endTime
             */

            /**
             * Constructs a new Interval.
             * @memberof google.type
             * @classdesc Represents an Interval.
             * @implements IInterval
             * @constructor
             * @param {google.type.IInterval=} [p] Properties to set
             */
            function Interval(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Interval startTime.
             * @member {google.protobuf.ITimestamp|null|undefined} startTime
             * @memberof google.type.Interval
             * @instance
             */
            Interval.prototype.startTime = null;

            /**
             * Interval endTime.
             * @member {google.protobuf.ITimestamp|null|undefined} endTime
             * @memberof google.type.Interval
             * @instance
             */
            Interval.prototype.endTime = null;

            /**
             * Creates a new Interval instance using the specified properties.
             * @function create
             * @memberof google.type.Interval
             * @static
             * @param {google.type.IInterval=} [properties] Properties to set
             * @returns {google.type.Interval} Interval instance
             */
            Interval.create = function create(properties) {
                return new Interval(properties);
            };

            /**
             * Encodes the specified Interval message. Does not implicitly {@link google.type.Interval.verify|verify} messages.
             * @function encode
             * @memberof google.type.Interval
             * @static
             * @param {google.type.IInterval} m Interval message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Interval.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                    $root.google.protobuf.Timestamp.encode(m.startTime, w.uint32(10).fork()).ldelim();
                if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                    $root.google.protobuf.Timestamp.encode(m.endTime, w.uint32(18).fork()).ldelim();
                return w;
            };

            /**
             * Decodes an Interval message from the specified reader or buffer.
             * @function decode
             * @memberof google.type.Interval
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.type.Interval} Interval
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Interval.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.type.Interval();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.startTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    case 2: {
                            m.endTime = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Interval message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.type.Interval
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.type.Interval} Interval
             */
            Interval.fromObject = function fromObject(d) {
                if (d instanceof $root.google.type.Interval)
                    return d;
                var m = new $root.google.type.Interval();
                if (d.startTime != null) {
                    if (typeof d.startTime !== "object")
                        throw TypeError(".google.type.Interval.startTime: object expected");
                    m.startTime = $root.google.protobuf.Timestamp.fromObject(d.startTime);
                }
                if (d.endTime != null) {
                    if (typeof d.endTime !== "object")
                        throw TypeError(".google.type.Interval.endTime: object expected");
                    m.endTime = $root.google.protobuf.Timestamp.fromObject(d.endTime);
                }
                return m;
            };

            /**
             * Creates a plain object from an Interval message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.type.Interval
             * @static
             * @param {google.type.Interval} m Interval
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Interval.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.startTime = null;
                    d.endTime = null;
                }
                if (m.startTime != null && m.hasOwnProperty("startTime")) {
                    d.startTime = $root.google.protobuf.Timestamp.toObject(m.startTime, o);
                }
                if (m.endTime != null && m.hasOwnProperty("endTime")) {
                    d.endTime = $root.google.protobuf.Timestamp.toObject(m.endTime, o);
                }
                return d;
            };

            /**
             * Converts this Interval to JSON.
             * @function toJSON
             * @memberof google.type.Interval
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Interval.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Interval
             * @function getTypeUrl
             * @memberof google.type.Interval
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Interval.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.type.Interval";
            };

            return Interval;
        })();

        return type;
    })();

    return google;
})();

module.exports = $root;
