import React from 'react';
import { MeshBackground, RadialFade, Row, StreemStackedLogoLegal } from '@streem/ui-react';
import ForgotPasswordForm from '../../forms/forgot_password_form';
import { LoginWrapper } from './login_page';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';

export const ForgotPasswordPage: React.FC = () => {
    const companyCode = useActiveCompanyCode();
    return (
        <MeshBackground>
            <LoginWrapper>
                <Row alignItems="center" justifyContent="center">
                    <RadialFade>
                        <StreemStackedLogoLegal color="white" width="400px" height="300px" />
                    </RadialFade>
                </Row>
                <ForgotPasswordForm companyCode={companyCode} />
            </LoginWrapper>
        </MeshBackground>
    );
};
