import { useEffect, createContext, useContext } from 'react';
import config from '../config';
import { AuthStore } from '../stores/auth_store';
import { CompanySettingsStore } from '../stores/company_settings_store';
import { UserStore } from '../stores/user_store';
import { ConfigStore } from '../stores/config_store';
import { UIStore } from '../stores/ui_store';
import { SDKStore } from '../stores/sdk_store';

export interface GlobalContext {
    authStore: AuthStore;
    configStore: ConfigStore;
    userStore: UserStore;
    uiStore: UIStore;
    sdkStore: SDKStore;
    companySettingsStore: CompanySettingsStore;
}

const globalContext = createContext<GlobalContext | undefined>(undefined);

export function useInitializeGlobalStores(): GlobalContext {
    const authStore = new AuthStore();
    const configStore = new ConfigStore();
    const userStore = new UserStore(authStore);
    const sdkStore = new SDKStore();
    const companySettingsStore = new CompanySettingsStore(config.companyCode);

    let uiStore: UIStore;
    if (window.navigator.cookieEnabled) {
        // If we attempt to instantiate the UIStore without access to local or session storage nothing will render.
        uiStore = new UIStore();
    }

    useEffect(() => {
        authStore.connect();
        configStore.connect();
        userStore.connect();
        return () => {
            authStore.disconnect();
            userStore.disconnect();
        };
    }, [authStore, userStore, configStore, sdkStore]);

    return {
        authStore,
        configStore,
        userStore,
        uiStore,
        sdkStore,
        companySettingsStore,
    };
}

export function useGlobalStore(): GlobalContext {
    const context = useContext(globalContext);

    if (!context) {
        throw new Error('Unable to useGlobalStore without parent <GlobalStoreProvider />');
    }

    return context;
}

export const GlobalStoreProvider = globalContext.Provider;
