import { AppIcon, Box, Column, Header, ListRowFeaturedCell, styled } from '@streem/ui-react';
import { SettingField } from '../../components/companies/settings/setting_field';
import { Setting } from '../../util/company_settings';
import { SettingRow } from '../../components/companies/settings/settings_page.styles';

interface Props {
    settings: Setting[];
    title: string;
}

export const SettingsPanel: React.FC<Props> = ({ settings, title }) => {
    return (
        <SettingsContainer>
            <Header size="large">{title}</Header>
            <Divider />
            {settings.map(setting => {
                return (
                    <SettingRow
                        key={setting.name}
                        data-testid={`${setting.name.toLowerCase()}-row`}
                        customPadding={'16px 0'}
                    >
                        {setting.superAdmin && (
                            <Column marginRight={'5px'} flexShrink={0}>
                                <AppIcon name="SuperAdminIcon" />
                            </Column>
                        )}
                        <ListRowFeaturedCell
                            headline={setting.label}
                            subHeadline={setting.description}
                            marginRight="auto"
                            paddingRight="16px"
                            testid={setting.testId}
                            helpLink={setting.helpLinkHref}
                            helpLinkDataTestId={setting.helpLinkDataTestId}
                        />
                        <SettingField setting={setting} minWidth="40px" />
                    </SettingRow>
                );
            })}
        </SettingsContainer>
    );
};

const SettingsContainer = styled.div`
    padding: 16px;
    font-family: ASAP, sans-serif;
`;

const Divider = styled(Box)(({ theme }) => ({
    marginTop: theme.space[3],
    marginBottom: theme.space[3],
    width: '100%',
    background: theme.colors.grey30,
    height: '1px',
}));
