import { APITypes, StreemAPI } from '@streem/api';
import StreemSDK, { DetailSession, UserInfo } from '@streem/sdk-core';
import { useObservable } from '@streem/sdk-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { getCallDurationStringFromRoom } from '../util/call_duration_helpers';
import { useGlobalStore } from './use_global_context';
import appLogger from '../util/logging/app_logger';
import { contextValues } from '@streem/logger';

const detailSessionContext = createContext<DetailSession | undefined>(undefined);

export function useInitializeDetailSession(roomSid: string, shareToken?: string) {
    const { sdkStore } = useGlobalStore();
    const [detailSession, setDetailSession] = useState<DetailSession>();

    useEffect(() => {
        let session: DetailSession | undefined;

        const connect = async () => {
            session = shareToken
                ? await StreemSDK.startAnonymousDetailSession(roomSid, shareToken)
                : await sdkStore.userSession.current?.startDetailSession(roomSid);
            setDetailSession(session);
        };
        connect();

        return () => {
            setDetailSession(undefined);
            session?.endSession();
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [roomSid, sdkStore.userSession.current]);

    return detailSession;
}

export function useDetailSession() {
    const context = useContext(detailSessionContext);

    if (!context) {
        throw new Error('Unable to useDetailSession without parent <DetailSessionProvider />');
    }

    return context;
}

export const DetailSessionProvider = detailSessionContext.Provider;

export function useCustomerExpertUserInfo(detailSession: DetailSession): {
    customer?: UserInfo;
    expert?: UserInfo;
} {
    const [invitations = []] = useObservable(detailSession.invitations.invitations);
    const [onsite = false] = useObservable(detailSession.room.onsite);
    const [users = []] = useObservable(detailSession.users.users);

    if (onsite) {
        const onsiteUser = users[0];
        return {
            customer: onsiteUser,
            expert: onsiteUser,
        };
    }

    const { fromUserSid, toUserSid } = invitations[0] ?? {};
    return {
        customer: users.find(user => user.id === fromUserSid),
        expert: users.find(user => user.id === toUserSid),
    };
}

export function useRoomDuration(roomSid: string | undefined): string {
    const [roomDetails, setRoomDetails] = useState<APITypes.StreemApiRoom | undefined>(undefined);

    useEffect(() => {
        const getRoom = async () => {
            try {
                const room = await StreemAPI.rooms.getRoom(roomSid);
                setRoomDetails(room.room);
            } catch (e) {
                appLogger.error(
                    `Could not find room (call) duration due to error fetching room details for ${contextValues.ROOM_ID}=${roomSid}`,
                    {
                        errorMessage: (e as Error).message,
                        stack: (e as Error).stack,
                    },
                );
            }
        };
        if (!roomSid) {
            setRoomDetails(undefined);
        } else {
            getRoom();
        }
    }, [roomSid]);

    return roomDetails ? getCallDurationStringFromRoom(roomDetails) : '';
}

export function useInviteType(detailSession: DetailSession) {
    const [onsite = false] = useObservable(detailSession.room.onsite);
    const [users = []] = useObservable(detailSession.users.users);

    if (onsite) return 'onsite';

    const hasPhone = users.some(user => !!user.phone);
    if (hasPhone) return 'phone';

    return 'link';
}
