import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalStore } from './use_global_context';

/* Determine whether Embed has been enabled based on the company settings configuration
 * and if it is an Embed view based on the path
 */
export const useGetEmbedEnabledSettings = () => {
    const [isEmbedEnabled, setIsEmbedEnabled] = useState(false);
    const [isEmbedView, setIsEmbedView] = useState(false);
    const [areCompanySettingsLoading, setAreCompanySettingsLoading] = useState(true);
    const { companySettingsStore } = useGlobalStore();
    const location = useLocation();

    useLayoutEffect(() => {
        if (!companySettingsStore.loading) {
            setIsEmbedEnabled(companySettingsStore.crmEmbedEnabled);
            setAreCompanySettingsLoading(false);
        }
        setIsEmbedView(
            location.pathname.includes('/embed') || location.pathname.includes('/streems'),
        );
    }, [companySettingsStore.loading, companySettingsStore.crmEmbedEnabled, location.pathname]);

    return { isEmbedEnabled, isEmbedView, areCompanySettingsLoading };
};
