import React from 'react';
import { Button, Spinner } from '@streem/ui-react';


interface Props {
    loadingMore: boolean;
    onClick: () => void;
    sourceTestId?: string;
}

export const LoadMoreButton: React.FC<Props> = ({loadingMore, onClick, sourceTestId}) => {
    if (loadingMore) {
        return <Spinner size={'30px'} />;
    }
    return <Button variant='tertiary' data-testid={`${sourceTestId}-load-more`} onClick={onClick}>
        Load More
    </Button>;
};
