import { Role, RoleOption, Scopes } from '../types/project.types';

/**
 * User role UI configurations
 */
export const userRoleOptions: RoleOption[] = [
    {
        groupName: 'AGENT',
        rank: 0,
        label: 'Agent',
        value: 'AGENT',
        description: 'Agents can initiate Streem calls with customers and view their own call log.',
    },
    {
        groupName: 'COMPANY_ADMIN',
        rank: 1,
        label: 'Admin',
        value: 'COMPANY_ADMIN',
        description: 'Admins can manage users and settings for their company.',
    },
    {
        groupName: 'COMPANY_OWNER',
        rank: 2,
        label: 'Owner',
        value: 'COMPANY_OWNER',
        description: 'Owners can manage users and settings for their company.',
    },
];

// Group name to option object
const optionsByGroupName = userRoleOptions.reduce((res, option) => {
    res[option.groupName] = option;
    return res;
}, {} as { [k in string]: RoleOption });

/**
 * Returns the highest ranking group of the user
 * @param userGroups
 */
export const getHighestUserGroup = (userGroups: string[]): RoleOption =>
    userRoleOptions
        .filter(roleOption => userGroups.includes(roleOption.groupName))
        .reduce((prev, current) => (prev.rank > current.rank ? prev : current), userRoleOptions[0]);

/**
 * Given a list of groups, and a desired highest group, filter out any groups that have a rank strictly greater than
 * the highest group's rank.  If the highestGroup is unknown, than a rank of -1 is used, and all known groups will be
 * removed, while other unknown groups will not be removed.
 * @param groups - The list of groups to filter
 * @param highestGroup - The highest group to keep
 */
export const filterOutHigherGroups = (groups: string[], highestGroup: string) => {
    const highestRank = optionsByGroupName[highestGroup]?.rank ?? -1;
    return groups.filter(group => (optionsByGroupName[group]?.rank ?? -1) <= highestRank);
};

/**
 * The server can return scopes such as "streem:user streem:company-admin",
 * We need to convert this scope into the highest level Role
 * @param scope the types of privileges the customer has as defined by the server
 */
export const convertServerRoleToSwagaRole = (scope: string): Role => {
    if (!scope.length) {
        return 'ANONYMOUS';
    }

    const scopes: Scopes[] = scope.split(' ') as Scopes[];

    if (scopes.includes(Scopes.SUPER_ADMIN)) {
        return 'SUPER_ADMIN';
    }

    if (scopes.includes(Scopes.COMPANY_OWNER) || scopes.includes(Scopes.COMPANY_ADMIN)) {
        return 'COMPANY_ADMIN';
    }

    if (scopes.includes(Scopes.SUPPORT_READONLY)) {
        return 'SUPPORT_READONLY';
    }

    if (scopes.includes(Scopes.USER)) {
        return 'AGENT';
    }

    return 'ANONYMOUS';
};
