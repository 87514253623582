import React from 'react';
import { Button, ButtonRow, Header, styled } from '@streem/ui-react';
import { BottomRow, MiddleRow } from '../../forms/shared_form.styles';

interface Props {
    onClose: () => void;
    onConfirm: () => void;
    currentStatus: boolean | 'error';
    isUpdating: boolean;
}

const BodyText = styled.p`
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4;
    max-width: 500px;
`;

const UserStatus: React.FC<Props> = ({ onConfirm, currentStatus, onClose, isUpdating }) => {
    let header;
    let body;
    let action;

    if (currentStatus === true) {
        header = 'Activate Team Member?';
        body = `If activated, this team member will be able to log in and access their Streem calls.`;
        action = 'Activate';
    }
    if (currentStatus === false) {
        header = 'Deactivate Team Member?';
        body = `If deactivated, this team member will no longer be able to log in or access any of their Streem calls.`;
        action = 'Deactivate';
    }
    if (currentStatus === 'error') {
        header = 'Error';
        body = `Something went wrong. This team member's status was not updated.`;
        action = 'Close';
    }

    const isError = currentStatus === 'error';

    return (
        <>
            <Header size="xlarge">{header}</Header>
            <MiddleRow style={{ marginTop: '48px' }}>
                <BodyText>{body}</BodyText>
            </MiddleRow>
            <BottomRow>
                <ButtonRow>
                    {!isError && (
                        <Button
                            variant="secondary"
                            data-testid="close-modal-cancel-button"
                            onClick={onClose}
                            disabled={isUpdating}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        data-testid="close-modal-confirm-button"
                        onClick={isError ? onClose : onConfirm}
                        disabled={isUpdating}
                        loading={isUpdating}
                    >
                        {action}
                    </Button>
                </ButtonRow>
            </BottomRow>
        </>
    );
};

export default UserStatus;
