import StreemAuth, {
    StreemAuthError,
    LoginIdentityUser,
    AnyUser,
    isLoginIdentityUser,
} from '@streem/auth';
import { observable, action, computed, runInAction } from 'mobx';
import { addUserToDatadogRumSession } from '../util/datadog';
import { invariant } from '@streem/toolbox';
import appLogger from '../util/logging/app_logger';
import config from '../config';

export class CentralLoginAuthStore {
    private unsubscriber?: () => void;

    // To clear up confusion between authStore and userStore, we only publicly
    // expose properties of user which the authStore should be responsible for.
    @observable
    private user: LoginIdentityUser | undefined;

    // The initialized property indicates whether we've received the initial
    // response from @streem/auth which indicates our initial auth state.
    @observable
    public initialized = false;

    // For async OAuth flows it's possible for errors to be returned from the
    // OAuth provider, in which case they enter via the setAuthUser callback.
    @observable
    public error?: StreemAuthError;

    @computed
    public get isUserSignedIn(): boolean {
        return this.user !== undefined;
    }

    @computed
    public get userName(): string | undefined {
        return this.user?.name;
    }

    @computed
    public get userEmail(): string | undefined {
        return this.user?.email;
    }

    @computed
    public get userAvatarUrl(): string | undefined {
        return this.user?.avatarUrl;
    }

    @computed
    public get userId(): string | undefined {
        return this.user?.id;
    }

    public async loginWithOauthRedirect() {
        await StreemAuth.loginWithOauthRedirect(config.centralLoginAppName);
    }

    public async logout(): Promise<void> {
        // ST-2685: From workspaces, there is no Region in context to logout from
        // try {
        //     appLogger.info('Logging out the user via API');
        //     await StreemAPI.auth.authLogout();
        // } catch (e) {
        //     appLogger.error('Error logging out the user via API', e);
        // }
        await StreemAuth.logout(config.centralLoginAppName);
    }

    /**
     * disconnect invokes the callback on StreemAuth's onAuthStateChanged
     */
    public disconnect(): void {
        if (this.unsubscriber) {
            this.unsubscriber();
        }
    }

    /**
     * connect registers a callback to StreemAuth's onAuthStateChanged
     */
    public connect(): void {
        this.unsubscriber = StreemAuth.onAuthStateChanged(this.setLoginIdentityUser);
    }

    @action.bound
    private async setLoginIdentityUser(user: AnyUser | undefined, error?: StreemAuthError) {
        this.user = undefined;
        this.error = error;
        this.initialized = true;

        // Skip further code paths if no user is defined.
        if (!user) {
            return;
        }

        try {
            invariant(isLoginIdentityUser(user), 'Expected type of LoginIdentityUser');
            runInAction(() => {
                this.user = user;
                addUserToDatadogRumSession(user);
            });
        } catch (error) {
            appLogger.error('Error in CentralLoginAuthStore.setLoginIdentityUser', error);
            runInAction(() => {
                this.error = new Error('Unable to log you in.');
            });
        }
    }
}
