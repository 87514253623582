import { StreemAPI } from '@streem/api';

import log from '../util/logging/app_logger';
import { useGlobalStore } from './use_global_context';

export function useSurveys(): (userId: string, roomId: string) => Promise<void> {
    const { companySettingsStore, uiStore } = useGlobalStore();
    const shouldCompleteCompanySurvey = !!companySettingsStore.companySurveyUrl;

    async function displaySurveys(userId: string, roomId: string) {
        const shouldCompleteStreemSurvey = await getStreemSurveyStatus(userId);
        if (shouldCompleteStreemSurvey && shouldCompleteCompanySurvey) {
            uiStore.setMultiSurveyPrompt(true);
        }

        if (shouldCompleteStreemSurvey) {
            uiStore.setStreemSurveyUrl(companySettingsStore.streemSurveyUrl, roomId);
        }

        if (shouldCompleteCompanySurvey) {
            uiStore.setCompanySurveyUrl(companySettingsStore.companySurveyUrl, roomId);
        }
    }

    async function getStreemSurveyStatus(userId: string) {
        try {
            const response = await StreemAPI.users.getStreemSurveyStatus(userId);
            return response.status?.shouldViewSurvey;
        } catch (e) {
            log.warn(`Failed to get Streem survey status for user ${userId}: `, e);
            return false;
        }
    }

    return displaySurveys;
}
