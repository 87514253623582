import { AppText, styled } from '@streem/ui-react';

interface Props {
    monochrome?: boolean;
}

export const StringSettingLabel = styled(AppText.withComponent('p'))<Props>(({ theme, monochrome }) => ({
    color: monochrome ? theme.colors.phosphorGreen : undefined,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    flex: 1,
    minWidth: 0,
    userSelect: 'all',
    lineHeight: '1.4rem',
}));
