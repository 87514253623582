import { StreemApiCallLogEntry } from '@streem/api/src/openapi';

export const processCallLogDetails = (callLogEntries: StreemApiCallLogEntry[]) => {
    const isEmbed =
        window.location.pathname.includes('/embed') ||
        window.location.pathname.includes('/streems');

    return callLogEntries
        .filter(callEntry => !callEntry.missed)
        .map(callLogEntry => {
            return {
                ...callLogEntry,
                callDetailsUrl: !isEmbed ? `/teamcalls/${callLogEntry.roomSid}` : null,
                urlHostname: !isEmbed ? window.location.hostname : null,
            };
        });
};
