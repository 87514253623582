import { WallItem } from 'streem-sdk-protobuf';
import config from '../config';

/**
 * Opens an href string in a new window
 * @param href
 */
export function openLinkInNewTab(href: string): void {
    window.open(href, '_blank', 'noopener,noreferrer');
}

/**
 * Given a latitude and longitude, provides a link to the location in google maps
 * @param gpsPosition
 */
export function gpsPosToGoogleMaps(gpsPosition: WallItem.IGPSPosition): string {
    return `https://www.google.com/maps/search/${gpsPosition?.latitude},${gpsPosition?.longitude}`;
}

/**
 * Takes at least one argument (strings) and combines them into a path with `/` as a separator
 * Prevents accidental '//'
 * @param required - a required first path string
 * @param args - any number of additional strings, which will be attached to the first string
 */
export function joinPaths(required: string, ...args: string[]): string {
    const separator = '/';
    const replaceRegex = new RegExp('/{1,}', 'g');
    return [required, ...args].join(separator).replace(replaceRegex, separator);
}

/**
 * Removes a number of segments from the pathname of a url
 * For example, removeSegments("/one/two", 1) will return "/one"
 * Cleanses trailing slashes, so removeSegments("/one/two/", 1) will still return return "/one"
 * @param pathname
 * @param numberOfPathsToRemove
 */
export function removeSegments(pathname: string, numberOfPathsToRemove: number): string {
    const segments = explodePathnameString(pathname);
    for (let i = 0; i < numberOfPathsToRemove; i++) {
        segments.pop();
    }
    return implodePathnameSegments(segments);
}

/**
 * Turn a pathname string into an array of segments
 * Also removes any trailing slash
 * @param pathname
 */
function explodePathnameString(pathname: string) {
    const segments = pathname.split('/');
    if (segments[segments.length - 1] === '') {
        segments.pop();
    }
    return segments;
}

/**
 * Turn an array of path segments back into pathname
 * Insures the pathname starts with a "/"
 * @param segments
 */
function implodePathnameSegments(segments: string[]) {
    if (segments[0] !== '') {
        segments.unshift('');
    }
    return segments.join('/');
}

/**
 * Generates an EV2 url for a given company code
 * @param companyCode
 */
export function ev2UrlFromCompanyCode(companyCode: string): string {
    return window.location.origin.replace(config.centralLoginAppName, companyCode);
}

/**
 * Returns the url for central login
 */
export function centralLoginUrl(): string {
    return window.location.origin.includes('localhost')
        ? /* Regex replaces everything up to and including 'localhost' */
          window.location.origin.replace(
              /^(.*?)localhost/,
              `https://${config.centralLoginAppName}.localhost`,
          )
        : /* Regex replaces the left most subdomain with the string 'workspaces' */
          'https://' + window.location.origin.replace(/^[^.]*/, config.centralLoginAppName);
}

/**
 * Removes the protocol from a URL, intended for cleaner display of URLs in the UI
 */
export function removeProtocol(url: string): string {
    /* Regex removes everything up to and including the first '//' */
    return url.replace(/(^\w+:|^)\/\//, '');
}

/**
 *  Converts a sessionStorage object to a string of query parameters for a path
 *  @param stringifiedSessionStorageObject - a sessionStorage object that has been converted with JSON.stringify()
 */
export function convertSessionStorageObjectToUrlQueryParamsString(
    stringifiedSessionStorageObject: string,
): string {
    if (stringifiedSessionStorageObject === '') return '';
    const parsedSessionStorageParams = JSON.parse(stringifiedSessionStorageObject);
    let pathParams = '?';
    for (const [key, value] of Object.entries(parsedSessionStorageParams)) {
        if (value) {
            pathParams = pathParams.concat(`${key}=${value}&`);
        }
    }
    return pathParams.slice(0, -1);
}
