import { Row, CopyButton, useTheme, styled, Label, Column, Sensor } from '@streem/ui-react';
import { useState } from 'react';

interface Props {
    value?: string;
}

export const InviteCodeField = ({ value }: Props) => {
    const [highlight, setHighlight] = useState(false);
    const theme = useTheme();
    const value1 = value.slice(0, 3);
    const value2 = value.slice(3, 6);
    const value3 = value.slice(6, 9);
    const highlightOn = () => setHighlight(true);
    const highlightOff = () => setHighlight(false);

    return (
        <Column>
            <Label headingFontFamily size="medium">
                Invite Code
            </Label>
            <Sensor onClickOutside={highlightOff} onEscape={highlightOff}>
                <Row
                    onFocus={highlightOn}
                    onBlur={highlightOff}
                    onClick={highlightOn}
                    tabIndex={0}
                    style={{
                        justifyContent: 'space-between',
                        height: '40px',
                        borderRadius: '2px',
                        border: 'solid 1px',
                        borderColor: theme.colors.grey90,
                        marginTop: '8px',
                        boxShadow: highlight ? '0px 0px 1px 3px #76c1ff' : '',
                    }}
                >
                    <DashRow data-testid="invite-success-invite-code">
                        <Dash data-testid="invite-success-invite-code-fragment">{value1}</Dash>
                        <Dash>{value2}</Dash>
                        <span>{value3}</span>
                    </DashRow>
                    <CopyButton
                        data-testid="invite-success-invite-code-copy-button"
                        label="Invitation Code"
                        value={value}
                    />
                </Row>
            </Sensor>
        </Column>
    );
};

const Dash = styled.span(() => ({
    ':after': {
        content: '" - "',
    },
    whiteSpace: 'pre',
}));

const DashRow = styled(Row)(props => ({
    fontFamily: props.theme.text.bodyFontFamily,
    alignItems: 'center',
    paddingLeft: '8px',
    width: '100%',
    ':active': {
        userSelect: 'all',
    },
    cursor: 'default',
}));
