// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../tools/webpack/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../tools/webpack/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * CropperJS defaults updated to show dashed circle crop mask & white color borders
 */

.cropper-view-box {
    outline: rgba(255, 255, 255, 0.75) dashed 1px;
    overflow: hidden;
    border-radius: 50%;
}

.cropper-view-box::after {
    content: '';
    border: 1px dashed #eee;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.cropper-center::before,
.cropper-center::after {
    background-color: #eee;
}

.cropper-face {
    background-color: transparent;
    left: 0;
    top: 0;
}

.cropper-line {
    background-color: #eee;
}

.cropper-point {
    background-color: #eee;
}
`, "",{"version":3,"sources":["webpack://./src/components/avatar_cropper/avatar_cropper.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;IACI,6CAA6C;IAC7C,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;IAC7B,OAAO;IACP,MAAM;AACV;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/*\n * CropperJS defaults updated to show dashed circle crop mask & white color borders\n */\n\n.cropper-view-box {\n    outline: rgba(255, 255, 255, 0.75) dashed 1px;\n    overflow: hidden;\n    border-radius: 50%;\n}\n\n.cropper-view-box::after {\n    content: '';\n    border: 1px dashed #eee;\n    height: 100%;\n    width: 100%;\n    border-radius: 50%;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.cropper-center::before,\n.cropper-center::after {\n    background-color: #eee;\n}\n\n.cropper-face {\n    background-color: transparent;\n    left: 0;\n    top: 0;\n}\n\n.cropper-line {\n    background-color: #eee;\n}\n\n.cropper-point {\n    background-color: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
