import { Row, ExtractProps } from '@streem/ui-react';
import { BooleanSettingField } from './boolean_setting_field';
import { StringSettingField } from './string_setting_field';
import { UrlSettingField } from './url_setting_field';
import { UploadSettingField } from './upload_field';
import { Setting } from '../../../util/company_settings';
import { DataRetentionPolicyField } from './data_retention_policy_field';

interface Props extends ExtractProps<typeof Row> {
    setting: Setting;
    monochrome?: boolean;
}

export function SettingField({ setting, monochrome, ...rowProps }: Props) {
    let content;
    if (setting.fieldType === 'URL') {
        content = <UrlSettingField setting={setting} monochrome={monochrome} />;
    } else if (setting.type === 'TYPE_BOOLEAN') {
        content = <BooleanSettingField setting={setting} monochrome={monochrome} />;
        if (setting.name === 'ROOM_OUTCOME_FEATURE_ENABLED' && setting.value === 'true') {
            content = <UploadSettingField setting={setting} />;
        }
    } else if (setting.name === 'ROOM_TTL_DAYS') {
        content = <DataRetentionPolicyField value={Number(setting.value)} />;
    } else {
        content = <StringSettingField setting={setting} monochrome={monochrome} />;
    }

    let width;
    let height;
    if (setting.type === 'TYPE_STRING') {
        width = '40%';
    } else if (setting.name === 'ROOM_OUTCOME_FEATURE_ENABLED') {
        width = 'auto';
        height = '60px';
    } else if (setting.type === 'TYPE_INTEGER') {
        width = 'auto';
    } else {
        width = '40px';
    }

    return (
        <Row
            alignItems="center"
            justifyContent="flex-end"
            minHeight="40px"
            alignSelf="flex-end"
            width={width}
            height={height || rowProps.height}
            {...rowProps}
        >
            {content}
        </Row>
    );
}
