import { useEffect, useState } from 'react';
import { AuthStore } from '../stores/auth_store';

// This hook requires authStore to be passed in because we use it in app.tsx and is therefore not wrapped
// in the GlobalStoreProvider and can't be accessed via useGlobalStore.
export function useAnonymousShareChecker(authStore: AuthStore): boolean {
    const [isAnonymousShare, setIsAnonymousShare] = useState(false);

    useEffect(() => {
        if (authStore.initialized) {
            setIsAnonymousShare(
                !authStore.isUserSignedIn && window.location.pathname.includes('/share/'),
            );
        }
    }, [authStore.initialized, authStore.isUserSignedIn]);

    return isAnonymousShare;
}
