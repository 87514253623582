import StreemAuth from '@streem/auth';
import React, { useEffect } from 'react';

/**
 * This component monitors whether the refresh token storage key was removed from storage,
 * and if so, clears the in-app auth state from the current user session
 *
 * Specifically, this catches when a user is logging out from one company and has
 * this session open in another window. Storage events only fire when the storage area is
 * changed within the context of another document.
 */
export const IFrameLogoutMonitor: React.FC = (): null => {
    useEffect(() => {
        const clearAuthStateOnLogout = (event: StorageEvent) => {
            const isRefreshTokenKeyEvent = StreemAuth.keyMatchesRefreshTokenStorageKey(event.key);
            if (isRefreshTokenKeyEvent && event.newValue === null) {
                StreemAuth.completeIframeLogout();
            }
        };

        window.addEventListener('storage', clearAuthStateOnLogout);

        return () => {
            window.removeEventListener('storage', clearAuthStateOnLogout);
        };
    }, []);

    return null;
};
