import {
    styled,
    MeshBackground,
    Button,
    Header,
    AppText,
    Row,
    Box,
    useTheme,
} from '@streem/ui-react';

interface MessageScreenProps {
    dataTestId: string;
    header: string;
    message: string | JSX.Element;
    hasButton?: boolean;
    clickHandler: () => void;
}

export const MessagePageWithOptionalButton = ({
    dataTestId,
    header,
    message,
    hasButton = false,
    clickHandler,
}: MessageScreenProps): JSX.Element => {
    const theme = useTheme();

    return (
        <div
            data-testid={dataTestId}
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <MeshBackground>
                <Row
                    position="relative"
                    zIndex={1}
                    alignItems="flex-center"
                    justifyContent="center"
                    overflow="hidden"
                >
                    <Row padding="120px 100px">
                        <Row
                            alignItems="center"
                            justifyContent="center"
                            flex="auto"
                            bg={theme.colors.white}
                            boxShadow={theme.shadow.large}
                        >
                            <Box mx="7vh" my="5">
                                <Header>{header}</Header>
                                <Box mt={4} mb={5}>
                                    <AppText size="medium">{message}</AppText>
                                </Box>
                                {hasButton && (
                                    <BackButtonWrap>
                                        <Box alignSelf="flex-end">
                                            <Button
                                                data-testid={dataTestId + '-back-button'}
                                                variant="inline"
                                                onClick={clickHandler}
                                            >
                                                Back to Streem
                                            </Button>
                                        </Box>
                                    </BackButtonWrap>
                                )}
                            </Box>
                        </Row>
                    </Row>
                </Row>
            </MeshBackground>
        </div>
    );
};

const BackButtonWrap = styled.div`
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
