import { FC, useRef, SyntheticEvent, useState } from 'react';
import {
    AvatarPreview,
    Box,
    Button,
    Column,
    IconButton,
    Row,
    ToastTypes,
    useTheme,
} from '@streem/ui-react';
import { AvatarAsset } from '../../../../util/images';
import { AvatarCropperModal } from '../../../avatar_cropper/avatar_cropper_modal';
import { MAX_IMAGE_SIZE } from './avatar_uploader.config';
import { useGlobalStore } from '../../../../hooks/use_global_context';

interface Props {
    name: string;
    avatar?: AvatarAsset;
    changeValue: (val?: AvatarAsset) => void;
}

export const AvatarUploader: FC<Props> = ({ name, avatar, changeValue }) => {
    const logoRef = useRef<null | HTMLInputElement>(null);
    const theme = useTheme();
    const avatarUrl = avatar?.dataUrl;
    const [showCropper, setShowCropper] = useState<boolean>(false);
    const [previousAvatar, setPreviousAvatar] = useState<AvatarAsset | undefined>(avatar);
    const { uiStore } = useGlobalStore();

    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.value.length === 0 || !e.currentTarget.files) {
            return;
        }
        const file = e.currentTarget.files[0];
        if (!file.type.startsWith('image')) {
            return;
        }
        if (file.size > MAX_IMAGE_SIZE) {
            uiStore.addToast({
                content: 'Image size is too large. Please select a file no larger than 10MB.',
                flavor: ToastTypes.ERROR,
                id: `file-size-exceeded`,
            });
            return;
        }
        const dataUrl = URL.createObjectURL(file);
        if (previousAvatar && previousAvatar.dataUrl.includes('blob')) {
            URL.revokeObjectURL(previousAvatar.dataUrl);
        }
        // Saves a backup avatar value in case of AvatarCropper 'Cancel' or modal close.
        setPreviousAvatar(avatar);
        changeValue({
            dataUrl: dataUrl,
            fileName: file.name,
            contentType: file.type,
        });
        setShowCropper(true);
        uiStore.removeToast('file-size-exceeded');
    };

    return (
        <>
            <Column>
                <Box mb={4}>
                    <AvatarPreview size={200} src={avatarUrl} name={name}>
                        <Button
                            variant="secondary"
                            data-testid="upload user avatar overlay"
                            onClick={() => logoRef.current!.click()}
                        >
                            Upload
                        </Button>
                    </AvatarPreview>
                </Box>
                <Row alignSelf="flex-end" justifyContent="center">
                    <input
                        ref={logoRef}
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        onClick={e => (e.currentTarget.value = '')}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                    <IconButton
                        iconName="TrashIcon"
                        size="medium"
                        hoverFill={theme.colors.red50}
                        shape="square"
                        background="transparent"
                        label="delete user avatar"
                        style={{ alignItems: 'flex-end' }}
                        onClick={() => {
                            changeValue(undefined);
                        }}
                    />
                    <IconButton
                        iconName="AddFileIcon"
                        size="medium"
                        hoverFill={theme.colors.azure}
                        shape="square"
                        background="transparent"
                        label="upload user avatar"
                        style={{ alignItems: 'flex-end' }}
                        onClick={() => logoRef.current!.click()}
                    />
                </Row>
            </Column>
            {showCropper && (
                <AvatarCropperModal
                    isOpen={showCropper}
                    avatar={avatar!}
                    changeValue={changeValue}
                    undoAvatarChange={() => {
                        changeValue(previousAvatar);
                    }}
                    onClose={() => {
                        setShowCropper(false);
                    }}
                />
            )}
        </>
    );
};
