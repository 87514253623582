import { useState } from 'react';
import { AppIcon, AppText, Button, Header, Modal, Row, styled, useTheme } from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';
import { detect } from 'detect-browser';
import AudioOutputSelect from './audio_output_select';
import TestAudioButton from './test_audio_button';
import { VideoOutputSelect } from './video_output_select';

const isSafari = detect()?.name === 'safari';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const DeviceSettingsModal = ({ isOpen, onClose }: Props) => {
    const theme = useTheme();
    const [audioPermissionDenied, setAudioPermissionDenied] = useState(false);
    const mediaSettingsModalCloseButtonId = 'media-settings-okay-button';
    const noBreakSpace = '\u00A0';
    let errorMessage = noBreakSpace; // default to blank space so that the component still takes space in the DOM
    if (isSafari) {
        // https://bugs.webkit.org/show_bug.cgi?id=184141
        errorMessage = 'Speaker settings are not supported in Safari.';
    } else if (audioPermissionDenied) {
        errorMessage = 'Streem requires speaker access for video calls.';
    }

    const onCloseModal = () => {
        recordElementClicked(mediaSettingsModalCloseButtonId);
        onClose();
    };

    return (
        <Modal
            data-testid="media-settings-modal"
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            hideCloseButton
        >
            <HeaderContainer>
                <Header
                    size="xxlarge"
                    style={{ display: 'block' }}
                    data-testid="media-settings-header"
                >
                    Device Settings
                </Header>
            </HeaderContainer>
            <VideoOutputSelect />

            {/* TODO: Refactor Audio Logic with ST-5426 */}
            <AudioOutputWrapper id="audio output wrapper">
                <AudioOutputSelect
                    open={isOpen}
                    onPermissionDenied={() => {
                        setAudioPermissionDenied(true);
                    }}
                    onPermissionAllowed={() => {
                        setAudioPermissionDenied(false);
                    }}
                />
                {/* marginTop is to help line up the button with the select element */}
                <TestAudioButton
                    styles={{ marginTop: '4.2rem' }}
                    audioPermissionDenied={audioPermissionDenied}
                />
            </AudioOutputWrapper>
            <ErrorMessageWrapper>
                {errorMessage !== noBreakSpace && (
                    <AppIcon
                        name="SpeakerOffIcon"
                        slashcolor={theme.colors.red50}
                        color={theme.colors.red50}
                    />
                )}
                <AppText
                    semibold
                    size="medium"
                    color="red50"
                    style={{ display: 'block' }}
                    data-testid="speaker-settings-error-message"
                >
                    {errorMessage}
                </AppText>
            </ErrorMessageWrapper>
            <OkayButtonWrapper>
                <Button onClick={onCloseModal} data-testid={mediaSettingsModalCloseButtonId}>
                    Okay
                </Button>
            </OkayButtonWrapper>
        </Modal>
    );
};

const AudioOutputWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const ErrorMessageWrapper = styled(Row)({
    marginTop: '1.2rem',
    gap: '10px',
});

const OkayButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2rem',
});

const HeaderContainer = styled.div({
    marginBottom: '48px',
});

export default DeviceSettingsModal;
