import { AppText, Button, ButtonRow, Column, Header, Modal, Row, styled } from '@streem/ui-react';
import React from 'react';
import { isPlural } from '../../util/plural';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    numStreemshots: number;
    numVideos: number;
    deleteInProgress: boolean;
    handleDelete: () => void;
}

export const DeleteConfirmation: React.FC<Props> = ({
    isOpen,
    onClose,
    numStreemshots,
    numVideos,
    deleteInProgress,
    handleDelete,
}) => {
    const streemshotsPlural = isPlural(numStreemshots);
    const videosPlural = isPlural(numVideos);
    const deleteVideo = numVideos >= 1;
    const deleteStreemshot = numStreemshots >= 1;
    const deleteBoth = deleteVideo && deleteStreemshot;

    const DeleteMessage = () => {
        if (deleteBoth) {
            return (
                <AppText size="mediumLarge" style={{ marginBottom: '48px' }}>
                    Are you sure you want to delete {numStreemshots} streemshot
                    {streemshotsPlural && 's'} <br></br> and {numVideos} video
                    {videosPlural && 's'}? This cannot be undone.
                </AppText>
            );
        } else if (deleteVideo) {
            return (
                <AppText size="mediumLarge" style={{ marginBottom: '48px' }}>
                    Are you sure you want to delete {numVideos} video
                    {videosPlural && 's'}? This cannot be undone.
                </AppText>
            );
        } else if (deleteStreemshot) {
            return (
                <AppText size="mediumLarge" style={{ marginBottom: '48px' }}>
                    Are you sure you want to delete {numStreemshots} streemshot
                    {streemshotsPlural && 's'}? This cannot be undone.
                </AppText>
            );
        } else return <></>;
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={true}
            closeOnEscape={true}
            closeDelayMS={300}
            styleOverride={{
                content: {
                    width: '600px',
                },
            }}
        >
            <Column>
                <Row mb={4}>
                    <ModalHeader data-testid="media-gallery-deletion-warning-header" size="xlarge">
                        Delete media?
                    </ModalHeader>
                </Row>
                <DeleteMessage />
                <ButtonRow style={{ justifyContent: 'flex-end' }}>
                    <Button
                        data-testid="media-gallery-cancel-delete-selected-button"
                        onClick={onClose}
                        variant="secondary"
                    >
                        Cancel
                    </Button>

                    <Button
                        data-testid="media-gallery-delete-selected-button"
                        disabled={deleteInProgress}
                        variant="primary"
                        loading={deleteInProgress}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </ButtonRow>
            </Column>
        </Modal>
    );
};

const ModalHeader = styled(Header)({
    width: '85%',
});
