import { ReactNode } from 'react';
import { Setting } from '../../util/company_settings';
import { RolesAuth } from './roles_auth';

interface Props {
    setting: Setting;
    children: ReactNode;
}

export function SettingAuth({ setting, children }: Props) {
    if (setting.superAdmin) {
        return <RolesAuth only="SUPER_ADMIN">{children}</RolesAuth>;
    }

    return <>{children}</>;
}
