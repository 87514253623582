import { FC } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { TeamMemberRoute } from './routes';
import { useGlobalStore } from '../hooks/use_global_context';
import { APITypes } from '@streem/api';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('embedRoute');

export const EmbedRoute: FC<RouteProps> = props => {
    const { authStore, userStore } = useGlobalStore();

    return useObserver(() => {
        if (!authStore.isUserSignedIn) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            );
        }

        setContextValuesOnEmbedLog(userStore?.user, authStore?.companyCode);

        log.info(`Accessing Embed 2.0 route with path: ${props.path}`);

        return <TeamMemberRoute {...props} />;
    });
};

const setContextValuesOnEmbedLog = (user: APITypes.StreemApiUser, companyCode: string): void => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    if (source === 'salesforce') {
        log.setContextValue('usingSalesforceNativeEmbed', true);
    } else {
        log.setContextValue('usingEv2Embed', true);
    }
    if (companyCode) log.setContextValue('company', companyCode);
    if (user) log.setContextValue('externalUserId', user.externalUserId);
};
