import config from '../../src/config';
import { computed } from 'mobx';
import { APICallStore } from './api_call_store';
import { APIError, StreemAPI } from '@streem/api';
import { createSettingsDictionary, SettingsDictionary } from '../util/company_settings';

/**
 * Load a subset of company settings that aren't sensitive and do not require auth
 * as well as other config items that need to be fetched before the user authenticates
 * Examples: Settings that control the background image displayed on load
 */
export class ConfigStore {
    private loadPublicSettingsStore = new APICallStore(
        StreemAPI.companies.listCompanyPublicSettings.bind(StreemAPI.companies),
        'listCompanyPublicSettings',
    );

    private loadPublicSettings() {
        return this.loadPublicSettingsStore.fetch(config.companyCode);
    }

    @computed
    public get lastError(): APIError {
        return this.loadPublicSettingsStore.lastError;
    }

    @computed
    public get loading(): boolean {
        return this.loadPublicSettingsStore.loading;
    }

    @computed
    private get settingsDictionary(): SettingsDictionary {
        if (
            !this.loadPublicSettingsStore.response ||
            !this.loadPublicSettingsStore.response.settings
        ) {
            return undefined;
        }

        return createSettingsDictionary(this.loadPublicSettingsStore.response.settings);
    }

    @computed
    public get anonymousShareEnabled(): boolean {
        return this.settingsDictionary?.ANONYMOUS_SHARE_ENABLED?.value === 'true';
    }

    @computed
    public get companyLogoUrl(): string {
        return this.settingsDictionary?.COMPANY_LOGO_URL?.value;
    }

    @computed
    public get gpsEnabled(): boolean {
        return this.settingsDictionary?.GPS_ENABLED?.value === 'true';
    }

    @computed
    public get videoTrackPreviewEnabled(): boolean {
        return this.settingsDictionary?.VIDEO_TRACK_PREVIEW_ENABLED?.value === 'true';
    }

    public connect(): Promise<void> {
        // errors in loading the public settings for a company are caught here,
        // while error state in the UI is handled by routing
        return this.loadPublicSettings().catch(() => undefined);
    }
}
