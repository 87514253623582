import { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import {
    AppText,
    Button,
    ButtonRow,
    Column,
    Flex,
    Header,
    Modal,
    Select,
    styled,
    Theme,
    ReactSelectComponents,
} from '@streem/ui-react';
import { sortParamFormatter, ListUsersForCompanyUserTypes } from '@streem/api';
import { createListGroupUsersStore, createListUsersStore } from '../hooks/list_store_hooks';
import { useActiveCompanyCode } from '../hooks/use_active_company_code';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    handleSubmit: () => void;
    groupName: string;
    selectedUser: string;
    setSelectedUser: (user: any) => void;
}

export interface Option<T> {
    label: string;
    value: T;
}

const AddGroupMemberFormModal: React.FC<Props> = ({
    isOpen,
    closeModal,
    handleSubmit,
    groupName,
    selectedUser,
    setSelectedUser,
}) => {
    const groupUsersStore = createListGroupUsersStore();
    const teamMemberStore = createListUsersStore();
    const companyCode = useActiveCompanyCode();
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        const promises = [
            groupUsersStore.fetch(companyCode, groupName),
            teamMemberStore.fetchFirstPage({
                companyCodeOrSid: companyCode,
                filter: searchString,
                sort: sortParamFormatter('name', 'asc'),
                userTypes: [ListUsersForCompanyUserTypes.LOGIN],
            }),
        ];
        return () => promises.forEach(p => p.cancel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupUsersStore, companyCode, groupName, searchString]);

    useEffect(() => {
        if (isOpen) {
            teamMemberStore.refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return useObserver(() => {
        const teamMemberOptions: Option<{ label: string; id: string; description: string }>[] =
            teamMemberStore?.results.reduce((acc, agent) => {
                if (
                    acc.findIndex(a => a.sid === agent.sid) === -1 &&
                    !agent.roles.includes(groupName)
                ) {
                    const option = {
                        label: agent.name,
                        value: {
                            label: agent.name,
                            id: agent.sid,
                            description: agent.email,
                        },
                    };
                    acc.push(option);
                    return acc;
                } else {
                    return acc;
                }
            }, []);

        return (
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    closeModal();
                }}
                styleOverride={{
                    content: {
                        width: '800px',
                        height: '600px',
                        padding: Theme.spacing.l,
                    },
                    card: {
                        height: '100%',
                        overflow: 'scroll',
                    },
                }}
            >
                <Column height="100%" data-testid="add-member-to-group-modal">
                    <Header>{`Add Member to  ${groupName} Group`}</Header>
                    <Flex marginTop="16px" marginBottom="24px" height="38px">
                        <Select
                            options={teamMemberOptions}
                            placeholder="Select team member"
                            onSelect={(option: Option<{ id: string }>) => {
                                setSelectedUser(option.value.id);
                            }}
                            onInputChange={value => setSearchString(value)}
                            styles={{
                                control: (base: any) => ({
                                    ...base,
                                    width: '400px',
                                    height: 'auto',
                                }),
                                valueContainer: () => ({
                                    height: '100%',
                                    padding: '12px',
                                }),
                                menu: (base: any) => ({
                                    ...base,
                                    marginTop: '24px',
                                    height: 'auto',
                                    zIndex: 4,
                                }),
                                menuList: (base: any) => ({
                                    ...base,
                                    height: '250px',
                                    zIndex: 4,
                                }),
                                input: () => ({
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: '35%',
                                }),
                            }}
                            formatOptionLabel={data => {
                                const { value } = data as Option<{
                                    label: string;
                                    id: string;
                                    description: string;
                                }>;
                                return (
                                    <div data-testid={`item-${value.label}`}>
                                        <OptionName>{value.label}</OptionName>
                                        <AppText size="small">{value.description}</AppText>
                                    </div>
                                );
                            }}
                            components={{
                                Placeholder: ({
                                    children,
                                    ...props
                                }: React.PropsWithChildren<any>) => (
                                    <ReactSelectComponents.Placeholder
                                        {...props}
                                        innerProps={{
                                            ...props.innerProps,
                                            'data-testid': 'team-member-select',
                                        }}
                                    >
                                        {children}
                                    </ReactSelectComponents.Placeholder>
                                ),
                            }}
                        />
                    </Flex>
                    <ModalButtonRow>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            data-testid="add-to-group-member"
                            variant="primary"
                            disabled={!selectedUser}
                            onClick={handleSubmit}
                        >
                            Add to Group
                        </Button>
                    </ModalButtonRow>
                </Column>
            </Modal>
        );
    });
};

export default AddGroupMemberFormModal;

const ModalButtonRow = styled(ButtonRow)({
    justifyContent: 'flex-end',
    width: '100%',
    flexGrow: 1,
    alignItems: 'flex-end',
});

const OptionName = styled(AppText)({
    display: 'block',
    marginBottom: '4px',
});
