import { recordElementClicked } from '@streem/analytics';
import { AppText, IconButton, Row, styled, Theme } from '@streem/ui-react';
import { PropsWithChildren, useState } from 'react';

interface CollapsibleContentProps {
    title: string;
    initialExpandedYn?: boolean;
    id: string;
}
export const CollapsibleContent = ({
    title,
    children,
    initialExpandedYn = false,
    id,
}: PropsWithChildren<CollapsibleContentProps>): JSX.Element => {
    const [expanded, setExpanded] = useState(initialExpandedYn);
    const expandButtonId = `${id}-expand-button`;

    const onClickCaret = () => {
        recordElementClicked(`${id}-${expanded ? 'collapse' : 'expand'}-button`);
        setExpanded(!expanded);
    };

    return (
        <CollapsibleContentContainer>
            <TitleContainer>
                <AppText size="mediumLarge" headingFontFamily>
                    {title}
                </AppText>
                <IconButton
                    iconName="CaretIcon"
                    label="expand"
                    fill={Theme.colors.black}
                    data-testid={expandButtonId}
                    style={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease-in-out',
                    }}
                    onClick={onClickCaret}
                    size="medium"
                />
            </TitleContainer>
            {expanded && children}
        </CollapsibleContentContainer>
    );
};

const CollapsibleContentContainer = styled('section')({
    border: `1px solid ${Theme.colors.grey10}`,
    borderRadius: '4px',
    padding: '0.6rem 1rem',
});

const TitleContainer = styled(Row)({
    alignItems: 'center',
    justifyContent: 'space-between',
});
