/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallDetailCustomer,
    StreemApiCallDetailCustomerFromJSON,
    StreemApiCallDetailCustomerFromJSONTyped,
    StreemApiCallDetailCustomerToJSON,
    StreemApiCallDetailItem,
    StreemApiCallDetailItemFromJSON,
    StreemApiCallDetailItemFromJSONTyped,
    StreemApiCallDetailItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCallDetail
 */
export interface StreemApiCallDetail {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetail
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetail
     */
    callSourceSid?: string;
    /**
     * 
     * @type {Array<StreemApiCallDetailItem>}
     * @memberof StreemApiCallDetail
     */
    items?: Array<StreemApiCallDetailItem>;
    /**
     * 
     * @type {StreemApiCallDetailCustomer}
     * @memberof StreemApiCallDetail
     */
    customer?: StreemApiCallDetailCustomer;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetail
     */
    callSourceCode?: string;
}

export function StreemApiCallDetailFromJSON(json: any): StreemApiCallDetail {
    return StreemApiCallDetailFromJSONTyped(json, false);
}

export function StreemApiCallDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'callSourceSid': !exists(json, 'call_source_sid') ? undefined : json['call_source_sid'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(StreemApiCallDetailItemFromJSON)),
        'customer': !exists(json, 'customer') ? undefined : StreemApiCallDetailCustomerFromJSON(json['customer']),
        'callSourceCode': !exists(json, 'call_source_code') ? undefined : json['call_source_code'],
    };
}

export function StreemApiCallDetailToJSON(value?: StreemApiCallDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'call_source_sid': value.callSourceSid,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(StreemApiCallDetailItemToJSON)),
        'customer': StreemApiCallDetailCustomerToJSON(value.customer),
        'call_source_code': value.callSourceCode,
    };
}


