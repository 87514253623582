import { observable, action, reaction, IReactionDisposer, runInAction } from 'mobx';
import { StreemAPI, APITypes } from '@streem/api';
import { AuthStore } from './auth_store';

export class UserStore {
    private unsubscriber: IReactionDisposer | undefined = undefined;

    public constructor(private readonly authStore: AuthStore) {}

    // Omit sid and companySid from the User type as these details are
    // most reliably source from AuthStore.
    @observable
    public user: Omit<APITypes.StreemApiUser, 'sid' | 'companySid'> | undefined = undefined;

    @action.bound
    public setUser(user: APITypes.StreemApiUser | undefined) {
        this.user = user;
    }

    @action.bound
    public async updateUserMeta(newUserMeta: APITypes.StreemApiUpdateUserRequest) {
        if (!this.authStore.userId) {
            throw new Error('Unable to update user meta without authenticated user.');
        }

        const response = await StreemAPI.users.updateUser(this.authStore.userId, newUserMeta);
        runInAction(() => {
            this.setUser(response.user);
        });
    }

    public connect() {
        this.unsubscriber = reaction(
            () => [this.authStore.userId, this.authStore.companyCode],
            ([id, companyCode]) => {
                if (id && companyCode) {
                    StreemAPI.users.getUser(id).then(response => {
                        action(() => {
                            this.setUser(response.user);
                        })();
                    });
                } else {
                    this.setUser(undefined);
                }
            },
            { fireImmediately: true },
        );
    }

    public disconnect() {
        if (this.unsubscriber) {
            this.unsubscriber();
        }
    }
}
