import { useEffect, useState } from 'react';
import { ExtractProps, IconButton, Row, ToastTypes } from '@streem/ui-react';
import appLogger from '../../../util/logging/app_logger';
import { StringSettingLabel } from './field.styles';
import { StringSettingInput } from './string_setting_input';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { useCompanySettings } from '../../../hooks/company_settings_hooks';

interface Props extends ExtractProps<typeof Row> {
    value: number;
    onSave?: () => void;
}

export function DataRetentionPolicyField({ value, onSave, ...rowProps }: Props) {
    const [editValue, setEditValue] = useState((value === null));
    const { uiStore } = useGlobalStore();
    const companySettingsStore = useCompanySettings();

    const [dataRetentionPolicyDefaultValue, setDataRetentionPolicyDefaultValue] = useState<number | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await companySettingsStore.getDataRetentionPolicyDefault();
                setDataRetentionPolicyDefaultValue(parseInt(response));
            } catch (error) {
                appLogger.error("Error fetching data retention policy details:", error);
            }
        };
        fetchData();
    }, []);
    const currentValue = value !== null ? value : dataRetentionPolicyDefaultValue;

    const updateDataRetentionPolicyDays = async (value: string) => {
        try {
            await companySettingsStore.updateSetting('ROOM_TTL_DAYS', value.trim())
            uiStore.removeToast('data-retention-fail');
            onSave && onSave();
        } catch (error) {
            appLogger.error('Error updating', 'Data Retention Policy', error);
            uiStore.addToast({
                content: `Uh oh, we were unable to save the changes to 'Data Retention Policy' at this time.\n\nPlease try refreshing the page and trying again. You may also contact our support staff for assistance.`,
                flavor: ToastTypes.ERROR,
                id: 'data-retention-fail',
            });
        } finally {
            setEditValue(false);
        }
    };

    const validation = (value: string) => {
        let num = Number(value)
        let valid = true;
        let message = '';
        if (isNaN(num) || num <= 0 || num > (dataRetentionPolicyDefaultValue)) {
            valid = false;
            message = `Please, enter a number between 0 and ${dataRetentionPolicyDefaultValue}`;
        }
        return {
            valid,
            message,
        };
    }

    return (
        <Row
            alignItems="center"
            justifyContent="flex-end"
            minHeight="40px"
            alignSelf="flex-end"
            {...rowProps}
        >
            {!editValue && (
                <>
                    <StringSettingLabel title={currentValue.toString()}>{currentValue.toString()}</StringSettingLabel>
                    <IconButton
                        iconName="EditIcon"
                        label={`Edit Data Retention Policy Days`}
                        onClick={async () => {
                            setEditValue(true);
                        }}
                        background="transparent"
                    />
                </>
            )}
            {editValue && (
                <StringSettingInput
                    name="DATA_RETENTION_POLICY"
                    label="Data Retention Policy"
                    value={currentValue.toString()}
                    onCancel={() => setEditValue(false)}
                    onSave={updateDataRetentionPolicyDays}
                    validate={validation}
                />
            )}
        </Row>
    );
}
