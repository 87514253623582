import { AgentRow } from '../users/team/agent_list_columns';
import { UserAvatarCell } from '@streem/ui-react';

export function NameColumn({ row }: { row: AgentRow }) {
    return (
        <UserAvatarCell
            avatarUrl={row.avatarUrl || undefined}
            headline={row.name!}
            subHeadline={row.email!}
        />
    );
}
