import { google } from 'streem-sdk-protobuf';

export const convertProtobufToSeconds = (time: google.protobuf.ITimestamp) => {
    return time?.seconds?.low || 0 + (time?.nanos || 0) / 1000000000;
};

export function getReservationExpirationMessage(minutesToDisplay: number) {
    return `This reservation will expire in ${minutesToDisplay < 1 ? 'less than ' : ''}${
        minutesToDisplay < 1 ? 1 : minutesToDisplay
    } minute${minutesToDisplay > 1 ? 's' : ''}.`;
}
