import { RedirectProps, Redirect } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { APIError } from '@streem/api';

interface ILastError {
    lastError?: Error;
}

interface APINotFoundProps {
    stores: ILastError | ILastError[];
    redirect?: RedirectProps;
}

export function APINotFound({ stores, redirect }: APINotFoundProps) {
    return useObserver(() => {
        if (
            Array.isArray(stores) &&
            stores.find(s => s.lastError instanceof APIError && s.lastError.status === 404)
        ) {
            if (redirect) {
                return <Redirect {...redirect} />;
            } else {
                return <Redirect to="/404" />;
            }
        } else if (
            !Array.isArray(stores) &&
            stores.lastError instanceof APIError &&
            stores.lastError.status === 404
        ) {
            if (redirect) {
                return <Redirect {...redirect} />;
            } else {
                return <Redirect to="/404" />;
            }
        }
        return null;
    });
}
