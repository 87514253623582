import { useMemo, useLayoutEffect } from 'react';
import { AppText, Box, Row } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { useFormikContext } from 'formik';
import { OutOfScopeReason, RoomOutcomeFormPayload } from '../../types/disposition.types';
import { BlueCheckBox, AddButton } from './room_outcome_form_styles';
import { OutOfScopeReasonInput } from './out_of_scope_reason_input';
import { useWillOfferOptions } from '../../hooks/use_will_offer_options';

interface OutOfScopeDiscussionProps {
    displayOutOfScopeReasons: boolean;
    outOfScopeReasons: OutOfScopeReason[];
    customFieldOptions?: {
        refund?: streem.api.CompanyRoomOutcomeConfig.ISection;
        training?: streem.api.CompanyRoomOutcomeConfig.ISection;
        outOfScope?: streem.api.CompanyRoomOutcomeConfig.ISection;
    };
    setOutOfScopeReasons: React.Dispatch<React.SetStateAction<OutOfScopeReason[]>>;
}
// From the EDD for anyone curious about where this number came from.
// It might make sense for this to come from the api similar to max diagnoses.
const MAX_OUT_OF_SCOPE_REASONS = 3;

export const OutOfScopeDiscussion = ({
    displayOutOfScopeReasons,
    outOfScopeReasons,
    customFieldOptions,
    setOutOfScopeReasons,
}: OutOfScopeDiscussionProps): JSX.Element => {
    const { values, setFieldValue } = useFormikContext<RoomOutcomeFormPayload>();
    const shouldShowOutOfScopeBox = useWillOfferOptions(customFieldOptions?.outOfScope);

    // only show the button if we have less than the max number of out of scope reasons
    // and we have options left to add.
    const shouldShowAddOutOfScopeButton =
        outOfScopeReasons.length < MAX_OUT_OF_SCOPE_REASONS &&
        customFieldOptions.outOfScope?.options.length > outOfScopeReasons.length;

    const outOfScopeInputOptions = useMemo(() => {
        return customFieldOptions.outOfScope?.options.map(reason => ({
            value: reason.entry.code,
            label: reason.entry.label,
        }));
    }, [customFieldOptions?.outOfScope]);

    const createEmptyOutOfScopeOption = () => {
        return {
            code: Date.now().toString(),
            label: '',
        };
    };

    const handleSetOutOfScopeReasons = (
        callback: (reason: OutOfScopeReason) => OutOfScopeReason,
        newReason?: OutOfScopeReason,
    ) => {
        if (newReason) {
            setOutOfScopeReasons(reason => [...reason, newReason]);
            return;
        }
        setOutOfScopeReasons(reason => reason?.map(r => callback(r)).filter(Boolean));
    };

    const addBlankOutOfScopeReason = () => {
        handleSetOutOfScopeReasons(
            outOfScopeOption => outOfScopeOption,
            createEmptyOutOfScopeOption(),
        );
    };

    const editOutOfScopeReason = (
        prevOutOfScopeReason: OutOfScopeReason,
        newOutOfScopeReason: OutOfScopeReason,
    ) => {
        handleSetOutOfScopeReasons(reason => {
            if (reason.code === prevOutOfScopeReason.code) {
                return newOutOfScopeReason;
            } else {
                return reason;
            }
        });
    };

    const deleteOutOfScopeReason = (outOfScopeOption: OutOfScopeReason) => {
        handleSetOutOfScopeReasons((reason: OutOfScopeReason) => {
            if (reason.code === outOfScopeOption.code) {
                return null;
            } else {
                return reason;
            }
        });
    };

    const handleToggleOutOfScope = () => {
        const { customFields } = values;
        if (!customFields.outOfScope) {
            return;
        }
        // clear out the reasons if we are toggling off
        if (customFields.outOfScope.isOutOfScopeDiscussion) {
            setOutOfScopeReasons([createEmptyOutOfScopeOption()]);
        }

        setFieldValue('customFields', {
            ...customFields,
            outOfScope: {
                isOutOfScopeDiscussion: !customFields.outOfScope.isOutOfScopeDiscussion,
            },
        });
    };

    useLayoutEffect(() => {
        if (displayOutOfScopeReasons) {
            const outOfScopeOption = document.querySelector(
                `[aria-label="out of scope reason options ${outOfScopeReasons.length}"]`,
            ) as HTMLInputElement;
            outOfScopeOption?.focus();
        }
    }, [displayOutOfScopeReasons, outOfScopeReasons.length]);

    return shouldShowOutOfScopeBox ? (
        <>
            <Row>
                <BlueCheckBox
                    id="out of scope"
                    dataTestId="out-of-scope-checkbox"
                    description="Out Of Scope Discussion"
                    handleChange={handleToggleOutOfScope}
                />
            </Row>

            <Box style={{ visibility: displayOutOfScopeReasons ? 'visible' : 'hidden' }}>
                {outOfScopeReasons?.map((reason, index) => (
                    <OutOfScopeReasonInput
                        key={reason.code}
                        isDisabled={!displayOutOfScopeReasons}
                        reason={reason}
                        outOfScopeReasons={outOfScopeReasons}
                        index={index}
                        outOfScopeInputOptions={outOfScopeInputOptions}
                        deleteOutOfScopeReason={deleteOutOfScopeReason}
                        editOutOfScopeReason={editOutOfScopeReason}
                    />
                ))}

                {shouldShowAddOutOfScopeButton && (
                    <Row ml={2} mt={4}>
                        <AddButton onClick={addBlankOutOfScopeReason} label="out of scope" />
                        <Box ml={2}>
                            <AppText semibold headingFontFamily>
                                Add Out Of Scope Reason
                            </AppText>
                        </Box>
                    </Row>
                )}
            </Box>
        </>
    ) : null;
};
