import { styled, MeshBackground, Row, RadialFade, StreemStackedLogoLegal } from '@streem/ui-react';
import { TermsAndConditionsForm } from '../../forms/terms_and_conditions_form';

export const TermsAndConditionsPage = () => {
    return (
        <div
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <MeshBackground>
                <Wrapper>
                    <Row alignItems="center" justifyContent="center">
                        <RadialFade>
                            <StreemStackedLogoLegal color="white" />
                        </RadialFade>
                    </Row>
                    <TermsAndConditionsForm />
                </Wrapper>
            </MeshBackground>
        </div>
    );
};

const Wrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 0 64px;
    justify-items: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
`;
