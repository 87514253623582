import { RoomOutcomeFormPayload } from '../types/disposition.types';
import { streem } from 'streem-sdk-protobuf';

export function convertRoomOutcomePayloadToProtobuf(
    roomOutcomeReport: RoomOutcomeFormPayload,
): streem.api.Artifact.IRoomOutcomeReport {
    if (
        !roomOutcomeReport.customFields ||
        Object.keys(roomOutcomeReport?.customFields).length === 0
    ) {
        return {
            ...roomOutcomeReport,
            customFields: null,
        };
    }

    const { refund, training, outOfScope } = roomOutcomeReport.customFields;

    const outOfScopeReasons =
        outOfScope?.reasons?.length > 0
            ? outOfScope.reasons.map(reason => {
                  return {
                      structValue: {
                          fields: {
                              code: { stringValue: reason.code },
                              label: { stringValue: reason.label },
                          },
                      },
                  };
              })
            : undefined;

    const customFields = {
        training: {
            boolValue: training,
        },
        ...(refund && {
            refund: {
                structValue: {
                    fields: {
                        // eslint-disable-next-line camelcase
                        should_refund: {
                            boolValue: refund?.shouldRefund,
                        },
                        ...(refund?.reason && {
                            reason: {
                                structValue: {
                                    fields: {
                                        code: {
                                            stringValue: refund.reason.code,
                                        },
                                        label: {
                                            stringValue: refund.reason.label,
                                        },
                                    },
                                },
                            },
                        }),
                    },
                },
            },
        }),
        ...(outOfScope && {
            out_of_scope_discussion: {
                structValue: {
                    fields: {
                        is_out_of_scope_discussion: {
                            boolValue: outOfScope.isOutOfScopeDiscussion,
                        },
                        ...(outOfScopeReasons && {
                            reasons: {
                                listValue: {
                                    values: outOfScopeReasons,
                                },
                            },
                        }),
                    },
                },
            },
        }),
    };

    const protoRoomOutcomeReport = {
        ...roomOutcomeReport,
        customFields,
    };

    return protoRoomOutcomeReport;
}

export function convertProtobufToRoomOutcome(
    roomOutcomeReport: streem.api.Artifact.IRoomOutcomeReport,
): RoomOutcomeFormPayload {
    if (
        !roomOutcomeReport.customFields ||
        Object.keys(roomOutcomeReport?.customFields).length === 0
    ) {
        return {
            ...roomOutcomeReport,
            customFields: null,
        } as RoomOutcomeFormPayload;
    }

    const refund = roomOutcomeReport.customFields?.refund;
    const training = roomOutcomeReport.customFields?.training;

    const customFields = {
        training: training?.boolValue,
        refund: {
            // handle either 'should_refund or 'shouldRefund' to account for old data
            // eslint-disable-next-line camelcase
            shouldRefund: refund?.structValue?.fields?.should_refund
                ? // eslint-disable-next-line camelcase
                  refund?.structValue?.fields?.should_refund.boolValue
                : refund?.structValue?.fields?.shouldRefund.boolValue,
            ...(refund?.structValue?.fields?.reason && {
                reason: {
                    code: refund?.structValue?.fields?.reason.structValue?.fields?.code.stringValue,
                    label: refund?.structValue?.fields?.reason.structValue?.fields?.label
                        .stringValue,
                },
            }),
        },
    };

    const roomOutcome = {
        ...roomOutcomeReport,
        customFields,
    };

    return roomOutcome as RoomOutcomeFormPayload;
}

// example of a protobuf object that is being converted to a custom object:
// const roomOutcomeReport: streem.api.Artifact.IRoomOutcomeReport = {
//     diagnoses: [{ label: 'Diagnosis 1', code: 'DG1' }],
//     disposition: { label: 'Disposition 1', code: 'DP1' },
//     customFields: {
//         out_of_scope_discussion: {
//             structValue: {
//                 fields: {
//                     is_out_of_scope_discussion: { boolValue: true },
//                     reasons: {
//                         listValue: {
//                             values: [
//                                 {
//                                     structValue: {
//                                         fields: {
//                                             code: { stringValue: 'OOSD1' },
//                                             label: { stringValue: 'OOSD Reason 1' },
//                                         },
//                                     },
//                                 },
//                             ],
//                         },
//                     },
//                 },
//             },
//         },
//         refund: {
//             structValue: {
//                 fields: {
//                     should_refund: { boolValue: true },
//                     reason: {
//                         structValue: {
//                             fields: {
//                                 code: { stringValue: 'RF1' },
//                                 label: { stringValue: 'Reason 1' },
//                             },
//                         },
//                     },
//                 },
//             },
//         },
//         training: {
//             boolValue: true,
//         },
//     },
// };
export interface RoomOutcomeReportProps {
    diagnoses: string[];
    disposition?: string;
    outOfScopeDiscussionYn?: boolean;
    outOfScopeDiscussionReasons?: string[];
    shouldRefundYn?: boolean;
    refundReason?: string;
}
export const convertProtobufToDiagnosisProps = (
    roomOutcomeReport: streem.api.Artifact.IRoomOutcomeReport,
): RoomOutcomeReportProps => {
    const disposition = roomOutcomeReport.disposition?.label;
    const diagnoses = roomOutcomeReport.diagnoses?.map(diagnosis => diagnosis.label) ?? [];
    const refundFields = roomOutcomeReport.customFields?.refund?.structValue?.fields;
    const outOfScopeDiscussionFields =
        roomOutcomeReport.customFields?.out_of_scope_discussion?.structValue?.fields;
    return {
        diagnoses,
        disposition,
        outOfScopeDiscussionYn: outOfScopeDiscussionFields?.is_out_of_scope_discussion?.boolValue,
        outOfScopeDiscussionReasons: outOfScopeDiscussionFields?.reasons?.listValue.values.map(
            value => value.structValue?.fields?.label?.stringValue,
        ),
        shouldRefundYn:
            // handle either 'should_refund or 'shouldRefund' to account for old data
            // eslint-disable-next-line camelcase
            refundFields?.should_refund
                ? // eslint-disable-next-line camelcase
                  refundFields.should_refund.boolValue
                : refundFields?.shouldRefund?.boolValue,
        refundReason: refundFields?.reason?.structValue?.fields?.label?.stringValue,
    };
};
