import { FC } from 'react';
import { AppText, styled, AppIcon, IconNames } from '@streem/ui-react';

export const DecoratedLink: FC<{
    testid?: string;
    icon: IconNames;
    displayText: string;
    link?: string;
    subText?: string;
}> = ({ testid = 'decorated-link', icon, displayText, link, subText }) => {
    return (
        <Container>
            <ResizedAppIcon name={icon} />
            {link ? (
                <BlueLink
                    data-testid={testid}
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {displayText}
                </BlueLink>
            ) : (
                <AppText data-testid={`${testid}-text`} color={'medium'}>
                    {displayText}
                </AppText>
            )}
            {subText && (
                <SubText color="medium" data-testid={`${testid}-subtext`}>
                    {subText}
                </SubText>
            )}
        </Container>
    );
};

const ResizedAppIcon = styled(AppIcon)`
    width: 24px;
    height: 24px;
`;

const BlueLink = styled.a`
    color: ${({ theme }) => theme.colors.azure};
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
`;

const Container = styled.div`
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 2px;
    align-content: center;
    align-items: center;
    grid-template-columns: min-content max-content;
    grid-template-rows: auto;
`;

const SubText = styled(AppText)`
    grid-column: 2;
    font-size: 15px;
`;
