import { FC } from 'react';
import { observer } from 'mobx-react';
import { match, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
    AppIcon,
    AppIconWithBadge,
    AppText,
    Button,
    NavButton,
    TopNav,
    VerticalNavIconBar,
    styled,
    useTheme,
} from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';
import { useGlobalStore } from '../../hooks/use_global_context';
import { openLinkInNewTab } from '../../util/routing';
import { RolesAuth } from '../authorization/roles_auth';
import { BasicVerticalNavBar } from './basic_vertical_nav_bar';
import { InviteToStreemButton } from '../invite/invite_to_streem_button';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useAnonymousShareChecker } from '../../hooks/use_anonymous_share_checker';

interface CompanyParams {
    company?: string;
}

export const VerticalNavBar: FC = observer(() => {
    const { authStore, companySettingsStore, uiStore } = useGlobalStore();
    const location = useLocation();
    const history = useHistory();
    const routeMatch = useRouteMatch('/companies/:company') as match<CompanyParams> | null;
    const theme = useTheme();
    const company = routeMatch?.params.company;
    const isOnCompaniesPage = company !== undefined;
    const { isEmbedView, areCompanySettingsLoading } = useGetEmbedEnabledSettings();
    const isAnonymousSharePage = useAnonymousShareChecker(authStore);

    if (areCompanySettingsLoading || isEmbedView || isAnonymousSharePage) {
        return null;
    }

    const accessToken = window.sessionStorage.getItem('STREEM_ACCESS_TOKEN');
    if (!authStore.isUserSignedIn && !accessToken) {
        uiStore.toggleVerticalNavBar(false);
        return <BasicVerticalNavBar />;
    }

    const expanded = uiStore.mainNavExpanded;

    return (
        <VerticalNavIconBar
            data-testid="main-nav-buttons"
            expanded={expanded}
            toggleNavBar={() => uiStore.toggleVerticalNavBar()}
        >
            <TopNav>
                <RolesAuth except="SUPER_ADMIN">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '16px 8px',
                            marginBottom: companySettingsStore?.expertAvailabilityEnabled
                                ? '16px'
                                : '30px',
                        }}
                    >
                        {!companySettingsStore?.expertAvailabilityEnabled && (
                            <InviteToStreemButton>
                                {({ open, onClick }) => (
                                    <InviteButton
                                        expanded={expanded}
                                        data-testid="invite-to-streem-btn"
                                        disabled={open}
                                        onClick={onClick}
                                    >
                                        <AppIcon
                                            name="PhoneInviteIcon"
                                            color={theme.colors.blue80}
                                        />
                                        <ButtonLabel headingFontFamily expanded={expanded}>
                                            Invite
                                        </ButtonLabel>
                                    </InviteButton>
                                )}
                            </InviteToStreemButton>
                        )}
                    </div>
                </RolesAuth>
                <RolesAuth only="SUPER_ADMIN">
                    <NavButton
                        expanded={expanded}
                        icon={<AppIconWithBadge name="HomeIcon" color="white" />}
                        tabName={'All Companies'}
                        testId={'nav-all-companies'}
                        onClick={() => history.push('/companies')}
                        active={location.pathname.endsWith('/companies')}
                    />
                </RolesAuth>
                {(isOnCompaniesPage || !authStore.isSuperAdmin) && (
                    <>
                        <NavButton
                            expanded={expanded}
                            icon={<AppIconWithBadge name="TimerIcon" color="white" />}
                            active={/\/(teamcalls|mycalls|myfavorites)/i.test(location.pathname)}
                            tabName={'Streem Calls'}
                            testId={'nav-streem-calls'}
                            onClick={() =>
                                history.push(
                                    authStore.isAgent
                                        ? '/mycalls'
                                        : authStore.isSuperAdmin && company
                                        ? `/companies/${company}/teamcalls`
                                        : '/teamcalls',
                                )
                            }
                            nested={authStore.isSuperAdmin && isOnCompaniesPage}
                        />
                        <RolesAuth except="AGENT">
                            <NavButton
                                expanded={expanded}
                                icon={<AppIconWithBadge name="TeamIcon" color="white" />}
                                active={
                                    (authStore.isSuperAdmin && company
                                        ? `/companies/${company}/team`
                                        : '/team') === location.pathname
                                }
                                tabName={'Team Members'}
                                testId={'nav-team-members'}
                                onClick={() =>
                                    history.push(
                                        authStore.isSuperAdmin && company
                                            ? `/companies/${company}/team`
                                            : '/team',
                                    )
                                }
                                nested={authStore.isSuperAdmin && isOnCompaniesPage}
                            />
                        </RolesAuth>
                        {(companySettingsStore.groupManagementEnabled ||
                            authStore.isSuperAdmin) && (
                            <RolesAuth only={['COMPANY_ADMIN', 'SUPER_ADMIN']}>
                                <NavButton
                                    expanded={expanded}
                                    icon={<AppIconWithBadge name="GroupIcon" color="white" />}
                                    active={
                                        (authStore.isSuperAdmin && company
                                            ? `/companies/${company}/groups`
                                            : '/groups') === location.pathname ||
                                        location.pathname.includes('/groups/')
                                    }
                                    tabName={'Groups'}
                                    testId={'nav-groups'}
                                    onClick={() =>
                                        history.push(
                                            authStore.isSuperAdmin && company
                                                ? `/companies/${company}/groups`
                                                : '/groups',
                                        )
                                    }
                                    nested={authStore.isSuperAdmin && isOnCompaniesPage}
                                />
                            </RolesAuth>
                        )}
                        {companySettingsStore.embeddedReportsEnabled && (
                            <RolesAuth only={['COMPANY_ADMIN', 'MANAGER']}>
                                <NavButton
                                    expanded={expanded}
                                    icon={<AppIconWithBadge name="ReportsIcon" color="white" />}
                                    active={
                                        (authStore.isSuperAdmin && company
                                            ? `/companies/${company}/reports`
                                            : '/reports') === location.pathname
                                    }
                                    tabName={'Reports'}
                                    testId={'nav-reports'}
                                    onClick={() => {
                                        recordElementClicked('nav-reports');
                                        history.push('/reports');
                                    }}
                                    nested={authStore.isSuperAdmin && isOnCompaniesPage}
                                />
                            </RolesAuth>
                        )}
                        <RolesAuth only={['SUPER_ADMIN']}>
                            <NavButton
                                expanded={expanded}
                                icon={<AppIconWithBadge name="DeveloperIcon" color="white" />}
                                active={`/companies/${company}/api` === location.pathname}
                                tabName={'API Keys'}
                                testId={'nav-api-keys'}
                                onClick={() => history.push(`/companies/${company}/api`)}
                                nested
                            />
                        </RolesAuth>
                        <RolesAuth except="AGENT">
                            <NavButton
                                expanded={expanded}
                                icon={<AppIconWithBadge name="SettingsIcon" color="white" />}
                                tabName={'Settings'}
                                testId={'nav-company-settings'}
                                onClick={() =>
                                    history.push(
                                        authStore.isSuperAdmin && company
                                            ? `/companies/${company}/settings`
                                            : '/settings',
                                    )
                                }
                                active={/settings|customizations$/.test(location.pathname)}
                                nested={authStore.isSuperAdmin && isOnCompaniesPage}
                            />
                        </RolesAuth>
                    </>
                )}
            </TopNav>
            <NavButton
                expanded={expanded}
                icon={<AppIconWithBadge name="HelpIcon" color="white" />}
                tabName={'Help'}
                testId={'nav-contact-help'}
                onClick={() => openLinkInNewTab('https://support.streem.com')}
            />
        </VerticalNavIconBar>
    );
});

const ButtonLabel = styled(AppText)<{ expanded: boolean }>(
    ({ theme, expanded }) => `
    display: inline-block;
    transition-property: opacity,width;
    transition-duration: ${expanded ? '100ms' : '30ms'};
    transition-timing-function: linear;
    opacity: ${expanded ? 1 : 0};
    width: ${expanded ? '50px' : '0px'};
    color: ${theme.colors.blue80};
    font-size: 1rem;
    margin-left: ${expanded ? theme.spacing.xs : theme.spacing.none};
`,
);

const InviteButton = styled(Button)<{ expanded: boolean }>(
    ({ theme, expanded }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.white};
    height: 40px;
    border-radius: 22.5px;
    transition-property: width, padding;
    transition-duration: 192ms;
    transition-delay: ${expanded ? '5ms' : '0ms'};
    width: ${expanded ? '190px' : '40px'};
    padding: ${expanded ? '0 40px' : '0px'};
`,
);
