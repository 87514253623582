import React, { PropsWithChildren, useCallback, useState } from 'react';
import { styled, Button, Header, ToastTypes, AppText } from '@streem/ui-react';
import { InviteForm } from './invite_form';
import { ReadOnlyField } from './read_only_field';
import { useGlobalStore } from '../../hooks/use_global_context';
import { InviteCodeField } from './invite_code_field';

// NOTE: This file now contains several instances of conditional styling for Embed2.0. With the
// completion of Embed and updates to headers, this was the only file where the EV2 styles didn't work with Embed
// so choosing to conditionally style here for now instead of trying to make responsive styling for the whole app

interface InviteProps {
    onDone: (inviteType: string) => void;
    onBack?: () => void;
    isEmbedded?: boolean;
    initialFormData?: InviteValues;
}

export interface InviteValues {
    phone?: string;
    name?: string;
    referenceId?: string;
    integrationId?: string;
    inviteType?: string;
    customerEmail?: string;
    callingCode?: string;
}

type InviteSuccessProps = Pick<InviteValues, 'name' | 'referenceId' | 'customerEmail'> & {
    inviteLink: string;
    inviteCode: string;
};

const CommonCard: React.FC<
    PropsWithChildren<{ header: string; isEmbedded: boolean; linkInvite?: boolean }>
> = ({ header, isEmbedded, linkInvite = false, children }) => {
    return (
        <GridCard>
            <GridCardHeader
                data-testid="invite-card-header"
                isEmbedded={isEmbedded}
                linkInvite={linkInvite}
            >
                {header}
            </GridCardHeader>
            <GridCardBody>{children}</GridCardBody>
        </GridCard>
    );
};

const LinkInviteSuccessFields: React.FC<InviteSuccessProps> = ({
    name,
    referenceId,
    inviteLink,
    inviteCode,
}) => {
    return (
        <GridFieldset>
            <ReadOnlyField data-testid="invite-success-name" value={name} label="Name" />
            <ReadOnlyField
                data-testid="invite-success-reference-id"
                value={referenceId}
                label="Reference ID"
            />
            <ReadOnlyField
                data-testid="invite-success-invite-link"
                value={inviteLink}
                label="Invitation Link"
                copy
            />
            <InviteCodeField value={inviteCode} />
        </GridFieldset>
    );
};

const LinkInviteSuccess: React.FC<InviteSuccessProps & InviteProps> = ({
    onDone,
    onBack,
    inviteLink,
    inviteCode,
    name,
    referenceId,
    isEmbedded,
}) => {
    return (
        <CommonCard header="Share Invitation" isEmbedded={isEmbedded} linkInvite>
            <AppText as="h2" headingFontFamily size="mediumLarge" style={{ marginBottom: '8px' }}>
                Copy and send to your customer
            </AppText>
            <LinkInviteSuccessFields
                inviteLink={inviteLink}
                inviteCode={inviteCode}
                name={name}
                referenceId={referenceId}
            />
            <GridCardFooter style={{ marginTop: isEmbedded ? '16px' : '24px' }}>
                <Button variant="secondary" data-testid="invite-back-btn" onClick={() => onBack()}>
                    Back
                </Button>
                <Button data-testid="invite-done-btn" onClick={() => onDone('link')}>
                    Done
                </Button>
            </GridCardFooter>
        </CommonCard>
    );
};

export const InviteCard: React.FC<InviteProps> = ({ onDone, isEmbedded, initialFormData }) => {
    const { uiStore } = useGlobalStore();
    enum InviteCardState {
        Form = 0,
        Success = 1,
        Error,
    }

    const [state, setState] = useState<InviteCardState>(0);

    const [values, setValues] = useState<InviteValues>({
        name: initialFormData?.name,
        phone: initialFormData?.phone,
        referenceId: initialFormData?.referenceId,
        integrationId: initialFormData?.integrationId,
        inviteType: initialFormData?.inviteType,
        callingCode: initialFormData?.callingCode,
    });

    const [inviteSuccessProps, setInviteSuccessProps] = useState<InviteSuccessProps>({
        inviteLink: '',
        inviteCode: '',
        name: '',
        referenceId: '',
    });

    const handleSuccess = useCallback(
        meta => {
            if (meta.values.inviteType === 'phone') {
                onDone('phone');
                uiStore.addToast(
                    {
                        content: `Invitation sent to ${meta.values.name} at ${meta.values.phone}`,
                        flavor: ToastTypes.SUCCESS,
                        id: 'phone-invite-success-' + Date.now().toString(),
                        timeout: 6000,
                    },
                    isEmbedded,
                );
            } else {
                setState(InviteCardState.Success);
                setValues(meta.values);
                setInviteSuccessProps({
                    inviteCode: meta.response?.invitation.code,
                    inviteLink: meta.response?.link,
                    name: meta.values.name,
                    referenceId: meta.values.referenceId,
                });
            }
        },
        [setState, setInviteSuccessProps, InviteCardState, uiStore, onDone, isEmbedded],
    );

    if (state === InviteCardState.Success) {
        const { inviteType } = values;

        if (inviteType === 'link')
            return (
                <LinkInviteSuccess
                    onDone={() => onDone('link')}
                    onBack={() => setState(InviteCardState.Form)}
                    name={values.name ?? '(No name provided)'}
                    referenceId={values.referenceId}
                    inviteLink={inviteSuccessProps.inviteLink}
                    inviteCode={inviteSuccessProps.inviteCode}
                    isEmbedded={isEmbedded}
                />
            );
    }

    return (
        <CommonCard header="Create an Invitation" isEmbedded={isEmbedded}>
            <InviteForm
                name={values.name}
                phone={values.phone}
                referenceId={values.referenceId}
                integrationId={values.integrationId}
                customerEmail={values.customerEmail}
                callingCode={values.callingCode}
                inviteType={values.inviteType ?? 'phone'}
                onSuccess={handleSuccess}
                onCancel={() => onDone('cancel')}
                isEmbedded={isEmbedded}
            />
        </CommonCard>
    );
};

const GridCard = styled.div`
    width: 500px;
    max-height: 630px;
    display: grid;
    padding: 0;
    grid-auto-rows: auto;
    grid-template-columns: auto 40px;
    grid-gap-columns: 4px;
`;

const GridCardHeader = styled(Header)<{ isEmbedded: boolean; linkInvite: boolean }>(
    ({ theme, isEmbedded, linkInvite }) => ({
        gridRow: 1,
        gridColumn: 1,
        marginBottom: linkInvite && isEmbedded ? '0px' : '12px',
        fontSize: isEmbedded ? theme.text.size.xlarge : theme.text.size.xxlarge,
    }),
);

const GridCardBody = styled.div`
    grid-column: span 2;
    margin-right: 24px;
`;

const GridCardFooter = styled('footer')`
    margin-left: auto;
    grid-column: span 2;
    text-align: right;
    margin-right: -24px;
    > button {
        margin-left: 16px;
    }
`;

const GridFieldset = styled('section')`
    display: grid;
    grid-gap: 8px;
`;
