import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
    useGetUserCallLogStore,
    useGetUserFavoritesCallLogStore,
    useGetCompanyCallLogStore,
} from './list_store_hooks';
import { useAppHistory } from './use_app_history';
import { useGetEmbedEnabledSettings } from './use_get_embed_enabled_settings';

/**
 * This hook gets the saved filter to use when navigating to the requisite call logs page
 */
export const useCallLogSavedFilter = (): string => {
    const appHistory = useAppHistory();
    const location = useLocation();
    const userCallLogStore = useGetUserCallLogStore();
    const userFavoritesCallLogStore = useGetUserFavoritesCallLogStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();

    const companyCallLogStore = useGetCompanyCallLogStore();
    const savedSearchValue = useMemo((): string => {
        const navigatingFromCallDetailPage = appHistory[appHistory.length - 1]?.includes('rm_');
        if (location.pathname.includes('mycalls') && navigatingFromCallDetailPage) {
            const myCallsFilter = userCallLogStore.baseRequest?.filter;
            return myCallsFilter;
        } else if (
            location.pathname.includes('myfavorites') &&
            navigatingFromCallDetailPage &&
            !isEmbedView
        ) {
            const myFavoritesCallsFilter = userFavoritesCallLogStore.baseRequest?.filter;
            return myFavoritesCallsFilter;
        } else if (location.pathname.includes('teamcalls') && navigatingFromCallDetailPage) {
            const teamCallsFilter = companyCallLogStore.baseRequest?.filter;
            return teamCallsFilter;
        } else {
            return '';
        }
    }, [
        appHistory,
        companyCallLogStore.baseRequest?.filter,
        isEmbedView,
        location.pathname,
        userCallLogStore.baseRequest?.filter,
        userFavoritesCallLogStore.baseRequest?.filter,
    ]);

    return savedSearchValue;
};
