import { Formik, Form } from 'formik';
import { FC, useState } from 'react';
import * as yup from 'yup';
import { AppText, Box, Button, Column, Header, Row, styled, WhiteInput } from '@streem/ui-react';
import { ValidatedField } from '../components/inputs/validated_field';
import { trimFormValues } from '../util/form';
import { StreemAPI } from '@streem/api';
import appLogger from '../util/logging/app_logger';

const BlueLink = styled.a(({ theme }) => ({
    color: theme.colors.azure,
    marginTop: theme.spacing.m,
    textDecoration: 'none',
}));

const forgotPasswordSchema = yup.object().shape({
    Email: yup.string().email().required('Email is a required field.'),
});

interface Props {
    companyCode: string;
}

const ForgotPasswordFormTag = styled(Form)(({ theme }) => ({
    position: 'relative',
    maxHeight: '80vh',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    padding: `
        ${theme.space[5]}px
        ${theme.space[6]}px`,
    background: theme.colors.white,
    boxShadow: theme.shadow.large,
    overflow: 'auto',
    borderRadius: '4px',
    width: '100%',
}));

const ForgotPasswordForm: FC<Props> = ({ companyCode }) => {
    const [success, setSuccess] = useState(false);

    return (
        <Formik
            initialValues={{ Email: '' }}
            validationSchema={forgotPasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
                const { Email } = trimFormValues(values, ['Email']);

                try {
                    await StreemAPI.authLogin.initiatePasswordReset({
                        email: Email.toLowerCase(),
                        companyCode,
                    });
                } catch (e) {
                    appLogger.error('Error requesting password reset email: ', e);
                } finally {
                    setSubmitting(false);
                    setSuccess(true);
                }
            }}
        >
            {({ isSubmitting, isValid, status }) =>
                success ? (
                    <ForgotPasswordFormTag>
                        <Header>Email sent</Header>
                        <Box my={4}>
                            <AppText>
                                We've sent you an email containing a link that will allow you to
                                reset your password. Once you receive the email follow the
                                instructions to change your password.
                            </AppText>
                        </Box>
                        <BlueLink data-testid="success-return-to-login" href="/">
                            Return to login
                        </BlueLink>
                    </ForgotPasswordFormTag>
                ) : (
                    <ForgotPasswordFormTag>
                        <Header>Forgot password</Header>
                        <Box mt={5} mb={4}>
                            <AppText>
                                We will send you an email to reset your password. This reset email
                                will expire after one hour.
                            </AppText>
                        </Box>
                        <Column>
                            <ValidatedField
                                data-testid="forgot-password-email-field"
                                type="email"
                                name="Email"
                                label="Email"
                                labelColor="dark"
                                errorStatus={status}
                                placeholder="customer@streem.com"
                                component={WhiteInput}
                            />
                            <Row mb={4}>
                                <Button
                                    disabled={isSubmitting || !isValid}
                                    data-testid="forgot-password-submit-button"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Row>
                            <BlueLink data-testid="return-to-login" href="/">
                                Return to login
                            </BlueLink>
                        </Column>
                    </ForgotPasswordFormTag>
                )
            }
        </Formik>
    );
};

export default ForgotPasswordForm;
