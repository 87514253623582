import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useObserver } from 'mobx-react';
import { useCentralLoginStore } from '../hooks/use_central_login_context';

type Props = RouteProps;

export const CentralLoginProtectedRoute: React.FC<Props> = props => {
    const { authStore } = useCentralLoginStore();
    const location = useLocation();

    const { ...routeProps } = props;

    return useObserver(() => {
        if (!authStore.isUserSignedIn) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            // currently only restricted to logged-in users. consider role-based permissions as needed
            <Route {...routeProps} />
        );
    });
};
