import {
    IMAGE_QUALITY,
    MAX_AVATAR_DIMENSION,
} from '../components/companies/customizations/avatar_uploader/avatar_uploader.config';
import { CreateUserAssetOperationType, StreemAPI } from '@streem/api';
import { isMobileDevice } from './check_device_support';

/**
 * Needs further description, but appears to create a square image
 * @param image
 * @param fileType
 * @param imageRotation
 * @param maxDimension
 * @param imageQuality
 */
export const createSquareImage = (
    image: HTMLImageElement,
    fileType: string,
    imageRotation?: number,
    maxDimension?: number,
    imageQuality?: number,
): string => {
    const _maxDimension = maxDimension || MAX_AVATAR_DIMENSION;
    const _imageQuality = imageQuality || IMAGE_QUALITY;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Unknown error occurred. Please refresh the page and try again');
    }
    const { offsetX, offsetY, squareSize } = getSquareCropProperties(image);
    const rotationAdjustment = {
        x: 0,
        y: 0,
    };
    const uploadSize = squareSize > _maxDimension ? _maxDimension : squareSize;
    canvas.width = uploadSize;
    canvas.height = uploadSize;
    // corrects portrait image orientation on mobile uploads
    if (imageRotation === 6 && isMobileDevice()) {
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        rotationAdjustment.x = uploadSize / -2;
        rotationAdjustment.y = uploadSize / -2;
    }
    ctx.drawImage(
        image,
        offsetX,
        offsetY,
        squareSize,
        squareSize,
        rotationAdjustment.x,
        rotationAdjustment.y,
        uploadSize,
        uploadSize,
    );
    // base64 dataURL of cropped and resized image
    return canvas.toDataURL(fileType, _imageQuality).split(',')[1];
};

/**
 * Returns the relevant URL for an avatar object.
 * Note, if the dataUrl contains "blob", it will also UPLOAD THE AVATAR and return its proper url
 */
export const getAvatarUrl = async (avatar: AvatarAsset | undefined, userSid: string) => {
    if (avatar === undefined) return '';
    if (avatar.dataUrl.includes('blob')) {
        const avatarResponse = await StreemAPI.users.createUserAsset(
            userSid,
            CreateUserAssetOperationType.AVATAR,
            {
                httpBody: {
                    contentType: avatar.contentType,
                    data: createAvatarImage(avatar),
                    extensions: [],
                },
                filename: avatar.fileName,
            },
        );
        // Maybe need to check for error response from the backend here?
        return avatarResponse.downloadUrl;
    } else {
        return avatar.dataUrl;
    }
};

/**
 * Needs further description
 * @param image
 */
export const getSquareCropProperties = (image: HTMLImageElement) => {
    const shortestSide = Math.min(image.height, image.width);
    const squareCropProperties = {
        offsetX: 0,
        offsetY: 0,
        squareSize: shortestSide,
    };
    if (image.height === shortestSide) {
        squareCropProperties.offsetX = Math.floor((image.width - shortestSide) / 2);
    } else if (image.width === shortestSide) {
        squareCropProperties.offsetY = Math.floor((image.height - shortestSide) / 2);
    }
    return squareCropProperties;
};

export interface AvatarAsset {
    dataUrl: string;
    fileName: string;
    contentType: string;
}

/**
 * Needs further description
 * @param avatar
 */
export const createAvatarImage = (avatar: AvatarAsset): string => {
    const image = new Image();
    image.src = avatar.dataUrl;
    const avatarImage = (image.onload = () => {
        const avatarImage = createSquareImage(image, avatar.contentType);
        URL.revokeObjectURL(avatar.dataUrl);
        return avatarImage;
    })();
    return avatarImage;
};
