import React from 'react';
import { NavButton, AppIconWithBadge } from '@streem/ui-react';
import { openLinkInNewTab } from '../../util/routing';
import {
    VertNavButtonsWrapper,
    Wrapper,
} from '@streem/ui-react/lib/components/vertical_icon_bar/vertical_icon_bar.styles';

const BasicVerticalNavBar: React.FC = () => {
    return (
        <Wrapper expanded={false}>
            <VertNavButtonsWrapper
                data-testid="basic-vertical-nav"
                expanded={false}
                style={{ justifyContent: 'flex-end' }}
            >
                <NavButton
                    expanded={false}
                    icon={<AppIconWithBadge name="HelpIcon" color="white" />}
                    tabName={'Help'}
                    testId={'nav-contact-help'}
                    onClick={() => openLinkInNewTab('https://support.streem.com')}
                />
            </VertNavButtonsWrapper>
        </Wrapper>
    );
};

export { BasicVerticalNavBar };
