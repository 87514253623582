import { useEffect, useState, useRef, ChangeEvent, MutableRefObject, useCallback } from 'react';
import {
    AppIcon,
    AppText,
    Box,
    Button,
    Row,
    Spinner,
    styled,
    Column,
    useTheme,
} from '@streem/ui-react';
import { APIError, StreemAPI } from '@streem/api';
import Papa from 'papaparse';
import { useGetCompanyStore } from '../../../hooks/detail_store_hooks';
import { getCompanyRoomOutcomeConfig } from '../../../hooks/use_room_outcome_form_config';
import { FileUploader } from '../../inputs/file_upload';
import { Setting } from '../../../util/company_settings';
import {
    transformOptions,
    validateCsvUpload,
    truncateFileName,
    CSVInput,
} from '../../../util/call_disposition_helpers';
import appLogger from '../../../util/logging/app_logger';
const log = appLogger.extend('uploadSettingField');

const deleteRoomOutcomeConfig = async (companySid: string) => {
    await StreemAPI.companies.deleteCompanyRoomOutcomeConfig(companySid);
};

export const UploadSettingField = ({ setting }: { setting: Setting }) => {
    const theme = useTheme();
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileUploadAttempt, setFileUploadAttempt] = useState(false);
    const [parsingError, setParsingError] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const companyStore = useGetCompanyStore();
    const ROOM_OUTCOME_CONFIG_FILE_NAME = `ROOM_OUTCOME_CONFIG_FILE_NAME_${companyStore?.result?.code}`;

    useEffect(() => {
        if (!companyStore || !companyStore.result) {
            return;
        }

        const fetchRoomOutcomeConfig = async () => {
            try {
                const roomConfig = await getCompanyRoomOutcomeConfig(companyStore?.result?.sid);
                setFile(new File([], roomConfig.fileName.replace('.json', '')));
                setFileUploadAttempt(true);
                localStorage.setItem(
                    ROOM_OUTCOME_CONFIG_FILE_NAME,
                    roomConfig.fileName.replace('.json', ''),
                );
            } catch (error) {
                if (error instanceof APIError && error.status === 404) {
                    log.debug('Room outcome not configured', error);
                } else {
                    log.error('Error fetching room outcome config: ', error);
                }
            }
            setIsLoading(false);
        };

        setIsLoading(true);
        fetchRoomOutcomeConfig();
    }, [companyStore, ROOM_OUTCOME_CONFIG_FILE_NAME]);

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setParsingError(null);
        let file: File;
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
            file = files[0];
        } else {
            setParsingError('File not detected');
            setIsLoading(false);
            return;
        }

        Papa.parse<CSVInput>(file, {
            header: true,
            skipEmptyLines: true,
            complete: async result => {
                let transformedData;
                let parsingError = validateCsvUpload(result);
                if (!parsingError) {
                    try {
                        transformedData = transformOptions(result.data);
                    } catch (error) {
                        parsingError = 'Invalid data format';
                    }
                }

                if (!parsingError) {
                    try {
                        await StreemAPI.companies.createCompanyRoomOutcomeConfig(
                            companyStore?.result?.sid,
                            file.name,
                            {
                                ...transformedData,
                            },
                        );
                        localStorage.setItem(ROOM_OUTCOME_CONFIG_FILE_NAME, file.name);
                    } catch (error) {
                        if (error instanceof APIError) {
                            // https://streempro.slack.com/archives/C5PAJ78JJ/p1689358580316529?thread_ts=1689353790.781229&cid=C5PAJ78JJ
                            if (error.response.status === 400) {
                                const errorResponse = await error.response.json();
                                parsingError = errorResponse.message || 'Invalid data format';
                            }
                        } else {
                            parsingError = 'Invalid data format';
                        }

                        log.error('Error creating room outcome config: ', error);
                    }
                }

                setParsingError(parsingError);
                setIsLoading(false);
                setFileUploadAttempt(true);
            },
            error: error => {
                setParsingError(error.message);
                setIsLoading(false);
                setFileUploadAttempt(true);
            },
        });
    };

    const handleDelete = useCallback(async () => {
        setFile(null);
        setIsLoading(true);
        try {
            await deleteRoomOutcomeConfig(companyStore.result.sid);
            localStorage.removeItem(ROOM_OUTCOME_CONFIG_FILE_NAME);
            setFileUploadAttempt(false);
            setIsLoading(false);
        } catch (error) {
            setParsingError('File deletion failed');
            setIsLoading(false);
            log.error('Error removing room outcome config: ', error);
        }
    }, [companyStore?.result?.sid, ROOM_OUTCOME_CONFIG_FILE_NAME]);

    const handleCancel = useCallback(() => {
        if (localStorage.getItem(ROOM_OUTCOME_CONFIG_FILE_NAME)) {
            setFile(new File([], localStorage.getItem(ROOM_OUTCOME_CONFIG_FILE_NAME)));
            setParsingError(null);
            return;
        }
        setFile(null);
        setFileUploadAttempt(false);
    }, [ROOM_OUTCOME_CONFIG_FILE_NAME]);

    if (isLoading) {
        return (
            <Column style={{ gap: '8px' }}>
                <Row justifyContent="center">
                    <Spinner size="30px" />
                </Row>
                <Row>
                    <AppText style={{ fontSize: '.875rem' }}>
                        {truncateFileName(file?.name)}
                    </AppText>
                </Row>
            </Column>
        );
    }

    return (
        <>
            {!file && (
                <UploadButton
                    data-testid={`${setting.testId}-upload-button`}
                    variant="primary"
                    onClick={() => {
                        inputRef.current.click();
                    }}
                >
                    <Row justifyContent="center" alignItems="center" style={{ gap: '10px' }}>
                        <FileUploader
                            inputRef={inputRef}
                            onChange={handleUpload}
                            accept=".csv"
                            testId={`${setting.testId}-hidden-input`}
                        />
                        <Box style={{ marginTop: '2px' }}>
                            <AppIcon name="AddFileIcon" size="medium" color={theme.colors.white} />
                        </Box>
                        <AppText semibold color="white">
                            Upload .CSV
                        </AppText>
                    </Row>
                </UploadButton>
            )}
            {fileUploadAttempt && (
                <ErrorOrSuccessDisplay
                    file={file}
                    inputRef={inputRef}
                    handleUpload={handleUpload}
                    error={parsingError}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                />
            )}
        </>
    );
};

interface ErrorOrSuccessDisplayProps {
    file: File;
    inputRef: MutableRefObject<HTMLInputElement>;
    handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    handleDelete: () => void;
    handleCancel: () => void;
}

const ErrorOrSuccessDisplay = ({
    file,
    inputRef,
    handleUpload,
    error,
    handleDelete,
    handleCancel,
}: ErrorOrSuccessDisplayProps) => {
    const theme = useTheme();
    return (
        <Box>
            <Row alignItems="center" justifyContent="flex-end">
                <AppIcon
                    name={error ? 'CloseIcon' : 'DoneIcon'}
                    color={error ? theme.colors.red50 : theme.colors.green50}
                    data-testid={
                        error
                            ? 'call-disposition-upload-failure'
                            : 'call-disposition-upload-success'
                    }
                />
                <Row style={{ gap: '5px' }}>
                    <TruncatedText semibold data-testid="call-disposition-upload-label">
                        {truncateFileName(file.name)}
                    </TruncatedText>
                    <AppText semibold>{error ? 'Failed' : 'Uploaded'}</AppText>
                </Row>
                <Button
                    variant="tertiary"
                    onClick={() => {
                        inputRef.current.click();
                    }}
                    style={{
                        margin: '0px 8px',
                    }}
                    data-testid={error ? 'call-disposition-retry' : 'call-disposition-replace'}
                >
                    {error ? 'Retry' : 'Replace'}
                </Button>
                <FileUploader inputRef={inputRef} onChange={handleUpload} accept=".csv" />

                <Button
                    variant="tertiary"
                    data-testid={`call-disposition-${error ? 'cancel' : 'delete'}`}
                    onClick={() => (error ? handleCancel() : handleDelete())}
                >
                    {error ? 'Cancel' : 'Delete'}
                </Button>
            </Row>
            {error && (
                <Row
                    style={{
                        marginLeft: '35px',
                        position: 'relative',
                    }}
                >
                    <ErrorText color="red50">{error}</ErrorText>
                </Row>
            )}
        </Box>
    );
};

const UploadButton = styled(Button)(() => ({
    width: '200px',
}));

const TruncatedText = styled(AppText)(() => ({
    marginLeft: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
}));

const ErrorText = styled(AppText)(() => ({
    position: 'absolute',
    top: '-3px',
    left: '0',
    width: '100%',
    fontSize: '.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));
