import { AppText, Column, Flex, Row, styled } from '@streem/ui-react';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { RoomMetrics } from '../../../util/metrics';

interface Props {
    roomMetrics: RoomMetrics;
    isSuccessfullyLoaded: boolean;
}

export const GroupMetrics: React.FC<Props> = ({ roomMetrics, isSuccessfullyLoaded }) => {
    const { companySettingsStore } = useGlobalStore();

    return (
        <MetricsContainer>
            {companySettingsStore.expertAvailabilityEnabled && (
                <Metric
                    value={roomMetrics?.avgCallCountPerHour}
                    label="Calls per Hour"
                    isSuccessfullyLoaded={isSuccessfullyLoaded}
                    dataTestId="calls-per-hour-metric"
                />
            )}
            <Metric
                value={roomMetrics?.avgCallDurationInMins}
                label="Avg Handle Time"
                unit={
                    isSuccessfullyLoaded
                        ? roomMetrics?.avgCallDurationInMins === 1
                            ? ' min'
                            : ' mins'
                        : null
                }
                isSuccessfullyLoaded={isSuccessfullyLoaded}
                dataTestId="avg-handle-time-metric"
            />
            <Metric
                value={roomMetrics?.missedCallCount}
                label="Missed Calls"
                isSuccessfullyLoaded={isSuccessfullyLoaded}
                dataTestId="missed-call-count-metric"
            />
            <Metric
                value={roomMetrics?.totalCallCount}
                label="Total Calls"
                isSuccessfullyLoaded={isSuccessfullyLoaded}
                dataTestId="total-calls-metric"
            />
            {companySettingsStore.expertAvailabilityEnabled && (
                <Metric
                    value={roomMetrics?.totalActiveHours}
                    label="Active Hours"
                    isSuccessfullyLoaded={isSuccessfullyLoaded}
                    dataTestId="active-hours-metric"
                />
            )}
            {companySettingsStore.expertAvailabilityEnabled && (
                <Metric
                    value={roomMetrics?.unavailabilityPercentage}
                    label="% Unavailable"
                    unit={isSuccessfullyLoaded ? '%' : null}
                    isSuccessfullyLoaded={isSuccessfullyLoaded}
                    dataTestId="percent-unavailable-metric"
                />
            )}
        </MetricsContainer>
    );
};

const Metric: React.FC<{
    value: number;
    unit?: string;
    label: string;
    isSuccessfullyLoaded: boolean;
    dataTestId: string;
}> = ({ value, unit, label, isSuccessfullyLoaded, dataTestId }) => (
    <MetricBox data-testid={dataTestId}>
        {isSuccessfullyLoaded && value !== undefined && value !== null ? (
            <AppText
                as="h3"
                size="large"
                color="dark"
                style={{ marginBottom: '17px' }}
                headingFontFamily
            >
                {value}
                {unit ?? ''}
            </AppText>
        ) : (
            <LoadingDashContainer>
                <LoadingDash />
            </LoadingDashContainer>
        )}
        <AppText size="medium" color="medium" headingFontFamily>
            {label}
        </AppText>
    </MetricBox>
);

const MetricsContainer = styled(Row)(() => ({
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '120px',
    marginBottom: '22px',
}));

const MetricBox = styled(Column)(({ theme }) => ({
    alignItems: 'center',
    border: `1px solid ${theme.colors.grey20}`,
    borderRadius: '8px',
    justifyContent: 'center',
    minWidth: '170px',
    height: '118px',
    marginRight: '32px',
    marginBottom: '10px',
}));

const LoadingDashContainer = styled(Flex)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '17px',
    minHeight: '39px',
}));

const LoadingDash = styled(Flex)(({ theme }) => ({
    background: theme.colors.grey30,
    height: '2px',
    width: '32px',
}));
